import { defaultPermissions } from '@donkeyjs/core';

export const permissions = defaultPermissions().with({
  visitor: {
    EventOption: { read: 'allow' },
    Koek: { read: 'allow' },
    Place: { read: 'allow' },
    TagEvents: { read: 'allow' },
  },
});
