import { createView, getUserContext } from '@donkeyjs/client';
import { live } from '@donkeyjs/jsx-runtime';
import {
  AddToCartQuantity,
  formatCurrencySign,
} from '@donkeyjs/module-shopping';
import type { DataNode } from '@donkeyjs/proxy';
import styles from './ViewProductOrEventOption.module.css';

export const ViewProductOrEventOption = createView<
  DataSchema,
  'EventOption' | 'ProductOption'
>(
  {
    name: () => 'Default shop option view',
    containerClass: styles.container,
    dataContainerClass: styles.dataContainer,
  },

  (props) => {
    const user = getUserContext();
    live(() => {
      if (user.isAdmin && !props.node.currency) {
        props.node.currency = 'EUR';
      }
    });

    return (
      <div
        class={[
          styles.option,
          { [styles.product]: props.node.__typename === 'ProductOption' },
        ]}
        onmount={props.onmount}
      >
        <div class={styles.name}>
          <props.node.$.name />
        </div>
        {props.node.__typename === 'EventOption' ? (
          <AddToCartQuantity
            kind={'eventOption'}
            item={props.node as DataNode<DataSchema, 'EventOption'>}
            class={styles.quantity}
          />
        ) : (
          <AddToCartQuantity
            kind={'productOption'}
            item={props.node as DataNode<DataSchema, 'ProductOption'>}
            class={styles.quantity}
          />
        )}
        <div class={styles.price}>
          <span>{() => formatCurrencySign(props.node.currency)}</span>
          <props.node.$.price formatter=".2f" />
        </div>
      </div>
    );
  },
);
