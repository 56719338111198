import { DataFilters, Menu, XLogo, getTheme, session } from '@donkeyjs/client';
import { OrderSummary, useCart } from '@donkeyjs/module-shopping';
// import { PhFacebookLogo } from '@donkeyjs/phosphor-icons';
import { bind } from '@donkeyjs/proxy';
import styles from './Sidebar.module.css';
import { Subscribe } from './Subscribe';

export function Sidebar() {
  const theme = getTheme();
  const cart = useCart();

  return (
    <div class={styles.sidebar}>
      <div class={styles.sticky}>
        <a href="/" class={styles.logo}>
          <span>Het Jagthuis</span>
        </a>

        <div class={styles.buttons}>
          <a
            class={[theme.class.button, 'active', styles.shadow]}
            href="/webshop"
          >
            Bestel een abonnement
          </a>
          <Subscribe />
        </div>
        {() =>
          session.router.route.node?.key !== 'cart' &&
          !!cart.order &&
          cart.count !== 0 && (
            <div class={styles.cart}>
              <div class={styles.cartSummary}>
                <OrderSummary order={cart.order} />
              </div>
              <a
                class={[
                  theme.class.button,
                  styles.cartButton,
                  'active',
                  styles.shadow,
                ]}
                href={bind(() => session.router.getPath({ routeKey: 'cart' }))}
              >
                Naar de kassa
              </a>
            </div>
          )
        }
        <div class={styles.filters}>
          <DataFilters />
        </div>
        <div class={styles.menu}>
          <Menu level={1} expand="active" />
        </div>
        <div class={styles.social}>
          {/* <a
            rel="noopener noreferrer"
            href="https://www.facebook.com/jagthuis.kamermuziek"
            target="_blank"
          >
            <PhFacebookLogo weight="fill" />
          </a> */}
          <a
            rel="noopener noreferrer"
            href="https://twitter.com/JagthuisMuziek"
            target="_blank"
          >
            <XLogo />
          </a>
        </div>
      </div>
    </div>
  );
}
