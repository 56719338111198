import { ViewGoogleMap, type Views } from '@donkeyjs/client';
import { ViewEvent } from './ViewEvent';
import { ViewKoek } from './ViewKoek';
import { ViewProduct } from './ViewProduct';
import { ViewProductOrEventOption } from './ViewProductOrEventOption';

export const views: Views = {
  Event: { default: ViewEvent },
  EventOption: { default: ViewProductOrEventOption as any },
  Koek: { default: ViewKoek },
  Place: { map: ViewGoogleMap },
  Product: { default: ViewProduct },
  ProductOption: { default: ViewProductOrEventOption as any },
};
