import {
  NodeRoutingPlugin,
  createClientApp,
  type AppClientModule,
  type BackOfficeSettings,
} from '@donkeyjs/client';
import { clientModuleEvents } from '@donkeyjs/module-events';
import {
  clientModuleMailings,
  setMailingOptions,
} from '@donkeyjs/module-mailings';
import {
  clientModuleProducts,
  clientModuleShopping,
  parseProductOrderItem,
  setShoppingSettings,
} from '@donkeyjs/module-shopping';
import { clientModuleTicketing } from '@donkeyjs/module-ticketing';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { clientModuleSocialButtons } from '../../../../donkeyjs/packages/module-social-buttons/src';
import { clientSchemaMeta } from '../clientSchemaMeta';
import { settings } from '../settings';
import { Main } from './Main';
import { blockPresets } from './blocks';
import { parseEventOrderItem } from './helpers/parseEventOrderItem';
import { theme } from './theme';
import { views } from './views';

const backOffice: BackOfficeSettings = {
  load: () => import('@donkeyjs/backoffice').then((m) => m.BackOffice),
};

setMailingOptions({
  newsletterTemplates: [
    {
      id: 'nl',
      name: 'Newsletter',
      component: () =>
        import('../newsletter/Newsletter').then((m) => m.Newsletter),
    },
  ],
});

setShoppingSettings({
  stripe: {
    publicKey: 'pk_live_H7Ow0Nta53ZFaOZnmUf5rUqn',
  },
  parseOrderItem: (item) => {
    const result = parseProductOrderItem(item);
    if (result) {
      result.image = item.productOption?.product.image;
      return result;
    }
    return parseEventOrderItem(item);
  },
});

export const app = createClientApp({
  ...settings,

  clientSchemaMeta,

  tz: {
    utcToZonedTime,
    zonedTimeToUtc,
    defaultTimeZone: 'Europe/Amsterdam',
  },

  theme,
  views,
  blockPresets,

  root: Main,
  backOffice,
  routerPlugins: [NodeRoutingPlugin],

  modules: [
    clientModuleEvents,
    clientModuleShopping,
    clientModuleProducts as AppClientModule<DataSchema>,
    clientModuleTicketing,
    clientModuleMailings,
    clientModuleSocialButtons,
  ],

  changelog: {
    scope: 'www.jagthuis.nl',
    log: () => import('../changelog').then((m) => m.log),
  },
});
