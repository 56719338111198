import { createClientSchemaMeta } from '@donkeyjs/client';
import { schemaMeta } from './schemaMeta';

export const clientSchemaMeta = createClientSchemaMeta(schemaMeta, {
  Koek: {
    asString(node) {
      const name = node.text[0]?.text?.slice(0, 30);
      return name ? `${name}...` : 'Koek';
    },
  },
});
