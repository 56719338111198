import { batch, signal, type Signal } from '@preact/signals-core';
import debounce from 'debounce';
import { generateNodeId } from '../helpers/generateNodeId';
import { meta } from '../helpers/meta';
import { processNodeData } from '../helpers/processNodeData';
import {
  isNode,
  type AppSchema,
  type NodeTypename,
  type Schema,
  type StatusFragment,
} from '../schema';
import { store } from '../store';
import { isDataList } from './DataList';
import {
  createDataNode,
  type DataNode,
  type DataNodeFields,
  type IntrinsicNodeFields,
  type Node,
} from './DataNode';
import { getNodeRef } from './getNodeRef';

export type EnsureNodeFunction = (
  values: IntrinsicNodeFields | `${string}:${string}`,
  options?: {
    fieldStatus?: StatusFragment;
    source?: string;
    isTest?: boolean;
    isCreating?: boolean;
    isLocal?: boolean;
  },
) => Node;

export interface NodeFactory<S extends Schema = Schema> {
  createNode<Typename extends NodeTypename<S>>(
    values: Omit<Partial<IntrinsicNodeFields>, '__typename'> & {
      readonly __typename: Typename;
    } & Partial<DataNodeFields<S, Typename>>,
  ): DataNode<S, Typename>;
  createLocalNode<Typename extends NodeTypename<S>>(
    values: Omit<Partial<IntrinsicNodeFields>, '__typename'> & {
      readonly __typename: Typename;
    } & Partial<DataNodeFields<S, Typename>>,
  ): DataNode<S, Typename>;
  ensureNode: EnsureNodeFunction;
  getNodeIfExists: <Typename extends NodeTypename<S>>(
    typename: Typename,
    id: string,
  ) => DataNode<S, Typename> | undefined;
  processNodeData(values: any, source?: string): any;
  deleteNode(node: Node): void;
  nodesByTypename(typename: string): Node[];
  nodeToString(node: Node): string;
  tz?: TimeZoneConfig;
  readonly presentTypenames: string[];
  readonly mutated: Node[];
  readonly mutationsInProgress: Node[];

  serialize(): any;
  deserialize(data: any): void;
}

export interface TimeZoneConfig {
  utcToZonedTime(date: Date | string | number, timeZone: string): Date;
  zonedTimeToUtc(date: Date | string | number, timeZone: string): Date;
  defaultTimeZone: string;
}

interface NodeFactoryOptions {
  handleMutations?(nodes: Node[]): Promise<void>;
  handleLazyLoad?(nodes: Node[]): void;
  nodeToString?(node: Node): string;
  tz?: TimeZoneConfig;
}

export function nodeFactory(schema: AppSchema, options?: NodeFactoryOptions) {
  const nodes = new Map<string, Node>();
  const byTypename: Record<string, Signal<Node[]>> = {};

  const state = store({
    mutated: [] as Node[],
    mutationsInProgress: [] as Node[],
  });

  function addToMutated(node: Node) {
    const mutated = state.$.peek('mutated');
    if (!mutated.includes(node)) {
      state.mutated = [...mutated, node];
    }
    handleMutations?.();
  }

  const handleMutations =
    options?.handleMutations &&
    debounce(() => {
      const mutationsInProgress = [...state.mutated];
      batch(() => {
        state.mutated = [];
        state.mutationsInProgress = mutationsInProgress;
      });
      options!.handleMutations!(mutationsInProgress).finally(() => {
        state.mutationsInProgress = [];
      });
    }, 500);

  const lazyFieldRequests = new Set<Node>();

  function handleLazyLoad(node: Node) {
    lazyFieldRequests.add(node);
    scheduleLazyLoad?.();
  }

  const scheduleLazyLoad =
    options?.handleLazyLoad &&
    debounce(() => {
      const requests = Array.from(lazyFieldRequests);
      lazyFieldRequests.clear();
      options.handleLazyLoad!(requests);
    }, 1);

  const factory: NodeFactory = {
    get mutated() {
      return state.mutated;
    },

    get mutationsInProgress() {
      return state.mutationsInProgress;
    },

    createNode(values) {
      const result = factory.createLocalNode(values);
      meta(result).isLocal = false;
      return result;
    },

    createLocalNode(values) {
      const result = factory.ensureNode(
        {
          ...values,
          id: values.id ?? generateNodeId(),
        },
        { isLocal: true },
      ) as any;
      return result;
    },

    ensureNode(
      values,
      { fieldStatus, source, isTest, isCreating, isLocal } = {},
    ): Node {
      const val =
        typeof values === 'string'
          ? {
              __typename: values.split(':')[0],
              id: values.split(':')[1],
            }
          : {
              ...values,
              id: values.id ?? generateNodeId(),
            };
      const { __typename, id, ...rest } = val;
      const ref = `${__typename}:${id}`;
      if (!nodes.has(ref)) {
        const loading =
          fieldStatus?.id === 'requested' || fieldStatus?.id === 'loading';

        const result: Node = createDataNode({
          schema,
          values: isCreating || isLocal ? { __typename, id } : val,
          fieldStatus:
            fieldStatus ||
            (isLocal || isCreating ? undefined : { id: 'ready' }),
          loading,
          source,
          dispose: () => {
            factory.deleteNode(result);
          },
          test: isTest,
          creating: isCreating,
          factory,
          scheduleFieldRequest: scheduleLazyLoad
            ? () => handleLazyLoad(result)
            : undefined,
          scheduleMutation: handleMutations
            ? () => addToMutated(result)
            : undefined,
        });

        if (isCreating || isLocal) {
          batch(() => {
            Object.assign(result, rest);
            if (isCreating) meta(result).fix();
          });
        }

        nodes.set(ref, result);
        if (!loading) {
          const typeNodes = (byTypename[result.__typename] ||= signal([]));
          if (!typeNodes.peek().includes(result)) {
            typeNodes.value = [...typeNodes.peek(), result];
          }
        }
      }
      return nodes.get(ref)!;
    },

    getNodeIfExists(typename, id) {
      return nodes.get(`${typename}:${id}`) as any;
    },

    processNodeData(values, source) {
      return processNodeData({
        values,
        source,
        factory,
      });
    },

    deleteNode(node) {
      const ref = getNodeRef(node);
      nodes.delete(ref);
      const typeNodes = byTypename[node.__typename];
      if (typeNodes?.peek().includes(node)) {
        typeNodes.value = typeNodes.peek().filter((n) => n !== node);
      }
    },

    nodesByTypename(typename) {
      const typeNodes = (byTypename[typename] ||= signal([]));
      return typeNodes.value;
    },

    nodeToString(node) {
      return options?.nodeToString?.(node) ?? '';
    },

    tz: options?.tz,

    get presentTypenames() {
      return Object.keys(byTypename);
    },

    serialize() {
      return Array.from(nodes.values())
        .map((node) => {
          if (meta(node).isLoading) {
            console.warn('Serializing skipped for', node);
            return undefined;
          }
          const result: any = {
            v: Object.fromEntries(
              Object.entries(meta(node).store.$.source)
                .map(
                  ([key, value]) =>
                    (Array.isArray(value)
                      ? [key, isDataList(value) ? [] : value]
                      : [
                          key,
                          isNode(value)
                            ? { __typename: value.__typename, id: value.id }
                            : value,
                        ])!,
                )
                .filter(Boolean),
            ),
          };
          if (meta(node).sources.length) {
            result.s = meta(node).sources;
          }
          return result;
        })
        .filter(Boolean);
    },

    deserialize(data) {
      for (const { v, s } of data) {
        const node = processNodeData({ values: v, factory });
        if (s)
          meta(node).sources = Array.from(
            new Set([...meta(node).sources, ...s]),
          );
      }
    },
  };

  return factory;
}
