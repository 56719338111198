import { getTheme } from '@donkeyjs/client';
import { PhMinus, PhPlus, PhTrash } from '@donkeyjs/phosphor-icons';
import { bind } from '@donkeyjs/proxy';
import styles from './Quantity.module.css';

interface QuantityProps {
  readonly number: number;
  readonly class?: JSX.ClassNames;
  increment(): void;
  decrement(): void;
}

export function Quantity(props: QuantityProps) {
  const theme = getTheme();

  return (
    <span class={bind(() => ['quantity', styles.quantity, props.class])}>
      <span class={['value', styles.value]}>{() => props.number}</span>
      <button
        type="button"
        class={[theme.class.linkButton, styles.button]}
        onclick={() => props.decrement()}
      >
        {() =>
          props.number === 1 ? (
            <PhTrash weight="bold" />
          ) : (
            <PhMinus weight="bold" />
          )
        }
      </button>
      <button
        type="button"
        class={[theme.class.linkButton, styles.button]}
        onclick={() => props.increment()}
      >
        <PhPlus weight="bold" />
      </button>
    </span>
  );
}
