import type { ParsedOrderItem } from '@donkeyjs/module-shopping';
import type { DataNode } from '@donkeyjs/proxy';

export function parseEventOrderItem(
  item: DataNode<DataSchema, 'OrderItem'>,
): Omit<ParsedOrderItem, 'origin'> | null {
  return item.eventOption
    ? {
        key: 'eventOption',
        orderable: item.eventOption,
        orderables: item.eventOption.event.options.map((option) => ({
          item: option,
          name: option.name,
        })),
        name: item.eventOption?.event.toString(),
        option: item.eventOption?.name,
        image: item.eventOption?.event.image,
      }
    : null;
}
