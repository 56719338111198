import { createTheme } from '@donkeyjs/client';
import themeNewsletter from '../newsletter/theme.newsletter.module.css';
import cart from './theme.cart.module.css';
import styles from './theme.module.css';

export const theme = () =>
  createTheme({
    colors: {
      accent: '#8d0000',
      secondary: '#1b4f10',
      background: '#fffeda',
      background2: '#f8f3c3',
      color: '#000',
      error: '#A82B1C',
      'text-background': '#fff5d04d',
      'text-on-error': '#ffffff',
      'text-on-accent': '#ffffff',
      'viewport-background': '#ffffff',
    },

    font: "10pt/1.6 'Noto Sans', sans-serif",

    webFonts: {
      google: {
        families: ['Noto+Sans:ital,wght@0,100..900;1,100..900'],
      },
    },

    email: {
      font: "10pt/1.3 'Asap', Helvetica, Arial, sans-serif",
      styles: themeNewsletter,
    },

    styles,

    components: {
      shopping_cart: cart,
    },
  });
