import { getI18n, session, text } from '@donkeyjs/client';
import { bind } from '@donkeyjs/jsx-runtime';
import type { DataNode } from '@donkeyjs/proxy';
import { orderBalance } from '../../helpers';
import { parseOrderItem } from '../../helpers/parseOrderItem';
import { I18nShopping } from '../../i18n';
import { formatMoney } from '../helpers/formatMoney';
import styles from './OrderSummary.module.css';

interface OrderSummaryProps {
  order: DataNode<DataSchema, 'Order'>;
  includeZeroPayed?: boolean;
}

export function OrderSummary(props: OrderSummaryProps) {
  const i18n = getI18n(true);
  const balance = orderBalance({
    get order() {
      return props.order;
    },
    shipping: session.app.data[0],
  });

  return (
    <>
      <div class={[styles.item]}>
        <span>
          {() =>
            props.order.items.length > 1 ? (
              text(
                I18nShopping,
                'Order.Length',
                { forUser: true },
                balance.itemCount,
              )
            ) : props.order.items.length ? (
              <>
                {parseOrderItem(props.order.items[0])?.name}
                {balance.itemCount > 1 ? ` (x${balance.itemCount})` : ''}
              </>
            ) : null
          }
        </span>
        <span>
          {() => formatMoney(i18n, balance.itemSum, props.order.currency)}
        </span>
      </div>
      {() =>
        balance.shippingCosts > 0 && (
          <div class={styles.item}>
            <span>{text(I18nShopping, 'Order.Shipping')}</span>
            <span>
              {() =>
                formatMoney(i18n, balance.shippingCosts, props.order.currency)
              }
            </span>
          </div>
        )
      }
      {() =>
        balance.discounts > 0 && (
          <div class={styles.item}>
            <span>{text(I18nShopping, 'Order.Discounts')}</span>
            <span>
              {() =>
                formatMoney(i18n, -balance.discounts, props.order.currency)
              }
            </span>
          </div>
        )
      }
      <div class={[styles.item, styles.sum]}>
        <span>{text(I18nShopping, 'Order.Sum')}</span>
        <span>
          {() => formatMoney(i18n, balance.sum, props.order.currency)}
        </span>
      </div>
      {() =>
        (props.includeZeroPayed || balance.payed > 0) && (
          <>
            <div
              class={bind(() => [
                styles.item,
                styles.payed,
                { [styles.zero]: balance.payed === 0 },
              ])}
            >
              <span>{text(I18nShopping, 'Order.Payed')}</span>
              <span>
                {() => formatMoney(i18n, balance.payed, props.order.currency)}
              </span>
            </div>
            <div class={[styles.item, styles.balance]}>
              <span>{text(I18nShopping, 'Order.Balance')}</span>
              <span>
                {() => formatMoney(i18n, balance.balance, props.order.currency)}
              </span>
            </div>
          </>
        )
      }
    </>
  );
}
