import { Heading, IncreaseHeadingLevel, createView } from '@donkeyjs/client';
import { bind } from '@donkeyjs/proxy';
import { CartOptions } from './components/CartOptions';
import { ViewBox } from './components/ViewBox';

export const ViewProduct = createView<DataSchema, 'Product'>(
  {
    name: () => 'Product View',
    enableAsBlock: true,
  },

  function ViewProduct(props) {
    return (
      <ViewBox
        item={props.node}
        class={bind(() => props.class)}
        onmount={props.onmount}
      >
        <Heading styleAs="title">
          <props.node.$.name />
        </Heading>
        <IncreaseHeadingLevel>
          <props.node.$.information />
          <CartOptions kind="productOption" node={props.node} />
        </IncreaseHeadingLevel>
      </ViewBox>
    );
  },
);
