import { lazyComponent, type AppClientModule } from '@donkeyjs/client';
import { permissions } from '../permissions';

const EventSalesRoute = lazyComponent('event-sales-route', () =>
  import('./backoffice/components/EventSalesRoute').then(
    (m) => m.EventSalesRoute,
  ),
);

export const clientModuleTicketing: AppClientModule = {
  backOfficePlugins: [
    () => import('./backoffice').then((m) => m.ticketingPlugin),
  ],
  permissions,
  localRoutes: [
    {
      name: 'Event',
      id: 'ticketing.event',
      pathname: '/.event',
      isSystemRoute: true,
      render: () => <EventSalesRoute />,
    },
  ],
};
