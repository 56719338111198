import type { BlockPreset, DataSettings } from '@donkeyjs/client';
import { PhBarcode, PhCake, PhCalendar } from '@donkeyjs/phosphor-icons';

export const blockPresets: BlockPreset[] = [
  {
    name: () => 'Agenda',
    group: () => 'Sections',
    icon: () => <PhCalendar weight="duotone" />,
    values: {
      type: 'data',
      settings: {
        resolver: 'events',
        selection: 'url',
        view: 'default',
        filters: [{ key: 'ends', value: '>=now' }],
      } as DataSettings,
    },
  },

  {
    name: () => 'Winkel',
    group: () => 'Sections',
    icon: () => <PhBarcode weight="duotone" />,
    values: {
      type: 'data',
      settings: {
        resolver: 'sortedProducts',
        view: 'default',
      } as DataSettings,
    },
  },

  {
    name: () => 'Koekpagina',
    group: () => 'Sections',
    icon: () => <PhCake weight="duotone" />,
    values: {
      type: 'data',
      settings: {
        resolver: 'koek',
        view: 'default',
      } as DataSettings,
    },
  },
];
