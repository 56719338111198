import { getTheme, session } from '@donkeyjs/client';
import { useCart, type OrderableKey } from '@donkeyjs/module-shopping';
import type { DataNode } from '@donkeyjs/proxy';
import styles from './CartOptions.module.css';

interface CartOptionsProps {
  kind: OrderableKey;
  node: DataNode<DataSchema, 'Product'> | DataNode<DataSchema, 'Event'>;
  class?: JSX.ClassNames;
}

export function CartOptions(props: CartOptionsProps) {
  const cart = useCart();
  const theme = getTheme();

  return (
    <div class={props.class}>
      <props.node.$.options />
      {() =>
        cart.isInCart(props.kind, props.node.options) && (
          <div class={styles.cartLink}>
            <a
              class={[theme.class.button, 'default']}
              href={session.router.getPath({ routeKey: 'cart' })}
            >
              Naar de kassa
            </a>
          </div>
        )
      }
    </div>
  );
}
