import { bind } from '@donkeyjs/proxy';
import { createView } from '../../../views';
import { GoogleMap } from './GoogleMap';

export const ViewGoogleMap = createView<DataSchema, 'Place'>(
  {
    name: () => 'Google Map',
    enableAsBlock: true,
  },

  function ViewGoogleMap(props) {
    return (
      <div class={bind(() => props.class)} onmount={props.onmount}>
        <GoogleMap googlePlacesId={bind(() => props.node.googlePlacesId)} />
      </div>
    );
  },
);
