import type { CreateAppInput } from '@donkeyjs/core';
import { nlNL } from '@donkeyjs/i18n-nl-nl';
import { permissions } from './permissions';
import { schema } from './schema';
import { schemaMeta } from './schemaMeta';

export const settings: CreateAppInput = {
  hostname: 'www.jagthuis.nl',
  key: 'jagthuis',
  locales: { nl: nlNL },
  permissions,
  schema,
  schemaMeta,
};
