import { bind, type DataNode } from '@donkeyjs/proxy';
import styles from './ViewBox.module.css';

interface ViewBoxProps {
  class?: JSX.ClassNames;
  alternate?: boolean;
  item:
    | DataNode<DataSchema, 'Event'>
    | DataNode<DataSchema, 'Koek'>
    | DataNode<DataSchema, 'Product'>;
  footer?: JSX.Element;
  prepend?: JSX.Element;
  children?: JSX.Element;
  onmount?: JSX.OnMount<HTMLElement>;
}

export function ViewBox(props: ViewBoxProps) {
  return (
    <article
      class={bind(() => ['box', styles.box, props.class])}
      onmount={props.onmount}
    >
      <div
        class={bind(() => [
          styles.main,
          { [styles.alternate]: props.alternate },
        ])}
      >
        {props.prepend}
        <div class={styles.image}>
          <props.item.$.image changeOnClick="button" />
        </div>
        <div class={styles.content}>{props.children}</div>
        {props.footer}
      </div>
    </article>
  );
}
