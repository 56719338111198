import { store, type NodeFieldsFromSchema } from '@donkeyjs/proxy';
import { orderItemCount } from './orderItemCount';
import {
  orderShippingCosts,
  type AppShippingFields,
  type OrderShippingCostsFields,
} from './orderShippingCosts';
import { orderSum } from './orderSum';

interface OrderBalance {
  readonly itemCount: number;
  readonly itemSum: number;
  readonly shippingCosts: number;
  readonly discounts: number;
  readonly sum: number;
  readonly payed: number;
  readonly balance: number;
}

export type OrderBalanceFields = OrderShippingCostsFields & {
  transactions: Pick<
    NodeFieldsFromSchema<DataSchema, 'Transaction'>,
    'status' | 'type' | 'amount'
  >[];
};

export const orderBalance = (input: {
  order: OrderBalanceFields;
  shipping: AppShippingFields | null;
}): OrderBalance => {
  const balance = store({
    get itemCount() {
      return orderItemCount(input.order);
    },
    get itemSum() {
      return orderSum(input.order);
    },
    get shippingCosts() {
      return orderShippingCosts(input);
    },
    get discounts() {
      return input.order.transactions
        .filter((t) => t.type === 'COUPON' && t.status === 'SUCCEEDED')
        .reduce((sum, t) => sum + t.amount, 0);
    },
    get sum() {
      return balance.itemSum - balance.discounts + balance.shippingCosts;
    },
    get payed() {
      return input.order.transactions
        .filter(
          (t) =>
            (t.type === 'PAYMENT' || t.type === 'REQUEST') &&
            t.status === 'SUCCEEDED',
        )
        .reduce((sum, t) => sum + t.amount, 0);
    },
    get balance() {
      return balance.sum - balance.payed;
    },
  });

  return balance;
};
