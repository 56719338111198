import { createView } from '@donkeyjs/client';
import { bind } from '@donkeyjs/proxy';
import { ViewBox } from './components/ViewBox';

export const ViewKoek = createView<DataSchema, 'Koek'>(
  {
    name: () => 'Koek View',
    enableAsBlock: true,
  },

  function ViewKoek(props) {
    return (
      <ViewBox
        item={props.node}
        class={bind(() => props.class)}
        onmount={props.onmount}
        alternate
      >
        <props.node.$.text />
      </ViewBox>
    );
  },
);
