import type { NodeFieldsFromSchema } from '@donkeyjs/proxy';
import type { OrderAddressFields } from './getAddressFromOrder';

export type OrderShippingCostsFields = OrderAddressFields & {
  items: Pick<
    NodeFieldsFromSchema<DataSchema, 'OrderItem'>,
    'quantity' | 'weight' | 'price'
  >[];
};

export type AppShippingFields = Pick<
  NodeFieldsFromSchema<DataSchema, 'App'>,
  'shippingBasePrice' | 'shippingBaseWeight' | 'shippingFromCountry'
> & {
  shippingThresholds: Pick<
    NodeFieldsFromSchema<DataSchema, 'ShippingThreshold'>,
    'price' | 'region' | 'fromWeight' | 'fromQuantity'
  >[];
};

export const orderShippingCosts = (input: {
  order: OrderShippingCostsFields;
  shipping: AppShippingFields | null;
}) => {
  const { order, shipping } = input;

  const {
    // shippingFromCountry,
    shippingBasePrice,
    // shippingBaseWeight,
    // shippingThresholds,
  } = shipping || { shippingBasePrice: 0 };

  // const addresses = getAddressesFromOrder(order);

  const basis = order.items?.length ? shippingBasePrice ?? 0 : 0;

  return basis;

  // const country = countries.find(
  //   (cntry) => cntry.id === shippingAddress?.countryCode,
  // );
  // if (!country) return basis;

  // const { quantity, weight } = (order.items || []).reduce(
  //   (previous, item) => ({
  //     quantity: previous.quantity + item.quantity,
  //     weight: previous.weight + (item.weight ?? 0),
  //   }),
  //   { quantity: 0, weight: shippingBaseWeight ?? 0 },
  // );
  // if (weight === 0) return basis;

  // const region =
  //   country.id === shipsFromCountry
  //     ? 'LOCAL'
  //     : country.region === shipsFromContinent
  //       ? 'CONTINENT'
  //       : 'WORLD';
  // const variable = (shippingThresholds || []).reduce(
  //   (previous, threshold) =>
  //     threshold.region === region &&
  //     (((threshold.fromWeight ?? 0) <= weight &&
  //       (threshold.fromWeight ?? 0) > previous.weightThreshold) ||
  //       ((threshold.fromQuantity ?? 0) <= quantity &&
  //         (threshold.fromQuantity ?? 0) > previous.quantityThreshold))
  //       ? {
  //           price: threshold.price,
  //           quantityThreshold: threshold.fromQuantity ?? 0,
  //           weightThreshold: threshold.fromWeight ?? 0,
  //         }
  //       : previous,
  //   {
  //     price: 0,
  //     quantityThreshold: Number.MIN_VALUE,
  //     weightThreshold: Number.MIN_VALUE,
  //   },
  // );
  // return Math.round((basis + variable.price) * 100) / 100;
};
