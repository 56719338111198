import type { Component } from "@donkeyjs/jsx-runtime";

export interface IconProps {
  weight?: "thin" | "light" | "regular" | "bold" | "fill" | "duotone";
  children?: JSX.Children;
}

export type Icon = Component<IconProps>;

export function PhActivity(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M160,220h-.6a12.1,12.1,0,0,1-10.6-7.7L95,71.1,66.9,133A12,12,0,0,1,56,140H24a12,12,0,0,1,0-24H48.3L85.1,35a12,12,0,0,1,22.1.7l54.3,142.5,27.8-55.6A12.1,12.1,0,0,1,200,116h32a12,12,0,0,1,0,24H207.4l-36.7,73.4A12.1,12.1,0,0,1,160,220Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M160,216h-.4a8.1,8.1,0,0,1-7.1-5.2L95.3,60.8l-32,70.5A8,8,0,0,1,56,136H24a8,8,0,0,1,0-16H50.8L88.7,36.7A8.2,8.2,0,0,1,96.3,32a8,8,0,0,1,7.2,5.2L161,188.1l31.8-63.7A8.2,8.2,0,0,1,200,120h32a8,8,0,0,1,0,16H204.9l-37.7,75.6A8.2,8.2,0,0,1,160,216Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M160,216h-.4a8.1,8.1,0,0,1-7.1-5.2L95.3,60.8l-32,70.5A8,8,0,0,1,56,136H24a8,8,0,0,1,0-16H50.8L88.7,36.7A8.2,8.2,0,0,1,96.3,32a8,8,0,0,1,7.2,5.2L161,188.1l31.8-63.7A8.2,8.2,0,0,1,200,120h32a8,8,0,0,1,0,16H204.9l-37.7,75.6A8.2,8.2,0,0,1,160,216Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M160,214h-.3a5.8,5.8,0,0,1-5.3-3.9L95.5,55.6l-34,74.9A6.1,6.1,0,0,1,56,134H24a6,6,0,0,1,0-12H52.1L90.5,37.5a6,6,0,0,1,11.1.4l59.1,155.2,33.9-67.8A6,6,0,0,1,200,122h32a6,6,0,0,1,0,12H203.7l-38.3,76.7A6.2,6.2,0,0,1,160,214Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M160,212h-.2a3.9,3.9,0,0,1-3.5-2.6L95.7,50.4,59.6,129.7A3.9,3.9,0,0,1,56,132H24a4,4,0,0,1,0-8H53.4l39-85.7A3.8,3.8,0,0,1,96.1,36a4,4,0,0,1,3.6,2.6l60.8,159.5,35.9-71.9A4.2,4.2,0,0,1,200,124h32a4,4,0,0,1,0,8H202.5l-38.9,77.8A4.2,4.2,0,0,1,160,212Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M160,216h-.4a8.1,8.1,0,0,1-7.1-5.2L95.3,60.8l-32,70.5A8,8,0,0,1,56,136H24a8,8,0,0,1,0-16H50.8L88.7,36.7A8.2,8.2,0,0,1,96.3,32a8,8,0,0,1,7.2,5.2L161,188.1l31.8-63.7A8.2,8.2,0,0,1,200,120h32a8,8,0,0,1,0,16H204.9l-37.7,75.6A8.2,8.2,0,0,1,160,216Z" />
        </>
      )}
    </svg>
  );
}

export function PhAddressBook(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M208,20H64A20.1,20.1,0,0,0,44,40V56H32a12,12,0,0,0,0,24H44V96H32a12,12,0,0,0,0,24H44v16H32a12,12,0,0,0,0,24H44v16H32a12,12,0,0,0,0,24H44v16a20.1,20.1,0,0,0,20,20H208a20.1,20.1,0,0,0,20-20V40A20.1,20.1,0,0,0,208,20Zm-4,192H68V44H204ZM100.8,175.4a47.9,47.9,0,0,1,70.4,0A12,12,0,0,0,188.8,159a73.4,73.4,0,0,0-19.2-14.6,44,44,0,1,0-67.2,0A73.4,73.4,0,0,0,83.2,159a12,12,0,0,0,17.6,16.4ZM116,116a20,20,0,1,1,20,20A20.1,20.1,0,0,1,116,116Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M208,32H64a8,8,0,0,0-8,8V216a8,8,0,0,0,8,8H208a8,8,0,0,0,8-8V40A8,8,0,0,0,208,32ZM136,144a32,32,0,1,1,32-32A32,32,0,0,1,136,144Z"
            opacity="0.2"
          />
          <path d="M83.2,174.4A7.7,7.7,0,0,0,88,176a8,8,0,0,0,6.4-3.2,52,52,0,0,1,83.2,0,8.1,8.1,0,0,0,11.2,1.6,8,8,0,0,0,1.6-11.2A67.8,67.8,0,0,0,163,141.5a40,40,0,1,0-54,0,67.8,67.8,0,0,0-27.4,21.7A8,8,0,0,0,83.2,174.4ZM112,112a24,24,0,1,1,24,24A24.1,24.1,0,0,1,112,112Zm96-88H64A16,16,0,0,0,48,40V60H32a8,8,0,0,0,0,16H48v24H32a8,8,0,0,0,0,16H48v24H32a8,8,0,0,0,0,16H48v24H32a8,8,0,0,0,0,16H48v20a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V40A16,16,0,0,0,208,24Zm0,192H64V40H208Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M160,112a24,24,0,1,1-24-24A24.1,24.1,0,0,1,160,112Zm64-72V216a16,16,0,0,1-16,16H64a16,16,0,0,1-16-16V196H32a8,8,0,0,1,0-16H48V156H32a8,8,0,0,1,0-16H48V116H32a8,8,0,0,1,0-16H48V76H32a8,8,0,0,1,0-16H48V40A16,16,0,0,1,64,24H208A16,16,0,0,1,224,40ZM190.4,163.2A67.8,67.8,0,0,0,163,141.5a40,40,0,1,0-54,0,67.8,67.8,0,0,0-27.4,21.7,8,8,0,0,0,1.6,11.2A7.7,7.7,0,0,0,88,176a8,8,0,0,0,6.4-3.2,52,52,0,0,1,83.2,0,8.1,8.1,0,0,0,11.2,1.6A8,8,0,0,0,190.4,163.2Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M159.1,142.1A37.6,37.6,0,0,0,174,112a38,38,0,0,0-76,0,37.6,37.6,0,0,0,14.9,30.1,66.4,66.4,0,0,0-29.7,22.3,6,6,0,0,0,9.6,7.2,54,54,0,0,1,86.4,0,6,6,0,0,0,8.4,1.2,6,6,0,0,0,1.2-8.4A66.4,66.4,0,0,0,159.1,142.1ZM110,112a26,26,0,1,1,26,26A26.1,26.1,0,0,1,110,112Zm98-86H64A14,14,0,0,0,50,40V62H32a6,6,0,0,0,0,12H50v28H32a6,6,0,0,0,0,12H50v28H32a6,6,0,0,0,0,12H50v28H32a6,6,0,0,0,0,12H50v22a14,14,0,0,0,14,14H208a14,14,0,0,0,14-14V40A14,14,0,0,0,208,26Zm2,190a2,2,0,0,1-2,2H64a2,2,0,0,1-2-2V40a2,2,0,0,1,2-2H208a2,2,0,0,1,2,2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M154.7,142.8a36,36,0,1,0-37.4,0,63,63,0,0,0-32.5,22.8,4,4,0,0,0,.8,5.6,4.3,4.3,0,0,0,2.4.8,4.2,4.2,0,0,0,3.2-1.6,56,56,0,0,1,89.6,0,4,4,0,0,0,6.4-4.8A63,63,0,0,0,154.7,142.8ZM108,112a28,28,0,1,1,28,28A28.1,28.1,0,0,1,108,112ZM208,28H64A12,12,0,0,0,52,40V64H32a4,4,0,0,0,0,8H52v32H32a4,4,0,0,0,0,8H52v32H32a4,4,0,0,0,0,8H52v32H32a4,4,0,0,0,0,8H52v24a12,12,0,0,0,12,12H208a12,12,0,0,0,12-12V40A12,12,0,0,0,208,28Zm4,188a4,4,0,0,1-4,4H64a4,4,0,0,1-4-4V40a4,4,0,0,1,4-4H208a4,4,0,0,1,4,4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M83.2,174.4A7.7,7.7,0,0,0,88,176a8,8,0,0,0,6.4-3.2,52,52,0,0,1,83.2,0,8.1,8.1,0,0,0,11.2,1.6,8,8,0,0,0,1.6-11.2A67.8,67.8,0,0,0,163,141.5a40,40,0,1,0-54,0,67.8,67.8,0,0,0-27.4,21.7A8,8,0,0,0,83.2,174.4ZM112,112a24,24,0,1,1,24,24A24.1,24.1,0,0,1,112,112Zm96-88H64A16,16,0,0,0,48,40V60H32a8,8,0,0,0,0,16H48v24H32a8,8,0,0,0,0,16H48v24H32a8,8,0,0,0,0,16H48v24H32a8,8,0,0,0,0,16H48v20a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V40A16,16,0,0,0,208,24Zm0,192H64V40H208Z" />
        </>
      )}
    </svg>
  );
}

export function PhAirplaneInFlight(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M228,216a12,12,0,0,1-12,12H72a12,12,0,0,1,0-24H216A12,12,0,0,1,228,216Zm24-80v24a12,12,0,0,1-12,12H63.8a43.6,43.6,0,0,1-42.1-31.4L7.6,93.7A20,20,0,0,1,26.8,68H44a12,12,0,0,1,9.2,4.3L69.6,92h9.8L72.1,70.3A20,20,0,0,1,91.1,44H108a11.7,11.7,0,0,1,8.8,3.9L157.3,92H208A44,44,0,0,1,252,136Zm-24,0a20.1,20.1,0,0,0-20-20H152a11.7,11.7,0,0,1-8.8-3.9L102.7,68H96.6l10.8,32.2A12.1,12.1,0,0,1,96,116H64a12,12,0,0,1-9.2-4.3L38.4,92H32.1l12.6,41.7A19.7,19.7,0,0,0,63.8,148H228Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M26.8,80H44l20,24H96L83.5,66.5A8,8,0,0,1,91.1,56H108l44,48h56a32,32,0,0,1,32,32v24H63.8a32,32,0,0,1-30.6-22.8L19.1,90.3A8,8,0,0,1,26.8,80Z" />
          </g>
          <path d="M224,216a8,8,0,0,1-8,8H72a8,8,0,0,1,0-16H216A8,8,0,0,1,224,216Zm24-80v24a8,8,0,0,1-8,8H63.8a39.8,39.8,0,0,1-38.3-28.5L11.4,92.6a16.3,16.3,0,0,1,2.5-14.2A16.2,16.2,0,0,1,26.8,72H44a7.9,7.9,0,0,1,6.1,2.9L67.7,96H84.9l-9-26.9A16,16,0,0,1,91.1,48H108a7.9,7.9,0,0,1,5.9,2.6L155.5,96H208A40,40,0,0,1,248,136Zm-16,0a24.1,24.1,0,0,0-24-24H152a7.9,7.9,0,0,1-5.9-2.6L104.5,64H91.1l12.5,37.5a8.1,8.1,0,0,1-1.1,7.2A7.9,7.9,0,0,1,96,112H64a7.9,7.9,0,0,1-6.1-2.9L40.3,88H26.7l14.1,46.9a23.9,23.9,0,0,0,23,17.1H232Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M224,216a8,8,0,0,1-8,8H72a8,8,0,0,1,0-16H216A8,8,0,0,1,224,216ZM208,100H153.8L110.9,53.3A3.9,3.9,0,0,0,108,52H91.1a11.6,11.6,0,0,0-9.7,5,11.9,11.9,0,0,0-1.7,10.8L90.5,100H65.9L47.1,77.4A4.1,4.1,0,0,0,44,76H26.8a12.1,12.1,0,0,0-9.7,4.8,11.9,11.9,0,0,0-1.8,10.6l14,46.9A35.8,35.8,0,0,0,63.8,164H240a4,4,0,0,0,4-4V136A36,36,0,0,0,208,100Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M222,216a6,6,0,0,1-6,6H72a6,6,0,0,1,0-12H216A6,6,0,0,1,222,216Zm24-80v24a6,6,0,0,1-6,6H63.8a37.8,37.8,0,0,1-36.4-27.1L13.3,92a14.4,14.4,0,0,1,2.2-12.4A14.2,14.2,0,0,1,26.8,74H44a5.8,5.8,0,0,1,4.6,2.2L66.8,98H87.7L77.8,68.4A14,14,0,0,1,91.1,50H108a6,6,0,0,1,4.4,1.9L154.6,98H208A38,38,0,0,1,246,136Zm-12,0a26.1,26.1,0,0,0-26-26H152a6,6,0,0,1-4.4-1.9L105.4,62H91.1a1.9,1.9,0,0,0-1.6.8,2,2,0,0,0-.3,1.8l12.5,37.5A6,6,0,0,1,96,110H64a5.8,5.8,0,0,1-4.6-2.2L41.2,86H26.8a2.3,2.3,0,0,0-1.7.8,2.2,2.2,0,0,0-.3,1.8l14.1,46.9A25.7,25.7,0,0,0,63.8,154H234Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M220,216a4,4,0,0,1-4,4H72a4,4,0,0,1,0-8H216A4,4,0,0,1,220,216Zm24-80v24a4,4,0,0,1-4,4H63.8a35.8,35.8,0,0,1-34.5-25.7l-14-46.9a11.9,11.9,0,0,1,1.8-10.6A12.1,12.1,0,0,1,26.8,76H44a4.1,4.1,0,0,1,3.1,1.4L65.9,100H90.5L79.7,67.8A11.9,11.9,0,0,1,81.4,57a11.6,11.6,0,0,1,9.7-5H108a3.9,3.9,0,0,1,2.9,1.3L153.8,100H208A36,36,0,0,1,244,136Zm-8,0a28.1,28.1,0,0,0-28-28H152a3.9,3.9,0,0,1-2.9-1.3L106.2,60H91.1a3.7,3.7,0,0,0-3.2,1.7,3.8,3.8,0,0,0-.6,3.6l12.5,37.4a3.8,3.8,0,0,1-.6,3.6A3.7,3.7,0,0,1,96,108H64a4.1,4.1,0,0,1-3.1-1.4L42.1,84H26.8a4.2,4.2,0,0,0-3.3,1.6,4.3,4.3,0,0,0-.6,3.5L37,136a27.8,27.8,0,0,0,26.8,20H236Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M224,216a8,8,0,0,1-8,8H72a8,8,0,0,1,0-16H216A8,8,0,0,1,224,216Zm24-80v24a8,8,0,0,1-8,8H63.8a39.8,39.8,0,0,1-38.3-28.5L11.4,92.6a16.3,16.3,0,0,1,2.5-14.2A16.2,16.2,0,0,1,26.8,72H44a7.9,7.9,0,0,1,6.1,2.9L67.7,96H84.9l-9-26.9A16,16,0,0,1,91.1,48H108a7.9,7.9,0,0,1,5.9,2.6L155.5,96H208A40,40,0,0,1,248,136Zm-16,0a24.1,24.1,0,0,0-24-24H152a7.9,7.9,0,0,1-5.9-2.6L104.5,64H91.1l12.5,37.5a8.1,8.1,0,0,1-1.1,7.2A7.9,7.9,0,0,1,96,112H64a7.9,7.9,0,0,1-6.1-2.9L40.3,88H26.7l14.1,46.9a23.9,23.9,0,0,0,23,17.1H232Z" />
        </>
      )}
    </svg>
  );
}

export function PhAirplaneLanding(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M252,216a12,12,0,0,1-12,12H96a12,12,0,0,1,0-24H240A12,12,0,0,1,252,216Zm-31.2-20.4L44.1,146.1A44.1,44.1,0,0,1,12,103.7V51.1a20,20,0,0,1,26.3-19l13.5,4.5a12.1,12.1,0,0,1,6.8,5.8L72.3,68,84,71.7V51.1a20,20,0,0,1,26.3-19l13.5,4.5a11.8,11.8,0,0,1,6.6,5.4L160,93.8l43.8,12.1A44.2,44.2,0,0,1,236,148.3V184a11.9,11.9,0,0,1-12,12A11.2,11.2,0,0,1,220.8,195.6ZM212,148.3a19.9,19.9,0,0,0-14.6-19.2l-48.6-13.5a12.3,12.3,0,0,1-7.2-5.6L111.9,57.9,108,56.6V88a12.1,12.1,0,0,1-4.9,9.7,12.3,12.3,0,0,1-10.7,1.8l-32-10a12.4,12.4,0,0,1-7-5.9L39.7,57.9,36,56.6v47.1A20,20,0,0,0,50.6,123L212,168.2Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M24,103.7V51.1a8,8,0,0,1,10.5-7.6L48,48,64,78,96,88V51.1a8,8,0,0,1,10.5-7.6L120,48l32,56,48.6,13.5A31.9,31.9,0,0,1,224,148.3V184L47.4,134.5A31.9,31.9,0,0,1,24,103.7Z" />
          </g>
          <path d="M248,216a8,8,0,0,1-8,8H96a8,8,0,0,1,0-16H240A8,8,0,0,1,248,216Zm-26.2-24.3L45.2,142.2A40.1,40.1,0,0,1,16,103.7V51.1A16,16,0,0,1,37.1,35.9l13.4,4.5a8.3,8.3,0,0,1,4.6,3.8L69.5,71.3,88,77.1v-26a16,16,0,0,1,21.1-15.2l13.4,4.5a7.5,7.5,0,0,1,4.4,3.6l30.4,53.2,45.4,12.6A40,40,0,0,1,232,148.3V184a8.1,8.1,0,0,1-3.2,6.4A8.4,8.4,0,0,1,224,192ZM216,148.3a24.1,24.1,0,0,0-17.6-23.1l-48.5-13.5a7.9,7.9,0,0,1-4.8-3.7L114.6,54.6,104,51.1V88a7.9,7.9,0,0,1-3.3,6.4,7.6,7.6,0,0,1-7.1,1.2l-32-10a8.1,8.1,0,0,1-4.7-3.8L42.4,54.6,32,51.1v52.6a24,24,0,0,0,17.5,23.1L216,173.5Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M248,216a8,8,0,0,1-8,8H96a8,8,0,0,1,0-16H240A8,8,0,0,1,248,216Zm-24-28a4.1,4.1,0,0,0,2.4-.8A4,4,0,0,0,228,184V148.3a36.1,36.1,0,0,0-26.4-34.7l-46.9-13L123.5,46a4.3,4.3,0,0,0-2.2-1.8l-13.5-4.5A11.9,11.9,0,0,0,97,41.4a11.6,11.6,0,0,0-5,9.7V82.6L66.8,74.7,51.5,46.1a4.1,4.1,0,0,0-2.2-1.9L35.8,39.7A11.9,11.9,0,0,0,25,41.4a11.6,11.6,0,0,0-5,9.7v52.6a36.1,36.1,0,0,0,26.3,34.7l176.6,49.5Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M246,216a6,6,0,0,1-6,6H96a6,6,0,0,1,0-12H240A6,6,0,0,1,246,216Zm-23.6-26.2L45.8,140.3A38.1,38.1,0,0,1,18,103.7V51.1A14,14,0,0,1,36.4,37.8l13.5,4.5a5.8,5.8,0,0,1,3.4,2.9L68.1,73,90,79.8V51.1a14,14,0,0,1,18.4-13.3l13.5,4.5a5.9,5.9,0,0,1,3.3,2.7L156,98.9l46.2,12.8A38.1,38.1,0,0,1,230,148.3V184a6.1,6.1,0,0,1-2.4,4.8A5.8,5.8,0,0,1,224,190ZM218,148.3a26,26,0,0,0-19-25l-48.6-13.5a5.9,5.9,0,0,1-3.6-2.8L115.9,53l-11.3-3.8a2,2,0,0,0-1.8.3,1.9,1.9,0,0,0-.8,1.6V88a6,6,0,0,1-2.4,4.8,5.9,5.9,0,0,1-5.4.9l-32-10a5.6,5.6,0,0,1-3.5-2.9L43.8,52.9,32.6,49.2H32a2.1,2.1,0,0,0-1.2.4,1.9,1.9,0,0,0-.8,1.6v52.6a26.2,26.2,0,0,0,19,25.1l169,47.3Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M244,216a4,4,0,0,1-4,4H96a4,4,0,0,1,0-8H240A4,4,0,0,1,244,216Zm-21.1-28.1L46.3,138.4A36.1,36.1,0,0,1,20,103.7V51.1a11.6,11.6,0,0,1,5-9.7,11.9,11.9,0,0,1,10.8-1.7l13.5,4.5a4.1,4.1,0,0,1,2.2,1.9L66.8,74.7,92,82.6V51.1a11.6,11.6,0,0,1,5-9.7,11.9,11.9,0,0,1,10.8-1.7l13.5,4.5a4.3,4.3,0,0,1,2.2,1.8l31.2,54.6,46.9,13A36.1,36.1,0,0,1,228,148.3V184a4,4,0,0,1-1.6,3.2,4.1,4.1,0,0,1-2.4.8ZM220,148.3a28.1,28.1,0,0,0-20.5-27l-48.6-13.4a4.1,4.1,0,0,1-2.4-1.9L117.3,51.3l-12-4a3.8,3.8,0,0,0-3.6.6,3.7,3.7,0,0,0-1.7,3.2V88a4,4,0,0,1-5.2,3.8l-32-10a3.9,3.9,0,0,1-2.3-1.9L45.2,51.3l-11.9-4L32,47.1a4,4,0,0,0-2.3.8A3.7,3.7,0,0,0,28,51.1v52.6a28.1,28.1,0,0,0,20.5,27l171.5,48Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M248,216a8,8,0,0,1-8,8H96a8,8,0,0,1,0-16H240A8,8,0,0,1,248,216Zm-26.2-24.3L45.2,142.2A40.1,40.1,0,0,1,16,103.7V51.1A16,16,0,0,1,37.1,35.9l13.4,4.5a8.3,8.3,0,0,1,4.6,3.8L69.5,71.3,88,77.1v-26a16,16,0,0,1,21.1-15.2l13.4,4.5a7.5,7.5,0,0,1,4.4,3.6l30.4,53.2,45.4,12.6A40,40,0,0,1,232,148.3V184a8.1,8.1,0,0,1-3.2,6.4A8.4,8.4,0,0,1,224,192ZM216,148.3a24.1,24.1,0,0,0-17.6-23.1l-48.5-13.5a7.9,7.9,0,0,1-4.8-3.7L114.6,54.6,104,51.1V88a7.9,7.9,0,0,1-3.3,6.4,7.6,7.6,0,0,1-7.1,1.2l-32-10a8.1,8.1,0,0,1-4.7-3.8L42.4,54.6,32,51.1v52.6a24,24,0,0,0,17.5,23.1L216,173.5Z" />
        </>
      )}
    </svg>
  );
}

export function PhAirplaneTakeoff(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M180,216a12,12,0,0,1-12,12H24a12,12,0,0,1,0-24H168A12,12,0,0,1,180,216ZM255.8,94.1a11.9,11.9,0,0,1-5.8,8.3l-151.4,88a44.3,44.3,0,0,1-22.2,6,43.7,43.7,0,0,1-30-11.9L8.7,149.1h0a19.9,19.9,0,0,1-6-18,20.2,20.2,0,0,1,11.8-15L31.3,109a11.8,11.8,0,0,1,9.4,0l22.5,9.6,8.4-4.8L53.3,97.3A20,20,0,0,1,58.8,64l16.5-7a11.9,11.9,0,0,1,8.5-.4l54.8,18.3,39.7-23.3a43.7,43.7,0,0,1,56.3,10l18.7,22.8A12.1,12.1,0,0,1,255.8,94.1Zm-30-5.4-9.7-11.9a19.9,19.9,0,0,0-25.6-4.6L146.1,98.3a11.9,11.9,0,0,1-9.9,1.1L80.5,80.8,74,83.6l26,23.5a12,12,0,0,1-2,19.3l-28,16a11.9,11.9,0,0,1-10.7.6L36,133.1l-6.4,2.7L62.8,167a20.2,20.2,0,0,0,23.8,2.7Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M16.9,140.4l37.7,35.3a32,32,0,0,0,38,4.3L244,92,225.4,69.2a32,32,0,0,0-41-7.3L140,88,80,68,63.5,75.1a8,8,0,0,0-2.2,13.3L92,116,64,132,36,120l-16.8,7.2A8,8,0,0,0,16.9,140.4Z" />
          </g>
          <path d="M176,216a8,8,0,0,1-8,8H24a8,8,0,0,1,0-16H168A8,8,0,0,1,176,216ZM251.9,93.4a8.2,8.2,0,0,1-3.9,5.5l-151.4,88a39.6,39.6,0,0,1-47.4-5.4L11.5,146.2h0a15.9,15.9,0,0,1-4.9-14.4,16.2,16.2,0,0,1,9.5-12l16.7-7.2a8.8,8.8,0,0,1,6.4,0l24.3,10.5,14.9-8.6L55.9,94.3a16.1,16.1,0,0,1,4.4-26.6l16.5-7.1a8.6,8.6,0,0,1,5.7-.2l56.6,18.9L180.3,55a39.8,39.8,0,0,1,51.3,9.2l18.6,22.7A8,8,0,0,1,251.9,93.4Zm-20-3.6L219.2,74.3a24,24,0,0,0-30.8-5.5L144.1,94.9a8,8,0,0,1-6.6.7l-57.1-19L66.7,82.4l30.7,27.7a8.2,8.2,0,0,1,2.6,6.8,8,8,0,0,1-4,6l-28,16a8.3,8.3,0,0,1-7.2.5L36,128.7l-13.6,5.8,37.7,35.4a23.8,23.8,0,0,0,28.5,3.2Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M176,216a8,8,0,0,1-8,8H24a8,8,0,0,1,0-16H168A8,8,0,0,1,176,216ZM247.1,89.5,228.5,66.7a35.9,35.9,0,0,0-46.2-8.2L139.5,83.6,81.3,64.2a4.1,4.1,0,0,0-2.9.1L61.9,71.4a12,12,0,0,0-3.3,19.9l26.6,24L63.7,127.5,37.6,116.3a4.4,4.4,0,0,0-3.2,0l-16.7,7.2a12,12,0,0,0-3.5,19.8h0l37.7,35.3a35.8,35.8,0,0,0,42.7,4.9L246,95.5a4,4,0,0,0,1.9-2.8A4.1,4.1,0,0,0,247.1,89.5Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M174,216a6,6,0,0,1-6,6H24a6,6,0,0,1,0-12H168A6,6,0,0,1,174,216ZM249.9,93.1a6.1,6.1,0,0,1-2.9,4.1l-151.4,88a37.8,37.8,0,0,1-45.1-5.1L12.8,144.7h0a14,14,0,0,1-4.2-12.6,13.6,13.6,0,0,1,8.3-10.4l16.7-7.2a5.7,5.7,0,0,1,4.8,0l25.2,10.8,18.2-10.4L57.3,92.8a14.1,14.1,0,0,1-4.5-12.7,13.8,13.8,0,0,1,8.3-10.5l16.5-7.1a5.8,5.8,0,0,1,4.3-.2l57.4,19.1,42-24.7A37.9,37.9,0,0,1,230,65.4l18.6,22.8A5.8,5.8,0,0,1,249.9,93.1Zm-15-2.7L220.7,73a25.8,25.8,0,0,0-33.3-5.9L143,93.2a6,6,0,0,1-4.9.5L80.3,74.4,65.9,80.6a2,2,0,0,0-.6,3.3L96,111.5a6.2,6.2,0,0,1,2,5.1,6,6,0,0,1-3,4.6l-28,16a6,6,0,0,1-5.4.3L36,126.5l-14.4,6.2a2,2,0,0,0-1.2,1.5A2.1,2.1,0,0,0,21,136l37.7,35.3a25.8,25.8,0,0,0,30.9,3.5Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M172,216a4,4,0,0,1-4,4H24a4,4,0,0,1,0-8H168A4,4,0,0,1,172,216ZM247.9,92.7a4,4,0,0,1-1.9,2.8l-151.4,88a35.8,35.8,0,0,1-42.7-4.9L14.2,143.3h0a12,12,0,0,1,3.5-19.8l16.7-7.2a4.4,4.4,0,0,1,3.2,0l26.1,11.2,21.5-12.2-26.6-24a12,12,0,0,1,3.3-19.9l16.5-7.1a4.1,4.1,0,0,1,2.9-.1l58.2,19.4,42.8-25.1a35.9,35.9,0,0,1,46.2,8.2l18.6,22.8A4.1,4.1,0,0,1,247.9,92.7Zm-10-1.8L222.3,71.8a27.9,27.9,0,0,0-35.9-6.5L142,91.4a3.9,3.9,0,0,1-3.3.4L80.2,72.3,65.1,78.7a4,4,0,0,0-2.4,3.1A3.8,3.8,0,0,0,64,85.4L94.7,113a4.3,4.3,0,0,1,1.3,3.4,4.4,4.4,0,0,1-2,3.1l-28,16a4.1,4.1,0,0,1-3.6.2L36,124.4l-15.2,6.5a3.8,3.8,0,0,0-2.3,2.9,4,4,0,0,0,1.2,3.7l37.7,35.3a27.9,27.9,0,0,0,33.2,3.8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M176,216a8,8,0,0,1-8,8H24a8,8,0,0,1,0-16H168A8,8,0,0,1,176,216ZM251.9,93.4a8.2,8.2,0,0,1-3.9,5.5l-151.4,88a39.6,39.6,0,0,1-47.4-5.4L11.5,146.2h0a15.9,15.9,0,0,1-4.9-14.4,16.2,16.2,0,0,1,9.5-12l16.7-7.2a8.8,8.8,0,0,1,6.4,0l24.3,10.5,14.9-8.6L55.9,94.3a16.1,16.1,0,0,1,4.4-26.6l16.5-7.1a8.6,8.6,0,0,1,5.7-.2l56.6,18.9L180.3,55a39.8,39.8,0,0,1,51.3,9.2l18.6,22.7A8,8,0,0,1,251.9,93.4Zm-20-3.6L219.2,74.3a24,24,0,0,0-30.8-5.5L144.1,94.9a8,8,0,0,1-6.6.7l-57.1-19L66.7,82.4l30.7,27.7a8.2,8.2,0,0,1,2.6,6.8,8,8,0,0,1-4,6l-28,16a8.3,8.3,0,0,1-7.2.5L36,128.7l-13.6,5.8,37.7,35.4a23.8,23.8,0,0,0,28.5,3.2Z" />
        </>
      )}
    </svg>
  );
}

export function PhAirplaneTilt(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M190,115.3l27.9-26.1,1.7-2.1a36,36,0,0,0-53.1-48.6L165.3,40,140.7,66,60.1,36.7a12,12,0,0,0-12.6,2.8l-24,24A12.2,12.2,0,0,0,25.3,82l59.9,39.9L75,132H56a12.3,12.3,0,0,0-8.5,3.5l-24,24a12,12,0,0,0,4.7,19.9l36.1,12.2,12.3,36.3a12.2,12.2,0,0,0,8.7,7.8,12.4,12.4,0,0,0,2.7.3,12,12,0,0,0,8.5-3.5l24-24A12.3,12.3,0,0,0,124,200V181.3l10.1-9.1,40,58.6a12.1,12.1,0,0,0,18.4,1.7l24-24a12,12,0,0,0,2.8-12.6Zm-4.3,90-39.6-58a12.1,12.1,0,0,0-8.5-5.2h-1.4a12,12,0,0,0-8.1,3.1l-24.2,22A12,12,0,0,0,100,176v19l-6.8,6.9-8-23.6a12.1,12.1,0,0,0-7.5-7.5l-23.6-8L61,156H80a12.3,12.3,0,0,0,8.5-3.5l24-24a12.2,12.2,0,0,0-1.8-18.5L50.8,70.1l8.3-8.2,80.8,29.4a12,12,0,0,0,12.8-3.1l31.7-33.5c.1,0,.1,0,.1-.1a12.2,12.2,0,0,1,16,.9,12,12,0,0,1,0,17c-.2.2-.4.3-.5.5l-32.2,30.3a12,12,0,0,0-3.1,12.8l29.4,80.8Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M209.7,80.4,176,112l32,88-24,24-47.8-70L112,176v24L88,224,73.8,182.1,32,168l24-24H80l24-24L32,72,56,48l88,32,31.7-33.5-.7.5a24,24,0,0,1,34,34Z"
            opacity="0.2"
          />
          <path d="M216.4,84.7a32,32,0,0,0-47-43.3,5.2,5.2,0,0,0-1,1.1L141.8,70.7,58.7,40.5a7.9,7.9,0,0,0-8.4,1.8l-24,24A8.2,8.2,0,0,0,24,72.8a8.4,8.4,0,0,0,3.6,5.9l63.8,42.5L76.7,136H56a8.1,8.1,0,0,0-5.7,2.3l-24,24a8.3,8.3,0,0,0-2.1,7.5,8,8,0,0,0,5.2,5.8l38.1,12.9,12.9,38.1a8,8,0,0,0,5.8,5.2l1.8.2a8.3,8.3,0,0,0,5.7-2.3l24-24A8.1,8.1,0,0,0,120,200V179.5l14.8-13.4,42.6,62.4a8.1,8.1,0,0,0,5.9,3.5,8.5,8.5,0,0,0,6.4-2.3l24-24a7.9,7.9,0,0,0,1.8-8.4l-30.2-83.1,29.8-28A6.4,6.4,0,0,0,216.4,84.7Zm-13.1-9.4-.4.5-32.4,30.4a7.9,7.9,0,0,0-2,8.5l30.2,83.2-13.5,13.6-42.4-62a7.8,7.8,0,0,0-5.7-3.4h-.9a8.3,8.3,0,0,0-5.4,2.1l-24.2,22A8.1,8.1,0,0,0,104,176v20.7L91.4,209.2l-10-29.6a8,8,0,0,0-5-5L46.8,164.5,59.3,152H80a8.1,8.1,0,0,0,5.7-2.3l24-24a8.2,8.2,0,0,0,2.3-6.5,8.4,8.4,0,0,0-3.6-5.9L44.6,70.8,58.1,57.3l83.2,30.2a7.9,7.9,0,0,0,8.5-2L181.5,52l.2-.3a16.1,16.1,0,0,1,21.6,1A15.9,15.9,0,0,1,203.3,75.3Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M215.1,86.2l-29.8,28,30.2,83.1a7.9,7.9,0,0,1-1.8,8.4l-24,24a8.5,8.5,0,0,1-6.4,2.3,8.1,8.1,0,0,1-5.9-3.5l-42.6-62.3L116,183.5V204a8.1,8.1,0,0,1-2.3,5.7l-20,20A8.3,8.3,0,0,1,88,232l-1.8-.2a8,8,0,0,1-5.8-5.2L67.5,188.5,29.4,175.6a8,8,0,0,1-5.2-5.8,8.3,8.3,0,0,1,2.1-7.5l20-20A8.1,8.1,0,0,1,52,140H72.7l18.7-18.8L27.6,78.7A8.4,8.4,0,0,1,24,72.8a8.2,8.2,0,0,1,2.3-6.5l24-24a7.9,7.9,0,0,1,8.4-1.8l83.1,30.2,26.6-28.2a5.2,5.2,0,0,1,1-1.1,32,32,0,0,1,47,43.3A6.4,6.4,0,0,1,215.1,86.2Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M214.8,83.5a30,30,0,0,0-44-40.7,3.9,3.9,0,0,0-.8,1L142.3,73,58.1,42.4a5.9,5.9,0,0,0-6.3,1.4l-24,24A6,6,0,0,0,26,72.6,6.1,6.1,0,0,0,28.7,77l65.9,43.9L77.5,138H56a5.6,5.6,0,0,0-4.2,1.8l-24,24a5.7,5.7,0,0,0-1.6,5.6,5.8,5.8,0,0,0,3.9,4.3l39,13.2,13.2,39a5.8,5.8,0,0,0,4.3,3.9l1.4.2a5.6,5.6,0,0,0,4.2-1.8l24-24A5.6,5.6,0,0,0,118,200V178.7l17.1-15.6L179,227.4a6.4,6.4,0,0,0,4.4,2.6,6,6,0,0,0,4.8-1.8l24-24a5.9,5.9,0,0,0,1.4-6.3L183,113.7l30.8-28.9A6.4,6.4,0,0,0,214.8,83.5Zm-10.1-6.8-.4.5-32.4,30.4a5.9,5.9,0,0,0-1.5,6.5l30.7,84.4-16.2,16.1-43.8-64a5.8,5.8,0,0,0-4.2-2.5h-.7a5.9,5.9,0,0,0-4,1.6l-24.2,22a5.7,5.7,0,0,0-2,4.4v21.5L90.6,212.9,79.5,180.2a5.8,5.8,0,0,0-3.8-3.7L43.1,165.4,58.5,150H80a5.6,5.6,0,0,0,4.2-1.8l24-24a6,6,0,0,0,1.8-4.8,6.1,6.1,0,0,0-2.7-4.4L41.4,71.1,57.5,54.9l84.4,30.7a5.9,5.9,0,0,0,6.5-1.5l31.7-33.5.2-.3a18,18,0,0,1,24.4,26.4Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M211.8,44.2a28.1,28.1,0,0,0-39.6,0c-.2.3-.5.5-.6.8L142.9,75.3,57.4,44.2a4.1,4.1,0,0,0-4.2,1l-24,24a3.9,3.9,0,0,0,.6,6.1l67.9,45.3L78.3,140H56a3.6,3.6,0,0,0-2.8,1.2l-24,24a3.9,3.9,0,0,0-1.1,3.7,4,4,0,0,0,2.6,2.9l40,13.5,13.5,40a4,4,0,0,0,2.9,2.6H88a3.8,3.8,0,0,0,2.8-1.2l24-24A3.6,3.6,0,0,0,116,200V177.8l19.5-17.7,45.2,66.2a4.2,4.2,0,0,0,2.9,1.7,3.8,3.8,0,0,0,3.2-1.2l24-24a4.1,4.1,0,0,0,1-4.2l-31.1-85.5,31.7-29.8.7-.9A28.1,28.1,0,0,0,211.8,44.2Zm-5.7,33.9-.3.4-32.5,30.6a4.1,4.1,0,0,0-1.1,4.3L203.4,199l-18.8,18.8-45.1-66a4.3,4.3,0,0,0-2.8-1.8h-.5a3.9,3.9,0,0,0-2.7,1.1L109.3,173a4.3,4.3,0,0,0-1.3,3v22.3L89.7,216.6,77.6,180.9a3.9,3.9,0,0,0-2.5-2.5L39.4,166.3,57.7,148H80a3.6,3.6,0,0,0,2.8-1.2l24-24a3.9,3.9,0,0,0-.6-6.1L38.3,71.4,57,52.6l85.6,31.2a4.1,4.1,0,0,0,4.3-1.1l31.7-33.5.3-.3a20,20,0,0,1,27.2,29.2Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216.4,84.7a32,32,0,0,0-47-43.3,5.2,5.2,0,0,0-1,1.1L141.8,70.7,58.7,40.5a7.9,7.9,0,0,0-8.4,1.8l-24,24A8.2,8.2,0,0,0,24,72.8a8.4,8.4,0,0,0,3.6,5.9l63.8,42.5L76.7,136H56a8.1,8.1,0,0,0-5.7,2.3l-24,24a8.3,8.3,0,0,0-2.1,7.5,8,8,0,0,0,5.2,5.8l38.1,12.9,12.9,38.1a8,8,0,0,0,5.8,5.2l1.8.2a8.3,8.3,0,0,0,5.7-2.3l24-24A8.1,8.1,0,0,0,120,200V179.5l14.8-13.4,42.6,62.4a8.1,8.1,0,0,0,5.9,3.5,8.5,8.5,0,0,0,6.4-2.3l24-24a7.9,7.9,0,0,0,1.8-8.4l-30.2-83.1,29.8-28A6.4,6.4,0,0,0,216.4,84.7Zm-13.1-9.4-.4.5-32.4,30.4a7.9,7.9,0,0,0-2,8.5l30.2,83.2-13.5,13.6-42.4-62a7.8,7.8,0,0,0-5.7-3.4h-.9a8.3,8.3,0,0,0-5.4,2.1l-24.2,22A8.1,8.1,0,0,0,104,176v20.7L91.4,209.2l-10-29.6a8,8,0,0,0-5-5L46.8,164.5,59.3,152H80a8.1,8.1,0,0,0,5.7-2.3l24-24a8.2,8.2,0,0,0,2.3-6.5,8.4,8.4,0,0,0-3.6-5.9L44.6,70.8,58.1,57.3l83.2,30.2a7.9,7.9,0,0,0,8.5-2L181.5,52l.2-.3a16.1,16.1,0,0,1,21.6,1A15.9,15.9,0,0,1,203.3,75.3Z" />
        </>
      )}
    </svg>
  );
}

export function PhAirplane(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M237.6,125.3,164.2,88.6V48a36,36,0,0,0-72,0V88.6L18.6,125.3A12.1,12.1,0,0,0,12,136v32a12,12,0,0,0,14.4,11.8L92,166.6V179L79.5,191.5A12.3,12.3,0,0,0,76,200v32a12,12,0,0,0,16.5,11.1L128,228.9l35.5,14.2a12,12,0,0,0,4.5.9,11.6,11.6,0,0,0,6.7-2.1A11.9,11.9,0,0,0,180,232V200a11.5,11.5,0,0,0-3.5-8.4l-12.3-12.5V166.6l65.7,13.2a11.8,11.8,0,0,0,9.9-2.5,12,12,0,0,0,4.4-9.3V136A11.9,11.9,0,0,0,237.6,125.3Zm-17.4,28.1-65.6-13.2A12,12,0,0,0,140.2,152v32a11.9,11.9,0,0,0,3.5,8.4L156,204.9v9.4l-23.5-9.4a11.7,11.7,0,0,0-9,0L100,214.3V205l12.5-12.5A12.3,12.3,0,0,0,116,184V152a12,12,0,0,0-14.4-11.8L36,153.4v-10l73.4-36.7A11.9,11.9,0,0,0,116,96V48a12,12,0,0,1,24,0V96a11.9,11.9,0,0,0,6.7,10.7l73.3,36.7Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M152.2,152v32L168,200v32l-40-16L88,232V200l16-16V152L24,168V136l80-40V48a24,24,0,0,1,48,0V96l80,40v32Z"
            opacity="0.2"
          />
          <path d="M235.8,128.8,160.2,91V48a32,32,0,0,0-64,0V91L20.4,128.8A8.2,8.2,0,0,0,16,136v32a7.9,7.9,0,0,0,9.6,7.8l70.4-14v18.9L82.3,194.3A8.1,8.1,0,0,0,80,200v32a7.9,7.9,0,0,0,3.5,6.6,8,8,0,0,0,7.5.8l37-14.8,37,14.8a8,8,0,0,0,3,.6,8.2,8.2,0,0,0,4.5-1.4A7.9,7.9,0,0,0,176,232V200a7.9,7.9,0,0,0-2.3-5.6l-13.5-13.7V161.8l70.5,14a7.9,7.9,0,0,0,6.6-1.6,7.8,7.8,0,0,0,2.9-6.2V136A8.2,8.2,0,0,0,235.8,128.8Zm-11.6,29.4-70.4-14a7.8,7.8,0,0,0-6.6,1.6,7.9,7.9,0,0,0-3,6.2v32a8.3,8.3,0,0,0,2.3,5.6L160,203.3v16.9l-29-11.6a7.8,7.8,0,0,0-6,0L96,220.2V203.3l13.7-13.6A8.1,8.1,0,0,0,112,184V152a7.9,7.9,0,0,0-9.6-7.8L32,158.2V140.9l75.6-37.7A8.2,8.2,0,0,0,112,96V48a16,16,0,0,1,32,0V96a8.2,8.2,0,0,0,4.4,7.2l75.6,37.7Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M240.2,136v32a7.8,7.8,0,0,1-2.9,6.2,7.9,7.9,0,0,1-6.6,1.6l-70.5-14v26.9l13.5,13.7A7.9,7.9,0,0,1,176,208v24a7.9,7.9,0,0,1-3.5,6.6A8.2,8.2,0,0,1,168,240a8,8,0,0,1-3-.6l-37-14.8L91,239.4a8,8,0,0,1-7.5-.8A7.9,7.9,0,0,1,80,232V208a8.1,8.1,0,0,1,2.3-5.7L96,188.7V161.8l-70.4,14A7.9,7.9,0,0,1,16,168V136a8.2,8.2,0,0,1,4.4-7.2L96,91V48a32,32,0,0,1,64,0V91l75.6,37.8A8.2,8.2,0,0,1,240.2,136Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M234.9,130.6,158.2,92.3V48a30,30,0,0,0-60,0V92.3L21.3,130.6A6,6,0,0,0,18,136v32a5.8,5.8,0,0,0,2.2,4.6,6,6,0,0,0,5,1.3L98,159.3v22.2L83.8,195.8A5.6,5.6,0,0,0,82,200v32a6.1,6.1,0,0,0,2.6,5,6.2,6.2,0,0,0,5.6.6L128,222.5l37.8,15.1a5.4,5.4,0,0,0,2.2.4,5.9,5.9,0,0,0,3.4-1,6.1,6.1,0,0,0,2.6-5V200a5.9,5.9,0,0,0-1.7-4.2l-14.1-14.3V159.3L231,173.9a6,6,0,0,0,5-1.3,5.8,5.8,0,0,0,2.2-4.6V136A6,6,0,0,0,234.9,130.6Zm-8.7,30.1-72.8-14.6a6,6,0,0,0-5,1.3,5.8,5.8,0,0,0-2.2,4.6v32a5.6,5.6,0,0,0,1.8,4.2l14,14.3v20.6l-31.8-12.7a6.6,6.6,0,0,0-4.4,0L94,223.1V202.5l14.2-14.3A5.6,5.6,0,0,0,110,184V152a5.8,5.8,0,0,0-2.2-4.6,6,6,0,0,0-5-1.3L30,160.7v-21l76.7-38.3A6,6,0,0,0,110,96V48a18,18,0,0,1,36,0V96a6,6,0,0,0,3.3,5.4l76.7,38.3Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M234,132.4,156.2,93.5V48a28,28,0,0,0-56,0V93.5L22.2,132.4A4.2,4.2,0,0,0,20,136v32a3.9,3.9,0,0,0,1.5,3.1,3.6,3.6,0,0,0,3.3.8l75.2-15v25.4L85.2,197.2A3.6,3.6,0,0,0,84,200v32a4,4,0,0,0,1.8,3.3,3.8,3.8,0,0,0,3.7.4L128,220.3l38.5,15.4a4,4,0,0,0,1.5.3,3.4,3.4,0,0,0,2.2-.7A4,4,0,0,0,172,232V200a3.6,3.6,0,0,0-1.2-2.8l-14.6-14.8V156.9l75.2,15a3.9,3.9,0,0,0,3.4-.8,4.1,4.1,0,0,0,1.4-3.1V136A4,4,0,0,0,234,132.4Zm-5.8,30.7-75.2-15a3.6,3.6,0,0,0-3.3.8,4.2,4.2,0,0,0-1.5,3.1v32a3.6,3.6,0,0,0,1.2,2.8L164,201.6v24.5l-34.5-13.8a3.9,3.9,0,0,0-3,0L92,226.1V201.7l14.8-14.9A3.6,3.6,0,0,0,108,184V152a3.9,3.9,0,0,0-1.5-3.1,3.6,3.6,0,0,0-3.3-.8L28,163.1V138.5l77.8-38.9A4.2,4.2,0,0,0,108,96V48a20,20,0,0,1,40,0V96a4.2,4.2,0,0,0,2.2,3.6l77.8,38.9Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M235.8,128.8,160.2,91V48a32,32,0,0,0-64,0V91L20.4,128.8A8.2,8.2,0,0,0,16,136v32a7.9,7.9,0,0,0,9.6,7.8l70.4-14v18.9L82.3,194.3A8.1,8.1,0,0,0,80,200v32a7.9,7.9,0,0,0,3.5,6.6,8,8,0,0,0,7.5.8l37-14.8,37,14.8a8,8,0,0,0,3,.6,8.2,8.2,0,0,0,4.5-1.4A7.9,7.9,0,0,0,176,232V200a7.9,7.9,0,0,0-2.3-5.6l-13.5-13.7V161.8l70.5,14a7.9,7.9,0,0,0,6.6-1.6,7.8,7.8,0,0,0,2.9-6.2V136A8.2,8.2,0,0,0,235.8,128.8Zm-11.6,29.4-70.4-14a7.8,7.8,0,0,0-6.6,1.6,7.9,7.9,0,0,0-3,6.2v32a8.3,8.3,0,0,0,2.3,5.6L160,203.3v16.9l-29-11.6a7.8,7.8,0,0,0-6,0L96,220.2V203.3l13.7-13.6A8.1,8.1,0,0,0,112,184V152a7.9,7.9,0,0,0-9.6-7.8L32,158.2V140.9l75.6-37.7A8.2,8.2,0,0,0,112,96V48a16,16,0,0,1,32,0V96a8.2,8.2,0,0,0,4.4,7.2l75.6,37.7Z" />
        </>
      )}
    </svg>
  );
}

export function PhAirplay(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M137.1,152.2a12,12,0,0,0-18.2,0l-48,56A11.8,11.8,0,0,0,69.1,221,12,12,0,0,0,80,228h96a12,12,0,0,0,10.9-7,11.8,11.8,0,0,0-1.8-12.8Zm-31,51.8L128,178.4,149.9,204ZM236,64V176a28.1,28.1,0,0,1-28,28,12,12,0,0,1,0-24,4,4,0,0,0,4-4V64a4,4,0,0,0-4-4H48a4,4,0,0,0-4,4V176a4,4,0,0,0,4,4,12,12,0,0,1,0,24,28.1,28.1,0,0,1-28-28V64A28.1,28.1,0,0,1,48,36H208A28.1,28.1,0,0,1,236,64Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M208,48H48A16,16,0,0,0,32,64V176a16,16,0,0,0,16,16h52.6L128,160l27.4,32H208a16,16,0,0,0,16-16V64A16,16,0,0,0,208,48Z" />
          </g>
          <path d="M134.1,154.8a8,8,0,0,0-12.2,0l-48,56A8,8,0,0,0,80,224h96a8,8,0,0,0,6.1-13.2ZM97.4,208,128,172.3,158.6,208Z" />
          <path d="M208,40H48A24.1,24.1,0,0,0,24,64V176a24.1,24.1,0,0,0,24,24H64a8,8,0,0,0,0-16H48a8,8,0,0,1-8-8V64a8,8,0,0,1,8-8H208a8,8,0,0,1,8,8V176a8,8,0,0,1-8,8H192a8,8,0,0,0,0,16h16a24.1,24.1,0,0,0,24-24V64A24.1,24.1,0,0,0,208,40Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M182.1,210.8A8,8,0,0,1,176,224H80a8,8,0,0,1-6.1-13.2l48-56a8,8,0,0,1,12.2,0ZM208,40H48A23.9,23.9,0,0,0,24,64V176a23.9,23.9,0,0,0,24,24H60.3a4.1,4.1,0,0,0,3-1.4l46.5-54.2a23.9,23.9,0,0,1,36.4,0l46.5,54.2a4.1,4.1,0,0,0,3,1.4H208a23.9,23.9,0,0,0,24-24V64A23.9,23.9,0,0,0,208,40Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M132.6,156.1a6.1,6.1,0,0,0-9.2,0l-48,56A6,6,0,0,0,80,222h96a6.1,6.1,0,0,0,4.6-9.9ZM93,210l35-40.8L163,210ZM230,64V176a22.1,22.1,0,0,1-22,22H192a6,6,0,0,1,0-12h16a10,10,0,0,0,10-10V64a10,10,0,0,0-10-10H48A10,10,0,0,0,38,64V176a10,10,0,0,0,10,10H64a6,6,0,0,1,0,12H48a22.1,22.1,0,0,1-22-22V64A22.1,22.1,0,0,1,48,42H208A22.1,22.1,0,0,1,230,64Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M131,157.4a3.9,3.9,0,0,0-6,0l-48,56a3.8,3.8,0,0,0-.6,4.3A3.9,3.9,0,0,0,80,220h96a3.9,3.9,0,0,0,3.6-2.3,3.8,3.8,0,0,0-.6-4.3ZM88.7,212,128,166.1,167.3,212ZM228,64V176a20.1,20.1,0,0,1-20,20H192a4,4,0,0,1,0-8h16a12,12,0,0,0,12-12V64a12,12,0,0,0-12-12H48A12,12,0,0,0,36,64V176a12,12,0,0,0,12,12H64a4,4,0,0,1,0,8H48a20.1,20.1,0,0,1-20-20V64A20.1,20.1,0,0,1,48,44H208A20.1,20.1,0,0,1,228,64Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M134.1,154.8a8,8,0,0,0-12.2,0l-48,56A8,8,0,0,0,80,224h96a8,8,0,0,0,6.1-13.2ZM97.4,208,128,172.3,158.6,208ZM232,64V176a24.1,24.1,0,0,1-24,24H192a8,8,0,0,1,0-16h16a8,8,0,0,0,8-8V64a8,8,0,0,0-8-8H48a8,8,0,0,0-8,8V176a8,8,0,0,0,8,8H64a8,8,0,0,1,0,16H48a24.1,24.1,0,0,1-24-24V64A24.1,24.1,0,0,1,48,40H208A24.1,24.1,0,0,1,232,64Z" />
        </>
      )}
    </svg>
  );
}

export function PhAlarm(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M176,116a12,12,0,0,1,0,24H128a12,12,0,0,1-12-12V80a12,12,0,0,1,24,0v36Zm22.7-58.7A100,100,0,1,1,57.3,198.7,100,100,0,1,1,198.7,57.3ZM204,128A75.9,75.9,0,0,0,74.3,74.3,75.9,75.9,0,1,0,181.7,181.7,75.2,75.2,0,0,0,204,128Zm40-82L210,12a11.9,11.9,0,0,0-16.9,0,12,12,0,0,0,0,17L227,62.9a11.7,11.7,0,0,0,8.5,3.6A12.1,12.1,0,0,0,244,46ZM62.9,29a12,12,0,0,0,0-17A11.9,11.9,0,0,0,46,12L12,46a12.1,12.1,0,0,0,8.5,20.5A11.7,11.7,0,0,0,29,62.9Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="128" cy="128" r="88" />
          </g>
          <path d="M136,120V72a8,8,0,0,0-16,0v56a8,8,0,0,0,8,8h56a8,8,0,0,0,0-16Z" />
          <path d="M128,32a95.9,95.9,0,1,0,67.9,28.1A95.5,95.5,0,0,0,128,32Zm56.6,152.6A80,80,0,0,1,71.4,71.4h0A80,80,0,0,1,184.6,184.6Z" />
          <path d="M235.5,54.5l-34-34a8,8,0,1,0-11.3,11.3l34,34a8,8,0,0,0,5.6,2.3,8.3,8.3,0,0,0,5.7-2.3A8,8,0,0,0,235.5,54.5Z" />
          <path d="M65.8,20.5a8,8,0,0,0-11.3,0l-34,34a8,8,0,0,0,0,11.3,8.3,8.3,0,0,0,5.7,2.3,8,8,0,0,0,5.6-2.3l34-34A8,8,0,0,0,65.8,20.5Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M235.5,65.8a8.3,8.3,0,0,1-5.7,2.3,8,8,0,0,1-5.6-2.3l-34-34a8,8,0,1,1,11.3-11.3l34,34A8,8,0,0,1,235.5,65.8ZM65.8,20.5a8,8,0,0,0-11.3,0l-34,34a8,8,0,0,0,0,11.3,8.3,8.3,0,0,0,5.7,2.3,8,8,0,0,0,5.6-2.3l34-34A8,8,0,0,0,65.8,20.5ZM224,125.5v5.3a14.8,14.8,0,0,0-.1,2.1h0A96,96,0,0,1,133,223.9H123A96,96,0,0,1,32.1,133h0a14.8,14.8,0,0,0-.1-2.1v-5.6a14.8,14.8,0,0,0,.1-2.1h0A96,96,0,0,1,123,32.1h10A96,96,0,0,1,223.9,123h0a14.8,14.8,0,0,0,.1,2.1ZM192,128a8,8,0,0,0-8-8H136V72a8,8,0,0,0-16,0v56a8,8,0,0,0,8,8h56A8,8,0,0,0,192,128Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M184,122a6,6,0,0,1,0,12H128a6,6,0,0,1-6-6V72a6,6,0,0,1,12,0v50Zm10.5-60.5A94.2,94.2,0,1,1,128,34,93.7,93.7,0,0,1,194.5,61.5ZM210,128a81.9,81.9,0,1,0-24,58A81.5,81.5,0,0,0,210,128Zm24.1-72.1-34-34a6,6,0,0,0-8.5,8.5l34,34a6.2,6.2,0,0,0,8.5,0A6.1,6.1,0,0,0,234.1,55.9ZM64.4,21.9a6.1,6.1,0,0,0-8.5,0l-34,34a6.1,6.1,0,0,0,0,8.5,6.2,6.2,0,0,0,8.5,0l34-34A6.1,6.1,0,0,0,64.4,21.9Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M188,128a4,4,0,0,1-4,4H128a4,4,0,0,1-4-4V72a4,4,0,0,1,8,0v52h52A4,4,0,0,1,188,128Zm5.1-65.1A92.2,92.2,0,1,1,128,36,91.8,91.8,0,0,1,193.1,62.9ZM212,128A84,84,0,0,0,68.6,68.6h0A84,84,0,1,0,212,128Zm20.7-70.7-34-34a4,4,0,0,0-5.6,0,4,4,0,0,0,0,5.7L227,62.9a3.9,3.9,0,0,0,2.8,1.2,4.2,4.2,0,0,0,2.9-1.2A4,4,0,0,0,232.7,57.3ZM62.9,23.3a4,4,0,0,0-5.6,0l-34,34a4,4,0,0,0,0,5.6,4.2,4.2,0,0,0,2.9,1.2A3.9,3.9,0,0,0,29,62.9L62.9,29A4,4,0,0,0,62.9,23.3Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M184,120a8,8,0,0,1,0,16H128a8,8,0,0,1-8-8V72a8,8,0,0,1,16,0v48Zm11.9-59.9A96.1,96.1,0,1,1,128,32,95.7,95.7,0,0,1,195.9,60.1ZM208,128a79.9,79.9,0,1,0-23.4,56.6A79.5,79.5,0,0,0,208,128Zm27.5-73.5-34-34a8,8,0,1,0-11.3,11.3l34,34a8,8,0,0,0,5.6,2.3,8.3,8.3,0,0,0,5.7-2.3A8,8,0,0,0,235.5,54.5ZM65.8,20.5a8,8,0,0,0-11.3,0l-34,34a8,8,0,0,0,0,11.3,8.3,8.3,0,0,0,5.7,2.3,8,8,0,0,0,5.6-2.3l34-34A8,8,0,0,0,65.8,20.5Z" />
        </>
      )}
    </svg>
  );
}

export function PhAlien(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,12A100.2,100.2,0,0,0,28,112c0,24.9,12.9,56.8,34.4,85.4,8.2,11,37.2,46.6,65.6,46.6s57.4-35.6,65.6-46.6C215.1,168.8,228,136.9,228,112A100.2,100.2,0,0,0,128,12Zm46.4,171c-13.8,18.4-34.2,37-46.4,37s-32.6-18.6-46.4-37C63.3,158.8,52,131.5,52,112a76,76,0,0,1,152,0C204,131.5,192.7,158.8,174.4,183ZM104,152a36,36,0,0,1-36-36v-4a12,12,0,0,1,12-12,36,36,0,0,1,36,36v4A12,12,0,0,1,104,152Zm84-40v4a36,36,0,0,1-36,36l-2-.2A11.9,11.9,0,0,1,140,140v-4a36,36,0,0,1,36-36A12,12,0,0,1,188,112Zm-36,72a12,12,0,0,1-12,12H116a12,12,0,0,1,0-24h24A12,12,0,0,1,152,184Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M128,24a88,88,0,0,0-88,88c0,48.6,56,120,88,120s88-71.4,88-120A88,88,0,0,0,128,24ZM112,140a8,8,0,0,1-8,8,32,32,0,0,1-32-32v-4a8,8,0,0,1,8-8,32,32,0,0,1,32,32Zm72-24a32,32,0,0,1-32,32,8,8,0,0,1-8-8v-4a32,32,0,0,1,32-32,8,8,0,0,1,8,8Z"
            opacity="0.2"
          />
          <path d="M128,16a96.2,96.2,0,0,0-96,96c0,24,12.6,55.1,33.6,83s44.5,45,62.4,45,41.2-16.8,62.4-45S224,136,224,112A96.2,96.2,0,0,0,128,16Zm49.6,169.4C160.2,208.5,140.3,224,128,224s-32.2-15.5-49.6-38.6C59.6,160.5,48,132.4,48,112a80,80,0,0,1,160,0C208,132.4,196.4,160.5,177.6,185.4ZM120,140v-4A40,40,0,0,0,80,96a16,16,0,0,0-16,16v4a40,40,0,0,0,40,40A16,16,0,0,0,120,140ZM80,116v-4a24.1,24.1,0,0,1,24,24v4h0A24.1,24.1,0,0,1,80,116Zm96-20a40,40,0,0,0-40,40v4a16,16,0,0,0,16,16,40,40,0,0,0,40-40v-4A16,16,0,0,0,176,96Zm0,20a24.1,24.1,0,0,1-24,24v-4a24.1,24.1,0,0,1,24-24Zm-24,68a8,8,0,0,1-8,8H112a8,8,0,0,1,0-16h32A8,8,0,0,1,152,184Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,16a96.2,96.2,0,0,0-96,96c0,24,12.6,55.1,33.6,83s44.5,45,62.4,45,41.2-16.8,62.4-45S224,136,224,112A96.2,96.2,0,0,0,128,16ZM64,116v-4a12,12,0,0,1,12-12,36,36,0,0,1,36,36v4a12,12,0,0,1-12,12A36,36,0,0,1,64,116Zm80,84H112a8,8,0,0,1,0-16h32a8,8,0,0,1,0,16Zm48-84a36,36,0,0,1-36,36,12,12,0,0,1-12-12v-4a36,36,0,0,1,36-36,12,12,0,0,1,12,12Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,18a94.1,94.1,0,0,0-94,94c0,23.6,12.4,54.2,33.2,81.8C83.3,215.2,107.7,238,128,238s44.7-22.8,60.8-44.2C209.6,166.2,222,135.6,222,112A94.1,94.1,0,0,0,128,18Zm51.2,168.6C161.5,210.2,140.9,226,128,226s-33.5-15.8-51.2-39.4C57.8,161.4,46,132.8,46,112a82,82,0,0,1,164,0C210,132.8,198.2,161.4,179.2,186.6ZM118,140v-4A38,38,0,0,0,80,98a14,14,0,0,0-14,14v4a38,38,0,0,0,38,38A14,14,0,0,0,118,140Zm-12,0a2,2,0,0,1-2,2,26.1,26.1,0,0,1-26-26v-4a2,2,0,0,1,2-2,26.1,26.1,0,0,1,26,26Zm70-42a38,38,0,0,0-38,38v4a14,14,0,0,0,14,14,38,38,0,0,0,38-38v-4A14,14,0,0,0,176,98Zm2,18a26.1,26.1,0,0,1-26,26,2,2,0,0,1-2-2v-4a26.1,26.1,0,0,1,26-26,2,2,0,0,1,2,2Zm-28,68a6,6,0,0,1-6,6H112a6,6,0,0,1,0-12h32A6,6,0,0,1,150,184Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,20a92.1,92.1,0,0,0-92,92c0,23.2,12.3,53.3,32.8,80.6,15.8,21,39.6,43.4,59.2,43.4s43.4-22.4,59.2-43.4C207.7,165.3,220,135.2,220,112A92.1,92.1,0,0,0,128,20Zm52.8,167.8C162.4,212.2,141.7,228,128,228s-34.4-15.8-52.8-40.2S44,133.2,44,112a84,84,0,0,1,168,0C212,133.2,200,162.2,180.8,187.8ZM116,140v-4a36,36,0,0,0-36-36,12,12,0,0,0-12,12v4a36,36,0,0,0,36,36A12,12,0,0,0,116,140Zm-8,0a4,4,0,0,1-4,4,28.1,28.1,0,0,1-28-28v-4a4,4,0,0,1,4-4,28.1,28.1,0,0,1,28,28Zm68-40a36,36,0,0,0-36,36v4a12,12,0,0,0,12,12,36,36,0,0,0,36-36v-4A12,12,0,0,0,176,100Zm4,16a28.1,28.1,0,0,1-28,28,4,4,0,0,1-4-4v-4a28.1,28.1,0,0,1,28-28,4,4,0,0,1,4,4Zm-32,68a4,4,0,0,1-4,4H112a4,4,0,0,1,0-8h32A4,4,0,0,1,148,184Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,16a96.2,96.2,0,0,0-96,96c0,24,12.6,55.1,33.6,83s44.5,45,62.4,45,41.2-16.8,62.4-45S224,136,224,112A96.2,96.2,0,0,0,128,16Zm49.6,169.4C160.2,208.5,140.3,224,128,224s-32.2-15.5-49.6-38.6C59.6,160.5,48,132.4,48,112a80,80,0,0,1,160,0C208,132.4,196.4,160.5,177.6,185.4ZM120,140v-4A40,40,0,0,0,80,96a16,16,0,0,0-16,16v4a40,40,0,0,0,40,40A16,16,0,0,0,120,140ZM80,116v-4a24.1,24.1,0,0,1,24,24v4h0A24.1,24.1,0,0,1,80,116Zm96-20a40,40,0,0,0-40,40v4a16,16,0,0,0,16,16,40,40,0,0,0,40-40v-4A16,16,0,0,0,176,96Zm0,20a24.1,24.1,0,0,1-24,24v-4a24.1,24.1,0,0,1,24-24Zm-24,68a8,8,0,0,1-8,8H112a8,8,0,0,1,0-16h32A8,8,0,0,1,152,184Z" />
        </>
      )}
    </svg>
  );
}

export function PhAlignBottomSimple(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M212,232a12,12,0,0,1-12,12H56a12,12,0,0,1,0-24H200A12,12,0,0,1,212,232ZM76,184V40A20.1,20.1,0,0,1,96,20h64a20.1,20.1,0,0,1,20,20V184a20.1,20.1,0,0,1-20,20H96A20.1,20.1,0,0,1,76,184Zm24-4h56V44H100Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M168,40V192a8,8,0,0,1-8,8H96a8,8,0,0,1-8-8V40a8,8,0,0,1,8-8h64A8,8,0,0,1,168,40Z"
            opacity="0.2"
          />
          <path d="M208,232a8,8,0,0,1-8,8H56a8,8,0,0,1,0-16H200A8,8,0,0,1,208,232ZM80,192V40A16,16,0,0,1,96,24h64a16,16,0,0,1,16,16V192a16,16,0,0,1-16,16H96A16,16,0,0,1,80,192Zm16,0h64V40H96Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M208,232a8,8,0,0,1-8,8H56a8,8,0,0,1,0-16H200A8,8,0,0,1,208,232ZM96,208h64a16,16,0,0,0,16-16V40a16,16,0,0,0-16-16H96A16,16,0,0,0,80,40V192A16,16,0,0,0,96,208Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M206,232a6,6,0,0,1-6,6H56a6,6,0,0,1,0-12H200A6,6,0,0,1,206,232ZM82,192V40A14,14,0,0,1,96,26h64a14,14,0,0,1,14,14V192a14,14,0,0,1-14,14H96A14,14,0,0,1,82,192Zm12,0a2,2,0,0,0,2,2h64a2,2,0,0,0,2-2V40a2,2,0,0,0-2-2H96a2,2,0,0,0-2,2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M204,232a4,4,0,0,1-4,4H56a4,4,0,0,1,0-8H200A4,4,0,0,1,204,232ZM84,192V40A12,12,0,0,1,96,28h64a12,12,0,0,1,12,12V192a12,12,0,0,1-12,12H96A12,12,0,0,1,84,192Zm8,0a4,4,0,0,0,4,4h64a4,4,0,0,0,4-4V40a4,4,0,0,0-4-4H96a4,4,0,0,0-4,4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M208,232a8,8,0,0,1-8,8H56a8,8,0,0,1,0-16H200A8,8,0,0,1,208,232ZM80,192V40A16,16,0,0,1,96,24h64a16,16,0,0,1,16,16V192a16,16,0,0,1-16,16H96A16,16,0,0,1,80,192Zm16,0h64V40H96Z" />
        </>
      )}
    </svg>
  );
}

export function PhAlignBottom(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M228,216a12,12,0,0,1-12,12H40a12,12,0,0,1,0-24H216A12,12,0,0,1,228,216Zm-92-48V80a20.1,20.1,0,0,1,20-20h36a20.1,20.1,0,0,1,20,20v88a20.1,20.1,0,0,1-20,20H156A20.1,20.1,0,0,1,136,168Zm24-4h28V84H160ZM44,168V40A20.1,20.1,0,0,1,64,20h36a20.1,20.1,0,0,1,20,20V168a20.1,20.1,0,0,1-20,20H64A20.1,20.1,0,0,1,44,168Zm24-4H96V44H68Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect
              x="116"
              y="100"
              width="112"
              height="56"
              rx="8"
              transform="translate(44 300) rotate(-90)"
            />
          </g>
          <g opacity="0.2">
            <rect x="56" y="32" width="56" height="152" rx="8" />
          </g>
          <path d="M224,216a8,8,0,0,1-8,8H40a8,8,0,0,1,0-16H216A8,8,0,0,1,224,216Zm-88-40V80a16,16,0,0,1,16-16h40a16,16,0,0,1,16,16v96a16,16,0,0,1-16,16H152A16,16,0,0,1,136,176Zm16,0h40V80H152ZM48,176V40A16,16,0,0,1,64,24h40a16,16,0,0,1,16,16V176a16,16,0,0,1-16,16H64A16,16,0,0,1,48,176Zm16,0h40V40H64Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M224,216a8,8,0,0,1-8,8H40a8,8,0,0,1,0-16H216A8,8,0,0,1,224,216Zm-72-24h40a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H152a16,16,0,0,0-16,16v96A16,16,0,0,0,152,192Zm-88,0h40a16,16,0,0,0,16-16V40a16,16,0,0,0-16-16H64A16,16,0,0,0,48,40V176A16,16,0,0,0,64,192Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M222,216a6,6,0,0,1-6,6H40a6,6,0,0,1,0-12H216A6,6,0,0,1,222,216Zm-84-40V80a14,14,0,0,1,14-14h40a14,14,0,0,1,14,14v96a14,14,0,0,1-14,14H152A14,14,0,0,1,138,176Zm12,0a2,2,0,0,0,2,2h40a2,2,0,0,0,2-2V80a2,2,0,0,0-2-2H152a2,2,0,0,0-2,2ZM50,176V40A14,14,0,0,1,64,26h40a14,14,0,0,1,14,14V176a14,14,0,0,1-14,14H64A14,14,0,0,1,50,176Zm12,0a2,2,0,0,0,2,2h40a2,2,0,0,0,2-2V40a2,2,0,0,0-2-2H64a2,2,0,0,0-2,2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M220,216a4,4,0,0,1-4,4H40a4,4,0,0,1,0-8H216A4,4,0,0,1,220,216Zm-80-40V80a12,12,0,0,1,12-12h40a12,12,0,0,1,12,12v96a12,12,0,0,1-12,12H152A12,12,0,0,1,140,176Zm8,0a4,4,0,0,0,4,4h40a4,4,0,0,0,4-4V80a4,4,0,0,0-4-4H152a4,4,0,0,0-4,4Zm-96,0V40A12,12,0,0,1,64,28h40a12,12,0,0,1,12,12V176a12,12,0,0,1-12,12H64A12,12,0,0,1,52,176Zm8,0a4,4,0,0,0,4,4h40a4,4,0,0,0,4-4V40a4,4,0,0,0-4-4H64a4,4,0,0,0-4,4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M224,216a8,8,0,0,1-8,8H40a8,8,0,0,1,0-16H216A8,8,0,0,1,224,216Zm-88-40V80a16,16,0,0,1,16-16h40a16,16,0,0,1,16,16v96a16,16,0,0,1-16,16H152A16,16,0,0,1,136,176Zm16,0h40V80H152ZM48,176V40A16,16,0,0,1,64,24h40a16,16,0,0,1,16,16V176a16,16,0,0,1-16,16H64A16,16,0,0,1,48,176Zm16,0h40V40H64Z" />
        </>
      )}
    </svg>
  );
}

export function PhAlignCenterHorizontalSimple(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M208,76H140V48a12,12,0,0,0-24,0V76H48A20.1,20.1,0,0,0,28,96v64a20.1,20.1,0,0,0,20,20h68v28a12,12,0,0,0,24,0V180h68a20.1,20.1,0,0,0,20-20V96A20.1,20.1,0,0,0,208,76Zm-4,80H52V100H204Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M216,96v64a8,8,0,0,1-8,8H48a8,8,0,0,1-8-8V96a8,8,0,0,1,8-8H208A8,8,0,0,1,216,96Z"
            opacity="0.2"
          />
          <path d="M208,80H136V48a8,8,0,0,0-16,0V80H48A16,16,0,0,0,32,96v64a16,16,0,0,0,16,16h72v32a8,8,0,0,0,16,0V176h72a16,16,0,0,0,16-16V96A16,16,0,0,0,208,80Zm0,80H48V96H208v64Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M224,96v64a16,16,0,0,1-16,16H136v32a8,8,0,0,1-16,0V176H48a16,16,0,0,1-16-16V96A16,16,0,0,1,48,80h72V48a8,8,0,0,1,16,0V80h72A16,16,0,0,1,224,96Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M208,82H134V48a6,6,0,0,0-12,0V82H48A14,14,0,0,0,34,96v64a14,14,0,0,0,14,14h74v34a6,6,0,0,0,12,0V174h74a14,14,0,0,0,14-14V96A14,14,0,0,0,208,82Zm2,78a2,2,0,0,1-2,2H48a2,2,0,0,1-2-2V96a2,2,0,0,1,2-2H208a2,2,0,0,1,2,2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M208,84H132V48a4,4,0,0,0-8,0V84H48A12,12,0,0,0,36,96v64a12,12,0,0,0,12,12h76v36a4,4,0,0,0,8,0V172h76a12,12,0,0,0,12-12V96A12,12,0,0,0,208,84Zm4,76a4,4,0,0,1-4,4H48a4,4,0,0,1-4-4V96a4,4,0,0,1,4-4H208a4,4,0,0,1,4,4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M208,80H136V48a8,8,0,0,0-16,0V80H48A16,16,0,0,0,32,96v64a16,16,0,0,0,16,16h72v32a8,8,0,0,0,16,0V176h72a16,16,0,0,0,16-16V96A16,16,0,0,0,208,80Zm0,80H48V96H208v64Z" />
        </>
      )}
    </svg>
  );
}

export function PhAlignCenterHorizontal(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M208,136H140V120h44a20.1,20.1,0,0,0,20-20V60a20.1,20.1,0,0,0-20-20H140V32a12,12,0,0,0-24,0v8H72A20.1,20.1,0,0,0,52,60v40a20.1,20.1,0,0,0,20,20h44v16H48a20.1,20.1,0,0,0-20,20v40a20.1,20.1,0,0,0,20,20h68v8a12,12,0,0,0,24,0v-8h68a20.1,20.1,0,0,0,20-20V156A20.1,20.1,0,0,0,208,136ZM76,64H180V96H76ZM204,192H52V160H204Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect x="64" y="56" width="128" height="56" rx="8" />
          </g>
          <g opacity="0.2">
            <rect x="40" y="144" width="176" height="56" rx="8" />
          </g>
          <path d="M208,136H136V120h48a16,16,0,0,0,16-16V64a16,16,0,0,0-16-16H136V32a8,8,0,0,0-16,0V48H72A16,16,0,0,0,56,64v40a16,16,0,0,0,16,16h48v16H48a16,16,0,0,0-16,16v40a16,16,0,0,0,16,16h72v16a8,8,0,0,0,16,0V208h72a16,16,0,0,0,16-16V152A16,16,0,0,0,208,136ZM72,64H184v40H72ZM208,192H48V152H208v40Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M224,152v40a16,16,0,0,1-16,16H136v16a8,8,0,0,1-16,0V208H48a16,16,0,0,1-16-16V152a16,16,0,0,1,16-16h72V120H72a16,16,0,0,1-16-16V64A16,16,0,0,1,72,48h48V32a8,8,0,0,1,16,0V48h48a16,16,0,0,1,16,16v40a16,16,0,0,1-16,16H136v16h72A16,16,0,0,1,224,152Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M208,138H134V118h50a14,14,0,0,0,14-14V64a14,14,0,0,0-14-14H134V32a6,6,0,0,0-12,0V50H72A14,14,0,0,0,58,64v40a14,14,0,0,0,14,14h50v20H48a14,14,0,0,0-14,14v40a14,14,0,0,0,14,14h74v18a6,6,0,0,0,12,0V206h74a14,14,0,0,0,14-14V152A14,14,0,0,0,208,138ZM70,104V64a2,2,0,0,1,2-2H184a2,2,0,0,1,2,2v40a2,2,0,0,1-2,2H72A2,2,0,0,1,70,104Zm140,88a2,2,0,0,1-2,2H48a2,2,0,0,1-2-2V152a2,2,0,0,1,2-2H208a2,2,0,0,1,2,2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M208,140H132V116h52a12,12,0,0,0,12-12V64a12,12,0,0,0-12-12H132V32a4,4,0,0,0-8,0V52H72A12,12,0,0,0,60,64v40a12,12,0,0,0,12,12h52v24H48a12,12,0,0,0-12,12v40a12,12,0,0,0,12,12h76v20a4,4,0,0,0,8,0V204h76a12,12,0,0,0,12-12V152A12,12,0,0,0,208,140ZM68,104V64a4,4,0,0,1,4-4H184a4,4,0,0,1,4,4v40a4,4,0,0,1-4,4H72A4,4,0,0,1,68,104Zm144,88a4,4,0,0,1-4,4H48a4,4,0,0,1-4-4V152a4,4,0,0,1,4-4H208a4,4,0,0,1,4,4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M208,136H136V120h48a16,16,0,0,0,16-16V64a16,16,0,0,0-16-16H136V32a8,8,0,0,0-16,0V48H72A16,16,0,0,0,56,64v40a16,16,0,0,0,16,16h48v16H48a16,16,0,0,0-16,16v40a16,16,0,0,0,16,16h72v16a8,8,0,0,0,16,0V208h72a16,16,0,0,0,16-16V152A16,16,0,0,0,208,136ZM72,64H184v40H72ZM208,192H48V152H208v40Z" />
        </>
      )}
    </svg>
  );
}

export function PhAlignCenterVerticalSimple(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M208,116H180V48a20.1,20.1,0,0,0-20-20H96A20.1,20.1,0,0,0,76,48v68H48a12,12,0,0,0,0,24H76v68a20.1,20.1,0,0,0,20,20h64a20.1,20.1,0,0,0,20-20V140h28a12,12,0,0,0,0-24Zm-52,88H100V52h56Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M168,48V208a8,8,0,0,1-8,8H96a8,8,0,0,1-8-8V48a8,8,0,0,1,8-8h64A8,8,0,0,1,168,48Z"
            opacity="0.2"
          />
          <path d="M208,120H176V48a16,16,0,0,0-16-16H96A16,16,0,0,0,80,48v72H48a8,8,0,0,0,0,16H80v72a16,16,0,0,0,16,16h64a16,16,0,0,0,16-16V136h32a8,8,0,0,0,0-16Zm-48,88H96V48h64Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,128a8,8,0,0,1-8,8H176v72a16,16,0,0,1-16,16H96a16,16,0,0,1-16-16V136H48a8,8,0,0,1,0-16H80V48A16,16,0,0,1,96,32h64a16,16,0,0,1,16,16v72h32A8,8,0,0,1,216,128Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M208,122H174V48a14,14,0,0,0-14-14H96A14,14,0,0,0,82,48v74H48a6,6,0,0,0,0,12H82v74a14,14,0,0,0,14,14h64a14,14,0,0,0,14-14V134h34a6,6,0,0,0,0-12Zm-46,86a2,2,0,0,1-2,2H96a2,2,0,0,1-2-2V48a2,2,0,0,1,2-2h64a2,2,0,0,1,2,2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M208,124H172V48a12,12,0,0,0-12-12H96A12,12,0,0,0,84,48v76H48a4,4,0,0,0,0,8H84v76a12,12,0,0,0,12,12h64a12,12,0,0,0,12-12V132h36a4,4,0,0,0,0-8Zm-44,84a4,4,0,0,1-4,4H96a4,4,0,0,1-4-4V48a4,4,0,0,1,4-4h64a4,4,0,0,1,4,4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M208,120H176V48a16,16,0,0,0-16-16H96A16,16,0,0,0,80,48v72H48a8,8,0,0,0,0,16H80v72a16,16,0,0,0,16,16h64a16,16,0,0,0,16-16V136h32a8,8,0,0,0,0-16Zm-48,88H96V48h64Z" />
        </>
      )}
    </svg>
  );
}

export function PhAlignCenterVertical(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M224,116h-8V72a20.1,20.1,0,0,0-20-20H156a20.1,20.1,0,0,0-20,20v44H120V48a20.1,20.1,0,0,0-20-20H60A20.1,20.1,0,0,0,40,48v68H32a12,12,0,0,0,0,24h8v68a20.1,20.1,0,0,0,20,20h40a20.1,20.1,0,0,0,20-20V140h16v44a20.1,20.1,0,0,0,20,20h40a20.1,20.1,0,0,0,20-20V140h8a12,12,0,0,0,0-24ZM96,204H64V128.1h0V52H96Zm96-24H160V128.1h0V76h32Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect
              x="108"
              y="100"
              width="128"
              height="56"
              rx="8"
              transform="translate(300 -44) rotate(90)"
            />
          </g>
          <g opacity="0.2">
            <rect x="56" y="40" width="56" height="176" rx="8" />
          </g>
          <path d="M224,120H208V72a16,16,0,0,0-16-16H152a16,16,0,0,0-16,16v48H120V48a16,16,0,0,0-16-16H64A16,16,0,0,0,48,48v72H32a8,8,0,0,0,0,16H48v72a16,16,0,0,0,16,16h40a16,16,0,0,0,16-16V136h16v48a16,16,0,0,0,16,16h40a16,16,0,0,0,16-16V136h16a8,8,0,0,0,0-16ZM104,208H64V128.1h0V48h40Zm88-24H152V128.1h0V72h40Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M232,128a8,8,0,0,1-8,8H208v48a16,16,0,0,1-16,16H152a16,16,0,0,1-16-16V136H120v72a16,16,0,0,1-16,16H64a16,16,0,0,1-16-16V136H32a8,8,0,0,1,0-16H48V48A16,16,0,0,1,64,32h40a16,16,0,0,1,16,16v72h16V72a16,16,0,0,1,16-16h40a16,16,0,0,1,16,16v48h16A8,8,0,0,1,232,128Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M224,122H206V72a14,14,0,0,0-14-14H152a14,14,0,0,0-14,14v50H118V48a14,14,0,0,0-14-14H64A14,14,0,0,0,50,48v74H32a6,6,0,0,0,0,12H50v74a14,14,0,0,0,14,14h40a14,14,0,0,0,14-14V134h20v50a14,14,0,0,0,14,14h40a14,14,0,0,0,14-14V134h18a6,6,0,0,0,0-12ZM106,208a2,2,0,0,1-2,2H64a2,2,0,0,1-2-2V128.1h0V48a2,2,0,0,1,2-2h40a2,2,0,0,1,2,2Zm88-24a2,2,0,0,1-2,2H152a2,2,0,0,1-2-2V128.1h0V72a2,2,0,0,1,2-2h40a2,2,0,0,1,2,2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M224,124H204V72a12,12,0,0,0-12-12H152a12,12,0,0,0-12,12v52H116V48a12,12,0,0,0-12-12H64A12,12,0,0,0,52,48v76H32a4,4,0,0,0,0,8H52v76a12,12,0,0,0,12,12h40a12,12,0,0,0,12-12V132h24v52a12,12,0,0,0,12,12h40a12,12,0,0,0,12-12V132h20a4,4,0,0,0,0-8ZM108,208a4,4,0,0,1-4,4H64a4,4,0,0,1-4-4V128h0V48a4,4,0,0,1,4-4h40a4,4,0,0,1,4,4Zm88-24a4,4,0,0,1-4,4H152a4,4,0,0,1-4-4V128h0V72a4,4,0,0,1,4-4h40a4,4,0,0,1,4,4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M224,120H208V72a16,16,0,0,0-16-16H152a16,16,0,0,0-16,16v48H120V48a16,16,0,0,0-16-16H64A16,16,0,0,0,48,48v72H32a8,8,0,0,0,0,16H48v72a16,16,0,0,0,16,16h40a16,16,0,0,0,16-16V136h16v48a16,16,0,0,0,16,16h40a16,16,0,0,0,16-16V136h16a8,8,0,0,0,0-16ZM104,208H64V128.1h0V48h40Zm88-24H152V128.1h0V72h40Z" />
        </>
      )}
    </svg>
  );
}

export function PhAlignLeftSimple(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M44,56V200a12,12,0,0,1-24,0V56a12,12,0,0,1,24,0ZM244,96v64a20.1,20.1,0,0,1-20,20H80a20.1,20.1,0,0,1-20-20V96A20.1,20.1,0,0,1,80,76H224A20.1,20.1,0,0,1,244,96Zm-24,4H84v56H220Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M232,96v64a8,8,0,0,1-8,8H72a8,8,0,0,1-8-8V96a8,8,0,0,1,8-8H224A8,8,0,0,1,232,96Z"
            opacity="0.2"
          />
          <path d="M40,56V200a8,8,0,0,1-16,0V56a8,8,0,0,1,16,0ZM240,96v64a16,16,0,0,1-16,16H72a16,16,0,0,1-16-16V96A16,16,0,0,1,72,80H224A16,16,0,0,1,240,96Zm-16,64V96H72v64Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M40,56V200a8,8,0,0,1-16,0V56a8,8,0,0,1,16,0ZM224,80H72A16,16,0,0,0,56,96v64a16,16,0,0,0,16,16H224a16,16,0,0,0,16-16V96A16,16,0,0,0,224,80Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M38,56V200a6,6,0,0,1-12,0V56a6,6,0,0,1,12,0ZM238,96v64a14,14,0,0,1-14,14H72a14,14,0,0,1-14-14V96A14,14,0,0,1,72,82H224A14,14,0,0,1,238,96Zm-12,0a2,2,0,0,0-2-2H72a2,2,0,0,0-2,2v64a2,2,0,0,0,2,2H224a2,2,0,0,0,2-2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M36,56V200a4,4,0,0,1-8,0V56a4,4,0,0,1,8,0ZM236,96v64a12,12,0,0,1-12,12H72a12,12,0,0,1-12-12V96A12,12,0,0,1,72,84H224A12,12,0,0,1,236,96Zm-8,0a4,4,0,0,0-4-4H72a4,4,0,0,0-4,4v64a4,4,0,0,0,4,4H224a4,4,0,0,0,4-4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M40,56V200a8,8,0,0,1-16,0V56a8,8,0,0,1,16,0ZM240,96v64a16,16,0,0,1-16,16H72a16,16,0,0,1-16-16V96A16,16,0,0,1,72,80H224A16,16,0,0,1,240,96Zm-16,64V96H72v64Z" />
        </>
      )}
    </svg>
  );
}

export function PhAlignLeft(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M52,40V216a12,12,0,0,1-24,0V40a12,12,0,0,1,24,0Zm16,60V64A20.1,20.1,0,0,1,88,44h88a20.1,20.1,0,0,1,20,20v36a20.1,20.1,0,0,1-20,20H88A20.1,20.1,0,0,1,68,100Zm24-4h80V68H92Zm144,60v36a20.1,20.1,0,0,1-20,20H88a20.1,20.1,0,0,1-20-20V156a20.1,20.1,0,0,1,20-20H216A20.1,20.1,0,0,1,236,156Zm-24,4H92v28H212Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect x="72" y="56" width="112" height="56" rx="8" />
          </g>
          <g opacity="0.2">
            <rect x="72" y="144" width="152" height="56" rx="8" />
          </g>
          <path d="M216,136H80a16,16,0,0,0-16,16v40a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V152A16,16,0,0,0,216,136Zm0,56H80V152H216v40ZM48,40V216a8,8,0,0,1-16,0V40a8,8,0,0,1,16,0Zm32,80h96a16,16,0,0,0,16-16V64a16,16,0,0,0-16-16H80A16,16,0,0,0,64,64v40A16,16,0,0,0,80,120Zm0-56h96v40H80Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M48,40V216a8,8,0,0,1-16,0V40a8,8,0,0,1,16,0Zm32,80h96a16,16,0,0,0,16-16V64a16,16,0,0,0-16-16H80A16,16,0,0,0,64,64v40A16,16,0,0,0,80,120Zm136,16H80a16,16,0,0,0-16,16v40a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V152A16,16,0,0,0,216,136Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M46,40V216a6,6,0,0,1-12,0V40a6,6,0,0,1,12,0Zm20,64V64A14,14,0,0,1,80,50h96a14,14,0,0,1,14,14v40a14,14,0,0,1-14,14H80A14,14,0,0,1,66,104Zm12,0a2,2,0,0,0,2,2h96a2,2,0,0,0,2-2V64a2,2,0,0,0-2-2H80a2,2,0,0,0-2,2Zm152,48v40a14,14,0,0,1-14,14H80a14,14,0,0,1-14-14V152a14,14,0,0,1,14-14H216A14,14,0,0,1,230,152Zm-12,0a2,2,0,0,0-2-2H80a2,2,0,0,0-2,2v40a2,2,0,0,0,2,2H216a2,2,0,0,0,2-2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M44,40V216a4,4,0,0,1-8,0V40a4,4,0,0,1,8,0Zm24,64V64A12,12,0,0,1,80,52h96a12,12,0,0,1,12,12v40a12,12,0,0,1-12,12H80A12,12,0,0,1,68,104Zm8,0a4,4,0,0,0,4,4h96a4,4,0,0,0,4-4V64a4,4,0,0,0-4-4H80a4,4,0,0,0-4,4Zm152,48v40a12,12,0,0,1-12,12H80a12,12,0,0,1-12-12V152a12,12,0,0,1,12-12H216A12,12,0,0,1,228,152Zm-8,0a4,4,0,0,0-4-4H80a4,4,0,0,0-4,4v40a4,4,0,0,0,4,4H216a4,4,0,0,0,4-4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M48,40V216a8,8,0,0,1-16,0V40a8,8,0,0,1,16,0Zm16,64V64A16,16,0,0,1,80,48h96a16,16,0,0,1,16,16v40a16,16,0,0,1-16,16H80A16,16,0,0,1,64,104Zm16,0h96V64H80Zm152,48v40a16,16,0,0,1-16,16H80a16,16,0,0,1-16-16V152a16,16,0,0,1,16-16H216A16,16,0,0,1,232,152Zm-16,40V152H80v40Z" />
        </>
      )}
    </svg>
  );
}

export function PhAlignRightSimple(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M236,56V200a12,12,0,0,1-24,0V56a12,12,0,0,1,24,0ZM196,96v64a20.1,20.1,0,0,1-20,20H32a20.1,20.1,0,0,1-20-20V96A20.1,20.1,0,0,1,32,76H176A20.1,20.1,0,0,1,196,96Zm-24,4H36v56H172Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M192,96v64a8,8,0,0,1-8,8H32a8,8,0,0,1-8-8V96a8,8,0,0,1,8-8H184A8,8,0,0,1,192,96Z"
            opacity="0.2"
          />
          <path d="M232,56V200a8,8,0,0,1-16,0V56a8,8,0,0,1,16,0ZM200,96v64a16,16,0,0,1-16,16H32a16,16,0,0,1-16-16V96A16,16,0,0,1,32,80H184A16,16,0,0,1,200,96Zm-16,0H32v64H184Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M232,56V200a8,8,0,0,1-16,0V56a8,8,0,0,1,16,0ZM184,80H32A16,16,0,0,0,16,96v64a16,16,0,0,0,16,16H184a16,16,0,0,0,16-16V96A16,16,0,0,0,184,80Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M230,56V200a6,6,0,0,1-12,0V56a6,6,0,0,1,12,0ZM198,96v64a14,14,0,0,1-14,14H32a14,14,0,0,1-14-14V96A14,14,0,0,1,32,82H184A14,14,0,0,1,198,96Zm-12,0a2,2,0,0,0-2-2H32a2,2,0,0,0-2,2v64a2,2,0,0,0,2,2H184a2,2,0,0,0,2-2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M228,56V200a4,4,0,0,1-8,0V56a4,4,0,0,1,8,0ZM196,96v64a12,12,0,0,1-12,12H32a12,12,0,0,1-12-12V96A12,12,0,0,1,32,84H184A12,12,0,0,1,196,96Zm-8,0a4,4,0,0,0-4-4H32a4,4,0,0,0-4,4v64a4,4,0,0,0,4,4H184a4,4,0,0,0,4-4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M232,56V200a8,8,0,0,1-16,0V56a8,8,0,0,1,16,0ZM200,96v64a16,16,0,0,1-16,16H32a16,16,0,0,1-16-16V96A16,16,0,0,1,32,80H184A16,16,0,0,1,200,96Zm-16,0H32v64H184Z" />
        </>
      )}
    </svg>
  );
}

export function PhAlignRight(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M228,40V216a12,12,0,0,1-24,0V40a12,12,0,0,1,24,0ZM188,64v36a20.1,20.1,0,0,1-20,20H80a20.1,20.1,0,0,1-20-20V64A20.1,20.1,0,0,1,80,44h88A20.1,20.1,0,0,1,188,64Zm-24,4H84V96h80Zm24,88v36a20.1,20.1,0,0,1-20,20H40a20.1,20.1,0,0,1-20-20V156a20.1,20.1,0,0,1,20-20H168A20.1,20.1,0,0,1,188,156Zm-24,4H44v28H164Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect
              x="72"
              y="56"
              width="112"
              height="56"
              rx="8"
              transform="translate(256 168) rotate(180)"
            />
          </g>
          <g opacity="0.2">
            <rect x="32" y="144" width="152" height="56" rx="8" />
          </g>
          <path d="M224,40V216a8,8,0,0,1-16,0V40a8,8,0,0,1,16,0ZM192,64v40a16,16,0,0,1-16,16H80a16,16,0,0,1-16-16V64A16,16,0,0,1,80,48h96A16,16,0,0,1,192,64Zm-16,0H80v40h96Zm16,88v40a16,16,0,0,1-16,16H40a16,16,0,0,1-16-16V152a16,16,0,0,1,16-16H176A16,16,0,0,1,192,152Zm-16,0H40v40H176Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M224,40V216a8,8,0,0,1-16,0V40a8,8,0,0,1,16,0Zm-48,8H80A16,16,0,0,0,64,64v40a16,16,0,0,0,16,16h96a16,16,0,0,0,16-16V64A16,16,0,0,0,176,48Zm0,88H40a16,16,0,0,0-16,16v40a16,16,0,0,0,16,16H176a16,16,0,0,0,16-16V152A16,16,0,0,0,176,136Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M222,40V216a6,6,0,0,1-12,0V40a6,6,0,0,1,12,0ZM190,64v40a14,14,0,0,1-14,14H80a14,14,0,0,1-14-14V64A14,14,0,0,1,80,50h96A14,14,0,0,1,190,64Zm-12,0a2,2,0,0,0-2-2H80a2,2,0,0,0-2,2v40a2,2,0,0,0,2,2h96a2,2,0,0,0,2-2Zm12,88v40a14,14,0,0,1-14,14H40a14,14,0,0,1-14-14V152a14,14,0,0,1,14-14H176A14,14,0,0,1,190,152Zm-12,0a2,2,0,0,0-2-2H40a2,2,0,0,0-2,2v40a2,2,0,0,0,2,2H176a2,2,0,0,0,2-2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M220,40V216a4,4,0,0,1-8,0V40a4,4,0,0,1,8,0ZM188,64v40a12,12,0,0,1-12,12H80a12,12,0,0,1-12-12V64A12,12,0,0,1,80,52h96A12,12,0,0,1,188,64Zm-8,0a4,4,0,0,0-4-4H80a4,4,0,0,0-4,4v40a4,4,0,0,0,4,4h96a4,4,0,0,0,4-4Zm8,88v40a12,12,0,0,1-12,12H40a12,12,0,0,1-12-12V152a12,12,0,0,1,12-12H176A12,12,0,0,1,188,152Zm-8,0a4,4,0,0,0-4-4H40a4,4,0,0,0-4,4v40a4,4,0,0,0,4,4H176a4,4,0,0,0,4-4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M224,40V216a8,8,0,0,1-16,0V40a8,8,0,0,1,16,0ZM192,64v40a16,16,0,0,1-16,16H80a16,16,0,0,1-16-16V64A16,16,0,0,1,80,48h96A16,16,0,0,1,192,64Zm-16,0H80v40h96Zm16,88v40a16,16,0,0,1-16,16H40a16,16,0,0,1-16-16V152a16,16,0,0,1,16-16H176A16,16,0,0,1,192,152Zm-16,0H40v40H176Z" />
        </>
      )}
    </svg>
  );
}

export function PhAlignTopSimple(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M212,32a12,12,0,0,1-12,12H56a12,12,0,0,1,0-24H200A12,12,0,0,1,212,32ZM180,80V224a20.1,20.1,0,0,1-20,20H96a20.1,20.1,0,0,1-20-20V80A20.1,20.1,0,0,1,96,60h64A20.1,20.1,0,0,1,180,80Zm-24,4H100V220h56Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M168,72V224a8,8,0,0,1-8,8H96a8,8,0,0,1-8-8V72a8,8,0,0,1,8-8h64A8,8,0,0,1,168,72Z"
            opacity="0.2"
          />
          <path d="M208,32a8,8,0,0,1-8,8H56a8,8,0,0,1,0-16H200A8,8,0,0,1,208,32ZM176,72V224a16,16,0,0,1-16,16H96a16,16,0,0,1-16-16V72A16,16,0,0,1,96,56h64A16,16,0,0,1,176,72Zm-16,0H96V224h64Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M208,32a8,8,0,0,1-8,8H56a8,8,0,0,1,0-16H200A8,8,0,0,1,208,32ZM160,56H96A16,16,0,0,0,80,72V224a16,16,0,0,0,16,16h64a16,16,0,0,0,16-16V72A16,16,0,0,0,160,56Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M206,32a6,6,0,0,1-6,6H56a6,6,0,0,1,0-12H200A6,6,0,0,1,206,32ZM174,72V224a14,14,0,0,1-14,14H96a14,14,0,0,1-14-14V72A14,14,0,0,1,96,58h64A14,14,0,0,1,174,72Zm-12,0a2,2,0,0,0-2-2H96a2,2,0,0,0-2,2V224a2,2,0,0,0,2,2h64a2,2,0,0,0,2-2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M204,32a4,4,0,0,1-4,4H56a4,4,0,0,1,0-8H200A4,4,0,0,1,204,32ZM172,72V224a12,12,0,0,1-12,12H96a12,12,0,0,1-12-12V72A12,12,0,0,1,96,60h64A12,12,0,0,1,172,72Zm-8,0a4,4,0,0,0-4-4H96a4,4,0,0,0-4,4V224a4,4,0,0,0,4,4h64a4,4,0,0,0,4-4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M208,32a8,8,0,0,1-8,8H56a8,8,0,0,1,0-16H200A8,8,0,0,1,208,32ZM176,72V224a16,16,0,0,1-16,16H96a16,16,0,0,1-16-16V72A16,16,0,0,1,96,56h64A16,16,0,0,1,176,72Zm-16,0H96V224h64Z" />
        </>
      )}
    </svg>
  );
}

export function PhAlignTop(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M100,68H64A20.1,20.1,0,0,0,44,88V216a20.1,20.1,0,0,0,20,20h36a20.1,20.1,0,0,0,20-20V88A20.1,20.1,0,0,0,100,68ZM96,212H68V92H96ZM192,68H156a20.1,20.1,0,0,0-20,20v88a20.1,20.1,0,0,0,20,20h36a20.1,20.1,0,0,0,20-20V88A20.1,20.1,0,0,0,192,68Zm-4,104H160V92h28ZM228,40a12,12,0,0,1-12,12H40a12,12,0,0,1,0-24H216A12,12,0,0,1,228,40Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect
              x="116"
              y="100"
              width="112"
              height="56"
              rx="8"
              transform="translate(300 -44) rotate(90)"
            />
          </g>
          <g opacity="0.2">
            <rect x="56" y="72" width="56" height="152" rx="8" />
          </g>
          <path d="M224,40a8,8,0,0,1-8,8H40a8,8,0,0,1,0-16H216A8,8,0,0,1,224,40ZM208,80v96a16,16,0,0,1-16,16H152a16,16,0,0,1-16-16V80a16,16,0,0,1,16-16h40A16,16,0,0,1,208,80Zm-16,0H152v96h40Zm-72,0V216a16,16,0,0,1-16,16H64a16,16,0,0,1-16-16V80A16,16,0,0,1,64,64h40A16,16,0,0,1,120,80Zm-16,0H64V216h40Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M224,40a8,8,0,0,1-8,8H40a8,8,0,0,1,0-16H216A8,8,0,0,1,224,40ZM192,64H152a16,16,0,0,0-16,16v96a16,16,0,0,0,16,16h40a16,16,0,0,0,16-16V80A16,16,0,0,0,192,64Zm-88,0H64A16,16,0,0,0,48,80V216a16,16,0,0,0,16,16h40a16,16,0,0,0,16-16V80A16,16,0,0,0,104,64Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M222,40a6,6,0,0,1-6,6H40a6,6,0,0,1,0-12H216A6,6,0,0,1,222,40ZM206,80v96a14,14,0,0,1-14,14H152a14,14,0,0,1-14-14V80a14,14,0,0,1,14-14h40A14,14,0,0,1,206,80Zm-12,0a2,2,0,0,0-2-2H152a2,2,0,0,0-2,2v96a2,2,0,0,0,2,2h40a2,2,0,0,0,2-2Zm-76,0V216a14,14,0,0,1-14,14H64a14,14,0,0,1-14-14V80A14,14,0,0,1,64,66h40A14,14,0,0,1,118,80Zm-12,0a2,2,0,0,0-2-2H64a2,2,0,0,0-2,2V216a2,2,0,0,0,2,2h40a2,2,0,0,0,2-2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M220,40a4,4,0,0,1-4,4H40a4,4,0,0,1,0-8H216A4,4,0,0,1,220,40ZM204,80v96a12,12,0,0,1-12,12H152a12,12,0,0,1-12-12V80a12,12,0,0,1,12-12h40A12,12,0,0,1,204,80Zm-8,0a4,4,0,0,0-4-4H152a4,4,0,0,0-4,4v96a4,4,0,0,0,4,4h40a4,4,0,0,0,4-4Zm-80,0V216a12,12,0,0,1-12,12H64a12,12,0,0,1-12-12V80A12,12,0,0,1,64,68h40A12,12,0,0,1,116,80Zm-8,0a4,4,0,0,0-4-4H64a4,4,0,0,0-4,4V216a4,4,0,0,0,4,4h40a4,4,0,0,0,4-4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M224,40a8,8,0,0,1-8,8H40a8,8,0,0,1,0-16H216A8,8,0,0,1,224,40ZM208,80v96a16,16,0,0,1-16,16H152a16,16,0,0,1-16-16V80a16,16,0,0,1,16-16h40A16,16,0,0,1,208,80Zm-16,0H152v96h40Zm-72,0V216a16,16,0,0,1-16,16H64a16,16,0,0,1-16-16V80A16,16,0,0,1,64,64h40A16,16,0,0,1,120,80Zm-16,0H64V216h40Z" />
        </>
      )}
    </svg>
  );
}

export function PhAnchorSimple(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M224,108H200a12,12,0,0,0,0,24h11.1A84,84,0,0,1,140,203.1V97.9a36,36,0,1,0-24,0V203.1A84,84,0,0,1,44.9,132H56a12,12,0,0,0,0-24H32a12,12,0,0,0-12,12,108,108,0,0,0,216,0A12,12,0,0,0,224,108ZM128,52a12,12,0,1,1-12,12A12,12,0,0,1,128,52Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M224,112H200a8,8,0,0,0,0,16h15.6A88,88,0,0,1,136,207.6V95a32,32,0,1,0-16,0V207.6A88,88,0,0,1,40.4,128H56a8,8,0,0,0,0-16H32a8,8,0,0,0-8,8,104,104,0,0,0,208,0A8,8,0,0,0,224,112ZM112,64a16,16,0,1,1,16,16A16,16,0,0,1,112,64Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M224,112H200a8,8,0,0,0,0,16h15.6A88,88,0,0,1,136,207.6V95a32,32,0,1,0-16,0V207.6A88,88,0,0,1,40.4,128H56a8,8,0,0,0,0-16H32a8,8,0,0,0-8,8,104,104,0,0,0,208,0A8,8,0,0,0,224,112ZM112,64a16,16,0,1,1,16,16A16,16,0,0,1,112,64Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M224,114H200a6,6,0,0,0,0,12h17.8A90.1,90.1,0,0,1,134,209.8V93.4a30,30,0,1,0-12,0V209.8A90.1,90.1,0,0,1,38.2,126H56a6,6,0,0,0,0-12H32a6,6,0,0,0-6,6,102,102,0,0,0,204,0A6,6,0,0,0,224,114ZM110,64a18,18,0,1,1,18,18A18.1,18.1,0,0,1,110,64Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M224,116H200a4,4,0,0,0,0,8h19.9A92,92,0,0,1,132,211.9V91.7a28,28,0,1,0-8,0V211.9A92,92,0,0,1,36.1,124H56a4,4,0,0,0,0-8H32a4,4,0,0,0-4,4,100,100,0,0,0,200,0A4,4,0,0,0,224,116ZM108,64a20,20,0,1,1,20,20A20.1,20.1,0,0,1,108,64Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M224,112H200a8,8,0,0,0,0,16h15.6A88,88,0,0,1,136,207.6V95a32,32,0,1,0-16,0V207.6A88,88,0,0,1,40.4,128H56a8,8,0,0,0,0-16H32a8,8,0,0,0-8,8,104,104,0,0,0,208,0A8,8,0,0,0,224,112ZM112,64a16,16,0,1,1,16,16A16,16,0,0,1,112,64Z" />
        </>
      )}
    </svg>
  );
}

export function PhAnchor(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216,132a12,12,0,0,0-12,12,36,36,0,0,1-36,36,51.6,51.6,0,0,0-28,8.2V124h28a12,12,0,0,0,0-24H140V81.7a32,32,0,1,0-24,0V100H88a12,12,0,0,0,0,24h28v64.2A51.6,51.6,0,0,0,88,180a36,36,0,0,1-36-36,12,12,0,0,0-24,0,60,60,0,0,0,60,60,28.1,28.1,0,0,1,28,28,12,12,0,0,0,24,0,28.1,28.1,0,0,1,28-28,60,60,0,0,0,60-60A12,12,0,0,0,216,132ZM128,44a8,8,0,1,1-8,8A8,8,0,0,1,128,44Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M216,136a8,8,0,0,0-8,8,40,40,0,0,1-40,40,47.9,47.9,0,0,0-32,12.3V120h32a8,8,0,0,0,0-16H136V78.8a28,28,0,1,0-16,0V104H88a8,8,0,0,0,0,16h32v76.3A47.9,47.9,0,0,0,88,184a40,40,0,0,1-40-40,8,8,0,0,0-16,0,56,56,0,0,0,56,56,32.1,32.1,0,0,1,32,32,8,8,0,0,0,16,0,32.1,32.1,0,0,1,32-32,56,56,0,0,0,56-56A8,8,0,0,0,216,136ZM116,52a12,12,0,1,1,12,12A12,12,0,0,1,116,52Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,136a8,8,0,0,0-8,8,40,40,0,0,1-40,40,47.9,47.9,0,0,0-32,12.3V120h32a8,8,0,0,0,0-16H136V78.8a28,28,0,1,0-16,0V104H88a8,8,0,0,0,0,16h32v76.3A47.9,47.9,0,0,0,88,184a40,40,0,0,1-40-40,8,8,0,0,0-16,0,56,56,0,0,0,56,56,32.1,32.1,0,0,1,32,32,8,8,0,0,0,16,0,32.1,32.1,0,0,1,32-32,56,56,0,0,0,56-56A8,8,0,0,0,216,136ZM116,52a12,12,0,1,1,12,12A12,12,0,0,1,116,52Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M216,138a6,6,0,0,0-6,6,42,42,0,0,1-42,42,45.6,45.6,0,0,0-34,15.1V118h34a6,6,0,0,0,0-12H134V77.3a26,26,0,1,0-12,0V106H88a6,6,0,0,0,0,12h34v83.1A45.6,45.6,0,0,0,88,186a42,42,0,0,1-42-42,6,6,0,0,0-12,0,54,54,0,0,0,54,54,34.1,34.1,0,0,1,34,34,6,6,0,0,0,12,0,34.1,34.1,0,0,1,34-34,54,54,0,0,0,54-54A6,6,0,0,0,216,138ZM114,52a14,14,0,1,1,14,14A14,14,0,0,1,114,52Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M216,140a4,4,0,0,0-4,4,44,44,0,0,1-44,44,44,44,0,0,0-36,18.7V116h36a4,4,0,0,0,0-8H132V75.7a24,24,0,1,0-8,0V108H88a4,4,0,0,0,0,8h36v90.7A44,44,0,0,0,88,188a44,44,0,0,1-44-44,4,4,0,0,0-8,0,52,52,0,0,0,52,52,36,36,0,0,1,36,36,4,4,0,0,0,8,0,36,36,0,0,1,36-36,52,52,0,0,0,52-52A4,4,0,0,0,216,140ZM112,52a16,16,0,1,1,16,16A16,16,0,0,1,112,52Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,136a8,8,0,0,0-8,8,40,40,0,0,1-40,40,47.9,47.9,0,0,0-32,12.3V120h32a8,8,0,0,0,0-16H136V78.8a28,28,0,1,0-16,0V104H88a8,8,0,0,0,0,16h32v76.3A47.9,47.9,0,0,0,88,184a40,40,0,0,1-40-40,8,8,0,0,0-16,0,56,56,0,0,0,56,56,32.1,32.1,0,0,1,32,32,8,8,0,0,0,16,0,32.1,32.1,0,0,1,32-32,56,56,0,0,0,56-56A8,8,0,0,0,216,136ZM116,52a12,12,0,1,1,12,12A12,12,0,0,1,116,52Z" />
        </>
      )}
    </svg>
  );
}

export function PhAndroidLogo(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M180,156a16,16,0,1,1-16-16A16,16,0,0,1,180,156ZM92,140a16,16,0,1,0,16,16A16,16,0,0,0,92,140Zm152,28v24a20.1,20.1,0,0,1-20,20H32a20.1,20.1,0,0,1-20-20V169.1A117.4,117.4,0,0,1,45.7,86.7L23.5,64.5a12,12,0,0,1,17-17L64.3,71.3A114.7,114.7,0,0,1,127.6,52h.4a115.5,115.5,0,0,1,63.9,19.1l23.6-23.6a12,12,0,0,1,17,17l-22,22A115,115,0,0,1,244,168Zm-24,0a92,92,0,0,0-92.3-92C77.1,76.2,36,118,36,169.1V188H220Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M232,168v24a8,8,0,0,1-8,8H32a8,8,0,0,1-8-8V169.1C24,111.6,70.2,64.2,127.6,64A104,104,0,0,1,232,168Z"
            opacity="0.2"
          />
          <path d="M176,156a12,12,0,1,1-12-12A12,12,0,0,1,176,156ZM92,144a12,12,0,1,0,12,12A12,12,0,0,0,92,144Zm148,24v24a16,16,0,0,1-16,16H32a16,16,0,0,1-16-16V169.1A113.4,113.4,0,0,1,51.4,86.7l-25.1-25A8.1,8.1,0,0,1,37.7,50.3L63.8,76.5a111.5,111.5,0,0,1,128.6-.2l25.9-26a8.1,8.1,0,0,1,11.4,11.4L204.8,86.5l2.3,2.2A111.2,111.2,0,0,1,240,168Zm-16,0a95.3,95.3,0,0,0-28.2-68,98.5,98.5,0,0,0-7.7-6.8.1.1,0,0,0-.1-.1A95,95,0,0,0,128,72h-.3C74.9,72.2,32,115.8,32,169.1V192H224Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M207.1,88.7l-2.3-2.2,24.9-24.8a8.1,8.1,0,0,0-11.4-11.4l-25.9,26a111.5,111.5,0,0,0-128.6.2L37.7,50.3A8.1,8.1,0,0,0,26.3,61.7l25.1,25A113.4,113.4,0,0,0,16,169.1V192a16,16,0,0,0,16,16H224a16,16,0,0,0,16-16V168A111.2,111.2,0,0,0,207.1,88.7ZM92,168a12,12,0,1,1,12-12A12,12,0,0,1,92,168Zm72,0a12,12,0,1,1,12-12A12,12,0,0,1,164,168Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M174,156a10,10,0,1,1-10-10A10,10,0,0,1,174,156ZM92,146a10,10,0,1,0,10,10A10,10,0,0,0,92,146Zm146,22v24a14,14,0,0,1-14,14H32a14,14,0,0,1-14-14V169.1A111,111,0,0,1,54.3,86.8L27.8,60.2a5.9,5.9,0,1,1,8.4-8.4L63.6,79.1a108.6,108.6,0,0,1,64-21.1h.4a109.3,109.3,0,0,1,64.6,20.9l27.2-27.1a5.9,5.9,0,0,1,8.4,8.4L201.9,86.6c1.3,1.1,2.5,2.3,3.7,3.5A109.1,109.1,0,0,1,238,168Zm-12,0a98,98,0,0,0-98-98h-.3C73.8,70.2,30,114.7,30,169.1V192a2,2,0,0,0,2,2H224a2,2,0,0,0,2-2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M172,156a8,8,0,1,1-8-8A8,8,0,0,1,172,156Zm-80-8a8,8,0,1,0,8,8A8,8,0,0,0,92,148Zm144,20v24a12,12,0,0,1-12,12H32a12,12,0,0,1-12-12V169.1A109.6,109.6,0,0,1,57.2,86.8l-28-28a4,4,0,0,1,5.6-5.6L63.4,81.8A106.7,106.7,0,0,1,127.6,60h.4a107.2,107.2,0,0,1,64.8,21.6l28.4-28.4a4,4,0,1,1,5.6,5.6L199,86.6l5.2,4.9A107.3,107.3,0,0,1,236,168Zm-8,0A100,100,0,0,0,128,68h-.4C72.7,68.2,28,113.6,28,169.1V192a4,4,0,0,0,4,4H224a4,4,0,0,0,4-4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M176,156a12,12,0,1,1-12-12A12,12,0,0,1,176,156ZM92,144a12,12,0,1,0,12,12A12,12,0,0,0,92,144Zm148,24v24a16,16,0,0,1-16,16H32a16,16,0,0,1-16-16V169.1A113.4,113.4,0,0,1,51.4,86.7l-25.1-25A8.1,8.1,0,0,1,37.7,50.3L63.8,76.5a111.5,111.5,0,0,1,128.6-.2l25.9-26a8.1,8.1,0,0,1,11.4,11.4L204.8,86.5l2.3,2.2A111.2,111.2,0,0,1,240,168Zm-16,0a95.3,95.3,0,0,0-28.2-68,98.5,98.5,0,0,0-7.7-6.8.1.1,0,0,0-.1-.1A95,95,0,0,0,128,72h-.3C74.9,72.2,32,115.8,32,169.1V192H224Z" />
        </>
      )}
    </svg>
  );
}

export function PhAngularLogo(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M228.6,60.9l-96-40a12.2,12.2,0,0,0-9.2,0l-96,40a12.1,12.1,0,0,0-7.3,12.7l16,120a11.9,11.9,0,0,0,6.5,9.1l80,40a11.9,11.9,0,0,0,10.8,0l80-40a11.9,11.9,0,0,0,6.5-9.1l16-120A12.1,12.1,0,0,0,228.6,60.9ZM196.9,184.1,128,218.6,59.1,184.1,45.1,79.5,128,45l82.9,34.5ZM117.3,82.6l-36,72a12,12,0,1,0,21.4,10.8l5.4-10.7h39.8l5.4,10.7a12,12,0,1,0,21.4-10.8l-36-72a12,12,0,0,0-21.4,0Zm2.8,48.1,7.9-15.9,7.9,15.9Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M224,72,208,192l-80,40L48,192,32,72l96-40Z" opacity="0.2" />
          <path d="M227.1,64.6l-96-40a8.3,8.3,0,0,0-6.2,0l-96,40a8.1,8.1,0,0,0-4.8,8.5l16,120a8.1,8.1,0,0,0,4.3,6.1l80,40a8.5,8.5,0,0,0,7.2,0l80-40a8.1,8.1,0,0,0,4.3-6.1l16-120A8.1,8.1,0,0,0,227.1,64.6ZM200.6,186.7,128,223.1,55.4,186.7,40.7,77,128,40.7,215.3,77ZM120.8,84.4l-36,72a8,8,0,0,0,14.4,7.2l8.4-16.9h40.8l8.4,16.9A8.1,8.1,0,0,0,164,168a9.4,9.4,0,0,0,3.6-.8,8.2,8.2,0,0,0,3.6-10.8l-36-72a8.1,8.1,0,0,0-14.4,0Zm19.6,46.3H115.6L128,105.9Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M227.1,64.6l-96-40a8.3,8.3,0,0,0-6.2,0l-96,40a8.1,8.1,0,0,0-4.8,8.5l16,120a8.1,8.1,0,0,0,4.3,6.1l80,40a8.5,8.5,0,0,0,7.2,0l80-40a8.1,8.1,0,0,0,4.3-6.1l16-120A8.1,8.1,0,0,0,227.1,64.6ZM167.6,167.2a9.4,9.4,0,0,1-3.6.8,8.1,8.1,0,0,1-7.2-4.4l-8.4-16.9H107.6l-8.4,16.9a8,8,0,0,1-14.4-7.2l36-72a8.1,8.1,0,0,1,14.4,0l36,72A8.2,8.2,0,0,1,167.6,167.2ZM128,105.9l12.4,24.8H115.6Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M226.3,66.5l-96-40a5.3,5.3,0,0,0-4.6,0l-96,40a5.9,5.9,0,0,0-3.6,6.3l16,120a5.8,5.8,0,0,0,3.2,4.6l80,40a6.4,6.4,0,0,0,5.4,0l80-40a5.8,5.8,0,0,0,3.2-4.6l16-120A5.9,5.9,0,0,0,226.3,66.5ZM202.5,188.1,128,225.3,53.5,188.1,38.6,75.8,128,38.5l89.4,37.3ZM122.6,85.3l-36,72a6,6,0,0,0,10.8,5.4l9-18h43.2l9,18A6.1,6.1,0,0,0,164,166a6.6,6.6,0,0,0,2.7-.6,6.2,6.2,0,0,0,2.7-8.1l-36-72a6.1,6.1,0,0,0-10.8,0Zm21,47.4H112.4L128,101.4Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M225.5,68.3l-96-40a3.9,3.9,0,0,0-3,0l-96,40A4,4,0,0,0,28,72.5l16,120a4.2,4.2,0,0,0,2.2,3.1l80,40a4.2,4.2,0,0,0,3.6,0l80-40a4.2,4.2,0,0,0,2.2-3.1l16-120A4,4,0,0,0,225.5,68.3ZM204.3,189.4,128,227.5,51.7,189.4,36.4,74.5,128,36.3l91.6,38.2ZM124.4,86.2l-36,72a4,4,0,0,0,7.2,3.6l9.5-19.1h45.8l9.5,19.1A4,4,0,0,0,164,164a3.9,3.9,0,0,0,1.8-.4,4,4,0,0,0,1.8-5.4l-36-72a4,4,0,0,0-7.2,0Zm22.5,48.5H109.1L128,96.9Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M227.1,64.6l-96-40a8.3,8.3,0,0,0-6.2,0l-96,40a8.1,8.1,0,0,0-4.8,8.5l16,120a8.1,8.1,0,0,0,4.3,6.1l80,40a8.5,8.5,0,0,0,7.2,0l80-40a8.1,8.1,0,0,0,4.3-6.1l16-120A8.1,8.1,0,0,0,227.1,64.6ZM200.6,186.7,128,223.1,55.4,186.7,40.7,77,128,40.7,215.3,77ZM120.8,84.4l-36,72a8,8,0,0,0,14.4,7.2l8.4-16.9h40.8l8.4,16.9A8.1,8.1,0,0,0,164,168a9.4,9.4,0,0,0,3.6-.8,8.2,8.2,0,0,0,3.6-10.8l-36-72a8.1,8.1,0,0,0-14.4,0Zm19.6,46.3H115.6L128,105.9Z" />
        </>
      )}
    </svg>
  );
}

export function PhAperture(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M204.4,51.6A108,108,0,1,0,127.9,236h.2A108,108,0,0,0,204.4,51.6Zm-91.6,94.3L105,123.8l15.1-17.9,23.1,4.2,7.8,22.1-15.1,17.9ZM51.5,93.4,92.9,101,48.1,153.7A84.3,84.3,0,0,1,51.5,93.4Zm156.4,8.9a84.3,84.3,0,0,1-3.4,60.3L163.1,155ZM196.2,79,169,111.1,145.7,45.9a83,83,0,0,1,41.7,22.7A85.1,85.1,0,0,1,196.2,79ZM119.7,44.4,133.8,84,65.7,71.6l2.9-3A83.5,83.5,0,0,1,119.7,44.4ZM59.8,177,87,144.9l23.3,65.2a83,83,0,0,1-41.7-22.7A85.1,85.1,0,0,1,59.8,177Zm76.5,34.6L122.2,172l16.2,2.9h.1l51.8,9.5-2.9,3A83.5,83.5,0,0,1,136.3,211.6Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M195.9,60.1a96,96,0,1,0,0,135.8A96,96,0,0,0,195.9,60.1Zm-31.2,74.6-24.2,28.4h0l-36.6-6.7h0L91.3,121.3h0l24.2-28.4h0l36.6,6.7h0l12.6,35.1Z" />
          </g>
          <path d="M232,128A104.1,104.1,0,0,0,128,24,104.1,104.1,0,0,0,24,128,104.1,104.1,0,0,0,128,232h.1A104.1,104.1,0,0,0,232,128ZM109.8,149.4,100.4,123l18.2-21.4,27.6,5,9.4,26.4-18.2,21.4ZM49.2,88.9l51.2,9.4L46.7,161.5A88,88,0,0,1,49.2,88.9Zm160.1,5.6a88,88,0,0,1-2.5,72.6l-51.2-9.4Zm-8-15.2L167.6,119l-28-78.2a86.8,86.8,0,0,1,50.6,25A88.5,88.5,0,0,1,201.3,79.3ZM122.4,40.2l17.5,49L58.3,74.3a99.2,99.2,0,0,1,7.5-8.5A87.1,87.1,0,0,1,122.4,40.2ZM54.7,176.7,88.4,137l28,78.2a86.8,86.8,0,0,1-50.6-25A88.5,88.5,0,0,1,54.7,176.7Zm78.9,39.1-17.5-49,23,4.2h.1l58.5,10.7a99.2,99.2,0,0,1-7.5,8.5A87.1,87.1,0,0,1,133.6,215.8Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M232,128A104.1,104.1,0,0,0,128,24,104.1,104.1,0,0,0,24,128,104.1,104.1,0,0,0,128,232h.1A104.1,104.1,0,0,0,232,128ZM49.2,88.9l51.2,9.4L46.7,161.5A88,88,0,0,1,49.2,88.9Zm160.1,5.6a88,88,0,0,1-2.5,72.6l-51.2-9.4Zm-8-15.2L167.6,119l-28-78.2a86.8,86.8,0,0,1,50.6,25A88.5,88.5,0,0,1,201.3,79.3ZM122.4,40.2l17.5,49L58.3,74.3a99.2,99.2,0,0,1,7.5-8.5A87.1,87.1,0,0,1,122.4,40.2ZM54.7,176.7,88.4,137l28,78.2a86.8,86.8,0,0,1-50.6-25A88.5,88.5,0,0,1,54.7,176.7Zm78.9,39.1-17.5-49,23,4.2h.1l58.5,10.7a99.2,99.2,0,0,1-7.5,8.5A87.1,87.1,0,0,1,133.6,215.8Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M230,128A102,102,0,0,0,55.9,55.9a101.9,101.9,0,0,0,0,144.2A100.9,100.9,0,0,0,128,230h0A102.1,102.1,0,0,0,230,128Zm-22,41.3-56.2-10.2,58.1-68.4a90.1,90.1,0,0,1-1.9,78.6Zm-99.7-18.2L98.1,122.5l19.7-23.1,29.9,5.5,10.2,28.6-19.7,23.1Zm95.5-71.7-37,43.5L136.7,38.4a89.1,89.1,0,0,1,54.9,26A87.1,87.1,0,0,1,203.8,79.4ZM64.4,64.4a89.1,89.1,0,0,1,59.4-26.3L143,91.8,54.8,75.7A78.7,78.7,0,0,1,64.4,64.4ZM48,86.7l56.2,10.2L46.1,165.3A90.1,90.1,0,0,1,48,86.7Zm4.2,89.9,37-43.5,30.1,84.5a89.1,89.1,0,0,1-54.9-26A87.1,87.1,0,0,1,52.2,176.6Zm80,41.3L113,164.2l26.5,4.8h0l61.7,11.3a78.7,78.7,0,0,1-9.6,11.3A89.1,89.1,0,0,1,132.2,217.9Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M228,128A100,100,0,0,0,57.3,57.3,100,100,0,0,0,128,228h0A100,100,0,0,0,228,128Zm-18.9,43.6-61-11.2L210.4,87a92.5,92.5,0,0,1-1.3,84.6ZM106.9,152.9l-11-30.8L117,97.2l32.1,5.9,11,30.8L139,158.8Zm99.3-73.3-40.1,47.2L133.7,36.2a91.7,91.7,0,0,1,59.4,26.7A95.4,95.4,0,0,1,206.2,79.6ZM62.9,62.9a91.6,91.6,0,0,1,62.3-26.8L146,94.4,51.3,77.1A104.3,104.3,0,0,1,62.9,62.9Zm-16,21.5,61,11.2L45.6,169a92.5,92.5,0,0,1,1.3-84.6Zm2.9,92,40.1-47.2,32.4,90.6a91.7,91.7,0,0,1-59.4-26.7A95.4,95.4,0,0,1,49.8,176.4Zm81,43.5L110,161.6l29.8,5.4h.1l64.8,11.8a104.3,104.3,0,0,1-11.6,14.2A91.6,91.6,0,0,1,130.8,219.9Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M232,128A104.1,104.1,0,0,0,128,24,104.1,104.1,0,0,0,24,128,104.1,104.1,0,0,0,128,232h.1A104.1,104.1,0,0,0,232,128ZM109.8,149.4,100.4,123l18.2-21.4,27.6,5,9.4,26.4-18.2,21.4ZM49.2,88.9l51.2,9.4L46.7,161.5A88,88,0,0,1,49.2,88.9Zm160.1,5.6a88,88,0,0,1-2.5,72.6l-51.2-9.4Zm-8-15.2L167.6,119l-28-78.2a86.8,86.8,0,0,1,50.6,25A88.5,88.5,0,0,1,201.3,79.3ZM122.4,40.2l17.5,49L58.3,74.3a99.2,99.2,0,0,1,7.5-8.5A87.1,87.1,0,0,1,122.4,40.2ZM54.7,176.7,88.4,137l28,78.2a86.8,86.8,0,0,1-50.6-25A88.5,88.5,0,0,1,54.7,176.7Zm78.9,39.1-17.5-49,23,4.2h.1l58.5,10.7a99.2,99.2,0,0,1-7.5,8.5A87.1,87.1,0,0,1,133.6,215.8Z" />
        </>
      )}
    </svg>
  );
}

export function PhAppStoreLogo(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M244,168a12,12,0,0,1-12,12H200.7l17.6,29.9a11.9,11.9,0,0,1-4.2,16.4A11.3,11.3,0,0,1,208,228a11.9,11.9,0,0,1-10.3-5.9L140.9,126a12,12,0,0,1,20.7-12.2L186.5,156H232A12,12,0,0,1,244,168ZM133.2,156H97.4L162.3,46.1a12,12,0,0,0-20.6-12.2L128,57,114.3,33.9A12,12,0,1,0,93.7,46.1l20.4,34.5L69.5,156H24a12,12,0,0,0,0,24H133.2a12,12,0,0,0,0-24ZM58.8,197.7a11.9,11.9,0,0,0-16.4,4.2l-4.7,8a11.9,11.9,0,0,0,4.2,16.4A11.3,11.3,0,0,0,48,228a11.9,11.9,0,0,0,10.3-5.9l4.8-8A12,12,0,0,0,58.8,197.7Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M64.3,204.1l-9.4,16A8.1,8.1,0,0,1,48,224a8.7,8.7,0,0,1-4.1-1.1,8.1,8.1,0,0,1-2.8-11l9.5-16a8,8,0,1,1,13.7,8.2ZM232,160H184.2l-30.7-52a8,8,0,0,0-11-2.8,7.9,7.9,0,0,0-2.8,10.9l61.4,104A8.1,8.1,0,0,0,208,224a8.7,8.7,0,0,0,4.1-1.1,8.1,8.1,0,0,0,2.8-11L193.7,176H232a8,8,0,0,0,0-16Zm-89.5,0H90.4L158.9,44.1a8,8,0,0,0-13.8-8.2L128,64.9l-17.1-29a8,8,0,1,0-13.8,8.2l21.6,36.5L71.8,160H24a8,8,0,0,0,0,16H142.5a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M64.3,204.1l-9.4,16A8.1,8.1,0,0,1,48,224a8.7,8.7,0,0,1-4.1-1.1,8.1,8.1,0,0,1-2.8-11l9.5-16a8,8,0,1,1,13.7,8.2ZM232,160H184.2l-30.7-52a8,8,0,0,0-11-2.8,7.9,7.9,0,0,0-2.8,10.9l61.4,104A8.1,8.1,0,0,0,208,224a8.7,8.7,0,0,0,4.1-1.1,8.1,8.1,0,0,0,2.8-11L193.7,176H232a8,8,0,0,0,0-16Zm-89.5,0H90.4l44.4-75.2h.1l24-40.6a8,8,0,0,0-13.8-8.2L128,64.9l-17.1-29a8,8,0,1,0-13.8,8.2l21.6,36.5L71.8,160H24a8,8,0,0,0,0,16H142.5a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M62.6,203.1l-9.4,16A6.4,6.4,0,0,1,48,222a7.1,7.1,0,0,1-3.1-.8,6.1,6.1,0,0,1-2.1-8.3l9.5-16a6,6,0,1,1,10.3,6.2ZM232,162H183.1l-31.3-53a6.1,6.1,0,0,0-8.3-2.1,6,6,0,0,0-2.1,8.2l61.4,104A6.4,6.4,0,0,0,208,222a7.1,7.1,0,0,0,3.1-.8,6.1,6.1,0,0,0,2.1-8.3l-23-38.9H232a6,6,0,0,0,0-12Zm-89.5,0H86.9L157.2,43.1a6.1,6.1,0,0,0-10.4-6.2L128,68.8,109.2,36.9a6.1,6.1,0,1,0-10.4,6.2L121,80.6,72.9,162H24a6,6,0,0,0,0,12H142.5a6,6,0,0,0,0-12Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M60.9,202l-9.5,16a3.8,3.8,0,0,1-3.4,2,3.6,3.6,0,0,1-2-.6,3.8,3.8,0,0,1-1.4-5.4L54,198a4,4,0,0,1,6.9,4ZM232,164H181.9L150,110a4,4,0,1,0-6.9,4.1L204.6,218a3.8,3.8,0,0,0,3.4,2,3.6,3.6,0,0,0,2-.6,3.8,3.8,0,0,0,1.4-5.4l-24.8-42H232a4,4,0,0,0,0-8Zm-89.5,0H83.4l72-122a3.9,3.9,0,1,0-6.8-4L128,72.8,107.4,38a3.9,3.9,0,1,0-6.8,4l22.8,38.6L74.1,164H24a4,4,0,0,0,0,8H142.5a4,4,0,0,0,0-8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M64.3,204.1l-9.4,16A8.1,8.1,0,0,1,48,224a8.7,8.7,0,0,1-4.1-1.1,8.1,8.1,0,0,1-2.8-11l9.5-16a8,8,0,1,1,13.7,8.2ZM232,160H184.2l-30.7-52a8,8,0,0,0-11-2.8,7.9,7.9,0,0,0-2.8,10.9l61.4,104A8.1,8.1,0,0,0,208,224a8.7,8.7,0,0,0,4.1-1.1,8.1,8.1,0,0,0,2.8-11L193.7,176H232a8,8,0,0,0,0-16Zm-89.5,0H90.4l44.4-75.2h.1l24-40.6a8,8,0,0,0-13.8-8.2L128,64.9l-17.1-29a8,8,0,1,0-13.8,8.2l21.6,36.5L71.8,160H24a8,8,0,0,0,0,16H142.5a8,8,0,0,0,0-16Z" />
        </>
      )}
    </svg>
  );
}

export function PhAppWindow(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216,36H40A20.1,20.1,0,0,0,20,56V200a20.1,20.1,0,0,0,20,20H216a20.1,20.1,0,0,0,20-20V56A20.1,20.1,0,0,0,216,36Zm-4,160H44V60H212ZM60,92a16,16,0,1,1,16,16A16,16,0,0,1,60,92Zm48,0a16,16,0,1,1,16,16A16,16,0,0,1,108,92Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M224,56V200a8,8,0,0,1-8,8H40a8,8,0,0,1-8-8V56a8,8,0,0,1,8-8H216A8,8,0,0,1,224,56Z"
            opacity="0.2"
          />
          <path d="M216,40H40A16,16,0,0,0,24,56V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40Zm0,160H40V56H216V200ZM80,84A12,12,0,1,1,68,72,12,12,0,0,1,80,84Zm40,0a12,12,0,1,1-12-12A12,12,0,0,1,120,84Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,40H40A16,16,0,0,0,24,56V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40ZM68,96A12,12,0,1,1,80,84,12,12,0,0,1,68,96Zm40,0a12,12,0,1,1,12-12A12,12,0,0,1,108,96Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M216,42H40A14,14,0,0,0,26,56V200a14,14,0,0,0,14,14H216a14,14,0,0,0,14-14V56A14,14,0,0,0,216,42Zm2,158a2,2,0,0,1-2,2H40a2,2,0,0,1-2-2V56a2,2,0,0,1,2-2H216a2,2,0,0,1,2,2ZM78,84A10,10,0,1,1,68,74,10,10,0,0,1,78,84Zm40,0a10,10,0,1,1-10-10A10,10,0,0,1,118,84Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M216,44H40A12,12,0,0,0,28,56V200a12,12,0,0,0,12,12H216a12,12,0,0,0,12-12V56A12,12,0,0,0,216,44Zm4,156a4,4,0,0,1-4,4H40a4,4,0,0,1-4-4V56a4,4,0,0,1,4-4H216a4,4,0,0,1,4,4ZM76,84a8,8,0,1,1-8-8A8,8,0,0,1,76,84Zm40,0a8,8,0,1,1-8-8A8,8,0,0,1,116,84Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,40H40A16,16,0,0,0,24,56V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40Zm0,160H40V56H216V200ZM80,84A12,12,0,1,1,68,72,12,12,0,0,1,80,84Zm40,0a12,12,0,1,1-12-12A12,12,0,0,1,120,84Z" />
        </>
      )}
    </svg>
  );
}

export function PhAppleLogo(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M223.8,152.2a36,36,0,0,1-2.4-63,12,12,0,0,0,3.2-17.7A71.6,71.6,0,0,0,168,44a72.6,72.6,0,0,0-40,12.1A72,72,0,0,0,16,116c0,26.3,8.6,54.1,23.6,76.2S74,228,92,228h72c12.6,0,25.7-6.4,38.1-18.4s20.8-25.8,27.5-42.1A12,12,0,0,0,223.8,152.2ZM164,204H92c-19.6,0-52-41.1-52-88a48,48,0,0,1,80-35.8l.2.2a12.1,12.1,0,0,0,16-.3,47.9,47.9,0,0,1,61-2.2,60.2,60.2,0,0,0,6,89.6C191.5,190.5,174.8,204,164,204ZM134.4,15.6A44,44,0,0,1,168,0a12,12,0,0,1,0,24,20,20,0,0,0-15.3,7.1,12.2,12.2,0,0,1-9.2,4.3,12.1,12.1,0,0,1-9.1-19.8Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M218.5,162.9C205.9,193.7,183.1,216,164,216H92c-28,0-64-48-64-100A60,60,0,0,1,128,71.3h0a60,60,0,0,1,87.2,7.6h0a48,48,0,0,0,3.3,84Z" />
          </g>
          <path d="M130.7,29.6A40.2,40.2,0,0,1,168,4a8,8,0,0,1,0,16,24.1,24.1,0,0,0-22.4,15.4,8,8,0,0,1-7.5,5.1,9.2,9.2,0,0,1-2.8-.5A8,8,0,0,1,130.7,29.6ZM225.9,166c-14,34.1-39.4,58-61.9,58H92c-16.4,0-34.7-12.7-49.1-34A135,135,0,0,1,20,116,68,68,0,0,1,128,61a68,68,0,0,1,93.5,13,8.2,8.2,0,0,1-2.2,11.8,40,40,0,0,0,2.7,70A7.9,7.9,0,0,1,225.9,166Zm-17.6.2a56.1,56.1,0,0,1-5.1-88.5,52,52,0,0,0-69.7-.6,8.1,8.1,0,0,1-10.7.3v-.2A52.1,52.1,0,0,0,36,116c0,49.9,34.4,92,56,92h72C178.4,208,196.7,190.4,208.3,166.2Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M130.7,29.6A40.2,40.2,0,0,1,168,4a8,8,0,0,1,0,16,24.1,24.1,0,0,0-22.4,15.4,8,8,0,0,1-7.5,5.1,9.2,9.2,0,0,1-2.8-.5A8,8,0,0,1,130.7,29.6ZM222,155.8a40,40,0,0,1-2.7-70A8.2,8.2,0,0,0,221.5,74,68,68,0,0,0,128,61,68,68,0,0,0,20,116a135,135,0,0,0,22.9,74c14.4,21.3,32.7,34,49.1,34h72c22.5,0,47.9-23.9,61.9-58A7.9,7.9,0,0,0,222,155.8Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M132.5,30.4A38.3,38.3,0,0,1,168,6a6,6,0,0,1,0,12,26.2,26.2,0,0,0-24.3,16.7,6.1,6.1,0,0,1-5.6,3.8,5.2,5.2,0,0,1-2.1-.4A6,6,0,0,1,132.5,30.4Zm91.6,134.8C210.4,198.6,185.7,222,164,222H92c-15.7,0-33.5-12.4-47.5-33.1S22,141.1,22,116A66,66,0,0,1,128,63.5a66,66,0,0,1,91.9,11.7,6.4,6.4,0,0,1,1.2,4.8,5.9,5.9,0,0,1-2.8,4,42,42,0,0,0,2.9,73.5A6.1,6.1,0,0,1,224.1,165.2Zm-13.2.3a54.1,54.1,0,0,1-4.7-87.7,54,54,0,0,0-74.1-2.1,6.1,6.1,0,0,1-8.1.1A54,54,0,0,0,34,116c0,22.8,7.5,46.9,20.5,66.1C66.1,199.3,80.5,210,92,210h72C179.4,210,199,191.2,210.9,165.5Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M134.4,31.1A36.2,36.2,0,0,1,168,8a4,4,0,0,1,0,8,28.1,28.1,0,0,0-26.1,17.9,4.2,4.2,0,0,1-3.8,2.6,3.5,3.5,0,0,1-1.4-.3A3.9,3.9,0,0,1,134.4,31.1Zm87.8,133.3C208.8,197.2,184.9,220,164,220H92c-30,0-68-49-68-104A64,64,0,0,1,128,66a64.1,64.1,0,0,1,90.3,10.4,4.1,4.1,0,0,1,.8,3.2,3.8,3.8,0,0,1-1.9,2.7,44,44,0,0,0,3.1,77A4.1,4.1,0,0,1,222.2,164.4Zm-8.8.3a52,52,0,0,1-4.2-86.6,56,56,0,0,0-78.5-3.9,4,4,0,0,1-5.4.1A55.9,55.9,0,0,0,32,116c0,23.2,7.6,47.7,20.8,67.3C64.8,201,79.8,212,92,212h72C180.8,212,201.2,192.3,213.4,164.7Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M130.7,29.6A40.2,40.2,0,0,1,168,4a8,8,0,0,1,0,16,24.1,24.1,0,0,0-22.4,15.4,8,8,0,0,1-7.5,5.1,9.2,9.2,0,0,1-2.8-.5A8,8,0,0,1,130.7,29.6ZM225.9,166c-14,34.1-39.4,58-61.9,58H92c-16.4,0-34.7-12.7-49.1-34A135,135,0,0,1,20,116,68,68,0,0,1,128,61a68,68,0,0,1,93.5,13,8.2,8.2,0,0,1-2.2,11.8,40,40,0,0,0,2.7,70A7.9,7.9,0,0,1,225.9,166Zm-17.6.2a56.1,56.1,0,0,1-5.1-88.5,52,52,0,0,0-69.7-.6,8.1,8.1,0,0,1-10.8.2h0A52.1,52.1,0,0,0,36,116c0,49.9,34.4,92,56,92h72C178.4,208,196.7,190.4,208.3,166.2Z" />
        </>
      )}
    </svg>
  );
}

export function PhApplePodcastsLogo(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M161.6,129.6A38.9,38.9,0,0,0,168,108a40,40,0,0,0-80,0,38.9,38.9,0,0,0,6.4,21.6,28.5,28.5,0,0,0-4.7,4.5A27.6,27.6,0,0,0,83.6,157l10.2,56a28,28,0,0,0,27.6,23h13.2a28,28,0,0,0,27.6-23l10.2-56a27.6,27.6,0,0,0-6.1-22.9A28.5,28.5,0,0,0,161.6,129.6ZM128,92a16,16,0,1,1-16,16A16,16,0,0,1,128,92Zm20.8,60.7-10.2,56a4.1,4.1,0,0,1-4,3.3H121.4a4.1,4.1,0,0,1-4-3.3l-10.2-56a4.2,4.2,0,0,1,.9-3.3,3.9,3.9,0,0,1,3.1-1.4h33.6a3.9,3.9,0,0,1,3.1,1.4A4.2,4.2,0,0,1,148.8,152.7ZM236,128a108.3,108.3,0,0,1-34.5,79.1,12,12,0,1,1-16.3-17.5,84,84,0,1,0-114.4,0,12,12,0,1,1-16.3,17.5A108,108,0,1,1,236,128Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M104,120a24,24,0,1,1,24,24A23.9,23.9,0,0,1,104,120Zm36.5,24h-25a16,16,0,0,0-15.7,19.1l9.6,48A16,16,0,0,0,125.1,224h5.8a16,16,0,0,0,15.7-12.9l9.6-48A16,16,0,0,0,140.5,144Z"
            opacity="0.2"
          />
          <path d="M153.2,139.7a32,32,0,1,0-50.4,0,20.1,20.1,0,0,0-5.8,5.1,23.9,23.9,0,0,0-5,19.9l9.6,48A24,24,0,0,0,125.1,232h5.8a24,24,0,0,0,23.5-19.3l9.6-48a23.9,23.9,0,0,0-5-19.9A20.1,20.1,0,0,0,153.2,139.7ZM128,104a16,16,0,1,1-16,16A16,16,0,0,1,128,104Zm20.3,57.6-9.6,48a7.9,7.9,0,0,1-7.8,6.4h-5.8a7.9,7.9,0,0,1-7.8-6.4l-9.6-48a8.1,8.1,0,0,1,1.6-6.7,8.3,8.3,0,0,1,6.2-2.9h25a8.3,8.3,0,0,1,6.2,2.9A8.1,8.1,0,0,1,148.3,161.6ZM184,128A56,56,0,1,0,75.8,148.3a8,8,0,0,1-4.6,10.3,7.2,7.2,0,0,1-2.9.6,8,8,0,0,1-7.4-5.1A70.3,70.3,0,0,1,56,128a72,72,0,0,1,144,0,70.7,70.7,0,0,1-4.9,26.1,8,8,0,0,1-14.9-5.8A56.2,56.2,0,0,0,184,128Zm48,0a103.9,103.9,0,0,1-48,87.7,8.8,8.8,0,0,1-4.3,1.2,8,8,0,0,1-4.4-14.7,88,88,0,1,0-94.6,0A8,8,0,0,1,72,215.7,104,104,0,1,1,232,128Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,144a28,28,0,1,1,28-28A28.1,28.1,0,0,1,128,144Zm12.5,0h-25a19.9,19.9,0,0,0-15.4,7.3,19.6,19.6,0,0,0-4.2,16.6l9.6,48A20.1,20.1,0,0,0,125.1,232h5.8a20.1,20.1,0,0,0,19.6-16.1l9.6-48a19.6,19.6,0,0,0-4.2-16.6A19.9,19.9,0,0,0,140.5,144Zm39.7,4.3a8,8,0,0,0,14.9,5.8A70.7,70.7,0,0,0,200,128a72,72,0,0,0-144,0,70.3,70.3,0,0,0,4.9,26.1,8,8,0,0,0,7.4,5.1,7.2,7.2,0,0,0,2.9-.6,8,8,0,0,0,4.6-10.3,56,56,0,1,1,104.4,0ZM128,24A104,104,0,0,0,72,215.7a8,8,0,0,0,8.7-13.5,88,88,0,1,1,94.6,0,8,8,0,0,0,4.4,14.7,8.8,8.8,0,0,0,4.3-1.2A104,104,0,0,0,128,24Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M150.1,140.2A29.7,29.7,0,0,0,158,120a30,30,0,0,0-60,0,29.7,29.7,0,0,0,7.9,20.2,22.2,22.2,0,0,0-7.4,5.9,21.8,21.8,0,0,0-4.6,18.2l9.6,48A22.1,22.1,0,0,0,125.1,230h5.8a22.1,22.1,0,0,0,21.6-17.7l9.6-48a21.8,21.8,0,0,0-4.6-18.2A22.2,22.2,0,0,0,150.1,140.2ZM128,102a18,18,0,1,1-18,18A18.1,18.1,0,0,1,128,102Zm22.3,60-9.6,48a10.1,10.1,0,0,1-9.8,8h-5.8a10.1,10.1,0,0,1-9.8-8l-9.6-48a9.9,9.9,0,0,1,2.1-8.3,9.7,9.7,0,0,1,7.7-3.7h25a9.7,9.7,0,0,1,7.7,3.7A9.9,9.9,0,0,1,150.3,162ZM186,128A58,58,0,1,0,73.9,149a6,6,0,0,1-3.4,7.8,8.5,8.5,0,0,1-2.2.4,6,6,0,0,1-5.6-3.9,70,70,0,1,1,130.6,0,6.1,6.1,0,0,1-7.8,3.5,6.1,6.1,0,0,1-3.4-7.8A57.9,57.9,0,0,0,186,128Zm44,0a101.7,101.7,0,0,1-47.1,86,6.2,6.2,0,0,1-3.2.9,5.9,5.9,0,0,1-5.1-2.7,6,6,0,0,1,1.8-8.3,90,90,0,1,0-96.8,0A6,6,0,0,1,73.1,214,102,102,0,1,1,230,128Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M146.6,140.9a28,28,0,1,0-37.2,0,20,20,0,0,0-9.3,6.4,19.6,19.6,0,0,0-4.2,16.6l9.6,48A20.1,20.1,0,0,0,125.1,228h5.8a20.1,20.1,0,0,0,19.6-16.1l9.6-48a19.6,19.6,0,0,0-4.2-16.6A20,20,0,0,0,146.6,140.9ZM108,120a20,20,0,1,1,20,20A20.1,20.1,0,0,1,108,120Zm44.2,42.4-9.6,48a12,12,0,0,1-11.7,9.6h-5.8a12,12,0,0,1-11.7-9.6l-9.6-48a11.6,11.6,0,0,1,2.4-10,12,12,0,0,1,9.3-4.4h25a12,12,0,0,1,9.3,4.4A11.6,11.6,0,0,1,152.2,162.4ZM188,128a60,60,0,0,0-120,0,61.4,61.4,0,0,0,4,21.7,4,4,0,0,1-2.2,5.2l-1.5.3a4,4,0,0,1-3.7-2.6A66.3,66.3,0,0,1,60,128a68,68,0,0,1,136,0,66.3,66.3,0,0,1-4.6,24.6,4,4,0,1,1-7.4-2.9A61.4,61.4,0,0,0,188,128Zm40,0a99.7,99.7,0,0,1-46.2,84.3,4.2,4.2,0,0,1-2.1.6,3.8,3.8,0,0,1-3.4-1.8,3.9,3.9,0,0,1,1.2-5.5,92,92,0,1,0-99,0,4,4,0,1,1-4.3,6.7A100,100,0,1,1,228,128Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M153.2,139.7a32,32,0,1,0-50.4,0,20.1,20.1,0,0,0-5.8,5.1,23.9,23.9,0,0,0-5,19.9l9.6,48A24,24,0,0,0,125.1,232h5.8a24,24,0,0,0,23.5-19.3l9.6-48a23.9,23.9,0,0,0-5-19.9A20.1,20.1,0,0,0,153.2,139.7ZM128,104a16,16,0,1,1-16,16A16,16,0,0,1,128,104Zm20.3,57.6-9.6,48a7.9,7.9,0,0,1-7.8,6.4h-5.8a7.9,7.9,0,0,1-7.8-6.4l-9.6-48a8.1,8.1,0,0,1,1.6-6.7,8.3,8.3,0,0,1,6.2-2.9h25a8.3,8.3,0,0,1,6.2,2.9A8.1,8.1,0,0,1,148.3,161.6ZM184,128A56,56,0,1,0,75.8,148.3a8,8,0,0,1-4.6,10.3,7.2,7.2,0,0,1-2.9.6,8,8,0,0,1-7.4-5.1A70.3,70.3,0,0,1,56,128a72,72,0,0,1,144,0,70.7,70.7,0,0,1-4.9,26.1,8,8,0,0,1-14.9-5.8A56.2,56.2,0,0,0,184,128Zm48,0a103.9,103.9,0,0,1-48,87.7,8.8,8.8,0,0,1-4.3,1.2,8,8,0,0,1-4.4-14.7,88,88,0,1,0-94.6,0A8,8,0,0,1,72,215.7,104,104,0,1,1,232,128Z" />
        </>
      )}
    </svg>
  );
}

export function PhArchiveBox(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M227,71.1l-16-36A12.2,12.2,0,0,0,200,28H56a12.2,12.2,0,0,0-11,7.1l-16,36A13.8,13.8,0,0,0,28,76V208a20.1,20.1,0,0,0,20,20H208a20.1,20.1,0,0,0,20-20V76A13.8,13.8,0,0,0,227,71.1ZM192.2,52l5.3,12H58.5l5.3-12ZM52,204V88H204V204Zm112.8-60.8a12,12,0,0,1,0,17l-28.3,28.3a12.1,12.1,0,0,1-17,0L91.2,160.2a12,12,0,0,1,17-17L116,151V112a12,12,0,0,1,24,0v39l7.8-7.8A12,12,0,0,1,164.8,143.2Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M216,72V208a8,8,0,0,1-8,8H48a8,8,0,0,1-8-8V72Z"
            opacity="0.2"
          />
          <path d="M223.2,68.4l-16-32A8.2,8.2,0,0,0,200,32H56a8.2,8.2,0,0,0-7.2,4.4l-16,32A9,9,0,0,0,32,72V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V72A9,9,0,0,0,223.2,68.4ZM60.9,48H195.1l8,16H52.9ZM208,208H48V80H208V208Zm-40.4-63.6a8,8,0,0,1,0,11.3l-33.9,34a8.2,8.2,0,0,1-11.4,0l-33.9-34a8,8,0,0,1,11.3-11.3L120,164.7V104a8,8,0,0,1,16,0v60.7l20.3-20.3A8,8,0,0,1,167.6,144.4Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M223.2,68.4l-16-32A8.2,8.2,0,0,0,200,32H56a8.2,8.2,0,0,0-7.2,4.4l-16,32A9,9,0,0,0,32,72V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V72A9,9,0,0,0,223.2,68.4Zm-55.6,87.3-33.9,34a8.2,8.2,0,0,1-11.4,0l-33.9-34a8,8,0,0,1,11.3-11.3L120,164.7V104a8,8,0,0,1,16,0v60.7l20.3-20.3a8,8,0,0,1,11.3,11.3ZM52.9,64l8-16H195.1l8,16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M221.4,69.3l-16-32A6,6,0,0,0,200,34H56a6,6,0,0,0-5.4,3.3l-16,32A6.3,6.3,0,0,0,34,72V208a14,14,0,0,0,14,14H208a14,14,0,0,0,14-14V72A6.3,6.3,0,0,0,221.4,69.3ZM59.7,46H196.3l10,20H49.7ZM208,210H48a2,2,0,0,1-2-2V78H210V208A2,2,0,0,1,208,210Zm-41.8-64.2a6.1,6.1,0,0,1,0,8.5l-34,33.9a5.8,5.8,0,0,1-8.4,0l-34-33.9a6,6,0,0,1,8.5-8.5L122,169.5V104a6,6,0,0,1,12,0v65.5l23.7-23.7A6.1,6.1,0,0,1,166.2,145.8Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M219.6,70.2l-16-32A4.2,4.2,0,0,0,200,36H56a4.2,4.2,0,0,0-3.6,2.2l-16,32A3.9,3.9,0,0,0,36,72V208a12,12,0,0,0,12,12H208a12,12,0,0,0,12-12V72A3.9,3.9,0,0,0,219.6,70.2ZM58.5,44h139l12,24H46.5ZM208,212H48a4,4,0,0,1-4-4V76H212V208A4,4,0,0,1,208,212Zm-43.2-64.8a4.2,4.2,0,0,1,0,5.7l-34,33.9a3.9,3.9,0,0,1-5.6,0l-34-33.9a4,4,0,0,1,5.7-5.7L124,174.3V104a4,4,0,0,1,8,0v70.3l27.1-27.1A4.2,4.2,0,0,1,164.8,147.2Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M223.2,68.4l-16-32A8.2,8.2,0,0,0,200,32H56a8.2,8.2,0,0,0-7.2,4.4l-16,32A9,9,0,0,0,32,72V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V72A9,9,0,0,0,223.2,68.4ZM60.9,48H195.1l8,16H52.9ZM208,208H48V80H208V208Zm-40.4-63.6a8,8,0,0,1,0,11.3l-33.9,34a8.2,8.2,0,0,1-11.4,0l-33.9-34a8,8,0,0,1,11.3-11.3L120,164.7V104a8,8,0,0,1,16,0v60.7l20.3-20.3A8,8,0,0,1,167.6,144.4Z" />
        </>
      )}
    </svg>
  );
}

export function PhArchiveTray(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M208,28H48A20.1,20.1,0,0,0,28,48V155.9h0V208a20.1,20.1,0,0,0,20,20H208a20.1,20.1,0,0,0,20-20V48A20.1,20.1,0,0,0,208,28Zm-4,24v92H179.3a19.7,19.7,0,0,0-14.1,5.9L147,168H109L90.8,149.9A19.8,19.8,0,0,0,76.7,144H52V52ZM52,204V168H75l18.2,18.1a19.7,19.7,0,0,0,14.1,5.9h41.4a19.8,19.8,0,0,0,14.1-5.9L181,168h23v36Zm39.2-79.8a12,12,0,0,1,17-17L116,115V76a12,12,0,0,1,24,0v39l7.8-7.8a12,12,0,0,1,17,17l-28.3,28.3h-.1l-.8.8-.4.3-.5.4-.6.3-.4.3-.6.2-.5.3-.5.2-.6.2h-.6l-.6.2h-4.6l-.6-.2h-.6l-.6-.2-.5-.2-.5-.3-.6-.2-.4-.3-.6-.3-.5-.4-.4-.3a6,6,0,0,1-.8-.8h-.1Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M76.7,160a7.9,7.9,0,0,1,5.6,2.3l19.4,19.4a7.9,7.9,0,0,0,5.6,2.3h41.4a7.9,7.9,0,0,0,5.6-2.3l19.4-19.4a7.9,7.9,0,0,1,5.6-2.3H216V48a8,8,0,0,0-8-8H48a8,8,0,0,0-8,8V160Z" />
          </g>
          <path d="M208,32H48A16,16,0,0,0,32,48V159.9h0V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32Zm0,16V152H179.3a15.9,15.9,0,0,0-11.3,4.7L148.7,176H107.3L88,156.7A15.9,15.9,0,0,0,76.7,152H48V48Zm0,160H48V168H76.7L96,187.3a15.9,15.9,0,0,0,11.3,4.7h41.4a15.9,15.9,0,0,0,11.3-4.7L179.3,168H208v40Z" />
          <path d="M122.3,157.7h.2l.4.4h.2l.5.3c0,.1.1.1.1.2l.5.3h.2l.5.3h.1l.7.3h4.6l.7-.3h.1l.5-.3h.2l.5-.3c0-.1.1-.1.1-.2l.5-.3h.2l.4-.4h.2l33.9-34a8,8,0,0,0-11.3-11.3L136,132.7V72a8,8,0,0,0-16,0v60.7L99.7,112.4a8,8,0,0,0-11.3,11.3Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M208,32H48A16,16,0,0,0,32,48V159.9h0V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM88.4,112.4a8,8,0,0,1,11.3,0L120,132.7V72a8,8,0,0,1,16,0v60.7l20.3-20.3a8,8,0,0,1,11.3,11.3l-33.9,34h-.2l-.4.4h-.2l-.5.3c0,.1-.1.1-.1.2l-.5.3h-.2l-.5.3H131l-.7.3h-4.6l-.7-.3h-.1l-.5-.3h-.2l-.5-.3c0-.1-.1-.1-.1-.2l-.5-.3h-.2l-.4-.4h-.2l-33.9-34A8,8,0,0,1,88.4,112.4ZM208,208H48V168H76.7L96,187.3a15.9,15.9,0,0,0,11.3,4.7h41.4a15.9,15.9,0,0,0,11.3-4.7L179.3,168H208v40Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M208,34H48A14,14,0,0,0,34,48V159.9h0V208a14,14,0,0,0,14,14H208a14,14,0,0,0,14-14V48A14,14,0,0,0,208,34ZM48,46H208a2,2,0,0,1,2,2V154H179.3a14.3,14.3,0,0,0-9.9,4.1l-19.3,19.3a2,2,0,0,1-1.4.6H107.3a2,2,0,0,1-1.4-.6L86.6,158.1a14.3,14.3,0,0,0-9.9-4.1H46V48A2,2,0,0,1,48,46ZM208,210H48a2,2,0,0,1-2-2V166H76.7a2,2,0,0,1,1.4.6l19.3,19.3a14.3,14.3,0,0,0,9.9,4.1h41.4a14.3,14.3,0,0,0,9.9-4.1l19.3-19.3a2,2,0,0,1,1.4-.6H210v42A2,2,0,0,1,208,210ZM89.8,122.3a6,6,0,0,1,8.5-8.5L122,137.5V72a6,6,0,0,1,12,0v65.5l23.7-23.7a6,6,0,0,1,8.5,8.5l-34,33.9a.8.8,0,0,1-.4.4h-.1l-.4.3h-.1l-.4.2h-.1l-.4.2h-4.6l-.4-.2h-.1l-.4-.2h-.1l-.4-.3h-.1a.8.8,0,0,1-.4-.4Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M208,36H48A12,12,0,0,0,36,48V159.9h0V208a12,12,0,0,0,12,12H208a12,12,0,0,0,12-12V48A12,12,0,0,0,208,36ZM48,44H208a4,4,0,0,1,4,4V156H179.3a11.9,11.9,0,0,0-8.5,3.5l-19.3,19.3a3.8,3.8,0,0,1-2.8,1.2H107.3a3.6,3.6,0,0,1-2.8-1.2L85.2,159.5a11.9,11.9,0,0,0-8.5-3.5H44V48A4,4,0,0,1,48,44ZM208,212H48a4,4,0,0,1-4-4V164H76.7a3.8,3.8,0,0,1,2.8,1.2l19.3,19.3a11.9,11.9,0,0,0,8.5,3.5h41.4a11.9,11.9,0,0,0,8.5-3.5l19.3-19.3a3.6,3.6,0,0,1,2.8-1.2H212v44A4,4,0,0,1,208,212ZM91.2,120.9a4,4,0,0,1,5.7-5.7L124,142.3V72a4,4,0,0,1,8,0v70.3l27.1-27.1a4,4,0,0,1,5.7,5.7l-34,33.9-.6.5h-.1l-.6.3h-3l-.6-.3h-.1l-.6-.5Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M208,32H48A16,16,0,0,0,32,48V159.9h0V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32Zm0,16V152H179.3a15.9,15.9,0,0,0-11.3,4.7L148.7,176H107.3L88,156.7A15.9,15.9,0,0,0,76.7,152H48V48Zm0,160H48V168H76.7L96,187.3a15.9,15.9,0,0,0,11.3,4.7h41.4a15.9,15.9,0,0,0,11.3-4.7L179.3,168H208v40ZM88.4,123.7a8,8,0,0,1,11.3-11.3L120,132.7V72a8,8,0,0,1,16,0v60.7l20.3-20.3a8,8,0,0,1,11.3,11.3l-33.9,34h-.2l-.4.4h-.2l-.5.3c0,.1-.1.1-.1.2l-.5.3h-.2l-.5.3H131l-.7.3h-4.6l-.7-.3h-.1l-.5-.3h-.2l-.5-.3c0-.1-.1-.1-.1-.2l-.5-.3h-.2l-.4-.4h-.2Z" />
        </>
      )}
    </svg>
  );
}

export function PhArchive(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M224,44H32A20.1,20.1,0,0,0,12,64V88a20.1,20.1,0,0,0,16,19.6V192a20.1,20.1,0,0,0,20,20H208a20.1,20.1,0,0,0,20-20V107.6A20.1,20.1,0,0,0,244,88V64A20.1,20.1,0,0,0,224,44ZM36,68H220V84H36ZM52,188V108H204v80Zm112-52a12,12,0,0,1-12,12H104a12,12,0,0,1,0-24h48A12,12,0,0,1,164,136Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M216,96v96a8,8,0,0,1-8,8H48a8,8,0,0,1-8-8V96Z" />
          </g>
          <path d="M224,48H32A16,16,0,0,0,16,64V88a16,16,0,0,0,16,16v88a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V104a16,16,0,0,0,16-16V64A16,16,0,0,0,224,48ZM208,192H48V104H208ZM224,88H32V64H224V88ZM96,136a8,8,0,0,1,8-8h48a8,8,0,0,1,0,16H104A8,8,0,0,1,96,136Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M224,48H32A16,16,0,0,0,16,64V88a16,16,0,0,0,16,16v88a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V104a16,16,0,0,0,16-16V64A16,16,0,0,0,224,48Zm-72,96H104a8,8,0,0,1,0-16h48a8,8,0,0,1,0,16Zm72-56H32V64H224V88Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M224,50H32A14,14,0,0,0,18,64V88a14,14,0,0,0,14,14h2v90a14,14,0,0,0,14,14H208a14,14,0,0,0,14-14V102h2a14,14,0,0,0,14-14V64A14,14,0,0,0,224,50ZM210,192a2,2,0,0,1-2,2H48a2,2,0,0,1-2-2V102H210ZM226,88a2,2,0,0,1-2,2H32a2,2,0,0,1-2-2V64a2,2,0,0,1,2-2H224a2,2,0,0,1,2,2ZM98,136a6,6,0,0,1,6-6h48a6,6,0,0,1,0,12H104A6,6,0,0,1,98,136Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M224,52H32A12,12,0,0,0,20,64V88a12,12,0,0,0,12,12h4v92a12,12,0,0,0,12,12H208a12,12,0,0,0,12-12V100h4a12,12,0,0,0,12-12V64A12,12,0,0,0,224,52ZM212,192a4,4,0,0,1-4,4H48a4,4,0,0,1-4-4V100H212ZM228,88a4,4,0,0,1-4,4H32a4,4,0,0,1-4-4V64a4,4,0,0,1,4-4H224a4,4,0,0,1,4,4ZM100,136a4,4,0,0,1,4-4h48a4,4,0,0,1,0,8H104A4,4,0,0,1,100,136Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M224,48H32A16,16,0,0,0,16,64V88a16,16,0,0,0,16,16v88a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V104a16,16,0,0,0,16-16V64A16,16,0,0,0,224,48ZM208,192H48V104H208ZM224,88H32V64H224V88ZM96,136a8,8,0,0,1,8-8h48a8,8,0,0,1,0,16H104A8,8,0,0,1,96,136Z" />
        </>
      )}
    </svg>
  );
}

export function PhArmchair(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M220,78.5V72a44,44,0,0,0-44-44H80A44,44,0,0,0,36,72v6.5a52,52,0,0,0,0,99V200a20.1,20.1,0,0,0,20,20H200a20.1,20.1,0,0,0,20-20V177.5a52,52,0,0,0,0-99ZM80,52h96a20.1,20.1,0,0,1,20,20v4.6A52.1,52.1,0,0,0,152.2,124H103.8A52.1,52.1,0,0,0,60,76.6V72A20.1,20.1,0,0,1,80,52ZM206.8,155.9A11.9,11.9,0,0,0,196,167.8V196H60V167.8a11.9,11.9,0,0,0-10.8-11.9A28,28,0,1,1,80,128v36a12,12,0,0,0,24,0V148h48v16a12,12,0,0,0,24,0V128a28,28,0,1,1,30.8,27.9Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M240,128a32,32,0,0,1-32,32v40a8,8,0,0,1-8,8H56a8,8,0,0,1-8-8V160a32,32,0,0,1,0-64V72A32,32,0,0,1,80,40h96a32,32,0,0,1,32,32V96A32,32,0,0,1,240,128Z"
            opacity="0.2"
          />
          <path d="M216,88.8V72a40,40,0,0,0-40-40H80A40,40,0,0,0,40,72V88.8a40,40,0,0,0,0,78.4V200a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V167.2a40,40,0,0,0,0-78.4ZM80,48h96a24.1,24.1,0,0,1,24,24V88.8A40.1,40.1,0,0,0,168,128H88A40.1,40.1,0,0,0,56,88.8V72A24.1,24.1,0,0,1,80,48ZM208.4,152H208a8,8,0,0,0-8,8v40H56V160h0a7.9,7.9,0,0,0-8-7.9h-.4A24,24,0,1,1,72,128v40a8,8,0,0,0,16,0V144h80v24a8,8,0,0,0,16,0V128a24,24,0,1,1,24.4,24Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,159v41a16,16,0,0,1-16,16H56a16,16,0,0,1-16-16V159a32,32,0,1,1,40-31v40a8,8,0,0,0,16,0V136h64v32a8,8,0,0,0,16,0V128a32,32,0,1,1,40,31ZM95.3,120h65.4A48,48,0,0,1,208,80h3.7a3.9,3.9,0,0,0,4.3-3.9V72a40,40,0,0,0-40-40H80A40,40,0,0,0,40,72v4.2a3.9,3.9,0,0,0,4.3,3.9H48A48,48,0,0,1,95.3,120Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M214,90.5V72a38,38,0,0,0-38-38H80A38,38,0,0,0,42,72V90.5a38,38,0,0,0,0,75V200a14,14,0,0,0,14,14H200a14,14,0,0,0,14-14V165.5a38,38,0,0,0,0-75ZM80,46h96a26.1,26.1,0,0,1,26,26V90.5A38.1,38.1,0,0,0,170,128v2H86v-2A38.1,38.1,0,0,0,54,90.5V72A26.1,26.1,0,0,1,80,46ZM208.3,154H208a6,6,0,0,0-6,6v40a2,2,0,0,1-2,2H56a2,2,0,0,1-2-2V160h0a6,6,0,0,0-6-6h-.3A26,26,0,1,1,74,128v40a6,6,0,0,0,12,0V142h84v26a6,6,0,0,0,12,0V128a26,26,0,1,1,26.3,26Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M212,92.2V72a36,36,0,0,0-36-36H80A36,36,0,0,0,44,72V92.2a36,36,0,0,0,0,71.6V200a12,12,0,0,0,12,12H200a12,12,0,0,0,12-12V163.8a36,36,0,0,0,0-71.6ZM80,44h96a28.1,28.1,0,0,1,28,28V92.2A36.1,36.1,0,0,0,172,128v4H84v-4A36.1,36.1,0,0,0,52,92.2V72A28.1,28.1,0,0,1,80,44ZM208.3,156H208a4,4,0,0,0-4,4v40a4,4,0,0,1-4,4H56a4,4,0,0,1-4-4V160h0a4,4,0,0,0-4-4h-.3A28,28,0,1,1,76,128v40a4,4,0,0,0,8,0V140h88v28a4,4,0,0,0,8,0V128a28,28,0,1,1,28.3,28Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,88.8V72a40,40,0,0,0-40-40H80A40,40,0,0,0,40,72V88.8a40,40,0,0,0,0,78.4V200a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V167.2a40,40,0,0,0,0-78.4ZM80,48h96a24.1,24.1,0,0,1,24,24V88.8A40.1,40.1,0,0,0,168,128H88A40.1,40.1,0,0,0,56,88.8V72A24.1,24.1,0,0,1,80,48ZM208.4,152H208a8,8,0,0,0-8,8v40H56V160h0a7.9,7.9,0,0,0-8-7.9h-.4A24,24,0,1,1,72,128v40a8,8,0,0,0,16,0V144h80v24a8,8,0,0,0,16,0V128a24,24,0,1,1,24.4,24Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowArcLeft(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M236,184a12,12,0,0,1-24,0A84,84,0,0,0,68.6,124.6L57.1,136.1h35a12,12,0,0,1,0,24h-64a12,12,0,0,1-12-12v-64a12,12,0,0,1,24,0v35l11.5-11.5A108,108,0,0,1,236,184Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M232,184a8,8,0,0,1-16,0A88,88,0,0,0,65.8,121.8L47.4,140.1H92.1a8,8,0,0,1,0,16h-64a8,8,0,0,1-8-8v-64a8,8,0,0,1,16,0v44.7l18.4-18.3a103.8,103.8,0,0,1,147,0A102.9,102.9,0,0,1,232,184Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M232,184a8,8,0,0,1-16,0A88,88,0,0,0,71.7,116.4l26.1,26.1a8,8,0,0,1,1.7,8.7,7.9,7.9,0,0,1-7.4,4.9h-64a8,8,0,0,1-8-8v-64a8,8,0,0,1,13.7-5.6L60.3,105a104,104,0,0,1,141.2,5.5A102.9,102.9,0,0,1,232,184Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M230,184a6,6,0,0,1-12,0A89.9,89.9,0,0,0,64.4,120.4L42.6,142.1H92.1a6,6,0,0,1,0,12h-64a6,6,0,0,1-6-6v-64a6,6,0,0,1,12,0v49.5l21.8-21.7A102,102,0,0,1,230,184Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M228,184a4,4,0,0,1-8,0A92.1,92.1,0,0,0,62.9,118.9L37.8,144.1H92.1a4,4,0,0,1,0,8h-64a4,4,0,0,1-4-4v-64a4,4,0,0,1,8,0v54.4l25.2-25.2A100,100,0,0,1,228,184Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M232,184a8,8,0,0,1-16,0A88,88,0,0,0,65.8,121.8L47.4,140.1H92.1a8,8,0,0,1,0,16h-64a8,8,0,0,1-8-8v-64a8,8,0,0,1,16,0v44.7l18.4-18.3a103.8,103.8,0,0,1,147,0A102.9,102.9,0,0,1,232,184Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowArcRight(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M239.9,84.1v64a12,12,0,0,1-12,12h-64a12,12,0,0,1,0-24h35l-11.5-11.5A84,84,0,0,0,44,184a12,12,0,0,1-24,0,108,108,0,0,1,184.4-76.4l11.5,11.5v-35a12,12,0,0,1,24,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M235.9,84.1v64a8,8,0,0,1-8,8h-64a8,8,0,0,1,0-16h44.7l-18.4-18.3A88,88,0,0,0,40,184a8,8,0,0,1-16,0A104.1,104.1,0,0,1,128,80a102.9,102.9,0,0,1,73.5,30.5l18.4,18.3V84.1a8,8,0,0,1,16,0Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M235.9,84.1v64a8,8,0,0,1-8,8h-64a7.9,7.9,0,0,1-7.4-4.9,8,8,0,0,1,1.7-8.7l26.1-26.1A88,88,0,0,0,40,184a8,8,0,0,1-16,0A104.1,104.1,0,0,1,128,80a103.3,103.3,0,0,1,67.7,25l26.5-26.5a8,8,0,0,1,13.7,5.6Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M233.9,84.1v64a6,6,0,0,1-6,6h-64a6,6,0,0,1,0-12h49.5l-21.8-21.7A90,90,0,0,0,38,184a6,6,0,0,1-12,0,102,102,0,0,1,174.1-72.1l21.8,21.7V84.1a6,6,0,0,1,12,0Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M231.9,84.1v64a4,4,0,0,1-4,4h-64a4,4,0,0,1,0-8h54.3l-25.1-25.2A92,92,0,0,0,36,184a4,4,0,0,1-8,0,100,100,0,0,1,170.7-70.7l25.2,25.2V84.1a4,4,0,0,1,8,0Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M235.9,84.1v64a8,8,0,0,1-8,8h-64a8,8,0,0,1,0-16h44.7l-18.4-18.3A88,88,0,0,0,40,184a8,8,0,0,1-16,0A104.1,104.1,0,0,1,128,80a102.9,102.9,0,0,1,73.5,30.5l18.4,18.3V84.1a8,8,0,0,1,16,0Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowBendDoubleUpLeft(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M88.5,143.5a12,12,0,0,1,0,17,12.1,12.1,0,0,1-17,0l-48-48a12,12,0,0,1,0-17l48-48a12,12,0,0,1,17,17L49,104ZM128,92H117l27.5-27.5a12,12,0,0,0-17-17l-48,48a12,12,0,0,0,0,17l48,48a12.1,12.1,0,0,0,17,0,12,12,0,0,0,0-17L117,116h11a84.1,84.1,0,0,1,84,84,12,12,0,0,0,24,0A108.1,108.1,0,0,0,128,92Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M85.7,146.3a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0l-48-48a8.1,8.1,0,0,1,0-11.4l48-48A8.1,8.1,0,0,1,85.7,61.7L43.3,104ZM128,96H99.3l34.4-34.3a8.1,8.1,0,1,0-11.4-11.4l-48,48a8.1,8.1,0,0,0,0,11.4l48,48a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4L99.3,112H128a88.1,88.1,0,0,1,88,88,8,8,0,0,0,16,0A104.2,104.2,0,0,0,128,96Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M85.7,146.3a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0l-48-48a8.1,8.1,0,0,1,0-11.4l48-48A8.1,8.1,0,0,1,85.7,61.7L43.3,104Zm50.3-50V56a8,8,0,0,0-4.9-7.4,8.4,8.4,0,0,0-8.8,1.7l-48,48a8.1,8.1,0,0,0,0,11.4l48,48A8.3,8.3,0,0,0,128,160a8.5,8.5,0,0,0,3.1-.6A8,8,0,0,0,136,152V112.4A88.1,88.1,0,0,1,216,200a8,8,0,0,0,16,0A104.1,104.1,0,0,0,136,96.3Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M84.2,147.8a5.9,5.9,0,1,1-8.4,8.4l-48-48a5.8,5.8,0,0,1,0-8.4l48-48a5.9,5.9,0,0,1,8.4,8.4L40.5,104ZM128,98H94.5l37.7-37.8a5.9,5.9,0,1,0-8.4-8.4l-48,48a5.8,5.8,0,0,0,0,8.4l48,48a5.9,5.9,0,1,0,8.4-8.4L94.5,110H128a90.1,90.1,0,0,1,90,90,6,6,0,0,0,12,0A102.2,102.2,0,0,0,128,98Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M82.8,149.2a3.9,3.9,0,0,1,0,5.6,3.9,3.9,0,0,1-5.6,0l-48-48a3.9,3.9,0,0,1,0-5.6l48-48a4,4,0,0,1,5.6,5.6L37.7,104ZM128,100H89.7l41.1-41.2a4,4,0,0,0-5.6-5.6l-48,48a3.9,3.9,0,0,0,0,5.6l48,48a3.9,3.9,0,0,0,5.6,0,3.9,3.9,0,0,0,0-5.6L89.7,108H128a92.1,92.1,0,0,1,92,92,4,4,0,0,0,8,0A100.2,100.2,0,0,0,128,100Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M85.7,146.3a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0l-48-48a8.1,8.1,0,0,1,0-11.4l48-48A8.1,8.1,0,0,1,85.7,61.7L43.3,104ZM128,96H99.3l34.4-34.3a8.1,8.1,0,1,0-11.4-11.4l-48,48a8.1,8.1,0,0,0,0,11.4l48,48a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4L99.3,112H128a88.1,88.1,0,0,1,88,88,8,8,0,0,0,16,0A104.2,104.2,0,0,0,128,96Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowBendDoubleUpRight(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M232.5,112.5l-48,48a12.1,12.1,0,0,1-17,0,12,12,0,0,1,0-17L207,104,167.5,64.5a12,12,0,0,1,17-17l48,48A12,12,0,0,1,232.5,112.5Zm-56-17-48-48a12,12,0,0,0-17,17L139,92H128A108.1,108.1,0,0,0,20,200a12,12,0,0,0,24,0,84.1,84.1,0,0,1,84-84h11l-27.5,27.5a12,12,0,0,0,0,17,12.1,12.1,0,0,0,17,0l48-48A12,12,0,0,0,176.5,95.5Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M229.7,109.7l-48,48a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L212.7,104,170.3,61.7a8.1,8.1,0,0,1,11.4-11.4l48,48A8.1,8.1,0,0,1,229.7,109.7Zm-48-11.4-48-48a8.1,8.1,0,0,0-11.4,11.4L156.7,96H128A104.2,104.2,0,0,0,24,200a8,8,0,0,0,16,0,88.1,88.1,0,0,1,88-88h28.7l-34.4,34.3a8.1,8.1,0,0,0,0,11.4,8.2,8.2,0,0,0,11.4,0l48-48A8.1,8.1,0,0,0,181.7,98.3Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M229.7,109.7l-48,48a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L212.7,104,170.3,61.7a8.1,8.1,0,0,1,11.4-11.4l48,48A8.1,8.1,0,0,1,229.7,109.7Zm-48-11.4-48-48a8.4,8.4,0,0,0-8.8-1.7A8,8,0,0,0,120,56V96.3A104.1,104.1,0,0,0,24,200a8,8,0,0,0,16,0,88.1,88.1,0,0,1,80-87.6V152a8,8,0,0,0,4.9,7.4,8.5,8.5,0,0,0,3.1.6,8.3,8.3,0,0,0,5.7-2.3l48-48A8.1,8.1,0,0,0,181.7,98.3Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M228.2,108.2l-48,48a5.9,5.9,0,0,1-8.4-8.4L215.5,104,171.8,60.2a5.9,5.9,0,0,1,8.4-8.4l48,48A5.8,5.8,0,0,1,228.2,108.2Zm-48-8.4-48-48a5.9,5.9,0,0,0-8.4,8.4L161.5,98H128A102.2,102.2,0,0,0,26,200a6,6,0,0,0,12,0,90.1,90.1,0,0,1,90-90h33.5l-37.7,37.8a5.9,5.9,0,1,0,8.4,8.4l48-48A5.8,5.8,0,0,0,180.2,99.8Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M226.8,106.8l-48,48a3.9,3.9,0,0,1-5.6,0,3.9,3.9,0,0,1,0-5.6L218.3,104,173.2,58.8a4,4,0,0,1,5.6-5.6l48,48A3.9,3.9,0,0,1,226.8,106.8Zm-48-5.6-48-48a4,4,0,1,0-5.6,5.6L166.3,100H128A100.2,100.2,0,0,0,28,200a4,4,0,0,0,8,0,92.1,92.1,0,0,1,92-92h38.3l-41.1,41.2a3.9,3.9,0,0,0,0,5.6,3.9,3.9,0,0,0,5.6,0l48-48A3.9,3.9,0,0,0,178.8,101.2Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M229.7,109.7l-48,48a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L212.7,104,170.3,61.7a8.1,8.1,0,0,1,11.4-11.4l48,48A8.1,8.1,0,0,1,229.7,109.7Zm-48-11.4-48-48a8.1,8.1,0,0,0-11.4,11.4L156.7,96H128A104.2,104.2,0,0,0,24,200a8,8,0,0,0,16,0,88.1,88.1,0,0,1,88-88h28.7l-34.4,34.3a8.1,8.1,0,0,0,0,11.4,8.2,8.2,0,0,0,11.4,0l48-48A8.1,8.1,0,0,0,181.7,98.3Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowBendDownLeft(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M236,56A108.1,108.1,0,0,1,128,164H61l27.5,27.5a12,12,0,0,1,0,17,12.1,12.1,0,0,1-17,0l-48-48a12,12,0,0,1,0-17l48-48a12,12,0,0,1,17,17L61,140h67a84.1,84.1,0,0,0,84-84,12,12,0,0,1,24,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M232,56A104.2,104.2,0,0,1,128,160H51.3l34.4,34.3a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0l-48-48a8.1,8.1,0,0,1,0-11.4l48-48a8.1,8.1,0,0,1,11.4,11.4L51.3,144H128a88.1,88.1,0,0,0,88-88,8,8,0,0,1,16,0Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M232,56A104.2,104.2,0,0,1,128,160H88v40a8,8,0,0,1-4.9,7.4,8.5,8.5,0,0,1-3.1.6,8.3,8.3,0,0,1-5.7-2.3l-48-48a8.1,8.1,0,0,1,0-11.4l48-48a8.4,8.4,0,0,1,8.8-1.7A8,8,0,0,1,88,104v40h40a88.1,88.1,0,0,0,88-88,8,8,0,0,1,16,0Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M230,56A102.2,102.2,0,0,1,128,158H46.5l37.7,37.8a5.9,5.9,0,1,1-8.4,8.4l-48-48a5.8,5.8,0,0,1,0-8.4l48-48a5.9,5.9,0,0,1,8.4,8.4L46.5,146H128a90.1,90.1,0,0,0,90-90,6,6,0,0,1,12,0Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M228,56A100.2,100.2,0,0,1,128,156H41.7l41.1,41.2a3.9,3.9,0,0,1,0,5.6,3.9,3.9,0,0,1-5.6,0l-48-48a3.9,3.9,0,0,1,0-5.6l48-48a4,4,0,0,1,5.6,5.6L41.7,148H128a92.1,92.1,0,0,0,92-92,4,4,0,0,1,8,0Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M232,56A104.2,104.2,0,0,1,128,160H51.3l34.4,34.3a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0l-48-48a8.1,8.1,0,0,1,0-11.4l48-48a8.1,8.1,0,0,1,11.4,11.4L51.3,144H128a88.1,88.1,0,0,0,88-88,8,8,0,0,1,16,0Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowBendDownRight(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M232.5,160.5l-48,48a12.1,12.1,0,0,1-17,0,12,12,0,0,1,0-17L195,164H128A108.1,108.1,0,0,1,20,56a12,12,0,0,1,24,0,84.1,84.1,0,0,0,84,84h67l-27.5-27.5a12,12,0,0,1,17-17l48,48A12,12,0,0,1,232.5,160.5Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M229.7,157.7l-48,48a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L204.7,160H128A104.2,104.2,0,0,1,24,56a8,8,0,0,1,16,0,88.1,88.1,0,0,0,88,88h76.7l-34.4-34.3a8.1,8.1,0,0,1,11.4-11.4l48,48A8.1,8.1,0,0,1,229.7,157.7Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M229.7,157.7l-48,48A8.3,8.3,0,0,1,176,208a8.5,8.5,0,0,1-3.1-.6A8,8,0,0,1,168,200V160H128A104.2,104.2,0,0,1,24,56a8,8,0,0,1,16,0,88.1,88.1,0,0,0,88,88h40V104a8,8,0,0,1,4.9-7.4,8.4,8.4,0,0,1,8.8,1.7l48,48A8.1,8.1,0,0,1,229.7,157.7Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M228.2,156.2l-48,48a5.9,5.9,0,0,1-8.4-8.4L209.5,158H128A102.2,102.2,0,0,1,26,56a6,6,0,0,1,12,0,90.1,90.1,0,0,0,90,90h81.5l-37.7-37.8a5.9,5.9,0,0,1,8.4-8.4l48,48A5.8,5.8,0,0,1,228.2,156.2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M226.8,154.8l-48,48a3.9,3.9,0,0,1-5.6,0,3.9,3.9,0,0,1,0-5.6L214.3,156H128A100.2,100.2,0,0,1,28,56a4,4,0,0,1,8,0,92.1,92.1,0,0,0,92,92h86.3l-41.1-41.2a4,4,0,0,1,5.6-5.6l48,48A3.9,3.9,0,0,1,226.8,154.8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M229.7,157.7l-48,48a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L204.7,160H128A104.2,104.2,0,0,1,24,56a8,8,0,0,1,16,0,88.1,88.1,0,0,0,88,88h76.7l-34.4-34.3a8.1,8.1,0,0,1,11.4-11.4l48,48A8.1,8.1,0,0,1,229.7,157.7Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowBendLeftDown(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M212,32a12,12,0,0,1-12,12,84.1,84.1,0,0,0-84,84v67l27.5-27.5a12,12,0,0,1,17,17l-48,48a12.1,12.1,0,0,1-17,0l-48-48a12,12,0,0,1,17-17L92,195V128A108.1,108.1,0,0,1,200,20,12,12,0,0,1,212,32Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M208,32a8,8,0,0,1-8,8,88.1,88.1,0,0,0-88,88v76.7l34.3-34.4a8.1,8.1,0,0,1,11.4,11.4l-48,48a8.2,8.2,0,0,1-11.4,0l-48-48a8.1,8.1,0,0,1,11.4-11.4L96,204.7V128A104.2,104.2,0,0,1,200,24,8,8,0,0,1,208,32Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M208,32a8,8,0,0,1-8,8,88.1,88.1,0,0,0-88,88v40h40a8,8,0,0,1,7.4,4.9,8.4,8.4,0,0,1-1.7,8.8l-48,48a8.2,8.2,0,0,1-11.4,0l-48-48a8.4,8.4,0,0,1-1.7-8.8A8,8,0,0,1,56,168H96V128A104.2,104.2,0,0,1,200,24,8,8,0,0,1,208,32Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M206,32a6,6,0,0,1-6,6,90.1,90.1,0,0,0-90,90v81.5l37.8-37.7a5.9,5.9,0,0,1,8.4,8.4l-48,48a5.8,5.8,0,0,1-8.4,0l-48-48a5.9,5.9,0,0,1,8.4-8.4L98,209.5V128A102.2,102.2,0,0,1,200,26,6,6,0,0,1,206,32Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M204,32a4,4,0,0,1-4,4,92.1,92.1,0,0,0-92,92v86.3l41.2-41.1a4,4,0,0,1,5.6,5.6l-48,48a3.9,3.9,0,0,1-5.6,0l-48-48a4,4,0,0,1,5.6-5.6L100,214.3V128A100.2,100.2,0,0,1,200,28,4,4,0,0,1,204,32Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M208,32a8,8,0,0,1-8,8,88.1,88.1,0,0,0-88,88v76.7l34.3-34.4a8.1,8.1,0,0,1,11.4,11.4l-48,48a8.2,8.2,0,0,1-11.4,0l-48-48a8.1,8.1,0,0,1,11.4-11.4L96,204.7V128A104.2,104.2,0,0,1,200,24,8,8,0,0,1,208,32Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowBendLeftUp(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M212,224a12,12,0,0,1-12,12A108.1,108.1,0,0,1,92,128V61L64.5,88.5a12,12,0,0,1-17-17l48-48a12,12,0,0,1,17,0l48,48a12,12,0,0,1,0,17,12.1,12.1,0,0,1-17,0L116,61v67a84.1,84.1,0,0,0,84,84A12,12,0,0,1,212,224Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M208,224a8,8,0,0,1-8,8A104.2,104.2,0,0,1,96,128V51.3L61.7,85.7A8.1,8.1,0,0,1,50.3,74.3l48-48a8.1,8.1,0,0,1,11.4,0l48,48a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0L112,51.3V128a88.1,88.1,0,0,0,88,88A8,8,0,0,1,208,224Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M208,224a8,8,0,0,1-8,8A104.2,104.2,0,0,1,96,128V88H56a8,8,0,0,1-7.4-4.9,8.4,8.4,0,0,1,1.7-8.8l48-48a8.1,8.1,0,0,1,11.4,0l48,48a8.4,8.4,0,0,1,1.7,8.8A8,8,0,0,1,152,88H112v40a88.1,88.1,0,0,0,88,88A8,8,0,0,1,208,224Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M206,224a6,6,0,0,1-6,6A102.2,102.2,0,0,1,98,128V46.5L60.2,84.2a5.9,5.9,0,0,1-8.4-8.4l48-48a5.8,5.8,0,0,1,8.4,0l48,48a5.9,5.9,0,1,1-8.4,8.4L110,46.5V128a90.1,90.1,0,0,0,90,90A6,6,0,0,1,206,224Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M204,224a4,4,0,0,1-4,4A100.2,100.2,0,0,1,100,128V41.7L58.8,82.8a4,4,0,0,1-5.6-5.6l48-48a3.9,3.9,0,0,1,5.6,0l48,48a3.9,3.9,0,0,1,0,5.6,3.9,3.9,0,0,1-5.6,0L108,41.7V128a92.1,92.1,0,0,0,92,92A4,4,0,0,1,204,224Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M208,224a8,8,0,0,1-8,8A104.2,104.2,0,0,1,96,128V51.3L61.7,85.7A8.1,8.1,0,0,1,50.3,74.3l48-48a8.1,8.1,0,0,1,11.4,0l48,48a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0L112,51.3V128a88.1,88.1,0,0,0,88,88A8,8,0,0,1,208,224Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowBendRightDown(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M208.5,184.5l-48,48a12.1,12.1,0,0,1-17,0l-48-48a12,12,0,0,1,17-17L140,195V128A84.1,84.1,0,0,0,56,44a12,12,0,0,1,0-24A108.1,108.1,0,0,1,164,128v67l27.5-27.5a12,12,0,0,1,17,17Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M205.7,181.7l-48,48a8.2,8.2,0,0,1-11.4,0l-48-48a8.1,8.1,0,0,1,11.4-11.4L144,204.7V128A88.1,88.1,0,0,0,56,40a8,8,0,0,1,0-16A104.2,104.2,0,0,1,160,128v76.7l34.3-34.4a8.1,8.1,0,0,1,11.4,11.4Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M205.7,181.7l-48,48a8.2,8.2,0,0,1-11.4,0l-48-48a8.4,8.4,0,0,1-1.7-8.8A8,8,0,0,1,104,168h40V128A88.1,88.1,0,0,0,56,40a8,8,0,0,1,0-16A104.2,104.2,0,0,1,160,128v40h40a8,8,0,0,1,7.4,4.9A8.4,8.4,0,0,1,205.7,181.7Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M204.2,180.2l-48,48a5.8,5.8,0,0,1-8.4,0l-48-48a5.9,5.9,0,0,1,8.4-8.4L146,209.5V128A90.1,90.1,0,0,0,56,38a6,6,0,0,1,0-12A102.2,102.2,0,0,1,158,128v81.5l37.8-37.7a5.9,5.9,0,0,1,8.4,8.4Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M202.8,178.8l-48,48a3.9,3.9,0,0,1-5.6,0l-48-48a4,4,0,0,1,5.6-5.6L148,214.3V128A92.1,92.1,0,0,0,56,36a4,4,0,0,1,0-8A100.2,100.2,0,0,1,156,128v86.3l41.2-41.1a4,4,0,0,1,5.6,5.6Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M205.7,181.7l-48,48a8.2,8.2,0,0,1-11.4,0l-48-48a8.1,8.1,0,0,1,11.4-11.4L144,204.7V128A88.1,88.1,0,0,0,56,40a8,8,0,0,1,0-16A104.2,104.2,0,0,1,160,128v76.7l34.3-34.4a8.1,8.1,0,0,1,11.4,11.4Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowBendRightUp(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M208.5,88.5a12.1,12.1,0,0,1-17,0L164,61v67A108.1,108.1,0,0,1,56,236a12,12,0,0,1,0-24,84.1,84.1,0,0,0,84-84V61L112.5,88.5a12,12,0,0,1-17-17l48-48a12,12,0,0,1,17,0l48,48A12,12,0,0,1,208.5,88.5Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M205.7,85.7a8.2,8.2,0,0,1-11.4,0L160,51.3V128A104.2,104.2,0,0,1,56,232a8,8,0,0,1,0-16,88.1,88.1,0,0,0,88-88V51.3L109.7,85.7A8.1,8.1,0,0,1,98.3,74.3l48-48a8.1,8.1,0,0,1,11.4,0l48,48A8.1,8.1,0,0,1,205.7,85.7Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M207.4,83.1A8,8,0,0,1,200,88H160v40A104.2,104.2,0,0,1,56,232a8,8,0,0,1,0-16,88.1,88.1,0,0,0,88-88V88H104a8,8,0,0,1-7.4-4.9,8.4,8.4,0,0,1,1.7-8.8l48-48a8.1,8.1,0,0,1,11.4,0l48,48A8.4,8.4,0,0,1,207.4,83.1Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M204.2,84.2a5.8,5.8,0,0,1-8.4,0L158,46.5V128A102.2,102.2,0,0,1,56,230a6,6,0,0,1,0-12,90.1,90.1,0,0,0,90-90V46.5L108.2,84.2a5.9,5.9,0,0,1-8.4-8.4l48-48a5.8,5.8,0,0,1,8.4,0l48,48A5.8,5.8,0,0,1,204.2,84.2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M202.8,82.8a3.9,3.9,0,0,1-5.6,0L156,41.7V128A100.2,100.2,0,0,1,56,228a4,4,0,0,1,0-8,92.1,92.1,0,0,0,92-92V41.7L106.8,82.8a4,4,0,0,1-5.6-5.6l48-48a3.9,3.9,0,0,1,5.6,0l48,48A3.9,3.9,0,0,1,202.8,82.8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M205.7,85.7a8.2,8.2,0,0,1-11.4,0L160,51.3V128A104.2,104.2,0,0,1,56,232a8,8,0,0,1,0-16,88.1,88.1,0,0,0,88-88V51.3L109.7,85.7A8.1,8.1,0,0,1,98.3,74.3l48-48a8.1,8.1,0,0,1,11.4,0l48,48A8.1,8.1,0,0,1,205.7,85.7Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowBendUpLeft(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M236,200a12,12,0,0,1-24,0,84.1,84.1,0,0,0-84-84H61l27.5,27.5a12,12,0,0,1,0,17,12.1,12.1,0,0,1-17,0l-48-48a12,12,0,0,1,0-17l48-48a12,12,0,0,1,17,17L61,92h67A108.1,108.1,0,0,1,236,200Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M232,200a8,8,0,0,1-16,0,88.1,88.1,0,0,0-88-88H51.3l34.4,34.3a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0l-48-48a8.1,8.1,0,0,1,0-11.4l48-48A8.1,8.1,0,0,1,85.7,61.7L51.3,96H128A104.2,104.2,0,0,1,232,200Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M232,200a8,8,0,0,1-16,0,88.1,88.1,0,0,0-88-88H88v40a8,8,0,0,1-4.9,7.4,8.5,8.5,0,0,1-3.1.6,8.3,8.3,0,0,1-5.7-2.3l-48-48a8.1,8.1,0,0,1,0-11.4l48-48a8.4,8.4,0,0,1,8.8-1.7A8,8,0,0,1,88,56V96h40A104.2,104.2,0,0,1,232,200Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M230,200a6,6,0,0,1-12,0,90.1,90.1,0,0,0-90-90H46.5l37.7,37.8a5.9,5.9,0,1,1-8.4,8.4l-48-48a5.8,5.8,0,0,1,0-8.4l48-48a5.9,5.9,0,0,1,8.4,8.4L46.5,98H128A102.2,102.2,0,0,1,230,200Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M228,200a4,4,0,0,1-8,0,92.1,92.1,0,0,0-92-92H41.7l41.1,41.2a3.9,3.9,0,0,1,0,5.6,3.9,3.9,0,0,1-5.6,0l-48-48a3.9,3.9,0,0,1,0-5.6l48-48a4,4,0,0,1,5.6,5.6L41.7,100H128A100.2,100.2,0,0,1,228,200Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M232,200a8,8,0,0,1-16,0,88.1,88.1,0,0,0-88-88H51.3l34.4,34.3a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0l-48-48a8.1,8.1,0,0,1,0-11.4l48-48A8.1,8.1,0,0,1,85.7,61.7L51.3,96H128A104.2,104.2,0,0,1,232,200Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowBendUpRight(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M232.5,112.5l-48,48a12.1,12.1,0,0,1-17,0,12,12,0,0,1,0-17L195,116H128a84.1,84.1,0,0,0-84,84,12,12,0,0,1-24,0A108.1,108.1,0,0,1,128,92h67L167.5,64.5a12,12,0,0,1,17-17l48,48A12,12,0,0,1,232.5,112.5Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M229.7,109.7l-48,48a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L204.7,112H128a88.1,88.1,0,0,0-88,88,8,8,0,0,1-16,0A104.2,104.2,0,0,1,128,96h76.7L170.3,61.7a8.1,8.1,0,0,1,11.4-11.4l48,48A8.1,8.1,0,0,1,229.7,109.7Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M229.7,109.7l-48,48A8.3,8.3,0,0,1,176,160a8.5,8.5,0,0,1-3.1-.6A8,8,0,0,1,168,152V112H128a88.1,88.1,0,0,0-88,88,8,8,0,0,1-16,0A104.2,104.2,0,0,1,128,96h40V56a8,8,0,0,1,4.9-7.4,8.4,8.4,0,0,1,8.8,1.7l48,48A8.1,8.1,0,0,1,229.7,109.7Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M228.2,108.2l-48,48a5.9,5.9,0,0,1-8.4-8.4L209.5,110H128a90.1,90.1,0,0,0-90,90,6,6,0,0,1-12,0A102.2,102.2,0,0,1,128,98h81.5L171.8,60.2a5.9,5.9,0,0,1,8.4-8.4l48,48A5.8,5.8,0,0,1,228.2,108.2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M226.8,106.8l-48,48a3.9,3.9,0,0,1-5.6,0,3.9,3.9,0,0,1,0-5.6L214.3,108H128a92.1,92.1,0,0,0-92,92,4,4,0,0,1-8,0A100.2,100.2,0,0,1,128,100h86.3L173.2,58.8a4,4,0,0,1,5.6-5.6l48,48A3.9,3.9,0,0,1,226.8,106.8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M229.7,109.7l-48,48a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L204.7,112H128a88.1,88.1,0,0,0-88,88,8,8,0,0,1-16,0A104.2,104.2,0,0,1,128,96h76.7L170.3,61.7a8.1,8.1,0,0,1,11.4-11.4l48,48A8.1,8.1,0,0,1,229.7,109.7Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowCircleDownLeft(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,20A108,108,0,1,0,236,128,108.1,108.1,0,0,0,128,20Zm0,192a84,84,0,1,1,84-84A84.1,84.1,0,0,1,128,212ZM164.5,91.5a12,12,0,0,1,0,17L129,144h19a12,12,0,0,1,0,24H100a12,12,0,0,1-12-12V108a12,12,0,0,1,24,0v19l35.5-35.5A12,12,0,0,1,164.5,91.5Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="128" cy="128" r="96" />
          </g>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Z" />
          <path d="M150.3,94.3,108,136.7V108a8,8,0,0,0-16,0v48a8,8,0,0,0,8,8h48a8,8,0,0,0,0-16H119.3l42.4-42.3a8.1,8.1,0,0,0-11.4-11.4Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm33.7,81.7L119.3,148H148a8,8,0,0,1,0,16H100a8.5,8.5,0,0,1-3.1-.6,8.2,8.2,0,0,1-4.3-4.3A8.5,8.5,0,0,1,92,156V108a8,8,0,0,1,16,0v28.7l42.3-42.4a8.1,8.1,0,0,1,11.4,11.4Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,26A102,102,0,1,0,230,128,102.2,102.2,0,0,0,128,26Zm0,192a90,90,0,1,1,90-90A90.1,90.1,0,0,1,128,218ZM160.2,95.8a5.8,5.8,0,0,1,0,8.4L114.5,150H148a6,6,0,0,1,0,12H100a6,6,0,0,1-6-6V108a6,6,0,0,1,12,0v33.5l45.8-45.7A5.8,5.8,0,0,1,160.2,95.8Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,28A100,100,0,1,0,228,128,100.2,100.2,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220ZM158.8,97.2a3.9,3.9,0,0,1,0,5.6L109.7,152H148a4,4,0,0,1,0,8H100a4,4,0,0,1-4-4V108a4,4,0,0,1,8,0v38.3l49.2-49.1A3.9,3.9,0,0,1,158.8,97.2Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216ZM161.7,94.3a8.1,8.1,0,0,1,0,11.4L119.3,148H148a8,8,0,0,1,0,16H100a8,8,0,0,1-8-8V108a8,8,0,0,1,16,0v28.7l42.3-42.4A8.1,8.1,0,0,1,161.7,94.3Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowCircleDownRight(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M204.4,51.6a107.9,107.9,0,1,0,0,152.8A108.2,108.2,0,0,0,204.4,51.6Zm-17,135.8a84,84,0,1,1,0-118.8A84.2,84.2,0,0,1,187.4,187.4ZM168,108v48a12,12,0,0,1-12,12H108a12,12,0,0,1,0-24h19L91.5,108.5a12,12,0,0,1,17-17L144,127V108a12,12,0,0,1,24,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="128" cy="128" r="96" />
          </g>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Z" />
          <path d="M156,100a8,8,0,0,0-8,8v28.7L105.7,94.3a8.1,8.1,0,0,0-11.4,11.4L136.7,148H108a8,8,0,0,0,0,16h48a8,8,0,0,0,8-8V108A8,8,0,0,0,156,100Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm36,132a8.5,8.5,0,0,1-.6,3.1,8.2,8.2,0,0,1-4.3,4.3,8.5,8.5,0,0,1-3.1.6H108a8,8,0,0,1,0-16h28.7L94.3,105.7a8.1,8.1,0,0,1,11.4-11.4L148,136.7V108a8,8,0,0,1,16,0Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M200.1,55.9a102,102,0,1,0,0,144.2A102,102,0,0,0,200.1,55.9Zm-8.5,135.7a90,90,0,1,1,0-127.2A90,90,0,0,1,191.6,191.6ZM162,108v48a6,6,0,0,1-6,6H108a6,6,0,0,1,0-12h33.5L95.8,104.2a5.9,5.9,0,0,1,8.4-8.4L150,141.5V108a6,6,0,0,1,12,0Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M198.7,57.3A100,100,0,0,0,57.3,198.7,100,100,0,0,0,198.7,57.3Zm-5.6,135.8a92,92,0,1,1,0-130.2A91.8,91.8,0,0,1,193.1,193.1ZM160,108v48a4,4,0,0,1-4,4H108a4,4,0,0,1,0-8h38.3L97.2,102.8a4,4,0,0,1,5.6-5.6L152,146.3V108a4,4,0,0,1,8,0Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M201.5,54.5a104,104,0,1,0,0,147A103.9,103.9,0,0,0,201.5,54.5ZM190.2,190.2a88,88,0,1,1,0-124.4A88.1,88.1,0,0,1,190.2,190.2ZM164,108v48a8,8,0,0,1-8,8H108a8,8,0,0,1,0-16h28.7L94.3,105.7a8.1,8.1,0,0,1,11.4-11.4L148,136.7V108a8,8,0,0,1,16,0Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowCircleDown(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,20A108,108,0,1,0,236,128,108.1,108.1,0,0,0,128,20Zm0,192a84,84,0,1,1,84-84A84.1,84.1,0,0,1,128,212Zm42.4-86.4a11.9,11.9,0,0,1,0,16.9l-33.9,34a12.1,12.1,0,0,1-17,0l-33.9-34a12,12,0,0,1,16.9-16.9L116,139V88a12,12,0,0,1,24,0v51l13.5-13.4A11.9,11.9,0,0,1,170.4,125.6Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="128" cy="128" r="96" />
          </g>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Z" />
          <path d="M156.3,128.4,136,148.7V88a8,8,0,0,0-16,0v60.7L99.7,128.4a8,8,0,0,0-11.3,11.3l33.9,34a8.2,8.2,0,0,0,11.4,0l33.9-34a8,8,0,0,0-11.3-11.3Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm39.6,115.7-33.9,34a8.2,8.2,0,0,1-11.4,0l-33.9-34a8,8,0,0,1,11.3-11.3L120,148.7V88a8,8,0,0,1,16,0v60.7l20.3-20.3a8,8,0,0,1,11.3,11.3Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,26A102,102,0,1,0,230,128,102.2,102.2,0,0,0,128,26Zm0,192a90,90,0,1,1,90-90A90.1,90.1,0,0,1,128,218Zm38.2-88.2a6.1,6.1,0,0,1,0,8.5l-34,33.9a5.8,5.8,0,0,1-8.4,0l-34-33.9a6,6,0,0,1,8.5-8.5L122,153.5V88a6,6,0,0,1,12,0v65.5l23.7-23.7A6.1,6.1,0,0,1,166.2,129.8Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,28A100,100,0,1,0,228,128,100.2,100.2,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm36.8-88.8a4.2,4.2,0,0,1,0,5.7l-34,33.9a3.9,3.9,0,0,1-5.6,0l-34-33.9a4,4,0,0,1,5.7-5.7L124,158.3V88a4,4,0,0,1,8,0v70.3l27.1-27.1A4.2,4.2,0,0,1,164.8,131.2Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm39.6-87.6a8,8,0,0,1,0,11.3l-33.9,34a8.2,8.2,0,0,1-11.4,0l-33.9-34a8,8,0,0,1,11.3-11.3L120,148.7V88a8,8,0,0,1,16,0v60.7l20.3-20.3A8,8,0,0,1,167.6,128.4Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowCircleLeft(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,20A108,108,0,1,0,236,128,108.1,108.1,0,0,0,128,20Zm0,192a84,84,0,1,1,84-84A84.1,84.1,0,0,1,128,212Zm52-84a12,12,0,0,1-12,12H117l13.4,13.5a11.9,11.9,0,0,1,0,16.9,12,12,0,0,1-16.9,0l-34-33.9a12,12,0,0,1,0-17l34-33.9a12,12,0,0,1,16.9,16.9L117,116h51A12,12,0,0,1,180,128Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="128" cy="128" r="96" />
          </g>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Z" />
          <path d="M168,120H107.3l20.3-20.3a8,8,0,0,0-11.3-11.3l-34,33.9a8.1,8.1,0,0,0,0,11.4l34,33.9a7.6,7.6,0,0,0,5.6,2.3,7.8,7.8,0,0,0,5.7-2.3,8,8,0,0,0,0-11.3L107.3,136H168a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm40,112H107.3l20.3,20.3a8,8,0,0,1,0,11.3,7.8,7.8,0,0,1-5.7,2.3,7.6,7.6,0,0,1-5.6-2.3L82.4,133.7a8.7,8.7,0,0,1-1.8-2.6,8.3,8.3,0,0,1,0-6.2,8.7,8.7,0,0,1,1.8-2.6l33.9-33.9a8,8,0,0,1,11.3,11.3L107.3,120H168a8,8,0,0,1,0,16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,26A102,102,0,1,0,230,128,102.2,102.2,0,0,0,128,26Zm0,192a90,90,0,1,1,90-90A90.1,90.1,0,0,1,128,218Zm46-90a6,6,0,0,1-6,6H102.5l23.7,23.7a6.1,6.1,0,0,1,0,8.5,5.9,5.9,0,0,1-4.3,1.7,5.8,5.8,0,0,1-4.2-1.7l-33.9-34a5.8,5.8,0,0,1,0-8.4l33.9-34a6,6,0,0,1,8.5,8.5L102.5,122H168A6,6,0,0,1,174,128Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,28A100,100,0,1,0,228,128,100.2,100.2,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm44-92a4,4,0,0,1-4,4H97.7l27.1,27.1a4.2,4.2,0,0,1,0,5.7,3.9,3.9,0,0,1-2.9,1.1,3.8,3.8,0,0,1-2.8-1.1l-33.9-34a3.9,3.9,0,0,1,0-5.6l33.9-34a4,4,0,0,1,5.7,5.7L97.7,124H168A4,4,0,0,1,172,128Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm48-88a8,8,0,0,1-8,8H107.3l20.3,20.3a8,8,0,0,1,0,11.3,7.8,7.8,0,0,1-5.7,2.3,7.6,7.6,0,0,1-5.6-2.3l-34-33.9a8.1,8.1,0,0,1,0-11.4l34-33.9a8,8,0,0,1,11.3,11.3L107.3,120H168A8,8,0,0,1,176,128Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowCircleRight(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,20A108,108,0,1,0,236,128,108.1,108.1,0,0,0,128,20Zm0,192a84,84,0,1,1,84-84A84.1,84.1,0,0,1,128,212Zm48.5-92.5a12,12,0,0,1,0,17l-34,33.9a12,12,0,0,1-16.9,0,11.9,11.9,0,0,1,0-16.9L139,140H88a12,12,0,0,1,0-24h51l-13.4-13.5a12,12,0,0,1,16.9-16.9Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="128" cy="128" r="96" />
          </g>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Z" />
          <path d="M139.7,88.4a8,8,0,0,0-11.3,11.3L148.7,120H88a8,8,0,0,0,0,16h60.7l-20.3,20.3a8,8,0,0,0,0,11.3,7.8,7.8,0,0,0,5.7,2.3,7.6,7.6,0,0,0,5.6-2.3l34-33.9a8.1,8.1,0,0,0,0-11.4Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm47.4,107.1a8.7,8.7,0,0,1-1.8,2.6l-33.9,33.9a7.6,7.6,0,0,1-5.6,2.3,7.8,7.8,0,0,1-5.7-2.3,8,8,0,0,1,0-11.3L148.7,136H88a8,8,0,0,1,0-16h60.7L128.4,99.7a8,8,0,0,1,11.3-11.3l33.9,33.9a8.7,8.7,0,0,1,1.8,2.6A8.3,8.3,0,0,1,175.4,131.1Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,26A102,102,0,1,0,230,128,102.2,102.2,0,0,0,128,26Zm0,192a90,90,0,1,1,90-90A90.1,90.1,0,0,1,128,218Zm44.2-94.2a5.8,5.8,0,0,1,0,8.4l-33.9,34a5.8,5.8,0,0,1-4.2,1.7,5.9,5.9,0,0,1-4.3-1.7,6.1,6.1,0,0,1,0-8.5L153.5,134H88a6,6,0,0,1,0-12h65.5L129.8,98.3a6,6,0,0,1,8.5-8.5Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,28A100,100,0,1,0,228,128,100.2,100.2,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm42.8-94.8a3.9,3.9,0,0,1,0,5.6l-33.9,34a3.8,3.8,0,0,1-2.8,1.1,3.9,3.9,0,0,1-2.9-1.1,4.2,4.2,0,0,1,0-5.7L158.3,132H88a4,4,0,0,1,0-8h70.3L131.2,96.9a4,4,0,0,1,5.7-5.7Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm45.7-93.7a8.1,8.1,0,0,1,0,11.4l-34,33.9a7.6,7.6,0,0,1-5.6,2.3,7.8,7.8,0,0,1-5.7-2.3,8,8,0,0,1,0-11.3L148.7,136H88a8,8,0,0,1,0-16h60.7L128.4,99.7a8,8,0,0,1,11.3-11.3Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowCircleUpLeft(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,20A108,108,0,1,0,236,128,108.1,108.1,0,0,0,128,20Zm0,192a84,84,0,1,1,84-84A84.1,84.1,0,0,1,128,212Zm36.5-64.5a12,12,0,0,1,0,17,12.1,12.1,0,0,1-17,0L112,129v19a12,12,0,0,1-24,0V100a12,12,0,0,1,12-12h48a12,12,0,0,1,0,24H129Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="128" cy="128" r="96" />
          </g>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Z" />
          <path d="M119.3,108H148a8,8,0,0,0,0-16H100a8,8,0,0,0-8,8v48a8,8,0,0,0,16,0V119.3l42.3,42.4a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm33.7,137.7a8.2,8.2,0,0,1-11.4,0L108,119.3V148a8,8,0,0,1-16,0V100a8.5,8.5,0,0,1,.6-3.1,8.2,8.2,0,0,1,4.3-4.3A8.5,8.5,0,0,1,100,92h48a8,8,0,0,1,0,16H119.3l42.4,42.3A8.1,8.1,0,0,1,161.7,161.7Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,26A102,102,0,1,0,230,128,102.2,102.2,0,0,0,128,26Zm0,192a90,90,0,1,1,90-90A90.1,90.1,0,0,1,128,218Zm32.2-66.2a5.9,5.9,0,1,1-8.4,8.4L106,114.5V148a6,6,0,0,1-12,0V100a6,6,0,0,1,6-6h48a6,6,0,0,1,0,12H114.5Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,28A100,100,0,1,0,228,128,100.2,100.2,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm30.8-66.8a3.9,3.9,0,0,1,0,5.6,3.9,3.9,0,0,1-5.6,0L104,109.7V148a4,4,0,0,1-8,0V100a4,4,0,0,1,4-4h48a4,4,0,0,1,0,8H109.7Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm33.7-65.7a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0L108,119.3V148a8,8,0,0,1-16,0V100a8,8,0,0,1,8-8h48a8,8,0,0,1,0,16H119.3Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowCircleUpRight(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,20A108,108,0,1,0,236,128,108.1,108.1,0,0,0,128,20Zm0,192a84,84,0,1,1,84-84A84.1,84.1,0,0,1,128,212Zm40-112v48a12,12,0,0,1-24,0V129l-35.5,35.5a12.1,12.1,0,0,1-17,0,12,12,0,0,1,0-17L127,112H108a12,12,0,0,1,0-24h48A12,12,0,0,1,168,100Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="128" cy="128" r="96" />
          </g>
          <path d="M201.5,54.5A103.8,103.8,0,1,0,232,128,103.9,103.9,0,0,0,201.5,54.5ZM190.2,190.2a88,88,0,1,1,0-124.4,88.1,88.1,0,0,1,0,124.4Z" />
          <path d="M156,92H108a8,8,0,0,0,0,16h28.7L94.3,150.3a8.1,8.1,0,0,0,0,11.4,8.2,8.2,0,0,0,11.4,0L148,119.3V148a8,8,0,0,0,16,0V100A8,8,0,0,0,156,92Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M201.5,54.5a104,104,0,1,0,0,147A103.9,103.9,0,0,0,201.5,54.5ZM164,148a8,8,0,0,1-16,0V119.3l-42.3,42.4A8.5,8.5,0,0,1,100,164a8.3,8.3,0,0,1-5.7-2.3,8.1,8.1,0,0,1,0-11.4L136.7,108H108a8,8,0,0,1,0-16h48a7.7,7.7,0,0,1,3,.6,8.1,8.1,0,0,1,4.4,4.3,8.5,8.5,0,0,1,.6,3.1Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,26A102,102,0,1,0,230,128,102.2,102.2,0,0,0,128,26Zm0,192a90,90,0,1,1,90-90A90.1,90.1,0,0,1,128,218Zm34-118v48a6,6,0,0,1-12,0V114.5l-45.8,45.7a5.9,5.9,0,0,1-8.4-8.4L141.5,106H108a6,6,0,0,1,0-12h48A6,6,0,0,1,162,100Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,28A100,100,0,1,0,228,128,100.2,100.2,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm32-120v48a4,4,0,0,1-8,0V109.7l-49.2,49.1a3.9,3.9,0,0,1-5.6,0,3.9,3.9,0,0,1,0-5.6L146.3,104H108a4,4,0,0,1,0-8h48A4,4,0,0,1,160,100Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm36-116v48a8,8,0,0,1-16,0V119.3l-42.3,42.4a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L136.7,108H108a8,8,0,0,1,0-16h48A8,8,0,0,1,164,100Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowCircleUp(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,20A108,108,0,1,0,236,128,108.1,108.1,0,0,0,128,20Zm0,192a84,84,0,1,1,84-84A84.1,84.1,0,0,1,128,212Zm42.4-98.5a11.9,11.9,0,0,1,0,16.9,12,12,0,0,1-16.9,0L140,117v51a12,12,0,0,1-24,0V117l-13.5,13.4a12,12,0,0,1-16.9-16.9l33.9-34a12,12,0,0,1,17,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="128" cy="128" r="96" />
          </g>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Z" />
          <path d="M133.7,82.3a8.1,8.1,0,0,0-11.4,0l-33.9,34a8,8,0,0,0,11.3,11.3L120,107.3V168a8,8,0,0,0,16,0V107.3l20.3,20.3a7.6,7.6,0,0,0,5.6,2.3,7.8,7.8,0,0,0,5.7-2.3,8,8,0,0,0,0-11.3Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm39.6,103.6a7.8,7.8,0,0,1-5.7,2.3,7.6,7.6,0,0,1-5.6-2.3L136,107.3V168a8,8,0,0,1-16,0V107.3L99.7,127.6a8,8,0,0,1-11.3-11.3l33.9-33.9a8.7,8.7,0,0,1,2.6-1.8,8.3,8.3,0,0,1,6.2,0,8.7,8.7,0,0,1,2.6,1.8l33.9,33.9A8,8,0,0,1,167.6,127.6Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,26A102,102,0,1,0,230,128,102.2,102.2,0,0,0,128,26Zm0,192a90,90,0,1,1,90-90A90.1,90.1,0,0,1,128,218Zm38.2-100.3a6.1,6.1,0,0,1,0,8.5,5.9,5.9,0,0,1-4.3,1.7,5.8,5.8,0,0,1-4.2-1.7L134,102.5V168a6,6,0,0,1-12,0V102.5L98.3,126.2a6,6,0,0,1-8.5-8.5l34-33.9a5.8,5.8,0,0,1,8.4,0Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,28A100,100,0,1,0,228,128,100.2,100.2,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm36.8-100.9a4.2,4.2,0,0,1,0,5.7,3.9,3.9,0,0,1-2.9,1.1,3.8,3.8,0,0,1-2.8-1.1L132,97.7V168a4,4,0,0,1-8,0V97.7L96.9,124.8a4,4,0,0,1-5.7-5.7l34-33.9a3.9,3.9,0,0,1,5.6,0Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm39.6-99.7a8,8,0,0,1,0,11.3,7.8,7.8,0,0,1-5.7,2.3,7.6,7.6,0,0,1-5.6-2.3L136,107.3V168a8,8,0,0,1-16,0V107.3L99.7,127.6a8,8,0,0,1-11.3-11.3l33.9-34a8.2,8.2,0,0,1,11.4,0Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowClockwise(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M236.2,51.7v48a12,12,0,0,1-12,12h-48a12,12,0,0,1,0-24h19L181.7,74.3a75.9,75.9,0,1,0,0,107.4,12.2,12.2,0,0,1,17,0,12,12,0,0,1,0,17,100,100,0,1,1,0-141.4l13.5,13.4v-19a12,12,0,1,1,24,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M232.2,51.7v48a8,8,0,0,1-8,8h-48a8,8,0,0,1,0-16h28.7L184.6,71.4a80,80,0,1,0,0,113.2,7.9,7.9,0,0,1,11.3,0,8,8,0,0,1,0,11.3,96,96,0,1,1,0-135.8l20.3,20.3V51.7a8,8,0,1,1,16,0Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M232.2,51.7v48a8,8,0,0,1-8,8h-48a8,8,0,0,1-5.7-13.6l18.4-18.4-4.3-4.3a80,80,0,1,0,0,113.2,7.9,7.9,0,0,1,11.3,0,8,8,0,0,1,0,11.3,96,96,0,1,1,0-135.8l4.3,4.3,18.3-18.3a8,8,0,0,1,8.7-1.8A8.2,8.2,0,0,1,232.2,51.7Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M230.2,51.7v48a6,6,0,0,1-6,6h-48a6,6,0,0,1,0-12h33.5L186,70a82,82,0,1,0,0,116,5.9,5.9,0,0,1,8.5,0,6.1,6.1,0,0,1,0,8.5,94,94,0,1,1,0-133l23.7,23.7V51.7a6,6,0,1,1,12,0Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M228.2,51.7v48a4,4,0,0,1-4,4h-48a4,4,0,0,1,0-8h38.3L187.4,68.6a84,84,0,1,0,0,118.8,4,4,0,0,1,5.7,0,4.2,4.2,0,0,1,0,5.7,92.1,92.1,0,1,1,0-130.2l27.1,27.2V51.7a4,4,0,1,1,8,0Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M232.2,51.7v48a8,8,0,0,1-8,8h-48a8,8,0,0,1,0-16h28.7L184.6,71.4a80,80,0,1,0,0,113.2,7.9,7.9,0,0,1,11.3,0,8,8,0,0,1,0,11.3,96,96,0,1,1,0-135.8l20.3,20.3V51.7a8,8,0,1,1,16,0Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowCounterClockwise(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M198.7,198.7a99.9,99.9,0,0,1-141.4,0,12,12,0,0,1,0-17,12.2,12.2,0,0,1,17,0,75.9,75.9,0,1,0,0-107.4L60.8,87.7h19a12,12,0,0,1,0,24h-48a12,12,0,0,1-12-12v-48a12,12,0,1,1,24,0v19L57.3,57.3A100,100,0,0,1,198.7,198.7Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M195.9,195.9a96.1,96.1,0,0,1-135.8,0,8,8,0,0,1,0-11.3,7.9,7.9,0,0,1,11.3,0,80,80,0,1,0,0-113.2L51.1,91.7H79.8a8,8,0,0,1,0,16h-48a8,8,0,0,1-8-8v-48a8,8,0,1,1,16,0V80.4L60.1,60.1A96,96,0,0,1,195.9,195.9Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M195.9,195.9a96.1,96.1,0,0,1-135.8,0,8,8,0,0,1,0-11.3,7.9,7.9,0,0,1,11.3,0,80,80,0,1,0,0-113.2l-4.3,4.3L85.5,94.1a8,8,0,0,1-5.7,13.6h-48a8,8,0,0,1-8-8v-48a8.2,8.2,0,0,1,5-7.4,8,8,0,0,1,8.7,1.8L55.8,64.4l4.3-4.3A96,96,0,0,1,195.9,195.9Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M194.5,194.5a94.2,94.2,0,0,1-133,0,6.1,6.1,0,0,1,0-8.5,5.9,5.9,0,0,1,8.5,0A82,82,0,1,0,70,70L46.3,93.7H79.8a6,6,0,0,1,0,12h-48a6,6,0,0,1-6-6v-48a6,6,0,1,1,12,0V85.2L61.5,61.5a94,94,0,0,1,133,133Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M220,128A92.1,92.1,0,0,1,62.9,193.1a4.2,4.2,0,0,1,0-5.7,4,4,0,0,1,5.7,0,84,84,0,1,0,0-118.8L41.5,95.7H79.8a4,4,0,0,1,0,8h-48a4,4,0,0,1-4-4v-48a4,4,0,1,1,8,0V90.1L62.9,62.9A92,92,0,0,1,220,128Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M195.9,195.9a96.1,96.1,0,0,1-135.8,0,8,8,0,0,1,0-11.3,7.9,7.9,0,0,1,11.3,0,80,80,0,1,0,0-113.2L51.1,91.7H79.8a8,8,0,0,1,0,16h-48a8,8,0,0,1-8-8v-48a8,8,0,1,1,16,0V80.4L60.1,60.1A96,96,0,0,1,195.9,195.9Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowDownLeft(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M200.5,72.5,93,180h75a12,12,0,0,1,0,24H64a12,12,0,0,1-12-12V88a12,12,0,0,1,24,0v75L183.5,55.5a12,12,0,0,1,17,17Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M197.7,69.7,83.3,184H168a8,8,0,0,1,0,16H64a8,8,0,0,1-8-8V88a8,8,0,0,1,16,0v84.7L186.3,58.3a8.1,8.1,0,0,1,11.4,11.4Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M197.7,69.7,127.3,140l46.4,46.3a8.4,8.4,0,0,1,1.7,8.8A8,8,0,0,1,168,200H64a8,8,0,0,1-8-8V88a8,8,0,0,1,4.9-7.4,8.4,8.4,0,0,1,8.8,1.7L116,128.7l70.3-70.4a8.1,8.1,0,0,1,11.4,11.4Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M196.2,68.2,78.5,186H168a6,6,0,0,1,0,12H64a6,6,0,0,1-6-6V88a6,6,0,0,1,12,0v89.5L187.8,59.8a5.9,5.9,0,0,1,8.4,8.4Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M194.8,66.8,73.7,188H168a4,4,0,0,1,0,8H64a4,4,0,0,1-4-4V88a4,4,0,0,1,8,0v94.3L189.2,61.2a4,4,0,1,1,5.6,5.6Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M197.7,69.7,83.3,184H168a8,8,0,0,1,0,16H64a8,8,0,0,1-8-8V88a8,8,0,0,1,16,0v84.7L186.3,58.3a8.1,8.1,0,0,1,11.4,11.4Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowDownRight(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M204,88V192a12,12,0,0,1-12,12H88a12,12,0,0,1,0-24h75L55.5,72.5a12,12,0,0,1,17-17L180,163V88a12,12,0,0,1,24,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M200,88V192a8,8,0,0,1-8,8H88a8,8,0,0,1,0-16h84.7L58.3,69.7A8.1,8.1,0,0,1,69.7,58.3L184,172.7V88a8,8,0,0,1,16,0Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M200,88V192a8,8,0,0,1-8,8H88a8,8,0,0,1-7.4-4.9,8.4,8.4,0,0,1,1.7-8.8L128.7,140,58.3,69.7A8.1,8.1,0,0,1,69.7,58.3L140,128.7l46.3-46.4a8.4,8.4,0,0,1,8.8-1.7A8,8,0,0,1,200,88Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M198,88V192a6,6,0,0,1-6,6H88a6,6,0,0,1,0-12h89.5L59.8,68.2a5.9,5.9,0,0,1,8.4-8.4L186,177.5V88a6,6,0,0,1,12,0Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M196,88V192a4,4,0,0,1-4,4H88a4,4,0,0,1,0-8h94.3L61.2,66.8a4,4,0,0,1,5.6-5.6L188,182.3V88a4,4,0,0,1,8,0Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M200,88V192a8,8,0,0,1-8,8H88a8,8,0,0,1,0-16h84.7L58.3,69.7A8.1,8.1,0,0,1,69.7,58.3L184,172.7V88a8,8,0,0,1,16,0Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowDown(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M208.5,152.5l-72,72a12.1,12.1,0,0,1-17,0l-72-72a12,12,0,0,1,17-17L116,187V40a12,12,0,0,1,24,0V187l51.5-51.5a12,12,0,0,1,17,17Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M205.7,149.7l-72,72a8.2,8.2,0,0,1-11.4,0l-72-72a8.1,8.1,0,0,1,11.4-11.4L120,196.7V40a8,8,0,0,1,16,0V196.7l58.3-58.4a8.1,8.1,0,0,1,11.4,11.4Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M205.7,149.7l-72,72a8.2,8.2,0,0,1-11.4,0l-72-72a8.4,8.4,0,0,1-1.7-8.8A8,8,0,0,1,56,136h64V40a8,8,0,0,1,16,0v96h64a8,8,0,0,1,7.4,4.9A8.4,8.4,0,0,1,205.7,149.7Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M204.2,148.2l-72,72a5.8,5.8,0,0,1-8.4,0l-72-72a5.9,5.9,0,0,1,8.4-8.4L122,201.5V40a6,6,0,0,1,12,0V201.5l61.8-61.7a5.9,5.9,0,0,1,8.4,8.4Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M202.8,146.8l-72,72a3.9,3.9,0,0,1-5.6,0l-72-72a4,4,0,0,1,5.6-5.6L124,206.3V40a4,4,0,0,1,8,0V206.3l65.2-65.1a4,4,0,0,1,5.6,5.6Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M205.7,149.7l-72,72a8.2,8.2,0,0,1-11.4,0l-72-72a8.1,8.1,0,0,1,11.4-11.4L120,196.7V40a8,8,0,0,1,16,0V196.7l58.3-58.4a8.1,8.1,0,0,1,11.4,11.4Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowElbowDownLeft(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M204,32V176a12,12,0,0,1-12,12H77l27.5,27.5a12,12,0,0,1,0,17,12.1,12.1,0,0,1-17,0l-48-48a12,12,0,0,1,0-17l48-48a12,12,0,0,1,17,17L77,164H180V32a12,12,0,0,1,24,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M200,32V176a8,8,0,0,1-8,8H67.3l34.4,34.3a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0l-48-48a8.1,8.1,0,0,1,0-11.4l48-48a8.1,8.1,0,0,1,11.4,11.4L67.3,168H184V32a8,8,0,0,1,16,0Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M200,32V176a8,8,0,0,1-8,8H104v40a8,8,0,0,1-4.9,7.4,8.5,8.5,0,0,1-3.1.6,8.3,8.3,0,0,1-5.7-2.3l-48-48a8.1,8.1,0,0,1,0-11.4l48-48a8.4,8.4,0,0,1,8.8-1.7A8,8,0,0,1,104,128v40h80V32a8,8,0,0,1,16,0Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M198,32V176a6,6,0,0,1-6,6H62.5l37.7,37.8a5.9,5.9,0,1,1-8.4,8.4l-48-48a5.8,5.8,0,0,1,0-8.4l48-48a5.9,5.9,0,0,1,8.4,8.4L62.5,170H186V32a6,6,0,0,1,12,0Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M196,32V176a4,4,0,0,1-4,4H57.7l41.1,41.2a3.9,3.9,0,0,1,0,5.6,3.9,3.9,0,0,1-5.6,0l-48-48a3.9,3.9,0,0,1,0-5.6l48-48a4,4,0,1,1,5.6,5.6L57.7,172H188V32a4,4,0,0,1,8,0Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M200,32V176a8,8,0,0,1-8,8H67.3l34.4,34.3a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0l-48-48a8.1,8.1,0,0,1,0-11.4l48-48a8.1,8.1,0,0,1,11.4,11.4L67.3,168H184V32a8,8,0,0,1,16,0Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowElbowDownRight(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216.5,184.5l-48,48a12.1,12.1,0,0,1-17,0,12,12,0,0,1,0-17L179,188H64a12,12,0,0,1-12-12V32a12,12,0,0,1,24,0V164H179l-27.5-27.5a12,12,0,0,1,17-17l48,48A12,12,0,0,1,216.5,184.5Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M213.7,181.7l-48,48a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L188.7,184H64a8,8,0,0,1-8-8V32a8,8,0,0,1,16,0V168H188.7l-34.4-34.3a8.1,8.1,0,0,1,11.4-11.4l48,48A8.1,8.1,0,0,1,213.7,181.7Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M213.7,181.7l-48,48A8.3,8.3,0,0,1,160,232a8.5,8.5,0,0,1-3.1-.6A8,8,0,0,1,152,224V184H64a8,8,0,0,1-8-8V32a8,8,0,0,1,16,0V168h80V128a8,8,0,0,1,4.9-7.4,8.4,8.4,0,0,1,8.8,1.7l48,48A8.1,8.1,0,0,1,213.7,181.7Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M212.2,180.2l-48,48a5.9,5.9,0,0,1-8.4-8.4L193.5,182H64a6,6,0,0,1-6-6V32a6,6,0,0,1,12,0V170H193.5l-37.7-37.8a5.9,5.9,0,0,1,8.4-8.4l48,48A5.8,5.8,0,0,1,212.2,180.2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M210.8,178.8l-48,48a3.9,3.9,0,0,1-5.6,0,3.9,3.9,0,0,1,0-5.6L198.3,180H64a4,4,0,0,1-4-4V32a4,4,0,0,1,8,0V172H198.3l-41.1-41.2a4,4,0,1,1,5.6-5.6l48,48A3.9,3.9,0,0,1,210.8,178.8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M213.7,181.7l-48,48a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L188.7,184H64a8,8,0,0,1-8-8V32a8,8,0,0,1,16,0V168H188.7l-34.4-34.3a8.1,8.1,0,0,1,11.4-11.4l48,48A8.1,8.1,0,0,1,213.7,181.7Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowElbowLeftDown(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M236,64a12,12,0,0,1-12,12H92V179l27.5-27.5a12,12,0,0,1,17,17l-48,48a12.1,12.1,0,0,1-17,0l-48-48a12,12,0,0,1,17-17L68,179V64A12,12,0,0,1,80,52H224A12,12,0,0,1,236,64Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M232,64a8,8,0,0,1-8,8H88V188.7l34.3-34.4a8.1,8.1,0,1,1,11.4,11.4l-48,48a8.2,8.2,0,0,1-11.4,0l-48-48a8.1,8.1,0,0,1,11.4-11.4L72,188.7V64a8,8,0,0,1,8-8H224A8,8,0,0,1,232,64Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M232,64a8,8,0,0,1-8,8H88v80h40a8,8,0,0,1,7.4,4.9,8.4,8.4,0,0,1-1.7,8.8l-48,48a8.2,8.2,0,0,1-11.4,0l-48-48a8.4,8.4,0,0,1-1.7-8.8A8,8,0,0,1,32,152H72V64a8,8,0,0,1,8-8H224A8,8,0,0,1,232,64Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M230,64a6,6,0,0,1-6,6H86V193.5l37.8-37.7a5.9,5.9,0,1,1,8.4,8.4l-48,48a5.8,5.8,0,0,1-8.4,0l-48-48a5.9,5.9,0,1,1,8.4-8.4L74,193.5V64a6,6,0,0,1,6-6H224A6,6,0,0,1,230,64Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M228,64a4,4,0,0,1-4,4H84V198.3l41.2-41.1a4,4,0,0,1,5.6,5.6l-48,48a3.9,3.9,0,0,1-5.6,0l-48-48a4,4,0,0,1,5.6-5.6L76,198.3V64a4,4,0,0,1,4-4H224A4,4,0,0,1,228,64Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M232,64a8,8,0,0,1-8,8H88V188.7l34.3-34.4a8.1,8.1,0,1,1,11.4,11.4l-48,48a8.2,8.2,0,0,1-11.4,0l-48-48a8.1,8.1,0,0,1,11.4-11.4L72,188.7V64a8,8,0,0,1,8-8H224A8,8,0,0,1,232,64Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowElbowLeftUp(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M236,192a12,12,0,0,1-12,12H80a12,12,0,0,1-12-12V77L40.5,104.5a12,12,0,0,1-17-17l48-48a12,12,0,0,1,17,0l48,48a12,12,0,0,1,0,17,12.1,12.1,0,0,1-17,0L92,77V180H224A12,12,0,0,1,236,192Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M232,192a8,8,0,0,1-8,8H80a8,8,0,0,1-8-8V67.3L37.7,101.7A8.1,8.1,0,0,1,26.3,90.3l48-48a8.1,8.1,0,0,1,11.4,0l48,48a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0L88,67.3V184H224A8,8,0,0,1,232,192Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M232,192a8,8,0,0,1-8,8H80a8,8,0,0,1-8-8V104H32a8,8,0,0,1-7.4-4.9,8.4,8.4,0,0,1,1.7-8.8l48-48a8.1,8.1,0,0,1,11.4,0l48,48a8.4,8.4,0,0,1,1.7,8.8A8,8,0,0,1,128,104H88v80H224A8,8,0,0,1,232,192Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M230,192a6,6,0,0,1-6,6H80a6,6,0,0,1-6-6V62.5L36.2,100.2a5.9,5.9,0,1,1-8.4-8.4l48-48a5.8,5.8,0,0,1,8.4,0l48,48a5.9,5.9,0,1,1-8.4,8.4L86,62.5V186H224A6,6,0,0,1,230,192Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M228,192a4,4,0,0,1-4,4H80a4,4,0,0,1-4-4V57.7L34.8,98.8a4,4,0,0,1-5.6-5.6l48-48a3.9,3.9,0,0,1,5.6,0l48,48a3.9,3.9,0,0,1,0,5.6,3.9,3.9,0,0,1-5.6,0L84,57.7V188H224A4,4,0,0,1,228,192Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M232,192a8,8,0,0,1-8,8H80a8,8,0,0,1-8-8V67.3L37.7,101.7A8.1,8.1,0,0,1,26.3,90.3l48-48a8.1,8.1,0,0,1,11.4,0l48,48a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0L88,67.3V184H224A8,8,0,0,1,232,192Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowElbowLeft(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M240.5,104.5l-96,96a12.1,12.1,0,0,1-17,0L36,109v43a12,12,0,0,1-24,0V80A12,12,0,0,1,24,68H96a12,12,0,0,1,0,24H53l83,83,87.5-87.5a12,12,0,0,1,17,17Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M237.7,101.7l-96,96a8.2,8.2,0,0,1-11.4,0L32,99.3V152a8,8,0,0,1-16,0V80a8,8,0,0,1,8-8H96a8,8,0,0,1,0,16H43.3L136,180.7l90.3-90.4a8.1,8.1,0,0,1,11.4,11.4Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M237.7,101.7l-96,96a8.2,8.2,0,0,1-11.4,0L60,127.3,29.7,157.7A8.3,8.3,0,0,1,24,160a8.5,8.5,0,0,1-3.1-.6A8,8,0,0,1,16,152V80a8,8,0,0,1,8-8H96a8,8,0,0,1,7.4,4.9,8.4,8.4,0,0,1-1.7,8.8L71.3,116,136,180.7l90.3-90.4a8.1,8.1,0,0,1,11.4,11.4Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M236.2,100.2l-96,96a5.8,5.8,0,0,1-8.4,0L30,94.5V152a6,6,0,0,1-12,0V80a6,6,0,0,1,6-6H96a6,6,0,0,1,0,12H38.5L136,183.5l91.8-91.7a5.9,5.9,0,0,1,8.4,8.4Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M234.8,98.8l-96,96a3.9,3.9,0,0,1-5.6,0L28,89.7V152a4,4,0,0,1-8,0V80a4,4,0,0,1,4-4H96a4,4,0,0,1,0,8H33.7L136,186.3l93.2-93.1a4,4,0,1,1,5.6,5.6Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M237.7,101.7l-96,96a8.2,8.2,0,0,1-11.4,0L32,99.3V152a8,8,0,0,1-16,0V80a8,8,0,0,1,8-8H96a8,8,0,0,1,0,16H43.3L136,180.7l90.3-90.4a8.1,8.1,0,0,1,11.4,11.4Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowElbowRightDown(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M232.5,168.5l-48,48a12.1,12.1,0,0,1-17,0l-48-48a12,12,0,0,1,17-17L164,179V76H32a12,12,0,0,1,0-24H176a12,12,0,0,1,12,12V179l27.5-27.5a12,12,0,0,1,17,17Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M229.7,165.7l-48,48a8.2,8.2,0,0,1-11.4,0l-48-48a8.1,8.1,0,0,1,11.4-11.4L168,188.7V72H32a8,8,0,0,1,0-16H176a8,8,0,0,1,8,8V188.7l34.3-34.4a8.1,8.1,0,0,1,11.4,11.4Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M229.7,165.7l-48,48a8.2,8.2,0,0,1-11.4,0l-48-48a8.4,8.4,0,0,1-1.7-8.8A8,8,0,0,1,128,152h40V72H32a8,8,0,0,1,0-16H176a8,8,0,0,1,8,8v88h40a8,8,0,0,1,7.4,4.9A8.4,8.4,0,0,1,229.7,165.7Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M228.2,164.2l-48,48a5.8,5.8,0,0,1-8.4,0l-48-48a5.9,5.9,0,0,1,8.4-8.4L170,193.5V70H32a6,6,0,0,1,0-12H176a6,6,0,0,1,6,6V193.5l37.8-37.7a5.9,5.9,0,0,1,8.4,8.4Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M226.8,162.8l-48,48a3.9,3.9,0,0,1-5.6,0l-48-48a4,4,0,1,1,5.6-5.6L172,198.3V68H32a4,4,0,0,1,0-8H176a4,4,0,0,1,4,4V198.3l41.2-41.1a4,4,0,0,1,5.6,5.6Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M229.7,165.7l-48,48a8.2,8.2,0,0,1-11.4,0l-48-48a8.1,8.1,0,0,1,11.4-11.4L168,188.7V72H32a8,8,0,0,1,0-16H176a8,8,0,0,1,8,8V188.7l34.3-34.4a8.1,8.1,0,0,1,11.4,11.4Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowElbowRightUp(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M232.5,104.5a12.1,12.1,0,0,1-17,0L188,77V192a12,12,0,0,1-12,12H32a12,12,0,0,1,0-24H164V77l-27.5,27.5a12,12,0,0,1-17-17l48-48a12,12,0,0,1,17,0l48,48A12,12,0,0,1,232.5,104.5Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M229.7,101.7a8.2,8.2,0,0,1-11.4,0L184,67.3V192a8,8,0,0,1-8,8H32a8,8,0,0,1,0-16H168V67.3l-34.3,34.4a8.1,8.1,0,0,1-11.4-11.4l48-48a8.1,8.1,0,0,1,11.4,0l48,48A8.1,8.1,0,0,1,229.7,101.7Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M231.4,99.1A8,8,0,0,1,224,104H184v88a8,8,0,0,1-8,8H32a8,8,0,0,1,0-16H168V104H128a8,8,0,0,1-7.4-4.9,8.4,8.4,0,0,1,1.7-8.8l48-48a8.1,8.1,0,0,1,11.4,0l48,48A8.4,8.4,0,0,1,231.4,99.1Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M228.2,100.2a5.8,5.8,0,0,1-8.4,0L182,62.5V192a6,6,0,0,1-6,6H32a6,6,0,0,1,0-12H170V62.5l-37.8,37.7a5.9,5.9,0,0,1-8.4-8.4l48-48a5.8,5.8,0,0,1,8.4,0l48,48A5.8,5.8,0,0,1,228.2,100.2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M226.8,98.8a3.9,3.9,0,0,1-5.6,0L180,57.7V192a4,4,0,0,1-4,4H32a4,4,0,0,1,0-8H172V57.7L130.8,98.8a4,4,0,1,1-5.6-5.6l48-48a3.9,3.9,0,0,1,5.6,0l48,48A3.9,3.9,0,0,1,226.8,98.8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M229.7,101.7a8.2,8.2,0,0,1-11.4,0L184,67.3V192a8,8,0,0,1-8,8H32a8,8,0,0,1,0-16H168V67.3l-34.3,34.4a8.1,8.1,0,0,1-11.4-11.4l48-48a8.1,8.1,0,0,1,11.4,0l48,48A8.1,8.1,0,0,1,229.7,101.7Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowElbowRight(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M244,80v72a12,12,0,0,1-24,0V109l-91.5,91.5a12.1,12.1,0,0,1-17,0l-96-96a12,12,0,0,1,17-17L120,175l83-83H160a12,12,0,0,1,0-24h72A12,12,0,0,1,244,80Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M240,80v72a8,8,0,0,1-16,0V99.3l-98.3,98.4a8.2,8.2,0,0,1-11.4,0l-96-96A8.1,8.1,0,0,1,29.7,90.3L120,180.7,212.7,88H160a8,8,0,0,1,0-16h72A8,8,0,0,1,240,80Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M240,80v72a8,8,0,0,1-4.9,7.4,8.5,8.5,0,0,1-3.1.6,8.3,8.3,0,0,1-5.7-2.3L196,127.3l-70.3,70.4a8.2,8.2,0,0,1-11.4,0l-96-96A8.1,8.1,0,0,1,29.7,90.3L120,180.7,184.7,116,154.3,85.7a8.4,8.4,0,0,1-1.7-8.8A8,8,0,0,1,160,72h72A8,8,0,0,1,240,80Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M238,80v72a6,6,0,0,1-12,0V94.5L124.2,196.2a5.8,5.8,0,0,1-8.4,0l-96-96a5.9,5.9,0,1,1,8.4-8.4L120,183.5,217.5,86H160a6,6,0,0,1,0-12h72A6,6,0,0,1,238,80Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M236,80v72a4,4,0,0,1-8,0V89.7L122.8,194.8a3.9,3.9,0,0,1-5.6,0l-96-96a4,4,0,0,1,5.6-5.6L120,186.3,222.3,84H160a4,4,0,0,1,0-8h72A4,4,0,0,1,236,80Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M240,80v72a8,8,0,0,1-16,0V99.3l-98.3,98.4a8.2,8.2,0,0,1-11.4,0l-96-96A8.1,8.1,0,0,1,29.7,90.3L120,180.7,212.7,88H160a8,8,0,0,1,0-16h72A8,8,0,0,1,240,80Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowElbowUpLeft(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M204,80V224a12,12,0,0,1-24,0V92H77l27.5,27.5a12,12,0,0,1,0,17,12.1,12.1,0,0,1-17,0l-48-48a12,12,0,0,1,0-17l48-48a12,12,0,0,1,17,17L77,68H192A12,12,0,0,1,204,80Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M200,80V224a8,8,0,0,1-16,0V88H67.3l34.4,34.3a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0l-48-48a8.1,8.1,0,0,1,0-11.4l48-48a8.1,8.1,0,1,1,11.4,11.4L67.3,72H192A8,8,0,0,1,200,80Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M200,80V224a8,8,0,0,1-16,0V88H104v40a8,8,0,0,1-4.9,7.4,8.5,8.5,0,0,1-3.1.6,8.3,8.3,0,0,1-5.7-2.3l-48-48a8.1,8.1,0,0,1,0-11.4l48-48a8.4,8.4,0,0,1,8.8-1.7A8,8,0,0,1,104,32V72h88A8,8,0,0,1,200,80Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M198,80V224a6,6,0,0,1-12,0V86H62.5l37.7,37.8a5.9,5.9,0,1,1-8.4,8.4l-48-48a5.8,5.8,0,0,1,0-8.4l48-48a5.9,5.9,0,1,1,8.4,8.4L62.5,74H192A6,6,0,0,1,198,80Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M196,80V224a4,4,0,0,1-8,0V84H57.7l41.1,41.2a3.9,3.9,0,0,1,0,5.6,3.9,3.9,0,0,1-5.6,0l-48-48a3.9,3.9,0,0,1,0-5.6l48-48a4,4,0,0,1,5.6,5.6L57.7,76H192A4,4,0,0,1,196,80Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M200,80V224a8,8,0,0,1-16,0V88H67.3l34.4,34.3a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0l-48-48h0l-.5-.5-.2-.3-.3-.4c0-.1-.1-.2-.2-.3l-.2-.3c0-.2-.1-.3-.1-.4l-.2-.3c0-.2-.1-.3-.1-.4l-.2-.4a.8.8,0,0,0-.1-.4v-.3a.9.9,0,0,1-.1-.5.4.4,0,0,0-.1-.3V79.2a.4.4,0,0,0,.1-.3.9.9,0,0,1,.1-.5v-.3a.8.8,0,0,0,.1-.4l.2-.4c0-.1.1-.2.1-.4l.2-.3c0-.1.1-.2.1-.4l.2-.3c.1-.1.2-.2.2-.3l.3-.4.2-.3.5-.5h0l48-48a8.1,8.1,0,1,1,11.4,11.4L67.3,72H192A8,8,0,0,1,200,80Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowElbowUpRight(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216.5,88.5l-48,48a12.1,12.1,0,0,1-17,0,12,12,0,0,1,0-17L179,92H76V224a12,12,0,0,1-24,0V80A12,12,0,0,1,64,68H179L151.5,40.5a12,12,0,0,1,17-17l48,48A12,12,0,0,1,216.5,88.5Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M213.7,85.7l-48,48a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L188.7,88H72V224a8,8,0,0,1-16,0V80a8,8,0,0,1,8-8H188.7L154.3,37.7a8.1,8.1,0,0,1,11.4-11.4l48,48A8.1,8.1,0,0,1,213.7,85.7Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M213.7,85.7l-48,48A8.3,8.3,0,0,1,160,136a8.5,8.5,0,0,1-3.1-.6A8,8,0,0,1,152,128V88H72V224a8,8,0,0,1-16,0V80a8,8,0,0,1,8-8h88V32a8,8,0,0,1,4.9-7.4,8.4,8.4,0,0,1,8.8,1.7l48,48A8.1,8.1,0,0,1,213.7,85.7Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M212.2,84.2l-48,48a5.9,5.9,0,1,1-8.4-8.4L193.5,86H70V224a6,6,0,0,1-12,0V80a6,6,0,0,1,6-6H193.5L155.8,36.2a5.9,5.9,0,1,1,8.4-8.4l48,48A5.8,5.8,0,0,1,212.2,84.2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M210.8,82.8l-48,48a3.9,3.9,0,0,1-5.6,0,3.9,3.9,0,0,1,0-5.6L198.3,84H68V224a4,4,0,0,1-8,0V80a4,4,0,0,1,4-4H198.3L157.2,34.8a4,4,0,0,1,5.6-5.6l48,48A3.9,3.9,0,0,1,210.8,82.8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M213.7,85.7l-48,48a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L188.7,88H72V224a8,8,0,0,1-16,0V80a8,8,0,0,1,8-8H188.7L154.3,37.7a8.1,8.1,0,0,1,11.4-11.4l48,48A8.1,8.1,0,0,1,213.7,85.7Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowFatDown(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M235.1,131.4A12,12,0,0,0,224,124H188V48a20.1,20.1,0,0,0-20-20H88A20.1,20.1,0,0,0,68,48v76H32a12,12,0,0,0-11.1,7.4,12.2,12.2,0,0,0,2.6,13.1l96,96a12.1,12.1,0,0,0,17,0l96-96A12.2,12.2,0,0,0,235.1,131.4ZM128,215,61,148H80a12,12,0,0,0,12-12V52h72v84a12,12,0,0,0,12,12h19Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M32,136l96,96,96-96H176V48a8,8,0,0,0-8-8H88a8,8,0,0,0-8,8v88Z" />
          </g>
          <path d="M231.4,132.9A8,8,0,0,0,224,128H184V48a16,16,0,0,0-16-16H88A16,16,0,0,0,72,48v80H32a8,8,0,0,0-7.4,4.9,8.4,8.4,0,0,0,1.7,8.8l96,96a8.2,8.2,0,0,0,11.4,0l96-96A8.4,8.4,0,0,0,231.4,132.9ZM128,220.7,51.3,144H80a8,8,0,0,0,8-8V48h80v88a8,8,0,0,0,8,8h28.7Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M229.7,141.7l-96,96a8.1,8.1,0,0,1-11.4,0l-96-96a8.4,8.4,0,0,1-1.7-8.8A8,8,0,0,1,32,128H72V48A16,16,0,0,1,88,32h80a16,16,0,0,1,16,16v80h40a8,8,0,0,1,7.4,4.9A8.4,8.4,0,0,1,229.7,141.7Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M229.5,133.7A6,6,0,0,0,224,130H182V48a14,14,0,0,0-14-14H88A14,14,0,0,0,74,48v82H32a6,6,0,0,0-5.5,3.7,5.7,5.7,0,0,0,1.3,6.5l96,96a5.8,5.8,0,0,0,8.4,0l96-96A5.7,5.7,0,0,0,229.5,133.7ZM128,223.5,46.5,142H80a6,6,0,0,0,6-6V48a2,2,0,0,1,2-2h80a2,2,0,0,1,2,2v88a6,6,0,0,0,6,6h33.5Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M227.7,134.5A4,4,0,0,0,224,132H180V48a12,12,0,0,0-12-12H88A12,12,0,0,0,76,48v84H32a4,4,0,0,0-3.7,2.5,3.8,3.8,0,0,0,.9,4.3l96,96a3.9,3.9,0,0,0,5.6,0l96-96A3.8,3.8,0,0,0,227.7,134.5ZM128,226.3,41.7,140H80a4,4,0,0,0,4-4V48a4,4,0,0,1,4-4h80a4,4,0,0,1,4,4v88a4,4,0,0,0,4,4h38.3Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M231.4,132.9A8,8,0,0,0,224,128H184V48a16,16,0,0,0-16-16H88A16,16,0,0,0,72,48v80H32a8,8,0,0,0-7.4,4.9,8.4,8.4,0,0,0,1.7,8.8l96,96a8.2,8.2,0,0,0,11.4,0l96-96A8.4,8.4,0,0,0,231.4,132.9ZM128,220.7,51.3,144H80a8,8,0,0,0,8-8V48h80v88a8,8,0,0,0,8,8h28.7Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowFatLeft(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M208,68H132V32a12,12,0,0,0-7.4-11.1,12.2,12.2,0,0,0-13.1,2.6l-96,96a12,12,0,0,0,0,17l96,96A12,12,0,0,0,120,236a12.5,12.5,0,0,0,4.6-.9A12,12,0,0,0,132,224V188h76a20.1,20.1,0,0,0,20-20V88A20.1,20.1,0,0,0,208,68Zm-4,96H120a12,12,0,0,0-12,12v19L41,128l67-67V80a12,12,0,0,0,12,12h84Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M120,32,24,128l96,96V176h88a8,8,0,0,0,8-8V88a8,8,0,0,0-8-8H120Z" />
          </g>
          <path d="M208,72H128V32a8,8,0,0,0-4.9-7.4,8.4,8.4,0,0,0-8.8,1.7l-96,96a8.1,8.1,0,0,0,0,11.4l96,96A8.3,8.3,0,0,0,120,232a8.5,8.5,0,0,0,3.1-.6A8,8,0,0,0,128,224V184h80a16,16,0,0,0,16-16V88A16,16,0,0,0,208,72Zm0,96H120a8,8,0,0,0-8,8v28.7L35.3,128,112,51.3V80a8,8,0,0,0,8,8h88Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M224,88v80a16,16,0,0,1-16,16H128v40a8,8,0,0,1-4.9,7.4,8.4,8.4,0,0,1-8.8-1.7l-96-96a8.1,8.1,0,0,1,0-11.4l96-96a8.4,8.4,0,0,1,8.8-1.7A8,8,0,0,1,128,32V72h80A16,16,0,0,1,224,88Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M208,74H126V32a6,6,0,0,0-3.7-5.5,5.7,5.7,0,0,0-6.5,1.3l-96,96a5.8,5.8,0,0,0,0,8.4l96,96A5.6,5.6,0,0,0,120,230a4.6,4.6,0,0,0,2.3-.5A6,6,0,0,0,126,224V182h82a14,14,0,0,0,14-14V88A14,14,0,0,0,208,74Zm2,94a2,2,0,0,1-2,2H120a6,6,0,0,0-6,6v33.5L32.5,128,114,46.5V80a6,6,0,0,0,6,6h88a2,2,0,0,1,2,2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M208,76H124V32a4,4,0,0,0-2.5-3.7,3.8,3.8,0,0,0-4.3.9l-96,96a3.9,3.9,0,0,0,0,5.6l96,96A3.8,3.8,0,0,0,120,228a4,4,0,0,0,4-4V180h84a12,12,0,0,0,12-12V88A12,12,0,0,0,208,76Zm4,92a4,4,0,0,1-4,4H120a4,4,0,0,0-4,4v38.3L29.7,128,116,41.7V80a4,4,0,0,0,4,4h88a4,4,0,0,1,4,4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M208,72H128V32a8,8,0,0,0-4.9-7.4,8.4,8.4,0,0,0-8.8,1.7l-96,96a8.1,8.1,0,0,0,0,11.4l96,96A8.3,8.3,0,0,0,120,232a8.5,8.5,0,0,0,3.1-.6A8,8,0,0,0,128,224V184h80a16,16,0,0,0,16-16V88A16,16,0,0,0,208,72Zm0,96H120a8,8,0,0,0-8,8v28.7L35.3,128,112,51.3V80a8,8,0,0,0,8,8h88Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowFatLineDown(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M235.1,131.4A12,12,0,0,0,224,124H188V80a12,12,0,0,0-12-12H80A12,12,0,0,0,68,80v44H32a12,12,0,0,0-11.1,7.4,12.2,12.2,0,0,0,2.6,13.1l96,96a12.1,12.1,0,0,0,17,0l96-96A12.2,12.2,0,0,0,235.1,131.4ZM128,215,61,148H80a12,12,0,0,0,12-12V92h72v44a12,12,0,0,0,12,12h19ZM68,40A12,12,0,0,1,80,28h96a12,12,0,0,1,0,24H80A12,12,0,0,1,68,40Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <polygon points="32 136 128 232 224 136 176 136 176 72 80 72 80 136 32 136" />
          </g>
          <path d="M231.4,132.9A8,8,0,0,0,224,128H184V72a8,8,0,0,0-8-8H80a8,8,0,0,0-8,8v56H32a8,8,0,0,0-7.4,4.9,8.4,8.4,0,0,0,1.7,8.8l96,96a8.2,8.2,0,0,0,11.4,0l96-96A8.4,8.4,0,0,0,231.4,132.9ZM128,220.7,51.3,144H80a8,8,0,0,0,8-8V80h80v56a8,8,0,0,0,8,8h28.7ZM72,40a8,8,0,0,1,8-8h96a8,8,0,0,1,0,16H80A8,8,0,0,1,72,40Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M72,40a8,8,0,0,1,8-8h96a8,8,0,0,1,0,16H80A8,8,0,0,1,72,40Zm159.4,92.9A8,8,0,0,0,224,128H184V72a8,8,0,0,0-8-8H80a8,8,0,0,0-8,8v56H32a8,8,0,0,0-7.4,4.9,8.4,8.4,0,0,0,1.7,8.8l96,96a8.1,8.1,0,0,0,11.4,0l96-96A8.4,8.4,0,0,0,231.4,132.9Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M229.5,133.7A6,6,0,0,0,224,130H182V72a6,6,0,0,0-6-6H80a6,6,0,0,0-6,6v58H32a6,6,0,0,0-5.5,3.7,5.7,5.7,0,0,0,1.3,6.5l96,96a5.8,5.8,0,0,0,8.4,0l96-96A5.7,5.7,0,0,0,229.5,133.7ZM128,223.5,46.5,142H80a6,6,0,0,0,6-6V78h84v58a6,6,0,0,0,6,6h33.5ZM74,40a6,6,0,0,1,6-6h96a6,6,0,0,1,0,12H80A6,6,0,0,1,74,40Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M227.7,134.5A4,4,0,0,0,224,132H180V72a4,4,0,0,0-4-4H80a4,4,0,0,0-4,4v60H32a4,4,0,0,0-3.7,2.5,3.8,3.8,0,0,0,.9,4.3l96,96a3.9,3.9,0,0,0,5.6,0l96-96A3.8,3.8,0,0,0,227.7,134.5ZM128,226.3,41.7,140H80a4,4,0,0,0,4-4V76h88v60a4,4,0,0,0,4,4h38.3ZM76,40a4,4,0,0,1,4-4h96a4,4,0,0,1,0,8H80A4,4,0,0,1,76,40Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M231.4,132.9A8,8,0,0,0,224,128H184V72a8,8,0,0,0-8-8H80a8,8,0,0,0-8,8v56H32a8,8,0,0,0-7.4,4.9,8.4,8.4,0,0,0,1.7,8.8l96,96a8.2,8.2,0,0,0,11.4,0l96-96A8.4,8.4,0,0,0,231.4,132.9ZM128,220.7,51.3,144H80a8,8,0,0,0,8-8V80h80v56a8,8,0,0,0,8,8h28.7ZM72,40a8,8,0,0,1,8-8h96a8,8,0,0,1,0,16H80A8,8,0,0,1,72,40Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowFatLineLeft(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M176,68H132V32a12,12,0,0,0-7.4-11.1,12.2,12.2,0,0,0-13.1,2.6l-96,96a12,12,0,0,0,0,17l96,96A12,12,0,0,0,120,236a12.5,12.5,0,0,0,4.6-.9A12,12,0,0,0,132,224V188h44a12,12,0,0,0,12-12V80A12,12,0,0,0,176,68Zm-12,96H120a12,12,0,0,0-12,12v19L41,128l67-67V80a12,12,0,0,0,12,12h44Zm64-84v96a12,12,0,0,1-24,0V80a12,12,0,0,1,24,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <polygon points="120 32 24 128 120 224 120 176 184 176 184 80 120 80 120 32" />
          </g>
          <path d="M184,72H128V32a8,8,0,0,0-4.9-7.4,8.4,8.4,0,0,0-8.8,1.7l-96,96a8.1,8.1,0,0,0,0,11.4l96,96A8.3,8.3,0,0,0,120,232a8.5,8.5,0,0,0,3.1-.6A8,8,0,0,0,128,224V184h56a8,8,0,0,0,8-8V80A8,8,0,0,0,184,72Zm-8,96H120a8,8,0,0,0-8,8v28.7L35.3,128,112,51.3V80a8,8,0,0,0,8,8h56Zm48-88v96a8,8,0,0,1-16,0V80a8,8,0,0,1,16,0Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M114.3,229.7l-96-96a8.1,8.1,0,0,1,0-11.4l96-96a8.4,8.4,0,0,1,8.8-1.7A8,8,0,0,1,128,32V72h56a8,8,0,0,1,8,8v96a8,8,0,0,1-8,8H128v40a8,8,0,0,1-4.9,7.4A8.4,8.4,0,0,1,114.3,229.7ZM216,184a8,8,0,0,0,8-8V80a8,8,0,0,0-16,0v96A8,8,0,0,0,216,184Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M184,74H126V32a6,6,0,0,0-3.7-5.5,5.7,5.7,0,0,0-6.5,1.3l-96,96a5.8,5.8,0,0,0,0,8.4l96,96A5.6,5.6,0,0,0,120,230a4.6,4.6,0,0,0,2.3-.5A6,6,0,0,0,126,224V182h58a6,6,0,0,0,6-6V80A6,6,0,0,0,184,74Zm-6,96H120a6,6,0,0,0-6,6v33.5L32.5,128,114,46.5V80a6,6,0,0,0,6,6h58Zm44-90v96a6,6,0,0,1-12,0V80a6,6,0,0,1,12,0Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M184,76H124V32a4,4,0,0,0-2.5-3.7,3.8,3.8,0,0,0-4.3.9l-96,96a3.9,3.9,0,0,0,0,5.6l96,96A3.8,3.8,0,0,0,120,228a4,4,0,0,0,4-4V180h60a4,4,0,0,0,4-4V80A4,4,0,0,0,184,76Zm-4,96H120a4,4,0,0,0-4,4v38.3L29.7,128,116,41.7V80a4,4,0,0,0,4,4h60Zm40-92v96a4,4,0,0,1-8,0V80a4,4,0,0,1,8,0Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M184,72H128V32a8,8,0,0,0-4.9-7.4,8.4,8.4,0,0,0-8.8,1.7l-96,96a8.1,8.1,0,0,0,0,11.4l96,96A8.3,8.3,0,0,0,120,232a8.5,8.5,0,0,0,3.1-.6A8,8,0,0,0,128,224V184h56a8,8,0,0,0,8-8V80A8,8,0,0,0,184,72Zm-8,96H120a8,8,0,0,0-8,8v28.7L35.3,128,112,51.3V80a8,8,0,0,0,8,8h56Zm48-88v96a8,8,0,0,1-16,0V80a8,8,0,0,1,16,0Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowFatLineRight(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M240.5,119.5l-96-96a12.2,12.2,0,0,0-13.1-2.6A12,12,0,0,0,124,32V68H80A12,12,0,0,0,68,80v96a12,12,0,0,0,12,12h44v36a12,12,0,0,0,7.4,11.1,12.5,12.5,0,0,0,4.6.9,12,12,0,0,0,8.5-3.5l96-96A12,12,0,0,0,240.5,119.5ZM148,195V176a12,12,0,0,0-12-12H92V92h44a12,12,0,0,0,12-12V61l67,67ZM52,80v96a12,12,0,0,1-24,0V80a12,12,0,0,1,24,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <polygon points="136 32 232 128 136 224 136 176 72 176 72 80 136 80 136 32" />
          </g>
          <path d="M237.7,122.3l-96-96a8.4,8.4,0,0,0-8.8-1.7A8,8,0,0,0,128,32V72H72a8,8,0,0,0-8,8v96a8,8,0,0,0,8,8h56v40a8,8,0,0,0,4.9,7.4,8.5,8.5,0,0,0,3.1.6,8.3,8.3,0,0,0,5.7-2.3l96-96A8.1,8.1,0,0,0,237.7,122.3ZM144,204.7V176a8,8,0,0,0-8-8H80V88h56a8,8,0,0,0,8-8V51.3L220.7,128ZM48,80v96a8,8,0,0,1-16,0V80a8,8,0,0,1,16,0Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M237.7,133.7l-96,96a8.4,8.4,0,0,1-8.8,1.7A8,8,0,0,1,128,224V184H72a8,8,0,0,1-8-8V80a8,8,0,0,1,8-8h56V32a8,8,0,0,1,4.9-7.4,8.4,8.4,0,0,1,8.8,1.7l96,96A8.1,8.1,0,0,1,237.7,133.7ZM40,72a8,8,0,0,0-8,8v96a8,8,0,0,0,16,0V80A8,8,0,0,0,40,72Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M236.2,123.8l-96-96a5.7,5.7,0,0,0-6.5-1.3A6,6,0,0,0,130,32V74H72a6,6,0,0,0-6,6v96a6,6,0,0,0,6,6h58v42a6,6,0,0,0,3.7,5.5,4.6,4.6,0,0,0,2.3.5,5.6,5.6,0,0,0,4.2-1.8l96-96A5.8,5.8,0,0,0,236.2,123.8ZM142,209.5V176a6,6,0,0,0-6-6H78V86h58a6,6,0,0,0,6-6V46.5L223.5,128ZM46,80v96a6,6,0,0,1-12,0V80a6,6,0,0,1,12,0Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M234.8,125.2l-96-96a3.8,3.8,0,0,0-4.3-.9A4,4,0,0,0,132,32V76H72a4,4,0,0,0-4,4v96a4,4,0,0,0,4,4h60v44a4,4,0,0,0,4,4,3.8,3.8,0,0,0,2.8-1.2l96-96A3.9,3.9,0,0,0,234.8,125.2ZM140,214.3V176a4,4,0,0,0-4-4H76V84h60a4,4,0,0,0,4-4V41.7L226.3,128ZM44,80v96a4,4,0,0,1-8,0V80a4,4,0,0,1,8,0Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M237.7,122.3l-96-96a8.4,8.4,0,0,0-8.8-1.7A8,8,0,0,0,128,32V72H72a8,8,0,0,0-8,8v96a8,8,0,0,0,8,8h56v40a8,8,0,0,0,4.9,7.4,8.5,8.5,0,0,0,3.1.6,8.3,8.3,0,0,0,5.7-2.3l96-96A8.1,8.1,0,0,0,237.7,122.3ZM144,204.7V176a8,8,0,0,0-8-8H80V88h56a8,8,0,0,0,8-8V51.3L220.7,128ZM48,80v96a8,8,0,0,1-16,0V80a8,8,0,0,1,16,0Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowFatLineUp(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M232.5,111.5l-96-96a12,12,0,0,0-17,0l-96,96a12.2,12.2,0,0,0-2.6,13.1A12,12,0,0,0,32,132H68v44a12,12,0,0,0,12,12h96a12,12,0,0,0,12-12V132h36a12,12,0,0,0,11.1-7.4A12.2,12.2,0,0,0,232.5,111.5ZM176,108a12,12,0,0,0-12,12v44H92V120a12,12,0,0,0-12-12H61l67-67,67,67Zm12,108a12,12,0,0,1-12,12H80a12,12,0,0,1,0-24h96A12,12,0,0,1,188,216Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <polygon points="32 120 128 24 224 120 176 120 176 184 80 184 80 120 32 120" />
          </g>
          <path d="M229.7,114.3l-96-96a8.1,8.1,0,0,0-11.4,0l-96,96a8.4,8.4,0,0,0-1.7,8.8A8,8,0,0,0,32,128H72v56a8,8,0,0,0,8,8h96a8,8,0,0,0,8-8V128h40a8,8,0,0,0,7.4-4.9A8.4,8.4,0,0,0,229.7,114.3ZM176,112a8,8,0,0,0-8,8v56H88V120a8,8,0,0,0-8-8H51.3L128,35.3,204.7,112Zm8,104a8,8,0,0,1-8,8H80a8,8,0,0,1,0-16h96A8,8,0,0,1,184,216Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M231.4,123.1A8,8,0,0,1,224,128H184v56a8,8,0,0,1-8,8H80a8,8,0,0,1-8-8V128H32a8,8,0,0,1-7.4-4.9,8.4,8.4,0,0,1,1.7-8.8l96-96a8.1,8.1,0,0,1,11.4,0l96,96A8.4,8.4,0,0,1,231.4,123.1ZM176,208H80a8,8,0,0,0,0,16h96a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M228.2,115.8l-96-96a5.8,5.8,0,0,0-8.4,0l-96,96a5.7,5.7,0,0,0-1.3,6.5A6,6,0,0,0,32,126H74v58a6,6,0,0,0,6,6h96a6,6,0,0,0,6-6V126h42a6,6,0,0,0,5.5-3.7A5.7,5.7,0,0,0,228.2,115.8ZM176,114a6,6,0,0,0-6,6v58H86V120a6,6,0,0,0-6-6H46.5L128,32.5,209.5,114Zm6,102a6,6,0,0,1-6,6H80a6,6,0,0,1,0-12h96A6,6,0,0,1,182,216Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M226.8,117.2l-96-96a3.9,3.9,0,0,0-5.6,0l-96,96a3.8,3.8,0,0,0-.9,4.3A4,4,0,0,0,32,124H76v60a4,4,0,0,0,4,4h96a4,4,0,0,0,4-4V124h44a4,4,0,0,0,3.7-2.5A3.8,3.8,0,0,0,226.8,117.2ZM176,116a4,4,0,0,0-4,4v60H84V120a4,4,0,0,0-4-4H41.7L128,29.7,214.3,116Zm4,100a4,4,0,0,1-4,4H80a4,4,0,0,1,0-8h96A4,4,0,0,1,180,216Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M229.7,114.3l-96-96a8.1,8.1,0,0,0-11.4,0l-96,96a8.4,8.4,0,0,0-1.7,8.8A8,8,0,0,0,32,128H72v56a8,8,0,0,0,8,8h96a8,8,0,0,0,8-8V128h40a8,8,0,0,0,7.4-4.9A8.4,8.4,0,0,0,229.7,114.3ZM176,112a8,8,0,0,0-8,8v56H88V120a8,8,0,0,0-8-8H51.3L128,35.3,204.7,112Zm8,104a8,8,0,0,1-8,8H80a8,8,0,0,1,0-16h96A8,8,0,0,1,184,216Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowFatLinesDown(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M235.1,131.4A12,12,0,0,0,224,124H188v-8a12,12,0,0,0-12-12H80a12,12,0,0,0-12,12v8H32a12,12,0,0,0-11.1,7.4,12.2,12.2,0,0,0,2.6,13.1l96,96a12.1,12.1,0,0,0,17,0l96-96A12.2,12.2,0,0,0,235.1,131.4ZM128,215,61,148H80a12,12,0,0,0,12-12v-8h72v8a12,12,0,0,0,12,12h19ZM68,44A12,12,0,0,1,80,32h96a12,12,0,0,1,0,24H80A12,12,0,0,1,68,44Zm0,36A12,12,0,0,1,80,68h96a12,12,0,0,1,0,24H80A12,12,0,0,1,68,80Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <polygon points="32 136 128 232 224 136 176 136 176 104 80 104 80 136 32 136" />
          </g>
          <path d="M231.4,132.9A8,8,0,0,0,224,128H184V104a8,8,0,0,0-8-8H80a8,8,0,0,0-8,8v24H32a8,8,0,0,0-7.4,4.9,8.4,8.4,0,0,0,1.7,8.8l96,96a8.2,8.2,0,0,0,11.4,0l96-96A8.4,8.4,0,0,0,231.4,132.9ZM128,220.7,51.3,144H80a8,8,0,0,0,8-8V112h80v24a8,8,0,0,0,8,8h28.7ZM72,40a8,8,0,0,1,8-8h96a8,8,0,0,1,0,16H80A8,8,0,0,1,72,40Zm0,32a8,8,0,0,1,8-8h96a8,8,0,0,1,0,16H80A8,8,0,0,1,72,72Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M72,72a8,8,0,0,1,8-8h96a8,8,0,0,1,0,16H80A8,8,0,0,1,72,72Zm8-24h96a8,8,0,0,0,0-16H80a8,8,0,0,0,0,16Zm151.4,84.9A8,8,0,0,0,224,128H184V104a8,8,0,0,0-8-8H80a8,8,0,0,0-8,8v24H32a8,8,0,0,0-7.4,4.9,8.4,8.4,0,0,0,1.7,8.8l96,96a8.1,8.1,0,0,0,11.4,0l96-96A8.4,8.4,0,0,0,231.4,132.9Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M229.5,133.7A6,6,0,0,0,224,130H182V104a6,6,0,0,0-6-6H80a6,6,0,0,0-6,6v26H32a6,6,0,0,0-5.5,3.7,5.7,5.7,0,0,0,1.3,6.5l96,96a5.8,5.8,0,0,0,8.4,0l96-96A5.7,5.7,0,0,0,229.5,133.7ZM128,223.5,46.5,142H80a6,6,0,0,0,6-6V110h84v26a6,6,0,0,0,6,6h33.5ZM74,40a6,6,0,0,1,6-6h96a6,6,0,0,1,0,12H80A6,6,0,0,1,74,40Zm0,32a6,6,0,0,1,6-6h96a6,6,0,0,1,0,12H80A6,6,0,0,1,74,72Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M227.7,134.5A4,4,0,0,0,224,132H180V104a4,4,0,0,0-4-4H80a4,4,0,0,0-4,4v28H32a4,4,0,0,0-3.7,2.5,3.8,3.8,0,0,0,.9,4.3l96,96a3.9,3.9,0,0,0,5.6,0l96-96A3.8,3.8,0,0,0,227.7,134.5ZM128,226.3,41.7,140H80a4,4,0,0,0,4-4V108h88v28a4,4,0,0,0,4,4h38.3ZM76,40a4,4,0,0,1,4-4h96a4,4,0,0,1,0,8H80A4,4,0,0,1,76,40Zm0,32a4,4,0,0,1,4-4h96a4,4,0,0,1,0,8H80A4,4,0,0,1,76,72Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M231.4,132.9A8,8,0,0,0,224,128H184V104a8,8,0,0,0-8-8H80a8,8,0,0,0-8,8v24H32a8,8,0,0,0-7.4,4.9,8.4,8.4,0,0,0,1.7,8.8l96,96a8.2,8.2,0,0,0,11.4,0l96-96A8.4,8.4,0,0,0,231.4,132.9ZM128,220.7,51.3,144H80a8,8,0,0,0,8-8V112h80v24a8,8,0,0,0,8,8h28.7ZM72,40a8,8,0,0,1,8-8h96a8,8,0,0,1,0,16H80A8,8,0,0,1,72,40Zm0,32a8,8,0,0,1,8-8h96a8,8,0,0,1,0,16H80A8,8,0,0,1,72,72Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowFatLinesLeft(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M140,68h-8V32a12,12,0,0,0-7.4-11.1,12.2,12.2,0,0,0-13.1,2.6l-96,96a12,12,0,0,0,0,17l96,96A12,12,0,0,0,120,236a12.5,12.5,0,0,0,4.6-.9A12,12,0,0,0,132,224V188h8a12,12,0,0,0,12-12V80A12,12,0,0,0,140,68Zm-12,96h-8a12,12,0,0,0-12,12v19L41,128l67-67V80a12,12,0,0,0,12,12h8Zm96-84v96a12,12,0,0,1-24,0V80a12,12,0,0,1,24,0Zm-36,0v96a12,12,0,0,1-24,0V80a12,12,0,0,1,24,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <polygon points="120 32 24 128 120 224 120 176 152 176 152 80 120 80 120 32" />
          </g>
          <path d="M152,72H128V32a8,8,0,0,0-4.9-7.4,8.4,8.4,0,0,0-8.8,1.7l-96,96a8.1,8.1,0,0,0,0,11.4l96,96A8.3,8.3,0,0,0,120,232a8.5,8.5,0,0,0,3.1-.6A8,8,0,0,0,128,224V184h24a8,8,0,0,0,8-8V80A8,8,0,0,0,152,72Zm-8,96H120a8,8,0,0,0-8,8v28.7L35.3,128,112,51.3V80a8,8,0,0,0,8,8h24Zm80-88v96a8,8,0,0,1-16,0V80a8,8,0,0,1,16,0Zm-32,0v96a8,8,0,0,1-16,0V80a8,8,0,0,1,16,0Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M192,80v96a8,8,0,0,1-16,0V80a8,8,0,0,1,16,0Zm-40-8H128V32a8,8,0,0,0-4.9-7.4,8.4,8.4,0,0,0-8.8,1.7l-96,96a8.1,8.1,0,0,0,0,11.4l96,96a8.4,8.4,0,0,0,8.8,1.7A8,8,0,0,0,128,224V184h24a8,8,0,0,0,8-8V80A8,8,0,0,0,152,72Zm64,0a8,8,0,0,0-8,8v96a8,8,0,0,0,16,0V80A8,8,0,0,0,216,72Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M152,74H126V32a6,6,0,0,0-3.7-5.5,5.7,5.7,0,0,0-6.5,1.3l-96,96a5.8,5.8,0,0,0,0,8.4l96,96A5.6,5.6,0,0,0,120,230a4.6,4.6,0,0,0,2.3-.5A6,6,0,0,0,126,224V182h26a6,6,0,0,0,6-6V80A6,6,0,0,0,152,74Zm-6,96H120a6,6,0,0,0-6,6v33.5L32.5,128,114,46.5V80a6,6,0,0,0,6,6h26Zm76-90v96a6,6,0,0,1-12,0V80a6,6,0,0,1,12,0Zm-32,0v96a6,6,0,0,1-12,0V80a6,6,0,0,1,12,0Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M152,76H124V32a4,4,0,0,0-2.5-3.7,3.8,3.8,0,0,0-4.3.9l-96,96a3.9,3.9,0,0,0,0,5.6l96,96A3.8,3.8,0,0,0,120,228a4,4,0,0,0,4-4V180h28a4,4,0,0,0,4-4V80A4,4,0,0,0,152,76Zm-4,96H120a4,4,0,0,0-4,4v38.3L29.7,128,116,41.7V80a4,4,0,0,0,4,4h28Zm72-92v96a4,4,0,0,1-8,0V80a4,4,0,0,1,8,0Zm-32,0v96a4,4,0,0,1-8,0V80a4,4,0,0,1,8,0Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M152,72H128V32a8,8,0,0,0-4.9-7.4,8.4,8.4,0,0,0-8.8,1.7l-96,96a8.1,8.1,0,0,0,0,11.4l96,96A8.3,8.3,0,0,0,120,232a8.5,8.5,0,0,0,3.1-.6A8,8,0,0,0,128,224V184h24a8,8,0,0,0,8-8V80A8,8,0,0,0,152,72Zm-8,96H120a8,8,0,0,0-8,8v28.7L35.3,128,112,51.3V80a8,8,0,0,0,8,8h24Zm80-88v96a8,8,0,0,1-16,0V80a8,8,0,0,1,16,0Zm-32,0v96a8,8,0,0,1-16,0V80a8,8,0,0,1,16,0Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowFatLinesRight(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M240.5,119.5l-96-96a12.2,12.2,0,0,0-13.1-2.6A12,12,0,0,0,124,32V68h-8a12,12,0,0,0-12,12v96a12,12,0,0,0,12,12h8v36a12,12,0,0,0,7.4,11.1,12.5,12.5,0,0,0,4.6.9,12,12,0,0,0,8.5-3.5l96-96A12,12,0,0,0,240.5,119.5ZM148,195V176a12,12,0,0,0-12-12h-8V92h8a12,12,0,0,0,12-12V61l67,67ZM56,80v96a12,12,0,0,1-24,0V80a12,12,0,0,1,24,0Zm36,0v96a12,12,0,0,1-24,0V80a12,12,0,0,1,24,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <polygon points="136 32 232 128 136 224 136 176 104 176 104 80 136 80 136 32" />
          </g>
          <path d="M237.7,122.3l-96-96a8.4,8.4,0,0,0-8.8-1.7A8,8,0,0,0,128,32V72H104a8,8,0,0,0-8,8v96a8,8,0,0,0,8,8h24v40a8,8,0,0,0,4.9,7.4,8.5,8.5,0,0,0,3.1.6,8.3,8.3,0,0,0,5.7-2.3l96-96A8.1,8.1,0,0,0,237.7,122.3ZM144,204.7V176a8,8,0,0,0-8-8H112V88h24a8,8,0,0,0,8-8V51.3L220.7,128ZM48,80v96a8,8,0,0,1-16,0V80a8,8,0,0,1,16,0Zm32,0v96a8,8,0,0,1-16,0V80a8,8,0,0,1,16,0Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M80,80v96a8,8,0,0,1-16,0V80a8,8,0,0,1,16,0ZM40,72a8,8,0,0,0-8,8v96a8,8,0,0,0,16,0V80A8,8,0,0,0,40,72Zm197.7,50.3-96-96a8.4,8.4,0,0,0-8.8-1.7A8,8,0,0,0,128,32V72H104a8,8,0,0,0-8,8v96a8,8,0,0,0,8,8h24v40a8,8,0,0,0,4.9,7.4,8.4,8.4,0,0,0,8.8-1.7l96-96A8.1,8.1,0,0,0,237.7,122.3Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M236.2,123.8l-96-96a5.7,5.7,0,0,0-6.5-1.3A6,6,0,0,0,130,32V74H104a6,6,0,0,0-6,6v96a6,6,0,0,0,6,6h26v42a6,6,0,0,0,3.7,5.5,4.6,4.6,0,0,0,2.3.5,5.6,5.6,0,0,0,4.2-1.8l96-96A5.8,5.8,0,0,0,236.2,123.8ZM142,209.5V176a6,6,0,0,0-6-6H110V86h26a6,6,0,0,0,6-6V46.5L223.5,128ZM46,80v96a6,6,0,0,1-12,0V80a6,6,0,0,1,12,0Zm32,0v96a6,6,0,0,1-12,0V80a6,6,0,0,1,12,0Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M234.8,125.2l-96-96a3.8,3.8,0,0,0-4.3-.9A4,4,0,0,0,132,32V76H104a4,4,0,0,0-4,4v96a4,4,0,0,0,4,4h28v44a4,4,0,0,0,4,4,3.8,3.8,0,0,0,2.8-1.2l96-96A3.9,3.9,0,0,0,234.8,125.2ZM140,214.3V176a4,4,0,0,0-4-4H108V84h28a4,4,0,0,0,4-4V41.7L226.3,128ZM44,80v96a4,4,0,0,1-8,0V80a4,4,0,0,1,8,0Zm32,0v96a4,4,0,0,1-8,0V80a4,4,0,0,1,8,0Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M237.7,122.3l-96-96a8.4,8.4,0,0,0-8.8-1.7A8,8,0,0,0,128,32V72H104a8,8,0,0,0-8,8v96a8,8,0,0,0,8,8h24v40a8,8,0,0,0,4.9,7.4,8.5,8.5,0,0,0,3.1.6,8.3,8.3,0,0,0,5.7-2.3l96-96A8.1,8.1,0,0,0,237.7,122.3ZM144,204.7V176a8,8,0,0,0-8-8H112V88h24a8,8,0,0,0,8-8V51.3L220.7,128ZM48,80v96a8,8,0,0,1-16,0V80a8,8,0,0,1,16,0Zm32,0v96a8,8,0,0,1-16,0V80a8,8,0,0,1,16,0Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowFatLinesUp(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M232.5,111.5l-96-96a12,12,0,0,0-17,0l-96,96a12.2,12.2,0,0,0-2.6,13.1A12,12,0,0,0,32,132H68v8a12,12,0,0,0,12,12h96a12,12,0,0,0,12-12v-8h36a12,12,0,0,0,11.1-7.4A12.2,12.2,0,0,0,232.5,111.5ZM176,108a12,12,0,0,0-12,12v8H92v-8a12,12,0,0,0-12-12H61l67-67,67,67Zm12,104a12,12,0,0,1-12,12H80a12,12,0,0,1,0-24h96A12,12,0,0,1,188,212Zm0-36a12,12,0,0,1-12,12H80a12,12,0,0,1,0-24h96A12,12,0,0,1,188,176Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <polygon points="32 120 128 24 224 120 176 120 176 152 80 152 80 120 32 120" />
          </g>
          <path d="M229.7,114.3l-96-96a8.1,8.1,0,0,0-11.4,0l-96,96a8.4,8.4,0,0,0-1.7,8.8A8,8,0,0,0,32,128H72v24a8,8,0,0,0,8,8h96a8,8,0,0,0,8-8V128h40a8,8,0,0,0,7.4-4.9A8.4,8.4,0,0,0,229.7,114.3ZM176,112a8,8,0,0,0-8,8v24H88V120a8,8,0,0,0-8-8H51.3L128,35.3,204.7,112Zm8,104a8,8,0,0,1-8,8H80a8,8,0,0,1,0-16h96A8,8,0,0,1,184,216Zm0-32a8,8,0,0,1-8,8H80a8,8,0,0,1,0-16h96A8,8,0,0,1,184,184Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M184,216a8,8,0,0,1-8,8H80a8,8,0,0,1,0-16h96A8,8,0,0,1,184,216Zm-8-40H80a8,8,0,0,0,0,16h96a8,8,0,0,0,0-16Zm53.7-61.7-96-96a8.1,8.1,0,0,0-11.4,0l-96,96a8.4,8.4,0,0,0-1.7,8.8A8,8,0,0,0,32,128H72v24a8,8,0,0,0,8,8h96a8,8,0,0,0,8-8V128h40a8,8,0,0,0,7.4-4.9A8.4,8.4,0,0,0,229.7,114.3Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M228.2,115.8l-96-96a5.8,5.8,0,0,0-8.4,0l-96,96a5.7,5.7,0,0,0-1.3,6.5A6,6,0,0,0,32,126H74v26a6,6,0,0,0,6,6h96a6,6,0,0,0,6-6V126h42a6,6,0,0,0,5.5-3.7A5.7,5.7,0,0,0,228.2,115.8ZM176,114a6,6,0,0,0-6,6v26H86V120a6,6,0,0,0-6-6H46.5L128,32.5,209.5,114Zm6,102a6,6,0,0,1-6,6H80a6,6,0,0,1,0-12h96A6,6,0,0,1,182,216Zm0-32a6,6,0,0,1-6,6H80a6,6,0,0,1,0-12h96A6,6,0,0,1,182,184Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M226.8,117.2l-96-96a3.9,3.9,0,0,0-5.6,0l-96,96a3.8,3.8,0,0,0-.9,4.3A4,4,0,0,0,32,124H76v28a4,4,0,0,0,4,4h96a4,4,0,0,0,4-4V124h44a4,4,0,0,0,3.7-2.5A3.8,3.8,0,0,0,226.8,117.2ZM176,116a4,4,0,0,0-4,4v28H84V120a4,4,0,0,0-4-4H41.7L128,29.7,214.3,116Zm4,100a4,4,0,0,1-4,4H80a4,4,0,0,1,0-8h96A4,4,0,0,1,180,216Zm0-32a4,4,0,0,1-4,4H80a4,4,0,0,1,0-8h96A4,4,0,0,1,180,184Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M229.7,114.3l-96-96a8.1,8.1,0,0,0-11.4,0l-96,96a8.4,8.4,0,0,0-1.7,8.8A8,8,0,0,0,32,128H72v24a8,8,0,0,0,8,8h96a8,8,0,0,0,8-8V128h40a8,8,0,0,0,7.4-4.9A8.4,8.4,0,0,0,229.7,114.3ZM176,112a8,8,0,0,0-8,8v24H88V120a8,8,0,0,0-8-8H51.3L128,35.3,204.7,112Zm8,104a8,8,0,0,1-8,8H80a8,8,0,0,1,0-16h96A8,8,0,0,1,184,216Zm0-32a8,8,0,0,1-8,8H80a8,8,0,0,1,0-16h96A8,8,0,0,1,184,184Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowFatRight(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M240.5,119.5l-96-96a12.2,12.2,0,0,0-13.1-2.6A12,12,0,0,0,124,32V68H48A20.1,20.1,0,0,0,28,88v80a20.1,20.1,0,0,0,20,20h76v36a12,12,0,0,0,7.4,11.1,12.5,12.5,0,0,0,4.6.9,12,12,0,0,0,8.5-3.5l96-96A12,12,0,0,0,240.5,119.5ZM148,195V176a12,12,0,0,0-12-12H52V92h84a12,12,0,0,0,12-12V61l67,67Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M136,32l96,96-96,96V176H48a8,8,0,0,1-8-8V88a8,8,0,0,1,8-8h88Z" />
          </g>
          <path d="M237.7,122.3l-96-96a8.4,8.4,0,0,0-8.8-1.7A8,8,0,0,0,128,32V72H48A16,16,0,0,0,32,88v80a16,16,0,0,0,16,16h80v40a8,8,0,0,0,4.9,7.4,8.5,8.5,0,0,0,3.1.6,8.3,8.3,0,0,0,5.7-2.3l96-96A8.1,8.1,0,0,0,237.7,122.3ZM144,204.7V176a8,8,0,0,0-8-8H48V88h88a8,8,0,0,0,8-8V51.3L220.7,128Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M132.9,231.4A8,8,0,0,1,128,224V184H48a16,16,0,0,1-16-16V88A16,16,0,0,1,48,72h80V32a8,8,0,0,1,4.9-7.4,8.4,8.4,0,0,1,8.8,1.7l96,96a8.1,8.1,0,0,1,0,11.4l-96,96A8.4,8.4,0,0,1,132.9,231.4Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M236.2,123.8l-96-96a5.7,5.7,0,0,0-6.5-1.3A6,6,0,0,0,130,32V74H48A14,14,0,0,0,34,88v80a14,14,0,0,0,14,14h82v42a6,6,0,0,0,3.7,5.5,4.6,4.6,0,0,0,2.3.5,5.6,5.6,0,0,0,4.2-1.8l96-96A5.8,5.8,0,0,0,236.2,123.8ZM142,209.5V176a6,6,0,0,0-6-6H48a2,2,0,0,1-2-2V88a2,2,0,0,1,2-2h88a6,6,0,0,0,6-6V46.5L223.5,128Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M234.8,125.2l-96-96a3.8,3.8,0,0,0-4.3-.9A4,4,0,0,0,132,32V76H48A12,12,0,0,0,36,88v80a12,12,0,0,0,12,12h84v44a4,4,0,0,0,4,4,3.8,3.8,0,0,0,2.8-1.2l96-96A3.9,3.9,0,0,0,234.8,125.2ZM140,214.3V176a4,4,0,0,0-4-4H48a4,4,0,0,1-4-4V88a4,4,0,0,1,4-4h88a4,4,0,0,0,4-4V41.7L226.3,128Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M237.7,122.3l-96-96a8.4,8.4,0,0,0-8.8-1.7A8,8,0,0,0,128,32V72H48A16,16,0,0,0,32,88v80a16,16,0,0,0,16,16h80v40a8,8,0,0,0,4.9,7.4,8.5,8.5,0,0,0,3.1.6,8.3,8.3,0,0,0,5.7-2.3l96-96A8.1,8.1,0,0,0,237.7,122.3ZM144,204.7V176a8,8,0,0,0-8-8H48V88h88a8,8,0,0,0,8-8V51.3L220.7,128Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowFatUp(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M232.5,111.5l-96-96a12,12,0,0,0-17,0l-96,96a12.2,12.2,0,0,0-2.6,13.1A12,12,0,0,0,32,132H68v76a20.1,20.1,0,0,0,20,20h80a20.1,20.1,0,0,0,20-20V132h36a12,12,0,0,0,11.1-7.4A12.2,12.2,0,0,0,232.5,111.5ZM176,108a12,12,0,0,0-12,12v84H92V120a12,12,0,0,0-12-12H61l67-67,67,67Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M32,120l96-96,96,96H176v88a8,8,0,0,1-8,8H88a8,8,0,0,1-8-8V120Z" />
          </g>
          <path d="M229.7,114.3l-96-96a8.1,8.1,0,0,0-11.4,0l-96,96a8.4,8.4,0,0,0-1.7,8.8A8,8,0,0,0,32,128H72v80a16,16,0,0,0,16,16h80a16,16,0,0,0,16-16V128h40a8,8,0,0,0,7.4-4.9A8.4,8.4,0,0,0,229.7,114.3ZM176,112a8,8,0,0,0-8,8v88H88V120a8,8,0,0,0-8-8H51.3L128,35.3,204.7,112Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M231.4,123.1A8,8,0,0,1,224,128H184v80a16,16,0,0,1-16,16H88a16,16,0,0,1-16-16V128H32a8,8,0,0,1-7.4-4.9,8.4,8.4,0,0,1,1.7-8.8l96-96a8.1,8.1,0,0,1,11.4,0l96,96A8.4,8.4,0,0,1,231.4,123.1Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M228.2,115.8l-96-96a5.8,5.8,0,0,0-8.4,0l-96,96a5.7,5.7,0,0,0-1.3,6.5A6,6,0,0,0,32,126H74v82a14,14,0,0,0,14,14h80a14,14,0,0,0,14-14V126h42a6,6,0,0,0,5.5-3.7A5.7,5.7,0,0,0,228.2,115.8ZM176,114a6,6,0,0,0-6,6v88a2,2,0,0,1-2,2H88a2,2,0,0,1-2-2V120a6,6,0,0,0-6-6H46.5L128,32.5,209.5,114Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M226.8,117.2l-96-96a3.9,3.9,0,0,0-5.6,0l-96,96a3.8,3.8,0,0,0-.9,4.3A4,4,0,0,0,32,124H76v84a12,12,0,0,0,12,12h80a12,12,0,0,0,12-12V124h44a4,4,0,0,0,3.7-2.5A3.8,3.8,0,0,0,226.8,117.2ZM176,116a4,4,0,0,0-4,4v88a4,4,0,0,1-4,4H88a4,4,0,0,1-4-4V120a4,4,0,0,0-4-4H41.7L128,29.7,214.3,116Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M229.7,114.3l-96-96a8.1,8.1,0,0,0-11.4,0l-96,96a8.4,8.4,0,0,0-1.7,8.8A8,8,0,0,0,32,128H72v80a16,16,0,0,0,16,16h80a16,16,0,0,0,16-16V128h40a8,8,0,0,0,7.4-4.9A8.4,8.4,0,0,0,229.7,114.3ZM176,112a8,8,0,0,0-8,8v88H88V120a8,8,0,0,0-8-8H51.3L128,35.3,204.7,112Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowLeft(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M228,128a12,12,0,0,1-12,12H69l51.5,51.5a12,12,0,0,1,0,17,12.1,12.1,0,0,1-17,0l-72-72a12,12,0,0,1,0-17l72-72a12,12,0,0,1,17,17L69,116H216A12,12,0,0,1,228,128Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M224,128a8,8,0,0,1-8,8H59.3l58.4,58.3a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0l-72-72a8.1,8.1,0,0,1,0-11.4l72-72a8.1,8.1,0,0,1,11.4,11.4L59.3,120H216A8,8,0,0,1,224,128Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M224,128a8,8,0,0,1-8,8H120v64a8,8,0,0,1-4.9,7.4,8.5,8.5,0,0,1-3.1.6,8.3,8.3,0,0,1-5.7-2.3l-72-72a8.1,8.1,0,0,1,0-11.4l72-72a8.4,8.4,0,0,1,8.8-1.7A8,8,0,0,1,120,56v64h96A8,8,0,0,1,224,128Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M222,128a6,6,0,0,1-6,6H54.5l61.7,61.8a5.9,5.9,0,1,1-8.4,8.4l-72-72a5.8,5.8,0,0,1,0-8.4l72-72a5.9,5.9,0,0,1,8.4,8.4L54.5,122H216A6,6,0,0,1,222,128Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M220,128a4,4,0,0,1-4,4H49.7l65.1,65.2a3.9,3.9,0,0,1,0,5.6,3.9,3.9,0,0,1-5.6,0l-72-72a3.9,3.9,0,0,1,0-5.6l72-72a4,4,0,0,1,5.6,5.6L49.7,124H216A4,4,0,0,1,220,128Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M224,128a8,8,0,0,1-8,8H59.3l58.4,58.3a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0l-72-72a8.1,8.1,0,0,1,0-11.4l72-72a8.1,8.1,0,0,1,11.4,11.4L59.3,120H216A8,8,0,0,1,224,128Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowLineDownLeft(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M184.5,79.5a12,12,0,0,1,0,17L93,188h71a12,12,0,0,1,0,24H64a12,12,0,0,1-12-12V100a12,12,0,0,1,24,0v71l91.5-91.5A12,12,0,0,1,184.5,79.5ZM216,28H40a12,12,0,0,0,0,24H216a12,12,0,0,0,0-24Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M181.7,82.3a8.1,8.1,0,0,1,0,11.4L83.3,192H164a8,8,0,0,1,0,16H64a8,8,0,0,1-8-8V100a8,8,0,0,1,16,0v80.7l98.3-98.4A8.1,8.1,0,0,1,181.7,82.3ZM216,32H40a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M181.7,82.3a8.1,8.1,0,0,1,0,11.4L125.3,150l44.4,44.3a8.4,8.4,0,0,1,1.7,8.8A8,8,0,0,1,164,208H64a8,8,0,0,1-8-8V100a8,8,0,0,1,4.9-7.4,8.4,8.4,0,0,1,8.8,1.7L114,138.7l56.3-56.4A8.1,8.1,0,0,1,181.7,82.3ZM216,32H40a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M180.2,83.8a5.8,5.8,0,0,1,0,8.4L78.5,194H164a6,6,0,0,1,0,12H64a6,6,0,0,1-6-6V100a6,6,0,0,1,12,0v85.5L171.8,83.8A5.8,5.8,0,0,1,180.2,83.8ZM216,34H40a6,6,0,0,0,0,12H216a6,6,0,0,0,0-12Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M178.8,85.2a3.9,3.9,0,0,1,0,5.6L73.7,196H164a4,4,0,0,1,0,8H64a4,4,0,0,1-4-4V100a4,4,0,0,1,8,0v90.3L173.2,85.2A3.9,3.9,0,0,1,178.8,85.2ZM216,36H40a4,4,0,0,0,0,8H216a4,4,0,0,0,0-8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M181.7,82.3a8.1,8.1,0,0,1,0,11.4L83.3,192H164a8,8,0,0,1,0,16H64a8,8,0,0,1-8-8V100a8,8,0,0,1,16,0v80.7l98.3-98.4A8.1,8.1,0,0,1,181.7,82.3ZM216,32H40a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowLineDownRight(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M204,100V200a12,12,0,0,1-12,12H92a12,12,0,0,1,0-24h71L71.5,96.5a12,12,0,0,1,17-17L180,171V100a12,12,0,0,1,24,0Zm12-72H40a12,12,0,0,0,0,24H216a12,12,0,0,0,0-24Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M200,100V200a8,8,0,0,1-8,8H92a8,8,0,0,1,0-16h80.7L74.3,93.7A8.1,8.1,0,0,1,85.7,82.3L184,180.7V100a8,8,0,0,1,16,0Zm16-68H40a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M200,100V200a8,8,0,0,1-8,8H92a8,8,0,0,1-7.4-4.9,8.4,8.4,0,0,1,1.7-8.8L130.7,150,74.3,93.7A8.1,8.1,0,0,1,85.7,82.3L142,138.7l44.3-44.4a8.4,8.4,0,0,1,8.8-1.7A8,8,0,0,1,200,100Zm16-68H40a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M198,100V200a6,6,0,0,1-6,6H92a6,6,0,0,1,0-12h85.5L75.8,92.2a5.9,5.9,0,0,1,8.4-8.4L186,185.5V100a6,6,0,0,1,12,0Zm18-66H40a6,6,0,0,0,0,12H216a6,6,0,0,0,0-12Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M196,100V200a4,4,0,0,1-4,4H92a4,4,0,0,1,0-8h90.3L77.2,90.8a4,4,0,0,1,5.6-5.6L188,190.3V100a4,4,0,0,1,8,0Zm20-64H40a4,4,0,0,0,0,8H216a4,4,0,0,0,0-8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M200,100V200a8,8,0,0,1-8,8H92a8,8,0,0,1,0-16h80.7L74.3,93.7A8.1,8.1,0,0,1,85.7,82.3L184,180.7V100a8,8,0,0,1,16,0Zm16-68H40a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowLineDown(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M47.5,112.5a12,12,0,0,1,17-17L116,147V32a12,12,0,0,1,24,0V147l51.5-51.5a12,12,0,0,1,17,17l-72,72a12.1,12.1,0,0,1-17,0ZM216,204H40a12,12,0,0,0,0,24H216a12,12,0,0,0,0-24Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M50.3,117.7a8.1,8.1,0,0,1,11.4-11.4L120,164.7V32a8,8,0,0,1,16,0V164.7l58.3-58.4a8.1,8.1,0,0,1,11.4,11.4l-72,72a8.2,8.2,0,0,1-11.4,0ZM216,208H40a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M50.3,117.7a8.4,8.4,0,0,1-1.7-8.8A8,8,0,0,1,56,104h64V32a8,8,0,0,1,16,0v72h64a8,8,0,0,1,7.4,4.9,8.4,8.4,0,0,1-1.7,8.8l-72,72a8.2,8.2,0,0,1-11.4,0ZM216,208H40a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M51.8,116.2a5.9,5.9,0,0,1,8.4-8.4L122,169.5V32a6,6,0,0,1,12,0V169.5l61.8-61.7a5.9,5.9,0,0,1,8.4,8.4l-72,72a5.8,5.8,0,0,1-8.4,0ZM216,210H40a6,6,0,0,0,0,12H216a6,6,0,0,0,0-12Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M53.2,114.8a4,4,0,0,1,5.6-5.6L124,174.3V32a4,4,0,0,1,8,0V174.3l65.2-65.1a4,4,0,1,1,5.6,5.6l-72,72a3.9,3.9,0,0,1-5.6,0ZM216,212H40a4,4,0,0,0,0,8H216a4,4,0,0,0,0-8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M50.3,117.7a8.1,8.1,0,0,1,11.4-11.4L120,164.7V32a8,8,0,0,1,16,0V164.7l58.3-58.4a8.1,8.1,0,0,1,11.4,11.4l-72,72a8.2,8.2,0,0,1-11.4,0ZM216,208H40a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowLineLeft(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M236,128a12,12,0,0,1-12,12H109l51.5,51.5a12,12,0,0,1,0,17,12.1,12.1,0,0,1-17,0l-72-72a12,12,0,0,1,0-17l72-72a12,12,0,0,1,17,17L109,116H224A12,12,0,0,1,236,128Z" />
          <path d="M40,228a12,12,0,0,1-12-12V40a12,12,0,0,1,24,0V216A12,12,0,0,1,40,228Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M232,128a8,8,0,0,1-8,8H91.3l58.4,58.3a8.1,8.1,0,0,1,0,11.4A8.3,8.3,0,0,1,144,208a8.5,8.5,0,0,1-5.7-2.3l-72-72a8.1,8.1,0,0,1,0-11.4l72-72a8.1,8.1,0,0,1,11.4,11.4L91.3,120H224A8,8,0,0,1,232,128ZM40,32a8,8,0,0,0-8,8V216a8,8,0,0,0,16,0V40A8,8,0,0,0,40,32Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M232,128a8,8,0,0,1-8,8H152v64a8,8,0,0,1-4.9,7.4,8.5,8.5,0,0,1-3.1.6,8.3,8.3,0,0,1-5.7-2.3l-72-72a8.1,8.1,0,0,1,0-11.4l72-72a8.4,8.4,0,0,1,8.8-1.7A8,8,0,0,1,152,56v64h72A8,8,0,0,1,232,128ZM40,32a8,8,0,0,0-8,8V216a8,8,0,0,0,16,0V40A8,8,0,0,0,40,32Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M230,128a6,6,0,0,1-6,6H86.5l61.7,61.8a5.9,5.9,0,1,1-8.4,8.4l-72-72a5.8,5.8,0,0,1,0-8.4l72-72a5.9,5.9,0,0,1,8.4,8.4L86.5,122H224A6,6,0,0,1,230,128ZM40,34a6,6,0,0,0-6,6V216a6,6,0,0,0,12,0V40A6,6,0,0,0,40,34Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M228,128a4,4,0,0,1-4,4H81.7l65.1,65.2a3.9,3.9,0,0,1,0,5.6A3.8,3.8,0,0,1,144,204a3.9,3.9,0,0,1-2.8-1.2l-72-72a3.9,3.9,0,0,1,0-5.6l72-72a4,4,0,1,1,5.6,5.6L81.7,124H224A4,4,0,0,1,228,128ZM40,36a4,4,0,0,0-4,4V216a4,4,0,0,0,8,0V40A4,4,0,0,0,40,36Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M232,128a8,8,0,0,1-8,8H91.3l58.4,58.3a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0l-72-72a8.1,8.1,0,0,1,0-11.4l72-72a8.1,8.1,0,0,1,11.4,11.4L91.3,120H224A8,8,0,0,1,232,128ZM40,32a8,8,0,0,0-8,8V216a8,8,0,0,0,16,0V40A8,8,0,0,0,40,32Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowLineRight(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M184.5,119.5a12,12,0,0,1,0,17l-72,72a12.1,12.1,0,0,1-17,0,12,12,0,0,1,0-17L147,140H32a12,12,0,0,1,0-24H147L95.5,64.5a12,12,0,0,1,17-17ZM216,28a12,12,0,0,0-12,12V216a12,12,0,0,0,24,0V40A12,12,0,0,0,216,28Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M189.7,122.3a8.1,8.1,0,0,1,0,11.4l-72,72A8.5,8.5,0,0,1,112,208a8.3,8.3,0,0,1-5.7-2.3,8.1,8.1,0,0,1,0-11.4L164.7,136H32a8,8,0,0,1,0-16H164.7L106.3,61.7a8.1,8.1,0,0,1,11.4-11.4ZM216,32a8,8,0,0,0-8,8V216a8,8,0,0,0,16,0V40A8,8,0,0,0,216,32Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M189.7,122.3a8.1,8.1,0,0,1,0,11.4l-72,72A8.3,8.3,0,0,1,112,208a8.5,8.5,0,0,1-3.1-.6A8,8,0,0,1,104,200V136H32a8,8,0,0,1,0-16h72V56a8,8,0,0,1,4.9-7.4,8.4,8.4,0,0,1,8.8,1.7ZM216,32a8,8,0,0,0-8,8V216a8,8,0,0,0,16,0V40A8,8,0,0,0,216,32Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M188.2,123.8a5.8,5.8,0,0,1,0,8.4l-72,72a5.9,5.9,0,0,1-8.4-8.4L169.5,134H32a6,6,0,0,1,0-12H169.5L107.8,60.2a5.9,5.9,0,0,1,8.4-8.4ZM216,34a6,6,0,0,0-6,6V216a6,6,0,0,0,12,0V40A6,6,0,0,0,216,34Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M186.8,125.2a3.9,3.9,0,0,1,0,5.6l-72,72A3.9,3.9,0,0,1,112,204a3.8,3.8,0,0,1-2.8-1.2,3.9,3.9,0,0,1,0-5.6L174.3,132H32a4,4,0,0,1,0-8H174.3L109.2,58.8a4,4,0,0,1,5.6-5.6ZM216,36a4,4,0,0,0-4,4V216a4,4,0,0,0,8,0V40A4,4,0,0,0,216,36Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M189.7,122.3a8.1,8.1,0,0,1,0,11.4l-72,72a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L164.7,136H32a8,8,0,0,1,0-16H164.7L106.3,61.7a8.1,8.1,0,0,1,11.4-11.4ZM216,32a8,8,0,0,0-8,8V216a8,8,0,0,0,16,0V40A8,8,0,0,0,216,32Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowLineUpLeft(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M52,156V56A12,12,0,0,1,64,44H164a12,12,0,0,1,0,24H93l91.5,91.5a12,12,0,0,1,0,17,12.1,12.1,0,0,1-17,0L76,85v71a12,12,0,0,1-24,0Zm164,48H40a12,12,0,0,0,0,24H216a12,12,0,0,0,0-24Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M56,156V56a8,8,0,0,1,8-8H164a8,8,0,0,1,0,16H83.3l98.4,98.3a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0L72,75.3V156a8,8,0,0,1-16,0Zm160,52H40a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M56,156V56a8,8,0,0,1,8-8H164a8,8,0,0,1,7.4,4.9,8.4,8.4,0,0,1-1.7,8.8L125.3,106l56.4,56.3a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0L114,117.3,69.7,161.7A8.3,8.3,0,0,1,64,164a8.5,8.5,0,0,1-3.1-.6A8,8,0,0,1,56,156Zm160,52H40a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M58,156V56a6,6,0,0,1,6-6H164a6,6,0,0,1,0,12H78.5L180.2,163.8a5.9,5.9,0,1,1-8.4,8.4L70,70.5V156a6,6,0,0,1-12,0Zm158,54H40a6,6,0,0,0,0,12H216a6,6,0,0,0,0-12Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M60,156V56a4,4,0,0,1,4-4H164a4,4,0,0,1,0,8H73.7L178.8,165.2a3.9,3.9,0,0,1,0,5.6,3.9,3.9,0,0,1-5.6,0L68,65.7V156a4,4,0,0,1-8,0Zm156,56H40a4,4,0,0,0,0,8H216a4,4,0,0,0,0-8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M56,156V56a8,8,0,0,1,8-8H164a8,8,0,0,1,0,16H83.3l98.4,98.3a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0L72,75.3V156a8,8,0,0,1-16,0Zm160,52H40a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowLineUpRight(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M71.5,176.5a12,12,0,0,1,0-17L163,68H92a12,12,0,0,1,0-24H192a12,12,0,0,1,12,12V156a12,12,0,0,1-24,0V85L88.5,176.5a12.1,12.1,0,0,1-17,0ZM216,204H40a12,12,0,0,0,0,24H216a12,12,0,0,0,0-24Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M74.3,173.7a8.1,8.1,0,0,1,0-11.4L172.7,64H92a8,8,0,0,1,0-16H192a8,8,0,0,1,8,8V156a8,8,0,0,1-16,0V75.3L85.7,173.7a8.2,8.2,0,0,1-11.4,0ZM216,208H40a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M74.3,173.7a8.1,8.1,0,0,1,0-11.4L130.7,106,86.3,61.7a8.4,8.4,0,0,1-1.7-8.8A8,8,0,0,1,92,48H192a8,8,0,0,1,8,8V156a8,8,0,0,1-4.9,7.4,8.5,8.5,0,0,1-3.1.6,8.3,8.3,0,0,1-5.7-2.3L142,117.3,85.7,173.7a8.2,8.2,0,0,1-11.4,0ZM216,208H40a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M75.8,172.2a5.8,5.8,0,0,1,0-8.4L177.5,62H92a6,6,0,0,1,0-12H192a6,6,0,0,1,6,6V156a6,6,0,0,1-12,0V70.5L84.2,172.2a5.8,5.8,0,0,1-8.4,0ZM216,210H40a6,6,0,0,0,0,12H216a6,6,0,0,0,0-12Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M77.2,170.8a3.9,3.9,0,0,1,0-5.6L182.3,60H92a4,4,0,0,1,0-8H192a4,4,0,0,1,4,4V156a4,4,0,0,1-8,0V65.7L82.8,170.8a3.9,3.9,0,0,1-5.6,0ZM216,212H40a4,4,0,0,0,0,8H216a4,4,0,0,0,0-8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M74.3,173.7a8.1,8.1,0,0,1,0-11.4L172.7,64H92a8,8,0,0,1,0-16H192a8,8,0,0,1,8,8V156a8,8,0,0,1-16,0V75.3L85.7,173.7a8.2,8.2,0,0,1-11.4,0ZM216,208H40a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowLineUp(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M208.5,143.5a12,12,0,0,1,0,17,12.1,12.1,0,0,1-17,0L140,109V224a12,12,0,0,1-24,0V109L64.5,160.5a12,12,0,0,1-17-17l72-72a12,12,0,0,1,17,0ZM216,28H40a12,12,0,0,0,0,24H216a12,12,0,0,0,0-24Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M205.7,138.3a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0L136,91.3V224a8,8,0,0,1-16,0V91.3L61.7,149.7a8.1,8.1,0,0,1-11.4-11.4l72-72a8.1,8.1,0,0,1,11.4,0ZM216,32H40a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M205.7,138.3a8.4,8.4,0,0,1,1.7,8.8A8,8,0,0,1,200,152H136v72a8,8,0,0,1-16,0V152H56a8,8,0,0,1-7.4-4.9,8.4,8.4,0,0,1,1.7-8.8l72-72a8.1,8.1,0,0,1,11.4,0ZM216,32H40a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M204.2,139.8a5.9,5.9,0,1,1-8.4,8.4L134,86.5V224a6,6,0,0,1-12,0V86.5L60.2,148.2a5.9,5.9,0,0,1-8.4-8.4l72-72a5.8,5.8,0,0,1,8.4,0ZM216,34H40a6,6,0,0,0,0,12H216a6,6,0,0,0,0-12Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M202.8,141.2a3.9,3.9,0,0,1,0,5.6,3.9,3.9,0,0,1-5.6,0L132,81.7V224a4,4,0,0,1-8,0V81.7L58.8,146.8a4,4,0,0,1-5.6-5.6l72-72a3.9,3.9,0,0,1,5.6,0ZM216,36H40a4,4,0,0,0,0,8H216a4,4,0,0,0,0-8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M205.7,138.3a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0L136,91.3V224a8,8,0,0,1-16,0V91.3L61.7,149.7a8.1,8.1,0,0,1-11.4-11.4l72-72a8.1,8.1,0,0,1,11.4,0ZM216,32H40a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowRight(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M224.5,136.5l-72,72a12.1,12.1,0,0,1-17,0,12,12,0,0,1,0-17L187,140H40a12,12,0,0,1,0-24H187L135.5,64.5a12,12,0,0,1,17-17l72,72A12,12,0,0,1,224.5,136.5Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M221.7,133.7l-72,72a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L196.7,136H40a8,8,0,0,1,0-16H196.7L138.3,61.7a8.1,8.1,0,0,1,11.4-11.4l72,72A8.1,8.1,0,0,1,221.7,133.7Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M221.7,133.7l-72,72A8.3,8.3,0,0,1,144,208a8.5,8.5,0,0,1-3.1-.6A8,8,0,0,1,136,200V136H40a8,8,0,0,1,0-16h96V56a8,8,0,0,1,4.9-7.4,8.4,8.4,0,0,1,8.8,1.7l72,72A8.1,8.1,0,0,1,221.7,133.7Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M220.2,132.2l-72,72a5.9,5.9,0,0,1-8.4-8.4L201.5,134H40a6,6,0,0,1,0-12H201.5L139.8,60.2a5.9,5.9,0,0,1,8.4-8.4l72,72A5.8,5.8,0,0,1,220.2,132.2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M218.8,130.8l-72,72a3.9,3.9,0,0,1-5.6,0,3.9,3.9,0,0,1,0-5.6L206.3,132H40a4,4,0,0,1,0-8H206.3L141.2,58.8a4,4,0,0,1,5.6-5.6l72,72A3.9,3.9,0,0,1,218.8,130.8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M221.7,133.7l-72,72a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L196.7,136H40a8,8,0,0,1,0-16H196.7L138.3,61.7a8.1,8.1,0,0,1,11.4-11.4l72,72A8.1,8.1,0,0,1,221.7,133.7Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowSquareDownLeft(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M208,28H48A20.1,20.1,0,0,0,28,48V208a20.1,20.1,0,0,0,20,20H208a20.1,20.1,0,0,0,20-20V48A20.1,20.1,0,0,0,208,28Zm-4,176H52V52H204ZM88,156V108a12,12,0,0,1,24,0v19l35.5-35.5a12,12,0,0,1,17,17L129,144h19a12,12,0,0,1,0,24H100A12,12,0,0,1,88,156Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect
              x="40"
              y="40"
              width="176"
              height="176"
              rx="8"
              transform="translate(0 256) rotate(-90)"
            />
          </g>
          <path d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32Zm0,176H48V48H208Z" />
          <path d="M100,164h48a8,8,0,0,0,0-16H119.3l42.4-42.3a8.1,8.1,0,0,0-11.4-11.4L108,136.7V108a8,8,0,0,0-16,0v48A8,8,0,0,0,100,164Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32Zm-46.3,73.7L119.3,148H148a8,8,0,0,1,0,16H100a8,8,0,0,1-8-8V108a8,8,0,0,1,16,0v28.7l42.3-42.4a8.1,8.1,0,0,1,11.4,11.4Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M208,34H48A14,14,0,0,0,34,48V208a14,14,0,0,0,14,14H208a14,14,0,0,0,14-14V48A14,14,0,0,0,208,34Zm2,174a2,2,0,0,1-2,2H48a2,2,0,0,1-2-2V48a2,2,0,0,1,2-2H208a2,2,0,0,1,2,2ZM160.2,95.8a5.8,5.8,0,0,1,0,8.4L114.5,150H148a6,6,0,0,1,0,12H100a6,6,0,0,1-6-6V108a6,6,0,0,1,12,0v33.5l45.8-45.7A5.8,5.8,0,0,1,160.2,95.8Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M208,36H48A12,12,0,0,0,36,48V208a12,12,0,0,0,12,12H208a12,12,0,0,0,12-12V48A12,12,0,0,0,208,36Zm4,172a4,4,0,0,1-4,4H48a4,4,0,0,1-4-4V48a4,4,0,0,1,4-4H208a4,4,0,0,1,4,4ZM158.8,97.2a3.9,3.9,0,0,1,0,5.6L109.7,152H148a4,4,0,0,1,0,8H100a4,4,0,0,1-4-4V108a4,4,0,0,1,8,0v38.3l49.2-49.1A3.9,3.9,0,0,1,158.8,97.2Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32Zm0,176H48V48H208ZM92,156V108a8,8,0,0,1,16,0v28.7l42.3-42.4a8.1,8.1,0,0,1,11.4,11.4L119.3,148H148a8,8,0,0,1,0,16H100A8,8,0,0,1,92,156Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowSquareDownRight(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M208,28H48A20.1,20.1,0,0,0,28,48V208a20.1,20.1,0,0,0,20,20H208a20.1,20.1,0,0,0,20-20V48A20.1,20.1,0,0,0,208,28Zm-4,176H52V52H204ZM91.5,108.5a12,12,0,0,1,17-17L144,127V108a12,12,0,0,1,24,0v48a12,12,0,0,1-12,12H108a12,12,0,0,1,0-24h19Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect
              x="40"
              y="40"
              width="176"
              height="176"
              rx="8"
              transform="translate(256 0) rotate(90)"
            />
          </g>
          <path d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32Zm0,176H48V48H208Z" />
          <path d="M136.7,148H108a8,8,0,0,0,0,16h48a8,8,0,0,0,8-8V108a8,8,0,0,0-16,0v28.7L105.7,94.3a8.1,8.1,0,0,0-11.4,11.4Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM164,156a8,8,0,0,1-8,8H108a8,8,0,0,1,0-16h28.7L94.3,105.7a8.1,8.1,0,0,1,11.4-11.4L148,136.7V108a8,8,0,0,1,16,0Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M208,34H48A14,14,0,0,0,34,48V208a14,14,0,0,0,14,14H208a14,14,0,0,0,14-14V48A14,14,0,0,0,208,34Zm2,174a2,2,0,0,1-2,2H48a2,2,0,0,1-2-2V48a2,2,0,0,1,2-2H208a2,2,0,0,1,2,2ZM162,108v48a6,6,0,0,1-6,6H108a6,6,0,0,1,0-12h33.5L95.8,104.2a5.9,5.9,0,0,1,8.4-8.4L150,141.5V108a6,6,0,0,1,12,0Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M208,36H48A12,12,0,0,0,36,48V208a12,12,0,0,0,12,12H208a12,12,0,0,0,12-12V48A12,12,0,0,0,208,36Zm4,172a4,4,0,0,1-4,4H48a4,4,0,0,1-4-4V48a4,4,0,0,1,4-4H208a4,4,0,0,1,4,4ZM160,108v48a4,4,0,0,1-4,4H108a4,4,0,0,1,0-8h38.3L97.2,102.8a4,4,0,0,1,5.6-5.6L152,146.3V108a4,4,0,0,1,8,0Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32Zm0,176H48V48H208ZM94.3,105.7a8.1,8.1,0,0,1,11.4-11.4L148,136.7V108a8,8,0,0,1,16,0v48a8,8,0,0,1-8,8H108a8,8,0,0,1,0-16h28.7Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowSquareDown(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M208,28H48A20.1,20.1,0,0,0,28,48V208a20.1,20.1,0,0,0,20,20H208a20.1,20.1,0,0,0,20-20V48A20.1,20.1,0,0,0,208,28Zm-4,176H52V52H204ZM85.6,142.5a12,12,0,0,1,16.9-16.9L116,139V88a12,12,0,0,1,24,0v51l13.5-13.4a12,12,0,0,1,16.9,16.9l-33.9,34a12.1,12.1,0,0,1-17,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect x="40" y="40" width="176" height="176" rx="8" />
          </g>
          <path d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32Zm0,176H48V48H208V208Z" />
          <path d="M156.3,128.4,136,148.7V88a8,8,0,0,0-16,0v60.7L99.7,128.4a8,8,0,0,0-11.3,11.3l33.9,34a8.2,8.2,0,0,0,11.4,0l33.9-34a8,8,0,0,0-11.3-11.3Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM167.6,139.7l-33.9,33.9a8,8,0,0,1-2.7,1.8,7.7,7.7,0,0,1-3,.6,8.5,8.5,0,0,1-3.1-.6,8.7,8.7,0,0,1-2.6-1.8L88.4,139.7a8,8,0,0,1,11.3-11.3L120,148.7V88a8,8,0,0,1,16,0v60.7l20.3-20.3a8,8,0,0,1,11.3,11.3Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M208,34H48A14,14,0,0,0,34,48V208a14,14,0,0,0,14,14H208a14,14,0,0,0,14-14V48A14,14,0,0,0,208,34Zm2,174a2,2,0,0,1-2,2H48a2,2,0,0,1-2-2V48a2,2,0,0,1,2-2H208a2,2,0,0,1,2,2Zm-43.8-78.2a6.1,6.1,0,0,1,0,8.5l-34,33.9a5.8,5.8,0,0,1-8.4,0l-34-33.9a6,6,0,0,1,8.5-8.5L122,153.5V88a6,6,0,0,1,12,0v65.5l23.7-23.7A6.1,6.1,0,0,1,166.2,129.8Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M208,36H48A12,12,0,0,0,36,48V208a12,12,0,0,0,12,12H208a12,12,0,0,0,12-12V48A12,12,0,0,0,208,36Zm4,172a4,4,0,0,1-4,4H48a4,4,0,0,1-4-4V48a4,4,0,0,1,4-4H208a4,4,0,0,1,4,4Zm-47.2-76.8a4.2,4.2,0,0,1,0,5.7l-34,33.9a3.9,3.9,0,0,1-5.6,0l-34-33.9a4,4,0,0,1,5.7-5.7L124,158.3V88a4,4,0,0,1,8,0v70.3l27.1-27.1A4.2,4.2,0,0,1,164.8,131.2Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32Zm0,176H48V48H208V208Zm-40.4-79.6a8,8,0,0,1,0,11.3l-33.9,34a8.2,8.2,0,0,1-11.4,0l-33.9-34a8,8,0,0,1,11.3-11.3L120,148.7V88a8,8,0,0,1,16,0v60.7l20.3-20.3A8,8,0,0,1,167.6,128.4Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowSquareIn(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M124,144v60a12,12,0,0,1-24,0V173L48.5,224.5A12,12,0,0,1,40,228a11.6,11.6,0,0,1-8.5-3.5,12,12,0,0,1,0-17L83,156H52a12,12,0,0,1,0-24h60A12,12,0,0,1,124,144ZM208,28H72A20.1,20.1,0,0,0,52,48V96a12,12,0,0,0,24,0V52H204V180H160a12,12,0,0,0,0,24h48a20.1,20.1,0,0,0,20-20V48A20.1,20.1,0,0,0,208,28Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M216,48V184a8,8,0,0,1-8,8H72a8,8,0,0,1-8-8V48a8,8,0,0,1,8-8H208A8,8,0,0,1,216,48Z"
            opacity="0.2"
          />
          <path d="M120,144v60a8,8,0,0,1-16,0V163.3L45.6,221.7A8,8,0,0,1,40,224a8.3,8.3,0,0,1-5.7-2.3,8,8,0,0,1,0-11.3L92.7,152H52a8,8,0,0,1,0-16h60A8,8,0,0,1,120,144ZM208,32H72A16,16,0,0,0,56,48V96a8,8,0,0,0,16,0V48H208V184H160a8,8,0,0,0,0,16h48a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M120,144v60a8,8,0,0,1-4.9,7.4,8.5,8.5,0,0,1-3.1.6,8.3,8.3,0,0,1-5.7-2.3L82,185.3,45.6,221.7A8,8,0,0,1,40,224a8.3,8.3,0,0,1-5.7-2.3,8,8,0,0,1,0-11.3L70.7,174,46.3,149.7a8.4,8.4,0,0,1-1.7-8.8A8,8,0,0,1,52,136h60A8,8,0,0,1,120,144ZM208,32H72A16,16,0,0,0,56,48V96a8,8,0,0,0,16,0V48H208V184H160a8,8,0,0,0,0,16h48a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M118,144v60a6,6,0,0,1-12,0V158.5L44.2,220.3a6.2,6.2,0,0,1-8.5,0,6.1,6.1,0,0,1,0-8.5L97.5,150H52a6,6,0,0,1,0-12h60A6,6,0,0,1,118,144ZM208,34H72A14,14,0,0,0,58,48V96a6,6,0,0,0,12,0V48a2,2,0,0,1,2-2H208a2,2,0,0,1,2,2V184a2,2,0,0,1-2,2H160a6,6,0,0,0,0,12h48a14,14,0,0,0,14-14V48A14,14,0,0,0,208,34Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M116,144v60a4,4,0,0,1-8,0V153.7L42.8,218.9A4.3,4.3,0,0,1,40,220a4.4,4.4,0,0,1-2.9-1.1,4.2,4.2,0,0,1,0-5.7L102.3,148H52a4,4,0,0,1,0-8h60A4,4,0,0,1,116,144ZM208,36H72A12,12,0,0,0,60,48V96a4,4,0,0,0,8,0V48a4,4,0,0,1,4-4H208a4,4,0,0,1,4,4V184a4,4,0,0,1-4,4H160a4,4,0,0,0,0,8h48a12,12,0,0,0,12-12V48A12,12,0,0,0,208,36Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M120,144v60a8,8,0,0,1-16,0V163.3L45.6,221.7A8,8,0,0,1,40,224a8.3,8.3,0,0,1-5.7-2.3,8,8,0,0,1,0-11.3L92.7,152H52a8,8,0,0,1,0-16h60A8,8,0,0,1,120,144ZM208,32H72A16,16,0,0,0,56,48V96a8,8,0,0,0,16,0V48H208V184H160a8,8,0,0,0,0,16h48a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowSquareLeft(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M208,28H48A20.1,20.1,0,0,0,28,48V208a20.1,20.1,0,0,0,20,20H208a20.1,20.1,0,0,0,20-20V48A20.1,20.1,0,0,0,208,28Zm-4,176H52V52H204ZM79.5,136.5a12,12,0,0,1,0-17l34-33.9a12,12,0,0,1,16.9,16.9L117,116h51a12,12,0,0,1,0,24H117l13.4,13.5a11.9,11.9,0,0,1,0,16.9,12,12,0,0,1-16.9,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect
              x="40"
              y="40"
              width="176"
              height="176"
              rx="8"
              transform="translate(256 0) rotate(90)"
            />
          </g>
          <path d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32Zm0,176H48V48H208Z" />
          <path d="M116.3,167.6a7.6,7.6,0,0,0,5.6,2.3,7.8,7.8,0,0,0,5.7-2.3,8,8,0,0,0,0-11.3L107.3,136H168a8,8,0,0,0,0-16H107.3l20.3-20.3a8,8,0,0,0-11.3-11.3l-34,33.9a8.1,8.1,0,0,0,0,11.4Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M224,208V48a16,16,0,0,0-16-16H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208A16,16,0,0,0,224,208ZM116.3,167.6,82.4,133.7a8,8,0,0,1-1.8-2.7,7.7,7.7,0,0,1-.6-3,8.5,8.5,0,0,1,.6-3.1,8.7,8.7,0,0,1,1.8-2.6l33.9-33.9a8,8,0,0,1,11.3,11.3L107.3,120H168a8,8,0,0,1,0,16H107.3l20.3,20.3a8,8,0,0,1-11.3,11.3Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M208,34H48A14,14,0,0,0,34,48V208a14,14,0,0,0,14,14H208a14,14,0,0,0,14-14V48A14,14,0,0,0,208,34Zm2,174a2,2,0,0,1-2,2H48a2,2,0,0,1-2-2V48a2,2,0,0,1,2-2H208a2,2,0,0,1,2,2Zm-36-80a6,6,0,0,1-6,6H102.5l23.7,23.7a6.1,6.1,0,0,1,0,8.5,5.9,5.9,0,0,1-4.3,1.7,5.8,5.8,0,0,1-4.2-1.7l-33.9-34a5.8,5.8,0,0,1,0-8.4l33.9-34a6,6,0,0,1,8.5,8.5L102.5,122H168A6,6,0,0,1,174,128Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M208,36H48A12,12,0,0,0,36,48V208a12,12,0,0,0,12,12H208a12,12,0,0,0,12-12V48A12,12,0,0,0,208,36Zm4,172a4,4,0,0,1-4,4H48a4,4,0,0,1-4-4V48a4,4,0,0,1,4-4H208a4,4,0,0,1,4,4Zm-40-80a4,4,0,0,1-4,4H97.7l27.1,27.1a4.2,4.2,0,0,1,0,5.7,3.9,3.9,0,0,1-2.9,1.1,3.8,3.8,0,0,1-2.8-1.1l-33.9-34a3.9,3.9,0,0,1,0-5.6l33.9-34a4,4,0,0,1,5.7,5.7L97.7,124H168A4,4,0,0,1,172,128Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32Zm0,176H48V48H208ZM82.3,133.7a8.1,8.1,0,0,1,0-11.4l34-33.9a8,8,0,0,1,11.3,11.3L107.3,120H168a8,8,0,0,1,0,16H107.3l20.3,20.3a8,8,0,0,1,0,11.3,7.8,7.8,0,0,1-5.7,2.3,7.6,7.6,0,0,1-5.6-2.3Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowSquareOut(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M228,100a12,12,0,0,1-24,0V69l-51.5,51.5A12,12,0,0,1,144,124a11.6,11.6,0,0,1-8.5-3.5,12,12,0,0,1,0-17L187,52H156a12,12,0,0,1,0-24h60a12,12,0,0,1,12,12Zm-44,32a12,12,0,0,0-12,12v60H52V84h60a12,12,0,0,0,0-24H48A20.1,20.1,0,0,0,28,80V208a20.1,20.1,0,0,0,20,20H176a20.1,20.1,0,0,0,20-20V144A12,12,0,0,0,184,132Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M184,80V208a8,8,0,0,1-8,8H48a8,8,0,0,1-8-8V80a8,8,0,0,1,8-8H176A8,8,0,0,1,184,80Z"
            opacity="0.2"
          />
          <path d="M224,100a8,8,0,0,1-16,0V59.3l-58.4,58.4A8,8,0,0,1,144,120a8.3,8.3,0,0,1-5.7-2.3,8,8,0,0,1,0-11.3L196.7,48H156a8,8,0,0,1,0-16h60a8,8,0,0,1,8,8Zm-40,36a8,8,0,0,0-8,8v64H48V80h64a8,8,0,0,0,0-16H48A16,16,0,0,0,32,80V208a16,16,0,0,0,16,16H176a16,16,0,0,0,16-16V144A8,8,0,0,0,184,136Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M224,100a8,8,0,0,1-4.9,7.4,8.5,8.5,0,0,1-3.1.6,8.3,8.3,0,0,1-5.7-2.3L186,81.3l-36.4,36.4A8,8,0,0,1,144,120a8.3,8.3,0,0,1-5.7-2.3,8,8,0,0,1,0-11.3L174.7,70,150.3,45.7a8.4,8.4,0,0,1-1.7-8.8A8,8,0,0,1,156,32h60a8,8,0,0,1,8,8Zm-40,36a8,8,0,0,0-8,8v64H48V80h64a8,8,0,0,0,0-16H48A16,16,0,0,0,32,80V208a16,16,0,0,0,16,16H176a16,16,0,0,0,16-16V144A8,8,0,0,0,184,136Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M222,100a6,6,0,0,1-12,0V54.5l-61.8,61.8a6.2,6.2,0,0,1-8.5,0,6.1,6.1,0,0,1,0-8.5L201.5,46H156a6,6,0,0,1,0-12h60a6,6,0,0,1,6,6Zm-38,38a6,6,0,0,0-6,6v64a2,2,0,0,1-2,2H48a2,2,0,0,1-2-2V80a2,2,0,0,1,2-2h64a6,6,0,0,0,0-12H48A14,14,0,0,0,34,80V208a14,14,0,0,0,14,14H176a14,14,0,0,0,14-14V144A6,6,0,0,0,184,138Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M220,100a4,4,0,0,1-8,0V49.7l-65.2,65.2A4.3,4.3,0,0,1,144,116a4.4,4.4,0,0,1-2.9-1.1,4.2,4.2,0,0,1,0-5.7L206.3,44H156a4,4,0,0,1,0-8h60a4,4,0,0,1,4,4Zm-36,40a4,4,0,0,0-4,4v64a4,4,0,0,1-4,4H48a4,4,0,0,1-4-4V80a4,4,0,0,1,4-4h64a4,4,0,0,0,0-8H48A12,12,0,0,0,36,80V208a12,12,0,0,0,12,12H176a12,12,0,0,0,12-12V144A4,4,0,0,0,184,140Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M224,100a8,8,0,0,1-16,0V59.3l-58.4,58.4A8,8,0,0,1,144,120a8.3,8.3,0,0,1-5.7-2.3,8,8,0,0,1,0-11.3L196.7,48H156a8,8,0,0,1,0-16h60a8,8,0,0,1,8,8Zm-40,36a8,8,0,0,0-8,8v64H48V80h64a8,8,0,0,0,0-16H48A16,16,0,0,0,32,80V208a16,16,0,0,0,16,16H176a16,16,0,0,0,16-16V144A8,8,0,0,0,184,136Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowSquareRight(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M208,28H48A20.1,20.1,0,0,0,28,48V208a20.1,20.1,0,0,0,20,20H208a20.1,20.1,0,0,0,20-20V48A20.1,20.1,0,0,0,208,28Zm-4,176H52V52H204ZM76,128a12,12,0,0,1,12-12h51l-13.4-13.5a12,12,0,0,1,16.9-16.9l34,33.9a12,12,0,0,1,0,17l-34,33.9a12,12,0,0,1-16.9,0,11.9,11.9,0,0,1,0-16.9L139,140H88A12,12,0,0,1,76,128Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect
              x="40"
              y="40"
              width="176"
              height="176"
              rx="8"
              transform="translate(256) rotate(90)"
            />
          </g>
          <path d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32Zm0,176H48V48H208Z" />
          <path d="M88,136h60.7l-20.3,20.3a8,8,0,0,0,0,11.3,7.8,7.8,0,0,0,5.7,2.3,7.6,7.6,0,0,0,5.6-2.3l34-33.9a8.1,8.1,0,0,0,0-11.4l-34-33.9a8,8,0,0,0-11.3,11.3L148.7,120H88a8,8,0,0,0,0,16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M48,224H208a16,16,0,0,0,16-16V48a16,16,0,0,0-16-16H48A16,16,0,0,0,32,48V208A16,16,0,0,0,48,224Zm80.4-56.4a8,8,0,0,1,0-11.3L148.7,136H88a8,8,0,0,1,0-16h60.7L128.4,99.7a8,8,0,0,1,11.3-11.3l33.9,33.9a8.7,8.7,0,0,1,1.8,2.6,8.5,8.5,0,0,1,.6,3.1,7.7,7.7,0,0,1-.6,3,8,8,0,0,1-1.8,2.7l-33.9,33.9A8,8,0,0,1,128.4,167.6Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M208,34H48A14,14,0,0,0,34,48V208a14,14,0,0,0,14,14H208a14,14,0,0,0,14-14V48A14,14,0,0,0,208,34Zm2,174a2,2,0,0,1-2,2H48a2,2,0,0,1-2-2V48a2,2,0,0,1,2-2H208a2,2,0,0,1,2,2Zm-37.8-84.2a5.8,5.8,0,0,1,0,8.4l-33.9,34a5.8,5.8,0,0,1-4.2,1.7,5.9,5.9,0,0,1-4.3-1.7,6.1,6.1,0,0,1,0-8.5L153.5,134H88a6,6,0,0,1,0-12h65.5L129.8,98.3a6,6,0,0,1,8.5-8.5Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M208,36H48A12,12,0,0,0,36,48V208a12,12,0,0,0,12,12H208a12,12,0,0,0,12-12V48A12,12,0,0,0,208,36Zm4,172a4,4,0,0,1-4,4H48a4,4,0,0,1-4-4V48a4,4,0,0,1,4-4H208a4,4,0,0,1,4,4Zm-41.2-82.8a3.9,3.9,0,0,1,0,5.6l-33.9,34a3.8,3.8,0,0,1-2.8,1.1,3.9,3.9,0,0,1-2.9-1.1,4.2,4.2,0,0,1,0-5.7L158.3,132H88a4,4,0,0,1,0-8h70.3L131.2,96.9a4,4,0,0,1,5.7-5.7Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32Zm0,176H48V48H208ZM80,128a8,8,0,0,1,8-8h60.7L128.4,99.7a8,8,0,0,1,11.3-11.3l34,33.9a8.1,8.1,0,0,1,0,11.4l-34,33.9a7.6,7.6,0,0,1-5.6,2.3,7.8,7.8,0,0,1-5.7-2.3,8,8,0,0,1,0-11.3L148.7,136H88A8,8,0,0,1,80,128Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowSquareUpLeft(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M208,28H48A20.1,20.1,0,0,0,28,48V208a20.1,20.1,0,0,0,20,20H208a20.1,20.1,0,0,0,20-20V48A20.1,20.1,0,0,0,208,28Zm-4,176H52V52H204ZM88,148V100a12,12,0,0,1,12-12h48a12,12,0,0,1,0,24H129l35.5,35.5a12,12,0,0,1,0,17,12.1,12.1,0,0,1-17,0L112,129v19a12,12,0,0,1-24,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect
              x="40"
              y="40"
              width="176"
              height="176"
              rx="8"
              transform="translate(256 0) rotate(90)"
            />
          </g>
          <path d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32Zm0,176H48V48H208Z" />
          <path d="M100,156a8,8,0,0,0,8-8V119.3l42.3,42.4a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4L119.3,108H148a8,8,0,0,0,0-16H100a8,8,0,0,0-8,8v48A8,8,0,0,0,100,156Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM161.7,161.7a8.2,8.2,0,0,1-11.4,0L108,119.3V148a8,8,0,0,1-16,0V100a8,8,0,0,1,8-8h48a8,8,0,0,1,0,16H119.3l42.4,42.3A8.1,8.1,0,0,1,161.7,161.7Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M208,34H48A14,14,0,0,0,34,48V208a14,14,0,0,0,14,14H208a14,14,0,0,0,14-14V48A14,14,0,0,0,208,34Zm2,174a2,2,0,0,1-2,2H48a2,2,0,0,1-2-2V48a2,2,0,0,1,2-2H208a2,2,0,0,1,2,2Zm-49.8-56.2a5.9,5.9,0,1,1-8.4,8.4L106,114.5V148a6,6,0,0,1-12,0V100a6,6,0,0,1,6-6h48a6,6,0,0,1,0,12H114.5Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M208,36H48A12,12,0,0,0,36,48V208a12,12,0,0,0,12,12H208a12,12,0,0,0,12-12V48A12,12,0,0,0,208,36Zm4,172a4,4,0,0,1-4,4H48a4,4,0,0,1-4-4V48a4,4,0,0,1,4-4H208a4,4,0,0,1,4,4Zm-53.2-54.8a3.9,3.9,0,0,1,0,5.6,3.9,3.9,0,0,1-5.6,0L104,109.7V148a4,4,0,0,1-8,0V100a4,4,0,0,1,4-4h48a4,4,0,0,1,0,8H109.7Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32Zm0,176H48V48H208ZM92,148V100a8,8,0,0,1,8-8h48a8,8,0,0,1,0,16H119.3l42.4,42.3a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0L108,119.3V148a8,8,0,0,1-16,0Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowSquareUpRight(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M208,28H48A20.1,20.1,0,0,0,28,48V208a20.1,20.1,0,0,0,20,20H208a20.1,20.1,0,0,0,20-20V48A20.1,20.1,0,0,0,208,28Zm-4,176H52V52H204ZM91.5,164.5a12,12,0,0,1,0-17L127,112H108a12,12,0,0,1,0-24h48a12,12,0,0,1,12,12v48a12,12,0,0,1-24,0V129l-35.5,35.5a12.1,12.1,0,0,1-17,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect
              x="40"
              y="40"
              width="176"
              height="176"
              rx="8"
              transform="translate(0 256) rotate(-90)"
            />
          </g>
          <path d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32Zm0,176H48V48H208Z" />
          <path d="M100,164a8.5,8.5,0,0,0,5.7-2.3L148,119.3V148a8,8,0,0,0,16,0V100a8,8,0,0,0-8-8H108a8,8,0,0,0,0,16h28.7L94.3,150.3a8.1,8.1,0,0,0,0,11.4A8.5,8.5,0,0,0,100,164Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM164,148a8,8,0,0,1-16,0V119.3l-42.3,42.4a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L136.7,108H108a8,8,0,0,1,0-16h48a8,8,0,0,1,8,8Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M208,34H48A14,14,0,0,0,34,48V208a14,14,0,0,0,14,14H208a14,14,0,0,0,14-14V48A14,14,0,0,0,208,34Zm2,174a2,2,0,0,1-2,2H48a2,2,0,0,1-2-2V48a2,2,0,0,1,2-2H208a2,2,0,0,1,2,2ZM162,100v48a6,6,0,0,1-12,0V114.5l-45.8,45.7a5.9,5.9,0,0,1-8.4-8.4L141.5,106H108a6,6,0,0,1,0-12h48A6,6,0,0,1,162,100Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M208,36H48A12,12,0,0,0,36,48V208a12,12,0,0,0,12,12H208a12,12,0,0,0,12-12V48A12,12,0,0,0,208,36Zm4,172a4,4,0,0,1-4,4H48a4,4,0,0,1-4-4V48a4,4,0,0,1,4-4H208a4,4,0,0,1,4,4ZM160,100v48a4,4,0,0,1-8,0V109.7l-49.2,49.1a3.9,3.9,0,0,1-5.6,0,3.9,3.9,0,0,1,0-5.6L146.3,104H108a4,4,0,0,1,0-8h48A4,4,0,0,1,160,100Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32Zm0,176H48V48H208ZM94.3,161.7a8.1,8.1,0,0,1,0-11.4L136.7,108H108a8,8,0,0,1,0-16h48a8,8,0,0,1,8,8v48a8,8,0,0,1-16,0V119.3l-42.3,42.4a8.2,8.2,0,0,1-11.4,0Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowSquareUp(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M208,28H48A20.1,20.1,0,0,0,28,48V208a20.1,20.1,0,0,0,20,20H208a20.1,20.1,0,0,0,20-20V48A20.1,20.1,0,0,0,208,28Zm-4,176H52V52H204ZM85.6,130.4a11.9,11.9,0,0,1,0-16.9l33.9-34a12.1,12.1,0,0,1,17,0l33.9,34a11.9,11.9,0,0,1,0,16.9,12,12,0,0,1-16.9,0L140,117v51a12,12,0,0,1-24,0V117l-13.5,13.4A11.9,11.9,0,0,1,85.6,130.4Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect x="40" y="40" width="176" height="176" rx="8" />
          </g>
          <path d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32Zm0,176H48V48H208Z" />
          <path d="M99.7,127.6,120,107.3V168a8,8,0,0,0,16,0V107.3l20.3,20.3a7.6,7.6,0,0,0,5.6,2.3,7.8,7.8,0,0,0,5.7-2.3,8,8,0,0,0,0-11.3l-33.9-34a8.2,8.2,0,0,0-11.4,0l-33.9,34a8,8,0,0,0,11.3,11.3Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M224,208V48a16,16,0,0,0-16-16H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208A16,16,0,0,0,224,208Zm-56.4-80.4a8,8,0,0,1-11.3,0L136,107.3V168a8,8,0,0,1-16,0V107.3L99.7,127.6a8,8,0,0,1-11.3-11.3l33.9-33.9a8.7,8.7,0,0,1,2.6-1.8A8.5,8.5,0,0,1,128,80a7.7,7.7,0,0,1,3,.6,8,8,0,0,1,2.7,1.8l33.9,33.9A8,8,0,0,1,167.6,127.6Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M208,34H48A14,14,0,0,0,34,48V208a14,14,0,0,0,14,14H208a14,14,0,0,0,14-14V48A14,14,0,0,0,208,34Zm2,174a2,2,0,0,1-2,2H48a2,2,0,0,1-2-2V48a2,2,0,0,1,2-2H208a2,2,0,0,1,2,2Zm-43.8-90.3a6.1,6.1,0,0,1,0,8.5,5.9,5.9,0,0,1-4.3,1.7,5.8,5.8,0,0,1-4.2-1.7L134,102.5V168a6,6,0,0,1-12,0V102.5L98.3,126.2a6,6,0,0,1-8.5-8.5l34-33.9a5.8,5.8,0,0,1,8.4,0Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M208,36H48A12,12,0,0,0,36,48V208a12,12,0,0,0,12,12H208a12,12,0,0,0,12-12V48A12,12,0,0,0,208,36Zm4,172a4,4,0,0,1-4,4H48a4,4,0,0,1-4-4V48a4,4,0,0,1,4-4H208a4,4,0,0,1,4,4Zm-47.2-88.9a4.2,4.2,0,0,1,0,5.7,3.9,3.9,0,0,1-2.9,1.1,3.8,3.8,0,0,1-2.8-1.1L132,97.7V168a4,4,0,0,1-8,0V97.7L96.9,124.8a4,4,0,0,1-5.7-5.7l34-33.9a3.9,3.9,0,0,1,5.6,0Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32Zm0,176H48V48H208ZM88.4,127.6a8,8,0,0,1,0-11.3l33.9-34a8.2,8.2,0,0,1,11.4,0l33.9,34a8,8,0,0,1,0,11.3,7.8,7.8,0,0,1-5.7,2.3,7.6,7.6,0,0,1-5.6-2.3L136,107.3V168a8,8,0,0,1-16,0V107.3L99.7,127.6A8,8,0,0,1,88.4,127.6Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowUDownLeft(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M236,112a68.1,68.1,0,0,1-68,68H61l27.5,27.5a12,12,0,0,1,0,17,12.1,12.1,0,0,1-17,0l-48-48a12,12,0,0,1,0-17l48-48a12,12,0,0,1,17,17L61,156H168a44,44,0,0,0,0-88H80a12,12,0,0,1,0-24h88A68.1,68.1,0,0,1,236,112Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M232,112a64.1,64.1,0,0,1-64,64H51.3l34.4,34.3a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0l-48-48a8.1,8.1,0,0,1,0-11.4l48-48a8.1,8.1,0,0,1,11.4,11.4L51.3,160H168a48,48,0,0,0,0-96H80a8,8,0,0,1,0-16h88A64.1,64.1,0,0,1,232,112Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M232,112a64.1,64.1,0,0,1-64,64H88v40a8,8,0,0,1-4.9,7.4,8.5,8.5,0,0,1-3.1.6,8.3,8.3,0,0,1-5.7-2.3l-48-48a8.1,8.1,0,0,1,0-11.4l48-48a8.4,8.4,0,0,1,8.8-1.7A8,8,0,0,1,88,120v40h80a48,48,0,0,0,0-96H80a8,8,0,0,1,0-16h88A64.1,64.1,0,0,1,232,112Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M230,112a62.1,62.1,0,0,1-62,62H46.5l37.7,37.8a5.9,5.9,0,1,1-8.4,8.4l-48-48a5.8,5.8,0,0,1,0-8.4l48-48a5.9,5.9,0,0,1,8.4,8.4L46.5,162H168a50,50,0,0,0,0-100H80a6,6,0,0,1,0-12h88A62.1,62.1,0,0,1,230,112Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M228,112a60,60,0,0,1-60,60H41.7l41.1,41.2a3.9,3.9,0,0,1,0,5.6,3.9,3.9,0,0,1-5.6,0l-48-48a3.9,3.9,0,0,1,0-5.6l48-48a4,4,0,0,1,5.6,5.6L41.7,164H168a52,52,0,0,0,0-104H80a4,4,0,0,1,0-8h88A60,60,0,0,1,228,112Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M232,112a64.1,64.1,0,0,1-64,64H51.3l34.4,34.3a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0l-48-48a8.1,8.1,0,0,1,0-11.4l48-48a8.1,8.1,0,0,1,11.4,11.4L51.3,160H168a48,48,0,0,0,0-96H80a8,8,0,0,1,0-16h88A64.1,64.1,0,0,1,232,112Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowUDownRight(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M232.5,176.5l-48,48a12.1,12.1,0,0,1-17,0,12,12,0,0,1,0-17L195,180H88A68,68,0,0,1,88,44h88a12,12,0,0,1,0,24H88a44,44,0,0,0,0,88H195l-27.5-27.5a12,12,0,0,1,17-17l48,48A12,12,0,0,1,232.5,176.5Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M229.7,173.7l-48,48a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L204.7,176H88A64,64,0,0,1,88,48h88a8,8,0,0,1,0,16H88a48,48,0,0,0,0,96H204.7l-34.4-34.3a8.1,8.1,0,0,1,11.4-11.4l48,48A8.1,8.1,0,0,1,229.7,173.7Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M229.7,173.7l-48,48A8.3,8.3,0,0,1,176,224a8.5,8.5,0,0,1-3.1-.6A8,8,0,0,1,168,216V176H88A64,64,0,0,1,88,48h88a8,8,0,0,1,0,16H88a48,48,0,0,0,0,96h80V120a8,8,0,0,1,4.9-7.4,8.4,8.4,0,0,1,8.8,1.7l48,48A8.1,8.1,0,0,1,229.7,173.7Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M228.2,172.2l-48,48a5.9,5.9,0,0,1-8.4-8.4L209.5,174H88A62,62,0,0,1,88,50h88a6,6,0,0,1,0,12H88a50,50,0,0,0,0,100H209.5l-37.7-37.8a5.9,5.9,0,0,1,8.4-8.4l48,48A5.8,5.8,0,0,1,228.2,172.2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M226.8,170.8l-48,48a3.9,3.9,0,0,1-5.6,0,3.9,3.9,0,0,1,0-5.6L214.3,172H88A60,60,0,0,1,88,52h88a4,4,0,0,1,0,8H88a52,52,0,0,0,0,104H214.3l-41.1-41.2a4,4,0,0,1,5.6-5.6l48,48A3.9,3.9,0,0,1,226.8,170.8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M229.7,173.7l-48,48a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L204.7,176H88A64,64,0,0,1,88,48h88a8,8,0,0,1,0,16H88a48,48,0,0,0,0,96H204.7l-34.4-34.3a8.1,8.1,0,0,1,11.4-11.4l48,48A8.1,8.1,0,0,1,229.7,173.7Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowULeftDown(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M212,88v88a12,12,0,0,1-24,0V88a44,44,0,0,0-88,0V195l27.5-27.5a12,12,0,0,1,17,17l-48,48a12.1,12.1,0,0,1-17,0l-48-48a12,12,0,0,1,17-17L76,195V88a68,68,0,0,1,136,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M208,88v88a8,8,0,0,1-16,0V88a48,48,0,0,0-96,0V204.7l34.3-34.4a8.1,8.1,0,0,1,11.4,11.4l-48,48a8.2,8.2,0,0,1-11.4,0l-48-48a8.1,8.1,0,0,1,11.4-11.4L80,204.7V88a64,64,0,0,1,128,0Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M208,88v88a8,8,0,0,1-16,0V88a48,48,0,0,0-96,0v80h40a8,8,0,0,1,7.4,4.9,8.4,8.4,0,0,1-1.7,8.8l-48,48a8.2,8.2,0,0,1-11.4,0l-48-48a8.4,8.4,0,0,1-1.7-8.8A8,8,0,0,1,40,168H80V88a64,64,0,0,1,128,0Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M206,88v88a6,6,0,0,1-12,0V88A50,50,0,0,0,94,88V209.5l37.8-37.7a5.9,5.9,0,0,1,8.4,8.4l-48,48a5.8,5.8,0,0,1-8.4,0l-48-48a5.9,5.9,0,0,1,8.4-8.4L82,209.5V88a62,62,0,0,1,124,0Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M204,88v88a4,4,0,0,1-8,0V88A52,52,0,0,0,92,88V214.3l41.2-41.1a4,4,0,0,1,5.6,5.6l-48,48a3.9,3.9,0,0,1-5.6,0l-48-48a4,4,0,0,1,5.6-5.6L84,214.3V88a60,60,0,0,1,120,0Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M208,88v88a8,8,0,0,1-16,0V88a48,48,0,0,0-96,0V204.7l34.3-34.4a8.1,8.1,0,0,1,11.4,11.4l-48,48a8.2,8.2,0,0,1-11.4,0l-48-48a8.1,8.1,0,0,1,11.4-11.4L80,204.7V88a64,64,0,0,1,128,0Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowULeftUp(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M212,80v88a68,68,0,0,1-136,0V61L48.5,88.5a12,12,0,0,1-17-17l48-48a12,12,0,0,1,17,0l48,48a12,12,0,0,1,0,17,12.1,12.1,0,0,1-17,0L100,61V168a44,44,0,0,0,88,0V80a12,12,0,0,1,24,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M208,80v88a64,64,0,0,1-128,0V51.3L45.7,85.7A8.1,8.1,0,0,1,34.3,74.3l48-48a8.1,8.1,0,0,1,11.4,0l48,48a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0L96,51.3V168a48,48,0,0,0,96,0V80a8,8,0,0,1,16,0Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M208,80v88a64,64,0,0,1-128,0V88H40a8,8,0,0,1-7.4-4.9,8.4,8.4,0,0,1,1.7-8.8l48-48a8.1,8.1,0,0,1,11.4,0l48,48a8.4,8.4,0,0,1,1.7,8.8A8,8,0,0,1,136,88H96v80a48,48,0,0,0,96,0V80a8,8,0,0,1,16,0Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M206,80v88a62,62,0,0,1-124,0V46.5L44.2,84.2a5.9,5.9,0,0,1-8.4-8.4l48-48a5.8,5.8,0,0,1,8.4,0l48,48a5.9,5.9,0,1,1-8.4,8.4L94,46.5V168a50,50,0,0,0,100,0V80a6,6,0,0,1,12,0Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M204,80v88a60,60,0,0,1-120,0V41.7L42.8,82.8a4,4,0,0,1-5.6-5.6l48-48a3.9,3.9,0,0,1,5.6,0l48,48a3.9,3.9,0,0,1,0,5.6,3.9,3.9,0,0,1-5.6,0L92,41.7V168a52,52,0,0,0,104,0V80a4,4,0,0,1,8,0Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M208,80v88a64,64,0,0,1-128,0V51.3L45.7,85.7A8.1,8.1,0,0,1,34.3,74.3l48-48a8.1,8.1,0,0,1,11.4,0l48,48a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0L96,51.3V168a48,48,0,0,0,96,0V80a8,8,0,0,1,16,0Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowURightDown(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M224.5,184.5l-48,48a12.1,12.1,0,0,1-17,0l-48-48a12,12,0,0,1,17-17L156,195V88a44,44,0,0,0-88,0v88a12,12,0,0,1-24,0V88a68,68,0,0,1,136,0V195l27.5-27.5a12,12,0,0,1,17,17Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M221.7,181.7l-48,48a8.2,8.2,0,0,1-11.4,0l-48-48a8.1,8.1,0,0,1,11.4-11.4L160,204.7V88a48,48,0,0,0-96,0v88a8,8,0,0,1-16,0V88a64,64,0,0,1,128,0V204.7l34.3-34.4a8.1,8.1,0,0,1,11.4,11.4Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M221.7,181.7l-48,48a8.2,8.2,0,0,1-11.4,0l-48-48a8.4,8.4,0,0,1-1.7-8.8A8,8,0,0,1,120,168h40V88a48,48,0,0,0-96,0v88a8,8,0,0,1-16,0V88a64,64,0,0,1,128,0v80h40a8,8,0,0,1,7.4,4.9A8.4,8.4,0,0,1,221.7,181.7Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M220.2,180.2l-48,48a5.8,5.8,0,0,1-8.4,0l-48-48a5.9,5.9,0,0,1,8.4-8.4L162,209.5V88A50,50,0,0,0,62,88v88a6,6,0,0,1-12,0V88a62,62,0,0,1,124,0V209.5l37.8-37.7a5.9,5.9,0,0,1,8.4,8.4Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M218.8,178.8l-48,48a3.9,3.9,0,0,1-5.6,0l-48-48a4,4,0,0,1,5.6-5.6L164,214.3V88A52,52,0,0,0,60,88v88a4,4,0,0,1-8,0V88a60,60,0,0,1,120,0V214.3l41.2-41.1a4,4,0,0,1,5.6,5.6Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M221.7,181.7l-48,48a8.2,8.2,0,0,1-11.4,0l-48-48a8.1,8.1,0,0,1,11.4-11.4L160,204.7V88a48,48,0,0,0-96,0v88a8,8,0,0,1-16,0V88a64,64,0,0,1,128,0V204.7l34.3-34.4a8.1,8.1,0,0,1,11.4,11.4Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowURightUp(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M224.5,88.5a12.1,12.1,0,0,1-17,0L180,61V168a68,68,0,0,1-136,0V80a12,12,0,0,1,24,0v88a44,44,0,0,0,88,0V61L128.5,88.5a12,12,0,0,1-17-17l48-48a12,12,0,0,1,17,0l48,48A12,12,0,0,1,224.5,88.5Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M221.7,85.7a8.2,8.2,0,0,1-11.4,0L176,51.3V168a64,64,0,0,1-128,0V80a8,8,0,0,1,16,0v88a48,48,0,0,0,96,0V51.3L125.7,85.7a8.1,8.1,0,0,1-11.4-11.4l48-48a8.1,8.1,0,0,1,11.4,0l48,48A8.1,8.1,0,0,1,221.7,85.7Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M223.4,83.1A8,8,0,0,1,216,88H176v80a64,64,0,0,1-128,0V80a8,8,0,0,1,16,0v88a48,48,0,0,0,96,0V88H120a8,8,0,0,1-7.4-4.9,8.4,8.4,0,0,1,1.7-8.8l48-48a8.1,8.1,0,0,1,11.4,0l48,48A8.4,8.4,0,0,1,223.4,83.1Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M220.2,84.2a5.8,5.8,0,0,1-8.4,0L174,46.5V168a62,62,0,0,1-124,0V80a6,6,0,0,1,12,0v88a50,50,0,0,0,100,0V46.5L124.2,84.2a5.9,5.9,0,0,1-8.4-8.4l48-48a5.8,5.8,0,0,1,8.4,0l48,48A5.8,5.8,0,0,1,220.2,84.2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M218.8,82.8a3.9,3.9,0,0,1-5.6,0L172,41.7V168a60,60,0,0,1-120,0V80a4,4,0,0,1,8,0v88a52,52,0,0,0,104,0V41.7L122.8,82.8a4,4,0,0,1-5.6-5.6l48-48a3.9,3.9,0,0,1,5.6,0l48,48A3.9,3.9,0,0,1,218.8,82.8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M221.7,85.7a8.2,8.2,0,0,1-11.4,0L176,51.3V168a64,64,0,0,1-128,0V80a8,8,0,0,1,16,0v88a48,48,0,0,0,96,0V51.3L125.7,85.7a8.1,8.1,0,0,1-11.4-11.4l48-48a8.1,8.1,0,0,1,11.4,0l48,48A8.1,8.1,0,0,1,221.7,85.7Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowUUpLeft(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M236,144a68.1,68.1,0,0,1-68,68H80a12,12,0,0,1,0-24h88a44,44,0,0,0,0-88H61l27.5,27.5a12,12,0,0,1,0,17,12.1,12.1,0,0,1-17,0l-48-48a12,12,0,0,1,0-17l48-48a12,12,0,0,1,17,17L61,76H168A68.1,68.1,0,0,1,236,144Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M232,144a64.1,64.1,0,0,1-64,64H80a8,8,0,0,1,0-16h88a48,48,0,0,0,0-96H51.3l34.4,34.3a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0l-48-48a8.1,8.1,0,0,1,0-11.4l48-48A8.1,8.1,0,0,1,85.7,45.7L51.3,80H168A64.1,64.1,0,0,1,232,144Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M232,144a64.1,64.1,0,0,1-64,64H80a8,8,0,0,1,0-16h88a48,48,0,0,0,0-96H88v40a8,8,0,0,1-4.9,7.4,8.5,8.5,0,0,1-3.1.6,8.3,8.3,0,0,1-5.7-2.3l-48-48a8.1,8.1,0,0,1,0-11.4l48-48a8.4,8.4,0,0,1,8.8-1.7A8,8,0,0,1,88,40V80h80A64.1,64.1,0,0,1,232,144Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M230,144a62.1,62.1,0,0,1-62,62H80a6,6,0,0,1,0-12h88a50,50,0,0,0,0-100H46.5l37.7,37.8a5.9,5.9,0,1,1-8.4,8.4l-48-48a5.8,5.8,0,0,1,0-8.4l48-48a5.9,5.9,0,0,1,8.4,8.4L46.5,82H168A62.1,62.1,0,0,1,230,144Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M228,144a60,60,0,0,1-60,60H80a4,4,0,0,1,0-8h88a52,52,0,0,0,0-104H41.7l41.1,41.2a3.9,3.9,0,0,1,0,5.6,3.9,3.9,0,0,1-5.6,0l-48-48a3.9,3.9,0,0,1,0-5.6l48-48a4,4,0,0,1,5.6,5.6L41.7,84H168A60,60,0,0,1,228,144Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M232,144a64.1,64.1,0,0,1-64,64H80a8,8,0,0,1,0-16h88a48,48,0,0,0,0-96H51.3l34.4,34.3a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0l-48-48a8.1,8.1,0,0,1,0-11.4l48-48A8.1,8.1,0,0,1,85.7,45.7L51.3,80H168A64.1,64.1,0,0,1,232,144Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowUUpRight(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M167.5,127.5,195,100H88a44,44,0,0,0,0,88h88a12,12,0,0,1,0,24H88A68,68,0,0,1,88,76H195L167.5,48.5a12,12,0,0,1,17-17l48,48a12,12,0,0,1,0,17l-48,48a12.1,12.1,0,0,1-17,0A12,12,0,0,1,167.5,127.5Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M170.3,130.3,204.7,96H88a48,48,0,0,0,0,96h88a8,8,0,0,1,0,16H88A64,64,0,0,1,88,80H204.7L170.3,45.7a8.1,8.1,0,0,1,11.4-11.4l48,48a8.1,8.1,0,0,1,0,11.4l-48,48a8.2,8.2,0,0,1-11.4,0A8.1,8.1,0,0,1,170.3,130.3Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M168,136V96H88a48,48,0,0,0,0,96h88a8,8,0,0,1,0,16H88A64,64,0,0,1,88,80h80V40a8,8,0,0,1,4.9-7.4,8.4,8.4,0,0,1,8.8,1.7l48,48a8.1,8.1,0,0,1,0,11.4l-48,48A8.3,8.3,0,0,1,176,144a8.5,8.5,0,0,1-3.1-.6A8,8,0,0,1,168,136Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M171.8,131.8,209.5,94H88a50,50,0,0,0,0,100h88a6,6,0,0,1,0,12H88A62,62,0,0,1,88,82H209.5L171.8,44.2a5.9,5.9,0,0,1,8.4-8.4l48,48a5.8,5.8,0,0,1,0,8.4l-48,48a5.9,5.9,0,0,1-8.4-8.4Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M173.2,133.2,214.3,92H88a52,52,0,0,0,0,104h88a4,4,0,0,1,0,8H88A60,60,0,0,1,88,84H214.3L173.2,42.8a4,4,0,0,1,5.6-5.6l48,48a3.9,3.9,0,0,1,0,5.6l-48,48a3.9,3.9,0,0,1-5.6,0A3.9,3.9,0,0,1,173.2,133.2Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M170.3,130.3,204.7,96H88a48,48,0,0,0,0,96h88a8,8,0,0,1,0,16H88A64,64,0,0,1,88,80H204.7L170.3,45.7a8.1,8.1,0,0,1,11.4-11.4l48,48a8.1,8.1,0,0,1,0,11.4l-48,48a8.2,8.2,0,0,1-11.4,0A8.1,8.1,0,0,1,170.3,130.3Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowUpLeft(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M200.5,200.5a12.1,12.1,0,0,1-17,0L76,93v75a12,12,0,0,1-24,0V64A12,12,0,0,1,64,52H168a12,12,0,0,1,0,24H93L200.5,183.5A12,12,0,0,1,200.5,200.5Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M197.7,197.7a8.2,8.2,0,0,1-11.4,0L72,83.3V168a8,8,0,0,1-16,0V64a8,8,0,0,1,8-8H168a8,8,0,0,1,0,16H83.3L197.7,186.3A8.1,8.1,0,0,1,197.7,197.7Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M197.7,197.7a8.2,8.2,0,0,1-11.4,0L116,127.3,69.7,173.7A8.3,8.3,0,0,1,64,176a8.5,8.5,0,0,1-3.1-.6A8,8,0,0,1,56,168V64a8,8,0,0,1,8-8H168a8,8,0,0,1,7.4,4.9,8.4,8.4,0,0,1-1.7,8.8L127.3,116l70.4,70.3A8.1,8.1,0,0,1,197.7,197.7Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M196.2,196.2a5.8,5.8,0,0,1-8.4,0L70,78.5V168a6,6,0,0,1-12,0V64a6,6,0,0,1,6-6H168a6,6,0,0,1,0,12H78.5L196.2,187.8A5.8,5.8,0,0,1,196.2,196.2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M194.8,194.8a3.9,3.9,0,0,1-5.6,0L68,73.7V168a4,4,0,0,1-8,0V64a4,4,0,0,1,4-4H168a4,4,0,0,1,0,8H73.7L194.8,189.2A3.9,3.9,0,0,1,194.8,194.8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M197.7,197.7a8.2,8.2,0,0,1-11.4,0L72,83.3V168a8,8,0,0,1-16,0V64a8,8,0,0,1,8-8H168a8,8,0,0,1,0,16H83.3L197.7,186.3A8.1,8.1,0,0,1,197.7,197.7Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowUpRight(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M204,64V168a12,12,0,0,1-24,0V93L72.5,200.5a12.1,12.1,0,0,1-17,0,12,12,0,0,1,0-17L163,76H88a12,12,0,0,1,0-24H192A12,12,0,0,1,204,64Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M200,64V168a8,8,0,0,1-16,0V83.3L69.7,197.7a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L172.7,72H88a8,8,0,0,1,0-16H192A8,8,0,0,1,200,64Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M200,64V168a8,8,0,0,1-4.9,7.4,8.5,8.5,0,0,1-3.1.6,8.3,8.3,0,0,1-5.7-2.3L140,127.3,69.7,197.7a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L128.7,116,82.3,69.7a8.4,8.4,0,0,1-1.7-8.8A8,8,0,0,1,88,56H192A8,8,0,0,1,200,64Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M198,64V168a6,6,0,0,1-12,0V78.5L68.2,196.2a5.9,5.9,0,0,1-8.4-8.4L177.5,70H88a6,6,0,0,1,0-12H192A6,6,0,0,1,198,64Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M196,64V168a4,4,0,0,1-8,0V73.7L66.8,194.8a3.9,3.9,0,0,1-5.6,0,3.9,3.9,0,0,1,0-5.6L182.3,68H88a4,4,0,0,1,0-8H192A4,4,0,0,1,196,64Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M200,64V168a8,8,0,0,1-16,0V83.3L69.7,197.7a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L172.7,72H88a8,8,0,0,1,0-16H192A8,8,0,0,1,200,64Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowUp(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M208.5,120.5a12.1,12.1,0,0,1-17,0L140,69V216a12,12,0,0,1-24,0V69L64.5,120.5a12,12,0,0,1-17-17l72-72a12,12,0,0,1,17,0l72,72A12,12,0,0,1,208.5,120.5Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M205.7,117.7a8.2,8.2,0,0,1-11.4,0L136,59.3V216a8,8,0,0,1-16,0V59.3L61.7,117.7a8.1,8.1,0,0,1-11.4-11.4l72-72a8.1,8.1,0,0,1,11.4,0l72,72A8.1,8.1,0,0,1,205.7,117.7Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M207.4,115.1A8,8,0,0,1,200,120H136v96a8,8,0,0,1-16,0V120H56a8,8,0,0,1-7.4-4.9,8.4,8.4,0,0,1,1.7-8.8l72-72a8.1,8.1,0,0,1,11.4,0l72,72A8.4,8.4,0,0,1,207.4,115.1Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M204.2,116.2a5.8,5.8,0,0,1-8.4,0L134,54.5V216a6,6,0,0,1-12,0V54.5L60.2,116.2a5.9,5.9,0,0,1-8.4-8.4l72-72a5.8,5.8,0,0,1,8.4,0l72,72A5.8,5.8,0,0,1,204.2,116.2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M202.8,114.8a3.9,3.9,0,0,1-5.6,0L132,49.7V216a4,4,0,0,1-8,0V49.7L58.8,114.8a4,4,0,0,1-5.6-5.6l72-72a3.9,3.9,0,0,1,5.6,0l72,72A3.9,3.9,0,0,1,202.8,114.8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M205.7,117.7a8.2,8.2,0,0,1-11.4,0L136,59.3V216a8,8,0,0,1-16,0V59.3L61.7,117.7a8.1,8.1,0,0,1-11.4-11.4l72-72a8.1,8.1,0,0,1,11.4,0l72,72A8.1,8.1,0,0,1,205.7,117.7Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowsClockwise(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M236.2,51.7v48a12,12,0,0,1-12,12h-48a12,12,0,0,1,0-24h19L181.7,74.3a75.9,75.9,0,0,0-107.4,0,12.2,12.2,0,0,1-17,0,12,12,0,0,1,0-17,100.1,100.1,0,0,1,141.4,0l13.5,13.4v-19a12,12,0,1,1,24,0Zm-54.5,130a75.9,75.9,0,0,1-107.4,0L60.8,168.3h19a12,12,0,1,0,0-24h-48a12,12,0,0,0-12,12v48a12,12,0,0,0,24,0v-19l13.5,13.4a99.9,99.9,0,0,0,141.4,0,12,12,0,0,0,0-17A12.2,12.2,0,0,0,181.7,181.7Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M232.2,51.7v48a8,8,0,0,1-8,8h-48a8,8,0,0,1,0-16h28.7L184.6,71.4a80.2,80.2,0,0,0-113.2,0,7.9,7.9,0,0,1-11.3,0,8,8,0,0,1,0-11.3,96.2,96.2,0,0,1,135.8,0l20.3,20.3V51.7a8,8,0,1,1,16,0ZM184.6,184.6a80.2,80.2,0,0,1-113.2,0L51.1,164.3H79.8a8,8,0,1,0,0-16h-48a8,8,0,0,0-8,8v48a8,8,0,0,0,16,0V175.6l20.3,20.3a96.1,96.1,0,0,0,135.8,0,8,8,0,0,0,0-11.3A7.9,7.9,0,0,0,184.6,184.6Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M232.2,51.7v48a8,8,0,0,1-8,8h-48a8,8,0,0,1-5.7-13.6l18.4-18.4-4.3-4.3a80.2,80.2,0,0,0-113.2,0,7.9,7.9,0,0,1-11.3,0,8,8,0,0,1,0-11.3,96.2,96.2,0,0,1,135.8,0l4.3,4.3,18.3-18.3a8,8,0,0,1,8.7-1.8A8.2,8.2,0,0,1,232.2,51.7ZM184.6,184.6a80.2,80.2,0,0,1-113.2,0l-4.3-4.3,18.4-18.4a8,8,0,0,0-5.7-13.6h-48a8,8,0,0,0-8,8v48a8.2,8.2,0,0,0,5,7.4,8,8,0,0,0,3,.6,7.8,7.8,0,0,0,5.7-2.4l18.3-18.3,4.3,4.3a96.1,96.1,0,0,0,135.8,0,8,8,0,0,0,0-11.3A7.9,7.9,0,0,0,184.6,184.6Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M230.2,51.7v48a6,6,0,0,1-6,6h-48a6,6,0,0,1,0-12h33.5L186,70A82.1,82.1,0,0,0,70,70a5.9,5.9,0,0,1-8.5,0,6.1,6.1,0,0,1,0-8.5,94.3,94.3,0,0,1,133,0l23.7,23.7V51.7a6,6,0,1,1,12,0ZM186,186a82.1,82.1,0,0,1-116,0L46.3,162.3H79.8a6,6,0,1,0,0-12h-48a6,6,0,0,0-6,6v48a6,6,0,0,0,12,0V170.8l23.7,23.7a94.2,94.2,0,0,0,133,0,6.1,6.1,0,0,0,0-8.5A5.9,5.9,0,0,0,186,186Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M228.2,51.7v48a4,4,0,0,1-4,4h-48a4,4,0,0,1,0-8h38.3L187.4,68.6a84.2,84.2,0,0,0-118.8,0,4,4,0,0,1-5.7,0,4.2,4.2,0,0,1,0-5.7,92.2,92.2,0,0,1,130.2,0l27.1,27.2V51.7a4,4,0,1,1,8,0ZM187.4,187.4a84.2,84.2,0,0,1-118.8,0L41.5,160.3H79.8a4,4,0,1,0,0-8h-48a4,4,0,0,0-4,4v48a4,4,0,1,0,8,0V165.9l27.1,27.2a92.2,92.2,0,0,0,130.2,0,4.2,4.2,0,0,0,0-5.7A4,4,0,0,0,187.4,187.4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M232.2,51.7v48a8,8,0,0,1-8,8h-48a8,8,0,0,1,0-16h28.7L184.6,71.4a80.2,80.2,0,0,0-113.2,0,7.9,7.9,0,0,1-11.3,0,8,8,0,0,1,0-11.3,96.2,96.2,0,0,1,135.8,0l20.3,20.3V51.7a8,8,0,1,1,16,0ZM184.6,184.6a80.2,80.2,0,0,1-113.2,0L51.1,164.3H79.8a8,8,0,1,0,0-16h-48a8,8,0,0,0-8,8v48a8,8,0,0,0,16,0V175.6l20.3,20.3a96.1,96.1,0,0,0,135.8,0,8,8,0,0,0,0-11.3A7.9,7.9,0,0,0,184.6,184.6Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowsCounterClockwise(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M79.8,111.7h-48a12,12,0,0,1-12-12v-48a12,12,0,1,1,24,0v19L57.3,57.3a100.1,100.1,0,0,1,141.4,0,12,12,0,0,1,0,17,12.2,12.2,0,0,1-17,0,75.9,75.9,0,0,0-107.4,0L60.8,87.7h19a12,12,0,0,1,0,24Zm144.4,32.6h-48a12,12,0,0,0,0,24h19l-13.5,13.4a75.9,75.9,0,0,1-107.4,0,12.2,12.2,0,0,0-17,0,12,12,0,0,0,0,17,99.9,99.9,0,0,0,141.4,0l13.5-13.4v19a12,12,0,0,0,24,0v-48A12,12,0,0,0,224.2,144.3Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M79.8,107.7h-48a8,8,0,0,1-8-8v-48a8,8,0,1,1,16,0V80.4L60.1,60.1a96.2,96.2,0,0,1,135.8,0,8,8,0,0,1,0,11.3,7.9,7.9,0,0,1-11.3,0,80.2,80.2,0,0,0-113.2,0L51.1,91.7H79.8a8,8,0,0,1,0,16Zm144.4,40.6h-48a8,8,0,0,0,0,16h28.7l-20.3,20.3a80.2,80.2,0,0,1-113.2,0,7.9,7.9,0,0,0-11.3,0,8,8,0,0,0,0,11.3,96.1,96.1,0,0,0,135.8,0l20.3-20.3v28.7a8,8,0,0,0,16,0v-48A8,8,0,0,0,224.2,148.3Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M79.8,107.7h-48a8,8,0,0,1-8-8v-48a8.2,8.2,0,0,1,5-7.4,8,8,0,0,1,8.7,1.8L55.8,64.4l4.3-4.3a96.2,96.2,0,0,1,135.8,0,8,8,0,0,1,0,11.3,7.9,7.9,0,0,1-11.3,0,80.2,80.2,0,0,0-113.2,0l-4.3,4.3L85.5,94.1a8,8,0,0,1-5.7,13.6Zm144.4,40.6h-48a8,8,0,0,0-5.7,13.6l18.4,18.4-4.3,4.3a80.2,80.2,0,0,1-113.2,0,7.9,7.9,0,0,0-11.3,0,8,8,0,0,0,0,11.3,96.1,96.1,0,0,0,135.8,0l4.3-4.3,18.3,18.3a7.8,7.8,0,0,0,5.7,2.4,8,8,0,0,0,3-.6,8.2,8.2,0,0,0,5-7.4v-48A8,8,0,0,0,224.2,148.3Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M79.8,105.7h-48a6,6,0,0,1-6-6v-48a6,6,0,1,1,12,0V85.2L61.5,61.5a94.3,94.3,0,0,1,133,0,6.1,6.1,0,0,1,0,8.5,5.9,5.9,0,0,1-8.5,0A82.1,82.1,0,0,0,70,70L46.3,93.7H79.8a6,6,0,0,1,0,12Zm144.4,44.6h-48a6,6,0,0,0,0,12h33.5L186,186a82.1,82.1,0,0,1-116,0,5.9,5.9,0,0,0-8.5,0,6.1,6.1,0,0,0,0,8.5,94.2,94.2,0,0,0,133,0l23.7-23.7v33.5a6,6,0,0,0,12,0v-48A6,6,0,0,0,224.2,150.3Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M79.8,103.7h-48a4,4,0,0,1-4-4v-48a4,4,0,1,1,8,0V90.1L62.9,62.9a92.3,92.3,0,0,1,130.2,0,4.2,4.2,0,0,1,0,5.7,4,4,0,0,1-5.7,0,84.2,84.2,0,0,0-118.8,0L41.5,95.7H79.8a4,4,0,0,1,0,8Zm144.4,48.6h-48a4,4,0,0,0,0,8h38.3l-27.1,27.1a84.2,84.2,0,0,1-118.8,0,4,4,0,0,0-5.7,0,4.2,4.2,0,0,0,0,5.7,92.2,92.2,0,0,0,130.2,0l27.1-27.2v38.4a4,4,0,0,0,8,0v-48A4,4,0,0,0,224.2,152.3Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M79.8,107.7h-48a8,8,0,0,1-8-8v-48a8,8,0,1,1,16,0V80.4L60.1,60.1a96.2,96.2,0,0,1,135.8,0,8,8,0,0,1,0,11.3,7.9,7.9,0,0,1-11.3,0,80.2,80.2,0,0,0-113.2,0L51.1,91.7H79.8a8,8,0,0,1,0,16Zm144.4,40.6h-48a8,8,0,0,0,0,16h28.7l-20.3,20.3a80.2,80.2,0,0,1-113.2,0,7.9,7.9,0,0,0-11.3,0,8,8,0,0,0,0,11.3,96.1,96.1,0,0,0,135.8,0l20.3-20.3v28.7a8,8,0,0,0,16,0v-48A8,8,0,0,0,224.2,148.3Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowsDownUp(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M120.5,167.5a12,12,0,0,1,0,17l-32,32a12.1,12.1,0,0,1-17,0l-32-32a12,12,0,0,1,17-17L68,179V48a12,12,0,0,1,24,0V179l11.5-11.5A12,12,0,0,1,120.5,167.5Zm96-96-32-32a12,12,0,0,0-17,0l-32,32a12,12,0,0,0,17,17L164,77V208a12,12,0,0,0,24,0V77l11.5,11.5a12.1,12.1,0,0,0,17,0A12,12,0,0,0,216.5,71.5Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M117.7,170.3a8.1,8.1,0,0,1,0,11.4l-32,32a8.2,8.2,0,0,1-11.4,0l-32-32a8.1,8.1,0,0,1,11.4-11.4L72,188.7V48a8,8,0,0,1,16,0V188.7l18.3-18.4A8.1,8.1,0,0,1,117.7,170.3Zm96-96-32-32a8.1,8.1,0,0,0-11.4,0l-32,32a8.1,8.1,0,0,0,11.4,11.4L168,67.3V208a8,8,0,0,0,16,0V67.3l18.3,18.4a8.2,8.2,0,0,0,11.4,0A8.1,8.1,0,0,0,213.7,74.3Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M119.4,172.9a8.4,8.4,0,0,1-1.7,8.8l-32,32a8.2,8.2,0,0,1-11.4,0l-32-32a8.4,8.4,0,0,1-1.7-8.8A8,8,0,0,1,48,168H72V48a8,8,0,0,1,16,0V168h24A8,8,0,0,1,119.4,172.9Zm94.3-98.6-32-32a8.1,8.1,0,0,0-11.4,0l-32,32a8.4,8.4,0,0,0-1.7,8.8A8,8,0,0,0,144,88h24V208a8,8,0,0,0,16,0V88h24a8,8,0,0,0,7.4-4.9A8.4,8.4,0,0,0,213.7,74.3Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M116.2,171.8a5.8,5.8,0,0,1,0,8.4l-32,32a5.8,5.8,0,0,1-8.4,0l-32-32a5.9,5.9,0,0,1,8.4-8.4L74,193.5V48a6,6,0,0,1,12,0V193.5l21.8-21.7A5.8,5.8,0,0,1,116.2,171.8Zm96-96-32-32a5.8,5.8,0,0,0-8.4,0l-32,32a5.9,5.9,0,0,0,8.4,8.4L170,62.5V208a6,6,0,0,0,12,0V62.5l21.8,21.7a5.9,5.9,0,1,0,8.4-8.4Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M114.8,173.2a3.9,3.9,0,0,1,0,5.6l-32,32a3.9,3.9,0,0,1-5.6,0l-32-32a4,4,0,0,1,5.6-5.6L76,198.3V48a4,4,0,0,1,8,0V198.3l25.2-25.1A3.9,3.9,0,0,1,114.8,173.2Zm96-96-32-32a3.9,3.9,0,0,0-5.6,0l-32,32a4,4,0,0,0,5.6,5.6L172,57.7V208a4,4,0,0,0,8,0V57.7l25.2,25.1a3.9,3.9,0,0,0,5.6,0A3.9,3.9,0,0,0,210.8,77.2Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M117.7,170.3a8.1,8.1,0,0,1,0,11.4l-32,32a8.2,8.2,0,0,1-11.4,0l-32-32a8.1,8.1,0,0,1,11.4-11.4L72,188.7V48a8,8,0,0,1,16,0V188.7l18.3-18.4A8.1,8.1,0,0,1,117.7,170.3Zm96-96-32-32a8.1,8.1,0,0,0-11.4,0l-32,32a8.1,8.1,0,0,0,11.4,11.4L168,67.3V208a8,8,0,0,0,16,0V67.3l18.3,18.4a8.2,8.2,0,0,0,11.4,0A8.1,8.1,0,0,0,213.7,74.3Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowsHorizontal(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M236.5,136.5l-32,32a12.1,12.1,0,0,1-17,0,12,12,0,0,1,0-17L199,140H57l11.5,11.5a12,12,0,0,1,0,17,12.1,12.1,0,0,1-17,0l-32-32a12,12,0,0,1,0-17l32-32a12,12,0,0,1,17,17L57,116H199l-11.5-11.5a12,12,0,0,1,17-17l32,32A12,12,0,0,1,236.5,136.5Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M237.7,133.7l-32,32a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L212.7,136H43.3l18.4,18.3a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0l-32-32a8.1,8.1,0,0,1,0-11.4l32-32a8.1,8.1,0,0,1,11.4,11.4L43.3,120H212.7l-18.4-18.3a8.1,8.1,0,0,1,11.4-11.4l32,32A8.1,8.1,0,0,1,237.7,133.7Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M237.7,133.7l-32,32A8.3,8.3,0,0,1,200,168a8.5,8.5,0,0,1-3.1-.6A8,8,0,0,1,192,160V136H64v24a8,8,0,0,1-4.9,7.4,8.5,8.5,0,0,1-3.1.6,8.3,8.3,0,0,1-5.7-2.3l-32-32a8.1,8.1,0,0,1,0-11.4l32-32a8.4,8.4,0,0,1,8.8-1.7A8,8,0,0,1,64,96v24H192V96a8,8,0,0,1,4.9-7.4,8.4,8.4,0,0,1,8.8,1.7l32,32A8.1,8.1,0,0,1,237.7,133.7Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M236.2,132.2l-32,32a5.9,5.9,0,0,1-8.4-8.4L217.5,134H38.5l21.7,21.8a5.9,5.9,0,1,1-8.4,8.4l-32-32a5.8,5.8,0,0,1,0-8.4l32-32a5.9,5.9,0,0,1,8.4,8.4L38.5,122h179l-21.7-21.8a5.9,5.9,0,0,1,8.4-8.4l32,32A5.8,5.8,0,0,1,236.2,132.2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M234.8,130.8l-32,32a3.9,3.9,0,0,1-5.6,0,3.9,3.9,0,0,1,0-5.6L222.3,132H33.7l25.1,25.2a3.9,3.9,0,0,1,0,5.6,3.9,3.9,0,0,1-5.6,0l-32-32a3.9,3.9,0,0,1,0-5.6l32-32a4,4,0,0,1,5.6,5.6L33.7,124H222.3L197.2,98.8a4,4,0,0,1,5.6-5.6l32,32A3.9,3.9,0,0,1,234.8,130.8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M237.7,133.7l-32,32a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L212.7,136H43.3l18.4,18.3a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0l-32-32a8.1,8.1,0,0,1,0-11.4l32-32a8.1,8.1,0,0,1,11.4,11.4L43.3,120H212.7l-18.4-18.3a8.1,8.1,0,0,1,11.4-11.4l32,32A8.1,8.1,0,0,1,237.7,133.7Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowsInCardinal(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M164.8,179.8a12,12,0,0,1,0,17,12.2,12.2,0,0,1-8.5,3.5,12,12,0,0,1-8.5-3.5L140,189v43a12,12,0,0,1-24,0V189l-7.8,7.8a12,12,0,0,1-17-17l28.3-28.3a12,12,0,0,1,17,0Zm-45.3-75.3a12.1,12.1,0,0,0,17,0l28.3-28.3a12,12,0,0,0-17-17L140,67V24a12,12,0,0,0-24,0V67l-7.8-7.8a12,12,0,0,0-17,17Zm-15,15L76.2,91.2a12,12,0,0,0-17,17L67,116H24a12,12,0,0,0,0,24H67l-7.8,7.8a12,12,0,0,0,0,17,12.2,12.2,0,0,0,8.5,3.5,12,12,0,0,0,8.5-3.5l28.3-28.3A12,12,0,0,0,104.5,119.5ZM232,116H189l7.8-7.8a12,12,0,0,0-17-17l-28.3,28.3a12,12,0,0,0,0,17l28.3,28.3a12,12,0,0,0,8.5,3.5,12.2,12.2,0,0,0,8.5-3.5,12,12,0,0,0,0-17L189,140h43a12,12,0,0,0,0-24Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M161.9,182.6a7.9,7.9,0,0,1,0,11.3,7.7,7.7,0,0,1-5.6,2.4,8,8,0,0,1-5.7-2.4L136,179.3V232a8,8,0,0,1-16,0V179.3l-14.6,14.6a8,8,0,0,1-11.3-11.3l28.2-28.3a8.1,8.1,0,0,1,11.4,0Zm-39.6-80.9a8.2,8.2,0,0,0,11.4,0l28.2-28.3a8,8,0,1,0-11.3-11.3L136,76.7V24a8,8,0,0,0-16,0V76.7L105.4,62.1A8,8,0,1,0,94.1,73.4Zm-20.6,20.6L73.4,94.1a8,8,0,1,0-11.3,11.3L76.7,120H24a8,8,0,0,0,0,16H76.7L62.1,150.6a7.9,7.9,0,0,0,0,11.3,7.7,7.7,0,0,0,5.6,2.4,8,8,0,0,0,5.7-2.4l28.3-28.2A8.1,8.1,0,0,0,101.7,122.3ZM232,120H179.3l14.6-14.6a8,8,0,0,0-11.3-11.3l-28.3,28.2a8.1,8.1,0,0,0,0,11.4l28.3,28.2a8,8,0,0,0,5.7,2.4,7.7,7.7,0,0,0,5.6-2.4,7.9,7.9,0,0,0,0-11.3L179.3,136H232a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M161.9,182.6a8,8,0,0,1-5.6,13.7H136V232a8,8,0,0,1-16,0V196.3H99.7a8,8,0,0,1-5.6-13.7l28.2-28.3a8.1,8.1,0,0,1,11.4,0Zm-39.6-80.9a8.2,8.2,0,0,0,11.4,0l28.2-28.3a8,8,0,0,0-5.6-13.7H136V24a8,8,0,0,0-16,0V59.7H99.7a8,8,0,0,0-5.6,13.7Zm-20.6,20.6L73.4,94.1a8,8,0,0,0-13.7,5.6V120H24a8,8,0,0,0,0,16H59.7v20.3a8.2,8.2,0,0,0,5,7.4,7.7,7.7,0,0,0,3,.6,8,8,0,0,0,5.7-2.4l28.3-28.2A8.1,8.1,0,0,0,101.7,122.3ZM232,120H196.3V99.7a8,8,0,0,0-13.7-5.6l-28.3,28.2a8.1,8.1,0,0,0,0,11.4l28.3,28.2a8,8,0,0,0,5.7,2.4,7.7,7.7,0,0,0,3-.6,8.2,8.2,0,0,0,5-7.4V136H232a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M160.5,184a5.9,5.9,0,0,1,0,8.5,5.8,5.8,0,0,1-4.2,1.8,6,6,0,0,1-4.3-1.8l-18-18V232a6,6,0,0,1-12,0V174.5l-18,18a6,6,0,0,1-8.5-8.5l28.3-28.2a5.8,5.8,0,0,1,8.4,0Zm-36.7-83.8a5.8,5.8,0,0,0,8.4,0L160.5,72a6,6,0,0,0-8.5-8.5l-18,18V24a6,6,0,0,0-12,0V81.5l-18-18A6,6,0,0,0,95.5,72Zm-23.6,23.6L72,95.5a6,6,0,0,0-8.5,8.5l18,18H24a6,6,0,0,0,0,12H81.5l-18,18a5.9,5.9,0,0,0,0,8.5,5.8,5.8,0,0,0,4.2,1.8,6,6,0,0,0,4.3-1.8l28.2-28.3A5.8,5.8,0,0,0,100.2,123.8ZM232,122H174.5l18-18a6,6,0,0,0-8.5-8.5l-28.2,28.3a5.8,5.8,0,0,0,0,8.4L184,160.5a6,6,0,0,0,4.3,1.8,5.8,5.8,0,0,0,4.2-1.8,5.9,5.9,0,0,0,0-8.5l-18-18H232a6,6,0,0,0,0-12Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M159.1,185.5a3.9,3.9,0,0,1,0,5.6,3.9,3.9,0,0,1-2.8,1.2,3.8,3.8,0,0,1-2.8-1.2L132,169.7V232a4,4,0,0,1-8,0V169.7l-21.5,21.4a4,4,0,1,1-5.6-5.6l28.3-28.3a3.9,3.9,0,0,1,5.6,0ZM125.2,98.8a3.9,3.9,0,0,0,5.6,0l28.3-28.3a4,4,0,1,0-5.6-5.6L132,86.3V24a4,4,0,0,0-8,0V86.3L102.5,64.9a4,4,0,0,0-5.6,5.6ZM98.8,125.2,70.5,96.9a4,4,0,0,0-5.6,5.6L86.3,124H24a4,4,0,0,0,0,8H86.3L64.9,153.5a3.9,3.9,0,0,0,0,5.6,3.9,3.9,0,0,0,2.8,1.2,3.8,3.8,0,0,0,2.8-1.2l28.3-28.3A3.9,3.9,0,0,0,98.8,125.2ZM232,124H169.7l21.4-21.5a4,4,0,1,0-5.6-5.6l-28.3,28.3a3.9,3.9,0,0,0,0,5.6l28.3,28.3a3.8,3.8,0,0,0,2.8,1.2,3.9,3.9,0,0,0,2.8-1.2,3.9,3.9,0,0,0,0-5.6L169.7,132H232a4,4,0,0,0,0-8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M161.9,182.6a7.9,7.9,0,0,1,0,11.3,7.7,7.7,0,0,1-5.6,2.4,8,8,0,0,1-5.7-2.4L136,179.3V232a8,8,0,0,1-16,0V179.3l-14.6,14.6a8,8,0,0,1-11.3-11.3l28.2-28.3a8.1,8.1,0,0,1,11.4,0Zm-39.6-80.9a8.2,8.2,0,0,0,11.4,0l28.2-28.3a8,8,0,1,0-11.3-11.3L136,76.7V24a8,8,0,0,0-16,0V76.7L105.4,62.1A8,8,0,1,0,94.1,73.4Zm-20.6,20.6L73.4,94.1a8,8,0,1,0-11.3,11.3L76.7,120H24a8,8,0,0,0,0,16H76.7L62.1,150.6a7.9,7.9,0,0,0,0,11.3,7.7,7.7,0,0,0,5.6,2.4,8,8,0,0,0,5.7-2.4l28.3-28.2A8.1,8.1,0,0,0,101.7,122.3ZM232,120H179.3l14.6-14.6a8,8,0,0,0-11.3-11.3l-28.3,28.2a8.1,8.1,0,0,0,0,11.4l28.3,28.2a8,8,0,0,0,5.7,2.4,7.7,7.7,0,0,0,5.6-2.4,7.9,7.9,0,0,0,0-11.3L179.3,136H232a8,8,0,0,0,0-16Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowsInLineHorizontal(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M140,40V216a12,12,0,0,1-24,0V40a12,12,0,0,1,24,0ZM64.5,87.5a12,12,0,0,0-17,17L59,116H12a12,12,0,0,0,0,24H59L47.5,151.5a12,12,0,0,0,0,17,12.1,12.1,0,0,0,17,0l32-32a12,12,0,0,0,0-17ZM244,116H197l11.5-11.5a12,12,0,0,0-17-17l-32,32a12,12,0,0,0,0,17l32,32a12.1,12.1,0,0,0,17,0,12,12,0,0,0,0-17L197,140h47a12,12,0,0,0,0-24Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M136,40V216a8,8,0,0,1-16,0V40a8,8,0,0,1,16,0ZM69.7,90.3a8.1,8.1,0,0,0-11.4,11.4L76.7,120H16a8,8,0,0,0,0,16H76.7L58.3,154.3a8.1,8.1,0,0,0,0,11.4,8.2,8.2,0,0,0,11.4,0l32-32a8.1,8.1,0,0,0,0-11.4ZM240,120H179.3l18.4-18.3a8.1,8.1,0,0,0-11.4-11.4l-32,32a8.1,8.1,0,0,0,0,11.4l32,32a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4L179.3,136H240a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M136,40V216a8,8,0,0,1-16,0V40a8,8,0,0,1,16,0ZM69.7,90.3a8.4,8.4,0,0,0-8.8-1.7A8,8,0,0,0,56,96v24H16a8,8,0,0,0,0,16H56v24a8,8,0,0,0,4.9,7.4,8.5,8.5,0,0,0,3.1.6,8.3,8.3,0,0,0,5.7-2.3l32-32a8.1,8.1,0,0,0,0-11.4ZM240,120H200V96a8,8,0,0,0-4.9-7.4,8.4,8.4,0,0,0-8.8,1.7l-32,32a8.1,8.1,0,0,0,0,11.4l32,32A8.3,8.3,0,0,0,192,168a8.5,8.5,0,0,0,3.1-.6A8,8,0,0,0,200,160V136h40a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M134,40V216a6,6,0,0,1-12,0V40a6,6,0,0,1,12,0ZM68.2,91.8a5.9,5.9,0,0,0-8.4,8.4L81.5,122H16a6,6,0,0,0,0,12H81.5L59.8,155.8a5.9,5.9,0,1,0,8.4,8.4l32-32a5.8,5.8,0,0,0,0-8.4ZM240,122H174.5l21.7-21.8a5.9,5.9,0,0,0-8.4-8.4l-32,32a5.8,5.8,0,0,0,0,8.4l32,32a5.9,5.9,0,0,0,8.4-8.4L174.5,134H240a6,6,0,0,0,0-12Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M132,40V216a4,4,0,0,1-8,0V40a4,4,0,0,1,8,0ZM66.8,93.2a4,4,0,0,0-5.6,5.6L86.3,124H16a4,4,0,0,0,0,8H86.3L61.2,157.2a3.9,3.9,0,0,0,0,5.6,3.9,3.9,0,0,0,5.6,0l32-32a3.9,3.9,0,0,0,0-5.6ZM240,124H169.7l25.1-25.2a4,4,0,1,0-5.6-5.6l-32,32a3.9,3.9,0,0,0,0,5.6l32,32a3.9,3.9,0,0,0,5.6,0,3.9,3.9,0,0,0,0-5.6L169.7,132H240a4,4,0,0,0,0-8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M136,40V216a8,8,0,0,1-16,0V40a8,8,0,0,1,16,0ZM69.7,90.3a8.1,8.1,0,0,0-11.4,11.4L76.7,120H16a8,8,0,0,0,0,16H76.7L58.3,154.3a8.1,8.1,0,0,0,0,11.4,8.2,8.2,0,0,0,11.4,0l32-32a8.1,8.1,0,0,0,0-11.4ZM240,120H179.3l18.4-18.3a8.1,8.1,0,0,0-11.4-11.4l-32,32a8.1,8.1,0,0,0,0,11.4l32,32a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4L179.3,136H240a8,8,0,0,0,0-16Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowsInLineVertical(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M228,128a12,12,0,0,1-12,12H40a12,12,0,0,1,0-24H216A12,12,0,0,1,228,128ZM119.5,96.5a12.1,12.1,0,0,0,17,0l32-32a12,12,0,0,0-17-17L140,59V12a12,12,0,0,0-24,0V59L104.5,47.5a12,12,0,0,0-17,17Zm17,63a12,12,0,0,0-17,0l-32,32a12,12,0,0,0,17,17L116,197v47a12,12,0,0,0,24,0V197l11.5,11.5a12.1,12.1,0,0,0,17,0,12,12,0,0,0,0-17Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M224,128a8,8,0,0,1-8,8H40a8,8,0,0,1,0-16H216A8,8,0,0,1,224,128ZM122.3,101.7a8.2,8.2,0,0,0,11.4,0l32-32a8.1,8.1,0,0,0-11.4-11.4L136,76.7V16a8,8,0,0,0-16,0V76.7L101.7,58.3A8.1,8.1,0,0,0,90.3,69.7Zm11.4,52.6a8.1,8.1,0,0,0-11.4,0l-32,32a8.1,8.1,0,0,0,11.4,11.4L120,179.3V240a8,8,0,0,0,16,0V179.3l18.3,18.4a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M224,128a8,8,0,0,1-8,8H40a8,8,0,0,1,0-16H216A8,8,0,0,1,224,128ZM122.3,101.7a8.2,8.2,0,0,0,11.4,0l32-32a8.4,8.4,0,0,0,1.7-8.8A8,8,0,0,0,160,56H136V16a8,8,0,0,0-16,0V56H96a8,8,0,0,0-7.4,4.9,8.4,8.4,0,0,0,1.7,8.8Zm11.4,52.6a8.1,8.1,0,0,0-11.4,0l-32,32a8.4,8.4,0,0,0-1.7,8.8A8,8,0,0,0,96,200h24v40a8,8,0,0,0,16,0V200h24a8,8,0,0,0,7.4-4.9,8.4,8.4,0,0,0-1.7-8.8Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M222,128a6,6,0,0,1-6,6H40a6,6,0,0,1,0-12H216A6,6,0,0,1,222,128Zm-98.2-27.8a5.8,5.8,0,0,0,8.4,0l32-32a5.9,5.9,0,0,0-8.4-8.4L134,81.5V16a6,6,0,0,0-12,0V81.5L100.2,59.8a5.9,5.9,0,0,0-8.4,8.4Zm8.4,55.6a5.8,5.8,0,0,0-8.4,0l-32,32a5.9,5.9,0,0,0,8.4,8.4L122,174.5V240a6,6,0,0,0,12,0V174.5l21.8,21.7a5.9,5.9,0,0,0,8.4-8.4Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M220,128a4,4,0,0,1-4,4H40a4,4,0,0,1,0-8H216A4,4,0,0,1,220,128ZM125.2,98.8a3.9,3.9,0,0,0,5.6,0l32-32a4,4,0,1,0-5.6-5.6L132,86.3V16a4,4,0,0,0-8,0V86.3L98.8,61.2a4,4,0,0,0-5.6,5.6Zm5.6,58.4a3.9,3.9,0,0,0-5.6,0l-32,32a4,4,0,0,0,5.6,5.6L124,169.7V240a4,4,0,0,0,8,0V169.7l25.2,25.1a3.9,3.9,0,0,0,5.6,0,3.9,3.9,0,0,0,0-5.6Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M224,128a8,8,0,0,1-8,8H40a8,8,0,0,1,0-16H216A8,8,0,0,1,224,128ZM122.3,101.7a8.2,8.2,0,0,0,11.4,0l32-32a8.1,8.1,0,0,0-11.4-11.4L136,76.7V16a8,8,0,0,0-16,0V76.7L101.7,58.3A8.1,8.1,0,0,0,90.3,69.7Zm11.4,52.6a8.1,8.1,0,0,0-11.4,0l-32,32a8.1,8.1,0,0,0,11.4,11.4L120,179.3V240a8,8,0,0,0,16,0V179.3l18.3,18.4a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowsInSimple(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216.5,56.5,181,92h19a12,12,0,0,1,0,24H152a12,12,0,0,1-12-12V56a12,12,0,0,1,24,0V75l35.5-35.5a12,12,0,0,1,17,17ZM104,140H56a12,12,0,0,0,0,24H75L39.5,199.5a12,12,0,0,0,0,17,12.1,12.1,0,0,0,17,0L92,181v19a12,12,0,0,0,24,0V152A12,12,0,0,0,104,140Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M213.7,53.7,171.3,96H200a8,8,0,0,1,0,16H152a8,8,0,0,1-8-8V56a8,8,0,0,1,16,0V84.7l42.3-42.4a8.1,8.1,0,0,1,11.4,11.4ZM104,144H56a8,8,0,0,0,0,16H84.7L42.3,202.3a8.1,8.1,0,0,0,0,11.4,8.2,8.2,0,0,0,11.4,0L96,171.3V200a8,8,0,0,0,16,0V152A8,8,0,0,0,104,144Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M213.7,53.7,187.3,80l18.4,18.3a8.4,8.4,0,0,1,1.7,8.8A8,8,0,0,1,200,112H152a8,8,0,0,1-8-8V56a8,8,0,0,1,4.9-7.4,8.4,8.4,0,0,1,8.8,1.7L176,68.7l26.3-26.4a8.1,8.1,0,0,1,11.4,11.4ZM104,144H56a8,8,0,0,0-7.4,4.9,8.4,8.4,0,0,0,1.7,8.8L68.7,176,42.3,202.3a8.1,8.1,0,0,0,0,11.4,8.2,8.2,0,0,0,11.4,0L80,187.3l18.3,18.4A8.3,8.3,0,0,0,104,208a8.5,8.5,0,0,0,3.1-.6A8,8,0,0,0,112,200V152A8,8,0,0,0,104,144Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M212.2,52.2,166.5,98H200a6,6,0,0,1,0,12H152a6,6,0,0,1-6-6V56a6,6,0,0,1,12,0V89.5l45.8-45.7a5.9,5.9,0,0,1,8.4,8.4ZM104,146H56a6,6,0,0,0,0,12H89.5L43.8,203.8a5.9,5.9,0,1,0,8.4,8.4L98,166.5V200a6,6,0,0,0,12,0V152A6,6,0,0,0,104,146Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M210.8,50.8,161.7,100H200a4,4,0,0,1,0,8H152a4,4,0,0,1-4-4V56a4,4,0,0,1,8,0V94.3l49.2-49.1a4,4,0,1,1,5.6,5.6ZM104,148H56a4,4,0,0,0,0,8H94.3L45.2,205.2a3.9,3.9,0,0,0,0,5.6,3.9,3.9,0,0,0,5.6,0L100,161.7V200a4,4,0,0,0,8,0V152A4,4,0,0,0,104,148Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M213.7,53.7,171.3,96H200a8,8,0,0,1,0,16H152a8,8,0,0,1-8-8V56a8,8,0,0,1,16,0V84.7l42.3-42.4a8.1,8.1,0,0,1,11.4,11.4ZM104,144H56a8,8,0,0,0,0,16H84.7L42.3,202.3a8.1,8.1,0,0,0,0,11.4,8.2,8.2,0,0,0,11.4,0L96,171.3V200a8,8,0,0,0,16,0V152A8,8,0,0,0,104,144Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowsIn(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M140,104V64a12,12,0,0,1,24,0V75l35.5-35.5a12,12,0,0,1,17,17L181,92h11a12,12,0,0,1,0,24H152A12,12,0,0,1,140,104Zm-36,36H64a12,12,0,0,0,0,24H75L39.5,199.5a12,12,0,0,0,0,17,12.1,12.1,0,0,0,17,0L92,181v11a12,12,0,0,0,24,0V152A12,12,0,0,0,104,140Zm77,24h11a12,12,0,0,0,0-24H152a12,12,0,0,0-12,12v40a12,12,0,0,0,24,0V181l35.5,35.5a12.1,12.1,0,0,0,17,0,12,12,0,0,0,0-17ZM104,52A12,12,0,0,0,92,64V75L56.5,39.5a12,12,0,0,0-17,17L75,92H64a12,12,0,0,0,0,24h40a12,12,0,0,0,12-12V64A12,12,0,0,0,104,52Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M144,104V64a8,8,0,0,1,16,0V84.7l42.3-42.4a8.1,8.1,0,0,1,11.4,11.4L171.3,96H192a8,8,0,0,1,0,16H152A8,8,0,0,1,144,104Zm-40,40H64a8,8,0,0,0,0,16H84.7L42.3,202.3a8.1,8.1,0,0,0,0,11.4,8.2,8.2,0,0,0,11.4,0L96,171.3V192a8,8,0,0,0,16,0V152A8,8,0,0,0,104,144Zm67.3,16H192a8,8,0,0,0,0-16H152a8,8,0,0,0-8,8v40a8,8,0,0,0,16,0V171.3l42.3,42.4a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4ZM104,56a8,8,0,0,0-8,8V84.7L53.7,42.3A8.1,8.1,0,0,0,42.3,53.7L84.7,96H64a8,8,0,0,0,0,16h40a8,8,0,0,0,8-8V64A8,8,0,0,0,104,56Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M144,104V64a8,8,0,0,1,4.9-7.4,8.4,8.4,0,0,1,8.8,1.7L172,72.7l30.3-30.4a8.1,8.1,0,0,1,11.4,11.4L183.3,84l14.4,14.3a8.4,8.4,0,0,1,1.7,8.8A8,8,0,0,1,192,112H152A8,8,0,0,1,144,104Zm-40,40H64a8,8,0,0,0-7.4,4.9,8.4,8.4,0,0,0,1.7,8.8L72.7,172,42.3,202.3a8.1,8.1,0,0,0,0,11.4,8.2,8.2,0,0,0,11.4,0L84,183.3l14.3,14.4A8.3,8.3,0,0,0,104,200a8.5,8.5,0,0,0,3.1-.6A8,8,0,0,0,112,192V152A8,8,0,0,0,104,144Zm79.3,28,14.4-14.3a8.4,8.4,0,0,0,1.7-8.8A8,8,0,0,0,192,144H152a8,8,0,0,0-8,8v40a8,8,0,0,0,4.9,7.4,8.5,8.5,0,0,0,3.1.6,8.3,8.3,0,0,0,5.7-2.3L172,183.3l30.3,30.4a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4ZM107.1,56.6a8.4,8.4,0,0,0-8.8,1.7L84,72.7,53.7,42.3A8.1,8.1,0,0,0,42.3,53.7L72.7,84,58.3,98.3a8.4,8.4,0,0,0-1.7,8.8A8,8,0,0,0,64,112h40a8,8,0,0,0,8-8V64A8,8,0,0,0,107.1,56.6Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M146,104V64a6,6,0,0,1,12,0V89.5l45.8-45.7a5.9,5.9,0,0,1,8.4,8.4L166.5,98H192a6,6,0,0,1,0,12H152A6,6,0,0,1,146,104Zm-42,42H64a6,6,0,0,0,0,12H89.5L43.8,203.8a5.9,5.9,0,1,0,8.4,8.4L98,166.5V192a6,6,0,0,0,12,0V152A6,6,0,0,0,104,146Zm62.5,12H192a6,6,0,0,0,0-12H152a6,6,0,0,0-6,6v40a6,6,0,0,0,12,0V166.5l45.8,45.7a5.9,5.9,0,0,0,8.4-8.4ZM104,58a6,6,0,0,0-6,6V89.5L52.2,43.8a5.9,5.9,0,0,0-8.4,8.4L89.5,98H64a6,6,0,0,0,0,12h40a6,6,0,0,0,6-6V64A6,6,0,0,0,104,58Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M148,104V64a4,4,0,0,1,8,0V94.3l49.2-49.1a4,4,0,1,1,5.6,5.6L161.7,100H192a4,4,0,0,1,0,8H152A4,4,0,0,1,148,104Zm-44,44H64a4,4,0,0,0,0,8H94.3L45.2,205.2a3.9,3.9,0,0,0,0,5.6,3.9,3.9,0,0,0,5.6,0L100,161.7V192a4,4,0,0,0,8,0V152A4,4,0,0,0,104,148Zm57.7,8H192a4,4,0,0,0,0-8H152a4,4,0,0,0-4,4v40a4,4,0,0,0,8,0V161.7l49.2,49.1a3.9,3.9,0,0,0,5.6,0,3.9,3.9,0,0,0,0-5.6ZM104,60a4,4,0,0,0-4,4V94.3L50.8,45.2a4,4,0,0,0-5.6,5.6L94.3,100H64a4,4,0,0,0,0,8h40a4,4,0,0,0,4-4V64A4,4,0,0,0,104,60Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M144,104V64a8,8,0,0,1,16,0V84.7l42.3-42.4a8.1,8.1,0,0,1,11.4,11.4L171.3,96H192a8,8,0,0,1,0,16H152A8,8,0,0,1,144,104Zm-40,40H64a8,8,0,0,0,0,16H84.7L42.3,202.3a8.1,8.1,0,0,0,0,11.4,8.2,8.2,0,0,0,11.4,0L96,171.3V192a8,8,0,0,0,16,0V152A8,8,0,0,0,104,144Zm67.3,16H192a8,8,0,0,0,0-16H152a8,8,0,0,0-8,8v40a8,8,0,0,0,16,0V171.3l42.3,42.4a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4ZM104,56a8,8,0,0,0-8,8V84.7L53.7,42.3A8.1,8.1,0,0,0,42.3,53.7L84.7,96H64a8,8,0,0,0,0,16h40a8,8,0,0,0,8-8V64A8,8,0,0,0,104,56Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowsLeftRight(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216.5,184.5l-32,32a12.1,12.1,0,0,1-17,0,12,12,0,0,1,0-17L179,188H48a12,12,0,0,1,0-24H179l-11.5-11.5a12,12,0,0,1,17-17l32,32A12,12,0,0,1,216.5,184.5Zm-145-64a12.1,12.1,0,0,0,17,0,12,12,0,0,0,0-17L77,92H208a12,12,0,0,0,0-24H77L88.5,56.5a12,12,0,0,0-17-17l-32,32a12,12,0,0,0,0,17Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M213.7,181.7l-32,32a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L188.7,184H48a8,8,0,0,1,0-16H188.7l-18.4-18.3a8.1,8.1,0,0,1,11.4-11.4l32,32A8.1,8.1,0,0,1,213.7,181.7Zm-139.4-64a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4L67.3,88H208a8,8,0,0,0,0-16H67.3L85.7,53.7A8.1,8.1,0,0,0,74.3,42.3l-32,32a8.1,8.1,0,0,0,0,11.4Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M213.7,181.7l-32,32A8.3,8.3,0,0,1,176,216a8.5,8.5,0,0,1-3.1-.6A8,8,0,0,1,168,208V184H48a8,8,0,0,1,0-16H168V144a8,8,0,0,1,4.9-7.4,8.4,8.4,0,0,1,8.8,1.7l32,32A8.1,8.1,0,0,1,213.7,181.7Zm-139.4-64A8.3,8.3,0,0,0,80,120a8.5,8.5,0,0,0,3.1-.6A8,8,0,0,0,88,112V88H208a8,8,0,0,0,0-16H88V48a8,8,0,0,0-4.9-7.4,8.4,8.4,0,0,0-8.8,1.7l-32,32a8.1,8.1,0,0,0,0,11.4Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M212.2,171.8a5.8,5.8,0,0,1,0,8.4l-32,32a5.9,5.9,0,0,1-8.4-8.4L193.5,182H48a6,6,0,0,1,0-12H193.5l-21.7-21.8a5.9,5.9,0,0,1,8.4-8.4ZM75.8,116.2a5.9,5.9,0,0,0,8.4-8.4L62.5,86H208a6,6,0,0,0,0-12H62.5L84.2,52.2a5.9,5.9,0,0,0-8.4-8.4l-32,32a5.8,5.8,0,0,0,0,8.4Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M210.8,173.2a3.9,3.9,0,0,1,0,5.6l-32,32a3.9,3.9,0,0,1-5.6,0,3.9,3.9,0,0,1,0-5.6L198.3,180H48a4,4,0,0,1,0-8H198.3l-25.1-25.2a4,4,0,0,1,5.6-5.6ZM77.2,114.8a3.9,3.9,0,0,0,5.6,0,3.9,3.9,0,0,0,0-5.6L57.7,84H208a4,4,0,0,0,0-8H57.7L82.8,50.8a4,4,0,0,0-5.6-5.6l-32,32a3.9,3.9,0,0,0,0,5.6Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M213.7,181.7l-32,32a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L188.7,184H48a8,8,0,0,1,0-16H188.7l-18.4-18.3a8.1,8.1,0,0,1,11.4-11.4l32,32A8.1,8.1,0,0,1,213.7,181.7Zm-139.4-64a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4L67.3,88H208a8,8,0,0,0,0-16H67.3L85.7,53.7A8.1,8.1,0,0,0,74.3,42.3l-32,32a8.1,8.1,0,0,0,0,11.4Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowsOutCardinal(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M164.8,195.2a12,12,0,0,1,0,17l-28.3,28.3a12.1,12.1,0,0,1-17,0L91.2,212.2a12,12,0,0,1,17-17L116,203V160a12,12,0,0,1,24,0v43l7.8-7.8A12,12,0,0,1,164.8,195.2ZM108.2,60.8,116,53V96a12,12,0,0,0,24,0V53l7.8,7.8a12,12,0,0,0,8.5,3.5,12.2,12.2,0,0,0,8.5-3.5,12,12,0,0,0,0-17L136.5,15.5a12.1,12.1,0,0,0-17,0L91.2,43.8a12,12,0,0,0,17,17ZM53,140H96a12,12,0,0,0,0-24H53l7.8-7.8a12,12,0,0,0-17-17L15.5,119.5a12,12,0,0,0,0,17l28.3,28.3a12,12,0,0,0,8.5,3.5,12.2,12.2,0,0,0,8.5-3.5,12,12,0,0,0,0-17Zm187.5-20.5L212.2,91.2a12,12,0,0,0-17,17L203,116H160a12,12,0,0,0,0,24h43l-7.8,7.8a12,12,0,0,0,0,17,12.2,12.2,0,0,0,8.5,3.5,12,12,0,0,0,8.5-3.5l28.3-28.3A12,12,0,0,0,240.5,119.5Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M161.9,198.1a7.9,7.9,0,0,1,0,11.3l-28.2,28.3a8.2,8.2,0,0,1-11.4,0L94.1,209.4a8,8,0,0,1,11.3-11.3L120,212.7V160a8,8,0,0,1,16,0v52.7l14.6-14.6A7.9,7.9,0,0,1,161.9,198.1ZM105.4,57.9,120,43.3V96a8,8,0,0,0,16,0V43.3l14.6,14.6a8,8,0,0,0,5.7,2.4,7.7,7.7,0,0,0,5.6-2.4,7.9,7.9,0,0,0,0-11.3L133.7,18.3a8.2,8.2,0,0,0-11.4,0L94.1,46.6a8,8,0,0,0,11.3,11.3ZM43.3,136H96a8,8,0,0,0,0-16H43.3l14.6-14.6A8,8,0,0,0,46.6,94.1L18.3,122.3a8.1,8.1,0,0,0,0,11.4l28.3,28.2a8,8,0,0,0,5.7,2.4,7.7,7.7,0,0,0,5.6-2.4,7.9,7.9,0,0,0,0-11.3Zm194.4-13.7L209.4,94.1a8,8,0,0,0-11.3,11.3L212.7,120H160a8,8,0,0,0,0,16h52.7l-14.6,14.6a7.9,7.9,0,0,0,0,11.3,7.7,7.7,0,0,0,5.6,2.4,8,8,0,0,0,5.7-2.4l28.3-28.2A8.1,8.1,0,0,0,237.7,122.3Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M163.7,200.7a8.1,8.1,0,0,1-1.8,8.7l-28.2,28.3a8.2,8.2,0,0,1-11.4,0L94.1,209.4a8,8,0,0,1,5.6-13.7H120V160a8,8,0,0,1,16,0v35.7h20.3A8.2,8.2,0,0,1,163.7,200.7ZM99.7,60.3H120V96a8,8,0,0,0,16,0V60.3h20.3a8,8,0,0,0,5.6-13.7L133.7,18.3a8.2,8.2,0,0,0-11.4,0L94.1,46.6a8,8,0,0,0,5.6,13.7ZM96,136a8,8,0,0,0,0-16H60.3V99.7a8,8,0,0,0-13.7-5.6L18.3,122.3a8.1,8.1,0,0,0,0,11.4l28.3,28.2a8,8,0,0,0,5.7,2.4,7.7,7.7,0,0,0,3-.6,8.2,8.2,0,0,0,5-7.4V136Zm141.7-13.7L209.4,94.1a8,8,0,0,0-13.7,5.6V120H160a8,8,0,0,0,0,16h35.7v20.3a8.2,8.2,0,0,0,5,7.4,7.7,7.7,0,0,0,3,.6,8,8,0,0,0,5.7-2.4l28.3-28.2A8.1,8.1,0,0,0,237.7,122.3Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M160.5,199.5a5.9,5.9,0,0,1,0,8.5l-28.3,28.2a5.8,5.8,0,0,1-8.4,0L95.5,208a6,6,0,0,1,8.5-8.5l18,18V160a6,6,0,0,1,12,0v57.5l18-18A5.9,5.9,0,0,1,160.5,199.5ZM104,56.5l18-18V96a6,6,0,0,0,12,0V38.5l18,18a6,6,0,0,0,4.3,1.8,5.8,5.8,0,0,0,4.2-1.8,5.9,5.9,0,0,0,0-8.5L132.2,19.8a5.8,5.8,0,0,0-8.4,0L95.5,48a6,6,0,0,0,8.5,8.5ZM38.5,134H96a6,6,0,0,0,0-12H38.5l18-18A6,6,0,0,0,48,95.5L19.8,123.8a5.8,5.8,0,0,0,0,8.4L48,160.5a6,6,0,0,0,4.3,1.8,5.8,5.8,0,0,0,4.2-1.8,5.9,5.9,0,0,0,0-8.5Zm197.7-10.2L208,95.5a6,6,0,0,0-8.5,8.5l18,18H160a6,6,0,0,0,0,12h57.5l-18,18a5.9,5.9,0,0,0,0,8.5,5.8,5.8,0,0,0,4.2,1.8,6,6,0,0,0,4.3-1.8l28.2-28.3A5.8,5.8,0,0,0,236.2,123.8Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M159.1,200.9a3.9,3.9,0,0,1,0,5.6l-28.3,28.3a3.9,3.9,0,0,1-5.6,0L96.9,206.5a4,4,0,1,1,5.6-5.6L124,222.3V160a4,4,0,0,1,8,0v62.3l21.5-21.4A3.9,3.9,0,0,1,159.1,200.9ZM102.5,55.1,124,33.7V96a4,4,0,0,0,8,0V33.7l21.5,21.4a3.8,3.8,0,0,0,2.8,1.2,3.9,3.9,0,0,0,2.8-1.2,3.9,3.9,0,0,0,0-5.6L130.8,21.2a3.9,3.9,0,0,0-5.6,0L96.9,49.5a4,4,0,0,0,5.6,5.6ZM33.7,132H96a4,4,0,0,0,0-8H33.7l21.4-21.5a4,4,0,0,0-5.6-5.6L21.2,125.2a3.9,3.9,0,0,0,0,5.6l28.3,28.3a3.8,3.8,0,0,0,2.8,1.2,3.9,3.9,0,0,0,2.8-1.2,3.9,3.9,0,0,0,0-5.6Zm201.1-6.8L206.5,96.9a4,4,0,1,0-5.6,5.6L222.3,124H160a4,4,0,0,0,0,8h62.3l-21.4,21.5a3.9,3.9,0,0,0,0,5.6,3.9,3.9,0,0,0,2.8,1.2,3.8,3.8,0,0,0,2.8-1.2l28.3-28.3A3.9,3.9,0,0,0,234.8,125.2Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M161.9,198.1a7.9,7.9,0,0,1,0,11.3l-28.2,28.3a8.2,8.2,0,0,1-11.4,0L94.1,209.4a8,8,0,0,1,11.3-11.3L120,212.7V160a8,8,0,0,1,16,0v52.7l14.6-14.6A7.9,7.9,0,0,1,161.9,198.1ZM105.4,57.9,120,43.3V96a8,8,0,0,0,16,0V43.3l14.6,14.6a8,8,0,0,0,5.7,2.4,7.7,7.7,0,0,0,5.6-2.4,7.9,7.9,0,0,0,0-11.3L133.7,18.3a8.2,8.2,0,0,0-11.4,0L94.1,46.6a8,8,0,0,0,11.3,11.3ZM43.3,136H96a8,8,0,0,0,0-16H43.3l14.6-14.6A8,8,0,0,0,46.6,94.1L18.3,122.3a8.1,8.1,0,0,0,0,11.4l28.3,28.2a8,8,0,0,0,5.7,2.4,7.7,7.7,0,0,0,5.6-2.4,7.9,7.9,0,0,0,0-11.3Zm194.4-13.7L209.4,94.1a8,8,0,0,0-11.3,11.3L212.7,120H160a8,8,0,0,0,0,16h52.7l-14.6,14.6a7.9,7.9,0,0,0,0,11.3,7.7,7.7,0,0,0,5.6,2.4,8,8,0,0,0,5.7-2.4l28.3-28.2A8.1,8.1,0,0,0,237.7,122.3Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowsOutLineHorizontal(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M140,40V216a12,12,0,0,1-24,0V40a12,12,0,0,1,24,0ZM88,116H41l11.5-11.5a12,12,0,0,0-17-17l-32,32a12,12,0,0,0,0,17l32,32a12.1,12.1,0,0,0,17,0,12,12,0,0,0,0-17L41,140H88a12,12,0,0,0,0-24Zm164.5,3.5-32-32a12,12,0,0,0-17,17L215,116H168a12,12,0,0,0,0,24h47l-11.5,11.5a12,12,0,0,0,0,17,12.1,12.1,0,0,0,17,0l32-32A12,12,0,0,0,252.5,119.5Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M136,40V216a8,8,0,0,1-16,0V40a8,8,0,0,1,16,0ZM96,120H35.3l18.4-18.3A8.1,8.1,0,0,0,42.3,90.3l-32,32a8.1,8.1,0,0,0,0,11.4l32,32a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4L35.3,136H96a8,8,0,0,0,0-16Zm149.7,2.3-32-32a8.1,8.1,0,0,0-11.4,11.4L220.7,120H160a8,8,0,0,0,0,16h60.7l-18.4,18.3a8.1,8.1,0,0,0,0,11.4,8.2,8.2,0,0,0,11.4,0l32-32A8.1,8.1,0,0,0,245.7,122.3Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M136,40V216a8,8,0,0,1-16,0V40a8,8,0,0,1,16,0ZM96,120H56V96a8,8,0,0,0-4.9-7.4,8.4,8.4,0,0,0-8.8,1.7l-32,32a8.1,8.1,0,0,0,0,11.4l32,32A8.3,8.3,0,0,0,48,168a8.5,8.5,0,0,0,3.1-.6A8,8,0,0,0,56,160V136H96a8,8,0,0,0,0-16Zm149.7,2.3-32-32a8.4,8.4,0,0,0-8.8-1.7A8,8,0,0,0,200,96v24H160a8,8,0,0,0,0,16h40v24a8,8,0,0,0,4.9,7.4,8.5,8.5,0,0,0,3.1.6,8.3,8.3,0,0,0,5.7-2.3l32-32A8.1,8.1,0,0,0,245.7,122.3Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M134,40V216a6,6,0,0,1-12,0V40a6,6,0,0,1,12,0ZM96,122H30.5l21.7-21.8a5.9,5.9,0,0,0-8.4-8.4l-32,32a5.8,5.8,0,0,0,0,8.4l32,32a5.9,5.9,0,0,0,8.4-8.4L30.5,134H96a6,6,0,0,0,0-12Zm148.2,1.8-32-32a5.9,5.9,0,0,0-8.4,8.4L225.5,122H160a6,6,0,0,0,0,12h65.5l-21.7,21.8a5.9,5.9,0,1,0,8.4,8.4l32-32A5.8,5.8,0,0,0,244.2,123.8Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M132,40V216a4,4,0,0,1-8,0V40a4,4,0,0,1,8,0ZM96,124H25.7L50.8,98.8a4,4,0,0,0-5.6-5.6l-32,32a3.9,3.9,0,0,0,0,5.6l32,32a3.9,3.9,0,0,0,5.6,0,3.9,3.9,0,0,0,0-5.6L25.7,132H96a4,4,0,0,0,0-8Zm146.8,1.2-32-32a4,4,0,0,0-5.6,5.6L230.3,124H160a4,4,0,0,0,0,8h70.3l-25.1,25.2a3.9,3.9,0,0,0,0,5.6,3.9,3.9,0,0,0,5.6,0l32-32A3.9,3.9,0,0,0,242.8,125.2Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M136,40V216a8,8,0,0,1-16,0V40a8,8,0,0,1,16,0ZM96,120H35.3l18.4-18.3A8.1,8.1,0,0,0,42.3,90.3l-32,32a8.1,8.1,0,0,0,0,11.4l32,32a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4L35.3,136H96a8,8,0,0,0,0-16Zm149.7,2.3-32-32a8.1,8.1,0,0,0-11.4,11.4L220.7,120H160a8,8,0,0,0,0,16h60.7l-18.4,18.3a8.1,8.1,0,0,0,0,11.4,8.2,8.2,0,0,0,11.4,0l32-32A8.1,8.1,0,0,0,245.7,122.3Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowsOutLineVertical(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M228,128a12,12,0,0,1-12,12H40a12,12,0,0,1,0-24H216A12,12,0,0,1,228,128ZM104.5,52.5,116,41V88a12,12,0,0,0,24,0V41l11.5,11.5a12.1,12.1,0,0,0,17,0,12,12,0,0,0,0-17l-32-32a12,12,0,0,0-17,0l-32,32a12,12,0,0,0,17,17Zm47,151L140,215V168a12,12,0,0,0-24,0v47l-11.5-11.5a12,12,0,0,0-17,17l32,32a12.1,12.1,0,0,0,17,0l32-32a12,12,0,0,0-17-17Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M224,128a8,8,0,0,1-8,8H40a8,8,0,0,1,0-16H216A8,8,0,0,1,224,128ZM101.7,53.7,120,35.3V96a8,8,0,0,0,16,0V35.3l18.3,18.4a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4l-32-32a8.1,8.1,0,0,0-11.4,0l-32,32a8.1,8.1,0,0,0,11.4,11.4Zm52.6,148.6L136,220.7V160a8,8,0,0,0-16,0v60.7l-18.3-18.4a8.1,8.1,0,0,0-11.4,11.4l32,32a8.2,8.2,0,0,0,11.4,0l32-32a8.1,8.1,0,0,0-11.4-11.4Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M224,128a8,8,0,0,1-8,8H40a8,8,0,0,1,0-16H216A8,8,0,0,1,224,128ZM96,56h24V96a8,8,0,0,0,16,0V56h24a8,8,0,0,0,7.4-4.9,8.4,8.4,0,0,0-1.7-8.8l-32-32a8.1,8.1,0,0,0-11.4,0l-32,32a8.4,8.4,0,0,0-1.7,8.8A8,8,0,0,0,96,56Zm64,144H136V160a8,8,0,0,0-16,0v40H96a8,8,0,0,0-7.4,4.9,8.4,8.4,0,0,0,1.7,8.8l32,32a8.2,8.2,0,0,0,11.4,0l32-32a8.4,8.4,0,0,0,1.7-8.8A8,8,0,0,0,160,200Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M222,128a6,6,0,0,1-6,6H40a6,6,0,0,1,0-12H216A6,6,0,0,1,222,128ZM100.2,52.2,122,30.5V96a6,6,0,0,0,12,0V30.5l21.8,21.7a5.9,5.9,0,1,0,8.4-8.4l-32-32a5.8,5.8,0,0,0-8.4,0l-32,32a5.9,5.9,0,0,0,8.4,8.4Zm55.6,151.6L134,225.5V160a6,6,0,0,0-12,0v65.5l-21.8-21.7a5.9,5.9,0,0,0-8.4,8.4l32,32a5.8,5.8,0,0,0,8.4,0l32-32a5.9,5.9,0,0,0-8.4-8.4Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M220,128a4,4,0,0,1-4,4H40a4,4,0,0,1,0-8H216A4,4,0,0,1,220,128ZM98.8,50.8,124,25.7V96a4,4,0,0,0,8,0V25.7l25.2,25.1a3.9,3.9,0,0,0,5.6,0,3.9,3.9,0,0,0,0-5.6l-32-32a3.9,3.9,0,0,0-5.6,0l-32,32a4,4,0,0,0,5.6,5.6Zm58.4,154.4L132,230.3V160a4,4,0,0,0-8,0v70.3L98.8,205.2a4,4,0,0,0-5.6,5.6l32,32a3.9,3.9,0,0,0,5.6,0l32-32a4,4,0,0,0-5.6-5.6Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M224,128a8,8,0,0,1-8,8H40a8,8,0,0,1,0-16H216A8,8,0,0,1,224,128ZM101.7,53.7,120,35.3V96a8,8,0,0,0,16,0V35.3l18.3,18.4a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4l-32-32a8.1,8.1,0,0,0-11.4,0l-32,32a8.1,8.1,0,0,0,11.4,11.4Zm52.6,148.6L136,220.7V160a8,8,0,0,0-16,0v60.7l-18.3-18.4a8.1,8.1,0,0,0-11.4,11.4l32,32a8.2,8.2,0,0,0,11.4,0l32-32a8.1,8.1,0,0,0-11.4-11.4Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowsOutSimple(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M220,48V96a12,12,0,0,1-24,0V77l-35.5,35.5a12.1,12.1,0,0,1-17,0,12,12,0,0,1,0-17L179,60H160a12,12,0,0,1,0-24h48A12,12,0,0,1,220,48ZM95.5,143.5,60,179V160a12,12,0,0,0-24,0v48a12,12,0,0,0,12,12H96a12,12,0,0,0,0-24H77l35.5-35.5a12,12,0,0,0-17-17Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M216,48V96a8,8,0,0,1-16,0V67.3l-42.3,42.4a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L188.7,56H160a8,8,0,0,1,0-16h48A8,8,0,0,1,216,48ZM98.3,146.3,56,188.7V160a8,8,0,0,0-16,0v48a8,8,0,0,0,8,8H96a8,8,0,0,0,0-16H67.3l42.4-42.3a8.1,8.1,0,0,0-11.4-11.4Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,48V96a8,8,0,0,1-4.9,7.4,8.5,8.5,0,0,1-3.1.6,8.3,8.3,0,0,1-5.7-2.3L184,83.3l-26.3,26.4a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L172.7,72,154.3,53.7a8.4,8.4,0,0,1-1.7-8.8A8,8,0,0,1,160,40h48A8,8,0,0,1,216,48ZM98.3,146.3,72,172.7,53.7,154.3a8.4,8.4,0,0,0-8.8-1.7A8,8,0,0,0,40,160v48a8,8,0,0,0,8,8H96a8,8,0,0,0,7.4-4.9,8.4,8.4,0,0,0-1.7-8.8L83.3,184l26.4-26.3a8.1,8.1,0,0,0-11.4-11.4Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M214,48V96a6,6,0,0,1-12,0V62.5l-45.8,45.7a5.9,5.9,0,0,1-8.4-8.4L193.5,54H160a6,6,0,0,1,0-12h48A6,6,0,0,1,214,48ZM99.8,147.8,54,193.5V160a6,6,0,0,0-12,0v48a6,6,0,0,0,6,6H96a6,6,0,0,0,0-12H62.5l45.7-45.8a5.9,5.9,0,0,0-8.4-8.4Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M212,48V96a4,4,0,0,1-8,0V57.7l-49.2,49.1a3.9,3.9,0,0,1-5.6,0,3.9,3.9,0,0,1,0-5.6L198.3,52H160a4,4,0,0,1,0-8h48A4,4,0,0,1,212,48ZM101.2,149.2,52,198.3V160a4,4,0,0,0-8,0v48a4,4,0,0,0,4,4H96a4,4,0,0,0,0-8H57.7l49.1-49.2a4,4,0,0,0-5.6-5.6Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,48V96a8,8,0,0,1-16,0V67.3l-42.3,42.4a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L188.7,56H160a8,8,0,0,1,0-16h48A8,8,0,0,1,216,48ZM98.3,146.3,56,188.7V160a8,8,0,0,0-16,0v48a8,8,0,0,0,8,8H96a8,8,0,0,0,0-16H67.3l42.4-42.3a8.1,8.1,0,0,0-11.4-11.4Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowsOut(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M220,48V88a12,12,0,0,1-24,0V77l-35.5,35.5a12.1,12.1,0,0,1-17,0,12,12,0,0,1,0-17L179,60H168a12,12,0,0,1,0-24h40A12,12,0,0,1,220,48ZM95.5,143.5,60,179V168a12,12,0,0,0-24,0v40a12,12,0,0,0,12,12H88a12,12,0,0,0,0-24H77l35.5-35.5a12,12,0,0,0-17-17ZM208,156a12,12,0,0,0-12,12v11l-35.5-35.5a12,12,0,0,0-17,17L179,196H168a12,12,0,0,0,0,24h40a12,12,0,0,0,12-12V168A12,12,0,0,0,208,156ZM77,60H88a12,12,0,0,0,0-24H48A12,12,0,0,0,36,48V88a12,12,0,0,0,24,0V77l35.5,35.5a12.1,12.1,0,0,0,17,0,12,12,0,0,0,0-17Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M216,48V88a8,8,0,0,1-16,0V67.3l-42.3,42.4a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L188.7,56H168a8,8,0,0,1,0-16h40A8,8,0,0,1,216,48ZM98.3,146.3,56,188.7V168a8,8,0,0,0-16,0v40a8,8,0,0,0,8,8H88a8,8,0,0,0,0-16H67.3l42.4-42.3a8.1,8.1,0,0,0-11.4-11.4ZM208,160a8,8,0,0,0-8,8v20.7l-42.3-42.4a8.1,8.1,0,0,0-11.4,11.4L188.7,200H168a8,8,0,0,0,0,16h40a8,8,0,0,0,8-8V168A8,8,0,0,0,208,160ZM67.3,56H88a8,8,0,0,0,0-16H48a8,8,0,0,0-8,8V88a8,8,0,0,0,16,0V67.3l42.3,42.4a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,48V88a8,8,0,0,1-4.9,7.4,8.5,8.5,0,0,1-3.1.6,8.3,8.3,0,0,1-5.7-2.3L188,79.3l-30.3,30.4a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L176.7,68,162.3,53.7a8.4,8.4,0,0,1-1.7-8.8A8,8,0,0,1,168,40h40A8,8,0,0,1,216,48ZM98.3,146.3,68,176.7,53.7,162.3a8.4,8.4,0,0,0-8.8-1.7A8,8,0,0,0,40,168v40a8,8,0,0,0,8,8H88a8,8,0,0,0,7.4-4.9,8.4,8.4,0,0,0-1.7-8.8L79.3,188l30.4-30.3a8.1,8.1,0,0,0-11.4-11.4Zm112.8,14.3a8.4,8.4,0,0,0-8.8,1.7L188,176.7l-30.3-30.4a8.1,8.1,0,0,0-11.4,11.4L176.7,188l-14.4,14.3a8.4,8.4,0,0,0-1.7,8.8A8,8,0,0,0,168,216h40a8,8,0,0,0,8-8V168A8,8,0,0,0,211.1,160.6ZM79.3,68,93.7,53.7a8.4,8.4,0,0,0,1.7-8.8A8,8,0,0,0,88,40H48a8,8,0,0,0-8,8V88a8,8,0,0,0,4.9,7.4A8.5,8.5,0,0,0,48,96a8.3,8.3,0,0,0,5.7-2.3L68,79.3l30.3,30.4a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M214,48V88a6,6,0,0,1-12,0V62.5l-45.8,45.7a5.9,5.9,0,0,1-8.4-8.4L193.5,54H168a6,6,0,0,1,0-12h40A6,6,0,0,1,214,48ZM99.8,147.8,54,193.5V168a6,6,0,0,0-12,0v40a6,6,0,0,0,6,6H88a6,6,0,0,0,0-12H62.5l45.7-45.8a5.9,5.9,0,0,0-8.4-8.4ZM208,162a6,6,0,0,0-6,6v25.5l-45.8-45.7a5.9,5.9,0,0,0-8.4,8.4L193.5,202H168a6,6,0,0,0,0,12h40a6,6,0,0,0,6-6V168A6,6,0,0,0,208,162ZM62.5,54H88a6,6,0,0,0,0-12H48a6,6,0,0,0-6,6V88a6,6,0,0,0,12,0V62.5l45.8,45.7a5.9,5.9,0,0,0,8.4-8.4Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M212,48V88a4,4,0,0,1-8,0V57.7l-49.2,49.1a3.9,3.9,0,0,1-5.6,0,3.9,3.9,0,0,1,0-5.6L198.3,52H168a4,4,0,0,1,0-8h40A4,4,0,0,1,212,48ZM101.2,149.2,52,198.3V168a4,4,0,0,0-8,0v40a4,4,0,0,0,4,4H88a4,4,0,0,0,0-8H57.7l49.1-49.2a4,4,0,0,0-5.6-5.6ZM208,164a4,4,0,0,0-4,4v30.3l-49.2-49.1a4,4,0,0,0-5.6,5.6L198.3,204H168a4,4,0,0,0,0,8h40a4,4,0,0,0,4-4V168A4,4,0,0,0,208,164ZM57.7,52H88a4,4,0,0,0,0-8H48a4,4,0,0,0-4,4V88a4,4,0,0,0,8,0V57.7l49.2,49.1a3.9,3.9,0,0,0,5.6,0,3.9,3.9,0,0,0,0-5.6Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,48V88a8,8,0,0,1-16,0V67.3l-42.3,42.4a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L188.7,56H168a8,8,0,0,1,0-16h40A8,8,0,0,1,216,48ZM98.3,146.3,56,188.7V168a8,8,0,0,0-16,0v40a8,8,0,0,0,8,8H88a8,8,0,0,0,0-16H67.3l42.4-42.3a8.1,8.1,0,0,0-11.4-11.4ZM208,160a8,8,0,0,0-8,8v20.7l-42.3-42.4a8.1,8.1,0,0,0-11.4,11.4L188.7,200H168a8,8,0,0,0,0,16h40a8,8,0,0,0,8-8V168A8,8,0,0,0,208,160ZM67.3,56H88a8,8,0,0,0,0-16H48a8,8,0,0,0-8,8V88a8,8,0,0,0,16,0V67.3l42.3,42.4a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4Z" />
        </>
      )}
    </svg>
  );
}

export function PhArrowsVertical(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M168.5,187.5a12,12,0,0,1,0,17l-32,32a12.1,12.1,0,0,1-17,0l-32-32a12,12,0,0,1,17-17L116,199V57L104.5,68.5a12,12,0,0,1-17-17l32-32a12,12,0,0,1,17,0l32,32a12,12,0,0,1,0,17,12.1,12.1,0,0,1-17,0L140,57V199l11.5-11.5A12,12,0,0,1,168.5,187.5Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M165.7,194.3a8.1,8.1,0,0,1,0,11.4l-32,32a8.2,8.2,0,0,1-11.4,0l-32-32a8.1,8.1,0,0,1,11.4-11.4L120,212.7V43.3L101.7,61.7A8.1,8.1,0,0,1,90.3,50.3l32-32a8.1,8.1,0,0,1,11.4,0l32,32a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0L136,43.3V212.7l18.3-18.4A8.1,8.1,0,0,1,165.7,194.3Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M167.4,196.9a8.4,8.4,0,0,1-1.7,8.8l-32,32a8.2,8.2,0,0,1-11.4,0l-32-32a8.4,8.4,0,0,1-1.7-8.8A8,8,0,0,1,96,192h24V64H96a8,8,0,0,1-7.4-4.9,8.4,8.4,0,0,1,1.7-8.8l32-32a8.1,8.1,0,0,1,11.4,0l32,32a8.4,8.4,0,0,1,1.7,8.8A8,8,0,0,1,160,64H136V192h24A8,8,0,0,1,167.4,196.9Z" />
          <path d="M129,46.9a1.5,1.5,0,0,0,0-3,1.5,1.5,0,1,0,0,3Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M164.2,195.8a5.8,5.8,0,0,1,0,8.4l-32,32a5.8,5.8,0,0,1-8.4,0l-32-32a5.9,5.9,0,0,1,8.4-8.4L122,217.5V38.5L100.2,60.2a5.9,5.9,0,0,1-8.4-8.4l32-32a5.8,5.8,0,0,1,8.4,0l32,32a5.9,5.9,0,1,1-8.4,8.4L134,38.5v179l21.8-21.7A5.8,5.8,0,0,1,164.2,195.8Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M162.8,197.2a3.9,3.9,0,0,1,0,5.6l-32,32a3.9,3.9,0,0,1-5.6,0l-32-32a4,4,0,0,1,5.6-5.6L124,222.3V33.7L98.8,58.8a4,4,0,0,1-5.6-5.6l32-32a3.9,3.9,0,0,1,5.6,0l32,32a3.9,3.9,0,0,1,0,5.6,3.9,3.9,0,0,1-5.6,0L132,33.7V222.3l25.2-25.1A3.9,3.9,0,0,1,162.8,197.2Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M165.7,194.3a8.1,8.1,0,0,1,0,11.4l-32,32a8.2,8.2,0,0,1-11.4,0l-32-32a8.1,8.1,0,0,1,11.4-11.4L120,212.7V43.3L101.7,61.7A8.1,8.1,0,0,1,90.3,50.3l32-32a8.1,8.1,0,0,1,11.4,0l32,32a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0L136,43.3V212.7l18.3-18.4A8.1,8.1,0,0,1,165.7,194.3Z" />
        </>
      )}
    </svg>
  );
}

export function PhArticleMedium(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M60,128a12,12,0,0,1-12,12H24a12,12,0,0,1,0-24h4V60H24a12,12,0,0,1,0-24H40a12.2,12.2,0,0,1,10.2,5.6L80,89.4l29.8-47.8A12.2,12.2,0,0,1,120,36h16a12,12,0,0,1,0,24h-4v56h4a12,12,0,0,1,0,24H112a12,12,0,0,1-12-12,11.9,11.9,0,0,1,8-11.3V89.8L90.2,118.4a12.1,12.1,0,0,1-20.4,0L52,89.8v26.9A11.9,11.9,0,0,1,60,128Zm116-28h64a12,12,0,0,0,0-24H176a12,12,0,0,0,0,24Zm64,16H176a12,12,0,0,0,0,24h64a12,12,0,0,0,0-24Zm0,40H72a12,12,0,0,0,0,24H240a12,12,0,0,0,0-24Zm0,40H72a12,12,0,0,0,0,24H240a12,12,0,0,0,0-24Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M56,136a8,8,0,0,1-8,8H24a8,8,0,0,1,0-16h8V64H24a8,8,0,0,1,0-16H40a8,8,0,0,1,6.8,3.8L80,104.9l33.2-53.1A8,8,0,0,1,120,48h16a8,8,0,0,1,0,16h-8v64h8a8,8,0,0,1,0,16H112a8,8,0,0,1,0-16V83.9L86.8,124.2a8,8,0,0,1-13.6,0L48,83.9V128A8,8,0,0,1,56,136Zm112-24h72a8,8,0,0,0,0-16H168a8,8,0,0,0,0,16Zm72,16H168a8,8,0,0,0,0,16h72a8,8,0,0,0,0-16Zm0,32H72a8,8,0,0,0,0,16H240a8,8,0,0,0,0-16Zm0,32H72a8,8,0,0,0,0,16H240a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M56,136a8,8,0,0,1-8,8H24a8,8,0,0,1,0-16h8V64H24a8,8,0,0,1,0-16H40a8,8,0,0,1,6.8,3.8L80,104.9l33.2-53.1A8,8,0,0,1,120,48h16a8,8,0,0,1,0,16h-8v64h8a8,8,0,0,1,0,16H112a8,8,0,0,1,0-16V83.9L86.8,124.2a8,8,0,0,1-13.6,0L48,83.9V128A8,8,0,0,1,56,136Zm112-24h72a8,8,0,0,0,0-16H168a8,8,0,0,0,0,16Zm72,16H168a8,8,0,0,0,0,16h72a8,8,0,0,0,0-16Zm0,32H72a8,8,0,0,0,0,16H240a8,8,0,0,0,0-16Zm0,32H72a8,8,0,0,0,0,16H240a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M54,136a6,6,0,0,1-6,6H24a6,6,0,0,1,0-12H34V62H24a6,6,0,0,1,0-12H40a6,6,0,0,1,5.1,2.8L80,108.7l34.9-55.9A6,6,0,0,1,120,50h16a6,6,0,0,1,0,12H126v68h10a6,6,0,0,1,0,12H112a6,6,0,0,1,0-12h2V76.9L85.1,123.2a6,6,0,0,1-10.2,0L46,76.9V130h2A6,6,0,0,1,54,136Zm114-26h72a6,6,0,0,0,0-12H168a6,6,0,0,0,0,12Zm72,20H168a6,6,0,0,0,0,12h72a6,6,0,0,0,0-12Zm0,32H72a6,6,0,0,0,0,12H240a6,6,0,0,0,0-12Zm0,32H72a6,6,0,0,0,0,12H240a6,6,0,0,0,0-12Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M52,136a4,4,0,0,1-4,4H24a4,4,0,0,1,0-8H36V60H24a4,4,0,0,1,0-8H40a4,4,0,0,1,3.4,1.9L80,112.5l36.6-58.6A4,4,0,0,1,120,52h16a4,4,0,0,1,0,8H124v72h12a4,4,0,0,1,0,8H112a4,4,0,0,1,0-8h4V69.9L83.4,122.1a4,4,0,0,1-6.8,0L44,69.9V132h4A4,4,0,0,1,52,136Zm116-28h72a4,4,0,0,0,0-8H168a4,4,0,0,0,0,8Zm72,24H168a4,4,0,0,0,0,8h72a4,4,0,0,0,0-8Zm0,32H72a4,4,0,0,0,0,8H240a4,4,0,0,0,0-8Zm0,32H72a4,4,0,0,0,0,8H240a4,4,0,0,0,0-8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M56,136a8,8,0,0,1-8,8H24a8,8,0,0,1,0-16h8V64H24a8,8,0,0,1,0-16H40a8,8,0,0,1,6.8,3.8L80,104.9l33.2-53.1A8,8,0,0,1,120,48h16a8,8,0,0,1,0,16h-8v64h8a8,8,0,0,1,0,16H112a8,8,0,0,1,0-16V83.9L86.8,124.2a8,8,0,0,1-13.6,0L48,83.9V128A8,8,0,0,1,56,136Zm112-24h72a8,8,0,0,0,0-16H168a8,8,0,0,0,0,16Zm72,16H168a8,8,0,0,0,0,16h72a8,8,0,0,0,0-16Zm0,32H72a8,8,0,0,0,0,16H240a8,8,0,0,0,0-16Zm0,32H72a8,8,0,0,0,0,16H240a8,8,0,0,0,0-16Z" />
        </>
      )}
    </svg>
  );
}

export function PhArticleNyTimes(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M164.1,88a12,12,0,0,1,12-12h64a12,12,0,1,1,0,24h-64A12,12,0,0,1,164.1,88Zm76,28h-64a12,12,0,0,0,0,24h64a12,12,0,1,0,0-24Zm0,40h-64a12,12,0,0,0,0,24h64a12,12,0,1,0,0-24Zm0,40H72.1a12,12,0,1,0,0,24h168a12,12,0,1,0,0-24ZM80,180a60,60,0,0,1-60-60,59.2,59.2,0,0,1,5.6-25.2A32,32,0,0,1,48,40a12.1,12.1,0,0,1,6.4,1.8l60.3,37.7A7.9,7.9,0,0,0,120,72a8,8,0,0,0-8-8,12,12,0,0,1,0-24,32,32,0,0,1,0,64,12.1,12.1,0,0,1-6.4-1.8L76.8,84.1A36,36,0,0,0,68,153.9V114.5a12,12,0,0,1,12-12h0a12,12,0,0,1,12,12V154a35.8,35.8,0,0,0,22.9-25.3,12,12,0,0,1,23.3,5.8A59.8,59.8,0,0,1,80,180ZM40,72a9.3,9.3,0,0,0,.5,2.9,59.2,59.2,0,0,1,10.1-7.1l-5.3-3.3A7.9,7.9,0,0,0,40,72Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M152.1,104a8,8,0,0,1,8-8h80a8,8,0,1,1,0,16h-80A8,8,0,0,1,152.1,104Zm88,24h-80a8,8,0,0,0,0,16h80a8,8,0,1,0,0-16Zm0,32h-80a8,8,0,0,0,0,16h80a8,8,0,1,0,0-16Zm0,32H72.1a8,8,0,0,0,0,16h168a8,8,0,1,0,0-16ZM80,176A55.9,55.9,0,0,1,30.5,93.9,28,28,0,0,1,48,44a7.6,7.6,0,0,1,4.2,1.2L114,83.8A12,12,0,0,0,112,60a8,8,0,0,1,0-16,28,28,0,0,1,0,56,7.6,7.6,0,0,1-4.2-1.2l-30-18.7A40,40,0,0,0,72,159.2V114.5a8,8,0,0,1,8-8h0a8,8,0,0,1,8,8v44.7a39.9,39.9,0,0,0,30.8-29.5,8,8,0,0,1,9.7-5.8,7.9,7.9,0,0,1,5.8,9.6A55.8,55.8,0,0,1,80,176ZM40,80.9A56.3,56.3,0,0,1,58.8,68.2L46,60.2A11.9,11.9,0,0,0,36,72,11.8,11.8,0,0,0,40,80.9Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M152.1,104a8,8,0,0,1,8-8h80a8,8,0,1,1,0,16h-80A8,8,0,0,1,152.1,104ZM80,176A55.9,55.9,0,0,1,30.5,93.9,28,28,0,0,1,48,44a7.6,7.6,0,0,1,4.2,1.2L114,83.8A12,12,0,0,0,112,60a8,8,0,0,1,0-16,28,28,0,0,1,0,56,7.6,7.6,0,0,1-4.2-1.2l-30-18.7A40,40,0,0,0,72,159.2V114.5a8,8,0,0,1,8-8h0a8,8,0,0,1,8,8v44.7a39.9,39.9,0,0,0,30.8-29.5,8,8,0,0,1,9.7-5.8,7.9,7.9,0,0,1,5.8,9.6A55.8,55.8,0,0,1,80,176ZM40,80.9A56.3,56.3,0,0,1,58.8,68.2L46,60.2A11.9,11.9,0,0,0,36,72,11.8,11.8,0,0,0,40,80.9ZM240.1,192H72.1a8,8,0,0,0,0,16h168a8,8,0,1,0,0-16Zm0-32h-80a8,8,0,0,0,0,16h80a8,8,0,1,0,0-16Zm0-32h-80a8,8,0,0,0,0,16h80a8,8,0,1,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M154.1,104a6,6,0,0,1,6-6h80a6,6,0,1,1,0,12h-80A6,6,0,0,1,154.1,104Zm86,26h-80a6,6,0,0,0,0,12h80a6,6,0,1,0,0-12Zm0,32h-80a6,6,0,0,0,0,12h80a6,6,0,1,0,0-12Zm0,32H72.1a6,6,0,0,0,0,12h168a6,6,0,1,0,0-12ZM80,174A53.9,53.9,0,0,1,33.1,93.3,26,26,0,0,1,48,46a6.2,6.2,0,0,1,3.2.9l62.4,39A14,14,0,0,0,112,58a6,6,0,0,1,0-12,26,26,0,0,1,0,52,6.2,6.2,0,0,1-3.2-.9L78.3,78A42,42,0,0,0,74,161.6V114.5a6,6,0,0,1,6-6h0a6,6,0,0,1,6,6v47.1a42,42,0,0,0,34.8-31.4,6,6,0,1,1,11.6,2.9A53.8,53.8,0,0,1,80,174ZM40.2,83.6a54.5,54.5,0,0,1,23.1-15L46.4,58.1a14,14,0,0,0-6.2,25.5Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M156.1,104a4,4,0,0,1,4-4h80a4,4,0,1,1,0,8h-80A4,4,0,0,1,156.1,104Zm84,28h-80a4,4,0,0,0,0,8h80a4,4,0,1,0,0-8Zm0,32h-80a4,4,0,0,0,0,8h80a4,4,0,1,0,0-8Zm0,32H72.1a4,4,0,0,0,0,8h168a4,4,0,1,0,0-8ZM80,172A51.9,51.9,0,0,1,35.8,92.7,24,24,0,0,1,48,48a3.9,3.9,0,0,1,2.1.6l63,39.4A16,16,0,0,0,112,56a4,4,0,0,1,0-8,24,24,0,0,1,0,48,3.9,3.9,0,0,1-2.1-.6L78.9,76a44,44,0,0,0-3,87.8V114.5a4,4,0,0,1,4-4h0a4,4,0,0,1,4,4v49.3a43.9,43.9,0,0,0,38.8-33.2,4.1,4.1,0,0,1,4.9-2.9,4.1,4.1,0,0,1,2.9,4.9A52,52,0,0,1,80,172ZM40.6,86.2A51.5,51.5,0,0,1,68.2,69.4L46.9,56a16,16,0,0,0-6.3,30.2Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M152.1,104a8,8,0,0,1,8-8h80a8,8,0,1,1,0,16h-80A8,8,0,0,1,152.1,104Zm88,24h-80a8,8,0,0,0,0,16h80a8,8,0,1,0,0-16Zm0,32h-80a8,8,0,0,0,0,16h80a8,8,0,1,0,0-16Zm0,32H72.1a8,8,0,0,0,0,16h168a8,8,0,1,0,0-16ZM80,176A55.9,55.9,0,0,1,30.5,93.9,28,28,0,0,1,48,44a7.6,7.6,0,0,1,4.2,1.2L114,83.8A12,12,0,0,0,112,60a8,8,0,0,1,0-16,28,28,0,0,1,0,56,7.6,7.6,0,0,1-4.2-1.2l-30-18.7A40,40,0,0,0,72,159.2V114.5a8,8,0,0,1,8-8h0a8,8,0,0,1,8,8v44.7a39.9,39.9,0,0,0,30.8-29.5,8,8,0,0,1,9.7-5.8,7.9,7.9,0,0,1,5.8,9.6A55.8,55.8,0,0,1,80,176ZM40,80.9A56.3,56.3,0,0,1,58.8,68.2L46,60.2A11.9,11.9,0,0,0,36,72,11.8,11.8,0,0,0,40,80.9Z" />
        </>
      )}
    </svg>
  );
}

export function PhArticle(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216,36H40A20.1,20.1,0,0,0,20,56V200a20.1,20.1,0,0,0,20,20H216a20.1,20.1,0,0,0,20-20V56A20.1,20.1,0,0,0,216,36Zm-4,160H44V60H212ZM64,92A12,12,0,0,1,76,80H180a12,12,0,0,1,0,24H76A12,12,0,0,1,64,92Zm0,36a12,12,0,0,1,12-12H180a12,12,0,0,1,0,24H76A12,12,0,0,1,64,128Zm0,36a12,12,0,0,1,12-12H180a12,12,0,0,1,0,24H76A12,12,0,0,1,64,164Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect x="32" y="48" width="192" height="160" rx="8" />
          </g>
          <path d="M216,40H40A16,16,0,0,0,24,56V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40Zm0,160H40V56H216V200Z" />
          <path d="M180,88H76a8,8,0,0,0,0,16H180a8,8,0,0,0,0-16Z" />
          <path d="M180,120H76a8,8,0,0,0,0,16H180a8,8,0,0,0,0-16Z" />
          <path d="M180,152H76a8,8,0,0,0,0,16H180a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,40H40A16,16,0,0,0,24,56V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40ZM180,168H76a8,8,0,0,1,0-16H180a8,8,0,0,1,0,16Zm0-32H76a8,8,0,0,1,0-16H180a8,8,0,0,1,0,16Zm0-32H76a8,8,0,0,1,0-16H180a8,8,0,0,1,0,16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M216,42H40A14,14,0,0,0,26,56V200a14,14,0,0,0,14,14H216a14,14,0,0,0,14-14V56A14,14,0,0,0,216,42Zm2,158a2,2,0,0,1-2,2H40a2,2,0,0,1-2-2V56a2,2,0,0,1,2-2H216a2,2,0,0,1,2,2ZM186,96a6,6,0,0,1-6,6H76a6,6,0,0,1,0-12H180A6,6,0,0,1,186,96Zm0,32a6,6,0,0,1-6,6H76a6,6,0,0,1,0-12H180A6,6,0,0,1,186,128Zm0,32a6,6,0,0,1-6,6H76a6,6,0,0,1,0-12H180A6,6,0,0,1,186,160Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M216,44H40A12,12,0,0,0,28,56V200a12,12,0,0,0,12,12H216a12,12,0,0,0,12-12V56A12,12,0,0,0,216,44Zm4,156a4,4,0,0,1-4,4H40a4,4,0,0,1-4-4V56a4,4,0,0,1,4-4H216a4,4,0,0,1,4,4ZM184,96a4,4,0,0,1-4,4H76a4,4,0,0,1,0-8H180A4,4,0,0,1,184,96Zm0,32a4,4,0,0,1-4,4H76a4,4,0,0,1,0-8H180A4,4,0,0,1,184,128Zm0,32a4,4,0,0,1-4,4H76a4,4,0,0,1,0-8H180A4,4,0,0,1,184,160Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,40H40A16,16,0,0,0,24,56V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40Zm0,160H40V56H216V200ZM188,96a8,8,0,0,1-8,8H76a8,8,0,0,1,0-16H180A8,8,0,0,1,188,96Zm0,32a8,8,0,0,1-8,8H76a8,8,0,0,1,0-16H180A8,8,0,0,1,188,128Zm0,32a8,8,0,0,1-8,8H76a8,8,0,0,1,0-16H180A8,8,0,0,1,188,160Z" />
        </>
      )}
    </svg>
  );
}

export function PhAsteriskSimple(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M215.4,112.2l-68,22.1,42,57.8a12.1,12.1,0,0,1-2.6,16.8,12.5,12.5,0,0,1-7.1,2.3,11.9,11.9,0,0,1-9.7-5l-42-57.8L86,206.2a11.9,11.9,0,0,1-9.7,5,12.5,12.5,0,0,1-7.1-2.3,12.1,12.1,0,0,1-2.6-16.8l42-57.8-68-22.1A12,12,0,0,1,48,89.4l68,22.1V40a12,12,0,0,1,24,0v71.5l68-22.1a12,12,0,0,1,7.4,22.8Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M214.2,108.4l-73.3,23.8,45.3,62.3a8,8,0,1,1-12.9,9.4L128,141.6,82.7,203.9a8,8,0,1,1-12.9-9.4l45.3-62.3L41.8,108.4a8,8,0,0,1,5-15.2L120,117V40a8,8,0,0,1,16,0v77l73.2-23.8a8,8,0,0,1,5,15.2Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M214.2,108.4l-73.3,23.8,45.3,62.3a8,8,0,1,1-12.9,9.4L128,141.6,82.7,203.9a8,8,0,1,1-12.9-9.4l45.3-62.3L41.8,108.4a8,8,0,0,1,5-15.2L120,117V40a8,8,0,0,1,16,0v77l73.2-23.8a8,8,0,0,1,5,15.2Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M213.5,106.5l-75.8,24.7,46.9,64.5a6,6,0,0,1-4.9,9.5,5.9,5.9,0,0,1-4.8-2.5L128,138.2,81.1,202.7a5.9,5.9,0,0,1-4.8,2.5,6,6,0,0,1-4.9-9.5l46.9-64.5L42.5,106.5A6,6,0,0,1,38.6,99a6,6,0,0,1,7.6-3.9L122,119.7V40a6,6,0,0,1,12,0v79.7l75.8-24.6a6,6,0,0,1,7.6,3.9A6,6,0,0,1,213.5,106.5Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M212.9,104.6l-78.4,25.5L183,196.8a4,4,0,0,1-.9,5.6,4.3,4.3,0,0,1-2.4.8,4,4,0,0,1-3.2-1.7L128,134.8,79.5,201.5a4,4,0,0,1-3.2,1.7,4.3,4.3,0,0,1-2.4-.8,4,4,0,0,1-.9-5.6l48.5-66.7L43.1,104.6A4,4,0,0,1,45.5,97L124,122.5V40a4,4,0,0,1,8,0v82.5L210.5,97a4,4,0,1,1,2.4,7.6Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M214.2,108.4l-73.3,23.8,45.3,62.3a8,8,0,1,1-12.9,9.4L128,141.6,82.7,203.9a8,8,0,1,1-12.9-9.4l45.3-62.3L41.8,108.4a8,8,0,0,1,5-15.2L120,117V40a8,8,0,0,1,16,0v77l73.2-23.8a8,8,0,0,1,5,15.2Z" />
        </>
      )}
    </svg>
  );
}

export function PhAsterisk(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M214.6,178a12,12,0,0,1-16.4,4.4L140,148.8V216a12,12,0,0,1-24,0V148.8L57.8,182.4a12,12,0,1,1-12-20.8L104,128,45.8,94.4a12,12,0,0,1,12-20.8L116,107.2V40a12,12,0,0,1,24,0v67.2l58.2-33.6a12,12,0,0,1,12,20.8L152,128l58.2,33.6A12.1,12.1,0,0,1,214.6,178Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M211.1,176a7.7,7.7,0,0,1-6.9,4,7.3,7.3,0,0,1-4-1.1l-64.2-37V216a8,8,0,0,1-16,0V141.9l-64.2,37a7.3,7.3,0,0,1-4,1.1,7.7,7.7,0,0,1-6.9-4,8,8,0,0,1,2.9-10.9L112,128,47.8,90.9a8,8,0,0,1,8-13.8l64.2,37V40a8,8,0,0,1,16,0v74.1l64.2-37a8,8,0,1,1,8,13.8L144,128l64.2,37.1A8,8,0,0,1,211.1,176Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M211.1,176a7.7,7.7,0,0,1-6.9,4,7.3,7.3,0,0,1-4-1.1l-64.2-37V216a8,8,0,0,1-16,0V141.9l-64.2,37a7.3,7.3,0,0,1-4,1.1,7.7,7.7,0,0,1-6.9-4,8,8,0,0,1,2.9-10.9L112,128,47.8,90.9a8,8,0,0,1,8-13.8l64.2,37V40a8,8,0,0,1,16,0v74.1l64.2-37a8,8,0,1,1,8,13.8L144,128l64.2,37.1A8,8,0,0,1,211.1,176Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M209.4,175a6,6,0,0,1-5.2,3,6.7,6.7,0,0,1-3-.8L134,138.4V216a6,6,0,0,1-12,0V138.4L54.8,177.2a6.7,6.7,0,0,1-3,.8,6,6,0,0,1-3-11.2L116,128,48.8,89.2a6,6,0,0,1,6-10.4L122,117.6V40a6,6,0,0,1,12,0v77.6l67.2-38.8a6,6,0,0,1,6,10.4L140,128l67.2,38.8A5.9,5.9,0,0,1,209.4,175Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M207.7,174a4.1,4.1,0,0,1-3.5,2,4.6,4.6,0,0,1-2-.5L132,134.9V216a4,4,0,0,1-8,0V134.9L53.8,175.5a4.6,4.6,0,0,1-2,.5,4,4,0,0,1-2-7.5L120,128,49.8,87.5a4,4,0,0,1,4-7L124,121.1V40a4,4,0,0,1,8,0v81.1l70.2-40.6a4,4,0,1,1,4,7L136,128l70.2,40.5A4.1,4.1,0,0,1,207.7,174Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M211.1,176a7.7,7.7,0,0,1-6.9,4,7.3,7.3,0,0,1-4-1.1l-64.2-37V216a8,8,0,0,1-16,0V141.9l-64.2,37a7.3,7.3,0,0,1-4,1.1,7.7,7.7,0,0,1-6.9-4,8,8,0,0,1,2.9-10.9L112,128,47.8,90.9a8,8,0,0,1,8-13.8l64.2,37V40a8,8,0,0,1,16,0v74.1l64.2-37a8,8,0,1,1,8,13.8L144,128l64.2,37.1A8,8,0,0,1,211.1,176Z" />
        </>
      )}
    </svg>
  );
}

export function PhAt(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,20a108,108,0,1,0,59.7,198,12,12,0,1,0-13.3-20A83,83,0,0,1,128,212a84,84,0,1,1,84-84c0,9.3-1.7,17.1-4.7,21.9s-6,6.1-11.3,6.1-8.7-1.8-11.3-6.1S180,137.3,180,128V88a12,12,0,0,0-23.5-3.5,52,52,0,1,0,8.9,79.6c6.9,10.2,17.4,15.9,30.6,15.9,24.7,0,40-19.9,40-52A108.1,108.1,0,0,0,128,20Zm0,136a28,28,0,1,1,28-28A28.1,28.1,0,0,1,128,156Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="128" cy="128" r="96" />
          </g>
          <path d="M128,24a104,104,0,1,0,57.5,190.7,8,8,0,0,0-8.8-13.4A88,88,0,1,1,216,128c0,26.4-10.9,32-20,32s-20-5.6-20-32V88a8,8,0,0,0-16,0v4.3A47.4,47.4,0,0,0,128,80a48,48,0,1,0,37.9,77.4c6,11.9,16.4,18.6,30.1,18.6,22.5,0,36-17.9,36-48A104.2,104.2,0,0,0,128,24Zm0,136a32,32,0,1,1,32-32A32.1,32.1,0,0,1,128,160Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M232,128v1.5c-.4,14.3-5.7,30.5-28,30.5s-28-17.4-28-32V88a8,8,0,0,0-8.5-8,8.2,8.2,0,0,0-7.5,8.3v4A47.4,47.4,0,0,0,128,80a48,48,0,1,0,38.7,76.3,43.9,43.9,0,0,0,3.4,5C175.5,168,185.7,176,204,176a52.5,52.5,0,0,0,9.2-.8,4,4,0,0,1,4.1,6A104,104,0,0,1,125.9,232c-54.8-1.1-99.7-45.2-101.8-99.9A104,104,0,1,1,232,128ZM96,128a32,32,0,1,0,32-32A32.1,32.1,0,0,0,96,128Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,26a102,102,0,1,0,56.4,187,6,6,0,0,0-6.6-10A90,90,0,1,1,218,128c0,29.6-13.8,34-22,34s-22-4.4-22-34V88a6,6,0,0,0-12,0v9a46,46,0,1,0,4.3,56.4C171.8,166.6,182,174,196,174c21.3,0,34-17.2,34-46A102.2,102.2,0,0,0,128,26Zm0,136a34,34,0,1,1,34-34A34,34,0,0,1,128,162Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,28a100,100,0,1,0,55.3,183.3,4,4,0,0,0,1.1-5.5,3.8,3.8,0,0,0-5.5-1.1A92,92,0,1,1,220,128c0,31.3-15,36-24,36s-24-4.7-24-36V88a4,4,0,0,0-8,0v14.7a44,44,0,1,0,2.8,46c4.7,14.9,14.9,23.3,29.2,23.3,20,0,32-16.4,32-44A100.2,100.2,0,0,0,128,28Zm0,136a36,36,0,1,1,36-36A36,36,0,0,1,128,164Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,24a104,104,0,1,0,57.5,190.7,8,8,0,0,0-8.8-13.4A88,88,0,1,1,216,128c0,26.4-10.9,32-20,32s-20-5.6-20-32V88a8,8,0,0,0-16,0v4.3A47.4,47.4,0,0,0,128,80a48,48,0,1,0,37.9,77.4c6,11.9,16.4,18.6,30.1,18.6,22.5,0,36-17.9,36-48A104.2,104.2,0,0,0,128,24Zm0,136a32,32,0,1,1,32-32A32.1,32.1,0,0,1,128,160Z" />
        </>
      )}
    </svg>
  );
}

export function PhAtom(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M201,128a193.9,193.9,0,0,0,17.9-31.1c11.9-26.2,11.7-47.3-.4-59.4s-33.2-12.3-59.4-.4A193.9,193.9,0,0,0,128,55,193.9,193.9,0,0,0,96.9,37.1c-26.2-11.9-47.3-11.7-59.4.4s-12.3,33.2-.4,59.4A193.9,193.9,0,0,0,55,128a193.9,193.9,0,0,0-17.9,31.1c-11.9,26.2-11.7,47.3.4,59.4,6.1,6.1,14.5,9.2,24.8,9.2s21.7-2.9,34.6-8.8A193.9,193.9,0,0,0,128,201a193.9,193.9,0,0,0,31.1,17.9c12.9,5.9,24.6,8.8,34.6,8.8s18.7-3.1,24.8-9.2c12.1-12.1,12.3-33.2.4-59.4A193.9,193.9,0,0,0,201,128Zm-7.2-75.8c3.7,0,6.3.8,7.7,2.3,3.5,3.5,3.5,14.9-4.4,32.6a182.5,182.5,0,0,1-11.5,20.8q-8.4-9.9-18-19.5C161.2,82,154.7,76,148,70.4,168,57.6,184.4,52.2,193.8,52.2ZM171,128a281.3,281.3,0,0,1-20.4,22.6A272.4,272.4,0,0,1,128,171.1a272.4,272.4,0,0,1-22.6-20.5A281.3,281.3,0,0,1,85,128a281.3,281.3,0,0,1,20.4-22.6A272.4,272.4,0,0,1,128,84.9a272.4,272.4,0,0,1,22.6,20.5A281.3,281.3,0,0,1,171,128ZM58.9,87.1C51,69.4,51,58,54.5,54.5c1.4-1.5,4-2.3,7.7-2.3,9.4,0,25.8,5.4,45.8,18.2C101.3,76,94.8,82,88.4,88.4s-12.4,12.9-18,19.5A182.5,182.5,0,0,1,58.9,87.1Zm3.3,116.7c-3.7,0-6.3-.8-7.7-2.3C51,198,51,186.6,58.9,168.9a182.5,182.5,0,0,1,11.5-20.8q8.4,9.9,18,19.5c6.4,6.4,12.9,12.4,19.6,18C88,198.4,71.6,203.8,62.2,203.8Zm139.3-2.3c-5.2,5.3-25.8,1.8-53.5-15.9,6.7-5.6,13.2-11.6,19.6-18s12.4-12.9,18-19.5a182.5,182.5,0,0,1,11.5,20.8C205,186.6,205,198,201.5,201.5ZM144,128a16,16,0,1,1-16-16A16,16,0,0,1,144,128Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M210,210c-17.2,17.2-67.8-5.6-113.1-50.9S28.8,63.2,46,46s67.8,5.6,113.1,50.9S227.2,192.8,210,210Z"
            opacity="0.2"
          />
          <path d="M196.1,128a195.3,195.3,0,0,0,19.2-32.7c11.1-24.6,11.2-44.2.4-55C198.2,22.9,162.5,35.4,128,60,93.5,35.4,57.8,22.9,40.3,40.3c-10.8,10.8-10.7,30.4.4,55A195.3,195.3,0,0,0,59.9,128a195.3,195.3,0,0,0-19.2,32.7c-11.1,24.6-11.2,44.2-.4,55,5.6,5.6,13.1,8.1,21.9,8.1,18.4,0,42.4-11.1,65.8-27.8,23.4,16.7,47.4,27.8,65.8,27.8,8.8,0,16.3-2.5,21.9-8.1,10.8-10.8,10.7-30.4-.4-55A195.3,195.3,0,0,0,196.1,128Zm-2.3-79.7c4.8,0,8.4,1.1,10.6,3.3,5.6,5.7,4.2,19.5-3.7,37.1a169.7,169.7,0,0,1-14.8,25.9c-6.4-7.9-13.5-15.7-21.1-23.4s-15.4-14.5-23.5-21.1C163.2,55.2,182,48.3,193.8,48.3ZM176.1,128a277.3,277.3,0,0,1-22.6,25.5A314.1,314.1,0,0,1,128,176.2a314.1,314.1,0,0,1-25.5-22.7A277.3,277.3,0,0,1,79.9,128a277.3,277.3,0,0,1,22.6-25.5A314.1,314.1,0,0,1,128,79.8a314.1,314.1,0,0,1,25.5,22.7A277.3,277.3,0,0,1,176.1,128ZM55.3,88.7C47.4,71.1,46,57.3,51.6,51.6c2.2-2.2,5.8-3.3,10.6-3.3,11.8,0,30.6,6.9,52.5,21.8-8.1,6.6-16,13.7-23.5,21.1s-14.7,15.5-21.1,23.4A169.7,169.7,0,0,1,55.3,88.7ZM51.6,204.4c-5.6-5.7-4.2-19.5,3.7-37.1a169.7,169.7,0,0,1,14.8-25.9c6.4,7.9,13.5,15.7,21.1,23.4s15.4,14.5,23.5,21.1C83.9,206.8,59.2,212,51.6,204.4Zm152.8,0c-2.2,2.2-5.8,3.3-10.6,3.3-11.8,0-30.6-6.9-52.5-21.8,8.1-6.6,16-13.7,23.5-21.1s14.7-15.5,21.1-23.4a169.7,169.7,0,0,1,14.8,25.9C208.6,184.9,210,198.7,204.4,204.4ZM140,128a12,12,0,1,1-12-12A12,12,0,0,1,140,128Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M196.1,128a195.3,195.3,0,0,0,19.2-32.7c11.1-24.6,11.2-44.2.4-55C198.2,22.9,162.5,35.4,128,60,93.5,35.4,57.8,22.9,40.3,40.3c-10.8,10.8-10.7,30.4.4,55A195.3,195.3,0,0,0,59.9,128a195.3,195.3,0,0,0-19.2,32.7c-11.1,24.6-11.2,44.2-.4,55,5.6,5.6,13.1,8.1,21.9,8.1,18.4,0,42.4-11.1,65.8-27.8,23.4,16.7,47.4,27.8,65.8,27.8,8.8,0,16.3-2.5,21.9-8.1,10.8-10.8,10.7-30.4-.4-55A195.3,195.3,0,0,0,196.1,128Zm-2.3-79.7c4.8,0,8.4,1.1,10.6,3.3,5.6,5.7,4.2,19.5-3.7,37.1a169.7,169.7,0,0,1-14.8,25.9c-6.4-7.9-13.5-15.7-21.1-23.4s-15.4-14.5-23.5-21.1C163.2,55.2,182,48.3,193.8,48.3ZM55.3,88.7C47.4,71.1,46,57.3,51.6,51.6c2.2-2.2,5.8-3.3,10.6-3.3,11.8,0,30.6,6.9,52.5,21.8-8.1,6.6-16,13.7-23.5,21.1s-14.7,15.5-21.1,23.4A169.7,169.7,0,0,1,55.3,88.7ZM51.6,204.4c-5.6-5.7-4.2-19.5,3.7-37.1a169.7,169.7,0,0,1,14.8-25.9c6.4,7.9,13.5,15.7,21.1,23.4s15.4,14.5,23.5,21.1C83.9,206.8,59.2,212,51.6,204.4Zm50.9-50.9A277.3,277.3,0,0,1,79.9,128a277.3,277.3,0,0,1,22.6-25.5A314.1,314.1,0,0,1,128,79.8a314.1,314.1,0,0,1,25.5,22.7A277.3,277.3,0,0,1,176.1,128a277.3,277.3,0,0,1-22.6,25.5A314.1,314.1,0,0,1,128,176.2,314.1,314.1,0,0,1,102.5,153.5Zm101.9,50.9c-2.2,2.2-5.8,3.3-10.6,3.3-11.8,0-30.6-6.9-52.5-21.8,8.1-6.6,16-13.7,23.5-21.1s14.7-15.5,21.1-23.4a169.7,169.7,0,0,1,14.8,25.9C208.6,184.9,210,198.7,204.4,204.4ZM140,128a12,12,0,1,1-12-12A12,12,0,0,1,140,128Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M193.6,128a197.3,197.3,0,0,0,19.9-33.5c10.7-23.8,11-42.6.8-52.8s-29-9.9-52.8.8A197.3,197.3,0,0,0,128,62.4,197.3,197.3,0,0,0,94.5,42.5c-23.8-10.7-42.6-11-52.8-.8s-9.9,29,.8,52.8A197.3,197.3,0,0,0,62.4,128a197.3,197.3,0,0,0-19.9,33.5c-10.7,23.8-11,42.6-.8,52.8,5,4.9,12,7.4,20.6,7.4s20-2.7,32.2-8.2A197.3,197.3,0,0,0,128,193.6a197.3,197.3,0,0,0,33.5,19.9c12.2,5.5,23.1,8.2,32.2,8.2s15.6-2.5,20.6-7.4c10.2-10.2,9.9-29-.8-52.8A197.3,197.3,0,0,0,193.6,128Zm12.2-77.8c6.2,6.3,5,21-3.3,39.3A173.4,173.4,0,0,1,186,118a309.6,309.6,0,0,0-22.6-25.4A309.6,309.6,0,0,0,138,70C169.6,47.9,196.5,41,205.8,50.2ZM178.7,128a308,308,0,0,1-23.8,26.9A312.9,312.9,0,0,1,128,178.7a312.9,312.9,0,0,1-26.9-23.8A308,308,0,0,1,77.3,128a308,308,0,0,1,23.8-26.9A312.9,312.9,0,0,1,128,77.3a312.9,312.9,0,0,1,26.9,23.8A308,308,0,0,1,178.7,128ZM53.5,89.5c-8.3-18.3-9.5-33-3.3-39.3,2.7-2.7,6.8-4,12.1-4,13.1,0,33.2,8,55.7,23.7A331,331,0,0,0,92.6,92.6,309.6,309.6,0,0,0,70,118,173.4,173.4,0,0,1,53.5,89.5ZM50.2,205.8c-6.2-6.3-5-21,3.3-39.3A173.4,173.4,0,0,1,70,138a309.6,309.6,0,0,0,22.6,25.4A309.6,309.6,0,0,0,118,186C86.4,208.1,59.5,215,50.2,205.8Zm155.6,0c-2.7,2.7-6.8,4-12.1,4-13.1,0-33.2-8-55.7-23.7a331,331,0,0,0,25.4-22.7A309.6,309.6,0,0,0,186,138a173.4,173.4,0,0,1,16.5,28.5C210.8,184.8,212,199.5,205.8,205.8ZM138,128a10,10,0,1,1-10-10A10,10,0,0,1,138,128Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M191.2,128c8.5-11.7,15.5-23.3,20.4-34.4,10.4-23,10.8-40.9,1.3-50.5s-27.5-9.1-50.5,1.3c-11.1,4.9-22.7,11.9-34.4,20.4-11.7-8.5-23.3-15.5-34.4-20.4C70.6,34,52.7,33.6,43.1,43.1S34,70.6,44.4,93.6c4.9,11.1,11.9,22.7,20.4,34.4-8.5,11.7-15.5,23.3-20.4,34.4-10.4,23-10.8,40.9-1.3,50.5,4.6,4.5,11.1,6.8,19.2,6.8s19.4-2.7,31.3-8.1,22.7-11.9,34.4-20.4c11.7,8.5,23.3,15.5,34.4,20.4s22.5,8.1,31.3,8.1,14.6-2.3,19.2-6.8c9.5-9.6,9.1-27.5-1.3-50.5C206.7,151.3,199.7,139.7,191.2,128Zm16-79.2c6.9,7,5.9,22.1-2.8,41.6a182.1,182.1,0,0,1-18.3,30.9,287.9,287.9,0,0,0-24.2-27.2,278.1,278.1,0,0,0-27.3-24.3C168.3,45.7,197,38.6,207.2,48.8Zm-26,79.2a291.7,291.7,0,0,1-24.9,28.3A307.9,307.9,0,0,1,128,181.2a307.9,307.9,0,0,1-28.3-24.9A291.7,291.7,0,0,1,74.8,128,291.7,291.7,0,0,1,99.7,99.7,307.9,307.9,0,0,1,128,74.8a307.9,307.9,0,0,1,28.3,24.9A291.7,291.7,0,0,1,181.2,128ZM51.6,90.4c-8.7-19.5-9.7-34.6-2.8-41.6,3-3,7.7-4.5,13.6-4.5,14.1,0,35.3,8.5,59,25.5A278.1,278.1,0,0,0,94.1,94.1a287.9,287.9,0,0,0-24.2,27.2A182.1,182.1,0,0,1,51.6,90.4ZM48.8,207.2c-6.9-7-5.9-22.1,2.8-41.6a182.1,182.1,0,0,1,18.3-30.9,287.9,287.9,0,0,0,24.2,27.2,278.1,278.1,0,0,0,27.3,24.3C87.7,210.3,59,217.4,48.8,207.2Zm158.4,0c-10.2,10.2-38.9,3.1-72.6-21a278.1,278.1,0,0,0,27.3-24.3,287.9,287.9,0,0,0,24.2-27.2,182.1,182.1,0,0,1,18.3,30.9C213.1,185.1,214.1,200.2,207.2,207.2ZM136,128a8,8,0,1,1-8-8A8,8,0,0,1,136,128Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M196.1,128a195.3,195.3,0,0,0,19.2-32.7c11.1-24.6,11.2-44.2.4-55C198.2,22.9,162.5,35.4,128,60,93.5,35.4,57.8,22.9,40.3,40.3c-10.8,10.8-10.7,30.4.4,55A195.3,195.3,0,0,0,59.9,128a195.3,195.3,0,0,0-19.2,32.7c-11.1,24.6-11.2,44.2-.4,55,5.6,5.6,13.1,8.1,21.9,8.1,18.4,0,42.4-11.1,65.8-27.8,23.4,16.7,47.4,27.8,65.8,27.8,8.8,0,16.3-2.5,21.9-8.1h0c10.8-10.8,10.7-30.4-.4-55A195.3,195.3,0,0,0,196.1,128Zm8.3-76.4c5.6,5.7,4.2,19.5-3.7,37.1a169.7,169.7,0,0,1-14.8,25.9c-6.4-7.9-13.5-15.7-21.1-23.4s-15.4-14.5-23.5-21.1C172.1,49.2,196.8,44,204.4,51.6ZM176.1,128a277.3,277.3,0,0,1-22.6,25.5A314.1,314.1,0,0,1,128,176.2a314.1,314.1,0,0,1-25.5-22.7A277.3,277.3,0,0,1,79.9,128a277.3,277.3,0,0,1,22.6-25.5A314.1,314.1,0,0,1,128,79.8a314.1,314.1,0,0,1,25.5,22.7A277.3,277.3,0,0,1,176.1,128ZM55.3,88.7C47.4,71.1,46,57.3,51.6,51.6c7.6-7.6,32.3-2.4,63.1,18.5-8.1,6.6-16,13.7-23.5,21.1s-14.7,15.5-21.1,23.4A169.7,169.7,0,0,1,55.3,88.7ZM51.6,204.4c-5.6-5.7-4.2-19.5,3.7-37.1a169.7,169.7,0,0,1,14.8-25.9c6.4,7.9,13.5,15.7,21.1,23.4s15.4,14.5,23.5,21.1C83.9,206.8,59.2,212,51.6,204.4Zm89.7-18.5c8.1-6.6,16-13.7,23.5-21.1s14.7-15.5,21.1-23.4a169.7,169.7,0,0,1,14.8,25.9c7.9,17.6,9.3,31.4,3.7,37.1C196.8,212,172.1,206.8,141.3,185.9ZM140,128a12,12,0,1,1-12-12A12,12,0,0,1,140,128Z" />
        </>
      )}
    </svg>
  );
}

export function PhBaby(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,20h-.2a108.1,108.1,0,1,0,.2,0Zm0,192A84,84,0,0,1,105.5,47.1C100.1,60.8,100,71.3,100,72a28.1,28.1,0,0,0,28,28,27.5,27.5,0,0,0,14-3.8,12,12,0,1,0-12-20.7,4.6,4.6,0,0,1-2,.5,4,4,0,0,1-4-3.9c0-.8.6-13.8,9.9-27.9A84,84,0,0,1,128,212ZM92,144a16,16,0,1,1,16-16A16,16,0,0,1,92,144Zm88-16a16,16,0,1,1-16-16A16,16,0,0,1,180,128Zm-15.5,33.4a11.9,11.9,0,0,1-3.3,16.6,60.1,60.1,0,0,1-66.4,0,12,12,0,0,1,13.3-20,36,36,0,0,0,39.8,0A11.9,11.9,0,0,1,164.5,161.4Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M224,128a96,96,0,1,1-96-96A96,96,0,0,1,224,128Z"
            opacity="0.2"
          />
          <path d="M128,24h-.1a104.1,104.1,0,1,0,.1,0Zm0,192A88,88,0,0,1,112.5,41.4C104.1,57.7,104,71.3,104,72a24,24,0,0,0,48,0,8,8,0,0,0-16,0,8,8,0,0,1-16,0c0-.1.3-15.6,11.9-31.9A88,88,0,0,1,128,216ZM92,140a12,12,0,1,1,12-12A12,12,0,0,1,92,140Zm84-12a12,12,0,1,1-12-12A12,12,0,0,1,176,128Zm-14.8,35.6a8.1,8.1,0,0,1-2.2,11.1,56.3,56.3,0,0,1-62,0,8.1,8.1,0,0,1-2.2-11.1,8,8,0,0,1,11.1-2.3,39.8,39.8,0,0,0,44.2,0A8,8,0,0,1,161.2,163.6Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M134.2,24.2a3.9,3.9,0,0,0-3.6,1.8C120.3,41.6,120,55.9,120,56a7.9,7.9,0,0,0,9.7,7.8c3.7-.7,6.2-4.2,6.3-8a8.1,8.1,0,0,1,8.8-7.8,8.2,8.2,0,0,1,7.2,8.3,24,24,0,0,1-48-.3c0-.6.1-10.8,5.4-24a4,4,0,0,0-4.6-5.4A104.1,104.1,0,0,0,24.1,131.7C26,186.8,71.2,231.1,126.3,232a104,104,0,0,0,7.9-207.8ZM80,128a12,12,0,1,1,12,12A12,12,0,0,1,80,128Zm79,46.7a56.3,56.3,0,0,1-62,0,8.1,8.1,0,0,1-2.2-11.1,8,8,0,0,1,11.1-2.3,39.8,39.8,0,0,0,44.2,0,8,8,0,0,1,11.1,2.3A8.1,8.1,0,0,1,159,174.7Zm5-34.7a12,12,0,1,1,12-12A12,12,0,0,1,164,140Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,26h-.1a102.1,102.1,0,1,0,.1,0Zm0,192A90,90,0,0,1,116.1,38.8C106.1,56.2,106,71.2,106,72a22,22,0,0,0,44,0,6,6,0,0,0-12,0,10,10,0,0,1-20,0c0-.1.3-16.8,12.9-33.9A90,90,0,0,1,128,218ZM92,138a10,10,0,1,1,10-10A10,10,0,0,1,92,138Zm82-10a10,10,0,1,1-10-10A10,10,0,0,1,174,128Zm-14.5,36.7a6,6,0,0,1-1.6,8.3,54.2,54.2,0,0,1-59.8,0,6,6,0,0,1,6.7-10,41.9,41.9,0,0,0,46.4,0A6,6,0,0,1,159.5,164.7Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,28A100,100,0,1,0,228,128,100.2,100.2,0,0,0,128,28Zm0,192a92,92,0,0,1-8-183.6C108.2,54.7,108,71.2,108,72a20,20,0,0,0,40,0,4,4,0,0,0-8,0,12,12,0,0,1-24,0c0-.2.3-17.9,13.9-36A92,92,0,0,1,128,220ZM92,136a8,8,0,1,1,8-8A8,8,0,0,1,92,136Zm80-8a8,8,0,1,1-8-8A8,8,0,0,1,172,128Zm-14.1,37.8a4,4,0,0,1-1.1,5.5,52,52,0,0,1-57.6,0,4,4,0,0,1-1.1-5.5,3.9,3.9,0,0,1,5.6-1.1,44.1,44.1,0,0,0,48.6,0A3.9,3.9,0,0,1,157.9,165.8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,24h-.1a104.1,104.1,0,1,0,.1,0Zm0,192A88,88,0,0,1,112.5,41.4C104.1,57.7,104,71.3,104,72a24,24,0,0,0,48,0,8,8,0,0,0-16,0,8,8,0,0,1-16,0c0-.1.3-15.6,11.9-31.9A88,88,0,0,1,128,216ZM92,140a12,12,0,1,1,12-12A12,12,0,0,1,92,140Zm84-12a12,12,0,1,1-12-12A12,12,0,0,1,176,128Zm-14.8,35.6a8.1,8.1,0,0,1-2.2,11.1,56.3,56.3,0,0,1-62,0,8.1,8.1,0,0,1-2.2-11.1,8,8,0,0,1,11.1-2.3,39.8,39.8,0,0,0,44.2,0A8,8,0,0,1,161.2,163.6Z" />
        </>
      )}
    </svg>
  );
}

export function PhBackpack(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M172,48.5V36A28.1,28.1,0,0,0,144,8H112A28.1,28.1,0,0,0,84,36V48.5A60.2,60.2,0,0,0,32,108V220a20.1,20.1,0,0,0,20,20H204a20.1,20.1,0,0,0,20-20V108A60.2,60.2,0,0,0,172,48.5ZM112,32h32a4,4,0,0,1,4,4V48H108V36A4,4,0,0,1,112,32Zm48,128H96v-8a4,4,0,0,1,4-4h56a4,4,0,0,1,4,4ZM96,184h32v4a12,12,0,0,0,24,0v-4h8v32H96Zm104,32H184V152a28.1,28.1,0,0,0-28-28H100a28.1,28.1,0,0,0-28,28v64H56V108A36,36,0,0,1,92,72h72a36,36,0,0,1,36,36ZM160,100a12,12,0,0,1-12,12H108a12,12,0,0,1,0-24h40A12,12,0,0,1,160,100Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M208,96V216a8,8,0,0,1-8,8H176V152a16,16,0,0,0-16-16H96a16,16,0,0,0-16,16v72H56a8,8,0,0,1-8-8V96A48,48,0,0,1,96,48h64A48,48,0,0,1,208,96Z"
            opacity="0.2"
          />
          <path d="M168,40.6V32A24.1,24.1,0,0,0,144,8H112A24.1,24.1,0,0,0,88,32v8.6A56,56,0,0,0,40,96V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V96A56,56,0,0,0,168,40.6ZM112,24h32a8,8,0,0,1,8,8v8H104V32A8,8,0,0,1,112,24Zm56,136H88v-8a8,8,0,0,1,8-8h64a8,8,0,0,1,8,8ZM88,176h48v8a8,8,0,0,0,16,0v-8h16v40H88Zm112,40H184V152a24.1,24.1,0,0,0-24-24H96a24.1,24.1,0,0,0-24,24v64H56V96A40,40,0,0,1,96,56h64a40,40,0,0,1,40,40V216ZM152,88a8,8,0,0,1-8,8H112a8,8,0,0,1,0-16h32A8,8,0,0,1,152,88Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M168,40.6V32A24.1,24.1,0,0,0,144,8H112A24.1,24.1,0,0,0,88,32v8.6A56,56,0,0,0,40,96V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V96A56,56,0,0,0,168,40.6ZM104,32a8,8,0,0,1,8-8h32a8,8,0,0,1,8,8v8H104Zm8,40h32a8,8,0,0,1,0,16H112a8,8,0,0,1,0-16Zm64,144H80V176h56v8a8,8,0,0,0,16,0v-8h24Zm0-56H80v-8a16,16,0,0,1,16-16h64a16,16,0,0,1,16,16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M166,42.3V32a22.1,22.1,0,0,0-22-22H112A22.1,22.1,0,0,0,90,32V42.3A54.1,54.1,0,0,0,42,96V216a14,14,0,0,0,14,14H200a14,14,0,0,0,14-14V96A54.1,54.1,0,0,0,166,42.3ZM112,22h32a10,10,0,0,1,10,10V42H102V32A10,10,0,0,1,112,22Zm58,140H86V152a10,10,0,0,1,10-10h64a10,10,0,0,1,10,10ZM86,174h52v10a6,6,0,0,0,12,0V174h20v44H86Zm116,42a2,2,0,0,1-2,2H182V152a22.1,22.1,0,0,0-22-22H96a22.1,22.1,0,0,0-22,22v66H56a2,2,0,0,1-2-2V96A42,42,0,0,1,96,54h64a42,42,0,0,1,42,42ZM150,88a6,6,0,0,1-6,6H112a6,6,0,0,1,0-12h32A6,6,0,0,1,150,88Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M164,44.2V32a20.1,20.1,0,0,0-20-20H112A20.1,20.1,0,0,0,92,32V44.2A52,52,0,0,0,44,96V216a12,12,0,0,0,12,12H200a12,12,0,0,0,12-12V96A52,52,0,0,0,164,44.2ZM112,20h32a12,12,0,0,1,12,12V44H100V32A12,12,0,0,1,112,20Zm60,144H84V152a12,12,0,0,1,12-12h64a12,12,0,0,1,12,12Zm-88,8h56v12a4,4,0,0,0,8,0V172h24v48H84Zm120,44a4,4,0,0,1-4,4H180V152a20.1,20.1,0,0,0-20-20H96a20.1,20.1,0,0,0-20,20v68H56a4,4,0,0,1-4-4V96A44,44,0,0,1,96,52h64a44,44,0,0,1,44,44ZM148,88a4,4,0,0,1-4,4H112a4,4,0,0,1,0-8h32A4,4,0,0,1,148,88Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M168,40.6V32A24.1,24.1,0,0,0,144,8H112A24.1,24.1,0,0,0,88,32v8.6A56,56,0,0,0,40,96V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V96A56,56,0,0,0,168,40.6ZM112,24h32a8,8,0,0,1,8,8v8H104V32A8,8,0,0,1,112,24Zm56,136H88v-8a8,8,0,0,1,8-8h64a8,8,0,0,1,8,8ZM88,176h48v8a8,8,0,0,0,16,0v-8h16v40H88Zm112,40H184V152a24.1,24.1,0,0,0-24-24H96a24.1,24.1,0,0,0-24,24v64H56V96A40,40,0,0,1,96,56h64a40,40,0,0,1,40,40V216ZM152,88a8,8,0,0,1-8,8H112a8,8,0,0,1,0-16h32A8,8,0,0,1,152,88Z" />
        </>
      )}
    </svg>
  );
}

export function PhBackspace(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216,36H68.5a20,20,0,0,0-17.1,9.7L5.7,121.8a12,12,0,0,0,0,12.4l45.7,76.1A20,20,0,0,0,68.5,220H216a20.1,20.1,0,0,0,20-20V56A20.1,20.1,0,0,0,216,36Zm-4,160H70.8L30,128,70.8,60H212ZM103.5,143.5,119,128l-15.5-15.5a12,12,0,0,1,17-17L136,111l15.5-15.5a12,12,0,0,1,17,17L153,128l15.5,15.5a12,12,0,0,1,0,17,12.1,12.1,0,0,1-17,0L136,145l-15.5,15.5a12.1,12.1,0,0,1-17,0A12,12,0,0,1,103.5,143.5Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M61.7,204.1,16,128,61.7,51.9A7.9,7.9,0,0,1,68.5,48H216a8,8,0,0,1,8,8V200a8,8,0,0,1-8,8H68.5A7.9,7.9,0,0,1,61.7,204.1Z" />
          </g>
          <path d="M216,40H68.5a16.2,16.2,0,0,0-13.7,7.8L9.2,123.9a7.6,7.6,0,0,0,0,8.2l45.6,76.1A16.2,16.2,0,0,0,68.5,216H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40ZM61.7,204.1l6.8-4.1h0ZM216,200H68.5L25.3,128,68.5,56H216Z" />
          <path d="M106.4,157.7A8,8,0,0,0,112,160a8.3,8.3,0,0,0,5.7-2.3L136,139.3l18.4,18.4A8,8,0,0,0,160,160a8.3,8.3,0,0,0,5.7-2.3,8.1,8.1,0,0,0,0-11.4L147.3,128l18.4-18.3a8.1,8.1,0,0,0,0-11.4,8,8,0,0,0-11.3,0L136,116.7,117.7,98.3a8,8,0,0,0-11.3,11.4L124.7,128l-18.3,18.3A8,8,0,0,0,106.4,157.7Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,40H68.5a16.2,16.2,0,0,0-13.7,7.8L9.1,123.9a8,8,0,0,0,0,8.2l45.7,76.1h0A16.2,16.2,0,0,0,68.5,216H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40ZM165.7,146.3a8.1,8.1,0,0,1,0,11.4A8.3,8.3,0,0,1,160,160a8.5,8.5,0,0,1-5.7-2.3L136,139.3l-18.3,18.4A8.3,8.3,0,0,1,112,160a8.5,8.5,0,0,1-5.7-2.3,8.1,8.1,0,0,1,0-11.4L124.7,128l-18.4-18.3a8.1,8.1,0,0,1,11.4-11.4L136,116.7l18.3-18.4a8.1,8.1,0,0,1,11.4,11.4L147.3,128Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M216,42H68.5a14,14,0,0,0-12,6.8L10.9,124.9a5.8,5.8,0,0,0,0,6.2l45.6,76.1a14,14,0,0,0,12,6.8H216a14,14,0,0,0,14-14V56A14,14,0,0,0,216,42Zm2,158a2,2,0,0,1-2,2H68.5a2.1,2.1,0,0,1-1.7-1h0L23,128,66.8,55a2.1,2.1,0,0,1,1.7-1H216a2,2,0,0,1,2,2Zm-53.8-91.8L144.5,128l19.7,19.8a5.9,5.9,0,1,1-8.4,8.4L136,136.5l-19.8,19.7a5.9,5.9,0,0,1-8.4-8.4L127.5,128l-19.7-19.8a5.9,5.9,0,0,1,8.4-8.4L136,119.5l19.8-19.7a5.9,5.9,0,0,1,8.4,8.4Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M216,44H68.5a12.1,12.1,0,0,0-10.3,5.8L12.6,125.9a4,4,0,0,0,0,4.2l45.6,76.1A12.1,12.1,0,0,0,68.5,212H216a12,12,0,0,0,12-12V56A12,12,0,0,0,216,44Zm4,156a4,4,0,0,1-4,4H68.5a3.9,3.9,0,0,1-3.4-1.9h0L20.7,128,65.1,53.9A3.9,3.9,0,0,1,68.5,52H216a4,4,0,0,1,4,4Zm-57.2-93.2L141.7,128l21.1,21.2a3.9,3.9,0,0,1,0,5.6A3.8,3.8,0,0,1,160,156a3.9,3.9,0,0,1-2.8-1.2L136,133.7l-21.2,21.1A3.8,3.8,0,0,1,112,156a3.9,3.9,0,0,1-2.8-1.2,3.9,3.9,0,0,1,0-5.6L130.3,128l-21.1-21.2a4,4,0,0,1,5.6-5.6L136,122.3l21.2-21.1a4,4,0,1,1,5.6,5.6Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,40H68.5a16.1,16.1,0,0,0-13.7,7.8L9.1,123.9a8,8,0,0,0,0,8.2l45.7,76.1A16.1,16.1,0,0,0,68.5,216H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40ZM61.7,204.1l6.8-4.1h0ZM216,200H68.5L25.3,128,68.5,56H216ZM106.3,146.3,124.7,128l-18.4-18.3a8.1,8.1,0,0,1,11.4-11.4L136,116.7l18.3-18.4a8.1,8.1,0,0,1,11.4,11.4L147.3,128l18.4,18.3a8.1,8.1,0,0,1,0,11.4A8.3,8.3,0,0,1,160,160a8.5,8.5,0,0,1-5.7-2.3L136,139.3l-18.3,18.4A8.3,8.3,0,0,1,112,160a8.5,8.5,0,0,1-5.7-2.3A8.1,8.1,0,0,1,106.3,146.3Z" />
        </>
      )}
    </svg>
  );
}

export function PhBagSimple(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216,60H178.6A52,52,0,0,0,77.4,60H40A20.1,20.1,0,0,0,20,80V208a20.1,20.1,0,0,0,20,20H216a20.1,20.1,0,0,0,20-20V80A20.1,20.1,0,0,0,216,60ZM128,44a28,28,0,0,1,25.3,16H102.7A28,28,0,0,1,128,44Zm84,160H44V84H212Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect x="32" y="72" width="192" height="144" rx="8" />
          </g>
          <path d="M216,64H175.3a48,48,0,0,0-94.6,0H40A16,16,0,0,0,24,80V208a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V80A16,16,0,0,0,216,64ZM128,40a32.1,32.1,0,0,1,31,24H97A32.1,32.1,0,0,1,128,40Zm88,168H40V80H216Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,64H175.3a48,48,0,0,0-94.6,0H40A16,16,0,0,0,24,80V208a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V80A16,16,0,0,0,216,64ZM128,40a32.1,32.1,0,0,1,31,24H97A32.1,32.1,0,0,1,128,40Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M216,66H173.6a46,46,0,0,0-91.2,0H40A14,14,0,0,0,26,80V208a14,14,0,0,0,14,14H216a14,14,0,0,0,14-14V80A14,14,0,0,0,216,66ZM128,38a34.1,34.1,0,0,1,33.5,28h-67A34.1,34.1,0,0,1,128,38Zm90,170a2,2,0,0,1-2,2H40a2,2,0,0,1-2-2V80a2,2,0,0,1,2-2H216a2,2,0,0,1,2,2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M216,68H171.8a44,44,0,0,0-87.6,0H40A12,12,0,0,0,28,80V208a12,12,0,0,0,12,12H216a12,12,0,0,0,12-12V80A12,12,0,0,0,216,68ZM128,36a36.1,36.1,0,0,1,35.8,32H92.2A36.1,36.1,0,0,1,128,36Zm92,172a4,4,0,0,1-4,4H40a4,4,0,0,1-4-4V80a4,4,0,0,1,4-4H216a4,4,0,0,1,4,4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,64H175.3a48,48,0,0,0-94.6,0H40A16,16,0,0,0,24,80V208a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V80A16,16,0,0,0,216,64ZM128,40a32.1,32.1,0,0,1,31,24H97A32.1,32.1,0,0,1,128,40Zm88,168H40V80H216Z" />
        </>
      )}
    </svg>
  );
}

export function PhBag(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216,60H178.6A52,52,0,0,0,77.4,60H40A20.1,20.1,0,0,0,20,80V208a20.1,20.1,0,0,0,20,20H216a20.1,20.1,0,0,0,20-20V80A20.1,20.1,0,0,0,216,60ZM128,44a28,28,0,0,1,25.3,16H102.7A28,28,0,0,1,128,44Zm84,160H44V84H76v20a12,12,0,0,0,24,0V84h56v20a12,12,0,0,0,24,0V84h32Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect x="32" y="72" width="192" height="144" rx="8" />
          </g>
          <path d="M216,64H175.3a48,48,0,0,0-94.6,0H40A16,16,0,0,0,24,80V208a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V80A16,16,0,0,0,216,64ZM128,40a32.1,32.1,0,0,1,31,24H97A32.1,32.1,0,0,1,128,40Zm88,168H40V80H80v24a8,8,0,0,0,16,0V80h64v24a8,8,0,0,0,16,0V80h40Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,64H175.3a48,48,0,0,0-94.6,0H40A16,16,0,0,0,24,80V208a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V80A16,16,0,0,0,216,64ZM96,104a8,8,0,0,1-16,0V88a8,8,0,0,1,16,0Zm1-40a32,32,0,0,1,62,0Zm79,40a8,8,0,0,1-16,0V88a8,8,0,0,1,16,0Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M216,66H173.6a46,46,0,0,0-91.2,0H40A14,14,0,0,0,26,80V208a14,14,0,0,0,14,14H216a14,14,0,0,0,14-14V80A14,14,0,0,0,216,66ZM128,38a34.1,34.1,0,0,1,33.5,28h-67A34.1,34.1,0,0,1,128,38Zm90,170a2,2,0,0,1-2,2H40a2,2,0,0,1-2-2V80a2,2,0,0,1,2-2H82v26a6,6,0,0,0,12,0V78h68v26a6,6,0,0,0,12,0V78h42a2,2,0,0,1,2,2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M216,68H171.8a44,44,0,0,0-87.6,0H40A12,12,0,0,0,28,80V208a12,12,0,0,0,12,12H216a12,12,0,0,0,12-12V80A12,12,0,0,0,216,68ZM128,36a36.1,36.1,0,0,1,35.8,32H92.2A36.1,36.1,0,0,1,128,36Zm92,172a4,4,0,0,1-4,4H40a4,4,0,0,1-4-4V80a4,4,0,0,1,4-4H84v28a4,4,0,0,0,8,0V76h72v28a4,4,0,0,0,8,0V76h44a4,4,0,0,1,4,4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,64H175.3a48,48,0,0,0-94.6,0H40A16,16,0,0,0,24,80V208a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V80A16,16,0,0,0,216,64ZM128,40a32.1,32.1,0,0,1,31,24H97A32.1,32.1,0,0,1,128,40Zm88,168H40V80H80v24a8,8,0,0,0,16,0V80h64v24a8,8,0,0,0,16,0V80h40Z" />
        </>
      )}
    </svg>
  );
}

export function PhBalloon(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,12a92.1,92.1,0,0,0-92,92c0,24.5,9.5,50.1,26.2,70.2C72,186,83.4,195,95.6,200.7L84.9,227.5A12,12,0,0,0,96,244h64a12,12,0,0,0,11.1-16.5l-10.7-26.8c12.2-5.7,23.6-14.7,33.4-26.5C210.5,154.1,220,128.5,220,104A92.1,92.1,0,0,0,128,12Zm14.3,208H113.7l5.1-12.6a70.8,70.8,0,0,0,18.4,0Zm33-61.1C161.9,175.1,145.1,184,128,184s-33.9-8.9-47.3-25.1S60,122.8,60,104a68,68,0,0,1,136,0C196,122.8,188.3,143.3,175.3,158.9Zm-2-70.9a12,12,0,0,1-7.3,15.3,11.6,11.6,0,0,1-4,.7,12.1,12.1,0,0,1-11.4-8A24,24,0,0,0,136,81.4a12,12,0,0,1,8-22.7A48.3,48.3,0,0,1,173.3,88Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M138.8,199h0L152,232H104l13.2-33h0C78.1,191.5,48,144.5,48,104a80,80,0,0,1,160,0C208,144.5,177.9,191.5,138.8,199Z"
            opacity="0.2"
          />
          <path d="M128,16a88.1,88.1,0,0,0-88,88c0,23.4,9.4,49.4,25.1,69.5,11.9,15.2,26.2,25.7,41.3,30.9L96.6,229a8,8,0,0,0,.8,7.5A7.9,7.9,0,0,0,104,240h48a7.9,7.9,0,0,0,6.6-3.5,8,8,0,0,0,.8-7.5l-9.8-24.6c15.1-5.2,29.4-15.7,41.3-30.9C206.6,153.4,216,127.4,216,104A88.1,88.1,0,0,0,128,16Zm12.2,208H115.8l6.5-16.3a54.3,54.3,0,0,0,11.4,0Zm38.1-60.4C163.9,181.9,146.1,192,128,192s-35.9-10.1-50.3-28.4S56,124,56,104a72,72,0,0,1,144,0C200,124,191.9,146.3,178.3,163.6Zm-1.6-59.7h-1.4a8.1,8.1,0,0,1-7.9-6.7,39.7,39.7,0,0,0-32.7-32.7,8.1,8.1,0,0,1-6.6-9.3,7.9,7.9,0,0,1,9.2-6.5,55.9,55.9,0,0,1,45.9,45.9A7.9,7.9,0,0,1,176.7,103.9Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,16a88.1,88.1,0,0,0-88,88c0,23.4,9.4,49.4,25.1,69.5,11.9,15.2,26.2,25.7,41.3,30.9L96.6,229a8,8,0,0,0,.8,7.5A7.9,7.9,0,0,0,104,240h48a7.9,7.9,0,0,0,6.6-3.5,8,8,0,0,0,.8-7.5l-9.8-24.6c15.1-5.2,29.4-15.7,41.3-30.9C206.6,153.4,216,127.4,216,104A88.1,88.1,0,0,0,128,16Zm48.7,87.9h-1.4a8.1,8.1,0,0,1-7.9-6.7,39.7,39.7,0,0,0-32.7-32.7,8.1,8.1,0,0,1-6.6-9.3,7.9,7.9,0,0,1,9.2-6.5,55.9,55.9,0,0,1,45.9,45.9A7.9,7.9,0,0,1,176.7,103.9Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,18a86.1,86.1,0,0,0-86,86c0,23,9.2,48.5,24.7,68.3,12.2,15.5,26.8,26.1,42.4,30.8L98.4,229.8a6.2,6.2,0,0,0,.6,5.6,6.1,6.1,0,0,0,5,2.6h48a6.1,6.1,0,0,0,5-2.6,6.2,6.2,0,0,0,.6-5.6l-10.7-26.7c15.6-4.7,30.2-15.3,42.4-30.8C204.8,152.5,214,127,214,104A86.1,86.1,0,0,0,128,18Zm15.1,208H112.9l8.1-20.4a61.4,61.4,0,0,0,14,0ZM128,194c-33.5,0-74-40.1-74-90a74,74,0,0,1,148,0C202,153.9,161.5,194,128,194Zm48.3-92.1h-1a6.1,6.1,0,0,1-5.9-5A41.8,41.8,0,0,0,135,62.6a6,6,0,0,1,2-11.9A54,54,0,0,1,181.3,95,6,6,0,0,1,176.3,101.9Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,20a84.1,84.1,0,0,0-84,84c0,22.6,9.1,47.6,24.3,67,12.5,16,27.5,26.5,43.5,30.8l-11.5,28.7a3.8,3.8,0,0,0,.4,3.7A4,4,0,0,0,104,236h48a4,4,0,0,0,3.3-1.8,3.8,3.8,0,0,0,.4-3.7l-11.5-28.7c16-4.3,31-14.8,43.5-30.8,15.2-19.4,24.3-44.4,24.3-67A84.1,84.1,0,0,0,128,20Zm18.1,208H109.9l9.8-24.6a57.7,57.7,0,0,0,16.6,0ZM128,196c-42.1,0-76-50.3-76-92a76,76,0,0,1,152,0C204,145.7,170.1,196,128,196ZM179.3,95.3a4,4,0,0,1-3.3,4.6h-.7a4,4,0,0,1-3.9-3.3,44,44,0,0,0-36.1-36.1,4,4,0,0,1,1.4-7.9A52.1,52.1,0,0,1,179.3,95.3Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,16a88.1,88.1,0,0,0-88,88c0,23.4,9.4,49.4,25.1,69.5,11.9,15.2,26.2,25.7,41.3,30.9L96.6,229a8,8,0,0,0,.8,7.5A7.9,7.9,0,0,0,104,240h48a7.9,7.9,0,0,0,6.6-3.5,8,8,0,0,0,.8-7.5l-9.8-24.6c15.1-5.2,29.4-15.7,41.3-30.9C206.6,153.4,216,127.4,216,104A88.1,88.1,0,0,0,128,16Zm12.2,208H115.8l6.5-16.3a54.3,54.3,0,0,0,11.4,0Zm38.1-60.4C163.9,181.9,146.1,192,128,192s-35.9-10.1-50.3-28.4S56,124,56,104a72,72,0,0,1,144,0C200,124,191.9,146.3,178.3,163.6Zm-1.6-59.7h-1.4a8.1,8.1,0,0,1-7.9-6.7,39.7,39.7,0,0,0-32.7-32.7,8.1,8.1,0,0,1-6.6-9.3,7.9,7.9,0,0,1,9.2-6.5,55.9,55.9,0,0,1,45.9,45.9A7.9,7.9,0,0,1,176.7,103.9Z" />
        </>
      )}
    </svg>
  );
}

export function PhBandaids(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M190.2,128l24.9-24.9a44,44,0,1,0-62.2-62.2L128,65.8,103.1,40.9a44,44,0,1,0-62.2,62.2L65.8,128,40.9,152.9a44,44,0,1,0,62.2,62.2L128,190.2l24.9,24.9a44,44,0,1,0,62.2-62.2ZM169.9,57.9h0a19.9,19.9,0,1,1,28.2,28.2L173.3,111,145,82.7ZM156.3,128,128,156.3,99.7,128,128,99.7ZM57.9,86.1A19.9,19.9,0,1,1,86.1,57.9L111,82.7,82.7,111Zm28.2,112a19.9,19.9,0,1,1-28.2-28.2L82.7,145,111,173.3Zm112,0a19.8,19.8,0,0,1-28.2,0L145,173.3,173.3,145l24.8,24.9a19.8,19.8,0,0,1,0,28.2Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M206.6,94.6a32,32,0,0,0-45.2-45.2L128,82.7,173.3,128Z" />
          </g>
          <g opacity="0.2">
            <path d="M49.4,161.4a32,32,0,0,0,45.2,45.2L128,173.3,82.7,128Z" />
          </g>
          <g opacity="0.2">
            <path d="M94.6,49.4A32,32,0,0,0,49.4,94.6L82.7,128,128,82.7Z" />
          </g>
          <g opacity="0.2">
            <path d="M173.3,128,128,173.3l33.4,33.3a32,32,0,0,0,45.2-45.2Z" />
          </g>
          <circle cx="128" cy="128" r="12" />
          <path d="M184.6,128l27.7-27.7a40,40,0,0,0-56.6-56.6L128,71.4,100.3,43.7a40,40,0,0,0-56.6,56.6L71.4,128,43.7,155.7a40,40,0,0,0,56.6,56.6L128,184.6l27.7,27.7a40,40,0,0,0,56.6-56.6ZM167,55a24,24,0,0,1,34,34l-27.7,27.7-34-34Zm-5.1,73L128,161.9,94.1,128,128,94.1ZM55,89h0A24,24,0,0,1,89,55l27.7,27.7-34,34ZM89,201a24,24,0,0,1-34-34l27.7-27.7,34,34Zm112,0a24.1,24.1,0,0,1-34,0l-27.7-27.7,34-34L201,167a24.1,24.1,0,0,1,0,34Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M116,128a12,12,0,1,1,12,12A12,12,0,0,1,116,128Zm96.3,27.7a40,40,0,0,1-56.6,56.6L128,184.6l-27.7,27.7a40,40,0,0,1-56.6-56.6L71.4,128,43.7,100.3a40,40,0,0,1,56.6-56.6L128,71.4l27.7-27.7a40,40,0,0,1,56.6,56.6L184.6,128ZM94.1,128,128,161.9,161.9,128,128,94.1Zm45.2-45.3,34,34L201,89a24,24,0,0,0-34-34Zm-22.6,90.6-34-34L55,167a24,24,0,0,0,34,34Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M181.7,128l29.2-29.1a38,38,0,0,0-53.8-53.8h0L128,74.3,98.9,45.1A38,38,0,0,0,45.1,98.9L74.3,128,45.1,157.1a38,38,0,0,0,53.8,53.8L128,181.7l29.1,29.2a38,38,0,0,0,53.8-53.8ZM165.6,53.6h0a26,26,0,0,1,36.8,36.8l-29.1,29.1L136.5,82.7Zm-.8,74.4L128,164.8,91.2,128,128,91.2ZM53.6,90.4A26,26,0,0,1,90.4,53.6l29.1,29.1L82.7,119.5Zm36.8,112a26,26,0,0,1-36.8-36.8l29.1-29.1,36.8,36.8Zm112,0a26.1,26.1,0,0,1-36.8,0l-29.1-29.1,36.8-36.8,29.1,29.1a26.1,26.1,0,0,1,0,36.8ZM118,128a10,10,0,1,1,10,10A10,10,0,0,1,118,128Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M178.9,128l30.6-30.5a36.1,36.1,0,0,0-51-51L128,77.1,97.5,46.5a36.1,36.1,0,0,0-51,51L77.1,128,46.5,158.5a36.1,36.1,0,0,0,51,51L128,178.9l30.5,30.6a36.1,36.1,0,0,0,51-51ZM164.2,52.2a28,28,0,0,1,39.6,39.6l-30.5,30.5L133.7,82.7Zm3.4,75.8L128,167.6,88.4,128,128,88.4ZM52.2,91.8A28,28,0,0,1,91.8,52.2l30.5,30.5L82.7,122.3Zm39.6,112a28,28,0,0,1-39.6-39.6l30.5-30.5,39.6,39.6Zm112,0a28,28,0,0,1-39.6,0l-30.5-30.5,39.6-39.6,30.5,30.5a28,28,0,0,1,0,39.6ZM120,128a8,8,0,1,1,8,8A8,8,0,0,1,120,128Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M116,128a12,12,0,1,1,12,12A12,12,0,0,1,116,128Zm96.3,27.7a40,40,0,0,1-56.6,56.6L128,184.6l-27.7,27.7a40,40,0,0,1-56.6-56.6L71.4,128,43.7,100.3a40,40,0,0,1,56.6-56.6L128,71.4l27.7-27.7a40,40,0,0,1,56.6,56.6L184.6,128Zm-73-73,34,34L201,89a24,24,0,0,0-34-34ZM128,94.1,94.1,128,128,161.9,161.9,128ZM82.7,116.7l34-34L89,55A24,24,0,0,0,55,89h0Zm34,56.6-34-34L55,167a24,24,0,0,0,34,34ZM208,184a24.2,24.2,0,0,0-7-17l-27.7-27.7-34,34L167,201a24,24,0,0,0,41-17Z" />
        </>
      )}
    </svg>
  );
}

export function PhBank(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M24,108H44v48H32a12,12,0,0,0,0,24H224a12,12,0,0,0,0-24H212V108h20a12,12,0,0,0,6.3-22.2l-104-64a11.9,11.9,0,0,0-12.6,0l-104,64A12,12,0,0,0,24,108Zm44,0H92v48H68Zm72,0v48H116V108Zm48,48H164V108h24ZM128,46.1,189.6,84H66.4ZM252,208a12,12,0,0,1-12,12H16a12,12,0,0,1,0-24H240A12,12,0,0,1,252,208Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <polygon points="24 96 232 96 128 32 24 96" />
          </g>
          <path d="M24,104H48v64H32a8,8,0,0,0,0,16H224a8,8,0,0,0,0-16H208V104h24a8,8,0,0,0,4.2-14.8l-104-64a7.9,7.9,0,0,0-8.4,0l-104,64A8,8,0,0,0,24,104Zm40,0H96v64H64Zm80,0v64H112V104Zm48,64H160V104h32ZM128,41.4,203.7,88H52.3ZM248,208a8,8,0,0,1-8,8H16a8,8,0,0,1,0-16H240A8,8,0,0,1,248,208Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M248,208a8,8,0,0,1-8,8H16a8,8,0,0,1,0-16H240A8,8,0,0,1,248,208ZM16.3,98.2a8,8,0,0,1,3.5-9l104-64a7.9,7.9,0,0,1,8.4,0l104,64A8,8,0,0,1,232,104H208v64h16a8,8,0,0,1,0,16H32a8,8,0,0,1,0-16H48V104H24A8,8,0,0,1,16.3,98.2ZM144,160a8,8,0,0,0,16,0V112a8,8,0,0,0-16,0Zm-48,0a8,8,0,0,0,16,0V112a8,8,0,0,0-16,0Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M24,102H50v68H32a6,6,0,0,0,0,12H224a6,6,0,0,0,0-12H206V102h26a6,6,0,0,0,3.1-11.1l-104-64a5.8,5.8,0,0,0-6.2,0l-104,64A6,6,0,0,0,24,102Zm38,0H98v68H62Zm84,0v68H110V102Zm48,68H158V102h36ZM128,39l82.8,51H45.2ZM246,208a6,6,0,0,1-6,6H16a6,6,0,0,1,0-12H240A6,6,0,0,1,246,208Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M24,100H52v72H32a4,4,0,0,0,0,8H224a4,4,0,0,0,0-8H204V100h28a3.9,3.9,0,0,0,3.8-2.9,4,4,0,0,0-1.7-4.5l-104-64a4,4,0,0,0-4.2,0l-104,64a4,4,0,0,0-1.7,4.5A3.9,3.9,0,0,0,24,100Zm36,0h40v72H60Zm88,0v72H108V100Zm48,72H156V100h40ZM128,36.7,217.9,92H38.1ZM244,208a4,4,0,0,1-4,4H16a4,4,0,0,1,0-8H240A4,4,0,0,1,244,208Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M24,104H48v64H32a8,8,0,0,0,0,16H224a8,8,0,0,0,0-16H208V104h24a8,8,0,0,0,4.2-14.8l-104-64a7.9,7.9,0,0,0-8.4,0l-104,64A8,8,0,0,0,24,104Zm40,0H96v64H64Zm80,0v64H112V104Zm48,64H160V104h32ZM128,41.4,203.7,88H52.3ZM248,208a8,8,0,0,1-8,8H16a8,8,0,0,1,0-16H240A8,8,0,0,1,248,208Z" />
        </>
      )}
    </svg>
  );
}

export function PhBarbell(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M244,116V88a20.1,20.1,0,0,0-20-20H208V64a20.1,20.1,0,0,0-20-20H164a20.1,20.1,0,0,0-20,20v52H112V64A20.1,20.1,0,0,0,92,44H68A20.1,20.1,0,0,0,48,64v4H32A20.1,20.1,0,0,0,12,88v28a12,12,0,0,0,0,24v28a20.1,20.1,0,0,0,20,20H48v4a20.1,20.1,0,0,0,20,20H92a20.1,20.1,0,0,0,20-20V140h32v52a20.1,20.1,0,0,0,20,20h24a20.1,20.1,0,0,0,20-20v-4h16a20.1,20.1,0,0,0,20-20V140a12,12,0,0,0,0-24ZM36,164V92H48v72Zm52,24H72V68H88Zm96,0H168V68h16Zm36-24H208V92h12Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect x="56" y="56" width="40" height="144" rx="8" />
          </g>
          <g opacity="0.2">
            <rect x="160" y="56" width="40" height="144" rx="8" />
          </g>
          <path d="M248,120h-8V88a16,16,0,0,0-16-16H208V64a16,16,0,0,0-16-16H168a16,16,0,0,0-16,16v56H104V64A16,16,0,0,0,88,48H64A16,16,0,0,0,48,64v8H32A16,16,0,0,0,16,88v32H8a8,8,0,0,0,0,16h8v32a16,16,0,0,0,16,16H48v8a16,16,0,0,0,16,16H88a16,16,0,0,0,16-16V136h48v56a16,16,0,0,0,16,16h24a16,16,0,0,0,16-16v-8h16a16,16,0,0,0,16-16V136h8a8,8,0,0,0,0-16ZM32,168V88H48v80Zm56,24H64V64H88v63.9h0V192Zm104,0H168V64h24V80h0V192Zm32-24H208V88h16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M248,120h-8V88a16,16,0,0,0-16-16H208V64a16,16,0,0,0-16-16H168a16,16,0,0,0-16,16v56H104V64A16,16,0,0,0,88,48H64A16,16,0,0,0,48,64v8H32A16,16,0,0,0,16,88v32H8a8,8,0,0,0,0,16h8v32a16,16,0,0,0,16,16H48v8a16,16,0,0,0,16,16H88a16,16,0,0,0,16-16V136h48v56a16,16,0,0,0,16,16h24a16,16,0,0,0,16-16v-8h16a16,16,0,0,0,16-16V136h8a8,8,0,0,0,0-16ZM32,168V88H48v80Zm192,0H208V88h16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M248,122H238V88a14,14,0,0,0-14-14H206V64a14,14,0,0,0-14-14H168a14,14,0,0,0-14,14v58H102V64A14,14,0,0,0,88,50H64A14,14,0,0,0,50,64V74H32A14,14,0,0,0,18,88v34H8a6,6,0,0,0,0,12H18v34a14,14,0,0,0,14,14H50v10a14,14,0,0,0,14,14H88a14,14,0,0,0,14-14V134h52v58a14,14,0,0,0,14,14h24a14,14,0,0,0,14-14V182h18a14,14,0,0,0,14-14V134h10a6,6,0,0,0,0-12ZM32,170a2,2,0,0,1-2-2V88a2,2,0,0,1,2-2H50v84Zm58,22a2,2,0,0,1-2,2H64a2,2,0,0,1-2-2V64a2,2,0,0,1,2-2H88a2,2,0,0,1,2,2Zm104,0a2,2,0,0,1-2,2H168a2,2,0,0,1-2-2V64a2,2,0,0,1,2-2h24a2,2,0,0,1,2,2Zm32-24a2,2,0,0,1-2,2H206V86h18a2,2,0,0,1,2,2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M248,124H236V88a12,12,0,0,0-12-12H204V64a12,12,0,0,0-12-12H168a12,12,0,0,0-12,12v60H100V64A12,12,0,0,0,88,52H64A12,12,0,0,0,52,64V76H32A12,12,0,0,0,20,88v36H8a4,4,0,0,0,0,8H20v36a12,12,0,0,0,12,12H52v12a12,12,0,0,0,12,12H88a12,12,0,0,0,12-12V132h56v60a12,12,0,0,0,12,12h24a12,12,0,0,0,12-12V180h20a12,12,0,0,0,12-12V132h12a4,4,0,0,0,0-8ZM32,172a4,4,0,0,1-4-4V88a4,4,0,0,1,4-4H52v88Zm60,20a4,4,0,0,1-4,4H64a4,4,0,0,1-4-4V64a4,4,0,0,1,4-4H88a4,4,0,0,1,4,4Zm104,0a4,4,0,0,1-4,4H168a4,4,0,0,1-4-4V64a4,4,0,0,1,4-4h24a4,4,0,0,1,4,4Zm32-24a4,4,0,0,1-4,4H204V84h20a4,4,0,0,1,4,4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M248,120h-8V88a16,16,0,0,0-16-16H208V64a16,16,0,0,0-16-16H168a16,16,0,0,0-16,16v56H104V64A16,16,0,0,0,88,48H64A16,16,0,0,0,48,64v8H32A16,16,0,0,0,16,88v32H8a8,8,0,0,0,0,16h8v32a16,16,0,0,0,16,16H48v8a16,16,0,0,0,16,16H88a16,16,0,0,0,16-16V136h48v56a16,16,0,0,0,16,16h24a16,16,0,0,0,16-16v-8h16a16,16,0,0,0,16-16V136h8a8,8,0,0,0,0-16ZM32,168V88H48v80Zm56,24H64V64H88v63.9h0V192Zm104,0H168V64h24V80h0V192Zm32-24H208V88h16Z" />
        </>
      )}
    </svg>
  );
}

export function PhBarcode(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M232,52V92a12,12,0,0,1-24,0V64H180a12,12,0,0,1,0-24h40A12,12,0,0,1,232,52ZM76,192H48V164a12,12,0,0,0-24,0v40a12,12,0,0,0,12,12H76a12,12,0,0,0,0-24Zm144-40a12,12,0,0,0-12,12v28H180a12,12,0,0,0,0,24h40a12,12,0,0,0,12-12V164A12,12,0,0,0,220,152ZM36,104A12,12,0,0,0,48,92V64H76a12,12,0,0,0,0-24H36A12,12,0,0,0,24,52V92A12,12,0,0,0,36,104ZM88,80A12,12,0,0,0,76,92v72a12,12,0,0,0,24,0V92A12,12,0,0,0,88,80Zm92,84V92a12,12,0,0,0-24,0v72a12,12,0,0,0,24,0ZM128,80a12,12,0,0,0-12,12v72a12,12,0,0,0,24,0V92A12,12,0,0,0,128,80Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M224,40H184a8,8,0,0,0,0,16h32V88a8,8,0,0,0,16,0V48A8,8,0,0,0,224,40Z" />
          <path d="M72,200H40V168a8,8,0,0,0-16,0v40a8,8,0,0,0,8,8H72a8,8,0,0,0,0-16Z" />
          <path d="M224,160a8,8,0,0,0-8,8v32H184a8,8,0,0,0,0,16h40a8,8,0,0,0,8-8V168A8,8,0,0,0,224,160Z" />
          <path d="M32,96a8,8,0,0,0,8-8V56H72a8,8,0,0,0,0-16H32a8,8,0,0,0-8,8V88A8,8,0,0,0,32,96Z" />
          <path d="M80,80a8,8,0,0,0-8,8v80a8,8,0,0,0,16,0V88A8,8,0,0,0,80,80Z" />
          <path d="M184,168V88a8,8,0,0,0-16,0v80a8,8,0,0,0,16,0Z" />
          <path d="M144,80a8,8,0,0,0-8,8v80a8,8,0,0,0,16,0V88A8,8,0,0,0,144,80Z" />
          <path d="M112,80a8,8,0,0,0-8,8v80a8,8,0,0,0,16,0V88A8,8,0,0,0,112,80Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M232,48V88a8,8,0,0,1-16,0V56H184a8,8,0,0,1,0-16h40A8,8,0,0,1,232,48ZM72,200H40V168a8,8,0,0,0-16,0v40a8,8,0,0,0,8,8H72a8,8,0,0,0,0-16Zm152-40a8,8,0,0,0-8,8v32H184a8,8,0,0,0,0,16h40a8,8,0,0,0,8-8V168A8,8,0,0,0,224,160ZM32,96a8,8,0,0,0,8-8V56H72a8,8,0,0,0,0-16H32a8,8,0,0,0-8,8V88A8,8,0,0,0,32,96ZM80,80a8,8,0,0,0-8,8v80a8,8,0,0,0,16,0V88A8,8,0,0,0,80,80Zm104,88V88a8,8,0,0,0-16,0v80a8,8,0,0,0,16,0ZM144,80a8,8,0,0,0-8,8v80a8,8,0,0,0,16,0V88A8,8,0,0,0,144,80Zm-32,0a8,8,0,0,0-8,8v80a8,8,0,0,0,16,0V88A8,8,0,0,0,112,80Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M230,48V88a6,6,0,0,1-12,0V54H184a6,6,0,0,1,0-12h40A6,6,0,0,1,230,48ZM72,202H38V168a6,6,0,0,0-12,0v40a6,6,0,0,0,6,6H72a6,6,0,0,0,0-12Zm152-40a6,6,0,0,0-6,6v34H184a6,6,0,0,0,0,12h40a6,6,0,0,0,6-6V168A6,6,0,0,0,224,162ZM32,94a6,6,0,0,0,6-6V54H72a6,6,0,0,0,0-12H32a6,6,0,0,0-6,6V88A6,6,0,0,0,32,94ZM80,82a6,6,0,0,0-6,6v80a6,6,0,0,0,12,0V88A6,6,0,0,0,80,82Zm102,86V88a6,6,0,0,0-12,0v80a6,6,0,0,0,12,0ZM144,82a6,6,0,0,0-6,6v80a6,6,0,0,0,12,0V88A6,6,0,0,0,144,82Zm-32,0a6,6,0,0,0-6,6v80a6,6,0,0,0,12,0V88A6,6,0,0,0,112,82Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M228,48V88a4,4,0,0,1-8,0V52H184a4,4,0,0,1,0-8h40A4,4,0,0,1,228,48ZM72,204H36V168a4,4,0,0,0-8,0v40a4,4,0,0,0,4,4H72a4,4,0,0,0,0-8Zm152-40a4,4,0,0,0-4,4v36H184a4,4,0,0,0,0,8h40a4,4,0,0,0,4-4V168A4,4,0,0,0,224,164ZM32,92a4,4,0,0,0,4-4V52H72a4,4,0,0,0,0-8H32a4,4,0,0,0-4,4V88A4,4,0,0,0,32,92Zm48-8a4,4,0,0,0-4,4v80a4,4,0,0,0,8,0V88A4,4,0,0,0,80,84Zm100,4a4,4,0,0,0-8,0v80a4,4,0,0,0,8,0Zm-36-4a4,4,0,0,0-4,4v80a4,4,0,0,0,8,0V88A4,4,0,0,0,144,84Zm-32,0a4,4,0,0,0-4,4v80a4,4,0,0,0,8,0V88A4,4,0,0,0,112,84Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M232,48V88a8,8,0,0,1-16,0V56H184a8,8,0,0,1,0-16h40A8,8,0,0,1,232,48ZM72,200H40V168a8,8,0,0,0-16,0v40a8,8,0,0,0,8,8H72a8,8,0,0,0,0-16Zm152-40a8,8,0,0,0-8,8v32H184a8,8,0,0,0,0,16h40a8,8,0,0,0,8-8V168A8,8,0,0,0,224,160ZM32,96a8,8,0,0,0,8-8V56H72a8,8,0,0,0,0-16H32a8,8,0,0,0-8,8V88A8,8,0,0,0,32,96ZM80,80a8,8,0,0,0-8,8v80a8,8,0,0,0,16,0V88A8,8,0,0,0,80,80Zm104,88V88a8,8,0,0,0-16,0v80a8,8,0,0,0,16,0ZM144,80a8,8,0,0,0-8,8v80a8,8,0,0,0,16,0V88A8,8,0,0,0,144,80Zm-32,0a8,8,0,0,0-8,8v80a8,8,0,0,0,16,0V88A8,8,0,0,0,112,80Z" />
        </>
      )}
    </svg>
  );
}

export function PhBarricade(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M224,60H32A20.1,20.1,0,0,0,12,80v72a20.1,20.1,0,0,0,20,20H52v28a12,12,0,0,0,24,0V172H180v28a12,12,0,0,0,24,0V172h20a20.1,20.1,0,0,0,20-20V80A20.1,20.1,0,0,0,224,60Zm-4,59L185,84h35Zm-43,29L113,84h38l64,64Zm-72,0L41,84H79l64,64ZM36,113l35,35H36Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M232,80v68L156,72h68A8,8,0,0,1,232,80ZM32,72a8,8,0,0,0-8,8v4l76,76h72L84,72Z"
            opacity="0.2"
          />
          <path d="M240,80a16,16,0,0,0-16-16H32A16,16,0,0,0,16,80v72a16,16,0,0,0,16,16H56v32a8,8,0,0,0,16,0V168H184v32a8,8,0,0,0,16,0V168h24a16,16,0,0,0,16-16Zm-16,48.7L175.3,80H224ZM80.7,80l72,72H103.3L32,80.7V80ZM32,103.3,80.7,152H32ZM224,152H175.3l-72-72h49.4L224,151.3v.7Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M224,64H32A16,16,0,0,0,16,80v72a16,16,0,0,0,16,16H56v32a8,8,0,0,0,16,0V168H184v32a8,8,0,0,0,16,0V168h24a16,16,0,0,0,16-16V80A16,16,0,0,0,224,64ZM32,92l60,60H32Zm192,60H164L92,80h72l60,60v12Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M224,66H32A14,14,0,0,0,18,80v72a14,14,0,0,0,14,14H58v34a6,6,0,0,0,12,0V166H186v34a6,6,0,0,0,12,0V166h26a14,14,0,0,0,14-14V80A14,14,0,0,0,224,66Zm2,14v53.5L170.5,78H224A2,2,0,0,1,226,80ZM32,78H81.5l76,76h-55L30,81.5V80A2,2,0,0,1,32,78Zm-2,74V98.5L85.5,154H32A2,2,0,0,1,30,152Zm194,2H174.5l-76-76h55L226,150.5V152A2,2,0,0,1,224,154Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M224,68H32A12,12,0,0,0,20,80v72a12,12,0,0,0,12,12H60v36a4,4,0,0,0,8,0V164H188v36a4,4,0,0,0,8,0V164h28a12,12,0,0,0,12-12V80A12,12,0,0,0,224,68Zm4,12v58.3L165.7,76H224A4,4,0,0,1,228,80ZM32,76H82.3l80,80H101.7L28,82.3V80A4,4,0,0,1,32,76Zm-4,76V93.7L90.3,156H32A4,4,0,0,1,28,152Zm196,4H173.7l-80-80h60.6L228,149.7V152A4,4,0,0,1,224,156Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M224,64H32A16,16,0,0,0,16,80v72a16,16,0,0,0,16,16H56v32a8,8,0,0,0,16,0V168H184v32a8,8,0,0,0,16,0V168h24a16,16,0,0,0,16-16V80A16,16,0,0,0,224,64Zm0,64.7L175.3,80H224ZM80.7,80l72,72H103.3L32,80.7V80ZM32,103.3,80.7,152H32ZM224,152H175.3l-72-72h49.4L224,151.3v.7Z" />
        </>
      )}
    </svg>
  );
}

export function PhBaseball(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,20A108,108,0,1,0,236,128,108.1,108.1,0,0,0,128,20ZM77,194.7a110.4,110.4,0,0,0,8.2-12,12,12,0,0,0-20.7-12.2c-1.4,2.3-2.9,4.6-4.5,6.8a84,84,0,0,1,0-98.6c1.6,2.2,3.1,4.5,4.5,6.8a11.9,11.9,0,0,0,10.3,5.9,11.3,11.3,0,0,0,6.1-1.7,12,12,0,0,0,4.3-16.4,110.4,110.4,0,0,0-8.2-12,83.8,83.8,0,0,1,102,0,110.4,110.4,0,0,0-8.2,12,12,12,0,0,0,4.3,16.4,11.3,11.3,0,0,0,6.1,1.7,11.9,11.9,0,0,0,10.3-5.9c1.4-2.3,2.9-4.6,4.5-6.8a84,84,0,0,1,0,98.6c-1.6-2.2-3.1-4.5-4.5-6.8a12,12,0,0,0-20.7,12.2,110.4,110.4,0,0,0,8.2,12,83.8,83.8,0,0,1-102,0Zm93.2-42.8h-1.5a12.1,12.1,0,0,1-11.9-10.5,114.3,114.3,0,0,1,0-27,12,12,0,0,1,23.9,3,79.1,79.1,0,0,0,0,21A12,12,0,0,1,170.2,151.9Zm-71-37.4A122.2,122.2,0,0,1,100,128a122.2,122.2,0,0,1-.8,13.5A12.1,12.1,0,0,1,87.3,152H85.8a12,12,0,0,1-10.5-13.4A73.1,73.1,0,0,0,76,128a92.4,92.4,0,0,0-.6-10.5,11.9,11.9,0,0,1,10.4-13.4A12.1,12.1,0,0,1,99.2,114.5Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M59.9,195.8Zm0-135.5h0a96,96,0,0,0,0,135.5,95.8,95.8,0,0,0,28-67.8A95.5,95.5,0,0,0,59.9,60.3Zm136,135.5h0Zm0-135.5h0a96,96,0,0,0,0,135.5,96,96,0,0,0,0-135.5Z"
            opacity="0.2"
          />
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24ZM71.3,195.3c1.5-1.8,3-3.6,4.3-5.5A8,8,0,0,0,74,178.6a8.1,8.1,0,0,0-11.2,1.7c-.9,1.2-1.8,2.4-2.8,3.5A87.8,87.8,0,0,1,60,72.2c1,1.1,1.9,2.3,2.8,3.5a7.9,7.9,0,0,0,6.4,3.2A8.5,8.5,0,0,0,74,77.4a8,8,0,0,0,1.6-11.2c-1.3-1.9-2.8-3.7-4.3-5.5a88,88,0,0,1,113.4,0c-1.5,1.8-3,3.6-4.3,5.5A8,8,0,0,0,182,77.4a8.5,8.5,0,0,0,4.8,1.5,7.9,7.9,0,0,0,6.4-3.2c.9-1.2,1.8-2.4,2.8-3.5a87.8,87.8,0,0,1,0,111.6c-1-1.1-1.9-2.3-2.8-3.5a8.1,8.1,0,0,0-11.2-1.7,8,8,0,0,0-1.6,11.2c1.3,1.9,2.8,3.7,4.3,5.5a88,88,0,0,1-113.4,0Zm90-83.5a109.5,109.5,0,0,1,4.1-16.9,8,8,0,0,1,15.2,5.1,80.6,80.6,0,0,0-3.5,14.3,8.1,8.1,0,0,1-7.9,6.7h-1.3A8,8,0,0,1,161.3,111.8Zm14.2,54.3a7.3,7.3,0,0,1-2.5.4,7.9,7.9,0,0,1-7.6-5.5,105.6,105.6,0,0,1-4.1-16.8,8,8,0,0,1,15.8-2.5,80.6,80.6,0,0,0,3.5,14.3A8,8,0,0,1,175.5,166.1ZM75.4,100a8,8,0,0,1,15.2-5.1,109.5,109.5,0,0,1,4.1,16.9,8,8,0,0,1-6.6,9.1H86.8a8.1,8.1,0,0,1-7.9-6.7A80.6,80.6,0,0,0,75.4,100Zm19.3,44.2A105.6,105.6,0,0,1,90.6,161a7.9,7.9,0,0,1-7.6,5.5,7.3,7.3,0,0,1-2.5-.4A8,8,0,0,1,75.4,156a80.6,80.6,0,0,0,3.5-14.3,8,8,0,0,1,15.8,2.5Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M51.9,63.9a4,4,0,0,0-6,.3,103.9,103.9,0,0,0,0,127.6,4,4,0,0,0,6,.3l7.3-7.3a8,8,0,0,1,6.2-2.8,7.9,7.9,0,0,1,5.2,2,8.2,8.2,0,0,1,.6,11.4,99.9,99.9,0,0,1-7.8,8.2,3.9,3.9,0,0,0,.2,6,103.8,103.8,0,0,0,128.8,0,3.9,3.9,0,0,0,.2-6,103.3,103.3,0,0,1-7.9-8.3,8,8,0,1,1,12.1-10.5l7.3,7.3a4,4,0,0,0,6-.3,103.9,103.9,0,0,0,0-127.6,4,4,0,0,0-6-.3l-7.3,7.3a8.1,8.1,0,0,1-6.1,2.8,7.6,7.6,0,0,1-5.3-2,8.2,8.2,0,0,1-.6-11.4,99.9,99.9,0,0,1,7.8-8.2,3.9,3.9,0,0,0-.2-6,103.8,103.8,0,0,0-128.8,0,3.9,3.9,0,0,0-.2,6,99.9,99.9,0,0,1,7.8,8.2A8,8,0,0,1,65.3,74h0a8.1,8.1,0,0,1-6.1-2.8ZM79.2,88.2a9.6,9.6,0,0,1,2.8-.7,8.1,8.1,0,0,1,7.9,5.3,105.7,105.7,0,0,1,4.4,16.5,8,8,0,0,1-5.9,9.4,6.3,6.3,0,0,1-1.9.3h0a8,8,0,0,1-7.9-6.6,83.8,83.8,0,0,0-3.7-13.9C73.5,94.5,75.2,89.8,79.2,88.2Zm15.1,58.5a105.7,105.7,0,0,1-4.4,16.5,8.1,8.1,0,0,1-7.6,5.3h0a7,7,0,0,1-3.1-.7,8.2,8.2,0,0,1-4.3-10.3,83.8,83.8,0,0,0,3.7-13.9,8.1,8.1,0,0,1,7.7-6.6l2,.2A8.2,8.2,0,0,1,94.3,146.7Zm82.5,21.1a7,7,0,0,1-3.1.7,8.1,8.1,0,0,1-7.6-5.3,105.7,105.7,0,0,1-4.4-16.5,8.2,8.2,0,0,1,5.4-9.3,8.1,8.1,0,0,1,10.3,6.2,83.8,83.8,0,0,0,3.7,13.9A8.2,8.2,0,0,1,176.8,167.8Zm3.6-68a23.6,23.6,0,0,0-2.9,11.4,8,8,0,0,1-8,7.8l-1.4-.2c-4.3-.7-7.2-5.3-6.4-9.6A109,109,0,0,1,166,93.1a8.3,8.3,0,0,1,9.8-5.3A8,8,0,0,1,180.4,99.8Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,26A102,102,0,1,0,230,128,102.2,102.2,0,0,0,128,26ZM68.5,195.5c1.9-2.2,3.8-4.5,5.5-6.9a6,6,0,1,0-9.6-7.1c-1.4,1.9-2.9,3.7-4.4,5.4A89.8,89.8,0,0,1,60,69.1c1.5,1.7,3,3.5,4.4,5.4a6,6,0,0,0,4.8,2.4A5.9,5.9,0,0,0,74,67.4c-1.7-2.4-3.6-4.7-5.5-6.9a89.9,89.9,0,0,1,119,0c-1.9,2.2-3.8,4.5-5.5,6.9a5.9,5.9,0,0,0,4.8,9.5,6,6,0,0,0,4.8-2.4c1.4-1.9,2.9-3.7,4.4-5.4a89.8,89.8,0,0,1,0,117.8c-1.5-1.7-3-3.5-4.4-5.4a6,6,0,1,0-9.6,7.1c1.7,2.4,3.6,4.7,5.5,6.9a89.9,89.9,0,0,1-119,0ZM178.7,99.4a89,89,0,0,0-3.6,14.6,6,6,0,0,1-5.9,5h-1a6.1,6.1,0,0,1-5-6.9,102.1,102.1,0,0,1,4.1-16.5,5.9,5.9,0,0,1,7.6-3.8A6,6,0,0,1,178.7,99.4Zm0,57.2a6.1,6.1,0,0,1-3.8,7.6l-1.9.3a6,6,0,0,1-5.7-4.1,104.7,104.7,0,0,1-4.1-16.5,6,6,0,0,1,11.9-1.9A89,89,0,0,0,178.7,156.6ZM77.3,99.4a6,6,0,0,1,3.8-7.6,5.9,5.9,0,0,1,7.6,3.8,102.1,102.1,0,0,1,4.1,16.5,6.1,6.1,0,0,1-5,6.9h-1a6,6,0,0,1-5.9-5A89,89,0,0,0,77.3,99.4Zm15.5,44.5a104.7,104.7,0,0,1-4.1,16.5,6,6,0,0,1-5.7,4.1l-1.9-.3a6.1,6.1,0,0,1-3.8-7.6A89,89,0,0,0,80.9,142a6,6,0,0,1,11.9,1.9Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,28A100,100,0,1,0,228,128,100.2,100.2,0,0,0,128,28ZM65.7,195.6a88.6,88.6,0,0,0,6.7-8.2,4,4,0,1,0-6.4-4.7q-2.9,3.8-6,7.2A91.8,91.8,0,0,1,60,66.1q3.2,3.5,6,7.2a3.9,3.9,0,0,0,3.2,1.6,4.2,4.2,0,0,0,2.4-.7,4,4,0,0,0,.8-5.6,88.6,88.6,0,0,0-6.7-8.2,91.7,91.7,0,0,1,124.6,0,88.6,88.6,0,0,0-6.7,8.2,4,4,0,0,0,.8,5.6,4.2,4.2,0,0,0,2.4.7,3.9,3.9,0,0,0,3.2-1.6q2.9-3.7,6-7.2a91.8,91.8,0,0,1,0,123.8q-3.1-3.5-6-7.2a4,4,0,1,0-6.4,4.7,88.6,88.6,0,0,0,6.7,8.2,91.7,91.7,0,0,1-124.6,0ZM176.8,98.8a83.2,83.2,0,0,0-3.7,14.9,4,4,0,0,1-3.9,3.3h-.7a4.1,4.1,0,0,1-3.3-4.6,100.9,100.9,0,0,1,4-16.2,4,4,0,0,1,5-2.5A4.1,4.1,0,0,1,176.8,98.8Zm0,58.4a4.1,4.1,0,0,1-2.6,5.1l-1.2.2a3.9,3.9,0,0,1-3.8-2.7,100.9,100.9,0,0,1-4-16.2,4.1,4.1,0,0,1,3.3-4.6,4.1,4.1,0,0,1,4.6,3.3A83.2,83.2,0,0,0,176.8,157.2ZM79.2,98.8a4.1,4.1,0,0,1,2.6-5.1,4,4,0,0,1,5,2.5,100.9,100.9,0,0,1,4,16.2,4.1,4.1,0,0,1-3.3,4.6h-.7a4,4,0,0,1-3.9-3.3A83.2,83.2,0,0,0,79.2,98.8Zm11.6,44.8a100.9,100.9,0,0,1-4,16.2,3.9,3.9,0,0,1-3.8,2.7l-1.2-.2a4.1,4.1,0,0,1-2.6-5.1,83.2,83.2,0,0,0,3.7-14.9,4.1,4.1,0,0,1,4.6-3.3A4.1,4.1,0,0,1,90.8,143.6Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24ZM71.3,195.3c1.5-1.8,3-3.6,4.3-5.5A8,8,0,0,0,74,178.6a8.1,8.1,0,0,0-11.2,1.7c-.9,1.2-1.8,2.4-2.8,3.5A87.8,87.8,0,0,1,60,72.2c1,1.1,1.9,2.3,2.8,3.5a7.9,7.9,0,0,0,6.4,3.2A8.5,8.5,0,0,0,74,77.4a8,8,0,0,0,1.6-11.2c-1.3-1.9-2.8-3.7-4.3-5.5a88,88,0,0,1,113.4,0c-1.5,1.8-3,3.6-4.3,5.5A8,8,0,0,0,182,77.4a8.5,8.5,0,0,0,4.8,1.5,7.9,7.9,0,0,0,6.4-3.2c.9-1.2,1.8-2.4,2.8-3.5a87.8,87.8,0,0,1,0,111.6c-1-1.1-1.9-2.3-2.8-3.5a8.1,8.1,0,0,0-11.2-1.7,8,8,0,0,0-1.6,11.2c1.3,1.9,2.8,3.7,4.3,5.5a88,88,0,0,1-113.4,0Zm90-83.5a109.5,109.5,0,0,1,4.1-16.9,8,8,0,0,1,15.2,5.1,80.6,80.6,0,0,0-3.5,14.3,8.1,8.1,0,0,1-7.9,6.7h-1.3A8,8,0,0,1,161.3,111.8Zm14.2,54.3a7.3,7.3,0,0,1-2.5.4,7.9,7.9,0,0,1-7.6-5.5,105.6,105.6,0,0,1-4.1-16.8,8,8,0,0,1,15.8-2.5,80.6,80.6,0,0,0,3.5,14.3A8,8,0,0,1,175.5,166.1ZM75.4,100a8,8,0,0,1,15.2-5.1,109.5,109.5,0,0,1,4.1,16.9,8,8,0,0,1-6.6,9.1H86.8a8.1,8.1,0,0,1-7.9-6.7A80.6,80.6,0,0,0,75.4,100Zm19.3,44.2A105.6,105.6,0,0,1,90.6,161a7.9,7.9,0,0,1-7.6,5.5,7.3,7.3,0,0,1-2.5-.4A8,8,0,0,1,75.4,156a80.6,80.6,0,0,0,3.5-14.3,8,8,0,0,1,15.8,2.5Z" />
        </>
      )}
    </svg>
  );
}

export function PhBasketball(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M205.3,203.3a107.7,107.7,0,0,0,0-150.6l-.8-1-.9-.8a107.9,107.9,0,0,0-151.2,0l-.9.8-.8,1a107.7,107.7,0,0,0,0,150.6l.8,1,.9.8a107.9,107.9,0,0,0,151.2,0l.9-.8ZM44.9,140H75.1A82.4,82.4,0,0,1,60,177.3,84.2,84.2,0,0,1,44.9,140ZM60,78.7A82.4,82.4,0,0,1,75.1,116H44.9A84.2,84.2,0,0,1,60,78.7ZM211.1,116H180.9A82.4,82.4,0,0,1,196,78.7,84.2,84.2,0,0,1,211.1,116Zm-54.4,0H140V44.9a84,84,0,0,1,39,16.4A107.9,107.9,0,0,0,156.7,116ZM116,116H99.3A107.9,107.9,0,0,0,77,61.3a84,84,0,0,1,39-16.4ZM99.3,140H116v71.1a84,84,0,0,1-39-16.4A107.9,107.9,0,0,0,99.3,140Zm40.7,0h16.7A107.9,107.9,0,0,0,179,194.7a84,84,0,0,1-39,16.4Zm40.9,0h30.2A84.2,84.2,0,0,1,196,177.3,82.4,82.4,0,0,1,180.9,140Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M59.9,195.8Zm0-135.5h0a96,96,0,0,0,0,135.5,95.8,95.8,0,0,0,28-67.8A95.5,95.5,0,0,0,59.9,60.3Zm136,135.5h0Zm0-135.5h0a96,96,0,0,0,0,135.5,96,96,0,0,0,0-135.5Z"
            opacity="0.2"
          />
          <path d="M202.3,200.7a103.8,103.8,0,0,0,0-145.4,2.3,2.3,0,0,0-.7-.7L201,54A103.8,103.8,0,0,0,55,54l-.6.6a2.3,2.3,0,0,0-.7.7,103.8,103.8,0,0,0,0,145.4,2.3,2.3,0,0,0,.7.7l.6.6a103.8,103.8,0,0,0,146,0l.6-.6ZM40.4,136H79.6A86.8,86.8,0,0,1,60,183.8,87,87,0,0,1,40.4,136ZM60,72.2A86.8,86.8,0,0,1,79.6,120H40.4A87,87,0,0,1,60,72.2ZM215.6,120H176.4A86.8,86.8,0,0,1,196,72.2,87,87,0,0,1,215.6,120Zm-55.3,0H136V40.4a87.3,87.3,0,0,1,48.7,20.3A103.4,103.4,0,0,0,160.3,120ZM120,120H95.7A103.4,103.4,0,0,0,71.3,60.7,87.3,87.3,0,0,1,120,40.4ZM95.7,136H120v79.6a87.3,87.3,0,0,1-48.7-20.3A103.4,103.4,0,0,0,95.7,136Zm40.3,0h24.3a103.4,103.4,0,0,0,24.4,59.3A87.3,87.3,0,0,1,136,215.6Zm40.4,0h39.2A87,87,0,0,1,196,183.8,86.8,86.8,0,0,1,176.4,136Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M63.6,46.4a104.3,104.3,0,0,1,52-21.7,4,4,0,0,1,4.4,4V120H95.7A104.1,104.1,0,0,0,63.4,52.4,3.9,3.9,0,0,1,63.6,46.4ZM46,64.1a104.8,104.8,0,0,0-21.3,51.4,4.1,4.1,0,0,0,4,4.5H79.6A88,88,0,0,0,51.9,63.6,4,4,0,0,0,46,64.1ZM192.4,46.4a104.3,104.3,0,0,0-52-21.7,4,4,0,0,0-4.4,4V120h24.3a104.1,104.1,0,0,1,32.3-67.6A3.9,3.9,0,0,0,192.4,46.4Zm38.9,69.1A104.8,104.8,0,0,0,210,64.1a4,4,0,0,0-5.9-.5A88,88,0,0,0,176.4,120h50.9A4.1,4.1,0,0,0,231.3,115.5Zm-206.6,25A104.8,104.8,0,0,0,46,191.9a4,4,0,0,0,5.9.5A88,88,0,0,0,79.6,136H28.7A4.1,4.1,0,0,0,24.7,140.5ZM210,191.9a104.8,104.8,0,0,0,21.3-51.4,4.1,4.1,0,0,0-4-4.5H176.4a88,88,0,0,0,27.7,56.4A4,4,0,0,0,210,191.9ZM63.6,209.6a104.3,104.3,0,0,0,52,21.7,4,4,0,0,0,4.4-4V136H95.7a104.1,104.1,0,0,1-32.3,67.6A3.9,3.9,0,0,0,63.6,209.6ZM160.3,136H136v91.3a4,4,0,0,0,4.4,4,104.3,104.3,0,0,0,52-21.7,3.9,3.9,0,0,0,.2-6A104.1,104.1,0,0,1,160.3,136Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M200.6,199.6a101.9,101.9,0,0,0,0-143.2c-.1-.1-.2-.3-.4-.4l-.4-.3a101.6,101.6,0,0,0-143.6,0l-.4.3-.4.4a101.9,101.9,0,0,0,0,143.2l.4.4.3.3a101.9,101.9,0,0,0,143.8,0l.3-.3C200.4,199.9,200.5,199.7,200.6,199.6ZM38.2,134H81.8A89.3,89.3,0,0,1,60,186.9,89.2,89.2,0,0,1,38.2,134ZM60,69.1A89.3,89.3,0,0,1,81.8,122H38.2A89.2,89.2,0,0,1,60,69.1ZM217.8,122H174.2A89.3,89.3,0,0,1,196,69.1,89.2,89.2,0,0,1,217.8,122Zm-55.6,0H134V38.2a90.5,90.5,0,0,1,53.5,22.3A100.9,100.9,0,0,0,162.2,122ZM122,122H93.8A100.9,100.9,0,0,0,68.5,60.5,90.5,90.5,0,0,1,122,38.2ZM93.8,134H122v83.8a90.5,90.5,0,0,1-53.5-22.3A100.9,100.9,0,0,0,93.8,134Zm40.2,0h28.2a100.9,100.9,0,0,0,25.3,61.5A90.5,90.5,0,0,1,134,217.8Zm40.2,0h43.6A89.2,89.2,0,0,1,196,186.9,89.3,89.3,0,0,1,174.2,134Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M199.2,198.2a100,100,0,0,0,0-140.4l-.4-.4-.4-.3a99.7,99.7,0,0,0-140.8,0l-.4.3-.4.4a100,100,0,0,0,0,140.4c.1.1.2.3.4.4l.4.3a99.7,99.7,0,0,0,140.8,0l.4-.3C199,198.5,199.1,198.3,199.2,198.2ZM36.1,132H83.9A91.1,91.1,0,0,1,60,189.9,90.9,90.9,0,0,1,36.1,132ZM60,66.1A91.1,91.1,0,0,1,83.9,124H36.1A90.9,90.9,0,0,1,60,66.1ZM219.9,124H172.1A91.1,91.1,0,0,1,196,66.1,90.9,90.9,0,0,1,219.9,124Zm-55.8,0H132V36.1a91.8,91.8,0,0,1,58.3,24.3A98.9,98.9,0,0,0,164.1,124ZM124,124H91.9A98.9,98.9,0,0,0,65.7,60.4,91.8,91.8,0,0,1,124,36.1Zm-32.1,8H124v87.9a91.8,91.8,0,0,1-58.3-24.3A98.9,98.9,0,0,0,91.9,132Zm40.1,0h32.1a98.9,98.9,0,0,0,26.2,63.6A91.8,91.8,0,0,1,132,219.9Zm40.1,0h47.8A90.9,90.9,0,0,1,196,189.9,91.1,91.1,0,0,1,172.1,132Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M202.3,200.7a103.8,103.8,0,0,0,0-145.4,2.3,2.3,0,0,0-.7-.7L201,54A103.8,103.8,0,0,0,55,54l-.6.6a2.3,2.3,0,0,0-.7.7,103.8,103.8,0,0,0,0,145.4,2.3,2.3,0,0,0,.7.7l.6.6a103.8,103.8,0,0,0,146,0l.6-.6ZM40.4,136H79.6A86.8,86.8,0,0,1,60,183.8,87,87,0,0,1,40.4,136ZM60,72.2A86.8,86.8,0,0,1,79.6,120H40.4A87,87,0,0,1,60,72.2ZM215.6,120H176.4A86.8,86.8,0,0,1,196,72.2,87,87,0,0,1,215.6,120Zm-55.3,0H136V40.4a87.3,87.3,0,0,1,48.7,20.3A103.4,103.4,0,0,0,160.3,120ZM120,120H95.7A103.4,103.4,0,0,0,71.3,60.7,87.3,87.3,0,0,1,120,40.4ZM95.7,136H120v79.6a87.3,87.3,0,0,1-48.7-20.3A103.4,103.4,0,0,0,95.7,136Zm40.3,0h24.3a103.4,103.4,0,0,0,24.4,59.3A87.3,87.3,0,0,1,136,215.6Zm40.4,0h39.2A87,87,0,0,1,196,183.8,86.8,86.8,0,0,1,176.4,136Z" />
        </>
      )}
    </svg>
  );
}

export function PhBathtub(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M232,92H211.3A11.9,11.9,0,0,0,200,84H136a11.9,11.9,0,0,0-11.3,8H68V52a8,8,0,0,1,16,0,12,12,0,0,0,24,0,32,32,0,0,0-64,0V92H24A20.1,20.1,0,0,0,4,112v32a60.2,60.2,0,0,0,56,59.9V216a12,12,0,0,0,24,0V204h88v12a12,12,0,0,0,24,0V203.9A60.2,60.2,0,0,0,252,144V112A20.1,20.1,0,0,0,232,92Zm-84,16h40v24H148Zm80,36a36,36,0,0,1-36,36H64a36,36,0,0,1-36-36V116h96v28a12,12,0,0,0,12,12h64a12,12,0,0,0,12-12V116h16Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M240,112v32a48,48,0,0,1-48,48H64a48,48,0,0,1-48-48V112a8,8,0,0,1,8-8H136v40h64V104h32A8,8,0,0,1,240,112Z"
            opacity="0.2"
          />
          <path d="M232,96H208a8,8,0,0,0-8-8H136a8,8,0,0,0-8,8H64V52a12,12,0,0,1,24,0,8,8,0,0,0,16,0,28,28,0,0,0-56,0V96H24A16,16,0,0,0,8,112v32a56,56,0,0,0,56,56v16a8,8,0,0,0,16,0V200h96v16a8,8,0,0,0,16,0V200a56,56,0,0,0,56-56V112A16,16,0,0,0,232,96Zm-40,8v32H144V104Zm40,40a40,40,0,0,1-40,40H64a40,40,0,0,1-40-40V112H128v32a8,8,0,0,0,8,8h64a8,8,0,0,0,8-8V112h24Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M232,96H212a8,8,0,0,0-8-8H132a8,8,0,0,0-8,8H64V52a12,12,0,0,1,24,0,8,8,0,0,0,16,0,28,28,0,0,0-56,0V96H24A16,16,0,0,0,8,112v32a56,56,0,0,0,56,56v16a8,8,0,0,0,16,0V200h96v16a8,8,0,0,0,16,0V200a56,56,0,0,0,56-56V112A16,16,0,0,0,232,96Zm-36,8v36H140V104Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M232,98H206V96a6,6,0,0,0-6-6H136a6,6,0,0,0-6,6v2H62V52a14,14,0,0,1,28,0,6,6,0,0,0,12,0,26,26,0,0,0-52,0V98H24a14,14,0,0,0-14,14v32a54,54,0,0,0,54,54h2v18a6,6,0,0,0,12,0V198H178v18a6,6,0,0,0,12,0V198h2a54,54,0,0,0,54-54V112A14,14,0,0,0,232,98Zm-90,4h52v36H142Zm92,42a42,42,0,0,1-42,42H64a42,42,0,0,1-42-42V112a2,2,0,0,1,2-2H130v34a6,6,0,0,0,6,6h64a6,6,0,0,0,6-6V110h26a2,2,0,0,1,2,2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M232,100H204V96a4,4,0,0,0-4-4H136a4,4,0,0,0-4,4v4H60V52a16,16,0,0,1,32,0,4,4,0,0,0,8,0,24,24,0,0,0-48,0v48H24a12,12,0,0,0-12,12v32a52,52,0,0,0,52,52h4v20a4,4,0,0,0,8,0V196H180v20a4,4,0,0,0,8,0V196h4a52,52,0,0,0,52-52V112A12,12,0,0,0,232,100Zm-92,0h56v40H140Zm96,44a44,44,0,0,1-44,44H64a44,44,0,0,1-44-44V112a4,4,0,0,1,4-4H132v36a4,4,0,0,0,4,4h64a4,4,0,0,0,4-4V108h28a4,4,0,0,1,4,4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M232,96H208a8,8,0,0,0-8-8H136a8,8,0,0,0-8,8H64V52a12,12,0,0,1,24,0,8,8,0,0,0,16,0,28,28,0,0,0-56,0V96H24A16,16,0,0,0,8,112v32a56,56,0,0,0,56,56v16a8,8,0,0,0,16,0V200h96v16a8,8,0,0,0,16,0V200a56,56,0,0,0,56-56V112A16,16,0,0,0,232,96Zm-40,8v32H144V104Zm40,40a40,40,0,0,1-40,40H64a40,40,0,0,1-40-40V112H128v32a8,8,0,0,0,8,8h64a8,8,0,0,0,8-8V112h24Z" />
        </>
      )}
    </svg>
  );
}

export function PhBatteryChargingVertical(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M88,12A12,12,0,0,1,100,0h56a12,12,0,0,1,0,24H100A12,12,0,0,1,88,12ZM212,64V212a28.1,28.1,0,0,1-28,28H72a28.1,28.1,0,0,1-28-28V64A28.1,28.1,0,0,1,72,36H184A28.1,28.1,0,0,1,212,64Zm-24,0a4,4,0,0,0-4-4H72a4,4,0,0,0-4,4V212a4,4,0,0,0,4,4H184a4,4,0,0,0,4-4Zm-44,62H129.7l9.4-23.5a12,12,0,1,0-22.2-9l-16,40A12,12,0,0,0,112,150h14.3l-9.4,23.5a12,12,0,1,0,22.2,9l16-40A12,12,0,0,0,144,126Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect x="56" y="40" width="144" height="184" rx="16" />
          </g>
          <path d="M88,8a8,8,0,0,1,8-8h64a8,8,0,0,1,0,16H96A8,8,0,0,1,88,8ZM208,56V208a24.1,24.1,0,0,1-24,24H72a24.1,24.1,0,0,1-24-24V56A24.1,24.1,0,0,1,72,32H184A24.1,24.1,0,0,1,208,56Zm-16,0a8,8,0,0,0-8-8H72a8,8,0,0,0-8,8V208a8,8,0,0,0,8,8H184a8,8,0,0,0,8-8Zm-48,68H123.8l11.6-29a8,8,0,1,0-14.8-6l-16,40a8,8,0,0,0,.8,7.5A7.9,7.9,0,0,0,112,140h20.2l-11.6,29a7.9,7.9,0,0,0,4.4,10.4,8,8,0,0,0,3,.6,7.9,7.9,0,0,0,7.4-5l16-40a8,8,0,0,0-.8-7.5A7.9,7.9,0,0,0,144,124Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M88,8a8,8,0,0,1,8-8h64a8,8,0,0,1,0,16H96A8,8,0,0,1,88,8ZM208,56V208a24.1,24.1,0,0,1-24,24H72a24.1,24.1,0,0,1-24-24V56A24.1,24.1,0,0,1,72,32H184A24.1,24.1,0,0,1,208,56Zm-57.4,71.5A7.9,7.9,0,0,0,144,124H123.8l11.6-29a8,8,0,1,0-14.8-6l-16,40a8,8,0,0,0,.8,7.5A7.9,7.9,0,0,0,112,140h20.2l-11.6,29a7.9,7.9,0,0,0,4.4,10.4,8,8,0,0,0,3,.6,7.9,7.9,0,0,0,7.4-5l16-40A8,8,0,0,0,150.6,127.5Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M90,8a6,6,0,0,1,6-6h64a6,6,0,0,1,0,12H96A6,6,0,0,1,90,8ZM206,56V208a22.1,22.1,0,0,1-22,22H72a22.1,22.1,0,0,1-22-22V56A22.1,22.1,0,0,1,72,34H184A22.1,22.1,0,0,1,206,56Zm-12,0a10,10,0,0,0-10-10H72A10,10,0,0,0,62,56V208a10,10,0,0,0,10,10H184a10,10,0,0,0,10-10Zm-50,70H120.9l12.7-31.8a6,6,0,0,0-11.2-4.4l-16,40a6.2,6.2,0,0,0,.6,5.6,6.1,6.1,0,0,0,5,2.6h23.1l-12.7,31.8a6.1,6.1,0,0,0,3.4,7.8,5.7,5.7,0,0,0,2.2.4,6,6,0,0,0,5.6-3.8l16-40a6.2,6.2,0,0,0-.6-5.6A6.1,6.1,0,0,0,144,126Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M92,8a4,4,0,0,1,4-4h64a4,4,0,0,1,0,8H96A4,4,0,0,1,92,8ZM204,56V208a20.1,20.1,0,0,1-20,20H72a20.1,20.1,0,0,1-20-20V56A20.1,20.1,0,0,1,72,36H184A20.1,20.1,0,0,1,204,56Zm-8,0a12,12,0,0,0-12-12H72A12,12,0,0,0,60,56V208a12,12,0,0,0,12,12H184a12,12,0,0,0,12-12Zm-52,72H117.9l13.8-34.5a4,4,0,1,0-7.4-3l-16,40a3.8,3.8,0,0,0,.4,3.7A4,4,0,0,0,112,136h26.1l-13.8,34.5a4,4,0,1,0,7.4,3l16-40a3.8,3.8,0,0,0-.4-3.7A4,4,0,0,0,144,128Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M88,8a8,8,0,0,1,8-8h64a8,8,0,0,1,0,16H96A8,8,0,0,1,88,8ZM208,56V208a24.1,24.1,0,0,1-24,24H72a24.1,24.1,0,0,1-24-24V56A24.1,24.1,0,0,1,72,32H184A24.1,24.1,0,0,1,208,56Zm-16,0a8,8,0,0,0-8-8H72a8,8,0,0,0-8,8V208a8,8,0,0,0,8,8H184a8,8,0,0,0,8-8Zm-48,68H123.8l11.6-29a8,8,0,1,0-14.8-6l-16,40a8,8,0,0,0,.8,7.5A7.9,7.9,0,0,0,112,140h20.2l-11.6,29a7.9,7.9,0,0,0,4.4,10.4,8,8,0,0,0,3,.6,7.9,7.9,0,0,0,7.4-5l16-40a8,8,0,0,0-.8-7.5A7.9,7.9,0,0,0,144,124Z" />
        </>
      )}
    </svg>
  );
}

export function PhBatteryCharging(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M192,44H44A28.1,28.1,0,0,0,16,72V184a28.1,28.1,0,0,0,28,28H192a28.1,28.1,0,0,0,28-28V72A28.1,28.1,0,0,0,192,44Zm4,140a4,4,0,0,1-4,4H44a4,4,0,0,1-4-4V72a4,4,0,0,1,4-4H192a4,4,0,0,1,4,4Zm60-88v64a12,12,0,0,1-24,0V96a12,12,0,0,1,24,0ZM143.9,121.3a11.8,11.8,0,0,1,1.2,11.2l-16,40a12,12,0,0,1-22.2-9l9.4-23.5H102a12,12,0,0,1-11.1-16.5l16-40a12,12,0,1,1,22.2,9L119.7,116H134A11.9,11.9,0,0,1,143.9,121.3Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect
              x="52"
              y="36"
              width="144"
              height="184"
              rx="16"
              transform="translate(252 4) rotate(90)"
            />
          </g>
          <path d="M256,96v64a8,8,0,0,1-16,0V96a8,8,0,0,1,16,0ZM224,72V184a24.1,24.1,0,0,1-24,24H48a24.1,24.1,0,0,1-24-24V72A24.1,24.1,0,0,1,48,48H200A24.1,24.1,0,0,1,224,72Zm-16,0a8,8,0,0,0-8-8H48a8,8,0,0,0-8,8V184a8,8,0,0,0,8,8H200a8,8,0,0,0,8-8Zm-68,48H119.8l11.6-29a8,8,0,1,0-14.8-6l-16,40a8,8,0,0,0,.8,7.5A7.9,7.9,0,0,0,108,136h20.2l-11.6,29a7.9,7.9,0,0,0,4.4,10.4,8,8,0,0,0,3,.6,7.9,7.9,0,0,0,7.4-5l16-40a8,8,0,0,0-.8-7.5A7.9,7.9,0,0,0,140,120Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M256,96v64a8,8,0,0,1-16,0V96a8,8,0,0,1,16,0ZM224,72V184a24.1,24.1,0,0,1-24,24H48a24.1,24.1,0,0,1-24-24V72A24.1,24.1,0,0,1,48,48H200A24.1,24.1,0,0,1,224,72Zm-77.4,51.5A7.9,7.9,0,0,0,140,120H119.8l11.6-29a8,8,0,1,0-14.8-6l-16,40a8,8,0,0,0,.8,7.5A7.9,7.9,0,0,0,108,136h20.2l-11.6,29a7.9,7.9,0,0,0,4.4,10.4,8,8,0,0,0,3,.6,7.9,7.9,0,0,0,7.4-5l16-40A8,8,0,0,0,146.6,123.5Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M254,96v64a6,6,0,0,1-12,0V96a6,6,0,0,1,12,0ZM222,72V184a22.1,22.1,0,0,1-22,22H48a22.1,22.1,0,0,1-22-22V72A22.1,22.1,0,0,1,48,50H200A22.1,22.1,0,0,1,222,72Zm-12,0a10,10,0,0,0-10-10H48A10,10,0,0,0,38,72V184a10,10,0,0,0,10,10H200a10,10,0,0,0,10-10Zm-70,50H116.9l12.7-31.8a6,6,0,0,0-11.2-4.4l-16,40a6.2,6.2,0,0,0,.6,5.6,6.1,6.1,0,0,0,5,2.6h23.1l-12.7,31.8a6.1,6.1,0,0,0,3.4,7.8,5.7,5.7,0,0,0,2.2.4,6,6,0,0,0,5.6-3.8l16-40a6.2,6.2,0,0,0-.6-5.6A6.1,6.1,0,0,0,140,122Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M252,96v64a4,4,0,0,1-8,0V96a4,4,0,0,1,8,0ZM220,72V184a20.1,20.1,0,0,1-20,20H48a20.1,20.1,0,0,1-20-20V72A20.1,20.1,0,0,1,48,52H200A20.1,20.1,0,0,1,220,72Zm-8,0a12,12,0,0,0-12-12H48A12,12,0,0,0,36,72V184a12,12,0,0,0,12,12H200a12,12,0,0,0,12-12Zm-72,52H113.9l13.8-34.5a4,4,0,1,0-7.4-3l-16,40a3.8,3.8,0,0,0,.4,3.7A4,4,0,0,0,108,132h26.1l-13.8,34.5a4,4,0,1,0,7.4,3l16-40a3.8,3.8,0,0,0-.4-3.7A4,4,0,0,0,140,124Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M256,96v64a8,8,0,0,1-16,0V96a8,8,0,0,1,16,0ZM224,72V184a24.1,24.1,0,0,1-24,24H48a24.1,24.1,0,0,1-24-24V72A24.1,24.1,0,0,1,48,48H200A24.1,24.1,0,0,1,224,72Zm-16,0a8,8,0,0,0-8-8H48a8,8,0,0,0-8,8V184a8,8,0,0,0,8,8H200a8,8,0,0,0,8-8Zm-68,48H119.8l11.6-29a8,8,0,1,0-14.8-6l-16,40a8,8,0,0,0,.8,7.5A7.9,7.9,0,0,0,108,136h20.2l-11.6,29a7.9,7.9,0,0,0,4.4,10.4,8,8,0,0,0,3,.6,7.9,7.9,0,0,0,7.4-5l16-40a8,8,0,0,0-.8-7.5A7.9,7.9,0,0,0,140,120Z" />
        </>
      )}
    </svg>
  );
}

export function PhBatteryEmpty(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M192,44H44A28.1,28.1,0,0,0,16,72V184a28.1,28.1,0,0,0,28,28H192a28.1,28.1,0,0,0,28-28V72A28.1,28.1,0,0,0,192,44Zm4,140a4,4,0,0,1-4,4H44a4,4,0,0,1-4-4V72a4,4,0,0,1,4-4H192a4,4,0,0,1,4,4Zm60-88v64a12,12,0,0,1-24,0V96a12,12,0,0,1,24,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect
              x="52"
              y="36"
              width="144"
              height="184"
              rx="16"
              transform="translate(252 4) rotate(90)"
            />
          </g>
          <path d="M256,96v64a8,8,0,0,1-16,0V96a8,8,0,0,1,16,0ZM224,72V184a24.1,24.1,0,0,1-24,24H48a24.1,24.1,0,0,1-24-24V72A24.1,24.1,0,0,1,48,48H200A24.1,24.1,0,0,1,224,72Zm-16,0a8,8,0,0,0-8-8H48a8,8,0,0,0-8,8V184a8,8,0,0,0,8,8H200a8,8,0,0,0,8-8Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M256,96v64a8,8,0,0,1-16,0V96a8,8,0,0,1,16,0ZM224,72V184a24.1,24.1,0,0,1-24,24H48a24.1,24.1,0,0,1-24-24V72A24.1,24.1,0,0,1,48,48H200A24.1,24.1,0,0,1,224,72Zm-16,0a8,8,0,0,0-8-8H48a8,8,0,0,0-8,8V184a8,8,0,0,0,8,8H200a8,8,0,0,0,8-8Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M254,96v64a6,6,0,0,1-12,0V96a6,6,0,0,1,12,0ZM222,72V184a22.1,22.1,0,0,1-22,22H48a22.1,22.1,0,0,1-22-22V72A22.1,22.1,0,0,1,48,50H200A22.1,22.1,0,0,1,222,72Zm-12,0a10,10,0,0,0-10-10H48A10,10,0,0,0,38,72V184a10,10,0,0,0,10,10H200a10,10,0,0,0,10-10Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M252,96v64a4,4,0,0,1-8,0V96a4,4,0,0,1,8,0ZM220,72V184a20.1,20.1,0,0,1-20,20H48a20.1,20.1,0,0,1-20-20V72A20.1,20.1,0,0,1,48,52H200A20.1,20.1,0,0,1,220,72Zm-8,0a12,12,0,0,0-12-12H48A12,12,0,0,0,36,72V184a12,12,0,0,0,12,12H200a12,12,0,0,0,12-12Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M256,96v64a8,8,0,0,1-16,0V96a8,8,0,0,1,16,0ZM224,72V184a24.1,24.1,0,0,1-24,24H48a24.1,24.1,0,0,1-24-24V72A24.1,24.1,0,0,1,48,48H200A24.1,24.1,0,0,1,224,72Zm-16,0a8,8,0,0,0-8-8H48a8,8,0,0,0-8,8V184a8,8,0,0,0,8,8H200a8,8,0,0,0,8-8Z" />
        </>
      )}
    </svg>
  );
}

export function PhBatteryFull(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M192,44H44A28.1,28.1,0,0,0,16,72V184a28.1,28.1,0,0,0,28,28H192a28.1,28.1,0,0,0,28-28V72A28.1,28.1,0,0,0,192,44Zm4,140a4,4,0,0,1-4,4H44a4,4,0,0,1-4-4V72a4,4,0,0,1,4-4H192a4,4,0,0,1,4,4ZM76,92v72a12,12,0,0,1-24,0V92a12,12,0,0,1,24,0Zm36,0v72a12,12,0,0,1-24,0V92a12,12,0,0,1,24,0Zm36,0v72a12,12,0,0,1-24,0V92a12,12,0,0,1,24,0Zm36,0v72a12,12,0,0,1-24,0V92a12,12,0,0,1,24,0Zm72,4v64a12,12,0,0,1-24,0V96a12,12,0,0,1,24,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect
              x="52"
              y="36"
              width="144"
              height="184"
              rx="16"
              transform="translate(252 4) rotate(90)"
            />
          </g>
          <path d="M256,96v64a8,8,0,0,1-16,0V96a8,8,0,0,1,16,0ZM224,72V184a24.1,24.1,0,0,1-24,24H48a24.1,24.1,0,0,1-24-24V72A24.1,24.1,0,0,1,48,48H200A24.1,24.1,0,0,1,224,72Zm-16,0a8,8,0,0,0-8-8H48a8,8,0,0,0-8,8V184a8,8,0,0,0,8,8H200a8,8,0,0,0,8-8Zm-65.6,8a8,8,0,0,0-8,8v80a8,8,0,0,0,16,0V88A8,8,0,0,0,142.4,80Zm-36.8,0a8,8,0,0,0-8,8v80a8,8,0,0,0,16,0V88A8,8,0,0,0,105.6,80ZM68.8,80a8,8,0,0,0-8,8v80a8,8,0,0,0,16,0V88A8,8,0,0,0,68.8,80Zm110.4,0a8,8,0,0,0-8,8v80a8,8,0,0,0,16,0V88A8,8,0,0,0,179.2,80Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M256,96v64a8,8,0,0,1-16,0V96a8,8,0,0,1,16,0ZM224,72V184a24.1,24.1,0,0,1-24,24H48a24.1,24.1,0,0,1-24-24V72A24.1,24.1,0,0,1,48,48H200A24.1,24.1,0,0,1,224,72Zm-16,0a8,8,0,0,0-8-8H48a8,8,0,0,0-8,8V184a8,8,0,0,0,8,8H200a8,8,0,0,0,8-8Zm-24,8H64a8,8,0,0,0-8,8v80a8,8,0,0,0,8,8H184a8,8,0,0,0,8-8V88A8,8,0,0,0,184,80Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M254,96v64a6,6,0,0,1-12,0V96a6,6,0,0,1,12,0ZM222,72V184a22.1,22.1,0,0,1-22,22H48a22.1,22.1,0,0,1-22-22V72A22.1,22.1,0,0,1,48,50H200A22.1,22.1,0,0,1,222,72Zm-12,0a10,10,0,0,0-10-10H48A10,10,0,0,0,38,72V184a10,10,0,0,0,10,10H200a10,10,0,0,0,10-10ZM142.4,82a6,6,0,0,0-6,6v80a6,6,0,0,0,12,0V88A6,6,0,0,0,142.4,82Zm-36.8,0a6,6,0,0,0-6,6v80a6,6,0,0,0,12,0V88A6,6,0,0,0,105.6,82ZM68.8,82a6,6,0,0,0-6,6v80a6,6,0,0,0,12,0V88A6,6,0,0,0,68.8,82Zm110.4,0a6,6,0,0,0-6,6v80a6,6,0,0,0,12,0V88A6,6,0,0,0,179.2,82Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M252,96v64a4,4,0,0,1-8,0V96a4,4,0,0,1,8,0ZM220,72V184a20.1,20.1,0,0,1-20,20H48a20.1,20.1,0,0,1-20-20V72A20.1,20.1,0,0,1,48,52H200A20.1,20.1,0,0,1,220,72Zm-8,0a12,12,0,0,0-12-12H48A12,12,0,0,0,36,72V184a12,12,0,0,0,12,12H200a12,12,0,0,0,12-12ZM142.4,84a4,4,0,0,0-4,4v80a4,4,0,0,0,8,0V88A4,4,0,0,0,142.4,84Zm-36.8,0a4,4,0,0,0-4,4v80a4,4,0,0,0,8,0V88A4,4,0,0,0,105.6,84ZM68.8,84a4,4,0,0,0-4,4v80a4,4,0,0,0,8,0V88A4,4,0,0,0,68.8,84Zm110.4,0a4,4,0,0,0-4,4v80a4,4,0,0,0,8,0V88A4,4,0,0,0,179.2,84Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M256,96v64a8,8,0,0,1-16,0V96a8,8,0,0,1,16,0ZM224,72V184a24.1,24.1,0,0,1-24,24H48a24.1,24.1,0,0,1-24-24V72A24.1,24.1,0,0,1,48,48H200A24.1,24.1,0,0,1,224,72Zm-16,0a8,8,0,0,0-8-8H48a8,8,0,0,0-8,8V184a8,8,0,0,0,8,8H200a8,8,0,0,0,8-8Zm-65.6,8a8,8,0,0,0-8,8v80a8,8,0,0,0,16,0V88A8,8,0,0,0,142.4,80Zm-36.8,0a8,8,0,0,0-8,8v80a8,8,0,0,0,16,0V88A8,8,0,0,0,105.6,80ZM68.8,80a8,8,0,0,0-8,8v80a8,8,0,0,0,16,0V88A8,8,0,0,0,68.8,80Zm110.4,0a8,8,0,0,0-8,8v80a8,8,0,0,0,16,0V88A8,8,0,0,0,179.2,80Z" />
        </>
      )}
    </svg>
  );
}

export function PhBatteryHigh(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M192,44H44A28.1,28.1,0,0,0,16,72V184a28.1,28.1,0,0,0,28,28H192a28.1,28.1,0,0,0,28-28V72A28.1,28.1,0,0,0,192,44Zm4,140a4,4,0,0,1-4,4H44a4,4,0,0,1-4-4V72a4,4,0,0,1,4-4H192a4,4,0,0,1,4,4ZM76,92v72a12,12,0,0,1-24,0V92a12,12,0,0,1,24,0Zm36,0v72a12,12,0,0,1-24,0V92a12,12,0,0,1,24,0Zm36,0v72a12,12,0,0,1-24,0V92a12,12,0,0,1,24,0Zm108,4v64a12,12,0,0,1-24,0V96a12,12,0,0,1,24,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect
              x="52"
              y="36"
              width="144"
              height="184"
              rx="16"
              transform="translate(252 4) rotate(90)"
            />
          </g>
          <path d="M256,96v64a8,8,0,0,1-16,0V96a8,8,0,0,1,16,0ZM224,72V184a24.1,24.1,0,0,1-24,24H48a24.1,24.1,0,0,1-24-24V72A24.1,24.1,0,0,1,48,48H200A24.1,24.1,0,0,1,224,72Zm-16,0a8,8,0,0,0-8-8H48a8,8,0,0,0-8,8V184a8,8,0,0,0,8,8H200a8,8,0,0,0,8-8Zm-65.6,8a8,8,0,0,0-8,8v80a8,8,0,0,0,16,0V88A8,8,0,0,0,142.4,80Zm-36.8,0a8,8,0,0,0-8,8v80a8,8,0,0,0,16,0V88A8,8,0,0,0,105.6,80ZM68.8,80a8,8,0,0,0-8,8v80a8,8,0,0,0,16,0V88A8,8,0,0,0,68.8,80Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M256,96v64a8,8,0,0,1-16,0V96a8,8,0,0,1,16,0ZM224,72V184a24.1,24.1,0,0,1-24,24H48a24.1,24.1,0,0,1-24-24V72A24.1,24.1,0,0,1,48,48H200A24.1,24.1,0,0,1,224,72Zm-16,0a8,8,0,0,0-8-8H48a8,8,0,0,0-8,8V184a8,8,0,0,0,8,8H200a8,8,0,0,0,8-8Zm-64,8H64a8,8,0,0,0-8,8v80a8,8,0,0,0,8,8h80a8,8,0,0,0,8-8V88A8,8,0,0,0,144,80Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M254,96v64a6,6,0,0,1-12,0V96a6,6,0,0,1,12,0ZM222,72V184a22.1,22.1,0,0,1-22,22H48a22.1,22.1,0,0,1-22-22V72A22.1,22.1,0,0,1,48,50H200A22.1,22.1,0,0,1,222,72Zm-12,0a10,10,0,0,0-10-10H48A10,10,0,0,0,38,72V184a10,10,0,0,0,10,10H200a10,10,0,0,0,10-10ZM142.4,82a6,6,0,0,0-6,6v80a6,6,0,0,0,12,0V88A6,6,0,0,0,142.4,82Zm-36.8,0a6,6,0,0,0-6,6v80a6,6,0,0,0,12,0V88A6,6,0,0,0,105.6,82ZM68.8,82a6,6,0,0,0-6,6v80a6,6,0,0,0,12,0V88A6,6,0,0,0,68.8,82Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M252,96v64a4,4,0,0,1-8,0V96a4,4,0,0,1,8,0ZM220,72V184a20.1,20.1,0,0,1-20,20H48a20.1,20.1,0,0,1-20-20V72A20.1,20.1,0,0,1,48,52H200A20.1,20.1,0,0,1,220,72Zm-8,0a12,12,0,0,0-12-12H48A12,12,0,0,0,36,72V184a12,12,0,0,0,12,12H200a12,12,0,0,0,12-12ZM142.4,84a4,4,0,0,0-4,4v80a4,4,0,0,0,8,0V88A4,4,0,0,0,142.4,84Zm-36.8,0a4,4,0,0,0-4,4v80a4,4,0,0,0,8,0V88A4,4,0,0,0,105.6,84ZM68.8,84a4,4,0,0,0-4,4v80a4,4,0,0,0,8,0V88A4,4,0,0,0,68.8,84Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M256,96v64a8,8,0,0,1-16,0V96a8,8,0,0,1,16,0ZM224,72V184a24.1,24.1,0,0,1-24,24H48a24.1,24.1,0,0,1-24-24V72A24.1,24.1,0,0,1,48,48H200A24.1,24.1,0,0,1,224,72Zm-16,0a8,8,0,0,0-8-8H48a8,8,0,0,0-8,8V184a8,8,0,0,0,8,8H200a8,8,0,0,0,8-8Zm-65.6,8a8,8,0,0,0-8,8v80a8,8,0,0,0,16,0V88A8,8,0,0,0,142.4,80Zm-36.8,0a8,8,0,0,0-8,8v80a8,8,0,0,0,16,0V88A8,8,0,0,0,105.6,80ZM68.8,80a8,8,0,0,0-8,8v80a8,8,0,0,0,16,0V88A8,8,0,0,0,68.8,80Z" />
        </>
      )}
    </svg>
  );
}

export function PhBatteryLow(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M192,44H44A28.1,28.1,0,0,0,16,72V184a28.1,28.1,0,0,0,28,28H192a28.1,28.1,0,0,0,28-28V72A28.1,28.1,0,0,0,192,44Zm4,140a4,4,0,0,1-4,4H44a4,4,0,0,1-4-4V72a4,4,0,0,1,4-4H192a4,4,0,0,1,4,4ZM76,92v72a12,12,0,0,1-24,0V92a12,12,0,0,1,24,0Zm180,4v64a12,12,0,0,1-24,0V96a12,12,0,0,1,24,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect
              x="52"
              y="36"
              width="144"
              height="184"
              rx="16"
              transform="translate(252 4) rotate(90)"
            />
          </g>
          <path d="M256,96v64a8,8,0,0,1-16,0V96a8,8,0,0,1,16,0ZM224,72V184a24.1,24.1,0,0,1-24,24H48a24.1,24.1,0,0,1-24-24V72A24.1,24.1,0,0,1,48,48H200A24.1,24.1,0,0,1,224,72Zm-16,0a8,8,0,0,0-8-8H48a8,8,0,0,0-8,8V184a8,8,0,0,0,8,8H200a8,8,0,0,0,8-8ZM68.8,80a8,8,0,0,0-8,8v80a8,8,0,0,0,16,0V88A8,8,0,0,0,68.8,80Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M256,96v64a8,8,0,0,1-16,0V96a8,8,0,0,1,16,0ZM224,72V184a24.1,24.1,0,0,1-24,24H48a24.1,24.1,0,0,1-24-24V72A24.1,24.1,0,0,1,48,48H200A24.1,24.1,0,0,1,224,72Zm-16,0a8,8,0,0,0-8-8H48a8,8,0,0,0-8,8V184a8,8,0,0,0,8,8H200a8,8,0,0,0,8-8ZM80,80H64a8,8,0,0,0-8,8v80a8,8,0,0,0,8,8H80a8,8,0,0,0,8-8V88A8,8,0,0,0,80,80Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M254,96v64a6,6,0,0,1-12,0V96a6,6,0,0,1,12,0ZM222,72V184a22.1,22.1,0,0,1-22,22H48a22.1,22.1,0,0,1-22-22V72A22.1,22.1,0,0,1,48,50H200A22.1,22.1,0,0,1,222,72Zm-12,0a10,10,0,0,0-10-10H48A10,10,0,0,0,38,72V184a10,10,0,0,0,10,10H200a10,10,0,0,0,10-10ZM68.8,82a6,6,0,0,0-6,6v80a6,6,0,0,0,12,0V88A6,6,0,0,0,68.8,82Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M252,96v64a4,4,0,0,1-8,0V96a4,4,0,0,1,8,0ZM220,72V184a20.1,20.1,0,0,1-20,20H48a20.1,20.1,0,0,1-20-20V72A20.1,20.1,0,0,1,48,52H200A20.1,20.1,0,0,1,220,72Zm-8,0a12,12,0,0,0-12-12H48A12,12,0,0,0,36,72V184a12,12,0,0,0,12,12H200a12,12,0,0,0,12-12ZM68.8,84a4,4,0,0,0-4,4v80a4,4,0,0,0,8,0V88A4,4,0,0,0,68.8,84Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M256,96v64a8,8,0,0,1-16,0V96a8,8,0,0,1,16,0ZM224,72V184a24.1,24.1,0,0,1-24,24H48a24.1,24.1,0,0,1-24-24V72A24.1,24.1,0,0,1,48,48H200A24.1,24.1,0,0,1,224,72Zm-16,0a8,8,0,0,0-8-8H48a8,8,0,0,0-8,8V184a8,8,0,0,0,8,8H200a8,8,0,0,0,8-8ZM68.8,80a8,8,0,0,0-8,8v80a8,8,0,0,0,16,0V88A8,8,0,0,0,68.8,80Z" />
        </>
      )}
    </svg>
  );
}

export function PhBatteryMedium(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M192,44H44A28.1,28.1,0,0,0,16,72V184a28.1,28.1,0,0,0,28,28H192a28.1,28.1,0,0,0,28-28V72A28.1,28.1,0,0,0,192,44Zm4,140a4,4,0,0,1-4,4H44a4,4,0,0,1-4-4V72a4,4,0,0,1,4-4H192a4,4,0,0,1,4,4ZM76,92v72a12,12,0,0,1-24,0V92a12,12,0,0,1,24,0Zm36,0v72a12,12,0,0,1-24,0V92a12,12,0,0,1,24,0Zm144,4v64a12,12,0,0,1-24,0V96a12,12,0,0,1,24,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect
              x="52"
              y="36"
              width="144"
              height="184"
              rx="16"
              transform="translate(252 4) rotate(90)"
            />
          </g>
          <path d="M256,96v64a8,8,0,0,1-16,0V96a8,8,0,0,1,16,0ZM224,72V184a24.1,24.1,0,0,1-24,24H48a24.1,24.1,0,0,1-24-24V72A24.1,24.1,0,0,1,48,48H200A24.1,24.1,0,0,1,224,72Zm-16,0a8,8,0,0,0-8-8H48a8,8,0,0,0-8,8V184a8,8,0,0,0,8,8H200a8,8,0,0,0,8-8ZM105.6,80a8,8,0,0,0-8,8v80a8,8,0,0,0,16,0V88A8,8,0,0,0,105.6,80ZM68.8,80a8,8,0,0,0-8,8v80a8,8,0,0,0,16,0V88A8,8,0,0,0,68.8,80Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M256,96v64a8,8,0,0,1-16,0V96a8,8,0,0,1,16,0ZM224,72V184a24.1,24.1,0,0,1-24,24H48a24.1,24.1,0,0,1-24-24V72A24.1,24.1,0,0,1,48,48H200A24.1,24.1,0,0,1,224,72Zm-16,0a8,8,0,0,0-8-8H48a8,8,0,0,0-8,8V184a8,8,0,0,0,8,8H200a8,8,0,0,0,8-8Zm-88,8H64a8,8,0,0,0-8,8v80a8,8,0,0,0,8,8h56a8,8,0,0,0,8-8V88A8,8,0,0,0,120,80Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M254,96v64a6,6,0,0,1-12,0V96a6,6,0,0,1,12,0ZM222,72V184a22.1,22.1,0,0,1-22,22H48a22.1,22.1,0,0,1-22-22V72A22.1,22.1,0,0,1,48,50H200A22.1,22.1,0,0,1,222,72Zm-12,0a10,10,0,0,0-10-10H48A10,10,0,0,0,38,72V184a10,10,0,0,0,10,10H200a10,10,0,0,0,10-10ZM105.6,82a6,6,0,0,0-6,6v80a6,6,0,0,0,12,0V88A6,6,0,0,0,105.6,82ZM68.8,82a6,6,0,0,0-6,6v80a6,6,0,0,0,12,0V88A6,6,0,0,0,68.8,82Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M252,96v64a4,4,0,0,1-8,0V96a4,4,0,0,1,8,0ZM220,72V184a20.1,20.1,0,0,1-20,20H48a20.1,20.1,0,0,1-20-20V72A20.1,20.1,0,0,1,48,52H200A20.1,20.1,0,0,1,220,72Zm-8,0a12,12,0,0,0-12-12H48A12,12,0,0,0,36,72V184a12,12,0,0,0,12,12H200a12,12,0,0,0,12-12ZM105.6,84a4,4,0,0,0-4,4v80a4,4,0,0,0,8,0V88A4,4,0,0,0,105.6,84ZM68.8,84a4,4,0,0,0-4,4v80a4,4,0,0,0,8,0V88A4,4,0,0,0,68.8,84Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M256,96v64a8,8,0,0,1-16,0V96a8,8,0,0,1,16,0ZM224,72V184a24.1,24.1,0,0,1-24,24H48a24.1,24.1,0,0,1-24-24V72A24.1,24.1,0,0,1,48,48H200A24.1,24.1,0,0,1,224,72Zm-16,0a8,8,0,0,0-8-8H48a8,8,0,0,0-8,8V184a8,8,0,0,0,8,8H200a8,8,0,0,0,8-8ZM105.6,80a8,8,0,0,0-8,8v80a8,8,0,0,0,16,0V88A8,8,0,0,0,105.6,80ZM68.8,80a8,8,0,0,0-8,8v80a8,8,0,0,0,16,0V88A8,8,0,0,0,68.8,80Z" />
        </>
      )}
    </svg>
  );
}

export function PhBatteryPlus(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M192,44H44A28.1,28.1,0,0,0,16,72V184a28.1,28.1,0,0,0,28,28H192a28.1,28.1,0,0,0,28-28V72A28.1,28.1,0,0,0,192,44Zm4,140a4,4,0,0,1-4,4H44a4,4,0,0,1-4-4V72a4,4,0,0,1,4-4H192a4,4,0,0,1,4,4Zm60-88v64a12,12,0,0,1-24,0V96a12,12,0,0,1,24,0Zm-96,32a12,12,0,0,1-12,12H136v12a12,12,0,0,1-24,0V140H100a12,12,0,0,1,0-24h12V104a12,12,0,0,1,24,0v12h12A12,12,0,0,1,160,128Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M216,72V184a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V72A16,16,0,0,1,48,56H200A16,16,0,0,1,216,72Z"
            opacity="0.2"
          />
          <path d="M256,96v64a8,8,0,0,1-16,0V96a8,8,0,0,1,16,0ZM224,72V184a24.1,24.1,0,0,1-24,24H48a24.1,24.1,0,0,1-24-24V72A24.1,24.1,0,0,1,48,48H200A24.1,24.1,0,0,1,224,72Zm-16,0a8,8,0,0,0-8-8H48a8,8,0,0,0-8,8V184a8,8,0,0,0,8,8H200a8,8,0,0,0,8-8Zm-60,48H132V104a8,8,0,0,0-16,0v16H100a8,8,0,0,0,0,16h16v16a8,8,0,0,0,16,0V136h16a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M256,96v64a8,8,0,0,1-16,0V96a8,8,0,0,1,16,0ZM224,72V184a24.1,24.1,0,0,1-24,24H48a24.1,24.1,0,0,1-24-24V72A24.1,24.1,0,0,1,48,48H200A24.1,24.1,0,0,1,224,72Zm-68,56a8,8,0,0,0-8-8H132V104a8,8,0,0,0-16,0v16H100a8,8,0,0,0,0,16h16v16a8,8,0,0,0,16,0V136h16A8,8,0,0,0,156,128Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M254,96v64a6,6,0,0,1-12,0V96a6,6,0,0,1,12,0ZM222,72V184a22.1,22.1,0,0,1-22,22H48a22.1,22.1,0,0,1-22-22V72A22.1,22.1,0,0,1,48,50H200A22.1,22.1,0,0,1,222,72Zm-12,0a10,10,0,0,0-10-10H48A10,10,0,0,0,38,72V184a10,10,0,0,0,10,10H200a10,10,0,0,0,10-10Zm-62,50H130V104a6,6,0,0,0-12,0v18H100a6,6,0,0,0,0,12h18v18a6,6,0,0,0,12,0V134h18a6,6,0,0,0,0-12Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M252,96v64a4,4,0,0,1-8,0V96a4,4,0,0,1,8,0ZM220,72V184a20.1,20.1,0,0,1-20,20H48a20.1,20.1,0,0,1-20-20V72A20.1,20.1,0,0,1,48,52H200A20.1,20.1,0,0,1,220,72Zm-8,0a12,12,0,0,0-12-12H48A12,12,0,0,0,36,72V184a12,12,0,0,0,12,12H200a12,12,0,0,0,12-12Zm-64,52H128V104a4,4,0,0,0-8,0v20H100a4,4,0,0,0,0,8h20v20a4,4,0,0,0,8,0V132h20a4,4,0,0,0,0-8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M256,96v64a8,8,0,0,1-16,0V96a8,8,0,0,1,16,0ZM224,72V184a24.1,24.1,0,0,1-24,24H48a24.1,24.1,0,0,1-24-24V72A24.1,24.1,0,0,1,48,48H200A24.1,24.1,0,0,1,224,72Zm-16,0a8,8,0,0,0-8-8H48a8,8,0,0,0-8,8V184a8,8,0,0,0,8,8H200a8,8,0,0,0,8-8Zm-60,48H132V104a8,8,0,0,0-16,0v16H100a8,8,0,0,0,0,16h16v16a8,8,0,0,0,16,0V136h16a8,8,0,0,0,0-16Z" />
        </>
      )}
    </svg>
  );
}

export function PhBatteryWarningVertical(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M88,12A12,12,0,0,1,100,0h56a12,12,0,0,1,0,24H100A12,12,0,0,1,88,12ZM212,64V212a28.1,28.1,0,0,1-28,28H72a28.1,28.1,0,0,1-28-28V64A28.1,28.1,0,0,1,72,36H184A28.1,28.1,0,0,1,212,64Zm-24,0a4,4,0,0,0-4-4H72a4,4,0,0,0-4,4V212a4,4,0,0,0,4,4H184a4,4,0,0,0,4-4Zm-60,84a12,12,0,0,0,12-12V96a12,12,0,0,0-24,0v40A12,12,0,0,0,128,148Zm0,12a16,16,0,1,0,16,16A16,16,0,0,0,128,160Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect x="56" y="40" width="144" height="184" rx="16" />
          </g>
          <path d="M120,132V92a8,8,0,0,1,16,0v40a8,8,0,0,1-16,0ZM96,16h64a8,8,0,0,0,0-16H96a8,8,0,0,0,0,16ZM208,56V208a24.1,24.1,0,0,1-24,24H72a24.1,24.1,0,0,1-24-24V56A24.1,24.1,0,0,1,72,32H184A24.1,24.1,0,0,1,208,56Zm-16,0a8,8,0,0,0-8-8H72a8,8,0,0,0-8,8V208a8,8,0,0,0,8,8H184a8,8,0,0,0,8-8ZM128,156a12,12,0,1,0,12,12A12,12,0,0,0,128,156Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M88,8a8,8,0,0,1,8-8h64a8,8,0,0,1,0,16H96A8,8,0,0,1,88,8ZM208,56V208a24.1,24.1,0,0,1-24,24H72a24.1,24.1,0,0,1-24-24V56A24.1,24.1,0,0,1,72,32H184A24.1,24.1,0,0,1,208,56Zm-88,76a8,8,0,0,0,16,0V92a8,8,0,0,0-16,0Zm20,36a12,12,0,1,0-12,12A12,12,0,0,0,140,168Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M122,132V92a6,6,0,0,1,12,0v40a6,6,0,0,1-12,0ZM96,14h64a6,6,0,0,0,0-12H96a6,6,0,0,0,0,12ZM206,56V208a22.1,22.1,0,0,1-22,22H72a22.1,22.1,0,0,1-22-22V56A22.1,22.1,0,0,1,72,34H184A22.1,22.1,0,0,1,206,56Zm-12,0a10,10,0,0,0-10-10H72A10,10,0,0,0,62,56V208a10,10,0,0,0,10,10H184a10,10,0,0,0,10-10ZM128,158a10,10,0,1,0,10,10A10,10,0,0,0,128,158Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M124,132V92a4,4,0,0,1,8,0v40a4,4,0,0,1-8,0ZM96,12h64a4,4,0,0,0,0-8H96a4,4,0,0,0,0,8ZM204,56V208a20.1,20.1,0,0,1-20,20H72a20.1,20.1,0,0,1-20-20V56A20.1,20.1,0,0,1,72,36H184A20.1,20.1,0,0,1,204,56Zm-8,0a12,12,0,0,0-12-12H72A12,12,0,0,0,60,56V208a12,12,0,0,0,12,12H184a12,12,0,0,0,12-12ZM128,160a8,8,0,1,0,8,8A8,8,0,0,0,128,160Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M120,132V92a8,8,0,0,1,16,0v40a8,8,0,0,1-16,0ZM96,16h64a8,8,0,0,0,0-16H96a8,8,0,0,0,0,16ZM208,56V208a24.1,24.1,0,0,1-24,24H72a24.1,24.1,0,0,1-24-24V56A24.1,24.1,0,0,1,72,32H184A24.1,24.1,0,0,1,208,56Zm-16,0a8,8,0,0,0-8-8H72a8,8,0,0,0-8,8V208a8,8,0,0,0,8,8H184a8,8,0,0,0,8-8ZM128,156a12,12,0,1,0,12,12A12,12,0,0,0,128,156Z" />
        </>
      )}
    </svg>
  );
}

export function PhBatteryWarning(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M192,44H44A28.1,28.1,0,0,0,16,72V184a28.1,28.1,0,0,0,28,28H192a28.1,28.1,0,0,0,28-28V72A28.1,28.1,0,0,0,192,44Zm4,140a4,4,0,0,1-4,4H44a4,4,0,0,1-4-4V72a4,4,0,0,1,4-4H192a4,4,0,0,1,4,4Zm60-88v64a12,12,0,0,1-24,0V96a12,12,0,0,1,24,0ZM104,120V92a12,12,0,0,1,24,0v28a12,12,0,0,1-24,0Zm28,40a16,16,0,1,1-16-16A16,16,0,0,1,132,160Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect
              x="52"
              y="36"
              width="144"
              height="184"
              rx="16"
              transform="translate(252 4) rotate(90)"
            />
          </g>
          <path d="M116,128V88a8,8,0,0,1,16,0v40a8,8,0,0,1-16,0ZM248,88a8,8,0,0,0-8,8v64a8,8,0,0,0,16,0V96A8,8,0,0,0,248,88ZM224,72V184a24.1,24.1,0,0,1-24,24H48a24.1,24.1,0,0,1-24-24V72A24.1,24.1,0,0,1,48,48H200A24.1,24.1,0,0,1,224,72Zm-16,0a8,8,0,0,0-8-8H48a8,8,0,0,0-8,8V184a8,8,0,0,0,8,8H200a8,8,0,0,0,8-8Zm-84,80a12,12,0,1,0,12,12A12,12,0,0,0,124,152Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M200,48H48A24.1,24.1,0,0,0,24,72V184a24.1,24.1,0,0,0,24,24H200a24.1,24.1,0,0,0,24-24V72A24.1,24.1,0,0,0,200,48ZM116,88a8,8,0,0,1,16,0v40a8,8,0,0,1-16,0Zm8,88a12,12,0,1,1,12-12A12,12,0,0,1,124,176ZM256,96v64a8,8,0,0,1-16,0V96a8,8,0,0,1,16,0Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M118,128V88a6,6,0,0,1,12,0v40a6,6,0,0,1-12,0ZM248,90a6,6,0,0,0-6,6v64a6,6,0,0,0,12,0V96A6,6,0,0,0,248,90ZM222,72V184a22.1,22.1,0,0,1-22,22H48a22.1,22.1,0,0,1-22-22V72A22.1,22.1,0,0,1,48,50H200A22.1,22.1,0,0,1,222,72Zm-12,0a10,10,0,0,0-10-10H48A10,10,0,0,0,38,72V184a10,10,0,0,0,10,10H200a10,10,0,0,0,10-10Zm-86,82a10,10,0,1,0,10,10A10,10,0,0,0,124,154Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M120,128V88a4,4,0,0,1,8,0v40a4,4,0,0,1-8,0ZM248,92a4,4,0,0,0-4,4v64a4,4,0,0,0,8,0V96A4,4,0,0,0,248,92ZM220,72V184a20.1,20.1,0,0,1-20,20H48a20.1,20.1,0,0,1-20-20V72A20.1,20.1,0,0,1,48,52H200A20.1,20.1,0,0,1,220,72Zm-8,0a12,12,0,0,0-12-12H48A12,12,0,0,0,36,72V184a12,12,0,0,0,12,12H200a12,12,0,0,0,12-12Zm-88,84a8,8,0,1,0,8,8A8,8,0,0,0,124,156Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M116,128V88a8,8,0,0,1,16,0v40a8,8,0,0,1-16,0ZM248,88a8,8,0,0,0-8,8v64a8,8,0,0,0,16,0V96A8,8,0,0,0,248,88ZM224,72V184a24.1,24.1,0,0,1-24,24H48a24.1,24.1,0,0,1-24-24V72A24.1,24.1,0,0,1,48,48H200A24.1,24.1,0,0,1,224,72Zm-16,0a8,8,0,0,0-8-8H48a8,8,0,0,0-8,8V184a8,8,0,0,0,8,8H200a8,8,0,0,0,8-8Zm-84,80a12,12,0,1,0,12,12A12,12,0,0,0,124,152Z" />
        </>
      )}
    </svg>
  );
}

export function PhBed(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M208,68H28V48A12,12,0,0,0,4,48V208a12,12,0,0,0,24,0V180H228v28a12,12,0,0,0,24,0V112A44,44,0,0,0,208,68ZM28,92H92v64H28Zm88,64V92h92a20.1,20.1,0,0,1,20,20v44Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M104,80H208a32,32,0,0,1,32,32v56a0,0,0,0,1,0,0H104a0,0,0,0,1,0,0V80A0,0,0,0,1,104,80Z" />
          </g>
          <path d="M208,72H24V48A8,8,0,0,0,8,48V208a8,8,0,0,0,16,0V176H232v32a8,8,0,0,0,16,0V112A40,40,0,0,0,208,72ZM24,88H96v72H24Zm88,72V88h96a24.1,24.1,0,0,1,24,24v48Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M208,72H24V48A8,8,0,0,0,8,48V208a8,8,0,0,0,16,0V176H232v32a8,8,0,0,0,16,0V112A40,40,0,0,0,208,72ZM24,88H96v72H24Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M208,74H22V48a6,6,0,0,0-12,0V208a6,6,0,0,0,12,0V174H234v34a6,6,0,0,0,12,0V112A38,38,0,0,0,208,74ZM22,86H98v76H22Zm88,76V86h98a26.1,26.1,0,0,1,26,26v50Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M208,76H20V48a4,4,0,0,0-8,0V208a4,4,0,0,0,8,0V172H236v36a4,4,0,0,0,8,0V112A36,36,0,0,0,208,76ZM20,84h80v80H20Zm88,80V84H208a28.1,28.1,0,0,1,28,28v52Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M208,72H24V48A8,8,0,0,0,8,48V208a8,8,0,0,0,16,0V176H232v32a8,8,0,0,0,16,0V112A40,40,0,0,0,208,72ZM24,88H96v72H24Zm88,72V88h96a24.1,24.1,0,0,1,24,24v48Z" />
        </>
      )}
    </svg>
  );
}

export function PhBeerBottle(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M248.5,39.5l-32-32a12,12,0,0,0-18.6,15L147,60.8l-37.4,7.4a12.2,12.2,0,0,0-6.1,3.3L18.8,156.2a28,28,0,0,0,0,39.6l41.4,41.4a28,28,0,0,0,39.6,0l84.7-84.7a12.2,12.2,0,0,0,3.3-6.1l7.4-37.4,38.3-50.9A11.5,11.5,0,0,0,240,60a12.2,12.2,0,0,0,8.5-3.5A12,12,0,0,0,248.5,39.5ZM108,195,61,148l35-35,47,47ZM77.2,220.2,35.8,178.8a3.9,3.9,0,0,1,0-5.6L44,165l47,47-8.2,8.2A3.9,3.9,0,0,1,77.2,220.2ZM174.4,96.8a12.3,12.3,0,0,0-2.2,4.8l-7.3,36.5L160,143,113,96l4.9-4.9,36.5-7.3a12.3,12.3,0,0,0,4.8-2.2l55.7-41.8,1.3,1.3Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M160,160l-56,56L40,152,96,96Z" opacity="0.2" />
          <path d="M245.7,42.3l-32-32a8.1,8.1,0,0,0-11.4,11.4l1.5,1.4L148.7,64.5l-38.3,7.7a8.5,8.5,0,0,0-4.1,2.1L21.7,159a23.9,23.9,0,0,0,0,34L63,234.3a23.9,23.9,0,0,0,34,0l84.7-84.6a8.5,8.5,0,0,0,2.1-4.1l7.7-38.3,41.4-55.1,1.4,1.5a8.2,8.2,0,0,0,11.4,0A8.1,8.1,0,0,0,245.7,42.3ZM96,107.3,148.7,160,104,204.7,51.3,152ZM74.3,223,33,181.7a8,8,0,0,1,0-11.4l7-7L92.7,216l-7,7A8.1,8.1,0,0,1,74.3,223ZM177.6,99.2a8.3,8.3,0,0,0-1.4,3.2l-7.6,37.7-8.6,8.6L107.3,96l8.6-8.6,37.7-7.6a8.3,8.3,0,0,0,3.2-1.4l58.4-43.8,6.2,6.2Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M245.7,42.3l-32-32a8.1,8.1,0,0,0-11.4,11.4l1.5,1.4L148.7,64.5l-38.3,7.7a8.5,8.5,0,0,0-4.1,2.1L21.7,159a23.9,23.9,0,0,0,0,34L63,234.3a23.9,23.9,0,0,0,34,0l84.7-84.6a8.5,8.5,0,0,0,2.1-4.1l7.7-38.3,41.4-55.1,1.4,1.5a8.2,8.2,0,0,0,11.4,0A8.1,8.1,0,0,0,245.7,42.3ZM74.3,223,33,181.7a8,8,0,0,1,0-11.4l7-7L92.7,216l-7,7A8.1,8.1,0,0,1,74.3,223ZM177.6,99.2a8.3,8.3,0,0,0-1.4,3.2l-7.6,37.7-8.6,8.6L107.3,96l8.6-8.6,37.7-7.6a8.3,8.3,0,0,0,3.2-1.4l58.4-43.8,6.2,6.2Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M244.2,43.8l-32-32a5.8,5.8,0,0,0-8.4,0,5.8,5.8,0,0,0,0,8.4l3.1,3.2-57.4,43-38.7,7.7a5.4,5.4,0,0,0-3,1.7L23.1,160.4a22,22,0,0,0,0,31.2l41.3,41.3a22,22,0,0,0,31.2,0l84.6-84.7a5.4,5.4,0,0,0,1.7-3l7.7-38.7,43-57.4,3.2,3.1a5.9,5.9,0,1,0,8.4-8.4ZM104,207.5,48.5,152,96,104.5,151.5,160ZM72.9,224.4,31.6,183.1a9.9,9.9,0,0,1,0-14.2l8.4-8.4L95.5,216l-8.4,8.4A10.1,10.1,0,0,1,72.9,224.4Zm106.3-124a6.6,6.6,0,0,0-1.1,2.4L170.5,141,160,151.5,104.5,96,115,85.5l38.2-7.6a6.6,6.6,0,0,0,2.4-1.1l59.8-44.9,8.7,8.7Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M242.8,45.2l-32-32a4,4,0,0,0-5.6,5.6l4.7,4.8L150.3,68.3l-39.1,7.8a3.1,3.1,0,0,0-2,1.1L24.5,161.9a19.8,19.8,0,0,0,0,28.2l41.4,41.4a19.8,19.8,0,0,0,28.2,0l68.7-68.7h0l16-16a3.1,3.1,0,0,0,1.1-2l7.8-39.1,44.7-59.6,4.8,4.7a3.9,3.9,0,0,0,5.6,0A3.9,3.9,0,0,0,242.8,45.2ZM104,210.3,45.7,152,96,101.7,154.3,160ZM88.5,225.9a12.2,12.2,0,0,1-17,0L30.1,184.5a12.1,12.1,0,0,1,0-17l9.9-9.8L98.3,216Zm92.3-124.3a3.3,3.3,0,0,0-.7,1.6L172.3,142,160,154.3,101.7,96,114,83.7l38.8-7.8a3.3,3.3,0,0,0,1.6-.7l61.2-45.9,11.1,11.1Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M245.7,42.3l-32-32a8.1,8.1,0,0,0-11.4,11.4l1.5,1.4L148.7,64.5l-38.3,7.7a8.5,8.5,0,0,0-4.1,2.1L21.7,159a23.9,23.9,0,0,0,0,34L63,234.3a23.9,23.9,0,0,0,34,0l84.7-84.6a8.5,8.5,0,0,0,2.1-4.1l7.7-38.3,41.4-55.1,1.4,1.5a8.2,8.2,0,0,0,11.4,0A8.1,8.1,0,0,0,245.7,42.3ZM104,204.7,51.3,152,96,107.3,148.7,160ZM74.3,223,33,181.7a8,8,0,0,1,0-11.4l7-7L92.7,216l-7,7A8.1,8.1,0,0,1,74.3,223ZM177.6,99.2a8.3,8.3,0,0,0-1.4,3.2l-7.6,37.7-8.6,8.6L107.3,96l8.6-8.6,37.7-7.6a8.3,8.3,0,0,0,3.2-1.4l58.4-43.8,6.2,6.2Z" />
        </>
      )}
    </svg>
  );
}

export function PhBehanceLogo(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M156,76a12,12,0,0,1,12-12h64a12,12,0,0,1,0,24H168A12,12,0,0,1,156,76Zm-20,82a46,46,0,0,1-46,46H28a12,12,0,0,1-12-12V64A12,12,0,0,1,28,52H78a42,42,0,0,1,34.5,65.9A46,46,0,0,1,136,158ZM40,112H78a18,18,0,0,0,0-36H40Zm72,46a22.1,22.1,0,0,0-22-22H40v44H90A22.1,22.1,0,0,0,112,158Zm140-6a12,12,0,0,1-12,12H174.7A28,28,0,0,0,200,180a27,27,0,0,0,12.2-2.8,12,12,0,0,1,10.5,21.6A52,52,0,1,1,252,152Zm-26.7-12a28,28,0,0,0-50.6,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M160,80a8,8,0,0,1,8-8h64a8,8,0,0,1,0,16H168A8,8,0,0,1,160,80Zm88,72a8,8,0,0,1-8,8H169a32.1,32.1,0,0,0,31,24,31.5,31.5,0,0,0,22.6-9.4,8,8,0,0,1,11.3,0,7.9,7.9,0,0,1,0,11.3A47.4,47.4,0,0,1,200,200a48,48,0,1,1,48-48Zm-17-8a32,32,0,0,0-62,0Zm-91,14a42,42,0,0,1-42,42H32a8,8,0,0,1-8-8V64a8,8,0,0,1,8-8H86a38,38,0,0,1,28.3,63.3A42,42,0,0,1,140,158ZM40,116H86a22,22,0,0,0,0-44H40Zm84,42a26.1,26.1,0,0,0-26-26H40v52H98A26.1,26.1,0,0,0,124,158Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M160,80a8,8,0,0,1,8-8h64a8,8,0,0,1,0,16H168A8,8,0,0,1,160,80Zm88,72a8,8,0,0,1-8,8H169a32.1,32.1,0,0,0,31,24,31.5,31.5,0,0,0,22.6-9.4,8,8,0,0,1,11.3,0,7.9,7.9,0,0,1,0,11.3A47.4,47.4,0,0,1,200,200a48,48,0,1,1,48-48Zm-17-8a32,32,0,0,0-62,0Zm-91,14a42,42,0,0,1-42,42H32a8,8,0,0,1-8-8V64a8,8,0,0,1,8-8H86a38,38,0,0,1,28.3,63.3A42,42,0,0,1,140,158ZM40,116H86a22,22,0,0,0,0-44H40Zm84,42a26.1,26.1,0,0,0-26-26H40v52H98A26.1,26.1,0,0,0,124,158Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M162,80a6,6,0,0,1,6-6h64a6,6,0,0,1,0,12H168A6,6,0,0,1,162,80Zm84,72a6,6,0,0,1-6,6H166.5A34.1,34.1,0,0,0,200,186a33.3,33.3,0,0,0,24-10,6.1,6.1,0,0,1,8.5,0,5.9,5.9,0,0,1,0,8.5A46,46,0,1,1,246,152Zm-12.5-6a34,34,0,0,0-67,0ZM138,158a40,40,0,0,1-40,40H32a6,6,0,0,1-6-6V64a6,6,0,0,1,6-6H86a36,36,0,0,1,24.8,62.1A40,40,0,0,1,138,158ZM38,118H86a24,24,0,0,0,0-48H38Zm88,40a28.1,28.1,0,0,0-28-28H38v56H98A28.1,28.1,0,0,0,126,158Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M164,80a4,4,0,0,1,4-4h64a4,4,0,0,1,0,8H168A4,4,0,0,1,164,80Zm80,72a4,4,0,0,1-4,4H164.2a36.1,36.1,0,0,0,61.3,21.5,4,4,0,0,1,5.6,5.6A44,44,0,1,1,244,152Zm-8.2-4a36,36,0,0,0-71.6,0ZM136,158a38,38,0,0,1-38,38H32a4,4,0,0,1-4-4V64a4,4,0,0,1,4-4H86a34,34,0,0,1,20.7,61A38,38,0,0,1,136,158ZM36,120H86a26,26,0,0,0,0-52H36Zm92,38a30.1,30.1,0,0,0-30-30H36v60H98A30.1,30.1,0,0,0,128,158Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M160,80a8,8,0,0,1,8-8h64a8,8,0,0,1,0,16H168A8,8,0,0,1,160,80Zm88,72a8,8,0,0,1-8,8H169a32.1,32.1,0,0,0,31,24,31.5,31.5,0,0,0,22.6-9.4,8,8,0,0,1,11.3,0,7.9,7.9,0,0,1,0,11.3A47.4,47.4,0,0,1,200,200a48,48,0,1,1,48-48Zm-17-8a32,32,0,0,0-62,0Zm-91,14a42,42,0,0,1-42,42H32a8,8,0,0,1-8-8V64a8,8,0,0,1,8-8H86a38,38,0,0,1,28.3,63.3A42,42,0,0,1,140,158ZM40,116H86a22,22,0,0,0,0-44H40Zm84,42a26.1,26.1,0,0,0-26-26H40v52H98A26.1,26.1,0,0,0,124,158Z" />
        </>
      )}
    </svg>
  );
}

export function PhBellRinging(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M211.8,112a83.6,83.6,0,0,0-83.2-84H128a83.8,83.8,0,0,0-83.8,84c0,33.4-6.8,52.1-12.5,61.9a20.1,20.1,0,0,0,0,20.1A19.7,19.7,0,0,0,49,204H84.2a44,44,0,0,0,87.6,0H207a19.7,19.7,0,0,0,17.3-10,20.1,20.1,0,0,0,0-20.1C218.6,164.1,211.8,145.4,211.8,112ZM128,220a20.1,20.1,0,0,1-19.6-16h39.2A20.1,20.1,0,0,1,128,220ZM55.6,180c10.3-21.5,12.6-48.4,12.6-68A60.1,60.1,0,0,1,85.9,69.3,59.3,59.3,0,0,1,128,52h.5a59.8,59.8,0,0,1,59.3,60c0,19.6,2.3,46.5,12.6,68ZM233.8,73.3a11.2,11.2,0,0,1-5.3,1.3,12,12,0,0,1-10.7-6.7,100.4,100.4,0,0,0-36.5-40.5A12,12,0,1,1,194.1,7.1a123.7,123.7,0,0,1,45.2,50.2A11.9,11.9,0,0,1,233.8,73.3ZM27.5,74.6a11.2,11.2,0,0,1-5.3-1.3,11.9,11.9,0,0,1-5.5-16A123.7,123.7,0,0,1,61.9,7.1,12,12,0,1,1,74.7,27.4,100.4,100.4,0,0,0,38.2,67.9,12,12,0,0,1,27.5,74.6Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M207,192H49a8,8,0,0,1-6.9-12c6.6-11.4,14.1-32.2,14.1-68a71.8,71.8,0,1,1,143.6,0c0,35.8,7.5,56.6,14.1,68A8,8,0,0,1,207,192Z"
            opacity="0.2"
          />
          <path d="M207.8,112a79.7,79.7,0,0,0-79.2-80H128a79.9,79.9,0,0,0-79.8,80c0,34.3-7.1,53.7-13,63.9a16.2,16.2,0,0,0-.1,16.1A15.9,15.9,0,0,0,49,200H88a40,40,0,0,0,80,0h39a15.9,15.9,0,0,0,13.9-8,16.2,16.2,0,0,0-.1-16.1C214.9,165.7,207.8,146.3,207.8,112ZM128,224a24.1,24.1,0,0,1-24-24h48A24.1,24.1,0,0,1,128,224ZM49,184c6.9-11.9,15.2-34.1,15.2-72A63.8,63.8,0,0,1,128,48h.5a62.9,62.9,0,0,1,44.8,18.9A63.6,63.6,0,0,1,191.8,112c0,37.9,8.3,60.1,15.2,72ZM224.9,73.3a9.3,9.3,0,0,1-3.5.8,7.9,7.9,0,0,1-7.2-4.5,97,97,0,0,0-35-38.8,8,8,0,0,1,8.5-13.6,111.7,111.7,0,0,1,40.8,45.4A8,8,0,0,1,224.9,73.3Zm-190.3.8a9.3,9.3,0,0,1-3.5-.8,8,8,0,0,1-3.6-10.7A111.7,111.7,0,0,1,68.3,17.2a8,8,0,0,1,8.5,13.6,97,97,0,0,0-35,38.8A7.9,7.9,0,0,1,34.6,74.1Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M34.6,74.1a9.3,9.3,0,0,1-3.5-.8,8,8,0,0,1-3.6-10.7A111.7,111.7,0,0,1,68.3,17.2a8,8,0,0,1,8.5,13.6,97,97,0,0,0-35,38.8A7.9,7.9,0,0,1,34.6,74.1ZM228.5,62.6a111.7,111.7,0,0,0-40.8-45.4,8,8,0,0,0-8.5,13.6,97,97,0,0,1,35,38.8,7.9,7.9,0,0,0,7.2,4.5,9.3,9.3,0,0,0,3.5-.8A8,8,0,0,0,228.5,62.6Zm-7.7,113.3a16.2,16.2,0,0,1,.1,16.1,15.9,15.9,0,0,1-13.9,8H168a40,40,0,0,1-80,0H49a15.9,15.9,0,0,1-13.9-8,16.2,16.2,0,0,1,.1-16.1c5.9-10.2,13-29.6,13-63.9A79.9,79.9,0,0,1,128,32h.6a79.7,79.7,0,0,1,79.2,80C207.8,146.3,214.9,165.7,220.8,175.9ZM152,200H104a24,24,0,0,0,48,0Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M205.8,112a77.7,77.7,0,0,0-77.2-78H128a78,78,0,0,0-77.8,78c0,34.8-7.2,54.5-13.3,64.9a14,14,0,0,0,0,14.1A13.7,13.7,0,0,0,49,198H90v2a38,38,0,0,0,76,0v-2h41a13.7,13.7,0,0,0,12.1-7,14,14,0,0,0,0-14.1C213,166.5,205.8,146.8,205.8,112ZM154,200a26,26,0,0,1-52,0v-2h52Zm54.7-15a1.8,1.8,0,0,1-1.7,1H49a1.8,1.8,0,0,1-1.7-1,2,2,0,0,1,0-2c6.8-11.7,14.9-33.5,14.9-71A65.9,65.9,0,0,1,128,46h.5a65.2,65.2,0,0,1,46.3,19.4,66.3,66.3,0,0,1,19,46.6c0,37.5,8.1,59.3,14.9,71A2,2,0,0,1,208.7,185ZM224,71.5a6.5,6.5,0,0,1-2.6.6,5.9,5.9,0,0,1-5.4-3.3,97.9,97.9,0,0,0-35.8-39.7,6,6,0,0,1-1.8-8.3,5.9,5.9,0,0,1,8.2-1.9,109.9,109.9,0,0,1,40.1,44.6A5.9,5.9,0,0,1,224,71.5Zm-189.4.6a6.5,6.5,0,0,1-2.6-.6,5.9,5.9,0,0,1-2.7-8A109.9,109.9,0,0,1,69.4,18.9a5.9,5.9,0,0,1,8.2,1.9,6,6,0,0,1-1.8,8.3A97.9,97.9,0,0,0,40,68.8,5.9,5.9,0,0,1,34.6,72.1Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M203.8,112a75.6,75.6,0,0,0-75.2-76H128a76,76,0,0,0-75.8,76c0,35.2-7.4,55.3-13.6,65.9a12.2,12.2,0,0,0,0,12.1A11.9,11.9,0,0,0,49,196H92v4a36,36,0,0,0,72,0v-4h43a11.9,11.9,0,0,0,10.4-6,12.2,12.2,0,0,0,0-12.1C211.2,167.3,203.8,147.2,203.8,112ZM156,200a28,28,0,0,1-56,0v-4h56Zm54.5-14a4.1,4.1,0,0,1-3.5,2H49a4.1,4.1,0,0,1-3.5-2,4,4,0,0,1,.1-4c6.6-11.5,14.6-33,14.6-70A67.9,67.9,0,0,1,128,44h.5a67.6,67.6,0,0,1,67.3,68c0,37,8,58.5,14.6,70A4,4,0,0,1,210.5,186ZM223.1,69.7a3.8,3.8,0,0,1-1.7.4,4,4,0,0,1-3.6-2.2,100.4,100.4,0,0,0-36.5-40.5,4,4,0,1,1,4.3-6.8,108.3,108.3,0,0,1,39.3,43.7A4,4,0,0,1,223.1,69.7Zm-188.5.4a3.8,3.8,0,0,1-1.7-.4,4,4,0,0,1-1.8-5.4A108.3,108.3,0,0,1,70.4,20.6a4,4,0,1,1,4.3,6.8A100.4,100.4,0,0,0,38.2,67.9,4,4,0,0,1,34.6,70.1Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M207.8,112a79.7,79.7,0,0,0-79.2-80H128a79.9,79.9,0,0,0-79.8,80c0,34.3-7.1,53.7-13,63.9a16.2,16.2,0,0,0-.1,16.1A15.9,15.9,0,0,0,49,200H88a40,40,0,0,0,80,0h39a15.9,15.9,0,0,0,13.9-8,16.2,16.2,0,0,0-.1-16.1C214.9,165.7,207.8,146.3,207.8,112ZM128,224a24.1,24.1,0,0,1-24-24h48A24.1,24.1,0,0,1,128,224ZM49,184c6.9-11.9,15.2-34.1,15.2-72A63.8,63.8,0,0,1,128,48h.5a62.9,62.9,0,0,1,44.8,18.9A63.6,63.6,0,0,1,191.8,112c0,37.9,8.3,60.1,15.2,72ZM224.9,73.3a9.3,9.3,0,0,1-3.5.8,7.9,7.9,0,0,1-7.2-4.5,97,97,0,0,0-35-38.8,8,8,0,0,1,8.5-13.6,111.7,111.7,0,0,1,40.8,45.4A8,8,0,0,1,224.9,73.3Zm-190.3.8a9.3,9.3,0,0,1-3.5-.8,8,8,0,0,1-3.6-10.7A111.7,111.7,0,0,1,68.3,17.2a8,8,0,0,1,8.5,13.6,97,97,0,0,0-35,38.8A7.9,7.9,0,0,1,34.6,74.1Z" />
        </>
      )}
    </svg>
  );
}

export function PhBellSimpleRinging(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M172,228a12,12,0,0,1-12,12H96a12,12,0,0,1,0-24h64A12,12,0,0,1,172,228Zm52.3-58.1a20.1,20.1,0,0,1,0,20.1A19.7,19.7,0,0,1,207,200H49a19.7,19.7,0,0,1-17.3-10,20.1,20.1,0,0,1,0-20.1c4.1-6.9,12.5-21.4,12.5-57.9A83.8,83.8,0,0,1,128,28h.6a83.6,83.6,0,0,1,83.2,84C211.8,148.5,220.2,163,224.3,169.9Zm-24,6.1c-6.6-13-12.5-31.5-12.5-64a59.8,59.8,0,0,0-59.3-60H128A59.3,59.3,0,0,0,85.9,69.3,60.1,60.1,0,0,0,68.2,112c0,32.5-5.9,51-12.5,64Zm39-118.7A123.7,123.7,0,0,0,194.1,7.1a12,12,0,1,0-12.8,20.3,100.4,100.4,0,0,1,36.5,40.5,12,12,0,0,0,10.7,6.7,11.2,11.2,0,0,0,5.3-1.3A11.9,11.9,0,0,0,239.3,57.3ZM38.2,67.9A100.4,100.4,0,0,1,74.7,27.4,12,12,0,1,0,61.9,7.1,123.7,123.7,0,0,0,16.7,57.3a11.9,11.9,0,0,0,5.5,16,11.2,11.2,0,0,0,5.3,1.3A12,12,0,0,0,38.2,67.9Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M207,192H49a8,8,0,0,1-6.9-12c6.6-11.4,14.1-32.2,14.1-68a71.8,71.8,0,1,1,143.6,0c0,35.8,7.5,56.6,14.1,68A8,8,0,0,1,207,192Z"
            opacity="0.2"
          />
          <path d="M168,224a8,8,0,0,1-8,8H96a8,8,0,0,1,0-16h64A8,8,0,0,1,168,224Zm52.8-48.1a16.2,16.2,0,0,1,.1,16.1,15.9,15.9,0,0,1-13.9,8H49a15.9,15.9,0,0,1-13.9-8,16.2,16.2,0,0,1,.1-16.1c5.9-10.2,13-29.6,13-63.9a79.8,79.8,0,1,1,159.6,0C207.8,146.3,214.9,165.7,220.8,175.9ZM207,184c-6.9-11.9-15.2-34.1-15.2-72a63.6,63.6,0,0,0-18.5-45.1A62.9,62.9,0,0,0,128.5,48H128a63.8,63.8,0,0,0-63.8,64c0,37.9-8.3,60.1-15.2,72ZM228.5,62.6a111.7,111.7,0,0,0-40.8-45.4,8,8,0,0,0-8.5,13.6,97,97,0,0,1,35,38.8,7.9,7.9,0,0,0,7.2,4.5,9.3,9.3,0,0,0,3.5-.8A8,8,0,0,0,228.5,62.6Zm-186.7,7a97,97,0,0,1,35-38.8,8,8,0,0,0-8.5-13.6A111.7,111.7,0,0,0,27.5,62.6a8,8,0,0,0,3.6,10.7,9.3,9.3,0,0,0,3.5.8A7.9,7.9,0,0,0,41.8,69.6Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M34.6,74.1a9.3,9.3,0,0,1-3.5-.8,8,8,0,0,1-3.6-10.7A111.7,111.7,0,0,1,68.3,17.2a8,8,0,0,1,8.5,13.6,97,97,0,0,0-35,38.8A7.9,7.9,0,0,1,34.6,74.1ZM160,216H96a8,8,0,0,0,0,16h64a8,8,0,0,0,0-16Zm47.8-104a79.7,79.7,0,0,0-79.2-80H128a79.9,79.9,0,0,0-79.8,80c0,34.3-7.1,53.7-13,63.9a16.2,16.2,0,0,0-.1,16.1A15.9,15.9,0,0,0,49,200H207a15.9,15.9,0,0,0,13.9-8,16.2,16.2,0,0,0-.1-16.1C214.9,165.7,207.8,146.3,207.8,112Zm20.7-49.4a111.7,111.7,0,0,0-40.8-45.4,8,8,0,0,0-8.5,13.6,97,97,0,0,1,35,38.8,7.9,7.9,0,0,0,7.2,4.5,9.3,9.3,0,0,0,3.5-.8A8,8,0,0,0,228.5,62.6Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M165.9,224a6,6,0,0,1-6,6h-64a6,6,0,1,1,0-12h64A6,6,0,0,1,165.9,224Zm53.2-47.1a14,14,0,0,1,0,14.1,13.7,13.7,0,0,1-12.1,7H49a13.7,13.7,0,0,1-12.1-7,14,14,0,0,1,0-14.1C43,166.5,50.2,146.8,50.2,112A78,78,0,0,1,128,34h.6a77.7,77.7,0,0,1,77.2,78C205.8,146.8,213,166.5,219.1,176.9ZM208.7,183c-6.8-11.7-14.9-33.5-14.9-71a66.3,66.3,0,0,0-19-46.6A65.2,65.2,0,0,0,128.5,46H128a65.9,65.9,0,0,0-65.8,66c0,37.5-8.1,59.3-14.9,71a2,2,0,0,0,0,2,1.8,1.8,0,0,0,1.7,1H207a1.8,1.8,0,0,0,1.7-1A2,2,0,0,0,208.7,183Zm18-119.5a109.9,109.9,0,0,0-40.1-44.6,5.9,5.9,0,0,0-8.2,1.9,6,6,0,0,0,1.8,8.3A97.9,97.9,0,0,1,216,68.8a5.9,5.9,0,0,0,5.4,3.3,6.5,6.5,0,0,0,2.6-.6A5.9,5.9,0,0,0,226.7,63.5ZM40,68.8A97.9,97.9,0,0,1,75.8,29.1a6,6,0,0,0,1.8-8.3,5.9,5.9,0,0,0-8.2-1.9A109.9,109.9,0,0,0,29.3,63.5a5.9,5.9,0,0,0,2.7,8,6.5,6.5,0,0,0,2.6.6A5.9,5.9,0,0,0,40,68.8Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M164,224a4,4,0,0,1-4,4H96a4,4,0,0,1,0-8h64A4,4,0,0,1,164,224Zm53.4-46.1a12.2,12.2,0,0,1,0,12.1,11.9,11.9,0,0,1-10.4,6H49a11.9,11.9,0,0,1-10.4-6,12.2,12.2,0,0,1,0-12.1c6.2-10.6,13.6-30.7,13.6-65.9A76,76,0,0,1,128,36h.6a75.6,75.6,0,0,1,75.2,76C203.8,147.2,211.2,167.3,217.4,177.9Zm-7,4.1c-6.6-11.5-14.6-33-14.6-70a67.6,67.6,0,0,0-67.3-68H128a67.9,67.9,0,0,0-67.8,68c0,37-8,58.5-14.6,70a4,4,0,0,0-.1,4,4.1,4.1,0,0,0,3.5,2H207a4.1,4.1,0,0,0,3.5-2A4,4,0,0,0,210.4,182ZM224.9,64.3a108.3,108.3,0,0,0-39.3-43.7,4,4,0,1,0-4.3,6.8,100.4,100.4,0,0,1,36.5,40.5,4,4,0,0,0,3.6,2.2,3.8,3.8,0,0,0,1.7-.4A4,4,0,0,0,224.9,64.3ZM38.2,67.9A100.4,100.4,0,0,1,74.7,27.4a4,4,0,1,0-4.3-6.8A108.3,108.3,0,0,0,31.1,64.3a4,4,0,0,0,1.8,5.4,3.8,3.8,0,0,0,1.7.4A4,4,0,0,0,38.2,67.9Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M168,224a8,8,0,0,1-8,8H96a8,8,0,0,1,0-16h64A8,8,0,0,1,168,224Zm52.8-48.1a16.2,16.2,0,0,1,.1,16.1,15.9,15.9,0,0,1-13.9,8H49a15.9,15.9,0,0,1-13.9-8,16.2,16.2,0,0,1,.1-16.1c5.9-10.2,13-29.6,13-63.9a79.8,79.8,0,1,1,159.6,0C207.8,146.3,214.9,165.7,220.8,175.9ZM207,184c-6.9-11.9-15.2-34.1-15.2-72a63.6,63.6,0,0,0-18.5-45.1A62.9,62.9,0,0,0,128.5,48H128a63.8,63.8,0,0,0-63.8,64c0,37.9-8.3,60.1-15.2,72ZM228.5,62.6a111.7,111.7,0,0,0-40.8-45.4,8,8,0,0,0-8.5,13.6,97,97,0,0,1,35,38.8,7.9,7.9,0,0,0,7.2,4.5,9.3,9.3,0,0,0,3.5-.8A8,8,0,0,0,228.5,62.6Zm-186.7,7a97,97,0,0,1,35-38.8,8,8,0,0,0-8.5-13.6A111.7,111.7,0,0,0,27.5,62.6a8,8,0,0,0,3.6,10.7,9.3,9.3,0,0,0,3.5.8A7.9,7.9,0,0,0,41.8,69.6Z" />
        </>
      )}
    </svg>
  );
}

export function PhBellSimpleSlash(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M172,228a12,12,0,0,1-12,12H96a12,12,0,0,1,0-24h64A12,12,0,0,1,172,228Zm44.1-3.1a12.1,12.1,0,0,1-17-.8L177.2,200H49a20.1,20.1,0,0,1-17.3-30.1c5.7-9.8,12.5-28.5,12.5-61.9v-4A84.3,84.3,0,0,1,54,64.5L39.1,48.1A12,12,0,0,1,56.9,31.9l160,176A12.1,12.1,0,0,1,216.1,224.9ZM155.4,176,71.6,83.9A61.5,61.5,0,0,0,68.2,104v4c0,19.5-2.3,46.4-12.6,68ZM104.7,48.7A59.5,59.5,0,0,1,128,44h.5c32.7.2,59.3,27.5,59.3,60.9V108c0,18,1.8,34.2,5.5,48.1a12,12,0,0,0,11.6,9,10.3,10.3,0,0,0,3-.4,12,12,0,0,0,8.6-14.6c-3.1-12-4.7-26.1-4.7-42.1v-3.1c0-46.5-37.3-84.6-83.2-84.9H128a83.6,83.6,0,0,0-32.8,6.6,12,12,0,0,0,9.5,22.1Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M56,104a71.9,71.9,0,0,1,72.5-72c39.8.3,71.5,33.2,71.5,72.9V112c0,35.8,7.5,56.6,14.1,67.9a8,8,0,0,1-6.9,12.1H48.8a8,8,0,0,1-6.9-12.1C48.5,168.6,56,147.8,56,112Z" />
          </g>
          <path d="M53.9,34.6A8,8,0,0,0,42.1,45.4L58.9,63.9A80.2,80.2,0,0,0,48.2,104v8c0,34.3-7.1,53.7-13,63.9A16,16,0,0,0,49,200H182.6l19.5,21.4A8,8,0,0,0,208,224a8.2,8.2,0,0,0,5.4-2.1,7.9,7.9,0,0,0,.5-11.3ZM49,184c6.9-12,15.2-34.1,15.2-72v-8a63.2,63.2,0,0,1,6.2-27.5L168.1,184Zm119,40a8,8,0,0,1-8,8H96a8,8,0,0,1,0-16h64A8,8,0,0,1,168,224ZM85.6,45.4a8.1,8.1,0,0,1,2.9-11A80.2,80.2,0,0,1,128.6,24c43.7.3,79.2,36.6,79.2,80.9V112c0,21.8,2.9,40.2,8.5,54.4a8,8,0,0,1-4.5,10.4,8.2,8.2,0,0,1-10.4-4.5c-6.4-16.2-9.6-36.4-9.6-60.3v-7.1c0-35.5-28.4-64.7-63.3-64.9a63.9,63.9,0,0,0-32,8.3A8,8,0,0,1,85.6,45.4Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M168,224a8,8,0,0,1-8,8H96a8,8,0,0,1,0-16h64A8,8,0,0,1,168,224Zm34.9-49.3a8,8,0,0,0,5.9,2.6,7.9,7.9,0,0,0,4.2-1.2,8,8,0,0,0,3.3-9.7c-5.6-14.2-8.5-32.6-8.5-54.4v-7.1c0-44.3-35.5-80.6-79.2-80.9A80.2,80.2,0,0,0,88.5,34.4a8,8,0,0,0-3.9,5.7,8.2,8.2,0,0,0,2,6.7ZM53.9,34.6A8,8,0,0,0,42.1,45.4L58.9,63.9A80.2,80.2,0,0,0,48.2,104v8c0,34.3-7.1,53.7-13,63.9A16,16,0,0,0,49,200H182.6l19.5,21.4A8,8,0,0,0,208,224a8.2,8.2,0,0,0,5.4-2.1,7.9,7.9,0,0,0,.5-11.3Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M52.4,36a5.9,5.9,0,0,0-8.8,8L61.4,63.7A77.7,77.7,0,0,0,50.2,104v8c0,34.8-7.2,54.5-13.3,64.9a14,14,0,0,0,0,14.1A13.7,13.7,0,0,0,49,198H183.5l20.1,22a5.9,5.9,0,0,0,4.4,2,5.7,5.7,0,0,0,4-1.6,5.8,5.8,0,0,0,.4-8.4ZM49,186a1.8,1.8,0,0,1-1.7-1,2,2,0,0,1,0-2c6.8-11.8,14.9-33.6,14.9-71v-8a65.3,65.3,0,0,1,7.7-31L172.6,186Zm117,38a6,6,0,0,1-6,6H96a6,6,0,0,1,0-12h64A6,6,0,0,1,166,224ZM87.3,44.4a5.9,5.9,0,0,1,2.2-8.2A77.7,77.7,0,0,1,128.6,26c42.6.3,77.2,35.7,77.2,78.9V112c0,22.1,2.9,40.7,8.6,55.1a6,6,0,0,1-3.4,7.8,5.4,5.4,0,0,1-2.2.4,6,6,0,0,1-5.6-3.8c-6.2-15.9-9.4-35.9-9.4-59.5v-7.1c0-36.6-29.3-66.7-65.3-66.9a65.6,65.6,0,0,0-33,8.6A6.1,6.1,0,0,1,87.3,44.4Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M51,37.3a4,4,0,1,0-6,5.4L63.9,63.4A75.6,75.6,0,0,0,52.2,104v8c0,35.2-7.4,55.3-13.6,65.9A12.1,12.1,0,0,0,49,196H184.4L205,218.7a4.1,4.1,0,0,0,5.7.3,4.2,4.2,0,0,0,.3-5.7ZM49,188a4.1,4.1,0,0,1-3.5-2,4.1,4.1,0,0,1,.1-4.1C52.2,170.5,60.2,149,60.2,112v-8a67.8,67.8,0,0,1,9.3-34.4L177.1,188Zm115,36a4,4,0,0,1-4,4H96a4,4,0,0,1,0-8h64A4,4,0,0,1,164,224ZM89,43.4a4,4,0,0,1,1.5-5.5A76,76,0,0,1,128.6,28c41.5.3,75.2,34.8,75.2,76.9V112c0,22.3,2.9,41.2,8.7,55.9a4,4,0,0,1-2.2,5.2l-1.5.2a3.9,3.9,0,0,1-3.7-2.5c-6.2-15.7-9.3-35.4-9.3-58.8v-7.1c0-37.7-30.2-68.6-67.3-68.9H128a66.7,66.7,0,0,0-33.5,8.9A4.1,4.1,0,0,1,89,43.4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M53.9,34.6A8,8,0,0,0,42.1,45.4L58.9,63.9A80.2,80.2,0,0,0,48.2,104v8c0,34.3-7.1,53.7-13,63.9A16,16,0,0,0,49,200H182.6l19.5,21.4A8,8,0,0,0,208,224a8.2,8.2,0,0,0,5.4-2.1,7.9,7.9,0,0,0,.5-11.3ZM49,184c6.9-12,15.2-34.1,15.2-72v-8a63.2,63.2,0,0,1,6.2-27.5L168.1,184Zm119,40a8,8,0,0,1-8,8H96a8,8,0,0,1,0-16h64A8,8,0,0,1,168,224ZM85.6,45.4a8.1,8.1,0,0,1,2.9-11A80.2,80.2,0,0,1,128.6,24c43.7.3,79.2,36.6,79.2,80.9V112c0,21.8,2.9,40.2,8.5,54.4a8,8,0,0,1-4.5,10.4,8.2,8.2,0,0,1-10.4-4.5c-6.4-16.2-9.6-36.4-9.6-60.3v-7.1c0-35.5-28.4-64.7-63.3-64.9a63.7,63.7,0,0,0-32,8.3A8,8,0,0,1,85.6,45.4Z" />
        </>
      )}
    </svg>
  );
}

export function PhBellSimpleZ(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M160,144a12,12,0,0,1-12,12H108a12,12,0,0,1-10.9-6.9,12.2,12.2,0,0,1,1.7-12.8L122.4,108H108a12,12,0,0,1,0-24h40a12,12,0,0,1,10.9,6.9,12.2,12.2,0,0,1-1.7,12.8L133.6,132H148A12,12,0,0,1,160,144Zm0,72H96a12,12,0,0,0,0,24h64a12,12,0,0,0,0-24Zm64.3-26A19.7,19.7,0,0,1,207,200H49a19.7,19.7,0,0,1-17.3-10,20.1,20.1,0,0,1,0-20.1c5.7-9.8,12.5-28.5,12.5-61.9v-4A83.8,83.8,0,0,1,128,20h.6c45.9.3,83.2,38.4,83.2,84.9V108c0,33.4,6.8,52.1,12.5,61.9A20.1,20.1,0,0,1,224.3,190Zm-23.9-14c-10.3-21.5-12.6-48.4-12.6-68v-3.1c0-33.3-26.6-60.7-59.3-60.9H128A59.3,59.3,0,0,0,85.9,61.3,60.1,60.1,0,0,0,68.2,104v4c0,19.6-2.3,46.5-12.6,68Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M207,192H49a8,8,0,0,1-6.9-12c6.6-11.4,14.1-32.2,14.1-68v-8a71.9,71.9,0,0,1,72.3-72c39.6.3,71.3,33.2,71.3,72.9V112c0,35.8,7.5,56.6,14.1,68A8,8,0,0,1,207,192Z"
            opacity="0.2"
          />
          <path d="M168,224a8,8,0,0,1-8,8H96a8,8,0,0,1,0-16h64A8,8,0,0,1,168,224Zm52.9-32a15.9,15.9,0,0,1-13.9,8H49a15.9,15.9,0,0,1-13.9-8,16.2,16.2,0,0,1,.1-16.1c5.9-10.2,13-29.6,13-63.9v-8A79.9,79.9,0,0,1,128,24h.6c43.7.3,79.2,36.6,79.2,80.9V112c0,34.3,7.1,53.7,13,63.9A16.2,16.2,0,0,1,220.9,192ZM207,184c-6.9-11.9-15.2-34.1-15.2-72v-7.1c0-35.5-28.4-64.6-63.3-64.9H128a63.8,63.8,0,0,0-63.8,64v8c0,37.9-8.3,60.1-15.2,72Zm-59-48H125.1l29-34.9a7.8,7.8,0,0,0,1.1-8.5A7.9,7.9,0,0,0,148,88H108a8,8,0,0,0,0,16h22.9l-29,34.9a7.8,7.8,0,0,0-1.1,8.5A7.9,7.9,0,0,0,108,152h40a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M168,224a8,8,0,0,1-8,8H96a8,8,0,0,1,0-16h64A8,8,0,0,1,168,224Zm52.9-32a15.9,15.9,0,0,1-13.9,8H49a15.9,15.9,0,0,1-13.9-8,16.2,16.2,0,0,1,.1-16.1c5.9-10.2,13-29.6,13-63.9v-8A79.9,79.9,0,0,1,128,24h.6c43.7.3,79.2,36.6,79.2,80.9V112c0,34.3,7.1,53.7,13,63.9A16.2,16.2,0,0,1,220.9,192ZM156,144a8,8,0,0,0-8-8H125.1l29-34.9a7.8,7.8,0,0,0,1.1-8.5A7.9,7.9,0,0,0,148,88H108a8,8,0,0,0,0,16h22.9l-29,34.9a7.8,7.8,0,0,0-1.1,8.5A7.9,7.9,0,0,0,108,152h40A8,8,0,0,0,156,144Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M166,224a6,6,0,0,1-6,6H96a6,6,0,0,1,0-12h64A6,6,0,0,1,166,224Zm53.1-33a13.7,13.7,0,0,1-12.1,7H49a13.7,13.7,0,0,1-12.1-7,14,14,0,0,1,0-14.1C43,166.5,50.2,146.8,50.2,112v-8A78,78,0,0,1,128,26h.6c42.6.3,77.2,35.7,77.2,78.9V112c0,34.8,7.2,54.5,13.3,64.9A14,14,0,0,1,219.1,191Zm-10.4-8c-6.8-11.7-14.9-33.5-14.9-71v-7.1c0-36.6-29.3-66.6-65.3-66.9H128a65.9,65.9,0,0,0-65.8,66v8c0,37.5-8.1,59.3-14.9,71a2,2,0,0,0,0,2,1.8,1.8,0,0,0,1.7,1H207a1.8,1.8,0,0,0,1.7-1A2,2,0,0,0,208.7,183ZM148,138H120.8l31.8-38.2a5.8,5.8,0,0,0,.8-6.3A5.9,5.9,0,0,0,148,90H108a6,6,0,0,0,0,12h27.2l-31.8,38.2a5.8,5.8,0,0,0-.8,6.3A5.9,5.9,0,0,0,108,150h40a6,6,0,0,0,0-12Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M164,224a4,4,0,0,1-4,4H96a4,4,0,0,1,0-8h64A4,4,0,0,1,164,224Zm53.4-34a11.9,11.9,0,0,1-10.4,6H49a11.9,11.9,0,0,1-10.4-6,12.2,12.2,0,0,1,0-12.1c6.2-10.6,13.6-30.7,13.6-65.9v-8A76,76,0,0,1,128,28h.6c41.5.3,75.2,34.8,75.2,76.9V112c0,35.2,7.4,55.3,13.6,65.9A12.2,12.2,0,0,1,217.4,190Zm-7-8c-6.6-11.5-14.6-33-14.6-70v-7.1c0-37.7-30.2-68.6-67.3-68.9H128a67.9,67.9,0,0,0-67.8,68v8c0,37-8,58.5-14.6,70a4,4,0,0,0-.1,4,4.1,4.1,0,0,0,3.5,2H207a4.1,4.1,0,0,0,3.5-2A4,4,0,0,0,210.4,182ZM148,140H116.5l34.6-41.4a4,4,0,0,0,.5-4.3A3.9,3.9,0,0,0,148,92H108a4,4,0,0,0,0,8h31.5l-34.6,41.4a4,4,0,0,0-.5,4.3A3.9,3.9,0,0,0,108,148h40a4,4,0,0,0,0-8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M168,224a8,8,0,0,1-8,8H96a8,8,0,0,1,0-16h64A8,8,0,0,1,168,224Zm52.9-32a15.9,15.9,0,0,1-13.9,8H49a15.9,15.9,0,0,1-13.9-8,16.2,16.2,0,0,1,.1-16.1c5.9-10.2,13-29.6,13-63.9v-8A79.9,79.9,0,0,1,128,24h.6c43.7.3,79.2,36.6,79.2,80.9V112c0,34.3,7.1,53.7,13,63.9A16.2,16.2,0,0,1,220.9,192ZM207,184c-6.9-11.9-15.2-34.1-15.2-72v-7.1c0-35.5-28.4-64.6-63.3-64.9H128a63.8,63.8,0,0,0-63.8,64v8c0,37.9-8.3,60.1-15.2,72Zm-59-48H125.1l29-34.9a7.8,7.8,0,0,0,1.1-8.5A7.9,7.9,0,0,0,148,88H108a8,8,0,0,0,0,16h22.9l-29,34.9a7.8,7.8,0,0,0-1.1,8.5A7.9,7.9,0,0,0,108,152h40a8,8,0,0,0,0-16Z" />
        </>
      )}
    </svg>
  );
}

export function PhBellSimple(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M172,228a12,12,0,0,1-12,12H96a12,12,0,0,1,0-24h64A12,12,0,0,1,172,228Zm52.3-38A19.9,19.9,0,0,1,207,200H49a20.1,20.1,0,0,1-17.3-30.1c5.7-9.8,12.5-28.5,12.5-61.9v-4A83.8,83.8,0,0,1,128,20h.6c45.9.3,83.2,38.4,83.2,84.9V108c0,33.4,6.8,52.1,12.5,61.9A20.1,20.1,0,0,1,224.3,190Zm-23.9-14c-10.3-21.6-12.6-48.5-12.6-68v-3.1c0-33.4-26.6-60.7-59.3-60.9H128a59.7,59.7,0,0,0-59.8,60v4c0,19.5-2.3,46.4-12.6,68Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M56,104a71.9,71.9,0,0,1,72.5-72c39.8.3,71.5,33.2,71.5,72.9V112c0,35.8,7.5,56.6,14.1,67.9a8,8,0,0,1-6.9,12.1H48.8a8,8,0,0,1-6.9-12.1C48.5,168.6,56,147.8,56,112Z" />
          </g>
          <path d="M168,224a8,8,0,0,1-8,8H96a8,8,0,0,1,0-16h64A8,8,0,0,1,168,224Zm52.9-32a15.9,15.9,0,0,1-13.9,8H49a16,16,0,0,1-13.8-24.1c5.9-10.2,13-29.6,13-63.9v-8A79.9,79.9,0,0,1,128,24h.6c43.7.3,79.2,36.6,79.2,80.9V112c0,34.3,7.1,53.7,13,63.9A16.2,16.2,0,0,1,220.9,192ZM207,184c-6.9-12-15.2-34.1-15.2-72v-7.1c0-35.5-28.4-64.7-63.3-64.9H128a63.8,63.8,0,0,0-63.8,64v8c0,37.9-8.3,60-15.2,72Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M221.1,192a15.9,15.9,0,0,1-13.9,8H48.8a15.9,15.9,0,0,1-13.9-8,15.8,15.8,0,0,1,.1-16.1c5.9-10.2,13-29.6,13-63.9v-8a79.9,79.9,0,0,1,80-80h.6c43.8.3,79.4,36.6,79.4,80.9V112c0,34.3,7.1,53.7,13,63.9A15.8,15.8,0,0,1,221.1,192Zm-61.2,24h-64a8,8,0,1,0,0,16h64a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M165.9,224a6,6,0,0,1-6,6h-64a6,6,0,1,1,0-12h64A6,6,0,0,1,165.9,224Zm53.2-33a13.7,13.7,0,0,1-12.1,7H49a13.7,13.7,0,0,1-12.1-7,14,14,0,0,1,0-14.1C43,166.5,50.2,146.8,50.2,112v-8A77.8,77.8,0,0,1,128,26h.6c42.6.3,77.2,35.7,77.2,78.9V112c0,34.8,7.2,54.5,13.3,64.9A14,14,0,0,1,219.1,191Zm-10.4-8c-6.8-11.8-14.9-33.6-14.9-71v-7.1c0-36.6-29.3-66.7-65.3-66.9H128a65.9,65.9,0,0,0-65.8,66v8c0,37.4-8.1,59.2-14.9,71a2,2,0,0,0,0,2,1.8,1.8,0,0,0,1.7,1H207a1.8,1.8,0,0,0,1.7-1A2,2,0,0,0,208.7,183Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M164,224a4,4,0,0,1-4,4H96a4,4,0,0,1,0-8h64A4,4,0,0,1,164,224Zm53.4-34a12.1,12.1,0,0,1-10.4,6H49a12.1,12.1,0,0,1-10.4-18.1c6.2-10.6,13.6-30.7,13.6-65.9v-8A76,76,0,0,1,128,28h.6c41.5.3,75.2,34.8,75.2,76.9V112c0,35.2,7.4,55.3,13.6,65.9A12.2,12.2,0,0,1,217.4,190Zm-7-8.1c-6.6-11.4-14.6-32.9-14.6-69.9v-7.1c0-37.7-30.2-68.6-67.3-68.9H128a67.8,67.8,0,0,0-67.8,68v8c0,37-8,58.5-14.6,69.9a4.1,4.1,0,0,0-.1,4.1,4.1,4.1,0,0,0,3.5,2H207a4.1,4.1,0,0,0,3.5-2A4.1,4.1,0,0,0,210.4,181.9Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M168,224a8,8,0,0,1-8,8H96a8,8,0,0,1,0-16h64A8,8,0,0,1,168,224Zm52.9-32a15.9,15.9,0,0,1-13.9,8H49a16,16,0,0,1-13.8-24.1c5.9-10.2,13-29.6,13-63.9v-8A79.9,79.9,0,0,1,128,24h.6c43.7.3,79.2,36.6,79.2,80.9V112c0,34.3,7.1,53.7,13,63.9A16.2,16.2,0,0,1,220.9,192ZM207,184c-6.9-12-15.2-34.1-15.2-72v-7.1c0-35.5-28.4-64.7-63.3-64.9H128a63.8,63.8,0,0,0-63.8,64v8c0,37.9-8.3,60-15.2,72Z" />
        </>
      )}
    </svg>
  );
}

export function PhBellSlash(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216.9,207.9l-160-176A12,12,0,0,0,39.1,48.1L54,64.5A84.3,84.3,0,0,0,44.2,104v8c0,33.4-6.8,52.1-12.5,61.9a20.1,20.1,0,0,0,0,20.1A19.7,19.7,0,0,0,49,204H84.2a44,44,0,0,0,87.6,0h9.1l18.2,20.1a12.1,12.1,0,0,0,17,.8A12.1,12.1,0,0,0,216.9,207.9ZM68.2,112v-8a59.4,59.4,0,0,1,3.5-20.1L159.1,180H55.6C65.9,158.5,68.2,131.6,68.2,112ZM128,220a20.1,20.1,0,0,1-19.6-16h39.2A20.1,20.1,0,0,1,128,220ZM88.9,42.4a12,12,0,0,1,6.4-15.7A82.6,82.6,0,0,1,128.6,20c45.9.3,83.2,38.4,83.2,84.9V112a181.6,181.6,0,0,0,3.5,37.2,12,12,0,0,1-9.2,14.2,10.7,10.7,0,0,1-2.5.3,12.2,12.2,0,0,1-11.8-9.5,208.6,208.6,0,0,1-4-42.2v-7.1c0-33.3-26.6-60.7-59.3-60.9H128a59.9,59.9,0,0,0-23.3,4.7A12,12,0,0,1,88.9,42.4Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M56.2,104a71.8,71.8,0,0,1,72.3-72c39.6.3,71.3,33.2,71.3,72.9V112c0,35.8,7.5,56.6,14.1,67.9A8,8,0,0,1,207,192H49a8,8,0,0,1-6.9-12.1c6.6-11.3,14.1-32.1,14.1-67.9Z" />
          </g>
          <path d="M53.9,34.6A8,8,0,0,0,42.1,45.4L58.9,63.9A80.2,80.2,0,0,0,48.2,104v8c0,34.3-7.1,53.7-13,63.9A16,16,0,0,0,49,200H88a40,40,0,0,0,80,0h14.6l19.5,21.4A8,8,0,0,0,208,224a8.2,8.2,0,0,0,5.4-2.1,7.9,7.9,0,0,0,.5-11.3ZM128,224a24.1,24.1,0,0,1-24-24h48A24.1,24.1,0,0,1,128,224ZM49,184c6.9-11.9,15.2-34.1,15.2-72v-8a63.2,63.2,0,0,1,6.2-27.5L168.1,184ZM85.6,45.4a8.1,8.1,0,0,1,2.9-11A80.5,80.5,0,0,1,128.6,24c43.7.3,79.2,36.6,79.2,80.9V112c0,21.9,2.8,40.1,8.4,54.4a8,8,0,0,1-4.5,10.4,9.3,9.3,0,0,1-2.9.5,7.8,7.8,0,0,1-7.4-5.1c-6.4-16.1-9.6-36.3-9.6-60.2v-7.1c0-35.5-28.4-64.6-63.3-64.9a63.7,63.7,0,0,0-32,8.3A8,8,0,0,1,85.6,45.4Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M86.6,46.8a8.1,8.1,0,0,1,1.9-12.4A80.2,80.2,0,0,1,128.6,24c43.7.3,79.2,36.6,79.2,80.9V112c0,21.9,2.8,40.1,8.4,54.4a8,8,0,0,1-13.3,8.3ZM213.9,210.6a7.9,7.9,0,0,1-.5,11.3A8.2,8.2,0,0,1,208,224a8,8,0,0,1-5.9-2.6L182.6,200H168a40,40,0,0,1-80,0H49a16,16,0,0,1-13.8-24.1c5.9-10.2,13-29.6,13-63.9v-8A80.2,80.2,0,0,1,58.9,63.9L42.1,45.4A8,8,0,0,1,53.9,34.6ZM152,200H104a24,24,0,0,0,48,0Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M52.4,36a5.9,5.9,0,0,0-8.8,8L61.4,63.7A77.9,77.9,0,0,0,50.2,104v8c0,34.8-7.2,54.5-13.3,64.9a14,14,0,0,0,0,14.1A13.7,13.7,0,0,0,49,198H90v2a38,38,0,0,0,76,0v-2h17.5l20.1,22a5.9,5.9,0,0,0,4.4,2,5.7,5.7,0,0,0,4-1.6,5.8,5.8,0,0,0,.4-8.4ZM154,200a26,26,0,0,1-52,0v-2h52ZM49,186a1.8,1.8,0,0,1-1.7-1,2,2,0,0,1,0-2c6.8-11.7,14.9-33.5,14.9-71v-8a65.3,65.3,0,0,1,7.7-31L172.6,186ZM87.3,44.4a6.1,6.1,0,0,1,2.2-8.2A77.7,77.7,0,0,1,128.6,26c42.6.3,77.2,35.7,77.2,78.9V112c0,22.1,2.9,40.6,8.6,55.1a6,6,0,0,1-3.4,7.8,5.7,5.7,0,0,1-2.2.4,6,6,0,0,1-5.6-3.8c-6.2-15.9-9.4-35.9-9.4-59.5v-7.1c0-36.6-29.3-66.6-65.3-66.9a65.6,65.6,0,0,0-33,8.6A6.1,6.1,0,0,1,87.3,44.4Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M51,37.3a4,4,0,1,0-6,5.4L63.9,63.4A75.6,75.6,0,0,0,52.2,104v8c0,35.2-7.4,55.3-13.6,65.9a12.2,12.2,0,0,0,0,12.1A11.9,11.9,0,0,0,49,196H92v4a36,36,0,0,0,72,0v-4h20.4L205,218.7a4.1,4.1,0,0,0,5.7.3,4.2,4.2,0,0,0,.3-5.7ZM156,200a28,28,0,0,1-56,0v-4h56ZM49,188a4.1,4.1,0,0,1-3.5-2,4,4,0,0,1,.1-4c6.6-11.5,14.6-33,14.6-70v-8a67.8,67.8,0,0,1,9.3-34.4L177.1,188ZM89,43.4a4.1,4.1,0,0,1,1.5-5.5A75.9,75.9,0,0,1,128,28h.6c41.5.3,75.2,34.8,75.2,76.9V112c0,22.4,2.9,41.1,8.7,55.9a3.9,3.9,0,0,1-2.2,5.1l-1.5.3a3.9,3.9,0,0,1-3.7-2.5c-6.2-15.7-9.3-35.4-9.3-58.8v-7.1c0-37.7-30.2-68.6-67.3-68.9H128a67.7,67.7,0,0,0-33.5,8.9A4.1,4.1,0,0,1,89,43.4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M53.9,34.6A8,8,0,0,0,42.1,45.4L58.9,63.9A80.2,80.2,0,0,0,48.2,104v8c0,34.3-7.1,53.7-13,63.9A16,16,0,0,0,49,200H88a40,40,0,0,0,80,0h14.6l19.5,21.4A8,8,0,0,0,208,224a8.2,8.2,0,0,0,5.4-2.1,7.9,7.9,0,0,0,.5-11.3ZM128,224a24.1,24.1,0,0,1-24-24h48A24.1,24.1,0,0,1,128,224ZM49,184c6.9-11.9,15.2-34.1,15.2-72v-8a63.2,63.2,0,0,1,6.2-27.5L168.1,184ZM85.6,45.4a8.1,8.1,0,0,1,2.9-11A80.5,80.5,0,0,1,128.6,24c43.7.3,79.2,36.6,79.2,80.9V112c0,21.9,2.8,40.1,8.4,54.4a8,8,0,0,1-4.5,10.4,9.3,9.3,0,0,1-2.9.5,7.8,7.8,0,0,1-7.4-5.1c-6.4-16.1-9.6-36.3-9.6-60.2v-7.1c0-35.5-28.4-64.6-63.3-64.9a63.7,63.7,0,0,0-32,8.3A8,8,0,0,1,85.6,45.4Z" />
        </>
      )}
    </svg>
  );
}

export function PhBellZ(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M160,144a12,12,0,0,1-12,12H108a12,12,0,0,1-10.9-6.9,12.2,12.2,0,0,1,1.7-12.8L122.4,108H108a12,12,0,0,1,0-24h40a12,12,0,0,1,10.9,6.9,12.2,12.2,0,0,1-1.7,12.8L133.6,132H148A12,12,0,0,1,160,144Zm64.3,50A19.7,19.7,0,0,1,207,204H171.8a44,44,0,0,1-87.6,0H49a19.7,19.7,0,0,1-17.3-10,20.1,20.1,0,0,1,0-20.1c5.7-9.8,12.5-28.5,12.5-61.9v-8A83.8,83.8,0,0,1,128,20h.6c45.9.3,83.2,38.4,83.2,84.9V112c0,33.4,6.8,52.1,12.5,61.9A20.1,20.1,0,0,1,224.3,194Zm-76.7,10H108.4a20,20,0,0,0,39.2,0Zm52.8-24c-10.3-21.5-12.6-48.4-12.6-68v-7.1c0-33.3-26.6-60.7-59.3-60.9H128A59.3,59.3,0,0,0,85.9,61.3,60.1,60.1,0,0,0,68.2,104v8c0,19.6-2.3,46.5-12.6,68Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M207,192H49a8,8,0,0,1-6.9-12c6.6-11.4,14.1-32.2,14.1-68v-8a71.9,71.9,0,0,1,72.3-72c39.6.3,71.3,33.2,71.3,72.9V112c0,35.8,7.5,56.6,14.1,68A8,8,0,0,1,207,192Z"
            opacity="0.2"
          />
          <path d="M220.8,175.9c-5.9-10.2-13-29.6-13-63.9v-7.1c0-44.3-35.5-80.6-79.2-80.9H128a79.9,79.9,0,0,0-79.8,80v8c0,34.3-7.1,53.7-13,63.9a16.2,16.2,0,0,0-.1,16.1A15.9,15.9,0,0,0,49,200H88a40,40,0,0,0,80,0h39a15.9,15.9,0,0,0,13.9-8A16.2,16.2,0,0,0,220.8,175.9ZM128,224a24.1,24.1,0,0,1-24-24h48A24.1,24.1,0,0,1,128,224ZM49,184c6.9-11.9,15.2-34.1,15.2-72v-8A63.8,63.8,0,0,1,128,40h.5c34.9.3,63.3,29.4,63.3,64.9V112c0,37.9,8.3,60.1,15.2,72Zm107-40a8,8,0,0,1-8,8H108a7.9,7.9,0,0,1-7.2-4.6,7.8,7.8,0,0,1,1.1-8.5l29-34.9H108a8,8,0,0,1,0-16h40a7.9,7.9,0,0,1,7.2,4.6,7.8,7.8,0,0,1-1.1,8.5l-29,34.9H148A8,8,0,0,1,156,144Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M220.8,175.9c-5.9-10.2-13-29.6-13-63.9v-7.1c0-44.3-35.5-80.6-79.2-80.9H128a79.9,79.9,0,0,0-79.8,80v8c0,34.3-7.1,53.7-13,63.9a16.2,16.2,0,0,0-.1,16.1A15.9,15.9,0,0,0,49,200H88a40,40,0,0,0,80,0h39a15.9,15.9,0,0,0,13.9-8A16.2,16.2,0,0,0,220.8,175.9ZM128,224a24.1,24.1,0,0,1-24-24h48A24.1,24.1,0,0,1,128,224Zm20-72H108a7.9,7.9,0,0,1-7.2-4.6,7.8,7.8,0,0,1,1.1-8.5l29-34.9H108a8,8,0,0,1,0-16h40a7.9,7.9,0,0,1,7.2,4.6,7.8,7.8,0,0,1-1.1,8.5l-29,34.9H148a8,8,0,0,1,0,16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M219.1,176.9c-6.1-10.4-13.3-30.1-13.3-64.9v-7.1c0-43.2-34.6-78.6-77.2-78.9H128a78,78,0,0,0-77.8,78v8c0,34.8-7.2,54.5-13.3,64.9a14,14,0,0,0,0,14.1A13.7,13.7,0,0,0,49,198H90v2a38,38,0,0,0,76,0v-2h41a13.7,13.7,0,0,0,12.1-7A14,14,0,0,0,219.1,176.9ZM154,200a26,26,0,0,1-52,0v-2h52Zm54.7-15a1.8,1.8,0,0,1-1.7,1H49a1.8,1.8,0,0,1-1.7-1,2,2,0,0,1,0-2c6.8-11.7,14.9-33.5,14.9-71v-8A65.9,65.9,0,0,1,128,38h.5c36,.3,65.3,30.3,65.3,66.9V112c0,37.5,8.1,59.3,14.9,71A2,2,0,0,1,208.7,185ZM154,144a6,6,0,0,1-6,6H108a5.9,5.9,0,0,1-5.4-3.5,5.8,5.8,0,0,1,.8-6.3L135.2,102H108a6,6,0,0,1,0-12h40a5.9,5.9,0,0,1,5.4,3.5,5.8,5.8,0,0,1-.8,6.3L120.8,138H148A6,6,0,0,1,154,144Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M217.4,177.9c-6.2-10.6-13.6-30.7-13.6-65.9v-7.1c0-42.1-33.7-76.6-75.2-76.9H128a76,76,0,0,0-75.8,76v8c0,35.2-7.4,55.3-13.6,65.9a12.2,12.2,0,0,0,0,12.1A11.9,11.9,0,0,0,49,196H92v4a36,36,0,0,0,72,0v-4h43a11.9,11.9,0,0,0,10.4-6A12.2,12.2,0,0,0,217.4,177.9ZM156,200a28,28,0,0,1-56,0v-4h56Zm54.5-14a4.1,4.1,0,0,1-3.5,2H49a4.1,4.1,0,0,1-3.5-2,4,4,0,0,1,.1-4c6.6-11.5,14.6-33,14.6-70v-8A67.9,67.9,0,0,1,128,36h.5c37.1.3,67.3,31.2,67.3,68.9V112c0,37,8,58.5,14.6,70A4,4,0,0,1,210.5,186ZM152,144a4,4,0,0,1-4,4H108a3.9,3.9,0,0,1-3.6-2.3,4,4,0,0,1,.5-4.3L139.5,100H108a4,4,0,0,1,0-8h40a3.9,3.9,0,0,1,3.6,2.3,4,4,0,0,1-.5,4.3L116.5,140H148A4,4,0,0,1,152,144Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M220.8,175.9c-5.9-10.2-13-29.6-13-63.9v-7.1c0-44.3-35.5-80.6-79.2-80.9H128a79.9,79.9,0,0,0-79.8,80v8c0,34.3-7.1,53.7-13,63.9a16.2,16.2,0,0,0-.1,16.1A15.9,15.9,0,0,0,49,200H88a40,40,0,0,0,80,0h39a15.9,15.9,0,0,0,13.9-8A16.2,16.2,0,0,0,220.8,175.9ZM128,224a24.1,24.1,0,0,1-24-24h48A24.1,24.1,0,0,1,128,224ZM49,184c6.9-11.9,15.2-34.1,15.2-72v-8A63.8,63.8,0,0,1,128,40h.5c34.9.3,63.3,29.4,63.3,64.9V112c0,37.9,8.3,60.1,15.2,72Zm107-40a8,8,0,0,1-8,8H108a7.9,7.9,0,0,1-7.2-4.6,7.8,7.8,0,0,1,1.1-8.5l29-34.9H108a8,8,0,0,1,0-16h40a7.9,7.9,0,0,1,7.2,4.6,7.8,7.8,0,0,1-1.1,8.5l-29,34.9H148A8,8,0,0,1,156,144Z" />
        </>
      )}
    </svg>
  );
}

export function PhBell(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M224.3,173.9c-5.7-9.8-12.5-28.5-12.5-61.9v-7.1c0-46.5-37.3-84.6-83.2-84.9H128a83.8,83.8,0,0,0-83.8,84v8c0,33.4-6.8,52.1-12.5,61.9A20.1,20.1,0,0,0,49,204H84.2a44,44,0,0,0,87.6,0H207a20.1,20.1,0,0,0,17.3-30.1ZM128,220a20.1,20.1,0,0,1-19.6-16h39.2A20.1,20.1,0,0,1,128,220ZM55.6,180c10.3-21.6,12.6-48.5,12.6-68v-8A59.7,59.7,0,0,1,128,44h.5c32.7.2,59.3,27.5,59.3,60.9V112c0,19.5,2.3,46.4,12.6,68Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M56.2,104a71.8,71.8,0,0,1,72.3-72c39.6.3,71.3,33.2,71.3,72.9V112c0,35.8,7.5,56.6,14.1,67.9A8,8,0,0,1,207,192H49a8,8,0,0,1-6.9-12.1c6.6-11.3,14.1-32.1,14.1-67.9Z" />
          </g>
          <path d="M220.8,175.9c-5.9-10.2-13-29.6-13-63.9v-7.1c0-44.3-35.5-80.6-79.2-80.9H128a79.9,79.9,0,0,0-79.8,80v8c0,34.3-7.1,53.7-13,63.9A16,16,0,0,0,49,200H88a40,40,0,0,0,80,0h39a15.9,15.9,0,0,0,13.9-8A16.2,16.2,0,0,0,220.8,175.9ZM128,224a24.1,24.1,0,0,1-24-24h48A24.1,24.1,0,0,1,128,224ZM49,184c6.9-12,15.2-34.1,15.2-72v-8A63.8,63.8,0,0,1,128,40h.5c34.9.2,63.3,29.4,63.3,64.9V112c0,37.9,8.3,60,15.2,72Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M220.8,175.9c-5.9-10.2-13-29.6-13-63.9v-7.1c0-44.3-35.5-80.6-79.2-80.9H128a79.9,79.9,0,0,0-79.8,80v8c0,34.3-7.1,53.7-13,63.9A16,16,0,0,0,49,200H88a40,40,0,0,0,80,0h39a15.9,15.9,0,0,0,13.9-8A16.2,16.2,0,0,0,220.8,175.9ZM128,224a24.1,24.1,0,0,1-24-24h48A24.1,24.1,0,0,1,128,224Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M219.1,176.9c-6.1-10.4-13.3-30.1-13.3-64.9v-7.1c0-43.2-34.6-78.6-77.2-78.9H128a77.8,77.8,0,0,0-77.8,78v8c0,34.8-7.2,54.5-13.3,64.9a14,14,0,0,0,0,14.1A13.7,13.7,0,0,0,49,198H90v2a38,38,0,0,0,76,0v-2h41a13.7,13.7,0,0,0,12.1-7A14,14,0,0,0,219.1,176.9ZM154,200a26,26,0,0,1-52,0v-2h52Zm54.7-15a1.8,1.8,0,0,1-1.7,1H49a1.8,1.8,0,0,1-1.7-1,2,2,0,0,1,0-2c6.8-11.8,14.9-33.6,14.9-71v-8A65.9,65.9,0,0,1,128,38h.5c36,.2,65.3,30.3,65.3,66.9V112c0,37.4,8.1,59.2,14.9,71A2,2,0,0,1,208.7,185Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M217.4,177.9c-6.2-10.6-13.6-30.7-13.6-65.9v-7.1c0-42.1-33.7-76.6-75.2-76.9H128a76,76,0,0,0-75.8,76v8c0,35.2-7.4,55.3-13.6,65.9A12.1,12.1,0,0,0,49,196H92v4a36,36,0,0,0,72,0v-4h43a12.1,12.1,0,0,0,10.4-18.1ZM156,200a28,28,0,0,1-56,0v-4h56Zm54.5-14a4.1,4.1,0,0,1-3.5,2H49a4.1,4.1,0,0,1-3.5-2,4.1,4.1,0,0,1,.1-4.1C52.2,170.5,60.2,149,60.2,112v-8A67.8,67.8,0,0,1,128,36h.5c37.1.3,67.3,31.2,67.3,68.9V112c0,37,8,58.5,14.6,69.9A4.1,4.1,0,0,1,210.5,186Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M220.8,175.9c-5.9-10.2-13-29.6-13-63.9v-7.1c0-44.3-35.5-80.6-79.2-80.9H128a79.9,79.9,0,0,0-79.8,80v8c0,34.3-7.1,53.7-13,63.9A16,16,0,0,0,49,200H88a40,40,0,0,0,80,0h39a15.9,15.9,0,0,0,13.9-8A16.2,16.2,0,0,0,220.8,175.9ZM128,224a24.1,24.1,0,0,1-24-24h48A24.1,24.1,0,0,1,128,224ZM49,184c6.9-12,15.2-34.1,15.2-72v-8A63.8,63.8,0,0,1,128,40h.5c34.9.2,63.3,29.4,63.3,64.9V112c0,37.9,8.3,60,15.2,72Z" />
        </>
      )}
    </svg>
  );
}

export function PhBezierCurve(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M224.3,141a100.7,100.7,0,0,0-31.4-49H240a12,12,0,0,0,0-24H161.9a35.9,35.9,0,0,0-67.8,0H16a12,12,0,0,0,0,24H63.1a100.7,100.7,0,0,0-31.4,49A35.9,35.9,0,1,0,56,143.7,76.7,76.7,0,0,1,97.1,98.5a36,36,0,0,0,61.8,0A76.7,76.7,0,0,1,200,143.7a36.1,36.1,0,1,0,24.3-2.7ZM40,188a12,12,0,1,1,12-12A12,12,0,0,1,40,188Zm88-96a12,12,0,1,1,12-12A12,12,0,0,1,128,92Zm88,96a12,12,0,1,1,12-12A12,12,0,0,1,216,188Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M152,80a24,24,0,1,1-24-24A23.9,23.9,0,0,1,152,80ZM40,152a24,24,0,1,0,24,24A23.9,23.9,0,0,0,40,152Zm176,0a24,24,0,1,0,24,24A23.9,23.9,0,0,0,216,152Z"
            opacity="0.2"
          />
          <path d="M221.1,144.4A97,97,0,0,0,181,88h59a8,8,0,0,0,0-16H159a32,32,0,0,0-62,0H16a8,8,0,0,0,0,16H75a97,97,0,0,0-40.1,56.4A32,32,0,1,0,51.1,146,80.5,80.5,0,0,1,99,93.4a31.9,31.9,0,0,0,58,0A80.5,80.5,0,0,1,204.9,146a32,32,0,1,0,16.2-1.6ZM56,176a16,16,0,1,1-16-16A16,16,0,0,1,56,176Zm72-80a16,16,0,1,1,16-16A16,16,0,0,1,128,96Zm88,96a16,16,0,1,1,16-16A16,16,0,0,1,216,192Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M221,144.4A95.9,95.9,0,0,0,181,88h59a8,8,0,0,0,0-16H159a32,32,0,0,0-62,0H16a8,8,0,0,0,0,16H75a95.9,95.9,0,0,0-40,56.4A32,32,0,1,0,71,184H185a32,32,0,1,0,36-39.6ZM40,192a16,16,0,1,1,16-16A16,16,0,0,1,40,192ZM128,64a16,16,0,1,1-16,16A16,16,0,0,1,128,64Zm88,128a16,16,0,1,1,16-16A16,16,0,0,1,216,192Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M219.4,146.2A94.6,94.6,0,0,0,173.9,86H240a6,6,0,0,0,0-12H157.4a30,30,0,0,0-58.8,0H16a6,6,0,0,0,0,12H82.1a94.6,94.6,0,0,0-45.5,60.2,30.1,30.1,0,1,0,12,1.1,82.5,82.5,0,0,1,51.5-56.4,29.9,29.9,0,0,0,55.8,0,82.5,82.5,0,0,1,51.5,56.4,29.9,29.9,0,1,0,12-1.1ZM58,176a18,18,0,1,1-18-18A18.1,18.1,0,0,1,58,176Zm70-78a18,18,0,1,1,18-18A18.1,18.1,0,0,1,128,98Zm88,96a18,18,0,1,1,18-18A18.1,18.1,0,0,1,216,194Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M217.8,148.1A92.6,92.6,0,0,0,165.5,84H240a4,4,0,0,0,0-8H155.7a28,28,0,0,0-55.4,0H16a4,4,0,0,0,0,8H90.5a92.6,92.6,0,0,0-52.3,64.1,27.9,27.9,0,1,0,8,.6,84.6,84.6,0,0,1,55.1-60.3,28,28,0,0,0,53.4,0,84.6,84.6,0,0,1,55.1,60.3,28.1,28.1,0,1,0,8-.6ZM60,176a20,20,0,1,1-20-20A20.1,20.1,0,0,1,60,176Zm68-76a20,20,0,1,1,20-20A20.1,20.1,0,0,1,128,100Zm88,96a20,20,0,1,1,20-20A20.1,20.1,0,0,1,216,196Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M221.1,144.4A97,97,0,0,0,181,88h59a8,8,0,0,0,0-16H159a32,32,0,0,0-62,0H16a8,8,0,0,0,0,16H75a97,97,0,0,0-40.1,56.4A32,32,0,1,0,51.1,146,80.5,80.5,0,0,1,99,93.4a31.9,31.9,0,0,0,58,0A80.5,80.5,0,0,1,204.9,146a32,32,0,1,0,16.2-1.6ZM56,176a16,16,0,1,1-16-16A16,16,0,0,1,56,176Zm72-80a16,16,0,1,1,16-16A16,16,0,0,1,128,96Zm88,96a16,16,0,1,1,16-16A16,16,0,0,1,216,192Z" />
        </>
      )}
    </svg>
  );
}

export function PhBicycle(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M204,108a50.2,50.2,0,0,0-15.1,2.3L168.9,76H192a4,4,0,0,1,4,4,12,12,0,0,0,24,0,28.1,28.1,0,0,0-28-28H148a12,12,0,0,0-10.4,6,12.2,12.2,0,0,0,0,12l8.2,14H109.6L94.4,58A12.1,12.1,0,0,0,84,52H52a12,12,0,0,0,0,24H77.1L88.2,95,74,112.9a52.1,52.1,0,1,0,18.8,14.9l8.4-10.6L118,146a11.8,11.8,0,0,0,10.3,6,11.9,11.9,0,0,0,10.4-18l-15.1-26h36.2l8.4,14.4A51.9,51.9,0,1,0,204,108ZM80,160a28,28,0,1,1-28-28,32,32,0,0,1,6.3.7L42.6,152.6a12,12,0,0,0,2,16.8A11.5,11.5,0,0,0,52,172a12.1,12.1,0,0,0,9.4-4.6l15.7-19.8A27.6,27.6,0,0,1,80,160Zm124,28a28.1,28.1,0,0,1-28-28,27.6,27.6,0,0,1,4.9-15.8L193.6,166a12,12,0,0,0,10.4,6,11.7,11.7,0,0,0,6-1.6,12,12,0,0,0,4.4-16.4l-12.8-21.9H204a28,28,0,0,1,0,56Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M248,160a40,40,0,1,1-40-40A40,40,0,0,1,248,160ZM48,120a40,40,0,1,0,40,40A40,40,0,0,0,48,120Z"
            opacity="0.2"
          />
          <path d="M208,112a47,47,0,0,0-16.9,3.1L165.9,72H192a8,8,0,0,1,8,8,8,8,0,0,0,16,0,24.1,24.1,0,0,0-24-24H152a7.8,7.8,0,0,0-6.9,4,7.7,7.7,0,0,0,0,8l11.6,20H99.3L82.9,60A7.9,7.9,0,0,0,76,56H48a8,8,0,0,0,0,16H71.4L85.1,95.5,69.4,117.1A46.6,46.6,0,0,0,48,112a48.1,48.1,0,1,0,34.3,14.5l11.6-15.9L125.1,164a8,8,0,1,0,13.8-8l-30.3-52h57.5l11.2,19.2A47.9,47.9,0,1,0,208,112ZM48,192a32,32,0,0,1,0-64,31,31,0,0,1,11.8,2.3l-18.3,25a8,8,0,0,0,1.8,11.2A8.2,8.2,0,0,0,48,168a7.9,7.9,0,0,0,6.5-3.3l18.2-25A31.5,31.5,0,0,1,80,160,32.1,32.1,0,0,1,48,192Zm160,0a32.1,32.1,0,0,1-32-32,31.6,31.6,0,0,1,9.5-22.7L201.1,164a8,8,0,1,0,13.8-8l-15.6-26.8A31.5,31.5,0,0,1,208,128a32,32,0,0,1,0,64Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M54.5,164.7l27.8-38.2A47.9,47.9,0,1,1,48,112a46.6,46.6,0,0,1,21.4,5.1L41.5,155.3a8,8,0,0,0,1.8,11.2A8.2,8.2,0,0,0,48,168,7.9,7.9,0,0,0,54.5,164.7ZM165.9,72H192a8,8,0,0,1,8,8,8,8,0,0,0,16,0,24.1,24.1,0,0,0-24-24H152a7.8,7.8,0,0,0-6.9,4,7.7,7.7,0,0,0,0,8l11.6,20H99.3L82.9,60A7.9,7.9,0,0,0,76,56H48a8,8,0,0,0,0,16H71.4L85.1,95.5,69.4,117.1a48.1,48.1,0,0,1,12.9,9.4l11.6-15.9L125.1,164a8,8,0,1,0,13.8-8l-30.3-52h57.5l11.2,19.2a46.5,46.5,0,0,1,13.8-8.1ZM208,112a47,47,0,0,0-16.9,3.1L214.9,156a8,8,0,1,1-13.8,8l-23.8-40.8A47.9,47.9,0,1,0,208,112Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M208,114a46,46,0,0,0-17.8,3.6L162.4,70H192a10,10,0,0,1,10,10,6,6,0,0,0,12,0,22.1,22.1,0,0,0-22-22H152a6,6,0,0,0-5.2,3,5.9,5.9,0,0,0,0,6l13.4,23H98.1L81.2,61A6,6,0,0,0,76,58H48a6,6,0,0,0,0,12H72.6L87.5,95.6,70,119.6A45.2,45.2,0,0,0,48,114a46.1,46.1,0,1,0,31.7,12.7L94.1,107l32.7,56a6,6,0,0,0,5.2,3,6.4,6.4,0,0,0,3-.8,6,6,0,0,0,2.2-8.2l-32.1-55h62.1l12.7,21.7A45.9,45.9,0,1,0,208,114ZM82,160a33.9,33.9,0,1,1-19.1-30.6L43.2,156.5a5.8,5.8,0,0,0,1.3,8.3A5.7,5.7,0,0,0,48,166a5.9,5.9,0,0,0,4.8-2.5l19.8-27A34.1,34.1,0,0,1,82,160Zm126,34a34,34,0,0,1-22-59.9L202.8,163a6,6,0,0,0,5.2,3,6.4,6.4,0,0,0,3-.8,6,6,0,0,0,2.2-8.2l-16.9-28.9A32.8,32.8,0,0,1,208,126a34,34,0,0,1,0,68Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M208,116a44.1,44.1,0,0,0-18.6,4.1L159,68h33a12,12,0,0,1,12,12,4,4,0,0,0,8,0,20.1,20.1,0,0,0-20-20H152a4.2,4.2,0,0,0-3.5,2,4.1,4.1,0,0,0,0,4l15.2,26H97L79.5,62A4.3,4.3,0,0,0,76,60H48a4,4,0,0,0,0,8H73.7L89.9,95.8,70.6,122.3A44.3,44.3,0,1,0,77,127l17.3-23.7L128.5,162a4.1,4.1,0,0,0,3.5,2,4.6,4.6,0,0,0,2-.5,4.1,4.1,0,0,0,1.5-5.5l-33.9-58h66.8l14.1,24.2A43.9,43.9,0,1,0,208,116ZM84,160a36,36,0,1,1-36-36,35.5,35.5,0,0,1,17.8,4.7l-21,28.9a4.1,4.1,0,0,0,.8,5.6,3.8,3.8,0,0,0,2.4.8,4.2,4.2,0,0,0,3.2-1.6l21.1-28.9A35.8,35.8,0,0,1,84,160Zm124,36a36,36,0,0,1-21.5-64.9l18,30.9a4.1,4.1,0,0,0,3.5,2,4.6,4.6,0,0,0,2-.5,4.1,4.1,0,0,0,1.5-5.5l-18.1-30.9A36,36,0,1,1,208,196Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M208,112a47,47,0,0,0-16.9,3.1L165.9,72H192a8,8,0,0,1,8,8,8,8,0,0,0,16,0,24.1,24.1,0,0,0-24-24H152a7.8,7.8,0,0,0-6.9,4,7.7,7.7,0,0,0,0,8l11.6,20H99.3L82.9,60A7.9,7.9,0,0,0,76,56H48a8,8,0,0,0,0,16H71.4L85.1,95.5,69.4,117.1A46.6,46.6,0,0,0,48,112a48.1,48.1,0,1,0,34.3,14.5l11.6-15.9L125.1,164a8,8,0,1,0,13.8-8l-30.3-52h57.5l11.2,19.2A47.9,47.9,0,1,0,208,112ZM80,160a32,32,0,1,1-32-32,31,31,0,0,1,11.8,2.3l-18.3,25a8,8,0,0,0,1.8,11.2A8.2,8.2,0,0,0,48,168a7.9,7.9,0,0,0,6.5-3.3l18.2-25A31.5,31.5,0,0,1,80,160Zm128,32a32.1,32.1,0,0,1-32-32,31.6,31.6,0,0,1,9.5-22.7L201.1,164a8,8,0,1,0,13.8-8l-15.6-26.8A31.5,31.5,0,0,1,208,128a32,32,0,0,1,0,64Z" />
        </>
      )}
    </svg>
  );
}

export function PhBinoculars(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M241,150.7h0a61.4,61.4,0,0,0-2.5-5.9L196.9,50.2a11.5,11.5,0,0,0-2.5-3.7,36.1,36.1,0,0,0-50.9,0A12.3,12.3,0,0,0,140,55V80H116V55a12.3,12.3,0,0,0-3.5-8.5,36.1,36.1,0,0,0-50.9,0,11.5,11.5,0,0,0-2.5,3.7L17.5,144.7a61.4,61.4,0,0,0-2.5,5.9h0A52,52,0,1,0,116,168V104h24v64a52,52,0,1,0,101-17.3ZM80,62.3a11.8,11.8,0,0,1,12-1.2v63.1a52,52,0,0,0-35.9-7.6ZM64,196a28.1,28.1,0,0,1-28-28,28.4,28.4,0,0,1,1.6-9.3l1.7-3.8A28,28,0,1,1,64,196ZM164,61.1a11.8,11.8,0,0,1,12,1.2l23.9,54.3a52,52,0,0,0-35.9,7.6ZM192,196a28,28,0,1,1,24.7-41.1l1.7,3.8A28.4,28.4,0,0,1,220,168,28.1,28.1,0,0,1,192,196Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M104,168a40,40,0,1,1-40-40A40,40,0,0,1,104,168Zm88-40a40,40,0,1,0,40,40A40,40,0,0,0,192,128Z"
            opacity="0.2"
          />
          <path d="M237.2,151.9h0a53.8,53.8,0,0,0-2.4-5.5L193.3,51.8a8.1,8.1,0,0,0-1.7-2.4,32,32,0,0,0-45.3,0A7.9,7.9,0,0,0,144,55V84H112V55a7.9,7.9,0,0,0-2.3-5.6,32,32,0,0,0-45.3,0,8.1,8.1,0,0,0-1.7,2.4L21.2,146.4a53.8,53.8,0,0,0-2.4,5.5h0A47.4,47.4,0,0,0,16,168a48,48,0,0,0,96,0V100h32v68a48,48,0,0,0,96,0A47.4,47.4,0,0,0,237.2,151.9ZM76.7,59.8A16.1,16.1,0,0,1,96,58.7v73.6A47.4,47.4,0,0,0,64,120a48.2,48.2,0,0,0-14.8,2.3ZM64,200a31.9,31.9,0,0,1-30.1-42.8l1.8-4A32,32,0,1,1,64,200ZM160,58.7a16.1,16.1,0,0,1,19.3,1.1l27.5,62.5A48.2,48.2,0,0,0,192,120a47.4,47.4,0,0,0-32,12.3ZM192,200a32,32,0,1,1,28.3-46.8l1.8,4A31.9,31.9,0,0,1,192,200Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M237.2,151.9h0c0-.1-.1-.1-.1-.2a42.3,42.3,0,0,0-2.3-5.3L193.3,51.8a8.1,8.1,0,0,0-1.7-2.4,32,32,0,0,0-45.3,0A7.9,7.9,0,0,0,144,55V84H112V55a7.9,7.9,0,0,0-2.3-5.6,32,32,0,0,0-45.3,0,8.1,8.1,0,0,0-1.7,2.4L21.2,146.3a42.3,42.3,0,0,0-2.3,5.3c0,.1-.1.1-.1.2h0A48,48,0,1,0,112,167.8V100h32v67.8a48,48,0,1,0,93.2-15.9Zm-143.1,27a32,32,0,0,1-60.2-21.7l1.8-4.1A32,32,0,0,1,96,167.9h0A32.1,32.1,0,0,1,94.1,178.9Zm108.8,19.2A32,32,0,0,1,160,168h0a32,32,0,0,1,60.3-14.8l1.8,4.1A32,32,0,0,1,202.9,198.1Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M233,147.3,191.4,52.6a5.7,5.7,0,0,0-1.2-1.8,30,30,0,0,0-42.4,0A5.6,5.6,0,0,0,146,55V86H110V55a5.6,5.6,0,0,0-1.8-4.2,30,30,0,0,0-42.4,0,5.7,5.7,0,0,0-1.2,1.8L23,147.3A44.9,44.9,0,0,0,18,168a46,46,0,0,0,92,0V98h36v70a46,46,0,0,0,92,0A44.9,44.9,0,0,0,233,147.3ZM64,202a34,34,0,0,1-32-45.5l1.8-4.2A34,34,0,1,1,64,202Zm0-80a45.4,45.4,0,0,0-18.5,3.9L75.1,58.6A18,18,0,0,1,98,57.7V137A45.9,45.9,0,0,0,64,122Zm94-64.3a18,18,0,0,1,22.9.9l29.6,67.3A45.4,45.4,0,0,0,192,122a45.9,45.9,0,0,0-34,15ZM192,202a34,34,0,1,1,30.2-49.7l1.8,4.2A34,34,0,0,1,192,202Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M231.2,148.1,189.6,53.4a8.3,8.3,0,0,0-.8-1.2,28.1,28.1,0,0,0-39.6,0A3.9,3.9,0,0,0,148,55V88H108V55a3.9,3.9,0,0,0-1.2-2.8,28.1,28.1,0,0,0-39.6,0,8.3,8.3,0,0,0-.8,1.2L24.8,148.1A43,43,0,0,0,20,168a44,44,0,0,0,88,0V96h40v72a44,44,0,0,0,88,0A43,43,0,0,0,231.2,148.1ZM64,204a36,36,0,0,1-36-36,37.6,37.6,0,0,1,2.1-12.2l1.9-4.3A36,36,0,1,1,64,204Zm0-80a44,44,0,0,0-22.7,6.3L73.4,57.4a19.9,19.9,0,0,1,26.6-.6v85.9A44,44,0,0,0,64,124Zm92-67.2a19.9,19.9,0,0,1,26.6.6l32.1,72.9A44,44,0,0,0,156,142.7ZM192,204a36,36,0,1,1,32-52.5l1.9,4.3A37.6,37.6,0,0,1,228,168,36,36,0,0,1,192,204Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M237.2,151.9h0a53.8,53.8,0,0,0-2.4-5.5L193.3,51.8a8.1,8.1,0,0,0-1.7-2.4,32,32,0,0,0-45.3,0A7.9,7.9,0,0,0,144,55V84H112V55a7.9,7.9,0,0,0-2.3-5.6,32,32,0,0,0-45.3,0,8.1,8.1,0,0,0-1.7,2.4L21.2,146.4a53.8,53.8,0,0,0-2.4,5.5h0A47.4,47.4,0,0,0,16,168a48,48,0,0,0,96,0V100h32v68a48,48,0,0,0,96,0A47.4,47.4,0,0,0,237.2,151.9ZM76.7,59.8A16.1,16.1,0,0,1,96,58.7v73.6A47.4,47.4,0,0,0,64,120a48.2,48.2,0,0,0-14.8,2.3ZM64,200a31.9,31.9,0,0,1-30.1-42.8l1.8-4A32,32,0,1,1,64,200ZM160,58.7a16.1,16.1,0,0,1,19.3,1.1l27.5,62.5A48.2,48.2,0,0,0,192,120a47.4,47.4,0,0,0-32,12.3ZM192,200a32,32,0,1,1,28.3-46.8l1.8,4A31.9,31.9,0,0,1,192,200Z" />
        </>
      )}
    </svg>
  );
}

export function PhBird(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M176,72a16,16,0,1,1-16-16A16,16,0,0,1,176,72Zm68,8a12.1,12.1,0,0,1-5.3,10L220,102.4V120A108.1,108.1,0,0,1,112,228H12a12.1,12.1,0,0,1-10.8-6.7,12,12,0,0,1,1.3-12.6L92,91.9V76A64,64,0,0,1,216.6,55.3L238.7,70A12.1,12.1,0,0,1,244,80Zm-33.6,0-10.7-7.1a12.2,12.2,0,0,1-5-7A40,40,0,0,0,116,76V96a12.2,12.2,0,0,1-2.5,7.3L36.3,204H112a84.1,84.1,0,0,0,84-84V96a12.1,12.1,0,0,1,5.3-10Zm-81.8,46.5a11.9,11.9,0,0,0-16.8,2.2l-30.7,40a12,12,0,0,0,2.3,16.8,11.5,11.5,0,0,0,7.3,2.5,11.9,11.9,0,0,0,9.5-4.7l30.7-40A12,12,0,0,0,128.6,126.5Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M232,80,208,96v24a96,96,0,0,1-96,96H8L104,96V76A52,52,0,0,1,206.3,62.9Z"
            opacity="0.2"
          />
          <path d="M176,68a12,12,0,1,1-12-12A12,12,0,0,1,176,68Zm-50.9,61.9a7.8,7.8,0,0,0-11.2,1l-40,48a7.8,7.8,0,0,0,1,11.2,7.8,7.8,0,0,0,11.2-1l40-48A7.8,7.8,0,0,0,125.1,129.9ZM240,80a7.9,7.9,0,0,1-3.6,6.7L216,100.3V120A104.2,104.2,0,0,1,112,224H8a8,8,0,0,1-6.2-13L96,93.2V76A60,60,0,0,1,213.2,57.9l23.2,15.4A7.9,7.9,0,0,1,240,80Zm-22.4,0L201.9,69.5a7.8,7.8,0,0,1-3.3-4.6A44,44,0,0,0,112,76V96a7.7,7.7,0,0,1-1.8,5L24.6,208H112a88.1,88.1,0,0,0,88-88V96a7.9,7.9,0,0,1,3.6-6.7Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M236.4,73.3,213.2,57.9A60,60,0,0,0,96,76V93.2L1.8,211A8,8,0,0,0,8,224H112A104.2,104.2,0,0,0,216,120V100.3l20.4-13.6a8,8,0,0,0,0-13.4ZM110.1,141.1l-40,48a8,8,0,0,1-12.2-10.2l40-48a8,8,0,1,1,12.2,10.2ZM164,80a12,12,0,1,1,12-12A12,12,0,0,1,164,80Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M174,68a10,10,0,1,1-10-10A10,10,0,0,1,174,68Zm-50.2,63.4a5.9,5.9,0,0,0-8.4.8l-40,48A6,6,0,0,0,80,190a5.8,5.8,0,0,0,4.6-2.2l40-48A5.9,5.9,0,0,0,123.8,131.4ZM238,80a6,6,0,0,1-2.7,5L214,99.2V120A102.2,102.2,0,0,1,112,222H8a6,6,0,0,1-4.7-9.7L98,93.9V76A58,58,0,0,1,211.5,59.1L235.3,75A6,6,0,0,1,238,80Zm-16.8,0L203,67.9a6.4,6.4,0,0,1-2.5-3.5A46,46,0,0,0,110,76V96a5.9,5.9,0,0,1-1.3,3.7L20.5,210H112a90.1,90.1,0,0,0,90-90V96a6,6,0,0,1,2.7-5Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M172,68a8,8,0,1,1-8-8A8,8,0,0,1,172,68Zm-55.1,65.4-40,48a4.1,4.1,0,0,0,.5,5.7,4.3,4.3,0,0,0,2.6.9,4.3,4.3,0,0,0,3.1-1.4l40-48a4,4,0,0,0-6.2-5.2ZM236,80a4,4,0,0,1-1.8,3.3L212,98.1V120A100.2,100.2,0,0,1,112,220H8a4.1,4.1,0,0,1-3.6-2.3,3.8,3.8,0,0,1,.5-4.2L100,94.6V76A56,56,0,0,1,209.8,60.4l24.4,16.3A4,4,0,0,1,236,80Zm-11.2,0L204.1,66.2a3.8,3.8,0,0,1-1.6-2.3A48,48,0,0,0,108,76V96a3.8,3.8,0,0,1-.9,2.5L16.3,212H112a92.1,92.1,0,0,0,92-92V96a4,4,0,0,1,1.8-3.3Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M176,68a12,12,0,1,1-12-12A12,12,0,0,1,176,68Zm-50.9,61.9a7.8,7.8,0,0,0-11.2,1l-40,48a7.8,7.8,0,0,0,1,11.2,7.8,7.8,0,0,0,11.2-1l40-48A7.8,7.8,0,0,0,125.1,129.9ZM240,80a7.9,7.9,0,0,1-3.6,6.7L216,100.3V120A104.2,104.2,0,0,1,112,224H8a8,8,0,0,1-6.2-13L96,93.2V76A60,60,0,0,1,213.2,57.9l23.2,15.4A7.9,7.9,0,0,1,240,80Zm-22.4,0L201.9,69.5a7.8,7.8,0,0,1-3.3-4.6A44,44,0,0,0,112,76V96a7.7,7.7,0,0,1-1.8,5L24.6,208H112a88.1,88.1,0,0,0,88-88V96a7.9,7.9,0,0,1,3.6-6.7Z" />
        </>
      )}
    </svg>
  );
}

export function PhBluetoothConnected(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M191.2,166.4,140,128l51.2-38.4a12,12,0,0,0,0-19.2l-64-48A12,12,0,0,0,108,32v72L63.2,70.4A12,12,0,0,0,48.8,89.6L100,128,48.8,166.4A12,12,0,0,0,56,188a12.2,12.2,0,0,0,7.2-2.4L108,152v72a11.9,11.9,0,0,0,6.6,10.7A11.6,11.6,0,0,0,120,236a12,12,0,0,0,7.2-2.4l64-48a12,12,0,0,0,0-19.2ZM132,56l32,24-32,24Zm0,144V152l32,24Zm80-72a16,16,0,1,1-16-16A16,16,0,0,1,212,128ZM52,144a16,16,0,1,1,16-16A16,16,0,0,1,52,144Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <polygon points="120 32 184 80 120 128 120 32" />
          </g>
          <g opacity="0.2">
            <polygon points="120 128 184 176 120 224 120 128" />
          </g>
          <path d="M188.8,169.6,133.3,128l55.5-41.6a8,8,0,0,0,0-12.8l-64-48a8,8,0,0,0-8.4-.7A7.9,7.9,0,0,0,112,32v80L60.8,73.6a8,8,0,0,0-9.6,12.8L106.7,128,51.2,169.6A8,8,0,0,0,56,184a7.7,7.7,0,0,0,4.8-1.6L112,144v80a8.2,8.2,0,0,0,4.4,7.2,9.4,9.4,0,0,0,3.6.8,7.7,7.7,0,0,0,4.8-1.6l64-48a8,8,0,0,0,0-12.8ZM128,48l42.7,32L128,112Zm0,160V144l42.7,32Zm80-80a12,12,0,1,1-12-12A12,12,0,0,1,208,128ZM52,140a12,12,0,1,1,12-12A12,12,0,0,1,52,140Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M52,140a12,12,0,1,1,12-12A12,12,0,0,1,52,140Zm140,36a8.1,8.1,0,0,1-3.2,6.4l-64,48A7.7,7.7,0,0,1,120,232a9.4,9.4,0,0,1-3.6-.8A8.2,8.2,0,0,1,112,224V144L60.8,182.4A7.7,7.7,0,0,1,56,184a8,8,0,0,1-4.8-14.4L106.7,128,51.2,86.4a8,8,0,0,1,9.6-12.8L112,112V32a7.9,7.9,0,0,1,4.4-7.1,8,8,0,0,1,8.4.7l64,48a8,8,0,0,1,0,12.8L133.3,128l55.5,41.6A8.1,8.1,0,0,1,192,176Zm-64-64,42.7-32L128,48Zm42.7,64L128,144v64ZM196,116a12,12,0,1,0,12,12A12,12,0,0,0,196,116Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M187.6,171.2,130,128l57.6-43.2a6,6,0,0,0,0-9.6l-64-48a6,6,0,0,0-6.3-.6A6.2,6.2,0,0,0,114,32v84L59.6,75.2a6,6,0,0,0-7.2,9.6L110,128,52.4,171.2a6,6,0,0,0-1.2,8.4,6,6,0,0,0,8.4,1.2L114,140v84a6,6,0,0,0,3.3,5.4,6.6,6.6,0,0,0,2.7.6,6,6,0,0,0,3.6-1.2l64-48a6,6,0,0,0,0-9.6ZM126,44l48,36-48,36Zm0,168V140l48,36Zm80-84a10,10,0,1,1-10-10A10,10,0,0,1,206,128ZM52,138a10,10,0,1,1,10-10A10,10,0,0,1,52,138Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M186.4,172.8,126.7,128l59.7-44.8A3.9,3.9,0,0,0,188,80a4,4,0,0,0-1.6-3.2l-64-48A4,4,0,0,0,116,32v88L58.4,76.8a4,4,0,0,0-4.8,6.4L113.3,128,53.6,172.8a4,4,0,0,0-.8,5.6A3.9,3.9,0,0,0,56,180a4.3,4.3,0,0,0,2.4-.8L116,136v88a4.2,4.2,0,0,0,2.2,3.6,3.9,3.9,0,0,0,1.8.4,4.1,4.1,0,0,0,2.4-.8l64-48A3.9,3.9,0,0,0,188,176,4,4,0,0,0,186.4,172.8ZM124,40l53.3,40L124,120Zm0,176V136l53.3,40Zm80-88a8,8,0,1,1-8-8A8,8,0,0,1,204,128ZM52,136a8,8,0,1,1,8-8A8,8,0,0,1,52,136Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M188.8,169.6,133.3,128l55.5-41.6a8,8,0,0,0,0-12.8l-64-48a8,8,0,0,0-8.4-.7A7.9,7.9,0,0,0,112,32v80L60.8,73.6a8,8,0,0,0-9.6,12.8L106.7,128,51.2,169.6A8,8,0,0,0,56,184a7.7,7.7,0,0,0,4.8-1.6L112,144v80a8.2,8.2,0,0,0,4.4,7.2,9.4,9.4,0,0,0,3.6.8,7.7,7.7,0,0,0,4.8-1.6l64-48a8,8,0,0,0,0-12.8ZM128,48l42.7,32L128,112Zm0,160V144l42.7,32Zm80-80a12,12,0,1,1-12-12A12,12,0,0,1,208,128ZM52,140a12,12,0,1,1,12-12A12,12,0,0,1,52,140Z" />
        </>
      )}
    </svg>
  );
}

export function PhBluetoothSlash(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216.9,207.9l-31.4-34.5h0L56.9,31.9A12,12,0,0,0,39.1,48.1L107,122.7,48.8,166.4a12,12,0,0,0-2.4,16.8A12.2,12.2,0,0,0,56,188a12,12,0,0,0,7.2-2.4L108,152v72a12,12,0,0,0,6.7,10.7A10.9,10.9,0,0,0,120,236a11.8,11.8,0,0,0,7.2-2.4l47.9-35.9,24,26.4A12.1,12.1,0,0,0,208,228a12,12,0,0,0,8.9-20.1ZM132,200V150.2l26.9,29.6ZM108,59.7V32a12,12,0,0,1,6.7-10.7,11.8,11.8,0,0,1,12.5,1.1l64,48a12,12,0,0,1,0,19.2l-27.1,20.3a12,12,0,1,1-14.4-19.2L164,80,132,56v3.7a12,12,0,0,1-24,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <polygon points="120 32 184 80 120 128 120 32" />
          </g>
          <g opacity="0.2">
            <polygon points="120 128 184 176 120 224 120 128" />
          </g>
          <path d="M214,210.6l-31.4-34.5h0l-31.4-34.5h0L54,34.6A8,8,0,0,0,42.1,45.4L113,123.3,51.2,169.6A8,8,0,0,0,56,184a7.7,7.7,0,0,0,4.8-1.6L112,144v80a8.1,8.1,0,0,0,4.5,7.2,8.5,8.5,0,0,0,3.5.8,7.7,7.7,0,0,0,4.8-1.6l50.9-38.1,26.4,29.1A8.2,8.2,0,0,0,208,224a7.8,7.8,0,0,0,5.4-2.1A8,8,0,0,0,214,210.6ZM128,208V144l11.8,8.8,25,27.6Z" />
          <path d="M120,79.6a8,8,0,0,0,8-8V48l42.7,32-25,18.7a8,8,0,0,0,4.8,14.4,7.7,7.7,0,0,0,4.8-1.6l33.5-25.1a8,8,0,0,0,0-12.8l-64-48A8,8,0,0,0,112,32V71.6A8,8,0,0,0,120,79.6Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M120,232a9.4,9.4,0,0,1-3.6-.8A8.2,8.2,0,0,1,112,224V144L60.8,182.4a8,8,0,0,1-11.2-1.6,8.1,8.1,0,0,1,1.6-11.2L113,123.3,42.1,45.4a8,8,0,0,1,.5-11.3,7.9,7.9,0,0,1,11.3.5L151,141.4l.2.2,31.3,34.5h.2l31.2,34.4a8,8,0,0,1-11.8,10.8l-26.4-29.1-50.9,38.1A7.7,7.7,0,0,1,120,232Zm8-88v64l36.8-27.6-25-27.6Zm22.5-30.9a8,8,0,0,1-4.8-14.4l25-18.7L128,48V71.6a8,8,0,0,1-16,0V32a8,8,0,0,1,12.8-6.4l64,48a8,8,0,0,1,0,12.8l-33.5,25.1A7.7,7.7,0,0,1,150.5,113.1Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M212.5,212l-31.4-34.6h0l-31.4-34.5h0L52.5,36a5.9,5.9,0,0,0-8.5-.4,5.8,5.8,0,0,0-.4,8.4l72.3,79.6L52.4,171.2a6,6,0,0,0-1.2,8.4,6,6,0,0,0,8.4,1.2L114,140v84a6,6,0,0,0,3.3,5.4,6.6,6.6,0,0,0,2.7.6,5.8,5.8,0,0,0,3.6-1.2l52.3-39.2L203.6,220a6,6,0,0,0,4.4,2,5.9,5.9,0,0,0,4.1-1.6A6,6,0,0,0,212.5,212ZM126,212V140l15.1,11.3,26.7,29.4ZM114,71.6V32a6.2,6.2,0,0,1,3.3-5.4,6,6,0,0,1,6.3.6l64,48a6,6,0,0,1,0,9.6l-33.5,25.1a5.6,5.6,0,0,1-3.6,1.2,6,6,0,0,1-3.6-10.8L174,80,126,44V71.6a6,6,0,0,1-12,0Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M211,213.3l-62.8-69.1h0L51,37.3a4.2,4.2,0,0,0-5.7-.3,4.1,4.1,0,0,0-.2,5.7l74,81.4h-.7a.1.1,0,0,1-.1.1h-.1a1,1,0,0,0-.5.4h-.1l-64,48a4,4,0,0,0-.8,5.6A3.9,3.9,0,0,0,56,180a4.3,4.3,0,0,0,2.4-.8L116,136v88a4.2,4.2,0,0,0,2.2,3.6,3.9,3.9,0,0,0,1.8.4,4.1,4.1,0,0,0,2.4-.8l53.7-40.3,29,31.8A3.6,3.6,0,0,0,208,220a3.9,3.9,0,0,0,2.7-1A4.1,4.1,0,0,0,211,213.3ZM124,216V136l18.5,13.9L170.7,181ZM116,71.6V32a4.2,4.2,0,0,1,2.2-3.6,4,4,0,0,1,4.2.4l64,48a4,4,0,0,1,0,6.4l-33.5,25.1a3.7,3.7,0,0,1-2.4.8,3.9,3.9,0,0,1-3.2-1.6,4,4,0,0,1,.8-5.6L177.3,80,124,40V71.6a4,4,0,0,1-8,0Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M213.9,210.6l-31.4-34.5h0l-31.3-34.5h-.1L53.9,34.6a7.9,7.9,0,0,0-11.3-.5,8,8,0,0,0-.5,11.3l70.8,77.9L51.2,169.6A8,8,0,0,0,56,184a7.7,7.7,0,0,0,4.8-1.6L112,144v80a8.2,8.2,0,0,0,4.4,7.2,9.4,9.4,0,0,0,3.6.8,7.7,7.7,0,0,0,4.8-1.6l50.8-38.1,26.5,29.1a8,8,0,0,0,11.3.5A7.9,7.9,0,0,0,213.9,210.6ZM128,208V144l11.8,8.8,25,27.6ZM112,71.6V32a8,8,0,0,1,12.8-6.4l64,48a8,8,0,0,1,0,12.8l-33.5,25.1a7.9,7.9,0,0,1-4.8,1.6,7.8,7.8,0,0,1-6.4-3.2,7.9,7.9,0,0,1,1.6-11.2l25-18.7L128,48V71.6a8,8,0,0,1-16,0Z" />
        </>
      )}
    </svg>
  );
}

export function PhBluetoothX(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M191.2,166.4,140,128l11.6-8.7a12,12,0,1,0-14.4-19.2L132,104V56l5.2,3.9a12,12,0,1,0,14.4-19.2L127.2,22.4a11.8,11.8,0,0,0-12.5-1.1A12,12,0,0,0,108,32v72L63.2,70.4A12,12,0,0,0,48.8,89.6L100,128,48.8,166.4a12,12,0,0,0-2.4,16.8A12.2,12.2,0,0,0,56,188a12,12,0,0,0,7.2-2.4L108,152v72a12,12,0,0,0,6.7,10.7A10.9,10.9,0,0,0,120,236a11.8,11.8,0,0,0,7.2-2.4l64-48a12,12,0,0,0,0-19.2ZM132,200V152l32,24ZM240.5,95.5A12,12,0,0,1,232,116a12.2,12.2,0,0,1-8.5-3.5L208,97l-15.5,15.5A12,12,0,0,1,184,116a12.2,12.2,0,0,1-8.5-3.5,12,12,0,0,1,0-17L191,80,175.5,64.5a12,12,0,0,1,17-17L208,63l15.5-15.5a12,12,0,0,1,17,17L225,80Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <polygon points="120 32 184 80 120 128 120 32" />
          </g>
          <g opacity="0.2">
            <polygon points="120 128 184 176 120 224 120 128" />
          </g>
          <path d="M188.8,169.6,133.4,128l23.4-17.6a7.9,7.9,0,0,0,1.6-11.2,8.1,8.1,0,0,0-11.2-1.6L128,112V48l19.2,14.4a8,8,0,1,0,9.6-12.8l-32-24A8,8,0,0,0,112,32v80L60.8,73.6a8,8,0,0,0-9.6,12.8L106.7,128,51.2,169.6A8,8,0,0,0,56,184a7.7,7.7,0,0,0,4.8-1.6L112,144v80a8.1,8.1,0,0,0,4.5,7.2,8.5,8.5,0,0,0,3.5.8,7.7,7.7,0,0,0,4.8-1.6l64-48a8,8,0,0,0,0-12.8ZM128,208V144l42.7,32Z" />
          <path d="M219.3,80l18.4-18.3a8.1,8.1,0,0,0,0-11.4,8,8,0,0,0-11.3,0L208,68.7,189.7,50.3a8,8,0,0,0-11.3,11.4L196.7,80,178.4,98.3A8,8,0,0,0,184,112a8.3,8.3,0,0,0,5.7-2.3L208,91.3l18.4,18.4A8,8,0,0,0,232,112a8.3,8.3,0,0,0,5.7-2.3,8.1,8.1,0,0,0,0-11.4Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M188.8,169.6,133.4,128l23.4-17.6a7.9,7.9,0,0,0,1.6-11.2,8.1,8.1,0,0,0-11.2-1.6L128,112V48l19.2,14.4a8,8,0,1,0,9.6-12.8l-32-24A8,8,0,0,0,112,32v80L60.8,73.6a8,8,0,0,0-9.6,12.8L106.7,128,51.2,169.6A8,8,0,0,0,56,184a7.7,7.7,0,0,0,4.8-1.6L112,144v80a8.2,8.2,0,0,0,4.4,7.2,9.4,9.4,0,0,0,3.6.8,7.7,7.7,0,0,0,4.8-1.6l64-48a8,8,0,0,0,0-12.8ZM128,208V144l42.7,32ZM237.7,98.3a8.1,8.1,0,0,1,0,11.4A8.3,8.3,0,0,1,232,112a8,8,0,0,1-5.6-2.3L208,91.3l-18.3,18.4A8.3,8.3,0,0,1,184,112a8,8,0,0,1-5.6-13.7L196.7,80,178.4,61.7a8,8,0,0,1,11.3-11.4L208,68.7l18.4-18.4a8,8,0,0,1,11.3,0,8.1,8.1,0,0,1,0,11.4L219.3,80Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M187.6,171.2,130,128l25.6-19.2a5.9,5.9,0,0,0,1.2-8.4,6,6,0,0,0-8.4-1.2L126,116V44l22.4,16.8a6,6,0,1,0,7.2-9.6l-32-24a6,6,0,0,0-6.3-.6A6.2,6.2,0,0,0,114,32v84L59.6,75.2a6,6,0,0,0-7.2,9.6L110,128,52.4,171.2a6,6,0,0,0-1.2,8.4,6,6,0,0,0,8.4,1.2L114,140v84a6,6,0,0,0,3.3,5.4,6.6,6.6,0,0,0,2.7.6,5.8,5.8,0,0,0,3.6-1.2l64-48a6,6,0,0,0,0-9.6ZM126,212V140l48,36ZM236.3,99.8a6,6,0,0,1,0,8.4A6.1,6.1,0,0,1,232,110a5.8,5.8,0,0,1-4.2-1.8L208,88.5l-19.7,19.7A6.1,6.1,0,0,1,184,110a6,6,0,0,1-4.2-10.2L199.5,80,179.8,60.2a5.8,5.8,0,0,1,0-8.4,5.9,5.9,0,0,1,8.5,0L208,71.5l19.8-19.7a6,6,0,0,1,8.5,8.4L216.5,80Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M186.4,172.8,126.7,128l27.7-20.8a4,4,0,1,0-4.8-6.4L124,120V40l25.6,19.2a4,4,0,0,0,4.8-6.4l-32-24a4,4,0,0,0-4.2-.4A4.2,4.2,0,0,0,116,32v88L58.4,76.8a4,4,0,0,0-4.8,6.4L113.3,128,53.6,172.8a4,4,0,0,0-.8,5.6A3.9,3.9,0,0,0,56,180a4.3,4.3,0,0,0,2.4-.8L116,136v88a4.2,4.2,0,0,0,2.2,3.6,3.9,3.9,0,0,0,1.8.4,4.1,4.1,0,0,0,2.4-.8l64-48a4,4,0,0,0,0-6.4ZM124,216V136l53.3,40ZM234.8,101.2a3.9,3.9,0,0,1,0,5.6A3.8,3.8,0,0,1,232,108a3.9,3.9,0,0,1-2.8-1.2L208,85.7l-21.2,21.1A3.8,3.8,0,0,1,184,108a3.9,3.9,0,0,1-2.8-1.2,3.9,3.9,0,0,1,0-5.6L202.3,80,181.2,58.8a4,4,0,0,1,5.6-5.6L208,74.3l21.2-21.1a4,4,0,1,1,5.6,5.6L213.7,80Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M188.8,169.6,133.3,128l23.5-17.6a8,8,0,0,0,1.6-11.2,8.1,8.1,0,0,0-11.2-1.6L128,112V48l19.2,14.4a8,8,0,0,0,11.2-1.6,8.1,8.1,0,0,0-1.6-11.2l-32-24A8,8,0,0,0,112,32v80L60.8,73.6a8.1,8.1,0,0,0-11.2,1.6,8,8,0,0,0,1.6,11.2L106.7,128,51.2,169.6A8,8,0,0,0,56,184a7.7,7.7,0,0,0,4.8-1.6L112,144v80a8.2,8.2,0,0,0,4.4,7.2,9.4,9.4,0,0,0,3.6.8,7.7,7.7,0,0,0,4.8-1.6l64-48a8,8,0,0,0,0-12.8ZM128,208V144l42.7,32ZM237.7,98.3a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0L208,91.3l-18.3,18.4a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L196.7,80,178.3,61.7a8.1,8.1,0,0,1,11.4-11.4L208,68.7l18.3-18.4a8.1,8.1,0,0,1,11.4,11.4L219.3,80Z" />
        </>
      )}
    </svg>
  );
}

export function PhBluetooth(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M191.2,166.4,140,128l51.2-38.4a12,12,0,0,0,0-19.2l-64-48A12,12,0,0,0,108,32v72L63.2,70.4A12,12,0,0,0,48.8,89.6L100,128,48.8,166.4A12,12,0,0,0,56,188a12.2,12.2,0,0,0,7.2-2.4L108,152v72a11.9,11.9,0,0,0,6.6,10.7A11.6,11.6,0,0,0,120,236a12,12,0,0,0,7.2-2.4l64-48a12,12,0,0,0,0-19.2ZM132,56l32,24-32,24Zm0,144V152l32,24Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <polygon points="120 32 184 80 120 128 120 32" />
          </g>
          <g opacity="0.2">
            <polygon points="120 128 184 176 120 224 120 128" />
          </g>
          <path d="M188.8,169.6,133.3,128l55.5-41.6a8,8,0,0,0,0-12.8l-64-48a8,8,0,0,0-8.4-.7A7.9,7.9,0,0,0,112,32v80L60.8,73.6a8,8,0,0,0-9.6,12.8L106.7,128,51.2,169.6A8,8,0,0,0,56,184a7.7,7.7,0,0,0,4.8-1.6L112,144v80a8.2,8.2,0,0,0,4.4,7.2,9.4,9.4,0,0,0,3.6.8,7.7,7.7,0,0,0,4.8-1.6l64-48a8,8,0,0,0,0-12.8ZM128,48l42.7,32L128,112Zm0,160V144l42.7,32Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M188.8,169.6,133.3,128l55.5-41.6a8,8,0,0,0,0-12.8l-64-48a8,8,0,0,0-8.4-.7A7.9,7.9,0,0,0,112,32v80L60.8,73.6a8.1,8.1,0,0,0-11.2,1.6,8,8,0,0,0,1.6,11.2L106.7,128,51.2,169.6A8,8,0,0,0,56,184a7.7,7.7,0,0,0,4.8-1.6L112,144v80a8.2,8.2,0,0,0,4.4,7.2,9.4,9.4,0,0,0,3.6.8,7.7,7.7,0,0,0,4.8-1.6l64-48a8,8,0,0,0,0-12.8ZM128,48l42.7,32L128,112Zm0,160V144l42.7,32Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M187.6,171.2,130,128l57.6-43.2a6,6,0,0,0,0-9.6l-64-48a6,6,0,0,0-6.3-.6A6.2,6.2,0,0,0,114,32v84L59.6,75.2a6,6,0,0,0-7.2,9.6L110,128,52.4,171.2a6,6,0,0,0-1.2,8.4,6,6,0,0,0,8.4,1.2L114,140v84a6,6,0,0,0,3.3,5.4,6.6,6.6,0,0,0,2.7.6,6,6,0,0,0,3.6-1.2l64-48a6,6,0,0,0,0-9.6ZM126,44l48,36-48,36Zm0,168V140l48,36Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M186.4,172.8,126.7,128l59.7-44.8A3.9,3.9,0,0,0,188,80a4.2,4.2,0,0,0-1.6-3.2l-64-48A4,4,0,0,0,116,32v88L58.4,76.8a4,4,0,0,0-4.8,6.4L113.3,128,53.6,172.8a4,4,0,0,0-.8,5.6A3.9,3.9,0,0,0,56,180a3.7,3.7,0,0,0,2.4-.8L116,136v88a4.2,4.2,0,0,0,2.2,3.6,3.9,3.9,0,0,0,1.8.4,4.1,4.1,0,0,0,2.4-.8l64-48A3.9,3.9,0,0,0,188,176,4.2,4.2,0,0,0,186.4,172.8ZM124,40l53.3,40L124,120Zm0,176V136l53.3,40Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M188.8,169.6,133.3,128l55.5-41.6a8,8,0,0,0,0-12.8l-64-48a8,8,0,0,0-8.4-.7A7.9,7.9,0,0,0,112,32v80L60.8,73.6a8,8,0,0,0-9.6,12.8L106.7,128,51.2,169.6A8,8,0,0,0,56,184a7.7,7.7,0,0,0,4.8-1.6L112,144v80a8.2,8.2,0,0,0,4.4,7.2,9.4,9.4,0,0,0,3.6.8,7.7,7.7,0,0,0,4.8-1.6l64-48a8,8,0,0,0,0-12.8ZM128,48l42.7,32L128,112Zm0,160V144l42.7,32Z" />
        </>
      )}
    </svg>
  );
}

export function PhBoat(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M222.3,106.8,212,103.4V56a20.1,20.1,0,0,0-20-20H140V24a12,12,0,0,0-24,0V36H64A20.1,20.1,0,0,0,44,56v47.4l-10.3,3.4a19.9,19.9,0,0,0-13.7,19V160a12.6,12.6,0,0,0,.5,3.3c16.3,57.2,88.6,76.7,103,80a20.5,20.5,0,0,0,9,0c14.4-3.3,86.7-22.8,103-80a12.6,12.6,0,0,0,.5-3.3V125.8A19.9,19.9,0,0,0,222.3,106.8ZM68,60H188V95.4L134.3,77.5a15.6,15.6,0,0,0-5.7-1h-1.2a15.6,15.6,0,0,0-5.7,1L68,95.4Zm144,98.2c-13.5,42.6-73.5,58.9-84,61.5-10.5-2.6-70.5-18.9-84-61.5V128.6l15.6-5.1h0L116,104.6V168a12,12,0,0,0,24,0V104.6l56.4,18.8h0l15.6,5.1Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M224,125.8V160c-14.6,51-82.2,68.8-94.2,71.6a7.3,7.3,0,0,1-3.6,0C114.2,228.8,46.6,211,32,160V125.8a8,8,0,0,1,5.5-7.6l88-29.4a8.5,8.5,0,0,1,5,0l88,29.4A8,8,0,0,1,224,125.8Z"
            opacity="0.2"
          />
          <path d="M221.1,110.6,208,106.2V56a16,16,0,0,0-16-16H136V24a8,8,0,0,0-16,0V40H64A16,16,0,0,0,48,56v50.2l-13.1,4.4A16,16,0,0,0,24,125.8V160a8.3,8.3,0,0,0,.3,2.2c15.7,55,86.1,74,100.1,77.2a16.4,16.4,0,0,0,7.2,0c14-3.2,84.4-22.2,100.1-77.2a8.3,8.3,0,0,0,.3-2.2V125.8A16,16,0,0,0,221.1,110.6ZM64,56H192v44.9L133.1,81.3a14.8,14.8,0,0,0-4.6-.8h-1a14.8,14.8,0,0,0-4.6.8L64,100.9ZM216,158.8c-5.8,19-20.5,35.2-43.9,48.1A184.7,184.7,0,0,1,128,223.8a184.7,184.7,0,0,1-44.1-16.9C60.5,194,45.8,177.8,40,158.8v-33l18.4-6.2h0L120,99.1V168a8,8,0,0,0,16,0V99.1l61.6,20.5h0l18.4,6.2Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M221.1,110.6,208,106.2V56a16,16,0,0,0-16-16H136V24a8,8,0,0,0-16,0V40H64A16,16,0,0,0,48,56v50.2l-13.1,4.4A16,16,0,0,0,24,125.8V160a8.3,8.3,0,0,0,.3,2.2c15.7,55,86.1,74,100.1,77.2a16.4,16.4,0,0,0,7.2,0c14-3.2,84.4-22.2,100.1-77.2a8.3,8.3,0,0,0,.3-2.2V125.8A16,16,0,0,0,221.1,110.6ZM136,168a8,8,0,0,1-16,0V112.4a8,8,0,0,1,16,0Zm56-67.1L133.1,81.3a16.2,16.2,0,0,0-10.2,0L64,100.9V56H192Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M220.4,112.5,206,107.7V56a14,14,0,0,0-14-14H134V24a6,6,0,0,0-12,0V42H64A14,14,0,0,0,50,56v51.7l-14.4,4.8A13.9,13.9,0,0,0,26,125.8V160a7.8,7.8,0,0,0,.2,1.6c15.4,54,84.9,72.7,98.6,75.8a13,13,0,0,0,6.4,0c13.7-3.1,83.2-21.8,98.6-75.8a7.8,7.8,0,0,0,.2-1.6V125.8A13.9,13.9,0,0,0,220.4,112.5ZM62,56a2,2,0,0,1,2-2H192a2,2,0,0,1,2,2v47.7L132.4,83.2a10.9,10.9,0,0,0-4-.7h-.8a10.9,10.9,0,0,0-4,.7L62,103.7ZM218,159.1c-5.9,19.6-21,36.3-45,49.5a183.5,183.5,0,0,1-44.5,17.2h-1A183.5,183.5,0,0,1,83,208.6c-24-13.2-39.1-29.9-45-49.5V125.8a1.8,1.8,0,0,1,1.4-1.9l18.4-6.2h0L122,96.3V168a6,6,0,0,0,12,0V96.3l64.2,21.4h0l18.4,6.2a1.8,1.8,0,0,1,1.4,1.9Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M219.8,114.4,204,109.1V56a12,12,0,0,0-12-12H132V24a4,4,0,0,0-8,0V44H64A12,12,0,0,0,52,56v53.1l-15.8,5.3A12,12,0,0,0,28,125.8V160a4.3,4.3,0,0,0,.2,1.1c15.1,52.9,83.5,71.2,97.1,74.4l2.7.3,2.7-.3c13.6-3.2,82-21.5,97.1-74.4a4.3,4.3,0,0,0,.2-1.1V125.8A12,12,0,0,0,219.8,114.4ZM60,56a4,4,0,0,1,4-4H192a4,4,0,0,1,4,4v50.5L131.8,85a11.9,11.9,0,0,0-3.7-.6h-.2a11.9,11.9,0,0,0-3.7.6L60,106.5ZM220,159.4c-6,20.3-21.4,37.4-46,51a185,185,0,0,1-45.1,17.3,5.4,5.4,0,0,1-1.8,0A185,185,0,0,1,82,210.4c-24.6-13.6-40-30.7-46-51V125.8a3.9,3.9,0,0,1,2.7-3.8L124,93.5V168a4,4,0,0,0,8,0V93.5L217.3,122a3.9,3.9,0,0,1,2.7,3.8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M221.1,110.6,208,106.2V56a16,16,0,0,0-16-16H136V24a8,8,0,0,0-16,0V40H64A16,16,0,0,0,48,56v50.2l-13.1,4.4A16,16,0,0,0,24,125.8V160a8.3,8.3,0,0,0,.3,2.2c15.7,55,86.1,74,100.1,77.2a16.4,16.4,0,0,0,7.2,0c14-3.2,84.4-22.2,100.1-77.2a8.3,8.3,0,0,0,.3-2.2V125.8A16,16,0,0,0,221.1,110.6ZM64,56H192v44.9L133.1,81.3a14.8,14.8,0,0,0-4.6-.8h-1a14.8,14.8,0,0,0-4.6.8L64,100.9ZM216,158.8c-5.8,19-20.5,35.2-43.9,48.1A184.7,184.7,0,0,1,128,223.8a184.7,184.7,0,0,1-44.1-16.9C60.5,194,45.8,177.8,40,158.8v-33l18.4-6.2h0L120,99.1V168a8,8,0,0,0,16,0V99.1l61.6,20.5h0l18.4,6.2Z" />
        </>
      )}
    </svg>
  );
}

export function PhBookBookmark(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M208,20H72A36,36,0,0,0,36,56V224a12,12,0,0,0,12,12H192a12,12,0,0,0,0-24H60v-4a12,12,0,0,1,12-12H208a12,12,0,0,0,12-12V32A12,12,0,0,0,208,20ZM120,44h40v60l-12.8-9.6a12,12,0,0,0-14.4,0L120,104Zm76,128H72a34.4,34.4,0,0,0-12,2.1V56A12,12,0,0,1,72,44H96v84a12.1,12.1,0,0,0,6.6,10.7A11.6,11.6,0,0,0,108,140a12,12,0,0,0,7.2-2.4L140,119l24.8,18.6A12,12,0,0,0,184,128V44h12Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M176,32v96l-32-24-32,24V32H72A23.9,23.9,0,0,0,48,56V216a23.9,23.9,0,0,1,24-24H208V32Z" />
          </g>
          <path d="M208,24H72A32.1,32.1,0,0,0,40,56V224a8,8,0,0,0,8,8H192a8,8,0,0,0,0-16H56a16,16,0,0,1,16-16H208a8,8,0,0,0,8-8V32A8,8,0,0,0,208,24ZM120,40h48v72L148.8,97.6a8.1,8.1,0,0,0-9.6,0L120,112Zm80,144H72a32.2,32.2,0,0,0-16,4.3V56A16,16,0,0,1,72,40h32v88a8,8,0,0,0,12.8,6.4L144,114l27.2,20.4A7.7,7.7,0,0,0,176,136a9.4,9.4,0,0,0,3.6-.8A8.2,8.2,0,0,0,184,128V40h16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M208,24H72A32.1,32.1,0,0,0,40,56V224a8,8,0,0,0,8,8H192a8,8,0,0,0,0-16H56a16,16,0,0,1,16-16H208a8,8,0,0,0,8-8V32A8,8,0,0,0,208,24Zm-24,96-25.6-19.2a3.9,3.9,0,0,0-4.8,0L128,120V40h56Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M208,26H72A30.1,30.1,0,0,0,42,56V224a6,6,0,0,0,6,6H192a6,6,0,0,0,0-12H54v-2a18.1,18.1,0,0,1,18-18H208a6,6,0,0,0,6-6V32A6,6,0,0,0,208,26ZM118,38h52v78L147.6,99.2a6,6,0,0,0-7.2,0L118,116Zm84,148H72a29.7,29.7,0,0,0-18,6V56A18.1,18.1,0,0,1,72,38h34v90a6,6,0,0,0,3.3,5.4,6,6,0,0,0,6.3-.6L144,111.5l28.4,21.3A6,6,0,0,0,176,134a6.6,6.6,0,0,0,2.7-.6A6.2,6.2,0,0,0,182,128V38h20Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M208,28H72A28.1,28.1,0,0,0,44,56V224a4,4,0,0,0,4,4H192a4,4,0,0,0,0-8H52v-4a20.1,20.1,0,0,1,20-20H208a4,4,0,0,0,4-4V32A4,4,0,0,0,208,28Zm-92,8h56v84l-25.6-19.2a3.9,3.9,0,0,0-4.8,0L116,120Zm88,152H72a27.9,27.9,0,0,0-20,8.4V56A20.1,20.1,0,0,1,72,36h36v92a4.2,4.2,0,0,0,2.2,3.6,4,4,0,0,0,4.2-.4L144,109l29.6,22.2a4.3,4.3,0,0,0,2.4.8,3.9,3.9,0,0,0,1.8-.4A4.2,4.2,0,0,0,180,128V36h24Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M208,24H72A32.1,32.1,0,0,0,40,56V224a8,8,0,0,0,8,8H192a8,8,0,0,0,0-16H56a16,16,0,0,1,16-16H208a8,8,0,0,0,8-8V32A8,8,0,0,0,208,24ZM120,40h48v72L148.8,97.6a8.1,8.1,0,0,0-9.6,0L120,112Zm80,144H72a32.2,32.2,0,0,0-16,4.3V56A16,16,0,0,1,72,40h32v88a8,8,0,0,0,12.8,6.4L144,114l27.2,20.4A7.7,7.7,0,0,0,176,136a9.4,9.4,0,0,0,3.6-.8A8.2,8.2,0,0,0,184,128V40h16Z" />
        </>
      )}
    </svg>
  );
}

export function PhBookOpen(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M224,44H160a43.8,43.8,0,0,0-32,13.8A43.8,43.8,0,0,0,96,44H32A20.1,20.1,0,0,0,12,64V192a20.1,20.1,0,0,0,20,20H96a20.1,20.1,0,0,1,20,20,12,12,0,0,0,24,0,20.1,20.1,0,0,1,20-20h64a20.1,20.1,0,0,0,20-20V64A20.1,20.1,0,0,0,224,44ZM96,188H36V68H96a20.1,20.1,0,0,1,20,20V192.8A43.4,43.4,0,0,0,96,188Zm124,0H160a43.4,43.4,0,0,0-20,4.8V88a20.1,20.1,0,0,1,20-20h60Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M224,56H160a32,32,0,0,0-32,32A32,32,0,0,0,96,56H32a8,8,0,0,0-8,8V192a8,8,0,0,0,8,8H96a32,32,0,0,1,32,32,32,32,0,0,1,32-32h64a8,8,0,0,0,8-8V64A8,8,0,0,0,224,56Z" />
          </g>
          <path d="M224,48H160a40,40,0,0,0-32,16A40,40,0,0,0,96,48H32A16,16,0,0,0,16,64V192a16,16,0,0,0,16,16H96a24.1,24.1,0,0,1,24,24,8,8,0,0,0,16,0,24.1,24.1,0,0,1,24-24h64a16,16,0,0,0,16-16V64A16,16,0,0,0,224,48ZM96,192H32V64H96a24.1,24.1,0,0,1,24,24V200A40,40,0,0,0,96,192Zm128,0H160a40,40,0,0,0-24,8V88a24.1,24.1,0,0,1,24-24h64Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M32,208a16,16,0,0,1-16-16V64A16,16,0,0,1,32,48H80a40,40,0,0,1,40,40v80a8,8,0,0,0,16,0V88a40,40,0,0,1,40-40h48a16,16,0,0,1,16,16V192a16,16,0,0,1-16,16H160a24.1,24.1,0,0,0-24,24,8,8,0,0,1-16,0,24.1,24.1,0,0,0-24-24Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M224,50H160a38.1,38.1,0,0,0-32,17.5A38.1,38.1,0,0,0,96,50H32A14,14,0,0,0,18,64V192a14,14,0,0,0,14,14H96a26.1,26.1,0,0,1,26,26,6,6,0,0,0,12,0,26.1,26.1,0,0,1,26-26h64a14,14,0,0,0,14-14V64A14,14,0,0,0,224,50ZM96,194H32a2,2,0,0,1-2-2V64a2,2,0,0,1,2-2H96a26.1,26.1,0,0,1,26,26V204.3A37.9,37.9,0,0,0,96,194Zm130-2a2,2,0,0,1-2,2H160a37.7,37.7,0,0,0-26,10.3V88a26.1,26.1,0,0,1,26-26h64a2,2,0,0,1,2,2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M224,52H160a36,36,0,0,0-32,19.5A36,36,0,0,0,96,52H32A12,12,0,0,0,20,64V192a12,12,0,0,0,12,12H96a28.1,28.1,0,0,1,28,28,4,4,0,0,0,8,0,28.1,28.1,0,0,1,28-28h64a12,12,0,0,0,12-12V64A12,12,0,0,0,224,52ZM96,196H32a4,4,0,0,1-4-4V64a4,4,0,0,1,4-4H96a28.1,28.1,0,0,1,28,28V209.4A35.8,35.8,0,0,0,96,196Zm132-4a4,4,0,0,1-4,4H160a35.8,35.8,0,0,0-28,13.4V88a28.1,28.1,0,0,1,28-28h64a4,4,0,0,1,4,4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M224,48H160a40,40,0,0,0-32,16A40,40,0,0,0,96,48H32A16,16,0,0,0,16,64V192a16,16,0,0,0,16,16H96a24.1,24.1,0,0,1,24,24,8,8,0,0,0,16,0,24.1,24.1,0,0,1,24-24h64a16,16,0,0,0,16-16V64A16,16,0,0,0,224,48ZM96,192H32V64H96a24.1,24.1,0,0,1,24,24V200A40,40,0,0,0,96,192Zm128,0H160a40,40,0,0,0-24,8V88a24.1,24.1,0,0,1,24-24h64Z" />
        </>
      )}
    </svg>
  );
}

export function PhBook(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M208,20H72A36,36,0,0,0,36,56V224a12,12,0,0,0,12,12H192a12,12,0,0,0,0-24H60v-4a12,12,0,0,1,12-12H208a12,12,0,0,0,12-12V32A12,12,0,0,0,208,20ZM196,172H72a34.4,34.4,0,0,0-12,2.1V56A12,12,0,0,1,72,44H196Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M48,216a23.9,23.9,0,0,1,24-24H208V32H72A23.9,23.9,0,0,0,48,56Z" />
          </g>
          <path d="M208,24H72A32.1,32.1,0,0,0,40,56V224a8,8,0,0,0,8,8H192a8,8,0,0,0,0-16H56a16,16,0,0,1,16-16H208a8,8,0,0,0,8-8V32A8,8,0,0,0,208,24Zm-8,160H72a32.2,32.2,0,0,0-16,4.3V56A16,16,0,0,1,72,40H200Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,32V192a8,8,0,0,1-8,8H72a16,16,0,0,0-16,16H192a8,8,0,0,1,0,16H48a8,8,0,0,1-8-8V56A32.1,32.1,0,0,1,72,24H208A8,8,0,0,1,216,32Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M208,26H72A30.1,30.1,0,0,0,42,56V224a6,6,0,0,0,6,6H192a6,6,0,0,0,0-12H54v-2a18.1,18.1,0,0,1,18-18H208a6,6,0,0,0,6-6V32A6,6,0,0,0,208,26Zm-6,160H72a29.7,29.7,0,0,0-18,6V56A18.1,18.1,0,0,1,72,38H202Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M208,28H72A28.1,28.1,0,0,0,44,56V224a4,4,0,0,0,4,4H192a4,4,0,0,0,0-8H52v-4a20.1,20.1,0,0,1,20-20H208a4,4,0,0,0,4-4V32A4,4,0,0,0,208,28Zm-4,160H72a27.9,27.9,0,0,0-20,8.4V56A20.1,20.1,0,0,1,72,36H204Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M208,24H72A32.1,32.1,0,0,0,40,56V224a8,8,0,0,0,8,8H192a8,8,0,0,0,0-16H56a16,16,0,0,1,16-16H208a8,8,0,0,0,8-8V32A8,8,0,0,0,208,24Zm-8,160H72a32.2,32.2,0,0,0-16,4.3V56A16,16,0,0,1,72,40H200Z" />
        </>
      )}
    </svg>
  );
}

export function PhBookmarkSimple(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M184,28H72A20.1,20.1,0,0,0,52,48V224a12,12,0,0,0,18.4,10.2l57.6-36,57.6,36A12.4,12.4,0,0,0,192,236a12,12,0,0,0,12-12V48A20.1,20.1,0,0,0,184,28Zm-4,174.4-45.6-28.6a12.3,12.3,0,0,0-12.8,0L76,202.3V52H180Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M192,224l-64-40L64,224V48a8,8,0,0,1,8-8H184a8,8,0,0,1,8,8Z" />
          </g>
          <path d="M184,32H72A16,16,0,0,0,56,48V224a8.1,8.1,0,0,0,4.1,7,7.8,7.8,0,0,0,3.9,1,7.6,7.6,0,0,0,4.2-1.2L128,193.4l59.8,37.4A8,8,0,0,0,200,224V48A16,16,0,0,0,184,32Zm0,177.6-51.8-32.4a8,8,0,0,0-8.4,0L72,209.6V48H184Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M200,48V224a8,8,0,0,1-12.2,6.8L128,193.4,68.2,230.8A7.6,7.6,0,0,1,64,232a7.8,7.8,0,0,1-3.9-1,8.1,8.1,0,0,1-4.1-7V48A16,16,0,0,1,72,32H184A16,16,0,0,1,200,48Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M184,34H72A14,14,0,0,0,58,48V224a5.9,5.9,0,0,0,3.1,5.2,5.1,5.1,0,0,0,2.9.8,6.2,6.2,0,0,0,3.2-.9l60.8-38,60.8,38a6.1,6.1,0,0,0,6.1.1A5.9,5.9,0,0,0,198,224V48A14,14,0,0,0,184,34Zm2,179.2-54.8-34.3a6.1,6.1,0,0,0-6.4,0L70,213.2V48a2,2,0,0,1,2-2H184a2,2,0,0,1,2,2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M184,36H72A12,12,0,0,0,60,48V224a3.9,3.9,0,0,0,2.1,3.5,3.8,3.8,0,0,0,4-.1L128,188.7l61.9,38.7a3.9,3.9,0,0,0,2.1.6,4.1,4.1,0,0,0,1.9-.5A3.9,3.9,0,0,0,196,224V48A12,12,0,0,0,184,36Zm4,180.8-57.9-36.2a4,4,0,0,0-4.2,0L68,216.8V48a4,4,0,0,1,4-4H184a4,4,0,0,1,4,4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M184,32H72A16,16,0,0,0,56,48V224a8.1,8.1,0,0,0,4.1,7,7.8,7.8,0,0,0,3.9,1,7.6,7.6,0,0,0,4.2-1.2L128,193.4l59.8,37.4A8,8,0,0,0,200,224V48A16,16,0,0,0,184,32Zm0,177.6-51.8-32.4a8,8,0,0,0-8.4,0L72,209.6V48H184Z" />
        </>
      )}
    </svg>
  );
}

export function PhBookmark(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M184,28H72A20.1,20.1,0,0,0,52,48V224a12,12,0,0,0,18.4,10.2l57.6-36,57.6,36A12.4,12.4,0,0,0,192,236a12,12,0,0,0,12-12V48A20.1,20.1,0,0,0,184,28Zm-4,24V154.3l-45.6-28.5a12.3,12.3,0,0,0-12.8,0L76,154.3V52ZM134.4,173.8a12.3,12.3,0,0,0-12.8,0L76,202.3V182.7l52-32.5,52,32.5v19.7Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <polygon points="192 176 128 136 64 176 64 224 128 184 192 224 192 176" />
          </g>
          <path d="M184,32H72A16,16,0,0,0,56,48V224a8.1,8.1,0,0,0,4.1,7,7.8,7.8,0,0,0,3.9,1,7.6,7.6,0,0,0,4.2-1.2L128,193.4l59.8,37.4A8,8,0,0,0,200,224V48A16,16,0,0,0,184,32Zm0,16V161.6l-51.8-32.4a8,8,0,0,0-8.4,0L72,161.6V48ZM132.2,177.2a8,8,0,0,0-8.4,0L72,209.6V180.4l56-35,56,35v29.2Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M184,32H72A16,16,0,0,0,56,48V224a8.1,8.1,0,0,0,4.1,7,7.8,7.8,0,0,0,3.9,1,7.6,7.6,0,0,0,4.2-1.2L128,193.4l59.8,37.4A8,8,0,0,0,200,224V48A16,16,0,0,0,184,32Zm0,177.6-51.8-32.4a8,8,0,0,0-8.4,0L72,209.6V180.4l56-35,56,35Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M184,34H72A14,14,0,0,0,58,48V224a5.9,5.9,0,0,0,3.1,5.2,5.1,5.1,0,0,0,2.9.8,6.2,6.2,0,0,0,3.2-.9l60.8-38,60.8,38a6.1,6.1,0,0,0,6.1.1A5.9,5.9,0,0,0,198,224V48A14,14,0,0,0,184,34ZM72,46H184a2,2,0,0,1,2,2V165.2l-54.8-34.3a6.1,6.1,0,0,0-6.4,0L70,165.2V48A2,2,0,0,1,72,46Zm59.2,132.9a6.1,6.1,0,0,0-6.4,0L70,213.2V179.3l58-36.2,58,36.2v33.9Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M184,36H72A12,12,0,0,0,60,48V224a3.9,3.9,0,0,0,2.1,3.5,3.8,3.8,0,0,0,4-.1L128,188.7l61.9,38.7a3.9,3.9,0,0,0,2.1.6,4.1,4.1,0,0,0,1.9-.5A3.9,3.9,0,0,0,196,224V48A12,12,0,0,0,184,36Zm4,180.8-57.9-36.2a4,4,0,0,0-4.2,0L68,216.8V178.2l60-37.5,60,37.5Zm0-48-57.9-36.2a4,4,0,0,0-4.2,0L68,168.8V48a4,4,0,0,1,4-4H184a4,4,0,0,1,4,4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M184,32H72A16,16,0,0,0,56,48V224a8.1,8.1,0,0,0,4.1,7,7.8,7.8,0,0,0,3.9,1,7.6,7.6,0,0,0,4.2-1.2L128,193.4l59.8,37.4A8,8,0,0,0,200,224V48A16,16,0,0,0,184,32Zm0,16V161.6l-51.8-32.4a8,8,0,0,0-8.4,0L72,161.6V48ZM132.2,177.2a8,8,0,0,0-8.4,0L72,209.6V180.4l56-35,56,35v29.2Z" />
        </>
      )}
    </svg>
  );
}

export function PhBookmarksSimple(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M156,56H60A20.1,20.1,0,0,0,40,76V228a12.1,12.1,0,0,0,6.5,10.7,12.2,12.2,0,0,0,12.5-.9l49-35.1,49,35.1a12.6,12.6,0,0,0,7,2.2,11.7,11.7,0,0,0,5.5-1.3A12.1,12.1,0,0,0,176,228V76A20.1,20.1,0,0,0,156,56Zm-4,148.7-37-26.5a12.1,12.1,0,0,0-7-2.2,11.9,11.9,0,0,0-7,2.2L64,204.7V80h88ZM216,36V188a12,12,0,0,1-24,0V40H92a12,12,0,0,1,0-24H196A20.1,20.1,0,0,1,216,36Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M168,224l-56-40L56,224V72a8,8,0,0,1,8-8h96a8,8,0,0,1,8,8Z" />
          </g>
          <path d="M160,56H64A16,16,0,0,0,48,72V224a8,8,0,0,0,8,8,8.2,8.2,0,0,0,4.7-1.5L112,193.8l51.4,36.7a7.8,7.8,0,0,0,8.3.6A8.1,8.1,0,0,0,176,224V72A16,16,0,0,0,160,56Zm0,152.5-43.4-31A7.7,7.7,0,0,0,112,176a8.2,8.2,0,0,0-4.7,1.5L64,208.5V72h96Z" />
          <path d="M192,24H88a8,8,0,0,0,0,16H192V192a8,8,0,0,0,16,0V40A16,16,0,0,0,192,24Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M176,72V224a8.1,8.1,0,0,1-4.3,7.1,7.8,7.8,0,0,1-8.3-.6L112,193.8,60.7,230.5A8.2,8.2,0,0,1,56,232a8,8,0,0,1-8-8V72A16,16,0,0,1,64,56h96A16,16,0,0,1,176,72Zm16-48H88a8,8,0,0,0,0,16H192V192a8,8,0,0,0,16,0V40A16,16,0,0,0,192,24Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M160,58H64A14,14,0,0,0,50,72V224a6,6,0,0,0,3.3,5.3,5.8,5.8,0,0,0,6.2-.4L112,191.4l52.5,37.5A6.3,6.3,0,0,0,168,230a5.2,5.2,0,0,0,2.7-.7A6,6,0,0,0,174,224V72A14,14,0,0,0,160,58Zm2,154.3-46.5-33.2A6.6,6.6,0,0,0,112,178a6.3,6.3,0,0,0-3.5,1.1L62,212.3V72a2,2,0,0,1,2-2h96a2,2,0,0,1,2,2ZM206,40V192a6,6,0,0,1-12,0V40a2,2,0,0,0-2-2H88a6,6,0,0,1,0-12H192A14,14,0,0,1,206,40Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M160,60H64A12,12,0,0,0,52,72V224a4,4,0,0,0,2.2,3.6,4.3,4.3,0,0,0,4.1-.3L112,188.9l53.7,38.4a4.8,4.8,0,0,0,2.3.7,3.7,3.7,0,0,0,1.8-.4A4,4,0,0,0,172,224V72A12,12,0,0,0,160,60Zm4,156.2-49.7-35.5a4.1,4.1,0,0,0-4.6,0L60,216.2V72a4,4,0,0,1,4-4h96a4,4,0,0,1,4,4ZM204,40V192a4,4,0,0,1-8,0V40a4,4,0,0,0-4-4H88a4,4,0,0,1,0-8H192A12,12,0,0,1,204,40Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M160,56H64A16,16,0,0,0,48,72V224a8,8,0,0,0,8,8,8.2,8.2,0,0,0,4.7-1.5L112,193.8l51.4,36.7a7.8,7.8,0,0,0,8.3.6A8.1,8.1,0,0,0,176,224V72A16,16,0,0,0,160,56Zm0,152.5-43.4-31A7.7,7.7,0,0,0,112,176a8.2,8.2,0,0,0-4.7,1.5L64,208.5V72h96ZM208,40V192a8,8,0,0,1-16,0V40H88a8,8,0,0,1,0-16H192A16,16,0,0,1,208,40Z" />
        </>
      )}
    </svg>
  );
}

export function PhBookmarks(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M192,20H96A20.1,20.1,0,0,0,76,40V60H64A20.1,20.1,0,0,0,44,80V224a12.1,12.1,0,0,0,6.7,10.8A12.4,12.4,0,0,0,56,236a11.5,11.5,0,0,0,7.3-2.5L108,199.1l44.7,34.4a12,12,0,0,0,12.6,1.3A12.1,12.1,0,0,0,172,224V177.6l20.7,15.9A11.5,11.5,0,0,0,200,196a12.4,12.4,0,0,0,5.3-1.2A12.1,12.1,0,0,0,212,184V40A20.1,20.1,0,0,0,192,20ZM148,199.6l-32.7-25.1a11.9,11.9,0,0,0-14.6,0L68,199.6V84h80Zm40-40-16-12.3V80a20.1,20.1,0,0,0-20-20H100V44h88Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M192,32H96a8,8,0,0,0-8,8V64h72a8,8,0,0,1,8,8v97.1L200,192V40A8,8,0,0,0,192,32Z" />
          </g>
          <path d="M192,24H96A16,16,0,0,0,80,40V56H64A16,16,0,0,0,48,72V224a8,8,0,0,0,8,8,8.2,8.2,0,0,0,4.7-1.5L112,193.8l51.4,36.7a7.8,7.8,0,0,0,8.3.6A8.1,8.1,0,0,0,176,224V184.7l19.4,13.8A7.7,7.7,0,0,0,200,200a8,8,0,0,0,8-8V40A16,16,0,0,0,192,24ZM160,208.5l-43.4-31A7.7,7.7,0,0,0,112,176a8.2,8.2,0,0,0-4.7,1.5L64,208.5V72h96Zm32-32L176,165V72a16,16,0,0,0-16-16H96V40h96Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M192,24H96A16,16,0,0,0,80,40V56H64A16,16,0,0,0,48,72V224a8,8,0,0,0,8,8,8.2,8.2,0,0,0,4.7-1.5L112,193.8l51.4,36.7a7.8,7.8,0,0,0,8.3.6A8.1,8.1,0,0,0,176,224V184.7l19.4,13.8A7.7,7.7,0,0,0,200,200a8,8,0,0,0,8-8V40A16,16,0,0,0,192,24Zm0,152.5L176,165V72a16,16,0,0,0-16-16H96V40h96Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M192,26H96A14,14,0,0,0,82,40V58H64A14,14,0,0,0,50,72V224a6,6,0,0,0,3.3,5.3,5.8,5.8,0,0,0,6.2-.4L112,191.4l52.5,37.5A6.3,6.3,0,0,0,168,230a5.2,5.2,0,0,0,2.7-.7A6,6,0,0,0,174,224V180.8l22.5,16.1A6.3,6.3,0,0,0,200,198a5.2,5.2,0,0,0,2.7-.7A6,6,0,0,0,206,192V40A14,14,0,0,0,192,26ZM162,212.3l-46.5-33.2A6.6,6.6,0,0,0,112,178a6.3,6.3,0,0,0-3.5,1.1L62,212.3V72a2,2,0,0,1,2-2h96a2,2,0,0,1,2,2Zm32-32-20-14.2V72a14,14,0,0,0-14-14H94V40a2,2,0,0,1,2-2h96a2,2,0,0,1,2,2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M192,28H96A12,12,0,0,0,84,40V60H64A12,12,0,0,0,52,72V224a4,4,0,0,0,2.2,3.6,4.3,4.3,0,0,0,4.1-.3L112,188.9l53.7,38.4a4.8,4.8,0,0,0,2.3.7,3.7,3.7,0,0,0,1.8-.4A4,4,0,0,0,172,224V176.9l25.7,18.4a4.8,4.8,0,0,0,2.3.7,3.7,3.7,0,0,0,1.8-.4A4,4,0,0,0,204,192V40A12,12,0,0,0,192,28ZM164,216.2l-49.7-35.5a4.1,4.1,0,0,0-4.6,0L60,216.2V72a4,4,0,0,1,4-4h96a4,4,0,0,1,4,4Zm32-32-24-17.1V72a12,12,0,0,0-12-12H92V40a4,4,0,0,1,4-4h96a4,4,0,0,1,4,4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M192,24H96A16,16,0,0,0,80,40V56H64A16,16,0,0,0,48,72V224a8,8,0,0,0,8,8,8.2,8.2,0,0,0,4.7-1.5L112,193.8l51.4,36.7a7.8,7.8,0,0,0,8.3.6A8.1,8.1,0,0,0,176,224V184.7l19.4,13.8A7.7,7.7,0,0,0,200,200a8,8,0,0,0,8-8V40A16,16,0,0,0,192,24ZM160,208.5l-43.4-31A7.7,7.7,0,0,0,112,176a8.2,8.2,0,0,0-4.7,1.5L64,208.5V72h96Zm32-32L176,165V72a16,16,0,0,0-16-16H96V40h96Z" />
        </>
      )}
    </svg>
  );
}

export function PhBooks(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M237.5,194.5l-8.3-30.9h0L196.1,40a20.1,20.1,0,0,0-24.5-14.2l-29.6,8A19.6,19.6,0,0,0,128,28H96a19.8,19.8,0,0,0-8,1.7A19.8,19.8,0,0,0,80,28H48A20.1,20.1,0,0,0,28,48V208a20.1,20.1,0,0,0,20,20H80a19.8,19.8,0,0,0,8-1.7,19.8,19.8,0,0,0,8,1.7h32a20.1,20.1,0,0,0,20-20V138.8l19.9,74.4A20.1,20.1,0,0,0,187.3,228a17.9,17.9,0,0,0,5.1-.7l30.9-8.3h0A20,20,0,0,0,237.5,194.5ZM161.1,94.9l23.2-6.2,18.6,69.6-23.2,6.2Zm12.8-44.8,4.2,15.4-23.2,6.2-4.2-15.4ZM124,164H100V52h24ZM76,52V68H52V52ZM52,92H76V204H52Zm48,112V188h24v16Zm90.1-.9-4.2-15.5,23.2-6.2,4.2,15.5Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M136,48V176H88V80H40V48a8,8,0,0,1,8-8H80a8,8,0,0,1,8,8,8,8,0,0,1,8-8h32A8,8,0,0,1,136,48Zm89.9,149.6-8.3-30.9-46.4,12.5,8.3,30.9a8,8,0,0,0,9.8,5.6l30.9-8.3A8,8,0,0,0,225.9,197.6ZM184.5,43.1a8.1,8.1,0,0,0-9.8-5.7l-30.9,8.3a8.1,8.1,0,0,0-5.7,9.8l8.3,30.9L192.8,74Z"
            opacity="0.2"
          />
          <path d="M233.6,195.6,192.2,41a16,16,0,0,0-19.6-11.3L141.7,38l-1,.3A16,16,0,0,0,128,32H96a15.8,15.8,0,0,0-8,2.2A15.8,15.8,0,0,0,80,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H80a15.8,15.8,0,0,0,8-2.2,15.8,15.8,0,0,0,8,2.2h32a16,16,0,0,0,16-16V108.4l27.8,103.7A16,16,0,0,0,187.3,224a19.9,19.9,0,0,0,4.1-.5l30.9-8.3A16,16,0,0,0,233.6,195.6ZM156.2,92.1l30.9-8.3,20.7,77.3-30.9,8.3Zm20.5-46.9L183,68.3l-30.9,8.3-6.3-23.1ZM128,48V168H96V48ZM80,48V72H48V48ZM48,208V88H80V208Zm80,0H96V184h32v24Zm90.2-8.3L187.3,208,181,184.8l31-8.3,6.2,23.2Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M233.6,195.6,192.2,41a16,16,0,0,0-19.6-11.3L141.7,38l-1,.3A16,16,0,0,0,128,32H96a15.8,15.8,0,0,0-8,2.2A15.8,15.8,0,0,0,80,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H80a15.8,15.8,0,0,0,8-2.2,15.8,15.8,0,0,0,8,2.2h32a16,16,0,0,0,16-16V108.4l27.8,103.7A16,16,0,0,0,187.3,224a19.9,19.9,0,0,0,4.1-.5l30.9-8.3A16,16,0,0,0,233.6,195.6ZM176.7,45.2,183,68.3l-30.9,8.3-6.3-23.1ZM128,48V168H96V48ZM80,48V72H48V48Zm48,160H96V184h32v24Zm90.2-8.3L187.3,208,181,184.8l31-8.3,6.2,23.2Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M231.7,196.1l-8.3-30.9h0L190.3,41.5a14.1,14.1,0,0,0-17.2-9.9l-30.9,8.3a8.5,8.5,0,0,0-2.2.9A14,14,0,0,0,128,34H96a13.8,13.8,0,0,0-8,2.5A13.8,13.8,0,0,0,80,34H48A14,14,0,0,0,34,48V208a14,14,0,0,0,14,14H80a13.8,13.8,0,0,0,8-2.5,13.8,13.8,0,0,0,8,2.5h32a14,14,0,0,0,14-14V93.2l31.7,118.4A14.1,14.1,0,0,0,187.3,222a14.9,14.9,0,0,0,3.6-.5l30.9-8.3A14,14,0,0,0,231.7,196.1Zm-78-105.4,34.8-9.3,21.8,81.1-34.8,9.3Zm-9.6-38.2a2.1,2.1,0,0,1,1.2-1l30.9-8.3h.5a2.2,2.2,0,0,1,2,1.4l6.7,25.2-34.8,9.3L143.9,54A2.1,2.1,0,0,1,144.1,52.5ZM96,46h32a2,2,0,0,1,2,2V170H94V48A2,2,0,0,1,96,46ZM48,46H80a2,2,0,0,1,2,2V74H46V48A2,2,0,0,1,48,46ZM80,210H48a2,2,0,0,1-2-2V86H82V208A2,2,0,0,1,80,210Zm48,0H96a2,2,0,0,1-2-2V182h36v26A2,2,0,0,1,128,210Zm90.7-8.4-30.9,8.3a2,2,0,0,1-2.5-1.4l-6.7-25.1,34.8-9.3,6.7,25.1A1.9,1.9,0,0,1,218.7,201.6Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M229.8,196.6l-8.3-30.9h0L196.6,73h0l-8.3-30.9a12,12,0,0,0-14.7-8.5l-30.9,8.3a11.7,11.7,0,0,0-3.6,1.6A12,12,0,0,0,128,36H96a11.9,11.9,0,0,0-8,3.1A11.9,11.9,0,0,0,80,36H48A12,12,0,0,0,36,48V208a12,12,0,0,0,12,12H80a11.9,11.9,0,0,0,8-3.1,11.9,11.9,0,0,0,8,3.1h32a12,12,0,0,0,12-12V78l2.5,9.5h0l24.9,92.7h0l8.3,30.9a11.9,11.9,0,0,0,11.6,8.9,11.5,11.5,0,0,0,3.1-.4l30.9-8.3a11.8,11.8,0,0,0,7.3-5.6A12.1,12.1,0,0,0,229.8,196.6ZM151.3,89.3l38.6-10.4,22.8,85-38.6,10.4ZM96,44h32a4,4,0,0,1,4,4V172H92V48A4,4,0,0,1,96,44ZM48,44H80a4,4,0,0,1,4,4V76H44V48A4,4,0,0,1,48,44ZM80,212H48a4,4,0,0,1-4-4V84H84V208A4,4,0,0,1,80,212Zm48,0H96a4,4,0,0,1-4-4V180h40v28A4,4,0,0,1,128,212ZM142.4,51.5a3.8,3.8,0,0,1,2.4-1.9l30.9-8.3h1a4,4,0,0,1,3.9,2.9l7.3,27.1L149.2,81.5l-7.2-27A3.5,3.5,0,0,1,142.4,51.5Zm79.2,150.2a4.1,4.1,0,0,1-2.4,1.9l-30.9,8.3a4.1,4.1,0,0,1-4.9-2.9l-7.3-27,38.7-10.4,7.2,27.1A3.5,3.5,0,0,1,221.6,201.7Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M233.6,195.6l-8.3-30.9h0L192.2,41a16,16,0,0,0-19.6-11.3L141.7,38l-1,.3A16,16,0,0,0,128,32H96a15.8,15.8,0,0,0-8,2.2A15.8,15.8,0,0,0,80,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H80a15.8,15.8,0,0,0,8-2.2,15.8,15.8,0,0,0,8,2.2h32a16,16,0,0,0,16-16V108.4l19.5,72.8h0l8.3,30.9A16,16,0,0,0,187.3,224a19.9,19.9,0,0,0,4.1-.5l30.9-8.3a15.9,15.9,0,0,0,9.7-7.5A15.6,15.6,0,0,0,233.6,195.6ZM156.2,92.1l30.9-8.3,20.7,77.3-30.9,8.3Zm20.5-46.9L183,68.3l-30.9,8.3-6.3-23.1ZM128,48V168H96V48ZM80,48V72H48V48ZM48,208V88H80V208Zm80,0H96V184h32v24Zm90.2-8.3L187.3,208,181,184.8l31-8.3,6.2,23.2Z" />
        </>
      )}
    </svg>
  );
}

export function PhBoundingBox(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M208,100a20.1,20.1,0,0,0,20-20V48a20.1,20.1,0,0,0-20-20H176a20.1,20.1,0,0,0-20,20v4H100V48A20.1,20.1,0,0,0,80,28H48A20.1,20.1,0,0,0,28,48V80a20.1,20.1,0,0,0,20,20h4v56H48a20.1,20.1,0,0,0-20,20v32a20.1,20.1,0,0,0,20,20H80a20.1,20.1,0,0,0,20-20v-4h56v4a20.1,20.1,0,0,0,20,20h32a20.1,20.1,0,0,0,20-20V176a20.1,20.1,0,0,0-20-20h-4V100ZM180,52h24V76H180ZM52,52H76V76H52ZM76,204H52V180H76Zm128,0H180V180h24Zm-24-48h-4a20.1,20.1,0,0,0-20,20v4H100v-4a20.1,20.1,0,0,0-20-20H76V100h4a20.1,20.1,0,0,0,20-20V76h56v4a20.1,20.1,0,0,0,20,20h4Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M216,48V80a8,8,0,0,1-8,8H176a8,8,0,0,1-8-8V48a8,8,0,0,1,8-8h32A8,8,0,0,1,216,48ZM80,40H48a8,8,0,0,0-8,8V80a8,8,0,0,0,8,8H80a8,8,0,0,0,8-8V48A8,8,0,0,0,80,40ZM208,168H176a8,8,0,0,0-8,8v32a8,8,0,0,0,8,8h32a8,8,0,0,0,8-8V176A8,8,0,0,0,208,168ZM80,168H48a8,8,0,0,0-8,8v32a8,8,0,0,0,8,8H80a8,8,0,0,0,8-8V176A8,8,0,0,0,80,168Z"
            opacity="0.2"
          />
          <path d="M208,96a16,16,0,0,0,16-16V48a16,16,0,0,0-16-16H176a16,16,0,0,0-16,16v8H96V48A16,16,0,0,0,80,32H48A16,16,0,0,0,32,48V80A16,16,0,0,0,48,96h8v64H48a16,16,0,0,0-16,16v32a16,16,0,0,0,16,16H80a16,16,0,0,0,16-16v-8h64v8a16,16,0,0,0,16,16h32a16,16,0,0,0,16-16V176a16,16,0,0,0-16-16h-8V96ZM176,48h32V80H176ZM48,48H80V63.9h0V80H48ZM80,208H48V176H80v15.9h0V208Zm128,0H176V176h32Zm-24-48h-8a16,16,0,0,0-16,16v8H96v-8a16,16,0,0,0-16-16H72V96h8A16,16,0,0,0,96,80V72h64v8a16,16,0,0,0,16,16h8Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M208,96a16,16,0,0,0,16-16V48a16,16,0,0,0-16-16H176a16,16,0,0,0-16,16v8H96V48A16,16,0,0,0,80,32H48A16,16,0,0,0,32,48V80A16,16,0,0,0,48,96h8v64H48a16,16,0,0,0-16,16v32a16,16,0,0,0,16,16H80a16,16,0,0,0,16-16v-8h64v8a16,16,0,0,0,16,16h32a16,16,0,0,0,16-16V176a16,16,0,0,0-16-16h-8V96Zm-24,64h-8a16,16,0,0,0-16,16v8H96v-8a16,16,0,0,0-16-16H72V96h8A16,16,0,0,0,96,80V72h64v8a16,16,0,0,0,16,16h8Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M208,94a14,14,0,0,0,14-14V48a14,14,0,0,0-14-14H176a14,14,0,0,0-14,14V58H94V48A14,14,0,0,0,80,34H48A14,14,0,0,0,34,48V80A14,14,0,0,0,48,94H58v68H48a14,14,0,0,0-14,14v32a14,14,0,0,0,14,14H80a14,14,0,0,0,14-14V198h68v10a14,14,0,0,0,14,14h32a14,14,0,0,0,14-14V176a14,14,0,0,0-14-14H198V94ZM174,48a2,2,0,0,1,2-2h32a2,2,0,0,1,2,2V80a2,2,0,0,1-2,2H176a2,2,0,0,1-2-2ZM46,80V48a2,2,0,0,1,2-2H80a2,2,0,0,1,2,2V80a2,2,0,0,1-2,2H48A2,2,0,0,1,46,80ZM82,208a2,2,0,0,1-2,2H48a2,2,0,0,1-2-2V176a2,2,0,0,1,2-2H80a2,2,0,0,1,2,2Zm128-32v32a2,2,0,0,1-2,2H176a2,2,0,0,1-2-2V176a2,2,0,0,1,2-2h32A2,2,0,0,1,210,176Zm-24-14H176a14,14,0,0,0-14,14v10H94V176a14,14,0,0,0-14-14H70V94H80A14,14,0,0,0,94,80V70h68V80a14,14,0,0,0,14,14h10Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M208,92a12,12,0,0,0,12-12V48a12,12,0,0,0-12-12H176a12,12,0,0,0-12,12V60H92V48A12,12,0,0,0,80,36H48A12,12,0,0,0,36,48V80A12,12,0,0,0,48,92H60v72H48a12,12,0,0,0-12,12v32a12,12,0,0,0,12,12H80a12,12,0,0,0,12-12V196h72v12a12,12,0,0,0,12,12h32a12,12,0,0,0,12-12V176a12,12,0,0,0-12-12H196V92ZM172,48a4,4,0,0,1,4-4h32a4,4,0,0,1,4,4V80a4,4,0,0,1-4,4H176a4,4,0,0,1-4-4ZM44,80V48a4,4,0,0,1,4-4H80a4,4,0,0,1,4,4V80a4,4,0,0,1-4,4H48A4,4,0,0,1,44,80ZM84,208a4,4,0,0,1-4,4H48a4,4,0,0,1-4-4V176a4,4,0,0,1,4-4H80a4,4,0,0,1,4,4Zm128-32v32a4,4,0,0,1-4,4H176a4,4,0,0,1-4-4V176a4,4,0,0,1,4-4h32A4,4,0,0,1,212,176Zm-24-12H176a12,12,0,0,0-12,12v12H92V176a12,12,0,0,0-12-12H68V92H80A12,12,0,0,0,92,80V68h72V80a12,12,0,0,0,12,12h12Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M208,96a16,16,0,0,0,16-16V48a16,16,0,0,0-16-16H176a16,16,0,0,0-16,16v8H96V48A16,16,0,0,0,80,32H48A16,16,0,0,0,32,48V80A16,16,0,0,0,48,96h8v64H48a16,16,0,0,0-16,16v32a16,16,0,0,0,16,16H80a16,16,0,0,0,16-16v-8h64v8a16,16,0,0,0,16,16h32a16,16,0,0,0,16-16V176a16,16,0,0,0-16-16h-8V96ZM176,48h32V80H176ZM48,48H80V63.9h0V80H48ZM80,208H48V176H80v15.9h0V208Zm128,0H176V176h32Zm-24-48h-8a16,16,0,0,0-16,16v8H96v-8a16,16,0,0,0-16-16H72V96h8A16,16,0,0,0,96,80V72h64v8a16,16,0,0,0,16,16h8Z" />
        </>
      )}
    </svg>
  );
}

export function PhBracketsAngle(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M90.1,46.4,38.2,128l51.9,81.6a11.9,11.9,0,0,1-3.7,16.5A11.6,11.6,0,0,1,80,228a11.9,11.9,0,0,1-10.1-5.6l-56-88a11.9,11.9,0,0,1,0-12.8l56-88A12,12,0,0,1,90.1,46.4Zm152,75.2-56-88a12,12,0,1,0-20.2,12.8L217.8,128l-51.9,81.6a11.9,11.9,0,0,0,3.7,16.5A11.6,11.6,0,0,0,176,228a11.9,11.9,0,0,0,10.1-5.6l56-88A11.9,11.9,0,0,0,242.1,121.6Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M86.7,44.3,33.5,128l53.2,83.7a7.9,7.9,0,0,1-2.4,11A7.5,7.5,0,0,1,80,224a7.9,7.9,0,0,1-6.7-3.7l-56-88a7.9,7.9,0,0,1,0-8.6l56-88a8,8,0,1,1,13.4,8.6Zm152,79.4-56-88a8,8,0,1,0-13.4,8.6L222.5,128l-53.2,83.7a7.9,7.9,0,0,0,2.4,11A7.5,7.5,0,0,0,176,224a7.9,7.9,0,0,0,6.7-3.7l56-88A7.9,7.9,0,0,0,238.7,123.7Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M86.7,44.3,33.5,128l53.2,83.7a7.9,7.9,0,0,1-2.4,11A7.5,7.5,0,0,1,80,224a7.9,7.9,0,0,1-6.7-3.7l-56-88a7.9,7.9,0,0,1,0-8.6l56-88a8,8,0,1,1,13.4,8.6Zm152,79.4-56-88a8,8,0,1,0-13.4,8.6L222.5,128l-53.2,83.7a7.9,7.9,0,0,0,2.4,11A7.5,7.5,0,0,0,176,224a7.9,7.9,0,0,0,6.7-3.7l56-88A7.9,7.9,0,0,0,238.7,123.7Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M85.1,43.2,31.1,128l54,84.8a6.1,6.1,0,0,1-1.9,8.3,6.2,6.2,0,0,1-3.2.9,6.1,6.1,0,0,1-5.1-2.8l-56-88a6.1,6.1,0,0,1,0-6.4l56-88a6,6,0,1,1,10.2,6.4Zm152,81.6-56-88a6,6,0,0,0-10.2,6.4l54,84.8-54,84.8a6.1,6.1,0,0,0,1.9,8.3,6.2,6.2,0,0,0,3.2.9,6.1,6.1,0,0,0,5.1-2.8l56-88A6.1,6.1,0,0,0,237.1,124.8Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M83.4,42.1,28.7,128l54.7,85.9A4,4,0,0,1,80,220a4.2,4.2,0,0,1-3.4-1.9l-56-88a4,4,0,0,1,0-4.2l56-88a4,4,0,1,1,6.8,4.2Zm152,83.8-56-88a4,4,0,1,0-6.8,4.2L227.3,128l-54.7,85.9A4,4,0,0,0,176,220a4.2,4.2,0,0,0,3.4-1.9l56-88A4,4,0,0,0,235.4,125.9Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M86.7,44.3,33.5,128l53.2,83.7a7.9,7.9,0,0,1-2.4,11A7.5,7.5,0,0,1,80,224a7.9,7.9,0,0,1-6.7-3.7l-56-88a7.9,7.9,0,0,1,0-8.6l56-88a8,8,0,1,1,13.4,8.6Zm152,79.4-56-88a8,8,0,1,0-13.4,8.6L222.5,128l-53.2,83.7a7.9,7.9,0,0,0,2.4,11A7.5,7.5,0,0,0,176,224a7.9,7.9,0,0,0,6.7-3.7l56-88A7.9,7.9,0,0,0,238.7,123.7Z" />
        </>
      )}
    </svg>
  );
}

export function PhBracketsCurly(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M54.8,119.5a39.5,39.5,0,0,1-5.7,8.5,39.5,39.5,0,0,1,5.7,8.5C60,147.2,60,159.8,60,172c0,25.9,1.8,32,20,32a12,12,0,0,1,0,24c-19.1,0-32.2-6.9-38.8-20.5C36,196.8,36,184.2,36,172c0-25.9-1.8-32-20-32a12,12,0,0,1,0-24c18.2,0,20-6.1,20-32,0-12.2,0-24.8,5.2-35.5C47.8,34.9,60.9,28,80,28a12,12,0,0,1,0,24c-18.2,0-20,6.1-20,32C60,96.2,60,108.8,54.8,119.5ZM240,116c-18.2,0-20-6.1-20-32,0-12.2,0-24.8-5.2-35.5C208.2,34.9,195.1,28,176,28a12,12,0,0,0,0,24c18.2,0,20,6.1,20,32,0,12.2,0,24.8,5.2,35.5a39.5,39.5,0,0,0,5.7,8.5,39.5,39.5,0,0,0-5.7,8.5C196,147.2,196,159.8,196,172c0,25.9-1.8,32-20,32a12,12,0,0,0,0,24c19.1,0,32.2-6.9,38.8-20.5,5.2-10.7,5.2-23.3,5.2-35.5,0-25.9,1.8-32,20-32a12,12,0,0,0,0-24Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M43.2,128a30.4,30.4,0,0,1,8,10.3c4.8,9.9,4.8,22,4.8,33.7,0,24.3,1,36,24,36a8,8,0,0,1,0,16c-17.5,0-29.3-6.1-35.2-18.3C40,195.8,40,183.7,40,172c0-24.3-1-36-24-36a8,8,0,0,1,0-16c23,0,24-11.7,24-36,0-11.7,0-23.8,4.8-33.7C50.7,38.1,62.5,32,80,32a8,8,0,0,1,0,16C57,48,56,59.7,56,84c0,11.7,0,23.8-4.8,33.7A30.4,30.4,0,0,1,43.2,128ZM240,120c-23,0-24-11.7-24-36,0-11.7,0-23.8-4.8-33.7C205.3,38.1,193.5,32,176,32a8,8,0,0,0,0,16c23,0,24,11.7,24,36,0,11.7,0,23.8,4.8,33.7a30.4,30.4,0,0,0,8,10.3,30.4,30.4,0,0,0-8,10.3c-4.8,9.9-4.8,22-4.8,33.7,0,24.3-1,36-24,36a8,8,0,0,0,0,16c17.5,0,29.3-6.1,35.2-18.3,4.8-9.9,4.8-22,4.8-33.7,0-24.3,1-36,24-36a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M43.2,128a30.4,30.4,0,0,1,8,10.3c4.8,9.9,4.8,22,4.8,33.7,0,24.3,1,36,24,36a8,8,0,0,1,0,16c-17.5,0-29.3-6.1-35.2-18.3C40,195.8,40,183.7,40,172c0-24.3-1-36-24-36a8,8,0,0,1,0-16c23,0,24-11.7,24-36,0-11.7,0-23.8,4.8-33.7C50.7,38.1,62.5,32,80,32a8,8,0,0,1,0,16C57,48,56,59.7,56,84c0,11.7,0,23.8-4.8,33.7A30.4,30.4,0,0,1,43.2,128ZM240,120c-23,0-24-11.7-24-36,0-11.7,0-23.8-4.8-33.7C205.3,38.1,193.5,32,176,32a8,8,0,0,0,0,16c23,0,24,11.7,24,36,0,11.7,0,23.8,4.8,33.7a30.4,30.4,0,0,0,8,10.3,30.4,30.4,0,0,0-8,10.3c-4.8,9.9-4.8,22-4.8,33.7,0,24.3-1,36-24,36a8,8,0,0,0,0,16c17.5,0,29.3-6.1,35.2-18.3,4.8-9.9,4.8-22,4.8-33.7,0-24.3,1-36,24-36a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M39.9,128a27.8,27.8,0,0,1,9.5,11.1C54,148.6,54,160.5,54,172c0,24.3,1.2,38,26,38a6,6,0,0,1,0,12c-16.9,0-27.8-5.6-33.4-17.1C42,195.4,42,183.5,42,172c0-24.3-1.2-38-26-38a6,6,0,0,1,0-12c24.8,0,26-13.7,26-38,0-11.5,0-23.4,4.6-32.9C52.2,39.6,63.1,34,80,34a6,6,0,0,1,0,12C55.2,46,54,59.7,54,84c0,11.5,0,23.4-4.6,32.9A27.8,27.8,0,0,1,39.9,128ZM240,122c-24.8,0-26-13.7-26-38,0-11.5,0-23.4-4.6-32.9C203.8,39.6,192.9,34,176,34a6,6,0,0,0,0,12c24.8,0,26,13.7,26,38,0,11.5,0,23.4,4.6,32.9a27.8,27.8,0,0,0,9.5,11.1,27.8,27.8,0,0,0-9.5,11.1c-4.6,9.5-4.6,21.4-4.6,32.9,0,24.3-1.2,38-26,38a6,6,0,0,0,0,12c16.9,0,27.8-5.6,33.4-17.1,4.6-9.5,4.6-21.4,4.6-32.9,0-24.3,1.2-38,26-38a6,6,0,0,0,0-12Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M35.9,128C52,136.2,52,155.6,52,172c0,24.8,1.4,40,28,40a4,4,0,0,1,0,8c-36,0-36-26.6-36-48,0-24.8-1.4-40-28-40a4,4,0,0,1,0-8c26.6,0,28-15.2,28-40,0-21.4,0-48,36-48a4,4,0,0,1,0,8C53.4,44,52,59.2,52,84,52,100.4,52,119.8,35.9,128ZM240,124c-26.6,0-28-15.2-28-40,0-21.4,0-48-36-48a4,4,0,0,0,0,8c26.6,0,28,15.2,28,40,0,16.4,0,35.8,16.1,44C204,136.2,204,155.6,204,172c0,24.8-1.4,40-28,40a4,4,0,0,0,0,8c36,0,36-26.6,36-48,0-24.8,1.4-40,28-40a4,4,0,0,0,0-8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M43.2,128a30.4,30.4,0,0,1,8,10.3c4.8,9.9,4.8,22,4.8,33.7,0,24.3,1,36,24,36a8,8,0,0,1,0,16c-17.5,0-29.3-6.1-35.2-18.3C40,195.8,40,183.7,40,172c0-24.3-1-36-24-36a8,8,0,0,1,0-16c23,0,24-11.7,24-36,0-11.7,0-23.8,4.8-33.7C50.7,38.1,62.5,32,80,32a8,8,0,0,1,0,16C57,48,56,59.7,56,84c0,11.7,0,23.8-4.8,33.7A30.4,30.4,0,0,1,43.2,128ZM240,120c-23,0-24-11.7-24-36,0-11.7,0-23.8-4.8-33.7C205.3,38.1,193.5,32,176,32a8,8,0,0,0,0,16c23,0,24,11.7,24,36,0,11.7,0,23.8,4.8,33.7a30.4,30.4,0,0,0,8,10.3,30.4,30.4,0,0,0-8,10.3c-4.8,9.9-4.8,22-4.8,33.7,0,24.3-1,36-24,36a8,8,0,0,0,0,16c17.5,0,29.3-6.1,35.2-18.3,4.8-9.9,4.8-22,4.8-33.7,0-24.3,1-36,24-36a8,8,0,0,0,0-16Z" />
        </>
      )}
    </svg>
  );
}

export function PhBracketsRound(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M82.3,222.2A12,12,0,0,1,72,228a11.9,11.9,0,0,1-6.2-1.7C64,225.2,20,198,20,128S64,30.8,65.8,29.7A12,12,0,1,1,78.2,50.2C76.7,51.2,44,72.3,44,128s32.9,76.9,34.3,77.8A12,12,0,0,1,82.3,222.2ZM190.2,29.7a12,12,0,0,0-12.5,20.5c1.4.9,34.3,22,34.3,77.8s-32.9,76.9-34.2,77.7A12,12,0,0,0,184,228a12.9,12.9,0,0,0,6.2-1.7C192,225.2,236,198,236,128S192,30.8,190.2,29.7Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M40,128c0,58.3,34.7,80.3,36.2,81.2A8,8,0,0,1,72,224a8.7,8.7,0,0,1-4.1-1.1C66.1,221.8,24,195.8,24,128S66.1,34.2,67.9,33.1a8.1,8.1,0,0,1,11,2.8,8,8,0,0,1-2.8,10.9C74.5,47.8,40,69.8,40,128ZM188.1,33.1a8,8,0,0,0-8.3,13.7c1.5.9,36.2,22.9,36.2,81.2s-34.7,80.3-36.1,81.1A8,8,0,0,0,184,224a8.7,8.7,0,0,0,4.1-1.1c1.8-1.1,43.9-27.1,43.9-94.9S189.9,34.2,188.1,33.1Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M40,128c0,58.3,34.7,80.3,36.2,81.2A8,8,0,0,1,72,224a8.7,8.7,0,0,1-4.1-1.1C66.1,221.8,24,195.8,24,128S66.1,34.2,67.9,33.1a8.1,8.1,0,0,1,11,2.8,8,8,0,0,1-2.8,10.9C74.5,47.8,40,69.8,40,128ZM188.1,33.1a8,8,0,0,0-8.3,13.7c1.5.9,36.2,22.9,36.2,81.2s-34.7,80.3-36.1,81.1A8,8,0,0,0,184,224a8.7,8.7,0,0,0,4.1-1.1c1.8-1.1,43.9-27.1,43.9-94.9S189.9,34.2,188.1,33.1Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M56.5,64C44.2,81.5,38,103,38,128s6.2,46.5,18.5,64c9.2,13,18.5,18.8,18.6,18.9a6,6,0,0,1,2,8.2,5.9,5.9,0,0,1-8.2,2C67.2,220.1,26,194.6,26,128S67.2,35.9,68.9,34.9a6,6,0,0,1,6.2,10.2C75,45.2,65.7,51,56.5,64ZM187.1,34.9a6,6,0,1,0-6.2,10.2c.1.1,9.4,5.9,18.6,18.9,12.3,17.5,18.5,39,18.5,64s-6.2,46.5-18.5,64c-9.2,13-18.5,18.8-18.6,18.9a6,6,0,0,0-2,8.2,5.9,5.9,0,0,0,8.2,2c1.7-1,42.9-26.5,42.9-93.1S188.8,35.9,187.1,34.9Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M55,62.7C46.3,74.9,36,96.2,36,128s10.3,53.1,19,65.3,19,19.2,19.1,19.3a3.9,3.9,0,0,1,1.3,5.5A4,4,0,0,1,72,220a4.2,4.2,0,0,1-2.1-.6C68.2,218.4,28,193.5,28,128S68.2,37.6,69.9,36.6a4,4,0,1,1,4.2,6.8C74,43.5,64.4,49.4,55,62.7ZM186.1,36.6a4,4,0,1,0-4.2,6.8c.1.1,9.7,6,19.1,19.3s19,33.5,19,65.3-10.3,53.1-19,65.3-19,19.2-19.1,19.3A4,4,0,0,0,184,220a4.2,4.2,0,0,0,2.1-.6c1.7-1,41.9-25.9,41.9-91.4S187.8,37.6,186.1,36.6Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M40,128c0,58.3,34.7,80.3,36.2,81.2A8,8,0,0,1,72,224a8.7,8.7,0,0,1-4.1-1.1C66.1,221.8,24,195.8,24,128S66.1,34.2,67.9,33.1a8.1,8.1,0,0,1,11,2.8,8,8,0,0,1-2.8,10.9C74.5,47.8,40,69.8,40,128ZM188.1,33.1a8,8,0,0,0-8.3,13.7c1.5.9,36.2,22.9,36.2,81.2s-34.7,80.3-36.1,81.1A8,8,0,0,0,184,224a8.7,8.7,0,0,0,4.1-1.1c1.8-1.1,43.9-27.1,43.9-94.9S189.9,34.2,188.1,33.1Z" />
        </>
      )}
    </svg>
  );
}

export function PhBracketsSquare(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M52,52V204H80a12,12,0,0,1,0,24H40a12,12,0,0,1-12-12V40A12,12,0,0,1,40,28H80a12,12,0,0,1,0,24ZM216,28H176a12,12,0,0,0,0,24h28V204H176a12,12,0,0,0,0,24h40a12,12,0,0,0,12-12V40A12,12,0,0,0,216,28Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M48,48V208H80a8,8,0,0,1,0,16H40a8,8,0,0,1-8-8V40a8,8,0,0,1,8-8H80a8,8,0,0,1,0,16ZM216,32H176a8,8,0,0,0,0,16h32V208H176a8,8,0,0,0,0,16h40a8,8,0,0,0,8-8V40A8,8,0,0,0,216,32Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M48,48V208H80a8,8,0,0,1,0,16H40a8,8,0,0,1-8-8V40a8,8,0,0,1,8-8H80a8,8,0,0,1,0,16ZM216,32H176a8,8,0,0,0,0,16h32V208H176a8,8,0,0,0,0,16h40a8,8,0,0,0,8-8V40A8,8,0,0,0,216,32Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M46,46V210H80a6,6,0,0,1,0,12H40a6,6,0,0,1-6-6V40a6,6,0,0,1,6-6H80a6,6,0,0,1,0,12ZM216,34H176a6,6,0,0,0,0,12h34V210H176a6,6,0,0,0,0,12h40a6,6,0,0,0,6-6V40A6,6,0,0,0,216,34Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M44,44V212H80a4,4,0,0,1,0,8H40a4,4,0,0,1-4-4V40a4,4,0,0,1,4-4H80a4,4,0,0,1,0,8Zm172-8H176a4,4,0,0,0,0,8h36V212H176a4,4,0,0,0,0,8h40a4,4,0,0,0,4-4V40A4,4,0,0,0,216,36Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M48,48V208H80a8,8,0,0,1,0,16H40a8,8,0,0,1-8-8V40a8,8,0,0,1,8-8H80a8,8,0,0,1,0,16ZM216,32H176a8,8,0,0,0,0,16h32V208H176a8,8,0,0,0,0,16h40a8,8,0,0,0,8-8V40A8,8,0,0,0,216,32Z" />
        </>
      )}
    </svg>
  );
}

export function PhBrain(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M252,132a60.3,60.3,0,0,0-32-53.1V72a52,52,0,0,0-92-33.2A52,52,0,0,0,36,72v6.9a60.1,60.1,0,0,0,0,106.2,52,52,0,0,0,92,32.1,52,52,0,0,0,92-32.1A60.2,60.2,0,0,0,252,132ZM88,212a28.2,28.2,0,0,1-26.9-20.1H72a12,12,0,0,0,0-24H64A36,36,0,0,1,52,98a11.9,11.9,0,0,0,8-11.3V72a28,28,0,0,1,56,0v68.2a50.1,50.1,0,0,0-7.2-3.9,12,12,0,0,0-9.6,22A28,28,0,0,1,88,212Zm104-44h-8a12,12,0,0,0,0,24h10.9a28,28,0,1,1-38.1-33.6,12,12,0,1,0-9.6-22,50.1,50.1,0,0,0-7.2,3.9V72a28,28,0,0,1,56,0V86.7A11.9,11.9,0,0,0,204,98a36,36,0,0,1-12,70ZM100,96a40,40,0,0,1-40,40,12,12,0,0,1,0-24A16,16,0,0,0,76,96V88a12,12,0,0,1,24,0Zm108,28a12,12,0,0,1-12,12,40,40,0,0,1-40-40V88a12,12,0,0,1,24,0v8a16,16,0,0,0,16,16A12,12,0,0,1,208,124Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M240,132a48.1,48.1,0,0,1-32,45.3h0V184a40,40,0,0,1-80,0,40,40,0,0,1-80,0v-6.7h0a48.1,48.1,0,0,1,0-90.6V72a40,40,0,0,1,80,0,40,40,0,0,1,80,0V86.7A48.1,48.1,0,0,1,240,132Z"
            opacity="0.2"
          />
          <path d="M248,132a56,56,0,0,0-32-50.6V72a48,48,0,0,0-88-26.5A48,48,0,0,0,40,72v9.4a56,56,0,0,0,0,101.2V184a48,48,0,0,0,88,26.5A48,48,0,0,0,216,184v-1.4A56.1,56.1,0,0,0,248,132ZM88,216a32,32,0,0,1-31.8-28.6,49.3,49.3,0,0,0,7.8.6h8a8,8,0,0,0,0-16H64A40,40,0,0,1,50.7,94.3,8.1,8.1,0,0,0,56,86.7V72a32,32,0,0,1,64,0v76.3A47.4,47.4,0,0,0,88,136a8,8,0,0,0,0,16,32,32,0,0,1,0,64Zm104-44h-8a8,8,0,0,0,0,16h8a49.3,49.3,0,0,0,7.8-.6A32,32,0,1,1,168,152a8,8,0,0,0,0-16,47.4,47.4,0,0,0-32,12.3V72a32,32,0,0,1,64,0V86.7a8.1,8.1,0,0,0,5.3,7.6A40,40,0,0,1,192,172ZM60,128a8,8,0,0,1,0-16A20.1,20.1,0,0,0,80,92V84a8,8,0,0,1,16,0v8A36,36,0,0,1,60,128Zm144-8a8,8,0,0,1-8,8,36,36,0,0,1-36-36V84a8,8,0,0,1,16,0v8a20.1,20.1,0,0,0,20,20A8,8,0,0,1,204,120Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M248,132a56,56,0,0,0-32-50.6V72a48,48,0,0,0-88-26.5A48,48,0,0,0,40,72v9.4a56,56,0,0,0,0,101.2V184a48,48,0,0,0,88,26.5A48,48,0,0,0,216,184v-1.4A56.1,56.1,0,0,0,248,132ZM88,216a32,32,0,0,1-31.8-28.6,49.3,49.3,0,0,0,7.8.6h8a8,8,0,0,0,0-16H64A40,40,0,0,1,50.7,94.3,8.1,8.1,0,0,0,56,86.7V72a32,32,0,0,1,64,0v76.3A47.4,47.4,0,0,0,88,136a8,8,0,0,0,0,16,32,32,0,0,1,0,64Zm104-44h-8a8,8,0,0,0,0,16h8a49.3,49.3,0,0,0,7.8-.6A32,32,0,1,1,168,152a8,8,0,0,0,0-16,47.4,47.4,0,0,0-32,12.3V72a32,32,0,0,1,64,0V86.7a8.1,8.1,0,0,0,5.3,7.6A40,40,0,0,1,192,172ZM60,128a8,8,0,0,1,0-16A20.1,20.1,0,0,0,80,92V84a8,8,0,0,1,16,0v8A36,36,0,0,1,60,128Zm144-8a8,8,0,0,1-8,8,36,36,0,0,1-36-36V84a8,8,0,0,1,16,0v8a20.1,20.1,0,0,0,20,20A8,8,0,0,1,204,120Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M246,132a54.1,54.1,0,0,0-32-49.3V72a46,46,0,0,0-86-22.7A46,46,0,0,0,42,72V82.7a54,54,0,0,0,0,98.6V184a46,46,0,0,0,86,22.7A46,46,0,0,0,214,184v-2.7A54.1,54.1,0,0,0,246,132ZM88,218a34.1,34.1,0,0,1-34-32.9,55.5,55.5,0,0,0,10,.9h8a6,6,0,0,0,0-12H64A42,42,0,0,1,50,92.4a6,6,0,0,0,4-5.7V72a34,34,0,0,1,68,0v81a45.9,45.9,0,0,0-34-15,6,6,0,0,0,0,12,34,34,0,0,1,0,68Zm104-44h-8a6,6,0,0,0,0,12h8a55.5,55.5,0,0,0,10-.9A34,34,0,1,1,168,150a6,6,0,0,0,0-12,45.9,45.9,0,0,0-34,15V72a34,34,0,0,1,68,0V86.7a6,6,0,0,0,4,5.7A42,42,0,0,1,192,174ZM60,126a6,6,0,0,1,0-12A22.1,22.1,0,0,0,82,92V84a6,6,0,0,1,12,0v8A34.1,34.1,0,0,1,60,126Zm142-6a6,6,0,0,1-6,6,34.1,34.1,0,0,1-34-34V84a6,6,0,0,1,12,0v8a22.1,22.1,0,0,0,22,22A6,6,0,0,1,202,120Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M244,132a52,52,0,0,0-32-48V72a44,44,0,0,0-84-18.3A44,44,0,0,0,44,72V84a52,52,0,0,0,0,96v4a44,44,0,0,0,84,18.3A44,44,0,0,0,212,184v-4A52.1,52.1,0,0,0,244,132ZM88,220a36,36,0,0,1-36-36v-1.4A53.2,53.2,0,0,0,64,184h8a4,4,0,0,0,0-8H64A44,44,0,0,1,49.3,90.5,4.1,4.1,0,0,0,52,86.7V72a36,36,0,0,1,72,0v86.7A44,44,0,0,0,88,140a4,4,0,0,0,0,8,36,36,0,0,1,0,72Zm104-44h-8a4,4,0,0,0,0,8h8a53.2,53.2,0,0,0,12-1.4V184a36,36,0,1,1-36-36,4,4,0,0,0,0-8,44,44,0,0,0-36,18.7V72a36,36,0,0,1,72,0V86.7a4.1,4.1,0,0,0,2.7,3.8A44,44,0,0,1,192,176ZM92,92a32.1,32.1,0,0,1-32,32,4,4,0,0,1,0-8A24.1,24.1,0,0,0,84,92V84a4,4,0,0,1,8,0Zm108,28a4,4,0,0,1-4,4,32.1,32.1,0,0,1-32-32V84a4,4,0,0,1,8,0v8a24.1,24.1,0,0,0,24,24A4,4,0,0,1,200,120Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M248,132a56,56,0,0,0-32-50.6V72a48,48,0,0,0-88-26.5A48,48,0,0,0,40,72v9.4a56,56,0,0,0,0,101.2V184a48,48,0,0,0,88,26.5A48,48,0,0,0,216,184v-1.4A56.1,56.1,0,0,0,248,132ZM88,216a32,32,0,0,1-31.8-28.6,49.3,49.3,0,0,0,7.8.6h8a8,8,0,0,0,0-16H64A40,40,0,0,1,50.7,94.3,8.1,8.1,0,0,0,56,86.7V72a32,32,0,0,1,64,0v76.3A47.4,47.4,0,0,0,88,136a8,8,0,0,0,0,16,32,32,0,0,1,0,64Zm104-44h-8a8,8,0,0,0,0,16h8a49.3,49.3,0,0,0,7.8-.6A32,32,0,1,1,168,152a8,8,0,0,0,0-16,47.4,47.4,0,0,0-32,12.3V72a32,32,0,0,1,64,0V86.7a8.1,8.1,0,0,0,5.3,7.6A40,40,0,0,1,192,172ZM60,128a8,8,0,0,1,0-16A20.1,20.1,0,0,0,80,92V84a8,8,0,0,1,16,0v8A36,36,0,0,1,60,128Zm144-8a8,8,0,0,1-8,8,36,36,0,0,1-36-36V84a8,8,0,0,1,16,0v8a20.1,20.1,0,0,0,20,20A8,8,0,0,1,204,120Z" />
        </>
      )}
    </svg>
  );
}

export function PhBrandy(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M228,88a99.7,99.7,0,0,0-16.2-54.6,11.9,11.9,0,0,0-10-5.4H54.2a11.9,11.9,0,0,0-10,5.4A100,100,0,0,0,116,187.3V212H88a12,12,0,0,0,0,24h80a12,12,0,0,0,0-24H140V187.3A100.2,100.2,0,0,0,228,88ZM61,52H195a78.3,78.3,0,0,1,8.1,24H52.9A78.3,78.3,0,0,1,61,52Zm-8,48H203a75.9,75.9,0,0,1-150,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M216,88A88,88,0,0,1,40,88Z" opacity="0.2" />
          <path d="M224,88a96.2,96.2,0,0,0-15.5-52.4,8.2,8.2,0,0,0-6.7-3.6H54.2a8.2,8.2,0,0,0-6.7,3.6A96.1,96.1,0,0,0,120,183.7V216H88a8,8,0,0,0,0,16h80a8,8,0,0,0,0-16H136V183.7A96.2,96.2,0,0,0,224,88ZM58.7,48H197.3a78.8,78.8,0,0,1,10.3,32H48.4A78.8,78.8,0,0,1,58.7,48ZM48.4,96H207.6A80,80,0,0,1,48.4,96Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M224,88a96.2,96.2,0,0,0-15.5-52.4,8.2,8.2,0,0,0-6.7-3.6H54.2a8.2,8.2,0,0,0-6.7,3.6A96.1,96.1,0,0,0,120,183.7V216H88a8,8,0,0,0,0,16h80a8,8,0,0,0,0-16H136V183.7A96.2,96.2,0,0,0,224,88ZM48.4,80A78.8,78.8,0,0,1,58.7,48H197.3a78.8,78.8,0,0,1,10.3,32Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M222,88a94.2,94.2,0,0,0-15.2-51.3,6,6,0,0,0-5-2.7H54.2a6,6,0,0,0-5,2.7A94,94,0,0,0,122,181.8V218H88a6,6,0,0,0,0,12h80a6,6,0,0,0,0-12H134V181.8A94.2,94.2,0,0,0,222,88ZM57.6,46H198.4a81,81,0,0,1,11.4,36H46.2A81,81,0,0,1,57.6,46ZM46.2,94H209.8A82,82,0,0,1,46.2,94Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M220,88a91.1,91.1,0,0,0-14.9-50.2,4,4,0,0,0-3.3-1.8H54.2a4,4,0,0,0-3.3,1.8A91.1,91.1,0,0,0,36,88a92.1,92.1,0,0,0,88,91.9V220H88a4,4,0,0,0,0,8h80a4,4,0,0,0,0-8H132V179.9A92.1,92.1,0,0,0,220,88ZM56.4,44H199.6a84.5,84.5,0,0,1,12.3,40H44.1A84.5,84.5,0,0,1,56.4,44ZM44.1,92H211.9A84,84,0,0,1,44.1,92Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M224,88a96.2,96.2,0,0,0-15.5-52.4,8.2,8.2,0,0,0-6.7-3.6H54.2a8.2,8.2,0,0,0-6.7,3.6A96.1,96.1,0,0,0,120,183.7V216H88a8,8,0,0,0,0,16h80a8,8,0,0,0,0-16H136V183.7A96.2,96.2,0,0,0,224,88ZM58.7,48H197.3a78.8,78.8,0,0,1,10.3,32H48.4A78.8,78.8,0,0,1,58.7,48ZM48.4,96H207.6A80,80,0,0,1,48.4,96Z" />
        </>
      )}
    </svg>
  );
}

export function PhBriefcaseMetal(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216,60H180V48a28.1,28.1,0,0,0-28-28H104A28.1,28.1,0,0,0,76,48V60H40A20.1,20.1,0,0,0,20,80V208a20.1,20.1,0,0,0,20,20H216a20.1,20.1,0,0,0,20-20V80A20.1,20.1,0,0,0,216,60ZM44,124H212v40H44Zm56-76a4,4,0,0,1,4-4h48a4,4,0,0,1,4,4V60H100ZM212,84v16H44V84ZM44,204V188H212v16Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M224,80v32H32V80a8,8,0,0,1,8-8H216A8,8,0,0,1,224,80ZM32,208a8,8,0,0,0,8,8H216a8,8,0,0,0,8-8V176H32Z"
            opacity="0.2"
          />
          <path d="M216,64H176V56a24.1,24.1,0,0,0-24-24H104A24.1,24.1,0,0,0,80,56v8H40A16,16,0,0,0,24,80V208a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V80A16,16,0,0,0,216,64ZM40,120H216v48H40ZM96,56a8,8,0,0,1,8-8h48a8,8,0,0,1,8,8v8H96ZM216,80v24H40V80Zm0,128H40V184H216v24Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M28,120H228a4,4,0,0,1,4,4v40a4,4,0,0,1-4,4H28a4,4,0,0,1-4-4V124A4,4,0,0,1,28,120Zm-4,88a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V188a4,4,0,0,0-4-4H28a4,4,0,0,0-4,4ZM232,80v20a4,4,0,0,1-4,4H28a4,4,0,0,1-4-4V80A16,16,0,0,1,40,64H80V56a23.9,23.9,0,0,1,24-24h48a23.9,23.9,0,0,1,24,24v8h40A16,16,0,0,1,232,80ZM160,56a8,8,0,0,0-8-8H104a8,8,0,0,0-8,8v8h64Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M216,66H174V56a22.1,22.1,0,0,0-22-22H104A22.1,22.1,0,0,0,82,56V66H40A14,14,0,0,0,26,80V208a14,14,0,0,0,14,14H216a14,14,0,0,0,14-14V80A14,14,0,0,0,216,66ZM38,118H218v52H38ZM94,56a10,10,0,0,1,10-10h48a10,10,0,0,1,10,10V66H94ZM40,78H216a2,2,0,0,1,2,2v26H38V80A2,2,0,0,1,40,78ZM216,210H40a2,2,0,0,1-2-2V182H218v26A2,2,0,0,1,216,210Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M216,68H172V56a20.1,20.1,0,0,0-20-20H104A20.1,20.1,0,0,0,84,56V68H40A12,12,0,0,0,28,80V208a12,12,0,0,0,12,12H216a12,12,0,0,0,12-12V80A12,12,0,0,0,216,68ZM36,116H220v56H36ZM92,56a12,12,0,0,1,12-12h48a12,12,0,0,1,12,12V68H92ZM40,76H216a4,4,0,0,1,4,4v28H36V80A4,4,0,0,1,40,76ZM216,212H40a4,4,0,0,1-4-4V180H220v28A4,4,0,0,1,216,212Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,64H176V56a24.1,24.1,0,0,0-24-24H104A24.1,24.1,0,0,0,80,56v8H40A16,16,0,0,0,24,80V208a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V80A16,16,0,0,0,216,64ZM40,120H216v48H40ZM96,56a8,8,0,0,1,8-8h48a8,8,0,0,1,8,8v8H96ZM216,80v24H40V80Zm0,128H40V184H216v24Z" />
        </>
      )}
    </svg>
  );
}

export function PhBriefcase(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216,56H180V48a28.1,28.1,0,0,0-28-28H104A28.1,28.1,0,0,0,76,48v8H40A20.1,20.1,0,0,0,20,76V204a20.1,20.1,0,0,0,20,20H216a20.1,20.1,0,0,0,20-20V76A20.1,20.1,0,0,0,216,56ZM100,48a4,4,0,0,1,4-4h48a4,4,0,0,1,4,4v8H100ZM212,80v35.2A180.1,180.1,0,0,1,128,136a180.4,180.4,0,0,1-84-20.8V80ZM44,200V141.9A203.7,203.7,0,0,0,128,160a203.7,203.7,0,0,0,84-18.1V200Zm60-88a12,12,0,0,1,12-12h24a12,12,0,0,1,0,24H116A12,12,0,0,1,104,112Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M128,152a190.3,190.3,0,0,1-96-25.7V208a8,8,0,0,0,8,8H216a8,8,0,0,0,8-8V126.3A191.3,191.3,0,0,1,128,152Z" />
          </g>
          <path d="M216,64H176V56a24.1,24.1,0,0,0-24-24H104A24.1,24.1,0,0,0,80,56v8H40A16,16,0,0,0,24,80V208a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V80A16,16,0,0,0,216,64ZM96,56a8,8,0,0,1,8-8h48a8,8,0,0,1,8,8v8H96ZM216,80v41.6A183.6,183.6,0,0,1,128,144a183.6,183.6,0,0,1-88-22.4V80Zm0,128H40V139.6A199.7,199.7,0,0,0,128,160a199.4,199.4,0,0,0,88-20.4V208ZM108,120a8,8,0,0,1,8-8h24a8,8,0,0,1,0,16H116A8,8,0,0,1,108,120Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,64H176V56a24.1,24.1,0,0,0-24-24H104A24.1,24.1,0,0,0,80,56v8H40A16,16,0,0,0,24,80V208a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V80A16,16,0,0,0,216,64ZM96,56a8,8,0,0,1,8-8h48a8,8,0,0,1,8,8v8H96ZM216,80v41.6A183.6,183.6,0,0,1,128,144a183.6,183.6,0,0,1-88-22.4V80ZM108,120a8,8,0,0,1,8-8h24a8,8,0,0,1,0,16H116A8,8,0,0,1,108,120Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M216,66H174V56a22.1,22.1,0,0,0-22-22H104A22.1,22.1,0,0,0,82,56V66H40A14,14,0,0,0,26,80V208a14,14,0,0,0,14,14H216a14,14,0,0,0,14-14V80A14,14,0,0,0,216,66ZM94,56a10,10,0,0,1,10-10h48a10,10,0,0,1,10,10V66H94ZM40,78H216a2,2,0,0,1,2,2v42.8A185.6,185.6,0,0,1,128,146a185.9,185.9,0,0,1-90-23.2V80A2,2,0,0,1,40,78ZM216,210H40a2,2,0,0,1-2-2V136.4A198.7,198.7,0,0,0,128,158a198.7,198.7,0,0,0,90-21.6V208A2,2,0,0,1,216,210ZM110,120a6,6,0,0,1,6-6h24a6,6,0,0,1,0,12H116A6,6,0,0,1,110,120Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M216,68H172V56a20.1,20.1,0,0,0-20-20H104A20.1,20.1,0,0,0,84,56V68H40A12,12,0,0,0,28,80V208a12,12,0,0,0,12,12H216a12,12,0,0,0,12-12V80A12,12,0,0,0,216,68ZM92,56a12,12,0,0,1,12-12h48a12,12,0,0,1,12,12V68H92ZM40,76H216a4,4,0,0,1,4,4v44a188.2,188.2,0,0,1-92,24,188.2,188.2,0,0,1-92-24V80A4,4,0,0,1,40,76ZM216,212H40a4,4,0,0,1-4-4V133.1A195.9,195.9,0,0,0,128,156a195.9,195.9,0,0,0,92-22.9V208A4,4,0,0,1,216,212ZM112,120a4,4,0,0,1,4-4h24a4,4,0,0,1,0,8H116A4,4,0,0,1,112,120Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,64H176V56a24.1,24.1,0,0,0-24-24H104A24.1,24.1,0,0,0,80,56v8H40A16,16,0,0,0,24,80V208a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V80A16,16,0,0,0,216,64ZM96,56a8,8,0,0,1,8-8h48a8,8,0,0,1,8,8v8H96ZM216,80v41.6A183.6,183.6,0,0,1,128,144a183.6,183.6,0,0,1-88-22.4V80Zm0,128H40V139.6A199.7,199.7,0,0,0,128,160a199.4,199.4,0,0,0,88-20.4V208ZM108,120a8,8,0,0,1,8-8h24a8,8,0,0,1,0,16H116A8,8,0,0,1,108,120Z" />
        </>
      )}
    </svg>
  );
}

export function PhBroadcast(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,84a44,44,0,1,0,44,44A44,44,0,0,0,128,84Zm0,64a20,20,0,1,1,20-20A20.1,20.1,0,0,1,128,148ZM85.6,170.4a12,12,0,0,1,0,17,12.2,12.2,0,0,1-8.5,3.5,12,12,0,0,1-8.5-3.5,83.9,83.9,0,0,1,0-118.8,12,12,0,0,1,17,17,59.7,59.7,0,0,0,0,84.8Zm119.8-9.7a83.3,83.3,0,0,1-18,26.7,12,12,0,0,1-8.5,3.5,12.2,12.2,0,0,1-8.5-3.5,12,12,0,0,1,0-17,59.7,59.7,0,0,0,0-84.8,12,12,0,0,1,17-17,84.1,84.1,0,0,1,18,92.1Zm-148.1,38a12,12,0,0,1,0,17,12.1,12.1,0,0,1-17,0,124.1,124.1,0,0,1,0-175.4,12,12,0,0,1,17,17A99,99,0,0,0,35.9,89.1a99.7,99.7,0,0,0,0,77.8A99,99,0,0,0,57.3,198.7ZM252,128a124.2,124.2,0,0,1-36.3,87.7,12.1,12.1,0,0,1-17,0,12,12,0,0,1,0-17,99,99,0,0,0,21.4-31.8,99.7,99.7,0,0,0,0-77.8,99,99,0,0,0-21.4-31.8,12,12,0,0,1,17-17A124.2,124.2,0,0,1,252,128Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="128" cy="128" r="32" />
          </g>
          <path d="M128,88a40,40,0,1,0,40,40A40,40,0,0,0,128,88Zm0,64a24,24,0,1,1,24-24A24.1,24.1,0,0,1,128,152Z" />
          <path d="M82.7,82.7a7.9,7.9,0,0,0,0-11.3,8,8,0,0,0-11.3,0A80,80,0,0,0,54.3,96.9a79.9,79.9,0,0,0,0,62.2,80,80,0,0,0,17.1,25.5,8.3,8.3,0,0,0,5.7,2.3,8,8,0,0,0,5.6-2.3,7.9,7.9,0,0,0,0-11.3A65.4,65.4,0,0,1,69,152.9a64.5,64.5,0,0,1,0-49.8A65.4,65.4,0,0,1,82.7,82.7Z" />
          <path d="M208,128a78.6,78.6,0,0,0-6.3-31.1,80,80,0,0,0-17.1-25.5,8,8,0,0,0-11.3,0,7.9,7.9,0,0,0,0,11.3A65.4,65.4,0,0,1,187,103.1a64.5,64.5,0,0,1,0,49.8,65.4,65.4,0,0,1-13.7,20.4,7.9,7.9,0,0,0,0,11.3,8,8,0,0,0,5.6,2.3,8.3,8.3,0,0,0,5.7-2.3,80,80,0,0,0,17.1-25.5A78.6,78.6,0,0,0,208,128Z" />
          <path d="M32.2,168.5a104.1,104.1,0,0,1,0-81,101.3,101.3,0,0,1,22.3-33A8.1,8.1,0,0,0,43.1,43.1,121.8,121.8,0,0,0,17.4,81.3a120.7,120.7,0,0,0,0,93.4,121.8,121.8,0,0,0,25.7,38.2,8.5,8.5,0,0,0,5.7,2.3,8.3,8.3,0,0,0,5.7-2.3,8.1,8.1,0,0,0,0-11.4A101.3,101.3,0,0,1,32.2,168.5Z" />
          <path d="M238.6,81.3a121.8,121.8,0,0,0-25.7-38.2,8.1,8.1,0,1,0-11.4,11.4A103.5,103.5,0,0,1,232,128a103.5,103.5,0,0,1-30.5,73.5,8.1,8.1,0,0,0,0,11.4,8.3,8.3,0,0,0,5.7,2.3,8.5,8.5,0,0,0,5.7-2.3,121.8,121.8,0,0,0,25.7-38.2,120.7,120.7,0,0,0,0-93.4Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M168,128a40,40,0,1,1-40-40A40,40,0,0,1,168,128ZM82.7,82.7a7.9,7.9,0,0,0,0-11.3,8,8,0,0,0-11.3,0A80,80,0,0,0,54.3,96.9a79.9,79.9,0,0,0,0,62.2,80,80,0,0,0,17.1,25.5,8.3,8.3,0,0,0,5.7,2.3,8,8,0,0,0,5.6-2.3,7.9,7.9,0,0,0,0-11.3A65.4,65.4,0,0,1,69,152.9a64.5,64.5,0,0,1,0-49.8A65.4,65.4,0,0,1,82.7,82.7ZM208,128a78.6,78.6,0,0,0-6.3-31.1,80,80,0,0,0-17.1-25.5,8,8,0,0,0-11.3,0,7.9,7.9,0,0,0,0,11.3A65.4,65.4,0,0,1,187,103.1a64.5,64.5,0,0,1,0,49.8,65.4,65.4,0,0,1-13.7,20.4,7.9,7.9,0,0,0,0,11.3,8,8,0,0,0,5.6,2.3,8.3,8.3,0,0,0,5.7-2.3,80,80,0,0,0,17.1-25.5A78.6,78.6,0,0,0,208,128ZM32.2,168.5a104.1,104.1,0,0,1,0-81,101.3,101.3,0,0,1,22.3-33A8.1,8.1,0,0,0,43.1,43.1,121.8,121.8,0,0,0,17.4,81.3a120.7,120.7,0,0,0,0,93.4,121.8,121.8,0,0,0,25.7,38.2,8.5,8.5,0,0,0,5.7,2.3,8.3,8.3,0,0,0,5.7-2.3,8.1,8.1,0,0,0,0-11.4A101.3,101.3,0,0,1,32.2,168.5ZM238.6,81.3a121.8,121.8,0,0,0-25.7-38.2,8.1,8.1,0,1,0-11.4,11.4A103.5,103.5,0,0,1,232,128a103.5,103.5,0,0,1-30.5,73.5,8.1,8.1,0,0,0,0,11.4,8.3,8.3,0,0,0,5.7,2.3,8.5,8.5,0,0,0,5.7-2.3,121.8,121.8,0,0,0,25.7-38.2,120.7,120.7,0,0,0,0-93.4Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,90a38,38,0,1,0,38,38A38,38,0,0,0,128,90Zm0,64a26,26,0,1,1,26-26A26.1,26.1,0,0,1,128,154ZM67.2,102.3a66.1,66.1,0,0,0,0,51.4,67.5,67.5,0,0,0,14.1,21,5.9,5.9,0,0,1,0,8.5,6.1,6.1,0,0,1-4.2,1.7,6.5,6.5,0,0,1-4.3-1.7,80.3,80.3,0,0,1-16.7-24.8,78.8,78.8,0,0,1,0-60.8A80.3,80.3,0,0,1,72.8,72.8a6.1,6.1,0,0,1,8.5,0,5.9,5.9,0,0,1,0,8.5A67.5,67.5,0,0,0,67.2,102.3ZM206,128a79.3,79.3,0,0,1-6.1,30.4,80.3,80.3,0,0,1-16.7,24.8,6.5,6.5,0,0,1-4.3,1.7,6.1,6.1,0,0,1-4.2-1.7,5.9,5.9,0,0,1,0-8.5,67.5,67.5,0,0,0,14.1-21,66.1,66.1,0,0,0,0-51.4,67.5,67.5,0,0,0-14.1-21,5.9,5.9,0,0,1,0-8.5,6.1,6.1,0,0,1,8.5,0,80.3,80.3,0,0,1,16.7,24.8A79.3,79.3,0,0,1,206,128ZM53,203a5.9,5.9,0,1,1-8.4,8.4,115,115,0,0,1-25.3-37.5,117.9,117.9,0,0,1,0-91.8A115,115,0,0,1,44.6,44.6,5.9,5.9,0,0,1,53,53,108.2,108.2,0,0,0,30.3,86.7a106.9,106.9,0,0,0,0,82.6A108.2,108.2,0,0,0,53,203Zm193-75a116.2,116.2,0,0,1-9.3,45.9,115,115,0,0,1-25.3,37.5A5.9,5.9,0,0,1,203,203a108.2,108.2,0,0,0,22.7-33.7,106.9,106.9,0,0,0,0-82.6A108.2,108.2,0,0,0,203,53a5.9,5.9,0,1,1,8.4-8.4,115,115,0,0,1,25.3,37.5A116.2,116.2,0,0,1,246,128Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,92a36,36,0,1,0,36,36A36,36,0,0,0,128,92Zm0,64a28,28,0,1,1,28-28A28.1,28.1,0,0,1,128,156ZM65.3,101.5a68.9,68.9,0,0,0,0,53,70.4,70.4,0,0,0,14.6,21.6,3.9,3.9,0,0,1,0,5.6,3.9,3.9,0,0,1-2.8,1.2,3.8,3.8,0,0,1-2.8-1.2A75.9,75.9,0,0,1,58,98.4,74.4,74.4,0,0,1,74.3,74.3a4,4,0,0,1,5.6,5.6A70.4,70.4,0,0,0,65.3,101.5ZM204,128a75.6,75.6,0,0,1-22.3,53.7,3.8,3.8,0,0,1-2.8,1.2,3.9,3.9,0,0,1-2.8-1.2,3.9,3.9,0,0,1,0-5.6,70.4,70.4,0,0,0,14.6-21.6,68.9,68.9,0,0,0,0-53,70.4,70.4,0,0,0-14.6-21.6,4,4,0,1,1,5.6-5.6A75.6,75.6,0,0,1,204,128ZM51.6,204.4a3.9,3.9,0,0,1,0,5.6,3.8,3.8,0,0,1-2.8,1.2A3.9,3.9,0,0,1,46,210,115.6,115.6,0,0,1,46,46a4,4,0,0,1,5.6,5.6,108.2,108.2,0,0,0,0,152.8ZM244,128a115,115,0,0,1-34,82,3.9,3.9,0,0,1-2.8,1.2,3.8,3.8,0,0,1-2.8-1.2,3.9,3.9,0,0,1,0-5.6,108.2,108.2,0,0,0,0-152.8A4,4,0,1,1,210,46a115,115,0,0,1,34,82Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,88a40,40,0,1,0,40,40A40,40,0,0,0,128,88Zm0,64a24,24,0,1,1,24-24A24.1,24.1,0,0,1,128,152ZM69,103.1a64.5,64.5,0,0,0,0,49.8,65.4,65.4,0,0,0,13.7,20.4,7.9,7.9,0,0,1,0,11.3,8,8,0,0,1-5.6,2.3,8.3,8.3,0,0,1-5.7-2.3,80,80,0,0,1-17.1-25.5,79.9,79.9,0,0,1,0-62.2A80,80,0,0,1,71.4,71.4a8,8,0,0,1,11.3,0,7.9,7.9,0,0,1,0,11.3A65.4,65.4,0,0,0,69,103.1Zm132.7,56a80,80,0,0,1-17.1,25.5,8.3,8.3,0,0,1-5.7,2.3,8,8,0,0,1-5.6-2.3,7.9,7.9,0,0,1,0-11.3A65.4,65.4,0,0,0,187,152.9a64.5,64.5,0,0,0,0-49.8,65.4,65.4,0,0,0-13.7-20.4,7.9,7.9,0,0,1,0-11.3,8,8,0,0,1,11.3,0,80,80,0,0,1,17.1,25.5,79.9,79.9,0,0,1,0,62.2ZM54.5,201.5a8.1,8.1,0,0,1,0,11.4,8.3,8.3,0,0,1-5.7,2.3,8.5,8.5,0,0,1-5.7-2.3,121.8,121.8,0,0,1-25.7-38.2,120.7,120.7,0,0,1,0-93.4A121.8,121.8,0,0,1,43.1,43.1,8.1,8.1,0,0,1,54.5,54.5,103.5,103.5,0,0,0,24,128a103.5,103.5,0,0,0,30.5,73.5ZM248,128a120.2,120.2,0,0,1-9.4,46.7,121.8,121.8,0,0,1-25.7,38.2,8.5,8.5,0,0,1-5.7,2.3,8.3,8.3,0,0,1-5.7-2.3,8.1,8.1,0,0,1,0-11.4A103.5,103.5,0,0,0,232,128a103.5,103.5,0,0,0-30.5-73.5,8.1,8.1,0,1,1,11.4-11.4,121.8,121.8,0,0,1,25.7,38.2A120.2,120.2,0,0,1,248,128Z" />
        </>
      )}
    </svg>
  );
}

export function PhBrowser(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216,36H40A20.1,20.1,0,0,0,20,56V200a20.1,20.1,0,0,0,20,20H216a20.1,20.1,0,0,0,20-20V56A20.1,20.1,0,0,0,216,36Zm-4,24V84H44V60ZM44,196V108H212v88Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M32,96H224V56a8,8,0,0,0-8-8H40a8,8,0,0,0-8,8Z" />
          </g>
          <path d="M216,40H40A16,16,0,0,0,24,56V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40Zm0,16V88H40V56Zm0,144H40V104H216v96Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,40H40A16,16,0,0,0,24,56V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40Zm0,16V88H40V56Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M216,42H40A14,14,0,0,0,26,56V200a14,14,0,0,0,14,14H216a14,14,0,0,0,14-14V56A14,14,0,0,0,216,42ZM40,54H216a2,2,0,0,1,2,2V90H38V56A2,2,0,0,1,40,54ZM216,202H40a2,2,0,0,1-2-2V102H218v98A2,2,0,0,1,216,202Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M216,44H40A12,12,0,0,0,28,56V95.9h0V200a12,12,0,0,0,12,12H216a12,12,0,0,0,12-12V56A12,12,0,0,0,216,44ZM40,52H216a4,4,0,0,1,4,4V92H36V56A4,4,0,0,1,40,52ZM216,204H40a4,4,0,0,1-4-4V100H220V200A4,4,0,0,1,216,204Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,40H40A16,16,0,0,0,24,56V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40Zm0,16V88H40V56Zm0,144H40V104H216v96Z" />
        </>
      )}
    </svg>
  );
}

export function PhBrowsers(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M220,32H76A20.1,20.1,0,0,0,56,52V72H36A20.1,20.1,0,0,0,16,92V204a20.1,20.1,0,0,0,20,20H180a20.1,20.1,0,0,0,20-20V184h20a20.1,20.1,0,0,0,20-20V52A20.1,20.1,0,0,0,220,32ZM176,96v16H40V96Zm0,104H40V136H176Zm40-40H200V92a20.1,20.1,0,0,0-20-20H80V56H216Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M216,48H72a8,8,0,0,0-8,8V80H184a8,8,0,0,1,8,8v88h24a8,8,0,0,0,8-8V56A8,8,0,0,0,216,48Z" />
          </g>
          <path d="M216,40H72A16,16,0,0,0,56,56V72H40A16,16,0,0,0,24,88V200a16,16,0,0,0,16,16H184a16,16,0,0,0,16-16V184h16a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40ZM184,88v16H40V88Zm0,112H40V120H184v80Zm32-32H200V88a16,16,0,0,0-16-16H72V56H216Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,40H72A16,16,0,0,0,56,56V72H40A16,16,0,0,0,24,88V200a16,16,0,0,0,16,16H184a16,16,0,0,0,16-16V184h16a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40ZM184,88v16H40V88Zm32,80H200V88a16,16,0,0,0-16-16H72V56H216Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M216,42H72A14,14,0,0,0,58,56V74H40A14,14,0,0,0,26,88V200a14,14,0,0,0,14,14H184a14,14,0,0,0,14-14V182h18a14,14,0,0,0,14-14V56A14,14,0,0,0,216,42ZM40,86H184a2,2,0,0,1,2,2v18H38V88A2,2,0,0,1,40,86ZM186,200a2,2,0,0,1-2,2H40a2,2,0,0,1-2-2V118H186Zm32-32a2,2,0,0,1-2,2H198V88a14,14,0,0,0-14-14H70V56a2,2,0,0,1,2-2H216a2,2,0,0,1,2,2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M216,44H72A12,12,0,0,0,60,56V76H40A12,12,0,0,0,28,88v23.9h0V200a12,12,0,0,0,12,12H184a12,12,0,0,0,12-12V180h20a12,12,0,0,0,12-12V56A12,12,0,0,0,216,44ZM40,84H184a4,4,0,0,1,4,4v20H36V88A4,4,0,0,1,40,84ZM188,200a4,4,0,0,1-4,4H40a4,4,0,0,1-4-4V116H188Zm32-32a4,4,0,0,1-4,4H196V88a12,12,0,0,0-12-12H68V56a4,4,0,0,1,4-4H216a4,4,0,0,1,4,4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,40H72A16,16,0,0,0,56,56V72H40A16,16,0,0,0,24,88V200a16,16,0,0,0,16,16H184a16,16,0,0,0,16-16V184h16a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40ZM184,88v16H40V88Zm0,112H40V120H184v80Zm32-32H200V88a16,16,0,0,0-16-16H72V56H216Z" />
        </>
      )}
    </svg>
  );
}

export function PhBugBeetle(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M224,156a12,12,0,0,0,0-24H212V116h12a12,12,0,0,0,0-24H211.1a83.8,83.8,0,0,0-18-41l15.1-14.3a12,12,0,0,0,.4-17,11.9,11.9,0,0,0-16.9-.4L175.4,34.7a83.7,83.7,0,0,0-94.9.1L64.2,19.3A12,12,0,1,0,47.7,36.7L62.8,51.1A82.9,82.9,0,0,0,44.9,92H32a12,12,0,0,0,0,24H44v16H32a12,12,0,0,0,0,24H44.1a93.5,93.5,0,0,0,2.3,16H32a12,12,0,0,0,0,24H56.5a83.9,83.9,0,0,0,143,0H224a12,12,0,0,0,0-24H209.6a93.5,93.5,0,0,0,2.3-16ZM128,44a60.1,60.1,0,0,1,58.8,48H69.2A60.1,60.1,0,0,1,128,44Zm12,166.8V144a12,12,0,0,0-24,0v66.8A60.1,60.1,0,0,1,68,152V116H188v36A60.1,60.1,0,0,1,140,210.8Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M200,104v48a72,72,0,0,1-72,72h0a72,72,0,0,1-72-72V104Z"
            opacity="0.2"
          />
          <path d="M208,144h16a8,8,0,0,0,0-16H208V112h16a8,8,0,0,0,0-16H207.6a79.6,79.6,0,0,0-20-45.3l17.8-16.9a7.9,7.9,0,0,0,.3-11.3,8,8,0,0,0-11.3-.3L175.8,39.9a79.8,79.8,0,0,0-95.7.1L61.4,22.2a8,8,0,0,0-11,11.6l17.9,17A79.9,79.9,0,0,0,48.4,96H32a8,8,0,0,0,0,16H48v16H32a8,8,0,0,0,0,16H48v8a70.3,70.3,0,0,0,.4,8H32a8,8,0,0,0,0,16H51.7a80,80,0,0,0,152.6,0H224a8,8,0,0,0,0-16H207.6a70.3,70.3,0,0,0,.4-8ZM128,40a64.1,64.1,0,0,1,63.5,56H64.5A64.1,64.1,0,0,1,128,40Zm8,175.5V136a8,8,0,0,0-16,0v79.5A64.1,64.1,0,0,1,64,152V112H192v40A64.1,64.1,0,0,1,136,215.5Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M48,96v16H32a8,8,0,0,1,0-16ZM68.3,50.8A79.9,79.9,0,0,0,48.4,96H207.6a79.6,79.6,0,0,0-20-45.3l17.8-16.9a7.9,7.9,0,0,0,.3-11.3,8,8,0,0,0-11.3-.3L175.8,39.9a79.8,79.8,0,0,0-95.7.1L61.4,22.2a8,8,0,0,0-11,11.6ZM232,104a8,8,0,0,0-8-8H208v16h16A8,8,0,0,0,232,104Zm-24,48a70.3,70.3,0,0,1-.4,8H224a8,8,0,0,1,0,16H204.3a80,80,0,0,1-152.6,0H32a8,8,0,0,1,0-16H48.4a70.3,70.3,0,0,1-.4-8v-8H32a8,8,0,0,1,0-16H48V112H208v16h16a8,8,0,0,1,0,16H208Zm-72-16a8,8,0,0,0-16,0v64a8,8,0,0,0,16,0Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M206,142h18a6,6,0,0,0,0-12H206V110h18a6,6,0,0,0,0-12H205.7a77.1,77.1,0,0,0-20.9-47.3l19.3-18.4a5.9,5.9,0,0,0,.2-8.4,6,6,0,0,0-8.5-.2L175.9,42.5a77.7,77.7,0,0,0-95.9.1L60.1,23.7a6,6,0,0,0-8.5.2,5.9,5.9,0,0,0,.2,8.4L71.1,50.7A77.2,77.2,0,0,0,50.3,98H32a6,6,0,0,0,0,12H50v20H32a6,6,0,0,0,0,12H50v10a66.3,66.3,0,0,0,.7,10H32a6,6,0,0,0,0,12H53.2a78,78,0,0,0,149.6,0H224a6,6,0,0,0,0-12H205.3a66.3,66.3,0,0,0,.7-10ZM128,38a66,66,0,0,1,65.7,60H62.3A66,66,0,0,1,128,38Zm6,179.7V136a6,6,0,0,0-12,0v81.7A66,66,0,0,1,62,152V110H194v42A66,66,0,0,1,134,217.7Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M204,140h20a4,4,0,0,0,0-8H204V108h20a4,4,0,0,0,0-8H203.9A75.7,75.7,0,0,0,182,50.6l20.7-19.7a4,4,0,0,0-5.5-5.8L176.1,45.2a75.9,75.9,0,0,0-96.2,0L58.7,25.1a4,4,0,1,0-5.5,5.8L73.9,50.7A75.8,75.8,0,0,0,52.1,100H32a4,4,0,0,0,0,8H52v24H32a4,4,0,0,0,0,8H52v12a67.8,67.8,0,0,0,1,12H32a4,4,0,0,0,0,8H54.7a76,76,0,0,0,146.6,0H224a4,4,0,0,0,0-8H203a67.8,67.8,0,0,0,1-12ZM128,36a68.1,68.1,0,0,1,67.9,64H60.1A68.1,68.1,0,0,1,128,36Zm4,183.9V136a4,4,0,0,0-8,0v83.9A68.1,68.1,0,0,1,60,152V108H196v44A68.1,68.1,0,0,1,132,219.9Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M208,144h16a8,8,0,0,0,0-16H208V112h16a8,8,0,0,0,0-16H207.6a79.6,79.6,0,0,0-20-45.3l17.8-16.9a7.9,7.9,0,0,0,.3-11.3,8,8,0,0,0-11.3-.3L175.8,39.9a79.8,79.8,0,0,0-95.7.1L61.4,22.2a8,8,0,0,0-11,11.6l17.9,17A79.9,79.9,0,0,0,48.4,96H32a8,8,0,0,0,0,16H48v16H32a8,8,0,0,0,0,16H48v8a70.3,70.3,0,0,0,.4,8H32a8,8,0,0,0,0,16H51.7a80,80,0,0,0,152.6,0H224a8,8,0,0,0,0-16H207.6a70.3,70.3,0,0,0,.4-8ZM128,40a64.1,64.1,0,0,1,63.5,56H64.5A64.1,64.1,0,0,1,128,40Zm8,175.5V136a8,8,0,0,0-16,0v79.5A64.1,64.1,0,0,1,64,152V112H192v40A64.1,64.1,0,0,1,136,215.5Z" />
        </>
      )}
    </svg>
  );
}

export function PhBugDroid(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M140.1,84a16,16,0,1,1,16,16A16,16,0,0,1,140.1,84Zm-40,16a16,16,0,1,0-16-16A16,16,0,0,0,100.1,100ZM220,108v40a92,92,0,0,1-184,0V108A91.6,91.6,0,0,1,59,47.2L47.8,36.8A12,12,0,0,1,64.2,19.2l13,12.1a92,92,0,0,1,101.6,0l13-12.1a12,12,0,1,1,16.4,17.6L197,47.2A91.6,91.6,0,0,1,220,108ZM128,40a68.1,68.1,0,0,0-68,68v8H196v-8A68.1,68.1,0,0,0,128,40Zm68,108v-8H60v8a68,68,0,0,0,136,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M48,128H208v20a80,80,0,0,1-160,0Z" />
          </g>
          <path d="M191.4,47l14.1-13.1a8.1,8.1,0,1,0-11-11.8L179.2,36.5a87.7,87.7,0,0,0-102.4,0L61.5,22.1a8.1,8.1,0,0,0-11,11.8L64.6,47A87.7,87.7,0,0,0,40,108v40a88,88,0,0,0,176,0V108A87.7,87.7,0,0,0,191.4,47ZM128,36a72.1,72.1,0,0,1,72,72v12H56V108A72.1,72.1,0,0,1,128,36Zm0,184a72.1,72.1,0,0,1-72-72V136H200v12A72.1,72.1,0,0,1,128,220ZM144,92a12,12,0,1,1,12,12A12,12,0,0,1,144,92ZM88,92a12,12,0,1,1,12,12A12,12,0,0,1,88,92Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M191.4,47l14.1-13.1a8.1,8.1,0,1,0-11-11.8L179.2,36.5a87.7,87.7,0,0,0-102.4,0L61.5,22.1a8.1,8.1,0,0,0-11,11.8L64.6,47A87.7,87.7,0,0,0,40,108v40a88,88,0,0,0,176,0V108A87.7,87.7,0,0,0,191.4,47ZM128,36a72.1,72.1,0,0,1,72,72v12H56V108A72.1,72.1,0,0,1,128,36Zm16,56a12,12,0,1,1,12,12A12,12,0,0,1,144,92ZM88,92a12,12,0,1,1,12,12A12,12,0,0,1,88,92Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M146.1,84a10,10,0,1,1,10,10A10,10,0,0,1,146.1,84Zm-46,10a10,10,0,1,0-10-10A10,10,0,0,0,100.1,94ZM214,108v40a86,86,0,0,1-172,0V108A85.9,85.9,0,0,1,67.5,46.9L51.9,32.4a6,6,0,1,1,8.2-8.8L76.7,39.1a85.5,85.5,0,0,1,102.6,0l16.6-15.5a6,6,0,0,1,8.2,8.8L188.5,46.9A85.9,85.9,0,0,1,214,108ZM54,108v14H202V108a74,74,0,0,0-148,0Zm148,40V134H54v14a74,74,0,0,0,148,0Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M148.1,84a8,8,0,1,1,8,8A8,8,0,0,1,148.1,84Zm-48,8a8,8,0,1,0-8-8A8,8,0,0,0,100.1,92ZM212,108v40a84,84,0,0,1-168,0V108A84,84,0,0,1,70.4,46.9l-17.1-16a4,4,0,0,1,5.4-5.8L76.5,41.7a83.8,83.8,0,0,1,103,0l17.8-16.6a4,4,0,1,1,5.4,5.8l-17.1,16A84,84,0,0,1,212,108ZM52,108v16H204V108a76,76,0,0,0-152,0Zm152,40V132H52v16a76,76,0,0,0,152,0Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M191.4,47l14.1-13.1a8.1,8.1,0,1,0-11-11.8L179.2,36.5a87.7,87.7,0,0,0-102.4,0L61.5,22.1a8.1,8.1,0,0,0-11,11.8L64.6,47A87.7,87.7,0,0,0,40,108v40a88,88,0,0,0,176,0V108A87.7,87.7,0,0,0,191.4,47ZM128,36a72.1,72.1,0,0,1,72,72v12H56V108A72.1,72.1,0,0,1,128,36Zm0,184a72.1,72.1,0,0,1-72-72V136H200v12A72.1,72.1,0,0,1,128,220ZM144,92a12,12,0,1,1,12,12A12,12,0,0,1,144,92ZM88,92a12,12,0,1,1,12,12A12,12,0,0,1,88,92Z" />
        </>
      )}
    </svg>
  );
}

export function PhBug(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M140,88a16,16,0,1,1,16,16A16,16,0,0,1,140,88ZM100,72a16,16,0,1,0,16,16A16,16,0,0,0,100,72Zm156,56a12,12,0,0,1-12,12H220v4a92.3,92.3,0,0,1-2.6,21.8l20.5,11.8a12,12,0,0,1-12,20.8l-17.3-10a92,92,0,0,1-161.2,0l-17.3,10a12,12,0,1,1-12-20.8l20.5-11.8A92.3,92.3,0,0,1,36,144v-4H12a12,12,0,0,1,0-24H36v-4a92.3,92.3,0,0,1,2.6-21.8L18.1,78.4a12,12,0,0,1,12-20.8l17.3,10a92,92,0,0,1,161.2,0l17.3-10a12,12,0,0,1,12,20.8L217.4,90.2A92.3,92.3,0,0,1,220,112v4h24A12,12,0,0,1,256,128ZM60,116H196v-4a68,68,0,0,0-136,0Zm56,94.9V140H60v4A68.1,68.1,0,0,0,116,210.9ZM196,140H140v70.9A68.1,68.1,0,0,0,196,144Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M208,128v16a80,80,0,0,1-160,0V128Z" opacity="0.2" />
          <path d="M143.9,92a12,12,0,1,1,12,12A12,12,0,0,1,143.9,92Zm-44-12a12,12,0,1,0,12,12A12,12,0,0,0,99.9,80ZM252,128a8,8,0,0,1-8,8H216v8a88.1,88.1,0,0,1-3.2,23.7l23.1,13.4a8,8,0,0,1-4,14.9,7.6,7.6,0,0,1-4-1.1L207,182.8a88,88,0,0,1-158,0L28.1,194.9a7.6,7.6,0,0,1-4,1.1,8,8,0,0,1-4-14.9l23.1-13.4A88.1,88.1,0,0,1,40,144v-8H12a8,8,0,0,1,0-16H40v-8a88.1,88.1,0,0,1,3.2-23.7L20.1,74.9a8,8,0,0,1-3-10.9,8.1,8.1,0,0,1,11-2.9L49,73.2a88,88,0,0,1,158,0l20.9-12.1a8.1,8.1,0,0,1,11,2.9,8,8,0,0,1-3,10.9L212.8,88.3A88.1,88.1,0,0,1,216,112v8h28A8,8,0,0,1,252,128ZM56,120H200v-8a72,72,0,0,0-144,0Zm64,95.5V136H56v8A72,72,0,0,0,120,215.5ZM200,136H136v79.5A72,72,0,0,0,200,144Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M244,120H216v-8a88.1,88.1,0,0,0-3.2-23.7l23.1-13.4a8,8,0,0,0,3-10.9,8.1,8.1,0,0,0-11-2.9L207,73.2a88,88,0,0,0-158,0L28.1,61.1a8.1,8.1,0,0,0-11,2.9,8,8,0,0,0,3,10.9L43.2,88.3A88.1,88.1,0,0,0,40,112v8H12a8,8,0,0,0,0,16H40v8a88.1,88.1,0,0,0,3.2,23.7L20.1,181.1a8,8,0,0,0,4,14.9,7.6,7.6,0,0,0,4-1.1L49,182.8a88,88,0,0,0,158,0l20.9,12.1a7.6,7.6,0,0,0,4,1.1,8,8,0,0,0,4-14.9l-23.1-13.4A88.1,88.1,0,0,0,216,144v-8h28a8,8,0,0,0,0-16ZM136,208a8,8,0,0,1-16,0V144a8,8,0,0,1,16,0Zm64-88H56v-8a72,72,0,0,1,144,0ZM111.9,92a12,12,0,1,1-12-12A12,12,0,0,1,111.9,92Zm56,0a12,12,0,1,1-12-12A12,12,0,0,1,167.9,92Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M146,92a10,10,0,1,1,10,10A10,10,0,0,1,146,92ZM100,82a10,10,0,1,0,10,10A10,10,0,0,0,100,82Zm150,46a6,6,0,0,1-6,6H214v10a86.6,86.6,0,0,1-3.6,24.6l24.5,14.2a6,6,0,0,1-3,11.2,6.7,6.7,0,0,1-3-.8L206.1,180a86,86,0,0,1-156.2,0L27.1,193.2a6.7,6.7,0,0,1-3,.8,6,6,0,0,1-3-11.2l24.5-14.2A86.6,86.6,0,0,1,42,144V134H12a6,6,0,0,1,0-12H42V112a86.6,86.6,0,0,1,3.6-24.6L21.1,73.2a6,6,0,0,1,6-10.4L49.9,76a86,86,0,0,1,156.2,0l22.8-13.2a6,6,0,0,1,6,10.4L210.4,87.4A86.6,86.6,0,0,1,214,112v10h30A6,6,0,0,1,250,128ZM54,122H202V112a74,74,0,0,0-148,0Zm68,95.7V134H54v10A74,74,0,0,0,122,217.7ZM202,134H134v83.7A74,74,0,0,0,202,144Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M148,92a8,8,0,1,1,8,8A8,8,0,0,1,148,92Zm-48-8a8,8,0,1,0,8,8A8,8,0,0,0,100,84Zm148,44a4,4,0,0,1-4,4H212v12a83.6,83.6,0,0,1-4,25.6l25.9,14.9a4,4,0,0,1-2,7.5,4.9,4.9,0,0,1-2-.5l-24.7-14.3a84.1,84.1,0,0,1-154.4,0L26.1,191.5a4.9,4.9,0,0,1-2,.5,4,4,0,0,1-2-7.5L48,169.6A83.6,83.6,0,0,1,44,144V132H12a4,4,0,0,1,0-8H44V112a83.6,83.6,0,0,1,4-25.6L22.1,71.5a4,4,0,0,1,4-7L50.8,78.8a84.1,84.1,0,0,1,154.4,0l24.7-14.3a4,4,0,1,1,4,7L208,86.4a83.6,83.6,0,0,1,4,25.6v12h32A4,4,0,0,1,248,128ZM52,124H204V112a76,76,0,0,0-152,0Zm72,95.9V132H52v12A76.1,76.1,0,0,0,124,219.9ZM204,132H132v87.9A76.1,76.1,0,0,0,204,144Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M168,92a12,12,0,1,1-12-12A12,12,0,0,1,168,92ZM100,80a12,12,0,1,0,12,12A12,12,0,0,0,100,80Zm152,48a8,8,0,0,1-8,8H216v8a88.1,88.1,0,0,1-3.2,23.7l23.1,13.4a8,8,0,0,1-4,14.9,7.6,7.6,0,0,1-4-1.1L207,182.8a88,88,0,0,1-158,0L28.1,194.9a7.6,7.6,0,0,1-4,1.1,8,8,0,0,1-4-14.9l23.1-13.4A88.1,88.1,0,0,1,40,144v-8H12a8,8,0,0,1,0-16H40v-8a88.1,88.1,0,0,1,3.2-23.7L20.1,74.9a8,8,0,0,1-3-10.9,8.1,8.1,0,0,1,11-2.9L49,73.2a88,88,0,0,1,158,0l20.9-12.1a8.1,8.1,0,0,1,11,2.9,8,8,0,0,1-3,10.9L212.8,88.3A88.1,88.1,0,0,1,216,112v8h28A8,8,0,0,1,252,128Zm-132,8H56v8a72,72,0,0,0,64,71.5Zm80,0H136v79.5A72,72,0,0,0,200,144Zm0-24a72,72,0,0,0-144,0v8H200Z" />
        </>
      )}
    </svg>
  );
}

export function PhBuildings(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M240,204h-4V104a20.1,20.1,0,0,0-20-20H156V40a20.1,20.1,0,0,0-20-20H40A20.1,20.1,0,0,0,20,40V204H16a12,12,0,0,0,0,24H240a12,12,0,0,0,0-24Zm-28-96v96H156V108ZM44,44h88V204H44ZM56,72A12,12,0,0,1,68,60H96a12,12,0,0,1,0,24H68A12,12,0,0,1,56,72Zm64,64a12,12,0,0,1-12,12H80a12,12,0,0,1,0-24h28A12,12,0,0,1,120,136Zm-12,40a12,12,0,0,1-12,12H68a12,12,0,0,1,0-24H96A12,12,0,0,1,108,176Zm92,0a12,12,0,0,1-12,12h-8a12,12,0,0,1,0-24h8A12,12,0,0,1,200,176Zm-32-40a12,12,0,0,1,12-12h8a12,12,0,0,1,0,24h-8A12,12,0,0,1,168,136Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M144,216V40a8,8,0,0,0-8-8H40a8,8,0,0,0-8,8V216" />
          </g>
          <path d="M240,208h-8V104a16,16,0,0,0-16-16H152V40a16,16,0,0,0-16-16H40A16,16,0,0,0,24,40V208H16a8,8,0,0,0,0,16H240a8,8,0,0,0,0-16ZM216,104V208H152V104ZM40,40h96V208H40ZM56,72a8,8,0,0,1,8-8H96a8,8,0,0,1,0,16H64A8,8,0,0,1,56,72Zm64,64a8,8,0,0,1-8,8H80a8,8,0,0,1,0-16h32A8,8,0,0,1,120,136Zm-16,40a8,8,0,0,1-8,8H64a8,8,0,0,1,0-16H96A8,8,0,0,1,104,176Zm96,0a8,8,0,0,1-8,8H176a8,8,0,0,1,0-16h16A8,8,0,0,1,200,176Zm-32-40a8,8,0,0,1,8-8h16a8,8,0,0,1,0,16H176A8,8,0,0,1,168,136Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M240,208h-8V104a16,16,0,0,0-16-16H152V40a16,16,0,0,0-16-16H40A16,16,0,0,0,24,40V208H16a8,8,0,0,0,0,16H240a8,8,0,0,0,0-16ZM120,136a8,8,0,0,1-8,8H80a8,8,0,0,1,0-16h32A8,8,0,0,1,120,136ZM64,64H96a8,8,0,0,1,0,16H64a8,8,0,0,1,0-16Zm0,104H96a8,8,0,0,1,0,16H64a8,8,0,0,1,0-16Zm88-64h64V208H152Zm48,72a8,8,0,0,1-8,8H176a8,8,0,0,1,0-16h16A8,8,0,0,1,200,176Zm-32-40a8,8,0,0,1,8-8h16a8,8,0,0,1,0,16H176A8,8,0,0,1,168,136Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M240,210H230V104a14,14,0,0,0-14-14H150V40a14,14,0,0,0-14-14H40A14,14,0,0,0,26,40V210H16a6,6,0,0,0,0,12H240a6,6,0,0,0,0-12ZM216,102a2,2,0,0,1,2,2V210H150V102ZM38,40a2,2,0,0,1,2-2h96a2,2,0,0,1,2,2V210H38ZM58,72a6,6,0,0,1,6-6H96a6,6,0,0,1,0,12H64A6,6,0,0,1,58,72Zm60,64a6,6,0,0,1-6,6H80a6,6,0,0,1,0-12h32A6,6,0,0,1,118,136Zm-16,40a6,6,0,0,1-6,6H64a6,6,0,0,1,0-12H96A6,6,0,0,1,102,176Zm96,0a6,6,0,0,1-6,6H176a6,6,0,0,1,0-12h16A6,6,0,0,1,198,176Zm-28-40a6,6,0,0,1,6-6h16a6,6,0,0,1,0,12H176A6,6,0,0,1,170,136Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M240,212H228V104a12,12,0,0,0-12-12H148V40a12,12,0,0,0-12-12H40A12,12,0,0,0,28,40V212H16a4,4,0,0,0,0,8H240a4,4,0,0,0,0-8ZM216,100a4,4,0,0,1,4,4V212H148V100ZM36,40a4,4,0,0,1,4-4h96a4,4,0,0,1,4,4V212H36ZM60,72a4,4,0,0,1,4-4H96a4,4,0,0,1,0,8H64A4,4,0,0,1,60,72Zm56,64a4,4,0,0,1-4,4H80a4,4,0,0,1,0-8h32A4,4,0,0,1,116,136Zm-16,40a4,4,0,0,1-4,4H64a4,4,0,0,1,0-8H96A4,4,0,0,1,100,176Zm96,0a4,4,0,0,1-4,4H176a4,4,0,0,1,0-8h16A4,4,0,0,1,196,176Zm-24-40a4,4,0,0,1,4-4h16a4,4,0,0,1,0,8H176A4,4,0,0,1,172,136Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M240,208h-8V104a16,16,0,0,0-16-16H152V40a16,16,0,0,0-16-16H40A16,16,0,0,0,24,40V208H16a8,8,0,0,0,0,16H240a8,8,0,0,0,0-16ZM216,104V208H152V104ZM40,40h96V208H40ZM56,72a8,8,0,0,1,8-8H96a8,8,0,0,1,0,16H64A8,8,0,0,1,56,72Zm64,64a8,8,0,0,1-8,8H80a8,8,0,0,1,0-16h32A8,8,0,0,1,120,136Zm-16,40a8,8,0,0,1-8,8H64a8,8,0,0,1,0-16H96A8,8,0,0,1,104,176Zm96,0a8,8,0,0,1-8,8H176a8,8,0,0,1,0-16h16A8,8,0,0,1,200,176Zm-32-40a8,8,0,0,1,8-8h16a8,8,0,0,1,0,16H176A8,8,0,0,1,168,136Z" />
        </>
      )}
    </svg>
  );
}

export function PhBus(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M190,28H66A36,36,0,0,0,30,64V216a20.1,20.1,0,0,0,20,20H74a20.1,20.1,0,0,0,20-20V196h68v20a20.1,20.1,0,0,0,20,20h24a20.1,20.1,0,0,0,20-20V64A36,36,0,0,0,190,28ZM54,172V144H202v28Zm0-80H202v28H54ZM66,52H190a12,12,0,0,1,12,12v4H54V64A12,12,0,0,1,66,52Zm4,160H54V196H70Zm116,0V196h16v16Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.3">
            <path d="M214,192v24a8,8,0,0,1-8,8H182a8,8,0,0,1-8-8V192Z" />
          </g>
          <g opacity="0.3">
            <path d="M82,192v24a8,8,0,0,1-8,8H50a8,8,0,0,1-8-8V192Z" />
          </g>
          <g opacity="0.2">
            <rect x="42" y="72" width="172" height="48" />
          </g>
          <path d="M190,32H66A32.1,32.1,0,0,0,34,64V216a16,16,0,0,0,16,16H74a16,16,0,0,0,16-16V200h76v16a16,16,0,0,0,16,16h24a16,16,0,0,0,16-16V64A32.1,32.1,0,0,0,190,32ZM50,184V128H206v56ZM50,80H206v32H50ZM66,48H190a16,16,0,0,1,16,16H50A16,16,0,0,1,66,48Zm8,168H50V200H74Zm108,0V200h24v16Z" />
          <circle cx="82" cy="156" r="12" />
          <circle cx="174" cy="156" r="12" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M190,32H66A32.1,32.1,0,0,0,34,64V216a16,16,0,0,0,16,16H74a16,16,0,0,0,16-16V200h76v16a16,16,0,0,0,16,16h24a16,16,0,0,0,16-16V64A32.1,32.1,0,0,0,190,32ZM82,168a12,12,0,1,1,12-12A12,12,0,0,1,82,168Zm92,0a12,12,0,1,1,12-12A12,12,0,0,1,174,168Zm32-48H50V72H206Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M190,34H66A30.1,30.1,0,0,0,36,64V216a14,14,0,0,0,14,14H74a14,14,0,0,0,14-14V198h80v18a14,14,0,0,0,14,14h24a14,14,0,0,0,14-14V64A30.1,30.1,0,0,0,190,34ZM48,186V126H208v60ZM48,78H208v36H48ZM66,46H190a18.1,18.1,0,0,1,18,18v2H48V64A18.1,18.1,0,0,1,66,46ZM76,216a2,2,0,0,1-2,2H50a2,2,0,0,1-2-2V198H76Zm130,2H182a2,2,0,0,1-2-2V198h28v18A2,2,0,0,1,206,218ZM94,156a10,10,0,1,1-10-10A10,10,0,0,1,94,156Zm88,0a10,10,0,1,1-10-10A10,10,0,0,1,182,156Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M190,36H66A28.1,28.1,0,0,0,38,64V216a12,12,0,0,0,12,12H74a12,12,0,0,0,12-12V196h84v20a12,12,0,0,0,12,12h24a12,12,0,0,0,12-12V64A28.1,28.1,0,0,0,190,36ZM46,188V124H210v64ZM46,76H210v40H46ZM66,44H190a20.1,20.1,0,0,1,20,20v4H46V64A20.1,20.1,0,0,1,66,44ZM78,216a4,4,0,0,1-4,4H50a4,4,0,0,1-4-4V196H78Zm128,4H182a4,4,0,0,1-4-4V196h32v20A4,4,0,0,1,206,220ZM92,156a8,8,0,1,1-8-8A8,8,0,0,1,92,156Zm88,0a8,8,0,1,1-8-8A8,8,0,0,1,180,156Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M190,32H66A32.1,32.1,0,0,0,34,64V216a16,16,0,0,0,16,16H74a16,16,0,0,0,16-16V200h76v16a16,16,0,0,0,16,16h24a16,16,0,0,0,16-16V64A32.1,32.1,0,0,0,190,32ZM50,184V128H206v56ZM50,80H206v32H50ZM66,48H190a16,16,0,0,1,16,16H50A16,16,0,0,1,66,48Zm8,168H50V200H74Zm108,0V200h24v16ZM94,156a12,12,0,1,1-12-12A12,12,0,0,1,94,156Zm92,0a12,12,0,1,1-12-12A12,12,0,0,1,186,156Z" />
        </>
      )}
    </svg>
  );
}

export function PhButterfly(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M234.6,47.9C230.3,42.5,222.3,36,208,36c-17.7,0-40.2,11.8-60,31.5-2.9,2.8-5.5,5.6-8,8.5V56a12,12,0,0,0-24,0V76c-2.5-2.9-5.1-5.7-8-8.5C88.2,47.8,65.7,36,48,36c-14.3,0-22.3,6.5-26.6,11.9-13.2,16.9-8.3,46-1,75,5.5,22.2,17,32.7,27.8,37.5A47.5,47.5,0,0,0,44,180a48,48,0,0,0,84,31.7,48,48,0,0,0,79.8-51.3c10.8-4.8,22.3-15.3,27.8-37.5C242.9,93.9,247.8,64.8,234.6,47.9ZM92,204a24,24,0,0,1-2.7-47.9,11.9,11.9,0,0,0,10.6-13.2,12,12,0,0,0-13.2-10.6,46.9,46.9,0,0,0-21.8,8.1c-7.8-.7-16.8-5.7-21.3-23.3-7.3-29.5-8.4-47.8-3.2-54.4.6-.8,2-2.7,7.6-2.7,11,0,28.4,9.9,43.1,24.5S116,116.6,116,128v52A24.1,24.1,0,0,1,92,204Zm120.4-86.9c-4.5,17.6-13.5,22.6-21.3,23.3a46.9,46.9,0,0,0-21.8-8.1,12,12,0,1,0-2.6,23.8A24,24,0,1,1,140,180V128c0-11.4,9.8-28.5,24.9-43.5S197,60,208,60c5.6,0,7,1.9,7.6,2.7C220.8,69.3,219.7,87.6,212.4,117.1Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M224,120c-7.8,31.1-29,32.8-36.9,32.4h0A36,36,0,1,1,128,180a36,36,0,1,1-59.1-27.6h0c-7.9.4-29.1-1.3-36.9-32.4S17.1,48,48,48s80,48,80,80c0-32,49.1-80,80-80S232,88,224,120Z"
            opacity="0.2"
          />
          <path d="M231.4,50.4C227.7,45.6,220.7,40,208,40c-16.7,0-38.1,11.3-57.2,30.3A144.2,144.2,0,0,0,136,87.5V56a8,8,0,0,0-16,0V87.5a144.2,144.2,0,0,0-14.8-17.2C86.1,51.3,64.7,40,48,40c-12.7,0-19.7,5.6-23.4,10.4-6.8,8.7-12.2,24.1-.4,71.5,5.9,23.4,18.7,32.7,29.5,36.3A44.8,44.8,0,0,0,48,180a44,44,0,0,0,80,25.3A44,44,0,0,0,208,180a44.8,44.8,0,0,0-5.7-21.8c10.8-3.6,23.6-12.9,29.5-36.3C238.4,95.3,243.6,66,231.4,50.4ZM92,208a28,28,0,0,1-18.3-49.2l.7-.6a28.1,28.1,0,0,1,14.5-6,8.1,8.1,0,0,0,7.1-8.9,8,8,0,0,0-8.9-7,42.9,42.9,0,0,0-21,8.1c-8.4-.2-20.9-4.5-26.3-26.3-2.9-11.5-11.6-46.3-2.6-57.9C38.6,58.4,41.3,56,48,56c26.9,0,72,45.1,72,72v52A28.1,28.1,0,0,1,92,208Zm124.2-89.9c-5.4,21.8-17.9,26.1-26.3,26.3a42.9,42.9,0,0,0-21-8.1,8,8,0,0,0-8.9,7,8.1,8.1,0,0,0,7.1,8.9,28.1,28.1,0,0,1,14.5,6l.7.6A28,28,0,1,1,136,180V128c0-26.9,45.1-72,72-72,6.7,0,9.4,2.4,10.8,4.2C227.8,71.8,219.1,106.6,216.2,118.1Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M136,87.5V56a8,8,0,0,0-16,0V87.5a98.7,98.7,0,0,1,8,12.7A98.7,98.7,0,0,1,136,87.5Z" />
          <path d="M231.4,50.4C227.7,45.6,220.7,40,208,40c-16.7,0-38.1,11.3-57.2,30.3A144.2,144.2,0,0,0,136,87.5V172a8,8,0,0,1-16,0V87.5a144.2,144.2,0,0,0-14.8-17.2C86.1,51.3,64.7,40,48,40c-12.7,0-19.7,5.6-23.4,10.4-6.8,8.7-12.2,24.1-.4,71.5,6.6,26.3,22,34.9,33.5,37.5a40,40,0,1,0,70.3,38,40,40,0,1,0,70.3-38c11.5-2.6,26.9-11.2,33.5-37.5S243.6,66,231.4,50.4Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M229.8,51.6C224.9,45.2,217.5,42,208,42c-16.2,0-37,11.1-55.8,29.7A133.5,133.5,0,0,0,134,93.9V56a6,6,0,0,0-12,0V93.9a133.5,133.5,0,0,0-18.2-22.2C85,53.1,64.2,42,48,42c-9.5,0-16.9,3.2-21.8,9.6s-7.8,17.7-6.8,32.5c.9,12.8,4.1,26.7,6.8,37.4,6,24.2,19.8,32.7,30.6,35.6A42,42,0,1,0,128,201.6a42,42,0,1,0,71.2-44.5c10.8-2.9,24.6-11.4,30.6-35.6C236.4,95.3,241.5,66.5,229.8,51.6ZM92,210a30.1,30.1,0,0,1-19.6-52.8,1,1,0,0,0,.6-.5,29.9,29.9,0,0,1,15.7-6.5,6,6,0,1,0-1.4-11.9,41.5,41.5,0,0,0-20.6,8.1c-8.9,0-22.9-4.1-28.9-27.9C34.6,105.6,26,71.3,35.6,59c2.6-3.4,6.7-5,12.4-5,12.7,0,31.3,10.3,47.4,26.3S122,114.8,122,128v52A30.1,30.1,0,0,1,92,210Zm126.2-91.5c-6,23.8-20,27.9-28.9,27.9a41.5,41.5,0,0,0-20.6-8.1,6,6,0,1,0-1.4,11.9,29.9,29.9,0,0,1,15.7,6.5,1,1,0,0,0,.6.5A30,30,0,1,1,134,180V128c0-13.2,10.2-31.5,26.6-47.7S195.3,54,208,54c5.7,0,9.8,1.6,12.4,5C230,71.3,221.4,105.6,218.2,118.5Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M228.3,52.8C223.7,47,216.9,44,208,44c-25.5,0-60.5,28.7-76,57.3V56a4,4,0,0,0-8,0v45.3C108.5,72.7,73.5,44,48,44c-8.9,0-15.7,3-20.3,8.8-11.1,14.3-6,42.5.4,68.2,3.6,14.4,10.4,24.7,20.3,30.5a35.7,35.7,0,0,0,11.7,4.3A40,40,0,1,0,128,197.4a40,40,0,1,0,67.9-41.6,35.7,35.7,0,0,0,11.7-4.3c9.9-5.8,16.7-16.1,20.3-30.5C234.3,95.3,239.4,67.1,228.3,52.8ZM92,212a32.1,32.1,0,0,1-20.8-56.4l.3-.2a31.8,31.8,0,0,1,16.9-7.2,4,4,0,0,0,3.6-4.4,4.1,4.1,0,0,0-4.4-3.6,40.8,40.8,0,0,0-20.2,8.2c-21.7.5-29.2-20.3-31.5-29.4C30.3,96.6,25,69.3,34.1,57.7c3-3.8,7.5-5.7,13.9-5.7,29.1,0,76,46.9,76,76v52A32.1,32.1,0,0,1,92,212Zm128.1-93c-2.3,9.1-9.8,29.9-31.5,29.4a40.8,40.8,0,0,0-20.2-8.2,4.1,4.1,0,0,0-4.4,3.6,4,4,0,0,0,3.6,4.4,31.8,31.8,0,0,1,16.9,7.2l.3.2A32,32,0,1,1,132,180V128c0-29.1,46.9-76,76-76,6.4,0,10.9,1.9,13.9,5.7C231,69.3,225.7,96.6,220.1,119Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M231.4,50.4C227.7,45.6,220.7,40,208,40c-16.7,0-38.1,11.3-57.2,30.3A144.2,144.2,0,0,0,136,87.5V56a8,8,0,0,0-16,0V87.5a144.2,144.2,0,0,0-14.8-17.2C86.1,51.3,64.7,40,48,40c-12.7,0-19.7,5.6-23.4,10.4-6.8,8.7-12.2,24.1-.4,71.5,5.9,23.4,18.7,32.7,29.5,36.3A44.8,44.8,0,0,0,48,180a44,44,0,0,0,80,25.3A44,44,0,0,0,208,180a44.8,44.8,0,0,0-5.7-21.8c10.8-3.6,23.6-12.9,29.5-36.3C238.4,95.3,243.6,66,231.4,50.4ZM92,208a28,28,0,0,1-18.3-49.2l.7-.6a28.1,28.1,0,0,1,14.5-6,8.1,8.1,0,0,0,7.1-8.9,8,8,0,0,0-8.9-7,42.9,42.9,0,0,0-21,8.1c-8.4-.2-20.9-4.5-26.3-26.3-2.9-11.5-11.6-46.3-2.6-57.9C38.6,58.4,41.3,56,48,56c26.9,0,72,45.1,72,72v52A28.1,28.1,0,0,1,92,208Zm124.2-89.9c-5.4,21.8-17.9,26.1-26.3,26.3a42.9,42.9,0,0,0-21-8.1,8,8,0,0,0-8.9,7,8.1,8.1,0,0,0,7.1,8.9,28.1,28.1,0,0,1,14.5,6l.7.6A28,28,0,1,1,136,180V128c0-26.9,45.1-72,72-72,6.7,0,9.4,2.4,10.8,4.2C227.8,71.8,219.1,106.6,216.2,118.1Z" />
        </>
      )}
    </svg>
  );
}

export function PhCactus(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216,204H172V184a72.1,72.1,0,0,0,72-72,32,32,0,0,0-64,0,8,8,0,0,1-8,8V56a44,44,0,0,0-88,0V80a8,8,0,0,1-8-8,32,32,0,0,0-64,0,72.1,72.1,0,0,0,72,72v60H40a12,12,0,0,0,0,24H216a12,12,0,0,0,0-24ZM96,120H84A48,48,0,0,1,36,72a8,8,0,0,1,16,0,32.1,32.1,0,0,0,32,32H96a12,12,0,0,0,12-12V56a20,20,0,0,1,40,0v76a12,12,0,0,0,12,12h12a32.1,32.1,0,0,0,32-32,8,8,0,0,1,16,0,48,48,0,0,1-48,48H160a12,12,0,0,0-12,12v32H108V132A12,12,0,0,0,96,120Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M232,112h0a60,60,0,0,1-60,60H160v44H96V132H84A60,60,0,0,1,24,72h0A20.1,20.1,0,0,1,44,52h0A20.1,20.1,0,0,1,64,72,20.1,20.1,0,0,0,84,92H96V56a32,32,0,0,1,32-32h0a32,32,0,0,1,32,32v76h12a20.1,20.1,0,0,0,20-20,20.1,20.1,0,0,1,20-20h0A20.1,20.1,0,0,1,232,112Z"
            opacity="0.2"
          />
          <path d="M216,208H168V180h4a68.1,68.1,0,0,0,68-68,28,28,0,0,0-56,0,12,12,0,0,1-12,12h-4V56a40,40,0,0,0-80,0V84H84A12,12,0,0,1,72,72a28,28,0,0,0-56,0,68.1,68.1,0,0,0,68,68h4v68H40a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16ZM96,124H84A52,52,0,0,1,32,72a12,12,0,0,1,24,0,28.1,28.1,0,0,0,28,28H96a8,8,0,0,0,8-8V56a24,24,0,0,1,48,0v76a8,8,0,0,0,8,8h12a28.1,28.1,0,0,0,28-28,12,12,0,0,1,24,0,52,52,0,0,1-52,52H160a8,8,0,0,0-8,8v36H104V132A8,8,0,0,0,96,124Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M224,216a8,8,0,0,1-8,8H40a8,8,0,0,1,0-16H88V140H84A68.1,68.1,0,0,1,16,72a28,28,0,0,1,56,0A12,12,0,0,0,84,84h4V56a40,40,0,0,1,80,0v68h4a12,12,0,0,0,12-12,28,28,0,0,1,56,0,68.1,68.1,0,0,1-68,68h-4v28h48A8,8,0,0,1,224,216Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M216,210H166V178h6a66.1,66.1,0,0,0,66-66,26,26,0,0,0-52,0,14,14,0,0,1-14,14h-6V56a38,38,0,0,0-76,0V86H84A14,14,0,0,1,70,72a26,26,0,0,0-52,0,66.1,66.1,0,0,0,66,66h6v72H40a6,6,0,0,0,0,12H216a6,6,0,0,0,0-12ZM96,126H84A54,54,0,0,1,30,72a14,14,0,0,1,28,0A26.1,26.1,0,0,0,84,98H96a6,6,0,0,0,6-6V56a26,26,0,0,1,52,0v76a6,6,0,0,0,6,6h12a26.1,26.1,0,0,0,26-26,14,14,0,0,1,28,0,54,54,0,0,1-54,54H160a6,6,0,0,0-6,6v38H102V132A6,6,0,0,0,96,126Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M216,212H164V176h8a64.1,64.1,0,0,0,64-64,24,24,0,0,0-48,0,16,16,0,0,1-16,16h-8V56a36,36,0,0,0-72,0V88H84A16,16,0,0,1,68,72a24,24,0,0,0-48,0,64.1,64.1,0,0,0,64,64h8v76H40a4,4,0,0,0,0,8H216a4,4,0,0,0,0-8ZM96,128H84A56,56,0,0,1,28,72a16,16,0,0,1,32,0A24.1,24.1,0,0,0,84,96H96a4,4,0,0,0,4-4V56a28,28,0,0,1,56,0v76a4,4,0,0,0,4,4h12a24.1,24.1,0,0,0,24-24,16,16,0,0,1,32,0,56,56,0,0,1-56,56H160a4,4,0,0,0-4,4v40H100V132A4,4,0,0,0,96,128Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,208H168V180h4a68.1,68.1,0,0,0,68-68,28,28,0,0,0-56,0,12,12,0,0,1-12,12h-4V56a40,40,0,0,0-80,0V84H84A12,12,0,0,1,72,72a28,28,0,0,0-56,0,68.1,68.1,0,0,0,68,68h4v68H40a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16ZM96,124H84A52,52,0,0,1,32,72a12,12,0,0,1,24,0,28.1,28.1,0,0,0,28,28H96a8,8,0,0,0,8-8V56a24,24,0,0,1,48,0v76a8,8,0,0,0,8,8h12a28.1,28.1,0,0,0,28-28,12,12,0,0,1,24,0,52,52,0,0,1-52,52H160a8,8,0,0,0-8,8v36H104V132A8,8,0,0,0,96,124Z" />
        </>
      )}
    </svg>
  );
}

export function PhCake(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M242,112a36,36,0,0,0-36-36H160.3a38.1,38.1,0,0,0,4-11.4c2.3-13.2-2-27.7-12.6-43.1A124.2,124.2,0,0,0,136.2,3.2a12,12,0,0,0-16.4,0,124.2,124.2,0,0,0-15.5,18.3C93.7,36.9,89.4,51.4,91.7,64.6a38.1,38.1,0,0,0,4,11.4H50a36,36,0,0,0-36,36v13.3a47.2,47.2,0,0,0,14,33.4V208a20.1,20.1,0,0,0,20,20H208a20.1,20.1,0,0,0,20-20V158.7a47.2,47.2,0,0,0,14-33.4ZM128,29.8c6.9,8.6,14.3,20.9,12.7,30.7-1,5.4-5.2,10.2-12.7,14.2-7.5-4-11.7-8.8-12.6-14.2C113.6,50.8,121.1,38.5,128,29.8ZM38,112a12,12,0,0,1,12-12H206a12,12,0,0,1,12,12v13.3a23.1,23.1,0,0,1-6.9,16.4,21.4,21.4,0,0,1-15.5,6.3A22.1,22.1,0,0,1,174,126a4.9,4.9,0,0,0-.1-1.2,12,12,0,0,0-23.8,0,4.9,4.9,0,0,0-.1,1.2,22,22,0,0,1-44,0,4.9,4.9,0,0,0-.1-1.2,12,12,0,0,0-23.8,0A4.9,4.9,0,0,0,82,126a22.1,22.1,0,0,1-21.6,22,21.4,21.4,0,0,1-15.5-6.3A23.1,23.1,0,0,1,38,125.3Zm14,92V171.3a52.6,52.6,0,0,0,8.9.7,46.2,46.2,0,0,0,33-15.1,46.1,46.1,0,0,0,68.2,0,46.2,46.2,0,0,0,33,15.1h.9a52.9,52.9,0,0,0,8-.7V204Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M128,8s46.2,40,0,56C80,48,128,8,128,8Zm78,80H50a23.9,23.9,0,0,0-24,24v13.3c0,19.1,15.6,35.1,34.7,34.7A34,34,0,0,0,94,126a34,34,0,0,0,68,0,34,34,0,0,0,33.3,34c19.1.4,34.7-15.6,34.7-34.7V112A23.9,23.9,0,0,0,206,88Z"
            opacity="0.2"
          />
          <path d="M206,80H136V69.4c11.5-5.1,18.2-12.5,20.1-22C160.2,26,136,4.3,133.2,2a7.8,7.8,0,0,0-10.3-.1C120,4.2,95.1,25.7,99.1,47.2c1.8,9.7,8.9,17.2,20.9,22.3V80H50a32.1,32.1,0,0,0-32,32v13.3A43.3,43.3,0,0,0,30.9,156l1.1,1v51a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V157l1.1-1A43.3,43.3,0,0,0,238,125.3V112A32.1,32.1,0,0,0,206,80ZM114.8,44.2c-1.5-7.8,6.7-18.4,13.1-25.1,6.2,6.7,14,17.4,12.5,25.3-.9,4.4-5.1,8.1-12.5,11.1C122.1,53.2,115.8,49.6,114.8,44.2ZM34,112A16,16,0,0,1,50,96H206a16,16,0,0,1,16,16v13.3a27.5,27.5,0,0,1-8.1,19.3,25.7,25.7,0,0,1-18.4,7.4A26.1,26.1,0,0,1,170,126a8.3,8.3,0,0,0-1.8-5.1l-.5-.6a9,9,0,0,0-2.6-1.7,8.3,8.3,0,0,0-6.2,0,9,9,0,0,0-2.6,1.7l-.5.6A8.3,8.3,0,0,0,154,126a26,26,0,0,1-52,0,8.3,8.3,0,0,0-1.8-5.1l-.5-.6a9,9,0,0,0-2.6-1.7,8.3,8.3,0,0,0-6.2,0,9,9,0,0,0-2.6,1.7l-.5.6A8.3,8.3,0,0,0,86,126a26.1,26.1,0,0,1-25.5,26,25.7,25.7,0,0,1-18.4-7.4A27.5,27.5,0,0,1,34,125.3Zm174,96H48V166.2A39.3,39.3,0,0,0,60.8,168,42.2,42.2,0,0,0,94,150.6a41.9,41.9,0,0,0,68,0A42.2,42.2,0,0,0,195.2,168h.8a40.6,40.6,0,0,0,12-1.8Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M206,80H136V69.4c11.5-5.1,18.2-12.5,20.1-22C160.2,26,136,4.3,133.2,2a7.8,7.8,0,0,0-10.3-.1C120,4.2,95.1,25.7,99.1,47.2c1.8,9.7,8.9,17.2,20.9,22.3V80H50a32.1,32.1,0,0,0-32,32v13.3A43.3,43.3,0,0,0,30.9,156l1.1,1v51a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V157l1.1-1A43.3,43.3,0,0,0,238,125.3V112A32.1,32.1,0,0,0,206,80Zm2,128H48V166.2A39.3,39.3,0,0,0,60.8,168,42.2,42.2,0,0,0,94,150.6a41.9,41.9,0,0,0,68,0A42.2,42.2,0,0,0,195.2,168h.8a40.6,40.6,0,0,0,12-1.8Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M206,82H134V68.1c11.6-4.9,18.3-11.9,20.1-21.1C158,26.7,134.6,5.8,131.9,3.5a5.8,5.8,0,0,0-7.7-.1,93.8,93.8,0,0,0-12.7,13.2c-8.6,11.1-12.1,21.2-10.4,30.2s8.8,16.5,20.9,21.4V82H50a30.1,30.1,0,0,0-30,30v13.3a41.5,41.5,0,0,0,12.3,29.3l1.7,1.5V208a14,14,0,0,0,14,14H208a14,14,0,0,0,14-14V156.1l1.7-1.5A41.5,41.5,0,0,0,236,125.3V112A30.1,30.1,0,0,0,206,82ZM112.9,44.6c-1.8-9.3,8.2-21.6,15-28.4a89.7,89.7,0,0,1,6.8,8c4.2,5.6,8.9,13.7,7.6,20.6-1,5.2-5.8,9.5-14.3,12.8C119,54.3,113.9,49.9,112.9,44.6ZM210,208a2,2,0,0,1-2,2H48a2,2,0,0,1-2-2V163.4A39.1,39.1,0,0,0,60.8,166,40.5,40.5,0,0,0,94,147a39.9,39.9,0,0,0,68,0,40.5,40.5,0,0,0,33.2,19h.8a39.1,39.1,0,0,0,14-2.6Zm14-82.7a29.3,29.3,0,0,1-8.7,20.7,27.7,27.7,0,0,1-19.8,8A28.2,28.2,0,0,1,168,126a6,6,0,0,0-12,0,28,28,0,0,1-56,0,6,6,0,0,0-12,0,28.2,28.2,0,0,1-27.5,28,27.7,27.7,0,0,1-19.8-8A29.3,29.3,0,0,1,32,125.3V112A18.1,18.1,0,0,1,50,94H206a18.1,18.1,0,0,1,18,18Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M206,84H132V66.8c11.7-4.6,18.5-11.4,20.1-20.1C155.9,27.1,131.7,5.9,130.6,5a4.1,4.1,0,0,0-5.2-.1c-1,.9-26,22-22.4,41.6,1.7,8.8,8.8,15.7,21,20.3V84H50a28.1,28.1,0,0,0-28,28v13.3a39.3,39.3,0,0,0,11.7,27.8,22.9,22.9,0,0,0,2.3,2.1V208a12,12,0,0,0,12,12H208a12,12,0,0,0,12-12V155.2a22.9,22.9,0,0,0,2.3-2.1A39.3,39.3,0,0,0,234,125.3V112A28.1,28.1,0,0,0,206,84ZM110.9,45c-2.1-10.9,9.9-24.8,17.1-31.6,6.9,6.8,18.4,20.8,16.3,31.7-1.2,6.2-6.7,11.1-16.3,14.7C117.8,56.1,112.1,51.2,110.9,45ZM212,208a4,4,0,0,1-4,4H48a4,4,0,0,1-4-4V160.4a37.8,37.8,0,0,0,50-17.5,37.9,37.9,0,0,0,68,0A38.5,38.5,0,0,0,195.3,164h.7a38.2,38.2,0,0,0,16-3.6Zm14-82.7a31.1,31.1,0,0,1-9.3,22.1,29.5,29.5,0,0,1-21.3,8.6A30.2,30.2,0,0,1,166,126a4,4,0,0,0-8,0,30,30,0,0,1-60,0,4,4,0,0,0-8,0,30.2,30.2,0,0,1-29.4,30,29.5,29.5,0,0,1-21.3-8.6A31.1,31.1,0,0,1,30,125.3V112A20.1,20.1,0,0,1,50,92H206a20.1,20.1,0,0,1,20,20Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M206,80H136V69.4c11.5-5.1,18.2-12.5,20.1-22C160.2,26,136,4.3,133.2,2a7.8,7.8,0,0,0-10.3-.1C120,4.2,95.1,25.7,99.1,47.2c1.8,9.7,8.9,17.2,20.9,22.3V80H50a32.1,32.1,0,0,0-32,32v13.3A43.3,43.3,0,0,0,30.9,156l1.1,1v51a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V157l1.1-1A43.3,43.3,0,0,0,238,125.3V112A32.1,32.1,0,0,0,206,80ZM114.8,44.2c-1.5-7.8,6.7-18.4,13.1-25.1,6.2,6.7,14,17.4,12.5,25.3-.9,4.4-5.1,8.1-12.5,11.1C122.1,53.2,115.8,49.6,114.8,44.2ZM34,112A16,16,0,0,1,50,96H206a16,16,0,0,1,16,16v13.3a27.5,27.5,0,0,1-8.1,19.3,25.7,25.7,0,0,1-18.4,7.4A26.1,26.1,0,0,1,170,126a8.3,8.3,0,0,0-1.8-5.1l-.5-.6a9,9,0,0,0-2.6-1.7,8.3,8.3,0,0,0-6.2,0,9,9,0,0,0-2.6,1.7l-.5.6A8.3,8.3,0,0,0,154,126a26,26,0,0,1-52,0,8.3,8.3,0,0,0-1.8-5.1l-.5-.6a9,9,0,0,0-2.6-1.7,8.3,8.3,0,0,0-6.2,0,9,9,0,0,0-2.6,1.7l-.5.6A8.3,8.3,0,0,0,86,126a26.1,26.1,0,0,1-25.5,26,25.7,25.7,0,0,1-18.4-7.4A27.5,27.5,0,0,1,34,125.3Zm174,96H48V166.2A39.3,39.3,0,0,0,60.8,168,42.2,42.2,0,0,0,94,150.6a41.9,41.9,0,0,0,68,0A42.2,42.2,0,0,0,195.2,168h.8a40.6,40.6,0,0,0,12-1.8Z" />
        </>
      )}
    </svg>
  );
}

export function PhCalculator(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M200,20H56A20.1,20.1,0,0,0,36,40V216a20.1,20.1,0,0,0,20,20H200a20.1,20.1,0,0,0,20-20V40A20.1,20.1,0,0,0,200,20Zm-4,192H60V44H196ZM80,76A12,12,0,0,1,92,64h72a12,12,0,0,1,0,24H92A12,12,0,0,1,80,76Zm88,52a16,16,0,1,1-16-16A16,16,0,0,1,168,128Zm0,48a16,16,0,1,1-16-16A16,16,0,0,1,168,176Zm-48-48a16,16,0,1,1-16-16A16,16,0,0,1,120,128Zm0,48a16,16,0,1,1-16-16A16,16,0,0,1,120,176Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect x="80" y="64" width="96" height="48" />
          </g>
          <path d="M80,120h96a8,8,0,0,0,8-8V64a8,8,0,0,0-8-8H80a8,8,0,0,0-8,8v48A8,8,0,0,0,80,120Zm8-48h80v32H88ZM200,24H56A16,16,0,0,0,40,40V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V40A16,16,0,0,0,200,24Zm0,192H56V40H200Zm-60-68a12,12,0,1,1-12-12A12,12,0,0,1,140,148Zm0,40a12,12,0,1,1-12-12A12,12,0,0,1,140,188Zm-40-40a12,12,0,1,1-12-12A12,12,0,0,1,100,148Zm0,40a12,12,0,1,1-12-12A12,12,0,0,1,100,188Zm80-40a12,12,0,1,1-12-12A12,12,0,0,1,180,148Zm0,40a12,12,0,1,1-12-12A12,12,0,0,1,180,188Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M200,24H56A16,16,0,0,0,40,40V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V40A16,16,0,0,0,200,24ZM88,200a12,12,0,1,1,12-12A12,12,0,0,1,88,200Zm0-40a12,12,0,1,1,12-12A12,12,0,0,1,88,160Zm40,40a12,12,0,1,1,12-12A12,12,0,0,1,128,200Zm0-40a12,12,0,1,1,12-12A12,12,0,0,1,128,160Zm40,40a12,12,0,1,1,12-12A12,12,0,0,1,168,200Zm0-40a12,12,0,1,1,12-12A12,12,0,0,1,168,160Zm16-56a8,8,0,0,1-8,8H80a8,8,0,0,1-8-8V64a8,8,0,0,1,8-8h96a8,8,0,0,1,8,8Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M176,58H80a6,6,0,0,0-6,6v48a6,6,0,0,0,6,6h96a6,6,0,0,0,6-6V64A6,6,0,0,0,176,58Zm-6,48H86V70h84Zm30-80H56A14,14,0,0,0,42,40V216a14,14,0,0,0,14,14H200a14,14,0,0,0,14-14V40A14,14,0,0,0,200,26Zm2,190a2,2,0,0,1-2,2H56a2,2,0,0,1-2-2V40a2,2,0,0,1,2-2H200a2,2,0,0,1,2,2Zm-64-68a10,10,0,1,1-10-10A10,10,0,0,1,138,148Zm0,40a10,10,0,1,1-10-10A10,10,0,0,1,138,188ZM98,148a10,10,0,1,1-10-10A10,10,0,0,1,98,148Zm0,40a10,10,0,1,1-10-10A10,10,0,0,1,98,188Zm80-40a10,10,0,1,1-10-10A10,10,0,0,1,178,148Zm0,40a10,10,0,1,1-10-10A10,10,0,0,1,178,188Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M176,60H80a4,4,0,0,0-4,4v48a4,4,0,0,0,4,4h96a4,4,0,0,0,4-4V64A4,4,0,0,0,176,60Zm-4,48H84V68h88Zm28-80H56A12,12,0,0,0,44,40V216a12,12,0,0,0,12,12H200a12,12,0,0,0,12-12V40A12,12,0,0,0,200,28Zm4,188a4,4,0,0,1-4,4H56a4,4,0,0,1-4-4V40a4,4,0,0,1,4-4H200a4,4,0,0,1,4,4Zm-68-68a8,8,0,1,1-8-8A8,8,0,0,1,136,148Zm0,40a8,8,0,1,1-8-8A8,8,0,0,1,136,188ZM96,148a8,8,0,1,1-8-8A8,8,0,0,1,96,148Zm0,40a8,8,0,1,1-8-8A8,8,0,0,1,96,188Zm80-40a8,8,0,1,1-8-8A8,8,0,0,1,176,148Zm0,40a8,8,0,1,1-8-8A8,8,0,0,1,176,188Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M80,120h96a8,8,0,0,0,8-8V64a8,8,0,0,0-8-8H80a8,8,0,0,0-8,8v48A8,8,0,0,0,80,120Zm8-48h80v32H88ZM200,24H56A16,16,0,0,0,40,40V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V40A16,16,0,0,0,200,24Zm0,192H56V40H200Zm-60-68a12,12,0,1,1-12-12A12,12,0,0,1,140,148Zm0,40a12,12,0,1,1-12-12A12,12,0,0,1,140,188Zm-40-40a12,12,0,1,1-12-12A12,12,0,0,1,100,148Zm0,40a12,12,0,1,1-12-12A12,12,0,0,1,100,188Zm80-40a12,12,0,1,1-12-12A12,12,0,0,1,180,148Zm0,40a12,12,0,1,1-12-12A12,12,0,0,1,180,188Z" />
        </>
      )}
    </svg>
  );
}

export function PhCalendarBlank(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M208,28H188V20a12,12,0,0,0-24,0v8H92V20a12,12,0,0,0-24,0v8H48A20.1,20.1,0,0,0,28,48V208a20.1,20.1,0,0,0,20,20H208a20.1,20.1,0,0,0,20-20V48A20.1,20.1,0,0,0,208,28Zm-4,24V76H52V52ZM52,204V100H204V204Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M40,88H216V48a8,8,0,0,0-8-8H48a8,8,0,0,0-8,8Z" />
          </g>
          <path d="M208,32H184V24a8,8,0,0,0-16,0v8H88V24a8,8,0,0,0-16,0v8H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM72,48v8a8,8,0,0,0,16,0V48h80v8a8,8,0,0,0,16,0V48h24V80H48V48ZM208,208H48V96H208V208Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M208,32H184V24a8,8,0,0,0-16,0v8H88V24a8,8,0,0,0-16,0v8H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32Zm0,48H48V48H72v8a8,8,0,0,0,16,0V48h80v8a8,8,0,0,0,16,0V48h24Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M208,34H182V24a6,6,0,0,0-12,0V34H86V24a6,6,0,0,0-12,0V34H48A14,14,0,0,0,34,48V208a14,14,0,0,0,14,14H208a14,14,0,0,0,14-14V48A14,14,0,0,0,208,34ZM48,46H74V56a6,6,0,0,0,12,0V46h84V56a6,6,0,0,0,12,0V46h26a2,2,0,0,1,2,2V82H46V48A2,2,0,0,1,48,46ZM208,210H48a2,2,0,0,1-2-2V94H210V208A2,2,0,0,1,208,210Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M208,36H180V24a4,4,0,0,0-8,0V36H84V24a4,4,0,0,0-8,0V36H48A12,12,0,0,0,36,48V208a12,12,0,0,0,12,12H208a12,12,0,0,0,12-12V48A12,12,0,0,0,208,36ZM48,44H76V56a4,4,0,0,0,8,0V44h88V56a4,4,0,0,0,8,0V44h28a4,4,0,0,1,4,4V84H44V48A4,4,0,0,1,48,44ZM208,212H48a4,4,0,0,1-4-4V92H212V208A4,4,0,0,1,208,212Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M208,32H184V24a8,8,0,0,0-16,0v8H88V24a8,8,0,0,0-16,0v8H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM72,48v8a8,8,0,0,0,16,0V48h80v8a8,8,0,0,0,16,0V48h24V80H48V48ZM208,208H48V96H208V208Z" />
        </>
      )}
    </svg>
  );
}

export function PhCalendarCheck(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M208,28H188V20a12,12,0,0,0-24,0v8H92V20a12,12,0,0,0-24,0v8H48A20.1,20.1,0,0,0,28,48V208a20.1,20.1,0,0,0,20,20H208a20.1,20.1,0,0,0,20-20V48A20.1,20.1,0,0,0,208,28Zm-4,24V76H52V52ZM52,204V100H204V204Zm120.7-84.2a11.9,11.9,0,0,1-.5,16.9l-46.6,44a12.2,12.2,0,0,1-8.3,3.3,11.9,11.9,0,0,1-8.2-3.3l-25.4-24a12,12,0,0,1-.4-17,11.9,11.9,0,0,1,16.9-.4l17.1,16.2,38.5-36.2A11.8,11.8,0,0,1,172.7,119.8Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M216,48V88H40V48a8,8,0,0,1,8-8H208A8,8,0,0,1,216,48Z"
            opacity="0.2"
          />
          <path d="M208,32H184V24a8,8,0,0,0-16,0v8H88V24a8,8,0,0,0-16,0v8H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM72,48v8a8,8,0,0,0,16,0V48h80v8a8,8,0,0,0,16,0V48h24V80H48V48ZM208,208H48V96H208V208Zm-38.2-85.5a8,8,0,0,1-.3,11.3l-46.7,44a7.7,7.7,0,0,1-5.5,2.2,7.9,7.9,0,0,1-5.5-2.2l-25.3-24a8,8,0,1,1,11-11.6L117.3,161l41.2-38.8A7.9,7.9,0,0,1,169.8,122.5Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M208,32H184V24a8,8,0,0,0-16,0v8H88V24a8,8,0,0,0-16,0v8H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM169.5,133.8l-46.7,44a7.7,7.7,0,0,1-5.5,2.2,7.9,7.9,0,0,1-5.5-2.2l-25.3-24a8,8,0,1,1,11-11.6L117.3,161l41.2-38.8a7.9,7.9,0,0,1,11.3.3A8,8,0,0,1,169.5,133.8ZM208,80H48V48H72v8a8,8,0,0,0,16,0V48h80v8a8,8,0,0,0,16,0V48h24Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M208,34H182V24a6,6,0,0,0-12,0V34H86V24a6,6,0,0,0-12,0V34H48A14,14,0,0,0,34,48V208a14,14,0,0,0,14,14H208a14,14,0,0,0,14-14V48A14,14,0,0,0,208,34ZM48,46H74V56a6,6,0,0,0,12,0V46h84V56a6,6,0,0,0,12,0V46h26a2,2,0,0,1,2,2V82H46V48A2,2,0,0,1,48,46ZM208,210H48a2,2,0,0,1-2-2V94H210V208A2,2,0,0,1,208,210Zm-39.6-86.1a6.1,6.1,0,0,1-.3,8.5l-46.7,44a5.7,5.7,0,0,1-4.1,1.6,6.4,6.4,0,0,1-4.1-1.6l-25.3-24a6,6,0,0,1,8.2-8.8l21.2,20.1,42.6-40.1A6.1,6.1,0,0,1,168.4,123.9Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M208,36H180V24a4,4,0,0,0-8,0V36H84V24a4,4,0,0,0-8,0V36H48A12,12,0,0,0,36,48V208a12,12,0,0,0,12,12H208a12,12,0,0,0,12-12V48A12,12,0,0,0,208,36ZM48,44H76V56a4,4,0,0,0,8,0V44h88V56a4,4,0,0,0,8,0V44h28a4,4,0,0,1,4,4V84H44V48A4,4,0,0,1,48,44ZM208,212H48a4,4,0,0,1-4-4V92H212V208A4,4,0,0,1,208,212Zm-41.1-86.7a4,4,0,0,1-.2,5.6l-46.6,44a4.3,4.3,0,0,1-2.8,1.1,3.9,3.9,0,0,1-2.7-1.1l-25.4-24a4.1,4.1,0,0,1-.1-5.7,4,4,0,0,1,5.6-.1l22.6,21.4,44-41.4A3.9,3.9,0,0,1,166.9,125.3Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M208,32H184V24a8,8,0,0,0-16,0v8H88V24a8,8,0,0,0-16,0v8H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM72,48v8a8,8,0,0,0,16,0V48h80v8a8,8,0,0,0,16,0V48h24V80H48V48ZM208,208H48V96H208V208Zm-38.2-85.5a8,8,0,0,1-.3,11.3l-46.7,44a7.7,7.7,0,0,1-5.5,2.2,7.9,7.9,0,0,1-5.5-2.2l-25.3-24a8,8,0,1,1,11-11.6L117.3,161l41.2-38.8A7.9,7.9,0,0,1,169.8,122.5Z" />
        </>
      )}
    </svg>
  );
}

export function PhCalendarPlus(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M168,152a12,12,0,0,1-12,12H140v16a12,12,0,0,1-24,0V164H100a12,12,0,0,1,0-24h16V124a12,12,0,0,1,24,0v16h16A12,12,0,0,1,168,152ZM228,48V208a20.1,20.1,0,0,1-20,20H48a20.1,20.1,0,0,1-20-20V48A20.1,20.1,0,0,1,48,28H68V20a12,12,0,0,1,24,0v8h72V20a12,12,0,0,1,24,0v8h20A20.1,20.1,0,0,1,228,48ZM52,52V76H204V52ZM204,204V100H52V204Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M216,48V88H40V48a8,8,0,0,1,8-8H208A8,8,0,0,1,216,48Z"
            opacity="0.2"
          />
          <path d="M208,32H184V24a8,8,0,0,0-16,0v8H88V24a8,8,0,0,0-16,0v8H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM72,48v8a8,8,0,0,0,16,0V48h80v8a8,8,0,0,0,16,0V48h24V80H48V48ZM208,208H48V96H208V208Zm-44-56a8,8,0,0,1-8,8H136v20a8,8,0,0,1-16,0V160H100a8,8,0,0,1,0-16h20V124a8,8,0,0,1,16,0v20h20A8,8,0,0,1,164,152Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M208,32H184V24a8,8,0,0,0-16,0v8H88V24a8,8,0,0,0-16,0v8H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM156,160H136v20a8,8,0,0,1-16,0V160H100a8,8,0,0,1,0-16h20V124a8,8,0,0,1,16,0v20h20a8,8,0,0,1,0,16Zm52-80H48V48H72v8a8,8,0,0,0,16,0V48h80v8a8,8,0,0,0,16,0V48h24Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M208,34H182V24a6,6,0,0,0-12,0V34H86V24a6,6,0,0,0-12,0V34H48A14,14,0,0,0,34,48V208a14,14,0,0,0,14,14H208a14,14,0,0,0,14-14V48A14,14,0,0,0,208,34Zm2,174a2,2,0,0,1-2,2H48a2,2,0,0,1-2-2V94H210Zm0-126H46V48a2,2,0,0,1,2-2H74V56a6,6,0,0,0,12,0V46h84V56a6,6,0,0,0,12,0V46h26a2,2,0,0,1,2,2ZM94,152a6,6,0,0,1,6-6h22V124a6,6,0,0,1,12,0v22h22a6,6,0,0,1,0,12H134v22a6,6,0,0,1-12,0V158H100A6,6,0,0,1,94,152Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M208,36H180V24a4,4,0,0,0-8,0V36H84V24a4,4,0,0,0-8,0V36H48A12,12,0,0,0,36,48V208a12,12,0,0,0,12,12H208a12,12,0,0,0,12-12V48A12,12,0,0,0,208,36ZM48,44H76V56a4,4,0,0,0,8,0V44h88V56a4,4,0,0,0,8,0V44h28a4,4,0,0,1,4,4V84H44V48A4,4,0,0,1,48,44ZM208,212H48a4,4,0,0,1-4-4V92H212V208A4,4,0,0,1,208,212Zm-48-60a4,4,0,0,1-4,4H132v24a4,4,0,0,1-8,0V156H100a4,4,0,0,1,0-8h24V124a4,4,0,0,1,8,0v24h24A4,4,0,0,1,160,152Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M208,32H184V24a8,8,0,0,0-16,0v8H88V24a8,8,0,0,0-16,0v8H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM72,48v8a8,8,0,0,0,16,0V48h80v8a8,8,0,0,0,16,0V48h24V80H48V48ZM208,208H48V96H208V208Zm-44-56a8,8,0,0,1-8,8H136v20a8,8,0,0,1-16,0V160H100a8,8,0,0,1,0-16h20V124a8,8,0,0,1,16,0v20h20A8,8,0,0,1,164,152Z" />
        </>
      )}
    </svg>
  );
}

export function PhCalendarX(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M160.5,136.5,145,152l15.5,15.5a12,12,0,0,1,0,17,12.1,12.1,0,0,1-17,0L128,169l-15.5,15.5a12.1,12.1,0,0,1-17,0,12,12,0,0,1,0-17L111,152,95.5,136.5a12,12,0,0,1,17-17L128,135l15.5-15.5a12,12,0,0,1,17,17ZM228,48V208a20.1,20.1,0,0,1-20,20H48a20.1,20.1,0,0,1-20-20V48A20.1,20.1,0,0,1,48,28H68V20a12,12,0,0,1,24,0v8h72V20a12,12,0,0,1,24,0v8h20A20.1,20.1,0,0,1,228,48ZM52,52V76H204V52ZM204,204V100H52V204Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M40,88H216V48a8,8,0,0,0-8-8H48a8,8,0,0,0-8,8Z" />
          </g>
          <path d="M208,32H184V24a8,8,0,0,0-16,0v8H88V24a8,8,0,0,0-16,0v8H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM72,48v8a8,8,0,0,0,16,0V48h80v8a8,8,0,0,0,16,0V48h24V80H48V48ZM208,208H48V96H208V208Z" />
          <path d="M157.7,122.3a8.1,8.1,0,0,0-11.4,0L128,140.7l-18.3-18.4a8.1,8.1,0,0,0-11.4,11.4L116.7,152,98.3,170.3a8.1,8.1,0,0,0,0,11.4,8.2,8.2,0,0,0,11.4,0L128,163.3l18.3,18.4a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4L139.3,152l18.4-18.3A8.1,8.1,0,0,0,157.7,122.3Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M208,32H184V24a8,8,0,0,0-16,0v8H88V24a8,8,0,0,0-16,0v8H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM157.7,170.3a8.1,8.1,0,0,1,0,11.4A8.5,8.5,0,0,1,152,184a8.3,8.3,0,0,1-5.7-2.3L128,163.3l-18.3,18.4A8.5,8.5,0,0,1,104,184a8.3,8.3,0,0,1-5.7-2.3,8.1,8.1,0,0,1,0-11.4L116.7,152,98.3,133.7a8.1,8.1,0,0,1,11.4-11.4L128,140.7l18.3-18.4a8.1,8.1,0,0,1,11.4,11.4L139.3,152ZM208,80H48V48H72v8a8,8,0,0,0,16,0V48h80v8a8,8,0,0,0,16,0V48h24Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M208,34H182V24a6,6,0,0,0-12,0V34H86V24a6,6,0,0,0-12,0V34H48A14,14,0,0,0,34,48V208a14,14,0,0,0,14,14H208a14,14,0,0,0,14-14V48A14,14,0,0,0,208,34ZM48,46H74V56a6,6,0,0,0,12,0V46h84V56a6,6,0,0,0,12,0V46h26a2,2,0,0,1,2,2V82H46V48A2,2,0,0,1,48,46ZM208,210H48a2,2,0,0,1-2-2V94H210V208A2,2,0,0,1,208,210Zm-51.8-77.8L136.5,152l19.7,19.8a5.9,5.9,0,1,1-8.4,8.4L128,160.5l-19.8,19.7a5.9,5.9,0,0,1-8.4-8.4L119.5,152,99.8,132.2a5.9,5.9,0,0,1,8.4-8.4L128,143.5l19.8-19.7a5.9,5.9,0,0,1,8.4,8.4Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M208,36H180V24a4,4,0,0,0-8,0V36H84V24a4,4,0,0,0-8,0V36H48A12,12,0,0,0,36,48V208a12,12,0,0,0,12,12H208a12,12,0,0,0,12-12V48A12,12,0,0,0,208,36ZM48,44H76V56a4,4,0,0,0,8,0V44h88V56a4,4,0,0,0,8,0V44h28a4,4,0,0,1,4,4V84H44V48A4,4,0,0,1,48,44ZM208,212H48a4,4,0,0,1-4-4V92H212V208A4,4,0,0,1,208,212Zm-53.2-81.2L133.7,152l21.1,21.2a3.9,3.9,0,0,1,0,5.6,3.9,3.9,0,0,1-5.6,0L128,157.7l-21.2,21.1a3.9,3.9,0,0,1-5.6,0,3.9,3.9,0,0,1,0-5.6L122.3,152l-21.1-21.2a4,4,0,1,1,5.6-5.6L128,146.3l21.2-21.1a4,4,0,1,1,5.6,5.6Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M208,32H184V24a8,8,0,0,0-16,0v8H88V24a8,8,0,0,0-16,0v8H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM72,48v8a8,8,0,0,0,16,0V48h80v8a8,8,0,0,0,16,0V48h24V80H48V48ZM208,208H48V96H208V208Zm-50.3-74.3L139.3,152l18.4,18.3a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0L128,163.3l-18.3,18.4a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L116.7,152,98.3,133.7a8.1,8.1,0,0,1,11.4-11.4L128,140.7l18.3-18.4a8.1,8.1,0,0,1,11.4,11.4Z" />
        </>
      )}
    </svg>
  );
}

export function PhCalendar(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M208,28H188V20a12,12,0,0,0-24,0v8H92V20a12,12,0,0,0-24,0v8H48A20.1,20.1,0,0,0,28,48V208a20.1,20.1,0,0,0,20,20H208a20.1,20.1,0,0,0,20-20V48A20.1,20.1,0,0,0,208,28Zm-4,24V76H52V52ZM52,204V100H204V204Zm76-40a28.1,28.1,0,0,1-28,28,27.8,27.8,0,0,1-19.8-8.2,12,12,0,0,1,17-17A3.7,3.7,0,0,0,100,168a4,4,0,0,0,0-8,12,12,0,0,1-9.4-19.5l.4-.5H88a12,12,0,0,1,0-24h28a12,12,0,0,1,9.4,19.5l-6.4,8A27.9,27.9,0,0,1,128,164Zm48-36v52a12,12,0,0,1-24,0V151.3a12,12,0,0,1-11.2-20.9l16-12A12,12,0,0,1,176,128Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M40,88H216V48a8,8,0,0,0-8-8H48a8,8,0,0,0-8,8Z" />
          </g>
          <path d="M208,32H184V24a8,8,0,0,0-16,0v8H88V24a8,8,0,0,0-16,0v8H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM72,48v8a8,8,0,0,0,16,0V48h80v8a8,8,0,0,0,16,0V48h24V80H48V48ZM208,208H48V96H208V208Z" />
          <path d="M117.4,144.1l8.8-11.1a8,8,0,0,0-6.2-13H92a8,8,0,0,0,0,16h11.4l-5.6,7a8,8,0,0,0,6.2,13,8,8,0,0,1,0,16,8.3,8.3,0,0,1-5.7-2.3,7.9,7.9,0,0,0-11.3,0A8,8,0,0,0,87,181a24,24,0,0,0,41-17A23.9,23.9,0,0,0,117.4,144.1Z" />
          <path d="M163.6,120.8a8.2,8.2,0,0,0-8.4.8l-16,12a8,8,0,0,0-1.6,11.2,8.1,8.1,0,0,0,11.2,1.6L152,144v36a8,8,0,0,0,16,0V128A8.2,8.2,0,0,0,163.6,120.8Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M208,32H184V24a8,8,0,0,0-16,0v8H88V24a8,8,0,0,0-16,0v8H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM104,188a24.2,24.2,0,0,1-17-7,8,8,0,0,1,0-11.3,7.9,7.9,0,0,1,11.3,0A8.3,8.3,0,0,0,104,172a8,8,0,0,0,0-16h-2.5l-.4-.2h-.3l-.5-.2h-.1l-.6-.4h-.2l-.4-.3h0l-.4-.3-.2-.2-.3-.3a8.6,8.6,0,0,1-1.3-2,5.8,5.8,0,0,1-.6-1.7h0c-.1-.1-.1-.2-.1-.4a.4.4,0,0,0-.1-.3V148h0v-.7c0-.2.1-.3.1-.4v-.4a.6.6,0,0,0,.1-.4c.1-.1.1-.2.1-.4l.2-.3c0-.2,0-.3.1-.4l.2-.4v-.3l.2-.4.2-.3.3-.4.2-.2,5.6-7H92a8,8,0,0,1,0-16h28a8,8,0,0,1,6.2,13l-8.8,11.1A24,24,0,0,1,104,188Zm64-8a8,8,0,0,1-16,0V144l-3.2,2.4a8.1,8.1,0,0,1-11.2-1.6,8,8,0,0,1,1.6-11.2l16-12A8,8,0,0,1,168,128ZM208,80H48V48H72v8a8,8,0,0,0,16,0V48h80v8a8,8,0,0,0,16,0V48h24Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M208,34H182V24a6,6,0,0,0-12,0V34H86V24a6,6,0,0,0-12,0V34H48A14,14,0,0,0,34,48V208a14,14,0,0,0,14,14H208a14,14,0,0,0,14-14V48A14,14,0,0,0,208,34ZM48,46H74V56a6,6,0,0,0,12,0V46h84V56a6,6,0,0,0,12,0V46h26a2,2,0,0,1,2,2V82H46V48A2,2,0,0,1,48,46ZM208,210H48a2,2,0,0,1-2-2V94H210V208A2,2,0,0,1,208,210Zm-82-46a22.1,22.1,0,0,1-22,22,21.8,21.8,0,0,1-15.5-6.4,6,6,0,0,1-.1-8.5,5.9,5.9,0,0,1,8.5,0A9.9,9.9,0,0,0,104,174a10,10,0,0,0,0-20,6,6,0,0,1-5.4-3.4,6.2,6.2,0,0,1,.7-6.4l8.2-10.2H92a6,6,0,0,1,0-12h28a6,6,0,0,1,4.7,9.7l-10.3,12.9A22,22,0,0,1,126,164Zm40-36v52a6,6,0,0,1-12,0V140l-6.4,4.8a6,6,0,0,1-8.4-1.2,5.9,5.9,0,0,1,1.2-8.4l16-12A6,6,0,0,1,166,128Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M208,36H180V24a4,4,0,0,0-8,0V36H84V24a4,4,0,0,0-8,0V36H48A12,12,0,0,0,36,48V208a12,12,0,0,0,12,12H208a12,12,0,0,0,12-12V48A12,12,0,0,0,208,36ZM48,44H76V56a4,4,0,0,0,8,0V44h88V56a4,4,0,0,0,8,0V44h28a4,4,0,0,1,4,4V84H44V48A4,4,0,0,1,48,44ZM208,212H48a4,4,0,0,1-4-4V92H212V208A4,4,0,0,1,208,212Zm-84-48a20.1,20.1,0,0,1-20,20,19.7,19.7,0,0,1-14.1-5.9,4,4,0,0,1,5.6-5.6A11.9,11.9,0,0,0,104,176a12,12,0,0,0,0-24,4.1,4.1,0,0,1-3.6-2.3,3.8,3.8,0,0,1,.5-4.2L111.7,132H92a4,4,0,0,1,0-8h28a4.1,4.1,0,0,1,3.6,2.3,3.8,3.8,0,0,1-.5,4.2l-11.9,14.9A19.9,19.9,0,0,1,124,164Zm40-36v52a4,4,0,0,1-8,0V136l-9.6,7.2a4,4,0,1,1-4.8-6.4l16-12a4,4,0,0,1,4.2-.4A4.2,4.2,0,0,1,164,128Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M208,32H184V24a8,8,0,0,0-16,0v8H88V24a8,8,0,0,0-16,0v8H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM48,48H72v8a8,8,0,0,0,16,0V48h80v8a8,8,0,0,0,16,0V48h24V80H48ZM208,208H48V96H208V208Zm-80-44a24,24,0,0,1-41,17,8,8,0,0,1,0-11.3,7.9,7.9,0,0,1,11.3,0A8.3,8.3,0,0,0,104,172a8,8,0,0,0,0-16,8,8,0,0,1-6.2-13l5.6-7H92a8,8,0,0,1,0-16h28a8,8,0,0,1,6.2,13l-8.8,11.1A23.9,23.9,0,0,1,128,164Zm40-36v52a8,8,0,0,1-16,0V144l-3.2,2.4a8.1,8.1,0,0,1-11.2-1.6,8,8,0,0,1,1.6-11.2l16-12A8,8,0,0,1,168,128Z" />
        </>
      )}
    </svg>
  );
}

export function PhCameraRotate(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M208,52H182.4L170,33.3A12.1,12.1,0,0,0,160,28H96a12.1,12.1,0,0,0-10,5.3L73.6,52H48A28.1,28.1,0,0,0,20,80V192a28.1,28.1,0,0,0,28,28H208a28.1,28.1,0,0,0,28-28V80A28.1,28.1,0,0,0,208,52Zm4,140a4,4,0,0,1-4,4H48a4,4,0,0,1-4-4V80a4,4,0,0,1,4-4H80a12.1,12.1,0,0,0,10-5.3L102.4,52h51.2L166,70.7A12.1,12.1,0,0,0,176,76h32a4,4,0,0,1,4,4Zm-47.2-40.2a12,12,0,0,1,0,17,52,52,0,0,1-72.9.6v.6A11.9,11.9,0,0,1,80,180l-2-.2A11.9,11.9,0,0,1,68.2,166l4-24a11.9,11.9,0,0,1,9.8-9.8l24-4a12,12,0,0,1,4,23.6l-1.5.3a28,28,0,0,0,39.3-.3A12,12,0,0,1,164.8,151.8Zm23-53.8-4,24a11.9,11.9,0,0,1-9.8,9.8l-24,4-2,.2a12,12,0,0,1-2-23.8l1.5-.3a28,28,0,0,0-39.3.3,12,12,0,0,1-17-17,52,52,0,0,1,72.9-.6V94a12,12,0,0,1,23.6,4Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M224,80V192a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V80A16,16,0,0,1,48,64H80L96,40h64l16,24h32A16,16,0,0,1,224,80Z"
            opacity="0.2"
          />
          <path d="M208,56H180.3L166.7,35.6A7.9,7.9,0,0,0,160,32H96a7.9,7.9,0,0,0-6.7,3.6L75.7,56H48A24.1,24.1,0,0,0,24,80V192a24.1,24.1,0,0,0,24,24H208a24.1,24.1,0,0,0,24-24V80A24.1,24.1,0,0,0,208,56Zm8,136a8,8,0,0,1-8,8H48a8,8,0,0,1-8-8V80a8,8,0,0,1,8-8H80a7.9,7.9,0,0,0,6.7-3.6L100.3,48h55.4l13.6,20.4A7.9,7.9,0,0,0,176,72h32a8,8,0,0,1,8,8Zm-54.1-37.4a7.9,7.9,0,0,1,0,11.3,47.8,47.8,0,0,1-67.8,0H94L89.3,161l-1.4,8.3A8.1,8.1,0,0,1,80,176H78.7a8,8,0,0,1-6.6-9.2l4-24a8.1,8.1,0,0,1,6.6-6.6l24-4a8,8,0,1,1,2.6,15.8l-9,1.5,5.1,5.3A32,32,0,0,0,128,164a31.5,31.5,0,0,0,22.6-9.4A8,8,0,0,1,161.9,154.6Zm22-57.3-4,24a8.1,8.1,0,0,1-6.6,6.6l-24,4H148a8.1,8.1,0,0,1-7.9-6.7,8,8,0,0,1,6.6-9.2l9-1.5-5.1-5.3A32,32,0,0,0,128,100a31.5,31.5,0,0,0-22.6,9.4,8,8,0,0,1-11.3,0,7.9,7.9,0,0,1,0-11.3,47.8,47.8,0,0,1,67.8,0c.1,0,.1,0,.1.1l4.7,4.8,1.4-8.3a8,8,0,0,1,15.8,2.6Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M208,56H180.3L166.7,35.6A7.9,7.9,0,0,0,160,32H96a7.9,7.9,0,0,0-6.7,3.6L75.7,56H48A24.1,24.1,0,0,0,24,80V192a24.1,24.1,0,0,0,24,24H208a24.1,24.1,0,0,0,24-24V80A24.1,24.1,0,0,0,208,56ZM161.9,165.9a47.8,47.8,0,0,1-67.8,0H94L89.3,161l-1.4,8.3A8.1,8.1,0,0,1,80,176H78.7a8,8,0,0,1-6.6-9.2l4-24a8.1,8.1,0,0,1,6.6-6.6l24-4a8,8,0,1,1,2.6,15.8l-9,1.5,5.1,5.3A32,32,0,0,0,128,164a31.5,31.5,0,0,0,22.6-9.4,8,8,0,0,1,11.3,0A7.9,7.9,0,0,1,161.9,165.9Zm22-68.6-4,24a8.1,8.1,0,0,1-6.6,6.6l-24,4H148a8.1,8.1,0,0,1-7.9-6.7,8,8,0,0,1,6.6-9.2l9-1.5-5.1-5.3A32,32,0,0,0,128,100a31.5,31.5,0,0,0-22.6,9.4,8,8,0,0,1-11.3,0,7.9,7.9,0,0,1,0-11.3,47.8,47.8,0,0,1,67.8,0c.1,0,.1,0,.1.1l4.7,4.8,1.4-8.3a8,8,0,0,1,15.8,2.6Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M208,58H179.2L165,36.7a6,6,0,0,0-5-2.7H96a6,6,0,0,0-5,2.7L76.8,58H48A22.1,22.1,0,0,0,26,80V192a22.1,22.1,0,0,0,22,22H208a22.1,22.1,0,0,0,22-22V80A22.1,22.1,0,0,0,208,58Zm10,134a10,10,0,0,1-10,10H48a10,10,0,0,1-10-10V80A10,10,0,0,1,48,70H80a6,6,0,0,0,5-2.7L99.2,46h57.6L171,67.3a6,6,0,0,0,5,2.7h32a10,10,0,0,1,10,10Zm-57.5-36a5.9,5.9,0,0,1,0,8.5,45.9,45.9,0,0,1-65,0h-.1L88,156.7,85.9,169a6,6,0,0,1-5.9,5H79a5.9,5.9,0,0,1-4.9-6.9l4-24a5.9,5.9,0,0,1,4.9-4.9l24-4a6,6,0,0,1,2,11.8L96.2,148l7.8,8.1a33.9,33.9,0,0,0,48-.1A6.1,6.1,0,0,1,160.5,156Zm21.4-59-4,24a5.9,5.9,0,0,1-4.9,4.9l-24,4h-1a6,6,0,0,1-5.9-5,5.9,5.9,0,0,1,4.9-6.9l12.8-2.1-7.8-8.1a33.9,33.9,0,0,0-48,.1,6.1,6.1,0,0,1-8.5,0,5.9,5.9,0,0,1,0-8.5,45.9,45.9,0,0,1,65,0h.1l7.4,7.8L170.1,95a6,6,0,0,1,11.8,2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M208,60H178.1L163.3,37.8A4,4,0,0,0,160,36H96a4,4,0,0,0-3.3,1.8L77.9,60H48A20.1,20.1,0,0,0,28,80V192a20.1,20.1,0,0,0,20,20H208a20.1,20.1,0,0,0,20-20V80A20.1,20.1,0,0,0,208,60Zm12,132a12,12,0,0,1-12,12H48a12,12,0,0,1-12-12V80A12,12,0,0,1,48,68H80a4,4,0,0,0,3.3-1.8L98.1,44h59.8l14.8,22.2A4,4,0,0,0,176,68h32a12,12,0,0,1,12,12Zm-60.9-34.5a3.9,3.9,0,0,1,0,5.6,43.9,43.9,0,0,1-62.2,0L86.6,152.5l-2.7,16.2A4,4,0,0,1,80,172h-.7a3.9,3.9,0,0,1-3.2-4.6l4-24a3.8,3.8,0,0,1,3.2-3.2l24-4a4,4,0,0,1,1.4,7.8l-16.5,2.8,10.4,10.8A35.4,35.4,0,0,0,128,168a35.9,35.9,0,0,0,25.5-10.5A3.9,3.9,0,0,1,159.1,157.5Zm20.8-60.8-4,24a3.8,3.8,0,0,1-3.2,3.2l-24,4H148a4,4,0,0,1-3.9-3.3,3.9,3.9,0,0,1,3.2-4.6l16.5-2.8-10.4-10.8A35.4,35.4,0,0,0,128,96a35.9,35.9,0,0,0-25.5,10.5,4,4,0,0,1-5.6-5.6,43.9,43.9,0,0,1,62.2,0l10.3,10.6,2.7-16.2a4,4,0,0,1,7.8,1.4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M208,56H180.3L166.7,35.6A7.9,7.9,0,0,0,160,32H96a7.9,7.9,0,0,0-6.7,3.6L75.7,56H48A24.1,24.1,0,0,0,24,80V192a24.1,24.1,0,0,0,24,24H208a24.1,24.1,0,0,0,24-24V80A24.1,24.1,0,0,0,208,56Zm8,136a8,8,0,0,1-8,8H48a8,8,0,0,1-8-8V80a8,8,0,0,1,8-8H80a7.9,7.9,0,0,0,6.7-3.6L100.3,48h55.4l13.6,20.4A7.9,7.9,0,0,0,176,72h32a8,8,0,0,1,8,8Zm-54.1-37.4a7.9,7.9,0,0,1,0,11.3,47.8,47.8,0,0,1-67.8,0H94L89.3,161l-1.4,8.3A8.1,8.1,0,0,1,80,176H78.7a8,8,0,0,1-6.6-9.2l4-24a8.1,8.1,0,0,1,6.6-6.6l24-4a8,8,0,1,1,2.6,15.8l-9,1.5,5.1,5.3A32,32,0,0,0,128,164a31.5,31.5,0,0,0,22.6-9.4A8,8,0,0,1,161.9,154.6Zm22-57.3-4,24a8.1,8.1,0,0,1-6.6,6.6l-24,4H148a8.1,8.1,0,0,1-7.9-6.7,8,8,0,0,1,6.6-9.2l9-1.5-5.1-5.3A32,32,0,0,0,128,100a31.5,31.5,0,0,0-22.6,9.4,8,8,0,0,1-11.3,0,7.9,7.9,0,0,1,0-11.3,47.8,47.8,0,0,1,67.8,0c.1,0,.1,0,.1.1l4.7,4.8,1.4-8.3a8,8,0,0,1,15.8,2.6Z" />
        </>
      )}
    </svg>
  );
}

export function PhCameraSlash(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M42.3,15.9A12,12,0,1,0,24.6,32.1L43.1,52.4A28.1,28.1,0,0,0,20,80V192a28.1,28.1,0,0,0,28,28H195.4l18.3,20.1a11.8,11.8,0,0,0,8.8,3.9,12,12,0,0,0,8.9-20.1Zm63.9,106L136,154.6a23.6,23.6,0,0,1-8,1.4,24.1,24.1,0,0,1-24-24A24.4,24.4,0,0,1,106.2,121.9ZM48,196a4,4,0,0,1-4-4V80a4,4,0,0,1,4-4H64.5l24.9,27.4A48.1,48.1,0,0,0,128,180a49,49,0,0,0,24.8-6.9L173.6,196ZM236,80v94.1a12,12,0,1,1-24,0V80a4,4,0,0,0-4-4H176a11.9,11.9,0,0,1-10-5.4L153.6,52H102.1a12,12,0,0,1,0-24H160a12.1,12.1,0,0,1,10,5.3L182.4,52H208A28.1,28.1,0,0,1,236,80Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M208,64H176L160,40H96L80,64H48A16,16,0,0,0,32,80V192a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V80A16,16,0,0,0,208,64ZM128,168a36,36,0,1,1,36-36A36,36,0,0,1,128,168Z" />
          </g>
          <path d="M39.4,18.6a7.9,7.9,0,0,0-11.3-.5,8,8,0,0,0-.6,11.3L51.7,56H48A24.1,24.1,0,0,0,24,80V192a24.1,24.1,0,0,0,24,24H197.2l19.4,21.4a8.2,8.2,0,0,0,5.9,2.6,7.8,7.8,0,0,0,5.4-2.1,8,8,0,0,0,.6-11.3Zm66.2,96.6,37,40.7A27.8,27.8,0,0,1,128,160a28,28,0,0,1-22.4-44.8ZM48,200a8,8,0,0,1-8-8V80a8,8,0,0,1,8-8H66.3l28.4,31.2a44,44,0,0,0,58.7,64.7L182.6,200Z" />
          <path d="M208,56H180.3L166.6,35.6A7.6,7.6,0,0,0,160,32H96a7.9,7.9,0,0,0-6.7,3.6l-2,3a8.1,8.1,0,0,0,2.2,11.1A8,8,0,0,0,100.2,48h55.5l13.6,20.4A7.9,7.9,0,0,0,176,72h32a8,8,0,0,1,8,8V186a8,8,0,0,0,16,0V80A24.1,24.1,0,0,0,208,56Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M39.4,18.6a7.9,7.9,0,0,0-11.3-.5,8,8,0,0,0-.6,11.3L51.7,56H48A24.1,24.1,0,0,0,24,80V192a24.1,24.1,0,0,0,24,24H197.2l19.4,21.4a8.2,8.2,0,0,0,5.9,2.6,7.8,7.8,0,0,0,5.4-2.1,8,8,0,0,0,.6-11.3ZM128,168a36,36,0,0,1-36-36,36.4,36.4,0,0,1,8.1-22.8L148,161.9A35.3,35.3,0,0,1,128,168ZM232,80V186a8,8,0,0,1-5.1,7.5,9.8,9.8,0,0,1-2.9.5,7.9,7.9,0,0,1-5.9-2.6l-130-143a8,8,0,0,1-.8-9.8l2-3A7.9,7.9,0,0,1,96,32h64a7.6,7.6,0,0,1,6.6,3.6L180.3,56H208A24.1,24.1,0,0,1,232,80Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M37.9,20a5.9,5.9,0,0,0-8.5-.4A6,6,0,0,0,29,28L56.3,58H48A22.1,22.1,0,0,0,26,80V192a22.1,22.1,0,0,0,22,22H198.1l20,22a6,6,0,0,0,4.4,2,5.9,5.9,0,0,0,4.1-1.6,6,6,0,0,0,.4-8.4Zm67.6,92.2,40.1,44.1a30,30,0,0,1-40.1-44.1ZM48,202a10,10,0,0,1-10-10V80A10,10,0,0,1,48,70H67.2l30.2,33.2A42,42,0,0,0,128,174a41.4,41.4,0,0,0,25.7-8.8L187.2,202ZM230,80V186a6,6,0,0,1-12,0V80a10,10,0,0,0-10-10H176a6,6,0,0,1-5-2.7L156.8,46H99.2l-.2.3a6,6,0,1,1-10-6.6l2-3A6,6,0,0,1,96,34h64a6,6,0,0,1,5,2.7L179.2,58H208A22.1,22.1,0,0,1,230,80Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M36.4,21.3a4,4,0,1,0-5.9,5.4L60.8,60H48A20.1,20.1,0,0,0,28,80V192a20.1,20.1,0,0,0,20,20H199l20.6,22.7a3.9,3.9,0,0,0,2.9,1.3,4.3,4.3,0,0,0,2.7-1,4,4,0,0,0,.3-5.7ZM148.5,156.5A31.2,31.2,0,0,1,128,164a32.1,32.1,0,0,1-32-32,31.7,31.7,0,0,1,9.5-22.8ZM48,204a12,12,0,0,1-12-12V80A12,12,0,0,1,48,68H68l32.1,35.3a40,40,0,0,0,53.8,59.2L191.7,204ZM228,80V186a4,4,0,0,1-8,0V80a12,12,0,0,0-12-12H176a4,4,0,0,1-3.3-1.8L157.9,44H98.1l-.8,1.2a4,4,0,0,1-6.6-4.4l2-3A4,4,0,0,1,96,36h64a4,4,0,0,1,3.3,1.8L178.1,60H208A20.1,20.1,0,0,1,228,80Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M39.4,18.6a7.9,7.9,0,0,0-11.3-.5,8,8,0,0,0-.6,11.3L51.7,56H48A24.1,24.1,0,0,0,24,80V192a24.1,24.1,0,0,0,24,24H197.2l19.4,21.4a8.2,8.2,0,0,0,5.9,2.6,7.8,7.8,0,0,0,5.4-2.1,8,8,0,0,0,.6-11.3Zm66.2,96.6,37,40.7A27.8,27.8,0,0,1,128,160a28,28,0,0,1-22.4-44.8ZM48,200a8,8,0,0,1-8-8V80a8,8,0,0,1,8-8H66.3l28.4,31.2a44,44,0,0,0,58.7,64.7L182.6,200ZM232,80V186a8,8,0,0,1-16,0V80a8,8,0,0,0-8-8H176a7.9,7.9,0,0,1-6.7-3.6L155.7,48H100.2a8,8,0,0,1-10.7,1.7,8.1,8.1,0,0,1-2.2-11.1l2-3A7.9,7.9,0,0,1,96,32h64a7.6,7.6,0,0,1,6.6,3.6L180.3,56H208A24.1,24.1,0,0,1,232,80Z" />
        </>
      )}
    </svg>
  );
}

export function PhCamera(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M208,52H182.4L170,33.3A12.1,12.1,0,0,0,160,28H96a12.1,12.1,0,0,0-10,5.3L73.6,52H48A28.1,28.1,0,0,0,20,80V192a28.1,28.1,0,0,0,28,28H208a28.1,28.1,0,0,0,28-28V80A28.1,28.1,0,0,0,208,52Zm4,140a4,4,0,0,1-4,4H48a4,4,0,0,1-4-4V80a4,4,0,0,1,4-4H80a12.1,12.1,0,0,0,10-5.3L102.4,52h51.2L166,70.7A12.1,12.1,0,0,0,176,76h32a4,4,0,0,1,4,4ZM128,84a48,48,0,1,0,48,48A48,48,0,0,0,128,84Zm0,72a24,24,0,1,1,24-24A24.1,24.1,0,0,1,128,156Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M208,64H176L160,40H96L80,64H48A16,16,0,0,0,32,80V192a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V80A16,16,0,0,0,208,64ZM128,168a36,36,0,1,1,36-36A36,36,0,0,1,128,168Z" />
          </g>
          <path d="M208,56H180.3L166.7,35.6A7.9,7.9,0,0,0,160,32H96a7.9,7.9,0,0,0-6.7,3.6L75.7,56H48A24.1,24.1,0,0,0,24,80V192a24.1,24.1,0,0,0,24,24H208a24.1,24.1,0,0,0,24-24V80A24.1,24.1,0,0,0,208,56Zm8,136a8,8,0,0,1-8,8H48a8,8,0,0,1-8-8V80a8,8,0,0,1,8-8H80a7.9,7.9,0,0,0,6.7-3.6L100.3,48h55.4l13.6,20.4A7.9,7.9,0,0,0,176,72h32a8,8,0,0,1,8,8Z" />
          <path d="M128,88a44,44,0,1,0,44,44A44,44,0,0,0,128,88Zm0,72a28,28,0,1,1,28-28A28.1,28.1,0,0,1,128,160Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M208,56H180.3L166.7,35.6A7.9,7.9,0,0,0,160,32H96a7.9,7.9,0,0,0-6.7,3.6L75.7,56H48A24.1,24.1,0,0,0,24,80V192a24.1,24.1,0,0,0,24,24H208a24.1,24.1,0,0,0,24-24V80A24.1,24.1,0,0,0,208,56Zm-44,76a36,36,0,1,1-36-36A36,36,0,0,1,164,132Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M208,58H179.2L165,36.7a6,6,0,0,0-5-2.7H96a6,6,0,0,0-5,2.7L76.8,58H48A22.1,22.1,0,0,0,26,80V192a22.1,22.1,0,0,0,22,22H208a22.1,22.1,0,0,0,22-22V80A22.1,22.1,0,0,0,208,58Zm10,134a10,10,0,0,1-10,10H48a10,10,0,0,1-10-10V80A10,10,0,0,1,48,70H80a6,6,0,0,0,5-2.7L99.2,46h57.6L171,67.3a6,6,0,0,0,5,2.7h32a10,10,0,0,1,10,10ZM128,90a42,42,0,1,0,42,42A42,42,0,0,0,128,90Zm0,72a30,30,0,1,1,30-30A30.1,30.1,0,0,1,128,162Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M208,60H178.1L163.3,37.8A4,4,0,0,0,160,36H96a4,4,0,0,0-3.3,1.8L77.9,60H48A20.1,20.1,0,0,0,28,80V192a20.1,20.1,0,0,0,20,20H208a20.1,20.1,0,0,0,20-20V80A20.1,20.1,0,0,0,208,60Zm12,132a12,12,0,0,1-12,12H48a12,12,0,0,1-12-12V80A12,12,0,0,1,48,68H80a4,4,0,0,0,3.3-1.8L98.1,44h59.8l14.8,22.2A4,4,0,0,0,176,68h32a12,12,0,0,1,12,12ZM128,92a40,40,0,1,0,40,40A40,40,0,0,0,128,92Zm0,72a32,32,0,1,1,32-32A32.1,32.1,0,0,1,128,164Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M208,56H180.3L166.7,35.6A7.9,7.9,0,0,0,160,32H96a7.9,7.9,0,0,0-6.7,3.6L75.7,56H48A24.1,24.1,0,0,0,24,80V192a24.1,24.1,0,0,0,24,24H208a24.1,24.1,0,0,0,24-24V80A24.1,24.1,0,0,0,208,56Zm8,136a8,8,0,0,1-8,8H48a8,8,0,0,1-8-8V80a8,8,0,0,1,8-8H80a7.9,7.9,0,0,0,6.7-3.6L100.3,48h55.4l13.6,20.4A7.9,7.9,0,0,0,176,72h32a8,8,0,0,1,8,8ZM128,88a44,44,0,1,0,44,44A44,44,0,0,0,128,88Zm0,72a28,28,0,1,1,28-28A28.1,28.1,0,0,1,128,160Z" />
        </>
      )}
    </svg>
  );
}

export function PhCampfire(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M227.4,227.6A12,12,0,0,1,216,236a11.3,11.3,0,0,1-3.6-.6L128,208.6,43.6,235.4a11.3,11.3,0,0,1-3.6.6,12,12,0,0,1-3.6-23.4l52-16.6-52-16.6a12,12,0,1,1,7.2-22.8L128,183.4l84.4-26.8a12,12,0,1,1,7.2,22.8l-52,16.6,52,16.6A11.9,11.9,0,0,1,227.4,227.6ZM64,108c0-50.1,55.4-84.8,57.7-86.2a11.9,11.9,0,0,1,12.6,0C136.6,23.2,192,57.9,192,108a64,64,0,0,1-128,0Zm64,40a12,12,0,0,0,12-12c0-10.3-6.6-19-12-24.3-5.4,5.3-12,14-12,24.3A12,12,0,0,0,128,148ZM88,108a41,41,0,0,0,4.8,19.1c4.7-24.9,27.5-40.4,28.5-41.1a12.1,12.1,0,0,1,13.4,0c1,.7,23.8,16.2,28.5,41.1A41,41,0,0,0,168,108c0-10.3-3.9-26.7-22.6-46.2A160.6,160.6,0,0,0,128,46.6C115.4,56,88,79.7,88,108Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M180,108a52,52,0,0,1-52,52,23.9,23.9,0,0,0,24-24c0-24-24-40-24-40s-24,16-24,40a23.9,23.9,0,0,0,24,24,52,52,0,0,1-52-52c0-44,52-76,52-76S180,64,180,108Z"
            opacity="0.2"
          />
          <path d="M128,168a60,60,0,0,0,60-60c0-47.9-53.5-81.4-55.8-82.8a7.9,7.9,0,0,0-8.4,0A153.5,153.5,0,0,0,96.2,48C77.8,67.1,68,87.9,68,108A60,60,0,0,0,128,168Zm0-16a16,16,0,0,1-16-16c0-13.6,10-24.5,16-29.8,6,5.3,16,16.2,16,29.8A16,16,0,0,1,128,152Zm0-110.3C139.7,50,172,76,172,108a43.5,43.5,0,0,1-12.1,30.2,15.5,15.5,0,0,0,.1-2.2c0-28-26.4-45.9-27.6-46.7a8.2,8.2,0,0,0-8.8,0C122.4,90.1,96,108,96,136a15.5,15.5,0,0,0,.1,2.2A43.5,43.5,0,0,1,84,108C84,76,116.3,50,128,41.7Zm95.6,184.7A8,8,0,0,1,216,232a6.7,6.7,0,0,1-2.4-.4L128,204.4,42.4,231.6a6.7,6.7,0,0,1-2.4.4,8,8,0,0,1-7.6-5.6,7.9,7.9,0,0,1,5.2-10l64-20.4-64-20.4a8,8,0,1,1,4.8-15.2L128,187.6l85.6-27.2a8,8,0,1,1,4.8,15.2l-64,20.4,64,20.4A7.9,7.9,0,0,1,223.6,226.4Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M132.2,25.2a7.9,7.9,0,0,0-8.4,0A153.5,153.5,0,0,0,96.2,48C77.8,67.1,68,87.9,68,108a60,60,0,0,0,120,0C188,60.1,134.5,26.6,132.2,25.2ZM128,152a23.9,23.9,0,0,1-24-24c0-24,24-40,24-40s24,16,24,40A23.9,23.9,0,0,1,128,152Zm95.6,74.4A8,8,0,0,1,216,232a6.7,6.7,0,0,1-2.4-.4L128,204.4,42.4,231.6a6.7,6.7,0,0,1-2.4.4,8,8,0,0,1-7.6-5.6,7.9,7.9,0,0,1,5.2-10l64-20.4-64-20.4a8,8,0,1,1,4.8-15.2L128,187.6l85.6-27.2a8,8,0,1,1,4.8,15.2l-64,20.4,64,20.4A7.9,7.9,0,0,1,223.6,226.4Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M221.7,225.8A6,6,0,0,1,216,230a5.7,5.7,0,0,1-1.8-.3L128,202.3,41.8,229.7a5.7,5.7,0,0,1-1.8.3,6,6,0,0,1-1.8-11.7l70-22.3-70-22.3a6,6,0,1,1,3.6-11.4L128,189.7l86.2-27.4a6,6,0,1,1,3.6,11.4l-70,22.3,70,22.3A6,6,0,0,1,221.7,225.8ZM70,108c0-46.8,52.6-79.7,54.9-81.1a5.8,5.8,0,0,1,6.2,0C133.4,28.3,186,61.2,186,108a58,58,0,0,1-116,0Zm58,46a18.1,18.1,0,0,0,18-18c0-15.5-12-27.4-18-32.4-6,5-18,17-18,32.4A18.1,18.1,0,0,0,128,154ZM82,108a45.9,45.9,0,0,0,17,35.7,28,28,0,0,1-1-7.7c0-26.9,25.6-44.3,26.7-45a6.1,6.1,0,0,1,6.6,0c1.1.7,26.7,18.1,26.7,45a28,28,0,0,1-1,7.7A45.9,45.9,0,0,0,174,108c0-34.1-35.1-61.2-46-68.8C117.2,46.8,82,73.9,82,108Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M219.8,225.2A4,4,0,0,1,216,228l-1.2-.2L128,200.2,41.2,227.8,40,228a4,4,0,0,1-1.2-7.8l76-24.2-76-24.2a4,4,0,1,1,2.4-7.6L128,191.8l86.8-27.6a4,4,0,1,1,2.4,7.6l-76,24.2,76,24.2A4,4,0,0,1,219.8,225.2ZM72,108c0-19,9.4-38.9,27.1-57.3a150.8,150.8,0,0,1,26.8-22.1,4,4,0,0,1,4.2,0,150.8,150.8,0,0,1,26.8,22.1C174.6,69.1,184,89,184,108a56.1,56.1,0,0,1-54.6,56h-2.8A56.1,56.1,0,0,1,72,108Zm56,48a20.1,20.1,0,0,0,20-20c0-17.4-14.4-30.5-20-35-5.6,4.5-20,17.6-20,35A20.1,20.1,0,0,0,128,156ZM80,108a47.9,47.9,0,0,0,23.3,41.1A27.7,27.7,0,0,1,100,136c0-25.8,24.7-42.6,25.8-43.3a3.9,3.9,0,0,1,4.4,0c1.1.7,25.8,17.5,25.8,43.3a27.7,27.7,0,0,1-3.3,13.1A47.9,47.9,0,0,0,176,108c0-36.4-38.5-64.8-48-71.2C118.5,43.2,80,71.7,80,108Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M223.6,226.4A8,8,0,0,1,216,232a6.7,6.7,0,0,1-2.4-.4L128,204.4,42.4,231.6a6.7,6.7,0,0,1-2.4.4,8,8,0,0,1-7.6-5.6,7.9,7.9,0,0,1,5.2-10l64-20.4-64-20.4a8,8,0,1,1,4.8-15.2L128,187.6l85.6-27.2a8,8,0,1,1,4.8,15.2l-64,20.4,64,20.4A7.9,7.9,0,0,1,223.6,226.4ZM68,108c0-20.1,9.8-40.9,28.2-60a153.5,153.5,0,0,1,27.6-22.8,7.9,7.9,0,0,1,8.4,0C134.5,26.6,188,60.1,188,108a60,60,0,0,1-120,0Zm60,44a16,16,0,0,0,16-16c0-13.6-10-24.5-16-29.8-6,5.3-16,16.2-16,29.8A16,16,0,0,0,128,152ZM84,108a43.5,43.5,0,0,0,12.1,30.2A15.5,15.5,0,0,1,96,136c0-28,26.4-45.9,27.6-46.7a8.2,8.2,0,0,1,8.8,0c1.2.8,27.6,18.7,27.6,46.7a15.5,15.5,0,0,1-.1,2.2A43.5,43.5,0,0,0,172,108c0-32-32.3-58-44-66.3C116.3,50,84,76,84,108Z" />
        </>
      )}
    </svg>
  );
}

export function PhCarSimple(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M240,108h-8.2L205.1,47.9A20.1,20.1,0,0,0,186.8,36H69.2A20.1,20.1,0,0,0,50.9,47.9L24.2,108H16a12,12,0,0,0,0,24h4v76a20.1,20.1,0,0,0,20,20H64a20.1,20.1,0,0,0,20-20V196h88v12a20.1,20.1,0,0,0,20,20h24a20.1,20.1,0,0,0,20-20V132h4a12,12,0,0,0,0-24ZM71.8,60H184.2l21.3,48H50.5ZM212,204H196V184a12,12,0,0,0-12-12H72a12,12,0,0,0-12,12v20H44V132H212Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M32,120,61.9,52.8A8,8,0,0,1,69.2,48H186.8a8,8,0,0,1,7.3,4.8L224,120Z" />
          </g>
          <path d="M240,112H229.2L201.4,49.5A15.9,15.9,0,0,0,186.8,40H69.2a15.9,15.9,0,0,0-14.6,9.5L26.8,112H16a8,8,0,0,0,0,16h8v80a16,16,0,0,0,16,16H64a16,16,0,0,0,16-16V192h96v16a16,16,0,0,0,16,16h24a16,16,0,0,0,16-16V128h8a8,8,0,0,0,0-16ZM69.2,56H186.8l24.9,56H44.3ZM216,208H192V184a8,8,0,0,0-8-8H72a8,8,0,0,0-8,8v24H40V128H216Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M240,112H229.2L201.4,49.5A15.9,15.9,0,0,0,186.8,40H69.2a15.9,15.9,0,0,0-14.6,9.5L26.8,112H16a8,8,0,0,0,0,16h8v80a16,16,0,0,0,16,16H64a16,16,0,0,0,16-16V192h96v16a16,16,0,0,0,16,16h24a16,16,0,0,0,16-16V128h8a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M240,114H227.9L199.6,50.3A14.2,14.2,0,0,0,186.8,42H69.2a14.2,14.2,0,0,0-12.8,8.3L28.1,114H16a6,6,0,0,0,0,12H26v82a14,14,0,0,0,14,14H64a14,14,0,0,0,14-14V190H178v18a14,14,0,0,0,14,14h24a14,14,0,0,0,14-14V126h10a6,6,0,0,0,0-12ZM67.4,55.2A2,2,0,0,1,69.2,54H186.8a2,2,0,0,1,1.8,1.2L214.8,114H41.2ZM218,208a2,2,0,0,1-2,2H192a2,2,0,0,1-2-2V184a6,6,0,0,0-6-6H72a6,6,0,0,0-6,6v24a2,2,0,0,1-2,2H40a2,2,0,0,1-2-2V126H218Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M240,116H226.6L197.8,51.1a12.2,12.2,0,0,0-11-7.1H69.2a12.2,12.2,0,0,0-11,7.1L29.4,116H16a4,4,0,0,0,0,8H28v84a12,12,0,0,0,12,12H64a12,12,0,0,0,12-12V188H180v20a12,12,0,0,0,12,12h24a12,12,0,0,0,12-12V124h12a4,4,0,0,0,0-8ZM65.5,54.4A4,4,0,0,1,69.2,52H186.8a4,4,0,0,1,3.7,2.4L217.8,116H38.2ZM220,208a4,4,0,0,1-4,4H192a4,4,0,0,1-4-4V184a4,4,0,0,0-4-4H72a4,4,0,0,0-4,4v24a4,4,0,0,1-4,4H40a4,4,0,0,1-4-4V124H220Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M240,112H229.2L201.4,49.5A15.9,15.9,0,0,0,186.8,40H69.2a15.9,15.9,0,0,0-14.6,9.5L26.8,112H16a8,8,0,0,0,0,16h8v80a16,16,0,0,0,16,16H64a16,16,0,0,0,16-16V192h96v16a16,16,0,0,0,16,16h24a16,16,0,0,0,16-16V128h8a8,8,0,0,0,0-16ZM69.2,56H186.8l24.9,56H44.3ZM216,208H192V184a8,8,0,0,0-8-8H72a8,8,0,0,0-8,8v24H40V128H216Z" />
        </>
      )}
    </svg>
  );
}

export function PhCar(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M240,108h-8.2L205.1,47.9A20.1,20.1,0,0,0,186.8,36H69.2A20.1,20.1,0,0,0,50.9,47.9L24.2,108H16a12,12,0,0,0,0,24h4v76a20.1,20.1,0,0,0,20,20H64a20.1,20.1,0,0,0,20-20V188h88v20a20.1,20.1,0,0,0,20,20h24a20.1,20.1,0,0,0,20-20V132h4a12,12,0,0,0,0-24ZM71.8,60H184.2l21.3,48H50.5ZM60,204H44V188H60Zm136,0V188h16v16Zm16-40H44V132H212Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M224,184v24a8,8,0,0,1-8,8H192a8,8,0,0,1-8-8V184Z" />
          </g>
          <g opacity="0.2">
            <path d="M72,184v24a8,8,0,0,1-8,8H40a8,8,0,0,1-8-8V184Z" />
          </g>
          <g opacity="0.2">
            <path d="M224,120,194.1,52.8a8,8,0,0,0-7.3-4.8H69.2a8,8,0,0,0-7.3,4.8L32,120Z" />
          </g>
          <path d="M240,112H229.2L201.4,49.5A15.9,15.9,0,0,0,186.8,40H69.2a15.9,15.9,0,0,0-14.6,9.5L26.8,112H16a8,8,0,0,0,0,16h8v80a16,16,0,0,0,16,16H64a16,16,0,0,0,16-16V192h96v16a16,16,0,0,0,16,16h24a16,16,0,0,0,16-16V128h8a8,8,0,0,0,0-16ZM69.2,56H186.8l24.9,56H44.3ZM64,208H40V192H64Zm128,0V192h24v16Zm24-32H40V128H216Z" />
          <path d="M64,160H80a8,8,0,0,0,0-16H64a8,8,0,0,0,0,16Z" />
          <path d="M176,160h16a8,8,0,0,0,0-16H176a8,8,0,0,0,0,16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M240,112H229.2L201.4,49.5A15.9,15.9,0,0,0,186.8,40H69.2a15.9,15.9,0,0,0-14.6,9.5L26.8,112H16a8,8,0,0,0,0,16h8v80a16,16,0,0,0,16,16H64a16,16,0,0,0,16-16V192h96v16a16,16,0,0,0,16,16h24a16,16,0,0,0,16-16V128h8a8,8,0,0,0,0-16ZM80,160H64a8,8,0,0,1,0-16H80a8,8,0,0,1,0,16Zm112,0H176a8,8,0,0,1,0-16h16a8,8,0,0,1,0,16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M240,114H227.9L199.6,50.3A14.2,14.2,0,0,0,186.8,42H69.2a14.2,14.2,0,0,0-12.8,8.3L28.1,114H16a6,6,0,0,0,0,12H26v82a14,14,0,0,0,14,14H64a14,14,0,0,0,14-14V190H178v18a14,14,0,0,0,14,14h24a14,14,0,0,0,14-14V126h10a6,6,0,0,0,0-12ZM67.4,55.2A2,2,0,0,1,69.2,54H186.8a2,2,0,0,1,1.8,1.2L214.8,114H41.2ZM66,208a2,2,0,0,1-2,2H40a2,2,0,0,1-2-2V190H66Zm150,2H192a2,2,0,0,1-2-2V190h28v18A2,2,0,0,1,216,210Zm2-32H38V126H218ZM58,152a6,6,0,0,1,6-6H80a6,6,0,0,1,0,12H64A6,6,0,0,1,58,152Zm112,0a6,6,0,0,1,6-6h16a6,6,0,0,1,0,12H176A6,6,0,0,1,170,152Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M240,116H226.6L197.8,51.1a12.2,12.2,0,0,0-11-7.1H69.2a12.2,12.2,0,0,0-11,7.1L29.4,116H16a4,4,0,0,0,0,8H28v84a12,12,0,0,0,12,12H64a12,12,0,0,0,12-12V188H180v20a12,12,0,0,0,12,12h24a12,12,0,0,0,12-12V124h12a4,4,0,0,0,0-8ZM65.5,54.4A4,4,0,0,1,69.2,52H186.8a4,4,0,0,1,3.7,2.4L217.8,116H38.2ZM68,208a4,4,0,0,1-4,4H40a4,4,0,0,1-4-4V188H68Zm148,4H192a4,4,0,0,1-4-4V188h32v20A4,4,0,0,1,216,212Zm4-32H36V124H220ZM60,152a4,4,0,0,1,4-4H80a4,4,0,0,1,0,8H64A4,4,0,0,1,60,152Zm112,0a4,4,0,0,1,4-4h16a4,4,0,0,1,0,8H176A4,4,0,0,1,172,152Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M240,112H229.2L201.4,49.5A15.9,15.9,0,0,0,186.8,40H69.2a15.9,15.9,0,0,0-14.6,9.5L26.8,112H16a8,8,0,0,0,0,16h8v80a16,16,0,0,0,16,16H64a16,16,0,0,0,16-16V192h96v16a16,16,0,0,0,16,16h24a16,16,0,0,0,16-16V128h8a8,8,0,0,0,0-16ZM69.2,56H186.8l24.9,56H44.3ZM64,208H40V192H64Zm128,0V192h24v16Zm24-32H40V128H216ZM56,152a8,8,0,0,1,8-8H80a8,8,0,0,1,0,16H64A8,8,0,0,1,56,152Zm112,0a8,8,0,0,1,8-8h16a8,8,0,0,1,0,16H176A8,8,0,0,1,168,152Z" />
        </>
      )}
    </svg>
  );
}

export function PhCardholder(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M208,44H48A28.1,28.1,0,0,0,20,72V184a28.1,28.1,0,0,0,28,28H208a28.1,28.1,0,0,0,28-28V72A28.1,28.1,0,0,0,208,44ZM48,68H208a4,4,0,0,1,4,4V88H167.2a20,20,0,0,0-19.6,16,20,20,0,0,1-39.2,0A20,20,0,0,0,88.8,88H44V72A4,4,0,0,1,48,68ZM208,188H48a4,4,0,0,1-4-4V112H85.7a44,44,0,0,0,84.6,0H212v72A4,4,0,0,1,208,188Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M32,120H88.8a7.9,7.9,0,0,1,7.8,6.4,32.1,32.1,0,0,0,62.8,0,7.9,7.9,0,0,1,7.8-6.4H224V72a16,16,0,0,0-16-16H48A16,16,0,0,0,32,72Z" />
          </g>
          <path d="M208,48H48A24.1,24.1,0,0,0,24,72V184a24.1,24.1,0,0,0,24,24H208a24.1,24.1,0,0,0,24-24V72A24.1,24.1,0,0,0,208,48ZM40,96H216v16H167.2a16.1,16.1,0,0,0-15.7,12.8,24,24,0,0,1-47,0A16.1,16.1,0,0,0,88.8,112H40Zm8-32H208a8,8,0,0,1,8,8v8H40V72A8,8,0,0,1,48,64ZM208,192H48a8,8,0,0,1-8-8V128H88.8a40,40,0,0,0,78.4,0H216v56A8,8,0,0,1,208,192Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M208,48H48A24.1,24.1,0,0,0,24,72V184a24.1,24.1,0,0,0,24,24H208a24.1,24.1,0,0,0,24-24V72A24.1,24.1,0,0,0,208,48Zm8,64H167.2a16.1,16.1,0,0,0-15.7,12.8,24,24,0,0,1-47,0A16.1,16.1,0,0,0,88.8,112H40V96H216Zm0-32H40V72a8,8,0,0,1,8-8H208a8,8,0,0,1,8,8Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M208,50H48A22.1,22.1,0,0,0,26,72V184a22.1,22.1,0,0,0,22,22H208a22.1,22.1,0,0,0,22-22V72A22.1,22.1,0,0,0,208,50ZM38,94H218v20H167.2a14,14,0,0,0-13.7,11.2,26,26,0,0,1-51,0A14,14,0,0,0,88.8,114H38ZM48,62H208a10,10,0,0,1,10,10V82H38V72A10,10,0,0,1,48,62ZM208,194H48a10,10,0,0,1-10-10V126H88.8a2,2,0,0,1,2,1.6,38,38,0,0,0,74.4,0,2,2,0,0,1,2-1.6H218v58A10,10,0,0,1,208,194Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M208,52H48A20.1,20.1,0,0,0,28,72V184a20.1,20.1,0,0,0,20,20H208a20.1,20.1,0,0,0,20-20V72A20.1,20.1,0,0,0,208,52ZM36,92H220v24H167.2a12.1,12.1,0,0,0-11.8,9.6,28,28,0,0,1-54.8,0A12.1,12.1,0,0,0,88.8,116H36ZM48,60H208a12,12,0,0,1,12,12V84H36V72A12,12,0,0,1,48,60ZM208,196H48a12,12,0,0,1-12-12V124H88.8a4,4,0,0,1,3.9,3.2,36,36,0,0,0,70.6,0,4,4,0,0,1,3.9-3.2H220v60A12,12,0,0,1,208,196Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M208,48H48A24.1,24.1,0,0,0,24,72V184a24.1,24.1,0,0,0,24,24H208a24.1,24.1,0,0,0,24-24V72A24.1,24.1,0,0,0,208,48ZM40,96H216v16H167.2a16.1,16.1,0,0,0-15.7,12.8,24,24,0,0,1-47,0A16.1,16.1,0,0,0,88.8,112H40Zm8-32H208a8,8,0,0,1,8,8v8H40V72A8,8,0,0,1,48,64ZM208,192H48a8,8,0,0,1-8-8V128H88.8a40,40,0,0,0,78.4,0H216v56A8,8,0,0,1,208,192Z" />
        </>
      )}
    </svg>
  );
}

export function PhCards(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M180,72H36A20.1,20.1,0,0,0,16,92V204a20.1,20.1,0,0,0,20,20H180a20.1,20.1,0,0,0,20-20V92A20.1,20.1,0,0,0,180,72Zm-4,128H40V96H176ZM240,52V176a12,12,0,0,1-24,0V56H64a12,12,0,0,1,0-24H220A20.1,20.1,0,0,1,240,52Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect x="32" y="80" width="160" height="128" rx="8" />
          </g>
          <path d="M184,72H40A16,16,0,0,0,24,88V200a16,16,0,0,0,16,16H184a16,16,0,0,0,16-16V88A16,16,0,0,0,184,72Zm0,128H40V88H184V200Z" />
          <path d="M216,40H64a8,8,0,0,0,0,16H216V176a8,8,0,0,0,16,0V56A16,16,0,0,0,216,40Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M200,88V200a16,16,0,0,1-16,16H40a16,16,0,0,1-16-16V88A16,16,0,0,1,40,72H184A16,16,0,0,1,200,88Zm16-48H64a8,8,0,0,0,0,16H216V176a8,8,0,0,0,16,0V56A16,16,0,0,0,216,40Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M184,74H40A14,14,0,0,0,26,88V200a14,14,0,0,0,14,14H184a14,14,0,0,0,14-14V88A14,14,0,0,0,184,74Zm2,126a2,2,0,0,1-2,2H40a2,2,0,0,1-2-2V88a2,2,0,0,1,2-2H184a2,2,0,0,1,2,2ZM230,56V176a6,6,0,0,1-12,0V56a2,2,0,0,0-2-2H64a6,6,0,0,1,0-12H216A14,14,0,0,1,230,56Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M184,76H40A12,12,0,0,0,28,88V200a12,12,0,0,0,12,12H184a12,12,0,0,0,12-12V88A12,12,0,0,0,184,76Zm4,124a4,4,0,0,1-4,4H40a4,4,0,0,1-4-4V88a4,4,0,0,1,4-4H184a4,4,0,0,1,4,4ZM228,56V176a4,4,0,0,1-8,0V56a4,4,0,0,0-4-4H64a4,4,0,0,1,0-8H216A12,12,0,0,1,228,56Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M184,72H40A16,16,0,0,0,24,88V200a16,16,0,0,0,16,16H184a16,16,0,0,0,16-16V88A16,16,0,0,0,184,72Zm0,128H40V88H184V200ZM232,56V176a8,8,0,0,1-16,0V56H64a8,8,0,0,1,0-16H216A16,16,0,0,1,232,56Z" />
        </>
      )}
    </svg>
  );
}

export function PhCaretCircleDoubleDown(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M204.4,51.6a107.9,107.9,0,1,0,0,152.8A108.2,108.2,0,0,0,204.4,51.6Zm-17,135.8a84,84,0,1,1,0-118.8A84.2,84.2,0,0,1,187.4,187.4ZM168.5,79.5a12,12,0,0,1,0,17l-32,32a12.1,12.1,0,0,1-17,0l-32-32a12,12,0,0,1,17-17L128,103l23.5-23.5A12,12,0,0,1,168.5,79.5Zm0,56a12,12,0,0,1,0,17l-32,32a12.1,12.1,0,0,1-17,0l-32-32a12,12,0,0,1,17-17L128,159l23.5-23.5A12,12,0,0,1,168.5,135.5Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="128" cy="128" r="96" />
          </g>
          <path d="M201.5,54.5a103.8,103.8,0,0,0-147,0,103.8,103.8,0,0,0,0,147,103.8,103.8,0,0,0,147,0,103.8,103.8,0,0,0,0-147ZM190.2,190.2a88,88,0,1,1,0-124.4A88.1,88.1,0,0,1,190.2,190.2Z" />
          <path d="M154.3,82.3,128,108.7,101.7,82.3A8.1,8.1,0,0,0,90.3,93.7l32,32a8.2,8.2,0,0,0,11.4,0l32-32a8.1,8.1,0,0,0-11.4-11.4Z" />
          <path d="M154.3,138.3,128,164.7l-26.3-26.4a8.1,8.1,0,0,0-11.4,11.4l32,32a8.2,8.2,0,0,0,11.4,0l32-32a8.1,8.1,0,0,0-11.4-11.4Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M54.5,54.5a104,104,0,1,0,147,0A103.9,103.9,0,0,0,54.5,54.5Zm99.8,83.8a8.1,8.1,0,0,1,11.4,0,8.2,8.2,0,0,1,0,11.4l-32,32a8.1,8.1,0,0,1-11.4,0l-32-32a8.1,8.1,0,0,1,11.4-11.4L128,164.7Zm0-56a8.1,8.1,0,0,1,11.4,0,8.2,8.2,0,0,1,0,11.4l-32,32a8.1,8.1,0,0,1-11.4,0l-32-32a8.1,8.1,0,0,1,11.4-11.4L128,108.7Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M200.1,55.9A102,102,0,1,0,55.9,200.1,102,102,0,1,0,200.1,55.9Zm-8.5,135.7a90,90,0,1,1,0-127.2A90,90,0,0,1,191.6,191.6ZM164.2,83.8a5.8,5.8,0,0,1,0,8.4l-32,32a5.8,5.8,0,0,1-8.4,0l-32-32a5.9,5.9,0,0,1,8.4-8.4L128,111.5l27.8-27.7A5.8,5.8,0,0,1,164.2,83.8Zm0,56a5.8,5.8,0,0,1,0,8.4l-32,32a5.8,5.8,0,0,1-8.4,0l-32-32a5.9,5.9,0,0,1,8.4-8.4L128,167.5l27.8-27.7A5.8,5.8,0,0,1,164.2,139.8Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M198.7,57.3A100,100,0,1,0,57.3,198.7,100,100,0,1,0,198.7,57.3Zm-5.6,135.8A92.2,92.2,0,1,1,220,128,91.8,91.8,0,0,1,193.1,193.1ZM162.8,85.2a3.9,3.9,0,0,1,0,5.6l-32,32a3.9,3.9,0,0,1-5.6,0l-32-32a4,4,0,0,1,5.6-5.6L128,114.3l29.2-29.1A3.9,3.9,0,0,1,162.8,85.2Zm0,56a3.9,3.9,0,0,1,0,5.6l-32,32a3.9,3.9,0,0,1-5.6,0l-32-32a4,4,0,0,1,5.6-5.6L128,170.3l29.2-29.1A3.9,3.9,0,0,1,162.8,141.2Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M201.5,54.5a103.8,103.8,0,0,0-147,0,103.8,103.8,0,0,0,0,147,103.8,103.8,0,0,0,147,0,103.8,103.8,0,0,0,0-147ZM190.2,190.2a88,88,0,1,1,0-124.4A88.1,88.1,0,0,1,190.2,190.2ZM165.7,82.3a8.1,8.1,0,0,1,0,11.4l-32,32a8.2,8.2,0,0,1-11.4,0l-32-32a8.1,8.1,0,0,1,11.4-11.4L128,108.7l26.3-26.4A8.1,8.1,0,0,1,165.7,82.3Zm0,56a8.1,8.1,0,0,1,0,11.4l-32,32a8.2,8.2,0,0,1-11.4,0l-32-32a8.1,8.1,0,0,1,11.4-11.4L128,164.7l26.3-26.4A8.1,8.1,0,0,1,165.7,138.3Z" />
        </>
      )}
    </svg>
  );
}

export function PhCaretCircleDoubleLeft(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M204.4,51.6a107.9,107.9,0,1,0,0,152.8A108.2,108.2,0,0,0,204.4,51.6Zm-17,135.8a84,84,0,1,1,0-118.8A84.2,84.2,0,0,1,187.4,187.4Zm-10.9-82.9L153,128l23.5,23.5a12,12,0,0,1,0,17,12.1,12.1,0,0,1-17,0l-32-32a12,12,0,0,1,0-17l32-32a12,12,0,0,1,17,17Zm-56,0L97,128l23.5,23.5a12,12,0,0,1,0,17,12.1,12.1,0,0,1-17,0l-32-32a12,12,0,0,1,0-17l32-32a12,12,0,0,1,17,17Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="128" cy="128" r="96" />
          </g>
          <path d="M201.5,54.5a103.8,103.8,0,0,0-147,0,103.8,103.8,0,0,0,0,147,103.8,103.8,0,0,0,147,0,103.8,103.8,0,0,0,0-147ZM190.2,190.2a88,88,0,1,1,0-124.4A88.1,88.1,0,0,1,190.2,190.2Z" />
          <path d="M173.7,90.3a8.1,8.1,0,0,0-11.4,0l-32,32a8.1,8.1,0,0,0,0,11.4l32,32a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4L147.3,128l26.4-26.3A8.1,8.1,0,0,0,173.7,90.3Z" />
          <path d="M117.7,90.3a8.1,8.1,0,0,0-11.4,0l-32,32a8.1,8.1,0,0,0,0,11.4l32,32a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4L91.3,128l26.4-26.3A8.1,8.1,0,0,0,117.7,90.3Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M201.5,54.5a104,104,0,1,0,0,147A103.9,103.9,0,0,0,201.5,54.5Zm-83.8,99.8a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0l-32-32a8.1,8.1,0,0,1,0-11.4l32-32a8.1,8.1,0,0,1,11.4,11.4L91.3,128Zm56,0a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0l-32-32a8.1,8.1,0,0,1,0-11.4l32-32a8.1,8.1,0,0,1,11.4,11.4L147.3,128Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M200.1,55.9A102,102,0,1,0,55.9,200.1,102,102,0,1,0,200.1,55.9Zm-8.5,135.7a90,90,0,1,1,0-127.2A90,90,0,0,1,191.6,191.6Zm-19.4-91.4L144.5,128l27.7,27.8a5.9,5.9,0,1,1-8.4,8.4l-32-32a5.8,5.8,0,0,1,0-8.4l32-32a5.9,5.9,0,0,1,8.4,8.4Zm-56,0L88.5,128l27.7,27.8a5.9,5.9,0,1,1-8.4,8.4l-32-32a5.8,5.8,0,0,1,0-8.4l32-32a5.9,5.9,0,0,1,8.4,8.4Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M198.7,57.3A100,100,0,1,0,57.3,198.7,100,100,0,1,0,198.7,57.3Zm-5.6,135.8A92.2,92.2,0,1,1,220,128,91.8,91.8,0,0,1,193.1,193.1ZM170.8,98.8,141.7,128l29.1,29.2a3.9,3.9,0,0,1,0,5.6,3.9,3.9,0,0,1-5.6,0l-32-32a3.9,3.9,0,0,1,0-5.6l32-32a4,4,0,1,1,5.6,5.6Zm-56,0L85.7,128l29.1,29.2a3.9,3.9,0,0,1,0,5.6,3.9,3.9,0,0,1-5.6,0l-32-32a3.9,3.9,0,0,1,0-5.6l32-32a4,4,0,0,1,5.6,5.6Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M201.5,54.5a103.8,103.8,0,0,0-147,0,103.8,103.8,0,0,0,0,147,103.8,103.8,0,0,0,147,0,103.8,103.8,0,0,0,0-147ZM190.2,190.2a88,88,0,1,1,0-124.4A88.1,88.1,0,0,1,190.2,190.2Zm-16.5-88.5L147.3,128l26.4,26.3a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0l-32-32a8.1,8.1,0,0,1,0-11.4l32-32a8.1,8.1,0,0,1,11.4,11.4Zm-56,0L91.3,128l26.4,26.3a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0l-32-32a8.1,8.1,0,0,1,0-11.4l32-32a8.1,8.1,0,0,1,11.4,11.4Z" />
        </>
      )}
    </svg>
  );
}

export function PhCaretCircleDoubleRight(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M204.4,51.6a107.9,107.9,0,1,0,0,152.8A108.2,108.2,0,0,0,204.4,51.6Zm-17,135.8a84,84,0,1,1,0-118.8A84.2,84.2,0,0,1,187.4,187.4Zm-58.9-67.9a12,12,0,0,1,0,17l-32,32a12.1,12.1,0,0,1-17,0,12,12,0,0,1,0-17L103,128,79.5,104.5a12,12,0,0,1,17-17Zm56,17-32,32a12.1,12.1,0,0,1-17,0,12,12,0,0,1,0-17L159,128l-23.5-23.5a12,12,0,0,1,17-17l32,32A12,12,0,0,1,184.5,136.5Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="128" cy="128" r="96" />
          </g>
          <path d="M201.5,54.5a103.8,103.8,0,0,0-147,0,103.8,103.8,0,0,0,0,147,103.8,103.8,0,0,0,147,0,103.8,103.8,0,0,0,0-147ZM190.2,190.2a88,88,0,1,1,0-124.4A88.1,88.1,0,0,1,190.2,190.2Z" />
          <path d="M93.7,90.3a8.1,8.1,0,0,0-11.4,11.4L108.7,128,82.3,154.3a8.1,8.1,0,0,0,0,11.4,8.2,8.2,0,0,0,11.4,0l32-32a8.1,8.1,0,0,0,0-11.4Z" />
          <path d="M149.7,90.3a8.1,8.1,0,0,0-11.4,11.4L164.7,128l-26.4,26.3a8.1,8.1,0,0,0,0,11.4,8.2,8.2,0,0,0,11.4,0l32-32a8.1,8.1,0,0,0,0-11.4Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M201.5,54.5a104,104,0,1,0,0,147A103.9,103.9,0,0,0,201.5,54.5Zm-75.8,79.2-32,32a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L108.7,128,82.3,101.7A8.1,8.1,0,0,1,93.7,90.3l32,32A8.1,8.1,0,0,1,125.7,133.7Zm56,0-32,32a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L164.7,128l-26.4-26.3a8.1,8.1,0,0,1,11.4-11.4l32,32A8.1,8.1,0,0,1,181.7,133.7Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M200.1,55.9A102,102,0,1,0,55.9,200.1,102,102,0,1,0,200.1,55.9Zm-8.5,135.7a90,90,0,1,1,0-127.2A90,90,0,0,1,191.6,191.6Zm-67.4-67.8a5.8,5.8,0,0,1,0,8.4l-32,32a5.9,5.9,0,0,1-8.4-8.4L111.5,128,83.8,100.2a5.9,5.9,0,0,1,8.4-8.4Zm56,0a5.8,5.8,0,0,1,0,8.4l-32,32a5.9,5.9,0,0,1-8.4-8.4L167.5,128l-27.7-27.8a5.9,5.9,0,0,1,8.4-8.4Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M198.7,57.3A100,100,0,1,0,57.3,198.7,100,100,0,1,0,198.7,57.3Zm-5.6,135.8A92.2,92.2,0,1,1,220,128,91.8,91.8,0,0,1,193.1,193.1Zm-70.3-67.9a3.9,3.9,0,0,1,0,5.6l-32,32a3.9,3.9,0,0,1-5.6,0,3.9,3.9,0,0,1,0-5.6L114.3,128,85.2,98.8a4,4,0,0,1,5.6-5.6Zm56,0a3.9,3.9,0,0,1,0,5.6l-32,32a3.9,3.9,0,0,1-5.6,0,3.9,3.9,0,0,1,0-5.6L170.3,128,141.2,98.8a4,4,0,0,1,5.6-5.6Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M201.5,54.5a103.8,103.8,0,0,0-147,0,103.8,103.8,0,0,0,0,147,103.8,103.8,0,0,0,147,0,103.8,103.8,0,0,0,0-147ZM190.2,190.2a88,88,0,1,1,0-124.4A88.1,88.1,0,0,1,190.2,190.2Zm-64.5-67.9a8.1,8.1,0,0,1,0,11.4l-32,32a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L108.7,128,82.3,101.7A8.1,8.1,0,0,1,93.7,90.3Zm56,0a8.1,8.1,0,0,1,0,11.4l-32,32a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L164.7,128l-26.4-26.3a8.1,8.1,0,0,1,11.4-11.4Z" />
        </>
      )}
    </svg>
  );
}

export function PhCaretCircleDoubleUp(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M204.4,51.6a107.9,107.9,0,1,0,0,152.8A108.2,108.2,0,0,0,204.4,51.6Zm-17,135.8a84,84,0,1,1,0-118.8A84.2,84.2,0,0,1,187.4,187.4Zm-18.9-27.9a12,12,0,0,1,0,17,12.1,12.1,0,0,1-17,0L128,153l-23.5,23.5a12,12,0,0,1-17-17l32-32a12,12,0,0,1,17,0Zm0-56a12,12,0,0,1,0,17,12.1,12.1,0,0,1-17,0L128,97l-23.5,23.5a12,12,0,0,1-17-17l32-32a12,12,0,0,1,17,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="128" cy="128" r="96" />
          </g>
          <path d="M201.5,54.5a103.8,103.8,0,0,0-147,0,103.8,103.8,0,0,0,0,147,103.8,103.8,0,0,0,147,0,103.8,103.8,0,0,0,0-147ZM190.2,190.2a88,88,0,1,1,0-124.4A88.1,88.1,0,0,1,190.2,190.2Z" />
          <path d="M133.7,130.3a8.1,8.1,0,0,0-11.4,0l-32,32a8.1,8.1,0,0,0,11.4,11.4L128,147.3l26.3,26.4a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4Z" />
          <path d="M133.7,74.3a8.1,8.1,0,0,0-11.4,0l-32,32a8.1,8.1,0,0,0,11.4,11.4L128,91.3l26.3,26.4a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M201.5,201.5a104,104,0,1,0-147,0A103.9,103.9,0,0,0,201.5,201.5Zm-99.8-83.8a8.1,8.1,0,0,1-11.4,0,8.2,8.2,0,0,1,0-11.4l32-32a8.1,8.1,0,0,1,11.4,0l32,32a8.1,8.1,0,0,1-11.4,11.4L128,91.3Zm0,56a8.1,8.1,0,0,1-11.4,0,8.2,8.2,0,0,1,0-11.4l32-32a8.1,8.1,0,0,1,11.4,0l32,32a8.1,8.1,0,0,1-11.4,11.4L128,147.3Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M200.1,55.9A102,102,0,1,0,55.9,200.1,102,102,0,1,0,200.1,55.9Zm-8.5,135.7a90,90,0,1,1,0-127.2A90,90,0,0,1,191.6,191.6Zm-27.4-27.8a5.9,5.9,0,1,1-8.4,8.4L128,144.5l-27.8,27.7a5.9,5.9,0,0,1-8.4-8.4l32-32a5.8,5.8,0,0,1,8.4,0Zm0-56a5.9,5.9,0,1,1-8.4,8.4L128,88.5l-27.8,27.7a5.9,5.9,0,0,1-8.4-8.4l32-32a5.8,5.8,0,0,1,8.4,0Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M198.7,57.3A100,100,0,1,0,57.3,198.7,100,100,0,1,0,198.7,57.3Zm-5.6,135.8A92.2,92.2,0,1,1,220,128,91.8,91.8,0,0,1,193.1,193.1Zm-30.3-27.9a3.9,3.9,0,0,1,0,5.6,3.9,3.9,0,0,1-5.6,0L128,141.7,98.8,170.8a4,4,0,0,1-5.6-5.6l32-32a3.9,3.9,0,0,1,5.6,0Zm0-56a3.9,3.9,0,0,1,0,5.6,3.9,3.9,0,0,1-5.6,0L128,85.7,98.8,114.8a4,4,0,0,1-5.6-5.6l32-32a3.9,3.9,0,0,1,5.6,0Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M201.5,54.5a103.8,103.8,0,0,0-147,0,103.8,103.8,0,0,0,0,147,103.8,103.8,0,0,0,147,0,103.8,103.8,0,0,0,0-147ZM190.2,190.2a88,88,0,1,1,0-124.4A88.1,88.1,0,0,1,190.2,190.2Zm-24.5-27.9a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0L128,147.3l-26.3,26.4a8.1,8.1,0,0,1-11.4-11.4l32-32a8.1,8.1,0,0,1,11.4,0Zm0-56a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0L128,91.3l-26.3,26.4a8.1,8.1,0,0,1-11.4-11.4l32-32a8.1,8.1,0,0,1,11.4,0Z" />
        </>
      )}
    </svg>
  );
}

export function PhCaretCircleDown(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,20A108,108,0,1,0,236,128,108.1,108.1,0,0,0,128,20Zm0,192a84,84,0,1,1,84-84A84.1,84.1,0,0,1,128,212Zm44.9-88-36,40a11.9,11.9,0,0,1-17.8,0l-36-40a12,12,0,0,1,17.8-16L128,138.1,155.1,108a12,12,0,0,1,17.8,16Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="128" cy="128" r="96" />
          </g>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Z" />
          <path d="M169.4,110.1a8,8,0,0,0-11.3.5L128,144,97.9,110.6a8,8,0,0,0-11.8,10.8l36,40a8,8,0,0,0,11.8,0l36-40A8,8,0,0,0,169.4,110.1Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm41.9,97.4-36,40a8,8,0,0,1-11.8,0l-36-40a8,8,0,0,1,11.8-10.8L128,144l30.1-33.4a8,8,0,1,1,11.8,10.8Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,26A102,102,0,1,0,230,128,102.2,102.2,0,0,0,128,26Zm0,192a90,90,0,1,1,90-90A90.1,90.1,0,0,1,128,218Zm40-106.5a6,6,0,0,1,.5,8.5l-36,40a6.1,6.1,0,0,1-9,0l-36-40a6,6,0,0,1,9-8L128,147l31.5-35A6,6,0,0,1,168,111.5Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,28A100,100,0,1,0,228,128,100.2,100.2,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm38.7-107a4.1,4.1,0,0,1,.3,5.7l-36,40a4.1,4.1,0,0,1-6,0l-36-40a4,4,0,0,1,6-5.4L128,150l33-36.7A4.1,4.1,0,0,1,166.7,113Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm41.9-94.6-36,40a8,8,0,0,1-11.8,0l-36-40a8,8,0,0,1,11.8-10.8L128,144l30.1-33.4a8,8,0,1,1,11.8,10.8Z" />
        </>
      )}
    </svg>
  );
}

export function PhCaretCircleLeft(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,20A108,108,0,1,0,236,128,108.1,108.1,0,0,0,128,20Zm0,192a84,84,0,1,1,84-84A84.1,84.1,0,0,1,128,212Zm24-111.1L121.9,128,152,155.1a11.8,11.8,0,0,1,.9,16.9,11.9,11.9,0,0,1-16.9.9l-40-36a11.9,11.9,0,0,1,0-17.8l40-36a12,12,0,0,1,16,17.8Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="128" cy="128" r="96" />
          </g>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Z" />
          <path d="M149.9,86.6a8,8,0,0,0-11.3-.5l-40,36a8,8,0,0,0,0,11.8l40,36A8.2,8.2,0,0,0,144,172a7.9,7.9,0,0,0,5.9-2.6,8,8,0,0,0-.5-11.3L116,128l33.4-30.1A8,8,0,0,0,149.9,86.6Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm21.4,134.1a8,8,0,0,1,.5,11.3A7.9,7.9,0,0,1,144,172a8.2,8.2,0,0,1-5.4-2.1l-40-36a8,8,0,0,1,0-11.8l40-36a8,8,0,1,1,10.8,11.8L116,128Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,26A102,102,0,1,0,230,128,102.2,102.2,0,0,0,128,26Zm0,192a90,90,0,1,1,90-90A90.1,90.1,0,0,1,128,218ZM148,96.5,113,128l35,31.5a6,6,0,0,1,.5,8.5,6.2,6.2,0,0,1-4.5,2,6,6,0,0,1-4-1.5l-40-36a6.1,6.1,0,0,1,0-9l40-36a6,6,0,0,1,8,9Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,28A100,100,0,1,0,228,128,100.2,100.2,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220ZM146.7,95,110,128l36.7,33a4.1,4.1,0,0,1,.3,5.7,4.1,4.1,0,0,1-5.7.3l-40-36a4.1,4.1,0,0,1,0-6l40-36a4,4,0,1,1,5.4,6Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216ZM149.4,97.9,116,128l33.4,30.1a8,8,0,0,1,.5,11.3A7.9,7.9,0,0,1,144,172a8.2,8.2,0,0,1-5.4-2.1l-40-36a8,8,0,0,1,0-11.8l40-36a8,8,0,1,1,10.8,11.8Z" />
        </>
      )}
    </svg>
  );
}

export function PhCaretCircleRight(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,20A108,108,0,1,0,236,128,108.1,108.1,0,0,0,128,20Zm0,192a84,84,0,1,1,84-84A84.1,84.1,0,0,1,128,212Zm36-92.9a11.9,11.9,0,0,1,0,17.8l-40,36a11.9,11.9,0,0,1-16.9-.9,11.8,11.8,0,0,1,.9-16.9L138.1,128,108,100.9a12,12,0,1,1,16-17.8Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="128" cy="128" r="96" />
          </g>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Z" />
          <path d="M121.4,86.1a8,8,0,0,0-10.8,11.8L144,128l-33.4,30.1a8,8,0,0,0-.5,11.3A7.9,7.9,0,0,0,116,172a8.2,8.2,0,0,0,5.4-2.1l40-36a8,8,0,0,0,0-11.8Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm33.4,109.9-40,36A8.2,8.2,0,0,1,116,172a7.9,7.9,0,0,1-5.9-2.6,8,8,0,0,1,.5-11.3L144,128,110.6,97.9a8,8,0,0,1,10.8-11.8l40,36a8,8,0,0,1,0,11.8Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,26A102,102,0,1,0,230,128,102.2,102.2,0,0,0,128,26Zm0,192a90,90,0,1,1,90-90A90.1,90.1,0,0,1,128,218Zm32-94.5a6.1,6.1,0,0,1,0,9l-40,36a6,6,0,0,1-4,1.5,6.2,6.2,0,0,1-4.5-2,6,6,0,0,1,.5-8.5L147,128,112,96.5a6,6,0,1,1,8-9Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,28A100,100,0,1,0,228,128,100.2,100.2,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm30.7-95a4.1,4.1,0,0,1,0,6l-40,36a4.1,4.1,0,0,1-5.7-.3,4.1,4.1,0,0,1,.3-5.7L150,128,113.3,95a4,4,0,0,1,5.4-6Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm33.4-93.9a8,8,0,0,1,0,11.8l-40,36A8.2,8.2,0,0,1,116,172a7.9,7.9,0,0,1-5.9-2.6,8,8,0,0,1,.5-11.3L144,128,110.6,97.9a8,8,0,0,1,10.8-11.8Z" />
        </>
      )}
    </svg>
  );
}

export function PhCaretCircleUp(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M204.4,51.6h0a107.9,107.9,0,1,0,0,152.8A107.4,107.4,0,0,0,204.4,51.6Zm-17,135.8a84,84,0,1,1,0-118.8A84.2,84.2,0,0,1,187.4,187.4ZM172.9,136a11.8,11.8,0,0,1-.9,16.9,11.9,11.9,0,0,1-16.9-.9L128,121.9,100.9,152a12,12,0,1,1-17.8-16l36-40a11.9,11.9,0,0,1,17.8,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="128" cy="128" r="96" />
          </g>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Z" />
          <path d="M133.9,98.6a8,8,0,0,0-11.8,0l-36,40a8,8,0,0,0,11.8,10.8L128,116l30.1,33.4A7.9,7.9,0,0,0,164,152a8.2,8.2,0,0,0,5.4-2.1,8,8,0,0,0,.5-11.3Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm41.4,125.9A8.2,8.2,0,0,1,164,152a7.9,7.9,0,0,1-5.9-2.6L128,116,97.9,149.4a8,8,0,0,1-11.8-10.8l36-40a8.3,8.3,0,0,1,11.8,0l36,40A8,8,0,0,1,169.4,149.9Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M200.1,55.9A102,102,0,0,0,55.9,200.1,102,102,0,0,0,200.1,55.9Zm-8.5,135.7a90,90,0,1,1,0-127.2A89.5,89.5,0,0,1,191.6,191.6ZM168.5,140a6,6,0,0,1-4.5,10,6.2,6.2,0,0,1-4.5-2L128,113,96.5,148a6,6,0,0,1-9-8l36-40a6.1,6.1,0,0,1,9,0Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M198.7,57.3h0A100,100,0,0,0,57.3,198.7,100,100,0,0,0,198.7,57.3Zm-5.6,135.8a92,92,0,1,1,0-130.2A91.8,91.8,0,0,1,193.1,193.1ZM167,141.3a4.1,4.1,0,0,1-.3,5.7,4.1,4.1,0,0,1-5.7-.3L128,110,95,146.7a4,4,0,0,1-6-5.4l36-40a4.1,4.1,0,0,1,6,0Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M201.5,54.5a103.9,103.9,0,0,0-147,147,103.9,103.9,0,0,0,147-147ZM190.2,190.2a88,88,0,1,1,0-124.4A87.5,87.5,0,0,1,190.2,190.2Zm-20.3-51.6a8,8,0,0,1-.5,11.3A8.2,8.2,0,0,1,164,152a7.9,7.9,0,0,1-5.9-2.6L128,116,97.9,149.4a8,8,0,0,1-11.8-10.8l36-40a8,8,0,0,1,11.8,0Z" />
        </>
      )}
    </svg>
  );
}

export function PhCaretDoubleDown(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216.5,119.5a12,12,0,0,1,0,17l-80,80a12.1,12.1,0,0,1-17,0l-80-80a12,12,0,0,1,17-17L128,191l71.5-71.5A12,12,0,0,1,216.5,119.5Zm-97,17a12.1,12.1,0,0,0,17,0l80-80a12,12,0,0,0-17-17L128,111,56.5,39.5a12,12,0,0,0-17,17Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <polygon points="208 48 128 128 48 48 208 48" />
          </g>
          <path d="M213.7,122.3a8.1,8.1,0,0,0-11.4,0L128,196.7,53.7,122.3a8.1,8.1,0,0,0-11.4,11.4l80,80a8.2,8.2,0,0,0,11.4,0l80-80A8.1,8.1,0,0,0,213.7,122.3Z" />
          <path d="M122.3,133.7a8.2,8.2,0,0,0,11.4,0l80-80a8.4,8.4,0,0,0,1.7-8.8A8,8,0,0,0,208,40H48a8,8,0,0,0-7.4,4.9,8.4,8.4,0,0,0,1.7,8.8ZM188.7,56,128,116.7,67.3,56Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M215.4,124.9a8.4,8.4,0,0,1-1.7,8.8l-80,80a8.2,8.2,0,0,1-11.4,0l-80-80a8.4,8.4,0,0,1-1.7-8.8A8,8,0,0,1,48,120h60.7L42.3,53.7a8.4,8.4,0,0,1-1.7-8.8A8,8,0,0,1,48,40H208a8,8,0,0,1,7.4,4.9,8.4,8.4,0,0,1-1.7,8.8L147.3,120H208A8,8,0,0,1,215.4,124.9Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M212.2,123.8a5.8,5.8,0,0,1,0,8.4l-80,80a5.8,5.8,0,0,1-8.4,0l-80-80a5.9,5.9,0,0,1,8.4-8.4L128,199.5l75.8-75.7A5.8,5.8,0,0,1,212.2,123.8Zm-88.4,8.4a5.8,5.8,0,0,0,8.4,0l80-80a5.9,5.9,0,0,0-8.4-8.4L128,119.5,52.2,43.8a5.9,5.9,0,0,0-8.4,8.4Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M210.8,125.2a3.9,3.9,0,0,1,0,5.6l-80,80a3.9,3.9,0,0,1-5.6,0l-80-80a4,4,0,1,1,5.6-5.6L128,202.3l77.2-77.1A3.9,3.9,0,0,1,210.8,125.2Zm-85.6,5.6a3.9,3.9,0,0,0,5.6,0l80-80a4,4,0,1,0-5.6-5.6L128,122.3,50.8,45.2a4,4,0,0,0-5.6,5.6Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M213.7,122.3a8.1,8.1,0,0,1,0,11.4l-80,80a8.2,8.2,0,0,1-11.4,0l-80-80a8.1,8.1,0,0,1,11.4-11.4L128,196.7l74.3-74.4A8.1,8.1,0,0,1,213.7,122.3Zm-91.4,11.4a8.2,8.2,0,0,0,11.4,0l80-80a8.1,8.1,0,0,0-11.4-11.4L128,116.7,53.7,42.3A8.1,8.1,0,0,0,42.3,53.7Z" />
        </>
      )}
    </svg>
  );
}

export function PhCaretDoubleLeft(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M208.5,199.5a12,12,0,0,1,0,17,12.1,12.1,0,0,1-17,0l-80-80a12,12,0,0,1,0-17l80-80a12,12,0,0,1,17,17L137,128ZM57,128l71.5-71.5a12,12,0,0,0-17-17l-80,80a12,12,0,0,0,0,17l80,80a12.1,12.1,0,0,0,17,0,12,12,0,0,0,0-17Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <polygon points="200 208 120 128 200 48 200 208" />
          </g>
          <path d="M203.1,40.6a8.4,8.4,0,0,0-8.8,1.7l-80,80a8.1,8.1,0,0,0,0,11.4l80,80A8.3,8.3,0,0,0,200,216a8.5,8.5,0,0,0,3.1-.6A8,8,0,0,0,208,208V48A8,8,0,0,0,203.1,40.6ZM192,188.7,131.3,128,192,67.3Z" />
          <path d="M51.3,128l74.4-74.3a8.1,8.1,0,0,0-11.4-11.4l-80,80a8.1,8.1,0,0,0,0,11.4l80,80a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M208,48V208a8,8,0,0,1-4.9,7.4,8.5,8.5,0,0,1-3.1.6,8.3,8.3,0,0,1-5.7-2.3L128,147.3V208a8,8,0,0,1-4.9,7.4,8.5,8.5,0,0,1-3.1.6,8.3,8.3,0,0,1-5.7-2.3l-80-80a8.1,8.1,0,0,1,0-11.4l80-80a8.4,8.4,0,0,1,8.8-1.7A8,8,0,0,1,128,48v60.7l66.3-66.4a8.4,8.4,0,0,1,8.8-1.7A8,8,0,0,1,208,48Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M204.2,203.8a5.9,5.9,0,1,1-8.4,8.4l-80-80a5.8,5.8,0,0,1,0-8.4l80-80a5.9,5.9,0,0,1,8.4,8.4L128.5,128ZM48.5,128l75.7-75.8a5.9,5.9,0,0,0-8.4-8.4l-80,80a5.8,5.8,0,0,0,0,8.4l80,80a5.9,5.9,0,0,0,8.4-8.4Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M202.8,205.2a3.9,3.9,0,0,1,0,5.6,3.9,3.9,0,0,1-5.6,0l-80-80a3.9,3.9,0,0,1,0-5.6l80-80a4,4,0,1,1,5.6,5.6L125.7,128ZM45.7,128l77.1-77.2a4,4,0,0,0-5.6-5.6l-80,80a3.9,3.9,0,0,0,0,5.6l80,80a3.9,3.9,0,0,0,5.6,0,3.9,3.9,0,0,0,0-5.6Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M205.7,202.3a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0l-80-80a8.1,8.1,0,0,1,0-11.4l80-80a8.1,8.1,0,0,1,11.4,11.4L131.3,128ZM51.3,128l74.4-74.3a8.1,8.1,0,0,0-11.4-11.4l-80,80a8.1,8.1,0,0,0,0,11.4l80,80a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4Z" />
        </>
      )}
    </svg>
  );
}

export function PhCaretDoubleRight(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M144.5,136.5l-80,80a12.1,12.1,0,0,1-17,0,12,12,0,0,1,0-17L119,128,47.5,56.5a12,12,0,0,1,17-17l80,80A12,12,0,0,1,144.5,136.5Zm80-17-80-80a12,12,0,0,0-17,17L199,128l-71.5,71.5a12,12,0,0,0,0,17,12.1,12.1,0,0,0,17,0l80-80A12,12,0,0,0,224.5,119.5Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <polygon points="56 48 136 128 56 208 56 48" />
          </g>
          <path d="M141.7,122.3l-80-80a8.4,8.4,0,0,0-8.8-1.7A8,8,0,0,0,48,48V208a8,8,0,0,0,4.9,7.4,8.5,8.5,0,0,0,3.1.6,8.3,8.3,0,0,0,5.7-2.3l80-80A8.1,8.1,0,0,0,141.7,122.3ZM64,188.7V67.3L124.7,128Z" />
          <path d="M221.7,122.3l-80-80a8.1,8.1,0,0,0-11.4,11.4L204.7,128l-74.4,74.3a8.1,8.1,0,0,0,0,11.4,8.2,8.2,0,0,0,11.4,0l80-80A8.1,8.1,0,0,0,221.7,122.3Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M221.7,133.7l-80,80A8.3,8.3,0,0,1,136,216a8.5,8.5,0,0,1-3.1-.6A8,8,0,0,1,128,208V147.3L61.7,213.7A8.3,8.3,0,0,1,56,216a8.5,8.5,0,0,1-3.1-.6A8,8,0,0,1,48,208V48a8,8,0,0,1,4.9-7.4,8.4,8.4,0,0,1,8.8,1.7L128,108.7V48a8,8,0,0,1,4.9-7.4,8.4,8.4,0,0,1,8.8,1.7l80,80A8.1,8.1,0,0,1,221.7,133.7Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M140.2,132.2l-80,80a5.9,5.9,0,0,1-8.4-8.4L127.5,128,51.8,52.2a5.9,5.9,0,0,1,8.4-8.4l80,80A5.8,5.8,0,0,1,140.2,132.2Zm80-8.4-80-80a5.9,5.9,0,0,0-8.4,8.4L207.5,128l-75.7,75.8a5.9,5.9,0,1,0,8.4,8.4l80-80A5.8,5.8,0,0,0,220.2,123.8Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M138.8,130.8l-80,80a3.9,3.9,0,0,1-5.6,0,3.9,3.9,0,0,1,0-5.6L130.3,128,53.2,50.8a4,4,0,0,1,5.6-5.6l80,80A3.9,3.9,0,0,1,138.8,130.8Zm80-5.6-80-80a4,4,0,0,0-5.6,5.6L210.3,128l-77.1,77.2a3.9,3.9,0,0,0,0,5.6,3.9,3.9,0,0,0,5.6,0l80-80A3.9,3.9,0,0,0,218.8,125.2Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M141.7,133.7l-80,80a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L124.7,128,50.3,53.7A8.1,8.1,0,0,1,61.7,42.3l80,80A8.1,8.1,0,0,1,141.7,133.7Zm80-11.4-80-80a8.1,8.1,0,0,0-11.4,11.4L204.7,128l-74.4,74.3a8.1,8.1,0,0,0,0,11.4,8.2,8.2,0,0,0,11.4,0l80-80A8.1,8.1,0,0,0,221.7,122.3Z" />
        </>
      )}
    </svg>
  );
}

export function PhCaretDoubleUp(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216.5,199.5a12,12,0,0,1,0,17,12.1,12.1,0,0,1-17,0L128,145,56.5,216.5a12,12,0,0,1-17-17l80-80a12,12,0,0,1,17,0Zm-160-63L128,65l71.5,71.5a12.1,12.1,0,0,0,17,0,12,12,0,0,0,0-17l-80-80a12,12,0,0,0-17,0l-80,80a12,12,0,0,0,17,17Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <polygon points="48 208 128 128 208 208 48 208" />
          </g>
          <path d="M133.7,122.3a8.1,8.1,0,0,0-11.4,0l-80,80a8.4,8.4,0,0,0-1.7,8.8A8,8,0,0,0,48,216H208a8,8,0,0,0,7.4-4.9,8.4,8.4,0,0,0-1.7-8.8ZM67.3,200,128,139.3,188.7,200Z" />
          <path d="M53.7,133.7,128,59.3l74.3,74.4a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4l-80-80a8.1,8.1,0,0,0-11.4,0l-80,80a8.1,8.1,0,1,0,11.4,11.4Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M213.7,202.3a8.4,8.4,0,0,1,1.7,8.8A8,8,0,0,1,208,216H48a8,8,0,0,1-7.4-4.9,8.4,8.4,0,0,1,1.7-8.8L108.7,136H48a8,8,0,0,1-7.4-4.9,8.4,8.4,0,0,1,1.7-8.8l80-80a8.1,8.1,0,0,1,11.4,0l80,80a8.4,8.4,0,0,1,1.7,8.8A8,8,0,0,1,208,136H147.3Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M212.2,203.8a5.9,5.9,0,1,1-8.4,8.4L128,136.5,52.2,212.2a5.9,5.9,0,0,1-8.4-8.4l80-80a5.8,5.8,0,0,1,8.4,0Zm-160-71.6L128,56.5l75.8,75.7a5.9,5.9,0,1,0,8.4-8.4l-80-80a5.8,5.8,0,0,0-8.4,0l-80,80a5.9,5.9,0,1,0,8.4,8.4Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M210.8,205.2a3.9,3.9,0,0,1,0,5.6,3.9,3.9,0,0,1-5.6,0L128,133.7,50.8,210.8a4,4,0,0,1-5.6-5.6l80-80a3.9,3.9,0,0,1,5.6,0Zm-160-74.4L128,53.7l77.2,77.1a3.9,3.9,0,0,0,5.6,0,3.9,3.9,0,0,0,0-5.6l-80-80a3.9,3.9,0,0,0-5.6,0l-80,80a4,4,0,0,0,5.6,5.6Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M213.7,202.3a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0L128,139.3,53.7,213.7a8.1,8.1,0,0,1-11.4-11.4l80-80a8.1,8.1,0,0,1,11.4,0Zm-160-68.6L128,59.3l74.3,74.4a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4l-80-80a8.1,8.1,0,0,0-11.4,0l-80,80a8.1,8.1,0,1,0,11.4,11.4Z" />
        </>
      )}
    </svg>
  );
}

export function PhCaretDown(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,188a12.2,12.2,0,0,1-8.5-3.5l-80-80a12,12,0,0,1,17-17L128,159l71.5-71.5a12,12,0,0,1,17,17l-80,80A12.2,12.2,0,0,1,128,188Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <polygon points="208 96 128 176 48 96 208 96" />
          </g>
          <path d="M128,184a8.5,8.5,0,0,1-5.7-2.3l-80-80a8.4,8.4,0,0,1-1.7-8.8A8,8,0,0,1,48,88H208a8,8,0,0,1,7.4,4.9,8.4,8.4,0,0,1-1.7,8.8l-80,80A8.5,8.5,0,0,1,128,184ZM67.3,104,128,164.7,188.7,104Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M213.7,101.7l-80,80a8.2,8.2,0,0,1-11.4,0l-80-80a8.4,8.4,0,0,1-1.7-8.8A8,8,0,0,1,48,88H208a8,8,0,0,1,7.4,4.9A8.4,8.4,0,0,1,213.7,101.7Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,182a5.8,5.8,0,0,1-4.2-1.8l-80-80a5.9,5.9,0,0,1,8.4-8.4L128,167.5l75.8-75.7a5.9,5.9,0,0,1,8.4,8.4l-80,80A5.8,5.8,0,0,1,128,182Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,180a3.9,3.9,0,0,1-2.8-1.2l-80-80a4,4,0,0,1,5.6-5.6L128,170.3l77.2-77.1a4,4,0,1,1,5.6,5.6l-80,80A3.9,3.9,0,0,1,128,180Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,184a8.5,8.5,0,0,1-5.7-2.3l-80-80A8.1,8.1,0,0,1,53.7,90.3L128,164.7l74.3-74.4a8.1,8.1,0,0,1,11.4,11.4l-80,80A8.5,8.5,0,0,1,128,184Z" />
        </>
      )}
    </svg>
  );
}

export function PhCaretLeft(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M160,220a12.2,12.2,0,0,1-8.5-3.5l-80-80a12,12,0,0,1,0-17l80-80a12,12,0,0,1,17,17L97,128l71.5,71.5a12,12,0,0,1,0,17A12.2,12.2,0,0,1,160,220Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <polygon points="160 208 80 128 160 48 160 208" />
          </g>
          <path d="M160,216a8.3,8.3,0,0,1-5.7-2.3l-80-80a8.1,8.1,0,0,1,0-11.4l80-80a8.4,8.4,0,0,1,8.8-1.7A8,8,0,0,1,168,48V208a8,8,0,0,1-4.9,7.4A8.5,8.5,0,0,1,160,216ZM91.3,128,152,188.7V67.3Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M168,48V208a8,8,0,0,1-4.9,7.4,8.5,8.5,0,0,1-3.1.6,8.3,8.3,0,0,1-5.7-2.3l-80-80a8.1,8.1,0,0,1,0-11.4l80-80a8.4,8.4,0,0,1,8.8-1.7A8,8,0,0,1,168,48Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M160,214a5.8,5.8,0,0,1-4.2-1.8l-80-80a5.8,5.8,0,0,1,0-8.4l80-80a5.9,5.9,0,0,1,8.4,8.4L88.5,128l75.7,75.8A6,6,0,0,1,160,214Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M160,212a3.9,3.9,0,0,1-2.8-1.2l-80-80a3.9,3.9,0,0,1,0-5.6l80-80a4,4,0,1,1,5.6,5.6L85.7,128l77.1,77.2a3.9,3.9,0,0,1,0,5.6A3.9,3.9,0,0,1,160,212Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M160,216a8.5,8.5,0,0,1-5.7-2.3l-80-80a8.1,8.1,0,0,1,0-11.4l80-80a8.1,8.1,0,0,1,11.4,11.4L91.3,128l74.4,74.3a8.1,8.1,0,0,1,0,11.4A8.5,8.5,0,0,1,160,216Z" />
        </>
      )}
    </svg>
  );
}

export function PhCaretRight(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M96,220a12.2,12.2,0,0,1-8.5-3.5,12,12,0,0,1,0-17L159,128,87.5,56.5a12,12,0,0,1,17-17l80,80a12,12,0,0,1,0,17l-80,80A12.2,12.2,0,0,1,96,220Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <polygon points="96 48 176 128 96 208 96 48" />
          </g>
          <path d="M96,216a8.5,8.5,0,0,1-3.1-.6A8,8,0,0,1,88,208V48a8,8,0,0,1,4.9-7.4,8.4,8.4,0,0,1,8.8,1.7l80,80a8.1,8.1,0,0,1,0,11.4l-80,80A8.3,8.3,0,0,1,96,216Zm8-148.7V188.7L164.7,128Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M181.7,133.7l-80,80A8.3,8.3,0,0,1,96,216a8.5,8.5,0,0,1-3.1-.6A8,8,0,0,1,88,208V48a8,8,0,0,1,4.9-7.4,8.4,8.4,0,0,1,8.8,1.7l80,80A8.1,8.1,0,0,1,181.7,133.7Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M96,214a6,6,0,0,1-4.2-10.2L167.5,128,91.8,52.2a5.9,5.9,0,0,1,8.4-8.4l80,80a5.8,5.8,0,0,1,0,8.4l-80,80A5.8,5.8,0,0,1,96,214Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M96,212a3.9,3.9,0,0,1-2.8-1.2,3.9,3.9,0,0,1,0-5.6L170.3,128,93.2,50.8a4,4,0,0,1,5.6-5.6l80,80a3.9,3.9,0,0,1,0,5.6l-80,80A3.9,3.9,0,0,1,96,212Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M96,216a8.5,8.5,0,0,1-5.7-2.3,8.1,8.1,0,0,1,0-11.4L164.7,128,90.3,53.7a8.1,8.1,0,0,1,11.4-11.4l80,80a8.1,8.1,0,0,1,0,11.4l-80,80A8.5,8.5,0,0,1,96,216Z" />
        </>
      )}
    </svg>
  );
}

export function PhCaretUp(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M208,172a12.2,12.2,0,0,1-8.5-3.5L128,97,56.5,168.5a12,12,0,0,1-17-17l80-80a12,12,0,0,1,17,0l80,80a12,12,0,0,1,0,17A12.2,12.2,0,0,1,208,172Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <polygon points="48 160 128 80 208 160 48 160" />
          </g>
          <path d="M208,168H48a8,8,0,0,1-7.4-4.9,8.4,8.4,0,0,1,1.7-8.8l80-80a8.1,8.1,0,0,1,11.4,0l80,80a8.4,8.4,0,0,1,1.7,8.8A8,8,0,0,1,208,168ZM67.3,152H188.7L128,91.3Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M215.4,163.1A8,8,0,0,1,208,168H48a8,8,0,0,1-7.4-4.9,8.4,8.4,0,0,1,1.7-8.8l80-80a8.1,8.1,0,0,1,11.4,0l80,80A8.4,8.4,0,0,1,215.4,163.1Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M208,166a5.8,5.8,0,0,1-4.2-1.8L128,88.5,52.2,164.2a5.9,5.9,0,0,1-8.4-8.4l80-80a5.8,5.8,0,0,1,8.4,0l80,80A6,6,0,0,1,208,166Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M208,164a3.9,3.9,0,0,1-2.8-1.2L128,85.7,50.8,162.8a4,4,0,0,1-5.6-5.6l80-80a3.9,3.9,0,0,1,5.6,0l80,80a3.9,3.9,0,0,1,0,5.6A3.9,3.9,0,0,1,208,164Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M208,168a8.5,8.5,0,0,1-5.7-2.3L128,91.3,53.7,165.7a8.1,8.1,0,0,1-11.4-11.4l80-80a8.1,8.1,0,0,1,11.4,0l80,80a8.1,8.1,0,0,1,0,11.4A8.5,8.5,0,0,1,208,168Z" />
        </>
      )}
    </svg>
  );
}

export function PhCat(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M100,140a16,16,0,1,1-16-16A16,16,0,0,1,100,140Zm72-16a16,16,0,1,0,16,16A16,16,0,0,0,172,124Zm64-72.7V136c0,55.1-48.4,100-108,100S20,191.1,20,136V51.3A19.9,19.9,0,0,1,54.1,37.2l15,15A114.5,114.5,0,0,1,105,38.3a11.3,11.3,0,0,1,1.8-.3,113.4,113.4,0,0,1,42.4,0,11.3,11.3,0,0,1,1.8.3,114.5,114.5,0,0,1,35.9,13.9l15-15A19.9,19.9,0,0,1,236,51.3ZM212,61,196.9,76.1a12,12,0,0,1-15.7,1.1A86.9,86.9,0,0,0,160,65.7V88a12,12,0,0,1-24,0V60.4a80.2,80.2,0,0,0-16,0V88a12,12,0,0,1-24,0V65.7A86.9,86.9,0,0,0,74.8,77.2a12,12,0,0,1-15.7-1.1L44,61v75c0,38.2,31.3,69.9,72,75.2V197l-12.5-12.5a12,12,0,0,1,17-17L128,175l7.5-7.5a12,12,0,0,1,17,17L140,197v14.2c40.7-5.3,72-37,72-75.2Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M224,51.3V136c0,48.6-43,88-96,88s-96-39.4-96-88V51.3a8,8,0,0,1,13.7-5.6L67.6,67.6h0A100.8,100.8,0,0,1,128,48a100.8,100.8,0,0,1,60.4,19.6h0l21.9-21.9A8,8,0,0,1,224,51.3Z"
            opacity="0.2"
          />
          <path d="M96,140a12,12,0,1,1-12-12A12,12,0,0,1,96,140Zm76-12a12,12,0,1,0,12,12A12,12,0,0,0,172,128Zm60-76.7V136c0,52.9-46.7,96-104,96S24,188.9,24,136V51.3A16,16,0,0,1,51.3,40L68.5,57.2A106.2,106.2,0,0,1,92.8,45.7l1.6-.5a111.2,111.2,0,0,1,67.2,0l1.6.5a106.2,106.2,0,0,1,24.3,11.5L204.7,40A16,16,0,0,1,232,51.3Zm-16,0-21.9,22a8.1,8.1,0,0,1-10.5.7A84.1,84.1,0,0,0,168,64.8V88a8,8,0,0,1-16,0V59.1a95.7,95.7,0,0,0-16-2.8V88a8,8,0,0,1-16,0V56.3a95.7,95.7,0,0,0-16,2.8V88a8,8,0,0,1-16,0V64.8A84.1,84.1,0,0,0,72.4,74a8.1,8.1,0,0,1-10.5-.7L40,51.3V136c0,41.7,35.2,76,80,79.7V195.3l-13.7-13.6a8.1,8.1,0,0,1,11.4-11.4L128,180.7l10.3-10.4a8.1,8.1,0,0,1,11.4,11.4L136,195.3v20.4c44.8-3.7,80-38,80-79.7Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M221.4,36.3a16.3,16.3,0,0,0-17,4L187.5,57.2a111.5,111.5,0,0,0-119,0L51.6,40.3a16.3,16.3,0,0,0-17-4A15.9,15.9,0,0,0,24,51.3V136c0,49.1,40.1,89.6,91.6,95.3a4,4,0,0,0,4.4-4v-32l-13.4-13.4a8.3,8.3,0,0,1-.4-11.4,8.1,8.1,0,0,1,11.5-.2L128,180.7l10.3-10.4a8.1,8.1,0,0,1,11.5.2,8.3,8.3,0,0,1-.4,11.4L136,195.3v32a4,4,0,0,0,4.4,4c51.5-5.7,91.6-46.2,91.6-95.3V51.3A15.9,15.9,0,0,0,221.4,36.3ZM84,152a12,12,0,1,1,12-12A12,12,0,0,1,84,152Zm20-64a8,8,0,0,1-16,0V69a8,8,0,0,1,16,0Zm32,0a8,8,0,0,1-16,0V64a8,8,0,0,1,16,0Zm16,0V69a8,8,0,0,1,16,0V88a8,8,0,0,1-16,0Zm20,64a12,12,0,1,1,12-12A12,12,0,0,1,172,152Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M94,140a10,10,0,1,1-10-10A10,10,0,0,1,94,140Zm78-10a10,10,0,1,0,10,10A10,10,0,0,0,172,130Zm58-78.7V136c0,51.8-45.8,94-102,94S26,187.8,26,136V51.3a14,14,0,0,1,23.9-9.9L68.3,59.8A99.6,99.6,0,0,1,93.5,47.6l1.4-.5a110,110,0,0,1,66.2,0l1.4.5a99.6,99.6,0,0,1,25.2,12.2l18.4-18.4A14,14,0,0,1,230,51.3Zm-12,0a2,2,0,0,0-1.2-1.8,1.9,1.9,0,0,0-2.2.4l-22,21.9a5.9,5.9,0,0,1-7.8.6A89.5,89.5,0,0,0,166,61.7V88a6,6,0,0,1-12,0V57.5a96,96,0,0,0-20-3.3V88a6,6,0,0,1-12,0V54.2a96,96,0,0,0-20,3.3V88a6,6,0,0,1-12,0V61.7A89.5,89.5,0,0,0,71.2,72.4a5.9,5.9,0,0,1-7.8-.6l-22-21.9a1.6,1.6,0,0,0-1.4-.6l-.8.2A2,2,0,0,0,38,51.3V136c0,43.4,37.2,79,84,81.8V194.5l-14.2-14.3a5.9,5.9,0,0,1,8.4-8.4L128,183.5l11.8-11.7a5.9,5.9,0,0,1,8.4,8.4L134,194.5v23.3c46.8-2.8,84-38.4,84-81.8Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M92,140a8,8,0,1,1-8-8A8,8,0,0,1,92,140Zm80-8a8,8,0,1,0,8,8A8,8,0,0,0,172,132Zm56-80.7V136c0,50.7-44.9,92-100,92S28,186.7,28,136V51.3a12,12,0,0,1,20.5-8.5L68,62.4a105,105,0,0,1,26.2-13,4.5,4.5,0,0,1,1.1-.3,107.4,107.4,0,0,1,65.4,0,4.5,4.5,0,0,1,1.1.3,105,105,0,0,1,26.2,13l19.5-19.6A12,12,0,0,1,228,51.3Zm-8,0a4,4,0,0,0-2.5-3.7,3.9,3.9,0,0,0-4.3.9l-22,21.9a4,4,0,0,1-5.2.4,94.1,94.1,0,0,0-22-12.1V88a4,4,0,0,1-8,0V56a101.1,101.1,0,0,0-24-3.9V88a4,4,0,0,1-8,0V52.1A101.1,101.1,0,0,0,100,56V88a4,4,0,0,1-8,0V58.7A94.1,94.1,0,0,0,70,70.8a4,4,0,0,1-5.2-.4l-22-21.9a3.8,3.8,0,0,0-2.7-1.2,4,4,0,0,0-4.1,4V136c0,45.1,39.1,82,88,83.9V193.7l-14.8-14.9a4,4,0,0,1,5.6-5.6L128,186.3l13.2-13.1a4,4,0,0,1,5.6,5.6L132,193.7v26.2c48.9-1.9,88-38.8,88-83.9Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M96,140a12,12,0,1,1-12-12A12,12,0,0,1,96,140Zm76-12a12,12,0,1,0,12,12A12,12,0,0,0,172,128Zm60-76.7V136c0,52.9-46.7,96-104,96S24,188.9,24,136V51.3A16,16,0,0,1,51.3,40L68.5,57.2A106.2,106.2,0,0,1,92.8,45.7l1.6-.5a111.2,111.2,0,0,1,67.2,0l1.6.5a106.2,106.2,0,0,1,24.3,11.5L204.7,40A16,16,0,0,1,232,51.3Zm-16,0-21.9,22a8.1,8.1,0,0,1-10.5.7A84.1,84.1,0,0,0,168,64.8V88a8,8,0,0,1-16,0V59.1a95.7,95.7,0,0,0-16-2.8V88a8,8,0,0,1-16,0V56.3a95.7,95.7,0,0,0-16,2.8V88a8,8,0,0,1-16,0V64.8A84.1,84.1,0,0,0,72.4,74a8.1,8.1,0,0,1-10.5-.7L40,51.3V136c0,41.7,35.2,76,80,79.7V195.3l-13.7-13.6a8.1,8.1,0,0,1,11.4-11.4L128,180.7l10.3-10.4a8.1,8.1,0,0,1,11.4,11.4L136,195.3v20.4c44.8-3.7,80-38,80-79.7Z" />
        </>
      )}
    </svg>
  );
}

export function PhCellSignalFull(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M199.7,24.8a19.9,19.9,0,0,0-21.8,4.4L13.2,193.8A20,20,0,0,0,27.3,228H192a20.1,20.1,0,0,0,20-20V43.3A19.9,19.9,0,0,0,199.7,24.8ZM132,109l16-16V204H132Zm-24,95H92V149l16-16ZM68,173v31H37Zm120,31H172V69l16-16Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M200,43.3V208a8,8,0,0,1-8,8H27.3a8,8,0,0,1-5.6-13.7L186.3,37.6A8.1,8.1,0,0,1,200,43.3Z" />
          </g>
          <path d="M192,224H27.3A16,16,0,0,1,16,196.7L180.7,32A16,16,0,0,1,208,43.3V208A16,16,0,0,1,192,224ZM27.3,208H192V43.3L27.3,208Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M208,43.3V208a16,16,0,0,1-16,16H27.3A16,16,0,0,1,16,196.7L180.7,32A16,16,0,0,1,208,43.3Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M197.4,30.4a14,14,0,0,0-15.3,3L115.8,99.7h0l-40,39.9h0L17.4,198.1A14,14,0,0,0,27.3,222H192a14,14,0,0,0,14-14V43.3A13.9,13.9,0,0,0,197.4,30.4ZM126,106.5l28-28V210H126ZM114,210H86V146.5l28-28Zm-88.5-1.2a1.9,1.9,0,0,1,.4-2.2L74,158.5V210H27.3A2,2,0,0,1,25.5,208.8ZM194,208a2,2,0,0,1-2,2H166V66.5l24.6-24.6a1.8,1.8,0,0,1,1.4-.6l.8.2a1.8,1.8,0,0,1,1.2,1.8Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M196.6,32.2a11.8,11.8,0,0,0-13.1,2.6l-66.3,66.4h0l-40,40h0L18.8,199.5A12,12,0,0,0,27.3,220H192a12,12,0,0,0,12-12V43.3A11.8,11.8,0,0,0,196.6,32.2ZM124,105.6l32-32V212H124ZM116,212H84V145.6l32-32Zm-92.4-2.5a3.8,3.8,0,0,1,.9-4.3L76,153.6V212H27.3A4,4,0,0,1,23.6,209.5ZM196,208a4,4,0,0,1-4,4H164V65.6l25.2-25.1a3.4,3.4,0,0,1,2.7-1.2,4,4,0,0,1,1.6.3,3.8,3.8,0,0,1,2.5,3.7Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M198.1,28.5A15.9,15.9,0,0,0,180.7,32L74.4,138.3h-.1L16,196.7A16,16,0,0,0,27.3,224H192a16,16,0,0,0,16-16V43.3A16,16,0,0,0,198.1,28.5ZM88,147.3l24-24V208H88ZM72,208H27.3L72,163.3Zm56-100.7,24-24V208H128ZM192,208H168V67.3l24-24Z" />
        </>
      )}
    </svg>
  );
}

export function PhCellSignalHigh(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M199.7,24.8a19.9,19.9,0,0,0-21.8,4.4L13.2,193.8A20,20,0,0,0,27.3,228H192a20.1,20.1,0,0,0,20-20V43.3A19.9,19.9,0,0,0,199.7,24.8ZM148,93V204H37Zm40,111H172V69l16-16Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M160,216H27.3a8,8,0,0,1-5.6-13.7L160,64Z" />
          </g>
          <path d="M198.1,28.5A15.9,15.9,0,0,0,180.7,32L16,196.7A16,16,0,0,0,27.3,224H192a16,16,0,0,0,16-16V43.3A16,16,0,0,0,198.1,28.5ZM152,83.3V208H27.3ZM192,208H168V67.3l24-24Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M198.1,28.5A15.9,15.9,0,0,0,180.7,32L16,196.7a15.7,15.7,0,0,0-4.7,11.6,4.9,4.9,0,0,0,.1,1.2,14.7,14.7,0,0,0,1.1,4.6,16,16,0,0,0,6.9,7.8l1,.5a10.2,10.2,0,0,0,2.2.9,18.4,18.4,0,0,0,4.7.7H192a16,16,0,0,0,16-16V43.3A16,16,0,0,0,198.1,28.5ZM192,208H168V67.3l24-24Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M197.4,30.4a14,14,0,0,0-15.3,3L17.4,198.1A14,14,0,0,0,27.3,222H192a14,14,0,0,0,14-14V43.3A13.9,13.9,0,0,0,197.4,30.4ZM25.5,208.8a1.9,1.9,0,0,1,.4-2.2L154,78.5V210H27.3A2,2,0,0,1,25.5,208.8ZM194,208a2,2,0,0,1-2,2H166V66.5l24.6-24.6a1.8,1.8,0,0,1,1.4-.6l.8.2a1.8,1.8,0,0,1,1.2,1.8Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M196.6,32.2a11.8,11.8,0,0,0-13.1,2.6L18.8,199.5A12,12,0,0,0,27.3,220H192a12,12,0,0,0,12-12V43.3A11.8,11.8,0,0,0,196.6,32.2ZM23.6,209.5a3.8,3.8,0,0,1,.9-4.3L156,73.6V212H27.3A4,4,0,0,1,23.6,209.5ZM196,208a4,4,0,0,1-4,4H164V65.6l25.2-25.1a3.4,3.4,0,0,1,2.7-1.2,4,4,0,0,1,1.6.3,3.8,3.8,0,0,1,2.5,3.7Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M198.1,28.5A15.9,15.9,0,0,0,180.7,32L16,196.7A16,16,0,0,0,27.3,224H192a16,16,0,0,0,16-16V43.3A16,16,0,0,0,198.1,28.5ZM152,83.3V208H27.3ZM192,208H168V67.3l24-24Z" />
        </>
      )}
    </svg>
  );
}

export function PhCellSignalLow(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M199.7,24.8a19.9,19.9,0,0,0-21.8,4.4L13.2,193.8A20,20,0,0,0,27.3,228H192a20.1,20.1,0,0,0,20-20V43.3A19.9,19.9,0,0,0,199.7,24.8ZM68,173v31H37Zm120,31H92V149l96-96Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M80,216H27.3a8,8,0,0,1-5.6-13.7L80,144Z" />
          </g>
          <path d="M198.1,28.5A15.9,15.9,0,0,0,180.7,32L74.4,138.3h-.1L16,196.7A16,16,0,0,0,27.3,224H192a16,16,0,0,0,16-16V43.3A16,16,0,0,0,198.1,28.5ZM72,163.3V208H27.3ZM192,208H88V147.3l104-104Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M198.1,28.5A15.9,15.9,0,0,0,180.7,32L74.4,138.3h-.1L16,196.7A16,16,0,0,0,27.3,224H192a16,16,0,0,0,16-16V43.3A16,16,0,0,0,198.1,28.5ZM192,208H88V147.3l104-104Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M197.4,30.4a14,14,0,0,0-15.3,3L75.8,139.7h0L17.4,198.1A14,14,0,0,0,27.3,222H192a14,14,0,0,0,14-14V43.3A13.9,13.9,0,0,0,197.4,30.4ZM25.5,208.8a1.9,1.9,0,0,1,.4-2.2L74,158.5V210H27.3A2,2,0,0,1,25.5,208.8ZM194,208a2,2,0,0,1-2,2H86V146.5L190.6,41.9a1.8,1.8,0,0,1,1.4-.6l.8.2a1.8,1.8,0,0,1,1.2,1.8Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M196.6,32.2a11.8,11.8,0,0,0-13.1,2.6L77.2,141.2h0L18.8,199.5A12,12,0,0,0,27.3,220H192a12,12,0,0,0,12-12V43.3A11.8,11.8,0,0,0,196.6,32.2ZM23.6,209.5a3.8,3.8,0,0,1,.9-4.3L76,153.6V212H27.3A4,4,0,0,1,23.6,209.5ZM196,208a4,4,0,0,1-4,4H84V145.6L189.2,40.5a3.4,3.4,0,0,1,2.7-1.2,4,4,0,0,1,1.6.3,3.8,3.8,0,0,1,2.5,3.7Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M198.1,28.5A15.9,15.9,0,0,0,180.7,32L74.4,138.3h-.1L16,196.7A16,16,0,0,0,27.3,224H192a16,16,0,0,0,16-16V43.3A16,16,0,0,0,198.1,28.5ZM72,163.3V208H27.3ZM192,208H88V147.3l104-104Z" />
        </>
      )}
    </svg>
  );
}

export function PhCellSignalMedium(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M199.7,24.8a19.9,19.9,0,0,0-21.8,4.4L13.2,193.8A20,20,0,0,0,27.3,228H192a20.1,20.1,0,0,0,20-20V43.3A19.9,19.9,0,0,0,199.7,24.8ZM108,133v71H37Zm80,71H132V109l56-56Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M120,216H27.3a8,8,0,0,1-5.6-13.7L120,104Z" />
          </g>
          <path d="M198.1,28.5A15.9,15.9,0,0,0,180.7,32L16,196.7A16,16,0,0,0,27.3,224H192a16,16,0,0,0,16-16V43.3A16,16,0,0,0,198.1,28.5ZM112,123.3V208H27.3ZM192,208H128V107.3l64-64Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M198.1,28.5A15.9,15.9,0,0,0,180.7,32L114.4,98.3h-.1L16,196.7a15.7,15.7,0,0,0-4.7,11.6,4.9,4.9,0,0,0,.1,1.2,14.7,14.7,0,0,0,1.1,4.6,16,16,0,0,0,6.9,7.8l1,.5a10.2,10.2,0,0,0,2.2.9,18.4,18.4,0,0,0,4.7.7H192a16,16,0,0,0,16-16V43.3A16,16,0,0,0,198.1,28.5ZM192,208H128V107.3l64-64Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M197.4,30.4a14,14,0,0,0-15.3,3L115.8,99.7h0L17.4,198.1A14,14,0,0,0,27.3,222H192a14,14,0,0,0,14-14V43.3A13.9,13.9,0,0,0,197.4,30.4ZM25.5,208.8a1.9,1.9,0,0,1,.4-2.2L114,118.5V210H27.3A2,2,0,0,1,25.5,208.8ZM194,208a2,2,0,0,1-2,2H126V106.5l64.6-64.6a1.8,1.8,0,0,1,1.4-.6l.8.2a1.8,1.8,0,0,1,1.2,1.8Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M196.6,32.2a11.8,11.8,0,0,0-13.1,2.6l-66.3,66.4h0L18.8,199.5A12,12,0,0,0,27.3,220H192a12,12,0,0,0,12-12V43.3A11.8,11.8,0,0,0,196.6,32.2ZM23.6,209.5a3.8,3.8,0,0,1,.9-4.3L116,113.6V212H27.3A4,4,0,0,1,23.6,209.5ZM196,208a4,4,0,0,1-4,4H124V105.6l65.2-65.1a3.4,3.4,0,0,1,2.7-1.2,4,4,0,0,1,1.6.3,3.8,3.8,0,0,1,2.5,3.7Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M198.1,28.5A15.9,15.9,0,0,0,180.7,32L16,196.7A16,16,0,0,0,27.3,224H192a16,16,0,0,0,16-16V43.3A16,16,0,0,0,198.1,28.5ZM112,123.3V208H27.3ZM192,208H128V107.3l64-64Z" />
        </>
      )}
    </svg>
  );
}

export function PhCellSignalNone(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M192,228H27.3a20,20,0,0,1-14.1-34.2L177.9,29.2A19.9,19.9,0,0,1,212,43.3V208A20.1,20.1,0,0,1,192,228ZM37,204H188V53Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M192,224H27.3A16,16,0,0,1,16,196.7L180.7,32A16,16,0,0,1,208,43.3V208A16,16,0,0,1,192,224ZM27.3,208H192V43.3L27.3,208Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M192,224H27.3A16,16,0,0,1,16,196.7L180.7,32A16,16,0,0,1,208,43.3V208A16,16,0,0,1,192,224ZM27.3,208H192V43.3L27.3,208Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M192,222H27.3a14,14,0,0,1-9.9-23.9L182.1,33.4A14,14,0,0,1,206,43.3V208A14,14,0,0,1,192,222Zm0-180.7a1.8,1.8,0,0,0-1.4.6L25.9,206.6a1.9,1.9,0,0,0-.4,2.2,2,2,0,0,0,1.8,1.2H192a2,2,0,0,0,2-2V43.3a1.8,1.8,0,0,0-1.2-1.8Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M192,220H27.3a12,12,0,0,1-8.5-20.5L183.5,34.8A12,12,0,0,1,204,43.3V208A12,12,0,0,1,192,220Zm-.1-180.7a3.4,3.4,0,0,0-2.7,1.2L24.5,205.2a3.8,3.8,0,0,0-.9,4.3,4,4,0,0,0,3.7,2.5H192a4,4,0,0,0,4-4V43.3a3.8,3.8,0,0,0-2.5-3.7A4,4,0,0,0,191.9,39.3Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M192,224H27.3A16,16,0,0,1,16,196.7L180.7,32A16,16,0,0,1,208,43.3V208A16,16,0,0,1,192,224ZM27.3,208H192V43.3L27.3,208Z" />
        </>
      )}
    </svg>
  );
}

export function PhCellSignalSlash(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216.9,207.9h0l-160-176A12,12,0,0,0,39.1,48.1l57.1,62.8-83,83A19.9,19.9,0,0,0,27.3,228H208a12,12,0,0,0,12-12A12.4,12.4,0,0,0,216.9,207.9ZM112.3,128.6,180.9,204H37Zm20.3-37.2a12,12,0,0,1,0-17l45.3-45.2A19.9,19.9,0,0,1,212,43.3V147.8a12,12,0,0,1-24,0V53L149.6,91.4A12,12,0,0,1,132.6,91.4Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M200,43.3V208a8,8,0,0,1-8,8H27.3a7.9,7.9,0,0,1-5.6-13.6L186.3,37.7A8,8,0,0,1,200,43.3Z"
            opacity="0.2"
          />
          <path d="M129.8,94.2a7.9,7.9,0,0,1,0-11.3L180.7,32A16,16,0,0,1,208,43.3V159.6a8,8,0,1,1-16,0V43.3L141.1,94.2A7.9,7.9,0,0,1,129.8,94.2ZM216,216a8,8,0,0,1-8,8H27.3A16,16,0,0,1,16,196.7L101.7,111,42.1,45.4a7.9,7.9,0,0,1,.5-11.3,8,8,0,0,1,11.3.5l160,176h0A7.9,7.9,0,0,1,216,216ZM27.3,208H189.9l-77.4-85.2Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M129.5,94a8,8,0,0,1,.3-11.1L180.7,32A16,16,0,0,1,208,43.3V159.6a8,8,0,0,1-5.1,7.5,7.4,7.4,0,0,1-2.9.5,7.9,7.9,0,0,1-5.9-2.6Zm84.4,116.6-160-176a8,8,0,0,0-11.3-.5,7.9,7.9,0,0,0-.5,11.3L101.7,111,16,196.7A16,16,0,0,0,27.3,224H208a8.2,8.2,0,0,0,5.4-2.1A8,8,0,0,0,213.9,210.6Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M131.2,92.8a5.9,5.9,0,0,1,0-8.5l50.9-50.9A14,14,0,0,1,206,43.3V159.6a6,6,0,1,1-12,0V43.3a2,2,0,0,0-1.2-1.8,1.9,1.9,0,0,0-2.2.4L139.7,92.8A5.9,5.9,0,0,1,131.2,92.8ZM214,216a6,6,0,0,1-6,6H27.3a14,14,0,0,1-9.9-23.9L104.5,111,43.6,44a5.9,5.9,0,0,1,8.8-8l160,176h0A5.8,5.8,0,0,1,214,216ZM27.3,210H194.4l-81.9-90.1L25.9,206.6a1.9,1.9,0,0,0-.4,2.2A2,2,0,0,0,27.3,210Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M132.6,91.4a3.9,3.9,0,0,1,0-5.6l50.9-51A12,12,0,0,1,204,43.3V159.6a4,4,0,1,1-8,0V43.3a4,4,0,0,0-2.5-3.7,3.9,3.9,0,0,0-4.3.9l-51,50.9A3.9,3.9,0,0,1,132.6,91.4ZM212,216a4,4,0,0,1-4,4H27.3a12,12,0,0,1-8.5-20.5l88.4-88.4L45,42.7a4.1,4.1,0,0,1,.3-5.7,4.2,4.2,0,0,1,5.7.3l160,176h-.1A3.9,3.9,0,0,1,212,216ZM27.3,212H199l-86.4-95L24.5,205.2a3.9,3.9,0,0,0-.9,4.3A4,4,0,0,0,27.3,212Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M129.8,94.2a7.9,7.9,0,0,1,0-11.3L180.7,32A16,16,0,0,1,208,43.3V159.6a8,8,0,1,1-16,0V43.3L141.1,94.2A7.9,7.9,0,0,1,129.8,94.2ZM216,216a8,8,0,0,1-8,8H27.3A16,16,0,0,1,16,196.7L101.7,111,42.1,45.4a7.9,7.9,0,0,1,.5-11.3,8,8,0,0,1,11.3.5l160,176h0A8.1,8.1,0,0,1,216,216ZM27.3,208H189.9l-77.4-85.2Z" />
        </>
      )}
    </svg>
  );
}

export function PhCellSignalX(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M232.5,143.5A12,12,0,0,1,224,164a12.2,12.2,0,0,1-8.5-3.5L200,145l-15.5,15.5A12,12,0,0,1,176,164a12.2,12.2,0,0,1-8.5-3.5,12,12,0,0,1,0-17L183,128l-15.5-15.5a12,12,0,0,1,17-17L200,111l15.5-15.5a12,12,0,0,1,17,17L217,128ZM200,172a12,12,0,0,0-12,12v20H37L188,53V72a12,12,0,0,0,24,0V43.3a19.9,19.9,0,0,0-34.1-14.1L13.2,193.9a19.7,19.7,0,0,0-4.3,21.8A19.8,19.8,0,0,0,27.3,228H192a20.1,20.1,0,0,0,20-20V184A12,12,0,0,0,200,172Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M200,43.3V208a8,8,0,0,1-8,8H27.3a8,8,0,0,1-5.6-13.7L186.3,37.7A8,8,0,0,1,200,43.3Z" />
          </g>
          <path d="M211.3,128l18.4-18.3a8.1,8.1,0,0,0-11.4-11.4L200,116.7,181.7,98.3a8.1,8.1,0,0,0-11.4,11.4L188.7,128l-18.4,18.3a8.1,8.1,0,0,0,0,11.4,8.2,8.2,0,0,0,11.4,0L200,139.3l18.3,18.4a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4Z" />
          <path d="M200,165.3a8,8,0,0,0-8,8V208H27.3L192,43.3V82.7a8,8,0,0,0,16,0V43.3A16,16,0,0,0,180.7,32L16,196.7A16,16,0,0,0,27.3,224H192a16,16,0,0,0,16-16V173.3A8,8,0,0,0,200,165.3Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M229.7,146.3a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0L200,139.3l-18.3,18.4a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L188.7,128l-18.4-18.3a8.1,8.1,0,0,1,11.4-11.4L200,116.7l18.3-18.4a8.1,8.1,0,0,1,11.4,11.4L211.3,128Zm-26.9,18.5a3.9,3.9,0,0,0-5.6,0L193,169a24,24,0,0,1-17,7,23.6,23.6,0,0,1-17.2-7.3c-9.2-9.5-8.7-24.7.6-34.1l6.7-6.6-6.7-6.6c-9.5-9.6-9.7-25.4,0-34.7A24.1,24.1,0,0,1,193,87l4.2,4.2a3.9,3.9,0,0,0,5.6,0L207,87h0a2.9,2.9,0,0,0,1-2.3V43.8a16.6,16.6,0,0,0-9.2-15A15.9,15.9,0,0,0,180.7,32L16.3,196.4a16.3,16.3,0,0,0-4,17,15.9,15.9,0,0,0,15,10.6H192a16,16,0,0,0,16-16V171.2a3,3,0,0,0-1-2.2h0Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M228.3,147.8a6,6,0,0,1,0,8.4A6.1,6.1,0,0,1,224,158a5.8,5.8,0,0,1-4.2-1.8L200,136.5l-19.7,19.7A6.1,6.1,0,0,1,176,158a6,6,0,0,1-4.2-10.2L191.5,128l-19.7-19.8a5.8,5.8,0,0,1,0-8.4,5.9,5.9,0,0,1,8.5,0L200,119.5l19.8-19.7a6,6,0,0,1,8.5,8.4L208.5,128ZM200,167.3a6,6,0,0,0-6,6V208a2,2,0,0,1-2,2H27.3a2,2,0,0,1-1.8-1.2,1.9,1.9,0,0,1,.4-2.2L190.6,41.9a1.9,1.9,0,0,1,2.2-.4,2,2,0,0,1,1.2,1.8V82.7a6,6,0,0,0,12,0V43.3a14,14,0,0,0-23.9-9.9L17.4,198.1A14,14,0,0,0,27.3,222H192a14,14,0,0,0,14-14V173.3A6,6,0,0,0,200,167.3Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M226.8,149.2a3.9,3.9,0,0,1,0,5.6A3.8,3.8,0,0,1,224,156a3.9,3.9,0,0,1-2.8-1.2L200,133.7l-21.2,21.1A3.8,3.8,0,0,1,176,156a3.9,3.9,0,0,1-2.8-1.2,3.9,3.9,0,0,1,0-5.6L194.3,128l-21.1-21.2a4,4,0,0,1,5.6-5.6L200,122.3l21.2-21.1a4,4,0,1,1,5.6,5.6L205.7,128ZM200,169.3a3.9,3.9,0,0,0-4,4V208a4,4,0,0,1-4,4H27.3a4,4,0,0,1-3.7-2.5,3.9,3.9,0,0,1,.9-4.3L189.2,40.5a3.9,3.9,0,0,1,4.3-.9,4,4,0,0,1,2.5,3.7V82.7a4,4,0,0,0,8,0V43.3a12,12,0,0,0-20.5-8.5L18.8,199.5A12,12,0,0,0,27.3,220H192a12,12,0,0,0,12-12V173.3A3.9,3.9,0,0,0,200,169.3Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M229.7,146.3a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0L200,139.3l-18.3,18.4a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L188.7,128l-18.4-18.3a8.1,8.1,0,0,1,11.4-11.4L200,116.7l18.3-18.4a8.1,8.1,0,0,1,11.4,11.4L211.3,128Zm-29.7,19a8,8,0,0,0-8,8V208H27.3L192,43.3V82.7a8,8,0,0,0,16,0V43.3A16,16,0,0,0,180.7,32L16,196.7A16,16,0,0,0,27.3,224H192a16,16,0,0,0,16-16V173.3A8,8,0,0,0,200,165.3Z" />
        </>
      )}
    </svg>
  );
}

export function PhChalkboardSimple(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M20,160V56A20.1,20.1,0,0,1,40,36H216a20.1,20.1,0,0,1,20,20V160a12,12,0,0,1-24,0V60H44V160a12,12,0,0,1-24,0Zm232,40a12,12,0,0,1-12,12H16a12,12,0,0,1,0-24h92V160a12,12,0,0,1,12-12h64a12,12,0,0,1,12,12v28h44A12,12,0,0,1,252,200ZM132,188h40V172H132Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M184,168v32H120V168Z" opacity="0.2" />
          <path d="M24,168V56A16,16,0,0,1,40,40H216a16,16,0,0,1,16,16V168a8,8,0,0,1-16,0V56H40V168a8,8,0,0,1-16,0Zm224,32a8,8,0,0,1-8,8H16a8,8,0,0,1,0-16h96V168a8,8,0,0,1,8-8h64a8,8,0,0,1,8,8v24h48A8,8,0,0,1,248,200Zm-120-8h48V176H128Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M240,192h-8V56a16,16,0,0,0-16-16H40A16,16,0,0,0,24,56V192H16a8,8,0,0,0,0,16H240a8,8,0,0,0,0-16Zm-40,0H128V176a8,8,0,0,1,8-8h56a8,8,0,0,1,8,8Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M26,168V56A14,14,0,0,1,40,42H216a14,14,0,0,1,14,14V168a6,6,0,0,1-12,0V56a2,2,0,0,0-2-2H40a2,2,0,0,0-2,2V168a6,6,0,0,1-12,0Zm220,32a6,6,0,0,1-6,6H16a6,6,0,0,1,0-12h98V168a6,6,0,0,1,6-6h64a6,6,0,0,1,6,6v26h50A6,6,0,0,1,246,200Zm-120-6h52V174H126Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M28,168V56A12,12,0,0,1,40,44H216a12,12,0,0,1,12,12V168a4,4,0,0,1-8,0V56a4,4,0,0,0-4-4H40a4,4,0,0,0-4,4V168a4,4,0,0,1-8,0Zm216,32a4,4,0,0,1-4,4H16a4,4,0,0,1,0-8H116V168a4,4,0,0,1,4-4h64a4,4,0,0,1,4,4v28h52A4,4,0,0,1,244,200Zm-120-4h56V172H124Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M24,168V56A16,16,0,0,1,40,40H216a16,16,0,0,1,16,16V168a8,8,0,0,1-16,0V56H40V168a8,8,0,0,1-16,0Zm224,32a8,8,0,0,1-8,8H16a8,8,0,0,1,0-16h96V168a8,8,0,0,1,8-8h64a8,8,0,0,1,8,8v24h48A8,8,0,0,1,248,200Zm-120-8h48V176H128Z" />
        </>
      )}
    </svg>
  );
}

export function PhChalkboardTeacher(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M56,84A12,12,0,0,1,68,72H188a12,12,0,0,1,12,12v88a12,12,0,0,1-24,0V96H68A12,12,0,0,1,56,84ZM236,56V200a20.1,20.1,0,0,1-20,20H149.3a11.9,11.9,0,0,1-11.4-8.3,36.1,36.1,0,0,0-67.8.1A12,12,0,0,1,58.7,220H40a20.1,20.1,0,0,1-20-20V56A20.1,20.1,0,0,1,40,36H216A20.1,20.1,0,0,1,236,56ZM104,164a16,16,0,1,0-16-16A16,16,0,0,0,104,164ZM212,60H44V196h6.9a60.8,60.8,0,0,1,21.8-23.2,40,40,0,1,1,62.6,0A60.8,60.8,0,0,1,157.1,196H212Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M192,80v96H104a32,32,0,1,0-32-32H64V80Z" opacity="0.2" />
          <path d="M216,40H40A16,16,0,0,0,24,56V200a16,16,0,0,0,16,16H53.4a7.9,7.9,0,0,0,7.2-4.6,48.1,48.1,0,0,1,86.8,0,7.9,7.9,0,0,0,7.2,4.6H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40ZM80,144a24,24,0,1,1,24,24A24.1,24.1,0,0,1,80,144Zm136,56H159.4a64.6,64.6,0,0,0-28.8-26.2,40,40,0,1,0-53.2,0A64.6,64.6,0,0,0,48.6,200H40V56H216ZM56,96V80a8,8,0,0,1,8-8H192a8,8,0,0,1,8,8v96a8,8,0,0,1-8,8H176a8,8,0,0,1,0-16h8V88H72v8a8,8,0,0,1-16,0Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,40H40A16,16,0,0,0,24,56V200a16,16,0,0,0,16,16H53.4a7.9,7.9,0,0,0,7.2-4.6,48.1,48.1,0,0,1,86.8,0,7.9,7.9,0,0,0,7.2,4.6H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40ZM104,168a32,32,0,1,1,32-32A32.1,32.1,0,0,1,104,168Zm112,32H159.4a63.7,63.7,0,0,0-13.1-16H192a8,8,0,0,0,8-8V80a8,8,0,0,0-8-8H64a8,8,0,0,0-8,8v96a8,8,0,0,0,6,7.7A64.2,64.2,0,0,0,48.6,200H40V56H216Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M216,42H40A14,14,0,0,0,26,56V200a14,14,0,0,0,14,14H53.4a6,6,0,0,0,5.4-3.4,50,50,0,0,1,90.4,0,6,6,0,0,0,5.4,3.4H216a14,14,0,0,0,14-14V56A14,14,0,0,0,216,42ZM78,144a26,26,0,1,1,26,26A26.1,26.1,0,0,1,78,144Zm140,56a2,2,0,0,1-2,2H158.3a62.3,62.3,0,0,0-31.5-27.6,38,38,0,1,0-45.6,0A62.3,62.3,0,0,0,49.7,202H40a2.1,2.1,0,0,1-2-2V56a2,2,0,0,1,2-2H216a2,2,0,0,1,2,2ZM198,80v96a6,6,0,0,1-6,6H176a6,6,0,0,1,0-12h10V86H70V96a6,6,0,0,1-12,0V80a6,6,0,0,1,6-6H192A6,6,0,0,1,198,80Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M216,44H40A12,12,0,0,0,28,56V200a12,12,0,0,0,12,12H53.4a4.1,4.1,0,0,0,3.6-2.3,52,52,0,0,1,94,0,4.1,4.1,0,0,0,3.6,2.3H216a12,12,0,0,0,12-12V56A12,12,0,0,0,216,44Zm4,156a4,4,0,0,1-4,4H157.1a60.4,60.4,0,0,0-34.7-29.1,36,36,0,1,0-36.8,0A60.4,60.4,0,0,0,50.9,204H40a4.1,4.1,0,0,1-4-4V56a4,4,0,0,1,4-4H216a4,4,0,0,1,4,4ZM104,172a28,28,0,1,1,28-28A28.1,28.1,0,0,1,104,172Zm92-92v96a4,4,0,0,1-4,4H176a4,4,0,0,1,0-8h12V84H68V96a4,4,0,0,1-8,0V80a4,4,0,0,1,4-4H192A4,4,0,0,1,196,80Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,40H40A16,16,0,0,0,24,56V200a16,16,0,0,0,16,16H53.4a7.9,7.9,0,0,0,7.2-4.6,48.1,48.1,0,0,1,86.8,0,7.9,7.9,0,0,0,7.2,4.6H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40ZM80,144a24,24,0,1,1,24,24A24.1,24.1,0,0,1,80,144Zm136,56H159.4a64.6,64.6,0,0,0-28.8-26.2,40,40,0,1,0-53.2,0A64.6,64.6,0,0,0,48.6,200H40V56H216ZM56,96V80a8,8,0,0,1,8-8H192a8,8,0,0,1,8,8v96a8,8,0,0,1-8,8H176a8,8,0,0,1,0-16h8V88H72v8a8,8,0,0,1-16,0Z" />
        </>
      )}
    </svg>
  );
}

export function PhChalkboard(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M240,188h-4V56a20.1,20.1,0,0,0-20-20H40A20.1,20.1,0,0,0,20,56V188H16a12,12,0,0,0,0,24H240a12,12,0,0,0,0-24ZM44,60H212V188H196V160a12,12,0,0,0-12-12H120a12,12,0,0,0-12,12v28H84V100h88v20a12,12,0,0,0,24,0V88a12,12,0,0,0-12-12H72A12,12,0,0,0,60,88V188H44ZM172,188H132V172h40Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M192,80v88H120v32H64V80Z" opacity="0.2" />
          <path d="M240,192h-8V56a16,16,0,0,0-16-16H40A16,16,0,0,0,24,56V192H16a8,8,0,0,0,0,16H240a8,8,0,0,0,0-16ZM40,56H216V192H200V168a8,8,0,0,0-8-8H120a8,8,0,0,0-8,8v24H72V88H184v48a8,8,0,0,0,16,0V80a8,8,0,0,0-8-8H64a8,8,0,0,0-8,8V192H40ZM184,192H128V176h56Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M240,192h-8V56a16,16,0,0,0-16-16H40A16,16,0,0,0,24,56V192H16a8,8,0,0,0,0,16H240a8,8,0,0,0,0-16Zm-24,0H144V176a8,8,0,0,1,8-8h56a8,8,0,0,1,8,8Zm0-48a8,8,0,0,1-16,0V72H56V184a8,8,0,0,1-16,0V64a8,8,0,0,1,8-8H208a8,8,0,0,1,8,8Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M240,194H230V56a14,14,0,0,0-14-14H40A14,14,0,0,0,26,56V194H16a6,6,0,0,0,0,12H240a6,6,0,0,0,0-12ZM38,56a2,2,0,0,1,2-2H216a2,2,0,0,1,2,2V194H198V168a6,6,0,0,0-6-6H120a6,6,0,0,0-6,6v26H70V86H186v50a6,6,0,0,0,12,0V80a6,6,0,0,0-6-6H64a6,6,0,0,0-6,6V194H38ZM186,194H126V174h60Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M240,196H228V56a12,12,0,0,0-12-12H40A12,12,0,0,0,28,56V196H16a4,4,0,0,0,0,8H240a4,4,0,0,0,0-8ZM36,56a4,4,0,0,1,4-4H216a4,4,0,0,1,4,4V196H196V168a4,4,0,0,0-4-4H120a4,4,0,0,0-4,4v28H68V84H188v52a4,4,0,0,0,8,0V80a4,4,0,0,0-4-4H64a4,4,0,0,0-4,4V196H36ZM188,196H124V172h64Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M240,192h-8V56a16,16,0,0,0-16-16H40A16,16,0,0,0,24,56V192H16a8,8,0,0,0,0,16H240a8,8,0,0,0,0-16ZM40,56H216V192H200V168a8,8,0,0,0-8-8H120a8,8,0,0,0-8,8v24H72V88H184v48a8,8,0,0,0,16,0V80a8,8,0,0,0-8-8H64a8,8,0,0,0-8,8V192H40ZM184,192H128V176h56Z" />
        </>
      )}
    </svg>
  );
}

export function PhChartBarHorizontal(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216,92H180V56a12,12,0,0,0-12-12H52V40a12,12,0,0,0-24,0V216a12,12,0,0,0,24,0v-4h84a12,12,0,0,0,12-12V164h68a12,12,0,0,0,12-12V104A12,12,0,0,0,216,92ZM156,68V92H52V68ZM124,188H52V164h72Zm80-48H52V116H204Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect x="40" y="104" width="176" height="48" />
          </g>
          <path d="M216,96H176V56a8,8,0,0,0-8-8H48V40a8,8,0,0,0-16,0V216a8,8,0,0,0,16,0v-8h88a8,8,0,0,0,8-8V160h72a8,8,0,0,0,8-8V104A8,8,0,0,0,216,96ZM160,64V96H48V64ZM128,192H48V160h80Zm80-48H48V112H208Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,96H176V56a8,8,0,0,0-8-8H48V40a8,8,0,0,0-16,0V216a8,8,0,0,0,16,0v-8h88a8,8,0,0,0,8-8V160h72a8,8,0,0,0,8-8V104A8,8,0,0,0,216,96ZM160,64V96H48V64ZM128,192H48V160h80Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M216,98H174V56a6,6,0,0,0-6-6H46V40a6,6,0,0,0-12,0V216a6,6,0,0,0,12,0V206h90a6,6,0,0,0,6-6V158h74a6,6,0,0,0,6-6V104A6,6,0,0,0,216,98ZM162,62V98H46V62ZM130,194H46V158h84Zm80-48H46V110H210Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M216,100H172V56a4,4,0,0,0-4-4H44V40a4,4,0,0,0-8,0V216a4,4,0,0,0,8,0V204h92a4,4,0,0,0,4-4V156h76a4,4,0,0,0,4-4V104A4,4,0,0,0,216,100ZM164,60v40H44V60ZM132,196H44V156h88Zm80-48H44V108H212Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,96H176V56a8,8,0,0,0-8-8H48V40a8,8,0,0,0-16,0V216a8,8,0,0,0,16,0v-8h88a8,8,0,0,0,8-8V160h72a8,8,0,0,0,8-8V104A8,8,0,0,0,216,96ZM160,64V96H48V64ZM128,192H48V160h80Zm80-48H48V112H208Z" />
        </>
      )}
    </svg>
  );
}

export function PhChartBar(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M228,196h-4V40a12,12,0,0,0-12-12H156a12,12,0,0,0-12,12V76H100A12,12,0,0,0,88,88v36H44a12,12,0,0,0-12,12v60H28a12,12,0,0,0,0,24H228a12,12,0,0,0,0-24ZM112,100h32v96H112ZM56,148H88v48H56Zm112,48V52h32V196Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect x="156" y="40" width="56" height="168" />
          </g>
          <path d="M228,200h-8V40a8,8,0,0,0-8-8H156a8,8,0,0,0-8,8V80H100a8,8,0,0,0-8,8v40H44a8,8,0,0,0-8,8v64H28a8,8,0,0,0,0,16H228a8,8,0,0,0,0-16ZM108,96h40V200H108ZM52,144H92v56H52Zm112,56V48h40V200Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M228,200h-8V40a8,8,0,0,0-8-8H156a8,8,0,0,0-8,8V80H100a8,8,0,0,0-8,8v40H44a8,8,0,0,0-8,8v64H28a8,8,0,0,0,0,16H228a8,8,0,0,0,0-16ZM108,96h40V200H108ZM52,144H92v56H52Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M228,202H218V40a6,6,0,0,0-6-6H156a6,6,0,0,0-6,6V82H100a6,6,0,0,0-6,6v42H44a6,6,0,0,0-6,6v66H28a6,6,0,0,0,0,12H228a6,6,0,0,0,0-12ZM106,94h44V202H106ZM50,142H94v60H50Zm112,60V46h44V202Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M228,204H216V40a4,4,0,0,0-4-4H156a4,4,0,0,0-4,4V84H100a4,4,0,0,0-4,4v44H44a4,4,0,0,0-4,4v68H28a4,4,0,0,0,0,8H228a4,4,0,0,0,0-8ZM104,92h48V204H104ZM48,140H96v64H48Zm112,64V44h48V204Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M228,200h-8V40a8,8,0,0,0-8-8H156a8,8,0,0,0-8,8V80H100a8,8,0,0,0-8,8v40H44a8,8,0,0,0-8,8v64H28a8,8,0,0,0,0,16H228a8,8,0,0,0,0-16ZM108,96h40V200H108ZM52,144H92v56H52Zm112,56V48h40V200Z" />
        </>
      )}
    </svg>
  );
}

export function PhChartLineUp(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M236,208a12,12,0,0,1-12,12H32a12,12,0,0,1-12-12V48a12,12,0,0,1,24,0v99l43.5-43.5a12,12,0,0,1,17,0L128,127l51-51H168a12,12,0,0,1,0-24h40a12,12,0,0,1,12,12v40a12,12,0,0,1-24,0V93l-59.5,59.5a12,12,0,0,1-17,0L96,129,44,181v15H224A12,12,0,0,1,236,208Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M232,208a8,8,0,0,1-8,8H32a8,8,0,0,1-8-8V48a8,8,0,0,1,16,0V156.7l50.3-50.4a8.1,8.1,0,0,1,11.4,0L128,132.7,188.7,72H168a8,8,0,0,1,0-16h40a8,8,0,0,1,8,8v40a8,8,0,0,1-16,0V83.3l-66.3,66.4a8.1,8.1,0,0,1-11.4,0L96,123.3l-56,56V200H224A8,8,0,0,1,232,208Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M232,208a8,8,0,0,1-8,8H32a8,8,0,0,1-8-8V48a8,8,0,0,1,16,0V156.7l50.3-50.4a8.1,8.1,0,0,1,11.4,0L128,132.7,176.7,84,162.3,69.7a8.4,8.4,0,0,1-1.7-8.8A8.1,8.1,0,0,1,168,56h40a8,8,0,0,1,8,8v40a8,8,0,0,1-4.9,7.4,8.5,8.5,0,0,1-3.1.6,8.3,8.3,0,0,1-5.7-2.3L188,95.3l-54.3,54.4a8.1,8.1,0,0,1-11.4,0L96,123.3l-56,56V200H224A8,8,0,0,1,232,208Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M230,208a6,6,0,0,1-6,6H32a6,6,0,0,1-6-6V48a6,6,0,0,1,12,0V161.5l53.8-53.7a5.8,5.8,0,0,1,8.4,0L128,135.5,193.5,70H168a6,6,0,0,1,0-12h40a6,6,0,0,1,6,6v40a6,6,0,0,1-12,0V78.5l-69.8,69.7a5.8,5.8,0,0,1-8.4,0L96,120.5l-58,58V202H224A6,6,0,0,1,230,208Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M228,208a4,4,0,0,1-4,4H32a4,4,0,0,1-4-4V48a4,4,0,0,1,8,0V166.3l57.2-57.1a3.9,3.9,0,0,1,5.6,0L128,138.3,198.3,68H168a4,4,0,0,1,0-8h40a4,4,0,0,1,4,4v40a4,4,0,0,1-8,0V73.7l-73.2,73.1a3.9,3.9,0,0,1-5.6,0L96,117.7l-60,60V204H224A4,4,0,0,1,228,208Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M232,208a8,8,0,0,1-8,8H32a8,8,0,0,1-8-8V48a8,8,0,0,1,16,0V156.7l50.3-50.4a8.1,8.1,0,0,1,11.4,0L128,132.7,188.7,72H168a8,8,0,0,1,0-16h40a8,8,0,0,1,8,8v40a8,8,0,0,1-16,0V83.3l-66.3,66.4a8.1,8.1,0,0,1-11.4,0L96,123.3l-56,56V200H224A8,8,0,0,1,232,208Z" />
        </>
      )}
    </svg>
  );
}

export function PhChartLine(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M236,208a12,12,0,0,1-12,12H32a12,12,0,0,1-12-12V48a12,12,0,0,1,24,0v85.6L88.1,95a11.9,11.9,0,0,1,15.1-.6l56.2,42.2L216.1,87a12,12,0,0,1,15.8,18l-64,56a11.9,11.9,0,0,1-15.1.6L96.6,119.4,44,165.4V196H224A12,12,0,0,1,236,208Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M232,208a8,8,0,0,1-8,8H32a8,8,0,0,1-8-8V48a8,8,0,0,1,16,0v94.4L90.7,98a8,8,0,0,1,10.1-.4l58.8,44.1L218.7,90a8,8,0,1,1,10.6,12l-64,56a8,8,0,0,1-10.1.4L96.4,114.3,40,163.6V200H224A8,8,0,0,1,232,208Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M232,208a8,8,0,0,1-8,8H32a8,8,0,0,1-8-8V48a8,8,0,0,1,16,0v94.4L90.7,98a8,8,0,0,1,10.1-.4l58.8,44.1L218.7,90a8,8,0,1,1,10.6,12l-64,56a8,8,0,0,1-10.1.4L96.4,114.3,40,163.6V200H224A8,8,0,0,1,232,208Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M230,208a6,6,0,0,1-6,6H32a6,6,0,0,1-6-6V48a6,6,0,0,1,12,0v98.8L92,99.5a6,6,0,0,1,7.6-.3l60.1,45.1L220,91.5a6,6,0,0,1,8,9l-64,56a6,6,0,0,1-7.6.3L96.3,111.7,38,162.7V202H224A6,6,0,0,1,230,208Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M228,208a4,4,0,0,1-4,4H32a4,4,0,0,1-4-4V48a4,4,0,0,1,8,0V151.2L93.4,101a3.9,3.9,0,0,1,5-.2l61.4,46.1L221.4,93a4,4,0,0,1,5.2,6l-64,56a3.9,3.9,0,0,1-5,.2L96.2,109.1,36,161.8V204H224A4,4,0,0,1,228,208Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M232,208a8,8,0,0,1-8,8H32a8,8,0,0,1-8-8V48a8,8,0,0,1,16,0v94.4L90.7,98a8,8,0,0,1,10.1-.4l58.8,44.1L218.7,90a8,8,0,1,1,10.6,12l-64,56a8,8,0,0,1-10.1.4L96.4,114.3,40,163.6V200H224A8,8,0,0,1,232,208Z" />
        </>
      )}
    </svg>
  );
}

export function PhChartPieSlice(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M221.7,74.4a.8.8,0,0,0-.2-.4l-.3-.5A108,108,0,0,0,128,20a12,12,0,0,0-12,12v89.1L38.9,165.6A12,12,0,0,0,34.5,182v.2l.3.5A108,108,0,0,0,236,128,107.1,107.1,0,0,0,221.7,74.4ZM140,44.9a83.8,83.8,0,0,1,54,31.2l-54,31.1ZM128,212a84.4,84.4,0,0,1-66-32L206,96.8A84,84,0,0,1,128,212ZM94,116.5a12,12,0,0,0,6-10.4V40.7A12,12,0,0,0,83,29.8,108.1,108.1,0,0,0,20.6,139.4a12.1,12.1,0,0,0,6.5,9.5,13,13,0,0,0,5.4,1.3,12.5,12.5,0,0,0,6-1.6ZM76,62V99.2L44.7,117.3A84.5,84.5,0,0,1,76,62Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M33.6,145.6A101.9,101.9,0,0,1,32,128,95.9,95.9,0,0,1,96,37.5v72Z" />
          </g>
          <path d="M100,116.5a8.1,8.1,0,0,0,4-7v-72a8,8,0,0,0-10.7-7.6A104.3,104.3,0,0,0,24,128a109.1,109.1,0,0,0,1.7,19,8,8,0,0,0,4.6,5.8,7.6,7.6,0,0,0,3.3.8,8,8,0,0,0,4-1.1ZM88,49.6v55.3L40.1,132.6c-.1-1.6-.1-3.1-.1-4.6A88.3,88.3,0,0,1,88,49.6Z" />
          <path d="M218.3,76.4a.8.8,0,0,1-.2-.4l-.4-.5A103.9,103.9,0,0,0,128,24a8,8,0,0,0-8,8v91.4L40.9,169.1a7.9,7.9,0,0,0-3,10.9v.2l.3.5A104,104,0,0,0,232,128,103.5,103.5,0,0,0,218.3,76.4ZM136,40.4a88.2,88.2,0,0,1,63.9,36.9L136,114.1ZM128,216a88.3,88.3,0,0,1-71.9-37.3L207.9,91.1A88,88,0,0,1,128,216Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M100,116.5a8.1,8.1,0,0,0,4-7v-72a8,8,0,0,0-10.7-7.6A104.3,104.3,0,0,0,24,128a109.1,109.1,0,0,0,1.7,19,8,8,0,0,0,4.6,5.8,7.6,7.6,0,0,0,3.3.8,8,8,0,0,0,4-1.1ZM88,49.6v55.3L40.1,132.6c-.1-1.6-.1-3.1-.1-4.6A88.3,88.3,0,0,1,88,49.6ZM232,128A104,104,0,0,1,38.3,180.7l-.3-.5V180a7.9,7.9,0,0,1,3-10.9L120,123.4V32a8,8,0,0,1,8-8,103.9,103.9,0,0,1,89.7,51.5l.4.5a.8.8,0,0,0,.2.4A103.5,103.5,0,0,1,232,128Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M102,109.5v-72a5.8,5.8,0,0,0-2.5-4.9,6,6,0,0,0-5.5-.8A102.1,102.1,0,0,0,26,128a104.6,104.6,0,0,0,1.7,18.6,5.9,5.9,0,0,0,3.4,4.4,5.7,5.7,0,0,0,2.5.6,5.5,5.5,0,0,0,3-.9l62.4-36A5.9,5.9,0,0,0,102,109.5Zm-12-3.4L38.3,135.9c-.2-2.6-.3-5.3-.3-7.9A90.1,90.1,0,0,1,90,46.4ZM216.5,77.4a.8.8,0,0,0-.2-.4l-.3-.5A101.9,101.9,0,0,0,128,26a6,6,0,0,0-6,6v92.5L41.9,170.8a5.9,5.9,0,0,0-2.2,8.2v.2l.3.5A102,102,0,0,0,230,128,100.9,100.9,0,0,0,216.5,77.4ZM134,38.2a89.9,89.9,0,0,1,68.7,39.7L134,117.6ZM128,218a90.7,90.7,0,0,1-74.8-39.9L208.8,88.3A90,90,0,0,1,128,218Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M100,109.5v-72a3.9,3.9,0,0,0-1.7-3.3,3.8,3.8,0,0,0-3.6-.5A100.2,100.2,0,0,0,28,128a102.1,102.1,0,0,0,1.7,18.3,4.1,4.1,0,0,0,2.2,2.9,5.3,5.3,0,0,0,1.7.4,3.6,3.6,0,0,0,2-.6L98,113A4,4,0,0,0,100,109.5Zm-8-2.3-55.3,32A84.2,84.2,0,0,1,36,128,92.2,92.2,0,0,1,92,43.3Zm122.7-29c0-.1-.1-.1-.1-.2s-.1-.1-.1-.2A100,100,0,0,0,128,28a4,4,0,0,0-4,4v93.7L42.9,172.5a4.1,4.1,0,0,0-1.5,5.5l.2.3.2.4A100,100,0,0,0,228,128,99.1,99.1,0,0,0,214.7,78.2ZM132,36.1a92.1,92.1,0,0,1,73.6,42.5L132,121.1ZM128,220a92.4,92.4,0,0,1-77.6-42.6L209.6,85.5A92,92,0,0,1,128,220Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M100,116.5a8.1,8.1,0,0,0,4-7v-72a8,8,0,0,0-10.7-7.6A104.3,104.3,0,0,0,24,128a109.1,109.1,0,0,0,1.7,19,8,8,0,0,0,4.6,5.8,7.6,7.6,0,0,0,3.3.8,8,8,0,0,0,4-1.1ZM88,49.6v55.3L40.1,132.6c-.1-1.6-.1-3.1-.1-4.6A88.3,88.3,0,0,1,88,49.6ZM218.3,76.4a.8.8,0,0,1-.2-.4l-.4-.5A103.9,103.9,0,0,0,128,24a8,8,0,0,0-8,8v91.4L40.9,169.1a7.9,7.9,0,0,0-3,10.9v.2l.3.5A104,104,0,0,0,232,128,103.5,103.5,0,0,0,218.3,76.4ZM136,40.4a88.2,88.2,0,0,1,63.9,36.9L136,114.1ZM128,216a88.3,88.3,0,0,1-71.9-37.3L207.9,91.1A88,88,0,0,1,128,216Z" />
        </>
      )}
    </svg>
  );
}

export function PhChartPie(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M221.7,74.4a.8.8,0,0,0-.2-.4l-.3-.5A108,108,0,0,0,34.3,181.6l.2.4.2.4A108,108,0,0,0,236,128,107.1,107.1,0,0,0,221.7,74.4ZM194,76.1l-54,31.1V44.9A83.8,83.8,0,0,1,194,76.1ZM116,44.9v76.2L50,159.2A85.3,85.3,0,0,1,44,128,84.1,84.1,0,0,1,116,44.9ZM128,212a83.9,83.9,0,0,1-65.9-32.1L206,96.8A84,84,0,0,1,128,212Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M128,128,44.9,176h0A95.9,95.9,0,0,1,128,32Z" />
          </g>
          <path d="M218.3,76.4a.8.8,0,0,1-.2-.4l-.4-.5a104,104,0,0,0-180,104.1l.2.4.3.4a104,104,0,0,0,180.1-104Zm-18.4.9L136,114.1V40.4A88.2,88.2,0,0,1,199.9,77.3ZM120,40.4v83L48.1,164.9A88,88,0,0,1,120,40.4ZM128,216a88,88,0,0,1-71.9-37.3L207.9,91.1A88,88,0,0,1,128,216Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M218.3,76.4a.8.8,0,0,1-.2-.4l-.4-.5a104,104,0,0,0-180,104.1l.2.4.3.4a104,104,0,0,0,180.1-104Zm-18.4.9L136,114.1V40.4A88.2,88.2,0,0,1,199.9,77.3ZM128,216a88,88,0,0,1-71.9-37.3L207.9,91.1A88,88,0,0,1,128,216Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M216.5,77.4a.8.8,0,0,0-.2-.4l-.3-.5A102,102,0,0,0,39.5,178.6l.2.4.2.3A102,102,0,0,0,230,128,100.9,100.9,0,0,0,216.5,77.4Zm-13.8.5L134,117.6V38.2A89.9,89.9,0,0,1,202.7,77.9ZM122,38.2v86.3L47.2,167.7A90.1,90.1,0,0,1,122,38.2ZM128,218a89.9,89.9,0,0,1-74.7-39.9L208.8,88.3A90,90,0,0,1,128,218Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M214.7,78.2c0-.1-.1-.1-.1-.2s-.1-.1-.1-.2a100,100,0,0,0-173.2,100v.2c.1.1.2.2.2.3A100,100,0,0,0,228,128,99.1,99.1,0,0,0,214.7,78.2Zm-9.1.4L132,121.1v-85A92.1,92.1,0,0,1,205.6,78.6ZM124,36.1v89.6L46.4,170.5A92,92,0,0,1,124,36.1ZM128,220a92,92,0,0,1-77.6-42.6L209.6,85.5A92,92,0,0,1,128,220Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M218.3,76.4a.8.8,0,0,1-.2-.4l-.4-.5a104,104,0,0,0-180,104.1l.2.4.3.4a104,104,0,0,0,180.1-104Zm-18.4.9L136,114.1V40.4A88.2,88.2,0,0,1,199.9,77.3ZM120,40.4v83L48.1,164.9A88,88,0,0,1,120,40.4ZM128,216a88,88,0,0,1-71.9-37.3L207.9,91.1A88,88,0,0,1,128,216Z" />
        </>
      )}
    </svg>
  );
}

export function PhChatCenteredDots(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216,36H40A20.1,20.1,0,0,0,20,56V184a20.1,20.1,0,0,0,20,20H97.2l13.7,22.7a19.7,19.7,0,0,0,17.1,9.8,20,20,0,0,0,17.2-9.8L158.8,204H216a20.1,20.1,0,0,0,20-20V56A20.1,20.1,0,0,0,216,36Zm-4,144H156.5a20.1,20.1,0,0,0-17.1,9.7h0l-11.4,19-11.4-19A20,20,0,0,0,99.5,180H44V60H212ZM84,120a16,16,0,1,1,16,16A16,16,0,0,1,84,120Zm56,0a16,16,0,1,1,16,16A16,16,0,0,1,140,120Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M149.7,195.9l-14.8,24.7a8.1,8.1,0,0,1-13.8,0l-14.8-24.7a7.9,7.9,0,0,0-6.8-3.9H40a8,8,0,0,1-8-8V56a8,8,0,0,1,8-8H216a8,8,0,0,1,8,8V184a8,8,0,0,1-8,8H156.5A7.9,7.9,0,0,0,149.7,195.9Z" />
          </g>
          <path d="M216,40H40A16,16,0,0,0,24,56V184a16,16,0,0,0,16,16H99.5l14.8,24.7a15.9,15.9,0,0,0,27.4,0L156.5,200H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40Zm0,144H156.5a16.2,16.2,0,0,0-13.7,7.8h0L128,216.5l-14.8-24.7A16.1,16.1,0,0,0,99.5,184H40V56H216Z" />
          <circle cx="128" cy="120" r="12" />
          <circle cx="80" cy="120" r="12" />
          <circle cx="176" cy="120" r="12" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,40H40A16,16,0,0,0,24,56V184a16,16,0,0,0,16,16H99.5l14.8,24.7a15.9,15.9,0,0,0,27.4,0L156.5,200H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40ZM80,132a12,12,0,1,1,12-12A12,12,0,0,1,80,132Zm48,0a12,12,0,1,1,12-12A12,12,0,0,1,128,132Zm48,0a12,12,0,1,1,12-12A12,12,0,0,1,176,132Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M216,42H40A14,14,0,0,0,26,56V184a14,14,0,0,0,14,14H99.5a2.1,2.1,0,0,1,1.7,1L116,223.7a14,14,0,0,0,24,0L154.8,199a2.1,2.1,0,0,1,1.7-1H216a14,14,0,0,0,14-14V56A14,14,0,0,0,216,42Zm2,142a2,2,0,0,1-2,2H156.5a14,14,0,0,0-12,6.8l-14.8,24.7a1.9,1.9,0,0,1-3.4,0l-14.8-24.7a14,14,0,0,0-12-6.8H40a2,2,0,0,1-2-2V56a2,2,0,0,1,2-2H216a2,2,0,0,1,2,2ZM90,120a10,10,0,1,1-10-10A10,10,0,0,1,90,120Zm48,0a10,10,0,1,1-10-10A10,10,0,0,1,138,120Zm48,0a10,10,0,1,1-10-10A10,10,0,0,1,186,120Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M216,44H40A12,12,0,0,0,28,56V184a12,12,0,0,0,12,12H99.5a3.9,3.9,0,0,1,3.4,1.9l14.8,24.7a11.9,11.9,0,0,0,20.6,0l14.8-24.7a3.9,3.9,0,0,1,3.4-1.9H216a12,12,0,0,0,12-12V56A12,12,0,0,0,216,44Zm4,140a4,4,0,0,1-4,4H156.5a12.1,12.1,0,0,0-10.3,5.8h0l-14.8,24.7a3.9,3.9,0,0,1-6.8,0l-14.8-24.7A12.1,12.1,0,0,0,99.5,188H40a4,4,0,0,1-4-4V56a4,4,0,0,1,4-4H216a4,4,0,0,1,4,4ZM88,120a8,8,0,1,1-8-8A8,8,0,0,1,88,120Zm48,0a8,8,0,1,1-8-8A8,8,0,0,1,136,120Zm48,0a8,8,0,1,1-8-8A8,8,0,0,1,184,120Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,40H40A16,16,0,0,0,24,56V184a16,16,0,0,0,16,16H99.5l14.8,24.7a15.9,15.9,0,0,0,27.4,0L156.5,200H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40Zm0,144H156.5a16.1,16.1,0,0,0-13.7,7.8h0L128,216.5l-14.8-24.7A16.1,16.1,0,0,0,99.5,184H40V56H216ZM116,120a12,12,0,1,1,12,12A12,12,0,0,1,116,120Zm-48,0a12,12,0,1,1,12,12A12,12,0,0,1,68,120Zm96,0a12,12,0,1,1,12,12A12,12,0,0,1,164,120Z" />
        </>
      )}
    </svg>
  );
}

export function PhChatCenteredText(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M84,100A12,12,0,0,1,96,88h64a12,12,0,0,1,0,24H96A12,12,0,0,1,84,100Zm12,52h64a12,12,0,0,0,0-24H96a12,12,0,0,0,0,24ZM236,56V184a20.1,20.1,0,0,1-20,20H158.8l-13.6,22.7a20,20,0,0,1-17.2,9.8,19.7,19.7,0,0,1-17.1-9.8L97.2,204H40a20.1,20.1,0,0,1-20-20V56A20.1,20.1,0,0,1,40,36H216A20.1,20.1,0,0,1,236,56Zm-24,4H44V180H99.5a20,20,0,0,1,17.1,9.7l11.4,19,11.4-19h0a20.1,20.1,0,0,1,17.1-9.7H212Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M149.7,195.9l-14.8,24.7a8.1,8.1,0,0,1-13.8,0l-14.8-24.7a7.9,7.9,0,0,0-6.8-3.9H40a8,8,0,0,1-8-8V56a8,8,0,0,1,8-8H216a8,8,0,0,1,8,8V184a8,8,0,0,1-8,8H156.5A7.9,7.9,0,0,0,149.7,195.9Z" />
          </g>
          <path d="M96,112h64a8,8,0,0,0,0-16H96a8,8,0,0,0,0,16Z" />
          <path d="M96,144h64a8,8,0,0,0,0-16H96a8,8,0,0,0,0,16Z" />
          <path d="M216,40H40A16,16,0,0,0,24,56V184a16,16,0,0,0,16,16H99.5l14.8,24.7a15.9,15.9,0,0,0,27.4,0L156.5,200H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40Zm0,144H156.5a16.2,16.2,0,0,0-13.7,7.8h0L128,216.5l-14.8-24.7A16.1,16.1,0,0,0,99.5,184H40V56H216Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,40H40A16,16,0,0,0,24,56V184a16,16,0,0,0,16,16H99.5l14.8,24.7a15.9,15.9,0,0,0,27.4,0L156.5,200H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40ZM160,144H96a8,8,0,0,1,0-16h64a8,8,0,0,1,0,16Zm0-32H96a8,8,0,0,1,0-16h64a8,8,0,0,1,0,16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M166,104a6,6,0,0,1-6,6H96a6,6,0,0,1,0-12h64A6,6,0,0,1,166,104Zm-6,26H96a6,6,0,0,0,0,12h64a6,6,0,0,0,0-12Zm70-74V184a14,14,0,0,1-14,14H156.5a2.1,2.1,0,0,0-1.7,1L140,223.7a14,14,0,0,1-24,0L101.2,199a2.1,2.1,0,0,0-1.7-1H40a14,14,0,0,1-14-14V56A14,14,0,0,1,40,42H216A14,14,0,0,1,230,56Zm-12,0a2,2,0,0,0-2-2H40a2,2,0,0,0-2,2V184a2,2,0,0,0,2,2H99.5a14,14,0,0,1,12,6.8l14.8,24.7a1.9,1.9,0,0,0,3.4,0l14.8-24.7a14,14,0,0,1,12-6.8H216a2,2,0,0,0,2-2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M164,104a4,4,0,0,1-4,4H96a4,4,0,0,1,0-8h64A4,4,0,0,1,164,104Zm-4,28H96a4,4,0,0,0,0,8h64a4,4,0,0,0,0-8Zm68-76V184a12,12,0,0,1-12,12H156.5a3.9,3.9,0,0,0-3.4,1.9l-14.8,24.7a11.9,11.9,0,0,1-20.6,0l-14.8-24.7a3.9,3.9,0,0,0-3.4-1.9H40a12,12,0,0,1-12-12V56A12,12,0,0,1,40,44H216A12,12,0,0,1,228,56Zm-8,0a4,4,0,0,0-4-4H40a4,4,0,0,0-4,4V184a4,4,0,0,0,4,4H99.5a12.1,12.1,0,0,1,10.3,5.8l14.8,24.7a3.9,3.9,0,0,0,6.8,0l14.8-24.7h0a12.1,12.1,0,0,1,10.3-5.8H216a4,4,0,0,0,4-4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M88,104a8,8,0,0,1,8-8h64a8,8,0,0,1,0,16H96A8,8,0,0,1,88,104Zm8,40h64a8,8,0,0,0,0-16H96a8,8,0,0,0,0,16ZM232,56V184a16,16,0,0,1-16,16H156.5l-14.8,24.7a15.9,15.9,0,0,1-27.4,0L99.5,200H40a16,16,0,0,1-16-16V56A16,16,0,0,1,40,40H216A16,16,0,0,1,232,56Zm-16,0H40V184H99.5a16.1,16.1,0,0,1,13.7,7.8L128,216.5l14.8-24.7h0a16.1,16.1,0,0,1,13.7-7.8H216Z" />
        </>
      )}
    </svg>
  );
}

export function PhChatCentered(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,236.4a19.6,19.6,0,0,1-17.1-9.7L97.2,204H40a20.1,20.1,0,0,1-20-20V56A20.1,20.1,0,0,1,40,36H216a20.1,20.1,0,0,1,20,20V184a20.1,20.1,0,0,1-20,20H158.8l-13.6,22.7A19.9,19.9,0,0,1,128,236.4ZM44,180H99.5a20,20,0,0,1,17.1,9.7l11.4,19,11.4-19h0a20.1,20.1,0,0,1,17.1-9.7H212V60H44Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M149.7,195.9l-14.8,24.7a8.1,8.1,0,0,1-13.8,0l-14.8-24.7a7.9,7.9,0,0,0-6.8-3.9H40a8,8,0,0,1-8-8V56a8,8,0,0,1,8-8H216a8,8,0,0,1,8,8V184a8,8,0,0,1-8,8H156.5A7.9,7.9,0,0,0,149.7,195.9Z" />
          </g>
          <path d="M128,232.4a15.9,15.9,0,0,1-13.7-7.7L99.5,200H40a16,16,0,0,1-16-16V56A16,16,0,0,1,40,40H216a16,16,0,0,1,16,16V184a16,16,0,0,1-16,16H156.5l-6.8-4.1,6.8,4.1-14.8,24.7A15.9,15.9,0,0,1,128,232.4ZM40,56V184H99.5a16.1,16.1,0,0,1,13.7,7.8L128,216.4l14.8-24.6h0a16.2,16.2,0,0,1,13.7-7.8H216V56Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M232,56V184a16,16,0,0,1-16,16H156.5l-14.8,24.7a16,16,0,0,1-27.4,0L99.5,200H40a16,16,0,0,1-16-16V56A16,16,0,0,1,40,40H216A16,16,0,0,1,232,56Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,230.4a13.9,13.9,0,0,1-12-6.7L101.2,199a2.1,2.1,0,0,0-1.7-1H40a14,14,0,0,1-14-14V56A14,14,0,0,1,40,42H216a14,14,0,0,1,14,14V184a14,14,0,0,1-14,14H156.5a2.1,2.1,0,0,0-1.7,1L140,223.7A13.8,13.8,0,0,1,128,230.4ZM40,54a2,2,0,0,0-2,2V184a2,2,0,0,0,2,2H99.5a14,14,0,0,1,12,6.8l14.8,24.7a2.1,2.1,0,0,0,3.4,0l14.8-24.7h0a14,14,0,0,1,12-6.8H216a2,2,0,0,0,2-2V56a2,2,0,0,0-2-2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,228.4a11.7,11.7,0,0,1-10.3-5.8l-14.8-24.7a3.9,3.9,0,0,0-3.4-1.9H40a12,12,0,0,1-12-12V56A12,12,0,0,1,40,44H216a12,12,0,0,1,12,12V184a12,12,0,0,1-12,12H156.5a3.9,3.9,0,0,0-3.4,1.9l-14.8,24.7A11.7,11.7,0,0,1,128,228.4ZM40,52a4,4,0,0,0-4,4V184a4,4,0,0,0,4,4H99.5a12.1,12.1,0,0,1,10.3,5.8l14.8,24.7a4,4,0,0,0,6.8,0l14.8-24.7h0a12.1,12.1,0,0,1,10.3-5.8H216a4,4,0,0,0,4-4V56a4,4,0,0,0-4-4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,232.4a15.9,15.9,0,0,1-13.7-7.7L99.5,200H40a16,16,0,0,1-16-16V56A16,16,0,0,1,40,40H216a16,16,0,0,1,16,16V184a16,16,0,0,1-16,16H156.5l-6.8-4.1,6.8,4.1-14.8,24.7A15.9,15.9,0,0,1,128,232.4ZM40,56V184H99.5a16.1,16.1,0,0,1,13.7,7.8L128,216.4l14.8-24.6h0a16.1,16.1,0,0,1,13.7-7.8H216V56Z" />
        </>
      )}
    </svg>
  );
}

export function PhChatCircleDots(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,20A108.1,108.1,0,0,0,32.5,178.5l-8.1,28.4a20,20,0,0,0,24.7,24.7l28.4-8.1A108,108,0,1,0,128,20Zm0,192a85.1,85.1,0,0,1-42.9-11.7,11,11,0,0,0-6.1-1.7,12.4,12.4,0,0,0-3.3.4l-26.2,7.5L57,180.3a11.9,11.9,0,0,0-1.3-9.4A84.1,84.1,0,1,1,128,212Zm-12-84a16,16,0,1,1-16-16A16,16,0,0,1,116,128Zm56,0a16,16,0,1,1-16-16A16,16,0,0,1,172,128Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M45.4,177A95.9,95.9,0,1,1,79,210.6h0L45.8,220a7.9,7.9,0,0,1-9.8-9.8L45.4,177Z" />
          </g>
          <path d="M128,24A104,104,0,0,0,36.8,178l-8.5,30A15.9,15.9,0,0,0,48,227.7l30-8.5A104,104,0,1,0,128,24Zm0,192a88.4,88.4,0,0,1-44.9-12.3,8.7,8.7,0,0,0-4.1-1.1,8.3,8.3,0,0,0-2.2.3l-33.2,9.5,9.5-33.2a8.2,8.2,0,0,0-.8-6.3A88,88,0,1,1,128,216Z" />
          <circle cx="128" cy="128" r="12" />
          <circle cx="80" cy="128" r="12" />
          <circle cx="176" cy="128" r="12" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,24A104,104,0,0,0,36.8,178l-8.5,29.9a16.1,16.1,0,0,0,4,15.8,15.8,15.8,0,0,0,15.7,4l30-8.5A104,104,0,1,0,128,24ZM80,140a12,12,0,1,1,12-12A12,12,0,0,1,80,140Zm48,0a12,12,0,1,1,12-12A12,12,0,0,1,128,140Zm48,0a12,12,0,1,1,12-12A12,12,0,0,1,176,140Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,26A102,102,0,0,0,39,177.8l-8.8,30.7a13.9,13.9,0,0,0,17.3,17.3L78.2,217A102,102,0,1,0,128,26Zm0,192a89,89,0,0,1-45.9-12.6,6.8,6.8,0,0,0-3.1-.8,4.1,4.1,0,0,0-1.6.2l-33.2,9.5a2,2,0,0,1-2.5-2.5l9.5-33.2a6,6,0,0,0-.6-4.7A90,90,0,1,1,128,218ZM90,128a10,10,0,1,1-10-10A10,10,0,0,1,90,128Zm48,0a10,10,0,1,1-10-10A10,10,0,0,1,138,128Zm48,0a10,10,0,1,1-10-10A10,10,0,0,1,186,128Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,28A100,100,0,0,0,41.1,177.5l-9,31.6a12,12,0,0,0,14.8,14.8l31.6-9A100,100,0,1,0,128,28Zm0,192a91.3,91.3,0,0,1-47-12.9,4.6,4.6,0,0,0-2-.5H77.9l-33.2,9.5a4,4,0,0,1-4.9-4.9l9.5-33.2a4.1,4.1,0,0,0-.4-3.1A92,92,0,1,1,128,220ZM88,128a8,8,0,1,1-8-8A8,8,0,0,1,88,128Zm48,0a8,8,0,1,1-8-8A8,8,0,0,1,136,128Zm48,0a8,8,0,1,1-8-8A8,8,0,0,1,184,128Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,24A104,104,0,0,0,36.8,178l-8.5,30A15.9,15.9,0,0,0,48,227.7l30-8.5A104,104,0,1,0,128,24Zm0,192a88.4,88.4,0,0,1-44.9-12.3,8.7,8.7,0,0,0-4.1-1.1,8.3,8.3,0,0,0-2.2.3l-33.2,9.5,9.5-33.2a8.2,8.2,0,0,0-.8-6.3A88,88,0,1,1,128,216Zm12-88a12,12,0,1,1-12-12A12,12,0,0,1,140,128Zm-48,0a12,12,0,1,1-12-12A12,12,0,0,1,92,128Zm96,0a12,12,0,1,1-12-12A12,12,0,0,1,188,128Z" />
        </>
      )}
    </svg>
  );
}

export function PhChatCircleText(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,20A108.1,108.1,0,0,0,32.5,178.5l-8.1,28.4a20,20,0,0,0,24.7,24.7l28.4-8.1A108,108,0,1,0,128,20Zm0,192a85.1,85.1,0,0,1-42.9-11.7,11,11,0,0,0-6.1-1.7,12.4,12.4,0,0,0-3.3.4l-26.2,7.5L57,180.3a11.9,11.9,0,0,0-1.3-9.4A85.1,85.1,0,0,1,44,128a84,84,0,1,1,84,84Zm44-104a12,12,0,0,1-12,12H96a12,12,0,0,1,0-24h64A12,12,0,0,1,172,108Zm0,40a12,12,0,0,1-12,12H96a12,12,0,0,1,0-24h64A12,12,0,0,1,172,148Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M45.4,177A95.9,95.9,0,1,1,79,210.6h0L45.8,220a7.9,7.9,0,0,1-9.8-9.8L45.4,177Z" />
          </g>
          <path d="M128,24A104,104,0,0,0,36.8,178l-8.5,30A15.9,15.9,0,0,0,48,227.7l30-8.5A104,104,0,1,0,128,24Zm0,192a88.4,88.4,0,0,1-44.9-12.3,8.7,8.7,0,0,0-4.1-1.1,8.3,8.3,0,0,0-2.2.3l-33.2,9.5,9.5-33.2a8.2,8.2,0,0,0-.8-6.3A88,88,0,1,1,128,216Z" />
          <path d="M160,104H96a8,8,0,0,0,0,16h64a8,8,0,0,0,0-16Z" />
          <path d="M160,136H96a8,8,0,0,0,0,16h64a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,24A104,104,0,0,0,36.8,178l-8.5,29.9a16.1,16.1,0,0,0,4,15.8,15.8,15.8,0,0,0,15.7,4l30-8.5A104,104,0,1,0,128,24Zm32,128H96a8,8,0,0,1,0-16h64a8,8,0,0,1,0,16Zm0-32H96a8,8,0,0,1,0-16h64a8,8,0,0,1,0,16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,26A102,102,0,0,0,39,177.8l-8.8,30.7a13.9,13.9,0,0,0,17.3,17.3L78.2,217A102,102,0,1,0,128,26Zm0,192a90,90,0,0,1-45.9-12.6,6.8,6.8,0,0,0-3.1-.8,4.1,4.1,0,0,0-1.6.2l-33.2,9.5a2,2,0,0,1-2.5-2.5l9.5-33.2a6,6,0,0,0-.6-4.7A90,90,0,1,1,128,218Zm38-106a6,6,0,0,1-6,6H96a6,6,0,0,1,0-12h64A6,6,0,0,1,166,112Zm0,32a6,6,0,0,1-6,6H96a6,6,0,0,1,0-12h64A6,6,0,0,1,166,144Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,28A100,100,0,0,0,41.1,177.5l-9,31.6a12,12,0,0,0,14.8,14.8l31.6-9A100,100,0,1,0,128,28Zm0,192a91.3,91.3,0,0,1-47-12.9,4.6,4.6,0,0,0-2-.5H77.9l-33.2,9.5a4,4,0,0,1-4.9-4.9l9.5-33.2a4.1,4.1,0,0,0-.4-3.1A92,92,0,1,1,128,220Zm36-108a4,4,0,0,1-4,4H96a4,4,0,0,1,0-8h64A4,4,0,0,1,164,112Zm0,32a4,4,0,0,1-4,4H96a4,4,0,0,1,0-8h64A4,4,0,0,1,164,144Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,24A104,104,0,0,0,36.8,178l-8.5,30A15.9,15.9,0,0,0,48,227.7l30-8.5A104,104,0,1,0,128,24Zm0,192a88.4,88.4,0,0,1-44.9-12.3,8.7,8.7,0,0,0-4.1-1.1,8.3,8.3,0,0,0-2.2.3l-33.2,9.5,9.5-33.2a8.2,8.2,0,0,0-.8-6.3A88,88,0,1,1,128,216Zm40-104a8,8,0,0,1-8,8H96a8,8,0,0,1,0-16h64A8,8,0,0,1,168,112Zm0,32a8,8,0,0,1-8,8H96a8,8,0,0,1,0-16h64A8,8,0,0,1,168,144Z" />
        </>
      )}
    </svg>
  );
}

export function PhChatCircle(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,236a107.9,107.9,0,0,1-50.5-12.5l-28.4,8.1a20,20,0,0,1-24.7-24.7l8.1-28.4A108,108,0,1,1,128,236ZM79,198.6a11,11,0,0,1,6.1,1.7A85.1,85.1,0,0,0,128,212a84,84,0,1,0-84-84,85.1,85.1,0,0,0,11.7,42.9,11.9,11.9,0,0,1,1.3,9.4l-7.5,26.2L75.7,199A12.4,12.4,0,0,1,79,198.6Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M45.4,177A95.9,95.9,0,1,1,79,210.6h0L45.8,220a7.9,7.9,0,0,1-9.8-9.8L45.4,177Z" />
          </g>
          <path d="M128,232a103.6,103.6,0,0,1-50-12.8l-30,8.5A15.9,15.9,0,0,1,28.3,208l8.5-30A104,104,0,1,1,128,232ZM79,202.6a8.7,8.7,0,0,1,4.1,1.1,88,88,0,1,0-30.8-30.8,8.2,8.2,0,0,1,.8,6.3l-9.5,33.2,33.2-9.5A8.3,8.3,0,0,1,79,202.6Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M232,128A104,104,0,0,1,78,219.2l-30,8.5A15.9,15.9,0,0,1,28.3,208l8.5-30A104,104,0,1,1,232,128Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,230a101.9,101.9,0,0,1-49.8-13l-30.7,8.8a13.9,13.9,0,0,1-17.3-17.3L39,177.8A102,102,0,1,1,128,230ZM79,204.6a6.8,6.8,0,0,1,3.1.8,90,90,0,1,0-31.5-31.5,6,6,0,0,1,.6,4.7l-9.5,33.2a2,2,0,0,0,2.5,2.5l33.2-9.5A4.1,4.1,0,0,1,79,204.6Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,228a99.6,99.6,0,0,1-49.5-13.1l-31.6,9a12,12,0,0,1-14.8-14.8l9-31.6A100,100,0,1,1,128,228ZM79,206.6a4.6,4.6,0,0,1,2,.5A92,92,0,1,0,48.9,175a4.1,4.1,0,0,1,.4,3.1l-9.5,33.2a4,4,0,0,0,4.9,4.9l33.2-9.5Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,232a103.6,103.6,0,0,1-50-12.8l-30,8.5A15.9,15.9,0,0,1,28.3,208l8.5-30A104,104,0,1,1,128,232ZM79,202.6a8.7,8.7,0,0,1,4.1,1.1,88,88,0,1,0-30.8-30.8,8.2,8.2,0,0,1,.8,6.3l-9.5,33.2,33.2-9.5A8.3,8.3,0,0,1,79,202.6Z" />
        </>
      )}
    </svg>
  );
}

export function PhChatDots(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216,44H40A20.1,20.1,0,0,0,20,64V222.8A19.9,19.9,0,0,0,31.6,241a21,21,0,0,0,8.5,1.9,19.6,19.6,0,0,0,12.8-4.8L84,212H216a20.1,20.1,0,0,0,20-20V64A20.1,20.1,0,0,0,216,44Zm-4,144H82.5a20.3,20.3,0,0,0-12.9,4.7h0L44,214.2V68H212ZM84,128a16,16,0,1,1,16,16A16,16,0,0,1,84,128Zm56,0a16,16,0,1,1,16,16A16,16,0,0,1,140,128Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M78,201.3,45.1,228.9A8,8,0,0,1,32,222.8V64a8,8,0,0,1,8-8H216a8,8,0,0,1,8,8V192a8,8,0,0,1-8,8H81.7Z" />
          </g>
          <path d="M82.5,208H216a16,16,0,0,0,16-16V64a16,16,0,0,0-16-16H40A16,16,0,0,0,24,64V222.8a15.7,15.7,0,0,0,9.2,14.5,15.1,15.1,0,0,0,6.8,1.6,16,16,0,0,0,10.3-3.8L82.5,208M216,192H82.5a15.6,15.6,0,0,0-10.3,3.8h0L40,222.8V64H216Z" />
          <circle cx="128" cy="128" r="12" />
          <circle cx="80" cy="128" r="12" />
          <circle cx="176" cy="128" r="12" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,48H40A16,16,0,0,0,24,64V222.8a15.7,15.7,0,0,0,9.3,14.5,14.7,14.7,0,0,0,6.7,1.6,16,16,0,0,0,10.3-3.8l31.8-26.7L216,208a16,16,0,0,0,16-16V64A16,16,0,0,0,216,48ZM80,140a12,12,0,1,1,12-12A12,12,0,0,1,80,140Zm48,0a12,12,0,1,1,12-12A12,12,0,0,1,128,140Zm48,0a12,12,0,1,1,12-12A12,12,0,0,1,176,140Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M216,50H40A14,14,0,0,0,26,64V222.8a13.9,13.9,0,0,0,8.1,12.7,13.7,13.7,0,0,0,5.9,1.3,13.9,13.9,0,0,0,9-3.3l32.2-27h0a2.1,2.1,0,0,1,1.3-.5H216a14,14,0,0,0,14-14V64A14,14,0,0,0,216,50Zm2,142a2,2,0,0,1-2,2H82.5a14.1,14.1,0,0,0-9,3.3L41.3,224.4a2.1,2.1,0,0,1-3.3-1.6V64a2,2,0,0,1,2-2H216a2,2,0,0,1,2,2ZM90,128a10,10,0,1,1-10-10A10,10,0,0,1,90,128Zm48,0a10,10,0,1,1-10-10A10,10,0,0,1,138,128Zm48,0a10,10,0,1,1-10-10A10,10,0,0,1,186,128Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M216,52H40A12,12,0,0,0,28,64V222.8a12,12,0,0,0,6.9,10.9,11.7,11.7,0,0,0,5.1,1.1,11.6,11.6,0,0,0,7.7-2.8l32.2-27.1a4.1,4.1,0,0,1,2.6-.9H216a12,12,0,0,0,12-12V64A12,12,0,0,0,216,52Zm4,140a4,4,0,0,1-4,4H82.5a12,12,0,0,0-7.7,2.8h0L42.6,225.9a4,4,0,0,1-6.6-3.1V64a4,4,0,0,1,4-4H216a4,4,0,0,1,4,4ZM88,128a8,8,0,1,1-8-8A8,8,0,0,1,88,128Zm48,0a8,8,0,1,1-8-8A8,8,0,0,1,136,128Zm48,0a8,8,0,1,1-8-8A8,8,0,0,1,184,128Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,48H40A16,16,0,0,0,24,64V222.8a15.7,15.7,0,0,0,9.2,14.5,15.1,15.1,0,0,0,6.8,1.6,16,16,0,0,0,10.3-3.8L82.5,208H216a16,16,0,0,0,16-16V64A16,16,0,0,0,216,48Zm0,144H82.5a15.6,15.6,0,0,0-10.3,3.8L40,222.8V64H216ZM116,128a12,12,0,1,1,12,12A12,12,0,0,1,116,128Zm-48,0a12,12,0,1,1,12,12A12,12,0,0,1,68,128Zm96,0a12,12,0,1,1,12,12A12,12,0,0,1,164,128Z" />
        </>
      )}
    </svg>
  );
}

export function PhChatTeardropDots(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M132,20A104.1,104.1,0,0,0,28,124v84.3A19.7,19.7,0,0,0,47.7,228H132a104,104,0,0,0,0-208Zm0,184H52V124a80,80,0,1,1,80,80Zm-32-76a16,16,0,1,1-16-16A16,16,0,0,1,100,128Zm48,0a16,16,0,1,1-16-16A16,16,0,0,1,148,128Zm48,0a16,16,0,1,1-16-16A16,16,0,0,1,196,128Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M132,216H47.7a7.6,7.6,0,0,1-7.7-7.7V124a92,92,0,0,1,92-92h0a92,92,0,0,1,92,92h0A92,92,0,0,1,132,216Z" />
          </g>
          <path d="M132,24A100.2,100.2,0,0,0,32,124v84.3A15.7,15.7,0,0,0,47.7,224H132a100,100,0,0,0,0-200Zm0,184H48V124a84,84,0,1,1,84,84Z" />
          <circle cx="132" cy="128" r="12" />
          <circle cx="84" cy="128" r="12" />
          <circle cx="180" cy="128" r="12" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M132,24A100.2,100.2,0,0,0,32,124v84.3A15.7,15.7,0,0,0,47.7,224H132a100,100,0,0,0,0-200ZM84,140a12,12,0,1,1,12-12A12,12,0,0,1,84,140Zm48,0a12,12,0,1,1,12-12A12,12,0,0,1,132,140Zm48,0a12,12,0,1,1,12-12A12,12,0,0,1,180,140Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M132,26a98.2,98.2,0,0,0-98,98v84.3A13.7,13.7,0,0,0,47.7,222H132a98,98,0,0,0,0-196Zm0,184H47.7a1.7,1.7,0,0,1-1.7-1.7V124a86,86,0,1,1,86,86ZM94,128a10,10,0,1,1-10-10A10,10,0,0,1,94,128Zm48,0a10,10,0,1,1-10-10A10,10,0,0,1,142,128Zm48,0a10,10,0,1,1-10-10A10,10,0,0,1,190,128Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M132,28a96.2,96.2,0,0,0-96,96v84.3A11.7,11.7,0,0,0,47.7,220H132a96,96,0,0,0,0-192Zm0,184H47.7a3.6,3.6,0,0,1-3.7-3.7V124a88,88,0,1,1,88,88ZM92,128a8,8,0,1,1-8-8A8,8,0,0,1,92,128Zm48,0a8,8,0,1,1-8-8A8,8,0,0,1,140,128Zm48,0a8,8,0,1,1-8-8A8,8,0,0,1,188,128Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M132,24A100.2,100.2,0,0,0,32,124v84.3A15.7,15.7,0,0,0,47.7,224H132a100,100,0,0,0,0-200Zm0,184H48V124a84,84,0,1,1,84,84Zm12-80a12,12,0,1,1-12-12A12,12,0,0,1,144,128Zm-48,0a12,12,0,1,1-12-12A12,12,0,0,1,96,128Zm96,0a12,12,0,1,1-12-12A12,12,0,0,1,192,128Z" />
        </>
      )}
    </svg>
  );
}

export function PhChatTeardropText(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M132,20A104.1,104.1,0,0,0,28,124v84.3A19.7,19.7,0,0,0,47.7,228H132a104,104,0,0,0,0-208Zm0,184H52V124a80,80,0,1,1,80,80Zm40-96a12,12,0,0,1-12,12H100a12,12,0,0,1,0-24h60A12,12,0,0,1,172,108Zm0,40a12,12,0,0,1-12,12H100a12,12,0,0,1,0-24h60A12,12,0,0,1,172,148Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M132,216H47.7a7.6,7.6,0,0,1-7.7-7.7V124a92,92,0,0,1,92-92h0a92,92,0,0,1,92,92h0A92,92,0,0,1,132,216Z" />
          </g>
          <path d="M132,24A100.2,100.2,0,0,0,32,124v84.3A15.7,15.7,0,0,0,47.7,224H132a100,100,0,0,0,0-200Zm0,184H48V124a84,84,0,1,1,84,84Z" />
          <path d="M160,104H100a8,8,0,0,0,0,16h60a8,8,0,0,0,0-16Z" />
          <path d="M160,136H100a8,8,0,0,0,0,16h60a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M132,24A100.2,100.2,0,0,0,32,124v84.3A15.7,15.7,0,0,0,47.7,224H132a100,100,0,0,0,0-200Zm28,128H100a8,8,0,0,1,0-16h60a8,8,0,0,1,0,16Zm0-32H100a8,8,0,0,1,0-16h60a8,8,0,0,1,0,16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M132,26a98.2,98.2,0,0,0-98,98v84.3A13.7,13.7,0,0,0,47.7,222H132a98,98,0,0,0,0-196Zm0,184H47.7a1.7,1.7,0,0,1-1.7-1.7V124a86,86,0,1,1,86,86Zm34-98a6,6,0,0,1-6,6H100a6,6,0,0,1,0-12h60A6,6,0,0,1,166,112Zm0,32a6,6,0,0,1-6,6H100a6,6,0,0,1,0-12h60A6,6,0,0,1,166,144Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M132,28a96.2,96.2,0,0,0-96,96v84.3A11.7,11.7,0,0,0,47.7,220H132a96,96,0,0,0,0-192Zm0,184H47.7a3.6,3.6,0,0,1-3.7-3.7V124a88,88,0,1,1,88,88Zm32-100a4,4,0,0,1-4,4H100a4,4,0,0,1,0-8h60A4,4,0,0,1,164,112Zm0,32a4,4,0,0,1-4,4H100a4,4,0,0,1,0-8h60A4,4,0,0,1,164,144Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M132,24A100.2,100.2,0,0,0,32,124v84.3A15.7,15.7,0,0,0,47.7,224H132a100,100,0,0,0,0-200Zm0,184H48V124a84,84,0,1,1,84,84Zm36-96a8,8,0,0,1-8,8H100a8,8,0,0,1,0-16h60A8,8,0,0,1,168,112Zm0,32a8,8,0,0,1-8,8H100a8,8,0,0,1,0-16h60A8,8,0,0,1,168,144Z" />
        </>
      )}
    </svg>
  );
}

export function PhChatTeardrop(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M132,228H47.7A19.7,19.7,0,0,1,28,208.3V124A104,104,0,1,1,132,228ZM52,204h80a80,80,0,1,0-80-80Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M132,216H47.7a7.6,7.6,0,0,1-7.7-7.7V124a92,92,0,0,1,92-92h0a92,92,0,0,1,92,92h0A92,92,0,0,1,132,216Z" />
          </g>
          <path d="M132,224H47.7A15.7,15.7,0,0,1,32,208.3V124A100,100,0,1,1,132,224ZM48,208h84a84,84,0,1,0-84-84Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M232,124A100.2,100.2,0,0,1,132,224H47.7A15.7,15.7,0,0,1,32,208.3V124a100,100,0,0,1,200,0Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M132,222H47.7A13.7,13.7,0,0,1,34,208.3V124a98,98,0,1,1,98,98Zm0-184a86.1,86.1,0,0,0-86,86v84.3a1.7,1.7,0,0,0,1.7,1.7H132a86,86,0,0,0,0-172Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M132,220H47.7A11.7,11.7,0,0,1,36,208.3V124a96,96,0,1,1,96,96Zm0-184a88.1,88.1,0,0,0-88,88v84.3a3.6,3.6,0,0,0,3.7,3.7H132a88,88,0,0,0,0-176Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M132,224H47.7A15.7,15.7,0,0,1,32,208.3V124A100,100,0,1,1,132,224ZM48,208h84a84,84,0,1,0-84-84Z" />
        </>
      )}
    </svg>
  );
}

export function PhChatText(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216,44H40A20.1,20.1,0,0,0,20,64V222.8A19.9,19.9,0,0,0,31.6,241a21,21,0,0,0,8.5,1.9,19.6,19.6,0,0,0,12.8-4.8L84,212H216a20.1,20.1,0,0,0,20-20V64A20.1,20.1,0,0,0,216,44Zm-4,144H81.7a11.4,11.4,0,0,0-4.1.7l-3.7,1.4a10,10,0,0,0-3.6,2.1L44,214.2V68H212ZM84,108A12,12,0,0,1,96,96h64a12,12,0,0,1,0,24H96A12,12,0,0,1,84,108Zm0,40a12,12,0,0,1,12-12h64a12,12,0,0,1,0,24H96A12,12,0,0,1,84,148Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M78,201.3,45.1,228.9A8,8,0,0,1,32,222.8V64a8,8,0,0,1,8-8H216a8,8,0,0,1,8,8V192a8,8,0,0,1-8,8H81.7Z" />
          </g>
          <path d="M216,48H40A16,16,0,0,0,24,64V222.8a15.7,15.7,0,0,0,9.2,14.5,16.5,16.5,0,0,0,6.8,1.6,16,16,0,0,0,10.3-3.8L82,208.4l1.1-.4H216a16,16,0,0,0,16-16V64A16,16,0,0,0,216,48Zm0,144H81.7a8.7,8.7,0,0,0-2.8.5l-3.7,1.3a10,10,0,0,0-2.4,1.4L40,222.8V64H216Z" />
          <path d="M96,120h64a8,8,0,0,0,0-16H96a8,8,0,0,0,0,16Z" />
          <path d="M96,152h64a8,8,0,0,0,0-16H96a8,8,0,0,0,0,16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,48H40A16,16,0,0,0,24,64V222.8a15.7,15.7,0,0,0,9.3,14.5,16,16,0,0,0,17-2.2L82,208.4l134-.4a16,16,0,0,0,16-16V64A16,16,0,0,0,216,48ZM160,152H96a8,8,0,0,1,0-16h64a8,8,0,0,1,0,16Zm0-32H96a8,8,0,0,1,0-16h64a8,8,0,0,1,0,16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M216,50H40A14,14,0,0,0,26,64V222.8a13.9,13.9,0,0,0,8.1,12.7,13.7,13.7,0,0,0,5.9,1.3,13.9,13.9,0,0,0,9-3.3l32-26.9,1.7-.6H216a14,14,0,0,0,14-14V64A14,14,0,0,0,216,50Zm2,142a2,2,0,0,1-2,2H81.7a5.2,5.2,0,0,0-2.1.4l-3.7,1.3a5.8,5.8,0,0,0-1.8,1.1L41.3,224.4a2.1,2.1,0,0,1-3.3-1.6V64a2,2,0,0,1,2-2H216a2,2,0,0,1,2,2Zm-52-80a6,6,0,0,1-6,6H96a6,6,0,0,1,0-12h64A6,6,0,0,1,166,112Zm0,32a6,6,0,0,1-6,6H96a6,6,0,0,1,0-12h64A6,6,0,0,1,166,144Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M216,52H40A12,12,0,0,0,28,64V222.8a12,12,0,0,0,6.9,10.9,11.7,11.7,0,0,0,5.1,1.1,11.6,11.6,0,0,0,7.7-2.8L80,204.9l2.4-.9H216a12,12,0,0,0,12-12V64A12,12,0,0,0,216,52Zm4,140a4,4,0,0,1-4,4H81.7l-1.4.2-3.7,1.4a3.7,3.7,0,0,0-1.2.7L42.6,225.9a4,4,0,0,1-6.6-3.1V64a4,4,0,0,1,4-4H216a4,4,0,0,1,4,4Zm-56-80a4,4,0,0,1-4,4H96a4,4,0,0,1,0-8h64A4,4,0,0,1,164,112Zm0,32a4,4,0,0,1-4,4H96a4,4,0,0,1,0-8h64A4,4,0,0,1,164,144Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,48H40A16,16,0,0,0,24,64V222.8a15.7,15.7,0,0,0,9.2,14.5,16.5,16.5,0,0,0,6.8,1.6,16,16,0,0,0,10.3-3.8l31.8-26.7,1-.4H216a16,16,0,0,0,16-16V64A16,16,0,0,0,216,48Zm0,144H81.7a8.7,8.7,0,0,0-2.8.5l-3.7,1.3a10,10,0,0,0-2.4,1.4L40,222.8V64H216ZM88,112a8,8,0,0,1,8-8h64a8,8,0,0,1,0,16H96A8,8,0,0,1,88,112Zm0,32a8,8,0,0,1,8-8h64a8,8,0,0,1,0,16H96A8,8,0,0,1,88,144Z" />
        </>
      )}
    </svg>
  );
}

export function PhChat(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M40.1,242.9a21,21,0,0,1-8.5-1.9A19.9,19.9,0,0,1,20,222.8V64A20.1,20.1,0,0,1,40,44H216a20.1,20.1,0,0,1,20,20V192a20.1,20.1,0,0,1-20,20H84L52.9,238.1A20.2,20.2,0,0,1,40.1,242.9ZM44,68V214.2l25.6-21.5h0A20.3,20.3,0,0,1,82.5,188H212V68Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M78,201.3,45.1,228.9A8,8,0,0,1,32,222.8V64a8,8,0,0,1,8-8H216a8,8,0,0,1,8,8V192a8,8,0,0,1-8,8H81.7Z" />
          </g>
          <path d="M40,238.8a16.4,16.4,0,0,1-6.8-1.5A15.7,15.7,0,0,1,24,222.8V64A16,16,0,0,1,40,48H216a16,16,0,0,1,16,16V192a16,16,0,0,1-16,16H82.5l-5.2-6.1,5.2,6.1L50.3,235.1A15.9,15.9,0,0,1,40,238.8ZM40,64V222.8l32.2-27h0A15.6,15.6,0,0,1,82.5,192H216V64Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M232,64V192a16,16,0,0,1-16,16l-134,.4L50.3,235.1a16,16,0,0,1-17,2.2A15.7,15.7,0,0,1,24,222.8V64A16,16,0,0,1,40,48H216A16,16,0,0,1,232,64Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M40,236.8a13.7,13.7,0,0,1-5.9-1.3A13.9,13.9,0,0,1,26,222.8V64A14,14,0,0,1,40,50H216a14,14,0,0,1,14,14V192a14,14,0,0,1-14,14H82.5a2.1,2.1,0,0,0-1.3.5h0L49,233.5A13.9,13.9,0,0,1,40,236.8ZM40,62a2,2,0,0,0-2,2V222.8a2.1,2.1,0,0,0,3.3,1.6l32.2-27.1h0a14.1,14.1,0,0,1,9-3.3H216a2,2,0,0,0,2-2V64a2,2,0,0,0-2-2ZM77.4,201.9Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M40,234.8a11.7,11.7,0,0,1-5.1-1.1A12,12,0,0,1,28,222.8V64A12,12,0,0,1,40,52H216a12,12,0,0,1,12,12V192a12,12,0,0,1-12,12H82.5a4.1,4.1,0,0,0-2.6.9L47.7,232A11.6,11.6,0,0,1,40,234.8ZM40,60a4,4,0,0,0-4,4V222.8a4,4,0,0,0,6.6,3.1l32.2-27.1h0a12,12,0,0,1,7.7-2.8H216a4,4,0,0,0,4-4V64a4,4,0,0,0-4-4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M40,238.8a16.4,16.4,0,0,1-6.8-1.5A15.7,15.7,0,0,1,24,222.8V64A16,16,0,0,1,40,48H216a16,16,0,0,1,16,16V192a16,16,0,0,1-16,16H82.5L50.3,235.1A15.9,15.9,0,0,1,40,238.8ZM40,64V222.8l32.2-27A15.6,15.6,0,0,1,82.5,192H216V64ZM77.4,201.9Z" />
        </>
      )}
    </svg>
  );
}

export function PhChatsCircle(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M234.8,190.2A84,84,0,0,0,172.3,68.9,84,84,0,1,0,21.2,142.2l-5.8,20.1a18.1,18.1,0,0,0,22.3,22.3l20.1-5.8a81.8,81.8,0,0,0,25.9,8.3,84,84,0,0,0,114.5,39.7l20.1,5.8a18.1,18.1,0,0,0,22.3-22.3ZM59.3,153.9a11.4,11.4,0,0,0-3.3.5l-14.5,4.1L45.6,144a12,12,0,0,0-1.2-9.4,60,60,0,1,1,21,21A12.2,12.2,0,0,0,59.3,153.9Zm152.3,28.7a12,12,0,0,0-1.2,9.4l4.1,14.5L200,202.4a12,12,0,0,0-9.4,1.2,60,60,0,0,1-79.6-17A84.1,84.1,0,0,0,180,104a83,83,0,0,0-.5-8.7,59.9,59.9,0,0,1,32.1,87.3Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M92.1,175.9a72,72,0,0,0,104.7,38h0l24.8,7.1a6,6,0,0,0,7.4-7.4l-7.1-24.8h0a72,72,0,0,0-58-108.7h0A70.2,70.2,0,0,1,168,104a72,72,0,0,1-72,72Z" />
          </g>
          <path d="M230.5,189.8A80,80,0,0,0,169.6,72.6,80,80,0,1,0,25.5,141.8l-6.2,21.6a13.9,13.9,0,0,0,17.3,17.3l21.6-6.2a80.8,80.8,0,0,0,28.2,8.9,80,80,0,0,0,111.4,39.1l21.6,6.2a13.9,13.9,0,0,0,17.3-17.3ZM59.3,157.9a7.8,7.8,0,0,0-2.2.3l-21.4,6.2,6.1-21.5a7.9,7.9,0,0,0-.8-6.2A64,64,0,1,1,96,168a64.8,64.8,0,0,1-32.7-8.9A7.2,7.2,0,0,0,59.3,157.9Zm155.8,26.8a7.6,7.6,0,0,0-.9,6.2l6.2,21.5L199,206.2a7.7,7.7,0,0,0-6.3.9,64.2,64.2,0,0,1-88.4-23.5A80.2,80.2,0,0,0,176,104a84.5,84.5,0,0,0-1.3-14.3A64,64,0,0,1,224,152,64.8,64.8,0,0,1,215.1,184.7Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M230.5,189.8A80,80,0,0,0,169.6,72.6,80,80,0,1,0,25.5,141.8l-6.2,21.6a13.9,13.9,0,0,0,17.3,17.3l21.6-6.2a80.8,80.8,0,0,0,28.2,8.9,80,80,0,0,0,111.4,39.1l21.6,6.2a13.9,13.9,0,0,0,17.3-17.3Zm-15.4-5.1a7.6,7.6,0,0,0-.9,6.2l6.2,21.5L199,206.2a7.7,7.7,0,0,0-6.3.9,64.2,64.2,0,0,1-88.4-23.5A80.2,80.2,0,0,0,176,104a84.5,84.5,0,0,0-1.3-14.3A64,64,0,0,1,224,152,64.8,64.8,0,0,1,215.1,184.7Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M228.4,189.5A78,78,0,0,0,168.2,74.4,78,78,0,1,0,27.6,141.5L21.2,164A12,12,0,0,0,36,178.8l22.5-6.4a77.3,77.3,0,0,0,29.3,9.2,78,78,0,0,0,109.7,38.8l22.5,6.4A11.9,11.9,0,0,0,234.8,212ZM59.3,159.9a4.7,4.7,0,0,0-1.7.3l-24.9,7.1,7.1-24.9a5.6,5.6,0,0,0-.6-4.7,65.9,65.9,0,1,1,23.1,23.1A5.5,5.5,0,0,0,59.3,159.9Zm156.9,30.5,7.1,24.9-24.9-7.1a5.6,5.6,0,0,0-4.7.6,66.1,66.1,0,0,1-92.6-27A78.1,78.1,0,0,0,174,104a78.9,78.9,0,0,0-1.9-16.9,66.1,66.1,0,0,1,44.7,98.6A5.6,5.6,0,0,0,216.2,190.4Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M226.2,189.3a76,76,0,0,0-59.4-113,76,76,0,1,0-137,65l-6.7,23.2a10.1,10.1,0,0,0,12.4,12.4l23.2-6.7a76.6,76.6,0,0,0,30.5,9.5,76,76,0,0,0,108.1,38.5l23.2,6.7a10.1,10.1,0,0,0,12.4-12.4ZM59.3,161.9l-1.1.2-24.9,7.1a2,2,0,0,1-2.5-2.5l7.1-24.8a4,4,0,0,0-.4-3.2,68,68,0,1,1,23.8,23.8A3.6,3.6,0,0,0,59.3,161.9Zm165.4,54.8a2.2,2.2,0,0,1-2,.5l-24.9-7.1a3.8,3.8,0,0,0-3.1.4A68.1,68.1,0,0,1,98,180a76,76,0,0,0,71.5-95.3,68,68,0,0,1,49,102,3.8,3.8,0,0,0-.4,3.1l7.1,24.9A2.2,2.2,0,0,1,224.7,216.7Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M230.5,189.8A80,80,0,0,0,169.6,72.6,80,80,0,1,0,25.5,141.8l-6.2,21.6a13.9,13.9,0,0,0,17.3,17.3l21.6-6.2a80.8,80.8,0,0,0,28.2,8.9,80,80,0,0,0,111.4,39.1l21.6,6.2a13.9,13.9,0,0,0,17.3-17.3ZM59.3,157.9a7.8,7.8,0,0,0-2.2.3l-21.5,6.2,6.2-21.5a7.6,7.6,0,0,0-.9-6.2A64.8,64.8,0,0,1,32,104a64,64,0,1,1,64,64,64.8,64.8,0,0,1-32.7-8.9A7.2,7.2,0,0,0,59.3,157.9Zm155.8,26.8a7.6,7.6,0,0,0-.9,6.2l6.2,21.5-21.5-6.2a7.6,7.6,0,0,0-6.2.9,64.2,64.2,0,0,1-88.4-23.5A80.2,80.2,0,0,0,176,104a84.5,84.5,0,0,0-1.3-14.3A64,64,0,0,1,224,152,64.8,64.8,0,0,1,215.1,184.7Z" />
        </>
      )}
    </svg>
  );
}

export function PhChatsTeardrop(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M172.3,68.9A84,84,0,0,0,12,104v66a18.1,18.1,0,0,0,18,18H84.1A84.3,84.3,0,0,0,160,236h66a18.1,18.1,0,0,0,18-18V152A84,84,0,0,0,172.3,68.9ZM36,104a60,60,0,1,1,60,60H36ZM220,212H160a60.2,60.2,0,0,1-49-25.4A84.1,84.1,0,0,0,180,104a81.4,81.4,0,0,0-.5-8.7A59.9,59.9,0,0,1,220,152Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M163.9,80.1A70.2,70.2,0,0,1,168,104h0a72,72,0,0,1-72,72H92.1A72,72,0,0,0,160,224h66a6,6,0,0,0,6-6V152a72,72,0,0,0-68.1-71.9Z" />
          </g>
          <path d="M169.6,72.6A80,80,0,0,0,16,104v66a14,14,0,0,0,14,14H86.7A80.2,80.2,0,0,0,160,232h66a14,14,0,0,0,14-14V152A79.8,79.8,0,0,0,169.6,72.6ZM32,104a64,64,0,1,1,64,64H32ZM224,216H160a64.2,64.2,0,0,1-55.7-32.4A80.2,80.2,0,0,0,176,104a83.6,83.6,0,0,0-1.3-14.3A64,64,0,0,1,224,152Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M169.6,72.6A80,80,0,0,0,16,104v66a14,14,0,0,0,14,14H86.7A80.2,80.2,0,0,0,160,232h66a14,14,0,0,0,14-14V152A79.8,79.8,0,0,0,169.6,72.6ZM224,216H160a64.2,64.2,0,0,1-55.7-32.4A80.2,80.2,0,0,0,176,104a83.6,83.6,0,0,0-1.3-14.3A64,64,0,0,1,224,152Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M168.2,74.4A78,78,0,0,0,18,104v66a12,12,0,0,0,12,12H88a78,78,0,0,0,72,48h66a12,12,0,0,0,12-12V152A78,78,0,0,0,168.2,74.4ZM30,104a66,66,0,1,1,66,66H30ZM226,218H160a66,66,0,0,1-58.9-36.2A78.1,78.1,0,0,0,174,104a78.9,78.9,0,0,0-1.9-16.9A66,66,0,0,1,226,152Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M166.8,76.3A76,76,0,0,0,20,104v66a10,10,0,0,0,10,10H89.3A76.3,76.3,0,0,0,160,228h66a10,10,0,0,0,10-10V152A75.9,75.9,0,0,0,166.8,76.3ZM28,170V104a68,68,0,1,1,68,68H30A2,2,0,0,1,28,170Zm200,48a2,2,0,0,1-2,2H160a68.1,68.1,0,0,1-62-40.1,75.9,75.9,0,0,0,71.5-95.2A67.9,67.9,0,0,1,228,152Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M169.6,72.6A80,80,0,0,0,16,104v66a14,14,0,0,0,14,14H86.7A80.2,80.2,0,0,0,160,232h66a14,14,0,0,0,14-14V152A79.8,79.8,0,0,0,169.6,72.6ZM32,104a64,64,0,1,1,64,64H32ZM224,216H160a64.2,64.2,0,0,1-55.7-32.4A80.2,80.2,0,0,0,176,104a83.6,83.6,0,0,0-1.3-14.3A64,64,0,0,1,224,152Z" />
        </>
      )}
    </svg>
  );
}

export function PhChats(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M236,96a20.1,20.1,0,0,0-20-20H188V48a20.1,20.1,0,0,0-20-20H40A20.1,20.1,0,0,0,20,48V176a12,12,0,0,0,12,12,11.5,11.5,0,0,0,7.5-2.7l28.5-23V184a20.1,20.1,0,0,0,20,20h92.2l36.3,29.3A11.5,11.5,0,0,0,224,236a12,12,0,0,0,12-12ZM44,150.9V52H164V88h0v44H71.6a11.8,11.8,0,0,0-7.6,2.7Zm148,31.8a11.8,11.8,0,0,0-7.6-2.7H92V156h76a20.1,20.1,0,0,0,20-20V100h24v98.9Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M80,144v40a8,8,0,0,0,8,8h96.4L224,224V96a8,8,0,0,0-8-8H176v48a8,8,0,0,1-8,8Z" />
          </g>
          <path d="M232,96a16,16,0,0,0-16-16H184V48a16,16,0,0,0-16-16H40A16,16,0,0,0,24,48V176a7.9,7.9,0,0,0,4.6,7.2,8.1,8.1,0,0,0,3.4.8,7.7,7.7,0,0,0,5-1.8L72,154v30a16,16,0,0,0,16,16h93.6L219,230.2a7.7,7.7,0,0,0,5,1.8,8.5,8.5,0,0,0,3.5-.8A8,8,0,0,0,232,224ZM66.6,137.8,40,159.2V48H168V88h0v48H71.6A7.7,7.7,0,0,0,66.6,137.8Zm122.9,48a7.9,7.9,0,0,0-5.1-1.8H88V152h80a16,16,0,0,0,16-16V96h32V207.2Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M232,96a16,16,0,0,0-16-16H184V48a16,16,0,0,0-16-16H40A16,16,0,0,0,24,48V176a7.9,7.9,0,0,0,4.6,7.2,8.1,8.1,0,0,0,3.4.8,7.7,7.7,0,0,0,5-1.8L72,154v30a16,16,0,0,0,16,16h93.6L219,230.2a7.7,7.7,0,0,0,5,1.8,8.5,8.5,0,0,0,3.5-.8A8,8,0,0,0,232,224Zm-42.5,89.8a7.9,7.9,0,0,0-5.1-1.8H88V152h80a16,16,0,0,0,16-16V96h32V207.2Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M230,96a14,14,0,0,0-14-14H182V48a14,14,0,0,0-14-14H40A14,14,0,0,0,26,48V176a6,6,0,0,0,3.4,5.4,5.8,5.8,0,0,0,2.6.6,6.7,6.7,0,0,0,3.8-1.3L73.7,150H74v34a14,14,0,0,0,14,14h94.3l37.9,30.7A6.7,6.7,0,0,0,224,230a5.8,5.8,0,0,0,2.6-.6A6,6,0,0,0,230,224ZM71.6,138a6.5,6.5,0,0,0-3.8,1.3L38,163.4V48a2,2,0,0,1,2-2H168a2,2,0,0,1,2,2v88a2,2,0,0,1-2,2Zm116.6,49.3a6.5,6.5,0,0,0-3.8-1.3H88a2,2,0,0,1-2-2V150h82a14,14,0,0,0,14-14V94h34a2,2,0,0,1,2,2V211.4Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M228,96a12,12,0,0,0-12-12H180V48a12,12,0,0,0-12-12H40A12,12,0,0,0,28,48V176a4.1,4.1,0,0,0,2.3,3.6,3.3,3.3,0,0,0,1.7.4,3.8,3.8,0,0,0,2.5-.9L73,148h3v36a12,12,0,0,0,12,12h95l38.5,31.1a3.8,3.8,0,0,0,2.5.9,3.3,3.3,0,0,0,1.7-.4A4.1,4.1,0,0,0,228,224ZM71.6,140a3.8,3.8,0,0,0-2.5.9L36,167.6V48a4,4,0,0,1,4-4H168a4,4,0,0,1,4,4V88h0v48a4,4,0,0,1-4,4Zm115.3,48.9a3.8,3.8,0,0,0-2.5-.9H88a4,4,0,0,1-4-4V148h84a12,12,0,0,0,12-12V92h36a4,4,0,0,1,4,4V215.6Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M232,96a16,16,0,0,0-16-16H184V48a16,16,0,0,0-16-16H40A16,16,0,0,0,24,48V176a7.9,7.9,0,0,0,4.6,7.2,8.1,8.1,0,0,0,3.4.8,7.7,7.7,0,0,0,5-1.8L72,154v30a16,16,0,0,0,16,16h93.6L219,230.2a7.8,7.8,0,0,0,8.4,1A7.9,7.9,0,0,0,232,224ZM66.6,137.8,40,159.2V48H168v88H71.6A7.7,7.7,0,0,0,66.6,137.8Zm122.8,48a7.7,7.7,0,0,0-5-1.8H88V152h80a16,16,0,0,0,16-16V96h32V207.2Z" />
        </>
      )}
    </svg>
  );
}

export function PhCheckCircle(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M180.7,95.7a12,12,0,0,1-.4,17l-58.7,56a12,12,0,0,1-8.3,3.3,12.3,12.3,0,0,1-8.3-3.3l-29.3-28a12,12,0,1,1,16.6-17.4l21,20.1,50.4-48.1A12,12,0,0,1,180.7,95.7ZM236,128A108,108,0,1,1,128,20,108.1,108.1,0,0,1,236,128Zm-24,0a84,84,0,1,0-84,84A84.1,84.1,0,0,0,212,128Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="128" cy="128" r="96" />
          </g>
          <path d="M166.5,98.2l-53.2,50.7L89.5,126.2a8,8,0,1,0-11,11.6l29.3,28a7.9,7.9,0,0,0,5.5,2.2,8.1,8.1,0,0,0,5.6-2.2l58.6-56a8,8,0,0,0-11-11.6Z" />
          <path d="M128,24A104,104,0,1,0,232,128,104.1,104.1,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm49.5,85.8-58.6,56a8.1,8.1,0,0,1-5.6,2.2,7.7,7.7,0,0,1-5.5-2.2l-29.3-28a8,8,0,1,1,11-11.6l23.8,22.7,53.2-50.7a8,8,0,0,1,11,11.6Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M176.3,99.9a5.9,5.9,0,0,1-.2,8.4l-58.6,56a6.3,6.3,0,0,1-4.2,1.7,6,6,0,0,1-4.1-1.7l-29.3-28a5.9,5.9,0,0,1,8.2-8.6l25.2,24,54.6-52A5.9,5.9,0,0,1,176.3,99.9ZM230,128A102,102,0,1,1,128,26,102.2,102.2,0,0,1,230,128Zm-12,0a90,90,0,1,0-90,90A90.1,90.1,0,0,0,218,128Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M174.9,101.2a4.1,4.1,0,0,1-.1,5.7l-58.7,56a4.3,4.3,0,0,1-2.8,1.1,3.9,3.9,0,0,1-2.7-1.1l-29.4-28a4,4,0,1,1,5.6-5.8l26.5,25.4,55.9-53.4A4.1,4.1,0,0,1,174.9,101.2ZM228,128A100,100,0,1,1,128,28,100.2,100.2,0,0,1,228,128Zm-8,0a92,92,0,1,0-92,92A92.1,92.1,0,0,0,220,128Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M177.8,98.5a8,8,0,0,1-.3,11.3l-58.6,56a8.1,8.1,0,0,1-5.6,2.2,7.9,7.9,0,0,1-5.5-2.2l-29.3-28a8,8,0,1,1,11-11.6l23.8,22.7,53.2-50.7A8,8,0,0,1,177.8,98.5ZM232,128A104,104,0,1,1,128,24,104.2,104.2,0,0,1,232,128Zm-16,0a88,88,0,1,0-88,88A88.1,88.1,0,0,0,216,128Z" />
        </>
      )}
    </svg>
  );
}

export function PhCheckSquareOffset(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M224,52V204a20.1,20.1,0,0,1-20,20H135.6a12,12,0,0,1,0-24H200V56H56v87.3a12,12,0,0,1-24,0V52A20.1,20.1,0,0,1,52,32H204A20.1,20.1,0,0,1,224,52Zm-87.5,91.5a12,12,0,0,0-17,0L64,199,40.5,175.5a12,12,0,0,0-17,17l32,32a12.1,12.1,0,0,0,17,0l64-64A12,12,0,0,0,136.5,143.5Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <polygon points="44 44 44 196 60 212 212 212 212 44 44 44" />
          </g>
          <path d="M204,36H52A16,16,0,0,0,36,52v91.3a8,8,0,0,0,16,0V52H204V204H135.6a8,8,0,0,0,0,16H204a16,16,0,0,0,16-16V52A16,16,0,0,0,204,36Z" />
          <path d="M133.7,146.3a8.1,8.1,0,0,0-11.4,0L64,204.7,37.7,178.3a8.1,8.1,0,0,0-11.4,11.4l32,32a8.2,8.2,0,0,0,11.4,0l64-64A8.1,8.1,0,0,0,133.7,146.3Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M220,52V204a16,16,0,0,1-16,16H135.6a8,8,0,0,1,0-16H204V52H52v91.3a8,8,0,0,1-16,0V52A16,16,0,0,1,52,36H204A16,16,0,0,1,220,52Zm-86.3,94.3a8.1,8.1,0,0,0-11.4,0L64,204.7,37.7,178.3a8.1,8.1,0,0,0-11.4,11.4l32,32a8.2,8.2,0,0,0,11.4,0l64-64A8.1,8.1,0,0,0,133.7,146.3Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M218,52V204a14,14,0,0,1-14,14H135.6a6,6,0,0,1,0-12H204a2,2,0,0,0,2-2V52a2,2,0,0,0-2-2H52a2,2,0,0,0-2,2v91.3a6,6,0,0,1-12,0V52A14,14,0,0,1,52,38H204A14,14,0,0,1,218,52Zm-85.8,95.8a5.8,5.8,0,0,0-8.4,0L64,207.5,36.2,179.8a5.9,5.9,0,1,0-8.4,8.4l32,32a5.8,5.8,0,0,0,8.4,0l64-64A5.8,5.8,0,0,0,132.2,147.8Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M216,52V204a12,12,0,0,1-12,12H135.6a4,4,0,0,1,0-8H204a4,4,0,0,0,4-4V52a4,4,0,0,0-4-4H52a4,4,0,0,0-4,4v91.3a4,4,0,0,1-8,0V52A12,12,0,0,1,52,40H204A12,12,0,0,1,216,52Zm-85.2,97.2a3.9,3.9,0,0,0-5.6,0L64,210.3,34.8,181.2a4,4,0,0,0-5.6,5.6l32,32a3.9,3.9,0,0,0,5.6,0l64-64A3.9,3.9,0,0,0,130.8,149.2Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M220,52V204a16,16,0,0,1-16,16H135.6a8,8,0,0,1,0-16H204V52H52v91.3a8,8,0,0,1-16,0V52A16,16,0,0,1,52,36H204A16,16,0,0,1,220,52Zm-86.3,94.3a8.1,8.1,0,0,0-11.4,0L64,204.7,37.7,178.3a8.1,8.1,0,0,0-11.4,11.4l32,32a8.2,8.2,0,0,0,11.4,0l64-64A8.1,8.1,0,0,0,133.7,146.3Z" />
        </>
      )}
    </svg>
  );
}

export function PhCheckSquare(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M75.7,140.7a12,12,0,1,1,16.6-17.4l21,20.1,50.4-48.1a12,12,0,1,1,16.6,17.4l-58.7,56a12,12,0,0,1-8.3,3.3,12.2,12.2,0,0,1-8.3-3.3ZM228,48V208a20.1,20.1,0,0,1-20,20H48a20.1,20.1,0,0,1-20-20V48A20.1,20.1,0,0,1,48,28H208A20.1,20.1,0,0,1,228,48Zm-24,4H52V204H204Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect x="40" y="40" width="176" height="176" rx="8" />
          </g>
          <path d="M166.5,98.2l-53.2,50.7L89.5,126.2a8,8,0,1,0-11,11.6l29.3,28a7.9,7.9,0,0,0,5.5,2.2,8.1,8.1,0,0,0,5.6-2.2l58.6-56a8,8,0,0,0-11-11.6Z" />
          <path d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32Zm0,176H48V48H208V208Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32Zm-30.5,77.8-58.6,56a8.1,8.1,0,0,1-5.6,2.2,7.9,7.9,0,0,1-5.5-2.2l-29.3-28a8,8,0,1,1,11-11.6l23.8,22.7,53.2-50.7a8,8,0,0,1,11,11.6Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M176.3,99.9a5.9,5.9,0,0,1-.2,8.4l-58.6,56a6.3,6.3,0,0,1-4.2,1.7,5.8,5.8,0,0,1-4.1-1.7l-29.3-28a5.9,5.9,0,0,1,8.2-8.6l25.2,24,54.6-52A5.9,5.9,0,0,1,176.3,99.9ZM222,48V208a14,14,0,0,1-14,14H48a14,14,0,0,1-14-14V48A14,14,0,0,1,48,34H208A14,14,0,0,1,222,48Zm-12,0a2,2,0,0,0-2-2H48a2,2,0,0,0-2,2V208a2,2,0,0,0,2,2H208a2,2,0,0,0,2-2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M174.9,101.2a4.1,4.1,0,0,1-.1,5.7l-58.7,56a4.3,4.3,0,0,1-2.8,1.1,3.9,3.9,0,0,1-2.7-1.1l-29.4-28a4,4,0,1,1,5.6-5.8l26.5,25.4,55.9-53.4A4.1,4.1,0,0,1,174.9,101.2ZM220,48V208a12,12,0,0,1-12,12H48a12,12,0,0,1-12-12V48A12,12,0,0,1,48,36H208A12,12,0,0,1,220,48Zm-8,0a4,4,0,0,0-4-4H48a4,4,0,0,0-4,4V208a4,4,0,0,0,4,4H208a4,4,0,0,0,4-4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M177.8,98.5a8,8,0,0,1-.3,11.3l-58.6,56a8.1,8.1,0,0,1-5.6,2.2,7.9,7.9,0,0,1-5.5-2.2l-29.3-28a8,8,0,1,1,11-11.6l23.8,22.7,53.2-50.7A8,8,0,0,1,177.8,98.5ZM224,48V208a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V48A16,16,0,0,1,48,32H208A16,16,0,0,1,224,48ZM208,208V48H48V208Z" />
        </>
      )}
    </svg>
  );
}

export function PhCheck(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M104,196a12.2,12.2,0,0,1-8.5-3.5l-56-56a12,12,0,0,1,17-17L104,167,207.5,63.5a12,12,0,0,1,17,17l-112,112A12.2,12.2,0,0,1,104,196Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M104,192a8.5,8.5,0,0,1-5.7-2.3l-56-56a8.1,8.1,0,0,1,11.4-11.4L104,172.7,210.3,66.3a8.1,8.1,0,0,1,11.4,11.4l-112,112A8.5,8.5,0,0,1,104,192Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M104,192a8.5,8.5,0,0,1-5.7-2.3l-56-56a8.1,8.1,0,0,1,11.4-11.4L104,172.7,210.3,66.3a8.1,8.1,0,0,1,11.4,11.4l-112,112A8.5,8.5,0,0,1,104,192Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M104,190a5.8,5.8,0,0,1-4.2-1.8l-56-56a5.9,5.9,0,0,1,8.4-8.4L104,175.5,211.8,67.8a5.9,5.9,0,0,1,8.4,8.4l-112,112A5.8,5.8,0,0,1,104,190Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M104,188a3.9,3.9,0,0,1-2.8-1.2l-56-56a4,4,0,1,1,5.6-5.6L104,178.3,213.2,69.2a4,4,0,1,1,5.6,5.6l-112,112A3.9,3.9,0,0,1,104,188Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M104,192a8.5,8.5,0,0,1-5.7-2.3l-56-56a8.1,8.1,0,0,1,11.4-11.4L104,172.7,210.3,66.3a8.1,8.1,0,0,1,11.4,11.4l-112,112A8.5,8.5,0,0,1,104,192Z" />
        </>
      )}
    </svg>
  );
}

export function PhChecks(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M152.5,92.5l-88,88a12.1,12.1,0,0,1-17,0l-44-44a12,12,0,0,1,17-17L56,155l79.5-79.5a12,12,0,0,1,17,17Zm100-17a12,12,0,0,0-17,0L156,155l-7.6-7.5a12,12,0,1,0-16.9,16.9l16,16.1a12.1,12.1,0,0,0,17,0l88-88A12,12,0,0,0,252.5,75.5Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M153.7,89.7l-88,88a8.2,8.2,0,0,1-11.4,0l-44-44a8.1,8.1,0,0,1,11.4-11.4L60,160.7l82.3-82.4a8.1,8.1,0,0,1,11.4,11.4Zm92-11.4a8.1,8.1,0,0,0-11.4,0L152,160.7,134.3,143A8,8,0,0,0,123,154.3l23.3,23.4a8.2,8.2,0,0,0,11.4,0l88-88A8.1,8.1,0,0,0,245.7,78.3Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M153.7,89.7l-88,88a8.2,8.2,0,0,1-11.4,0l-44-44a8.1,8.1,0,0,1,11.4-11.4L60,160.7l82.3-82.4a8.1,8.1,0,0,1,11.4,11.4Zm92-11.4a8.1,8.1,0,0,0-11.4,0L152,160.7,134.3,143A8,8,0,0,0,123,154.3l23.3,23.4a8.2,8.2,0,0,0,11.4,0l88-88A8.1,8.1,0,0,0,245.7,78.3Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M152.2,88.2l-88,88a5.8,5.8,0,0,1-8.4,0l-44-44a5.8,5.8,0,0,1,0-8.4,5.8,5.8,0,0,1,8.4,0L60,163.5l83.8-83.7a5.9,5.9,0,0,1,8.4,8.4Zm92-8.4a5.8,5.8,0,0,0-8.4,0L152,163.5l-19.1-19.1a6,6,0,0,0-8.5,8.5l23.4,23.3a5.8,5.8,0,0,0,8.4,0l88-88A5.8,5.8,0,0,0,244.2,79.8Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M150.8,86.8l-88,88a3.9,3.9,0,0,1-5.6,0l-44-44a4,4,0,1,1,5.6-5.6L60,166.3l85.2-85.1a4,4,0,1,1,5.6,5.6Zm92-5.6a3.9,3.9,0,0,0-5.6,0L152,166.3l-20.5-20.5a4,4,0,0,0-5.7,5.7l23.4,23.3a3.9,3.9,0,0,0,5.6,0l88-88A3.9,3.9,0,0,0,242.8,81.2Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M153.7,89.7l-88,88a8.2,8.2,0,0,1-11.4,0l-44-44a8.1,8.1,0,0,1,11.4-11.4L60,160.7l82.3-82.4a8.1,8.1,0,0,1,11.4,11.4Zm92-11.4a8.1,8.1,0,0,0-11.4,0L152,160.7,134.3,143A8,8,0,0,0,123,154.3l23.3,23.4a8.2,8.2,0,0,0,11.4,0l88-88A8.1,8.1,0,0,0,245.7,78.3Z" />
        </>
      )}
    </svg>
  );
}

export function PhCircleDashed(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M68.6,51.6a12,12,0,0,1,0,17,84.1,84.1,0,0,0-21.7,37.6,12.1,12.1,0,0,1-11.6,9,10.6,10.6,0,0,1-3.1-.5,11.9,11.9,0,0,1-8.5-14.6A107.8,107.8,0,0,1,51.6,51.6,12.2,12.2,0,0,1,68.6,51.6ZM46.9,149.7A12,12,0,0,0,23.7,156a108.8,108.8,0,0,0,27.9,48.4,12.2,12.2,0,0,0,8.5,3.5,12,12,0,0,0,8.5-20.5A83.7,83.7,0,0,1,46.9,149.7Zm102.8,59.5a83,83,0,0,1-43.4-.1,12,12,0,0,0-14.7,8.5,11.9,11.9,0,0,0,8.4,14.7,107.4,107.4,0,0,0,28.1,3.7,105.3,105.3,0,0,0,27.9-3.7,12,12,0,0,0-6.3-23.1Zm74.1-67.9a11.9,11.9,0,0,0-14.6,8.5,84.6,84.6,0,0,1-21.8,37.6,12,12,0,0,0,8.5,20.5,12.2,12.2,0,0,0,8.5-3.5A108,108,0,0,0,232.3,156,12,12,0,0,0,223.8,141.3Zm-14.7-35a12.2,12.2,0,0,0,11.6,8.9,15.5,15.5,0,0,0,3.1-.4,12.1,12.1,0,0,0,8.5-14.7,108.6,108.6,0,0,0-27.9-48.5,12,12,0,0,0-17,17A83.7,83.7,0,0,1,209.1,106.3ZM106.3,46.8a83,83,0,0,1,43.4.1,17.5,17.5,0,0,0,3.2.4A12,12,0,0,0,156,23.7a108.5,108.5,0,0,0-56,0,12,12,0,1,0,6.3,23.1Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M65.8,54.5a8,8,0,0,1,0,11.3A87.7,87.7,0,0,0,43,105.2a8.1,8.1,0,0,1-7.7,6l-2.1-.3a8,8,0,0,1-5.7-9.8,103.2,103.2,0,0,1,27-46.6A7.9,7.9,0,0,1,65.8,54.5ZM43,150.8a8,8,0,0,0-9.8-5.7,8.1,8.1,0,0,0-5.7,9.8,104.8,104.8,0,0,0,27,46.7,8,8,0,0,0,5.6,2.3,8.3,8.3,0,0,0,5.7-2.3,8.1,8.1,0,0,0,0-11.4A87.6,87.6,0,0,1,43,150.8ZM150.8,213a88.1,88.1,0,0,1-45.6,0,8,8,0,0,0-4.1,15.5,107,107,0,0,0,27,3.5,105.4,105.4,0,0,0,26.8-3.5,8,8,0,0,0-4.1-15.5Zm72-67.9a8,8,0,0,0-9.8,5.7,87.2,87.2,0,0,1-22.8,39.4,8.1,8.1,0,0,0,5.7,13.7,7.7,7.7,0,0,0,5.6-2.4,103.2,103.2,0,0,0,27-46.6A8.1,8.1,0,0,0,222.8,145.1ZM213,105.2a8.1,8.1,0,0,0,7.7,6l2.1-.3a8,8,0,0,0,5.7-9.8,103.9,103.9,0,0,0-27-46.6,7.9,7.9,0,0,0-11.3,0,8,8,0,0,0,0,11.3A87.6,87.6,0,0,1,213,105.2ZM105.2,43a88.1,88.1,0,0,1,45.6,0,7.6,7.6,0,0,0,2.1.3,8,8,0,0,0,2-15.8,104.4,104.4,0,0,0-53.8,0A8,8,0,0,0,105.2,43Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M65.8,54.5a8,8,0,0,1,0,11.3A87.7,87.7,0,0,0,43,105.2a8.1,8.1,0,0,1-7.7,6l-2.1-.3a8,8,0,0,1-5.7-9.8,103.2,103.2,0,0,1,27-46.6A7.9,7.9,0,0,1,65.8,54.5ZM43,150.8a8,8,0,0,0-9.8-5.7,8.1,8.1,0,0,0-5.7,9.8,104.8,104.8,0,0,0,27,46.7,8,8,0,0,0,5.6,2.3,8.3,8.3,0,0,0,5.7-2.3,8.1,8.1,0,0,0,0-11.4A87.6,87.6,0,0,1,43,150.8ZM150.8,213a88.1,88.1,0,0,1-45.6,0,8,8,0,0,0-4.1,15.5,107,107,0,0,0,27,3.5,105.4,105.4,0,0,0,26.8-3.5,8,8,0,0,0-4.1-15.5Zm72-67.9a8,8,0,0,0-9.8,5.7,87.2,87.2,0,0,1-22.8,39.4,8.1,8.1,0,0,0,5.7,13.7,7.7,7.7,0,0,0,5.6-2.4,103.2,103.2,0,0,0,27-46.6A8.1,8.1,0,0,0,222.8,145.1ZM213,105.2a8.1,8.1,0,0,0,7.7,6l2.1-.3a8,8,0,0,0,5.7-9.8,103.9,103.9,0,0,0-27-46.6,7.9,7.9,0,0,0-11.3,0,8,8,0,0,0,0,11.3A87.6,87.6,0,0,1,213,105.2ZM105.2,43a88.1,88.1,0,0,1,45.6,0,7.6,7.6,0,0,0,2.1.3,8,8,0,0,0,2-15.8,104.4,104.4,0,0,0-53.8,0A8,8,0,0,0,105.2,43Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M64.4,55.9a6.1,6.1,0,0,1,0,8.5,89.3,89.3,0,0,0-23.3,40.3,6.2,6.2,0,0,1-5.8,4.5l-1.6-.2a6,6,0,0,1-4.2-7.4A102,102,0,0,1,55.9,55.9,5.9,5.9,0,0,1,64.4,55.9ZM41.1,151.3a6,6,0,0,0-7.4-4.2,5.9,5.9,0,0,0-4.2,7.3,100.9,100.9,0,0,0,26.4,45.7,5.8,5.8,0,0,0,4.2,1.8,6.1,6.1,0,0,0,4.3-1.8,5.9,5.9,0,0,0-.1-8.4A90.7,90.7,0,0,1,41.1,151.3ZM151.3,215a90.9,90.9,0,0,1-46.6-.1,6,6,0,0,0-7.3,4.3,5.9,5.9,0,0,0,4.2,7.3,99.5,99.5,0,0,0,26.5,3.5,98,98,0,0,0,26.3-3.5,6,6,0,1,0-3.1-11.5Zm71-67.9a6,6,0,0,0-7.4,4.2,88.7,88.7,0,0,1-23.3,40.3,6.1,6.1,0,0,0,0,8.5,6,6,0,0,0,4.3,1.8,5.8,5.8,0,0,0,4.2-1.8,101.4,101.4,0,0,0,26.4-45.7A5.9,5.9,0,0,0,222.3,147.1Zm-7.4-42.4a6.2,6.2,0,0,0,5.8,4.5l1.6-.2a6,6,0,0,0,4.2-7.4,100.9,100.9,0,0,0-26.4-45.7,5.9,5.9,0,0,0-8.5,0,6,6,0,0,0,.1,8.5A89.8,89.8,0,0,1,214.9,104.7ZM104.7,41.1a89.8,89.8,0,0,1,46.6,0l1.6.2a5.9,5.9,0,0,0,5.7-4.5,5.9,5.9,0,0,0-4.2-7.3,100.6,100.6,0,0,0-52.8,0,5.9,5.9,0,0,0-4.2,7.3A6,6,0,0,0,104.7,41.1Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M62.9,57.3a4,4,0,0,1,0,5.7,92.6,92.6,0,0,0-23.8,41.2,4,4,0,0,1-3.8,3l-1.1-.2a3.9,3.9,0,0,1-2.8-4.9A99.9,99.9,0,0,1,57.3,57.3,3.9,3.9,0,0,1,62.9,57.3ZM39.1,151.8a3.9,3.9,0,0,0-4.9-2.8,4,4,0,0,0-2.8,4.9,99.7,99.7,0,0,0,25.9,44.8,3.9,3.9,0,0,0,2.8,1.2,3.8,3.8,0,0,0,2.8-1.2,3.9,3.9,0,0,0,0-5.6A93.4,93.4,0,0,1,39.1,151.8Zm112.7,65.1a91.5,91.5,0,0,1-47.6,0,4,4,0,0,0-2.1,7.7,99.8,99.8,0,0,0,26,3.4,97.8,97.8,0,0,0,25.8-3.4,4,4,0,1,0-2.1-7.7Zm70-67.9a4,4,0,0,0-4.9,2.8,93,93,0,0,1-23.8,41.3,3.9,3.9,0,0,0,0,5.6,3.8,3.8,0,0,0,2.8,1.2,3.9,3.9,0,0,0,2.8-1.2,99.9,99.9,0,0,0,25.9-44.8A4,4,0,0,0,221.8,149Zm-4.9-44.8a3.9,3.9,0,0,0,3.8,3l1.1-.2a3.9,3.9,0,0,0,2.8-4.9,99.7,99.7,0,0,0-25.9-44.8,4,4,0,1,0-5.6,5.6A93.4,93.4,0,0,1,216.9,104.2ZM104.2,39.1a92.6,92.6,0,0,1,47.6,0l1.1.2a4,4,0,0,0,1-7.9,101.1,101.1,0,0,0-51.8,0,4,4,0,0,0,2.1,7.7Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M65.8,54.5a8,8,0,0,1,0,11.3A87.7,87.7,0,0,0,43,105.2a8.1,8.1,0,0,1-7.7,6l-2.1-.3a8,8,0,0,1-5.7-9.8,103.2,103.2,0,0,1,27-46.6A7.9,7.9,0,0,1,65.8,54.5ZM43,150.8a8,8,0,0,0-9.8-5.7,8.1,8.1,0,0,0-5.7,9.8,104.8,104.8,0,0,0,27,46.7,8,8,0,0,0,5.6,2.3,8.3,8.3,0,0,0,5.7-2.3,8.1,8.1,0,0,0,0-11.4A87.6,87.6,0,0,1,43,150.8ZM150.8,213a88.1,88.1,0,0,1-45.6,0,8,8,0,0,0-4.1,15.5,107,107,0,0,0,27,3.5,105.4,105.4,0,0,0,26.8-3.5,8,8,0,0,0-4.1-15.5Zm72-67.9a8,8,0,0,0-9.8,5.7,87.2,87.2,0,0,1-22.8,39.4,8.1,8.1,0,0,0,5.7,13.7,7.7,7.7,0,0,0,5.6-2.4,103.2,103.2,0,0,0,27-46.6A8.1,8.1,0,0,0,222.8,145.1ZM213,105.2a8.1,8.1,0,0,0,7.7,6l2.1-.3a8,8,0,0,0,5.7-9.8,103.9,103.9,0,0,0-27-46.6,7.9,7.9,0,0,0-11.3,0,8,8,0,0,0,0,11.3A87.6,87.6,0,0,1,213,105.2ZM105.2,43a88.1,88.1,0,0,1,45.6,0,7.6,7.6,0,0,0,2.1.3,8,8,0,0,0,2-15.8,104.4,104.4,0,0,0-53.8,0A8,8,0,0,0,105.2,43Z" />
        </>
      )}
    </svg>
  );
}

export function PhCircleHalfTilt(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M204.4,51.6A108.2,108.2,0,1,0,236,128,108.2,108.2,0,0,0,204.4,51.6ZM176,196.9a86.2,86.2,0,0,1-16,8.8V113l16-16ZM44,128A84,84,0,0,1,178.3,60.7L60.7,178.3A83.5,83.5,0,0,1,44,128Zm52,49v28.7a89.8,89.8,0,0,1-18.3-10.4Zm24,34.6V153l16-16v74.6A85.5,85.5,0,0,1,120,211.6Zm80-40.3V84.7a84.1,84.1,0,0,1,0,86.6Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M195.9,195.9a96,96,0,0,1-135.8,0L195.9,60.1A96,96,0,0,1,195.9,195.9Z"
            opacity="0.2"
          />
          <path d="M201.5,54.5a103.8,103.8,0,0,0-147,0,103.8,103.8,0,0,0,0,147,103.8,103.8,0,0,0,147,0,103.8,103.8,0,0,0,0-147ZM40,128A88,88,0,0,1,184.3,60.4L60.4,184.3A87.3,87.3,0,0,1,40,128Zm150.2,62.2a88.1,88.1,0,0,1-118.5,5.4L195.6,71.7a87.9,87.9,0,0,1-5.4,118.5Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.1,104.1,0,0,0,128,24ZM40,128A88,88,0,0,1,190.2,65.8L65.8,190.2A87.7,87.7,0,0,1,40,128Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M230,128A102.1,102.1,0,0,0,128,26,102,102,0,0,0,55.9,200.1,101.2,101.2,0,0,0,127.8,230h.2A102.1,102.1,0,0,0,230,128Zm-12,0a89.3,89.3,0,0,1-20,56.6V71.4A89.3,89.3,0,0,1,218,128ZM102,162.5l20-20v75.3a96.5,96.5,0,0,1-20-3.6ZM90,209.6a86.2,86.2,0,0,1-21.2-13.9L90,174.5Zm44-79.1,20-20V214.2a96.5,96.5,0,0,1-20,3.6Zm32-32,20-20V196.8a86.1,86.1,0,0,1-20,12.8ZM38,128A89.9,89.9,0,0,1,187.2,60.3L60.3,187.2A89.2,89.2,0,0,1,38,128Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M198.7,57.3A100,100,0,1,0,57.3,198.7,100,100,0,1,0,198.7,57.3ZM188,197.7a91.2,91.2,0,0,1-24,15V97.7l24-24Zm-88-36,24-24v82.2a95.8,95.8,0,0,1-24-4.2Zm-8,51a92.5,92.5,0,0,1-26.2-16.9L92,169.7Zm40-83,24-24v110a95.8,95.8,0,0,1-24,4.2ZM36,128A92,92,0,0,1,190.2,60.2l-130,130A91.8,91.8,0,0,1,36,128Zm160,62V66a92.1,92.1,0,0,1,0,124Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M201.5,54.5a103.8,103.8,0,0,0-147,0,103.8,103.8,0,0,0,0,147,103.8,103.8,0,0,0,147,0,103.8,103.8,0,0,0,0-147ZM184,195.9a91.9,91.9,0,0,1-16,10.5V99.3l16-16Zm-80-32.6,16-16v68.3a86.8,86.8,0,0,1-16-3ZM88,206.4a90.3,90.3,0,0,1-16.3-10.8L88,179.3Zm48-75.1,16-16v97.3a86.8,86.8,0,0,1-16,3ZM40,128A88,88,0,0,1,184.3,60.4L60.4,184.3A87.3,87.3,0,0,1,40,128Zm160,50.6V77.4a88,88,0,0,1,0,101.2Z" />
        </>
      )}
    </svg>
  );
}

export function PhCircleHalf(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,20A108,108,0,1,0,236,128,108.1,108.1,0,0,0,128,20Zm12,24.9a84.5,84.5,0,0,1,24,7.2V203.9a84.5,84.5,0,0,1-24,7.2ZM44,128a84,84,0,0,1,72-83.1V211.1A84,84,0,0,1,44,128Zm144,58.7V69.3a83.8,83.8,0,0,1,0,117.4Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M224,128a96,96,0,0,1-96,96V32A96,96,0,0,1,224,128Z"
            opacity="0.2"
          />
          <path d="M128,24A104,104,0,1,0,232,128,104.1,104.1,0,0,0,128,24ZM40,128a88.1,88.1,0,0,1,80-87.6V215.6A88.1,88.1,0,0,1,40,128Zm96,87.6V40.4a88,88,0,0,1,0,175.2Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.1,104.1,0,0,0,128,24ZM40,128a88.1,88.1,0,0,1,88-88V216A88.1,88.1,0,0,1,40,128Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,26A102,102,0,1,0,230,128,102.2,102.2,0,0,0,128,26Zm6,12.2a94.1,94.1,0,0,1,20,3.6V214.2a94.1,94.1,0,0,1-20,3.6Zm32,8.2a90.8,90.8,0,0,1,20,12.8V196.8a90.8,90.8,0,0,1-20,12.8ZM38,128a90.2,90.2,0,0,1,84-89.8V217.8A90.2,90.2,0,0,1,38,128Zm160,56.5V71.5a89.8,89.8,0,0,1,0,113Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,28A100,100,0,1,0,228,128,100.2,100.2,0,0,0,128,28Zm4,8.1a87,87,0,0,1,24,4.3V215.6a90.9,90.9,0,0,1-24,4.3Zm32,7.2a94.2,94.2,0,0,1,24,15V197.7a94.2,94.2,0,0,1-24,15ZM36,128a92.1,92.1,0,0,1,88-91.9V219.9A92.1,92.1,0,0,1,36,128Zm160,61.9V66.1a91.8,91.8,0,0,1,0,123.8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.1,104.1,0,0,0,128,24Zm8,16.4a94,94,0,0,1,16,2.9V212.7a94,94,0,0,1-16,2.9Zm32,9.2a90.6,90.6,0,0,1,16,10.6V195.8a90.6,90.6,0,0,1-16,10.6ZM40,128a88.1,88.1,0,0,1,80-87.6V215.6A88.1,88.1,0,0,1,40,128Zm160,50.5V77.5a87.7,87.7,0,0,1,0,101Z" />
        </>
      )}
    </svg>
  );
}

export function PhCircleNotch(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M236,128A108,108,0,1,1,83,29.8,12,12,0,0,1,93,51.6a84,84,0,1,0,70,0,12,12,0,0,1,10-21.8A108.3,108.3,0,0,1,236,128Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M232,128A104,104,0,1,1,84.7,33.4a8.1,8.1,0,0,1,10.6,4,8,8,0,0,1-4,10.6,88,88,0,1,0,73.4,0,8,8,0,0,1-4-10.6,8.1,8.1,0,0,1,10.6-4A104.4,104.4,0,0,1,232,128Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M232,128A104,104,0,1,1,84.7,33.4a8.1,8.1,0,0,1,10.6,4,8,8,0,0,1-4,10.6,88,88,0,1,0,73.4,0,8,8,0,0,1-4-10.6,8.1,8.1,0,0,1,10.6-4A104.4,104.4,0,0,1,232,128Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M230,128A102,102,0,1,1,85.5,35.3a6,6,0,0,1,8,2.9,6.2,6.2,0,0,1-3,8,90,90,0,1,0,75,0,6.2,6.2,0,0,1-3-8,6,6,0,0,1,8-2.9A102.2,102.2,0,0,1,230,128Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M228,128A100,100,0,1,1,86.3,37.1a4,4,0,1,1,3.4,7.2,92,92,0,1,0,76.6,0,3.9,3.9,0,0,1-1.9-5.3,4,4,0,0,1,5.3-1.9A100.2,100.2,0,0,1,228,128Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M232,128A104,104,0,1,1,84.7,33.4a8.1,8.1,0,0,1,10.6,4,8,8,0,0,1-4,10.6,88,88,0,1,0,73.4,0,8,8,0,0,1-4-10.6,8.1,8.1,0,0,1,10.6-4A104.4,104.4,0,0,1,232,128Z" />
        </>
      )}
    </svg>
  );
}

export function PhCircleWavyCheck(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M228.7,100c-3.5-3.6-7.1-7.4-8.3-10.3s-1.1-7.8-1.2-12.4c-.2-10-.3-22.5-9.2-31.3s-21.3-9-31.3-9.2c-4.6-.1-9.9-.1-12.4-1.2s-6.7-4.8-10.3-8.3C148.9,20.5,140.1,12,128,12s-20.9,8.5-28,15.3c-3.6,3.5-7.4,7.1-10.3,8.3s-7.8,1.1-12.4,1.2c-10,.2-22.5.3-31.3,9.2s-9,21.3-9.2,31.3c-.1,4.6-.1,9.9-1.2,12.4s-4.8,6.7-8.3,10.3C20.5,107.1,12,115.9,12,128s8.5,20.9,15.3,28c3.5,3.6,7.1,7.4,8.3,10.3s1.1,7.8,1.2,12.4c.2,10,.3,22.5,9.2,31.3s21.3,9,31.3,9.2c4.6.1,9.9.1,12.4,1.2s6.7,4.8,10.3,8.3c7.1,6.8,15.9,15.3,28,15.3s20.9-8.5,28-15.3c3.6-3.5,7.4-7.1,10.3-8.3s7.8-1.1,12.4-1.2c10-.2,22.5-.3,31.3-9.2s9-21.3,9.2-31.3c.1-4.6.1-9.9,1.2-12.4s4.8-6.7,8.3-10.3c6.8-7.1,15.3-15.9,15.3-28S235.5,107.1,228.7,100Zm-17.3,39.3c-4.8,5.1-10.3,10.8-13.2,17.8s-2.9,14.2-3,21.3c-.1,5.3-.2,12.7-2.1,14.7s-9.3,2-14.7,2.1c-7.1.1-14.5.2-21.3,3s-12.7,8.4-17.8,13.2c-3.5,3.4-8.9,8.6-11.3,8.6s-7.8-5.2-11.3-8.6c-5.1-4.8-10.8-10.3-17.8-13.2s-14.2-2.9-21.3-3c-5.3-.1-12.7-.2-14.7-2.1s-2-9.4-2.1-14.7c-.1-7.1-.2-14.5-3-21.3s-8.4-12.7-13.2-17.8c-3.4-3.5-8.6-8.9-8.6-11.3s5.2-7.8,8.6-11.3c4.8-5.1,10.3-10.8,13.2-17.8s2.9-14.2,3-21.3c.1-5.4.2-12.7,2.1-14.7s9.4-2,14.7-2.1c7.1-.1,14.5-.2,21.3-3s12.7-8.4,17.8-13.2c3.5-3.4,8.9-8.6,11.3-8.6s7.8,5.2,11.3,8.6c5.1,4.8,10.8,10.3,17.8,13.2s14.2,2.9,21.3,3c5.4.1,12.7.2,14.7,2.1s2,9.3,2.1,14.7c.1,7.1.2,14.5,3,21.3s8.4,12.7,13.2,17.8c3.4,3.5,8.6,8.9,8.6,11.3S214.8,135.8,211.4,139.3ZM180.7,95.7a12,12,0,0,1-.4,17l-58.7,56a12,12,0,0,1-8.3,3.3,12.2,12.2,0,0,1-8.3-3.3l-29.3-28a12,12,0,1,1,16.6-17.4l21,20.1,50.4-48.1A12,12,0,0,1,180.7,95.7Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M232,128c0,12.5-17.8,22-22.7,33.7s1.4,30.6-7.8,39.8-28.5,3.1-39.8,7.8S140.5,232,128,232s-22-17.8-33.7-22.7-30.6,1.4-39.8-7.8-3.1-28.5-7.8-39.8S24,140.5,24,128s17.8-22,22.7-33.7-1.4-30.6,7.8-39.8S83,51.4,94.3,46.7,115.5,24,128,24s22,17.8,33.7,22.7,30.6-1.4,39.8,7.8,3.1,28.5,7.8,39.8S232,115.5,232,128Z"
            opacity="0.2"
          />
          <path d="M225.9,102.8c-3.8-3.9-7.7-8-9.2-11.5s-1.4-8.7-1.5-14c-.1-9.7-.3-20.8-8-28.5s-18.8-7.9-28.5-8c-5.3-.1-10.7-.2-14-1.5s-7.6-5.4-11.5-9.2C146.3,23.5,138.4,16,128,16s-18.3,7.5-25.2,14.1c-3.9,3.8-8,7.7-11.5,9.2s-8.7,1.4-14,1.5c-9.7.1-20.8.3-28.5,8s-7.9,18.8-8,28.5c-.1,5.3-.2,10.7-1.5,14s-5.4,7.6-9.2,11.5C23.5,109.7,16,117.6,16,128s7.5,18.3,14.1,25.2c3.8,3.9,7.7,8,9.2,11.5s1.4,8.7,1.5,14c.1,9.7.3,20.8,8,28.5s18.8,7.9,28.5,8c5.3.1,10.7.2,14,1.5s7.6,5.4,11.5,9.2c6.9,6.6,14.8,14.1,25.2,14.1s18.3-7.5,25.2-14.1c3.9-3.8,8-7.7,11.5-9.2s8.7-1.4,14-1.5c9.7-.1,20.8-.3,28.5-8s7.9-18.8,8-28.5c.1-5.3.2-10.7,1.5-14s5.4-7.6,9.2-11.5c6.6-6.9,14.1-14.8,14.1-25.2S232.5,109.7,225.9,102.8Zm-11.6,39.3c-4.8,5-9.7,10.2-12.4,16.5s-2.6,13.1-2.7,19.8-.2,14.4-3.3,17.5-10.4,3.2-17.5,3.3-13.7.2-19.8,2.7-11.5,7.6-16.5,12.4S132,224,128,224s-9.1-4.9-14.1-9.7-10.2-9.7-16.5-12.4-13.1-2.6-19.8-2.7-14.4-.2-17.5-3.3-3.2-10.4-3.3-17.5-.2-13.7-2.7-19.8-7.6-11.5-12.4-16.5S32,132,32,128s4.9-9.1,9.7-14.1,9.7-10.2,12.4-16.5,2.6-13.1,2.7-19.8.2-14.4,3.3-17.5,10.4-3.2,17.5-3.3,13.7-.2,19.8-2.7,11.5-7.6,16.5-12.4S124,32,128,32s9.1,4.9,14.1,9.7,10.2,9.7,16.5,12.4,13.1,2.6,19.8,2.7,14.4.2,17.5,3.3,3.2,10.4,3.3,17.5.2,13.7,2.7,19.8,7.6,11.5,12.4,16.5S224,124,224,128,219.1,137.1,214.3,142.1ZM177.8,98.5a8,8,0,0,1-.3,11.3l-58.6,56a8.1,8.1,0,0,1-5.6,2.2,7.9,7.9,0,0,1-5.5-2.2l-29.3-28a8,8,0,1,1,11-11.6l23.8,22.7,53.2-50.7A8,8,0,0,1,177.8,98.5Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M225.9,102.8c-3.8-3.9-7.7-8-9.2-11.5s-1.4-8.7-1.5-14c-.1-9.7-.3-20.8-8-28.5s-18.8-7.9-28.5-8c-5.3-.1-10.7-.2-14-1.5s-7.6-5.4-11.5-9.2C146.3,23.5,138.4,16,128,16s-18.3,7.5-25.2,14.1c-3.9,3.8-8,7.7-11.5,9.2s-8.7,1.4-14,1.5c-9.7.1-20.8.3-28.5,8s-7.9,18.8-8,28.5c-.1,5.3-.2,10.7-1.5,14s-5.4,7.6-9.2,11.5C23.5,109.7,16,117.6,16,128s7.5,18.3,14.1,25.2c3.8,3.9,7.7,8,9.2,11.5s1.4,8.7,1.5,14c.1,9.7.3,20.8,8,28.5s18.8,7.9,28.5,8c5.3.1,10.7.2,14,1.5s7.6,5.4,11.5,9.2c6.9,6.6,14.8,14.1,25.2,14.1s18.3-7.5,25.2-14.1c3.9-3.8,8-7.7,11.5-9.2s8.7-1.4,14-1.5c9.7-.1,20.8-.3,28.5-8s7.9-18.8,8-28.5c.1-5.3.2-10.7,1.5-14s5.4-7.6,9.2-11.5c6.6-6.9,14.1-14.8,14.1-25.2S232.5,109.7,225.9,102.8Zm-48.4,7-58.6,56a8.1,8.1,0,0,1-5.6,2.2,7.9,7.9,0,0,1-5.5-2.2l-29.3-28a8,8,0,1,1,11-11.6l23.8,22.7,53.2-50.7a8,8,0,0,1,11,11.6Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M224.4,104.2c-3.9-4.1-7.9-8.3-9.5-12.2s-1.6-9-1.7-14.7c-.1-9.3-.3-20-7.4-27.1s-17.8-7.3-27.1-7.4c-5.7-.1-11.1-.2-14.7-1.7s-8.1-5.6-12.2-9.5C145.2,25.2,137.6,18,128,18s-17.2,7.2-23.8,13.6c-4.1,3.9-8.3,7.9-12.2,9.5s-9,1.6-14.7,1.7c-9.3.1-20,.3-27.1,7.4S42.9,68,42.8,77.3c-.1,5.7-.2,11.1-1.7,14.7s-5.6,8.1-9.5,12.2C25.2,110.8,18,118.4,18,128s7.2,17.2,13.6,23.8c3.9,4.1,7.9,8.3,9.5,12.2s1.6,9,1.7,14.7c.1,9.3.3,20,7.4,27.1s17.8,7.3,27.1,7.4c5.7.1,11.1.2,14.7,1.7s8.1,5.6,12.2,9.5c6.6,6.4,14.2,13.6,23.8,13.6s17.2-7.2,23.8-13.6c4.1-3.9,8.3-7.9,12.2-9.5s9-1.6,14.7-1.7c9.3-.1,20-.3,27.1-7.4s7.3-17.8,7.4-27.1c.1-5.7.2-11.1,1.7-14.7s5.6-8.1,9.5-12.2c6.4-6.6,13.6-14.2,13.6-23.8S230.8,110.8,224.4,104.2Zm-8.6,39.3c-4.7,4.9-9.5,9.9-12,15.9s-2.5,12.5-2.6,19.1-.2,15.1-3.9,18.8-11.4,3.8-18.8,3.9-13.4.2-19.1,2.6-11,7.3-15.9,12S132.8,226,128,226s-10.2-5.2-15.5-10.2-9.9-9.5-15.9-12-12.5-2.5-19.1-2.6-15.1-.2-18.8-3.9-3.8-11.4-3.9-18.8-.2-13.4-2.6-19.1-7.3-11-12-15.9S30,132.8,30,128s5.2-10.2,10.2-15.5,9.5-9.9,12-15.9,2.5-12.5,2.6-19.1.2-15.1,3.9-18.8,11.4-3.8,18.8-3.9,13.4-.2,19.1-2.6,11-7.3,15.9-12S123.2,30,128,30s10.2,5.2,15.5,10.2,9.9,9.5,15.9,12,12.5,2.5,19.1,2.6,15.1.2,18.8,3.9,3.8,11.4,3.9,18.8.2,13.4,2.6,19.1,7.3,11,12,15.9S226,123.2,226,128,220.8,138.2,215.8,143.5ZM176.3,99.9a5.9,5.9,0,0,1-.2,8.4l-58.6,56a6.3,6.3,0,0,1-4.2,1.7,5.8,5.8,0,0,1-4.1-1.7l-29.3-28a5.9,5.9,0,0,1,8.2-8.6l25.2,24,54.6-52A5.9,5.9,0,0,1,176.3,99.9Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M223,105.6c-4.1-4.2-8.2-8.6-10-12.8s-1.7-9.5-1.8-15.4c-.1-9-.3-19.2-6.8-25.8s-16.8-6.7-25.8-6.8c-5.9-.1-11.4-.2-15.4-1.8s-8.6-5.9-12.8-10c-6.7-6.4-13.6-13-22.4-13s-15.7,6.6-22.4,13c-4.2,4.1-8.6,8.2-12.8,10s-9.5,1.7-15.4,1.8c-9,.1-19.2.3-25.8,6.8s-6.7,16.8-6.8,25.8c-.1,5.9-.2,11.4-1.8,15.4s-5.9,8.6-10,12.8c-6.4,6.7-13,13.6-13,22.4s6.6,15.7,13,22.4c4.1,4.2,8.2,8.6,10,12.8s1.7,9.5,1.8,15.4c.1,9,.3,19.2,6.8,25.8s16.8,6.7,25.8,6.8c5.9.1,11.4.2,15.4,1.8s8.6,5.9,12.8,10c6.7,6.4,13.6,13,22.4,13s15.7-6.6,22.4-13c4.2-4.1,8.6-8.2,12.8-10s9.5-1.7,15.4-1.8c9-.1,19.2-.3,25.8-6.8s6.7-16.8,6.8-25.8c.1-5.9.2-11.4,1.8-15.4s5.9-8.6,10-12.8c6.4-6.7,13-13.6,13-22.4S229.4,112.3,223,105.6Zm-5.8,39.3c-4.5,4.7-9.2,9.6-11.6,15.3s-2.3,11.9-2.4,18.3c-.1,8.2-.2,16-4.5,20.2s-12,4.4-20.2,4.5c-6.4.1-13,.2-18.3,2.4s-10.6,7.1-15.3,11.6S133.6,228,128,228s-11.1-5.2-16.9-10.8-9.6-9.2-15.3-11.6-11.9-2.3-18.3-2.4c-8.2-.1-16-.2-20.2-4.5s-4.4-12-4.5-20.2c-.1-6.4-.2-13-2.4-18.3s-7.1-10.6-11.6-15.3S28,133.6,28,128s5.2-11.1,10.8-16.9,9.2-9.6,11.6-15.3,2.3-11.9,2.4-18.3c.1-8.2.2-16,4.5-20.2s12-4.4,20.2-4.5c6.4-.1,13-.2,18.3-2.4s10.6-7.1,15.3-11.6S122.4,28,128,28s11.1,5.2,16.9,10.8,9.6,9.2,15.3,11.6,11.9,2.3,18.3,2.4c8.2.1,16,.2,20.2,4.5s4.4,12,4.5,20.2c.1,6.4.2,13,2.4,18.3s7.1,10.6,11.6,15.3S228,122.4,228,128,222.8,139.1,217.2,144.9Zm-42.3-43.7a4.1,4.1,0,0,1-.1,5.7l-58.7,56a4.3,4.3,0,0,1-2.8,1.1,3.9,3.9,0,0,1-2.7-1.1l-29.4-28a4,4,0,1,1,5.6-5.8l26.5,25.4,55.9-53.4A4.1,4.1,0,0,1,174.9,101.2Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M225.9,102.8c-3.8-3.9-7.7-8-9.2-11.5s-1.4-8.7-1.5-14c-.1-9.7-.3-20.8-8-28.5s-18.8-7.9-28.5-8c-5.3-.1-10.7-.2-14-1.5s-7.6-5.4-11.5-9.2C146.3,23.5,138.4,16,128,16s-18.3,7.5-25.2,14.1c-3.9,3.8-8,7.7-11.5,9.2s-8.7,1.4-14,1.5c-9.7.1-20.8.3-28.5,8s-7.9,18.8-8,28.5c-.1,5.3-.2,10.7-1.5,14s-5.4,7.6-9.2,11.5C23.5,109.7,16,117.6,16,128s7.5,18.3,14.1,25.2c3.8,3.9,7.7,8,9.2,11.5s1.4,8.7,1.5,14c.1,9.7.3,20.8,8,28.5s18.8,7.9,28.5,8c5.3.1,10.7.2,14,1.5s7.6,5.4,11.5,9.2c6.9,6.6,14.8,14.1,25.2,14.1s18.3-7.5,25.2-14.1c3.9-3.8,8-7.7,11.5-9.2s8.7-1.4,14-1.5c9.7-.1,20.8-.3,28.5-8s7.9-18.8,8-28.5c.1-5.3.2-10.7,1.5-14s5.4-7.6,9.2-11.5c6.6-6.9,14.1-14.8,14.1-25.2S232.5,109.7,225.9,102.8Zm-11.6,39.3c-4.8,5-9.7,10.2-12.4,16.5s-2.6,13.1-2.7,19.8-.2,14.4-3.3,17.5-10.4,3.2-17.5,3.3-13.7.2-19.8,2.7-11.5,7.6-16.5,12.4S132,224,128,224s-9.1-4.9-14.1-9.7-10.2-9.7-16.5-12.4-13.1-2.6-19.8-2.7-14.4-.2-17.5-3.3-3.2-10.4-3.3-17.5-.2-13.7-2.7-19.8-7.6-11.5-12.4-16.5S32,132,32,128s4.9-9.1,9.7-14.1,9.7-10.2,12.4-16.5,2.6-13.1,2.7-19.8.2-14.4,3.3-17.5,10.4-3.2,17.5-3.3,13.7-.2,19.8-2.7,11.5-7.6,16.5-12.4S124,32,128,32s9.1,4.9,14.1,9.7,10.2,9.7,16.5,12.4,13.1,2.6,19.8,2.7,14.4.2,17.5,3.3,3.2,10.4,3.3,17.5.2,13.7,2.7,19.8,7.6,11.5,12.4,16.5S224,124,224,128,219.1,137.1,214.3,142.1ZM177.8,98.5a8,8,0,0,1-.3,11.3l-58.6,56a8.1,8.1,0,0,1-5.6,2.2,7.9,7.9,0,0,1-5.5-2.2l-29.3-28a8,8,0,1,1,11-11.6l23.8,22.7,53.2-50.7A8,8,0,0,1,177.8,98.5Z" />
        </>
      )}
    </svg>
  );
}

export function PhCircleWavyQuestion(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M228.7,100c-3.5-3.6-7.1-7.4-8.3-10.3s-1.1-7.8-1.2-12.4c-.2-10-.3-22.5-9.2-31.3s-21.3-9-31.3-9.2c-4.6-.1-9.9-.1-12.4-1.2s-6.7-4.8-10.3-8.3C148.9,20.5,140.1,12,128,12s-20.9,8.5-28,15.3c-3.6,3.5-7.4,7.1-10.3,8.3s-7.8,1.1-12.4,1.2c-10,.2-22.5.3-31.3,9.2s-9,21.3-9.2,31.3c-.1,4.6-.1,9.9-1.2,12.4s-4.8,6.7-8.3,10.3C20.5,107.1,12,115.9,12,128s8.5,20.9,15.3,28c3.5,3.6,7.1,7.4,8.3,10.3s1.1,7.8,1.2,12.4c.2,10,.3,22.5,9.2,31.3s21.3,9,31.3,9.2c4.6.1,9.9.1,12.4,1.2s6.7,4.8,10.3,8.3c7.1,6.8,15.9,15.3,28,15.3s20.9-8.5,28-15.3c3.6-3.5,7.4-7.1,10.3-8.3s7.8-1.1,12.4-1.2c10-.2,22.5-.3,31.3-9.2s9-21.3,9.2-31.3c.1-4.6.1-9.9,1.2-12.4s4.8-6.7,8.3-10.3c6.8-7.1,15.3-15.9,15.3-28S235.5,107.1,228.7,100Zm-17.3,39.3c-4.8,5.1-10.3,10.8-13.2,17.8s-2.9,14.2-3,21.3c-.1,5.3-.2,12.7-2.1,14.7s-9.3,2-14.7,2.1c-7.1.1-14.5.2-21.3,3s-12.7,8.4-17.8,13.2c-3.5,3.4-8.9,8.6-11.3,8.6s-7.8-5.2-11.3-8.6c-5.1-4.8-10.8-10.3-17.8-13.2s-14.2-2.9-21.3-3c-5.3-.1-12.7-.2-14.7-2.1s-2-9.4-2.1-14.7c-.1-7.1-.2-14.5-3-21.3s-8.4-12.7-13.2-17.8c-3.4-3.5-8.6-8.9-8.6-11.3s5.2-7.8,8.6-11.3c4.8-5.1,10.3-10.8,13.2-17.8s2.9-14.2,3-21.3c.1-5.4.2-12.7,2.1-14.7s9.4-2,14.7-2.1c7.1-.1,14.5-.2,21.3-3s12.7-8.4,17.8-13.2c3.5-3.4,8.9-8.6,11.3-8.6s7.8,5.2,11.3,8.6c5.1,4.8,10.8,10.3,17.8,13.2s14.2,2.9,21.3,3c5.4.1,12.7.2,14.7,2.1s2,9.3,2.1,14.7c.1,7.1.2,14.5,3,21.3s8.4,12.7,13.2,17.8c3.4,3.5,8.6,8.9,8.6,11.3S214.8,135.8,211.4,139.3ZM144,176a16,16,0,1,1-16-16A16,16,0,0,1,144,176Zm24-68a40,40,0,0,1-40,40,12,12,0,0,1,0-24,16,16,0,1,0-16-16,12,12,0,0,1-24,0,40,40,0,0,1,80,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M232,128c0,12.5-17.8,22-22.7,33.7s1.4,30.6-7.8,39.8-28.5,3.1-39.8,7.8S140.5,232,128,232s-22-17.8-33.7-22.7-30.6,1.4-39.8-7.8-3.1-28.5-7.8-39.8S24,140.5,24,128s17.8-22,22.7-33.7-1.4-30.6,7.8-39.8S83,51.4,94.3,46.7,115.5,24,128,24s22,17.8,33.7,22.7,30.6-1.4,39.8,7.8,3.1,28.5,7.8,39.8S232,115.5,232,128Z"
            opacity="0.2"
          />
          <path d="M225.9,102.8c-3.8-3.9-7.7-8-9.2-11.5s-1.4-8.7-1.5-14c-.1-9.7-.3-20.8-8-28.5s-18.8-7.9-28.5-8c-5.3-.1-10.7-.2-14-1.5s-7.6-5.4-11.5-9.2C146.3,23.5,138.4,16,128,16s-18.3,7.5-25.2,14.1c-3.9,3.8-8,7.7-11.5,9.2s-8.7,1.4-14,1.5c-9.7.1-20.8.3-28.5,8s-7.9,18.8-8,28.5c-.1,5.3-.2,10.7-1.5,14s-5.4,7.6-9.2,11.5C23.5,109.7,16,117.6,16,128s7.5,18.3,14.1,25.2c3.8,3.9,7.7,8,9.2,11.5s1.4,8.7,1.5,14c.1,9.7.3,20.8,8,28.5s18.8,7.9,28.5,8c5.3.1,10.7.2,14,1.5s7.6,5.4,11.5,9.2c6.9,6.6,14.8,14.1,25.2,14.1s18.3-7.5,25.2-14.1c3.9-3.8,8-7.7,11.5-9.2s8.7-1.4,14-1.5c9.7-.1,20.8-.3,28.5-8s7.9-18.8,8-28.5c.1-5.3.2-10.7,1.5-14s5.4-7.6,9.2-11.5c6.6-6.9,14.1-14.8,14.1-25.2S232.5,109.7,225.9,102.8Zm-11.6,39.3c-4.8,5-9.7,10.2-12.4,16.5s-2.6,13.1-2.7,19.8-.2,14.4-3.3,17.5-10.4,3.2-17.5,3.3-13.7.2-19.8,2.7-11.5,7.6-16.5,12.4S132,224,128,224s-9.1-4.9-14.1-9.7-10.2-9.7-16.5-12.4-13.1-2.6-19.8-2.7-14.4-.2-17.5-3.3-3.2-10.4-3.3-17.5-.2-13.7-2.7-19.8-7.6-11.5-12.4-16.5S32,132,32,128s4.9-9.1,9.7-14.1,9.7-10.2,12.4-16.5,2.6-13.1,2.7-19.8.2-14.4,3.3-17.5,10.4-3.2,17.5-3.3,13.7-.2,19.8-2.7,11.5-7.6,16.5-12.4S124,32,128,32s9.1,4.9,14.1,9.7,10.2,9.7,16.5,12.4,13.1,2.6,19.8,2.7,14.4.2,17.5,3.3,3.2,10.4,3.3,17.5.2,13.7,2.7,19.8,7.6,11.5,12.4,16.5S224,124,224,128,219.1,137.1,214.3,142.1ZM140,180a12,12,0,1,1-12-12A12,12,0,0,1,140,180Zm24-72a36,36,0,0,1-28,35.1v.9a8,8,0,0,1-16,0v-8a8,8,0,0,1,8-8,20,20,0,1,0-20-20,8,8,0,0,1-16,0,36,36,0,0,1,72,0Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M225.9,102.8c-3.8-3.9-7.7-8-9.2-11.5s-1.4-8.7-1.5-14c-.1-9.7-.3-20.8-8-28.5s-18.8-7.9-28.5-8c-5.3-.1-10.7-.2-14-1.5s-7.6-5.4-11.5-9.2C146.3,23.5,138.4,16,128,16s-18.3,7.5-25.2,14.1c-3.9,3.8-8,7.7-11.5,9.2s-8.7,1.4-14,1.5c-9.7.1-20.8.3-28.5,8s-7.9,18.8-8,28.5c-.1,5.3-.2,10.7-1.5,14s-5.4,7.6-9.2,11.5C23.5,109.7,16,117.6,16,128s7.5,18.3,14.1,25.2c3.8,3.9,7.7,8,9.2,11.5s1.4,8.7,1.5,14c.1,9.7.3,20.8,8,28.5s18.8,7.9,28.5,8c5.3.1,10.7.2,14,1.5s7.6,5.4,11.5,9.2c6.9,6.6,14.8,14.1,25.2,14.1s18.3-7.5,25.2-14.1c3.9-3.8,8-7.7,11.5-9.2s8.7-1.4,14-1.5c9.7-.1,20.8-.3,28.5-8s7.9-18.8,8-28.5c.1-5.3.2-10.7,1.5-14s5.4-7.6,9.2-11.5c6.6-6.9,14.1-14.8,14.1-25.2S232.5,109.7,225.9,102.8ZM128,192a12,12,0,1,1,12-12A12,12,0,0,1,128,192Zm8-48.9v.9a8,8,0,0,1-16,0v-8a8,8,0,0,1,8-8,20,20,0,1,0-20-20,8,8,0,0,1-16,0,36,36,0,1,1,44,35.1Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M224.4,104.2c-3.9-4.1-7.9-8.3-9.5-12.2s-1.6-9-1.7-14.7c-.1-9.3-.3-20-7.4-27.1s-17.8-7.3-27.1-7.4c-5.7-.1-11.1-.2-14.7-1.7s-8.1-5.6-12.2-9.5C145.2,25.2,137.6,18,128,18s-17.2,7.2-23.8,13.6c-4.1,3.9-8.3,7.9-12.2,9.5s-9,1.6-14.7,1.7c-9.3.1-20,.3-27.1,7.4S42.9,68,42.8,77.3c-.1,5.7-.2,11.1-1.7,14.7s-5.6,8.1-9.5,12.2C25.2,110.8,18,118.4,18,128s7.2,17.2,13.6,23.8c3.9,4.1,7.9,8.3,9.5,12.2s1.6,9,1.7,14.7c.1,9.3.3,20,7.4,27.1s17.8,7.3,27.1,7.4c5.7.1,11.1.2,14.7,1.7s8.1,5.6,12.2,9.5c6.6,6.4,14.2,13.6,23.8,13.6s17.2-7.2,23.8-13.6c4.1-3.9,8.3-7.9,12.2-9.5s9-1.6,14.7-1.7c9.3-.1,20-.3,27.1-7.4s7.3-17.8,7.4-27.1c.1-5.7.2-11.1,1.7-14.7s5.6-8.1,9.5-12.2c6.4-6.6,13.6-14.2,13.6-23.8S230.8,110.8,224.4,104.2Zm-8.6,39.3c-4.7,4.9-9.5,9.9-12,15.9s-2.5,12.5-2.6,19.1-.2,15.1-3.9,18.8-11.4,3.8-18.8,3.9-13.4.2-19.1,2.6-11,7.3-15.9,12S132.8,226,128,226s-10.2-5.2-15.5-10.2-9.9-9.5-15.9-12-12.5-2.5-19.1-2.6-15.1-.2-18.8-3.9-3.8-11.4-3.9-18.8-.2-13.4-2.6-19.1-7.3-11-12-15.9S30,132.8,30,128s5.2-10.2,10.2-15.5,9.5-9.9,12-15.9,2.5-12.5,2.6-19.1.2-15.1,3.9-18.8,11.4-3.8,18.8-3.9,13.4-.2,19.1-2.6,11-7.3,15.9-12S123.2,30,128,30s10.2,5.2,15.5,10.2,9.9,9.5,15.9,12,12.5,2.5,19.1,2.6,15.1.2,18.8,3.9,3.8,11.4,3.9,18.8.2,13.4,2.6,19.1,7.3,11,12,15.9S226,123.2,226,128,220.8,138.2,215.8,143.5ZM138,180a10,10,0,1,1-10-10A10,10,0,0,1,138,180Zm24-72a34.1,34.1,0,0,1-28,33.5V144a6,6,0,0,1-12,0v-8a6,6,0,0,1,6-6,22,22,0,1,0-22-22,6,6,0,0,1-12,0,34,34,0,0,1,68,0Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M223,105.6c-4.1-4.2-8.2-8.6-10-12.8s-1.7-9.5-1.8-15.4c-.1-9-.3-19.2-6.8-25.8s-16.8-6.7-25.8-6.8c-5.9-.1-11.4-.2-15.4-1.8s-8.6-5.9-12.8-10c-6.7-6.4-13.6-13-22.4-13s-15.7,6.6-22.4,13c-4.2,4.1-8.6,8.2-12.8,10s-9.5,1.7-15.4,1.8c-9,.1-19.2.3-25.8,6.8s-6.7,16.8-6.8,25.8c-.1,5.9-.2,11.4-1.8,15.4s-5.9,8.6-10,12.8c-6.4,6.7-13,13.6-13,22.4s6.6,15.7,13,22.4c4.1,4.2,8.2,8.6,10,12.8s1.7,9.5,1.8,15.4c.1,9,.3,19.2,6.8,25.8s16.8,6.7,25.8,6.8c5.9.1,11.4.2,15.4,1.8s8.6,5.9,12.8,10c6.7,6.4,13.6,13,22.4,13s15.7-6.6,22.4-13c4.2-4.1,8.6-8.2,12.8-10s9.5-1.7,15.4-1.8c9-.1,19.2-.3,25.8-6.8s6.7-16.8,6.8-25.8c.1-5.9.2-11.4,1.8-15.4s5.9-8.6,10-12.8c6.4-6.7,13-13.6,13-22.4S229.4,112.3,223,105.6Zm-5.8,39.3c-4.5,4.7-9.2,9.6-11.6,15.3s-2.3,11.9-2.4,18.3c-.1,8.2-.2,16-4.5,20.2s-12,4.4-20.2,4.5c-6.4.1-13,.2-18.3,2.4s-10.6,7.1-15.3,11.6S133.6,228,128,228s-11.1-5.2-16.9-10.8-9.6-9.2-15.3-11.6-11.9-2.3-18.3-2.4c-8.2-.1-16-.2-20.2-4.5s-4.4-12-4.5-20.2c-.1-6.4-.2-13-2.4-18.3s-7.1-10.6-11.6-15.3S28,133.6,28,128s5.2-11.1,10.8-16.9,9.2-9.6,11.6-15.3,2.3-11.9,2.4-18.3c.1-8.2.2-16,4.5-20.2s12-4.4,20.2-4.5c6.4-.1,13-.2,18.3-2.4s10.6-7.1,15.3-11.6S122.4,28,128,28s11.1,5.2,16.9,10.8,9.6,9.2,15.3,11.6,11.9,2.3,18.3,2.4c8.2.1,16,.2,20.2,4.5s4.4,12,4.5,20.2c.1,6.4.2,13,2.4,18.3s7.1,10.6,11.6,15.3S228,122.4,228,128,222.8,139.1,217.2,144.9ZM136,180a8,8,0,1,1-8-8A8,8,0,0,1,136,180Zm24-72a32.1,32.1,0,0,1-28,31.8V144a4,4,0,0,1-8,0v-8a4,4,0,0,1,4-4,24,24,0,1,0-24-24,4,4,0,0,1-8,0,32,32,0,0,1,64,0Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M225.9,102.8c-3.8-3.9-7.7-8-9.2-11.5s-1.4-8.7-1.5-14c-.1-9.7-.3-20.8-8-28.5s-18.8-7.9-28.5-8c-5.3-.1-10.7-.2-14-1.5s-7.6-5.4-11.5-9.2C146.3,23.5,138.4,16,128,16s-18.3,7.5-25.2,14.1c-3.9,3.8-8,7.7-11.5,9.2s-8.7,1.4-14,1.5c-9.7.1-20.8.3-28.5,8s-7.9,18.8-8,28.5c-.1,5.3-.2,10.7-1.5,14s-5.4,7.6-9.2,11.5C23.5,109.7,16,117.6,16,128s7.5,18.3,14.1,25.2c3.8,3.9,7.7,8,9.2,11.5s1.4,8.7,1.5,14c.1,9.7.3,20.8,8,28.5s18.8,7.9,28.5,8c5.3.1,10.7.2,14,1.5s7.6,5.4,11.5,9.2c6.9,6.6,14.8,14.1,25.2,14.1s18.3-7.5,25.2-14.1c3.9-3.8,8-7.7,11.5-9.2s8.7-1.4,14-1.5c9.7-.1,20.8-.3,28.5-8s7.9-18.8,8-28.5c.1-5.3.2-10.7,1.5-14s5.4-7.6,9.2-11.5c6.6-6.9,14.1-14.8,14.1-25.2S232.5,109.7,225.9,102.8Zm-11.6,39.3c-4.8,5-9.7,10.2-12.4,16.5s-2.6,13.1-2.7,19.8-.2,14.4-3.3,17.5-10.4,3.2-17.5,3.3-13.7.2-19.8,2.7-11.5,7.6-16.5,12.4S132,224,128,224s-9.1-4.9-14.1-9.7-10.2-9.7-16.5-12.4-13.1-2.6-19.8-2.7-14.4-.2-17.5-3.3-3.2-10.4-3.3-17.5-.2-13.7-2.7-19.8-7.6-11.5-12.4-16.5S32,132,32,128s4.9-9.1,9.7-14.1,9.7-10.2,12.4-16.5,2.6-13.1,2.7-19.8.2-14.4,3.3-17.5,10.4-3.2,17.5-3.3,13.7-.2,19.8-2.7,11.5-7.6,16.5-12.4S124,32,128,32s9.1,4.9,14.1,9.7,10.2,9.7,16.5,12.4,13.1,2.6,19.8,2.7,14.4.2,17.5,3.3,3.2,10.4,3.3,17.5.2,13.7,2.7,19.8,7.6,11.5,12.4,16.5S224,124,224,128,219.1,137.1,214.3,142.1ZM140,180a12,12,0,1,1-12-12A12,12,0,0,1,140,180Zm24-72a36,36,0,0,1-28,35.1v.9a8,8,0,0,1-16,0v-8a8,8,0,0,1,8-8,20,20,0,1,0-20-20,8,8,0,0,1-16,0,36,36,0,0,1,72,0Z" />
        </>
      )}
    </svg>
  );
}

export function PhCircleWavyWarning(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M228.7,100c-3.5-3.6-7.1-7.4-8.3-10.3s-1.1-7.8-1.2-12.4c-.2-10-.3-22.5-9.2-31.3s-21.3-9-31.3-9.2c-4.6-.1-9.9-.1-12.4-1.2s-6.7-4.8-10.3-8.3C148.9,20.5,140.1,12,128,12s-20.9,8.5-28,15.3c-3.6,3.5-7.4,7.1-10.3,8.3s-7.8,1.1-12.4,1.2c-10,.2-22.5.3-31.3,9.2s-9,21.3-9.2,31.3c-.1,4.6-.1,9.9-1.2,12.4s-4.8,6.7-8.3,10.3C20.5,107.1,12,115.9,12,128s8.5,20.9,15.3,28c3.5,3.6,7.1,7.4,8.3,10.3s1.1,7.8,1.2,12.4c.2,10,.3,22.5,9.2,31.3s21.3,9,31.3,9.2c4.6.1,9.9.1,12.4,1.2s6.7,4.8,10.3,8.3c7.1,6.8,15.9,15.3,28,15.3s20.9-8.5,28-15.3c3.6-3.5,7.4-7.1,10.3-8.3s7.8-1.1,12.4-1.2c10-.2,22.5-.3,31.3-9.2s9-21.3,9.2-31.3c.1-4.6.1-9.9,1.2-12.4s4.8-6.7,8.3-10.3c6.8-7.1,15.3-15.9,15.3-28S235.5,107.1,228.7,100Zm-17.3,39.3c-4.8,5.1-10.3,10.8-13.2,17.8s-2.9,14.2-3,21.3c-.1,5.3-.2,12.7-2.1,14.7s-9.3,2-14.7,2.1c-7.1.1-14.5.2-21.3,3s-12.7,8.4-17.8,13.2c-3.5,3.4-8.9,8.6-11.3,8.6s-7.8-5.2-11.3-8.6c-5.1-4.8-10.8-10.3-17.8-13.2s-14.2-2.9-21.3-3c-5.3-.1-12.7-.2-14.7-2.1s-2-9.4-2.1-14.7c-.1-7.1-.2-14.5-3-21.3s-8.4-12.7-13.2-17.8c-3.4-3.5-8.6-8.9-8.6-11.3s5.2-7.8,8.6-11.3c4.8-5.1,10.3-10.8,13.2-17.8s2.9-14.2,3-21.3c.1-5.4.2-12.7,2.1-14.7s9.4-2,14.7-2.1c7.1-.1,14.5-.2,21.3-3s12.7-8.4,17.8-13.2c3.5-3.4,8.9-8.6,11.3-8.6s7.8,5.2,11.3,8.6c5.1,4.8,10.8,10.3,17.8,13.2s14.2,2.9,21.3,3c5.4.1,12.7.2,14.7,2.1s2,9.3,2.1,14.7c.1,7.1.2,14.5,3,21.3s8.4,12.7,13.2,17.8c3.4,3.5,8.6,8.9,8.6,11.3S214.8,135.8,211.4,139.3ZM116,132V80a12,12,0,0,1,24,0v52a12,12,0,0,1-24,0Zm28,40a16,16,0,1,1-16-16A16,16,0,0,1,144,172Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M232,128c0,12.5-17.8,22-22.7,33.7s1.4,30.6-7.8,39.8-28.5,3.1-39.8,7.8S140.5,232,128,232s-22-17.8-33.7-22.7-30.6,1.4-39.8-7.8-3.1-28.5-7.8-39.8S24,140.5,24,128s17.8-22,22.7-33.7-1.4-30.6,7.8-39.8S83,51.4,94.3,46.7,115.5,24,128,24s22,17.8,33.7,22.7,30.6-1.4,39.8,7.8,3.1,28.5,7.8,39.8S232,115.5,232,128Z"
            opacity="0.2"
          />
          <path d="M225.9,102.8c-3.8-3.9-7.7-8-9.2-11.5s-1.4-8.7-1.5-14c-.1-9.7-.3-20.8-8-28.5s-18.8-7.9-28.5-8c-5.3-.1-10.7-.2-14-1.5s-7.6-5.4-11.5-9.2C146.3,23.5,138.4,16,128,16s-18.3,7.5-25.2,14.1c-3.9,3.8-8,7.7-11.5,9.2s-8.7,1.4-14,1.5c-9.7.1-20.8.3-28.5,8s-7.9,18.8-8,28.5c-.1,5.3-.2,10.7-1.5,14s-5.4,7.6-9.2,11.5C23.5,109.7,16,117.6,16,128s7.5,18.3,14.1,25.2c3.8,3.9,7.7,8,9.2,11.5s1.4,8.7,1.5,14c.1,9.7.3,20.8,8,28.5s18.8,7.9,28.5,8c5.3.1,10.7.2,14,1.5s7.6,5.4,11.5,9.2c6.9,6.6,14.8,14.1,25.2,14.1s18.3-7.5,25.2-14.1c3.9-3.8,8-7.7,11.5-9.2s8.7-1.4,14-1.5c9.7-.1,20.8-.3,28.5-8s7.9-18.8,8-28.5c.1-5.3.2-10.7,1.5-14s5.4-7.6,9.2-11.5c6.6-6.9,14.1-14.8,14.1-25.2S232.5,109.7,225.9,102.8Zm-11.6,39.3c-4.8,5-9.7,10.2-12.4,16.5s-2.6,13.1-2.7,19.8-.2,14.4-3.3,17.5-10.4,3.2-17.5,3.3-13.7.2-19.8,2.7-11.5,7.6-16.5,12.4S132,224,128,224s-9.1-4.9-14.1-9.7-10.2-9.7-16.5-12.4-13.1-2.6-19.8-2.7-14.4-.2-17.5-3.3-3.2-10.4-3.3-17.5-.2-13.7-2.7-19.8-7.6-11.5-12.4-16.5S32,132,32,128s4.9-9.1,9.7-14.1,9.7-10.2,12.4-16.5,2.6-13.1,2.7-19.8.2-14.4,3.3-17.5,10.4-3.2,17.5-3.3,13.7-.2,19.8-2.7,11.5-7.6,16.5-12.4S124,32,128,32s9.1,4.9,14.1,9.7,10.2,9.7,16.5,12.4,13.1,2.6,19.8,2.7,14.4.2,17.5,3.3,3.2,10.4,3.3,17.5.2,13.7,2.7,19.8,7.6,11.5,12.4,16.5S224,124,224,128,219.1,137.1,214.3,142.1ZM120,136V80a8,8,0,0,1,16,0v56a8,8,0,0,1-16,0Zm20,36a12,12,0,1,1-12-12A12,12,0,0,1,140,172Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M225.9,102.8c-3.8-3.9-7.7-8-9.2-11.5s-1.4-8.7-1.5-14c-.1-9.7-.3-20.8-8-28.5s-18.8-7.9-28.5-8c-5.3-.1-10.7-.2-14-1.5s-7.6-5.4-11.5-9.2C146.3,23.5,138.4,16,128,16s-18.3,7.5-25.2,14.1c-3.9,3.8-8,7.7-11.5,9.2s-8.7,1.4-14,1.5c-9.7.1-20.8.3-28.5,8s-7.9,18.8-8,28.5c-.1,5.3-.2,10.7-1.5,14s-5.4,7.6-9.2,11.5C23.5,109.7,16,117.6,16,128s7.5,18.3,14.1,25.2c3.8,3.9,7.7,8,9.2,11.5s1.4,8.7,1.5,14c.1,9.7.3,20.8,8,28.5s18.8,7.9,28.5,8c5.3.1,10.7.2,14,1.5s7.6,5.4,11.5,9.2c6.9,6.6,14.8,14.1,25.2,14.1s18.3-7.5,25.2-14.1c3.9-3.8,8-7.7,11.5-9.2s8.7-1.4,14-1.5c9.7-.1,20.8-.3,28.5-8s7.9-18.8,8-28.5c.1-5.3.2-10.7,1.5-14s5.4-7.6,9.2-11.5c6.6-6.9,14.1-14.8,14.1-25.2S232.5,109.7,225.9,102.8ZM120,80a8,8,0,0,1,16,0v56a8,8,0,0,1-16,0Zm8,104a12,12,0,1,1,12-12A12,12,0,0,1,128,184Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M224.4,104.2c-3.9-4.1-7.9-8.3-9.5-12.2s-1.6-9-1.7-14.7c-.1-9.3-.3-20-7.4-27.1s-17.8-7.3-27.1-7.4c-5.7-.1-11.1-.2-14.7-1.7s-8.1-5.6-12.2-9.5C145.2,25.2,137.6,18,128,18s-17.2,7.2-23.8,13.6c-4.1,3.9-8.3,7.9-12.2,9.5s-9,1.6-14.7,1.7c-9.3.1-20,.3-27.1,7.4S42.9,68,42.8,77.3c-.1,5.7-.2,11.1-1.7,14.7s-5.6,8.1-9.5,12.2C25.2,110.8,18,118.4,18,128s7.2,17.2,13.6,23.8c3.9,4.1,7.9,8.3,9.5,12.2s1.6,9,1.7,14.7c.1,9.3.3,20,7.4,27.1s17.8,7.3,27.1,7.4c5.7.1,11.1.2,14.7,1.7s8.1,5.6,12.2,9.5c6.6,6.4,14.2,13.6,23.8,13.6s17.2-7.2,23.8-13.6c4.1-3.9,8.3-7.9,12.2-9.5s9-1.6,14.7-1.7c9.3-.1,20-.3,27.1-7.4s7.3-17.8,7.4-27.1c.1-5.7.2-11.1,1.7-14.7s5.6-8.1,9.5-12.2c6.4-6.6,13.6-14.2,13.6-23.8S230.8,110.8,224.4,104.2Zm-8.6,39.3c-4.7,4.9-9.5,9.9-12,15.9s-2.5,12.5-2.6,19.1-.2,15.1-3.9,18.8-11.4,3.8-18.8,3.9-13.4.2-19.1,2.6-11,7.3-15.9,12S132.8,226,128,226s-10.2-5.2-15.5-10.2-9.9-9.5-15.9-12-12.5-2.5-19.1-2.6-15.1-.2-18.8-3.9-3.8-11.4-3.9-18.8-.2-13.4-2.6-19.1-7.3-11-12-15.9S30,132.8,30,128s5.2-10.2,10.2-15.5,9.5-9.9,12-15.9,2.5-12.5,2.6-19.1.2-15.1,3.9-18.8,11.4-3.8,18.8-3.9,13.4-.2,19.1-2.6,11-7.3,15.9-12S123.2,30,128,30s10.2,5.2,15.5,10.2,9.9,9.5,15.9,12,12.5,2.5,19.1,2.6,15.1.2,18.8,3.9,3.8,11.4,3.9,18.8.2,13.4,2.6,19.1,7.3,11,12,15.9S226,123.2,226,128,220.8,138.2,215.8,143.5ZM122,136V80a6,6,0,0,1,12,0v56a6,6,0,0,1-12,0Zm16,36a10,10,0,1,1-10-10A10,10,0,0,1,138,172Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M223,105.6c-4.1-4.2-8.2-8.6-10-12.8s-1.7-9.5-1.8-15.4c-.1-9-.3-19.2-6.8-25.8s-16.8-6.7-25.8-6.8c-5.9-.1-11.4-.2-15.4-1.8s-8.6-5.9-12.8-10c-6.7-6.4-13.6-13-22.4-13s-15.7,6.6-22.4,13c-4.2,4.1-8.6,8.2-12.8,10s-9.5,1.7-15.4,1.8c-9,.1-19.2.3-25.8,6.8s-6.7,16.8-6.8,25.8c-.1,5.9-.2,11.4-1.8,15.4s-5.9,8.6-10,12.8c-6.4,6.7-13,13.6-13,22.4s6.6,15.7,13,22.4c4.1,4.2,8.2,8.6,10,12.8s1.7,9.5,1.8,15.4c.1,9,.3,19.2,6.8,25.8s16.8,6.7,25.8,6.8c5.9.1,11.4.2,15.4,1.8s8.6,5.9,12.8,10c6.7,6.4,13.6,13,22.4,13s15.7-6.6,22.4-13c4.2-4.1,8.6-8.2,12.8-10s9.5-1.7,15.4-1.8c9-.1,19.2-.3,25.8-6.8s6.7-16.8,6.8-25.8c.1-5.9.2-11.4,1.8-15.4s5.9-8.6,10-12.8c6.4-6.7,13-13.6,13-22.4S229.4,112.3,223,105.6Zm-5.8,39.3c-4.5,4.7-9.2,9.6-11.6,15.3s-2.3,11.9-2.4,18.3c-.1,8.2-.2,16-4.5,20.2s-12,4.4-20.2,4.5c-6.4.1-13,.2-18.3,2.4s-10.6,7.1-15.3,11.6S133.6,228,128,228s-11.1-5.2-16.9-10.8-9.6-9.2-15.3-11.6-11.9-2.3-18.3-2.4c-8.2-.1-16-.2-20.2-4.5s-4.4-12-4.5-20.2c-.1-6.4-.2-13-2.4-18.3s-7.1-10.6-11.6-15.3S28,133.6,28,128s5.2-11.1,10.8-16.9,9.2-9.6,11.6-15.3,2.3-11.9,2.4-18.3c.1-8.2.2-16,4.5-20.2s12-4.4,20.2-4.5c6.4-.1,13-.2,18.3-2.4s10.6-7.1,15.3-11.6S122.4,28,128,28s11.1,5.2,16.9,10.8,9.6,9.2,15.3,11.6,11.9,2.3,18.3,2.4c8.2.1,16,.2,20.2,4.5s4.4,12,4.5,20.2c.1,6.4.2,13,2.4,18.3s7.1,10.6,11.6,15.3S228,122.4,228,128,222.8,139.1,217.2,144.9ZM124,136V80a4,4,0,0,1,8,0v56a4,4,0,0,1-8,0Zm12,36a8,8,0,1,1-8-8A8,8,0,0,1,136,172Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M225.9,102.8c-3.8-3.9-7.7-8-9.2-11.5s-1.4-8.7-1.5-14c-.1-9.7-.3-20.8-8-28.5s-18.8-7.9-28.5-8c-5.3-.1-10.7-.2-14-1.5s-7.6-5.4-11.5-9.2C146.3,23.5,138.4,16,128,16s-18.3,7.5-25.2,14.1c-3.9,3.8-8,7.7-11.5,9.2s-8.7,1.4-14,1.5c-9.7.1-20.8.3-28.5,8s-7.9,18.8-8,28.5c-.1,5.3-.2,10.7-1.5,14s-5.4,7.6-9.2,11.5C23.5,109.7,16,117.6,16,128s7.5,18.3,14.1,25.2c3.8,3.9,7.7,8,9.2,11.5s1.4,8.7,1.5,14c.1,9.7.3,20.8,8,28.5s18.8,7.9,28.5,8c5.3.1,10.7.2,14,1.5s7.6,5.4,11.5,9.2c6.9,6.6,14.8,14.1,25.2,14.1s18.3-7.5,25.2-14.1c3.9-3.8,8-7.7,11.5-9.2s8.7-1.4,14-1.5c9.7-.1,20.8-.3,28.5-8s7.9-18.8,8-28.5c.1-5.3.2-10.7,1.5-14s5.4-7.6,9.2-11.5c6.6-6.9,14.1-14.8,14.1-25.2S232.5,109.7,225.9,102.8Zm-11.6,39.3c-4.8,5-9.7,10.2-12.4,16.5s-2.6,13.1-2.7,19.8-.2,14.4-3.3,17.5-10.4,3.2-17.5,3.3-13.7.2-19.8,2.7-11.5,7.6-16.5,12.4S132,224,128,224s-9.1-4.9-14.1-9.7-10.2-9.7-16.5-12.4-13.1-2.6-19.8-2.7-14.4-.2-17.5-3.3-3.2-10.4-3.3-17.5-.2-13.7-2.7-19.8-7.6-11.5-12.4-16.5S32,132,32,128s4.9-9.1,9.7-14.1,9.7-10.2,12.4-16.5,2.6-13.1,2.7-19.8.2-14.4,3.3-17.5,10.4-3.2,17.5-3.3,13.7-.2,19.8-2.7,11.5-7.6,16.5-12.4S124,32,128,32s9.1,4.9,14.1,9.7,10.2,9.7,16.5,12.4,13.1,2.6,19.8,2.7,14.4.2,17.5,3.3,3.2,10.4,3.3,17.5.2,13.7,2.7,19.8,7.6,11.5,12.4,16.5S224,124,224,128,219.1,137.1,214.3,142.1ZM120,136V80a8,8,0,0,1,16,0v56a8,8,0,0,1-16,0Zm20,36a12,12,0,1,1-12-12A12,12,0,0,1,140,172Z" />
        </>
      )}
    </svg>
  );
}

export function PhCircleWavy(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M228.7,100c-3.5-3.6-7.1-7.4-8.3-10.3s-1.1-7.8-1.2-12.4c-.2-10-.3-22.5-9.2-31.3s-21.3-9-31.3-9.2c-4.6-.1-9.9-.1-12.4-1.2s-6.7-4.8-10.3-8.3C148.9,20.5,140.1,12,128,12s-20.9,8.5-28,15.3c-3.6,3.5-7.4,7.1-10.3,8.3s-7.8,1.1-12.4,1.2c-10,.2-22.5.3-31.3,9.2s-9,21.3-9.2,31.3c-.1,4.6-.1,9.9-1.2,12.4s-4.8,6.7-8.3,10.3C20.5,107.1,12,115.9,12,128s8.5,20.9,15.3,28c3.5,3.6,7.1,7.4,8.3,10.3s1.1,7.8,1.2,12.4c.2,10,.3,22.5,9.2,31.3s21.3,9,31.3,9.2c4.6.1,9.9.1,12.4,1.2s6.7,4.8,10.3,8.3c7.1,6.8,15.9,15.3,28,15.3s20.9-8.5,28-15.3c3.6-3.5,7.4-7.1,10.3-8.3s7.8-1.1,12.4-1.2c10-.2,22.5-.3,31.3-9.2s9-21.3,9.2-31.3c.1-4.6.1-9.9,1.2-12.4s4.8-6.7,8.3-10.3c6.8-7.1,15.3-15.9,15.3-28S235.5,107.1,228.7,100Zm-17.3,39.3c-4.8,5.1-10.3,10.8-13.2,17.8s-2.9,14.2-3,21.3c-.1,5.4-.2,12.7-2.1,14.7s-9.3,2-14.7,2.1c-7.1.1-14.5.2-21.3,3s-12.7,8.4-17.8,13.2c-3.5,3.4-8.9,8.6-11.3,8.6s-7.8-5.2-11.3-8.6c-5.1-4.8-10.8-10.3-17.8-13.2s-14.2-2.9-21.3-3c-5.4-.1-12.7-.2-14.7-2.1s-2-9.3-2.1-14.7c-.1-7.1-.2-14.5-3-21.3s-8.4-12.7-13.2-17.8c-3.4-3.5-8.6-8.9-8.6-11.3s5.2-7.8,8.6-11.3c4.8-5.1,10.3-10.8,13.2-17.8s2.9-14.2,3-21.3c.1-5.4.2-12.7,2.1-14.7s9.3-2,14.7-2.1c7.1-.1,14.5-.2,21.3-3s12.7-8.4,17.8-13.2c3.5-3.4,8.9-8.6,11.3-8.6s7.8,5.2,11.3,8.6c5.1,4.8,10.8,10.3,17.8,13.2s14.2,2.9,21.3,3c5.4.1,12.7.2,14.7,2.1s2,9.3,2.1,14.7c.1,7.1.2,14.5,3,21.3s8.4,12.7,13.2,17.8c3.4,3.5,8.6,8.9,8.6,11.3S214.8,135.8,211.4,139.3Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M232,128c0,12.5-17.8,22-22.7,33.7s1.4,30.6-7.8,39.8-28.5,3.1-39.8,7.8S140.5,232,128,232s-22-17.8-33.7-22.7-30.6,1.4-39.8-7.8-3.1-28.5-7.8-39.8S24,140.5,24,128s17.8-22,22.7-33.7-1.4-30.6,7.8-39.8S83,51.4,94.3,46.7,115.5,24,128,24s22,17.8,33.7,22.7,30.6-1.4,39.8,7.8,3.1,28.5,7.8,39.8S232,115.5,232,128Z"
            opacity="0.2"
          />
          <path d="M225.9,102.8c-3.8-3.9-7.7-8-9.2-11.5s-1.4-8.7-1.5-14c-.1-9.7-.3-20.8-8-28.5s-18.8-7.9-28.5-8c-5.3-.1-10.7-.2-14-1.5s-7.6-5.4-11.5-9.2C146.3,23.5,138.4,16,128,16s-18.3,7.5-25.2,14.1c-3.9,3.8-8,7.7-11.5,9.2s-8.7,1.4-14,1.5c-9.7.1-20.8.3-28.5,8s-7.9,18.8-8,28.5c-.1,5.3-.2,10.7-1.5,14s-5.4,7.6-9.2,11.5C23.5,109.7,16,117.6,16,128s7.5,18.3,14.1,25.2c3.8,3.9,7.7,8,9.2,11.5s1.4,8.7,1.5,14c.1,9.7.3,20.8,8,28.5s18.8,7.9,28.5,8c5.3.1,10.7.2,14,1.5s7.6,5.4,11.5,9.2c6.9,6.6,14.8,14.1,25.2,14.1s18.3-7.5,25.2-14.1c3.9-3.8,8-7.7,11.5-9.2s8.7-1.4,14-1.5c9.7-.1,20.8-.3,28.5-8s7.9-18.8,8-28.5c.1-5.3.2-10.7,1.5-14s5.4-7.6,9.2-11.5c6.6-6.9,14.1-14.8,14.1-25.2S232.5,109.7,225.9,102.8Zm-11.6,39.3c-4.8,5-9.7,10.2-12.4,16.5s-2.6,13.1-2.7,19.8-.2,14.4-3.3,17.5-10.4,3.2-17.5,3.3-13.7.2-19.8,2.7-11.5,7.6-16.5,12.4S132,224,128,224s-9.1-4.9-14.1-9.7-10.2-9.7-16.5-12.4-13.1-2.6-19.8-2.7-14.4-.2-17.5-3.3h0c-3.1-3.1-3.2-10.4-3.3-17.5s-.2-13.7-2.7-19.8-7.6-11.5-12.4-16.5S32,132,32,128s4.9-9.1,9.7-14.1,9.7-10.2,12.4-16.5,2.6-13.1,2.7-19.8.2-14.4,3.3-17.5,10.4-3.2,17.5-3.3,13.7-.2,19.8-2.7,11.5-7.6,16.5-12.4S124,32,128,32s9.1,4.9,14.1,9.7,10.2,9.7,16.5,12.4,13.1,2.6,19.8,2.7,14.4.2,17.5,3.3,3.2,10.4,3.3,17.5.2,13.7,2.7,19.8,7.6,11.5,12.4,16.5S224,124,224,128,219.1,137.1,214.3,142.1Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M240,128c0,10.4-7.5,18.3-14.1,25.2-3.8,3.9-7.7,8-9.2,11.5s-1.4,8.7-1.5,14c-.1,9.7-.3,20.8-8,28.5s-18.8,7.9-28.5,8c-5.3.1-10.7.2-14,1.5s-7.6,5.4-11.5,9.2c-6.9,6.6-14.8,14.1-25.2,14.1s-18.3-7.5-25.2-14.1c-3.9-3.8-8-7.7-11.5-9.2s-8.7-1.4-14-1.5c-9.7-.1-20.8-.3-28.5-8s-7.9-18.8-8-28.5c-.1-5.3-.2-10.7-1.5-14s-5.4-7.6-9.2-11.5C23.5,146.3,16,138.4,16,128s7.5-18.3,14.1-25.2c3.8-3.9,7.7-8,9.2-11.5s1.4-8.7,1.5-14c.1-9.7.3-20.8,8-28.5s18.8-7.9,28.5-8c5.3-.1,10.7-.2,14-1.5s7.6-5.4,11.5-9.2C109.7,23.5,117.6,16,128,16s18.3,7.5,25.2,14.1c3.9,3.8,8,7.7,11.5,9.2s8.7,1.4,14,1.5c9.7.1,20.8.3,28.5,8s7.9,18.8,8,28.5c.1,5.3.2,10.7,1.5,14s5.4,7.6,9.2,11.5C232.5,109.7,240,117.6,240,128Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M224.4,104.2c-3.9-4.1-7.9-8.3-9.5-12.2s-1.6-9-1.7-14.7c-.1-9.3-.3-20-7.4-27.1s-17.8-7.3-27.1-7.4c-5.7-.1-11.1-.2-14.7-1.7s-8.1-5.6-12.2-9.5C145.2,25.2,137.6,18,128,18s-17.2,7.2-23.8,13.6c-4.1,3.9-8.3,7.9-12.2,9.5s-9,1.6-14.7,1.7c-9.3.1-20,.3-27.1,7.4S42.9,68,42.8,77.3c-.1,5.7-.2,11.1-1.7,14.7s-5.6,8.1-9.5,12.2C25.2,110.8,18,118.4,18,128s7.2,17.2,13.6,23.8c3.9,4.1,7.9,8.3,9.5,12.2s1.6,9,1.7,14.7c.1,9.3.3,20,7.4,27.1s17.8,7.3,27.1,7.4c5.7.1,11.1.2,14.7,1.7s8.1,5.6,12.2,9.5c6.6,6.4,14.2,13.6,23.8,13.6s17.2-7.2,23.8-13.6c4.1-3.9,8.3-7.9,12.2-9.5s9-1.6,14.7-1.7c9.3-.1,20-.3,27.1-7.4s7.3-17.8,7.4-27.1c.1-5.7.2-11.1,1.7-14.7s5.6-8.1,9.5-12.2c6.4-6.6,13.6-14.2,13.6-23.8S230.8,110.8,224.4,104.2Zm-8.6,39.3c-4.7,4.9-9.5,9.9-12,15.9s-2.5,12.5-2.6,19.1-.2,15.1-3.9,18.8-11.4,3.8-18.8,3.9-13.4.2-19.1,2.6-11,7.3-15.9,12S132.8,226,128,226s-10.2-5.2-15.5-10.2-9.9-9.5-15.9-12-12.5-2.5-19.1-2.6-15.1-.2-18.8-3.9-3.8-11.4-3.9-18.8-.2-13.4-2.6-19.1-7.3-11-12-15.9S30,132.8,30,128s5.2-10.2,10.2-15.5,9.5-9.9,12-15.9,2.5-12.5,2.6-19.1.2-15.1,3.9-18.8,11.4-3.8,18.8-3.9,13.4-.2,19.1-2.6,11-7.3,15.9-12S123.2,30,128,30s10.2,5.2,15.5,10.2,9.9,9.5,15.9,12,12.5,2.5,19.1,2.6,15.1.2,18.8,3.9,3.8,11.4,3.9,18.8.2,13.4,2.6,19.1,7.3,11,12,15.9S226,123.2,226,128,220.8,138.2,215.8,143.5Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M223,105.6c-4.1-4.2-8.2-8.6-10-12.8s-1.7-9.5-1.8-15.4c-.1-9-.3-19.2-6.8-25.8s-16.8-6.7-25.8-6.8c-5.9-.1-11.4-.2-15.4-1.8s-8.6-5.9-12.8-10c-6.7-6.4-13.6-13-22.4-13s-15.7,6.6-22.4,13c-4.2,4.1-8.6,8.2-12.8,10s-9.5,1.7-15.4,1.8c-9,.1-19.2.3-25.8,6.8s-6.7,16.8-6.8,25.8c-.1,5.9-.2,11.4-1.8,15.4s-5.9,8.6-10,12.8c-6.4,6.7-13,13.6-13,22.4s6.6,15.7,13,22.4c4.1,4.2,8.2,8.6,10,12.8s1.7,9.5,1.8,15.4c.1,9,.3,19.2,6.8,25.8s16.8,6.7,25.8,6.8c5.9.1,11.4.2,15.4,1.8s8.6,5.9,12.8,10c6.7,6.4,13.6,13,22.4,13s15.7-6.6,22.4-13c4.2-4.1,8.6-8.2,12.8-10s9.5-1.7,15.4-1.8c9-.1,19.2-.3,25.8-6.8s6.7-16.8,6.8-25.8c.1-5.9.2-11.4,1.8-15.4s5.9-8.6,10-12.8c6.4-6.7,13-13.6,13-22.4S229.4,112.3,223,105.6Zm-5.8,39.3c-4.5,4.7-9.2,9.6-11.6,15.3s-2.3,11.9-2.4,18.3c-.1,8.2-.2,16-4.5,20.2s-12,4.4-20.2,4.5c-6.4.1-13,.2-18.3,2.4s-10.6,7.1-15.3,11.6S133.6,228,128,228s-11.1-5.2-16.9-10.8-9.6-9.2-15.3-11.6-11.9-2.3-18.3-2.4c-8.2-.1-16-.2-20.2-4.5h0c-4.3-4.2-4.4-12-4.5-20.2-.1-6.4-.2-13-2.4-18.3s-7.1-10.6-11.6-15.3S28,133.6,28,128s5.2-11.1,10.8-16.9,9.2-9.6,11.6-15.3,2.3-11.9,2.4-18.3c.1-8.2.2-16,4.5-20.2s12-4.4,20.2-4.5c6.4-.1,13-.2,18.3-2.4s10.6-7.1,15.3-11.6S122.4,28,128,28s11.1,5.2,16.9,10.8,9.6,9.2,15.3,11.6,11.9,2.3,18.3,2.4c8.2.1,16,.2,20.2,4.5s4.4,12,4.5,20.2c.1,6.4.2,13,2.4,18.3s7.1,10.6,11.6,15.3S228,122.4,228,128,222.8,139.1,217.2,144.9Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M225.9,102.8c-3.8-3.9-7.7-8-9.2-11.5s-1.4-8.7-1.5-14c-.1-9.7-.3-20.8-8-28.5s-18.8-7.9-28.5-8c-5.3-.1-10.7-.2-14-1.5s-7.6-5.4-11.5-9.2C146.3,23.5,138.4,16,128,16s-18.3,7.5-25.2,14.1c-3.9,3.8-8,7.7-11.5,9.2s-8.7,1.4-14,1.5c-9.7.1-20.8.3-28.5,8s-7.9,18.8-8,28.5c-.1,5.3-.2,10.7-1.5,14s-5.4,7.6-9.2,11.5C23.5,109.7,16,117.6,16,128s7.5,18.3,14.1,25.2c3.8,3.9,7.7,8,9.2,11.5s1.4,8.7,1.5,14c.1,9.7.3,20.8,8,28.5s18.8,7.9,28.5,8c5.3.1,10.7.2,14,1.5s7.6,5.4,11.5,9.2c6.9,6.6,14.8,14.1,25.2,14.1s18.3-7.5,25.2-14.1c3.9-3.8,8-7.7,11.5-9.2s8.7-1.4,14-1.5c9.7-.1,20.8-.3,28.5-8s7.9-18.8,8-28.5c.1-5.3.2-10.7,1.5-14s5.4-7.6,9.2-11.5c6.6-6.9,14.1-14.8,14.1-25.2S232.5,109.7,225.9,102.8Zm-11.6,39.3c-4.8,5-9.7,10.2-12.4,16.5s-2.6,13.1-2.7,19.8-.2,14.4-3.3,17.5-10.4,3.2-17.5,3.3-13.7.2-19.8,2.7-11.5,7.6-16.5,12.4S132,224,128,224s-9.1-4.9-14.1-9.7-10.2-9.7-16.5-12.4-13.1-2.6-19.8-2.7-14.4-.2-17.5-3.3h0c-3.1-3.1-3.2-10.4-3.3-17.5s-.2-13.7-2.7-19.8-7.6-11.5-12.4-16.5S32,132,32,128s4.9-9.1,9.7-14.1,9.7-10.2,12.4-16.5,2.6-13.1,2.7-19.8.2-14.4,3.3-17.5,10.4-3.2,17.5-3.3,13.7-.2,19.8-2.7,11.5-7.6,16.5-12.4S124,32,128,32s9.1,4.9,14.1,9.7,10.2,9.7,16.5,12.4,13.1,2.6,19.8,2.7,14.4.2,17.5,3.3,3.2,10.4,3.3,17.5.2,13.7,2.7,19.8,7.6,11.5,12.4,16.5S224,124,224,128,219.1,137.1,214.3,142.1Z" />
        </>
      )}
    </svg>
  );
}

export function PhCircle(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,236A108,108,0,1,1,236,128,108.1,108.1,0,0,1,128,236Zm0-192a84,84,0,1,0,84,84A84.1,84.1,0,0,0,128,44Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="128" cy="128" r="96" />
          </g>
          <path d="M128,232A104,104,0,1,1,232,128,104.2,104.2,0,0,1,128,232Zm0-192a88,88,0,1,0,88,88A88.1,88.1,0,0,0,128,40Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M232,128A104,104,0,1,1,128,24,104.2,104.2,0,0,1,232,128Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,230A102,102,0,1,1,230,128,102.2,102.2,0,0,1,128,230Zm0-192a90,90,0,1,0,90,90A90.1,90.1,0,0,0,128,38Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,228A100,100,0,1,1,228,128,100.2,100.2,0,0,1,128,228Zm0-192a92,92,0,1,0,92,92A92.1,92.1,0,0,0,128,36Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,232A104,104,0,1,1,232,128,104.2,104.2,0,0,1,128,232Zm0-192a88,88,0,1,0,88,88A88.1,88.1,0,0,0,128,40Z" />
        </>
      )}
    </svg>
  );
}

export function PhCirclesFour(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M74,28a46,46,0,1,0,46,46A46,46,0,0,0,74,28Zm0,68A22,22,0,1,1,96,74,22.1,22.1,0,0,1,74,96Zm108,24a46,46,0,1,0-46-46A46,46,0,0,0,182,120Zm0-68a22,22,0,1,1-22,22A22.1,22.1,0,0,1,182,52ZM74,136a46,46,0,1,0,46,46A46,46,0,0,0,74,136Zm0,68a22,22,0,1,1,22-22A22.1,22.1,0,0,1,74,204Zm108-68a46,46,0,1,0,46,46A46,46,0,0,0,182,136Zm0,68a22,22,0,1,1,22-22A22.1,22.1,0,0,1,182,204Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="76" cy="180" r="36" />
          </g>
          <g opacity="0.2">
            <circle cx="76" cy="76" r="36" />
          </g>
          <g opacity="0.2">
            <circle cx="180" cy="76" r="36" />
          </g>
          <g opacity="0.2">
            <circle cx="180" cy="180" r="36" />
          </g>
          <path d="M76,32a44,44,0,1,0,44,44A44,44,0,0,0,76,32Zm0,72a28,28,0,1,1,28-28A28.1,28.1,0,0,1,76,104Z" />
          <path d="M180,120a44,44,0,1,0-44-44A44,44,0,0,0,180,120Zm0-72a28,28,0,1,1-28,28A28.1,28.1,0,0,1,180,48Z" />
          <path d="M180,136a44,44,0,1,0,44,44A44,44,0,0,0,180,136Zm0,72a28,28,0,1,1,28-28A28.1,28.1,0,0,1,180,208Z" />
          <path d="M76,136a44,44,0,1,0,44,44A44,44,0,0,0,76,136Zm0,72a28,28,0,1,1,28-28A28.1,28.1,0,0,1,76,208Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M120,76A44,44,0,1,1,76,32,44,44,0,0,1,120,76Zm60,44a44,44,0,1,0-44-44A44,44,0,0,0,180,120ZM76,136a44,44,0,1,0,44,44A44,44,0,0,0,76,136Zm104,0a44,44,0,1,0,44,44A44,44,0,0,0,180,136Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M76,34a42,42,0,1,0,42,42A42,42,0,0,0,76,34Zm0,72a30,30,0,1,1,30-30A30.1,30.1,0,0,1,76,106Zm104,12a42,42,0,1,0-42-42A42,42,0,0,0,180,118Zm0-72a30,30,0,1,1-30,30A30.1,30.1,0,0,1,180,46ZM76,138a42,42,0,1,0,42,42A42,42,0,0,0,76,138Zm0,72a30,30,0,1,1,30-30A30.1,30.1,0,0,1,76,210Zm104-72a42,42,0,1,0,42,42A42,42,0,0,0,180,138Zm0,72a30,30,0,1,1,30-30A30.1,30.1,0,0,1,180,210Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M76,36a40,40,0,1,0,40,40A40,40,0,0,0,76,36Zm0,72a32,32,0,1,1,32-32A32.1,32.1,0,0,1,76,108Zm104,8a40,40,0,1,0-40-40A40,40,0,0,0,180,116Zm0-72a32,32,0,1,1-32,32A32.1,32.1,0,0,1,180,44ZM76,140a40,40,0,1,0,40,40A40,40,0,0,0,76,140Zm0,72a32,32,0,1,1,32-32A32.1,32.1,0,0,1,76,212Zm104-72a40,40,0,1,0,40,40A40,40,0,0,0,180,140Zm0,72a32,32,0,1,1,32-32A32.1,32.1,0,0,1,180,212Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M76,32a44,44,0,1,0,44,44A44,44,0,0,0,76,32Zm0,72a28,28,0,1,1,28-28A28.1,28.1,0,0,1,76,104Zm104,16a44,44,0,1,0-44-44A44,44,0,0,0,180,120Zm0-72a28,28,0,1,1-28,28A28.1,28.1,0,0,1,180,48ZM76,136a44,44,0,1,0,44,44A44,44,0,0,0,76,136Zm0,72a28,28,0,1,1,28-28A28.1,28.1,0,0,1,76,208Zm104-72a44,44,0,1,0,44,44A44,44,0,0,0,180,136Zm0,72a28,28,0,1,1,28-28A28.1,28.1,0,0,1,180,208Z" />
        </>
      )}
    </svg>
  );
}

export function PhCirclesThreePlus(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M74,28a46,46,0,1,0,46,46A46,46,0,0,0,74,28Zm0,68A22,22,0,1,1,96,74,22.1,22.1,0,0,1,74,96Zm108,24a46,46,0,1,0-46-46A46,46,0,0,0,182,120Zm0-68a22,22,0,1,1-22,22A22.1,22.1,0,0,1,182,52ZM74,136a46,46,0,1,0,46,46A46,46,0,0,0,74,136Zm0,68a22,22,0,1,1,22-22A22.1,22.1,0,0,1,74,204Zm146-22a12,12,0,0,1-12,12H194v14a12,12,0,0,1-24,0V194H156a12,12,0,0,1,0-24h14V156a12,12,0,0,1,24,0v14h14A12,12,0,0,1,220,182Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="76" cy="76" r="36" />
          </g>
          <g opacity="0.2">
            <circle cx="180" cy="76" r="36" />
          </g>
          <g opacity="0.2">
            <circle cx="76" cy="180" r="36" />
          </g>
          <path d="M76,32a44,44,0,1,0,44,44A44,44,0,0,0,76,32Zm0,72a28,28,0,1,1,28-28A28.1,28.1,0,0,1,76,104Z" />
          <path d="M180,120a44,44,0,1,0-44-44A44,44,0,0,0,180,120Zm0-72a28,28,0,1,1-28,28A28.1,28.1,0,0,1,180,48Z" />
          <path d="M76,136a44,44,0,1,0,44,44A44,44,0,0,0,76,136Zm0,72a28,28,0,1,1,28-28A28.1,28.1,0,0,1,76,208Z" />
          <path d="M208,172H188V152a8,8,0,0,0-16,0v20H152a8,8,0,0,0,0,16h20v20a8,8,0,0,0,16,0V188h20a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M120,76A44,44,0,1,1,76,32,44,44,0,0,1,120,76Zm60,44a44,44,0,1,0-44-44A44,44,0,0,0,180,120ZM76,136a44,44,0,1,0,44,44A44,44,0,0,0,76,136Zm132,36H188V152a8,8,0,0,0-16,0v20H152a8,8,0,0,0,0,16h20v20a8,8,0,0,0,16,0V188h20a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M76,34a42,42,0,1,0,42,42A42,42,0,0,0,76,34Zm0,72a30,30,0,1,1,30-30A30.1,30.1,0,0,1,76,106Zm104,12a42,42,0,1,0-42-42A42,42,0,0,0,180,118Zm0-72a30,30,0,1,1-30,30A30.1,30.1,0,0,1,180,46ZM76,138a42,42,0,1,0,42,42A42,42,0,0,0,76,138Zm0,72a30,30,0,1,1,30-30A30.1,30.1,0,0,1,76,210Zm138-30a6,6,0,0,1-6,6H186v22a6,6,0,0,1-12,0V186H152a6,6,0,0,1,0-12h22V152a6,6,0,0,1,12,0v22h22A6,6,0,0,1,214,180Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M76,36a40,40,0,1,0,40,40A40,40,0,0,0,76,36Zm0,72a32,32,0,1,1,32-32A32.1,32.1,0,0,1,76,108Zm104,8a40,40,0,1,0-40-40A40,40,0,0,0,180,116Zm0-72a32,32,0,1,1-32,32A32.1,32.1,0,0,1,180,44ZM76,140a40,40,0,1,0,40,40A40,40,0,0,0,76,140Zm0,72a32,32,0,1,1,32-32A32.1,32.1,0,0,1,76,212Zm136-32a4,4,0,0,1-4,4H184v24a4,4,0,0,1-8,0V184H152a4,4,0,0,1,0-8h24V152a4,4,0,0,1,8,0v24h24A4,4,0,0,1,212,180Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M76,32a44,44,0,1,0,44,44A44,44,0,0,0,76,32Zm0,72a28,28,0,1,1,28-28A28.1,28.1,0,0,1,76,104Zm104,16a44,44,0,1,0-44-44A44,44,0,0,0,180,120Zm0-72a28,28,0,1,1-28,28A28.1,28.1,0,0,1,180,48ZM76,136a44,44,0,1,0,44,44A44,44,0,0,0,76,136Zm0,72a28,28,0,1,1,28-28A28.1,28.1,0,0,1,76,208Zm140-28a8,8,0,0,1-8,8H188v20a8,8,0,0,1-16,0V188H152a8,8,0,0,1,0-16h20V152a8,8,0,0,1,16,0v20h20A8,8,0,0,1,216,180Z" />
        </>
      )}
    </svg>
  );
}

export function PhCirclesThree(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M180,68a52,52,0,1,0-52,52A52,52,0,0,0,180,68ZM128,96a28,28,0,1,1,28-28A28.1,28.1,0,0,1,128,96Zm60,24a52,52,0,1,0,52,52A52,52,0,0,0,188,120Zm0,80a28,28,0,1,1,28-28A28.1,28.1,0,0,1,188,200ZM68,120a52,52,0,1,0,52,52A52,52,0,0,0,68,120Zm0,80a28,28,0,1,1,28-28A28.1,28.1,0,0,1,68,200Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="128" cy="68" r="40" />
          </g>
          <g opacity="0.2">
            <circle cx="188" cy="172" r="40" />
          </g>
          <g opacity="0.2">
            <circle cx="68" cy="172" r="40" />
          </g>
          <path d="M176,68a48,48,0,1,0-48,48A48,48,0,0,0,176,68Zm-48,32a32,32,0,1,1,32-32A32.1,32.1,0,0,1,128,100Z" />
          <path d="M188,124a48,48,0,1,0,48,48A48,48,0,0,0,188,124Zm0,80a32,32,0,1,1,32-32A32.1,32.1,0,0,1,188,204Z" />
          <path d="M68,124a48,48,0,1,0,48,48A48,48,0,0,0,68,124Zm0,80a32,32,0,1,1,32-32A32.1,32.1,0,0,1,68,204Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,116a48,48,0,1,1,48-48A48,48,0,0,1,128,116Zm60,8a48,48,0,1,0,48,48A48,48,0,0,0,188,124ZM68,124a48,48,0,1,0,48,48A48,48,0,0,0,68,124Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M174,68a46,46,0,1,0-46,46A46,46,0,0,0,174,68Zm-46,34a34,34,0,1,1,34-34A34.1,34.1,0,0,1,128,102Zm60,24a46,46,0,1,0,46,46A46,46,0,0,0,188,126Zm0,80a34,34,0,1,1,34-34A34.1,34.1,0,0,1,188,206ZM68,126a46,46,0,1,0,46,46A46,46,0,0,0,68,126Zm0,80a34,34,0,1,1,34-34A34.1,34.1,0,0,1,68,206Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M172,68a44,44,0,1,0-44,44A44,44,0,0,0,172,68Zm-44,36a36,36,0,1,1,36-36A36,36,0,0,1,128,104Zm60,24a44,44,0,1,0,44,44A44,44,0,0,0,188,128Zm0,80a36,36,0,1,1,36-36A36,36,0,0,1,188,208ZM68,128a44,44,0,1,0,44,44A44,44,0,0,0,68,128Zm0,80a36,36,0,1,1,36-36A36,36,0,0,1,68,208Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M176,68a48,48,0,1,0-48,48A48,48,0,0,0,176,68Zm-48,32a32,32,0,1,1,32-32A32.1,32.1,0,0,1,128,100Zm60,24a48,48,0,1,0,48,48A48,48,0,0,0,188,124Zm0,80a32,32,0,1,1,32-32A32.1,32.1,0,0,1,188,204ZM68,124a48,48,0,1,0,48,48A48,48,0,0,0,68,124Zm0,80a32,32,0,1,1,32-32A32.1,32.1,0,0,1,68,204Z" />
        </>
      )}
    </svg>
  );
}

export function PhClipboardText(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M172,156a12,12,0,0,1-12,12H96a12,12,0,0,1,0-24h64A12,12,0,0,1,172,156Zm-12-52H96a12,12,0,0,0,0,24h64a12,12,0,0,0,0-24Zm60-56V216a20.1,20.1,0,0,1-20,20H56a20.1,20.1,0,0,1-20-20V48A20.1,20.1,0,0,1,56,28H90.5a51.9,51.9,0,0,1,75,0H200A20.1,20.1,0,0,1,220,48ZM128,36a27.9,27.9,0,0,0-27.7,24h55.4A27.9,27.9,0,0,0,128,36Zm68,16H178.6A53.2,53.2,0,0,1,180,64v8a12,12,0,0,1-12,12H88A12,12,0,0,1,76,72V64a53.2,53.2,0,0,1,1.4-12H60V212H196Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M208,48V216a8,8,0,0,1-8,8H56a8,8,0,0,1-8-8V48a8,8,0,0,1,8-8H96a40,40,0,0,0-8,24v8h80V64a40,40,0,0,0-8-24h40A8,8,0,0,1,208,48Z"
            opacity="0.2"
          />
          <path d="M168,152a8,8,0,0,1-8,8H96a8,8,0,0,1,0-16h64A8,8,0,0,1,168,152Zm-8-40H96a8,8,0,0,0,0,16h64a8,8,0,0,0,0-16Zm56-64V216a16,16,0,0,1-16,16H56a16,16,0,0,1-16-16V48A16,16,0,0,1,56,32H92.3a47.8,47.8,0,0,1,71.4,0H200A16,16,0,0,1,216,48ZM96,64h64a32,32,0,0,0-64,0ZM200,48H173.2A47.2,47.2,0,0,1,176,64v8a8,8,0,0,1-8,8H88a8,8,0,0,1-8-8V64a47.2,47.2,0,0,1,2.8-16H56V216H200Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M200,32H163.7a47.8,47.8,0,0,0-71.4,0H56A16,16,0,0,0,40,48V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V48A16,16,0,0,0,200,32Zm-72,0a32.1,32.1,0,0,1,32,32H96A32.1,32.1,0,0,1,128,32Zm32,128H96a8,8,0,0,1,0-16h64a8,8,0,0,1,0,16Zm0-32H96a8,8,0,0,1,0-16h64a8,8,0,0,1,0,16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M166,152a6,6,0,0,1-6,6H96a6,6,0,0,1,0-12h64A6,6,0,0,1,166,152Zm-6-38H96a6,6,0,0,0,0,12h64a6,6,0,0,0,0-12Zm54-66V216a14,14,0,0,1-14,14H56a14,14,0,0,1-14-14V48A14,14,0,0,1,56,34H93.2a45.8,45.8,0,0,1,69.6,0H200A14,14,0,0,1,214,48ZM94,64v2h68V64a34,34,0,0,0-68,0ZM202,48a2,2,0,0,0-2-2H170.3A44.9,44.9,0,0,1,174,64v8a6,6,0,0,1-6,6H88a6,6,0,0,1-6-6V64a44.9,44.9,0,0,1,3.7-18H56a2,2,0,0,0-2,2V216a2,2,0,0,0,2,2H200a2,2,0,0,0,2-2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M164,152a4,4,0,0,1-4,4H96a4,4,0,0,1,0-8h64A4,4,0,0,1,164,152Zm-4-36H96a4,4,0,0,0,0,8h64a4,4,0,0,0,0-8Zm52-68V216a12,12,0,0,1-12,12H56a12,12,0,0,1-12-12V48A12,12,0,0,1,56,36H94.1a43.9,43.9,0,0,1,67.8,0H200A12,12,0,0,1,212,48ZM92,64v4h72V64a36,36,0,0,0-72,0ZM204,48a4,4,0,0,0-4-4H167.2A43.4,43.4,0,0,1,172,64v8a4,4,0,0,1-4,4H88a4,4,0,0,1-4-4V64a43.4,43.4,0,0,1,4.8-20H56a4,4,0,0,0-4,4V216a4,4,0,0,0,4,4H200a4,4,0,0,0,4-4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M168,152a8,8,0,0,1-8,8H96a8,8,0,0,1,0-16h64A8,8,0,0,1,168,152Zm-8-40H96a8,8,0,0,0,0,16h64a8,8,0,0,0,0-16Zm56-64V216a16,16,0,0,1-16,16H56a16,16,0,0,1-16-16V48A16,16,0,0,1,56,32H92.3a47.8,47.8,0,0,1,71.4,0H200A16,16,0,0,1,216,48ZM96,64h64a32,32,0,0,0-64,0ZM200,48H173.2A47.2,47.2,0,0,1,176,64v8a8,8,0,0,1-8,8H88a8,8,0,0,1-8-8V64a47.2,47.2,0,0,1,2.8-16H56V216H200Z" />
        </>
      )}
    </svg>
  );
}

export function PhClipboard(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M200,28H165.5a51.9,51.9,0,0,0-75,0H56A20.1,20.1,0,0,0,36,48V216a20.1,20.1,0,0,0,20,20H200a20.1,20.1,0,0,0,20-20V48A20.1,20.1,0,0,0,200,28ZM155.7,60H100.3a28,28,0,0,1,55.4,0ZM196,212H60V52H77.4A53.2,53.2,0,0,0,76,64v8A12,12,0,0,0,88,84h80a12,12,0,0,0,12-12V64a53.2,53.2,0,0,0-1.4-12H196Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M208,48V216a8,8,0,0,1-8,8H56a8,8,0,0,1-8-8V48a8,8,0,0,1,8-8H96a40,40,0,0,0-8,24v8h80V64a40,40,0,0,0-8-24h40A8,8,0,0,1,208,48Z"
            opacity="0.2"
          />
          <path d="M200,32H163.7a47.8,47.8,0,0,0-71.4,0H56A16,16,0,0,0,40,48V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V48A16,16,0,0,0,200,32Zm-72,0a32.1,32.1,0,0,1,32,32H96A32.1,32.1,0,0,1,128,32Zm72,184H56V48H82.8A47.2,47.2,0,0,0,80,64v8a8,8,0,0,0,8,8h80a8,8,0,0,0,8-8V64a47.2,47.2,0,0,0-2.8-16H200Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M200,32H163.7a47.8,47.8,0,0,0-71.4,0H56A16,16,0,0,0,40,48V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V48A16,16,0,0,0,200,32ZM96,64a32,32,0,0,1,64,0Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M200,34H162.8a45.8,45.8,0,0,0-69.6,0H56A14,14,0,0,0,42,48V216a14,14,0,0,0,14,14H200a14,14,0,0,0,14-14V48A14,14,0,0,0,200,34Zm-72-4a34.1,34.1,0,0,1,34,34v2H94V64A34.1,34.1,0,0,1,128,30Zm74,186a2,2,0,0,1-2,2H56a2,2,0,0,1-2-2V48a2,2,0,0,1,2-2H85.7A44.9,44.9,0,0,0,82,64v8a6,6,0,0,0,6,6h80a6,6,0,0,0,6-6V64a44.9,44.9,0,0,0-3.7-18H200a2,2,0,0,1,2,2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M200,36H161.9a43.9,43.9,0,0,0-67.8,0H56A12,12,0,0,0,44,48V216a12,12,0,0,0,12,12H200a12,12,0,0,0,12-12V48A12,12,0,0,0,200,36Zm-72-8a36,36,0,0,1,36,36v4H92V64A36,36,0,0,1,128,28Zm76,188a4,4,0,0,1-4,4H56a4,4,0,0,1-4-4V48a4,4,0,0,1,4-4H88.8A43.4,43.4,0,0,0,84,64v8a4,4,0,0,0,4,4h80a4,4,0,0,0,4-4V64a43.4,43.4,0,0,0-4.8-20H200a4,4,0,0,1,4,4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M200,32H163.7a47.8,47.8,0,0,0-71.4,0H56A16,16,0,0,0,40,48V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V48A16,16,0,0,0,200,32Zm-72,0a32.1,32.1,0,0,1,32,32H96A32.1,32.1,0,0,1,128,32Zm72,184H56V48H82.8A47.2,47.2,0,0,0,80,64v8a8,8,0,0,0,8,8h80a8,8,0,0,0,8-8V64a47.2,47.2,0,0,0-2.8-16H200Z" />
        </>
      )}
    </svg>
  );
}

export function PhClockAfternoon(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,20A108,108,0,1,0,236,128,108.1,108.1,0,0,0,128,20Zm0,192a84,84,0,1,1,84-84A84.1,84.1,0,0,1,128,212Zm68-84a12,12,0,0,1-12,12H157l19.1,19.1a12,12,0,0,1,0,17,12.1,12.1,0,0,1-17,0l-39.6-39.6h0A12,12,0,0,1,128,116h56A12,12,0,0,1,196,128Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M224,128a96,96,0,1,1-96-96A96,96,0,0,1,224,128Z"
            opacity="0.2"
          />
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm64-88a8,8,0,0,1-8,8H147.3l26,25.9a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0l-39.6-39.6A8.4,8.4,0,0,1,120,128a8,8,0,0,1,8-8h56A8,8,0,0,1,192,128Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm56,112H147.3l26,25.9a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0l-39.6-39.6A8.4,8.4,0,0,1,120,128a8,8,0,0,1,8-8h56a8,8,0,0,1,0,16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,26A102,102,0,1,0,230,128,102.2,102.2,0,0,0,128,26Zm0,192a90,90,0,1,1,90-90A90.1,90.1,0,0,1,128,218Zm62-90a6,6,0,0,1-6,6H142.5l29.3,29.4a5.9,5.9,0,1,1-8.4,8.4l-39.6-39.6h0A5.6,5.6,0,0,1,122,128a6,6,0,0,1,6-6h56A6,6,0,0,1,190,128Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,28A100,100,0,1,0,228,128,100.2,100.2,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm60-92a4,4,0,0,1-4,4H137.7l32.7,32.8a3.9,3.9,0,0,1,0,5.6,3.9,3.9,0,0,1-5.6,0l-39.6-39.6a3.9,3.9,0,0,1,0-5.6A3.9,3.9,0,0,1,128,124h56A4,4,0,0,1,188,128Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm64-88a8,8,0,0,1-8,8H147.3l26,25.9a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0l-39.6-39.6A8.4,8.4,0,0,1,120,128a8,8,0,0,1,8-8h56A8,8,0,0,1,192,128Z" />
        </>
      )}
    </svg>
  );
}

export function PhClockClockwise(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M140,80v41.1l35.6,20.5A12.1,12.1,0,0,1,180,158a12.2,12.2,0,0,1-10.4,6,11.7,11.7,0,0,1-6-1.6l-41.6-24-.3-.2-.6-.4-.4-.3-.5-.4-.5-.4-.3-.4-.5-.5-.3-.3-.4-.6-.3-.4-.3-.5-.3-.5-.2-.5-.3-.6c0-.2-.1-.3-.1-.5a1.4,1.4,0,0,1-.2-.6l-.2-.5c0-.2-.1-.4-.1-.6s-.1-.4-.1-.6V129a1.5,1.5,0,0,1-.1-.7V80a12,12,0,0,1,24,0Zm86.5,31.5.6-.2h.6l.5-.2.6-.2.5-.2.5-.3.5-.3.5-.3.5-.4.5-.3a3,3,0,0,0,.8-.8h.1l.7-.9.4-.4.3-.5.4-.5.2-.5.3-.6.3-.5a.8.8,0,0,1,.2-.5c0-.2.1-.4.1-.6s.1-.4.2-.5.1-.4.1-.6a2,2,0,0,0,.1-.7.9.9,0,0,0,.1-.5c0-.4.1-.8.1-1.2v-40a12,12,0,1,0-24,0v11L198.7,57.3a100,100,0,1,0,0,141.4,12,12,0,0,0,0-17,12.2,12.2,0,0,0-17,0,75.9,75.9,0,1,1,0-107.4l13.5,13.4h-11a12,12,0,0,0,0,24h42.3Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="128" cy="128" r="88" />
          </g>
          <path d="M128,72a8,8,0,0,0-8,8v48.7c0,.1.1.2.1.4v.4c.1.1.1.2.1.4a.4.4,0,0,1,.1.3l.2.4a.4.4,0,0,0,.1.3c0,.2.1.3.1.4l.2.3a.5.5,0,0,0,.2.4l.2.3.2.3.2.4.2.2c.1.1.2.3.4.4l.2.2.3.3.3.2.3.3.4.2h.2l41.6,24a7.1,7.1,0,0,0,4,1.1,8,8,0,0,0,4-14.9L136,123.4V80A8,8,0,0,0,128,72Z" />
          <path d="M230.8,104.2c.1-.2.2-.3.2-.4l.2-.3c.1-.1.1-.3.2-.4l.2-.3a.6.6,0,0,1,.1-.4c0-.1.1-.2.1-.4a.4.4,0,0,0,.1-.3c0-.2.1-.3.1-.4s.1-.3.1-.4v-.4a2.2,2.2,0,0,0,.1-.8v-40a8,8,0,1,0-16,0V80.4L195.9,60.1a96,96,0,1,0,0,135.8,8,8,0,0,0,0-11.3,7.9,7.9,0,0,0-11.3,0,80,80,0,1,1,0-113.2l20.3,20.3H184.2a8,8,0,0,0,0,16h42.3l.4-.2h.3l.4-.2h.3l.4-.2.3-.2.4-.3.2-.2,1.2-1.1.2-.3Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M136,80v43.4l37.6,21.7a8,8,0,0,1-4,14.9,7.1,7.1,0,0,1-4-1.1l-41.6-24h-.2l-.4-.2-.3-.3-.3-.2-.3-.3-.2-.2c-.2-.1-.3-.3-.4-.4l-.2-.2-.2-.4-.2-.3-.2-.3a.5.5,0,0,1-.2-.4l-.2-.3c0-.1-.1-.2-.1-.4a.4.4,0,0,1-.1-.3l-.2-.4a.4.4,0,0,0-.1-.3c0-.2,0-.3-.1-.4v-.4c0-.2-.1-.3-.1-.4V80a8,8,0,0,1,16,0Zm91.2-27.7a8,8,0,0,0-8.7,1.8L204.2,68.4l-8.3-8.3a96,96,0,1,0,0,135.8,8,8,0,0,0,0-11.3,7.9,7.9,0,0,0-11.3,0,80,80,0,1,1,0-113.2l8.3,8.3L178.5,94.1a8,8,0,0,0,5.7,13.6h40a8,8,0,0,0,8-8v-40A8.2,8.2,0,0,0,227.2,52.3Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M134,80v44.5l38.6,22.3a6,6,0,0,1-6,10.4l-41.6-24h-.1l-.3-.2-.3-.2h-.2l-.2-.3h-.2l-.2-.3-.2-.2-.2-.2v-.2l-.2-.3c0-.1-.1-.2-.1-.3s-.1-.1-.1-.2l-.2-.3c0-.1,0-.2-.1-.2a.8.8,0,0,0-.1-.4v-.2a.4.4,0,0,1-.1-.3v-.3a.4.4,0,0,1-.1-.3V80a6,6,0,0,1,12,0Zm95.2,23a4.7,4.7,0,0,0,.5-1h0a4.2,4.2,0,0,0,.4-1h0c0-.3.1-.7.1-1.1v-40a6,6,0,1,0-12,0V85.2L194.5,61.5a94,94,0,1,0,0,133,6.1,6.1,0,0,0,0-8.5,5.9,5.9,0,0,0-8.5,0,82,82,0,1,1,0-116l23.7,23.7H184.2a6,6,0,0,0,0,12h41.1l1.1-.3h0l1-.5h.1l.9-.7h0l.8-1Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M124,128.1h0V80a4,4,0,0,1,8,0v45.7l39.6,22.8A4,4,0,0,1,173,154a3.9,3.9,0,0,1-3.4,2,4.6,4.6,0,0,1-2-.5l-41.6-24h-.1l-.4-.3-.2-.2-.3-.3-.3-.3c0-.1-.1-.2-.2-.3s-.1-.2-.1-.3l-.2-.4c0-.1-.1-.3-.1-.4v-.3A.9.9,0,0,1,124,128.1Zm103.7-26.5.2-.4a.8.8,0,0,1,.1-.4v-.3c0-.3.1-.5.1-.8v-40a4,4,0,1,0-8,0V90.1L193.1,62.9a92,92,0,1,0,0,130.2,4.2,4.2,0,0,0,0-5.7,4,4,0,0,0-5.7,0,84,84,0,1,1,0-118.8l27.1,27.1H184.2a4,4,0,0,0,0,8h41.5l.4-.2.3-.2.6-.4h0l.5-.6Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M136,80v43.4l37.6,21.7a8,8,0,0,1-4,14.9,7.1,7.1,0,0,1-4-1.1l-41.6-24h-.2l-.4-.3-.3-.2-.3-.2-.3-.3-.2-.2c-.2-.1-.3-.3-.4-.4l-.2-.2-.2-.4-.2-.3-.2-.3a.5.5,0,0,1-.2-.4l-.2-.3c0-.1-.1-.2-.1-.4a.4.4,0,0,1-.1-.3l-.2-.4a.4.4,0,0,0-.1-.3c0-.2,0-.3-.1-.4v-.4c0-.2-.1-.3-.1-.4V80a8,8,0,0,1,16,0Zm94.8,24.2c.1-.2.2-.3.2-.4l.2-.3c.1-.1.1-.3.2-.4l.2-.3a.6.6,0,0,1,.1-.4c0-.1.1-.2.1-.4a.4.4,0,0,0,.1-.3c0-.2.1-.3.1-.4s.1-.3.1-.4v-.4a2.2,2.2,0,0,0,.1-.8v-40a8,8,0,1,0-16,0V80.4L195.9,60.1a96,96,0,1,0,0,135.8,8,8,0,0,0,0-11.3,7.9,7.9,0,0,0-11.3,0,80,80,0,1,1,0-113.2l20.3,20.3H184.2a8,8,0,0,0,0,16h42.3l.4-.2h.3l.4-.2h.3l.4-.2.3-.2.4-.3.2-.2,1.2-1.1.2-.3Z" />
        </>
      )}
    </svg>
  );
}

export function PhClockCounterClockwise(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M140,80v41.1l35.6,20.5A12.1,12.1,0,0,1,180,158a12.2,12.2,0,0,1-10.4,6,11.7,11.7,0,0,1-6-1.6l-41.6-24-.3-.2-.6-.4-.4-.3-.5-.4-.5-.4-.3-.4-.5-.5-.3-.3-.4-.6-.3-.4-.3-.5-.3-.5-.2-.5-.3-.6c0-.2-.1-.3-.1-.5a1.4,1.4,0,0,1-.2-.6l-.2-.5c0-.2-.1-.4-.1-.6s-.1-.4-.1-.6V129a1.5,1.5,0,0,1-.1-.7V80a12,12,0,0,1,24,0Zm58.7-22.7a100.1,100.1,0,0,0-141.4,0L43.8,70.7v-11a12,12,0,1,0-24,0v40c0,.4.1.8.1,1.2a.9.9,0,0,0,.1.5,2,2,0,0,0,.1.7c0,.2.1.4.1.6s.1.3.2.5.1.4.1.6a.8.8,0,0,1,.2.5l.3.5.3.6.2.5.4.5.3.5c.1.1.2.3.4.4l.7.9h.1l.8.8.5.3.5.4.5.3.5.3.5.3.5.2.6.2.5.2h.6l.6.2H71.8a12,12,0,0,0,0-24h-11L74.3,74.3a76,76,0,1,1,0,107.4,12.2,12.2,0,0,0-17,0,12,12,0,0,0,0,17A100,100,0,0,0,198.7,57.3Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="128" cy="128" r="88" />
          </g>
          <path d="M128,72a8,8,0,0,0-8,8v48.7c0,.1.1.2.1.4v.4c.1.1.1.2.1.4a.4.4,0,0,1,.1.3l.2.4a.4.4,0,0,0,.1.3c0,.2.1.3.1.4l.2.3a.5.5,0,0,0,.2.4l.2.3.2.3.2.4.2.2c.1.1.2.3.4.4l.2.2.3.3.3.2.3.3.4.2h.2l41.6,24a7.1,7.1,0,0,0,4,1.1,8,8,0,0,0,4-14.9L136,123.4V80A8,8,0,0,0,128,72Z" />
          <path d="M195.9,60.1a96.2,96.2,0,0,0-135.8,0L39.8,80.4V59.7a8,8,0,1,0-16,0v40h0a2.2,2.2,0,0,0,.1.8v.4c0,.1.1.2.1.4s.1.2.1.4a.4.4,0,0,0,.1.3c0,.2.1.3.1.4a.6.6,0,0,1,.1.4l.2.3c.1.1.1.3.2.4l.2.3c0,.1.1.2.2.4l.2.3.2.3,1.2,1.1.2.2.4.3.3.2.4.2h.3l.4.2h.3l.4.2H71.8a8,8,0,0,0,0-16H51.1L71.4,71.4a80,80,0,1,1,0,113.2,7.9,7.9,0,0,0-11.3,0,8,8,0,0,0,0,11.3A96,96,0,0,0,195.9,60.1Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M136,80v43.4l37.6,21.7a8,8,0,0,1-4,14.9,7.1,7.1,0,0,1-4-1.1l-41.6-24h-.2l-.4-.2-.3-.3-.3-.2-.3-.3-.2-.2c-.2-.1-.3-.3-.4-.4l-.2-.2-.2-.4-.2-.3-.2-.3a.5.5,0,0,1-.2-.4l-.2-.3c0-.1-.1-.2-.1-.4a.4.4,0,0,1-.1-.3l-.2-.4a.4.4,0,0,0-.1-.3c0-.2,0-.3-.1-.4v-.4c0-.2-.1-.3-.1-.4V80a8,8,0,0,1,16,0Zm59.9-19.9a96.2,96.2,0,0,0-135.8,0l-8.3,8.3L37.5,54.1a8,8,0,0,0-8.7-1.8,8.2,8.2,0,0,0-5,7.4v40a8,8,0,0,0,8,8h40a8,8,0,0,0,5.7-13.6L63.1,79.7l8.3-8.3a80,80,0,1,1,0,113.2,7.9,7.9,0,0,0-11.3,0,8,8,0,0,0,0,11.3A96,96,0,0,0,195.9,60.1Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M134,80v44.5l38.6,22.3a6,6,0,0,1-6,10.4l-41.6-24h-.1l-.3-.2-.3-.2h-.2l-.2-.3h-.2l-.2-.3-.2-.2-.2-.2v-.2l-.2-.3c0-.1-.1-.2-.1-.3s-.1-.1-.1-.2l-.2-.3c0-.1,0-.2-.1-.2a.8.8,0,0,0-.1-.4v-.2a.4.4,0,0,1-.1-.3v-.3a.4.4,0,0,1-.1-.3V80a6,6,0,0,1,12,0Zm60.5-18.5a94.3,94.3,0,0,0-133,0L37.8,85.2V59.7a6,6,0,1,0-12,0v40c0,.4.1.8.1,1.1h0a4.2,4.2,0,0,0,.4,1h0a4.7,4.7,0,0,0,.5,1h0l.8,1h0l.9.7h.1l1,.5h0l1.1.3H71.8a6,6,0,0,0,0-12H46.3L70,70a82,82,0,1,1,0,116,5.9,5.9,0,0,0-8.5,0,6.1,6.1,0,0,0,0,8.5,94,94,0,0,0,133-133Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M132,80v45.7l39.6,22.8A4,4,0,0,1,173,154a3.9,3.9,0,0,1-3.4,2,4.6,4.6,0,0,1-2-.5l-41.6-24h-.1l-.4-.3-.2-.2-.3-.3-.2-.3-.3-.3c0-.1-.1-.2-.1-.3l-.2-.4c0-.1-.1-.3-.1-.4v-.3a.9.9,0,0,1-.1-.5h0V80a4,4,0,0,1,8,0Zm61.1-17.1a92.3,92.3,0,0,0-130.2,0L35.8,90.1V59.7a4,4,0,1,0-8,0v40c0,.3.1.5.1.8v.3a.8.8,0,0,1,.1.4l.2.4.2.3.5.6h0l.6.4.3.2.4.2H71.8a4,4,0,0,0,0-8H41.5L68.6,68.6a84,84,0,1,1,0,118.8,4,4,0,0,0-5.7,0,4.2,4.2,0,0,0,0,5.7A92.1,92.1,0,0,0,193.1,62.9Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M136,80v43.4l37.6,21.7a8,8,0,0,1-4,14.9,7.1,7.1,0,0,1-4-1.1l-41.6-24h-.2l-.4-.3-.3-.2-.3-.2-.3-.3-.2-.2c-.2-.1-.3-.3-.4-.4l-.2-.2-.2-.4-.2-.3-.2-.3a.5.5,0,0,1-.2-.4l-.2-.3c0-.1-.1-.2-.1-.4a.4.4,0,0,1-.1-.3l-.2-.4a.4.4,0,0,0-.1-.3c0-.2,0-.3-.1-.4v-.4c0-.2-.1-.3-.1-.4V80a8,8,0,0,1,16,0Zm59.9-19.9a96.2,96.2,0,0,0-135.8,0L39.8,80.4V59.7a8,8,0,1,0-16,0v40a2.2,2.2,0,0,0,.1.8v.4c0,.1.1.2.1.4s.1.2.1.4a.4.4,0,0,0,.1.3c0,.2.1.3.1.4a.6.6,0,0,1,.1.4l.2.3c.1.1.1.3.2.4l.2.3c0,.1.1.2.2.4l.2.3.2.3,1.2,1.1.2.2.4.3.3.2.4.2h.3l.4.2h.3l.4.2H71.8a8,8,0,0,0,0-16H51.1L71.4,71.4a80,80,0,1,1,0,113.2,7.9,7.9,0,0,0-11.3,0,8,8,0,0,0,0,11.3A96,96,0,0,0,195.9,60.1Z" />
        </>
      )}
    </svg>
  );
}

export function PhClock(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,20A108,108,0,1,0,236,128,108.1,108.1,0,0,0,128,20Zm0,192a84,84,0,1,1,84-84A84.1,84.1,0,0,1,128,212Zm68-84a12,12,0,0,1-12,12H128a12,12,0,0,1-12-12V72a12,12,0,0,1,24,0v44h44A12,12,0,0,1,196,128Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="128" cy="128" r="96" />
          </g>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Z" />
          <path d="M184,120H136V72a8,8,0,0,0-16,0v56a8,8,0,0,0,8,8h56a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm56,112H128a8,8,0,0,1-8-8V72a8,8,0,0,1,16,0v48h48a8,8,0,0,1,0,16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,230A102,102,0,1,1,230,128,102.2,102.2,0,0,1,128,230Zm0-192a90,90,0,1,0,90,90A90.1,90.1,0,0,0,128,38Zm62,90a6,6,0,0,0-6-6H134V72a6,6,0,0,0-12,0v56a6,6,0,0,0,6,6h56A6,6,0,0,0,190,128Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,28A100,100,0,1,0,228,128,100.2,100.2,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm60-92a4,4,0,0,1-4,4H128a4,4,0,0,1-4-4V72a4,4,0,0,1,8,0v52h52A4,4,0,0,1,188,128Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm64-88a8,8,0,0,1-8,8H128a8,8,0,0,1-8-8V72a8,8,0,0,1,16,0v48h48A8,8,0,0,1,192,128Z" />
        </>
      )}
    </svg>
  );
}

export function PhClosedCaptioning(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216,36H40A20.1,20.1,0,0,0,20,56V200a20.1,20.1,0,0,0,20,20H216a20.1,20.1,0,0,0,20-20V56A20.1,20.1,0,0,0,216,36Zm-4,160H44V60H212ZM60,128a40,40,0,0,1,58.6-35.4,12,12,0,1,1-11.2,21.2A15.9,15.9,0,0,0,100,112a16,16,0,0,0,0,32,15.9,15.9,0,0,0,7.4-1.8,12,12,0,0,1,11.2,21.2A40,40,0,0,1,60,128Zm72,0a40,40,0,0,1,58.6-35.4,12,12,0,1,1-11.2,21.2A15.9,15.9,0,0,0,172,112a16,16,0,0,0,0,32,15.9,15.9,0,0,0,7.4-1.8,12,12,0,0,1,11.2,21.2A40,40,0,0,1,132,128Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect x="32" y="48" width="192" height="160" rx="8" />
          </g>
          <path d="M216,40H40A16,16,0,0,0,24,56V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40Zm0,160H40V56H216V200Z" />
          <path d="M96,108a19.8,19.8,0,0,1,14.3,6,8,8,0,0,0,11.4-11.2A35.4,35.4,0,0,0,96,92a36,36,0,0,0,0,72,35.4,35.4,0,0,0,25.7-10.8A8,8,0,0,0,110.3,142,19.8,19.8,0,0,1,96,148a20,20,0,0,1,0-40Z" />
          <path d="M168,108a19.8,19.8,0,0,1,14.3,6,8,8,0,0,0,11.4-11.2A35.4,35.4,0,0,0,168,92a36,36,0,0,0,0,72,35.4,35.4,0,0,0,25.7-10.8A8,8,0,0,0,182.3,142a19.8,19.8,0,0,1-14.3,6,20,20,0,0,1,0-40Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,40H40A16,16,0,0,0,24,56V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40ZM96,148a19.8,19.8,0,0,0,14.3-6,8,8,0,0,1,11.4,11.2A35.4,35.4,0,0,1,96,164a36,36,0,0,1,0-72,35.4,35.4,0,0,1,25.7,10.8A8,8,0,0,1,110.3,114,19.8,19.8,0,0,0,96,108a20,20,0,0,0,0,40Zm72,0a19.8,19.8,0,0,0,14.3-6,8,8,0,0,1,11.4,11.2A35.4,35.4,0,0,1,168,164a36,36,0,0,1,0-72,35.4,35.4,0,0,1,25.7,10.8A8,8,0,0,1,182.3,114a19.8,19.8,0,0,0-14.3-6,20,20,0,0,0,0,40Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M216,42H40A14,14,0,0,0,26,56V200a14,14,0,0,0,14,14H216a14,14,0,0,0,14-14V56A14,14,0,0,0,216,42Zm2,158a2,2,0,0,1-2,2H40a2,2,0,0,1-2-2V56a2,2,0,0,1,2-2H216a2,2,0,0,1,2,2ZM74,128a22.1,22.1,0,0,0,22,22,21.6,21.6,0,0,0,15.7-6.6,6,6,0,0,1,8.6,8.4,34,34,0,1,1,0-47.6,6,6,0,1,1-8.6,8.4A21.6,21.6,0,0,0,96,106,22.1,22.1,0,0,0,74,128Zm72,0a22.1,22.1,0,0,0,22,22,21.6,21.6,0,0,0,15.7-6.6,6,6,0,0,1,8.6,8.4,34,34,0,1,1,0-47.6,6,6,0,1,1-8.6,8.4A21.6,21.6,0,0,0,168,106,22.1,22.1,0,0,0,146,128Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M216,44H40A12,12,0,0,0,28,56V200a12,12,0,0,0,12,12H216a12,12,0,0,0,12-12V56A12,12,0,0,0,216,44Zm4,156a4,4,0,0,1-4,4H40a4,4,0,0,1-4-4V56a4,4,0,0,1,4-4H216a4,4,0,0,1,4,4ZM72,128a24.1,24.1,0,0,0,24,24,23.8,23.8,0,0,0,17.1-7.2,4,4,0,0,1,5.8,5.6,32,32,0,1,1,0-44.8,4,4,0,0,1-5.8,5.6A23.8,23.8,0,0,0,96,104,24.1,24.1,0,0,0,72,128Zm72,0a24.1,24.1,0,0,0,24,24,23.8,23.8,0,0,0,17.1-7.2,4,4,0,1,1,5.8,5.6,32,32,0,1,1,0-44.8,4,4,0,0,1-5.8,5.6A23.8,23.8,0,0,0,168,104,24.1,24.1,0,0,0,144,128Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,40H40A16,16,0,0,0,24,56V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40Zm0,160H40V56H216V200ZM76,128a20.1,20.1,0,0,0,20,20,19.8,19.8,0,0,0,14.3-6,8,8,0,0,1,11.4,11.2A35.4,35.4,0,0,1,96,164a36,36,0,0,1,0-72,35.4,35.4,0,0,1,25.7,10.8A8,8,0,0,1,110.3,114,19.8,19.8,0,0,0,96,108,20.1,20.1,0,0,0,76,128Zm72,0a20.1,20.1,0,0,0,20,20,19.8,19.8,0,0,0,14.3-6,8,8,0,0,1,11.4,11.2A35.4,35.4,0,0,1,168,164a36,36,0,0,1,0-72,35.4,35.4,0,0,1,25.7,10.8A8,8,0,0,1,182.3,114a19.8,19.8,0,0,0-14.3-6A20.1,20.1,0,0,0,148,128Z" />
        </>
      )}
    </svg>
  );
}

export function PhCloudArrowDown(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M194.4,165.6a11.9,11.9,0,0,1,0,16.9l-33.9,34a12.1,12.1,0,0,1-17,0l-33.9-34a12,12,0,0,1,16.9-16.9L140,179V128a12,12,0,0,1,24,0v51l13.5-13.4A11.9,11.9,0,0,1,194.4,165.6ZM160,36A92.1,92.1,0,0,0,79,84.4a53.7,53.7,0,0,0-7-.4,68,68,0,0,0,0,136H96a12,12,0,0,0,0-24H72a44,44,0,0,1-1.8-88A94.3,94.3,0,0,0,68,128a12,12,0,0,0,24,0,68,68,0,0,1,136,0,67.2,67.2,0,0,1-13.6,40.8,12,12,0,0,0,2.4,16.8A12.2,12.2,0,0,0,224,188a11.9,11.9,0,0,0,9.6-4.8A92,92,0,0,0,160,36Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M240,128a80,80,0,0,1-80,80H72A56,56,0,0,1,72,96a57.5,57.5,0,0,1,13.9,1.7h0A80,80,0,0,1,240,128Z"
            opacity="0.2"
          />
          <path d="M191.6,168.4a8,8,0,0,1,0,11.3l-33.9,34a8.2,8.2,0,0,1-11.4,0l-33.9-34a8,8,0,0,1,11.3-11.3L144,188.7V128a8,8,0,0,1,16,0v60.7l20.3-20.3A8,8,0,0,1,191.6,168.4ZM160,40A88,88,0,0,0,81.3,88.7,58.2,58.2,0,0,0,72,88a64,64,0,0,0,0,128H96a8,8,0,0,0,0-16H72a48,48,0,0,1,0-96h3.3A85.7,85.7,0,0,0,72,128a8,8,0,0,0,16,0,72,72,0,1,1,129.6,43.2,8.1,8.1,0,0,0,1.6,11.2A7.7,7.7,0,0,0,224,184a8,8,0,0,0,6.4-3.2A88,88,0,0,0,160,40Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M160.1,40A88.1,88.1,0,0,0,81.3,88.7h0a86.6,86.6,0,0,0-9.3,39,8.2,8.2,0,0,1-7.4,8.3,8,8,0,0,1-8.6-8,105,105,0,0,1,5.3-32.9,4,4,0,0,0-4.7-5.2A64,64,0,0,0,8,152c0,35.2,29.8,64,64.9,64H160a88,88,0,0,0,.1-176Zm31.5,123.7-33.9,34A8.5,8.5,0,0,1,152,200a8.3,8.3,0,0,1-5.7-2.3l-33.9-34a8,8,0,0,1,11.3-11.3L144,172.7V112a8,8,0,0,1,16,0v60.7l20.3-20.3a8,8,0,0,1,11.3,11.3Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M190.2,169.8a6.1,6.1,0,0,1,0,8.5l-34,33.9a5.8,5.8,0,0,1-8.4,0l-34-33.9a6,6,0,0,1,8.5-8.5L146,193.5V128a6,6,0,0,1,12,0v65.5l23.7-23.7A6.1,6.1,0,0,1,190.2,169.8ZM160,42A86.2,86.2,0,0,0,82.4,90.9,60,60,0,0,0,72,90a62,62,0,0,0,0,124H96a6,6,0,0,0,0-12H72a50,50,0,0,1,0-100,38.1,38.1,0,0,1,5.9.4A86.7,86.7,0,0,0,74,128a6,6,0,0,0,12,0,74,74,0,0,1,148,0,73.2,73.2,0,0,1-14.8,44.4,6,6,0,1,0,9.6,7.2A85,85,0,0,0,246,128,86.1,86.1,0,0,0,160,42Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M188.8,171.2a4.2,4.2,0,0,1,0,5.7l-34,33.9a3.9,3.9,0,0,1-5.6,0l-34-33.9a4,4,0,0,1,5.7-5.7L148,198.3V128a4,4,0,0,1,8,0v70.3l27.1-27.1A4.2,4.2,0,0,1,188.8,171.2ZM160,44A84.2,84.2,0,0,0,83.6,93.1,64.2,64.2,0,0,0,72,92a60,60,0,0,0,0,120H96a4,4,0,0,0,0-8H72a52,52,0,0,1,0-104,62.3,62.3,0,0,1,8.6.7A82.8,82.8,0,0,0,76,128a4,4,0,0,0,8,0,76,76,0,1,1,136.8,45.6,4,4,0,0,0,.8,5.6,4.3,4.3,0,0,0,2.4.8,3.9,3.9,0,0,0,3.2-1.6A83.2,83.2,0,0,0,244,128,84.1,84.1,0,0,0,160,44Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M191.6,168.4a8,8,0,0,1,0,11.3l-33.9,34a8.2,8.2,0,0,1-11.4,0l-33.9-34a8,8,0,0,1,11.3-11.3L144,188.7V128a8,8,0,0,1,16,0v60.7l20.3-20.3A8,8,0,0,1,191.6,168.4ZM160,40A88,88,0,0,0,81.3,88.7,58.2,58.2,0,0,0,72,88a64,64,0,0,0,0,128H96a8,8,0,0,0,0-16H72a48,48,0,0,1,0-96h3.3A85.7,85.7,0,0,0,72,128a8,8,0,0,0,16,0,72,72,0,1,1,129.6,43.2,8.1,8.1,0,0,0,1.6,11.2A7.7,7.7,0,0,0,224,184a8,8,0,0,0,6.4-3.2A88,88,0,0,0,160,40Z" />
        </>
      )}
    </svg>
  );
}

export function PhCloudArrowUp(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M252,128a91.4,91.4,0,0,1-18.4,55.2A11.9,11.9,0,0,1,224,188a12.2,12.2,0,0,1-7.2-2.4,12,12,0,0,1-2.4-16.8A67.2,67.2,0,0,0,228,128a68,68,0,0,0-136,0,12,12,0,0,1-24,0,94.3,94.3,0,0,1,2.2-20A44,44,0,0,0,72,196H96a12,12,0,0,1,0,24H72A68,68,0,0,1,72,84a53.7,53.7,0,0,1,7,.4A92,92,0,0,1,252,128Zm-91.5-8.5a12,12,0,0,0-17,0l-33.9,34a12,12,0,0,0,16.9,16.9L140,157v51a12,12,0,0,0,24,0V157l13.5,13.4a12,12,0,0,0,16.9,0,11.9,11.9,0,0,0,0-16.9Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M240,128a80,80,0,0,1-80,80H72A56,56,0,0,1,72,96a57.5,57.5,0,0,1,13.9,1.7h0A80,80,0,0,1,240,128Z"
            opacity="0.2"
          />
          <path d="M248,128a87.3,87.3,0,0,1-17.6,52.8A8,8,0,0,1,224,184a7.7,7.7,0,0,1-4.8-1.6,8.1,8.1,0,0,1-1.6-11.2A72,72,0,1,0,88,128a8,8,0,0,1-16,0,85.7,85.7,0,0,1,3.3-23.9H72a48,48,0,0,0,0,96H96a8,8,0,0,1,0,16H72A64,64,0,0,1,72,88a58.2,58.2,0,0,1,9.3.7A88,88,0,0,1,248,128Zm-90.3-5.7a8.1,8.1,0,0,0-11.4,0l-33.9,34a8,8,0,0,0,11.3,11.3L144,147.3V208a8,8,0,0,0,16,0V147.3l20.3,20.3a7.6,7.6,0,0,0,5.6,2.3,7.8,7.8,0,0,0,5.7-2.3,8,8,0,0,0,0-11.3Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M160.1,40A88.1,88.1,0,0,0,81.3,88.7h0a86.6,86.6,0,0,0-9.3,39,8.2,8.2,0,0,1-7.4,8.3,8,8,0,0,1-8.6-8,105,105,0,0,1,5.3-32.9,4,4,0,0,0-4.7-5.2A64,64,0,0,0,8,152c0,35.2,29.8,64,64.9,64H160a88,88,0,0,0,.1-176Zm31.5,111.6a8.3,8.3,0,0,1-5.7,2.3,8,8,0,0,1-5.6-2.3L160,131.3V192a8,8,0,0,1-16,0V131.3l-20.3,20.3a8,8,0,0,1-11.3-11.3l33.9-34a8.1,8.1,0,0,1,11.4,0l33.9,34A8,8,0,0,1,191.6,151.6Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M246,128a85,85,0,0,1-17.2,51.6,6,6,0,1,1-9.6-7.2A73.2,73.2,0,0,0,234,128a74,74,0,0,0-148,0,6,6,0,0,1-12,0,86.7,86.7,0,0,1,3.9-25.6A38.1,38.1,0,0,0,72,102a50,50,0,0,0,0,100H96a6,6,0,0,1,0,12H72A62,62,0,0,1,72,90a60,60,0,0,1,10.4.9A86,86,0,0,1,246,128Zm-89.8-4.2a5.8,5.8,0,0,0-8.4,0l-34,33.9a6,6,0,0,0,8.5,8.5L146,142.5V208a6,6,0,0,0,12,0V142.5l23.7,23.7a5.8,5.8,0,0,0,4.2,1.7,5.9,5.9,0,0,0,4.3-1.7,6.1,6.1,0,0,0,0-8.5Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M244,128a83.2,83.2,0,0,1-16.8,50.4A3.9,3.9,0,0,1,224,180a4.3,4.3,0,0,1-2.4-.8,4,4,0,0,1-.8-5.6A76,76,0,1,0,84,128a4,4,0,0,1-8,0,82.8,82.8,0,0,1,4.6-27.3A62.3,62.3,0,0,0,72,100a52,52,0,0,0,0,104H96a4,4,0,0,1,0,8H72A60,60,0,0,1,72,92a64.2,64.2,0,0,1,11.6,1.1A84,84,0,0,1,244,128Zm-89.2-2.8a3.9,3.9,0,0,0-5.6,0l-34,33.9a4,4,0,0,0,5.7,5.7L148,137.7V208a4,4,0,0,0,8,0V137.7l27.1,27.1a3.8,3.8,0,0,0,2.8,1.1,3.9,3.9,0,0,0,2.9-1.1,4.2,4.2,0,0,0,0-5.7Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M248,128a87.3,87.3,0,0,1-17.6,52.8A8,8,0,0,1,224,184a7.7,7.7,0,0,1-4.8-1.6,8.1,8.1,0,0,1-1.6-11.2A72,72,0,1,0,88,128a8,8,0,0,1-16,0,85.7,85.7,0,0,1,3.3-23.9H72a48,48,0,0,0,0,96H96a8,8,0,0,1,0,16H72A64,64,0,0,1,72,88a58.2,58.2,0,0,1,9.3.7A88,88,0,0,1,248,128Zm-90.3-5.7a8.1,8.1,0,0,0-11.4,0l-33.9,34a8,8,0,0,0,11.3,11.3L144,147.3V208a8,8,0,0,0,16,0V147.3l20.3,20.3a7.6,7.6,0,0,0,5.6,2.3,7.8,7.8,0,0,0,5.7-2.3,8,8,0,0,0,0-11.3Z" />
        </>
      )}
    </svg>
  );
}

export function PhCloudCheck(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M160,36A92.2,92.2,0,0,0,79,84.4a53.7,53.7,0,0,0-7-.4,68,68,0,0,0,0,136h88a92,92,0,0,0,0-184Zm0,160H72a44,44,0,0,1-1.8-88A94.3,94.3,0,0,0,68,128a12,12,0,0,0,24,0,68,68,0,1,1,68,68Zm40.5-88.5a12,12,0,0,1,0,17l-48,48a12.1,12.1,0,0,1-17,0l-24-24a12,12,0,0,1,17-17L144,147l39.5-39.5A12,12,0,0,1,200.5,107.5Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M80,128a80,80,0,1,1,80,80H72A56,56,0,0,1,72,96a57.5,57.5,0,0,1,13.9,1.7" />
          </g>
          <path d="M160,40A88,88,0,0,0,81.3,88.7,58.2,58.2,0,0,0,72,88a64,64,0,0,0,0,128h88a88,88,0,0,0,0-176Zm0,160H72a48,48,0,0,1,0-96h3.3A85.7,85.7,0,0,0,72,128a8,8,0,0,0,16,0,72,72,0,1,1,72,72Z" />
          <path d="M186.3,110.3,144,152.7l-18.3-18.4a8.1,8.1,0,0,0-11.4,11.4l24,24a8.2,8.2,0,0,0,11.4,0l48-48a8.1,8.1,0,0,0-11.4-11.4Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M160.1,40A88.1,88.1,0,0,0,81.3,88.7h0a86.6,86.6,0,0,0-9.3,39,8.2,8.2,0,0,1-7.4,8.3,8,8,0,0,1-8.6-8,105,105,0,0,1,5.3-32.9,4,4,0,0,0-4.7-5.2A64,64,0,0,0,8,152c0,35.2,29.8,64,64.9,64H160a88,88,0,0,0,.1-176Zm37.6,81.7-48,48a8.2,8.2,0,0,1-11.4,0l-24-24a8.1,8.1,0,0,1,11.4-11.4L144,152.7l42.3-42.4a8.1,8.1,0,0,1,11.4,11.4Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M160,42A86.2,86.2,0,0,0,82.4,90.9,60,60,0,0,0,72,90a62,62,0,0,0,0,124h88a86,86,0,0,0,0-172Zm0,160H72a50,50,0,0,1,0-100,56.8,56.8,0,0,1,5.9.3A87.9,87.9,0,0,0,74,128a6,6,0,0,0,12,0,74,74,0,1,1,74,74Zm36.2-90.2a5.8,5.8,0,0,1,0,8.4l-48,48a5.8,5.8,0,0,1-8.4,0l-24-24a5.9,5.9,0,0,1,8.4-8.4L144,155.5l43.8-43.7A5.8,5.8,0,0,1,196.2,111.8Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M160,212H72A60,60,0,0,1,72,92a64.2,64.2,0,0,1,11.6,1.1A84,84,0,1,1,160,212ZM72,100a52,52,0,0,0,0,104h88a76,76,0,1,0-76-76,4,4,0,0,1-8,0,82.3,82.3,0,0,1,4.6-27.3A49.9,49.9,0,0,0,72,100Zm74.8,66.8,48-48a4,4,0,1,0-5.6-5.6L144,158.3l-21.2-21.1a4,4,0,0,0-5.6,5.6l24,24a3.9,3.9,0,0,0,5.6,0Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M160,40A88,88,0,0,0,81.3,88.7,58.2,58.2,0,0,0,72,88a64,64,0,0,0,0,128h88a88,88,0,0,0,0-176Zm0,160H72a48,48,0,0,1,0-96h3.3A85.7,85.7,0,0,0,72,128a8,8,0,0,0,16,0,72,72,0,1,1,72,72Zm37.7-89.7a8.1,8.1,0,0,1,0,11.4l-48,48a8.2,8.2,0,0,1-11.4,0l-24-24a8.1,8.1,0,0,1,11.4-11.4L144,152.7l42.3-42.4A8.1,8.1,0,0,1,197.7,110.3Z" />
        </>
      )}
    </svg>
  );
}

export function PhCloudFog(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M120,212H72a12,12,0,0,1,0-24h48a12,12,0,0,1,0,24Zm64-24H160a12,12,0,0,0,0,24h24a12,12,0,0,0,0-24Zm-24,40H104a12,12,0,0,0,0,24h56a12,12,0,0,0,0-24ZM236,92a80.1,80.1,0,0,1-80,80H76A56,56,0,0,1,76,60a46.3,46.3,0,0,1,6.5.4A80,80,0,0,1,236,92Zm-24,0a56,56,0,0,0-112,0,12,12,0,0,1-24,0,70.3,70.3,0,0,1,.4-8H76a32,32,0,0,0,0,64h80A56,56,0,0,0,212,92Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M88,92a68,68,0,1,1,68,68H76a44,44,0,0,1,0-88,42.5,42.5,0,0,1,14.3,2.4" />
          </g>
          <path d="M120,200H72a8,8,0,0,1,0-16h48a8,8,0,0,1,0,16Zm64-16H160a8,8,0,0,0,0,16h24a8,8,0,0,0,0-16Zm-24,32H104a8,8,0,0,0,0,16h56a8,8,0,0,0,0-16ZM232,92a76.1,76.1,0,0,1-76,76H76A52,52,0,0,1,76,64a53.8,53.8,0,0,1,9,.8A76,76,0,0,1,232,92Zm-16,0A60,60,0,0,0,96,92a8,8,0,0,1-16,0,74.9,74.9,0,0,1,.9-11.7A40.3,40.3,0,0,0,76,80a36,36,0,0,0,0,72h80A60,60,0,0,0,216,92Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M168,224a8,8,0,0,1-8,8H104a8,8,0,0,1,0-16h56A8,8,0,0,1,168,224Zm-40-32a8,8,0,0,0-8-8H72a8,8,0,0,0,0,16h48A8,8,0,0,0,128,192Zm56-8H160a8,8,0,0,0,0,16h24a8,8,0,0,0,0-16ZM159.3,16.1A76.1,76.1,0,0,0,85,64.8h0a76.4,76.4,0,0,0-5,26.9,8.3,8.3,0,0,1-7.4,8.3A8,8,0,0,1,64,92a88.2,88.2,0,0,1,2.5-21.1,4,4,0,0,0-5-4.8A52,52,0,0,0,24,116.3C24.2,145,48.1,168,76.8,168H156a76,76,0,0,0,3.3-151.9Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M120,198H72a6,6,0,0,1,0-12h48a6,6,0,0,1,0,12Zm64-12H160a6,6,0,0,0,0,12h24a6,6,0,0,0,0-12Zm-24,32H104a6,6,0,0,0,0,12h56a6,6,0,0,0,0-12ZM230,92a74.1,74.1,0,0,1-74,74H76A50,50,0,0,1,76,66a50.7,50.7,0,0,1,10.3,1.1A74,74,0,0,1,230,92Zm-12,0A62,62,0,0,0,94,92a6,6,0,0,1-12,0,73.1,73.1,0,0,1,1.2-13.3A35.1,35.1,0,0,0,76,78a38,38,0,0,0,0,76h80A62.1,62.1,0,0,0,218,92Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M120,196H72a4,4,0,0,1,0-8h48a4,4,0,0,1,0,8Zm64-8H160a4,4,0,0,0,0,8h24a4,4,0,0,0,0-8Zm-24,32H104a4,4,0,0,0,0,8h56a4,4,0,0,0,0-8ZM228,92a72.1,72.1,0,0,1-72,72H76a48,48,0,0,1,0-96,50.4,50.4,0,0,1,11.6,1.4A72,72,0,0,1,228,92Zm-8,0A64,64,0,0,0,92,92a4,4,0,0,1-8,0,73.1,73.1,0,0,1,1.5-14.9A43.3,43.3,0,0,0,76,76a40,40,0,0,0,0,80h80A64.1,64.1,0,0,0,220,92Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M120,200H72a8,8,0,0,1,0-16h48a8,8,0,0,1,0,16Zm64-16H160a8,8,0,0,0,0,16h24a8,8,0,0,0,0-16Zm-24,32H104a8,8,0,0,0,0,16h56a8,8,0,0,0,0-16ZM232,92a76.1,76.1,0,0,1-76,76H76A52,52,0,0,1,76,64a53.8,53.8,0,0,1,9,.8A76,76,0,0,1,232,92Zm-16,0A60,60,0,0,0,96,92a8,8,0,0,1-16,0,74.9,74.9,0,0,1,.9-11.7A40.3,40.3,0,0,0,76,80a36,36,0,0,0,0,72h80A60,60,0,0,0,216,92Z" />
        </>
      )}
    </svg>
  );
}

export function PhCloudLightning(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M156,172A80,80,0,1,0,82.5,60.4,46.3,46.3,0,0,0,76,60a56,56,0,0,0,0,112h32L94,193.4a11.8,11.8,0,0,0-.6,12.3A12,12,0,0,0,104,212h28l-14,21.4a11.9,11.9,0,0,0,3.4,16.6,11.4,11.4,0,0,0,6.6,2,11.9,11.9,0,0,0,10-5.4l26.3-40a12.1,12.1,0,0,0,.5-12.3,11.9,11.9,0,0,0-10.6-6.3h-28l10.5-16ZM44,116A32.1,32.1,0,0,1,76,84h.4a70.3,70.3,0,0,0-.4,8,12,12,0,0,0,24,0,56,56,0,1,1,56,56H76A32.1,32.1,0,0,1,44,116Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M88,92a68,68,0,1,1,68,68H76a44,44,0,0,1,0-88,42.5,42.5,0,0,1,14.3,2.4" />
          </g>
          <path d="M156,168A76,76,0,1,0,85,64.8a53.8,53.8,0,0,0-9-.8,52,52,0,0,0,0,104h39.4L97.3,195.6A8,8,0,0,0,104,208h35.4l-18.1,27.6a8,8,0,0,0,13.4,8.8l26.2-40a7.8,7.8,0,0,0,.4-8.2,8,8,0,0,0-7.1-4.2H118.8l15.8-24ZM40,116A36,36,0,0,1,76,80a40.3,40.3,0,0,1,4.9.3A74.9,74.9,0,0,0,80,92a8,8,0,0,0,16,0,60,60,0,1,1,60,60H76A36,36,0,0,1,40,116Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M134.6,168l-15.8,24H154a8,8,0,0,1,6.2,2.7,7.8,7.8,0,0,1,.7,9.7l-26.2,40A8,8,0,0,1,128,248a7.9,7.9,0,0,1-5-1.8,8.2,8.2,0,0,1-1.6-10.8l18-27.4H104.2a8.3,8.3,0,0,1-6.2-2.7,8.1,8.1,0,0,1-.7-9.7L115.4,168H76.8c-28.7,0-52.6-23-52.8-51.7A52,52,0,0,1,61.5,66.1a4,4,0,0,1,5,4.7,91.4,91.4,0,0,0-2.5,21,8.3,8.3,0,0,0,7.5,8.2A8,8,0,0,0,80,92a76.4,76.4,0,0,1,5-27.2h0A76,76,0,1,1,156,168Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M156,166A74,74,0,1,0,86.3,67.1,50.7,50.7,0,0,0,76,66a50,50,0,0,0,0,100h43.1L99,196.7a6.2,6.2,0,0,0-.3,6.2A6.1,6.1,0,0,0,104,206h39.1L123,236.7a6,6,0,0,0,1.7,8.3,5.8,5.8,0,0,0,3.3,1,6.1,6.1,0,0,0,5-2.7l26.3-40a6,6,0,0,0,.2-6.2,5.9,5.9,0,0,0-5.3-3.1H115.1l18.4-28ZM38,116A38,38,0,0,1,76,78a35.1,35.1,0,0,1,7.2.7A73.1,73.1,0,0,0,82,92a6,6,0,0,0,12,0,62,62,0,1,1,62,62H76A38,38,0,0,1,38,116Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M156,164A72,72,0,1,0,87.6,69.4,50.4,50.4,0,0,0,76,68a48,48,0,0,0,0,96h46.8l-22.1,33.8a3.8,3.8,0,0,0-.2,4.1A3.9,3.9,0,0,0,104,204h42.8l-22.1,33.8a3.9,3.9,0,0,0,1.1,5.5,3.6,3.6,0,0,0,2.2.7,3.8,3.8,0,0,0,3.3-1.8l26.3-40a4,4,0,0,0,.2-4.1,4.1,4.1,0,0,0-3.6-2.1H111.4l21-32ZM36,116A40,40,0,0,1,76,76a43.3,43.3,0,0,1,9.5,1.1A73.1,73.1,0,0,0,84,92a4,4,0,0,0,8,0,64,64,0,1,1,64,64H76A40,40,0,0,1,36,116Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M156,168A76,76,0,1,0,85,64.8a53.8,53.8,0,0,0-9-.8,52,52,0,0,0,0,104h39.4L97.3,195.6A8,8,0,0,0,104,208h35.4l-18.1,27.6a8,8,0,0,0,13.4,8.8l26.2-40a7.8,7.8,0,0,0,.4-8.2,8,8,0,0,0-7.1-4.2H118.8l15.8-24ZM40,116A36,36,0,0,1,76,80a40.3,40.3,0,0,1,4.9.3A74.9,74.9,0,0,0,80,92a8,8,0,0,0,16,0,60,60,0,1,1,60,60H76A36,36,0,0,1,40,116Z" />
        </>
      )}
    </svg>
  );
}

export function PhCloudMoon(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M156,68h-1A75.8,75.8,0,0,0,97.1,5.9,12.1,12.1,0,0,0,85.9,9.1a12.2,12.2,0,0,0-3.2,11.2A50.6,50.6,0,0,1,84,32,52,52,0,0,1,32,84a50.6,50.6,0,0,1-11.7-1.3A12.2,12.2,0,0,0,9.1,85.9,12.1,12.1,0,0,0,5.9,97.1a75.5,75.5,0,0,0,25.4,41.2A56,56,0,0,0,76,228h80a80,80,0,0,0,0-160ZM107.9,36a52,52,0,0,1,23.5,35.9,80.4,80.4,0,0,0-48.9,44.5A46.3,46.3,0,0,0,76,116a55.3,55.3,0,0,0-26,6.4,50.5,50.5,0,0,1-13.9-14.5A76.1,76.1,0,0,0,107.9,36ZM156,204H76a32,32,0,0,1,0-64h.4a70.3,70.3,0,0,0-.4,8,12,12,0,0,0,24,0,56,56,0,1,1,56,56Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M90.3,130.4a67.9,67.9,0,0,1,45.5-47.3h.1c.1-1,.1-2,.1-3A64,64,0,0,0,86.4,17.6h0A68.3,68.3,0,0,1,88,32,64.1,64.1,0,0,1,24,96,68.3,68.3,0,0,1,9.6,94.4h0A64.1,64.1,0,0,0,47,138.9h0A43.7,43.7,0,0,1,76,128a42.5,42.5,0,0,1,14.3,2.4Z" />
          </g>
          <path d="M156,72a85.2,85.2,0,0,0-12.4,1A71.8,71.8,0,0,0,88.2,9.8a8,8,0,0,0-9.6,9.6A58.6,58.6,0,0,1,80,32,56,56,0,0,1,24,88a58.6,58.6,0,0,1-12.6-1.4,8,8,0,0,0-9.6,9.6,71.9,71.9,0,0,0,32.3,45A52,52,0,0,0,76,224h80a76,76,0,0,0,0-152ZM21.4,104H24A72.1,72.1,0,0,0,96,32V29.3a55.6,55.6,0,0,1,31.9,48.1A76.3,76.3,0,0,0,85,120.8a53.8,53.8,0,0,0-9-.8,51.8,51.8,0,0,0-30,9.6A55.9,55.9,0,0,1,21.4,104ZM156,208H76a36,36,0,0,1,0-72,40.3,40.3,0,0,1,4.9.3A74.9,74.9,0,0,0,80,148a8,8,0,0,0,16,0,60,60,0,1,1,60,60Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M156,72a85.2,85.2,0,0,0-12.4,1A71.8,71.8,0,0,0,88.2,9.8a8,8,0,0,0-9.6,9.6A58.6,58.6,0,0,1,80,32,56,56,0,0,1,24,88a58.6,58.6,0,0,1-12.6-1.4,8,8,0,0,0-9.6,9.6,71.9,71.9,0,0,0,32.3,45A52,52,0,0,0,76,224h80a76,76,0,0,0,0-152ZM21.4,104H24A72.1,72.1,0,0,0,96,32V29.3a55.6,55.6,0,0,1,31.9,48.1A76.3,76.3,0,0,0,85,120.8a53.8,53.8,0,0,0-9-.8,51.8,51.8,0,0,0-30,9.6A55.9,55.9,0,0,1,21.4,104Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M156,74a74.3,74.3,0,0,0-14.2,1.4A69.9,69.9,0,0,0,87.7,11.8a5.9,5.9,0,0,0-5.6,1.6A6.3,6.3,0,0,0,80.5,19,56.4,56.4,0,0,1,82,32,58,58,0,0,1,24,90a56.4,56.4,0,0,1-13-1.5,6.2,6.2,0,0,0-5.6,1.6,5.9,5.9,0,0,0-1.6,5.6,70,70,0,0,0,33.3,45A49.9,49.9,0,0,0,76,222h80a74,74,0,0,0,0-148ZM18.2,101.8l5.8.2A70.1,70.1,0,0,0,94,32c0-1.9-.1-3.9-.2-5.8A57.7,57.7,0,0,1,130,78.7a74.6,74.6,0,0,0-43.7,44.4A50.7,50.7,0,0,0,76,122a50.1,50.1,0,0,0-29.9,9.9A58.1,58.1,0,0,1,18.2,101.8ZM156,210H76a38,38,0,0,1,0-76,35.1,35.1,0,0,1,7.2.7A73.1,73.1,0,0,0,82,148a6,6,0,0,0,12,0,62,62,0,1,1,62,62Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M156,76a70.1,70.1,0,0,0-16,1.8A68,68,0,0,0,87.3,13.7a4.1,4.1,0,0,0-3.8,1.1,4.2,4.2,0,0,0-1,3.7A60.7,60.7,0,0,1,84,32,60,60,0,0,1,24,92a60.7,60.7,0,0,1-13.5-1.5,4,4,0,0,0-3.7,1.1,3.8,3.8,0,0,0-1.1,3.7,68.1,68.1,0,0,0,34.5,44.8A47.9,47.9,0,0,0,76,220h80a72,72,0,0,0,0-144ZM15.2,99.4a65.1,65.1,0,0,0,8.8.6A68.1,68.1,0,0,0,92,32a65.1,65.1,0,0,0-.6-8.8A59.6,59.6,0,0,1,132,80h0a72.4,72.4,0,0,0-44.4,45.3A50.4,50.4,0,0,0,76,124a47.3,47.3,0,0,0-29.6,10.3A60.3,60.3,0,0,1,15.2,99.4ZM156,212H76a40,40,0,0,1,0-80,43.3,43.3,0,0,1,9.5,1.1A73.1,73.1,0,0,0,84,148a4,4,0,0,0,8,0,64,64,0,1,1,64,64Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M156,72a85.2,85.2,0,0,0-12.4,1A71.8,71.8,0,0,0,88.2,9.8a8,8,0,0,0-9.6,9.6A58.6,58.6,0,0,1,80,32,56,56,0,0,1,24,88a58.6,58.6,0,0,1-12.6-1.4,8,8,0,0,0-9.6,9.6,71.9,71.9,0,0,0,32.3,45A52,52,0,0,0,76,224h80a76,76,0,0,0,0-152ZM21.4,104H24A72.1,72.1,0,0,0,96,32V29.3a55.6,55.6,0,0,1,31.9,48.1A76.3,76.3,0,0,0,85,120.8a53.8,53.8,0,0,0-9-.8,51.8,51.8,0,0,0-30,9.6A55.9,55.9,0,0,1,21.4,104ZM156,208H76a36,36,0,0,1,0-72,40.3,40.3,0,0,1,4.9.3A74.9,74.9,0,0,0,80,148a8,8,0,0,0,16,0,60,60,0,1,1,60,60Z" />
        </>
      )}
    </svg>
  );
}

export function PhCloudRain(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M164.3,206.6l-26.3,40a11.9,11.9,0,0,1-10,5.4,11.4,11.4,0,0,1-6.6-2,11.9,11.9,0,0,1-3.4-16.6l26.2-40a12,12,0,0,1,20.1,13.2ZM236,92a80.1,80.1,0,0,1-80,80H136.7l-27.9,42.6A12.1,12.1,0,0,1,98.7,220a11.3,11.3,0,0,1-6.5-2,12,12,0,0,1-3.5-16.6L108,172H76A56,56,0,0,1,76,60a46.3,46.3,0,0,1,6.5.4A80,80,0,0,1,236,92Zm-24,0a56,56,0,0,0-112,0,12,12,0,0,1-24,0,70.3,70.3,0,0,1,.4-8H76a32,32,0,0,0,0,64h80A56,56,0,0,0,212,92Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M88,92a68,68,0,1,1,68,68H76a44,44,0,0,1,0-88,42.5,42.5,0,0,1,14.3,2.4" />
          </g>
          <path d="M160.9,204.4l-26.2,40a8,8,0,0,1-13.4-8.8l26.3-40a8,8,0,1,1,13.3,8.8ZM232,92a76.1,76.1,0,0,1-76,76H134.6l-29.2,44.4a8,8,0,0,1-6.7,3.6,7.5,7.5,0,0,1-4.3-1.3,7.9,7.9,0,0,1-2.3-11.1L115.4,168H76A52,52,0,0,1,76,64a53.8,53.8,0,0,1,9,.8A76,76,0,0,1,232,92Zm-16,0A60,60,0,0,0,96,92a8,8,0,0,1-16,0,74.9,74.9,0,0,1,.9-11.7A40.3,40.3,0,0,0,76,80a36,36,0,0,0,0,72h80A60,60,0,0,0,216,92Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M160.9,204.4l-26.2,40a8,8,0,0,1-13.4-8.8l26.3-40a8,8,0,1,1,13.3,8.8ZM159.3,16.1A76.1,76.1,0,0,0,85,64.8h0a74.8,74.8,0,0,0-5,26.9,8.3,8.3,0,0,1-7.4,8.3A8,8,0,0,1,64,92a91.6,91.6,0,0,1,2.4-21.1,3.9,3.9,0,0,0-4.9-4.8A52,52,0,0,0,24,116.3C24.2,145,48.1,168,76.8,168h38.6L92.2,203.4A8,8,0,0,0,98.7,216a8,8,0,0,0,6.7-3.6L134.6,168H156a76,76,0,0,0,3.3-151.9Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M159.3,203.3l-26.3,40a6.1,6.1,0,0,1-5,2.7,5.8,5.8,0,0,1-3.3-1,6,6,0,0,1-1.7-8.3l26.2-40a6,6,0,0,1,10.1,6.6ZM230,92a74.1,74.1,0,0,1-74,74H133.5l-29.7,45.3a6.3,6.3,0,0,1-5.1,2.7,5.5,5.5,0,0,1-3.2-1,6,6,0,0,1-1.8-8.3L119.1,166H76A50,50,0,0,1,76,66a50.7,50.7,0,0,1,10.3,1.1A74,74,0,0,1,230,92Zm-12,0A62,62,0,0,0,94,92a6,6,0,0,1-12,0,73.1,73.1,0,0,1,1.2-13.3A35.1,35.1,0,0,0,76,78a38,38,0,0,0,0,76h80A62.1,62.1,0,0,0,218,92Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M157.6,202.2l-26.3,40A3.8,3.8,0,0,1,128,244a3.6,3.6,0,0,1-2.2-.7,3.9,3.9,0,0,1-1.1-5.5l26.2-40a4,4,0,0,1,6.7,4.4ZM228,92a72.1,72.1,0,0,1-72,72H132.4l-30.3,46.2a4.1,4.1,0,0,1-3.4,1.8,3.3,3.3,0,0,1-2.1-.7,3.9,3.9,0,0,1-1.2-5.5L122.8,164H76a48,48,0,0,1,0-96,50.4,50.4,0,0,1,11.6,1.4A72,72,0,0,1,228,92Zm-8,0A64,64,0,0,0,92,92a4,4,0,0,1-8,0,73.1,73.1,0,0,1,1.5-14.9A43.3,43.3,0,0,0,76,76a40,40,0,0,0,0,80h80A64.1,64.1,0,0,0,220,92Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M160.9,204.4l-26.2,40a8,8,0,0,1-13.4-8.8l26.3-40a8,8,0,1,1,13.3,8.8ZM232,92a76.1,76.1,0,0,1-76,76H134.6l-29.2,44.4a8,8,0,0,1-6.7,3.6,7.5,7.5,0,0,1-4.3-1.3,7.9,7.9,0,0,1-2.3-11.1L115.4,168H76A52,52,0,0,1,76,64a53.8,53.8,0,0,1,9,.8A76,76,0,0,1,232,92Zm-16,0A60,60,0,0,0,96,92a8,8,0,0,1-16,0,74.9,74.9,0,0,1,.9-11.7A40.3,40.3,0,0,0,76,80a36,36,0,0,0,0,72h80A60,60,0,0,0,216,92Z" />
        </>
      )}
    </svg>
  );
}

export function PhCloudSlash(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M202.8,192.4h-.1L100,79.4c0-.1,0-.1-.1-.1l-43-47.4A12,12,0,0,0,39.1,48.1L71.8,84A68,68,0,0,0,72,220h88a93.4,93.4,0,0,0,30.7-5.2l8.4,9.3a12.1,12.1,0,0,0,17,.8,12.1,12.1,0,0,0,.8-17ZM160,196H72a44,44,0,0,1-1.8-88A96.5,96.5,0,0,0,68,128a12,12,0,0,0,24,0,66.3,66.3,0,0,1,2.7-18.8l77.8,85.6A64.6,64.6,0,0,1,160,196Zm92-68a91.6,91.6,0,0,1-17.5,54,11.9,11.9,0,0,1-9.7,5,12.3,12.3,0,0,1-7.1-2.3,12,12,0,0,1-2.6-16.8,68,68,0,0,0-89.6-98.5,11.9,11.9,0,0,1-16.4-4.2,12.1,12.1,0,0,1,4.2-16.5A92,92,0,0,1,252,128Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M80,127.6a80,80,0,1,1,80,80H72a56,56,0,0,1,0-112,53,53,0,0,1,13.9,1.8" />
          </g>
          <path d="M199.8,195.1h0L97,82h0L53.9,34.6A8,8,0,0,0,42.1,45.4L81.3,88.5v.2A58.2,58.2,0,0,0,72,88a64,64,0,0,0,0,128h88a87.9,87.9,0,0,0,31.8-5.9l10.3,11.3A8,8,0,0,0,208,224a8.2,8.2,0,0,0,5.4-2.1,7.9,7.9,0,0,0,.5-11.3ZM160,200H72a48,48,0,0,1,0-96h3.3A87.8,87.8,0,0,0,72,128a8,8,0,0,0,16,0,70.6,70.6,0,0,1,5.1-26.5l87,95.7A75,75,0,0,1,160,200Zm88-72a87.3,87.3,0,0,1-22.4,58.6,7.6,7.6,0,0,1-5.9,2.7,7.7,7.7,0,0,1-5.3-2.1,8,8,0,0,1-.7-11.3A72,72,0,0,0,160,56a70.9,70.9,0,0,0-42.6,14,8.1,8.1,0,0,1-11.2-1.7,8,8,0,0,1,1.7-11.2A87.2,87.2,0,0,1,160,40,88.1,88.1,0,0,1,248,128Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M213.9,210.6a7.9,7.9,0,0,1-.5,11.3A8.2,8.2,0,0,1,208,224a8,8,0,0,1-5.9-2.6l-10.3-11.3A87.9,87.9,0,0,1,160,216H72A64,64,0,0,1,72,88a58.2,58.2,0,0,1,9.3.7v-.2L42.1,45.4A8,8,0,0,1,53.9,34.6ZM160,40a87.2,87.2,0,0,0-52.1,17.1,7.9,7.9,0,0,0-3.2,5.6,7.8,7.8,0,0,0,2,6.2L213.8,186.7a8.1,8.1,0,0,0,5.9,2.6h0a7.8,7.8,0,0,0,5.9-2.7A88,88,0,0,0,160,40Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M198.3,196.4h0L95.5,83.4h0L52.4,36a5.9,5.9,0,0,0-8.8,8L83.7,88.2l-1.3,2.7A60,60,0,0,0,72,90a62,62,0,0,0,0,124h88a85.4,85.4,0,0,0,32.4-6.3L203.6,220a5.9,5.9,0,0,0,4.4,2,5.7,5.7,0,0,0,4-1.6,5.8,5.8,0,0,0,.4-8.4ZM160,202H72a50,50,0,0,1,0-100,38.1,38.1,0,0,1,5.9.4A85,85,0,0,0,74,128a6,6,0,0,0,12,0,74.1,74.1,0,0,1,6.4-30.2l91.3,100.3A72.7,72.7,0,0,1,160,202Zm86-74a86.3,86.3,0,0,1-21.8,57.3,6.2,6.2,0,0,1-4.5,2,6,6,0,0,1-4.5-10,74,74,0,0,0-99-108.9,6.1,6.1,0,0,1-8.4-1.3,6,6,0,0,1,1.3-8.4A85.3,85.3,0,0,1,160,42,86.1,86.1,0,0,1,246,128Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M51,37.3a4,4,0,1,0-6,5.4L86.2,87.9c-1,1.7-1.8,3.5-2.6,5.2A64.2,64.2,0,0,0,72,92a60,60,0,0,0,0,120h88a84.1,84.1,0,0,0,32.9-6.7L205,218.7a4.1,4.1,0,0,0,5.7.3,4.2,4.2,0,0,0,.3-5.7ZM160,204H72a52,52,0,0,1,0-104,59.7,59.7,0,0,1,8.5.7A87.3,87.3,0,0,0,76,128a4,4,0,0,0,8,0,75.9,75.9,0,0,1,7.9-33.8L187.1,199A75.3,75.3,0,0,1,160,204Zm84-76a84,84,0,0,1-21.3,55.9,4.1,4.1,0,0,1-3,1.4,4.5,4.5,0,0,1-2.7-1,4.1,4.1,0,0,1-.3-5.7A76,76,0,0,0,115,66.7a3.9,3.9,0,0,1-5.6-.8,4,4,0,0,1,.9-5.6A83.1,83.1,0,0,1,160,44,84.1,84.1,0,0,1,244,128Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M199.8,195.1h0L97,82h0L53.9,34.6A8,8,0,0,0,42.1,45.4L81.3,88.5v.2A58.2,58.2,0,0,0,72,88a64,64,0,0,0,0,128h88a87.9,87.9,0,0,0,31.8-5.9l10.3,11.3A8,8,0,0,0,208,224a8.2,8.2,0,0,0,5.4-2.1,7.9,7.9,0,0,0,.5-11.3ZM160,200H72a48,48,0,0,1,0-96h3.3A87.8,87.8,0,0,0,72,128a8,8,0,0,0,16,0,70.6,70.6,0,0,1,5.1-26.5l87,95.7A75,75,0,0,1,160,200Zm88-72a87.3,87.3,0,0,1-22.4,58.6,7.6,7.6,0,0,1-5.9,2.7,7.7,7.7,0,0,1-5.3-2.1,8,8,0,0,1-.7-11.3A72,72,0,0,0,160,56a70.9,70.9,0,0,0-42.6,14,8.1,8.1,0,0,1-11.2-1.7,8,8,0,0,1,1.7-11.2A87.2,87.2,0,0,1,160,40,88.1,88.1,0,0,1,248,128Z" />
        </>
      )}
    </svg>
  );
}

export function PhCloudSnow(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M156,16A80.1,80.1,0,0,0,82.5,64.4,46.3,46.3,0,0,0,76,64a56,56,0,0,0,0,112h80a80,80,0,0,0,0-160Zm0,136H76a32,32,0,0,1,0-64h.4a70.3,70.3,0,0,0-.4,8,12,12,0,0,0,24,0,56,56,0,1,1,56,56Zm20,64a16,16,0,1,1-16-16A16,16,0,0,1,176,216Zm-96,0a16,16,0,1,1-16-16A16,16,0,0,1,80,216Zm48,0a16,16,0,1,1-16-16A16,16,0,0,1,128,216Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M88,92a68,68,0,1,1,68,68H76a44,44,0,0,1,0-88,42.5,42.5,0,0,1,14.3,2.4" />
          </g>
          <path d="M156,16A76.2,76.2,0,0,0,85,64.8a53.8,53.8,0,0,0-9-.8,52,52,0,0,0,0,104h80a76,76,0,0,0,0-152Zm0,136H76a36,36,0,0,1,0-72,40.3,40.3,0,0,1,4.9.3A74.9,74.9,0,0,0,80,92a8,8,0,0,0,16,0,60,60,0,1,1,60,60Zm20,44a12,12,0,1,1-12-12A12,12,0,0,1,176,196Zm-88,0a12,12,0,1,1-12-12A12,12,0,0,1,88,196Zm40,16a12,12,0,1,1-12-12A12,12,0,0,1,128,212ZM80,236a12,12,0,1,1-12-12A12,12,0,0,1,80,236Zm88,0a12,12,0,1,1-12-12A12,12,0,0,1,168,236Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M176,196a12,12,0,1,1-12-12A12,12,0,0,1,176,196ZM76,184a12,12,0,1,0,12,12A12,12,0,0,0,76,184Zm40,16a12,12,0,1,0,12,12A12,12,0,0,0,116,200ZM68,224a12,12,0,1,0,12,12A12,12,0,0,0,68,224Zm88,0a12,12,0,1,0,12,12A12,12,0,0,0,156,224Zm3.3-207.9A76.1,76.1,0,0,0,85,64.8h0a74.8,74.8,0,0,0-5,26.9,8.3,8.3,0,0,1-7.4,8.3A8,8,0,0,1,64,92a91.6,91.6,0,0,1,2.4-21.1,3.9,3.9,0,0,0-4.9-4.8A52,52,0,0,0,24,116.3C24.2,145,48.1,168,76.8,168H156a76,76,0,0,0,3.3-151.9Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M156,18A74.2,74.2,0,0,0,86.3,67.1,50.7,50.7,0,0,0,76,66a50,50,0,0,0,0,100h80a74,74,0,0,0,0-148Zm0,136H76a38,38,0,0,1,0-76,35.1,35.1,0,0,1,7.2.7A73.1,73.1,0,0,0,82,92a6,6,0,0,0,12,0,62,62,0,1,1,62,62Zm18,42a10,10,0,1,1-10-10A10,10,0,0,1,174,196Zm-88,0a10,10,0,1,1-10-10A10,10,0,0,1,86,196Zm40,16a10,10,0,1,1-10-10A10,10,0,0,1,126,212ZM78,236a10,10,0,1,1-10-10A10,10,0,0,1,78,236Zm88,0a10,10,0,1,1-10-10A10,10,0,0,1,166,236Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M156,20A72.1,72.1,0,0,0,87.6,69.4,50.4,50.4,0,0,0,76,68a48,48,0,0,0,0,96h80a72,72,0,0,0,0-144Zm0,136H76a40,40,0,0,1,0-80,43.3,43.3,0,0,1,9.5,1.1A73.1,73.1,0,0,0,84,92a4,4,0,0,0,8,0,64,64,0,1,1,64,64Zm16,40a8,8,0,1,1-8-8A8,8,0,0,1,172,196Zm-88,0a8,8,0,1,1-8-8A8,8,0,0,1,84,196Zm40,16a8,8,0,1,1-8-8A8,8,0,0,1,124,212ZM76,236a8,8,0,1,1-8-8A8,8,0,0,1,76,236Zm88,0a8,8,0,1,1-8-8A8,8,0,0,1,164,236Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M156,16A76.2,76.2,0,0,0,85,64.8a53.8,53.8,0,0,0-9-.8,52,52,0,0,0,0,104h80a76,76,0,0,0,0-152Zm0,136H76a36,36,0,0,1,0-72,40.3,40.3,0,0,1,4.9.3A74.9,74.9,0,0,0,80,92a8,8,0,0,0,16,0,60,60,0,1,1,60,60Zm20,44a12,12,0,1,1-12-12A12,12,0,0,1,176,196Zm-88,0a12,12,0,1,1-12-12A12,12,0,0,1,88,196Zm40,16a12,12,0,1,1-12-12A12,12,0,0,1,128,212ZM80,236a12,12,0,1,1-12-12A12,12,0,0,1,80,236Zm88,0a12,12,0,1,1-12-12A12,12,0,0,1,168,236Z" />
        </>
      )}
    </svg>
  );
}

export function PhCloudSun(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M156,68a79.9,79.9,0,0,0-18.4,2.2,75.8,75.8,0,0,0-6-7.5l7.5-10.8a12,12,0,1,0-19.6-13.8l-7.6,10.8A60.3,60.3,0,0,0,89.6,44L87.3,31a12,12,0,1,0-23.6,4.2l2.3,13A59.1,59.1,0,0,0,46.7,60.4L35.9,52.9A12,12,0,1,0,22.1,72.5l10.8,7.6A60.8,60.8,0,0,0,28,102.4l-13,2.3a12,12,0,0,0,2.1,23.8l2.1-.2,13-2.3a68.9,68.9,0,0,0,3.4,7.3A55.9,55.9,0,0,0,76,228h80a80,80,0,0,0,0-160Zm-74.5.6h.4a36.1,36.1,0,0,1,26.2,5.6l.5.4.7.4a38.7,38.7,0,0,1,5.2,4.7,80.7,80.7,0,0,0-32,36.8A46.3,46.3,0,0,0,76,116a56.8,56.8,0,0,0-20.3,3.8,33.5,33.5,0,0,1-3.1-9.4c0-.1,0-.1-.1-.2V110a35.7,35.7,0,0,1,5.9-26.5c0-.1.1-.1.1-.2h.1A35.6,35.6,0,0,1,81.5,68.6ZM156,204H76a32,32,0,0,1,0-64h.4a70.3,70.3,0,0,0-.4,8,12,12,0,0,0,24,0,56,56,0,1,1,56,56Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M131.8,84.4a48,48,0,0,0-91.1,27.9,49.1,49.1,0,0,0,10.9,23.1h0A43.6,43.6,0,0,1,76,128a42.5,42.5,0,0,1,14.3,2.4h0a68.3,68.3,0,0,1,41.5-46Z" />
          </g>
          <path d="M156,72a78.8,78.8,0,0,0-20.2,2.7,58.9,58.9,0,0,0-9.4-11.5l9.4-13.6a7.9,7.9,0,0,0-1.9-11.1,8,8,0,0,0-11.2,1.9L113.2,54a55,55,0,0,0-26.9-6L83.4,31.7a8,8,0,1,0-15.8,2.8l2.9,16.3A55.7,55.7,0,0,0,47.2,65.6L33.6,56.1a8,8,0,0,0-11.1,2,7.9,7.9,0,0,0,1.9,11.1L38,78.7a55.1,55.1,0,0,0-6,27l-16.3,2.9a8,8,0,0,0,1.4,15.9h1.4l16.3-2.9a52.3,52.3,0,0,0,5.9,12.4A51.9,51.9,0,0,0,76,224h80a76,76,0,0,0,0-152ZM48.6,111.1v-.4a39.6,39.6,0,0,1,6.5-29.5V81h.1A40.1,40.1,0,0,1,80.8,64.6h.5a39.9,39.9,0,0,1,29.1,6.2l.5.4.4.3a37.4,37.4,0,0,1,9.3,9.3,76.6,76.6,0,0,0-35.6,40,53.8,53.8,0,0,0-9-.8,52.6,52.6,0,0,0-22.1,4.9A40,40,0,0,1,48.6,111.1ZM156,208H76a36,36,0,0,1,0-72,40.3,40.3,0,0,1,4.9.3A74.9,74.9,0,0,0,80,148a8,8,0,0,0,16,0,60,60,0,1,1,60,60Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M156,72a78.8,78.8,0,0,0-20.2,2.7,58.9,58.9,0,0,0-9.4-11.5l9.4-13.6a7.9,7.9,0,0,0-1.9-11.1,8,8,0,0,0-11.2,1.9L113.2,54a55,55,0,0,0-26.9-6L83.4,31.7a8,8,0,1,0-15.8,2.8l2.9,16.3A55.7,55.7,0,0,0,47.2,65.6L33.6,56.1a8,8,0,0,0-11.1,2,7.9,7.9,0,0,0,1.9,11.1L38,78.7a55.1,55.1,0,0,0-6,27l-16.3,2.9a8,8,0,0,0,1.4,15.9h1.4l16.3-2.9a52.3,52.3,0,0,0,5.9,12.4A51.9,51.9,0,0,0,76,224h80a76,76,0,0,0,0-152ZM48.6,111.1v-.4a39.6,39.6,0,0,1,6.5-29.5V81h.1A40.1,40.1,0,0,1,80.8,64.6h.5a39.5,39.5,0,0,1,29.1,6.3l.5.3.4.3a37.4,37.4,0,0,1,9.3,9.3,76.6,76.6,0,0,0-35.6,40,53.8,53.8,0,0,0-9-.8,52.6,52.6,0,0,0-22.1,4.9A40,40,0,0,1,48.6,111.1Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M156,74a73.9,73.9,0,0,0-21.2,3.1,52.5,52.5,0,0,0-11.1-13.6l10.5-15a6.1,6.1,0,0,0-1.5-8.4,6,6,0,0,0-8.3,1.5l-10.5,15a53.6,53.6,0,0,0-29.3-6.5l-3.2-18a6,6,0,0,0-11.8,2l3.2,18.1A52.3,52.3,0,0,0,47.5,68.3l-15-10.5a6,6,0,1,0-6.9,9.8l15,10.5a53.5,53.5,0,0,0-6.5,29.3l-18,3.2a5.9,5.9,0,0,0-4.9,6.9,6,6,0,0,0,5.9,5h1l18.1-3.2a53.5,53.5,0,0,0,7.1,15.1A49.5,49.5,0,0,0,26,172a50,50,0,0,0,50,50h80a74,74,0,0,0,0-148ZM46.7,111.4h-.1v-.2A41.9,41.9,0,0,1,53.5,80h.1c0-.1.1-.1.1-.2a41.5,41.5,0,0,1,26.9-17,.1.1,0,0,1,.1-.1h.2a42.1,42.1,0,0,1,30.7,6.6l.5.4.4.3a42,42,0,0,1,11,11.6,74.6,74.6,0,0,0-37.2,41.6A50.7,50.7,0,0,0,76,122a49.8,49.8,0,0,0-22.8,5.5A40.6,40.6,0,0,1,46.7,111.4ZM156,210H76a38,38,0,0,1,0-76,35.1,35.1,0,0,1,7.2.7A73.1,73.1,0,0,0,82,148a6,6,0,0,0,12,0,62,62,0,1,1,62,62Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M156,76a70.1,70.1,0,0,0-22.1,3.5A50.8,50.8,0,0,0,121,63.8l11.6-16.5a4,4,0,0,0-6.6-4.6L114.5,59.2a52.1,52.1,0,0,0-31.6-7L79.4,32.4a4,4,0,0,0-7.8,1.4l3.5,19.8A52.1,52.1,0,0,0,47.8,71L31.3,59.4a4,4,0,0,0-5.6,1,4.1,4.1,0,0,0,1,5.6L43.2,77.5a52.7,52.7,0,0,0-7,31.5l-19.8,3.5a4,4,0,0,0,.7,8h.7l19.8-3.5A52.5,52.5,0,0,0,46,134.6,48,48,0,0,0,76,220h80a72,72,0,0,0,0-144ZM44.7,111.7h-.1a43.7,43.7,0,0,1,7.3-32.7H52v-.2A43.4,43.4,0,0,1,80.2,60.7h.2a.1.1,0,0,1,.1-.1,44.1,44.1,0,0,1,32.4,7.1l.3.3.4.2a43.4,43.4,0,0,1,12.7,14.2,73.1,73.1,0,0,0-38.7,43A50.4,50.4,0,0,0,76,124a47.4,47.4,0,0,0-23.4,6.1A43.9,43.9,0,0,1,44.7,111.7ZM156,212H76a40,40,0,0,1,0-80,43.3,43.3,0,0,1,9.5,1.1A73.1,73.1,0,0,0,84,148a4,4,0,0,0,8,0,64,64,0,1,1,64,64Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M156,72a78.8,78.8,0,0,0-20.2,2.7,58.9,58.9,0,0,0-9.4-11.5l9.4-13.6a7.9,7.9,0,0,0-1.9-11.1,8,8,0,0,0-11.2,1.9L113.2,54a55,55,0,0,0-26.9-6L83.4,31.7a8,8,0,1,0-15.8,2.8l2.9,16.3A55.7,55.7,0,0,0,47.2,65.6L33.6,56.1a8,8,0,0,0-11.1,2,7.9,7.9,0,0,0,1.9,11.1L38,78.7a55.1,55.1,0,0,0-6,27l-16.3,2.9a8,8,0,0,0,1.4,15.9h1.4l16.3-2.9a52.3,52.3,0,0,0,5.9,12.4A51.9,51.9,0,0,0,76,224h80a76,76,0,0,0,0-152ZM48.6,111.1v-.4a39.6,39.6,0,0,1,6.5-29.5V81h.1A40.1,40.1,0,0,1,80.8,64.6h.5a39.5,39.5,0,0,1,29.1,6.3l.5.3.4.3a37.4,37.4,0,0,1,9.3,9.3,76.6,76.6,0,0,0-35.6,40,53.8,53.8,0,0,0-9-.8,52.6,52.6,0,0,0-22.1,4.9A40,40,0,0,1,48.6,111.1ZM156,208H76a36,36,0,0,1,0-72,40.3,40.3,0,0,1,4.9.3A74.9,74.9,0,0,0,80,148a8,8,0,0,0,16,0,60,60,0,1,1,60,60Z" />
        </>
      )}
    </svg>
  );
}

export function PhCloud(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M160,220H72A68,68,0,0,1,72,84a53.7,53.7,0,0,1,7,.4A92,92,0,1,1,160,220ZM70.2,108A44,44,0,0,0,72,196h88a68,68,0,1,0-68-68,12,12,0,0,1-24,0A94.3,94.3,0,0,1,70.2,108Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M80,128a80,80,0,1,1,80,80H72A56,56,0,0,1,72,96a57.5,57.5,0,0,1,13.9,1.7" />
          </g>
          <path d="M160,216H72A64,64,0,0,1,72,88a58.2,58.2,0,0,1,9.3.7A88,88,0,1,1,160,216ZM72,104a48,48,0,0,0,0,96h88a72,72,0,1,0-72-72,8,8,0,0,1-16,0,89.6,89.6,0,0,1,3.3-23.9Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M248,128.9c-.5,48.4-40.7,87.1-89.1,87.1H73c-35.2,0-65-28.8-65-64A64,64,0,0,1,56.6,89.9a4,4,0,0,1,4.7,5.2A103.1,103.1,0,0,0,56,127.7a8.2,8.2,0,0,0,7.5,8.3,8,8,0,0,0,8.5-8,86.5,86.5,0,0,1,9.3-39.3h0A88,88,0,0,1,248,128.9Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M160,214H72A62,62,0,0,1,72,90a60,60,0,0,1,10.4.9A86,86,0,1,1,160,214ZM72,102a50,50,0,0,0,0,100h88a74,74,0,1,0-74-74,6,6,0,0,1-12,0,87.9,87.9,0,0,1,3.9-25.7A56.8,56.8,0,0,0,72,102Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M160,212H72A60,60,0,0,1,72,92a64.2,64.2,0,0,1,11.6,1.1A84,84,0,1,1,160,212ZM72,100a52,52,0,0,0,0,104h88a76,76,0,1,0-76-76,4,4,0,0,1-8,0,82.3,82.3,0,0,1,4.6-27.3A49.9,49.9,0,0,0,72,100Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M160,216H72A64,64,0,0,1,72,88a58.2,58.2,0,0,1,9.3.7A88,88,0,1,1,160,216ZM72,104a48,48,0,0,0,0,96h88a72,72,0,1,0-72-72,8,8,0,0,1-16,0,85.7,85.7,0,0,1,3.3-23.9Z" />
        </>
      )}
    </svg>
  );
}

export function PhClub(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M186.7,88.4A57.4,57.4,0,0,0,188,76,60,60,0,0,0,68,76a57.4,57.4,0,0,0,1.3,12.4A60,60,0,0,0,76,208a57,57,0,0,0,12.5-1.3l-4.3,23.1A12,12,0,0,0,96,244h64a12,12,0,0,0,11.8-14.2l-4.3-23.1A57,57,0,0,0,180,208a60,60,0,0,0,6.7-119.6ZM180,184a35.6,35.6,0,0,1-21.4-7,11.9,11.9,0,0,0-13.5-.6,12.1,12.1,0,0,0-5.4,12.4l5.8,31.2h-35l5.8-31.2a12.1,12.1,0,0,0-5.4-12.4,11.9,11.9,0,0,0-13.5.6A35.6,35.6,0,0,1,76,184a36,36,0,0,1,0-72,37.5,37.5,0,0,1,8.2.9,13.4,13.4,0,0,0,4.9.3,12.2,12.2,0,0,0,9.8-9,12.5,12.5,0,0,0-1.4-9.1l-.2-.3A35.4,35.4,0,0,1,92,76a36,36,0,1,1,66.8,18.7,11.3,11.3,0,0,0-1.9,5.1,12,12,0,0,0,15,13.1,36.6,36.6,0,0,1,8.1-.9,36,36,0,0,1,0,72Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M112.5,179.2A48,48,0,1,1,76,100a51.2,51.2,0,0,1,11.2,1.3h0A47.3,47.3,0,0,1,80,76a48,48,0,0,1,96,0,47.3,47.3,0,0,1-7.2,25.3h0A51.2,51.2,0,0,1,180,100a48,48,0,1,1-36.5,79.2L160,232H96Z" />
          </g>
          <path d="M160,240H96a7.9,7.9,0,0,1-6.4-3.3,7.6,7.6,0,0,1-1.2-7.1l9.3-30A55,55,0,0,1,76,204,56,56,0,0,1,74.3,92,56.8,56.8,0,0,1,72,76a56,56,0,0,1,112,0,56.8,56.8,0,0,1-2.3,16A56,56,0,0,1,180,204a55,55,0,0,1-21.7-4.4l9.3,30a7.6,7.6,0,0,1-1.2,7.1A7.9,7.9,0,0,1,160,240Zm-53.1-16h42.2l-13.3-42.5a8,8,0,0,1,13.8-7.5,39.9,39.9,0,1,0,21.3-65,7.7,7.7,0,0,1-5.9-.6,8.2,8.2,0,0,1-4.2-8.1,9.3,9.3,0,0,1,1.3-3.4A39.6,39.6,0,0,0,168,76,40,40,0,1,0,93.9,97l.2.2a8,8,0,0,1-5.6,12,9,9,0,0,1-3.3-.1A40.6,40.6,0,0,0,76,108a40,40,0,1,0,30.4,66,8,8,0,0,1,13.8,7.5Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M232,148a52,52,0,0,1-80.5,43.5l10.7,34.1a7.8,7.8,0,0,1-1.2,7.1,7.9,7.9,0,0,1-6.4,3.3H101.4a7.9,7.9,0,0,1-6.4-3.3,7.8,7.8,0,0,1-1.2-7.1l10.7-34.1a51.7,51.7,0,0,1-30,8.5c-27.7-.8-50.4-24-50.5-51.8A52.1,52.1,0,0,1,76,96l4,.2a53.3,53.3,0,0,1-3.9-23.4,52,52,0,1,1,99.8,23.4A51.5,51.5,0,0,1,232,148Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M160,238H96a6,6,0,0,1-4.8-2.4,5.9,5.9,0,0,1-.9-5.4L101,195.9A54,54,0,1,1,76,94h1.1a54,54,0,1,1,101.8,0H180a54,54,0,1,1-25,101.9l10.7,34.3a5.9,5.9,0,0,1-.9,5.4A6,6,0,0,1,160,238Zm-55.8-12h47.6l-14-45.1a5.9,5.9,0,0,1,10.2-5.6A42,42,0,1,0,180,106a44.2,44.2,0,0,0-9.6,1.1,5.7,5.7,0,0,1-4.4-.5,6.1,6.1,0,0,1-3.2-6,7.9,7.9,0,0,1,1-2.7A41,41,0,0,0,170,76a42,42,0,0,0-84,0,42.5,42.5,0,0,0,6.2,22c.1.1.1.1.1.2a5.9,5.9,0,0,1-4.2,9,4.9,4.9,0,0,1-2.4-.1A45.1,45.1,0,0,0,76,106a42,42,0,1,0,32,69.3,5.9,5.9,0,0,1,10.2,5.6Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M160,236H96a4,4,0,0,1-3.8-5.2l12.3-39.3A52,52,0,1,1,76,96l4.1.2a52,52,0,1,1,95.8,0L180,96a52,52,0,1,1-28.5,95.5l12.3,39.3A4,4,0,0,1,160,236Zm-58.6-8h53.2l-14.9-47.7a3.9,3.9,0,0,1,6.8-3.7A44,44,0,1,0,180,104a42.9,42.9,0,0,0-10.1,1.2,4.1,4.1,0,0,1-5.1-4.4,4.5,4.5,0,0,1,.7-1.8A43.2,43.2,0,0,0,172,76a44,44,0,0,0-88,0,44.5,44.5,0,0,0,6.5,23.1.1.1,0,0,1,.1.1,3.9,3.9,0,0,1,.5,3.1,4.2,4.2,0,0,1-3.3,3,4.3,4.3,0,0,1-1.6-.1,44,44,0,1,0,23.3,71.4,3.9,3.9,0,0,1,6.8,3.7Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M160,240H96a7.9,7.9,0,0,1-6.4-3.3,7.6,7.6,0,0,1-1.2-7.1l9.3-30A55,55,0,0,1,76,204,56,56,0,0,1,74.3,92,56.8,56.8,0,0,1,72,76a56,56,0,0,1,112,0,56.8,56.8,0,0,1-2.3,16A56,56,0,0,1,180,204a55,55,0,0,1-21.7-4.4l9.3,30a7.6,7.6,0,0,1-1.2,7.1A7.9,7.9,0,0,1,160,240Zm-53.1-16h42.2l-13.3-42.5a8,8,0,0,1,13.8-7.5,39.9,39.9,0,1,0,21.3-65,7.7,7.7,0,0,1-5.9-.6,8.2,8.2,0,0,1-4.2-8.1,9.3,9.3,0,0,1,1.3-3.4A39.6,39.6,0,0,0,168,76,40,40,0,1,0,93.9,97a7.6,7.6,0,0,1,1.1,6.1,7.9,7.9,0,0,1-6.5,6.1,9.2,9.2,0,0,1-3.3-.1A40.6,40.6,0,0,0,76,108a40,40,0,1,0,30.4,66,8,8,0,0,1,13.8,7.5Z" />
        </>
      )}
    </svg>
  );
}

export function PhCoatHanger(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M244,168,148,96l19.2-14.4A12.1,12.1,0,0,0,172,72a44,44,0,0,0-88,0,12,12,0,0,0,24,0,20,20,0,0,1,39.2-5.4L121,86.2l-.4.4L12,168a20,20,0,0,0,12,36H232a20,20,0,0,0,12-36ZM36,180l92-69,92,69Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M241.6,171.2,141.3,96l23.5-17.6A8.1,8.1,0,0,0,168,72a40,40,0,0,0-80,0,8,8,0,0,0,16,0,24,24,0,0,1,47.7-3.8L123.3,89.5l-.2.2L14.4,171.2A16,16,0,0,0,24,200H232a16,16,0,0,0,9.6-28.8ZM24,184l104-78,104,78H24Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M241.6,171.2,141.3,96l23.5-17.6A8.1,8.1,0,0,0,168,72a40,40,0,0,0-80,0,8,8,0,0,0,16,0,24,24,0,0,1,47.7-3.8L123.3,89.5l-.2.2L14.4,171.2A16,16,0,0,0,24,200H232a16,16,0,0,0,9.6-28.8ZM24,184l104-78,104,78H24Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M240.4,172.8,138,96l25.6-19.2A6,6,0,0,0,166,72a38,38,0,0,0-76,0,6,6,0,0,0,12,0,26,26,0,0,1,51.8-2.9l-29.3,22-.2.2L15.6,172.8A14,14,0,0,0,24,198H232a14,14,0,0,0,8.4-25.2Zm-6.5,11.8A1.8,1.8,0,0,1,232,186H24a1.8,1.8,0,0,1-1.9-1.4,1.8,1.8,0,0,1,.7-2.2L128,103.5l105.2,78.9A1.8,1.8,0,0,1,233.9,184.6Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M239.2,174.4,134.7,96l27.7-20.8A4,4,0,0,0,164,72a36,36,0,0,0-72,0,4,4,0,0,0,8,0,28,28,0,0,1,55.9-1.9L125.7,92.7l-.2.2L16.8,174.4a12,12,0,0,0-4.2,13.4A11.8,11.8,0,0,0,24,196H232a11.8,11.8,0,0,0,11.4-8.2A12,12,0,0,0,239.2,174.4Zm-3.4,10.9A3.9,3.9,0,0,1,232,188H24a3.9,3.9,0,0,1-3.8-2.7,4,4,0,0,1,1.4-4.5L128,101l106.4,79.8A4,4,0,0,1,235.8,185.3Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M241.6,171.2,141.3,96l23.5-17.6A8.1,8.1,0,0,0,168,72a40,40,0,0,0-80,0,8,8,0,0,0,16,0,24,24,0,0,1,47.7-3.8L123.3,89.5l-.2.2L14.4,171.2A16,16,0,0,0,24,200H232a16,16,0,0,0,9.6-28.8ZM232,184H24l104-78,104,78Z" />
        </>
      )}
    </svg>
  );
}

export function PhCodeSimple(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M96,73,34.1,128,96,183a12.1,12.1,0,0,1,1,17,12.1,12.1,0,0,1-17,1L8,137a12.1,12.1,0,0,1,0-18L80,55A12,12,0,0,1,96,73Zm152,46L176,55a12,12,0,1,0-16,18l61.9,55L160,183a12.1,12.1,0,0,0-1,17,12.1,12.1,0,0,0,17,1l72-64a12.1,12.1,0,0,0,0-18Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M93.3,70,28,128l65.3,58A8,8,0,0,1,88,200a8.1,8.1,0,0,1-5.3-2l-72-64a8,8,0,0,1,0-12l72-64A8,8,0,0,1,93.3,70Zm152,52-72-64a8,8,0,0,0-10.6,12L228,128l-65.3,58a8,8,0,0,0,5.3,14,8.1,8.1,0,0,0,5.3-2l72-64a8,8,0,0,0,0-12Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M93.3,70,28,128l65.3,58A8,8,0,0,1,88,200a8.1,8.1,0,0,1-5.3-2l-72-64a8,8,0,0,1,0-12l72-64A8,8,0,0,1,93.3,70Zm152,52-72-64a8,8,0,0,0-10.6,12L228,128l-65.3,58a8,8,0,0,0,5.3,14,8.1,8.1,0,0,0,5.3-2l72-64a8,8,0,0,0,0-12Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M92,68.5,25,128l67,59.5A6,6,0,0,1,88,198a6.2,6.2,0,0,1-4-1.5l-72-64a6.1,6.1,0,0,1,0-9l72-64a6,6,0,0,1,8,9Zm152,55-72-64a6,6,0,1,0-8,9L231,128l-67,59.5a6,6,0,0,0-.5,8.5,6.1,6.1,0,0,0,8.5.5l72-64a6.1,6.1,0,0,0,0-9Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M90.7,67,22,128l68.7,61a4.1,4.1,0,0,1,.3,5.7,4.3,4.3,0,0,1-3,1.3,4.1,4.1,0,0,1-2.7-1l-72-64a4.1,4.1,0,0,1,0-6l72-64a4,4,0,0,1,5.4,6Zm152,58-72-64a4,4,0,0,0-5.4,6L234,128l-68.7,61a4.1,4.1,0,0,0-.3,5.7,4.3,4.3,0,0,0,3,1.3,3.9,3.9,0,0,0,2.7-1l72-64a4.1,4.1,0,0,0,0-6Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M93.3,70,28,128l65.3,58A8,8,0,0,1,88,200a8.1,8.1,0,0,1-5.3-2l-72-64a8,8,0,0,1,0-12l72-64A8,8,0,0,1,93.3,70Zm152,52-72-64a8,8,0,0,0-10.6,12L228,128l-65.3,58a8,8,0,0,0,5.3,14,8.1,8.1,0,0,0,5.3-2l72-64a8,8,0,0,0,0-12Z" />
        </>
      )}
    </svg>
  );
}

export function PhCode(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M71.7,97.2,34.8,128l36.9,30.8a12,12,0,1,1-15.4,18.4l-48-40a12,12,0,0,1,0-18.4l48-40A12,12,0,0,1,71.7,97.2Zm176,21.6-48-40a12,12,0,0,0-15.4,18.4l37,30.8-37,30.8a11.9,11.9,0,0,0-1.5,16.9A12.2,12.2,0,0,0,192,180a12,12,0,0,0,7.7-2.8l48-40a12,12,0,0,0,0-18.4ZM164.1,28.7a12.1,12.1,0,0,0-15.4,7.2l-64,176a12.1,12.1,0,0,0,7.2,15.4,11.8,11.8,0,0,0,4.1.7,12,12,0,0,0,11.3-7.9l64-176A12.1,12.1,0,0,0,164.1,28.7Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M69.1,94.1,28.5,128l40.6,33.9a7.9,7.9,0,0,1,1.1,11.2A8.1,8.1,0,0,1,64,176a7.7,7.7,0,0,1-5.1-1.9l-48-40a7.9,7.9,0,0,1,0-12.2l48-40A8,8,0,1,1,69.1,94.1Zm176,27.8-48-40a8,8,0,0,0-10.2,12.2L227.5,128l-40.6,33.9A8,8,0,0,0,192,176a7.7,7.7,0,0,0,5.1-1.9l48-40a7.9,7.9,0,0,0,0-12.2ZM162.7,32.5a7.9,7.9,0,0,0-10.2,4.8l-64,176a7.9,7.9,0,0,0,4.8,10.2,8.6,8.6,0,0,0,2.7.5,7.9,7.9,0,0,0,7.5-5.3l64-176A7.9,7.9,0,0,0,162.7,32.5Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M69.1,94.1,28.5,128l40.6,33.9a7.9,7.9,0,0,1,1.1,11.2A8.1,8.1,0,0,1,64,176a7.7,7.7,0,0,1-5.1-1.9l-48-40a7.9,7.9,0,0,1,0-12.2l48-40A8,8,0,1,1,69.1,94.1Zm176,27.8-48-40a8,8,0,0,0-10.2,12.2L227.5,128l-40.6,33.9A8,8,0,0,0,192,176a7.7,7.7,0,0,0,5.1-1.9l48-40a7.9,7.9,0,0,0,0-12.2ZM162.7,32.5a7.9,7.9,0,0,0-10.2,4.8l-64,176a7.9,7.9,0,0,0,4.8,10.2,8.6,8.6,0,0,0,2.7.5,7.9,7.9,0,0,0,7.5-5.3l64-176A7.9,7.9,0,0,0,162.7,32.5Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M67.8,92.6,25.4,128l42.4,35.4a5.9,5.9,0,0,1,.8,8.4A5.8,5.8,0,0,1,64,174a5.9,5.9,0,0,1-3.8-1.4l-48-40a5.9,5.9,0,0,1,0-9.2l48-40a6,6,0,0,1,7.6,9.2Zm176,30.8-48-40a6,6,0,0,0-7.6,9.2L230.6,128l-42.4,35.4a5.9,5.9,0,0,0-.8,8.4A5.8,5.8,0,0,0,192,174a5.9,5.9,0,0,0,3.8-1.4l48-40a5.9,5.9,0,0,0,0-9.2Zm-81.7-89a5.9,5.9,0,0,0-7.7,3.5l-64,176a5.9,5.9,0,0,0,3.6,7.7,4.5,4.5,0,0,0,2,.4,5.8,5.8,0,0,0,5.6-3.9l64-176A5.9,5.9,0,0,0,162.1,34.4Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M66.6,91.1,22.3,128l44.3,36.9a4.1,4.1,0,0,1,.5,5.7A4.3,4.3,0,0,1,64,172a4.3,4.3,0,0,1-2.6-.9l-48-40a4.1,4.1,0,0,1,0-6.2l48-40a4,4,0,0,1,5.2,6.2Zm176,33.8-48-40a4,4,0,1,0-5.2,6.2L233.8,128l-44.4,36.9a4.1,4.1,0,0,0-.5,5.7A4.3,4.3,0,0,0,192,172a4.3,4.3,0,0,0,2.6-.9l48-40a4.1,4.1,0,0,0,0-6.2ZM161.4,36.2a4.2,4.2,0,0,0-5.2,2.4l-64,176a4.2,4.2,0,0,0,2.4,5.2l1.4.2a4.1,4.1,0,0,0,3.8-2.6l64-176A4.2,4.2,0,0,0,161.4,36.2Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M69.1,94.1,28.5,128l40.6,33.9a7.9,7.9,0,0,1,1.1,11.2A8.1,8.1,0,0,1,64,176a7.7,7.7,0,0,1-5.1-1.9l-48-40a7.9,7.9,0,0,1,0-12.2l48-40A8,8,0,1,1,69.1,94.1Zm176,27.8-48-40a8,8,0,0,0-10.2,12.2L227.5,128l-40.6,33.9A8,8,0,0,0,192,176a7.7,7.7,0,0,0,5.1-1.9l48-40a7.9,7.9,0,0,0,0-12.2ZM162.7,32.5a7.9,7.9,0,0,0-10.2,4.8l-64,176a7.9,7.9,0,0,0,4.8,10.2,8.6,8.6,0,0,0,2.7.5,7.9,7.9,0,0,0,7.5-5.3l64-176A7.9,7.9,0,0,0,162.7,32.5Z" />
        </>
      )}
    </svg>
  );
}

export function PhCodepenLogo(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M237.9,90.6l-100-56.8a20,20,0,0,0-19.8,0L18.1,90.6A11.9,11.9,0,0,0,12,101v64a11.9,11.9,0,0,0,6.1,10.4l100,56.8a20.1,20.1,0,0,0,19.8,0l100-56.8A11.9,11.9,0,0,0,244,165V101A11.9,11.9,0,0,0,237.9,90.6ZM220,144.4,199.9,133,220,121.6Zm-44.4-25.2L140,99V62.6L207.7,101Zm-47.6,27L104.7,133,128,119.8,151.3,133ZM116,62.6V99L80.4,119.2,48.3,101Zm-80,59L56.1,133,36,144.4Zm44.4,25.2L116,167v36.4L48.3,165ZM140,203.4V167l35.6-20.2L207.7,165Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M232,165,131.9,221.8a8.3,8.3,0,0,1-7.8,0L24,165l104-59Z"
            opacity="0.2"
          />
          <path d="M235.9,94l-100-56.7a15.9,15.9,0,0,0-15.8,0L20.1,94a8,8,0,0,0-4.1,7v64a8,8,0,0,0,4.1,7l100,56.7a15.9,15.9,0,0,0,15.8,0l100-56.7a8,8,0,0,0,4.1-7V101A8,8,0,0,0,235.9,94ZM224,151.3,191.8,133,224,114.7Zm-48.4-27.5L136,101.3V55.7L215.8,101Zm-47.6,27L96.6,133,128,115.2,159.4,133Zm-8-95.1v45.6L80.4,123.8,40.2,101Zm-88,59L64.2,133,32,151.3Zm48.4,27.5L120,164.7v45.6L40.2,165ZM136,210.3V164.7l39.6-22.5L215.8,165Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M240,101v64a8,8,0,0,1-4.1,7L142,225.3a4,4,0,0,1-6-3.5V164.7L191.8,133,224,151.3V114.7L191.8,133l-16.2-9.2L215.8,101,136,55.7v45.6l39.6,22.5-47.6,27-47.6-27L120,101.3V55.7L40.2,101l40.2,22.8L64.2,133,32,114.7v36.6L64.2,133,120,164.7v57.1a4,4,0,0,1-6,3.5L20.1,172a8,8,0,0,1-4.1-7V101a8,8,0,0,1,4.1-7l100-56.7a15.9,15.9,0,0,1,15.8,0L235.9,94A8,8,0,0,1,240,101Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M235,95.8,134.9,39a14.1,14.1,0,0,0-13.8,0L21,95.8a6,6,0,0,0-3,5.2v64a6,6,0,0,0,3,5.2L121.1,227a14.1,14.1,0,0,0,13.8,0L235,170.2a6,6,0,0,0,3-5.2V101A6,6,0,0,0,235,95.8Zm-9,58.9L187.8,133,226,111.3ZM219.8,101l-44.2,25.1L134,102.5V52.3ZM128,153.1,92.6,133,128,112.9,163.4,133ZM122,52.3v50.2L80.4,126.1,36.2,101Zm-92,59L68.2,133,30,154.7ZM36.2,165l44.2-25.1L122,163.5v50.2ZM134,213.7V163.5l41.6-23.6L219.8,165Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M234,97.5,133.9,40.8a11.8,11.8,0,0,0-11.8,0L22,97.5a4.2,4.2,0,0,0-2,3.5v64a4.2,4.2,0,0,0,2,3.5l100.1,56.7a11.7,11.7,0,0,0,11.8,0L234,168.5a4.2,4.2,0,0,0,2-3.5V101A4.2,4.2,0,0,0,234,97.5Zm-6,60.6L183.7,133,228,107.9ZM223.9,101l-48.3,27.4L132,103.7V48.9ZM128,155.4,88.5,133,128,110.6,167.5,133ZM124,48.9v54.8L80.4,128.4,32.1,101Zm-96,59L72.3,133,28,158.1ZM32.1,165l48.3-27.4L124,162.3v54.8ZM132,217.1V162.3l43.6-24.7L223.9,165Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M235.9,94l-100-56.7a15.9,15.9,0,0,0-15.8,0L20.1,94a8,8,0,0,0-4.1,7v64a8,8,0,0,0,4.1,7l100,56.7a15.9,15.9,0,0,0,15.8,0l100-56.7a8,8,0,0,0,4.1-7V101A8,8,0,0,0,235.9,94ZM224,151.3,191.8,133,224,114.7Zm-48.4-27.5L136,101.3V55.7L215.8,101Zm-47.6,27L96.6,133,128,115.2,159.4,133Zm-8-95.1v45.6L80.4,123.8,40.2,101Zm-88,59L64.2,133,32,151.3Zm48.4,27.5L120,164.7v45.6L40.2,165ZM136,210.3V164.7l39.6-22.5L215.8,165Z" />
        </>
      )}
    </svg>
  );
}

export function PhCodesandboxLogo(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M236,78.7a19.4,19.4,0,0,0-2.6-9.8c0-.1,0-.1-.1-.2h0a20.5,20.5,0,0,0-7.5-7.4l-88-49.5a20.1,20.1,0,0,0-19.6,0l-88,49.5a20,20,0,0,0-7.5,7.5h-.1a.3.3,0,0,1-.1.2A20,20,0,0,0,20,78.7v98.6a20.2,20.2,0,0,0,10.2,17.5l88,49.5a20.6,20.6,0,0,0,9,2.5h1.7a21.2,21.2,0,0,0,8.9-2.5l88-49.5A20.2,20.2,0,0,0,236,177.3Zm-72,75.2V202l-23.8,13.4.7-80.4L212,94.6v25.3l-41.9,23.6A11.9,11.9,0,0,0,164,153.9ZM85.9,143.5,44,119.9V94.4L116.9,135l-.7,80.6L92,202V153.9A11.9,11.9,0,0,0,85.9,143.5ZM79,61.3l43.1,24.2a11.7,11.7,0,0,0,11.8,0L177,61.3l22.6,12.8-70.7,40.1L56.6,73.9Zm49-27.5,24.5,13.7L128,61.3,103.5,47.5ZM44,147.4l24,13.5v27.6L44,175Zm144,41.1V160.9l24-13.5V175Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M177,47.5,128,75.1,79,47.5l45.1-25.3a7.8,7.8,0,0,1,7.8,0Zm-1,106.4V209l43.9-24.7a8.1,8.1,0,0,0,4.1-7V126.9ZM32,177.3a8.1,8.1,0,0,0,4.1,7L80,209V153.9l-48-27Z"
            opacity="0.2"
          />
          <path d="M232,78.7a15.6,15.6,0,0,0-2.1-7.9h-.1a15.5,15.5,0,0,0-6-5.9l-88-49.5a16,16,0,0,0-15.6,0l-88,49.5a16.2,16.2,0,0,0-6,5.9.1.1,0,0,1-.1.1v.2A15,15,0,0,0,24,78.7v98.6a16.1,16.1,0,0,0,8.2,14l88,49.5a16.5,16.5,0,0,0,7.2,2h1.3a16.1,16.1,0,0,0,7.1-2l88-49.5a16.1,16.1,0,0,0,8.2-14Zm-64,75.2v50.4l-31.9,18,.8-89.6,79.1-45v34.5L172.1,147A7.9,7.9,0,0,0,168,153.9ZM83.9,147,40,122.2V87.6l80.9,45.1-.8,89.7L88,204.3V153.9A7.9,7.9,0,0,0,83.9,147ZM79,56.7,124.1,82a7.5,7.5,0,0,0,7.8,0L177,56.7,207.7,74l-78.8,44.8L48.4,74Zm49-27.5,32.6,18.3L128,65.9,95.4,47.5ZM40,140.6l32,18v36.7l-32-18Zm144,54.7V158.6l32-18v36.7Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M229.9,70.8h-.1a15.5,15.5,0,0,0-6-5.9l-88-49.5a16,16,0,0,0-15.6,0l-88,49.5a16.2,16.2,0,0,0-6,5.9.1.1,0,0,1-.1.1v.2A15,15,0,0,0,24,78.7v98.6a16.1,16.1,0,0,0,8.2,14l88,49.5a16.5,16.5,0,0,0,7.2,2h1.3a16.1,16.1,0,0,0,7.1-2l88-49.5a16.1,16.1,0,0,0,8.2-14V78.7A15.6,15.6,0,0,0,229.9,70.8ZM120.1,222.4,88,204.3V153.9a7.9,7.9,0,0,0-4.1-6.9L40,122.2V87.6l80.9,45.1Zm8.8-103.6L48.4,74,79,56.7,124.1,82a7.5,7.5,0,0,0,7.8,0L177,56.7,207.7,74Zm87.1,3.4L172.1,147a7.9,7.9,0,0,0-4.1,6.9v50.4l-31.9,18,.8-89.6,79.1-45Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M230,78.7a14.8,14.8,0,0,0-1.8-6.9h-.1a14.1,14.1,0,0,0-5.2-5.1L134.9,17a14.1,14.1,0,0,0-13.8,0l-88,49.5a13.8,13.8,0,0,0-4.9,4.7h0l-.3.4h-.1A13.7,13.7,0,0,0,26,78.7v98.6a14,14,0,0,0,7.1,12.2l88,49.5a14.8,14.8,0,0,0,6.1,1.8h1.6a14.8,14.8,0,0,0,6.1-1.8l88-49.5a14,14,0,0,0,7.1-12.2ZM128.9,121.1,44.3,74,79,54.4l46.1,25.9a5.7,5.7,0,0,0,5.8,0L177,54.4,211.8,74ZM127,27.4l1-.2,1,.2,35.7,20.1L128,68.2,91.3,47.5ZM38,177.3V137.2l36,20.2v41.3L39,179.1A2.2,2.2,0,0,1,38,177.3Zm48,28.2V153.9a6.1,6.1,0,0,0-3.1-5.2L38,123.4V84.2l84.9,47.3-.8,94.3Zm48.1,20.2.8-94.2L218,84.3v39.1l-44.9,25.3a6.1,6.1,0,0,0-3.1,5.2v51.6ZM217,179.1l-35,19.6V157.4l36-20.2v40.1A2.2,2.2,0,0,1,217,179.1Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M226.4,72.7h0a12.7,12.7,0,0,0-4.5-4.4l-88-49.5a12.4,12.4,0,0,0-11.8,0l-88,49.5a12.7,12.7,0,0,0-4.5,4.4h0A11.8,11.8,0,0,0,28,78.7v98.6a11.9,11.9,0,0,0,6.1,10.5l88,49.5a11.5,11.5,0,0,0,5.6,1.5h.6a12.3,12.3,0,0,0,5.6-1.5l88-49.5a11.9,11.9,0,0,0,6.1-10.5V78.7A12.5,12.5,0,0,0,226.4,72.7Zm-97.5,50.7L40.2,74,79,52.1l47,26.5a4.2,4.2,0,0,0,4,0l47-26.5L215.9,74ZM126,25.7a4.3,4.3,0,0,1,4,0l38.8,21.8L128,70.5l-40.8-23ZM36,177.3V133.8l40,22.5v45.9L38,180.8A4,4,0,0,1,36,177.3Zm48,29.4V153.9a4.3,4.3,0,0,0-2-3.5L36,124.6V80.8l88.9,49.5-.8,98.9Zm48.1,22.4.8-98.8L220,80.9v43.7l-46,25.8a4.3,4.3,0,0,0-2,3.5v52.8ZM218,180.8l-38,21.4V156.3l40-22.5v43.5A4,4,0,0,1,218,180.8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M232,78.7a15.6,15.6,0,0,0-2.1-7.9h-.1a15.5,15.5,0,0,0-6-5.9l-88-49.5a16,16,0,0,0-15.6,0l-88,49.5a16.2,16.2,0,0,0-6,5.9.1.1,0,0,1-.1.1v.2A15,15,0,0,0,24,78.7v98.6a16.1,16.1,0,0,0,8.2,14l88,49.5a16.5,16.5,0,0,0,7.2,2h1.3a16.1,16.1,0,0,0,7.1-2l88-49.5a16.1,16.1,0,0,0,8.2-14Zm-64,75.2v50.4l-31.9,18,.8-89.6,79.1-45v34.5L172.1,147A7.9,7.9,0,0,0,168,153.9ZM83.9,147,40,122.2V87.6l80.9,45.1-.8,89.7L88,204.3V153.9A7.9,7.9,0,0,0,83.9,147ZM79,56.7,124.1,82a7.5,7.5,0,0,0,7.8,0L177,56.7,207.7,74l-78.8,44.8L48.4,74Zm49-27.5,32.6,18.3L128,65.9,95.4,47.5ZM40,140.6l32,18v36.7l-32-18Zm144,54.7V158.6l32-18v36.7Z" />
        </>
      )}
    </svg>
  );
}

export function PhCoffee(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M212,76H32A12,12,0,0,0,20,88v48a100.4,100.4,0,0,0,26.7,68H32a12,12,0,0,0,0,24H208a12,12,0,0,0,0-24H193.3a101.5,101.5,0,0,0,20-32A44.1,44.1,0,0,0,256,128v-8A44,44,0,0,0,212,76ZM86,204a76.2,76.2,0,0,1-42-68V100H196v36a75.9,75.9,0,0,1-2.8,20.3c-.1.2-.1.5-.2.7a76.3,76.3,0,0,1-39,47Zm146-76a20.1,20.1,0,0,1-12.6,18.6A94.2,94.2,0,0,0,220,136V101.7A19.9,19.9,0,0,1,232,120ZM68,48V24a12,12,0,0,1,24,0V48a12,12,0,0,1-24,0Zm40,0V24a12,12,0,0,1,24,0V48a12,12,0,0,1-24,0Zm40,0V24a12,12,0,0,1,24,0V48a12,12,0,0,1-24,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M83.3,216A88,88,0,0,1,32,136V88H208v48a88,88,0,0,1-51.3,80Z"
            opacity="0.2"
          />
          <path d="M80,56V24a8,8,0,0,1,16,0V56a8,8,0,0,1-16,0Zm40,8a8,8,0,0,0,8-8V24a8,8,0,0,0-16,0V56A8,8,0,0,0,120,64Zm32,0a8,8,0,0,0,8-8V24a8,8,0,0,0-16,0V56A8,8,0,0,0,152,64Zm96,56v8a40,40,0,0,1-37.5,39.9,98,98,0,0,1-27,40.1H208a8,8,0,0,1,0,16H32a8,8,0,0,1,0-16H56.5A96.4,96.4,0,0,1,24,136V88a8,8,0,0,1,8-8H208A40,40,0,0,1,248,120Zm-51.2,38.1a1.4,1.4,0,0,0,.2-.6,77,77,0,0,0,3-21.5V96H40v40a80.3,80.3,0,0,0,45.1,72h69.8A80.3,80.3,0,0,0,196.8,158.1ZM232,120a24,24,0,0,0-16-22.6V136a92.9,92.9,0,0,1-1.2,15A24,24,0,0,0,232,128Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M80,56V24a8,8,0,0,1,16,0V56a8,8,0,0,1-16,0Zm40,8a8,8,0,0,0,8-8V24a8,8,0,0,0-16,0V56A8,8,0,0,0,120,64Zm32,0a8,8,0,0,0,8-8V24a8,8,0,0,0-16,0V56A8,8,0,0,0,152,64Zm96,56v8a40,40,0,0,1-37.5,39.9,98,98,0,0,1-27,40.1H208a8,8,0,0,1,0,16H32a8,8,0,0,1,0-16H56.5A96.4,96.4,0,0,1,24,136V88a8,8,0,0,1,8-8H208A40,40,0,0,1,248,120Zm-16,0a24,24,0,0,0-16-22.6V136a92.9,92.9,0,0,1-1.2,15A24,24,0,0,0,232,128Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M82,56V24a6,6,0,0,1,12,0V56a6,6,0,0,1-12,0Zm38,6a6,6,0,0,0,6-6V24a6,6,0,0,0-12,0V56A6,6,0,0,0,120,62Zm32,0a6,6,0,0,0,6-6V24a6,6,0,0,0-12,0V56A6,6,0,0,0,152,62Zm94,58v8a38.1,38.1,0,0,1-36.9,38,95,95,0,0,1-31.2,44H208a6,6,0,0,1,0,12H32a6,6,0,0,1,0-12H62.1A94.3,94.3,0,0,1,26,136V88a6,6,0,0,1,6-6H208A38,38,0,0,1,246,120Zm-47.2,38.7c0-.2.1-.4.1-.6A81.4,81.4,0,0,0,202,136V94H38v42a82.3,82.3,0,0,0,46.7,74h70.6A82.4,82.4,0,0,0,198.8,158.7ZM234,120a26,26,0,0,0-20-25.3V136a92.9,92.9,0,0,1-1.7,17.6A25.9,25.9,0,0,0,234,128Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M84,56V24a4,4,0,0,1,8,0V56a4,4,0,0,1-8,0Zm36,4a4,4,0,0,0,4-4V24a4,4,0,0,0-8,0V56A4,4,0,0,0,120,60Zm32,0a4,4,0,0,0,4-4V24a4,4,0,0,0-8,0V56A4,4,0,0,0,152,60Zm92,60v8a36,36,0,0,1-36,36h-.4a92.2,92.2,0,0,1-35.8,48H208a4,4,0,0,1,0,8H32a4,4,0,0,1,0-8H68.2A92.4,92.4,0,0,1,28,136V88a4,4,0,0,1,4-4H208A36,36,0,0,1,244,120Zm-43.3,39.2h0A81.1,81.1,0,0,0,204,136V92H36v44a84.3,84.3,0,0,0,48.2,76h71.6A84.2,84.2,0,0,0,200.7,159.2ZM236,120a27.9,27.9,0,0,0-24-27.7V136a93.3,93.3,0,0,1-2.2,19.9A28,28,0,0,0,236,128Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M80,56V24a8,8,0,0,1,16,0V56a8,8,0,0,1-16,0Zm40,8a8,8,0,0,0,8-8V24a8,8,0,0,0-16,0V56A8,8,0,0,0,120,64Zm32,0a8,8,0,0,0,8-8V24a8,8,0,0,0-16,0V56A8,8,0,0,0,152,64Zm96,56v8a40,40,0,0,1-37.5,39.9,98,98,0,0,1-27,40.1H208a8,8,0,0,1,0,16H32a8,8,0,0,1,0-16H56.5A96.4,96.4,0,0,1,24,136V88a8,8,0,0,1,8-8H208A40,40,0,0,1,248,120ZM200,96H40v40a80.3,80.3,0,0,0,45.1,72h69.8a80.3,80.3,0,0,0,41.9-49.9,1.4,1.4,0,0,0,.2-.6,77,77,0,0,0,3-21.5Zm32,24a24,24,0,0,0-16-22.6V136a92.9,92.9,0,0,1-1.2,15A24,24,0,0,0,232,128Z" />
        </>
      )}
    </svg>
  );
}

export function PhCoinVertical(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M152,20H104C65.9,20,36,67.4,36,128s29.9,108,68,108h48c38.1,0,68-47.4,68-108S190.1,20,152,20Zm14.3,152a167,167,0,0,0,5.3-32h23.9a133.5,133.5,0,0,1-6.3,32Zm5.3-56a167,167,0,0,0-5.3-32h22.9a133.5,133.5,0,0,1,6.3,32Zm5.6-56h-20a101,101,0,0,0-10-16H152C157.4,44,167.3,46.2,177.2,60ZM74.8,189.8C65.2,173.5,60,151.6,60,128s5.2-45.5,14.8-61.8C86,46.9,97.9,44,104,44s18,2.9,29.2,22.2a99.1,99.1,0,0,1,5.2,10.3.4.4,0,0,0,.1.3c6.2,14.7,9.5,32.4,9.5,51.2s-3.3,36.5-9.5,51.2a.4.4,0,0,0-.1.3,99.1,99.1,0,0,1-5.2,10.3C122,209.1,110.1,212,104,212S86,209.1,74.8,189.8ZM152,212h-4.8a101,101,0,0,0,10-16h20C167.3,209.8,157.4,212,152,212Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M160,128c0,53-25.1,96-56,96s-56-43-56-96,25.1-96,56-96S160,75,160,128Z"
            opacity="0.2"
          />
          <path d="M198.5,56.1C186.4,35.4,169.9,24,152,24H104C86.1,24,69.6,35.4,57.5,56.1S40,101,40,128s6.2,52.6,17.5,71.9S86.1,232,104,232h48c17.9,0,34.4-11.4,46.5-32.1S216,155,216,128,209.8,75.4,198.5,56.1Zm1.3,63.9h-32c-.8-17.2-4.1-33.7-9.7-48h30.6C194.8,85.4,198.9,102,199.8,120ZM179.2,56H150.5a89,89,0,0,0-12-16H152C162,40,171.4,46,179.2,56ZM56,128c0-47.7,22-88,48-88s48,40.3,48,88-22,88-48,88S56,175.7,56,128Zm96,88H138.5a89,89,0,0,0,12-16h28.7C171.4,210,162,216,152,216Zm36.7-32H158.1c5.6-14.3,8.9-30.8,9.7-48h32C198.9,154,194.8,170.6,188.7,184Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M198.5,56.1C186.4,35.4,169.9,24,152,24H104C86.1,24,69.6,35.4,57.5,56.1S40,101,40,128s6.2,52.6,17.5,71.9S86.1,232,104,232h48c17.9,0,34.4-11.4,46.5-32.1S216,155,216,128,209.8,75.4,198.5,56.1Zm1.3,63.9h-32c-.8-17.2-4.1-33.7-9.7-48h30.6C194.8,85.4,198.9,102,199.8,120ZM179.2,56H150.5a89,89,0,0,0-12-16H152C162,40,171.4,46,179.2,56ZM152,216H138.5a89,89,0,0,0,12-16h28.7C171.4,210,162,216,152,216Zm36.7-32H158.1c5.6-14.3,8.9-30.8,9.7-48h32C198.9,154,194.8,170.6,188.7,184Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M196.8,57.1C185.1,37,169.2,26,152,26H104C86.8,26,70.9,37,59.2,57.1S42,101.3,42,128s6.1,51.9,17.2,70.9S86.8,230,104,230h48c17.2,0,33.1-11,44.8-31.1S214,154.7,214,128,207.9,76.1,196.8,57.1Zm5.1,64.9h-36c-.7-18.8-4.4-36.7-10.8-52H190C197.1,84.7,201.2,102.8,201.9,122ZM152,38c11.3,0,22.2,7.1,31.1,20H149.3l-.5-.9A75.1,75.1,0,0,0,133.5,38ZM69.6,192.9C59.5,175.6,54,152.6,54,128s5.5-47.6,15.6-64.9S91.3,38,104,38s25,8.9,34.4,25.1S154,103.4,154,128s-5.5,47.6-15.6,64.9S116.7,218,104,218,79,209.1,69.6,192.9ZM152,218H133.5a75.1,75.1,0,0,0,15.3-19.1l.5-.9h33.8C174.2,210.9,163.3,218,152,218Zm38-32H155.1c6.4-15.3,10.1-33.2,10.8-52h36C201.2,153.2,197.1,171.3,190,186Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M195.1,58.1C183.7,38.7,168.4,28,152,28H104C87.6,28,72.3,38.7,60.9,58.1S44,101.7,44,128s6,51.2,16.9,69.9S87.6,228,104,228h48c16.4,0,31.7-10.7,43.1-30.1S212,154.3,212,128,206,76.8,195.1,58.1Zm8.8,65.9h-40c-.4-20.5-4.6-39.9-11.8-56h39.2C199,83.7,203.4,103.2,203.9,124ZM152,36c12.9,0,25.2,8.5,34.8,24H148.1l-1-1.9c-5.6-9.6-12.2-17-19.3-22.1ZM67.9,193.9C57.6,176.3,52,152.9,52,128s5.6-48.3,15.9-65.9S90.5,36,104,36s26.3,9.3,36.1,26.1S156,103.1,156,128s-5.6,48.3-15.9,65.9S117.5,220,104,220,77.7,210.7,67.9,193.9ZM152,220H127.8c7.1-5.1,13.7-12.5,19.3-22.1l1-1.9h38.7C177.2,211.5,164.9,220,152,220Zm39.3-32H152.1c7.2-16.1,11.4-35.5,11.8-56h40C203.4,152.8,199,172.3,191.3,188Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M198.5,56.1C186.4,35.4,169.9,24,152,24H104C86.1,24,69.6,35.4,57.5,56.1S40,101,40,128s6.2,52.6,17.5,71.9S86.1,232,104,232h48c17.9,0,34.4-11.4,46.5-32.1S216,155,216,128,209.8,75.4,198.5,56.1Zm1.3,63.9h-32c-.8-17.2-4.1-33.7-9.7-48h30.6C194.8,85.4,198.9,102,199.8,120ZM179.2,56H150.5a89,89,0,0,0-12-16H152C162,40,171.4,46,179.2,56ZM56,128c0-47.7,22-88,48-88s48,40.3,48,88-22,88-48,88S56,175.7,56,128Zm96,88H138.5a89,89,0,0,0,12-16h28.7C171.4,210,162,216,152,216Zm36.7-32H158.1c5.6-14.3,8.9-30.8,9.7-48h32C198.9,154,194.8,170.6,188.7,184Z" />
        </>
      )}
    </svg>
  );
}

export function PhCoin(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M209.4,60.3C188.1,49.6,159.9,44,128,44S67.9,49.6,46.6,60.3,12,87.2,12,104v48c0,16.8,12.6,32.7,34.6,43.7S96.1,212,128,212s60.1-5.6,81.4-16.3S244,168.8,244,152V104C244,87.2,231.4,71.3,209.4,60.3ZM57.4,81.7C75.1,72.9,100.2,68,128,68s52.9,4.9,70.6,13.7C211.8,88.3,220,96.9,220,104s-8.2,15.7-21.4,22.3C180.9,135.1,155.8,140,128,140s-52.9-4.9-70.6-13.7C44.2,119.7,36,111.1,36,104S44.2,88.3,57.4,81.7ZM180,181.4a182.2,182.2,0,0,1-40,6.3v-24a204.3,204.3,0,0,0,40-5.5ZM76,158.2a204.3,204.3,0,0,0,40,5.5v24a182.2,182.2,0,0,1-40-6.3ZM36,152V141.5a89.5,89.5,0,0,0,10.6,6.2l5.4,2.5v21.1C42,165.2,36,158.1,36,152Zm168,19.3V150.2l5.4-2.5a89.5,89.5,0,0,0,10.6-6.2V152C220,158.1,214,165.2,204,171.3Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M232,104c0,24-40,48-104,48S24,128,24,104,64,56,128,56,232,80,232,104Z"
            opacity="0.2"
          />
          <path d="M207.6,63.8C186.8,53.5,159.3,48,128,48S69.2,53.5,48.4,63.8,16,88.8,16,104v48c0,15.2,11.8,29.9,32.4,40.2S96.7,208,128,208s58.8-5.5,79.6-15.8S240,167.2,240,152V104C240,88.8,228.2,74.1,207.6,63.8ZM128,64c62.6,0,96,23.2,96,40,0,9.9-11.7,22.2-34.4,30.5h-.3c-15.5,5.6-36,9.4-61.3,9.4s-45.8-3.8-61.3-9.4h-.3C43.7,126.2,32,113.9,32,104,32,87.2,65.4,64,128,64Zm-8,95.9v32c-19-.7-35-3.5-48-7.5V153C86.3,157,102.5,159.3,120,159.9Zm16,0c17.5-.6,33.7-2.9,48-6.9v31.4c-13,4-29,6.8-48,7.5ZM32,152V133.5a84.5,84.5,0,0,0,16.4,10.7l7.6,3.4V178C40.2,170.2,32,160.3,32,152Zm168,26V147.6l7.6-3.4A84.5,84.5,0,0,0,224,133.5V152C224,160.3,215.8,170.2,200,178Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M207.6,63.8C186.8,53.5,159.3,48,128,48S69.2,53.5,48.4,63.8,16,88.8,16,104v48c0,15.2,11.8,29.9,32.4,40.2S96.7,208,128,208s58.8-5.5,79.6-15.8S240,167.2,240,152V104C240,88.8,228.2,74.1,207.6,63.8ZM120,159.9v32c-19-.7-35-3.5-48-7.5V153C86.3,157,102.5,159.3,120,159.9Zm16,0c17.5-.6,33.7-2.9,48-6.9v31.4c-13,4-29,6.8-48,7.5ZM32,152V133.5a84.5,84.5,0,0,0,16.4,10.7l7.6,3.4V178C40.2,170.2,32,160.3,32,152Zm168,26V147.6l7.6-3.4A84.5,84.5,0,0,0,224,133.5V152C224,160.3,215.8,170.2,200,178Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M206.7,65.6C186.2,55.4,159,50,128,50S69.8,55.4,49.3,65.6,18,89.6,18,104v48c0,14.4,11.4,28.4,31.3,38.4S97,206,128,206s58.2-5.4,78.7-15.6S238,166.4,238,152V104C238,89.6,226.6,75.6,206.7,65.6ZM54.7,76.4C73.2,67.1,99.2,62,128,62s54.8,5.1,73.3,14.4C217,84.2,226,94.3,226,104s-9,19.8-24.7,27.6C182.8,140.9,156.8,146,128,146s-54.8-5.1-73.3-14.4C39,123.8,30,113.7,30,104S39,84.2,54.7,76.4ZM122,157.9v36c-19.2-.5-37.1-3.3-52-8.1V150.4C85.3,154.9,102.8,157.5,122,157.9Zm12,0c19.2-.4,36.7-3,52-7.5v35.4c-14.9,4.8-32.8,7.6-52,8.1ZM30,152V129a76.3,76.3,0,0,0,19.3,13.4c2.8,1.4,5.7,2.6,8.7,3.8v35l-3.3-1.6C39,171.8,30,161.7,30,152Zm171.3,27.6-3.3,1.6v-35c3-1.2,5.9-2.4,8.7-3.8A76.3,76.3,0,0,0,226,129v23C226,161.7,217,171.8,201.3,179.6Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M205.8,67.4C185.9,57.5,158.3,52,128,52S70.1,57.5,50.2,67.4,20,90.4,20,104v48c0,13.6,11,27,30.2,36.6a124.2,124.2,0,0,0,12.2,5.2h.3C81,200.4,103.6,204,128,204s47-3.6,65.3-10.1h.3a124.2,124.2,0,0,0,12.2-5.2C225,179,236,165.6,236,152V104C236,90.4,225,77,205.8,67.4ZM128,60c61.8,0,100,22.8,100,44s-38.2,44-100,44S28,125.2,28,104,66.2,60,128,60Zm-4,96v40c-22-.4-40.9-3.7-56-8.8V147.6C84.2,152.7,103.4,155.6,124,156Zm8,0c20.6-.4,39.8-3.3,56-8.4v39.6c-15.1,5.1-34,8.4-56,8.8ZM28,152V123.9c5.2,6.2,12.7,11.9,22.2,16.7,3.1,1.5,6.4,2.9,9.8,4.3v39.3C39.5,175.7,28,163.6,28,152Zm200,0c0,11.6-11.5,23.7-32,32.2V144.9c3.4-1.4,6.7-2.8,9.8-4.3,9.5-4.8,17-10.5,22.2-16.7Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M207.6,63.8C186.8,53.5,159.3,48,128,48S69.2,53.5,48.4,63.8,16,88.8,16,104v48c0,15.2,11.8,29.9,32.4,40.2S96.7,208,128,208s58.8-5.5,79.6-15.8S240,167.2,240,152V104C240,88.8,228.2,74.1,207.6,63.8ZM128,64c62.6,0,96,23.2,96,40,0,9.9-11.7,22.2-34.4,30.5h-.3c-15.5,5.6-36,9.4-61.3,9.4s-45.8-3.8-61.3-9.4h-.3C43.7,126.2,32,113.9,32,104,32,87.2,65.4,64,128,64Zm-8,95.9v32c-19-.7-35-3.5-48-7.5V153C86.3,157,102.5,159.3,120,159.9Zm16,0c17.5-.6,33.7-2.9,48-6.9v31.4c-13,4-29,6.8-48,7.5ZM32,152V133.5a84.5,84.5,0,0,0,16.4,10.7l7.6,3.4V178C40.2,170.2,32,160.3,32,152Zm168,26V147.6l7.6-3.4A84.5,84.5,0,0,0,224,133.5V152C224,160.3,215.8,170.2,200,178Z" />
        </>
      )}
    </svg>
  );
}

export function PhCoins(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M188,86.1V84c0-14.6-10.8-27.5-30.5-36.4C140.9,40.1,119,36,96,36S51.1,40.1,34.5,47.6C14.8,56.5,4,69.4,4,84v40c0,14.6,10.8,27.5,30.5,36.4A133,133,0,0,0,68,169.9V172c0,14.6,10.8,27.5,30.5,36.4C115.1,215.9,137,220,160,220s44.9-4.1,61.5-11.6c19.7-8.9,30.5-21.8,30.5-36.4V132C252,109.9,226.7,92.1,188,86.1ZM228,132c0,7.8-21.8,22.5-61.8,23.9,14.1-8.5,21.8-19.6,21.8-31.9V110.4C213.9,115.1,228,125.5,228,132ZM76,146.8V130.9A187.1,187.1,0,0,0,96,132a187.1,187.1,0,0,0,20-1.1v15.9A154.7,154.7,0,0,1,96,148,154.7,154.7,0,0,1,76,146.8Zm88-29.7V124c0,4.8-8.3,12.2-24,17.5v-15a105.7,105.7,0,0,0,17.5-6.1A56.4,56.4,0,0,0,164,117.1ZM96,60c44,0,68,15.9,68,24s-24,24-68,24S28,92.1,28,84,52,60,96,60ZM28,124v-6.9a56.4,56.4,0,0,0,6.5,3.3A105.7,105.7,0,0,0,52,126.5v15C36.3,136.2,28,128.8,28,124Zm64,48h4q5.4,0,10.8-.3c2.9,1,6,2,9.2,2.8v15C100.3,184.2,92,176.8,92,172Zm48,22.8V178.9a187.1,187.1,0,0,0,20,1.1,187.1,187.1,0,0,0,20-1.1v15.9a167.3,167.3,0,0,1-40,0Zm64-5.3v-15a105.7,105.7,0,0,0,17.5-6.1,56.4,56.4,0,0,0,6.5-3.3V172C228,176.8,219.7,184.2,204,189.5Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M96,120c-44.2,0-80-16.1-80-36S51.8,48,96,48s80,16.1,80,36S140.2,120,96,120Zm80-23.3V124c0,18-29.2,32.9-67.5,35.6h0c13.9,5.2,31.9,8.4,51.5,8.4,44.2,0,80-16.1,80-36C240,114.6,212.5,100.1,176,96.7Z"
            opacity="0.2"
          />
          <path d="M184,89.6V84c0-25.1-37.8-44-88-44S8,58.9,8,84v40c0,20.9,26.2,37.5,64,42.5V172c0,25.1,37.8,44,88,44s88-18.9,88-44V132C248,111.3,222.6,94.7,184,89.6ZM232,132c0,13.2-30.8,28-72,28-3.7,0-7.4-.1-11.1-.4C170.5,151.8,184,139,184,124V105.7C213.9,110.2,232,122.3,232,132ZM72,150.2V126.5A184,184,0,0,0,96,128a184,184,0,0,0,24-1.5v23.7A151.1,151.1,0,0,1,96,152,151.1,151.1,0,0,1,72,150.2Zm96-40.3V124c0,8.4-12.4,17.4-32,22.9V123.5C148.9,120.4,159.8,115.7,168,109.9ZM96,56c41.2,0,72,14.8,72,28s-30.8,28-72,28S24,97.2,24,84,54.8,56,96,56ZM24,124V109.9c8.2,5.8,19.1,10.5,32,13.6v23.4C36.4,141.4,24,132.4,24,124Zm64,48v-4.2l8,.2q5.9,0,11.4-.3c4,1.4,8.2,2.6,12.6,3.7v23.5C100.4,189.4,88,180.4,88,172Zm48,26.2V174.4a188.4,188.4,0,0,0,24,1.6,184,184,0,0,0,24-1.5v23.7a160.9,160.9,0,0,1-48,0Zm64-3.3V171.5c12.9-3.1,23.8-7.8,32-13.6V172C232,180.4,219.6,189.4,200,194.9Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M184,89.6V84c0-25.1-37.8-44-88-44S8,58.9,8,84v40c0,20.9,26.2,37.5,64,42.5V172c0,25.1,37.8,44,88,44s88-18.9,88-44V132C248,111.3,222.6,94.7,184,89.6ZM72,150.2V126.5A184,184,0,0,0,96,128a184,184,0,0,0,24-1.5v23.7A151.1,151.1,0,0,1,96,152,151.1,151.1,0,0,1,72,150.2Zm96-40.3V124c0,8.4-12.4,17.4-32,22.9V123.5C148.9,120.4,159.8,115.7,168,109.9ZM24,124V109.9c8.2,5.8,19.1,10.5,32,13.6v23.4C36.4,141.4,24,132.4,24,124Zm64,48v-4.2l8,.2q5.9,0,11.4-.3c4,1.4,8.2,2.6,12.6,3.7v23.5C100.4,189.4,88,180.4,88,172Zm48,26.2V174.4a188.4,188.4,0,0,0,24,1.6,184,184,0,0,0,24-1.5v23.7a160.9,160.9,0,0,1-48,0Zm64-3.3V171.5c12.9-3.1,23.8-7.8,32-13.6V172C232,180.4,219.6,189.4,200,194.9Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M224.6,103.8c-11.2-6.1-26.1-10.4-42.6-12.5V84c0-12.1-9.6-23.1-27-30.9C139.2,45.9,118.2,42,96,42S52.8,45.9,37,53.1C19.6,60.9,10,71.9,10,84v40c0,12.1,9.6,23.1,27,30.9a130.8,130.8,0,0,0,37,9.8V172c0,12.1,9.6,23.1,27,30.9,15.8,7.2,36.8,11.1,59,11.1s43.2-3.9,59-11.1c17.4-7.8,27-18.8,27-30.9V132C246,121.3,238.4,111.3,224.6,103.8Zm-5.8,10.5c9.8,5.4,15.2,11.6,15.2,17.7,0,14.2-30.4,30-74,30a182.8,182.8,0,0,1-21.2-1.3,102.7,102.7,0,0,0,16.2-5.8c17.4-7.8,27-18.8,27-30.9V103.4C196.4,105.4,209.3,109.2,218.8,114.3Zm-138.1,39h-.3c-3.6-.3-7.1-.8-10.4-1.3V124.1A181.7,181.7,0,0,0,96,126a181.7,181.7,0,0,0,26-1.9V152a162.5,162.5,0,0,1-26,2A151.1,151.1,0,0,1,80.7,153.3ZM170,105.9V124c0,9.5-13.7,19.8-36,25.5V121.8a105.6,105.6,0,0,0,21-6.9A62.7,62.7,0,0,0,170,105.9ZM96,54c43.6,0,74,15.8,74,30s-30.4,30-74,30S22,98.2,22,84,52.4,54,96,54ZM22,124V105.9a62.7,62.7,0,0,0,15,9,105.6,105.6,0,0,0,21,6.9v27.7C35.7,143.8,22,133.5,22,124Zm64,48v-6.3c3.3.2,6.6.3,10,.3s7.8-.1,11.7-.4a95.9,95.9,0,0,0,14.3,4.2v27.7C99.7,191.8,86,181.5,86,172Zm48,28V172.1a182.8,182.8,0,0,0,26,1.9,181.7,181.7,0,0,0,26-1.9V200a170,170,0,0,1-52,0Zm64-2.5V169.8a105.6,105.6,0,0,0,21-6.9,62.7,62.7,0,0,0,15-9V172C234,181.5,220.3,191.8,198,197.5Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M180,93.1V84c0-22.4-36.9-40-84-40S12,61.6,12,84v40c0,19.1,26.9,34.7,64,38.9V172c0,22.4,36.9,40,84,40s84-17.6,84-40V132C244,113.1,217.9,97.4,180,93.1ZM236,132c0,15.5-30.5,32-76,32a168.8,168.8,0,0,1-28-2.3v-1.4c28.6-6.3,48-20,48-36.3V101.2C212.2,105,236,117.9,236,132ZM80.3,155.3H80c-4.2-.4-8.2-.9-12-1.6V121.8A176.6,176.6,0,0,0,96,124a176.6,176.6,0,0,0,28-2.2v31.9A161.9,161.9,0,0,1,96,156C90.5,156,85.3,155.8,80.3,155.3Zm91.7-54V124c0,10.9-15.2,22.3-40,28.1V120.3C149.6,116.4,163.8,109.7,172,101.3ZM96,52c45.5,0,76,16.5,76,32s-30.5,32-76,32S20,99.5,20,84,50.5,52,96,52ZM20,124V101.3c8.2,8.4,22.4,15.1,40,19v31.8C35.2,146.3,20,134.9,20,124Zm64,48v-8.4c3.9.3,7.9.4,12,.4s8.1-.1,12-.4a115.9,115.9,0,0,0,16,4.6v31.9C99.2,194.3,84,182.9,84,172Zm48,29.7V169.8a181.5,181.5,0,0,0,28,2.2,176.6,176.6,0,0,0,28-2.2v31.9a171.6,171.6,0,0,1-56,0ZM236,172c0,10.9-15.2,22.3-40,28.1V168.3c17.6-3.9,31.8-10.6,40-19Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M184,89.6V84c0-25.1-37.8-44-88-44S8,58.9,8,84v40c0,20.9,26.2,37.5,64,42.5V172c0,25.1,37.8,44,88,44s88-18.9,88-44V132C248,111.3,222.6,94.7,184,89.6ZM232,132c0,13.2-30.8,28-72,28-3.7,0-7.4-.1-11.1-.4C170.5,151.8,184,139,184,124V105.7C213.9,110.2,232,122.3,232,132ZM72,150.2V126.5A184,184,0,0,0,96,128a184,184,0,0,0,24-1.5v23.7A151.1,151.1,0,0,1,96,152,151.1,151.1,0,0,1,72,150.2Zm96-40.3V124c0,8.4-12.4,17.4-32,22.9V123.5C148.9,120.4,159.8,115.7,168,109.9ZM96,56c41.2,0,72,14.8,72,28s-30.8,28-72,28S24,97.2,24,84,54.8,56,96,56ZM24,124V109.9c8.2,5.8,19.1,10.5,32,13.6v23.4C36.4,141.4,24,132.4,24,124Zm64,48v-4.2l8,.2q5.9,0,11.4-.3c4,1.4,8.2,2.6,12.6,3.7v23.5C100.4,189.4,88,180.4,88,172Zm48,26.2V174.4a188.4,188.4,0,0,0,24,1.6,184,184,0,0,0,24-1.5v23.7a160.9,160.9,0,0,1-48,0Zm64-3.3V171.5c12.9-3.1,23.8-7.8,32-13.6V172C232,180.4,219.6,189.4,200,194.9Z" />
        </>
      )}
    </svg>
  );
}

export function PhColumns(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M100,28H64A20.1,20.1,0,0,0,44,48V208a20.1,20.1,0,0,0,20,20h36a20.1,20.1,0,0,0,20-20V48A20.1,20.1,0,0,0,100,28ZM96,204H68V52H96ZM192,28H156a20.1,20.1,0,0,0-20,20V208a20.1,20.1,0,0,0,20,20h36a20.1,20.1,0,0,0,20-20V48A20.1,20.1,0,0,0,192,28Zm-4,176H160V52h28Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <rect
            x="-4"
            y="100"
            width="176"
            height="56"
            rx="8"
            transform="translate(212 44) rotate(90)"
            opacity="0.2"
          />
          <rect
            x="84"
            y="100"
            width="176"
            height="56"
            rx="8"
            transform="translate(300 -44) rotate(90)"
            opacity="0.2"
          />
          <path d="M104,32H64A16,16,0,0,0,48,48V208a16,16,0,0,0,16,16h40a16,16,0,0,0,16-16V48A16,16,0,0,0,104,32Zm0,176H64V48h40ZM192,32H152a16,16,0,0,0-16,16V208a16,16,0,0,0,16,16h40a16,16,0,0,0,16-16V48A16,16,0,0,0,192,32Zm0,176H152V48h40Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M120,48V208a16,16,0,0,1-16,16H64a16,16,0,0,1-16-16V48A16,16,0,0,1,64,32h40A16,16,0,0,1,120,48Zm72-16H152a16,16,0,0,0-16,16V208a16,16,0,0,0,16,16h40a16,16,0,0,0,16-16V48A16,16,0,0,0,192,32Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M104,34H64A14,14,0,0,0,50,48V208a14,14,0,0,0,14,14h40a14,14,0,0,0,14-14V48A14,14,0,0,0,104,34Zm2,174a2,2,0,0,1-2,2H64a2,2,0,0,1-2-2V48a2,2,0,0,1,2-2h40a2,2,0,0,1,2,2ZM192,34H152a14,14,0,0,0-14,14V208a14,14,0,0,0,14,14h40a14,14,0,0,0,14-14V48A14,14,0,0,0,192,34Zm2,174a2,2,0,0,1-2,2H152a2,2,0,0,1-2-2V48a2,2,0,0,1,2-2h40a2,2,0,0,1,2,2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M104,36H64A12,12,0,0,0,52,48V208a12,12,0,0,0,12,12h40a12,12,0,0,0,12-12V48A12,12,0,0,0,104,36Zm4,172a4,4,0,0,1-4,4H64a4,4,0,0,1-4-4V48a4,4,0,0,1,4-4h40a4,4,0,0,1,4,4ZM192,36H152a12,12,0,0,0-12,12V208a12,12,0,0,0,12,12h40a12,12,0,0,0,12-12V48A12,12,0,0,0,192,36Zm4,172a4,4,0,0,1-4,4H152a4,4,0,0,1-4-4V48a4,4,0,0,1,4-4h40a4,4,0,0,1,4,4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M104,32H64A16,16,0,0,0,48,48V208a16,16,0,0,0,16,16h40a16,16,0,0,0,16-16V48A16,16,0,0,0,104,32Zm0,176H64V48h40Z" />
          <path d="M192,32H152a16,16,0,0,0-16,16V208a16,16,0,0,0,16,16h40a16,16,0,0,0,16-16V48A16,16,0,0,0,192,32Zm0,176H152V48h40Z" />
        </>
      )}
    </svg>
  );
}

export function PhCommand(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M180,140H164V116h16a40,40,0,1,0-40-40V92H116V76a40,40,0,1,0-40,40H92v24H76a40,40,0,1,0,40,40V164h24v16a40,40,0,1,0,40-40ZM164,76a16,16,0,1,1,16,16H164ZM60,76a16,16,0,0,1,32,0V92H76A16,16,0,0,1,60,76ZM92,180a16,16,0,1,1-16-16H92Zm24-64h24v24H116Zm64,80a16,16,0,0,1-16-16V164h16a16,16,0,0,1,0,32Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M180,48h0a28,28,0,0,1,28,28v0a28,28,0,0,1-28,28H152a0,0,0,0,1,0,0V76a28,28,0,0,1,28-28Z" />
          </g>
          <g opacity="0.2">
            <path
              d="M48,48H76a28,28,0,0,1,28,28v0a28,28,0,0,1-28,28h0A28,28,0,0,1,48,76V48A0,0,0,0,1,48,48Z"
              transform="translate(152 152) rotate(180)"
            />
          </g>
          <g opacity="0.2">
            <path d="M152,152h28a28,28,0,0,1,28,28v0a28,28,0,0,1-28,28h0a28,28,0,0,1-28-28V152A0,0,0,0,1,152,152Z" />
          </g>
          <g opacity="0.2">
            <path
              d="M76,152h0a28,28,0,0,1,28,28v0a28,28,0,0,1-28,28H48a0,0,0,0,1,0,0V180A28,28,0,0,1,76,152Z"
              transform="translate(152 360) rotate(-180)"
            />
          </g>
          <path d="M180,144H160V112h20a36,36,0,1,0-36-36V96H112V76a36,36,0,1,0-36,36H96v32H76a36,36,0,1,0,36,36V160h32v20a36,36,0,1,0,36-36ZM160,76a20,20,0,1,1,20,20H160ZM56,76a20,20,0,0,1,40,0V96H76A20.1,20.1,0,0,1,56,76ZM96,180a20,20,0,1,1-20-20H96Zm16-68h32v32H112Zm68,88a20.1,20.1,0,0,1-20-20V160h20a20,20,0,0,1,0,40Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M180,144H160V112h20a36,36,0,1,0-36-36V96H112V76a36,36,0,1,0-36,36H96v32H76a36,36,0,1,0,36,36V160h32v20a36,36,0,1,0,36-36ZM160,76a20,20,0,1,1,20,20H160ZM56,76a20,20,0,0,1,40,0V96H76A20.1,20.1,0,0,1,56,76ZM96,180a20,20,0,1,1-20-20H96Zm16-68h32v32H112Zm68,88a20.1,20.1,0,0,1-20-20V160h20a20,20,0,0,1,0,40Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M180,146H158V110h22a34,34,0,1,0-34-34V98H110V76a34,34,0,1,0-34,34H98v36H76a34,34,0,1,0,34,34V158h36v22a34,34,0,1,0,34-34ZM158,76a22,22,0,1,1,22,22H158ZM54,76a22,22,0,0,1,44,0V98H76A22.1,22.1,0,0,1,54,76ZM98,180a22,22,0,1,1-22-22H98Zm12-70h36v36H110Zm70,92a22.1,22.1,0,0,1-22-22V158h22a22,22,0,0,1,0,44Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M180,148H156V108h24a32,32,0,1,0-32-32v24H108V76a32,32,0,1,0-32,32h24v40H76a32,32,0,1,0,32,32V156h40v24a32,32,0,1,0,32-32ZM156,76a24,24,0,1,1,24,24H156ZM52,76a24,24,0,0,1,48,0v24H76A24.1,24.1,0,0,1,52,76Zm48,104a24,24,0,1,1-24-24h24Zm8-72h40v40H108Zm72,96a24.1,24.1,0,0,1-24-24V156h24a24,24,0,0,1,0,48Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M180,144H160V112h20a36,36,0,1,0-36-36V96H112V76a36,36,0,1,0-36,36H96v32H76a36,36,0,1,0,36,36V160h32v20a36,36,0,1,0,36-36ZM160,76a20,20,0,1,1,20,20H160ZM56,76a20,20,0,0,1,40,0V96H76A20.1,20.1,0,0,1,56,76ZM96,180a20,20,0,1,1-20-20H96Zm16-68h32v32H112Zm68,88a20.1,20.1,0,0,1-20-20V160h20a20,20,0,0,1,0,40Z" />
        </>
      )}
    </svg>
  );
}

export function PhCompass(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128.1,20a108,108,0,1,0,108,108A108.1,108.1,0,0,0,128.1,20Zm0,192a84,84,0,1,1,84-84A84.1,84.1,0,0,1,128.1,212Zm35-134.7L106.7,99.9a11.6,11.6,0,0,0-6.7,6.7L77.4,163.1A12,12,0,0,0,93,178.7l56.5-22.6a11.6,11.6,0,0,0,6.7-6.7l22.5-56.5a12,12,0,0,0-15.6-15.6Zm-27.3,58.4L110,146.1l10.4-25.8L146.1,110Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M128.1,32a96,96,0,1,0,96,96A96,96,0,0,0,128.1,32Zm17,113L85.7,170.4,111.1,111l62.1-28.2Z" />
          </g>
          <path d="M128.1,24a104,104,0,1,0,104,104A104.2,104.2,0,0,0,128.1,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128.1,216ZM169.9,75.5l-62.1,28.2a8.2,8.2,0,0,0-4,4.2L78.3,167.3a8,8,0,0,0,1.7,8.8,8.3,8.3,0,0,0,5.7,2.3,8.5,8.5,0,0,0,3.1-.6l59.4-25.5a8.2,8.2,0,0,0,4.2-4l28.1-62.2a8.1,8.1,0,0,0-10.6-10.6Zm-30.8,63.3-38.2,16.4L117.3,117l39.8-18.1Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128.1,24a104,104,0,1,0,104,104A104.2,104.2,0,0,0,128.1,24Zm52.4,62.1-28.1,62.2a8.2,8.2,0,0,1-4.2,4L88.8,177.8a8.5,8.5,0,0,1-3.1.6,8.3,8.3,0,0,1-5.7-2.3,8,8,0,0,1-1.7-8.8l25.5-59.4a8.2,8.2,0,0,1,4-4.2l62.1-28.2a8.1,8.1,0,0,1,10.6,10.6Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128.1,26a102,102,0,1,0,102,102A102.2,102.2,0,0,0,128.1,26Zm0,192a90,90,0,1,1,90-90A90.1,90.1,0,0,1,128.1,218ZM170.7,77.4l-62,28.2a5.9,5.9,0,0,0-3.1,3.1L80.2,168.1a6,6,0,0,0,1.2,6.6,6.3,6.3,0,0,0,4.3,1.7,4.6,4.6,0,0,0,2.3-.5l59.4-25.4a5.9,5.9,0,0,0,3.1-3.1l28.1-62.1a6,6,0,0,0-7.9-7.9Zm-30.1,63L97.1,159l18.6-43.5,45.4-20.6Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128.1,28a100,100,0,1,0,100,100A100.2,100.2,0,0,0,128.1,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128.1,220ZM171.5,79.2l-62,28.2a3.8,3.8,0,0,0-2,2.1L82,168.9a4,4,0,0,0,.9,4.4,3.9,3.9,0,0,0,2.8,1.1l1.6-.3,59.4-25.5a3.6,3.6,0,0,0,2-2l28.1-62.1a4,4,0,0,0-5.3-5.3Zm-29.4,62.7L93.3,162.8,114.2,114l50.9-23.1Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128.1,24a104,104,0,1,0,104,104A104.2,104.2,0,0,0,128.1,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128.1,216ZM169.9,75.5l-62.1,28.2a8.2,8.2,0,0,0-4,4.2L78.3,167.3a8,8,0,0,0,1.7,8.8,8.3,8.3,0,0,0,5.7,2.3,8.5,8.5,0,0,0,3.1-.6l59.4-25.5a8.2,8.2,0,0,0,4.2-4l28.1-62.2a8.1,8.1,0,0,0-10.6-10.6Zm-30.8,63.3-38.2,16.4L117.3,117l39.8-18.1Z" />
        </>
      )}
    </svg>
  );
}

export function PhComputerTower(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M84,76A12,12,0,0,1,96,64h64a12,12,0,0,1,0,24H96A12,12,0,0,1,84,76Zm12,52h64a12,12,0,0,0,0-24H96a12,12,0,0,0,0,24Zm32,40a16,16,0,1,0,16,16A16,16,0,0,0,128,168ZM212,40V216a20.1,20.1,0,0,1-20,20H64a20.1,20.1,0,0,1-20-20V40A20.1,20.1,0,0,1,64,20H192A20.1,20.1,0,0,1,212,40Zm-24,4H68V212H188Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect
              x="32"
              y="56"
              width="192"
              height="144"
              rx="8"
              transform="translate(256 0) rotate(90)"
            />
          </g>
          <path d="M88,72a8,8,0,0,1,8-8h64a8,8,0,0,1,0,16H96A8,8,0,0,1,88,72Zm8,40h64a8,8,0,0,0,0-16H96a8,8,0,0,0,0,16Zm32,56a12,12,0,1,0,12,12A12,12,0,0,0,128,168ZM208,40V216a16,16,0,0,1-16,16H64a16,16,0,0,1-16-16V40A16,16,0,0,1,64,24H192A16,16,0,0,1,208,40Zm-16,0H64V216H192Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M192,24H64A16,16,0,0,0,48,40V216a16,16,0,0,0,16,16H192a16,16,0,0,0,16-16V40A16,16,0,0,0,192,24ZM128,192a12,12,0,1,1,12-12A12,12,0,0,1,128,192Zm32-80H96a8,8,0,0,1,0-16h64a8,8,0,0,1,0,16Zm0-32H96a8,8,0,0,1,0-16h64a8,8,0,0,1,0,16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M166,72a6,6,0,0,1-6,6H96a6,6,0,0,1,0-12h64A6,6,0,0,1,166,72Zm-6,26H96a6,6,0,0,0,0,12h64a6,6,0,0,0,0-12Zm-32,72a10,10,0,1,0,10,10A10,10,0,0,0,128,170ZM206,40V216a14,14,0,0,1-14,14H64a14,14,0,0,1-14-14V40A14,14,0,0,1,64,26H192A14,14,0,0,1,206,40Zm-12,0a2,2,0,0,0-2-2H64a2,2,0,0,0-2,2V216a2,2,0,0,0,2,2H192a2,2,0,0,0,2-2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M164,72a4,4,0,0,1-4,4H96a4,4,0,0,1,0-8h64A4,4,0,0,1,164,72Zm-4,28H96a4,4,0,0,0,0,8h64a4,4,0,0,0,0-8Zm-32,72a8,8,0,1,0,8,8A8,8,0,0,0,128,172ZM204,40V216a12,12,0,0,1-12,12H64a12,12,0,0,1-12-12V40A12,12,0,0,1,64,28H192A12,12,0,0,1,204,40Zm-8,0a4,4,0,0,0-4-4H64a4,4,0,0,0-4,4V216a4,4,0,0,0,4,4H192a4,4,0,0,0,4-4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M88,72a8,8,0,0,1,8-8h64a8,8,0,0,1,0,16H96A8,8,0,0,1,88,72Zm8,40h64a8,8,0,0,0,0-16H96a8,8,0,0,0,0,16Zm32,56a12,12,0,1,0,12,12A12,12,0,0,0,128,168ZM208,40V216a16,16,0,0,1-16,16H64a16,16,0,0,1-16-16V40A16,16,0,0,1,64,24H192A16,16,0,0,1,208,40Zm-16,0H64V216H192Z" />
        </>
      )}
    </svg>
  );
}

export function PhConfetti(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M109.1,60.1a20.4,20.4,0,0,0-18.5-5.4A19.9,19.9,0,0,0,76.2,67.4L26.6,203.8a19.9,19.9,0,0,0,18.7,26.8,20.9,20.9,0,0,0,6.9-1.2l136.4-49.6a19.9,19.9,0,0,0,12.7-14.4,20.4,20.4,0,0,0-5.4-18.5Zm-3.6,124.4-34-34,8-22,48,48ZM52.1,203.9l10.3-28.5,18.2,18.2Zm100.3-36.5L88.6,103.6l8-22.1,77.9,77.9ZM124,40V16a12,12,0,0,1,24,0V40a12,12,0,0,1-24,0Zm116.5,87.5a12,12,0,0,1,0,17,12.1,12.1,0,0,1-17,0l-16-16a12,12,0,0,1,17-17Zm3.3-40.1-24,8a12.6,12.6,0,0,1-3.8.6,12,12,0,0,1-3.8-23.4l24-8a12,12,0,0,1,7.6,22.8Zm-79.8.7a12,12,0,0,0,12-12h0a17,17,0,0,1,2-7.2c1.3-2.4,3.5-4.9,10-4.9,26.4,0,36-21.5,36-36a12,12,0,0,0-24,0h0a17,17,0,0,1-2,7.2c-1.3,2.4-3.5,4.9-10,4.9-14.5,0-25.7,6.6-31.7,18.6A41.2,41.2,0,0,0,152,76h0A12,12,0,0,0,164,88.1Zm12-12h0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M57.6,153.6l44.8,44.8L48.1,218.1a7.9,7.9,0,0,1-10.2-10.2Zm141.5-2.5L104.9,56.9a8,8,0,0,0-13.2,2.9l-14.9,41,78.4,78.4,41-14.9A8,8,0,0,0,199.1,151.1Z"
            opacity="0.2"
          />
          <path d="M110.5,51.2a15.7,15.7,0,0,0-14.7-4.3A16,16,0,0,0,84.2,57.1L30.3,205.2h0a16.1,16.1,0,0,0,15,21.4,17.1,17.1,0,0,0,5.5-.9l148.1-53.9a16,16,0,0,0,10.2-11.6,15.7,15.7,0,0,0-4.3-14.7Zm-6,137.9L66.9,151.5,80,115.3,140.7,176ZM45.4,210.6l15.4-42.5,27.1,27.1Zm111.9-40.7L86.1,98.7,99.2,62.5l94.3,94.3ZM160,72a38.2,38.2,0,0,1,3.8-15.6C169.1,45.8,179.1,40,192,40c6.7,0,11-2.3,13.7-7.2a22.3,22.3,0,0,0,2.3-8.9,7.9,7.9,0,0,1,8-7.9,8,8,0,0,1,8,8c0,12.9-8.5,32-32,32-6.7,0-11,2.3-13.7,7.2a22.3,22.3,0,0,0-2.3,8.9,7.9,7.9,0,0,1-8,7.9A8,8,0,0,1,160,72ZM136,40V16a8,8,0,0,1,16,0V40a8,8,0,0,1-16,0Zm101.7,82.3a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0l-16-16a8.1,8.1,0,0,1,11.4-11.4Zm4.8-42.7-24,8a7.3,7.3,0,0,1-2.5.4,8,8,0,0,1-2.5-15.6l24-8a8,8,0,0,1,5,15.2Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M110.5,51.2a15.7,15.7,0,0,0-14.7-4.3A16,16,0,0,0,84.2,57.1L30.3,205.2h0a16.1,16.1,0,0,0,15,21.4,17.1,17.1,0,0,0,5.5-.9l148.1-53.9a16,16,0,0,0,10.2-11.6,15.7,15.7,0,0,0-4.3-14.7ZM63.8,159.8,83,107l66,66L96.2,192.2ZM160,72a38.2,38.2,0,0,1,3.8-15.6C169.1,45.8,179.1,40,192,40c6.7,0,11-2.3,13.7-7.2a22.3,22.3,0,0,0,2.3-8.9,7.9,7.9,0,0,1,8-7.9,8,8,0,0,1,8,8c0,12.9-8.5,32-32,32-6.7,0-11,2.3-13.7,7.2a22.3,22.3,0,0,0-2.3,8.9,7.9,7.9,0,0,1-8,7.9A8,8,0,0,1,160,72ZM136,40V16a8,8,0,0,1,16,0V40a8,8,0,0,1-16,0Zm101.7,82.3a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0l-16-16a8.1,8.1,0,0,1,11.4-11.4Zm4.8-42.7-24,8a7.3,7.3,0,0,1-2.5.4,8,8,0,0,1-2.5-15.6l24-8a8,8,0,0,1,5,15.2Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M109.1,52.6a14,14,0,0,0-23,5.2l-53.9,148a14,14,0,0,0,13.1,18.8,14,14,0,0,0,4.9-.8l148-53.9a14,14,0,0,0,5.2-23Zm-5.2,138.9L64.5,152.1l14.7-40.4,65.1,65.1Zm-57.8,21a2.1,2.1,0,0,1-2.6-2.6L60,164.5,91.5,196Zm149.3-55.3a2.2,2.2,0,0,1-1.3,1.5l-37.4,13.6-73-73L97.3,61.9a2.2,2.2,0,0,1,1.5-1.3,2,2,0,0,1,1.8.5l94.3,94.3A2,2,0,0,1,195.4,157.2ZM162,72a35.8,35.8,0,0,1,3.6-14.7c5-9.9,14.3-15.3,26.4-15.3,7.5,0,12.5-2.7,15.5-8.4A24.4,24.4,0,0,0,210,24a6,6,0,0,1,6-6h0a6,6,0,0,1,6,6c0,10.4-6.3,30-30,30-7.5,0-12.5,2.7-15.5,8.4A24.4,24.4,0,0,0,174,72a6,6,0,0,1-6,6h0A6,6,0,0,1,162,72ZM138,40V16a6,6,0,0,1,12,0V40a6,6,0,0,1-12,0Zm98.2,83.8a5.9,5.9,0,1,1-8.4,8.4l-16-16a5.9,5.9,0,0,1,8.4-8.4Zm5.7-46.1-24,8a6.3,6.3,0,0,1-1.9.3,6,6,0,0,1-5.7-4.1,6.1,6.1,0,0,1,3.8-7.6l24-8a6,6,0,0,1,3.8,11.4Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M107.7,54.1a11.8,11.8,0,0,0-11.1-3.3,12.3,12.3,0,0,0-8.7,7.6L34.1,206.5a12,12,0,0,0,2.8,12.6,11.8,11.8,0,0,0,8.4,3.5,12.3,12.3,0,0,0,4.2-.7l148.1-53.8a12.3,12.3,0,0,0,7.6-8.7,11.7,11.7,0,0,0-3.3-11.1Zm-4.3,139.7L62.2,152.6l16.2-44.5,69.5,69.5ZM46.7,214.4a4,4,0,0,1-5.1-5.1l17.6-48.4,35.9,35.9Zm150.7-56.8a3.8,3.8,0,0,1-2.6,2.9l-38.6,14.1L81.4,99.8,95.5,61.2a3.8,3.8,0,0,1,2.9-2.6,4,4,0,0,1,3.7,1.1l94.2,94.2A4,4,0,0,1,197.4,157.6ZM220,24c0,9.7-5.9,28-28,28-8.3,0-14.2,3.3-17.4,9.8A26.3,26.3,0,0,0,172,72a4,4,0,0,1-8,0,33.5,33.5,0,0,1,3.4-13.8C170.7,51.7,177.6,44,192,44c19.3,0,20-18,20-20a4,4,0,0,1,4-4h0A4,4,0,0,1,220,24ZM140,40V16a4,4,0,0,1,8,0V40a4,4,0,0,1-8,0Zm94.8,85.2a3.9,3.9,0,0,1,0,5.6,3.9,3.9,0,0,1-5.6,0l-16-16a4,4,0,0,1,5.6-5.6Zm6.5-49.4-24,8L216,84a4.1,4.1,0,0,1-3.8-2.7,4,4,0,0,1,2.5-5.1l24-8a4,4,0,1,1,2.6,7.6Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M110.5,51.2a15.7,15.7,0,0,0-14.7-4.3A16,16,0,0,0,84.2,57.1L30.3,205.2h0a16.1,16.1,0,0,0,15,21.4,17.1,17.1,0,0,0,5.5-.9l148.1-53.9a16,16,0,0,0,10.2-11.6,15.7,15.7,0,0,0-4.3-14.7Zm-6,137.9L66.9,151.5,80,115.3,140.7,176ZM45.4,210.6l15.4-42.5,27.1,27.1Zm111.9-40.7L86.1,98.7,99.2,62.5l94.3,94.3ZM160,72a38.2,38.2,0,0,1,3.8-15.6C169.1,45.8,179.1,40,192,40c6.7,0,11-2.3,13.7-7.2a22.3,22.3,0,0,0,2.3-8.9,7.9,7.9,0,0,1,8-7.9,8,8,0,0,1,8,8c0,12.9-8.5,32-32,32-6.7,0-11,2.3-13.7,7.2a22.3,22.3,0,0,0-2.3,8.9,7.9,7.9,0,0,1-8,7.9A8,8,0,0,1,160,72ZM136,40V16a8,8,0,0,1,16,0V40a8,8,0,0,1-16,0Zm101.7,82.3a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0l-16-16a8.1,8.1,0,0,1,11.4-11.4Zm4.8-42.7-24,8a7.3,7.3,0,0,1-2.5.4,8,8,0,0,1-2.5-15.6l24-8a8,8,0,0,1,5,15.2Z" />
        </>
      )}
    </svg>
  );
}

export function PhCookie(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M228.4,111.8a19.9,19.9,0,0,0-16.8-3.9,20.1,20.1,0,0,1-24.4-19.3,19.8,19.8,0,0,0-19.8-19.8,20.1,20.1,0,0,1-19.3-24.4A20.1,20.1,0,0,0,128.6,20H128a107.9,107.9,0,1,0,76.3,184.4A107,107,0,0,0,236,127.3h0A19.7,19.7,0,0,0,228.4,111.8Zm-41.1,75.7c-32.3,32.2-85.1,32.5-117.8.7A84,84,0,0,1,123.8,44.1a44.1,44.1,0,0,0,39.6,48.5,44.1,44.1,0,0,0,48.5,39.6A83.2,83.2,0,0,1,187.3,187.5Zm-20-26.8a16,16,0,1,1-22.6,0A15.9,15.9,0,0,1,167.3,160.7Zm-64-8a16,16,0,1,1-22.6,0A15.9,15.9,0,0,1,103.3,152.7ZM72.7,119.3a16,16,0,1,1,22.6,0A15.9,15.9,0,0,1,72.7,119.3Zm74.6,16a16,16,0,1,1,0-22.6A15.9,15.9,0,0,1,147.3,135.3Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M224,127.4a95.6,95.6,0,0,1-28.2,68.5c-36.9,36.9-97.3,37.3-134.7.9A96,96,0,0,1,128.6,32a8.1,8.1,0,0,1,7.8,9.8,32,32,0,0,0,30.8,39,8,8,0,0,1,8,8,32,32,0,0,0,39,30.8A8.1,8.1,0,0,1,224,127.4Z"
            opacity="0.2"
          />
          <path d="M225.9,114.9a15.8,15.8,0,0,0-13.4-3.1,23.9,23.9,0,0,1-29.3-23.1,16,16,0,0,0-15.9-15.9,23.9,23.9,0,0,1-23.1-29.3A16.1,16.1,0,0,0,128.6,24H128A104.1,104.1,0,0,0,24,128.7a104,104,0,0,0,208-1.3h0A15.7,15.7,0,0,0,225.9,114.9Zm-35.7,75.4c-33.9,33.8-89.3,34.1-123.5.8A88,88,0,0,1,128,40h.6a39.9,39.9,0,0,0,38.6,48.8A39.9,39.9,0,0,0,216,127.4h0A87.6,87.6,0,0,1,190.2,190.3Zm-25.7-26.8a12,12,0,1,1-17,0A12,12,0,0,1,164.5,163.5Zm-64-8a12,12,0,1,1-17,0A12,12,0,0,1,100.5,155.5Zm-25-39a12,12,0,1,1,17,0A12,12,0,0,1,75.5,116.5Zm69,16a12,12,0,1,1,0-17A12,12,0,0,1,144.5,132.5Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M225.9,114.9a15.8,15.8,0,0,0-13.4-3.1,23.9,23.9,0,0,1-29.3-23.1,16,16,0,0,0-15.9-15.9,23.9,23.9,0,0,1-23.1-29.3A16.1,16.1,0,0,0,128.6,24H128A104.1,104.1,0,0,0,24,128.7a104,104,0,0,0,208-1.3h0A15.7,15.7,0,0,0,225.9,114.9ZM75.5,99.5a12,12,0,1,1,0,17A12,12,0,0,1,75.5,99.5Zm25,73a12,12,0,1,1,0-17A12,12,0,0,1,100.5,172.5Zm27-40a12,12,0,1,1,17,0A12,12,0,0,1,127.5,132.5Zm37,48a12,12,0,1,1,0-17A12,12,0,0,1,164.5,180.5Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M224.7,116.5a13.5,13.5,0,0,0-11.8-2.7,26,26,0,0,1-31.7-25.1,14,14,0,0,0-13.9-13.9,26,26,0,0,1-25.1-31.7,13.5,13.5,0,0,0-2.7-11.8A13.9,13.9,0,0,0,128.6,26,102.1,102.1,0,0,0,26,128.7a102,102,0,0,0,204-1.3A13.9,13.9,0,0,0,224.7,116.5Zm-33.1,75.2c-34.6,34.5-91.3,34.9-126.3.8A90,90,0,0,1,128,38h.5a1.9,1.9,0,0,1,1.6.8,1.7,1.7,0,0,1,.4,1.7,38,38,0,0,0,36.7,46.3,2,2,0,0,1,2,2,38,38,0,0,0,46.3,36.7,1.7,1.7,0,0,1,1.7.4,1.8,1.8,0,0,1,.8,1.5A89.6,89.6,0,0,1,191.6,191.7Zm-28.5-26.8a10,10,0,1,1-14.2,0A10.1,10.1,0,0,1,163.1,164.9Zm-64-8a10,10,0,1,1-14.2,0A10.1,10.1,0,0,1,99.1,156.9ZM76.9,115.1a10,10,0,1,1,14.2,0A10.1,10.1,0,0,1,76.9,115.1Zm66.2,16a10,10,0,1,1,0-14.2A10.1,10.1,0,0,1,143.1,131.1Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M223.5,118a12.3,12.3,0,0,0-10.1-2.3,28,28,0,0,1-34.2-27,12,12,0,0,0-11.9-11.9,28,28,0,0,1-27-34.2A12,12,0,0,0,128.6,28H128a100,100,0,1,0,100,99.4A12.2,12.2,0,0,0,223.5,118ZM193,193.1c-35.4,35.3-93.3,35.7-129.1.9A92,92,0,0,1,128,36h.6a4.2,4.2,0,0,1,3.1,1.5,4.1,4.1,0,0,1,.8,3.4,36.1,36.1,0,0,0,9.6,33.4,35.8,35.8,0,0,0,25.1,10.5,4,4,0,0,1,4,4,35.8,35.8,0,0,0,10.5,25.1,36.1,36.1,0,0,0,33.4,9.6,4.1,4.1,0,0,1,3.4.8,4.2,4.2,0,0,1,1.5,3.1A91.3,91.3,0,0,1,193,193.1Zm-31.3-26.8a8.1,8.1,0,1,1-11.4,0A8.1,8.1,0,0,1,161.7,166.3Zm-64-8a8.1,8.1,0,1,1-11.4,0A8.1,8.1,0,0,1,97.7,158.3Zm-8-44.6a8.1,8.1,0,1,1,0-11.4A8.1,8.1,0,0,1,89.7,113.7Zm52,4.6a8.1,8.1,0,1,1-11.4,0A8.1,8.1,0,0,1,141.7,118.3Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M225.9,114.9a15.8,15.8,0,0,0-13.4-3.1,23.9,23.9,0,0,1-29.3-23.1,16,16,0,0,0-15.9-15.9,23.9,23.9,0,0,1-23.1-29.3A16.1,16.1,0,0,0,128.6,24H128A104.1,104.1,0,0,0,24,128.7a104,104,0,0,0,208-1.3h0A15.7,15.7,0,0,0,225.9,114.9Zm-35.7,75.4c-33.9,33.8-89.3,34.1-123.5.8A88,88,0,0,1,128,40h.6a39.9,39.9,0,0,0,38.6,48.8A39.9,39.9,0,0,0,216,127.4h0A87.6,87.6,0,0,1,190.2,190.3Zm-25.7-26.8a12,12,0,1,1-17,0A12,12,0,0,1,164.5,163.5Zm-64-8a12,12,0,1,1-17,0A12,12,0,0,1,100.5,155.5Zm-25-39a12,12,0,1,1,17,0A12,12,0,0,1,75.5,116.5Zm69,16a12,12,0,1,1,0-17A12,12,0,0,1,144.5,132.5Z" />
        </>
      )}
    </svg>
  );
}

export function PhCookingPot(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M76,40V16a12,12,0,0,1,24,0V40a12,12,0,0,1-24,0Zm52,12a12,12,0,0,0,12-12V16a12,12,0,0,0-24,0V40A12,12,0,0,0,128,52Zm40,0a12,12,0,0,0,12-12V16a12,12,0,0,0-24,0V40A12,12,0,0,0,168,52Zm83.2,53.6L224,126v58a36,36,0,0,1-36,36H68a36,36,0,0,1-36-36V126L4.8,105.6A12,12,0,0,1,19.2,86.4L32,96V88A20.1,20.1,0,0,1,52,68H204a20.1,20.1,0,0,1,20,20v8l12.8-9.6a12,12,0,0,1,14.4,19.2ZM200,92H56v92a12,12,0,0,0,12,12H188a12,12,0,0,0,12-12Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M216,88v96a23.9,23.9,0,0,1-24,24H64a23.9,23.9,0,0,1-24-24V88a8,8,0,0,1,8-8H208A8,8,0,0,1,216,88Z"
            opacity="0.2"
          />
          <path d="M88,48V16a8,8,0,0,1,16,0V48a8,8,0,0,1-16,0Zm40,8a8,8,0,0,0,8-8V16a8,8,0,0,0-16,0V48A8,8,0,0,0,128,56Zm32,0a8,8,0,0,0,8-8V16a8,8,0,0,0-16,0V48A8,8,0,0,0,160,56Zm92.8,46.4L224,124v60a32.1,32.1,0,0,1-32,32H64a32.1,32.1,0,0,1-32-32V124L3.2,102.4a8,8,0,0,1,9.6-12.8L32,104V88A16,16,0,0,1,48,72H208a16,16,0,0,1,16,16v16l19.2-14.4a8,8,0,0,1,9.6,12.8ZM208,88H48v96a16,16,0,0,0,16,16H192a16,16,0,0,0,16-16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M252.8,102.4,224,124v60a32.1,32.1,0,0,1-32,32H64a32.1,32.1,0,0,1-32-32V124L3.2,102.4a8,8,0,0,1,9.6-12.8L32,104V88A16,16,0,0,1,48,72H208a16,16,0,0,1,16,16v16l19.2-14.4a8,8,0,0,1,9.6,12.8ZM160,56a8,8,0,0,0,8-8V16a8,8,0,0,0-16,0V48A8,8,0,0,0,160,56Zm-32,0a8,8,0,0,0,8-8V16a8,8,0,0,0-16,0V48A8,8,0,0,0,128,56ZM96,56a8,8,0,0,0,8-8V16a8,8,0,0,0-16,0V48A8,8,0,0,0,96,56Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M90,48V16a6,6,0,0,1,12,0V48a6,6,0,0,1-12,0Zm38,6a6,6,0,0,0,6-6V16a6,6,0,0,0-12,0V48A6,6,0,0,0,128,54Zm32,0a6,6,0,0,0,6-6V16a6,6,0,0,0-12,0V48A6,6,0,0,0,160,54Zm91.6,46.8L222,123v61a30.1,30.1,0,0,1-30,30H64a30.1,30.1,0,0,1-30-30V123L4.4,100.8a6,6,0,0,1,7.2-9.6L34,108V88A14,14,0,0,1,48,74H208a14,14,0,0,1,14,14v20l22.4-16.8a6,6,0,0,1,7.2,9.6ZM210,88a2,2,0,0,0-2-2H48a2,2,0,0,0-2,2v96a18.1,18.1,0,0,0,18,18H192a18.1,18.1,0,0,0,18-18Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M92,48V16a4,4,0,0,1,8,0V48a4,4,0,0,1-8,0Zm36,4a4,4,0,0,0,4-4V16a4,4,0,0,0-8,0V48A4,4,0,0,0,128,52Zm32,0a4,4,0,0,0,4-4V16a4,4,0,0,0-8,0V48A4,4,0,0,0,160,52Zm90.4,47.2L220,122v62a28.1,28.1,0,0,1-28,28H64a28.1,28.1,0,0,1-28-28V122L5.6,99.2a4,4,0,0,1,4.8-6.4L36,112V88A12,12,0,0,1,48,76H208a12,12,0,0,1,12,12v24l25.6-19.2a4,4,0,1,1,4.8,6.4ZM212,88a4,4,0,0,0-4-4H48a4,4,0,0,0-4,4v96a20.1,20.1,0,0,0,20,20H192a20.1,20.1,0,0,0,20-20Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M88,48V16a8,8,0,0,1,16,0V48a8,8,0,0,1-16,0Zm40,8a8,8,0,0,0,8-8V16a8,8,0,0,0-16,0V48A8,8,0,0,0,128,56Zm32,0a8,8,0,0,0,8-8V16a8,8,0,0,0-16,0V48A8,8,0,0,0,160,56Zm92.8,46.4L224,124v60a32.1,32.1,0,0,1-32,32H64a32.1,32.1,0,0,1-32-32V124L3.2,102.4a8,8,0,0,1,9.6-12.8L32,104V88A16,16,0,0,1,48,72H208a16,16,0,0,1,16,16v16l19.2-14.4a8,8,0,0,1,9.6,12.8ZM208,88H48v96a16,16,0,0,0,16,16H192a16,16,0,0,0,16-16Z" />
        </>
      )}
    </svg>
  );
}

export function PhCopySimple(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M232,36V176a12,12,0,0,1-24,0V48H80a12,12,0,0,1,0-24H220A12,12,0,0,1,232,36ZM192,76V216a12,12,0,0,1-12,12H40a12,12,0,0,1-12-12V76A12,12,0,0,1,40,64H180A12,12,0,0,1,192,76ZM168,88H52V204H168Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect x="39.9" y="72" width="144" height="144" />
          </g>
          <path d="M216,32H71.9a8,8,0,0,0,0,16H208V184a8,8,0,0,0,16,0V40A8,8,0,0,0,216,32Z" />
          <path d="M184,64H39.9a8,8,0,0,0-8,8V216a8,8,0,0,0,8,8H184a8,8,0,0,0,8-8V72A8,8,0,0,0,184,64Zm-8,144H47.9V80H176Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M224,40V184a8,8,0,0,1-16,0V48H72a8,8,0,0,1,0-16H216A8,8,0,0,1,224,40ZM184,64H40a8,8,0,0,0-8,8V216a8,8,0,0,0,8,8H184a8,8,0,0,0,8-8V72A8,8,0,0,0,184,64Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M222,40V184a6,6,0,0,1-12,0V46H72a6,6,0,0,1,0-12H216A6,6,0,0,1,222,40ZM190,72V216a6,6,0,0,1-6,6H40a6,6,0,0,1-6-6V72a6,6,0,0,1,6-6H184A6,6,0,0,1,190,72Zm-12,6H46V210H178Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M220,40V184a4,4,0,0,1-8,0V44H72a4,4,0,0,1,0-8H216A4,4,0,0,1,220,40ZM188,72V216a4,4,0,0,1-4,4H40a4,4,0,0,1-4-4V72a4,4,0,0,1,4-4H184A4,4,0,0,1,188,72Zm-8,4H44V212H180Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M224,40V184a8,8,0,0,1-16,0V48H72a8,8,0,0,1,0-16H216A8,8,0,0,1,224,40ZM192,72V216a8,8,0,0,1-8,8H40a8,8,0,0,1-8-8V72a8,8,0,0,1,8-8H184A8,8,0,0,1,192,72Zm-16,8H48V208H176Z" />
        </>
      )}
    </svg>
  );
}

export function PhCopy(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216,28H88A12,12,0,0,0,76,40V76H40A12,12,0,0,0,28,88V216a12,12,0,0,0,12,12H168a12,12,0,0,0,12-12V180h36a12,12,0,0,0,12-12V40A12,12,0,0,0,216,28ZM156,204H52V100H156Zm48-48H180V88a12,12,0,0,0-12-12H100V52H204Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M216,40V168H168V88H88V40Z" opacity="0.2" />
          <path d="M216,32H88a8,8,0,0,0-8,8V80H40a8,8,0,0,0-8,8V216a8,8,0,0,0,8,8H168a8,8,0,0,0,8-8V176h40a8,8,0,0,0,8-8V40A8,8,0,0,0,216,32ZM160,208H48V96H160Zm48-48H176V88a8,8,0,0,0-8-8H96V48H208Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,32H88a8,8,0,0,0-8,8V80H40a8,8,0,0,0-8,8V216a8,8,0,0,0,8,8H168a8,8,0,0,0,8-8V176h40a8,8,0,0,0,8-8V40A8,8,0,0,0,216,32Zm-8,128H176V88a8,8,0,0,0-8-8H96V48H208Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M216,34H88a6,6,0,0,0-6,6V82H40a6,6,0,0,0-6,6V216a6,6,0,0,0,6,6H168a6,6,0,0,0,6-6V174h42a6,6,0,0,0,6-6V40A6,6,0,0,0,216,34ZM162,210H46V94H162Zm48-48H174V88a6,6,0,0,0-6-6H94V46H210Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M216,36H88a4,4,0,0,0-4,4V84H40a4,4,0,0,0-4,4V216a4,4,0,0,0,4,4H168a4,4,0,0,0,4-4V172h44a4,4,0,0,0,4-4V40A4,4,0,0,0,216,36ZM164,212H44V92H164Zm48-48H172V88a4,4,0,0,0-4-4H92V44H212Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,32H88a8,8,0,0,0-8,8V80H40a8,8,0,0,0-8,8V216a8,8,0,0,0,8,8H168a8,8,0,0,0,8-8V176h40a8,8,0,0,0,8-8V40A8,8,0,0,0,216,32ZM160,208H48V96H160Zm48-48H176V88a8,8,0,0,0-8-8H96V48H208Z" />
        </>
      )}
    </svg>
  );
}

export function PhCopyleft(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,20A108,108,0,1,0,236,128,108.1,108.1,0,0,0,128,20Zm0,192a84,84,0,1,1,84-84A84.1,84.1,0,0,1,128,212Zm52-84a52,52,0,0,1-93.6,31.2,12,12,0,1,1,19.2-14.4A27.8,27.8,0,0,0,128,156a28,28,0,0,0,0-56,27.8,27.8,0,0,0-22.4,11.2A12,12,0,1,1,86.4,96.8,52,52,0,0,1,180,128Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M224,128a96,96,0,1,1-96-96A96,96,0,0,1,224,128Z"
            opacity="0.2"
          />
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm48-88a48,48,0,0,1-86.4,28.8,8,8,0,1,1,12.8-9.6,32,32,0,1,0,0-38.4,8.1,8.1,0,0,1-11.2,1.6,8,8,0,0,1-1.6-11.2A48,48,0,0,1,176,128Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,56a72,72,0,1,0,72,72A72.1,72.1,0,0,0,128,56Zm0,120a47.7,47.7,0,0,1-38.4-19.2,8,8,0,1,1,12.8-9.6,32,32,0,1,0,0-38.4,8.1,8.1,0,0,1-11.2,1.6,8,8,0,0,1-1.6-11.2A48,48,0,1,1,128,176Zm0-156A108,108,0,1,0,236,128,108.1,108.1,0,0,0,128,20Zm0,196a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,26A102,102,0,1,0,230,128,102.2,102.2,0,0,0,128,26Zm0,192a90,90,0,1,1,90-90A90.1,90.1,0,0,1,128,218Zm46-90a46,46,0,0,1-82.8,27.6,6,6,0,0,1,1.2-8.4,6,6,0,0,1,8.4,1.2,34,34,0,1,0,0-40.8,6,6,0,1,1-9.6-7.2A46,46,0,0,1,174,128Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,28A100,100,0,1,0,228,128,100.2,100.2,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm44-92a44,44,0,0,1-79.2,26.4,4,4,0,0,1,6.4-4.8,36,36,0,1,0,0-43.2,4,4,0,0,1-6.4-4.8A44,44,0,0,1,172,128Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm48-88a48,48,0,0,1-86.4,28.8,8,8,0,1,1,12.8-9.6,32,32,0,1,0,0-38.4,8.1,8.1,0,0,1-11.2,1.6,8,8,0,0,1-1.6-11.2A48,48,0,0,1,176,128Z" />
        </>
      )}
    </svg>
  );
}

export function PhCopyright(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,20A108,108,0,1,0,236,128,108.1,108.1,0,0,0,128,20Zm0,192a84,84,0,1,1,84-84A84.1,84.1,0,0,1,128,212Zm41.6-52.8a52,52,0,1,1,0-62.4,12,12,0,0,1-19.2,14.4A27.8,27.8,0,0,0,128,100a28,28,0,0,0,0,56,27.8,27.8,0,0,0,22.4-11.2,12,12,0,0,1,19.2,14.4Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="128" cy="128" r="96" />
          </g>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216ZM96,128a32,32,0,0,0,57.6,19.2,8.1,8.1,0,0,1,11.2-1.6,8,8,0,0,1,1.6,11.2,48,48,0,1,1,0-57.6,8,8,0,0,1-1.6,11.2,8.1,8.1,0,0,1-11.2-1.6A32,32,0,0,0,96,128Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,20A108,108,0,1,0,236,128,108.1,108.1,0,0,0,128,20Zm0,196a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm0-160a72,72,0,1,0,72,72A72.1,72.1,0,0,0,128,56Zm0,104a31.8,31.8,0,0,0,25.6-12.8,8.1,8.1,0,0,1,11.2-1.6,8,8,0,0,1,1.6,11.2,48,48,0,1,1,0-57.6,8,8,0,0,1-1.6,11.2,8.1,8.1,0,0,1-11.2-1.6A32,32,0,1,0,128,160Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,26A102,102,0,1,0,230,128,102.2,102.2,0,0,0,128,26Zm0,192a90,90,0,1,1,90-90A90.1,90.1,0,0,1,128,218ZM94,128a34,34,0,0,0,61.2,20.4,6,6,0,0,1,9.6,7.2,46,46,0,1,1,0-55.2,6,6,0,0,1-1.2,8.4,6,6,0,0,1-8.4-1.2A34,34,0,0,0,94,128Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,28A100,100,0,1,0,228,128,100.2,100.2,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220ZM92,128a36,36,0,0,0,64.8,21.6,4,4,0,1,1,6.4,4.8,44,44,0,1,1,0-52.8,4,4,0,1,1-6.4,4.8A35.7,35.7,0,0,0,128,92,36,36,0,0,0,92,128Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216ZM96,128a32,32,0,0,0,57.6,19.2,8.1,8.1,0,0,1,11.2-1.6,8,8,0,0,1,1.6,11.2,48,48,0,1,1,0-57.6,8,8,0,0,1-1.6,11.2,8.1,8.1,0,0,1-11.2-1.6A32,32,0,0,0,96,128Z" />
        </>
      )}
    </svg>
  );
}

export function PhCornersIn(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M148,96V48a12,12,0,0,1,24,0V84h36a12,12,0,0,1,0,24H160A12,12,0,0,1,148,96ZM96,148H48a12,12,0,0,0,0,24H84v36a12,12,0,0,0,24,0V160A12,12,0,0,0,96,148Zm112,0H160a12,12,0,0,0-12,12v48a12,12,0,0,0,24,0V172h36a12,12,0,0,0,0-24ZM96,36A12,12,0,0,0,84,48V84H48a12,12,0,0,0,0,24H96a12,12,0,0,0,12-12V48A12,12,0,0,0,96,36Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M152,96V48a8,8,0,0,1,16,0V88h40a8,8,0,0,1,0,16H160A8,8,0,0,1,152,96ZM96,152H48a8,8,0,0,0,0,16H88v40a8,8,0,0,0,16,0V160A8,8,0,0,0,96,152Zm112,0H160a8,8,0,0,0-8,8v48a8,8,0,0,0,16,0V168h40a8,8,0,0,0,0-16ZM96,40a8,8,0,0,0-8,8V88H48a8,8,0,0,0,0,16H96a8,8,0,0,0,8-8V48A8,8,0,0,0,96,40Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M152,96V48a8,8,0,0,1,4.9-7.4,8.4,8.4,0,0,1,8.8,1.7l48,48a8.4,8.4,0,0,1,1.7,8.8A8,8,0,0,1,208,104H160A8,8,0,0,1,152,96ZM96,152H48a8,8,0,0,0-7.4,4.9,8.4,8.4,0,0,0,1.7,8.8l48,48A8.3,8.3,0,0,0,96,216a8.5,8.5,0,0,0,3.1-.6A8,8,0,0,0,104,208V160A8,8,0,0,0,96,152ZM99.1,40.6a8.4,8.4,0,0,0-8.8,1.7l-48,48a8.4,8.4,0,0,0-1.7,8.8A8,8,0,0,0,48,104H96a8,8,0,0,0,8-8V48A8,8,0,0,0,99.1,40.6ZM208,152H160a8,8,0,0,0-8,8v48a8,8,0,0,0,4.9,7.4,8.5,8.5,0,0,0,3.1.6,8.3,8.3,0,0,0,5.7-2.3l48-48a8.4,8.4,0,0,0,1.7-8.8A8,8,0,0,0,208,152Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M154,96V48a6,6,0,0,1,12,0V90h42a6,6,0,0,1,0,12H160A6,6,0,0,1,154,96ZM96,154H48a6,6,0,0,0,0,12H90v42a6,6,0,0,0,12,0V160A6,6,0,0,0,96,154Zm112,0H160a6,6,0,0,0-6,6v48a6,6,0,0,0,12,0V166h42a6,6,0,0,0,0-12ZM96,42a6,6,0,0,0-6,6V90H48a6,6,0,0,0,0,12H96a6,6,0,0,0,6-6V48A6,6,0,0,0,96,42Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M156,96V48a4,4,0,0,1,8,0V92h44a4,4,0,0,1,0,8H160A4,4,0,0,1,156,96ZM96,156H48a4,4,0,0,0,0,8H92v44a4,4,0,0,0,8,0V160A4,4,0,0,0,96,156Zm112,0H160a4,4,0,0,0-4,4v48a4,4,0,0,0,8,0V164h44a4,4,0,0,0,0-8ZM96,44a4,4,0,0,0-4,4V92H48a4,4,0,0,0,0,8H96a4,4,0,0,0,4-4V48A4,4,0,0,0,96,44Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M152,96V48a8,8,0,0,1,16,0V88h40a8,8,0,0,1,0,16H160A8,8,0,0,1,152,96ZM96,152H48a8,8,0,0,0,0,16H88v40a8,8,0,0,0,16,0V160A8,8,0,0,0,96,152Zm112,0H160a8,8,0,0,0-8,8v48a8,8,0,0,0,16,0V168h40a8,8,0,0,0,0-16ZM96,40a8,8,0,0,0-8,8V88H48a8,8,0,0,0,0,16H96a8,8,0,0,0,8-8V48A8,8,0,0,0,96,40Z" />
        </>
      )}
    </svg>
  );
}

export function PhCornersOut(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M220,48V88a12,12,0,0,1-24,0V60H168a12,12,0,0,1,0-24h40A12,12,0,0,1,220,48ZM88,196H60V168a12,12,0,0,0-24,0v40a12,12,0,0,0,12,12H88a12,12,0,0,0,0-24Zm120-40a12,12,0,0,0-12,12v28H168a12,12,0,0,0,0,24h40a12,12,0,0,0,12-12V168A12,12,0,0,0,208,156ZM88,36H48A12,12,0,0,0,36,48V88a12,12,0,0,0,24,0V60H88a12,12,0,0,0,0-24Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M216,48V88a8,8,0,0,1-16,0V56H168a8,8,0,0,1,0-16h40A8,8,0,0,1,216,48ZM88,200H56V168a8,8,0,0,0-16,0v40a8,8,0,0,0,8,8H88a8,8,0,0,0,0-16Zm120-40a8,8,0,0,0-8,8v32H168a8,8,0,0,0,0,16h40a8,8,0,0,0,8-8V168A8,8,0,0,0,208,160ZM88,40H48a8,8,0,0,0-8,8V88a8,8,0,0,0,16,0V56H88a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,48V88a8,8,0,0,1-4.9,7.4,8.5,8.5,0,0,1-3.1.6,8.3,8.3,0,0,1-5.7-2.3l-40-40a8.4,8.4,0,0,1-1.7-8.8A8,8,0,0,1,168,40h40A8,8,0,0,1,216,48ZM53.7,162.3a8.4,8.4,0,0,0-8.8-1.7A8,8,0,0,0,40,168v40a8,8,0,0,0,8,8H88a8,8,0,0,0,7.4-4.9,8.4,8.4,0,0,0-1.7-8.8Zm157.4-1.7a8.4,8.4,0,0,0-8.8,1.7l-40,40a8.4,8.4,0,0,0-1.7,8.8A8,8,0,0,0,168,216h40a8,8,0,0,0,8-8V168A8,8,0,0,0,211.1,160.6ZM88,40H48a8,8,0,0,0-8,8V88a8,8,0,0,0,4.9,7.4A8.5,8.5,0,0,0,48,96a8.3,8.3,0,0,0,5.7-2.3l40-40a8.4,8.4,0,0,0,1.7-8.8A8,8,0,0,0,88,40Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M214,48V88a6,6,0,0,1-12,0V54H168a6,6,0,0,1,0-12h40A6,6,0,0,1,214,48ZM88,202H54V168a6,6,0,0,0-12,0v40a6,6,0,0,0,6,6H88a6,6,0,0,0,0-12Zm120-40a6,6,0,0,0-6,6v34H168a6,6,0,0,0,0,12h40a6,6,0,0,0,6-6V168A6,6,0,0,0,208,162ZM88,42H48a6,6,0,0,0-6,6V88a6,6,0,0,0,12,0V54H88a6,6,0,0,0,0-12Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M212,48V88a4,4,0,0,1-8,0V52H168a4,4,0,0,1,0-8h40A4,4,0,0,1,212,48ZM88,204H52V168a4,4,0,0,0-8,0v40a4,4,0,0,0,4,4H88a4,4,0,0,0,0-8Zm120-40a4,4,0,0,0-4,4v36H168a4,4,0,0,0,0,8h40a4,4,0,0,0,4-4V168A4,4,0,0,0,208,164ZM88,44H48a4,4,0,0,0-4,4V88a4,4,0,0,0,8,0V52H88a4,4,0,0,0,0-8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,48V88a8,8,0,0,1-16,0V56H168a8,8,0,0,1,0-16h40A8,8,0,0,1,216,48ZM88,200H56V168a8,8,0,0,0-16,0v40a8,8,0,0,0,8,8H88a8,8,0,0,0,0-16Zm120-40a8,8,0,0,0-8,8v32H168a8,8,0,0,0,0,16h40a8,8,0,0,0,8-8V168A8,8,0,0,0,208,160ZM88,40H48a8,8,0,0,0-8,8V88a8,8,0,0,0,16,0V56H88a8,8,0,0,0,0-16Z" />
        </>
      )}
    </svg>
  );
}

export function PhCpu(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M156,88H100a12,12,0,0,0-12,12v56a12,12,0,0,0,12,12h56a12,12,0,0,0,12-12V100A12,12,0,0,0,156,88Zm-12,56H112V112h32Zm88-4H220V116h12a12,12,0,0,0,0-24H220V56a20.1,20.1,0,0,0-20-20H164V24a12,12,0,0,0-24,0V36H116V24a12,12,0,0,0-24,0V36H56A20.1,20.1,0,0,0,36,56V92H24a12,12,0,0,0,0,24H36v24H24a12,12,0,0,0,0,24H36v36a20.1,20.1,0,0,0,20,20H92v12a12,12,0,0,0,24,0V220h24v12a12,12,0,0,0,24,0V220h36a20.1,20.1,0,0,0,20-20V164h12a12,12,0,0,0,0-24Zm-36,56H60V60H196Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M200,48H56a8,8,0,0,0-8,8V200a8,8,0,0,0,8,8H200a8,8,0,0,0,8-8V56A8,8,0,0,0,200,48ZM156,156H100V100h56Z"
            opacity="0.2"
          />
          <path d="M156,92H100a8,8,0,0,0-8,8v56a8,8,0,0,0,8,8h56a8,8,0,0,0,8-8V100A8,8,0,0,0,156,92Zm-8,56H108V108h40Zm84-4H216V112h16a8,8,0,0,0,0-16H216V56a16,16,0,0,0-16-16H160V24a8,8,0,0,0-16,0V40H112V24a8,8,0,0,0-16,0V40H56A16,16,0,0,0,40,56V96H24a8,8,0,0,0,0,16H40v32H24a8,8,0,0,0,0,16H40v40a16,16,0,0,0,16,16H96v16a8,8,0,0,0,16,0V216h32v16a8,8,0,0,0,16,0V216h40a16,16,0,0,0,16-16V160h16a8,8,0,0,0,0-16Zm-32,56H56V56H200v47.9h0v47.8h0V200Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M232,144H216V112h16a8,8,0,0,0,0-16H216V56a16,16,0,0,0-16-16H160V24a8,8,0,0,0-16,0V40H112V24a8,8,0,0,0-16,0V40H56A16,16,0,0,0,40,56V96H24a8,8,0,0,0,0,16H40v32H24a8,8,0,0,0,0,16H40v40a16,16,0,0,0,16,16H96v16a8,8,0,0,0,16,0V216h32v16a8,8,0,0,0,16,0V216h40a16,16,0,0,0,16-16V160h16a8,8,0,0,0,0-16Zm-68,12a8,8,0,0,1-8,8H100a8,8,0,0,1-8-8V100a8,8,0,0,1,8-8h56a8,8,0,0,1,8,8Zm-56-48h40v40H108Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M156,94H100a6,6,0,0,0-6,6v56a6,6,0,0,0,6,6h56a6,6,0,0,0,6-6V100A6,6,0,0,0,156,94Zm-6,56H106V106h44Zm82-4H214V110h18a6,6,0,0,0,0-12H214V56a14,14,0,0,0-14-14H158V24a6,6,0,0,0-12,0V42H110V24a6,6,0,0,0-12,0V42H56A14,14,0,0,0,42,56V98H24a6,6,0,0,0,0,12H42v36H24a6,6,0,0,0,0,12H42v42a14,14,0,0,0,14,14H98v18a6,6,0,0,0,12,0V214h36v18a6,6,0,0,0,12,0V214h42a14,14,0,0,0,14-14V158h18a6,6,0,0,0,0-12Zm-30,54a2,2,0,0,1-2,2H56a2,2,0,0,1-2-2V56a2,2,0,0,1,2-2H200a2,2,0,0,1,2,2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M156,96H100a4,4,0,0,0-4,4v56a4,4,0,0,0,4,4h56a4,4,0,0,0,4-4V100A4,4,0,0,0,156,96Zm-4,56H104V104h48Zm80-4H212V108h20a4,4,0,0,0,0-8H212V56a12,12,0,0,0-12-12H156V24a4,4,0,0,0-8,0V44H108V24a4,4,0,0,0-8,0V44H56A12,12,0,0,0,44,56v44H24a4,4,0,0,0,0,8H44v40H24a4,4,0,0,0,0,8H44v44a12,12,0,0,0,12,12h44v20a4,4,0,0,0,8,0V212h40v20a4,4,0,0,0,8,0V212h44a12,12,0,0,0,12-12V156h20a4,4,0,0,0,0-8Zm-28,52a4,4,0,0,1-4,4H56a4,4,0,0,1-4-4V56a4,4,0,0,1,4-4H200a4,4,0,0,1,4,4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M156,92H100a8,8,0,0,0-8,8v56a8,8,0,0,0,8,8h56a8,8,0,0,0,8-8V100A8,8,0,0,0,156,92Zm-8,56H108V108h40Zm84-4H216V112h16a8,8,0,0,0,0-16H216V56a16,16,0,0,0-16-16H160V24a8,8,0,0,0-16,0V40H112V24a8,8,0,0,0-16,0V40H56A16,16,0,0,0,40,56V96H24a8,8,0,0,0,0,16H40v32H24a8,8,0,0,0,0,16H40v40a16,16,0,0,0,16,16H96v16a8,8,0,0,0,16,0V216h32v16a8,8,0,0,0,16,0V216h40a16,16,0,0,0,16-16V160h16a8,8,0,0,0,0-16Zm-32,56H56V56H200v47.9h0v47.8h0V200Z" />
        </>
      )}
    </svg>
  );
}

export function PhCreditCard(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M224,44H32A20.1,20.1,0,0,0,12,64V96.9h0v95a20.1,20.1,0,0,0,20,20H224a20.1,20.1,0,0,0,20-20V64A20.1,20.1,0,0,0,224,44Zm-4,24V84.9H36V68ZM36,188V108.9H220V188Zm172-24a12,12,0,0,1-12,12H164a12,12,0,0,1,0-24h32A12,12,0,0,1,208,164Zm-72,0a12,12,0,0,1-12,12H108a12,12,0,0,1,0-24h16A12,12,0,0,1,136,164Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M24,96.9H232V192a8,8,0,0,1-8,8H32a8,8,0,0,1-8-8Z" />
          </g>
          <path d="M224,48H32A16,16,0,0,0,16,64V96.9h0v95a16,16,0,0,0,16,16H224a16,16,0,0,0,16-16V64A16,16,0,0,0,224,48Zm0,16V88.9H32V64Zm0,128H32V104.9H224V192Z" />
          <path d="M200,160H168a8,8,0,0,0,0,16h32a8,8,0,0,0,0-16Z" />
          <path d="M136,160H120a8,8,0,0,0,0,16h16a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M224,48H32A16,16,0,0,0,16,64V96.9h0v95a16,16,0,0,0,16,16H224a16,16,0,0,0,16-16V64A16,16,0,0,0,224,48ZM136,176H120a8,8,0,0,1,0-16h16a8,8,0,0,1,0,16Zm64,0H168a8,8,0,0,1,0-16h32a8,8,0,0,1,0,16ZM32,88.9V64H224V88.9Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M224,50H32A14,14,0,0,0,18,64V96.9h0v95a14,14,0,0,0,14,14H224a14,14,0,0,0,14-14V64A14,14,0,0,0,224,50ZM32,62H224a2,2,0,0,1,2,2V90.9H30V64A2,2,0,0,1,32,62ZM224,194H32a2,2,0,0,1-2-2V102.9H226V192A2,2,0,0,1,224,194Zm-18-26a6,6,0,0,1-6,6H168a6,6,0,0,1,0-12h32A6,6,0,0,1,206,168Zm-64,0a6,6,0,0,1-6,6H120a6,6,0,0,1,0-12h16A6,6,0,0,1,142,168Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M224,52H32A12,12,0,0,0,20,64V96.8h0V192a12,12,0,0,0,12,12H224a12,12,0,0,0,12-12V64A12,12,0,0,0,224,52ZM32,60H224a4,4,0,0,1,4,4V92.9H28V64A4,4,0,0,1,32,60ZM224,196H32a4,4,0,0,1-4-4V100.9H228V192A4,4,0,0,1,224,196Zm-20-28a4,4,0,0,1-4,4H168a4,4,0,0,1,0-8h32A4,4,0,0,1,204,168Zm-64,0a4,4,0,0,1-4,4H120a4,4,0,0,1,0-8h16A4,4,0,0,1,140,168Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M224,48H32A16,16,0,0,0,16,64V96.9h0v95a16,16,0,0,0,16,16H224a16,16,0,0,0,16-16V64A16,16,0,0,0,224,48Zm0,16V88.9H32V64Zm0,128H32V104.9H224V192Zm-16-24a8,8,0,0,1-8,8H168a8,8,0,0,1,0-16h32A8,8,0,0,1,208,168Zm-64,0a8,8,0,0,1-8,8H120a8,8,0,0,1,0-16h16A8,8,0,0,1,144,168Z" />
        </>
      )}
    </svg>
  );
}

export function PhCrop(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M244,192a12,12,0,0,1-12,12H204v28a12,12,0,0,1-24,0V204H64a12,12,0,0,1-12-12V76H24a12,12,0,0,1,0-24H52V24a12,12,0,0,1,24,0V180H232A12,12,0,0,1,244,192ZM104,76h76v76a12,12,0,0,0,24,0V64a12,12,0,0,0-12-12H104a12,12,0,0,0,0,24Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M240,192a8,8,0,0,1-8,8H200v32a8,8,0,0,1-16,0V200H64a8,8,0,0,1-8-8V72H24a8,8,0,0,1,0-16H56V24a8,8,0,0,1,16,0V184H232A8,8,0,0,1,240,192ZM96,72h88v88a8,8,0,0,0,16,0V64a8,8,0,0,0-8-8H96a8,8,0,0,0,0,16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M88,64a8,8,0,0,1,8-8h96a8,8,0,0,1,8,8v96a8,8,0,0,1-16,0V72H96A8,8,0,0,1,88,64ZM232,184H72V24a8,8,0,0,0-16,0V56H24a8,8,0,0,0,0,16H56V192a8,8,0,0,0,8,8H184v32a8,8,0,0,0,16,0V200h32a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M238,192a6,6,0,0,1-6,6H198v34a6,6,0,0,1-12,0V198H64a6,6,0,0,1-6-6V70H24a6,6,0,0,1,0-12H58V24a6,6,0,0,1,12,0V186H232A6,6,0,0,1,238,192ZM96,70h90v90a6,6,0,0,0,12,0V64a6,6,0,0,0-6-6H96a6,6,0,0,0,0,12Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M236,192a4,4,0,0,1-4,4H196v36a4,4,0,0,1-8,0V196H64a4,4,0,0,1-4-4V68H24a4,4,0,0,1,0-8H60V24a4,4,0,0,1,8,0V188H232A4,4,0,0,1,236,192ZM96,68h92v92a4,4,0,0,0,8,0V64a4,4,0,0,0-4-4H96a4,4,0,0,0,0,8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M240,192a8,8,0,0,1-8,8H200v32a8,8,0,0,1-16,0V200H64a8,8,0,0,1-8-8V72H24a8,8,0,0,1,0-16H56V24a8,8,0,0,1,16,0V184H232A8,8,0,0,1,240,192ZM96,72h88v88a8,8,0,0,0,16,0V64a8,8,0,0,0-8-8H96a8,8,0,0,0,0,16Z" />
        </>
      )}
    </svg>
  );
}

export function PhCrosshairSimple(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm12,183.1V180a12,12,0,0,0-24,0v27.1A80.2,80.2,0,0,1,48.9,140H76a12,12,0,0,0,0-24H48.9A80.2,80.2,0,0,1,116,48.9V76a12,12,0,0,0,24,0V48.9A80.2,80.2,0,0,1,207.1,116H180a12,12,0,0,0,0,24h27.1A80.2,80.2,0,0,1,140,207.1Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="128" cy="128" r="92" />
          </g>
          <path d="M128,28A100,100,0,1,0,228,128,100.2,100.2,0,0,0,128,28Zm8,183.6V180a8,8,0,0,0-16,0v31.6A84.2,84.2,0,0,1,44.4,136H76a8,8,0,0,0,0-16H44.4A84.2,84.2,0,0,1,120,44.4V76a8,8,0,0,0,16,0V44.4A84.2,84.2,0,0,1,211.6,120H180a8,8,0,0,0,0,16h31.6A84.2,84.2,0,0,1,136,211.6Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,28A100,100,0,1,0,228,128,100.2,100.2,0,0,0,128,28Zm8,183.6V180a8,8,0,0,0-16,0v31.6A84.2,84.2,0,0,1,44.4,136H76a8,8,0,0,0,0-16H44.4A84.2,84.2,0,0,1,120,44.4V76a8,8,0,0,0,16,0V44.4A84.2,84.2,0,0,1,211.6,120H180a8,8,0,0,0,0,16h31.6A84.2,84.2,0,0,1,136,211.6Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,30a98,98,0,1,0,98,98A98.2,98.2,0,0,0,128,30Zm6,183.8V180a6,6,0,0,0-12,0v33.8A86.3,86.3,0,0,1,42.2,134H76a6,6,0,0,0,0-12H42.2A86.3,86.3,0,0,1,122,42.2V76a6,6,0,0,0,12,0V42.2A86.3,86.3,0,0,1,213.8,122H180a6,6,0,0,0,0,12h33.8A86.3,86.3,0,0,1,134,213.8Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,32a96,96,0,1,0,96,96A96.2,96.2,0,0,0,128,32Zm4,183.9V180a4,4,0,0,0-8,0v35.9A88.1,88.1,0,0,1,40.1,132H76a4,4,0,0,0,0-8H40.1A88.1,88.1,0,0,1,124,40.1V76a4,4,0,0,0,8,0V40.1A88.1,88.1,0,0,1,215.9,124H180a4,4,0,0,0,0,8h35.9A88.1,88.1,0,0,1,132,215.9Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,28A100,100,0,1,0,228,128,100.2,100.2,0,0,0,128,28Zm8,183.6V180a8,8,0,0,0-16,0v31.6A84.2,84.2,0,0,1,44.4,136H76a8,8,0,0,0,0-16H44.4A84.2,84.2,0,0,1,120,44.4V76a8,8,0,0,0,16,0V44.4A84.2,84.2,0,0,1,211.6,120H180a8,8,0,0,0,0,16h31.6A84.2,84.2,0,0,1,136,211.6Z" />
        </>
      )}
    </svg>
  );
}

export function PhCrosshair(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M236,116h-8.7A100.2,100.2,0,0,0,140,28.7V20a12,12,0,0,0-24,0v8.7A100.2,100.2,0,0,0,28.7,116H20a12,12,0,0,0,0,24h8.7A100.2,100.2,0,0,0,116,227.3V236a12,12,0,0,0,24,0v-8.7A100.2,100.2,0,0,0,227.3,140H236a12,12,0,0,0,0-24Zm-96,87v-3a12,12,0,0,0-24,0v3a76,76,0,0,1-63-63h3a12,12,0,0,0,0-24H53a76,76,0,0,1,63-63v3a12,12,0,0,0,24,0V53a76,76,0,0,1,63,63h-3a12,12,0,0,0,0,24h3A76,76,0,0,1,140,203ZM128,84a44,44,0,1,0,44,44A44,44,0,0,0,128,84Zm0,64a20,20,0,1,1,20-20A20.1,20.1,0,0,1,128,148Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="128" cy="128" r="32" />
          </g>
          <path d="M236,120H223.7A96.2,96.2,0,0,0,136,32.3V20a8,8,0,0,0-16,0V32.3A96.2,96.2,0,0,0,32.3,120H20a8,8,0,0,0,0,16H32.3A96.2,96.2,0,0,0,120,223.7V236a8,8,0,0,0,16,0V223.7A96.2,96.2,0,0,0,223.7,136H236a8,8,0,0,0,0-16ZM136,207.6V196a8,8,0,0,0-16,0v11.6A80.3,80.3,0,0,1,48.4,136H60a8,8,0,0,0,0-16H48.4A80.3,80.3,0,0,1,120,48.4V60a8,8,0,0,0,16,0V48.4A80.3,80.3,0,0,1,207.6,120H196a8,8,0,0,0,0,16h11.6A80.3,80.3,0,0,1,136,207.6Z" />
          <path d="M128,88a40,40,0,1,0,40,40A40,40,0,0,0,128,88Zm0,64a24,24,0,1,1,24-24A24.1,24.1,0,0,1,128,152Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M236,120H223.7A96.2,96.2,0,0,0,136,32.3V20a8,8,0,0,0-16,0V32.3A96.2,96.2,0,0,0,32.3,120H20a8,8,0,0,0,0,16H32.3A96.2,96.2,0,0,0,120,223.7V236a8,8,0,0,0,16,0V223.7A96.2,96.2,0,0,0,223.7,136H236a8,8,0,0,0,0-16Zm-40,16h11.6A80.3,80.3,0,0,1,136,207.6V196a8,8,0,0,0-16,0v11.6A80.3,80.3,0,0,1,48.4,136H60a8,8,0,0,0,0-16H48.4A80.3,80.3,0,0,1,120,48.4V60a8,8,0,0,0,16,0V48.4A80.3,80.3,0,0,1,207.6,120H196a8,8,0,0,0,0,16Zm-28-8a40,40,0,1,1-40-40A40,40,0,0,1,168,128Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M236,122H221.8A94.1,94.1,0,0,0,134,34.2V20a6,6,0,0,0-12,0V34.2A94.1,94.1,0,0,0,34.2,122H20a6,6,0,0,0,0,12H34.2A94.1,94.1,0,0,0,122,221.8V236a6,6,0,0,0,12,0V221.8A94.1,94.1,0,0,0,221.8,134H236a6,6,0,0,0,0-12ZM134,209.8V196a6,6,0,0,0-12,0v13.8A82.3,82.3,0,0,1,46.2,134H60a6,6,0,0,0,0-12H46.2A82.3,82.3,0,0,1,122,46.2V60a6,6,0,0,0,12,0V46.2A82.3,82.3,0,0,1,209.8,122H196a6,6,0,0,0,0,12h13.8A82.3,82.3,0,0,1,134,209.8ZM128,90a38,38,0,1,0,38,38A38,38,0,0,0,128,90Zm0,64a26,26,0,1,1,26-26A26.1,26.1,0,0,1,128,154Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M236,124H219.9A92,92,0,0,0,132,36.1V20a4,4,0,0,0-8,0V36.1A92,92,0,0,0,36.1,124H20a4,4,0,0,0,0,8H36.1A92,92,0,0,0,124,219.9V236a4,4,0,0,0,8,0V219.9A92,92,0,0,0,219.9,132H236a4,4,0,0,0,0-8ZM132,211.9V196a4,4,0,0,0-8,0v15.9A84.1,84.1,0,0,1,44.1,132H60a4,4,0,0,0,0-8H44.1A84.1,84.1,0,0,1,124,44.1V60a4,4,0,0,0,8,0V44.1A84.1,84.1,0,0,1,211.9,124H196a4,4,0,0,0,0,8h15.9A84.1,84.1,0,0,1,132,211.9ZM128,92a36,36,0,1,0,36,36A36,36,0,0,0,128,92Zm0,64a28,28,0,1,1,28-28A28.1,28.1,0,0,1,128,156Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M236,120H223.7A96.2,96.2,0,0,0,136,32.3V20a8,8,0,0,0-16,0V32.3A96.2,96.2,0,0,0,32.3,120H20a8,8,0,0,0,0,16H32.3A96.2,96.2,0,0,0,120,223.7V236a8,8,0,0,0,16,0V223.7A96.2,96.2,0,0,0,223.7,136H236a8,8,0,0,0,0-16ZM136,207.6V196a8,8,0,0,0-16,0v11.6A80.3,80.3,0,0,1,48.4,136H60a8,8,0,0,0,0-16H48.4A80.3,80.3,0,0,1,120,48.4V60a8,8,0,0,0,16,0V48.4A80.3,80.3,0,0,1,207.6,120H196a8,8,0,0,0,0,16h11.6A80.3,80.3,0,0,1,136,207.6ZM128,88a40,40,0,1,0,40,40A40,40,0,0,0,128,88Zm0,64a24,24,0,1,1,24-24A24.1,24.1,0,0,1,128,152Z" />
        </>
      )}
    </svg>
  );
}

export function PhCrownSimple(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M241.3,70.5a19.9,19.9,0,0,0-20.9-2.9l-47.2,21L145.5,38.8a20,20,0,0,0-35,0L82.8,88.6l-47.2-21A19.9,19.9,0,0,0,8,90.4L33.4,198.8A20.1,20.1,0,0,0,53,214.2a18.9,18.9,0,0,0,5.3-.8,262.7,262.7,0,0,1,139.3,0,20,20,0,0,0,24.9-14.6L248,90.4A19.8,19.8,0,0,0,241.3,70.5ZM56.8,193.3Zm143.3-4.1a287.1,287.1,0,0,0-144.2,0L33.2,92.8,76.4,112a19.9,19.9,0,0,0,25.6-8.6l26-46.7,26,46.7a19.9,19.9,0,0,0,25.6,8.6l43.2-19.2Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M236.3,87.7,210.8,196a8.1,8.1,0,0,1-10,5.9,273,273,0,0,0-145.7,0,8.1,8.1,0,0,1-10-5.9L19.7,87.7a8,8,0,0,1,11-9.2L81.3,101a8.1,8.1,0,0,0,10.3-3.4l29.4-53a8,8,0,0,1,14,0l29.4,53a8.1,8.1,0,0,0,10.3,3.4l50.6-22.5A8,8,0,0,1,236.3,87.7Z"
            opacity="0.2"
          />
          <path d="M238.7,73.5A16.1,16.1,0,0,0,222,71.2L171.4,93.7,142,40.7a16.1,16.1,0,0,0-28,0l-29.4,53L34,71.2A16,16,0,0,0,11.9,89.5L37.3,197.8a15.9,15.9,0,0,0,7.4,10.1,16.2,16.2,0,0,0,8.3,2.3,15.2,15.2,0,0,0,4.2-.6,265.5,265.5,0,0,1,141.5,0,16.5,16.5,0,0,0,12.5-1.7,15.6,15.6,0,0,0,7.4-10.1L244.1,89.5A16,16,0,0,0,238.7,73.5ZM203,194.2a281.2,281.2,0,0,0-150.1,0h0L27.5,85.9l50.6,22.4a15.8,15.8,0,0,0,20.4-6.8l29.5-53,29.5,53a15.8,15.8,0,0,0,20.4,6.8l50.6-22.4Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M238.7,73.5A15.9,15.9,0,0,0,222,71.2L171.4,93.7,142,40.7a16.1,16.1,0,0,0-28,0l-29.4,53L34,71.2A16,16,0,0,0,11.9,89.5L37.3,197.8a15.9,15.9,0,0,0,7.4,10.1,16.2,16.2,0,0,0,8.3,2.3,15.2,15.2,0,0,0,4.2-.6,265.5,265.5,0,0,1,141.5,0,16.5,16.5,0,0,0,12.5-1.7,15.6,15.6,0,0,0,7.4-10.1L244.1,89.5A16,16,0,0,0,238.7,73.5Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M237.5,75.1a14.1,14.1,0,0,0-14.7-2L172.3,95.5a1.9,1.9,0,0,1-2.6-.8l-29.5-53a13.9,13.9,0,0,0-24.4,0l-29.5,53a1.9,1.9,0,0,1-2.6.8L33.2,73.1a14,14,0,0,0-19.4,16L39.3,197.4a13.9,13.9,0,0,0,6.5,8.8,13.6,13.6,0,0,0,7.1,2,15.8,15.8,0,0,0,3.8-.5,267.9,267.9,0,0,1,142.5-.1,14,14,0,0,0,17.5-10.2L242.1,89.1A13.8,13.8,0,0,0,237.5,75.1Zm-7,11.2L205,194.6a2.3,2.3,0,0,1-.9,1.3,2.5,2.5,0,0,1-1.6.2,279.8,279.8,0,0,0-149,0,2,2,0,0,1-2.5-1.5h0L25.5,86.3a2.1,2.1,0,0,1,2-2.5l.8.2,50.6,22.5a13.9,13.9,0,0,0,17.9-6l29.5-53a1.9,1.9,0,0,1,3.4,0l29.5,53a13.9,13.9,0,0,0,17.9,6L227.7,84a2,2,0,0,1,2.1.3A2,2,0,0,1,230.5,86.3Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M236.2,76.6a12,12,0,0,0-12.6-1.7L173.1,97.4a4.2,4.2,0,0,1-5.2-1.7L138.5,42.6a12.1,12.1,0,0,0-21,0L88.1,95.7a4.2,4.2,0,0,1-5.2,1.7L32.3,74.9A11.9,11.9,0,0,0,15.8,88.6L41.2,196.9a12.4,12.4,0,0,0,5.6,7.6,12.2,12.2,0,0,0,6.1,1.7,12.6,12.6,0,0,0,3.3-.5,270.6,270.6,0,0,1,143.6,0,11.9,11.9,0,0,0,14.9-8.8L240.2,88.6A11.9,11.9,0,0,0,236.2,76.6Zm-3.8,10.2L206.9,195.1a3.8,3.8,0,0,1-1.8,2.5,4,4,0,0,1-3.2.4A278.4,278.4,0,0,0,54,198a4.1,4.1,0,0,1-5-2.9h0L23.6,86.8a3.8,3.8,0,0,1,1.3-4,4.2,4.2,0,0,1,2.6-1,4.7,4.7,0,0,1,1.6.4l50.6,22.5a12,12,0,0,0,15.4-5.2l29.4-53a4.1,4.1,0,0,1,7,0l29.4,53a12,12,0,0,0,15.4,5.2l50.6-22.5a4,4,0,0,1,4.2.6A3.8,3.8,0,0,1,232.4,86.8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M238.7,73.5A16.1,16.1,0,0,0,222,71.2L171.4,93.7,142,40.7a16.1,16.1,0,0,0-28,0l-29.4,53L34,71.2A16,16,0,0,0,11.9,89.5L37.3,197.8a15.9,15.9,0,0,0,7.4,10.1,16.2,16.2,0,0,0,8.3,2.3,15.2,15.2,0,0,0,4.2-.6,265.5,265.5,0,0,1,141.5,0,16.5,16.5,0,0,0,12.5-1.7,15.6,15.6,0,0,0,7.4-10.1L244.1,89.5A16,16,0,0,0,238.7,73.5ZM203,194.2a281.2,281.2,0,0,0-150.1,0h0L27.5,85.9l50.6,22.4a15.8,15.8,0,0,0,20.4-6.8l29.5-53,29.5,53a15.8,15.8,0,0,0,20.4,6.8l50.6-22.4Z" />
        </>
      )}
    </svg>
  );
}

export function PhCrown(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M241.3,70.5a19.9,19.9,0,0,0-20.9-2.9l-47.2,21L145.5,38.8a20,20,0,0,0-35,0L82.8,88.6l-47.2-21A19.9,19.9,0,0,0,8,90.4L33.4,198.8A20.1,20.1,0,0,0,53,214.2a18.9,18.9,0,0,0,5.3-.8,262.7,262.7,0,0,1,139.3,0,20,20,0,0,0,24.9-14.6L248,90.4A19.8,19.8,0,0,0,241.3,70.5ZM56.8,193.3Zm143.3-4.1a287.1,287.1,0,0,0-144.2,0L33.2,92.8,76.4,112a19.9,19.9,0,0,0,25.6-8.6l26-46.7,26,46.7a19.9,19.9,0,0,0,25.6,8.6l43.2-19.2Zm-28.2-34.3A11.9,11.9,0,0,1,160,165.6h-1.2a301.9,301.9,0,0,0-61.6,0,11.9,11.9,0,0,1-13.1-10.7,12,12,0,0,1,10.7-13.2,320.4,320.4,0,0,1,66.4,0A12,12,0,0,1,171.9,154.9Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M236.3,87.7,210.8,196a8.1,8.1,0,0,1-10,5.9,273,273,0,0,0-145.7,0,8.1,8.1,0,0,1-10-5.9L19.7,87.7a8,8,0,0,1,11-9.2L81.3,101a8.1,8.1,0,0,0,10.3-3.4l29.4-53a8,8,0,0,1,14,0l29.4,53a8.1,8.1,0,0,0,10.3,3.4l50.6-22.5A8,8,0,0,1,236.3,87.7Z"
            opacity="0.2"
          />
          <path d="M238.7,73.5A15.9,15.9,0,0,0,222,71.2L171.4,93.7,142,40.7a16.1,16.1,0,0,0-28,0l-29.4,53L34,71.2A16,16,0,0,0,11.9,89.5L37.3,197.8a15.9,15.9,0,0,0,7.4,10.1,16.2,16.2,0,0,0,8.3,2.3,15.2,15.2,0,0,0,4.2-.6,265.5,265.5,0,0,1,141.5,0,16.5,16.5,0,0,0,12.5-1.7,15.6,15.6,0,0,0,7.4-10.1L244.1,89.5A16,16,0,0,0,238.7,73.5ZM203,194.2a281.2,281.2,0,0,0-150.1,0h0L27.5,85.9l50.6,22.4a15.8,15.8,0,0,0,20.4-6.8l29.5-53,29.5,53a15.8,15.8,0,0,0,20.4,6.8l50.6-22.4Zm-35-31.7a8.1,8.1,0,0,1-8,7.2h-.8a309.8,309.8,0,0,0-62.4,0,8,8,0,0,1-8.8-7.1,8.1,8.1,0,0,1,7.2-8.8,312.7,312.7,0,0,1,65.6,0A8.1,8.1,0,0,1,168,162.5Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M238.7,73.5A15.9,15.9,0,0,0,222,71.2L171.4,93.7,142,40.7a16.1,16.1,0,0,0-28,0l-29.4,53L34,71.2A16,16,0,0,0,11.9,89.5L37.3,197.8a15.9,15.9,0,0,0,7.4,10.1,16.2,16.2,0,0,0,8.3,2.3,15.2,15.2,0,0,0,4.2-.6,265.5,265.5,0,0,1,141.5,0,16.5,16.5,0,0,0,12.5-1.7,15.6,15.6,0,0,0,7.4-10.1L244.1,89.5A16,16,0,0,0,238.7,73.5Zm-70.7,89a8.1,8.1,0,0,1-8,7.2h-.8a309.8,309.8,0,0,0-62.4,0,8,8,0,0,1-8.8-7.1,8.1,8.1,0,0,1,7.2-8.8,312.7,312.7,0,0,1,65.6,0A8.1,8.1,0,0,1,168,162.5Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M237.5,75.1a14.1,14.1,0,0,0-14.7-2L172.3,95.5a1.9,1.9,0,0,1-2.6-.8l-29.5-53a13.9,13.9,0,0,0-24.4,0l-29.5,53a1.9,1.9,0,0,1-2.6.8L33.2,73.1a14,14,0,0,0-19.4,16L39.3,197.4a13.9,13.9,0,0,0,6.5,8.8,13.6,13.6,0,0,0,7.1,2,15.8,15.8,0,0,0,3.8-.5,267.9,267.9,0,0,1,142.5-.1,14,14,0,0,0,17.5-10.2L242.1,89.1A13.8,13.8,0,0,0,237.5,75.1Zm-7,11.2L205,194.6a2.3,2.3,0,0,1-.9,1.3,2.5,2.5,0,0,1-1.6.2,279.8,279.8,0,0,0-149,0,2,2,0,0,1-2.5-1.5h0L25.5,86.3a2.1,2.1,0,0,1,2-2.5l.8.2,50.6,22.5a13.9,13.9,0,0,0,17.9-6l29.5-53a1.9,1.9,0,0,1,3.4,0l29.5,53a13.9,13.9,0,0,0,17.9,6L227.7,84a2,2,0,0,1,2.1.3A2,2,0,0,1,230.5,86.3Zm-64.5,76a6.1,6.1,0,0,1-6,5.4h-.6a313.8,313.8,0,0,0-62.8,0,6,6,0,0,1-6.6-5.3,6.1,6.1,0,0,1,5.4-6.6,308.9,308.9,0,0,1,65.2,0A6.1,6.1,0,0,1,166,162.3Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M236.2,76.6a12,12,0,0,0-12.6-1.7L173.1,97.4a4.2,4.2,0,0,1-5.2-1.7L138.5,42.6a12.1,12.1,0,0,0-21,0L88.1,95.7a4.2,4.2,0,0,1-5.2,1.7L32.3,74.9A11.9,11.9,0,0,0,15.8,88.6L41.2,196.9a12.4,12.4,0,0,0,5.6,7.6,12.2,12.2,0,0,0,6.1,1.7,12.6,12.6,0,0,0,3.3-.5,270.6,270.6,0,0,1,143.6,0,11.9,11.9,0,0,0,14.9-8.8L240.2,88.6A11.9,11.9,0,0,0,236.2,76.6Zm-3.8,10.2L206.9,195.1a3.8,3.8,0,0,1-1.8,2.5,4,4,0,0,1-3.2.4A278.4,278.4,0,0,0,54,198a4.1,4.1,0,0,1-5-2.9h0L23.6,86.8a3.8,3.8,0,0,1,1.3-4,4.2,4.2,0,0,1,2.6-1,4.7,4.7,0,0,1,1.6.4l50.6,22.5a12,12,0,0,0,15.4-5.2l29.4-53a4.1,4.1,0,0,1,7,0l29.4,53a12,12,0,0,0,15.4,5.2l50.6-22.5a4,4,0,0,1,4.2.6A3.8,3.8,0,0,1,232.4,86.8ZM164,162.1a4,4,0,0,1-4,3.6h-.4a303.4,303.4,0,0,0-63.2,0,4.1,4.1,0,0,1-4.4-3.6,4,4,0,0,1,3.6-4.4,305.1,305.1,0,0,1,64.8,0A4,4,0,0,1,164,162.1Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M238.7,73.5A15.9,15.9,0,0,0,222,71.2L171.4,93.7,142,40.7a16.1,16.1,0,0,0-28,0l-29.4,53L34,71.2A16,16,0,0,0,11.9,89.5L37.3,197.8a15.9,15.9,0,0,0,7.4,10.1,16.2,16.2,0,0,0,8.3,2.3,15.2,15.2,0,0,0,4.2-.6,265.5,265.5,0,0,1,141.5,0,16.5,16.5,0,0,0,12.5-1.7,15.6,15.6,0,0,0,7.4-10.1L244.1,89.5A16,16,0,0,0,238.7,73.5ZM203,194.2a281.2,281.2,0,0,0-150.1,0h0L27.5,85.9l50.6,22.4a15.8,15.8,0,0,0,20.4-6.8l29.5-53,29.5,53a15.8,15.8,0,0,0,20.4,6.8l50.6-22.4Zm-35-31.7a8.1,8.1,0,0,1-8,7.2h-.8a309.8,309.8,0,0,0-62.4,0,8,8,0,0,1-8.8-7.1,8.1,8.1,0,0,1,7.2-8.8,312.7,312.7,0,0,1,65.6,0A8.1,8.1,0,0,1,168,162.5Z" />
        </>
      )}
    </svg>
  );
}

export function PhCube(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M233.4,68.9c0-.1,0-.1-.1-.2h0a20.5,20.5,0,0,0-7.5-7.4l-88-49.5a20.1,20.1,0,0,0-19.6,0l-88,49.5a20,20,0,0,0-7.5,7.5h-.1a.3.3,0,0,1-.1.2A20,20,0,0,0,20,78.7v98.6a20.2,20.2,0,0,0,10.2,17.5l88,49.5a20.6,20.6,0,0,0,9,2.5h1.7a21.2,21.2,0,0,0,8.9-2.5l88-49.5A20.2,20.2,0,0,0,236,177.3V78.7A19.4,19.4,0,0,0,233.4,68.9ZM128,33.8l71.6,40.3-70.7,40.1L56.6,73.9ZM44,94.4,116.9,135l-.7,80.6L44,175Zm96.2,121,.7-80.4L212,94.6V175Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M33.1,74.6A8.1,8.1,0,0,0,32,78.7v98.6a8.1,8.1,0,0,0,4.1,7l88,49.5a7.6,7.6,0,0,0,3.9,1h0l.9-106.8L33.1,74.6Z" />
          </g>
          <path d="M229.9,70.8h0a.1.1,0,0,1-.1-.1,16.2,16.2,0,0,0-6-5.9l-88-49.5a16,16,0,0,0-15.6,0l-88,49.5a16.2,16.2,0,0,0-6,5.9.1.1,0,0,1-.1.1v.2A15,15,0,0,0,24,78.7v98.6a16.1,16.1,0,0,0,8.2,14l88,49.5a16.5,16.5,0,0,0,7.2,2h1.4a16.1,16.1,0,0,0,7-2l88-49.5a16.1,16.1,0,0,0,8.2-14V78.7A15.6,15.6,0,0,0,229.9,70.8ZM128,29.2,207.7,74l-78.8,44.8L48.4,74ZM40,87.6l80.9,45.1-.8,89.7L40,177.3Zm96.1,134.7.8-89.6,79.1-45v89.6Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M229.9,70.8h0a.1.1,0,0,1-.1-.1,16.2,16.2,0,0,0-6-5.9l-88-49.5a16,16,0,0,0-15.6,0l-88,49.5a16.2,16.2,0,0,0-6,5.9.1.1,0,0,1-.1.1v.2A15,15,0,0,0,24,78.7v98.6a16.1,16.1,0,0,0,8.2,14l88,49.5a16.5,16.5,0,0,0,7.2,2h1.4a16.1,16.1,0,0,0,7-2l88-49.5a16.1,16.1,0,0,0,8.2-14V78.7A15.6,15.6,0,0,0,229.9,70.8Zm-101,48L48.4,74,128,29.2,207.7,74ZM216,177.3l-79.9,45,.8-89.6,79.1-45Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M228.2,71.8h-.1a13.4,13.4,0,0,0-5.2-5.1L134.9,17a14.1,14.1,0,0,0-13.8,0l-88,49.5a13.4,13.4,0,0,0-5.2,5.1h-.1A13.7,13.7,0,0,0,26,78.7v98.6a14,14,0,0,0,7.1,12.2l88,49.5a14.8,14.8,0,0,0,6.1,1.8h1.7a14.8,14.8,0,0,0,6-1.8l88-49.5a14,14,0,0,0,7.1-12.2V78.7A14.5,14.5,0,0,0,228.2,71.8ZM127,27.4l1-.2,1,.2L211.8,74l-82.9,47.1L44.3,74ZM39,179.1a2.2,2.2,0,0,1-1-1.8V84.2l84.9,47.3-.8,94.3Zm178,0-82.9,46.6.8-94.2L218,84.3v93A2.2,2.2,0,0,1,217,179.1Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M226.4,72.7h0a12.7,12.7,0,0,0-4.5-4.4l-88-49.5a12.4,12.4,0,0,0-11.8,0l-88,49.5a12.7,12.7,0,0,0-4.5,4.4h0A11.8,11.8,0,0,0,28,78.7v98.6a11.9,11.9,0,0,0,6.1,10.5l88,49.5a12.3,12.3,0,0,0,5.6,1.5h.6a13.7,13.7,0,0,0,5.6-1.5l88-49.5a11.9,11.9,0,0,0,6.1-10.5V78.7A12.5,12.5,0,0,0,226.4,72.7ZM126,25.7a4.3,4.3,0,0,1,4,0L215.9,74l-87,49.4L40.2,74ZM38,180.8a4,4,0,0,1-2-3.5V80.8l88.9,49.5-.8,98.9Zm180,0-85.9,48.3.8-98.8L220,80.9v96.4A4,4,0,0,1,218,180.8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M229.9,70.8h0a.1.1,0,0,1-.1-.1,16.2,16.2,0,0,0-6-5.9l-88-49.5a16,16,0,0,0-15.6,0l-88,49.5a16.2,16.2,0,0,0-6,5.9.1.1,0,0,1-.1.1v.2A15,15,0,0,0,24,78.7v98.6a16.1,16.1,0,0,0,8.2,14l88,49.5a16.5,16.5,0,0,0,7.2,2h1.4a15.7,15.7,0,0,0,7-2l88-49.5a16.1,16.1,0,0,0,8.2-14V78.7A15.6,15.6,0,0,0,229.9,70.8ZM128,29.2,207.7,74l-78.8,44.8L48.4,74ZM40,87.6l80.9,45.1-.8,89.7L40,177.3Zm96.1,134.7.8-89.6,79.1-45v89.6Z" />
        </>
      )}
    </svg>
  );
}

export function PhCurrencyBtc(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M177.1,114.5a48.1,48.1,0,0,0-25.1-77V24a12,12,0,0,0-24,0V36H112V24a12,12,0,0,0-24,0V36H64a12,12,0,0,0,0,24h4V188H64a12,12,0,0,0,0,24H88v12a12,12,0,0,0,24,0V212h16v12a12,12,0,0,0,24,0V212a52,52,0,0,0,25.1-97.5ZM164,84a24.1,24.1,0,0,1-24,24H92V60h48A24.1,24.1,0,0,1,164,84ZM152,188H92V132h60a28,28,0,0,1,0,56Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M170.5,115.7A44,44,0,0,0,144,40.2V24a8,8,0,0,0-16,0V40H112V24a8,8,0,0,0-16,0V40H64a8,8,0,0,0,0,16h8V192H64a8,8,0,0,0,0,16H96v16a8,8,0,0,0,16,0V208h16v16a8,8,0,0,0,16,0V208h8a48,48,0,0,0,18.5-92.3ZM168,84a28.1,28.1,0,0,1-28,28H88V56h52A28.1,28.1,0,0,1,168,84ZM152,192H88V128h64a32,32,0,0,1,0,64Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M170.5,115.7A44,44,0,0,0,144,40.2V24a8,8,0,0,0-16,0V40H112V24a8,8,0,0,0-16,0V40H64a8,8,0,0,0,0,16h8V192H64a8,8,0,0,0,0,16H96v16a8,8,0,0,0,16,0V208h16v16a8,8,0,0,0,16,0V208h8a48,48,0,0,0,18.5-92.3ZM168,84a28.1,28.1,0,0,1-28,28H88V56h52A28.1,28.1,0,0,1,168,84ZM152,192H88V128h64a32,32,0,0,1,0,64Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M166.7,116.4A41.9,41.9,0,0,0,142,42.1V24a6,6,0,0,0-12,0V42H110V24a6,6,0,0,0-12,0V42H64a6,6,0,0,0,0,12H74V194H64a6,6,0,0,0,0,12H98v18a6,6,0,0,0,12,0V206h20v18a6,6,0,0,0,12,0V206h10a46,46,0,0,0,14.7-89.6ZM170,84a30.1,30.1,0,0,1-30,30H86V54h54A30.1,30.1,0,0,1,170,84ZM152,194H86V126h66a34,34,0,0,1,0,68Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M162.3,117.2A40,40,0,0,0,140,44V24a4,4,0,0,0-8,0V44H108V24a4,4,0,0,0-8,0V44H64a4,4,0,0,0,0,8H76V196H64a4,4,0,0,0,0,8h36v20a4,4,0,0,0,8,0V204h24v20a4,4,0,0,0,8,0V204h12a44,44,0,0,0,10.3-86.8ZM172,84a32.1,32.1,0,0,1-32,32H84V52h56A32.1,32.1,0,0,1,172,84ZM152,196H84V124h68a36,36,0,0,1,0,72Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M170.5,115.7A44,44,0,0,0,144,40.2V24a8,8,0,0,0-16,0V40H112V24a8,8,0,0,0-16,0V40H64a8,8,0,0,0,0,16h8V192H64a8,8,0,0,0,0,16H96v16a8,8,0,0,0,16,0V208h16v16a8,8,0,0,0,16,0V208h8a48,48,0,0,0,18.5-92.3ZM168,84a28.1,28.1,0,0,1-28,28H88V56h52A28.1,28.1,0,0,1,168,84ZM152,192H88V128h64a32,32,0,0,1,0,64Z" />
        </>
      )}
    </svg>
  );
}

export function PhCurrencyCircleDollar(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,20A108,108,0,1,0,236,128,108.1,108.1,0,0,0,128,20Zm0,192a84,84,0,1,1,84-84A84.1,84.1,0,0,1,128,212Zm44-64a32.1,32.1,0,0,1-32,32v4a12,12,0,0,1-24,0v-4H104a12,12,0,0,1,0-24h36a8,8,0,0,0,0-16H116a32,32,0,0,1,0-64V72a12,12,0,0,1,24,0v4h12a12,12,0,0,1,0,24H116a8,8,0,0,0,0,16h24A32.1,32.1,0,0,1,172,148Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="128" cy="128" r="96" />
          </g>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm40-68a28.1,28.1,0,0,1-28,28h-4v8a8,8,0,0,1-16,0v-8H104a8,8,0,0,1,0-16h36a12,12,0,0,0,0-24H116a28,28,0,0,1,0-56h4V72a8,8,0,0,1,16,0v8h16a8,8,0,0,1,0,16H116a12,12,0,0,0,0,24h24A28.1,28.1,0,0,1,168,148Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm12,152h-4v8a8,8,0,0,1-16,0v-8H104a8,8,0,0,1,0-16h36a12,12,0,0,0,0-24H116a28,28,0,0,1,0-56h4V72a8,8,0,0,1,16,0v8h16a8,8,0,0,1,0,16H116a12,12,0,0,0,0,24h24a28,28,0,0,1,0,56Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,26A102,102,0,1,0,230,128,102.2,102.2,0,0,0,128,26Zm0,192a90,90,0,1,1,90-90A90.1,90.1,0,0,1,128,218Zm38-70a26.1,26.1,0,0,1-26,26h-6v10a6,6,0,0,1-12,0V174H104a6,6,0,0,1,0-12h36a14,14,0,0,0,0-28H116a26,26,0,0,1,0-52h6V72a6,6,0,0,1,12,0V82h18a6,6,0,0,1,0,12H116a14,14,0,0,0,0,28h24A26.1,26.1,0,0,1,166,148Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,28A100,100,0,1,0,228,128,100.2,100.2,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm36-72a24.1,24.1,0,0,1-24,24h-8v12a4,4,0,0,1-8,0V172H104a4,4,0,0,1,0-8h36a16,16,0,0,0,0-32H116a24,24,0,0,1,0-48h8V72a4,4,0,0,1,8,0V84h20a4,4,0,0,1,0,8H116a16,16,0,0,0,0,32h24A24.1,24.1,0,0,1,164,148Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm40-68a28.1,28.1,0,0,1-28,28h-4v8a8,8,0,0,1-16,0v-8H104a8,8,0,0,1,0-16h36a12,12,0,0,0,0-24H116a28,28,0,0,1,0-56h4V72a8,8,0,0,1,16,0v8h16a8,8,0,0,1,0,16H116a12,12,0,0,0,0,24h24A28.1,28.1,0,0,1,168,148Z" />
        </>
      )}
    </svg>
  );
}

export function PhCurrencyCny(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M52,64A12,12,0,0,1,64,52H192a12,12,0,0,1,0,24H64A12,12,0,0,1,52,64ZM216,164a12,12,0,0,0-12,12v12H176a12,12,0,0,1-12-12V132h44a12,12,0,0,0,0-24H48a12,12,0,0,0,0,24H92v4a52,52,0,0,1-52,52,12,12,0,0,0,0,24,76.1,76.1,0,0,0,76-76v-4h24v44a36,36,0,0,0,36,36h40a12,12,0,0,0,12-12V176A12,12,0,0,0,216,164Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M56,64a8,8,0,0,1,8-8H192a8,8,0,0,1,0,16H64A8,8,0,0,1,56,64ZM216,168a8,8,0,0,0-8,8v16H176a16,16,0,0,1-16-16V128h48a8,8,0,0,0,0-16H48a8,8,0,0,0,0,16H96v8a56,56,0,0,1-56,56,8,8,0,0,0,0,16,72.1,72.1,0,0,0,72-72v-8h32v48a32.1,32.1,0,0,0,32,32h40a8,8,0,0,0,8-8V176A8,8,0,0,0,216,168Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M56,64a8,8,0,0,1,8-8H192a8,8,0,0,1,0,16H64A8,8,0,0,1,56,64ZM216,168a8,8,0,0,0-8,8v16H176a16,16,0,0,1-16-16V128h48a8,8,0,0,0,0-16H48a8,8,0,0,0,0,16H96v8a56,56,0,0,1-56,56,8,8,0,0,0,0,16,72.1,72.1,0,0,0,72-72v-8h32v48a32.1,32.1,0,0,0,32,32h40a8,8,0,0,0,8-8V176A8,8,0,0,0,216,168Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M58,64a6,6,0,0,1,6-6H192a6,6,0,0,1,0,12H64A6,6,0,0,1,58,64ZM216,170a6,6,0,0,0-6,6v18H176a18.1,18.1,0,0,1-18-18V126h50a6,6,0,0,0,0-12H48a6,6,0,0,0,0,12H98v10a58,58,0,0,1-58,58,6,6,0,0,0,0,12,70.1,70.1,0,0,0,70-70V126h36v50a30.1,30.1,0,0,0,30,30h40a6,6,0,0,0,6-6V176A6,6,0,0,0,216,170Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M60,64a4,4,0,0,1,4-4H192a4,4,0,0,1,0,8H64A4,4,0,0,1,60,64ZM216,172a4,4,0,0,0-4,4v20H176a20.1,20.1,0,0,1-20-20V124h52a4,4,0,0,0,0-8H48a4,4,0,0,0,0,8h52v12a60,60,0,0,1-60,60,4,4,0,0,0,0,8,68.1,68.1,0,0,0,68-68V124h40v52a28.1,28.1,0,0,0,28,28h40a4,4,0,0,0,4-4V176A4,4,0,0,0,216,172Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M56,64a8,8,0,0,1,8-8H192a8,8,0,0,1,0,16H64A8,8,0,0,1,56,64ZM216,168a8,8,0,0,0-8,8v16H176a16,16,0,0,1-16-16V128h48a8,8,0,0,0,0-16H48a8,8,0,0,0,0,16H96v8a56,56,0,0,1-56,56,8,8,0,0,0,0,16,72.1,72.1,0,0,0,72-72v-8h32v48a32.1,32.1,0,0,0,32,32h40a8,8,0,0,0,8-8V176A8,8,0,0,0,216,168Z" />
        </>
      )}
    </svg>
  );
}

export function PhCurrencyDollarSimple(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M204,168a52,52,0,0,1-52,52H140v12a12,12,0,0,1-24,0V220H104a52,52,0,0,1-52-52,12,12,0,0,1,24,0,28.1,28.1,0,0,0,28,28h48a28,28,0,0,0,0-56H108a52,52,0,0,1,0-104h8V24a12,12,0,0,1,24,0V36h4a52,52,0,0,1,52,52,12,12,0,0,1-24,0,28.1,28.1,0,0,0-28-28H108a28,28,0,0,0,0,56h44A52,52,0,0,1,204,168Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M200,168a48,48,0,0,1-48,48H136v16a8,8,0,0,1-16,0V216H104a48,48,0,0,1-48-48,8,8,0,0,1,16,0,32.1,32.1,0,0,0,32,32h48a32,32,0,0,0,0-64H108a48,48,0,0,1,0-96h12V24a8,8,0,0,1,16,0V40h8a48,48,0,0,1,48,48,8,8,0,0,1-16,0,32.1,32.1,0,0,0-32-32H108a32,32,0,0,0,0,64h44A48,48,0,0,1,200,168Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M200,168a48,48,0,0,1-48,48H136v16a8,8,0,0,1-16,0V216H104a48,48,0,0,1-48-48,8,8,0,0,1,16,0,32.1,32.1,0,0,0,32,32h48a32,32,0,0,0,0-64H108a48,48,0,0,1,0-96h12V24a8,8,0,0,1,16,0V40h8a48,48,0,0,1,48,48,8,8,0,0,1-16,0,32.1,32.1,0,0,0-32-32H108a32,32,0,0,0,0,64h44A48,48,0,0,1,200,168Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M198,168a46,46,0,0,1-46,46H134v18a6,6,0,0,1-12,0V214H104a46,46,0,0,1-46-46,6,6,0,0,1,12,0,34.1,34.1,0,0,0,34,34h48a34,34,0,0,0,0-68H108a46,46,0,0,1,0-92h14V24a6,6,0,0,1,12,0V42h10a46,46,0,0,1,46,46,6,6,0,0,1-12,0,34.1,34.1,0,0,0-34-34H108a34,34,0,0,0,0,68h44A46,46,0,0,1,198,168Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M196,168a44,44,0,0,1-44,44H132v20a4,4,0,0,1-8,0V212H104a44,44,0,0,1-44-44,4,4,0,0,1,8,0,36,36,0,0,0,36,36h48a36,36,0,0,0,0-72H108a44,44,0,0,1,0-88h16V24a4,4,0,0,1,8,0V44h12a44,44,0,0,1,44,44,4,4,0,0,1-8,0,36,36,0,0,0-36-36H108a36,36,0,0,0,0,72h44A44,44,0,0,1,196,168Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M200,168a48,48,0,0,1-48,48H136v16a8,8,0,0,1-16,0V216H104a48,48,0,0,1-48-48,8,8,0,0,1,16,0,32.1,32.1,0,0,0,32,32h48a32,32,0,0,0,0-64H108a48,48,0,0,1,0-96h12V24a8,8,0,0,1,16,0V40h8a48,48,0,0,1,48,48,8,8,0,0,1-16,0,32.1,32.1,0,0,0-32-32H108a32,32,0,0,0,0,64h44A48,48,0,0,1,200,168Z" />
        </>
      )}
    </svg>
  );
}

export function PhCurrencyDollar(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M152,116H140V60h4a28.1,28.1,0,0,1,28,28,12,12,0,0,0,24,0,52,52,0,0,0-52-52h-4V24a12,12,0,0,0-24,0V36h-8a52,52,0,0,0,0,104h8v56H104a28.1,28.1,0,0,1-28-28,12,12,0,0,0-24,0,52,52,0,0,0,52,52h12v12a12,12,0,0,0,24,0V220h12a52,52,0,0,0,0-104Zm-44,0a28,28,0,0,1,0-56h8v56Zm44,80H140V140h12a28,28,0,0,1,0,56Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M152,120H136V56h8a32.1,32.1,0,0,1,32,32,8,8,0,0,0,16,0,48,48,0,0,0-48-48h-8V24a8,8,0,0,0-16,0V40H108a48,48,0,0,0,0,96h12v64H104a32.1,32.1,0,0,1-32-32,8,8,0,0,0-16,0,48,48,0,0,0,48,48h16v16a8,8,0,0,0,16,0V216h16a48,48,0,0,0,0-96Zm-44,0a32,32,0,0,1,0-64h12v64Zm44,80H136V136h16a32,32,0,0,1,0,64Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M152,120H136V56h8a32.1,32.1,0,0,1,32,32,8,8,0,0,0,16,0,48,48,0,0,0-48-48h-8V24a8,8,0,0,0-16,0V40H108a48,48,0,0,0,0,96h12v64H104a32.1,32.1,0,0,1-32-32,8,8,0,0,0-16,0,48,48,0,0,0,48,48h16v16a8,8,0,0,0,16,0V216h16a48,48,0,0,0,0-96Zm-32,0H108a32,32,0,0,1,0-64h12Zm32,80H136V136h16a32,32,0,0,1,0,64Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M152,122H134V54h10a34.1,34.1,0,0,1,34,34,6,6,0,0,0,12,0,46,46,0,0,0-46-46H134V24a6,6,0,0,0-12,0V42H108a46,46,0,0,0,0,92h14v68H104a34.1,34.1,0,0,1-34-34,6,6,0,0,0-12,0,46,46,0,0,0,46,46h18v18a6,6,0,0,0,12,0V214h18a46,46,0,0,0,0-92Zm-44,0a34,34,0,0,1,0-68h14v68Zm44,80H134V134h18a34,34,0,0,1,0,68Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M152,124H132V52h12a36,36,0,0,1,36,36,4,4,0,0,0,8,0,44,44,0,0,0-44-44H132V24a4,4,0,0,0-8,0V44H108a44,44,0,0,0,0,88h16v72H104a36,36,0,0,1-36-36,4,4,0,0,0-8,0,44,44,0,0,0,44,44h20v20a4,4,0,0,0,8,0V212h20a44,44,0,0,0,0-88Zm-44,0a36,36,0,0,1,0-72h16v72Zm44,80H132V132h20a36,36,0,0,1,0,72Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M152,120H136V56h8a32.1,32.1,0,0,1,32,32,8,8,0,0,0,16,0,48,48,0,0,0-48-48h-8V24a8,8,0,0,0-16,0V40H108a48,48,0,0,0,0,96h12v64H104a32.1,32.1,0,0,1-32-32,8,8,0,0,0-16,0,48,48,0,0,0,48,48h16v16a8,8,0,0,0,16,0V216h16a48,48,0,0,0,0-96Zm-44,0a32,32,0,0,1,0-64h12v64Zm44,80H136V136h16a32,32,0,0,1,0,64Z" />
        </>
      )}
    </svg>
  );
}

export function PhCurrencyEth(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M225.4,120.6l-88-112a11.9,11.9,0,0,0-18.8,0l-88,112a11.7,11.7,0,0,0,0,14.8l88,112a11.9,11.9,0,0,0,18.8,0l88-112A11.7,11.7,0,0,0,225.4,120.6ZM140,50.7l57.1,72.7-57.1,26Zm-24,98.7-57.1-26L116,50.7Zm0,26.3v29.6l-36.1-46Zm24,0,36.1-16.4-36.1,46Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M216,128,128,240,40,128l88,40Z" opacity="0.2" />
          <path d="M222.3,123.1l-88-112a8,8,0,0,0-12.6,0l-88,112a7.8,7.8,0,0,0,0,9.8l88,112a8,8,0,0,0,12.6,0l88-112A7.8,7.8,0,0,0,222.3,123.1ZM136,39.1l67.4,85.8L136,155.6ZM120,155.6,52.6,124.9,120,39.1Zm0,17.6v43.7l-53.4-68Zm16,0,53.4-24.3-53.4,68Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M222.3,123.1l-88-112a8,8,0,0,0-12.6,0l-88,112a7.8,7.8,0,0,0,0,9.8l88,112a8,8,0,0,0,12.6,0l88-112A7.8,7.8,0,0,0,222.3,123.1ZM136,155.6V39.1l67.4,85.8Zm-16,0L52.6,124.9,120,39.1Zm0,17.6v43.7l-53.4-68Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M220.7,124.3l-88-112a6,6,0,0,0-9.4,0l-88,112a6,6,0,0,0,0,7.4l88,112a6,6,0,0,0,9.4,0l88-112A6,6,0,0,0,220.7,124.3ZM134,33.3l72.6,92.4-72.6,33ZM122,158.7l-72.6-33L122,33.3Zm0,13.2v50.8L59.9,143.6Zm12,0,62.1-28.3L134,222.7Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M219.1,125.5l-88-112a4,4,0,0,0-6.2,0l-88,112a3.9,3.9,0,0,0,0,5l88,112a4,4,0,0,0,6.2,0l88-112A3.9,3.9,0,0,0,219.1,125.5ZM132,27.6l77.7,98.9L132,161.8Zm-8,134.2L46.3,126.5,124,27.6Zm0,8.8v57.8l-70.7-90Zm8,0,70.7-32.2-70.7,90Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M222.3,123.1l-88-112a8,8,0,0,0-12.6,0l-88,112a7.8,7.8,0,0,0,0,9.8l88,112a8,8,0,0,0,12.6,0l88-112A7.8,7.8,0,0,0,222.3,123.1ZM136,39.1l67.4,85.8L136,155.6ZM120,155.6,52.6,124.9,120,39.1Zm0,17.6v43.7l-53.4-68Zm16,0,53.4-24.3-53.4,68Z" />
        </>
      )}
    </svg>
  );
}

export function PhCurrencyEur(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M195.4,186.4a12,12,0,0,1,0,17A84,84,0,0,1,53.5,160H40a12,12,0,0,1,0-24H52V120H40a12,12,0,0,1,0-24H53.5A84,84,0,0,1,195.4,52.6a12,12,0,0,1-17,17A59.3,59.3,0,0,0,136,52,60.1,60.1,0,0,0,78.2,96H136a12,12,0,0,1,0,24H76v16h44a12,12,0,0,1,0,24H78.2A60.1,60.1,0,0,0,136,204a59.3,59.3,0,0,0,42.4-17.6A12,12,0,0,1,195.4,186.4Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M192.6,189.3a8,8,0,0,1,0,11.3A80,80,0,0,1,56.4,152H40a8,8,0,0,1,0-16H56V120H40a8,8,0,0,1,0-16H56.4A80,80,0,0,1,192.6,55.4a8,8,0,0,1,0,11.3,7.9,7.9,0,0,1-11.3,0A64.1,64.1,0,0,0,72.5,104H136a8,8,0,0,1,0,16H72v16h48a8,8,0,0,1,0,16H72.5a64.1,64.1,0,0,0,108.8,37.3A7.9,7.9,0,0,1,192.6,189.3Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M192.6,189.3a8,8,0,0,1,0,11.3A80,80,0,0,1,56.4,152H40a8,8,0,0,1,0-16H56V120H40a8,8,0,0,1,0-16H56.4A80,80,0,0,1,192.6,55.4a8,8,0,0,1,0,11.3,7.9,7.9,0,0,1-11.3,0A64.1,64.1,0,0,0,72.5,104H136a8,8,0,0,1,0,16H72v16h48a8,8,0,0,1,0,16H72.5a64.1,64.1,0,0,0,108.8,37.3A7.9,7.9,0,0,1,192.6,189.3Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M191.2,190.7a6.1,6.1,0,0,1,0,8.5A78,78,0,0,1,58.3,150H40a6,6,0,0,1,0-12H58V118H40a6,6,0,0,1,0-12H58.3A78,78,0,0,1,191.2,56.8a6.1,6.1,0,0,1,0,8.5,5.9,5.9,0,0,1-8.5,0A66,66,0,0,0,70.3,106H136a6,6,0,0,1,0,12H70v20h50a6,6,0,0,1,0,12H70.3a66,66,0,0,0,112.4,40.7A5.9,5.9,0,0,1,191.2,190.7Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M189.7,192.1a3.9,3.9,0,0,1,0,5.6A75.2,75.2,0,0,1,136,220a76.1,76.1,0,0,1-75.9-72H40a4,4,0,0,1,0-8H60V116H40a4,4,0,0,1,0-8H60.1A76.1,76.1,0,0,1,136,36a75.2,75.2,0,0,1,53.7,22.3,4,4,0,0,1-5.6,5.6A68,68,0,0,0,68.1,108H136a4,4,0,0,1,0,8H68v24h52a4,4,0,0,1,0,8H68.1a68,68,0,0,0,116,44.1A3.9,3.9,0,0,1,189.7,192.1Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M192.6,189.3a8,8,0,0,1,0,11.3A80,80,0,0,1,56.4,152H40a8,8,0,0,1,0-16H56V120H40a8,8,0,0,1,0-16H56.4A80,80,0,0,1,192.6,55.4a8,8,0,0,1,0,11.3,7.9,7.9,0,0,1-11.3,0A64.1,64.1,0,0,0,72.5,104H136a8,8,0,0,1,0,16H72v16h48a8,8,0,0,1,0,16H72.5a64.1,64.1,0,0,0,108.8,37.3A7.9,7.9,0,0,1,192.6,189.3Z" />
        </>
      )}
    </svg>
  );
}

export function PhCurrencyGbp(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M196,208a12,12,0,0,1-12,12H56a12,12,0,0,1,0-24,24.1,24.1,0,0,0,24-24V144H56a12,12,0,0,1,0-24H80V84a56,56,0,0,1,95.6-39.6,12,12,0,0,1-17,17A31.5,31.5,0,0,0,136,52a32.1,32.1,0,0,0-32,32v36h32a12,12,0,0,1,0,24H104v28a47.9,47.9,0,0,1-6.5,24H184A12,12,0,0,1,196,208Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M192,208a8,8,0,0,1-8,8H56a8,8,0,0,1,0-16,28.1,28.1,0,0,0,28-28V140H56a8,8,0,0,1,0-16H84V84a52,52,0,0,1,88.8-36.8,8,8,0,0,1,0,11.3,7.9,7.9,0,0,1-11.3,0A36.1,36.1,0,0,0,100,84v40h36a8,8,0,0,1,0,16H100v32a43.8,43.8,0,0,1-10.1,28H184A8,8,0,0,1,192,208Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M192,208a8,8,0,0,1-8,8H56a8,8,0,0,1,0-16,28.1,28.1,0,0,0,28-28V140H56a8,8,0,0,1,0-16H84V84a52,52,0,0,1,88.8-36.8,8,8,0,0,1,0,11.3,7.9,7.9,0,0,1-11.3,0A36.1,36.1,0,0,0,100,84v40h36a8,8,0,0,1,0,16H100v32a43.8,43.8,0,0,1-10.1,28H184A8,8,0,0,1,192,208Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M190,208a6,6,0,0,1-6,6H56a6,6,0,0,1,0-12,30.1,30.1,0,0,0,30-30V138H56a6,6,0,0,1,0-12H86V84a50.1,50.1,0,0,1,85.4-35.4,6.1,6.1,0,0,1,0,8.5,5.9,5.9,0,0,1-8.5,0A38,38,0,0,0,98,84v42h38a6,6,0,0,1,0,12H98v34a42.2,42.2,0,0,1-12.6,30H184A6,6,0,0,1,190,208Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M188,208a4,4,0,0,1-4,4H56a4,4,0,0,1,0-8,32.1,32.1,0,0,0,32-32V136H56a4,4,0,0,1,0-8H88V84a48,48,0,0,1,48-48,47.4,47.4,0,0,1,33.9,14.1,4,4,0,1,1-5.6,5.6A40,40,0,0,0,96,84v44h40a4,4,0,0,1,0,8H96v36a40,40,0,0,1-16,32H184A4,4,0,0,1,188,208Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M192,208a8,8,0,0,1-8,8H56a8,8,0,0,1,0-16,28.1,28.1,0,0,0,28-28V140H56a8,8,0,0,1,0-16H84V84a52,52,0,0,1,88.8-36.8,8,8,0,0,1,0,11.3,7.9,7.9,0,0,1-11.3,0A36.1,36.1,0,0,0,100,84v40h36a8,8,0,0,1,0,16H100v32a43.8,43.8,0,0,1-10.1,28H184A8,8,0,0,1,192,208Z" />
        </>
      )}
    </svg>
  );
}

export function PhCurrencyInr(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M212,80a12,12,0,0,1-12,12H172a64.1,64.1,0,0,1-64,64h-5l65.1,59.1a12.1,12.1,0,0,1,.8,17,12.1,12.1,0,0,1-17,.8l-88-80a12.1,12.1,0,0,1-3.1-13.2A11.9,11.9,0,0,1,72,132h36a40,40,0,0,0,40-40H72a12,12,0,0,1,0-24h68a40,40,0,0,0-32-16H72a12,12,0,0,1,0-24H200a12,12,0,0,1,0,24H157.9a61.2,61.2,0,0,1,9.4,16H200A12,12,0,0,1,212,80Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M208,80a8,8,0,0,1-8,8H167.9c0,1.3.1,2.7.1,4a60,60,0,0,1-60,60H92.7l72.7,66.1a7.9,7.9,0,0,1,.5,11.3A8,8,0,0,1,160,232a8.2,8.2,0,0,1-5.4-2.1l-88-80A8,8,0,0,1,72,136h36a44,44,0,0,0,44-44c0-1.3-.1-2.7-.2-4H72a8,8,0,0,1,0-16h75.2A44.1,44.1,0,0,0,108,48H72a8,8,0,0,1,0-16H200a8,8,0,0,1,0,16H148.7a60.2,60.2,0,0,1,15.9,24H200A8,8,0,0,1,208,80Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M208,80a8,8,0,0,1-8,8H167.9c0,1.3.1,2.7.1,4a60,60,0,0,1-60,60H92.7l72.7,66.1a7.9,7.9,0,0,1,.5,11.3A8,8,0,0,1,160,232a8.2,8.2,0,0,1-5.4-2.1l-88-80A8,8,0,0,1,72,136h36a44,44,0,0,0,44-44c0-1.3-.1-2.7-.2-4H72a8,8,0,0,1,0-16h75.2A44.1,44.1,0,0,0,108,48H72a8,8,0,0,1,0-16H200a8,8,0,0,1,0,16H148.7a60.2,60.2,0,0,1,15.9,24H200A8,8,0,0,1,208,80Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M206,80a6,6,0,0,1-6,6H165.7q.3,3,.3,6a58,58,0,0,1-58,58H87.5L164,219.6a6,6,0,0,1-4,10.4,5.7,5.7,0,0,1-4-1.6l-88-80a5.9,5.9,0,0,1-1.6-6.6A6,6,0,0,1,72,138h36a46,46,0,0,0,46-46,40.3,40.3,0,0,0-.4-6H72a6,6,0,0,1,0-12h78.3A46.1,46.1,0,0,0,108,46H72a6,6,0,0,1,0-12H200a6,6,0,0,1,0,12H143.3a58.4,58.4,0,0,1,19.8,28H200A6,6,0,0,1,206,80Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M204,80a4,4,0,0,1-4,4H163.4a52.9,52.9,0,0,1,.6,8,56,56,0,0,1-56,56H82.3l80.4,73a4.2,4.2,0,0,1,.3,5.7,4.1,4.1,0,0,1-5.7.3l-88-80a4.1,4.1,0,0,1-1-4.4A3.9,3.9,0,0,1,72,140h36a48,48,0,0,0,48-48,42.5,42.5,0,0,0-.7-8H72a4,4,0,0,1,0-8h81.2A48,48,0,0,0,108,44H72a4,4,0,0,1,0-8H200a4,4,0,0,1,0,8H136.8a56.6,56.6,0,0,1,24.9,32H200A4,4,0,0,1,204,80Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M208,80a8,8,0,0,1-8,8H167.9c0,1.3.1,2.7.1,4a60,60,0,0,1-60,60H92.7l72.7,66.1a7.9,7.9,0,0,1,.5,11.3A8,8,0,0,1,160,232a8.2,8.2,0,0,1-5.4-2.1l-88-80A8,8,0,0,1,72,136h36a44,44,0,0,0,44-44c0-1.3-.1-2.7-.2-4H72a8,8,0,0,1,0-16h75.2A44.1,44.1,0,0,0,108,48H72a8,8,0,0,1,0-16H200a8,8,0,0,1,0,16H148.7a60.2,60.2,0,0,1,15.9,24H200A8,8,0,0,1,208,80Z" />
        </>
      )}
    </svg>
  );
}

export function PhCurrencyJpy(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M209.3,55.6l-56,68.4H176a12,12,0,0,1,0,24H140v16h36a12,12,0,0,1,0,24H140v28a12,12,0,0,1-24,0V188H80a12,12,0,0,1,0-24h36V148H80a12,12,0,0,1,0-24h22.7l-56-68.4A12,12,0,1,1,65.3,40.4L128,117l62.7-76.6a12,12,0,1,1,18.6,15.2Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M206.2,53.1,144.9,128H176a8,8,0,0,1,0,16H136v16h40a8,8,0,0,1,0,16H136v40a8,8,0,0,1-16,0V176H80a8,8,0,0,1,0-16h40V144H80a8,8,0,0,1,0-16h31.1L49.8,53.1A8,8,0,0,1,62.2,42.9L128,123.4l65.8-80.5a8,8,0,0,1,12.4,10.2Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M206.2,53.1,144.9,128H176a8,8,0,0,1,0,16H136v16h40a8,8,0,0,1,0,16H136v40a8,8,0,0,1-16,0V176H80a8,8,0,0,1,0-16h40V144H80a8,8,0,0,1,0-16h31.1L49.8,53.1A8,8,0,0,1,62.2,42.9L128,123.4l65.8-80.5a8,8,0,0,1,12.4,10.2Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M204.6,51.8,140.7,130H176a6,6,0,0,1,0,12H134v20h42a6,6,0,0,1,0,12H134v42a6,6,0,0,1-12,0V174H80a6,6,0,0,1,0-12h42V142H80a6,6,0,0,1,0-12h35.3L51.4,51.8a6,6,0,1,1,9.2-7.6L128,126.5l67.4-82.3a6,6,0,0,1,9.2,7.6Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M203.1,50.5,136.4,132H176a4,4,0,0,1,0,8H132v24h44a4,4,0,0,1,0,8H132v44a4,4,0,0,1-8,0V172H80a4,4,0,0,1,0-8h44V140H80a4,4,0,0,1,0-8h39.6L52.9,50.5a4,4,0,0,1,6.2-5L128,129.7l68.9-84.2a4,4,0,1,1,6.2,5Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M206.2,53.1,144.9,128H176a8,8,0,0,1,0,16H136v16h40a8,8,0,0,1,0,16H136v40a8,8,0,0,1-16,0V176H80a8,8,0,0,1,0-16h40V144H80a8,8,0,0,1,0-16h31.1L49.8,53.1A8,8,0,0,1,62.2,42.9L128,123.4l65.8-80.5a8,8,0,0,1,12.4,10.2Z" />
        </>
      )}
    </svg>
  );
}

export function PhCurrencyKrw(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M240,124H223.8l19.3-47.5a12,12,0,1,0-22.2-9l-23,56.5H162.1l-23-56.5a12,12,0,0,0-22.2,0L93.9,124H58.1l-23-56.5a12,12,0,1,0-22.2,9L32.2,124H16a12,12,0,0,0,0,24H41.9l23,56.5a12,12,0,0,0,22.2,0l23-56.5h35.8l23,56.5a12,12,0,0,0,22.2,0l23-56.5H240a12,12,0,0,0,0-24ZM76,168.1,67.8,148H84.2ZM119.8,124l8.2-20.1,8.2,20.1ZM180,168.1,171.8,148h16.4Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M240,128H217.9l21.5-53a8,8,0,1,0-14.8-6l-24,59H159.4l-24-59a8,8,0,0,0-14.8,0l-24,59H55.4l-24-59a8,8,0,1,0-14.8,6l21.5,53H16a8,8,0,0,0,0,16H44.6l24,59a8,8,0,0,0,14.8,0l24-59h41.2l24,59a8,8,0,0,0,14.8,0l24-59H240a8,8,0,0,0,0-16ZM76,178.7,61.9,144H90.1ZM113.9,128,128,93.3,142.1,128ZM180,178.7,165.9,144h28.2Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M240,128H217.9l21.5-53a8,8,0,1,0-14.8-6l-24,59H159.4l-24-59a8,8,0,0,0-14.8,0l-24,59H55.4l-24-59a8,8,0,1,0-14.8,6l21.5,53H16a8,8,0,0,0,0,16H44.6l24,59a8,8,0,0,0,14.8,0l24-59h41.2l24,59a8,8,0,0,0,14.8,0l24-59H240a8,8,0,0,0,0-16ZM76,178.7,61.9,144H90.1ZM113.9,128,128,93.3,142.1,128ZM180,178.7,165.9,144h28.2Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M240,130H214.9l22.7-55.7a6.1,6.1,0,1,0-11.2-4.6L202,130H158L133.6,69.7a6.1,6.1,0,0,0-11.2,0L98,130H54L29.6,69.7a6.2,6.2,0,0,0-7.9-3.3,6.2,6.2,0,0,0-3.3,7.9L41.1,130H16a6,6,0,0,0,0,12H46l24.4,60.3a6.1,6.1,0,0,0,11.2,0L106,142h44l24.4,60.3a6.1,6.1,0,0,0,11.2,0L210,142h30a6,6,0,0,0,0-12ZM76,184.1,58.9,142H93.1ZM110.9,130,128,87.9,145.1,130ZM180,184.1,162.9,142h34.2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M240,132H211.9l23.8-58.5a4,4,0,1,0-7.4-3l-25,61.5H156.7l-25-61.5a4,4,0,0,0-7.4,0L99.3,132H52.7l-25-61.5a4,4,0,1,0-7.4,3L44.1,132H16a4,4,0,0,0,0,8H47.3l25,61.5a4,4,0,0,0,7.4,0l25-61.5h46.6l25,61.5a4,4,0,0,0,7.4,0l25-61.5H240a4,4,0,0,0,0-8ZM76,189.4,55.9,140H96.1ZM107.9,132,128,82.6,148.1,132ZM180,189.4,159.9,140h40.2Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M240,128H217.9l21.5-53a8,8,0,1,0-14.8-6l-24,59H159.4l-24-59a8,8,0,0,0-14.8,0l-24,59H55.4l-24-59a8,8,0,1,0-14.8,6l21.5,53H16a8,8,0,0,0,0,16H44.6l24,59a8,8,0,0,0,14.8,0l24-59h41.2l24,59a8,8,0,0,0,14.8,0l24-59H240a8,8,0,0,0,0-16ZM76,178.7,61.9,144H90.1ZM113.9,128,128,93.3,142.1,128ZM180,178.7,165.9,144h28.2Z" />
        </>
      )}
    </svg>
  );
}

export function PhCurrencyKzt(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M212,100a12,12,0,0,1-12,12H140V212a12,12,0,0,1-24,0V112H56a12,12,0,0,1,0-24H200A12,12,0,0,1,212,100ZM56,64H200a12,12,0,0,0,0-24H56a12,12,0,0,0,0,24Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M208,96a8,8,0,0,1-8,8H136V216a8,8,0,0,1-16,0V104H56a8,8,0,0,1,0-16H200A8,8,0,0,1,208,96ZM56,64H200a8,8,0,0,0,0-16H56a8,8,0,0,0,0,16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M208,96a8,8,0,0,1-8,8H136V216a8,8,0,0,1-16,0V104H56a8,8,0,0,1,0-16H200A8,8,0,0,1,208,96ZM56,64H200a8,8,0,0,0,0-16H56a8,8,0,0,0,0,16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M206,96a6,6,0,0,1-6,6H134V216a6,6,0,0,1-12,0V102H56a6,6,0,0,1,0-12H200A6,6,0,0,1,206,96ZM56,62H200a6,6,0,0,0,0-12H56a6,6,0,0,0,0,12Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M204,96a4,4,0,0,1-4,4H132V216a4,4,0,0,1-8,0V100H56a4,4,0,0,1,0-8H200A4,4,0,0,1,204,96ZM56,60H200a4,4,0,0,0,0-8H56a4,4,0,0,0,0,8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M208,96a8,8,0,0,1-8,8H136V216a8,8,0,0,1-16,0V104H56a8,8,0,0,1,0-16H200A8,8,0,0,1,208,96ZM56,64H200a8,8,0,0,0,0-16H56a8,8,0,0,0,0,16Z" />
        </>
      )}
    </svg>
  );
}

export function PhCurrencyNgn(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216,116H204V46a12,12,0,0,0-24,0v70H133.9L73.5,38.6A12.1,12.1,0,0,0,52,46v70H40a12,12,0,0,0,0,24H52v70a12,12,0,0,0,24,0V140h46.1l60.4,77.4A12.1,12.1,0,0,0,192,222a10.7,10.7,0,0,0,3.9-.7A12,12,0,0,0,204,210V140h12a12,12,0,0,0,0-24ZM76,116V80.9L103.4,116Zm104,59.1L152.6,140H180Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M216,136H200V120h16a8,8,0,0,0,0-16H200V46a8,8,0,0,0-16,0v58H119.4L70.3,41.1A8,8,0,0,0,56,46v58H40a8,8,0,0,0,0,16H56v16H40a8,8,0,0,0,0,16H56v58a8,8,0,0,0,16,0V152h64.6l49.1,62.9a8.1,8.1,0,0,0,8.9,2.7A8.1,8.1,0,0,0,200,210V152h16a8,8,0,0,0,0-16Zm-32-16v16H144.4l-12.5-16ZM72,69.3,99.1,104H72ZM72,136V120h39.6l12.5,16Zm112,50.7L156.9,152H184Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,136H200V120h16a8,8,0,0,0,0-16H200V46a8,8,0,0,0-16,0v58H119.4L70.3,41.1A8,8,0,0,0,56,46v58H40a8,8,0,0,0,0,16H56v16H40a8,8,0,0,0,0,16H56v58a8,8,0,0,0,16,0V152h64.6l49.1,62.9a8.1,8.1,0,0,0,8.9,2.7A8.1,8.1,0,0,0,200,210V152h16a8,8,0,0,0,0-16Zm-32-16v16H144.4l-12.5-16ZM72,69.3,99.1,104H72ZM72,136V120h39.6l12.5,16Zm112,50.7L156.9,152H184Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M216,138H198V118h18a6,6,0,0,0,0-12H198V46a6,6,0,0,0-12,0v60H118.4L68.7,42.3A6,6,0,0,0,58,46v60H40a6,6,0,0,0,0,12H58v20H40a6,6,0,0,0,0,12H58v60a6,6,0,0,0,12,0V150h67.6l49.7,63.7A5.8,5.8,0,0,0,192,216a6.9,6.9,0,0,0,2-.3,6,6,0,0,0,4-5.7V150h18a6,6,0,0,0,0-12Zm-30-20v20H143.4l-15.6-20ZM70,63.4,103.2,106H70ZM70,138V118h42.6l15.6,20Zm116,54.6L152.8,150H186Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M216,140H196V116h20a4,4,0,0,0,0-8H196V46a4,4,0,0,0-8,0v62H117.5L67.2,43.5a4.3,4.3,0,0,0-4.5-1.3A4.1,4.1,0,0,0,60,46v62H40a4,4,0,0,0,0,8H60v24H40a4,4,0,0,0,0,8H60v62a4,4,0,0,0,8,0V148h70.5l50.3,64.5A4.1,4.1,0,0,0,192,214l1.3-.2A4.1,4.1,0,0,0,196,210V148h20a4,4,0,0,0,0-8Zm-28-24v24H142.4l-18.7-24ZM68,57.6,107.3,108H68ZM68,140V116h45.6l18.7,24Zm120,58.4L148.7,148H188Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,136H200V120h16a8,8,0,0,0,0-16H200V46a8,8,0,0,0-16,0v58H119.4L70.3,41.1A8,8,0,0,0,56,46v58H40a8,8,0,0,0,0,16H56v16H40a8,8,0,0,0,0,16H56v58a8,8,0,0,0,16,0V152h64.6l49.1,62.9a8.1,8.1,0,0,0,8.9,2.7A8.1,8.1,0,0,0,200,210V152h16a8,8,0,0,0,0-16Zm-32-16v16H144.4l-12.5-16ZM72,69.3,99.1,104H72ZM72,136V120h39.6l12.5,16Zm112,50.7L156.9,152H184Z" />
        </>
      )}
    </svg>
  );
}

export function PhCurrencyRub(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M148,156a64,64,0,0,0,0-128H88A12,12,0,0,0,76,40v92H56a12,12,0,0,0,0,24H76v16H56a12,12,0,0,0,0,24H76v20a12,12,0,0,0,24,0V196h44a12,12,0,0,0,0-24H100V156ZM100,52h48a40,40,0,0,1,0,80H100Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M148,152a60,60,0,0,0,0-120H88a8,8,0,0,0-8,8v96H56a8,8,0,0,0,0,16H80v16H56a8,8,0,0,0,0,16H80v32a8,8,0,0,0,16,0V184h48a8,8,0,0,0,0-16H96V152ZM96,48h52a44,44,0,0,1,0,88H96Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M148,152a60,60,0,0,0,0-120H88a8,8,0,0,0-8,8v96H56a8,8,0,0,0,0,16H80v16H56a8,8,0,0,0,0,16H80v32a8,8,0,0,0,16,0V184h48a8,8,0,0,0,0-16H96V152ZM96,48h52a44,44,0,0,1,0,88H96Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M148,150a58,58,0,0,0,0-116H88a6,6,0,0,0-6,6v98H56a6,6,0,0,0,0,12H82v20H56a6,6,0,0,0,0,12H82v34a6,6,0,0,0,12,0V182h50a6,6,0,0,0,0-12H94V150ZM94,46h54a46,46,0,0,1,0,92H94Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M148,36H88a4,4,0,0,0-4,4V140H56a4,4,0,0,0,0,8H84v24H56a4,4,0,0,0,0,8H84v36a4,4,0,0,0,8,0V180h52a4,4,0,0,0,0-8H92V148h56a56,56,0,0,0,0-112Zm0,104H92V44h56a48,48,0,0,1,0,96Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M148,152a60,60,0,0,0,0-120H88a8,8,0,0,0-8,8v96H56a8,8,0,0,0,0,16H80v16H56a8,8,0,0,0,0,16H80v32a8,8,0,0,0,16,0V184h48a8,8,0,0,0,0-16H96V152ZM96,48h52a44,44,0,0,1,0,88H96Z" />
        </>
      )}
    </svg>
  );
}

export function PhCursorText(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M188,208a12,12,0,0,1-12,12H160a43.8,43.8,0,0,1-32-13.8A43.8,43.8,0,0,1,96,220H80a12,12,0,0,1,0-24H96a20.1,20.1,0,0,0,20-20V140H104a12,12,0,0,1,0-24h12V80A20.1,20.1,0,0,0,96,60H80a12,12,0,0,1,0-24H96a43.8,43.8,0,0,1,32,13.8A43.8,43.8,0,0,1,160,36h16a12,12,0,0,1,0,24H160a20.1,20.1,0,0,0-20,20v36h12a12,12,0,0,1,0,24H140v36a20.1,20.1,0,0,0,20,20h16A12,12,0,0,1,188,208Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M184,208a8,8,0,0,1-8,8H160a40,40,0,0,1-32-16,40,40,0,0,1-32,16H80a8,8,0,0,1,0-16H96a24.1,24.1,0,0,0,24-24V136H104a8,8,0,0,1,0-16h16V80A24.1,24.1,0,0,0,96,56H80a8,8,0,0,1,0-16H96a40,40,0,0,1,32,16,40,40,0,0,1,32-16h16a8,8,0,0,1,0,16H160a24.1,24.1,0,0,0-24,24v40h16a8,8,0,0,1,0,16H136v40a24.1,24.1,0,0,0,24,24h16A8,8,0,0,1,184,208Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M184,208a8,8,0,0,1-8,8H160a40,40,0,0,1-32-16,40,40,0,0,1-32,16H80a8,8,0,0,1,0-16H96a24.1,24.1,0,0,0,24-24V136H104a8,8,0,0,1,0-16h16V80A24.1,24.1,0,0,0,96,56H80a8,8,0,0,1,0-16H96a40,40,0,0,1,32,16,40,40,0,0,1,32-16h16a8,8,0,0,1,0,16H160a24.1,24.1,0,0,0-24,24v40h16a8,8,0,0,1,0,16H136v40a24.1,24.1,0,0,0,24,24h16A8,8,0,0,1,184,208Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M182,208a6,6,0,0,1-6,6H160a38.1,38.1,0,0,1-32-17.5A38.1,38.1,0,0,1,96,214H80a6,6,0,0,1,0-12H96a26.1,26.1,0,0,0,26-26V134H104a6,6,0,0,1,0-12h18V80A26.1,26.1,0,0,0,96,54H80a6,6,0,0,1,0-12H96a38.1,38.1,0,0,1,32,17.5A38.1,38.1,0,0,1,160,42h16a6,6,0,0,1,0,12H160a26.1,26.1,0,0,0-26,26v42h18a6,6,0,0,1,0,12H134v42a26.1,26.1,0,0,0,26,26h16A6,6,0,0,1,182,208Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M180,208a4,4,0,0,1-4,4H160a36,36,0,0,1-32-19.5A36,36,0,0,1,96,212H80a4,4,0,0,1,0-8H96a28.1,28.1,0,0,0,28-28V132H104a4,4,0,0,1,0-8h20V80A28.1,28.1,0,0,0,96,52H80a4,4,0,0,1,0-8H96a36,36,0,0,1,32,19.5A36,36,0,0,1,160,44h16a4,4,0,0,1,0,8H160a28.1,28.1,0,0,0-28,28v44h20a4,4,0,0,1,0,8H132v44a28.1,28.1,0,0,0,28,28h16A4,4,0,0,1,180,208Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M184,208a8,8,0,0,1-8,8H160a40,40,0,0,1-32-16,40,40,0,0,1-32,16H80a8,8,0,0,1,0-16H96a24.1,24.1,0,0,0,24-24V136H104a8,8,0,0,1,0-16h16V80A24.1,24.1,0,0,0,96,56H80a8,8,0,0,1,0-16H96a40,40,0,0,1,32,16,40,40,0,0,1,32-16h16a8,8,0,0,1,0,16H160a24.1,24.1,0,0,0-24,24v40h16a8,8,0,0,1,0,16H136v40a24.1,24.1,0,0,0,24,24h16A8,8,0,0,1,184,208Z" />
        </>
      )}
    </svg>
  );
}

export function PhCursor(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M157.8,140.8l49.9-18.1A19.8,19.8,0,0,0,220.9,104a19.5,19.5,0,0,0-13-18.8L52.7,26.9A20,20,0,0,0,26.9,52.7L85.2,207.9a19.8,19.8,0,0,0,18.7,13h.1a19.7,19.7,0,0,0,18.7-13.2l18.1-49.9,58.7,58.7a12.1,12.1,0,0,0,17,0,12,12,0,0,0,0-17Zm-54,48.6L52.5,52.5l136.9,51.3-54,19.6a20,20,0,0,0-6.4,3.9h0l-1.7,1.7h0a20,20,0,0,0-3.9,6.4Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M38.2,48.5,96.4,203.7a8,8,0,0,0,15-.1l23.3-64.1a8.1,8.1,0,0,1,4.8-4.8l64.1-23.3a8,8,0,0,0,.1-15L48.5,38.2A8,8,0,0,0,38.2,48.5Z" />
          </g>
          <path d="M150.5,139.2l55.9-20.3a16,16,0,0,0,.1-30L51.3,30.7A16,16,0,0,0,30.7,51.3h0L88.9,206.5a15.9,15.9,0,0,0,15,10.4h.1a15.9,15.9,0,0,0,14.9-10.5l20.3-55.9,63.1,63.2A8.5,8.5,0,0,0,208,216a8.3,8.3,0,0,0,5.7-2.3,8.1,8.1,0,0,0,0-11.4Zm-46.6,61.7L45.7,45.7l155.2,58.2-64.1,23.3a15.7,15.7,0,0,0-9.6,9.6Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M213.7,202.3a8.1,8.1,0,0,1,0,11.4A8.3,8.3,0,0,1,208,216a8.5,8.5,0,0,1-5.7-2.3l-63.1-63.2-20.3,55.9A15.9,15.9,0,0,1,104,216.9h-.1a15.9,15.9,0,0,1-15-10.4L30.7,51.3h0A16,16,0,0,1,51.3,30.7L206.5,88.9a16,16,0,0,1-.1,30l-55.9,20.3Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M146.9,138.4,205.7,117a13.7,13.7,0,0,0,9.2-13,13.8,13.8,0,0,0-9.1-13.2L50.6,32.6a14,14,0,0,0-18,18h0L90.8,205.8a13.9,13.9,0,0,0,13.1,9.1h.1a13.7,13.7,0,0,0,13-9.2l21.4-58.8,65.4,65.3a5.9,5.9,0,0,0,8.4-8.4Zm-41.1,63.2a1.9,1.9,0,0,1-1.9,1.3h0a1.8,1.8,0,0,1-1.9-1.3L43.8,46.4h0a1.7,1.7,0,0,1,.5-2.1,1.6,1.6,0,0,1,1.3-.6h.8L201.6,102a2,2,0,0,1,0,3.8l-64.1,23.3a14,14,0,0,0-8.4,8.4Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M143.3,137.6,205,115.2a12.1,12.1,0,0,0,.1-22.6L49.9,34.4A12.1,12.1,0,0,0,34.4,49.9L92.7,205.1a11.7,11.7,0,0,0,11.2,7.8h0a12,12,0,0,0,11.3-7.9l22.4-61.7,67.6,67.5A3.9,3.9,0,0,0,208,212a3.8,3.8,0,0,0,2.8-1.2,3.9,3.9,0,0,0,0-5.6Zm-35.7,64.7a3.8,3.8,0,0,1-3.7,2.6h0a3.9,3.9,0,0,1-3.8-2.6L41.9,47.1h0a4.2,4.2,0,0,1,.9-4.3,4.6,4.6,0,0,1,2.8-1.1,3.9,3.9,0,0,1,1.5.2l155.2,58.2a4,4,0,0,1,2.6,3.8,3.8,3.8,0,0,1-2.6,3.7L138.1,131a11.9,11.9,0,0,0-7.1,7.1Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M150.5,139.2l55.9-20.3a16,16,0,0,0,.1-30L51.3,30.7A16,16,0,0,0,30.7,51.3h0L88.9,206.5a15.9,15.9,0,0,0,15,10.4h.1a15.9,15.9,0,0,0,14.9-10.5l20.3-55.9,63.1,63.2A8.5,8.5,0,0,0,208,216a8.3,8.3,0,0,0,5.7-2.3,8.1,8.1,0,0,0,0-11.4Zm-46.6,61.7L45.7,45.7l155.2,58.2-64.1,23.3a15.7,15.7,0,0,0-9.6,9.6Z" />
        </>
      )}
    </svg>
  );
}

export function PhCylinder(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,12C84.7,12,52,31.8,52,58V198c0,26.2,32.7,46,76,46s76-19.8,76-46V58C204,31.8,171.3,12,128,12Zm0,24c31.7,0,52,13,52,22s-20.3,22-52,22S76,67,76,58,96.3,36,128,36Zm0,184c-31.7,0-52-13-52-22V92.1c13.4,7.5,31.5,11.9,52,11.9s38.6-4.4,52-11.9V198C180,207,159.7,220,128,220Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M192,58c0,18.8-28.7,34-64,34S64,76.8,64,58s28.7-34,64-34S192,39.2,192,58Z"
            opacity="0.2"
          />
          <path d="M128,16C87.6,16,56,34.4,56,58V198c0,23.6,31.6,42,72,42s72-18.4,72-42V58C200,34.4,168.4,16,128,16Zm0,16c33,0,56,13.7,56,26s-23,26-56,26S72,70.3,72,58,95,32,128,32Zm0,192c-33,0-56-13.7-56-26V84.7c13.1,9.4,33.1,15.3,56,15.3s42.9-5.9,56-15.3V198C184,210.3,161,224,128,224Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,16C87.6,16,56,34.4,56,58V198c0,23.6,31.6,42,72,42s72-18.4,72-42V58C200,34.4,168.4,16,128,16Zm56,182c0,12.3-23,26-56,26s-56-13.7-56-26V84.7c13.1,9.4,33.1,15.3,56,15.3s42.9-5.9,56-15.3Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,18C88.7,18,58,35.6,58,58V198c0,22.4,30.7,40,70,40s70-17.6,70-40V58C198,35.6,167.3,18,128,18ZM85.6,39.3c11.2-6,26.3-9.3,42.4-9.3s31.2,3.3,42.4,9.3S186,51.3,186,58s-5.7,13.5-15.6,18.7S144.1,86,128,86s-31.2-3.3-42.4-9.3S70,64.7,70,58,75.7,44.5,85.6,39.3Zm84.8,177.4c-11.2,6-26.3,9.3-42.4,9.3s-31.2-3.3-42.4-9.3S70,204.7,70,198V80.7C82.5,91.2,103.6,98,128,98s45.5-6.8,58-17.3V198C186,204.7,180.3,211.5,170.4,216.7Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,20C89.9,20,60,36.7,60,58V198c0,21.3,29.9,38,68,38s68-16.7,68-38V58C196,36.7,166.1,20,128,20Zm0,8c32.5,0,60,13.7,60,30s-27.5,30-60,30S68,74.3,68,58,95.5,28,128,28Zm60,170c0,16.3-27.5,30-60,30s-60-13.7-60-30V76.2C79.4,88.1,101.7,96,128,96s48.6-7.9,60-19.8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,16C87.6,16,56,34.4,56,58V198c0,23.6,31.6,42,72,42s72-18.4,72-42V58C200,34.4,168.4,16,128,16Zm0,16c33,0,56,13.7,56,26s-23,26-56,26S72,70.3,72,58,95,32,128,32Zm0,192c-33,0-56-13.7-56-26V84.7c13.1,9.4,33.1,15.3,56,15.3s42.9-5.9,56-15.3V198C184,210.3,161,224,128,224Z" />
        </>
      )}
    </svg>
  );
}

export function PhDatabase(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M196,35.5C177.6,25.5,153.5,20,128,20S78.4,25.5,60,35.5C39.4,46.8,28,62.6,28,80v96c0,17.4,11.4,33.2,32,44.5,18.4,10,42.5,15.5,68,15.5s49.6-5.5,68-15.5c20.6-11.3,32-27.1,32-44.5V80C228,62.6,216.6,46.8,196,35.5Zm8,92.5c0,17-31.2,36-76,36s-76-19-76-36v-8.5a90.3,90.3,0,0,0,8,5c18.4,10,42.5,15.5,68,15.5s49.6-5.5,68-15.5a90.3,90.3,0,0,0,8-5ZM128,44c44.8,0,76,19,76,36s-31.2,36-76,36S52,97,52,80,83.2,44,128,44Zm0,168c-44.8,0-76-19-76-36v-8.5a90.3,90.3,0,0,0,8,5c18.4,10,42.5,15.5,68,15.5s49.6-5.5,68-15.5a90.3,90.3,0,0,0,8-5V176C204,193,172.8,212,128,212Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <ellipse cx="128" cy="80" rx="88" ry="48" />
          </g>
          <path d="M128,24C74.2,24,32,48.6,32,80v96c0,31.4,42.2,56,96,56s96-24.6,96-56V80C224,48.6,181.8,24,128,24Zm80,104c0,9.6-7.9,19.4-21.6,26.9C170.9,163.4,150.2,168,128,168s-42.9-4.6-58.4-13.1C55.9,147.4,48,137.6,48,128V111.4C65.1,126.3,94.2,136,128,136s62.9-9.7,80-24.6ZM69.6,53.1C85.1,44.6,105.8,40,128,40s42.9,4.6,58.4,13.1C200.1,60.6,208,70.4,208,80s-7.9,19.4-21.6,26.9C170.9,115.4,150.2,120,128,120s-42.9-4.6-58.4-13.1C55.9,99.4,48,89.6,48,80S55.9,60.6,69.6,53.1ZM186.4,202.9C170.9,211.4,150.2,216,128,216s-42.9-4.6-58.4-13.1C55.9,195.4,48,185.6,48,176V159.4C65.1,174.3,94.2,184,128,184s62.9-9.7,80-24.6V176C208,185.6,200.1,195.4,186.4,202.9Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,24C74.2,24,32,48.6,32,80v96c0,31.4,42.2,56,96,56s96-24.6,96-56V80C224,48.6,181.8,24,128,24Zm80,104c0,9.6-7.9,19.4-21.6,26.9C170.9,163.4,150.2,168,128,168s-42.9-4.6-58.4-13.1C55.9,147.4,48,137.6,48,128V111.4C65.1,126.3,94.2,136,128,136s62.9-9.7,80-24.6Zm-21.6,74.9C170.9,211.4,150.2,216,128,216s-42.9-4.6-58.4-13.1C55.9,195.4,48,185.6,48,176V159.4C65.1,174.3,94.2,184,128,184s62.9-9.7,80-24.6V176C208,185.6,200.1,195.4,186.4,202.9Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,26C75.3,26,34,49.7,34,80v96c0,30.3,41.3,54,94,54s94-23.7,94-54V80C222,49.7,180.7,26,128,26Zm0,12c44.4,0,82,19.2,82,42s-37.6,42-82,42S46,102.8,46,80,83.6,38,128,38Zm82,138c0,22.8-37.6,42-82,42s-82-19.2-82-42V154.8C62,171.2,92.4,182,128,182s66-10.8,82-27.2Zm0-48c0,22.8-37.6,42-82,42s-82-19.2-82-42V106.8C62,123.2,92.4,134,128,134s66-10.8,82-27.2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M192.1,42.5C174.9,33.2,152.2,28,128,28S81.1,33.2,63.9,42.5,36,65.6,36,80v96c0,14.4,9.9,27.7,27.9,37.5S103.8,228,128,228s46.9-5.2,64.1-14.5S220,190.4,220,176V80C220,65.6,210.1,52.3,192.1,42.5ZM67.7,49.6C83.7,40.8,105.2,36,128,36s44.3,4.8,60.3,13.6S212,68.7,212,80s-8.4,22.1-23.7,30.4S150.8,124,128,124s-44.3-4.8-60.3-13.6S44,91.3,44,80,52.4,57.9,67.7,49.6ZM212,176c0,11.3-8.4,22.1-23.7,30.4S150.8,220,128,220s-44.3-4.8-60.3-13.6S44,187.3,44,176V149.5c4.7,5.9,11.4,11.3,19.9,16C81.1,174.8,103.8,180,128,180s46.9-5.2,64.1-14.5c8.5-4.7,15.2-10.1,19.9-16Zm0-48c0,11.3-8.4,22.1-23.7,30.4S150.8,172,128,172s-44.3-4.8-60.3-13.6S44,139.3,44,128V101.5c4.7,5.9,11.4,11.3,19.9,16C81.1,126.8,103.8,132,128,132s46.9-5.2,64.1-14.5c8.5-4.7,15.2-10.1,19.9-16Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,24C74.2,24,32,48.6,32,80v96c0,31.4,42.2,56,96,56s96-24.6,96-56V80C224,48.6,181.8,24,128,24Zm80,104c0,9.6-7.9,19.4-21.6,26.9C170.9,163.4,150.2,168,128,168s-42.9-4.6-58.4-13.1C55.9,147.4,48,137.6,48,128V111.4C65.1,126.3,94.2,136,128,136s62.9-9.7,80-24.6ZM69.6,53.1C85.1,44.6,105.8,40,128,40s42.9,4.6,58.4,13.1C200.1,60.6,208,70.4,208,80s-7.9,19.4-21.6,26.9C170.9,115.4,150.2,120,128,120s-42.9-4.6-58.4-13.1C55.9,99.4,48,89.6,48,80S55.9,60.6,69.6,53.1ZM186.4,202.9C170.9,211.4,150.2,216,128,216s-42.9-4.6-58.4-13.1C55.9,195.4,48,185.6,48,176V159.4C65.1,174.3,94.2,184,128,184s62.9-9.7,80-24.6V176C208,185.6,200.1,195.4,186.4,202.9Z" />
        </>
      )}
    </svg>
  );
}

export function PhDesktopTower(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M28,96v68a4,4,0,0,0,4,4h76a12,12,0,0,1,0,24h-8v12h8a12,12,0,0,1,0,24H68a12,12,0,0,1,0-24h8V192H32A28.1,28.1,0,0,1,4,164V96A28.1,28.1,0,0,1,32,68h76a12,12,0,0,1,0,24H32A4,4,0,0,0,28,96Zm152-8h24a12,12,0,0,0,0-24H180a12,12,0,0,0,0,24Zm0,36h24a12,12,0,0,0,0-24H180a12,12,0,0,0,0,24Zm72-76V208a20.1,20.1,0,0,1-20,20H152a20.1,20.1,0,0,1-20-20V48a20.1,20.1,0,0,1,20-20h80A20.1,20.1,0,0,1,252,48Zm-24,4H156V204h72ZM192,160a16,16,0,1,0,16,16A16,16,0,0,0,192,160Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect x="144" y="40" width="96" height="176" rx="8" />
          </g>
          <path d="M24,96v72a8,8,0,0,0,8,8h80a8,8,0,0,1,0,16H96v16h16a8,8,0,0,1,0,16H64a8,8,0,0,1,0-16H80V192H32A24.1,24.1,0,0,1,8,168V96A24.1,24.1,0,0,1,32,72h80a8,8,0,0,1,0,16H32A8,8,0,0,0,24,96ZM208,64H176a8,8,0,0,0,0,16h32a8,8,0,0,0,0-16Zm0,32H176a8,8,0,0,0,0,16h32a8,8,0,0,0,0-16Zm40-48V208a16,16,0,0,1-16,16H152a16,16,0,0,1-16-16V48a16,16,0,0,1,16-16h80A16,16,0,0,1,248,48ZM232,208V48H152V208Zm-40-40a12,12,0,1,0,12,12A12,12,0,0,0,192,168Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M120,80V184a8,8,0,0,1-8,8H96v16h16a8,8,0,0,1,0,16H64a8,8,0,0,1,0-16H80V192H32A24.1,24.1,0,0,1,8,168V96A24.1,24.1,0,0,1,32,72h80A8,8,0,0,1,120,80ZM248,48V208a16,16,0,0,1-16,16H152a16,16,0,0,1-16-16V48a16,16,0,0,1,16-16h80A16,16,0,0,1,248,48ZM204,180a12,12,0,1,0-12,12A12,12,0,0,0,204,180Zm20-76a8,8,0,0,0-8-8H168a8,8,0,0,0,0,16h48A8,8,0,0,0,224,104Zm0-32a8,8,0,0,0-8-8H168a8,8,0,0,0,0,16h48A8,8,0,0,0,224,72Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M22,96v72a10,10,0,0,0,10,10h80a6,6,0,0,1,0,12H94v20h18a6,6,0,0,1,0,12H64a6,6,0,0,1,0-12H82V190H32a22.1,22.1,0,0,1-22-22V96A22.1,22.1,0,0,1,32,74h80a6,6,0,0,1,0,12H32A10,10,0,0,0,22,96ZM208,66H176a6,6,0,0,0,0,12h32a6,6,0,0,0,0-12Zm0,32H176a6,6,0,0,0,0,12h32a6,6,0,0,0,0-12Zm38-50V208a14,14,0,0,1-14,14H152a14,14,0,0,1-14-14V48a14,14,0,0,1,14-14h80A14,14,0,0,1,246,48Zm-12,0a2,2,0,0,0-2-2H152a2,2,0,0,0-2,2V208a2,2,0,0,0,2,2h80a2,2,0,0,0,2-2ZM192,170a10,10,0,1,0,10,10A10,10,0,0,0,192,170Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M20,96v72a12,12,0,0,0,12,12h80a4,4,0,0,1,0,8H92v24h20a4,4,0,0,1,0,8H64a4,4,0,0,1,0-8H84V188H32a20.1,20.1,0,0,1-20-20V96A20.1,20.1,0,0,1,32,76h80a4,4,0,0,1,0,8H32A12,12,0,0,0,20,96ZM208,68H176a4,4,0,0,0,0,8h32a4,4,0,0,0,0-8Zm0,32H176a4,4,0,0,0,0,8h32a4,4,0,0,0,0-8Zm36-52V208a12,12,0,0,1-12,12H152a12,12,0,0,1-12-12V48a12,12,0,0,1,12-12h80A12,12,0,0,1,244,48Zm-8,0a4,4,0,0,0-4-4H152a4,4,0,0,0-4,4V208a4,4,0,0,0,4,4h80a4,4,0,0,0,4-4ZM192,172a8,8,0,1,0,8,8A8,8,0,0,0,192,172Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M24,96v72a8,8,0,0,0,8,8h80a8,8,0,0,1,0,16H96v16h16a8,8,0,0,1,0,16H64a8,8,0,0,1,0-16H80V192H32A24.1,24.1,0,0,1,8,168V96A24.1,24.1,0,0,1,32,72h80a8,8,0,0,1,0,16H32A8,8,0,0,0,24,96ZM208,64H176a8,8,0,0,0,0,16h32a8,8,0,0,0,0-16Zm0,32H176a8,8,0,0,0,0,16h32a8,8,0,0,0,0-16Zm40-48V208a16,16,0,0,1-16,16H152a16,16,0,0,1-16-16V48a16,16,0,0,1,16-16h80A16,16,0,0,1,248,48ZM232,208V48H152V208Zm-40-40a12,12,0,1,0,12,12A12,12,0,0,0,192,168Z" />
        </>
      )}
    </svg>
  );
}

export function PhDesktop(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M208,36H48A28.1,28.1,0,0,0,20,64V172a28.1,28.1,0,0,0,28,28h68v12H96a12,12,0,0,0,0,24h64a12,12,0,0,0,0-24H140V200h68a28.1,28.1,0,0,0,28-28V64A28.1,28.1,0,0,0,208,36ZM48,60H208a4,4,0,0,1,4,4v72H44V64A4,4,0,0,1,48,60ZM208,176H48a4,4,0,0,1-4-4V160H212v12A4,4,0,0,1,208,176Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M32,152V64A16,16,0,0,1,48,48H208a16,16,0,0,1,16,16v88Z" />
          </g>
          <path d="M208,40H48A24.1,24.1,0,0,0,24,64V176a24.1,24.1,0,0,0,24,24h72v16H96a8,8,0,0,0,0,16h64a8,8,0,0,0,0-16H136V200h72a24.1,24.1,0,0,0,24-24V64A24.1,24.1,0,0,0,208,40ZM48,56H208a8,8,0,0,1,8,8v80H40V64A8,8,0,0,1,48,56ZM208,184H48a8,8,0,0,1-8-8V160H216v16A8,8,0,0,1,208,184Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M208,40H48A24.1,24.1,0,0,0,24,64V176a24.1,24.1,0,0,0,24,24h72v16H96a8,8,0,0,0,0,16h64a8,8,0,0,0,0-16H136V200h72a24.1,24.1,0,0,0,24-24V64A24.1,24.1,0,0,0,208,40Zm8,136a8,8,0,0,1-8,8H48a8,8,0,0,1-8-8V160H216Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M208,42H48A22.1,22.1,0,0,0,26,64V176a22.1,22.1,0,0,0,22,22h74v20H96a6,6,0,0,0,0,12h64a6,6,0,0,0,0-12H134V198h74a22.1,22.1,0,0,0,22-22V64A22.1,22.1,0,0,0,208,42ZM48,54H208a10,10,0,0,1,10,10v82H38V64A10,10,0,0,1,48,54ZM208,186H48a10,10,0,0,1-10-10V158H218v18A10,10,0,0,1,208,186Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M208,44H48A20.1,20.1,0,0,0,28,64V176a20.1,20.1,0,0,0,20,20h76v24H96a4,4,0,0,0,0,8h64a4,4,0,0,0,0-8H132V196h76a20.1,20.1,0,0,0,20-20V64A20.1,20.1,0,0,0,208,44ZM48,52H208a12,12,0,0,1,12,12v84H36V64A12,12,0,0,1,48,52ZM208,188H48a12,12,0,0,1-12-12V156H220v20A12,12,0,0,1,208,188Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M208,40H48A24.1,24.1,0,0,0,24,64V176a24.1,24.1,0,0,0,24,24h72v16H96a8,8,0,0,0,0,16h64a8,8,0,0,0,0-16H136V200h72a24.1,24.1,0,0,0,24-24V64A24.1,24.1,0,0,0,208,40ZM48,56H208a8,8,0,0,1,8,8v80H40V64A8,8,0,0,1,48,56ZM208,184H48a8,8,0,0,1-8-8V160H216v16A8,8,0,0,1,208,184Z" />
        </>
      )}
    </svg>
  );
}

export function PhDetective(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M182,144a37.9,37.9,0,0,0-36,26H110a38,38,0,1,0,0,24h36a38,38,0,1,0,36-50ZM74,196a14,14,0,1,1,14-14A14,14,0,0,1,74,196Zm108,0a14,14,0,1,1,14-14A14,14,0,0,1,182,196Zm62-88H222.2L176.5,42.7a19.8,19.8,0,0,0-16.6-8.5,20.1,20.1,0,0,0-16.4,8.9L128,66.4,112.5,43.1a20.1,20.1,0,0,0-16.4-8.9,19.7,19.7,0,0,0-16.6,8.5L33.8,108H12a12,12,0,0,0,0,24H244a12,12,0,0,0,0-24ZM95.8,61.3l15.6,23.4a19.9,19.9,0,0,0,33.2,0l15.6-23.4L193,108H63Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M104,180a28,28,0,1,1-28-28A28,28,0,0,1,104,180Zm76-28a28,28,0,1,0,28,28A28,28,0,0,0,180,152ZM166.7,49.6a8,8,0,0,0-13.2.1L134.7,78a8,8,0,0,1-13.4,0L102.5,49.7a8,8,0,0,0-13.2-.1L40,120H216Z"
            opacity="0.2"
          />
          <path d="M180,144a36.1,36.1,0,0,0-35.1,28H111.1a36,36,0,1,0,0,16h33.8A36,36,0,1,0,180,144ZM76,200a20,20,0,1,1,20-20A20.1,20.1,0,0,1,76,200Zm104,0a20,20,0,1,1,20-20A20.1,20.1,0,0,1,180,200Zm68-88H220.2L173.3,45a16.1,16.1,0,0,0-26.5.3L128,73.6,109.2,45.3A16.1,16.1,0,0,0,82.7,45L35.8,112H8a8,8,0,0,0,0,16H248a8,8,0,0,0,0-16ZM95.8,54.2l18.9,28.3a16,16,0,0,0,26.6,0l18.9-28.3L200.6,112H55.4Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,180a36,36,0,0,1-71.1,8H111.1a36,36,0,1,1,0-16h33.8a36,36,0,0,1,71.1,8Zm32-68H220.2L173.3,45a16.1,16.1,0,0,0-26.5.3L128,73.6,109.2,45.3A16.1,16.1,0,0,0,82.7,45L35.8,112H8a8,8,0,0,0,0,16H248a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M180,146a34.1,34.1,0,0,0-33.5,28h-37a34,34,0,1,0,0,12h37A34,34,0,1,0,180,146ZM76,202a22,22,0,1,1,22-22A22.1,22.1,0,0,1,76,202Zm104,0a22,22,0,1,1,22-22A22.1,22.1,0,0,1,180,202Zm68-88H219.1L171.6,46.2a13.8,13.8,0,0,0-11.6-6,14.1,14.1,0,0,0-11.5,6.2L129.7,74.7a2.1,2.1,0,0,1-3.4,0L107.5,46.4A14.1,14.1,0,0,0,96,40.2a13.8,13.8,0,0,0-11.6,6L36.9,114H8a6,6,0,0,0,0,12H248a6,6,0,0,0,0-12ZM94.2,53a2.1,2.1,0,0,1,1.7-.8,1.8,1.8,0,0,1,1.6.9l18.9,28.2a13.8,13.8,0,0,0,23.2,0l18.9-28.2a1.8,1.8,0,0,1,1.6-.9,2.1,2.1,0,0,1,1.7.8l42.7,61H51.5Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M180,148a31.9,31.9,0,0,0-31.7,28H107.7a32,32,0,1,0,0,8h40.6A32,32,0,1,0,180,148ZM76,204a24,24,0,1,1,24-24A24.1,24.1,0,0,1,76,204Zm104,0a24,24,0,1,1,24-24A24.1,24.1,0,0,1,180,204Zm68-88H218.1L170,47.3a12,12,0,0,0-9.8-5.1H160a11.8,11.8,0,0,0-9.8,5.3L131.3,75.8a3.9,3.9,0,0,1-6.6,0L105.8,47.5A12,12,0,0,0,86,47.3L37.9,116H8a4,4,0,0,0,0,8H248a4,4,0,0,0,0-8ZM92.6,51.9a3.8,3.8,0,0,1,3.3-1.7A3.9,3.9,0,0,1,99.2,52L118,80.2a12,12,0,0,0,20,0L156.8,52a3.9,3.9,0,0,1,3.3-1.8,3.8,3.8,0,0,1,3.3,1.7L208.3,116H47.7Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M180,144a36.1,36.1,0,0,0-35.1,28H111.1a36,36,0,1,0,0,16h33.8A36,36,0,1,0,180,144ZM76,200a20,20,0,1,1,20-20A20.1,20.1,0,0,1,76,200Zm104,0a20,20,0,1,1,20-20A20.1,20.1,0,0,1,180,200Zm68-88H220.2L173.3,45a15.9,15.9,0,0,0-13.1-6.8H160a16,16,0,0,0-13.2,7.1L128,73.6,109.2,45.3A16.1,16.1,0,0,0,82.7,45L35.8,112H8a8,8,0,0,0,0,16H248a8,8,0,0,0,0-16ZM95.8,54.2l18.9,28.3a16,16,0,0,0,26.6,0l18.9-28.3L200.6,112H55.4Z" />
        </>
      )}
    </svg>
  );
}

export function PhDeviceMobileCamera(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M176,12H80A28.1,28.1,0,0,0,52,40V216a28.1,28.1,0,0,0,28,28h96a28.1,28.1,0,0,0,28-28V40A28.1,28.1,0,0,0,176,12Zm4,204a4,4,0,0,1-4,4H80a4,4,0,0,1-4-4V40a4,4,0,0,1,4-4h96a4,4,0,0,1,4,4ZM144,68a16,16,0,1,1-16-16A16,16,0,0,1,144,68Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect
              x="24"
              y="64"
              width="208"
              height="128"
              rx="16"
              transform="translate(256) rotate(90)"
            />
          </g>
          <circle cx="128" cy="60" r="12" />
          <path d="M176,16H80A24.1,24.1,0,0,0,56,40V216a24.1,24.1,0,0,0,24,24h96a24.1,24.1,0,0,0,24-24V40A24.1,24.1,0,0,0,176,16Zm8,200a8,8,0,0,1-8,8H80a8,8,0,0,1-8-8V40a8,8,0,0,1,8-8h96a8,8,0,0,1,8,8Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M176,16H80A24.1,24.1,0,0,0,56,40V216a24.1,24.1,0,0,0,24,24h96a24.1,24.1,0,0,0,24-24V40A24.1,24.1,0,0,0,176,16ZM128,72a12,12,0,1,1,12-12A12,12,0,0,1,128,72Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M176,18H80A22.1,22.1,0,0,0,58,40V216a22.1,22.1,0,0,0,22,22h96a22.1,22.1,0,0,0,22-22V40A22.1,22.1,0,0,0,176,18Zm10,198a10,10,0,0,1-10,10H80a10,10,0,0,1-10-10V40A10,10,0,0,1,80,30h96a10,10,0,0,1,10,10ZM138,60a10,10,0,1,1-10-10A10,10,0,0,1,138,60Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M176,20H80A20.1,20.1,0,0,0,60,40V216a20.1,20.1,0,0,0,20,20h96a20.1,20.1,0,0,0,20-20V40A20.1,20.1,0,0,0,176,20Zm12,196a12,12,0,0,1-12,12H80a12,12,0,0,1-12-12V40A12,12,0,0,1,80,28h96a12,12,0,0,1,12,12ZM136,60a8,8,0,1,1-8-8A8,8,0,0,1,136,60Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M176,16H80A24.1,24.1,0,0,0,56,40V216a24.1,24.1,0,0,0,24,24h96a24.1,24.1,0,0,0,24-24V40A24.1,24.1,0,0,0,176,16Zm8,200a8,8,0,0,1-8,8H80a8,8,0,0,1-8-8V40a8,8,0,0,1,8-8h96a8,8,0,0,1,8,8ZM140,60a12,12,0,1,1-12-12A12,12,0,0,1,140,60Z" />
        </>
      )}
    </svg>
  );
}

export function PhDeviceMobileSpeaker(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M176,12H80A28.1,28.1,0,0,0,52,40V216a28.1,28.1,0,0,0,28,28h96a28.1,28.1,0,0,0,28-28V40A28.1,28.1,0,0,0,176,12Zm4,204a4,4,0,0,1-4,4H80a4,4,0,0,1-4-4V40a4,4,0,0,1,4-4h96a4,4,0,0,1,4,4ZM164,64a12,12,0,0,1-12,12H104a12,12,0,0,1,0-24h48A12,12,0,0,1,164,64Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect
              x="24"
              y="64"
              width="208"
              height="128"
              rx="16"
              transform="translate(256) rotate(90)"
            />
          </g>
          <path d="M176,16H80A24.1,24.1,0,0,0,56,40V216a24.1,24.1,0,0,0,24,24h96a24.1,24.1,0,0,0,24-24V40A24.1,24.1,0,0,0,176,16Zm8,200a8,8,0,0,1-8,8H80a8,8,0,0,1-8-8V40a8,8,0,0,1,8-8h96a8,8,0,0,1,8,8Z" />
          <path d="M160,48H96a8,8,0,0,0,0,16h64a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M176,16H80A24.1,24.1,0,0,0,56,40V216a24.1,24.1,0,0,0,24,24h96a24.1,24.1,0,0,0,24-24V40A24.1,24.1,0,0,0,176,16ZM160,64H96a8,8,0,0,1,0-16h64a8,8,0,0,1,0,16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M176,18H80A22.1,22.1,0,0,0,58,40V216a22.1,22.1,0,0,0,22,22h96a22.1,22.1,0,0,0,22-22V40A22.1,22.1,0,0,0,176,18Zm10,198a10,10,0,0,1-10,10H80a10,10,0,0,1-10-10V40A10,10,0,0,1,80,30h96a10,10,0,0,1,10,10ZM166,56a6,6,0,0,1-6,6H96a6,6,0,0,1,0-12h64A6,6,0,0,1,166,56Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M176,20H80A20.1,20.1,0,0,0,60,40V216a20.1,20.1,0,0,0,20,20h96a20.1,20.1,0,0,0,20-20V40A20.1,20.1,0,0,0,176,20Zm12,196a12,12,0,0,1-12,12H80a12,12,0,0,1-12-12V40A12,12,0,0,1,80,28h96a12,12,0,0,1,12,12ZM164,56a4,4,0,0,1-4,4H96a4,4,0,0,1,0-8h64A4,4,0,0,1,164,56Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M176,16H80A24.1,24.1,0,0,0,56,40V216a24.1,24.1,0,0,0,24,24h96a24.1,24.1,0,0,0,24-24V40A24.1,24.1,0,0,0,176,16Zm8,200a8,8,0,0,1-8,8H80a8,8,0,0,1-8-8V40a8,8,0,0,1,8-8h96a8,8,0,0,1,8,8ZM168,56a8,8,0,0,1-8,8H96a8,8,0,0,1,0-16h64A8,8,0,0,1,168,56Z" />
        </>
      )}
    </svg>
  );
}

export function PhDeviceMobile(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M176,12H80A28.1,28.1,0,0,0,52,40V216a28.1,28.1,0,0,0,28,28h96a28.1,28.1,0,0,0,28-28V40A28.1,28.1,0,0,0,176,12ZM76,76H180V180H76Zm4-40h96a4,4,0,0,1,4,4V52H76V40A4,4,0,0,1,80,36Zm96,184H80a4,4,0,0,1-4-4V204H180v12A4,4,0,0,1,176,220Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect x="64" y="56" width="128" height="144" />
          </g>
          <path d="M176,16H80A24.1,24.1,0,0,0,56,40V216a24.1,24.1,0,0,0,24,24h96a24.1,24.1,0,0,0,24-24V40A24.1,24.1,0,0,0,176,16ZM72,64H184V192H72Zm8-32h96a8,8,0,0,1,8,8v8H72V40A8,8,0,0,1,80,32Zm96,192H80a8,8,0,0,1-8-8v-8H184v8A8,8,0,0,1,176,224Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M176,16H80A24.1,24.1,0,0,0,56,40V216a24.1,24.1,0,0,0,24,24h96a24.1,24.1,0,0,0,24-24V40A24.1,24.1,0,0,0,176,16ZM80,32h96a8,8,0,0,1,8,8v8H72V40A8,8,0,0,1,80,32Zm96,192H80a8,8,0,0,1-8-8v-8H184v8A8,8,0,0,1,176,224Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M176,18H80A22.1,22.1,0,0,0,58,40V216a22.1,22.1,0,0,0,22,22h96a22.1,22.1,0,0,0,22-22V40A22.1,22.1,0,0,0,176,18ZM70,62H186V194H70ZM80,30h96a10,10,0,0,1,10,10V50H70V40A10,10,0,0,1,80,30Zm96,196H80a10,10,0,0,1-10-10V206H186v10A10,10,0,0,1,176,226Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M176,20H80A20.1,20.1,0,0,0,60,40V216a20.1,20.1,0,0,0,20,20h96a20.1,20.1,0,0,0,20-20V40A20.1,20.1,0,0,0,176,20ZM68,60H188V196H68ZM80,28h96a12,12,0,0,1,12,12V52H68V40A12,12,0,0,1,80,28Zm96,200H80a12,12,0,0,1-12-12V204H188v12A12,12,0,0,1,176,228Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M176,16H80A24.1,24.1,0,0,0,56,40V216a24.1,24.1,0,0,0,24,24h96a24.1,24.1,0,0,0,24-24V40A24.1,24.1,0,0,0,176,16ZM72,64H184V192H72Zm8-32h96a8,8,0,0,1,8,8v8H72V40A8,8,0,0,1,80,32Zm96,192H80a8,8,0,0,1-8-8v-8H184v8A8,8,0,0,1,176,224Z" />
        </>
      )}
    </svg>
  );
}

export function PhDeviceTabletCamera(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M192,20H64A28.1,28.1,0,0,0,36,48V208a28.1,28.1,0,0,0,28,28H192a28.1,28.1,0,0,0,28-28V48A28.1,28.1,0,0,0,192,20Zm4,188a4,4,0,0,1-4,4H64a4,4,0,0,1-4-4V48a4,4,0,0,1,4-4H192a4,4,0,0,1,4,4ZM144,76a16,16,0,1,1-16-16A16,16,0,0,1,144,76Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect
              x="32"
              y="48"
              width="192"
              height="160"
              rx="16"
              transform="translate(256) rotate(90)"
            />
          </g>
          <circle cx="128" cy="68" r="12" />
          <path d="M192,24H64A24.1,24.1,0,0,0,40,48V208a24.1,24.1,0,0,0,24,24H192a24.1,24.1,0,0,0,24-24V48A24.1,24.1,0,0,0,192,24Zm8,184a8,8,0,0,1-8,8H64a8,8,0,0,1-8-8V48a8,8,0,0,1,8-8H192a8,8,0,0,1,8,8Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M192,24H64A24.1,24.1,0,0,0,40,48V208a24.1,24.1,0,0,0,24,24H192a24.1,24.1,0,0,0,24-24V48A24.1,24.1,0,0,0,192,24ZM128,80a12,12,0,1,1,12-12A12,12,0,0,1,128,80Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M192,26H64A22.1,22.1,0,0,0,42,48V208a22.1,22.1,0,0,0,22,22H192a22.1,22.1,0,0,0,22-22V48A22.1,22.1,0,0,0,192,26Zm10,182a10,10,0,0,1-10,10H64a10,10,0,0,1-10-10V48A10,10,0,0,1,64,38H192a10,10,0,0,1,10,10ZM138,68a10,10,0,1,1-10-10A10,10,0,0,1,138,68Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M192,28H64A20.1,20.1,0,0,0,44,48V208a20.1,20.1,0,0,0,20,20H192a20.1,20.1,0,0,0,20-20V48A20.1,20.1,0,0,0,192,28Zm12,180a12,12,0,0,1-12,12H64a12,12,0,0,1-12-12V48A12,12,0,0,1,64,36H192a12,12,0,0,1,12,12ZM136,68a8,8,0,1,1-8-8A8,8,0,0,1,136,68Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M192,24H64A24.1,24.1,0,0,0,40,48V208a24.1,24.1,0,0,0,24,24H192a24.1,24.1,0,0,0,24-24V48A24.1,24.1,0,0,0,192,24Zm8,184a8,8,0,0,1-8,8H64a8,8,0,0,1-8-8V48a8,8,0,0,1,8-8H192a8,8,0,0,1,8,8ZM140,68a12,12,0,1,1-12-12A12,12,0,0,1,140,68Z" />
        </>
      )}
    </svg>
  );
}

export function PhDeviceTabletSpeaker(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M192,20H64A28.1,28.1,0,0,0,36,48V208a28.1,28.1,0,0,0,28,28H192a28.1,28.1,0,0,0,28-28V48A28.1,28.1,0,0,0,192,20Zm4,188a4,4,0,0,1-4,4H64a4,4,0,0,1-4-4V48a4,4,0,0,1,4-4H192a4,4,0,0,1,4,4ZM172,72a12,12,0,0,1-12,12H96a12,12,0,0,1,0-24h64A12,12,0,0,1,172,72Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect
              x="32"
              y="48"
              width="192"
              height="160"
              rx="16"
              transform="translate(256) rotate(90)"
            />
          </g>
          <path d="M192,24H64A24.1,24.1,0,0,0,40,48V208a24.1,24.1,0,0,0,24,24H192a24.1,24.1,0,0,0,24-24V48A24.1,24.1,0,0,0,192,24Zm8,184a8,8,0,0,1-8,8H64a8,8,0,0,1-8-8V48a8,8,0,0,1,8-8H192a8,8,0,0,1,8,8Z" />
          <path d="M160,56H96a8,8,0,0,0,0,16h64a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M192,24H64A24.1,24.1,0,0,0,40,48V208a24.1,24.1,0,0,0,24,24H192a24.1,24.1,0,0,0,24-24V48A24.1,24.1,0,0,0,192,24ZM160,72H96a8,8,0,0,1,0-16h64a8,8,0,0,1,0,16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M192,26H64A22.1,22.1,0,0,0,42,48V208a22.1,22.1,0,0,0,22,22H192a22.1,22.1,0,0,0,22-22V48A22.1,22.1,0,0,0,192,26Zm10,182a10,10,0,0,1-10,10H64a10,10,0,0,1-10-10V48A10,10,0,0,1,64,38H192a10,10,0,0,1,10,10ZM166,64a6,6,0,0,1-6,6H96a6,6,0,0,1,0-12h64A6,6,0,0,1,166,64Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M192,28H64A20.1,20.1,0,0,0,44,48V208a20.1,20.1,0,0,0,20,20H192a20.1,20.1,0,0,0,20-20V48A20.1,20.1,0,0,0,192,28Zm12,180a12,12,0,0,1-12,12H64a12,12,0,0,1-12-12V48A12,12,0,0,1,64,36H192a12,12,0,0,1,12,12ZM164,64a4,4,0,0,1-4,4H96a4,4,0,0,1,0-8h64A4,4,0,0,1,164,64Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M192,24H64A24.1,24.1,0,0,0,40,48V208a24.1,24.1,0,0,0,24,24H192a24.1,24.1,0,0,0,24-24V48A24.1,24.1,0,0,0,192,24Zm8,184a8,8,0,0,1-8,8H64a8,8,0,0,1-8-8V48a8,8,0,0,1,8-8H192a8,8,0,0,1,8,8ZM168,64a8,8,0,0,1-8,8H96a8,8,0,0,1,0-16h64A8,8,0,0,1,168,64Z" />
        </>
      )}
    </svg>
  );
}

export function PhDeviceTablet(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M192,20H64A28.1,28.1,0,0,0,36,48V208a28.1,28.1,0,0,0,28,28H192a28.1,28.1,0,0,0,28-28V48A28.1,28.1,0,0,0,192,20ZM60,84H196v88H60Zm4-40H192a4,4,0,0,1,4,4V60H60V48A4,4,0,0,1,64,44ZM192,212H64a4,4,0,0,1-4-4V196H196v12A4,4,0,0,1,192,212Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect x="48" y="64" width="160" height="128" />
          </g>
          <path d="M192,24H64A24.1,24.1,0,0,0,40,48V208a24.1,24.1,0,0,0,24,24H192a24.1,24.1,0,0,0,24-24V48A24.1,24.1,0,0,0,192,24ZM56,72H200V184H56Zm8-32H192a8,8,0,0,1,8,8v8H56V48A8,8,0,0,1,64,40ZM192,216H64a8,8,0,0,1-8-8v-8H200v8A8,8,0,0,1,192,216Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M192,24H64A24.1,24.1,0,0,0,40,48V208a24.1,24.1,0,0,0,24,24H192a24.1,24.1,0,0,0,24-24V48A24.1,24.1,0,0,0,192,24ZM64,40H192a8,8,0,0,1,8,8v8H56V48A8,8,0,0,1,64,40ZM192,216H64a8,8,0,0,1-8-8v-8H200v8A8,8,0,0,1,192,216Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M192,26H64A22.1,22.1,0,0,0,42,48V208a22.1,22.1,0,0,0,22,22H192a22.1,22.1,0,0,0,22-22V48A22.1,22.1,0,0,0,192,26ZM54,70H202V186H54ZM64,38H192a10,10,0,0,1,10,10V58H54V48A10,10,0,0,1,64,38ZM192,218H64a10,10,0,0,1-10-10V198H202v10A10,10,0,0,1,192,218Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M192,28H64A20.1,20.1,0,0,0,44,48V208a20.1,20.1,0,0,0,20,20H192a20.1,20.1,0,0,0,20-20V48A20.1,20.1,0,0,0,192,28ZM52,68H204V188H52ZM64,36H192a12,12,0,0,1,12,12V60H52V48A12,12,0,0,1,64,36ZM192,220H64a12,12,0,0,1-12-12V196H204v12A12,12,0,0,1,192,220Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M192,24H64A24.1,24.1,0,0,0,40,48V208a24.1,24.1,0,0,0,24,24H192a24.1,24.1,0,0,0,24-24V48A24.1,24.1,0,0,0,192,24ZM56,72H200V184H56Zm8-32H192a8,8,0,0,1,8,8v8H56V48A8,8,0,0,1,64,40ZM192,216H64a8,8,0,0,1-8-8v-8H200v8A8,8,0,0,1,192,216Z" />
        </>
      )}
    </svg>
  );
}

export function PhDiamond(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M238.8,113.9,142.1,17.2a19.8,19.8,0,0,0-28.2,0L17.2,113.9a19.8,19.8,0,0,0,0,28.2l96.7,96.7a19.8,19.8,0,0,0,28.2,0l96.7-96.7a19.8,19.8,0,0,0,0-28.2ZM128,219,37,128l91-91,91,91Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M230.3,133.7l-96.6,96.6a8,8,0,0,1-11.4,0L25.7,133.7a8,8,0,0,1,0-11.4l96.6-96.6a8,8,0,0,1,11.4,0l96.6,96.6A8,8,0,0,1,230.3,133.7Z"
            opacity="0.2"
          />
          <path d="M236,116.7,139.3,20a16.1,16.1,0,0,0-22.6,0L20,116.7a16.1,16.1,0,0,0,0,22.6L116.7,236a15.9,15.9,0,0,0,22.6,0L236,139.3A16.1,16.1,0,0,0,236,116.7Zm-108,108h0L31.3,128,128,31.3,224.7,128Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M236,139.3,139.3,236a15.9,15.9,0,0,1-22.6,0L20,139.3a16.1,16.1,0,0,1,0-22.6L116.7,20a16.1,16.1,0,0,1,22.6,0L236,116.7A16.1,16.1,0,0,1,236,139.3Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M234.6,118.1,137.9,21.4a14.1,14.1,0,0,0-19.8,0L21.4,118.1a14,14,0,0,0,0,19.8l96.7,96.7h0a14,14,0,0,0,19.8,0l96.7-96.7a14,14,0,0,0,0-19.8Zm-8.5,11.3-96.7,96.7a1.9,1.9,0,0,1-2.8,0h0L29.9,129.4a1.9,1.9,0,0,1,0-2.8l96.7-96.7a1.9,1.9,0,0,1,2.8,0l96.7,96.7A1.9,1.9,0,0,1,226.1,129.4Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M233.2,119.5,136.5,22.8a12,12,0,0,0-17,0L22.8,119.5a12.1,12.1,0,0,0,0,17l96.7,96.7a12.1,12.1,0,0,0,17,0l96.7-96.7a12.1,12.1,0,0,0,0-17Zm-5.7,11.3-96.7,96.7a3.9,3.9,0,0,1-5.6,0h0L28.5,130.8a3.9,3.9,0,0,1,0-5.6l96.7-96.7a3.9,3.9,0,0,1,5.6,0l96.7,96.7a3.9,3.9,0,0,1,0,5.6Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M236,116.7,139.3,20a16.1,16.1,0,0,0-22.6,0L20,116.7a15.9,15.9,0,0,0,0,22.6L116.7,236h0a15.9,15.9,0,0,0,22.6,0L236,139.3a15.9,15.9,0,0,0,0-22.6Zm-108,108h0L31.3,128,128,31.3,224.7,128Z" />
        </>
      )}
    </svg>
  );
}

export function PhDiamondsFour(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M113.9,102.8a19.8,19.8,0,0,0,28.2,0l26.7-26.7a19.9,19.9,0,0,0,0-28.2L142.1,21.2a19.8,19.8,0,0,0-28.2,0L87.2,47.9a19.9,19.9,0,0,0,0,28.2Zm16.9-16.9h0ZM128,41l21,21L128,83,107,62Zm106.8,72.9L208.1,87.2a19.8,19.8,0,0,0-28.2,0l-26.7,26.7a19.9,19.9,0,0,0,0,28.2l26.7,26.7a19.8,19.8,0,0,0,28.2,0l26.7-26.7A19.9,19.9,0,0,0,234.8,113.9Zm-38,38h0ZM194,149l-21-21,21-21,21,21Zm-91.2-35.1L76.1,87.2a19.8,19.8,0,0,0-28.2,0L21.2,113.9a19.9,19.9,0,0,0,0,28.2l26.7,26.7a19.8,19.8,0,0,0,28.2,0l26.7-26.7A19.9,19.9,0,0,0,102.8,113.9Zm-38,38h0ZM62,149,41,128l21-21,21,21Zm80.1,4.2a19.8,19.8,0,0,0-28.2,0L87.2,179.9a19.9,19.9,0,0,0,0,28.2l26.7,26.7a19.8,19.8,0,0,0,28.2,0l26.7-26.7a19.9,19.9,0,0,0,0-28.2Zm-11.3,64.7h0ZM128,215l-21-21,21-21,21,21Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M93.7,69.7a8,8,0,0,1,0-11.4l28.6-28.6a8,8,0,0,1,11.4,0l28.6,28.6a8,8,0,0,1,0,11.4L133.7,98.3a8,8,0,0,1-11.4,0Zm132.6,52.6L197.7,93.7a8,8,0,0,0-11.4,0l-28.6,28.6a8,8,0,0,0,0,11.4l28.6,28.6a8,8,0,0,0,11.4,0l28.6-28.6A8,8,0,0,0,226.3,122.3Zm-128,0L69.7,93.7a8,8,0,0,0-11.4,0L29.7,122.3a8,8,0,0,0,0,11.4l28.6,28.6a8,8,0,0,0,11.4,0l28.6-28.6A8,8,0,0,0,98.3,122.3Zm35.4,35.4a8,8,0,0,0-11.4,0L93.7,186.3a8,8,0,0,0,0,11.4l28.6,28.6a8,8,0,0,0,11.4,0l28.6-28.6a8,8,0,0,0,0-11.4Z"
            opacity="0.2"
          />
          <path d="M116.7,104a15.9,15.9,0,0,0,22.6,0L168,75.3a16.1,16.1,0,0,0,0-22.6L139.3,24a15.9,15.9,0,0,0-22.6,0L88,52.7a16.1,16.1,0,0,0,0,22.6ZM128,35.3,156.7,64,128,92.7h0L99.3,64Zm104,81.4L203.3,88a15.9,15.9,0,0,0-22.6,0L152,116.7a16.1,16.1,0,0,0,0,22.6L180.7,168a15.9,15.9,0,0,0,22.6,0L232,139.3A16.1,16.1,0,0,0,232,116.7Zm-40,40h0L163.3,128,192,99.3,220.7,128Zm-88-40L75.3,88a15.9,15.9,0,0,0-22.6,0L24,116.7a16.1,16.1,0,0,0,0,22.6L52.7,168a15.9,15.9,0,0,0,22.6,0L104,139.3A16.1,16.1,0,0,0,104,116.7Zm-40,40h0L35.3,128,64,99.3,92.7,128Zm75.3-4.7a15.9,15.9,0,0,0-22.6,0L88,180.7a16.1,16.1,0,0,0,0,22.6L116.7,232a15.9,15.9,0,0,0,22.6,0L168,203.3a16.1,16.1,0,0,0,0-22.6ZM128,220.7h0L99.3,192,128,163.3,156.7,192Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M88,75.3a16.1,16.1,0,0,1,0-22.6L116.7,24a15.9,15.9,0,0,1,22.6,0L168,52.7a16.1,16.1,0,0,1,0,22.6L139.3,104a15.9,15.9,0,0,1-22.6,0Zm16,41.4L75.3,88a15.9,15.9,0,0,0-22.6,0L24,116.7a16.1,16.1,0,0,0,0,22.6L52.7,168a15.9,15.9,0,0,0,22.6,0L104,139.3A16.1,16.1,0,0,0,104,116.7Zm128,0L203.3,88a15.9,15.9,0,0,0-22.6,0L152,116.7a16.1,16.1,0,0,0,0,22.6L180.7,168a15.9,15.9,0,0,0,22.6,0L232,139.3A16.1,16.1,0,0,0,232,116.7ZM139.3,152a15.9,15.9,0,0,0-22.6,0L88,180.7a16.1,16.1,0,0,0,0,22.6L116.7,232a15.9,15.9,0,0,0,22.6,0L168,203.3a16.1,16.1,0,0,0,0-22.6Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M118.1,102.6a14,14,0,0,0,19.8,0l28.7-28.7a14.1,14.1,0,0,0,0-19.8L137.9,25.4a14,14,0,0,0-19.8,0L89.4,54.1a14.1,14.1,0,0,0,0,19.8Zm-20.2-40,28.7-28.7a1.9,1.9,0,0,1,2.8,0l28.7,28.7a1.9,1.9,0,0,1,0,2.8L129.4,94.1a1.9,1.9,0,0,1-2.8,0h0L97.9,65.4A1.9,1.9,0,0,1,97.9,62.6Zm132.7,55.5L201.9,89.4a14,14,0,0,0-19.8,0l-28.7,28.7a14.1,14.1,0,0,0,0,19.8l28.7,28.7a14,14,0,0,0,19.8,0l28.7-28.7A14.1,14.1,0,0,0,230.6,118.1Zm-8.5,11.3-28.7,28.7a1.9,1.9,0,0,1-2.8,0h0l-28.7-28.7a1.9,1.9,0,0,1,0-2.8l28.7-28.7a1.9,1.9,0,0,1,2.8,0l28.7,28.7A1.9,1.9,0,0,1,222.1,129.4ZM102.6,118.1,73.9,89.4a14,14,0,0,0-19.8,0L25.4,118.1a14.1,14.1,0,0,0,0,19.8l28.7,28.7a14,14,0,0,0,19.8,0l28.7-28.7A14.1,14.1,0,0,0,102.6,118.1Zm-8.5,11.3L65.4,158.1a1.9,1.9,0,0,1-2.8,0h0L33.9,129.4a1.9,1.9,0,0,1,0-2.8L62.6,97.9a1.9,1.9,0,0,1,2.8,0l28.7,28.7A1.9,1.9,0,0,1,94.1,129.4Zm43.8,24a14,14,0,0,0-19.8,0L89.4,182.1a14.1,14.1,0,0,0,0,19.8l28.7,28.7a14,14,0,0,0,19.8,0l28.7-28.7a14.1,14.1,0,0,0,0-19.8Zm20.2,40-28.7,28.7a1.9,1.9,0,0,1-2.8,0h0L97.9,193.4a1.9,1.9,0,0,1,0-2.8l28.7-28.7a1.9,1.9,0,0,1,2.8,0l28.7,28.7A1.9,1.9,0,0,1,158.1,193.4Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M119.5,101.2a12.1,12.1,0,0,0,17,0l28.7-28.7a12.2,12.2,0,0,0,0-17L136.5,26.8a12.1,12.1,0,0,0-17,0L90.8,55.5a12.2,12.2,0,0,0,0,17Zm-23-40,28.7-28.7a3.9,3.9,0,0,1,5.6,0l28.7,28.7a3.9,3.9,0,0,1,0,5.6L130.8,95.5a4,4,0,0,1-5.6,0h0L96.5,66.8A3.9,3.9,0,0,1,96.5,61.2Zm132.7,58.3L200.5,90.8a12.1,12.1,0,0,0-17,0l-28.7,28.7a12.2,12.2,0,0,0,0,17l28.7,28.7a12.1,12.1,0,0,0,17,0l28.7-28.7A12.2,12.2,0,0,0,229.2,119.5Zm-5.7,11.3-28.7,28.7a4,4,0,0,1-5.6,0h0l-28.7-28.7a3.9,3.9,0,0,1,0-5.6l28.7-28.7a3.9,3.9,0,0,1,5.6,0l28.7,28.7A3.9,3.9,0,0,1,223.5,130.8ZM101.2,119.5,72.5,90.8a12.1,12.1,0,0,0-17,0L26.8,119.5a12.2,12.2,0,0,0,0,17l28.7,28.7a12.1,12.1,0,0,0,17,0l28.7-28.7A12.2,12.2,0,0,0,101.2,119.5Zm-5.7,11.3L66.8,159.5a4,4,0,0,1-5.6,0h0L32.5,130.8a3.9,3.9,0,0,1,0-5.6L61.2,96.5a3.9,3.9,0,0,1,5.6,0l28.7,28.7A3.9,3.9,0,0,1,95.5,130.8Zm41,24a12.1,12.1,0,0,0-17,0L90.8,183.5a12.2,12.2,0,0,0,0,17l28.7,28.7a12.1,12.1,0,0,0,17,0l28.7-28.7a12.2,12.2,0,0,0,0-17Zm23,40-28.7,28.7a4,4,0,0,1-5.6,0h0L96.5,194.8a3.9,3.9,0,0,1,0-5.6l28.7-28.7a3.9,3.9,0,0,1,5.6,0l28.7,28.7A3.9,3.9,0,0,1,159.5,194.8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M116.7,104a15.9,15.9,0,0,0,22.6,0L168,75.3a16.1,16.1,0,0,0,0-22.6L139.3,24a15.9,15.9,0,0,0-22.6,0L88,52.7a16.1,16.1,0,0,0,0,22.6ZM128,35.3,156.7,64,128,92.7h0L99.3,64Zm104,81.4L203.3,88a15.9,15.9,0,0,0-22.6,0L152,116.7a16.1,16.1,0,0,0,0,22.6L180.7,168a15.9,15.9,0,0,0,22.6,0L232,139.3A16.1,16.1,0,0,0,232,116.7Zm-40,40h0L163.3,128,192,99.3,220.7,128Zm-88-40L75.3,88a15.9,15.9,0,0,0-22.6,0L24,116.7a16.1,16.1,0,0,0,0,22.6L52.7,168a15.9,15.9,0,0,0,22.6,0L104,139.3A16.1,16.1,0,0,0,104,116.7Zm-40,40h0L35.3,128,64,99.3,92.7,128Zm75.3-4.7a15.9,15.9,0,0,0-22.6,0L88,180.7a16.1,16.1,0,0,0,0,22.6L116.7,232a15.9,15.9,0,0,0,22.6,0L168,203.3a16.1,16.1,0,0,0,0-22.6ZM128,220.7h0L99.3,192,128,163.3,156.7,192Z" />
        </>
      )}
    </svg>
  );
}

export function PhDiceFive(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M192,28H64A36,36,0,0,0,28,64V192a36,36,0,0,0,36,36H192a36,36,0,0,0,36-36V64A36,36,0,0,0,192,28Zm12,164a12,12,0,0,1-12,12H64a12,12,0,0,1-12-12V64A12,12,0,0,1,64,52H192a12,12,0,0,1,12,12ZM104,88A16,16,0,1,1,88,72,16,16,0,0,1,104,88Zm80,0a16,16,0,1,1-16-16A16,16,0,0,1,184,88Zm-80,80a16,16,0,1,1-16-16A16,16,0,0,1,104,168Zm80,0a16,16,0,1,1-16-16A16,16,0,0,1,184,168Zm-40-40a16,16,0,1,1-16-16A16,16,0,0,1,144,128Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect x="40" y="40" width="176" height="176" rx="24" />
          </g>
          <path d="M192,32H64A32.1,32.1,0,0,0,32,64V192a32.1,32.1,0,0,0,32,32H192a32.1,32.1,0,0,0,32-32V64A32.1,32.1,0,0,0,192,32Zm16,160a16,16,0,0,1-16,16H64a16,16,0,0,1-16-16V64A16,16,0,0,1,64,48H192a16,16,0,0,1,16,16ZM104,92A12,12,0,1,1,92,80,12,12,0,0,1,104,92Zm72,0a12,12,0,1,1-12-12A12,12,0,0,1,176,92Zm-72,72a12,12,0,1,1-12-12A12,12,0,0,1,104,164Zm72,0a12,12,0,1,1-12-12A12,12,0,0,1,176,164Zm-36-36a12,12,0,1,1-12-12A12,12,0,0,1,140,128Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M192,32H64A32.1,32.1,0,0,0,32,64V192a32.1,32.1,0,0,0,32,32H192a32.1,32.1,0,0,0,32-32V64A32.1,32.1,0,0,0,192,32ZM92,176a12,12,0,1,1,12-12A12,12,0,0,1,92,176Zm0-72a12,12,0,1,1,12-12A12,12,0,0,1,92,104Zm36,36a12,12,0,1,1,12-12A12,12,0,0,1,128,140Zm36,36a12,12,0,1,1,12-12A12,12,0,0,1,164,176Zm0-72a12,12,0,1,1,12-12A12,12,0,0,1,164,104Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M192,34H64A30.1,30.1,0,0,0,34,64V192a30.1,30.1,0,0,0,30,30H192a30.1,30.1,0,0,0,30-30V64A30.1,30.1,0,0,0,192,34Zm18,158a18.1,18.1,0,0,1-18,18H64a18.1,18.1,0,0,1-18-18V64A18.1,18.1,0,0,1,64,46H192a18.1,18.1,0,0,1,18,18ZM102,92A10,10,0,1,1,92,82,10,10,0,0,1,102,92Zm72,0a10,10,0,1,1-10-10A10,10,0,0,1,174,92Zm-72,72a10,10,0,1,1-10-10A10,10,0,0,1,102,164Zm72,0a10,10,0,1,1-10-10A10,10,0,0,1,174,164Zm-36-36a10,10,0,1,1-10-10A10,10,0,0,1,138,128Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M192,36H64A28.1,28.1,0,0,0,36,64V192a28.1,28.1,0,0,0,28,28H192a28.1,28.1,0,0,0,28-28V64A28.1,28.1,0,0,0,192,36Zm20,156a20.1,20.1,0,0,1-20,20H64a20.1,20.1,0,0,1-20-20V64A20.1,20.1,0,0,1,64,44H192a20.1,20.1,0,0,1,20,20ZM100,92a8,8,0,1,1-8-8A8,8,0,0,1,100,92Zm72,0a8,8,0,1,1-8-8A8,8,0,0,1,172,92Zm-72,72a8,8,0,1,1-8-8A8,8,0,0,1,100,164Zm72,0a8,8,0,1,1-8-8A8,8,0,0,1,172,164Zm-36-36a8,8,0,1,1-8-8A8,8,0,0,1,136,128Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M192,32H64A32.1,32.1,0,0,0,32,64V192a32.1,32.1,0,0,0,32,32H192a32.1,32.1,0,0,0,32-32V64A32.1,32.1,0,0,0,192,32Zm16,160a16,16,0,0,1-16,16H64a16,16,0,0,1-16-16V64A16,16,0,0,1,64,48H192a16,16,0,0,1,16,16ZM104,92A12,12,0,1,1,92,80,12,12,0,0,1,104,92Zm72,0a12,12,0,1,1-12-12A12,12,0,0,1,176,92Zm-72,72a12,12,0,1,1-12-12A12,12,0,0,1,104,164Zm72,0a12,12,0,1,1-12-12A12,12,0,0,1,176,164Zm-36-36a12,12,0,1,1-12-12A12,12,0,0,1,140,128Z" />
        </>
      )}
    </svg>
  );
}

export function PhDiceFour(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M192,28H64A36,36,0,0,0,28,64V192a36,36,0,0,0,36,36H192a36,36,0,0,0,36-36V64A36,36,0,0,0,192,28Zm12,164a12,12,0,0,1-12,12H64a12,12,0,0,1-12-12V64A12,12,0,0,1,64,52H192a12,12,0,0,1,12,12ZM112,96A16,16,0,1,1,96,80,16,16,0,0,1,112,96Zm64,0a16,16,0,1,1-16-16A16,16,0,0,1,176,96Zm-64,64a16,16,0,1,1-16-16A16,16,0,0,1,112,160Zm64,0a16,16,0,1,1-16-16A16,16,0,0,1,176,160Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect x="40" y="40" width="176" height="176" rx="24" />
          </g>
          <path d="M192,32H64A32.1,32.1,0,0,0,32,64V192a32.1,32.1,0,0,0,32,32H192a32.1,32.1,0,0,0,32-32V64A32.1,32.1,0,0,0,192,32Zm16,160a16,16,0,0,1-16,16H64a16,16,0,0,1-16-16V64A16,16,0,0,1,64,48H192a16,16,0,0,1,16,16Zm-96-92a12,12,0,1,1-12-12A12,12,0,0,1,112,100Zm56,0a12,12,0,1,1-12-12A12,12,0,0,1,168,100Zm-56,56a12,12,0,1,1-12-12A12,12,0,0,1,112,156Zm56,0a12,12,0,1,1-12-12A12,12,0,0,1,168,156Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M192,32H64A32.1,32.1,0,0,0,32,64V192a32.1,32.1,0,0,0,32,32H192a32.1,32.1,0,0,0,32-32V64A32.1,32.1,0,0,0,192,32ZM100,168a12,12,0,1,1,12-12A12,12,0,0,1,100,168Zm0-56a12,12,0,1,1,12-12A12,12,0,0,1,100,112Zm56,56a12,12,0,1,1,12-12A12,12,0,0,1,156,168Zm0-56a12,12,0,1,1,12-12A12,12,0,0,1,156,112Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M192,34H64A30.1,30.1,0,0,0,34,64V192a30.1,30.1,0,0,0,30,30H192a30.1,30.1,0,0,0,30-30V64A30.1,30.1,0,0,0,192,34Zm18,158a18.1,18.1,0,0,1-18,18H64a18.1,18.1,0,0,1-18-18V64A18.1,18.1,0,0,1,64,46H192a18.1,18.1,0,0,1,18,18ZM110,100a10,10,0,1,1-10-10A10,10,0,0,1,110,100Zm56,0a10,10,0,1,1-10-10A10,10,0,0,1,166,100Zm-56,56a10,10,0,1,1-10-10A10,10,0,0,1,110,156Zm56,0a10,10,0,1,1-10-10A10,10,0,0,1,166,156Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M192,36H64A28.1,28.1,0,0,0,36,64V192a28.1,28.1,0,0,0,28,28H192a28.1,28.1,0,0,0,28-28V64A28.1,28.1,0,0,0,192,36Zm20,156a20.1,20.1,0,0,1-20,20H64a20.1,20.1,0,0,1-20-20V64A20.1,20.1,0,0,1,64,44H192a20.1,20.1,0,0,1,20,20ZM108,100a8,8,0,1,1-8-8A8,8,0,0,1,108,100Zm56,0a8,8,0,1,1-8-8A8,8,0,0,1,164,100Zm-56,56a8,8,0,1,1-8-8A8,8,0,0,1,108,156Zm56,0a8,8,0,1,1-8-8A8,8,0,0,1,164,156Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M192,32H64A32.1,32.1,0,0,0,32,64V192a32.1,32.1,0,0,0,32,32H192a32.1,32.1,0,0,0,32-32V64A32.1,32.1,0,0,0,192,32Zm16,160a16,16,0,0,1-16,16H64a16,16,0,0,1-16-16V64A16,16,0,0,1,64,48H192a16,16,0,0,1,16,16Zm-96-92a12,12,0,1,1-12-12A12,12,0,0,1,112,100Zm56,0a12,12,0,1,1-12-12A12,12,0,0,1,168,100Zm-56,56a12,12,0,1,1-12-12A12,12,0,0,1,112,156Zm56,0a12,12,0,1,1-12-12A12,12,0,0,1,168,156Z" />
        </>
      )}
    </svg>
  );
}

export function PhDiceOne(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M192,28H64A36,36,0,0,0,28,64V192a36,36,0,0,0,36,36H192a36,36,0,0,0,36-36V64A36,36,0,0,0,192,28Zm12,164a12,12,0,0,1-12,12H64a12,12,0,0,1-12-12V64A12,12,0,0,1,64,52H192a12,12,0,0,1,12,12Zm-60-64a16,16,0,1,1-16-16A16,16,0,0,1,144,128Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect x="40" y="40" width="176" height="176" rx="24" />
          </g>
          <path d="M192,32H64A32.1,32.1,0,0,0,32,64V192a32.1,32.1,0,0,0,32,32H192a32.1,32.1,0,0,0,32-32V64A32.1,32.1,0,0,0,192,32Zm16,160a16,16,0,0,1-16,16H64a16,16,0,0,1-16-16V64A16,16,0,0,1,64,48H192a16,16,0,0,1,16,16Zm-68-64a12,12,0,1,1-12-12A12,12,0,0,1,140,128Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M192,32H64A32.1,32.1,0,0,0,32,64V192a32.1,32.1,0,0,0,32,32H192a32.1,32.1,0,0,0,32-32V64A32.1,32.1,0,0,0,192,32ZM128,140a12,12,0,1,1,12-12A12,12,0,0,1,128,140Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M192,34H64A30.1,30.1,0,0,0,34,64V192a30.1,30.1,0,0,0,30,30H192a30.1,30.1,0,0,0,30-30V64A30.1,30.1,0,0,0,192,34Zm18,158a18.1,18.1,0,0,1-18,18H64a18.1,18.1,0,0,1-18-18V64A18.1,18.1,0,0,1,64,46H192a18.1,18.1,0,0,1,18,18Zm-72-64a10,10,0,1,1-10-10A10,10,0,0,1,138,128Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M192,36H64A28.1,28.1,0,0,0,36,64V192a28.1,28.1,0,0,0,28,28H192a28.1,28.1,0,0,0,28-28V64A28.1,28.1,0,0,0,192,36Zm20,156a20.1,20.1,0,0,1-20,20H64a20.1,20.1,0,0,1-20-20V64A20.1,20.1,0,0,1,64,44H192a20.1,20.1,0,0,1,20,20Zm-76-64a8,8,0,1,1-8-8A8,8,0,0,1,136,128Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M192,32H64A32.1,32.1,0,0,0,32,64V192a32.1,32.1,0,0,0,32,32H192a32.1,32.1,0,0,0,32-32V64A32.1,32.1,0,0,0,192,32Zm16,160a16,16,0,0,1-16,16H64a16,16,0,0,1-16-16V64A16,16,0,0,1,64,48H192a16,16,0,0,1,16,16Zm-68-64a12,12,0,1,1-12-12A12,12,0,0,1,140,128Z" />
        </>
      )}
    </svg>
  );
}

export function PhDiceSix(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M192,28H64A36,36,0,0,0,28,64V192a36,36,0,0,0,36,36H192a36,36,0,0,0,36-36V64A36,36,0,0,0,192,28Zm12,164a12,12,0,0,1-12,12H64a12,12,0,0,1-12-12V64A12,12,0,0,1,64,52H192a12,12,0,0,1,12,12ZM112,84A16,16,0,1,1,96,68,16,16,0,0,1,112,84Zm0,44a16,16,0,1,1-16-16A16,16,0,0,1,112,128Zm0,44a16,16,0,1,1-16-16A16,16,0,0,1,112,172Zm64-88a16,16,0,1,1-16-16A16,16,0,0,1,176,84Zm0,44a16,16,0,1,1-16-16A16,16,0,0,1,176,128Zm0,44a16,16,0,1,1-16-16A16,16,0,0,1,176,172Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect x="40" y="40" width="176" height="176" rx="24" />
          </g>
          <path d="M192,32H64A32.1,32.1,0,0,0,32,64V192a32.1,32.1,0,0,0,32,32H192a32.1,32.1,0,0,0,32-32V64A32.1,32.1,0,0,0,192,32Zm16,160a16,16,0,0,1-16,16H64a16,16,0,0,1-16-16V64A16,16,0,0,1,64,48H192a16,16,0,0,1,16,16ZM104,84A12,12,0,1,1,92,72,12,12,0,0,1,104,84Zm0,44a12,12,0,1,1-12-12A12,12,0,0,1,104,128Zm0,44a12,12,0,1,1-12-12A12,12,0,0,1,104,172Zm72-88a12,12,0,1,1-12-12A12,12,0,0,1,176,84Zm0,44a12,12,0,1,1-12-12A12,12,0,0,1,176,128Zm0,44a12,12,0,1,1-12-12A12,12,0,0,1,176,172Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M192,32H64A32.1,32.1,0,0,0,32,64V192a32.1,32.1,0,0,0,32,32H192a32.1,32.1,0,0,0,32-32V64A32.1,32.1,0,0,0,192,32ZM92,184a12,12,0,1,1,12-12A12,12,0,0,1,92,184Zm0-44a12,12,0,1,1,12-12A12,12,0,0,1,92,140Zm0-44a12,12,0,1,1,12-12A12,12,0,0,1,92,96Zm72,88a12,12,0,1,1,12-12A12,12,0,0,1,164,184Zm0-44a12,12,0,1,1,12-12A12,12,0,0,1,164,140Zm0-44a12,12,0,1,1,12-12A12,12,0,0,1,164,96Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M192,34H64A30.1,30.1,0,0,0,34,64V192a30.1,30.1,0,0,0,30,30H192a30.1,30.1,0,0,0,30-30V64A30.1,30.1,0,0,0,192,34Zm18,158a18.1,18.1,0,0,1-18,18H64a18.1,18.1,0,0,1-18-18V64A18.1,18.1,0,0,1,64,46H192a18.1,18.1,0,0,1,18,18ZM102,84A10,10,0,1,1,92,74,10,10,0,0,1,102,84Zm0,44a10,10,0,1,1-10-10A10,10,0,0,1,102,128Zm0,44a10,10,0,1,1-10-10A10,10,0,0,1,102,172Zm72-88a10,10,0,1,1-10-10A10,10,0,0,1,174,84Zm0,44a10,10,0,1,1-10-10A10,10,0,0,1,174,128Zm0,44a10,10,0,1,1-10-10A10,10,0,0,1,174,172Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M192,36H64A28.1,28.1,0,0,0,36,64V192a28.1,28.1,0,0,0,28,28H192a28.1,28.1,0,0,0,28-28V64A28.1,28.1,0,0,0,192,36Zm20,156a20.1,20.1,0,0,1-20,20H64a20.1,20.1,0,0,1-20-20V64A20.1,20.1,0,0,1,64,44H192a20.1,20.1,0,0,1,20,20ZM100,84a8,8,0,1,1-8-8A8,8,0,0,1,100,84Zm0,44a8,8,0,1,1-8-8A8,8,0,0,1,100,128Zm0,44a8,8,0,1,1-8-8A8,8,0,0,1,100,172Zm72-88a8,8,0,1,1-8-8A8,8,0,0,1,172,84Zm0,44a8,8,0,1,1-8-8A8,8,0,0,1,172,128Zm0,44a8,8,0,1,1-8-8A8,8,0,0,1,172,172Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M192,32H64A32.1,32.1,0,0,0,32,64V192a32.1,32.1,0,0,0,32,32H192a32.1,32.1,0,0,0,32-32V64A32.1,32.1,0,0,0,192,32Zm16,160a16,16,0,0,1-16,16H64a16,16,0,0,1-16-16V64A16,16,0,0,1,64,48H192a16,16,0,0,1,16,16ZM104,84A12,12,0,1,1,92,72,12,12,0,0,1,104,84Zm0,44a12,12,0,1,1-12-12A12,12,0,0,1,104,128Zm0,44a12,12,0,1,1-12-12A12,12,0,0,1,104,172Zm72-88a12,12,0,1,1-12-12A12,12,0,0,1,176,84Zm0,44a12,12,0,1,1-12-12A12,12,0,0,1,176,128Zm0,44a12,12,0,1,1-12-12A12,12,0,0,1,176,172Z" />
        </>
      )}
    </svg>
  );
}

export function PhDiceThree(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M192,28H64A36,36,0,0,0,28,64V192a36,36,0,0,0,36,36H192a36,36,0,0,0,36-36V64A36,36,0,0,0,192,28Zm12,164a12,12,0,0,1-12,12H64a12,12,0,0,1-12-12V64A12,12,0,0,1,64,52H192a12,12,0,0,1,12,12ZM104,88A16,16,0,1,1,88,72,16,16,0,0,1,104,88Zm40,40a16,16,0,1,1-16-16A16,16,0,0,1,144,128Zm40,40a16,16,0,1,1-16-16A16,16,0,0,1,184,168Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect x="40" y="40" width="176" height="176" rx="24" />
          </g>
          <path d="M192,32H64A32.1,32.1,0,0,0,32,64V192a32.1,32.1,0,0,0,32,32H192a32.1,32.1,0,0,0,32-32V64A32.1,32.1,0,0,0,192,32Zm16,160a16,16,0,0,1-16,16H64a16,16,0,0,1-16-16V64A16,16,0,0,1,64,48H192a16,16,0,0,1,16,16ZM104,92A12,12,0,1,1,92,80,12,12,0,0,1,104,92Zm36,36a12,12,0,1,1-12-12A12,12,0,0,1,140,128Zm36,36a12,12,0,1,1-12-12A12,12,0,0,1,176,164Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M192,32H64A32.1,32.1,0,0,0,32,64V192a32.1,32.1,0,0,0,32,32H192a32.1,32.1,0,0,0,32-32V64A32.1,32.1,0,0,0,192,32ZM92,104a12,12,0,1,1,12-12A12,12,0,0,1,92,104Zm36,36a12,12,0,1,1,12-12A12,12,0,0,1,128,140Zm36,36a12,12,0,1,1,12-12A12,12,0,0,1,164,176Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M192,34H64A30.1,30.1,0,0,0,34,64V192a30.1,30.1,0,0,0,30,30H192a30.1,30.1,0,0,0,30-30V64A30.1,30.1,0,0,0,192,34Zm18,158a18.1,18.1,0,0,1-18,18H64a18.1,18.1,0,0,1-18-18V64A18.1,18.1,0,0,1,64,46H192a18.1,18.1,0,0,1,18,18ZM102,92A10,10,0,1,1,92,82,10,10,0,0,1,102,92Zm36,36a10,10,0,1,1-10-10A10,10,0,0,1,138,128Zm36,36a10,10,0,1,1-10-10A10,10,0,0,1,174,164Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M192,36H64A28.1,28.1,0,0,0,36,64V192a28.1,28.1,0,0,0,28,28H192a28.1,28.1,0,0,0,28-28V64A28.1,28.1,0,0,0,192,36Zm20,156a20.1,20.1,0,0,1-20,20H64a20.1,20.1,0,0,1-20-20V64A20.1,20.1,0,0,1,64,44H192a20.1,20.1,0,0,1,20,20ZM100,92a8,8,0,1,1-8-8A8,8,0,0,1,100,92Zm36,36a8,8,0,1,1-8-8A8,8,0,0,1,136,128Zm36,36a8,8,0,1,1-8-8A8,8,0,0,1,172,164Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M192,32H64A32.1,32.1,0,0,0,32,64V192a32.1,32.1,0,0,0,32,32H192a32.1,32.1,0,0,0,32-32V64A32.1,32.1,0,0,0,192,32Zm16,160a16,16,0,0,1-16,16H64a16,16,0,0,1-16-16V64A16,16,0,0,1,64,48H192a16,16,0,0,1,16,16ZM104,92A12,12,0,1,1,92,80,12,12,0,0,1,104,92Zm36,36a12,12,0,1,1-12-12A12,12,0,0,1,140,128Zm36,36a12,12,0,1,1-12-12A12,12,0,0,1,176,164Z" />
        </>
      )}
    </svg>
  );
}

export function PhDiceTwo(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M192,28H64A36,36,0,0,0,28,64V192a36,36,0,0,0,36,36H192a36,36,0,0,0,36-36V64A36,36,0,0,0,192,28Zm12,164a12,12,0,0,1-12,12H64a12,12,0,0,1-12-12V64A12,12,0,0,1,64,52H192a12,12,0,0,1,12,12Zm-84-88a16,16,0,1,1-16-16A16,16,0,0,1,120,104Zm48,48a16,16,0,1,1-16-16A16,16,0,0,1,168,152Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect x="40" y="40" width="176" height="176" rx="24" />
          </g>
          <path d="M192,32H64A32.1,32.1,0,0,0,32,64V192a32.1,32.1,0,0,0,32,32H192a32.1,32.1,0,0,0,32-32V64A32.1,32.1,0,0,0,192,32Zm16,160a16,16,0,0,1-16,16H64a16,16,0,0,1-16-16V64A16,16,0,0,1,64,48H192a16,16,0,0,1,16,16Zm-88-84a12,12,0,1,1-12-12A12,12,0,0,1,120,108Zm40,40a12,12,0,1,1-12-12A12,12,0,0,1,160,148Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M192,32H64A32.1,32.1,0,0,0,32,64V192a32.1,32.1,0,0,0,32,32H192a32.1,32.1,0,0,0,32-32V64A32.1,32.1,0,0,0,192,32Zm-84,88a12,12,0,1,1,12-12A12,12,0,0,1,108,120Zm40,40a12,12,0,1,1,12-12A12,12,0,0,1,148,160Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M192,34H64A30.1,30.1,0,0,0,34,64V192a30.1,30.1,0,0,0,30,30H192a30.1,30.1,0,0,0,30-30V64A30.1,30.1,0,0,0,192,34Zm18,158a18.1,18.1,0,0,1-18,18H64a18.1,18.1,0,0,1-18-18V64A18.1,18.1,0,0,1,64,46H192a18.1,18.1,0,0,1,18,18Zm-92-84a10,10,0,1,1-10-10A10,10,0,0,1,118,108Zm40,40a10,10,0,1,1-10-10A10,10,0,0,1,158,148Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M192,36H64A28.1,28.1,0,0,0,36,64V192a28.1,28.1,0,0,0,28,28H192a28.1,28.1,0,0,0,28-28V64A28.1,28.1,0,0,0,192,36Zm20,156a20.1,20.1,0,0,1-20,20H64a20.1,20.1,0,0,1-20-20V64A20.1,20.1,0,0,1,64,44H192a20.1,20.1,0,0,1,20,20Zm-96-84a8,8,0,1,1-8-8A8,8,0,0,1,116,108Zm40,40a8,8,0,1,1-8-8A8,8,0,0,1,156,148Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M192,32H64A32.1,32.1,0,0,0,32,64V192a32.1,32.1,0,0,0,32,32H192a32.1,32.1,0,0,0,32-32V64A32.1,32.1,0,0,0,192,32Zm16,160a16,16,0,0,1-16,16H64a16,16,0,0,1-16-16V64A16,16,0,0,1,64,48H192a16,16,0,0,1,16,16Zm-88-84a12,12,0,1,1-12-12A12,12,0,0,1,120,108Zm40,40a12,12,0,1,1-12-12A12,12,0,0,1,160,148Z" />
        </>
      )}
    </svg>
  );
}

export function PhDisc(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M205.2,52.6l-.8-1-1-.8a108.2,108.2,0,1,0,1.8,1.8ZM170.3,116a38.9,38.9,0,0,0-3.9-9.4l28.8-28.9A82.7,82.7,0,0,1,211.1,116ZM148,128a20,20,0,1,1-20-20A20.1,20.1,0,0,1,148,128Zm-20,84a84,84,0,0,1,0-168,83.1,83.1,0,0,1,50.3,16.8L149.4,89.6A42.9,42.9,0,0,0,128,84a44,44,0,1,0,42.3,56h40.8A84,84,0,0,1,128,212Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M160,128a32.1,32.1,0,1,1-9.4-22.6h0l45.3-45.3h0A96,96,0,1,0,224,128Z" />
          </g>
          <path d="M202.2,55.2a2.3,2.3,0,0,0-.7-.7,2.3,2.3,0,0,0-.7-.7,104.1,104.1,0,1,0,1.4,1.4Zm-35,64.8a40.3,40.3,0,0,0-5.8-14l34.2-34.3a87.5,87.5,0,0,1,20,48.3ZM152,128a24,24,0,1,1-24-24A24.1,24.1,0,0,1,152,128Zm-24,88A88,88,0,1,1,184.3,60.4L150,94.6A40,40,0,1,0,167.2,136h48.4A88.1,88.1,0,0,1,128,216Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M188.3,43.3l-.6-.5-.8-.4A102.9,102.9,0,0,0,128,24a104.1,104.1,0,1,0,60.3,19.3ZM128,152a24,24,0,1,1,24-24A24.1,24.1,0,0,1,128,152Zm88-24c0,2.5-.1,4.9-.3,7.3L168,126.9a39.8,39.8,0,0,0-11-26.4l27.8-39.7A87.9,87.9,0,0,1,216,128Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M200.5,56.3c-.2-.1-.2-.3-.4-.4s-.3-.2-.4-.4a102.1,102.1,0,1,0,.8.8Zm-35,65.7a37.9,37.9,0,0,0-6.7-16.3l36.9-36.9A89.5,89.5,0,0,1,217.8,122ZM154,128a26,26,0,1,1-26-26A26.1,26.1,0,0,1,154,128Zm-26,90A90,90,0,1,1,187.2,60.3L150.3,97.2A38,38,0,1,0,165.5,134h52.3A90.2,90.2,0,0,1,128,218Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M199,57.7l-.3-.4-.4-.3a100.2,100.2,0,1,0,.7.7ZM163.8,124a36,36,0,0,0-7.7-18.5l39.7-39.6A91.4,91.4,0,0,1,219.9,124Zm-7.8,4a28,28,0,1,1-28-28A28.1,28.1,0,0,1,156,128Zm-28,92A92,92,0,1,1,190.1,60.2L150.5,99.9A36,36,0,1,0,163.8,132h56.1A92.1,92.1,0,0,1,128,220Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M202.2,55.2a2.3,2.3,0,0,0-.7-.7,2.3,2.3,0,0,0-.7-.7,104.1,104.1,0,1,0,1.4,1.4Zm-35,64.8a40.3,40.3,0,0,0-5.8-14l34.2-34.3a87.5,87.5,0,0,1,20,48.3ZM152,128a24,24,0,1,1-24-24A24.1,24.1,0,0,1,152,128Zm-24,88A88,88,0,1,1,184.3,60.4L150,94.6A40,40,0,1,0,167.2,136h48.4A88.1,88.1,0,0,1,128,216Z" />
        </>
      )}
    </svg>
  );
}

export function PhDiscordLogo(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M112,132a16,16,0,1,1-16-16A16,16,0,0,1,112,132Zm48-16a16,16,0,1,0,16,16A16,16,0,0,0,160,116Zm85,73.6c-17,14.8-39.9,26-66.1,32.4a19.7,19.7,0,0,1-4.8.6,20,20,0,0,1-17.8-11.1l-12-24.2c-5.4.5-10.8.7-16.3.7s-10.9-.2-16.3-.7l-12,24.2a20,20,0,0,1-17.8,11.1,19.7,19.7,0,0,1-4.8-.6C50.9,215.6,28,204.4,11,189.6a20,20,0,0,1-6.1-20.9L38.8,55.4A20.1,20.1,0,0,1,50.4,42.7a195.3,195.3,0,0,1,31.7-9.9,20,20,0,0,1,23.2,13.3l7.5,22.5c5.1-.4,10.1-.6,15.2-.6s10.1.2,15.2.6l7.5-22.5a20,20,0,0,1,23.2-13.3,195.3,195.3,0,0,1,31.7,9.9,20.1,20.1,0,0,1,11.6,12.7l33.9,113.3A20,20,0,0,1,245,189.6ZM227.4,173,194.7,64.1a172.1,172.1,0,0,0-22.4-7l-5,14.8q6.3,1.3,12,3A12,12,0,0,1,176,98.4a9.5,9.5,0,0,1-3.3-.5A167.2,167.2,0,0,0,128,92a167.2,167.2,0,0,0-44.7,5.9,12,12,0,1,1-6.6-23q5.7-1.7,12-3l-5-14.8a172.1,172.1,0,0,0-22.4,7L28.6,173c13.3,11,30.9,19.6,51,24.9l7.2-14.2c-5.5-1.2-10.7-2.7-15.7-4.3a12,12,0,0,1,7.6-22.8c14.5,4.8,31.6,7.4,49.3,7.4s34.8-2.6,49.3-7.4a12,12,0,0,1,7.6,22.8c-5,1.6-10.2,3.1-15.7,4.3l7.2,14.2C196.5,192.6,214.1,184,227.4,173Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M237.2,180.5c-15.4,13.4-36.6,23.8-61.1,29.8a7.8,7.8,0,0,1-9-4.2l-12.1-24a195,195,0,0,1-27,1.9,195,195,0,0,1-27-1.9h0l-12.1,24a7.8,7.8,0,0,1-9,4.2c-24.5-6-45.7-16.4-61.1-29.8a8.1,8.1,0,0,1-2.4-8.4L50.3,58.9A7.7,7.7,0,0,1,55,53.8a176.4,176.4,0,0,1,29.6-9.2A8.1,8.1,0,0,1,94,49.9l7.9,23.9a190.1,190.1,0,0,1,52.2,0h0L162,49.9a8.1,8.1,0,0,1,9.4-5.3A176.4,176.4,0,0,1,201,53.8a7.7,7.7,0,0,1,4.7,5.1l33.9,113.2A8.1,8.1,0,0,1,237.2,180.5Z"
            opacity="0.2"
          />
          <path d="M108,144a12,12,0,1,1-12-12A12,12,0,0,1,108,144Zm52-12a12,12,0,1,0,12,12A12,12,0,0,0,160,132Zm82.4,54.5c-16.6,14.4-38.9,25.4-64.4,31.6a17.4,17.4,0,0,1-3.9.5,15.8,15.8,0,0,1-14.2-8.9l-9.5-19a193.6,193.6,0,0,1-44.8,0l-9.5,19a15.8,15.8,0,0,1-14.2,8.9,17.4,17.4,0,0,1-3.9-.5c-25.5-6.2-47.8-17.2-64.4-31.6a16,16,0,0,1-4.9-16.7l34-113.2a15.6,15.6,0,0,1,9.2-10.2,172,172,0,0,1,31-9.6,15.9,15.9,0,0,1,18.6,10.5l6,17.8a191.6,191.6,0,0,1,41,0l6-17.8a15.9,15.9,0,0,1,18.6-10.5,172,172,0,0,1,31,9.6,15.6,15.6,0,0,1,9.2,10.2l34,113.2A16,16,0,0,1,242.4,186.5Zm-10.5-12L198,61.2a160.8,160.8,0,0,0-28.3-8.8l-5.1,15a163.4,163.4,0,0,1,19.5,5,8,8,0,0,1-5,15.2c-15.3-5-33-7.6-51.1-7.6s-35.8,2.6-51.1,7.6a7.3,7.3,0,0,1-2.5.4,8,8,0,0,1-2.5-15.6,163.4,163.4,0,0,1,19.5-5l-5-15A167.7,167.7,0,0,0,58,61.1L24,174.4c14.7,12.8,34.7,22.5,57.8,28.2L89,188.1a152.9,152.9,0,0,1-17.1-4.5,8,8,0,1,1,5-15.2c15.3,5,33,7.6,51.1,7.6s35.8-2.6,51.1-7.6a8,8,0,0,1,5,15.2,152.9,152.9,0,0,1-17.1,4.5l7.2,14.5C197.3,196.9,217.3,187.2,231.9,174.5Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M247.3,169.8l-34-113.2a15.6,15.6,0,0,0-9.2-10.2h-.6l.6-.2A192.4,192.4,0,0,0,169.6,36a8,8,0,0,0-9.4,6.3,7.9,7.9,0,0,0,6.2,9.4c4.5.9,8.9,2,13.2,3.2A8,8,0,0,1,176,70h-.8A185.4,185.4,0,0,0,128,64a181.8,181.8,0,0,0-46.1,5.8,8,8,0,0,1-5.6-14.9h.1c4.3-1.2,8.7-2.3,13.2-3.2a8,8,0,0,0,6.3-9.4A8.1,8.1,0,0,0,86.5,36,191.2,191.2,0,0,0,51.9,46.4a15.6,15.6,0,0,0-9.2,10.2L8.7,169.8a16,16,0,0,0,4.9,16.7,34.7,34.7,0,0,0,2.9,2.5h.1c16.2,13.2,37.5,23.3,61.5,29.1a6.3,6.3,0,0,0,1.9.3,8,8,0,0,0,1.9-15.8,160.3,160.3,0,0,1-31.3-11.1h0a8,8,0,0,1,8.6-13.2c19,8.4,42.9,13.7,68.8,13.7s49.8-5.3,68.8-13.7a8,8,0,0,1,8.6,13.2h0a160.3,160.3,0,0,1-31.3,11.1,8,8,0,0,0,1.9,15.8,6.3,6.3,0,0,0,1.9-.3c24-5.8,45.3-15.9,61.5-29.1h.1a34.7,34.7,0,0,0,2.9-2.5A16,16,0,0,0,247.3,169.8ZM96,156a12,12,0,1,1,12-12A12,12,0,0,1,96,156Zm64,0a12,12,0,1,1,12-12A12,12,0,0,1,160,156Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M106,144a10,10,0,1,1-10-10A10,10,0,0,1,106,144Zm54-10a10,10,0,1,0,10,10A10,10,0,0,0,160,134Zm81.1,51c-16.3,14.2-38.3,25-63.6,31.2a18.6,18.6,0,0,1-3.3.4,13.9,13.9,0,0,1-12.5-7.8l-10.1-20.2A203.5,203.5,0,0,1,128,190a203.5,203.5,0,0,1-23.6-1.4L94.3,208.8a13.9,13.9,0,0,1-12.5,7.8,18.6,18.6,0,0,1-3.3-.4c-25.3-6.2-47.3-17-63.6-31.2a14,14,0,0,1-4.3-14.6l34-113.3a14,14,0,0,1,8.1-8.9,174.3,174.3,0,0,1,30.7-9.5A13.9,13.9,0,0,1,99.6,48l6.5,19.2a200.4,200.4,0,0,1,43.8,0L156.4,48a13.9,13.9,0,0,1,16.2-9.3,174.3,174.3,0,0,1,30.7,9.5,14,14,0,0,1,8.1,8.9l34,113.3A14,14,0,0,1,241.1,185Zm-7.2-11.2-34-113.2a2,2,0,0,0-1.2-1.3,170.3,170.3,0,0,0-28.6-8.9,2.2,2.2,0,0,0-2.4,1.4L162,69a152.1,152.1,0,0,1,21.4,5.3A6,6,0,0,1,181.6,86l-1.9-.3A170.8,170.8,0,0,0,128,78a170.8,170.8,0,0,0-51.7,7.7,6,6,0,1,1-3.7-11.4A152.1,152.1,0,0,1,94,69L88.3,51.8a2.2,2.2,0,0,0-2.4-1.4,170.3,170.3,0,0,0-28.6,8.9,2,2,0,0,0-1.2,1.3l-34,113.2a2.1,2.1,0,0,0,.7,2.2c14.9,12.9,35.1,22.8,58.6,28.5a2,2,0,0,0,2.2-1L92,186.7a157,157,0,0,1-19.4-5,6,6,0,1,1,3.7-11.4A170.8,170.8,0,0,0,128,178a170.8,170.8,0,0,0,51.7-7.7,6,6,0,1,1,3.7,11.4,157,157,0,0,1-19.4,5l8.4,16.8a2,2,0,0,0,2.2,1c23.5-5.7,43.7-15.6,58.6-28.5A2.1,2.1,0,0,0,233.9,173.8Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M104,144a8,8,0,1,1-8-8A8,8,0,0,1,104,144Zm56-8a8,8,0,1,0,8,8A8,8,0,0,0,160,136Zm79.8,47.5c-16.1,14-37.8,24.6-62.8,30.7a9,9,0,0,1-2.8.4,11.9,11.9,0,0,1-10.7-6.7l-10.8-21.5a191.5,191.5,0,0,1-49.4,0L92.5,207.9a11.9,11.9,0,0,1-10.7,6.7,9,9,0,0,1-2.8-.4c-25-6.1-46.7-16.7-62.8-30.7A12.1,12.1,0,0,1,12.5,171l34-113.3a11.9,11.9,0,0,1,6.9-7.6,173.5,173.5,0,0,1,30.4-9.4,11.8,11.8,0,0,1,13.9,7.9l7,20.8a194.6,194.6,0,0,1,46.6,0l7-20.8a11.8,11.8,0,0,1,13.9-7.9,173.5,173.5,0,0,1,30.4,9.4,11.9,11.9,0,0,1,6.9,7.6l34,113.3A12.1,12.1,0,0,1,239.8,183.5Zm-4-10.2L201.8,60a3.6,3.6,0,0,0-2.3-2.5,166.1,166.1,0,0,0-29-9,4,4,0,0,0-4.7,2.6l-6.4,19.4a166.7,166.7,0,0,1,23.4,5.7,4,4,0,0,1-1.2,7.8l-1.2-.2A172.8,172.8,0,0,0,128,76a172.8,172.8,0,0,0-52.4,7.8,4,4,0,1,1-2.4-7.6,166.7,166.7,0,0,1,23.4-5.7L90.2,51.1a4,4,0,0,0-4.7-2.6,174.9,174.9,0,0,0-29,8.9A4,4,0,0,0,54.2,60l-34,113.3a3.8,3.8,0,0,0,1.3,4.2c15.1,13.1,35.7,23.2,59.4,29a4.2,4.2,0,0,0,4.5-2.2l9.5-19.1a181.1,181.1,0,0,1-21.7-5.4,4,4,0,1,1,2.4-7.6A172.8,172.8,0,0,0,128,180a172.8,172.8,0,0,0,52.4-7.8,4,4,0,1,1,2.4,7.6,181.1,181.1,0,0,1-21.7,5.4l9.5,19.1a4.2,4.2,0,0,0,4.5,2.2c23.7-5.8,44.3-15.9,59.4-29A3.8,3.8,0,0,0,235.8,173.3Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M108,144a12,12,0,1,1-12-12A12,12,0,0,1,108,144Zm52-12a12,12,0,1,0,12,12A12,12,0,0,0,160,132Zm82.4,54.5c-16.6,14.4-38.9,25.4-64.4,31.6a17.4,17.4,0,0,1-3.9.5,15.8,15.8,0,0,1-14.2-8.9l-9.5-19a193.6,193.6,0,0,1-44.8,0l-9.5,19a15.8,15.8,0,0,1-14.2,8.9,17.4,17.4,0,0,1-3.9-.5c-25.5-6.2-47.8-17.2-64.4-31.6a16,16,0,0,1-4.9-16.7l34-113.2a15.6,15.6,0,0,1,9.2-10.2,172,172,0,0,1,31-9.6,15.9,15.9,0,0,1,18.6,10.5l6,17.8a191.6,191.6,0,0,1,41,0l6-17.8a15.9,15.9,0,0,1,18.6-10.5,172,172,0,0,1,31,9.6,15.6,15.6,0,0,1,9.2,10.2l34,113.2A16,16,0,0,1,242.4,186.5Zm-10.5-12L198,61.2a160.8,160.8,0,0,0-28.3-8.8l-5.1,15a163.4,163.4,0,0,1,19.5,5,8,8,0,0,1-5,15.2c-15.3-5-33-7.6-51.1-7.6s-35.8,2.6-51.1,7.6a7.3,7.3,0,0,1-2.5.4,8,8,0,0,1-2.5-15.6,163.4,163.4,0,0,1,19.5-5l-5-15A167.7,167.7,0,0,0,58,61.1L24,174.4c14.7,12.8,34.7,22.5,57.8,28.2L89,188.1a152.9,152.9,0,0,1-17.1-4.5,8,8,0,1,1,5-15.2c15.3,5,33,7.6,51.1,7.6s35.8-2.6,51.1-7.6a8,8,0,0,1,5,15.2,152.9,152.9,0,0,1-17.1,4.5l7.2,14.5C197.3,196.9,217.3,187.2,231.9,174.5Z" />
        </>
      )}
    </svg>
  );
}

export function PhDivide(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M228,128a12,12,0,0,1-12,12H40a12,12,0,0,1,0-24H216A12,12,0,0,1,228,128ZM128,84a20,20,0,1,0-20-20A20.1,20.1,0,0,0,128,84Zm0,88a20,20,0,1,0,20,20A20.1,20.1,0,0,0,128,172Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M224,128a8,8,0,0,1-8,8H40a8,8,0,0,1,0-16H216A8,8,0,0,1,224,128ZM128,80a16,16,0,1,0-16-16A16,16,0,0,0,128,80Zm0,96a16,16,0,1,0,16,16A16,16,0,0,0,128,176Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M224,128a8,8,0,0,1-8,8H40a8,8,0,0,1,0-16H216A8,8,0,0,1,224,128ZM128,80a16,16,0,1,0-16-16A16,16,0,0,0,128,80Zm0,96a16,16,0,1,0,16,16A16,16,0,0,0,128,176Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M222,128a6,6,0,0,1-6,6H40a6,6,0,0,1,0-12H216A6,6,0,0,1,222,128ZM128,78a14,14,0,1,0-14-14A14,14,0,0,0,128,78Zm0,100a14,14,0,1,0,14,14A14,14,0,0,0,128,178Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M220,128a4,4,0,0,1-4,4H40a4,4,0,0,1,0-8H216A4,4,0,0,1,220,128ZM128,76a12,12,0,1,0-12-12A12,12,0,0,0,128,76Zm0,104a12,12,0,1,0,12,12A12,12,0,0,0,128,180Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M224,128a8,8,0,0,1-8,8H40a8,8,0,0,1,0-16H216A8,8,0,0,1,224,128ZM128,80a16,16,0,1,0-16-16A16,16,0,0,0,128,80Zm0,96a16,16,0,1,0,16,16A16,16,0,0,0,128,176Z" />
        </>
      )}
    </svg>
  );
}

export function PhDog(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M108,136a16,16,0,1,1-16-16A16,16,0,0,1,108,136Zm56-16a16,16,0,1,0,16,16A16,16,0,0,0,164,120Zm70.4,27.6a21.2,21.2,0,0,1-8.4,1.8,19.8,19.8,0,0,1-6-.9V184a44,44,0,0,1-44,44H80a44,44,0,0,1-44-44V148.5a19.8,19.8,0,0,1-6,.9,21.2,21.2,0,0,1-8.4-1.8,19.8,19.8,0,0,1-11.4-21.9L26.6,38.1A20.2,20.2,0,0,1,35.5,25a20,20,0,0,1,15.6-2.6L105.5,36h45l54.4-13.6A20,20,0,0,1,220.5,25a20.2,20.2,0,0,1,8.9,13.1l16.4,87.6A19.8,19.8,0,0,1,234.4,147.6ZM172.9,55.1l46.3,59L206.6,46.7Zm-136.1,59,46.3-59L49.4,46.7ZM196,184V123.4L146.2,60H109.8L60,123.4V184a20.1,20.1,0,0,0,20,20h36v-7l-12.5-12.5a12,12,0,0,1,17-17L128,175l7.5-7.5a12,12,0,0,1,17,17L140,197v7h36A20.1,20.1,0,0,0,196,184Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M208,119.3V184a32,32,0,0,1-32,32H80a32,32,0,0,1-32-32V119.3L104,48h48Z"
            opacity="0.2"
          />
          <path d="M104,140a12,12,0,1,1-12-12A12,12,0,0,1,104,140Zm60-12a12,12,0,1,0,12,12A12,12,0,0,0,164,128Zm68.7,16a16.1,16.1,0,0,1-6.7,1.4,15.6,15.6,0,0,1-10-3.6V184a40,40,0,0,1-40,40H80a40,40,0,0,1-40-40V141.8a15.6,15.6,0,0,1-10,3.6,16.1,16.1,0,0,1-6.7-1.4,15.8,15.8,0,0,1-9.1-17.6L30.6,38.9A16.1,16.1,0,0,1,50.2,26.3L105,40h46l54.8-13.7a16.1,16.1,0,0,1,19.6,12.6l16.4,87.5A15.8,15.8,0,0,1,232.7,144ZM90.1,52.8l-43.8-11L29.9,129.4ZM200,184V122L148.1,56H107.9L56,122v62a24.1,24.1,0,0,0,24,24h40V195.3l-13.7-13.6a8.1,8.1,0,0,1,11.4-11.4L128,180.7l10.3-10.4a8.1,8.1,0,0,1,11.4,11.4L136,195.3V208h40A24.1,24.1,0,0,0,200,184Zm26.1-54.6L209.7,41.8l-43.8,11Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M104,140a12,12,0,1,1-12-12A12,12,0,0,1,104,140Zm60,12a12,12,0,1,0-12-12A12,12,0,0,0,164,152Zm68.7-8a16.1,16.1,0,0,1-6.7,1.4,15.6,15.6,0,0,1-10-3.6V184a40,40,0,0,1-40,40H80a40,40,0,0,1-40-40V141.8a15.6,15.6,0,0,1-10,3.6,16.1,16.1,0,0,1-6.7-1.4,15.8,15.8,0,0,1-9.1-17.6L30.6,38.9A16.1,16.1,0,0,1,50.2,26.3L105,40h46l54.8-13.7a16.1,16.1,0,0,1,19.6,12.6l16.4,87.5A15.8,15.8,0,0,1,232.7,144ZM200,122,148.1,56H107.9L56,122v62a24.1,24.1,0,0,0,24,24h40V195.3l-13.7-13.6a8.1,8.1,0,0,1,11.4-11.4L128,180.7l10.3-10.4a8.1,8.1,0,0,1,11.4,11.4L136,195.3V208h40a24.1,24.1,0,0,0,24-24Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M102,140a10,10,0,1,1-10-10A10,10,0,0,1,102,140Zm62,10a10,10,0,1,0-10-10A10,10,0,0,0,164,150Zm67.9-7.9a13.7,13.7,0,0,1-5.9,1.3,14,14,0,0,1-10.9-5.4l-1.1-1.4V184a38,38,0,0,1-38,38H80a38,38,0,0,1-38-38V136.6L40.9,138A14,14,0,0,1,30,143.4a13.7,13.7,0,0,1-5.9-1.3,13.7,13.7,0,0,1-8-15.3L32.5,39.2A14.4,14.4,0,0,1,38.7,30a14.7,14.7,0,0,1,11-1.8l55,13.8h46.6l55-13.8a14.7,14.7,0,0,1,11,1.8,14.4,14.4,0,0,1,6.2,9.2l16.4,87.6A13.7,13.7,0,0,1,231.9,142.1ZM93.6,51.6,46.8,39.9h-.5a2.4,2.4,0,0,0-1.1.3,2.2,2.2,0,0,0-.9,1.4L27.9,129a2,2,0,0,0,3.6,1.6ZM202,121.3,149.1,54H106.9L54,121.3V184a26.1,26.1,0,0,0,26,26h42V194.5l-14.2-14.3a5.9,5.9,0,0,1,8.4-8.4L128,183.5l11.8-11.7a5.9,5.9,0,0,1,8.4,8.4L134,194.5V210h42a26.1,26.1,0,0,0,26-26Zm26.1,7.7L211.7,41.5a2.2,2.2,0,0,0-.9-1.4,2.4,2.4,0,0,0-1.6-.2L162.4,51.6l62.1,79a2,2,0,0,0,3.6-1.6Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M100,140a8,8,0,1,1-8-8A8,8,0,0,1,100,140Zm64,8a8,8,0,1,0-8-8A8,8,0,0,0,164,148Zm67.1-7.7a11.9,11.9,0,0,1-14.4-3.5l-4.7-6V184a36,36,0,0,1-36,36H80a36,36,0,0,1-36-36V130.8l-4.7,6a11.9,11.9,0,0,1-14.4,3.5,11.7,11.7,0,0,1-6.8-13.1L34.5,39.6a11.9,11.9,0,0,1,14.7-9.4L104.5,44h47l55.3-13.8a11.9,11.9,0,0,1,14.7,9.4l16.4,87.6A11.7,11.7,0,0,1,231.1,140.3ZM97,50.4,47.3,37.9h-1a3.6,3.6,0,0,0-2.2.7,3.9,3.9,0,0,0-1.7,2.6L25.9,128.6a4,4,0,0,0,7.1,3.3Zm107,70.3L150.1,52H105.9L52,120.7V184a28.1,28.1,0,0,0,28,28h44V193.7l-14.8-14.9a4,4,0,0,1,5.6-5.6L128,186.3l13.2-13.1a4,4,0,0,1,5.6,5.6L132,193.7V212h44a28.1,28.1,0,0,0,28-28Zm26.1,7.9L213.6,41.1a3.9,3.9,0,0,0-1.7-2.6,4.5,4.5,0,0,0-3.2-.6L159,50.4l64,81.5a4,4,0,0,0,7.1-3.3Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M104,140a12,12,0,1,1-12-12A12,12,0,0,1,104,140Zm60-12a12,12,0,1,0,12,12A12,12,0,0,0,164,128Zm68.7,16a16.1,16.1,0,0,1-6.7,1.4,15.6,15.6,0,0,1-10-3.6V184a40,40,0,0,1-40,40H80a40,40,0,0,1-40-40V141.8a15.6,15.6,0,0,1-10,3.6,16.1,16.1,0,0,1-6.7-1.4,15.8,15.8,0,0,1-9.1-17.6L30.6,38.9A16.1,16.1,0,0,1,50.2,26.3L105,40h46l54.8-13.7a16.1,16.1,0,0,1,19.6,12.6l16.4,87.5A15.8,15.8,0,0,1,232.7,144ZM90.1,52.8l-43.8-11L29.9,129.4ZM200,184V122L148.1,56H107.9L56,122v62a24.1,24.1,0,0,0,24,24h40V195.3l-13.7-13.6a8.1,8.1,0,0,1,11.4-11.4L128,180.7l10.3-10.4a8.1,8.1,0,0,1,11.4,11.4L136,195.3V208h40A24.1,24.1,0,0,0,200,184Zm26.1-54.6L209.7,41.8l-43.8,11Z" />
        </>
      )}
    </svg>
  );
}

export function PhDoor(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M232,212H212V40a20.1,20.1,0,0,0-20-20H64A20.1,20.1,0,0,0,44,40V212H24a12,12,0,0,0,0,24H232a12,12,0,0,0,0-24ZM68,44H188V212H68Zm104,84a16,16,0,1,1-16-16A16,16,0,0,1,172,128Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M200,40V224H56V40a8,8,0,0,1,8-8H192A8,8,0,0,1,200,40Z"
            opacity="0.2"
          />
          <path d="M232,216H208V40a16,16,0,0,0-16-16H64A16,16,0,0,0,48,40V216H24a8,8,0,0,0,0,16H232a8,8,0,0,0,0-16ZM64,40H192V216H64Zm104,88a12,12,0,1,1-12-12A12,12,0,0,1,168,128Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M232,216H208V40a16,16,0,0,0-16-16H64A16,16,0,0,0,48,40V216H24a8,8,0,0,0,0,16H232a8,8,0,0,0,0-16Zm-68-76a12,12,0,1,1,12-12A12,12,0,0,1,164,140Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M232,218H206V40a14,14,0,0,0-14-14H64A14,14,0,0,0,50,40V218H24a6,6,0,0,0,0,12H232a6,6,0,0,0,0-12ZM62,40a2,2,0,0,1,2-2H192a2,2,0,0,1,2,2V218H62Zm104,88a10,10,0,1,1-10-10A10,10,0,0,1,166,128Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M232,220H204V40a12,12,0,0,0-12-12H64A12,12,0,0,0,52,40V220H24a4,4,0,0,0,0,8H232a4,4,0,0,0,0-8ZM60,40a4,4,0,0,1,4-4H192a4,4,0,0,1,4,4V220H60Zm104,88a8,8,0,1,1-8-8A8,8,0,0,1,164,128Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M232,216H208V40a16,16,0,0,0-16-16H64A16,16,0,0,0,48,40V216H24a8,8,0,0,0,0,16H232a8,8,0,0,0,0-16ZM64,40H192V216H64Zm104,88a12,12,0,1,1-12-12A12,12,0,0,1,168,128Z" />
        </>
      )}
    </svg>
  );
}

export function PhDotsNine(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M76,60A16,16,0,1,1,60,44,16,16,0,0,1,76,60Zm52-16a16,16,0,1,0,16,16A16,16,0,0,0,128,44Zm68,32a16,16,0,1,0-16-16A16,16,0,0,0,196,76ZM60,180a16,16,0,1,0,16,16A16,16,0,0,0,60,180Zm68,0a16,16,0,1,0,16,16A16,16,0,0,0,128,180Zm68,0a16,16,0,1,0,16,16A16,16,0,0,0,196,180ZM60,112a16,16,0,1,0,16,16A16,16,0,0,0,60,112Zm68,0a16,16,0,1,0,16,16A16,16,0,0,0,128,112Zm68,0a16,16,0,1,0,16,16A16,16,0,0,0,196,112Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M72,128a12,12,0,1,1-12-12A12,12,0,0,1,72,128Zm56,56a12,12,0,1,0,12,12A12,12,0,0,0,128,184ZM196,72a12,12,0,1,0-12-12A12,12,0,0,0,196,72ZM60,184a12,12,0,1,0,12,12A12,12,0,0,0,60,184ZM60,48A12,12,0,1,0,72,60,12,12,0,0,0,60,48Zm136,68a12,12,0,1,0,12,12A12,12,0,0,0,196,116Zm0,68a12,12,0,1,0,12,12A12,12,0,0,0,196,184ZM128,48a12,12,0,1,0,12,12A12,12,0,0,0,128,48Zm0,68a12,12,0,1,0,12,12A12,12,0,0,0,128,116Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M60,48A12,12,0,1,0,72,60,12,12,0,0,0,60,48Zm68,0a12,12,0,1,0,12,12A12,12,0,0,0,128,48Zm68,24a12,12,0,1,0-12-12A12,12,0,0,0,196,72ZM60,184a12,12,0,1,0,12,12A12,12,0,0,0,60,184Zm68,0a12,12,0,1,0,12,12A12,12,0,0,0,128,184Zm68,0a12,12,0,1,0,12,12A12,12,0,0,0,196,184ZM60,116a12,12,0,1,0,12,12A12,12,0,0,0,60,116Zm68,0a12,12,0,1,0,12,12A12,12,0,0,0,128,116Zm68,0a12,12,0,1,0,12,12A12,12,0,0,0,196,116Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M70,60A10,10,0,1,1,60,50,10,10,0,0,1,70,60Zm58-10a10,10,0,1,0,10,10A10,10,0,0,0,128,50Zm68,20a10,10,0,1,0-10-10A10,10,0,0,0,196,70ZM60,186a10,10,0,1,0,10,10A10,10,0,0,0,60,186Zm68,0a10,10,0,1,0,10,10A10,10,0,0,0,128,186Zm68,0a10,10,0,1,0,10,10A10,10,0,0,0,196,186ZM60,118a10,10,0,1,0,10,10A10,10,0,0,0,60,118Zm68,0a10,10,0,1,0,10,10A10,10,0,0,0,128,118Zm68,0a10,10,0,1,0,10,10A10,10,0,0,0,196,118Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M68,60a8,8,0,1,1-8-8A8,8,0,0,1,68,60Zm60-8a8,8,0,1,0,8,8A8,8,0,0,0,128,52Zm68,16a8,8,0,1,0-8-8A8,8,0,0,0,196,68ZM60,188a8,8,0,1,0,8,8A8,8,0,0,0,60,188Zm68,0a8,8,0,1,0,8,8A8,8,0,0,0,128,188Zm68,0a8,8,0,1,0,8,8A8,8,0,0,0,196,188ZM60,120a8,8,0,1,0,8,8A8,8,0,0,0,60,120Zm68,0a8,8,0,1,0,8,8A8,8,0,0,0,128,120Zm68,0a8,8,0,1,0,8,8A8,8,0,0,0,196,120Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M72,60A12,12,0,1,1,60,48,12,12,0,0,1,72,60Zm56-12a12,12,0,1,0,12,12A12,12,0,0,0,128,48Zm68,24a12,12,0,1,0-12-12A12,12,0,0,0,196,72ZM60,184a12,12,0,1,0,12,12A12,12,0,0,0,60,184Zm68,0a12,12,0,1,0,12,12A12,12,0,0,0,128,184Zm68,0a12,12,0,1,0,12,12A12,12,0,0,0,196,184ZM60,116a12,12,0,1,0,12,12A12,12,0,0,0,60,116Zm68,0a12,12,0,1,0,12,12A12,12,0,0,0,128,116Zm68,0a12,12,0,1,0,12,12A12,12,0,0,0,196,116Z" />
        </>
      )}
    </svg>
  );
}

export function PhDotsSixVertical(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M108,60A16,16,0,1,1,92,44,16,16,0,0,1,108,60Zm56,16a16,16,0,1,0-16-16A16,16,0,0,0,164,76ZM92,112a16,16,0,1,0,16,16A16,16,0,0,0,92,112Zm72,0a16,16,0,1,0,16,16A16,16,0,0,0,164,112ZM92,180a16,16,0,1,0,16,16A16,16,0,0,0,92,180Zm72,0a16,16,0,1,0,16,16A16,16,0,0,0,164,180Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M104,60A12,12,0,1,1,92,48,12,12,0,0,1,104,60Zm60,12a12,12,0,1,0-12-12A12,12,0,0,0,164,72ZM92,116a12,12,0,1,0,12,12A12,12,0,0,0,92,116Zm72,0a12,12,0,1,0,12,12A12,12,0,0,0,164,116ZM92,184a12,12,0,1,0,12,12A12,12,0,0,0,92,184Zm72,0a12,12,0,1,0,12,12A12,12,0,0,0,164,184Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M104,196a12,12,0,1,1-12-12A12,12,0,0,1,104,196ZM92,116a12,12,0,1,0,12,12A12,12,0,0,0,92,116Zm72-44a12,12,0,1,0-12-12A12,12,0,0,0,164,72Zm0,44a12,12,0,1,0,12,12A12,12,0,0,0,164,116ZM92,48a12,12,0,1,0,12,12A12,12,0,0,0,92,48Zm72,136a12,12,0,1,0,12,12A12,12,0,0,0,164,184Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M102,60A10,10,0,1,1,92,50,10,10,0,0,1,102,60Zm62,10a10,10,0,1,0-10-10A10,10,0,0,0,164,70ZM92,118a10,10,0,1,0,10,10A10,10,0,0,0,92,118Zm72,0a10,10,0,1,0,10,10A10,10,0,0,0,164,118ZM92,186a10,10,0,1,0,10,10A10,10,0,0,0,92,186Zm72,0a10,10,0,1,0,10,10A10,10,0,0,0,164,186Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M100,60a8,8,0,1,1-8-8A8,8,0,0,1,100,60Zm64,8a8,8,0,1,0-8-8A8,8,0,0,0,164,68ZM92,120a8,8,0,1,0,8,8A8,8,0,0,0,92,120Zm72,0a8,8,0,1,0,8,8A8,8,0,0,0,164,120ZM92,188a8,8,0,1,0,8,8A8,8,0,0,0,92,188Zm72,0a8,8,0,1,0,8,8A8,8,0,0,0,164,188Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M104,60A12,12,0,1,1,92,48,12,12,0,0,1,104,60Zm60,12a12,12,0,1,0-12-12A12,12,0,0,0,164,72ZM92,116a12,12,0,1,0,12,12A12,12,0,0,0,92,116Zm72,0a12,12,0,1,0,12,12A12,12,0,0,0,164,116ZM92,184a12,12,0,1,0,12,12A12,12,0,0,0,92,184Zm72,0a12,12,0,1,0,12,12A12,12,0,0,0,164,184Z" />
        </>
      )}
    </svg>
  );
}

export function PhDotsSix(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M76,92A16,16,0,1,1,60,76,16,16,0,0,1,76,92Zm52-16a16,16,0,1,0,16,16A16,16,0,0,0,128,76Zm68,32a16,16,0,1,0-16-16A16,16,0,0,0,196,108ZM60,148a16,16,0,1,0,16,16A16,16,0,0,0,60,148Zm68,0a16,16,0,1,0,16,16A16,16,0,0,0,128,148Zm68,0a16,16,0,1,0,16,16A16,16,0,0,0,196,148Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M72,92A12,12,0,1,1,60,80,12,12,0,0,1,72,92Zm56-12a12,12,0,1,0,12,12A12,12,0,0,0,128,80Zm68,24a12,12,0,1,0-12-12A12,12,0,0,0,196,104ZM60,152a12,12,0,1,0,12,12A12,12,0,0,0,60,152Zm68,0a12,12,0,1,0,12,12A12,12,0,0,0,128,152Zm68,0a12,12,0,1,0,12,12A12,12,0,0,0,196,152Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M72,164a12,12,0,1,1-12-12A12,12,0,0,1,72,164Zm124-60a12,12,0,1,0-12-12A12,12,0,0,0,196,104ZM60,80A12,12,0,1,0,72,92,12,12,0,0,0,60,80Zm68,0a12,12,0,1,0,12,12A12,12,0,0,0,128,80Zm68,72a12,12,0,1,0,12,12A12,12,0,0,0,196,152Zm-68,0a12,12,0,1,0,12,12A12,12,0,0,0,128,152Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M70,92A10,10,0,1,1,60,82,10,10,0,0,1,70,92Zm58-10a10,10,0,1,0,10,10A10,10,0,0,0,128,82Zm68,20a10,10,0,1,0-10-10A10,10,0,0,0,196,102ZM60,154a10,10,0,1,0,10,10A10,10,0,0,0,60,154Zm68,0a10,10,0,1,0,10,10A10,10,0,0,0,128,154Zm68,0a10,10,0,1,0,10,10A10,10,0,0,0,196,154Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M68,92a8,8,0,1,1-8-8A8,8,0,0,1,68,92Zm60-8a8,8,0,1,0,8,8A8,8,0,0,0,128,84Zm68,16a8,8,0,1,0-8-8A8,8,0,0,0,196,100ZM60,156a8,8,0,1,0,8,8A8,8,0,0,0,60,156Zm68,0a8,8,0,1,0,8,8A8,8,0,0,0,128,156Zm68,0a8,8,0,1,0,8,8A8,8,0,0,0,196,156Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M72,92A12,12,0,1,1,60,80,12,12,0,0,1,72,92Zm56-12a12,12,0,1,0,12,12A12,12,0,0,0,128,80Zm68,24a12,12,0,1,0-12-12A12,12,0,0,0,196,104ZM60,152a12,12,0,1,0,12,12A12,12,0,0,0,60,152Zm68,0a12,12,0,1,0,12,12A12,12,0,0,0,128,152Zm68,0a12,12,0,1,0,12,12A12,12,0,0,0,196,152Z" />
        </>
      )}
    </svg>
  );
}

export function PhDotsThreeCircleVertical(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,20A108,108,0,1,0,236,128,108.1,108.1,0,0,0,128,20Zm0,192a84,84,0,1,1,84-84A84.1,84.1,0,0,1,128,212Zm16-112a16,16,0,1,1-16-16A16,16,0,0,1,144,100Zm0,56a16,16,0,1,1-16-16A16,16,0,0,1,144,156Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="128" cy="128" r="96" />
          </g>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm12-88a12,12,0,1,1-12-12A12,12,0,0,1,140,128Zm0,48a12,12,0,1,1-12-12A12,12,0,0,1,140,176Zm0-96a12,12,0,1,1-12-12A12,12,0,0,1,140,80Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,164a12,12,0,1,1,12-12A12,12,0,0,1,128,188Zm0-48a12,12,0,1,1,12-12A12,12,0,0,1,128,140Zm0-48a12,12,0,1,1,12-12A12,12,0,0,1,128,92Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,26A102,102,0,1,0,230,128,102.2,102.2,0,0,0,128,26Zm0,192a90,90,0,1,1,90-90A90.1,90.1,0,0,1,128,218Zm10-90a10,10,0,1,1-10-10A10,10,0,0,1,138,128Zm0,48a10,10,0,1,1-10-10A10,10,0,0,1,138,176Zm0-96a10,10,0,1,1-10-10A10,10,0,0,1,138,80Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,28A100,100,0,1,0,228,128,100.2,100.2,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm8-92a8,8,0,1,1-8-8A8,8,0,0,1,136,128Zm0,48a8,8,0,1,1-8-8A8,8,0,0,1,136,176Zm0-96a8,8,0,1,1-8-8A8,8,0,0,1,136,80Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm12-88a12,12,0,1,1-12-12A12,12,0,0,1,140,128Zm0,48a12,12,0,1,1-12-12A12,12,0,0,1,140,176Zm0-96a12,12,0,1,1-12-12A12,12,0,0,1,140,80Z" />
        </>
      )}
    </svg>
  );
}

export function PhDotsThreeCircle(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,20A108,108,0,1,0,236,128,108.1,108.1,0,0,0,128,20Zm0,192a84,84,0,1,1,84-84A84.1,84.1,0,0,1,128,212Zm-12-84a16,16,0,1,1-16-16A16,16,0,0,1,116,128Zm56,0a16,16,0,1,1-16-16A16,16,0,0,1,172,128Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="128" cy="128" r="96" />
          </g>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm12-88a12,12,0,1,1-12-12A12,12,0,0,1,140,128Zm48,0a12,12,0,1,1-12-12A12,12,0,0,1,188,128Zm-96,0a12,12,0,1,1-12-12A12,12,0,0,1,92,128Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24ZM80,140a12,12,0,1,1,12-12A12,12,0,0,1,80,140Zm48,0a12,12,0,1,1,12-12A12,12,0,0,1,128,140Zm48,0a12,12,0,1,1,12-12A12,12,0,0,1,176,140Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,26A102,102,0,1,0,230,128,102.2,102.2,0,0,0,128,26Zm0,192a90,90,0,1,1,90-90A90.1,90.1,0,0,1,128,218Zm10-90a10,10,0,1,1-10-10A10,10,0,0,1,138,128Zm48,0a10,10,0,1,1-10-10A10,10,0,0,1,186,128Zm-96,0a10,10,0,1,1-10-10A10,10,0,0,1,90,128Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,28A100,100,0,1,0,228,128,100.2,100.2,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm8-92a8,8,0,1,1-8-8A8,8,0,0,1,136,128Zm48,0a8,8,0,1,1-8-8A8,8,0,0,1,184,128Zm-96,0a8,8,0,1,1-8-8A8,8,0,0,1,88,128Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm12-88a12,12,0,1,1-12-12A12,12,0,0,1,140,128Zm48,0a12,12,0,1,1-12-12A12,12,0,0,1,188,128Zm-96,0a12,12,0,1,1-12-12A12,12,0,0,1,92,128Z" />
        </>
      )}
    </svg>
  );
}

export function PhDotsThreeOutlineVertical(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,96a32,32,0,1,0,32,32A32.1,32.1,0,0,0,128,96Zm0,40a8,8,0,1,1,8-8A8,8,0,0,1,128,136Zm0-56A32,32,0,1,0,96,48,32.1,32.1,0,0,0,128,80Zm0-40a8,8,0,1,1-8,8A8,8,0,0,1,128,40Zm0,136a32,32,0,1,0,32,32A32.1,32.1,0,0,0,128,176Zm0,40a8,8,0,1,1,8-8A8,8,0,0,1,128,216Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.1">
            <circle cx="128" cy="128" r="24" />
          </g>
          <g opacity="0.1">
            <circle cx="128" cy="48" r="24" />
          </g>
          <g opacity="0.1">
            <circle cx="128" cy="208" r="24" />
          </g>
          <path d="M128,96a32,32,0,1,0,32,32A32.1,32.1,0,0,0,128,96Zm0,48a16,16,0,1,1,16-16A16,16,0,0,1,128,144Z" />
          <path d="M128,80A32,32,0,1,0,96,48,32.1,32.1,0,0,0,128,80Zm0-48a16,16,0,1,1-16,16A16,16,0,0,1,128,32Z" />
          <path d="M128,176a32,32,0,1,0,32,32A32.1,32.1,0,0,0,128,176Zm0,48a16,16,0,1,1,16-16A16,16,0,0,1,128,224Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M156,128a28,28,0,1,1-28-28A28.1,28.1,0,0,1,156,128ZM128,76a28,28,0,1,0-28-28A28.1,28.1,0,0,0,128,76Zm0,104a28,28,0,1,0,28,28A28.1,28.1,0,0,0,128,180Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,98a30,30,0,1,0,30,30A30.1,30.1,0,0,0,128,98Zm0,48a18,18,0,1,1,18-18A18.1,18.1,0,0,1,128,146Zm0-68A30,30,0,1,0,98,48,30.1,30.1,0,0,0,128,78Zm0-48a18,18,0,1,1-18,18A18.1,18.1,0,0,1,128,30Zm0,148a30,30,0,1,0,30,30A30.1,30.1,0,0,0,128,178Zm0,48a18,18,0,1,1,18-18A18.1,18.1,0,0,1,128,226Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,100a28,28,0,1,0,28,28A28.1,28.1,0,0,0,128,100Zm0,48a20,20,0,1,1,20-20A20.1,20.1,0,0,1,128,148Zm0-72a28,28,0,1,0-28-28A28.1,28.1,0,0,0,128,76Zm0-48a20,20,0,1,1-20,20A20.1,20.1,0,0,1,128,28Zm0,152a28,28,0,1,0,28,28A28.1,28.1,0,0,0,128,180Zm0,48a20,20,0,1,1,20-20A20.1,20.1,0,0,1,128,228Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,96a32,32,0,1,0,32,32A32.1,32.1,0,0,0,128,96Zm0,48a16,16,0,1,1,16-16A16,16,0,0,1,128,144Zm0-64A32,32,0,1,0,96,48,32.1,32.1,0,0,0,128,80Zm0-48a16,16,0,1,1-16,16A16,16,0,0,1,128,32Zm0,144a32,32,0,1,0,32,32A32.1,32.1,0,0,0,128,176Zm0,48a16,16,0,1,1,16-16A16,16,0,0,1,128,224Z" />
        </>
      )}
    </svg>
  );
}

export function PhDotsThreeOutline(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,96a32,32,0,1,0,32,32A32.1,32.1,0,0,0,128,96Zm0,40a8,8,0,1,1,8-8A8,8,0,0,1,128,136Zm80-40a32,32,0,1,0,32,32A32.1,32.1,0,0,0,208,96Zm0,40a8,8,0,1,1,8-8A8,8,0,0,1,208,136ZM48,96a32,32,0,1,0,32,32A32.1,32.1,0,0,0,48,96Zm0,40a8,8,0,1,1,8-8A8,8,0,0,1,48,136Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.1">
            <circle cx="128" cy="128" r="24" />
          </g>
          <g opacity="0.1">
            <circle cx="48" cy="128" r="24" />
          </g>
          <g opacity="0.1">
            <circle cx="208" cy="128" r="24" />
          </g>
          <path d="M128,96a32,32,0,1,0,32,32A32.1,32.1,0,0,0,128,96Zm0,48a16,16,0,1,1,16-16A16,16,0,0,1,128,144Z" />
          <path d="M48,96a32,32,0,1,0,32,32A32.1,32.1,0,0,0,48,96Zm0,48a16,16,0,1,1,16-16A16,16,0,0,1,48,144Z" />
          <path d="M208,96a32,32,0,1,0,32,32A32.1,32.1,0,0,0,208,96Zm0,48a16,16,0,1,1,16-16A16,16,0,0,1,208,144Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M156,128a28,28,0,1,1-28-28A28.1,28.1,0,0,1,156,128ZM48,100a28,28,0,1,0,28,28A28.1,28.1,0,0,0,48,100Zm160,0a28,28,0,1,0,28,28A28.1,28.1,0,0,0,208,100Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,98a30,30,0,1,0,30,30A30.1,30.1,0,0,0,128,98Zm0,48a18,18,0,1,1,18-18A18.1,18.1,0,0,1,128,146ZM48,98a30,30,0,1,0,30,30A30.1,30.1,0,0,0,48,98Zm0,48a18,18,0,1,1,18-18A18.1,18.1,0,0,1,48,146ZM208,98a30,30,0,1,0,30,30A30.1,30.1,0,0,0,208,98Zm0,48a18,18,0,1,1,18-18A18.1,18.1,0,0,1,208,146Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,100a28,28,0,1,0,28,28A28.1,28.1,0,0,0,128,100Zm0,48a20,20,0,1,1,20-20A20.1,20.1,0,0,1,128,148ZM48,100a28,28,0,1,0,28,28A28.1,28.1,0,0,0,48,100Zm0,48a20,20,0,1,1,20-20A20.1,20.1,0,0,1,48,148Zm160-48a28,28,0,1,0,28,28A28.1,28.1,0,0,0,208,100Zm0,48a20,20,0,1,1,20-20A20.1,20.1,0,0,1,208,148Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,96a32,32,0,1,0,32,32A32.1,32.1,0,0,0,128,96Zm0,48a16,16,0,1,1,16-16A16,16,0,0,1,128,144ZM48,96a32,32,0,1,0,32,32A32.1,32.1,0,0,0,48,96Zm0,48a16,16,0,1,1,16-16A16,16,0,0,1,48,144ZM208,96a32,32,0,1,0,32,32A32.1,32.1,0,0,0,208,96Zm0,48a16,16,0,1,1,16-16A16,16,0,0,1,208,144Z" />
        </>
      )}
    </svg>
  );
}

export function PhDotsThreeVertical(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M144,192a16,16,0,1,1-16-16A16,16,0,0,1,144,192ZM128,80a16,16,0,1,0-16-16A16,16,0,0,0,128,80Zm0,32a16,16,0,1,0,16,16A16,16,0,0,0,128,112Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M140,192a12,12,0,1,1-12-12A12,12,0,0,1,140,192ZM128,76a12,12,0,1,0-12-12A12,12,0,0,0,128,76Zm0,40a12,12,0,1,0,12,12A12,12,0,0,0,128,116Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M116,64a12,12,0,1,1,12,12A12,12,0,0,1,116,64Zm12,52a12,12,0,1,0,12,12A12,12,0,0,0,128,116Zm0,64a12,12,0,1,0,12,12A12,12,0,0,0,128,180Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M138,192a10,10,0,1,1-10-10A10,10,0,0,1,138,192ZM128,74a10,10,0,1,0-10-10A10,10,0,0,0,128,74Zm0,44a10,10,0,1,0,10,10A10,10,0,0,0,128,118Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M136,192a8,8,0,1,1-8-8A8,8,0,0,1,136,192ZM128,72a8,8,0,1,0-8-8A8,8,0,0,0,128,72Zm0,48a8,8,0,1,0,8,8A8,8,0,0,0,128,120Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M140,192a12,12,0,1,1-12-12A12,12,0,0,1,140,192ZM128,76a12,12,0,1,0-12-12A12,12,0,0,0,128,76Zm0,40a12,12,0,1,0,12,12A12,12,0,0,0,128,116Z" />
        </>
      )}
    </svg>
  );
}

export function PhDotsThree(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M80,128a16,16,0,1,1-16-16A16,16,0,0,1,80,128Zm112-16a16,16,0,1,0,16,16A16,16,0,0,0,192,112Zm-64,0a16,16,0,1,0,16,16A16,16,0,0,0,128,112Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <circle cx="64" cy="128" r="12" />
          <circle cx="192" cy="128" r="12" />
          <circle cx="128" cy="128" r="12" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M76,128a12,12,0,1,1-12-12A12,12,0,0,1,76,128Zm52-12a12,12,0,1,0,12,12A12,12,0,0,0,128,116Zm64,0a12,12,0,1,0,12,12A12,12,0,0,0,192,116Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M74,128a10,10,0,1,1-10-10A10,10,0,0,1,74,128Zm118-10a10,10,0,1,0,10,10A10,10,0,0,0,192,118Zm-64,0a10,10,0,1,0,10,10A10,10,0,0,0,128,118Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M72,128a8,8,0,1,1-8-8A8,8,0,0,1,72,128Zm120-8a8,8,0,1,0,8,8A8,8,0,0,0,192,120Zm-64,0a8,8,0,1,0,8,8A8,8,0,0,0,128,120Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M76,128a12,12,0,1,1-12-12A12,12,0,0,1,76,128Zm116-12a12,12,0,1,0,12,12A12,12,0,0,0,192,116Zm-64,0a12,12,0,1,0,12,12A12,12,0,0,0,128,116Z" />
        </>
      )}
    </svg>
  );
}

export function PhDownloadSimple(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M77.5,118.5a12,12,0,0,1,17-17L116,123V40a12,12,0,0,1,24,0v83l21.5-21.5a12,12,0,0,1,17,17l-42,42a12.1,12.1,0,0,1-17,0ZM216,140a12,12,0,0,0-12,12v52H52V152a12,12,0,0,0-24,0v56a20.1,20.1,0,0,0,20,20H208a20.1,20.1,0,0,0,20-20V152A12,12,0,0,0,216,140Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M80.3,115.7a8,8,0,0,1,11.4-11.3L120,132.7V40a8,8,0,0,1,16,0v92.7l28.3-28.3a8,8,0,0,1,11.4,11.3l-42,42a8.2,8.2,0,0,1-11.4,0ZM216,144a8,8,0,0,0-8,8v56H48V152a8,8,0,0,0-16,0v56a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V152A8,8,0,0,0,216,144Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M80.3,115.7a8.2,8.2,0,0,1-1.7-8.7,8,8,0,0,1,7.4-5h34V40a8,8,0,0,1,16,0v62h34a8,8,0,0,1,7.4,5,8.2,8.2,0,0,1-1.7,8.7l-42,42a8.2,8.2,0,0,1-11.4,0ZM216,144a8,8,0,0,0-8,8v56H48V152a8,8,0,0,0-16,0v56a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V152A8,8,0,0,0,216,144Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M81.8,114.3a5.9,5.9,0,0,1,0-8.5,5.8,5.8,0,0,1,8.4,0L122,137.5V40a6,6,0,0,1,12,0v97.5l31.8-31.7a5.8,5.8,0,0,1,8.4,0,5.9,5.9,0,0,1,0,8.5l-42,41.9a5.8,5.8,0,0,1-8.4,0ZM216,146a6,6,0,0,0-6,6v56a2,2,0,0,1-2,2H48a2,2,0,0,1-2-2V152a6,6,0,0,0-12,0v56a14,14,0,0,0,14,14H208a14,14,0,0,0,14-14V152A6,6,0,0,0,216,146Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M83.2,112.8a4,4,0,0,1,5.6-5.6L124,142.3V40a4,4,0,0,1,8,0V142.3l35.2-35.1a4,4,0,1,1,5.6,5.6l-42,42a3.9,3.9,0,0,1-5.6,0ZM216,148a4,4,0,0,0-4,4v56a4,4,0,0,1-4,4H48a4,4,0,0,1-4-4V152a4,4,0,0,0-8,0v56a12,12,0,0,0,12,12H208a12,12,0,0,0,12-12V152A4,4,0,0,0,216,148Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M80.3,115.7a8,8,0,0,1,11.4-11.3L120,132.7V40a8,8,0,0,1,16,0v92.7l28.3-28.3a8,8,0,0,1,11.4,11.3l-42,42a8.2,8.2,0,0,1-11.4,0ZM216,144a8,8,0,0,0-8,8v56H48V152a8,8,0,0,0-16,0v56a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V152A8,8,0,0,0,216,144Z" />
        </>
      )}
    </svg>
  );
}

export function PhDownload(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M71.5,88.5a12,12,0,0,1,17-17L116,99V24a12,12,0,0,1,24,0V99l27.5-27.5a12,12,0,0,1,17,17l-48,48a12.1,12.1,0,0,1-17,0ZM224,116H184.6a12,12,0,0,0,0,24H220v56H36V140H71.4a12,12,0,0,0,0-24H32a20.1,20.1,0,0,0-20,20v64a20.1,20.1,0,0,0,20,20H224a20.1,20.1,0,0,0,20-20V136A20.1,20.1,0,0,0,224,116Zm-20,52a16,16,0,1,0-16,16A16,16,0,0,0,204,168Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M232,136v64a8,8,0,0,1-8,8H32a8,8,0,0,1-8-8V136a8,8,0,0,1,8-8H224A8,8,0,0,1,232,136Z"
            opacity="0.2"
          />
          <path d="M240,136v64a16,16,0,0,1-16,16H32a16,16,0,0,1-16-16V136a16,16,0,0,1,16-16H80a8,8,0,0,1,0,16H32v64H224V136H176a8,8,0,0,1,0-16h48A16,16,0,0,1,240,136Zm-117.7-2.3a8.2,8.2,0,0,0,11.4,0l48-48a8.1,8.1,0,0,0-11.4-11.4L136,108.7V24a8,8,0,0,0-16,0v84.7L85.7,74.3A8.1,8.1,0,0,0,74.3,85.7ZM200,168a12,12,0,1,0-12,12A12,12,0,0,0,200,168Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M74.3,85.7A8.1,8.1,0,0,1,85.7,74.3L120,108.7V24a8,8,0,0,1,16,0v84.7l34.3-34.4a8.1,8.1,0,0,1,11.4,11.4l-48,48a8.2,8.2,0,0,1-11.4,0ZM240,136v64a16,16,0,0,1-16,16H32a16,16,0,0,1-16-16V136a16,16,0,0,1,16-16H84.4a3.6,3.6,0,0,1,2.8,1.2L111,145a24.1,24.1,0,0,0,34,0l23.8-23.8a3.6,3.6,0,0,1,2.8-1.2H224A16,16,0,0,1,240,136Zm-40,32a12,12,0,1,0-12,12A12,12,0,0,0,200,168Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M238,136v64a14,14,0,0,1-14,14H32a14,14,0,0,1-14-14V136a14,14,0,0,1,14-14H80a6,6,0,0,1,0,12H32a2,2,0,0,0-2,2v64a2,2,0,0,0,2,2H224a2,2,0,0,0,2-2V136a2,2,0,0,0-2-2H176a6,6,0,0,1,0-12h48A14,14,0,0,1,238,136Zm-114.2-3.8a5.8,5.8,0,0,0,8.4,0l48-48a5.9,5.9,0,0,0-8.4-8.4L134,113.5V24a6,6,0,0,0-12,0v89.5L84.2,75.8a5.9,5.9,0,0,0-8.4,8.4ZM198,168a10,10,0,1,0-10,10A10,10,0,0,0,198,168Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M236,136v64a12,12,0,0,1-12,12H32a12,12,0,0,1-12-12V136a12,12,0,0,1,12-12H80a4,4,0,0,1,0,8H32a4,4,0,0,0-4,4v64a4,4,0,0,0,4,4H224a4,4,0,0,0,4-4V136a4,4,0,0,0-4-4H176a4,4,0,0,1,0-8h48A12,12,0,0,1,236,136Zm-110.8-5.2a3.9,3.9,0,0,0,5.6,0l48-48a4,4,0,1,0-5.6-5.6L132,118.3V24a4,4,0,0,0-8,0v94.3L82.8,77.2a4,4,0,0,0-5.6,5.6ZM196,168a8,8,0,1,0-8,8A8,8,0,0,0,196,168Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M240,136v64a16,16,0,0,1-16,16H32a16,16,0,0,1-16-16V136a16,16,0,0,1,16-16H80a8,8,0,0,1,0,16H32v64H224V136H176a8,8,0,0,1,0-16h48A16,16,0,0,1,240,136Zm-117.7-2.3a8.2,8.2,0,0,0,11.4,0l48-48a8.1,8.1,0,0,0-11.4-11.4L136,108.7V24a8,8,0,0,0-16,0v84.7L85.7,74.3A8.1,8.1,0,0,0,74.3,85.7ZM200,168a12,12,0,1,0-12,12A12,12,0,0,0,200,168Z" />
        </>
      )}
    </svg>
  );
}

export function PhDribbbleLogo(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M236,128.6V128a108.2,108.2,0,0,0-39.7-83.7l-.6-.5-.9-.6A107.9,107.9,0,0,0,81.3,30.6l-1.5.8a108.3,108.3,0,0,0-57.7,75.4h0A103.9,103.9,0,0,0,20,128a108.2,108.2,0,0,0,43.1,86.3,10.4,10.4,0,0,0,1.7,1.2h.1A108.1,108.1,0,0,0,163.6,230l1.3-.5A108.2,108.2,0,0,0,236,128.9ZM211.2,116H208a168.1,168.1,0,0,0-41.3,5.1A168.4,168.4,0,0,0,156,97.4a170.7,170.7,0,0,0,32.9-27.2A84.7,84.7,0,0,1,211.2,116ZM170,55.3a151.7,151.7,0,0,1-26.9,21.9,173.3,173.3,0,0,0-30.6-31.7A79.1,79.1,0,0,1,128,44,83,83,0,0,1,170,55.3Zm-84.4.3a149.5,149.5,0,0,1,35.9,32.8A147.3,147.3,0,0,1,64,100c-4.9,0-9.9-.3-14.9-.8A84.2,84.2,0,0,1,85.6,55.6ZM44,128c0-1.7.1-3.4.2-5.1A187.7,187.7,0,0,0,64,124a172.3,172.3,0,0,0,70.9-15.2,157,157,0,0,1,8.9,19.6,170.9,170.9,0,0,0-21.5,10.5,172.7,172.7,0,0,0-53.7,48.4A83.7,83.7,0,0,1,44,128Zm43.8,73.7a148.4,148.4,0,0,1,46.5-42,132.7,132.7,0,0,1,16-8,154.4,154.4,0,0,1,2.7,28,145.2,145.2,0,0,1-3,29.4,84,84,0,0,1-22,2.9A83.1,83.1,0,0,1,87.8,201.7Zm88.3-4.9a163.2,163.2,0,0,0,.9-17.1,170.2,170.2,0,0,0-3.8-35.6A151.2,151.2,0,0,1,208,140h3.2A84,84,0,0,1,176.1,196.8Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="128.1" cy="128" r="96" />
          </g>
          <path d="M232.1,128.7V128a103.8,103.8,0,0,0-38.4-80.6l-.6-.5a1.8,1.8,0,0,0-.7-.5A103.8,103.8,0,0,0,83.1,34.2l-1.3.7a104.4,104.4,0,0,0-55.7,72.6v.2H26A104.6,104.6,0,0,0,65.6,211.1l1.3,1h.2a103.7,103.7,0,0,0,61,19.8,101.9,101.9,0,0,0,34.2-5.8l1.2-.4A104.2,104.2,0,0,0,232,128.9C232,128.8,232.1,128.8,232.1,128.7Zm-16.4-8.5-7.6-.2a171.4,171.4,0,0,0-44,5.8A173.1,173.1,0,0,0,150.7,96a171.5,171.5,0,0,0,38.2-31.6A88.1,88.1,0,0,1,215.7,120.2ZM176.3,54.5a150.4,150.4,0,0,1-34.3,28,168.1,168.1,0,0,0-38.6-39,87.9,87.9,0,0,1,72.9,11ZM85.7,50.9a151,151,0,0,1,42,39.2A152.5,152.5,0,0,1,64.1,104a152.7,152.7,0,0,1-20.3-1.4A88.2,88.2,0,0,1,85.7,50.9ZM40.1,128a75.2,75.2,0,0,1,.5-9.6A183.1,183.1,0,0,0,64.1,120a167.4,167.4,0,0,0,72.5-16.4,150.3,150.3,0,0,1,12.3,27.1,172.5,172.5,0,0,0-24.6,11.6,170.5,170.5,0,0,0-55.1,51.1A87.8,87.8,0,0,1,40.1,128Zm42,75a151.9,151.9,0,0,1,50.2-46.8,148.3,148.3,0,0,1,20.9-10,150.3,150.3,0,0,1,3.8,33.5,153.3,153.3,0,0,1-3.5,32.5,85.8,85.8,0,0,1-25.4,3.8A87.8,87.8,0,0,1,82.1,203Zm89,1.7a160,160,0,0,0,1.9-25,166.1,166.1,0,0,0-4.5-38.5,155.4,155.4,0,0,1,39.6-5.2l7.6.2A88.2,88.2,0,0,1,171.1,204.7Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M93.3,36.9a4,4,0,0,1,.8-7.2,103.7,103.7,0,0,1,88.6,9.9,4,4,0,0,1,1.1,5.9,156.1,156.1,0,0,1-41.9,37A169,169,0,0,0,93.3,36.9ZM127.6,90a154.2,154.2,0,0,0-56-46.9,4.3,4.3,0,0,0-4.1.3A105.1,105.1,0,0,0,29.2,95.5a4,4,0,0,0,3,5.1A151.1,151.1,0,0,0,64,104,150.6,150.6,0,0,0,127.6,90Zm103.8,26.7a104,104,0,0,0-29.2-61.5,4.1,4.1,0,0,0-6,.3,168.3,168.3,0,0,1-45.7,40.4,171.5,171.5,0,0,1,13.6,29.9A167.3,167.3,0,0,1,208,120a171.5,171.5,0,0,1,19,1.1A4,4,0,0,0,231.4,116.7Zm-62.9,24.5a169,169,0,0,1,4.4,38.5,168.1,168.1,0,0,1-4.1,36.8,4,4,0,0,0,5.7,4.5,104.1,104.1,0,0,0,56.6-79.2,4,4,0,0,0-3.5-4.5A141.3,141.3,0,0,0,208,136,151.2,151.2,0,0,0,168.5,141.2Zm-19.7-10.4a142.6,142.6,0,0,0-12.4-27.2A167.2,167.2,0,0,1,64,120a167.3,167.3,0,0,1-34.9-3.7,4,4,0,0,0-4.8,3.6q-.3,4-.3,8.1a103.7,103.7,0,0,0,33,75.9,3.9,3.9,0,0,0,6.1-.9A168.8,168.8,0,0,1,148.8,130.8ZM75.7,213.2a4,4,0,0,0,1.5,5.5A102.9,102.9,0,0,0,128,232a98.3,98.3,0,0,0,18.1-1.6,3.8,3.8,0,0,0,3-2.6,151,151,0,0,0,7.8-48.1,154.2,154.2,0,0,0-3.7-33.5A153.2,153.2,0,0,0,75.7,213.2Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M230,128.7V128a101.7,101.7,0,0,0-37.7-79.1l-.5-.5-.5-.4A101.5,101.5,0,0,0,128,26,100.2,100.2,0,0,0,83.9,36.1l-1.2.5A102.5,102.5,0,0,0,28,107.9h0v.2A99.2,99.2,0,0,0,26,128a101.9,101.9,0,0,0,40.9,81.6l1,.8h.2A101.7,101.7,0,0,0,128,230a100.2,100.2,0,0,0,33.9-5.8l.6-.2A102.3,102.3,0,0,0,230,128.9Zm-12.2-6.4c-3.2-.2-6.5-.3-9.8-.3a163.9,163.9,0,0,0-45.2,6.3A175.9,175.9,0,0,0,148.4,96l-.5-.7a168.2,168.2,0,0,0,40.8-33.7A89.7,89.7,0,0,1,217.8,122.3ZM179.3,54.1a152.4,152.4,0,0,1-37.9,31.1A167.9,167.9,0,0,0,98.9,42.8a90.1,90.1,0,0,1,80.4,11.3ZM85.7,48.6a153.9,153.9,0,0,1,45,42.3A154.4,154.4,0,0,1,64,106a158,158,0,0,1-22.8-1.7A90.2,90.2,0,0,1,85.7,48.6ZM38,128a93.9,93.9,0,0,1,.8-11.9A177,177,0,0,0,64,118a165.1,165.1,0,0,0,73.3-17l.7,1.1A157.1,157.1,0,0,1,151.4,132a158.3,158.3,0,0,0-26.1,12.1,165,165,0,0,0-55.7,52.3A89.9,89.9,0,0,1,38,128Zm41.2,75.6a155.8,155.8,0,0,1,75.4-60.1,151.9,151.9,0,0,1,4.3,36.2,149.8,149.8,0,0,1-3.8,34.1,89.8,89.8,0,0,1-75.9-10.2Zm89.3,4.8a172.8,172.8,0,0,0,2.4-28.7,166.9,166.9,0,0,0-4.8-39.9A155.8,155.8,0,0,1,208,134c3.3,0,6.5.1,9.8.3A90.2,90.2,0,0,1,168.5,208.4Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M228,128a99.8,99.8,0,0,0-37.1-77.7l-.3-.3-.4-.3A99.8,99.8,0,0,0,84.5,38l-.7.3A100.3,100.3,0,0,0,30,108.4h-.1A104,104,0,0,0,28,128a99.5,99.5,0,0,0,40.3,80.1l.7.6h.2a99.6,99.6,0,0,0,92,13.5l.6-.2A100.1,100.1,0,0,0,228,128Zm-62.2,83.9a165.8,165.8,0,0,0,3.1-32.2,168.1,168.1,0,0,0-5.2-41.3A157.2,157.2,0,0,1,208,132c4,0,8,.1,11.9.4A92.4,92.4,0,0,1,165.8,211.9Zm54.1-87.5c-3.9-.3-7.9-.4-11.9-.4a165.5,165.5,0,0,0-46.5,6.7A168.7,168.7,0,0,0,146.6,97l-1.5-2.4a166.9,166.9,0,0,0,43.4-35.8A91.5,91.5,0,0,1,219.9,124.4ZM182.3,53.7a155.3,155.3,0,0,1-41.5,34.1A165.8,165.8,0,0,0,94.5,42.3,91.1,91.1,0,0,1,128,36,92.1,92.1,0,0,1,182.3,53.7ZM85.8,46.3a155.8,155.8,0,0,1,47.9,45.3A155.1,155.1,0,0,1,64,108a152.4,152.4,0,0,1-25.3-2.1A92.3,92.3,0,0,1,85.8,46.3ZM36,128a94.9,94.9,0,0,1,1.1-14.2A161.8,161.8,0,0,0,64,116a164.7,164.7,0,0,0,74.1-17.6l1.6,2.7a156.1,156.1,0,0,1,14.2,32.1,156.2,156.2,0,0,0-27.6,12.6A165,165,0,0,0,70,199.4,92,92,0,0,1,36,128Zm40.5,76.2a156.9,156.9,0,0,1,53.8-51.5A161.4,161.4,0,0,1,156,140.9a152.8,152.8,0,0,1,4.9,38.8,158.3,158.3,0,0,1-4.1,35.7,92,92,0,0,1-80.3-11.2Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M232,128.7V128a104.1,104.1,0,0,0-38.3-80.6l-.6-.5-.7-.5A104,104,0,0,0,83,34.2l-1.3.7A104.4,104.4,0,0,0,26,107.5v.2h0A103.7,103.7,0,0,0,65.6,211.1l1.3,1H67a104,104,0,0,0,95.3,14l1.1-.4A104.2,104.2,0,0,0,232,128.9Zm-16.4-8.5L208,120a170.1,170.1,0,0,0-43.9,5.8A166.2,166.2,0,0,0,150.6,96a167.1,167.1,0,0,0,38.2-31.6A87.6,87.6,0,0,1,215.6,120.2ZM176.3,54.5a150.4,150.4,0,0,1-34.3,28,170.5,170.5,0,0,0-38.7-39,88.1,88.1,0,0,1,73,11ZM85.6,50.9a152.8,152.8,0,0,1,42.1,39.2A153.3,153.3,0,0,1,64,104a152.7,152.7,0,0,1-20.3-1.4A88.5,88.5,0,0,1,85.6,50.9ZM40,128c0-3.3.2-6.5.5-9.6A184.3,184.3,0,0,0,64,120a167.4,167.4,0,0,0,72.5-16.4,150.3,150.3,0,0,1,12.3,27.1,177.8,177.8,0,0,0-24.6,11.6,169.1,169.1,0,0,0-55,51.1A87.9,87.9,0,0,1,40,128Zm42,75a151.2,151.2,0,0,1,71.2-56.8,156.3,156.3,0,0,1,3.7,33.5,153.3,153.3,0,0,1-3.5,32.5A85.3,85.3,0,0,1,128,216,88,88,0,0,1,82,203Zm89.1,1.7a173.1,173.1,0,0,0,1.8-25,171.6,171.6,0,0,0-4.4-38.5A154,154,0,0,1,208,136l7.6.2A88.1,88.1,0,0,1,171.1,204.7Z" />
        </>
      )}
    </svg>
  );
}

export function PhDropHalfBottom(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M134.9,6.2a12,12,0,0,0-13.8,0A264.8,264.8,0,0,0,78.9,45.1C50.9,77.4,36,111.6,36,144a92,92,0,0,0,184,0C220,66.6,138.4,8.6,134.9,6.2ZM191.6,168H64.4a65.7,65.7,0,0,1-3.9-16h135A65.7,65.7,0,0,1,191.6,168ZM96.7,61.3A247.8,247.8,0,0,1,128,31.1a247.8,247.8,0,0,1,31.3,30.2c14,16.2,30.3,39.9,35.2,66.7H61.5C66.4,101.2,82.7,77.5,96.7,61.3ZM128,212a67.9,67.9,0,0,1-48.1-20h96.2A67.9,67.9,0,0,1,128,212Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M208,144a80,80,0,0,1-160,0,105.7,105.7,0,0,1,1.3-16H206.7A105.7,105.7,0,0,1,208,144Z"
            opacity="0.2"
          />
          <path d="M174,47.8A259.4,259.4,0,0,0,132.6,9.4a8.1,8.1,0,0,0-9.2,0A259.4,259.4,0,0,0,82,47.8C54.5,79.3,40,112.6,40,144a88,88,0,0,0,176,0C216,112.6,201.5,79.3,174,47.8ZM128,26c14.2,11.1,56.9,47.8,68.8,94H59.2C71.1,73.8,113.8,37.1,128,26Zm0,190a72.1,72.1,0,0,1-72-72,70.3,70.3,0,0,1,.4-8H199.6a70.3,70.3,0,0,1,.4,8A72.1,72.1,0,0,1,128,216Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M174,47.8A259.4,259.4,0,0,0,132.6,9.4a8.1,8.1,0,0,0-9.2,0A259.4,259.4,0,0,0,82,47.8C54.5,79.3,40,112.6,40,144a88,88,0,0,0,176,0C216,112.6,201.5,79.3,174,47.8ZM128,26c14.2,11.1,56.9,47.8,68.8,94H59.2C71.1,73.8,113.8,37.1,128,26Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M172.5,49.1a247.8,247.8,0,0,0-41.1-38,5.7,5.7,0,0,0-6.8,0,247.8,247.8,0,0,0-41.1,38C56.3,80.3,42,113.1,42,144a86,86,0,0,0,172,0C214,113.1,199.7,80.3,172.5,49.1ZM128,23.5c13.1,10.1,59.8,49,71.4,98.5H56.6C68.2,72.5,114.9,33.6,128,23.5ZM202,144a66.3,66.3,0,0,1-.7,10H54.7a77.3,77.3,0,0,1-.1-20H201.4A82.6,82.6,0,0,1,202,144ZM67.1,186a75.9,75.9,0,0,1-9.8-20H198.7a75.9,75.9,0,0,1-9.8,20Zm10.4,12h101a73.8,73.8,0,0,1-101,0Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M171,50.4a255.4,255.4,0,0,0-40.7-37.7,4.3,4.3,0,0,0-4.6,0A255.4,255.4,0,0,0,85,50.4C58.2,81.2,44,113.6,44,144a84,84,0,0,0,168,0C212,113.6,197.8,81.2,171,50.4ZM90.9,55.8A247.5,247.5,0,0,1,128,21a247.5,247.5,0,0,1,37.1,34.8c14.4,16.5,31,40.6,36.8,68.2H54.1C59.9,96.4,76.5,72.3,90.9,55.8ZM204,144a67.8,67.8,0,0,1-1,12H53a67.8,67.8,0,0,1-1-12,94.2,94.2,0,0,1,.8-12H203.2A94.2,94.2,0,0,1,204,144ZM54.7,164H201.3a76.9,76.9,0,0,1-11.4,24H66.1A76.9,76.9,0,0,1,54.7,164ZM128,220a75.8,75.8,0,0,1-55.4-24H183.4A75.8,75.8,0,0,1,128,220Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M174,47.8A259.4,259.4,0,0,0,132.6,9.4a8.1,8.1,0,0,0-9.2,0A259.4,259.4,0,0,0,82,47.8C54.5,79.3,40,112.6,40,144a88,88,0,0,0,176,0C216,112.6,201.5,79.3,174,47.8ZM128,26c14.2,11.1,56.9,47.8,68.8,94H59.2C71.1,73.8,113.8,37.1,128,26Zm72,118a70.1,70.1,0,0,1-.5,8H56.5a71.3,71.3,0,0,1-.1-16H199.6A70.3,70.3,0,0,1,200,144ZM68.2,184a70.8,70.8,0,0,1-8.1-16H195.9a70.8,70.8,0,0,1-8.1,16Zm14.6,16h90.4a71.8,71.8,0,0,1-90.4,0Z" />
        </>
      )}
    </svg>
  );
}

export function PhDropHalf(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M134.9,6.2a12,12,0,0,0-13.8,0A264.8,264.8,0,0,0,78.9,45.1C50.9,77.4,36,111.6,36,144a92,92,0,0,0,184,0C220,66.6,138.4,8.6,134.9,6.2ZM196,144a70.1,70.1,0,0,1-1.9,16H140V144Zm-56-24V104h47a108.5,108.5,0,0,1,5.7,16Zm19.3-58.7A190.1,190.1,0,0,1,173.7,80H140V41.5A246.1,246.1,0,0,1,159.3,61.3ZM60,144c0-33.3,20-63.4,36.7-82.7A246.1,246.1,0,0,1,116,41.5V210.9A68.1,68.1,0,0,1,60,144Zm80,66.9V184h42.9A67.5,67.5,0,0,1,140,210.9Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M128,224a80,80,0,0,0,80-80c0-72-80-128-80-128Z" />
          </g>
          <path d="M174,47.8A259.4,259.4,0,0,0,132.6,9.4h-.1l-.9-.5h-.2a6.5,6.5,0,0,0-2.1-.7h-2.6a6.5,6.5,0,0,0-2.1.7h-.2l-.9.5h-.1A259.4,259.4,0,0,0,82,47.8C54.5,79.3,40,112.6,40,144a88,88,0,0,0,176,0C216,112.6,201.5,79.3,174,47.8ZM56,144c0-50,42.3-92.7,64-111.4V215.5A72,72,0,0,1,56,144Zm80,71.5V32.6C157.7,51.3,200,94,200,144A72,72,0,0,1,136,215.5Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M174,47.8A259.4,259.4,0,0,0,132.6,9.4a8.1,8.1,0,0,0-9.2,0A259.4,259.4,0,0,0,82,47.8C54.5,79.3,40,112.6,40,144a88,88,0,0,0,176,0C216,112.6,201.5,79.3,174,47.8ZM128,216a72.1,72.1,0,0,1-72-72c0-57.2,55.5-105,72-118Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M172.5,49.1a247.8,247.8,0,0,0-41.1-38,5.7,5.7,0,0,0-6.8,0,247.8,247.8,0,0,0-41.1,38C56.3,80.3,42,113.1,42,144a86,86,0,0,0,172,0C214,113.1,199.7,80.3,172.5,49.1ZM202,144a66.3,66.3,0,0,1-.7,10H134V134h67.4A82.6,82.6,0,0,1,202,144ZM186.8,90H134V70h39.9A175.6,175.6,0,0,1,186.8,90ZM134,198h44.5A73.1,73.1,0,0,1,134,217.7Zm0-12V166h64.7a75.9,75.9,0,0,1-9.8,20Zm0-64V102h58.7a114.6,114.6,0,0,1,6.7,20Zm30.3-64H134V28.3A261,261,0,0,1,164.3,58ZM54,144c0-53.4,47.3-98.6,68-115.7V217.7A74,74,0,0,1,54,144Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M171,50.4a255.4,255.4,0,0,0-40.7-37.7,4.3,4.3,0,0,0-4.6,0A255.4,255.4,0,0,0,85,50.4C58.2,81.2,44,113.6,44,144a84,84,0,0,0,168,0C212,113.6,197.8,81.2,171,50.4ZM204,144a67.8,67.8,0,0,1-1,12H132V132h71.2A94.2,94.2,0,0,1,204,144ZM190.1,92H132V68h43A175.4,175.4,0,0,1,190.1,92ZM132,164h69.3a76.9,76.9,0,0,1-11.4,24H132Zm0-40V100h62a120.6,120.6,0,0,1,7.9,24Zm33.1-68.2q1.8,2,3.6,4.2H132V24.1A253.5,253.5,0,0,1,165.1,55.8ZM52,144c0-35.9,21.2-67.8,38.9-88.2A253.5,253.5,0,0,1,124,24.1V219.9A76.1,76.1,0,0,1,52,144Zm80,75.9V196h51.4A76.5,76.5,0,0,1,132,219.9Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M174,47.8A259.4,259.4,0,0,0,132.6,9.4a8.1,8.1,0,0,0-9.2,0A259.4,259.4,0,0,0,82,47.8C54.5,79.3,40,112.6,40,144a88,88,0,0,0,176,0C216,112.6,201.5,79.3,174,47.8ZM200,144a70.1,70.1,0,0,1-.5,8H136V136h63.6A70.3,70.3,0,0,1,200,144ZM183.4,88H136V72h36.9A184.1,184.1,0,0,1,183.4,88ZM136,200h37.2A71.3,71.3,0,0,1,136,215.5Zm0-16V168h59.9a70.8,70.8,0,0,1-8.1,16Zm0-64V104h55.4a111.5,111.5,0,0,1,5.4,16Zm23.9-64H136V32.6A266.2,266.2,0,0,1,159.9,56ZM56,144c0-50,42.3-92.7,64-111.4V215.5A72,72,0,0,1,56,144Z" />
        </>
      )}
    </svg>
  );
}

export function PhDrop(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M134.9,6.2a12,12,0,0,0-13.8,0A264.8,264.8,0,0,0,78.9,45.1C50.9,77.4,36,111.6,36,144a92,92,0,0,0,184,0C220,66.6,138.4,8.6,134.9,6.2ZM128,212a68.1,68.1,0,0,1-68-68c0-33.3,20-63.4,36.7-82.7A247.8,247.8,0,0,1,128,31.1a247.8,247.8,0,0,1,31.3,30.2C176,80.6,196,110.7,196,144A68.1,68.1,0,0,1,128,212Zm46.8-57.3a48.2,48.2,0,0,1-36.1,36.1l-2.7.3a12,12,0,0,1-2.7-23.7,23.9,23.9,0,0,0,18.1-18.1,12,12,0,1,1,23.4,5.4Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M128,224a80,80,0,0,1-80-80c0-72,80-128,80-128s80,56,80,128A80,80,0,0,1,128,224Z"
            opacity="0.2"
          />
          <path d="M174,47.8A259.4,259.4,0,0,0,132.6,9.4a8.1,8.1,0,0,0-9.2,0A259.4,259.4,0,0,0,82,47.8C54.5,79.3,40,112.6,40,144a88,88,0,0,0,176,0C216,112.6,201.5,79.3,174,47.8ZM128,216a72.1,72.1,0,0,1-72-72c0-57.2,55.5-105,72-118,16.5,13,72,60.8,72,118A72.1,72.1,0,0,1,128,216Zm55.2-62.6a55.8,55.8,0,0,1-45.8,45.7h-1.3a8,8,0,0,1-1.3-15.9,39.7,39.7,0,0,0,32.6-32.6,8,8,0,0,1,9.3-6.5A7.9,7.9,0,0,1,183.2,153.4Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M174,47.8A259.4,259.4,0,0,0,132.6,9.4a8.1,8.1,0,0,0-9.2,0A259.4,259.4,0,0,0,82,47.8C54.5,79.3,40,112.6,40,144a88,88,0,0,0,176,0C216,112.6,201.5,79.3,174,47.8Zm9.2,105.6a55.8,55.8,0,0,1-45.8,45.7h-1.3a8,8,0,0,1-1.3-15.9,39.7,39.7,0,0,0,32.6-32.6,8,8,0,0,1,9.3-6.5A7.9,7.9,0,0,1,183.2,153.4Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M172.5,49.1a247.8,247.8,0,0,0-41.1-38,5.7,5.7,0,0,0-6.8,0,247.8,247.8,0,0,0-41.1,38C56.3,80.3,42,113.1,42,144a86,86,0,0,0,172,0C214,113.1,199.7,80.3,172.5,49.1ZM128,218a74.1,74.1,0,0,1-74-74c0-59.6,59-108.9,74-120.5,15,11.6,74,60.9,74,120.5A74.1,74.1,0,0,1,128,218Zm53.2-64.9a53.8,53.8,0,0,1-44.1,44h-1a6,6,0,0,1-1-11.9,41.9,41.9,0,0,0,34.3-34.2,6.1,6.1,0,0,1,6.9-5A6,6,0,0,1,181.2,153.1Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M171,50.4a255.4,255.4,0,0,0-40.7-37.7,4.3,4.3,0,0,0-4.6,0A255.4,255.4,0,0,0,85,50.4C58.2,81.2,44,113.6,44,144a84,84,0,0,0,168,0C212,113.6,197.8,81.2,171,50.4ZM128,220a76.1,76.1,0,0,1-76-76c0-35.9,21.2-67.8,38.9-88.2A247.5,247.5,0,0,1,128,21a247.5,247.5,0,0,1,37.1,34.8C182.8,76.2,204,108.1,204,144A76.1,76.1,0,0,1,128,220Zm51.3-67.3a52,52,0,0,1-42.5,42.5h-.7a4,4,0,0,1-3.9-3.3,3.9,3.9,0,0,1,3.3-4.6,43.9,43.9,0,0,0,35.9-35.9,4,4,0,0,1,4.6-3.3A4,4,0,0,1,179.3,152.7Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M174,47.8A259.4,259.4,0,0,0,132.6,9.4a8.1,8.1,0,0,0-9.2,0A259.4,259.4,0,0,0,82,47.8C54.5,79.3,40,112.6,40,144a88,88,0,0,0,176,0C216,112.6,201.5,79.3,174,47.8ZM128,216a72.1,72.1,0,0,1-72-72c0-57.2,55.5-105,72-118,16.5,13,72,60.8,72,118A72.1,72.1,0,0,1,128,216Zm55.2-62.6a55.8,55.8,0,0,1-45.8,45.7h-1.3a8,8,0,0,1-1.3-15.9,39.7,39.7,0,0,0,32.6-32.6,8,8,0,0,1,9.3-6.5A7.9,7.9,0,0,1,183.2,153.4Z" />
        </>
      )}
    </svg>
  );
}

export function PhEarSlash(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216.9,207.9a12.1,12.1,0,0,1-.8,17,12.1,12.1,0,0,1-17-.8l-36.5-40.2H160a32.1,32.1,0,0,1-32-32,35.6,35.6,0,0,1,.4-5.7L97.1,111.8A11.9,11.9,0,0,1,88,116a12,12,0,0,1-12-12,49.8,49.8,0,0,1,1.8-13.4L65.5,77.1A68.1,68.1,0,0,0,60,104c0,25,7.6,32.3,16.3,40.8S96,163.7,96,188a32,32,0,0,0,56.7,20.4,12,12,0,0,1,18.5,15.2A56,56,0,0,1,72,188c0-14.1-4.6-18.6-12.3-26C49.1,151.9,36,139.2,36,104A90.7,90.7,0,0,1,48.3,58.1l-9.2-10A12,12,0,0,1,56.9,31.9L72.2,48.8h.1l27.9,30.7h0l75.4,83h.1ZM99.3,42.3A68.1,68.1,0,0,1,196,104a12,12,0,0,0,24,0,92.1,92.1,0,0,0-92-92,91.1,91.1,0,0,0-38.8,8.6A12,12,0,0,0,99.3,42.3Zm64.2,80.6a14.1,14.1,0,0,0,3.5.5,12.2,12.2,0,0,0,11.5-8.6A38.1,38.1,0,0,0,180,104a52,52,0,0,0-52-52l-5.1.2a12.2,12.2,0,0,0-10.8,13.2,11.9,11.9,0,0,0,13.1,10.7H128a28.1,28.1,0,0,1,28,28,16.2,16.2,0,0,1-.5,4A12,12,0,0,0,163.5,122.9Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M161.9,216A44,44,0,0,1,84,188c0-41.5-36-28-36-84a80,80,0,0,1,160,0C208,144,209,169,161.9,216Z"
            opacity="0.2"
          />
          <path d="M213.9,210.6a7.9,7.9,0,0,1-.5,11.3A8.2,8.2,0,0,1,208,224a8,8,0,0,1-5.9-2.6l-37.9-41.7a29.7,29.7,0,0,1-4.2.3,28.1,28.1,0,0,1-28-28,31.1,31.1,0,0,1,.7-6.9L96,104.7a8,8,0,0,1-16-.7,50.4,50.4,0,0,1,2.2-14.5L64.5,70A71.4,71.4,0,0,0,56,104c0,26.7,8.5,34.9,17.6,43.6S92,165.4,92,188a36,36,0,0,0,63.8,22.9,7.9,7.9,0,0,1,11.2-1.1,8.1,8.1,0,0,1,1.1,11.3A52,52,0,0,1,76,188c0-15.8-5.7-21.3-13.5-28.8C52.5,149.5,40,137.5,40,104A87.8,87.8,0,0,1,53.2,57.6L42.1,45.4A8,8,0,0,1,53.9,34.6L69.2,51.5h.1l28,30.8h0l75.4,83h0ZM91.1,42.2A72,72,0,0,1,200,104a8,8,0,0,0,16,0A88,88,0,0,0,82.9,28.4a8,8,0,0,0-2.8,11A8.1,8.1,0,0,0,91.1,42.2Zm67.2,83.2a7.7,7.7,0,0,0,4.8,1.6,8,8,0,0,0,6.4-3.2A31.6,31.6,0,0,0,176,104a48,48,0,0,0-48-48,48.6,48.6,0,0,0-15.6,2.6,8,8,0,0,0,5.2,15.1A32.9,32.9,0,0,1,128,72a32.1,32.1,0,0,1,32,32,16,16,0,0,1-3.3,10.2A7.9,7.9,0,0,0,158.3,125.4Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M80.1,39.4a8,8,0,0,1,2.8-11A88,88,0,0,1,216,104a8,8,0,0,1-16,0A72,72,0,0,0,91.1,42.2,8.1,8.1,0,0,1,80.1,39.4Zm78.2,86a7.7,7.7,0,0,0,4.8,1.6,8,8,0,0,0,6.4-3.2A31.6,31.6,0,0,0,176,104a48,48,0,0,0-48-48,48.6,48.6,0,0,0-15.6,2.6,8,8,0,0,0,5.2,15.1A32.9,32.9,0,0,1,128,72a32.1,32.1,0,0,1,32,32,16,16,0,0,1-3.3,10.2A7.9,7.9,0,0,0,158.3,125.4Zm14.4,39.9h0l-75.4-83h0l-28-30.8h-.1L53.9,34.6A8,8,0,0,0,42.1,45.4L53.2,57.6A87.8,87.8,0,0,0,40,104c0,33.5,12.5,45.5,22.5,55.2,7.8,7.5,13.5,13,13.5,28.8a52,52,0,0,0,92.1,33.1,8.1,8.1,0,0,0-1.1-11.3,7.9,7.9,0,0,0-11.2,1.1A36,36,0,0,1,92,188c0-22.6-9.8-32-18.4-40.4S56,130.7,56,104a71.4,71.4,0,0,1,8.5-34L82.2,89.5A50.4,50.4,0,0,0,80,104a8,8,0,0,0,16,.7l36.7,40.4a31.1,31.1,0,0,0-.7,6.9,28.1,28.1,0,0,0,28,28,29.7,29.7,0,0,0,4.2-.3l37.9,41.7A8,8,0,0,0,208,224a8.2,8.2,0,0,0,5.4-2.1,7.9,7.9,0,0,0,.5-11.3Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M171.3,166.7h0l-75.5-83h0l-28-30.8h0L52.4,36a5.9,5.9,0,0,0-8.8,8L55.7,57.4A85.2,85.2,0,0,0,42,104c0,32.6,12.1,44.3,21.9,53.7C71.8,165.3,78,171.4,78,188a50,50,0,0,0,88.6,31.8,6,6,0,0,0-9.3-7.6A38,38,0,0,1,90,188c0-21.7-9.1-30.5-17.8-38.9S54,131.5,54,104A73.9,73.9,0,0,1,64.1,66.6L84.5,89.1A44.4,44.4,0,0,0,82,104a6,6,0,0,0,12,0,29.7,29.7,0,0,1,.3-4.2l40.6,44.7a30.8,30.8,0,0,0-.9,7.5,25.8,25.8,0,0,0,30.9,25.5L203.6,220a5.9,5.9,0,0,0,4.4,2,5.7,5.7,0,0,0,4-1.6,5.8,5.8,0,0,0,.4-8.4Zm-23.7-8.2,4.8,5.2A14.1,14.1,0,0,1,147.6,158.5ZM81.8,38.4a6,6,0,0,1,2.1-8.2A86,86,0,0,1,214,104a6,6,0,0,1-12,0,74.1,74.1,0,0,0-74-74A73.1,73.1,0,0,0,90.1,40.5,6.1,6.1,0,0,1,81.8,38.4Zm76.5,77A17.5,17.5,0,0,0,162,104a34.1,34.1,0,0,0-45-32.2,6,6,0,1,1-3.9-11.3A44.4,44.4,0,0,1,128,58a46,46,0,0,1,46,46,30.1,30.1,0,0,1-6.1,18.6,6,6,0,1,1-9.6-7.2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M169.8,168.1h0L94.4,85h-.1l-28-30.8h0L51,37.3a4,4,0,1,0-6,5.4L58.2,57.2A83.7,83.7,0,0,0,44,104c0,31.8,11.3,42.7,21.2,52.3,8,7.6,14.8,14.2,14.8,31.7a48,48,0,0,0,85,30.6,4.1,4.1,0,0,0-.5-5.7,4,4,0,0,0-5.6.6A40.1,40.1,0,0,1,88,188c0-20.9-8.7-29.3-17.2-37.5S52,132.4,52,104A75.7,75.7,0,0,1,63.8,63.3l23,25.3A42.4,42.4,0,0,0,84,104a4,4,0,0,0,8,0,39.5,39.5,0,0,1,1-8.5l44.1,48.4A26.9,26.9,0,0,0,136,152a24.1,24.1,0,0,0,24,24,21,21,0,0,0,5.6-.7L205,218.7a4.1,4.1,0,0,0,5.7.3,4.2,4.2,0,0,0,.3-5.7ZM144,152v-.4l14.9,16.3A15.9,15.9,0,0,1,144,152ZM83.5,37.4a4,4,0,0,1,1.4-5.5A84,84,0,0,1,212,104a4,4,0,0,1-8,0A76.1,76.1,0,0,0,89,38.7,3.9,3.9,0,0,1,83.5,37.4Zm76.4,79.2A19.9,19.9,0,0,0,164,104a36,36,0,0,0-36-36,38.7,38.7,0,0,0-11.7,1.9,4,4,0,1,1-2.6-7.5A43.4,43.4,0,0,1,128,60a44,44,0,0,1,44,44,28.2,28.2,0,0,1-5.7,17.4,3.9,3.9,0,0,1-3.2,1.6,4.1,4.1,0,0,1-2.4-.8A3.9,3.9,0,0,1,159.9,116.6Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M213.9,210.6a7.9,7.9,0,0,1-.5,11.3A8.2,8.2,0,0,1,208,224a8,8,0,0,1-5.9-2.6l-37.9-41.7a29.7,29.7,0,0,1-4.2.3,28.1,28.1,0,0,1-28-28,31.1,31.1,0,0,1,.7-6.9L96,104.7a8,8,0,0,1-16-.7,50.4,50.4,0,0,1,2.2-14.5L64.5,70A71.4,71.4,0,0,0,56,104c0,26.7,8.5,34.9,17.6,43.6S92,165.4,92,188a36,36,0,0,0,63.8,22.9,7.9,7.9,0,0,1,11.2-1.1,8.1,8.1,0,0,1,1.1,11.3A52,52,0,0,1,76,188c0-15.8-5.7-21.3-13.5-28.8C52.5,149.5,40,137.5,40,104A87.8,87.8,0,0,1,53.2,57.6L42.1,45.4A8,8,0,0,1,53.9,34.6L69.2,51.5h.1l28,30.8h0l75.4,83h0ZM91.1,42.2A72,72,0,0,1,200,104a8,8,0,0,0,16,0A88,88,0,0,0,82.9,28.4a8,8,0,0,0-2.8,11A8.1,8.1,0,0,0,91.1,42.2Zm67.2,83.2a7.7,7.7,0,0,0,4.8,1.6,8,8,0,0,0,6.4-3.2A31.6,31.6,0,0,0,176,104a48,48,0,0,0-48-48,48.6,48.6,0,0,0-15.6,2.6,8,8,0,0,0,5.2,15.1A32.9,32.9,0,0,1,128,72a32.1,32.1,0,0,1,32,32,16,16,0,0,1-3.3,10.2A7.9,7.9,0,0,0,158.3,125.4Z" />
        </>
      )}
    </svg>
  );
}

export function PhEar(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M220,104a12,12,0,0,1-24,0,68,68,0,0,0-136,0c0,25,7.6,32.3,16.3,40.8S96,163.7,96,188a32,32,0,0,0,56.7,20.4,12,12,0,0,1,18.5,15.2A56,56,0,0,1,72,188c0-14.1-4.6-18.6-12.3-26C49.1,151.9,36,139.2,36,104a92,92,0,0,1,184,0Zm-36.9,45a12.1,12.1,0,0,0-15.8,6.2A7.9,7.9,0,0,1,160,160a8,8,0,0,1-8-8c0-6.2,3-9.1,9.8-14.9S180,121.5,180,104a52,52,0,0,0-104,0,12,12,0,0,0,24,0,28,28,0,0,1,56,0c0,6.2-3,9.1-9.8,14.9S128,134.5,128,152a32,32,0,0,0,61.3,12.8A11.9,11.9,0,0,0,183.1,149Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M161.9,216A44,44,0,0,1,84,188c0-41.5-36-28-36-84a80,80,0,0,1,160,0C208,144,209,169,161.9,216Z"
            opacity="0.2"
          />
          <path d="M188,152a28,28,0,0,1-56,0c0-15.7,9.3-23.7,16.8-30.1S160,111.9,160,104a32,32,0,0,0-64,0,8,8,0,0,1-16,0,48,48,0,0,1,96,0c0,15.7-9.3,23.7-16.8,30.1S148,144.1,148,152a12,12,0,0,0,24,0,8,8,0,0,1,16,0ZM128,16a88.1,88.1,0,0,0-88,88c0,33.5,12.5,45.5,22.5,55.2,7.8,7.5,13.5,13,13.5,28.8a52,52,0,0,0,92.1,33.1,8.1,8.1,0,0,0-1.1-11.3,7.9,7.9,0,0,0-11.2,1.1A36,36,0,0,1,92,188c0-22.6-9.8-32-18.4-40.4S56,130.7,56,104a72,72,0,0,1,144,0,8,8,0,0,0,16,0A88.1,88.1,0,0,0,128,16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M188,152a28,28,0,0,1-56,0c0-15.7,9.3-23.7,16.8-30.1S160,111.9,160,104a32,32,0,0,0-64,0,8,8,0,0,1-16,0,48,48,0,0,1,96,0c0,15.7-9.3,23.7-16.8,30.1S148,144.1,148,152a12,12,0,0,0,24,0,8,8,0,0,1,16,0ZM128,16a88.1,88.1,0,0,0-88,88c0,33.5,12.5,45.5,22.5,55.2,7.8,7.5,13.5,13,13.5,28.8a52,52,0,0,0,92.1,33.1,8.1,8.1,0,0,0-1.1-11.3,7.9,7.9,0,0,0-11.2,1.1A36,36,0,0,1,92,188c0-22.6-9.8-32-18.4-40.4S56,130.7,56,104a72,72,0,0,1,144,0,8,8,0,0,0,16,0A88.1,88.1,0,0,0,128,16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M186,152a26,26,0,0,1-52,0c0-14.8,8.9-22.4,16.1-28.6S162,113.2,162,104a34,34,0,0,0-68,0,6,6,0,0,1-12,0,46,46,0,0,1,92,0c0,14.8-8.9,22.4-16.1,28.6S146,142.8,146,152a14,14,0,0,0,28,0,6,6,0,0,1,12,0ZM128,18a86.1,86.1,0,0,0-86,86c0,32.6,12.1,44.3,21.9,53.7C71.8,165.3,78,171.4,78,188a50,50,0,0,0,88.6,31.8,6,6,0,0,0-9.3-7.6A38,38,0,0,1,90,188c0-9.7-1.9-17.7-5.7-24.5s-7.8-10.3-12.1-14.4C62.8,140.1,54,131.5,54,104a74,74,0,0,1,148,0,6,6,0,0,0,12,0A86.1,86.1,0,0,0,128,18Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M184,152a24,24,0,0,1-48,0c0-13.8,8.2-20.9,15.4-27S164,114.2,164,104a36,36,0,0,0-72,0,4,4,0,0,1-8,0,44,44,0,0,1,88,0c0,13.8-8.2,20.9-15.4,27S144,141.8,144,152a16,16,0,0,0,32,0,4,4,0,0,1,8,0ZM128,20a84.1,84.1,0,0,0-84,84c0,31.8,11.3,42.7,21.2,52.3,8,7.6,14.8,14.2,14.8,31.7a48,48,0,0,0,85,30.6,4.1,4.1,0,0,0-.5-5.7,4,4,0,0,0-5.6.6A40.1,40.1,0,0,1,88,188c0-20.9-8.7-29.3-17.2-37.5S52,132.4,52,104a76,76,0,0,1,152,0,4,4,0,0,0,8,0A84.1,84.1,0,0,0,128,20Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M188,152a28,28,0,0,1-56,0c0-15.7,9.3-23.7,16.8-30.1S160,111.9,160,104a32,32,0,0,0-64,0,8,8,0,0,1-16,0,48,48,0,0,1,96,0c0,15.7-9.3,23.7-16.8,30.1S148,144.1,148,152a12,12,0,0,0,24,0,8,8,0,0,1,16,0ZM128,16a88.1,88.1,0,0,0-88,88c0,33.5,12.5,45.5,22.5,55.2,7.8,7.5,13.5,13,13.5,28.8a52,52,0,0,0,92.1,33.1,8.1,8.1,0,0,0-1.1-11.3,7.9,7.9,0,0,0-11.2,1.1A36,36,0,0,1,92,188c0-22.6-9.8-32-18.4-40.4S56,130.7,56,104a72,72,0,0,1,144,0,8,8,0,0,0,16,0A88.1,88.1,0,0,0,128,16Z" />
        </>
      )}
    </svg>
  );
}

export function PhEggCrack(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M190,57.3C171.1,29,147.9,12,128,12S84.9,29,66,57.3,36,120.5,36,152a92,92,0,0,0,184,0C220,120.5,209.1,86,190,57.3ZM128,220a68.1,68.1,0,0,1-68-68c0-61.1,46.2-116,68-116,7.6,0,18.1,6.6,28.6,17.9L120.9,93.7a11.9,11.9,0,0,0-2.8,10.8,12,12,0,0,0,7.4,8.4l19.4,7.5-5.3,27.3a12.1,12.1,0,0,0,9.5,14.1l2.3.2a12.1,12.1,0,0,0,11.8-9.7l7.2-37.2a12,12,0,0,0-7.5-13.4l-12.6-4.9,21.2-23.6C185.2,94.3,196,122.4,196,152A68.1,68.1,0,0,1,128,220Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M208,152a80,80,0,0,1-160,0C48,88,96,24,128,24S208,88,208,152Z"
            opacity="0.2"
          />
          <path d="M186.7,59.6C168.5,32.3,146.5,16,128,16S87.5,32.3,69.3,59.6,40,121.2,40,152a88,88,0,0,0,176,0C216,121.2,205.3,87.5,186.7,59.6ZM128,224a72.1,72.1,0,0,1-72-72c0-27.7,9.7-58.2,26.7-83.6C97.2,46.6,115.4,32,128,32c9.5,0,22.2,8.3,34.1,21.8L123.8,96.4a8.1,8.1,0,0,0-1.8,7.2,8.3,8.3,0,0,0,4.9,5.6l22.6,8.7-6,30.6a8.1,8.1,0,0,0,6.4,9.4h1.5a8.1,8.1,0,0,0,7.9-6.5l7.2-37.1a8.1,8.1,0,0,0-5-9l-18-7,28.6-31.8,1.2,1.8c17,25.4,26.7,55.9,26.7,83.6A72.1,72.1,0,0,1,128,224Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,152a88.1,88.1,0,0,1-87.8,88c-50.6.1-90.2-43-88.1-93.6,1.2-29.2,11.7-60.6,29.2-86.8S109.5,16,128,16c13.3,0,28.2,8.3,42.3,23a4,4,0,0,1,.1,5.5L123.8,96.4a8.1,8.1,0,0,0-1.8,7.2,8.3,8.3,0,0,0,4.9,5.6l22.6,8.7-5.9,30.3a8.4,8.4,0,0,0,6.3,9.7h1.5a8.1,8.1,0,0,0,7.9-6.5l7.2-37.1a8.1,8.1,0,0,0-5-9l-18-7,36.2-40.3a4.1,4.1,0,0,1,6.3.4l.7,1.1C205.3,87.5,216,121.2,216,152Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M185,60.7C167.2,34,145.9,18,128,18S88.8,34,71,60.7,42,121.6,42,152a86,86,0,0,0,172,0C214,121.6,203.4,88.3,185,60.7ZM128,226a74.1,74.1,0,0,1-74-74c0-28.1,9.8-58.9,27-84.7C96.1,44.7,114.6,30,128,30c10.5,0,24.1,9,36.8,23.8L125.3,97.7a6.1,6.1,0,0,0,2.3,9.6l24.2,9.3-6.3,32.3a6,6,0,0,0,4.8,7h1.1a6.1,6.1,0,0,0,5.9-4.9l7.2-37.1a5.9,5.9,0,0,0-3.7-6.7l-20.7-8,32.2-35.9,2.7,3.9c17.2,25.8,27,56.6,27,84.7A74.1,74.1,0,0,1,128,226Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,20C92.9,20,44,86.5,44,152a84,84,0,0,0,168,0C212,86.5,163.1,20,128,20Zm0,208a76.1,76.1,0,0,1-76-76c0-28.5,10-59.7,27.3-85.8C94.8,43,113.9,28,128,28c11.4,0,26,9.8,39.4,25.8L126.8,99a4,4,0,0,0-.9,3.6,3.7,3.7,0,0,0,2.5,2.8l25.6,10-6.5,33.8a4,4,0,0,0,3.1,4.7h.8a3.9,3.9,0,0,0,3.9-3.2l7.2-37.2a4,4,0,0,0-2.5-4.5l-23.4-9,35.8-39.9c1.5,1.9,2.9,3.9,4.3,6C194,92.3,204,123.5,204,152A76.1,76.1,0,0,1,128,228Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M186.7,59.6C168.5,32.3,146.5,16,128,16S87.5,32.3,69.3,59.6,40,121.2,40,152a88,88,0,0,0,176,0C216,121.2,205.3,87.5,186.7,59.6ZM128,224a72.1,72.1,0,0,1-72-72c0-27.7,9.7-58.2,26.7-83.6C97.2,46.6,115.4,32,128,32c9.5,0,22.2,8.3,34.1,21.8L123.8,96.4a8.1,8.1,0,0,0-1.8,7.2,8.3,8.3,0,0,0,4.9,5.6l22.6,8.7-6,30.6a8.1,8.1,0,0,0,6.4,9.4h1.5a8.1,8.1,0,0,0,7.9-6.5l7.2-37.1a8.1,8.1,0,0,0-5-9l-18-7,28.6-31.8,1.2,1.8c17,25.4,26.7,55.9,26.7,83.6A72.1,72.1,0,0,1,128,224Z" />
        </>
      )}
    </svg>
  );
}

export function PhEgg(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M190,57.3C171.1,29,147.9,12,128,12S84.9,29,66,57.3,36,120.5,36,152a92,92,0,0,0,184,0C220,120.5,209.1,86,190,57.3ZM128,220a68.1,68.1,0,0,1-68-68c0-61.1,46.2-116,68-116s68,54.9,68,116A68.1,68.1,0,0,1,128,220Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M208,152a80,80,0,0,1-160,0C48,88,96,24,128,24S208,88,208,152Z"
            opacity="0.2"
          />
          <path d="M186.7,59.6C168.5,32.3,146.5,16,128,16S87.5,32.3,69.3,59.6,40,121.2,40,152a88,88,0,0,0,176,0C216,121.2,205.3,87.5,186.7,59.6ZM128,224a72.1,72.1,0,0,1-72-72c0-27.7,9.7-58.2,26.7-83.6C97.2,46.6,115.4,32,128,32s30.8,14.6,45.3,36.4c17,25.4,26.7,55.9,26.7,83.6A72.1,72.1,0,0,1,128,224Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,152a88,88,0,0,1-176,0c0-30.8,10.7-64.5,29.3-92.4S109.5,16,128,16s40.5,16.3,58.7,43.6S216,121.2,216,152Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M185,60.7C167.2,34,145.9,18,128,18S88.8,34,71,60.7,42,121.6,42,152a86,86,0,0,0,172,0C214,121.6,203.4,88.3,185,60.7ZM128,226a74.1,74.1,0,0,1-74-74c0-28.1,9.8-58.9,27-84.7C96.1,44.7,114.6,30,128,30s31.9,14.7,47,37.3c17.2,25.8,27,56.6,27,84.7A74.1,74.1,0,0,1,128,226Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,20C92.9,20,44,86.5,44,152a84,84,0,0,0,168,0C212,86.5,163.1,20,128,20Zm0,208a76.1,76.1,0,0,1-76-76c0-28.5,10-59.7,27.3-85.8C94.8,43,113.9,28,128,28s33.2,15,48.7,38.2C194,92.3,204,123.5,204,152A76.1,76.1,0,0,1,128,228Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M186.7,59.6C168.5,32.3,146.5,16,128,16S87.5,32.3,69.3,59.6,40,121.2,40,152a88,88,0,0,0,176,0C216,121.2,205.3,87.5,186.7,59.6ZM128,224a72.1,72.1,0,0,1-72-72c0-27.7,9.7-58.2,26.7-83.6C97.2,46.6,115.4,32,128,32s30.8,14.6,45.3,36.4c17,25.4,26.7,55.9,26.7,83.6A72.1,72.1,0,0,1,128,224Z" />
        </>
      )}
    </svg>
  );
}

export function PhEjectSimple(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M40.8,172H215.2a19.9,19.9,0,0,0,15.5-32.6L143.5,32.1a19.9,19.9,0,0,0-31,0L25.3,139.4A19.9,19.9,0,0,0,40.8,172ZM128,51l78.8,97H49.2ZM236,208a12,12,0,0,1-12,12H32a12,12,0,0,1,0-24H224A12,12,0,0,1,236,208Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M215.2,160H40.8a8,8,0,0,1-6.2-13L121.8,39.6a8,8,0,0,1,12.4,0L221.4,147A8,8,0,0,1,215.2,160Z"
            opacity="0.2"
          />
          <path d="M40.8,168H215.2a16,16,0,0,0,12.4-26.1L140.4,34.6a16,16,0,0,0-24.8,0L28.4,141.9A16,16,0,0,0,40.8,168ZM128,44.7,215.2,152H40.8ZM232,208a8,8,0,0,1-8,8H32a8,8,0,0,1,0-16H224A8,8,0,0,1,232,208Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M26.4,158.9a15.9,15.9,0,0,1,2-17L115.6,34.6a16,16,0,0,1,24.8,0l87.2,107.3A16,16,0,0,1,215.2,168H40.8A15.8,15.8,0,0,1,26.4,158.9ZM224,200H32a8,8,0,0,0,0,16H224a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M40.8,166H215.2a14,14,0,0,0,10.9-22.8L138.9,35.9a14,14,0,0,0-21.8,0h0L29.9,143.2A14,14,0,0,0,40.8,166Zm-1.5-15.3L126.4,43.4h0a2.2,2.2,0,0,1,3.2,0l87.1,107.3a2.1,2.1,0,0,1,.3,2.2,1.9,1.9,0,0,1-1.8,1.1H40.8a1.9,1.9,0,0,1-1.8-1.1A2.1,2.1,0,0,1,39.3,150.7ZM230,208a6,6,0,0,1-6,6H32a6,6,0,0,1,0-12H224A6,6,0,0,1,230,208Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M40.8,164H215.2a11.8,11.8,0,0,0,10.8-6.8,11.9,11.9,0,0,0-1.5-12.8L137.3,37.1a12,12,0,0,0-18.6,0h0L31.5,144.4A11.9,11.9,0,0,0,30,157.2,11.8,11.8,0,0,0,40.8,164Zm-3.1-14.5L124.9,42.2h0a4,4,0,0,1,6.2,0l87.2,107.3a3.8,3.8,0,0,1,.5,4.2,4,4,0,0,1-3.6,2.3H40.8a4,4,0,0,1-3.6-2.3A3.8,3.8,0,0,1,37.7,149.5ZM228,208a4,4,0,0,1-4,4H32a4,4,0,0,1,0-8H224A4,4,0,0,1,228,208Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M40.8,168H215.2a16,16,0,0,0,12.4-26.1L140.4,34.6a16,16,0,0,0-24.8,0L28.4,141.9A16,16,0,0,0,40.8,168ZM128,44.7,215.2,152H40.8ZM232,208a8,8,0,0,1-8,8H32a8,8,0,0,1,0-16H224A8,8,0,0,1,232,208Z" />
        </>
      )}
    </svg>
  );
}

export function PhEject(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M50.8,144H205.2a20,20,0,0,0,14.4-33.9l-77-80a20.1,20.1,0,0,0-29.2,0l-77,80A20,20,0,0,0,50.8,144ZM128,49.6,195.8,120H60.2ZM208,156H48a20.1,20.1,0,0,0-20,20v24a20.1,20.1,0,0,0,20,20H208a20.1,20.1,0,0,0,20-20V176A20.1,20.1,0,0,0,208,156Zm-4,40H52V180H204Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M122.1,38.4,44.4,122.6A8,8,0,0,0,50.3,136H205.7a8,8,0,0,0,5.9-13.4L133.9,38.4A7.9,7.9,0,0,0,122.1,38.4Z" />
          </g>
          <g opacity="0.2">
            <rect
              x="108"
              y="100"
              width="40"
              height="176"
              rx="8"
              transform="translate(-60 316) rotate(-90)"
            />
          </g>
          <path d="M50.3,144H205.7a16,16,0,0,0,11.8-26.9L139.8,32.9a16.2,16.2,0,0,0-23.6,0h0L38.5,117.1A16,16,0,0,0,50.3,144ZM128,43.8,205.7,128H50.3ZM208,160H48a16,16,0,0,0-16,16v24a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V176A16,16,0,0,0,208,160Zm0,40H48V176H208Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M35.6,134.4a15.8,15.8,0,0,1,2.9-17.3l77.7-84.2h0a16.2,16.2,0,0,1,23.6,0l77.7,84.2A16,16,0,0,1,205.7,144H50.3A15.9,15.9,0,0,1,35.6,134.4ZM208,160H48a16,16,0,0,0-16,16v24a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V176A16,16,0,0,0,208,160Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M50.3,142H205.7A14,14,0,0,0,216,118.5L138.3,34.3a14,14,0,0,0-20.6,0L40,118.5A14,14,0,0,0,50.3,142Zm-1.5-15.4,77.7-84.2h0a2.2,2.2,0,0,1,3,0l77.7,84.2a2,2,0,0,1,.4,2.2,2,2,0,0,1-1.9,1.2H50.3a2,2,0,0,1-1.9-1.2A2,2,0,0,1,48.8,126.6ZM208,162H48a14,14,0,0,0-14,14v24a14,14,0,0,0,14,14H208a14,14,0,0,0,14-14V176A14,14,0,0,0,208,162Zm2,38a2,2,0,0,1-2,2H48a2,2,0,0,1-2-2V176a2,2,0,0,1,2-2H208a2,2,0,0,1,2,2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M50.3,140H205.7a11.8,11.8,0,0,0,11-7.2,11.6,11.6,0,0,0-2.2-12.9L136.8,35.7a11.9,11.9,0,0,0-17.6,0L41.5,119.9a11.6,11.6,0,0,0-2.2,12.9A11.8,11.8,0,0,0,50.3,140Zm-3-14.7,77.8-84.2a3.9,3.9,0,0,1,5.8,0l77.8,84.2a4.1,4.1,0,0,1,.7,4.3,3.9,3.9,0,0,1-3.7,2.4H50.3a3.9,3.9,0,0,1-3.7-2.4A4.1,4.1,0,0,1,47.3,125.3ZM208,164H48a12,12,0,0,0-12,12v24a12,12,0,0,0,12,12H208a12,12,0,0,0,12-12V176A12,12,0,0,0,208,164Zm4,36a4,4,0,0,1-4,4H48a4,4,0,0,1-4-4V176a4,4,0,0,1,4-4H208a4,4,0,0,1,4,4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M50.3,144H205.7a16,16,0,0,0,11.8-26.9L139.8,32.9a16.2,16.2,0,0,0-23.6,0h0L38.5,117.1A16,16,0,0,0,50.3,144ZM128,43.8,205.7,128H50.3ZM208,160H48a16,16,0,0,0-16,16v24a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V176A16,16,0,0,0,208,160Zm0,40H48V176H208Z" />
        </>
      )}
    </svg>
  );
}

export function PhEnvelopeOpen(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M230.7,86l-96-64a12.1,12.1,0,0,0-13.4,0l-96,64A12.1,12.1,0,0,0,20,96V200a20.1,20.1,0,0,0,20,20H216a20.1,20.1,0,0,0,20-20V96A12.1,12.1,0,0,0,230.7,86ZM89.8,152,44,184.3v-65Zm24.6,12h27.2l45.3,32H69.1Zm51.8-12L212,119.3v65ZM128,46.4l74.9,49.9L141.6,140H114.4L53.1,96.3Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M224,96l-78.5,56h-35L32,96l96-64Z" opacity="0.2" />
          <path d="M228.4,89.3l-96-64a8.2,8.2,0,0,0-8.8,0l-96,64A7.9,7.9,0,0,0,24,96V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V96A7.9,7.9,0,0,0,228.4,89.3ZM40,111.5,96.7,152,40,192ZM113.1,160h29.8l56.6,40H56.5Zm46.2-8L216,111.5V192ZM128,41.6l81.9,54.6-67,47.8H113.1l-67-47.8Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M228.4,89.3l-96-64a8.2,8.2,0,0,0-8.8,0l-96,64A7.9,7.9,0,0,0,24,96V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V96A7.9,7.9,0,0,0,228.4,89.3ZM96.7,152,40,192V111.5Zm16.4,8h29.8l56.6,40H56.5Zm46.2-8L216,111.5V192Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M227.3,91l-96-64a6.1,6.1,0,0,0-6.6,0l-96,64A6,6,0,0,0,26,96V200a14,14,0,0,0,14,14H216a14,14,0,0,0,14-14V96A6,6,0,0,0,227.3,91ZM100.2,152,38,195.9V107.6Zm12.3,6h31l62.3,44H50.2Zm43.3-6L218,107.6v88.3ZM128,39.2l85.4,57L143.5,146h-31L42.6,96.2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M226.2,92.7l-96-64a3.9,3.9,0,0,0-4.4,0l-96,64A4,4,0,0,0,28,96V200a12,12,0,0,0,12,12H216a12,12,0,0,0,12-12V96A4,4,0,0,0,226.2,92.7ZM103.6,152,36,199.8v-96Zm8.2,4h32.4l67.9,48H43.9Zm40.6-4L220,103.8v96ZM128,36.8l89,59.3L144.2,148H111.8L39,96.1Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M228.4,89.3l-96-64a8.2,8.2,0,0,0-8.8,0l-96,64A7.9,7.9,0,0,0,24,96V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V96A7.9,7.9,0,0,0,228.4,89.3ZM96.7,152,40,192V111.5Zm16.4,8h29.8l56.6,40H56.5Zm46.2-8L216,111.5V192ZM128,41.6l81.9,54.6-67,47.8H113.1l-67-47.8Z" />
        </>
      )}
    </svg>
  );
}

export function PhEnvelopeSimpleOpen(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M230.7,86l-96-64a12.1,12.1,0,0,0-13.4,0l-96,64A12.1,12.1,0,0,0,20,96V200a20.1,20.1,0,0,0,20,20H216a20.1,20.1,0,0,0,20-20V96A12.1,12.1,0,0,0,230.7,86ZM128,46.4l74.9,49.9L141.6,140H114.4L53.1,96.3ZM44,196V119.3l59.6,42.5a12,12,0,0,0,6.9,2.2h35a12,12,0,0,0,6.9-2.2L212,119.3V196Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M224,96l-78.5,56h-35L32,96l96-64Z" opacity="0.2" />
          <path d="M228.4,89.3l-96-64a8.2,8.2,0,0,0-8.8,0l-96,64A7.9,7.9,0,0,0,24,96V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V96A7.9,7.9,0,0,0,228.4,89.3ZM128,41.6l81.9,54.6-67,47.8H113.1l-67-47.8ZM40,200V111.5l65.9,47a7.9,7.9,0,0,0,4.6,1.5h35a7.9,7.9,0,0,0,4.6-1.5l65.9-47V200Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M228.4,89.3l-96-64a8.2,8.2,0,0,0-8.8,0l-96,64A7.9,7.9,0,0,0,24,96V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V96A7.9,7.9,0,0,0,228.4,89.3ZM40,200V111.5l65.9,47a7.9,7.9,0,0,0,4.6,1.5h35a7.9,7.9,0,0,0,4.6-1.5l65.9-47V200Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M227.3,91l-96-64a6.1,6.1,0,0,0-6.6,0l-96,64A6,6,0,0,0,26,96V200a14,14,0,0,0,14,14H216a14,14,0,0,0,14-14V96A6,6,0,0,0,227.3,91ZM128,39.2l85.4,57L143.5,146h-31L42.6,96.2ZM216,202H40a2,2,0,0,1-2-2V107.6l69.1,49.3a6,6,0,0,0,3.4,1.1h35a6,6,0,0,0,3.4-1.1L218,107.6V200A2,2,0,0,1,216,202Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M226.2,92.7l-96-64a3.9,3.9,0,0,0-4.4,0l-96,64A4,4,0,0,0,28,96V200a12,12,0,0,0,12,12H216a12,12,0,0,0,12-12V96A4,4,0,0,0,226.2,92.7ZM128,36.8l89,59.3L144.2,148H111.8L39,96.1ZM216,204H40a4,4,0,0,1-4-4V103.8l72.2,51.5a4.8,4.8,0,0,0,2.3.7h35a4.8,4.8,0,0,0,2.3-.7L220,103.8V200A4,4,0,0,1,216,204Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M228.4,89.3l-96-64a8.2,8.2,0,0,0-8.8,0l-96,64A7.9,7.9,0,0,0,24,96V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V96A7.9,7.9,0,0,0,228.4,89.3ZM128,41.6l81.9,54.6-67,47.8H113.1l-67-47.8ZM40,200V111.5l65.9,47a7.9,7.9,0,0,0,4.6,1.5h35a7.9,7.9,0,0,0,4.6-1.5l65.9-47V200Z" />
        </>
      )}
    </svg>
  );
}

export function PhEnvelopeSimple(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M224,44H32A12,12,0,0,0,20,56V192a20.1,20.1,0,0,0,20,20H216a20.1,20.1,0,0,0,20-20V56A12,12,0,0,0,224,44ZM193.2,68,128,127.7,62.8,68ZM44,188V83.3l75.9,69.5a11.9,11.9,0,0,0,16.2,0L212,83.3V188Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M224,56l-96,88L32,56Z" opacity="0.2" />
          <path d="M224,48H32a8,8,0,0,0-8,8V192a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A8,8,0,0,0,224,48ZM203.4,64,128,133.1,52.6,64ZM216,192H40V74.2l82.6,75.7a8,8,0,0,0,10.8,0L216,74.2V192Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M224,48H32a8,8,0,0,0-8,8V192a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A8,8,0,0,0,224,48Zm-8,144H40V74.2l82.6,75.7a8,8,0,0,0,10.8,0L216,74.2V192Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M224,50H32a6,6,0,0,0-6,6V192a14,14,0,0,0,14,14H216a14,14,0,0,0,14-14V56A6,6,0,0,0,224,50ZM208.6,62,128,135.9,47.4,62ZM216,194H40a2,2,0,0,1-2-2V69.6l85.9,78.8a6.1,6.1,0,0,0,8.2,0L218,69.6V192A2,2,0,0,1,216,194Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M224,52H32a4,4,0,0,0-4,4V192a12,12,0,0,0,12,12H216a12,12,0,0,0,12-12V56A4,4,0,0,0,224,52Zm-10.3,8L128,138.6,42.3,60ZM216,196H40a4,4,0,0,1-4-4V65.1l89.3,81.8a3.9,3.9,0,0,0,5.4,0L220,65.1V192A4,4,0,0,1,216,196Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M224,48H32a8,8,0,0,0-8,8V192a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A8,8,0,0,0,224,48ZM203.4,64,128,133.1,52.6,64ZM216,192H40V74.2l82.6,75.7a8,8,0,0,0,10.8,0L216,74.2V192Z" />
        </>
      )}
    </svg>
  );
}

export function PhEnvelope(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M224,44H32A12,12,0,0,0,20,56V192a20.1,20.1,0,0,0,20,20H216a20.1,20.1,0,0,0,20-20V56A12,12,0,0,0,224,44Zm-86.5,75h0l-9.5,8.7L62.8,68H193.2Zm-44.7,9L44,172.7V83.3Zm17.7,16.3,9.4,8.5a11.9,11.9,0,0,0,16.2,0l9.4-8.5L193.2,188H62.8ZM163.2,128,212,83.3v89.4Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M224,56l-96,88L32,56Z" opacity="0.2" />
          <path d="M224,48H32a8,8,0,0,0-8,8V192a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A8,8,0,0,0,224,48Zm-83.9,74h0L128,133.1,52.6,64H203.4Zm-41.4,6L40,181.8V74.2Zm11.8,10.9,12.1,11a8,8,0,0,0,10.8,0l12.1-11L203.4,192H52.6ZM157.3,128,216,74.2V181.8Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M224,48H32a8,8,0,0,0-8,8V192a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A8,8,0,0,0,224,48ZM98.7,128,40,181.8V74.2Zm11.8,10.9,12.1,11a8,8,0,0,0,10.8,0l12.1-11L203.4,192H52.6ZM157.3,128,216,74.2V181.8Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M224,50H32a6,6,0,0,0-6,6V192a14,14,0,0,0,14,14H216a14,14,0,0,0,14-14V56A6,6,0,0,0,224,50Zm-96,85.9L47.4,62H208.6ZM101.7,128,38,186.4V69.6Zm8.8,8.1,13.4,12.3a6.1,6.1,0,0,0,8.2,0l13.4-12.3L208.6,194H47.4Zm43.8-8.1L218,69.6V186.4Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M224,52H32a4,4,0,0,0-4,4V192a12,12,0,0,0,12,12H216a12,12,0,0,0,12-12V56A4,4,0,0,0,224,52Zm-96,86.6L42.3,60H213.7ZM104.6,128,36,190.9V65.1Zm5.9,5.4,14.8,13.5a3.9,3.9,0,0,0,5.4,0l14.8-13.5L213.7,196H42.3Zm40.9-5.4L220,65.1V190.9Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M224,48H32a8,8,0,0,0-8,8V192a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A8,8,0,0,0,224,48Zm-83.9,74h0L128,133.1,52.6,64H203.4Zm-41.4,6L40,181.8V74.2Zm11.8,10.9,12.1,11a8,8,0,0,0,10.8,0l12.1-11L203.4,192H52.6ZM157.3,128,216,74.2V181.8Z" />
        </>
      )}
    </svg>
  );
}

export function PhEqualizer(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M84,108a12,12,0,0,1-12,12H36a12,12,0,0,1,0-24H72A12,12,0,0,1,84,108ZM72,136H36a12,12,0,0,0,0,24H72a12,12,0,0,0,0-24Zm0,44H36a12,12,0,0,0,0,24H72a12,12,0,0,0,0-24Zm74-44H110a12,12,0,0,0,0,24h36a12,12,0,0,0,0-24Zm0,44H110a12,12,0,0,0,0,24h36a12,12,0,0,0,0-24ZM184,80h36a12,12,0,0,0,0-24H184a12,12,0,0,0,0,24Zm36,16H184a12,12,0,0,0,0,24h36a12,12,0,0,0,0-24Zm0,40H184a12,12,0,0,0,0,24h36a12,12,0,0,0,0-24Zm0,40H184a12,12,0,0,0,0,24h36a12,12,0,0,0,0-24Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M84,96a8,8,0,0,1-8,8H36a8,8,0,0,1,0-16H76A8,8,0,0,1,84,96Zm-8,24H36a8,8,0,0,0,0,16H76a8,8,0,0,0,0-16Zm0,32H36a8,8,0,0,0,0,16H76a8,8,0,0,0,0-16Zm0,32H36a8,8,0,0,0,0,16H76a8,8,0,0,0,0-16Zm72-64H108a8,8,0,0,0,0,16h40a8,8,0,0,0,0-16Zm0,32H108a8,8,0,0,0,0,16h40a8,8,0,0,0,0-16Zm0,32H108a8,8,0,0,0,0,16h40a8,8,0,0,0,0-16Zm72-96H180a8,8,0,0,0,0,16h40a8,8,0,0,0,0-16ZM180,72h40a8,8,0,0,0,0-16H180a8,8,0,0,0,0,16Zm40,48H180a8,8,0,0,0,0,16h40a8,8,0,0,0,0-16Zm0,32H180a8,8,0,0,0,0,16h40a8,8,0,0,0,0-16Zm0,32H180a8,8,0,0,0,0,16h40a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M84,160a8,8,0,0,1-8,8H36a8,8,0,0,1,0-16H76A8,8,0,0,1,84,160Zm-8-40H36a8,8,0,0,0,0,16H76a8,8,0,0,0,0-16Zm0-32H36a8,8,0,0,0,0,16H76a8,8,0,0,0,0-16Zm72,96H108a8,8,0,0,0,0,16h40a8,8,0,0,0,0-16Zm-72,0H36a8,8,0,0,0,0,16H76a8,8,0,0,0,0-16ZM180,72h40a8,8,0,0,0,0-16H180a8,8,0,0,0,0,16Zm40,112H180a8,8,0,0,0,0,16h40a8,8,0,0,0,0-16Zm0-32H180a8,8,0,0,0,0,16h40a8,8,0,0,0,0-16Zm0-32H180a8,8,0,0,0,0,16h40a8,8,0,0,0,0-16Zm0-32H180a8,8,0,0,0,0,16h40a8,8,0,0,0,0-16Zm-72,64H108a8,8,0,0,0,0,16h40a8,8,0,0,0,0-16Zm0-32H108a8,8,0,0,0,0,16h40a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M82,96a6,6,0,0,1-6,6H36a6,6,0,0,1,0-12H76A6,6,0,0,1,82,96Zm-6,26H36a6,6,0,0,0,0,12H76a6,6,0,0,0,0-12Zm0,32H36a6,6,0,0,0,0,12H76a6,6,0,0,0,0-12Zm0,32H36a6,6,0,0,0,0,12H76a6,6,0,0,0,0-12Zm72-64H108a6,6,0,0,0,0,12h40a6,6,0,0,0,0-12Zm0,32H108a6,6,0,0,0,0,12h40a6,6,0,0,0,0-12Zm0,32H108a6,6,0,0,0,0,12h40a6,6,0,0,0,0-12Zm72-96H180a6,6,0,0,0,0,12h40a6,6,0,0,0,0-12ZM180,70h40a6,6,0,0,0,0-12H180a6,6,0,0,0,0,12Zm40,52H180a6,6,0,0,0,0,12h40a6,6,0,0,0,0-12Zm0,32H180a6,6,0,0,0,0,12h40a6,6,0,0,0,0-12Zm0,32H180a6,6,0,0,0,0,12h40a6,6,0,0,0,0-12Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M80,96a4,4,0,0,1-4,4H36a4,4,0,0,1,0-8H76A4,4,0,0,1,80,96Zm-4,28H36a4,4,0,0,0,0,8H76a4,4,0,0,0,0-8Zm0,32H36a4,4,0,0,0,0,8H76a4,4,0,0,0,0-8Zm0,32H36a4,4,0,0,0,0,8H76a4,4,0,0,0,0-8Zm72-64H108a4,4,0,0,0,0,8h40a4,4,0,0,0,0-8Zm0,32H108a4,4,0,0,0,0,8h40a4,4,0,0,0,0-8Zm0,32H108a4,4,0,0,0,0,8h40a4,4,0,0,0,0-8Zm72-96H180a4,4,0,0,0,0,8h40a4,4,0,0,0,0-8ZM180,68h40a4,4,0,0,0,0-8H180a4,4,0,0,0,0,8Zm40,56H180a4,4,0,0,0,0,8h40a4,4,0,0,0,0-8Zm0,32H180a4,4,0,0,0,0,8h40a4,4,0,0,0,0-8Zm0,32H180a4,4,0,0,0,0,8h40a4,4,0,0,0,0-8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M84,96a8,8,0,0,1-8,8H36a8,8,0,0,1,0-16H76A8,8,0,0,1,84,96Zm-8,24H36a8,8,0,0,0,0,16H76a8,8,0,0,0,0-16Zm0,32H36a8,8,0,0,0,0,16H76a8,8,0,0,0,0-16Zm0,32H36a8,8,0,0,0,0,16H76a8,8,0,0,0,0-16Zm72-64H108a8,8,0,0,0,0,16h40a8,8,0,0,0,0-16Zm0,32H108a8,8,0,0,0,0,16h40a8,8,0,0,0,0-16Zm0,32H108a8,8,0,0,0,0,16h40a8,8,0,0,0,0-16Zm72-96H180a8,8,0,0,0,0,16h40a8,8,0,0,0,0-16ZM180,72h40a8,8,0,0,0,0-16H180a8,8,0,0,0,0,16Zm40,48H180a8,8,0,0,0,0,16h40a8,8,0,0,0,0-16Zm0,32H180a8,8,0,0,0,0,16h40a8,8,0,0,0,0-16Zm0,32H180a8,8,0,0,0,0,16h40a8,8,0,0,0,0-16Z" />
        </>
      )}
    </svg>
  );
}

export function PhEquals(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M228,160a12,12,0,0,1-12,12H40a12,12,0,0,1,0-24H216A12,12,0,0,1,228,160ZM40,108H216a12,12,0,0,0,0-24H40a12,12,0,0,0,0,24Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M224,160a8,8,0,0,1-8,8H40a8,8,0,0,1,0-16H216A8,8,0,0,1,224,160ZM40,104H216a8,8,0,0,0,0-16H40a8,8,0,0,0,0,16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M224,160a8,8,0,0,1-8,8H40a8,8,0,0,1,0-16H216A8,8,0,0,1,224,160ZM40,104H216a8,8,0,0,0,0-16H40a8,8,0,0,0,0,16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M222,160a6,6,0,0,1-6,6H40a6,6,0,0,1,0-12H216A6,6,0,0,1,222,160ZM40,102H216a6,6,0,0,0,0-12H40a6,6,0,0,0,0,12Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M220,160a4,4,0,0,1-4,4H40a4,4,0,0,1,0-8H216A4,4,0,0,1,220,160ZM40,100H216a4,4,0,0,0,0-8H40a4,4,0,0,0,0,8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M224,160a8,8,0,0,1-8,8H40a8,8,0,0,1,0-16H216A8,8,0,0,1,224,160ZM40,104H216a8,8,0,0,0,0-16H40a8,8,0,0,0,0,16Z" />
        </>
      )}
    </svg>
  );
}

export function PhEraser(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216,203.8H140l27.9-27.9h0l56.6-56.6a27.9,27.9,0,0,0,0-39.5L179.2,34.5a28.1,28.1,0,0,0-39.6,0L83.1,91.1h0L26.5,147.6a28.1,28.1,0,0,0,0,39.6l37.1,37.1a11.7,11.7,0,0,0,8.5,3.5H216a12,12,0,0,0,0-24ZM156.6,51.5a4,4,0,0,1,5.7,0l45.2,45.2a4,4,0,0,1,0,5.7l-48.1,48.1-50.9-51ZM106.1,203.8H77L43.5,170.3a4,4,0,0,1,0-5.7l48.1-48.1,50.9,50.9Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M111,215.8H72.1L35,178.7a15.9,15.9,0,0,1,0-22.6L91.6,99.5l67.8,67.9Z" />
          </g>
          <path d="M216,207.8H130.3l34.8-34.7h0l56.6-56.6a24.1,24.1,0,0,0,0-33.9L176.4,37.3a24,24,0,0,0-33.9,0L85.9,93.9h0L29.3,150.5a24,24,0,0,0,0,33.9l37.1,37.1a8.1,8.1,0,0,0,5.7,2.3H216a8,8,0,0,0,0-16ZM153.8,48.6a8.1,8.1,0,0,1,11.3,0l45.2,45.3a7.9,7.9,0,0,1,0,11.3l-50.9,50.9L102.9,99.5ZM75.4,207.8,40.6,173.1a8.1,8.1,0,0,1,0-11.3l51-50.9,56.5,56.5-40.4,40.4Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,207.8H130.3l34.8-34.7h0l56.6-56.6a24.1,24.1,0,0,0,0-33.9L176.4,37.3a24,24,0,0,0-33.9,0L85.9,93.9h0L29.3,150.5a24,24,0,0,0,0,33.9l37.1,37.1a8.1,8.1,0,0,0,5.7,2.3H216a8,8,0,0,0,0-16ZM153.8,48.6a8.1,8.1,0,0,1,11.3,0l45.2,45.3a7.9,7.9,0,0,1,0,11.3l-50.9,50.9L102.9,99.5Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M216,209.8H125.5l94.7-94.7a22,22,0,0,0,0-31.1L175,38.7a22.2,22.2,0,0,0-15.6-6.4,22,22,0,0,0-15.5,6.4L87.3,95.3h0L30.7,151.9a22.2,22.2,0,0,0,0,31.1l37.1,37.1a6.3,6.3,0,0,0,4.3,1.7H216a6,6,0,0,0,0-12ZM152.4,47.2a10,10,0,0,1,14.1,0l45.3,45.3a10,10,0,0,1,0,14.1l-52.4,52.3L100,99.5ZM74.6,209.8,39.2,174.5a10,10,0,0,1,0-14.1L91.6,108l59.3,59.4-42.4,42.4Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M216,211.8H120.7l98.1-98.1a20,20,0,0,0,0-28.3L173.6,40.2a20,20,0,0,0-28.3,0L88.7,96.7h0L32.2,153.3a20,20,0,0,0,0,28.3l37,37.1a4.4,4.4,0,0,0,2.9,1.1H216a4,4,0,0,0,0-8Zm-65.1-166a12,12,0,0,1,17,0l45.3,45.3a11.9,11.9,0,0,1,0,16.9l-53.8,53.8L97.2,99.5Zm-41.5,166H73.7L37.8,175.9a12,12,0,0,1,0-17l53.8-53.7,62.2,62.2Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,207.8H130.3l34.8-34.7h0l56.6-56.6a24.1,24.1,0,0,0,0-33.9L176.4,37.3a24,24,0,0,0-33.9,0L85.9,93.9h0L29.3,150.5a24,24,0,0,0,0,33.9l37.1,37.1a7.9,7.9,0,0,0,5.7,2.3H216a8,8,0,0,0,0-16ZM153.8,48.6a8.1,8.1,0,0,1,11.3,0l45.2,45.3a7.9,7.9,0,0,1,0,11.3l-50.9,50.9L102.9,99.5ZM75.4,207.8,40.6,173.1a8.1,8.1,0,0,1,0-11.3l51-50.9,56.5,56.5-40.4,40.4Z" />
        </>
      )}
    </svg>
  );
}

export function PhExam(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216,36H40A20.1,20.1,0,0,0,20,56V216a12,12,0,0,0,5.7,10.2,11.9,11.9,0,0,0,11.7.5L64,213.4l26.6,13.3a12.1,12.1,0,0,0,10.8,0L128,213.4l26.6,13.3a11.9,11.9,0,0,0,10.8,0L192,213.4l26.6,13.3a11.9,11.9,0,0,0,11.7-.5A12,12,0,0,0,236,216V56A20.1,20.1,0,0,0,216,36Zm-4,160.6-14.6-7.3a12.1,12.1,0,0,0-10.8,0L160,202.6l-26.6-13.3a11.9,11.9,0,0,0-10.8,0L96,202.6,69.4,189.3a12.1,12.1,0,0,0-10.8,0L44,196.6V60H212ZM62.6,170.7a11.9,11.9,0,0,0,16.1-5.3l4.7-9.4h33.2l4.7,9.4a12,12,0,1,0,21.4-10.8l-32-64a12,12,0,0,0-21.4,0l-32,64A11.9,11.9,0,0,0,62.6,170.7Zm42-38.7H95.4l4.6-9.2Zm39.4-4a12,12,0,0,1,12-12h4v-4a12,12,0,0,1,24,0v4h4a12,12,0,0,1,0,24h-4v4a12,12,0,0,1-24,0v-4h-4A12,12,0,0,1,144,128Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M224,56V216l-32-16-32,16-32-16L96,216,64,200,32,216V56a8,8,0,0,1,8-8H216A8,8,0,0,1,224,56Z"
            opacity="0.2"
          />
          <path d="M216,40H40A16,16,0,0,0,24,56V216a7.9,7.9,0,0,0,3.8,6.8,8,8,0,0,0,7.8.4L64,208.9l28.4,14.3a8.3,8.3,0,0,0,7.2,0L128,208.9l28.4,14.3a8.5,8.5,0,0,0,7.2,0L192,208.9l28.4,14.3a8,8,0,0,0,7.8-.4A7.9,7.9,0,0,0,232,216V56A16,16,0,0,0,216,40Zm0,163.1-20.4-10.3a8.3,8.3,0,0,0-7.2,0L160,207.1l-28.4-14.3a8.5,8.5,0,0,0-7.2,0L96,207.1,67.6,192.8a8.3,8.3,0,0,0-7.2,0L40,203.1V56H216ZM68.4,167.2a8.2,8.2,0,0,0,10.8-3.6L84.9,152h38.2l5.7,11.6A8.1,8.1,0,0,0,136,168a9.4,9.4,0,0,0,3.6-.8,8.2,8.2,0,0,0,3.6-10.8l-32-64a8.1,8.1,0,0,0-14.4,0l-32,64A8.2,8.2,0,0,0,68.4,167.2ZM104,113.9,115.1,136H92.9ZM144,128a8,8,0,0,1,8-8h12V108a8,8,0,0,1,16,0v12h12a8,8,0,0,1,0,16H180v12a8,8,0,0,1-16,0V136H152A8,8,0,0,1,144,128Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M100,113.9,111.1,136H88.9ZM232,56V216a7.9,7.9,0,0,1-3.8,6.8,8,8,0,0,1-7.8.4L192,208.9l-28.4,14.3a8.5,8.5,0,0,1-7.2,0L128,208.9,99.6,223.2a8.3,8.3,0,0,1-7.2,0L64,208.9,35.6,223.2a8,8,0,0,1-7.8-.4A7.9,7.9,0,0,1,24,216V56A16,16,0,0,1,40,40H216A16,16,0,0,1,232,56ZM139.2,156.4l-32-64a8.1,8.1,0,0,0-14.4,0l-32,64a8,8,0,0,0,14.4,7.2L80.9,152h38.2l5.7,11.6A8.1,8.1,0,0,0,132,168a9.4,9.4,0,0,0,3.6-.8A8.2,8.2,0,0,0,139.2,156.4ZM204,128a8,8,0,0,0-8-8H184V108a8,8,0,0,0-16,0v12H156a8,8,0,0,0,0,16h12v12a8,8,0,0,0,16,0V136h12A8,8,0,0,0,204,128Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M216,42H40A14,14,0,0,0,26,56V216a6.1,6.1,0,0,0,2.8,5.1,6.2,6.2,0,0,0,5.9.3L64,206.7l29.3,14.7a6.4,6.4,0,0,0,5.4,0L128,206.7l29.3,14.7a6.4,6.4,0,0,0,5.4,0L192,206.7l29.3,14.7a6.6,6.6,0,0,0,2.7.6,6.2,6.2,0,0,0,3.2-.9A6.1,6.1,0,0,0,230,216V56A14,14,0,0,0,216,42Zm2,164.3-23.3-11.7a6.4,6.4,0,0,0-5.4,0L160,209.3l-29.3-14.7a6.4,6.4,0,0,0-5.4,0L96,209.3,66.7,194.6a6.4,6.4,0,0,0-5.4,0L38,206.3V56a2,2,0,0,1,2-2H216a2,2,0,0,1,2,2ZM109.4,93.3a6.1,6.1,0,0,0-10.8,0l-32,64a6,6,0,0,0,10.8,5.4L83.7,150h40.6l6.3,12.7A6.1,6.1,0,0,0,136,166a6.6,6.6,0,0,0,2.7-.6,6.2,6.2,0,0,0,2.7-8.1ZM89.7,138,104,109.4,118.3,138ZM198,128a6,6,0,0,1-6,6H178v14a6,6,0,0,1-12,0V134H152a6,6,0,0,1,0-12h14V108a6,6,0,0,1,12,0v14h14A6,6,0,0,1,198,128Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M216,44H40A12,12,0,0,0,28,56V216a3.9,3.9,0,0,0,1.9,3.4,4.1,4.1,0,0,0,3.9.2L64,204.5l30.2,15.1a4.5,4.5,0,0,0,3.6,0L128,204.5l30.2,15.1a4.5,4.5,0,0,0,3.6,0L192,204.5l30.2,15.1A4,4,0,0,0,228,216V56A12,12,0,0,0,216,44Zm4,165.5-26.2-13.1a4.5,4.5,0,0,0-3.6,0L160,211.5l-30.2-15.1a4.2,4.2,0,0,0-3.6,0L96,211.5,65.8,196.4a4.5,4.5,0,0,0-3.6,0L36,209.5V56a4,4,0,0,1,4-4H216a4,4,0,0,1,4,4ZM107.6,94.2a4,4,0,0,0-7.2,0l-32,64a4,4,0,0,0,7.2,3.6L82.5,148h43l6.9,13.8A4,4,0,0,0,136,164a3.9,3.9,0,0,0,1.8-.4,4,4,0,0,0,1.8-5.4ZM86.5,140,104,104.9,121.5,140ZM196,128a4,4,0,0,1-4,4H176v16a4,4,0,0,1-8,0V132H152a4,4,0,0,1,0-8h16V108a4,4,0,0,1,8,0v16h16A4,4,0,0,1,196,128Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,40H40A16,16,0,0,0,24,56V216a7.9,7.9,0,0,0,3.8,6.8,8,8,0,0,0,7.8.4L64,208.9l28.4,14.3a8.3,8.3,0,0,0,7.2,0L128,208.9l28.4,14.3a8.5,8.5,0,0,0,7.2,0L192,208.9l28.4,14.3a8,8,0,0,0,7.8-.4A7.9,7.9,0,0,0,232,216V56A16,16,0,0,0,216,40Zm0,163.1-20.4-10.3a8.3,8.3,0,0,0-7.2,0L160,207.1l-28.4-14.3a8.5,8.5,0,0,0-7.2,0L96,207.1,67.6,192.8a8.3,8.3,0,0,0-7.2,0L40,203.1V56H216ZM68.4,167.2a8.2,8.2,0,0,0,10.8-3.6L84.9,152h38.2l5.7,11.6A8.1,8.1,0,0,0,136,168a9.4,9.4,0,0,0,3.6-.8,8.2,8.2,0,0,0,3.6-10.8l-32-64a8.1,8.1,0,0,0-14.4,0l-32,64A8.2,8.2,0,0,0,68.4,167.2ZM104,113.9,115.1,136H92.9ZM144,128a8,8,0,0,1,8-8h12V108a8,8,0,0,1,16,0v12h12a8,8,0,0,1,0,16H180v12a8,8,0,0,1-16,0V136H152A8,8,0,0,1,144,128Z" />
        </>
      )}
    </svg>
  );
}

export function PhExport(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M77.5,66.5a12,12,0,0,1,0-17l42-42a12,12,0,0,1,17,0l42,42a12,12,0,0,1,0,17,12.1,12.1,0,0,1-17,0L140,45v83a12,12,0,0,1-24,0V45L94.5,66.5A12,12,0,0,1,77.5,66.5ZM200,88H176a12,12,0,0,0,0,24h20v92H60V112H80a12,12,0,0,0,0-24H56a20.1,20.1,0,0,0-20,20V208a20.1,20.1,0,0,0,20,20H200a20.1,20.1,0,0,0,20-20V108A20.1,20.1,0,0,0,200,88Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M208,96V216H48V96Z" opacity="0.2" />
          <path d="M80.3,63.6a8,8,0,0,1,0-11.3l42-42a8.1,8.1,0,0,1,11.4,0l42,42a8,8,0,0,1,0,11.3,8,8,0,0,1-11.4,0L136,35.3V128a8,8,0,0,1-16,0V35.3L91.7,63.6A8,8,0,0,1,80.3,63.6ZM200,88H176a8,8,0,0,0,0,16h24V208H56V104H80a8,8,0,0,0,0-16H56a16,16,0,0,0-16,16V208a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V104A16,16,0,0,0,200,88Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M136,88H120V35.3L91.7,63.6A8,8,0,0,1,80.3,52.3l42-42a8.1,8.1,0,0,1,11.4,0l42,42a8,8,0,0,1,0,11.3,8,8,0,0,1-11.4,0L136,35.3Zm64,0H136v40a8,8,0,0,1-16,0V88H56a16,16,0,0,0-16,16V208a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V104A16,16,0,0,0,200,88Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M81.8,62.2a5.9,5.9,0,0,1,0-8.5l42-41.9a5.8,5.8,0,0,1,8.4,0l42,41.9a5.9,5.9,0,0,1,0,8.5,5.8,5.8,0,0,1-8.4,0L134,30.5V128a6,6,0,0,1-12,0V30.5L90.2,62.2A5.8,5.8,0,0,1,81.8,62.2ZM200,90H176a6,6,0,0,0,0,12h24a2,2,0,0,1,2,2V208a2,2,0,0,1-2,2H56a2,2,0,0,1-2-2V104a2,2,0,0,1,2-2H80a6,6,0,0,0,0-12H56a14,14,0,0,0-14,14V208a14,14,0,0,0,14,14H200a14,14,0,0,0,14-14V104A14,14,0,0,0,200,90Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M83.2,60.8a3.9,3.9,0,0,1,0-5.6l42-42a3.9,3.9,0,0,1,5.6,0l42,42a3.9,3.9,0,0,1,0,5.6,3.9,3.9,0,0,1-5.6,0L132,25.7V128a4,4,0,0,1-8,0V25.7L88.8,60.8A3.9,3.9,0,0,1,83.2,60.8ZM200,92H176a4,4,0,0,0,0,8h24a4,4,0,0,1,4,4V208a4,4,0,0,1-4,4H56a4,4,0,0,1-4-4V104a4,4,0,0,1,4-4H80a4,4,0,0,0,0-8H56a12,12,0,0,0-12,12V208a12,12,0,0,0,12,12H200a12,12,0,0,0,12-12V104A12,12,0,0,0,200,92Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M80.3,63.6a8,8,0,0,1,0-11.3l42-42a8.1,8.1,0,0,1,11.4,0l42,42a8,8,0,0,1,0,11.3,8,8,0,0,1-11.4,0L136,35.3V128a8,8,0,0,1-16,0V35.3L91.7,63.6A8,8,0,0,1,80.3,63.6ZM200,88H176a8,8,0,0,0,0,16h24V208H56V104H80a8,8,0,0,0,0-16H56a16,16,0,0,0-16,16V208a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V104A16,16,0,0,0,200,88Z" />
        </>
      )}
    </svg>
  );
}

export function PhEyeClosed(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M234.4,160.8a12,12,0,0,1-10.4,18,11.8,11.8,0,0,1-10.4-6l-16.3-28.2a126,126,0,0,1-29.4,13.5l5.2,29.4a11.9,11.9,0,0,1-9.7,13.9l-2.1.2a12,12,0,0,1-11.8-9.9L144.4,163a123.5,123.5,0,0,1-16.4,1,146.3,146.3,0,0,1-16.5-1l-5.1,28.7a12,12,0,0,1-11.8,9.9l-2.1-.2a11.9,11.9,0,0,1-9.7-13.9L88,158.1a125.3,125.3,0,0,1-29.3-13.5L42.3,173a12.1,12.1,0,0,1-10.4,6,11.7,11.7,0,0,1-6-1.6A12,12,0,0,1,21.5,161l17.9-31a142.4,142.4,0,0,1-16.7-17.6A12,12,0,1,1,41.3,97.3C57.1,116.8,84.9,140,128,140s70.9-23.2,86.7-42.7a12,12,0,1,1,18.6,15.1,150.3,150.3,0,0,1-16.7,17.7Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M230.9,162.8a8,8,0,0,1-2.9,10.9,7.6,7.6,0,0,1-4,1.1,8,8,0,0,1-7-4L198.6,139a122.9,122.9,0,0,1-35.2,16.3l5.8,32.9a8.1,8.1,0,0,1-6.5,9.3h-1.4a8,8,0,0,1-7.9-6.6l-5.7-32.4A137.3,137.3,0,0,1,128,160a139.8,139.8,0,0,1-19.8-1.4L102.5,191a8,8,0,0,1-7.9,6.6H93.2a8.1,8.1,0,0,1-6.5-9.3l5.9-33a122.1,122.1,0,0,1-35.3-16.3L38.8,171a8.2,8.2,0,0,1-6.9,4A8,8,0,0,1,25,163l19.5-33.8a145.8,145.8,0,0,1-18.7-19.3A7.9,7.9,0,0,1,27,98.7a7.9,7.9,0,0,1,11.2,1.1,128.7,128.7,0,0,0,21.3,21h.1A109.7,109.7,0,0,0,128,144c29.3,0,51.8-10.3,68.3-23.1h.2a128.7,128.7,0,0,0,21.3-21A7.9,7.9,0,0,1,229,98.7a7.9,7.9,0,0,1,1.2,11.2,145.8,145.8,0,0,1-18.7,19.3Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M230.9,162.8a8,8,0,0,1-2.9,10.9,7.6,7.6,0,0,1-4,1.1,8,8,0,0,1-7-4L198.6,139a122.9,122.9,0,0,1-35.2,16.3l5.8,32.9a8.1,8.1,0,0,1-6.5,9.3h-1.4a8,8,0,0,1-7.9-6.6l-5.7-32.4A137.3,137.3,0,0,1,128,160a139.8,139.8,0,0,1-19.8-1.4L102.5,191a8,8,0,0,1-7.9,6.6H93.2a8.1,8.1,0,0,1-6.5-9.3l5.9-33a122.1,122.1,0,0,1-35.3-16.3L38.8,171a8.2,8.2,0,0,1-6.9,4A8,8,0,0,1,25,163l19.5-33.8a145.8,145.8,0,0,1-18.7-19.3A7.9,7.9,0,0,1,27,98.7a7.9,7.9,0,0,1,11.2,1.1,128.7,128.7,0,0,0,21.3,21h.1A109.7,109.7,0,0,0,128,144c29.3,0,51.8-10.3,68.3-23.1h.2a128.7,128.7,0,0,0,21.3-21A7.9,7.9,0,0,1,229,98.7a7.9,7.9,0,0,1,1.2,11.2,145.8,145.8,0,0,1-18.7,19.3Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M229.2,163.8A6.1,6.1,0,0,1,227,172a5.6,5.6,0,0,1-3,.8,6,6,0,0,1-5.2-3l-19.5-33.7a121.3,121.3,0,0,1-38.2,17.7l6.1,34.8a6,6,0,0,1-4.9,6.9h-1a5.9,5.9,0,0,1-5.9-5l-6.1-34.3A131.3,131.3,0,0,1,128,158a132.6,132.6,0,0,1-21.4-1.7l-6.1,34.3a6,6,0,0,1-5.9,5h-1a6,6,0,0,1-4.9-6.9l6.1-34.8a124.7,124.7,0,0,1-38.2-17.7L37.1,170a6,6,0,0,1-5.2,3,6.4,6.4,0,0,1-3-.8,6,6,0,0,1-2.2-8.2L47,128.7a148.1,148.1,0,0,1-19.7-20.1,6,6,0,0,1,9.4-7.5,131.5,131.5,0,0,0,21.5,21.3l.4.2A111.4,111.4,0,0,0,128,146a111.4,111.4,0,0,0,69.4-23.4l.3-.2a128.5,128.5,0,0,0,21.6-21.3,6,6,0,0,1,9.4,7.5,149.4,149.4,0,0,1-19.8,20.2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M227.4,164.8a4,4,0,0,1-1.4,5.5,5.3,5.3,0,0,1-2,.5,3.9,3.9,0,0,1-3.5-2l-20.6-35.6a119.2,119.2,0,0,1-41.1,19.1l6.4,36.6a3.9,3.9,0,0,1-3.2,4.6h-.7a4,4,0,0,1-3.9-3.3L151,154a129.6,129.6,0,0,1-23,2,128.7,128.7,0,0,1-23-2l-6.4,36.3a4.2,4.2,0,0,1-4,3.3h-.7a3.9,3.9,0,0,1-3.2-4.6l6.4-36.6A120.1,120.1,0,0,1,56,133.2L35.4,169a4.1,4.1,0,0,1-3.5,2,3.6,3.6,0,0,1-2-.6,4,4,0,0,1-1.5-5.4l21.2-36.7a145,145,0,0,1-20.7-20.9,4,4,0,0,1,.6-5.6,3.9,3.9,0,0,1,5.6.6,132.4,132.4,0,0,0,22.3,21.8h.1A113.5,113.5,0,0,0,128,148a113.5,113.5,0,0,0,70.5-23.7h0a137.5,137.5,0,0,0,22.4-21.9,3.9,3.9,0,0,1,5.6-.6,4,4,0,0,1,.6,5.6,140.1,140.1,0,0,1-20.7,20.9Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M230.9,162.8a8,8,0,0,1-2.9,10.9,7.6,7.6,0,0,1-4,1.1,8,8,0,0,1-7-4L198.6,139a122.9,122.9,0,0,1-35.2,16.3l5.8,32.9a8.1,8.1,0,0,1-6.5,9.3h-1.4a8,8,0,0,1-7.9-6.6l-5.7-32.4A137.3,137.3,0,0,1,128,160a139.8,139.8,0,0,1-19.8-1.4L102.5,191a8,8,0,0,1-7.9,6.6H93.2a8.1,8.1,0,0,1-6.5-9.3l5.9-33a122.1,122.1,0,0,1-35.3-16.3L38.8,171a8.2,8.2,0,0,1-6.9,4A8,8,0,0,1,25,163l19.5-33.8a145.8,145.8,0,0,1-18.7-19.3A7.9,7.9,0,0,1,27,98.7a7.9,7.9,0,0,1,11.2,1.1,128.7,128.7,0,0,0,21.3,21h.1A109.7,109.7,0,0,0,128,144c29.3,0,51.8-10.3,68.3-23.1h.2a128.7,128.7,0,0,0,21.3-21A7.9,7.9,0,0,1,229,98.7a7.9,7.9,0,0,1,1.2,11.2,145.8,145.8,0,0,1-18.7,19.3Z" />
        </>
      )}
    </svg>
  );
}

export function PhEyeSlash(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M56.9,31.9A12,12,0,0,0,39.1,48.1L55.2,65.7C20.7,88.6,5.7,121.6,5,123.1a12.8,12.8,0,0,0,0,9.8,149.8,149.8,0,0,0,28.5,39.6C59.4,198.3,92,212,128,212a132.1,132.1,0,0,0,51-10l20.1,22.1a12.1,12.1,0,0,0,17,.8,12.1,12.1,0,0,0,.8-17ZM128,188c-29.6,0-55.5-10.7-76.9-31.9A131.4,131.4,0,0,1,29.5,128c5.3-9.3,18.8-29.9,42-44.3l90.1,99.1A106.9,106.9,0,0,1,128,188Zm123-55.1c-.4.8-9,19.9-28.1,39.1a11.7,11.7,0,0,1-8.5,3.6,12.1,12.1,0,0,1-8.5-20.5A128.5,128.5,0,0,0,226.5,128a131.4,131.4,0,0,0-21.6-28.1C183.5,78.7,157.6,68,128,68c-3.3,0-6.7.1-10,.4a11.8,11.8,0,0,1-12.9-10.9,12,12,0,0,1,10.9-13c3.9-.3,8-.5,12-.5,36,0,68.6,13.7,94.5,39.5A149.8,149.8,0,0,1,251,123.1,12.8,12.8,0,0,1,251,132.9Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M128,56C48,56,16,128,16,128s32,72,112,72,112-72,112-72S208,56,128,56Zm0,112a40,40,0,1,1,40-40A40,40,0,0,1,128,168Z" />
          </g>
          <path d="M53.9,34.6A8,8,0,0,0,42.1,45.4L61.3,66.5C25,88.8,9.4,123.2,8.7,124.8a7.9,7.9,0,0,0,0,6.4c.3.8,8.8,19.6,27.6,38.5C61.4,194.7,93.1,208,128,208a128.6,128.6,0,0,0,52.1-10.8l22,24.2A8,8,0,0,0,208,224a8.2,8.2,0,0,0,5.4-2.1,7.9,7.9,0,0,0,.5-11.3Zm47.3,75.9,41.7,45.8A31.6,31.6,0,0,1,128,160a32,32,0,0,1-26.8-49.5ZM128,192c-30.8,0-57.7-11.2-79.9-33.3A131.5,131.5,0,0,1,24.9,128c4.7-8.8,19.7-33.4,47.4-49.4l18,19.8a48,48,0,0,0,63.6,70l14.7,16.2A112.1,112.1,0,0,1,128,192Z" />
          <path d="M247.3,124.8c-.3-.8-8.8-19.6-27.6-38.5C194.6,61.3,162.9,48,128,48a132.4,132.4,0,0,0-22,1.8A8,8,0,0,0,99.4,59a8.1,8.1,0,0,0,9.2,6.6A112.9,112.9,0,0,1,128,64c30.8,0,57.7,11.2,79.9,33.2A130.3,130.3,0,0,1,231,128a132.6,132.6,0,0,1-27.7,35.1,8,8,0,0,0-.6,11.3,7.6,7.6,0,0,0,5.9,2.7,8.1,8.1,0,0,0,5.3-2c22.9-20.5,33-42.9,33.4-43.9A7.9,7.9,0,0,0,247.3,124.8Z" />
          <path d="M159.9,125a7.9,7.9,0,0,0,7.9,7.2h.8a8,8,0,0,0,7.2-8.7A48.3,48.3,0,0,0,137,80.8a8,8,0,0,0-3,15.8A32.2,32.2,0,0,1,159.9,125Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M247.3,131.3c-.4.9-10.5,23.3-33.3,43.8a8.6,8.6,0,0,1-5.4,2,8,8,0,0,1-5.9-2.6L101.4,63.1A8.1,8.1,0,0,1,106,49.8,132.4,132.4,0,0,1,128,48c34.9,0,66.6,13.3,91.7,38.3,18.8,18.9,27.3,37.7,27.6,38.5A8.2,8.2,0,0,1,247.3,131.3Zm-33.4,79.3a7.9,7.9,0,0,1-.5,11.3A8.2,8.2,0,0,1,208,224a8,8,0,0,1-5.9-2.6l-22-24.2A128.6,128.6,0,0,1,128,208c-34.9,0-66.6-13.3-91.7-38.3C17.5,150.8,9,132,8.7,131.3a8.2,8.2,0,0,1,0-6.5c.7-1.6,16.3-36,52.6-58.3L42.1,45.4A8,8,0,0,1,53.9,34.6Zm-68.2-51.3L98.5,107.4a36,36,0,0,0,47.2,51.9Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M52.4,36a5.9,5.9,0,0,0-8.8,8L64.4,67c-37.2,21.9-53.2,57-53.9,58.6a5.7,5.7,0,0,0,0,4.8c.4.8,8.7,19.3,27.3,37.8S84.6,206,128,206a124.6,124.6,0,0,0,52.6-11.3l23,25.3a5.9,5.9,0,0,0,4.4,2,5.7,5.7,0,0,0,4-1.6,5.8,5.8,0,0,0,.4-8.4Zm48.7,71.3,45,49.5a34,34,0,0,1-45-49.5ZM128,194c-31.4,0-58.8-11.4-81.4-33.9A133.9,133.9,0,0,1,22.7,128c4.3-8.2,20.1-35.2,50-51.9L92.9,98.3a46,46,0,0,0,61.3,67.5L172,185.4A113.2,113.2,0,0,1,128,194Zm117.5-63.6c-.4,1-10.4,23-32.9,43.2a6,6,0,0,1-4,1.5,6.2,6.2,0,0,1-4.5-2,6,6,0,0,1,.5-8.5A134.2,134.2,0,0,0,233.3,128a133.9,133.9,0,0,0-23.9-32.1C186.8,73.4,159.4,62,128,62a116.4,116.4,0,0,0-19.7,1.6,6,6,0,0,1-2-11.8A128.8,128.8,0,0,1,128,50c43.4,0,73,20.5,90.2,37.8s26.9,37,27.3,37.8A5.7,5.7,0,0,1,245.5,130.4ZM134.4,94.6a6,6,0,0,1,2.2-11.8,46.2,46.2,0,0,1,37.2,40.9,6,6,0,0,1-5.4,6.5h-.6a5.9,5.9,0,0,1-5.9-5.4A34.1,34.1,0,0,0,134.4,94.6Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M51,37.3a4,4,0,1,0-6,5.4L67.6,67.5C29.3,88.9,13,124.8,12.3,126.4a4.4,4.4,0,0,0,0,3.2c.4.8,8.6,18.9,26.9,37.2S85.3,204,128,204a122.4,122.4,0,0,0,53.1-11.7L205,218.7a4.1,4.1,0,0,0,5.7.3,4.2,4.2,0,0,0,.3-5.7Zm98.1,119.9a36,36,0,0,1-48.1-53ZM128,196c-32,0-59.9-11.7-83-34.6A138.7,138.7,0,0,1,20.4,128c3.7-7.2,20.1-36.8,52.7-54.4L95.6,98.3A43.9,43.9,0,0,0,128,172a43.6,43.6,0,0,0,26.5-8.9l20.9,23A114.4,114.4,0,0,1,128,196Zm115.7-66.4a141.3,141.3,0,0,1-32.4,42.5,3.9,3.9,0,0,1-2.7,1,4.3,4.3,0,0,1-3-1.3,4.2,4.2,0,0,1,.3-5.7A136.4,136.4,0,0,0,235.6,128,136.8,136.8,0,0,0,211,94.6C187.9,71.6,160,60,128,60a119.1,119.1,0,0,0-20,1.6,3.9,3.9,0,0,1-4.6-3.3,4,4,0,0,1,3.3-4.6A135.1,135.1,0,0,1,128,52c42.7,0,71.9,20.2,88.8,37.2s26.5,36.4,26.9,37.2A4.4,4.4,0,0,1,243.7,129.6Zm-108.9-37a4,4,0,0,1-3.2-4.6,4,4,0,0,1,4.7-3.2,44.1,44.1,0,0,1,35.5,39.1,3.9,3.9,0,0,1-3.6,4.3h-.4a4,4,0,0,1-4-3.6A36.1,36.1,0,0,0,134.8,92.6Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M53.9,34.6A8,8,0,0,0,42.1,45.4L61.3,66.5C25,88.8,9.4,123.2,8.7,124.8a8.2,8.2,0,0,0,0,6.5c.3.7,8.8,19.5,27.6,38.4C61.4,194.7,93.1,208,128,208a128.6,128.6,0,0,0,52.1-10.8l22,24.2A8,8,0,0,0,208,224a8.2,8.2,0,0,0,5.4-2.1,7.9,7.9,0,0,0,.5-11.3Zm47.3,75.9,41.7,45.8A31.6,31.6,0,0,1,128,160a32,32,0,0,1-26.8-49.5ZM128,192c-30.8,0-57.7-11.2-79.9-33.3A128.3,128.3,0,0,1,25,128c4.7-8.8,19.8-33.5,47.3-49.4l18,19.8a48,48,0,0,0,63.6,70l14.7,16.2A112.1,112.1,0,0,1,128,192Zm119.3-60.7c-.4.9-10.5,23.3-33.4,43.8a8.1,8.1,0,0,1-5.3,2,7.6,7.6,0,0,1-5.9-2.7,8,8,0,0,1,.6-11.3A131,131,0,0,0,231,128a130.3,130.3,0,0,0-23.1-30.8C185.7,75.2,158.8,64,128,64a112.9,112.9,0,0,0-19.4,1.6A8.1,8.1,0,0,1,99.4,59a8,8,0,0,1,6.6-9.2A132.4,132.4,0,0,1,128,48c34.9,0,66.6,13.3,91.7,38.3,18.8,18.9,27.3,37.7,27.6,38.5A8.2,8.2,0,0,1,247.3,131.3ZM134,96.6a8,8,0,0,1,3-15.8,48.3,48.3,0,0,1,38.8,42.7,8,8,0,0,1-7.2,8.7h-.8a7.9,7.9,0,0,1-7.9-7.2A32.2,32.2,0,0,0,134,96.6Z" />
        </>
      )}
    </svg>
  );
}

export function PhEye(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M251,123.1a149.8,149.8,0,0,0-28.5-39.6C196.6,57.7,164,44,128,44S59.4,57.7,33.5,83.5A149.8,149.8,0,0,0,5,123.1a12.8,12.8,0,0,0,0,9.8,149.8,149.8,0,0,0,28.5,39.6C59.4,198.3,92,212,128,212s68.6-13.7,94.5-39.5A149.8,149.8,0,0,0,251,132.9,12.8,12.8,0,0,0,251,123.1Zm-46.1,33C183.5,177.3,157.6,188,128,188s-55.5-10.7-76.9-31.9A131.4,131.4,0,0,1,29.5,128,128.9,128.9,0,0,1,51.1,99.9C72.5,78.7,98.4,68,128,68s55.5,10.7,76.9,31.9A131.4,131.4,0,0,1,226.5,128,131.4,131.4,0,0,1,204.9,156.1ZM128,84a44,44,0,1,0,44,44A44,44,0,0,0,128,84Zm0,64a20,20,0,1,1,20-20A20.1,20.1,0,0,1,128,148Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M128,56C48,56,16,128,16,128s32,72,112,72,112-72,112-72S208,56,128,56Zm0,112a40,40,0,1,1,40-40A40,40,0,0,1,128,168Z" />
          </g>
          <path d="M247.3,124.8c-.3-.8-8.8-19.6-27.6-38.5C194.6,61.3,162.9,48,128,48S61.4,61.3,36.3,86.3C17.5,105.2,9,124,8.7,124.8a7.9,7.9,0,0,0,0,6.4c.3.8,8.8,19.6,27.6,38.5C61.4,194.7,93.1,208,128,208s66.6-13.3,91.7-38.3c18.8-18.9,27.3-37.7,27.6-38.5A7.9,7.9,0,0,0,247.3,124.8ZM128,192c-30.8,0-57.7-11.2-79.9-33.3A130.3,130.3,0,0,1,25,128,130.3,130.3,0,0,1,48.1,97.2C70.3,75.2,97.2,64,128,64s57.7,11.2,79.9,33.2A130.3,130.3,0,0,1,231,128C223.8,141.5,192.4,192,128,192Z" />
          <path d="M128,80a48,48,0,1,0,48,48A48,48,0,0,0,128,80Zm0,80a32,32,0,1,1,32-32A32.1,32.1,0,0,1,128,160Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M247.3,124.8c-.3-.8-8.8-19.6-27.6-38.5C194.6,61.3,162.9,48,128,48S61.4,61.3,36.3,86.3C17.5,105.2,9,124,8.7,124.8a7.9,7.9,0,0,0,0,6.4c.3.8,8.8,19.6,27.6,38.5C61.4,194.7,93.1,208,128,208s66.6-13.3,91.7-38.3c18.8-18.9,27.3-37.7,27.6-38.5A7.9,7.9,0,0,0,247.3,124.8ZM128,92a36,36,0,1,1-36,36A36,36,0,0,1,128,92Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M245.5,125.6c-.4-.8-8.7-19.3-27.3-37.8S171.4,50,128,50,55,70.5,37.8,87.8s-26.9,37-27.3,37.8a5.7,5.7,0,0,0,0,4.8c.4.8,8.7,19.3,27.3,37.8S84.6,206,128,206s73-20.5,90.2-37.8,26.9-37,27.3-37.8A5.7,5.7,0,0,0,245.5,125.6ZM128,194c-31.4,0-58.8-11.4-81.4-33.9A133.9,133.9,0,0,1,22.7,128,133.9,133.9,0,0,1,46.6,95.9C69.2,73.4,96.6,62,128,62s58.8,11.4,81.4,33.9A133.9,133.9,0,0,1,233.3,128C226.9,140.2,195,194,128,194Zm0-112a46,46,0,1,0,46,46A46,46,0,0,0,128,82Zm0,80a34,34,0,1,1,34-34A34.1,34.1,0,0,1,128,162Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M243.7,126.4c-.4-.8-8.6-18.9-26.9-37.2S170.7,52,128,52,56.1,72.2,39.2,89.2s-26.5,36.4-26.9,37.2a4.4,4.4,0,0,0,0,3.2c.4.8,8.6,18.9,26.9,37.2S85.3,204,128,204s71.9-20.2,88.8-37.2,26.5-36.4,26.9-37.2A4.4,4.4,0,0,0,243.7,126.4Zm-32.7,35c-23.1,22.9-51,34.6-83,34.6s-59.9-11.7-83-34.6A138.7,138.7,0,0,1,20.4,128,136.8,136.8,0,0,1,45,94.6C68.1,71.6,96,60,128,60s59.9,11.6,83,34.6A136.8,136.8,0,0,1,235.6,128,136.8,136.8,0,0,1,211,161.4ZM128,84a44,44,0,1,0,44,44A44,44,0,0,0,128,84Zm0,80a36,36,0,1,1,36-36A36,36,0,0,1,128,164Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M247.3,124.8c-.3-.8-8.8-19.6-27.6-38.5C194.6,61.3,162.9,48,128,48S61.4,61.3,36.3,86.3C17.5,105.2,9,124,8.7,124.8a7.9,7.9,0,0,0,0,6.4c.3.8,8.8,19.6,27.6,38.5C61.4,194.7,93.1,208,128,208s66.6-13.3,91.7-38.3c18.8-18.9,27.3-37.7,27.6-38.5A7.9,7.9,0,0,0,247.3,124.8ZM128,192c-30.8,0-57.7-11.2-79.9-33.3A130.3,130.3,0,0,1,25,128,130.3,130.3,0,0,1,48.1,97.2C70.3,75.2,97.2,64,128,64s57.7,11.2,79.9,33.2A130.3,130.3,0,0,1,231,128C223.8,141.5,192.4,192,128,192Zm0-112a48,48,0,1,0,48,48A48,48,0,0,0,128,80Zm0,80a32,32,0,1,1,32-32A32.1,32.1,0,0,1,128,160Z" />
        </>
      )}
    </svg>
  );
}

export function PhEyedropperSample(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M228,67.4a40.1,40.1,0,0,0-12.5-28.5c-15.6-14.7-40.8-14.2-56.2,1.2L139.9,59.6a27.9,27.9,0,0,0-35.7,3.2l-7,7.1a19.9,19.9,0,0,0-.8,27.4L48.9,144.9A44.2,44.2,0,0,0,36.6,183l-9.3,21.3A17.5,17.5,0,0,0,31,223.9a19.6,19.6,0,0,0,14.1,5.9,18.9,18.9,0,0,0,8-1.7L73,219.4a44.2,44.2,0,0,0,38.1-12.3l47.6-47.5a19.8,19.8,0,0,0,27.4-.8l7.1-7a27.9,27.9,0,0,0,3.2-35.7l19.9-19.8A40,40,0,0,0,228,67.4ZM136.3,148H79.7l33.7-33.7,28.3,28.3ZM94.1,190.1a19.7,19.7,0,0,1-19.3,5.2,11.9,11.9,0,0,0-7.9.6l-12.1,5.3,5.3-12.1a11.9,11.9,0,0,0,.6-7.9,20.5,20.5,0,0,1-.3-9.2h51.9ZM199.3,79.3l-28,28a12,12,0,0,0,0,17l4.9,4.9a3.9,3.9,0,0,1,0,5.6L172,139,117,84l4.2-4.2a3.9,3.9,0,0,1,5.6,0l4.9,4.9a12,12,0,0,0,17,0l27.6-27.6c6.3-6.3,16.5-6.6,22.7-.7a15.9,15.9,0,0,1,.3,22.9Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M141.3,160l-38.7,38.6a31.7,31.7,0,0,1-30.9,8.3L48.3,217.1a8,8,0,0,1-8.8-1.6,5.7,5.7,0,0,1-1.2-6.4l10.8-24.8A31.9,31.9,0,0,1,52.3,160Z"
            opacity="0.2"
          />
          <path d="M224,67.5a35.5,35.5,0,0,0-11.3-25.7c-14-13.2-36.7-12.7-50.6,1.2L140.2,64.9a24.1,24.1,0,0,0-33.2.8l-7,7a16.1,16.1,0,0,0,0,22.6l2.1,2.1L51.7,147.7a40.3,40.3,0,0,0-11,35.8l-9.8,22.4a13.6,13.6,0,0,0,2.9,15.2,15.8,15.8,0,0,0,11.3,4.7,16.3,16.3,0,0,0,6.4-1.3l21-9.2a40.3,40.3,0,0,0,35.8-11l50.3-50.4,2.1,2.1a15.9,15.9,0,0,0,22.6,0l7-7a24.1,24.1,0,0,0,.8-33.2l22.4-22.3A36.2,36.2,0,0,0,224,67.5ZM97,193a24.3,24.3,0,0,1-23.2,6.2,8,8,0,0,0-5.3.4L47,209l9.4-21.5a8,8,0,0,0,.4-5.3,23.7,23.7,0,0,1,.6-14.2h64.5Zm40.9-41H70.1l43.3-43.3,33.9,33.9Zm64.2-69.9-28,28a8.1,8.1,0,0,0,0,11.4l4.9,4.8a8.1,8.1,0,0,1,0,11.4l-7,7L111.3,84l7-7a8,8,0,0,1,11.4,0l4.8,4.9a8.1,8.1,0,0,0,11.4,0l27.5-27.6c7.9-7.8,20.6-8.2,28.3-.8A19.7,19.7,0,0,1,208,67.7,19.4,19.4,0,0,1,202.1,82.1Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M224,67.5a35.5,35.5,0,0,0-11.3-25.7c-14-13.2-36.7-12.7-50.6,1.2L140.2,64.9a24.1,24.1,0,0,0-33.2.8l-7,7a16.1,16.1,0,0,0,0,22.6l2.1,2.1L51.7,147.7a40.3,40.3,0,0,0-11,35.8l-9.8,22.4a13.6,13.6,0,0,0,2.9,15.2,15.8,15.8,0,0,0,11.3,4.7,16.3,16.3,0,0,0,6.4-1.3l21-9.2a40.3,40.3,0,0,0,35.8-11l50.3-50.4,2.1,2.1a15.9,15.9,0,0,0,22.6,0l7-7a24.1,24.1,0,0,0,.8-33.2l22.4-22.3A36.2,36.2,0,0,0,224,67.5ZM137.9,152H70.1l43.3-43.3,33.9,33.9Zm64.2-69.9-28,28a8.1,8.1,0,0,0,0,11.4l4.9,4.8a8.1,8.1,0,0,1,0,11.4l-7,7L111.3,84l7-7a8,8,0,0,1,11.4,0l4.8,4.9a8.1,8.1,0,0,0,11.4,0l27.5-27.6c7.9-7.8,20.6-8.2,28.3-.8A19.7,19.7,0,0,1,208,67.7,19.4,19.4,0,0,1,202.1,82.1Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M222,67.5a33.8,33.8,0,0,0-10.6-24.2c-13.3-12.5-34.7-12.1-47.9,1.1L140.2,67.7l-.6-.6a22.1,22.1,0,0,0-31.2,0l-7,7a14.1,14.1,0,0,0,0,19.8l3.5,3.5L53.1,149.1a38.3,38.3,0,0,0-10.3,34.6l-10,23a11.6,11.6,0,0,0,2.4,13,14,14,0,0,0,9.9,4.1,13.8,13.8,0,0,0,5.6-1.2l21.6-9.4a38.3,38.3,0,0,0,34.6-10.3l51.7-51.8,3.5,3.5a14,14,0,0,0,19.8,0l7-7a22.1,22.1,0,0,0,0-31.2l-.6-.6L212,92A33.5,33.5,0,0,0,222,67.5ZM98.4,194.4a26.2,26.2,0,0,1-25.1,6.7,6.9,6.9,0,0,0-4,.3L45.9,211.6a2,2,0,0,1-2.1-.3l10.8-24.6a6.9,6.9,0,0,0,.3-4A25.8,25.8,0,0,1,56,166h70.8ZM138.8,154H65.2l48.2-48.1,36.7,36.7Zm64.8-70.4-28,28a5.8,5.8,0,0,0,0,8.4l4.8,4.9a10.1,10.1,0,0,1,0,14.2l-7,7a1.9,1.9,0,0,1-2.8,0L109.9,85.4a1.9,1.9,0,0,1,0-2.8l7-7a9.9,9.9,0,0,1,14.2,0l4.9,4.8a5.8,5.8,0,0,0,8.4,0L172,52.9c8.6-8.6,22.5-9,31.1-.9A21.7,21.7,0,0,1,210,67.7,22,22,0,0,1,203.6,83.6Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M220,67.6a31.9,31.9,0,0,0-10-22.9c-12.5-11.7-32.7-11.3-45,1.1L140.2,70.5l-2.1-2a19.9,19.9,0,0,0-28.2,0l-7.1,7a12.2,12.2,0,0,0,0,17l4.9,4.9L54.5,150.5a36.2,36.2,0,0,0-9.6,33.4L34.6,207.5a9.8,9.8,0,0,0,2,10.8,12,12,0,0,0,8.5,3.5,11.5,11.5,0,0,0,4.8-1l22.2-9.7a36.2,36.2,0,0,0,33.4-9.6l53.1-53.2,4.9,4.9a12.1,12.1,0,0,0,17,0l7-7.1a19.9,19.9,0,0,0,0-28.2l-2-2.1,25.1-25.2A31.5,31.5,0,0,0,220,67.6ZM99.8,195.8a28.4,28.4,0,0,1-27.1,7.3,3.9,3.9,0,0,0-2.6.1L46.7,213.5a4,4,0,0,1-4.4-.9,1.7,1.7,0,0,1-.4-1.9l10.9-24.8a4.6,4.6,0,0,0,.2-2.6A27.8,27.8,0,0,1,54.7,164h76.9ZM139.6,156H60.4l53-53L153,142.6ZM177,113a3.9,3.9,0,0,0,0,5.6l4.9,4.9a12.2,12.2,0,0,1,0,17l-7.1,7a4,4,0,0,1-5.6,0L108.5,86.8a3.9,3.9,0,0,1,0-5.6l7-7.1a12.1,12.1,0,0,1,17,0l4.9,4.9a3.9,3.9,0,0,0,5.6,0l27.6-27.6c9.3-9.3,24.5-9.7,33.9-.8A24,24,0,0,1,205,85Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M224,67.5a35.5,35.5,0,0,0-11.3-25.7c-14-13.2-36.7-12.7-50.6,1.2L140.2,64.9a24.1,24.1,0,0,0-33.2.8l-7,7a16.1,16.1,0,0,0,0,22.6l2.1,2.1L51.7,147.7a40.3,40.3,0,0,0-11,35.8l-9.8,22.4a13.6,13.6,0,0,0,2.9,15.2,15.8,15.8,0,0,0,11.3,4.7,16.3,16.3,0,0,0,6.4-1.3l21-9.2a40.3,40.3,0,0,0,35.8-11l50.3-50.4,2.1,2.1a15.9,15.9,0,0,0,22.6,0l7-7a24.1,24.1,0,0,0,.8-33.2l22.4-22.3A36.2,36.2,0,0,0,224,67.5ZM97,193a24.3,24.3,0,0,1-23.2,6.2,8,8,0,0,0-5.3.4L47,209l9.4-21.5a8,8,0,0,0,.4-5.3,23.7,23.7,0,0,1,.6-14.2h64.5Zm40.9-41H70.1l43.3-43.3,33.9,33.9Zm64.2-69.9-28,28a8.1,8.1,0,0,0,0,11.4l4.9,4.8a8.1,8.1,0,0,1,0,11.4l-7,7L111.3,84l7-7a8,8,0,0,1,11.4,0l4.8,4.9a8.1,8.1,0,0,0,11.4,0l27.5-27.6c7.9-7.8,20.6-8.2,28.3-.8A19.7,19.7,0,0,1,208,67.7,19.4,19.4,0,0,1,202.1,82.1Z" />
        </>
      )}
    </svg>
  );
}

export function PhEyedropper(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M45.1,229.8A19.6,19.6,0,0,1,31,223.9a17.5,17.5,0,0,1-3.7-19.6L36.6,183a44.2,44.2,0,0,1,12.3-38.1l56-56a12,12,0,0,1,17,0,12.2,12.2,0,0,1,0,17l-56,56a19.7,19.7,0,0,0-5.2,19.3,11.9,11.9,0,0,1-.6,7.9l-5.3,12.1,12.1-5.3a11.9,11.9,0,0,1,7.9-.6,19.7,19.7,0,0,0,19.3-5.2l56-56a12.2,12.2,0,0,1,17,0,12,12,0,0,1,0,17l-56,56A44.2,44.2,0,0,1,73,219.4l-19.9,8.7A18.9,18.9,0,0,1,45.1,229.8Z" />
          <path d="M172,164.7a19.7,19.7,0,0,1-14.1-5.9L97.2,98.1a19.9,19.9,0,0,1,0-28.2l15-15.1a27.9,27.9,0,0,1,35.7-3.2l11.4-11.5c15.4-15.4,40.6-15.9,56.2-1.2a40.1,40.1,0,0,1,.8,57.4l-11.9,11.8a27.9,27.9,0,0,1-3.2,35.7l-15.1,15A19.7,19.7,0,0,1,172,164.7ZM117,84l55,55,12.2-12.2a3.9,3.9,0,0,0,0-5.6l-4.9-4.9a12,12,0,0,1,0-17l20-20a15.9,15.9,0,0,0-.3-22.9c-6.2-5.9-16.4-5.6-22.7.7L156.7,76.7a12.1,12.1,0,0,1-17,0l-4.9-4.9a3.9,3.9,0,0,0-5.6,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M179.8,115.8l4.9,4.9a16.1,16.1,0,0,1,0,22.6l-7,7a8,8,0,0,1-11.4,0L105.7,89.7a8,8,0,0,1,0-11.4l7-7a16.1,16.1,0,0,1,22.6,0l4.9,4.9,27.6-27.6c10.8-10.8,28.4-11.4,39.4-.9a28,28,0,0,1,.6,40.1Z" />
          </g>
          <path d="M224,67.5a35.5,35.5,0,0,0-11.3-25.7c-14-13.2-36.7-12.7-50.6,1.2L140.2,64.9a24.1,24.1,0,0,0-33.2.8l-7,7a16.1,16.1,0,0,0,0,22.6l2.1,2.1L51.7,147.7a40.3,40.3,0,0,0-11,35.8l-9.8,22.4a13.6,13.6,0,0,0,2.9,15.2,15.8,15.8,0,0,0,11.3,4.7,16.3,16.3,0,0,0,6.4-1.3l21-9.2a40.3,40.3,0,0,0,35.8-11l50.3-50.4,2.1,2.1a15.9,15.9,0,0,0,22.6,0l7-7a24.1,24.1,0,0,0,.8-33.2l22.4-22.3A36.2,36.2,0,0,0,224,67.5ZM97,193a24.3,24.3,0,0,1-23.2,6.2,8,8,0,0,0-5.3.4L47,209l9.4-21.5a8,8,0,0,0,.4-5.3A24.3,24.3,0,0,1,63,159l50.4-50.3,33.9,33.9ZM202.1,82.1l-28,28a8.1,8.1,0,0,0,0,11.4l4.9,4.8a8.1,8.1,0,0,1,0,11.4l-7,7L111.3,84l7-7a8,8,0,0,1,11.4,0l4.8,4.9a8.2,8.2,0,0,0,11.4,0l27.5-27.6c7.9-7.8,20.6-8.2,28.3-.8A19.7,19.7,0,0,1,208,67.7,19.4,19.4,0,0,1,202.1,82.1Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M224,67.5a35.5,35.5,0,0,0-11.3-25.7c-14-13.2-36.7-12.7-50.6,1.2L140.2,64.9a24.1,24.1,0,0,0-33.2.8l-7,7a16.1,16.1,0,0,0,0,22.6l2.1,2.1L51.7,147.7a40.3,40.3,0,0,0-11,35.8l-9.8,22.4a13.6,13.6,0,0,0,2.9,15.2,15.8,15.8,0,0,0,11.3,4.7,16.3,16.3,0,0,0,6.4-1.3l21-9.2a40.3,40.3,0,0,0,35.8-11l50.3-50.4,2.1,2.1a15.9,15.9,0,0,0,22.6,0l7-7a24.1,24.1,0,0,0,.8-33.2l22.4-22.3A36.2,36.2,0,0,0,224,67.5ZM97,193a24.3,24.3,0,0,1-23.2,6.2,8,8,0,0,0-5.3.4L47,209l9.4-21.5a8,8,0,0,0,.4-5.3A24.3,24.3,0,0,1,63,159l50.4-50.3,33.9,33.9Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M222,67.5a33.8,33.8,0,0,0-10.6-24.2c-13.3-12.5-34.7-12.1-47.9,1.1L140.2,67.7l-.6-.6a22.1,22.1,0,0,0-31.2,0l-7,7a14.1,14.1,0,0,0,0,19.8l3.5,3.5L53.1,149.1a38.3,38.3,0,0,0-10.3,34.6l-10,23a11.6,11.6,0,0,0,2.4,13,14,14,0,0,0,9.9,4.1,13.8,13.8,0,0,0,5.6-1.2l21.6-9.4a38.3,38.3,0,0,0,34.6-10.3l51.7-51.8,3.5,3.5a14,14,0,0,0,19.8,0l7-7a22.1,22.1,0,0,0,0-31.2l-.6-.6L212,92A33.5,33.5,0,0,0,222,67.5ZM98.4,194.4a26.2,26.2,0,0,1-25.1,6.7,6.9,6.9,0,0,0-4,.3L45.9,211.6a2,2,0,0,1-2.1-.3l10.8-24.6a6.9,6.9,0,0,0,.3-4,26.2,26.2,0,0,1,6.7-25.1l51.8-51.7,36.7,36.7ZM203.6,83.6l-28,28a5.8,5.8,0,0,0,0,8.4l4.8,4.9a10.1,10.1,0,0,1,0,14.2l-7,7a1.9,1.9,0,0,1-2.8,0L109.9,85.4a1.9,1.9,0,0,1,0-2.8l7-7a9.9,9.9,0,0,1,14.2,0l4.9,4.8a5.8,5.8,0,0,0,8.4,0L172,52.9c8.6-8.6,22.5-9,31.1-.9A21.7,21.7,0,0,1,210,67.7,22,22,0,0,1,203.6,83.6Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M220,67.6a31.9,31.9,0,0,0-10-22.9c-12.5-11.7-32.7-11.3-45,1.1L140.2,70.5l-2.1-2a19.9,19.9,0,0,0-28.2,0l-7.1,7a12,12,0,0,0,0,17l4.9,4.9L54.5,150.5a36.2,36.2,0,0,0-9.6,33.4L34.6,207.5a9.8,9.8,0,0,0,2,10.8,12,12,0,0,0,8.5,3.5,11.5,11.5,0,0,0,4.8-1l22.2-9.7a36.2,36.2,0,0,0,33.4-9.6l53.1-53.2,4.9,4.9a12.1,12.1,0,0,0,17,0l7-7.1a19.9,19.9,0,0,0,0-28.2l-2-2.1,25.1-25.2A31.5,31.5,0,0,0,220,67.6ZM99.8,195.8A28.2,28.2,0,0,1,72.7,203a4.6,4.6,0,0,0-2.6.2L46.7,213.5a4,4,0,0,1-4.4-.9,1.7,1.7,0,0,1-.4-1.9l10.9-24.8a4.6,4.6,0,0,0,.2-2.6,28.2,28.2,0,0,1,7.2-27.1L113.4,103,153,142.6ZM177,113a3.9,3.9,0,0,0,0,5.6l4.9,4.9a12.2,12.2,0,0,1,0,17l-7.1,7a3.9,3.9,0,0,1-5.6,0L108.5,86.8a3.9,3.9,0,0,1,0-5.6l7-7.1a12.1,12.1,0,0,1,17,0l4.9,4.9a3.9,3.9,0,0,0,5.6,0l27.6-27.6c9.3-9.3,24.5-9.7,33.9-.8A24,24,0,0,1,205,85Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M224,67.5a35.5,35.5,0,0,0-11.3-25.7c-14-13.2-36.7-12.7-50.6,1.2L140.2,64.9a24.1,24.1,0,0,0-33.2.8l-7,7a16.1,16.1,0,0,0,0,22.6l2.1,2.1L51.7,147.7a40.3,40.3,0,0,0-11,35.8l-9.8,22.4a13.6,13.6,0,0,0,2.9,15.2,15.8,15.8,0,0,0,11.3,4.7,16.3,16.3,0,0,0,6.4-1.3l21-9.2a40.3,40.3,0,0,0,35.8-11l50.3-50.4,2.1,2.1a15.9,15.9,0,0,0,22.6,0l7-7a24.1,24.1,0,0,0,.8-33.2l22.4-22.3A36.2,36.2,0,0,0,224,67.5ZM97,193a24.3,24.3,0,0,1-23.2,6.2,8,8,0,0,0-5.3.4L47,209l9.4-21.5a8,8,0,0,0,.4-5.3A24.3,24.3,0,0,1,63,159l50.4-50.3,33.9,33.9ZM202.1,82.1l-28,28a8.1,8.1,0,0,0,0,11.4l4.9,4.8a8.1,8.1,0,0,1,0,11.4l-7,7L111.3,84l7-7a8,8,0,0,1,11.4,0l4.8,4.9a8.2,8.2,0,0,0,11.4,0l27.5-27.6c7.9-7.8,20.6-8.2,28.3-.8A19.7,19.7,0,0,1,208,67.7,19.4,19.4,0,0,1,202.1,82.1Z" />
        </>
      )}
    </svg>
  );
}

export function PhEyeglasses(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M200,36a12,12,0,0,0,0,24,12,12,0,0,1,12,12v50.5A47.8,47.8,0,0,0,142.8,148H113.2A47.8,47.8,0,0,0,44,122.5V72A12,12,0,0,1,56,60a12,12,0,0,0,0-24A36,36,0,0,0,20,72v92a48,48,0,0,0,95.3,8h25.4a48,48,0,0,0,95.3-8V72A36,36,0,0,0,200,36ZM68,188a24,24,0,1,1,24-24A24.1,24.1,0,0,1,68,188Zm120,0a24,24,0,1,1,24-24A24.1,24.1,0,0,1,188,188Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M104,164a36,36,0,1,1-36-36A36,36,0,0,1,104,164Zm84-36a36,36,0,1,0,36,36A36,36,0,0,0,188,128Z"
            opacity="0.2"
          />
          <path d="M200,40a8,8,0,0,0,0,16,16,16,0,0,1,16,16v58.1A43.9,43.9,0,0,0,145.7,152H110.3A43.9,43.9,0,0,0,40,130.1V72A16,16,0,0,1,56,56a8,8,0,0,0,0-16A32.1,32.1,0,0,0,24,72v92a44,44,0,0,0,87.8,4h32.4a44,44,0,0,0,87.8-4V72A32.1,32.1,0,0,0,200,40ZM68,192a28,28,0,1,1,28-28A28.1,28.1,0,0,1,68,192Zm120,0a28,28,0,1,1,28-28A28.1,28.1,0,0,1,188,192Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M232,72v92a44,44,0,0,1-87.8,4H111.8A44,44,0,0,1,24,164V72A32.1,32.1,0,0,1,56,40a8,8,0,0,1,0,16A16,16,0,0,0,40,72v58.1A43.9,43.9,0,0,1,110.3,152h35.4A43.9,43.9,0,0,1,216,130.1V72a16,16,0,0,0-16-16,8,8,0,0,1,0-16A32.1,32.1,0,0,1,232,72Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M200,42a6,6,0,0,0,0,12,18.1,18.1,0,0,1,18,18v62.6A42,42,0,0,0,147.2,154H108.8A42,42,0,0,0,38,134.6V72A18.1,18.1,0,0,1,56,54a6,6,0,0,0,0-12A30.1,30.1,0,0,0,26,72v92a42,42,0,0,0,83.9,2h36.2a42,42,0,0,0,83.9-2V72A30.1,30.1,0,0,0,200,42ZM68,194a30,30,0,1,1,30-30A30.1,30.1,0,0,1,68,194Zm120,0a30,30,0,1,1,30-30A30.1,30.1,0,0,1,188,194Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M200,44a4,4,0,0,0,0,8,20.1,20.1,0,0,1,20,20v68a40,40,0,0,0-71.2,16H107.2A40,40,0,0,0,36,140V72A20.1,20.1,0,0,1,56,52a4,4,0,0,0,0-8A28.1,28.1,0,0,0,28,72v92a40,40,0,0,0,80,0h40a40,40,0,0,0,80,0V72A28.1,28.1,0,0,0,200,44ZM68,196a32,32,0,1,1,32-32A32.1,32.1,0,0,1,68,196Zm120,0a32,32,0,1,1,32-32A32.1,32.1,0,0,1,188,196Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M200,40a8,8,0,0,0,0,16,16,16,0,0,1,16,16v58.1A43.9,43.9,0,0,0,145.7,152H110.3A43.9,43.9,0,0,0,40,130.1V72A16,16,0,0,1,56,56a8,8,0,0,0,0-16A32.1,32.1,0,0,0,24,72v92a44,44,0,0,0,87.8,4h32.4a44,44,0,0,0,87.8-4V72A32.1,32.1,0,0,0,200,40ZM68,192a28,28,0,1,1,28-28A28.1,28.1,0,0,1,68,192Zm120,0a28,28,0,1,1,28-28A28.1,28.1,0,0,1,188,192Z" />
        </>
      )}
    </svg>
  );
}

export function PhFaceMask(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M180,108a12,12,0,0,1-12,12H88a12,12,0,0,1,0-24h80A12,12,0,0,1,180,108Zm-12,24H88a12,12,0,0,0,0,24h80a12,12,0,0,0,0-24Zm88-28v24a36,36,0,0,1-36,36h-6.2c-6.4,13.8-17.9,26-34.1,36.1-19.1,11.8-39.3,17.3-47.1,19.2a21.4,21.4,0,0,1-9.2,0c-12-2.8-63.5-17-81.3-55.3H36A36,36,0,0,1,0,128V104A36,36,0,0,1,36,68h2.3a20.2,20.2,0,0,1,10.3-9.2l72-28.8a19.9,19.9,0,0,1,14.8,0l72,28.8A20.2,20.2,0,0,1,217.7,68H220A36,36,0,0,1,256,104ZM36.1,140c0-1.3-.1-2.6-.1-4V92a12,12,0,0,0-12,12v24a12,12,0,0,0,12,12Zm157.5,10.2a10.8,10.8,0,0,1,1-3.5A41.8,41.8,0,0,0,196,136V80.1L128,52.9,60,80.1V136a41.8,41.8,0,0,0,1.4,10.7,10.8,10.8,0,0,1,1,3.5c4,11.1,12.9,21,26.5,29.4,15.7,9.8,32.9,14.6,39.1,16.1,6.2-1.5,23.3-6.3,39.1-16.1C180.7,171.2,189.6,161.3,193.6,150.2ZM232,104a12,12,0,0,0-12-12v44c0,1.3,0,2.7-.1,4h.1a12,12,0,0,0,12-12Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M216,136c0,51.5-74.4,69.2-86.4,71.7a8.6,8.6,0,0,1-3.2,0C114.4,205.2,40,187.5,40,136V77.6a7.9,7.9,0,0,1,5.3-7.5l80-29.1a8.3,8.3,0,0,1,5.4,0l80,29.1a7.9,7.9,0,0,1,5.3,7.5Z" />
          </g>
          <path d="M176,112a8,8,0,0,1-8,8H88a8,8,0,0,1,0-16h80A8,8,0,0,1,176,112Zm-8,24H88a8,8,0,0,0,0,16h80a8,8,0,0,0,0-16Zm88-32v24a32.1,32.1,0,0,1-32,32h-5.2c-6.6,14.4-19.3,27-37.6,37.2-21.1,11.7-43.6,17-49.9,18.3l-3.3.3-3.3-.3c-6.3-1.3-28.8-6.6-49.9-18.3C56.5,187,43.8,174.4,37.2,160H32A32.1,32.1,0,0,1,0,128V104A32.1,32.1,0,0,1,32,72h1a16,16,0,0,1,9.5-9.4l80-29.1a16,16,0,0,1,11,0l80,29.1A16,16,0,0,1,223,72h1A32.1,32.1,0,0,1,256,104ZM32.6,144a52.9,52.9,0,0,1-.6-8V88a16,16,0,0,0-16,16v24a16,16,0,0,0,16,16ZM208,136V77.6L128,48.5,48,77.6V136c0,45.8,71.8,62.1,80,63.8C136.2,198.1,208,181.8,208,136Zm32-32a16,16,0,0,0-16-16v48a52.9,52.9,0,0,1-.6,8h.6a16,16,0,0,0,16-16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M224,72h-1a16,16,0,0,0-9.5-9.4l-80-29.1a16,16,0,0,0-11,0l-80,29.1A16,16,0,0,0,33,72H32A32.1,32.1,0,0,0,0,104v24a32.1,32.1,0,0,0,32,32h5.2c6.6,14.4,19.3,27,37.6,37.2,21.1,11.7,43.6,17,49.9,18.3l3.3.3,3.3-.3c6.3-1.3,28.8-6.6,49.9-18.3,18.3-10.2,31-22.8,37.6-37.2H224a32.1,32.1,0,0,0,32-32V104A32.1,32.1,0,0,0,224,72ZM32,144a16,16,0,0,1-16-16V104A16,16,0,0,1,32,88v48a52.9,52.9,0,0,0,.6,8Zm136,8H88a8,8,0,0,1,0-16h80a8,8,0,0,1,0,16Zm0-32H88a8,8,0,0,1,0-16h80a8,8,0,0,1,0,16Zm72,8a16,16,0,0,1-16,16h-.6a52.9,52.9,0,0,0,.6-8V88a16,16,0,0,1,16,16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M174,112a6,6,0,0,1-6,6H88a6,6,0,0,1,0-12h80A6,6,0,0,1,174,112Zm-6,26H88a6,6,0,0,0,0,12h80a6,6,0,0,0,0-12Zm86-34v24a30.1,30.1,0,0,1-30,30h-6.5c-6.2,14.5-18.8,27.2-37.3,37.5-20.8,11.5-43,16.7-49.4,18.1l-2.8.2-2.8-.2c-6.4-1.4-28.6-6.6-49.4-18.1-18.5-10.3-31.1-23-37.3-37.5H32A30.1,30.1,0,0,1,2,128V104A30.1,30.1,0,0,1,32,74h2.5a14.4,14.4,0,0,1,8.7-9.6l80-29a13.3,13.3,0,0,1,9.6,0l80,29a14.4,14.4,0,0,1,8.7,9.6H224A30.1,30.1,0,0,1,254,104ZM34.9,146a55.5,55.5,0,0,1-.9-10V86H32a18.1,18.1,0,0,0-18,18v24a18.1,18.1,0,0,0,18,18ZM210,136V77.6a2.1,2.1,0,0,0-1.3-1.9l-80-29.1h-1.4l-80,29.1A2.1,2.1,0,0,0,46,77.6V136c0,47.3,73.3,64.1,81.6,65.8h.8C136.7,200.1,210,183.3,210,136Zm32-32a18.1,18.1,0,0,0-18-18h-2v50a55.5,55.5,0,0,1-.9,10H224a18.1,18.1,0,0,0,18-18Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M172,112a4,4,0,0,1-4,4H88a4,4,0,0,1,0-8h80A4,4,0,0,1,172,112Zm-4,28H88a4,4,0,0,0,0,8h80a4,4,0,0,0,0-8Zm84-36v24a28.1,28.1,0,0,1-28,28h-7.9c-5.8,14.6-18.3,27.4-36.9,37.7-20.5,11.4-42.5,16.6-48.8,17.9a14.5,14.5,0,0,1-4.8,0c-6.3-1.3-28.3-6.5-48.8-17.9C58.2,183.4,45.7,170.6,39.9,156H32A28.1,28.1,0,0,1,4,128V104A28.1,28.1,0,0,1,32,76h4.1a12.2,12.2,0,0,1,7.8-9.7l80-29.1a12.8,12.8,0,0,1,8.2,0l80,29.1a12.2,12.2,0,0,1,7.8,9.7H224A28.1,28.1,0,0,1,252,104ZM37.4,148A53.2,53.2,0,0,1,36,136V84H32a20.1,20.1,0,0,0-20,20v24a20.1,20.1,0,0,0,20,20ZM212,136V77.6a4.1,4.1,0,0,0-2.6-3.8l-80-29a3.4,3.4,0,0,0-2.8,0l-80,29A4.1,4.1,0,0,0,44,77.6V136c0,20.1,12.3,37.2,36.7,50.7,19.5,10.9,40.5,15.8,46.5,17.1h1.6c6-1.3,27-6.2,46.5-17.1C199.7,173.2,212,156.1,212,136Zm32-32a20.1,20.1,0,0,0-20-20h-4v52a53.2,53.2,0,0,1-1.4,12H224a20.1,20.1,0,0,0,20-20Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M176,112a8,8,0,0,1-8,8H88a8,8,0,0,1,0-16h80A8,8,0,0,1,176,112Zm-8,24H88a8,8,0,0,0,0,16h80a8,8,0,0,0,0-16Zm88-32v24a32.1,32.1,0,0,1-32,32h-5.2c-6.6,14.4-19.3,27-37.6,37.2-21.1,11.7-43.6,17-49.9,18.3l-3.3.3-3.3-.3c-6.3-1.3-28.8-6.6-49.9-18.3C56.5,187,43.8,174.4,37.2,160H32A32.1,32.1,0,0,1,0,128V104A32.1,32.1,0,0,1,32,72h1a16,16,0,0,1,9.5-9.4l80-29.1a16,16,0,0,1,11,0l80,29.1A16,16,0,0,1,223,72h1A32.1,32.1,0,0,1,256,104ZM32.6,144a52.9,52.9,0,0,1-.6-8V88a16,16,0,0,0-16,16v24a16,16,0,0,0,16,16ZM208,136V77.6L128,48.5,48,77.6V136c0,45.8,71.8,62.1,80,63.8C136.2,198.1,208,181.8,208,136Zm32-32a16,16,0,0,0-16-16v48a52.9,52.9,0,0,1-.6,8h.6a16,16,0,0,0,16-16Z" />
        </>
      )}
    </svg>
  );
}

export function PhFacebookLogo(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M236,128A108,108,0,1,0,128,236h0A108.1,108.1,0,0,0,236,128Zm-96,83.1V156h20a12,12,0,0,0,0-24H140V112a12,12,0,0,1,12-12h16a12,12,0,0,0,0-24H152a36,36,0,0,0-36,36v20H96a12,12,0,0,0,0,24h20v55.1a84,84,0,1,1,24,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="128" cy="128" r="96" />
          </g>
          <path d="M232,128A104,104,0,1,0,128,232h0A104.1,104.1,0,0,0,232,128Zm-96,87.6V152h24a8,8,0,0,0,0-16H136V112a16,16,0,0,1,16-16h16a8,8,0,0,0,0-16H152a32.1,32.1,0,0,0-32,32v24H96a8,8,0,0,0,0,16h24v63.6a88,88,0,1,1,16,0Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M232,128a104.3,104.3,0,0,1-91.5,103.3,4.1,4.1,0,0,1-4.5-4V152h24a8,8,0,0,0,8-8.5,8.2,8.2,0,0,0-8.3-7.5H136V112a16,16,0,0,1,16-16h16a8,8,0,0,0,8-8.5,8.2,8.2,0,0,0-8.3-7.5H152a32,32,0,0,0-32,32v24H96a8,8,0,0,0-8,8.5,8.2,8.2,0,0,0,8.3,7.5H120v75.3a4,4,0,0,1-4.4,4C62.8,224.9,22,179,24.1,124.1A104,104,0,0,1,232,128Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M230,128A102,102,0,1,0,128,230h0A102.1,102.1,0,0,0,230,128Zm-96,89.8V150h26a6,6,0,0,0,0-12H134V112a18.1,18.1,0,0,1,18-18h16a6,6,0,0,0,0-12H152a30.1,30.1,0,0,0-30,30v26H96a6,6,0,0,0,0,12h26v67.8a90,90,0,1,1,12,0Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M228,128A100,100,0,1,0,128,228h0A100.2,100.2,0,0,0,228,128Zm-96,91.9V148h28a4,4,0,0,0,0-8H132V112a20.1,20.1,0,0,1,20-20h16a4,4,0,0,0,0-8H152a28.1,28.1,0,0,0-28,28v28H96a4,4,0,0,0,0,8h28v71.9a92,92,0,1,1,8,0Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M232,128A104,104,0,1,0,128,232h0A104.1,104.1,0,0,0,232,128Zm-96,87.6V152h24a8,8,0,0,0,0-16H136V112a16,16,0,0,1,16-16h16a8,8,0,0,0,0-16H152a32.1,32.1,0,0,0-32,32v24H96a8,8,0,0,0,0,16h24v63.6a88,88,0,1,1,16,0Z" />
        </>
      )}
    </svg>
  );
}

export function PhFactory(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M120,176a12,12,0,0,1-12,12H80a12,12,0,0,1,0-24h28A12,12,0,0,1,120,176Zm56-12H148a12,12,0,0,0,0,24h28a12,12,0,0,0,0-24Zm76,52a12,12,0,0,1-12,12H16a12,12,0,0,1,0-24H28V88a12,12,0,0,1,19.2-9.6L92,112V88a12,12,0,0,1,19.2-9.6l33.3,25,10.6-74.2A20.2,20.2,0,0,1,174.9,12h18.2a20.2,20.2,0,0,1,19.8,17.2l15,105.1v.2a1.3,1.3,0,0,0,.1.6V204h12A12,12,0,0,1,252,216Zm-85.6-96.2L172,124h30.2L189.6,36H178.4ZM52,204H204V148H168a11.8,11.8,0,0,1-7.2-2.4l-14.4-10.8h0L116,112v24a12,12,0,0,1-19.2,9.6L52,112Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M216,136v80H40V88l64,48V88l64,48Z" opacity="0.2" />
          <path d="M116,176a8,8,0,0,1-8,8H80a8,8,0,0,1,0-16h28A8,8,0,0,1,116,176Zm60-8H148a8,8,0,0,0,0,16h28a8,8,0,0,0,0-16Zm72,48a8,8,0,0,1-8,8H16a8,8,0,0,1,0-16H32V88a8,8,0,0,1,12.8-6.4L96,120V88a8,8,0,0,1,12.8-6.4l38.7,29.1,11.6-81A16,16,0,0,1,174.9,16h18.2a16,16,0,0,1,15.8,13.7l15,105.2h0a.8.8,0,0,1,.1.4v.5h0v72h16A8,8,0,0,1,248,216Zm-85.9-94.4,8.6,6.4h36.1L193.1,32H174.9ZM48,208H208V144H168a8.4,8.4,0,0,1-4.8-1.6l-14.4-10.8h0L112,104v32a8,8,0,0,1-12.8,6.4L48,104Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M240,208H224V136h0v-.5a.8.8,0,0,0-.1-.4h0l-15-105.2A16,16,0,0,0,193.1,16H174.9a16,16,0,0,0-15.8,13.7l-11.6,81L108.8,81.6A8,8,0,0,0,96,88v32L44.8,81.6A8,8,0,0,0,32,88V208H16a8,8,0,0,0,0,16H240a8,8,0,0,0,0-16ZM100,184H72a8,8,0,0,1,0-16h28a8,8,0,0,1,0,16Zm84,0H156a8,8,0,0,1,0-16h28a8,8,0,0,1,0,16Zm-13.3-56-8.6-6.4L174.9,32h18.2l13.7,96Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M114,176a6,6,0,0,1-6,6H80a6,6,0,0,1,0-12h28A6,6,0,0,1,114,176Zm62-6H148a6,6,0,0,0,0,12h28a6,6,0,0,0,0-12Zm70,46a6,6,0,0,1-6,6H16a6,6,0,0,1,0-12H34V88a6,6,0,0,1,3.3-5.4,6,6,0,0,1,6.3.6L98,124V88a6,6,0,0,1,3.3-5.4,6,6,0,0,1,6.3.6L149,114.3,161.1,30a14.1,14.1,0,0,1,13.8-12h18.2a14.1,14.1,0,0,1,13.8,12l15,105.2h0a.4.4,0,0,1,.1.3v.3h0v74h18A6,6,0,0,1,246,216Zm-86-93.5,10,7.5h39.1L195,31.7a1.9,1.9,0,0,0-1.9-1.7H174.9a1.9,1.9,0,0,0-1.9,1.7ZM46,210H210V142H168a5.8,5.8,0,0,1-3.6-1.2L150,130h0l-40-30v36a6,6,0,0,1-3.3,5.4,6,6,0,0,1-6.3-.6L46,100Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M112,176a4,4,0,0,1-4,4H80a4,4,0,0,1,0-8h28A4,4,0,0,1,112,176Zm64-4H148a4,4,0,0,0,0,8h28a4,4,0,0,0,0-8Zm68,44a4,4,0,0,1-4,4H16a4,4,0,0,1,0-8H36V88a4.2,4.2,0,0,1,2.2-3.6,4,4,0,0,1,4.2.4L100,128V88a4.2,4.2,0,0,1,2.2-3.6,4,4,0,0,1,4.2.4l44.1,33.1,12.6-87.6A11.9,11.9,0,0,1,174.9,20h18.2a11.9,11.9,0,0,1,11.8,10.3L220,135.4V212h20A4,4,0,0,1,244,216Zm-86.2-92.6,11.5,8.6h42.1L197,31.4a4.1,4.1,0,0,0-3.9-3.4H174.9a4.1,4.1,0,0,0-3.9,3.4ZM44,212H212V140H168a4.1,4.1,0,0,1-2.4-.8L108,96v40a4.2,4.2,0,0,1-2.2,3.6,4,4,0,0,1-4.2-.4L44,96Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M116,176a8,8,0,0,1-8,8H80a8,8,0,0,1,0-16h28A8,8,0,0,1,116,176Zm60-8H148a8,8,0,0,0,0,16h28a8,8,0,0,0,0-16Zm72,48a8,8,0,0,1-8,8H16a8,8,0,0,1,0-16H32V88a8,8,0,0,1,12.8-6.4L96,120V88a8,8,0,0,1,12.8-6.4l38.7,29.1,11.6-81A16,16,0,0,1,174.9,16h18.2a16,16,0,0,1,15.8,13.7l15,105.2h0a.8.8,0,0,1,.1.4v.5h0v72h16A8,8,0,0,1,248,216Zm-85.9-94.4,8.6,6.4h36.1L193.1,32H174.9ZM48,208H208V144H168a8.4,8.4,0,0,1-4.8-1.6l-14.4-10.8h0L112,104v32a8,8,0,0,1-12.8,6.4L48,104Z" />
        </>
      )}
    </svg>
  );
}

export function PhFadersHorizontal(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M144,172a12,12,0,0,1-12,12H40a12,12,0,0,1,0-24h92A12,12,0,0,1,144,172Zm72-12H184V148a12,12,0,0,0-24,0v48a12,12,0,0,0,24,0V184h32a12,12,0,0,0,0-24ZM40,96H68a12,12,0,0,0,0-24H40a12,12,0,0,0,0,24Zm68,24a12,12,0,0,0,12-12V96h96a12,12,0,0,0,0-24H120V60a12,12,0,0,0-24,0v48A12,12,0,0,0,108,120Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M144,172a8,8,0,0,1-8,8H40a8,8,0,0,1,0-16h96A8,8,0,0,1,144,172Zm72-8H176V148a8,8,0,0,0-16,0v48a8,8,0,0,0,16,0V180h40a8,8,0,0,0,0-16ZM40,92H72a8,8,0,0,0,0-16H40a8,8,0,0,0,0,16Zm64,24a8,8,0,0,0,8-8V92H216a8,8,0,0,0,0-16H112V60a8,8,0,0,0-16,0v48A8,8,0,0,0,104,116Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M144,172a8,8,0,0,1-8,8H40a8,8,0,0,1,0-16h96A8,8,0,0,1,144,172Zm72-8H176V148a8,8,0,0,0-16,0v48a8,8,0,0,0,16,0V180h40a8,8,0,0,0,0-16ZM40,92H72a8,8,0,0,0,0-16H40a8,8,0,0,0,0,16Zm64,24a8,8,0,0,0,8-8V92H216a8,8,0,0,0,0-16H112V60a8,8,0,0,0-16,0v48A8,8,0,0,0,104,116Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M142,172a6,6,0,0,1-6,6H40a6,6,0,0,1,0-12h96A6,6,0,0,1,142,172Zm74-6H174V148a6,6,0,0,0-12,0v48a6,6,0,0,0,12,0V178h42a6,6,0,0,0,0-12ZM40,90H72a6,6,0,0,0,0-12H40a6,6,0,0,0,0,12Zm64,24a6,6,0,0,0,6-6V90H216a6,6,0,0,0,0-12H110V60a6,6,0,0,0-12,0v48A6,6,0,0,0,104,114Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M140,172a4,4,0,0,1-4,4H40a4,4,0,0,1,0-8h96A4,4,0,0,1,140,172Zm76-4H172V148a4,4,0,0,0-8,0v48a4,4,0,0,0,8,0V176h44a4,4,0,0,0,0-8ZM40,88H72a4,4,0,0,0,0-8H40a4,4,0,0,0,0,8Zm64,24a4,4,0,0,0,4-4V88H216a4,4,0,0,0,0-8H108V60a4,4,0,0,0-8,0v48A4,4,0,0,0,104,112Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M144,172a8,8,0,0,1-8,8H40a8,8,0,0,1,0-16h96A8,8,0,0,1,144,172Zm72-8H176V148a8,8,0,0,0-16,0v48a8,8,0,0,0,16,0V180h40a8,8,0,0,0,0-16ZM40,92H72a8,8,0,0,0,0-16H40a8,8,0,0,0,0,16Zm64,24a8,8,0,0,0,8-8V92H216a8,8,0,0,0,0-16H112V60a8,8,0,0,0-16,0v48A8,8,0,0,0,104,116Z" />
        </>
      )}
    </svg>
  );
}

export function PhFaders(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M140,124v92a12,12,0,0,1-24,0V124a12,12,0,0,1,24,0Zm60,68a12,12,0,0,0-12,12v12a12,12,0,0,0,24,0V204A12,12,0,0,0,200,192Zm24-40H212V40a12,12,0,0,0-24,0V152H176a12,12,0,0,0,0,24h48a12,12,0,0,0,0-24ZM56,160h0a12,12,0,0,0-12,12v44a12,12,0,0,0,12,12h0a12,12,0,0,0,12-12V172A12,12,0,0,0,56,160Zm24-40H68V40a12,12,0,0,0-24,0v80H32a12,12,0,0,0,0,24H80a12,12,0,0,0,0-24Zm72-48H140V40a12,12,0,0,0-24,0V72H104a12,12,0,0,0,0,24h48a12,12,0,0,0,0-24Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M136,120v96a8,8,0,0,1-16,0V120a8,8,0,0,1,16,0Zm64,72a8,8,0,0,0-8,8v16a8,8,0,0,0,16,0V200A8,8,0,0,0,200,192Zm24-32H208V40a8,8,0,0,0-16,0V160H176a8,8,0,0,0,0,16h48a8,8,0,0,0,0-16ZM56,160a8,8,0,0,0-8,8v48a8,8,0,0,0,16,0V168A8,8,0,0,0,56,160Zm24-32H64V40a8,8,0,0,0-16,0v88H32a8,8,0,0,0,0,16H80a8,8,0,0,0,0-16Zm72-48H136V40a8,8,0,0,0-16,0V80H104a8,8,0,0,0,0,16h48a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M136,120v96a8,8,0,0,1-16,0V120a8,8,0,0,1,16,0Zm64,72a8,8,0,0,0-8,8v16a8,8,0,0,0,16,0V200A8,8,0,0,0,200,192Zm24-32H208V40a8,8,0,0,0-16,0V160H176a8,8,0,0,0,0,16h48a8,8,0,0,0,0-16ZM56,160a8,8,0,0,0-8,8v48a8,8,0,0,0,16,0V168A8,8,0,0,0,56,160Zm24-32H64V40a8,8,0,0,0-16,0v88H32a8,8,0,0,0,0,16H80a8,8,0,0,0,0-16Zm72-48H136V40a8,8,0,0,0-16,0V80H104a8,8,0,0,0,0,16h48a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M134,120v96a6,6,0,0,1-12,0V120a6,6,0,0,1,12,0Zm66,74a6,6,0,0,0-6,6v16a6,6,0,0,0,12,0V200A6,6,0,0,0,200,194Zm24-32H206V40a6,6,0,0,0-12,0V162H176a6,6,0,0,0,0,12h48a6,6,0,0,0,0-12ZM56,162h0a6,6,0,0,0-6,6v48a6,6,0,0,0,6,6h0a6,6,0,0,0,6-6V168A6,6,0,0,0,56,162Zm24-32H62V40a6,6,0,0,0-12,0v90H32a6,6,0,0,0,0,12H80a6,6,0,0,0,0-12Zm72-48H134V40a6,6,0,0,0-12,0V82H104a6,6,0,0,0,0,12h48a6,6,0,0,0,0-12Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M132,120v96a4,4,0,0,1-8,0V120a4,4,0,0,1,8,0Zm68,76a4,4,0,0,0-4,4v16a4,4,0,0,0,8,0V200A4,4,0,0,0,200,196Zm24-32H204V40a4,4,0,0,0-8,0V164H176a4,4,0,0,0,0,8h48a4,4,0,0,0,0-8ZM56,164a4,4,0,0,0-4,4v48a4,4,0,0,0,8,0V168A4,4,0,0,0,56,164Zm24-32H60V40a4,4,0,0,0-8,0v92H32a4,4,0,0,0,0,8H80a4,4,0,0,0,0-8Zm72-48H132V40a4,4,0,0,0-8,0V84H104a4,4,0,0,0,0,8h48a4,4,0,0,0,0-8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M136,120v96a8,8,0,0,1-16,0V120a8,8,0,0,1,16,0Zm64,72a8,8,0,0,0-8,8v16a8,8,0,0,0,16,0V200A8,8,0,0,0,200,192Zm24-32H208V40a8,8,0,0,0-8-8h0a8,8,0,0,0-8,8V160H176a8,8,0,0,0,0,16h48a8,8,0,0,0,0-16ZM56,160h0a8,8,0,0,0-8,8v48a8,8,0,0,0,8,8h0a8,8,0,0,0,8-8V168A8,8,0,0,0,56,160Zm24-32H64V40a8,8,0,0,0-16,0v88H32a8,8,0,0,0,0,16H80a8,8,0,0,0,0-16Zm72-48H136V40a8,8,0,0,0-16,0V80H104a8,8,0,0,0,0,16h48a8,8,0,0,0,0-16Z" />
        </>
      )}
    </svg>
  );
}

export function PhFastForwardCircle(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,20A108,108,0,1,0,236,128,108.1,108.1,0,0,0,128,20Zm0,192a84,84,0,1,1,84-84A84.1,84.1,0,0,1,128,212Zm64-84a12.1,12.1,0,0,1-4.6,9.5l-36,28A12.7,12.7,0,0,1,144,168a12.4,12.4,0,0,1-5.3-1.2A12,12,0,0,1,132,156V136.9l-.6.6-36,28A12.7,12.7,0,0,1,88,168a12.4,12.4,0,0,1-5.3-1.2A12,12,0,0,1,76,156V100a12,12,0,0,1,6.7-10.8,12.2,12.2,0,0,1,12.7,1.3l36,28,.6.6V100a12,12,0,0,1,6.7-10.8,12.2,12.2,0,0,1,12.7,1.3l36,28A12.1,12.1,0,0,1,192,128Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M128,32a96,96,0,1,0,96,96A96,96,0,0,0,128,32ZM80,160V96l48,32Zm64,0V96l48,32Z" />
          </g>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Z" />
          <path d="M132.4,121.3l-48-32a8.3,8.3,0,0,0-8.2-.4A8,8,0,0,0,72,96v64a8,8,0,0,0,4.2,7.1,8.5,8.5,0,0,0,3.8.9,8.4,8.4,0,0,0,4.4-1.3l48-32a8,8,0,0,0,0-13.4ZM88,145.1V110.9L113.6,128Z" />
          <path d="M196.4,121.3l-48-32a8.3,8.3,0,0,0-8.2-.4A8,8,0,0,0,136,96v64a8,8,0,0,0,4.2,7.1,8.5,8.5,0,0,0,3.8.9,8.4,8.4,0,0,0,4.4-1.3l48-32a8,8,0,0,0,0-13.4ZM152,145.1V110.9L177.6,128Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm56.6,110.6-40,28A8.6,8.6,0,0,1,140,164a8,8,0,0,1-8-8V128a8.2,8.2,0,0,1-3.4,6.6l-40,28A8.6,8.6,0,0,1,84,164a8,8,0,0,1-8-8V100a8.1,8.1,0,0,1,4.3-7.1,8,8,0,0,1,8.3.5l40,28A8.2,8.2,0,0,1,132,128V100a8.1,8.1,0,0,1,4.3-7.1,8,8,0,0,1,8.3.5l40,28a8.1,8.1,0,0,1,0,13.2Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,26A102,102,0,1,0,230,128,102.2,102.2,0,0,0,128,26Zm0,192a90,90,0,1,1,90-90A90.1,90.1,0,0,1,128,218Zm63.3-95-48-32a6,6,0,0,0-9.3,5v26.1L87.3,91A6,6,0,0,0,78,96v64a6,6,0,0,0,3.2,5.3,5.9,5.9,0,0,0,6.1-.3L134,133.9V160a6,6,0,0,0,3.2,5.3,5.9,5.9,0,0,0,6.1-.3l48-32a6,6,0,0,0,0-10ZM90,148.8V107.2L121.2,128Zm56,0V107.2L177.2,128Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,28A100,100,0,1,0,228,128,100.2,100.2,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm6.2-95.3-48-32a4,4,0,0,0-4.1-.2A3.9,3.9,0,0,0,80,96v64a3.9,3.9,0,0,0,2.1,3.5,4.5,4.5,0,0,0,1.9.5,3.6,3.6,0,0,0,2.2-.7l48-32a3.9,3.9,0,0,0,0-6.6ZM88,152.5v-49L124.8,128Zm102.2-27.8-48-32a4,4,0,0,0-4.1-.2A3.9,3.9,0,0,0,136,96v64a3.9,3.9,0,0,0,2.1,3.5,4.5,4.5,0,0,0,1.9.5,3.6,3.6,0,0,0,2.2-.7l48-32a3.9,3.9,0,0,0,0-6.6ZM144,152.5v-49L180.8,128Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm4.4-94.7-48-32a8.3,8.3,0,0,0-8.2-.4A8,8,0,0,0,72,96v64a8,8,0,0,0,4.2,7.1,8.5,8.5,0,0,0,3.8.9,8.4,8.4,0,0,0,4.4-1.3l48-32a8,8,0,0,0,0-13.4ZM88,145.1V110.9L113.6,128Zm108.4-23.8-48-32a8.3,8.3,0,0,0-8.2-.4A8,8,0,0,0,136,96v64a8,8,0,0,0,4.2,7.1,8.5,8.5,0,0,0,3.8.9,8.4,8.4,0,0,0,4.4-1.3l48-32a8,8,0,0,0,0-13.4ZM152,145.1V110.9L177.6,128Z" />
        </>
      )}
    </svg>
  );
}

export function PhFastForward(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M244,111.2,154.8,53.8A20,20,0,0,0,124,70.7v32.7L46.8,53.8A20,20,0,0,0,16,70.7V185.3a20,20,0,0,0,10.4,17.6,20.4,20.4,0,0,0,9.6,2.4,19.9,19.9,0,0,0,10.8-3.1L124,152.6v32.7a20,20,0,0,0,10.4,17.6,20.4,20.4,0,0,0,9.6,2.4,19.9,19.9,0,0,0,10.8-3.1L244,144.8a19.9,19.9,0,0,0,0-33.6ZM40,178V78l77.8,50Zm108,0V78l77.8,50Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M241.5,134.7l-89.2,57.4a8,8,0,0,1-12.3-6.8V70.7a8,8,0,0,1,12.3-6.8l89.2,57.4A7.9,7.9,0,0,1,241.5,134.7Zm-112-13.4L40.3,63.9A8,8,0,0,0,28,70.7V185.3a8,8,0,0,0,12.3,6.8l89.2-57.4A7.9,7.9,0,0,0,129.5,121.3Z"
            opacity="0.2"
          />
          <path d="M245.9,114.5,156.7,57.2A16,16,0,0,0,132,70.7v42.6L44.7,57.2A16,16,0,0,0,20,70.7V185.3a16.1,16.1,0,0,0,8.3,14.1,16.5,16.5,0,0,0,16.4-.6L132,142.7v42.6a16.1,16.1,0,0,0,8.3,14.1,16.5,16.5,0,0,0,16.4-.6l89.2-57.3a16.1,16.1,0,0,0,0-27ZM36,185.3V70.7L125.2,128Zm112,0V70.7L237.2,128Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M253.2,128a16,16,0,0,1-7.3,13.5l-89.2,57.3A16,16,0,0,1,132,185.3V142.7L44.7,198.8A16,16,0,0,1,20,185.3V70.7A16,16,0,0,1,44.7,57.2L132,113.3V70.7a16,16,0,0,1,24.7-13.5l89.2,57.3A16,16,0,0,1,253.2,128Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M244.8,116.2,155.6,58.9A14,14,0,0,0,134,70.7v46.4l-1.2-.9L43.6,58.9A14,14,0,0,0,22,70.7V185.3a13.9,13.9,0,0,0,7.3,12.3,13.4,13.4,0,0,0,6.7,1.7,14,14,0,0,0,7.6-2.2l89.2-57.3,1.2-.9v46.4a13.9,13.9,0,0,0,7.3,12.3,13.4,13.4,0,0,0,6.7,1.7,14,14,0,0,0,7.6-2.2l89.2-57.3a14.1,14.1,0,0,0,0-23.6ZM126.3,129.7,37.1,187a2,2,0,0,1-2.1.1,2.1,2.1,0,0,1-1-1.8V70.7a2.1,2.1,0,0,1,1-1.8,2.9,2.9,0,0,1,1-.3,1.9,1.9,0,0,1,1.1.4l89.2,57.3a2.1,2.1,0,0,1,0,3.4Zm112,0L149.1,187a2,2,0,0,1-2.1.1,2.1,2.1,0,0,1-1-1.8V70.7a2.1,2.1,0,0,1,1-1.8,2.9,2.9,0,0,1,1-.3,1.9,1.9,0,0,1,1.1.4l89.2,57.3a2.1,2.1,0,0,1,0,3.4Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M243.7,117.9,154.5,60.6A11.9,11.9,0,0,0,136,70.7v52.1a11.3,11.3,0,0,0-4.3-4.9L42.5,60.6A11.9,11.9,0,0,0,24,70.7V185.3a12,12,0,0,0,6.2,10.6,12.2,12.2,0,0,0,5.8,1.5,11.8,11.8,0,0,0,6.5-2l89.2-57.3a11.3,11.3,0,0,0,4.3-4.9v52.1a12,12,0,0,0,6.2,10.6,12.2,12.2,0,0,0,5.8,1.5,11.8,11.8,0,0,0,6.5-2l89.2-57.3a12,12,0,0,0,0-20.2ZM127.4,131.4,38.2,188.7a4,4,0,0,1-4.1.2,4.2,4.2,0,0,1-2.1-3.6V70.7a4.2,4.2,0,0,1,2.1-3.6,4,4,0,0,1,1.9-.5,4,4,0,0,1,2.2.7l89.2,57.3a4.1,4.1,0,0,1,0,6.8Zm112,0-89.2,57.3a4,4,0,0,1-4.1.2,4.2,4.2,0,0,1-2.1-3.6V70.7a4.2,4.2,0,0,1,2.1-3.6,4,4,0,0,1,1.9-.5,4,4,0,0,1,2.2.7l89.2,57.3a4.1,4.1,0,0,1,0,6.8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M245.9,114.5,156.7,57.2A16,16,0,0,0,132,70.7v42.6L44.7,57.2A16,16,0,0,0,20,70.7V185.3a16.1,16.1,0,0,0,8.3,14.1,16.5,16.5,0,0,0,16.4-.6L132,142.7v42.6a16.1,16.1,0,0,0,8.3,14.1,16.5,16.5,0,0,0,16.4-.6l89.2-57.3a16.1,16.1,0,0,0,0-27ZM36,185.3V70.7L125.2,128Zm112,0V70.7L237.2,128Z" />
        </>
      )}
    </svg>
  );
}

export function PhFigmaLogo(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M193,94a46,46,0,0,0-31-80H94A46,46,0,0,0,63,94a46,46,0,0,0,0,68,46,46,0,1,0,77,34V168.4a45.2,45.2,0,0,0,22,5.6,46,46,0,0,0,31-80Zm-9-34a22.1,22.1,0,0,1-22,22H140V38h22A22.1,22.1,0,0,1,184,60ZM72,60A22.1,22.1,0,0,1,94,38h22V82H94A22.1,22.1,0,0,1,72,60Zm0,68a22.1,22.1,0,0,1,22-22h22v44H94A22.1,22.1,0,0,1,72,128Zm44,68a22,22,0,1,1-22-22h22Zm46-46a22,22,0,0,1,0-44h0a22,22,0,0,1,0,44Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="162" cy="128" r="34" />
            <path d="M94,94h34V26H94a34,34,0,0,0,0,68Z" />
            <path d="M60,196a34,34,0,0,0,68,0V162H94A34,34,0,0,0,60,196Z" />
          </g>
          <path d="M186.6,94A42,42,0,0,0,162,18H94A42,42,0,0,0,69.4,94a41.9,41.9,0,0,0,0,68A42,42,0,1,0,136,196V160.9A42,42,0,1,0,186.6,94ZM188,60a26.1,26.1,0,0,1-26,26H136V34h26A26.1,26.1,0,0,1,188,60ZM68,60A26.1,26.1,0,0,1,94,34h26V86H94A26.1,26.1,0,0,1,68,60Zm0,68a26.1,26.1,0,0,1,26-26h26v52H94A26.1,26.1,0,0,1,68,128Zm26,94a26,26,0,0,1,0-52h26v26A26.1,26.1,0,0,1,94,222Zm68-68a26,26,0,0,1,0-52h0a26,26,0,0,1,0,52Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M186.6,94A42,42,0,0,0,162,18H94A42,42,0,0,0,69.4,94a41.9,41.9,0,0,0,0,68A42,42,0,1,0,136,196V160.9A42,42,0,1,0,186.6,94ZM188,60a26.1,26.1,0,0,1-26,26H136V34h26A26.1,26.1,0,0,1,188,60Zm-26,94a26,26,0,0,1,0-52h0a26,26,0,0,1,0,52Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M183,94a40,40,0,0,0-21-74H94A40,40,0,0,0,73,94a39.9,39.9,0,0,0,0,68,40,40,0,1,0,61,34V156.5A40,40,0,1,0,183,94Zm7-34a28.1,28.1,0,0,1-28,28H134V32h28A28.1,28.1,0,0,1,190,60ZM66,60A28.1,28.1,0,0,1,94,32h28V88H94A28.1,28.1,0,0,1,66,60Zm0,68a28.1,28.1,0,0,1,28-28h28v56H94A28.1,28.1,0,0,1,66,128Zm28,96a28,28,0,0,1,0-56h28v28A28.1,28.1,0,0,1,94,224Zm68-68a28,28,0,0,1,0-56h0a28,28,0,0,1,0,56Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M178.9,94A38,38,0,0,0,162,22H94A38,38,0,0,0,77.1,94a37.9,37.9,0,0,0,0,68A38,38,0,1,0,132,196V151.3A38,38,0,1,0,178.9,94ZM192,60a30,30,0,0,1-30,30H132V30h30A30.1,30.1,0,0,1,192,60ZM138.7,98a42.5,42.5,0,0,0-6.7,6.7V98ZM64,60A30.1,30.1,0,0,1,94,30h30V90H94A30,30,0,0,1,64,60ZM94,226a30,30,0,0,1,0-60h30v30A30.1,30.1,0,0,1,94,226Zm30-68H94a30,30,0,0,1,0-60h30Zm38,0a30,30,0,0,1,0-60h0a30,30,0,0,1,0,60Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M186.6,94A42,42,0,0,0,162,18H94A42,42,0,0,0,69.4,94a41.9,41.9,0,0,0,0,68A42,42,0,1,0,136,196V160.9A42,42,0,1,0,186.6,94ZM188,60a26.1,26.1,0,0,1-26,26H136V34h26A26.1,26.1,0,0,1,188,60ZM68,60A26.1,26.1,0,0,1,94,34h26V86H94A26.1,26.1,0,0,1,68,60Zm0,68a26.1,26.1,0,0,1,26-26h26v52H94A26.1,26.1,0,0,1,68,128Zm26,94a26,26,0,0,1,0-52h26v26A26.1,26.1,0,0,1,94,222Zm68-68a26,26,0,0,1,0-52h0a26,26,0,0,1,0,52Z" />
        </>
      )}
    </svg>
  );
}

export function PhFileArrowDown(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216.5,79.5l-56-56A12.3,12.3,0,0,0,152,20H56A20.1,20.1,0,0,0,36,40V216a20.1,20.1,0,0,0,20,20H200a20.1,20.1,0,0,0,20-20V88A12.3,12.3,0,0,0,216.5,79.5ZM160,57l23,23H160ZM60,212V44h76V92a12,12,0,0,0,12,12h48V212Zm104.5-64.5a12,12,0,0,1,0,17l-28,28a12.1,12.1,0,0,1-17,0l-28-28a12,12,0,0,1,17-17L116,155V124a12,12,0,0,1,24,0v31l7.5-7.5A12,12,0,0,1,164.5,147.5Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M208,88H152V32Z" opacity="0.2" />
          <path d="M213.7,82.3l-56-56A8.1,8.1,0,0,0,152,24H56A16,16,0,0,0,40,40V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V88A8.1,8.1,0,0,0,213.7,82.3ZM160,51.3,188.7,80H160ZM200,216H56V40h88V88a8,8,0,0,0,8,8h48V216Zm-38.3-65.7a8.1,8.1,0,0,1,0,11.4l-28,28a8.2,8.2,0,0,1-11.4,0l-28-28a8.1,8.1,0,0,1,11.4-11.4L120,164.7V120a8,8,0,0,1,16,0v44.7l14.3-14.4A8.1,8.1,0,0,1,161.7,150.3Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M213.7,82.3l-56-56A8.1,8.1,0,0,0,152,24H56A16,16,0,0,0,40,40V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V88A8.1,8.1,0,0,0,213.7,82.3Zm-52,79.4-28,28a8.2,8.2,0,0,1-11.4,0l-28-28a8.1,8.1,0,0,1,11.4-11.4L120,164.7V120a8,8,0,0,1,16,0v44.7l14.3-14.4a8.1,8.1,0,0,1,11.4,11.4ZM152,88V44l44,44Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M212.2,83.8l-56-56A5.6,5.6,0,0,0,152,26H56A14,14,0,0,0,42,40V216a14,14,0,0,0,14,14H200a14,14,0,0,0,14-14V88A5.6,5.6,0,0,0,212.2,83.8ZM158,46.5,193.5,82H158ZM200,218H56a2,2,0,0,1-2-2V40a2,2,0,0,1,2-2h90V88a6,6,0,0,0,6,6h50V216A2,2,0,0,1,200,218Zm-39.8-66.2a5.8,5.8,0,0,1,0,8.4l-28,28a5.8,5.8,0,0,1-8.4,0l-28-28a5.9,5.9,0,0,1,8.4-8.4L122,169.5V120a6,6,0,0,1,12,0v49.5l17.8-17.7A5.8,5.8,0,0,1,160.2,151.8Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M210.8,85.2l-56-56A3.6,3.6,0,0,0,152,28H56A12,12,0,0,0,44,40V216a12,12,0,0,0,12,12H200a12,12,0,0,0,12-12V88A3.6,3.6,0,0,0,210.8,85.2ZM156,41.7,198.3,84H156ZM200,220H56a4,4,0,0,1-4-4V40a4,4,0,0,1,4-4h92V88a4,4,0,0,0,4,4h52V216A4,4,0,0,1,200,220Zm-41.2-66.8a3.9,3.9,0,0,1,0,5.6l-28,28a3.9,3.9,0,0,1-5.6,0l-28-28a4,4,0,0,1,5.6-5.6L124,174.3V120a4,4,0,0,1,8,0v54.3l21.2-21.1A3.9,3.9,0,0,1,158.8,153.2Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M213.7,82.3l-56-56A8.1,8.1,0,0,0,152,24H56A16,16,0,0,0,40,40V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V88A8.1,8.1,0,0,0,213.7,82.3ZM160,51.3,188.7,80H160ZM200,216H56V40h88V88a8,8,0,0,0,8,8h48V216Zm-38.3-65.7a8.1,8.1,0,0,1,0,11.4l-28,28a8.2,8.2,0,0,1-11.4,0l-28-28a8.1,8.1,0,0,1,11.4-11.4L120,164.7V120a8,8,0,0,1,16,0v44.7l14.3-14.4A8.1,8.1,0,0,1,161.7,150.3Z" />
        </>
      )}
    </svg>
  );
}

export function PhFileArrowUp(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M220,92V88a12.3,12.3,0,0,0-3.5-8.5l-56-56A12.3,12.3,0,0,0,152,20H56A20.1,20.1,0,0,0,36,40V216a20.1,20.1,0,0,0,20,20H200a20.1,20.1,0,0,0,20-20V92ZM183,80H160V57ZM60,212V44h76V92a12,12,0,0,0,12,12h48V212Zm104.5-68.5a12,12,0,0,1,0,17,12.1,12.1,0,0,1-17,0L140,153v31a12,12,0,0,1-24,0V153l-7.5,7.5a12,12,0,0,1-17-17l28-28h0l.9-.8.4-.3.5-.4.6-.3.4-.3.6-.2.5-.3.6-.2.5-.2h.6l.6-.2h4.6l.6.2h.6l.5.2.6.2.5.3.6.2.4.3.6.3.5.4.4.3.9.8h0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M208,88H152V32Z" opacity="0.2" />
          <path d="M216,88a7.7,7.7,0,0,0-2.4-5.7l-55.9-56-.6-.5h-.2l-.5-.3h-.1l-.5-.4h-.1l-.6-.3H155l-.7-.3H56A16,16,0,0,0,40,40V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V88ZM160,51.3,188.7,80H160ZM200,216H56V40h88V88a8,8,0,0,0,8,8h48V216Zm-38.3-73.7a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0L136,139.3V184a8,8,0,0,1-16,0V139.3l-14.3,14.4a8.1,8.1,0,0,1-11.4-11.4l28-28,.6-.5.3-.2.4-.3.3-.2.3-.2h.4l.3-.2h.4l.4-.2h4.6l.4.2h.4l.3.2h.4l.3.2.3.2.4.3.3.2.6.5Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M213.6,82.3l-55.9-56-.3-.2-.3-.3-.3-.2-.3-.2c-.1-.1-.2-.1-.2-.2l-.5-.3h-.2l-.5-.3H155l-.7-.3H56A16,16,0,0,0,40,40V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V88A7.8,7.8,0,0,0,213.6,82.3Zm-51.9,71.4a8.1,8.1,0,0,1-11.4,0L136,139.3V184a8,8,0,0,1-16,0V139.3l-14.3,14.4a8.1,8.1,0,0,1-11.4-11.4l28-28h.1l.5-.5.3-.2.4-.3.3-.2.3-.2h.4l.3-.2h.4l.4-.2h4.6l.4.2h.4l.3.2h.4l.3.2.3.2.4.3.3.2.5.5h.1l28,28A8.1,8.1,0,0,1,161.7,153.7ZM152,88V43.3L196.7,88Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M214,88a6,6,0,0,0-1.8-4.3l-56-55.9h0l-.4-.3h-.1l-.4-.3h-.1l-.4-.2h0l-.5-.2a.1.1,0,0,0-.1-.1H56A14,14,0,0,0,42,40V216a14,14,0,0,0,14,14H200a14,14,0,0,0,14-14V88ZM158,46.5,193.5,82H158ZM200,218H56a2,2,0,0,1-2-2V40a2,2,0,0,1,2-2h90V88a6,6,0,0,0,6,6h50V216A2,2,0,0,1,200,218Zm-39.8-74.2a5.9,5.9,0,1,1-8.4,8.4L134,134.5V184a6,6,0,0,1-12,0V134.5l-17.8,17.7a5.9,5.9,0,0,1-8.4-8.4l28-28h0a2.4,2.4,0,0,1,.9-.8h0l1-.5h.1l1-.3h2.4l1,.3h.1l1,.5h0a2.4,2.4,0,0,1,.9.8h0Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M212,88a4.5,4.5,0,0,0-1.2-2.9l-56-55.9h0l-.6-.4H154l-.5-.3H56A12,12,0,0,0,44,40V216a12,12,0,0,0,12,12H200a12,12,0,0,0,12-12V88.1ZM156,41.7,198.3,84H156ZM200,220H56a4,4,0,0,1-4-4V40a4,4,0,0,1,4-4h92V88a4,4,0,0,0,4,4h52V216A4,4,0,0,1,200,220Zm-41.2-74.8a3.9,3.9,0,0,1,0,5.6,3.9,3.9,0,0,1-5.6,0L132,129.7V184a4,4,0,0,1-8,0V129.7l-21.2,21.1a4,4,0,0,1-5.6-5.6l28-28h0l.6-.4.3-.2.4-.2h3l.4.2.3.2.6.4h0Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,88a7.7,7.7,0,0,0-2.4-5.7l-55.9-56-.6-.5h-.2l-.5-.3h-.1l-.5-.4h-.1l-.6-.3H155l-.7-.3H56A16,16,0,0,0,40,40V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V88ZM160,51.3,188.7,80H160ZM200,216H56V40h88V88a8,8,0,0,0,8,8h48V216Zm-38.3-73.7a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0L136,139.3V184a8,8,0,0,1-16,0V139.3l-14.3,14.4a8.1,8.1,0,0,1-11.4-11.4l28-28,.6-.5.3-.2.4-.3.3-.2.3-.2h.4l.3-.2h.4l.4-.2h4.6l.4.2h.4l.3.2h.4l.3.2.3.2.4.3.3.2.6.5Z" />
        </>
      )}
    </svg>
  );
}

export function PhFileAudio(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216.5,79.5l-56-56A12.3,12.3,0,0,0,152,20H56A20.1,20.1,0,0,0,36,40v76a12,12,0,0,0,24,0V44h76V92a12,12,0,0,0,12,12h48V212H180a12,12,0,0,0,0,24h20a20.1,20.1,0,0,0,20-20V88A12.3,12.3,0,0,0,216.5,79.5ZM160,57l23,23H160Zm-58.9,76.1a12.2,12.2,0,0,0-12.8,1.7L67.7,152H48a12,12,0,0,0-12,12v40a12,12,0,0,0,12,12H67.7l20.6,17.2A11.6,11.6,0,0,0,96,236a13,13,0,0,0,5.1-1.1A12,12,0,0,0,108,224V144A12,12,0,0,0,101.1,133.1ZM84,198.4l-4.3-3.6A12.2,12.2,0,0,0,72,192H60V176H72a12.2,12.2,0,0,0,7.7-2.8l4.3-3.6ZM160,188a43.7,43.7,0,0,1-16.5,34.3A11.9,11.9,0,0,1,136,225a12.3,12.3,0,0,1-9.4-4.5,12.1,12.1,0,0,1,1.9-16.9,20,20,0,0,0,0-31.2,12,12,0,1,1,15-18.7A43.7,43.7,0,0,1,160,188Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M208,88H152V32ZM48,172v32H72l24,20V152L72,172Z"
            opacity="0.2"
          />
          <path d="M216,88a7.8,7.8,0,0,0-2.4-5.7l-55.9-56A8.1,8.1,0,0,0,152,24H56A16,16,0,0,0,40,40v88a8,8,0,0,0,16,0V40h88V88a8,8,0,0,0,8,8h48V216H168a8,8,0,0,0,0,16h32a16,16,0,0,0,16-16V88ZM160,51.3,188.7,80H160ZM99.4,144.8a7.8,7.8,0,0,0-8.5,1.1L69.1,164H48a8,8,0,0,0-8,8v32a8,8,0,0,0,8,8H69.1l21.8,18.1A7.5,7.5,0,0,0,96,232a6.8,6.8,0,0,0,3.4-.8A7.9,7.9,0,0,0,104,224V152A7.9,7.9,0,0,0,99.4,144.8ZM88,206.9l-10.9-9A7.8,7.8,0,0,0,72,196H56V180H72a7.8,7.8,0,0,0,5.1-1.9l10.9-9ZM148,188a39.8,39.8,0,0,1-15,31.2,7.9,7.9,0,0,1-5,1.8,7.8,7.8,0,0,1-6.2-3,8.1,8.1,0,0,1,1.2-11.3,23.9,23.9,0,0,0,0-37.4,8,8,0,0,1,10-12.5A39.8,39.8,0,0,1,148,188Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M213.7,82.3l-56-56A8.1,8.1,0,0,0,152,24H56A16,16,0,0,0,40,40v88a8,8,0,0,0,16,0V40h88V88a8,8,0,0,0,8,8h48V216H168a8,8,0,0,0,0,16h32a16,16,0,0,0,16-16V88A8.1,8.1,0,0,0,213.7,82.3ZM160,80V51.3L188.7,80Zm-56,72v72a7.9,7.9,0,0,1-4.6,7.2,6.8,6.8,0,0,1-3.4.8,7.5,7.5,0,0,1-5.1-1.9L69.1,212H48a8,8,0,0,1-8-8V172a8,8,0,0,1,8-8H69.1l21.8-18.1a7.8,7.8,0,0,1,8.5-1.1A7.9,7.9,0,0,1,104,152Zm44,36a39.8,39.8,0,0,1-15,31.2,7.9,7.9,0,0,1-5,1.8,7.8,7.8,0,0,1-6.2-3,8.1,8.1,0,0,1,1.2-11.3,23.9,23.9,0,0,0,0-37.4,8,8,0,0,1,10-12.5A39.8,39.8,0,0,1,148,188Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M214,88a6,6,0,0,0-1.8-4.3l-56-55.9A5.6,5.6,0,0,0,152,26H56A14,14,0,0,0,42,40v88a6,6,0,0,0,12,0V40a2,2,0,0,1,2-2h90V88a6,6,0,0,0,6,6h50V216a2,2,0,0,1-2,2H168a6,6,0,0,0,0,12h32a14,14,0,0,0,14-14V88ZM158,46.5,193.5,82H158ZM98.5,146.6a5.8,5.8,0,0,0-6.3.8L69.8,166H48a6,6,0,0,0-6,6v32a6,6,0,0,0,6,6H69.8l22.4,18.6A5.9,5.9,0,0,0,96,230a5.4,5.4,0,0,0,2.5-.6A5.9,5.9,0,0,0,102,224V152A5.9,5.9,0,0,0,98.5,146.6ZM90,211.2,75.8,199.4A5.7,5.7,0,0,0,72,198H54V178H72a5.7,5.7,0,0,0,3.8-1.4L90,164.8ZM146,188a38.1,38.1,0,0,1-14.2,29.7A7,7,0,0,1,128,219a6,6,0,0,1-3.8-10.7,25.9,25.9,0,0,0,0-40.6,6,6,0,0,1-.9-8.4,6.1,6.1,0,0,1,8.5-1A38.1,38.1,0,0,1,146,188Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M212,88a4.5,4.5,0,0,0-1.2-2.9l-56-55.9A3.6,3.6,0,0,0,152,28H56A12,12,0,0,0,44,40v88a4,4,0,0,0,8,0V40a4,4,0,0,1,4-4h92V88a4,4,0,0,0,4,4h52V216a4,4,0,0,1-4,4H168a4,4,0,0,0,0,8h32a12,12,0,0,0,12-12V88.1ZM156,41.7,198.3,84H156ZM97.7,148.4a4,4,0,0,0-4.3.5L70.6,168H48a4,4,0,0,0-4,4v32a4,4,0,0,0,4,4H70.6l22.8,19.1a4.3,4.3,0,0,0,2.6.9,3.3,3.3,0,0,0,1.7-.4A3.9,3.9,0,0,0,100,224V152A3.9,3.9,0,0,0,97.7,148.4ZM92,215.5,74.6,200.9A4.3,4.3,0,0,0,72,200H52V176H72a4.3,4.3,0,0,0,2.6-.9L92,160.5ZM144,188a35.8,35.8,0,0,1-13.5,28.1,3.8,3.8,0,0,1-2.5.9,3.9,3.9,0,0,1-3.1-1.5,4,4,0,0,1,.6-5.6,28.1,28.1,0,0,0,0-43.8,4,4,0,1,1,5-6.2A35.8,35.8,0,0,1,144,188Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,88a7.8,7.8,0,0,0-2.4-5.7l-55.9-56A8.1,8.1,0,0,0,152,24H56A16,16,0,0,0,40,40v88a8,8,0,0,0,16,0V40h88V88a8,8,0,0,0,8,8h48V216H168a8,8,0,0,0,0,16h32a16,16,0,0,0,16-16V88ZM160,51.3,188.7,80H160ZM99.4,144.8a7.8,7.8,0,0,0-8.5,1.1L69.1,164H48a8,8,0,0,0-8,8v32a8,8,0,0,0,8,8H69.1l21.8,18.1A7.5,7.5,0,0,0,96,232a6.8,6.8,0,0,0,3.4-.8A7.9,7.9,0,0,0,104,224V152A7.9,7.9,0,0,0,99.4,144.8ZM88,206.9l-10.9-9A7.8,7.8,0,0,0,72,196H56V180H72a7.8,7.8,0,0,0,5.1-1.9l10.9-9ZM148,188a39.8,39.8,0,0,1-15,31.2,7.9,7.9,0,0,1-5,1.8,7.8,7.8,0,0,1-6.2-3,8.1,8.1,0,0,1,1.2-11.3,23.9,23.9,0,0,0,0-37.4,8,8,0,0,1,10-12.5A39.8,39.8,0,0,1,148,188Z" />
        </>
      )}
    </svg>
  );
}

export function PhFileCloud(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216.5,79.5l-56-56A12.3,12.3,0,0,0,152,20H56A20.1,20.1,0,0,0,36,40v84a12,12,0,0,0,24,0V44h76V92a12,12,0,0,0,12,12h48V212H180a12,12,0,0,0,0,24h20a20.1,20.1,0,0,0,20-20V88A12.3,12.3,0,0,0,216.5,79.5ZM160,57l23,23H160Zm-52,67a56,56,0,0,0-50.6,32.1A40,40,0,0,0,60,236h48a56,56,0,0,0,0-112Zm0,88H60a16,16,0,0,1-7.8-30A12,12,0,0,0,76,180a32,32,0,1,1,32,32Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M208,88H152V32Z" opacity="0.2" />
          <path d="M216,88a7.7,7.7,0,0,0-2.4-5.7l-55.9-56A8.1,8.1,0,0,0,152,24H56A16,16,0,0,0,40,40v88a8,8,0,0,0,16,0V40h88V88a8,8,0,0,0,8,8h48V216H176a8,8,0,0,0,0,16h24a16,16,0,0,0,16-16V88ZM160,51.3,188.7,80H160ZM108,128a52.1,52.1,0,0,0-48,32,36,36,0,0,0,0,72h48a52,52,0,0,0,0-104Zm0,88H60a20,20,0,0,1-3.9-39.6c-.1,1.2-.1,2.4-.1,3.6a8,8,0,0,0,16,0,36,36,0,1,1,36,36Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M213.7,82.3l-56-56A8.1,8.1,0,0,0,152,24H56A16,16,0,0,0,40,40v88a8,8,0,0,0,16,0V40h88V88a8,8,0,0,0,8,8h48V216H176a8,8,0,0,0,0,16h24a16,16,0,0,0,16-16V88A8.1,8.1,0,0,0,213.7,82.3ZM160,80V51.3L188.7,80Zm0,100a52,52,0,0,1-52,52H60a36,36,0,0,1-9.2-70.8A65.3,65.3,0,0,0,48,180a8,8,0,0,0,16,0,47.4,47.4,0,0,1,4.4-20h0A48,48,0,0,1,160,180Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M214,88a6,6,0,0,0-1.8-4.3l-56-55.9h0l-.4-.3h-.1l-.4-.3h-.1l-.4-.2h0l-.5-.2a.1.1,0,0,0-.1-.1H56A14,14,0,0,0,42,40v88a6,6,0,0,0,12,0V40a2,2,0,0,1,2-2h90V88a6,6,0,0,0,6,6h50V216a2,2,0,0,1-2,2H176a6,6,0,0,0,0,12h24a14,14,0,0,0,14-14V88ZM158,46.5,193.5,82H158ZM108,130a50,50,0,0,0-46.6,32H60a34,34,0,0,0,0,68h48a50,50,0,0,0,0-100Zm0,88H60a22,22,0,0,1-1.6-43.9A38.1,38.1,0,0,0,58,180a6,6,0,0,0,12,0,38,38,0,1,1,38,38Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M212,88a4.5,4.5,0,0,0-1.2-2.9l-56-55.9h0l-.6-.4H154l-.5-.3H56A12,12,0,0,0,44,40v88a4,4,0,0,0,8,0V40a4,4,0,0,1,4-4h92V88a4,4,0,0,0,4,4h52V216a4,4,0,0,1-4,4H176a4,4,0,0,0,0,8h24a12,12,0,0,0,12-12V88.1ZM156,41.7,198.3,84H156ZM108,132a48.1,48.1,0,0,0-45.3,32H60a32,32,0,0,0,0,64h48a48,48,0,0,0,0-96Zm0,88H60a24,24,0,0,1,0-48h.7a42.5,42.5,0,0,0-.7,8,4,4,0,0,0,8,0,40,40,0,1,1,40,40Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M213.7,82.3l-56-56A8.1,8.1,0,0,0,152,24H56A16,16,0,0,0,40,40v88a8,8,0,0,0,16,0V40h88V88a8,8,0,0,0,8,8h48V216H176a8,8,0,0,0,0,16h24a16,16,0,0,0,16-16V88A8.1,8.1,0,0,0,213.7,82.3ZM160,51.3,188.7,80H160ZM108,128a52.1,52.1,0,0,0-48,32,36,36,0,0,0,0,72h48a52,52,0,0,0,0-104Zm0,88H60a20,20,0,0,1-3.9-39.6c-.1,1.2-.1,2.4-.1,3.6a8,8,0,0,0,16,0,36,36,0,1,1,36,36Z" />
        </>
      )}
    </svg>
  );
}

export function PhFileCode(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M180.5,147.5a12,12,0,0,1,0,17l-24,24a12.1,12.1,0,0,1-17,0,12,12,0,0,1,0-17L155,156l-15.5-15.5a12,12,0,0,1,17-17Zm-64-24a12,12,0,0,0-17,0l-24,24a12,12,0,0,0,0,17l24,24a12.1,12.1,0,0,0,17,0,12,12,0,0,0,0-17L101,156l15.5-15.5A12,12,0,0,0,116.5,123.5ZM220,88V216a20.1,20.1,0,0,1-20,20H56a20.1,20.1,0,0,1-20-20V40A20.1,20.1,0,0,1,56,20h96a12.3,12.3,0,0,1,8.5,3.5l56,56A12.3,12.3,0,0,1,220,88ZM160,57V80h23Zm36,155V104H148a12,12,0,0,1-12-12V44H60V212Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M208,88H152V32Z" opacity="0.2" />
          <path d="M216,88a7.7,7.7,0,0,0-2.4-5.7l-55.9-56A8.1,8.1,0,0,0,152,24H56A16,16,0,0,0,40,40V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V88ZM160,51.3,188.7,80H160ZM200,216H56V40h88V88a8,8,0,0,0,8,8h48V216Zm-22.3-69.7a8.1,8.1,0,0,1,0,11.4l-24,24A8.5,8.5,0,0,1,148,184a8.3,8.3,0,0,1-5.7-2.3,8.1,8.1,0,0,1,0-11.4L160.7,152l-18.4-18.3a8.1,8.1,0,0,1,11.4-11.4Zm-64-12.6L95.3,152l18.4,18.3a8.1,8.1,0,0,1,0,11.4A8.5,8.5,0,0,1,108,184a8.3,8.3,0,0,1-5.7-2.3l-24-24a8.1,8.1,0,0,1,0-11.4l24-24a8.1,8.1,0,0,1,11.4,11.4Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M213.7,82.3l-56-56A8.1,8.1,0,0,0,152,24H56A16,16,0,0,0,40,40V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V88A8.1,8.1,0,0,0,213.7,82.3Zm-104,88a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0l-24-24a8.1,8.1,0,0,1,0-11.4l24-24a8.1,8.1,0,0,1,11.4,11.4L91.3,152Zm72-12.6-24,24a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L164.7,152l-18.4-18.3a8.1,8.1,0,0,1,11.4-11.4l24,24A8.1,8.1,0,0,1,181.7,157.7ZM152,88V44l44,44Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M214,88a6,6,0,0,0-1.8-4.3l-56-55.9A5.6,5.6,0,0,0,152,26H56A14,14,0,0,0,42,40V216a14,14,0,0,0,14,14H200a14,14,0,0,0,14-14V88ZM158,46.5,193.5,82H158ZM200,218H56a2,2,0,0,1-2-2V40a2,2,0,0,1,2-2h90V88a6,6,0,0,0,6,6h50V216A2,2,0,0,1,200,218Zm-23.8-70.2a5.8,5.8,0,0,1,0,8.4l-24,24a5.9,5.9,0,0,1-8.4-8.4L163.5,152l-19.7-19.8a5.9,5.9,0,0,1,8.4-8.4Zm-64-15.6L92.5,152l19.7,19.8a5.9,5.9,0,1,1-8.4,8.4l-24-24a5.8,5.8,0,0,1,0-8.4l24-24a5.9,5.9,0,0,1,8.4,8.4Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M212,88a4.5,4.5,0,0,0-1.2-2.9l-56-55.9A3.6,3.6,0,0,0,152,28H56A12,12,0,0,0,44,40V216a12,12,0,0,0,12,12H200a12,12,0,0,0,12-12V88.1ZM156,41.7,198.3,84H156ZM200,220H56a4,4,0,0,1-4-4V40a4,4,0,0,1,4-4h92V88a4,4,0,0,0,4,4h52V216A4,4,0,0,1,200,220Zm-25.2-70.8a3.9,3.9,0,0,1,0,5.6l-24,24A3.9,3.9,0,0,1,148,180a3.8,3.8,0,0,1-2.8-1.2,3.9,3.9,0,0,1,0-5.6L166.3,152l-21.1-21.2a4,4,0,1,1,5.6-5.6Zm-64-18.4L89.7,152l21.1,21.2a3.9,3.9,0,0,1,0,5.6A3.9,3.9,0,0,1,108,180a3.8,3.8,0,0,1-2.8-1.2l-24-24a3.9,3.9,0,0,1,0-5.6l24-24a4,4,0,1,1,5.6,5.6Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,88a7.7,7.7,0,0,0-2.4-5.7l-55.9-56A8.1,8.1,0,0,0,152,24H56A16,16,0,0,0,40,40V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V88ZM160,51.3,188.7,80H160ZM200,216H56V40h88V88a8,8,0,0,0,8,8h48V216Zm-22.3-69.7a8.1,8.1,0,0,1,0,11.4l-24,24A8.5,8.5,0,0,1,148,184a8.3,8.3,0,0,1-5.7-2.3,8.1,8.1,0,0,1,0-11.4L160.7,152l-18.4-18.3a8.1,8.1,0,0,1,11.4-11.4Zm-64-12.6L95.3,152l18.4,18.3a8.1,8.1,0,0,1,0,11.4A8.5,8.5,0,0,1,108,184a8.3,8.3,0,0,1-5.7-2.3l-24-24a8.1,8.1,0,0,1,0-11.4l24-24a8.1,8.1,0,0,1,11.4,11.4Z" />
        </>
      )}
    </svg>
  );
}

export function PhFileCss(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M224,99.8V92a12.3,12.3,0,0,0-3.5-8.5l-56-56A12.3,12.3,0,0,0,156,24H52A20.1,20.1,0,0,0,32,44v80a12,12,0,0,0,24,0V48h80v52a12,12,0,0,0,12,12h52v12a12,12,0,0,0,24,0V99.8ZM160,88V57l31,31ZM56,192c0,8.8,6.3,16,14,16a12.8,12.8,0,0,0,7.2-2.3,12,12,0,0,1,13.6,19.8A37.1,37.1,0,0,1,70,232c-20.9,0-38-17.9-38-40s17.1-40,38-40a37.1,37.1,0,0,1,20.8,6.5,12,12,0,0,1-13.6,19.8A12.8,12.8,0,0,0,70,176C62.3,176,56,183.2,56,192Zm104,13.4c0,16.2-11.4,26.6-29,26.6a36.2,36.2,0,0,1-23.2-8.8h0a12,12,0,0,1,8.2-20.8,12,12,0,0,1,8.2,3.2H124a12,12,0,0,0,7,2.5c3.1,0,4.5-.6,4.8-.8a3.7,3.7,0,0,0,.2-1.4c-1.6-1-5.4-2.1-7.8-2.8-8.5-2.5-24.2-7.2-24.2-24.4,0-15.4,11.4-26.6,27-26.6a36.2,36.2,0,0,1,23.2,8.8h0a12,12,0,0,1-8.2,20.8,12,12,0,0,1-8.2-3.2h.2a12,12,0,0,0-7-2.5c-2.3,0-2.8.6-3,1.6,1.7.8,4.9,1.7,7,2.4C143.8,182.6,160,187.4,160,205.4Zm64,0c0,16.2-11.4,26.6-29,26.6a36.2,36.2,0,0,1-23.2-8.8h0a12,12,0,0,1,8.2-20.8,12,12,0,0,1,8.2,3.2H188a12,12,0,0,0,7,2.5,10.5,10.5,0,0,0,4.8-.8,3.7,3.7,0,0,0,.2-1.4c-1.6-1-5.4-2.1-7.8-2.8-8.5-2.5-24.2-7.2-24.2-24.4,0-15.4,11.4-26.6,27-26.6a36.2,36.2,0,0,1,23.2,8.8h0a12,12,0,0,1-8.2,20.8,12,12,0,0,1-8.2-3.2h.2a12,12,0,0,0-7-2.5c-2.3,0-2.8.6-3,1.6,1.7.8,4.9,1.7,7,2.4C207.8,182.6,224,187.4,224,205.4Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M208,88H152V32Z" opacity="0.2" />
          <path d="M216,88a7.8,7.8,0,0,0-2.4-5.7l-55.9-56A8.1,8.1,0,0,0,152,24H56A16,16,0,0,0,40,40v88a8,8,0,0,0,16,0V40h88V88a8,8,0,0,0,8,8h48v32a8,8,0,0,0,16,0V88ZM160,51.3,188.7,80H160ZM52,192c0,8.8,6.3,16,14,16a12.9,12.9,0,0,0,8.6-3.4,7.9,7.9,0,0,1,11.3.6,8,8,0,0,1-.5,11.3A29.3,29.3,0,0,1,66,224c-16.5,0-30-14.4-30-32s13.5-32,30-32a29.3,29.3,0,0,1,19.4,7.5,8,8,0,0,1,.5,11.3,7.9,7.9,0,0,1-11.3.6A12.9,12.9,0,0,0,66,176C58.3,176,52,183.2,52,192Zm77.7-8.2c-3.1-.8-8.6-2.3-9.7-3.6V180c0-3.3,2.4-5,7-5a18.4,18.4,0,0,1,10,3.2h-.1A8.3,8.3,0,0,0,142,180a8,8,0,0,0,8-8,7.6,7.6,0,0,0-2.9-6.1h0A33.4,33.4,0,0,0,127,159c-13.5,0-23,8.6-23,21s13,17,21.6,19.3c3.4.9,7,1.8,9.2,3.1s1.2,1,1.2,1.6,0,5-9,5a18.4,18.4,0,0,1-10-3.2h.1A8.3,8.3,0,0,0,112,204a8,8,0,0,0-8,8,7.6,7.6,0,0,0,2.9,6.1h0A33.4,33.4,0,0,0,127,225c15.6,0,25-7.9,25-21S138,186,129.7,183.8Zm7.2-5.6h0Zm-19.8,27.6h0Zm76.6-22c-3.1-.8-8.6-2.3-9.7-3.6V180c0-3.3,2.3-5,7-5a18.4,18.4,0,0,1,10,3.2h-.1A8.3,8.3,0,0,0,206,180a8,8,0,0,0,8-8,7.6,7.6,0,0,0-2.9-6.1h0A33.4,33.4,0,0,0,191,159c-13.5,0-23,8.6-23,21s13,17,21.6,19.3c3.4.9,7,1.8,9.2,3.1s1.2,1,1.2,1.6,0,5-9,5a18.4,18.4,0,0,1-10-3.2h.1A8.3,8.3,0,0,0,176,204a8,8,0,0,0-8,8,7.6,7.6,0,0,0,2.9,6.1h0A33.4,33.4,0,0,0,191,225c15.6,0,25-7.9,25-21S202,186,193.7,183.8Zm7.2-5.6h0Zm-19.8,27.6h0Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M129.7,183.8c-3.1-.8-8.6-2.3-9.7-3.6V180c0-3.3,2.4-5,7-5a18.4,18.4,0,0,1,10,3.2h-.1A8.3,8.3,0,0,0,142,180a8,8,0,0,0,8-8,7.6,7.6,0,0,0-2.9-6.1h0A33.4,33.4,0,0,0,127,159c-13.5,0-23,8.6-23,21s13,17,21.6,19.3c3.4.9,7,1.8,9.2,3.1s1.2,1,1.2,1.6,0,5-9,5a18.4,18.4,0,0,1-10-3.2h.1A8.3,8.3,0,0,0,112,204a8,8,0,0,0-8,8,7.6,7.6,0,0,0,2.9,6.1h0A33.4,33.4,0,0,0,127,225c15.7,0,25-7.9,25-21S138,186,129.7,183.8Zm7.2-5.7h0Zm-19.8,27.8h0ZM52,192c0,8.8,6.3,16,14,16a12.9,12.9,0,0,0,8.6-3.4,7.9,7.9,0,0,1,11.3.6,8,8,0,0,1-.5,11.3A29.3,29.3,0,0,1,66,224c-16.5,0-30-14.4-30-32s13.5-32,30-32a29.3,29.3,0,0,1,19.4,7.5,8,8,0,0,1,.5,11.3,7.9,7.9,0,0,1-11.3.6A12.9,12.9,0,0,0,66,176C58.3,176,52,183.2,52,192Zm141.7-8.2c-3.1-.8-8.6-2.3-9.7-3.6V180c0-3.3,2.4-5,7-5a18.4,18.4,0,0,1,10,3.2h-.1A8.3,8.3,0,0,0,206,180a8,8,0,0,0,8-8,7.6,7.6,0,0,0-2.9-6.1h0A33.4,33.4,0,0,0,191,159c-13.5,0-23,8.6-23,21s13,17,21.6,19.3c3.4.9,7,1.8,9.2,3.1s1.2,1,1.2,1.6,0,5-9,5a18.4,18.4,0,0,1-10-3.2h.1A8.3,8.3,0,0,0,176,204a8,8,0,0,0-8,8,7.6,7.6,0,0,0,2.9,6.1h0A33.4,33.4,0,0,0,191,225c15.7,0,25-7.9,25-21S202,186,193.7,183.8Zm7.2-5.7h0Zm-19.8,27.8h0Zm20.5-27.2Zm-64,0Zm76.1-96.4-56-56A8.1,8.1,0,0,0,152,24H56A16,16,0,0,0,40,40v88a8,8,0,0,0,8,8H208a8,8,0,0,0,8-8V88A8.1,8.1,0,0,0,213.7,82.3ZM152,88V44l44,44Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M214,88a6,6,0,0,0-1.8-4.3l-56-55.9A5.6,5.6,0,0,0,152,26H56A14,14,0,0,0,42,40v88a6,6,0,0,0,12,0V40a2,2,0,0,1,2-2h90V88a6,6,0,0,0,6,6h50v34a6,6,0,0,0,12,0V88ZM158,46.5,193.5,82H158ZM50,192c0,9.9,7.2,18,16,18a15.1,15.1,0,0,0,10-3.9,5.9,5.9,0,0,1,8.5.4A6,6,0,0,1,84,215a26.9,26.9,0,0,1-18,7c-15.4,0-28-13.5-28-30s12.6-30,28-30a26.9,26.9,0,0,1,18,7,6,6,0,0,1,.5,8.5,5.9,5.9,0,0,1-8.5.4A15.1,15.1,0,0,0,66,174C57.2,174,50,182.1,50,192Zm100,12c0,11.9-8.6,19-23,19a30,30,0,0,1-18.8-6.4,6,6,0,1,1,7.6-9.2A20,20,0,0,0,127,211c11,0,11-4.7,11-7s-3.1-4.3-11.9-6.7S106,192,106,180s8.6-19,21-19a30,30,0,0,1,18.8,6.4,6,6,0,0,1-7.6,9.2A19.7,19.7,0,0,0,127,173c-3.4,0-9,.9-9,7,0,2.4,3.4,3.7,11.2,5.7S150,191.3,150,204Zm64,0c0,11.9-8.6,19-23,19a30,30,0,0,1-18.8-6.4,6,6,0,1,1,7.6-9.2A20,20,0,0,0,191,211c11,0,11-4.7,11-7s-3.1-4.3-11.9-6.7S170,192,170,180s8.6-19,21-19a30,30,0,0,1,18.8,6.4,6,6,0,0,1-7.6,9.2A19.7,19.7,0,0,0,191,173c-3.4,0-9,.9-9,7,0,2.4,3.4,3.7,11.2,5.7S214,191.3,214,204Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M48,192c0,11,8.1,20,18,20a16.2,16.2,0,0,0,11.3-4.5,4.2,4.2,0,0,1,5.7.3,4.1,4.1,0,0,1-.3,5.7A25,25,0,0,1,66,220c-14.3,0-26-12.6-26-28s11.7-28,26-28a25,25,0,0,1,16.7,6.5,4.1,4.1,0,0,1,.3,5.7,4.2,4.2,0,0,1-5.7.3A16.2,16.2,0,0,0,66,172C56.1,172,48,181,48,192Zm80.6-4.3c-8.6-2.3-12.6-3.8-12.6-7.7,0-5.6,4.1-9,11-9a20.7,20.7,0,0,1,12.4,4.1,4,4,0,1,0,5.2-6.2A30,30,0,0,0,127,163c-11.4,0-19,6.8-19,17s10.3,13.2,18.6,15.4,13.4,4,13.4,8.6c0,2.5,0,9-13,9a21.5,21.5,0,0,1-12.5-4.1,4,4,0,0,0-5.6.5,4.1,4.1,0,0,0,.5,5.7A30,30,0,0,0,127,221c13.3,0,21-6.2,21-17S137.3,189.9,128.6,187.7Zm64,0c-8.6-2.3-12.6-3.8-12.6-7.7,0-5.6,4.1-9,11-9a20.7,20.7,0,0,1,12.4,4.1,4,4,0,0,0,5.2-6.2A30,30,0,0,0,191,163c-11.4,0-19,6.8-19,17s10.3,13.2,18.6,15.4,13.4,4,13.4,8.6c0,2.5,0,9-13,9a21.5,21.5,0,0,1-12.5-4.1,4,4,0,0,0-5.6.5,4.1,4.1,0,0,0,.5,5.7A30,30,0,0,0,191,221c13.3,0,21-6.2,21-17S201.3,189.9,192.6,187.7ZM212,88.1V128a4,4,0,0,1-8,0V92H152a4,4,0,0,1-4-4V36H56a4,4,0,0,0-4,4v88a4,4,0,0,1-8,0V40A12,12,0,0,1,56,28h96a3.6,3.6,0,0,1,2.8,1.2l56,55.9A4.5,4.5,0,0,1,212,88ZM156,84h42.3L156,41.7Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M52,192c0,8.8,6.3,16,14,16a12.9,12.9,0,0,0,8.6-3.4,7.9,7.9,0,0,1,11.3.6,8,8,0,0,1-.5,11.3A29.3,29.3,0,0,1,66,224c-16.5,0-30-14.4-30-32s13.5-32,30-32a29.3,29.3,0,0,1,19.4,7.5,8,8,0,0,1,.5,11.3,7.9,7.9,0,0,1-11.3.6A12.9,12.9,0,0,0,66,176C58.3,176,52,183.2,52,192ZM216,88v40a8,8,0,0,1-16,0V96H152a8,8,0,0,1-8-8V40H56v88a8,8,0,0,1-16,0V40A16,16,0,0,1,56,24h96a8.1,8.1,0,0,1,5.7,2.3l55.9,56A7.8,7.8,0,0,1,216,88Zm-27.3-8L160,51.3V80ZM152,204c0,13.1-9.4,21-25,21a33.4,33.4,0,0,1-20.1-6.8h0A7.6,7.6,0,0,1,104,212a8,8,0,0,1,8-8,8.3,8.3,0,0,1,5.1,1.8H117a18.4,18.4,0,0,0,10,3.2c9,0,9-3,9-5s0-.9-1.2-1.6-5.8-2.2-9.2-3.1C117,197,104,193.5,104,180s9.5-21,23-21a33.4,33.4,0,0,1,20.1,6.8h0A7.6,7.6,0,0,1,150,172a8,8,0,0,1-8,8,8.3,8.3,0,0,1-5.1-1.8h.1a18.4,18.4,0,0,0-10-3.2c-4.6,0-7,1.7-7,5v.2c1.1,1.3,6.6,2.8,9.7,3.6C138,186,152,189.7,152,204Zm-15.1-25.9h0Zm-19.8,27.8h0ZM216,204c0,13.1-9.4,21-25,21a33.4,33.4,0,0,1-20.1-6.8h0A7.6,7.6,0,0,1,168,212a8,8,0,0,1,8-8,8.3,8.3,0,0,1,5.1,1.8H181a18.4,18.4,0,0,0,10,3.2c9,0,9-3,9-5s0-.9-1.2-1.6-5.8-2.2-9.2-3.1C181,197,168,193.5,168,180s9.5-21,23-21a33.4,33.4,0,0,1,20.1,6.8h0A7.6,7.6,0,0,1,214,172a8,8,0,0,1-8,8,8.3,8.3,0,0,1-5.1-1.8h.1a18.4,18.4,0,0,0-10-3.2c-4.7,0-7,1.7-7,5v.2c1.1,1.3,6.6,2.8,9.7,3.6C202,186,216,189.7,216,204Zm-15.1-25.9h0Zm-19.8,27.8h0Z" />
        </>
      )}
    </svg>
  );
}

export function PhFileCsv(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M231.2,168.4l-22,56a12.1,12.1,0,0,1-22.4,0l-22-56a12,12,0,0,1,22.4-8.8L198,187.2l10.8-27.6a12,12,0,1,1,22.4,8.8ZM32,124V44A20.1,20.1,0,0,1,52,24H156a12.3,12.3,0,0,1,8.5,3.5l56,56A12.3,12.3,0,0,1,224,92v32a12,12,0,0,1-24,0V112H148a12,12,0,0,1-12-12V48H56v76a12,12,0,0,1-24,0ZM160,57V88h31ZM62,176a12.8,12.8,0,0,1,7.2,2.3,12,12,0,0,0,13.6-19.8A37.1,37.1,0,0,0,62,152c-21,0-38,17.9-38,40s17,40,38,40a37.1,37.1,0,0,0,20.8-6.5,12,12,0,0,0-13.6-19.8A12.8,12.8,0,0,1,62,208c-7.7,0-14-7.2-14-16S54.3,176,62,176Zm69,4c-2.1-.7-5.3-1.6-7-2.4.2-1,.7-1.6,3-1.6a12,12,0,0,1,7,2.5h-.2a12,12,0,0,0,20.2-8.8,12.1,12.1,0,0,0-3.8-8.8h0A36.2,36.2,0,0,0,127,152c-15.6,0-27,11.2-27,26.6,0,17.2,15.7,21.9,24.2,24.4,2.4.7,6.2,1.8,7.8,2.8a3.7,3.7,0,0,1-.2,1.4c-.3.2-1.7.8-4.8.8a12,12,0,0,1-7-2.5h.2a12,12,0,0,0-20.2,8.8,12.1,12.1,0,0,0,3.8,8.8h0A36.2,36.2,0,0,0,127,232c17.6,0,29-10.4,29-26.6C156,187.4,139.8,182.6,131,180Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M208,88H152V32Z" opacity="0.2" />
          <path d="M219.4,171.1l-20,48a8,8,0,0,1-14.8,0l-20-48a8,8,0,1,1,14.8-6.2L192,195.2l12.6-30.3a8,8,0,1,1,14.8,6.2ZM66,176a12.9,12.9,0,0,1,8.6,3.4,7.9,7.9,0,0,0,11.3-.6,8,8,0,0,0-.5-11.3A29.3,29.3,0,0,0,66,160c-16.5,0-30,14.4-30,32s13.5,32,30,32a29.3,29.3,0,0,0,19.4-7.5,8,8,0,0,0,.5-11.3,7.9,7.9,0,0,0-11.3-.6A12.9,12.9,0,0,1,66,208c-7.7,0-14-7.2-14-16S58.3,176,66,176ZM200,96H152a8,8,0,0,1-8-8V40H56v88a8,8,0,0,1-16,0V40A16,16,0,0,1,56,24h96a8.1,8.1,0,0,1,5.7,2.3l55.9,56A7.8,7.8,0,0,1,216,88v40a8,8,0,0,1-16,0ZM160,80h28.7L160,51.3ZM129.7,183.8c-3.1-.8-8.6-2.3-9.7-3.6V180c0-3.3,2.4-5,7-5a18.4,18.4,0,0,1,10,3.2h-.1A8.3,8.3,0,0,0,142,180a8,8,0,0,0,8-8,7.6,7.6,0,0,0-2.9-6.1h0A33.4,33.4,0,0,0,127,159c-13.5,0-23,8.6-23,21s13,17,21.6,19.3c3.4.9,7,1.8,9.2,3.1s1.2,1,1.2,1.6,0,5-9,5a18.4,18.4,0,0,1-10-3.2h.1A8.3,8.3,0,0,0,112,204a8,8,0,0,0-8,8,7.6,7.6,0,0,0,2.9,6.1h0A33.4,33.4,0,0,0,127,225c15.6,0,25-7.9,25-21S138,186,129.7,183.8Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M219.4,171.1l-20,48a8,8,0,0,1-14.8,0l-20-48a8,8,0,1,1,14.8-6.2L192,195.2l12.6-30.3a8,8,0,1,1,14.8,6.2ZM66,176a12.9,12.9,0,0,1,8.6,3.4,7.9,7.9,0,0,0,11.3-.6,8,8,0,0,0-.5-11.3A29.3,29.3,0,0,0,66,160c-16.5,0-30,14.4-30,32s13.5,32,30,32a29.3,29.3,0,0,0,19.4-7.5,8,8,0,0,0,.5-11.3,7.9,7.9,0,0,0-11.3-.6A12.9,12.9,0,0,1,66,208c-7.7,0-14-7.2-14-16S58.3,176,66,176Zm63.7,7.8c-3.1-.8-8.6-2.3-9.7-3.6V180c0-3.3,2.4-5,7-5a18.4,18.4,0,0,1,10,3.2h-.1A8.3,8.3,0,0,0,142,180a8,8,0,0,0,8-8,7.6,7.6,0,0,0-2.9-6.1h0A33.4,33.4,0,0,0,127,159c-13.5,0-23,8.6-23,21s13,17,21.6,19.3c3.4.9,7,1.8,9.2,3.1s1.2,1,1.2,1.6,0,5-9,5a18.4,18.4,0,0,1-10-3.2h.1A8.3,8.3,0,0,0,112,204a8,8,0,0,0-8,8,7.6,7.6,0,0,0,2.9,6.1h0A33.4,33.4,0,0,0,127,225c15.7,0,25-7.9,25-21S138,186,129.7,183.8ZM40,128V40A16,16,0,0,1,56,24h96a8.1,8.1,0,0,1,5.7,2.3l56,56A8.1,8.1,0,0,1,216,88v40a8,8,0,0,1-8,8H48A8,8,0,0,1,40,128ZM152,88h44L152,44Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M217.5,170.3l-20,48a5.9,5.9,0,0,1-11,0l-20-48a6,6,0,0,1,11-4.6L192,200.4l14.5-34.7a6,6,0,1,1,11,4.6ZM76,206.1A15.1,15.1,0,0,1,66,210c-8.8,0-16-8.1-16-18s7.2-18,16-18a15.1,15.1,0,0,1,10,3.9,5.9,5.9,0,0,0,8.5-.4A6,6,0,0,0,84,169a26.9,26.9,0,0,0-18-7c-15.4,0-28,13.5-28,30s12.6,30,28,30a26.9,26.9,0,0,0,18-7,6,6,0,0,0,.5-8.5A5.9,5.9,0,0,0,76,206.1Zm53.2-20.4c-7.8-2-11.2-3.3-11.2-5.7,0-6.1,5.6-7,9-7a19.7,19.7,0,0,1,11.2,3.6,6,6,0,0,0,7.6-9.2A30,30,0,0,0,127,161c-12.4,0-21,7.8-21,19s11.6,15.1,20.1,17.3S138,201,138,204s0,7-11,7a20,20,0,0,1-11.2-3.6,6,6,0,1,0-7.6,9.2A30,30,0,0,0,127,223c14.4,0,23-7.1,23-19S137.5,187.9,129.2,185.7ZM202,94H152a6,6,0,0,1-6-6V38H56a2,2,0,0,0-2,2v88a6,6,0,0,1-12,0V40A14,14,0,0,1,56,26h96a5.6,5.6,0,0,1,4.2,1.8l56,55.9A6,6,0,0,1,214,88v40a6,6,0,0,1-12,0ZM158,82h35.5L158,46.5Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M215.7,169.5l-20,48a4,4,0,0,1-7.4,0l-20-48a4,4,0,1,1,7.4-3L192,205.6l16.3-39.1a4,4,0,0,1,7.4,3Zm-138.4,38A16.2,16.2,0,0,1,66,212c-9.9,0-18-9-18-20s8.1-20,18-20a16.2,16.2,0,0,1,11.3,4.5,4.2,4.2,0,0,0,5.7-.3,4.1,4.1,0,0,0-.3-5.7A25,25,0,0,0,66,164c-14.3,0-26,12.6-26,28s11.7,28,26,28a25,25,0,0,0,16.7-6.5,4.1,4.1,0,0,0,.3-5.7A4.2,4.2,0,0,0,77.3,207.5Zm51.3-19.8c-8.6-2.3-12.6-3.8-12.6-7.7,0-5.6,4.1-9,11-9a20.7,20.7,0,0,1,12.4,4.1,4,4,0,1,0,5.2-6.2A30,30,0,0,0,127,163c-11.4,0-19,6.8-19,17s10.3,13.2,18.6,15.4,13.4,4,13.4,8.6c0,2.5,0,9-13,9a21.5,21.5,0,0,1-12.5-4.1,4,4,0,0,0-5.6.5,4.1,4.1,0,0,0,.5,5.7A30,30,0,0,0,127,221c13.3,0,21-6.2,21-17S137.3,189.9,128.6,187.7ZM204,92H152a4,4,0,0,1-4-4V36H56a4,4,0,0,0-4,4v88a4,4,0,0,1-8,0V40A12,12,0,0,1,56,28h96a3.6,3.6,0,0,1,2.8,1.2l56,55.9A4.5,4.5,0,0,1,212,88h0V128a4,4,0,0,1-8,0Zm-48-8h42.3L156,41.7Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M219.4,171.1l-20,48a8,8,0,0,1-14.8,0l-20-48a8,8,0,1,1,14.8-6.2L192,195.2l12.6-30.3a8,8,0,1,1,14.8,6.2ZM66,176a12.9,12.9,0,0,1,8.6,3.4,7.9,7.9,0,0,0,11.3-.6,8,8,0,0,0-.5-11.3A29.3,29.3,0,0,0,66,160c-16.5,0-30,14.4-30,32s13.5,32,30,32a29.3,29.3,0,0,0,19.4-7.5,8,8,0,0,0,.5-11.3,7.9,7.9,0,0,0-11.3-.6A12.9,12.9,0,0,1,66,208c-7.7,0-14-7.2-14-16S58.3,176,66,176ZM200,96H152a8,8,0,0,1-8-8V40H56v88a8,8,0,0,1-16,0V40A16,16,0,0,1,56,24h96a8.1,8.1,0,0,1,5.7,2.3l55.9,56A7.8,7.8,0,0,1,216,88v40a8,8,0,0,1-16,0ZM160,80h28.7L160,51.3ZM129.7,183.8c-3.1-.8-8.6-2.3-9.7-3.6V180c0-3.3,2.4-5,7-5a18.4,18.4,0,0,1,10,3.2h-.1A8.3,8.3,0,0,0,142,180a8,8,0,0,0,8-8,7.6,7.6,0,0,0-2.9-6.1h0A33.4,33.4,0,0,0,127,159c-13.5,0-23,8.6-23,21s13,17,21.6,19.3c3.4.9,7,1.8,9.2,3.1s1.2,1,1.2,1.6,0,5-9,5a18.4,18.4,0,0,1-10-3.2h.1A8.3,8.3,0,0,0,112,204a8,8,0,0,0-8,8,7.6,7.6,0,0,0,2.9,6.1h0A33.4,33.4,0,0,0,127,225c15.6,0,25-7.9,25-21S138,186,129.7,183.8Z" />
        </>
      )}
    </svg>
  );
}

export function PhFileDoc(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M44,136a12,12,0,0,0,12-12V48h80v52a12,12,0,0,0,12,12h52v12a12,12,0,0,0,24,0V92a12.3,12.3,0,0,0-3.5-8.5l-56-56A12.3,12.3,0,0,0,156,24H52A20.1,20.1,0,0,0,32,44v80A12,12,0,0,0,44,136ZM191,88H160V57ZM44,152H28a12,12,0,0,0-12,12v56a12,12,0,0,0,12,12H44a40,40,0,0,0,0-80Zm0,56H40V176h4a16,16,0,0,1,0,32Zm193.9.8a12,12,0,0,1-3.1,16.7A37.1,37.1,0,0,1,214,232c-21,0-38-17.9-38-40s17-40,38-40a37.1,37.1,0,0,1,20.8,6.5,12,12,0,1,1-13.6,19.8A12.8,12.8,0,0,0,214,176c-7.7,0-14,7.2-14,16s6.3,16,14,16a12.8,12.8,0,0,0,7.2-2.3A12,12,0,0,1,237.9,208.8ZM130,152c-21,0-38,17.9-38,40s17,40,38,40,38-17.9,38-40S151,152,130,152Zm0,56c-7.7,0-14-7.2-14-16s6.3-16,14-16,14,7.2,14,16S137.7,208,130,208Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M208,88H152V32Z" opacity="0.2" />
          <path d="M58,160H44a8,8,0,0,0-8,8v48a8,8,0,0,0,8,8H58a32,32,0,0,0,0-64Zm0,48H52V176h6a16,16,0,0,1,0,32Zm72-48c-16.5,0-30,14.4-30,32s13.5,32,30,32,30-14.4,30-32S146.5,160,130,160Zm0,48c-7.7,0-14-7.2-14-16s6.3-16,14-16,14,7.2,14,16S137.7,208,130,208Zm87.9-2.8a8,8,0,0,1-.5,11.3A29.3,29.3,0,0,1,198,224c-16.5,0-30-14.4-30-32s13.5-32,30-32a29.3,29.3,0,0,1,19.4,7.5,8,8,0,0,1,.5,11.3,7.9,7.9,0,0,1-11.3.6A12.9,12.9,0,0,0,198,176c-7.7,0-14,7.2-14,16s6.3,16,14,16a12.9,12.9,0,0,0,8.6-3.4A7.9,7.9,0,0,1,217.9,205.2ZM48,136a8,8,0,0,0,8-8V40h88V88a8,8,0,0,0,8,8h48v32a8,8,0,0,0,16,0V88a7.8,7.8,0,0,0-2.4-5.7l-55.9-56A8.1,8.1,0,0,0,152,24H56A16,16,0,0,0,40,40v88A8,8,0,0,0,48,136ZM160,51.3,188.7,80H160Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M58,160H44a8,8,0,0,0-8,8v48a8,8,0,0,0,8,8H58a32,32,0,0,0,0-64Zm0,48H52V176h6a16,16,0,0,1,0,32Zm72-48c-16.5,0-30,14.4-30,32s13.5,32,30,32,30-14.4,30-32S146.5,160,130,160Zm0,48c-7.7,0-14-7.2-14-16s6.3-16,14-16,14,7.2,14,16S137.7,208,130,208Zm87.9-2.8a8,8,0,0,1-.5,11.3A29.3,29.3,0,0,1,198,224c-16.5,0-30-14.4-30-32s13.5-32,30-32a29.3,29.3,0,0,1,19.4,7.5,8,8,0,0,1,.5,11.3,7.9,7.9,0,0,1-11.3.6A12.9,12.9,0,0,0,198,176c-7.7,0-14,7.2-14,16s6.3,16,14,16a12.9,12.9,0,0,0,8.6-3.4A7.9,7.9,0,0,1,217.9,205.2ZM48,136H208a8,8,0,0,0,8-8V88a8.1,8.1,0,0,0-2.3-5.7l-56-56A8.1,8.1,0,0,0,152,24H56A16,16,0,0,0,40,40v88A8,8,0,0,0,48,136ZM152,44l44,44H152Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M58,162H44a6,6,0,0,0-6,6v48a6,6,0,0,0,6,6H58a30,30,0,0,0,0-60Zm0,48H50V174h8a18,18,0,0,1,0,36Zm72-48c-15.4,0-28,13.5-28,30s12.6,30,28,30,28-13.5,28-30S145.4,162,130,162Zm0,48c-8.8,0-16-8.1-16-18s7.2-18,16-18,16,8.1,16,18S138.8,210,130,210Zm86.5-3.5a6,6,0,0,1-.5,8.5,26.9,26.9,0,0,1-18,7c-15.4,0-28-13.5-28-30s12.6-30,28-30a26.9,26.9,0,0,1,18,7,6,6,0,0,1,.5,8.5,5.9,5.9,0,0,1-8.5.4,15.1,15.1,0,0,0-10-3.9c-8.8,0-16,8.1-16,18s7.2,18,16,18a15.1,15.1,0,0,0,10-3.9A5.9,5.9,0,0,1,216.5,206.5ZM48,134a6,6,0,0,0,6-6V40a2,2,0,0,1,2-2h90V88a6,6,0,0,0,6,6h50v34a6,6,0,0,0,12,0V88a6,6,0,0,0-1.8-4.3l-56-55.9A5.6,5.6,0,0,0,152,26H56A14,14,0,0,0,42,40v88A6,6,0,0,0,48,134ZM158,46.5,193.5,82H158Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M58,164H44a4,4,0,0,0-4,4v48a4,4,0,0,0,4,4H58a28,28,0,0,0,0-56Zm0,48H48V172H58a20,20,0,0,1,0,40Zm72-48c-14.3,0-26,12.6-26,28s11.7,28,26,28,26-12.6,26-28S144.3,164,130,164Zm0,48c-9.9,0-18-9-18-20s8.1-20,18-20,18,9,18,20S139.9,212,130,212Zm85-4.2a4.1,4.1,0,0,1-.3,5.7A25,25,0,0,1,198,220c-14.3,0-26-12.6-26-28s11.7-28,26-28a25,25,0,0,1,16.7,6.5,4.1,4.1,0,0,1,.3,5.7,4.2,4.2,0,0,1-5.7.3A16.2,16.2,0,0,0,198,172c-9.9,0-18,9-18,20s8.1,20,18,20a16.2,16.2,0,0,0,11.3-4.5A4.2,4.2,0,0,1,215,207.8ZM48,132a4,4,0,0,0,4-4V40a4,4,0,0,1,4-4h92V88a4,4,0,0,0,4,4h52v36a4,4,0,0,0,8,0V88.1h0a4.5,4.5,0,0,0-1.2-2.9l-56-55.9A3.6,3.6,0,0,0,152,28H56A12,12,0,0,0,44,40v88A4,4,0,0,0,48,132ZM156,41.7,198.3,84H156Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M58,160H44a8,8,0,0,0-8,8v48a8,8,0,0,0,8,8H58a32,32,0,0,0,0-64Zm0,48H52V176h6a16,16,0,0,1,0,32Zm72-48c-16.5,0-30,14.4-30,32s13.5,32,30,32,30-14.4,30-32S146.5,160,130,160Zm0,48c-7.7,0-14-7.2-14-16s6.3-16,14-16,14,7.2,14,16S137.7,208,130,208Zm87.9-2.8a8,8,0,0,1-.5,11.3A29.3,29.3,0,0,1,198,224c-16.5,0-30-14.4-30-32s13.5-32,30-32a29.3,29.3,0,0,1,19.4,7.5,8,8,0,0,1,.5,11.3,7.9,7.9,0,0,1-11.3.6A12.9,12.9,0,0,0,198,176c-7.7,0-14,7.2-14,16s6.3,16,14,16a12.9,12.9,0,0,0,8.6-3.4A7.9,7.9,0,0,1,217.9,205.2ZM48,136a8,8,0,0,0,8-8V40h88V88a8,8,0,0,0,8,8h48v32a8,8,0,0,0,16,0V88a7.8,7.8,0,0,0-2.4-5.7l-55.9-56A8.1,8.1,0,0,0,152,24H56A16,16,0,0,0,40,40v88A8,8,0,0,0,48,136ZM160,51.3,188.7,80H160Z" />
        </>
      )}
    </svg>
  );
}

export function PhFileDotted(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M220,92V88a12.3,12.3,0,0,0-3.5-8.5l-56-56A12.3,12.3,0,0,0,152,20H120a12,12,0,0,0,0,24h16V92a12,12,0,0,0,12,12h48v32a12,12,0,0,0,24,0V92ZM160,57l23,23H160ZM84,224a12,12,0,0,1-12,12H56a20.1,20.1,0,0,1-20-20V184a12,12,0,0,1,24,0v28H72A12,12,0,0,1,84,224ZM92,32A12,12,0,0,1,80,44H60V64a12,12,0,0,1-24,0V40A20.1,20.1,0,0,1,56,20H80A12,12,0,0,1,92,32ZM220,176v40a20.1,20.1,0,0,1-20,20h-8a12,12,0,0,1,0-24h4V176a12,12,0,0,1,24,0ZM36,144V104a12,12,0,0,1,24,0v40a12,12,0,0,1-24,0Zm128,80a12,12,0,0,1-12,12H112a12,12,0,0,1,0-24h40A12,12,0,0,1,164,224Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M208,88H152V32Z" opacity="0.2" />
          <path d="M80,224a8,8,0,0,1-8,8H56a16,16,0,0,1-16-16V184a8,8,0,0,1,16,0v32H72A8,8,0,0,1,80,224ZM80,24H56A16,16,0,0,0,40,40V64a8,8,0,0,0,16,0V40H80a8,8,0,0,0,0-16ZM216,88.2V136a8,8,0,0,1-16,0V96H152a8,8,0,0,1-8-8V40H120a8,8,0,0,1,0-16h34.3l.7.3h.1l.5.3h.2l.5.3c0,.1.1.1.2.2l.4.3h.2l.4.4h.2l55.9,56A7.8,7.8,0,0,1,216,88ZM160,80h28.7L160,51.3Zm48,88a8,8,0,0,0-8,8v40h-8a8,8,0,0,0,0,16h8a16,16,0,0,0,16-16V176A8,8,0,0,0,208,168ZM48,152a8,8,0,0,0,8-8V104a8,8,0,0,0-16,0v40A8,8,0,0,0,48,152Zm104,64H112a8,8,0,0,0,0,16h40a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M40,144V104a8,8,0,0,1,16,0v40a8,8,0,0,1-16,0ZM80,24H56A16,16,0,0,0,40,40V64a8,8,0,0,0,16,0V40H80a8,8,0,0,0,0-16ZM72,216H56V184a8,8,0,0,0-16,0v32a16,16,0,0,0,16,16H72a8,8,0,0,0,0-16ZM213.6,82.3l-55.9-56h-.2l-.4-.4h-.2l-.4-.3c-.1-.1-.2-.1-.2-.2l-.5-.3h-.2l-.5-.3H155l-.7-.3H120a8,8,0,0,0,0,16h24V88a8,8,0,0,0,8,8h48v40a8,8,0,0,0,16,0V88A7.8,7.8,0,0,0,213.6,82.3ZM208,168a8,8,0,0,0-8,8v40h-8a8,8,0,0,0,0,16h8a16,16,0,0,0,16-16V176A8,8,0,0,0,208,168Zm-56,48H112a8,8,0,0,0,0,16h40a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M78,224a6,6,0,0,1-6,6H56a14,14,0,0,1-14-14V184a6,6,0,0,1,12,0v32a2,2,0,0,0,2,2H72A6,6,0,0,1,78,224ZM80,26H56A14,14,0,0,0,42,40V64a6,6,0,0,0,12,0V40a2,2,0,0,1,2-2H80a6,6,0,0,0,0-12ZM214,88.2V136a6,6,0,0,1-12,0V94H152a6,6,0,0,1-6-6V38H120a6,6,0,0,1,0-12h34.2c.1,0,.1,0,.1.1a.5.5,0,0,1,.4.2h.1l.4.2h.1l.4.3h.1a.8.8,0,0,1,.4.4l56,55.9A6,6,0,0,1,214,88ZM158,82h35.5L158,46.5Zm50,88a6,6,0,0,0-6,6v40a2,2,0,0,1-2,2h-8a6,6,0,0,0,0,12h8a14,14,0,0,0,14-14V176A6,6,0,0,0,208,170ZM48,150a6,6,0,0,0,6-6V104a6,6,0,0,0-12,0v40A6,6,0,0,0,48,150Zm104,68H112a6,6,0,0,0,0,12h40a6,6,0,0,0,0-12Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M76,224a4,4,0,0,1-4,4H56a12,12,0,0,1-12-12V184a4,4,0,0,1,8,0v32a4,4,0,0,0,4,4H72A4,4,0,0,1,76,224ZM80,28H56A12,12,0,0,0,44,40V64a4,4,0,0,0,8,0V40a4,4,0,0,1,4-4H80a4,4,0,0,0,0-8ZM212,88.1V136a4,4,0,0,1-8,0V92H152a4,4,0,0,1-4-4V36H120a4,4,0,0,1,0-8h33.5l.6.3h.1l.6.5,56,55.9A4.5,4.5,0,0,1,212,88ZM156,84h42.3L156,41.7Zm52,88a4,4,0,0,0-4,4v40a4,4,0,0,1-4,4h-8a4,4,0,0,0,0,8h8a12,12,0,0,0,12-12V176A4,4,0,0,0,208,172ZM48,148a4,4,0,0,0,4-4V104a4,4,0,0,0-8,0v40A4,4,0,0,0,48,148Zm104,72H112a4,4,0,0,0,0,8h40a4,4,0,0,0,0-8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M80,224a8,8,0,0,1-8,8H56a16,16,0,0,1-16-16V184a8,8,0,0,1,16,0v32H72A8,8,0,0,1,80,224ZM80,24H56A16,16,0,0,0,40,40V64a8,8,0,0,0,16,0V40H80a8,8,0,0,0,0-16ZM216,88.2V136a8,8,0,0,1-16,0V96H152a8,8,0,0,1-8-8V40H120a8,8,0,0,1,0-16h34.3l.7.3h.1l.6.3h.1l.5.3c0,.1.1.1.1.2l.5.3h.2l.4.4h.2l55.9,56A7.8,7.8,0,0,1,216,88ZM160,80h28.7L160,51.3Zm48,88a8,8,0,0,0-8,8v40h-8a8,8,0,0,0,0,16h8a16,16,0,0,0,16-16V176A8,8,0,0,0,208,168ZM48,152a8,8,0,0,0,8-8V104a8,8,0,0,0-16,0v40A8,8,0,0,0,48,152Zm104,64H112a8,8,0,0,0,0,16h40a8,8,0,0,0,0-16Z" />
        </>
      )}
    </svg>
  );
}

export function PhFileHtml(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M256,220a12,12,0,0,1-12,12H220a12,12,0,0,1-12-12V164a12,12,0,0,1,24,0v44h12A12,12,0,0,1,256,220Zm-64.2-67.4a12.1,12.1,0,0,0-13.4,4.2L164,176l-14.4-19.2-.2-.2-.3-.4-.5-.5-.2-.2a10.7,10.7,0,0,0-1.6-1.4l-.3-.2-.6-.3-.4-.2-.6-.3-.4-.2-.7-.3h-.4l-.9-.2H60a11.9,11.9,0,0,0-11.9,10.8A4.9,4.9,0,0,0,48,164v16H32V164a12,12,0,0,0-24,0v56a12,12,0,0,0,24,0V204H48v16a12,12,0,0,0,24,0V176H88v44a12,12,0,0,0,24,0V176h16v44a12,12,0,0,0,24,0V200l2.4,3.2a12,12,0,0,0,19.2,0L176,200v20a12,12,0,0,0,24,0V164A12,12,0,0,0,191.8,152.6ZM212,136a12,12,0,0,1-12-12V112H148a12,12,0,0,1-12-12V48H56v76a12,12,0,0,1-24,0V44A20.1,20.1,0,0,1,52,24H156a12.3,12.3,0,0,1,8.5,3.5l56,56A12.3,12.3,0,0,1,224,92v32A12,12,0,0,1,212,136ZM160,88h31L160,57Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M208,88H152V32Z" opacity="0.2" />
          <path d="M128,176a8,8,0,0,1-8,8h-8v32a8,8,0,0,1-16,0V184H88a8,8,0,0,1,0-16h32A8,8,0,0,1,128,176Zm-64-8a8,8,0,0,0-8,8v12H44V176a8,8,0,0,0-16,0v40a8,8,0,0,0,16,0V204H56v12a8,8,0,0,0,16,0V176A8,8,0,0,0,64,168Zm172,40H220V176a8,8,0,0,0-16,0v40a8,8,0,0,0,8,8h24a8,8,0,0,0,0-16Zm-49.6-39.6a7.8,7.8,0,0,0-8.9,3L164,190.2l-13.5-18.8A8,8,0,0,0,136,176v40a8,8,0,0,0,16,0V201l5.5,7.6a7.9,7.9,0,0,0,13,0L176,201v15a8,8,0,0,0,16,0V176A7.8,7.8,0,0,0,186.4,168.4ZM208,136a8,8,0,0,1-8-8V96H152a8,8,0,0,1-8-8V40H56v88a8,8,0,0,1-16,0V40A16,16,0,0,1,56,24h96a8.1,8.1,0,0,1,5.7,2.3l55.9,56A7.8,7.8,0,0,1,216,88v40A8,8,0,0,1,208,136ZM160,80h28.7L160,51.3Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M120,168H88a8,8,0,0,0,0,16h8v32a8,8,0,0,0,16,0V184h8a8,8,0,0,0,0-16Z" />
          <path d="M64,168a8,8,0,0,0-8,8v12H44V176a8,8,0,0,0-16,0v40a8,8,0,0,0,16,0V204H56v12a8,8,0,0,0,16,0V176A8,8,0,0,0,64,168Z" />
          <path d="M236,208H220V176a8,8,0,0,0-16,0v40a8,8,0,0,0,8,8h24a8,8,0,0,0,0-16Z" />
          <path d="M186.4,168.4a7.8,7.8,0,0,0-8.9,3L164,190.2l-13.5-18.8A8,8,0,0,0,136,176v40a8,8,0,0,0,16,0V201l5.5,7.6a7.9,7.9,0,0,0,13,0L176,201v15a8,8,0,0,0,16,0V176A7.8,7.8,0,0,0,186.4,168.4Z" />
          <path d="M48,136H208a8,8,0,0,0,8-8V88a8.1,8.1,0,0,0-2.3-5.7l-56-56A8.1,8.1,0,0,0,152,24H56A16,16,0,0,0,40,40v88A8,8,0,0,0,48,136ZM152,44l44,44H152Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M126,176a6,6,0,0,1-6,6H110v34a6,6,0,0,1-12,0V182H88a6,6,0,0,1,0-12h32A6,6,0,0,1,126,176Zm-62-6a6,6,0,0,0-6,6v14H42V176a6,6,0,0,0-12,0v40a6,6,0,0,0,12,0V202H58v14a6,6,0,0,0,12,0V176A6,6,0,0,0,64,170Zm172,40H218V176a6,6,0,0,0-12,0v40a6,6,0,0,0,6,6h24a6,6,0,0,0,0-12Zm-50.2-39.7a6,6,0,0,0-6.7,2.2L164,193.7l-15.1-21.2A6,6,0,0,0,138,176v40a6,6,0,0,0,12,0V194.7l9.1,12.8a6.1,6.1,0,0,0,9.8,0l9.1-12.8V216a6,6,0,0,0,12,0V176A6,6,0,0,0,185.8,170.3ZM208,142a6,6,0,0,1-6-6V94H152a6,6,0,0,1-6-6V38H56a2,2,0,0,0-2,2v96a6,6,0,0,1-12,0V40A14,14,0,0,1,56,26h96a5.6,5.6,0,0,1,4.2,1.8l56,55.9A6,6,0,0,1,214,88v48A6,6,0,0,1,208,142ZM158,82h35.5L158,46.5Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M124,176a4,4,0,0,1-4,4H108v36a4,4,0,0,1-8,0V180H88a4,4,0,0,1,0-8h32A4,4,0,0,1,124,176Zm-60-4a4,4,0,0,0-4,4v16H40V176a4,4,0,0,0-8,0v40a4,4,0,0,0,8,0V200H60v16a4,4,0,0,0,8,0V176A4,4,0,0,0,64,172Zm172,40H216V176a4,4,0,0,0-8,0v40a4,4,0,0,0,4,4h24a4,4,0,0,0,0-8Zm-50.8-39.8a4.2,4.2,0,0,0-4.5,1.5L164,197.1l-16.7-23.4a4.2,4.2,0,0,0-4.5-1.5A4,4,0,0,0,140,176v40a4,4,0,0,0,8,0V188.5l12.7,17.8a4.1,4.1,0,0,0,6.6,0L180,188.5V216a4,4,0,0,0,8,0V176A4,4,0,0,0,185.2,172.2ZM208,140a4,4,0,0,1-4-4V92H152a4,4,0,0,1-4-4V36H56a4,4,0,0,0-4,4v96a4,4,0,0,1-8,0V40A12,12,0,0,1,56,28h96a3.6,3.6,0,0,1,2.8,1.2l56,55.9A4.5,4.5,0,0,1,212,88h0V136A4,4,0,0,1,208,140ZM156,84h42.3L156,41.7Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,176a8,8,0,0,1-8,8h-8v32a8,8,0,0,1-16,0V184H88a8,8,0,0,1,0-16h32A8,8,0,0,1,128,176Zm-64-8a8,8,0,0,0-8,8v12H44V176a8,8,0,0,0-16,0v40a8,8,0,0,0,16,0V204H56v12a8,8,0,0,0,16,0V176A8,8,0,0,0,64,168Zm172,40H220V176a8,8,0,0,0-16,0v40a8,8,0,0,0,8,8h24a8,8,0,0,0,0-16Zm-49.6-39.6a7.8,7.8,0,0,0-8.9,3L164,190.2l-13.5-18.8A8,8,0,0,0,136,176v40a8,8,0,0,0,16,0V201l5.5,7.6a7.9,7.9,0,0,0,13,0L176,201v15a8,8,0,0,0,16,0V176A7.8,7.8,0,0,0,186.4,168.4ZM208,144a8,8,0,0,1-8-8V96H152a8,8,0,0,1-8-8V40H56v96a8,8,0,0,1-16,0V40A16,16,0,0,1,56,24h96a8.1,8.1,0,0,1,5.7,2.3l55.9,56A7.8,7.8,0,0,1,216,88v48A8,8,0,0,1,208,144ZM160,80h28.7L160,51.3Z" />
        </>
      )}
    </svg>
  );
}

export function PhFileImage(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M117.3,150.3a16,16,0,0,0-26.6,0L76.5,171.5l-3-4.8a16.1,16.1,0,0,0-27,0L13.9,217.5A12,12,0,0,0,24,236H152a12,12,0,0,0,10.6-6.3,12.2,12.2,0,0,0-.6-12.4ZM46,212l14-21.8,3,4.6a16,16,0,0,0,13.3,7.4h.1A15.8,15.8,0,0,0,89.7,195L104,173.6,129.6,212ZM216.5,79.5l-56-56A12.3,12.3,0,0,0,152,20H56A20.1,20.1,0,0,0,36,40v88a12,12,0,0,0,24,0V44h76V92a12,12,0,0,0,12,12h48V212a12,12,0,0,0,0,24h4a20.1,20.1,0,0,0,20-20V88A12.3,12.3,0,0,0,216.5,79.5ZM160,57l23,23H160Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M107.3,157,152,224H24l32.6-50.8a4.1,4.1,0,0,1,6.8,0l9.7,15.1a4,4,0,0,0,6.7.1L100.7,157A3.9,3.9,0,0,1,107.3,157ZM152,32V88h56Z"
            opacity="0.2"
          />
          <path d="M114,152.6a12,12,0,0,0-20,0L76.5,178.9l-6.4-10a12,12,0,0,0-20.2,0L17.3,219.7a7.9,7.9,0,0,0-.3,8.1,8,8,0,0,0,7,4.2H152a8,8,0,0,0,7.1-4.2,8.3,8.3,0,0,0-.4-8.2ZM38.7,216,60,182.8l6.3,9.8a12.1,12.1,0,0,0,10,5.6,12,12,0,0,0,10.1-5.4L104,166.4,137.1,216ZM216,88a7.8,7.8,0,0,0-2.4-5.7l-55.9-56A8.1,8.1,0,0,0,152,24H56A16,16,0,0,0,40,40v96a8,8,0,0,0,16,0V40h88V88a8,8,0,0,0,8,8h48V216h-8a8,8,0,0,0,0,16h8a16,16,0,0,0,16-16V88ZM160,51.3,188.7,80H160Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M213.7,82.3l-56-56A8.1,8.1,0,0,0,152,24H56A16,16,0,0,0,40,40v96a8,8,0,0,0,16,0V40h88V88a8,8,0,0,0,8,8h48V216h-8a8,8,0,0,0,0,16h8a16,16,0,0,0,16-16V88A8.1,8.1,0,0,0,213.7,82.3ZM160,80V51.3L188.7,80Zm-1.3,139.6a8.3,8.3,0,0,1,.4,8.2A8,8,0,0,1,152,232H24a8,8,0,0,1-7-4.2,7.9,7.9,0,0,1,.3-8.1l32.6-50.8a12,12,0,0,1,20.2,0l6.4,10L94,152.6a12,12,0,0,1,20,0Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M112.3,153.7a9.9,9.9,0,0,0-16.6,0L76.5,182.5,68.4,170a10,10,0,0,0-16.8,0L19,220.8a6,6,0,0,0-.3,6.1A6.1,6.1,0,0,0,24,230H152a6,6,0,0,0,5-9.3ZM35,218l25-38.9,8,12.5a10.3,10.3,0,0,0,8.3,4.6h.1a9.8,9.8,0,0,0,8.3-4.5L104,162.8,140.8,218ZM214,88a6,6,0,0,0-1.8-4.3l-56-55.9A5.6,5.6,0,0,0,152,26H56A14,14,0,0,0,42,40v96a6,6,0,0,0,12,0V40a2,2,0,0,1,2-2h90V88a6,6,0,0,0,6,6h50V216a2,2,0,0,1-2,2h-8a6,6,0,0,0,0,12h8a14,14,0,0,0,14-14V88ZM158,46.5,193.5,82H158Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M110.7,154.8a8,8,0,0,0-13.4,0L76.4,186.2l-9.7-15.1a7.9,7.9,0,0,0-13.4,0L20.6,221.8a4,4,0,0,0-.1,4.1A3.9,3.9,0,0,0,24,228H152a3.9,3.9,0,0,0,3.5-2.1,4,4,0,0,0-.2-4.1ZM31.3,220,60,175.4l9.7,15.1a8.3,8.3,0,0,0,6.7,3.7,8.2,8.2,0,0,0,6.7-3.6L104,159.2,144.5,220ZM212,88a4.5,4.5,0,0,0-1.2-2.9l-56-55.9A3.6,3.6,0,0,0,152,28H56A12,12,0,0,0,44,40v96a4,4,0,0,0,8,0V40a4,4,0,0,1,4-4h92V88a4,4,0,0,0,4,4h52V216a4,4,0,0,1-4,4h-8a4,4,0,0,0,0,8h8a12,12,0,0,0,12-12V88.1ZM156,41.7,198.3,84H156Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M114,152.6a12,12,0,0,0-20,0L76.5,178.9l-6.4-10a12,12,0,0,0-20.2,0L17.3,219.7a7.9,7.9,0,0,0-.3,8.1,8,8,0,0,0,7,4.2H152a8,8,0,0,0,7.1-4.2,8.3,8.3,0,0,0-.4-8.2ZM38.7,216,60,182.8l6.3,9.8a12.1,12.1,0,0,0,10,5.6,12,12,0,0,0,10.1-5.4L104,166.4,137.1,216ZM216,88a7.8,7.8,0,0,0-2.4-5.7l-55.9-56A8.1,8.1,0,0,0,152,24H56A16,16,0,0,0,40,40v96a8,8,0,0,0,16,0V40h88V88a8,8,0,0,0,8,8h48V216h-8a8,8,0,0,0,0,16h8a16,16,0,0,0,16-16V88ZM160,51.3,188.7,80H160Z" />
        </>
      )}
    </svg>
  );
}

export function PhFileJpg(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M200,124a12,12,0,0,0,24,0V92a12.3,12.3,0,0,0-3.5-8.5l-56-56A12.3,12.3,0,0,0,156,24H52A20.1,20.1,0,0,0,32,44v80a12,12,0,0,0,24,0V48h80v52a12,12,0,0,0,12,12h52ZM160,57l31,31H160Zm-44,95H100a12,12,0,0,0-12,12v56a12,12,0,0,0,24,0v-4h4a32,32,0,0,0,0-64Zm0,40h-4V176h4a8,8,0,0,1,0,16ZM72,164v36a32,32,0,0,1-64,0,12,12,0,0,1,24,0,8,8,0,0,0,16,0V164a12,12,0,0,1,24,0Zm160,34.9v8a12.9,12.9,0,0,1-1.6,6.1c-7,11.9-19.1,19-32.4,19-21,0-38-17.9-38-40s17-40,38-40a37.1,37.1,0,0,1,20.8,6.5,12,12,0,0,1-13.6,19.8A12.8,12.8,0,0,0,198,176c-7.7,0-14,7.2-14,16s6.3,16,14,16a12.6,12.6,0,0,0,5.1-1.1,11.9,11.9,0,0,1-3.1-8,12,12,0,0,1,12-12h8A12,12,0,0,1,232,198.9Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M208,88H152V32Z" opacity="0.2" />
          <path d="M216,88a7.8,7.8,0,0,0-2.4-5.7l-55.9-56A8.1,8.1,0,0,0,152,24H56A16,16,0,0,0,40,40v88a8,8,0,0,0,16,0V40h88V88a8,8,0,0,0,8,8h48v32a8,8,0,0,0,16,0V88ZM160,51.3,188.7,80H160ZM120,160H104a8,8,0,0,0-8,8v48a8,8,0,0,0,16,0v-8h8a24,24,0,0,0,0-48Zm0,32h-8V176h8a8,8,0,0,1,0,16ZM80,168v32a24,24,0,0,1-48,0,8,8,0,0,1,16,0,8,8,0,0,0,16,0V168a8,8,0,0,1,16,0Zm128,26v11.8a7.5,7.5,0,0,1-1.3,4.3A29.4,29.4,0,0,1,182,224c-16.5,0-30-14.4-30-32s13.5-32,30-32a28.4,28.4,0,0,1,16.6,5.4,8,8,0,0,1-9.2,13A12.6,12.6,0,0,0,182,176c-7.7,0-14,7.2-14,16s6.3,16,14,16a13.3,13.3,0,0,0,10-4.8V202h-4a8,8,0,0,1,0-16h12A8,8,0,0,1,208,194Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M120,160H104a8,8,0,0,0-8,8v48a8,8,0,0,0,16,0v-8h8a24,24,0,0,0,0-48Zm0,32h-8V176h8a8,8,0,0,1,0,16ZM80,168v32a24,24,0,0,1-48,0,8,8,0,0,1,16,0,8,8,0,0,0,16,0V168a8,8,0,0,1,16,0Zm128,26v11.8a7.5,7.5,0,0,1-1.3,4.3A29.4,29.4,0,0,1,182,224c-16.5,0-30-14.4-30-32s13.5-32,30-32a28.4,28.4,0,0,1,16.6,5.4,8,8,0,0,1-9.2,13A12.6,12.6,0,0,0,182,176c-7.7,0-14,7.2-14,16s6.3,16,14,16a13.3,13.3,0,0,0,10-4.8V202h-4a8,8,0,0,1,0-16h12A8,8,0,0,1,208,194Zm5.7-111.7-56-56A8.1,8.1,0,0,0,152,24H56A16,16,0,0,0,40,40v88a8,8,0,0,0,8,8H208a8,8,0,0,0,8-8V88A8.1,8.1,0,0,0,213.7,82.3ZM152,88V44l44,44Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M120,162H104a6,6,0,0,0-6,6v48a6,6,0,0,0,12,0V206h10a22,22,0,0,0,0-44Zm0,32H110V174h10a10,10,0,0,1,0,20ZM78,168v32a22,22,0,0,1-44,0,6,6,0,0,1,12,0,10,10,0,0,0,20,0V168a6,6,0,0,1,12,0Zm128,26v11.8a5.6,5.6,0,0,1-1,3.3A27.4,27.4,0,0,1,182,222c-15.4,0-28-13.5-28-30s12.6-30,28-30a26.5,26.5,0,0,1,15.5,5,6,6,0,1,1-7,9.8A14.7,14.7,0,0,0,182,174c-8.8,0-16,8.1-16,18s7.2,18,16,18a15.5,15.5,0,0,0,12-6.1V200h-6a6,6,0,0,1,0-12h12A6,6,0,0,1,206,194Zm8-106a6,6,0,0,0-1.8-4.3l-56-55.9A5.6,5.6,0,0,0,152,26H56A14,14,0,0,0,42,40v88a6,6,0,0,0,12,0V40a2,2,0,0,1,2-2h90V88a6,6,0,0,0,6,6h50v34a6,6,0,0,0,12,0V88ZM158,46.5,193.5,82H158Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M120,164H104a4,4,0,0,0-4,4v48a4,4,0,0,0,8,0V204h12a20,20,0,0,0,0-40Zm0,32H108V172h12a12,12,0,0,1,0,24ZM76,168v32a20,20,0,0,1-40,0,4,4,0,0,1,8,0,12,12,0,0,0,24,0V168a4,4,0,0,1,8,0Zm128,26v11.8a4.3,4.3,0,0,1-.6,2.2A25.7,25.7,0,0,1,182,220c-14.3,0-26-12.6-26-28s11.7-28,26-28a24.6,24.6,0,0,1,14.3,4.6,4.1,4.1,0,0,1,1,5.6,4,4,0,0,1-5.6.9A17.2,17.2,0,0,0,182,172c-9.9,0-18,9-18,20s8.1,20,18,20a17.6,17.6,0,0,0,14-7.4V198h-8a4,4,0,0,1,0-8h12A4,4,0,0,1,204,194Zm8-106a4.5,4.5,0,0,0-1.2-2.9l-56-55.9A3.6,3.6,0,0,0,152,28H56A12,12,0,0,0,44,40v88a4,4,0,0,0,8,0V40a4,4,0,0,1,4-4h92V88a4,4,0,0,0,4,4h52v36a4,4,0,0,0,8,0V88.1ZM156,41.7,198.3,84H156Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M120,160H104a8,8,0,0,0-8,8v48a8,8,0,0,0,16,0v-8h8a24,24,0,0,0,0-48Zm0,32h-8V176h8a8,8,0,0,1,0,16ZM80,168v32a24,24,0,0,1-48,0,8,8,0,0,1,16,0,8,8,0,0,0,16,0V168a8,8,0,0,1,16,0Zm128,26v11.8a7.5,7.5,0,0,1-1.3,4.3A29.4,29.4,0,0,1,182,224c-16.5,0-30-14.4-30-32s13.5-32,30-32a28.4,28.4,0,0,1,16.6,5.4,8,8,0,0,1-9.2,13A12.6,12.6,0,0,0,182,176c-7.7,0-14,7.2-14,16s6.3,16,14,16a13.3,13.3,0,0,0,10-4.8V202h-4a8,8,0,0,1,0-16h12A8,8,0,0,1,208,194Zm8-106a7.8,7.8,0,0,0-2.4-5.7l-55.9-56A8.1,8.1,0,0,0,152,24H56A16,16,0,0,0,40,40v88a8,8,0,0,0,16,0V40h88V88a8,8,0,0,0,8,8h48v32a8,8,0,0,0,16,0V88ZM160,51.3,188.7,80H160Z" />
        </>
      )}
    </svg>
  );
}

export function PhFileJs(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M224,92a12.3,12.3,0,0,0-3.5-8.5l-56-56A12.3,12.3,0,0,0,156,24H52A20.1,20.1,0,0,0,32,44v80a12,12,0,0,0,24,0V48h80v52a12,12,0,0,0,12,12h52v96H180a12,12,0,0,0,0,24h24a20.1,20.1,0,0,0,20-20ZM160,57l31,31H160ZM80,164v36a32,32,0,0,1-64,0,12,12,0,0,1,24,0,8,8,0,0,0,16,0V164a12,12,0,0,1,24,0Zm72,41.4c0,16.2-11.4,26.6-29,26.6a36.2,36.2,0,0,1-23.2-8.8h0a12,12,0,0,1,8.2-20.8,12,12,0,0,1,8.2,3.2H116a12,12,0,0,0,7,2.5c3.1,0,4.5-.6,4.8-.8a3.7,3.7,0,0,0,.2-1.4c-1.6-1-5.4-2.1-7.8-2.8-8.5-2.5-24.2-7.2-24.2-24.4,0-15.4,11.4-26.6,27-26.6a36.2,36.2,0,0,1,23.2,8.8h0a12,12,0,0,1-8.2,20.8,12,12,0,0,1-8.2-3.2h.2a12,12,0,0,0-7-2.5c-2.3,0-2.8.6-3,1.6,1.7.8,4.9,1.7,7,2.4C135.8,182.6,152,187.4,152,205.4Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M208,88H152V32Z" opacity="0.2" />
          <path d="M80,168v32a24,24,0,0,1-48,0,8,8,0,0,1,16,0,8,8,0,0,0,16,0V168a8,8,0,0,1,16,0ZM216,88.2V216a16,16,0,0,1-16,16H176a8,8,0,0,1,0-16h24V96H152a8,8,0,0,1-8-8V40H56v88a8,8,0,0,1-16,0V40A16,16,0,0,1,56,24h96a8.1,8.1,0,0,1,5.7,2.3l55.9,56A7.8,7.8,0,0,1,216,88ZM160,80h28.7L160,51.3ZM121.7,183.8c-3.1-.8-8.6-2.3-9.7-3.6V180c0-3.3,2.4-5,7-5a18.4,18.4,0,0,1,10,3.2h-.1A8.3,8.3,0,0,0,134,180a8,8,0,0,0,8-8,7.6,7.6,0,0,0-2.9-6.1h0A33.4,33.4,0,0,0,119,159c-13.5,0-23,8.6-23,21s13,17,21.6,19.3c3.4.9,7,1.8,9.2,3.1s1.2,1,1.2,1.6,0,5-9,5a18.4,18.4,0,0,1-10-3.2h.1A8.3,8.3,0,0,0,104,204a8,8,0,0,0-8,8,7.6,7.6,0,0,0,2.9,6.1h0A33.4,33.4,0,0,0,119,225c15.6,0,25-7.9,25-21S130,186,121.7,183.8Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M144,204c0,13.1-9.3,21-25,21a33.4,33.4,0,0,1-20.1-6.8h0A7.6,7.6,0,0,1,96,212a8,8,0,0,1,8-8,8.3,8.3,0,0,1,5.1,1.8H109a18.4,18.4,0,0,0,10,3.2c9,0,9-3,9-5s0-.9-1.2-1.6-5.8-2.2-9.2-3.1C109,197,96,193.5,96,180s9.5-21,23-21a33.4,33.4,0,0,1,20.1,6.8h0A7.6,7.6,0,0,1,142,172a8,8,0,0,1-8,8,8.3,8.3,0,0,1-5.1-1.8h.1a18.4,18.4,0,0,0-10-3.2c-4.6,0-7,1.7-7,5v.2c1.1,1.3,6.6,2.8,9.7,3.6C130,186,144,189.7,144,204ZM72,160a8,8,0,0,0-8,8v32a8,8,0,0,1-16,0,8,8,0,0,0-16,0,24,24,0,0,0,48,0V168A8,8,0,0,0,72,160ZM216,88V216a16,16,0,0,1-16,16H176a8,8,0,0,1-8-8V144a8,8,0,0,0-8-8H48a8,8,0,0,1-8-8V40A16,16,0,0,1,56,24h96a8.1,8.1,0,0,1,5.7,2.3l56,56A8.1,8.1,0,0,1,216,88Zm-20,0L152,44V88Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M78,168v32a22,22,0,0,1-44,0,6,6,0,0,1,12,0,10,10,0,0,0,20,0V168a6,6,0,0,1,12,0Zm43.2,17.7c-7.8-2-11.2-3.3-11.2-5.7,0-6.1,5.6-7,9-7a19.7,19.7,0,0,1,11.2,3.6,6,6,0,0,0,7.6-9.2A30,30,0,0,0,119,161c-12.4,0-21,7.8-21,19s11.6,15.1,20.1,17.3S130,201,130,204s0,7-11,7a20,20,0,0,1-11.2-3.6,6,6,0,1,0-7.6,9.2A30,30,0,0,0,119,223c14.4,0,23-7.1,23-19S129.5,187.9,121.2,185.7ZM214,88.2V216a14,14,0,0,1-14,14H176a6,6,0,0,1,0-12h24a2,2,0,0,0,2-2V94H152a6,6,0,0,1-6-6V38H56a2,2,0,0,0-2,2V144a6,6,0,0,1-12,0V40A14,14,0,0,1,56,26h96a5.6,5.6,0,0,1,4.2,1.8l56,55.9A6,6,0,0,1,214,88ZM158,82h35.5L158,46.5Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M76,168v32a20,20,0,0,1-40,0,4,4,0,0,1,8,0,12,12,0,0,0,24,0V168a4,4,0,0,1,8,0Zm44.6,19.7c-8.6-2.3-12.6-3.8-12.6-7.7,0-5.6,4.1-9,11-9a20.7,20.7,0,0,1,12.4,4.1,4,4,0,1,0,5.2-6.2A30,30,0,0,0,119,163c-11.4,0-19,6.8-19,17s10.3,13.2,18.6,15.4,13.4,4,13.4,8.6c0,2.5,0,9-13,9a21.5,21.5,0,0,1-12.5-4.1,4,4,0,0,0-5.6.5,4.1,4.1,0,0,0,.5,5.7A30,30,0,0,0,119,221c13.3,0,21-6.2,21-17S129.3,189.9,120.6,187.7ZM212,88.1V216a12,12,0,0,1-12,12H176a4,4,0,0,1,0-8h24a4,4,0,0,0,4-4V92H152a4,4,0,0,1-4-4V36H56a4,4,0,0,0-4,4V144a4,4,0,0,1-8,0V40A12,12,0,0,1,56,28h96a3.6,3.6,0,0,1,2.8,1.2l56,55.9A4.5,4.5,0,0,1,212,88ZM156,84h42.3L156,41.7Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M80,168v32a24,24,0,0,1-48,0,8,8,0,0,1,16,0,8,8,0,0,0,16,0V168a8,8,0,0,1,16,0ZM216,88.2V216a16,16,0,0,1-16,16H176a8,8,0,0,1,0-16h24V96H152a8,8,0,0,1-8-8V40H56v88a8,8,0,0,1-16,0V40A16,16,0,0,1,56,24h96a8.1,8.1,0,0,1,5.7,2.3l55.9,56A7.8,7.8,0,0,1,216,88ZM160,80h28.7L160,51.3ZM121.7,183.8c-3.1-.8-8.6-2.3-9.7-3.6V180c0-3.3,2.4-5,7-5a18.4,18.4,0,0,1,10,3.2h-.1A8.3,8.3,0,0,0,134,180a8,8,0,0,0,8-8,7.6,7.6,0,0,0-2.9-6.1h0A33.4,33.4,0,0,0,119,159c-13.5,0-23,8.6-23,21s13,17,21.6,19.3c3.4.9,7,1.8,9.2,3.1s1.2,1,1.2,1.6,0,5-9,5a18.4,18.4,0,0,1-10-3.2h.1A8.3,8.3,0,0,0,104,204a8,8,0,0,0-8,8,7.6,7.6,0,0,0,2.9,6.1h0A33.4,33.4,0,0,0,119,225c15.6,0,25-7.9,25-21S130,186,121.7,183.8Z" />
        </>
      )}
    </svg>
  );
}

export function PhFileJsx(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M221.8,171l-15.1,21,15.1,21a12,12,0,0,1-19.6,14L192,212.6,181.8,227a12,12,0,1,1-19.6-14l15.1-21-15.1-21a12,12,0,1,1,19.6-14L192,171.4,202.2,157a12,12,0,0,1,19.6,14ZM224,92v32a12,12,0,0,1-24,0V112H148a12,12,0,0,1-12-12V48H56v76a12,12,0,0,1-24,0V44A20.1,20.1,0,0,1,52,24H156a12.3,12.3,0,0,1,8.5,3.5l56,56A12.3,12.3,0,0,1,224,92Zm-64-4h31L160,57ZM60,152a12,12,0,0,0-12,12v36a8,8,0,0,1-16,0,12,12,0,0,0-24,0,32,32,0,0,0,64,0V164A12,12,0,0,0,60,152Zm59,28c-2.1-.7-5.3-1.6-7-2.4.2-1,.7-1.6,3-1.6a12,12,0,0,1,7,2.5h-.2a12,12,0,0,0,20.2-8.8,12.1,12.1,0,0,0-3.8-8.8h0A36.2,36.2,0,0,0,115,152c-15.6,0-27,11.2-27,26.6,0,17.2,15.7,21.9,24.2,24.4,2.4.7,6.2,1.8,7.8,2.8a3.7,3.7,0,0,1-.2,1.4c-.3.2-1.7.8-4.8.8a12,12,0,0,1-7-2.5h.2A12,12,0,0,0,88,214.4a12.1,12.1,0,0,0,3.8,8.8h0A36.2,36.2,0,0,0,115,232c17.6,0,29-10.4,29-26.6C144,187.4,127.8,182.6,119,180Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M208,88H152V32Z" opacity="0.2" />
          <path d="M206.4,172.8,192,192l14.4,19.2a8,8,0,0,1-1.6,11.2A7.7,7.7,0,0,1,200,224a8,8,0,0,1-6.4-3.2L182,205.3l-11.6,15.5A8,8,0,0,1,164,224a7.7,7.7,0,0,1-4.8-1.6,8,8,0,0,1-1.6-11.2L172,192l-14.4-19.2a8,8,0,0,1,12.8-9.6L182,178.7l11.6-15.5a8,8,0,1,1,12.8,9.6ZM72,160a8,8,0,0,0-8,8v32a8,8,0,0,1-16,0,8,8,0,0,0-16,0,24,24,0,0,0,48,0V168A8,8,0,0,0,72,160ZM216,88.2V128a8,8,0,0,1-16,0V96H152a8,8,0,0,1-8-8V40H56v88a8,8,0,0,1-16,0V40A16,16,0,0,1,56,24h96a8.1,8.1,0,0,1,5.7,2.3l55.9,56A7.8,7.8,0,0,1,216,88ZM160,80h28.7L160,51.3ZM121.7,183.8c-3.1-.8-8.6-2.3-9.7-3.6V180c0-3.3,2.4-5,7-5a18.4,18.4,0,0,1,10,3.2h-.1A8.3,8.3,0,0,0,134,180a8,8,0,0,0,8-8,7.6,7.6,0,0,0-2.9-6.1h0A33.4,33.4,0,0,0,119,159c-13.5,0-23,8.6-23,21s13,17,21.6,19.3c3.4.9,7,1.8,9.2,3.1s1.2,1,1.2,1.6,0,5-9,5a18.4,18.4,0,0,1-10-3.2h.1A8.3,8.3,0,0,0,104,204a8,8,0,0,0-8,8,7.6,7.6,0,0,0,2.9,6.1h0A33.4,33.4,0,0,0,119,225c15.6,0,25-7.9,25-21S130,186,121.7,183.8Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M80,168v32a24,24,0,0,1-48,0,8,8,0,0,1,16,0,8,8,0,0,0,16,0V168a8,8,0,0,1,16,0Zm41.7,15.8c-3.1-.8-8.6-2.3-9.7-3.6V180c0-3.3,2.4-5,7-5a18.4,18.4,0,0,1,10,3.2h-.1A8.3,8.3,0,0,0,134,180a8,8,0,0,0,8-8,7.6,7.6,0,0,0-2.9-6.1h0A33.4,33.4,0,0,0,119,159c-13.5,0-23,8.6-23,21s13,17,21.6,19.3c3.4.9,7,1.8,9.2,3.1s1.2,1,1.2,1.6,0,5-9,5a18.4,18.4,0,0,1-10-3.2h.1A8.3,8.3,0,0,0,104,204a8,8,0,0,0-8,8,7.6,7.6,0,0,0,2.9,6.1h0A33.4,33.4,0,0,0,119,225c15.7,0,25-7.9,25-21S130,186,121.7,183.8Zm83.1-22.2a8,8,0,0,0-11.2,1.6L182,178.7l-11.6-15.5a8,8,0,0,0-12.8,9.6L172,192l-14.4,19.2a8,8,0,0,0,1.6,11.2A7.7,7.7,0,0,0,164,224a8,8,0,0,0,6.4-3.2L182,205.3l11.6,15.5A8,8,0,0,0,200,224a7.7,7.7,0,0,0,4.8-1.6,8,8,0,0,0,1.6-11.2L192,192l14.4-19.2A8,8,0,0,0,204.8,161.6ZM216,88v40a8,8,0,0,1-8,8H48a8,8,0,0,1-8-8V40A16,16,0,0,1,56,24h96a8.1,8.1,0,0,1,5.7,2.3l56,56A8.1,8.1,0,0,1,216,88Zm-20,0L152,44V88Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M204.8,171.6,189.5,192l15.3,20.4a6,6,0,0,1-9.6,7.2L182,202l-13.2,17.6a6,6,0,1,1-9.6-7.2L174.5,192l-15.3-20.4a6,6,0,1,1,9.6-7.2L182,182l13.2-17.6a6,6,0,0,1,9.6,7.2ZM72,162a6,6,0,0,0-6,6v32a10,10,0,0,1-20,0,6,6,0,0,0-12,0,22,22,0,0,0,44,0V168A6,6,0,0,0,72,162Zm49.2,23.7c-7.8-2-11.2-3.3-11.2-5.7,0-6.1,5.6-7,9-7a19.7,19.7,0,0,1,11.2,3.6,6,6,0,0,0,7.6-9.2A30,30,0,0,0,119,161c-12.4,0-21,7.8-21,19s11.6,15.1,20.1,17.3S130,201,130,204s0,7-11,7a20,20,0,0,1-11.2-3.6,6,6,0,1,0-7.6,9.2A30,30,0,0,0,119,223c14.4,0,23-7.1,23-19S129.5,187.9,121.2,185.7ZM214,88.2V128a6,6,0,0,1-12,0V94H152a6,6,0,0,1-6-6V38H56a2,2,0,0,0-2,2v88a6,6,0,0,1-12,0V40A14,14,0,0,1,56,26h96a5.6,5.6,0,0,1,4.2,1.8l56,55.9A6,6,0,0,1,214,88ZM158,82h35.5L158,46.5Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M203.2,170.4,187,192l16.2,21.6a4,4,0,0,1-.8,5.6,4.3,4.3,0,0,1-2.4.8,4.2,4.2,0,0,1-3.2-1.6L182,198.7l-14.8,19.7A4.2,4.2,0,0,1,164,220a4.3,4.3,0,0,1-2.4-.8,4,4,0,0,1-.8-5.6L177,192l-16.2-21.6a4,4,0,1,1,6.4-4.8L182,185.3l14.8-19.7a4,4,0,0,1,6.4,4.8ZM72,164a4,4,0,0,0-4,4v32a12,12,0,0,1-24,0,4,4,0,0,0-8,0,20,20,0,0,0,40,0V168A4,4,0,0,0,72,164Zm48.6,23.7c-8.6-2.3-12.6-3.8-12.6-7.7,0-5.6,4.1-9,11-9a20.7,20.7,0,0,1,12.4,4.1,4,4,0,1,0,5.2-6.2A30,30,0,0,0,119,163c-11.4,0-19,6.8-19,17s10.3,13.2,18.6,15.4,13.4,4,13.4,8.6c0,2.5,0,9-13,9a21.5,21.5,0,0,1-12.5-4.1,4,4,0,0,0-5.6.5,4.1,4.1,0,0,0,.5,5.7A30,30,0,0,0,119,221c13.3,0,21-6.2,21-17S129.3,189.9,120.6,187.7ZM212,88.1V128a4,4,0,0,1-8,0V92H152a4,4,0,0,1-4-4V36H56a4,4,0,0,0-4,4v88a4,4,0,0,1-8,0V40A12,12,0,0,1,56,28h96a3.6,3.6,0,0,1,2.8,1.2l56,55.9A4.5,4.5,0,0,1,212,88ZM156,84h42.3L156,41.7Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M206.4,172.8,192,192l14.4,19.2a8,8,0,0,1-1.6,11.2A7.7,7.7,0,0,1,200,224a8,8,0,0,1-6.4-3.2L182,205.3l-11.6,15.5A8,8,0,0,1,164,224a7.7,7.7,0,0,1-4.8-1.6,8,8,0,0,1-1.6-11.2L172,192l-14.4-19.2a8,8,0,0,1,12.8-9.6L182,178.7l11.6-15.5a8.1,8.1,0,0,1,11.2-1.6A8,8,0,0,1,206.4,172.8ZM72,160a8,8,0,0,0-8,8v32a8,8,0,0,1-16,0,8,8,0,0,0-16,0,24,24,0,0,0,48,0V168A8,8,0,0,0,72,160ZM216,88.2V128a8,8,0,0,1-16,0V96H152a8,8,0,0,1-8-8V40H56v88a8,8,0,0,1-16,0V40A16,16,0,0,1,56,24h96a8.1,8.1,0,0,1,5.7,2.3l55.9,56A7.8,7.8,0,0,1,216,88ZM160,80h28.7L160,51.3ZM121.7,183.8c-3.1-.8-8.6-2.3-9.7-3.6V180c0-3.3,2.4-5,7-5a18.4,18.4,0,0,1,10,3.2h-.1A8.3,8.3,0,0,0,134,180a8,8,0,0,0,8-8,7.6,7.6,0,0,0-2.9-6.1h0A33.4,33.4,0,0,0,119,159c-13.5,0-23,8.6-23,21s13,17,21.6,19.3c3.4.9,7,1.8,9.2,3.1s1.2,1,1.2,1.6,0,5-9,5a18.4,18.4,0,0,1-10-3.2h.1A8.3,8.3,0,0,0,104,204a8,8,0,0,0-8,8,7.6,7.6,0,0,0,2.9,6.1h0A33.4,33.4,0,0,0,119,225c15.6,0,25-7.9,25-21S130,186,121.7,183.8Z" />
        </>
      )}
    </svg>
  );
}

export function PhFileLock(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216.5,79.5l-56-56A12.3,12.3,0,0,0,152,20H56A20.1,20.1,0,0,0,36,40v84a12,12,0,0,0,24,0V44h76V92a12,12,0,0,0,12,12h48V212H172a12,12,0,0,0,0,24h28a20.1,20.1,0,0,0,20-20V88A12.3,12.3,0,0,0,216.5,79.5ZM160,57l23,23H160ZM128,172h-4v-4a32,32,0,0,0-64,0v4H56a12,12,0,0,0-12,12v44a12,12,0,0,0,12,12h72a12,12,0,0,0,12-12V184A12,12,0,0,0,128,172Zm-44-4a8,8,0,0,1,16,0v4H84Zm32,48H68V196h48Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M56,180h72v44H56ZM152,32V88h56Z" opacity="0.2" />
          <path d="M216,88a7.8,7.8,0,0,0-2.4-5.7l-55.9-56A8.1,8.1,0,0,0,152,24H56A16,16,0,0,0,40,40v88a8,8,0,0,0,16,0V40h88V88a8,8,0,0,0,8,8h48V216H168a8,8,0,0,0,0,16h32a16,16,0,0,0,16-16V88ZM160,51.3,188.7,80H160ZM128,172h-8v-4a28,28,0,0,0-56,0v4H56a8,8,0,0,0-8,8v44a8,8,0,0,0,8,8h72a8,8,0,0,0,8-8V180A8,8,0,0,0,128,172Zm-48-4a12,12,0,0,1,24,0v4H80Zm40,48H64V188h56Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M213.7,82.3l-56-56A8.1,8.1,0,0,0,152,24H56A16,16,0,0,0,40,40v88a8,8,0,0,0,16,0V40h88V88a8,8,0,0,0,8,8h48V216H168a8,8,0,0,0,0,16h32a16,16,0,0,0,16-16V88A8.1,8.1,0,0,0,213.7,82.3ZM160,51.3,188.7,80H160ZM128,172h-8v-4a28,28,0,0,0-56,0v4H56a8,8,0,0,0-8,8v44a8,8,0,0,0,8,8h72a8,8,0,0,0,8-8V180A8,8,0,0,0,128,172Zm-24,0H80v-4a12,12,0,0,1,24,0Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M212.2,83.8l-56-56A5.6,5.6,0,0,0,152,26H56A14,14,0,0,0,42,40v88a6,6,0,0,0,12,0V40a2,2,0,0,1,2-2h90V88a6,6,0,0,0,6,6h50V216a2,2,0,0,1-2,2H168a6,6,0,0,0,0,12h32a14,14,0,0,0,14-14V88A5.6,5.6,0,0,0,212.2,83.8ZM158,46.5,193.5,82H158ZM128,174H118v-6a26,26,0,0,0-52,0v6H56a6,6,0,0,0-6,6v44a6,6,0,0,0,6,6h72a6,6,0,0,0,6-6V180A6,6,0,0,0,128,174Zm-50-6a14,14,0,0,1,28,0v6H78Zm44,50H62V186h60Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M210.8,85.2l-56-56A3.6,3.6,0,0,0,152,28H56A12,12,0,0,0,44,40v88a4,4,0,0,0,8,0V40a4,4,0,0,1,4-4h92V88a4,4,0,0,0,4,4h52V216a4,4,0,0,1-4,4H168a4,4,0,0,0,0,8h32a12,12,0,0,0,12-12V88A3.6,3.6,0,0,0,210.8,85.2ZM156,41.7,198.3,84H156ZM128,176H116v-8a24,24,0,0,0-48,0v8H56a4,4,0,0,0-4,4v44a4,4,0,0,0,4,4h72a4,4,0,0,0,4-4V180A4,4,0,0,0,128,176Zm-52-8a16,16,0,0,1,32,0v8H76Zm48,52H60V184h64Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,88a7.8,7.8,0,0,0-2.4-5.7l-55.9-56A8.1,8.1,0,0,0,152,24H56A16,16,0,0,0,40,40v88a8,8,0,0,0,16,0V40h88V88a8,8,0,0,0,8,8h48V216H168a8,8,0,0,0,0,16h32a16,16,0,0,0,16-16V88ZM160,51.3,188.7,80H160ZM128,172h-8v-4a28,28,0,0,0-56,0v4H56a8,8,0,0,0-8,8v44a8,8,0,0,0,8,8h72a8,8,0,0,0,8-8V180A8,8,0,0,0,128,172Zm-48-4a12,12,0,0,1,24,0v4H80Zm40,48H64V188h56Z" />
        </>
      )}
    </svg>
  );
}

export function PhFileMinus(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216.5,79.5l-56-56A12.3,12.3,0,0,0,152,20H56A20.1,20.1,0,0,0,36,40V216a20.1,20.1,0,0,0,20,20H200a20.1,20.1,0,0,0,20-20V88A12.3,12.3,0,0,0,216.5,79.5ZM160,57l23,23H160ZM60,212V44h76V92a12,12,0,0,0,12,12h48V212Zm104-60a12,12,0,0,1-12,12H104a12,12,0,0,1,0-24h48A12,12,0,0,1,164,152Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M208,88H152V32Z" opacity="0.2" />
          <path d="M213.7,82.3l-56-56A8.1,8.1,0,0,0,152,24H56A16,16,0,0,0,40,40V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V88A8.1,8.1,0,0,0,213.7,82.3ZM160,51.3,188.7,80H160ZM200,216H56V40h88V88a8,8,0,0,0,8,8h48V216Zm-40-64a8,8,0,0,1-8,8H104a8,8,0,0,1,0-16h48A8,8,0,0,1,160,152Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M213.7,82.3l-56-56A8.1,8.1,0,0,0,152,24H56A16,16,0,0,0,40,40V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V88A8.1,8.1,0,0,0,213.7,82.3ZM152,160H104a8,8,0,0,1,0-16h48a8,8,0,0,1,0,16Zm0-72V43.3L196.7,88Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M212.2,83.8l-56-56A5.6,5.6,0,0,0,152,26H56A14,14,0,0,0,42,40V216a14,14,0,0,0,14,14H200a14,14,0,0,0,14-14V88A5.6,5.6,0,0,0,212.2,83.8ZM158,46.5,193.5,82H158ZM200,218H56a2,2,0,0,1-2-2V40a2,2,0,0,1,2-2h90V88a6,6,0,0,0,6,6h50V216A2,2,0,0,1,200,218Zm-42-66a6,6,0,0,1-6,6H104a6,6,0,0,1,0-12h48A6,6,0,0,1,158,152Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M210.8,85.2l-56-56A3.6,3.6,0,0,0,152,28H56A12,12,0,0,0,44,40V216a12,12,0,0,0,12,12H200a12,12,0,0,0,12-12V88A3.6,3.6,0,0,0,210.8,85.2ZM156,41.7,198.3,84H156ZM204,216a4,4,0,0,1-4,4H56a4,4,0,0,1-4-4V40a4,4,0,0,1,4-4h92V88a4,4,0,0,0,4,4h52Zm-52-68a4,4,0,0,1,0,8H104a4,4,0,0,1,0-8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M213.7,82.3l-56-56A8.1,8.1,0,0,0,152,24H56A16,16,0,0,0,40,40V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V88A8.1,8.1,0,0,0,213.7,82.3ZM160,51.3,188.7,80H160ZM200,216H56V40h88V88a8,8,0,0,0,8,8h48V216Zm-40-64a8,8,0,0,1-8,8H104a8,8,0,0,1,0-16h48A8,8,0,0,1,160,152Z" />
        </>
      )}
    </svg>
  );
}

export function PhFilePdf(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M208,176v8h16a12,12,0,0,1,0,24H208v12a12,12,0,0,1-24,0V164a12,12,0,0,1,12-12h32a12,12,0,0,1,0,24ZM32,124V44A20.1,20.1,0,0,1,52,24H156a12.3,12.3,0,0,1,8.5,3.5l56,56A12.3,12.3,0,0,1,224,92v32a12,12,0,0,1-24,0V112H148a12,12,0,0,1-12-12V48H56v76a12,12,0,0,1-24,0ZM160,57V88h31Zm8,135a40,40,0,0,1-40,40H112a12,12,0,0,1-12-12V164a12,12,0,0,1,12-12h16A40,40,0,0,1,168,192Zm-24,0a16,16,0,0,0-16-16h-4v32h4A16,16,0,0,0,144,192Zm-60-8a32.1,32.1,0,0,1-32,32H48v4a12,12,0,0,1-24,0V164a12,12,0,0,1,12-12H52A32.1,32.1,0,0,1,84,184Zm-24,0a8,8,0,0,0-8-8H48v16h4A8,8,0,0,0,60,184Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M208,88H152V32Z" opacity="0.2" />
          <path d="M48,136a8,8,0,0,0,8-8V40h88V88a8,8,0,0,0,8,8h48v32a8,8,0,0,0,16,0V88a7.7,7.7,0,0,0-2.4-5.7l-55.9-56A8.1,8.1,0,0,0,152,24H56A16,16,0,0,0,40,40v88A8,8,0,0,0,48,136ZM160,51.3,188.7,80H160ZM64,160H48a8,8,0,0,0-8,8v48a8,8,0,0,0,16,0v-8h8a24,24,0,0,0,0-48Zm0,32H56V176h8a8,8,0,0,1,0,16Zm132-16v12h16a8,8,0,0,1,0,16H196v12a8,8,0,0,1-16,0V168a8,8,0,0,1,8-8h28a8,8,0,0,1,0,16Zm-68-16H114a8,8,0,0,0-8,8v48a8,8,0,0,0,8,8h14a32,32,0,0,0,0-64Zm0,48h-6V176h6a16,16,0,0,1,0,32Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M64,160H48a8,8,0,0,0-8,8v48a8,8,0,0,0,16,0v-8h8a24,24,0,0,0,0-48Zm0,32H56V176h8a8,8,0,0,1,0,16Zm132-16v12h16a8,8,0,0,1,0,16H196v12a8,8,0,0,1-16,0V168a8,8,0,0,1,8-8h28a8,8,0,0,1,0,16Zm-68-16H114a8,8,0,0,0-8,8v48a8,8,0,0,0,8,8h14a32,32,0,0,0,0-64Zm0,48h-6V176h6a16,16,0,0,1,0,32ZM48,136H208a8,8,0,0,0,8-8V88a8.1,8.1,0,0,0-2.3-5.7l-56-56A8.1,8.1,0,0,0,152,24H56A16,16,0,0,0,40,40v88A8,8,0,0,0,48,136ZM152,44l44,44H152Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M48,134a6,6,0,0,0,6-6V40a2,2,0,0,1,2-2h90V88a6,6,0,0,0,6,6h50v34a6,6,0,0,0,12,0V88a6,6,0,0,0-1.8-4.3l-56-55.9A5.6,5.6,0,0,0,152,26H56A14,14,0,0,0,42,40v88A6,6,0,0,0,48,134ZM158,46.5,193.5,82H158ZM64,162H48a6,6,0,0,0-6,6v48a6,6,0,0,0,12,0V206H64a22,22,0,0,0,0-44Zm0,32H54V174H64a10,10,0,0,1,0,20Zm130-20v16h18a6,6,0,0,1,0,12H194v14a6,6,0,0,1-12,0V168a6,6,0,0,1,6-6h28a6,6,0,0,1,0,12Zm-66-12H114a6,6,0,0,0-6,6v48a6,6,0,0,0,6,6h14a30,30,0,0,0,0-60Zm0,48h-8V174h8a18,18,0,0,1,0,36Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M48,132a4,4,0,0,0,4-4V40a4,4,0,0,1,4-4h92V88a4,4,0,0,0,4,4h52v36a4,4,0,0,0,8,0V88.1h0a4.5,4.5,0,0,0-1.2-2.9l-56-55.9A3.6,3.6,0,0,0,152,28H56A12,12,0,0,0,44,40v88A4,4,0,0,0,48,132ZM156,41.7,198.3,84H156ZM64,164H48a4,4,0,0,0-4,4v48a4,4,0,0,0,8,0V204H64a20,20,0,0,0,0-40Zm0,32H52V172H64a12,12,0,0,1,0,24Zm156-28a4,4,0,0,1-4,4H192v20h20a4,4,0,0,1,0,8H192v16a4,4,0,0,1-8,0V168a4,4,0,0,1,4-4h28A4,4,0,0,1,220,168Zm-92-4H114a4,4,0,0,0-4,4v48a4,4,0,0,0,4,4h14a28,28,0,0,0,0-56Zm0,48H118V172h10a20,20,0,0,1,0,40Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M48,136a8,8,0,0,0,8-8V40h88V88a8,8,0,0,0,8,8h48v32a8,8,0,0,0,16,0V88a7.7,7.7,0,0,0-2.4-5.7l-55.9-56A8.1,8.1,0,0,0,152,24H56A16,16,0,0,0,40,40v88A8,8,0,0,0,48,136ZM160,51.3,188.7,80H160ZM64,160H48a8,8,0,0,0-8,8v48a8,8,0,0,0,16,0v-8h8a24,24,0,0,0,0-48Zm0,32H56V176h8a8,8,0,0,1,0,16Zm132-16v12h16a8,8,0,0,1,0,16H196v12a8,8,0,0,1-16,0V168a8,8,0,0,1,8-8h28a8,8,0,0,1,0,16Zm-68-16H114a8,8,0,0,0-8,8v48a8,8,0,0,0,8,8h14a32,32,0,0,0,0-64Zm0,48h-6V176h6a16,16,0,0,1,0,32Z" />
        </>
      )}
    </svg>
  );
}

export function PhFilePlus(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216.5,79.5l-56-56A12.3,12.3,0,0,0,152,20H56A20.1,20.1,0,0,0,36,40V216a20.1,20.1,0,0,0,20,20H200a20.1,20.1,0,0,0,20-20V88A12.3,12.3,0,0,0,216.5,79.5ZM160,57l23,23H160ZM60,212V44h76V92a12,12,0,0,0,12,12h48V212Zm104-60a12,12,0,0,1-12,12H140v12a12,12,0,0,1-24,0V164H104a12,12,0,0,1,0-24h12V128a12,12,0,0,1,24,0v12h12A12,12,0,0,1,164,152Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M208,88H152V32Z" opacity="0.2" />
          <path d="M213.7,82.3l-56-56A8.1,8.1,0,0,0,152,24H56A16,16,0,0,0,40,40V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V88A8.1,8.1,0,0,0,213.7,82.3ZM160,51.3,188.7,80H160ZM200,216H56V40h88V88a8,8,0,0,0,8,8h48V216Zm-40-64a8,8,0,0,1-8,8H136v16a8,8,0,0,1-16,0V160H104a8,8,0,0,1,0-16h16V128a8,8,0,0,1,16,0v16h16A8,8,0,0,1,160,152Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M213.7,82.3l-56-56A8.1,8.1,0,0,0,152,24H56A16,16,0,0,0,40,40V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V88A8.1,8.1,0,0,0,213.7,82.3ZM152,160H136v16a8,8,0,0,1-16,0V160H104a8,8,0,0,1,0-16h16V128a8,8,0,0,1,16,0v16h16a8,8,0,0,1,0,16Zm0-72V43.3L196.7,88Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M212.2,83.8l-56-56A5.6,5.6,0,0,0,152,26H56A14,14,0,0,0,42,40V216a14,14,0,0,0,14,14H200a14,14,0,0,0,14-14V88A5.6,5.6,0,0,0,212.2,83.8ZM158,46.5,193.5,82H158ZM200,218H56a2,2,0,0,1-2-2V40a2,2,0,0,1,2-2h90V88a6,6,0,0,0,6,6h50V216A2,2,0,0,1,200,218Zm-42-66a6,6,0,0,1-6,6H134v18a6,6,0,0,1-12,0V158H104a6,6,0,0,1,0-12h18V128a6,6,0,0,1,12,0v18h18A6,6,0,0,1,158,152Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M210.8,85.2l-56-56A3.6,3.6,0,0,0,152,28H56A12,12,0,0,0,44,40V216a12,12,0,0,0,12,12H200a12,12,0,0,0,12-12V88A3.6,3.6,0,0,0,210.8,85.2ZM156,41.7,198.3,84H156ZM200,220H56a4,4,0,0,1-4-4V40a4,4,0,0,1,4-4h92V88a4,4,0,0,0,4,4h52V216A4,4,0,0,1,200,220Zm-44-68a4,4,0,0,1-4,4H132v20a4,4,0,0,1-8,0V156H104a4,4,0,0,1,0-8h20V128a4,4,0,0,1,8,0v20h20A4,4,0,0,1,156,152Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M213.7,82.3l-56-56A8.1,8.1,0,0,0,152,24H56A16,16,0,0,0,40,40V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V88A8.1,8.1,0,0,0,213.7,82.3ZM160,51.3,188.7,80H160ZM200,216H56V40h88V88a8,8,0,0,0,8,8h48V216Zm-40-64a8,8,0,0,1-8,8H136v16a8,8,0,0,1-16,0V160H104a8,8,0,0,1,0-16h16V128a8,8,0,0,1,16,0v16h16A8,8,0,0,1,160,152Z" />
        </>
      )}
    </svg>
  );
}

export function PhFilePng(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M160,164v56a12,12,0,0,1-21.8,7L120,201.4V220a12,12,0,0,1-24,0V164a12,12,0,0,1,21.8-7L136,182.6V164a12,12,0,0,1,24,0ZM32,124V44A20.1,20.1,0,0,1,52,24H156a12.3,12.3,0,0,1,8.5,3.5l56,56A12.3,12.3,0,0,1,224,92v32a12,12,0,0,1-24,0V112H148a12,12,0,0,1-12-12V48H56v76a12,12,0,0,1-24,0ZM160,57V88h31ZM84,184a32.1,32.1,0,0,1-32,32H48v4a12,12,0,0,1-24,0V164a12,12,0,0,1,12-12H52A32.1,32.1,0,0,1,84,184Zm-24,0a8,8,0,0,0-8-8H48v16h4A8,8,0,0,0,60,184Zm168,2.9h-8a12,12,0,0,0-12,12,11.9,11.9,0,0,0,3.1,8A12.6,12.6,0,0,1,206,208c-7.7,0-14-7.2-14-16s6.3-16,14-16a12.8,12.8,0,0,1,7.2,2.3,12,12,0,0,0,13.6-19.8A37.1,37.1,0,0,0,206,152c-21,0-38,17.9-38,40s17,40,38,40c13.3,0,25.4-7.1,32.4-19a12.9,12.9,0,0,0,1.6-6.1v-8A12,12,0,0,0,228,186.9Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M208,88H152V32Z" opacity="0.2" />
          <path d="M60,160H44a8,8,0,0,0-8,8v48a8,8,0,0,0,16,0v-8h8a24,24,0,0,0,0-48Zm0,32H52V176h8a8,8,0,0,1,0,16Zm92-24v48a8,8,0,0,1-5.5,7.6,7.3,7.3,0,0,1-2.5.4,7.8,7.8,0,0,1-6.4-3.2L116,192v24a8,8,0,0,1-16,0V168a8,8,0,0,1,5.5-7.6,7.9,7.9,0,0,1,8.9,2.8L136,192V168a8,8,0,0,1,16,0Zm72,37.8a7.5,7.5,0,0,1-1.3,4.3A29.4,29.4,0,0,1,198,224c-16.5,0-30-14.4-30-32s13.5-32,30-32a28.4,28.4,0,0,1,16.6,5.4,8,8,0,0,1-9.2,13A12.6,12.6,0,0,0,198,176c-7.7,0-14,7.2-14,16s6.3,16,14,16a13.3,13.3,0,0,0,10-4.8V202h-4a8,8,0,0,1,0-16h12a8,8,0,0,1,8,8ZM200,128a8,8,0,0,0,16,0V88a7.8,7.8,0,0,0-2.4-5.7l-55.9-56A8.1,8.1,0,0,0,152,24H56A16,16,0,0,0,40,40v88a8,8,0,0,0,16,0V40h88V88a8,8,0,0,0,8,8h48ZM160,51.3,188.7,80H160Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M60,160H44a8,8,0,0,0-8,8v48a8,8,0,0,0,16,0v-8h8a24,24,0,0,0,0-48Zm0,32H52V176h8a8,8,0,0,1,0,16Zm92-24v48a8,8,0,0,1-5.5,7.6,7.3,7.3,0,0,1-2.5.4,7.8,7.8,0,0,1-6.4-3.2L116,192v24a8,8,0,0,1-16,0V168a8,8,0,0,1,5.5-7.6,7.9,7.9,0,0,1,8.9,2.8L136,192V168a8,8,0,0,1,16,0Zm72,37.8a7.5,7.5,0,0,1-1.3,4.3A29.4,29.4,0,0,1,198,224c-16.5,0-30-14.4-30-32s13.5-32,30-32a28.4,28.4,0,0,1,16.6,5.4,8,8,0,0,1-9.2,13A12.6,12.6,0,0,0,198,176c-7.7,0-14,7.2-14,16s6.3,16,14,16a13.3,13.3,0,0,0,10-4.8V202h-4a8,8,0,0,1,0-16h12a8,8,0,0,1,8,8ZM48,136H208a8,8,0,0,0,8-8V88a8.1,8.1,0,0,0-2.3-5.7l-56-56A8.1,8.1,0,0,0,152,24H56A16,16,0,0,0,40,40v88A8,8,0,0,0,48,136ZM152,44l44,44H152Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M60,162H44a6,6,0,0,0-6,6v48a6,6,0,0,0,12,0V206H60a22,22,0,0,0,0-44Zm0,32H50V174H60a10,10,0,0,1,0,20Zm90-26v48a6,6,0,0,1-4.1,5.7,6.3,6.3,0,0,1-1.9.3,6.1,6.1,0,0,1-4.8-2.4L114,186v30a6,6,0,0,1-12,0V168a6,6,0,0,1,10.8-3.6L138,198V168a6,6,0,0,1,12,0Zm72,37.8a5.6,5.6,0,0,1-1,3.3A27.4,27.4,0,0,1,198,222c-15.4,0-28-13.5-28-30s12.6-30,28-30a26.5,26.5,0,0,1,15.5,5,6,6,0,1,1-7,9.8A14.7,14.7,0,0,0,198,174c-8.8,0-16,8.1-16,18s7.2,18,16,18a15.5,15.5,0,0,0,12-6.1V200h-6a6,6,0,0,1,0-12h12a6,6,0,0,1,6,6ZM202,128a6,6,0,0,0,12,0V88a6,6,0,0,0-1.8-4.3l-56-55.9A5.6,5.6,0,0,0,152,26H56A14,14,0,0,0,42,40v88a6,6,0,0,0,12,0V40a2,2,0,0,1,2-2h90V88a6,6,0,0,0,6,6h50ZM158,46.5,193.5,82H158Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M60,164H44a4,4,0,0,0-4,4v48a4,4,0,0,0,8,0V204H60a20,20,0,0,0,0-40Zm0,32H48V172H60a12,12,0,0,1,0,24Zm88-28v48a4.1,4.1,0,0,1-2.7,3.8l-1.3.2a4.2,4.2,0,0,1-3.2-1.6L112,180v36a4,4,0,0,1-8,0V168a4.1,4.1,0,0,1,2.7-3.8,4,4,0,0,1,4.5,1.4L140,204V168a4,4,0,0,1,8,0Zm72,37.8a4.3,4.3,0,0,1-.6,2.2A25.7,25.7,0,0,1,198,220c-14.3,0-26-12.6-26-28s11.7-28,26-28a24.6,24.6,0,0,1,14.3,4.6,4.1,4.1,0,0,1,1,5.6,4,4,0,0,1-5.6.9A17.2,17.2,0,0,0,198,172c-9.9,0-18,9-18,20s8.1,20,18,20a17.6,17.6,0,0,0,14-7.4V198h-8a4,4,0,0,1,0-8h12a4,4,0,0,1,4,4ZM204,128a4,4,0,0,0,8,0V88.1h0a4.5,4.5,0,0,0-1.2-2.9l-56-55.9A3.6,3.6,0,0,0,152,28H56A12,12,0,0,0,44,40v88a4,4,0,0,0,8,0V40a4,4,0,0,1,4-4h92V88a4,4,0,0,0,4,4h52ZM156,41.7,198.3,84H156Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M60,160H44a8,8,0,0,0-8,8v48a8,8,0,0,0,16,0v-8h8a24,24,0,0,0,0-48Zm0,32H52V176h8a8,8,0,0,1,0,16Zm92-24v48a8,8,0,0,1-5.5,7.6,7.3,7.3,0,0,1-2.5.4,7.8,7.8,0,0,1-6.4-3.2L116,192v24a8,8,0,0,1-16,0V168a8,8,0,0,1,5.5-7.6,7.9,7.9,0,0,1,8.9,2.8L136,192V168a8,8,0,0,1,16,0Zm72,37.8a7.5,7.5,0,0,1-1.3,4.3A29.4,29.4,0,0,1,198,224c-16.5,0-30-14.4-30-32s13.5-32,30-32a28.4,28.4,0,0,1,16.6,5.4,8,8,0,0,1-9.2,13A12.6,12.6,0,0,0,198,176c-7.7,0-14,7.2-14,16s6.3,16,14,16a13.3,13.3,0,0,0,10-4.8V202h-4a8,8,0,0,1,0-16h12a8,8,0,0,1,8,8ZM200,128a8,8,0,0,0,16,0V88a7.8,7.8,0,0,0-2.4-5.7l-55.9-56A8.1,8.1,0,0,0,152,24H56A16,16,0,0,0,40,40v88a8,8,0,0,0,16,0V40h88V88a8,8,0,0,0,8,8h48ZM160,51.3,188.7,80H160Z" />
        </>
      )}
    </svg>
  );
}

export function PhFilePpt(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M44,136a12,12,0,0,0,12-12V48h80v52a12,12,0,0,0,12,12h52v12a12,12,0,0,0,24,0V92a12.3,12.3,0,0,0-3.5-8.5l-56-56A12.3,12.3,0,0,0,156,24H52A20.1,20.1,0,0,0,32,44v80A12,12,0,0,0,44,136ZM191,88H160V57ZM60,152H44a12,12,0,0,0-12,12v56a12,12,0,0,0,24,0v-4h4a32,32,0,0,0,0-64Zm0,40H56V176h4a8,8,0,0,1,0,16Zm72-40H116a12,12,0,0,0-12,12v56a12,12,0,0,0,24,0v-4h4a32,32,0,0,0,0-64Zm0,40h-4V176h4a8,8,0,0,1,0,16Zm100-28a12,12,0,0,1-12,12h-8v44a12,12,0,0,1-24,0V176h-8a12,12,0,0,1,0-24h40A12,12,0,0,1,232,164Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M208,88H152V32Z" opacity="0.2" />
          <path d="M64,160H48a8,8,0,0,0-8,8v48a8,8,0,0,0,16,0v-8h8a24,24,0,0,0,0-48Zm0,32H56V176h8a8,8,0,0,1,0,16Zm64-32H112a8,8,0,0,0-8,8v48a8,8,0,0,0,16,0v-8h8a24,24,0,0,0,0-48Zm0,32h-8V176h8a8,8,0,0,1,0,16Zm88-24a8,8,0,0,1-8,8H198v40a8,8,0,0,1-16,0V176H172a8,8,0,0,1,0-16h36A8,8,0,0,1,216,168Zm0-80a7.8,7.8,0,0,0-2.4-5.7l-55.9-56A8.1,8.1,0,0,0,152,24H56A16,16,0,0,0,40,40v88a8,8,0,0,0,16,0V40h88V88a8,8,0,0,0,8,8h48v32a8,8,0,0,0,16,0V88ZM160,51.3,188.7,80H160Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M213.7,82.3l-56-56A8.1,8.1,0,0,0,152,24H56A16,16,0,0,0,40,40v88a8,8,0,0,0,8,8H208a8,8,0,0,0,8-8V88A8.1,8.1,0,0,0,213.7,82.3ZM152,88V44l44,44ZM64,160H48a8,8,0,0,0-8,8v48a8,8,0,0,0,16,0v-8h8a24,24,0,0,0,0-48Zm0,32H56V176h8a8,8,0,0,1,0,16Zm64-32H112a8,8,0,0,0-8,8v48a8,8,0,0,0,16,0v-8h8a24,24,0,0,0,0-48Zm0,32h-8V176h8a8,8,0,0,1,0,16Zm88-24a8,8,0,0,1-8,8H198v40a8,8,0,0,1-16,0V176H172a8,8,0,0,1,0-16h36A8,8,0,0,1,216,168Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M64,162H48a6,6,0,0,0-6,6v48a6,6,0,0,0,12,0V206H64a22,22,0,0,0,0-44Zm0,32H54V174H64a10,10,0,0,1,0,20Zm64-32H112a6,6,0,0,0-6,6v48a6,6,0,0,0,12,0V206h10a22,22,0,0,0,0-44Zm0,32H118V174h10a10,10,0,0,1,0,20Zm86-26a6,6,0,0,1-6,6H196v42a6,6,0,0,1-12,0V174H172a6,6,0,0,1,0-12h36A6,6,0,0,1,214,168Zm0-80a6,6,0,0,0-1.8-4.3l-56-55.9A5.6,5.6,0,0,0,152,26H56A14,14,0,0,0,42,40v88a6,6,0,0,0,12,0V40a2,2,0,0,1,2-2h90V88a6,6,0,0,0,6,6h50v34a6,6,0,0,0,12,0V88ZM158,46.5,193.5,82H158Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M64,164H48a4,4,0,0,0-4,4v48a4,4,0,0,0,8,0V204H64a20,20,0,0,0,0-40Zm0,32H52V172H64a12,12,0,0,1,0,24Zm64-32H112a4,4,0,0,0-4,4v48a4,4,0,0,0,8,0V204h12a20,20,0,0,0,0-40Zm0,32H116V172h12a12,12,0,0,1,0,24Zm84-28a4,4,0,0,1-4,4H194v44a4,4,0,0,1-8,0V172H172a4,4,0,0,1,0-8h36A4,4,0,0,1,212,168Zm0-80a4.5,4.5,0,0,0-1.2-2.9l-56-55.9A3.6,3.6,0,0,0,152,28H56A12,12,0,0,0,44,40v88a4,4,0,0,0,8,0V40a4,4,0,0,1,4-4h92V88a4,4,0,0,0,4,4h52v36a4,4,0,0,0,8,0V88.1ZM156,41.7,198.3,84H156Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M64,160H48a8,8,0,0,0-8,8v48a8,8,0,0,0,16,0v-8h8a24,24,0,0,0,0-48Zm0,32H56V176h8a8,8,0,0,1,0,16Zm64-32H112a8,8,0,0,0-8,8v48a8,8,0,0,0,16,0v-8h8a24,24,0,0,0,0-48Zm0,32h-8V176h8a8,8,0,0,1,0,16Zm88-24a8,8,0,0,1-8,8H198v40a8,8,0,0,1-16,0V176H172a8,8,0,0,1,0-16h36A8,8,0,0,1,216,168Zm0-80a7.8,7.8,0,0,0-2.4-5.7l-55.9-56A8.1,8.1,0,0,0,152,24H56A16,16,0,0,0,40,40v88a8,8,0,0,0,16,0V40h88V88a8,8,0,0,0,8,8h48v32a8,8,0,0,0,16,0V88ZM160,51.3,188.7,80H160Z" />
        </>
      )}
    </svg>
  );
}

export function PhFileRs(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M220.5,83.5l-56-56A12.3,12.3,0,0,0,156,24H52A20.1,20.1,0,0,0,32,44v80a12,12,0,0,0,24,0V48h80v52a12,12,0,0,0,12,12h52v96H180a12,12,0,0,0,0,24h24a20.1,20.1,0,0,0,20-20V92A12.3,12.3,0,0,0,220.5,83.5ZM160,57l31,31H160ZM52,152H36a12,12,0,0,0-12,12v56a12,12,0,0,0,24,0v-4h4l3.1-.2,6.8,10.7a12,12,0,1,0,20.2-13l-5.7-8.8A32,32,0,0,0,52,152Zm-4,24h4a8,8,0,0,1,0,16H48Zm104,29.4c0,16.2-11.4,26.6-29,26.6a36.2,36.2,0,0,1-23.2-8.8h0a12,12,0,0,1,8.2-20.8,12,12,0,0,1,8.2,3.2H116a12,12,0,0,0,7,2.5c3.1,0,4.5-.6,4.8-.8a3.7,3.7,0,0,0,.2-1.4c-1.6-1-5.4-2.1-7.8-2.8-8.5-2.5-24.2-7.2-24.2-24.4,0-15.4,11.4-26.6,27-26.6a36.2,36.2,0,0,1,23.2,8.8h0a12,12,0,0,1-8.2,20.8,12,12,0,0,1-8.2-3.2h.2a12,12,0,0,0-7-2.5c-2.3,0-2.8.6-3,1.6,1.7.8,4.9,1.7,7,2.4C135.8,182.6,152,187.4,152,205.4Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M208,88H152V32Z" opacity="0.2" />
          <path d="M80.1,201.8A24,24,0,0,0,64,160H48a8,8,0,0,0-8,8v48a8,8,0,0,0,16,0v-8h9l8.3,12.4A7.9,7.9,0,0,0,80,224a7.8,7.8,0,0,0,4.4-1.3,8,8,0,0,0,2.2-11.1ZM56,176h8a8,8,0,0,1,0,16H56ZM216,88a7.8,7.8,0,0,0-2.4-5.7l-55.9-56A8.1,8.1,0,0,0,152,24H56A16,16,0,0,0,40,40v88a8,8,0,0,0,16,0V40h88V88a8,8,0,0,0,8,8h48V216H184a8,8,0,0,0,0,16h16a16,16,0,0,0,16-16V88ZM160,51.3,188.7,80H160ZM152,204c0,13.1-9.4,21-25,21a33.4,33.4,0,0,1-20.1-6.8h0A7.6,7.6,0,0,1,104,212a8,8,0,0,1,8-8,8.3,8.3,0,0,1,5.1,1.8H117a18.4,18.4,0,0,0,10,3.2c9,0,9-3,9-5s0-.9-1.2-1.6-5.8-2.2-9.2-3.1C117,197,104,193.5,104,180s9.5-21,23-21a33.4,33.4,0,0,1,20.1,6.8h0A7.6,7.6,0,0,1,150,172a8,8,0,0,1-8,8,8.3,8.3,0,0,1-5.1-1.8h.1a18.4,18.4,0,0,0-10-3.2c-4.6,0-7,1.7-7,5v.2c1.1,1.3,6.6,2.8,9.7,3.6C138,186,152,189.7,152,204Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M80.1,201.8A24,24,0,0,0,64,160H48a8,8,0,0,0-8,8v48a8,8,0,0,0,16,0v-8h9l8.3,12.4A7.9,7.9,0,0,0,80,224a7.8,7.8,0,0,0,4.4-1.3,8,8,0,0,0,2.2-11.1ZM64,192H56V176h8a8,8,0,0,1,0,16Zm88,12c0,13.1-9.3,21-25,21a33.4,33.4,0,0,1-20.1-6.8h0A7.6,7.6,0,0,1,104,212a8,8,0,0,1,8-8,8.3,8.3,0,0,1,5.1,1.8H117a18.4,18.4,0,0,0,10,3.2c9,0,9-3,9-5s0-.9-1.2-1.6-5.8-2.2-9.2-3.1C117,197,104,193.5,104,180s9.5-21,23-21a33.4,33.4,0,0,1,20.1,6.8h0A7.6,7.6,0,0,1,150,172a8,8,0,0,1-8,8,8.3,8.3,0,0,1-5.1-1.8h.1a18.4,18.4,0,0,0-10-3.2c-4.6,0-7,1.7-7,5v.2c1.1,1.3,6.6,2.8,9.7,3.6C138,186,152,189.7,152,204ZM213.7,82.3l-56-56A8.1,8.1,0,0,0,152,24H56A16,16,0,0,0,40,40v88a8,8,0,0,0,8,8H168a8,8,0,0,1,8,8v80a8,8,0,0,0,8,8h16a16,16,0,0,0,16-16V88A8.1,8.1,0,0,0,213.7,82.3ZM152,88V44l44,44Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M77.5,201.4A22,22,0,0,0,64,162H48a6,6,0,0,0-6,6v48a6,6,0,0,0,12,0V206H66l9,13.4a6,6,0,0,0,5,2.7,5.8,5.8,0,0,0,3.3-1,6,6,0,0,0,1.7-8.3ZM54,174H64a10,10,0,0,1,0,20H54Zm96,30c0,11.9-8.6,19-23,19a30,30,0,0,1-18.8-6.4,6,6,0,1,1,7.6-9.2A20,20,0,0,0,127,211c11,0,11-4.7,11-7s-3.1-4.3-11.9-6.7S106,192,106,180s8.6-19,21-19a30,30,0,0,1,18.8,6.4,6,6,0,0,1-7.6,9.2A19.7,19.7,0,0,0,127,173c-3.4,0-9,.9-9,7,0,2.4,3.4,3.7,11.2,5.7S150,191.3,150,204ZM214,88a6,6,0,0,0-1.8-4.3l-56-55.9A5.6,5.6,0,0,0,152,26H56A14,14,0,0,0,42,40v88a6,6,0,0,0,12,0V40a2,2,0,0,1,2-2h90V88a6,6,0,0,0,6,6h50V216a2,2,0,0,1-2,2H184a6,6,0,0,0,0,12h16a14,14,0,0,0,14-14V88ZM158,46.5,193.5,82H158Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M74.7,200.9A20,20,0,0,0,64,164H48a4,4,0,0,0-4,4v48a4,4,0,0,0,8,0V204H64a15.3,15.3,0,0,0,3-.3l9.7,14.5A3.8,3.8,0,0,0,80,220a3.6,3.6,0,0,0,2.2-.7,4,4,0,0,0,1.1-5.5ZM52,196V172H64a12,12,0,0,1,0,24Zm96,8c0,10.8-7.7,17-21,17a30,30,0,0,1-17.6-5.9,4.1,4.1,0,0,1-.5-5.7,4,4,0,0,1,5.6-.5A21.5,21.5,0,0,0,127,213c13,0,13-6.5,13-9,0-4.6-4.3-6.2-13.4-8.6S108,190.5,108,180s7.6-17,19-17a30,30,0,0,1,17.6,5.9,4,4,0,1,1-5.2,6.2A20.7,20.7,0,0,0,127,171c-6.9,0-11,3.4-11,9,0,3.9,4,5.4,12.6,7.7S148,192.8,148,204ZM212,88a4.5,4.5,0,0,0-1.2-2.9l-56-55.9A3.6,3.6,0,0,0,152,28H56A12,12,0,0,0,44,40v88a4,4,0,0,0,8,0V40a4,4,0,0,1,4-4h92V88a4,4,0,0,0,4,4h52V216a4,4,0,0,1-4,4H184a4,4,0,0,0,0,8h16a12,12,0,0,0,12-12V88.1ZM156,41.7,198.3,84H156Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M80.1,201.8A24,24,0,0,0,64,160H48a8,8,0,0,0-8,8v48a8,8,0,0,0,16,0v-8h9l8.3,12.4A7.9,7.9,0,0,0,80,224a7.8,7.8,0,0,0,4.4-1.3,8,8,0,0,0,2.2-11.1ZM56,176h8a8,8,0,0,1,0,16H56ZM216,88a7.8,7.8,0,0,0-2.4-5.7l-55.9-56A8.1,8.1,0,0,0,152,24H56A16,16,0,0,0,40,40v88a8,8,0,0,0,16,0V40h88V88a8,8,0,0,0,8,8h48V216H184a8,8,0,0,0,0,16h16a16,16,0,0,0,16-16V88ZM160,51.3,188.7,80H160ZM152,204c0,13.1-9.4,21-25,21a33.4,33.4,0,0,1-20.1-6.8h0A7.6,7.6,0,0,1,104,212a8,8,0,0,1,8-8,8.3,8.3,0,0,1,5.1,1.8H117a18.4,18.4,0,0,0,10,3.2c9,0,9-3,9-5s0-.9-1.2-1.6-5.8-2.2-9.2-3.1C117,197,104,193.5,104,180s9.5-21,23-21a33.4,33.4,0,0,1,20.1,6.8h0A7.6,7.6,0,0,1,150,172a8,8,0,0,1-8,8,8.3,8.3,0,0,1-5.1-1.8h.1a18.4,18.4,0,0,0-10-3.2c-4.6,0-7,1.7-7,5v.2c1.1,1.3,6.6,2.8,9.7,3.6C138,186,152,189.7,152,204Z" />
        </>
      )}
    </svg>
  );
}

export function PhFileSearch(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M220,92V88a12.3,12.3,0,0,0-3.5-8.5l-56-56A12.3,12.3,0,0,0,152,20H56A20.1,20.1,0,0,0,36,40V216a20.1,20.1,0,0,0,20,20H200a20.1,20.1,0,0,0,20-20V92ZM183,80H160V57ZM60,212V44h76V92a12,12,0,0,0,12,12h48V212Zm96.5-48.5A35.6,35.6,0,0,0,160,148a36,36,0,0,0-61.5-25.5,36.2,36.2,0,0,0,0,51h0a36.2,36.2,0,0,0,41,7l11.8,11.8a12.1,12.1,0,0,0,17,0,12,12,0,0,0,0-17Zm-41-7h0a12.1,12.1,0,0,1,0-17,12,12,0,0,1,17,0,12.1,12.1,0,0,1,0,17A12,12,0,0,1,115.5,156.5Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M208,88H152V32Z" opacity="0.2" />
          <path d="M216,88a7.7,7.7,0,0,0-2.4-5.7l-55.9-56-.6-.5h-.2l-.5-.3h-.1l-.5-.4h-.1l-.6-.3H155l-.7-.3H56A16,16,0,0,0,40,40V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V88ZM160,51.3,188.7,80H160ZM200,216H56V40h88V88a8,8,0,0,0,8,8h48V216Zm-45.5-48.8a36.4,36.4,0,0,0-5-44.7,36.2,36.2,0,0,0-51,0,36.2,36.2,0,0,0,0,51,36.2,36.2,0,0,0,44.7,5l10.9,11a8.3,8.3,0,0,0,5.7,2.3,8.5,8.5,0,0,0,5.7-2.3,8.1,8.1,0,0,0,0-11.4Zm-44.6-5.1h0a19.9,19.9,0,0,1,0-28.2,19.8,19.8,0,0,1,28.2,0,19.9,19.9,0,0,1,0,28.2,19.8,19.8,0,0,1-28.2,0Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M213.6,82.3l-55.9-56-.3-.2-.3-.3-.3-.2-.3-.2c-.1-.1-.2-.1-.2-.2l-.5-.3h-.2l-.5-.3H155l-.7-.3H56A16,16,0,0,0,40,40V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V88A7.8,7.8,0,0,0,213.6,82.3ZM165.4,189.5a8,8,0,0,1-5.6,2.3,8.3,8.3,0,0,1-5.7-2.3l-10.9-11A36.8,36.8,0,0,1,124,184a36,36,0,1,1,36-36,35.8,35.8,0,0,1-5.5,19.2l10.9,10.9A8,8,0,0,1,165.4,189.5ZM152,88V43.3L196.7,88Z" />
          <path d="M124,128a19.9,19.9,0,0,0-14.1,5.9,19.8,19.8,0,0,0,0,28.2,19.9,19.9,0,0,0,28.2,0,19.8,19.8,0,0,0,0-28.2A19.9,19.9,0,0,0,124,128Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M214,88a6,6,0,0,0-1.8-4.3l-56-55.9h0l-.4-.3h-.1l-.4-.3h-.1l-.4-.2h0l-.5-.2a.1.1,0,0,0-.1-.1H56A14,14,0,0,0,42,40V216a14,14,0,0,0,14,14H200a14,14,0,0,0,14-14V88ZM158,46.5,193.5,82H158ZM200,218H56a2,2,0,0,1-2-2V40a2,2,0,0,1,2-2h90V88a6,6,0,0,0,6,6h50V216A2,2,0,0,1,200,218Zm-48.1-50.6A34,34,0,0,0,100,124a34,34,0,0,0,43.4,51.9L155.6,188a5.9,5.9,0,1,0,8.4-8.4Zm-43.5-3.8h0A22.2,22.2,0,1,1,124,170,22.2,22.2,0,0,1,108.4,163.6Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M210.8,85.1l-56-55.9h0l-.6-.4H154l-.5-.3H56A12,12,0,0,0,44,40V216a12,12,0,0,0,12,12H200a12,12,0,0,0,12-12V88.1h0A4.5,4.5,0,0,0,210.8,85.1ZM156,41.7,198.3,84H156ZM204,216a4,4,0,0,1-4,4H56a4,4,0,0,1-4-4V40a4,4,0,0,1,4-4h92V88a4,4,0,0,0,4,4h52Zm-57.4-90.6A32,32,0,1,0,124,180a32,32,0,0,0,19.6-6.7L157,186.6a3.8,3.8,0,0,0,2.8,1.2,3.9,3.9,0,0,0,2.8-1.2,3.9,3.9,0,0,0,0-5.6l-13.3-13.4A32,32,0,0,0,156,148,31.5,31.5,0,0,0,146.6,125.4ZM141,165a24.1,24.1,0,1,1,7-17A24.2,24.2,0,0,1,141,165Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,88a7.7,7.7,0,0,0-2.4-5.7l-55.9-56-.6-.5h-.2l-.5-.3h-.1l-.5-.4h-.1l-.6-.3H155l-.7-.3H56A16,16,0,0,0,40,40V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V88ZM160,51.3,188.7,80H160ZM200,216H56V40h88V88a8,8,0,0,0,8,8h48V216Zm-45.5-48.8A35.8,35.8,0,0,0,160,148a36.3,36.3,0,0,0-10.5-25.5,36,36,0,1,0-6.3,56l10.9,11a8.3,8.3,0,0,0,5.7,2.3,8.5,8.5,0,0,0,5.7-2.3,8.1,8.1,0,0,0,0-11.4Zm-44.6-5.1h0A19.8,19.8,0,1,1,124,168,19.7,19.7,0,0,1,109.9,162.1Z" />
        </>
      )}
    </svg>
  );
}

export function PhFileText(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216.5,79.5l-56-56A12.3,12.3,0,0,0,152,20H56A20.1,20.1,0,0,0,36,40V216a20.1,20.1,0,0,0,20,20H200a20.1,20.1,0,0,0,20-20V88A12.3,12.3,0,0,0,216.5,79.5ZM160,57l23,23H160ZM60,212V44h76V92a12,12,0,0,0,12,12h48V212Zm112-80a12,12,0,0,1-12,12H96a12,12,0,0,1,0-24h64A12,12,0,0,1,172,132Zm0,40a12,12,0,0,1-12,12H96a12,12,0,0,1,0-24h64A12,12,0,0,1,172,172Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M208,88H152V32Z" opacity="0.2" />
          <path d="M213.7,82.3l-56-56A8.1,8.1,0,0,0,152,24H56A16,16,0,0,0,40,40V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V88A8.1,8.1,0,0,0,213.7,82.3ZM160,51.3,188.7,80H160ZM200,216H56V40h88V88a8,8,0,0,0,8,8h48V216Zm-32-80a8,8,0,0,1-8,8H96a8,8,0,0,1,0-16h64A8,8,0,0,1,168,136Zm0,32a8,8,0,0,1-8,8H96a8,8,0,0,1,0-16h64A8,8,0,0,1,168,168Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M213.7,82.3l-56-56A8.1,8.1,0,0,0,152,24H56A16,16,0,0,0,40,40V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V88A8.1,8.1,0,0,0,213.7,82.3ZM160,176H96a8,8,0,0,1,0-16h64a8,8,0,0,1,0,16Zm0-32H96a8,8,0,0,1,0-16h64a8,8,0,0,1,0,16Zm-8-56V44l44,44Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M212.2,83.8l-56-56A5.6,5.6,0,0,0,152,26H56A14,14,0,0,0,42,40V216a14,14,0,0,0,14,14H200a14,14,0,0,0,14-14V88A5.6,5.6,0,0,0,212.2,83.8ZM158,46.5,193.5,82H158ZM200,218H56a2,2,0,0,1-2-2V40a2,2,0,0,1,2-2h90V88a6,6,0,0,0,6,6h50V216A2,2,0,0,1,200,218Zm-34-82a6,6,0,0,1-6,6H96a6,6,0,0,1,0-12h64A6,6,0,0,1,166,136Zm0,32a6,6,0,0,1-6,6H96a6,6,0,0,1,0-12h64A6,6,0,0,1,166,168Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M210.8,85.2l-56-56A3.6,3.6,0,0,0,152,28H56A12,12,0,0,0,44,40V216a12,12,0,0,0,12,12H200a12,12,0,0,0,12-12V88A3.6,3.6,0,0,0,210.8,85.2ZM156,41.7,198.3,84H156ZM200,220H56a4,4,0,0,1-4-4V40a4,4,0,0,1,4-4h92V88a4,4,0,0,0,4,4h52V216A4,4,0,0,1,200,220Zm-36-84a4,4,0,0,1-4,4H96a4,4,0,0,1,0-8h64A4,4,0,0,1,164,136Zm0,32a4,4,0,0,1-4,4H96a4,4,0,0,1,0-8h64A4,4,0,0,1,164,168Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M213.7,82.3l-56-56A8.1,8.1,0,0,0,152,24H56A16,16,0,0,0,40,40V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V88A8.1,8.1,0,0,0,213.7,82.3ZM160,51.3,188.7,80H160ZM200,216H56V40h88V88a8,8,0,0,0,8,8h48V216Zm-32-80a8,8,0,0,1-8,8H96a8,8,0,0,1,0-16h64A8,8,0,0,1,168,136Zm0,32a8,8,0,0,1-8,8H96a8,8,0,0,1,0-16h64A8,8,0,0,1,168,168Z" />
        </>
      )}
    </svg>
  );
}

export function PhFileTs(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M224,92a12.3,12.3,0,0,0-3.5-8.5l-56-56A12.3,12.3,0,0,0,156,24H52A20.1,20.1,0,0,0,32,44v80a12,12,0,0,0,24,0V48h80v52a12,12,0,0,0,12,12h52v96H180a12,12,0,0,0,0,24h24a20.1,20.1,0,0,0,20-20ZM160,57l31,31H160ZM88,164a12,12,0,0,1-12,12H68v44a12,12,0,0,1-24,0V176H36a12,12,0,0,1,0-24H76A12,12,0,0,1,88,164Zm64,41.4c0,16.2-11.4,26.6-29,26.6a36.2,36.2,0,0,1-23.2-8.8h0a12,12,0,0,1,8.2-20.8,12,12,0,0,1,8.2,3.2H116a12,12,0,0,0,7,2.5c3.1,0,4.5-.6,4.8-.8a3.7,3.7,0,0,0,.2-1.4c-1.6-1-5.4-2.1-7.8-2.8-8.5-2.5-24.2-7.2-24.2-24.4,0-15.4,11.4-26.6,27-26.6a36.2,36.2,0,0,1,23.2,8.8h0a12,12,0,0,1-8.2,20.8,12,12,0,0,1-8.2-3.2h.2a12,12,0,0,0-7-2.5c-2.3,0-2.8.6-3,1.6,1.7.8,4.9,1.7,7,2.4C135.8,182.6,152,187.4,152,205.4Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M208,88H152V32Z" opacity="0.2" />
          <path d="M92,168a8,8,0,0,1-8,8H74v40a8,8,0,0,1-16,0V176H48a8,8,0,0,1,0-16H84A8,8,0,0,1,92,168ZM216,88.2V216a16,16,0,0,1-16,16H184a8,8,0,0,1,0-16h16V96H152a8,8,0,0,1-8-8V40H56v88a8,8,0,0,1-16,0V40A16,16,0,0,1,56,24h96a8.1,8.1,0,0,1,5.7,2.3l55.9,56A7.8,7.8,0,0,1,216,88ZM160,80h28.7L160,51.3ZM129.7,183.8c-3.1-.8-8.6-2.3-9.7-3.6V180c0-3.3,2.4-5,7-5a18.4,18.4,0,0,1,10,3.2h-.1A8.3,8.3,0,0,0,142,180a8,8,0,0,0,8-8,7.6,7.6,0,0,0-2.9-6.1h0A33.4,33.4,0,0,0,127,159c-13.5,0-23,8.6-23,21s13,17,21.6,19.3c3.4.9,7,1.8,9.2,3.1s1.2,1,1.2,1.6,0,5-9,5a18.4,18.4,0,0,1-10-3.2h.1A8.3,8.3,0,0,0,112,204a8,8,0,0,0-8,8,7.6,7.6,0,0,0,2.9,6.1h0A33.4,33.4,0,0,0,127,225c15.6,0,25-7.9,25-21S138,186,129.7,183.8Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M92,168a8,8,0,0,1-8,8H74v40a8,8,0,0,1-16,0V176H48a8,8,0,0,1,0-16H84A8,8,0,0,1,92,168Zm37.7,15.8c-3.1-.8-8.6-2.3-9.7-3.6V180c0-3.3,2.4-5,7-5a18.4,18.4,0,0,1,10,3.2h-.1A8.3,8.3,0,0,0,142,180a8,8,0,0,0,8-8,7.6,7.6,0,0,0-2.9-6.1h0A33.4,33.4,0,0,0,127,159c-13.5,0-23,8.6-23,21s13,17,21.6,19.3c3.4.9,7,1.8,9.2,3.1s1.2,1,1.2,1.6,0,5-9,5a18.4,18.4,0,0,1-10-3.2h.1A8.3,8.3,0,0,0,112,204a8,8,0,0,0-8,8,7.6,7.6,0,0,0,2.9,6.1h0A33.4,33.4,0,0,0,127,225c15.7,0,25-7.9,25-21S138,186,129.7,183.8ZM216,88V216a16,16,0,0,1-16,16H184a8,8,0,0,1-8-8V144a8,8,0,0,0-8-8H48a8,8,0,0,1-8-8V40A16,16,0,0,1,56,24h96a8.1,8.1,0,0,1,5.7,2.3l56,56A8.1,8.1,0,0,1,216,88Zm-20,0L152,44V88Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M90,168a6,6,0,0,1-6,6H72v42a6,6,0,0,1-12,0V174H48a6,6,0,0,1,0-12H84A6,6,0,0,1,90,168Zm39.2,17.7c-7.8-2-11.2-3.3-11.2-5.7,0-6.1,5.6-7,9-7a19.7,19.7,0,0,1,11.2,3.6,6,6,0,0,0,7.6-9.2A30,30,0,0,0,127,161c-12.4,0-21,7.8-21,19s11.6,15.1,20.1,17.3S138,201,138,204s0,7-11,7a20,20,0,0,1-11.2-3.6,6,6,0,1,0-7.6,9.2A30,30,0,0,0,127,223c14.4,0,23-7.1,23-19S137.5,187.9,129.2,185.7ZM214,88.2V216a14,14,0,0,1-14,14H184a6,6,0,0,1,0-12h16a2,2,0,0,0,2-2V94H152a6,6,0,0,1-6-6V38H56a2,2,0,0,0-2,2v88a6,6,0,0,1-12,0V40A14,14,0,0,1,56,26h96a5.6,5.6,0,0,1,4.2,1.8l56,55.9A6,6,0,0,1,214,88ZM158,82h35.5L158,46.5Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M88,168a4,4,0,0,1-4,4H70v44a4,4,0,0,1-8,0V172H48a4,4,0,0,1,0-8H84A4,4,0,0,1,88,168Zm40.6,19.7c-8.6-2.3-12.6-3.8-12.6-7.7,0-5.6,4.1-9,11-9a20.7,20.7,0,0,1,12.4,4.1,4,4,0,1,0,5.2-6.2A30,30,0,0,0,127,163c-11.4,0-19,6.8-19,17s10.3,13.2,18.6,15.4,13.4,4,13.4,8.6c0,2.5,0,9-13,9a21.5,21.5,0,0,1-12.5-4.1,4,4,0,0,0-5.6.5,4.1,4.1,0,0,0,.5,5.7A30,30,0,0,0,127,221c13.3,0,21-6.2,21-17S137.3,189.9,128.6,187.7ZM212,88.1V216a12,12,0,0,1-12,12H184a4,4,0,0,1,0-8h16a4,4,0,0,0,4-4V92H152a4,4,0,0,1-4-4V36H56a4,4,0,0,0-4,4v88a4,4,0,0,1-8,0V40A12,12,0,0,1,56,28h96a3.6,3.6,0,0,1,2.8,1.2l56,55.9A4.5,4.5,0,0,1,212,88ZM156,84h42.3L156,41.7Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M92,168a8,8,0,0,1-8,8H74v40a8,8,0,0,1-16,0V176H48a8,8,0,0,1,0-16H84A8,8,0,0,1,92,168ZM216,88.2V216a16,16,0,0,1-16,16H184a8,8,0,0,1,0-16h16V96H152a8,8,0,0,1-8-8V40H56v88a8,8,0,0,1-16,0V40A16,16,0,0,1,56,24h96a8.1,8.1,0,0,1,5.7,2.3l55.9,56A7.8,7.8,0,0,1,216,88ZM160,80h28.7L160,51.3ZM129.7,183.8c-3.1-.8-8.6-2.3-9.7-3.6V180c0-3.3,2.4-5,7-5a18.4,18.4,0,0,1,10,3.2h-.1A8.3,8.3,0,0,0,142,180a8,8,0,0,0,8-8,7.6,7.6,0,0,0-2.9-6.1h0A33.4,33.4,0,0,0,127,159c-13.5,0-23,8.6-23,21s13,17,21.6,19.3c3.4.9,7,1.8,9.2,3.1s1.2,1,1.2,1.6,0,5-9,5a18.4,18.4,0,0,1-10-3.2h.1A8.3,8.3,0,0,0,112,204a8,8,0,0,0-8,8,7.6,7.6,0,0,0,2.9,6.1h0A33.4,33.4,0,0,0,127,225c15.6,0,25-7.9,25-21S138,186,129.7,183.8Z" />
        </>
      )}
    </svg>
  );
}

export function PhFileTsx(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M221.8,171l-15.1,21,15.1,21a12,12,0,0,1-19.6,14L192,212.6,181.8,227a12,12,0,1,1-19.6-14l15.1-21-15.1-21a12,12,0,1,1,19.6-14L192,171.4,202.2,157a12,12,0,0,1,19.6,14ZM224,92v32a12,12,0,0,1-24,0V112H148a12,12,0,0,1-12-12V48H56v76a12,12,0,0,1-24,0V44A20.1,20.1,0,0,1,52,24H156a12.3,12.3,0,0,1,8.5,3.5l56,56A12.3,12.3,0,0,1,224,92Zm-64-4h31L160,57ZM76,152H36a12,12,0,0,0,0,24h8v44a12,12,0,0,0,24,0V176h8a12,12,0,0,0,0-24Zm51,28c-2.1-.7-5.3-1.6-7-2.4.2-1,.7-1.6,3-1.6a12,12,0,0,1,7,2.5h-.2a12,12,0,0,0,20.2-8.8,12.1,12.1,0,0,0-3.8-8.8h0A36.2,36.2,0,0,0,123,152c-15.6,0-27,11.2-27,26.6,0,17.2,15.7,21.9,24.2,24.4,2.4.7,6.2,1.8,7.8,2.8a3.7,3.7,0,0,1-.2,1.4c-.3.2-1.7.8-4.8.8a12,12,0,0,1-7-2.5h.2A12,12,0,0,0,96,214.4a12.1,12.1,0,0,0,3.8,8.8h0A36.2,36.2,0,0,0,123,232c17.6,0,29-10.4,29-26.6C152,187.4,135.8,182.6,127,180Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M208,88H152V32Z" opacity="0.2" />
          <path d="M214.4,172.8,200,192l14.4,19.2a8,8,0,0,1-1.6,11.2A7.7,7.7,0,0,1,208,224a8,8,0,0,1-6.4-3.2L190,205.3l-11.6,15.5A8,8,0,0,1,172,224a7.7,7.7,0,0,1-4.8-1.6,8,8,0,0,1-1.6-11.2L180,192l-14.4-19.2a8,8,0,0,1,12.8-9.6L190,178.7l11.6-15.5a8,8,0,1,1,12.8,9.6ZM84,160H48a8,8,0,0,0,0,16H58v40a8,8,0,0,0,16,0V176H84a8,8,0,0,0,0-16ZM216,88.2V128a8,8,0,0,1-16,0V96H152a8,8,0,0,1-8-8V40H56v88a8,8,0,0,1-16,0V40A16,16,0,0,1,56,24h96a8.1,8.1,0,0,1,5.7,2.3l55.9,56A7.8,7.8,0,0,1,216,88ZM160,80h28.7L160,51.3ZM129.7,183.8c-3.1-.8-8.6-2.3-9.7-3.6V180c0-3.3,2.4-5,7-5a18.4,18.4,0,0,1,10,3.2h-.1A8.3,8.3,0,0,0,142,180a8,8,0,0,0,8-8,7.6,7.6,0,0,0-2.9-6.1h0A33.4,33.4,0,0,0,127,159c-13.5,0-23,8.6-23,21s13,17,21.6,19.3c3.4.9,7,1.8,9.2,3.1s1.2,1,1.2,1.6,0,5-9,5a18.4,18.4,0,0,1-10-3.2h.1A8.3,8.3,0,0,0,112,204a8,8,0,0,0-8,8,7.6,7.6,0,0,0,2.9,6.1h0A33.4,33.4,0,0,0,127,225c15.6,0,25-7.9,25-21S138,186,129.7,183.8Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M213.7,82.3l-56-56A8.1,8.1,0,0,0,152,24H56A16,16,0,0,0,40,40v88a8,8,0,0,0,8,8H208a8,8,0,0,0,8-8V88A8.1,8.1,0,0,0,213.7,82.3ZM152,88V44l44,44ZM92,168a8,8,0,0,1-8,8H74v40a8,8,0,0,1-16,0V176H48a8,8,0,0,1,0-16H84A8,8,0,0,1,92,168Zm122.4,4.8L200,192l14.4,19.2a8,8,0,0,1-1.6,11.2A7.7,7.7,0,0,1,208,224a8,8,0,0,1-6.4-3.2L190,205.3l-11.6,15.5A8,8,0,0,1,172,224a7.7,7.7,0,0,1-4.8-1.6,8.1,8.1,0,0,1-1.6-11.2L180,192l-14.4-19.2a8.1,8.1,0,0,1,1.6-11.2,8,8,0,0,1,11.2,1.6L190,178.7l11.6-15.5a8,8,0,1,1,12.8,9.6ZM152,204c0,13.1-9.3,21-25,21a33.4,33.4,0,0,1-20.1-6.8h0A7.6,7.6,0,0,1,104,212a8,8,0,0,1,8-8,8.3,8.3,0,0,1,5.1,1.8H117a18.4,18.4,0,0,0,10,3.2c9,0,9-3,9-5s0-.9-1.2-1.6-5.8-2.2-9.2-3.1C117,197,104,193.5,104,180s9.5-21,23-21a33.4,33.4,0,0,1,20.1,6.8h0A7.6,7.6,0,0,1,150,172a8,8,0,0,1-8,8,8.3,8.3,0,0,1-5.1-1.8h.1a18.4,18.4,0,0,0-10-3.2c-4.6,0-7,1.7-7,5v.2c1.1,1.3,6.6,2.8,9.7,3.6C138,186,152,189.7,152,204Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M212.8,171.6,197.5,192l15.3,20.4a6,6,0,0,1-9.6,7.2L190,202l-13.2,17.6a6,6,0,1,1-9.6-7.2L182.5,192l-15.3-20.4a6,6,0,1,1,9.6-7.2L190,182l13.2-17.6a6,6,0,0,1,9.6,7.2ZM84,162H48a6,6,0,0,0,0,12H60v42a6,6,0,0,0,12,0V174H84a6,6,0,0,0,0-12Zm45.2,23.7c-7.8-2-11.2-3.3-11.2-5.7,0-6.1,5.6-7,9-7a19.7,19.7,0,0,1,11.2,3.6,6,6,0,0,0,7.6-9.2A30,30,0,0,0,127,161c-12.4,0-21,7.8-21,19s11.6,15.1,20.1,17.3S138,201,138,204s0,7-11,7a20,20,0,0,1-11.2-3.6,6,6,0,1,0-7.6,9.2A30,30,0,0,0,127,223c14.4,0,23-7.1,23-19S137.5,187.9,129.2,185.7ZM214,88.2V128a6,6,0,0,1-12,0V94H152a6,6,0,0,1-6-6V38H56a2,2,0,0,0-2,2v88a6,6,0,0,1-12,0V40A14,14,0,0,1,56,26h96a5.6,5.6,0,0,1,4.2,1.8l56,55.9A6,6,0,0,1,214,88ZM158,82h35.5L158,46.5Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M211.2,170.4,195,192l16.2,21.6a4,4,0,0,1-.8,5.6,4.3,4.3,0,0,1-2.4.8,4.2,4.2,0,0,1-3.2-1.6L190,198.7l-14.8,19.7A4.2,4.2,0,0,1,172,220a4.3,4.3,0,0,1-2.4-.8,4,4,0,0,1-.8-5.6L185,192l-16.2-21.6a4,4,0,1,1,6.4-4.8L190,185.3l14.8-19.7a4,4,0,0,1,6.4,4.8ZM84,164H48a4,4,0,0,0,0,8H62v44a4,4,0,0,0,8,0V172H84a4,4,0,0,0,0-8Zm44.6,23.7c-8.6-2.3-12.6-3.8-12.6-7.7,0-5.6,4.1-9,11-9a20.7,20.7,0,0,1,12.4,4.1,4,4,0,1,0,5.2-6.2A30,30,0,0,0,127,163c-11.4,0-19,6.8-19,17s10.3,13.2,18.6,15.4,13.4,4,13.4,8.6c0,2.5,0,9-13,9a21.5,21.5,0,0,1-12.5-4.1,4,4,0,0,0-5.6.5,4.1,4.1,0,0,0,.5,5.7A30,30,0,0,0,127,221c13.3,0,21-6.2,21-17S137.3,189.9,128.6,187.7ZM212,88.1V128a4,4,0,0,1-8,0V92H152a4,4,0,0,1-4-4V36H56a4,4,0,0,0-4,4v88a4,4,0,0,1-8,0V40A12,12,0,0,1,56,28h96a3.6,3.6,0,0,1,2.8,1.2l56,55.9A4.5,4.5,0,0,1,212,88ZM156,84h42.3L156,41.7Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M214.4,172.8,200,192l14.4,19.2a8,8,0,0,1-1.6,11.2A7.7,7.7,0,0,1,208,224a8,8,0,0,1-6.4-3.2L190,205.3l-11.6,15.5A8,8,0,0,1,172,224a7.7,7.7,0,0,1-4.8-1.6,8,8,0,0,1-1.6-11.2L180,192l-14.4-19.2a8,8,0,0,1,12.8-9.6L190,178.7l11.6-15.5a8.1,8.1,0,0,1,11.2-1.6A8,8,0,0,1,214.4,172.8ZM84,160H48a8,8,0,0,0,0,16H58v40a8,8,0,0,0,16,0V176H84a8,8,0,0,0,0-16ZM216,88.2V128a8,8,0,0,1-16,0V96H152a8,8,0,0,1-8-8V40H56v88a8,8,0,0,1-16,0V40A16,16,0,0,1,56,24h96a8.1,8.1,0,0,1,5.7,2.3l55.9,56A7.8,7.8,0,0,1,216,88ZM160,80h28.7L160,51.3ZM129.7,183.8c-3.1-.8-8.6-2.3-9.7-3.6V180c0-3.3,2.4-5,7-5a18.4,18.4,0,0,1,10,3.2h-.1A8.3,8.3,0,0,0,142,180a8,8,0,0,0,8-8,7.6,7.6,0,0,0-2.9-6.1h0A33.4,33.4,0,0,0,127,159c-13.5,0-23,8.6-23,21s13,17,21.6,19.3c3.4.9,7,1.8,9.2,3.1s1.2,1,1.2,1.6,0,5-9,5a18.4,18.4,0,0,1-10-3.2h.1A8.3,8.3,0,0,0,112,204a8,8,0,0,0-8,8,7.6,7.6,0,0,0,2.9,6.1h0A33.4,33.4,0,0,0,127,225c15.6,0,25-7.9,25-21S138,186,129.7,183.8Z" />
        </>
      )}
    </svg>
  );
}

export function PhFileVideo(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216.5,79.5l-56-56A12.3,12.3,0,0,0,152,20H56A20.1,20.1,0,0,0,36,40v80a12,12,0,0,0,24,0V44h76V92a12,12,0,0,0,12,12h48V212h-8a12,12,0,0,0,0,24h12a20.1,20.1,0,0,0,20-20V88A12.3,12.3,0,0,0,216.5,79.5ZM160,57l23,23H160Zm-5,97.3a11.9,11.9,0,0,0-10.8-1.7l-21.9,7.3A20,20,0,0,0,104,148H48a20.1,20.1,0,0,0-20,20v40a20.1,20.1,0,0,0,20,20h56a20,20,0,0,0,18.1-11.5l21.8,7.9a11.5,11.5,0,0,0,4.1.8,12.5,12.5,0,0,0,6.9-2.2,11.9,11.9,0,0,0,5.1-9.8V164A11.9,11.9,0,0,0,155,154.3ZM52,204V172h48v4.2h0V204Zm84-8-12-4.4v-7l12-4Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M112,180l32-16v49.2L112,196v12a8,8,0,0,1-8,8H48a8,8,0,0,1-8-8V168a8,8,0,0,1,8-8h56a8,8,0,0,1,8,8ZM152,32V88h56Z"
            opacity="0.2"
          />
          <path d="M216,88a7.8,7.8,0,0,0-2.4-5.7l-55.9-56A8.1,8.1,0,0,0,152,24H56A16,16,0,0,0,40,40v88a8,8,0,0,0,16,0V40h88V88a8,8,0,0,0,8,8h48V216H176a8,8,0,0,0,0,16h24a16,16,0,0,0,16-16V88ZM160,51.3,188.7,80H160ZM148.2,157.2a8,8,0,0,0-7.8-.4L120,167.1A16.1,16.1,0,0,0,104,152H48a16,16,0,0,0-16,16v40a16,16,0,0,0,16,16h56a16,16,0,0,0,15.9-14.7l20.3,10.9a7.5,7.5,0,0,0,3.8,1,7.8,7.8,0,0,0,4.1-1.2,7.9,7.9,0,0,0,3.9-6.8V164A7.9,7.9,0,0,0,148.2,157.2ZM104,208H48V168h56v40Zm32-8.2-16-8.6v-6.3l16-8Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M152,164v49.2a8.1,8.1,0,0,1-4.5,7.2,8.5,8.5,0,0,1-3.5.8,7.9,7.9,0,0,1-4.9-1.7l-19.1-15V208a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V168a16,16,0,0,1,16-16h56a16,16,0,0,1,16,16v4l19.2-14.4A8,8,0,0,1,152,164Zm64-76V216a16,16,0,0,1-16,16H168a8,8,0,0,1,0-16h32V96H152a8,8,0,0,1-8-8V40H56v80a8,8,0,0,1-16,0V40A16,16,0,0,1,56,24h96a8.1,8.1,0,0,1,5.7,2.3l56,56A8.1,8.1,0,0,1,216,88Zm-27.3-8L160,51.3V80Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M214,88a6,6,0,0,0-1.8-4.3l-56-55.9A5.6,5.6,0,0,0,152,26H56A14,14,0,0,0,42,40v88a6,6,0,0,0,12,0V40a2,2,0,0,1,2-2h90V88a6,6,0,0,0,6,6h50V216a2,2,0,0,1-2,2H176a6,6,0,0,0,0,12h24a14,14,0,0,0,14-14V88ZM158,46.5,193.5,82H158ZM147.2,158.9a6.2,6.2,0,0,0-5.9-.3L118,170.3V168a14,14,0,0,0-14-14H48a14,14,0,0,0-14,14v40a14,14,0,0,0,14,14h56a14,14,0,0,0,14-14v-2l23.2,12.5a6.4,6.4,0,0,0,2.8.7,5.9,5.9,0,0,0,3.1-.9,6,6,0,0,0,2.9-5.1V164A6.1,6.1,0,0,0,147.2,158.9ZM104,210H48a2,2,0,0,1-2-2V168a2,2,0,0,1,2-2h56a2,2,0,0,1,2,2v28h0v12A2,2,0,0,1,104,210Zm34-6.9-20-10.7v-8.7l20-10Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M212,88a4.5,4.5,0,0,0-1.2-2.9l-56-55.9A3.6,3.6,0,0,0,152,28H56A12,12,0,0,0,44,40v88a4,4,0,0,0,8,0V40a4,4,0,0,1,4-4h92V88a4,4,0,0,0,4,4h52V216a4,4,0,0,1-4,4H176a4,4,0,0,0,0,8h24a12,12,0,0,0,12-12V88.1ZM156,41.7,198.3,84H156Zm-9.9,118.9a4.1,4.1,0,0,0-3.9-.2L116,173.5V168a12,12,0,0,0-12-12H48a12,12,0,0,0-12,12v40a12,12,0,0,0,12,12h56a12,12,0,0,0,12-12v-5.3l26.1,14a4.5,4.5,0,0,0,1.9.5,4.2,4.2,0,0,0,2.1-.6,3.9,3.9,0,0,0,1.9-3.4V164A3.9,3.9,0,0,0,146.1,160.6ZM108,208a4,4,0,0,1-4,4H48a4,4,0,0,1-4-4V168a4,4,0,0,1,4-4h56a4,4,0,0,1,4,4Zm32-1.5-24-12.9V182.5l24-12Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,88a7.8,7.8,0,0,0-2.4-5.7l-55.9-56A8.1,8.1,0,0,0,152,24H56A16,16,0,0,0,40,40v88a8,8,0,0,0,16,0V40h88V88a8,8,0,0,0,8,8h48V216H176a8,8,0,0,0,0,16h24a16,16,0,0,0,16-16V88ZM160,51.3,188.7,80H160ZM148.2,157.2a8,8,0,0,0-7.8-.4L120,167.1A16.1,16.1,0,0,0,104,152H48a16,16,0,0,0-16,16v40a16,16,0,0,0,16,16h56a16,16,0,0,0,15.9-14.7l20.3,10.9a7.5,7.5,0,0,0,3.8,1,7.8,7.8,0,0,0,4.1-1.2,7.9,7.9,0,0,0,3.9-6.8V164A7.9,7.9,0,0,0,148.2,157.2ZM104,208H48V168h56v40Zm32-8.2-16-8.6v-6.3l16-8Z" />
        </>
      )}
    </svg>
  );
}

export function PhFileVue(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M232,220a12,12,0,0,1-12,12H188a12,12,0,0,1-12-12V164a12,12,0,0,1,12-12h32a12,12,0,0,1,0,24H200v4h16a12,12,0,0,1,0,24H200v4h20A12,12,0,0,1,232,220ZM80.4,152.8a12.1,12.1,0,0,0-15.6,6.8L54,187.2,43.2,159.6a12,12,0,1,0-22.4,8.8l22,56a12.1,12.1,0,0,0,22.4,0l22-56A12.1,12.1,0,0,0,80.4,152.8ZM32,124V44A20.1,20.1,0,0,1,52,24H156a12.3,12.3,0,0,1,8.5,3.5l56,56A12.3,12.3,0,0,1,224,92v32a12,12,0,0,1-24,0V112H148a12,12,0,0,1-12-12V48H56v76a12,12,0,0,1-24,0ZM160,57V88h31Zm-12,95a12,12,0,0,0-12,12v36a8,8,0,0,1-16,0V164a12,12,0,0,0-24,0v36a32,32,0,0,0,64,0V164A12,12,0,0,0,148,152Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M208,88H152V32Z" opacity="0.2" />
          <path d="M188,176v8h16a8,8,0,0,1,0,16H188v8h20a8,8,0,0,1,0,16H180a8,8,0,0,1-8-8V168a8,8,0,0,1,8-8h28a8,8,0,0,1,0,16ZM87.1,160.6a8,8,0,0,0-10.5,4.3L64,195.2,51.4,164.9a8,8,0,0,0-14.8,6.2l20,48a8,8,0,0,0,14.8,0l20-48A8,8,0,0,0,87.1,160.6ZM148,160a8,8,0,0,0-8,8v30a10,10,0,0,1-20,0V168a8,8,0,0,0-16,0v30a26,26,0,0,0,52,0V168A8,8,0,0,0,148,160Zm68-71.8V128a8,8,0,0,1-16,0V96H152a8,8,0,0,1-8-8V40H56v88a8,8,0,0,1-16,0V40A16,16,0,0,1,56,24h96a8.1,8.1,0,0,1,5.7,2.3l55.9,56A7.8,7.8,0,0,1,216,88ZM160,80h28.7L160,51.3Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M188,176v8h16a8,8,0,0,1,0,16H188v8h20a8,8,0,0,1,0,16H180a8,8,0,0,1-8-8V168a8,8,0,0,1,8-8h28a8,8,0,0,1,0,16ZM87.1,160.6a8,8,0,0,0-10.5,4.3L64,195.2,51.4,164.9a8,8,0,0,0-14.8,6.2l20,48a8,8,0,0,0,14.8,0l20-48A8,8,0,0,0,87.1,160.6ZM148,160a8,8,0,0,0-8,8v30a10,10,0,0,1-20,0V168a8,8,0,0,0-16,0v30a26,26,0,0,0,52,0V168A8,8,0,0,0,148,160Zm68-72v40a8,8,0,0,1-8,8H48a8,8,0,0,1-8-8V40A16,16,0,0,1,56,24h96a8.1,8.1,0,0,1,5.7,2.3l56,56A8.1,8.1,0,0,1,216,88Zm-20,0L152,44V88Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M186,174v12h18a6,6,0,0,1,0,12H186v12h22a6,6,0,0,1,0,12H180a6,6,0,0,1-6-6V168a6,6,0,0,1,6-6h28a6,6,0,0,1,0,12ZM86.3,162.5a5.8,5.8,0,0,0-7.8,3.2L64,200.4,49.5,165.7a6,6,0,0,0-11,4.6l20,48a5.9,5.9,0,0,0,11,0l20-48A5.8,5.8,0,0,0,86.3,162.5ZM148,162a6,6,0,0,0-6,6v30a12,12,0,0,1-24,0V168a6,6,0,0,0-12,0v30a24,24,0,0,0,48,0V168A6,6,0,0,0,148,162Zm66-73.8V128a6,6,0,0,1-12,0V94H152a6,6,0,0,1-6-6V38H56a2,2,0,0,0-2,2v88a6,6,0,0,1-12,0V40A14,14,0,0,1,56,26h96a5.6,5.6,0,0,1,4.2,1.8l56,55.9A6,6,0,0,1,214,88ZM158,82h35.5L158,46.5Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M184,172v16h20a4,4,0,0,1,0,8H184v16h24a4,4,0,0,1,0,8H180a4,4,0,0,1-4-4V168a4,4,0,0,1,4-4h28a4,4,0,0,1,0,8Zm-98.5-7.7a4,4,0,0,0-5.2,2.2L64,205.6,47.7,166.5a4,4,0,0,0-7.4,3l20,48a4,4,0,0,0,7.4,0l20-48A4,4,0,0,0,85.5,164.3ZM148,164a4,4,0,0,0-4,4v30a14,14,0,0,1-28,0V168a4,4,0,0,0-8,0v30a22,22,0,0,0,44,0V168A4,4,0,0,0,148,164Zm64-75.9V128a4,4,0,0,1-8,0V92H152a4,4,0,0,1-4-4V36H56a4,4,0,0,0-4,4v88a4,4,0,0,1-8,0V40A12,12,0,0,1,56,28h96a3.6,3.6,0,0,1,2.8,1.2l56,55.9A4.5,4.5,0,0,1,212,88ZM156,84h42.3L156,41.7Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M188,176v8h16a8,8,0,0,1,0,16H188v8h20a8,8,0,0,1,0,16H180a8,8,0,0,1-8-8V168a8,8,0,0,1,8-8h28a8,8,0,0,1,0,16ZM87.1,160.6a8,8,0,0,0-10.5,4.3L64,195.2,51.4,164.9a8,8,0,0,0-14.8,6.2l20,48a8,8,0,0,0,14.8,0l20-48A8,8,0,0,0,87.1,160.6ZM148,160a8,8,0,0,0-8,8v30a10,10,0,0,1-20,0V168a8,8,0,0,0-16,0v30a26,26,0,0,0,52,0V168A8,8,0,0,0,148,160Zm68-71.8V128a8,8,0,0,1-16,0V96H152a8,8,0,0,1-8-8V40H56v88a8,8,0,0,1-16,0V40A16,16,0,0,1,56,24h96a8.1,8.1,0,0,1,5.7,2.3l55.9,56A7.8,7.8,0,0,1,216,88ZM160,80h28.7L160,51.3Z" />
        </>
      )}
    </svg>
  );
}

export function PhFileX(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216.5,79.5l-56-56A12.3,12.3,0,0,0,152,20H56A20.1,20.1,0,0,0,36,40V216a20.1,20.1,0,0,0,20,20H200a20.1,20.1,0,0,0,20-20V88A12.3,12.3,0,0,0,216.5,79.5ZM160,57l23,23H160ZM60,212V44h76V92a12,12,0,0,0,12,12h48V212Zm100.5-75.5L145,152l15.5,15.5a12,12,0,0,1,0,17,12.1,12.1,0,0,1-17,0L128,169l-15.5,15.5a12.1,12.1,0,0,1-17,0,12,12,0,0,1,0-17L111,152,95.5,136.5a12,12,0,0,1,17-17L128,135l15.5-15.5a12,12,0,0,1,17,17Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M208,88H152V32Z" opacity="0.2" />
          <path d="M213.7,82.3l-56-56A8.1,8.1,0,0,0,152,24H56A16,16,0,0,0,40,40V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V88A8.1,8.1,0,0,0,213.7,82.3ZM160,51.3,188.7,80H160ZM200,216H56V40h88V88a8,8,0,0,0,8,8h48V216Zm-42.3-82.3L139.3,152l18.4,18.3a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0L128,163.3l-18.3,18.4a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L116.7,152,98.3,133.7a8.1,8.1,0,0,1,11.4-11.4L128,140.7l18.3-18.4a8.1,8.1,0,0,1,11.4,11.4Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M213.7,82.3l-56-56A8.1,8.1,0,0,0,152,24H56A16,16,0,0,0,40,40V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V88A8.1,8.1,0,0,0,213.7,82.3Zm-56,88a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0L128,163.3l-18.3,18.4a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L116.7,152,98.3,133.7a8.1,8.1,0,0,1,11.4-11.4L128,140.7l18.3-18.4a8.1,8.1,0,0,1,11.4,11.4L139.3,152ZM152,88V44l44,44Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M212.2,83.8l-56-56A5.6,5.6,0,0,0,152,26H56A14,14,0,0,0,42,40V216a14,14,0,0,0,14,14H200a14,14,0,0,0,14-14V88A5.6,5.6,0,0,0,212.2,83.8ZM158,46.5,193.5,82H158ZM200,218H56a2,2,0,0,1-2-2V40a2,2,0,0,1,2-2h90V88a6,6,0,0,0,6,6h50V216A2,2,0,0,1,200,218Zm-43.8-85.8L136.5,152l19.7,19.8a5.9,5.9,0,1,1-8.4,8.4L128,160.5l-19.8,19.7a5.9,5.9,0,0,1-8.4-8.4L119.5,152,99.8,132.2a5.9,5.9,0,0,1,8.4-8.4L128,143.5l19.8-19.7a5.9,5.9,0,0,1,8.4,8.4Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M210.8,85.2l-56-56A3.6,3.6,0,0,0,152,28H56A12,12,0,0,0,44,40V216a12,12,0,0,0,12,12H200a12,12,0,0,0,12-12V88A3.6,3.6,0,0,0,210.8,85.2ZM156,41.7,198.3,84H156ZM200,220H56a4,4,0,0,1-4-4V40a4,4,0,0,1,4-4h92V88a4,4,0,0,0,4,4h52V216A4,4,0,0,1,200,220Zm-45.2-89.2L133.7,152l21.1,21.2a3.9,3.9,0,0,1,0,5.6,3.9,3.9,0,0,1-5.6,0L128,157.7l-21.2,21.1a3.9,3.9,0,0,1-5.6,0,3.9,3.9,0,0,1,0-5.6L122.3,152l-21.1-21.2a4,4,0,1,1,5.6-5.6L128,146.3l21.2-21.1a4,4,0,1,1,5.6,5.6Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M213.7,82.3l-56-56A8.1,8.1,0,0,0,152,24H56A16,16,0,0,0,40,40V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V88A8.1,8.1,0,0,0,213.7,82.3ZM160,51.3,188.7,80H160ZM200,216H56V40h88V88a8,8,0,0,0,8,8h48V216Zm-42.3-82.3L139.3,152l18.4,18.3a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0L128,163.3l-18.3,18.4a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L116.7,152,98.3,133.7a8.1,8.1,0,0,1,11.4-11.4L128,140.7l18.3-18.4a8.1,8.1,0,0,1,11.4,11.4Z" />
        </>
      )}
    </svg>
  );
}

export function PhFileXls(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M93.8,171,78.7,192l15.1,21a12,12,0,0,1-19.6,14L64,212.6,53.8,227a12,12,0,1,1-19.6-14l15.1-21L34.2,171a12,12,0,1,1,19.6-14L64,171.4,74.2,157a12,12,0,0,1,19.6,14ZM148,208H132V164a12,12,0,0,0-24,0v56a12,12,0,0,0,12,12h28a12,12,0,0,0,0-24ZM224,92v32a12,12,0,0,1-24,0V112H148a12,12,0,0,1-12-12V48H56v76a12,12,0,0,1-24,0V44A20.1,20.1,0,0,1,52,24H156a12.3,12.3,0,0,1,8.5,3.5l56,56A12.3,12.3,0,0,1,224,92Zm-64-4h31L160,57Zm39,92c-2.1-.7-5.3-1.6-7-2.4.2-1,.7-1.6,3-1.6a12,12,0,0,1,7,2.5h-.2a12,12,0,0,0,20.2-8.8,12.1,12.1,0,0,0-3.8-8.8h0A36.2,36.2,0,0,0,195,152c-15.6,0-27,11.2-27,26.6,0,17.2,15.7,21.9,24.2,24.4,2.4.7,6.2,1.8,7.8,2.8a3.7,3.7,0,0,1-.2,1.4c-.3.2-1.7.8-4.8.8a12,12,0,0,1-7-2.5h.2a12,12,0,0,0-20.2,8.8,12.1,12.1,0,0,0,3.8,8.8h0A36.2,36.2,0,0,0,195,232c17.6,0,29-10.4,29-26.6C224,187.4,207.8,182.6,199,180Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M208,88H152V32Z" opacity="0.2" />
          <path d="M156,216a8,8,0,0,1-8,8H120a8,8,0,0,1-8-8V168a8,8,0,0,1,16,0v40h20A8,8,0,0,1,156,216ZM92.8,161.6a8,8,0,0,0-11.2,1.6L70,178.7,58.4,163.2a8,8,0,1,0-12.8,9.6L60,192,45.6,211.2a8,8,0,0,0,1.6,11.2A7.7,7.7,0,0,0,52,224a8,8,0,0,0,6.4-3.2L70,205.3l11.6,15.5A8,8,0,0,0,88,224a7.7,7.7,0,0,0,4.8-1.6,8,8,0,0,0,1.6-11.2L80,192l14.4-19.2A8,8,0,0,0,92.8,161.6ZM216,88.2V128a8,8,0,0,1-16,0V96H152a8,8,0,0,1-8-8V40H56v88a8,8,0,0,1-16,0V40A16,16,0,0,1,56,24h96a8.1,8.1,0,0,1,5.7,2.3l55.9,56A7.8,7.8,0,0,1,216,88ZM160,80h28.7L160,51.3Zm33.7,103.8c-3.1-.8-8.6-2.3-9.7-3.6V180c0-3.3,2.4-5,7-5a18.4,18.4,0,0,1,10,3.2h-.1A8.3,8.3,0,0,0,206,180a8,8,0,0,0,8-8,7.6,7.6,0,0,0-2.9-6.1h0A33.4,33.4,0,0,0,191,159c-13.5,0-23,8.6-23,21s13,17,21.6,19.3c3.4.9,7,1.8,9.2,3.1s1.2,1,1.2,1.6,0,5-9,5a18.4,18.4,0,0,1-10-3.2h.1A8.3,8.3,0,0,0,176,204a8,8,0,0,0-8,8,7.6,7.6,0,0,0,2.9,6.1h0A33.4,33.4,0,0,0,191,225c15.6,0,25-7.9,25-21S202,186,193.7,183.8Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M94.4,172.8,80,192l14.4,19.2a8,8,0,0,1-1.6,11.2A7.7,7.7,0,0,1,88,224a8,8,0,0,1-6.4-3.2L70,205.3,58.4,220.8A8,8,0,0,1,52,224a7.7,7.7,0,0,1-4.8-1.6,8,8,0,0,1-1.6-11.2L60,192,45.6,172.8a8,8,0,1,1,12.8-9.6L70,178.7l11.6-15.5a8,8,0,0,1,12.8,9.6ZM148,208H128V168a8,8,0,0,0-16,0v48a8,8,0,0,0,8,8h28a8,8,0,0,0,0-16Zm45.7-24.2c-3.1-.8-8.6-2.3-9.7-3.6V180c0-3.3,2.4-5,7-5a18.4,18.4,0,0,1,10,3.2h-.1A8.3,8.3,0,0,0,206,180a8,8,0,0,0,8-8,7.6,7.6,0,0,0-2.9-6.1h0A33.4,33.4,0,0,0,191,159c-13.5,0-23,8.6-23,21s13,17,21.6,19.3c3.4.9,7,1.8,9.2,3.1s1.2,1,1.2,1.6,0,5-9,5a18.4,18.4,0,0,1-10-3.2h.1A8.3,8.3,0,0,0,176,204a8,8,0,0,0-8,8,7.6,7.6,0,0,0,2.9,6.1h0A33.4,33.4,0,0,0,191,225c15.7,0,25-7.9,25-21S202,186,193.7,183.8ZM216,88v40a8,8,0,0,1-8,8H48a8,8,0,0,1-8-8V40A16,16,0,0,1,56,24h96a8.1,8.1,0,0,1,5.7,2.3l56,56A8.1,8.1,0,0,1,216,88Zm-20,0L152,44V88Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M154,216a6,6,0,0,1-6,6H120a6,6,0,0,1-6-6V168a6,6,0,0,1,12,0v42h22A6,6,0,0,1,154,216ZM91.6,163.2a5.9,5.9,0,0,0-8.4,1.2L70,182,56.8,164.4a6,6,0,1,0-9.6,7.2L62.5,192,47.2,212.4a6,6,0,1,0,9.6,7.2L70,202l13.2,17.6a6,6,0,0,0,9.6-7.2L77.5,192l15.3-20.4A5.9,5.9,0,0,0,91.6,163.2Zm101.6,22.5c-7.8-2-11.2-3.3-11.2-5.7,0-6.1,5.6-7,9-7a19.7,19.7,0,0,1,11.2,3.6,6,6,0,0,0,7.6-9.2A30,30,0,0,0,191,161c-12.4,0-21,7.8-21,19s11.6,15.1,20.1,17.3S202,201,202,204s0,7-11,7a20,20,0,0,1-11.2-3.6,6,6,0,1,0-7.6,9.2A30,30,0,0,0,191,223c14.4,0,23-7.1,23-19S201.5,187.9,193.2,185.7ZM214,88.2V128a6,6,0,0,1-12,0V94H152a6,6,0,0,1-6-6V38H56a2,2,0,0,0-2,2v88a6,6,0,0,1-12,0V40A14,14,0,0,1,56,26h96a5.6,5.6,0,0,1,4.2,1.8l56,55.9A6,6,0,0,1,214,88ZM158,82h35.5L158,46.5Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M152,216a4,4,0,0,1-4,4H120a4,4,0,0,1-4-4V168a4,4,0,0,1,8,0v44h24A4,4,0,0,1,152,216ZM90.4,164.8a4,4,0,0,0-5.6.8L70,185.3,55.2,165.6a4,4,0,0,0-6.4,4.8L65,192,48.8,213.6a4,4,0,0,0,.8,5.6,4.3,4.3,0,0,0,2.4.8,4.2,4.2,0,0,0,3.2-1.6L70,198.7l14.8,19.7A4.2,4.2,0,0,0,88,220a4.3,4.3,0,0,0,2.4-.8,4,4,0,0,0,.8-5.6L75,192l16.2-21.6A4,4,0,0,0,90.4,164.8Zm102.2,22.9c-8.6-2.3-12.6-3.8-12.6-7.7,0-5.6,4.1-9,11-9a20.7,20.7,0,0,1,12.4,4.1,4,4,0,0,0,5.2-6.2A30,30,0,0,0,191,163c-11.4,0-19,6.8-19,17s10.3,13.2,18.6,15.4,13.4,4,13.4,8.6c0,2.5,0,9-13,9a21.5,21.5,0,0,1-12.5-4.1,4,4,0,0,0-5.6.5,4.1,4.1,0,0,0,.5,5.7A30,30,0,0,0,191,221c13.3,0,21-6.2,21-17S201.3,189.9,192.6,187.7ZM212,88.1V128a4,4,0,0,1-8,0V92H152a4,4,0,0,1-4-4V36H56a4,4,0,0,0-4,4v88a4,4,0,0,1-8,0V40A12,12,0,0,1,56,28h96a3.6,3.6,0,0,1,2.8,1.2l56,55.9A4.5,4.5,0,0,1,212,88ZM156,84h42.3L156,41.7Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M156,216a8,8,0,0,1-8,8H120a8,8,0,0,1-8-8V168a8,8,0,0,1,16,0v40h20A8,8,0,0,1,156,216ZM92.8,161.6a8,8,0,0,0-11.2,1.6L70,178.7,58.4,163.2a8,8,0,1,0-12.8,9.6L60,192,45.6,211.2a8,8,0,0,0,1.6,11.2A7.7,7.7,0,0,0,52,224a8,8,0,0,0,6.4-3.2L70,205.3l11.6,15.5A8,8,0,0,0,88,224a7.7,7.7,0,0,0,4.8-1.6,8,8,0,0,0,1.6-11.2L80,192l14.4-19.2A8,8,0,0,0,92.8,161.6ZM216,88.2V128a8,8,0,0,1-16,0V96H152a8,8,0,0,1-8-8V40H56v88a8,8,0,0,1-16,0V40A16,16,0,0,1,56,24h96a8.1,8.1,0,0,1,5.7,2.3l55.9,56A7.8,7.8,0,0,1,216,88ZM160,80h28.7L160,51.3Zm33.7,103.8c-3.1-.8-8.6-2.3-9.7-3.6V180c0-3.3,2.4-5,7-5a18.4,18.4,0,0,1,10,3.2h-.1A8.3,8.3,0,0,0,206,180a8,8,0,0,0,8-8,7.6,7.6,0,0,0-2.9-6.1h0A33.4,33.4,0,0,0,191,159c-13.5,0-23,8.6-23,21s13,17,21.6,19.3c3.4.9,7,1.8,9.2,3.1s1.2,1,1.2,1.6,0,5-9,5a18.4,18.4,0,0,1-10-3.2h.1A8.3,8.3,0,0,0,176,204a8,8,0,0,0-8,8,7.6,7.6,0,0,0,2.9,6.1h0A33.4,33.4,0,0,0,191,225c15.6,0,25-7.9,25-21S202,186,193.7,183.8Z" />
        </>
      )}
    </svg>
  );
}

export function PhFileZip(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M140,164v56a12,12,0,0,1-24,0V164a12,12,0,0,1,24,0ZM84,208H67.3l26.5-37a12.2,12.2,0,0,0,.9-12.5A12.1,12.1,0,0,0,84,152H44a12,12,0,0,0,0,24H60.7L34.2,213a12.2,12.2,0,0,0-.9,12.5A12.1,12.1,0,0,0,44,232H84a12,12,0,0,0,0-24Zm140-24a32.1,32.1,0,0,1-32,32h-4v4a12,12,0,0,1-24,0V164a12,12,0,0,1,12-12h16A32.1,32.1,0,0,1,224,184Zm-24,0a8,8,0,0,0-8-8h-4v16h4A8,8,0,0,0,200,184Zm24-92v32a12,12,0,0,1-24,0V112H148a12,12,0,0,1-12-12V48H56v76a12,12,0,0,1-24,0V44A20.1,20.1,0,0,1,52,24H156a12.3,12.3,0,0,1,8.5,3.5l56,56A12.3,12.3,0,0,1,224,92Zm-33-4L160,57V88Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M208,88H152V32Z" opacity="0.2" />
          <path d="M216,88a7.8,7.8,0,0,0-2.4-5.7l-55.9-56A8.1,8.1,0,0,0,152,24H56A16,16,0,0,0,40,40v88a8,8,0,0,0,16,0V40h88V88a8,8,0,0,0,8,8h48v32a8,8,0,0,0,16,0V88ZM160,51.3,188.7,80H160ZM184,160H168a8,8,0,0,0-8,8v48a8,8,0,0,0,16,0v-8h8a24,24,0,0,0,0-48Zm0,32h-8V176h8a8,8,0,0,1,0,16Zm-48-24v48a8,8,0,0,1-16,0V168a8,8,0,0,1,16,0ZM96,216a8,8,0,0,1-8,8H56a8,8,0,0,1-7.1-4.2,8.3,8.3,0,0,1,.4-8.2L73.1,176H56a8,8,0,0,1,0-16H88a8,8,0,0,1,7.1,4.2,8.3,8.3,0,0,1-.4,8.2L70.9,208H88A8,8,0,0,1,96,216Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M184,160H168a8,8,0,0,0-8,8v48a8,8,0,0,0,16,0v-8h8a24,24,0,0,0,0-48Zm0,32h-8V176h8a8,8,0,0,1,0,16ZM96,216a8,8,0,0,1-8,8H56a8,8,0,0,1-7.1-4.2,8.3,8.3,0,0,1,.4-8.2L73.1,176H56a8,8,0,0,1,0-16H88a8,8,0,0,1,7.1,4.2,8.3,8.3,0,0,1-.4,8.2L70.9,208H88A8,8,0,0,1,96,216Zm40-48v48a8,8,0,0,1-16,0V168a8,8,0,0,1,16,0Zm77.7-85.7-56-56A8.1,8.1,0,0,0,152,24H56A16,16,0,0,0,40,40v88a8,8,0,0,0,8,8H208a8,8,0,0,0,8-8V88A8.1,8.1,0,0,0,213.7,82.3ZM152,88V44l44,44Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M214,88a6,6,0,0,0-1.8-4.3l-56-55.9A5.6,5.6,0,0,0,152,26H56A14,14,0,0,0,42,40v88a6,6,0,0,0,12,0V40a2,2,0,0,1,2-2h90V88a6,6,0,0,0,6,6h50v34a6,6,0,0,0,12,0V88ZM158,46.5,193.5,82H158ZM184,162H168a6,6,0,0,0-6,6v48a6,6,0,0,0,12,0V206h10a22,22,0,0,0,0-44Zm0,32H174V174h10a10,10,0,0,1,0,20Zm-50-26v48a6,6,0,0,1-12,0V168a6,6,0,0,1,12,0ZM94,216a6,6,0,0,1-6,6H56a6,6,0,0,1-5-9.3L76.8,174H56a6,6,0,0,1,0-12H88a6,6,0,0,1,5,9.3L67.2,210H88A6,6,0,0,1,94,216Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M212,88a4.5,4.5,0,0,0-1.2-2.9l-56-55.9A3.6,3.6,0,0,0,152,28H56A12,12,0,0,0,44,40v88a4,4,0,0,0,8,0V40a4,4,0,0,1,4-4h92V88a4,4,0,0,0,4,4h52v36a4,4,0,0,0,8,0V88.1ZM156,41.7,198.3,84H156ZM184,164H168a4,4,0,0,0-4,4v48a4,4,0,0,0,8,0V204h12a20,20,0,0,0,0-40Zm0,32H172V172h12a12,12,0,0,1,0,24Zm-52-28v48a4,4,0,0,1-8,0V168a4,4,0,0,1,8,0ZM92,216a4,4,0,0,1-4,4H56a3.9,3.9,0,0,1-3.5-2.1,4,4,0,0,1,.2-4.1L80.5,172H56a4,4,0,0,1,0-8H88a3.9,3.9,0,0,1,3.5,2.1,4,4,0,0,1-.2,4.1L63.5,212H88A4,4,0,0,1,92,216Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,88a7.8,7.8,0,0,0-2.4-5.7l-55.9-56A8.1,8.1,0,0,0,152,24H56A16,16,0,0,0,40,40v88a8,8,0,0,0,16,0V40h88V88a8,8,0,0,0,8,8h48v32a8,8,0,0,0,16,0V88ZM160,51.3,188.7,80H160ZM184,160H168a8,8,0,0,0-8,8v48a8,8,0,0,0,16,0v-8h8a24,24,0,0,0,0-48Zm0,32h-8V176h8a8,8,0,0,1,0,16Zm-48-24v48a8,8,0,0,1-16,0V168a8,8,0,0,1,16,0ZM96,216a8,8,0,0,1-8,8H56a8,8,0,0,1-7.1-4.2,8.3,8.3,0,0,1,.4-8.2L73.1,176H56a8,8,0,0,1,0-16H88a8,8,0,0,1,7.1,4.2,8.3,8.3,0,0,1-.4,8.2L70.9,208H88A8,8,0,0,1,96,216Z" />
        </>
      )}
    </svg>
  );
}

export function PhFile(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216.5,79.5l-56-56A12.3,12.3,0,0,0,152,20H56A20.1,20.1,0,0,0,36,40V216a20.1,20.1,0,0,0,20,20H200a20.1,20.1,0,0,0,20-20V88A12.3,12.3,0,0,0,216.5,79.5ZM160,57l23,23H160ZM60,212V44h76V92a12,12,0,0,0,12,12h48V212Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M208,88H152V32Z" opacity="0.2" />
          <path d="M213.7,82.3l-56-56A8.1,8.1,0,0,0,152,24H56A16,16,0,0,0,40,40V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V88A8.1,8.1,0,0,0,213.7,82.3ZM160,51.3,188.7,80H160ZM200,216H56V40h88V88a8,8,0,0,0,8,8h48V216Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M213.7,82.3l-56-56A8.1,8.1,0,0,0,152,24H56A16,16,0,0,0,40,40V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V88A8.1,8.1,0,0,0,213.7,82.3ZM152,88V44l44,44Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M212.2,83.8l-56-56A5.6,5.6,0,0,0,152,26H56A14,14,0,0,0,42,40V216a14,14,0,0,0,14,14H200a14,14,0,0,0,14-14V88A5.6,5.6,0,0,0,212.2,83.8ZM158,46.5,193.5,82H158ZM200,218H56a2,2,0,0,1-2-2V40a2,2,0,0,1,2-2h90V88a6,6,0,0,0,6,6h50V216A2,2,0,0,1,200,218Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M210.8,85.2l-56-56A3.6,3.6,0,0,0,152,28H56A12,12,0,0,0,44,40V216a12,12,0,0,0,12,12H200a12,12,0,0,0,12-12V88A3.6,3.6,0,0,0,210.8,85.2ZM156,41.7,198.3,84H156ZM200,220H56a4,4,0,0,1-4-4V40a4,4,0,0,1,4-4h92V88a4,4,0,0,0,4,4h52V216A4,4,0,0,1,200,220Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M213.7,82.3l-56-56A8.1,8.1,0,0,0,152,24H56A16,16,0,0,0,40,40V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V88A8.1,8.1,0,0,0,213.7,82.3ZM160,51.3,188.7,80H160ZM200,216H56V40h88V88a8,8,0,0,0,8,8h48V216Z" />
        </>
      )}
    </svg>
  );
}

export function PhFiles(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M220.5,59.5l-40-40A12.3,12.3,0,0,0,172,16H92A20.1,20.1,0,0,0,72,36V56H56A20.1,20.1,0,0,0,36,76V216a20.1,20.1,0,0,0,20,20H164a20.1,20.1,0,0,0,20-20V196h20a20.1,20.1,0,0,0,20-20V68A12.3,12.3,0,0,0,220.5,59.5ZM160,212H60V80h67l33,33Zm40-40H184V108a12.3,12.3,0,0,0-3.5-8.5l-40-40A12.3,12.3,0,0,0,132,56H96V40h71l33,33Zm-56-28a12,12,0,0,1-12,12H88a12,12,0,0,1,0-24h44A12,12,0,0,1,144,144Zm0,40a12,12,0,0,1-12,12H88a12,12,0,0,1,0-24h44A12,12,0,0,1,144,184Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M208,72V184a8,8,0,0,1-8,8H176V104L136,64H80V40a8,8,0,0,1,8-8h80Z"
            opacity="0.2"
          />
          <path d="M213.7,66.3l-40-40A8.1,8.1,0,0,0,168,24H88A16,16,0,0,0,72,40V56H56A16,16,0,0,0,40,72V216a16,16,0,0,0,16,16H168a16,16,0,0,0,16-16V200h16a16,16,0,0,0,16-16V72A8.1,8.1,0,0,0,213.7,66.3ZM168,216H56V72h76.7L168,107.3V216Zm32-32H184V104a8.1,8.1,0,0,0-2.3-5.7l-40-40A8.1,8.1,0,0,0,136,56H88V40h76.7L200,75.3Zm-56-32a8,8,0,0,1-8,8H88a8,8,0,0,1,0-16h48A8,8,0,0,1,144,152Zm0,32a8,8,0,0,1-8,8H88a8,8,0,0,1,0-16h48A8,8,0,0,1,144,184Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M213.7,66.3l-40-40A8.1,8.1,0,0,0,168,24H88A16,16,0,0,0,72,40V56H56A16,16,0,0,0,40,72V216a16,16,0,0,0,16,16H168a16,16,0,0,0,16-16V200h16a16,16,0,0,0,16-16V72A8.1,8.1,0,0,0,213.7,66.3ZM136,192H88a8,8,0,0,1,0-16h48a8,8,0,0,1,0,16Zm0-32H88a8,8,0,0,1,0-16h48a8,8,0,0,1,0,16Zm64,24H184V104a8.1,8.1,0,0,0-2.3-5.7l-40-40A8.1,8.1,0,0,0,136,56H88V40h76.7L200,75.3Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M212.2,67.8l-40-40A5.6,5.6,0,0,0,168,26H88A14,14,0,0,0,74,40V58H56A14,14,0,0,0,42,72V216a14,14,0,0,0,14,14H168a14,14,0,0,0,14-14V198h18a14,14,0,0,0,14-14V72A5.6,5.6,0,0,0,212.2,67.8ZM170,216a2,2,0,0,1-2,2H56a2,2,0,0,1-2-2V72a2,2,0,0,1,2-2h77.5L170,106.5Zm32-32a2,2,0,0,1-2,2H182V104a5.6,5.6,0,0,0-1.8-4.2l-40-40A5.6,5.6,0,0,0,136,58H86V40a2,2,0,0,1,2-2h77.5L202,74.5Zm-60-32a6,6,0,0,1-6,6H88a6,6,0,0,1,0-12h48A6,6,0,0,1,142,152Zm0,32a6,6,0,0,1-6,6H88a6,6,0,0,1,0-12h48A6,6,0,0,1,142,184Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M210.8,69.2l-40-40A3.6,3.6,0,0,0,168,28H88A12,12,0,0,0,76,40V60H56A12,12,0,0,0,44,72V216a12,12,0,0,0,12,12H168a12,12,0,0,0,12-12V196h20a12,12,0,0,0,12-12V72A3.6,3.6,0,0,0,210.8,69.2ZM172,216a4,4,0,0,1-4,4H56a4,4,0,0,1-4-4V72a4,4,0,0,1,4-4h78.3L172,105.7Zm32-32a4,4,0,0,1-4,4H180V104a3.6,3.6,0,0,0-1.2-2.8l-40-40A3.6,3.6,0,0,0,136,60H84V40a4,4,0,0,1,4-4h78.3L204,73.7Zm-64-32a4,4,0,0,1-4,4H88a4,4,0,0,1,0-8h48A4,4,0,0,1,140,152Zm0,32a4,4,0,0,1-4,4H88a4,4,0,0,1,0-8h48A4,4,0,0,1,140,184Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M213.7,66.3l-40-40A8.1,8.1,0,0,0,168,24H88A16,16,0,0,0,72,40V56H56A16,16,0,0,0,40,72V216a16,16,0,0,0,16,16H168a16,16,0,0,0,16-16V200h16a16,16,0,0,0,16-16V72A8.1,8.1,0,0,0,213.7,66.3ZM168,216H56V72h76.7L168,107.3V216Zm32-32H184V104a8.1,8.1,0,0,0-2.3-5.7l-40-40A8.1,8.1,0,0,0,136,56H88V40h76.7L200,75.3Zm-56-32a8,8,0,0,1-8,8H88a8,8,0,0,1,0-16h48A8,8,0,0,1,144,152Zm0,32a8,8,0,0,1-8,8H88a8,8,0,0,1,0-16h48A8,8,0,0,1,144,184Z" />
        </>
      )}
    </svg>
  );
}

export function PhFilmScript(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M200,20H56A20.1,20.1,0,0,0,36,40V216a20.1,20.1,0,0,0,20,20H200a20.1,20.1,0,0,0,20-20V40A20.1,20.1,0,0,0,200,20Zm-4,192H60V44H196ZM76,80A16,16,0,1,1,92,96,16,16,0,0,1,76,80Zm0,96a16,16,0,1,1,16,16A16,16,0,0,1,76,176Zm0-48a16,16,0,1,1,16,16A16,16,0,0,1,76,128Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M208,40V216a8,8,0,0,1-8,8H56a8,8,0,0,1-8-8V40a8,8,0,0,1,8-8H200A8,8,0,0,1,208,40Z"
            opacity="0.2"
          />
          <path d="M200,24H56A16,16,0,0,0,40,40V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V40A16,16,0,0,0,200,24Zm0,192H56V40H200V216ZM96,80A12,12,0,1,1,84,68,12,12,0,0,1,96,80Zm0,96a12,12,0,1,1-12-12A12,12,0,0,1,96,176Zm0-48a12,12,0,1,1-12-12A12,12,0,0,1,96,128Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M200,24H56A16,16,0,0,0,40,40V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V40A16,16,0,0,0,200,24ZM76,188a12,12,0,1,1,12-12A12,12,0,0,1,76,188Zm0-48a12,12,0,1,1,12-12A12,12,0,0,1,76,140Zm0-48A12,12,0,1,1,88,80,12,12,0,0,1,76,92Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M200,26H56A14,14,0,0,0,42,40V216a14,14,0,0,0,14,14H200a14,14,0,0,0,14-14V40A14,14,0,0,0,200,26Zm2,190a2,2,0,0,1-2,2H56a2,2,0,0,1-2-2V40a2,2,0,0,1,2-2H200a2,2,0,0,1,2,2ZM94,80A10,10,0,1,1,84,70,10,10,0,0,1,94,80Zm0,96a10,10,0,1,1-10-10A10,10,0,0,1,94,176Zm0-48a10,10,0,1,1-10-10A10,10,0,0,1,94,128Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M200,28H56A12,12,0,0,0,44,40V216a12,12,0,0,0,12,12H200a12,12,0,0,0,12-12V40A12,12,0,0,0,200,28Zm4,188a4,4,0,0,1-4,4H56a4,4,0,0,1-4-4V40a4,4,0,0,1,4-4H200a4,4,0,0,1,4,4ZM92,80a8,8,0,1,1-8-8A8,8,0,0,1,92,80Zm0,96a8,8,0,1,1-8-8A8,8,0,0,1,92,176Zm0-48a8,8,0,1,1-8-8A8,8,0,0,1,92,128Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M200,24H56A16,16,0,0,0,40,40V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V40A16,16,0,0,0,200,24Zm0,192H56V40H200V216ZM96,76A12,12,0,1,1,84,64,12,12,0,0,1,96,76Zm0,104a12,12,0,1,1-12-12A12,12,0,0,1,96,180Zm0-52a12,12,0,1,1-12-12A12,12,0,0,1,96,128Z" />
        </>
      )}
    </svg>
  );
}

export function PhFilmSlate(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216,100H131.1l51-13.7h0l31-8.3a11.8,11.8,0,0,0,7.3-5.6,12,12,0,0,0,1.2-9.1l-8.3-30.9a20,20,0,0,0-24.5-14.1L34.3,59.7A20.1,20.1,0,0,0,20.1,84.2L28,113.6V200a20.1,20.1,0,0,0,20,20H208a20.1,20.1,0,0,0,20-20V112A12,12,0,0,0,216,100ZM128.4,59.3,148,70.6l-36.8,9.9L91.6,69.2Zm62.8-16.8L195.3,58l-14.5,3.9L161.2,50.5ZM58.9,78,78.5,89.3l-30,8L44.3,81.8ZM204,196H52V124H204Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M210,66.4l-30.9,8.3-49-28.3,61.8-16.5a7.9,7.9,0,0,1,9.8,5.6ZM37.4,71.3a8,8,0,0,0-5.7,9.8L40,112l69.5-18.6L60.6,65.1Z"
            opacity="0.2"
          />
          <path d="M216,104H100.8L212.1,74.2a7.9,7.9,0,0,0,4.8-3.8,7.5,7.5,0,0,0,.8-6l-8.3-30.9a15.8,15.8,0,0,0-19.5-11.3L35.3,63.6A15.9,15.9,0,0,0,24,83.2l8,29.9V200a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V112A8,8,0,0,0,216,104ZM129,55l29.4,17L110.7,84.8l-29.4-17Zm65-17.4,6.2,23.2-20,5.3L150.8,49.2ZM59.4,73.7,88.8,90.6,45.7,102.2,39.4,79ZM208,200H48V120H208v80Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,104H100.8L212.1,74.2a7.9,7.9,0,0,0,4.8-3.8,7.5,7.5,0,0,0,.8-6l-8.3-30.9a15.8,15.8,0,0,0-19.5-11.3L35.3,63.6A15.9,15.9,0,0,0,24,83.2l8,29.9V200a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V112A8,8,0,0,0,216,104ZM139.9,52.1l29.4,17L99.7,87.7l-29.3-17ZM208,200H48V120H208v80Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M216,106H85.6l25.5-6.8h0l69.5-18.7h0l31-8.3a6,6,0,0,0,4.2-7.3L207.5,34a14.2,14.2,0,0,0-6.5-8.5,13.6,13.6,0,0,0-10.6-1.4L35.8,65.5a14,14,0,0,0-9.9,17.1L34,112.8V200a14,14,0,0,0,14,14H208a14,14,0,0,0,14-14V112A6,6,0,0,0,216,106ZM129.3,52.9l34.2,19.8L110.4,86.9,76.1,67.1Zm64.2-17.2h.5a1.9,1.9,0,0,1,1.9,1.5l6.8,25.1-22.8,6.1L145.6,48.5ZM38.9,77.1l20.8-5.6L94,91.3,44.2,104.7,37.5,79.5A2,2,0,0,1,38.9,77.1ZM210,200a2,2,0,0,1-2,2H48a2,2,0,0,1-2-2V118H210Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M216,108H70.4l40.2-10.8h0l69.5-18.6h0L211,70.3a4,4,0,0,0,2.5-1.9,4.5,4.5,0,0,0,.4-3l-8.3-30.9a11.8,11.8,0,0,0-5.6-7.3,12.1,12.1,0,0,0-9.1-1.2L59.5,61.2h0L36.3,67.4a11.9,11.9,0,0,0-8.4,14.7L36,112.5V200a12,12,0,0,0,12,12H208a12,12,0,0,0,12-12V112A4,4,0,0,0,216,108ZM129.5,50.7l39.2,22.7L110.1,89.1,70.9,66.4Zm63.5-17h1a4.1,4.1,0,0,1,3.9,3l7.2,27-25.4,6.8L140.5,47.8ZM38.4,75.2,60,69.4,99.2,92,42.8,107.1l-7.2-27A3.9,3.9,0,0,1,38.4,75.2ZM212,200a4,4,0,0,1-4,4H48a4,4,0,0,1-4-4V116H212Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,104H100.8L212.1,74.2a7.9,7.9,0,0,0,4.8-3.8,7.5,7.5,0,0,0,.8-6l-8.3-30.9a15.8,15.8,0,0,0-19.5-11.3L35.3,63.6A15.9,15.9,0,0,0,24,83.2l8,29.9V200a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V112A8,8,0,0,0,216,104ZM129,55l29.4,17L110.7,84.8l-29.4-17Zm65-17.4,6.2,23.2-20,5.3L150.8,49.2ZM59.4,73.7,88.8,90.6,45.7,102.2,39.4,79ZM208,200H48V120H208v80Z" />
        </>
      )}
    </svg>
  );
}

export function PhFilmStrip(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216,36H40A20.1,20.1,0,0,0,20,56V200a20.1,20.1,0,0,0,20,20H216a20.1,20.1,0,0,0,20-20V56A20.1,20.1,0,0,0,216,36ZM44,100h72v56H44Zm96-24V60h24V76Zm-24,0H92V60h24Zm0,104v16H92V180Zm24,0h24v16H140Zm0-24V100h72v56Zm72-80H188V60h24ZM68,60V76H44V60ZM44,180H68v16H44Zm144,16V180h24v16Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M32,176H224v24a8,8,0,0,1-8,8H40a8,8,0,0,1-8-8Z" />
          </g>
          <g opacity="0.2">
            <path d="M224,80H32V56a8,8,0,0,1,8-8H216a8,8,0,0,1,8,8Z" />
          </g>
          <path d="M216,40H40A16,16,0,0,0,24,56V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40ZM40,88h80v80H40Zm96-16V56h32V72Zm-16,0H88V56h32Zm0,112v16H88V184Zm16,0h32v16H136Zm0-16V88h80v80Zm80-96H184V56h32ZM72,56V72H40V56ZM40,184H72v16H40Zm176,16H184V184h32v16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,40H40A16,16,0,0,0,24,56V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40ZM184,56h32V72H184Zm-48,0h32V72H136ZM88,56h32V72H88ZM72,200H40V184H72ZM72,72H40V56H72ZM88,200V185.3h32V200Zm48,0V185.3h32V200Zm80,0H184V185.3h9.2V184H216v16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M216,42H40A14,14,0,0,0,26,56V200a14,14,0,0,0,14,14H216a14,14,0,0,0,14-14V56A14,14,0,0,0,216,42ZM38,86h84v84H38Zm96-12V54h36V74Zm-12,0H86V54h36Zm0,108v20H86V182Zm12,0h36v20H134Zm0-12V86h84v84ZM218,56V74H182V54h34A2,2,0,0,1,218,56ZM40,54H74V74H38V56A2,2,0,0,1,40,54ZM38,200V182H74v20H40A2,2,0,0,1,38,200Zm178,2H182V182h36v18A2,2,0,0,1,216,202Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M216,44H40A12,12,0,0,0,28,56V200a12,12,0,0,0,12,12H216a12,12,0,0,0,12-12V56A12,12,0,0,0,216,44ZM36,84h88v88H36Zm96-8V52h40V76Zm-8,0H84V52h40Zm0,104v24H84V180Zm8,0h40v24H132Zm0-8V84h88v88ZM220,56V76H180V52h36A4,4,0,0,1,220,56ZM40,52H76V76H36V56A4,4,0,0,1,40,52ZM36,200V180H76v24H40A4,4,0,0,1,36,200Zm180,4H180V180h40v20A4,4,0,0,1,216,204Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,40H40A16,16,0,0,0,24,56V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40ZM40,88h80v80H40Zm96-16V56h32V72Zm-16,0H88V56h32Zm0,112v16H88V184Zm16,0h32v16H136Zm0-16V88h80v80Zm80-96H184V56h32ZM72,56V72H40V56ZM40,184H72v16H40Zm176,16H184V184h32v16Z" />
        </>
      )}
    </svg>
  );
}

export function PhFingerprintSimple(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M188,128a250.9,250.9,0,0,1-18.8,95.8,12,12,0,0,1-11.1,7.4,12.2,12.2,0,0,1-4.6-.9,12,12,0,0,1-6.5-15.7A226.6,226.6,0,0,0,164,128a12,12,0,0,1,24,0ZM128,92a36.1,36.1,0,0,1,26.8,12,12,12,0,0,0,17.9-16A60,60,0,0,0,68,128a131.6,131.6,0,0,1-16.5,63.9,12,12,0,0,0,21,11.7A155.9,155.9,0,0,0,92,128,36,36,0,0,1,128,92Zm0-72a109.3,109.3,0,0,0-36,6.1,12,12,0,0,0,8,22.7A83.2,83.2,0,0,1,128,44a84.1,84.1,0,0,1,84,84,279.5,279.5,0,0,1-6.8,61.3,12,12,0,0,0,9,14.4,12.4,12.4,0,0,0,2.7.3,12.1,12.1,0,0,0,11.7-9.3A304.4,304.4,0,0,0,236,128,108.1,108.1,0,0,0,128,20ZM72,65.4A12,12,0,0,0,56,47.5,108.1,108.1,0,0,0,20,128a84.1,84.1,0,0,1-7.6,35,12,12,0,0,0,5.9,15.9,11,11,0,0,0,5,1.1,12,12,0,0,0,10.9-7A107.2,107.2,0,0,0,44,128,84,84,0,0,1,72,65.4Zm43.6,131.7A12,12,0,0,0,99.7,203c-1.9,4.1-4,8.2-6.2,12.2a12,12,0,1,0,21,11.6c2.5-4.5,4.8-9.1,7-13.8A12.1,12.1,0,0,0,115.6,197.1ZM128,116a12,12,0,0,0-12,12,179.1,179.1,0,0,1-5.7,45,12,12,0,0,0,8.6,14.6,10.8,10.8,0,0,0,3,.4,12.1,12.1,0,0,0,11.7-9,207.8,207.8,0,0,0,6.4-51A12,12,0,0,0,128,116Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M184,128a246.5,246.5,0,0,1-18.6,94.2,7.9,7.9,0,0,1-7.4,5,8,8,0,0,1-7.4-11A229.3,229.3,0,0,0,168,128a8,8,0,0,1,16,0ZM128,88a40.4,40.4,0,0,1,29.8,13.3,8,8,0,0,0,11.3.7,8,8,0,0,0,.6-11.3A56,56,0,0,0,72,128a136.4,136.4,0,0,1-17,65.9,8,8,0,0,0,3.1,10.8A8,8,0,0,0,69,201.6,152.2,152.2,0,0,0,88,128,40,40,0,0,1,128,88Zm0-64a104.5,104.5,0,0,0-34.7,5.9,8.1,8.1,0,0,0-4.9,10.2A8,8,0,0,0,98.6,45,88.1,88.1,0,0,1,216,128a281.8,281.8,0,0,1-7,62.2,8.2,8.2,0,0,0,6.1,9.6l1.8.2a7.9,7.9,0,0,0,7.7-6.2A296.3,296.3,0,0,0,232,128,104.2,104.2,0,0,0,128,24ZM69.3,62.4A8,8,0,1,0,58.6,50.5,104.2,104.2,0,0,0,24,128a87.6,87.6,0,0,1-8,36.7,8.1,8.1,0,0,0,3.9,10.6,8.3,8.3,0,0,0,3.4.7,7.8,7.8,0,0,0,7.2-4.7A102.4,102.4,0,0,0,40,128,88.3,88.3,0,0,1,69.3,62.4Zm44.6,138.3a8,8,0,0,0-10.6,4c-1.9,4.2-4.1,8.3-6.3,12.4a8.1,8.1,0,0,0,3.1,10.9,7.8,7.8,0,0,0,3.9,1,8.1,8.1,0,0,0,7-4.1c2.4-4.4,4.7-9,6.8-13.6A8,8,0,0,0,113.9,200.7ZM128,120a8,8,0,0,0-8,8,186.5,186.5,0,0,1-5.8,46,7.9,7.9,0,0,0,5.7,9.7l2,.3a8.1,8.1,0,0,0,7.8-6,200.4,200.4,0,0,0,6.3-50A8,8,0,0,0,128,120Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M184,128a246.4,246.4,0,0,1-18.5,94.2,8.1,8.1,0,0,1-10.5,4.4,7.9,7.9,0,0,1-4.3-10.4A231.1,231.1,0,0,0,168,128a8,8,0,0,1,16,0ZM128,88a40.2,40.2,0,0,1,29.8,13.3,8,8,0,0,0,11.3.7,8,8,0,0,0,.6-11.3A56,56,0,0,0,72,128a136.4,136.4,0,0,1-17,65.9,8,8,0,0,0,3.1,10.8A8,8,0,0,0,69,201.6,152.2,152.2,0,0,0,88,128,40,40,0,0,1,128,88ZM69.3,62.4a7.9,7.9,0,0,0,.6-11.3,7.8,7.8,0,0,0-11.2-.6A104,104,0,0,0,24,128a87.6,87.6,0,0,1-8,36.7,8.1,8.1,0,0,0,3.9,10.6,8.3,8.3,0,0,0,3.4.7,8,8,0,0,0,7.3-4.7A103.8,103.8,0,0,0,40,128,88.3,88.3,0,0,1,69.3,62.4ZM128,24a104.5,104.5,0,0,0-34.7,5.9,8.2,8.2,0,0,0-4.9,10.2A8.1,8.1,0,0,0,98.7,45,88.1,88.1,0,0,1,216,128a275.9,275.9,0,0,1-7,62.2,8.2,8.2,0,0,0,6.1,9.6l1.8.2a8.1,8.1,0,0,0,7.8-6.2A302.3,302.3,0,0,0,232,128,104.2,104.2,0,0,0,128,24ZM113.9,200.7a8,8,0,0,0-10.6,4c-1.9,4.2-4,8.3-6.3,12.4a8,8,0,0,0,14,7.8c2.4-4.4,4.7-9,6.8-13.6A8,8,0,0,0,113.9,200.7ZM128,120a8,8,0,0,0-8,8,186.5,186.5,0,0,1-5.8,46,8,8,0,0,0,5.7,9.7l2,.3a8.1,8.1,0,0,0,7.8-6,200.4,200.4,0,0,0,6.3-50A8,8,0,0,0,128,120Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M182,128a244.7,244.7,0,0,1-18.4,93.5,5.9,5.9,0,0,1-5.5,3.7,4.6,4.6,0,0,1-2.3-.5,6,6,0,0,1-3.3-7.8A232.5,232.5,0,0,0,170,128a6,6,0,0,1,12,0ZM128,86a42.2,42.2,0,0,1,31.3,14,6,6,0,1,0,8.9-8A54,54,0,0,0,74,128a138.7,138.7,0,0,1-17.2,66.8,6,6,0,0,0,2.3,8.2,5.9,5.9,0,0,0,2.9.7,6,6,0,0,0,5.3-3.1A150.6,150.6,0,0,0,86,128,42,42,0,0,1,128,86Zm0-60a102.9,102.9,0,0,0-34,5.8,6,6,0,1,0,4,11.3A90.1,90.1,0,0,1,218,128a286.2,286.2,0,0,1-7,62.7,6.1,6.1,0,0,0,4.5,7.2h1.4a6,6,0,0,0,5.8-4.7A297.8,297.8,0,0,0,230,128,102.2,102.2,0,0,0,128,26ZM68,60.9a6,6,0,0,0,.5-8.5A6.1,6.1,0,0,0,60,52a102,102,0,0,0-34,76,88,88,0,0,1-8.2,37.5,6.2,6.2,0,0,0,3,8,7.4,7.4,0,0,0,2.5.5,5.9,5.9,0,0,0,5.4-3.5A100.5,100.5,0,0,0,38,128,90.1,90.1,0,0,1,68,60.9Zm45.1,141.6a6.2,6.2,0,0,0-8,3c-1.9,4.2-4.1,8.5-6.3,12.6a5.9,5.9,0,0,0,2.3,8.1,5.9,5.9,0,0,0,8.2-2.3c2.4-4.4,4.7-8.9,6.7-13.4A6,6,0,0,0,113.1,202.5ZM128,122a6,6,0,0,0-6,6,185.8,185.8,0,0,1-5.9,46.5,6,6,0,0,0,4.3,7.3l1.5.2a6.1,6.1,0,0,0,5.9-4.5A200.4,200.4,0,0,0,134,128,6,6,0,0,0,128,122Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M36,128a98.2,98.2,0,0,1-9.1,41.7,4,4,0,0,1-3.6,2.3,3.5,3.5,0,0,1-1.7-.4,3.9,3.9,0,0,1-1.9-5.3A91.3,91.3,0,0,0,28,128,100.3,100.3,0,0,1,61.3,53.5a4,4,0,1,1,5.4,5.9A92.3,92.3,0,0,0,36,128Zm140-4a4,4,0,0,0-4,4,235.1,235.1,0,0,1-17.6,89.7,3.9,3.9,0,0,0,2.2,5.2,3.7,3.7,0,0,0,1.5.3,4.1,4.1,0,0,0,3.7-2.5A242.4,242.4,0,0,0,180,128,4,4,0,0,0,176,124ZM160.8,98.7a4,4,0,0,0,5.6.3,4.1,4.1,0,0,0,.4-5.7A52,52,0,0,0,76,128a139.5,139.5,0,0,1-17.5,67.8,4.1,4.1,0,0,0,1.6,5.4,3.4,3.4,0,0,0,1.9.5,3.9,3.9,0,0,0,3.5-2A148.1,148.1,0,0,0,84,128a44,44,0,0,1,76.8-29.3ZM128,28a99.2,99.2,0,0,0-33.3,5.7,4,4,0,0,0-2.5,5.1,4,4,0,0,0,5.1,2.4A94.3,94.3,0,0,1,128,36a92.1,92.1,0,0,1,92,92,289.9,289.9,0,0,1-7,63.1,3.9,3.9,0,0,0,3,4.8h.9a4.1,4.1,0,0,0,3.9-3.1A294.1,294.1,0,0,0,228,128,100.2,100.2,0,0,0,128,28ZM112.3,204.4a4,4,0,0,0-5.4,1.9c-1.9,4.3-4.1,8.6-6.4,12.7a4.1,4.1,0,0,0,1.6,5.5,4.5,4.5,0,0,0,1.9.5,4,4,0,0,0,3.5-2.1c2.4-4.3,4.7-8.8,6.7-13.2A4,4,0,0,0,112.3,204.4ZM128,124a4,4,0,0,0-4,4,190.4,190.4,0,0,1-5.9,47,4,4,0,0,0,2.8,4.9h1a4,4,0,0,0,3.9-3,196.5,196.5,0,0,0,6.2-49A4,4,0,0,0,128,124Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M184,128a246.4,246.4,0,0,1-18.5,94.2,8.1,8.1,0,0,1-7.4,5,8.5,8.5,0,0,1-3.1-.6,8,8,0,0,1-4.3-10.4A231.1,231.1,0,0,0,168,128a8,8,0,0,1,16,0ZM128,88a40.2,40.2,0,0,1,29.8,13.3,8,8,0,0,0,11.3.7,8,8,0,0,0,.6-11.3A56,56,0,0,0,72,128a136.4,136.4,0,0,1-17,65.9,8,8,0,0,0,3.2,10.8,7.5,7.5,0,0,0,3.8,1,8,8,0,0,0,7-4.1A152.2,152.2,0,0,0,88,128,40.1,40.1,0,0,1,128,88Zm0-64a105,105,0,0,0-34.7,5.9,8,8,0,0,0-4.8,10.2A7.9,7.9,0,0,0,98.7,45,88.1,88.1,0,0,1,216,128a281.7,281.7,0,0,1-6.9,62.2,8,8,0,0,0,6,9.6l1.8.2a8.1,8.1,0,0,0,7.8-6.2A302.3,302.3,0,0,0,232,128,104.1,104.1,0,0,0,128,24ZM69.3,62.4A8,8,0,0,0,70,51.1a8,8,0,0,0-11.3-.6A104,104,0,0,0,24,128a87.6,87.6,0,0,1-8,36.7,8.1,8.1,0,0,0,4,10.6,7.8,7.8,0,0,0,3.3.7,8,8,0,0,0,7.3-4.7A103.8,103.8,0,0,0,40,128,88.3,88.3,0,0,1,69.3,62.4Zm44.6,138.3a8,8,0,0,0-10.6,4c-1.9,4.2-4,8.3-6.3,12.4a8,8,0,0,0,7,11.9,8,8,0,0,0,7-4.1c2.5-4.4,4.8-9,6.9-13.6A8.1,8.1,0,0,0,113.9,200.7ZM128,120a8,8,0,0,0-8,8,186.5,186.5,0,0,1-5.8,46,8,8,0,0,0,5.8,9.7,6.4,6.4,0,0,0,2,.3,8,8,0,0,0,7.7-6,200.4,200.4,0,0,0,6.3-50A8,8,0,0,0,128,120Z" />
        </>
      )}
    </svg>
  );
}

export function PhFingerprint(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M160,128a223.6,223.6,0,0,1-26.4,105.5,11.9,11.9,0,0,1-10.6,6.4,12.5,12.5,0,0,1-5.6-1.4,12,12,0,0,1-4.9-16.3A200.2,200.2,0,0,0,136,128a8,8,0,0,0-16,0,12,12,0,0,1-24,0,32,32,0,0,1,64,0ZM128,56a12,12,0,0,0,0,24,48,48,0,0,1,48,48c0,7.6-.4,15.3-1.1,22.9a11.9,11.9,0,0,0,10.8,13h1.2a12,12,0,0,0,11.9-10.9c.8-8.3,1.2-16.7,1.2-25.1A72.1,72.1,0,0,0,128,56ZM96,92.2A12,12,0,0,0,80,74.3,72,72,0,0,0,56,128a120.4,120.4,0,0,1-15.1,58.4,11.9,11.9,0,0,0,4.6,16.3,12.5,12.5,0,0,0,5.8,1.5,11.9,11.9,0,0,0,10.5-6.1A144.2,144.2,0,0,0,80,128,48.1,48.1,0,0,1,96,92.2Zm10.1,64.1a12,12,0,0,0-14.4,8.9,163,163,0,0,1-18.9,45.9,12,12,0,0,0,4,16.5,11.6,11.6,0,0,0,6.2,1.7,12,12,0,0,0,10.3-5.7A184.5,184.5,0,0,0,115,170.8,12,12,0,0,0,106.1,156.3Zm76.7,24.1a11.9,11.9,0,0,0-14.6,8.6,238.8,238.8,0,0,1-8,24.8,11.9,11.9,0,0,0,6.9,15.5,10.9,10.9,0,0,0,4.3.8,11.9,11.9,0,0,0,11.2-7.7,257.1,257.1,0,0,0,8.8-27.4A11.9,11.9,0,0,0,182.8,180.4ZM128,16A112.1,112.1,0,0,0,16,127.4v.6a79.5,79.5,0,0,1-5,27.8,12.2,12.2,0,0,0,7.1,15.5,12,12,0,0,0,15.4-7.1A102.9,102.9,0,0,0,40,128.6V128a88,88,0,0,1,176-.5v.5a286.6,286.6,0,0,1-6.7,61.4,11.9,11.9,0,0,0,9,14.3,12.4,12.4,0,0,0,2.7.3,12,12,0,0,0,11.7-9.4,304.3,304.3,0,0,0,7.3-66.1V128A112.1,112.1,0,0,0,128,16Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M72,128a136.1,136.1,0,0,1-14.1,60.5,8.1,8.1,0,0,1-7.2,4.4,9.4,9.4,0,0,1-3.6-.8,8.1,8.1,0,0,1-3.6-10.7A119,119,0,0,0,56,128,71.7,71.7,0,0,1,83,71.8,8,8,0,1,1,93,84.3,55.9,55.9,0,0,0,72,128Zm56-8a8,8,0,0,0-8,8,183.3,183.3,0,0,1-23,89.1,8.1,8.1,0,0,0,3.2,10.9,7.5,7.5,0,0,0,3.8,1,8,8,0,0,0,7-4.1A199.8,199.8,0,0,0,136,128,8,8,0,0,0,128,120Zm0-32a40.1,40.1,0,0,0-40,40,8,8,0,0,0,16,0,24,24,0,0,1,48,0,214.3,214.3,0,0,1-20.5,92,7.9,7.9,0,0,0,3.8,10.6,7.1,7.1,0,0,0,3.4.8,8.1,8.1,0,0,0,7.3-4.6A230,230,0,0,0,168,128,40,40,0,0,0,128,88Zm0-64A104.2,104.2,0,0,0,24,128a88,88,0,0,1-5,29.3,7.9,7.9,0,0,0,4.9,10.2,8,8,0,0,0,10.2-4.8A104.5,104.5,0,0,0,40,128a88,88,0,0,1,176,0,284.4,284.4,0,0,1-5.3,54.5,8,8,0,0,0,6.3,9.3,4.4,4.4,0,0,0,1.6.2,7.9,7.9,0,0,0,7.8-6.5A298.1,298.1,0,0,0,232,128,104.1,104.1,0,0,0,128,24ZM94.4,152.2a8,8,0,0,0-9.4,6.2,153.2,153.2,0,0,1-17.2,45.4,8.1,8.1,0,0,0,2.9,11,8.2,8.2,0,0,0,4,1,7.9,7.9,0,0,0,6.9-4,164.4,164.4,0,0,0,19.1-50.2A8.1,8.1,0,0,0,94.4,152.2ZM128,56a68,68,0,0,0-9,.6,8,8,0,1,0,2,15.8,54.8,54.8,0,0,1,7-.4,56,56,0,0,1,56,56,245.7,245.7,0,0,1-1.9,31,7.9,7.9,0,0,0,6.9,8.9h1a8.1,8.1,0,0,0,8-7,279.7,279.7,0,0,0,2-33A72.1,72.1,0,0,0,128,56Zm57.9,128.3a7.9,7.9,0,0,0-9.7,5.7c-1.5,5.7-3.2,11.4-5,17a8,8,0,0,0,5,10.1,8,8,0,0,0,10.1-5.1q3-8.9,5.4-18A8,8,0,0,0,185.9,184.3Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M232,128a298.1,298.1,0,0,1-5.6,57.5,7.9,7.9,0,0,1-7.8,6.5,4.4,4.4,0,0,1-1.6-.2,8,8,0,0,1-6.3-9.3A284.4,284.4,0,0,0,216,128a88,88,0,0,0-176,0,104.5,104.5,0,0,1-5.9,34.7,8.1,8.1,0,0,1-7.6,5.3,6.3,6.3,0,0,1-2.6-.5A7.9,7.9,0,0,1,19,157.3,88,88,0,0,0,24,128a104,104,0,0,1,208,0ZM93,84.3A8,8,0,1,0,83,71.8,71.7,71.7,0,0,0,56,128a119,119,0,0,1-12.5,53.4,8.1,8.1,0,0,0,3.6,10.7,9.4,9.4,0,0,0,3.6.8,8.1,8.1,0,0,0,7.2-4.4A136.1,136.1,0,0,0,72,128,55.9,55.9,0,0,1,93,84.3ZM128,120a8,8,0,0,0-8,8,183.3,183.3,0,0,1-23,89.1,8.1,8.1,0,0,0,3.2,10.9,7.5,7.5,0,0,0,3.8,1,8,8,0,0,0,7-4.1A199.8,199.8,0,0,0,136,128,8,8,0,0,0,128,120Zm0-32a40.1,40.1,0,0,0-40,40,8,8,0,0,0,16,0,24,24,0,0,1,48,0,214.3,214.3,0,0,1-20.5,92,7.9,7.9,0,0,0,3.8,10.6,7.1,7.1,0,0,0,3.4.8,8.1,8.1,0,0,0,7.3-4.6A230,230,0,0,0,168,128,40,40,0,0,0,128,88ZM94.4,152.2a7.9,7.9,0,0,0-9.4,6.2,153.2,153.2,0,0,1-17.2,45.4,8.1,8.1,0,0,0,2.9,11,8.2,8.2,0,0,0,4,1,7.9,7.9,0,0,0,6.9-4,164.4,164.4,0,0,0,19.1-50.2A8.1,8.1,0,0,0,94.4,152.2ZM128,56a68,68,0,0,0-9,.6,8,8,0,1,0,2,15.8,54.8,54.8,0,0,1,7-.4,56,56,0,0,1,56,56,245.7,245.7,0,0,1-1.9,31,7.9,7.9,0,0,0,6.9,8.9h1a8.1,8.1,0,0,0,8-7,279.7,279.7,0,0,0,2-33A72.1,72.1,0,0,0,128,56Zm57.9,128.3a7.9,7.9,0,0,0-9.7,5.7c-1.5,5.7-3.2,11.4-5,17a8,8,0,0,0,5,10.1,8,8,0,0,0,10.1-5.1q3-8.9,5.4-18A8,8,0,0,0,185.9,184.3Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M70,128a133.4,133.4,0,0,1-13.9,59.6,6.1,6.1,0,0,1-5.4,3.3,6.6,6.6,0,0,1-2.7-.6,6.1,6.1,0,0,1-2.7-8.1A120.6,120.6,0,0,0,58,128,69.4,69.4,0,0,1,84.3,73.4a5.9,5.9,0,0,1,8.4.9,6,6,0,0,1-.9,8.4A57.6,57.6,0,0,0,70,128Zm58-6a6,6,0,0,0-6,6,186.2,186.2,0,0,1-23.2,90.1,5.9,5.9,0,0,0,2.3,8.1,5.9,5.9,0,0,0,8.2-2.3A197.9,197.9,0,0,0,134,128,6,6,0,0,0,128,122Zm0-32a38,38,0,0,0-38,38,6,6,0,0,0,12,0,26,26,0,0,1,52,0,216.2,216.2,0,0,1-20.7,92.8,6,6,0,0,0,2.9,8,5.7,5.7,0,0,0,2.5.6,6.2,6.2,0,0,0,5.5-3.4A228.1,228.1,0,0,0,166,128,38,38,0,0,0,128,90Zm0-64A102.2,102.2,0,0,0,26,128a89.6,89.6,0,0,1-5.1,30,6,6,0,1,0,11.3,4A100.4,100.4,0,0,0,38,128a90,90,0,0,1,180,0,286,286,0,0,1-5.3,54.8,6,6,0,0,0,4.7,7.1h1.2a6.1,6.1,0,0,0,5.9-4.8A303.8,303.8,0,0,0,230,128,102.1,102.1,0,0,0,128,26ZM94,154.1a6.2,6.2,0,0,0-7.1,4.7,151.7,151.7,0,0,1-17.4,46,5.9,5.9,0,0,0,2.2,8.2,5.4,5.4,0,0,0,3,.8,5.8,5.8,0,0,0,5.2-3,165.6,165.6,0,0,0,18.8-49.6A6,6,0,0,0,94,154.1ZM128,58a83,83,0,0,0-8.7.5,6,6,0,0,0,1.5,12A56.8,56.8,0,0,1,128,70a58,58,0,0,1,58,58,250.5,250.5,0,0,1-1.9,31.3,6.1,6.1,0,0,0,5.2,6.7h.7a6,6,0,0,0,6-5.3,273.4,273.4,0,0,0,2-32.7A70.1,70.1,0,0,0,128,58Zm57.4,128.2a6.1,6.1,0,0,0-7.3,4.3c-1.4,5.7-3.1,11.5-5,17.1a6,6,0,0,0,3.8,7.6,5.8,5.8,0,0,0,1.9.3,6.1,6.1,0,0,0,5.7-4.1c1.9-5.9,3.7-11.9,5.3-17.9A6,6,0,0,0,185.4,186.2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M68,128a130.8,130.8,0,0,1-13.7,58.7,4,4,0,0,1-3.6,2.2,3.9,3.9,0,0,1-1.8-.4,4,4,0,0,1-1.8-5.4A121.9,121.9,0,0,0,60,128,67.8,67.8,0,0,1,85.5,74.9a4,4,0,0,1,5.6.6,4.1,4.1,0,0,1-.6,5.7A59.6,59.6,0,0,0,68,128Zm60-4a4,4,0,0,0-4,4,187.2,187.2,0,0,1-23.5,91,4.2,4.2,0,0,0,1.6,5.5,4.5,4.5,0,0,0,1.9.5,4,4,0,0,0,3.5-2.1A196.4,196.4,0,0,0,132,128,4,4,0,0,0,128,124Zm0-32a36.1,36.1,0,0,0-36,36,4,4,0,0,0,8,0,28,28,0,0,1,56,0,217.4,217.4,0,0,1-20.9,93.7A4,4,0,0,0,137,227a3.8,3.8,0,0,0,1.7.4,4,4,0,0,0,3.7-2.3A226.8,226.8,0,0,0,164,128,36,36,0,0,0,128,92Zm0-64A100.2,100.2,0,0,0,28,128a91.7,91.7,0,0,1-5.2,30.7,3.9,3.9,0,0,0,2.4,5.1,4,4,0,0,0,5.1-2.5A99.2,99.2,0,0,0,36,128a92,92,0,0,1,184,0,282.3,282.3,0,0,1-5.4,55.2,4,4,0,0,0,3.2,4.7h.8a4,4,0,0,0,3.9-3.2A291.6,291.6,0,0,0,228,128,100.1,100.1,0,0,0,128,28ZM93.6,156.1a3.9,3.9,0,0,0-4.7,3.1,153.2,153.2,0,0,1-17.7,46.6,4,4,0,0,0,1.5,5.5,3.5,3.5,0,0,0,2,.5,4.1,4.1,0,0,0,3.5-2,161.5,161.5,0,0,0,18.5-49A3.9,3.9,0,0,0,93.6,156.1ZM128,60a80.8,80.8,0,0,0-8.5.5A4.1,4.1,0,0,0,116,65a4,4,0,0,0,4.5,3.5A63,63,0,0,1,128,68a60,60,0,0,1,60,60,254.9,254.9,0,0,1-1.9,31.5,4,4,0,0,0,3.4,4.5h.5a4.1,4.1,0,0,0,4-3.5,270.1,270.1,0,0,0,2-32.5A68.1,68.1,0,0,0,128,60Zm56.9,128.1a4,4,0,0,0-4.8,2.9c-1.5,5.8-3.2,11.6-5.1,17.2a4,4,0,0,0,2.5,5.1l1.3.2a4.2,4.2,0,0,0,3.8-2.7c1.9-5.9,3.7-11.8,5.2-17.8A4,4,0,0,0,184.9,188.1Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M72,128a136.1,136.1,0,0,1-14.1,60.5,8.1,8.1,0,0,1-7.2,4.4,9.4,9.4,0,0,1-3.6-.8,8.1,8.1,0,0,1-3.6-10.7A119,119,0,0,0,56,128,71.7,71.7,0,0,1,83,71.8,8,8,0,1,1,93,84.3,55.9,55.9,0,0,0,72,128Zm56-8a8,8,0,0,0-8,8,183.3,183.3,0,0,1-23,89.1,8.1,8.1,0,0,0,3.2,10.9,7.5,7.5,0,0,0,3.8,1,8,8,0,0,0,7-4.1A199.8,199.8,0,0,0,136,128,8,8,0,0,0,128,120Zm0-32a40.1,40.1,0,0,0-40,40,8,8,0,0,0,16,0,24,24,0,0,1,48,0,214.3,214.3,0,0,1-20.5,92,7.9,7.9,0,0,0,3.8,10.6,7.1,7.1,0,0,0,3.4.8,8.1,8.1,0,0,0,7.3-4.6A230,230,0,0,0,168,128,40,40,0,0,0,128,88Zm0-64A104.2,104.2,0,0,0,24,128a88,88,0,0,1-5,29.3,7.9,7.9,0,0,0,4.9,10.2,8,8,0,0,0,10.2-4.8A104.5,104.5,0,0,0,40,128a88,88,0,0,1,176,0,284.4,284.4,0,0,1-5.3,54.5,8,8,0,0,0,6.3,9.3,4.4,4.4,0,0,0,1.6.2,7.9,7.9,0,0,0,7.8-6.5A298.1,298.1,0,0,0,232,128,104.1,104.1,0,0,0,128,24ZM94.4,152.2a8,8,0,0,0-9.4,6.2,153.2,153.2,0,0,1-17.2,45.4,8.1,8.1,0,0,0,2.9,11,8.2,8.2,0,0,0,4,1,7.9,7.9,0,0,0,6.9-4,164.4,164.4,0,0,0,19.1-50.2A8.1,8.1,0,0,0,94.4,152.2ZM128,56a68,68,0,0,0-9,.6,8,8,0,1,0,2,15.8,54.8,54.8,0,0,1,7-.4,56,56,0,0,1,56,56,245.7,245.7,0,0,1-1.9,31,7.9,7.9,0,0,0,6.9,8.9h1a8.1,8.1,0,0,0,8-7,279.7,279.7,0,0,0,2-33A72.1,72.1,0,0,0,128,56Zm57.9,128.3a7.9,7.9,0,0,0-9.7,5.7c-1.5,5.7-3.2,11.4-5,17a8,8,0,0,0,5,10.1,8,8,0,0,0,10.1-5.1q3-8.9,5.4-18A8,8,0,0,0,185.9,184.3Z" />
        </>
      )}
    </svg>
  );
}

export function PhFinnTheHuman(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M168,88H88a44,44,0,0,0-44,44v16a44,44,0,0,0,44,44h80a44,44,0,0,0,44-44V132A44,44,0,0,0,168,88Zm20,60a20.1,20.1,0,0,1-20,20H88a20.1,20.1,0,0,1-20-20V132a20.1,20.1,0,0,1,20-20h80a20.1,20.1,0,0,1,20,20Zm-12-8a16,16,0,1,1-16-16A16,16,0,0,1,176,140Zm-64,0a16,16,0,1,1-16-16A16,16,0,0,1,112,140ZM212,28a36,36,0,0,0-33.9,24H77.9A36,36,0,0,0,8,64v88a76.1,76.1,0,0,0,76,76h88a76.1,76.1,0,0,0,76-76V64A36,36,0,0,0,212,28Zm12,124a52,52,0,0,1-52,52H84a52,52,0,0,1-52-52V64a12,12,0,0,1,24,0A12,12,0,0,0,68,76H188a12,12,0,0,0,12-12,12,12,0,0,1,24,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M208,40a23.9,23.9,0,0,0-24,24H72a24,24,0,0,0-48,0v80a64.1,64.1,0,0,0,64,64h80a64.1,64.1,0,0,0,64-64V64A23.9,23.9,0,0,0,208,40Zm-8,104a32,32,0,0,1-32,32H88a32,32,0,0,1-32-32v-8a32,32,0,0,1,32-32h80a32,32,0,0,1,32,32Z" />
          </g>
          <path d="M168,96H88a40,40,0,0,0-40,40v8a40,40,0,0,0,40,40h80a40,40,0,0,0,40-40v-8A40,40,0,0,0,168,96Zm24,48a24.1,24.1,0,0,1-24,24H88a24.1,24.1,0,0,1-24-24v-8a24.1,24.1,0,0,1,24-24h80a24.1,24.1,0,0,1,24,24Zm-16-4a12,12,0,1,1-12-12A12,12,0,0,1,176,140Zm-72,0a12,12,0,1,1-12-12A12,12,0,0,1,104,140ZM208,32a32.1,32.1,0,0,0-31,24H79a32,32,0,0,0-63,8v80a72.1,72.1,0,0,0,72,72h80a72.1,72.1,0,0,0,72-72V64A32.1,32.1,0,0,0,208,32Zm16,112a56,56,0,0,1-56,56H88a56,56,0,0,1-56-56V64a16,16,0,0,1,32,0,8,8,0,0,0,8,8H184a8,8,0,0,0,8-8,16,16,0,0,1,32,0Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M104,140a12,12,0,1,1-12-12A12,12,0,0,1,104,140Zm60-12a12,12,0,1,0,12,12A12,12,0,0,0,164,128Zm76-64v80a72.1,72.1,0,0,1-72,72H88a72.1,72.1,0,0,1-72-72V64a32,32,0,0,1,63-8h98a32,32,0,0,1,63,8Zm-40,72a32,32,0,0,0-32-32H88a32,32,0,0,0-32,32v8a32,32,0,0,0,32,32h80a32,32,0,0,0,32-32Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M168,98H88a38,38,0,0,0-38,38v8a38,38,0,0,0,38,38h80a38,38,0,0,0,38-38v-8A38,38,0,0,0,168,98Zm26,46a26.1,26.1,0,0,1-26,26H88a26.1,26.1,0,0,1-26-26v-8a26.1,26.1,0,0,1,26-26h80a26.1,26.1,0,0,1,26,26Zm-20-4a10,10,0,1,1-10-10A10,10,0,0,1,174,140Zm-72,0a10,10,0,1,1-10-10A10,10,0,0,1,102,140ZM208,34a30,30,0,0,0-29.4,24H77.4A30,30,0,0,0,18,64v80a70.1,70.1,0,0,0,70,70h80a70.1,70.1,0,0,0,70-70V64A30.1,30.1,0,0,0,208,34Zm18,110a58,58,0,0,1-58,58H88a58,58,0,0,1-58-58V64a18,18,0,0,1,36,0,6,6,0,0,0,6,6H184a6,6,0,0,0,6-6,18,18,0,0,1,36,0Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M168,100H88a36,36,0,0,0-36,36v8a36,36,0,0,0,36,36h80a36,36,0,0,0,36-36v-8A36,36,0,0,0,168,100Zm28,44a28.1,28.1,0,0,1-28,28H88a28.1,28.1,0,0,1-28-28v-8a28.1,28.1,0,0,1,28-28h80a28.1,28.1,0,0,1,28,28Zm-24-4a8,8,0,1,1-8-8A8,8,0,0,1,172,140Zm-72,0a8,8,0,1,1-8-8A8,8,0,0,1,100,140ZM208,36a27.9,27.9,0,0,0-27.7,24H75.7A28,28,0,0,0,20,64v80a68.1,68.1,0,0,0,68,68h80a68.1,68.1,0,0,0,68-68V64A28.1,28.1,0,0,0,208,36Zm20,108a60,60,0,0,1-60,60H88a60,60,0,0,1-60-60V64a20,20,0,0,1,40,0,4,4,0,0,0,4,4H184a4,4,0,0,0,4-4,20,20,0,0,1,40,0Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M168,96H88a40,40,0,0,0-40,40v8a40,40,0,0,0,40,40h80a40,40,0,0,0,40-40v-8A40,40,0,0,0,168,96Zm24,48a24.1,24.1,0,0,1-24,24H88a24.1,24.1,0,0,1-24-24v-8a24.1,24.1,0,0,1,24-24h80a24.1,24.1,0,0,1,24,24Zm-16-4a12,12,0,1,1-12-12A12,12,0,0,1,176,140Zm-72,0a12,12,0,1,1-12-12A12,12,0,0,1,104,140ZM208,32a32.1,32.1,0,0,0-31,24H79a32,32,0,0,0-63,8v80a72.1,72.1,0,0,0,72,72h80a72.1,72.1,0,0,0,72-72V64A32.1,32.1,0,0,0,208,32Zm16,112a56,56,0,0,1-56,56H88a56,56,0,0,1-56-56V64a16,16,0,0,1,32,0,8,8,0,0,0,8,8H184a8,8,0,0,0,8-8,16,16,0,0,1,32,0Z" />
        </>
      )}
    </svg>
  );
}

export function PhFireSimple(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M200.5,64.5c-13.3-21.2-30.3-39.2-45.2-54.1A11.9,11.9,0,0,0,136,13.7L107.1,74.3,78.7,55.4A12.1,12.1,0,0,0,62,58.7C42.1,88.8,32,116.1,32,140a96,96,0,0,0,192,0C224,114.3,216.3,89.6,200.5,64.5ZM128,212a72.1,72.1,0,0,1-72-72c0-16.6,6.6-36,19.6-57.8L105.3,102a12,12,0,0,0,17.5-4.8l27.4-57.6C177.3,68.5,200,100.7,200,140A72.1,72.1,0,0,1,128,212Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M212,140a84,84,0,0,1-168,0c0-24.8,12.6-51.4,28-74.6h0L112,92l34.8-73.1h0C176,48,212,88,212,140Z"
            opacity="0.2"
          />
          <path d="M197.1,66.6c-13-20.8-29.9-38.7-44.6-53.4a8,8,0,0,0-7-2.2,7.9,7.9,0,0,0-5.9,4.4L108.7,80.2,76.5,58.7a8.1,8.1,0,0,0-6-1.2,8,8,0,0,0-5.1,3.4C45.9,90.3,36,116.9,36,140a92,92,0,0,0,184,0C220,115.1,212.5,91.1,197.1,66.6ZM128,216a76.1,76.1,0,0,1-76-76c0-18.2,7.5-39.5,22.4-63.5l33.2,22.2a8.2,8.2,0,0,0,6.5,1,7.8,7.8,0,0,0,5.1-4.3l29.9-62.8C178.6,63.2,204,97.4,204,140A76.1,76.1,0,0,1,128,216Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M220,140a92,92,0,0,1-184,0c0-23.1,9.9-49.7,29.4-79.1a8,8,0,0,1,5.1-3.4,8.1,8.1,0,0,1,6,1.2l32.2,21.5,30.9-64.8a7.9,7.9,0,0,1,5.9-4.4,8,8,0,0,1,7,2.2c14.7,14.7,31.6,32.6,44.6,53.4C212.5,91.1,220,115.1,220,140Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M151.1,14.6a6.1,6.1,0,0,0-9.7,1.7L109.6,83.2,75.4,60.4A6.2,6.2,0,0,0,67,62c-19.2,29.1-29,55.3-29,78a90,90,0,0,0,180,0C218,88.5,185.8,49.3,151.1,14.6ZM128,218a78.1,78.1,0,0,1-78-78c0-19,8-41.3,23.8-66.3L108.7,97a6,6,0,0,0,4.9.8,5.8,5.8,0,0,0,3.8-3.2l31.2-65.4C179.1,60.5,206,95.8,206,140A78.1,78.1,0,0,1,128,218Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M149.6,16a4.1,4.1,0,0,0-3.5-1.1,4.4,4.4,0,0,0-2.9,2.3L110.4,86.1,74.3,62a4.2,4.2,0,0,0-3.1-.6,4.4,4.4,0,0,0-2.5,1.7C49.7,91.9,40,117.7,40,140a88,88,0,0,0,176,0C216,89.3,184.1,50.4,149.6,16ZM128,220a80.1,80.1,0,0,1-80-80c0-19.8,8.5-43.1,25.2-69.1l36.6,24.4a3.8,3.8,0,0,0,3.3.6,4.2,4.2,0,0,0,2.5-2.2l32.4-68c31.7,32.2,60,68.4,60,114.3A80.1,80.1,0,0,1,128,220Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M197.1,66.6c-13-20.8-29.9-38.7-44.6-53.4a8,8,0,0,0-7-2.2,7.9,7.9,0,0,0-5.9,4.4L108.7,80.2,76.5,58.7a8.1,8.1,0,0,0-6-1.2,8,8,0,0,0-5.1,3.4C45.9,90.3,36,116.9,36,140a92,92,0,0,0,184,0C220,115.1,212.5,91.1,197.1,66.6ZM128,216a76.1,76.1,0,0,1-76-76c0-18.2,7.5-39.5,22.4-63.5l33.2,22.2a8.2,8.2,0,0,0,6.5,1,7.8,7.8,0,0,0,5.1-4.3l29.9-62.8C178.6,63.2,204,97.4,204,140A76.1,76.1,0,0,1,128,216Z" />
        </>
      )}
    </svg>
  );
}

export function PhFire(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M159.9,142.9a12,12,0,1,1,23.9,2.2,56.2,56.2,0,0,1-45.6,50l-2.2.2a12.1,12.1,0,0,1-11.8-9.9,11.9,11.9,0,0,1,9.6-13.9A32.1,32.1,0,0,0,159.9,142.9ZM224,140a96,96,0,0,1-192,0c0-23.9,10.1-51.2,30-81.3a12.1,12.1,0,0,1,16.7-3.3l28.4,18.9L136,13.7a11.9,11.9,0,0,1,19.3-3.3c14.9,14.9,31.9,32.9,45.2,54.1C216.3,89.6,224,114.3,224,140Zm-24,0c0-39.3-22.7-71.5-49.8-100.4L122.8,97.2a12,12,0,0,1-17.5,4.8L75.6,82.2C62.6,104,56,123.4,56,140a72,72,0,0,0,144,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M212,140a84,84,0,0,1-168,0c0-24.8,12.6-51.4,28-74.6h0L112,92l34.8-73.1h0C176,48,212,88,212,140Z"
            opacity="0.2"
          />
          <path d="M187.3,149.2a59.6,59.6,0,0,1-50.1,50.1H136a8,8,0,0,1-1.2-15.9,44.4,44.4,0,0,0,36.7-36.7,8,8,0,1,1,15.8,2.4ZM220,140a92,92,0,0,1-184,0c0-23.1,9.9-49.7,29.4-79.1a8,8,0,0,1,5.1-3.4,8.1,8.1,0,0,1,6,1.2l32.2,21.5,30.9-64.8a7.9,7.9,0,0,1,5.9-4.4,8,8,0,0,1,7,2.2c14.7,14.7,31.6,32.6,44.6,53.4C212.5,91.1,220,115.1,220,140Zm-16,0c0-42.6-25.4-76.8-54.9-107.4L119.2,95.4a7.8,7.8,0,0,1-5.1,4.3,8.2,8.2,0,0,1-6.5-1L74.4,76.5C59.5,100.5,52,121.8,52,140a76,76,0,0,0,152,0Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M197.1,66.6c-13-20.8-29.9-38.7-44.6-53.4a8,8,0,0,0-7-2.2,7.9,7.9,0,0,0-5.9,4.4L108.7,80.2,76.5,58.7a8.1,8.1,0,0,0-6-1.2,8,8,0,0,0-5.1,3.4C45.9,90.3,36,116.9,36,140a92,92,0,0,0,184,0C220,115.1,212.5,91.1,197.1,66.6Zm-9.8,82.6a59.6,59.6,0,0,1-50.1,50.1H136a8,8,0,0,1-1.2-15.9,44.4,44.4,0,0,0,36.7-36.7,8,8,0,1,1,15.8,2.4Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M185.3,148.9a57.7,57.7,0,0,1-48.4,48.4H136a6,6,0,0,1-.9-11.9,46.5,46.5,0,0,0,38.4-38.4,6,6,0,1,1,11.8,1.8ZM218,140a90,90,0,0,1-180,0c0-22.7,9.8-48.9,29-78a6.2,6.2,0,0,1,8.4-1.6l34.2,22.8,31.8-66.9a6.1,6.1,0,0,1,9.7-1.7C185.8,49.3,218,88.5,218,140Zm-12,0c0-44.2-26.9-79.5-57.4-110.8L117.4,94.6a5.8,5.8,0,0,1-3.8,3.2,6,6,0,0,1-4.9-.8L73.8,73.7C58,98.7,50,121,50,140a78,78,0,0,0,156,0Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M183.3,148.6a55.6,55.6,0,0,1-46.7,46.7H136a4,4,0,0,1-.6-8,47.7,47.7,0,0,0,40-40A4.1,4.1,0,0,1,180,144,4,4,0,0,1,183.3,148.6ZM216,140a88,88,0,0,1-176,0c0-22.3,9.7-48.1,28.7-76.9a4.4,4.4,0,0,1,2.5-1.7,4.2,4.2,0,0,1,3.1.6l36.1,24.1,32.8-68.9a4.4,4.4,0,0,1,2.9-2.3,4.1,4.1,0,0,1,3.5,1.1C184.1,50.4,216,89.3,216,140Zm-8,0c0-45.9-28.3-82.1-60-114.3l-32.4,68a4.2,4.2,0,0,1-2.5,2.2,3.8,3.8,0,0,1-3.3-.6L73.2,70.9C56.5,96.9,48,120.2,48,140a80,80,0,0,0,160,0Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M187.3,149.2a59.6,59.6,0,0,1-50.1,50.1H136a8,8,0,0,1-1.2-15.9,44.4,44.4,0,0,0,36.7-36.7,8,8,0,1,1,15.8,2.4ZM220,140a92,92,0,0,1-184,0c0-23.1,9.9-49.7,29.4-79.1a8,8,0,0,1,5.1-3.4,8.1,8.1,0,0,1,6,1.2l32.2,21.5,30.9-64.8a7.9,7.9,0,0,1,5.9-4.4,8,8,0,0,1,7,2.2c14.7,14.7,31.6,32.6,44.6,53.4C212.5,91.1,220,115.1,220,140Zm-16,0c0-42.6-25.4-76.8-54.9-107.4L119.2,95.4a7.8,7.8,0,0,1-5.1,4.3,8.2,8.2,0,0,1-6.5-1L74.4,76.5C59.5,100.5,52,121.8,52,140a76,76,0,0,0,152,0Z" />
        </>
      )}
    </svg>
  );
}

export function PhFirstAidKit(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M168,144a12,12,0,0,1-12,12H140v16a12,12,0,0,1-24,0V156H100a12,12,0,0,1,0-24h16V116a12,12,0,0,1,24,0v16h16A12,12,0,0,1,168,144Zm68-64V208a20.1,20.1,0,0,1-20,20H40a20.1,20.1,0,0,1-20-20V80A20.1,20.1,0,0,1,40,60H76V52a28.1,28.1,0,0,1,28-28h48a28.1,28.1,0,0,1,28,28v8h36A20.1,20.1,0,0,1,236,80ZM100,60h56V52a4,4,0,0,0-4-4H104a4,4,0,0,0-4,4ZM212,84H44V204H212Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect x="32" y="72" width="192" height="144" rx="8" />
          </g>
          <path d="M156,136H136V116a8,8,0,0,0-16,0v20H100a8,8,0,0,0,0,16h20v20a8,8,0,0,0,16,0V152h20a8,8,0,0,0,0-16Z" />
          <path d="M216,64H176V56a24.1,24.1,0,0,0-24-24H104A24.1,24.1,0,0,0,80,56v8H40A16,16,0,0,0,24,80V208a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V80A16,16,0,0,0,216,64ZM96,56a8,8,0,0,1,8-8h48a8,8,0,0,1,8,8v8H96ZM216,208H40V80H216V208Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,64H176V56a24.1,24.1,0,0,0-24-24H104A24.1,24.1,0,0,0,80,56v8H40A16,16,0,0,0,24,80V208a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V80A16,16,0,0,0,216,64ZM96,56a8,8,0,0,1,8-8h48a8,8,0,0,1,8,8v8H96Zm60,96H136v20a8,8,0,0,1-16,0V152H100a8,8,0,0,1,0-16h20V116a8,8,0,0,1,16,0v20h20a8,8,0,0,1,0,16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M162,144a6,6,0,0,1-6,6H134v22a6,6,0,0,1-12,0V150H100a6,6,0,0,1,0-12h22V116a6,6,0,0,1,12,0v22h22A6,6,0,0,1,162,144Zm68-64V208a14,14,0,0,1-14,14H40a14,14,0,0,1-14-14V80A14,14,0,0,1,40,66H82V56a22.1,22.1,0,0,1,22-22h48a22.1,22.1,0,0,1,22,22V66h42A14,14,0,0,1,230,80ZM94,66h68V56a10,10,0,0,0-10-10H104A10,10,0,0,0,94,56ZM218,80a2,2,0,0,0-2-2H40a2,2,0,0,0-2,2V208a2,2,0,0,0,2,2H216a2,2,0,0,0,2-2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M160,144a4,4,0,0,1-4,4H132v24a4,4,0,0,1-8,0V148H100a4,4,0,0,1,0-8h24V116a4,4,0,0,1,8,0v24h24A4,4,0,0,1,160,144Zm68-64V208a12,12,0,0,1-12,12H40a12,12,0,0,1-12-12V80A12,12,0,0,1,40,68H84V56a20.1,20.1,0,0,1,20-20h48a20.1,20.1,0,0,1,20,20V68h44A12,12,0,0,1,228,80ZM92,68h72V56a12,12,0,0,0-12-12H104A12,12,0,0,0,92,56ZM220,80a4,4,0,0,0-4-4H40a4,4,0,0,0-4,4V208a4,4,0,0,0,4,4H216a4,4,0,0,0,4-4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,64H176V56a24.1,24.1,0,0,0-24-24H104A24.1,24.1,0,0,0,80,56v8H40A16,16,0,0,0,24,80V208a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V80A16,16,0,0,0,216,64ZM96,56a8,8,0,0,1,8-8h48a8,8,0,0,1,8,8v8H96ZM216,208H40V80H216V208Zm-52-64a8,8,0,0,1-8,8H136v20a8,8,0,0,1-16,0V152H100a8,8,0,0,1,0-16h20V116a8,8,0,0,1,16,0v20h20A8,8,0,0,1,164,144Z" />
        </>
      )}
    </svg>
  );
}

export function PhFirstAid(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216,84H172V40a20.1,20.1,0,0,0-20-20H104A20.1,20.1,0,0,0,84,40V84H40a20.1,20.1,0,0,0-20,20v48a20.1,20.1,0,0,0,20,20H84v44a20.1,20.1,0,0,0,20,20h48a20.1,20.1,0,0,0,20-20V172h44a20.1,20.1,0,0,0,20-20V104A20.1,20.1,0,0,0,216,84Zm-4,64H160a12,12,0,0,0-12,12v52H108V160a12,12,0,0,0-12-12H44V108H96a12,12,0,0,0,12-12V44h40V96a12,12,0,0,0,12,12h52Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M96,160H40a8,8,0,0,1-8-8V104a8,8,0,0,1,8-8H96V40a8,8,0,0,1,8-8h48a8,8,0,0,1,8,8V96h56a8,8,0,0,1,8,8v48a8,8,0,0,1-8,8H160v56a8,8,0,0,1-8,8H104a8,8,0,0,1-8-8V160Z" />
          </g>
          <path d="M216,88H168V40a16,16,0,0,0-16-16H104A16,16,0,0,0,88,40V88H40a16,16,0,0,0-16,16v48a16,16,0,0,0,16,16H88v48a16,16,0,0,0,16,16h48a16,16,0,0,0,16-16V168h48a16,16,0,0,0,16-16V104A16,16,0,0,0,216,88Zm0,64H160a8,8,0,0,0-8,8v56H104V160a8,8,0,0,0-8-8H40V104H96a8,8,0,0,0,8-8V40h48V96a8,8,0,0,0,8,8h56Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M232,104v48a16,16,0,0,1-16,16H168v48a16,16,0,0,1-16,16H104a16,16,0,0,1-16-16V168H40a16,16,0,0,1-16-16V104A16,16,0,0,1,40,88H88V40a16,16,0,0,1,16-16h48a16,16,0,0,1,16,16V88h48A16,16,0,0,1,232,104Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M216,90H166V40a14,14,0,0,0-14-14H104A14,14,0,0,0,90,40V90H40a14,14,0,0,0-14,14v48a14,14,0,0,0,14,14H90v50a14,14,0,0,0,14,14h48a14,14,0,0,0,14-14V166h50a14,14,0,0,0,14-14V104A14,14,0,0,0,216,90Zm2,62a2,2,0,0,1-2,2H160a6,6,0,0,0-6,6v56a2,2,0,0,1-2,2H104a2,2,0,0,1-2-2V160a6,6,0,0,0-6-6H40a2,2,0,0,1-2-2V104a2,2,0,0,1,2-2H96a6,6,0,0,0,6-6V40a2,2,0,0,1,2-2h48a2,2,0,0,1,2,2V96a6,6,0,0,0,6,6h56a2,2,0,0,1,2,2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M216,92H164V40a12,12,0,0,0-12-12H104A12,12,0,0,0,92,40V92H40a12,12,0,0,0-12,12v48a12,12,0,0,0,12,12H92v52a12,12,0,0,0,12,12h48a12,12,0,0,0,12-12V164h52a12,12,0,0,0,12-12V104A12,12,0,0,0,216,92Zm4,60a4,4,0,0,1-4,4H160a4,4,0,0,0-4,4v56a4,4,0,0,1-4,4H104a4,4,0,0,1-4-4V160a4,4,0,0,0-4-4H40a4,4,0,0,1-4-4V104a4,4,0,0,1,4-4H96a4,4,0,0,0,4-4V40a4,4,0,0,1,4-4h48a4,4,0,0,1,4,4V96a4,4,0,0,0,4,4h56a4,4,0,0,1,4,4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,88H168V40a16,16,0,0,0-16-16H104A16,16,0,0,0,88,40V88H40a16,16,0,0,0-16,16v48a16,16,0,0,0,16,16H88v48a16,16,0,0,0,16,16h48a16,16,0,0,0,16-16V168h48a16,16,0,0,0,16-16V104A16,16,0,0,0,216,88Zm0,64H160a8,8,0,0,0-8,8v56H104V160a8,8,0,0,0-8-8H40V104H96a8,8,0,0,0,8-8V40h48V96a8,8,0,0,0,8,8h56Z" />
        </>
      )}
    </svg>
  );
}

export function PhFishSimple(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M140,76a16,16,0,1,1,16,16A16,16,0,0,1,140,76Zm61,94.8c-26.5,26.5-67.8,38-123.2,34.4,1.5,10.3,3.5,21,5.9,32.2a12,12,0,0,1-9.1,14.3L72,252a12,12,0,0,1-11.7-9.4c-3.1-13.9-5.4-27.2-7.1-39.8-12.6-1.7-25.9-4.1-39.8-7.1a12,12,0,0,1,5.2-23.5c11.1,2.5,21.9,4.5,32.2,6C47.2,122.8,58.7,81.5,85.2,55c42.4-42.5,109-32.1,128.4-27.9a20,20,0,0,1,15.3,15.3C233.1,61.8,243.5,128.4,201,170.8ZM116.2,60.9A83.8,83.8,0,0,0,195,139.8c7.6-12.3,12.2-27.3,13.7-44.8A168.4,168.4,0,0,0,206,50C189.5,46.8,148,41.4,116.2,60.9Zm12.2,118.6c18.9-3.1,34.7-9.2,47-18.3A108,108,0,0,1,94.8,80.6c-9.1,12.3-15.3,28.1-18.3,47-2.6,15.6-3.1,33.5-1.5,53.4C94.9,182.5,112.7,182.1,128.4,179.5Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M201.4,152c-20.6,28.1-60.8,48.3-137.4,40h0C55.7,115.4,75.9,75.2,104,54.6h0A96,96,0,0,0,201.3,152Z"
            opacity="0.2"
          />
          <path d="M168,76a12,12,0,1,1-12-12A12,12,0,0,1,168,76Zm30.2,92c-26.5,26.4-68.4,37.4-125.1,32.9,1.6,11.8,3.8,24.3,6.7,37.4a7.9,7.9,0,0,1-6.1,9.5L72,248a8,8,0,0,1-7.8-6.3c-3.3-14.9-5.7-29.1-7.4-42.5-13.4-1.7-27.6-4.2-42.5-7.4a8,8,0,0,1,3.4-15.7c13.1,2.9,25.6,5.2,37.4,6.8C50.6,126.2,61.6,84.3,88,57.8c41-41,105.8-30.9,124.7-26.8A15.9,15.9,0,0,1,225,43.3C229.1,62.2,239.2,127,198.2,168Zm-13.7-9.1a103,103,0,0,1-58-29.4A103.6,103.6,0,0,1,97.1,71.4C75.3,95.2,66.6,133.1,71.3,184.7,122.8,189.4,160.8,180.7,184.5,158.9ZM209.4,46.7c-15-3.3-62.3-10.9-97.3,12.1a87.9,87.9,0,0,0,85.1,85.1C220.2,108.9,212.6,61.6,209.4,46.7Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M225,43.3A15.9,15.9,0,0,0,212.7,31C193.8,26.9,129,16.8,88,57.8,61.6,84.3,50.6,126.2,55.1,182.9c-11.8-1.6-24.3-3.9-37.4-6.8a8,8,0,0,0-3.4,15.7c14.9,3.2,29.1,5.7,42.5,7.4,1.7,13.4,4.1,27.6,7.4,42.5A8,8,0,0,0,72,248l1.7-.2a7.9,7.9,0,0,0,6.1-9.5c-2.9-13.1-5.1-25.6-6.7-37.4,56.7,4.5,98.6-6.5,125.1-32.9C239.2,127,229.1,62.2,225,43.3ZM197.2,143.9a87.9,87.9,0,0,1-85.1-85.1c35-23,82.3-15.4,97.3-12.1C212.6,61.6,220.2,108.9,197.2,143.9ZM168,76a12,12,0,1,1-12-12A12,12,0,0,1,168,76Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M166,76a10,10,0,1,1-10-10A10,10,0,0,1,166,76Zm30.8,90.6c-26.5,26.3-68.7,37.1-126,32.1,1.6,12.6,4,25.9,7.1,40a6.1,6.1,0,0,1-4.6,7.2H72a6.1,6.1,0,0,1-5.9-4.7c-3.3-15.5-5.9-30.1-7.5-43.9-13.8-1.7-28.5-4.2-43.9-7.6a6,6,0,1,1,2.6-11.7c14,3.1,27.4,5.4,40,7.1-5-57.3,5.8-99.5,32.1-126A93.8,93.8,0,0,1,100.2,50l.6-.4c40-29,94.5-20.3,111.5-16.7a14.2,14.2,0,0,1,10.8,10.8C227.1,62.3,237.1,126.3,196.8,166.6Zm-8.5-8.5.6-.7a101,101,0,0,1-61-29.3,101,101,0,0,1-29.3-61l-.7.6C73.8,91.9,64.2,131.8,69.4,186.5,124.2,191.7,164.1,182.2,188.3,158.1Zm23-111.9a1.9,1.9,0,0,0-1.5-1.5c-15.4-3.3-63.9-11.1-99.8,13.1a89.2,89.2,0,0,0,26.4,61.8A88.7,88.7,0,0,0,198.2,146C222.4,110.1,214.6,61.6,211.3,46.2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M164,76a8,8,0,1,1-8-8A8,8,0,0,1,164,76Zm31.3,89.1c-26.3,26.4-68.9,36.8-126.8,31.4,1.7,13.4,4.1,27.6,7.4,42.6a4,4,0,0,1-3,4.8H72a4.1,4.1,0,0,1-3.9-3.1,447.6,447.6,0,0,1-7.7-45.3c-14.2-1.7-29.3-4.2-45.3-7.7a4,4,0,0,1,1.8-7.8c15,3.2,29.2,5.7,42.6,7.4C54.1,129.6,64.5,87,90.8,60.7a92.8,92.8,0,0,1,10.9-9.3h.1c39.4-28.6,93.3-20,110.1-16.4a12,12,0,0,1,9.2,9.2C225.1,62.5,235,125.6,195.3,165.1Zm-5.6-5.6,3.5-3.7a99,99,0,0,1-63.9-29.1,99,99,0,0,1-29.1-63.9l-3.7,3.5C71.7,91.2,62,132.1,67.6,188.4,123.9,194,164.8,184.3,189.7,159.5ZM213.3,45.8a4.4,4.4,0,0,0-3.1-3.1c-15.7-3.4-65.6-11.4-102.2,14A91.8,91.8,0,0,0,199.3,148C224.7,111.4,216.7,61.5,213.3,45.8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M168,76a12,12,0,1,1-12-12A12,12,0,0,1,168,76Zm30.2,92c-26.5,26.4-68.4,37.4-125.1,32.9,1.6,11.8,3.8,24.3,6.7,37.4a7.9,7.9,0,0,1-6.1,9.5L72,248a8,8,0,0,1-7.8-6.3c-3.3-14.9-5.7-29.1-7.4-42.5-13.4-1.7-27.6-4.2-42.5-7.4a8,8,0,0,1,3.4-15.7c13.1,2.9,25.6,5.2,37.4,6.8C50.6,126.2,61.6,84.3,88,57.8c41-41,105.8-30.9,124.7-26.8A15.9,15.9,0,0,1,225,43.3C229.1,62.2,239.2,127,198.2,168Zm-13.7-9.1a103,103,0,0,1-58-29.4A103.6,103.6,0,0,1,97.1,71.4C75.3,95.2,66.6,133.1,71.3,184.7,122.8,189.4,160.8,180.7,184.5,158.9ZM209.4,46.7c-15-3.3-62.3-10.9-97.3,12.1a87.9,87.9,0,0,0,85.1,85.1C220.2,108.9,212.6,61.6,209.4,46.7Z" />
        </>
      )}
    </svg>
  );
}

export function PhFish(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M172,76a16,16,0,1,1-16-16A16,16,0,0,1,172,76Zm36.9,86c-20.9,26.4-53.9,41-98.2,43.5L90.9,249A11.9,11.9,0,0,1,80,256h-.8a12.1,12.1,0,0,1-10.7-8.5L54.4,201.6,8.5,187.4A11.9,11.9,0,0,1,0,176.7,12.2,12.2,0,0,1,7,165l43.5-19.7C52.9,101,67.6,68,94,47.1c42.5-33.4,102.3-23.8,119.6-20a20,20,0,0,1,15.3,15.3C232.7,59.7,242.3,119.5,208.9,162Zm-54,10.4A59.8,59.8,0,0,1,125,131a59.8,59.8,0,0,1-41.4-29.9q-8.7,21.9-9.4,52.4a12,12,0,0,1-7.1,10.6l-21.3,9.7,21.7,6.7a12.3,12.3,0,0,1,8,8l6.7,21.7,9.7-21.4a11.9,11.9,0,0,1,10.6-7Q133,181.1,154.9,172.4ZM206,50c-17.3-3.4-64.6-9.6-97.1,16a68.4,68.4,0,0,0-8.8,8.2A36,36,0,0,0,136,108a12,12,0,0,1,12,12,36,36,0,0,0,33.8,35.9,68.4,68.4,0,0,0,8.2-8.8C215.6,114.6,209.4,67.4,206,50Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M102.8,193.8h0L80,248,64,192,8,176l54.2-22.8h0c.9-39.1,11.1-65.6,25.9-83.5A17.7,17.7,0,0,0,88,72a48,48,0,0,0,48,48,48,48,0,0,0,48,48h2.3C168.4,182.7,141.8,192.9,102.8,193.8Z"
            opacity="0.2"
          />
          <path d="M168,76a12,12,0,1,1-12-12A12,12,0,0,1,168,76Zm37.7,83.5c-20.4,25.9-53.2,40.1-97.5,42.1L87.4,251.1A8.1,8.1,0,0,1,80,256h-.5a7.9,7.9,0,0,1-7.2-5.8L57.5,198.5,5.8,183.7a8,8,0,0,1-.9-15.1l49.5-20.8C56,112.6,65.2,84.7,82,64.5h0A101.7,101.7,0,0,1,96.5,50.3c41.1-32.4,99.4-23,116.3-19.3A15.9,15.9,0,0,1,225,43.2C228.7,60.1,238.1,118.4,205.7,159.5Zm-41.2,13a56.3,56.3,0,0,1-36-45,56.3,56.3,0,0,1-45-36c-8.3,16.5-12.7,37.2-13.3,61.9a8.2,8.2,0,0,1-4.9,7.2L32,174.5l34.2,9.8a8,8,0,0,1,5.5,5.5l9.7,34.1,14-33.2a8.1,8.1,0,0,1,7.2-4.9C127.3,185.2,148,180.8,164.5,172.5ZM209.4,46.6c-15.2-3.2-67.4-11.8-103,16.3A74.1,74.1,0,0,0,96,72.7,40.1,40.1,0,0,0,136,112a8,8,0,0,1,8,8,40.1,40.1,0,0,0,39.3,40,80,80,0,0,0,9.8-10.4C221.2,114,212.6,61.8,209.4,46.6Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M168,76a12,12,0,1,1-12-12A12,12,0,0,1,168,76Zm37.7,83.5c-20.4,25.9-53.2,40.1-97.5,42.1L87.4,251.1A8.1,8.1,0,0,1,80,256h-.5a7.9,7.9,0,0,1-7.2-5.8L57.5,198.5,5.8,183.7a8,8,0,0,1-.9-15.1l49.5-20.8c2-44.3,16.1-77.1,42.1-97.5,41.1-32.4,99.4-23,116.3-19.3A15.9,15.9,0,0,1,225,43.2C228.7,60.1,238.1,118.4,205.7,159.5Zm3.7-112.9c-15.2-3.2-67.4-11.8-103,16.3A74.1,74.1,0,0,0,96,72.7,40.1,40.1,0,0,0,136,112a8,8,0,0,1,8,8,40.1,40.1,0,0,0,39.3,40,80,80,0,0,0,9.8-10.4C221.2,114,212.6,61.8,209.4,46.6Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M166,76a10,10,0,1,1-10-10A10,10,0,0,1,166,76Zm38.1,82.3c-20.2,25.7-52.9,39.6-97.3,41.4L85.5,250.3A5.8,5.8,0,0,1,80,254h-.4a6.1,6.1,0,0,1-5.4-4.4L59.1,196.9,6.3,181.7A6,6,0,0,1,2,176.3a6.1,6.1,0,0,1,3.7-5.9l50.6-21.3c1.8-44.3,15.7-77,41.4-97.2C138.2,20,195.7,29.3,212.3,33A13.7,13.7,0,0,1,223,43.7C226.7,60.3,236,117.8,204.1,158.3ZM169.6,172a54,54,0,0,1-39.3-46.3A54.2,54.2,0,0,1,83.9,86.3c-9.8,17.5-15.1,39.9-15.7,67a6,6,0,0,1-3.7,5.4L26,174.9l39.7,11.3a6.4,6.4,0,0,1,4.1,4.2L81.1,230l16.2-38.5a6,6,0,0,1,5.4-3.7C129.8,187.2,152.2,181.9,169.6,172ZM211.3,46.2a1.7,1.7,0,0,0-1.5-1.5c-15.4-3.3-68.3-12-104.6,16.6A75.5,75.5,0,0,0,94,72h0a42,42,0,0,0,42,42,6,6,0,0,1,6,6,42,42,0,0,0,42,42h0a81,81,0,0,0,10.7-11.2C223.3,114.5,214.6,61.6,211.3,46.2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M164,76a8,8,0,1,1-8-8A8,8,0,0,1,164,76Zm38.6,81a92,92,0,0,1-13.8,14.1h-.1c-20.1,16.4-47.9,25.3-83.2,26.5L83.7,249.6A4.1,4.1,0,0,1,80,252h-.2a4,4,0,0,1-3.6-2.9L60.8,195.2,6.9,179.8A4.1,4.1,0,0,1,4,176.2a4,4,0,0,1,2.4-3.9l51.9-21.8c1.2-35.3,10.1-63.1,26.5-83.2H85A91.4,91.4,0,0,1,99,53.4c39.7-31.2,96.5-22.1,112.9-18.5a12,12,0,0,1,9.2,9.2C224.7,60.5,233.8,117.3,202.6,157ZM175,171.2a52.2,52.2,0,0,1-42.9-47.3A52.2,52.2,0,0,1,84.8,81q-17.5,27.6-18.6,72.3a4.2,4.2,0,0,1-2.5,3.6L20,175.2l45.1,13a3.7,3.7,0,0,1,2.7,2.7L80.7,236l18.4-43.8a4,4,0,0,1,3.6-2.4C132.4,189.1,156.6,182.9,175,171.2ZM213.3,45.8a4.4,4.4,0,0,0-3.1-3.1c-15.6-3.3-69.3-12.1-106.3,17A86.2,86.2,0,0,0,92,71.2V72a44,44,0,0,0,44,44,4,4,0,0,1,4,4,44,44,0,0,0,44,44h.8a86.2,86.2,0,0,0,11.5-11.9C225.4,115.1,216.6,61.4,213.3,45.8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M168,76a12,12,0,1,1-12-12A12,12,0,0,1,168,76Zm37.7,83.5c-20.4,25.9-53.2,40.1-97.5,42.1L87.4,251.1A8.1,8.1,0,0,1,80,256h-.5a7.9,7.9,0,0,1-7.2-5.8L57.5,198.5,5.8,183.7a8,8,0,0,1-.9-15.1l49.5-20.8C56,112.6,65.2,84.7,82,64.5h0A101.7,101.7,0,0,1,96.5,50.3c41.1-32.4,99.4-23,116.3-19.3A15.9,15.9,0,0,1,225,43.2C228.7,60.1,238.1,118.4,205.7,159.5Zm-41.2,13a56.3,56.3,0,0,1-36-45,56.3,56.3,0,0,1-45-36c-8.3,16.5-12.7,37.2-13.3,61.9a8.2,8.2,0,0,1-4.9,7.2L32,174.5l34.2,9.8a8,8,0,0,1,5.5,5.5l9.7,34.1,14-33.2a8.1,8.1,0,0,1,7.2-4.9C127.3,185.2,148,180.8,164.5,172.5ZM209.4,46.6c-15.2-3.2-67.4-11.8-103,16.3A74.1,74.1,0,0,0,96,72.7,40.1,40.1,0,0,0,136,112a8,8,0,0,1,8,8,40.1,40.1,0,0,0,39.3,40,80,80,0,0,0,9.8-10.4C221.2,114,212.6,61.8,209.4,46.6Z" />
        </>
      )}
    </svg>
  );
}

export function PhFlagBanner(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M191.4,108l42-52.5A12,12,0,0,0,224,36H40A12,12,0,0,0,28,48V216a12,12,0,0,0,24,0V180H224a12,12,0,0,0,9.4-19.5ZM52,156V60H199l-32.4,40.5a12,12,0,0,0,0,15L199,156Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M176,108l48,60H40V48H224Z" opacity="0.2" />
          <path d="M186.2,108l44-55A8,8,0,0,0,224,40H40a8,8,0,0,0-8,8V216a8,8,0,0,0,16,0V176H224a8,8,0,0,0,6.2-13ZM48,160V56H207.4l-37.6,47a7.8,7.8,0,0,0,0,10l37.6,47Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M230.2,163a8,8,0,0,1-6.2,13H48v40a8,8,0,0,1-16,0V48a8,8,0,0,1,8-8H224a8,8,0,0,1,6.2,13l-44,55Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M183.7,108l45-56.3A6,6,0,0,0,224,42H40a6,6,0,0,0-6,6V216a6,6,0,0,0,12,0V174H224a6,6,0,0,0,4.7-9.7ZM46,162V54H211.5l-40.2,50.3a6,6,0,0,0,0,7.4L211.5,162Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M181.1,108l46-57.5a3.8,3.8,0,0,0,.5-4.2A4.1,4.1,0,0,0,224,44H40a4,4,0,0,0-4,4V216a4,4,0,0,0,8,0V172H224a4.1,4.1,0,0,0,3.6-2.3,3.8,3.8,0,0,0-.5-4.2ZM44,164V52H215.7l-42.8,53.5a3.9,3.9,0,0,0,0,5L215.7,164Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M186.2,108l44-55A8,8,0,0,0,224,40H40a8,8,0,0,0-8,8V216a8,8,0,0,0,16,0V176H224a8,8,0,0,0,6.2-13ZM48,160V56H207.4l-37.6,47a7.8,7.8,0,0,0,0,10l37.6,47Z" />
        </>
      )}
    </svg>
  );
}

export function PhFlagCheckered(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M221.4,37.3a12,12,0,0,0-12.6,1.1c-26.4,19.8-48.3,10.5-76.1-1.4S70.3,10.2,32.8,38.4A12.2,12.2,0,0,0,28,48h0V216a12,12,0,0,0,24,0V174.3c24.3-15.4,45.2-6.4,71.3,4.7,16.7,7.2,34.7,14.9,54.1,14.9,14.5,0,29.8-4.3,45.8-16.3A12.1,12.1,0,0,0,228,168V48A12.1,12.1,0,0,0,221.4,37.3ZM204,68.7V99.9c-12.5,7.9-24.1,9.3-36,7.4v-34A70.6,70.6,0,0,0,204,68.7Zm-60-1.3V99.9l-11.3-4.8c-6.7-2.9-13.6-5.8-20.7-8.3V54.3L123.3,59C130,61.9,136.9,64.9,144,67.4ZM88,46.9V80.8a69.6,69.6,0,0,0-36,4.7V54.3C64.5,46.4,76.1,44.9,88,46.9ZM52,147.3V112.4c12.5-7.9,24.1-9.3,36-7.4v37.7A70.6,70.6,0,0,0,52,147.3Zm60,1.3V112.4l11.3,4.8c6.7,2.8,13.6,5.8,20.7,8.3v36.2L132.7,157C126,154.1,119.1,151.1,112,148.6Zm56,20.5V131.5c3.1.3,6.3.6,9.4.6a70,70,0,0,0,26.6-5.3v34.9C191.5,169.6,179.9,171.1,168,169.1Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M100,37.4V95.5c-19-5.4-38.6-5.4-60,10.6V48C61.4,32,81,32,100,37.4Zm56,21.2v58.2c19,5.3,38.6,5.4,60-10.7V48C194.6,64,175,64,156,58.6Zm-56,98.8c18.9,5.3,37.1,15.9,56,21.2V116.8c-18.9-5.4-37.1-15.9-56-21.3Z"
            opacity="0.2"
          />
          <path d="M219.6,40.8a8.2,8.2,0,0,0-8.4.8c-28.3,21.2-52.3,11-80-.9s-60.3-25.9-96,.9A8,8,0,0,0,32,48h0V216a8,8,0,0,0,16,0V172.1c26.9-18.1,50.1-8.2,76.8,3.3,16.3,6.9,33.8,14.4,52.6,14.4,13.8,0,28.3-4,43.4-15.4A8.1,8.1,0,0,0,224,168V48A8.2,8.2,0,0,0,219.6,40.8ZM208,62.4V102c-15.4,10.4-29.6,11.6-44,8.6v-42C177.9,71.1,192.6,70.2,208,62.4Zm-60,2.1v41.2q-8.2-3.2-16.8-6.9c-7.5-3.2-15.2-6.5-23.2-9.2V48.4c5.5,2.1,11.1,4.5,16.8,7C132.3,58.6,140,61.9,148,64.5ZM92,43.6V85.5C78.1,83.1,63.4,84,48,91.7V52.1C63.4,41.7,77.6,40.5,92,43.6Zm-44,110V110.3c15.4-10.4,29.6-11.6,44-8.6v45.7C78.1,145,63.4,145.8,48,153.6Zm60-2.1V106.6c5.5,2,11.1,4.4,16.8,6.9,7.5,3.2,15.2,6.5,23.2,9.1v45c-5.5-2.1-11.1-4.5-16.8-6.9C123.7,157.5,116,154.1,108,151.5Zm56,20.9V126.8a84.5,84.5,0,0,0,13.4,1.2,66,66,0,0,0,30.6-7.5v43.4C192.6,174.3,178.4,175.5,164,172.4Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M219.6,40.8a8.2,8.2,0,0,0-8.4.8c-28.3,21.2-52.3,11-80-.9s-60.3-25.9-96,.9A8,8,0,0,0,32,48h0V216a8,8,0,0,0,16,0V172.1c26.9-18.1,50.1-8.2,76.8,3.3,16.3,6.9,33.8,14.4,52.6,14.4,13.8,0,28.3-4,43.4-15.4A8.1,8.1,0,0,0,224,168V48A8.2,8.2,0,0,0,219.6,40.8ZM156,170.3V116.8c-18.9-5.4-37.1-15.9-56-21.3v53.6c-16.3-4.2-33.6-4.8-52,4.5V100.8c18.3-10.6,35.4-10,52-5.3V45.7a243.3,243.3,0,0,1,24.8,9.7c10,4.2,20.4,8.7,31.2,11.5v49.9c16.6,4.7,33.7,5.3,52-5.3v52.4C189.7,176.2,173.1,175.6,156,170.3Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M218.7,42.6a6,6,0,0,0-6.3.6c-29.2,21.9-54.9,10.9-82-.7s-59.2-25.4-94,.7A6,6,0,0,0,34,48h0V216a6,6,0,0,0,12,0V171.1c28.2-19.6,53.2-8.9,79.6,2.4,16.1,6.9,33.3,14.3,51.7,14.3,13.5,0,27.6-4,42.3-15A6,6,0,0,0,222,168V48A6.2,6.2,0,0,0,218.7,42.6ZM210,59.1v44c-16.8,11.6-32.5,12.5-48,9V66.2C177.2,69.2,193.2,68.5,210,59.1ZM150,63v45.5c-6.5-2.2-13-5-19.6-7.9s-16-6.8-24.4-9.5V45.6c6.5,2.3,13,5.1,19.6,7.9A246.7,246.7,0,0,0,150,63ZM94,42.1V88c-15.2-3.1-31.2-2.3-48,7.1v-44C62.8,39.4,78.5,38.5,94,42.1ZM46,156.9V109.2c16.8-11.6,32.5-12.6,48-9v49.6C78.8,146.8,62.8,147.5,46,156.9Zm60-3.9V103.7c6.5,2.3,13,5.1,19.6,8s16,6.8,24.4,9.5v49.2c-6.5-2.3-13-5.1-19.6-7.9S114.4,155.7,106,153Zm56,21V124.3a77.9,77.9,0,0,0,15.3,1.6,65.8,65.8,0,0,0,32.7-8.7v47.7C193.2,176.6,177.5,177.5,162,174Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M217.8,44.4a4,4,0,0,0-4.2.4c-30.1,22.6-56.3,11.4-84-.5s-58.1-24.9-92,.5A4.2,4.2,0,0,0,36,48h0V216a4,4,0,0,0,8,0V170.1c29.5-21.1,55.2-10.1,82.4,1.6,16.5,7,33.2,14.2,50.9,14.2,13.1,0,26.7-3.9,41.1-14.7A4,4,0,0,0,220,168V48A4.2,4.2,0,0,0,217.8,44.4ZM212,55.6v48.5c-18.4,13.1-35.3,13.8-52,9.6V63.8C176.5,67.6,193.6,67,212,55.6Zm-60,6v49.8c-7.4-2.5-14.9-5.7-22.4-8.9s-16.9-7.3-25.6-10V42.8c7.4,2.5,14.9,5.6,22.4,8.9A265.2,265.2,0,0,0,152,61.6ZM96,40.5V90.4c-16.5-3.8-33.6-3.3-52,8.2V50.1C62.4,36.9,79.3,36.3,96,40.5ZM44,160.4V108.2c18.4-13.1,35.3-13.8,52-9.6v53.6C79.5,148.4,62.4,149,44,160.4Zm60-6V100.9c7.4,2.5,14.9,5.7,22.4,8.9s16.9,7.3,25.6,10v53.4c-7.4-2.5-14.9-5.6-22.4-8.9A265.2,265.2,0,0,0,104,154.4Zm56,21.2V121.9a78,78,0,0,0,17.3,2.1c11.2,0,22.7-2.8,34.7-10.3V166C193.6,179.1,176.7,179.7,160,175.6Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M219.6,40.8a8.2,8.2,0,0,0-8.4.8c-28.3,21.2-52.3,11-80-.9s-60.3-25.9-96,.9A8,8,0,0,0,32,48h0V216a8,8,0,0,0,16,0V172.1c26.9-18.1,50.1-8.2,76.8,3.3,16.3,6.9,33.8,14.4,52.6,14.4,13.8,0,28.3-4,43.4-15.4A8.1,8.1,0,0,0,224,168V48A8.2,8.2,0,0,0,219.6,40.8ZM208,62.4V102c-15.4,10.4-29.6,11.6-44,8.6v-42C177.9,71.1,192.6,70.2,208,62.4Zm-60,2.1v41.2q-8.2-3.2-16.8-6.9c-7.5-3.2-15.2-6.5-23.2-9.2V48.4c5.5,2.1,11.1,4.5,16.8,7C132.3,58.6,140,61.9,148,64.5ZM92,43.6V85.5C78.1,83.1,63.4,84,48,91.7V52.1C63.4,41.7,77.6,40.5,92,43.6Zm-44,110V110.3c15.4-10.4,29.6-11.6,44-8.6v45.7C78.1,145,63.4,145.8,48,153.6Zm60-2.1V106.6c5.5,2,11.1,4.4,16.8,6.9,7.5,3.2,15.2,6.5,23.2,9.1v45c-5.5-2.1-11.1-4.5-16.8-6.9C123.7,157.5,116,154.1,108,151.5Zm56,20.9V126.8a84.5,84.5,0,0,0,13.4,1.2,66,66,0,0,0,30.6-7.5v43.4C192.6,174.3,178.4,175.5,164,172.4Z" />
        </>
      )}
    </svg>
  );
}

export function PhFlag(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M221.4,37.3a12,12,0,0,0-12.6,1.1c-26.5,19.8-48.3,10.5-76.1-1.4S70.3,10.2,32.8,38.4l-.2.2-.5.4-.4.4-.4.3c-.2.2-.3.4-.5.5l-.2.4-.5.6c-.1.1-.1.2-.2.4l-.4.5c-.1.2-.1.4-.2.5l-.3.6c-.1.1-.1.3-.2.5s-.1.4-.2.5-.1.4-.2.6-.1.4-.1.6a4.3,4.3,0,0,1-.1.5c0,.2-.1.4-.1.6a1.3,1.3,0,0,1-.1.6V216a12,12,0,0,0,24,0V174.3c24.3-15.4,45.2-6.4,71.3,4.7,16.7,7.2,34.7,14.9,54.1,14.9,14.5,0,29.8-4.3,45.8-16.3A12.1,12.1,0,0,0,228,168V48A12.1,12.1,0,0,0,221.4,37.3ZM204,161.7c-24.3,15.4-45.2,6.4-71.3-4.7-24.1-10.4-50.9-21.9-80.7-9.7v-93c24.3-15.4,45.2-6.4,71.3,4.7,24.1,10.4,50.9,21.9,80.7,9.7Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M40,168c64-48,112,48,176,0V48C152,96,104,0,40,48Z" />
          </g>
          <path d="M219.6,40.8a8.2,8.2,0,0,0-8.4.8c-28.3,21.2-52.3,10.9-80.1-1s-60.2-25.8-95.9,1h-.1l-.4.3-.3.2-.2.3-.3.3-.2.3-.3.3c0,.1-.1.2-.2.3l-.2.4c0,.1-.1.2-.1.3a.8.8,0,0,0-.2.4c-.1.1-.1.2-.2.4s-.1.2-.1.3-.1.3-.1.4-.1.2-.1.3-.1.3-.1.4V47a.6.6,0,0,0-.1.4V216a8,8,0,0,0,16,0V172.1c26.9-18.1,50.1-8.2,76.8,3.3,16.3,6.9,33.8,14.4,52.6,14.4,13.8,0,28.3-4,43.4-15.4A8.1,8.1,0,0,0,224,168V48A8.2,8.2,0,0,0,219.6,40.8ZM208,163.9c-26.9,18.1-50.1,8.2-76.9-3.3-24.8-10.6-52.5-22.5-83.1-7V52.1c26.9-18.1,50.1-8.2,76.8,3.3,24.9,10.6,52.6,22.5,83.2,7Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M224,48V168a8.1,8.1,0,0,1-3.2,6.4c-15.1,11.4-29.6,15.4-43.4,15.4-18.8,0-36.3-7.5-52.6-14.4C98.1,163.9,74.9,154,48,172.1V216a8,8,0,0,1-16,0V47.4a.6.6,0,0,1,.1-.4v-.4c0-.1.1-.2.1-.4s.1-.2.1-.3.1-.3.1-.4.1-.2.1-.3.1-.3.2-.4a.8.8,0,0,1,.2-.4c0-.1.1-.2.1-.3l.2-.4c.1-.1.2-.2.2-.3l.3-.3.2-.3.3-.3.2-.3.3-.2.4-.3h.1c35.7-26.8,67.7-13.1,95.9-1s51.8,22.2,80.1,1A8,8,0,0,1,224,48Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M218.7,42.6a6,6,0,0,0-6.3.6c-29.2,21.9-54.9,10.9-82-.7s-59.2-25.4-94,.7h-.1a.2.2,0,0,0-.2.2l-.3.2a.3.3,0,0,0-.1.2l-.3.2c0,.1-.1.1-.1.2s-.2.2-.2.3-.1.1-.2.2-.1.2-.1.3l-.2.2a.4.4,0,0,1-.1.3.3.3,0,0,1-.1.2c0,.1-.1.2-.1.3s-.1.2-.1.3a.4.4,0,0,1-.1.3V47a.4.4,0,0,1-.1.3v.6h0V216a6,6,0,0,0,12,0V171.1c28.2-19.6,53.2-8.9,79.6,2.4,16.1,6.9,33.3,14.3,51.7,14.3,13.5,0,27.6-4,42.3-15A6,6,0,0,0,222,168V48A6,6,0,0,0,218.7,42.6ZM210,164.9c-28.2,19.6-53.2,8.9-79.6-2.4S77,139.6,46,156.9V51.1c28.2-19.6,53.2-8.9,79.6,2.4S179,76.4,210,59.1Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M217.8,44.4a4,4,0,0,0-4.2.4c-30.2,22.6-56.3,11.4-84-.5s-58.1-24.9-92,.5l-.2.2-.3.3-.3.3c0,.1-.1.2-.2.3s-.1.2-.2.3-.1.2-.1.4a.4.4,0,0,0-.1.3,61654275059634.7,61654275059634.7,0,0,0-.2.8V216a4,4,0,0,0,8,0V170.1c29.5-21.1,55.2-10.1,82.4,1.6,16.5,7,33.2,14.2,50.9,14.2,13.1,0,26.7-3.9,41.1-14.7A4,4,0,0,0,220,168V48A4.2,4.2,0,0,0,217.8,44.4ZM212,166c-29.5,21-55.2,10-82.4-1.7S75.2,141,44,160.4V50.1C73.5,29,99.2,40,126.4,51.7S180.8,75,212,55.6Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M219.6,40.8a8.2,8.2,0,0,0-8.4.8c-28.3,21.2-52.3,10.9-80-1s-60.3-25.8-96,1h-.1l-.4.3-.2.2-.3.3-.3.3-.2.3-.3.3c0,.1-.1.2-.2.3l-.2.4c0,.1-.1.2-.1.3a.8.8,0,0,0-.2.4c-.1.1-.1.2-.2.4s-.1.2-.1.3-.1.3-.1.4-.1.2-.1.3-.1.3-.1.4V47c0,.1-.1.3-.1.4V216a8,8,0,0,0,16,0V172.1c26.9-18.1,50.1-8.2,76.8,3.3,16.3,6.9,33.8,14.4,52.6,14.4,13.8,0,28.3-4,43.4-15.4A8.1,8.1,0,0,0,224,168V48A8.2,8.2,0,0,0,219.6,40.8ZM208,163.9c-26.9,18.1-50.1,8.2-76.8-3.3-24.9-10.6-52.6-22.5-83.2-7V52.1c26.9-18.1,50.1-8.2,76.8,3.3,24.9,10.6,52.6,22.5,83.2,7Z" />
        </>
      )}
    </svg>
  );
}

export function PhFlame(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M176.7,48.7a228.5,228.5,0,0,0-42.5-35,12,12,0,0,0-12.4,0,228.5,228.5,0,0,0-42.5,35C51,78.5,36,111.4,36,144a92,92,0,0,0,184,0C220,111.4,205,78.5,176.7,48.7ZM100,184c0-13.3,5.5-26.3,16.4-38.5A106.9,106.9,0,0,1,128,134.7a106.9,106.9,0,0,1,11.6,10.8C150.5,157.7,156,170.7,156,184a28,28,0,0,1-56,0Zm79.8,3.9c.1-1.3.2-2.6.2-3.9,0-46.3-44-73.2-45.8-74.3a12,12,0,0,0-12.4,0C120,110.8,76,137.7,76,184c0,1.3.1,2.6.2,3.9A67.4,67.4,0,0,1,60,144c0-26.5,12.2-52.9,36.3-78.3A214.3,214.3,0,0,1,128,38.4c17.8,12.5,68,52.3,68,105.6A67.4,67.4,0,0,1,179.8,187.9Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M128,24S48,72,48,144a79.9,79.9,0,0,0,77.9,79.9A39.9,39.9,0,0,1,88,184c0-40,40-64,40-64s40,24,40,64a39.9,39.9,0,0,1-37.9,39.9A79.9,79.9,0,0,0,208,144C208,72,128,24,128,24Z"
            opacity="0.2"
          />
          <path d="M173.8,51.5a221.5,221.5,0,0,0-41.7-34.4,8,8,0,0,0-8.2,0A221.5,221.5,0,0,0,82.2,51.5C54.6,80.5,40,112.5,40,144a88,88,0,0,0,176,0C216,112.5,201.4,80.5,173.8,51.5ZM96,184c0-27.7,22.5-47.3,32-54.3,9.5,7,32,26.6,32,54.3a32,32,0,0,1-64,0Zm77.3,15.9A49.1,49.1,0,0,0,176,184c0-44-42.1-69.8-43.9-70.9a8,8,0,0,0-8.2,0C122.1,114.2,80,140,80,184a49.1,49.1,0,0,0,2.7,15.9A71.7,71.7,0,0,1,56,144c0-34.4,20.4-63.1,37.5-81.2A223.2,223.2,0,0,1,128,33.5a223.2,223.2,0,0,1,34.5,29.3C193.5,95.5,200,125,200,144A71.7,71.7,0,0,1,173.3,199.9Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M173.8,51.5a221.5,221.5,0,0,0-41.7-34.4,8,8,0,0,0-8.2,0A221.5,221.5,0,0,0,82.2,51.5C54.6,80.5,40,112.5,40,144a88,88,0,0,0,176,0C216,112.5,201.4,80.5,173.8,51.5ZM128,216a32.1,32.1,0,0,1-32-32c0-27.7,22.5-47.3,32-54.3,9.5,7,32,26.6,32,54.3A32.1,32.1,0,0,1,128,216Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M172.3,52.9a216.8,216.8,0,0,0-41.2-34,5.8,5.8,0,0,0-6.2,0,216.8,216.8,0,0,0-41.2,34C56.4,81.5,42,113,42,144a86,86,0,0,0,172,0C214,113,199.6,81.5,172.3,52.9ZM94,184c0-29.8,25.1-50.4,34-56.8,8.9,6.4,34,26.9,34,56.8a34.1,34.1,0,0,1-32.3,34h-3.4A34.1,34.1,0,0,1,94,184Zm74.4,21.9A45.1,45.1,0,0,0,174,184c0-42.9-41.2-68.1-42.9-69.1a5.8,5.8,0,0,0-6.2,0c-1.7,1-42.9,26.2-42.9,69.1a45.1,45.1,0,0,0,5.6,21.9A73.9,73.9,0,0,1,54,144c0-59.8,59.6-103.3,74-112.9,14.4,9.6,74,53.1,74,112.9A73.9,73.9,0,0,1,168.4,205.9Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M170.9,54.2a216.8,216.8,0,0,0-40.8-33.6,4,4,0,0,0-4.2,0A216.8,216.8,0,0,0,85.1,54.2C58.2,82.5,44,113.5,44,144a84,84,0,0,0,168,0C212,113.5,197.8,82.5,170.9,54.2ZM92,184c0-32.1,28.1-53.7,36-59.2,7.9,5.5,36,27.1,36,59.2a36,36,0,0,1-72,0Zm69.9,28A43.8,43.8,0,0,0,172,184c0-41.8-40.2-66.4-41.9-67.4a4,4,0,0,0-4.2,0c-1.7,1-41.9,25.6-41.9,67.4a43.8,43.8,0,0,0,10.1,28A76,76,0,0,1,52,144c0-62.5,63.6-107.2,76-115.3,12.4,8.1,76,52.8,76,115.3A76,76,0,0,1,161.9,212Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M173.8,51.5a221.5,221.5,0,0,0-41.7-34.4,8,8,0,0,0-8.2,0A221.5,221.5,0,0,0,82.2,51.5C54.6,80.5,40,112.5,40,144a88,88,0,0,0,176,0C216,112.5,201.4,80.5,173.8,51.5ZM96,184c0-27.7,22.5-47.3,32-54.3,9.5,7,32,26.6,32,54.3a32,32,0,0,1-64,0Zm77.3,15.9A49.1,49.1,0,0,0,176,184c0-44-42.1-69.8-43.9-70.9a8,8,0,0,0-8.2,0C122.1,114.2,80,140,80,184a49.1,49.1,0,0,0,2.7,15.9A71.7,71.7,0,0,1,56,144c0-34.4,20.4-63.1,37.5-81.2A223.2,223.2,0,0,1,128,33.5a223.2,223.2,0,0,1,34.5,29.3C193.5,95.5,200,125,200,144A71.7,71.7,0,0,1,173.3,199.9Z" />
        </>
      )}
    </svg>
  );
}

export function PhFlashlight(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M184,12H72A20.1,20.1,0,0,0,52,32V77.3a19.9,19.9,0,0,0,4,12L76,116V224a20.1,20.1,0,0,0,20,20h64a20.1,20.1,0,0,0,20-20V116l20-26.7a19.9,19.9,0,0,0,4-12V32A20.1,20.1,0,0,0,184,12Zm-4,24V52H76V36Zm-20,66.7a19.9,19.9,0,0,0-4,12V220H100V114.7a19.9,19.9,0,0,0-4-12L76,76H180ZM140,120v32a12,12,0,0,1-24,0V120a12,12,0,0,1,24,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M192,77.3a8.1,8.1,0,0,1-1.6,4.8l-20.8,27.8a8.1,8.1,0,0,0-1.6,4.8V224a8,8,0,0,1-8,8H96a8,8,0,0,1-8-8V114.7a8.1,8.1,0,0,0-1.6-4.8L65.6,82.1A8.1,8.1,0,0,1,64,77.3V64H192Z" />
          </g>
          <path d="M184,16H72A16,16,0,0,0,56,32V77.3a15.6,15.6,0,0,0,3.2,9.6L80,114.7V224a16,16,0,0,0,16,16h64a16,16,0,0,0,16-16V114.7l20.8-27.8a15.6,15.6,0,0,0,3.2-9.6V32A16,16,0,0,0,184,16ZM72,32H184V56H72V32Zm91.2,73.1a15.6,15.6,0,0,0-3.2,9.6V224H96V114.7a15.6,15.6,0,0,0-3.2-9.6L72,77.3V72H184v5.3Z" />
          <path d="M128,112a8,8,0,0,0-8,8v32a8,8,0,0,0,16,0V120A8,8,0,0,0,128,112Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M184,16H72A16,16,0,0,0,56,32V77.3a15.6,15.6,0,0,0,3.2,9.6L80,114.7V224a16,16,0,0,0,16,16h64a16,16,0,0,0,16-16V114.7l20.8-27.8a15.6,15.6,0,0,0,3.2-9.6V32A16,16,0,0,0,184,16ZM136,152a8,8,0,0,1-16,0V120a8,8,0,0,1,16,0ZM72,56V32H184V56Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M184,18H72A14,14,0,0,0,58,32V77.3a14.1,14.1,0,0,0,2.8,8.4l20.8,27.8a1.6,1.6,0,0,1,.4,1.2V224a14,14,0,0,0,14,14h64a14,14,0,0,0,14-14V114.7a1.6,1.6,0,0,1,.4-1.2l20.8-27.8a14.1,14.1,0,0,0,2.8-8.4V32A14,14,0,0,0,184,18ZM72,30H184a2,2,0,0,1,2,2V58H70V32A2,2,0,0,1,72,30ZM185.6,78.5l-20.8,27.8a14.1,14.1,0,0,0-2.8,8.4V224a2,2,0,0,1-2,2H96a2,2,0,0,1-2-2V114.7a14.1,14.1,0,0,0-2.8-8.4L70.4,78.5a1.6,1.6,0,0,1-.4-1.2V70H186v7.3A1.6,1.6,0,0,1,185.6,78.5ZM134,120v32a6,6,0,0,1-12,0V120a6,6,0,0,1,12,0Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M184,20H72A12,12,0,0,0,60,32V77.3a12.2,12.2,0,0,0,2.4,7.2l20.8,27.8a4.1,4.1,0,0,1,.8,2.4V224a12,12,0,0,0,12,12h64a12,12,0,0,0,12-12V114.7a4.1,4.1,0,0,1,.8-2.4l20.8-27.8a12.2,12.2,0,0,0,2.4-7.2V32A12,12,0,0,0,184,20ZM72,28H184a4,4,0,0,1,4,4V60H68V32A4,4,0,0,1,72,28ZM187.2,79.7l-20.8,27.8a12.2,12.2,0,0,0-2.4,7.2V224a4,4,0,0,1-4,4H96a4,4,0,0,1-4-4V114.7a12.2,12.2,0,0,0-2.4-7.2L68.8,79.7a4.1,4.1,0,0,1-.8-2.4V68H188v9.3A4.1,4.1,0,0,1,187.2,79.7ZM132,120v32a4,4,0,0,1-8,0V120a4,4,0,0,1,8,0Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M184,16H72A16,16,0,0,0,56,32V77.3a15.6,15.6,0,0,0,3.2,9.6L80,114.7V224a16,16,0,0,0,16,16h64a16,16,0,0,0,16-16V114.7l20.8-27.8a15.6,15.6,0,0,0,3.2-9.6V32A16,16,0,0,0,184,16ZM72,32H184V56H72V32Zm91.2,73.1a15.6,15.6,0,0,0-3.2,9.6V224H96V114.7a15.6,15.6,0,0,0-3.2-9.6L72,77.3V72H184v5.3ZM136,120v32a8,8,0,0,1-16,0V120a8,8,0,0,1,16,0Z" />
        </>
      )}
    </svg>
  );
}

export function PhFlask(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M227,197.7l-63-105V44h4a12,12,0,0,0,0-24H88a12,12,0,0,0,0,24h4V92.7l-39.6,66v.2L29,197.7A19.9,19.9,0,0,0,46.1,228H209.9A19.9,19.9,0,0,0,227,197.7ZM113.1,104.1A19.8,19.8,0,0,0,116,93.8V44h24V93.8a19.8,19.8,0,0,0,2.9,10.3L179.4,165c-14.3,5.2-32.3-.9-46-7.7-17.9-9-32.7-12.3-44.8-12.3ZM53.2,204l18.3-30.6c15-9.1,35.9-2.3,51.1,5.3,17.9,9,32.8,12.3,45,12.3a56.3,56.3,0,0,0,24.3-5.2L202.8,204Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M195.9,169.1Zm20.8,34.8-20.8-34.8c-11.4,9.7-32.2,16.8-67.9-1.1-33.3-16.6-53.6-11.7-65.4-3h0L39.3,203.9A8,8,0,0,0,46.1,216H209.9A8,8,0,0,0,216.7,203.9Z"
            opacity="0.2"
          />
          <path d="M223.6,199.8,160,93.8V40h8a8,8,0,0,0,0-16H88a8,8,0,0,0,0,16h8V93.8L55.8,160.7c0,.1-.1.2-.1.3L32.4,199.8A16,16,0,0,0,46.1,224H209.9a16,16,0,0,0,13.7-24.2ZM109.7,102a15.9,15.9,0,0,0,2.3-8.2V40h32V93.8a15.9,15.9,0,0,0,2.3,8.2l38.8,64.8c-13.1,7.1-31.4,5.1-53.5-6-14.3-7.1-32.7-13.5-50.3-11.4ZM46.1,208l22.5-37.4c13.2-8.7,32.4-7.1,55.8,4.6,17.3,8.6,31.5,11.8,43.2,11.8a51.4,51.4,0,0,0,25.8-6.5L209.9,208Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M223.6,199.8,160,93.8V40h8a8,8,0,0,0,0-16H88a8,8,0,0,0,0,16h8V93.8L55.8,160.7c0,.1-.1.2-.1.3L32.4,199.8A16,16,0,0,0,46.1,224H209.9a16,16,0,0,0,13.7-24.2Zm-92-39c-14.3-7.1-32.7-13.5-50.3-11.4L109.7,102a15.9,15.9,0,0,0,2.3-8.2V40h32V93.8a15.9,15.9,0,0,0,2.3,8.2l38.8,64.8C172,173.9,153.7,171.9,131.6,160.8Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M221.9,200.8l-63.6-106a1.9,1.9,0,0,1-.3-1V38h10a6,6,0,0,0,0-12H88a6,6,0,0,0,0,12H98V93.8a1.9,1.9,0,0,1-.3,1l-40.2,67v.2L34.1,200.8a14,14,0,0,0,12,21.2H209.9a14,14,0,0,0,12-21.2ZM108,101a14.2,14.2,0,0,0,2-7.2V38h36V93.8a14.2,14.2,0,0,0,2,7.2l39.9,66.5c-13.8,8.7-33.5,7-57.2-4.9s-40.5-13.1-53.4-10.5ZM211.6,209a1.8,1.8,0,0,1-1.7,1H46.1a1.8,1.8,0,0,1-1.7-1,2,2,0,0,1,0-2l22.7-37.8c13.8-9.4,33.9-8,58.2,4.2,17,8.4,30.9,11.6,42.2,11.6a49.4,49.4,0,0,0,26.6-7.2L211.6,207A2,2,0,0,1,211.6,209Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M220.2,201.8l-63.6-106a3.6,3.6,0,0,1-.6-2V36h12a4,4,0,0,0,0-8H88a4,4,0,0,0,0,8h12V93.8a3.6,3.6,0,0,1-.6,2L59.2,162.9h0L35.8,201.8a11.9,11.9,0,0,0-.1,12.1A11.7,11.7,0,0,0,46.1,220H209.9a11.7,11.7,0,0,0,10.4-6.1A11.9,11.9,0,0,0,220.2,201.8ZM106.3,100a12.3,12.3,0,0,0,1.7-6.2V36h40V93.8a12.3,12.3,0,0,0,1.7,6.2l40.9,68.1c-14.5,10.3-35.4,9-60.8-3.7-16.1-8-37.6-15.1-56.7-9.1ZM213.4,210a4.1,4.1,0,0,1-3.5,2H46.1a4.1,4.1,0,0,1-3.5-2,4.2,4.2,0,0,1,.1-4.1l22.9-38.1c14.5-10.1,35.4-8.8,60.6,3.8,16.6,8.3,30.3,11.4,41.3,11.4s20.7-3.5,27.2-8l18.6,30.9A4.2,4.2,0,0,1,213.4,210Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M223.6,199.8,160,93.8V40h8a8,8,0,0,0,0-16H88a8,8,0,0,0,0,16h8V93.8L55.8,160.7c0,.1-.1.2-.1.3L32.4,199.8A16,16,0,0,0,46.1,224H209.9a16,16,0,0,0,13.7-24.2ZM109.7,102a15.9,15.9,0,0,0,2.3-8.2V40h32V93.8a15.9,15.9,0,0,0,2.3,8.2l38.8,64.8c-13.1,7.1-31.4,5.1-53.5-6-14.3-7.1-32.7-13.5-50.3-11.4ZM46.1,208l22.5-37.4c13.2-8.7,32.4-7.1,55.8,4.6,17.3,8.6,31.5,11.8,43.2,11.8a51.4,51.4,0,0,0,25.8-6.5L209.9,208Z" />
        </>
      )}
    </svg>
  );
}

export function PhFloppyDiskBack(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M208,28H91.3a20,20,0,0,0-14.1,5.8L33.9,77.2A19.7,19.7,0,0,0,28,91.3V208a20.1,20.1,0,0,0,20,20H208a20.1,20.1,0,0,0,20-20V48A20.1,20.1,0,0,0,208,28Zm-4,176H52V93L93,52h55V68H104a12,12,0,0,0,0,24h56a12,12,0,0,0,12-12V52h32Zm-76-96a40,40,0,1,0,40,40A40,40,0,0,0,128,108Zm0,56a16,16,0,1,1,16-16A16,16,0,0,1,128,164Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M208,40H91.3a7.9,7.9,0,0,0-5.6,2.3L42.3,85.6A8.1,8.1,0,0,0,40,91.3V208a8,8,0,0,0,8,8H208a8,8,0,0,0,8-8V48A8,8,0,0,0,208,40ZM128,176a28,28,0,1,1,28-28A28,28,0,0,1,128,176Z"
            opacity="0.2"
          />
          <path d="M208,32H91.3A15.9,15.9,0,0,0,80,36.7L36.7,80A15.9,15.9,0,0,0,32,91.3V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32Zm0,176H48V91.3L91.3,48H152V72H96a8,8,0,0,0,0,16h64a8,8,0,0,0,8-8V48h40Zm-80-96a36,36,0,1,0,36,36A36.1,36.1,0,0,0,128,112Zm0,56a20,20,0,1,1,20-20A20.1,20.1,0,0,1,128,168Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M208,32H172a4,4,0,0,0-4,4V80a8,8,0,0,1-8,8H96.3A8.2,8.2,0,0,1,88,80.5,8,8,0,0,1,96,72h56V36a4,4,0,0,0-4-4H91.3A15.9,15.9,0,0,0,80,36.7L36.7,80A15.9,15.9,0,0,0,32,91.3V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM128,176a28,28,0,1,1,28-28A28.1,28.1,0,0,1,128,176Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M208,34H91.3a13.8,13.8,0,0,0-9.9,4.1L38.1,81.4A14.1,14.1,0,0,0,34,91.3V208a14,14,0,0,0,14,14H208a14,14,0,0,0,14-14V48A14,14,0,0,0,208,34Zm2,174a2,2,0,0,1-2,2H48a2,2,0,0,1-2-2V91.3a2,2,0,0,1,.6-1.4L89.9,46.6a2,2,0,0,1,1.4-.6H154V74H96a6,6,0,0,0,0,12h64a6,6,0,0,0,6-6V46h42a2,2,0,0,1,2,2Zm-82-94a34,34,0,1,0,34,34A34.1,34.1,0,0,0,128,114Zm0,56a22,22,0,1,1,22-22A22.1,22.1,0,0,1,128,170Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M208,36H91.3a11.9,11.9,0,0,0-8.5,3.5L39.5,82.8A11.9,11.9,0,0,0,36,91.3V208a12,12,0,0,0,12,12H208a12,12,0,0,0,12-12V48A12,12,0,0,0,208,36Zm4,172a4,4,0,0,1-4,4H48a4,4,0,0,1-4-4V91.3a3.6,3.6,0,0,1,1.2-2.8L88.5,45.2A3.6,3.6,0,0,1,91.3,44H156V76H96a4,4,0,0,0,0,8h64a4,4,0,0,0,4-4V44h44a4,4,0,0,1,4,4Zm-84-92a32,32,0,1,0,32,32A32.1,32.1,0,0,0,128,116Zm0,56a24,24,0,1,1,24-24A24.1,24.1,0,0,1,128,172Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M208,32H91.3A15.9,15.9,0,0,0,80,36.7L36.7,80A15.9,15.9,0,0,0,32,91.3V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32Zm0,176H48V91.3L91.3,48H152V72H96a8,8,0,0,0,0,16h64a8,8,0,0,0,8-8V48h40Zm-80-96a36,36,0,1,0,36,36A36.1,36.1,0,0,0,128,112Zm0,56a20,20,0,1,1,20-20A20.1,20.1,0,0,1,128,168Z" />
        </>
      )}
    </svg>
  );
}

export function PhFloppyDisk(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M222.1,77.2,178.8,33.9A19.7,19.7,0,0,0,164.7,28H48A20.1,20.1,0,0,0,28,48V208a20.1,20.1,0,0,0,20,20H208a20.1,20.1,0,0,0,20-20V91.3A19.7,19.7,0,0,0,222.1,77.2ZM164,204H92V156h72Zm40,0H188V152a20.1,20.1,0,0,0-20-20H88a20.1,20.1,0,0,0-20,20v52H52V52H163l41,41ZM164,80a12,12,0,0,1-12,12H96a12,12,0,0,1,0-24h56A12,12,0,0,1,164,80Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M216,91.3V208a8,8,0,0,1-8,8H176V152a8,8,0,0,0-8-8H88a8,8,0,0,0-8,8v64H48a8,8,0,0,1-8-8V48a8,8,0,0,1,8-8H164.7a7.9,7.9,0,0,1,5.6,2.3l43.4,43.3A8.1,8.1,0,0,1,216,91.3Z"
            opacity="0.2"
          />
          <path d="M219.3,80,176,36.7A15.9,15.9,0,0,0,164.7,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V91.3A15.9,15.9,0,0,0,219.3,80ZM168,208H88V152h80ZM208,91.3V208H184V152a16,16,0,0,0-16-16H88a16,16,0,0,0-16,16v56H48V48H164.7L208,91.3ZM160,72a8,8,0,0,1-8,8H96a8,8,0,0,1,0-16h56A8,8,0,0,1,160,72Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M219.3,80,176,36.7A15.9,15.9,0,0,0,164.7,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V91.3A15.9,15.9,0,0,0,219.3,80ZM208,91.3V208H184V152a16,16,0,0,0-16-16H88a16,16,0,0,0-16,16v56H48V48H164.7L208,91.3ZM160,72a8,8,0,0,1-8,8H96a8,8,0,0,1,0-16h56A8,8,0,0,1,160,72Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M217.9,81.4,174.6,38.1a13.8,13.8,0,0,0-9.9-4.1H48A14,14,0,0,0,34,48V208a14,14,0,0,0,14,14H208a14,14,0,0,0,14-14V91.3A14.1,14.1,0,0,0,217.9,81.4ZM170,210H86V152a2,2,0,0,1,2-2h80a2,2,0,0,1,2,2Zm40-2a2,2,0,0,1-2,2H182V152a14,14,0,0,0-14-14H88a14,14,0,0,0-14,14v58H48a2,2,0,0,1-2-2V48a2,2,0,0,1,2-2H164.7a2,2,0,0,1,1.4.6l43.3,43.3a2,2,0,0,1,.6,1.4ZM158,72a6,6,0,0,1-6,6H96a6,6,0,0,1,0-12h56A6,6,0,0,1,158,72Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M216.5,82.8,173.2,39.5a11.9,11.9,0,0,0-8.5-3.5H48A12,12,0,0,0,36,48V208a12,12,0,0,0,12,12H208a12,12,0,0,0,12-12V91.3A11.9,11.9,0,0,0,216.5,82.8ZM172,212H84V152a4,4,0,0,1,4-4h80a4,4,0,0,1,4,4Zm40-4a4,4,0,0,1-4,4H180V152a12,12,0,0,0-12-12H88a12,12,0,0,0-12,12v60H48a4,4,0,0,1-4-4V48a4,4,0,0,1,4-4H164.7a3.8,3.8,0,0,1,2.8,1.2l43.3,43.3a3.6,3.6,0,0,1,1.2,2.8ZM156,72a4,4,0,0,1-4,4H96a4,4,0,0,1,0-8h56A4,4,0,0,1,156,72Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M219.3,80,176,36.7A15.9,15.9,0,0,0,164.7,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V91.3A15.9,15.9,0,0,0,219.3,80ZM168,208H88V152h80ZM208,91.3V208H184V152a16,16,0,0,0-16-16H88a16,16,0,0,0-16,16v56H48V48H164.7L208,91.3ZM160,72a8,8,0,0,1-8,8H96a8,8,0,0,1,0-16h56A8,8,0,0,1,160,72Z" />
        </>
      )}
    </svg>
  );
}

export function PhFlowArrow(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M248.5,71.5l-40-40a12,12,0,0,0-17,17L211,68H176a60,60,0,0,0-60,60,36,36,0,0,1-33.9,35.9,39.9,39.9,0,1,0,.1,24A59.9,59.9,0,0,0,140,128a36,36,0,0,1,36-36h35l-19.5,19.5a12,12,0,0,0,0,17,12.1,12.1,0,0,0,17,0l40-40A12,12,0,0,0,248.5,71.5ZM44,192a16,16,0,1,1,16-16A16,16,0,0,1,44,192Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M72,176a28,28,0,1,1-28-28A28,28,0,0,1,72,176Z"
            opacity="0.2"
          />
          <path d="M245.7,74.3l-40-40a8.1,8.1,0,0,0-11.4,11.4L220.7,72H176a56,56,0,0,0-56,56,40,40,0,0,1-40,40h-.9a36,36,0,1,0,0,16H80a56,56,0,0,0,56-56,40,40,0,0,1,40-40h44.7l-26.4,26.3a8.1,8.1,0,0,0,0,11.4,8.2,8.2,0,0,0,11.4,0l40-40A8.1,8.1,0,0,0,245.7,74.3ZM44,196a20,20,0,1,1,20-20A20.1,20.1,0,0,1,44,196Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M245.7,85.7l-40,40a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L220.7,88H176a40,40,0,0,0-40,40,56,56,0,0,1-56,56h-.9a36,36,0,1,1,0-16H80a40,40,0,0,0,40-40,56,56,0,0,1,56-56h44.7L194.3,45.7a8.1,8.1,0,0,1,11.4-11.4l40,40A8.1,8.1,0,0,1,245.7,85.7Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M244.2,75.8l-40-40a5.9,5.9,0,0,0-8.4,8.4L225.5,74H176a54,54,0,0,0-54,54,42,42,0,0,1-42,42H77.5a34,34,0,1,0,0,12H80a54,54,0,0,0,54-54,42,42,0,0,1,42-42h49.5l-29.7,29.8a5.9,5.9,0,1,0,8.4,8.4l40-40A5.8,5.8,0,0,0,244.2,75.8ZM44,198a22,22,0,1,1,22-22A22.1,22.1,0,0,1,44,198Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M242.8,77.2l-40-40a4,4,0,0,0-5.6,5.6L230.3,76H176a52,52,0,0,0-52,52,44,44,0,0,1-44,44H75.7a32,32,0,1,0,0,8H80a52,52,0,0,0,52-52,44,44,0,0,1,44-44h54.3l-33.1,33.2a3.9,3.9,0,0,0,0,5.6,3.9,3.9,0,0,0,5.6,0l40-40A3.9,3.9,0,0,0,242.8,77.2ZM44,200a24,24,0,1,1,24-24A24.1,24.1,0,0,1,44,200Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M245.7,74.3l-40-40a8.1,8.1,0,0,0-11.4,11.4L220.7,72H176a56,56,0,0,0-56,56,40,40,0,0,1-40,40h-.9a36,36,0,1,0,0,16H80a56,56,0,0,0,56-56,40,40,0,0,1,40-40h44.7l-26.4,26.3a8.1,8.1,0,0,0,0,11.4,8.2,8.2,0,0,0,11.4,0l40-40A8.1,8.1,0,0,0,245.7,74.3ZM44,196a20,20,0,1,1,20-20A20.1,20.1,0,0,1,44,196Z" />
        </>
      )}
    </svg>
  );
}

export function PhFlowerLotus(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M253.3,119.3a20.1,20.1,0,0,0-12.3-9.4,84.7,84.7,0,0,0-23.6-2.3,88.5,88.5,0,0,0-3.5-37.7,19.9,19.9,0,0,0-23.1-13.4,84.1,84.1,0,0,0-23.7,9.1,98.7,98.7,0,0,0-27.1-32,19.8,19.8,0,0,0-24,0,98.7,98.7,0,0,0-27.1,32,84.1,84.1,0,0,0-23.7-9.1A19.9,19.9,0,0,0,42.1,69.9a88.5,88.5,0,0,0-3.5,37.7A84.7,84.7,0,0,0,15,109.9,20.1,20.1,0,0,0,.7,134.6c3.7,13.3,15,38.4,49,58S112.3,212,127.9,212h.2c15.6,0,44.7,0,78.2-19.4s45.3-44.7,49-58A20,20,0,0,0,253.3,119.3ZM192.2,80.9c2,8.3,3.3,21.1-1.4,37l-.3.9a99.1,99.1,0,0,1-9.2,20.8,66.5,66.5,0,0,1-3.9,6.3A128.3,128.3,0,0,0,180,120a122.3,122.3,0,0,0-4-31.8A61.5,61.5,0,0,1,192.2,80.9ZM128,54.7c10,8.2,28,28.3,28,65.3s-18.3,57.4-28,65.4c-9.7-8-28-28-28-65.4S118,62.9,128,54.7ZM63.8,80.9A61.5,61.5,0,0,1,80,88.2,122.3,122.3,0,0,0,76,120a128.3,128.3,0,0,0,2.6,25.9,66.5,66.5,0,0,1-3.9-6.3,99.1,99.1,0,0,1-9.2-20.8l-.3-.9C60.5,102,61.8,89.2,63.8,80.9ZM25.2,132.3a65.7,65.7,0,0,1,19.5-.1,124.8,124.8,0,0,0,9.2,19.4,136.2,136.2,0,0,0,19.8,26.3q-5.9-2.6-12-6C38.5,158.4,29,142.6,25.2,132.3Zm169.1,39.6c-4.1,2.3-8.1,4.3-11.9,6a137.6,137.6,0,0,0,19.7-26.3,124.8,124.8,0,0,0,9.2-19.4,65.7,65.7,0,0,1,19.5.1C227,142.6,217.5,158.4,194.3,171.9Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M128,200c-16,0-42.5-.2-72.9-17.8s-40.3-39.5-43.4-50.8a7.9,7.9,0,0,1,5.7-9.8c7.3-1.9,20.1-3.6,36.5.2-6.7-22.2-3.3-39.5-.4-48.2a7.9,7.9,0,0,1,9.3-5.3A77.5,77.5,0,0,1,94.1,83.4h0A106,106,0,0,0,88,120C88,178.2,128,200,128,200Zm110.6-78.4c-7.3-1.9-20.1-3.6-36.5.2,6.7-22.2,3.3-39.5.4-48.2a7.9,7.9,0,0,0-9.3-5.3,77.5,77.5,0,0,0-31.3,15.1h0A106,106,0,0,1,168,120c0,58.2-40,80-40,80,16,0,42.5-.2,72.9-17.8s40.3-39.5,43.4-50.8A7.9,7.9,0,0,0,238.6,121.6Z"
            opacity="0.2"
          />
          <path d="M250.5,121.3a15.9,15.9,0,0,0-9.9-7.5,77.1,77.1,0,0,0-27.9-1.8,86.7,86.7,0,0,0-2.6-40.9,15.9,15.9,0,0,0-18.5-10.7,82.3,82.3,0,0,0-26.2,10.8,94.4,94.4,0,0,0-27.8-34.4,15.9,15.9,0,0,0-19.2,0A94.4,94.4,0,0,0,90.6,71.2,82.3,82.3,0,0,0,64.4,60.4,15.9,15.9,0,0,0,45.9,71.1,86.7,86.7,0,0,0,43.3,112a77.1,77.1,0,0,0-27.9,1.8A15.9,15.9,0,0,0,4,133.6c3.5,12.6,14.3,36.7,47.1,55.6S112.5,208,127.9,208h.2c15.4,0,44.2,0,76.8-18.8s43.6-43,47.1-55.6A16,16,0,0,0,250.5,121.3ZM194.9,76.1c2.8,8.4,5.4,23.5-.3,42.9l-.3,1.1a100.6,100.6,0,0,1-9.5,21.5,120.3,120.3,0,0,1-17.4,23.1c5.1-12,8.6-26.7,8.6-44.7a116.4,116.4,0,0,0-4.7-33.6A66.6,66.6,0,0,1,194.9,76.1ZM128,49.6c9.6,7.2,32,28.4,32,70.4s-22.7,63.6-32,70.4c-9.3-6.8-32-27.9-32-70.4S118.4,56.8,128,49.6ZM61.1,76.1A66.6,66.6,0,0,1,84.7,86.4,116.4,116.4,0,0,0,80,120c0,18,3.5,32.7,8.6,44.7a120.3,120.3,0,0,1-17.4-23.1A99.1,99.1,0,0,1,61.7,120l-.3-.9C55.6,99.6,58.3,84.5,61.1,76.1ZM19.3,129.3c6.2-1.6,15.9-2.8,28.4-.6a117.1,117.1,0,0,0,9.7,20.9,137.9,137.9,0,0,0,33.2,38.6,123.4,123.4,0,0,1-31.5-12.9C31.3,159.2,22.3,139.5,19.3,129.3Zm177.6,46a123.4,123.4,0,0,1-31.5,12.9,137.9,137.9,0,0,0,33.2-38.6,117.1,117.1,0,0,0,9.7-20.9,68.8,68.8,0,0,1,28.3.5C233.7,139.5,224.7,159.2,196.9,175.3Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M250.5,121.3a15.9,15.9,0,0,0-9.9-7.5,77.1,77.1,0,0,0-27.9-1.8,86.7,86.7,0,0,0-2.6-40.9,15.9,15.9,0,0,0-18.5-10.7,82.3,82.3,0,0,0-26.2,10.8,94.4,94.4,0,0,0-27.8-34.4,15.9,15.9,0,0,0-19.2,0A94.4,94.4,0,0,0,90.6,71.2,82.3,82.3,0,0,0,64.4,60.4,15.9,15.9,0,0,0,45.9,71.1,86.7,86.7,0,0,0,43.3,112a77.1,77.1,0,0,0-27.9,1.8A15.9,15.9,0,0,0,4,133.6c3.5,12.6,14.3,36.7,47.1,55.6S112.5,208,127.9,208h.2c15.4,0,44.2,0,76.8-18.8s43.6-43,47.1-55.6A16,16,0,0,0,250.5,121.3ZM128,49.6c9.6,7.2,32,28.4,32,70.4s-22.4,63.2-32,70.4h0c-9.6-7.2-32-28.4-32-70.4S118.4,56.8,128,49.6ZM19.3,129.3c6.2-1.6,15.9-2.8,28.4-.6a117.1,117.1,0,0,0,9.7,20.9,137.9,137.9,0,0,0,33.2,38.6,123.4,123.4,0,0,1-31.5-12.9C31.3,159.2,22.3,139.5,19.3,129.3Zm177.6,46a123.4,123.4,0,0,1-31.5,12.9,137.9,137.9,0,0,0,33.2-38.6,117.1,117.1,0,0,0,9.7-20.9,68.8,68.8,0,0,1,28.3.5C233.7,139.5,224.7,159.2,196.9,175.3Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M248.7,122.3a13.2,13.2,0,0,0-8.6-6.5,77.6,77.6,0,0,0-29.9-1.5c3.8-18.9.9-33.7-2-42.5A13.9,13.9,0,0,0,192,62.4a80.7,80.7,0,0,0-27.4,11.7,92.7,92.7,0,0,0-28.2-35.7,14.1,14.1,0,0,0-16.8,0A92.7,92.7,0,0,0,91.4,74.1,80.7,80.7,0,0,0,64,62.4a13.9,13.9,0,0,0-16.2,9.4c-2.9,8.8-5.8,23.6-2,42.5a77.6,77.6,0,0,0-29.9,1.5,13.2,13.2,0,0,0-8.6,6.5A13.9,13.9,0,0,0,5.9,133c3.5,12.4,14.1,35.9,46.2,54.4S112.7,206,128,206h0c15.3,0,43.8,0,75.9-18.6s42.7-42,46.2-54.4A13.9,13.9,0,0,0,248.7,122.3ZM194.5,74.1a2,2,0,0,1,2.3,1.4c2.9,8.7,5.6,24.2-.3,44.2a2.9,2.9,0,0,0-.2.9,111.4,111.4,0,0,1-9.8,22,124.2,124.2,0,0,1-25.8,31.2C168.1,160.7,174,143,174,120a116.7,116.7,0,0,0-5-34.4A70.8,70.8,0,0,1,194.5,74.1ZM126.8,48a2,2,0,0,1,2.4,0c9.9,7.4,32.8,29.1,32.8,72,0,45.3-25.2,66.8-34,72.9-8.6-6.1-34-27.9-34-72.9C94,77.1,116.9,55.4,126.8,48ZM59.2,75.5a2,2,0,0,1,2.3-1.4A70.8,70.8,0,0,1,87,85.6,116.7,116.7,0,0,0,82,120c0,23,5.9,40.7,13.3,53.8a124.2,124.2,0,0,1-25.8-31.2,108.3,108.3,0,0,1-9.8-22,2.9,2.9,0,0,0-.2-.9C53.6,99.7,56.3,84.2,59.2,75.5ZM17.5,129.8a2.3,2.3,0,0,1,.1-1.5,1.9,1.9,0,0,1,1.2-.9c6.6-1.7,17-3,30.4-.4a116.4,116.4,0,0,0,9.9,21.6A135.7,135.7,0,0,0,99,192a122.8,122.8,0,0,1-40.9-14.9C29.6,160.6,20.4,140.4,17.5,129.8Zm221,0c-2.9,10.6-12.1,30.8-40.6,47.3A122.8,122.8,0,0,1,157,192a135.7,135.7,0,0,0,39.9-43.4,116.4,116.4,0,0,0,9.9-21.6c13.4-2.6,23.8-1.3,30.4.4a1.9,1.9,0,0,1,1.2.9A2.3,2.3,0,0,1,238.5,129.8Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M247,123.3a11.7,11.7,0,0,0-7.4-5.6,77.8,77.8,0,0,0-31.9-1c4.5-19.8,1.6-35.3-1.4-44.3a12,12,0,0,0-13.9-8.1,80.3,80.3,0,0,0-28.7,12.8A91,91,0,0,0,135.2,40a12,12,0,0,0-14.4,0A91,91,0,0,0,92.3,77.1,80.3,80.3,0,0,0,63.6,64.3a12,12,0,0,0-13.9,8.1c-3,9-5.9,24.5-1.4,44.3a77.8,77.8,0,0,0-31.9,1A11.7,11.7,0,0,0,9,123.3a12.3,12.3,0,0,0-1.2,9.2c3.4,12,13.8,35,45.3,53.2S112.9,204,128,204h0c15.1,0,43.2,0,74.9-18.3s41.9-41.2,45.3-53.2A12.3,12.3,0,0,0,247,123.3ZM194.1,72.2a3.9,3.9,0,0,1,4.6,2.6c2.9,9,5.8,25-.3,45.6-.1.2-.1.4-.2.6a101.2,101.2,0,0,1-10,22.6,128,128,0,0,1-33.5,37.7l-2.5,1.8C162.3,169.8,172,149.4,172,120a111.3,111.3,0,0,0-5.4-35.2A71.6,71.6,0,0,1,194.1,72.2ZM125.6,46.4a4,4,0,0,1,4.8,0C140.5,54,164,76.2,164,120c0,23.1-6.4,42.9-18.9,59A75,75,0,0,1,128,195.3,75,75,0,0,1,110.9,179C98.4,162.9,92,143.1,92,120,92,76.2,115.5,54,125.6,46.4ZM57.3,74.8a3.9,3.9,0,0,1,4.6-2.6A71.6,71.6,0,0,1,89.4,84.8,111.3,111.3,0,0,0,84,120c0,29.4,9.7,49.8,19.8,63.1l-2.5-1.8a128,128,0,0,1-33.5-37.7,101.8,101.8,0,0,1-10-22.7l-.2-.5C51.5,99.8,54.4,83.8,57.3,74.8ZM15.5,130.3a3.9,3.9,0,0,1,.4-3,4.5,4.5,0,0,1,2.4-1.9,72.9,72.9,0,0,1,32.4-.1,119.4,119.4,0,0,0,10.1,22.3C73.7,170,92.5,186,107.7,195.2a124.2,124.2,0,0,1-50.6-16.4C28,162,18.6,141.2,15.5,130.3Zm225,0c-3.1,10.9-12.5,31.7-41.6,48.5a124.2,124.2,0,0,1-50.6,16.4c15.2-9.2,34-25.2,46.9-47.6a119.4,119.4,0,0,0,10.1-22.3,72.9,72.9,0,0,1,32.4.1,4.5,4.5,0,0,1,2.4,1.9A3.9,3.9,0,0,1,240.5,130.3Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M250.5,121.3a15.9,15.9,0,0,0-9.9-7.5,77.1,77.1,0,0,0-27.9-1.8,86.7,86.7,0,0,0-2.6-40.9,15.9,15.9,0,0,0-18.5-10.7,82.3,82.3,0,0,0-26.2,10.8,94.4,94.4,0,0,0-27.8-34.4,15.9,15.9,0,0,0-19.2,0A94.4,94.4,0,0,0,90.6,71.2,82.3,82.3,0,0,0,64.4,60.4,15.9,15.9,0,0,0,45.9,71.1,86.7,86.7,0,0,0,43.3,112a77.1,77.1,0,0,0-27.9,1.8A15.9,15.9,0,0,0,4,133.6c3.5,12.6,14.3,36.7,47.1,55.6S112.5,208,127.9,208h.2c15.4,0,44.2,0,76.8-18.8s43.6-43,47.1-55.6A16,16,0,0,0,250.5,121.3ZM194.9,76.1c2.8,8.4,5.4,23.5-.3,42.9l-.3,1.1a100.6,100.6,0,0,1-9.5,21.5,120.3,120.3,0,0,1-17.4,23.1c5.1-12,8.6-26.7,8.6-44.7a116.4,116.4,0,0,0-4.7-33.6A66.6,66.6,0,0,1,194.9,76.1ZM128,49.6c9.6,7.2,32,28.4,32,70.4s-22.7,63.6-32,70.4c-9.3-6.8-32-27.9-32-70.4S118.4,56.8,128,49.6ZM61.1,76.1A66.6,66.6,0,0,1,84.7,86.4,116.4,116.4,0,0,0,80,120c0,18,3.5,32.7,8.6,44.7a120.3,120.3,0,0,1-17.4-23.1A99.1,99.1,0,0,1,61.7,120l-.3-.9C55.6,99.6,58.3,84.5,61.1,76.1ZM19.3,129.3c6.2-1.6,15.9-2.8,28.4-.6a117.1,117.1,0,0,0,9.7,20.9,137.9,137.9,0,0,0,33.2,38.6,123.4,123.4,0,0,1-31.5-12.9C31.3,159.2,22.3,139.5,19.3,129.3Zm177.6,46a123.4,123.4,0,0,1-31.5,12.9,137.9,137.9,0,0,0,33.2-38.6,117.1,117.1,0,0,0,9.7-20.9,68.8,68.8,0,0,1,28.3.5C233.7,139.5,224.7,159.2,196.9,175.3Z" />
        </>
      )}
    </svg>
  );
}

export function PhFlower(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M210.4,129.4l-2.6-1.4,2.6-1.4a40,40,0,0,0-40-69.2,12.8,12.8,0,0,0-2.5,1.5c.1-1,.1-2,.1-2.9a40,40,0,0,0-80,0c0,.9,0,1.9.1,2.9a20.7,20.7,0,0,0-2.5-1.5,40,40,0,1,0-40,69.2l2.6,1.4-2.6,1.4A40,40,0,0,0,65.7,204a38.7,38.7,0,0,0,19.9-5.4,12.8,12.8,0,0,0,2.5-1.5c-.1,1-.1,2-.1,2.9a40,40,0,0,0,80,0c0-.9,0-1.9-.1-2.9a20.7,20.7,0,0,0,2.5,1.5,38.7,38.7,0,0,0,19.9,5.4,40,40,0,0,0,20.1-74.6ZM112,128a16,16,0,1,1,16,16A16,16,0,0,1,112,128Zm70.4-49.9a16,16,0,0,1,16,27.8c-3.6,2-12.5,5.6-33,8a36.9,36.9,0,0,0-6.5-11.2C171.2,86.1,178.7,80.2,182.4,78.1ZM128,40a16,16,0,0,1,16,16c0,4.2-1.4,13.6-9.5,32.5a42.5,42.5,0,0,0-13,0C113.4,69.6,112,60.2,112,56A16,16,0,0,1,128,40ZM51.8,84a16,16,0,0,1,21.8-5.9c3.7,2.1,11.2,8,23.5,24.6a36.9,36.9,0,0,0-6.5,11.2c-20.5-2.4-29.4-6-33-8A16.1,16.1,0,0,1,51.8,84Zm21.8,93.9a16,16,0,0,1-16-27.8c3.6-2,12.5-5.6,33-8a36.9,36.9,0,0,0,6.5,11.2C84.8,169.9,77.3,175.8,73.6,177.9ZM128,216a16,16,0,0,1-16-16c0-4.2,1.4-13.6,9.5-32.5a42.5,42.5,0,0,0,13,0c8.1,18.9,9.5,28.3,9.5,32.5A16,16,0,0,1,128,216Zm76.2-44a16,16,0,0,1-21.8,5.9c-3.7-2.1-11.2-8-23.5-24.6a36.9,36.9,0,0,0,6.5-11.2c20.5,2.4,29.4,6,33,8A16.1,16.1,0,0,1,204.2,172Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M115,103.2c-7.3-15.4-15-34.6-15-47.2a28,28,0,0,1,56,0c0,12.6-7.7,31.8-15,47.2a28,28,0,0,0-26,0Zm26,0Zm-41,23.6Zm13-22.4ZM51.6,116.2c10.9,6.3,31.4,9.3,48.4,10.6a27.8,27.8,0,0,1,3.8-12.8,26.5,26.5,0,0,1,9.2-9.6C103.3,90.3,90.5,74,79.6,67.8a28,28,0,0,0-28,48.4ZM103.8,142a27.8,27.8,0,0,1-3.8-12.8c-17,1.3-37.5,4.3-48.4,10.6a28,28,0,1,0,28,48.4c10.9-6.2,23.7-22.5,33.4-36.6A26.5,26.5,0,0,1,103.8,142ZM115,152.8Zm26,0ZM128,156a27.2,27.2,0,0,1-13-3.2c-7.3,15.4-15,34.6-15,47.2a28,28,0,0,0,56,0c0-12.6-7.7-31.8-15-47.2A27.2,27.2,0,0,1,128,156Zm76.4-16.2c-10.9-6.3-31.4-9.3-48.4-10.6a27.8,27.8,0,0,1-3.8,12.8,26.5,26.5,0,0,1-9.2,9.6c9.7,14.1,22.5,30.4,33.4,36.6a28,28,0,0,0,28-48.4Zm-48.4-13Zm-13-22.4Zm9.2,9.6a27.8,27.8,0,0,1,3.8,12.8c17-1.3,37.5-4.3,48.4-10.6a28,28,0,0,0-28-48.4C165.5,74,152.7,90.3,143,104.4A26.5,26.5,0,0,1,152.2,114Z"
            opacity="0.2"
          />
          <path d="M208.4,132.8a56.9,56.9,0,0,0-11.5-4.8,56.9,56.9,0,0,0,11.5-4.8,36,36,0,0,0-36-62.4,58.7,58.7,0,0,0-9.9,7.5A58.6,58.6,0,0,0,164,56a36,36,0,0,0-72,0,58.6,58.6,0,0,0,1.5,12.3,58.7,58.7,0,0,0-9.9-7.5,36,36,0,0,0-36,62.4A56.9,56.9,0,0,0,59.1,128a56.9,56.9,0,0,0-11.5,4.8,36,36,0,0,0,36,62.4,58.7,58.7,0,0,0,9.9-7.5A58.6,58.6,0,0,0,92,200a36,36,0,0,0,72,0,58.6,58.6,0,0,0-1.5-12.3,58.7,58.7,0,0,0,9.9,7.5,36,36,0,0,0,36-62.4ZM108,128a20,20,0,1,1,20,20A20.1,20.1,0,0,1,108,128Zm72.4-53.3a20,20,0,0,1,20,34.6c-6.8,3.9-20.1,7-37.8,8.9a36.2,36.2,0,0,0-8.8-15.3C164.3,88.5,173.6,78.6,180.4,74.7ZM128,36a20.1,20.1,0,0,1,20,20c0,7.8-3.9,20.9-11.2,37.1a35.8,35.8,0,0,0-17.6,0C111.9,76.9,108,63.8,108,56A20.1,20.1,0,0,1,128,36ZM48.3,82a20,20,0,0,1,27.3-7.3c6.8,3.9,16.1,13.8,26.6,28.2a36.2,36.2,0,0,0-8.8,15.3c-17.7-1.9-31-5-37.8-8.9A20,20,0,0,1,48.3,82Zm27.3,99.3a20,20,0,0,1-20-34.6c6.8-3.9,20.1-7,37.8-8.9a36.2,36.2,0,0,0,8.8,15.3C91.7,167.5,82.4,177.4,75.6,181.3ZM128,220a20.1,20.1,0,0,1-20-20c0-7.8,3.9-20.9,11.2-37.1a35.8,35.8,0,0,0,17.6,0c7.3,16.2,11.2,29.3,11.2,37.1A20.1,20.1,0,0,1,128,220Zm79.7-46a20.1,20.1,0,0,1-27.3,7.3c-6.8-3.9-16.1-13.8-26.6-28.2a36.2,36.2,0,0,0,8.8-15.3c17.7,1.9,31,5,37.8,8.9A20,20,0,0,1,207.7,174Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M208.4,132.8a56.9,56.9,0,0,0-11.5-4.8,56.9,56.9,0,0,0,11.5-4.8,36,36,0,0,0-36-62.4,58.7,58.7,0,0,0-9.9,7.5A58.6,58.6,0,0,0,164,56a36,36,0,0,0-72,0,58.6,58.6,0,0,0,1.5,12.3,58.7,58.7,0,0,0-9.9-7.5,36,36,0,0,0-36,62.4A56.9,56.9,0,0,0,59.1,128a56.9,56.9,0,0,0-11.5,4.8,36,36,0,0,0,36,62.4,58.7,58.7,0,0,0,9.9-7.5A58.6,58.6,0,0,0,92,200a36,36,0,0,0,72,0,58.6,58.6,0,0,0-1.5-12.3,58.7,58.7,0,0,0,9.9,7.5,36,36,0,0,0,36-62.4ZM128,152a24,24,0,1,1,24-24A24.1,24.1,0,0,1,128,152Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M207.4,134.6q-6.7-3.9-18-6.6,11.3-2.7,18-6.6a34,34,0,1,0-34-58.8c-4.5,2.5-9.4,6.6-14.7,12.2,2.2-7.4,3.3-13.7,3.3-18.8a34,34,0,0,0-68,0c0,5.1,1.1,11.4,3.3,18.8C92,69.2,87.1,65.1,82.6,62.6a34,34,0,1,0-34,58.8q6.8,3.9,18,6.6-11.2,2.7-18,6.6A34,34,0,0,0,65.7,198a33.3,33.3,0,0,0,16.9-4.6c4.5-2.5,9.4-6.6,14.7-12.2C95.1,188.6,94,194.9,94,200a34,34,0,0,0,68,0c0-5.1-1.1-11.4-3.3-18.8,5.3,5.6,10.2,9.7,14.7,12.2a33.3,33.3,0,0,0,16.9,4.6,34,34,0,0,0,17.1-63.4ZM106,128a22,22,0,1,1,22,22A22.1,22.1,0,0,1,106,128Zm73.4-55.1a22,22,0,0,1,22,38.2c-7.4,4.2-21.3,7.4-40.3,9.2a33.6,33.6,0,0,0-9.9-17.1C162.3,87.6,172,77.2,179.4,72.9ZM128,34a22.1,22.1,0,0,1,22,22c0,8.5-4.2,22.1-12.1,39.5a33.4,33.4,0,0,0-19.8,0C110.2,78.1,106,64.5,106,56A22.1,22.1,0,0,1,128,34ZM46.6,81a22.1,22.1,0,0,1,30-8.1c7.4,4.3,17.1,14.7,28.2,30.3a33.6,33.6,0,0,0-9.9,17.1c-19-1.8-32.9-5-40.3-9.2A22,22,0,0,1,46.6,81Zm30,102.1a22,22,0,0,1-22-38.2c7.4-4.2,21.3-7.4,40.3-9.2a33.6,33.6,0,0,0,9.9,17.1C93.7,168.4,84,178.8,76.6,183.1ZM128,222a22.1,22.1,0,0,1-22-22c0-8.5,4.2-22.1,12.1-39.5a33.4,33.4,0,0,0,19.8,0c7.9,17.4,12.1,31,12.1,39.5A22.1,22.1,0,0,1,128,222Zm81.4-47a22.1,22.1,0,0,1-30,8.1c-7.4-4.3-17.1-14.7-28.2-30.3a33.6,33.6,0,0,0,9.9-17.1c19,1.8,32.9,5,40.3,9.2A22,22,0,0,1,209.4,175Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M206.4,136.3c-6.1-3.5-15-6.3-26.9-8.3,11.9-2,20.8-4.8,26.9-8.3a32,32,0,0,0-32-55.4c-6.1,3.5-13,9.9-20.6,19.1C157.9,72.1,160,62.9,160,56a32,32,0,0,0-64,0c0,6.9,2.1,16.1,6.2,27.4-7.6-9.2-14.5-15.6-20.6-19.1a32,32,0,0,0-32,55.4c6.1,3.5,15,6.3,26.9,8.3-11.9,2-20.8,4.8-26.9,8.3A32,32,0,0,0,65.7,196a31.7,31.7,0,0,0,15.9-4.3c6.1-3.5,13-9.9,20.6-19.1C98.1,183.9,96,193.1,96,200a32,32,0,0,0,64,0c0-6.9-2.1-16.1-6.2-27.4,7.6,9.2,14.5,15.6,20.6,19.1a31.7,31.7,0,0,0,15.9,4.3,32,32,0,0,0,16.1-59.7ZM128,152a24,24,0,1,1,24-24A24.1,24.1,0,0,1,128,152Zm50.4-80.8a24,24,0,0,1,24,41.6c-7.9,4.5-22.6,7.8-42.9,9.7a31.5,31.5,0,0,0-11-19C160.2,86.9,170.5,75.7,178.4,71.2ZM128,32a24.1,24.1,0,0,1,24,24c0,9-4.5,23.5-13,42a31.3,31.3,0,0,0-22,0c-8.5-18.5-13-33-13-42A24.1,24.1,0,0,1,128,32ZM44.9,80a23.9,23.9,0,0,1,32.7-8.8c7.9,4.5,18.2,15.7,29.9,32.3a31.5,31.5,0,0,0-11,19c-20.3-1.9-35-5.2-42.9-9.7A24,24,0,0,1,44.9,80ZM77.6,184.8a24,24,0,0,1-24-41.6c7.9-4.5,22.6-7.8,42.9-9.7a31.5,31.5,0,0,0,11,19C95.8,169.1,85.5,180.3,77.6,184.8ZM128,224a24.1,24.1,0,0,1-24-24c0-9,4.5-23.5,13-42a31.3,31.3,0,0,0,22,0c8.5,18.5,13,33,13,42A24.1,24.1,0,0,1,128,224Zm83.1-48a23.9,23.9,0,0,1-32.7,8.8c-7.9-4.5-18.2-15.7-29.9-32.3a31.5,31.5,0,0,0,11-19c20.3,1.9,35,5.2,42.9,9.7A24,24,0,0,1,211.1,176Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M208.4,132.8a56.9,56.9,0,0,0-11.5-4.8,56.9,56.9,0,0,0,11.5-4.8,36,36,0,0,0-36-62.4,58.7,58.7,0,0,0-9.9,7.5A58.6,58.6,0,0,0,164,56a36,36,0,0,0-72,0,58.6,58.6,0,0,0,1.5,12.3,58.7,58.7,0,0,0-9.9-7.5,36,36,0,0,0-36,62.4A56.9,56.9,0,0,0,59.1,128a56.9,56.9,0,0,0-11.5,4.8,36,36,0,0,0,36,62.4,58.7,58.7,0,0,0,9.9-7.5A58.6,58.6,0,0,0,92,200a36,36,0,0,0,72,0,58.6,58.6,0,0,0-1.5-12.3,58.7,58.7,0,0,0,9.9,7.5,36,36,0,0,0,36-62.4ZM108,128a20,20,0,1,1,20,20A20.1,20.1,0,0,1,108,128Zm72.4-53.3a20,20,0,0,1,20,34.6c-6.8,3.9-20.1,7-37.8,8.9a36.2,36.2,0,0,0-8.8-15.3C164.3,88.5,173.6,78.6,180.4,74.7ZM128,36a20.1,20.1,0,0,1,20,20c0,7.8-3.9,20.9-11.2,37.1a35.8,35.8,0,0,0-17.6,0C111.9,76.9,108,63.8,108,56A20.1,20.1,0,0,1,128,36ZM48.3,82a20,20,0,0,1,27.3-7.3c6.8,3.9,16.1,13.8,26.6,28.2a36.2,36.2,0,0,0-8.8,15.3c-17.7-1.9-31-5-37.8-8.9A20,20,0,0,1,48.3,82Zm27.3,99.3a20,20,0,0,1-20-34.6c6.8-3.9,20.1-7,37.8-8.9a36.2,36.2,0,0,0,8.8,15.3C91.7,167.5,82.4,177.4,75.6,181.3ZM128,220a20.1,20.1,0,0,1-20-20c0-7.8,3.9-20.9,11.2-37.1a35.8,35.8,0,0,0,17.6,0c7.3,16.2,11.2,29.3,11.2,37.1A20.1,20.1,0,0,1,128,220Zm79.7-46a20,20,0,0,1-27.3,7.3c-6.8-3.9-16.1-13.8-26.6-28.2a36.2,36.2,0,0,0,8.8-15.3c17.7,1.9,31,5,37.8,8.9A20,20,0,0,1,207.7,174Z" />
        </>
      )}
    </svg>
  );
}

export function PhFlyingSaucer(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M184.6,58.2a73.9,73.9,0,0,0-8.8-10.6A67.6,67.6,0,0,0,71.9,58.1C29.3,68,4,87.9,4,112c0,17.9,14.2,33.9,40.1,45,22.6,9.7,52.4,15,83.9,15s61.3-5.3,83.9-15c25.9-11.1,40.1-27.1,40.1-45C252,88,226.9,68.1,184.6,58.2ZM127.4,52h.6a44,44,0,0,1,44,44v3.9a4,4,0,0,1-3,3.8,181.1,181.1,0,0,1-41,4.3,181.1,181.1,0,0,1-41-4.3,4,4,0,0,1-3-3.8V96.8C84,72.4,103.5,52.3,127.4,52Zm.6,96c-61,0-100-21.3-100-36,0-6.7,7.7-12.8,12.3-15.9a97.9,97.9,0,0,1,20.5-9.9A75.9,75.9,0,0,0,60,96.9v3h0a27.9,27.9,0,0,0,21.7,27.2A204.3,204.3,0,0,0,128,132a204.3,204.3,0,0,0,46.3-4.9A27.9,27.9,0,0,0,196,99.9V96a62.4,62.4,0,0,0-.7-9.7,93.2,93.2,0,0,1,20.5,9.9c4.5,3,12.2,9.2,12.2,15.8C228,126.7,189,148,128,148Zm56.5,62.3a12,12,0,0,1-9.8,13.8l-2,.2a12,12,0,0,1-11.8-10.1L158.3,199a12,12,0,1,1,23.7-4ZM140,200v24a12,12,0,0,1-24,0V200a12,12,0,0,1,24,0Zm-42.3-1.1-2.6,15.3a12,12,0,0,1-11.8,10.1l-2-.2a12,12,0,0,1-9.8-13.8L74,195a12,12,0,0,1,23.7,3.9Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M240,112c0,26.5-50.1,48-112,48S16,138.5,16,112c0-19,25.9-35.5,63.4-43.2h0a56.8,56.8,0,0,0-7.4,28v3.1a15.8,15.8,0,0,0,12.4,15.5A191.4,191.4,0,0,0,128,120a191.4,191.4,0,0,0,43.6-4.6A15.8,15.8,0,0,0,184,99.9V96a56.7,56.7,0,0,0-7-27.2h0C214.3,76.6,240,93,240,112Z"
            opacity="0.2"
          />
          <path d="M180.6,211a8.1,8.1,0,0,1-6.6,9.2h-1.3a8.1,8.1,0,0,1-7.9-6.7l-3.9-23.5a8,8,0,0,1,15.8-2.7ZM128,184a8,8,0,0,0-8,8v32a8,8,0,0,0,16,0V192A8,8,0,0,0,128,184Zm-39.5-3.1a7.9,7.9,0,0,0-9.2,6.5L75.4,211a8.1,8.1,0,0,0,6.6,9.2h1.3a8.1,8.1,0,0,0,7.9-6.7l3.9-23.5A8,8,0,0,0,88.5,180.9ZM248,112c0,16.2-13.4,30.9-37.7,41.3C188.2,162.8,159,168,128,168s-60.2-5.2-82.3-14.7C21.4,142.9,8,128.2,8,112c0-8.4,3.7-20.8,21.2-32.5,11.3-7.6,26.9-13.8,45.2-17.9A64,64,0,0,1,127.2,32,63.3,63.3,0,0,1,173,50.5a64.7,64.7,0,0,1,9.1,11.2C223.4,71.1,248,89.7,248,112ZM80,96.8v3.1a7.8,7.8,0,0,0,6.1,7.7A189.1,189.1,0,0,0,128,112a189.1,189.1,0,0,0,41.9-4.4,7.8,7.8,0,0,0,6.1-7.7V96a47.5,47.5,0,0,0-6.1-23.4h0a51.7,51.7,0,0,0-8.2-10.7A47.4,47.4,0,0,0,127.4,48C101.2,48.3,80,70.2,80,96.8ZM232,112c0-11.7-16.6-23.9-41.9-31.6A62.3,62.3,0,0,1,192,96v3.9a23.9,23.9,0,0,1-18.6,23.3A202.1,202.1,0,0,1,128,128a202.1,202.1,0,0,1-45.4-4.8A23.9,23.9,0,0,1,64,99.9h0v-3a65.1,65.1,0,0,1,2.1-16.6C40.7,88,24,100.3,24,112c0,18.9,42.7,40,104,40S232,130.9,232,112Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M180.6,211a8.1,8.1,0,0,1-6.6,9.2h-1.3a8.1,8.1,0,0,1-7.9-6.7l-3.9-23.5a8,8,0,0,1,15.8-2.7ZM128,184a8,8,0,0,0-8,8v32a8,8,0,0,0,16,0V192A8,8,0,0,0,128,184Zm-39.5-3.1a7.9,7.9,0,0,0-9.2,6.5L75.4,211a8.1,8.1,0,0,0,6.6,9.2h1.3a8.1,8.1,0,0,0,7.9-6.7l3.9-23.5A8,8,0,0,0,88.5,180.9ZM248,112c0,16.2-13.4,30.9-37.7,41.3C188.2,162.8,159,168,128,168s-60.2-5.2-82.3-14.7C21.4,142.9,8,128.2,8,112c0-8.4,3.7-20.8,21.2-32.5,11.3-7.6,26.9-13.8,45.2-17.9A63.6,63.6,0,0,1,173,50.5a64.7,64.7,0,0,1,9.1,11.2C223.4,71.1,248,89.7,248,112ZM80,96.8v3.1a7.8,7.8,0,0,0,6.1,7.7A189.1,189.1,0,0,0,128,112a189.1,189.1,0,0,0,41.9-4.4,7.8,7.8,0,0,0,6.1-7.7V96a47.5,47.5,0,0,0-6.1-23.4h0a51.7,51.7,0,0,0-8.2-10.7A47.4,47.4,0,0,0,127.4,48C101.2,48.3,80,70.2,80,96.8Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M178.6,211.3a5.9,5.9,0,0,1-4.9,6.9h-1a6,6,0,0,1-5.9-5l-3.9-23.6a6,6,0,1,1,11.8-1.9ZM128,186a6,6,0,0,0-6,6v32a6,6,0,0,0,12,0V192A6,6,0,0,0,128,186Zm-39.8-3.2a6,6,0,0,0-6.9,5l-3.9,23.5a5.9,5.9,0,0,0,4.9,6.9h1a6,6,0,0,0,5.9-5l3.9-23.6A6,6,0,0,0,88.2,182.8ZM246,112c0,15.4-12.9,29.4-36.4,39.5C187.7,160.8,158.7,166,128,166s-59.7-5.2-81.6-14.5C22.9,141.4,10,127.4,10,112c0-21.2,25.1-39.6,65.6-48.6a61.7,61.7,0,0,1,96-11.5,64.3,64.3,0,0,1,9.2,11.6C221.1,72.5,246,90.9,246,112ZM78,96.8v3.1a10,10,0,0,0,7.7,9.7A192.1,192.1,0,0,0,128,114a192.1,192.1,0,0,0,42.3-4.4,10,10,0,0,0,7.7-9.7V96a50.3,50.3,0,0,0-6.2-24.2l-.2-.2a48,48,0,0,0-8.5-11.2A49.6,49.6,0,0,0,128,46h-.7C100.1,46.4,78,69.2,78,96.8ZM234,112c0-13.3-18.4-26.6-46.8-34.5A63.3,63.3,0,0,1,190,96v3.9a21.8,21.8,0,0,1-17,21.4,204.5,204.5,0,0,1-45,4.7,204.5,204.5,0,0,1-45-4.7A21.8,21.8,0,0,1,66,99.9h0v-3a62.7,62.7,0,0,1,3.1-19.5C40.5,85.3,22,98.6,22,112c0,10.1,10.6,20.5,29.2,28.4C71.6,149.2,98.9,154,128,154s56.4-4.8,76.8-13.6C223.4,132.5,234,122.1,234,112Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M176.7,211.6a4.1,4.1,0,0,1-3.3,4.6h-.7a4,4,0,0,1-3.9-3.4l-4-23.5a4.1,4.1,0,0,1,3.3-4.6,3.9,3.9,0,0,1,4.6,3.3ZM128,188a4,4,0,0,0-4,4v32a4,4,0,0,0,8,0V192A4,4,0,0,0,128,188Zm-40.1-3.2a3.9,3.9,0,0,0-4.6,3.3l-4,23.5a4.1,4.1,0,0,0,3.3,4.6h.7a4,4,0,0,0,3.9-3.4l4-23.5A4.1,4.1,0,0,0,87.9,184.8ZM244,112c0,29.2-51,52-116,52S12,141.2,12,112c0-20.3,24.8-38.2,64.8-46.8A60.2,60.2,0,0,1,127.2,36a59.5,59.5,0,0,1,42.9,17.3,60.4,60.4,0,0,1,9.5,12C219.4,74,244,91.8,244,112ZM76,96.8v3.1a11.8,11.8,0,0,0,9.2,11.6A191.2,191.2,0,0,0,128,116a189.7,189.7,0,0,0,42.7-4.5A11.8,11.8,0,0,0,180,99.9V96a52.1,52.1,0,0,0-52.7-52C99,44.4,76,68.1,76,96.8ZM236,112c0-15.1-20.1-29.4-51.9-37.4A61.1,61.1,0,0,1,188,96v3.9a19.8,19.8,0,0,1-15.5,19.4A199.9,199.9,0,0,1,128,124a199.9,199.9,0,0,1-44.5-4.7A19.8,19.8,0,0,1,68,99.9h0V96.8a61.4,61.4,0,0,1,4.2-22.3C40.2,82.5,20,96.9,20,112c0,23.9,49.5,44,108,44S236,135.9,236,112Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M180.6,211a8.1,8.1,0,0,1-6.6,9.2h-1.3a8.1,8.1,0,0,1-7.9-6.7l-3.9-23.5a8,8,0,0,1,15.8-2.7ZM128,184a8,8,0,0,0-8,8v32a8,8,0,0,0,16,0V192A8,8,0,0,0,128,184Zm-39.5-3.1a7.9,7.9,0,0,0-9.2,6.5L75.4,211a8.1,8.1,0,0,0,6.6,9.2h1.3a8.1,8.1,0,0,0,7.9-6.7l3.9-23.5A8,8,0,0,0,88.5,180.9ZM248,112c0,16.2-13.4,30.9-37.7,41.3C188.2,162.8,159,168,128,168s-60.2-5.2-82.3-14.7C21.4,142.9,8,128.2,8,112c0-8.4,3.7-20.8,21.2-32.5,11.3-7.6,26.9-13.8,45.2-17.9A63.6,63.6,0,0,1,173,50.5a64.7,64.7,0,0,1,9.1,11.2C223.4,71.1,248,89.7,248,112ZM80,96.8v3.1a7.8,7.8,0,0,0,6.1,7.7A189.1,189.1,0,0,0,128,112a189.1,189.1,0,0,0,41.9-4.4,7.8,7.8,0,0,0,6.1-7.7V96a47.5,47.5,0,0,0-6.1-23.4h0a51.7,51.7,0,0,0-8.2-10.7A47.4,47.4,0,0,0,127.4,48C101.2,48.3,80,70.2,80,96.8ZM232,112c0-11.7-16.6-23.9-41.9-31.6A62.3,62.3,0,0,1,192,96v3.9a23.9,23.9,0,0,1-18.6,23.3A202.1,202.1,0,0,1,128,128a202.1,202.1,0,0,1-45.4-4.8A23.9,23.9,0,0,1,64,99.9h0v-3a65.1,65.1,0,0,1,2.1-16.6C40.7,88,24,100.3,24,112c0,18.9,42.7,40,104,40S232,130.9,232,112Z" />
        </>
      )}
    </svg>
  );
}

export function PhFolderDotted(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M100,208a12,12,0,0,1-12,12H39.4A19.4,19.4,0,0,1,20,200.6V192a12,12,0,0,1,24,0v4H88A12,12,0,0,1,100,208Zm60-12H128a12,12,0,0,0,0,24h32a12,12,0,0,0,0-24Zm64-56a12,12,0,0,0-12,12v44H200a12,12,0,0,0,0,24h16.9A19.2,19.2,0,0,0,236,200.9V152A12,12,0,0,0,224,140Zm-8-72H168a12,12,0,0,0,0,24h44v20a12,12,0,0,0,24,0V88A20.1,20.1,0,0,0,216,68ZM32,164a12,12,0,0,0,12-12V120a12,12,0,0,0-24,0v32A12,12,0,0,0,32,164ZM20,80V52A20.1,20.1,0,0,1,40,32H92.4a20,20,0,0,1,15,6.7h0L137,72a12.1,12.1,0,0,1-9,20H32A12,12,0,0,1,20,80ZM44,68h57.3L90.6,56H44Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M128,80H32V56a8,8,0,0,1,8-8H92.7a7.9,7.9,0,0,1,5.6,2.3Z"
            opacity="0.2"
          />
          <path d="M96,208a8,8,0,0,1-8,8H39.4A15.4,15.4,0,0,1,24,200.6V192a8,8,0,0,1,16,0v8H88A8,8,0,0,1,96,208Zm64-8H128a8,8,0,0,0,0,16h32a8,8,0,0,0,0-16Zm64-56a8,8,0,0,0-8,8v48H200a8,8,0,0,0,0,16h16.9A15.2,15.2,0,0,0,232,200.9V152A8,8,0,0,0,224,144Zm-8-72H168a8,8,0,0,0,0,16h48v24a8,8,0,0,0,16,0V88A16,16,0,0,0,216,72ZM24,80V56A16,16,0,0,1,40,40H92.7A15.9,15.9,0,0,1,104,44.7l29.7,29.6a8.4,8.4,0,0,1,1.7,8.8A8,8,0,0,1,128,88H32A8,8,0,0,1,24,80Zm16-8h68.7l-16-16H40Zm-8,88a8,8,0,0,0,8-8V120a8,8,0,0,0-16,0v32A8,8,0,0,0,32,160Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M96,208a8,8,0,0,1-8,8H39.4A15.4,15.4,0,0,1,24,200.6V192a8,8,0,0,1,16,0v8H88A8,8,0,0,1,96,208ZM32,88h96a8,8,0,0,0,7.4-4.9,8.4,8.4,0,0,0-1.7-8.8L104,44.7A15.9,15.9,0,0,0,92.7,40H40A16,16,0,0,0,24,56V80A8,8,0,0,0,32,88Zm0,72a8,8,0,0,0,8-8V120a8,8,0,0,0-16,0v32A8,8,0,0,0,32,160ZM216,72H168a8,8,0,0,0,0,16h48v24a8,8,0,0,0,16,0V88A16,16,0,0,0,216,72Zm8,72a8,8,0,0,0-8,8v48H200a8,8,0,0,0,0,16h16.9A15.2,15.2,0,0,0,232,200.9V152A8,8,0,0,0,224,144Zm-64,56H128a8,8,0,0,0,0,16h32a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M94,208a6,6,0,0,1-6,6H39.4A13.4,13.4,0,0,1,26,200.6V192a6,6,0,0,1,12,0v8.6a1.4,1.4,0,0,0,1.4,1.4H88A6,6,0,0,1,94,208Zm66-6H128a6,6,0,0,0,0,12h32a6,6,0,0,0,0-12Zm64-56a6,6,0,0,0-6,6v48.9a1.1,1.1,0,0,1-1.1,1.1H200a6,6,0,0,0,0,12h16.9A13.1,13.1,0,0,0,230,200.9V152A6,6,0,0,0,224,146Zm-8-72H168a6,6,0,0,0,0,12h48a2,2,0,0,1,2,2v24a6,6,0,0,0,12,0V88A14,14,0,0,0,216,74ZM26,80V56A14,14,0,0,1,40,42H92.7a14.3,14.3,0,0,1,9.9,4.1l29.6,29.7a5.7,5.7,0,0,1,1.3,6.5A6,6,0,0,1,128,86H32A6,6,0,0,1,26,80Zm12-6h75.5L94.1,54.6a2,2,0,0,0-1.4-.6H40a2,2,0,0,0-2,2Zm-6,84a6,6,0,0,0,6-6V120a6,6,0,0,0-12,0v32A6,6,0,0,0,32,158Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M92,208a4,4,0,0,1-4,4H39.4A11.4,11.4,0,0,1,28,200.6V192a4,4,0,0,1,8,0v8.6a3.4,3.4,0,0,0,3.4,3.4H88A4,4,0,0,1,92,208Zm68-4H128a4,4,0,0,0,0,8h32a4,4,0,0,0,0-8Zm64-56a4,4,0,0,0-4,4v48.9a3.1,3.1,0,0,1-3.1,3.1H200a4,4,0,0,0,0,8h16.9A11.1,11.1,0,0,0,228,200.9V152A4,4,0,0,0,224,148Zm-8-72H168a4,4,0,0,0,0,8h48a4,4,0,0,1,4,4v24a4,4,0,0,0,8,0V88A12,12,0,0,0,216,76ZM28,80V56A12,12,0,0,1,40,44H92.7a11.9,11.9,0,0,1,8.5,3.5l29.6,29.7a3.8,3.8,0,0,1,.9,4.3A4,4,0,0,1,128,84H32A4,4,0,0,1,28,80Zm8-4h82.3L95.5,53.2A3.8,3.8,0,0,0,92.7,52H40a4,4,0,0,0-4,4Zm-4,80a4,4,0,0,0,4-4V120a4,4,0,0,0-8,0v32A4,4,0,0,0,32,156Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M96,208a8,8,0,0,1-8,8H39.4A15.4,15.4,0,0,1,24,200.6V192a8,8,0,0,1,16,0v8H88A8,8,0,0,1,96,208Zm64-8H128a8,8,0,0,0,0,16h32a8,8,0,0,0,0-16Zm64-56a8,8,0,0,0-8,8v48H200a8,8,0,0,0,0,16h16.9A15.2,15.2,0,0,0,232,200.9V152A8,8,0,0,0,224,144Zm-8-72H168a8,8,0,0,0,0,16h48v24a8,8,0,0,0,16,0V88A16,16,0,0,0,216,72ZM24,80V56A16,16,0,0,1,40,40H92.7A15.9,15.9,0,0,1,104,44.7l29.7,29.6a8.4,8.4,0,0,1,1.7,8.8A8,8,0,0,1,128,88H32A8,8,0,0,1,24,80Zm16-8h68.7l-16-16H40Zm-8,88a8,8,0,0,0,8-8V120a8,8,0,0,0-16,0v32A8,8,0,0,0,32,160Z" />
        </>
      )}
    </svg>
  );
}

export function PhFolderLock(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M224,152h-4v-4a32,32,0,0,0-64,0v4h-4a12,12,0,0,0-12,12v44a12,12,0,0,0,12,12h72a12,12,0,0,0,12-12V164A12,12,0,0,0,224,152Zm-44-4a8,8,0,0,1,16,0v4H180Zm32,48H164V176h48Zm4-128H133.4l-26-29.3a20,20,0,0,0-15-6.7H40A20.1,20.1,0,0,0,20,52V200.6A19.4,19.4,0,0,0,39.4,220h65.2a12,12,0,1,0,0-24H44V92H212a12,12,0,0,0,24,0V88A20.1,20.1,0,0,0,216,68ZM44,68V56H90.6l10.7,12Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M98.3,50.3,128,80H32V56a8,8,0,0,1,8-8H92.7A7.9,7.9,0,0,1,98.3,50.3ZM152,164v44h72V164Z"
            opacity="0.2"
          />
          <path d="M224,156h-8v-4a28,28,0,0,0-56,0v4h-8a8,8,0,0,0-8,8v44a8,8,0,0,0,8,8h72a8,8,0,0,0,8-8V164A8,8,0,0,0,224,156Zm-48-4a12,12,0,0,1,24,0v4H176Zm40,48H160V172h56Zm0-128H131.3L104,44.7A15.9,15.9,0,0,0,92.7,40H40A16,16,0,0,0,24,56V200.6A15.4,15.4,0,0,0,39.4,216h73.2a8,8,0,1,0,0-16H40V88H216v16a8,8,0,0,0,16,0V88A16,16,0,0,0,216,72ZM92.7,56l16,16H40V56Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M224,156h-8v-4a28,28,0,0,0-56,0v4h-8a8,8,0,0,0-8,8v44a8,8,0,0,0,8,8h72a8,8,0,0,0,8-8V164A8,8,0,0,0,224,156Zm-48-4a12,12,0,0,1,24,0v4H176Zm40-80H131.3L104,44.7A15.9,15.9,0,0,0,92.7,40H40A16,16,0,0,0,24,56V200.6A15.4,15.4,0,0,0,39.4,216h73.2a8,8,0,1,0,0-16H40V88H216v16a8,8,0,0,0,16,0V88A16,16,0,0,0,216,72ZM92.7,56l16,16H40V56Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M224,158H214v-6a26,26,0,0,0-52,0v6H152a6,6,0,0,0-6,6v44a6,6,0,0,0,6,6h72a6,6,0,0,0,6-6V164A6,6,0,0,0,224,158Zm-50-6a14,14,0,0,1,28,0v6H174Zm44,50H158V170h60ZM216,74H130.5L102.6,46.1A14.3,14.3,0,0,0,92.7,42H40A14,14,0,0,0,26,56V200.6A13.4,13.4,0,0,0,39.4,214h73.2a6,6,0,1,0,0-12H39.4a1.4,1.4,0,0,1-1.4-1.4V86H216a2,2,0,0,1,2,2v16a6,6,0,0,0,12,0V88A14,14,0,0,0,216,74ZM40,54H92.7a2,2,0,0,1,1.4.6L113.5,74H38V56A2,2,0,0,1,40,54Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M224,160H212v-8a24,24,0,0,0-48,0v8H152a4,4,0,0,0-4,4v44a4,4,0,0,0,4,4h72a4,4,0,0,0,4-4V164A4,4,0,0,0,224,160Zm-52-8a16,16,0,0,1,32,0v8H172Zm48,52H156V168h64ZM216,76H129.7L101.2,47.5A11.9,11.9,0,0,0,92.7,44H40A12,12,0,0,0,28,56V200.6A11.4,11.4,0,0,0,39.4,212h73.2a4,4,0,1,0,0-8H39.4a3.4,3.4,0,0,1-3.4-3.4V84H216a4,4,0,0,1,4,4v16a4,4,0,0,0,8,0V88A12,12,0,0,0,216,76ZM40,52H92.7a3.6,3.6,0,0,1,2.8,1.2L118.3,76H36V56A4,4,0,0,1,40,52Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M224,156h-8v-4a28,28,0,0,0-56,0v4h-8a8,8,0,0,0-8,8v44a8,8,0,0,0,8,8h72a8,8,0,0,0,8-8V164A8,8,0,0,0,224,156Zm-48-4a12,12,0,0,1,24,0v4H176Zm40,48H160V172h56Zm0-128H131.3L104,44.7A15.9,15.9,0,0,0,92.7,40H40A16,16,0,0,0,24,56V200.6A15.4,15.4,0,0,0,39.4,216h73.2a8,8,0,1,0,0-16H40V88H216v16a8,8,0,0,0,16,0V88A16,16,0,0,0,216,72ZM92.7,56l16,16H40V56Z" />
        </>
      )}
    </svg>
  );
}

export function PhFolderMinus(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216,68H133.4l-26-29.3a20,20,0,0,0-15-6.7H40A20.1,20.1,0,0,0,20,52V200.6A19.4,19.4,0,0,0,39.4,220H216.9A19.2,19.2,0,0,0,236,200.9V88A20.1,20.1,0,0,0,216,68ZM90.6,56l10.7,12H44V56ZM212,196H44V92H212ZM104,132h48a12,12,0,0,1,0,24H104a12,12,0,0,1,0-24Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M98.3,50.3,128,80H32V56a8,8,0,0,1,8-8H92.7A7.9,7.9,0,0,1,98.3,50.3Z" />
          </g>
          <path d="M216,72H131.3L104,44.7A15.9,15.9,0,0,0,92.7,40H40A16,16,0,0,0,24,56V200.6A15.4,15.4,0,0,0,39.4,216H216.9A15.2,15.2,0,0,0,232,200.9V88A16,16,0,0,0,216,72ZM92.7,56l16,16H40V56ZM216,200H40V88H216Z" />
          <path d="M96,144a8,8,0,0,0,8,8h48a8,8,0,0,0,0-16H104A8,8,0,0,0,96,144Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,72H131.3L104,44.7A15.9,15.9,0,0,0,92.7,40H40A16,16,0,0,0,24,56V200.6A15.4,15.4,0,0,0,39.4,216H216.9A15.2,15.2,0,0,0,232,200.9V88A16,16,0,0,0,216,72ZM40,56H92.7l16,16H40Zm112,96H104a8,8,0,0,1,0-16h48a8,8,0,0,1,0,16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M216,74H130.5L102.6,46.1A14.3,14.3,0,0,0,92.7,42H40A14,14,0,0,0,26,56V200.6A13.4,13.4,0,0,0,39.4,214H216.9A13.1,13.1,0,0,0,230,200.9V88A14,14,0,0,0,216,74ZM40,54H92.7a2,2,0,0,1,1.4.6L113.5,74H38V56A2,2,0,0,1,40,54ZM218,200.9a1.1,1.1,0,0,1-1.1,1.1H39.4a1.4,1.4,0,0,1-1.4-1.4V86H216a2,2,0,0,1,2,2ZM158,144a6,6,0,0,1-6,6H104a6,6,0,0,1,0-12h48A6,6,0,0,1,158,144Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M216,76H129.7L101.2,47.5A11.9,11.9,0,0,0,92.7,44H40A12,12,0,0,0,28,56V200.6A11.4,11.4,0,0,0,39.4,212H216.9A11.1,11.1,0,0,0,228,200.9V88A12,12,0,0,0,216,76ZM40,52H92.7a3.6,3.6,0,0,1,2.8,1.2L118.3,76H36V56A4,4,0,0,1,40,52ZM220,200.9a3.1,3.1,0,0,1-3.1,3.1H39.4a3.4,3.4,0,0,1-3.4-3.4V84H216a4,4,0,0,1,4,4ZM156,144a4,4,0,0,1-4,4H104a4,4,0,0,1,0-8h48A4,4,0,0,1,156,144Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,72H131.3L104,44.7A15.9,15.9,0,0,0,92.7,40H40A16,16,0,0,0,24,56V200.6A15.4,15.4,0,0,0,39.4,216H216.9A15.2,15.2,0,0,0,232,200.9V88A16,16,0,0,0,216,72ZM92.7,56l16,16H40V56ZM216,200H40V88H216ZM104,136h48a8,8,0,0,1,0,16H104a8,8,0,0,1,0-16Z" />
        </>
      )}
    </svg>
  );
}

export function PhFolderNotchMinus(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216,68H132L105.3,48a19.9,19.9,0,0,0-12-4H40A20.1,20.1,0,0,0,20,64V200a20.1,20.1,0,0,0,20,20H216a20.1,20.1,0,0,0,20-20V88A20.1,20.1,0,0,0,216,68ZM44,68H92l16,12L92,92H44ZM212,196H44V116H93.3a19.9,19.9,0,0,0,12-4L132,92h80Zm-60-60a12,12,0,0,1,0,24H104a12,12,0,0,1,0-24Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M93.3,104H32V64a8,8,0,0,1,8-8H93.3a7.9,7.9,0,0,1,4.8,1.6L128,80,98.1,102.4A7.9,7.9,0,0,1,93.3,104Z" />
          </g>
          <path d="M216,72H130.7L102.9,51.2A15.6,15.6,0,0,0,93.3,48H40A16,16,0,0,0,24,64V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V88A16,16,0,0,0,216,72ZM40,64H93.3l21.4,16L93.3,96H40ZM216,200H40V112H93.3a15.6,15.6,0,0,0,9.6-3.2L130.7,88H216Z" />
          <path d="M104,140a8,8,0,0,0,0,16h48a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,72H130.7L102.9,51.2A15.6,15.6,0,0,0,93.3,48H40A16,16,0,0,0,24,64V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V88A16,16,0,0,0,216,72ZM40,96V64H93.3l21.4,16L93.3,96Zm112,60H104a8,8,0,0,1,0-16h48a8,8,0,0,1,0,16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M216,74H130L101.7,52.8A14.1,14.1,0,0,0,93.3,50H40A14,14,0,0,0,26,64V200a14,14,0,0,0,14,14H216a14,14,0,0,0,14-14V88A14,14,0,0,0,216,74ZM38,64a2,2,0,0,1,2-2H93.3a1.6,1.6,0,0,1,1.2.4L118,80,94.5,97.6a1.6,1.6,0,0,1-1.2.4H38ZM218,200a2,2,0,0,1-2,2H40a2,2,0,0,1-2-2V110H93.3a14.1,14.1,0,0,0,8.4-2.8L130,86h86a2,2,0,0,1,2,2Zm-60-52a6,6,0,0,1-6,6H104a6,6,0,0,1,0-12h48A6,6,0,0,1,158,148Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M216,76H129.3L100.5,54.4A12.2,12.2,0,0,0,93.3,52H40A12,12,0,0,0,28,64V200a12,12,0,0,0,12,12H216a12,12,0,0,0,12-12V88A12,12,0,0,0,216,76ZM36,64a4,4,0,0,1,4-4H93.3a4.1,4.1,0,0,1,2.4.8L121.3,80,95.7,99.2a4.1,4.1,0,0,1-2.4.8H36ZM220,200a4,4,0,0,1-4,4H40a4,4,0,0,1-4-4V108H93.3a12.2,12.2,0,0,0,7.2-2.4L129.3,84H216a4,4,0,0,1,4,4Zm-64-52a4,4,0,0,1-4,4H104a4,4,0,0,1,0-8h48A4,4,0,0,1,156,148Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,72H130.7L102.9,51.2A15.6,15.6,0,0,0,93.3,48H40A16,16,0,0,0,24,64V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V88A16,16,0,0,0,216,72ZM40,64H93.3l21.4,16L93.3,96v0H40ZM216,200H40V112H93.3a15.6,15.6,0,0,0,9.6-3.2L130.7,88H216Zm-64-60a8,8,0,0,1,0,16H104a8,8,0,0,1,0-16Z" />
        </>
      )}
    </svg>
  );
}

export function PhFolderNotchOpen(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M245,112.4a19.8,19.8,0,0,0-16.3-8.4H220V88a20.1,20.1,0,0,0-20-20H132L105.3,48a19.9,19.9,0,0,0-12-4H40A20.1,20.1,0,0,0,20,64V208h0a12.2,12.2,0,0,0,2,6.6A11.9,11.9,0,0,0,32,220H208a11.8,11.8,0,0,0,11.3-8.1l28.3-81.3A19.7,19.7,0,0,0,245,112.4ZM92,68l26.7,20a19.9,19.9,0,0,0,12,4H196v12H146.4a20.1,20.1,0,0,0-11.1,3.4L116.4,120H69.3a19.9,19.9,0,0,0-18.4,12.2L44,148.6V68ZM199.5,196H50.1L72,144h45.6a20.1,20.1,0,0,0,11.1-3.4L147.6,128h75.5Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M208,88v24H146.4a8.4,8.4,0,0,0-4.4,1.3l-20,13.4a8.4,8.4,0,0,1-4.4,1.3H69.4a7.9,7.9,0,0,0-7.4,5L32,208V64a8,8,0,0,1,8-8H93.3a8.1,8.1,0,0,1,4.8,1.6l27.8,20.8a8.1,8.1,0,0,0,4.8,1.6H200A8,8,0,0,1,208,88Z"
            opacity="0.2"
          />
          <path d="M241.9,110.6a16.2,16.2,0,0,0-13-6.6H216V88a16,16,0,0,0-16-16H130.7L102.9,51.2A15.6,15.6,0,0,0,93.3,48H40A16,16,0,0,0,24,64V208h0a8.2,8.2,0,0,0,1.4,4.5A7.9,7.9,0,0,0,32,216H208a8,8,0,0,0,7.6-5.5l28.5-85.4A16.3,16.3,0,0,0,241.9,110.6ZM93.3,64l27.8,20.8a15.6,15.6,0,0,0,9.6,3.2H200v16H146.4a16,16,0,0,0-8.9,2.7L117.6,120H69.4a15.7,15.7,0,0,0-14.8,10.1L40,166.5V64ZM202.2,200H43.8l25.6-64h48.2a16,16,0,0,0,8.9-2.7L146.4,120h82.5Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M241.9,110.6a16.2,16.2,0,0,0-13-6.6H216V88a16,16,0,0,0-16-16H130.7L102.9,51.2A15.6,15.6,0,0,0,93.3,48H40A16,16,0,0,0,24,64V208h0a8.2,8.2,0,0,0,1.4,4.5A7.9,7.9,0,0,0,32,216H208a8,8,0,0,0,7.6-5.5l28.5-85.4A16.3,16.3,0,0,0,241.9,110.6ZM93.3,64l27.8,20.8a15.6,15.6,0,0,0,9.6,3.2H200v16H146.4a16,16,0,0,0-8.9,2.7L117.6,120H69.4a15.7,15.7,0,0,0-14.8,10.1L40,166.5V64Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M240.3,111.8a14.3,14.3,0,0,0-11.4-5.8H214V88a14,14,0,0,0-14-14H130.7a1.6,1.6,0,0,1-1.2-.4L101.7,52.8A14.1,14.1,0,0,0,93.3,50H40A14,14,0,0,0,26,64V208h0a5.9,5.9,0,0,0,1,3.4,6.1,6.1,0,0,0,5,2.6H208a6,6,0,0,0,5.7-4.1l28.5-85.5A14.2,14.2,0,0,0,240.3,111.8ZM40,62H93.3a1.6,1.6,0,0,1,1.2.4l27.8,20.8a14.1,14.1,0,0,0,8.4,2.8H200a2,2,0,0,1,2,2v18H146.4a13.2,13.2,0,0,0-7.7,2.4l-20,13.3a2,2,0,0,1-1.1.3H69.4a14.1,14.1,0,0,0-13,8.8L38,176.8V64A2,2,0,0,1,40,62Zm190.8,58.6L203.7,202H40.9l26.7-66.7a1.9,1.9,0,0,1,1.8-1.3h48.2a13.2,13.2,0,0,0,7.7-2.4l20-13.3a2,2,0,0,1,1.1-.3h82.5a1.9,1.9,0,0,1,1.6.8A2,2,0,0,1,230.8,120.6Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M238.6,113a11.6,11.6,0,0,0-9.7-5H212V88a12,12,0,0,0-12-12H130.7a4.1,4.1,0,0,1-2.4-.8L100.5,54.4A12.2,12.2,0,0,0,93.3,52H40A12,12,0,0,0,28,64V208h0a3.4,3.4,0,0,0,.7,2.2A4,4,0,0,0,32,212H208a4.1,4.1,0,0,0,3.8-2.7l28.5-85.5A11.9,11.9,0,0,0,238.6,113ZM40,60H93.3a4.1,4.1,0,0,1,2.4.8l27.8,20.8a12.2,12.2,0,0,0,7.2,2.4H200a4,4,0,0,1,4,4v20H146.4a11.7,11.7,0,0,0-6.6,2l-20,13.3a3.6,3.6,0,0,1-2.2.7H69.4a12,12,0,0,0-11.1,7.5L36,187.2V64A4,4,0,0,1,40,60Zm192.7,61.3L205.1,204H37.9l27.8-69.5a4,4,0,0,1,3.7-2.5h48.2a11.7,11.7,0,0,0,6.6-2l20-13.3a3.6,3.6,0,0,1,2.2-.7h82.5a3.7,3.7,0,0,1,3.2,1.7A3.8,3.8,0,0,1,232.7,121.3Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M241.9,110.6a16.2,16.2,0,0,0-13-6.6H216V88a16,16,0,0,0-16-16H130.7L102.9,51.2A15.6,15.6,0,0,0,93.3,48H40A16,16,0,0,0,24,64V208h0a8.2,8.2,0,0,0,1.4,4.5A7.9,7.9,0,0,0,32,216H208a8,8,0,0,0,7.6-5.5l28.5-85.4A16.3,16.3,0,0,0,241.9,110.6ZM93.3,64l27.8,20.8a15.6,15.6,0,0,0,9.6,3.2H200v16H146.4a16,16,0,0,0-8.9,2.7L117.6,120H69.4a15.7,15.7,0,0,0-14.8,10.1L40,166.5V64ZM202.2,200H43.8l25.6-64h48.2a16,16,0,0,0,8.9-2.7L146.4,120h82.5Z" />
        </>
      )}
    </svg>
  );
}

export function PhFolderNotchPlus(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216,68H132L105.3,48a19.9,19.9,0,0,0-12-4H40A20.1,20.1,0,0,0,20,64V200a20.1,20.1,0,0,0,20,20H216a20.1,20.1,0,0,0,20-20V88A20.1,20.1,0,0,0,216,68ZM44,68H92l16,12L92,92H44ZM212,196H44V116H93.3a19.9,19.9,0,0,0,12-4L132,92h80Zm-72-72v12h12a12,12,0,0,1,0,24H140v12a12,12,0,0,1-24,0V160H104a12,12,0,0,1,0-24h12V124a12,12,0,0,1,24,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M93.3,104H32V64a8,8,0,0,1,8-8H93.3a7.9,7.9,0,0,1,4.8,1.6L128,80,98.1,102.4A7.9,7.9,0,0,1,93.3,104Z" />
          </g>
          <path d="M216,72H130.7L102.9,51.2A15.6,15.6,0,0,0,93.3,48H40A16,16,0,0,0,24,64V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V88A16,16,0,0,0,216,72ZM40,64H93.3l21.4,16L93.3,96H40ZM216,200H40V112H93.3a15.6,15.6,0,0,0,9.6-3.2L130.7,88H216Z" />
          <path d="M128,116a8,8,0,0,0-8,8v16H104a8,8,0,0,0,0,16h16v16a8,8,0,0,0,16,0V156h16a8,8,0,0,0,0-16H136V124A8,8,0,0,0,128,116Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,72H130.7L102.9,51.2A15.6,15.6,0,0,0,93.3,48H40A16,16,0,0,0,24,64V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V88A16,16,0,0,0,216,72ZM40,96V64H93.3l21.4,16L93.3,96Zm112,60H136v16a8,8,0,0,1-16,0V156H104a8,8,0,0,1,0-16h16V124a8,8,0,0,1,16,0v16h16a8,8,0,0,1,0,16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M216,74H130L101.7,52.8A14.1,14.1,0,0,0,93.3,50H40A14,14,0,0,0,26,64V200a14,14,0,0,0,14,14H216a14,14,0,0,0,14-14V88A14,14,0,0,0,216,74ZM38,64a2,2,0,0,1,2-2H93.3a1.6,1.6,0,0,1,1.2.4L118,80,94.5,97.6a1.6,1.6,0,0,1-1.2.4H38ZM218,200a2,2,0,0,1-2,2H40a2,2,0,0,1-2-2V110H93.3a14.1,14.1,0,0,0,8.4-2.8L130,86h86a2,2,0,0,1,2,2Zm-60-52a6,6,0,0,1-6,6H134v18a6,6,0,0,1-12,0V154H104a6,6,0,0,1,0-12h18V124a6,6,0,0,1,12,0v18h18A6,6,0,0,1,158,148Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M216,76H129.3L100.5,54.4A12.2,12.2,0,0,0,93.3,52H40A12,12,0,0,0,28,64V200a12,12,0,0,0,12,12H216a12,12,0,0,0,12-12V88A12,12,0,0,0,216,76ZM36,64a4,4,0,0,1,4-4H93.3a4.1,4.1,0,0,1,2.4.8L121.3,80,95.7,99.2a4.1,4.1,0,0,1-2.4.8H36ZM220,200a4,4,0,0,1-4,4H40a4,4,0,0,1-4-4V108H93.3a12.2,12.2,0,0,0,7.2-2.4L129.3,84H216a4,4,0,0,1,4,4Zm-64-52a4,4,0,0,1-4,4H132v20a4,4,0,0,1-8,0V152H104a4,4,0,0,1,0-8h20V124a4,4,0,0,1,8,0v20h20A4,4,0,0,1,156,148Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,72H130.7L102.9,51.2A15.6,15.6,0,0,0,93.3,48H40A16,16,0,0,0,24,64V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V88A16,16,0,0,0,216,72ZM93.3,96H40V64H93.3l21.4,16L93.3,96M216,200H40V112H93.3a15.6,15.6,0,0,0,9.6-3.2L130.7,88H216Zm-80-76v16h16a8,8,0,0,1,0,16H136v16a8,8,0,0,1-16,0V156H104a8,8,0,0,1,0-16h16V124a8,8,0,0,1,16,0Z" />
        </>
      )}
    </svg>
  );
}

export function PhFolderNotch(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216,68H132L105.3,48a19.9,19.9,0,0,0-12-4H40A20.1,20.1,0,0,0,20,64V200a20.1,20.1,0,0,0,20,20H216a20.1,20.1,0,0,0,20-20V88A20.1,20.1,0,0,0,216,68ZM44,68H92l16,12L92,92H44ZM212,196H44V116H93.3a19.9,19.9,0,0,0,12-4L132,92h80Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M93.3,104H32V64a8,8,0,0,1,8-8H93.3a7.9,7.9,0,0,1,4.8,1.6L128,80,98.1,102.4A7.9,7.9,0,0,1,93.3,104Z" />
          </g>
          <path d="M216,72H130.7L102.9,51.2A15.6,15.6,0,0,0,93.3,48H40A16,16,0,0,0,24,64V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V88A16,16,0,0,0,216,72ZM40,64H93.3l21.4,16L93.3,96H40ZM216,200H40V112H93.3a15.6,15.6,0,0,0,9.6-3.2L130.7,88H216Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,72H130.7L102.9,51.2A15.6,15.6,0,0,0,93.3,48H40A16,16,0,0,0,24,64V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V88A16,16,0,0,0,216,72ZM40,64H93.3l21.4,16L93.3,96H40Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M216,74H130L101.7,52.8A14.1,14.1,0,0,0,93.3,50H40A14,14,0,0,0,26,64V200a14,14,0,0,0,14,14H216a14,14,0,0,0,14-14V88A14,14,0,0,0,216,74ZM38,64a2,2,0,0,1,2-2H93.3a1.6,1.6,0,0,1,1.2.4L118,80,94.5,97.6a1.6,1.6,0,0,1-1.2.4H38ZM218,200a2,2,0,0,1-2,2H40a2,2,0,0,1-2-2V110H93.3a14.1,14.1,0,0,0,8.4-2.8L130,86h86a2,2,0,0,1,2,2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M216,76H129.3L100.5,54.4A12.2,12.2,0,0,0,93.3,52H40A12,12,0,0,0,28,64V200a12,12,0,0,0,12,12H216a12,12,0,0,0,12-12V88A12,12,0,0,0,216,76ZM36,64a4,4,0,0,1,4-4H93.3a4.1,4.1,0,0,1,2.4.8L121.3,80,95.7,99.2a4.1,4.1,0,0,1-2.4.8H36ZM220,200a4,4,0,0,1-4,4H40a4,4,0,0,1-4-4V108H93.3a12.2,12.2,0,0,0,7.2-2.4L129.3,84H216a4,4,0,0,1,4,4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,72H130.7L102.9,51.2A15.6,15.6,0,0,0,93.3,48H40A16,16,0,0,0,24,64V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V88A16,16,0,0,0,216,72ZM93.3,96H40V64H93.3l21.4,16L93.3,96M216,200H40V112H93.3a15.6,15.6,0,0,0,9.6-3.2L130.7,88H216Z" />
        </>
      )}
    </svg>
  );
}

export function PhFolderOpen(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M245,112.4a19.8,19.8,0,0,0-16.3-8.4H220V88a20.1,20.1,0,0,0-20-20H132L105.3,48a19.9,19.9,0,0,0-12-4H40A20.1,20.1,0,0,0,20,64V208h0a12.6,12.6,0,0,0,2.2,7,12.2,12.2,0,0,0,9.8,5H208a11.8,11.8,0,0,0,11.3-8.1l28.3-81.3A19.7,19.7,0,0,0,245,112.4ZM92,68l26.7,20a19.9,19.9,0,0,0,12,4H196v12H69.7a20.1,20.1,0,0,0-18.9,13.4L44,137V68ZM199.5,196H48.9l23.6-68H223.1Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M208,88v24H69.8a8,8,0,0,0-7.6,5.5L32,208V64a8,8,0,0,1,8-8H93.3a8.1,8.1,0,0,1,4.8,1.6l27.8,20.8a8.1,8.1,0,0,0,4.8,1.6H200A8,8,0,0,1,208,88Z"
            opacity="0.2"
          />
          <path d="M241.9,110.6a16.2,16.2,0,0,0-13-6.6H216V88a16,16,0,0,0-16-16H130.7L102.9,51.2A15.6,15.6,0,0,0,93.3,48H40A16,16,0,0,0,24,64V208h0a7.9,7.9,0,0,0,8,8H208a8,8,0,0,0,7.6-5.5l28.5-85.4A16.3,16.3,0,0,0,241.9,110.6ZM93.3,64l27.8,20.8a15.6,15.6,0,0,0,9.6,3.2H200v16H69.8a16,16,0,0,0-15.2,10.9L40,158.7V64ZM202.2,200H43.1l26.7-80H228.9Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M241.9,110.6a16.2,16.2,0,0,0-13-6.6H216V88a16,16,0,0,0-16-16H130.7L102.9,51.2A15.6,15.6,0,0,0,93.3,48H40A16,16,0,0,0,24,64V208h0a7.9,7.9,0,0,0,8,8H208a8,8,0,0,0,7.6-5.5l28.5-85.4A16.3,16.3,0,0,0,241.9,110.6ZM93.3,64l27.8,20.8a15.6,15.6,0,0,0,9.6,3.2H200v16H69.8a16,16,0,0,0-15.2,10.9L40,158.7V64Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M240.3,111.8a14.3,14.3,0,0,0-11.4-5.8H214V88a14,14,0,0,0-14-14H130.7a1.6,1.6,0,0,1-1.2-.4L101.7,52.8A14.1,14.1,0,0,0,93.3,50H40A14,14,0,0,0,26,64V208h0a6.3,6.3,0,0,0,1.1,3.5A6.1,6.1,0,0,0,32,214H208a6,6,0,0,0,5.7-4.1l28.5-85.5A14.2,14.2,0,0,0,240.3,111.8ZM40,62H93.3a1.6,1.6,0,0,1,1.2.4l27.8,20.8a14.1,14.1,0,0,0,8.4,2.8H200a2,2,0,0,1,2,2v18H69.8a13.9,13.9,0,0,0-13.3,9.6L38,171V64A2,2,0,0,1,40,62Zm190.8,58.6L203.7,202H40.3l27.6-82.6a1.8,1.8,0,0,1,1.9-1.4H228.9a1.9,1.9,0,0,1,1.6.8A2,2,0,0,1,230.8,120.6Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M238.6,113a11.6,11.6,0,0,0-9.7-5H212V88a12,12,0,0,0-12-12H130.7a4.1,4.1,0,0,1-2.4-.8L100.5,54.4A12.2,12.2,0,0,0,93.3,52H40A12,12,0,0,0,28,64V208h0a4,4,0,0,0,.8,2.3A3.7,3.7,0,0,0,32,212H208a4.1,4.1,0,0,0,3.8-2.7l28.5-85.5A11.9,11.9,0,0,0,238.6,113ZM40,60H93.3a4.1,4.1,0,0,1,2.4.8l27.8,20.8a12.2,12.2,0,0,0,7.2,2.4H200a4,4,0,0,1,4,4v20H69.8a12,12,0,0,0-11.4,8.2L36,183.4V64A4,4,0,0,1,40,60Zm192.7,61.3L205.1,204H37.5L66,118.7a3.9,3.9,0,0,1,3.8-2.7H228.9a3.7,3.7,0,0,1,3.2,1.7A3.8,3.8,0,0,1,232.7,121.3Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M241.9,110.6a16.2,16.2,0,0,0-13-6.6H216V88a16,16,0,0,0-16-16H130.7L102.9,51.2A15.6,15.6,0,0,0,93.3,48H40A16,16,0,0,0,24,64V208h0a7.9,7.9,0,0,0,8,8H208a8,8,0,0,0,7.6-5.5l28.5-85.4A16.3,16.3,0,0,0,241.9,110.6ZM93.3,64l27.8,20.8a15.6,15.6,0,0,0,9.6,3.2H200v16H69.8a16,16,0,0,0-15.2,10.9L40,158.7V64ZM202.2,200H43.1l26.7-80H228.9Z" />
        </>
      )}
    </svg>
  );
}

export function PhFolderPlus(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216,68H133.4l-26-29.3a20,20,0,0,0-15-6.7H40A20.1,20.1,0,0,0,20,52V200.6A19.4,19.4,0,0,0,39.4,220H216.9A19.2,19.2,0,0,0,236,200.9V88A20.1,20.1,0,0,0,216,68ZM90.6,56l10.7,12H44V56ZM212,196H44V92H212Zm-72-76v12h12a12,12,0,0,1,0,24H140v12a12,12,0,0,1-24,0V156H104a12,12,0,0,1,0-24h12V120a12,12,0,0,1,24,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M98.3,50.3,128,80H32V56a8,8,0,0,1,8-8H92.7A7.9,7.9,0,0,1,98.3,50.3Z" />
          </g>
          <path d="M216,72H131.3L104,44.7A15.9,15.9,0,0,0,92.7,40H40A16,16,0,0,0,24,56V200.6A15.4,15.4,0,0,0,39.4,216H216.9A15.2,15.2,0,0,0,232,200.9V88A16,16,0,0,0,216,72ZM92.7,56l16,16H40V56ZM216,200H40V88H216Z" />
          <path d="M120,120v16H104a8,8,0,0,0,0,16h16v16a8,8,0,0,0,16,0V152h16a8,8,0,0,0,0-16H136V120a8,8,0,0,0-16,0Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,72H131.3L104,44.7A15.9,15.9,0,0,0,92.7,40H40A16,16,0,0,0,24,56V200.6A15.4,15.4,0,0,0,39.4,216H216.9A15.2,15.2,0,0,0,232,200.9V88A16,16,0,0,0,216,72ZM40,56H92.7l16,16H40Zm112,96H136v16a8,8,0,0,1-16,0V152H104a8,8,0,0,1,0-16h16V120a8,8,0,0,1,16,0v16h16a8,8,0,0,1,0,16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M216,74H130.5L102.6,46.1A14.3,14.3,0,0,0,92.7,42H40A14,14,0,0,0,26,56V200.6A13.4,13.4,0,0,0,39.4,214H216.9A13.1,13.1,0,0,0,230,200.9V88A14,14,0,0,0,216,74ZM40,54H92.7a2,2,0,0,1,1.4.6L113.5,74H38V56A2,2,0,0,1,40,54ZM218,200.9a1.1,1.1,0,0,1-1.1,1.1H39.4a1.4,1.4,0,0,1-1.4-1.4V86H216a2,2,0,0,1,2,2ZM158,144a6,6,0,0,1-6,6H134v18a6,6,0,0,1-12,0V150H104a6,6,0,0,1,0-12h18V120a6,6,0,0,1,12,0v18h18A6,6,0,0,1,158,144Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M216,76H129.7L101.2,47.5A11.9,11.9,0,0,0,92.7,44H40A12,12,0,0,0,28,56V200.6A11.4,11.4,0,0,0,39.4,212H216.9A11.1,11.1,0,0,0,228,200.9V88A12,12,0,0,0,216,76ZM40,52H92.7a3.6,3.6,0,0,1,2.8,1.2L118.3,76H36V56A4,4,0,0,1,40,52ZM220,200.9a3.1,3.1,0,0,1-3.1,3.1H39.4a3.4,3.4,0,0,1-3.4-3.4V84H216a4,4,0,0,1,4,4ZM156,144a4,4,0,0,1-4,4H132v20a4,4,0,0,1-8,0V148H104a4,4,0,0,1,0-8h20V120a4,4,0,0,1,8,0v20h20A4,4,0,0,1,156,144Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,72H131.3L104,44.7A15.9,15.9,0,0,0,92.7,40H40A16,16,0,0,0,24,56V200.6A15.4,15.4,0,0,0,39.4,216H216.9A15.2,15.2,0,0,0,232,200.9V88A16,16,0,0,0,216,72ZM92.7,56l16,16H40V56ZM216,200H40V88H216Zm-88-88a8,8,0,0,1,8,8v16h16a8,8,0,0,1,0,16H136v16a8,8,0,0,1-16,0V152H104a8,8,0,0,1,0-16h16V120A8,8,0,0,1,128,112Z" />
        </>
      )}
    </svg>
  );
}

export function PhFolderSimpleDotted(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M20,80V64A20.1,20.1,0,0,1,40,44H93.3a19.9,19.9,0,0,1,12,4l29.9,22.4a12,12,0,0,1,2.4,16.8,12,12,0,0,1-16.8,2.4L92,68H44V80a12,12,0,0,1-24,0ZM88,196H44v-4a12,12,0,0,0-24,0v8.6A19.4,19.4,0,0,0,39.4,220H88a12,12,0,0,0,0-24Zm72,0H128a12,12,0,0,0,0,24h32a12,12,0,0,0,0-24Zm64-56a12,12,0,0,0-12,12v44H200a12,12,0,0,0,0,24h16.9A19.2,19.2,0,0,0,236,200.9V152A12,12,0,0,0,224,140Zm-8-72H168a12,12,0,0,0,0,24h44v20a12,12,0,0,0,24,0V88A20.1,20.1,0,0,0,216,68ZM32,164a12,12,0,0,0,12-12V120a12,12,0,0,0-24,0v32A12,12,0,0,0,32,164Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M224,88V200.9a7.1,7.1,0,0,1-7.1,7.1H40a8,8,0,0,1-8-8V64a8,8,0,0,1,8-8H93.3a8.1,8.1,0,0,1,4.8,1.6l27.8,20.8a8.1,8.1,0,0,0,4.8,1.6H216A8,8,0,0,1,224,88Z"
            opacity="0.2"
          />
          <path d="M24,80V64A16,16,0,0,1,40,48H93.3a15.6,15.6,0,0,1,9.6,3.2l29.9,22.4A8,8,0,0,1,128,88a7.7,7.7,0,0,1-4.8-1.6L93.3,64H40V80a8,8,0,0,1-16,0ZM88,200H40v-8a8,8,0,0,0-16,0v8.6A15.4,15.4,0,0,0,39.4,216H88a8,8,0,0,0,0-16Zm72,0H128a8,8,0,0,0,0,16h32a8,8,0,0,0,0-16Zm64-56a8,8,0,0,0-8,8v48H200a8,8,0,0,0,0,16h16.9A15.2,15.2,0,0,0,232,200.9V152A8,8,0,0,0,224,144Zm-8-72H168a8,8,0,0,0,0,16h48v24a8,8,0,0,0,16,0V88A16,16,0,0,0,216,72ZM32,160a8,8,0,0,0,8-8V120a8,8,0,0,0-16,0v32A8,8,0,0,0,32,160Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M24,152V120a8,8,0,0,1,16,0v32a8,8,0,0,1-16,0Zm8-64a8,8,0,0,0,8-8V64H93.3l29.9,22.4A7.7,7.7,0,0,0,128,88a8,8,0,0,0,4.8-14.4L102.9,51.2A15.6,15.6,0,0,0,93.3,48H40A16,16,0,0,0,24,64V80A8,8,0,0,0,32,88ZM88,200H40v-8a8,8,0,0,0-16,0v8.6A15.4,15.4,0,0,0,39.4,216H88a8,8,0,0,0,0-16ZM216,72H168a8,8,0,0,0,0,16h48v24a8,8,0,0,0,16,0V88A16,16,0,0,0,216,72ZM160,200H128a8,8,0,0,0,0,16h32a8,8,0,0,0,0-16Zm64-56a8,8,0,0,0-8,8v48H200a8,8,0,0,0,0,16h16.9A15.2,15.2,0,0,0,232,200.9V152A8,8,0,0,0,224,144Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M124.4,84.8,94.5,62.4a1.6,1.6,0,0,0-1.2-.4H40a2,2,0,0,0-2,2V80a6,6,0,0,1-12,0V64A14,14,0,0,1,40,50H93.3a14.1,14.1,0,0,1,8.4,2.8l29.9,22.4a6,6,0,0,1-7.2,9.6ZM88,202H39.4a1.4,1.4,0,0,1-1.4-1.4V192a6,6,0,0,0-12,0v8.6A13.4,13.4,0,0,0,39.4,214H88a6,6,0,0,0,0-12Zm72,0H128a6,6,0,0,0,0,12h32a6,6,0,0,0,0-12Zm64-56a6,6,0,0,0-6,6v48.9a1.1,1.1,0,0,1-1.1,1.1H200a6,6,0,0,0,0,12h16.9A13.1,13.1,0,0,0,230,200.9V152A6,6,0,0,0,224,146Zm-8-72H168a6,6,0,0,0,0,12h48a2,2,0,0,1,2,2v24a6,6,0,0,0,12,0V88A14,14,0,0,0,216,74ZM32,158a6,6,0,0,0,6-6V120a6,6,0,0,0-12,0v32A6,6,0,0,0,32,158Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M125.6,83.2,95.7,60.8a4.1,4.1,0,0,0-2.4-.8H40a4,4,0,0,0-4,4V80a4,4,0,0,1-8,0V64A12,12,0,0,1,40,52H93.3a12.2,12.2,0,0,1,7.2,2.4l29.9,22.4a4,4,0,0,1,.8,5.6A4.2,4.2,0,0,1,128,84,4.3,4.3,0,0,1,125.6,83.2ZM88,204H39.4a3.4,3.4,0,0,1-3.4-3.4V192a4,4,0,0,0-8,0v8.6A11.4,11.4,0,0,0,39.4,212H88a4,4,0,0,0,0-8Zm72,0H128a4,4,0,0,0,0,8h32a4,4,0,0,0,0-8Zm64-56a4,4,0,0,0-4,4v48.9a3.1,3.1,0,0,1-3.1,3.1H200a4,4,0,0,0,0,8h16.9A11.1,11.1,0,0,0,228,200.9V152A4,4,0,0,0,224,148Zm-8-72H168a4,4,0,0,0,0,8h48a4,4,0,0,1,4,4v24a4,4,0,0,0,8,0V88A12,12,0,0,0,216,76ZM32,156a4,4,0,0,0,4-4V120a4,4,0,0,0-8,0v32A4,4,0,0,0,32,156Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M24,80V64A16,16,0,0,1,40,48H93.3a15.6,15.6,0,0,1,9.6,3.2l29.9,22.4A8,8,0,0,1,128,88a7.7,7.7,0,0,1-4.8-1.6L93.3,64H40V80a8,8,0,0,1-16,0ZM88,200H40v-8a8,8,0,0,0-16,0v8.6A15.4,15.4,0,0,0,39.4,216H88a8,8,0,0,0,0-16Zm72,0H128a8,8,0,0,0,0,16h32a8,8,0,0,0,0-16Zm64-56a8,8,0,0,0-8,8v48H200a8,8,0,0,0,0,16h16.9A15.2,15.2,0,0,0,232,200.9V152A8,8,0,0,0,224,144Zm-8-72H168a8,8,0,0,0,0,16h48v24a8,8,0,0,0,16,0V88A16,16,0,0,0,216,72ZM32,160a8,8,0,0,0,8-8V120a8,8,0,0,0-16,0v32A8,8,0,0,0,32,160Z" />
        </>
      )}
    </svg>
  );
}

export function PhFolderSimpleLock(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M236,88v4a12,12,0,0,1-24,0H130.7a19.9,19.9,0,0,1-12-4L92,68H44V196h60a12,12,0,0,1,0,24H40a20.1,20.1,0,0,1-20-20V64A20.1,20.1,0,0,1,40,44H93.3a19.9,19.9,0,0,1,12,4L132,68h84A20.1,20.1,0,0,1,236,88Zm0,76v44a12,12,0,0,1-12,12H152a12,12,0,0,1-12-12V164a12,12,0,0,1,12-12h4v-4a32,32,0,0,1,64,0v4h4A12,12,0,0,1,236,164Zm-56-12h16v-4a8,8,0,0,0-16,0Zm32,24H164v20h48Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M224,164v44H152V164Z" opacity="0.2" />
          <path d="M232,88v16a8,8,0,0,1-16,0V88H130.7a15.6,15.6,0,0,1-9.6-3.2L93.3,64H40V200h72a8,8,0,0,1,0,16H40a16,16,0,0,1-16-16V64A16,16,0,0,1,40,48H93.3a15.6,15.6,0,0,1,9.6,3.2L130.7,72H216A16,16,0,0,1,232,88Zm0,76v44a8,8,0,0,1-8,8H152a8,8,0,0,1-8-8V164a8,8,0,0,1,8-8h8v-4a28,28,0,0,1,56,0v4h8A8,8,0,0,1,232,164Zm-56-8h24v-4a12,12,0,0,0-24,0Zm40,16H160v28h56Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M232,88v16a8,8,0,0,1-16,0V88H130.7a15.6,15.6,0,0,1-9.6-3.2L93.3,64H40V200h72a8,8,0,0,1,0,16H40a16,16,0,0,1-16-16V64A16,16,0,0,1,40,48H93.3a15.6,15.6,0,0,1,9.6,3.2L130.7,72H216A16,16,0,0,1,232,88Zm0,76v44a8,8,0,0,1-8,8H152a8,8,0,0,1-8-8V164a8,8,0,0,1,8-8h8v-4a28,28,0,0,1,56,0v4h8A8,8,0,0,1,232,164Zm-32-12a12,12,0,0,0-24,0v4h24Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M230,88v16a6,6,0,0,1-12,0V88a2,2,0,0,0-2-2H130.7a14.1,14.1,0,0,1-8.4-2.8L94.5,62.4a1.6,1.6,0,0,0-1.2-.4H40a2,2,0,0,0-2,2V200a2,2,0,0,0,2,2h72a6,6,0,0,1,0,12H40a14,14,0,0,1-14-14V64A14,14,0,0,1,40,50H93.3a14.1,14.1,0,0,1,8.4,2.8l27.8,20.8a1.6,1.6,0,0,0,1.2.4H216A14,14,0,0,1,230,88Zm0,76v44a6,6,0,0,1-6,6H152a6,6,0,0,1-6-6V164a6,6,0,0,1,6-6h10v-6a26,26,0,0,1,52,0v6h10A6,6,0,0,1,230,164Zm-56-6h28v-6a14,14,0,0,0-28,0Zm44,12H158v32h60Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M228,88v16a4,4,0,0,1-8,0V88a4,4,0,0,0-4-4H130.7a12.2,12.2,0,0,1-7.2-2.4L95.7,60.8a4.1,4.1,0,0,0-2.4-.8H40a4,4,0,0,0-4,4V200a4,4,0,0,0,4,4h72a4,4,0,0,1,0,8H40a12,12,0,0,1-12-12V64A12,12,0,0,1,40,52H93.3a12.2,12.2,0,0,1,7.2,2.4l27.8,20.8a4.1,4.1,0,0,0,2.4.8H216A12,12,0,0,1,228,88Zm0,76v44a4,4,0,0,1-4,4H152a4,4,0,0,1-4-4V164a4,4,0,0,1,4-4h12v-8a24,24,0,0,1,48,0v8h12A4,4,0,0,1,228,164Zm-56-4h32v-8a16,16,0,0,0-32,0Zm48,8H156v36h64Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M232,88v16a8,8,0,0,1-16,0V88H130.7a15.6,15.6,0,0,1-9.6-3.2L93.3,64H40V200h72a8,8,0,0,1,0,16H40a16,16,0,0,1-16-16V64A16,16,0,0,1,40,48H93.3a15.6,15.6,0,0,1,9.6,3.2L130.7,72H216A16,16,0,0,1,232,88Zm0,76v44a8,8,0,0,1-8,8H152a8,8,0,0,1-8-8V164a8,8,0,0,1,8-8h8v-4a28,28,0,0,1,56,0v4h8A8,8,0,0,1,232,164Zm-56-8h24v-4a12,12,0,0,0-24,0Zm40,16H160v28h56Z" />
        </>
      )}
    </svg>
  );
}

export function PhFolderSimpleMinus(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M104,132h48a12,12,0,0,1,0,24H104a12,12,0,0,1,0-24ZM236,88V200.9A19.2,19.2,0,0,1,216.9,220H40a20.1,20.1,0,0,1-20-20V64A20.1,20.1,0,0,1,40,44H93.3a19.9,19.9,0,0,1,12,4L132,68h84A20.1,20.1,0,0,1,236,88Zm-24,4H130.7a19.9,19.9,0,0,1-12-4L92,68H44V196H212Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M224,88V200.9a7.1,7.1,0,0,1-7.1,7.1H40a8,8,0,0,1-8-8V64a8,8,0,0,1,8-8H93.3a7.9,7.9,0,0,1,4.8,1.6l27.8,20.8a8.1,8.1,0,0,0,4.8,1.6H216A8,8,0,0,1,224,88Z" />
          </g>
          <path d="M152,136H104a8,8,0,0,0,0,16h48a8,8,0,0,0,0-16Z" />
          <path d="M216,72H130.7L102.9,51.2A15.6,15.6,0,0,0,93.3,48H40A16,16,0,0,0,24,64V200a16,16,0,0,0,16,16H216.9A15.2,15.2,0,0,0,232,200.9V88A16,16,0,0,0,216,72Zm0,128H40V64H93.3l27.8,20.8a15.6,15.6,0,0,0,9.6,3.2H216Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,72H130.7L102.9,51.2A15.6,15.6,0,0,0,93.3,48H40A16,16,0,0,0,24,64V200a16,16,0,0,0,16,16H216.9A15.2,15.2,0,0,0,232,200.9V88A16,16,0,0,0,216,72Zm-64,80H104a8,8,0,0,1,0-16h48a8,8,0,0,1,0,16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M158,144a6,6,0,0,1-6,6H104a6,6,0,0,1,0-12h48A6,6,0,0,1,158,144Zm72-56V200.9A13.1,13.1,0,0,1,216.9,214H40a14,14,0,0,1-14-14V64A14,14,0,0,1,40,50H93.3a14.1,14.1,0,0,1,8.4,2.8l27.8,20.8a1.6,1.6,0,0,0,1.2.4H216A14,14,0,0,1,230,88Zm-12,0a2,2,0,0,0-2-2H130.7a14.1,14.1,0,0,1-8.4-2.8L94.5,62.4a1.6,1.6,0,0,0-1.2-.4H40a2,2,0,0,0-2,2V200a2,2,0,0,0,2,2H216.9a1.1,1.1,0,0,0,1.1-1.1Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M156,144a4,4,0,0,1-4,4H104a4,4,0,0,1,0-8h48A4,4,0,0,1,156,144Zm72-56V200.9A11.1,11.1,0,0,1,216.9,212H40a12,12,0,0,1-12-12V64A12,12,0,0,1,40,52H93.3a12.2,12.2,0,0,1,7.2,2.4l27.8,20.8a4.1,4.1,0,0,0,2.4.8H216A12,12,0,0,1,228,88Zm-8,0a4,4,0,0,0-4-4H130.7a12.2,12.2,0,0,1-7.2-2.4L95.7,60.8a4.1,4.1,0,0,0-2.4-.8H40a4,4,0,0,0-4,4V200a4,4,0,0,0,4,4H216.9a3.1,3.1,0,0,0,3.1-3.1Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M160,144a8,8,0,0,1-8,8H104a8,8,0,0,1,0-16h48A8,8,0,0,1,160,144Zm72-56V200.9A15.2,15.2,0,0,1,216.9,216H40a16,16,0,0,1-16-16V64A16,16,0,0,1,40,48H93.3a15.6,15.6,0,0,1,9.6,3.2L130.7,72H216A16,16,0,0,1,232,88Zm-16,0H130.7a15.6,15.6,0,0,1-9.6-3.2L93.3,64H40V200H216Z" />
        </>
      )}
    </svg>
  );
}

export function PhFolderSimplePlus(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216,68H132L105.3,48a19.9,19.9,0,0,0-12-4H40A20.1,20.1,0,0,0,20,64V200a20.1,20.1,0,0,0,20,20H216.9A19.2,19.2,0,0,0,236,200.9V88A20.1,20.1,0,0,0,216,68Zm-4,128H44V68H92l26.7,20a19.9,19.9,0,0,0,12,4H212Zm-84-88a12,12,0,0,1,12,12v12h12a12,12,0,0,1,0,24H140v12a12,12,0,0,1-24,0V156H104a12,12,0,0,1,0-24h12V120A12,12,0,0,1,128,108Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M224,88V200.9a7.1,7.1,0,0,1-7.1,7.1H40a8,8,0,0,1-8-8V64a8,8,0,0,1,8-8H93.3a7.9,7.9,0,0,1,4.8,1.6l27.8,20.8a8.1,8.1,0,0,0,4.8,1.6H216A8,8,0,0,1,224,88Z" />
          </g>
          <path d="M216,72H130.7L102.9,51.2A15.6,15.6,0,0,0,93.3,48H40A16,16,0,0,0,24,64V200a16,16,0,0,0,16,16H216.9A15.2,15.2,0,0,0,232,200.9V88A16,16,0,0,0,216,72Zm0,128H40V64H93.3l27.8,20.8a15.6,15.6,0,0,0,9.6,3.2H216Z" />
          <path d="M152,136H136V120a8,8,0,0,0-16,0v16H104a8,8,0,0,0,0,16h16v16a8,8,0,0,0,16,0V152h16a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,72H130.7L102.9,51.2A15.6,15.6,0,0,0,93.3,48H40A16,16,0,0,0,24,64V200a16,16,0,0,0,16,16H216.9A15.2,15.2,0,0,0,232,200.9V88A16,16,0,0,0,216,72Zm-64,80H136v16a8,8,0,0,1-16,0V152H104a8,8,0,0,1,0-16h16V120a8,8,0,0,1,16,0v16h16a8,8,0,0,1,0,16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M216,74H130.7a1.6,1.6,0,0,1-1.2-.4L101.7,52.8A14.1,14.1,0,0,0,93.3,50H40A14,14,0,0,0,26,64V200a14,14,0,0,0,14,14H216.9A13.1,13.1,0,0,0,230,200.9V88A14,14,0,0,0,216,74Zm2,126.9a1.1,1.1,0,0,1-1.1,1.1H40a2,2,0,0,1-2-2V64a2,2,0,0,1,2-2H93.3a1.6,1.6,0,0,1,1.2.4l27.8,20.8a14.1,14.1,0,0,0,8.4,2.8H216a2,2,0,0,1,2,2ZM158,144a6,6,0,0,1-6,6H134v18a6,6,0,0,1-12,0V150H104a6,6,0,0,1,0-12h18V120a6,6,0,0,1,12,0v18h18A6,6,0,0,1,158,144Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M216,76H130.7a4.1,4.1,0,0,1-2.4-.8L100.5,54.4A12.2,12.2,0,0,0,93.3,52H40A12,12,0,0,0,28,64V200a12,12,0,0,0,12,12H216.9A11.1,11.1,0,0,0,228,200.9V88A12,12,0,0,0,216,76Zm4,124.9a3.1,3.1,0,0,1-3.1,3.1H40a4,4,0,0,1-4-4V64a4,4,0,0,1,4-4H93.3a4.1,4.1,0,0,1,2.4.8l27.8,20.8a12.2,12.2,0,0,0,7.2,2.4H216a4,4,0,0,1,4,4ZM156,144a4,4,0,0,1-4,4H132v20a4,4,0,0,1-8,0V148H104a4,4,0,0,1,0-8h20V120a4,4,0,0,1,8,0v20h20A4,4,0,0,1,156,144Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,72H130.7L102.9,51.2A15.6,15.6,0,0,0,93.3,48H40A16,16,0,0,0,24,64V200a16,16,0,0,0,16,16H216.9A15.2,15.2,0,0,0,232,200.9V88A16,16,0,0,0,216,72Zm0,128H40V64H93.3l27.8,20.8a15.6,15.6,0,0,0,9.6,3.2H216Zm-56-56a8,8,0,0,1-8,8H136v16a8,8,0,0,1-16,0V152H104a8,8,0,0,1,0-16h16V120a8,8,0,0,1,16,0v16h16A8,8,0,0,1,160,144Z" />
        </>
      )}
    </svg>
  );
}

export function PhFolderSimpleStar(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M124,208a12,12,0,0,1-12,12H40a20.1,20.1,0,0,1-20-20V64A20.1,20.1,0,0,1,40,44H93.3a19.9,19.9,0,0,1,12,4L132,68h84a20.1,20.1,0,0,1,20,20v24a12,12,0,0,1-24,0V92H130.7a19.9,19.9,0,0,1-12-4L92,68H44V196h68A12,12,0,0,1,124,208Zm119.6-37.1-20.5,17,6.2,25.2a11.9,11.9,0,0,1-4.5,12.5,11.7,11.7,0,0,1-7.1,2.4,11.3,11.3,0,0,1-6.1-1.7L188,212.5l-23.6,13.8a12,12,0,0,1-17.7-13.2l6.2-25.2-20.5-17a12.1,12.1,0,0,1,6.7-21.3l27.4-2.1L177,123.2a12,12,0,0,1,22,0l10.5,24.3,27.4,2.1a12.1,12.1,0,0,1,6.7,21.3Zm-38.2.4-5-.4a12,12,0,0,1-10.1-7.2l-2.3-5.4-2.3,5.4a12,12,0,0,1-10.1,7.2l-5,.4,3.4,2.9a11.6,11.6,0,0,1,4,12.1l-1.2,4.9,5.1-3a11.8,11.8,0,0,1,12.2,0l5.1,3-1.2-4.9a11.6,11.6,0,0,1,4-12.1Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M209.6,183.4l8.1,32.6L188,198.5,158.3,216l8.1-32.6L140,161.6l34.7-2.7L188,128l13.3,30.9,34.7,2.7Z"
            opacity="0.2"
          />
          <path d="M40,64V200h80a8,8,0,0,1,0,16H40a16,16,0,0,1-16-16V64A16,16,0,0,1,40,48H93.3a15.6,15.6,0,0,1,9.6,3.2L130.7,72H216a16,16,0,0,1,16,16v32a8,8,0,0,1-16,0V88H130.7a15.6,15.6,0,0,1-9.6-3.2L93.3,64ZM241.1,167.8l-22.5,18.6,6.8,27.7a7.9,7.9,0,0,1-3,8.3,7.4,7.4,0,0,1-4.7,1.6,8.7,8.7,0,0,1-4.1-1.1L188,207.8l-25.6,15.1a8,8,0,0,1-8.8-.5,7.9,7.9,0,0,1-3-8.3l6.8-27.7-22.5-18.6a8.1,8.1,0,0,1,4.5-14.2l29.8-2.3,11.5-26.5a8,8,0,0,1,14.6,0l11.5,26.5,29.8,2.3a8.1,8.1,0,0,1,4.5,14.2Zm-25.5.3-14.9-1.2a8.1,8.1,0,0,1-6.7-4.8l-6-13.9-6,13.9a8.1,8.1,0,0,1-6.7,4.8l-14.9,1.2,11.1,9.1a8.1,8.1,0,0,1,2.7,8.1l-3.5,14.2,13.2-7.9a8.2,8.2,0,0,1,8.2,0l13.2,7.9-3.5-14.2a8.1,8.1,0,0,1,2.7-8.1Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M40,64V200h80a8,8,0,0,1,0,16H40a16,16,0,0,1-16-16V64A16,16,0,0,1,40,48H93.3a15.6,15.6,0,0,1,9.6,3.2L130.7,72H216a16,16,0,0,1,16,16v32a8,8,0,0,1-16,0V88H130.7a15.6,15.6,0,0,1-9.6-3.2L93.3,64Zm203.6,95.2a8.1,8.1,0,0,0-7-5.6l-29.8-2.3-11.5-26.5a8,8,0,0,0-14.6,0l-11.5,26.5-29.8,2.3a8.1,8.1,0,0,0-4.5,14.2l22.5,18.6-6.8,27.7a7.9,7.9,0,0,0,3,8.3,8,8,0,0,0,8.8.5L188,207.8l25.6,15.1a8.7,8.7,0,0,0,4.1,1.1,7.4,7.4,0,0,0,4.7-1.6,7.9,7.9,0,0,0,3-8.3l-6.8-27.7,22.5-18.6A8,8,0,0,0,243.6,159.2Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M38,64V200a2,2,0,0,0,2,2h80a6,6,0,0,1,0,12H40a14,14,0,0,1-14-14V64A14,14,0,0,1,40,50H93.3a14.1,14.1,0,0,1,8.4,2.8l27.8,20.8a1.6,1.6,0,0,0,1.2.4H216a14,14,0,0,1,14,14v32a6,6,0,0,1-12,0V88a2,2,0,0,0-2-2H130.7a14.1,14.1,0,0,1-8.4-2.8L94.5,62.4a1.6,1.6,0,0,0-1.2-.4H40A2,2,0,0,0,38,64ZM239.8,166.2l-23.5,19.4,7.2,29a5.9,5.9,0,0,1-2.2,6.2,6.2,6.2,0,0,1-3.6,1.2,6.8,6.8,0,0,1-3.1-.8L188,205.5l-26.6,15.7a6.1,6.1,0,0,1-6.7-.4,5.9,5.9,0,0,1-2.2-6.2l7.2-29-23.5-19.4a5.8,5.8,0,0,1-1.9-6.4,6,6,0,0,1,5.2-4.2l31.1-2.4,11.9-27.6a6,6,0,0,1,11,0l11.9,27.6,31.1,2.4a6,6,0,0,1,5.2,4.2A5.8,5.8,0,0,1,239.8,166.2Zm-19.1.3-19.8-1.6a6.1,6.1,0,0,1-5.1-3.6L188,143.1l-7.8,18.2a6.1,6.1,0,0,1-5.1,3.6l-19.8,1.6,14.9,12.3a5.9,5.9,0,0,1,2,6.1l-4.6,18.7L185,193.4a5.5,5.5,0,0,1,6,0l17.4,10.2-4.6-18.7a5.9,5.9,0,0,1,2-6.1Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M36,64V200a4,4,0,0,0,4,4h80a4,4,0,0,1,0,8H40a12,12,0,0,1-12-12V64A12,12,0,0,1,40,52H93.3a12.2,12.2,0,0,1,7.2,2.4l27.8,20.8a4.1,4.1,0,0,0,2.4.8H216a12,12,0,0,1,12,12v32a4,4,0,0,1-8,0V88a4,4,0,0,0-4-4H130.7a12.2,12.2,0,0,1-7.2-2.4L95.7,60.8a4.1,4.1,0,0,0-2.4-.8H40A4,4,0,0,0,36,64ZM238.5,164.7l-24.4,20.2,7.4,30.1a4.1,4.1,0,0,1-1.4,4.2,4.3,4.3,0,0,1-2.4.8,4.2,4.2,0,0,1-2.1-.6L188,203.2l-27.6,16.2a3.9,3.9,0,0,1-4.5-.2,4.1,4.1,0,0,1-1.4-4.2l7.4-30.1-24.4-20.2a3.9,3.9,0,0,1-1.3-4.3,4.3,4.3,0,0,1,3.5-2.8l32.2-2.5,12.4-28.7a4.1,4.1,0,0,1,7.4,0l12.4,28.7,32.2,2.5a4.3,4.3,0,0,1,3.5,2.8A3.9,3.9,0,0,1,238.5,164.7Zm-12.7.1L201,162.9a3.9,3.9,0,0,1-3.3-2.4L188,138.1l-9.7,22.4a3.9,3.9,0,0,1-3.3,2.4l-24.8,1.9,18.7,15.5a4,4,0,0,1,1.4,4.1l-5.8,23.3L186,195.1a3.6,3.6,0,0,1,4,0l21.5,12.6-5.8-23.3a4,4,0,0,1,1.4-4.1Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M40,64V200h80a8,8,0,0,1,0,16H40a16,16,0,0,1-16-16V64A16,16,0,0,1,40,48H93.3a15.6,15.6,0,0,1,9.6,3.2L130.7,72H216a16,16,0,0,1,16,16v32a8,8,0,0,1-16,0V88H130.7a15.6,15.6,0,0,1-9.6-3.2L93.3,64ZM241.1,167.8l-22.5,18.6,6.8,27.7a7.9,7.9,0,0,1-3,8.3,7.4,7.4,0,0,1-4.7,1.6,8.7,8.7,0,0,1-4.1-1.1L188,207.8l-25.6,15.1a8,8,0,0,1-8.8-.5,7.9,7.9,0,0,1-3-8.3l6.8-27.7-22.5-18.6a8.1,8.1,0,0,1,4.5-14.2l29.8-2.3,11.5-26.5a8,8,0,0,1,14.6,0l11.5,26.5,29.8,2.3a8.1,8.1,0,0,1,4.5,14.2Zm-25.5.3-14.9-1.2a8.1,8.1,0,0,1-6.7-4.8l-6-13.9-6,13.9a8.1,8.1,0,0,1-6.7,4.8l-14.9,1.2,11.1,9.1a8.1,8.1,0,0,1,2.7,8.1l-3.5,14.2,13.2-7.9a8.2,8.2,0,0,1,8.2,0l13.2,7.9-3.5-14.2a8.1,8.1,0,0,1,2.7-8.1Z" />
        </>
      )}
    </svg>
  );
}

export function PhFolderSimpleUser(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216.3,194.3a36,36,0,1,0-56.6,0A43.7,43.7,0,0,0,145.4,217a11.9,11.9,0,0,0,8.6,14.6,10.3,10.3,0,0,0,3,.4,12,12,0,0,0,11.6-9,20,20,0,0,1,38.8,0,12,12,0,1,0,23.2-6A43.7,43.7,0,0,0,216.3,194.3ZM176,172a12,12,0,1,1,12,12A12,12,0,0,1,176,172Zm60-84v20a12,12,0,0,1-24,0V92H130.7a19.9,19.9,0,0,1-12-4L92,68H44V196h68a12,12,0,0,1,0,24H40a20.1,20.1,0,0,1-20-20V64A20.1,20.1,0,0,1,40,44H93.3a19.9,19.9,0,0,1,12,4L132,68h84A20.1,20.1,0,0,1,236,88Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M212,172a24,24,0,1,1-24-24A23.9,23.9,0,0,1,212,172Z"
            opacity="0.2"
          />
          <path d="M210.4,194.8a32,32,0,1,0-44.8,0A40.2,40.2,0,0,0,149.3,218a7.9,7.9,0,0,0,5.7,9.7l2,.3a8.1,8.1,0,0,0,7.8-6,24,24,0,0,1,46.4,0,8,8,0,0,0,9.8,5.7,7.9,7.9,0,0,0,5.7-9.7A40.2,40.2,0,0,0,210.4,194.8ZM172,172a16,16,0,1,1,16,16A16,16,0,0,1,172,172Zm60-84v32a8,8,0,0,1-16,0V88H130.7a15.6,15.6,0,0,1-9.6-3.2L93.3,64H40V200h80a8,8,0,0,1,0,16H40a16,16,0,0,1-16-16V64A16,16,0,0,1,40,48H93.3a15.6,15.6,0,0,1,9.6,3.2L130.7,72H216A16,16,0,0,1,232,88Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M226.7,218a8,8,0,0,1-7.7,10H157a8,8,0,0,1-7.7-10,40.2,40.2,0,0,1,16.3-23.2,32,32,0,1,1,44.8,0A40.2,40.2,0,0,1,226.7,218ZM216,72H130.7L102.9,51.2A15.6,15.6,0,0,0,93.3,48H40A16,16,0,0,0,24,64V200a16,16,0,0,0,16,16h80a8,8,0,0,0,0-16H40V64H93.3l27.8,20.8a15.6,15.6,0,0,0,9.6,3.2H216v32a8,8,0,0,0,16,0V88A16,16,0,0,0,216,72Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M207.1,195.1a30,30,0,1,0-38.2,0,38,38,0,0,0-17.7,23.4,6,6,0,0,0,4.3,7.3l1.5.2a6,6,0,0,0,5.8-4.5,26,26,0,0,1,50.4,0,6,6,0,1,0,11.6-3A38,38,0,0,0,207.1,195.1ZM170,172a18,18,0,1,1,18,18A18.1,18.1,0,0,1,170,172Zm60-84v32a6,6,0,0,1-12,0V88a2,2,0,0,0-2-2H130.7a14.1,14.1,0,0,1-8.4-2.8L94.5,62.4a1.6,1.6,0,0,0-1.2-.4H40a2,2,0,0,0-2,2V200a2,2,0,0,0,2,2h80a6,6,0,0,1,0,12H40a14,14,0,0,1-14-14V64A14,14,0,0,1,40,50H93.3a14.1,14.1,0,0,1,8.4,2.8l27.8,20.8a1.6,1.6,0,0,0,1.2.4H216A14,14,0,0,1,230,88Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M203.3,195.4a28,28,0,1,0-30.6,0A36.4,36.4,0,0,0,153.1,219a4.2,4.2,0,0,0,2.9,4.9h1a4.1,4.1,0,0,0,3.9-3,28,28,0,0,1,54.2,0,4,4,0,0,0,7.8-2A36.4,36.4,0,0,0,203.3,195.4ZM168,172a20,20,0,1,1,20,20A20.1,20.1,0,0,1,168,172Zm60-84v32a4,4,0,0,1-8,0V88a4,4,0,0,0-4-4H130.7a12.2,12.2,0,0,1-7.2-2.4L95.7,60.8a4.1,4.1,0,0,0-2.4-.8H40a4,4,0,0,0-4,4V200a4,4,0,0,0,4,4h80a4,4,0,0,1,0,8H40a12,12,0,0,1-12-12V64A12,12,0,0,1,40,52H93.3a12.2,12.2,0,0,1,7.2,2.4l27.8,20.8a4.1,4.1,0,0,0,2.4.8H216A12,12,0,0,1,228,88Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M210.4,194.8a32,32,0,1,0-44.8,0A40.2,40.2,0,0,0,149.3,218a7.9,7.9,0,0,0,5.7,9.7l2,.3a8.1,8.1,0,0,0,7.8-6,24,24,0,0,1,46.4,0,8,8,0,0,0,9.8,5.7,7.9,7.9,0,0,0,5.7-9.7A40.2,40.2,0,0,0,210.4,194.8ZM172,172a16,16,0,1,1,16,16A16,16,0,0,1,172,172Zm60-84v32a8,8,0,0,1-16,0V88H130.7a15.6,15.6,0,0,1-9.6-3.2L93.3,64H40V200h80a8,8,0,0,1,0,16H40a16,16,0,0,1-16-16V64A16,16,0,0,1,40,48H93.3a15.6,15.6,0,0,1,9.6,3.2L130.7,72H216A16,16,0,0,1,232,88Z" />
        </>
      )}
    </svg>
  );
}

export function PhFolderSimple(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216.9,220H40a20.1,20.1,0,0,1-20-20V64A20.1,20.1,0,0,1,40,44H93.3a19.9,19.9,0,0,1,12,4L132,68h84a20.1,20.1,0,0,1,20,20V200.9A19.2,19.2,0,0,1,216.9,220ZM44,196H212V92H130.7a19.9,19.9,0,0,1-12-4L92,68H44Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M224,88V200.9a7.1,7.1,0,0,1-7.1,7.1H40a8,8,0,0,1-8-8V64a8,8,0,0,1,8-8H93.3a7.9,7.9,0,0,1,4.8,1.6l27.8,20.8a8.1,8.1,0,0,0,4.8,1.6H216A8,8,0,0,1,224,88Z" />
          </g>
          <path d="M216,72H130.7L102.9,51.2A15.6,15.6,0,0,0,93.3,48H40A16,16,0,0,0,24,64V200a16,16,0,0,0,16,16H216.9A15.2,15.2,0,0,0,232,200.9V88A16,16,0,0,0,216,72Zm0,128H40V64H93.3l27.8,20.8a15.6,15.6,0,0,0,9.6,3.2H216Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M232,88V200.9A15.2,15.2,0,0,1,216.9,216H40a16,16,0,0,1-16-16V64A16,16,0,0,1,40,48H93.3a15.6,15.6,0,0,1,9.6,3.2L130.7,72H216A16,16,0,0,1,232,88Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M216.9,214H40a14,14,0,0,1-14-14V64A14,14,0,0,1,40,50H93.3a14.1,14.1,0,0,1,8.4,2.8l27.8,20.8a1.6,1.6,0,0,0,1.2.4H216a14,14,0,0,1,14,14V200.9A13.1,13.1,0,0,1,216.9,214ZM40,62a2,2,0,0,0-2,2V200a2,2,0,0,0,2,2H216.9a1.1,1.1,0,0,0,1.1-1.1V88a2,2,0,0,0-2-2H130.7a14.1,14.1,0,0,1-8.4-2.8L94.5,62.4a1.6,1.6,0,0,0-1.2-.4Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M216.9,212H40a12,12,0,0,1-12-12V64A12,12,0,0,1,40,52H93.3a12.2,12.2,0,0,1,7.2,2.4l27.8,20.8a4.1,4.1,0,0,0,2.4.8H216a12,12,0,0,1,12,12V200.9A11.1,11.1,0,0,1,216.9,212ZM40,60a4,4,0,0,0-4,4V200a4,4,0,0,0,4,4H216.9a3.1,3.1,0,0,0,3.1-3.1V88a4,4,0,0,0-4-4H130.7a12.2,12.2,0,0,1-7.2-2.4L95.7,60.8a4.1,4.1,0,0,0-2.4-.8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216.9,216H40a16,16,0,0,1-16-16V64A16,16,0,0,1,40,48H93.3a15.6,15.6,0,0,1,9.6,3.2L130.7,72H216a16,16,0,0,1,16,16V200.9A15.2,15.2,0,0,1,216.9,216ZM40,64V200H216V88H130.7a15.6,15.6,0,0,1-9.6-3.2L93.3,64Z" />
        </>
      )}
    </svg>
  );
}

export function PhFolderStar(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M112.6,196H44V92H212v20a12,12,0,0,0,24,0V88a20.1,20.1,0,0,0-20-20H133.4l-26-29.3h0a20,20,0,0,0-15-6.7H40A20.1,20.1,0,0,0,20,52V200.6A19.4,19.4,0,0,0,39.4,220h73.2a12,12,0,1,0,0-24ZM44,56H90.6l10.7,12H44ZM247.4,158a11.9,11.9,0,0,0-10.5-8.4l-27.4-2.1L199,123.2a12,12,0,0,0-22,0l-10.5,24.3-27.4,2.1a12.1,12.1,0,0,0-6.7,21.3l20.5,17-6.2,25.2a12,12,0,0,0,17.7,13.2L188,212.5l23.6,13.8a11.3,11.3,0,0,0,6.1,1.7,11.7,11.7,0,0,0,7.1-2.4,11.9,11.9,0,0,0,4.5-12.5l-6.2-25.2,20.5-17A11.9,11.9,0,0,0,247.4,158ZM202,174.2a11.6,11.6,0,0,0-4,12.1l1.2,4.9-5.1-3a11.8,11.8,0,0,0-12.2,0l-5.1,3,1.2-4.9a11.6,11.6,0,0,0-4-12.1l-3.4-2.9,5-.4a12,12,0,0,0,10.1-7.2l2.3-5.4,2.3,5.4a12,12,0,0,0,10.1,7.2l5,.4Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M209.6,183.4l8.1,32.6L188,198.5,158.3,216l8.1-32.6L140,161.6l34.7-2.7L188,128l13.3,30.9,34.7,2.7Z"
            opacity="0.2"
          />
          <path d="M120.6,200H40V88H216v32a8,8,0,0,0,16,0V88a16,16,0,0,0-16-16H131.3L104,44.7A15.9,15.9,0,0,0,92.7,40H40A16,16,0,0,0,24,56V200.6A15.4,15.4,0,0,0,39.4,216h81.2a8,8,0,1,0,0-16ZM92.7,56l16,16H40V56ZM243.6,159.2a8.1,8.1,0,0,0-7-5.6l-29.8-2.3-11.5-26.5a8,8,0,0,0-14.6,0l-11.5,26.5-29.8,2.3a8.1,8.1,0,0,0-4.5,14.2l22.5,18.6-6.8,27.7a7.9,7.9,0,0,0,3,8.3,8,8,0,0,0,8.8.5L188,207.8l25.6,15.1a8.7,8.7,0,0,0,4.1,1.1,7.4,7.4,0,0,0,4.7-1.6,7.9,7.9,0,0,0,3-8.3l-6.8-27.7,22.5-18.6A8,8,0,0,0,243.6,159.2Zm-39.1,18a8.1,8.1,0,0,0-2.7,8.1l3.5,14.2-13.2-7.9a8.2,8.2,0,0,0-8.2,0l-13.2,7.9,3.5-14.2a8.1,8.1,0,0,0-2.7-8.1l-11.1-9.1,14.9-1.2a8.1,8.1,0,0,0,6.7-4.8l6-13.9,6,13.9a8.1,8.1,0,0,0,6.7,4.8l14.9,1.2Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M120.6,200H40V88H216v32a8,8,0,0,0,16,0V88a16,16,0,0,0-16-16H131.3L104,44.7A15.9,15.9,0,0,0,92.7,40H40A16,16,0,0,0,24,56V200.6A15.4,15.4,0,0,0,39.4,216h81.2a8,8,0,1,0,0-16ZM40,56H92.7l16,16H40ZM241.1,167.8l-22.5,18.6,6.8,27.7a7.9,7.9,0,0,1-3,8.3,7.4,7.4,0,0,1-4.7,1.6,8.7,8.7,0,0,1-4.1-1.1L188,207.8l-25.6,15.1a8,8,0,0,1-8.8-.5,7.9,7.9,0,0,1-3-8.3l6.8-27.7-22.5-18.6a8.1,8.1,0,0,1,4.5-14.2l29.8-2.3,11.5-26.5a8,8,0,0,1,14.6,0l11.5,26.5,29.8,2.3a8.1,8.1,0,0,1,4.5,14.2Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M241.7,159.8a6,6,0,0,0-5.2-4.2l-31.1-2.4-11.9-27.6a6,6,0,0,0-11,0l-11.9,27.6-31.1,2.4a6,6,0,0,0-5.2,4.2,5.8,5.8,0,0,0,1.9,6.4l23.5,19.4-7.2,29a5.9,5.9,0,0,0,2.2,6.2,6.1,6.1,0,0,0,6.7.4L188,205.5l26.6,15.7a6.8,6.8,0,0,0,3.1.8,6.2,6.2,0,0,0,3.6-1.2,5.9,5.9,0,0,0,2.2-6.2l-7.2-29,23.5-19.4A5.8,5.8,0,0,0,241.7,159.8Zm-35.9,19a5.9,5.9,0,0,0-2,6.1l4.6,18.7L191,193.4a5.5,5.5,0,0,0-6,0l-17.4,10.2,4.6-18.7a5.9,5.9,0,0,0-2-6.1l-14.9-12.3,19.8-1.6a6.1,6.1,0,0,0,5.1-3.6l7.8-18.2,7.8,18.2a6.1,6.1,0,0,0,5.1,3.6l19.8,1.6ZM120.6,202H39.4a1.4,1.4,0,0,1-1.4-1.4V86H216a2,2,0,0,1,2,2v32a6,6,0,0,0,12,0V88a14,14,0,0,0-14-14H130.5L102.6,46.1A14.3,14.3,0,0,0,92.7,42H40A14,14,0,0,0,26,56V200.6A13.4,13.4,0,0,0,39.4,214h81.2a6,6,0,1,0,0-12ZM38,56a2,2,0,0,1,2-2H92.7a2,2,0,0,1,1.4.6L113.5,74H38Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M120.6,204H39.4a3.4,3.4,0,0,1-3.4-3.4V84H216a4,4,0,0,1,4,4v32a4,4,0,0,0,8,0V88a12,12,0,0,0-12-12H129.7L101.2,47.5A11.9,11.9,0,0,0,92.7,44H40A12,12,0,0,0,28,56V200.6A11.4,11.4,0,0,0,39.4,212h81.2a4,4,0,1,0,0-8ZM36,56a4,4,0,0,1,4-4H92.7a3.8,3.8,0,0,1,2.8,1.2L118.3,76H36ZM239.8,160.4a4.3,4.3,0,0,0-3.5-2.8l-32.2-2.5-12.4-28.7a4.1,4.1,0,0,0-7.4,0l-12.4,28.7-32.2,2.5a4.3,4.3,0,0,0-3.5,2.8,3.9,3.9,0,0,0,1.3,4.3l24.4,20.2L154.5,215a4.1,4.1,0,0,0,1.4,4.2,3.9,3.9,0,0,0,4.5.2L188,203.2l27.6,16.2a4.2,4.2,0,0,0,2.1.6,4.3,4.3,0,0,0,2.4-.8,4.1,4.1,0,0,0,1.4-4.2l-7.4-30.1,24.4-20.2A3.9,3.9,0,0,0,239.8,160.4Zm-32.7,19.9a4,4,0,0,0-1.4,4.1l5.8,23.3L190,195.1a3.6,3.6,0,0,0-4,0l-21.5,12.6,5.8-23.3a4,4,0,0,0-1.4-4.1l-18.7-15.5,24.8-1.9a3.9,3.9,0,0,0,3.3-2.4l9.7-22.4,9.7,22.4a3.9,3.9,0,0,0,3.3,2.4l24.8,1.9Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M120.6,200H40V88H216v32a8,8,0,0,0,16,0V88a16,16,0,0,0-16-16H131.3L104,44.7A15.9,15.9,0,0,0,92.7,40H40A16,16,0,0,0,24,56V200.6A15.4,15.4,0,0,0,39.4,216h81.2a8,8,0,1,0,0-16ZM92.7,56l16,16H40V56ZM243.6,159.2a8.1,8.1,0,0,0-7-5.6l-29.8-2.3-11.5-26.5a8,8,0,0,0-14.6,0l-11.5,26.5-29.8,2.3a8.1,8.1,0,0,0-4.5,14.2l22.5,18.6-6.8,27.7a7.9,7.9,0,0,0,3,8.3,8,8,0,0,0,8.8.5L188,207.8l25.6,15.1a8.7,8.7,0,0,0,4.1,1.1,7.4,7.4,0,0,0,4.7-1.6,7.9,7.9,0,0,0,3-8.3l-6.8-27.7,22.5-18.6A8,8,0,0,0,243.6,159.2Zm-39.1,18a8.1,8.1,0,0,0-2.7,8.1l3.5,14.2-13.2-7.9a8.2,8.2,0,0,0-8.2,0l-13.2,7.9,3.5-14.2a8.1,8.1,0,0,0-2.7-8.1l-11.1-9.1,14.9-1.2a8.1,8.1,0,0,0,6.7-4.8l6-13.9,6,13.9a8.1,8.1,0,0,0,6.7,4.8l14.9,1.2Z" />
        </>
      )}
    </svg>
  );
}

export function PhFolderUser(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216.3,194.3a36,36,0,1,0-56.6,0A43.7,43.7,0,0,0,145.4,217a11.9,11.9,0,0,0,8.6,14.6,10.3,10.3,0,0,0,3,.4,12,12,0,0,0,11.6-9,20,20,0,0,1,38.8,0,12,12,0,1,0,23.2-6A43.7,43.7,0,0,0,216.3,194.3ZM176,172a12,12,0,1,1,12,12A12,12,0,0,1,176,172ZM216,68H133.4l-26-29.3a20,20,0,0,0-15-6.7H40A20.1,20.1,0,0,0,20,52V200.6A19.4,19.4,0,0,0,39.4,220h73.2a12,12,0,1,0,0-24H44V92H212v16a12,12,0,0,0,24,0V88A20.1,20.1,0,0,0,216,68ZM44,68V56H90.6l10.7,12Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M212,172a24,24,0,1,1-24-24A23.9,23.9,0,0,1,212,172Z"
            opacity="0.2"
          />
          <path d="M210.4,194.8a32,32,0,1,0-44.8,0A40.2,40.2,0,0,0,149.3,218a7.9,7.9,0,0,0,5.7,9.7l2,.3a8.1,8.1,0,0,0,7.8-6,24,24,0,0,1,46.4,0,8,8,0,0,0,9.8,5.7,7.9,7.9,0,0,0,5.7-9.7A40.2,40.2,0,0,0,210.4,194.8ZM172,172a16,16,0,1,1,16,16A16,16,0,0,1,172,172ZM216,72H131.3L104,44.7A15.9,15.9,0,0,0,92.7,40H40A16,16,0,0,0,24,56V200.6A15.4,15.4,0,0,0,39.4,216h81.2a8,8,0,1,0,0-16H40V88H216v32a8,8,0,0,0,16,0V88A16,16,0,0,0,216,72ZM92.7,56l16,16H40V56Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,72H131.3L104,44.7A15.9,15.9,0,0,0,92.7,40H40A16,16,0,0,0,24,56V200.6A15.4,15.4,0,0,0,39.4,216h81.2a8,8,0,1,0,0-16H40V88H216v32a8,8,0,0,0,16,0V88A16,16,0,0,0,216,72ZM40,72V56H92.7l16,16ZM226.7,218a8,8,0,0,1-7.7,10H157a8,8,0,0,1-7.7-10,40.2,40.2,0,0,1,16.3-23.2,32,32,0,1,1,44.8,0A40.2,40.2,0,0,1,226.7,218Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M207.1,195.1a30,30,0,1,0-38.2,0,38,38,0,0,0-17.7,23.4,6,6,0,0,0,4.3,7.3l1.5.2a6,6,0,0,0,5.8-4.5,26,26,0,0,1,50.4,0,6,6,0,1,0,11.6-3A38,38,0,0,0,207.1,195.1ZM170,172a18,18,0,1,1,18,18A18.1,18.1,0,0,1,170,172Zm46-98H130.5L102.6,46.1A14.3,14.3,0,0,0,92.7,42H40A14,14,0,0,0,26,56V200.6A13.4,13.4,0,0,0,39.4,214h81.2a6,6,0,1,0,0-12H39.4a1.4,1.4,0,0,1-1.4-1.4V86H216a2,2,0,0,1,2,2v32a6,6,0,0,0,12,0V88A14,14,0,0,0,216,74ZM40,54H92.7a2,2,0,0,1,1.4.6L113.5,74H38V56A2,2,0,0,1,40,54Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M203.3,195.4a28,28,0,1,0-30.6,0A36.4,36.4,0,0,0,153.1,219a4.2,4.2,0,0,0,2.9,4.9h1a4.1,4.1,0,0,0,3.9-3,28,28,0,0,1,54.2,0,4,4,0,0,0,7.8-2A36.4,36.4,0,0,0,203.3,195.4ZM168,172a20,20,0,1,1,20,20A20.1,20.1,0,0,1,168,172Zm48-96H129.7L101.2,47.5A11.9,11.9,0,0,0,92.7,44H40A12,12,0,0,0,28,56V200.6A11.4,11.4,0,0,0,39.4,212h81.2a4,4,0,1,0,0-8H39.4a3.4,3.4,0,0,1-3.4-3.4V84H216a4,4,0,0,1,4,4v32a4,4,0,0,0,8,0V88A12,12,0,0,0,216,76ZM40,52H92.7a3.6,3.6,0,0,1,2.8,1.2L118.3,76H36V56A4,4,0,0,1,40,52Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M210.4,194.8a32,32,0,1,0-44.8,0A40.2,40.2,0,0,0,149.3,218a7.9,7.9,0,0,0,5.7,9.7l2,.3a8.1,8.1,0,0,0,7.8-6,24,24,0,0,1,46.4,0,8,8,0,0,0,9.8,5.7,7.9,7.9,0,0,0,5.7-9.7A40.2,40.2,0,0,0,210.4,194.8ZM172,172a16,16,0,1,1,16,16A16,16,0,0,1,172,172ZM216,72H131.3L104,44.7A15.9,15.9,0,0,0,92.7,40H40A16,16,0,0,0,24,56V200.6A15.4,15.4,0,0,0,39.4,216h81.2a8,8,0,1,0,0-16H40V88H216v32a8,8,0,0,0,16,0V88A16,16,0,0,0,216,72ZM92.7,56l16,16H40V56Z" />
        </>
      )}
    </svg>
  );
}

export function PhFolder(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216,68H133.4l-26-29.3h0a20,20,0,0,0-15-6.7H40A20.1,20.1,0,0,0,20,52V200.6A19.4,19.4,0,0,0,39.4,220H216.9A19.2,19.2,0,0,0,236,200.9V88A20.1,20.1,0,0,0,216,68ZM44,56H90.6l10.7,12H44ZM212,196H44V92H212Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M98.3,50.3,128,80H32V56a8,8,0,0,1,8-8H92.7A7.9,7.9,0,0,1,98.3,50.3Z" />
          </g>
          <path d="M216,72H131.3L104,44.7A15.9,15.9,0,0,0,92.7,40H40A16,16,0,0,0,24,56V200.6A15.4,15.4,0,0,0,39.4,216H216.9A15.2,15.2,0,0,0,232,200.9V88A16,16,0,0,0,216,72ZM92.7,56l16,16H40V56ZM216,200H40V88H216Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,72H131.3L104,44.7A15.9,15.9,0,0,0,92.7,40H40A16,16,0,0,0,24,56V200.6A15.4,15.4,0,0,0,39.4,216H216.9A15.2,15.2,0,0,0,232,200.9V88A16,16,0,0,0,216,72ZM40,56H92.7l16,16H40Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M216,74H130.5L102.6,46.1A14.3,14.3,0,0,0,92.7,42H40A14,14,0,0,0,26,56V200.6A13.4,13.4,0,0,0,39.4,214H216.9A13.1,13.1,0,0,0,230,200.9V88A14,14,0,0,0,216,74ZM40,54H92.7a2,2,0,0,1,1.4.6L113.5,74H38V56A2,2,0,0,1,40,54ZM218,200.9a1.1,1.1,0,0,1-1.1,1.1H39.4a1.4,1.4,0,0,1-1.4-1.4V86H216a2,2,0,0,1,2,2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M216,76H129.7L101.2,47.5A11.9,11.9,0,0,0,92.7,44H40A12,12,0,0,0,28,56V200.6A11.4,11.4,0,0,0,39.4,212H216.9A11.1,11.1,0,0,0,228,200.9V88A12,12,0,0,0,216,76ZM40,52H92.7a3.6,3.6,0,0,1,2.8,1.2L118.3,76H36V56A4,4,0,0,1,40,52ZM220,200.9a3.1,3.1,0,0,1-3.1,3.1H39.4a3.4,3.4,0,0,1-3.4-3.4V84H216a4,4,0,0,1,4,4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,72H131.3L104,44.7A15.9,15.9,0,0,0,92.7,40H40A16,16,0,0,0,24,56V200.6A15.4,15.4,0,0,0,39.4,216H216.9A15.2,15.2,0,0,0,232,200.9V88A16,16,0,0,0,216,72ZM92.7,56l16,16H40V56ZM216,200H40V88H216Z" />
        </>
      )}
    </svg>
  );
}

export function PhFolders(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M228,56H160L133.3,36a19.9,19.9,0,0,0-12-4H76A20.1,20.1,0,0,0,56,52V72H36A20.1,20.1,0,0,0,16,92V204a20.1,20.1,0,0,0,20,20H188.9A19.2,19.2,0,0,0,208,204.9V184h20.9A19.2,19.2,0,0,0,248,164.9V76A20.1,20.1,0,0,0,228,56ZM184,200H40V96H80l26.7,20a19.9,19.9,0,0,0,12,4H184Zm40-40H208V116a20.1,20.1,0,0,0-20-20H120L93.3,76a19.9,19.9,0,0,0-12-4H80V56h40l26.7,20a19.9,19.9,0,0,0,12,4H224Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M224,72H154.7a8.1,8.1,0,0,1-4.8-1.6L122.1,49.6a8.1,8.1,0,0,0-4.8-1.6H72a8,8,0,0,0-8,8V80H85.3a8.1,8.1,0,0,1,4.8,1.6l27.8,20.8a8.1,8.1,0,0,0,4.8,1.6H192a8,8,0,0,1,8,8v64h24.9a7.1,7.1,0,0,0,7.1-7.1V80A8,8,0,0,0,224,72Z" />
          </g>
          <path d="M224,64H154.7L126.9,43.2a15.6,15.6,0,0,0-9.6-3.2H72A16,16,0,0,0,56,56V72H40A16,16,0,0,0,24,88V200a16,16,0,0,0,16,16H192.9A15.2,15.2,0,0,0,208,200.9V184h16.9A15.2,15.2,0,0,0,240,168.9V80A16,16,0,0,0,224,64ZM192,200H40V88H85.3l27.8,20.8a15.6,15.6,0,0,0,9.6,3.2H192Zm32-32H208V112a16,16,0,0,0-16-16H122.7L94.9,75.2A15.6,15.6,0,0,0,85.3,72H72V56h45.3l27.8,20.8a15.6,15.6,0,0,0,9.6,3.2H224Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M224,64H154.7L126.9,43.2a15.6,15.6,0,0,0-9.6-3.2H72A16,16,0,0,0,56,56V72H40A16,16,0,0,0,24,88V200a16,16,0,0,0,16,16H192.9A15.2,15.2,0,0,0,208,200.9V184h16.9A15.2,15.2,0,0,0,240,168.9V80A16,16,0,0,0,224,64Zm0,104H208V112a16,16,0,0,0-16-16H122.7L94.9,75.2A15.6,15.6,0,0,0,85.3,72H72V56h45.3l27.8,20.8a15.6,15.6,0,0,0,9.6,3.2H224Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M224,66H154.7a1.6,1.6,0,0,1-1.2-.4L125.7,44.8a14.1,14.1,0,0,0-8.4-2.8H72A14,14,0,0,0,58,56V74H40A14,14,0,0,0,26,88V200a14,14,0,0,0,14,14H192.9A13.1,13.1,0,0,0,206,200.9V182h18.9A13.1,13.1,0,0,0,238,168.9V80A14,14,0,0,0,224,66ZM194,200.9a1.1,1.1,0,0,1-1.1,1.1H40a2,2,0,0,1-2-2V88a2,2,0,0,1,2-2H85.3a1.6,1.6,0,0,1,1.2.4l27.8,20.8a14.1,14.1,0,0,0,8.4,2.8H192a2,2,0,0,1,2,2Zm32-32a1.1,1.1,0,0,1-1.1,1.1H206V112a14,14,0,0,0-14-14H122.7a1.6,1.6,0,0,1-1.2-.4L93.7,76.8A14.1,14.1,0,0,0,85.3,74H70V56a2,2,0,0,1,2-2h45.3a1.6,1.6,0,0,1,1.2.4l27.8,20.8a14.1,14.1,0,0,0,8.4,2.8H224a2,2,0,0,1,2,2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M224,68H154.7a4.1,4.1,0,0,1-2.4-.8L124.5,46.4a12.2,12.2,0,0,0-7.2-2.4H72A12,12,0,0,0,60,56V76H40A12,12,0,0,0,28,88V200a12,12,0,0,0,12,12H192.9A11.1,11.1,0,0,0,204,200.9V180h20.9A11.1,11.1,0,0,0,236,168.9V80A12,12,0,0,0,224,68ZM196,200.9a3.1,3.1,0,0,1-3.1,3.1H40a4,4,0,0,1-4-4V88a4,4,0,0,1,4-4H85.3a4.1,4.1,0,0,1,2.4.8l27.8,20.8a12.2,12.2,0,0,0,7.2,2.4H192a4,4,0,0,1,4,4Zm32-32a3.1,3.1,0,0,1-3.1,3.1H204V112a12,12,0,0,0-12-12H122.7a4.1,4.1,0,0,1-2.4-.8L92.5,78.4A12.2,12.2,0,0,0,85.3,76H68V56a4,4,0,0,1,4-4h45.3a4.1,4.1,0,0,1,2.4.8l27.8,20.8a12.2,12.2,0,0,0,7.2,2.4H224a4,4,0,0,1,4,4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M224,64H154.7L126.9,43.2a15.6,15.6,0,0,0-9.6-3.2H72A16,16,0,0,0,56,56V72H40A16,16,0,0,0,24,88V200a16,16,0,0,0,16,16H192.9A15.2,15.2,0,0,0,208,200.9V184h16.9A15.2,15.2,0,0,0,240,168.9V80A16,16,0,0,0,224,64ZM192,200H40V88H85.3l27.8,20.8a15.6,15.6,0,0,0,9.6,3.2H192Zm32-32H208V112a16,16,0,0,0-16-16H122.7L94.9,75.2A15.6,15.6,0,0,0,85.3,72H72V56h45.3l27.8,20.8a15.6,15.6,0,0,0,9.6,3.2H224Z" />
        </>
      )}
    </svg>
  );
}

export function PhFootball(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M229.9,55.6a35.9,35.9,0,0,0-29.5-29.5c-32.2-5.5-92.7-7.9-137.5,36.8S20.6,168.2,26.1,200.4a35.9,35.9,0,0,0,29.5,29.5,202.5,202.5,0,0,0,34,2.9c31.9,0,71.7-8,103.5-39.7C237.8,148.3,235.4,87.8,229.9,55.6Zm-23.6,4c1.1,7,1.9,13.7,2.3,20L176.4,47.4c6.3.4,13,1.2,20,2.3A12.4,12.4,0,0,1,206.3,59.6ZM49.7,196.4c-1.1-7-1.9-13.7-2.3-20l32.2,32.2c-6.3-.4-13-1.2-20-2.3A12.4,12.4,0,0,1,49.7,196.4Zm126.4-20.3c-10.9,10.9-31.4,26.1-63.9,31.1L48.8,143.8c5-32.5,20.2-53,31.1-63.9s31.4-26.1,63.9-31.1l63.4,63.4C202.2,144.7,187,165.2,176.1,176.1Zm-7.6-88.6a12,12,0,0,1,0,17l-12.2,12.2,6.1,6.1a12,12,0,1,1-16.9,17l-6.2-6.1-5.6,5.6,6.1,6.2a11.9,11.9,0,0,1,0,16.9,11.6,11.6,0,0,1-8.5,3.5,11.8,11.8,0,0,1-8.5-3.5l-6.1-6.1-12.2,12.2a12.1,12.1,0,0,1-17,0,12,12,0,0,1,0-17l12.2-12.2-6.1-6.1a12,12,0,0,1,0-17,11.9,11.9,0,0,1,16.9,0l6.2,6.1,5.6-5.6-6.1-6.2a11.9,11.9,0,0,1,0-16.9,12,12,0,0,1,17,0l6.1,6.1,12.2-12.2A12,12,0,0,1,168.5,87.5Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M219.8,107.8c-2.8,25.2-12,53.5-35.2,76.8S133,217,107.8,219.8L36.2,148.2C39,123,48.2,94.7,71.4,71.4S123,39,148.2,36.2h0l71.6,71.6Z"
            opacity="0.2"
          />
          <path d="M226,56.3A32.3,32.3,0,0,0,199.7,30c-31.4-5.3-90.4-7.7-133.9,35.8S24.7,168.3,30,199.7A32.3,32.3,0,0,0,56.3,226a206.3,206.3,0,0,0,33.3,2.8c31.1,0,69.8-7.7,100.6-38.6C233.7,146.7,231.3,87.7,226,56.3ZM59,210.2A15.9,15.9,0,0,1,45.8,197a178.7,178.7,0,0,1-2.7-30.6l46.5,46.5A179.5,179.5,0,0,1,59,210.2Zm119.9-31.3c-11.5,11.6-33.4,27.8-68.1,32.6L44.5,145.2c4.9-34.7,21-56.6,32.6-68.1s33.4-27.8,68.1-32.6l66.3,66.3C206.6,145.5,190.5,167.4,178.9,178.9ZM166.4,43.1A179.5,179.5,0,0,1,197,45.8,15.9,15.9,0,0,1,210.2,59a178.7,178.7,0,0,1,2.7,30.6Zm-27.1,62.3,15-15.1a8.1,8.1,0,0,1,11.4,11.4l-15.1,15,9,9a8,8,0,0,1,0,11.3,8.3,8.3,0,0,1-5.7,2.3,8,8,0,0,1-5.6-2.3l-9-9L128,139.3l9,9a8,8,0,0,1,0,11.3,7.8,7.8,0,0,1-5.7,2.3,7.6,7.6,0,0,1-5.6-2.3l-9-9-15,15.1a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4l15.1-15-9-9A8,8,0,0,1,107.7,119l9,9L128,116.7l-9-9a8,8,0,0,1,11.3-11.3Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M226,56.3A32.3,32.3,0,0,0,199.7,30c-31.4-5.3-90.4-7.7-133.9,35.8S24.7,168.3,30,199.7A32.3,32.3,0,0,0,56.3,226a206.3,206.3,0,0,0,33.3,2.8c31.1,0,69.8-7.7,100.6-38.6C233.7,146.7,231.3,87.7,226,56.3ZM59,210.2A15.9,15.9,0,0,1,45.8,197a178.7,178.7,0,0,1-2.7-30.6l46.5,46.5A179.5,179.5,0,0,1,59,210.2ZM165.7,101.7l-15.1,15,9,9a8,8,0,0,1,0,11.3,8.3,8.3,0,0,1-5.7,2.3,8,8,0,0,1-5.6-2.3l-9-9L128,139.3l9,9a8,8,0,0,1,0,11.3,7.8,7.8,0,0,1-5.7,2.3,7.6,7.6,0,0,1-5.6-2.3l-9-9-15,15.1a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4l15.1-15-9-9A8,8,0,0,1,107.7,119l9,9L128,116.7l-9-9a8,8,0,0,1,11.3-11.3l9,9,15-15.1a8.1,8.1,0,0,1,11.4,11.4Zm.7-58.6A179.5,179.5,0,0,1,197,45.8,15.9,15.9,0,0,1,210.2,59a178.7,178.7,0,0,1,2.7,30.6Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M224,56.6A30,30,0,0,0,199.4,32c-31-5.3-89.4-7.7-132.2,35.2S26.7,168.4,32,199.4A30,30,0,0,0,56.6,224a203.3,203.3,0,0,0,33,2.8c30.7,0,68.8-7.6,99.2-38C231.7,146,229.3,87.6,224,56.6ZM166.1,41.1a187.4,187.4,0,0,1,31.3,2.7,18.3,18.3,0,0,1,14.8,14.8,183.5,183.5,0,0,1,2.6,35.8L161.6,41.2ZM58.6,212.2a18.3,18.3,0,0,1-14.8-14.8,183.5,183.5,0,0,1-2.6-35.8l53.2,53.2A183.5,183.5,0,0,1,58.6,212.2Zm121.7-31.9c-11.8,11.9-34.4,28.6-70.2,33.3L42.4,145.9c4.7-35.8,21.4-58.4,33.3-70.2s34.4-28.6,70.2-33.3l67.7,67.7C208.9,145.9,192.2,168.5,180.3,180.3ZM164.2,91.8a5.8,5.8,0,0,1,0,8.4l-16.4,16.5,10.4,10.4a6.1,6.1,0,0,1,0,8.5,6.2,6.2,0,0,1-8.5,0l-10.4-10.4-14.1,14.1,10.4,10.4a6,6,0,0,1-4.3,10.2,5.7,5.7,0,0,1-4.2-1.7l-10.4-10.4-16.5,16.4a5.9,5.9,0,0,1-8.4-8.4l16.4-16.5L97.8,128.9a6,6,0,0,1,8.5-8.5l10.4,10.4,14.1-14.1-10.4-10.4a6,6,0,0,1,8.5-8.5l10.4,10.4,16.5-16.4A5.8,5.8,0,0,1,164.2,91.8Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M222,57a28,28,0,0,0-23-23c-30.6-5.2-88.2-7.6-130.4,34.6S28.8,168.4,34,199a28,28,0,0,0,23,23,197.1,197.1,0,0,0,32.6,2.8c30.3,0,67.9-7.5,97.8-37.4C229.6,145.2,227.2,87.6,222,57ZM166.1,39.1a181.3,181.3,0,0,1,31.6,2.8,20,20,0,0,1,16.4,16.4A183.9,183.9,0,0,1,216.7,99L157,39.3ZM58.3,214.1a20,20,0,0,1-16.4-16.4A183.9,183.9,0,0,1,39.3,157L99,216.7l-9.1.2A181.3,181.3,0,0,1,58.3,214.1Zm123.4-32.4h0c-12.1,12.2-35.4,29.4-72.3,34L40.3,146.6c4.6-36.9,21.8-60.2,34-72.3s35.4-29.4,72.3-34l69.1,69.1C211.1,146.3,193.9,169.6,181.7,181.7ZM162.8,93.2a3.9,3.9,0,0,1,0,5.6L145,116.7l11.8,11.8a4,4,0,0,1,0,5.6,4,4,0,0,1-2.9,1.2,3.9,3.9,0,0,1-2.8-1.2l-11.8-11.8-17,17,11.8,11.8a4,4,0,0,1,0,5.7,3.7,3.7,0,0,1-2.8,1.1,3.8,3.8,0,0,1-2.8-1.1L116.7,145,98.8,162.8a3.9,3.9,0,0,1-5.6,0,3.9,3.9,0,0,1,0-5.6L111,139.3,99.2,127.5a4,4,0,0,1,0-5.6,4,4,0,0,1,5.7,0l11.8,11.8,17-17-11.8-11.8a4,4,0,0,1,0-5.7,4,4,0,0,1,5.6,0L139.3,111l17.9-17.8A3.9,3.9,0,0,1,162.8,93.2Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M226,56.3A32.3,32.3,0,0,0,199.7,30c-31.4-5.3-90.4-7.7-133.9,35.8S24.7,168.3,30,199.7A32.3,32.3,0,0,0,56.3,226a206.3,206.3,0,0,0,33.3,2.8c31.1,0,69.8-7.7,100.6-38.6C233.7,146.7,231.3,87.7,226,56.3ZM210.2,59a178.7,178.7,0,0,1,2.7,30.6L166.4,43.1A179.5,179.5,0,0,1,197,45.8,15.9,15.9,0,0,1,210.2,59ZM59,210.2A15.9,15.9,0,0,1,45.8,197a178.7,178.7,0,0,1-2.7-30.6l46.5,46.5A179.5,179.5,0,0,1,59,210.2Zm119.9-31.3c-11.5,11.6-33.4,27.8-68.1,32.6L44.5,145.2c4.9-34.7,21-56.6,32.6-68.1s33.4-27.8,68.1-32.6l66.3,66.3C206.6,145.5,190.5,167.4,178.9,178.9ZM165.7,90.3a8.1,8.1,0,0,1,0,11.4l-15.1,15,9,9a8,8,0,0,1,0,11.3,8.3,8.3,0,0,1-5.7,2.3,8,8,0,0,1-5.6-2.3l-9-9L128,139.3l9,9a8,8,0,0,1,0,11.3,7.8,7.8,0,0,1-5.7,2.3,7.6,7.6,0,0,1-5.6-2.3l-9-9-15,15.1a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4l15.1-15-9-9A8,8,0,0,1,107.7,119l9,9L128,116.7l-9-9a8,8,0,0,1,11.3-11.3l9,9,15-15.1A8.1,8.1,0,0,1,165.7,90.3Z" />
        </>
      )}
    </svg>
  );
}

export function PhForkKnife(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M72,72V32a12,12,0,0,1,24,0V72a12,12,0,0,1-24,0Zm48-52a12,12,0,0,0-12,12V80a24,24,0,0,1-48,0V32a12,12,0,0,0-24,0V80a48.2,48.2,0,0,0,36,46.5V224a12,12,0,0,0,24,0V126.5A48.2,48.2,0,0,0,132,80V32A12,12,0,0,0,120,20Zm96,12V224a12,12,0,0,1-24,0V172H148a12,12,0,0,1-11.9-13.3,398,398,0,0,1,11.9-60c12.3-43.7,29.8-69.9,51.9-78A12,12,0,0,1,216,32ZM192,57c-7.8,10.8-15,27.2-21,48.3a385,385,0,0,0-9.2,42.7H192Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M204,160H148S160,48,204,32" />
          </g>
          <path d="M84,80a8,8,0,0,0,8-8V32a8,8,0,0,0-16,0V72A8,8,0,0,0,84,80Z" />
          <path d="M119.9,30.7a8,8,0,1,0-15.8,2.6L112,80.6a28,28,0,0,1-56,0l7.9-47.3a8,8,0,1,0-15.8-2.6l-8,48A5.7,5.7,0,0,0,40,80a44.1,44.1,0,0,0,36,43.3V224a8,8,0,0,0,16,0V123.3A44.1,44.1,0,0,0,128,80a5.7,5.7,0,0,0-.1-1.3Z" />
          <path d="M208.6,25.4a8.2,8.2,0,0,0-7.3-.9c-20.8,7.5-37.5,32.9-49.5,75.3A412.8,412.8,0,0,0,140,159.1a8.1,8.1,0,0,0,2,6.2,8.2,8.2,0,0,0,6,2.7h48v56a8,8,0,0,0,16,0V32A8.2,8.2,0,0,0,208.6,25.4ZM196,152H157.1a402.3,402.3,0,0,1,10.1-47.8c7.9-28,17.7-47.6,28.8-57.8Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M127.9,78.8A4.9,4.9,0,0,1,128,80a44.1,44.1,0,0,1-36,43.3V224a8,8,0,0,1-16,0V123.3A44.1,44.1,0,0,1,40,80a4.9,4.9,0,0,1,.1-1.2h0l8-48a8,8,0,1,1,15.8,2.6L57.4,72H76V32a8,8,0,0,1,16,0V72h18.6l-6.5-38.7a8,8,0,1,1,15.8-2.6l8,48Zm80.7-53.4a8.2,8.2,0,0,0-7.3-.9c-20.8,7.5-37.5,32.9-49.5,75.3A412.8,412.8,0,0,0,140,159.1a8.1,8.1,0,0,0,2,6.2,8.2,8.2,0,0,0,6,2.7h48v56a8,8,0,0,0,16,0V32A8.2,8.2,0,0,0,208.6,25.4Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M78,72V32a6,6,0,0,1,12,0V72a6,6,0,0,1-12,0Zm39.9-41a6,6,0,0,0-11.8,2L114,80.5a30,30,0,0,1-60,0L61.9,33a6,6,0,1,0-11.8-2l-8,48a3.4,3.4,0,0,0-.1,1,42.2,42.2,0,0,0,36,41.6V224a6,6,0,0,0,12,0V121.6A42.2,42.2,0,0,0,126,80a3.4,3.4,0,0,0-.1-1ZM210,32V224a6,6,0,0,1-12,0V166H148a6.1,6.1,0,0,1-4.5-2,6.4,6.4,0,0,1-1.5-4.6,412.4,412.4,0,0,1,11.7-59c11.9-41.8,28.1-66.7,48.2-74A6,6,0,0,1,210,32ZM198,42.1c-25.7,19.4-39.1,81.1-43.2,111.9H198Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M80,72V32a4,4,0,0,1,8,0V72a4,4,0,0,1-8,0Zm35.9-40.7a4,4,0,0,0-7.8,1.4L116,80.3a32,32,0,0,1-64,0l7.9-47.6a4,4,0,1,0-7.8-1.4l-8,48a2,2,0,0,0-.1.7,40,40,0,0,0,36,39.8V224a4,4,0,0,0,8,0V119.8A40,40,0,0,0,124,80a2,2,0,0,0-.1-.7ZM208,32V224a4,4,0,0,1-8,0V164H148a4.3,4.3,0,0,1-3-1.3,4.5,4.5,0,0,1-1-3.1c.5-4.7,12.8-114.7,58.6-131.4a4.4,4.4,0,0,1,3.7.5A4,4,0,0,1,208,32Zm-8,6.3c-14.5,8.8-27,30.9-36.6,64.7a392.4,392.4,0,0,0-10.9,53H200Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M76,72V32a8,8,0,0,1,16,0V72a8,8,0,0,1-16,0Zm43.9-41.3a8,8,0,1,0-15.8,2.6L112,80.6a28,28,0,0,1-56,0l7.9-47.3a8,8,0,1,0-15.8-2.6l-8,48A5.7,5.7,0,0,0,40,80a44.1,44.1,0,0,0,36,43.3V224a8,8,0,0,0,16,0V123.3A44.1,44.1,0,0,0,128,80a5.7,5.7,0,0,0-.1-1.3ZM212,32V224a8,8,0,0,1-16,0V168H148a8.2,8.2,0,0,1-6-2.7,8.1,8.1,0,0,1-2-6.2,412.8,412.8,0,0,1,11.8-59.3c12-42.4,28.7-67.8,49.5-75.3A7.9,7.9,0,0,1,212,32ZM196,46.4c-11.1,10.2-20.9,29.8-28.8,57.8A402.3,402.3,0,0,0,157.1,152H196Z" />
        </>
      )}
    </svg>
  );
}

export function PhFrameCorners(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M140,88a12,12,0,0,1,12-12h32a12,12,0,0,1,12,12v32a12,12,0,0,1-24,0V100H152A12,12,0,0,1,140,88ZM72,180h32a12,12,0,0,0,0-24H84V136a12,12,0,0,0-24,0v32A12,12,0,0,0,72,180ZM236,56V200a20.1,20.1,0,0,1-20,20H40a20.1,20.1,0,0,1-20-20V56A20.1,20.1,0,0,1,40,36H216A20.1,20.1,0,0,1,236,56Zm-24,4H44V196H212Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M224,56V200a8,8,0,0,1-8,8H40a8,8,0,0,1-8-8V56a8,8,0,0,1,8-8H216A8,8,0,0,1,224,56Z"
            opacity="0.2"
          />
          <path d="M200,80v32a8,8,0,0,1-16,0V88H160a8,8,0,0,1,0-16h32A8,8,0,0,1,200,80ZM96,168H72V144a8,8,0,0,0-16,0v32a8,8,0,0,0,8,8H96a8,8,0,0,0,0-16ZM232,56V200a16,16,0,0,1-16,16H40a16,16,0,0,1-16-16V56A16,16,0,0,1,40,40H216A16,16,0,0,1,232,56ZM216,200V56H40V200Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,40H40A16,16,0,0,0,24,56V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40ZM88,192H56a8,8,0,0,1-8-8V152a8,8,0,0,1,16,0v24H88a8,8,0,0,1,0,16Zm120-88a8,8,0,0,1-16,0V80H168a8,8,0,0,1,0-16h32a8,8,0,0,1,8,8Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M198,80v32a6,6,0,0,1-12,0V86H160a6,6,0,0,1,0-12h32A6,6,0,0,1,198,80ZM96,170H70V144a6,6,0,0,0-12,0v32a6,6,0,0,0,6,6H96a6,6,0,0,0,0-12ZM230,56V200a14,14,0,0,1-14,14H40a14,14,0,0,1-14-14V56A14,14,0,0,1,40,42H216A14,14,0,0,1,230,56Zm-12,0a2,2,0,0,0-2-2H40a2,2,0,0,0-2,2V200a2,2,0,0,0,2,2H216a2,2,0,0,0,2-2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M196,80v32a4,4,0,0,1-8,0V84H160a4,4,0,0,1,0-8h32A4,4,0,0,1,196,80ZM96,172H68V144a4,4,0,0,0-8,0v32a4,4,0,0,0,4,4H96a4,4,0,0,0,0-8ZM228,56V200a12,12,0,0,1-12,12H40a12,12,0,0,1-12-12V56A12,12,0,0,1,40,44H216A12,12,0,0,1,228,56Zm-8,0a4,4,0,0,0-4-4H40a4,4,0,0,0-4,4V200a4,4,0,0,0,4,4H216a4,4,0,0,0,4-4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M200,80v32a8,8,0,0,1-16,0V88H160a8,8,0,0,1,0-16h32A8,8,0,0,1,200,80ZM96,168H72V144a8,8,0,0,0-16,0v32a8,8,0,0,0,8,8H96a8,8,0,0,0,0-16ZM232,56V200a16,16,0,0,1-16,16H40a16,16,0,0,1-16-16V56A16,16,0,0,1,40,40H216A16,16,0,0,1,232,56ZM216,200V56H40V200Z" />
        </>
      )}
    </svg>
  );
}

export function PhFramerLogo(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,248a11.6,11.6,0,0,1-8.2-3.3l-72-68A11.9,11.9,0,0,1,44,168V100A12,12,0,0,1,56,88H97.8l-50-47.3a12,12,0,0,1-3-13.1A12.2,12.2,0,0,1,56,20H200a12,12,0,0,1,12,12v68a12,12,0,0,1-12,12H158.2l50,47.3a12,12,0,0,1,3,13.1A12.2,12.2,0,0,1,200,180H140v56a12.1,12.1,0,0,1-7.2,11A11.8,11.8,0,0,1,128,248ZM68,162.8l48,45.4V168a12,12,0,0,1,12-12h41.8l-46.6-44H68ZM132.8,88H188V44H86.2Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <polygon points="56 100 56 168 128 236 128 168 200 168 56 32 200 32 200 100 56 100" />
          </g>
          <path d="M128,244a8.2,8.2,0,0,1-5.5-2.2l-72-68A7.9,7.9,0,0,1,48,168V100a8,8,0,0,1,8-8h51.9L50.5,37.8A8,8,0,0,1,56,24H200a8,8,0,0,1,8,8v68a8,8,0,0,1-8,8H148.1l57.4,54.2A8,8,0,0,1,200,176H136v60a7.9,7.9,0,0,1-4.8,7.3A7.4,7.4,0,0,1,128,244ZM64,164.6l56,52.8V168a8,8,0,0,1,8-8h51.9l-55.1-52H64ZM131.2,92H192V40H76.1Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M200,108H148.1l57.4,54.2A8,8,0,0,1,200,176H136v60a7.9,7.9,0,0,1-4.8,7.3,7.4,7.4,0,0,1-3.2.7,8.2,8.2,0,0,1-5.5-2.2l-72-68A7.9,7.9,0,0,1,48,168V100a8,8,0,0,1,8-8h51.9L50.5,37.8A8,8,0,0,1,56,24H200a8,8,0,0,1,8,8v68A8,8,0,0,1,200,108Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,242a6.2,6.2,0,0,1-4.1-1.6l-72-68A6.1,6.1,0,0,1,50,168V100a6,6,0,0,1,6-6h56.9l-61-57.6a6,6,0,0,1-1.5-6.6A6,6,0,0,1,56,26H200a6,6,0,0,1,6,6v68a6,6,0,0,1-6,6H143.1l61,57.6a6,6,0,0,1,1.5,6.6A6,6,0,0,1,200,174H134v62a5.9,5.9,0,0,1-3.6,5.5A6.9,6.9,0,0,1,128,242ZM62,165.4l60,56.7V168a6,6,0,0,1,6-6h56.9l-59.3-56H62ZM130.4,94H194V38H71.1Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,240a3.9,3.9,0,0,1-2.7-1.1l-72-68A3.9,3.9,0,0,1,52,168V100a4,4,0,0,1,4-4h61.9L53.3,34.9A4,4,0,0,1,56,28H200a4,4,0,0,1,4,4v68a4,4,0,0,1-4,4H138.1l64.6,61.1A4,4,0,0,1,200,172H132v64a4,4,0,0,1-2.4,3.7A4.5,4.5,0,0,1,128,240ZM60,166.3l64,60.4V168a4,4,0,0,1,4-4h61.9l-63.5-60H60ZM129.6,96H196V36H66.1Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M208,100V32a8,8,0,0,0-8-8H56a8,8,0,0,0-5.5,13.8L107.9,92H56a8,8,0,0,0-8,8v68a7.9,7.9,0,0,0,2.5,5.8l72,68A8.2,8.2,0,0,0,128,244a7.4,7.4,0,0,0,3.2-.7A7.9,7.9,0,0,0,136,236V176h64a8,8,0,0,0,5.5-13.8L148.1,108H200A8,8,0,0,0,208,100Zm-28.1,60H128a8,8,0,0,0-8,8v49.4L64,164.6V108h60.8ZM192,92H131.2L76.1,40H192Z" />
        </>
      )}
    </svg>
  );
}

export function PhFunction(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M212,40a12,12,0,0,1-12,12H170.7A20,20,0,0,0,151,68.4L142.4,116H184a12,12,0,0,1,0,24H138l-9.4,51.9A44,44,0,0,1,85.3,228H56a12,12,0,0,1,0-24H85.3A20,20,0,0,0,105,187.6l8.6-47.6H72a12,12,0,0,1,0-24h46l9.4-51.9A44,44,0,0,1,170.7,28H200A12,12,0,0,1,212,40Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M208,40a8,8,0,0,1-8,8H170.7a24,24,0,0,0-23.6,19.7L137.6,120H184a8,8,0,0,1,0,16H134.7l-10.1,55.2A39.9,39.9,0,0,1,85.3,224H56a8,8,0,0,1,0-16H85.3a24,24,0,0,0,23.6-19.7l9.5-52.3H72a8,8,0,0,1,0-16h49.3l10.1-55.2A39.9,39.9,0,0,1,170.7,32H200A8,8,0,0,1,208,40Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M208,40a8,8,0,0,1-8,8H170.7a24,24,0,0,0-23.6,19.7L137.6,120H184a8,8,0,0,1,0,16H134.7l-10.1,55.2A39.9,39.9,0,0,1,85.3,224H56a8,8,0,0,1,0-16H85.3a24,24,0,0,0,23.6-19.7l9.5-52.3H72a8,8,0,0,1,0-16h49.3l10.1-55.2A39.9,39.9,0,0,1,170.7,32H200A8,8,0,0,1,208,40Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M206,40a6,6,0,0,1-6,6H170.7a26.1,26.1,0,0,0-25.6,21.3L135.2,122H184a6,6,0,0,1,0,12H133l-10.3,56.8A38,38,0,0,1,85.3,222H56a6,6,0,0,1,0-12H85.3a26.1,26.1,0,0,0,25.6-21.3l9.9-54.7H72a6,6,0,0,1,0-12h51l10.3-56.8A38,38,0,0,1,170.7,34H200A6,6,0,0,1,206,40Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M204,40a4,4,0,0,1-4,4H170.7a28,28,0,0,0-27.5,23l-10.4,57H184a4,4,0,0,1,0,8H131.3l-10.6,58.4A35.9,35.9,0,0,1,85.3,220H56a4,4,0,0,1,0-8H85.3a28,28,0,0,0,27.5-23l10.4-57H72a4,4,0,0,1,0-8h52.7l10.6-58.4A35.9,35.9,0,0,1,170.7,36H200A4,4,0,0,1,204,40Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M208,40a8,8,0,0,1-8,8H170.7a24,24,0,0,0-23.6,19.7L137.6,120H184a8,8,0,0,1,0,16H134.7l-10.1,55.2A39.9,39.9,0,0,1,85.3,224H56a8,8,0,0,1,0-16H85.3a24,24,0,0,0,23.6-19.7l9.5-52.3H72a8,8,0,0,1,0-16h49.3l10.1-55.2A39.9,39.9,0,0,1,170.7,32H200A8,8,0,0,1,208,40Z" />
        </>
      )}
    </svg>
  );
}

export function PhFunnelSimple(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M204,128a12,12,0,0,1-12,12H64a12,12,0,0,1,0-24H192A12,12,0,0,1,204,128Zm28-60H24a12,12,0,0,0,0,24H232a12,12,0,0,0,0-24Zm-80,96H104a12,12,0,0,0,0,24h48a12,12,0,0,0,0-24Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M200,128a8,8,0,0,1-8,8H64a8,8,0,0,1,0-16H192A8,8,0,0,1,200,128Zm32-56H24a8,8,0,0,0,0,16H232a8,8,0,0,0,0-16Zm-80,96H104a8,8,0,0,0,0,16h48a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M200,128a8,8,0,0,1-8,8H64a8,8,0,0,1,0-16H192A8,8,0,0,1,200,128Zm32-56H24a8,8,0,0,0,0,16H232a8,8,0,0,0,0-16Zm-80,96H104a8,8,0,0,0,0,16h48a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M198,128a6,6,0,0,1-6,6H64a6,6,0,0,1,0-12H192A6,6,0,0,1,198,128Zm34-54H24a6,6,0,0,0,0,12H232a6,6,0,0,0,0-12Zm-80,96H104a6,6,0,0,0,0,12h48a6,6,0,0,0,0-12Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M196,128a4,4,0,0,1-4,4H64a4,4,0,0,1,0-8H192A4,4,0,0,1,196,128Zm36-52H24a4,4,0,0,0,0,8H232a4,4,0,0,0,0-8Zm-80,96H104a4,4,0,0,0,0,8h48a4,4,0,0,0,0-8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M200,128a8,8,0,0,1-8,8H64a8,8,0,0,1,0-16H192A8,8,0,0,1,200,128Zm32-56H24a8,8,0,0,0,0,16H232a8,8,0,0,0,0-16Zm-80,96H104a8,8,0,0,0,0,16h48a8,8,0,0,0,0-16Z" />
        </>
      )}
    </svg>
  );
}

export function PhFunnel(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M112,237.1a19.6,19.6,0,0,1-9.4-2.4A19.9,19.9,0,0,1,92,217.1V140.6L27.3,69.5A20,20,0,0,1,42.1,36H213.9a20,20,0,0,1,14.8,33.5L164,140.6v55.1a20.1,20.1,0,0,1-8.9,16.7l-32,21.3A20.4,20.4,0,0,1,112,237.1ZM51.1,60l59.7,65.6a19.8,19.8,0,0,1,5.2,13.5v70.5l24-16V139.1a19.8,19.8,0,0,1,5.2-13.5L204.9,60ZM93,141.8h0ZM213.9,60Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M42.1,48H213.9a8,8,0,0,1,5.9,13.4l-65.7,72.3a7.8,7.8,0,0,0-2.1,5.4v56.6a7.9,7.9,0,0,1-3.6,6.7l-32,21.3a8,8,0,0,1-12.4-6.6v-78a7.8,7.8,0,0,0-2.1-5.4L36.2,61.4A8,8,0,0,1,42.1,48Z" />
          </g>
          <path d="M112,233.1a16.4,16.4,0,0,1-7.5-1.9A15.9,15.9,0,0,1,96,217.1v-78L30.2,66.8A16.1,16.1,0,0,1,42.1,40H213.9a16.1,16.1,0,0,1,11.9,26.8L160,139.1v56.6a15.9,15.9,0,0,1-7.1,13.3l-32,21.4A16,16,0,0,1,112,233.1ZM42.1,56l65.7,72.3a16.1,16.1,0,0,1,4.2,10.8v78l32-21.4V139.1a16.1,16.1,0,0,1,4.2-10.8L213.9,56ZM214,56Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M225.8,66.8,160,139.1v56.6a15.9,15.9,0,0,1-7.1,13.3l-32,21.4a16,16,0,0,1-8.9,2.7,16.4,16.4,0,0,1-7.5-1.9A15.9,15.9,0,0,1,96,217.1v-78L30.2,66.8A16.1,16.1,0,0,1,42.1,40H213.9a16.1,16.1,0,0,1,11.9,26.8Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M112,231.1a14.1,14.1,0,0,1-6.6-1.7A13.9,13.9,0,0,1,98,217.1v-78a1.9,1.9,0,0,0-.5-1.3L31.7,65.4a13.7,13.7,0,0,1-2.4-15.1A13.9,13.9,0,0,1,42.1,42H213.9a13.9,13.9,0,0,1,12.8,8.3,13.7,13.7,0,0,1-2.4,15.1l-65.8,72.3a2.4,2.4,0,0,0-.5,1.4v56.6a14,14,0,0,1-6.2,11.7l-32,21.3A14,14,0,0,1,112,231.1ZM42.1,54a1.8,1.8,0,0,0-1.8,1.2,1.7,1.7,0,0,0,.3,2.1l65.8,72.4a14.1,14.1,0,0,1,3.6,9.4v78a1.9,1.9,0,0,0,1.1,1.7,1.8,1.8,0,0,0,2-.1l32-21.3a2,2,0,0,0,.9-1.7V139.1a14.1,14.1,0,0,1,3.6-9.4l65.8-72.4a1.7,1.7,0,0,0,.3-2.1,1.8,1.8,0,0,0-1.8-1.2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M112,229.1a12.2,12.2,0,0,1-12-12v-78a4.3,4.3,0,0,0-1-2.7L33.2,64.1a12.1,12.1,0,0,1-2.1-13,11.9,11.9,0,0,1,11-7.1H213.9a11.9,11.9,0,0,1,11,7.1,12.1,12.1,0,0,1-2.1,13L157,136.4a4.3,4.3,0,0,0-1,2.7v56.6a12.1,12.1,0,0,1-5.3,10l-32,21.3A12,12,0,0,1,112,229.1ZM42.1,52a4,4,0,0,0-3,6.7L104.9,131a12,12,0,0,1,3.1,8.1v78a4.1,4.1,0,0,0,2.1,3.5,4,4,0,0,0,4.1-.2l32-21.4a3.9,3.9,0,0,0,1.8-3.3V139.1a12,12,0,0,1,3.1-8.1l65.8-72.3a4,4,0,0,0-3-6.7Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M112,233.1a16.4,16.4,0,0,1-7.5-1.9A15.9,15.9,0,0,1,96,217.1v-78L30.2,66.8A16.1,16.1,0,0,1,42.1,40H213.9a16.1,16.1,0,0,1,11.9,26.8L160,139.1v56.6a15.9,15.9,0,0,1-7.1,13.3l-32,21.4A16,16,0,0,1,112,233.1ZM42.1,56l65.7,72.3a16.1,16.1,0,0,1,4.2,10.8v78l32-21.4V139.1a16.1,16.1,0,0,1,4.2-10.8L213.9,56ZM214,56Z" />
        </>
      )}
    </svg>
  );
}

export function PhGameController(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M184,120H152a12,12,0,0,1,0-24h32a12,12,0,0,1,0,24ZM104,96h-4V92a12,12,0,0,0-24,0v4H72a12,12,0,0,0,0,24h4v4a12,12,0,0,0,24,0v-4h4a12,12,0,0,0,0-24ZM219,227.4a42.3,42.3,0,0,1-7,.6,40.4,40.4,0,0,1-28.3-11.7l-.6-.7-39.4-43.7H112.4L72.9,215.7l-.6.6A40,40,0,0,1,4.6,181v-.2L20.9,97.1v-.2A63.7,63.7,0,0,1,84,44l88-.3h0a64.2,64.2,0,0,1,63,52.5v.2l16.4,84.5h0A40.1,40.1,0,0,1,219,227.4Zm-47-79.7a40,40,0,1,0,0-80L84,68a39.9,39.9,0,0,0-39.3,32.6,1.7,1.7,0,0,0-.1.7l-16.4,84a16,16,0,0,0,26.9,14.2l43-47.5a11.8,11.8,0,0,1,8.9-4Zm55.8,37.6-7-36.2a63.5,63.5,0,0,1-45,22.5l25.1,27.9a16,16,0,0,0,26.9-14.2Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M149,159.8h23a52,52,0,0,0,52-52,45,45,0,0,0-.8-9h0l16.4,84.4a28,28,0,0,1-47.4,24.7h0l-43.2-48Z" />
          </g>
          <path d="M192,108a8,8,0,0,0-8-8H152a8,8,0,0,0,0,16h32A8,8,0,0,0,192,108Z" />
          <path d="M104,100H96V92a8,8,0,0,0-16,0v8H72a8,8,0,0,0,0,16h8v8a8,8,0,0,0,16,0v-8h8a8,8,0,0,0,0-16Z" />
          <path d="M247.5,181.7h-.1L231.1,97.2c0-.1,0-.1-.1-.2a60,60,0,0,0-59-49.3h0L84,48A59.9,59.9,0,0,0,24.9,97.6v.2L8.6,181.6h0a35.9,35.9,0,0,0,29.2,41.7,31.8,31.8,0,0,0,6.2.6,36.4,36.4,0,0,0,25.5-10.5l.4-.5,40.7-45,34.9-.2L186.1,213l.5.5A36,36,0,0,0,212,224a33.6,33.6,0,0,0,6.3-.6A36.1,36.1,0,0,0,247.5,181.7ZM107,152a8.3,8.3,0,0,0-5.9,2.6L58,202.3a20,20,0,0,1-33.7-17.7l16.3-84.1a.9.9,0,0,0,.1-.5A43.9,43.9,0,0,1,84,64l88-.3a44,44,0,1,1,0,88Zm108.5,55.7a20.1,20.1,0,0,1-17.5-5.4l-31.1-34.5H172a60,60,0,0,0,51-28.3l8.7,45.2A20,20,0,0,1,215.5,207.7Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M247.5,181.7h-.1L231.1,97.2c0-.1,0-.1-.1-.2a60,60,0,0,0-59-49.3h0L84,48A59.9,59.9,0,0,0,24.9,97.6v.2L8.6,181.6h0a35.9,35.9,0,0,0,29.2,41.7,31.8,31.8,0,0,0,6.2.6,36.4,36.4,0,0,0,25.5-10.5l.4-.5,40.7-45,34.9-.2L186.1,213l.5.5A36,36,0,0,0,212,224a33.6,33.6,0,0,0,6.3-.6A36.1,36.1,0,0,0,247.5,181.7ZM104,116H96v8a8,8,0,0,1-16,0v-8H72a8,8,0,0,1,0-16h8V92a8,8,0,0,1,16,0v8h8a8,8,0,0,1,0,16Zm40-8a8,8,0,0,1,8-8h32a8,8,0,0,1,0,16H152A8,8,0,0,1,144,108Zm71.5,99.7a20.1,20.1,0,0,1-17.5-5.4l-31.1-34.5H172a60,60,0,0,0,51-28.3l8.7,45.2A20,20,0,0,1,215.5,207.7Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M184,114H152a6,6,0,0,1,0-12h32a6,6,0,0,1,0,12Zm-80-12H94V92a6,6,0,0,0-12,0v10H72a6,6,0,0,0,0,12H82v10a6,6,0,0,0,12,0V114h10a6,6,0,0,0,0-12ZM217.9,221.5a39.2,39.2,0,0,1-5.9.5,33.7,33.7,0,0,1-24-10l-.4-.3-41.3-45.9-36.6.2L68.4,211.7l-.4.3a33.7,33.7,0,0,1-24,10,39.2,39.2,0,0,1-5.9-.5,34.1,34.1,0,0,1-27.6-39.4h0L26.8,98.1c.1,0,.1-.1.1-.2A57.9,57.9,0,0,1,84,50l88-.3h0a58.1,58.1,0,0,1,57.1,47.7v.2L245.5,182h0A34.1,34.1,0,0,1,217.9,221.5ZM172,153.7a46,46,0,1,0,0-92L84,62A45.9,45.9,0,0,0,38.7,99.7v.4L22.3,184.2a22.2,22.2,0,0,0,17.9,25.5,21.9,21.9,0,0,0,19.2-6L102.6,156a5.7,5.7,0,0,1,4.4-2Zm61.7,30.5-9.8-50.5a58,58,0,0,1-51.9,32h-9.6l34.2,37.9a22,22,0,0,0,37.1-19.5Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M184,112H152a4,4,0,0,1,0-8h32a4,4,0,0,1,0,8Zm-80-8H92V92a4,4,0,0,0-8,0v12H72a4,4,0,0,0,0,8H84v12a4,4,0,0,0,8,0V112h12a4,4,0,0,0,0-8ZM217.6,219.5a35.3,35.3,0,0,1-5.6.5,31.7,31.7,0,0,1-22.6-9.4l-.3-.2-41.9-46.6-38.4.2L66.9,210.4l-.3.2A31.7,31.7,0,0,1,44,220a35.3,35.3,0,0,1-5.6-.5,32.1,32.1,0,0,1-25.9-37.1L28.8,98.5v-.2A55.9,55.9,0,0,1,84,52l88-.3h0a56.1,56.1,0,0,1,55.1,46.1c0,.1,0,.1.1.1l16.3,84.5A32.1,32.1,0,0,1,217.6,219.5ZM172,155.7a48,48,0,1,0,0-96L84,60A47.9,47.9,0,0,0,36.8,99.4c0,.1-.1.2-.1.3L20.4,183.9a23.9,23.9,0,0,0,19.4,27.7,24.2,24.2,0,0,0,21.1-6.5l43.2-47.8A3.9,3.9,0,0,1,107,156Zm63.6,28.1-11-56.9A55.9,55.9,0,0,1,172,163.7H158l37.1,41.3a24,24,0,0,0,40.5-21.3Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M184,116H152a8,8,0,0,1,0-16h32a8,8,0,0,1,0,16Zm-80-16H96V92a8,8,0,0,0-16,0v8H72a8,8,0,0,0,0,16h8v8a8,8,0,0,0,16,0v-8h8a8,8,0,0,0,0-16ZM218.3,223.4a33.6,33.6,0,0,1-6.3.6,36,36,0,0,1-25.4-10.5l-.5-.5-40.6-45.2-34.9.2L69.9,213l-.4.5A36.4,36.4,0,0,1,44,224a31.8,31.8,0,0,1-6.2-.6A35.9,35.9,0,0,1,8.6,181.7h0L24.9,97.8v-.2A59.9,59.9,0,0,1,84,48l88-.3h0A60,60,0,0,1,231,97c.1.1.1.1.1.2l16.3,84.4h.1A36.1,36.1,0,0,1,218.3,223.4ZM172,151.7a44,44,0,1,0,0-88L84,64a43.9,43.9,0,0,0-43.3,36,.9.9,0,0,1-.1.5L24.3,184.6A20,20,0,0,0,58,202.3l43.1-47.7A8.3,8.3,0,0,1,107,152Zm59.7,32.9L223,139.4a60,60,0,0,1-51,28.3h-5.1L198,202.3a20,20,0,0,0,33.7-17.7Z" />
        </>
      )}
    </svg>
  );
}

export function PhGasPump(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M247.8,66.8,228.5,47.5a12,12,0,0,0-17,17l19.3,19.3a3.9,3.9,0,0,1,1.2,2.8V168a4,4,0,0,1-4,4h-4a4,4,0,0,1-4-4V128a28.1,28.1,0,0,0-28-28H180V56a28.1,28.1,0,0,0-28-28H72A28.1,28.1,0,0,0,44,56V204H32a12,12,0,0,0,0,24H192a12,12,0,0,0,0-24H180V124h12a4,4,0,0,1,4,4v40a28.1,28.1,0,0,0,28,28h4a28.1,28.1,0,0,0,28-28V86.6A27.8,27.8,0,0,0,247.8,66.8ZM68,204V56a4,4,0,0,1,4-4h80a4,4,0,0,1,4,4V204Zm72-92a12,12,0,0,1-12,12H96a12,12,0,0,1,0-24h32A12,12,0,0,1,140,112Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M168,56V216H56V56A16,16,0,0,1,72,40h80A16,16,0,0,1,168,56Z"
            opacity="0.2"
          />
          <path d="M241,69.7,221.7,50.3a8.1,8.1,0,0,0-11.4,11.4L229.7,81a7.9,7.9,0,0,1,2.3,5.6V168a8,8,0,0,1-16,0V128a24.1,24.1,0,0,0-24-24H176V56a24.1,24.1,0,0,0-24-24H72A24.1,24.1,0,0,0,48,56V208H32a8,8,0,0,0,0,16H192a8,8,0,0,0,0-16H176V120h16a8,8,0,0,1,8,8v40a24,24,0,0,0,48,0V86.6A23.8,23.8,0,0,0,241,69.7ZM64,208V56a8,8,0,0,1,8-8h80a8,8,0,0,1,8,8V208Zm80-96a8,8,0,0,1-8,8H88a8,8,0,0,1,0-16h48A8,8,0,0,1,144,112Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M241,69.7,221.7,50.3a8.1,8.1,0,0,0-11.4,11.4L229.7,81a7.9,7.9,0,0,1,2.3,5.6V168a8,8,0,0,1-16,0V128a24.1,24.1,0,0,0-24-24H176V56a24.1,24.1,0,0,0-24-24H72A24.1,24.1,0,0,0,48,56V208H32a8,8,0,0,0,0,16H192a8,8,0,0,0,0-16H176V120h16a8,8,0,0,1,8,8v40a24,24,0,0,0,48,0V86.6A23.8,23.8,0,0,0,241,69.7ZM144,120H80a8,8,0,0,1,0-16h64a8,8,0,0,1,0,16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M239.6,71.1,220.2,51.8a5.9,5.9,0,0,0-8.4,8.4l19.3,19.4a9.7,9.7,0,0,1,2.9,7V168a10,10,0,0,1-20,0V128a22.1,22.1,0,0,0-22-22H174V56a22.1,22.1,0,0,0-22-22H72A22.1,22.1,0,0,0,50,56V210H32a6,6,0,0,0,0,12H192a6,6,0,0,0,0-12H174V118h18a10,10,0,0,1,10,10v40a22,22,0,0,0,44,0V86.6A22,22,0,0,0,239.6,71.1ZM62,210V56A10,10,0,0,1,72,46h80a10,10,0,0,1,10,10V210Zm80-98a6,6,0,0,1-6,6H88a6,6,0,0,1,0-12h48A6,6,0,0,1,142,112Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M238.1,72.5,218.8,53.2a4,4,0,0,0-5.6,5.6l19.3,19.3a11.9,11.9,0,0,1,3.5,8.5V168a12,12,0,0,1-24,0V128a20.1,20.1,0,0,0-20-20H172V56a20.1,20.1,0,0,0-20-20H72A20.1,20.1,0,0,0,52,56V212H32a4,4,0,0,0,0,8H192a4,4,0,0,0,0-8H172V116h20a12,12,0,0,1,12,12v40a20,20,0,0,0,40,0V86.6A19.8,19.8,0,0,0,238.1,72.5ZM60,212V56A12,12,0,0,1,72,44h80a12,12,0,0,1,12,12V212Zm80-100a4,4,0,0,1-4,4H88a4,4,0,0,1,0-8h48A4,4,0,0,1,140,112Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M241,69.7,221.7,50.3a8.1,8.1,0,0,0-11.4,11.4L229.7,81a7.9,7.9,0,0,1,2.3,5.6V168a8,8,0,0,1-16,0V128a24.1,24.1,0,0,0-24-24H176V56a24.1,24.1,0,0,0-24-24H72A24.1,24.1,0,0,0,48,56V208H32a8,8,0,0,0,0,16H192a8,8,0,0,0,0-16H176V120h16a8,8,0,0,1,8,8v40a24,24,0,0,0,48,0V86.6A23.8,23.8,0,0,0,241,69.7ZM64,208V56a8,8,0,0,1,8-8h80a8,8,0,0,1,8,8V208Zm80-96a8,8,0,0,1-8,8H88a8,8,0,0,1,0-16h48A8,8,0,0,1,144,112Z" />
        </>
      )}
    </svg>
  );
}

export function PhGauge(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M209.9,77.8A115.4,115.4,0,0,0,128,44h-.4c-52.2.2-96.5,35.5-110.7,83.6A11.7,11.7,0,0,0,16,130a5.9,5.9,0,0,0-.4,2.3A114.2,114.2,0,0,0,12,161.1V184a20.1,20.1,0,0,0,20,20H224a20.1,20.1,0,0,0,20-20V160A115.3,115.3,0,0,0,209.9,77.8ZM220,180H127.8l53.6-69.8a12,12,0,0,0-19.1-14.6L97.5,180H36V161.1a95,95,0,0,1,.9-13.1l18.4,5a17.5,17.5,0,0,0,3.2.4,12,12,0,0,0,3.1-23.6l-18.3-4.9C55.8,95.1,83.2,73.1,116,68.8V88a12,12,0,0,0,24,0V68.8a91.4,91.4,0,0,1,52.9,26,90.5,90.5,0,0,1,20.1,30l-18.6,5a12,12,0,0,0,3.1,23.6,17.5,17.5,0,0,0,3.2-.4l18.5-5a94.2,94.2,0,0,1,.8,12Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M232,160v24a8,8,0,0,1-8,8H32a8,8,0,0,1-8-8V161.1C24,103.6,70.2,56.2,127.6,56A104,104,0,0,1,232,160Z"
            opacity="0.2"
          />
          <path d="M207.1,80.7A111.3,111.3,0,0,0,128,48h-.4c-50.6.2-93.4,34.5-107,81.2a7.1,7.1,0,0,0-.8,1.8,11,11,0,0,0-.2,1.8A110.9,110.9,0,0,0,16,161.1V184a16,16,0,0,0,16,16H224a16,16,0,0,0,16-16V160A111.2,111.2,0,0,0,207.1,80.7ZM224,184H119.7l58.5-76.3A8,8,0,0,0,165.5,98l-66,86H32V161.1A98.3,98.3,0,0,1,33.7,143l22.7,6.1a7.6,7.6,0,0,0,2.1.3,8,8,0,0,0,2-15.8l-22.6-6C50.6,93,82.2,67.5,120,64.3V88a8,8,0,0,0,16,0V64.3A95.6,95.6,0,0,1,195.8,92a96.9,96.9,0,0,1,22.6,35.5l-22.9,6.1a8,8,0,0,0,2,15.8,7.6,7.6,0,0,0,2.1-.3l22.9-6.1a95.1,95.1,0,0,1,1.5,17Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M240,160v24a16,16,0,0,1-16,16H115.5a4,4,0,0,1-3.2-6.4L178,108a8.2,8.2,0,0,0-1.1-11.3A7.9,7.9,0,0,0,165.5,98L88.4,198.4a3.8,3.8,0,0,1-3.1,1.6H32a16,16,0,0,1-16-16V161.1a116.1,116.1,0,0,1,2.2-22.2L40.9,145l2.1.2a8,8,0,0,0,7.8-6.2,8.1,8.1,0,0,0-6-9.6l-22.4-6C37,82,74.9,51.5,120,48.3V71.7a8.2,8.2,0,0,0,7.5,8.3,8,8,0,0,0,8.5-8V48.3a111.5,111.5,0,0,1,71.1,32.4,112.7,112.7,0,0,1,26.8,42.6l-22.7,6.1a8.1,8.1,0,0,0-6,9.6,8,8,0,0,0,7.8,6.2l2.1-.2,22.9-6.2A114.5,114.5,0,0,1,240,160Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M205.6,82.1A109.1,109.1,0,0,0,128,50h-.4c-49.7.2-91.8,34-105.2,80a6.9,6.9,0,0,0-.7,1.5,10,10,0,0,0-.2,1.6,113.1,113.1,0,0,0-3.5,28V184a14,14,0,0,0,14,14H224a14,14,0,0,0,14-14V160A109.1,109.1,0,0,0,205.6,82.1ZM226,184a2,2,0,0,1-2,2H115.6l61-79.5a6,6,0,1,0-9.5-7.3L100.5,186H32a2,2,0,0,1-2-2V161.1a100.7,100.7,0,0,1,2.1-20.6l24.8,6.7,1.6.2a5.9,5.9,0,0,0,5.7-4.5,5.9,5.9,0,0,0-4.2-7.3L35.3,129C47.9,91.9,81.7,64.7,122,62.2V88a6,6,0,0,0,12,0V62.2a98.2,98.2,0,0,1,87,66.7l-25,6.7a5.9,5.9,0,0,0-4.2,7.3,5.9,5.9,0,0,0,5.7,4.5l1.6-.2,25-6.7A104.8,104.8,0,0,1,226,160Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M204.2,83.5A107,107,0,0,0,128.1,52h-.5c-48.9.2-90.4,33.5-103.4,78.9a3.7,3.7,0,0,0-.5,1.1,3,3,0,0,0-.1,1.3A106.5,106.5,0,0,0,20,161.1V184a12,12,0,0,0,12,12H224a12,12,0,0,0,12-12V160A107.3,107.3,0,0,0,204.2,83.5ZM228,184a4,4,0,0,1-4,4H111.6L175,105.3a4,4,0,1,0-6.3-4.9L101.5,188H32a4,4,0,0,1-4-4V161.1a102.6,102.6,0,0,1,2.6-23l26.8,7.1,1.1.2a4,4,0,0,0,1-7.9l-26.7-7.2C45.3,90.8,81.3,61.8,124,60.1V88a4,4,0,0,0,8,0V60.1a99.3,99.3,0,0,1,66.6,29.1,98.2,98.2,0,0,1,24.9,41.1l-27,7.2a4,4,0,0,0,1,7.9l1.1-.2,27-7.2a100.2,100.2,0,0,1,2.4,22Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M207.1,80.7A111.3,111.3,0,0,0,128,48h-.4c-50.6.2-93.4,34.5-107,81.2a7.1,7.1,0,0,0-.8,1.8,11,11,0,0,0-.2,1.8A110.9,110.9,0,0,0,16,161.1V184a16,16,0,0,0,16,16H224a16,16,0,0,0,16-16V160A111.2,111.2,0,0,0,207.1,80.7ZM224,184H119.7l58.5-76.3A8,8,0,0,0,165.5,98l-66,86H32V161.1A98.3,98.3,0,0,1,33.7,143l22.7,6.1a7.6,7.6,0,0,0,2.1.3,8,8,0,0,0,2-15.8l-22.6-6C50.6,93,82.2,67.5,120,64.3V88a8,8,0,0,0,16,0V64.3A95.6,95.6,0,0,1,195.8,92a96.9,96.9,0,0,1,22.6,35.5l-22.9,6.1a8,8,0,0,0,2,15.8,7.6,7.6,0,0,0,2.1-.3l22.9-6.1a95.1,95.1,0,0,1,1.5,17Z" />
        </>
      )}
    </svg>
  );
}

export function PhGearSix(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,72a56,56,0,1,0,56,56A56,56,0,0,0,128,72Zm0,88a32,32,0,1,1,32-32A32.1,32.1,0,0,1,128,160Zm113.4-56.6a11.9,11.9,0,0,0-5.9-7.9L212.7,82.9c-1-2-2.1-3.9-3.2-5.7l.4-26a12,12,0,0,0-3.9-9.1,116,116,0,0,0-42.6-24.6,12,12,0,0,0-9.8,1.2L131.3,32h-6.6L102.4,18.7a12.1,12.1,0,0,0-9.8-1.2A116,116,0,0,0,50,42.1a12,12,0,0,0-3.9,9.1l.4,26c-1.1,1.8-2.2,3.7-3.2,5.7L20.5,95.5a12.1,12.1,0,0,0-5.9,7.9,116.6,116.6,0,0,0,0,49.2,11.9,11.9,0,0,0,5.9,7.9l22.8,12.6c1,2,2.1,3.9,3.2,5.7l-.4,26a12,12,0,0,0,3.9,9.1,116,116,0,0,0,42.6,24.6,12,12,0,0,0,9.8-1.2L124.7,224h6.6l22.3,13.3a11.5,11.5,0,0,0,6.2,1.8,11.3,11.3,0,0,0,3.6-.6A116,116,0,0,0,206,213.9a12,12,0,0,0,3.9-9.1l-.4-26c1.1-1.8,2.2-3.7,3.2-5.7l22.8-12.6a12.1,12.1,0,0,0,5.9-7.9A116.6,116.6,0,0,0,241.4,103.4Zm-22.5,38.9-21,11.6a12.2,12.2,0,0,0-5,5.3,79,79,0,0,1-5.4,9.4,12,12,0,0,0-2.1,6.9l.4,24.1a93.7,93.7,0,0,1-24.7,14.3l-20.6-12.4a12.3,12.3,0,0,0-6.2-1.7h-.9a64.9,64.9,0,0,1-10.8,0,11.9,11.9,0,0,0-7.1,1.7L94.9,213.9a92.1,92.1,0,0,1-24.7-14.3l.4-24a12.3,12.3,0,0,0-2.1-7,79,79,0,0,1-5.4-9.4,12.7,12.7,0,0,0-5-5.3l-21-11.6a91.4,91.4,0,0,1,0-28.6l21-11.6a12.2,12.2,0,0,0,5-5.3,79,79,0,0,1,5.4-9.4,12,12,0,0,0,2.1-6.9l-.4-24.1A93.7,93.7,0,0,1,94.9,42.1l20.6,12.4a11.7,11.7,0,0,0,7.1,1.7,64.9,64.9,0,0,1,10.8,0,11.9,11.9,0,0,0,7.1-1.7l20.6-12.4a92.1,92.1,0,0,1,24.7,14.3l-.4,24a12.3,12.3,0,0,0,2.1,7,79,79,0,0,1,5.4,9.4,12.7,12.7,0,0,0,5,5.3l21,11.6A91.4,91.4,0,0,1,218.9,142.3Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M229.6,106,203.7,91.6a80.1,80.1,0,0,0-6.3-11l.5-29.6a102.6,102.6,0,0,0-38.2-22L134.3,44.2a88.3,88.3,0,0,0-12.6,0L96.2,28.9A104,104,0,0,0,58.1,51l.5,29.7a73.6,73.6,0,0,0-6.3,10.9L26.3,106a102,102,0,0,0,.1,44l25.9,14.4a80.1,80.1,0,0,0,6.3,11L58.1,205a102.6,102.6,0,0,0,38.2,22l25.4-15.2a88.3,88.3,0,0,0,12.6,0l25.5,15.3A104,104,0,0,0,197.9,205l-.5-29.7a73.6,73.6,0,0,0,6.3-10.9l26-14.4A102,102,0,0,0,229.6,106ZM128,176a48,48,0,1,1,48-48A48,48,0,0,1,128,176Z"
            opacity="0.2"
          />
          <path d="M128,72a56,56,0,1,0,56,56A56,56,0,0,0,128,72Zm0,96a40,40,0,1,1,40-40A40,40,0,0,1,128,168Zm109.5-63.7a8,8,0,0,0-4-5.3L209.7,85.8a69.3,69.3,0,0,0-4.3-7.5l.5-27.2a8.3,8.3,0,0,0-2.6-6.1,112,112,0,0,0-41.1-23.7,8.1,8.1,0,0,0-6.6.8l-23.3,14c-2.9-.1-5.7-.1-8.6,0l-23.3-14a8.1,8.1,0,0,0-6.6-.8A111.1,111.1,0,0,0,52.7,45.1a7.9,7.9,0,0,0-2.6,6l.5,27.2c-1.6,2.4-3,4.9-4.4,7.5L22.4,99a7.9,7.9,0,0,0-3.9,5.3,111.4,111.4,0,0,0,0,47.4,8,8,0,0,0,4,5.3l23.8,13.2a69.3,69.3,0,0,0,4.3,7.5l-.5,27.2a8.3,8.3,0,0,0,2.6,6.1,112,112,0,0,0,41.1,23.7,8.1,8.1,0,0,0,6.6-.8l23.3-14h8.6l23.4,14a7.3,7.3,0,0,0,4.1,1.2,10,10,0,0,0,2.4-.4,111.1,111.1,0,0,0,41.1-23.8,7.9,7.9,0,0,0,2.6-6l-.5-27.2c1.6-2.4,3-4.9,4.4-7.5L233.6,157a7.9,7.9,0,0,0,3.9-5.3A111.4,111.4,0,0,0,237.5,104.3Zm-15,40.5-22.7,12.6a8.2,8.2,0,0,0-3.3,3.6,73.6,73.6,0,0,1-5.7,9.8,8.6,8.6,0,0,0-1.4,4.7l.4,25.9a94.4,94.4,0,0,1-29.1,16.9l-22.3-13.4a8.1,8.1,0,0,0-4.1-1.1h-.6a72.3,72.3,0,0,1-11.4,0,8.6,8.6,0,0,0-4.7,1.1L95.3,218.3a95,95,0,0,1-29.1-16.8l.4-26a8.6,8.6,0,0,0-1.4-4.7,86.4,86.4,0,0,1-5.7-9.8,8.8,8.8,0,0,0-3.3-3.6L33.5,144.8a94.8,94.8,0,0,1,0-33.6L56.2,98.6A8.2,8.2,0,0,0,59.5,95a73.6,73.6,0,0,1,5.7-9.8,8.6,8.6,0,0,0,1.4-4.7l-.4-25.9A94.4,94.4,0,0,1,95.3,37.7l22.3,13.4a8.4,8.4,0,0,0,4.7,1.1,72.3,72.3,0,0,1,11.4,0,8.6,8.6,0,0,0,4.7-1.1l22.3-13.4a95,95,0,0,1,29.1,16.8l-.4,26a8.6,8.6,0,0,0,1.4,4.7,86.4,86.4,0,0,1,5.7,9.8,8.8,8.8,0,0,0,3.3,3.6l22.7,12.6A94.8,94.8,0,0,1,222.5,144.8Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M237.5,104.3a8,8,0,0,0-4-5.3L209.7,85.8a69.3,69.3,0,0,0-4.3-7.5l.5-27.2a8.3,8.3,0,0,0-2.6-6.1,112,112,0,0,0-41.1-23.7,8.1,8.1,0,0,0-6.6.8l-23.3,14c-2.9-.1-5.7-.1-8.6,0l-23.3-14a8.1,8.1,0,0,0-6.6-.8A111.1,111.1,0,0,0,52.7,45.1a7.9,7.9,0,0,0-2.6,6l.5,27.2c-1.6,2.4-3,4.9-4.4,7.5L22.4,99a7.9,7.9,0,0,0-3.9,5.3,111.4,111.4,0,0,0,0,47.4,8,8,0,0,0,4,5.3l23.8,13.2a69.3,69.3,0,0,0,4.3,7.5l-.5,27.2a8.3,8.3,0,0,0,2.6,6.1,112,112,0,0,0,41.1,23.7,8.1,8.1,0,0,0,6.6-.8l23.3-14h8.6l23.4,14a7.3,7.3,0,0,0,4.1,1.2,10,10,0,0,0,2.4-.4,111.1,111.1,0,0,0,41.1-23.8,7.9,7.9,0,0,0,2.6-6l-.5-27.2c1.6-2.4,3-4.9,4.4-7.5L233.6,157a7.9,7.9,0,0,0,3.9-5.3A111.4,111.4,0,0,0,237.5,104.3ZM128,172a44,44,0,1,1,44-44A44,44,0,0,1,128,172Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,74a54,54,0,1,0,54,54A54,54,0,0,0,128,74Zm0,96a42,42,0,1,1,42-42A42,42,0,0,1,128,170Zm107.5-65.3a6.2,6.2,0,0,0-2.9-4L208.2,87.2a77.4,77.4,0,0,0-4.8-8.3l.5-27.8a6.1,6.1,0,0,0-2-4.6,109.3,109.3,0,0,0-40.3-23.3,6.2,6.2,0,0,0-5,.6L132.8,38.1a76.9,76.9,0,0,0-9.6,0L99.3,23.8a6.1,6.1,0,0,0-4.9-.6A108.4,108.4,0,0,0,54.1,46.6a5.9,5.9,0,0,0-2,4.5l.5,27.8c-1.8,2.7-3.4,5.5-4.9,8.3L23.4,100.7a5.9,5.9,0,0,0-2.9,4,107.7,107.7,0,0,0,0,46.6,6.2,6.2,0,0,0,2.9,4l24.4,13.5a77.4,77.4,0,0,0,4.8,8.3l-.5,27.8a6.1,6.1,0,0,0,2,4.6,109.3,109.3,0,0,0,40.3,23.3,6.2,6.2,0,0,0,5-.6l23.8-14.3a76.9,76.9,0,0,0,9.6,0l23.9,14.3a5.6,5.6,0,0,0,3.1.9l1.8-.3a108.4,108.4,0,0,0,40.3-23.4,5.9,5.9,0,0,0,2-4.5l-.5-27.8c1.8-2.7,3.4-5.5,4.9-8.3l24.3-13.5a5.9,5.9,0,0,0,2.9-4A107.7,107.7,0,0,0,235.5,104.7Zm-11.2,41.4-23.5,13.1a5.7,5.7,0,0,0-2.5,2.6,91.2,91.2,0,0,1-5.8,10.1,6.6,6.6,0,0,0-1.1,3.5l.5,26.9a97.1,97.1,0,0,1-31.4,18.2l-23.1-13.9a6.5,6.5,0,0,0-3.1-.8h-.4a76.2,76.2,0,0,1-11.7,0,6.4,6.4,0,0,0-3.6.8L95.5,220.5a100.5,100.5,0,0,1-31.4-18.1l.5-26.9a6,6,0,0,0-1-3.5,73.2,73.2,0,0,1-5.9-10.2,5.7,5.7,0,0,0-2.5-2.6L31.7,146.1a95.8,95.8,0,0,1,0-36.2L55.2,96.8a5.7,5.7,0,0,0,2.5-2.6,91.2,91.2,0,0,1,5.8-10.1,5.8,5.8,0,0,0,1.1-3.5l-.5-26.9A98.1,98.1,0,0,1,95.5,35.5l23.1,13.9a6.2,6.2,0,0,0,3.5.8,76.2,76.2,0,0,1,11.7,0,6.4,6.4,0,0,0,3.6-.8l23.1-13.9a100.5,100.5,0,0,1,31.4,18.1l-.5,26.9a6.8,6.8,0,0,0,1,3.5,73.2,73.2,0,0,1,5.9,10.2,5.7,5.7,0,0,0,2.5,2.6l23.5,13.1A95.8,95.8,0,0,1,224.3,146.1Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,76a52,52,0,1,0,52,52A52,52,0,0,0,128,76Zm0,96a44,44,0,1,1,44-44A44,44,0,0,1,128,172Zm105.5-66.9a3.6,3.6,0,0,0-1.9-2.6L206.7,88.7a64.6,64.6,0,0,0-5.3-9.2l.5-28.5a4.3,4.3,0,0,0-1.3-3A108.5,108.5,0,0,0,161,25.1a4.2,4.2,0,0,0-3.3.4L133.3,40.2a75,75,0,0,0-10.6-.1L98.3,25.5a4.2,4.2,0,0,0-3.3-.4A109.4,109.4,0,0,0,55.4,48a4.4,4.4,0,0,0-1.3,3.1l.5,28.4a64.6,64.6,0,0,0-5.3,9.2L24.4,102.5a4.1,4.1,0,0,0-2,2.6,105.6,105.6,0,0,0,.1,45.8,3.6,3.6,0,0,0,1.9,2.6l24.9,13.8a64.6,64.6,0,0,0,5.3,9.2L54.1,205a4.3,4.3,0,0,0,1.3,3A108.5,108.5,0,0,0,95,230.9a4.2,4.2,0,0,0,3.3-.4l24.4-14.7a75,75,0,0,0,10.6.1l24.4,14.6a3.7,3.7,0,0,0,2.1.6l1.2-.2A109.4,109.4,0,0,0,200.6,208a4.4,4.4,0,0,0,1.3-3.1l-.5-28.4a64.6,64.6,0,0,0,5.3-9.2l24.9-13.8a4.1,4.1,0,0,0,2-2.6A105.6,105.6,0,0,0,233.5,105.1Zm-7.4,42.3-24.3,13.5a3.7,3.7,0,0,0-1.7,1.8,71.9,71.9,0,0,1-6,10.4,3.9,3.9,0,0,0-.7,2.3l.5,27.8a98.7,98.7,0,0,1-33.6,19.5l-23.9-14.3a4.2,4.2,0,0,0-2.1-.6H134a80.1,80.1,0,0,1-12,0,3.7,3.7,0,0,0-2.3.5L95.8,222.7a101.3,101.3,0,0,1-33.7-19.4l.5-27.9a3.9,3.9,0,0,0-.7-2.3,82.5,82.5,0,0,1-6-10.4,3.7,3.7,0,0,0-1.7-1.8L29.9,147.4a97.5,97.5,0,0,1,0-38.8L54.2,95.1a3.7,3.7,0,0,0,1.7-1.8,71.9,71.9,0,0,1,6-10.4,3.9,3.9,0,0,0,.7-2.3l-.5-27.8A98.7,98.7,0,0,1,95.7,33.3l23.9,14.3a3.5,3.5,0,0,0,2.4.6,80.1,80.1,0,0,1,12,0,3.7,3.7,0,0,0,2.3-.5l23.9-14.4a101.3,101.3,0,0,1,33.7,19.4l-.5,27.9a3.9,3.9,0,0,0,.7,2.3,82.5,82.5,0,0,1,6,10.4,3.7,3.7,0,0,0,1.7,1.8l24.3,13.5A97.5,97.5,0,0,1,226.1,147.4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M237.5,104.3a8,8,0,0,0-4-5.3L209.7,85.8a69.3,69.3,0,0,0-4.3-7.5l.5-27.2a8.3,8.3,0,0,0-2.6-6.1,112,112,0,0,0-41.1-23.7,8.1,8.1,0,0,0-6.6.8l-23.3,14c-2.9-.1-5.7-.1-8.6,0l-23.3-14a8.1,8.1,0,0,0-6.6-.8A111.1,111.1,0,0,0,52.7,45.1a7.9,7.9,0,0,0-2.6,6l.5,27.2c-1.6,2.4-3,4.9-4.4,7.5L22.4,99a7.9,7.9,0,0,0-3.9,5.3,111.4,111.4,0,0,0,0,47.4,8,8,0,0,0,4,5.3l23.8,13.2a69.3,69.3,0,0,0,4.3,7.5l-.5,27.2a8.3,8.3,0,0,0,2.6,6.1,112,112,0,0,0,41.1,23.7,8.1,8.1,0,0,0,6.6-.8l23.3-14h8.6l23.4,14a7.3,7.3,0,0,0,4.1,1.2,10,10,0,0,0,2.4-.4,111.1,111.1,0,0,0,41.1-23.8,7.9,7.9,0,0,0,2.6-6l-.5-27.2c1.6-2.4,3-4.9,4.4-7.5L233.6,157a7.9,7.9,0,0,0,3.9-5.3A111.4,111.4,0,0,0,237.5,104.3Zm-15,40.5-22.7,12.6a8.2,8.2,0,0,0-3.3,3.6,73.6,73.6,0,0,1-5.7,9.8,8.6,8.6,0,0,0-1.4,4.7l.4,25.9a94.4,94.4,0,0,1-29.1,16.9l-22.3-13.4a8.1,8.1,0,0,0-4.1-1.1h-.6a72.3,72.3,0,0,1-11.4,0,8.6,8.6,0,0,0-4.7,1.1L95.3,218.3a95,95,0,0,1-29.1-16.8l.4-26a8.6,8.6,0,0,0-1.4-4.7,86.4,86.4,0,0,1-5.7-9.8,8.8,8.8,0,0,0-3.3-3.6L33.5,144.8a94.8,94.8,0,0,1,0-33.6L56.2,98.6A8.2,8.2,0,0,0,59.5,95a73.6,73.6,0,0,1,5.7-9.8,8.6,8.6,0,0,0,1.4-4.7l-.4-25.9A94.4,94.4,0,0,1,95.3,37.7l22.3,13.4a8.4,8.4,0,0,0,4.7,1.1,72.3,72.3,0,0,1,11.4,0,8.6,8.6,0,0,0,4.7-1.1l22.3-13.4a95,95,0,0,1,29.1,16.8l-.4,26a8.6,8.6,0,0,0,1.4,4.7,86.4,86.4,0,0,1,5.7,9.8,8.8,8.8,0,0,0,3.3,3.6l22.7,12.6A94.8,94.8,0,0,1,222.5,144.8ZM128,72a56,56,0,1,0,56,56A56,56,0,0,0,128,72Zm0,96a40,40,0,1,1,40-40A40,40,0,0,1,128,168Z" />
        </>
      )}
    </svg>
  );
}

export function PhGear(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,72a56,56,0,1,0,56,56A56,56,0,0,0,128,72Zm0,88a32,32,0,1,1,32-32A32.1,32.1,0,0,1,128,160Zm110-12-14.1-18.8v-2.4L238,108a12.3,12.3,0,0,0,2-10.3,113.4,113.4,0,0,0-11.4-27.4,11.8,11.8,0,0,0-8.7-5.9l-23.1-3.3-1.9-1.9-3.3-23.1a11.8,11.8,0,0,0-5.9-8.7A113.4,113.4,0,0,0,158.3,16,11.9,11.9,0,0,0,148,18L129.3,32h-2.6L108,18a12.3,12.3,0,0,0-10.3-2A113.4,113.4,0,0,0,70.3,27.4a11.8,11.8,0,0,0-5.9,8.7L61.1,59.2a17,17,0,0,0-1.9,1.9L36.1,64.4a11.8,11.8,0,0,0-8.7,5.9A113.4,113.4,0,0,0,16,97.7,11.9,11.9,0,0,0,18,108l14.1,18.8v2.4L18,148a12.3,12.3,0,0,0-2,10.3,113.4,113.4,0,0,0,11.4,27.4,11.8,11.8,0,0,0,8.7,5.9l23.1,3.3,1.9,1.9,3.3,23.1a11.8,11.8,0,0,0,5.9,8.7A113.4,113.4,0,0,0,97.7,240a10.6,10.6,0,0,0,3.1.4A11.8,11.8,0,0,0,108,238l18.7-14h2.6L148,238a12.3,12.3,0,0,0,10.3,2,113.4,113.4,0,0,0,27.4-11.4,11.8,11.8,0,0,0,5.9-8.7l3.4-23.2,1.7-1.7,23.2-3.4a11.8,11.8,0,0,0,8.7-5.9A113.4,113.4,0,0,0,240,158.3,11.9,11.9,0,0,0,238,148Zm-27.5,20.7-21.3,3.1a12.2,12.2,0,0,0-7,3.6c-1.7,1.8-4.9,5.1-6.5,6.5a12.2,12.2,0,0,0-3.9,7.3l-3.1,21.3a85.3,85.3,0,0,1-11.2,4.7l-17.2-13a11.7,11.7,0,0,0-7.9-2.3h-8.8a11.9,11.9,0,0,0-7.9,2.3l-17.2,13a85.3,85.3,0,0,1-11.2-4.7l-3.1-21.3a12.2,12.2,0,0,0-3.9-7.3,60.5,60.5,0,0,1-6.2-6.2,12.2,12.2,0,0,0-7.3-3.9l-21.3-3.1a85.3,85.3,0,0,1-4.7-11.2l13-17.2a12.4,12.4,0,0,0,2.4-7.5c-.1-2.5-.2-7.1-.1-9.2a11.9,11.9,0,0,0-2.3-7.9l-13-17.2a85.3,85.3,0,0,1,4.7-11.2l21.3-3.1a12.2,12.2,0,0,0,7.3-3.9,60.5,60.5,0,0,1,6.2-6.2,12.2,12.2,0,0,0,3.9-7.3l3.1-21.3a85.3,85.3,0,0,1,11.2-4.7l17.2,13a11.7,11.7,0,0,0,7.9,2.3c2.9-.1,5.9-.1,8.8,0a11.7,11.7,0,0,0,7.9-2.3l17.2-13a85.3,85.3,0,0,1,11.2,4.7l3.1,21.3a12.2,12.2,0,0,0,3.9,7.3,60.5,60.5,0,0,1,6.2,6.2,12.2,12.2,0,0,0,7.3,3.9l21.3,3.1a85.3,85.3,0,0,1,4.7,11.2l-13,17.2a11.7,11.7,0,0,0-2.3,7.5c0,2.5.1,7.1,0,9.2a11.7,11.7,0,0,0,2.3,7.9l13,17.2A85.3,85.3,0,0,1,210.5,168.7Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M211.8,133.1c.3-3.4,0-10.2,0-10.2l16.6-22.1a103.2,103.2,0,0,0-10.2-24.6l-27.3-3.9q-3.5-3.7-7.2-7.2l-3.9-27.3a102.2,102.2,0,0,0-24.6-10.2L133.1,44.2a57.9,57.9,0,0,0-10.2,0L100.8,27.6A103.2,103.2,0,0,0,76.2,37.8L72.3,65.1q-3.7,3.5-7.2,7.2L37.8,76.2a102.2,102.2,0,0,0-10.2,24.6l16.6,22.1c-.3,3.4,0,10.2,0,10.2L27.6,155.2a103.2,103.2,0,0,0,10.2,24.6l27.3,3.9q3.5,3.8,7.2,7.2l3.9,27.3a102.2,102.2,0,0,0,24.6,10.2l22.1-16.6a57.9,57.9,0,0,0,10.2,0l22.1,16.6a103.2,103.2,0,0,0,24.6-10.2l3.9-27.3c2.5-2.3,7.2-7.2,7.2-7.2l27.3-3.9a102.2,102.2,0,0,0,10.2-24.6ZM128,176a48,48,0,1,1,48-48A48,48,0,0,1,128,176Z"
            opacity="0.2"
          />
          <path d="M128,72a56,56,0,1,0,56,56A56,56,0,0,0,128,72Zm0,96a40,40,0,1,1,40-40A40,40,0,0,1,128,168Zm106.8-17.6-14.9-19.8c.1-1.8,0-3.7,0-5.1l14.9-19.9a7.8,7.8,0,0,0,1.3-6.9,114.8,114.8,0,0,0-10.9-26.4,8.2,8.2,0,0,0-5.8-4l-24.5-3.5-3.7-3.7-3.5-24.5a8.2,8.2,0,0,0-4-5.8,114.8,114.8,0,0,0-26.4-10.9,7.8,7.8,0,0,0-6.9,1.3L130.6,36h-5.2L105.6,21.2a7.8,7.8,0,0,0-6.9-1.3A114.8,114.8,0,0,0,72.3,30.8a8.2,8.2,0,0,0-4,5.8L64.8,61.1l-3.7,3.7L36.6,68.3a8.2,8.2,0,0,0-5.8,4A114.8,114.8,0,0,0,19.9,98.7a7.8,7.8,0,0,0,1.3,6.9l14.9,19.8v5.1L21.2,150.4a7.8,7.8,0,0,0-1.3,6.9,114.8,114.8,0,0,0,10.9,26.4,8.2,8.2,0,0,0,5.8,4l24.5,3.5,3.7,3.7,3.5,24.5a8.2,8.2,0,0,0,4,5.8,114.8,114.8,0,0,0,26.4,10.9,7.6,7.6,0,0,0,2.1.3,7.7,7.7,0,0,0,4.8-1.6L125.4,220h5.2l19.8,14.8a7.8,7.8,0,0,0,6.9,1.3,114.8,114.8,0,0,0,26.4-10.9,8.2,8.2,0,0,0,4-5.8l3.5-24.6c1.2-1.2,2.6-2.5,3.6-3.6l24.6-3.5a8.2,8.2,0,0,0,5.8-4,114.8,114.8,0,0,0,10.9-26.4A7.8,7.8,0,0,0,234.8,150.4Zm-21.7,22-23.3,3.3a8.3,8.3,0,0,0-4.7,2.5c-1.2,1.3-4.9,5.1-6.7,6.7a8,8,0,0,0-2.7,4.9l-3.3,23.3a88.6,88.6,0,0,1-15.6,6.5l-18.9-14.2a7.7,7.7,0,0,0-5.3-1.5h-9.2a7.7,7.7,0,0,0-5.3,1.5L99.2,219.6a88.6,88.6,0,0,1-15.6-6.5l-3.3-23.3a8,8,0,0,0-2.7-4.9,66.5,66.5,0,0,1-6.5-6.5,8,8,0,0,0-4.9-2.7l-23.3-3.3a96.9,96.9,0,0,1-6.5-15.6l14.2-18.9a8.8,8.8,0,0,0,1.6-5c-.1-1.8-.2-7-.1-9.5a7.7,7.7,0,0,0-1.5-5.3L36.4,99.2a96.9,96.9,0,0,1,6.5-15.6l23.3-3.3a8,8,0,0,0,4.9-2.7,66.5,66.5,0,0,1,6.5-6.5,8,8,0,0,0,2.7-4.9l3.3-23.3a96.9,96.9,0,0,1,15.6-6.5l18.9,14.2a7.7,7.7,0,0,0,5.3,1.5c3-.1,6.2-.1,9.2,0a7.7,7.7,0,0,0,5.3-1.5l18.9-14.2a88.6,88.6,0,0,1,15.6,6.5l3.3,23.3a8,8,0,0,0,2.7,4.9,66.5,66.5,0,0,1,6.5,6.5,8,8,0,0,0,4.9,2.7l23.3,3.3a88.6,88.6,0,0,1,6.5,15.6l-14.2,18.9a8.8,8.8,0,0,0-1.6,5c.1,1.8.2,7,.1,9.5a7.7,7.7,0,0,0,1.5,5.3l14.2,18.9A88.6,88.6,0,0,1,213.1,172.4Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M234.8,150.4l-14.9-19.8c.1-1.8,0-3.7,0-5.1l14.9-19.9a7.8,7.8,0,0,0,1.3-6.9,114.8,114.8,0,0,0-10.9-26.4,8.2,8.2,0,0,0-5.8-4l-24.5-3.5-3.7-3.7-3.5-24.5a8.2,8.2,0,0,0-4-5.8,114.8,114.8,0,0,0-26.4-10.9,7.8,7.8,0,0,0-6.9,1.3L130.6,36h-5.2L105.6,21.2a7.8,7.8,0,0,0-6.9-1.3A114.8,114.8,0,0,0,72.3,30.8a8.2,8.2,0,0,0-4,5.8L64.8,61.1l-3.7,3.7L36.6,68.3a8.2,8.2,0,0,0-5.8,4A114.8,114.8,0,0,0,19.9,98.7a7.8,7.8,0,0,0,1.3,6.9l14.9,19.8v5.1L21.2,150.4a7.8,7.8,0,0,0-1.3,6.9,114.8,114.8,0,0,0,10.9,26.4,8.2,8.2,0,0,0,5.8,4l24.5,3.5,3.7,3.7,3.5,24.5a8.2,8.2,0,0,0,4,5.8,114.8,114.8,0,0,0,26.4,10.9,7.6,7.6,0,0,0,2.1.3,7.7,7.7,0,0,0,4.8-1.6L125.4,220h5.2l19.8,14.8a7.8,7.8,0,0,0,6.9,1.3,114.8,114.8,0,0,0,26.4-10.9,8.2,8.2,0,0,0,4-5.8l3.5-24.6c1.2-1.2,2.6-2.5,3.6-3.6l24.6-3.5a8.2,8.2,0,0,0,5.8-4,114.8,114.8,0,0,0,10.9-26.4A7.8,7.8,0,0,0,234.8,150.4ZM128,172a44,44,0,1,1,44-44A44,44,0,0,1,128,172Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,74a54,54,0,1,0,54,54A54,54,0,0,0,128,74Zm0,96a42,42,0,1,1,42-42A42,42,0,0,1,128,170Zm105.2-18.4-15.3-20.4c.1-2.2,0-4.7,0-6.4l15.3-20.4a6,6,0,0,0,1-5.2,109.1,109.1,0,0,0-10.8-25.9,6.1,6.1,0,0,0-4.3-3l-25.2-3.6c-1.5-1.6-3-3.1-4.6-4.6l-3.6-25.2a6,6,0,0,0-2.9-4.3,113.4,113.4,0,0,0-26-10.8,6,6,0,0,0-5.2,1L131.3,38.1q-3.3-.1-6.6,0L104.4,22.8a6,6,0,0,0-5.2-1A109.1,109.1,0,0,0,73.3,32.6a6.1,6.1,0,0,0-3,4.3L66.7,62.1c-1.6,1.5-3.1,3-4.6,4.6L36.9,70.3a6,6,0,0,0-4.3,2.9,113.4,113.4,0,0,0-10.8,26,6,6,0,0,0,1,5.2l15.3,20.4c-.1,2.2,0,4.7,0,6.4L22.8,151.6a6,6,0,0,0-1,5.2,109.1,109.1,0,0,0,10.8,25.9,5.8,5.8,0,0,0,4.3,3l25.2,3.6,4.6,4.6,3.6,25.2a6.1,6.1,0,0,0,3,4.3,110.9,110.9,0,0,0,25.9,10.8l1.6.2a6,6,0,0,0,3.6-1.2l20.3-15.3h6.6l20.3,15.3a6,6,0,0,0,5.2,1,111.6,111.6,0,0,0,26-10.8,6,6,0,0,0,2.9-4.3l3.6-25.2c1.6-1.5,3.3-3.3,4.5-4.6l25.3-3.6a6,6,0,0,0,4.3-2.9,113.4,113.4,0,0,0,10.8-26A6,6,0,0,0,233.2,151.6Zm-18.8,22.6-24.3,3.5a6.9,6.9,0,0,0-3.6,1.8c-1.2,1.4-4.9,5.2-6.8,6.9a6.2,6.2,0,0,0-2,3.7l-3.5,24.3a92.8,92.8,0,0,1-17.8,7.4L136.7,207a6,6,0,0,0-3.9-1.1h-9.6a6,6,0,0,0-3.9,1.1L99.6,221.8a86.8,86.8,0,0,1-17.8-7.4l-3.5-24.3a6.2,6.2,0,0,0-2-3.7,70.6,70.6,0,0,1-6.7-6.7,6.2,6.2,0,0,0-3.7-2l-24.3-3.5a92.8,92.8,0,0,1-7.4-17.8L49,136.7a6.4,6.4,0,0,0,1.2-3.8c-.1-1.8-.2-7.1-.1-9.7a6,6,0,0,0-1.1-3.9L34.2,99.6a86.8,86.8,0,0,1,7.4-17.8l24.3-3.5a6.2,6.2,0,0,0,3.7-2,70.6,70.6,0,0,1,6.7-6.7,6.2,6.2,0,0,0,2-3.7l3.5-24.3a92.8,92.8,0,0,1,17.8-7.4L119.3,49a6,6,0,0,0,3.9,1.1q4.8-.1,9.6,0a6,6,0,0,0,3.9-1.1l19.7-14.8a86.8,86.8,0,0,1,17.8,7.4l3.5,24.3a6.2,6.2,0,0,0,2,3.7,70.6,70.6,0,0,1,6.7,6.7,6.2,6.2,0,0,0,3.7,2l24.3,3.5a92.8,92.8,0,0,1,7.4,17.8L207,119.3a6.4,6.4,0,0,0-1.2,3.8c.1,1.8.2,7.1.1,9.7a6,6,0,0,0,1.1,3.9l14.8,19.7A86.8,86.8,0,0,1,214.4,174.2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,76a52,52,0,1,0,52,52A52,52,0,0,0,128,76Zm0,96a44,44,0,1,1,44-44A44,44,0,0,1,128,172Zm103.6-19.2-15.7-20.9c.1-2.6,0-5.9,0-7.7l15.7-21a4.2,4.2,0,0,0,.7-3.4,106.4,106.4,0,0,0-10.6-25.6,4.2,4.2,0,0,0-2.9-1.9l-25.9-3.7a71.4,71.4,0,0,0-5.5-5.5l-3.7-25.9a4.2,4.2,0,0,0-1.9-2.9,109.1,109.1,0,0,0-25.5-10.6,4.4,4.4,0,0,0-3.5.7L131.9,40.1q-3.9-.1-7.8,0L103.2,24.4a4.2,4.2,0,0,0-3.4-.7A106.4,106.4,0,0,0,74.2,34.3a4.2,4.2,0,0,0-1.9,2.9L68.6,63.1a71.4,71.4,0,0,0-5.5,5.5L37.2,72.3a4.2,4.2,0,0,0-2.9,1.9A108.1,108.1,0,0,0,23.7,99.8a4.2,4.2,0,0,0,.7,3.4l15.7,20.9c-.1,2.6,0,5.9,0,7.7l-15.7,21a4.2,4.2,0,0,0-.7,3.4,110.6,110.6,0,0,0,10.6,25.6,4.2,4.2,0,0,0,2.9,1.9l25.9,3.7a71.4,71.4,0,0,0,5.5,5.5l3.7,25.9a4.2,4.2,0,0,0,1.9,2.9,108.1,108.1,0,0,0,25.6,10.6h1a4.1,4.1,0,0,0,2.4-.8l20.9-15.7h7.8l20.9,15.7a4.2,4.2,0,0,0,3.4.7,106.4,106.4,0,0,0,25.6-10.6,4.2,4.2,0,0,0,1.9-2.9l3.7-25.9,5.5-5.5,25.9-3.7a4.2,4.2,0,0,0,2.9-1.9,108.1,108.1,0,0,0,10.6-25.6A4.2,4.2,0,0,0,231.6,152.8Zm-15.9,23.3-25.4,3.6a4.3,4.3,0,0,0-2.3,1.2c0,.1-4.6,4.9-7,7a3.8,3.8,0,0,0-1.3,2.4l-3.6,25.4A97.9,97.9,0,0,1,156,224l-20.5-15.4a3.9,3.9,0,0,0-2.6-.7h-9.8a3.9,3.9,0,0,0-2.6.7L100,224a97.9,97.9,0,0,1-20.1-8.3l-3.6-25.4a3.8,3.8,0,0,0-1.3-2.4,74.9,74.9,0,0,1-6.9-6.9,3.8,3.8,0,0,0-2.4-1.3l-25.4-3.6A97.9,97.9,0,0,1,32,156l15.4-20.5a4.4,4.4,0,0,0,.8-2.5c0-.1-.2-6.7-.1-9.9a3.9,3.9,0,0,0-.7-2.6L32,100a97.9,97.9,0,0,1,8.3-20.1l25.4-3.6A3.8,3.8,0,0,0,68.1,75,74.9,74.9,0,0,1,75,68.1a3.8,3.8,0,0,0,1.3-2.4l3.6-25.4A97.9,97.9,0,0,1,100,32l20.5,15.4a3.9,3.9,0,0,0,2.6.7c3.3-.1,6.5-.1,9.8,0a3.9,3.9,0,0,0,2.6-.7L156,32a97.9,97.9,0,0,1,20.1,8.3l3.6,25.4a3.8,3.8,0,0,0,1.3,2.4,74.9,74.9,0,0,1,6.9,6.9,3.8,3.8,0,0,0,2.4,1.3l25.4,3.6A97.9,97.9,0,0,1,224,100l-15.4,20.5a4.4,4.4,0,0,0-.8,2.5c0,.1.2,6.7.1,9.9a3.9,3.9,0,0,0,.7,2.6L224,156A97.9,97.9,0,0,1,215.7,176.1Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,72a56,56,0,1,0,56,56A56,56,0,0,0,128,72Zm0,96a40,40,0,1,1,40-40A40,40,0,0,1,128,168Zm106.8-17.6-14.9-19.8c.1-1.8,0-3.7,0-5.1l14.9-19.9a7.8,7.8,0,0,0,1.3-6.9,114.8,114.8,0,0,0-10.9-26.4,8.2,8.2,0,0,0-5.8-4l-24.5-3.5-3.7-3.7-3.5-24.5a8.2,8.2,0,0,0-4-5.8,114.8,114.8,0,0,0-26.4-10.9,7.8,7.8,0,0,0-6.9,1.3L130.6,36h-5.2L105.6,21.2a7.8,7.8,0,0,0-6.9-1.3A114.8,114.8,0,0,0,72.3,30.8a8.2,8.2,0,0,0-4,5.8L64.8,61.1l-3.7,3.7L36.6,68.3a8.2,8.2,0,0,0-5.8,4A114.8,114.8,0,0,0,19.9,98.7a7.8,7.8,0,0,0,1.3,6.9l14.9,19.8v5.1L21.2,150.4a7.8,7.8,0,0,0-1.3,6.9,114.8,114.8,0,0,0,10.9,26.4,8.2,8.2,0,0,0,5.8,4l24.5,3.5,3.7,3.7,3.5,24.5a8.2,8.2,0,0,0,4,5.8,114.8,114.8,0,0,0,26.4,10.9,7.6,7.6,0,0,0,2.1.3,7.7,7.7,0,0,0,4.8-1.6L125.4,220h5.2l19.8,14.8a7.8,7.8,0,0,0,6.9,1.3,114.8,114.8,0,0,0,26.4-10.9,8.2,8.2,0,0,0,4-5.8l3.5-24.6c1.2-1.2,2.6-2.5,3.6-3.6l24.6-3.5a8.2,8.2,0,0,0,5.8-4,114.8,114.8,0,0,0,10.9-26.4A7.8,7.8,0,0,0,234.8,150.4Zm-21.7,22-23.3,3.3a8.3,8.3,0,0,0-4.7,2.5c-1.2,1.3-4.9,5.1-6.7,6.7a8,8,0,0,0-2.7,4.9l-3.3,23.3a88.6,88.6,0,0,1-15.6,6.5l-18.9-14.2a7.7,7.7,0,0,0-5.3-1.5h-9.2a7.7,7.7,0,0,0-5.3,1.5L99.2,219.6a88.6,88.6,0,0,1-15.6-6.5l-3.3-23.3a8,8,0,0,0-2.7-4.9,66.5,66.5,0,0,1-6.5-6.5,8,8,0,0,0-4.9-2.7l-23.3-3.3a96.9,96.9,0,0,1-6.5-15.6l14.2-18.9a8.8,8.8,0,0,0,1.6-5c-.1-1.8-.2-7-.1-9.5a7.7,7.7,0,0,0-1.5-5.3L36.4,99.2a96.9,96.9,0,0,1,6.5-15.6l23.3-3.3a8,8,0,0,0,4.9-2.7,66.5,66.5,0,0,1,6.5-6.5,8,8,0,0,0,2.7-4.9l3.3-23.3a96.9,96.9,0,0,1,15.6-6.5l18.9,14.2a7.7,7.7,0,0,0,5.3,1.5c3-.1,6.2-.1,9.2,0a7.7,7.7,0,0,0,5.3-1.5l18.9-14.2a88.6,88.6,0,0,1,15.6,6.5l3.3,23.3a8,8,0,0,0,2.7,4.9,66.5,66.5,0,0,1,6.5,6.5,8,8,0,0,0,4.9,2.7l23.3,3.3a88.6,88.6,0,0,1,6.5,15.6l-14.2,18.9a8.8,8.8,0,0,0-1.6,5c.1,1.8.2,7,.1,9.5a7.7,7.7,0,0,0,1.5,5.3l14.2,18.9A88.6,88.6,0,0,1,213.1,172.4Z" />
        </>
      )}
    </svg>
  );
}

export function PhGenderFemale(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M212,96a84,84,0,1,0-96,83.1V196H88a12,12,0,0,0,0,24h28v20a12,12,0,0,0,24,0V220h28a12,12,0,0,0,0-24H140V179.1A84,84,0,0,0,212,96ZM68,96a60,60,0,1,1,60,60A60,60,0,0,1,68,96Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M200,96a72,72,0,1,1-72-72A72,72,0,0,1,200,96Z"
            opacity="0.2"
          />
          <path d="M208,96a80,80,0,1,0-88,79.6V200H88a8,8,0,0,0,0,16h32v24a8,8,0,0,0,16,0V216h32a8,8,0,0,0,0-16H136V175.6A80.1,80.1,0,0,0,208,96ZM64,96a64,64,0,1,1,64,64A64.1,64.1,0,0,1,64,96Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M208,96a80,80,0,1,0-88,79.6V200H88a8,8,0,0,0,0,16h32v24a8,8,0,0,0,16,0V216h32a8,8,0,0,0,0-16H136V175.6A80.1,80.1,0,0,0,208,96Zm-80,64a64,64,0,1,1,64-64A64.1,64.1,0,0,1,128,160Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M206,96a78,78,0,1,0-84,77.7V202H88a6,6,0,0,0,0,12h34v26a6,6,0,0,0,12,0V214h34a6,6,0,0,0,0-12H134V173.7A78,78,0,0,0,206,96ZM62,96a66,66,0,1,1,66,66A66.1,66.1,0,0,1,62,96Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M204,96a76,76,0,1,0-80,75.9V204H88a4,4,0,0,0,0,8h36v28a4,4,0,0,0,8,0V212h36a4,4,0,0,0,0-8H132V171.9A76.1,76.1,0,0,0,204,96ZM60,96a68,68,0,1,1,68,68A68.1,68.1,0,0,1,60,96Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M208,96a80,80,0,1,0-88,79.6V200H88a8,8,0,0,0,0,16h32v24a8,8,0,0,0,16,0V216h32a8,8,0,0,0,0-16H136V175.6A80.1,80.1,0,0,0,208,96ZM64,96a64,64,0,1,1,64,64A64.1,64.1,0,0,1,64,96Z" />
        </>
      )}
    </svg>
  );
}

export function PhGenderIntersex(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M208,12H172a12,12,0,0,0,0,24h7L164,51.1A71.2,71.2,0,0,0,120,36a72,72,0,0,0-12,143v13H88a12,12,0,0,0,0,24h20v16a12,12,0,0,0,24,0V216h20a12,12,0,0,0,0-24H132V179A72,72,0,0,0,180.3,68.7L196,53v7a12,12,0,0,0,24,0V24A12,12,0,0,0,208,12ZM120,156a48,48,0,1,1,48-48A48,48,0,0,1,120,156Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M180,108a60,60,0,1,1-60-60A60,60,0,0,1,180,108Z"
            opacity="0.2"
          />
          <path d="M208,16H168a8,8,0,0,0,0,16h20.7L164.3,56.4A68,68,0,1,0,112,175.5V196H88a8,8,0,0,0,0,16h24v20a8,8,0,0,0,16,0V212h24a8,8,0,0,0,0-16H128V175.5A67.9,67.9,0,0,0,175.1,68.2L200,43.3V64a8,8,0,0,0,16,0V24A8,8,0,0,0,208,16ZM120,160a52,52,0,1,1,52-52A52,52,0,0,1,120,160Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M208,16H168a8,8,0,0,0,0,16h20.7L164.3,56.4A68,68,0,1,0,112,175.5V196H88a8,8,0,0,0,0,16h24v20a8,8,0,0,0,16,0V212h24a8,8,0,0,0,0-16H128V175.5A67.9,67.9,0,0,0,175.1,68.2L200,43.3V64a8,8,0,0,0,16,0V24A8,8,0,0,0,208,16ZM120,160a52,52,0,1,1,52-52A52,52,0,0,1,120,160Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M208,18H168a6,6,0,0,0,0,12h25.5L164.4,59.2A66,66,0,1,0,114,173.7V198H88a6,6,0,0,0,0,12h26v22a6,6,0,0,0,12,0V210h26a6,6,0,0,0,0-12H126V173.7A66,66,0,0,0,172.5,68L202,38.5V64a6,6,0,0,0,12,0V24A6,6,0,0,0,208,18ZM120,162a54,54,0,1,1,54-54A54,54,0,0,1,120,162Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M208,20H168a4,4,0,0,0,0,8h30.3L164.4,62A64,64,0,1,0,116,171.9V200H88a4,4,0,0,0,0,8h28v24a4,4,0,0,0,8,0V208h28a4,4,0,0,0,0-8H124V171.9A64.2,64.2,0,0,0,184,108a63.2,63.2,0,0,0-14.2-40.1L204,33.7V64a4,4,0,0,0,8,0V24A4,4,0,0,0,208,20ZM120,164a56,56,0,1,1,56-56A56,56,0,0,1,120,164Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M208,16H168a8,8,0,0,0,0,16h20.7L164.3,56.4A68,68,0,1,0,112,175.5V196H88a8,8,0,0,0,0,16h24v20a8,8,0,0,0,16,0V212h24a8,8,0,0,0,0-16H128V175.5A67.9,67.9,0,0,0,175.1,68.2L200,43.3V64a8,8,0,0,0,16,0V24A8,8,0,0,0,208,16ZM120,160a52,52,0,1,1,52-52A52,52,0,0,1,120,160Z" />
        </>
      )}
    </svg>
  );
}

export function PhGenderMale(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216,28H168a12,12,0,0,0,0,24h19L154.3,84.7a84,84,0,1,0,17,17L204,69V88a12,12,0,0,0,24,0V40A12,12,0,0,0,216,28ZM146.4,194.4a60,60,0,1,1,0-84.8A60,60,0,0,1,146.4,194.4Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M154.9,202.9a72,72,0,1,1,0-101.8A72,72,0,0,1,154.9,202.9Z"
            opacity="0.2"
          />
          <path d="M216,32H168a8,8,0,0,0,0,16h28.7L154.6,90.1a80,80,0,1,0,11.3,11.3L208,59.3V88a8,8,0,0,0,16,0V40A8,8,0,0,0,216,32ZM149.3,197.3a64,64,0,1,1,0-90.6A64.3,64.3,0,0,1,149.3,197.3Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,32H168a8,8,0,0,0,0,16h28.7L154.6,90.1a80,80,0,1,0,11.3,11.3L208,59.3V88a8,8,0,0,0,16,0V40A8,8,0,0,0,216,32ZM149.3,197.3a64,64,0,1,1,0-90.6A64.3,64.3,0,0,1,149.3,197.3Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M216,34H168a6,6,0,0,0,0,12h33.5L154.7,92.8a77.9,77.9,0,1,0,4.5,114.4h0a78.1,78.1,0,0,0,4-105.9L210,54.5V88a6,6,0,0,0,12,0V40A6,6,0,0,0,216,34ZM150.7,198.7a66,66,0,1,1,0-93.4A66.2,66.2,0,0,1,150.7,198.7Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M216,36H168a4,4,0,0,0,0,8h38.3L154.8,95.5a75.9,75.9,0,1,0,5.7,5.7L212,49.7V88a4,4,0,0,0,8,0V40A4,4,0,0,0,216,36ZM152.1,200.1a68,68,0,1,1,0-96.2A68.1,68.1,0,0,1,152.1,200.1Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,32H168a8,8,0,0,0,0,16h28.7L154.6,90.1a80,80,0,1,0,11.3,11.3L208,59.3V88a8,8,0,0,0,16,0V40A8,8,0,0,0,216,32ZM149.3,197.3a64,64,0,1,1,0-90.6A64.3,64.3,0,0,1,149.3,197.3Z" />
        </>
      )}
    </svg>
  );
}

export function PhGenderNeuter(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M212,104a84,84,0,1,0-96,83.1V232a12,12,0,0,0,24,0V187.1A84,84,0,0,0,212,104Zm-84,60a60,60,0,1,1,60-60A60,60,0,0,1,128,164Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M200,104a72,72,0,1,1-72-72A72,72,0,0,1,200,104Z"
            opacity="0.2"
          />
          <path d="M208,104a80,80,0,1,0-88,79.6V232a8,8,0,0,0,16,0V183.6A80.1,80.1,0,0,0,208,104Zm-80,64a64,64,0,1,1,64-64A64.1,64.1,0,0,1,128,168Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,24a80,80,0,0,0-8,159.6V232a8,8,0,0,0,16,0V183.6A80,80,0,0,0,128,24Zm0,144a64,64,0,1,1,64-64A64.1,64.1,0,0,1,128,168Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M206,104a78,78,0,1,0-84,77.7V232a6,6,0,0,0,12,0V181.7A78,78,0,0,0,206,104Zm-78,66a66,66,0,1,1,66-66A66.1,66.1,0,0,1,128,170Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M204,104a76,76,0,1,0-80,75.9V232a4,4,0,0,0,8,0V179.9A76.1,76.1,0,0,0,204,104Zm-76,68a68,68,0,1,1,68-68A68.1,68.1,0,0,1,128,172Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M208,104a80,80,0,1,0-88,79.6V232a8,8,0,0,0,16,0V183.6A80.1,80.1,0,0,0,208,104Zm-80,64a64,64,0,1,1,64-64A64.1,64.1,0,0,1,128,168Z" />
        </>
      )}
    </svg>
  );
}

export function PhGenderNonbinary(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M140,84.9V63.5l17.4,10.7a12.3,12.3,0,0,0,6.3,1.8,11.8,11.8,0,0,0,10.2-5.7A12,12,0,0,0,170,53.8L150.9,42,170,30.2A12,12,0,0,0,157.4,9.8L128,27.9,98.6,9.8A12,12,0,0,0,86,30.2L105.1,42,86,53.8a12,12,0,0,0-3.9,16.5A11.8,11.8,0,0,0,92.3,76a12.3,12.3,0,0,0,6.3-1.8L116,63.5V84.9a80,80,0,1,0,24,0ZM128,220a56,56,0,1,1,56-56A56,56,0,0,1,128,220Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M136,88.4V59l23.3,15.6a7.2,7.2,0,0,0,4.4,1.4,8,8,0,0,0,4.5-14.6L142.3,44l25.9-17.4a8,8,0,1,0-8.9-13.2L128,34.4l-31.3-21a8,8,0,1,0-8.9,13.2L113.7,44,87.8,61.4A8,8,0,0,0,92.3,76a7.2,7.2,0,0,0,4.4-1.4L120,59V88.4a76,76,0,1,0,16,0ZM128,224a60,60,0,1,1,60-60A60,60,0,0,1,128,224Z" />
          <circle cx="128" cy="164" r="68" opacity="0.2" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M136,88.4V59l23.3,15.6a7.2,7.2,0,0,0,4.4,1.4,8,8,0,0,0,4.5-14.6L142.3,44l25.9-17.4a8,8,0,1,0-8.9-13.2L128,34.4l-31.3-21a8,8,0,1,0-8.9,13.2L113.7,44,87.8,61.4A8,8,0,0,0,92.3,76a7.2,7.2,0,0,0,4.4-1.4L120,59V88.4a76,76,0,1,0,16,0ZM128,224a60,60,0,1,1,60-60A60,60,0,0,1,128,224Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M134,90.3v-35L160.4,73a5.8,5.8,0,0,0,3.3,1,6,6,0,0,0,5-2.7,6.1,6.1,0,0,0-1.6-8.3L138.8,44l28.3-19a6.1,6.1,0,0,0,1.6-8.3,6,6,0,0,0-8.3-1.7L128,36.8,95.6,15a6,6,0,0,0-8.3,1.7A6.1,6.1,0,0,0,88.9,25l28.3,19L88.9,63a6.1,6.1,0,0,0-1.6,8.3,6,6,0,0,0,5,2.7,5.8,5.8,0,0,0,3.3-1L122,55.3v35a74,74,0,1,0,12,0ZM128,226a62,62,0,1,1,62-62A62.1,62.1,0,0,1,128,226Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M132,92.1V51.5l29.5,19.8a3.6,3.6,0,0,0,2.2.7,3.8,3.8,0,0,0,3.3-1.8,3.8,3.8,0,0,0-1.1-5.5L135.2,44l30.7-20.7a3.8,3.8,0,0,0,1.1-5.5,3.8,3.8,0,0,0-5.5-1.1L128,39.2,94.5,16.7A3.8,3.8,0,0,0,89,17.8a3.8,3.8,0,0,0,1.1,5.5L120.8,44,90.1,64.7A3.8,3.8,0,0,0,89,70.2,3.8,3.8,0,0,0,92.3,72a3.6,3.6,0,0,0,2.2-.7L124,51.5V92.1a72,72,0,1,0,8,0ZM128,228a64,64,0,1,1,64-64A64.1,64.1,0,0,1,128,228Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M136,88.4V59l23.3,15.6a7.2,7.2,0,0,0,4.4,1.4,8,8,0,0,0,4.5-14.6L142.3,44l25.9-17.4a8,8,0,1,0-8.9-13.2L128,34.4l-31.3-21a8,8,0,1,0-8.9,13.2L113.7,44,87.8,61.4A8,8,0,0,0,92.3,76a7.2,7.2,0,0,0,4.4-1.4L120,59V88.4a76,76,0,1,0,16,0ZM128,224a60,60,0,1,1,60-60A60,60,0,0,1,128,224Z" />
        </>
      )}
    </svg>
  );
}

export function PhGenderTransgender(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216,28H172a12,12,0,0,0,0,24h15L168,71,152.5,55.5a12,12,0,0,0-17,17L151,88,140.6,98.5a75.9,75.9,0,0,0-98.3,7.8A76,76,0,0,0,96,236a76.1,76.1,0,0,0,76-76,76.1,76.1,0,0,0-14.4-44.6L168,105l15.5,15.5a12.1,12.1,0,0,0,17,0,12,12,0,0,0,0-17L185,88l19-19V84a12,12,0,0,0,24,0V40A12,12,0,0,0,216,28ZM132.8,196.8A52.1,52.1,0,1,1,148,160,52.3,52.3,0,0,1,132.8,196.8Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M141.3,205.3a64.1,64.1,0,1,1,0-90.6A64.3,64.3,0,0,1,141.3,205.3Z"
            opacity="0.2"
          />
          <path d="M216,32H168a8,8,0,0,0,0,16h28.7L168,76.7,149.7,58.3a8.1,8.1,0,0,0-11.4,11.4L156.7,88l-15.8,15.8a72.2,72.2,0,1,0,11.3,11.3L168,99.3l18.3,18.4a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4L179.3,88,208,59.3V88a8,8,0,0,0,16,0V40A8,8,0,0,0,216,32ZM135.6,199.6a56.1,56.1,0,0,1-79.2,0,56,56,0,0,1,79.2-79.2,56,56,0,0,1,0,79.2Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,32H168a8,8,0,0,0,0,16h28.7L168,76.7,149.7,58.3a8.1,8.1,0,0,0-11.4,11.4L156.7,88l-15.8,15.8a72.2,72.2,0,1,0,11.3,11.3L168,99.3l18.3,18.4a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4L179.3,88,208,59.3V88a8,8,0,0,0,16,0V40A8,8,0,0,0,216,32ZM135.6,199.6a56.1,56.1,0,0,1-79.2,0,56,56,0,0,1,79.2-79.2,56,56,0,0,1,0,79.2Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M216,34H168a6,6,0,0,0,0,12h33.5L168,79.5,148.2,59.8a5.9,5.9,0,0,0-8.4,8.4L159.5,88l-18.4,18.5a68.9,68.9,0,1,0,8.5,8.4L168,96.5l19.8,19.7a5.9,5.9,0,1,0,8.4-8.4L176.5,88,210,54.5V88a6,6,0,0,0,12,0V40A6,6,0,0,0,216,34ZM137,201a58.1,58.1,0,0,1-82,0,58,58,0,1,1,82-82,57.9,57.9,0,0,1,0,82Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M216,36H168a4,4,0,0,0,0,8h38.3L168,82.3,146.8,61.2a4,4,0,0,0-5.6,5.6L162.3,88l-21.1,21.2a68,68,0,1,0,5.6,5.6L168,93.7l21.2,21.1a3.9,3.9,0,0,0,5.6,0,3.9,3.9,0,0,0,0-5.6L173.7,88,212,49.7V88a4,4,0,0,0,8,0V40A4,4,0,0,0,216,36ZM138.4,202.4a60,60,0,0,1-84.8,0,60,60,0,1,1,84.8-84.8,59.9,59.9,0,0,1,0,84.8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,32H168a8,8,0,0,0,0,16h28.7L168,76.7,149.7,58.3a8.1,8.1,0,0,0-11.4,11.4L156.7,88l-15.8,15.8a72.2,72.2,0,1,0,11.3,11.3L168,99.3l18.3,18.4a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4L179.3,88,208,59.3V88a8,8,0,0,0,16,0V40A8,8,0,0,0,216,32ZM135.6,199.6a56.1,56.1,0,0,1-79.2,0,56,56,0,0,1,79.2-79.2,56,56,0,0,1,0,79.2Z" />
        </>
      )}
    </svg>
  );
}

export function PhGhost(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M116,116a16,16,0,1,1-16-16A16,16,0,0,1,116,116Zm40-16a16,16,0,1,0,16,16A16,16,0,0,0,156,100Zm72,20v96a11.9,11.9,0,0,1-6.9,10.8A11.6,11.6,0,0,1,216,228a12,12,0,0,1-7.6-2.7l-21.7-17.8-21.8,17.8a12,12,0,0,1-15.2,0L128,207.5l-21.7,17.8a12,12,0,0,1-15.2,0L69.3,207.5,47.6,225.3A12.1,12.1,0,0,1,28,216V120a100,100,0,0,1,200,0Zm-24,0a76,76,0,0,0-152,0v70.7l9.7-8a12,12,0,0,1,15.2,0l21.8,17.8,21.7-17.8a12,12,0,0,1,15.2,0l21.7,17.8,21.8-17.8a12,12,0,0,1,15.2,0l9.7,8Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M216,216l-29.3-24-29.4,24L128,192,98.7,216,69.3,192,40,216V120a88,88,0,0,1,176,0Z" />
          </g>
          <path d="M112,116a12,12,0,1,1-12-12A12,12,0,0,1,112,116Zm44-12a12,12,0,1,0,12,12A12,12,0,0,0,156,104Zm68,16v96a7.9,7.9,0,0,1-4.6,7.2,7.9,7.9,0,0,1-8.5-1l-24.2-19.9-24.3,19.9a8,8,0,0,1-10.1,0L128,202.3l-24.3,19.9a8,8,0,0,1-10.1,0L69.3,202.3,45.1,222.2A8.1,8.1,0,0,1,32,216V120a96,96,0,0,1,192,0Zm-16,0a80,80,0,0,0-160,0v79.1l16.3-13.3a8,8,0,0,1,10.1,0l24.3,19.9,24.2-19.9a8.1,8.1,0,0,1,10.2,0l24.2,19.9,24.3-19.9a8,8,0,0,1,10.1,0L208,199.1Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,24a96.2,96.2,0,0,0-96,96v96a8.1,8.1,0,0,0,13.1,6.2l24.2-19.9,24.3,19.9a8,8,0,0,0,10.1,0L128,202.3l24.3,19.9a8,8,0,0,0,10.1,0l24.3-19.9,24.2,19.9a7.9,7.9,0,0,0,8.5,1A7.9,7.9,0,0,0,224,216V120A96.2,96.2,0,0,0,128,24ZM100,128a12,12,0,1,1,12-12A12,12,0,0,1,100,128Zm56,0a12,12,0,1,1,12-12A12,12,0,0,1,156,128Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M110,116a10,10,0,1,1-10-10A10,10,0,0,1,110,116Zm46-10a10,10,0,1,0,10,10A10,10,0,0,0,156,106Zm66,14v96a6,6,0,0,1-3.4,5.4,5.8,5.8,0,0,1-2.6.6,5.9,5.9,0,0,1-3.8-1.4l-25.5-20.8-25.6,20.8a5.8,5.8,0,0,1-7.6,0L128,199.8l-25.5,20.8a5.8,5.8,0,0,1-7.6,0L69.3,199.8,43.8,220.6A6,6,0,0,1,34,216V120a94,94,0,0,1,188,0Zm-12,0a82,82,0,0,0-164,0v83.3l19.5-15.9a5.8,5.8,0,0,1,7.6,0l25.6,20.8,25.5-20.8a5.9,5.9,0,0,1,7.6,0l25.5,20.8,25.6-20.8a5.8,5.8,0,0,1,7.6,0L210,203.3Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M108,116a8,8,0,1,1-8-8A8,8,0,0,1,108,116Zm48-8a8,8,0,1,0,8,8A8,8,0,0,0,156,108Zm64,12v96a4,4,0,0,1-2.3,3.6,3.3,3.3,0,0,1-1.7.4,3.8,3.8,0,0,1-2.5-.9l-26.8-21.9-26.8,21.9a4.1,4.1,0,0,1-5.1,0L128,197.2l-26.8,21.9a4.1,4.1,0,0,1-5.1,0L69.3,197.2,42.5,219.1a3.8,3.8,0,0,1-4.2.5A4,4,0,0,1,36,216V120a92,92,0,0,1,184,0Zm-8,0a84,84,0,0,0-168,0v87.6l22.8-18.7a4.1,4.1,0,0,1,5.1,0l26.8,21.9,26.8-21.9a3.9,3.9,0,0,1,5,0l26.8,21.9,26.8-21.9a4.1,4.1,0,0,1,5.1,0L212,207.6Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M112,116a12,12,0,1,1-12-12A12,12,0,0,1,112,116Zm44-12a12,12,0,1,0,12,12A12,12,0,0,0,156,104Zm68,16v96a7.9,7.9,0,0,1-4.6,7.2,7.9,7.9,0,0,1-8.5-1l-24.2-19.9-24.3,19.9a8,8,0,0,1-10.1,0L128,202.3l-24.3,19.9a8,8,0,0,1-10.1,0L69.3,202.3,45.1,222.2A8.1,8.1,0,0,1,32,216V120a96,96,0,0,1,192,0Zm-16,0a80,80,0,0,0-160,0v79.1l16.3-13.3a8,8,0,0,1,10.1,0l24.3,19.9,24.2-19.9a8.1,8.1,0,0,1,10.2,0l24.2,19.9,24.3-19.9a8,8,0,0,1,10.1,0L208,199.1Z" />
        </>
      )}
    </svg>
  );
}

export function PhGif(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M148,72V184a12,12,0,0,1-24,0V72a12,12,0,0,1,24,0Zm80-12H180a12,12,0,0,0-12,12V184a12,12,0,0,0,24,0V140h24a12,12,0,0,0,0-24H192V84h36a12,12,0,0,0,0-24ZM96,120H72a12,12,0,0,0,0,24H84v8a20,20,0,0,1-40,0V104a20,20,0,0,1,39.4-5,12,12,0,0,0,23.2-6A44,44,0,0,0,20,104v48a44,44,0,0,0,88,0V132A12,12,0,0,0,96,120Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M144,72V184a8,8,0,0,1-16,0V72a8,8,0,0,1,16,0Zm84-8H180a8,8,0,0,0-8,8V184a8,8,0,0,0,16,0V136h28a8,8,0,0,0,0-16H188V80h40a8,8,0,0,0,0-16ZM96,120H72a8,8,0,0,0,0,16H88v16a24,24,0,0,1-48,0V104a24,24,0,0,1,47.2-6,8,8,0,0,0,15.5-4A40,40,0,0,0,24,104v48a40,40,0,0,0,80,0V128A8,8,0,0,0,96,120Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M144,72V184a8,8,0,0,1-16,0V72a8,8,0,0,1,16,0Zm84-8H180a8,8,0,0,0-8,8V184a8,8,0,0,0,16,0V136h28a8,8,0,0,0,0-16H188V80h40a8,8,0,0,0,0-16ZM96,120H72a8,8,0,0,0,0,16H88v16a24,24,0,0,1-48,0V104a24,24,0,0,1,47.2-6,8,8,0,0,0,15.5-4A40,40,0,0,0,24,104v48a40,40,0,0,0,80,0V128A8,8,0,0,0,96,120Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M142,72V184a6,6,0,0,1-12,0V72a6,6,0,0,1,12,0Zm86-6H180a6,6,0,0,0-6,6V184a6,6,0,0,0,12,0V134h30a6,6,0,0,0,0-12H186V78h42a6,6,0,0,0,0-12ZM96,122H72a6,6,0,0,0,0,12H90v18a26,26,0,0,1-52,0V104a26,26,0,0,1,51.2-6.5,6,6,0,0,0,11.6-3A38,38,0,0,0,26,104v48a38,38,0,0,0,76,0V128A6,6,0,0,0,96,122Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M140,72V184a4,4,0,0,1-8,0V72a4,4,0,0,1,8,0Zm88-4H180a4,4,0,0,0-4,4V184a4,4,0,0,0,8,0V132h32a4,4,0,0,0,0-8H184V76h44a4,4,0,0,0,0-8ZM96,124H72a4,4,0,0,0,0,8H92v20a28,28,0,0,1-56,0V104a28,28,0,0,1,55.1-7,4,4,0,1,0,7.8-2A36,36,0,0,0,28,104v48a36,36,0,0,0,72,0V128A4,4,0,0,0,96,124Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M144,72V184a8,8,0,0,1-16,0V72a8,8,0,0,1,16,0Zm84-8H180a8,8,0,0,0-8,8V184a8,8,0,0,0,16,0V136h28a8,8,0,0,0,0-16H188V80h40a8,8,0,0,0,0-16ZM96,120H72a8,8,0,0,0,0,16H88v16a24,24,0,0,1-48,0V104a24,24,0,0,1,47.2-6,8,8,0,0,0,15.5-4A40,40,0,0,0,24,104v48a40,40,0,0,0,80,0V128A8,8,0,0,0,96,120Z" />
        </>
      )}
    </svg>
  );
}

export function PhGift(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216,68H188.4a36,36,0,0,0-57.6-41.7,30.4,30.4,0,0,0-2.8,3.3,30.4,30.4,0,0,0-2.8-3.3A36,36,0,0,0,67.6,68H40A20.1,20.1,0,0,0,20,88v32a20.1,20.1,0,0,0,16,19.6V200a20.1,20.1,0,0,0,20,20H200a20.1,20.1,0,0,0,20-20V139.6A20.1,20.1,0,0,0,236,120V88A20.1,20.1,0,0,0,216,68Zm-4,48H140V92h72ZM147.8,43.2a12,12,0,0,1,8.5-3.5,12,12,0,0,1,8.5,20.5c-3.3,3.3-13.5,5.8-24.1,7C142.1,55.4,145,46,147.8,43.2Zm-56.6,0a12.2,12.2,0,0,1,17,0c3.3,3.3,5.8,13.5,7,24.1-11.8-1.4-21.2-4.3-24-7.1A12.2,12.2,0,0,1,91.2,43.2ZM44,92h72v24H44Zm16,48h56v56H60Zm80,56V140h56v56Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M216,72H181a32,32,0,0,0-47.3-42.9,29.2,29.2,0,0,0-5.7,8.2,29.2,29.2,0,0,0-5.7-8.2A32,32,0,0,0,75,72H40A16,16,0,0,0,24,88v32a16,16,0,0,0,16,16v64a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V136a16,16,0,0,0,16-16V88A16,16,0,0,0,216,72ZM145,40.4A16,16,0,1,1,167.6,63c-4.9,5-19.2,7.7-31.3,8.7C137.3,59.6,140,45.3,145,40.4Zm-56.6,0a16.1,16.1,0,0,1,22.6,0c5,4.9,7.7,19.2,8.7,31.3-12.1-1-26.4-3.7-31.3-8.7A16.1,16.1,0,0,1,88.4,40.4ZM40,88h80v32H40Zm16,48h64v64H56Zm144,64H136V136h64Zm16-80H136V88h80v32Z" />
          <g opacity="0.2">
            <path d="M208,128v72a8,8,0,0,1-8,8H56a8,8,0,0,1-8-8V128Z" />
          </g>
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,72H181a32,32,0,0,0-47.3-42.9,29.2,29.2,0,0,0-5.7,8.2,29.2,29.2,0,0,0-5.7-8.2A32,32,0,0,0,75,72H40A16,16,0,0,0,24,88v32a16,16,0,0,0,16,16v64a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V136a16,16,0,0,0,16-16V88A16,16,0,0,0,216,72ZM145,40.4A16,16,0,1,1,167.6,63c-4.9,5-19.2,7.7-31.3,8.7C137.3,59.6,140,45.3,145,40.4Zm-56.6,0a16.1,16.1,0,0,1,22.6,0c5,4.9,7.7,19.2,8.7,31.3-12.1-1-26.4-3.7-31.3-8.7A16.1,16.1,0,0,1,88.4,40.4ZM216,120H136v72a8,8,0,0,1-16,0V120H40V88h80v32h16V88h80v32Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M216,74H176.3l1.2-1.1h0a30,30,0,1,0-42.4-42.4A33.9,33.9,0,0,0,128,42.2a33.9,33.9,0,0,0-7.1-11.7A30,30,0,0,0,78.5,72.9h0L79.7,74H40A14,14,0,0,0,26,88v32a14,14,0,0,0,14,14h2v66a14,14,0,0,0,14,14H200a14,14,0,0,0,14-14V134h2a14,14,0,0,0,14-14V88A14,14,0,0,0,216,74ZM143.6,39A18,18,0,1,1,169,64.4c-5.9,5.9-22.5,8.7-34.8,9.4C134.9,61.5,137.7,44.9,143.6,39ZM87,39a18,18,0,0,1,25.4,0c5.9,5.9,8.7,22.5,9.4,34.8-12.3-.7-28.9-3.5-34.8-9.4h0A18,18,0,0,1,87,39ZM38,120V88a2,2,0,0,1,2-2h82v36H40A2,2,0,0,1,38,120Zm16,80V134h68v68H56A2,2,0,0,1,54,200Zm148,0a2,2,0,0,1-2,2H134V134h68Zm16-80a2,2,0,0,1-2,2H134V86h82a2,2,0,0,1,2,2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M216,76H169.8a25.4,25.4,0,0,0,6.3-4.5,28,28,0,1,0-39.6-39.6c-3.6,3.6-6.4,9.2-8.5,16.7-2.1-7.5-4.9-13.1-8.5-16.7A28,28,0,0,0,79.9,71.5,25.4,25.4,0,0,0,86.2,76H40A12,12,0,0,0,28,88v32a12,12,0,0,0,12,12h4v68a12,12,0,0,0,12,12H200a12,12,0,0,0,12-12V132h4a12,12,0,0,0,12-12V88A12,12,0,0,0,216,76ZM142.1,37.6a20.2,20.2,0,0,1,14.2-5.9,20.1,20.1,0,0,1,14.1,34.2c-7.2,7.2-27.3,9.5-38.3,10C132.6,64.9,134.9,44.8,142.1,37.6Zm-56.5,0a20,20,0,0,1,28.3,0c7.2,7.2,9.5,27.3,10,38.3-11-.5-31.1-2.8-38.3-10A20,20,0,0,1,85.6,37.6ZM36,120V88a4,4,0,0,1,4-4h84v40H40A4,4,0,0,1,36,120Zm16,80V132h72v72H56A4,4,0,0,1,52,200Zm152,0a4,4,0,0,1-4,4H132V132h72Zm16-80a4,4,0,0,1-4,4H132V84h84a4,4,0,0,1,4,4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,72H181a32,32,0,0,0-47.3-42.9,29.2,29.2,0,0,0-5.7,8.2,29.2,29.2,0,0,0-5.7-8.2A32,32,0,0,0,75,72H40A16,16,0,0,0,24,88v32a16,16,0,0,0,16,16v64a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V136a16,16,0,0,0,16-16V88A16,16,0,0,0,216,72ZM145,40.4A16,16,0,1,1,167.6,63c-4.9,5-19.2,7.7-31.3,8.7C137.3,59.6,140,45.3,145,40.4Zm-56.6,0a16.1,16.1,0,0,1,22.6,0c5,4.9,7.7,19.2,8.7,31.3-12.1-1-26.4-3.7-31.3-8.7A16.1,16.1,0,0,1,88.4,40.4ZM40,88h80v32H40Zm16,48h64v64H56Zm144,64H136V136h64Zm16-80H136V88h80v32Z" />
        </>
      )}
    </svg>
  );
}

export function PhGitBranch(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M228,68a40,40,0,1,0-52.2,38.1A12,12,0,0,1,164,116H92a34.4,34.4,0,0,0-12,2.1V106.2a40,40,0,1,0-24,0v43.6a40.1,40.1,0,1,0,24.2.1A12,12,0,0,1,92,140h72a36,36,0,0,0,35.9-33.8A40.1,40.1,0,0,0,228,68ZM68,52A16,16,0,1,1,52,68,16,16,0,0,1,68,52Zm0,152a16,16,0,1,1,16-16A16,16,0,0,1,68,204ZM188,84a16,16,0,1,1,16-16A16,16,0,0,1,188,84Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="188" cy="68" r="28" />
          </g>
          <path d="M224,68a36,36,0,1,0-44,35.1v.9a16,16,0,0,1-16,16H92a32.2,32.2,0,0,0-16,4.3V103.1a36,36,0,1,0-16,0v49.8a36,36,0,1,0,16,0V152a16,16,0,0,1,16-16h72a32,32,0,0,0,32-32v-.9A36.1,36.1,0,0,0,224,68ZM48,68A20,20,0,1,1,68,88,20.1,20.1,0,0,1,48,68ZM88,188a20,20,0,1,1-20-20A20.1,20.1,0,0,1,88,188ZM188,88a20,20,0,1,1,20-20A20.1,20.1,0,0,1,188,88Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M224,68a36,36,0,1,0-44,35.1v.9a16,16,0,0,1-16,16H92a32.2,32.2,0,0,0-16,4.3V103.1a36,36,0,1,0-16,0v49.8a36,36,0,1,0,16,0V152a16,16,0,0,1,16-16h72a32,32,0,0,0,32-32v-.9A36.1,36.1,0,0,0,224,68ZM48,68A20,20,0,1,1,68,88,20.1,20.1,0,0,1,48,68ZM88,188a20,20,0,1,1-20-20A20.1,20.1,0,0,1,88,188Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M188,34a34,34,0,0,0-6,67.5V104a18.1,18.1,0,0,1-18,18H92a29.7,29.7,0,0,0-18,6V101.5a34,34,0,1,0-12,0v53a34,34,0,1,0,12,0V152a18.1,18.1,0,0,1,18-18h72a30.1,30.1,0,0,0,30-30v-2.5A34,34,0,0,0,188,34ZM90,188a22,22,0,1,1-22-22A22.1,22.1,0,0,1,90,188ZM68,90A22,22,0,1,1,90,68,22.1,22.1,0,0,1,68,90Zm120,0a22,22,0,1,1,22-22A22.1,22.1,0,0,1,188,90Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M220,68a32,32,0,1,0-36,31.7V104a20.1,20.1,0,0,1-20,20H92a27.9,27.9,0,0,0-20,8.4V99.7a32,32,0,1,0-8,0v56.6a32,32,0,1,0,8,0V152a20.1,20.1,0,0,1,20-20h72a28.1,28.1,0,0,0,28-28V99.7A31.9,31.9,0,0,0,220,68ZM44,68A24,24,0,1,1,68,92,24.1,24.1,0,0,1,44,68ZM92,188a24,24,0,1,1-24-24A24.1,24.1,0,0,1,92,188Zm96-96a24,24,0,1,1,24-24A24.1,24.1,0,0,1,188,92Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M224,68a36,36,0,1,0-44,35.1v.9a16,16,0,0,1-16,16H92a32.2,32.2,0,0,0-16,4.3V103.1a36,36,0,1,0-16,0v49.8a36,36,0,1,0,16,0V152a16,16,0,0,1,16-16h72a32.1,32.1,0,0,0,32-32v-.9A36.1,36.1,0,0,0,224,68ZM48,68A20,20,0,1,1,68,88,20.1,20.1,0,0,1,48,68ZM88,188a20,20,0,1,1-20-20A20.1,20.1,0,0,1,88,188ZM188,88a20,20,0,1,1,20-20A20.1,20.1,0,0,1,188,88Z" />
        </>
      )}
    </svg>
  );
}

export function PhGitCommit(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M244,116H190.9a64,64,0,0,0-125.7,0H12a12,12,0,0,0,0,24H65.2a64,64,0,0,0,125.7,0H244a12,12,0,0,0,0-24ZM128,168a40,40,0,1,1,40-40A40.1,40.1,0,0,1,128,168Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="128" cy="128" r="52" />
          </g>
          <path d="M248,120H187.5a60,60,0,0,0-118.9,0H8a8,8,0,0,0,0,16H68.6a60,60,0,0,0,118.9,0H248a8,8,0,0,0,0-16ZM128,172a44,44,0,1,1,44-44A44.1,44.1,0,0,1,128,172Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M256,128a8,8,0,0,1-8,8H187.5a60,60,0,0,1-118.9,0H8a8,8,0,0,1,0-16H68.6a60,60,0,0,1,118.9,0H248A8,8,0,0,1,256,128Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M248,122H185.7a58,58,0,0,0-115.4,0H8a6,6,0,0,0,0,12H70.3a58,58,0,0,0,115.4,0H248a6,6,0,0,0,0-12ZM128,174a46,46,0,1,1,46-46A46,46,0,0,1,128,174Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M248,124H183.8a55.9,55.9,0,0,0-111.6,0H8a4,4,0,0,0,0,8H72.2a55.9,55.9,0,0,0,111.6,0H248a4,4,0,0,0,0-8ZM128,176a48,48,0,1,1,48-48A48,48,0,0,1,128,176Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M248,120H187.5a60,60,0,0,0-119,0H8a8,8,0,0,0,0,16H68.5a60,60,0,0,0,119,0H248a8,8,0,0,0,0-16ZM128,172a44,44,0,1,1,44-44A44,44,0,0,1,128,172Z" />
        </>
      )}
    </svg>
  );
}

export function PhGitDiff(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M208,149.8V119.9a59.3,59.3,0,0,0-17.6-42.4L173,60h11a12,12,0,0,0,0-24H144a12,12,0,0,0-12,12V88a12,12,0,0,0,24,0V77l17.5,17.4A35.9,35.9,0,0,1,184,119.9v29.9a40,40,0,1,0,24,0ZM196,204a16,16,0,1,1,16-16A16,16,0,0,1,196,204Zm-84-48a12,12,0,0,0-12,12v11L82.5,161.6A35.9,35.9,0,0,1,72,136.1V106.2a40,40,0,1,0-24,0v29.9a59.3,59.3,0,0,0,17.6,42.4L83,196H72a12,12,0,0,0,0,24h40a12,12,0,0,0,12-12V168A12,12,0,0,0,112,156ZM60,52A16,16,0,1,1,44,68,16,16,0,0,1,60,52Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M224,188a28,28,0,1,1-28-28A28,28,0,0,1,224,188ZM60,40A28,28,0,1,0,88,68,28,28,0,0,0,60,40Z"
            opacity="0.2"
          />
          <path d="M204,152.9v-33a55.5,55.5,0,0,0-16.4-39.6L163.3,56H184a8,8,0,0,0,0-16H144a8,8,0,0,0-8,8V88a8,8,0,0,0,16,0V67.3l24.3,24.3A40,40,0,0,1,188,119.9v33a36,36,0,1,0,16,0ZM196,208a20,20,0,1,1,20-20A20.1,20.1,0,0,1,196,208Zm-84-48a8,8,0,0,0-8,8v20.7L79.7,164.4A40,40,0,0,1,68,136.1v-33a36,36,0,1,0-16,0v33a55.5,55.5,0,0,0,16.4,39.6L92.7,200H72a8,8,0,0,0,0,16h40a8,8,0,0,0,8-8V168A8,8,0,0,0,112,160ZM40,68A20,20,0,1,1,60,88,20.1,20.1,0,0,1,40,68Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M232,188a36,36,0,1,1-44-35.1v-33a40,40,0,0,0-11.7-28.3L152,67.3V88a8,8,0,0,1-16,0V48a8,8,0,0,1,8-8h40a8,8,0,0,1,0,16H163.3l24.3,24.3A55.5,55.5,0,0,1,204,119.9v33A36.1,36.1,0,0,1,232,188ZM112,160a8,8,0,0,0-8,8v20.7L79.7,164.4A40,40,0,0,1,68,136.1v-33a36,36,0,1,0-16,0v33a55.5,55.5,0,0,0,16.4,39.6L92.7,200H72a8,8,0,0,0,0,16h40a8,8,0,0,0,8-8V168A8,8,0,0,0,112,160Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M202,154.5V119.9a53.7,53.7,0,0,0-15.8-38.2L158.5,54H184a6,6,0,0,0,0-12H144a6,6,0,0,0-6,6V88a6,6,0,0,0,12,0V62.5l27.7,27.7A41.8,41.8,0,0,1,190,119.9v34.6a34,34,0,1,0,12,0ZM196,210a22,22,0,1,1,22-22A22.1,22.1,0,0,1,196,210Zm-84-48a6,6,0,0,0-6,6v25.5L78.3,165.8A41.8,41.8,0,0,1,66,136.1V101.5a34,34,0,1,0-12,0v34.6a53.7,53.7,0,0,0,15.8,38.2L97.5,202H72a6,6,0,0,0,0,12h40a6,6,0,0,0,6-6V168A6,6,0,0,0,112,162ZM38,68A22,22,0,1,1,60,90,22.1,22.1,0,0,1,38,68Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M200,156.3V119.9a51.7,51.7,0,0,0-15.2-36.8L153.7,52H184a4,4,0,0,0,0-8H144a4,4,0,0,0-4,4V88a4,4,0,0,0,8,0V57.7l31.1,31.1A43.6,43.6,0,0,1,192,119.9v36.4a32,32,0,1,0,8,0ZM196,212a24,24,0,1,1,24-24A24.1,24.1,0,0,1,196,212Zm-84-48a4,4,0,0,0-4,4v30.3L76.9,167.2A43.6,43.6,0,0,1,64,136.1V99.7a32,32,0,1,0-8,0v36.4a51.7,51.7,0,0,0,15.2,36.8L102.3,204H72a4,4,0,0,0,0,8h40a4,4,0,0,0,4-4V168A4,4,0,0,0,112,164ZM36,68A24,24,0,1,1,60,92,24.1,24.1,0,0,1,36,68Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M204,152.9v-33a55.5,55.5,0,0,0-16.4-39.6L163.3,56H184a8,8,0,0,0,0-16H144a8,8,0,0,0-8,8V88a8,8,0,0,0,16,0V67.3l24.3,24.3A40,40,0,0,1,188,119.9v33a36,36,0,1,0,16,0ZM196,208a20,20,0,1,1,20-20A20.1,20.1,0,0,1,196,208Zm-84-48a8,8,0,0,0-8,8v20.7L79.7,164.4A40,40,0,0,1,68,136.1v-33a36,36,0,1,0-16,0v33a55.5,55.5,0,0,0,16.4,39.6L92.7,200H72a8,8,0,0,0,0,16h40a8,8,0,0,0,8-8V168A8,8,0,0,0,112,160ZM40,68A20,20,0,1,1,60,88,20.1,20.1,0,0,1,40,68Z" />
        </>
      )}
    </svg>
  );
}

export function PhGitFork(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M228,68a40,40,0,1,0-52.2,38.1A12,12,0,0,1,164,116H92a11.9,11.9,0,0,1-8.5-3.5,12.4,12.4,0,0,1-3.3-6.4,40,40,0,1,0-24.1.1A35.8,35.8,0,0,0,92,140h24v9.8a40,40,0,1,0,24,0V140h24a36,36,0,0,0,35.9-33.8A40.1,40.1,0,0,0,228,68ZM68,52A16,16,0,1,1,52,68,16,16,0,0,1,68,52Zm60,152a16,16,0,1,1,16-16A16,16,0,0,1,128,204ZM188,84a16,16,0,1,1,16-16A16,16,0,0,1,188,84Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="188" cy="68" r="28" />
          </g>
          <g opacity="0.2">
            <circle cx="68" cy="68" r="28" />
          </g>
          <path d="M224,68a36,36,0,1,0-44,35.1v.9a16,16,0,0,1-16,16H92a16,16,0,0,1-16-16v-.9a36,36,0,1,0-16,0v.9a32.1,32.1,0,0,0,32,32h28v16.9a36,36,0,1,0,16,0V136h28a32,32,0,0,0,32-32v-.9A36.1,36.1,0,0,0,224,68ZM48,68A20,20,0,1,1,68,88,20.1,20.1,0,0,1,48,68ZM148,188a20,20,0,1,1-20-20A20.1,20.1,0,0,1,148,188ZM188,88a20,20,0,1,1,20-20A20.1,20.1,0,0,1,188,88Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M224,68a36,36,0,1,0-44,35.1v.9a16,16,0,0,1-16,16H92a16,16,0,0,1-16-16v-.9a36,36,0,1,0-16,0v.9a32.1,32.1,0,0,0,32,32h28v16.9a36,36,0,1,0,16,0V136h28a32,32,0,0,0,32-32v-.9A36.1,36.1,0,0,0,224,68ZM148,188a20,20,0,1,1-20-20A20.1,20.1,0,0,1,148,188Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M222,68a34,34,0,1,0-40,33.5V104a18.1,18.1,0,0,1-18,18H92a18.1,18.1,0,0,1-18-18v-2.5a34,34,0,1,0-12,0V104a30,30,0,0,0,30,30h30v20.5a34,34,0,1,0,12,0V134h30a30.1,30.1,0,0,0,30-30v-2.5A34.1,34.1,0,0,0,222,68ZM46,68A22,22,0,1,1,68,90,22.1,22.1,0,0,1,46,68ZM150,188a22,22,0,1,1-22-22A22.1,22.1,0,0,1,150,188Zm38-98a22,22,0,1,1,22-22A22.1,22.1,0,0,1,188,90Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M220,68a32,32,0,1,0-36,31.7V104a20.1,20.1,0,0,1-20,20H92a20.1,20.1,0,0,1-20-20V99.7a32,32,0,1,0-8,0V104a28,28,0,0,0,28,28h32v24.3a32,32,0,1,0,8,0V132h32a28.1,28.1,0,0,0,28-28V99.7A31.9,31.9,0,0,0,220,68ZM44,68A24,24,0,1,1,68,92,24.1,24.1,0,0,1,44,68ZM152,188a24,24,0,1,1-24-24A24.1,24.1,0,0,1,152,188Zm36-96a24,24,0,1,1,24-24A24.1,24.1,0,0,1,188,92Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M224,68a36,36,0,1,0-44,35.1v.9a16,16,0,0,1-16,16H92a16,16,0,0,1-16-16v-.9a36,36,0,1,0-16,0v.9a32.1,32.1,0,0,0,32,32h28v16.9a36,36,0,1,0,16,0V136h28a32.1,32.1,0,0,0,32-32v-.9A36.1,36.1,0,0,0,224,68ZM48,68A20,20,0,1,1,68,88,20.1,20.1,0,0,1,48,68ZM148,188a20,20,0,1,1-20-20A20.1,20.1,0,0,1,148,188ZM188,88a20,20,0,1,1,20-20A20.1,20.1,0,0,1,188,88Z" />
        </>
      )}
    </svg>
  );
}

export function PhGitMerge(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M188,100a40.1,40.1,0,0,0-39.2,32H130.5a35.9,35.9,0,0,1-27.7-13l-14-16.8a40,40,0,1,0-32.8,4v43.6a40,40,0,1,0,24,0V129.1l4.4,5.3A59.8,59.8,0,0,0,130.5,156h20.9A40,40,0,1,0,188,100ZM68,52A16,16,0,1,1,52,68,16,16,0,0,1,68,52Zm0,152a16,16,0,1,1,16-16A16,16,0,0,1,68,204Zm120-48a16,16,0,1,1,16-16A16,16,0,0,1,188,156Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="68" cy="68" r="28" />
          </g>
          <path d="M188,104a36.1,36.1,0,0,0-35.8,32H130.5a39.6,39.6,0,0,1-30.7-14.4L82.5,100.9A35.9,35.9,0,1,0,60,103.1v49.8a36,36,0,1,0,16,0V118.1l11.5,13.7a55.4,55.4,0,0,0,43,20.2h23.6A36,36,0,1,0,188,104ZM48,68A20,20,0,1,1,68,88,20.1,20.1,0,0,1,48,68ZM88,188a20,20,0,1,1-20-20A20.1,20.1,0,0,1,88,188Zm100-28a20,20,0,1,1,20-20A20.1,20.1,0,0,1,188,160Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M188,104a36.1,36.1,0,0,0-35.8,32H130.5a39.6,39.6,0,0,1-30.7-14.4L82.5,100.9A35.9,35.9,0,1,0,60,103.1v49.8a36,36,0,1,0,16,0V118.1l11.5,13.7a55.4,55.4,0,0,0,43,20.2h23.6A36,36,0,1,0,188,104ZM88,188a20,20,0,1,1-20-20A20.1,20.1,0,0,1,88,188Zm100-28a20,20,0,1,1,20-20A20.1,20.1,0,0,1,188,160Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M188,106a34,34,0,0,0-33.9,32H130.5a42,42,0,0,1-32.3-15.1l-19-22.8A33.9,33.9,0,1,0,62,101.5v53a34,34,0,1,0,12,0V112.6l15,18A54,54,0,0,0,130.5,150h25A34,34,0,1,0,188,106ZM46,68A22,22,0,1,1,68,90,22.1,22.1,0,0,1,46,68ZM90,188a22,22,0,1,1-22-22A22.1,22.1,0,0,1,90,188Zm98-26a22,22,0,1,1,22-22A22.1,22.1,0,0,1,188,162Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M188,108a32.1,32.1,0,0,0-32,32H130.5a44.1,44.1,0,0,1-33.8-15.8L75.7,99a32,32,0,1,0-11.7.7v56.6a32,32,0,1,0,8,0V107l18.5,22.3a51.9,51.9,0,0,0,40,18.7H157a32,32,0,1,0,31-40ZM44,68A24,24,0,1,1,68,92,24.1,24.1,0,0,1,44,68ZM92,188a24,24,0,1,1-24-24A24.1,24.1,0,0,1,92,188Zm96-24a24,24,0,1,1,24-24A24.1,24.1,0,0,1,188,164Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M188,104a36.1,36.1,0,0,0-35.8,32H130.5a39.6,39.6,0,0,1-30.7-14.4L82.5,100.9A35.9,35.9,0,1,0,60,103.1v49.8a36,36,0,1,0,16,0V118.1l11.5,13.7a55.4,55.4,0,0,0,43,20.2h23.6A36,36,0,1,0,188,104ZM48,68A20,20,0,1,1,68,88,20.1,20.1,0,0,1,48,68ZM88,188a20,20,0,1,1-20-20A20.1,20.1,0,0,1,88,188Zm100-28a20,20,0,1,1,20-20A20.1,20.1,0,0,1,188,160Z" />
        </>
      )}
    </svg>
  );
}

export function PhGitPullRequest(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M108,68a40,40,0,1,0-52,38.2v43.6a40,40,0,1,0,24,0V106.2A40.2,40.2,0,0,0,108,68ZM68,52A16,16,0,1,1,52,68,16,16,0,0,1,68,52Zm0,152a16,16,0,1,1,16-16A16,16,0,0,1,68,204Zm132-54.2V119.9a59.3,59.3,0,0,0-17.6-42.4L165,60h11a12,12,0,0,0,0-24H136a12,12,0,0,0-12,12V88a12,12,0,0,0,24,0V77l17.5,17.4A35.9,35.9,0,0,1,176,119.9v29.9a40,40,0,1,0,24,0ZM188,204a16,16,0,1,1,16-16A16,16,0,0,1,188,204Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M96,68A28,28,0,1,1,68,40,28,28,0,0,1,96,68Zm92,92a28,28,0,1,0,28,28A28,28,0,0,0,188,160Z"
            opacity="0.2"
          />
          <path d="M104,68a36,36,0,1,0-44,35.1v49.8a36,36,0,1,0,16,0V103.1A36.1,36.1,0,0,0,104,68ZM48,68A20,20,0,1,1,68,88,20.1,20.1,0,0,1,48,68ZM88,188a20,20,0,1,1-20-20A20.1,20.1,0,0,1,88,188Zm108-35.1v-33a55.5,55.5,0,0,0-16.4-39.6L155.3,56H176a8,8,0,0,0,0-16H136a8,8,0,0,0-8,8V88a8,8,0,0,0,16,0V67.3l24.3,24.3A40,40,0,0,1,180,119.9v33a36,36,0,1,0,16,0ZM188,208a20,20,0,1,1,20-20A20.1,20.1,0,0,1,188,208Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M104,68a36,36,0,1,0-44,35.1v49.8a36,36,0,1,0,16,0V103.1A36.1,36.1,0,0,0,104,68ZM88,188a20,20,0,1,1-20-20A20.1,20.1,0,0,1,88,188Zm136,0a36,36,0,1,1-44-35.1v-33a40,40,0,0,0-11.7-28.3L144,67.3V88a8,8,0,0,1-16,0V48a8,8,0,0,1,8-8h40a8,8,0,0,1,0,16H155.3l24.3,24.3A55.5,55.5,0,0,1,196,119.9v33A36.1,36.1,0,0,1,224,188Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M102,68a34,34,0,1,0-40,33.5v53a34,34,0,1,0,12,0v-53A34.1,34.1,0,0,0,102,68ZM46,68A22,22,0,1,1,68,90,22.1,22.1,0,0,1,46,68ZM90,188a22,22,0,1,1-22-22A22.1,22.1,0,0,1,90,188Zm104-33.5V119.9a53.7,53.7,0,0,0-15.8-38.2L150.5,54H176a6,6,0,0,0,0-12H136a6,6,0,0,0-6,6V88a6,6,0,0,0,12,0V62.5l27.7,27.7A41.8,41.8,0,0,1,182,119.9v34.6a34,34,0,1,0,12,0ZM188,210a22,22,0,1,1,22-22A22.1,22.1,0,0,1,188,210Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M100,68A32,32,0,1,0,64,99.7v56.6a32,32,0,1,0,8,0V99.7A31.9,31.9,0,0,0,100,68ZM44,68A24,24,0,1,1,68,92,24.1,24.1,0,0,1,44,68ZM92,188a24,24,0,1,1-24-24A24.1,24.1,0,0,1,92,188Zm100-31.7V119.9a51.7,51.7,0,0,0-15.2-36.8L145.7,52H176a4,4,0,0,0,0-8H136a4,4,0,0,0-4,4V88a4,4,0,0,0,8,0V57.7l31.1,31.1A43.6,43.6,0,0,1,184,119.9v36.4a32,32,0,1,0,8,0ZM188,212a24,24,0,1,1,24-24A24.1,24.1,0,0,1,188,212Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M104,68a36,36,0,1,0-44,35.1v49.8a36,36,0,1,0,16,0V103.1A36.1,36.1,0,0,0,104,68ZM48,68A20,20,0,1,1,68,88,20.1,20.1,0,0,1,48,68ZM88,188a20,20,0,1,1-20-20A20.1,20.1,0,0,1,88,188Zm108-35.1v-33a55.5,55.5,0,0,0-16.4-39.6L155.3,56H176a8,8,0,0,0,0-16H136a8,8,0,0,0-8,8V88a8,8,0,0,0,16,0V67.3l24.3,24.3A40,40,0,0,1,180,119.9v33a36,36,0,1,0,16,0ZM188,208a20,20,0,1,1,20-20A20.1,20.1,0,0,1,188,208Z" />
        </>
      )}
    </svg>
  );
}

export function PhGithubLogo(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216,212a12,12,0,0,1-12-12v-8a36.1,36.1,0,0,0-12.3-27.1A59.9,59.9,0,0,0,212,120v-8a61.1,61.1,0,0,0-7.4-28.8A63.7,63.7,0,0,0,198.4,34,12.1,12.1,0,0,0,188,28a64,64,0,0,0-50,24H118A64,64,0,0,0,68,28a12.1,12.1,0,0,0-10.4,6,63.7,63.7,0,0,0-6.2,49.2A61.1,61.1,0,0,0,44,112v8a59.9,59.9,0,0,0,20.3,44.9A36.1,36.1,0,0,0,52,192v8a12,12,0,0,1-12,12,12,12,0,0,0,0,24,36,36,0,0,0,36-36v-8a12,12,0,0,1,12-12h8v36a12,12,0,0,1-12,12,12,12,0,0,0,0,24,36,36,0,0,0,36-36V180h16v36a36,36,0,0,0,36,36,12,12,0,0,0,0-24,12,12,0,0,1-12-12V180h8a12,12,0,0,1,12,12v8a36,36,0,0,0,36,36,12,12,0,0,0,0-24ZM68,112a38.3,38.3,0,0,1,6.1-20.1,11.6,11.6,0,0,0,1.6-11.5,39.4,39.4,0,0,1-.4-27.7,39.6,39.6,0,0,1,26.4,17.8A12.1,12.1,0,0,0,111.8,76h32.4a12.1,12.1,0,0,0,10.1-5.5,39.6,39.6,0,0,1,26.4-17.8,39.4,39.4,0,0,1-.4,27.7,11.9,11.9,0,0,0,1.6,11.5A37.5,37.5,0,0,1,188,112v8a36,36,0,0,1-36,36H104a36,36,0,0,1-36-36Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M200,112v8a48,48,0,0,1-48,48H104a48,48,0,0,1-48-48v-8a49.3,49.3,0,0,1,8.5-27.3A52,52,0,0,1,68,40a52,52,0,0,1,43.8,24h32.4A52,52,0,0,1,188,40a52,52,0,0,1,3.5,44.7A49.3,49.3,0,0,1,200,112Z"
            opacity="0.2"
          />
          <path d="M216,216a16,16,0,0,1-16-16v-8a32.1,32.1,0,0,0-14.8-27A55.8,55.8,0,0,0,208,120v-8a58,58,0,0,0-7.7-28.3A59.9,59.9,0,0,0,194.9,36a7.8,7.8,0,0,0-6.9-4,59.7,59.7,0,0,0-48,24H116A59.7,59.7,0,0,0,68,32a7.8,7.8,0,0,0-6.9,4,59.9,59.9,0,0,0-5.4,47.7A58,58,0,0,0,48,112v8a55.8,55.8,0,0,0,22.8,45A32.1,32.1,0,0,0,56,192v8a16,16,0,0,1-16,16,8,8,0,0,0,0,16,32.1,32.1,0,0,0,32-32v-8a16,16,0,0,1,16-16h12v40a16,16,0,0,1-16,16,8,8,0,0,0,0,16,32.1,32.1,0,0,0,32-32V176h24v40a32.1,32.1,0,0,0,32,32,8,8,0,0,0,0-16,16,16,0,0,1-16-16V176h12a16,16,0,0,1,16,16v8a32.1,32.1,0,0,0,32,32,8,8,0,0,0,0-16ZM64,120v-8a42.7,42.7,0,0,1,6.9-22.5A7.9,7.9,0,0,0,72,81.8a43.7,43.7,0,0,1,.8-33.5,43.6,43.6,0,0,1,32.3,20,8,8,0,0,0,6.7,3.7h32.4a8,8,0,0,0,6.7-3.7,43.6,43.6,0,0,1,32.3-20,43.7,43.7,0,0,1,.8,33.5,8.2,8.2,0,0,0,1.1,7.7A42.7,42.7,0,0,1,192,112v8a40,40,0,0,1-40,40H104A40,40,0,0,1,64,120Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M224,224a8,8,0,0,1-8,8,32.1,32.1,0,0,1-32-32v-8a16,16,0,0,0-16-16H156v40a16,16,0,0,0,16,16,8,8,0,0,1,0,16,32.1,32.1,0,0,1-32-32V176H116v40a32.1,32.1,0,0,1-32,32,8,8,0,0,1,0-16,16,16,0,0,0,16-16V176H88a16,16,0,0,0-16,16v8a32.1,32.1,0,0,1-32,32,8,8,0,0,1,0-16,16,16,0,0,0,16-16v-8a32.1,32.1,0,0,1,14.8-27A55.8,55.8,0,0,1,48,120v-8a58,58,0,0,1,7.7-28.3A59.9,59.9,0,0,1,61.1,36,7.8,7.8,0,0,1,68,32a59.7,59.7,0,0,1,48,24h24a59.7,59.7,0,0,1,48-24,7.8,7.8,0,0,1,6.9,4,59.9,59.9,0,0,1,5.4,47.7A58,58,0,0,1,208,112v8a55.8,55.8,0,0,1-22.8,45A32.1,32.1,0,0,1,200,192v8a16,16,0,0,0,16,16A8,8,0,0,1,224,224Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M216,218a18.1,18.1,0,0,1-18-18v-8a30.1,30.1,0,0,0-16.5-26.8A54,54,0,0,0,206,120v-8a55.1,55.1,0,0,0-7.9-28.1A57.6,57.6,0,0,0,193.2,37a6,6,0,0,0-5.2-3,57.7,57.7,0,0,0-47,24H115A57.7,57.7,0,0,0,68,34a6,6,0,0,0-5.2,3,57.6,57.6,0,0,0-4.9,46.9A55.1,55.1,0,0,0,50,112v8a54,54,0,0,0,24.5,45.2A30.1,30.1,0,0,0,58,192v8a18.1,18.1,0,0,1-18,18,6,6,0,0,0,0,12,30.1,30.1,0,0,0,30-30v-8a18.1,18.1,0,0,1,18-18h14v42a18.1,18.1,0,0,1-18,18,6,6,0,0,0,0,12,30.1,30.1,0,0,0,30-30V174h28v42a30.1,30.1,0,0,0,30,30,6,6,0,0,0,0-12,18.1,18.1,0,0,1-18-18V174h14a18.1,18.1,0,0,1,18,18v8a30.1,30.1,0,0,0,30,30,6,6,0,0,0,0-12ZM62,120v-8a43.4,43.4,0,0,1,7.3-23.7,6,6,0,0,0,.8-5.8,46.1,46.1,0,0,1,1.4-36.4,46,46,0,0,1,35.3,21.1,5.8,5.8,0,0,0,5,2.8h32.4a5.8,5.8,0,0,0,5-2.8,46,46,0,0,1,35.3-21.1,46.1,46.1,0,0,1,1.4,36.4,6.3,6.3,0,0,0,.8,5.8A43.4,43.4,0,0,1,194,112v8a42,42,0,0,1-42,42H104A42,42,0,0,1,62,120Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M216,220a20.1,20.1,0,0,1-20-20v-8a28,28,0,0,0-18.9-26.5A51.9,51.9,0,0,0,204,120v-8a52.7,52.7,0,0,0-8.1-27.8A55.9,55.9,0,0,0,191.5,38a4.3,4.3,0,0,0-3.5-2,55.7,55.7,0,0,0-46,24H114A55.7,55.7,0,0,0,68,36a4.3,4.3,0,0,0-3.5,2,55.9,55.9,0,0,0-4.4,46.2A52.7,52.7,0,0,0,52,112v8a51.9,51.9,0,0,0,26.9,45.5A28,28,0,0,0,60,192v8a20.1,20.1,0,0,1-20,20,4,4,0,0,0,0,8,28.1,28.1,0,0,0,28-28v-8a20.1,20.1,0,0,1,20-20h16v44a20.1,20.1,0,0,1-20,20,4,4,0,0,0,0,8,28.1,28.1,0,0,0,28-28V172h32v44a28.1,28.1,0,0,0,28,28,4,4,0,0,0,0-8,20.1,20.1,0,0,1-20-20V172h16a20.1,20.1,0,0,1,20,20v8a28.1,28.1,0,0,0,28,28,4,4,0,0,0,0-8ZM60,120v-8a45.4,45.4,0,0,1,7.7-24.9,4,4,0,0,0,.5-3.8,48.3,48.3,0,0,1,2.1-39.2,47.6,47.6,0,0,1,38.2,22.1,4,4,0,0,0,3.3,1.8h32.4a4,4,0,0,0,3.3-1.8,47.6,47.6,0,0,1,38.2-22.1,48.3,48.3,0,0,1,2.1,39.2,4,4,0,0,0,.5,3.8A45.4,45.4,0,0,1,196,112v8a44,44,0,0,1-44,44H104A44,44,0,0,1,60,120Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,216a16,16,0,0,1-16-16v-8a32.1,32.1,0,0,0-14.8-27A55.8,55.8,0,0,0,208,120v-8a58,58,0,0,0-7.7-28.3A59.9,59.9,0,0,0,194.9,36a7.8,7.8,0,0,0-6.9-4,59.7,59.7,0,0,0-48,24H116A59.7,59.7,0,0,0,68,32a7.8,7.8,0,0,0-6.9,4,59.9,59.9,0,0,0-5.4,47.7A58,58,0,0,0,48,112v8a55.8,55.8,0,0,0,22.8,45A32.1,32.1,0,0,0,56,192v8a16,16,0,0,1-16,16,8,8,0,0,0,0,16,32.1,32.1,0,0,0,32-32v-8a16,16,0,0,1,16-16h12v40a16,16,0,0,1-16,16,8,8,0,0,0,0,16,32.1,32.1,0,0,0,32-32V176h24v40a32.1,32.1,0,0,0,32,32,8,8,0,0,0,0-16,16,16,0,0,1-16-16V176h12a16,16,0,0,1,16,16v8a32.1,32.1,0,0,0,32,32,8,8,0,0,0,0-16ZM64,120v-8a42.7,42.7,0,0,1,6.9-22.5A7.9,7.9,0,0,0,72,81.8a43.7,43.7,0,0,1,.8-33.5,43.6,43.6,0,0,1,32.3,20,8,8,0,0,0,6.7,3.7h32.4a8,8,0,0,0,6.7-3.7,43.6,43.6,0,0,1,32.3-20,43.7,43.7,0,0,1,.8,33.5,8.2,8.2,0,0,0,1.1,7.7A42.7,42.7,0,0,1,192,112v8a40,40,0,0,1-40,40H104A40,40,0,0,1,64,120Z" />
        </>
      )}
    </svg>
  );
}

export function PhGitlabLogoSimple(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M242.1,151.5,214.8,49a16,16,0,0,0-30.4-1.5L164.7,100H91.3L71.6,47.5A16,16,0,0,0,41.2,49L13.9,151.5a20,20,0,0,0,7.9,21.6l94.8,65.6a20.3,20.3,0,0,0,22.8,0l94.8-65.6A20,20,0,0,0,242.1,151.5ZM128,217.4,37.8,154.9,57.9,79.3l13.9,36.9A11.9,11.9,0,0,0,83,124h90a11.9,11.9,0,0,0,11.2-7.8l13.9-36.9,20.1,75.6Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M227.4,163.2l-94.8,65.6a8.1,8.1,0,0,1-9.2,0L28.6,163.2a8.2,8.2,0,0,1-3.2-8.6L52.8,52.1a4,4,0,0,1,7.6-.4L83,112h90l22.6-60.3a4,4,0,0,1,7.6.4l27.4,102.5A8.2,8.2,0,0,1,227.4,163.2Z"
            opacity="0.2"
          />
          <path d="M238.3,152.5,211,50a12,12,0,0,0-22.9-1.1L167.5,104h-79L67.9,48.9A12,12,0,0,0,45,50L17.7,152.5a16.1,16.1,0,0,0,6.4,17.3l94.8,65.6a15.7,15.7,0,0,0,18.2,0l94.8-65.6A16.1,16.1,0,0,0,238.3,152.5ZM128,222.3,33.2,156.6,57.3,66.2l18.2,48.6A8.1,8.1,0,0,0,83,120h90a8.1,8.1,0,0,0,7.5-5.2l18.2-48.6,24.1,90.4Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M231.9,169.8l-94.8,65.6a15.7,15.7,0,0,1-18.2,0L24.1,169.8a16.1,16.1,0,0,1-6.4-17.3L45,50a12,12,0,0,1,22.9-1.1L88.5,104h79l20.6-55.1A12,12,0,0,1,211,50l27.3,102.5A16.1,16.1,0,0,1,231.9,169.8Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M236.3,153,209,50.5a10,10,0,0,0-19-.9L168.8,106H87.2L66,49.6a10,10,0,0,0-19,.9L19.7,153a13.9,13.9,0,0,0,5.5,15.1L120,233.8a14.1,14.1,0,0,0,16,0l94.8-65.7A13.9,13.9,0,0,0,236.3,153ZM224,158.3l-94.9,65.6a1.8,1.8,0,0,1-2.2,0L32,158.3a2.1,2.1,0,0,1-.8-2.2L57,59.7l20.4,54.4A6.1,6.1,0,0,0,83,118h90a6.1,6.1,0,0,0,5.6-3.9L199,59.7l25.8,96.4A2.1,2.1,0,0,1,224,158.3Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M234.4,153.5,207.1,51a7.9,7.9,0,0,0-7.4-5.9,7.8,7.8,0,0,0-7.8,5.2L170.2,108H85.8L64.1,50.3a7.8,7.8,0,0,0-7.8-5.2A7.9,7.9,0,0,0,48.9,51L21.6,153.5a12.1,12.1,0,0,0,4.7,13l94.9,65.6a11.6,11.6,0,0,0,13.6,0l94.9-65.6A12.1,12.1,0,0,0,234.4,153.5Zm-9.3,6.4-94.8,65.7a4.3,4.3,0,0,1-4.6,0L30.9,159.9a4,4,0,0,1-1.6-4.3L56.6,53.1l22.7,60.3A3.8,3.8,0,0,0,83,116h90a3.8,3.8,0,0,0,3.7-2.6l22.7-60.3,27.3,102.5A4,4,0,0,1,225.1,159.9Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M238.3,152.5,211,50a12,12,0,0,0-22.9-1.1L167.5,104h-79L67.9,48.9A12,12,0,0,0,45,50L17.7,152.5a16.1,16.1,0,0,0,6.4,17.3l94.8,65.6a15.7,15.7,0,0,0,18.2,0l94.8-65.6A16.1,16.1,0,0,0,238.3,152.5ZM128,222.3,33.2,156.6,57.3,66.2l18.2,48.6A8.1,8.1,0,0,0,83,120h90a8.1,8.1,0,0,0,7.5-5.2l18.2-48.6,24.1,90.4Z" />
        </>
      )}
    </svg>
  );
}

export function PhGitlabLogo(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M242.1,151.5,214.8,49a16,16,0,0,0-30.4-1.5L164.7,100H91.3L71.6,47.5A16,16,0,0,0,41.2,49L13.9,151.5a20,20,0,0,0,7.9,21.6l94.8,65.6a21.1,21.1,0,0,0,8.7,3.4h5.4a21.1,21.1,0,0,0,8.7-3.4l94.8-65.6A20,20,0,0,0,242.1,151.5ZM155.7,124,128,197.8,100.3,124ZM57.9,79.3l13.9,36.9h0l31.5,84.1L37.8,154.9Zm94.8,121,31.5-84.1h0l13.9-36.9,20.1,75.6Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M83,112h90L128.7,230.2h0a3.3,3.3,0,0,1-1.4,0h0ZM203.2,52.1a4,4,0,0,0-7.6-.4L173,112h46.2Zm-150.4,0L36.8,112H83L60.4,51.7A4,4,0,0,0,52.8,52.1Z"
            opacity="0.2"
          />
          <path d="M238.3,152.5,211,50a12,12,0,0,0-22.9-1.1L167.5,104h-79L67.9,48.9A12,12,0,0,0,45,50L17.7,152.5a16.1,16.1,0,0,0,6.4,17.3l94.8,65.6a15.5,15.5,0,0,0,7.1,2.7h4a15.5,15.5,0,0,0,7.1-2.7l94.8-65.6A16.1,16.1,0,0,0,238.3,152.5ZM198.7,66.2,208.8,104H184.5ZM161.5,120,128,209.2,94.5,120ZM57.3,66.2,71.5,104H47.2ZM42.9,120H77.5l34,90.9L33.2,156.6Zm101.6,90.9,34-90.9h34.6l9.7,36.6Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M238.3,152.5,211,50a12,12,0,0,0-22.9-1.1L167.5,104h-79L67.9,48.9A12,12,0,0,0,45,50L17.7,152.5a16.1,16.1,0,0,0,6.4,17.3l94.8,65.6a15.5,15.5,0,0,0,7.1,2.7h4a15.5,15.5,0,0,0,7.1-2.7l94.8-65.6A16.1,16.1,0,0,0,238.3,152.5ZM33.2,156.6,42.9,120H77.5l34,90.9Zm111.3,54.3,34-90.9h34.6l9.7,36.6Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M236.3,153,209,50.5a10,10,0,0,0-19-.9L168.8,106H87.2L66,49.6a10,10,0,0,0-19,.9L19.7,153a13.9,13.9,0,0,0,5.5,15.1L120,233.8a13.4,13.4,0,0,0,6.3,2.3h3.4a13.4,13.4,0,0,0,6.3-2.3l94.8-65.7A13.9,13.9,0,0,0,236.3,153ZM199,59.7,211.4,106H181.7ZM164.3,118,128,214.9,91.7,118ZM57,59.7,74.3,106H44.6ZM31.2,156.1,41.4,118H78.8l36.8,98.2L32,158.3A2.1,2.1,0,0,1,31.2,156.1ZM224,158.3l-83.6,57.9L177.2,118h37.4l10.2,38.1A2.1,2.1,0,0,1,224,158.3Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M234.4,153.5,207.1,51a7.9,7.9,0,0,0-7.4-5.9,7.8,7.8,0,0,0-7.8,5.2L170.2,108H85.8L64.1,50.3a8,8,0,0,0-15.2.7L21.6,153.5a12.1,12.1,0,0,0,4.7,13l94.9,65.6a10.9,10.9,0,0,0,5.4,2.1h2.8a10.9,10.9,0,0,0,5.4-2.1l94.9-65.6A12.1,12.1,0,0,0,234.4,153.5Zm-35-100.4L214,108H178.8ZM128,220.6,88.8,116h78.4ZM56.6,53.1,77.2,108H42ZM29.3,155.6,39.9,116H80.2l39.6,105.4L30.9,159.9A4,4,0,0,1,29.3,155.6Zm195.8,4.3-88.9,61.5L175.8,116h40.3l10.6,39.6A4,4,0,0,1,225.1,159.9Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M238.3,152.5,211,50a12,12,0,0,0-22.9-1.1L167.5,104h-79L67.9,48.9A12,12,0,0,0,45,50L17.7,152.5a16.1,16.1,0,0,0,6.4,17.3l94.8,65.6a15.5,15.5,0,0,0,7.1,2.7h4a15.5,15.5,0,0,0,7.1-2.7l94.8-65.6A16.1,16.1,0,0,0,238.3,152.5ZM198.7,66.2,208.8,104H184.5ZM161.5,120,128,209.2,94.5,120ZM57.3,66.2,71.5,104H47.2ZM42.9,120H77.5l34,90.9L33.2,156.6Zm101.6,90.9,34-90.9h34.6l9.7,36.6Z" />
        </>
      )}
    </svg>
  );
}

export function PhGlobeHemisphereEast(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M227.1,170.9a10.1,10.1,0,0,0,.7-1.5A108.1,108.1,0,0,0,190.1,39.7l-1.3-.9A107.9,107.9,0,0,0,39.2,189.4v.2l1.1,1.5a107.8,107.8,0,0,0,153.1,22.7,7.5,7.5,0,0,0,1.7-1.4A107.8,107.8,0,0,0,227.1,170.9Zm-42.3,13.6a19.1,19.1,0,0,0-9.1-5.3l-17.9-4.7,1.5-10.4,26-10.8,16.4,15a84.3,84.3,0,0,1-14.2,18.9ZM128,44a82.9,82.9,0,0,1,43,11.9l.8,16.5L151.7,95.6l-27.9,3.8L109.7,87.6A19.9,19.9,0,0,0,80,92.1L60.3,123a20.2,20.2,0,0,0-3.1,8.8l-3.4,35.1h-.2A83.3,83.3,0,0,1,44,128,84.1,84.1,0,0,1,128,44ZM67.8,186.4h.1a20.3,20.3,0,0,0,9.5-15.2L81,135.1l16.8-26.2,11.9,10a19.9,19.9,0,0,0,15.5,4.5l31.2-4.2a20.1,20.1,0,0,0,12.4-6.7L191,86.9a20.5,20.5,0,0,0,4.4-9A83.9,83.9,0,0,1,212,128a82.2,82.2,0,0,1-1.5,15.8l-10.9-9.9a19.9,19.9,0,0,0-21.2-3.7L148,142.8a20.1,20.1,0,0,0-12.1,15.6l-2.4,16.2a19.9,19.9,0,0,0,14.7,22.2l19.6,5.2a83.8,83.8,0,0,1-100-15.6Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M216.4,165.5ZM183,141.2l-30.4,12.7a8.2,8.2,0,0,0-4.9,6.2l-2.3,16.2a8,8,0,0,0,5.8,8.9l21.5,5.6a8.2,8.2,0,0,1,3.6,2.1l10.8,10.8.2-.2a96.7,96.7,0,0,0,29.1-38l-24.9-22.8A8,8,0,0,0,183,141.2Zm-23.3-36.6L181.9,79a8.1,8.1,0,0,0,1.9-5.6l-1.1-24.3h0A96,96,0,0,0,49.6,183.4h0l11.7-7.1a8,8,0,0,0,3.8-6.8l.2-36.1a7.7,7.7,0,0,1,1.3-4.2L86.4,98.1a8.1,8.1,0,0,1,11.5-2.2l19.6,14.2a8.6,8.6,0,0,0,5.8,1.5l31.5-4.3A7.8,7.8,0,0,0,159.7,104.6Z"
            opacity="0.2"
          />
          <path d="M223.5,169.2a6.1,6.1,0,0,0,.5-1.2A104,104,0,0,0,187.8,42.9l-1.2-.7A103,103,0,0,0,128,24,104,104,0,0,0,42.6,187.3v.2l.8,1.1a104,104,0,0,0,147.8,21.9l1.4-1.1A105.3,105.3,0,0,0,223.5,169.2Zm-36,23.6-5.5-5.5a15.8,15.8,0,0,0-7.3-4.2l-21.4-5.6,2.4-16.2,30.4-12.7,20.6,18.8A90.6,90.6,0,0,1,187.5,192.8ZM128,40a87.5,87.5,0,0,1,46.9,13.6l1,20.2L153.7,99.4l-31.5,4.2L102.6,89.4a16.2,16.2,0,0,0-12.4-2.8,16.3,16.3,0,0,0-10.5,7.2l-19.9,31a16.3,16.3,0,0,0-2.5,8.6l-.2,36.1-5,3A87.2,87.2,0,0,1,40,128,88.1,88.1,0,0,1,128,40ZM61.5,185.6l3.9-2.4a16.2,16.2,0,0,0,7.7-13.6l.2-36.1,19.9-31.1,19.6,14.2a16.5,16.5,0,0,0,11.6,2.9l31.4-4.3a15.8,15.8,0,0,0,10-5.4L188,84.2A15.8,15.8,0,0,0,191.8,73l-.2-5.8A87.7,87.7,0,0,1,216,128a86.4,86.4,0,0,1-3.2,23.4l-15.9-14.6a16.1,16.1,0,0,0-16.9-3l-30.5,12.7a16.1,16.1,0,0,0-9.7,12.4l-2.4,16.2A16.2,16.2,0,0,0,149.2,193l21.5,5.6,4,4a88,88,0,0,1-113.2-17Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M187.8,42.9l-1.2-.7A103,103,0,0,0,128,24,104,104,0,0,0,42.6,187.3v.2l.8,1.1a104,104,0,0,0,147.8,21.9l1.4-1.1a105.3,105.3,0,0,0,30.8-40.2,6.1,6.1,0,0,0,.5-1.2A104,104,0,0,0,187.8,42.9Zm25,108.5-15.9-14.6a16.1,16.1,0,0,0-16.9-3l-30.5,12.7a16.1,16.1,0,0,0-9.7,12.4l-2.4,16.2A16.2,16.2,0,0,0,149.2,193l21.5,5.6,4,4a88,88,0,0,1-113.2-17l3.9-2.4a15.8,15.8,0,0,0,7.7-13.6l.2-33.8a8.3,8.3,0,0,1,1.3-4.3l14-22a8,8,0,0,1,11.4-2.2l12.8,9.3a16.5,16.5,0,0,0,11.6,2.9l31.4-4.3a15.8,15.8,0,0,0,10-5.4L188,84.2A15.8,15.8,0,0,0,191.8,73l-.2-5.8A87.7,87.7,0,0,1,216,128,86.4,86.4,0,0,1,212.8,151.4Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M221.9,167.9c0-.2.1-.3.2-.5A102,102,0,0,0,186.7,44.6l-1.2-.8A102,102,0,0,0,44.3,186.3c.1,0,.1.1.1.2l.7.9a101.9,101.9,0,0,0,145.1,21.4,4.8,4.8,0,0,0,1.1-.9h0A103.2,103.2,0,0,0,221.9,167.9Zm-34.5,27.6-6.8-6.8a14.8,14.8,0,0,0-6.4-3.7l-21.4-5.6a2,2,0,0,1-1.5-2.2l2.4-16.2a2,2,0,0,1,1.2-1.6l30.4-12.6a2.2,2.2,0,0,1,2.2.3L209.1,167A89.3,89.3,0,0,1,187.4,195.5ZM128,38a89.3,89.3,0,0,1,48.8,14.4l1.1,21.3a2.4,2.4,0,0,1-.5,1.4l-22.2,25.6a2.1,2.1,0,0,1-1.2.7l-31.5,4.2a2.1,2.1,0,0,1-1.5-.3L101.4,91a14.3,14.3,0,0,0-10.8-2.4,13.9,13.9,0,0,0-9.2,6.2L61.5,125.9a14.3,14.3,0,0,0-2.2,7.5l-.2,36.1a1.9,1.9,0,0,1-.9,1.7l-6.8,4.1A90,90,0,0,1,128,38ZM58.4,185.1l6-3.6a14.1,14.1,0,0,0,6.7-11.9l.2-36.2a1.9,1.9,0,0,1,.3-1l19.9-31.1a2.4,2.4,0,0,1,1.3-.9,1.8,1.8,0,0,1,1.5.3L114,115a13.9,13.9,0,0,0,10.1,2.5l31.5-4.2a14.1,14.1,0,0,0,8.7-4.8l22.1-25.6a13.6,13.6,0,0,0,3.4-9.8l-.5-10.9A89.4,89.4,0,0,1,218,128a92.4,92.4,0,0,1-4.1,27l-18.3-16.7a14.1,14.1,0,0,0-14.9-2.6l-30.4,12.7a13.9,13.9,0,0,0-8.5,10.8l-2.4,16.2A14,14,0,0,0,149.7,191l21.5,5.6a2.4,2.4,0,0,1,.9.6l5.7,5.7A89.9,89.9,0,0,1,58.4,185.1Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M219.9,167.4l.3-.7A99.9,99.9,0,0,0,185.4,46.2l-.9-.7A100,100,0,0,0,46.1,185.3v.2l.5.6a99.8,99.8,0,0,0,142.4,21,2.1,2.1,0,0,0,.8-.6h0A99.4,99.4,0,0,0,219.9,167.4ZM128,36a91.3,91.3,0,0,1,50.8,15.3l1.1,22.3a4.2,4.2,0,0,1-1,2.8L156.7,102a3.8,3.8,0,0,1-2.5,1.3l-31.4,4.3a4.1,4.1,0,0,1-2.9-.7L100.2,92.6a12.2,12.2,0,0,0-9.3-2,11.6,11.6,0,0,0-7.8,5.3L63.2,127a12,12,0,0,0-1.9,6.4l-.2,36.1a3.9,3.9,0,0,1-1.9,3.4L50.8,178A91.2,91.2,0,0,1,36,128,92.1,92.1,0,0,1,128,36ZM55.5,184.5l7.8-4.7a12,12,0,0,0,5.8-10.2l.2-36.2a4,4,0,0,1,.7-2.1l19.8-31.1a4,4,0,0,1,2.6-1.8,4.2,4.2,0,0,1,3.1.7l19.7,14.3a12,12,0,0,0,8.6,2.1l31.5-4.2a12.2,12.2,0,0,0,7.5-4.1l22.1-25.6a11.3,11.3,0,0,0,2.9-8.4l-.7-15.7a92.2,92.2,0,0,1,27.7,101.1l-20.6-18.8a12.1,12.1,0,0,0-12.7-2.3l-30.4,12.7a12,12,0,0,0-7.3,9.3l-2.4,16.2a12,12,0,0,0,8.8,13.4l21.5,5.6a4.2,4.2,0,0,1,1.8,1.1l7.4,7.4A91.7,91.7,0,0,1,55.5,184.5Zm131.8,13.8-8.1-8.2a11.6,11.6,0,0,0-5.5-3.1l-21.4-5.7a4,4,0,0,1-3-4.4l2.4-16.2a3.9,3.9,0,0,1,2.4-3.1l30.5-12.7a3.9,3.9,0,0,1,4.2.8l22.8,20.8A93.8,93.8,0,0,1,187.3,198.3Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M223.5,169.2a6.1,6.1,0,0,0,.5-1.2A103.9,103.9,0,0,0,187.8,43l-1.2-.9a104,104,0,0,0-144,145.2v.2l.8,1.1a104,104,0,0,0,147.8,21.9l1.4-1.1A105.3,105.3,0,0,0,223.5,169.2Zm-36,23.6-5.5-5.5a15.8,15.8,0,0,0-7.3-4.2l-21.4-5.6,2.4-16.2,30.4-12.7,20.6,18.8A90.6,90.6,0,0,1,187.5,192.8ZM128,40a87.5,87.5,0,0,1,46.9,13.6l1,20.2L153.7,99.4l-31.5,4.2L102.6,89.4a16.2,16.2,0,0,0-12.4-2.8,16.3,16.3,0,0,0-10.5,7.2l-19.9,31a16.3,16.3,0,0,0-2.5,8.6l-.2,36.1-5,3A87.2,87.2,0,0,1,40,128,88.1,88.1,0,0,1,128,40ZM61.5,185.6l3.9-2.4a16.2,16.2,0,0,0,7.7-13.6l.2-36.1,19.9-31.1,19.6,14.2a16.5,16.5,0,0,0,11.6,2.9l31.4-4.3a15.8,15.8,0,0,0,10-5.4L188,84.2A15.8,15.8,0,0,0,191.8,73l-.2-5.8A87.7,87.7,0,0,1,216,128a86.4,86.4,0,0,1-3.2,23.4l-15.9-14.6a16.1,16.1,0,0,0-16.9-3l-30.5,12.7a16.1,16.1,0,0,0-9.7,12.4l-2.4,16.2A16.2,16.2,0,0,0,149.2,193l21.5,5.6,4,4a88,88,0,0,1-113.2-17Z" />
        </>
      )}
    </svg>
  );
}

export function PhGlobeHemisphereWest(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M224.5,176.4a6.1,6.1,0,0,0,.7-1.4A107.9,107.9,0,0,0,155.9,23.7l-.5-.2A108.1,108.1,0,0,0,57.9,45.9a10.7,10.7,0,0,0-1.6,1.4A108,108,0,0,0,128,236h2.4A108.2,108.2,0,0,0,224.5,176.4ZM212,128a83.9,83.9,0,0,1-3.7,24.7l-42.1-25.9a20.4,20.4,0,0,0-7.8-2.8l-22.9-3.1a20.3,20.3,0,0,0-17.8,6.8h-4l-2.8-5.6a19.8,19.8,0,0,0-13.8-10.9l-1.8-.4,1.7-3.9h18.7a20.2,20.2,0,0,0,9.6-2.5l12.3-6.8a15.9,15.9,0,0,0,3.7-2.7l26.9-24.3a20.1,20.1,0,0,0,6.5-12.4A83.9,83.9,0,0,1,212,128ZM144.6,45.7l5.2,9.2L125.6,76.8l-11,6.1H94.3A19.9,19.9,0,0,0,75.9,95.1l-1.4,3.2L67.7,80l7.2-17a83.3,83.3,0,0,1,69.7-17.3ZM44,128a82,82,0,0,1,5.2-29l6.9,18.6a19.9,19.9,0,0,0,14.6,12.5l5.5,1.2h.1l13.9,3,2.9,6.1a20.4,20.4,0,0,0,13.3,10.7l-5.5,12.2a19.9,19.9,0,0,0,3.6,21.7l17.4,18.9-1.5,7.7A84,84,0,0,1,44,128Zm101.1,82.2.8-3.7a20.1,20.1,0,0,0-5-17.4l-17-18.4,11.4-25.6,19,2.5,43.6,26.9A83.8,83.8,0,0,1,145.1,210.2Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M130.3,224a96.3,96.3,0,0,0,83.9-53.6h0L159.9,137a7.2,7.2,0,0,0-3.1-1.1l-22.9-3.1a7.8,7.8,0,0,0-8.3,4.7l-13.7,30.7a8,8,0,0,0,1.4,8.7l18.8,20.3a8.2,8.2,0,0,1,2,7L130.3,224Z" />
          </g>
          <g opacity="0.2">
            <path d="M65,55.6,56,76.8a8,8,0,0,0-.1,5.9l11.5,30.6a7.9,7.9,0,0,0,5.8,5.1l5.4,1.1,8.3-19.7a8.1,8.1,0,0,1,7.4-4.9h21.4a8.6,8.6,0,0,0,3.8-1l12.3-6.8a7.2,7.2,0,0,0,1.5-1.1l26.9-24.3a8.1,8.1,0,0,0,1.6-9.8l-9.3-16.8h0A98.5,98.5,0,0,0,128,32,95.4,95.4,0,0,0,65,55.6Z" />
          </g>
          <path d="M221.6,173.3A102.9,102.9,0,0,0,232,128,104.2,104.2,0,0,0,154.8,27.5h-.5A103.8,103.8,0,0,0,60.4,49l-1.3,1.2A103.9,103.9,0,0,0,128,232h2.4A104.3,104.3,0,0,0,221,174.6h0ZM216,128a89.3,89.3,0,0,1-5.5,30.7l-46.4-28.5a15.4,15.4,0,0,0-6.3-2.3l-22.8-3a16.1,16.1,0,0,0-15.3,6.8h-8.6l-3.8-7.9a15.7,15.7,0,0,0-11-8.7l-6.6-1.4,4.6-10.8h21.4a16.1,16.1,0,0,0,7.7-2l12.2-6.8a16.1,16.1,0,0,0,3-2.1l26.9-24.4A15.7,15.7,0,0,0,170,50.7,88,88,0,0,1,216,128ZM147.2,42.1l7.6,13.7L127.9,80.1l-12.2,6.8H94.3a15.9,15.9,0,0,0-14.7,9.8l-5.3,12.4L63.4,79.9l8.2-19.3a87.8,87.8,0,0,1,75.6-18.5ZM40,128a87.1,87.1,0,0,1,9.5-39.7l10.4,27.9a16.1,16.1,0,0,0,11.6,10l5.5,1.2h.1l15.8,3.4,3.8,7.9a16.4,16.4,0,0,0,14.4,9h1.2l-7.7,17.2a16.1,16.1,0,0,0,2.8,17.4l18.8,20.4-2.5,13.2A88.1,88.1,0,0,1,40,128Zm100.1,87.2,1.8-9.5a16,16,0,0,0-3.9-13.9l-18.8-20.3,12.7-28.7h.1l.9-2.1,22.8,3.1,47.8,29.4A88.5,88.5,0,0,1,140.1,215.2Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M221.6,173.3A102.9,102.9,0,0,0,232,128,104.2,104.2,0,0,0,154.8,27.5h-.5A103.8,103.8,0,0,0,60.4,49l-1.3,1.2A103.9,103.9,0,0,0,128,232h2.4A104.3,104.3,0,0,0,221,174.6h0ZM216,128a89.3,89.3,0,0,1-5.5,30.7l-46.4-28.5a15.4,15.4,0,0,0-6.3-2.3l-22.8-3a16.1,16.1,0,0,0-15.3,6.8h-8.6l-3.8-7.9a16.2,16.2,0,0,0-11-8.7l-6.6-1.4,2.6-5.9a7.8,7.8,0,0,1,7.3-4.9h16.1a16.1,16.1,0,0,0,7.7-2l12.2-6.8a16.1,16.1,0,0,0,3-2.1l26.9-24.4A15.7,15.7,0,0,0,170,50.7,88,88,0,0,1,216,128ZM40,128a87.1,87.1,0,0,1,9.5-39.7l10.4,27.9a16.1,16.1,0,0,0,11.6,10l5.5,1.2h.1l12,2.6a7.8,7.8,0,0,1,5.5,4.3l2.1,4.4a16.1,16.1,0,0,0,14.4,9h1.2l-7.7,17.2a16.1,16.1,0,0,0,2.8,17.4l16.1,17.4a8.3,8.3,0,0,1,2,6.9l-1.8,9.3A88.1,88.1,0,0,1,40,128Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M219.8,172.5A102.1,102.1,0,0,0,154.3,29.4h-.5A100.3,100.3,0,0,0,128,26,101.9,101.9,0,0,0,61.6,50.6a5.2,5.2,0,0,0-1.1,1A102,102,0,0,0,128,230h2.3a102.1,102.1,0,0,0,88.9-56.4h.1ZM218,128a89.9,89.9,0,0,1-6.5,33.7L163,131.9a13.6,13.6,0,0,0-5.4-2l-22.9-3.1a14.2,14.2,0,0,0-13.9,6.9h-9.7a2.3,2.3,0,0,1-1.8-1.1l-3.8-7.9a13.9,13.9,0,0,0-9.6-7.6L87,115.2l5.5-13.1a1.8,1.8,0,0,1,1.8-1.2h21.4a13.2,13.2,0,0,0,6.7-1.8l12.3-6.7a24.8,24.8,0,0,0,2.6-1.9l26.9-24.3A14,14,0,0,0,167,48.9l-1.5-2.7A89.9,89.9,0,0,1,218,128ZM148.5,40.4l8.1,14.4a2.1,2.1,0,0,1-.4,2.4l-27,24.4-.3.3-12.3,6.7a1.6,1.6,0,0,1-.9.3H94.3a14,14,0,0,0-12.9,8.5L75,112.6h-.6a1.8,1.8,0,0,1-1.4-1.3L61.5,80.6a2,2,0,0,1,0-1.5l8.4-19.8A89.6,89.6,0,0,1,128,38,87.1,87.1,0,0,1,148.5,40.4ZM38,128A90,90,0,0,1,49.8,83.4l.5,1.4,11.5,30.7a13.7,13.7,0,0,0,10.1,8.7l5.5,1.2h.1l15.9,3.4a2.1,2.1,0,0,1,1.3,1.1l3.8,7.9a14.2,14.2,0,0,0,12.6,7.9h4.2l-8.9,20.1a14,14,0,0,0,2.5,15.2l18.8,20.3a2.2,2.2,0,0,1,.5,1.7l-2.9,15A90.2,90.2,0,0,1,38,128Zm99.6,89.5,2.4-12.2a14.1,14.1,0,0,0-3.5-12.1l-18.8-20.4a2.2,2.2,0,0,1-.4-2.2L130.1,142h0l.9-2.1a2.2,2.2,0,0,1,2.1-1.2l22.9,3.1c.2.1.5.1.7.3l49.5,30.4A90.1,90.1,0,0,1,137.6,217.5Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M217.9,171.7A98.5,98.5,0,0,0,228,128a100.3,100.3,0,0,0-74.3-96.7h-.3A96.7,96.7,0,0,0,128,28,99.5,99.5,0,0,0,62.8,52.2a3,3,0,0,0-.8.8,99.9,99.9,0,0,0,66,175h2.3a100,100,0,0,0,87.2-55.5h.1ZM220,128a91.2,91.2,0,0,1-7.6,36.6l-50.4-31a11.6,11.6,0,0,0-4.7-1.7l-22.8-3.1a12,12,0,0,0-12.5,6.9H111.1a4.1,4.1,0,0,1-3.6-2.3l-3.8-7.8a11.5,11.5,0,0,0-8.2-6.5l-11.3-2.5,6.4-15.3a3.9,3.9,0,0,1,3.7-2.4h21.4a13.2,13.2,0,0,0,5.8-1.5l12.2-6.8a8.9,8.9,0,0,0,2.2-1.6l27-24.3a12.2,12.2,0,0,0,2.4-14.8l-4.4-7.8A92.2,92.2,0,0,1,220,128ZM149.8,38.6l8.5,15.2a3.9,3.9,0,0,1-.8,4.9L130.6,83.1l-.8.5-12.2,6.8a4.5,4.5,0,0,1-1.9.5H94.3a11.8,11.8,0,0,0-11,7.3l-7.1,16.7-2.2-.4a4.2,4.2,0,0,1-2.9-2.6L59.6,81.3a4.4,4.4,0,0,1,.1-3l8.5-20.2a92.1,92.1,0,0,1,81.6-19.5ZM36,128A92.1,92.1,0,0,1,51.9,76.3a12.2,12.2,0,0,0,.2,7.8l11.5,30.7a12.2,12.2,0,0,0,8.7,7.5l5.5,1.2h0l16,3.4a3.6,3.6,0,0,1,2.7,2.1l3.8,7.9a12.2,12.2,0,0,0,10.8,6.8h7.3l-10.2,22.9a12,12,0,0,0,2.2,13l18.8,20.3a4.1,4.1,0,0,1,.9,3.5L126.9,220A92.1,92.1,0,0,1,36,128Zm99.1,91.7,2.9-14.8a12.1,12.1,0,0,0-3-10.4l-18.8-20.3a4.2,4.2,0,0,1-.7-4.4l12.8-28.6h0l.9-2.1a4,4,0,0,1,4.2-2.3l22.8,3a4.4,4.4,0,0,1,1.6.6l51.1,31.4A92,92,0,0,1,135.1,219.7Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M221.6,173.3A102.9,102.9,0,0,0,232,128,104.2,104.2,0,0,0,154.8,27.5h-.5A103.8,103.8,0,0,0,60.4,49l-1.3,1.2A103.9,103.9,0,0,0,128,232h2.4A104.3,104.3,0,0,0,221,174.6h0ZM216,128a89.3,89.3,0,0,1-5.5,30.7l-46.4-28.5a16.6,16.6,0,0,0-6.3-2.3l-22.8-3a16.1,16.1,0,0,0-15.3,6.8h-8.6l-3.8-7.9a15.9,15.9,0,0,0-11-8.7l-6.6-1.4,4.6-10.8h21.4a16.1,16.1,0,0,0,7.7-2l12.2-6.8a16.1,16.1,0,0,0,3-2.1l26.9-24.4A15.7,15.7,0,0,0,170,50.7,88,88,0,0,1,216,128ZM147.2,42.1l7.6,13.7L127.9,80.1l-12.2,6.8H94.3a15.9,15.9,0,0,0-14.7,9.8l-5.3,12.4L63.4,79.9l8.1-19.3a88,88,0,0,1,75.7-18.5ZM40,128a87.1,87.1,0,0,1,9.5-39.7l10.4,27.9a16.1,16.1,0,0,0,11.6,10l5.5,1.2h.1l15.8,3.4,3.8,7.9a16.3,16.3,0,0,0,14.4,9h1.2l-7.7,17.2a15.9,15.9,0,0,0,2.8,17.4l18.8,20.4-2.5,13.2A88.1,88.1,0,0,1,40,128Zm100.1,87.2,1.8-9.5a16,16,0,0,0-3.9-13.9l-18.8-20.3,12.7-28.7h0l1-2.1,22.8,3.1,47.8,29.4A88.5,88.5,0,0,1,140.1,215.2Z" />
        </>
      )}
    </svg>
  );
}

export function PhGlobeSimple(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,20A108,108,0,1,0,236,128,108.1,108.1,0,0,0,128,20Zm83.1,96H179.5a138.3,138.3,0,0,0-22.9-67A84.2,84.2,0,0,1,211.1,116ZM100.6,140h54.8c-2.4,24.7-12.2,47.7-27.4,64.6C112.8,187.7,103,164.7,100.6,140Zm0-24C103,91.3,112.8,68.3,128,51.4c15.2,16.9,25,39.9,27.4,64.6ZM99.4,49a138.3,138.3,0,0,0-22.9,67H44.9A84.2,84.2,0,0,1,99.4,49ZM44.9,140H76.5a138.3,138.3,0,0,0,22.9,67A84,84,0,0,1,44.9,140Zm111.7,67a138.3,138.3,0,0,0,22.9-67h31.6A84,84,0,0,1,156.6,207Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="128" cy="128" r="96" />
          </g>
          <path d="M232,128A104.1,104.1,0,0,0,128,24h0a104,104,0,0,0,0,208h0A104.1,104.1,0,0,0,232,128Zm-16.4-8H175.8c-1.6-29.6-12-57-29.5-78.1A88.2,88.2,0,0,1,215.6,120ZM96.3,136h63.4c-1.8,28.8-13.3,55.7-31.7,74.4C109.6,191.7,98.1,164.8,96.3,136Zm0-16c1.8-28.8,13.3-55.7,31.7-74.4,18.4,18.7,29.9,45.6,31.7,74.4Zm13.4-78.1C92.2,63,81.8,90.4,80.2,120H40.4A88.2,88.2,0,0,1,109.7,41.9ZM40.4,136H80.2c1.6,29.6,12,57,29.5,78.1A88.2,88.2,0,0,1,40.4,136Zm105.9,78.1c17.5-21.1,27.9-48.5,29.5-78.1h39.8A88.2,88.2,0,0,1,146.3,214.1Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M232,128A104.1,104.1,0,0,0,128,24h0a104,104,0,0,0,0,208h0A104.1,104.1,0,0,0,232,128Zm-16.4-8H175.8c-1.6-29.6-12-57-29.5-78.1A88.2,88.2,0,0,1,215.6,120ZM96.3,136h63.4c-1.8,28.8-13.3,55.7-31.7,74.4C109.6,191.7,98.1,164.8,96.3,136Zm0-16c1.8-28.8,13.3-55.7,31.7-74.4,18.4,18.7,29.9,45.6,31.7,74.4Zm50,94.1c17.5-21.1,27.9-48.5,29.5-78.1h39.8A88.2,88.2,0,0,1,146.3,214.1Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,26A102,102,0,1,0,230,128,102.2,102.2,0,0,0,128,26Zm89.8,96H173.9c-1.3-32-13-61.4-32.9-83A90,90,0,0,1,217.8,122ZM94.1,134h67.8c-1.5,30.8-13.8,59.7-33.9,79.3C107.9,193.7,95.6,164.8,94.1,134Zm0-12c1.5-30.8,13.8-59.7,33.9-79.3,20.1,19.6,32.4,48.5,33.9,79.3ZM115,39C95.1,60.6,83.4,90,82.1,122H38.2A90,90,0,0,1,115,39ZM38.2,134H82.1c1.3,32,13,61.4,32.9,83.1A90.2,90.2,0,0,1,38.2,134ZM141,217.1c19.9-21.7,31.6-51.1,32.9-83.1h43.9A90.2,90.2,0,0,1,141,217.1Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,28A100,100,0,1,0,228,128,100.2,100.2,0,0,0,128,28Zm91.9,96h-48C171,89.8,158,58.4,135.8,36.3A92.2,92.2,0,0,1,219.9,124ZM128,216c-21.7-20.3-34.9-51.2-35.9-84h71.8C162.9,164.8,149.7,195.7,128,216ZM92.1,124c1-32.7,14.2-63.7,35.9-84,21.7,20.3,34.9,51.3,35.9,84Zm28.2-87.7C98,58.4,85,89.8,84.1,124h-48A92.2,92.2,0,0,1,120.3,36.3ZM36.1,132h48c.9,34.3,13.9,65.7,36.2,87.7A92.2,92.2,0,0,1,36.1,132Zm99.7,87.7c22.2-22,35.2-53.4,36.1-87.7h48A92.2,92.2,0,0,1,135.8,219.7Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M232,128A104.1,104.1,0,0,0,128,24h0a104,104,0,0,0,0,208h0A104.1,104.1,0,0,0,232,128Zm-16.4-8H175.8c-1.6-29.6-12-57-29.5-78.1A88.2,88.2,0,0,1,215.6,120ZM96.3,136h63.4c-1.8,28.8-13.3,55.7-31.7,74.4C109.6,191.7,98.1,164.8,96.3,136Zm0-16c1.8-28.8,13.3-55.7,31.7-74.4,18.4,18.7,29.9,45.6,31.7,74.4Zm13.4-78.1C92.2,63,81.8,90.4,80.2,120H40.4A88.2,88.2,0,0,1,109.7,41.9ZM40.4,136H80.2c1.6,29.6,12,57,29.5,78.1A88.2,88.2,0,0,1,40.4,136Zm105.9,78.1c17.5-21.1,27.9-48.5,29.5-78.1h39.8A88.2,88.2,0,0,1,146.3,214.1Z" />
        </>
      )}
    </svg>
  );
}

export function PhGlobeStand(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,172A76,76,0,1,0,52,96,76.1,76.1,0,0,0,128,172Zm0-128A52,52,0,1,1,76,96,52,52,0,0,1,128,44Z" />
          <path d="M210,178a12,12,0,1,0-16.9-17A91.5,91.5,0,0,1,128,188h0a91.1,91.1,0,0,1-65-27A92,92,0,0,1,63,30.9,12,12,0,0,0,46,14a116.1,116.1,0,0,0,0,164,114.8,114.8,0,0,0,70,33.4V224H96a12,12,0,0,0,0,24h64a12,12,0,0,0,0-24H140V211.4A114.6,114.6,0,0,0,210,178Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="128" cy="96" r="72" />
          </g>
          <path d="M128,176A80,80,0,1,0,48,96,80.1,80.1,0,0,0,128,176Zm0-144A64,64,0,1,1,64,96,64.1,64.1,0,0,1,128,32Z" />
          <path d="M207.2,175.2a8,8,0,0,0-11.3-11.3A96,96,0,0,1,60.1,28.1,8,8,0,1,0,48.8,16.8,112,112,0,0,0,120,207.7V224H96a8,8,0,0,0,0,16h64a8,8,0,0,0,0-16H136V207.7A111.6,111.6,0,0,0,207.2,175.2Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M48,96a80,80,0,1,1,80,80A80.1,80.1,0,0,1,48,96Zm159.2,79.2a8,8,0,0,0-11.3-11.3A96,96,0,0,1,60.1,28.1,8,8,0,1,0,48.8,16.8,112,112,0,0,0,120,207.7V224H96a8,8,0,0,0,0,16h64a8,8,0,0,0,0-16H136V207.7A111.6,111.6,0,0,0,207.2,175.2Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,174A78,78,0,1,0,50,96,78.1,78.1,0,0,0,128,174Zm0-144A66,66,0,1,1,62,96,66.1,66.1,0,0,1,128,30Zm6,175.8V226h26a6,6,0,0,1,0,12H96a6,6,0,0,1,0-12h26V205.8A110,110,0,0,1,50.2,18.2a6,6,0,0,1,8.5,8.5A98,98,0,0,0,128,194h0a97.3,97.3,0,0,0,69.3-28.7,5.9,5.9,0,0,1,8.5,0,6.1,6.1,0,0,1,0,8.5A109.4,109.4,0,0,1,134,205.8Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,172A76,76,0,1,0,52,96,76.1,76.1,0,0,0,128,172Zm0-144A68,68,0,1,1,60,96,68.1,68.1,0,0,1,128,28Zm4,175.9V228h28a4,4,0,0,1,0,8H96a4,4,0,0,1,0-8h28V203.9A108,108,0,0,1,51.6,19.6a4.2,4.2,0,0,1,5.7,0,4,4,0,0,1,0,5.7A100,100,0,1,0,198.7,166.7a4,4,0,0,1,5.7,0,4.2,4.2,0,0,1,0,5.7A107.7,107.7,0,0,1,132,203.9Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,176A80,80,0,1,0,48,96,80.1,80.1,0,0,0,128,176Zm0-144A64,64,0,1,1,64,96,64.1,64.1,0,0,1,128,32Zm8,175.7V224h24a8,8,0,0,1,0,16H96a8,8,0,0,1,0-16h24V207.7A112,112,0,0,1,48.8,16.8,8,8,0,1,1,60.1,28.1,96,96,0,0,0,195.9,163.9a8,8,0,0,1,11.3,11.3A111.6,111.6,0,0,1,136,207.7Z" />
        </>
      )}
    </svg>
  );
}

export function PhGlobe(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M230,163.4a107.4,107.4,0,0,0,0-70.8,6.5,6.5,0,0,0-.4-1.3,108,108,0,0,0-203.2,0,6.5,6.5,0,0,0-.4,1.3,107.4,107.4,0,0,0,0,70.8,6.5,6.5,0,0,0,.4,1.3,108,108,0,0,0,203.2,0A6.5,6.5,0,0,0,230,163.4ZM108.2,172h39.6A104.8,104.8,0,0,1,128,204.6,104.8,104.8,0,0,1,108.2,172Zm-6.6-24a125.8,125.8,0,0,1,0-40h52.8a125.8,125.8,0,0,1,0,40ZM44,128a87.6,87.6,0,0,1,2.4-20H77.3A165.2,165.2,0,0,0,76,128a163.9,163.9,0,0,0,1.3,20H46.4A87.6,87.6,0,0,1,44,128ZM147.8,84H108.2A104.8,104.8,0,0,1,128,51.4,104.8,104.8,0,0,1,147.8,84Zm30.9,24h30.9a84.5,84.5,0,0,1,0,40H178.7a163.9,163.9,0,0,0,1.3-20A165.2,165.2,0,0,0,178.7,108Zm20.8-24H173.4a134.7,134.7,0,0,0-16.8-35A85.1,85.1,0,0,1,199.5,84ZM99.4,49A134.7,134.7,0,0,0,82.6,84H56.5A85.1,85.1,0,0,1,99.4,49ZM56.5,172H82.6a134.7,134.7,0,0,0,16.8,35A84.6,84.6,0,0,1,56.5,172Zm100.1,35a134.7,134.7,0,0,0,16.8-35h26.1A84.6,84.6,0,0,1,156.6,207Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="128" cy="128" r="96" />
          </g>
          <path d="M225.9,163.2l.3-1a103.7,103.7,0,0,0,0-68.4l-.3-1A104.4,104.4,0,0,0,128,24h0A104.2,104.2,0,0,0,30.1,92.8l-.3,1a103.7,103.7,0,0,0,0,68.4l.3,1A104.2,104.2,0,0,0,128,232h0A104.2,104.2,0,0,0,225.9,163.2ZM102.5,168h51A108.6,108.6,0,0,1,128,210.4,108.6,108.6,0,0,1,102.5,168Zm-4.2-16a126.4,126.4,0,0,1,0-48h59.4a127.6,127.6,0,0,1,2.3,24,126.8,126.8,0,0,1-2.3,24ZM40,128a90.3,90.3,0,0,1,3.3-24H82a145,145,0,0,0,0,48H43.3A90.3,90.3,0,0,1,40,128ZM153.5,88h-51A108.6,108.6,0,0,1,128,45.6,108.6,108.6,0,0,1,153.5,88ZM174,104h38.7a88.9,88.9,0,0,1,0,48H174a145,145,0,0,0,0-48Zm32.4-16h-36a128.7,128.7,0,0,0-24.1-46.1A88.6,88.6,0,0,1,206.4,88ZM109.7,41.9A128.7,128.7,0,0,0,85.6,88h-36A88.6,88.6,0,0,1,109.7,41.9ZM49.6,168h36a128.7,128.7,0,0,0,24.1,46.1A88.3,88.3,0,0,1,49.6,168Zm96.7,46.1A128.7,128.7,0,0,0,170.4,168h36A88.3,88.3,0,0,1,146.3,214.1Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M225.9,163.2l.3-1a103.7,103.7,0,0,0,0-68.4l-.3-1A104.4,104.4,0,0,0,128,24h0A104.2,104.2,0,0,0,30.1,92.8l-.3,1a103.7,103.7,0,0,0,0,68.4l.3,1A104.2,104.2,0,0,0,128,232h0A104.2,104.2,0,0,0,225.9,163.2ZM128,45.6A108.6,108.6,0,0,1,153.5,88h-51A108.6,108.6,0,0,1,128,45.6ZM102.5,168h51A108.6,108.6,0,0,1,128,210.4,108.6,108.6,0,0,1,102.5,168Zm-4.2-16a126.4,126.4,0,0,1,0-48h59.4a127.6,127.6,0,0,1,2.3,24,126.8,126.8,0,0,1-2.3,24ZM174,104h38.7a88.9,88.9,0,0,1,0,48H174a145,145,0,0,0,0-48Zm32.4-16h-36a128.7,128.7,0,0,0-24.1-46.1A88.6,88.6,0,0,1,206.4,88ZM146.3,214.1A128.7,128.7,0,0,0,170.4,168h36A88.3,88.3,0,0,1,146.3,214.1Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M224.3,161.6a101.9,101.9,0,0,0,0-67.2l-.3-.8a102,102,0,0,0-192,0l-.3.8a101.9,101.9,0,0,0,0,67.2l.3.8a102,102,0,0,0,192,.1ZM99.7,166h56.6c-5.7,18.3-15.4,34.7-28.3,47.3C115.1,200.7,105.4,184.3,99.7,166Zm-3-12a126.5,126.5,0,0,1,0-52h62.6a126.5,126.5,0,0,1,0,52ZM38,128a89.4,89.4,0,0,1,3.8-26H84.4a142,142,0,0,0,0,52H41.8A89.4,89.4,0,0,1,38,128ZM156.3,90H99.7c5.7-18.3,15.4-34.7,28.3-47.3C140.9,55.3,150.6,71.7,156.3,90Zm15.4,12h42.5a90.8,90.8,0,0,1,0,52H171.7a148.1,148.1,0,0,0,0-52Zm37.9-12H168.9A124,124,0,0,0,141,39,90.1,90.1,0,0,1,209.6,90ZM115,39A124,124,0,0,0,87.1,90H46.4A90.1,90.1,0,0,1,115,39ZM46.4,166H87.1A125,125,0,0,0,115,217.1,90.5,90.5,0,0,1,46.4,166ZM141,217.1A125,125,0,0,0,168.9,166h40.7A90.5,90.5,0,0,1,141,217.1Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M222.4,161.1A100.4,100.4,0,0,0,228,128a99,99,0,0,0-5.7-33.2v-.4a100,100,0,0,0-188.4,0,4.3,4.3,0,0,0-.1.5,99.2,99.2,0,0,0,0,66.3c0,.2.1.3.1.4a100,100,0,0,0,188.4.1A1.4,1.4,0,0,1,222.4,161.1ZM128,216c-14.4-13.5-25.1-31.7-30.9-52h61.8C153.1,184.3,142.4,202.5,128,216ZM95,156a132.2,132.2,0,0,1,0-56h66a132.2,132.2,0,0,1,0,56ZM36,128a89.2,89.2,0,0,1,4.4-28H86.8a141.4,141.4,0,0,0,0,56H40.4A89.2,89.2,0,0,1,36,128Zm92-88c14.4,13.5,25.1,31.7,30.9,52H97.1C102.9,71.7,113.6,53.5,128,40Zm41.2,60h46.4a91.3,91.3,0,0,1,0,56H169.2a141.4,141.4,0,0,0,0-56Zm43.5-8H167.4a123.1,123.1,0,0,0-31.6-55.7A92.3,92.3,0,0,1,212.7,92ZM120.3,36.3A122.4,122.4,0,0,0,88.6,92H43.3A92.3,92.3,0,0,1,120.3,36.3ZM43.3,164H88.6c5.7,21.5,16.6,40.8,31.7,55.7A92.3,92.3,0,0,1,43.3,164Zm92.5,55.7c15-14.9,25.9-34.2,31.6-55.7h45.3A92.3,92.3,0,0,1,135.8,219.7Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M225.9,163.2l.3-1a103.7,103.7,0,0,0,0-68.4l-.3-1A104.4,104.4,0,0,0,128,24h0A104.2,104.2,0,0,0,30.1,92.8l-.3,1a103.7,103.7,0,0,0,0,68.4l.3,1A104.2,104.2,0,0,0,128,232h0A104.2,104.2,0,0,0,225.9,163.2ZM102.5,168h51A108.6,108.6,0,0,1,128,210.4,108.6,108.6,0,0,1,102.5,168Zm-4.2-16a126.4,126.4,0,0,1,0-48h59.4a127.6,127.6,0,0,1,2.3,24,126.8,126.8,0,0,1-2.3,24ZM40,128a90.3,90.3,0,0,1,3.3-24H82a145,145,0,0,0,0,48H43.3A90.3,90.3,0,0,1,40,128ZM153.5,88h-51A108.6,108.6,0,0,1,128,45.6,108.6,108.6,0,0,1,153.5,88ZM174,104h38.7a88.9,88.9,0,0,1,0,48H174a145,145,0,0,0,0-48Zm32.4-16h-36a128.7,128.7,0,0,0-24.1-46.1A88.6,88.6,0,0,1,206.4,88ZM109.7,41.9A128.7,128.7,0,0,0,85.6,88h-36A88.6,88.6,0,0,1,109.7,41.9ZM49.6,168h36a128.7,128.7,0,0,0,24.1,46.1A88.3,88.3,0,0,1,49.6,168Zm96.7,46.1A128.7,128.7,0,0,0,170.4,168h36A88.3,88.3,0,0,1,146.3,214.1Z" />
        </>
      )}
    </svg>
  );
}

export function PhGoogleChromeLogo(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,20A108,108,0,1,0,236,128,108.1,108.1,0,0,0,128,20Zm0,24a84.1,84.1,0,0,1,68.9,36H128a48.3,48.3,0,0,0-41.6,24L67,70.3A83.7,83.7,0,0,1,128,44Zm24,84a24,24,0,1,1-24-24A24.1,24.1,0,0,1,152,128ZM44,128a83.7,83.7,0,0,1,8-35.7L86.4,152c.1.1.2.2.2.3A48.1,48.1,0,0,0,128,176l-19.5,33.7A84.2,84.2,0,0,1,44,128Zm91.1,83.7L169.6,152a.8.8,0,0,1,.2-.4,47.8,47.8,0,0,0-.3-47.6h39a82.9,82.9,0,0,1,3.5,24A84.1,84.1,0,0,1,135.1,211.7Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M164,128a36,36,0,1,1-36-36A36,36,0,0,1,164,128Z"
            opacity="0.2"
          />
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,16a88.1,88.1,0,0,1,76.2,44H128a44,44,0,0,0-41.2,28.6L62.1,69.8A87.6,87.6,0,0,1,128,40Zm28,88a28,28,0,1,1-28-28A28.1,28.1,0,0,1,156,128ZM40,128A87.7,87.7,0,0,1,51.8,84l38.1,66,.2.3a44,44,0,0,0,45.2,21.1l-24.8,42.9A88.3,88.3,0,0,1,40,128Zm88,88,38.1-66c.1-.1.1-.3.2-.4a43.8,43.8,0,0,0-4.4-49.6h49.5a86.5,86.5,0,0,1,4.6,28A88.1,88.1,0,0,1,128,216Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,16a88.1,88.1,0,0,1,76.2,44H128a44,44,0,0,0-41.2,28.6L62.1,69.8A87.6,87.6,0,0,1,128,40ZM110.5,214.3A88,88,0,0,1,51.8,84l38.1,66,.2.3a44,44,0,0,0,45.2,21.1ZM128,216l38.1-66c.1-.1.1-.3.2-.4a43.8,43.8,0,0,0-4.4-49.6h49.5a86.5,86.5,0,0,1,4.6,28A88.1,88.1,0,0,1,128,216Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,26A102,102,0,1,0,230,128,102.2,102.2,0,0,0,128,26Zm0,12a90.1,90.1,0,0,1,79.6,48H128a42.2,42.2,0,0,0-40.7,31.5L59.6,69.6A89.9,89.9,0,0,1,128,38Zm30,90a30,30,0,1,1-30-30A30.1,30.1,0,0,1,158,128ZM38,128A90,90,0,0,1,51.8,80.1L91.6,149l.2.2A41.8,41.8,0,0,0,128,170a38.6,38.6,0,0,0,11.3-1.6l-27.7,48.1A90.2,90.2,0,0,1,38,128Zm90,90h-3.4L164.4,149l.2-.4A42,42,0,0,0,157.4,98h55.5A90.1,90.1,0,0,1,128,218Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,28A100,100,0,1,0,228,128,100.2,100.2,0,0,0,128,28Zm0,8a92.1,92.1,0,0,1,82.8,52H128a40.1,40.1,0,0,0-39.7,35.2L57.2,69.3A91.9,91.9,0,0,1,128,36Zm32,92a32,32,0,1,1-32-32A32.1,32.1,0,0,1,160,128ZM36,128A91,91,0,0,1,52,76.3L93.4,148v.2A40.1,40.1,0,0,0,128,168a40.8,40.8,0,0,0,15.7-3.2l-31.1,53.9A92.2,92.2,0,0,1,36,128Zm92,92c-2.3,0-4.5-.1-6.8-.3L162.6,148v-.2A39.1,39.1,0,0,0,168,128a40,40,0,0,0-16-32h62.3A92.1,92.1,0,0,1,128,220Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,16a88.1,88.1,0,0,1,76.2,44H128a44,44,0,0,0-41.2,28.6L62.1,69.8A87.6,87.6,0,0,1,128,40Zm28,88a28,28,0,1,1-28-28A28.1,28.1,0,0,1,156,128ZM40,128A87.7,87.7,0,0,1,51.8,84l38.1,66,.2.3a44,44,0,0,0,45.2,21.1l-24.8,42.9A88.3,88.3,0,0,1,40,128Zm88,88,38.1-66c.1-.1.1-.3.2-.4a43.8,43.8,0,0,0-4.4-49.6h49.5a86.5,86.5,0,0,1,4.6,28A88.1,88.1,0,0,1,128,216Z" />
        </>
      )}
    </svg>
  );
}

export function PhGoogleLogo(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,228A100,100,0,1,1,198.7,57.3a12,12,0,0,1,0,17,12.2,12.2,0,0,1-17,0A75.2,75.2,0,0,0,128,52a76,76,0,1,0,75.1,88H128a12,12,0,0,1,0-24h88a12,12,0,0,1,12,12A100.2,100.2,0,0,1,128,228Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M128,224A96,96,0,1,1,195.9,60.1a8,8,0,0,1,0,11.3,7.9,7.9,0,0,1-11.3,0,80,80,0,1,0,23,64.6H128a8,8,0,0,1,0-16h88a8,8,0,0,1,8,8A96.2,96.2,0,0,1,128,224Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,184A80,80,0,1,1,184.6,71.4a8,8,0,0,1,0,11.3,7.9,7.9,0,0,1-11.3,0A64.1,64.1,0,1,0,191.5,136H128a8,8,0,0,1,0-16h72a8,8,0,0,1,8,8A80.1,80.1,0,0,1,128,208Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,222A94,94,0,1,1,194.5,61.5a6.1,6.1,0,0,1,0,8.5,5.9,5.9,0,0,1-8.5,0,82,82,0,1,0,23.8,64H128a6,6,0,0,1,0-12h88a6,6,0,0,1,6,6A94.1,94.1,0,0,1,128,222Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,220A92,92,0,1,1,193.1,62.9a4.2,4.2,0,0,1,0,5.7,4,4,0,0,1-5.7,0A84,84,0,1,0,211.9,132H128a4,4,0,0,1,0-8h88a4,4,0,0,1,4,4A92.1,92.1,0,0,1,128,220Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,224A96,96,0,1,1,195.9,60.1a8,8,0,0,1,0,11.3,7.9,7.9,0,0,1-11.3,0,80,80,0,1,0,23,64.6H128a8,8,0,0,1,0-16h88a8,8,0,0,1,8,8A96.2,96.2,0,0,1,128,224Z" />
        </>
      )}
    </svg>
  );
}

export function PhGooglePhotosLogo(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M232,120H194.4A72.1,72.1,0,0,0,132,12a12,12,0,0,0-12,12V61.6A72.1,72.1,0,0,0,12,124a12,12,0,0,0,12,12H61.6A72.1,72.1,0,0,0,124,244a12,12,0,0,0,12-12V194.4A72.1,72.1,0,0,0,244,132,12,12,0,0,0,232,120ZM180,84a47.4,47.4,0,0,1-16.3,36H144V37.5A48.2,48.2,0,0,1,180,84ZM84,76a47.4,47.4,0,0,1,36,16.3V112H37.5A48.2,48.2,0,0,1,84,76Zm-8,96a47.4,47.4,0,0,1,16.3-36H112v82.5A48.2,48.2,0,0,1,76,172Zm96,8a47.4,47.4,0,0,1-36-16.3V144h82.5A48.2,48.2,0,0,1,172,180Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M192,84a59.8,59.8,0,0,1-24,48H132V24A60,60,0,0,1,192,84ZM64,172a60,60,0,0,0,60,60V124H88A59.8,59.8,0,0,0,64,172Z"
            opacity="0.2"
          />
          <path d="M232,124H187a67.4,67.4,0,0,0,13-40,68.1,68.1,0,0,0-68-68,8,8,0,0,0-8,8V69A67.4,67.4,0,0,0,84,56a68.1,68.1,0,0,0-68,68,8,8,0,0,0,8,8H69a67.4,67.4,0,0,0-13,40,68.1,68.1,0,0,0,68,68,8,8,0,0,0,8-8V187a67.4,67.4,0,0,0,40,13,68.1,68.1,0,0,0,68-68A8,8,0,0,0,232,124ZM140,32.6A52.1,52.1,0,0,1,184,84a51.5,51.5,0,0,1-18.8,40H140ZM84,72a51.5,51.5,0,0,1,40,18.8V116H32.6A52.1,52.1,0,0,1,84,72Zm32,151.4A52.1,52.1,0,0,1,72,172a51.5,51.5,0,0,1,18.8-40H116ZM172,184a51.5,51.5,0,0,1-40-18.8V140h91.4A52.1,52.1,0,0,1,172,184Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M232,124H187a67.4,67.4,0,0,0,13-40,68.1,68.1,0,0,0-68-68,8,8,0,0,0-8,8V69A67.4,67.4,0,0,0,84,56a68.1,68.1,0,0,0-68,68,8,8,0,0,0,8,8H69a67.4,67.4,0,0,0-13,40,68.1,68.1,0,0,0,68,68,8,8,0,0,0,8-8V187a67.4,67.4,0,0,0,40,13,68.1,68.1,0,0,0,68-68A8,8,0,0,0,232,124ZM84,72a51.5,51.5,0,0,1,40,18.8V116H32.6A52.1,52.1,0,0,1,84,72Zm88,112a51.5,51.5,0,0,1-40-18.8V140h91.4A52.1,52.1,0,0,1,172,184Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M232,126H182.9A66,66,0,0,0,132,18a6,6,0,0,0-6,6V73.1A66,66,0,0,0,18,124a6,6,0,0,0,6,6H73.1A66,66,0,0,0,124,238a6,6,0,0,0,6-6V182.9A66,66,0,0,0,238,132,6,6,0,0,0,232,126ZM138,30.3A54.1,54.1,0,0,1,186,84a53.5,53.5,0,0,1-20.1,42H138ZM84,70a53.5,53.5,0,0,1,42,20.1V118H30.3A54.1,54.1,0,0,1,84,70Zm34,155.7A54.1,54.1,0,0,1,70,172a53.5,53.5,0,0,1,20.1-42H118ZM172,186a53.5,53.5,0,0,1-42-20.1V138h95.7A54.1,54.1,0,0,1,172,186Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M232,128H178.5A64,64,0,0,0,132,20a4,4,0,0,0-4,4V77.5A64,64,0,0,0,20,124a4,4,0,0,0,4,4H77.5A64,64,0,0,0,124,236a4,4,0,0,0,4-4V178.5A64,64,0,0,0,236,132,4,4,0,0,0,232,128ZM136,28.1A56,56,0,0,1,166.6,128H136ZM84,68a55.6,55.6,0,0,1,44,21.4V120H28.1A56.2,56.2,0,0,1,84,68Zm36,159.9A56,56,0,0,1,89.4,128H120ZM172,188a55.6,55.6,0,0,1-44-21.4V136h99.9A56.2,56.2,0,0,1,172,188Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M232,124H187a67.4,67.4,0,0,0,13-40,68.1,68.1,0,0,0-68-68,8,8,0,0,0-8,8V69A67.4,67.4,0,0,0,84,56a68.1,68.1,0,0,0-68,68,8,8,0,0,0,8,8H69a67.4,67.4,0,0,0-13,40,68.1,68.1,0,0,0,68,68,8,8,0,0,0,8-8V187a67.4,67.4,0,0,0,40,13,68.1,68.1,0,0,0,68-68A8,8,0,0,0,232,124ZM140,32.6A52.1,52.1,0,0,1,184,84a51.5,51.5,0,0,1-18.8,40H140ZM84,72a51.5,51.5,0,0,1,40,18.8V116H32.6A52.1,52.1,0,0,1,84,72Zm32,151.4A52.1,52.1,0,0,1,72,172a51.5,51.5,0,0,1,18.8-40H116ZM172,184a51.5,51.5,0,0,1-40-18.8V140h91.4A52.1,52.1,0,0,1,172,184Z" />
        </>
      )}
    </svg>
  );
}

export function PhGooglePlayLogo(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M225.7,110.7,57.9,14.7a20.1,20.1,0,0,0-20.1,0,19.7,19.7,0,0,0-9.9,17.2V224.1a19.7,19.7,0,0,0,9.9,17.2A20.1,20.1,0,0,0,47.9,244a19.8,19.8,0,0,0,10-2.7l167.8-96a19.8,19.8,0,0,0,0-34.6ZM51.9,203.2V52.8L127.1,128ZM144.1,145l12.3,12.3-57.7,33ZM98.7,65.7l57.7,33L144.1,111ZM178,144.9,161,128l17-16.9L207.6,128Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M42.3,26.2a7.7,7.7,0,0,0-2.4,5.7V224.1a7.7,7.7,0,0,0,2.4,5.7h0L144.1,128,42.3,26.2Z" />
          </g>
          <path d="M223.7,114.2,55.9,18.1a16,16,0,0,0-16.1.1,15.6,15.6,0,0,0-7.9,13.7V224.1a16,16,0,0,0,16,15.9,16.2,16.2,0,0,0,8-2.1l167.8-96.1a15.7,15.7,0,0,0,0-27.6ZM47.9,212.9V43.1L132.8,128Zm96.2-73.6,18.8,18.9L74.7,208.6Zm-69.4-92,88.2,50.5-18.8,18.9ZM177.3,149.9,155.4,128l21.9-21.9L215.6,128Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M223.7,114.2,55.9,18.1a16,16,0,0,0-16.1.1,15.6,15.6,0,0,0-7.9,13.7V224.1a16,16,0,0,0,16,15.9,16.2,16.2,0,0,0,8-2.1l167.8-96.1a15.7,15.7,0,0,0,0-27.6Zm-79.6,25.1,18.8,18.9L74.7,208.6Zm-69.4-92,88.2,50.5-18.8,18.9ZM177.3,149.9,155.4,128l21.9-21.9L215.6,128Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M222.7,115.9,54.9,19.9a14,14,0,0,0-14.1,0,13.8,13.8,0,0,0-6.9,12V224.1a13.8,13.8,0,0,0,6.9,12,14,14,0,0,0,7.1,1.9,13.7,13.7,0,0,0,7-1.9l167.8-96a13.8,13.8,0,0,0,0-24.2ZM45.9,217.7V38.3L135.6,128Zm98.2-81.2,22.1,22.1L62.7,217.8ZM62.7,38.2,166.2,97.4l-22.1,22.1Zm154.2,91.4h-.1L177,152.4,152.6,128,177,103.6l39.8,22.8h.1a1.8,1.8,0,0,1,0,3.2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M221.8,117.7,53.9,21.6a11.9,11.9,0,0,0-12.1,0,18.4,18.4,0,0,0-2.2,1.7h-.1a11.6,11.6,0,0,0-3.6,8.5V224.1a11.6,11.6,0,0,0,3.6,8.5h.1a18.4,18.4,0,0,0,2.2,1.7,12.1,12.1,0,0,0,6.1,1.6,11.7,11.7,0,0,0,6-1.6l167.8-96a11.8,11.8,0,0,0,6.2-10.4A11.6,11.6,0,0,0,221.8,117.7ZM169.5,97l-25.4,25.3L50.7,29ZM43.9,222.5V33.5L138.4,128Zm6.8,4.5,93.4-93.3L169.5,159Zm167.1-95.6-41.1,23.5-27-26.9,27-26.9,41.2,23.6a3.7,3.7,0,0,1,2,3.3A3.8,3.8,0,0,1,217.8,131.4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M223.7,114.2,55.9,18.1a16,16,0,0,0-16.1.1,15.6,15.6,0,0,0-7.9,13.7V224.1a16,16,0,0,0,16,15.9,16.2,16.2,0,0,0,8-2.1l167.8-96.1a15.7,15.7,0,0,0,0-27.6ZM47.9,212.9V43.1L132.8,128Zm96.2-73.6,18.8,18.9L74.7,208.6Zm-69.4-92,88.2,50.5-18.8,18.9ZM177.3,149.9,155.4,128l21.9-21.9L215.6,128Z" />
        </>
      )}
    </svg>
  );
}

export function PhGooglePodcastsLogo(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M140,24V48a12,12,0,0,1-24,0V24a12,12,0,0,1,24,0Zm36,32a12,12,0,0,0-12,12V92a12,12,0,0,0,24,0V68A12,12,0,0,0,176,56ZM128,196a12,12,0,0,0-12,12v24a12,12,0,0,0,24,0V208A12,12,0,0,0,128,196Zm0-120a12,12,0,0,0-12,12v80a12,12,0,0,0,24,0V88A12,12,0,0,0,128,76ZM80,56A12,12,0,0,0,68,68v56a12,12,0,0,0,24,0V68A12,12,0,0,0,80,56Zm96,64a12,12,0,0,0-12,12v56a12,12,0,0,0,24,0V132A12,12,0,0,0,176,120ZM32,104a12,12,0,0,0-12,12v24a12,12,0,0,0,24,0V116A12,12,0,0,0,32,104Zm48,48a12,12,0,0,0-12,12v24a12,12,0,0,0,24,0V164A12,12,0,0,0,80,152Zm144-48a12,12,0,0,0-12,12v24a12,12,0,0,0,24,0V116A12,12,0,0,0,224,104Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M136,24V48a8,8,0,0,1-16,0V24a8,8,0,0,1,16,0Zm40,36a8,8,0,0,0-8,8V92a8,8,0,0,0,16,0V68A8,8,0,0,0,176,60ZM128,200a8,8,0,0,0-8,8v24a8,8,0,0,0,16,0V208A8,8,0,0,0,128,200Zm0-128a8,8,0,0,0-8,8v96a8,8,0,0,0,16,0V80A8,8,0,0,0,128,72ZM80,60a8,8,0,0,0-8,8v64a8,8,0,0,0,16,0V68A8,8,0,0,0,80,60Zm96,56a8,8,0,0,0-8,8v64a8,8,0,0,0,16,0V124A8,8,0,0,0,176,116ZM32,108a8,8,0,0,0-8,8v24a8,8,0,0,0,16,0V116A8,8,0,0,0,32,108Zm48,48a8,8,0,0,0-8,8v24a8,8,0,0,0,16,0V164A8,8,0,0,0,80,156Zm144-48a8,8,0,0,0-8,8v24a8,8,0,0,0,16,0V116A8,8,0,0,0,224,108Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M88,68v64a8,8,0,0,1-16,0V68a8,8,0,0,1,16,0Zm40,132a8,8,0,0,0-8,8v24a8,8,0,0,0,16,0V208A8,8,0,0,0,128,200Zm0-128a8,8,0,0,0-8,8v96a8,8,0,0,0,16,0V80A8,8,0,0,0,128,72ZM80,156a8,8,0,0,0-8,8v24a8,8,0,0,0,16,0V164A8,8,0,0,0,80,156ZM32,108a8,8,0,0,0-8,8v24a8,8,0,0,0,16,0V116A8,8,0,0,0,32,108Zm96-92a8,8,0,0,0-8,8V48a8,8,0,0,0,16,0V24A8,8,0,0,0,128,16Zm48,44a8,8,0,0,0-8,8V92a8,8,0,0,0,16,0V68A8,8,0,0,0,176,60Zm48,48a8,8,0,0,0-8,8v24a8,8,0,0,0,16,0V116A8,8,0,0,0,224,108Zm-48,8a8,8,0,0,0-8,8v64a8,8,0,0,0,16,0V124A8,8,0,0,0,176,116Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M134,24V48a6,6,0,0,1-12,0V24a6,6,0,0,1,12,0Zm42,38a6,6,0,0,0-6,6V92a6,6,0,0,0,12,0V68A6,6,0,0,0,176,62ZM128,202a6,6,0,0,0-6,6v24a6,6,0,0,0,12,0V208A6,6,0,0,0,128,202Zm0-128a6,6,0,0,0-6,6v96a6,6,0,0,0,12,0V80A6,6,0,0,0,128,74ZM80,62a6,6,0,0,0-6,6v64a6,6,0,0,0,12,0V68A6,6,0,0,0,80,62Zm96,56a6,6,0,0,0-6,6v64a6,6,0,0,0,12,0V124A6,6,0,0,0,176,118ZM32,110a6,6,0,0,0-6,6v24a6,6,0,0,0,12,0V116A6,6,0,0,0,32,110Zm48,48a6,6,0,0,0-6,6v24a6,6,0,0,0,12,0V164A6,6,0,0,0,80,158Zm144-48a6,6,0,0,0-6,6v24a6,6,0,0,0,12,0V116A6,6,0,0,0,224,110Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M132,24V48a4,4,0,0,1-8,0V24a4,4,0,0,1,8,0Zm44,40a4,4,0,0,0-4,4V92a4,4,0,0,0,8,0V68A4,4,0,0,0,176,64ZM128,204a4,4,0,0,0-4,4v24a4,4,0,0,0,8,0V208A4,4,0,0,0,128,204Zm0-128a4,4,0,0,0-4,4v96a4,4,0,0,0,8,0V80A4,4,0,0,0,128,76ZM80,64a4,4,0,0,0-4,4v64a4,4,0,0,0,8,0V68A4,4,0,0,0,80,64Zm96,56a4,4,0,0,0-4,4v64a4,4,0,0,0,8,0V124A4,4,0,0,0,176,120ZM32,112a4,4,0,0,0-4,4v24a4,4,0,0,0,8,0V116A4,4,0,0,0,32,112Zm48,48a4,4,0,0,0-4,4v24a4,4,0,0,0,8,0V164A4,4,0,0,0,80,160Zm144-48a4,4,0,0,0-4,4v24a4,4,0,0,0,8,0V116A4,4,0,0,0,224,112Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M136,24V48a8,8,0,0,1-16,0V24a8,8,0,0,1,16,0Zm40,36a8,8,0,0,0-8,8V92a8,8,0,0,0,16,0V68A8,8,0,0,0,176,60ZM128,200a8,8,0,0,0-8,8v24a8,8,0,0,0,16,0V208A8,8,0,0,0,128,200Zm0-128a8,8,0,0,0-8,8v96a8,8,0,0,0,16,0V80A8,8,0,0,0,128,72ZM80,60a8,8,0,0,0-8,8v64a8,8,0,0,0,16,0V68A8,8,0,0,0,80,60Zm96,56a8,8,0,0,0-8,8v64a8,8,0,0,0,16,0V124A8,8,0,0,0,176,116ZM32,108a8,8,0,0,0-8,8v24a8,8,0,0,0,16,0V116A8,8,0,0,0,32,108Zm48,48a8,8,0,0,0-8,8v24a8,8,0,0,0,16,0V164A8,8,0,0,0,80,156Zm144-48a8,8,0,0,0-8,8v24a8,8,0,0,0,16,0V116A8,8,0,0,0,224,108Z" />
        </>
      )}
    </svg>
  );
}

export function PhGradient(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M20,108A12,12,0,0,1,32,96h76a12,12,0,0,1,0,24H32A12,12,0,0,1,20,108ZM224,96H148a12,12,0,0,0,0,24h76a12,12,0,0,0,0-24ZM68,136H32a12,12,0,0,0,0,24H68a12,12,0,0,0,0-24Zm156,0H188a12,12,0,0,0,0,24h36a12,12,0,0,0,0-24ZM96,148a12,12,0,0,0,12,12h40a12,12,0,0,0,0-24H108A12,12,0,0,0,96,148ZM52,176H32a12,12,0,0,0,0,24H52a12,12,0,0,0,0-24Zm56,0H92a12,12,0,0,0,0,24h16a12,12,0,0,0,0-24Zm56,0H148a12,12,0,0,0,0,24h16a12,12,0,0,0,0-24Zm60,0H204a12,12,0,0,0,0,24h20a12,12,0,0,0,0-24ZM32,80H224a12,12,0,0,0,0-24H32a12,12,0,0,0,0,24Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M24,104a8,8,0,0,1,8-8h80a8,8,0,0,1,0,16H32A8,8,0,0,1,24,104Zm200-8H144a8,8,0,0,0,0,16h80a8,8,0,0,0,0-16ZM84,144a8,8,0,0,0-8-8H32a8,8,0,0,0,0,16H76A8,8,0,0,0,84,144Zm140-8H180a8,8,0,0,0,0,16h44a8,8,0,0,0,0-16ZM96,144a8,8,0,0,0,8,8h48a8,8,0,0,0,0-16H104A8,8,0,0,0,96,144ZM56,176H32a8,8,0,0,0,0,16H56a8,8,0,0,0,0-16Zm56,0H88a8,8,0,0,0,0,16h24a8,8,0,0,0,0-16Zm56,0H144a8,8,0,0,0,0,16h24a8,8,0,0,0,0-16Zm56,0H200a8,8,0,0,0,0,16h24a8,8,0,0,0,0-16ZM32,72H224a8,8,0,0,0,0-16H32a8,8,0,0,0,0,16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M64,184a8,8,0,0,1-8,8H32a8,8,0,0,1,0-16H56A8,8,0,0,1,64,184Zm20-40a8,8,0,0,0-8-8H32a8,8,0,0,0,0,16H76A8,8,0,0,0,84,144Zm12,0a8,8,0,0,0,8,8h48a8,8,0,0,0,0-16H104A8,8,0,0,0,96,144ZM32,72H224a8,8,0,0,0,0-16H32a8,8,0,0,0,0,16Zm0,40h80a8,8,0,0,0,0-16H32a8,8,0,0,0,0,16Zm136,64H144a8,8,0,0,0,0,16h24a8,8,0,0,0,0-16Zm56-40H180a8,8,0,0,0,0,16h44a8,8,0,0,0,0-16ZM112,176H88a8,8,0,0,0,0,16h24a8,8,0,0,0,0-16ZM224,96H144a8,8,0,0,0,0,16h80a8,8,0,0,0,0-16Zm0,80H200a8,8,0,0,0,0,16h24a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M26,104a6,6,0,0,1,6-6h80a6,6,0,0,1,0,12H32A6,6,0,0,1,26,104Zm198-6H144a6,6,0,0,0,0,12h80a6,6,0,0,0,0-12ZM76,138H32a6,6,0,0,0,0,12H76a6,6,0,0,0,0-12Zm148,0H180a6,6,0,0,0,0,12h44a6,6,0,0,0,0-12ZM98,144a6,6,0,0,0,6,6h48a6,6,0,0,0,0-12H104A6,6,0,0,0,98,144ZM56,178H32a6,6,0,0,0,0,12H56a6,6,0,0,0,0-12Zm56,0H88a6,6,0,0,0,0,12h24a6,6,0,0,0,0-12Zm56,0H144a6,6,0,0,0,0,12h24a6,6,0,0,0,0-12Zm56,0H200a6,6,0,0,0,0,12h24a6,6,0,0,0,0-12ZM32,70H224a6,6,0,0,0,0-12H32a6,6,0,0,0,0,12Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M28,104a4,4,0,0,1,4-4h80a4,4,0,0,1,0,8H32A4,4,0,0,1,28,104Zm196-4H144a4,4,0,0,0,0,8h80a4,4,0,0,0,0-8ZM76,140H32a4,4,0,0,0,0,8H76a4,4,0,0,0,0-8Zm148,0H180a4,4,0,0,0,0,8h44a4,4,0,0,0,0-8Zm-124,4a4,4,0,0,0,4,4h48a4,4,0,0,0,0-8H104A4,4,0,0,0,100,144ZM56,180H32a4,4,0,0,0,0,8H56a4,4,0,0,0,0-8Zm56,0H88a4,4,0,0,0,0,8h24a4,4,0,0,0,0-8Zm56,0H144a4,4,0,0,0,0,8h24a4,4,0,0,0,0-8Zm56,0H200a4,4,0,0,0,0,8h24a4,4,0,0,0,0-8ZM32,68H224a4,4,0,0,0,0-8H32a4,4,0,0,0,0,8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M24,104a8,8,0,0,1,8-8h80a8,8,0,0,1,0,16H32A8,8,0,0,1,24,104Zm200-8H144a8,8,0,0,0,0,16h80a8,8,0,0,0,0-16ZM84,144a8,8,0,0,0-8-8H32a8,8,0,0,0,0,16H76A8,8,0,0,0,84,144Zm140-8H180a8,8,0,0,0,0,16h44a8,8,0,0,0,0-16ZM96,144a8,8,0,0,0,8,8h48a8,8,0,0,0,0-16H104A8,8,0,0,0,96,144ZM56,176H32a8,8,0,0,0,0,16H56a8,8,0,0,0,0-16Zm56,0H88a8,8,0,0,0,0,16h24a8,8,0,0,0,0-16Zm56,0H144a8,8,0,0,0,0,16h24a8,8,0,0,0,0-16Zm56,0H200a8,8,0,0,0,0,16h24a8,8,0,0,0,0-16ZM32,72H224a8,8,0,0,0,0-16H32a8,8,0,0,0,0,16Z" />
        </>
      )}
    </svg>
  );
}

export function PhGraduationCap(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M249.8,85.5l-116-64a12,12,0,0,0-11.6,0l-116,64a12,12,0,0,0,0,21L20,114.1v51.1a20.3,20.3,0,0,0,4.7,12.9C33.8,188.9,68.2,224,128,224a138.8,138.8,0,0,0,46-7.6V240a12,12,0,0,0,24,0V205.5a132.2,132.2,0,0,0,33.3-27.4,20.3,20.3,0,0,0,4.7-12.9V114.1l13.8-7.6a12,12,0,0,0,0-21ZM128,45.7,219.2,96l-.9.5h-.1L186,114.3,133.8,85.5a12,12,0,1,0-11.6,21l39,21.5L128,146.3,37.8,96.5h-.1l-.9-.5ZM128,200c-47.6,0-75.3-26.4-84-36.3V127.4l78.2,43.1a12,12,0,0,0,11.6,0L174,148.3v42.5A116.7,116.7,0,0,1,128,200Zm84-36.3a99.8,99.8,0,0,1-14,13V135.1l14-7.7Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M220,110.9v54.6a7.8,7.8,0,0,1-1.6,4.7c-6.7,9-35.1,41.8-90.4,41.8s-83.7-32.8-90.4-41.8a7.8,7.8,0,0,1-1.6-4.7V110.9L128,160Z"
            opacity="0.2"
          />
          <path d="M251.8,88.9l-120-64a8.5,8.5,0,0,0-7.6,0l-120,64a8.1,8.1,0,0,0,0,14.2L28,115.7v49.8a15.5,15.5,0,0,0,3.2,9.5c7.9,10.6,38.3,45,96.8,45a128.7,128.7,0,0,0,52-10.5V240a8,8,0,0,0,16,0V201.1A111.8,111.8,0,0,0,224.8,175a15.5,15.5,0,0,0,3.2-9.5V115.7l23.8-12.6a8.1,8.1,0,0,0,0-14.2ZM128,204c-51.1,0-77.2-29.5-84-38.5V124.3l80.2,42.8a8.5,8.5,0,0,0,7.6,0L180,141.3v50.5C166.9,198.7,149.7,204,128,204Zm84-38.6a85.8,85.8,0,0,1-16,16.1V132.8l16-8.5Zm4.2-61.5h0l-28.2,15-56.2-30a8.1,8.1,0,1,0-7.6,14.2L171,128l-43,22.9-88.2-47h0L25,96,128,41.1,231,96Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M180,209.6a119.4,119.4,0,0,0,16-8.5V240a8,8,0,0,1-16,0ZM120.9,92.2a8.2,8.2,0,0,0,3.3,10.9L171,128l17-9.1-56.2-30A8.2,8.2,0,0,0,120.9,92.2Zm130.9-3.3-120-64a8.5,8.5,0,0,0-7.6,0l-120,64a8.1,8.1,0,0,0,0,14.2L28,115.7v49.8a15.5,15.5,0,0,0,3.2,9.5c7.9,10.6,38.3,45,96.8,45a128.7,128.7,0,0,0,52-10.5V132.8l-9-4.8-43,22.9-88.2-47h0L25,96,128,41.1,231,96l-14.8,7.9h0l-28.2,15,3.8,2a8.4,8.4,0,0,1,3.2,3.3,7.5,7.5,0,0,1,1,3.8v73.1A111.8,111.8,0,0,0,224.8,175a15.5,15.5,0,0,0,3.2-9.5V115.7l23.8-12.6a8.1,8.1,0,0,0,0-14.2Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M250.8,90.7l-120-64a6.1,6.1,0,0,0-5.6,0l-120,64a6,6,0,0,0,0,10.6L30,114.5v51a13.6,13.6,0,0,0,2.8,8.3C40.6,184.2,70.4,218,128,218c21.9,0,39.8-4.9,54-11.5V240a6,6,0,0,0,12,0V200a106.8,106.8,0,0,0,29.2-26.2,13.6,13.6,0,0,0,2.8-8.3v-51l24.8-13.2a6,6,0,0,0,0-10.6ZM128,206c-52,0-78.7-30.1-85.6-39.4a1.5,1.5,0,0,1-.4-1.1V120.9l83.2,44.4a6,6,0,0,0,5.6,0L182,138v55.1C168.5,200.3,150.7,206,128,206Zm86-40.5a1.5,1.5,0,0,1-.4,1.1A90.7,90.7,0,0,1,194,185.5V131.6l20-10.7Zm3.2-59.9h-.1L188,121.2,130.8,90.7a6,6,0,0,0-5.6,10.6L175.3,128,128,153.2,38.9,105.7h-.1L20.8,96,128,38.8,235.3,96Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M249.9,92.5l-120-64a3.7,3.7,0,0,0-3.8,0l-120,64a4,4,0,0,0,0,7L32,113.3v52.2a11.5,11.5,0,0,0,2.4,7.1C42,182.8,71.4,216,128,216c23.1,0,41.6-5.5,56-12.7V240a4,4,0,0,0,8,0V198.8a104,104,0,0,0,29.6-26.2,11.5,11.5,0,0,0,2.4-7.1V113.3l25.9-13.8a4,4,0,0,0,0-7ZM128,208c-52.9,0-80.1-30.7-87.2-40.2a3.8,3.8,0,0,1-.8-2.3V117.6l86.1,45.9a3.9,3.9,0,0,0,3.8,0L184,134.7v59.5C170.1,201.9,151.6,208,128,208Zm88-42.5a3.8,3.8,0,0,1-.8,2.3A95.9,95.9,0,0,1,192,189.4v-59l24-12.8Zm-28-42-58.1-31a4,4,0,1,0-3.8,7L179.5,128,128,155.5,16.5,96,128,36.5,239.5,96Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M251.8,88.9l-120-64a8.5,8.5,0,0,0-7.6,0l-120,64a8.1,8.1,0,0,0,0,14.2L28,115.7v49.8a15.5,15.5,0,0,0,3.2,9.5c7.9,10.6,38.3,45,96.8,45a128.7,128.7,0,0,0,52-10.5V240a8,8,0,0,0,16,0V201.1A111.8,111.8,0,0,0,224.8,175a15.5,15.5,0,0,0,3.2-9.5V115.7l23.8-12.6a8.1,8.1,0,0,0,0-14.2ZM128,204c-51.1,0-77.2-29.5-84-38.5V124.3l80.2,42.8a8.5,8.5,0,0,0,7.6,0L180,141.3v50.5C166.9,198.7,149.7,204,128,204Zm84-38.6a85.8,85.8,0,0,1-16,16.1V132.8l16-8.5Zm4.2-61.5h0l-28.2,15-56.2-30a8.1,8.1,0,1,0-7.6,14.2L171,128l-43,22.9-88.2-47h0L25,96,128,41.1,231,96Z" />
        </>
      )}
    </svg>
  );
}

export function PhGraph(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M200,152a36.1,36.1,0,0,0-18.4,5.1l-19.8-16.6A36.9,36.9,0,0,0,164,128v-1.1l8.7-3.4A36,36,0,1,0,164,100v1.1l-8.7,3.4A36.2,36.2,0,0,0,128,92h-2.8l-4.5-10A35.5,35.5,0,0,0,132,56,36,36,0,1,0,96,92h2.8l4.5,10A35.5,35.5,0,0,0,92,128a36.2,36.2,0,0,0,2.7,13.6L73.4,160.5A35.5,35.5,0,0,0,56,156a36.1,36.1,0,1,0,33.3,22.4l21.3-18.9A35.5,35.5,0,0,0,128,164a36.1,36.1,0,0,0,18.4-5.1l19.8,16.6A36.9,36.9,0,0,0,164,188a36,36,0,1,0,36-36Zm0-64a12,12,0,1,1-12,12A12,12,0,0,1,200,88ZM84,56A12,12,0,1,1,96,68,12,12,0,0,1,84,56ZM56,204a12,12,0,1,1,12-12A12,12,0,0,1,56,204Zm60-76a12,12,0,1,1,12,12A12,12,0,0,1,116,128Zm84,72a12,12,0,1,1,12-12A12,12,0,0,1,200,200Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M152,128a24,24,0,1,1-24-24A23.9,23.9,0,0,1,152,128Z"
            opacity="0.2"
          />
          <path d="M200,152a31.7,31.7,0,0,0-19.5,6.7l-23.1-18A31.7,31.7,0,0,0,160,128a16.2,16.2,0,0,0-.1-2.2l13.3-4.4A31.9,31.9,0,1,0,168,104a16.2,16.2,0,0,0,.1,2.2l-13.3,4.4A31.9,31.9,0,0,0,128,96a45.5,45.5,0,0,0-5.3.4L115.9,81A31.7,31.7,0,0,0,128,56,32,32,0,1,0,96,88a45.5,45.5,0,0,0,5.3-.4l6.8,15.4A31.7,31.7,0,0,0,96,128a32.4,32.4,0,0,0,3.5,14.6L73.8,165.4A32,32,0,1,0,88,192a32.4,32.4,0,0,0-3.5-14.6l25.7-22.8a31.9,31.9,0,0,0,37.3-1.3l23.1,18A31.7,31.7,0,0,0,168,184a32,32,0,1,0,32-32Zm0-64a16,16,0,1,1-16,16A16,16,0,0,1,200,88ZM80,56A16,16,0,1,1,96,72,16,16,0,0,1,80,56ZM56,208a16,16,0,1,1,16-16A16,16,0,0,1,56,208Zm56-80a16,16,0,1,1,16,16A16,16,0,0,1,112,128Zm88,72a16,16,0,1,1,16-16A16,16,0,0,1,200,200Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M200,152a31.7,31.7,0,0,0-19.5,6.7l-23.1-18A31.7,31.7,0,0,0,160,128a16.2,16.2,0,0,0-.1-2.2l13.3-4.4A31.9,31.9,0,1,0,168,104a16.2,16.2,0,0,0,.1,2.2l-13.3,4.4A31.9,31.9,0,0,0,128,96a45.5,45.5,0,0,0-5.3.4L115.9,81A31.7,31.7,0,0,0,128,56,32,32,0,1,0,96,88a45.5,45.5,0,0,0,5.3-.4l6.8,15.4A31.7,31.7,0,0,0,96,128a32.4,32.4,0,0,0,3.5,14.6L73.8,165.4A32,32,0,1,0,88,192a32.4,32.4,0,0,0-3.5-14.6l25.7-22.8a31.9,31.9,0,0,0,37.3-1.3l23.1,18A31.7,31.7,0,0,0,168,184a32,32,0,1,0,32-32Zm0-64a16,16,0,1,1-16,16A16,16,0,0,1,200,88ZM80,56A16,16,0,1,1,96,72,16,16,0,0,1,80,56ZM56,208a16,16,0,1,1,16-16A16,16,0,0,1,56,208Zm144-8a16,16,0,1,1,16-16A16,16,0,0,1,200,200Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M200,154a29.8,29.8,0,0,0-19.5,7.2l-25.6-19.9A29.6,29.6,0,0,0,158,128c0-1.2-.1-2.4-.2-3.6L174,119a30,30,0,1,0-4-15c0,1.2.1,2.4.2,3.6L154,113a30,30,0,0,0-26-15,28.1,28.1,0,0,0-6.5.7l-8.1-18.3A30,30,0,1,0,96,86a28.1,28.1,0,0,0,6.5-.7l8.1,18.3A29.9,29.9,0,0,0,98,128a29.3,29.3,0,0,0,4.1,15L74,168a29.7,29.7,0,0,0-18-6,30,30,0,1,0,30,30,29.3,29.3,0,0,0-4.1-15L110,152a30,30,0,0,0,37.5-1.2l25.6,19.9A29.6,29.6,0,0,0,170,184a30,30,0,1,0,30-30Zm0-68a18,18,0,1,1-18,18A18.1,18.1,0,0,1,200,86ZM78,56A18,18,0,1,1,96,74,18.1,18.1,0,0,1,78,56ZM56,210a18,18,0,1,1,18-18A18.1,18.1,0,0,1,56,210Zm72-64a18,18,0,1,1,18-18A18.1,18.1,0,0,1,128,146Zm72,56a18,18,0,1,1,18-18A18.1,18.1,0,0,1,200,202Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M200,156a28.1,28.1,0,0,0-19.4,7.8l-28.3-22A26.9,26.9,0,0,0,156,128a27.3,27.3,0,0,0-.5-5l19.5-6.4a28,28,0,1,0-3-12.6,27.3,27.3,0,0,0,.5,5L153,115.4A28.1,28.1,0,0,0,128,100a27.4,27.4,0,0,0-7.6,1.1l-9.5-21.4A28,28,0,1,0,96,84a27.4,27.4,0,0,0,7.6-1.1l9.5,21.4a28,28,0,0,0-8.5,39.1L74,170.6A27.6,27.6,0,0,0,56,164a28,28,0,1,0,23.4,12.6L110,149.4a27.6,27.6,0,0,0,18,6.6,28.1,28.1,0,0,0,19.4-7.8l28.3,22A26.9,26.9,0,0,0,172,184a28,28,0,1,0,28-28Zm0-72a20,20,0,1,1-20,20A20.1,20.1,0,0,1,200,84ZM76,56A20,20,0,1,1,96,76,20.1,20.1,0,0,1,76,56ZM56,212a20,20,0,1,1,20-20A20.1,20.1,0,0,1,56,212Zm72-64a20,20,0,1,1,20-20A20.1,20.1,0,0,1,128,148Zm72,56a20,20,0,1,1,20-20A20.1,20.1,0,0,1,200,204Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M200,152a31.7,31.7,0,0,0-19.5,6.7l-23.1-18A31.7,31.7,0,0,0,160,128a16.2,16.2,0,0,0-.1-2.2l13.3-4.4A31.9,31.9,0,1,0,168,104a16.2,16.2,0,0,0,.1,2.2l-13.3,4.4A31.9,31.9,0,0,0,128,96a45.5,45.5,0,0,0-5.3.4L115.9,81A31.7,31.7,0,0,0,128,56,32,32,0,1,0,96,88a45.5,45.5,0,0,0,5.3-.4l6.8,15.4A31.7,31.7,0,0,0,96,128a32.4,32.4,0,0,0,3.5,14.6L73.8,165.4A32,32,0,1,0,88,192a32.4,32.4,0,0,0-3.5-14.6l25.7-22.8a31.9,31.9,0,0,0,37.3-1.3l23.1,18A31.7,31.7,0,0,0,168,184a32,32,0,1,0,32-32Zm0-64a16,16,0,1,1-16,16A16,16,0,0,1,200,88ZM80,56A16,16,0,1,1,96,72,16,16,0,0,1,80,56ZM56,208a16,16,0,1,1,16-16A16,16,0,0,1,56,208Zm56-80a16,16,0,1,1,16,16A16,16,0,0,1,112,128Zm88,72a16,16,0,1,1,16-16A16,16,0,0,1,200,200Z" />
        </>
      )}
    </svg>
  );
}

export function PhGridFour(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M204,32H52A20.1,20.1,0,0,0,32,52V204a20.1,20.1,0,0,0,20,20H204a20.1,20.1,0,0,0,20-20V52A20.1,20.1,0,0,0,204,32Zm-4,84H140V56h60ZM116,56v60H56V56ZM56,140h60v60H56Zm84,60V140h60v60Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M128,128H44V52a8,8,0,0,1,8-8h76Z" />
          </g>
          <path d="M204,36H52A16,16,0,0,0,36,52V204a16,16,0,0,0,16,16H204a16,16,0,0,0,16-16V52A16,16,0,0,0,204,36Zm0,84H136V52h68ZM120,52v68H52V52ZM52,136h68v68H52Zm152,68H136V136h68v68Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M204,36H52A16,16,0,0,0,36,52V204a16,16,0,0,0,16,16H204a16,16,0,0,0,16-16V52A16,16,0,0,0,204,36Zm0,92H128V52h76ZM52,128h76v76H52Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M204,38H52A14,14,0,0,0,38,52V204a14,14,0,0,0,14,14H204a14,14,0,0,0,14-14V52A14,14,0,0,0,204,38Zm2,14v70H134V50h70A2,2,0,0,1,206,52ZM52,50h70v72H50V52A2,2,0,0,1,52,50ZM50,204V134h72v72H52A2,2,0,0,1,50,204Zm154,2H134V134h72v70A2,2,0,0,1,204,206Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M204,40H52A12,12,0,0,0,40,52V204a12,12,0,0,0,12,12H204a12,12,0,0,0,12-12V52A12,12,0,0,0,204,40Zm4,12v72H132V48h72A4,4,0,0,1,208,52ZM52,48h72v76H48V52A4,4,0,0,1,52,48ZM48,204V132h76v76H52A4,4,0,0,1,48,204Zm156,4H132V132h76v72A4,4,0,0,1,204,208Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M204,36H52A16,16,0,0,0,36,52V204a16,16,0,0,0,16,16H204a16,16,0,0,0,16-16V52A16,16,0,0,0,204,36Zm0,84H136V52h68ZM120,52v68H52V52ZM52,136h68v68H52Zm152,68H136V136h68v68Z" />
        </>
      )}
    </svg>
  );
}

export function PhHamburger(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M223.5,140.9,188,155.1l-35.5-14.2a11.7,11.7,0,0,0-9,0L108,155.1,72.5,140.9a11.7,11.7,0,0,0-9,0l-40,16a12,12,0,1,0,9,22.2l3.5-1.4V184a44,44,0,0,0,44,44h96a44,44,0,0,0,44-44V168.1l12.5-5a12,12,0,0,0-9-22.2ZM196,184a20.1,20.1,0,0,1-20,20H80a20.1,20.1,0,0,1-20-20V168.1l8-3.2,35.5,14.2a11.7,11.7,0,0,0,9,0L148,164.9l35.5,14.2a11.7,11.7,0,0,0,9,0l3.5-1.4Zm36-84H219.2a92,92,0,0,0-182.4,0H24a12,12,0,0,0,0,24H232a12,12,0,0,0,0-24ZM128,44a68.1,68.1,0,0,1,66.9,56H61.1A68.1,68.1,0,0,1,128,44Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M207.2,96H48.8A8,8,0,0,1,41,86.3C47.4,55.5,83.9,32,128,32s80.6,23.5,87,54.3A8,8,0,0,1,207.2,96ZM148,160l-40,16L68,160l-20,8v16a32,32,0,0,0,32,32h96a32,32,0,0,0,32-32V168l-20,8Z"
            opacity="0.2"
          />
          <path d="M48.8,104H207.2a16,16,0,0,0,15.6-19.4C215.5,49.5,175.6,24,128,24S40.5,49.5,33.2,84.6A16,16,0,0,0,48.8,104ZM128,40c39.4,0,73.4,20.6,79.2,47.9H48.8C54.6,60.6,88.6,40,128,40Zm97,112.6-37,14.8-37-14.8a7.8,7.8,0,0,0-6,0l-37,14.8L71,152.6a7.8,7.8,0,0,0-6,0l-40,16A8,8,0,0,0,28,184a8,8,0,0,0,3-.6l9-3.6V184a40,40,0,0,0,40,40h96a40,40,0,0,0,40-40V173.4l15-6a8,8,0,0,0-6-14.8ZM200,184a24.1,24.1,0,0,1-24,24H80a24.1,24.1,0,0,1-24-24V173.4l12-4.8,37,14.8a7.8,7.8,0,0,0,6,0l37-14.8,37,14.8a7.8,7.8,0,0,0,6,0l9-3.6Zm40-56a8,8,0,0,1-8,8H24a8,8,0,0,1,0-16H232A8,8,0,0,1,240,128Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M36.4,98.1a16.3,16.3,0,0,1-3.2-13.5C40.5,49.5,80.4,24,128,24s87.5,25.5,94.8,60.6A16,16,0,0,1,207.2,104H48.8A16.2,16.2,0,0,1,36.4,98.1ZM225,152.6l-37,14.8-37-14.8a7.8,7.8,0,0,0-6,0l-37,14.8L71,152.6a7.8,7.8,0,0,0-6,0l-40,16A8,8,0,0,0,28,184a8,8,0,0,0,3-.6l9-3.6V184a40,40,0,0,0,40,40h96a40,40,0,0,0,40-40V173.4l15-6a8,8,0,0,0-6-14.8Zm7-32.6H24a8,8,0,0,0,0,16H232a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M48.8,102H207.2A13.7,13.7,0,0,0,218,96.8,14.3,14.3,0,0,0,220.9,85c-3.5-16.5-14.9-31.6-32.1-42.5S150.3,26,128,26,84.1,31.9,67.2,42.5,38.6,68.5,35.1,85A14.3,14.3,0,0,0,38,96.8,13.7,13.7,0,0,0,48.8,102ZM46.9,87.5C52.8,59.3,87.7,38,128,38s75.2,21.3,81.1,49.5a2.2,2.2,0,0,1-.4,1.8,2.1,2.1,0,0,1-1.5.7H48.8a2.1,2.1,0,0,1-1.5-.7A2.2,2.2,0,0,1,46.9,87.5Zm178.9,66.9L188,169.5l-37.8-15.1a6.6,6.6,0,0,0-4.4,0L108,169.5,70.2,154.4a6.6,6.6,0,0,0-4.4,0l-40,16a6.1,6.1,0,0,0-3.4,7.8A6,6,0,0,0,28,182a5.7,5.7,0,0,0,2.2-.4L42,176.9V184a38,38,0,0,0,38,38h96a38,38,0,0,0,38-38V172.1l16.2-6.5a6,6,0,1,0-4.4-11.2ZM202,184a26.1,26.1,0,0,1-26,26H80a26.1,26.1,0,0,1-26-26V172.1l14-5.6,37.8,15.1a6.6,6.6,0,0,0,4.4,0L148,166.5l37.8,15.1a6.6,6.6,0,0,0,4.4,0l11.8-4.7Zm36-56a6,6,0,0,1-6,6H24a6,6,0,0,1,0-12H232A6,6,0,0,1,238,128Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M48.8,100H207.2a11.9,11.9,0,0,0,11.7-14.5c-3.4-16.1-14.4-30.7-31.2-41.3S149.9,28,128,28,84.9,33.8,68.3,44.2,40.5,69.4,37.1,85.5A11.9,11.9,0,0,0,48.8,100ZM44.9,87.1C51,58,86.7,36,128,36s77,22,83.1,51.1a4.5,4.5,0,0,1-.8,3.4,4,4,0,0,1-3.1,1.5H48.8a4,4,0,0,1-3.1-1.5A4.5,4.5,0,0,1,44.9,87.1Zm181.6,69.2L188,171.7l-38.5-15.4a3.9,3.9,0,0,0-3,0L108,171.7,69.5,156.3a3.9,3.9,0,0,0-3,0l-40,16a4,4,0,1,0,3,7.4L44,173.9V184a36,36,0,0,0,36,36h96a36,36,0,0,0,36-36V170.7l17.5-7a4,4,0,1,0-3-7.4ZM204,184a28.1,28.1,0,0,1-28,28H80a28.1,28.1,0,0,1-28-28V170.7l16-6.4,38.5,15.4a3.9,3.9,0,0,0,3,0L148,164.3l38.5,15.4a3.9,3.9,0,0,0,3,0l14.5-5.8Zm32-56a4,4,0,0,1-4,4H24a4,4,0,0,1,0-8H232A4,4,0,0,1,236,128Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M48.8,104H207.2a16,16,0,0,0,15.6-19.4C215.5,49.5,175.6,24,128,24S40.5,49.5,33.2,84.6A16,16,0,0,0,48.8,104ZM128,40c39.4,0,73.4,20.6,79.2,47.9H48.8C54.6,60.6,88.6,40,128,40Zm97,112.6-37,14.8-37-14.8a7.8,7.8,0,0,0-6,0l-37,14.8L71,152.6a7.8,7.8,0,0,0-6,0l-40,16A8,8,0,0,0,28,184a8,8,0,0,0,3-.6l9-3.6V184a40,40,0,0,0,40,40h96a40,40,0,0,0,40-40V173.4l15-6a8,8,0,0,0-6-14.8ZM200,184a24.1,24.1,0,0,1-24,24H80a24.1,24.1,0,0,1-24-24V173.4l12-4.8,37,14.8a7.8,7.8,0,0,0,6,0l37-14.8,37,14.8a7.8,7.8,0,0,0,6,0l9-3.6Zm40-56a8,8,0,0,1-8,8H24a8,8,0,0,1,0-16H232A8,8,0,0,1,240,128Z" />
        </>
      )}
    </svg>
  );
}

export function PhHandEye(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M144,184a16,16,0,1,1-16-16A16,16,0,0,1,144,184Zm35.5-19.3v-.4C175.6,151.6,160.2,128,128,128s-47.6,23.6-51.4,36.3v.4h0A12.6,12.6,0,0,0,76,168a12,12,0,0,0,23.5,3.3h0c.6-2,6.6-19.4,28.5-19.4s27.9,17.4,28.5,19.4h0A12,12,0,0,0,180,168a12.6,12.6,0,0,0-.5-3.3ZM220,112v40a92,92,0,0,1-184,0V68A32.1,32.1,0,0,1,68,36a35,35,0,0,1,8,1V36a32,32,0,0,1,60.8-14A32.1,32.1,0,0,1,180,52V81a35,35,0,0,1,8-1A32.1,32.1,0,0,1,220,112Zm-24,0a8,8,0,0,0-16,0v4a12,12,0,0,1-24,0V52a8,8,0,0,0-16,0v52a12,12,0,0,1-24,0V36a8,8,0,0,0-16,0v76a12,12,0,0,1-24,0V68a8,8,0,0,0-16,0v84a68,68,0,0,0,136,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M188,92a20.1,20.1,0,0,0-20,20V52a20,20,0,0,0-40,0V36a20,20,0,0,0-40,0V68a20,20,0,0,0-40,0v84a80,80,0,0,0,160,0V112A20.1,20.1,0,0,0,188,92Zm-14.6,80.4c-5.7,8.8-20.8,27.6-45.4,27.6s-39.7-18.8-45.4-27.6a8.2,8.2,0,0,1,0-8.8c5.7-8.8,20.8-27.6,45.4-27.6s39.7,18.8,45.4,27.6A8.2,8.2,0,0,1,173.4,172.4Z"
            opacity="0.2"
          />
          <path d="M128,128c-28.7,0-46,21.8-52.1,31.2a16.1,16.1,0,0,0,0,17.6C82,186.2,99.3,208,128,208s46-21.8,52.1-31.2a16.1,16.1,0,0,0,0-17.6C174,149.8,156.7,128,128,128Zm0,64c-20.8,0-34-16.8-38.7-24h0c4.7-7.2,17.9-24,38.7-24s34,16.8,38.7,24C162,175.2,148.8,192,128,192ZM188,84a28.2,28.2,0,0,0-12,2.7V52a28.1,28.1,0,0,0-28-28,27.8,27.8,0,0,0-13.4,3.4A28,28,0,0,0,80,36v6.7A28,28,0,0,0,40,68v84a88,88,0,0,0,176,0V112A28.1,28.1,0,0,0,188,84Zm12,68a72,72,0,0,1-144,0V68a12,12,0,0,1,24,0v44a8,8,0,0,0,16,0V36a12,12,0,0,1,24,0v68a8,8,0,0,0,16,0V52a12,12,0,0,1,24,0v64a8,8,0,0,0,16,0v-4a12,12,0,0,1,24,0Zm-60,16a12,12,0,1,1-12-12A12,12,0,0,1,140,168Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M188,84a28.2,28.2,0,0,0-12,2.7V52a28.1,28.1,0,0,0-28-28,27.8,27.8,0,0,0-13.4,3.4A28,28,0,0,0,80,36v6.7A28,28,0,0,0,40,68v84a88,88,0,0,0,176,0V112A28.1,28.1,0,0,0,188,84Zm12,68a72,72,0,0,1-144,0V68a12,12,0,0,1,24,0v44a8,8,0,0,0,16,0V36a12,12,0,0,1,24,0v68a8,8,0,0,0,16,0V52a12,12,0,0,1,24,0v64a8,8,0,0,0,16,0v-4a12,12,0,0,1,24,0Zm-72-16c-26.7,0-43,20.6-48.8,29.4a12.1,12.1,0,0,0,0,13.2C85,187.4,101.3,208,128,208s43-20.6,48.8-29.4a12.1,12.1,0,0,0,0-13.2C171,156.6,154.7,136,128,136Zm0,52a16,16,0,1,1,16-16A16,16,0,0,1,128,188Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,130c-27.7,0-44.5,21.2-50.4,30.3a13.9,13.9,0,0,0,0,15.4c5.9,9.1,22.7,30.3,50.4,30.3s44.5-21.2,50.4-30.3a13.9,13.9,0,0,0,0-15.4C172.5,151.2,155.7,130,128,130Zm40.4,39.1c-4.9,7.5-18.6,24.9-40.4,24.9s-35.5-17.4-40.4-24.9a2.2,2.2,0,0,1,0-2.2h0c4.9-7.5,18.6-24.9,40.4-24.9s35.5,17.4,40.4,24.9A2.2,2.2,0,0,1,168.4,169.1ZM188,86a25.6,25.6,0,0,0-14,4.1V52a26,26,0,0,0-40.6-21.5A26,26,0,0,0,82,36V46.1A25.6,25.6,0,0,0,68,42,26.1,26.1,0,0,0,42,68v84a86,86,0,0,0,172,0V112A26.1,26.1,0,0,0,188,86Zm14,66a74,74,0,0,1-148,0V68a14,14,0,0,1,28,0v44a6,6,0,0,0,12,0V36a14,14,0,0,1,28,0v68a6,6,0,0,0,12,0V52a14,14,0,0,1,28,0v64a6,6,0,0,0,12,0v-4a14,14,0,0,1,28,0Zm-64,16a10,10,0,1,1-10-10A10,10,0,0,1,138,168Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,132c-26.7,0-43,20.6-48.8,29.4a12.1,12.1,0,0,0,0,13.2C85,183.4,101.3,204,128,204s43-20.6,48.8-29.4a12.1,12.1,0,0,0,0-13.2C171,152.6,154.7,132,128,132Zm42,38.2c-5,7.8-19.2,25.8-42,25.8s-37-18-42-25.8a3.9,3.9,0,0,1,0-4.4c5-7.8,19.2-25.8,42-25.8s37,18,42,25.8A3.9,3.9,0,0,1,170,170.2ZM188,88a23.9,23.9,0,0,0-16,6.1V52a24.1,24.1,0,0,0-24-24,23.8,23.8,0,0,0-16.1,6.2A24,24,0,0,0,84,36V50.1A24,24,0,0,0,44,68v84a84,84,0,0,0,168,0V112A24.1,24.1,0,0,0,188,88Zm16,64a76,76,0,0,1-152,0V68a16,16,0,0,1,32,0v44a4,4,0,0,0,8,0V36a16,16,0,0,1,32,0v68a4,4,0,0,0,8,0V52a16,16,0,0,1,32,0v64a4,4,0,0,0,8,0v-4a16,16,0,0,1,32,0Zm-68,16a8,8,0,1,1-8-8A8,8,0,0,1,136,168Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,128c-28.7,0-46,21.8-52.1,31.2a16.1,16.1,0,0,0,0,17.6C82,186.2,99.3,208,128,208s46-21.8,52.1-31.2a16.1,16.1,0,0,0,0-17.6C174,149.8,156.7,128,128,128Zm0,64c-20.8,0-34-16.8-38.7-24h0c4.7-7.2,17.9-24,38.7-24s34,16.8,38.7,24C162,175.2,148.8,192,128,192ZM188,84a28.2,28.2,0,0,0-12,2.7V52a28.1,28.1,0,0,0-28-28,27.8,27.8,0,0,0-13.4,3.4A28,28,0,0,0,80,36v6.7A28,28,0,0,0,40,68v84a88,88,0,0,0,176,0V112A28.1,28.1,0,0,0,188,84Zm12,68a72,72,0,0,1-144,0V68a12,12,0,0,1,24,0v44a8,8,0,0,0,16,0V36a12,12,0,0,1,24,0v68a8,8,0,0,0,16,0V52a12,12,0,0,1,24,0v64a8,8,0,0,0,16,0v-4a12,12,0,0,1,24,0Zm-60,16a12,12,0,1,1-12-12A12,12,0,0,1,140,168Z" />
        </>
      )}
    </svg>
  );
}

export function PhHandFist(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M200,76H188V64a36,36,0,0,0-60-26.8A35.9,35.9,0,0,0,69.3,54.5,36.6,36.6,0,0,0,56,52,36,36,0,0,0,20,88v40a108,108,0,0,0,216,0V112A36,36,0,0,0,200,76ZM152,52a12,12,0,0,1,12,12V76H140V64A12,12,0,0,1,152,52ZM92,64a12,12,0,0,1,24,0v40a12,12,0,0,1-24,0V64ZM44,88a12,12,0,0,1,24,0v16a12,12,0,0,1-24,0Zm168,40A84,84,0,0,1,44.6,138.2,36.2,36.2,0,0,0,80,130.8a36,36,0,0,0,24,9.2,35.6,35.6,0,0,0,19.6-5.9,35.3,35.3,0,0,0,6.4,6.4A51.8,51.8,0,0,0,116,176a12,12,0,0,0,24,0,28.1,28.1,0,0,1,28-28,12,12,0,0,0,0-24H152a12,12,0,0,1-12-12V100h60a12,12,0,0,1,12,12Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M128,64a24,24,0,0,1,48,0V88h24a23.9,23.9,0,0,1,24,24v16a96,96,0,0,1-192,0V88a24,24,0,0,1,48,0V64a24,24,0,0,1,48,0" />
          </g>
          <path d="M200,80H184V64a32,32,0,0,0-56-21.1A31.8,31.8,0,0,0,104,32,32.1,32.1,0,0,0,72.2,60.4,32,32,0,0,0,24,88v40a104,104,0,0,0,208,0V112A32.1,32.1,0,0,0,200,80ZM152,48a16,16,0,0,1,16,16V80H136V64A16,16,0,0,1,152,48ZM88,64a16,16,0,0,1,32,0v40a16,16,0,0,1-32,0V64ZM40,88a16,16,0,0,1,32,0v16a16,16,0,0,1-32,0Zm176,40a88,88,0,0,1-175.9,3.8A32,32,0,0,0,80,125.1a31.9,31.9,0,0,0,44.6,3.4,32.3,32.3,0,0,0,11.8,11.4A47.7,47.7,0,0,0,120,176a8,8,0,0,0,16,0,32.1,32.1,0,0,1,32-32,8,8,0,0,0,0-16H152a16,16,0,0,1-16-16V96h64a16,16,0,0,1,16,16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M200,80H184V64a32,32,0,0,0-56-21.1A31.8,31.8,0,0,0,104,32,32.1,32.1,0,0,0,72.2,60.4,32,32,0,0,0,24,88v40a104,104,0,0,0,208,0V112A32.1,32.1,0,0,0,200,80ZM152,48a16,16,0,0,1,16,16V80H136V64A16,16,0,0,1,152,48ZM88,64a16,16,0,0,1,32,0v40a16,16,0,0,1-32,0V64ZM40,88a16,16,0,0,1,32,0v16a16,16,0,0,1-32,0Zm88,128a88,88,0,0,1-87.9-84.2A32,32,0,0,0,80,125.1a31.9,31.9,0,0,0,44.6,3.4,32.3,32.3,0,0,0,11.8,11.4A47.7,47.7,0,0,0,120,176a8,8,0,0,0,16,0,32.1,32.1,0,0,1,32-32,8,8,0,0,0,0-16H152a16,16,0,0,1-16-16V96h64a16,16,0,0,1,16,16v16A88.1,88.1,0,0,1,128,216Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M200,82H182V64a30,30,0,0,0-54-18A30,30,0,0,0,74,64h0a29.7,29.7,0,0,0-18-6A30.1,30.1,0,0,0,26,88v40a102,102,0,0,0,204,0V112A30.1,30.1,0,0,0,200,82ZM152,46a18.1,18.1,0,0,1,18,18V82H136l-2,.2V64A18.1,18.1,0,0,1,152,46ZM86,64a18,18,0,0,1,36,0v40a18,18,0,0,1-36,0V64ZM38,88a18,18,0,0,1,36,0v16a18,18,0,0,1-36,0Zm180,40a90,90,0,0,1-180,0h0a30,30,0,0,0,42-6,30,30,0,0,0,45.1,3.3A30.3,30.3,0,0,0,140,139.5,46.1,46.1,0,0,0,122,176a6,6,0,0,0,12,0,34.1,34.1,0,0,1,34-34,6,6,0,0,0,0-12H152a18.1,18.1,0,0,1-18-18V96a2,2,0,0,1,2-2h64a18.1,18.1,0,0,1,18,18Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M200,84H180V64a28,28,0,0,0-52-14.4A28,28,0,0,0,76,64v4.4A28,28,0,0,0,28,88v40a100,100,0,0,0,200,0V112A28.1,28.1,0,0,0,200,84ZM152,44a20.1,20.1,0,0,1,20,20V84H136a13.4,13.4,0,0,0-4,.7V64A20.1,20.1,0,0,1,152,44ZM84,64a20,20,0,0,1,40,0v40a20,20,0,0,1-40,0V64ZM36,88a20,20,0,0,1,40,0v16a20,20,0,0,1-40,0Zm184,40a92,92,0,0,1-184,0v-4.4a28,28,0,0,0,44-5.2A28.1,28.1,0,0,0,104,132a27.6,27.6,0,0,0,21.7-10.4,28,28,0,0,0,18.6,17.3A44.2,44.2,0,0,0,124,176a4,4,0,0,0,8,0,36,36,0,0,1,36-36,4,4,0,0,0,0-8H152a20.1,20.1,0,0,1-20-20V96a4,4,0,0,1,4-4h64a20.1,20.1,0,0,1,20,20Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M200,80H184V64a32,32,0,0,0-56-21.1A31.8,31.8,0,0,0,104,32,32.1,32.1,0,0,0,72.2,60.4,32,32,0,0,0,24,88v40a104,104,0,0,0,208,0V112A32.1,32.1,0,0,0,200,80ZM152,48a16,16,0,0,1,16,16V80H136V64A16,16,0,0,1,152,48ZM88,64a16,16,0,0,1,32,0v40a16,16,0,0,1-32,0V64ZM40,88a16,16,0,0,1,32,0v16a16,16,0,0,1-32,0Zm176,40a88,88,0,0,1-175.9,3.8A32,32,0,0,0,80,125.1a31.9,31.9,0,0,0,44.6,3.4,32.3,32.3,0,0,0,11.8,11.4A47.7,47.7,0,0,0,120,176a8,8,0,0,0,16,0,32.1,32.1,0,0,1,32-32,8,8,0,0,0,0-16H152a16,16,0,0,1-16-16V96h64a16,16,0,0,1,16,16Z" />
        </>
      )}
    </svg>
  );
}

export function PhHandGrabbing(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M188,76a32.2,32.2,0,0,0-11.2,2A32.2,32.2,0,0,0,148,60a31.8,31.8,0,0,0-20,7A32,32,0,0,0,76,92v12H68a32.1,32.1,0,0,0-32,32v16a92,92,0,0,0,184,0V108A32.1,32.1,0,0,0,188,76Zm8,76a68,68,0,0,1-136,0V136a8,8,0,0,1,8-8h8v20a12,12,0,0,0,24,0V92a8,8,0,0,1,16,0v32a12,12,0,0,0,24,0V92a8,8,0,0,1,16,0v32a12,12,0,0,0,24,0V108a8,8,0,0,1,16,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M88,116H68a20.1,20.1,0,0,0-20,20v16a80,80,0,0,0,160,0V108a20,20,0,0,0-40,0V92a20,20,0,0,0-40,0,20,20,0,0,0-40,0Z" />
          </g>
          <path d="M188,80a27.8,27.8,0,0,0-13.4,3.4,28,28,0,0,0-46.6-11A28,28,0,0,0,80,92v16H68a28.1,28.1,0,0,0-28,28v16a88,88,0,0,0,176,0V108A28.1,28.1,0,0,0,188,80Zm12,72a72,72,0,0,1-144,0V136a12,12,0,0,1,12-12H80v24a8,8,0,0,0,16,0V92a12,12,0,0,1,24,0v32a8,8,0,0,0,16,0V92a12,12,0,0,1,24,0v32a8,8,0,0,0,16,0V108a12,12,0,0,1,24,0Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M188,80a27.8,27.8,0,0,0-13.4,3.4,28,28,0,0,0-46.6-11A28,28,0,0,0,80,92v16H68a28.1,28.1,0,0,0-28,28v16a88,88,0,0,0,176,0V108A28.1,28.1,0,0,0,188,80Zm12,72a72,72,0,0,1-144,0V136a12,12,0,0,1,12-12H80v24a8,8,0,0,0,16,0V92a12,12,0,0,1,24,0v32a8,8,0,0,0,16,0V92a12,12,0,0,1,24,0v32a8,8,0,0,0,16,0V108a12,12,0,0,1,24,0Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M188,82a26.2,26.2,0,0,0-14.6,4.5A26,26,0,0,0,128,75.4,26,26,0,0,0,82,92v18H68a26.1,26.1,0,0,0-26,26v16a86,86,0,0,0,172,0V108A26.1,26.1,0,0,0,188,82Zm14,70a74,74,0,0,1-148,0V136a14,14,0,0,1,14-14H82v26a6,6,0,0,0,12,0V92a14,14,0,0,1,28,0v32a6,6,0,0,0,12,0V92a14,14,0,0,1,28,0v32a6,6,0,0,0,12,0V108a14,14,0,0,1,28,0Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M188,84a23.8,23.8,0,0,0-16.1,6.2A23.9,23.9,0,0,0,128,78.8,24,24,0,0,0,84,92v20H68a24.1,24.1,0,0,0-24,24v16a84,84,0,0,0,168,0V108A24.1,24.1,0,0,0,188,84Zm16,68a76,76,0,0,1-152,0V136a16,16,0,0,1,16-16H84v28a4,4,0,0,0,8,0V92a16,16,0,0,1,32,0v32a4,4,0,0,0,8,0V92a16,16,0,0,1,32,0v32a4,4,0,0,0,8,0V108a16,16,0,0,1,32,0Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M188,80a27.8,27.8,0,0,0-13.4,3.4,28,28,0,0,0-46.6-11A28,28,0,0,0,80,92v16H68a28.1,28.1,0,0,0-28,28v16a88,88,0,0,0,176,0V108A28.1,28.1,0,0,0,188,80Zm12,72a72,72,0,0,1-144,0V136a12,12,0,0,1,12-12H80v24a8,8,0,0,0,16,0V92a12,12,0,0,1,24,0v32a8,8,0,0,0,16,0V92a12,12,0,0,1,24,0v32a8,8,0,0,0,16,0V108a12,12,0,0,1,24,0Z" />
        </>
      )}
    </svg>
  );
}

export function PhHandPalm(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M188,80a35,35,0,0,0-8,1V52a32.1,32.1,0,0,0-43.2-30A32,32,0,0,0,76,36v1a35,35,0,0,0-8-1A32.1,32.1,0,0,0,36,68v84a92,92,0,0,0,184,0V112A32.1,32.1,0,0,0,188,80Zm8,72a68,68,0,0,1-136,0V68a8,8,0,0,1,16,0v44a12,12,0,0,0,24,0V36a8,8,0,0,1,16,0v68a12,12,0,0,0,24,0V52a8,8,0,0,1,16,0v69.4A52.1,52.1,0,0,0,116,172a12,12,0,0,0,24,0,28.1,28.1,0,0,1,28-28,12,12,0,0,0,12-12V112a8,8,0,0,1,16,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M208,112v40a80,80,0,0,1-160,0V68a20,20,0,0,1,40,0V36a20,20,0,0,1,40,0V52a20,20,0,0,1,40,0v60a20,20,0,0,1,40,0Z"
            opacity="0.2"
          />
          <path d="M188,84a28.2,28.2,0,0,0-12,2.7V52a28.1,28.1,0,0,0-28-28,27.8,27.8,0,0,0-13.4,3.4A28,28,0,0,0,80,36v6.7A28,28,0,0,0,40,68v84a88,88,0,0,0,176,0V112A28.1,28.1,0,0,0,188,84Zm12,68a72,72,0,0,1-144,0V68a12,12,0,0,1,24,0v44a8,8,0,0,0,16,0V36a12,12,0,0,1,24,0v68a8,8,0,0,0,16,0V52a12,12,0,0,1,24,0v72.7A48,48,0,0,0,120,172a8,8,0,0,0,16,0,32.1,32.1,0,0,1,32-32,8,8,0,0,0,8-8V112a12,12,0,0,1,24,0Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M188,84a28.2,28.2,0,0,0-12,2.7V52a28.1,28.1,0,0,0-28-28,27.8,27.8,0,0,0-13.4,3.4A28,28,0,0,0,80,36v6.7A28,28,0,0,0,40,68v84a88,88,0,0,0,176,0V112A28.1,28.1,0,0,0,188,84Zm12,68a72,72,0,0,1-144,0V68a12,12,0,0,1,24,0v44a8,8,0,0,0,16,0V36a12,12,0,0,1,24,0v68a8,8,0,0,0,16,0V52a12,12,0,0,1,24,0v72.7A48,48,0,0,0,120,172a8,8,0,0,0,16,0,32.1,32.1,0,0,1,32-32,8,8,0,0,0,8-8V112a12,12,0,0,1,24,0Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M188,86a25.6,25.6,0,0,0-14,4.1V52a26,26,0,0,0-40.6-21.5A26,26,0,0,0,82,36V46.1A25.6,25.6,0,0,0,68,42,26.1,26.1,0,0,0,42,68v84a86,86,0,0,0,172,0V112A26.1,26.1,0,0,0,188,86Zm14,66a74,74,0,0,1-148,0V68a14,14,0,0,1,28,0v44a6,6,0,0,0,12,0V36a14,14,0,0,1,28,0v68a6,6,0,0,0,12,0V52a14,14,0,0,1,28,0v74.4A46.1,46.1,0,0,0,122,172a6,6,0,0,0,12,0,34.1,34.1,0,0,1,34-34,6,6,0,0,0,6-6V112a14,14,0,0,1,28,0Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M188,88a23.9,23.9,0,0,0-16,6.1V52a24.1,24.1,0,0,0-24-24,23.8,23.8,0,0,0-16.1,6.2A24,24,0,0,0,84,36V50.1A24,24,0,0,0,44,68v84a84,84,0,0,0,168,0V112A24.1,24.1,0,0,0,188,88Zm16,64a76,76,0,0,1-152,0V68a16,16,0,0,1,32,0v44a4,4,0,0,0,8,0V36a16,16,0,0,1,32,0v68a4,4,0,0,0,8,0V52a16,16,0,0,1,32,0v76.2A44,44,0,0,0,124,172a4,4,0,0,0,8,0,36,36,0,0,1,36-36,4,4,0,0,0,4-4V112a16,16,0,0,1,32,0Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M188,84a28.2,28.2,0,0,0-12,2.7V52a28.1,28.1,0,0,0-28-28,27.8,27.8,0,0,0-13.4,3.4A28,28,0,0,0,80,36v6.7A28,28,0,0,0,40,68v84a88,88,0,0,0,176,0V112A28.1,28.1,0,0,0,188,84Zm12,68a72,72,0,0,1-144,0V68a12,12,0,0,1,24,0v44a8,8,0,0,0,16,0V36a12,12,0,0,1,24,0v68a8,8,0,0,0,16,0V52a12,12,0,0,1,24,0v72.7A48,48,0,0,0,120,172a8,8,0,0,0,16,0,32.1,32.1,0,0,1,32-32,8,8,0,0,0,8-8V112a12,12,0,0,1,24,0Z" />
        </>
      )}
    </svg>
  );
}

export function PhHandPointing(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M188,80a31.3,31.3,0,0,0-13,2.8A32.1,32.1,0,0,0,148,68a35,35,0,0,0-8,1V36a32,32,0,0,0-64,0v76.5a32,32,0,0,0-43.2-10.9,31.5,31.5,0,0,0-15,19.4,31.9,31.9,0,0,0,3,23.8c15.7,33.1,29.5,58.5,46.1,75.1S102.3,244,128,244a92.1,92.1,0,0,0,92-92V112A32.1,32.1,0,0,0,188,80Zm8,72a68.1,68.1,0,0,1-68,68c-36.2,0-53-16.8-85.7-85.9a5.6,5.6,0,0,1-.5-.8,8.6,8.6,0,0,1-.8-6.1,7.9,7.9,0,0,1,3.8-4.8,7.5,7.5,0,0,1,6-.8,7.6,7.6,0,0,1,4.9,3.7l21.9,38a12,12,0,0,0,22.4-6V36a8,8,0,0,1,16,0v68a12,12,0,0,0,24,0v-4a8,8,0,0,1,16,0v12a12,12,0,0,0,24,0,8,8,0,0,1,16,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M168,112V100a20,20,0,0,0-40,0V36a20,20,0,0,0-40,0V157.3l-21.9-38a20,20,0,0,0-34.7,20C64,208,83.8,232,128,232a80,80,0,0,0,80-80V112a20,20,0,0,0-40,0Z" />
          </g>
          <path d="M188,84a27.6,27.6,0,0,0-14.6,4.1A28.1,28.1,0,0,0,136,74.7V36a28,28,0,0,0-56,0v91.4l-7-12.1A28,28,0,0,0,24.3,143c32.5,68.4,54.1,97,103.7,97a88.1,88.1,0,0,0,88-88V112A28.1,28.1,0,0,0,188,84Zm12,68a72.1,72.1,0,0,1-72,72c-20.2,0-34.2-5.5-47-18.2S56.3,173,38.7,135.9l-.3-.6a11.6,11.6,0,0,1-1.2-9.1,11.8,11.8,0,0,1,5.6-7.3,12,12,0,0,1,9.1-1.2,11.6,11.6,0,0,1,7.2,5.6l22,38a8.1,8.1,0,0,0,9,3.7,7.9,7.9,0,0,0,5.9-7.7V36a12,12,0,0,1,24,0v68a8,8,0,0,0,16,0v-4a12,12,0,0,1,24,0v12a8,8,0,0,0,16,0,12,12,0,0,1,24,0Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M188,84a27.6,27.6,0,0,0-14.6,4.1A28.1,28.1,0,0,0,136,74.7V36a28,28,0,0,0-56,0v91.4l-7-12.1A28,28,0,0,0,24.3,143c32.5,68.4,54.1,97,103.7,97a88.1,88.1,0,0,0,88-88V112A28.1,28.1,0,0,0,188,84Zm12,68a72.1,72.1,0,0,1-72,72c-20.2,0-34.2-5.5-47-18.2S56.3,173,38.7,135.9l-.3-.6a11.6,11.6,0,0,1-1.2-9.1,11.8,11.8,0,0,1,5.6-7.3,12,12,0,0,1,9.1-1.2,11.6,11.6,0,0,1,7.2,5.6l22,38a8.1,8.1,0,0,0,9,3.7,7.9,7.9,0,0,0,5.9-7.7V36a12,12,0,0,1,24,0v68a8,8,0,0,0,16,0v-4a12,12,0,0,1,24,0v12a8,8,0,0,0,16,0,12,12,0,0,1,24,0Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M188,86a25.4,25.4,0,0,0-15.5,5.2A26.2,26.2,0,0,0,148,74a25.6,25.6,0,0,0-14,4.1V36a26,26,0,0,0-52,0v98.9L71.3,116.3a26,26,0,0,0-15.8-12.1,25.6,25.6,0,0,0-19.7,2.6,25.3,25.3,0,0,0-12.2,15.8,26.1,26.1,0,0,0,2.5,19.5C58.2,209.7,79.5,238,128,238a86.1,86.1,0,0,0,86-86V112A26.1,26.1,0,0,0,188,86Zm14,66a74.1,74.1,0,0,1-74,74c-38.8,0-57.5-18.4-91.1-89.3l-.3-.4a14.5,14.5,0,0,1-1.4-10.6,13.8,13.8,0,0,1,6.6-8.5,14,14,0,0,1,19.1,5.1l21.9,38a6.1,6.1,0,0,0,6.8,2.8,6,6,0,0,0,4.4-5.8V36a14,14,0,0,1,28,0v68a6,6,0,0,0,12,0v-4a14,14,0,0,1,28,0v12a6,6,0,0,0,12,0,14,14,0,0,1,28,0Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M188,88a24.1,24.1,0,0,0-16.6,6.7A24,24,0,0,0,132,82.1V36a24,24,0,0,0-48,0V142.3l-14.5-25a23.9,23.9,0,0,0-32.7-8.8,23.5,23.5,0,0,0-11.2,14.6,23.2,23.2,0,0,0,2.3,18C59.6,208,80.5,236,128,236a84.1,84.1,0,0,0,84-84V112A24.1,24.1,0,0,0,188,88Zm16,64a76.1,76.1,0,0,1-76,76c-37,0-56.6-13.7-93-90.4v-.3a16,16,0,0,1,5.9-21.9,15.9,15.9,0,0,1,21.8,5.9l21.9,38a4,4,0,0,0,4.5,1.8,3.9,3.9,0,0,0,3-3.8V36a16,16,0,0,1,32,0v68a4,4,0,0,0,8,0v-4a16,16,0,0,1,32,0v12a4,4,0,0,0,8,0,16,16,0,0,1,32,0Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M188,84a27.6,27.6,0,0,0-14.6,4.1A28.1,28.1,0,0,0,136,74.7V36a28,28,0,0,0-56,0v91.4l-7-12.1A28,28,0,0,0,24.3,143c32.5,68.4,54.1,97,103.7,97a88.1,88.1,0,0,0,88-88V112A28.1,28.1,0,0,0,188,84Zm12,68a72.1,72.1,0,0,1-72,72c-20.2,0-34.2-5.5-47-18.2S56.3,173,38.7,135.9l-.3-.6a11.6,11.6,0,0,1-1.2-9.1,11.8,11.8,0,0,1,5.6-7.3,12,12,0,0,1,9.1-1.2,11.6,11.6,0,0,1,7.2,5.6l22,38a8.1,8.1,0,0,0,9,3.7,7.9,7.9,0,0,0,5.9-7.7V36a12,12,0,0,1,24,0v68a8,8,0,0,0,16,0v-4a12,12,0,0,1,24,0v12a8,8,0,0,0,16,0,12,12,0,0,1,24,0Z" />
        </>
      )}
    </svg>
  );
}

export function PhHandSoap(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M188,97.7V92a36,36,0,0,0-36-36H140V36h28a4,4,0,0,1,4,4,12,12,0,0,0,24,0,28.1,28.1,0,0,0-28-28H104a12,12,0,0,0,0,24h12V56H104A36,36,0,0,0,68,92v5.7A44.1,44.1,0,0,0,36,140v76a20.1,20.1,0,0,0,20,20H200a20.1,20.1,0,0,0,20-20V140A44.1,44.1,0,0,0,188,97.7ZM104,80h48a12,12,0,0,1,12,12v4H92V92A12,12,0,0,1,104,80Zm92,132H60V140a20.1,20.1,0,0,1,20-20h96a20.1,20.1,0,0,1,20,20Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M208,136v80a8,8,0,0,1-8,8H56a8,8,0,0,1-8-8V136a32,32,0,0,1,32-32h96A32,32,0,0,1,208,136Z"
            opacity="0.2"
          />
          <path d="M184,96.8V88a32.1,32.1,0,0,0-32-32H136V32h32a8,8,0,0,1,8,8,8,8,0,0,0,16,0,24.1,24.1,0,0,0-24-24H104a8,8,0,0,0,0,16h16V56H104A32.1,32.1,0,0,0,72,88v8.8A40.1,40.1,0,0,0,40,136v80a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V136A40.1,40.1,0,0,0,184,96.8ZM104,72h48a16,16,0,0,1,16,16v8H88V88A16,16,0,0,1,104,72Zm96,144H56V136a24.1,24.1,0,0,1,24-24h96a24.1,24.1,0,0,1,24,24v80Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M184,96.8V88a32.1,32.1,0,0,0-32-32H136V32h32a8,8,0,0,1,8,8,8,8,0,0,0,16,0,24.1,24.1,0,0,0-24-24H104a8,8,0,0,0,0,16h16V56H104A32.1,32.1,0,0,0,72,88v8.8A40.1,40.1,0,0,0,40,136v80a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V136A40.1,40.1,0,0,0,184,96.8ZM168,96H88V88a16,16,0,0,1,16-16h48a16,16,0,0,1,16,16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M182,98.5V88a30.1,30.1,0,0,0-30-30H134V30h34a10,10,0,0,1,10,10,6,6,0,0,0,12,0,22.1,22.1,0,0,0-22-22H104a6,6,0,0,0,0,12h18V58H104A30.1,30.1,0,0,0,74,88V98.5A38.1,38.1,0,0,0,42,136v80a14,14,0,0,0,14,14H200a14,14,0,0,0,14-14V136A38.1,38.1,0,0,0,182,98.5ZM104,70h48a18.1,18.1,0,0,1,18,18V98H86V88A18.1,18.1,0,0,1,104,70Zm98,146a2,2,0,0,1-2,2H56a2,2,0,0,1-2-2V136a26.1,26.1,0,0,1,26-26h96a26.1,26.1,0,0,1,26,26Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M180,100.2V88a28.1,28.1,0,0,0-28-28H132V28h36a12,12,0,0,1,12,12,4,4,0,0,0,8,0,20.1,20.1,0,0,0-20-20H104a4,4,0,0,0,0,8h20V60H104A28.1,28.1,0,0,0,76,88v12.2A36.1,36.1,0,0,0,44,136v80a12,12,0,0,0,12,12H200a12,12,0,0,0,12-12V136A36.1,36.1,0,0,0,180,100.2ZM104,68h48a20.1,20.1,0,0,1,20,20v12H84V88A20.1,20.1,0,0,1,104,68ZM204,216a4,4,0,0,1-4,4H56a4,4,0,0,1-4-4V136a28.1,28.1,0,0,1,28-28h96a28.1,28.1,0,0,1,28,28Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M184,96.8V88a32.1,32.1,0,0,0-32-32H136V32h32a8,8,0,0,1,8,8,8,8,0,0,0,16,0,24.1,24.1,0,0,0-24-24H104a8,8,0,0,0,0,16h16V56H104A32.1,32.1,0,0,0,72,88v8.8A40.1,40.1,0,0,0,40,136v80a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V136A40.1,40.1,0,0,0,184,96.8ZM104,72h48a16,16,0,0,1,16,16v8H88V88A16,16,0,0,1,104,72Zm96,144H56V136a24.1,24.1,0,0,1,24-24h96a24.1,24.1,0,0,1,24,24v80Z" />
        </>
      )}
    </svg>
  );
}

export function PhHandWaving(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M203.7,67.3A32,32,0,0,0,160,55.6a31.7,31.7,0,0,0-6.5,4.9L139,35.4A32,32,0,0,0,95.3,23.7,32.5,32.5,0,0,0,86.6,31,35,35,0,0,0,77,26.6,32,32,0,0,0,41,73.5l.5.9A30.5,30.5,0,0,0,34,77.5a32.1,32.1,0,0,0-11.7,43.7l42,72.8a92,92,0,1,0,159.4-92Zm6,98.3A68,68,0,0,1,85.1,182l-42-72.8A8,8,0,0,1,46,98.3a8.1,8.1,0,0,1,11,2.9l22,38.1a5.7,5.7,0,0,0,.8,1.3l.3.3a2.7,2.7,0,0,0,.7.8l.3.3,1,.9h.1l1.1.7.4.2.9.5H85l1.3.4h.1l1.2.2h3.3a.1.1,0,0,1,.1-.1l1.2-.2h.5l.9-.4H94l1.4-.7a11.8,11.8,0,0,0,5.9-9.5,11.5,11.5,0,0,0-1.6-6.9l-38-65.8a8.4,8.4,0,0,1-.8-6,8.1,8.1,0,0,1,3.8-4.9,7.5,7.5,0,0,1,6-.8,8.3,8.3,0,0,1,4.9,3.7l34,58.9.7,1.1a11.9,11.9,0,0,0,13.9,4.1l1.8-.8a12.1,12.1,0,0,0,6-9.5,13,13,0,0,0-1.6-6.9l-26-45a8,8,0,0,1,13.8-8l30,51.9h0l4.7,8.2a52.1,52.1,0,0,0-9.3,63.8,12,12,0,0,0,20.8-12,28,28,0,0,1,10.2-38.2l1-.7a1.8,1.8,0,0,0,.7-.5l.3-.2.7-.7h.1l.7-.8h.1a5.6,5.6,0,0,1,.5-.8V117l.5-.8a.3.3,0,0,1,.1-.2,2,2,0,0,1,.4-.8.4.4,0,0,1,.1-.3l.6-2.3v-.2c0-.3.1-.6.1-.9v-1.4a3.4,3.4,0,0,0-.1-1h0a3.6,3.6,0,0,0-.2-1h0l-.3-1.1c0-.1-.1-.1-.1-.2l-.3-.9-.6-1L169,87.3a8.4,8.4,0,0,1-.8-6,8.1,8.1,0,0,1,3.8-4.9,8,8,0,0,1,10.9,2.9l20,34.7A67.4,67.4,0,0,1,209.7,165.6ZM234,63.4a12,12,0,0,1-6,1.6,11.8,11.8,0,0,1-10.4-6A48.2,48.2,0,0,0,176,35a12,12,0,0,1,0-24h0a72.3,72.3,0,0,1,62.4,36A12.1,12.1,0,0,1,234,63.4ZM76.1,247.7a11.9,11.9,0,0,1-9.2,4.3,12,12,0,0,1-7.7-2.8A131.7,131.7,0,0,1,29.7,214a12,12,0,0,1,20.8-12,109.7,109.7,0,0,0,24.1,28.8A11.9,11.9,0,0,1,76.1,247.7Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M184,217.3A80,80,0,0,1,74.7,188l-42-72.8a20,20,0,0,1,34.7-20l-16-27.7A20,20,0,0,1,86,47.5l8,13.9a20,20,0,0,1,34.6-20l30,51.9a20,20,0,0,1,34.7-20l20,34.7A80,80,0,0,1,184,217.3Z"
            opacity="0.2"
          />
          <path d="M220.2,104l-20-34.7a28.1,28.1,0,0,0-47.3-1.9l-17.3-30A28.1,28.1,0,0,0,97.3,27.1a29.4,29.4,0,0,0-9.9,9.6,27.9,27.9,0,0,0-11.5-6.2,27.1,27.1,0,0,0-21.2,2.8A27.9,27.9,0,0,0,44.4,71.5l3.4,5.8A28.5,28.5,0,0,0,36,81a28.1,28.1,0,0,0-10.2,38.2l42,72.8a88,88,0,1,0,152.4-88Zm-6.7,62.6A71.2,71.2,0,0,1,180,210.3,72.1,72.1,0,0,1,81.6,184l-42-72.8a12,12,0,0,1,20.8-12l22,38.1.6.9v.2l.5.5.2.2.7.6h.1l.7.5h.3l.6.3h.2l.9.3h.1l.8.2h2.2l.9-.2h.3l.6-.2h.3l.9-.4a8.1,8.1,0,0,0,2.9-11l-22-38.1h0l-16-27.7a12,12,0,0,1-1.2-9.1,11.8,11.8,0,0,1,5.6-7.3,12,12,0,0,1,9.1-1.2,12.5,12.5,0,0,1,7.3,5.6L87,65.4h.1l26,45a6,6,0,0,0,.9,1.3,8,8,0,0,0,12.9-9.3l-26-45a12,12,0,1,1,20.8-12l30,51.9h0l6.3,11a48.1,48.1,0,0,0-10.9,61,8,8,0,0,0,13.8-8,32,32,0,0,1,11.7-43.7l.7-.4.5-.4h.1l.6-.6h0l.5-.5h0l.4-.5h0l.3-.6h.1l.2-.5v-.2a1.9,1.9,0,0,0,.2-.7h.1c0-.2.1-.4.1-.6s0-.2.1-.2v-1.4h0v-.7h0a6.4,6.4,0,0,0-.2-.7h0a1.9,1.9,0,0,0-.2-.7v-.2c0-.2-.1-.3-.2-.5l-.3-.7-10-17.4a12,12,0,0,1,13.5-17.5,11.8,11.8,0,0,1,7.2,5.5l20,34.7A70.9,70.9,0,0,1,213.5,166.6Zm-125.8,78a8.2,8.2,0,0,1-6.6,3.4,8.6,8.6,0,0,1-4.6-1.4A117.9,117.9,0,0,1,41.1,208a8,8,0,1,1,13.8-8,102.6,102.6,0,0,0,30.8,33.4A8.1,8.1,0,0,1,87.7,244.6ZM168,31a8,8,0,0,1,8-8h0a60.2,60.2,0,0,1,52,30,7.9,7.9,0,0,1-3,10.9,7.1,7.1,0,0,1-4,1.1,8,8,0,0,1-6.9-4A44,44,0,0,0,176,39,8,8,0,0,1,168,31Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M168,31a8,8,0,0,1,8-8h0a60.2,60.2,0,0,1,52,30,7.9,7.9,0,0,1-3,10.9,7.1,7.1,0,0,1-4,1.1,8,8,0,0,1-6.9-4A44,44,0,0,0,176,39,8,8,0,0,1,168,31Zm61,139.8A88,88,0,0,1,67.8,192l-42-72.8A28.1,28.1,0,0,1,36,81a28.5,28.5,0,0,1,11.8-3.7l-3.4-5.8A27.9,27.9,0,0,1,54.7,33.3a27.1,27.1,0,0,1,21.2-2.8,27.9,27.9,0,0,1,11.5,6.2,29.4,29.4,0,0,1,9.9-9.6,28.1,28.1,0,0,1,38.3,10.3l17.3,30a28.1,28.1,0,0,1,47.3,1.9l20,34.7A87.2,87.2,0,0,1,229,170.8ZM206.3,112l-20-34.7a11.8,11.8,0,0,0-7.2-5.5,11.9,11.9,0,0,0-13.5,17.5l10,17.4.3.7c.1.2.2.3.2.5v.2a1.9,1.9,0,0,1,.2.7h0a6.4,6.4,0,0,1,.2.7h0v.7h0v1.4c-.1,0-.1.1-.1.2s-.1.4-.1.6h-.1a1.9,1.9,0,0,1-.2.7v.2l-.2.5h-.1l-.3.6h0l-.4.5h0l-.5.5h0l-.6.6h-.1l-.5.4-.7.4a32,32,0,0,0-11.7,43.7,8,8,0,0,1-13.8,8,48.1,48.1,0,0,1,10.9-61l-6.3-11h0l-30-51.9a12,12,0,1,0-20.8,12l26,45a8,8,0,0,1-2.9,10.9,7.9,7.9,0,0,1-8.5-.3,6.7,6.7,0,0,1-2.4-2.6l-26-45H87L79.1,51.5a12.5,12.5,0,0,0-7.3-5.6,12,12,0,0,0-9.1,1.2,11.8,11.8,0,0,0-5.6,7.3,12,12,0,0,0,1.2,9.1l16,27.7h0l22,38.1a8.1,8.1,0,0,1-2.9,11l-.9.4h-.3l-.6.2h-.3l-.9.2H88.2l-.8-.2h-.1l-.9-.3h-.2l-.6-.3h-.3l-.7-.5h-.1l-.7-.6-.2-.2-.5-.5v-.2l-.6-.9-22-38.1a12,12,0,0,0-20.8,12l42,72.8A72.1,72.1,0,0,0,180,210.3,72,72,0,0,0,206.3,112ZM85.7,233.4A102.6,102.6,0,0,1,54.9,200a8,8,0,1,0-13.8,8,117.9,117.9,0,0,0,35.4,38.6,8.6,8.6,0,0,0,4.6,1.4,8.2,8.2,0,0,0,6.6-3.4A8.1,8.1,0,0,0,85.7,233.4Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M218.5,105l-20-34.7a26,26,0,0,0-45.6,1.1l-19.1-33a26,26,0,0,0-35.5-9.5A25.5,25.5,0,0,0,87.9,40.1,25.8,25.8,0,0,0,55.7,35,26,26,0,0,0,43.6,50.8a25.6,25.6,0,0,0,2.6,19.7l5,8.8a25.9,25.9,0,0,0-23.7,38.9l42,72.8a86,86,0,0,0,149-86Zm-3,62.1A74,74,0,0,1,79.9,185l-42-72.8A14,14,0,0,1,43,93.1a14.2,14.2,0,0,1,19.2,5.1l22,38.1a1.6,1.6,0,0,0,.4.7h.1a.8.8,0,0,0,.4.4v.2l.5.4h.1l.5.3h.2l.5.2h.2l.6.2H91l.5-.2h.2l.7-.4a6.2,6.2,0,0,0,3-5.3,6,6,0,0,0-.6-2.3c-.1-.2-.1-.4-.3-.6l-38-65.8a14,14,0,0,1-1.3-10.6A14,14,0,0,1,72.3,44a14.2,14.2,0,0,1,8.5,6.5l34,58.9a5.9,5.9,0,0,0,8.2,2.2,6,6,0,0,0,2.2-8.2l-26-45a14,14,0,1,1,24.2-14l30,51.9h0l7.2,12.5a46,46,0,0,0-11.8,59.5,5.9,5.9,0,0,0,8.2,2.2,6,6,0,0,0,2.2-8.2,34,34,0,0,1,12.4-46.4l1-.7h0l.8-.9h0l.6-1h0a3.6,3.6,0,0,0,.4-1.1h.1a.9.9,0,0,1,.1-.5h0v-1h0v-.5h0a.9.9,0,0,1-.1-.5h0l-.2-.4c0-.1,0-.2-.1-.2s0-.3-.1-.4l-.3-.5-10-17.4a14.5,14.5,0,0,1-1.4-10.6,14,14,0,0,1,6.6-8.5,14.5,14.5,0,0,1,10.6-1.4,14.2,14.2,0,0,1,8.5,6.5l20,34.7A73.4,73.4,0,0,1,215.5,167.1ZM86,243.4a5.8,5.8,0,0,1-4.9,2.6,5.3,5.3,0,0,1-3.4-1.1A116.2,116.2,0,0,1,42.8,207a6,6,0,0,1,10.4-6,105.4,105.4,0,0,0,31.3,34.1A6,6,0,0,1,86,243.4ZM170,31a6,6,0,0,1,6-6h0a58,58,0,0,1,50.2,29,5.9,5.9,0,0,1-2.2,8.2,6.4,6.4,0,0,1-3,.8,6.1,6.1,0,0,1-5.2-3A46,46,0,0,0,176,37,6,6,0,0,1,170,31Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M216.7,106l-20-34.7a24,24,0,0,0-43.5,4.6L132.1,39.4A24,24,0,0,0,88.5,44a24.5,24.5,0,0,0-13.6-9.7,24,24,0,0,0-29.4,17,23.6,23.6,0,0,0,2.4,18.2L55,81.8a24,24,0,0,0-25.8,35.4l42,72.8a84.3,84.3,0,0,0,72.9,42,84,84,0,0,0,72.6-126Zm.7,61.7A76,76,0,0,1,78.2,186l-42-72.8a16,16,0,0,1,27.7-16l22,38.1a1.5,1.5,0,0,0,.4.6v.2l.5.4h0l.6.4h.2l.6.2h2.4l.7-.3a4,4,0,0,0,1.4-5.5l-22-38.1h0l-16-27.7a15.9,15.9,0,0,1,5.9-21.8,15.6,15.6,0,0,1,12.1-1.6,15.7,15.7,0,0,1,9.7,7.4l34,58.9a4.1,4.1,0,0,0,4.8,1.8l.7-.3a4,4,0,0,0,2-3.2,4.6,4.6,0,0,0-.5-2.3l-26-45a16,16,0,1,1,27.7-16l30,51.9h0l8.1,14.1a43.4,43.4,0,0,0-17.2,24.5,44.3,44.3,0,0,0,4.4,33.4,4,4,0,1,0,7-4,36.1,36.1,0,0,1,13.1-49.2l.7-.4.3-.4.2-.2.3-.5v-.2a2.4,2.4,0,0,0,.4-1.5v-.7a.4.4,0,0,0-.1-.3.9.9,0,0,0-.1-.5l-.3-.7-10-17.4a16,16,0,0,1,18-23.4,15.7,15.7,0,0,1,9.7,7.4l20,34.7A75.2,75.2,0,0,1,217.4,167.7Zm-133,74.6a4,4,0,0,1-3.3,1.7,3.9,3.9,0,0,1-2.3-.7A115.6,115.6,0,0,1,44.5,206a4,4,0,0,1,7-4,106,106,0,0,0,31.9,34.7A4.1,4.1,0,0,1,84.4,242.3ZM172,31a4,4,0,0,1,4-4h0a56.2,56.2,0,0,1,48.5,28,4.1,4.1,0,0,1-1.5,5.5,4.6,4.6,0,0,1-2,.5,3.8,3.8,0,0,1-3.4-2A48.2,48.2,0,0,0,176,35,4,4,0,0,1,172,31Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M220.2,104l-20-34.7a28.1,28.1,0,0,0-47.3-1.9l-17.3-30A28.1,28.1,0,0,0,97.3,27.1a29.4,29.4,0,0,0-9.9,9.6,27.9,27.9,0,0,0-11.5-6.2,27.2,27.2,0,0,0-21.2,2.8A27.9,27.9,0,0,0,44.4,71.5l3.4,5.8A28.5,28.5,0,0,0,36,81a28.1,28.1,0,0,0-10.2,38.2l42,72.8a88,88,0,1,0,152.4-88Zm-6.7,62.6A71.2,71.2,0,0,1,180,210.3,72.1,72.1,0,0,1,81.6,184l-42-72.8a12,12,0,0,1,20.8-12l22,38.1.6.9v.2l.5.5.2.2.7.6h.1l.7.5h.3l.6.3h.2l.9.3h.1l.8.2h2.2l.9-.2h.3l.6-.2h.3l.9-.4a8.1,8.1,0,0,0,2.9-11l-22-38.1h0l-16-27.7a12,12,0,0,1-1.2-9.1,11.8,11.8,0,0,1,5.6-7.3,12,12,0,0,1,9.1-1.2,12.5,12.5,0,0,1,7.3,5.6L87,65.4h.1l26,45a7,7,0,0,0,1.5,1.9,8,8,0,0,0,12.3-9.9l-26-45a12,12,0,1,1,20.8-12l30,51.9h0l6.3,11a48.1,48.1,0,0,0-10.9,61,8,8,0,0,0,13.8-8,32,32,0,0,1,11.7-43.7l.7-.4.5-.4h.1l.6-.6h0l.5-.5h0l.4-.5h0l.3-.6h.1l.2-.5v-.2a1.9,1.9,0,0,0,.2-.7h.1c0-.2.1-.4.1-.6s0-.2.1-.2v-1.4h0v-.7h0a6.4,6.4,0,0,0-.2-.7h0a1.9,1.9,0,0,0-.2-.7v-.2c0-.2-.1-.3-.2-.5l-.3-.7-10-17.4a12,12,0,0,1,13.5-17.5,11.8,11.8,0,0,1,7.2,5.5l20,34.7A70.9,70.9,0,0,1,213.5,166.6Zm-125.8,78a8.2,8.2,0,0,1-6.6,3.4,8.6,8.6,0,0,1-4.6-1.4A117.9,117.9,0,0,1,41.1,208a8,8,0,1,1,13.8-8,102.6,102.6,0,0,0,30.8,33.4A8.1,8.1,0,0,1,87.7,244.6ZM168,31a8,8,0,0,1,8-8h0a60.2,60.2,0,0,1,52,30,7.9,7.9,0,0,1-3,10.9,7.1,7.1,0,0,1-4,1.1,8,8,0,0,1-6.9-4A44,44,0,0,0,176,39,8,8,0,0,1,168,31Z" />
        </>
      )}
    </svg>
  );
}

export function PhHand(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M188,36a35,35,0,0,0-8,1V36a32,32,0,0,0-60.8-14A32.1,32.1,0,0,0,76,52v60.5a32,32,0,0,0-43.2-10.9,31.5,31.5,0,0,0-15,19.4,31.9,31.9,0,0,0,3,23.8c15.7,33.1,29.5,58.5,46.1,75.1S102.3,244,128,244a92.1,92.1,0,0,0,92-92V68A32.1,32.1,0,0,0,188,36Zm8,116a68.1,68.1,0,0,1-68,68c-36.2,0-53-16.8-85.7-85.9a5.6,5.6,0,0,1-.5-.8,8.6,8.6,0,0,1-.8-6.1,7.9,7.9,0,0,1,3.8-4.8,7.5,7.5,0,0,1,6-.8,7.6,7.6,0,0,1,4.9,3.7l21.9,38a12,12,0,0,0,22.4-6V52a8,8,0,0,1,16,0v68a12,12,0,0,0,24,0V36a8,8,0,0,1,16,0v84a12,12,0,0,0,24,0V68a8,8,0,0,1,16,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M128,52V36a20,20,0,0,1,40,0V68a20,20,0,0,1,40,0v84a80,80,0,0,1-80,80c-44.2,0-64-24-96.6-92.7a20,20,0,0,1,34.7-20l21.9,38V52a20,20,0,0,1,40,0Z" />
          </g>
          <path d="M188,40a28.2,28.2,0,0,0-12,2.7V36a28,28,0,0,0-54.6-8.6A27.8,27.8,0,0,0,108,24,28.1,28.1,0,0,0,80,52v75.4l-7-12.1A28,28,0,0,0,24.3,143c32.5,68.4,54.1,97,103.7,97a88.1,88.1,0,0,0,88-88V68A28.1,28.1,0,0,0,188,40Zm12,112a72.1,72.1,0,0,1-72,72c-20.2,0-34.2-5.5-47-18.2S56.3,173,38.7,135.9l-.3-.6a11.6,11.6,0,0,1-1.2-9.1,11.8,11.8,0,0,1,5.6-7.3,12,12,0,0,1,9.1-1.2,11.6,11.6,0,0,1,7.2,5.6l22,38a8.1,8.1,0,0,0,9,3.7,7.9,7.9,0,0,0,5.9-7.7V52a12,12,0,0,1,24,0v68a8,8,0,0,0,16,0V36a12,12,0,0,1,24,0v84a8,8,0,0,0,16,0V68a12,12,0,0,1,24,0Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M188,40a28.2,28.2,0,0,0-12,2.7V36a28,28,0,0,0-54.6-8.6A27.8,27.8,0,0,0,108,24,28.1,28.1,0,0,0,80,52v75.4l-7-12.1A28,28,0,0,0,24.3,143c32.5,68.4,54.1,97,103.7,97a88.1,88.1,0,0,0,88-88V68A28.1,28.1,0,0,0,188,40Zm12,112a72.1,72.1,0,0,1-72,72c-20.2,0-34.2-5.5-47-18.2S56.3,173,38.7,135.9l-.3-.6a11.6,11.6,0,0,1-1.2-9.1,11.8,11.8,0,0,1,5.6-7.3,12,12,0,0,1,9.1-1.2,11.6,11.6,0,0,1,7.2,5.6l22,38a8.1,8.1,0,0,0,9,3.7,7.9,7.9,0,0,0,5.9-7.7V52a12,12,0,0,1,24,0v68a8,8,0,0,0,16,0V36a12,12,0,0,1,24,0v84a8,8,0,0,0,16,0V68a12,12,0,0,1,24,0Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M188,42a25.6,25.6,0,0,0-14,4.1V36a26,26,0,0,0-51.4-5.5A26,26,0,0,0,82,52v82.9L71.3,116.3a26,26,0,0,0-15.8-12.1,25.6,25.6,0,0,0-19.7,2.6,25.3,25.3,0,0,0-12.2,15.8,26.1,26.1,0,0,0,2.5,19.5C58.2,209.7,79.5,238,128,238a86.1,86.1,0,0,0,86-86V68A26.1,26.1,0,0,0,188,42Zm14,110a74.1,74.1,0,0,1-74,74c-38.8,0-57.5-18.4-91.1-89.3l-.3-.4a14.5,14.5,0,0,1-1.4-10.6,13.8,13.8,0,0,1,6.6-8.5,14,14,0,0,1,19.1,5.1l21.9,38a6.1,6.1,0,0,0,6.8,2.8,6,6,0,0,0,4.4-5.8V52a14,14,0,0,1,28,0v68a6,6,0,0,0,12,0V36a14,14,0,0,1,28,0v84a6,6,0,0,0,12,0V68a14,14,0,0,1,28,0Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M188,44a23.9,23.9,0,0,0-16,6.1V36a24,24,0,0,0-47.9-1.8A23.8,23.8,0,0,0,108,28,24.1,24.1,0,0,0,84,52v90.3l-14.5-25a23.9,23.9,0,0,0-32.7-8.8,23.5,23.5,0,0,0-11.2,14.6,23.2,23.2,0,0,0,2.3,18C59.6,208,80.5,236,128,236a84.1,84.1,0,0,0,84-84V68A24.1,24.1,0,0,0,188,44Zm16,108a76.1,76.1,0,0,1-76,76c-37,0-56.6-13.7-93-90.4v-.3a16,16,0,0,1,5.9-21.9,15.9,15.9,0,0,1,21.8,5.9l21.9,38a4,4,0,0,0,4.5,1.8,3.9,3.9,0,0,0,3-3.8V52a16,16,0,0,1,32,0v68a4,4,0,0,0,8,0V36a16,16,0,0,1,32,0v84a4,4,0,0,0,8,0V68a16,16,0,0,1,32,0Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M188,40a28.2,28.2,0,0,0-12,2.7V36a28,28,0,0,0-54.6-8.6A27.8,27.8,0,0,0,108,24,28.1,28.1,0,0,0,80,52v75.4l-7-12.1A28,28,0,0,0,24.3,143c32.5,68.4,54.1,97,103.7,97a88.1,88.1,0,0,0,88-88V68A28.1,28.1,0,0,0,188,40Zm12,112a72.1,72.1,0,0,1-72,72c-20.2,0-34.2-5.5-47-18.2S56.3,173,38.7,135.9l-.3-.6a11.6,11.6,0,0,1-1.2-9.1,11.8,11.8,0,0,1,5.6-7.3,12,12,0,0,1,9.1-1.2,11.6,11.6,0,0,1,7.2,5.6l22,38a8.1,8.1,0,0,0,9,3.7,7.9,7.9,0,0,0,5.9-7.7V52a12,12,0,0,1,24,0v68a8,8,0,0,0,16,0V36a12,12,0,0,1,24,0v84a8,8,0,0,0,16,0V68a12,12,0,0,1,24,0Z" />
        </>
      )}
    </svg>
  );
}

export function PhHandbagSimple(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M242.9,205.8l-14.2-128A19.9,19.9,0,0,0,208.8,60H178.6A52,52,0,0,0,77.4,60H47.2A19.9,19.9,0,0,0,27.3,77.8l-14.2,128A19.6,19.6,0,0,0,18,221.3,20,20,0,0,0,32.9,228H223.1a20,20,0,0,0,14.9-6.7A19.6,19.6,0,0,0,242.9,205.8ZM128,44a28,28,0,0,1,25.3,16H102.7A28,28,0,0,1,128,44ZM37.4,204,50.7,84H205.3l13.3,120Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M208.8,72H47.2a8.1,8.1,0,0,0-8,7.1L25,207.1a8,8,0,0,0,7.9,8.9H223.1a8,8,0,0,0,7.9-8.9l-14.2-128A8.1,8.1,0,0,0,208.8,72Z" />
          </g>
          <path d="M239,206.2l-14.3-128A15.9,15.9,0,0,0,208.8,64H175.3a48,48,0,0,0-94.6,0H47.2A15.9,15.9,0,0,0,31.3,78.2L17,206.2a16.2,16.2,0,0,0,4,12.5A15.9,15.9,0,0,0,32.9,224H223.1a15.9,15.9,0,0,0,11.9-5.3A16.2,16.2,0,0,0,239,206.2ZM128,40a32.1,32.1,0,0,1,31,24H97A32.1,32.1,0,0,1,128,40ZM32.9,208,47.2,80H208.8l14.3,128Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M239,206.2l-14.3-128A15.9,15.9,0,0,0,208.8,64H175.3a48,48,0,0,0-94.6,0H47.2A15.9,15.9,0,0,0,31.3,78.2L17,206.2a16.2,16.2,0,0,0,4,12.5A15.9,15.9,0,0,0,32.9,224H223.1a15.9,15.9,0,0,0,11.9-5.3A16.2,16.2,0,0,0,239,206.2ZM128,40a32.1,32.1,0,0,1,31,24H97A32.1,32.1,0,0,1,128,40Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M237,206.5l-14.2-128a14.1,14.1,0,0,0-14-12.5H173.6a46,46,0,0,0-91.2,0H47.2a14.1,14.1,0,0,0-14,12.5L19,206.5A14.1,14.1,0,0,0,32.9,222H223.1A14.1,14.1,0,0,0,237,206.5ZM128,38a34.1,34.1,0,0,1,33.5,28h-67A34.1,34.1,0,0,1,128,38Zm96.6,171.3a2.1,2.1,0,0,1-1.5.7H32.9a2.1,2.1,0,0,1-1.5-.7,1.8,1.8,0,0,1-.4-1.5l14.2-128a2,2,0,0,1,2-1.8H208.8a2,2,0,0,1,2,1.8l14.2,128A1.8,1.8,0,0,1,224.6,209.3Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M235,206.7l-14.2-128a12,12,0,0,0-12-10.7h-37a44,44,0,0,0-87.6,0h-37a12,12,0,0,0-12,10.7L21,206.7a11.7,11.7,0,0,0,3,9.3,12.1,12.1,0,0,0,8.9,4H223.1a12.1,12.1,0,0,0,8.9-4A11.7,11.7,0,0,0,235,206.7ZM128,36a36.1,36.1,0,0,1,35.8,32H92.2A36.1,36.1,0,0,1,128,36Zm98,174.7a3.9,3.9,0,0,1-2.9,1.3H32.9a3.9,3.9,0,0,1-2.9-1.3,3.7,3.7,0,0,1-1-3.1l14.2-128a3.9,3.9,0,0,1,4-3.6H208.8a3.9,3.9,0,0,1,4,3.6l14.2,128A3.7,3.7,0,0,1,226,210.7Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M239,206.2l-14.3-128A15.9,15.9,0,0,0,208.8,64H175.3a48,48,0,0,0-94.6,0H47.2A15.9,15.9,0,0,0,31.3,78.2L17,206.2a16.2,16.2,0,0,0,4,12.5A15.9,15.9,0,0,0,32.9,224H223.1a15.9,15.9,0,0,0,11.9-5.3A16.2,16.2,0,0,0,239,206.2ZM128,40a32.1,32.1,0,0,1,31,24H97A32.1,32.1,0,0,1,128,40ZM32.9,208,47.2,80H208.8l14.3,128Z" />
        </>
      )}
    </svg>
  );
}

export function PhHandbag(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M242.9,205.8l-14.2-128A19.9,19.9,0,0,0,208.8,60H178.6A52,52,0,0,0,77.4,60H47.2A19.9,19.9,0,0,0,27.3,77.8l-14.2,128A19.6,19.6,0,0,0,18,221.3,20,20,0,0,0,32.9,228H223.1a20,20,0,0,0,14.9-6.7A19.6,19.6,0,0,0,242.9,205.8ZM128,44a28,28,0,0,1,25.3,16H102.7A28,28,0,0,1,128,44ZM37.4,204,50.7,84H76v20a12,12,0,0,0,24,0V84h56v20a12,12,0,0,0,24,0V84h25.3l13.3,120Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M208.8,72H47.2a8.1,8.1,0,0,0-8,7.1L25,207.1a8,8,0,0,0,7.9,8.9H223.1a8,8,0,0,0,7.9-8.9l-14.2-128A8.1,8.1,0,0,0,208.8,72Z" />
          </g>
          <path d="M239,206.2l-14.3-128A15.9,15.9,0,0,0,208.8,64H175.3a48,48,0,0,0-94.6,0H47.2A15.9,15.9,0,0,0,31.3,78.2L17,206.2a16.2,16.2,0,0,0,4,12.5A15.9,15.9,0,0,0,32.9,224H223.1a15.9,15.9,0,0,0,11.9-5.3A16.2,16.2,0,0,0,239,206.2ZM128,40a32.1,32.1,0,0,1,31,24H97A32.1,32.1,0,0,1,128,40ZM32.9,208,47.2,80H80v24a8,8,0,0,0,16,0V80h64v24a8,8,0,0,0,16,0V80h32.8l14.3,128Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M239,206.2l-14.3-128A15.9,15.9,0,0,0,208.8,64H175.3a48,48,0,0,0-94.6,0H47.2A15.9,15.9,0,0,0,31.3,78.2L17,206.2a16.2,16.2,0,0,0,4,12.5A15.9,15.9,0,0,0,32.9,224H223.1a15.9,15.9,0,0,0,11.9-5.3A16.2,16.2,0,0,0,239,206.2ZM96,104a8,8,0,0,1-16,0V88a8,8,0,0,1,16,0Zm1-40a32,32,0,0,1,62,0Zm79,40a8,8,0,0,1-16,0V88a8,8,0,0,1,16,0Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M237,206.5l-14.2-128a14.1,14.1,0,0,0-14-12.5H173.6a46,46,0,0,0-91.2,0H47.2a14.1,14.1,0,0,0-14,12.5L19,206.5A14.1,14.1,0,0,0,32.9,222H223.1A14.1,14.1,0,0,0,237,206.5ZM128,38a34.1,34.1,0,0,1,33.5,28h-67A34.1,34.1,0,0,1,128,38Zm96.6,171.3a2.1,2.1,0,0,1-1.5.7H32.9a2.1,2.1,0,0,1-1.5-.7,1.8,1.8,0,0,1-.4-1.5l14.2-128a2,2,0,0,1,2-1.8H82v26a6,6,0,0,0,12,0V78h68v26a6,6,0,0,0,12,0V78h34.8a2,2,0,0,1,2,1.8l14.2,128A1.8,1.8,0,0,1,224.6,209.3Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M235,206.7l-14.2-128a12,12,0,0,0-12-10.7h-37a44,44,0,0,0-87.6,0h-37a12,12,0,0,0-12,10.7L21,206.7a11.7,11.7,0,0,0,3,9.3,12.1,12.1,0,0,0,8.9,4H223.1a12.1,12.1,0,0,0,8.9-4A11.7,11.7,0,0,0,235,206.7ZM128,36a36.1,36.1,0,0,1,35.8,32H92.2A36.1,36.1,0,0,1,128,36Zm98,174.7a3.9,3.9,0,0,1-2.9,1.3H32.9a3.9,3.9,0,0,1-2.9-1.3,3.7,3.7,0,0,1-1-3.1l14.2-128a3.9,3.9,0,0,1,4-3.6H84v28a4,4,0,0,0,8,0V76h72v28a4,4,0,0,0,8,0V76h36.8a3.9,3.9,0,0,1,4,3.6l14.2,128A3.7,3.7,0,0,1,226,210.7Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M239,206.2l-14.3-128A15.9,15.9,0,0,0,208.8,64H175.3a48,48,0,0,0-94.6,0H47.2A15.9,15.9,0,0,0,31.3,78.2L17,206.2a16.2,16.2,0,0,0,4,12.5A15.9,15.9,0,0,0,32.9,224H223.1a15.9,15.9,0,0,0,11.9-5.3A16.2,16.2,0,0,0,239,206.2ZM128,40a32.1,32.1,0,0,1,31,24H97A32.1,32.1,0,0,1,128,40ZM32.9,208,47.2,80H80v24a8,8,0,0,0,16,0V80h64v24a8,8,0,0,0,16,0V80h32.8l14.3,128Z" />
        </>
      )}
    </svg>
  );
}

export function PhHandsClapping(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M195.7,75.3A32,32,0,0,0,152,63.6a31.7,31.7,0,0,0-6.5,4.9L131,43.4A32,32,0,0,0,87.3,31.7,30.8,30.8,0,0,0,78.6,39,35,35,0,0,0,69,34.6,32,32,0,0,0,33,81.5l.5.9A30.5,30.5,0,0,0,26,85.5a32,32,0,0,0-11.7,43.7l42,72.8a92,92,0,1,0,159.4-92ZM170,214.9A68.1,68.1,0,0,1,77.1,190l-42-72.8a7.9,7.9,0,0,1-.8-6,8.3,8.3,0,0,1,3.7-4.9,8.1,8.1,0,0,1,11,2.9l22,38.1h0l4,6.9a12,12,0,1,0,20.7-12l-42-72.8a8.4,8.4,0,0,1-.8-6,8.1,8.1,0,0,1,3.8-4.9,7.5,7.5,0,0,1,6-.8,8.3,8.3,0,0,1,4.9,3.7l8,13.9h0l13,22.5,21,36.4a12,12,0,0,0,20.8-12l-8-13.9h0l-13-22.5-13-22.5a8,8,0,0,1,13.8-8l30,51.9h0l18,31.2a12,12,0,0,0,20.8-12l-8-13.8h0L161,95.3a8.4,8.4,0,0,1-.8-6,8.1,8.1,0,0,1,3.8-4.9,8,8,0,0,1,10.9,2.9l20,34.7A68.1,68.1,0,0,1,170,214.9ZM144,28V12a12,12,0,0,1,24,0V28a12,12,0,0,1-24,0Zm41.6,4.7,8-13.8a12,12,0,0,1,20.8,12l-8,13.8A12,12,0,0,1,190,49.1,12,12,0,0,1,185.6,32.7Zm59.5,37.7-13.8,8a12,12,0,1,1-12-20.8l13.8-8a12,12,0,0,1,12,20.8Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M188,191.5a71.5,71.5,0,0,0-5.7-59.5l-17-29.5a18.2,18.2,0,0,0-9.1-7.8h0a18,18,0,0,0-22,25.8l-26-45A17.9,17.9,0,0,0,99,67.7h0A18,18,0,0,0,77,93.5L69,79.7a17.6,17.6,0,0,0-9.1-7.8h0l-2.4-4.1a18,18,0,1,1,31.2-18l8,13.9a18,18,0,1,1,31.2-18l26,45a18,18,0,1,1,31.2-18l17,29.4A72.1,72.1,0,0,1,188,191.5Zm0,0Z"
            opacity="0.2"
          />
          <path d="M192,68.7a26,26,0,0,0-15.8-12.1,25.6,25.6,0,0,0-19.7,2.6,24.9,24.9,0,0,0-7.7,6.7l-14-24.2a26,26,0,0,0-35.5-9.6,27.1,27.1,0,0,0-8.4,7.7,25.2,25.2,0,0,0-11.1-6.1,25.6,25.6,0,0,0-19.7,2.6A26,26,0,0,0,48,52.1a25,25,0,0,0-.5,11.2,28.3,28.3,0,0,0-7.1,2.8,26.1,26.1,0,0,0-9.5,35.6l2,3.4a24.3,24.3,0,0,0-9.7,3.3,26,26,0,0,0-9.5,35.5l37,64.1A80.1,80.1,0,0,0,160,237.3,79,79,0,0,0,194.9,196,79.9,79.9,0,0,0,209,98.1ZM107.3,46A10,10,0,0,1,121,49.7l21.3,36.9a27,27,0,0,0-5.5,2.4,26.4,26.4,0,0,0-7.7,6.7l-14-24.2a25.6,25.6,0,0,0-10.4-10l-1.1-1.8h0A10.1,10.1,0,0,1,107.3,46ZM68.1,50.1a10.1,10.1,0,0,1,13.7,3.7l3.3,5.8A29.6,29.6,0,0,0,79.6,62a25,25,0,0,0-8.4,7.7,25.4,25.4,0,0,0-5.7-4l-1-1.9A10,10,0,0,1,68.1,50.1ZM181.8,184.6A63.3,63.3,0,0,1,152,223.4,64,64,0,0,1,64.6,200l-37-64.1a10,10,0,0,1,11.2-14.7,10.3,10.3,0,0,1,6.1,4.7h0l24,41.6a8.2,8.2,0,0,0,6.9,4,8,8,0,0,0,6.9-12l-38-65.8a10,10,0,0,1,17.4-10l38,65.8a8,8,0,1,0,13.8-8l-30-52h0a10.1,10.1,0,0,1,3.7-13.7,10,10,0,0,1,13.6,3.7l42,72.8a8.2,8.2,0,0,0,7,4,8.6,8.6,0,0,0,4-1.1,8,8,0,0,0,2.9-10.9l-16-27.8h0a9.9,9.9,0,0,1-1-7.5,9.6,9.6,0,0,1,4.7-6.1,9.4,9.4,0,0,1,7.5-1,9.8,9.8,0,0,1,6.1,4.6l17,29.5A63.5,63.5,0,0,1,181.8,184.6Zm17.8-24.2A78.9,78.9,0,0,0,189.3,128l-17-29.5a26.3,26.3,0,0,0-10.4-10l-1.1-1.8h0a10.1,10.1,0,0,1-1-7.6,10.3,10.3,0,0,1,4.7-6.1,9.9,9.9,0,0,1,13.6,3.7l17,29.4A64.1,64.1,0,0,1,199.6,160.4ZM165,24.7l4.1-15.5a8,8,0,1,1,15.5,4.1l-4.2,15.5a7.9,7.9,0,0,1-7.7,5.9l-2.1-.2A8,8,0,0,1,165,24.7Zm26.3,9.2,9.2-13.1a8.1,8.1,0,0,1,11.2-2A8,8,0,0,1,213.6,30l-9.2,13.1A7.9,7.9,0,0,1,193.3,45,8,8,0,0,1,191.3,33.9Zm43.9,21.6-13.1,9.2a8.6,8.6,0,0,1-4.6,1.4,8,8,0,0,1-4.6-14.5L226,42.4a8,8,0,0,1,11.2,1.9A8.1,8.1,0,0,1,235.2,55.5Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M205.5,100.1l-17-29.4a21.9,21.9,0,0,0-30-8.1A21.5,21.5,0,0,0,148.9,74L131.3,43.7a21.9,21.9,0,0,0-30-8.1A21.5,21.5,0,0,0,91.7,47a22.1,22.1,0,0,0-29.6-7.3A21.9,21.9,0,0,0,51.9,53.1a20.9,20.9,0,0,0-.4,9.6,26.5,26.5,0,0,0-11.1,3.4,26.1,26.1,0,0,0-9.5,35.6l2,3.4a24.3,24.3,0,0,0-9.7,3.3,26,26,0,0,0-9.5,35.5l37,64.1a80,80,0,0,0,146.6-19.3l.3-1.3A76.5,76.5,0,0,0,205.5,100.1Zm-23.7,84.5A63.3,63.3,0,0,1,152,223.4,64,64,0,0,1,64.6,200l-37-64.1a10,10,0,0,1,11.2-14.7,10.3,10.3,0,0,1,6.1,4.7h0l24,41.6a8.2,8.2,0,0,0,6.9,4,8,8,0,0,0,6.9-12l-38-65.8a10,10,0,0,1,17.4-10l38,65.8a8,8,0,1,0,13.8-8l-30-52h0a10.1,10.1,0,0,1,3.7-13.7,10,10,0,0,1,13.6,3.7l42,72.8a8.2,8.2,0,0,0,7,4,8.6,8.6,0,0,0,4-1.1,8,8,0,0,0,2.9-10.9l-16-27.8h0a9.9,9.9,0,0,1-1-7.5,9.6,9.6,0,0,1,4.7-6.1,9.4,9.4,0,0,1,7.5-1,9.8,9.8,0,0,1,6.1,4.6l17,29.5A63.5,63.5,0,0,1,181.8,184.6ZM165,24.7l4.1-15.5a8,8,0,1,1,15.5,4.1l-4.2,15.5a7.9,7.9,0,0,1-7.7,5.9l-2.1-.2A8,8,0,0,1,165,24.7Zm26.3,9.2,9.2-13.1a8.1,8.1,0,0,1,11.2-2A8,8,0,0,1,213.6,30l-9.2,13.1A7.9,7.9,0,0,1,193.3,45,8,8,0,0,1,191.3,33.9Zm43.9,21.6-13.1,9.2a8.6,8.6,0,0,1-4.6,1.4,8,8,0,0,1-4.6-14.5L226,42.4a8,8,0,0,1,11.2,1.9A8.1,8.1,0,0,1,235.2,55.5Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M190.3,69.7a24.1,24.1,0,0,0-14.6-11.2,23.6,23.6,0,0,0-18.2,2.4,24,24,0,0,0-8.8,8.8l-15.6-27a24,24,0,0,0-32.8-8.8,23.4,23.4,0,0,0-9,9.2,24,24,0,0,0-41.4,9.5,23.3,23.3,0,0,0,0,12.3,22.8,22.8,0,0,0-8.5,3,24,24,0,0,0-8.8,32.8l3.6,6.2a23.7,23.7,0,0,0-12,3.2,24.1,24.1,0,0,0-8.8,32.8l37,64.1a78.1,78.1,0,0,0,140.8-12.1,78.5,78.5,0,0,0,14.1-95.8ZM100.7,51.5a11.8,11.8,0,0,1,5.6-7.2,12,12,0,0,1,16.4,4.4l22.7,39.2a24.3,24.3,0,0,0-7.6,2.9,22.6,22.6,0,0,0-8.8,8.8L113.4,72.5A24.9,24.9,0,0,0,103.3,63l-1.4-2.3h0A12.3,12.3,0,0,1,100.7,51.5ZM61.5,55.7a12,12,0,0,1,22-2.9l4.7,8.1A24,24,0,0,0,71.5,73a22.9,22.9,0,0,0-7.4-5.8l-1.4-2.4A12.1,12.1,0,0,1,61.5,55.7ZM153,225.1A66.1,66.1,0,0,1,62.8,201l-37-64.1a12,12,0,1,1,20.8-12h0l24,41.6a6,6,0,0,0,5.2,3,6.4,6.4,0,0,0,3-.8,6,6,0,0,0,2.2-8.2L43,94.7a12,12,0,1,1,20.8-12l38,65.8a6,6,0,0,0,10.4-6l-30-52h0A12.1,12.1,0,0,1,81,81.4a11.8,11.8,0,0,1,5.6-7.3A12.1,12.1,0,0,1,103,78.5l42,72.8a6,6,0,0,0,5.2,3,6.7,6.7,0,0,0,3-.8,6.1,6.1,0,0,0,2.2-8.2l-16-27.8h0a11.9,11.9,0,0,1,4.4-16.3,11.8,11.8,0,0,1,16.3,4.3l17,29.5A65.9,65.9,0,0,1,153,225.1ZM198,169a76.8,76.8,0,0,0-10.5-40l-17-29.5a23.8,23.8,0,0,0-10-9.4l-1.4-2.4h0a11.6,11.6,0,0,1-1.2-9.1,11.8,11.8,0,0,1,5.6-7.3,12.1,12.1,0,0,1,16.4,4.4l17,29.4A66.6,66.6,0,0,1,198,169ZM166.9,25.2l4.2-15.5a6,6,0,0,1,11.5,3.1l-4.1,15.5a5.9,5.9,0,0,1-5.8,4.4,3.9,3.9,0,0,1-1.5-.2A6,6,0,0,1,166.9,25.2ZM193,35l9.1-13.1a6,6,0,1,1,9.9,6.9l-9.2,13.1a5.9,5.9,0,0,1-4.9,2.6,6.6,6.6,0,0,1-3.5-1.1A6.1,6.1,0,0,1,193,35Zm41.1,18.9L221,63a5.8,5.8,0,0,1-3.5,1.1,6,6,0,0,1-3.4-10.9L227.2,44a6,6,0,1,1,6.9,9.9Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M188.5,70.7a21.9,21.9,0,0,0-30-8.1A21.5,21.5,0,0,0,148.9,74L131.3,43.7a21.9,21.9,0,0,0-30-8.1A21.5,21.5,0,0,0,91.7,47a22.1,22.1,0,0,0-29.6-7.3A21.9,21.9,0,0,0,51.9,53.1a21.1,21.1,0,0,0,.7,13.6,21,21,0,0,0-10.2,2.9,22.1,22.1,0,0,0-8.1,30.1l5.6,9.5a22.8,22.8,0,0,0-14.7,2.6,22,22,0,0,0-8,30.1l37,64.1a75.9,75.9,0,0,0,137.2-12.2,76.3,76.3,0,0,0,14.1-93.7ZM105.3,42.5a14,14,0,0,1,19.1,5.2l24.2,41.9a21.4,21.4,0,0,0-9.8,2.9,22.3,22.3,0,0,0-9.7,11.3L111.6,73.5a21.3,21.3,0,0,0-9.7-8.9l-1.7-2.9h0A14.1,14.1,0,0,1,105.3,42.5ZM59.6,55.2a14,14,0,0,1,25.6-3.4l6.3,10.8a21,21,0,0,0-9.9,2.9A21.4,21.4,0,0,0,72,76.9a22.8,22.8,0,0,0-9.3-8.2L61,65.8A13.6,13.6,0,0,1,59.6,55.2ZM154,226.9A68.1,68.1,0,0,1,61.1,202l-37-64.1a14,14,0,1,1,24.2-14h0l24,41.6a4.1,4.1,0,0,0,3.5,2,3.6,3.6,0,0,0,2-.6,4,4,0,0,0,1.5-5.4l-38-65.8a14,14,0,1,1,24.2-14l38,65.8a4,4,0,0,0,5.5,1.4,4,4,0,0,0,1.5-5.4l-30-52h0a14,14,0,0,1,24.2-14l42,72.8a3.9,3.9,0,0,0,5.5,1.4,3.9,3.9,0,0,0,1.4-5.4l-16-27.8h0a14.5,14.5,0,0,1-1.4-10.6A14.1,14.1,0,0,1,153.4,98l1.3.4h0a14.1,14.1,0,0,1,7.2,6l17,29.5A68.1,68.1,0,0,1,154,226.9Zm41.4-49.8a75.4,75.4,0,0,0-9.6-47.1l-17-29.5a22.2,22.2,0,0,0-9.8-8.9l-1.7-2.9h0a14.5,14.5,0,0,1-1.4-10.6,13.8,13.8,0,0,1,6.6-8.5,14,14,0,0,1,19.1,5.1l17,29.4A68.5,68.5,0,0,1,195.4,177.1ZM168.8,25.7,173,10.2a4,4,0,0,1,4.9-2.8,4,4,0,0,1,2.8,4.9l-4.1,15.5a4.1,4.1,0,0,1-3.9,2.9h-1A4.1,4.1,0,0,1,168.8,25.7Zm25.8,10.5,9.2-13.1a4,4,0,1,1,6.5,4.6l-9.1,13.1a4.2,4.2,0,0,1-3.3,1.7,4,4,0,0,1-2.3-.8A3.9,3.9,0,0,1,194.6,36.2Zm38.3,16-13.1,9.2a4,4,0,0,1-5.5-1,4,4,0,0,1,.9-5.6l13.1-9.1a4,4,0,1,1,4.6,6.5Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M192,68.7a26,26,0,0,0-15.8-12.1,25.6,25.6,0,0,0-19.7,2.6,24.9,24.9,0,0,0-7.7,6.7l-14-24.2a26,26,0,0,0-35.5-9.6,27.1,27.1,0,0,0-8.4,7.7,25.2,25.2,0,0,0-11.1-6.1,25.6,25.6,0,0,0-19.7,2.6A26,26,0,0,0,48,52.1a25,25,0,0,0-.5,11.2,28.3,28.3,0,0,0-7.1,2.8,26.1,26.1,0,0,0-9.5,35.6l2,3.4a24.3,24.3,0,0,0-9.7,3.3,26,26,0,0,0-9.5,35.5l37,64.1A80.1,80.1,0,0,0,160,237.3,79,79,0,0,0,194.9,196,79.9,79.9,0,0,0,209,98.1ZM107.3,46A10,10,0,0,1,121,49.7l21.3,36.9a27,27,0,0,0-5.5,2.4,26.4,26.4,0,0,0-7.7,6.7l-14-24.2a25.6,25.6,0,0,0-10.4-10l-1.1-1.8h0A10.1,10.1,0,0,1,107.3,46ZM68.1,50.1a10.1,10.1,0,0,1,13.7,3.7l3.3,5.8A29.6,29.6,0,0,0,79.6,62a25,25,0,0,0-8.4,7.7,25.4,25.4,0,0,0-5.7-4l-1-1.9A10,10,0,0,1,68.1,50.1ZM152,223.4A64,64,0,0,1,64.6,200l-37-64.1a10,10,0,0,1,11.2-14.7,10.3,10.3,0,0,1,6.1,4.7h0l24,41.6a8.2,8.2,0,0,0,6.9,4,8,8,0,0,0,6.9-12l-38-65.8a10,10,0,0,1,17.4-10l38,65.8a8,8,0,1,0,13.8-8l-30-52h0a10.1,10.1,0,0,1,3.7-13.7,10,10,0,0,1,13.6,3.7l42,72.8a8.2,8.2,0,0,0,7,4,8.6,8.6,0,0,0,4-1.1,8,8,0,0,0,2.9-10.9l-16-27.8h0a9.9,9.9,0,0,1-1-7.5,9.6,9.6,0,0,1,4.7-6.1,9.4,9.4,0,0,1,7.5-1,9.8,9.8,0,0,1,6.1,4.6l17,29.5A63.9,63.9,0,0,1,152,223.4Zm47.6-63A78.9,78.9,0,0,0,189.3,128l-17-29.5a26.3,26.3,0,0,0-10.4-10l-1.1-1.8h0a10.1,10.1,0,0,1-1-7.6,10.3,10.3,0,0,1,4.7-6.1,9.9,9.9,0,0,1,13.6,3.7l17,29.4A64.1,64.1,0,0,1,199.6,160.4ZM165,24.7l4.1-15.5a8,8,0,1,1,15.5,4.1l-4.2,15.5a7.9,7.9,0,0,1-7.7,5.9l-2.1-.2A8,8,0,0,1,165,24.7Zm26.3,9.2,9.2-13.1a8.1,8.1,0,0,1,11.2-2A8,8,0,0,1,213.6,30l-9.2,13.1A7.9,7.9,0,0,1,193.3,45,8,8,0,0,1,191.3,33.9Zm43.9,21.6-13.1,9.2a8.6,8.6,0,0,1-4.6,1.4,8,8,0,0,1-4.6-14.5L226,42.4a8,8,0,0,1,11.2,1.9A8.1,8.1,0,0,1,235.2,55.5Z" />
        </>
      )}
    </svg>
  );
}

export function PhHandshake(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M250.9,105.4,226.3,58.3a20.2,20.2,0,0,0-26.7-8.7L177.2,60.9H166.6L134.4,46.3a20.2,20.2,0,0,0-13.9-1h-.2L77.2,59,56.4,48.6a20.1,20.1,0,0,0-26.7,8.6L5.1,104.3a20.1,20.1,0,0,0-1.3,15.4,19.9,19.9,0,0,0,10.1,11.7l23.5,11.8L86.9,185l.4.3a20.4,20.4,0,0,0,7.4,3.6l58,14.5a19.7,19.7,0,0,0,4.8.6,20.1,20.1,0,0,0,14.2-5.9l36.8-36.8h.1l.4-.4.5-.7.2-.2.2-.3.2-.3,10.2-15.9,21.8-10.9a19.9,19.9,0,0,0,8.8-27.1Zm-54.1,30.3-33.7-24.5a12,12,0,0,0-14.3.1L136,120.9a20.1,20.1,0,0,1-24,0l-1.7-1.3L145,84.9h27.7l25.4,48.6ZM49.2,71.8l10.5,5.3-20.9,40-10.5-5.3ZM156.3,179.6l-54.8-13.7L59.1,130.1,84.2,82l41.7-13.2.8.4L90,105.9A19.9,19.9,0,0,0,92.2,136l5.4,4.1a44.4,44.4,0,0,0,52.8,0l5.7-4.3,25.5,18.5Zm60.9-61.5-20.9-40,10.5-5.2,20.9,40ZM119.6,223.8a12,12,0,0,1-11.6,9.1,14.5,14.5,0,0,1-2.9-.4L75,225a20.6,20.6,0,0,1-8.3-4.3L44.1,201.1a12.1,12.1,0,0,1,15.8-18.2l21.8,19,29.2,7.3A12,12,0,0,1,119.6,223.8Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M200,152.9l-36.8,36.8a8.5,8.5,0,0,1-7.6,2.1l-58-14.5a8,8,0,0,1-2.9-1.5L40,133.1,72.6,71.8l51.3-15a8,8,0,0,1,5.5.4L164,72.9H143.3a7.9,7.9,0,0,0-5.6,2.3L98.5,114.3a8.1,8.1,0,0,0,.9,12.1l5.4,4.1a32.1,32.1,0,0,0,38.4,0l12.8-9.6Z" />
          </g>
          <path d="M251.3,107.2,226.7,60.1a16,16,0,0,0-21.3-6.9L182.1,64.9H165.7l-33-15a16.6,16.6,0,0,0-11-.8L73,63.3,50.6,52.1a16.1,16.1,0,0,0-21.3,7L4.7,106.2a16,16,0,0,0-1.1,12.3,16.3,16.3,0,0,0,8.1,9.4l24,12,54,42.2a15.2,15.2,0,0,0,6,2.9l58,14.5a15.8,15.8,0,0,0,3.8.5,16.5,16.5,0,0,0,11.4-4.7l36.8-36.8h.1c0-.1.1-.1.1-.2h.2L221,140.6l23.3-11.7a15.8,15.8,0,0,0,8.1-9.3A16.2,16.2,0,0,0,251.3,107.2ZM198.7,142l-38-27.6a8,8,0,0,0-9.5.1l-12.8,9.6a24.3,24.3,0,0,1-28.8,0l-5.4-4.1,39.1-39.1h35.9l27.2,52.2ZM43.5,66.5l17.6,8.8L36.5,122.4l-17.6-8.8ZM157.5,184,99.6,169.5,50.2,130.9,77.4,78.7l48.7-14.2,7.7,3.5L132,69.5,92.9,108.7a15.9,15.9,0,0,0,1.7,24.1l5.4,4.1a40.2,40.2,0,0,0,48,0l8.1-6.1,31.6,23Zm79.6-69.4-17.6,8.9L194.9,76.4l17.6-8.9,24.6,47.1ZM119.8,214.8a8.1,8.1,0,0,1-7.8,6.1,6.3,6.3,0,0,1-1.9-.3l-30.2-7.5a15.7,15.7,0,0,1-6.6-3.5L50.8,190a7.9,7.9,0,1,1,10.4-12l22.6,19.6,30.1,7.5A8.1,8.1,0,0,1,119.8,214.8Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M119.8,214.8a8.1,8.1,0,0,1-7.8,6.1,6.3,6.3,0,0,1-1.9-.3l-30.2-7.5a15.7,15.7,0,0,1-6.6-3.5L50.8,190a7.9,7.9,0,1,1,10.4-12l22.6,19.6,30.1,7.5A8.1,8.1,0,0,1,119.8,214.8Zm132.6-95.2a15.8,15.8,0,0,1-8.1,9.3L221,140.6l-14.9,17.5h-.2c0,.1-.1.1-.1.2h-.1l-36.8,36.8a16.5,16.5,0,0,1-11.4,4.7,15.8,15.8,0,0,1-3.8-.5L95.7,185a15.2,15.2,0,0,1-6-2.9l-54-42.2-24-12a16.3,16.3,0,0,1-8.1-9.4,16,16,0,0,1,1.1-12.3L29.3,59.1a16.1,16.1,0,0,1,21.3-7L73,63.3l48.7-14.2a16.6,16.6,0,0,1,11,.8l33,15h16.4l23.3-11.7a16,16,0,0,1,21.3,6.9l24.6,47.1A16.2,16.2,0,0,1,252.4,119.6Zm-64.7,34.2-31.6-23-8.1,6.1a40.2,40.2,0,0,1-48,0l-5.4-4.1a15.9,15.9,0,0,1-1.7-24.1L132,69.5l1.8-1.5-7.7-3.5L77.4,78.7,50.2,130.9l49.4,38.6L157.5,184Zm18.7-20.7L179.2,80.9H143.3L104.2,120l5.4,4.1a24.3,24.3,0,0,0,28.8,0l12.8-9.6a8,8,0,0,1,9.5-.1l38,27.6Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M249.5,108.1,225,61.1A14.1,14.1,0,0,0,206.3,55L182.6,66.9H165.3L131.9,51.8a13.5,13.5,0,0,0-9.7-.7L72.8,65.5,49.7,53.9A14.2,14.2,0,0,0,31,60L6.5,107.1a13.8,13.8,0,0,0,6.1,19l24.2,12.1L91,180.5a12.8,12.8,0,0,0,5.2,2.6l58,14.5a12.4,12.4,0,0,0,3.3.4,14.1,14.1,0,0,0,9.9-4.1l36.8-36.8h.4L219.8,139l23.6-11.8a14.2,14.2,0,0,0,7.1-8.3A14,14,0,0,0,249.5,108.1ZM199,144.7,159.5,116a6,6,0,0,0-7.1.1l-12.8,9.6a26.1,26.1,0,0,1-31.2,0l-5.4-4.1a1.7,1.7,0,0,1-.8-1.5,1.8,1.8,0,0,1,.6-1.5l39.1-39.2a2.4,2.4,0,0,1,1.4-.5h37.1l28.4,54.4ZM17,114.2a1.8,1.8,0,0,1,.1-1.6L41.7,65.5a2,2,0,0,1,1.8-1,1.1,1.1,0,0,1,.8.2l19.6,9.7L37.4,125.1,18,115.3A1.7,1.7,0,0,1,17,114.2Zm142,71.2a2,2,0,0,1-1.9.5l-58-14.4-.7-.4L47.6,131.4,76,77l49.6-14.4a2.3,2.3,0,0,1,1.4.1l11.3,5.1a14.3,14.3,0,0,0-4.9,3.2L94.3,110.1a13.9,13.9,0,0,0,1.5,21.1l5.4,4.1a38.4,38.4,0,0,0,45.6,0l9.3-7,34.7,25.3Zm80-70.2a1.8,1.8,0,0,1-1,1.2l-19.4,9.7L192.1,75.5l19.6-9.8a2,2,0,0,1,2.6.9l24.6,47.1A1.6,1.6,0,0,1,239,115.2ZM117.8,214.3a6.1,6.1,0,0,1-5.8,4.6l-1.5-.2-30.1-7.6a13.5,13.5,0,0,1-5.8-3L52.1,188.5a6,6,0,1,1,7.8-9l22.6,19.6a2,2,0,0,0,.8.4l30.2,7.5A6,6,0,0,1,117.8,214.3Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M247.8,109.1,223.2,62a12,12,0,0,0-16-5.2L183.1,68.9H164.9L131.1,53.6a11.9,11.9,0,0,0-8.3-.6L72.6,67.6,48.8,55.7a12,12,0,0,0-16,5.2L8.2,108a12.1,12.1,0,0,0-.8,9.3,12.3,12.3,0,0,0,6.1,7l24.4,12.2L92.2,179a13.5,13.5,0,0,0,4.5,2.2l57.9,14.4a10.1,10.1,0,0,0,2.9.4,11.7,11.7,0,0,0,8.5-3.5l36.8-36.8.2-.2,15.5-18.1,24-12a12.2,12.2,0,0,0,6.1-7.1A11.8,11.8,0,0,0,247.8,109.1Zm-48.4,38.4-41-29.9a4,4,0,0,0-4.8.1l-12.8,9.6a28.2,28.2,0,0,1-33.6,0l-5.4-4.1a3.7,3.7,0,0,1-1.6-2.9,3.6,3.6,0,0,1,1.2-3.1L140.5,78a4.1,4.1,0,0,1,2.8-1.1h38.3l29.6,56.7ZM15.1,114.8a3.7,3.7,0,0,1,.2-3.1L39.9,64.6a3.9,3.9,0,0,1,3.6-2.1,3.3,3.3,0,0,1,1.7.4L66.6,73.5,38.3,127.7,17.1,117.1A3.5,3.5,0,0,1,15.1,114.8Zm145.3,72a4.2,4.2,0,0,1-3.8,1.1l-58-14.5a5.1,5.1,0,0,1-1.5-.7L45.1,132,74.7,75.3,125,60.7a3.9,3.9,0,0,1,2.8.2l17.7,8h-2.2a11.9,11.9,0,0,0-8.5,3.5L95.7,111.5A12,12,0,0,0,97,129.6l5.4,4.1a36.3,36.3,0,0,0,43.2,0l10.4-7.9,37.9,27.5Zm80.5-70.9a3.8,3.8,0,0,1-2,2.3l-21.2,10.6L189.4,74.6,210.8,64a4,4,0,0,1,5.3,1.7l24.6,47.1A3.7,3.7,0,0,1,240.9,115.9Zm-125,97.9a4.1,4.1,0,0,1-3.9,3.1l-1-.2-30.1-7.5a12,12,0,0,1-5-2.6L53.4,187a4,4,0,1,1,5.2-6l22.6,19.6a3.5,3.5,0,0,0,1.6.8L113,209A4,4,0,0,1,115.9,213.8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M251.3,107.2,226.7,60.1a16,16,0,0,0-21.3-6.9L182.1,64.9H165.7l-33-15a16.6,16.6,0,0,0-11-.8L73,63.3,50.6,52.1a16.1,16.1,0,0,0-21.3,7L4.7,106.2a16,16,0,0,0-1.1,12.3,16.3,16.3,0,0,0,8.1,9.4l24,12,54,42.2a15.2,15.2,0,0,0,6,2.9l58,14.5a15.8,15.8,0,0,0,3.8.5,16.5,16.5,0,0,0,11.4-4.7l36.8-36.8h.1c0-.1.1-.1.1-.2h.2L221,140.6l23.3-11.7a15.8,15.8,0,0,0,8.1-9.3A16.2,16.2,0,0,0,251.3,107.2ZM198.7,142l-38-27.6a8,8,0,0,0-9.5.1l-12.8,9.6a24.3,24.3,0,0,1-28.8,0l-5.4-4.1,39.1-39.1h35.9l27.2,52.2ZM43.5,66.5l17.6,8.8L36.5,122.4l-17.6-8.8ZM157.5,184,99.6,169.5,50.2,130.9,77.4,78.7l48.7-14.2,7.7,3.5L132,69.5,92.9,108.7a15.9,15.9,0,0,0,1.7,24.1l5.4,4.1a40.2,40.2,0,0,0,48,0l8.1-6.1,31.6,23Zm79.6-69.4-17.6,8.9L194.9,76.4l17.6-8.9,24.6,47.1ZM119.8,214.8a8.1,8.1,0,0,1-7.8,6.1,6.3,6.3,0,0,1-1.9-.3l-30.2-7.5a15.7,15.7,0,0,1-6.6-3.5L50.8,190a7.9,7.9,0,1,1,10.4-12l22.6,19.6,30.1,7.5A8.1,8.1,0,0,1,119.8,214.8Z" />
        </>
      )}
    </svg>
  );
}

export function PhHardDrive(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M224,60H32A20.1,20.1,0,0,0,12,80v96a20.1,20.1,0,0,0,20,20H224a20.1,20.1,0,0,0,20-20V80A20.1,20.1,0,0,0,224,60Zm-4,112H36V84H220Zm-56-44a16,16,0,1,1,16,16A16,16,0,0,1,164,128Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect x="24" y="72" width="208" height="112" rx="8" />
          </g>
          <path d="M224,64H32A16,16,0,0,0,16,80v96a16,16,0,0,0,16,16H224a16,16,0,0,0,16-16V80A16,16,0,0,0,224,64Zm0,112H32V80H224v96Zm-24-48a12,12,0,1,1-12-12A12,12,0,0,1,200,128Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M224,64H32A16,16,0,0,0,16,80v96a16,16,0,0,0,16,16H224a16,16,0,0,0,16-16V80A16,16,0,0,0,224,64Zm-36,76a12,12,0,1,1,12-12A12,12,0,0,1,188,140Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M224,66H32A14,14,0,0,0,18,80v96a14,14,0,0,0,14,14H224a14,14,0,0,0,14-14V80A14,14,0,0,0,224,66Zm2,110a2,2,0,0,1-2,2H32a2,2,0,0,1-2-2V80a2,2,0,0,1,2-2H224a2,2,0,0,1,2,2Zm-28-48a10,10,0,1,1-10-10A10,10,0,0,1,198,128Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M224,68H32A12,12,0,0,0,20,80v96a12,12,0,0,0,12,12H224a12,12,0,0,0,12-12V80A12,12,0,0,0,224,68Zm4,108a4,4,0,0,1-4,4H32a4,4,0,0,1-4-4V80a4,4,0,0,1,4-4H224a4,4,0,0,1,4,4Zm-32-48a8,8,0,1,1-8-8A8,8,0,0,1,196,128Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M224,64H32A16,16,0,0,0,16,80v96a16,16,0,0,0,16,16H224a16,16,0,0,0,16-16V80A16,16,0,0,0,224,64Zm0,112H32V80H224v96Zm-24-48a12,12,0,1,1-12-12A12,12,0,0,1,200,128Z" />
        </>
      )}
    </svg>
  );
}

export function PhHardDrives(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M160,88a16,16,0,1,1,16,16A16,16,0,0,1,160,88Zm16,64a16,16,0,1,0,16,16A16,16,0,0,0,176,152Zm52-96V200a20.1,20.1,0,0,1-20,20H48a20.1,20.1,0,0,1-20-20V56A20.1,20.1,0,0,1,48,36H208A20.1,20.1,0,0,1,228,56ZM52,60v56H204V60ZM204,196V140H52v56Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect x="40" y="144" width="176" height="64" rx="8" />
          </g>
          <g opacity="0.2">
            <rect x="40" y="48" width="176" height="64" rx="8" />
          </g>
          <path d="M208,136H48a16,16,0,0,0-16,16v48a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V152A16,16,0,0,0,208,136Zm0,64H48V152H208v48Zm0-160H48A16,16,0,0,0,32,56v48a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V56A16,16,0,0,0,208,40Zm0,64H48V56H208v48ZM192,80a12,12,0,1,1-12-12A12,12,0,0,1,192,80Zm0,96a12,12,0,1,1-12-12A12,12,0,0,1,192,176Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M208,40H48A16,16,0,0,0,32,56v48a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V56A16,16,0,0,0,208,40ZM180,92a12,12,0,1,1,12-12A12,12,0,0,1,180,92Zm28,44H48a16,16,0,0,0-16,16v48a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V152A16,16,0,0,0,208,136Zm-28,52a12,12,0,1,1,12-12A12,12,0,0,1,180,188Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M208,138H48a14,14,0,0,0-14,14v48a14,14,0,0,0,14,14H208a14,14,0,0,0,14-14V152A14,14,0,0,0,208,138Zm2,62a2,2,0,0,1-2,2H48a2,2,0,0,1-2-2V152a2,2,0,0,1,2-2H208a2,2,0,0,1,2,2ZM208,42H48A14,14,0,0,0,34,56v48a14,14,0,0,0,14,14H208a14,14,0,0,0,14-14V56A14,14,0,0,0,208,42Zm2,62a2,2,0,0,1-2,2H48a2,2,0,0,1-2-2V56a2,2,0,0,1,2-2H208a2,2,0,0,1,2,2ZM190,80a10,10,0,1,1-10-10A10,10,0,0,1,190,80Zm0,96a10,10,0,1,1-10-10A10,10,0,0,1,190,176Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M208,140H48a12,12,0,0,0-12,12v48a12,12,0,0,0,12,12H208a12,12,0,0,0,12-12V152A12,12,0,0,0,208,140Zm4,60a4,4,0,0,1-4,4H48a4,4,0,0,1-4-4V152a4,4,0,0,1,4-4H208a4,4,0,0,1,4,4ZM208,44H48A12,12,0,0,0,36,56v48a12,12,0,0,0,12,12H208a12,12,0,0,0,12-12V56A12,12,0,0,0,208,44Zm4,60a4,4,0,0,1-4,4H48a4,4,0,0,1-4-4V56a4,4,0,0,1,4-4H208a4,4,0,0,1,4,4ZM188,80a8,8,0,1,1-8-8A8,8,0,0,1,188,80Zm0,96a8,8,0,1,1-8-8A8,8,0,0,1,188,176Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M208,136H48a16,16,0,0,0-16,16v48a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V152A16,16,0,0,0,208,136Zm0,64H48V152H208v48Zm0-160H48A16,16,0,0,0,32,56v48a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V56A16,16,0,0,0,208,40Zm0,64H48V56H208v48ZM192,80a12,12,0,1,1-12-12A12,12,0,0,1,192,80Zm0,96a12,12,0,1,1-12-12A12,12,0,0,1,192,176Z" />
        </>
      )}
    </svg>
  );
}

export function PhHashStraight(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216,148H172V108h44a12,12,0,0,0,0-24H172V40a12,12,0,0,0-24,0V84H108V40a12,12,0,0,0-24,0V84H40a12,12,0,0,0,0,24H84v40H40a12,12,0,0,0,0,24H84v44a12,12,0,0,0,24,0V172h40v44a12,12,0,0,0,24,0V172h44a12,12,0,0,0,0-24Zm-108,0V108h40v40Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M216,152H168V104h48a8,8,0,0,0,0-16H168V40a8,8,0,0,0-16,0V88H104V40a8,8,0,0,0-16,0V88H40a8,8,0,0,0,0,16H88v48H40a8,8,0,0,0,0,16H88v48a8,8,0,0,0,16,0V168h48v48a8,8,0,0,0,16,0V168h48a8,8,0,0,0,0-16Zm-112,0V104h48v48Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M160,224a8,8,0,0,1-8-8V168H104v48a8,8,0,0,1-16,0V168H40a8,8,0,0,1,0-16H88V104H40a8,8,0,0,1,0-16H88V40a8,8,0,0,1,16,0V88h48V40a8,8,0,0,1,16,0V88h48a8,8,0,0,1,0,16H168v48h48a8,8,0,0,1,0,16H168v48A8,8,0,0,1,160,224Zm-56-72h48V104H104Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M216,154H166V102h50a6,6,0,0,0,0-12H166V40a6,6,0,0,0-12,0V90H102V40a6,6,0,0,0-12,0V90H40a6,6,0,0,0,0,12H90v52H40a6,6,0,0,0,0,12H90v50a6,6,0,0,0,12,0V166h52v50a6,6,0,0,0,12,0V166h50a6,6,0,0,0,0-12Zm-114,0V102h52v52Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M216,156H164V100h52a4,4,0,0,0,0-8H164V40a4,4,0,0,0-8,0V92H100V40a4,4,0,0,0-8,0V92H40a4,4,0,0,0,0,8H92v56H40a4,4,0,0,0,0,8H92v52a4,4,0,0,0,8,0V164h56v52a4,4,0,0,0,8,0V164h52a4,4,0,0,0,0-8Zm-116,0V100h56v56Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,152H168V104h48a8,8,0,0,0,0-16H168V40a8,8,0,0,0-16,0V88H104V40a8,8,0,0,0-16,0V88H40a8,8,0,0,0,0,16H88v48H40a8,8,0,0,0,0,16H88v48a8,8,0,0,0,16,0V168h48v48a8,8,0,0,0,16,0V168h48a8,8,0,0,0,0-16Zm-112,0V104h48v48Z" />
        </>
      )}
    </svg>
  );
}

export function PhHash(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M224,108a12,12,0,0,0,0-24H180.2l7.6-41.8a12,12,0,1,0-23.6-4.3L155.8,84H116.2l7.6-41.8a12,12,0,1,0-23.6-4.3L91.8,84H43.6a12,12,0,1,0,0,24H87.4l-7.2,40H32a12,12,0,0,0,0,24H75.8l-7.6,41.9a11.9,11.9,0,0,0,9.7,13.9l2.1.2a12,12,0,0,0,11.8-9.8l8.4-46.2h39.6l-7.6,41.9a11.9,11.9,0,0,0,9.7,13.9l2.1.2a12,12,0,0,0,11.8-9.8l8.4-46.2h48.2a12,12,0,0,0,0-24H168.6l7.2-40Zm-79.8,40H104.6l7.2-40h39.6Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M224,88H175.4l8.5-46.6a8,8,0,0,0-15.8-2.8l-9,49.4H111.4l8.5-46.6a8,8,0,1,0-15.8-2.8L95.1,88H43.6a8,8,0,1,0,0,16H92.2l-8.7,48H32a8,8,0,0,0,0,16H80.6l-8.5,46.6a8,8,0,0,0,6.5,9.3H80a8,8,0,0,0,7.9-6.6l9-49.4h47.7l-8.5,46.6a8,8,0,0,0,6.5,9.3H144a8,8,0,0,0,7.9-6.6l9-49.4h51.5a8,8,0,0,0,0-16H163.8l8.7-48H224a8,8,0,0,0,0-16Zm-76.5,64H99.8l8.7-48h47.7Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M144,224h-1.4a8,8,0,0,1-6.5-9.3l8.5-46.6H96.9l-9,49.4a8,8,0,1,1-15.8-2.8L80.6,168H32a8,8,0,0,1,0-16H83.5l8.7-48H43.6a8,8,0,1,1,0-16H95.1l9-49.4a8,8,0,1,1,15.8,2.8L111.4,88h47.7l9-49.4a8,8,0,0,1,15.8,2.8L175.4,88H224a8,8,0,0,1,0,16H172.5l-8.7,48h48.6a8,8,0,0,1,0,16H160.9l-9,49.4A8,8,0,0,1,144,224ZM99.8,152h47.7l8.7-48H108.5Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M224,90H173l8.9-48.9a6,6,0,0,0-11.8-2.2L160.8,90H109l8.9-48.9a6,6,0,0,0-11.8-2.2L96.8,90H43.6a6,6,0,1,0,0,12h51l-9.4,52H32a6,6,0,0,0,0,12H83l-8.9,48.9a6,6,0,0,0,4.8,7H80a5.9,5.9,0,0,0,5.9-4.9L95.2,166H147l-8.9,48.9a6,6,0,0,0,4.8,7H144a5.9,5.9,0,0,0,5.9-4.9l9.3-51.1h53.2a6,6,0,0,0,0-12h-51l9.4-52H224a6,6,0,0,0,0-12Zm-74.8,64H97.4l9.4-52h51.8Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M224,92H170.6l9.3-51.3a4,4,0,1,0-7.8-1.4L162.5,92H106.6l9.3-51.3a4,4,0,1,0-7.8-1.4L98.5,92H43.6a4,4,0,1,0,0,8H97L86.8,156H32a4,4,0,0,0,0,8H85.4l-9.3,51.3a3.9,3.9,0,0,0,3.2,4.6H80a3.9,3.9,0,0,0,3.9-3.3L93.5,164h55.9l-9.3,51.3a3.9,3.9,0,0,0,3.2,4.6h.7a3.9,3.9,0,0,0,3.9-3.3l9.6-52.7h54.9a4,4,0,0,0,0-8H159l10.2-56H224a4,4,0,0,0,0-8Zm-73.2,64H95l10.2-56H161Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M224,88H175.4l8.5-46.6a8,8,0,0,0-15.8-2.8l-9,49.4H111.4l8.5-46.6a8,8,0,1,0-15.8-2.8L95.1,88H43.6a8,8,0,1,0,0,16H92.2l-8.7,48H32a8,8,0,0,0,0,16H80.6l-8.5,46.6a8,8,0,0,0,6.5,9.3H80a8,8,0,0,0,7.9-6.6l9-49.4h47.7l-8.5,46.6a8,8,0,0,0,6.5,9.3H144a8,8,0,0,0,7.9-6.6l9-49.4h51.5a8,8,0,0,0,0-16H163.8l8.7-48H224a8,8,0,0,0,0-16Zm-76.5,64H99.8l8.7-48h47.7Z" />
        </>
      )}
    </svg>
  );
}

export function PhHeadlights(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M164,80a12,12,0,0,1,12-12h64a12,12,0,0,1,0,24H176A12,12,0,0,1,164,80Zm76,84H176a12,12,0,0,0,0,24h64a12,12,0,0,0,0-24Zm0-48H176a12,12,0,0,0,0,24h64a12,12,0,0,0,0-24ZM148,64V192a20.1,20.1,0,0,1-20,20H88A84,84,0,0,1,4,127.4C4.3,81.4,42.4,44,88.9,44H128A20.1,20.1,0,0,1,148,64Zm-24,4H88.9C55.6,68,28.2,94.7,28,127.5A59.9,59.9,0,0,0,88,188h36Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M136,64V192a8,8,0,0,1-8,8H88a72,72,0,0,1-72-72.5C16.3,87.8,49.2,56,88.9,56H128A8,8,0,0,1,136,64Z"
            opacity="0.2"
          />
          <path d="M160,80a8,8,0,0,1,8-8h72a8,8,0,0,1,0,16H168A8,8,0,0,1,160,80Zm80,88H168a8,8,0,0,0,0,16h72a8,8,0,0,0,0-16Zm0-64H168a8,8,0,0,0,0,16h72a8,8,0,0,0,0-16Zm0,32H168a8,8,0,0,0,0,16h72a8,8,0,0,0,0-16ZM144,64V192a16,16,0,0,1-16,16H88A79.9,79.9,0,0,1,8,127.4C8.3,83.6,44.6,48,88.9,48H128A16,16,0,0,1,144,64Zm-16,0H88.9C53.4,64,24.3,92.5,24,127.5A64,64,0,0,0,88,192h40Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M144,64V192a16,16,0,0,1-16,16H88A79.9,79.9,0,0,1,8,127.4C8.3,83.6,44.6,48,88.9,48H128A16,16,0,0,1,144,64Zm96,104H168a8,8,0,0,0,0,16h72a8,8,0,0,0,0-16ZM168,88h72a8,8,0,0,0,0-16H168a8,8,0,0,0,0,16Zm72,16H168a8,8,0,0,0,0,16h72a8,8,0,0,0,0-16Zm0,32H168a8,8,0,0,0,0,16h72a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M162,80a6,6,0,0,1,6-6h72a6,6,0,0,1,0,12H168A6,6,0,0,1,162,80Zm78,90H168a6,6,0,0,0,0,12h72a6,6,0,0,0,0-12Zm0-64H168a6,6,0,0,0,0,12h72a6,6,0,0,0,0-12Zm0,32H168a6,6,0,0,0,0,12h72a6,6,0,0,0,0-12ZM142,64V192a14,14,0,0,1-14,14H88a78,78,0,0,1-78-78.6C10.3,84.7,45.7,50,88.9,50H128A14,14,0,0,1,142,64Zm-12,0a2,2,0,0,0-2-2H88.9C52.3,62,22.3,91.4,22,127.5A66.1,66.1,0,0,0,88,194h40a2,2,0,0,0,2-2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M164,80a4,4,0,0,1,4-4h72a4,4,0,0,1,0,8H168A4,4,0,0,1,164,80Zm76,92H168a4,4,0,0,0,0,8h72a4,4,0,0,0,0-8Zm0-64H168a4,4,0,0,0,0,8h72a4,4,0,0,0,0-8Zm0,32H168a4,4,0,0,0,0,8h72a4,4,0,0,0,0-8ZM140,64V192a12,12,0,0,1-12,12H88a76.1,76.1,0,0,1-76-76.6C12.3,85.8,46.8,52,88.9,52H128A12,12,0,0,1,140,64Zm-8,0a4,4,0,0,0-4-4H88.9C51.2,60,20.3,90.3,20,127.5A67.9,67.9,0,0,0,88,196h40a4,4,0,0,0,4-4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M160,80a8,8,0,0,1,8-8h72a8,8,0,0,1,0,16H168A8,8,0,0,1,160,80Zm80,88H168a8,8,0,0,0,0,16h72a8,8,0,0,0,0-16Zm0-64H168a8,8,0,0,0,0,16h72a8,8,0,0,0,0-16Zm0,32H168a8,8,0,0,0,0,16h72a8,8,0,0,0,0-16ZM144,64V192a16,16,0,0,1-16,16H88A79.9,79.9,0,0,1,8,127.4C8.3,83.6,44.6,48,88.9,48H128A16,16,0,0,1,144,64Zm-16,0H88.9C53.4,64,24.3,92.5,24,127.5A64,64,0,0,0,88,192h40Z" />
        </>
      )}
    </svg>
  );
}

export function PhHeadphones(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M205.5,59.3A107.1,107.1,0,0,0,128.7,28,107.9,107.9,0,0,0,20,136v56a28.1,28.1,0,0,0,28,28H64a28.1,28.1,0,0,0,28-28V152a28.1,28.1,0,0,0-28-28H44.8A84.1,84.1,0,0,1,128,52h.8a84.1,84.1,0,0,1,83.8,72H193.5a28,28,0,0,0-28,28v40a28,28,0,0,0,28,28h16a28.1,28.1,0,0,0,28-28V136A107.5,107.5,0,0,0,205.5,59.3ZM64,148a4,4,0,0,1,4,4v40a4,4,0,0,1-4,4H48a4,4,0,0,1-4-4V148Zm149.5,44a4,4,0,0,1-4,4h-16a3.9,3.9,0,0,1-4-4V152a3.9,3.9,0,0,1,4-4h20Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M32,136v56a16,16,0,0,0,16,16H64a16,16,0,0,0,16-16V152a16,16,0,0,0-16-16Z" />
          </g>
          <g opacity="0.2">
            <path d="M225.5,136h-32a16,16,0,0,0-16,16v40a16,16,0,0,0,16,16h16a16,16,0,0,0,16-16Z" />
          </g>
          <path d="M202.7,62.2A103.1,103.1,0,0,0,129.5,32H128A104,104,0,0,0,24,136v56a24.1,24.1,0,0,0,24,24H64a24.1,24.1,0,0,0,24-24V152a24.1,24.1,0,0,0-24-24H40.4a87.8,87.8,0,0,1,88.3-80h.1a88,88,0,0,1,88.3,80H193.5a24,24,0,0,0-24,24v40a24,24,0,0,0,24,24h16a24.1,24.1,0,0,0,24-24V136A103.5,103.5,0,0,0,202.7,62.2ZM64,144a8,8,0,0,1,8,8v40a8,8,0,0,1-8,8H48a8,8,0,0,1-8-8V144Zm153.5,48a8,8,0,0,1-8,8h-16a8,8,0,0,1-8-8V152a8,8,0,0,1,8-8h24Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M233.5,136v56a24.1,24.1,0,0,1-24,24h-16a24,24,0,0,1-24-24V152a24,24,0,0,1,24-24h23.6a88,88,0,0,0-88.3-80h-.1a87.8,87.8,0,0,0-88.3,80H64a24.1,24.1,0,0,1,24,24v40a24.1,24.1,0,0,1-24,24H48a24.1,24.1,0,0,1-24-24V136A104,104,0,0,1,128.7,32h.8a104.1,104.1,0,0,1,104,104Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M201.3,63.6A101.1,101.1,0,0,0,128.8,34H128A101.9,101.9,0,0,0,26,136v56a22.1,22.1,0,0,0,22,22H64a22.1,22.1,0,0,0,22-22V152a22.1,22.1,0,0,0-22-22H38.2a90,90,0,0,1,89.9-84h1.4a90,90,0,0,1,89.8,84H193.5a22,22,0,0,0-22,22v40a22,22,0,0,0,22,22h16a22.1,22.1,0,0,0,22-22V136A101.1,101.1,0,0,0,201.3,63.6ZM64,142a10,10,0,0,1,10,10v40a10,10,0,0,1-10,10H48a10,10,0,0,1-10-10V142Zm155.5,50a10,10,0,0,1-10,10h-16a10,10,0,0,1-10-10V152a10,10,0,0,1,10-10h26Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M199.9,65a99.3,99.3,0,0,0-71.1-29A100,100,0,0,0,28,136v56a20.1,20.1,0,0,0,20,20H64a20.1,20.1,0,0,0,20-20V152a20.1,20.1,0,0,0-20-20H36.1a92,92,0,0,1,92.7-88,92,92,0,0,1,92.6,88H193.5a20,20,0,0,0-20,20v40a20,20,0,0,0,20,20h16a20.1,20.1,0,0,0,20-20V136A99.1,99.1,0,0,0,199.9,65ZM64,140a12,12,0,0,1,12,12v40a12,12,0,0,1-12,12H48a12,12,0,0,1-12-12V140Zm157.5,52a12,12,0,0,1-12,12h-16a12,12,0,0,1-12-12V152a12,12,0,0,1,12-12h28Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M202.7,62.2a103.1,103.1,0,0,0-74-30.2H128A104,104,0,0,0,24,136v56a24.1,24.1,0,0,0,24,24H64a24.1,24.1,0,0,0,24-24V152a24.1,24.1,0,0,0-24-24H40.4a87.8,87.8,0,0,1,88.3-80h.1a88,88,0,0,1,88.3,80H193.5a24,24,0,0,0-24,24v40a24,24,0,0,0,24,24h16a24.1,24.1,0,0,0,24-24V136A103.5,103.5,0,0,0,202.7,62.2ZM64,144a8,8,0,0,1,8,8v40a8,8,0,0,1-8,8H48a8,8,0,0,1-8-8V144Zm153.5,48a8,8,0,0,1-8,8h-16a8,8,0,0,1-8-8V152a8,8,0,0,1,8-8h24Z" />
        </>
      )}
    </svg>
  );
}

export function PhHeadset(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M205.5,51.3A107.1,107.1,0,0,0,128.7,20,107.9,107.9,0,0,0,20,128v56a28.1,28.1,0,0,0,28,28H64a28.1,28.1,0,0,0,28-28V144a28.1,28.1,0,0,0-28-28H44.8A84.1,84.1,0,0,1,128,44h1.5a84.1,84.1,0,0,1,83.1,72H193.5a28,28,0,0,0-28,28v40a28,28,0,0,0,28,28h16l3.6-.2A20,20,0,0,1,193.5,228H136a12,12,0,0,0,0,24h57.5a44.1,44.1,0,0,0,44-44V128A107.5,107.5,0,0,0,205.5,51.3ZM64,140a4,4,0,0,1,4,4v40a4,4,0,0,1-4,4H48a4,4,0,0,1-4-4V140Zm145.5,48h-16a3.9,3.9,0,0,1-4-4V144a3.9,3.9,0,0,1,4-4h20v44A4,4,0,0,1,209.5,188Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M32,128v56a16,16,0,0,0,16,16H64a16,16,0,0,0,16-16V144a16,16,0,0,0-16-16Z" />
          </g>
          <g opacity="0.2">
            <path d="M225.5,128h-32a16,16,0,0,0-16,16v40a16,16,0,0,0,16,16h16a16,16,0,0,0,16-16Z" />
          </g>
          <path d="M202.7,54.2A103.1,103.1,0,0,0,129.5,24H128A104,104,0,0,0,24,128v56a24.1,24.1,0,0,0,24,24H64a24.1,24.1,0,0,0,24-24V144a24.1,24.1,0,0,0-24-24H40.4a87.8,87.8,0,0,1,88.3-80h.1a88,88,0,0,1,88.3,80H193.5a24,24,0,0,0-24,24v40a24,24,0,0,0,24,24h16a23.6,23.6,0,0,0,8-1.4V208a24.1,24.1,0,0,1-24,24H136a8,8,0,0,0,0,16h57.5a40.1,40.1,0,0,0,40-40V128A103.5,103.5,0,0,0,202.7,54.2ZM64,136a8,8,0,0,1,8,8v40a8,8,0,0,1-8,8H48a8,8,0,0,1-8-8V136Zm145.5,56h-16a8,8,0,0,1-8-8V144a8,8,0,0,1,8-8h24v48A8,8,0,0,1,209.5,192Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M233.5,128v80a40.1,40.1,0,0,1-40,40H136a8,8,0,0,1,0-16h57.5a24.1,24.1,0,0,0,24-24v-1.4a23.6,23.6,0,0,1-8,1.4h-16a24,24,0,0,1-24-24V144a24,24,0,0,1,24-24h23.6a88,88,0,0,0-88.3-80h-.1a87.8,87.8,0,0,0-88.3,80H64a24.1,24.1,0,0,1,24,24v40a24.1,24.1,0,0,1-24,24H48a24.1,24.1,0,0,1-24-24V128A104,104,0,0,1,128.7,24h.8a104.1,104.1,0,0,1,104,104Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M201.3,55.6A101.1,101.1,0,0,0,128.8,26H128A101.9,101.9,0,0,0,26,128v56a22.1,22.1,0,0,0,22,22H64a22.1,22.1,0,0,0,22-22V144a22.1,22.1,0,0,0-22-22H38.2a90,90,0,0,1,89.9-84h.7a90,90,0,0,1,90.5,84H193.5a22,22,0,0,0-22,22v40a22,22,0,0,0,22,22h16a22.3,22.3,0,0,0,10-2.4V208a26.1,26.1,0,0,1-26,26H136a6,6,0,0,0,0,12h57.5a38,38,0,0,0,38-38V128A101.1,101.1,0,0,0,201.3,55.6ZM64,134a10,10,0,0,1,10,10v40a10,10,0,0,1-10,10H48a10,10,0,0,1-10-10V134Zm145.5,60h-16a10,10,0,0,1-10-10V144a10,10,0,0,1,10-10h26v50A10,10,0,0,1,209.5,194Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M199.9,57a99.3,99.3,0,0,0-71.1-29A100,100,0,0,0,28,128v56a20.1,20.1,0,0,0,20,20H64a20.1,20.1,0,0,0,20-20V144a20.1,20.1,0,0,0-20-20H36.1a92,92,0,0,1,92.7-88,92,92,0,0,1,92.6,88H193.5a20,20,0,0,0-20,20v40a20,20,0,0,0,20,20h16a20.1,20.1,0,0,0,12-4v8a28.1,28.1,0,0,1-28,28H136a4,4,0,0,0,0,8h57.5a36,36,0,0,0,36-36V128A99.1,99.1,0,0,0,199.9,57ZM64,132a12,12,0,0,1,12,12v40a12,12,0,0,1-12,12H48a12,12,0,0,1-12-12V132Zm145.5,64h-16a12,12,0,0,1-12-12V144a12,12,0,0,1,12-12h28v52A12,12,0,0,1,209.5,196Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M202.7,54.2a103.1,103.1,0,0,0-74-30.2H128A104,104,0,0,0,24,128v56a24.1,24.1,0,0,0,24,24H64a24.1,24.1,0,0,0,24-24V144a24.1,24.1,0,0,0-24-24H40.4a87.8,87.8,0,0,1,88.3-80h.1a88,88,0,0,1,88.3,80H193.5a24,24,0,0,0-24,24v40a24,24,0,0,0,24,24h16a23.6,23.6,0,0,0,8-1.4V208a24.1,24.1,0,0,1-24,24H136a8,8,0,0,0,0,16h57.5a40.1,40.1,0,0,0,40-40V128A103.5,103.5,0,0,0,202.7,54.2ZM64,136a8,8,0,0,1,8,8v40a8,8,0,0,1-8,8H48a8,8,0,0,1-8-8V136Zm145.5,56h-16a8,8,0,0,1-8-8V144a8,8,0,0,1,8-8h24v48A8,8,0,0,1,209.5,192Z" />
        </>
      )}
    </svg>
  );
}

export function PhHeartBreak(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M176,28a64,64,0,0,0-45.3,18.7l-2.8,2.9A63.4,63.4,0,0,0,80,28,64.1,64.1,0,0,0,16,92c0,74.2,101.8,132,106.1,134.5a12.4,12.4,0,0,0,11.8,0C138.2,224,240,166.2,240,92A64.1,64.1,0,0,0,176,28ZM128,202c-20.6-12.8-88-58.8-88-110a40,40,0,0,1,70.9-25.4L98.7,78.7a12.2,12.2,0,0,0,0,17l23.6,23.5-7.6,7.5a12.2,12.2,0,0,0,0,17,12,12,0,0,0,17,0l16-16a12,12,0,0,0,0-17L124.2,87.2l10.3-10.3.4-.4,12.8-12.8A40,40,0,0,1,216,92C216,143.2,148.6,189.2,128,202Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M228,92c0,68-100,124-100,124S28,160,28,92a52,52,0,0,1,98.3-23.8l12.9-13A52,52,0,0,1,228,92Z"
            opacity="0.2"
          />
          <path d="M176,32a59.3,59.3,0,0,0-42.4,17.6l-6,5.9A60,60,0,0,0,20,92c0,71.9,99.8,128.6,104.1,131a8.1,8.1,0,0,0,7.8,0,314.3,314.3,0,0,0,51.5-37.6C218.3,154,236,122.6,236,92A60,60,0,0,0,176,32ZM128,206.7c-18-11-92-59.5-92-114.7a44,44,0,0,1,80.2-25L101.6,81.6a7.9,7.9,0,0,0,0,11.3l26.3,26.3-10.3,10.4a7.9,7.9,0,0,0,0,11.3,8,8,0,0,0,11.3,0l16-16a8,8,0,0,0,0-11.3L118.5,87.2,131.8,74l.2-.3,12.9-12.8A44,44,0,0,1,220,92C220,147.2,146,195.7,128,206.7Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M236,92c0,30.6-17.7,62-52.6,93.4A314.3,314.3,0,0,1,131.9,223a8.1,8.1,0,0,1-7.8,0C119.8,220.6,20,163.9,20,92a60,60,0,0,1,92.3-50.6,4,4,0,0,1,.6,6.2l-18,18a8,8,0,0,0,0,11.3l34.4,34.3-15,15.1a7.9,7.9,0,0,0,0,11.3,8,8,0,0,0,5.6,2.3,8.3,8.3,0,0,0,5.7-2.3l20.7-20.7a8,8,0,0,0,0-11.3L111.9,71.2l21.7-21.6h0A59.3,59.3,0,0,1,176,32,60,60,0,0,1,236,92Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M176,34a57.3,57.3,0,0,0-41,17l-7.6,7.6A58,58,0,0,0,22,92c0,30,17.5,60.9,52,92,25.2,22.6,50,36.7,51.1,37.2a5.7,5.7,0,0,0,5.8,0c1.1-.5,25.9-14.6,51.1-37.2,34.5-31.1,52-62,52-92A58,58,0,0,0,176,34ZM128,209c-16.2-9.7-94-59.7-94-117a46,46,0,0,1,84.8-24.8L103,83a5.9,5.9,0,0,0,0,8.5l27.7,27.7L119,131a5.9,5.9,0,0,0,0,8.5,6.1,6.1,0,0,0,8.5,0l16-16a6.1,6.1,0,0,0,0-8.5L115.7,87.2l14.7-14.7h.2l12.9-12.9A46,46,0,0,1,222,92C222,149.3,144.2,199.3,128,209Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M176,36a55.5,55.5,0,0,0-39.6,16.4l-9.3,9.3A56,56,0,0,0,24,92c0,29.4,17.3,59.8,51.3,90.5a321.4,321.4,0,0,0,50.7,37,4.3,4.3,0,0,0,4,0,321.4,321.4,0,0,0,50.7-37c34-30.7,51.3-61.1,51.3-90.5A56,56,0,0,0,176,36ZM128,211.4C114.2,203.1,32,151.7,32,92a48,48,0,0,1,89.3-24.5L104.4,84.4a4,4,0,0,0,0,5.7l29.2,29.1-13.2,13.2a4,4,0,0,0,0,5.7,4.2,4.2,0,0,0,5.7,0l16-16a4.2,4.2,0,0,0,0-5.7L112.9,87.2,129,71.1h.1l13-12.9A47.4,47.4,0,0,1,176,44a48,48,0,0,1,48,48C224,151.7,141.8,203.1,128,211.4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M176,32a59.3,59.3,0,0,0-42.4,17.6l-6,5.9A60,60,0,0,0,20,92c0,71.9,99.8,128.6,104.1,131a8.1,8.1,0,0,0,7.8,0,314.3,314.3,0,0,0,51.5-37.6C218.3,154,236,122.6,236,92A60,60,0,0,0,176,32ZM128,206.7c-18-11-92-59.5-92-114.7a44,44,0,0,1,80.2-25L101.6,81.6a7.9,7.9,0,0,0,0,11.3l26.3,26.3-10.3,10.4a7.9,7.9,0,0,0,0,11.3,8,8,0,0,0,11.3,0l16-16a8,8,0,0,0,0-11.3L118.5,87.2,131.8,74l.2-.3,12.9-12.8A44,44,0,0,1,220,92C220,147.2,146,195.7,128,206.7Z" />
        </>
      )}
    </svg>
  );
}

export function PhHeartStraightBreak(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M227.6,49.2a64.1,64.1,0,0,0-92.9-2.5L128,53.5l-4.7-4.7a68.8,68.8,0,0,0-44.1-20,62.1,62.1,0,0,0-46,15.6,64.1,64.1,0,0,0-2.5,92.9l83.2,83.1a20,20,0,0,0,28.2,0l81.1-81.1a68.8,68.8,0,0,0,20-44.1A62.1,62.1,0,0,0,227.6,49.2Zm-21.4,73.2L128,200.6,47.7,120.3a40,40,0,0,1,1.6-58.1A38.1,38.1,0,0,1,75,52.7a44.6,44.6,0,0,1,31.4,13.1l5,5-8.3,9.1a12.1,12.1,0,0,0,.4,16.6L127,120l-7.5,7.5a12,12,0,0,0,0,17,12.1,12.1,0,0,0,17,0l16-16a12,12,0,0,0,0-17L128.6,87.6l23.1-23.9a40,40,0,0,1,58.1,1.6C223.8,80.8,222.2,106.4,206.2,122.4Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M214.7,130.9l-81,81a8.1,8.1,0,0,1-11.4,0L39.2,128.8a52,52,0,0,1,2.1-75.5c20.9-18.8,53.6-15.9,73.6,4L128,70.5l15.2-15.3a52,52,0,0,1,75.5,2.1C237.5,78.2,234.6,110.9,214.7,130.9Z"
            opacity="0.2"
          />
          <path d="M224.6,51.9a59.9,59.9,0,0,0-87-2.3L128,59.1l-7.5-7.4A64.3,64.3,0,0,0,79,32.8,57.7,57.7,0,0,0,35.9,47.4a59.9,59.9,0,0,0-2.3,87l83.1,83.1a15.9,15.9,0,0,0,22.6,0l81-81A64.3,64.3,0,0,0,239.2,95,57.7,57.7,0,0,0,224.6,51.9ZM209,125.2l-81,81L44.9,123.1a44,44,0,0,1,1.7-63.9A42.5,42.5,0,0,1,75,48.7,48.4,48.4,0,0,1,109.2,63l7.7,7.7L106.1,82.6a8.1,8.1,0,0,0,.2,11.1L132.7,120l-10.4,10.3a8.1,8.1,0,0,0,0,11.4,8.2,8.2,0,0,0,11.4,0l16-16a8.1,8.1,0,0,0,0-11.4L123.1,87.7l25.8-26.8a44,44,0,0,1,63.9,1.7C228.1,79.7,226.5,107.8,209,125.2Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M239.2,95a64.3,64.3,0,0,1-18.9,41.5l-81,81a15.9,15.9,0,0,1-22.6,0L33.6,134.4a59.9,59.9,0,0,1,2.3-87A57.7,57.7,0,0,1,79,32.8a64.4,64.4,0,0,1,33.8,12.3,4,4,0,0,1,.5,6.1L94.9,69.6a8,8,0,0,0,0,11.3l34.4,34.3-15,15.1a7.9,7.9,0,0,0,0,11.3,8,8,0,0,0,5.6,2.3,8.3,8.3,0,0,0,5.7-2.3l20.7-20.7a8,8,0,0,0,0-11.3L111.9,75.2l21.7-21.6h0l4-4a59.9,59.9,0,0,1,87,2.3A57.7,57.7,0,0,1,239.2,95Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M223.2,53.2A58,58,0,0,0,139,51L128,62l-8.9-8.9A62.8,62.8,0,0,0,78.8,34.8a56.5,56.5,0,0,0-41.6,14A58,58,0,0,0,35,133l83.1,83.1a14,14,0,0,0,19.8,0l81-81a62.8,62.8,0,0,0,18.3-40.3A56.5,56.5,0,0,0,223.2,53.2Zm-12.8,73.4-81,81a1.9,1.9,0,0,1-2.8,0L43.5,124.5a45.8,45.8,0,0,1,1.8-66.7A44,44,0,0,1,75,46.7a50.3,50.3,0,0,1,35.6,14.9l9.1,9.1L107.6,84a5.8,5.8,0,0,0,.2,8.2L135.5,120l-11.7,11.8a5.9,5.9,0,1,0,8.4,8.4l16-16a5.8,5.8,0,0,0,0-8.4l-27.9-28,27.2-28.3a45.8,45.8,0,0,1,66.7,1.8C230.3,79.1,228.6,108.4,210.4,126.6Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M221.7,54.6a56,56,0,0,0-81.3-2.2L128,64.8,117.7,54.5a60.3,60.3,0,0,0-39-17.7A54,54,0,0,0,38.6,50.3a56,56,0,0,0-2.2,81.3l83.1,83.1a12.1,12.1,0,0,0,17,0l81-81a60.3,60.3,0,0,0,17.7-39A54,54,0,0,0,221.7,54.6ZM211.9,128l-81.1,81.1a4,4,0,0,1-5.6,0L42.1,125.9a47.9,47.9,0,0,1,1.8-69.6A45.9,45.9,0,0,1,75,44.7a52.4,52.4,0,0,1,37,15.4l10.5,10.5L109,85.3a4,4,0,0,0,.2,5.5L138.3,120l-13.1,13.2a3.9,3.9,0,0,0,0,5.6,3.9,3.9,0,0,0,5.6,0l16-16a3.9,3.9,0,0,0,0-5.6L117.5,87.9l28.6-29.8a47.9,47.9,0,0,1,69.6,1.8C232.5,78.6,230.8,109.1,211.9,128Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M224.6,51.9a59.9,59.9,0,0,0-87-2.3L128,59.1l-7.5-7.4A64.3,64.3,0,0,0,79,32.8,57.7,57.7,0,0,0,35.9,47.4a59.9,59.9,0,0,0-2.3,87l83.1,83.1a15.9,15.9,0,0,0,22.6,0l81-81A64.3,64.3,0,0,0,239.2,95,57.7,57.7,0,0,0,224.6,51.9ZM209,125.2l-81,81L44.9,123.1a44,44,0,0,1,1.7-63.9A42.5,42.5,0,0,1,75,48.7,48.4,48.4,0,0,1,109.2,63l7.7,7.7L106.1,82.6a8.1,8.1,0,0,0,.2,11.1L132.7,120l-10.4,10.3a8.1,8.1,0,0,0,0,11.4,8.2,8.2,0,0,0,11.4,0l16-16a8.1,8.1,0,0,0,0-11.4L123.1,87.7l25.8-26.8a44,44,0,0,1,63.9,1.7C228.1,79.7,226.5,107.8,209,125.2Z" />
        </>
      )}
    </svg>
  );
}

export function PhHeartStraight(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,226.2a20.1,20.1,0,0,1-14.2-5.8L30.7,137.3a64.1,64.1,0,0,1,2.5-92.9,62.1,62.1,0,0,1,46-15.6,68.8,68.8,0,0,1,44.1,20l4.7,4.7,6.7-6.8a64.1,64.1,0,0,1,92.9,2.5,62.1,62.1,0,0,1,15.6,46,68.8,68.8,0,0,1-20,44.1l-81.1,81.1h0A19.8,19.8,0,0,1,128,226.2Zm5.6-14.3ZM75,52.7a38.1,38.1,0,0,0-25.7,9.5,40,40,0,0,0-1.6,58.1L128,200.6l78.2-78.2c15.9-16,17.5-41.6,3.6-57.1a40,40,0,0,0-58.1-1.6L136.5,78.9a12,12,0,0,1-17,0L106.3,65.8A44.2,44.2,0,0,0,75,52.7Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M133.6,211.9l81.1-81c19.9-20,22.8-52.7,4-73.6a52,52,0,0,0-75.5-2.1L128,70.5,114.8,57.3c-19.9-19.9-52.6-22.8-73.6-4a52,52,0,0,0-2,75.5l83.1,83.1A8,8,0,0,0,133.6,211.9Z" />
          </g>
          <path d="M128,222.2a15.6,15.6,0,0,1-11.3-4.7L33.5,134.4a60,60,0,0,1,2.4-87,57.6,57.6,0,0,1,43-14.6,64.5,64.5,0,0,1,41.6,18.9l7.5,7.4,9.5-9.5a60,60,0,0,1,87.1,2.3c10.5,11.6,15.6,26.9,14.6,43.1a64.3,64.3,0,0,1-18.9,41.5l-81,81h0A15.6,15.6,0,0,1,128,222.2ZM75,48.7A42.3,42.3,0,0,0,46.6,59.2a44,44,0,0,0-1.7,63.9l88.7,88.8-5.6-5.7,81-81c17.4-17.4,19.1-45.5,3.7-62.6a43.9,43.9,0,0,0-63.8-1.7L133.6,76.1a8,8,0,0,1-11.3,0L109.2,63A48.5,48.5,0,0,0,75,48.7Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M220.3,136.5l-81,81a15.9,15.9,0,0,1-22.6,0L33.6,134.4a59.9,59.9,0,0,1,2.3-87c23.3-21.1,61.3-19.1,84.6,4.3l7.5,7.4,9.6-9.5A60.4,60.4,0,0,1,181.5,32a59.8,59.8,0,0,1,43.1,19.9C245.6,75.2,243.7,113.2,220.3,136.5Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,220.2a13.6,13.6,0,0,1-9.9-4.1L35,133a58,58,0,0,1,2.2-84.2,56.5,56.5,0,0,1,41.6-14,62.8,62.8,0,0,1,40.3,18.3L128,62l11-11a57.9,57.9,0,0,1,84.1,2.2,56.2,56.2,0,0,1,14.1,41.6,62.8,62.8,0,0,1-18.3,40.3l-81,81h0A13.6,13.6,0,0,1,128,220.2Zm5.6-8.3h0ZM75,46.7A44,44,0,0,0,45.3,57.8a45.8,45.8,0,0,0-1.8,66.7l83.1,83.1a1.9,1.9,0,0,0,2.8,0l81-81c18.2-18.2,19.9-47.5,3.8-65.3a45.8,45.8,0,0,0-66.7-1.8L132.2,74.7a6.1,6.1,0,0,1-8.5,0L110.6,61.6A50.3,50.3,0,0,0,75,46.7Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,218.2a11.6,11.6,0,0,1-8.5-3.5L36.4,131.6a56,56,0,0,1,2.2-81.3A54,54,0,0,1,78.7,36.8a60.3,60.3,0,0,1,39,17.7L128,64.8l12.4-12.4a56,56,0,0,1,81.3,2.2,54,54,0,0,1,13.5,40.1,60.3,60.3,0,0,1-17.7,39l-81,81h0A12.2,12.2,0,0,1,128,218.2Zm5.7-6.3ZM75,44.7A45.9,45.9,0,0,0,43.9,56.3a47.9,47.9,0,0,0-1.8,69.6l83.1,83.2a4,4,0,0,0,5.6,0L211.9,128c18.9-18.9,20.6-49.4,3.8-68.1a47.9,47.9,0,0,0-69.6-1.8L130.8,73.3a3.9,3.9,0,0,1-5.6,0L112,60.1A52.6,52.6,0,0,0,75,44.7Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,222.2a15.6,15.6,0,0,1-11.3-4.7L33.6,134.4a59.9,59.9,0,0,1,2.3-87A57.7,57.7,0,0,1,79,32.8a64.3,64.3,0,0,1,41.5,18.9l7.5,7.4,9.6-9.5a59.9,59.9,0,0,1,87,2.3A57.7,57.7,0,0,1,239.2,95a64.3,64.3,0,0,1-18.9,41.5l-81,81h0A15.6,15.6,0,0,1,128,222.2ZM75,48.7A42.5,42.5,0,0,0,46.6,59.2a44,44,0,0,0-1.7,63.9l88.8,88.8-5.7-5.7,81-81c17.5-17.4,19.1-45.5,3.8-62.6a44,44,0,0,0-63.9-1.7L133.7,76.1a8.1,8.1,0,0,1-11.4,0L109.2,63A48.4,48.4,0,0,0,75,48.7Z" />
        </>
      )}
    </svg>
  );
}

export function PhHeart(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,228a12.6,12.6,0,0,1-5.9-1.5C117.8,224,16,166.2,16,92A64,64,0,0,1,128,49.6,64,64,0,0,1,240,92c0,74.2-101.8,132-106.1,134.5A12.6,12.6,0,0,1,128,228ZM80,52A40,40,0,0,0,40,92c0,51.2,67.4,97.2,88,110,20.6-12.8,88-58.8,88-110a40,40,0,0,0-76.9-15.4,12,12,0,0,1-22.2,0A39.9,39.9,0,0,0,80,52Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M128,216S28,160,28,92A52,52,0,0,1,128,72h0A52,52,0,0,1,228,92C228,160,128,216,128,216Z" />
          </g>
          <path d="M128,224a7.8,7.8,0,0,1-3.9-1C119.8,220.6,20,163.9,20,92A60,60,0,0,1,128,56,60,60,0,0,1,236,92c0,30.6-17.7,62-52.6,93.4A314.3,314.3,0,0,1,131.9,223,7.8,7.8,0,0,1,128,224Zm-3.9-15ZM80,48A44,44,0,0,0,36,92c0,55.2,74,103.7,92,114.7,18-11,92-59.5,92-114.7a44,44,0,0,0-84.6-17,8,8,0,0,1-14.8,0A43.8,43.8,0,0,0,80,48Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M236,92c0,30.6-17.7,62-52.6,93.4A314.3,314.3,0,0,1,131.9,223a8.1,8.1,0,0,1-7.8,0C119.8,220.6,20,163.9,20,92A60,60,0,0,1,128,56,60,60,0,0,1,236,92Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,222a6,6,0,0,1-2.9-.8C124,220.7,99.2,206.6,74,184c-34.5-31.1-52-62-52-92A58,58,0,0,1,128,59.4,58,58,0,0,1,234,92c0,30-17.5,60.9-52,92-25.2,22.6-50,36.7-51.1,37.2A6,6,0,0,1,128,222ZM80,46A46,46,0,0,0,34,92c0,57.3,77.8,107.3,94,117,16.2-9.7,94-59.7,94-117a46,46,0,0,0-88.5-17.7,5.9,5.9,0,0,1-11,0A46,46,0,0,0,80,46Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,220a4.9,4.9,0,0,1-2-.5,321.4,321.4,0,0,1-50.7-37C41.3,151.8,24,121.4,24,92A56,56,0,0,1,128,63.1,56,56,0,0,1,232,92c0,29.4-17.3,59.8-51.3,90.5a321.4,321.4,0,0,1-50.7,37A4.9,4.9,0,0,1,128,220ZM80,44A48,48,0,0,0,32,92c0,59.7,82.2,111.1,96,119.4,13.8-8.3,96-59.7,96-119.4a48,48,0,0,0-92.3-18.5,4,4,0,0,1-7.4,0A48,48,0,0,0,80,44Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,224a7.8,7.8,0,0,1-3.9-1C119.8,220.6,20,163.9,20,92A60,60,0,0,1,128,56,60,60,0,0,1,236,92c0,30.6-17.7,62-52.6,93.4A314.3,314.3,0,0,1,131.9,223,7.8,7.8,0,0,1,128,224Zm-3.9-15ZM80,48A44,44,0,0,0,36,92c0,55.2,74,103.7,92,114.7,18-11,92-59.5,92-114.7a44,44,0,0,0-84.6-17,8,8,0,0,1-14.8,0A43.8,43.8,0,0,0,80,48Z" />
        </>
      )}
    </svg>
  );
}

export function PhHeartbeat(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M72,140H32a12,12,0,0,1,0-24H65.6L78,97.3a12.1,12.1,0,0,1,20,0l22,33.1,6-9.1a12.1,12.1,0,0,1,10-5.3h24a12,12,0,0,1,0,24H142.4L130,158.7a12.1,12.1,0,0,1-20,0L88,125.6l-6,9.1A12.1,12.1,0,0,1,72,140ZM176,28a64,64,0,0,0-48,21.6A64,64,0,0,0,16.2,87.3a12,12,0,1,0,23.9,1.8,40,40,0,0,1,76.8-12.5,12,12,0,0,0,22.2,0A40,40,0,0,1,216,92c0,51.2-67.5,97.2-88,110-10.6-6.6-33.5-21.9-53.4-42.4a12,12,0,0,0-17.2,16.8c29.2,30,63.3,49.3,64.7,50.1a12.4,12.4,0,0,0,11.8,0C138.2,224,240,166.2,240,92A64.1,64.1,0,0,0,176,28Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M128,216S28,160,28,92A52,52,0,0,1,128,72h0A52,52,0,0,1,228,92C228,160,128,216,128,216Z" />
          </g>
          <path d="M78.7,132.4l9.3-14,25.3,38a8,8,0,0,0,13.4,0L140.3,136H160a8,8,0,0,0,0-16H136a7.9,7.9,0,0,0-6.7,3.6l-9.3,14-25.3-38a8,8,0,0,0-13.4,0L67.7,120H32a8,8,0,0,0,0,16H72A7.9,7.9,0,0,0,78.7,132.4Z" />
          <path d="M176,32a60,60,0,0,0-48,24A60,60,0,0,0,20,92c0,1.5,0,3,.1,4.4a8.1,8.1,0,0,0,8.5,7.6,8,8,0,0,0,7.5-8.5C36,94.4,36,93.2,36,92a44,44,0,0,1,84.6-17,8,8,0,0,0,14.8,0A44,44,0,0,1,220,92c0,55.2-74,103.7-92,114.7-10.6-6.4-40.5-25.8-63.3-52a8,8,0,0,0-12.1,10.6c30,34.2,69.8,56.7,71.5,57.7a8.1,8.1,0,0,0,7.8,0,314.3,314.3,0,0,0,51.5-37.6C218.3,154,236,122.6,236,92A60,60,0,0,0,176,32Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M236,92c0,30.6-17.7,62-52.6,93.4A314.3,314.3,0,0,1,131.9,223a8.1,8.1,0,0,1-7.8,0c-3.2-1.8-60.4-34.3-88.6-80.9a4,4,0,0,1,3.4-6.1H72a7.9,7.9,0,0,0,6.7-3.6l9.3-14,25.3,38a8.1,8.1,0,0,0,9.2,3.2,8.8,8.8,0,0,0,4.3-3.3L140.3,136h19.4a8.2,8.2,0,0,0,8.3-7.5,8,8,0,0,0-8-8.5H136a7.9,7.9,0,0,0-6.7,3.6l-9.3,14-25.3-38a8.1,8.1,0,0,0-9.2-3.2,8.8,8.8,0,0,0-4.3,3.3L67.7,120H27.8a4,4,0,0,1-3.8-2.8A86.2,86.2,0,0,1,20,92,60,60,0,0,1,128,56,60,60,0,0,1,236,92Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M72,134H32a6,6,0,0,1,0-12H68.8L83,100.7a6,6,0,0,1,10,0l27,40.5,11-16.5a6,6,0,0,1,5-2.7h24a6,6,0,0,1,0,12H139.2L125,155.3a6,6,0,0,1-10,0L88,114.8,77,131.3A6,6,0,0,1,72,134ZM176,34a58,58,0,0,0-48,25.4A58,58,0,0,0,22,92c0,1.4,0,2.9.1,4.3a6.1,6.1,0,0,0,6.4,5.7,5.9,5.9,0,0,0,5.6-6.3C34,94.4,34,93.2,34,92a46,46,0,0,1,88.5-17.7,5.9,5.9,0,0,0,11,0A46,46,0,0,1,222,92c0,57.3-77.8,107.3-94,117-9.6-5.8-41-25.7-64.8-52.9a6,6,0,0,0-8.5-.6,6.1,6.1,0,0,0-.6,8.5c29.8,34,69.3,56.3,71,57.2a5.7,5.7,0,0,0,5.8,0A315.8,315.8,0,0,0,182,184c34.5-31.1,52-62,52-92A58,58,0,0,0,176,34Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M72,132H32a4,4,0,0,1,0-8H69.9l14.8-22.2a3.9,3.9,0,0,1,6.6,0l28.7,43,12.7-19A4,4,0,0,1,136,124h24a4,4,0,0,1,0,8H138.1l-14.8,22.2a3.9,3.9,0,0,1-6.6,0L88,111.2l-12.7,19A4,4,0,0,1,72,132ZM176,36a55.8,55.8,0,0,0-48,27.1A56,56,0,0,0,24,92c0,1.4,0,2.8.1,4.2a4,4,0,0,0,8-.4C32,94.5,32,93.2,32,92a48,48,0,0,1,92.3-18.5A4,4,0,0,0,128,76h0a4,4,0,0,0,3.7-2.5A48,48,0,0,1,224,92c0,59.7-82.2,111.1-96,119.4-8.3-5-41.4-25.5-66.3-54A4,4,0,0,0,56,157a4.1,4.1,0,0,0-.4,5.6c29.6,33.8,68.8,56,70.4,56.9a4.3,4.3,0,0,0,4,0,321.4,321.4,0,0,0,50.7-37c34-30.7,51.3-61.1,51.3-90.5A56,56,0,0,0,176,36Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M72,136H32a8,8,0,0,1,0-16H67.7L81.3,99.6a8,8,0,0,1,13.4,0l25.3,38,9.3-14A7.9,7.9,0,0,1,136,120h24a8,8,0,0,1,0,16H140.3l-13.6,20.4a8,8,0,0,1-13.4,0L88,118.4l-9.3,14A7.9,7.9,0,0,1,72,136ZM176,32a60,60,0,0,0-48,24A60,60,0,0,0,20,92c0,1.5,0,3,.1,4.4a8.1,8.1,0,0,0,8.5,7.6,8,8,0,0,0,7.5-8.5C36,94.4,36,93.2,36,92a44,44,0,0,1,84.6-17,8,8,0,0,0,14.8,0A44,44,0,0,1,220,92c0,55.2-74,103.7-92,114.7-10.6-6.4-40.5-25.8-63.3-52a8,8,0,0,0-12.1,10.6c30,34.2,69.8,56.7,71.5,57.7a8.1,8.1,0,0,0,7.8,0,314.3,314.3,0,0,0,51.5-37.6C218.3,154,236,122.6,236,92A60,60,0,0,0,176,32Z" />
        </>
      )}
    </svg>
  );
}

export function PhHexagon(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,242.8a20,20,0,0,1-9.8-2.6l-84-47.5A19.9,19.9,0,0,1,24,175.3V80.7A19.9,19.9,0,0,1,34.2,63.3l84-47.5a19.6,19.6,0,0,1,19.6,0l84,47.5A19.9,19.9,0,0,1,232,80.7v94.6a19.9,19.9,0,0,1-10.2,17.4l-84,47.5A20,20,0,0,1,128,242.8ZM48,173l80,45.2L208,173V83L128,37.8,48,83Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M220,175.3V80.7a8.1,8.1,0,0,0-4.1-7l-84-47.5a7.8,7.8,0,0,0-7.8,0l-84,47.5a8.1,8.1,0,0,0-4.1,7v94.6a8.1,8.1,0,0,0,4.1,7l84,47.5a7.8,7.8,0,0,0,7.8,0l84-47.5A8.1,8.1,0,0,0,220,175.3Z" />
          </g>
          <path d="M128,238.8a16.1,16.1,0,0,1-7.9-2.1l-84-47.4a16.2,16.2,0,0,1-8.1-14V80.7a16.2,16.2,0,0,1,8.1-14l84-47.4a15.9,15.9,0,0,1,15.8,0l84,47.4a16.2,16.2,0,0,1,8.1,14v94.6a16.2,16.2,0,0,1-8.1,14l-84,47.4A16.1,16.1,0,0,1,128,238.8Zm0-205.6L44,80.7v94.6l84,47.5,84-47.5V80.7Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M228,80.7v94.6a16.2,16.2,0,0,1-8.1,14l-84,47.4a15.9,15.9,0,0,1-15.8,0l-84-47.4a16.2,16.2,0,0,1-8.1-14V80.7a16.2,16.2,0,0,1,8.1-14l84-47.4a15.9,15.9,0,0,1,15.8,0l84,47.4A16.2,16.2,0,0,1,228,80.7Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,236.8a13.9,13.9,0,0,1-6.9-1.8l-84-47.5A14,14,0,0,1,30,175.3V80.7a14,14,0,0,1,7.1-12.2l84-47.5a14.1,14.1,0,0,1,13.8,0l84,47.5A14,14,0,0,1,226,80.7v94.6a14,14,0,0,1-7.1,12.2l-84,47.5A13.9,13.9,0,0,1,128,236.8Zm0-205.6-1,.2L43,78.9a2.1,2.1,0,0,0-1,1.8v94.6a2.1,2.1,0,0,0,1,1.8l84,47.5a2.3,2.3,0,0,0,2,0l84-47.5a2.1,2.1,0,0,0,1-1.8V80.7a2.1,2.1,0,0,0-1-1.8L129,31.4Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,234.8a12.3,12.3,0,0,1-5.9-1.5l-84-47.5A11.9,11.9,0,0,1,32,175.3V80.7a11.9,11.9,0,0,1,6.1-10.5l84-47.5a12.4,12.4,0,0,1,11.8,0l84,47.5A11.9,11.9,0,0,1,224,80.7v94.6a11.9,11.9,0,0,1-6.1,10.5l-84,47.5A12.3,12.3,0,0,1,128,234.8Zm0-205.6a4.6,4.6,0,0,0-2,.5L42,77.2a4,4,0,0,0-2,3.5v94.6a4,4,0,0,0,2,3.5l84,47.5a4.1,4.1,0,0,0,4,0l84-47.5a4,4,0,0,0,2-3.5V80.7a4,4,0,0,0-2-3.5L130,29.7A4.6,4.6,0,0,0,128,29.2Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,238.8a16.1,16.1,0,0,1-7.9-2.1l-84-47.4a16.2,16.2,0,0,1-8.1-14V80.7a16.2,16.2,0,0,1,8.1-14l84-47.4a15.9,15.9,0,0,1,15.8,0l84,47.4a16.2,16.2,0,0,1,8.1,14v94.6a16.2,16.2,0,0,1-8.1,14l-84,47.4A16.1,16.1,0,0,1,128,238.8Zm0-205.6L44,80.7v94.6l84,47.5,84-47.5V80.7Z" />
        </>
      )}
    </svg>
  );
}

export function PhHighlighterCircle(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M204.4,51.6A108.2,108.2,0,1,0,236,128,108.2,108.2,0,0,0,204.4,51.6ZM140,136H116V111.4l24-12Zm-40,71.2V160h56v47.2a84.1,84.1,0,0,1-56,0Zm87.4-19.8c-2.4,2.3-4.8,4.5-7.4,6.6V156a20.1,20.1,0,0,0-16-19.6V80a12,12,0,0,0-5.7-10.2,11.9,11.9,0,0,0-11.7-.5l-48,24A12.1,12.1,0,0,0,92,104v32.4A20.1,20.1,0,0,0,76,156v38c-2.6-2.1-5-4.3-7.4-6.6a84,84,0,1,1,118.8,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M195.9,60.1A96,96,0,1,0,88,215.3V152a8,8,0,0,1,8-8h8V96l48-24v72h8a8,8,0,0,1,8,8v63.3A96,96,0,0,0,195.9,60.1Z" />
          </g>
          <path d="M201.5,54.5a103.8,103.8,0,0,0-147,0,103.8,103.8,0,0,0,0,147,103.8,103.8,0,0,0,147,0,103.8,103.8,0,0,0,0-147ZM96,210V152h64v58a88.3,88.3,0,0,1-64,0Zm48-74H112V100.9l32-16Zm46.2,54.2A86.8,86.8,0,0,1,176,201.8V152a16,16,0,0,0-16-16V72a7.9,7.9,0,0,0-3.8-6.8,8,8,0,0,0-7.8-.4l-48,24A8.2,8.2,0,0,0,96,96v40a16,16,0,0,0-16,16v49.8A88.7,88.7,0,0,1,40,128a88,88,0,1,1,150.2,62.2Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M201.5,54.5a103.8,103.8,0,0,0-147,0,103.8,103.8,0,0,0,0,147,103.8,103.8,0,0,0,147,0,103.8,103.8,0,0,0,0-147ZM96,210V152h64v58a88.3,88.3,0,0,1-64,0Zm94.2-19.8A86.8,86.8,0,0,1,176,201.8V152a16,16,0,0,0-16-16V72a7.9,7.9,0,0,0-3.8-6.8,8,8,0,0,0-7.8-.4l-48,24A8.2,8.2,0,0,0,96,96v40a16,16,0,0,0-16,16v49.8A88.7,88.7,0,0,1,40,128a88,88,0,1,1,150.2,62.2Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M200.1,200.1ZM230,128A102,102,0,0,1,55.9,200.1,102,102,0,1,1,200.1,55.9,101.1,101.1,0,0,1,230,128ZM128,218a90.7,90.7,0,0,0,34-6.6V152a2,2,0,0,0-2-2H96a2,2,0,0,0-2,2v59.4A90.7,90.7,0,0,0,128,218ZM146,81.7l-36,18V138h36Zm45.6,109.9A89.5,89.5,0,1,0,82,205.4V152a14,14,0,0,1,14-14h2V96a6,6,0,0,1,3.3-5.4l48-24a6.2,6.2,0,0,1,5.9.3A6.1,6.1,0,0,1,158,72v66h2a14,14,0,0,1,14,14v53.4a89.2,89.2,0,0,0,17.6-13.8Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M198.7,57.3A100,100,0,1,0,57.3,198.7,100,100,0,1,0,198.7,57.3ZM92,212.7V152a4,4,0,0,1,4-4h64a4,4,0,0,1,4,4v60.7a92.4,92.4,0,0,1-72,0ZM148,140H108V98.5l40-20Zm45.1,53.1A93.4,93.4,0,0,1,172,208.8V152a12,12,0,0,0-12-12h-4V72a3.9,3.9,0,0,0-1.9-3.4,4.1,4.1,0,0,0-3.9-.2l-48,24A4.2,4.2,0,0,0,100,96v44H96a12,12,0,0,0-12,12v56.8a93.4,93.4,0,0,1-21.1-15.7,92,92,0,1,1,130.2,0Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M201.5,54.5a103.8,103.8,0,0,0-147,0,103.8,103.8,0,0,0,0,147,103.8,103.8,0,0,0,147,0,103.8,103.8,0,0,0,0-147ZM96,210V152h64v58a88.3,88.3,0,0,1-64,0Zm48-74H112V100.9l32-16Zm46.2,54.2A86.8,86.8,0,0,1,176,201.8V152a16,16,0,0,0-16-16V72a7.9,7.9,0,0,0-3.8-6.8,8,8,0,0,0-7.8-.4l-48,24A8.2,8.2,0,0,0,96,96v40a16,16,0,0,0-16,16v49.8A88.7,88.7,0,0,1,40,128a88,88,0,1,1,150.2,62.2Z" />
        </>
      )}
    </svg>
  );
}

export function PhHorse(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M204.9,52.2A107.2,107.2,0,0,0,128,20h-8a12,12,0,0,0-12,12V57.3L9.7,117.8a12.2,12.2,0,0,0-5.5,7.8A12.1,12.1,0,0,0,6.3,135l13.8,19.1a35.7,35.7,0,0,0,35.2,14.5c12.3-2.1,34-3.9,54.7,4L88.8,202.3A84.3,84.3,0,0,1,72,190.6a12,12,0,0,0-16,17.9A107.7,107.7,0,0,0,128,236h2.2A108,108,0,0,0,204.9,52.2ZM187.5,187.1a83.1,83.1,0,0,1-75.1,23.4l22-30.8A60.2,60.2,0,0,0,188,120a12,12,0,0,0-24,0,36,36,0,0,1-33,35.9c-29.6-16.2-62.4-13.9-79.7-11a12,12,0,0,1-11.8-4.8l-6.2-8.6,93-57.3A12,12,0,0,0,132,64V44.1a83.8,83.8,0,0,1,55.5,143ZM144,104a16,16,0,1,1-16-16A16,16,0,0,1,144,104Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M92.8,217.3a95.2,95.2,0,0,0,37.1,6.7c51.5-1,93.4-43.1,94.1-94.6A96,96,0,0,0,128,32h-8V64L16,128l13.8,19.1a23.9,23.9,0,0,0,23.5,9.6c17.5-2.9,48.1-4.7,74.7,11.3h0Z" />
          </g>
          <circle cx="124" cy="100" r="12" />
          <path d="M202.1,55A103.2,103.2,0,0,0,128,24h-8a8,8,0,0,0-8,8V59.5L11.8,121.2a8,8,0,0,0-3.6,5.2,7.8,7.8,0,0,0,1.3,6.3l13.8,19.1a31.6,31.6,0,0,0,31.3,12.8c13.7-2.3,38.6-4.2,61.6,6.2L90,207.4a91.1,91.1,0,0,1-20.7-13.8,8,8,0,0,0-11.3.6,8,8,0,0,0,.7,11.3A103.5,103.5,0,0,0,128,232h2.1a104,104,0,0,0,72-177ZM190.3,190a87.6,87.6,0,0,1-60.5,26,88.7,88.7,0,0,1-24.2-2.9l26.6-37.3A56.1,56.1,0,0,0,184,120a8,8,0,0,0-16,0,39.9,39.9,0,0,1-38,39.9c-28.7-16.2-61.1-13.9-78-11.1a15.8,15.8,0,0,1-15.7-6.4l-8.8-12.1,96.7-59.5A7.8,7.8,0,0,0,128,64V40a87.9,87.9,0,0,1,62.3,150Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M202.1,55A103.2,103.2,0,0,0,128,24h-8a8,8,0,0,0-8,8V59.5L11.8,121.2a8,8,0,0,0-3.6,5.2,7.8,7.8,0,0,0,1.3,6.3l13.8,19.1a31.6,31.6,0,0,0,31.3,12.8c13.7-2.3,38.6-4.2,61.6,6.2L90,207.4a91.1,91.1,0,0,1-20.7-13.8,8,8,0,0,0-11.3.6,8,8,0,0,0,.7,11.3A103.5,103.5,0,0,0,128,232h2.1a104,104,0,0,0,72-177ZM124,112a12,12,0,1,1,12-12A12,12,0,0,1,124,112Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M200.6,56.4A101.2,101.2,0,0,0,128,26h-8a6,6,0,0,0-6,6V60.6L12.9,122.9a5.8,5.8,0,0,0-2.8,3.9,6.4,6.4,0,0,0,1,4.7l13.8,19.1a29.6,29.6,0,0,0,29.4,12c14.3-2.4,40.9-4.4,64.9,7.4L90.7,209.9A88.7,88.7,0,0,1,68,195.1a6,6,0,0,0-8.5.5A5.9,5.9,0,0,0,60,204a101.6,101.6,0,0,0,68,26h2.1A102,102,0,0,0,200.6,56.4Zm-8.9,135A89.4,89.4,0,0,1,129.8,218a90.4,90.4,0,0,1-27.5-3.7l28.9-40.4A54,54,0,0,0,182,120a6,6,0,0,0-12,0,42.2,42.2,0,0,1-40.5,42c-28.3-16.3-60.4-14-77.2-11.2a17.8,17.8,0,0,1-17.6-7.2L24.6,129.7l98.5-60.6A6,6,0,0,0,126,64V38h2a89.9,89.9,0,0,1,63.7,153.4ZM134,100a10,10,0,1,1-10-10A10,10,0,0,1,134,100Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M199.2,57.8A99.2,99.2,0,0,0,128,28h-8a4,4,0,0,0-4,4V61.8L13.9,124.6a4,4,0,0,0-1.8,2.6,3.8,3.8,0,0,0,.7,3.1l13.7,19.2A28,28,0,0,0,54,160.7c15-2.6,43.1-4.6,68.2,8.6L91.4,212.4a92,92,0,0,1-24.7-15.8,4,4,0,1,0-5.4,5.9A99.5,99.5,0,0,0,128,228h2a100.5,100.5,0,0,0,98-98.6A99.3,99.3,0,0,0,199.2,57.8ZM220,129.3A92.2,92.2,0,0,1,129.9,220a93.5,93.5,0,0,1-30.8-4.6l31-43.5A52,52,0,0,0,180,120a4,4,0,0,0-8,0,44.1,44.1,0,0,1-43,44c-27.9-16.3-59.7-14-76.4-11.2a19.9,19.9,0,0,1-19.6-8L21.8,129.2,122.1,67.4A3.9,3.9,0,0,0,124,64V36h4a92,92,0,0,1,92,93.3ZM132,100a8,8,0,1,1-8-8A8,8,0,0,1,132,100Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M136,100a12,12,0,1,1-12-12A12,12,0,0,1,136,100Zm96,29.5A104.4,104.4,0,0,1,130.1,232H128a103.5,103.5,0,0,1-69.3-26.5,8,8,0,0,1-.7-11.3,8,8,0,0,1,11.3-.6A91.1,91.1,0,0,0,90,207.4l26.2-36.6c-23-10.4-47.9-8.5-61.6-6.2a31.6,31.6,0,0,1-31.3-12.8L9.5,132.7a7.8,7.8,0,0,1-1.3-6.3,8,8,0,0,1,3.6-5.2L112,59.5V32a8,8,0,0,1,8-8h8A104,104,0,0,1,232,129.5Zm-16-.2A87.9,87.9,0,0,0,128,40V64a7.8,7.8,0,0,1-3.8,6.8L27.5,130.3l8.8,12.1A15.8,15.8,0,0,0,52,148.8c16.9-2.8,49.3-5.1,78,11.1A39.9,39.9,0,0,0,168,120a8,8,0,0,1,16,0,56.1,56.1,0,0,1-51.8,55.8l-26.6,37.3a88.7,88.7,0,0,0,24.2,2.9A88.3,88.3,0,0,0,216,129.3Z" />
        </>
      )}
    </svg>
  );
}

export function PhHourglassHigh(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M184,20H72A20.1,20.1,0,0,0,52,40V76a20.1,20.1,0,0,0,8,16l48,36L60,164a20.1,20.1,0,0,0-8,16v36a20.1,20.1,0,0,0,20,20H184a20.1,20.1,0,0,0,20-20V180.4a20.3,20.3,0,0,0-7.9-16L147.9,128l48.2-36.4a20.3,20.3,0,0,0,7.9-16V40A20.1,20.1,0,0,0,184,20Zm-4,24V60H76V44Zm-52,69L89.3,84h77Zm52,99H76V182l52-39,52,39.3Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M128,128,67.2,82.4A8.1,8.1,0,0,1,64,76V64H192V75.6a8.1,8.1,0,0,1-3.2,6.4L128,128h0" />
          </g>
          <path d="M184,24H72A16,16,0,0,0,56,40V76a16.1,16.1,0,0,0,6.4,12.8L114.7,128,62.4,167.2A16.1,16.1,0,0,0,56,180v36a16,16,0,0,0,16,16H184a16,16,0,0,0,16-16V180.4a16.1,16.1,0,0,0-6.4-12.8L141.3,128l52.3-39.6A16.1,16.1,0,0,0,200,75.6V40A16,16,0,0,0,184,24Zm0,16V56H72V40Zm0,176H72V180l56-42,56,42.4Zm-56-98L72,76V72H184v3.6Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M200,75.6V40a16,16,0,0,0-16-16H72A16,16,0,0,0,56,40V76a16.1,16.1,0,0,0,6.4,12.8L114.7,128,62.4,167.2A16.1,16.1,0,0,0,56,180v36a16,16,0,0,0,16,16H184a16,16,0,0,0,16-16V180.4a16.1,16.1,0,0,0-6.4-12.8L141.3,128l52.3-39.6A16.1,16.1,0,0,0,200,75.6ZM184,40V64H72V40Zm0,176H72V180l56-42,56,42.4Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M184,26H72A14,14,0,0,0,58,40V76a14,14,0,0,0,5.6,11.2L118,128,63.6,168.8A14,14,0,0,0,58,180v36a14,14,0,0,0,14,14H184a14,14,0,0,0,14-14V180.4a14,14,0,0,0-5.6-11.2L138,128l54.4-41.2A14,14,0,0,0,198,75.6V40A14,14,0,0,0,184,26ZM72,38H184a2,2,0,0,1,2,2V58H70V40A2,2,0,0,1,72,38ZM186,180.4V216a2,2,0,0,1-2,2H72a2,2,0,0,1-2-2V180a2.1,2.1,0,0,1,.8-1.6L128,135.5l57.2,43.3A1.8,1.8,0,0,1,186,180.4Zm-.8-103.2L128,120.5,70.8,77.6A2.1,2.1,0,0,1,70,76V70H186v5.6A1.8,1.8,0,0,1,185.2,77.2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M184,28H72A12,12,0,0,0,60,40V76a12.1,12.1,0,0,0,4.8,9.6L121.3,128,64.8,170.4A12.1,12.1,0,0,0,60,180v36a12,12,0,0,0,12,12H184a12,12,0,0,0,12-12V180.4a11.9,11.9,0,0,0-4.8-9.6L134.6,128l56.6-42.8a11.9,11.9,0,0,0,4.8-9.6V40A12,12,0,0,0,184,28ZM72,36H184a4,4,0,0,1,4,4V60H68V40A4,4,0,0,1,72,36ZM188,180.4V216a4,4,0,0,1-4,4H72a4,4,0,0,1-4-4V180a4,4,0,0,1,1.6-3.2L128,133l58.4,44.2A3.9,3.9,0,0,1,188,180.4ZM186.4,78.8,128,123,69.6,79.2A4,4,0,0,1,68,76V68H188v7.6A3.9,3.9,0,0,1,186.4,78.8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M184,24H72A16,16,0,0,0,56,40V76a16.1,16.1,0,0,0,6.4,12.8L114.7,128,62.4,167.2A16.1,16.1,0,0,0,56,180v36a16,16,0,0,0,16,16H184a16,16,0,0,0,16-16V180.4a16.1,16.1,0,0,0-6.4-12.8L141.3,128l52.3-39.6A16.1,16.1,0,0,0,200,75.6V40A16,16,0,0,0,184,24Zm0,16V56H72V40Zm0,176H72V180l56-42,56,42.4Zm-56-98L72,76V72H184v3.6Z" />
        </>
      )}
    </svg>
  );
}

export function PhHourglassLow(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M204,75.6V40a20.1,20.1,0,0,0-20-20H72A20.1,20.1,0,0,0,52,40V76a20.1,20.1,0,0,0,8,16l48,36L60,164a19.9,19.9,0,0,0-8,15.9h0v36a20.1,20.1,0,0,0,20,20H184a20.1,20.1,0,0,0,20-20V180.4a20.3,20.3,0,0,0-7.9-16L147.9,128l48.2-36.4A20.3,20.3,0,0,0,204,75.6Zm-42.6,92.7-66.8-.2L128,143ZM76,212V192l104,.3V212ZM180,73.7,128,113,76,74V44H180Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M64,180v36a8,8,0,0,0,8,8H184a8,8,0,0,0,8-8V180.4Z" />
          </g>
          <path d="M200,75.6V40a16,16,0,0,0-16-16H72A16,16,0,0,0,56,40V76a16.1,16.1,0,0,0,6.4,12.8L114.7,128,62.4,167.2a15.6,15.6,0,0,0-6.3,11.9,2.8,2.8,0,0,0-.1.9h0v36a16,16,0,0,0,16,16H184a16,16,0,0,0,16-16V180.4h0a1.9,1.9,0,0,0-.1-.8,15.6,15.6,0,0,0-6.3-11.9L141.3,128l52.3-39.6A16.1,16.1,0,0,0,200,75.6Zm-26.7,96.7-90.7-.2L128,138ZM72,216V188l112,.3V216ZM184,75.6,128,118,72,76V40H184Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M200,75.6V40a16,16,0,0,0-16-16H72A16,16,0,0,0,56,40V76a16.1,16.1,0,0,0,6.4,12.8L114.7,128,62.4,167.2a15.6,15.6,0,0,0-6.3,11.9,2.8,2.8,0,0,0-.1.9v36a16,16,0,0,0,16,16H184a16,16,0,0,0,16-16V180.4a2.8,2.8,0,0,0-.1-.9,15.6,15.6,0,0,0-6.3-11.9L141.3,128l52.3-39.6A16.1,16.1,0,0,0,200,75.6ZM82.6,172.1,128,138l45.3,34.3ZM184,75.6,128,118,72,76V40H184Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M198,75.6V40a14,14,0,0,0-14-14H72A14,14,0,0,0,58,40V76a14,14,0,0,0,5.6,11.2L118,128,63.6,168.8A14.2,14.2,0,0,0,58,179.3v.7h0v36a14,14,0,0,0,14,14H184a14,14,0,0,0,14-14V180.4h0v-.6a14.2,14.2,0,0,0-5.6-10.5L138,128l54.4-41.2A14,14,0,0,0,198,75.6Zm-18.7,98.7L76.6,174,128,135.5ZM184,218H72a2,2,0,0,1-2-2V186l116,.3V216A2,2,0,0,1,184,218Zm2-142.4a1.8,1.8,0,0,1-.8,1.6L128,120.5,70.8,77.6A2.1,2.1,0,0,1,70,76V40a2,2,0,0,1,2-2H184a2,2,0,0,1,2,2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M196,75.6V40a12,12,0,0,0-12-12H72A12,12,0,0,0,60,40V76a12.1,12.1,0,0,0,4.8,9.6L121.3,128,64.8,170.4A11.9,11.9,0,0,0,60,180h0v36a12,12,0,0,0,12,12H184a12,12,0,0,0,12-12V180.4a11.9,11.9,0,0,0-4.8-9.6L134.6,128l56.6-42.8A11.9,11.9,0,0,0,196,75.6ZM185.3,176.3,70.6,176,128,133ZM184,220H72a4,4,0,0,1-4-4V184l120,.3V216A4,4,0,0,1,184,220Zm4-144.4a3.9,3.9,0,0,1-1.6,3.2L128,123,69.6,79.2A4,4,0,0,1,68,76V40a4,4,0,0,1,4-4H184a4,4,0,0,1,4,4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M200,75.6V40a16,16,0,0,0-16-16H72A16,16,0,0,0,56,40V76a16.1,16.1,0,0,0,6.4,12.8L114.7,128,62.4,167.2a15.6,15.6,0,0,0-6.3,11.9,2.8,2.8,0,0,0-.1.9h0v36a16,16,0,0,0,16,16H184a16,16,0,0,0,16-16V180.4h0a1.9,1.9,0,0,0-.1-.8,15.6,15.6,0,0,0-6.3-11.9L141.3,128l52.3-39.6A16.1,16.1,0,0,0,200,75.6Zm-26.7,96.7-90.7-.2L128,138ZM72,216V188l112,.3V216ZM184,75.6,128,118,72,76V40H184Z" />
        </>
      )}
    </svg>
  );
}

export function PhHourglassMedium(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M204,75.6V40a20.1,20.1,0,0,0-20-20H72A20.1,20.1,0,0,0,52,40V76a20.1,20.1,0,0,0,8,16l48,36L60,164a20.1,20.1,0,0,0-8,16v36a20.1,20.1,0,0,0,20,20H184a20.1,20.1,0,0,0,20-20V180.4a20.3,20.3,0,0,0-7.9-16L147.9,128l48.2-36.4A20.3,20.3,0,0,0,204,75.6ZM180,44V72H76V44Zm-52,69L105.3,96h45.1Zm52,99H76V182l40-30v16a12,12,0,0,0,24,0V152.1l40,30.2Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <polygon points="128 128 180.9 88 74.7 88 128 128" />
          </g>
          <path d="M200,75.6V40a16,16,0,0,0-16-16H72A16,16,0,0,0,56,40V76a16.1,16.1,0,0,0,6.4,12.8L114.7,128,62.4,167.2A16.1,16.1,0,0,0,56,180v36a16,16,0,0,0,16,16H184a16,16,0,0,0,16-16V180.4a16.1,16.1,0,0,0-6.4-12.8L141.3,128l52.3-39.6A16.1,16.1,0,0,0,200,75.6ZM72,40H184V75.6L178.2,80H77.3L72,76Zm56,78L98.7,96h58.4Zm56,98H72V180l48-36v24a8,8,0,0,0,16,0V144.1l48,36.3Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M193.6,88.4A16.1,16.1,0,0,0,200,75.6V40a16,16,0,0,0-16-16H72A16,16,0,0,0,56,40V76a16.1,16.1,0,0,0,6.4,12.8l18.1,13.6h0L114.7,128,62.4,167.2A16.1,16.1,0,0,0,56,180v36a16,16,0,0,0,16,16H184a16,16,0,0,0,16-16V180.4a16.1,16.1,0,0,0-6.4-12.8L141.3,128,164,110.8ZM72,40H184V75.6L178.2,80H77.3L72,76ZM184,180.4V216H72V180l48-36v24a8,8,0,0,0,16,0V144.1Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M198,75.6V40a14,14,0,0,0-14-14H72A14,14,0,0,0,58,40V76a14,14,0,0,0,5.6,11.2L118,128,63.6,168.8A14,14,0,0,0,58,180v36a14,14,0,0,0,14,14H184a14,14,0,0,0,14-14V180.4a14,14,0,0,0-5.6-11.2L138,128l54.4-41.2A14,14,0,0,0,198,75.6ZM70,40a2,2,0,0,1,2-2H184a2,2,0,0,1,2,2V75.6a1.8,1.8,0,0,1-.8,1.6L178.9,82H76.7l-5.9-4.4A2.1,2.1,0,0,1,70,76Zm58,80.5L92.7,94H163Zm58,59.9V216a2,2,0,0,1-2,2H72a2,2,0,0,1-2-2V180a2.1,2.1,0,0,1,.8-1.6L122,140v28a6,6,0,0,0,12,0V140.1l51.2,38.7A1.8,1.8,0,0,1,186,180.4Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M196,75.6V40a12,12,0,0,0-12-12H72A12,12,0,0,0,60,40V76a12.1,12.1,0,0,0,4.8,9.6L121.3,128,64.8,170.4A12.1,12.1,0,0,0,60,180v36a12,12,0,0,0,12,12H184a12,12,0,0,0,12-12V180.4a11.9,11.9,0,0,0-4.8-9.6L134.6,128l56.6-42.8A11.9,11.9,0,0,0,196,75.6ZM128,123,86.7,92H169Zm60,57.4V216a4,4,0,0,1-4,4H72a4,4,0,0,1-4-4V180a4,4,0,0,1,1.6-3.2L124,136v32a4,4,0,0,0,8,0V136l54.4,41.2A3.9,3.9,0,0,1,188,180.4Zm0-104.8a3.9,3.9,0,0,1-1.6,3.2L179.6,84H76l-6.4-4.8A4,4,0,0,1,68,76V40a4,4,0,0,1,4-4H184a4,4,0,0,1,4,4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M200,75.6V40a16,16,0,0,0-16-16H72A16,16,0,0,0,56,40V76a16.1,16.1,0,0,0,6.4,12.8L114.7,128,62.4,167.2A16.1,16.1,0,0,0,56,180v36a16,16,0,0,0,16,16H184a16,16,0,0,0,16-16V180.4a16.1,16.1,0,0,0-6.4-12.8L141.3,128l52.3-39.6A16.1,16.1,0,0,0,200,75.6ZM72,40H184V75.6L178.2,80H77.3L72,76Zm56,78L98.7,96h58.4Zm56,98H72V180l48-36v24a8,8,0,0,0,16,0V144.1l48,36.3Z" />
        </>
      )}
    </svg>
  );
}

export function PhHourglassSimpleHigh(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M145,128l65.8-65.9A19.9,19.9,0,0,0,196.7,28H59.3A19.9,19.9,0,0,0,45.2,62.1L111,128,45.2,193.9A19.9,19.9,0,0,0,59.3,228H196.7a19.9,19.9,0,0,0,14.1-34.1Zm42-76L171,68H85L69,52ZM109,92h38l-19,19ZM69,204l59-59,59,59Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <polygon points="128 128 184 72 72 72 128 128" />
          </g>
          <path d="M139.3,128l50.4-50.3h0L208,59.3A16,16,0,0,0,196.7,32H59.3A16,16,0,0,0,48,59.3L116.7,128,48,196.7A16,16,0,0,0,59.3,224H196.7A16,16,0,0,0,208,196.7Zm57.4-80-16,16H75.3l-16-16ZM91.3,80h73.4L128,116.7Zm-32,128L128,139.3,196.7,208Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M139.3,128l50.4-50.3h0L208,59.3A16,16,0,0,0,196.7,32H59.3A16,16,0,0,0,48,59.3L116.7,128,48,196.7A16,16,0,0,0,59.3,224H196.7A16,16,0,0,0,208,196.7Zm57.4-80-16,16H75.3l-16-16ZM59.3,208,128,139.3,196.7,208Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M136.5,128l70.1-70.1A14,14,0,0,0,196.7,34H59.3a14,14,0,0,0-9.9,23.9L119.5,128,49.4,198.1A14,14,0,0,0,59.3,222H196.7a14,14,0,0,0,9.9-23.9Zm-79-80.8A2,2,0,0,1,59.3,46H196.7a2,2,0,0,1,1.8,1.2,1.9,1.9,0,0,1-.4,2.2L181.5,66H74.5L57.9,49.4A1.9,1.9,0,0,1,57.5,47.2ZM86.5,78h83L128,119.5Zm112,130.8a2,2,0,0,1-1.8,1.2H59.3a2,2,0,0,1-1.8-1.2,1.9,1.9,0,0,1,.4-2.2L128,136.5l70.1,70.1A1.9,1.9,0,0,1,198.5,208.8Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M133.7,128l71.5-71.5A12,12,0,0,0,196.7,36H59.3a12,12,0,0,0-8.5,20.5L122.3,128,50.8,199.5A12,12,0,0,0,59.3,220H196.7a12,12,0,0,0,8.5-20.5ZM55.6,46.5A4,4,0,0,1,59.3,44H196.7a4,4,0,0,1,3.7,2.5,3.9,3.9,0,0,1-.9,4.3L182.3,68H73.7L56.5,50.8A3.9,3.9,0,0,1,55.6,46.5ZM81.7,76h92.6L128,122.3ZM200.4,209.5a4,4,0,0,1-3.7,2.5H59.3a4,4,0,0,1-3.7-2.5,3.9,3.9,0,0,1,.9-4.3L128,133.7l71.5,71.5A3.9,3.9,0,0,1,200.4,209.5Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M139.3,128l50.4-50.3h0L208,59.3A16,16,0,0,0,196.7,32H59.3A16,16,0,0,0,48,59.3L116.7,128,48,196.7A16,16,0,0,0,59.3,224H196.7A16,16,0,0,0,208,196.7Zm57.4-80-16,16H75.3l-16-16ZM91.3,80h73.4L128,116.7Zm-32,128L128,139.3,196.7,208Z" />
        </>
      )}
    </svg>
  );
}

export function PhHourglassSimpleLow(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M145,128l65.8-65.9A19.9,19.9,0,0,0,196.7,28H59.3A19.9,19.9,0,0,0,45.2,62.1L111,128,45.2,193.9A19.9,19.9,0,0,0,59.3,228H196.7a19.9,19.9,0,0,0,14.1-34.1ZM69,52H187l-59,59Zm59,93,19,19H109ZM69,204l16-16h86l16,16Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M53.7,202.3A8,8,0,0,0,59.3,216H196.7a8,8,0,0,0,5.6-13.7L176,176H80Z" />
          </g>
          <path d="M139.3,128,208,59.3A16,16,0,0,0,196.7,32H59.3A16,16,0,0,0,48,59.3L116.7,128,48,196.7A16,16,0,0,0,59.3,224H196.7A16,16,0,0,0,208,196.7Zm-80-80H196.7L128,116.7ZM128,139.3,156.7,168H99.3ZM59.3,208l24-24h89.4l24,24Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M139.3,128,208,59.3A16,16,0,0,0,196.7,32H59.3A16,16,0,0,0,48,59.3L116.7,128,48,196.7A16,16,0,0,0,59.3,224H196.7A16,16,0,0,0,208,196.7Zm-80-80H196.7L128,116.7ZM128,139.3,156.7,168H99.3Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M136.5,128l70.1-70.1A14,14,0,0,0,196.7,34H59.3a14,14,0,0,0-9.9,23.9L119.5,128,75.8,171.8h0L49.4,198.1A14,14,0,0,0,59.3,222H196.7a14,14,0,0,0,9.9-23.9ZM57.9,49.4a1.9,1.9,0,0,1-.4-2.2A2,2,0,0,1,59.3,46H196.7a2,2,0,0,1,1.8,1.2,1.9,1.9,0,0,1-.4,2.2L128,119.5ZM128,136.5,161.5,170h-67Zm70.5,72.3a2,2,0,0,1-1.8,1.2H59.3a2,2,0,0,1-1.8-1.2,1.9,1.9,0,0,1,.4-2.2L82.5,182h91l24.6,24.6A1.9,1.9,0,0,1,198.5,208.8Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M133.7,128l71.5-71.5A12,12,0,0,0,196.7,36H59.3a12,12,0,0,0-8.5,20.5L122.3,128,50.8,199.5A12,12,0,0,0,59.3,220H196.7a12,12,0,0,0,8.5-20.5ZM56.5,50.8a3.9,3.9,0,0,1-.9-4.3A4,4,0,0,1,59.3,44H196.7a4,4,0,0,1,3.7,2.5,3.9,3.9,0,0,1-.9,4.3L128,122.3ZM128,133.7,166.3,172H89.7Zm72.4,75.8a4,4,0,0,1-3.7,2.5H59.3a4,4,0,0,1-3.7-2.5,3.9,3.9,0,0,1,.9-4.3L81.7,180h92.6l25.2,25.2A3.9,3.9,0,0,1,200.4,209.5Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M139.3,128,208,59.3A16,16,0,0,0,196.7,32H59.3A16,16,0,0,0,48,59.3L116.7,128,74.3,170.3h0L48,196.7A16,16,0,0,0,59.3,224H196.7A16,16,0,0,0,208,196.7Zm-80-80H196.7L128,116.7ZM128,139.3,156.7,168H99.3ZM59.3,208l24-24h89.4l24,24Z" />
        </>
      )}
    </svg>
  );
}

export function PhHourglassSimpleMedium(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M145,128l65.8-65.9A19.9,19.9,0,0,0,196.7,28H59.3A19.9,19.9,0,0,0,45.2,62.1L111,128,45.2,193.9A19.9,19.9,0,0,0,59.3,228H196.7a19.9,19.9,0,0,0,14.1-34.1Zm42-76L171,68H85L69,52ZM109,92h38l-19,19ZM69,204l47-47v11a12,12,0,0,0,24,0V157l47,47Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <polygon points="128 128 176 80 80 80 128 128" />
          </g>
          <path d="M139.3,128l42.4-42.3h0L208,59.3A16,16,0,0,0,196.7,32H59.3A16,16,0,0,0,48,59.3L116.7,128,48,196.7A16,16,0,0,0,59.3,224H196.7A16,16,0,0,0,208,196.7Zm57.4-80-24,24H83.3l-24-24ZM99.3,88h57.4L128,116.7Zm-40,120L120,147.3V168a8,8,0,0,0,16,0V147.3L196.7,208Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M139.3,128l34.4-34.3h0L208,59.3A16,16,0,0,0,196.7,32H59.3A16,16,0,0,0,48,59.3L116.7,128,48,196.7A16,16,0,0,0,59.3,224H196.7A16,16,0,0,0,208,196.7Zm57.4-80-24,24H83.3l-24-24ZM59.3,208,120,147.3V168a8,8,0,0,0,16,0V147.3L196.7,208Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M136.5,128l70.1-70.1A14,14,0,0,0,196.7,34H59.3a14,14,0,0,0-9.9,23.9L119.5,128,49.4,198.1A14,14,0,0,0,59.3,222H196.7a14,14,0,0,0,9.9-23.9Zm-79-80.8A2,2,0,0,1,59.3,46H196.7a2,2,0,0,1,1.8,1.2,1.9,1.9,0,0,1-.4,2.2L173.5,74h-91L57.9,49.4A1.9,1.9,0,0,1,57.5,47.2ZM94.5,86h67L128,119.5Zm104,122.8a2,2,0,0,1-1.8,1.2H59.3a2,2,0,0,1-1.8-1.2,1.9,1.9,0,0,1,.4-2.2L122,142.5V168a6,6,0,0,0,12,0V142.5l64.1,64.1A1.9,1.9,0,0,1,198.5,208.8Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M133.7,128l71.5-71.5A12,12,0,0,0,196.7,36H59.3a12,12,0,0,0-8.5,20.5L122.3,128,50.8,199.5A12,12,0,0,0,59.3,220H196.7a12,12,0,0,0,8.5-20.5ZM55.6,46.5A4,4,0,0,1,59.3,44H196.7a4,4,0,0,1,3.7,2.5,3.9,3.9,0,0,1-.9,4.3L174.3,76H81.7L56.5,50.8A3.9,3.9,0,0,1,55.6,46.5ZM89.7,84h76.6L128,122.3ZM200.4,209.5a4,4,0,0,1-3.7,2.5H59.3a4,4,0,0,1-3.7-2.5,3.9,3.9,0,0,1,.9-4.3L124,137.7V168a4,4,0,0,0,8,0V137.7l67.5,67.5A3.9,3.9,0,0,1,200.4,209.5Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M139.3,128l42.4-42.3h0L208,59.3A16,16,0,0,0,196.7,32H59.3A16,16,0,0,0,48,59.3L116.7,128,48,196.7A16,16,0,0,0,59.3,224H196.7A16,16,0,0,0,208,196.7Zm57.4-80-24,24H83.3l-24-24ZM99.3,88h57.4L128,116.7Zm-40,120L120,147.3V168a8,8,0,0,0,16,0V147.3L196.7,208Z" />
        </>
      )}
    </svg>
  );
}

export function PhHourglassSimple(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M196.7,228H59.3a19.9,19.9,0,0,1-14.1-34.1L111,128,45.2,62.1A19.9,19.9,0,0,1,59.3,28H196.7a19.9,19.9,0,0,1,14.1,34.1L145,128l65.8,65.9A19.9,19.9,0,0,1,196.7,228Zm-2.8-17.2ZM69,204H187l-59-59ZM69,52l59,59,59-59Zm127.7,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M59.3,40H196.7a8,8,0,0,1,5.6,13.7L53.7,202.3A8,8,0,0,0,59.3,216H196.7a8,8,0,0,0,5.6-13.7L53.7,53.7A8,8,0,0,1,59.3,40Z" />
          </g>
          <path d="M139.3,128,208,59.3A16,16,0,0,0,196.7,32H59.3A16,16,0,0,0,48,59.3L116.7,128,48,196.7A16,16,0,0,0,59.3,224H196.7A16,16,0,0,0,208,196.7Zm-80-80H196.7L128,116.7Zm0,160L128,139.3,196.7,208Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M208,196.7A16,16,0,0,1,196.7,224H59.3A16,16,0,0,1,48,196.7L116.7,128,48,59.3A16,16,0,0,1,59.3,32H196.7A16,16,0,0,1,208,59.3L139.3,128Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M196.7,222H59.3a14,14,0,0,1-9.9-23.9L119.5,128,49.4,57.9A14,14,0,0,1,59.3,34H196.7a14,14,0,0,1,9.9,23.9L136.5,128l70.1,70.1a14,14,0,0,1-9.9,23.9ZM128,136.5,57.9,206.6a1.9,1.9,0,0,0-.4,2.2,2,2,0,0,0,1.8,1.2H196.7a2,2,0,0,0,1.8-1.2,1.9,1.9,0,0,0-.4-2.2ZM59.3,46a2,2,0,0,0-1.8,1.2,1.9,1.9,0,0,0,.4,2.2L128,119.5l70.1-70.1a1.9,1.9,0,0,0,.4-2.2,2,2,0,0,0-1.8-1.2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M196.7,220H59.3a12,12,0,0,1-8.5-20.5L122.3,128,50.8,56.5A12,12,0,0,1,59.3,36H196.7a12,12,0,0,1,8.5,20.5L133.7,128l71.5,71.5a12,12,0,0,1-8.5,20.5ZM128,133.7,56.5,205.2a3.9,3.9,0,0,0-.9,4.3,4,4,0,0,0,3.7,2.5H196.7a4,4,0,0,0,3.7-2.5,3.9,3.9,0,0,0-.9-4.3ZM59.3,44a4,4,0,0,0-3.7,2.5,3.9,3.9,0,0,0,.9,4.3L128,122.3l71.5-71.5a3.9,3.9,0,0,0,.9-4.3,4,4,0,0,0-3.7-2.5Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M196.7,224H59.3A16,16,0,0,1,48,196.7L116.7,128,48,59.3A16,16,0,0,1,59.3,32H196.7A16,16,0,0,1,208,59.3L139.3,128,208,196.7A16,16,0,0,1,196.7,224Zm0-15.9ZM59.3,208H196.7L128,139.3Zm0-160L128,116.7,196.7,48Zm137.5,0Z" />
        </>
      )}
    </svg>
  );
}

export function PhHourglass(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M204,75.6V40a20.1,20.1,0,0,0-20-20H72A20.1,20.1,0,0,0,52,40V76a20.1,20.1,0,0,0,8,16l48,36L60,164a20.1,20.1,0,0,0-8,16v36a20.1,20.1,0,0,0,20,20H184a20.1,20.1,0,0,0,20-20V180.4a20.3,20.3,0,0,0-7.9-16L147.9,128l48.2-36.4A20.3,20.3,0,0,0,204,75.6ZM180,212H76V182l52-39,52,39.3Zm0-138.3L128,113,76,74V44H180Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M128,128,67.2,82.4A8.1,8.1,0,0,1,64,76V40a8,8,0,0,1,8-8H184a8,8,0,0,1,8,8V75.6a8.1,8.1,0,0,1-3.2,6.4L128,128h0" />
          </g>
          <g opacity="0.2">
            <path d="M128,128,67.2,173.6A8.1,8.1,0,0,0,64,180v36a8,8,0,0,0,8,8H184a8,8,0,0,0,8-8V180.4a8.1,8.1,0,0,0-3.2-6.4L128,128h0" />
          </g>
          <path d="M200,75.6V40a16,16,0,0,0-16-16H72A16,16,0,0,0,56,40V76a16.1,16.1,0,0,0,6.4,12.8L114.7,128,62.4,167.2A16.1,16.1,0,0,0,56,180v36a16,16,0,0,0,16,16H184a16,16,0,0,0,16-16V180.4a16.1,16.1,0,0,0-6.4-12.8L141.3,128l52.3-39.6A16.1,16.1,0,0,0,200,75.6ZM184,216H72V180l56-42,56,42.4Zm0-140.4L128,118,72,76V40H184Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M193.6,88.4,141.3,128l52.3,39.6a16.1,16.1,0,0,1,6.4,12.8V216a16,16,0,0,1-16,16H72a16,16,0,0,1-16-16V180a16.1,16.1,0,0,1,6.4-12.8L114.7,128,62.4,88.8A16.1,16.1,0,0,1,56,76V40A16,16,0,0,1,72,24H184a16,16,0,0,1,16,16V75.6A16.1,16.1,0,0,1,193.6,88.4Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M198,75.6V40a14,14,0,0,0-14-14H72A14,14,0,0,0,58,40V76a14,14,0,0,0,5.6,11.2L118,128,63.6,168.8A14,14,0,0,0,58,180v36a14,14,0,0,0,14,14H184a14,14,0,0,0,14-14V180.4a14,14,0,0,0-5.6-11.2L138,128l54.4-41.2A14,14,0,0,0,198,75.6ZM186,180.4V216a2,2,0,0,1-2,2H72a2,2,0,0,1-2-2V180a2.1,2.1,0,0,1,.8-1.6L128,135.5l57.2,43.3A1.8,1.8,0,0,1,186,180.4Zm0-104.8a1.8,1.8,0,0,1-.8,1.6L128,120.5,70.8,77.6A2.1,2.1,0,0,1,70,76V40a2,2,0,0,1,2-2H184a2,2,0,0,1,2,2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M196,75.6V40a12,12,0,0,0-12-12H72A12,12,0,0,0,60,40V76a12.1,12.1,0,0,0,4.8,9.6L121.3,128,64.8,170.4A12.1,12.1,0,0,0,60,180v36a12,12,0,0,0,12,12H184a12,12,0,0,0,12-12V180.4a11.9,11.9,0,0,0-4.8-9.6L134.6,128l56.6-42.8A11.9,11.9,0,0,0,196,75.6Zm-8,104.8V216a4,4,0,0,1-4,4H72a4,4,0,0,1-4-4V180a4,4,0,0,1,1.6-3.2L128,133l58.4,44.2A3.9,3.9,0,0,1,188,180.4Zm0-104.8a3.9,3.9,0,0,1-1.6,3.2L128,123,69.6,79.2A4,4,0,0,1,68,76V40a4,4,0,0,1,4-4H184a4,4,0,0,1,4,4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M200,75.6V40a16,16,0,0,0-16-16H72A16,16,0,0,0,56,40V76a16.1,16.1,0,0,0,6.4,12.8L114.7,128,62.4,167.2A16.1,16.1,0,0,0,56,180v36a16,16,0,0,0,16,16H184a16,16,0,0,0,16-16V180.4a16.1,16.1,0,0,0-6.4-12.8L141.3,128l52.3-39.6A16.1,16.1,0,0,0,200,75.6ZM184,216H72V180l56-42,56,42.4Zm0-140.4L128,118,72,76V40H184Z" />
        </>
      )}
    </svg>
  );
}

export function PhHouseLine(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M240,204H228V115.5a20,20,0,0,0-6.6-14.8L141.4,28a19.9,19.9,0,0,0-26.9,0l-80,72.7A20.3,20.3,0,0,0,28,115.5V204H16a12,12,0,0,0,0,24H240a12,12,0,0,0,0-24ZM52,117.3l76-69.1,76,69.1V204H164V160a20.1,20.1,0,0,0-20-20H112a20.1,20.1,0,0,0-20,20v44H52ZM140,204H116V164h24Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M152,216V152H104v64H40V115.5a7.9,7.9,0,0,1,2.6-5.9l80-72.7a8,8,0,0,1,10.8,0l80,72.7a8.3,8.3,0,0,1,2.6,5.9V216Z" />
          </g>
          <path d="M240,208H224V115.5a15.7,15.7,0,0,0-5.3-11.8L138.7,31a15.9,15.9,0,0,0-21.5,0l-80,72.7A16,16,0,0,0,32,115.5V208H16a8,8,0,0,0,0,16H240a8,8,0,0,0,0-16ZM48,115.5l80-72.7,80,72.7V208H160V160a16,16,0,0,0-16-16H112a16,16,0,0,0-16,16v48H48ZM144,208H112V160h32Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M240,208H224V115.5a15.7,15.7,0,0,0-5.3-11.8L138.7,31a15.9,15.9,0,0,0-21.5,0l-80,72.7A16,16,0,0,0,32,115.5V208H16a8,8,0,0,0,0,16H240a8,8,0,0,0,0-16Zm-88,0H104V160a8,8,0,0,1,8-8h32a8,8,0,0,1,8,8Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M240,210H222V115.5a13.9,13.9,0,0,0-4.6-10.3l-80-72.8a14.1,14.1,0,0,0-18.8,0l-80,72.8A13.9,13.9,0,0,0,34,115.5V210H16a6,6,0,0,0,0,12H240a6,6,0,0,0,0-12ZM46,115.5a1.8,1.8,0,0,1,.6-1.4l80-72.8a2,2,0,0,1,2.7,0l80,72.8a1.6,1.6,0,0,1,.7,1.4V210H158V160a14,14,0,0,0-14-14H112a14,14,0,0,0-14,14v50H46ZM146,210H110V160a2,2,0,0,1,2-2h32a2,2,0,0,1,2,2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M240,212H220V115.5a11.8,11.8,0,0,0-3.9-8.8l-80-72.8a12,12,0,0,0-16.2,0l-80,72.8a11.8,11.8,0,0,0-3.9,8.8V212H16a4,4,0,0,0,0,8H240a4,4,0,0,0,0-8ZM44,115.5a3.9,3.9,0,0,1,1.3-2.9l80-72.8a4,4,0,0,1,5.4,0l80,72.8a3.9,3.9,0,0,1,1.3,2.9V212H156V160a12,12,0,0,0-12-12H112a12,12,0,0,0-12,12v52H44ZM148,212H108V160a4,4,0,0,1,4-4h32a4,4,0,0,1,4,4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M240,208H224V115.5a16,16,0,0,0-5.2-11.8L138.8,31a16,16,0,0,0-21.6,0l-80,72.7A16,16,0,0,0,32,115.5V208H16a8,8,0,0,0,0,16H240a8,8,0,0,0,0-16ZM48,115.5l80-72.7,80,72.7V208H160V160a16,16,0,0,0-16-16H112a16,16,0,0,0-16,16v48H48ZM144,208H112V160h32Z" />
        </>
      )}
    </svg>
  );
}

export function PhHouseSimple(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M208,228H48a20.1,20.1,0,0,1-20-20V115.5a20,20,0,0,1,6.6-14.8L114.5,28a20,20,0,0,1,27,0l80,72.7h0a20.3,20.3,0,0,1,6.5,14.8V208A20.1,20.1,0,0,1,208,228ZM52,204H204V117.3L128,48.2,52,117.3Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M213.4,109.6l-80-72.7a8,8,0,0,0-10.8,0l-80,72.7a8.3,8.3,0,0,0-2.6,5.9V208a8,8,0,0,0,8,8H208a8,8,0,0,0,8-8V115.5A7.9,7.9,0,0,0,213.4,109.6Z" />
          </g>
          <path d="M208,224H48a16,16,0,0,1-16-16V115.5a16,16,0,0,1,5.2-11.8l80-72.7a16,16,0,0,1,21.6,0l80,72.7h0a16,16,0,0,1,5.2,11.8V208A16,16,0,0,1,208,224ZM128,42.8,48,115.5V208H208V115.5Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M224,115.5V208a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V115.5a16,16,0,0,1,5.2-11.8l80-72.7a16,16,0,0,1,21.6,0l80,72.7h0A16,16,0,0,1,224,115.5Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M208,222H48a14,14,0,0,1-14-14V115.5a13.9,13.9,0,0,1,4.6-10.3l80-72.8a14.1,14.1,0,0,1,18.8,0l80,72.8h0a13.9,13.9,0,0,1,4.6,10.3V208A14,14,0,0,1,208,222ZM128,40.8a1.9,1.9,0,0,0-1.3.5l-80,72.8a1.6,1.6,0,0,0-.7,1.4V208a2,2,0,0,0,2,2H208a2,2,0,0,0,2-2V115.5a1.8,1.8,0,0,0-.6-1.4L129.3,41.3A1.9,1.9,0,0,0,128,40.8Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M208,220H48a12,12,0,0,1-12-12V115.5a11.8,11.8,0,0,1,3.9-8.8l80-72.8a12,12,0,0,1,16.2,0l80,72.8h0a11.8,11.8,0,0,1,3.9,8.8V208A12,12,0,0,1,208,220ZM128,38.8a3.9,3.9,0,0,0-2.7,1l-80,72.8a3.9,3.9,0,0,0-1.3,2.9V208a4,4,0,0,0,4,4H208a4,4,0,0,0,4-4V115.5a3.9,3.9,0,0,0-1.3-2.9l-80-72.8A3.9,3.9,0,0,0,128,38.8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M208,224H48a16,16,0,0,1-16-16V115.5a16,16,0,0,1,5.2-11.8l80-72.7a16,16,0,0,1,21.6,0l80,72.7h0a16,16,0,0,1,5.2,11.8V208A16,16,0,0,1,208,224ZM128,42.8,48,115.5V208H208V115.5Z" />
        </>
      )}
    </svg>
  );
}

export function PhHouse(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M208,228H160a20.1,20.1,0,0,1-20-20V164H116v44a20.1,20.1,0,0,1-20,20H48a20.1,20.1,0,0,1-20-20V115.5a20,20,0,0,1,6.6-14.8L114.5,28a20,20,0,0,1,27,0l80,72.7a20.3,20.3,0,0,1,6.5,14.8V208a20.1,20.1,0,0,1-20,20Zm-44-24h40V117.3L128,48.2,52,117.3V204H92V160a20.1,20.1,0,0,1,20-20h32a20.1,20.1,0,0,1,20,20Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M152,208V160a8,8,0,0,0-8-8H112a8,8,0,0,0-8,8v48a8,8,0,0,1-8,8H48a8,8,0,0,1-8-8V115.5a8.3,8.3,0,0,1,2.6-5.9l80-72.7a8,8,0,0,1,10.8,0l80,72.7a7.9,7.9,0,0,1,2.6,5.9V208a8,8,0,0,1-8,8H160A8,8,0,0,1,152,208Z" />
          </g>
          <path d="M208,224H160a16,16,0,0,1-16-16V160H112v48a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V115.5a16,16,0,0,1,5.2-11.8l80-72.7a16,16,0,0,1,21.6,0l80,72.7a16,16,0,0,1,5.2,11.8V208a16,16,0,0,1-16,16Zm-96-80h32a16,16,0,0,1,16,16v48h48V115.5L128,42.8,48,115.5V208H96V160A16,16,0,0,1,112,144Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M224,115.5V208a16.1,16.1,0,0,1-8.4,14.1A15.3,15.3,0,0,1,208,224H160a8,8,0,0,1-8-8V168a8,8,0,0,0-8-8H112a8,8,0,0,0-8,8v48a8,8,0,0,1-8,8H48a15.9,15.9,0,0,1-12-5.4,16.9,16.9,0,0,1-4-11V115.5a16,16,0,0,1,5.2-11.8l80-72.7a16,16,0,0,1,21.6,0l80,72.7A16,16,0,0,1,224,115.5Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M208,222H160a14,14,0,0,1-14-14V160a2,2,0,0,0-2-2H112a2,2,0,0,0-2,2v48a14,14,0,0,1-14,14H48a14.3,14.3,0,0,1-9.9-4.1A14.1,14.1,0,0,1,34,208V115.5a13.9,13.9,0,0,1,4.6-10.3l80-72.8a14.1,14.1,0,0,1,18.8,0l80,72.8a13.9,13.9,0,0,1,4.6,10.3V208a14,14,0,0,1-14,14Zm-96-76h32a14,14,0,0,1,14,14v48a2,2,0,0,0,2,2h48a2.1,2.1,0,0,0,2-2V115.5a1.8,1.8,0,0,0-.6-1.4L129.3,41.3a1.9,1.9,0,0,0-2.6,0l-80,72.8a1.6,1.6,0,0,0-.7,1.4V208a2.1,2.1,0,0,0,2,2H96a2,2,0,0,0,2-2V160A14,14,0,0,1,112,146Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M208,220H160a12,12,0,0,1-12-12V160a4,4,0,0,0-4-4H112a4,4,0,0,0-4,4v48a12,12,0,0,1-12,12H48a11.9,11.9,0,0,1-8.5-3.5A12.3,12.3,0,0,1,36,208V115.5a11.8,11.8,0,0,1,3.9-8.8l80-72.8a12,12,0,0,1,16.2,0l80,72.8a11.8,11.8,0,0,1,3.9,8.8V208a12,12,0,0,1-12,12Zm-96-72h32a12,12,0,0,1,12,12v48a4,4,0,0,0,4,4h48a4.1,4.1,0,0,0,4-4V115.5a3.9,3.9,0,0,0-1.3-2.9l-80-72.8a4.1,4.1,0,0,0-5.4,0l-80,72.8a3.9,3.9,0,0,0-1.3,2.9V208a4.1,4.1,0,0,0,4,4H96a4,4,0,0,0,4-4V160A12,12,0,0,1,112,148Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M208,224H160a16,16,0,0,1-16-16V160H112v48a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V115.5a16,16,0,0,1,5.2-11.8l80-72.7a16,16,0,0,1,21.6,0l80,72.7a16,16,0,0,1,5.2,11.8V208a16,16,0,0,1-16,16Zm-96-80h32a16,16,0,0,1,16,16v48h48V115.5L128,42.8,48,115.5V208H96V160A16,16,0,0,1,112,144Z" />
        </>
      )}
    </svg>
  );
}

export function PhIdentificationBadge(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M200,20H56A20.1,20.1,0,0,0,36,40V216a20.1,20.1,0,0,0,20,20H200a20.1,20.1,0,0,0,20-20V40A20.1,20.1,0,0,0,200,20Zm-4,192H60V44H196ZM84,68A12,12,0,0,1,96,56h64a12,12,0,0,1,0,24H96A12,12,0,0,1,84,68Zm8.8,127.4a47.9,47.9,0,0,1,70.4,0A12,12,0,0,0,180.8,179a73.4,73.4,0,0,0-19.2-14.6,44,44,0,1,0-67.2,0A73.4,73.4,0,0,0,75.2,179a12,12,0,0,0,17.6,16.4ZM108,136a20,20,0,1,1,20,20A20.1,20.1,0,0,1,108,136Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M200,32H56a8,8,0,0,0-8,8V216a8,8,0,0,0,8,8H200a8,8,0,0,0,8-8V40A8,8,0,0,0,200,32ZM128,168a32,32,0,1,1,32-32A32,32,0,0,1,128,168Z" />
          </g>
          <path d="M128,176a40,40,0,1,1,40-40A40,40,0,0,1,128,176Zm0-64a24,24,0,1,0,24,24A24.1,24.1,0,0,0,128,112Z" />
          <path d="M80,200a7.7,7.7,0,0,1-4.8-1.6,8,8,0,0,1-1.6-11.2,68,68,0,0,1,108.8,0,8,8,0,0,1-1.6,11.2,8.1,8.1,0,0,1-11.2-1.6,52,52,0,0,0-83.2,0A8,8,0,0,1,80,200Z" />
          <path d="M200,232H56a16,16,0,0,1-16-16V40A16,16,0,0,1,56,24H200a16,16,0,0,1,16,16V216A16,16,0,0,1,200,232ZM56,40V216H200V40Z" />
          <path d="M160,72H96a8,8,0,0,1,0-16h64a8,8,0,0,1,0,16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M152,136a24,24,0,1,1-24-24A24.1,24.1,0,0,1,152,136Zm64-96V216a16,16,0,0,1-16,16H56a16,16,0,0,1-16-16V40A16,16,0,0,1,56,24H200A16,16,0,0,1,216,40ZM88,56a8,8,0,0,0,8,8h64a8,8,0,0,0,0-16H96A8,8,0,0,0,88,56Zm94.4,131.2A67.8,67.8,0,0,0,155,165.5a40,40,0,1,0-54,0,67.8,67.8,0,0,0-27.4,21.7,8,8,0,0,0,1.6,11.2A7.7,7.7,0,0,0,80,200a8,8,0,0,0,6.4-3.2,52,52,0,0,1,83.2,0,8.1,8.1,0,0,0,11.2,1.6A8,8,0,0,0,182.4,187.2Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M151.1,166.1A37.6,37.6,0,0,0,166,136a38,38,0,0,0-76,0,37.6,37.6,0,0,0,14.9,30.1,66.4,66.4,0,0,0-29.7,22.3,6,6,0,0,0,9.6,7.2,54,54,0,0,1,86.4,0,6,6,0,0,0,8.4,1.2,6,6,0,0,0,1.2-8.4A66.4,66.4,0,0,0,151.1,166.1ZM128,110a26,26,0,1,1-26,26A26.1,26.1,0,0,1,128,110Zm72-84H56A14,14,0,0,0,42,40V216a14,14,0,0,0,14,14H200a14,14,0,0,0,14-14V40A14,14,0,0,0,200,26Zm2,190a2,2,0,0,1-2,2H56a2,2,0,0,1-2-2V40a2,2,0,0,1,2-2H200a2,2,0,0,1,2,2ZM90,64a6,6,0,0,1,6-6h64a6,6,0,0,1,0,12H96A6,6,0,0,1,90,64Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M146.7,166.8a36,36,0,1,0-37.4,0,63,63,0,0,0-32.5,22.8,4,4,0,0,0,.8,5.6,4.3,4.3,0,0,0,2.4.8,4.2,4.2,0,0,0,3.2-1.6,56,56,0,0,1,89.6,0,4,4,0,0,0,6.4-4.8A63,63,0,0,0,146.7,166.8ZM100,136a28,28,0,1,1,28,28A28.1,28.1,0,0,1,100,136ZM200,28H56A12,12,0,0,0,44,40V216a12,12,0,0,0,12,12H200a12,12,0,0,0,12-12V40A12,12,0,0,0,200,28Zm4,188a4,4,0,0,1-4,4H56a4,4,0,0,1-4-4V40a4,4,0,0,1,4-4H200a4,4,0,0,1,4,4ZM92,64a4,4,0,0,1,4-4h64a4,4,0,0,1,0,8H96A4,4,0,0,1,92,64Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M75.2,198.4A7.7,7.7,0,0,0,80,200a8,8,0,0,0,6.4-3.2,52,52,0,0,1,83.2,0,8.1,8.1,0,0,0,11.2,1.6,8,8,0,0,0,1.6-11.2A67.8,67.8,0,0,0,155,165.5a40,40,0,1,0-54,0,67.8,67.8,0,0,0-27.4,21.7A8,8,0,0,0,75.2,198.4ZM128,112a24,24,0,1,1-24,24A24.1,24.1,0,0,1,128,112Zm72-88H56A16,16,0,0,0,40,40V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V40A16,16,0,0,0,200,24Zm0,192H56V40H200ZM88,64a8,8,0,0,1,8-8h64a8,8,0,0,1,0,16H96A8,8,0,0,1,88,64Z" />
        </>
      )}
    </svg>
  );
}

export function PhIdentificationCard(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M140,108a12,12,0,0,1,12-12h32a12,12,0,0,1,0,24H152A12,12,0,0,1,140,108Zm12,52h32a12,12,0,0,0,0-24H152a12,12,0,0,0,0,24Zm-91.9,4A34,34,0,0,1,70,140a34,34,0,1,1,48.2,0,34,34,0,0,1,9.9,24,12,12,0,0,1-24,0,10,10,0,0,0-20,0,12,12,0,0,1-24,0Zm24-48a10,10,0,1,0,10-10A10,10,0,0,0,84.1,116ZM236,56V200a20.1,20.1,0,0,1-20,20H40a20.1,20.1,0,0,1-20-20V56A20.1,20.1,0,0,1,40,36H216A20.1,20.1,0,0,1,236,56Zm-24,4H44V196H212Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M216,48H40a8,8,0,0,0-8,8V200a8,8,0,0,0,8,8H216a8,8,0,0,0,8-8V56A8,8,0,0,0,216,48ZM92.1,144a24,24,0,1,1,24-24A23.9,23.9,0,0,1,92.1,144Z" />
          </g>
          <path d="M192,104H152a8,8,0,0,0,0,16h40a8,8,0,0,0,0-16Z" />
          <path d="M192,136H152a8,8,0,0,0,0,16h40a8,8,0,0,0,0-16Z" />
          <path d="M114.5,142.8a32,32,0,1,0-44.8,0A40.2,40.2,0,0,0,53.4,166a7.9,7.9,0,0,0,5.7,9.7l2,.3a8.1,8.1,0,0,0,7.8-6,24,24,0,0,1,46.4,0,8,8,0,0,0,9.8,5.7,7.9,7.9,0,0,0,5.7-9.7A40.2,40.2,0,0,0,114.5,142.8ZM76.1,120a16,16,0,1,1,16,16A16,16,0,0,1,76.1,120Z" />
          <path d="M216,40H40A16,16,0,0,0,24,56V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40Zm0,160H40V56H216V200Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,40H40A16,16,0,0,0,24,56V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40ZM125.1,175.7a8,8,0,0,1-9.8-5.7,24,24,0,0,0-46.4,0,8.1,8.1,0,0,1-7.8,6l-2-.3a7.9,7.9,0,0,1-5.7-9.7,40.2,40.2,0,0,1,16.3-23.2,32,32,0,1,1,44.8,0A40.2,40.2,0,0,1,130.8,166,7.9,7.9,0,0,1,125.1,175.7ZM192,152H152a8,8,0,0,1,0-16h40a8,8,0,0,1,0,16Zm0-32H152a8,8,0,0,1,0-16h40a8,8,0,0,1,0,16Zm-83.9,0a16,16,0,1,1-16-16A16,16,0,0,1,108.1,120Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M198,112a6,6,0,0,1-6,6H152a6,6,0,0,1,0-12h40A6,6,0,0,1,198,112Zm-6,26H152a6,6,0,0,0,0,12h40a6,6,0,0,0,0-12Zm-63.1,28.5a6,6,0,0,1-11.6,3,26,26,0,0,0-50.4,0,6,6,0,0,1-5.8,4.5l-1.5-.2a6,6,0,0,1-4.3-7.3A38,38,0,0,1,73,143.1a30,30,0,1,1,38.2,0A38,38,0,0,1,128.9,166.5ZM92.1,138a18,18,0,1,0-18-18A18.1,18.1,0,0,0,92.1,138ZM230,56V200a14,14,0,0,1-14,14H40a14,14,0,0,1-14-14V56A14,14,0,0,1,40,42H216A14,14,0,0,1,230,56Zm-12,0a2,2,0,0,0-2-2H40a2,2,0,0,0-2,2V200a2,2,0,0,0,2,2H216a2,2,0,0,0,2-2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M196,112a4,4,0,0,1-4,4H152a4,4,0,0,1,0-8h40A4,4,0,0,1,196,112Zm-4,28H152a4,4,0,0,0,0,8h40a4,4,0,0,0,0-8Zm-65,27a4,4,0,0,1-7.8,2A28,28,0,0,0,65,169a4.1,4.1,0,0,1-3.9,3h-1a4.2,4.2,0,0,1-2.9-4.9,36.4,36.4,0,0,1,19.6-23.6,28,28,0,1,1,30.6,0A36.4,36.4,0,0,1,127,167ZM92.1,140a20,20,0,1,0-20-20A20.1,20.1,0,0,0,92.1,140ZM228,56V200a12,12,0,0,1-12,12H40a12,12,0,0,1-12-12V56A12,12,0,0,1,40,44H216A12,12,0,0,1,228,56Zm-8,0a4,4,0,0,0-4-4H40a4,4,0,0,0-4,4V200a4,4,0,0,0,4,4H216a4,4,0,0,0,4-4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M200,112a8,8,0,0,1-8,8H152a8,8,0,0,1,0-16h40A8,8,0,0,1,200,112Zm-8,24H152a8,8,0,0,0,0,16h40a8,8,0,0,0,0-16Zm-61.2,30a7.9,7.9,0,0,1-5.7,9.7,8,8,0,0,1-9.8-5.7,24,24,0,0,0-46.4,0,8.1,8.1,0,0,1-7.8,6l-2-.3a7.9,7.9,0,0,1-5.7-9.7,40.2,40.2,0,0,1,16.3-23.2,32,32,0,1,1,44.8,0A40.2,40.2,0,0,1,130.8,166ZM92.1,136a16,16,0,1,0-16-16A16,16,0,0,0,92.1,136ZM232,56V200a16,16,0,0,1-16,16H40a16,16,0,0,1-16-16V56A16,16,0,0,1,40,40H216A16,16,0,0,1,232,56ZM216,200V56H40V200Z" />
        </>
      )}
    </svg>
  );
}

export function PhImageSquare(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M88.7,103.3A15.9,15.9,0,0,1,84,92a16,16,0,0,1,32,0h0a16,16,0,0,1-16,16A15.9,15.9,0,0,1,88.7,103.3ZM228,48V160h0v48a20.1,20.1,0,0,1-20,20H48a20.1,20.1,0,0,1-20-20V176h0V48A20.1,20.1,0,0,1,48,28H208A20.1,20.1,0,0,1,228,48ZM204,165l-36-36-41.9,41.8a19.9,19.9,0,0,1-28.2,0L80,153,52,181v23H204Zm0-113H52v95l13.9-13.8a19.9,19.9,0,0,1,28.2,0L112,151l41.9-41.8a19.9,19.9,0,0,1,28.2,0L204,131Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M40,176V48a8,8,0,0,1,8-8H208a8,8,0,0,1,8,8V160h0l-42.3-42.3a8,8,0,0,0-11.4,0l-44.6,44.6a8,8,0,0,1-11.4,0L85.7,141.7a8,8,0,0,0-11.4,0Z" />
          </g>
          <path d="M88,92a12,12,0,1,1,12,12A12,12,0,0,1,88,92ZM224,48V208a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V176h0V48A16,16,0,0,1,48,32H208A16,16,0,0,1,224,48ZM48,156.7,68.7,136a16.1,16.1,0,0,1,22.6,0L112,156.7,156.7,112a16.1,16.1,0,0,1,22.6,0L208,140.7V48H48ZM208,208V163.3l-40-40L123.3,168a16.1,16.1,0,0,1-22.6,0L80,147.3l-32,32V208Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M208,32H48A16,16,0,0,0,32,48V176h0v32a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32Zm-28.7,80a16.1,16.1,0,0,0-22.6,0L112,156.7,91.3,136a16.1,16.1,0,0,0-22.6,0L48,156.7V48H208v92.7ZM112,92a12,12,0,1,1-12-12A12,12,0,0,1,112,92Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M208,34H48A14,14,0,0,0,34,48V176h0v32a14,14,0,0,0,14,14H208a14,14,0,0,0,14-14V48A14,14,0,0,0,208,34Zm2,174a2,2,0,0,1-2,2H48a2,2,0,0,1-2-2V178.5l32.6-32.6a1.9,1.9,0,0,1,2.8,0l20.7,20.7a14,14,0,0,0,19.8,0l44.7-44.7a1.9,1.9,0,0,1,2.8,0L210,162.5Zm0-62.5-32.1-32.1a14,14,0,0,0-19.8,0l-44.7,44.7a1.9,1.9,0,0,1-2.8,0L89.9,137.4a14.1,14.1,0,0,0-19.8,0L46,161.5V48a2,2,0,0,1,2-2H208a2,2,0,0,1,2,2ZM110,92h0a10,10,0,0,1-10,10A9.9,9.9,0,0,1,90,92a10,10,0,0,1,20,0Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M208,36H48A12,12,0,0,0,36,48V208a12,12,0,0,0,12,12H208a12,12,0,0,0,12-12V48A12,12,0,0,0,208,36Zm4,172a4,4,0,0,1-4,4H48a4,4,0,0,1-4-4V177.7l33.2-33.2a3.9,3.9,0,0,1,5.6,0l20.7,20.7a12.2,12.2,0,0,0,17,0l44.7-44.7a3.9,3.9,0,0,1,5.6,0L212,161.7Zm0-57.7-35.5-35.5a12.1,12.1,0,0,0-17,0l-44.7,44.7a3.9,3.9,0,0,1-5.6,0L88.5,138.8a12,12,0,0,0-17,0L44,166.3V48a4,4,0,0,1,4-4H208a4,4,0,0,1,4,4ZM108,92h0a8,8,0,0,1-8,8,7.9,7.9,0,0,1-8-8,8,8,0,0,1,16,0Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M224,160V48a16,16,0,0,0-16-16H48A16,16,0,0,0,32,48V176h0v32a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V160ZM208,48v92.7L179.3,112a16.1,16.1,0,0,0-22.6,0L112,156.7,91.3,136a16.1,16.1,0,0,0-22.6,0L48,156.7V48Zm0,160H48V179.3l32-32L100.7,168a16.1,16.1,0,0,0,22.6,0L168,123.3l40,40V208ZM91.5,100.5A11.9,11.9,0,0,1,88,92a12,12,0,0,1,24,0h0a12,12,0,0,1-12,12A12.3,12.3,0,0,1,91.5,100.5Z" />
        </>
      )}
    </svg>
  );
}

export function PhImage(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M236,184V56a20.1,20.1,0,0,0-20-20H40A20.1,20.1,0,0,0,20,56V168h0v32a20.1,20.1,0,0,0,20,20H216a20.1,20.1,0,0,0,20-20V184ZM212,60v95l-21.9-21.8a19.8,19.8,0,0,0-28.2,0L144,151l-41.9-41.8a19.9,19.9,0,0,0-28.2,0L44,139V60ZM44,196V173l44-44,41.9,41.8a19.8,19.8,0,0,0,28.2,0L176,153l36,36v7Zm100.7-84.7A15.9,15.9,0,0,1,140,100a16,16,0,0,1,32,0h0a16,16,0,0,1-16,16A15.9,15.9,0,0,1,144.7,111.3Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M32,168l50.3-50.3a8,8,0,0,1,11.4,0l44.6,44.6a8,8,0,0,0,11.4,0l20.6-20.6a8,8,0,0,1,11.4,0L224,184V56a8,8,0,0,0-8-8H40a8,8,0,0,0-8,8Z" />
          </g>
          <path d="M232,184V56a16,16,0,0,0-16-16H40A16,16,0,0,0,24,56V168h0v32a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V184ZM216,56V164.7L187.3,136a16.1,16.1,0,0,0-22.6,0L144,156.7,99.3,112a16.1,16.1,0,0,0-22.6,0L40,148.7V56Zm0,144H40V171.3l48-48L132.7,168a15.9,15.9,0,0,0,22.6,0L176,147.3l40,40V200Zm-68.5-91.5A11.9,11.9,0,0,1,144,100a12,12,0,0,1,24,0h0a12,12,0,0,1-12,12A12.3,12.3,0,0,1,147.5,108.5Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M168,100h0a12,12,0,0,1-12,12,12.3,12.3,0,0,1-8.5-3.5A11.9,11.9,0,0,1,144,100a12,12,0,0,1,24,0Zm64-44V184h0v16a16,16,0,0,1-16,16H40a16,16,0,0,1-16-16V168h0V56A16,16,0,0,1,40,40H216A16,16,0,0,1,232,56ZM216,164.7V56H40v92.7L76.7,112a16.1,16.1,0,0,1,22.6,0L144,156.7,164.7,136a16.1,16.1,0,0,1,22.6,0Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M216,42H40A14,14,0,0,0,26,56V168h0v32a14,14,0,0,0,14,14H216a14,14,0,0,0,14-14V56A14,14,0,0,0,216,42ZM40,54H216a2,2,0,0,1,2,2V169.5l-32.1-32.1a14.1,14.1,0,0,0-19.8,0l-20.7,20.7a1.9,1.9,0,0,1-2.8,0L97.9,113.4a14.1,14.1,0,0,0-19.8,0L38,153.5V56A2,2,0,0,1,40,54ZM216,202H40a2,2,0,0,1-2-2V170.5l48.6-48.6a1.9,1.9,0,0,1,2.8,0l44.7,44.7a14.1,14.1,0,0,0,19.8,0l20.7-20.7a1.9,1.9,0,0,1,2.8,0L218,186.5V200A2,2,0,0,1,216,202Zm-67.1-94.9A9.9,9.9,0,0,1,146,100a10,10,0,0,1,20,0h0a10,10,0,0,1-10,10A9.9,9.9,0,0,1,148.9,107.1Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M216,44H40A12,12,0,0,0,28,56V200a12,12,0,0,0,12,12H216a12,12,0,0,0,12-12V56A12,12,0,0,0,216,44ZM40,52H216a4,4,0,0,1,4,4V174.3l-35.5-35.5a12,12,0,0,0-17,0l-20.7,20.7a3.9,3.9,0,0,1-5.6,0L96.5,114.8a12,12,0,0,0-17,0L36,158.3V56A4,4,0,0,1,40,52ZM216,204H40a4,4,0,0,1-4-4V169.7l49.2-49.2a3.9,3.9,0,0,1,5.6,0l44.7,44.7a12.2,12.2,0,0,0,17,0l20.7-20.7a3.9,3.9,0,0,1,5.6,0L220,185.7V200A4,4,0,0,1,216,204Zm-65.7-98.3A8.3,8.3,0,0,1,148,100a8,8,0,0,1,16,0h0a8,8,0,0,1-8,8A8.3,8.3,0,0,1,150.3,105.7Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M232,184V56a16,16,0,0,0-16-16H40A16,16,0,0,0,24,56V168h0v32a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V184ZM216,56V164.7L187.3,136a16.1,16.1,0,0,0-22.6,0L144,156.7,99.3,112a16.1,16.1,0,0,0-22.6,0L40,148.7V56Zm0,144H40V171.3l48-48L132.7,168a15.9,15.9,0,0,0,22.6,0L176,147.3l40,40V200Zm-68.5-91.5A11.9,11.9,0,0,1,144,100a12,12,0,0,1,24,0h0a12,12,0,0,1-12,12A12.3,12.3,0,0,1,147.5,108.5Z" />
        </>
      )}
    </svg>
  );
}

export function PhInfinity(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M252,128a60,60,0,0,1-102.4,42.4l-.5-.5L89.2,102.3a36,36,0,1,0,0,51.4l3.1-3.5a12,12,0,0,1,18,15.9l-3.4,3.8-.5.5a59.9,59.9,0,0,1-84.8,0A60,60,0,0,1,64,68a59.3,59.3,0,0,1,42.4,17.6l.5.5,59.9,67.6a36,36,0,1,0,0-51.4l-3.1,3.5a12,12,0,0,1-18-15.9l3.4-3.8.5-.5A60,60,0,0,1,252,128Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M248,128a56,56,0,0,1-95.6,39.6l-.3-.4-60-67.6A39.5,39.5,0,0,0,64,88a40,40,0,1,0,0,80,39.5,39.5,0,0,0,28.1-11.6l8.5-9.6a8,8,0,0,1,12,10.6l-8.7,9.8-.3.4a56,56,0,0,1-79.2,0A56,56,0,0,1,64,72a55.5,55.5,0,0,1,39.6,16.4l.3.4,60,67.6A39.5,39.5,0,0,0,192,168a40,40,0,1,0,0-80,39.5,39.5,0,0,0-28.1,11.6l-8.5,9.6a8,8,0,0,1-12-10.6l8.7-9.8.3-.4A56,56,0,0,1,248,128Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M248,128a56,56,0,0,1-95.6,39.6l-.3-.4-60-67.6A39.5,39.5,0,0,0,64,88a40,40,0,1,0,0,80,39.5,39.5,0,0,0,28.1-11.6l8.5-9.6a8,8,0,0,1,12,10.6l-8.7,9.8-.3.4a56,56,0,0,1-79.2,0A56,56,0,0,1,64,72a55.5,55.5,0,0,1,39.6,16.4l.3.4,60,67.6A39.5,39.5,0,0,0,192,168a40,40,0,1,0,0-80,39.5,39.5,0,0,0-28.1,11.6l-8.5,9.6a8,8,0,0,1-12-10.6l8.7-9.8.3-.4A56,56,0,0,1,248,128Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M246,128a54,54,0,0,1-92.2,38.2l-.2-.3-60-67.7a42,42,0,1,0,0,59.6l8.5-9.6a6,6,0,1,1,9,7.9l-8.7,9.8-.2.3a54,54,0,1,1,0-76.4l.2.3,60,67.7a42,42,0,1,0,0-59.6l-8.5,9.6a6,6,0,1,1-9-7.9l8.7-9.8.2-.3A54,54,0,0,1,246,128Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M244,128a52,52,0,0,1-88.8,36.8c0-.1-.1-.1-.1-.2L95,96.8a44,44,0,1,0,0,62.4l8.6-9.7a4,4,0,1,1,6,5.3l-8.7,9.8c0,.1-.1.1-.1.2a52,52,0,1,1,0-73.6c0,.1.1.1.1.2L161,159.2a44,44,0,1,0,0-62.4l-8.6,9.7a4,4,0,1,1-6-5.3l8.7-9.8c0-.1.1-.1.1-.2A52,52,0,0,1,244,128Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M248,128a56,56,0,0,1-95.6,39.6l-.3-.4-60-67.6A39.5,39.5,0,0,0,64,88a40,40,0,1,0,0,80,39.5,39.5,0,0,0,28.1-11.6l8.5-9.6a8,8,0,0,1,12,10.6l-8.7,9.8-.3.4a56,56,0,0,1-79.2,0A56,56,0,0,1,64,72a55.5,55.5,0,0,1,39.6,16.4l.3.4,60,67.6A39.5,39.5,0,0,0,192,168a40,40,0,1,0,0-80,39.5,39.5,0,0,0-28.1,11.6l-8.5,9.6a8,8,0,0,1-12-10.6l8.7-9.8.3-.4A56,56,0,0,1,248,128Z" />
        </>
      )}
    </svg>
  );
}

export function PhInfo(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,20A108,108,0,1,0,236,128,108.1,108.1,0,0,0,128,20Zm0,192a84,84,0,1,1,84-84A84.1,84.1,0,0,1,128,212Zm20-36a12,12,0,0,1-12,12h-8a12,12,0,0,1-12-12V135.3a11.9,11.9,0,0,1-8-11.3,12,12,0,0,1,12-12h8a12,12,0,0,1,12,12v40.7A11.9,11.9,0,0,1,148,176ZM110,84a16,16,0,1,1,16,16A16,16,0,0,1,110,84Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M224,128a96,96,0,1,1-96-96A96,96,0,0,1,224,128Z"
            opacity="0.2"
          />
          <path d="M128,24A104,104,0,1,0,232,128,104.1,104.1,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm16-40a8,8,0,0,1-8,8h-8a8,8,0,0,1-8-8V128a8,8,0,0,1,0-16h8a8,8,0,0,1,8,8v48A8,8,0,0,1,144,176ZM114,84a12,12,0,1,1,12,12A12,12,0,0,1,114,84Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.1,104.1,0,0,0,128,24Zm-2,48a12,12,0,1,1-12,12A12,12,0,0,1,126,72Zm10,112h-8a8,8,0,0,1-8-8V128a8,8,0,0,1,0-16h8a8,8,0,0,1,8,8v48a8,8,0,0,1,0,16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,26A102,102,0,1,0,230,128,102.2,102.2,0,0,0,128,26Zm0,192a90,90,0,1,1,90-90A90.1,90.1,0,0,1,128,218Zm14-42a6,6,0,0,1-6,6h-8a6,6,0,0,1-6-6V126h-2a6,6,0,0,1,0-12h8a6,6,0,0,1,6,6v50h2A6,6,0,0,1,142,176ZM116,84a10,10,0,1,1,10,10A10,10,0,0,1,116,84Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,28A100,100,0,1,0,228,128,100.2,100.2,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm12-44a4,4,0,0,1-4,4h-8a4,4,0,0,1-4-4V124h-4a4,4,0,0,1,0-8h8a4,4,0,0,1,4,4v52h4A4,4,0,0,1,140,176ZM118,84a8,8,0,1,1,8,8A8,8,0,0,1,118,84Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.1,104.1,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm16-40a8,8,0,0,1-8,8h-8a8,8,0,0,1-8-8V128a8,8,0,0,1,0-16h8a8,8,0,0,1,8,8v48A8,8,0,0,1,144,176ZM114,84a12,12,0,1,1,12,12A12,12,0,0,1,114,84Z" />
        </>
      )}
    </svg>
  );
}

export function PhInstagramLogo(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,82a46,46,0,1,0,46,46A46,46,0,0,0,128,82Zm0,68a22,22,0,1,1,22-22A22.1,22.1,0,0,1,128,150ZM176,20H80A60,60,0,0,0,20,80v96a60,60,0,0,0,60,60h96a60,60,0,0,0,60-60V80A60,60,0,0,0,176,20Zm36,156a36,36,0,0,1-36,36H80a36,36,0,0,1-36-36V80A36,36,0,0,1,80,44h96a36,36,0,0,1,36,36ZM196,76a16,16,0,1,1-16-16A16,16,0,0,1,196,76Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M172,36H84A48,48,0,0,0,36,84v88a48,48,0,0,0,48,48h88a48,48,0,0,0,48-48V84A48,48,0,0,0,172,36ZM128,168a40,40,0,1,1,40-40A40,40,0,0,1,128,168Z" />
          </g>
          <path d="M128,80a48,48,0,1,0,48,48A48,48,0,0,0,128,80Zm0,80a32,32,0,1,1,32-32A32.1,32.1,0,0,1,128,160Z" />
          <path d="M172,28H84A56,56,0,0,0,28,84v88a56,56,0,0,0,56,56h88a56,56,0,0,0,56-56V84A56,56,0,0,0,172,28Zm40,144a40,40,0,0,1-40,40H84a40,40,0,0,1-40-40V84A40,40,0,0,1,84,44h88a40,40,0,0,1,40,40Z" />
          <circle cx="180" cy="76" r="12" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M160,128a32,32,0,1,1-32-32A32.1,32.1,0,0,1,160,128Zm68-44v88a56,56,0,0,1-56,56H84a56,56,0,0,1-56-56V84A56,56,0,0,1,84,28h88A56,56,0,0,1,228,84Zm-52,44a48,48,0,1,0-48,48A48,48,0,0,0,176,128Zm16-52a12,12,0,1,0-12,12A12,12,0,0,0,192,76Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,82a46,46,0,1,0,46,46A46,46,0,0,0,128,82Zm0,80a34,34,0,1,1,34-34A34.1,34.1,0,0,1,128,162ZM172,30H84A54,54,0,0,0,30,84v88a54,54,0,0,0,54,54h88a54,54,0,0,0,54-54V84A54,54,0,0,0,172,30Zm42,142a42,42,0,0,1-42,42H84a42,42,0,0,1-42-42V84A42,42,0,0,1,84,42h88a42,42,0,0,1,42,42ZM190,76a10,10,0,1,1-10-10A10,10,0,0,1,190,76Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,84a44,44,0,1,0,44,44A44,44,0,0,0,128,84Zm0,80a36,36,0,1,1,36-36A36,36,0,0,1,128,164ZM172,32H84A52,52,0,0,0,32,84v88a52,52,0,0,0,52,52h88a52,52,0,0,0,52-52V84A52,52,0,0,0,172,32Zm44,140a44,44,0,0,1-44,44H84a44,44,0,0,1-44-44V84A44,44,0,0,1,84,40h88a44,44,0,0,1,44,44ZM188,76a8,8,0,1,1-8-8A8,8,0,0,1,188,76Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,80a48,48,0,1,0,48,48A48,48,0,0,0,128,80Zm0,80a32,32,0,1,1,32-32A32.1,32.1,0,0,1,128,160ZM172,28H84A56,56,0,0,0,28,84v88a56,56,0,0,0,56,56h88a56,56,0,0,0,56-56V84A56,56,0,0,0,172,28Zm40,144a40,40,0,0,1-40,40H84a40,40,0,0,1-40-40V84A40,40,0,0,1,84,44h88a40,40,0,0,1,40,40ZM192,76a12,12,0,1,1-12-12A12,12,0,0,1,192,76Z" />
        </>
      )}
    </svg>
  );
}

export function PhIntersect(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M178,78A84,84,0,1,0,78,178,84,84,0,1,0,178,78Zm-68,48.9L129.1,146a59,59,0,0,1-28.9,9.8A59,59,0,0,1,110,126.9Zm36,2.2L126.9,110a59,59,0,0,1,28.9-9.8A59,59,0,0,1,146,129.1ZM36,96A60,60,0,0,1,152.7,76.3a84.4,84.4,0,0,0-76.4,76.4A60.2,60.2,0,0,1,36,96ZM160,220a60.2,60.2,0,0,1-56.7-40.3,84.4,84.4,0,0,0,76.4-76.4A60,60,0,0,1,160,220Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M88,160c0,2.5.5,4.8.7,7.3,2.5.2,4.8.7,7.3.7a72,72,0,0,0,72-72c0-2.5-.5-4.8-.7-7.3-2.5-.2-4.8-.7-7.3-.7A72,72,0,0,0,88,160Z" />
          </g>
          <path d="M174.6,81.4a80,80,0,1,0-93.2,93.2,80,80,0,1,0,93.2-93.2ZM32,96A64,64,0,0,1,158,80.1,79.9,79.9,0,0,0,80.1,158,64.2,64.2,0,0,1,32,96Zm128,0a64.1,64.1,0,0,1-64,64A64.1,64.1,0,0,1,160,96Zm0,128a64.2,64.2,0,0,1-62-48.1A79.9,79.9,0,0,0,175.9,98,64,64,0,0,1,160,224Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M174.6,81.4a80,80,0,1,0-93.2,93.2,80,80,0,1,0,93.2-93.2ZM32,96A64,64,0,0,1,158,80.1,79.9,79.9,0,0,0,80.1,158,64.2,64.2,0,0,1,32,96ZM160,224a64.2,64.2,0,0,1-62-48.1A79.9,79.9,0,0,0,175.9,98,64,64,0,0,1,160,224Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M172.9,83.1a78,78,0,1,0-89.8,89.8,78,78,0,1,0,89.8-89.8ZM30,96A66,66,0,0,1,160.5,82H160a77.2,77.2,0,0,0-40.4,11.3l-.6.4A78.4,78.4,0,0,0,93.7,119l-.4.6A77.2,77.2,0,0,0,82,160v.5A66.1,66.1,0,0,1,30,96Zm104.2,53.7-27.9-27.9a63.4,63.4,0,0,1,15.5-15.5l27.9,27.9A63.4,63.4,0,0,1,134.2,149.7ZM94,160a66.6,66.6,0,0,1,6-27.5L123.5,156A66.6,66.6,0,0,1,96,162H94.1A13.4,13.4,0,0,1,94,160Zm68-64a66.6,66.6,0,0,1-6,27.5L132.5,100A66.6,66.6,0,0,1,160,94h2Zm-2,130a66.1,66.1,0,0,1-64.5-52H96a77.2,77.2,0,0,0,40.4-11.3l.6-.4A78.4,78.4,0,0,0,162.3,137l.4-.6A77.2,77.2,0,0,0,174,96v-.5A66,66,0,0,1,160,226Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M171.2,84.8a76,76,0,1,0-86.4,86.4,76,76,0,1,0,86.4-86.4ZM28,96A68,68,0,0,1,162.9,84.1H160a75.2,75.2,0,0,0-39.3,11l-.6.4a76.7,76.7,0,0,0-24.7,24.7,2.1,2.1,0,0,0-.4.6A75.2,75.2,0,0,0,84,160a27.1,27.1,0,0,0,.1,2.9A68,68,0,0,1,28,96Zm136,0a67.2,67.2,0,0,1-7.5,30.9L129.1,99.5A67.2,67.2,0,0,1,160,92h3.9C164,93.4,164,94.7,164,96Zm-30,56.4L103.6,122A70,70,0,0,1,122,103.6L152.4,134A70,70,0,0,1,134,152.4ZM92,160a67.2,67.2,0,0,1,7.5-30.9l27.4,27.4A67.2,67.2,0,0,1,96,164H92.1C92,162.6,92,161.3,92,160Zm68,68a68,68,0,0,1-66.9-56.1H96a75.2,75.2,0,0,0,39.3-11l.6-.4a76.7,76.7,0,0,0,24.7-24.7l.4-.6A75.2,75.2,0,0,0,172,96a27.1,27.1,0,0,0-.1-2.9A68,68,0,0,1,160,228Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M174.6,81.4a80,80,0,1,0-93.2,93.2,80,80,0,1,0,93.2-93.2Zm-40.2,65.7-25.5-25.5a63.7,63.7,0,0,1,12.7-12.7l25.5,25.5A63.7,63.7,0,0,1,134.4,147.1ZM100.7,136,120,155.3A63.8,63.8,0,0,1,96,160,63.8,63.8,0,0,1,100.7,136Zm54.6-16L136,100.7A63.8,63.8,0,0,1,160,96,63.8,63.8,0,0,1,155.3,120ZM32,96A64,64,0,0,1,158,80.1a79.8,79.8,0,0,0-39,11.2,6,6,0,0,0-1.3.9,79.3,79.3,0,0,0-25.5,25.4,11,11,0,0,0-.9,1.4,79.8,79.8,0,0,0-11.2,39A64.2,64.2,0,0,1,32,96ZM160,224a64.2,64.2,0,0,1-62-48.1,79.8,79.8,0,0,0,39-11.2,6,6,0,0,0,1.3-.9,79.3,79.3,0,0,0,25.5-25.4,11,11,0,0,0,.9-1.4,79.8,79.8,0,0,0,11.2-39A64,64,0,0,1,160,224Z" />
        </>
      )}
    </svg>
  );
}

export function PhJeep(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M240,84h-9.6l-5.5-38.8A20.2,20.2,0,0,0,205.1,28H50.9A20.2,20.2,0,0,0,31.1,45.2L25.6,84H16a12,12,0,0,0,0,24h8V208a20.1,20.1,0,0,0,20,20H68a20.1,20.1,0,0,0,20-20V188h80v20a20.1,20.1,0,0,0,20,20h24a20.1,20.1,0,0,0,20-20V108h8a12,12,0,0,0,0-24ZM54.4,52H201.6l4.6,32H49.8ZM64,204H48V188H64Zm128,0V188h16v16Zm16-40H180V136a12,12,0,0,0-24,0v28H140V136a12,12,0,0,0-24,0v28H100V136a12,12,0,0,0-24,0v28H48V108H208Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M220,176v32a8,8,0,0,1-8,8H188a8,8,0,0,1-8-8V176Z" />
          </g>
          <g opacity="0.2">
            <path d="M76,176v32a8,8,0,0,1-8,8H44a8,8,0,0,1-8-8V176Z" />
          </g>
          <g opacity="0.2">
            <path d="M36,96l7-49.1A8,8,0,0,1,50.9,40H205.1a8,8,0,0,1,7.9,6.9L220,96Z" />
          </g>
          <path d="M240,88H226.9l-6-42.3A16,16,0,0,0,205.1,32H50.9A16,16,0,0,0,35.1,45.7L29.1,88H16a8,8,0,0,0,0,16H28V208a16,16,0,0,0,16,16H68a16,16,0,0,0,16-16V184h88v24a16,16,0,0,0,16,16h24a16,16,0,0,0,16-16V104h12a8,8,0,0,0,0-16ZM50.9,48H205.1l5.7,40H45.2ZM68,208H44V184H68Zm120,0V184h24v24Zm24-40H152V128a8,8,0,0,0-16,0v40H120V128a8,8,0,0,0-16,0v40H44V104H212Z" />
          <circle cx="72" cy="132" r="12" />
          <circle cx="184" cy="132" r="12" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M239.7,88H226.9l-6-42.3A16,16,0,0,0,205.1,32H50.9A16,16,0,0,0,35.1,45.7L29.1,88H16.3A8.2,8.2,0,0,0,8,95.5a8,8,0,0,0,8,8.5H28V208a16,16,0,0,0,16,16H68a16,16,0,0,0,16-16V184h16a4,4,0,0,0,4-4V128.3a8.2,8.2,0,0,1,7.5-8.3,8,8,0,0,1,8.5,8v52a4,4,0,0,0,4,4h8a4,4,0,0,0,4-4V128.3a8.2,8.2,0,0,1,7.5-8.3,8,8,0,0,1,8.5,8v52a4,4,0,0,0,4,4h16v24a16,16,0,0,0,16,16h24a16,16,0,0,0,16-16V104h12a8,8,0,0,0,8-8.5A8.2,8.2,0,0,0,239.7,88ZM72,144a12,12,0,1,1,12-12A12,12,0,0,1,72,144Zm112,0a12,12,0,1,1,12-12A12,12,0,0,1,184,144Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M240,90H225.2l-6.3-44a14.1,14.1,0,0,0-13.8-12H50.9A14.1,14.1,0,0,0,37.1,46L30.8,90H16a6,6,0,0,0,0,12H30V208a14,14,0,0,0,14,14H68a14,14,0,0,0,14-14V182h92v26a14,14,0,0,0,14,14h24a14,14,0,0,0,14-14V102h14a6,6,0,0,0,0-12ZM49,47.7A1.9,1.9,0,0,1,50.9,46H205.1a1.9,1.9,0,0,1,1.9,1.7L213.1,90H42.9ZM70,208a2,2,0,0,1-2,2H44a2,2,0,0,1-2-2V182H70Zm142,2H188a2,2,0,0,1-2-2V182h28v26A2,2,0,0,1,212,210Zm2-40H150V128a6,6,0,0,0-12,0v42H118V128a6,6,0,0,0-12,0v42H42V102H214ZM62,132a10,10,0,1,1,10,10A10,10,0,0,1,62,132Zm112,0a10,10,0,1,1,10,10A10,10,0,0,1,174,132Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M240,92H223.5l-6.6-45.7A11.9,11.9,0,0,0,205.1,36H50.9A11.9,11.9,0,0,0,39.1,46.3L32.5,92H16a4,4,0,0,0,0,8H32V208a12,12,0,0,0,12,12H68a12,12,0,0,0,12-12V180h96v28a12,12,0,0,0,12,12h24a12,12,0,0,0,12-12V100h16a4,4,0,0,0,0-8ZM47,47.4A4.1,4.1,0,0,1,50.9,44H205.1a4.1,4.1,0,0,1,3.9,3.4L215.4,92H40.6ZM72,208a4,4,0,0,1-4,4H44a4,4,0,0,1-4-4V180H72Zm140,4H188a4,4,0,0,1-4-4V180h32v28A4,4,0,0,1,212,212Zm4-40H148V128a4,4,0,0,0-8,0v44H116V128a4,4,0,0,0-8,0v44H40V100H216ZM64,132a8,8,0,1,1,8,8A8,8,0,0,1,64,132Zm112,0a8,8,0,1,1,8,8A8,8,0,0,1,176,132Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M240,88H226.9l-6-42.3A16,16,0,0,0,205.1,32H50.9A16,16,0,0,0,35.1,45.7L29.1,88H16a8,8,0,0,0,0,16H28V208a16,16,0,0,0,16,16H68a16,16,0,0,0,16-16V184h88v24a16,16,0,0,0,16,16h24a16,16,0,0,0,16-16V104h12a8,8,0,0,0,0-16ZM50.9,48H205.1l5.7,40H45.2ZM68,208H44V184H68Zm120,0V184h24v24Zm24-40H152V128a8,8,0,0,0-16,0v40H120V128a8,8,0,0,0-16,0v40H44V104H212ZM60,132a12,12,0,1,1,12,12A12,12,0,0,1,60,132Zm112,0a12,12,0,1,1,12,12A12,12,0,0,1,172,132Z" />
        </>
      )}
    </svg>
  );
}

export function PhKanban(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216,44H40A12,12,0,0,0,28,56V208a20.1,20.1,0,0,0,20,20H88a20.1,20.1,0,0,0,20-20V164h40v12a20.1,20.1,0,0,0,20,20h40a20.1,20.1,0,0,0,20-20V56A12,12,0,0,0,216,44Zm-12,64H172V68h32ZM84,68v40H52V68Zm0,136H52V132H84Zm24-64V68h40v72Zm64,32V132h32v40Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M216,56v64H160V56ZM40,208a8,8,0,0,0,8,8H88a8,8,0,0,0,8-8V120H40Z"
            opacity="0.2"
          />
          <path d="M216,48H40a8,8,0,0,0-8,8V208a16,16,0,0,0,16,16H88a16,16,0,0,0,16-16V160h48v16a16,16,0,0,0,16,16h40a16,16,0,0,0,16-16V56A8,8,0,0,0,216,48Zm-8,64H168V64h40ZM88,64v48H48V64Zm0,144H48V128H88Zm16-64V64h48v80Zm64,32V128h40v48Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,48H40a8,8,0,0,0-8,8V208a16,16,0,0,0,16,16H88a16,16,0,0,0,16-16V160h48v16a16,16,0,0,0,16,16h40a16,16,0,0,0,16-16V56A8,8,0,0,0,216,48ZM88,64v56H48V64Zm16,80V64h48v80Zm64,32V120h40v56Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M216,50H40a6,6,0,0,0-6,6V208a14,14,0,0,0,14,14H88a14,14,0,0,0,14-14V158h52v18a14,14,0,0,0,14,14h40a14,14,0,0,0,14-14V56A6,6,0,0,0,216,50Zm-6,64H166V62h44ZM90,62v52H46V62Zm0,146a2,2,0,0,1-2,2H48a2,2,0,0,1-2-2V126H90Zm12-62V62h52v84Zm106,32H168a2,2,0,0,1-2-2V126h44v50A2,2,0,0,1,208,178Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M216,52H40a4,4,0,0,0-4,4V208a12,12,0,0,0,12,12H88a12,12,0,0,0,12-12V156h56v20a12,12,0,0,0,12,12h40a12,12,0,0,0,12-12V56A4,4,0,0,0,216,52Zm-4,64H164V60h48ZM92,60v56H44V60Zm0,148a4,4,0,0,1-4,4H48a4,4,0,0,1-4-4V124H92Zm8-60V60h56v88Zm108,32H168a4,4,0,0,1-4-4V124h48v52A4,4,0,0,1,208,180Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,48H40a8,8,0,0,0-8,8V208a16,16,0,0,0,16,16H88a16,16,0,0,0,16-16V160h48v16a16,16,0,0,0,16,16h40a16,16,0,0,0,16-16V56A8,8,0,0,0,216,48ZM88,208H48V128H88Zm0-96H48V64H88Zm64,32H104V64h48Zm56,32H168V128h40Zm0-64H168V64h40Z" />
        </>
      )}
    </svg>
  );
}

export function PhKeyReturn(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M71.5,144.5a12,12,0,0,1,0-17l24-24a12,12,0,0,1,17,17L109,124h55V104a12,12,0,0,1,24,0v32a12,12,0,0,1-12,12H109l3.5,3.5a12,12,0,0,1,0,17,12.1,12.1,0,0,1-17,0ZM236,56V200a20.1,20.1,0,0,1-20,20H40a20.1,20.1,0,0,1-20-20V56A20.1,20.1,0,0,1,40,36H216A20.1,20.1,0,0,1,236,56Zm-24,4H44V196H212Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M224,56V200a8,8,0,0,1-8,8H40a8,8,0,0,1-8-8V56a8,8,0,0,1,8-8H216A8,8,0,0,1,224,56Z"
            opacity="0.2"
          />
          <path d="M184,104v32a8,8,0,0,1-8,8H99.3l10.4,10.3a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0l-24-24a8.1,8.1,0,0,1,0-11.4l24-24a8.1,8.1,0,0,1,11.4,11.4L99.3,128H168V104a8,8,0,0,1,16,0Zm48-48V200a16,16,0,0,1-16,16H40a16,16,0,0,1-16-16V56A16,16,0,0,1,40,40H216A16,16,0,0,1,232,56ZM216,200V56H40V200Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,40H40A16,16,0,0,0,24,56V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40Zm-32,96a8,8,0,0,1-8,8H99.3l10.4,10.3a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0l-24-24a8.1,8.1,0,0,1,0-11.4l24-24a8.1,8.1,0,0,1,11.4,11.4L99.3,128H168V104a8,8,0,0,1,16,0Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M182,104v32a6,6,0,0,1-6,6H94.5l13.7,13.8a5.9,5.9,0,1,1-8.4,8.4l-24-24a5.8,5.8,0,0,1,0-8.4l24-24a5.9,5.9,0,0,1,8.4,8.4L94.5,130H170V104a6,6,0,0,1,12,0Zm48-48V200a14,14,0,0,1-14,14H40a14,14,0,0,1-14-14V56A14,14,0,0,1,40,42H216A14,14,0,0,1,230,56Zm-12,0a2,2,0,0,0-2-2H40a2,2,0,0,0-2,2V200a2,2,0,0,0,2,2H216a2,2,0,0,0,2-2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M180,104v32a4,4,0,0,1-4,4H89.7l17.1,17.2a3.9,3.9,0,0,1,0,5.6,3.9,3.9,0,0,1-5.6,0l-24-24a3.9,3.9,0,0,1,0-5.6l24-24a4,4,0,0,1,5.6,5.6L89.7,132H172V104a4,4,0,0,1,8,0Zm48-48V200a12,12,0,0,1-12,12H40a12,12,0,0,1-12-12V56A12,12,0,0,1,40,44H216A12,12,0,0,1,228,56Zm-8,0a4,4,0,0,0-4-4H40a4,4,0,0,0-4,4V200a4,4,0,0,0,4,4H216a4,4,0,0,0,4-4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M184,104v32a8,8,0,0,1-8,8H99.3l10.4,10.3a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0l-24-24a8.1,8.1,0,0,1,0-11.4l24-24a8.1,8.1,0,0,1,11.4,11.4L99.3,128H168V104a8,8,0,0,1,16,0Zm48-48V200a16,16,0,0,1-16,16H40a16,16,0,0,1-16-16V56A16,16,0,0,1,40,40H216A16,16,0,0,1,232,56ZM216,200V56H40V200Z" />
        </>
      )}
    </svg>
  );
}

export function PhKey(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M160,12A84.1,84.1,0,0,0,76,96a82.3,82.3,0,0,0,3.4,23.7L23.5,175.5A12.3,12.3,0,0,0,20,184v40a12,12,0,0,0,12,12H72a12,12,0,0,0,12-12V212H96a12,12,0,0,0,12-12V188h12a12.3,12.3,0,0,0,8.5-3.5l7.8-7.9A82.3,82.3,0,0,0,160,180a84,84,0,0,0,0-168Zm0,144a59.5,59.5,0,0,1-22.1-4.2,12,12,0,0,0-13.2,2.5L115,164H96a12,12,0,0,0-12,12v12H72a12,12,0,0,0-12,12v12H44V189l57.7-57.7a12,12,0,0,0,2.5-13.2A60,60,0,1,1,160,156Zm36-80a16,16,0,1,1-16-16A16,16,0,0,1,196,76Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M93.2,122.8A70.3,70.3,0,0,1,88,96a72,72,0,1,1,72,72,70.3,70.3,0,0,1-26.8-5.2h0L120,176H96v24H72v24H32V184l61.2-61.2Z" />
          </g>
          <circle cx="180" cy="76" r="12" />
          <path d="M160,16A80.1,80.1,0,0,0,83.9,120.8L26.3,178.3A8.1,8.1,0,0,0,24,184v40a8,8,0,0,0,8,8H72a8,8,0,0,0,8-8V208H96a8,8,0,0,0,8-8V184h16a8.1,8.1,0,0,0,5.7-2.3l9.5-9.6A80,80,0,1,0,160,16Zm0,144a64,64,0,0,1-23.7-4.5,7.9,7.9,0,0,0-8.8,1.7L116.7,168H96a8,8,0,0,0-8,8v16H72a8,8,0,0,0-8,8v16H40V187.3l58.8-58.8a7.9,7.9,0,0,0,1.7-8.8A64,64,0,1,1,160,160Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M160,16A80.1,80.1,0,0,0,83.9,120.8L26.3,178.3A8.1,8.1,0,0,0,24,184v40a8,8,0,0,0,8,8H72a8,8,0,0,0,8-8V208H96a8,8,0,0,0,8-8V184h16a8.1,8.1,0,0,0,5.7-2.3l9.5-9.6A80,80,0,1,0,160,16Zm20,76a16,16,0,1,1,16-16A16,16,0,0,1,180,92Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M160,18A78.1,78.1,0,0,0,82,96a77.2,77.2,0,0,0,4.2,25.3L27.8,179.8A5.6,5.6,0,0,0,26,184v40a6,6,0,0,0,6,6H72a6,6,0,0,0,6-6V206H96a6,6,0,0,0,6-6V182h18a5.6,5.6,0,0,0,4.2-1.8l10.5-10.4A77.2,77.2,0,0,0,160,174a78,78,0,0,0,0-156Zm0,144a65.4,65.4,0,0,1-24.4-4.7,6.2,6.2,0,0,0-6.7,1.3L117.5,170H96a6,6,0,0,0-6,6v18H72a6,6,0,0,0-6,6v18H38V186.5l59.4-59.4a6.2,6.2,0,0,0,1.3-6.7A65.4,65.4,0,0,1,94,96a66,66,0,1,1,66,66Zm30-86a10,10,0,1,1-10-10A10,10,0,0,1,190,76Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M160,20A76.1,76.1,0,0,0,84,96a74.9,74.9,0,0,0,4.5,25.8L29.2,181.2A3.6,3.6,0,0,0,28,184v40a4,4,0,0,0,4,4H72a4,4,0,0,0,4-4V204H96a4,4,0,0,0,4-4V180h20a3.6,3.6,0,0,0,2.8-1.2l11.4-11.3A74.9,74.9,0,0,0,160,172a76,76,0,0,0,0-152Zm0,144a67.6,67.6,0,0,1-25.2-4.8,4,4,0,0,0-4.5.8l-12,12H96a4,4,0,0,0-4,4v20H72a4,4,0,0,0-4,4v20H36V185.7l60-60a4,4,0,0,0,.8-4.5A68,68,0,1,1,160,164Zm28-88a8,8,0,1,1-8-8A8,8,0,0,1,188,76Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M160,16A80.1,80.1,0,0,0,83.9,120.8L26.3,178.3A8.1,8.1,0,0,0,24,184v40a8,8,0,0,0,8,8H72a8,8,0,0,0,8-8V208H96a8,8,0,0,0,8-8V184h16a8.1,8.1,0,0,0,5.7-2.3l9.5-9.6A80,80,0,1,0,160,16Zm0,144a64,64,0,0,1-23.7-4.5,7.9,7.9,0,0,0-8.8,1.7L116.7,168H96a8,8,0,0,0-8,8v16H72a8,8,0,0,0-8,8v16H40V187.3l58.8-58.8a7.9,7.9,0,0,0,1.7-8.8A64,64,0,1,1,160,160Zm32-84a12,12,0,1,1-12-12A12,12,0,0,1,192,76Z" />
        </>
      )}
    </svg>
  );
}

export function PhKeyboard(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M223.5,44H32.5A20.5,20.5,0,0,0,12,64.5v127A20.5,20.5,0,0,0,32.5,212h191A20.5,20.5,0,0,0,244,191.5V64.5A20.5,20.5,0,0,0,223.5,44ZM220,188H36V68H220ZM52,128a12,12,0,0,1,12-12H192a12,12,0,0,1,0,24H64A12,12,0,0,1,52,128Zm0-36A12,12,0,0,1,64,80H192a12,12,0,0,1,0,24H64A12,12,0,0,1,52,92Zm0,72a12,12,0,0,1,12-12h8a12,12,0,0,1,0,24H64A12,12,0,0,1,52,164Zm108,0a12,12,0,0,1-12,12H108a12,12,0,0,1,0-24h40A12,12,0,0,1,160,164Zm44,0a12,12,0,0,1-12,12h-8a12,12,0,0,1,0-24h8A12,12,0,0,1,204,164Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect x="24" y="56" width="208" height="144" rx="8.5" />
          </g>
          <path d="M223.5,47.5H32.5a17,17,0,0,0-17,17v127a17,17,0,0,0,17,17h191a17,17,0,0,0,17-17V64.5A17,17,0,0,0,223.5,47.5Zm0,144H32.5V64.5h191v127Z" />
          <path d="M200,120H56a8,8,0,0,0,0,16H200a8,8,0,0,0,0-16Z" />
          <path d="M200,88H56a8,8,0,0,0,0,16H200a8,8,0,0,0,0-16Z" />
          <path d="M64,152H56a8,8,0,0,0,0,16h8a8,8,0,0,0,0-16Z" />
          <path d="M160,152H96a8,8,0,0,0,0,16h64a8,8,0,0,0,0-16Z" />
          <path d="M200,152h-8a8,8,0,0,0,0,16h8a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M223.5,47.5H32.5a17,17,0,0,0-17,17v127a17,17,0,0,0,17,17h191a17,17,0,0,0,17-17V64.5A17,17,0,0,0,223.5,47.5ZM64,168H56a8,8,0,0,1,0-16h8a8,8,0,0,1,0,16Zm96,0H96a8,8,0,0,1,0-16h64a8,8,0,0,1,0,16Zm40,0h-8a8,8,0,0,1,0-16h8a8,8,0,0,1,0,16Zm0-32H56a8,8,0,0,1,0-16H200a8,8,0,0,1,0,16Zm0-32H56a8,8,0,0,1,0-16H200a8,8,0,0,1,0,16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M223.5,50H32.5A14.5,14.5,0,0,0,18,64.5v127A14.5,14.5,0,0,0,32.5,206h191A14.5,14.5,0,0,0,238,191.5V64.5A14.5,14.5,0,0,0,223.5,50ZM226,191.5a2.5,2.5,0,0,1-2.5,2.5H32.5a2.5,2.5,0,0,1-2.5-2.5V64.5A2.5,2.5,0,0,1,32.5,62h191a2.5,2.5,0,0,1,2.5,2.5ZM206,128a6,6,0,0,1-6,6H56a6,6,0,0,1,0-12H200A6,6,0,0,1,206,128Zm0-32a6,6,0,0,1-6,6H56a6,6,0,0,1,0-12H200A6,6,0,0,1,206,96ZM70,160a6,6,0,0,1-6,6H56a6,6,0,0,1,0-12h8A6,6,0,0,1,70,160Zm96,0a6,6,0,0,1-6,6H96a6,6,0,0,1,0-12h64A6,6,0,0,1,166,160Zm40,0a6,6,0,0,1-6,6h-8a6,6,0,0,1,0-12h8A6,6,0,0,1,206,160Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M223.5,52H32.5A12.5,12.5,0,0,0,20,64.5v127A12.5,12.5,0,0,0,32.5,204h191A12.5,12.5,0,0,0,236,191.5V64.5A12.5,12.5,0,0,0,223.5,52ZM228,191.5a4.5,4.5,0,0,1-4.5,4.5H32.5a4.5,4.5,0,0,1-4.5-4.5V64.5A4.5,4.5,0,0,1,32.5,60h191a4.5,4.5,0,0,1,4.5,4.5ZM204,128a4,4,0,0,1-4,4H56a4,4,0,0,1,0-8H200A4,4,0,0,1,204,128Zm0-32a4,4,0,0,1-4,4H56a4,4,0,0,1,0-8H200A4,4,0,0,1,204,96ZM68,160a4,4,0,0,1-4,4H56a4,4,0,0,1,0-8h8A4,4,0,0,1,68,160Zm96,0a4,4,0,0,1-4,4H96a4,4,0,0,1,0-8h64A4,4,0,0,1,164,160Zm40,0a4,4,0,0,1-4,4h-8a4,4,0,0,1,0-8h8A4,4,0,0,1,204,160Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M223.5,47.5H32.5a17,17,0,0,0-17,17v127a17,17,0,0,0,17,17h191a17,17,0,0,0,17-17V64.5A17,17,0,0,0,223.5,47.5Zm0,144H32.5V64.5h191v127ZM208,128a8,8,0,0,1-8,8H56a8,8,0,0,1,0-16H200A8,8,0,0,1,208,128Zm0-32a8,8,0,0,1-8,8H56a8,8,0,0,1,0-16H200A8,8,0,0,1,208,96ZM72,160a8,8,0,0,1-8,8H56a8,8,0,0,1,0-16h8A8,8,0,0,1,72,160Zm96,0a8,8,0,0,1-8,8H96a8,8,0,0,1,0-16h64A8,8,0,0,1,168,160Zm40,0a8,8,0,0,1-8,8h-8a8,8,0,0,1,0-16h8A8,8,0,0,1,208,160Z" />
        </>
      )}
    </svg>
  );
}

export function PhKeyhole(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,20A108,108,0,1,0,236,128,108.1,108.1,0,0,0,128,20Zm0,192a84,84,0,1,1,84-84A84.1,84.1,0,0,1,128,212Zm31.9-79.9a39.7,39.7,0,0,0,8-27.4,40,40,0,1,0-71.8,27.4l-8.7,30.4A19.6,19.6,0,0,0,90.6,180a20,20,0,0,0,16,8h42.8a20,20,0,0,0,16-8,19.6,19.6,0,0,0,3.2-17.5Zm-48,31.9,9.1-31.7a12,12,0,0,0-3.6-12.3,16.2,16.2,0,0,1-5.4-12,16.1,16.1,0,0,1,17.1-16,16,16,0,0,1,14.8,14.7,15.6,15.6,0,0,1-5.3,13.3,12,12,0,0,0-3.6,12.3l9.1,31.7Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M155.2,164.8a8,8,0,0,1-7.3,11.2H108.1a8,8,0,0,1-7.3-11.2l13.8-32.2a28.1,28.1,0,1,1,26.8,0Z"
            opacity="0.2"
          />
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm35.8-111.8A36,36,0,0,0,92,108a35.7,35.7,0,0,0,12.7,27.4L93.4,161.7A16.1,16.1,0,0,0,108.1,184h39.8a16.1,16.1,0,0,0,14.7-22.3l-11.3-26.3A35.9,35.9,0,0,0,163.8,104.2ZM134,135.7,147.9,168H108.1L122,135.7a7.9,7.9,0,0,0-3.6-10.1A20.1,20.1,0,0,1,108,108a20.1,20.1,0,0,1,20-20h2.3a20.1,20.1,0,0,1,7.3,37.5A7.9,7.9,0,0,0,134,135.7Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M146.4,134.2l12.5,29.1a12,12,0,0,1-11,16.7H108.1a12,12,0,0,1-11-16.7l12.5-29.1a32,32,0,0,1-2.9-50.1,32.4,32.4,0,0,1,25-7.9,32,32,0,0,1,14.7,58ZM232,128A104,104,0,1,1,128,24,104.2,104.2,0,0,1,232,128Zm-16,0a88,88,0,1,0-88,88A88.1,88.1,0,0,0,216,128Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,26A102,102,0,1,0,230,128,102.2,102.2,0,0,0,128,26Zm0,192a90,90,0,1,1,90-90A90.1,90.1,0,0,1,128,218Zm33.8-113.6a34,34,0,1,0-54.7,30.4L95.3,162.5a13.8,13.8,0,0,0,1.1,13.2,14.1,14.1,0,0,0,11.7,6.3h39.8a14.1,14.1,0,0,0,11.7-6.3,13.8,13.8,0,0,0,1.1-13.2l-11.8-27.7A33.9,33.9,0,0,0,161.8,104.4Zm-23.3,22.9a6.1,6.1,0,0,0-2.6,7.7l13.8,32.2a1.7,1.7,0,0,1-.2,1.9,1.9,1.9,0,0,1-1.6.9H108.1a1.9,1.9,0,0,1-1.6-.9,1.7,1.7,0,0,1-.2-1.9L120.1,135a6.1,6.1,0,0,0-2.6-7.7A21.8,21.8,0,0,1,106,108a22.2,22.2,0,0,1,7.3-16.4A21.6,21.6,0,0,1,128,86h2.6a22.2,22.2,0,0,1,19.3,19.6A22,22,0,0,1,138.5,127.3Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,28A100,100,0,1,0,228,128,100.2,100.2,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm31.8-115.4a32,32,0,0,0-28.1-28.4A32,32,0,0,0,96,108a32,32,0,0,0,13.6,26.2L97.1,163.3a12,12,0,0,0,11,16.7h39.8a12,12,0,0,0,11-16.7l-12.5-29.1A32.2,32.2,0,0,0,159.8,104.6Zm-22.1,29.6,13.8,32.2a3.7,3.7,0,0,1-.3,3.8,4,4,0,0,1-3.3,1.8H108.1a4,4,0,0,1-3.3-1.8,3.7,3.7,0,0,1-.3-3.8l13.8-32.2a4.1,4.1,0,0,0-1.8-5.1,24,24,0,0,1-4.5-39A23.7,23.7,0,0,1,128,84l2.8.2a24,24,0,0,1,8.7,44.9A4.1,4.1,0,0,0,137.7,134.2Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm35.8-111.8A36,36,0,0,0,92,108a35.7,35.7,0,0,0,12.7,27.4L93.4,161.7A16.1,16.1,0,0,0,108.1,184h39.8a16.1,16.1,0,0,0,14.7-22.3l-11.3-26.3A35.9,35.9,0,0,0,163.8,104.2ZM134,135.7,147.9,168H108.1L122,135.7a7.9,7.9,0,0,0-3.6-10.1A20.1,20.1,0,0,1,108,108a20.1,20.1,0,0,1,20-20h2.3a20.1,20.1,0,0,1,7.3,37.5A7.9,7.9,0,0,0,134,135.7Z" />
        </>
      )}
    </svg>
  );
}

export function PhKnife(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M234.6,29.4a31.9,31.9,0,0,0-45.2,0c-.1,0-.1,0-.1.1L15.4,207.6a12,12,0,0,0,5.9,20.1,162.1,162.1,0,0,0,35.9,4.1c34.2,0,68.4-11.1,100.5-32.9a234.1,234.1,0,0,0,51.9-47.7,12,12,0,0,0-1.1-15.7l-16-16,42.3-45A32,32,0,0,0,234.6,29.4ZM143.7,179.5c-31,20.8-62.7,30.2-94.7,28.1l97.6-100L167,127.9l.6.6h0l16.1,16.1A222.4,222.4,0,0,1,143.7,179.5Zm74-121.8-.3.2-41.9,44.6L163.4,90.4l43-44.1a8,8,0,0,1,11.3,0A8.1,8.1,0,0,1,217.7,57.7Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M226.1,66.1l-50.3,53.7L146.5,90.5l51.4-52.6a19.9,19.9,0,0,1,28.2,28.2Z"
            opacity="0.2"
          />
          <path d="M231.8,32.2a28.1,28.1,0,0,0-39.7.1L18.3,210.4a8,8,0,0,0,3.9,13.4,154.1,154.1,0,0,0,35,4c33.4,0,66.8-10.9,98.3-32.2a232,232,0,0,0,50.9-46.8,8.1,8.1,0,0,0-.7-10.5l-18.8-18.7,45-47.9A28,28,0,0,0,231.8,32.2ZM189.2,144.5a227,227,0,0,1-43.1,38.2c-34.5,23.2-70,32.6-105.8,28.1L146.6,101.9l23.3,23.3c.1.1.2.3.4.4h.1Zm31.3-84c-.1,0-.1.1-.2.2l-44.7,47.6L157.7,90.4l45.8-46.9a12,12,0,0,1,17,17Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M231.8,32.2a28.1,28.1,0,0,0-39.7.1L18.3,210.4a8,8,0,0,0,3.9,13.4,154.1,154.1,0,0,0,35,4c33.4,0,66.8-10.9,98.3-32.2a232,232,0,0,0,50.9-46.8,8.1,8.1,0,0,0-.7-10.5l-18.8-18.7,45-47.9A28,28,0,0,0,231.8,32.2ZM189.2,144.5a227,227,0,0,1-43.1,38.2c-34.5,23.2-70,32.6-105.8,28.1L146.6,101.9l23.3,23.3c.1.1.2.3.4.4h.1Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M230.4,33.6a26,26,0,0,0-36.8.1L19.7,211.8a6.1,6.1,0,0,0-1.5,5.9,5.9,5.9,0,0,0,4.5,4.1,148.9,148.9,0,0,0,34.4,4c33.1,0,66.2-10.8,97.3-31.8s49.6-45.4,50.4-46.4a5.8,5.8,0,0,0-.6-7.8l-20.1-20.2,46.4-49.3A26.1,26.1,0,0,0,230.4,33.6ZM191.9,144.4a226.1,226.1,0,0,1-44.6,39.8c-36.2,24.5-73.5,33.8-111.2,28L146.5,99l24.8,24.8.4.4h.1Zm30-82.5h-.1l-46.2,49.2L154.9,90.4l47.2-48.3a14,14,0,0,1,19.8,19.8Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M229,35a24.1,24.1,0,0,0-34,.1L21.1,213.2a4.1,4.1,0,0,0,2,6.7,151.6,151.6,0,0,0,34,3.9c32.7,0,65.4-10.6,96.1-31.5s49.2-44.9,50-45.9a4,4,0,0,0-.4-5.2l-21.4-21.5L229,68.9A24.1,24.1,0,0,0,229,35ZM194.6,144.3a221.3,221.3,0,0,1-45.8,41.4c-38,25.7-77.2,35-116.8,27.9L146.5,96.2l26.3,26.2.2.3h.1Zm28.7-81a.1.1,0,0,1-.1.1L175.7,114,152.1,90.5l48.6-49.8a16,16,0,1,1,22.6,22.6Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M231.8,32.2a28.1,28.1,0,0,0-39.7.1L18.3,210.4a8,8,0,0,0,3.9,13.4,154.1,154.1,0,0,0,35,4c33.4,0,66.8-10.9,98.3-32.2a232,232,0,0,0,50.9-46.8,8.1,8.1,0,0,0-.7-10.5l-18.8-18.7,45-47.9A28,28,0,0,0,231.8,32.2ZM189.2,144.5a227,227,0,0,1-43.1,38.2c-34.5,23.2-70,32.6-105.8,28.1L146.6,101.9l23.3,23.3c.1.1.2.3.4.4h.1Zm31.3-84c-.1,0-.1.1-.2.2l-44.7,47.6L157.7,90.4l45.8-46.9a12,12,0,0,1,17,17Z" />
        </>
      )}
    </svg>
  );
}

export function PhLadderSimple(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M192,20a12,12,0,0,0-12,12V60H76V32a12,12,0,0,0-24,0V224a12,12,0,0,0,24,0V196H180v28a12,12,0,0,0,24,0V32A12,12,0,0,0,192,20ZM180,84v32H76V84ZM76,172V140H180v32Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M192,24a8,8,0,0,0-8,8V64H72V32a8,8,0,0,0-16,0V224a8,8,0,0,0,16,0V192H184v32a8,8,0,0,0,16,0V32A8,8,0,0,0,192,24Zm-8,56v40H72V80ZM72,176V136H184v40Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M192,24a8,8,0,0,0-8,8V64H72V32a8,8,0,0,0-16,0V224a8,8,0,0,0,16,0V192H184v32a8,8,0,0,0,16,0V32A8,8,0,0,0,192,24Zm-8,56v40H72V80ZM72,176V136H184v40Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M192,26a6,6,0,0,0-6,6V66H70V32a6,6,0,0,0-12,0V224a6,6,0,0,0,12,0V190H186v34a6,6,0,0,0,12,0V32A6,6,0,0,0,192,26Zm-6,52v44H70V78ZM70,178V134H186v44Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M192,28a4,4,0,0,0-4,4V68H68V32a4,4,0,0,0-8,0V224a4,4,0,0,0,8,0V188H188v36a4,4,0,0,0,8,0V32A4,4,0,0,0,192,28Zm-4,48v48H68V76ZM68,180V132H188v48Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M192,24a8,8,0,0,0-8,8V64H72V32a8,8,0,0,0-16,0V224a8,8,0,0,0,16,0V192H184v32a8,8,0,0,0,16,0V32A8,8,0,0,0,192,24Zm-8,56v40H72V80ZM72,176V136H184v40Z" />
        </>
      )}
    </svg>
  );
}

export function PhLadder(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M219.3,211.9,168.8,73l7.6-21H184a12,12,0,0,0,0-24H88A12,12,0,0,0,76,40,11.9,11.9,0,0,0,86.9,51.9l-58.2,160a12.1,12.1,0,0,0,7.2,15.4,11.8,11.8,0,0,0,4.1.7,12,12,0,0,0,11.3-7.9L60,196H98.5l-5.8,15.9a12.1,12.1,0,0,0,7.2,15.4,11.8,11.8,0,0,0,4.1.7,12,12,0,0,0,11.3-7.9L132.8,172h46.4l17.5,48.1A12,12,0,0,0,208,228a11.8,11.8,0,0,0,4.1-.7A12.1,12.1,0,0,0,219.3,211.9ZM124.7,124H86.2L95,100h38.4Zm17.4-48H103.7l8.7-24h38.5ZM68.8,172l8.7-24H116l-8.8,24Zm72.7-24L156,108.1,170.5,148Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M215.5,213.3,164.5,73l9.1-25H184a8,8,0,0,0,0-16H88a8,8,0,0,0,0,16h4.6L32.5,213.3a7.9,7.9,0,0,0,4.8,10.2,8.6,8.6,0,0,0,2.7.5,7.9,7.9,0,0,0,7.5-5.3L57.2,192h47l-7.7,21.3a7.9,7.9,0,0,0,4.8,10.2,8.6,8.6,0,0,0,2.7.5,7.9,7.9,0,0,0,7.5-5.3L130,168h52l18.5,50.7A7.9,7.9,0,0,0,208,224a8.6,8.6,0,0,0,2.7-.5A7.9,7.9,0,0,0,215.5,213.3Zm-88-85.3h-47L92.1,96h47Zm29.1-80L144.9,80H98l11.6-32ZM63.1,176l11.6-32h47L110,176Zm72.7-24L156,96.4,176.2,152Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M215.5,213.3,164.5,73l9.1-25H184a8,8,0,0,0,0-16H88a8,8,0,0,0,0,16h4.6L32.5,213.3a7.9,7.9,0,0,0,4.8,10.2,8.6,8.6,0,0,0,2.7.5,7.9,7.9,0,0,0,7.5-5.3L57.2,192h47l-7.7,21.3a7.9,7.9,0,0,0,4.8,10.2,8.6,8.6,0,0,0,2.7.5,7.9,7.9,0,0,0,7.5-5.3L130,168h52l18.5,50.7A7.9,7.9,0,0,0,208,224a8.6,8.6,0,0,0,2.7-.5A7.9,7.9,0,0,0,215.5,213.3Zm-88-85.3h-47L92.1,96h47Zm29.1-80L144.9,80H98l11.6-32ZM63.1,176l11.6-32h47L110,176Zm72.7-24L156,96.4,176.2,152Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M213.6,213.9,162.4,73l9.8-27H184a6,6,0,0,0,0-12H88a6,6,0,0,0,0,12h7.4l-61,167.9a5.9,5.9,0,0,0,3.5,7.7,5.2,5.2,0,0,0,2.1.4,5.8,5.8,0,0,0,5.6-3.9L55.8,190h51.3l-8.7,23.9a5.9,5.9,0,0,0,3.5,7.7,5.2,5.2,0,0,0,2.1.4,5.8,5.8,0,0,0,5.6-3.9l19-52.1h54.8l19,52.1A5.8,5.8,0,0,0,208,222a5.2,5.2,0,0,0,2.1-.4A5.9,5.9,0,0,0,213.6,213.9ZM128.9,130H77.7l13-36H142Zm30.5-84L146.3,82H95.1l13.1-36ZM60.2,178l13.1-36h51.2l-13.1,36Zm72.7-24L156,90.6,179.1,154Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M211.8,214.6,160.3,73l10.5-29H184a4,4,0,0,0,0-8H88a4,4,0,0,0,0,8H98.3L36.2,214.6a4.2,4.2,0,0,0,2.4,5.2l1.4.2a4.1,4.1,0,0,0,3.8-2.6L54.4,188h55.5l-9.7,26.6a4.2,4.2,0,0,0,2.4,5.2l1.4.2a4.1,4.1,0,0,0,3.8-2.6L127.2,164h57.6l19.4,53.4A4.1,4.1,0,0,0,208,220l1.4-.2A4.2,4.2,0,0,0,211.8,214.6ZM130.3,132H74.8L89.3,92h55.5Zm32-88L147.7,84H92.3l14.5-40ZM57.3,180l14.6-40h55.5l-14.6,40Zm72.8-24L156,84.7,181.9,156Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M215.5,213.3,164.5,73l9.1-25H184a8,8,0,0,0,0-16H88a8,8,0,0,0,0,16h4.6L32.5,213.3a7.9,7.9,0,0,0,4.8,10.2,8.6,8.6,0,0,0,2.7.5,7.9,7.9,0,0,0,7.5-5.3L57.2,192h47l-7.7,21.3a7.9,7.9,0,0,0,4.8,10.2,8.6,8.6,0,0,0,2.7.5,7.9,7.9,0,0,0,7.5-5.3L130,168h52l18.5,50.7A7.9,7.9,0,0,0,208,224a8.6,8.6,0,0,0,2.7-.5A7.9,7.9,0,0,0,215.5,213.3Zm-88-85.3h-47L92.1,96h47Zm29.1-80L144.9,80H98l11.6-32ZM63.1,176l11.6-32h47L110,176Zm72.7-24L156,96.4,176.2,152Z" />
        </>
      )}
    </svg>
  );
}

export function PhLamp(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M246.3,136.1l-41.2-96A20,20,0,0,0,186.7,28H69.3A20,20,0,0,0,50.9,40.1l-41.2,96A20,20,0,0,0,28.1,164H116v40H96a12,12,0,0,0,0,24h64a12,12,0,0,0,0-24H140V164h48v28a12,12,0,0,0,24,0V164h15.9a20,20,0,0,0,18.4-27.9ZM34.2,140,71.9,52H184.1l37.7,88Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M227.9,152H28.1a8,8,0,0,1-7.3-11.2l41.1-96A8.1,8.1,0,0,1,69.3,40H186.7a8.1,8.1,0,0,1,7.4,4.8l41.1,96A8,8,0,0,1,227.9,152Z"
            opacity="0.2"
          />
          <path d="M242.6,137.7l-41.2-96A15.9,15.9,0,0,0,186.7,32H69.3a15.9,15.9,0,0,0-14.7,9.7l-41.2,96A16.1,16.1,0,0,0,28.1,160H120v48H96a8,8,0,0,0,0,16h64a8,8,0,0,0,0-16H136V160h56v32a8,8,0,0,0,16,0V160h19.9a16.1,16.1,0,0,0,14.7-22.3ZM28.1,144,69.3,48H186.7l41.2,96Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M241.2,152.8a15.8,15.8,0,0,1-13.3,7.2H208v32a8,8,0,0,1-16,0V160H136v48h24a8,8,0,0,1,0,16H96a8,8,0,0,1,0-16h24V160H28.1a16.1,16.1,0,0,1-14.7-22.3l41.2-96A15.9,15.9,0,0,1,69.3,32H186.7a15.9,15.9,0,0,1,14.7,9.7l41.2,96A15.9,15.9,0,0,1,241.2,152.8Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M240.7,138.5l-41.1-96A14,14,0,0,0,186.7,34H69.3a14,14,0,0,0-12.9,8.5l-41.1,96a13.8,13.8,0,0,0,1.1,13.2A14.1,14.1,0,0,0,28.1,158H122v52H96a6,6,0,0,0,0,12h64a6,6,0,0,0,0-12H134V158h60v34a6,6,0,0,0,12,0V158h21.9a14.1,14.1,0,0,0,11.7-6.3A13.8,13.8,0,0,0,240.7,138.5Zm-11.2,6.6a1.9,1.9,0,0,1-1.6.9H28.1a1.9,1.9,0,0,1-1.6-.9,1.7,1.7,0,0,1-.2-1.9l41.1-96A2.2,2.2,0,0,1,69.3,46H186.7a2.2,2.2,0,0,1,1.9,1.2l41.1,96A1.7,1.7,0,0,1,229.5,145.1Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M238.9,139.3l-41.1-96A12.2,12.2,0,0,0,186.7,36H69.3a12.2,12.2,0,0,0-11.1,7.3l-41.1,96a12,12,0,0,0,11,16.7H124v56H96a4,4,0,0,0,0,8h64a4,4,0,0,0,0-8H132V156h64v36a4,4,0,0,0,8,0V156h23.9a12,12,0,0,0,11-16.7Zm-7.7,6.9a4,4,0,0,1-3.3,1.8H28.1a4,4,0,0,1-3.3-1.8,3.7,3.7,0,0,1-.3-3.8l41.1-96A4.1,4.1,0,0,1,69.3,44H186.7a4.1,4.1,0,0,1,3.7,2.4l41.1,96A3.7,3.7,0,0,1,231.2,146.2Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M242.6,137.7l-41.2-96A15.9,15.9,0,0,0,186.7,32H69.3a15.9,15.9,0,0,0-14.7,9.7l-41.2,96A16.1,16.1,0,0,0,28.1,160H120v48H96a8,8,0,0,0,0,16h64a8,8,0,0,0,0-16H136V160h56v32a8,8,0,0,0,16,0V160h19.9a16.1,16.1,0,0,0,14.7-22.3ZM28.1,144,69.3,48H186.7l41.2,96Z" />
        </>
      )}
    </svg>
  );
}

export function PhLaptop(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M232,156h-4V72a28.1,28.1,0,0,0-28-28H56A28.1,28.1,0,0,0,28,72v84H24a12,12,0,0,0-12,12v24a28.1,28.1,0,0,0,28,28H216a28.1,28.1,0,0,0,28-28V168A12,12,0,0,0,232,156ZM52,72a4,4,0,0,1,4-4H200a4,4,0,0,1,4,4v84H52ZM220,192a4,4,0,0,1-4,4H40a4,4,0,0,1-4-4V180H220ZM156,96a12,12,0,0,1-12,12H112a12,12,0,0,1,0-24h32A12,12,0,0,1,156,96Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M40,176V72A16,16,0,0,1,56,56H200a16,16,0,0,1,16,16V176Z" />
          </g>
          <path d="M232,168h-8V72a24.1,24.1,0,0,0-24-24H56A24.1,24.1,0,0,0,32,72v96H24a8,8,0,0,0-8,8v16a24.1,24.1,0,0,0,24,24H216a24.1,24.1,0,0,0,24-24V176A8,8,0,0,0,232,168ZM48,72a8,8,0,0,1,8-8H200a8,8,0,0,1,8,8v96H48ZM224,192a8,8,0,0,1-8,8H40a8,8,0,0,1-8-8v-8H224ZM152,88a8,8,0,0,1-8,8H112a8,8,0,0,1,0-16h32A8,8,0,0,1,152,88Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M232,168h-8V72a24.1,24.1,0,0,0-24-24H56A24.1,24.1,0,0,0,32,72v96H24a8,8,0,0,0-8,8v16a24.1,24.1,0,0,0,24,24H216a24.1,24.1,0,0,0,24-24V176A8,8,0,0,0,232,168ZM112,72h32a8,8,0,0,1,0,16H112a8,8,0,0,1,0-16ZM224,192a8,8,0,0,1-8,8H40a8,8,0,0,1-8-8v-8H224Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M232,170H222V72a22.1,22.1,0,0,0-22-22H56A22.1,22.1,0,0,0,34,72v98H24a6,6,0,0,0-6,6v16a22.1,22.1,0,0,0,22,22H216a22.1,22.1,0,0,0,22-22V176A6,6,0,0,0,232,170ZM46,72A10,10,0,0,1,56,62H200a10,10,0,0,1,10,10v98H46ZM226,192a10,10,0,0,1-10,10H40a10,10,0,0,1-10-10V182H226ZM150,88a6,6,0,0,1-6,6H112a6,6,0,0,1,0-12h32A6,6,0,0,1,150,88Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M232,172H220V72a20.1,20.1,0,0,0-20-20H56A20.1,20.1,0,0,0,36,72V172H24a4,4,0,0,0-4,4v16a20.1,20.1,0,0,0,20,20H216a20.1,20.1,0,0,0,20-20V176A4,4,0,0,0,232,172ZM44,72A12,12,0,0,1,56,60H200a12,12,0,0,1,12,12V172H44ZM228,192a12,12,0,0,1-12,12H40a12,12,0,0,1-12-12V180H228ZM148,88a4,4,0,0,1-4,4H112a4,4,0,0,1,0-8h32A4,4,0,0,1,148,88Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M232,168h-8V72a24.1,24.1,0,0,0-24-24H56A24.1,24.1,0,0,0,32,72v96H24a8,8,0,0,0-8,8v16a24.1,24.1,0,0,0,24,24H216a24.1,24.1,0,0,0,24-24V176A8,8,0,0,0,232,168ZM48,72a8,8,0,0,1,8-8H200a8,8,0,0,1,8,8v96H48ZM224,192a8,8,0,0,1-8,8H40a8,8,0,0,1-8-8v-8H224ZM152,88a8,8,0,0,1-8,8H112a8,8,0,0,1,0-16h32A8,8,0,0,1,152,88Z" />
        </>
      )}
    </svg>
  );
}

export function PhLayout(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216,36H40A20.1,20.1,0,0,0,20,56V200a20.1,20.1,0,0,0,20,20H216a20.1,20.1,0,0,0,20-20V56A20.1,20.1,0,0,0,216,36Zm-4,24V92H44V60ZM44,116H92v80H44Zm72,80V116h96v80Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M104,104V208H40a8,8,0,0,1-8-8V104Z" opacity="0.2" />
          <path d="M216,40H40A16,16,0,0,0,24,56V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40ZM40,56H216V96H40Zm0,56H96v88H40Zm176,88H112V112H216v88Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,40H40A16,16,0,0,0,24,56V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40ZM40,56H216V96H40ZM216,200H112V112H216v88Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M216,42H40A14,14,0,0,0,26,56V200a14,14,0,0,0,14,14H216a14,14,0,0,0,14-14V56A14,14,0,0,0,216,42ZM40,54H216a2,2,0,0,1,2,2V98H38V56A2,2,0,0,1,40,54ZM38,200V110H98v92H40A2,2,0,0,1,38,200Zm178,2H110V110H218v90A2,2,0,0,1,216,202Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M216,44H40A12,12,0,0,0,28,56V200a12,12,0,0,0,12,12H216a12,12,0,0,0,12-12V56A12,12,0,0,0,216,44ZM40,52H216a4,4,0,0,1,4,4v44H36V56A4,4,0,0,1,40,52ZM36,200V108h64v96H40A4,4,0,0,1,36,200Zm180,4H108V108H220v92A4,4,0,0,1,216,204Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,40H40A16,16,0,0,0,24,56V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40ZM40,56H216V96H40Zm0,56H96v88H40Zm176,88H112V112H216v88Z" />
        </>
      )}
    </svg>
  );
}

export function PhLeaf(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M228,39.3A12.1,12.1,0,0,0,216.7,28c-39.7-2.3-74.8,2.4-104.2,13.9s-50,28.2-62.9,49.6c-17.7,29.2-17.7,64.6-.5,98.4L31.5,207.5a12,12,0,0,0,0,17,12.1,12.1,0,0,0,17,0l17.6-17.6c16.8,8.5,34,12.9,50.6,12.9a91.6,91.6,0,0,0,47.8-13.4c21.4-12.9,38.5-34.7,49.6-62.9S230.3,79,228,39.3ZM152.1,185.9c-20.2,12.2-43.9,13.1-68,2.9l84.4-84.3a12,12,0,0,0-17-17L67.2,171.9c-10.2-24.1-9.3-47.8,2.9-68,21-34.6,70.7-53.8,134.4-52.4C206,115.1,186.7,164.9,152.1,185.9Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M64,192C16,112,80,32,216,40,224,176,144,240,64,192Z" />
          </g>
          <path d="M224,39.5a8.1,8.1,0,0,0-7.5-7.5C140.2,27.5,79.1,50.5,53,93.6a87.3,87.3,0,0,0-12.8,49.1c.6,15.9,5.2,32.1,13.8,48L34.3,210.3a8.1,8.1,0,0,0,0,11.4,8.2,8.2,0,0,0,11.4,0L65.3,202c15.9,8.6,32.1,13.2,48,13.8h3.3A87,87,0,0,0,162.4,203C205.5,176.9,228.5,115.8,224,39.5ZM154.2,189.3c-22.9,13.8-49.9,14-77,.8l88.5-88.4a8.1,8.1,0,0,0-11.4-11.4L65.9,178.8c-13.2-27.1-13-54.1.8-77,22.1-36.5,74.8-56.5,141.7-54.2C210.7,114.5,190.7,167.2,154.2,189.3Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M162.4,203a87,87,0,0,1-45.8,12.8h-3.3c-15.9-.6-32.1-5.2-48-13.8L165.7,101.7a8.1,8.1,0,0,0-11.4-11.4L54,190.7c-8.6-15.9-13.2-32.1-13.8-48A87.3,87.3,0,0,1,53,93.6C79.1,50.5,140.2,27.5,216.5,32a8.1,8.1,0,0,1,7.5,7.5C228.5,115.8,205.5,176.9,162.4,203ZM57.1,196.1c-1.1-1.8-2.1-3.6-3.1-5.4L34.3,210.3a8.1,8.1,0,0,0,0,11.4,8.2,8.2,0,0,0,11.4,0L65.3,202l-5.4-3.1A9.3,9.3,0,0,1,57.1,196.1Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M222,39.6a6,6,0,0,0-5.6-5.6c-75.6-4.4-136,18.2-161.7,60.6-17.5,28.8-16.8,62.9,1.8,96.4L35.8,211.8a5.9,5.9,0,1,0,8.4,8.4L65,199.5c17.3,9.6,34.8,14.4,51.6,14.4a85.8,85.8,0,0,0,44.8-12.6C203.8,175.6,226.4,115.2,222,39.6ZM155.2,191c-24.1,14.6-52.8,14.4-81.3-.4l90.3-90.4a5.9,5.9,0,0,0-8.4-8.4L65.4,182.1c-14.8-28.5-15-57.2-.4-81.3,22.7-37.5,76.8-57.9,145.3-55.1C213.1,114.2,192.7,168.3,155.2,191Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M220,39.8a3.9,3.9,0,0,0-3.8-3.8C141.4,31.6,81.7,53.9,56.4,95.6,39.1,124.2,40,158.1,59,191.3L37.2,213.2a3.9,3.9,0,0,0,0,5.6,3.9,3.9,0,0,0,5.6,0L64.7,197c17.4,9.9,35,14.9,51.9,14.9a83.9,83.9,0,0,0,43.8-12.3C202.1,174.3,224.4,114.6,220,39.8ZM156.2,192.7c-25.4,15.4-55.7,14.8-85.7-1.6l92.3-92.3a4,4,0,1,0-5.6-5.6L64.9,185.5c-16.4-30-17-60.3-1.6-85.7,23.3-38.5,78.8-59.3,148.9-56C215.5,113.9,194.7,169.4,156.2,192.7Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M224,39.5a8.1,8.1,0,0,0-7.5-7.5C140.2,27.5,79.1,50.5,53,93.6a87.3,87.3,0,0,0-12.8,49.1c.6,15.9,5.2,32.1,13.8,48L34.3,210.3a8.1,8.1,0,0,0,0,11.4,8.2,8.2,0,0,0,11.4,0L65.3,202c15.9,8.6,32.1,13.2,48,13.8h3.3A87,87,0,0,0,162.4,203C205.5,176.9,228.5,115.8,224,39.5ZM154.2,189.3c-22.9,13.8-49.9,14-77,.8l88.5-88.4a8.1,8.1,0,0,0-11.4-11.4L65.9,178.8c-13.2-27.1-13-54.1.8-77,22.1-36.5,74.8-56.5,141.7-54.2C210.7,114.5,190.7,167.2,154.2,189.3Z" />
        </>
      )}
    </svg>
  );
}

export function PhLifebuoy(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M205.2,203.4a107.7,107.7,0,0,0,0-150.8l-.8-1-1-.8a107.7,107.7,0,0,0-150.8,0l-1,.8-.8,1a107.7,107.7,0,0,0,0,150.8l.8,1,1,.8a107.7,107.7,0,0,0,150.8,0l1-.8Zm-10-25.1-23-23a51.7,51.7,0,0,0,0-54.6l23-23a83.7,83.7,0,0,1,0,100.6ZM100,128a28,28,0,1,1,28,28A28.1,28.1,0,0,1,100,128Zm78.3-67.2-23,23a51.7,51.7,0,0,0-54.6,0l-23-23a83.7,83.7,0,0,1,100.6,0ZM60.8,77.7l23,23a51.7,51.7,0,0,0,0,54.6l-23,23a83.7,83.7,0,0,1,0-100.6ZM77.7,195.2l23-23a51.7,51.7,0,0,0,54.6,0l23,23a83.7,83.7,0,0,1-100.6,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M195.9,195.9l-39.6-39.6a40.1,40.1,0,0,0,0-56.6l39.6-39.6A96,96,0,0,1,195.9,195.9ZM60.1,60.1a96,96,0,0,0,0,135.8l39.6-39.6a40.1,40.1,0,0,1,0-56.6Z"
            opacity="0.2"
          />
          <path d="M202.2,200.8a103.8,103.8,0,0,0,0-145.6,2.3,2.3,0,0,0-.7-.7,2.3,2.3,0,0,0-.7-.7,103.8,103.8,0,0,0-145.6,0,2.3,2.3,0,0,0-.7.7,2.3,2.3,0,0,0-.7.7,103.8,103.8,0,0,0,0,145.6l.7.7a2.3,2.3,0,0,0,.7.7,103.8,103.8,0,0,0,145.6,0,2.3,2.3,0,0,0,.7-.7A2.3,2.3,0,0,0,202.2,200.8Zm-6.6-16.5-28.5-28.5a47.9,47.9,0,0,0,0-55.6l28.5-28.5a87.9,87.9,0,0,1,0,112.6ZM96,128a32,32,0,1,1,32,32A32.1,32.1,0,0,1,96,128Zm88.3-67.6L155.8,88.9a47.9,47.9,0,0,0-55.6,0L71.7,60.4a87.9,87.9,0,0,1,112.6,0ZM60.4,71.7l28.5,28.5a47.9,47.9,0,0,0,0,55.6L60.4,184.3a87.9,87.9,0,0,1,0-112.6ZM71.7,195.6l28.5-28.5a47.9,47.9,0,0,0,55.6,0l28.5,28.5a87.9,87.9,0,0,1-112.6,0Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M202.2,55.2a2.3,2.3,0,0,0-.7-.7,2.3,2.3,0,0,0-.7-.7,103.8,103.8,0,0,0-145.6,0,2.3,2.3,0,0,0-.7.7,2.3,2.3,0,0,0-.7.7,103.8,103.8,0,0,0,0,145.6l.7.7a2.3,2.3,0,0,0,.7.7,103.8,103.8,0,0,0,145.6,0,2.3,2.3,0,0,0,.7-.7,2.3,2.3,0,0,0,.7-.7,103.8,103.8,0,0,0,0-145.6ZM128,216a87.9,87.9,0,0,1-56.3-20.4l28.5-28.5a47.9,47.9,0,0,0,55.6,0l28.5,28.5A87.9,87.9,0,0,1,128,216ZM96,128a32,32,0,1,1,32,32A32.1,32.1,0,0,1,96,128Zm59.8-39.1a47.9,47.9,0,0,0-55.6,0L71.7,60.4a87.9,87.9,0,0,1,112.6,0Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M200.5,199.7a101.9,101.9,0,0,0,0-143.4c-.2-.2-.2-.3-.4-.4s-.2-.2-.4-.4a101.9,101.9,0,0,0-143.4,0l-.4.4-.4.4a101.9,101.9,0,0,0,0,143.4l.4.4c.1.2.2.2.4.4a101.9,101.9,0,0,0,143.4,0c.2-.2.3-.2.4-.4S200.3,199.9,200.5,199.7Zm-4.8-12.5L164.5,156a46,46,0,0,0,0-56l31.2-31.2a89.7,89.7,0,0,1,0,118.4ZM94,128a34,34,0,1,1,34,34A34.1,34.1,0,0,1,94,128Zm93.2-67.7L156,91.5a46,46,0,0,0-56,0L68.8,60.3a89.7,89.7,0,0,1,118.4,0ZM60.3,68.8,91.5,100a46,46,0,0,0,0,56L60.3,187.2a89.7,89.7,0,0,1,0-118.4Zm8.5,126.9L100,164.5a46,46,0,0,0,56,0l31.2,31.2a89.7,89.7,0,0,1-118.4,0Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M199,198.3a99.7,99.7,0,0,0,0-140.6l-.3-.4-.4-.3A99.7,99.7,0,0,0,57.7,57l-.4.3-.3.4a99.7,99.7,0,0,0,0,140.6l.3.4.4.3a99.7,99.7,0,0,0,140.6,0l.4-.3Zm-3.2-8.2-34-34a43.8,43.8,0,0,0,0-56.2l34-34a91.8,91.8,0,0,1,0,124.2ZM92,128a36,36,0,1,1,36,36A36,36,0,0,1,92,128Zm98.1-67.8-34,34a43.8,43.8,0,0,0-56.2,0l-34-34a91.8,91.8,0,0,1,124.2,0ZM60.2,65.9l34,34a43.8,43.8,0,0,0,0,56.2l-34,34a91.8,91.8,0,0,1,0-124.2Zm5.7,129.9,34-34a43.8,43.8,0,0,0,56.2,0l34,34a91.8,91.8,0,0,1-124.2,0Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M202.2,200.8a103.8,103.8,0,0,0,0-145.6,2.3,2.3,0,0,0-.7-.7,2.3,2.3,0,0,0-.7-.7,103.8,103.8,0,0,0-145.6,0,2.3,2.3,0,0,0-.7.7,2.3,2.3,0,0,0-.7.7,103.8,103.8,0,0,0,0,145.6l.7.7a2.3,2.3,0,0,0,.7.7,103.8,103.8,0,0,0,145.6,0,2.3,2.3,0,0,0,.7-.7A2.3,2.3,0,0,0,202.2,200.8Zm-6.6-16.5-28.5-28.5a47.9,47.9,0,0,0,0-55.6l28.5-28.5a87.9,87.9,0,0,1,0,112.6ZM96,128a32,32,0,1,1,32,32A32.1,32.1,0,0,1,96,128Zm88.3-67.6L155.8,88.9a47.9,47.9,0,0,0-55.6,0L71.7,60.4a87.9,87.9,0,0,1,112.6,0ZM60.4,71.7l28.5,28.5a47.9,47.9,0,0,0,0,55.6L60.4,184.3a87.9,87.9,0,0,1,0-112.6ZM71.7,195.6l28.5-28.5a47.9,47.9,0,0,0,55.6,0l28.5,28.5a87.9,87.9,0,0,1-112.6,0Z" />
        </>
      )}
    </svg>
  );
}

export function PhLightbulbFilament(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M180,232a12,12,0,0,1-12,12H88a12,12,0,0,1,0-24h80A12,12,0,0,1,180,232Zm40-128a92.3,92.3,0,0,1-37,73.7,8,8,0,0,0-3,6.3,20.1,20.1,0,0,1-20,20H96a20.1,20.1,0,0,1-20-20v-.2a7.8,7.8,0,0,0-3.2-6.2A91.6,91.6,0,0,1,36,104.5C35.7,54.7,76,13.2,125.8,12A92,92,0,0,1,220,104Zm-24,0a68,68,0,0,0-69.6-68c-36.8.9-66.6,31.6-66.4,68.4a67.4,67.4,0,0,0,27.2,54h0A32.3,32.3,0,0,1,99.8,180H116V149L87.5,120.5a12,12,0,0,1,17-17L128,127l23.5-23.5a12,12,0,0,1,17,17L140,149v31h16.3a31.4,31.4,0,0,1,12.4-21.5A67.6,67.6,0,0,0,196,104Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M208,104a79.9,79.9,0,0,1-30.6,62.9A24.2,24.2,0,0,0,168,186v6a8,8,0,0,1-8,8H96a8,8,0,0,1-8-8v-6a24.4,24.4,0,0,0-9.3-19A79.5,79.5,0,0,1,48,104.5C47.8,61.1,82.7,25,126.1,24A80.1,80.1,0,0,1,208,104Z"
            opacity="0.2"
          />
          <path d="M176,232a8,8,0,0,1-8,8H88a8,8,0,0,1,0-16h80A8,8,0,0,1,176,232Zm40-128a87.7,87.7,0,0,1-33.6,69.2A16.1,16.1,0,0,0,176,186v6a16,16,0,0,1-16,16H96a16,16,0,0,1-16-16v-6a16.2,16.2,0,0,0-6.2-12.7A87.8,87.8,0,0,1,40,104.5C39.7,56.8,78.3,17.1,125.9,16A87.9,87.9,0,0,1,216,104Zm-16,0a72.1,72.1,0,0,0-73.7-72c-39,.9-70.5,33.4-70.3,72.4a71.7,71.7,0,0,0,27.6,56.3h0A32,32,0,0,1,96,186v6h24V147.3L90.3,117.7a8.1,8.1,0,0,1,11.4-11.4L128,132.7l26.3-26.4a8.1,8.1,0,0,1,11.4,11.4L136,147.3V192h24v-6a32.1,32.1,0,0,1,12.5-25.4A71.5,71.5,0,0,0,200,104Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M176,232a8,8,0,0,1-8,8H88a8,8,0,0,1,0-16h80A8,8,0,0,1,176,232Zm40-128a87.7,87.7,0,0,1-33.6,69.2A16.1,16.1,0,0,0,176,186v6a16,16,0,0,1-16,16H96a16,16,0,0,1-16-16v-6a16.2,16.2,0,0,0-6.2-12.7A87.8,87.8,0,0,1,40,104.5C39.7,56.8,78.3,17.1,125.9,16A87.9,87.9,0,0,1,216,104Zm-50.3,2.3a8.1,8.1,0,0,0-11.4,0L128,132.7l-26.3-26.4a8.1,8.1,0,0,0-11.4,11.4L120,147.3V184a8,8,0,0,0,16,0V147.3l29.7-29.6A8.1,8.1,0,0,0,165.7,106.3Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M174,232a6,6,0,0,1-6,6H88a6,6,0,0,1,0-12h80A6,6,0,0,1,174,232Zm40-128a85.4,85.4,0,0,1-32.9,67.6A18.4,18.4,0,0,0,174,186v6a14,14,0,0,1-14,14H96a14,14,0,0,1-14-14v-6a18.2,18.2,0,0,0-7-14.3h0a85.4,85.4,0,0,1-33-67.2C41.7,57.9,79.4,19.1,125.9,18A86.1,86.1,0,0,1,214,104Zm-12,0a74.1,74.1,0,0,0-75.8-74c-40,1-72.4,34.3-72.2,74.4a73.7,73.7,0,0,0,28.4,57.9h0A29.9,29.9,0,0,1,94,186v6a2,2,0,0,0,2,2h26V146.5L91.8,116.2a5.9,5.9,0,0,1,8.4-8.4L128,135.5l27.8-27.7a5.9,5.9,0,0,1,8.4,8.4L134,146.5V194h26a2,2,0,0,0,2-2v-6a30.3,30.3,0,0,1,11.7-23.8A73.6,73.6,0,0,0,202,104Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M172,232a4,4,0,0,1-4,4H88a4,4,0,0,1,0-8h80A4,4,0,0,1,172,232Zm40-128a83.8,83.8,0,0,1-32.1,66.1A20.1,20.1,0,0,0,172,186v6a12,12,0,0,1-12,12H96a12,12,0,0,1-12-12v-6a19.7,19.7,0,0,0-7.8-15.8A83.7,83.7,0,0,1,44,104.5C43.8,59,80.5,21.1,126,20a84,84,0,0,1,86,84Zm-8,0a76,76,0,0,0-77.8-76C85,29,51.8,63.3,52,104.4a75.8,75.8,0,0,0,29.2,59.5A27.8,27.8,0,0,1,92,186v6a4,4,0,0,0,4,4h28V145.7L93.2,114.8a4,4,0,0,1,5.6-5.6L128,138.3l29.2-29.1a4,4,0,1,1,5.6,5.6L132,145.7V196h28a4,4,0,0,0,4-4v-6a28.1,28.1,0,0,1,10.9-22.2A75.5,75.5,0,0,0,204,104Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M176,232a8,8,0,0,1-8,8H88a8,8,0,0,1,0-16h80A8,8,0,0,1,176,232Zm40-128a87.7,87.7,0,0,1-33.6,69.2A16.1,16.1,0,0,0,176,186v6a16,16,0,0,1-16,16H96a16,16,0,0,1-16-16v-6a16.2,16.2,0,0,0-6.2-12.7A87.8,87.8,0,0,1,40,104.5C39.7,56.8,78.3,17.1,125.9,16A87.9,87.9,0,0,1,216,104Zm-16,0a72.1,72.1,0,0,0-73.7-72c-39,.9-70.5,33.4-70.3,72.4a71.7,71.7,0,0,0,27.6,56.3h0A32,32,0,0,1,96,186v6h24V147.3L90.3,117.7a8.1,8.1,0,0,1,11.4-11.4L128,132.7l26.3-26.4a8.1,8.1,0,0,1,11.4,11.4L136,147.3V192h24v-6a32.1,32.1,0,0,1,12.5-25.4A71.5,71.5,0,0,0,200,104Z" />
        </>
      )}
    </svg>
  );
}

export function PhLightbulb(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M180,232a12,12,0,0,1-12,12H88a12,12,0,0,1,0-24h80A12,12,0,0,1,180,232Zm40-128a92.3,92.3,0,0,1-37,73.7,8,8,0,0,0-3,6.3,20.1,20.1,0,0,1-20,20H96a20.1,20.1,0,0,1-20-20v-.2a7.8,7.8,0,0,0-3.2-6.2A91.6,91.6,0,0,1,36,104.5C35.7,54.7,76,13.2,125.8,12A92,92,0,0,1,220,104Zm-24,0a68,68,0,0,0-69.6-68c-36.8.9-66.6,31.6-66.4,68.4a67.4,67.4,0,0,0,27.2,54h0A32.3,32.3,0,0,1,99.8,180h56.5a31.4,31.4,0,0,1,12.4-21.5A67.6,67.6,0,0,0,196,104ZM138.7,57.2a12,12,0,0,0-5.4,23.4,23.9,23.9,0,0,1,18.1,18.1,12.1,12.1,0,0,0,11.7,9.3,12.4,12.4,0,0,0,2.7-.3,12,12,0,0,0,9-14.4A48.2,48.2,0,0,0,138.7,57.2Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M208,104a79.9,79.9,0,0,1-30.6,62.9A24.2,24.2,0,0,0,168,186v6a8,8,0,0,1-8,8H96a8,8,0,0,1-8-8v-6a24.4,24.4,0,0,0-9.3-19A79.5,79.5,0,0,1,48,104.5C47.8,61.1,82.7,25,126.1,24A80.1,80.1,0,0,1,208,104Z"
            opacity="0.2"
          />
          <path d="M176,232a8,8,0,0,1-8,8H88a8,8,0,0,1,0-16h80A8,8,0,0,1,176,232Zm40-128a87.7,87.7,0,0,1-33.6,69.2A16.1,16.1,0,0,0,176,186v6a16,16,0,0,1-16,16H96a16,16,0,0,1-16-16v-6a16.2,16.2,0,0,0-6.2-12.7A87.8,87.8,0,0,1,40,104.5C39.7,56.8,78.3,17.1,125.9,16A87.9,87.9,0,0,1,216,104Zm-16,0a72.1,72.1,0,0,0-73.7-72c-39,.9-70.5,33.4-70.3,72.4a71.7,71.7,0,0,0,27.6,56.3A32,32,0,0,1,96,186v6h64v-6a32.1,32.1,0,0,1,12.5-25.4A71.5,71.5,0,0,0,200,104Zm-16.8-9.4a55.8,55.8,0,0,0-45.8-45.7,8,8,0,1,0-2.6,15.8,39.7,39.7,0,0,1,32.6,32.6,8,8,0,0,0,7.9,6.6h1.4A7.9,7.9,0,0,0,183.2,94.6Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M176,232a8,8,0,0,1-8,8H88a8,8,0,0,1,0-16h80A8,8,0,0,1,176,232Zm40-128a87.7,87.7,0,0,1-33.6,69.2A16.1,16.1,0,0,0,176,186v6a16,16,0,0,1-16,16H96a16,16,0,0,1-16-16v-6a16.2,16.2,0,0,0-6.2-12.7A87.8,87.8,0,0,1,40,104.5C39.7,56.8,78.3,17.1,125.9,16A87.9,87.9,0,0,1,216,104Zm-32.8-9.4a55.8,55.8,0,0,0-45.8-45.7,8,8,0,1,0-2.6,15.8,39.7,39.7,0,0,1,32.6,32.6,8,8,0,0,0,7.9,6.6h1.4A7.9,7.9,0,0,0,183.2,94.6Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M174,232a6,6,0,0,1-6,6H88a6,6,0,0,1,0-12h80A6,6,0,0,1,174,232Zm40-128a85.4,85.4,0,0,1-32.9,67.6A18.4,18.4,0,0,0,174,186v6a14,14,0,0,1-14,14H96a14,14,0,0,1-14-14v-6a17.9,17.9,0,0,0-7-14.3h0a85.4,85.4,0,0,1-33-67.2C41.7,57.9,79.4,19.1,125.9,18A86.1,86.1,0,0,1,214,104Zm-12,0a74.1,74.1,0,0,0-75.8-74c-40,1-72.4,34.3-72.2,74.4a73.7,73.7,0,0,0,28.4,57.9h0A29.9,29.9,0,0,1,94,186v6a2,2,0,0,0,2,2h64a2,2,0,0,0,2-2v-6a30.3,30.3,0,0,1,11.7-23.8A73.6,73.6,0,0,0,202,104Zm-20.8-9.1a53.8,53.8,0,0,0-44.1-44,5.9,5.9,0,0,0-6.9,4.9,6,6,0,0,0,4.9,6.9,41.9,41.9,0,0,1,34.3,34.2,5.9,5.9,0,0,0,5.9,5h1A6,6,0,0,0,181.2,94.9Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M172,232a4,4,0,0,1-4,4H88a4,4,0,0,1,0-8h80A4,4,0,0,1,172,232Zm40-128a83.8,83.8,0,0,1-32.1,66.1A20.1,20.1,0,0,0,172,186v6a12,12,0,0,1-12,12H96a12,12,0,0,1-12-12v-6a19.7,19.7,0,0,0-7.8-15.8A83.7,83.7,0,0,1,44,104.5C43.8,59,80.5,21.1,126,20a84,84,0,0,1,86,84Zm-8,0a76,76,0,0,0-77.8-76C85,29,51.8,63.3,52,104.4a75.8,75.8,0,0,0,29.2,59.5A27.8,27.8,0,0,1,92,186v6a4,4,0,0,0,4,4h64a4,4,0,0,0,4-4v-6a28.1,28.1,0,0,1,10.9-22.2A75.5,75.5,0,0,0,204,104ZM136.8,52.8a4,4,0,0,0-4.6,3.3,3.9,3.9,0,0,0,3.3,4.6,43.9,43.9,0,0,1,35.9,35.9,3.9,3.9,0,0,0,3.9,3.3h.7a4,4,0,0,0,3.3-4.6A52,52,0,0,0,136.8,52.8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M176,232a8,8,0,0,1-8,8H88a8,8,0,0,1,0-16h80A8,8,0,0,1,176,232Zm40-128a87.7,87.7,0,0,1-33.6,69.2A16.1,16.1,0,0,0,176,186v6a16,16,0,0,1-16,16H96a16,16,0,0,1-16-16v-6a16.2,16.2,0,0,0-6.2-12.7A87.8,87.8,0,0,1,40,104.5C39.7,56.8,78.3,17.1,125.9,16A87.9,87.9,0,0,1,216,104Zm-16,0a72.1,72.1,0,0,0-73.7-72c-39,.9-70.5,33.4-70.3,72.4a71.7,71.7,0,0,0,27.6,56.3A32,32,0,0,1,96,186v6h64v-6a32.1,32.1,0,0,1,12.5-25.4A71.5,71.5,0,0,0,200,104Zm-16.8-9.4a55.8,55.8,0,0,0-45.8-45.7,8,8,0,1,0-2.6,15.8,39.7,39.7,0,0,1,32.6,32.6,8,8,0,0,0,7.9,6.6h1.4A7.9,7.9,0,0,0,183.2,94.6Z" />
        </>
      )}
    </svg>
  );
}

export function PhLightningSlash(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M56.9,31.9A12,12,0,0,0,39.1,48.1L75.9,88.5,39.2,127.8a12.1,12.1,0,0,0-2.9,10.9,11.9,11.9,0,0,0,7.5,8.5l54.4,20.4-14,70a12.1,12.1,0,0,0,6.3,13.1A13.4,13.4,0,0,0,96,252a12.2,12.2,0,0,0,8.8-3.8l58.9-63.1,35.4,39a12.1,12.1,0,0,0,17,.8,12.1,12.1,0,0,0,.8-17Zm59.2,169,7.7-38.6a12.2,12.2,0,0,0-7.6-13.6L69,131l23.1-24.7,55.4,61ZM111.4,68.1a12,12,0,0,1-.6-17L151.2,7.8a12.2,12.2,0,0,1,14.3-2.5,12.1,12.1,0,0,1,6.3,13l-14,70,54.4,20.4a11.9,11.9,0,0,1,7.5,8.5,12.3,12.3,0,0,1-2.9,11l-16.9,18a11.8,11.8,0,0,1-8.7,3.8,12,12,0,0,1-8.8-20.2l4.6-4.9-47.2-17.7a12.1,12.1,0,0,1-7.6-13.6L139.9,55,128.4,67.5A12.1,12.1,0,0,1,111.4,68.1Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <polygon points="96 240 112 160 48 136 160 16 144 96 208 120 96 240" />
          </g>
          <path d="M53.9,34.6a8,8,0,0,0-11.3-.5,7.9,7.9,0,0,0-.5,11.3L81.3,88.6,42.2,130.5a7.9,7.9,0,0,0-2,7.3,8.2,8.2,0,0,0,5,5.7l57.6,21.6L88.2,238.4a8.1,8.1,0,0,0,4.1,8.7,8.4,8.4,0,0,0,3.7.9,7.7,7.7,0,0,0,5.8-2.6l61.9-66.2,38.4,42.2A8,8,0,0,0,208,224a8.2,8.2,0,0,0,5.4-2.1,7.9,7.9,0,0,0,.5-11.3Zm55.5,179.3,10.4-52.4a7.9,7.9,0,0,0-5-9L62,132.7l30.1-32.3,60.8,66.9ZM108.6,71a7.9,7.9,0,0,1-.3-11.3l45.9-49.2a8,8,0,0,1,13.6,7L153.2,90.9l57.6,21.6a7.9,7.9,0,0,1,3,12.9l-22.3,23.9a7.9,7.9,0,0,1-11.3.4,8,8,0,0,1-.4-11.3L194,123.3l-52.8-19.8a7.9,7.9,0,0,1-5-9.1L146.6,42,120,70.6A8.2,8.2,0,0,1,108.6,71Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M213.9,210.6a7.9,7.9,0,0,1-.5,11.3A8.2,8.2,0,0,1,208,224a8,8,0,0,1-5.9-2.6l-38.4-42.2-61.9,66.2A7.7,7.7,0,0,1,96,248a8.4,8.4,0,0,1-3.7-.9,8.1,8.1,0,0,1-4.1-8.7l14.6-73.3L45.2,143.5a8.2,8.2,0,0,1-5-5.7,7.9,7.9,0,0,1,2-7.3L81.3,88.6,42.1,45.4a7.9,7.9,0,0,1,.5-11.3,8,8,0,0,1,11.3.5Zm-34.1-61.3a8,8,0,0,0,5.8,2.6h.1a7.7,7.7,0,0,0,5.8-2.6l22.3-23.9a7.9,7.9,0,0,0-3-12.9L153.2,90.9l14.6-73.4a8,8,0,0,0-13.6-7L108.3,59.7a7.9,7.9,0,0,0-.1,10.8Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M52.4,36a5.9,5.9,0,0,0-8.8,8L84,88.6,43.6,131.9a5.6,5.6,0,0,0-1.4,5.4,5.8,5.8,0,0,0,3.7,4.3l59.2,22.2-15,75a6,6,0,0,0,3.2,6.5,5.2,5.2,0,0,0,2.7.7,6.3,6.3,0,0,0,4.4-1.9l63.3-67.9L203.6,220a5.9,5.9,0,0,0,4.4,2,5.7,5.7,0,0,0,4-1.6,5.8,5.8,0,0,0,.4-8.4ZM106,220.4l11.9-59.2a5.9,5.9,0,0,0-3.8-6.8L58.5,133.5l33.6-36,63.5,69.8Zm4-150.9a6,6,0,0,1-.3-8.4l45.9-49.2a6,6,0,0,1,7.1-1.3,6.2,6.2,0,0,1,3.2,6.6l-15,74.9,59.2,22.3a6,6,0,0,1,2.3,9.7L190.1,148a6.3,6.3,0,0,1-4.4,1.9,5.7,5.7,0,0,1-4.1-1.6,6,6,0,0,1-.3-8.5l16.2-17.4-55.6-20.8a5.9,5.9,0,0,1-3.8-6.8L150,35.5,118.5,69.2A5.9,5.9,0,0,1,110,69.5Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M51,37.3a4,4,0,1,0-6,5.4L86.8,88.6,45.1,133.2a4.2,4.2,0,0,0-1,3.7,3.7,3.7,0,0,0,2.5,2.8l60.8,22.8L92.1,239.2a3.9,3.9,0,0,0,2.1,4.3,3.7,3.7,0,0,0,4.7-.8l64.8-69.4L205,218.7a4.1,4.1,0,0,0,5.7.3,4.2,4.2,0,0,0,.3-5.7ZM102.7,227l13.2-66.2a4.1,4.1,0,0,0-2.5-4.6L55,134.3,92.2,94.5l66.1,72.8Zm8.7-158.9a4.1,4.1,0,0,1-.2-5.7l45.9-49.2a4,4,0,0,1,6.8,3.6L148.6,93.4l60.8,22.8a4.1,4.1,0,0,1,2.5,2.9,4,4,0,0,1-1,3.6l-22.3,23.9a3.7,3.7,0,0,1-2.9,1.3,4,4,0,0,1-2.9-6.7L201,121.6,142.6,99.7a4,4,0,0,1-2.5-4.5L153.3,29,117,67.9A4,4,0,0,1,111.4,68.1Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M53.9,34.6a8,8,0,0,0-11.3-.5,7.9,7.9,0,0,0-.5,11.3L81.3,88.6,42.2,130.5a7.9,7.9,0,0,0-2,7.3,8.2,8.2,0,0,0,5,5.7l57.6,21.6L88.2,238.4a8.1,8.1,0,0,0,4.1,8.7,8.4,8.4,0,0,0,3.7.9,7.7,7.7,0,0,0,5.8-2.6l61.9-66.2,38.4,42.2A8,8,0,0,0,208,224a8.2,8.2,0,0,0,5.4-2.1,7.9,7.9,0,0,0,.5-11.3Zm55.5,179.3,10.4-52.4a7.9,7.9,0,0,0-5-9L62,132.7l30.1-32.3,60.8,66.9ZM108.6,71a7.9,7.9,0,0,1-.3-11.3l45.9-49.2a8,8,0,0,1,13.6,7L153.2,90.9l57.6,21.6a7.9,7.9,0,0,1,3,12.9l-22.3,23.9a7.9,7.9,0,0,1-11.3.4,8,8,0,0,1-.4-11.3L194,123.3l-52.8-19.8a7.9,7.9,0,0,1-5-9.1L146.6,42,120,70.6A8.2,8.2,0,0,1,108.6,71Z" />
        </>
      )}
    </svg>
  );
}

export function PhLightning(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M96,252a13.4,13.4,0,0,1-5.5-1.3,12.1,12.1,0,0,1-6.3-13.1l14-70L43.8,147.2a11.9,11.9,0,0,1-7.5-8.5,12.1,12.1,0,0,1,2.9-10.9l112-120a12.2,12.2,0,0,1,14.3-2.5,12.1,12.1,0,0,1,6.3,13l-14,70,54.4,20.4a11.9,11.9,0,0,1,7.5,8.5,12.3,12.3,0,0,1-2.9,11l-112,120A12.2,12.2,0,0,1,96,252ZM69,131l47.2,17.7a12.2,12.2,0,0,1,7.6,13.6l-7.7,38.6,70.9-76-47.2-17.7a12.1,12.1,0,0,1-7.6-13.6L139.9,55Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <polygon points="96 240 112 160 48 136 160 16 144 96 208 120 96 240" />
          </g>
          <path d="M96,248a8.4,8.4,0,0,1-3.7-.9,8.1,8.1,0,0,1-4.1-8.7l14.6-73.3L45.2,143.5a8.2,8.2,0,0,1-5-5.7,7.9,7.9,0,0,1,2-7.3l112-120a8,8,0,0,1,13.6,7L153.2,90.9l57.6,21.6a7.9,7.9,0,0,1,3,12.9l-112,120A7.7,7.7,0,0,1,96,248ZM62,132.7l52.8,19.8a7.9,7.9,0,0,1,5,9l-10.4,52.4L194,123.3l-52.8-19.8a7.9,7.9,0,0,1-5-9.1L146.6,42Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M213.8,125.4l-112,120A7.7,7.7,0,0,1,96,248a8.4,8.4,0,0,1-3.7-.9,8.1,8.1,0,0,1-4.1-8.7l14.6-73.3L45.2,143.5a8.2,8.2,0,0,1-5-5.7,7.9,7.9,0,0,1,2-7.3l112-120a8,8,0,0,1,13.6,7L153.2,90.9l57.6,21.6a7.9,7.9,0,0,1,3,12.9Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M96,246a5.2,5.2,0,0,1-2.7-.7,6,6,0,0,1-3.2-6.5l15-75L45.9,141.6a5.8,5.8,0,0,1-3.7-4.3,5.6,5.6,0,0,1,1.4-5.4l112-120a6,6,0,0,1,7.1-1.3,6.2,6.2,0,0,1,3.2,6.6l-15,74.9,59.2,22.3a6,6,0,0,1,2.3,9.7l-112,120A6.3,6.3,0,0,1,96,246ZM58.5,133.5l55.6,20.9a5.9,5.9,0,0,1,3.8,6.8L106,220.4l91.5-98-55.6-20.8a5.9,5.9,0,0,1-3.8-6.8L150,35.5Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M96,244a4.1,4.1,0,0,1-1.8-.5,3.9,3.9,0,0,1-2.1-4.3l15.3-76.7L46.6,139.7a3.7,3.7,0,0,1-2.5-2.8,4.2,4.2,0,0,1,1-3.7l112-120a4,4,0,0,1,6.8,3.6L148.6,93.4l60.8,22.8a4.1,4.1,0,0,1,2.5,2.9,4,4,0,0,1-1,3.6l-112,120A4.1,4.1,0,0,1,96,244ZM55,134.3l58.4,21.9a4.1,4.1,0,0,1,2.5,4.6L102.7,227,201,121.6,142.6,99.7a4,4,0,0,1-2.5-4.5L153.3,29Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M96,248a8.4,8.4,0,0,1-3.7-.9,8.1,8.1,0,0,1-4.1-8.7l14.6-73.3L45.2,143.5a8.2,8.2,0,0,1-5-5.7,7.9,7.9,0,0,1,2-7.3l112-120a8,8,0,0,1,13.6,7L153.2,90.9l57.6,21.6a7.9,7.9,0,0,1,3,12.9l-112,120A7.7,7.7,0,0,1,96,248ZM62,132.7l52.8,19.8a7.9,7.9,0,0,1,5,9l-10.4,52.4L194,123.3l-52.8-19.8a7.9,7.9,0,0,1-5-9.1L146.6,42Z" />
        </>
      )}
    </svg>
  );
}

export function PhLineSegment(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M217.5,38.5a36,36,0,0,0-58,41l-80,80a36.2,36.2,0,0,0-41,7h0a36.1,36.1,0,1,0,58,10l80-80a36,36,0,0,0,41-58Zm-145,162a12,12,0,0,1-17-17h0a12,12,0,0,1,17,17Zm128-128a12,12,0,0,1-17,0h0a12,12,0,1,1,17,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M81,175a24,24,0,1,1-34,0A24.2,24.2,0,0,1,81,175ZM209,47a24,24,0,1,0,0,34A24.2,24.2,0,0,0,209,47Z"
            opacity="0.2"
          />
          <path d="M214.6,41.4a32,32,0,0,0-50.2,38.9L80.3,164.4a32,32,0,0,0-38.9,5h0a31.9,31.9,0,0,0,0,45.2,31.9,31.9,0,0,0,45.2,0,32,32,0,0,0,5-38.9l84.1-84.1A32.7,32.7,0,0,0,192,96a31.6,31.6,0,0,0,22.6-9.4A31.9,31.9,0,0,0,214.6,41.4ZM75.3,203.3a16,16,0,0,1-22.6-22.6h0a15.9,15.9,0,0,1,22.6,0h0A15.9,15.9,0,0,1,75.3,203.3Zm128-128a15.9,15.9,0,0,1-22.6,0h0a16,16,0,1,1,22.6,0Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M214.6,86.6A31.6,31.6,0,0,1,192,96a32.7,32.7,0,0,1-16.3-4.4L91.6,175.7a32,32,0,0,1-5,38.9,31.9,31.9,0,0,1-45.2,0,31.9,31.9,0,0,1,0-45.2h0a32,32,0,0,1,38.9-5l84.1-84.1a32,32,0,1,1,50.2,6.3Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M213.2,42.8A30,30,0,0,0,167,80.5L80.5,167a29.9,29.9,0,0,0-37.7,3.8h0a30,30,0,0,0,0,42.4,29.9,29.9,0,0,0,42.4,0A29.9,29.9,0,0,0,89,175.5L175.5,89a30,30,0,0,0,37.7-46.2ZM76.7,204.7a18,18,0,0,1-25.4-25.4h0a18,18,0,1,1,25.4,25.4Zm128-128a18,18,0,0,1-25.4,0h0a18,18,0,1,1,25.4,0Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M211.8,44.2a28,28,0,0,0-42.2,36.6L80.8,169.6a28,28,0,0,0-36.6,2.6h0a28.1,28.1,0,0,0,0,39.6,28,28,0,0,0,42.2-36.6l88.8-88.8A28.3,28.3,0,0,0,192,92a27.8,27.8,0,0,0,19.8-8.2A28.1,28.1,0,0,0,211.8,44.2ZM78.1,206.1a19.9,19.9,0,0,1-28.2-28.2h0a19.9,19.9,0,1,1,28.2,28.2Zm128-128a19.9,19.9,0,0,1-28.2,0h0a19.9,19.9,0,1,1,28.2,0Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M214.6,41.4a32,32,0,0,0-50.2,38.9L80.3,164.4a32,32,0,0,0-38.9,5h0a31.9,31.9,0,0,0,0,45.2,31.9,31.9,0,0,0,45.2,0,32,32,0,0,0,5-38.9l84.1-84.1A32.7,32.7,0,0,0,192,96a31.6,31.6,0,0,0,22.6-9.4A31.9,31.9,0,0,0,214.6,41.4ZM75.3,203.3a16,16,0,0,1-22.6-22.6h0a15.9,15.9,0,0,1,22.6,0h0A15.9,15.9,0,0,1,75.3,203.3Zm128-128a15.9,15.9,0,0,1-22.6,0h0a16,16,0,1,1,22.6,0Z" />
        </>
      )}
    </svg>
  );
}

export function PhLineSegments(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M241.5,30.5a36.2,36.2,0,0,0-51,0h0a36.2,36.2,0,0,0-1.2,49.7l-23.8,44.2a37.2,37.2,0,0,0-21,3.1l-16-16a36,36,0,0,0-58-41h0a36.2,36.2,0,0,0-1.2,49.7L45.5,164.4a36.5,36.5,0,0,0-31,10.1h0a36.1,36.1,0,1,0,52.2,1.3l23.8-44.2a50.9,50.9,0,0,0,5.5.4,36.5,36.5,0,0,0,15.5-3.5l16,16a36,36,0,1,0,59.2-8.7l23.8-44.2a50.9,50.9,0,0,0,5.5.4,36,36,0,0,0,25.5-61.5Zm-154,57a12,12,0,0,1,17,17h0a12,12,0,0,1-17-17Zm-39,121a12,12,0,0,1-17-17h0a12,12,0,0,1,17,17Zm120-40a12,12,0,0,1-17-17h0a12,12,0,0,1,17,17Zm56-104a12,12,0,0,1-17-17h0a12,12,0,0,1,17,17Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M57,183a24,24,0,0,1-34,34,24,24,0,0,1,34-34ZM79,79a24,24,0,1,0,34,0A24.2,24.2,0,0,0,79,79Zm64,64a24,24,0,1,0,34,0A24.2,24.2,0,0,0,143,143ZM233,39a24,24,0,1,0,0,34A24.2,24.2,0,0,0,233,39Z"
            opacity="0.2"
          />
          <path d="M238.6,33.4a31.9,31.9,0,0,0-45.2,0h0a31.9,31.9,0,0,0,0,45.2l.9.9-26.7,49.4a32.4,32.4,0,0,0-23.9,3.5l-20.1-20.1A32,32,0,0,0,73.4,73.4h0a31.9,31.9,0,0,0,0,45.2l.9.9L47.6,168.9a32.1,32.1,0,0,0-30.2,8.5h0a31.9,31.9,0,0,0,0,45.2,31.9,31.9,0,0,0,45.2,0,31.9,31.9,0,0,0,0-45.2l-.9-.9,26.7-49.4a32.2,32.2,0,0,0,7.6.9,32.7,32.7,0,0,0,16.3-4.4l20.1,20.1a32,32,0,0,0,5,38.9,31.9,31.9,0,0,0,45.2,0,31.9,31.9,0,0,0,0-45.2l-.9-.9,26.7-49.4a32.2,32.2,0,0,0,7.6.9,31.6,31.6,0,0,0,22.6-9.4A31.9,31.9,0,0,0,238.6,33.4ZM51.3,211.3a16,16,0,0,1-22.6-22.6h0a16,16,0,0,1,22.6,22.6Zm33.4-104a15.9,15.9,0,0,1,0-22.6h0a16,16,0,1,1,0,22.6Zm86.6,64a16,16,0,0,1-22.6-22.6h0a16,16,0,0,1,22.6,22.6Zm56-104a16,16,0,0,1-22.6-22.6h0a16,16,0,1,1,22.6,22.6Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M238.6,78.6A31.6,31.6,0,0,1,216,88a32.2,32.2,0,0,1-7.6-.9l-26.7,49.4.9.9a31.9,31.9,0,0,1,0,45.2,31.9,31.9,0,0,1-45.2,0,32,32,0,0,1-5-38.9l-20.1-20.1A32.7,32.7,0,0,1,96,128a32.2,32.2,0,0,1-7.6-.9L61.7,176.5l.9.9a31.9,31.9,0,0,1,0,45.2,31.9,31.9,0,0,1-45.2,0,31.9,31.9,0,0,1,0-45.2h0a32.1,32.1,0,0,1,30.2-8.5l26.7-49.4-.9-.9a31.9,31.9,0,0,1,0-45.2h0a32,32,0,0,1,50.2,38.9l20.1,20.1a32.4,32.4,0,0,1,23.9-3.5l26.7-49.4-.9-.9a31.9,31.9,0,0,1,0-45.2h0a32,32,0,0,1,45.2,45.2Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M237.2,34.8a30,30,0,0,0-42.4,0h0a30,30,0,0,0,0,42.4,11.2,11.2,0,0,0,2,1.8l-28.2,52.3a29.9,29.9,0,0,0-25.1,3.7L121,112.5A30,30,0,0,0,74.8,74.8h0a30,30,0,0,0,0,42.4,11.2,11.2,0,0,0,2,1.8L48.6,171.3a29.8,29.8,0,0,0-29.8,7.5h0a30,30,0,0,0,0,42.4,29.9,29.9,0,0,0,42.4,0,30,30,0,0,0,0-42.4,11.2,11.2,0,0,0-2-1.8l28.2-52.3A28,28,0,0,0,96,126a29.9,29.9,0,0,0,16.5-5L135,143.5a29.9,29.9,0,0,0,3.8,37.7,29.9,29.9,0,0,0,42.4,0,30,30,0,0,0,0-42.4,11.2,11.2,0,0,0-2-1.8l28.2-52.3A28,28,0,0,0,216,86a29.6,29.6,0,0,0,21.2-8.8A30,30,0,0,0,237.2,34.8ZM52.7,212.7a18,18,0,0,1-25.4-25.4h0a18,18,0,0,1,25.4,25.4Zm30.6-104a18,18,0,0,1,0-25.4h0a18,18,0,1,1,0,25.4Zm89.4,64a18,18,0,0,1-25.4-25.4h0a18,18,0,0,1,25.4,25.4Zm56-104a18,18,0,0,1-25.4-25.4h0a18,18,0,0,1,25.4,25.4Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M235.8,36.2a28.1,28.1,0,0,0-39.6,0h0a28.1,28.1,0,0,0,0,39.6,26.1,26.1,0,0,0,3.1,2.7l-29.7,55.2a27.9,27.9,0,0,0-26.4,3.9l-24.8-24.8A28,28,0,0,0,76.2,76.2h0a28.1,28.1,0,0,0,0,39.6,26.1,26.1,0,0,0,3.1,2.7L49.6,173.7a28.1,28.1,0,0,0-29.4,6.5h0a28.1,28.1,0,0,0,0,39.6,28,28,0,0,0,39.6,0,28.1,28.1,0,0,0,0-39.6,26.1,26.1,0,0,0-3.1-2.7l29.7-55.2A28.5,28.5,0,0,0,96,124a28.3,28.3,0,0,0,16.8-5.6l24.8,24.8a28,28,0,1,0,42.2-3,26.1,26.1,0,0,0-3.1-2.7l29.7-55.2A28.5,28.5,0,0,0,216,84a27.8,27.8,0,0,0,19.8-8.2A28.1,28.1,0,0,0,235.8,36.2ZM54.1,214.1a19.9,19.9,0,0,1-28.2-28.2h0a19.9,19.9,0,0,1,28.2,28.2Zm27.8-104a19.9,19.9,0,0,1,0-28.2h0a19.9,19.9,0,0,1,28.2,28.2h0A19.9,19.9,0,0,1,81.9,110.1Zm92.2,64a19.9,19.9,0,0,1-28.2-28.2h0a19.9,19.9,0,0,1,28.2,28.2Zm56-104a19.9,19.9,0,0,1-28.2-28.2h0a19.9,19.9,0,0,1,28.2,28.2Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M238.6,33.4a31.9,31.9,0,0,0-45.2,0h0a31.9,31.9,0,0,0,0,45.2l.9.9-26.7,49.4a32.4,32.4,0,0,0-23.9,3.5l-20.1-20.1A32,32,0,0,0,73.4,73.4h0a31.9,31.9,0,0,0,0,45.2l.9.9L47.6,168.9a32.1,32.1,0,0,0-30.2,8.5h0a31.9,31.9,0,0,0,0,45.2,31.9,31.9,0,0,0,45.2,0,31.9,31.9,0,0,0,0-45.2l-.9-.9,26.7-49.4a32.2,32.2,0,0,0,7.6.9,32.7,32.7,0,0,0,16.3-4.4l20.1,20.1a32,32,0,0,0,5,38.9,31.9,31.9,0,0,0,45.2,0,31.9,31.9,0,0,0,0-45.2l-.9-.9,26.7-49.4a32.2,32.2,0,0,0,7.6.9,31.6,31.6,0,0,0,22.6-9.4A31.9,31.9,0,0,0,238.6,33.4ZM51.3,211.3a16,16,0,0,1-22.6-22.6h0a16,16,0,0,1,22.6,22.6Zm33.4-104a15.9,15.9,0,0,1,0-22.6h0a16,16,0,1,1,0,22.6Zm86.6,64a16,16,0,0,1-22.6-22.6h0a16,16,0,0,1,22.6,22.6Zm56-104a16,16,0,0,1-22.6-22.6h0a16,16,0,1,1,22.6,22.6Z" />
        </>
      )}
    </svg>
  );
}

export function PhLinkBreak(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M84,68V48a12,12,0,0,1,24,0V68a12,12,0,0,1-24,0Zm76,108a12,12,0,0,0-12,12v20a12,12,0,0,0,24,0V188A12,12,0,0,0,160,176ZM48,108H68a12,12,0,0,0,0-24H48a12,12,0,0,0,0,24Zm160,40H188a12,12,0,0,0,0,24h20a12,12,0,0,0,0-24Zm-92.9,32.5-7.3,7.3a28,28,0,0,1-39.6-39.6l7.3-7.3a12,12,0,0,0-17-17l-7.3,7.3a52,52,0,0,0,73.6,73.6l7.3-7.3a12,12,0,0,0-17-17ZM187.8,68.2a28.1,28.1,0,0,1,0,39.6l-7.3,7.3a12,12,0,0,0,8.5,20.5,12.4,12.4,0,0,0,8.5-3.5l7.3-7.3a52,52,0,0,0-73.6-73.6l-7.3,7.3a12,12,0,0,0,17,17l7.3-7.3A28.1,28.1,0,0,1,187.8,68.2Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M88,72V48a8,8,0,0,1,16,0V72a8,8,0,0,1-16,0Zm72,104a8,8,0,0,0-8,8v24a8,8,0,0,0,16,0V184A8,8,0,0,0,160,176ZM48,104H72a8,8,0,0,0,0-16H48a8,8,0,0,0,0,16Zm160,48H184a8,8,0,0,0,0,16h24a8,8,0,0,0,0-16Zm-86.1,27.3-11.3,11.3a32,32,0,0,1-45.2-45.2l11.3-11.3a8.1,8.1,0,0,0,0-11.4,8,8,0,0,0-11.3,0L54.1,134.1a47.9,47.9,0,1,0,67.8,67.8l11.4-11.3a8,8,0,0,0,0-11.3A8.1,8.1,0,0,0,121.9,179.3ZM190.6,65.4a31.9,31.9,0,0,1,0,45.2l-11.3,11.3a8.1,8.1,0,0,0,0,11.4,8.3,8.3,0,0,0,5.7,2.3,8,8,0,0,0,5.6-2.3l11.3-11.4a47.9,47.9,0,0,0-67.8-67.8L122.7,65.4a8,8,0,0,0,0,11.3,8.1,8.1,0,0,0,11.4,0l11.3-11.3A31.9,31.9,0,0,1,190.6,65.4Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M133.3,179.3a8,8,0,0,1,0,11.3l-11.4,11.3a47.9,47.9,0,0,1-67.8-67.8l11.3-11.4a8,8,0,0,1,11.3,0,8.1,8.1,0,0,1,0,11.4L65.4,145.4a32,32,0,0,0,45.2,45.2l11.3-11.3A8.1,8.1,0,0,1,133.3,179.3ZM48,104H72a8,8,0,0,0,0-16H48a8,8,0,0,0,0,16ZM96,80a8,8,0,0,0,8-8V48a8,8,0,0,0-16,0V72A8,8,0,0,0,96,80Zm64,96a8,8,0,0,0-8,8v24a8,8,0,0,0,16,0V184A8,8,0,0,0,160,176Zm48-24H184a8,8,0,0,0,0,16h24a8,8,0,0,0,0-16ZM190.6,65.4a31.9,31.9,0,0,1,0,45.2l-11.3,11.3a8.1,8.1,0,0,0,0,11.4,8.3,8.3,0,0,0,5.7,2.3,8,8,0,0,0,5.6-2.3l11.3-11.4a47.9,47.9,0,0,0-67.8-67.8L122.7,65.4a8,8,0,0,0,0,11.3,8.1,8.1,0,0,0,11.4,0l11.3-11.3A31.9,31.9,0,0,1,190.6,65.4Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M90,72V48a6,6,0,0,1,12,0V72a6,6,0,0,1-12,0Zm70,106a6,6,0,0,0-6,6v24a6,6,0,0,0,12,0V184A6,6,0,0,0,160,178ZM48,102H72a6,6,0,0,0,0-12H48a6,6,0,0,0,0,12Zm160,52H184a6,6,0,0,0,0,12h24a6,6,0,0,0,0-12Zm-84.6,26.7L112,192a33.9,33.9,0,0,1-48-48l11.3-11.4a6,6,0,0,0-8.5-8.4L55.5,135.5a46,46,0,1,0,65,65l11.3-11.3a6,6,0,0,0-8.4-8.5ZM192,112l-11.3,11.4a6,6,0,1,0,8.5,8.4l11.3-11.3a46,46,0,0,0-65-65L124.2,66.8a6,6,0,0,0,8.4,8.5L144,64a33.9,33.9,0,0,1,48,48Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M92,72V48a4,4,0,0,1,8,0V72a4,4,0,0,1-8,0Zm68,108a4,4,0,0,0-4,4v24a4,4,0,0,0,8,0V184A4,4,0,0,0,160,180ZM48,100H72a4,4,0,0,0,0-8H48a4,4,0,0,0,0,8Zm160,56H184a4,4,0,0,0,0,8h24a4,4,0,0,0,0-8Zm-83.2,26.1-11.3,11.4a36.1,36.1,0,0,1-51-51l11.4-11.3a4,4,0,0,0,0-5.6,4,4,0,0,0-5.7,0L56.9,136.9a44,44,0,1,0,62.2,62.2l11.3-11.3a4,4,0,0,0,0-5.7A4,4,0,0,0,124.8,182.1Zm68.7-68.6-11.4,11.3a4,4,0,0,0,0,5.6,4,4,0,0,0,2.9,1.2,3.9,3.9,0,0,0,2.8-1.2l11.3-11.3a44,44,0,0,0-62.2-62.2L125.6,68.2a4,4,0,0,0,0,5.7,4,4,0,0,0,5.6,0l11.3-11.4a36.1,36.1,0,0,1,51,51Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M88,72V48a8,8,0,0,1,16,0V72a8,8,0,0,1-16,0Zm72,104a8,8,0,0,0-8,8v24a8,8,0,0,0,16,0V184A8,8,0,0,0,160,176ZM48,104H72a8,8,0,0,0,0-16H48a8,8,0,0,0,0,16Zm160,48H184a8,8,0,0,0,0,16h24a8,8,0,0,0,0-16Zm-86.1,27.3-11.3,11.3a32,32,0,0,1-45.2-45.2l11.3-11.3a8.1,8.1,0,0,0,0-11.4,8,8,0,0,0-11.3,0L54.1,134.1a47.9,47.9,0,1,0,67.8,67.8l11.4-11.3a8,8,0,0,0,0-11.3A8.1,8.1,0,0,0,121.9,179.3ZM190.6,65.4a31.9,31.9,0,0,1,0,45.2l-11.3,11.3a8.1,8.1,0,0,0,0,11.4,8.3,8.3,0,0,0,5.7,2.3,8,8,0,0,0,5.6-2.3l11.3-11.4a47.9,47.9,0,0,0-67.8-67.8L122.7,65.4a8,8,0,0,0,0,11.3,8.1,8.1,0,0,0,11.4,0l11.3-11.3A31.9,31.9,0,0,1,190.6,65.4Z" />
        </>
      )}
    </svg>
  );
}

export function PhLinkSimpleBreak(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M153.4,170.4a12,12,0,0,1,0,17l-28.2,28.3a60,60,0,1,1-84.9-84.9l28.3-28.3a12.2,12.2,0,0,1,17,0,12,12,0,0,1,0,17L57.3,147.8a36,36,0,0,0,50.9,50.9l28.3-28.3A11.9,11.9,0,0,1,153.4,170.4ZM215.7,40.3a60.1,60.1,0,0,0-84.9,0L102.5,68.6a12.2,12.2,0,0,0,0,17,12,12,0,0,0,17,0l28.3-28.3a36,36,0,1,1,50.9,50.9l-28.3,28.3a12.1,12.1,0,0,0,8.5,20.5,11.7,11.7,0,0,0,8.5-3.6l28.3-28.2a60,60,0,0,0,0-84.9Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M150.6,173.3a8,8,0,0,1,0,11.3l-28.3,28.3a56,56,0,0,1-79.2-79.2l28.3-28.3a8,8,0,0,1,11.3,11.3L54.5,145a40,40,0,0,0,0,56.5,39.7,39.7,0,0,0,56.5,0l28.3-28.2A7.9,7.9,0,0,1,150.6,173.3ZM212.9,43.1a56.1,56.1,0,0,0-79.2,0L105.4,71.4a8,8,0,0,0,11.3,11.3L145,54.5a39.7,39.7,0,0,1,56.5,0,40,40,0,0,1,0,56.5l-28.2,28.3a7.9,7.9,0,0,0,0,11.3,7.7,7.7,0,0,0,5.6,2.4,8,8,0,0,0,5.7-2.4l28.3-28.3A56.1,56.1,0,0,0,212.9,43.1Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M150.6,173.3a8,8,0,0,1,0,11.3l-28.3,28.3a56,56,0,0,1-79.2-79.2l28.3-28.3a8,8,0,0,1,11.3,11.3L54.5,145a40,40,0,0,0,0,56.5,39.7,39.7,0,0,0,56.5,0l28.3-28.2A7.9,7.9,0,0,1,150.6,173.3ZM212.9,43.1a56,56,0,0,0-79.2,0L105.4,71.4a8,8,0,0,0,11.3,11.3L145,54.5a39.7,39.7,0,0,1,56.5,0,40,40,0,0,1,0,56.5l-28.2,28.3a7.9,7.9,0,0,0,0,11.3,7.7,7.7,0,0,0,5.6,2.4,8,8,0,0,0,5.7-2.4l28.3-28.3A56.1,56.1,0,0,0,212.9,43.1Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M149.2,174.7a6.1,6.1,0,0,1,0,8.5l-28.3,28.2a54,54,0,0,1-76.3-76.3l28.2-28.3a6,6,0,0,1,8.5,8.5L53,143.6A42,42,0,0,0,112.4,203l28.3-28.3A5.9,5.9,0,0,1,149.2,174.7ZM211.4,44.6a53.9,53.9,0,0,0-76.3,0L106.8,72.8a6,6,0,0,0,8.5,8.5L143.6,53A42,42,0,1,1,203,112.4l-28.3,28.3a5.9,5.9,0,0,0,0,8.5,5.8,5.8,0,0,0,4.2,1.8,6.1,6.1,0,0,0,4.3-1.8l28.2-28.3A53.9,53.9,0,0,0,211.4,44.6Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M147.8,176.1a3.9,3.9,0,0,1,0,5.6L119.5,210A52,52,0,0,1,46,210a52,52,0,0,1,0-73.5l28.3-28.3a3.9,3.9,0,0,1,5.6,0,4,4,0,0,1,0,5.7L51.6,142.1a44.1,44.1,0,0,0,62.3,62.3l28.2-28.3A4,4,0,0,1,147.8,176.1ZM210,46a52,52,0,0,0-73.5,0L108.2,74.3a3.9,3.9,0,0,0,0,5.6,4,4,0,0,0,5.7,0l28.2-28.3a44.1,44.1,0,0,1,62.3,62.3l-28.3,28.2a4.1,4.1,0,0,0,2.8,6.9,3.8,3.8,0,0,0,2.8-1.2L210,119.5A52,52,0,0,0,210,46Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M150.6,173.3a8,8,0,0,1,0,11.3l-28.3,28.3a56,56,0,0,1-79.2-79.2l28.3-28.3a8,8,0,0,1,11.3,11.3L54.5,145a40,40,0,0,0,0,56.5,39.7,39.7,0,0,0,56.5,0l28.3-28.2A7.9,7.9,0,0,1,150.6,173.3ZM212.9,43.1a56,56,0,0,0-79.2,0L105.4,71.4a8,8,0,0,0,11.3,11.3L145,54.5a39.7,39.7,0,0,1,56.5,0,40,40,0,0,1,0,56.5l-28.2,28.3a7.9,7.9,0,0,0,0,11.3,7.7,7.7,0,0,0,5.6,2.4,8,8,0,0,0,5.7-2.4l28.3-28.3A56.1,56.1,0,0,0,212.9,43.1Z" />
        </>
      )}
    </svg>
  );
}

export function PhLinkSimpleHorizontalBreak(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M28,128a36,36,0,0,0,36,36h40a12,12,0,0,1,0,24H64A60,60,0,0,1,64,68h40a12,12,0,0,1,0,24H64A36,36,0,0,0,28,128ZM192,68H152a12,12,0,0,0,0,24h40a36,36,0,0,1,0,72H152a12,12,0,0,0,0,24h40a60,60,0,0,0,0-120Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M24,128a40,40,0,0,0,40,40h40a8,8,0,0,1,0,16H64A56,56,0,0,1,64,72h40a8,8,0,0,1,0,16H64A40,40,0,0,0,24,128ZM192,72H152a8,8,0,0,0,0,16h40a40,40,0,0,1,0,80H152a8,8,0,0,0,0,16h40a56,56,0,0,0,0-112Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M112,176a8,8,0,0,1-8,8H64A56,56,0,0,1,64,72h40a8,8,0,0,1,0,16H64a40,40,0,0,0,0,80h40A8,8,0,0,1,112,176ZM192,72H152a8,8,0,0,0,0,16h40a40,40,0,0,1,0,80H152a8,8,0,0,0,0,16h40a56,56,0,0,0,0-112Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M22,128a42,42,0,0,0,42,42h40a6,6,0,0,1,0,12H64A54,54,0,0,1,64,74h40a6,6,0,0,1,0,12H64A42,42,0,0,0,22,128ZM192,74H152a6,6,0,0,0,0,12h40a42,42,0,0,1,0,84H152a6,6,0,0,0,0,12h40a54,54,0,0,0,0-108Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M20,128a44,44,0,0,0,44,44h40a4,4,0,0,1,0,8H64A52,52,0,0,1,64,76h40a4,4,0,0,1,0,8H64A44,44,0,0,0,20,128ZM192,76H152a4,4,0,0,0,0,8h40a44,44,0,0,1,0,88H152a4,4,0,0,0,0,8h40a52,52,0,0,0,0-104Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M24,128a40,40,0,0,0,40,40h40a8,8,0,0,1,0,16H64A56,56,0,0,1,64,72h40a8,8,0,0,1,0,16H64A40,40,0,0,0,24,128ZM192,72H152a8,8,0,0,0,0,16h40a40,40,0,0,1,0,80H152a8,8,0,0,0,0,16h40a56,56,0,0,0,0-112Z" />
        </>
      )}
    </svg>
  );
}

export function PhLinkSimpleHorizontal(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M80,116h96a12,12,0,0,1,0,24H80a12,12,0,0,1,0-24Zm24,48H64a36,36,0,0,1,0-72h40a12,12,0,0,0,0-24H64a60,60,0,0,0,0,120h40a12,12,0,0,0,0-24Zm88-96H152a12,12,0,0,0,0,24h40a36,36,0,0,1,0,72H152a12,12,0,0,0,0,24h40a60,60,0,0,0,0-120Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M80,120h96a8,8,0,0,1,0,16H80a8,8,0,0,1,0-16Zm24,48H64a40,40,0,0,1,0-80h40a8,8,0,0,0,0-16H64a56,56,0,0,0,0,112h40a8,8,0,0,0,0-16Zm88-96H152a8,8,0,0,0,0,16h40a40,40,0,0,1,0,80H152a8,8,0,0,0,0,16h40a56,56,0,0,0,0-112Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M80,120h96a8,8,0,0,1,0,16H80a8,8,0,0,1,0-16Zm24,48H64a40,40,0,0,1,0-80h40a8,8,0,0,0,0-16H64a56,56,0,0,0,0,112h40a8,8,0,0,0,0-16Zm88-96H152a8,8,0,0,0,0,16h40a40,40,0,0,1,0,80H152a8,8,0,0,0,0,16h40a56,56,0,0,0,0-112Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M80,122h96a6,6,0,0,1,0,12H80a6,6,0,0,1,0-12Zm24,48H64a42,42,0,0,1,0-84h40a6,6,0,0,0,0-12H64a54,54,0,0,0,0,108h40a6,6,0,0,0,0-12Zm88-96H152a6,6,0,0,0,0,12h40a42,42,0,0,1,0,84H152a6,6,0,0,0,0,12h40a54,54,0,0,0,0-108Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M80,124h96a4,4,0,0,1,0,8H80a4,4,0,0,1,0-8Zm24,48H64a44,44,0,0,1,0-88h40a4,4,0,0,0,0-8H64a52,52,0,0,0,0,104h40a4,4,0,0,0,0-8Zm88-96H152a4,4,0,0,0,0,8h40a44,44,0,0,1,0,88H152a4,4,0,0,0,0,8h40a52,52,0,0,0,0-104Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M80,120h96a8,8,0,0,1,0,16H80a8,8,0,0,1,0-16Zm24,48H64a40,40,0,0,1,0-80h40a8,8,0,0,0,0-16H64a56,56,0,0,0,0,112h40a8,8,0,0,0,0-16Zm88-96H152a8,8,0,0,0,0,16h40a40,40,0,0,1,0,80H152a8,8,0,0,0,0,16h40a56,56,0,0,0,0-112Z" />
        </>
      )}
    </svg>
  );
}

export function PhLinkSimple(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M85.6,153.4l67.9-67.8a12,12,0,0,1,16.9,16.9l-67.9,67.9a12,12,0,0,1-16.9,0A12,12,0,0,1,85.6,153.4Zm50.9,17-28.3,28.3a36,36,0,0,1-50.9-50.9l28.3-28.3a12,12,0,0,0,0-17,12.2,12.2,0,0,0-17,0L40.3,130.8a60,60,0,0,0,84.9,84.9l28.2-28.3a12,12,0,0,0,0-17A11.9,11.9,0,0,0,136.5,170.4ZM215.7,40.3a60.1,60.1,0,0,0-84.9,0L102.5,68.6a12.2,12.2,0,0,0,0,17,12,12,0,0,0,17,0l28.3-28.3a36,36,0,1,1,50.9,50.9l-28.3,28.3a12.1,12.1,0,0,0,8.5,20.5,11.7,11.7,0,0,0,8.5-3.6l28.3-28.2a60,60,0,0,0,0-84.9Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M167.6,88.4a8,8,0,0,1,0,11.3L99.7,167.6a8.1,8.1,0,0,1-11.3,0,8,8,0,0,1,0-11.3l67.9-67.9A8,8,0,0,1,167.6,88.4Zm-28.3,84.9L111,201.5a39.7,39.7,0,0,1-56.5,0,40,40,0,0,1,0-56.5l28.2-28.3a8,8,0,0,0-11.3-11.3L43.1,133.7a56,56,0,0,0,79.2,79.2l28.3-28.3a8,8,0,0,0,0-11.3A7.9,7.9,0,0,0,139.3,173.3ZM212.9,43.1a56.1,56.1,0,0,0-79.2,0L105.4,71.4a8,8,0,0,0,11.3,11.3L145,54.5a39.7,39.7,0,0,1,56.5,0,40,40,0,0,1,0,56.5l-28.2,28.3a7.9,7.9,0,0,0,0,11.3,7.7,7.7,0,0,0,5.6,2.4,8,8,0,0,0,5.7-2.4l28.3-28.3A56.1,56.1,0,0,0,212.9,43.1Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M167.6,88.4a8,8,0,0,1,0,11.3L99.7,167.6a8.1,8.1,0,0,1-11.3,0,8,8,0,0,1,0-11.3l67.9-67.9A8,8,0,0,1,167.6,88.4Zm-28.3,84.9L111,201.5a39.7,39.7,0,0,1-56.5,0,40,40,0,0,1,0-56.5l28.2-28.3a8,8,0,0,0-11.3-11.3L43.1,133.7a56,56,0,0,0,79.2,79.2l28.3-28.3a8,8,0,0,0,0-11.3A7.9,7.9,0,0,0,139.3,173.3ZM212.9,43.1a56,56,0,0,0-79.2,0L105.4,71.4a8,8,0,0,0,11.3,11.3L145,54.5a39.7,39.7,0,0,1,56.5,0,40,40,0,0,1,0,56.5l-28.2,28.3a7.9,7.9,0,0,0,0,11.3,7.7,7.7,0,0,0,5.6,2.4,8,8,0,0,0,5.7-2.4l28.3-28.3A56.1,56.1,0,0,0,212.9,43.1Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M166.2,89.8a6.1,6.1,0,0,1,0,8.5L98.3,166.2a6.2,6.2,0,0,1-8.5,0,6.1,6.1,0,0,1,0-8.5l67.9-67.9A6.1,6.1,0,0,1,166.2,89.8Zm-25.5,84.9L112.4,203A42,42,0,0,1,53,143.6l28.3-28.3a6,6,0,0,0-8.5-8.5L44.6,135.1a53.9,53.9,0,0,0,0,76.3,54,54,0,0,0,76.3,0l28.3-28.2a6.1,6.1,0,0,0,0-8.5A5.9,5.9,0,0,0,140.7,174.7ZM211.4,44.6a53.9,53.9,0,0,0-76.3,0L106.8,72.8a6,6,0,0,0,8.5,8.5L143.6,53A42,42,0,1,1,203,112.4l-28.3,28.3a5.9,5.9,0,0,0,0,8.5,5.8,5.8,0,0,0,4.2,1.8,6.1,6.1,0,0,0,4.3-1.8l28.2-28.3A53.9,53.9,0,0,0,211.4,44.6Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M164.8,91.2a4.2,4.2,0,0,1,0,5.7L96.9,164.8a4.3,4.3,0,0,1-2.8,1.1,4.4,4.4,0,0,1-2.9-1.1,4.2,4.2,0,0,1,0-5.7l67.9-67.9A4.2,4.2,0,0,1,164.8,91.2Zm-22.7,84.9-28.2,28.3a44.1,44.1,0,0,1-62.3-62.3l28.3-28.2a4,4,0,0,0,0-5.7,3.9,3.9,0,0,0-5.6,0L46,136.5A52,52,0,0,0,46,210a52,52,0,0,0,73.5,0l28.3-28.3a3.9,3.9,0,0,0,0-5.6A4,4,0,0,0,142.1,176.1ZM210,46a52,52,0,0,0-73.5,0L108.2,74.3a3.9,3.9,0,0,0,0,5.6,4,4,0,0,0,5.7,0l28.2-28.3a44.1,44.1,0,0,1,62.3,62.3l-28.3,28.2a4.1,4.1,0,0,0,2.8,6.9,3.8,3.8,0,0,0,2.8-1.2L210,119.5A52,52,0,0,0,210,46Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M167.6,88.4a8,8,0,0,1,0,11.3L99.7,167.6a7.6,7.6,0,0,1-5.6,2.3,7.8,7.8,0,0,1-5.7-2.3,8,8,0,0,1,0-11.3l67.9-67.9A8,8,0,0,1,167.6,88.4Zm-28.3,84.9L111,201.5a39.7,39.7,0,0,1-56.5,0,40,40,0,0,1,0-56.5l28.2-28.3a8,8,0,0,0-11.3-11.3L43.1,133.7a56,56,0,0,0,79.2,79.2l28.3-28.3a8,8,0,0,0,0-11.3A7.9,7.9,0,0,0,139.3,173.3ZM212.9,43.1a56,56,0,0,0-79.2,0L105.4,71.4a8,8,0,0,0,11.3,11.3L145,54.5a39.7,39.7,0,0,1,56.5,0,40,40,0,0,1,0,56.5l-28.2,28.3a7.9,7.9,0,0,0,0,11.3,7.7,7.7,0,0,0,5.6,2.4,8,8,0,0,0,5.7-2.4l28.3-28.3A56.1,56.1,0,0,0,212.9,43.1Z" />
        </>
      )}
    </svg>
  );
}

export function PhLink(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M212.9,122.3l-30.3,30.3a56,56,0,0,1-79.2,0,12,12,0,1,1,16.9-16.9,32.2,32.2,0,0,0,45.3,0l30.3-30.3a32,32,0,0,0-45.3-45.3L141,69.8a12,12,0,0,1-17-17l9.7-9.7a56,56,0,1,1,79.2,79.2ZM115,186.2l-9.6,9.7a32,32,0,0,1-45.3-45.3l30.3-30.3a32.2,32.2,0,0,1,45.3,0,12,12,0,0,0,16.9-16.9,55.9,55.9,0,0,0-79.2,0L43.1,133.7a56,56,0,0,0,79.2,79.2l9.7-9.7a12,12,0,0,0-17-17Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M225.3,82.7A51.8,51.8,0,0,1,210,119.5l-28.3,28.3a52,52,0,0,1-73.5,0,8,8,0,1,1,11.3-11.3,36.1,36.1,0,0,0,50.9,0l28.3-28.3a36,36,0,1,0-50.9-50.9L128,77.1a8,8,0,0,1-11.3-11.3L136.5,46a52.1,52.1,0,0,1,88.8,36.7ZM128,178.9l-19.8,19.8a36,36,0,0,1-50.9-50.9l28.3-28.3a36.1,36.1,0,0,1,50.9,0,8,8,0,0,0,11.3-11.3,52,52,0,0,0-73.5,0L46,136.5A52,52,0,1,0,119.5,210l19.8-19.8A8,8,0,0,0,128,178.9Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M225.3,82.7A51.8,51.8,0,0,1,210,119.5l-28.3,28.3a52,52,0,0,1-73.5,0,8,8,0,1,1,11.3-11.3,36.1,36.1,0,0,0,50.9,0l28.3-28.3a36,36,0,1,0-50.9-50.9L128,77.1a8,8,0,0,1-11.3-11.3L136.5,46a52.1,52.1,0,0,1,88.8,36.7ZM128,178.9l-19.8,19.8a36,36,0,0,1-50.9-50.9l28.3-28.3a36.1,36.1,0,0,1,50.9,0,8,8,0,0,0,11.3-11.3,52,52,0,0,0-73.5,0L46,136.5A52,52,0,1,0,119.5,210l19.8-19.8A8,8,0,0,0,128,178.9Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M208.6,118.1l-28.3,28.3a50.1,50.1,0,0,1-70.7,0,6,6,0,0,1,8.5-8.5,38,38,0,0,0,53.7,0l28.3-28.3a38,38,0,1,0-53.7-53.7L126.6,75.7a6,6,0,0,1-8.5-8.5l19.8-19.8a49.9,49.9,0,0,1,70.7,0A50,50,0,0,1,208.6,118.1Zm-79.2,62.2-19.8,19.8a38,38,0,0,1-53.7-53.7l28.3-28.3a38,38,0,0,1,53.7,0,6,6,0,0,0,8.5-8.5,50,50,0,0,0-70.7,0L47.4,137.9a50,50,0,0,0,0,70.7,50.1,50.1,0,0,0,70.7,0l19.8-19.8a6,6,0,0,0-8.5-8.5Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M207.2,116.7,178.9,145a48.2,48.2,0,0,1-67.9,0,4,4,0,1,1,5.7-5.7,40.1,40.1,0,0,0,56.6,0L201.5,111a40,40,0,0,0,0-56.5,39.7,39.7,0,0,0-56.5,0L125.2,74.3a4,4,0,0,1-5.7-5.7l19.8-19.8a48,48,0,0,1,67.9,67.9Zm-76.4,65L111,201.5A40,40,0,0,1,54.5,145l28.2-28.3a40.1,40.1,0,0,1,56.6,0A4,4,0,0,0,145,111a48.1,48.1,0,0,0-67.9,0L48.8,139.3a48.1,48.1,0,0,0,0,67.9,47.9,47.9,0,0,0,67.9,0l19.8-19.8a4,4,0,0,0-5.7-5.7Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M225.3,82.7A51.8,51.8,0,0,1,210,119.5l-28.3,28.3a52,52,0,0,1-73.5,0,8,8,0,1,1,11.3-11.3,36.1,36.1,0,0,0,50.9,0l28.3-28.3a36,36,0,1,0-50.9-50.9L128,77.1a8,8,0,0,1-11.3-11.3L136.5,46a52.1,52.1,0,0,1,88.8,36.7ZM128,178.9l-19.8,19.8a36,36,0,0,1-50.9-50.9l28.3-28.3a36.1,36.1,0,0,1,50.9,0,8,8,0,0,0,11.3-11.3,52,52,0,0,0-73.5,0L46,136.5A52,52,0,1,0,119.5,210l19.8-19.8A8,8,0,0,0,128,178.9Z" />
        </>
      )}
    </svg>
  );
}

export function PhLinkedinLogo(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M96,120v56a12,12,0,0,1-24,0V120a12,12,0,0,1,24,0Zm28,68a12,12,0,0,0,12-12V148a16,16,0,0,1,32,0v28a12,12,0,0,0,24,0V148a40,40,0,0,0-58.6-35.4A12,12,0,0,0,112,120v56A12,12,0,0,0,124,188ZM232,44V212a20.1,20.1,0,0,1-20,20H44a20.1,20.1,0,0,1-20-20V44A20.1,20.1,0,0,1,44,24H212A20.1,20.1,0,0,1,232,44Zm-24,4H48V208H208ZM84,96A16,16,0,1,0,68,80,16,16,0,0,0,84,96Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M220,44V212a8,8,0,0,1-8,8H44a8,8,0,0,1-8-8V44a8,8,0,0,1,8-8H212A8,8,0,0,1,220,44Z"
            opacity="0.2"
          />
          <path d="M96,112v64a8,8,0,0,1-16,0V112a8,8,0,0,1,16,0ZM88,68a12,12,0,1,0,12,12A12,12,0,0,0,88,68ZM228,44V212a16,16,0,0,1-16,16H44a16,16,0,0,1-16-16V44A16,16,0,0,1,44,28H212A16,16,0,0,1,228,44ZM212,212V44H44V212ZM148,104a36,36,0,0,0-20.2,6.2A8,8,0,0,0,112,112v64a8,8,0,0,0,16,0V140a20,20,0,0,1,40,0v36a8,8,0,0,0,16,0V140A36,36,0,0,0,148,104Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M212,28H44A16,16,0,0,0,28,44V212a16,16,0,0,0,16,16H212a16,16,0,0,0,16-16V44A16,16,0,0,0,212,28ZM96,176a8,8,0,0,1-16,0V112a8,8,0,0,1,16,0ZM88,92a12,12,0,1,1,12-12A12,12,0,0,1,88,92Zm96,84a8,8,0,0,1-16,0V140a20,20,0,0,0-40,0v36a8,8,0,0,1-16,0V112a8,8,0,0,1,15.8-1.8A36,36,0,0,1,184,140Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M94,112v64a6,6,0,0,1-12,0V112a6,6,0,0,1,12,0ZM88,70A10,10,0,1,0,98,80,10,10,0,0,0,88,70ZM226,44V212a14,14,0,0,1-14,14H44a14,14,0,0,1-14-14V44A14,14,0,0,1,44,30H212A14,14,0,0,1,226,44Zm-12,0a2,2,0,0,0-2-2H44a2,2,0,0,0-2,2V212a2,2,0,0,0,2,2H212a2,2,0,0,0,2-2Zm-66,62a34,34,0,0,0-22,8.1V112a6,6,0,0,0-12,0v64a6,6,0,0,0,12,0V140a22,22,0,0,1,44,0v36a6,6,0,0,0,12,0V140A34.1,34.1,0,0,0,148,106Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M96,80a8,8,0,1,1-8-8A8,8,0,0,1,96,80Zm-8,28a4,4,0,0,0-4,4v64a4,4,0,0,0,8,0V112A4,4,0,0,0,88,108Zm60,0a31.8,31.8,0,0,0-24,10.9V112a4,4,0,0,0-8,0v64a4,4,0,0,0,8,0V140a24,24,0,0,1,48,0v36a4,4,0,0,0,8,0V140A32.1,32.1,0,0,0,148,108Zm76-64V212a12,12,0,0,1-12,12H44a12,12,0,0,1-12-12V44A12,12,0,0,1,44,32H212A12,12,0,0,1,224,44Zm-8,0a4,4,0,0,0-4-4H44a4,4,0,0,0-4,4V212a4,4,0,0,0,4,4H212a4,4,0,0,0,4-4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M100,80A12,12,0,1,1,88,68,12,12,0,0,1,100,80ZM228,44V212a16,16,0,0,1-16,16H44a16,16,0,0,1-16-16V44A16,16,0,0,1,44,28H212A16,16,0,0,1,228,44ZM212,212V44H44V212ZM88,104a8,8,0,0,0-8,8v64a8,8,0,0,0,16,0V112A8,8,0,0,0,88,104Zm60,0a36,36,0,0,0-20.2,6.2A8,8,0,0,0,112,112v64a8,8,0,0,0,16,0V140a20,20,0,0,1,40,0v36a8,8,0,0,0,16,0V140A36,36,0,0,0,148,104Z" />
        </>
      )}
    </svg>
  );
}

export function PhLinuxLogo(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M88,112a16,16,0,1,1,16,16A16,16,0,0,1,88,112Zm80,0a16,16,0,1,0-16,16A16,16,0,0,0,168,112Zm-16.7,29L128,150.9,104.7,141a12,12,0,1,0-9.4,22l28,12a11.5,11.5,0,0,0,9.4,0l28-12a12,12,0,0,0-9.4-22Zm9.9,61a60.1,60.1,0,0,0-66.4,0,12,12,0,0,0,6.7,22,12.2,12.2,0,0,0,6.6-2,36,36,0,0,1,39.8,0,12,12,0,0,0,13.3-20Zm69.6,5c-.3-.5-22.8-50.4-22.8-103a80,80,0,0,0-160,0c0,52.5-22.5,102.4-22.8,103a12.1,12.1,0,0,0,5.9,15.9,11.6,11.6,0,0,0,5,1.1,12.2,12.2,0,0,0,11-7c1-2.2,24.9-55,24.9-113a56,56,0,0,1,112,0c0,58,23.9,110.8,24.9,113a12,12,0,0,0,21.9-10Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M224,216H169.6a48.1,48.1,0,0,0-83.2,0H32S64,160,64,96a64,64,0,0,1,128,0C192,160,224,216,224,216Z"
            opacity="0.2"
          />
          <path d="M228,222.9a7.9,7.9,0,0,1-10.9-2.9c-1.4-2.4-33.1-58.6-33.1-124A56,56,0,0,0,72,96c0,65.4-31.7,121.6-33.1,124a7.7,7.7,0,0,1-6.9,4,7.3,7.3,0,0,1-4-1.1A7.9,7.9,0,0,1,25.1,212c.3-.5,30.9-55,30.9-116a72,72,0,0,1,144,0c0,61,30.6,115.5,30.9,116A7.9,7.9,0,0,1,228,222.9ZM100,96a12,12,0,1,0,12,12A12,12,0,0,0,100,96Zm68,12a12,12,0,1,0-12,12A12,12,0,0,0,168,108ZM99.6,136.8a8,8,0,1,0-7.2,14.4l32,16a8.5,8.5,0,0,0,7.2,0l32-16a8,8,0,1,0-7.2-14.4L128,151.1ZM128,184a56.2,56.2,0,0,0-48.5,28,8,8,0,1,0,13.8,8,40.1,40.1,0,0,1,69.4,0,7.7,7.7,0,0,0,6.9,4,7.6,7.6,0,0,0,4-1.1,8,8,0,0,0,2.9-10.9A56.2,56.2,0,0,0,128,184Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M161.2,217.7a4,4,0,0,1-3.3,6.3H98.1a4,4,0,0,1-3.3-6.3,40,40,0,0,1,66.4,0Zm68.6,3.8a8.2,8.2,0,0,1-6,2.5H184.6a4.1,4.1,0,0,1-3.8-2.7,56,56,0,0,0-105.6,0,4.1,4.1,0,0,1-3.8,2.7H32.2a8,8,0,0,1-6-2.5,7.9,7.9,0,0,1-1.1-9.5c.3-.5,30.9-55,30.9-116a72.1,72.1,0,0,1,72.5-72c39.6.3,71.4,32.9,71.5,72.5.2,60.8,30.6,115,30.9,115.5A7.9,7.9,0,0,1,229.8,221.5ZM88,108a12,12,0,1,0,12-12A12,12,0,0,0,88,108Zm79.2,32.4a8.2,8.2,0,0,0-10.8-3.6L128,151.1,99.6,136.8a8,8,0,1,0-7.2,14.4l32,16a8.5,8.5,0,0,0,7.2,0l32-16A8.2,8.2,0,0,0,167.2,140.4ZM168,108a12,12,0,1,0-12,12A12,12,0,0,0,168,108Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M227,221.2a5.9,5.9,0,0,1-8.2-2.2c-1.3-2.4-32.8-58.2-32.8-123A58,58,0,0,0,70,96c0,64.8-31.5,120.6-32.8,123a6,6,0,0,1-5.2,3,6.7,6.7,0,0,1-3-.8,5.9,5.9,0,0,1-2.2-8.2c.4-.7,31.2-55.5,31.2-117a70,70,0,0,1,140,0c0,61.6,30.9,116.5,31.2,117A5.9,5.9,0,0,1,227,221.2ZM100,98a10,10,0,1,0,10,10A10,10,0,0,0,100,98Zm66,10a10,10,0,1,0-10,10A10,10,0,0,0,166,108ZM98.7,138.6a6,6,0,1,0-5.4,10.8l32,16a6.4,6.4,0,0,0,5.4,0l32-16a6,6,0,1,0-5.4-10.8L128,153.3ZM128,186a54.3,54.3,0,0,0-46.8,27,6,6,0,0,0,2.2,8.2,5.9,5.9,0,0,0,8.2-2.2,42,42,0,0,1,72.8,0,6,6,0,0,0,5.2,3,6.7,6.7,0,0,0,3-.8,6,6,0,0,0,2.2-8.2A54.3,54.3,0,0,0,128,186Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M226,219.5a4.6,4.6,0,0,1-2,.5,4.1,4.1,0,0,1-3.5-2c-1.3-2.3-32.5-57.7-32.5-122A60,60,0,0,0,68,96c0,64.3-31.2,119.7-32.5,122a4,4,0,0,1-7-4c.3-.5,31.5-55.9,31.5-118a68,68,0,0,1,136,0c0,62.1,31.2,117.5,31.5,118A4.1,4.1,0,0,1,226,219.5ZM100,100a8,8,0,1,0,8,8A8,8,0,0,0,100,100Zm64,8a8,8,0,1,0-8,8A8,8,0,0,0,164,108ZM97.8,140.4a4,4,0,1,0-3.6,7.2l32,16a4.2,4.2,0,0,0,3.6,0l32-16a4,4,0,1,0-3.6-7.2L128,155.5ZM128,188a52.1,52.1,0,0,0-45,26,4,4,0,1,0,6.9,4,44,44,0,0,1,76.2,0,4.1,4.1,0,0,0,3.5,2,4.6,4.6,0,0,0,2-.5A4,4,0,0,0,173,214,52.1,52.1,0,0,0,128,188Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M228,222.9a7.9,7.9,0,0,1-10.9-2.9c-1.4-2.4-33.1-58.6-33.1-124A56,56,0,0,0,72,96c0,65.4-31.7,121.6-33.1,124a7.7,7.7,0,0,1-6.9,4,7.3,7.3,0,0,1-4-1.1A7.9,7.9,0,0,1,25.1,212c.3-.5,30.9-55,30.9-116a72,72,0,0,1,144,0c0,61,30.6,115.5,30.9,116A7.9,7.9,0,0,1,228,222.9ZM100,96a12,12,0,1,0,12,12A12,12,0,0,0,100,96Zm68,12a12,12,0,1,0-12,12A12,12,0,0,0,168,108ZM99.6,136.8a8,8,0,1,0-7.2,14.4l32,16a8.5,8.5,0,0,0,7.2,0l32-16a8,8,0,1,0-7.2-14.4L128,151.1ZM128,184a56.2,56.2,0,0,0-48.5,28,8,8,0,1,0,13.8,8,40.1,40.1,0,0,1,69.4,0,7.7,7.7,0,0,0,6.9,4,7.6,7.6,0,0,0,4-1.1,8,8,0,0,0,2.9-10.9A56.2,56.2,0,0,0,128,184Z" />
        </>
      )}
    </svg>
  );
}

export function PhListBullets(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M76,64A12,12,0,0,1,88,52H216a12,12,0,0,1,0,24H88A12,12,0,0,1,76,64Zm140,52H88a12,12,0,0,0,0,24H216a12,12,0,0,0,0-24Zm0,64H88a12,12,0,0,0,0,24H216a12,12,0,0,0,0-24ZM44,112a16,16,0,1,0,16,16A16,16,0,0,0,44,112Zm0-64A16,16,0,1,0,60,64,16,16,0,0,0,44,48Zm0,128a16,16,0,1,0,16,16A16,16,0,0,0,44,176Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M80,64a8,8,0,0,1,8-8H216a8,8,0,0,1,0,16H88A8,8,0,0,1,80,64Zm136,56H88a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Zm0,64H88a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16ZM44,52A12,12,0,1,0,56,64,12,12,0,0,0,44,52Zm0,64a12,12,0,1,0,12,12A12,12,0,0,0,44,116Zm0,64a12,12,0,1,0,12,12A12,12,0,0,0,44,180Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M56,128a12,12,0,1,1-12-12A12,12,0,0,1,56,128ZM44,180a12,12,0,1,0,12,12A12,12,0,0,0,44,180ZM44,52A12,12,0,1,0,56,64,12,12,0,0,0,44,52ZM88,72H216a8,8,0,0,0,0-16H88a8,8,0,0,0,0,16Zm128,48H88a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Zm0,64H88a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M82,64a6,6,0,0,1,6-6H216a6,6,0,0,1,0,12H88A6,6,0,0,1,82,64Zm134,58H88a6,6,0,0,0,0,12H216a6,6,0,0,0,0-12Zm0,64H88a6,6,0,0,0,0,12H216a6,6,0,0,0,0-12ZM44,54A10,10,0,1,0,54,64,10,10,0,0,0,44,54Zm0,128a10,10,0,1,0,10,10A10,10,0,0,0,44,182Zm0-64a10,10,0,1,0,10,10A10,10,0,0,0,44,118Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M84,64a4,4,0,0,1,4-4H216a4,4,0,0,1,0,8H88A4,4,0,0,1,84,64Zm132,60H88a4,4,0,0,0,0,8H216a4,4,0,0,0,0-8Zm0,64H88a4,4,0,0,0,0,8H216a4,4,0,0,0,0-8ZM44,120a8,8,0,1,0,8,8A8,8,0,0,0,44,120Zm0-64a8,8,0,1,0,8,8A8,8,0,0,0,44,56Zm0,128a8,8,0,1,0,8,8A8,8,0,0,0,44,184Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M80,64a8,8,0,0,1,8-8H216a8,8,0,0,1,0,16H88A8,8,0,0,1,80,64Zm136,56H88a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Zm0,64H88a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16ZM44,52A12,12,0,1,0,56,64,12,12,0,0,0,44,52Zm0,64a12,12,0,1,0,12,12A12,12,0,0,0,44,116Zm0,64a12,12,0,1,0,12,12A12,12,0,0,0,44,180Z" />
        </>
      )}
    </svg>
  );
}

export function PhListChecks(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M228,128a12,12,0,0,1-12,12H136a12,12,0,0,1,0-24h80A12,12,0,0,1,228,128ZM136,76h80a12,12,0,0,0,0-24H136a12,12,0,0,0,0,24Zm80,104H136a12,12,0,0,0,0,24h80a12,12,0,0,0,0-24ZM83.9,39.2,57.3,63.7l-9.2-8.5A12,12,0,1,0,31.9,72.8l17.3,16a12,12,0,0,0,16.3,0l34.6-32A12,12,0,0,0,83.9,39.2Zm0,64L57.3,127.7l-9.2-8.5a12,12,0,0,0-16.2,17.6l17.3,16a12,12,0,0,0,16.3,0l34.6-32a12,12,0,1,0-16.2-17.6Zm0,64L57.3,191.7l-9.2-8.5a12,12,0,0,0-16.2,17.6l17.3,16a12,12,0,0,0,16.3,0l34.6-32a12,12,0,1,0-16.2-17.6Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M224,128a8,8,0,0,1-8,8H128a8,8,0,0,1,0-16h88A8,8,0,0,1,224,128ZM128,72h88a8,8,0,0,0,0-16H128a8,8,0,0,0,0,16Zm88,112H128a8,8,0,0,0,0,16h88a8,8,0,0,0,0-16ZM86.6,42.1l-29.3,27-11.9-11A8,8,0,0,0,34.6,69.9l17.3,16A8,8,0,0,0,57.3,88a8.2,8.2,0,0,0,5.5-2.1l34.6-32A8,8,0,1,0,86.6,42.1Zm0,64-29.3,27-11.9-11a8,8,0,1,0-10.8,11.8l17.3,16a8,8,0,0,0,5.4,2.1,8.2,8.2,0,0,0,5.5-2.1l34.6-32a8,8,0,1,0-10.8-11.8Zm0,64-29.3,27-11.9-11a8,8,0,1,0-10.8,11.8l17.3,16a8,8,0,0,0,5.4,2.1,8.2,8.2,0,0,0,5.5-2.1l34.6-32a8,8,0,0,0-10.8-11.8Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M97.9,170.6a7.9,7.9,0,0,1-.5,11.3l-34.6,32a8.2,8.2,0,0,1-5.5,2.1,8,8,0,0,1-5.4-2.1l-17.3-16a8,8,0,1,1,10.8-11.8l11.9,11,29.3-27A7.9,7.9,0,0,1,97.9,170.6ZM128,72h88a8,8,0,0,0,0-16H128a8,8,0,0,0,0,16ZM86.6,106.1l-29.3,27-11.9-11a8,8,0,1,0-10.8,11.8l17.3,16a8,8,0,0,0,5.4,2.1,8.2,8.2,0,0,0,5.5-2.1l34.6-32a8,8,0,1,0-10.8-11.8ZM216,120H128a8,8,0,0,0,0,16h88a8,8,0,0,0,0-16Zm0,64H128a8,8,0,0,0,0,16h88a8,8,0,0,0,0-16ZM86.6,42.1l-29.3,27-11.9-11A8,8,0,0,0,34.6,69.9l17.3,16A8,8,0,0,0,57.3,88a8.2,8.2,0,0,0,5.5-2.1l34.6-32A8,8,0,1,0,86.6,42.1Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M222,128a6,6,0,0,1-6,6H128a6,6,0,0,1,0-12h88A6,6,0,0,1,222,128ZM128,70h88a6,6,0,0,0,0-12H128a6,6,0,0,0,0,12Zm88,116H128a6,6,0,0,0,0,12h88a6,6,0,0,0,0-12ZM87.9,43.6,57.3,71.8,44.1,59.6a6,6,0,0,0-8.2,8.8l17.4,16a5.7,5.7,0,0,0,4,1.6,5.7,5.7,0,0,0,4.1-1.6l34.7-32a6,6,0,1,0-8.2-8.8Zm0,64L57.3,135.8,44.1,123.6a6,6,0,0,0-8.2,8.8l17.4,16a5.7,5.7,0,0,0,4,1.6,5.7,5.7,0,0,0,4.1-1.6l34.7-32a6,6,0,1,0-8.2-8.8Zm0,64L57.3,199.8,44.1,187.6a6,6,0,0,0-8.2,8.8l17.4,16a5.7,5.7,0,0,0,4,1.6,5.7,5.7,0,0,0,4.1-1.6l34.7-32a6,6,0,1,0-8.2-8.8Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M220,128a4,4,0,0,1-4,4H128a4,4,0,0,1,0-8h88A4,4,0,0,1,220,128ZM128,68h88a4,4,0,0,0,0-8H128a4,4,0,0,0,0,8Zm88,120H128a4,4,0,0,0,0,8h88a4,4,0,0,0,0-8ZM89.3,45.1l-32,29.5L42.7,61.1a4,4,0,1,0-5.4,5.8l17.3,16A4.3,4.3,0,0,0,57.3,84,3.9,3.9,0,0,0,60,82.9l34.7-32a4,4,0,1,0-5.4-5.8Zm0,64-32,29.5L42.7,125.1a4,4,0,0,0-5.4,5.8l17.3,16a4.3,4.3,0,0,0,2.7,1.1,3.9,3.9,0,0,0,2.7-1.1l34.7-32a4,4,0,0,0-5.4-5.8Zm0,64-32,29.5L42.7,189.1a4,4,0,0,0-5.4,5.8l17.3,16a4.3,4.3,0,0,0,2.7,1.1,3.9,3.9,0,0,0,2.7-1.1l34.7-32a4,4,0,1,0-5.4-5.8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M224,128a8,8,0,0,1-8,8H128a8,8,0,0,1,0-16h88A8,8,0,0,1,224,128ZM128,72h88a8,8,0,0,0,0-16H128a8,8,0,0,0,0,16Zm88,112H128a8,8,0,0,0,0,16h88a8,8,0,0,0,0-16ZM86.6,42.1l-29.3,27-11.9-11A8,8,0,0,0,34.6,69.9l17.3,16A8,8,0,0,0,57.3,88a8.2,8.2,0,0,0,5.5-2.1l34.6-32A8,8,0,1,0,86.6,42.1Zm0,64-29.3,27-11.9-11a8,8,0,1,0-10.8,11.8l17.3,16a8,8,0,0,0,5.4,2.1,8.2,8.2,0,0,0,5.5-2.1l34.6-32a8,8,0,1,0-10.8-11.8Zm0,64-29.3,27-11.9-11a8,8,0,1,0-10.8,11.8l17.3,16a8,8,0,0,0,5.4,2.1,8.2,8.2,0,0,0,5.5-2.1l34.6-32a8,8,0,0,0-10.8-11.8Z" />
        </>
      )}
    </svg>
  );
}

export function PhListDashes(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M84,64A12,12,0,0,1,96,52H216a12,12,0,0,1,0,24H96A12,12,0,0,1,84,64Zm132,52H96a12,12,0,0,0,0,24H216a12,12,0,0,0,0-24Zm0,64H96a12,12,0,0,0,0,24H216a12,12,0,0,0,0-24ZM56,52H40a12,12,0,0,0,0,24H56a12,12,0,0,0,0-24Zm0,64H40a12,12,0,0,0,0,24H56a12,12,0,0,0,0-24Zm0,64H40a12,12,0,0,0,0,24H56a12,12,0,0,0,0-24Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M88,64a8,8,0,0,1,8-8H216a8,8,0,0,1,0,16H96A8,8,0,0,1,88,64Zm128,56H96a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Zm0,64H96a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16ZM56,56H40a8,8,0,0,0,0,16H56a8,8,0,0,0,0-16Zm0,64H40a8,8,0,0,0,0,16H56a8,8,0,0,0,0-16Zm0,64H40a8,8,0,0,0,0,16H56a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M88,64a8,8,0,0,1,8-8H216a8,8,0,0,1,0,16H96A8,8,0,0,1,88,64Zm128,56H96a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Zm0,64H96a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16ZM56,56H40a8,8,0,0,0,0,16H56a8,8,0,0,0,0-16Zm0,64H40a8,8,0,0,0,0,16H56a8,8,0,0,0,0-16Zm0,64H40a8,8,0,0,0,0,16H56a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M90,64a6,6,0,0,1,6-6H216a6,6,0,0,1,0,12H96A6,6,0,0,1,90,64Zm126,58H96a6,6,0,0,0,0,12H216a6,6,0,0,0,0-12Zm0,64H96a6,6,0,0,0,0,12H216a6,6,0,0,0,0-12ZM56,58H40a6,6,0,0,0,0,12H56a6,6,0,0,0,0-12Zm0,64H40a6,6,0,0,0,0,12H56a6,6,0,0,0,0-12Zm0,64H40a6,6,0,0,0,0,12H56a6,6,0,0,0,0-12Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M92,64a4,4,0,0,1,4-4H216a4,4,0,0,1,0,8H96A4,4,0,0,1,92,64Zm124,60H96a4,4,0,0,0,0,8H216a4,4,0,0,0,0-8Zm0,64H96a4,4,0,0,0,0,8H216a4,4,0,0,0,0-8ZM56,60H40a4,4,0,0,0,0,8H56a4,4,0,0,0,0-8Zm0,64H40a4,4,0,0,0,0,8H56a4,4,0,0,0,0-8Zm0,64H40a4,4,0,0,0,0,8H56a4,4,0,0,0,0-8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M88,64a8,8,0,0,1,8-8H216a8,8,0,0,1,0,16H96A8,8,0,0,1,88,64Zm128,56H96a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Zm0,64H96a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16ZM56,56H40a8,8,0,0,0,0,16H56a8,8,0,0,0,0-16Zm0,64H40a8,8,0,0,0,0,16H56a8,8,0,0,0,0-16Zm0,64H40a8,8,0,0,0,0,16H56a8,8,0,0,0,0-16Z" />
        </>
      )}
    </svg>
  );
}

export function PhListNumbers(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M228,128a12,12,0,0,1-12,12H108a12,12,0,0,1,0-24H216A12,12,0,0,1,228,128ZM108,76H216a12,12,0,0,0,0-24H108a12,12,0,0,0,0,24ZM216,180H108a12,12,0,0,0,0,24H216a12,12,0,0,0,0-24ZM44,71.3V108a12,12,0,0,0,24,0V52a12,12,0,0,0-5.7-10.2,11.9,11.9,0,0,0-11.7-.5l-16,8a12,12,0,0,0,9.4,22ZM75.6,172.5A25.8,25.8,0,0,0,80,158a26,26,0,0,0-50-10.1,12,12,0,0,0,22.2,9.3A1.9,1.9,0,0,1,54,156a2,2,0,0,1,2,2,3,3,0,0,1-.2,1L30.4,192.8A12,12,0,0,0,40,212H68a12,12,0,0,0,0-24H64l11.2-15Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M224,128a8,8,0,0,1-8,8H104a8,8,0,0,1,0-16H216A8,8,0,0,1,224,128ZM104,72H216a8,8,0,0,0,0-16H104a8,8,0,0,0,0,16ZM216,184H104a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16ZM43.6,67.2,48,64.9V108a8,8,0,0,0,16,0V52a7.9,7.9,0,0,0-3.8-6.8,8,8,0,0,0-7.8-.4l-16,8a8,8,0,1,0,7.2,14.4ZM72.2,170.3A21.5,21.5,0,0,0,76,158a22,22,0,0,0-42.3-8.6,8,8,0,0,0,14.8,6.3A5.8,5.8,0,0,1,54,152a6,6,0,0,1,6,6,5.7,5.7,0,0,1-.9,3.2l-25.5,34A8,8,0,0,0,40,208H68a8,8,0,0,0,0-16H56l16-21.4Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M72,170.6,56,192H68a8,8,0,0,1,0,16H40a8,8,0,0,1-6.4-12.8l25.5-34A5.7,5.7,0,0,0,60,158a6,6,0,0,0-6-6,5.8,5.8,0,0,0-5.5,3.7,8,8,0,0,1-14.8-6.3A22,22,0,0,1,76,158a21.5,21.5,0,0,1-3.8,12.3ZM43.6,67.2,48,64.9V108a8,8,0,0,0,16,0V52a7.9,7.9,0,0,0-3.8-6.8,8,8,0,0,0-7.8-.4l-16,8a8,8,0,1,0,7.2,14.4ZM104,72H216a8,8,0,0,0,0-16H104a8,8,0,0,0,0,16ZM216,184H104a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Zm0-64H104a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M222,128a6,6,0,0,1-6,6H104a6,6,0,0,1,0-12H216A6,6,0,0,1,222,128ZM104,70H216a6,6,0,0,0,0-12H104a6,6,0,0,0,0,12ZM216,186H104a6,6,0,0,0,0,12H216a6,6,0,0,0,0-12ZM42.7,65.4,50,61.7V108a6,6,0,0,0,12,0V52a6.1,6.1,0,0,0-2.8-5.1,6.2,6.2,0,0,0-5.9-.3l-16,8a6,6,0,1,0,5.4,10.8ZM70.6,169.2A20,20,0,0,0,54,138a19.9,19.9,0,0,0-18.4,12.2,6,6,0,1,0,11,4.7A8,8,0,0,1,62,158a7.6,7.6,0,0,1-1.3,4.4l-25.5,34a6,6,0,0,0-.6,6.3A6.2,6.2,0,0,0,40,206H68a6,6,0,0,0,0-12H52l18.4-24.6Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M220,128a4,4,0,0,1-4,4H104a4,4,0,0,1,0-8H216A4,4,0,0,1,220,128ZM104,68H216a4,4,0,0,0,0-8H104a4,4,0,0,0,0,8ZM216,188H104a4,4,0,0,0,0,8H216a4,4,0,0,0,0-8ZM41.8,63.6,52,58.5V108a4,4,0,0,0,8,0V52a3.9,3.9,0,0,0-1.9-3.4,4.1,4.1,0,0,0-3.9-.2l-16,8a4,4,0,1,0,3.6,7.2ZM68.9,168.1A18,18,0,0,0,54,140a18,18,0,0,0-16.6,11,4,4,0,0,0,7.4,3.1A10,10,0,0,1,64,158a9.7,9.7,0,0,1-1.7,5.5L36.8,197.6a4,4,0,0,0-.4,4.2A4.2,4.2,0,0,0,40,204H68a4,4,0,0,0,0-8H48l20.8-27.8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M224,128a8,8,0,0,1-8,8H104a8,8,0,0,1,0-16H216A8,8,0,0,1,224,128ZM104,72H216a8,8,0,0,0,0-16H104a8,8,0,0,0,0,16ZM216,184H104a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16ZM43.6,67.2,48,64.9V108a8,8,0,0,0,16,0V52a7.9,7.9,0,0,0-3.8-6.8,8,8,0,0,0-7.8-.4l-16,8a8,8,0,1,0,7.2,14.4ZM72.2,170.3A21.5,21.5,0,0,0,76,158a22,22,0,0,0-42.3-8.6,8,8,0,0,0,14.8,6.3A5.8,5.8,0,0,1,54,152a6,6,0,0,1,6,6,5.7,5.7,0,0,1-.9,3.2l-25.5,34A8,8,0,0,0,40,208H68a8,8,0,0,0,0-16H56l16-21.4Z" />
        </>
      )}
    </svg>
  );
}

export function PhListPlus(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M28,64A12,12,0,0,1,40,52H216a12,12,0,0,1,0,24H40A12,12,0,0,1,28,64Zm12,76H216a12,12,0,0,0,0-24H40a12,12,0,0,0,0,24Zm104,40H40a12,12,0,0,0,0,24H144a12,12,0,0,0,0-24Zm88,0H220V168a12,12,0,0,0-24,0v12H184a12,12,0,0,0,0,24h12v12a12,12,0,0,0,24,0V204h12a12,12,0,0,0,0-24Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M32,64a8,8,0,0,1,8-8H216a8,8,0,0,1,0,16H40A8,8,0,0,1,32,64Zm8,72H216a8,8,0,0,0,0-16H40a8,8,0,0,0,0,16Zm104,48H40a8,8,0,0,0,0,16H144a8,8,0,0,0,0-16Zm88,0H216V168a8,8,0,0,0-16,0v16H184a8,8,0,0,0,0,16h16v16a8,8,0,0,0,16,0V200h16a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M32,64a8,8,0,0,1,8-8H216a8,8,0,0,1,0,16H40A8,8,0,0,1,32,64Zm8,72H216a8,8,0,0,0,0-16H40a8,8,0,0,0,0,16Zm104,48H40a8,8,0,0,0,0,16H144a8,8,0,0,0,0-16Zm88,0H216V168a8,8,0,0,0-16,0v16H184a8,8,0,0,0,0,16h16v16a8,8,0,0,0,16,0V200h16a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M34,64a6,6,0,0,1,6-6H216a6,6,0,0,1,0,12H40A6,6,0,0,1,34,64Zm6,70H216a6,6,0,0,0,0-12H40a6,6,0,0,0,0,12Zm104,52H40a6,6,0,0,0,0,12H144a6,6,0,0,0,0-12Zm88,0H214V168a6,6,0,0,0-12,0v18H184a6,6,0,0,0,0,12h18v18a6,6,0,0,0,12,0V198h18a6,6,0,0,0,0-12Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M36,64a4,4,0,0,1,4-4H216a4,4,0,0,1,0,8H40A4,4,0,0,1,36,64Zm4,68H216a4,4,0,0,0,0-8H40a4,4,0,0,0,0,8Zm104,56H40a4,4,0,0,0,0,8H144a4,4,0,0,0,0-8Zm88,0H212V168a4,4,0,0,0-8,0v20H184a4,4,0,0,0,0,8h20v20a4,4,0,0,0,8,0V196h20a4,4,0,0,0,0-8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M32,64a8,8,0,0,1,8-8H216a8,8,0,0,1,0,16H40A8,8,0,0,1,32,64Zm8,72H216a8,8,0,0,0,0-16H40a8,8,0,0,0,0,16Zm104,48H40a8,8,0,0,0,0,16H144a8,8,0,0,0,0-16Zm88,0H216V168a8,8,0,0,0-16,0v16H184a8,8,0,0,0,0,16h16v16a8,8,0,0,0,16,0V200h16a8,8,0,0,0,0-16Z" />
        </>
      )}
    </svg>
  );
}

export function PhList(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M228,128a12,12,0,0,1-12,12H40a12,12,0,0,1,0-24H216A12,12,0,0,1,228,128ZM40,76H216a12,12,0,0,0,0-24H40a12,12,0,0,0,0,24ZM216,180H40a12,12,0,0,0,0,24H216a12,12,0,0,0,0-24Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M224,128a8,8,0,0,1-8,8H40a8,8,0,0,1,0-16H216A8,8,0,0,1,224,128ZM40,72H216a8,8,0,0,0,0-16H40a8,8,0,0,0,0,16ZM216,184H40a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M224,128a8,8,0,0,1-8,8H40a8,8,0,0,1,0-16H216A8,8,0,0,1,224,128ZM40,72H216a8,8,0,0,0,0-16H40a8,8,0,0,0,0,16ZM216,184H40a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M222,128a6,6,0,0,1-6,6H40a6,6,0,0,1,0-12H216A6,6,0,0,1,222,128ZM40,70H216a6,6,0,0,0,0-12H40a6,6,0,0,0,0,12ZM216,186H40a6,6,0,0,0,0,12H216a6,6,0,0,0,0-12Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M220,128a4,4,0,0,1-4,4H40a4,4,0,0,1,0-8H216A4,4,0,0,1,220,128ZM40,68H216a4,4,0,0,0,0-8H40a4,4,0,0,0,0,8ZM216,188H40a4,4,0,0,0,0,8H216a4,4,0,0,0,0-8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M224,128a8,8,0,0,1-8,8H40a8,8,0,0,1,0-16H216A8,8,0,0,1,224,128ZM40,72H216a8,8,0,0,0,0-16H40a8,8,0,0,0,0,16ZM216,184H40a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Z" />
        </>
      )}
    </svg>
  );
}

export function PhLockKeyOpen(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M116,173.7V180a12,12,0,0,0,24,0v-6.3a32,32,0,1,0-24,0ZM128,136a8,8,0,1,1-8,8A8,8,0,0,1,128,136Zm80-60H104V52a24,24,0,0,1,47.9-2.7,12,12,0,0,0,23.8-2.6A48,48,0,0,0,80,52V76H48A20.1,20.1,0,0,0,28,96V208a20.1,20.1,0,0,0,20,20H208a20.1,20.1,0,0,0,20-20V96A20.1,20.1,0,0,0,208,76Zm-4,128H52V100H204Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M208,88H48a8,8,0,0,0-8,8V208a8,8,0,0,0,8,8H208a8,8,0,0,0,8-8V96A8,8,0,0,0,208,88Zm-80,72a20,20,0,1,1,20-20A20.1,20.1,0,0,1,128,160Z" />
          </g>
          <path d="M128,112a28,28,0,0,0-8,54.8V184a8,8,0,0,0,16,0V166.8a28,28,0,0,0-8-54.8Zm0,40a12,12,0,1,1,12-12A12,12,0,0,1,128,152Z" />
          <path d="M208,80H100V52a28,28,0,0,1,56,0,8,8,0,0,0,16,0,44,44,0,0,0-88,0V80H48A16,16,0,0,0,32,96V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V96A16,16,0,0,0,208,80Zm0,128H48V96H208V208Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M140,140a12,12,0,1,1-12-12A12,12,0,0,1,140,140Zm84-44V208a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V96A16,16,0,0,1,48,80H84V52a44,44,0,0,1,88,0,8,8,0,0,1-16,0,28,28,0,0,0-56,0V80H208A16,16,0,0,1,224,96Zm-68,44a28,28,0,1,0-36,26.8V184a8,8,0,0,0,16,0V166.8A27.9,27.9,0,0,0,156,140Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,114a26,26,0,0,0-6,51.3V184a6,6,0,0,0,12,0V165.3a26,26,0,0,0-6-51.3Zm0,40a14,14,0,1,1,14-14A14,14,0,0,1,128,154Zm80-72H98V52a30,30,0,0,1,60,0,6,6,0,0,0,12,0,42,42,0,0,0-84,0V82H48A14,14,0,0,0,34,96V208a14,14,0,0,0,14,14H208a14,14,0,0,0,14-14V96A14,14,0,0,0,208,82Zm2,126a2,2,0,0,1-2,2H48a2,2,0,0,1-2-2V96a2,2,0,0,1,2-2H208a2,2,0,0,1,2,2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,116a24,24,0,0,0-4,47.7V184a4,4,0,0,0,8,0V163.7a24,24,0,0,0-4-47.7Zm0,40a16,16,0,1,1,16-16A16,16,0,0,1,128,156Zm80-72H96V52a32,32,0,0,1,64,0,4,4,0,0,0,8,0,40,40,0,0,0-80,0V84H48A12,12,0,0,0,36,96V208a12,12,0,0,0,12,12H208a12,12,0,0,0,12-12V96A12,12,0,0,0,208,84Zm4,124a4,4,0,0,1-4,4H48a4,4,0,0,1-4-4V96a4,4,0,0,1,4-4H208a4,4,0,0,1,4,4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,112a28,28,0,0,0-8,54.8V184a8,8,0,0,0,16,0V166.8a28,28,0,0,0-8-54.8Zm0,40a12,12,0,1,1,12-12A12,12,0,0,1,128,152Zm80-72H100V52a28,28,0,0,1,56,0,8,8,0,0,0,16,0,44,44,0,0,0-88,0V80H48A16,16,0,0,0,32,96V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V96A16,16,0,0,0,208,80Zm0,128H48V96H208V208Z" />
        </>
      )}
    </svg>
  );
}

export function PhLockKey(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M208,76H176V52a48,48,0,0,0-96,0V76H48A20.1,20.1,0,0,0,28,96V208a20.1,20.1,0,0,0,20,20H208a20.1,20.1,0,0,0,20-20V96A20.1,20.1,0,0,0,208,76ZM104,52a24,24,0,0,1,48,0V76H104ZM204,204H52V100H204Zm-76-92a32,32,0,0,0-12,61.7V180a12,12,0,0,0,24,0v-6.3A32,32,0,0,0,128,112Zm0,24a8,8,0,1,1-8,8A8,8,0,0,1,128,136Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M208,88H48a8,8,0,0,0-8,8V208a8,8,0,0,0,8,8H208a8,8,0,0,0,8-8V96A8,8,0,0,0,208,88Zm-80,72a20,20,0,1,1,20-20A20.1,20.1,0,0,1,128,160Z" />
          </g>
          <path d="M128,112a28,28,0,0,0-8,54.8V184a8,8,0,0,0,16,0V166.8a28,28,0,0,0-8-54.8Zm0,40a12,12,0,1,1,12-12A12,12,0,0,1,128,152Z" />
          <path d="M208,80H172V52a44,44,0,0,0-88,0V80H48A16,16,0,0,0,32,96V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V96A16,16,0,0,0,208,80ZM100,52a28,28,0,0,1,56,0V80H100ZM208,208H48V96H208V208Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M140,140a12,12,0,1,1-12-12A12,12,0,0,1,140,140Zm84-44V208a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V96A16,16,0,0,1,48,80H84V52a44,44,0,0,1,88,0V80h36A16,16,0,0,1,224,96Zm-68,44a28,28,0,1,0-36,26.8V184a8,8,0,0,0,16,0V166.8A27.9,27.9,0,0,0,156,140Zm0-88a28,28,0,0,0-56,0V80h56Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,114a26,26,0,0,0-6,51.3V184a6,6,0,0,0,12,0V165.3a26,26,0,0,0-6-51.3Zm0,40a14,14,0,1,1,14-14A14,14,0,0,1,128,154Zm80-72H170V52a42,42,0,0,0-84,0V82H48A14,14,0,0,0,34,96V208a14,14,0,0,0,14,14H208a14,14,0,0,0,14-14V96A14,14,0,0,0,208,82ZM98,52a30,30,0,0,1,60,0V82H98ZM210,208a2,2,0,0,1-2,2H48a2,2,0,0,1-2-2V96a2,2,0,0,1,2-2H208a2,2,0,0,1,2,2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,116a24,24,0,0,0-4,47.7V184a4,4,0,0,0,8,0V163.7a24,24,0,0,0-4-47.7Zm0,40a16,16,0,1,1,16-16A16,16,0,0,1,128,156Zm80-72H168V52a40,40,0,0,0-80,0V84H48A12,12,0,0,0,36,96V208a12,12,0,0,0,12,12H208a12,12,0,0,0,12-12V96A12,12,0,0,0,208,84ZM96,52a32,32,0,0,1,64,0V84H96ZM212,208a4,4,0,0,1-4,4H48a4,4,0,0,1-4-4V96a4,4,0,0,1,4-4H208a4,4,0,0,1,4,4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,112a28,28,0,0,0-8,54.8V184a8,8,0,0,0,16,0V166.8a28,28,0,0,0-8-54.8Zm0,40a12,12,0,1,1,12-12A12,12,0,0,1,128,152Zm80-72H172V52a44,44,0,0,0-88,0V80H48A16,16,0,0,0,32,96V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V96A16,16,0,0,0,208,80ZM100,52a28,28,0,0,1,56,0V80H100ZM208,208H48V96H208V208Z" />
        </>
      )}
    </svg>
  );
}

export function PhLockLaminatedOpen(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M208,76H104V52a24,24,0,0,1,47.9-2.7,12,12,0,0,0,23.8-2.6A48,48,0,0,0,80,52V76H48A20.1,20.1,0,0,0,28,96V208a20.1,20.1,0,0,0,20,20H208a20.1,20.1,0,0,0,20-20V96A20.1,20.1,0,0,0,208,76ZM52,144H204v16H52Zm152-44v20H52V100ZM52,204V184H204v20Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect x="40" y="88" width="176" height="128" rx="8" />
          </g>
          <path d="M208,80H100V52a28,28,0,0,1,56,0,8,8,0,0,0,16,0,44,44,0,0,0-88,0V80H48A16,16,0,0,0,32,96V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V96A16,16,0,0,0,208,80ZM48,128H208v16H48Zm0,32H208v16H48ZM208,96v16H48V96Zm0,112H48V192H208v16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M208,80H100V52a28,28,0,0,1,56,0,8,8,0,0,0,16,0,44,44,0,0,0-88,0V80H48A16,16,0,0,0,32,96V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V96A16,16,0,0,0,208,80ZM184,192H72a8,8,0,0,1,0-16H184a8,8,0,0,1,0,16Zm0-32H72a8,8,0,0,1,0-16H184a8,8,0,0,1,0,16Zm0-32H72a8,8,0,0,1,0-16H184a8,8,0,0,1,0,16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M208,82H98V52a30,30,0,0,1,60,0,6,6,0,0,0,12,0,42,42,0,0,0-84,0V82H48A14,14,0,0,0,34,96V208a14,14,0,0,0,14,14H208a14,14,0,0,0,14-14V96A14,14,0,0,0,208,82ZM46,126H210v20H46Zm0,32H210v20H46Zm2-64H208a2,2,0,0,1,2,2v18H46V96A2,2,0,0,1,48,94ZM208,210H48a2,2,0,0,1-2-2V190H210v18A2,2,0,0,1,208,210Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M208,84H96V52a32,32,0,0,1,64,0,4,4,0,0,0,8,0,40,40,0,0,0-80,0V84H48A12,12,0,0,0,36,96V208a12,12,0,0,0,12,12H208a12,12,0,0,0,12-12V96A12,12,0,0,0,208,84ZM44,124H212v24H44Zm0,32H212v24H44Zm4-64H208a4,4,0,0,1,4,4v20H44V96A4,4,0,0,1,48,92ZM208,212H48a4,4,0,0,1-4-4V188H212v20A4,4,0,0,1,208,212Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M208,80H100V52a28,28,0,0,1,56,0,8,8,0,0,0,16,0,44,44,0,0,0-88,0V80H48A16,16,0,0,0,32,96V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V96A16,16,0,0,0,208,80ZM48,128H208v16H48Zm0,32H208v16H48ZM208,96v16H48V96Zm0,112H48V192H208v16Z" />
        </>
      )}
    </svg>
  );
}

export function PhLockLaminated(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M208,76H176V52a48,48,0,0,0-96,0V76H48A20.1,20.1,0,0,0,28,96V208a20.1,20.1,0,0,0,20,20H208a20.1,20.1,0,0,0,20-20V96A20.1,20.1,0,0,0,208,76ZM52,144H204v16H52Zm52-92a24,24,0,0,1,48,0V76H104Zm100,48v20H52V100ZM52,204V184H204v20Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect x="40" y="88" width="176" height="128" rx="8" />
          </g>
          <path d="M208,80H172V52a44,44,0,0,0-88,0V80H48A16,16,0,0,0,32,96V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V96A16,16,0,0,0,208,80ZM48,128H208v16H48Zm0,32H208v16H48ZM100,52a28,28,0,0,1,56,0V80H100ZM208,96v16H48V96Zm0,112H48V192H208v16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M208,80H172V52a44,44,0,0,0-88,0V80H48A16,16,0,0,0,32,96V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V96A16,16,0,0,0,208,80ZM100,52a28,28,0,0,1,56,0V80H100Zm84,140H72a8,8,0,0,1,0-16H184a8,8,0,0,1,0,16Zm0-32H72a8,8,0,0,1,0-16H184a8,8,0,0,1,0,16Zm0-32H72a8,8,0,0,1,0-16H184a8,8,0,0,1,0,16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M208,82H170V52a42,42,0,0,0-84,0V82H48A14,14,0,0,0,34,96V208a14,14,0,0,0,14,14H208a14,14,0,0,0,14-14V96A14,14,0,0,0,208,82ZM46,126H210v20H46Zm0,32H210v20H46ZM98,52a30,30,0,0,1,60,0V82H98ZM48,94H208a2,2,0,0,1,2,2v18H46V96A2,2,0,0,1,48,94ZM208,210H48a2,2,0,0,1-2-2V190H210v18A2,2,0,0,1,208,210Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M208,84H168V52a40,40,0,0,0-80,0V84H48A12,12,0,0,0,36,96V208a12,12,0,0,0,12,12H208a12,12,0,0,0,12-12V96A12,12,0,0,0,208,84ZM44,124H212v24H44Zm0,32H212v24H44ZM96,52a32,32,0,0,1,64,0V84H96ZM48,92H208a4,4,0,0,1,4,4v20H44V96A4,4,0,0,1,48,92ZM208,212H48a4,4,0,0,1-4-4V188H212v20A4,4,0,0,1,208,212Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M208,80H172V52a44,44,0,0,0-88,0V80H48A16,16,0,0,0,32,96V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V96A16,16,0,0,0,208,80ZM48,128H208v16H48Zm0,32H208v16H48ZM100,52a28,28,0,0,1,56,0V80H100ZM208,96v16H48V96Zm0,112H48V192H208v16Z" />
        </>
      )}
    </svg>
  );
}

export function PhLockOpen(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M208,76H104V52a24,24,0,0,1,47.9-2.7,12,12,0,0,0,23.8-2.6A48,48,0,0,0,80,52V76H48A20.1,20.1,0,0,0,28,96V208a20.1,20.1,0,0,0,20,20H208a20.1,20.1,0,0,0,20-20V96A20.1,20.1,0,0,0,208,76Zm-4,128H52V100H204Zm-92-52a16,16,0,1,1,16,16A16,16,0,0,1,112,152Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect x="40" y="88" width="176" height="128" rx="8" />
          </g>
          <circle cx="128" cy="152" r="12" />
          <path d="M208,80H100V52a28,28,0,0,1,56,0,8,8,0,0,0,16,0,44,44,0,0,0-88,0V80H48A16,16,0,0,0,32,96V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V96A16,16,0,0,0,208,80Zm0,128H48V96H208V208Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M208,80H100V52a28,28,0,0,1,56,0,8,8,0,0,0,16,0,44,44,0,0,0-88,0V80H48A16,16,0,0,0,32,96V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V96A16,16,0,0,0,208,80Zm-80,84a12,12,0,1,1,12-12A12,12,0,0,1,128,164Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M208,82H98V52a30,30,0,0,1,60,0,6,6,0,0,0,12,0,42,42,0,0,0-84,0V82H48A14,14,0,0,0,34,96V208a14,14,0,0,0,14,14H208a14,14,0,0,0,14-14V96A14,14,0,0,0,208,82Zm2,126a2,2,0,0,1-2,2H48a2,2,0,0,1-2-2V96a2,2,0,0,1,2-2H208a2,2,0,0,1,2,2Zm-72-56a10,10,0,1,1-10-10A10,10,0,0,1,138,152Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M208,84H96V52a32,32,0,0,1,64,0,4,4,0,0,0,8,0,40,40,0,0,0-80,0V84H48A12,12,0,0,0,36,96V208a12,12,0,0,0,12,12H208a12,12,0,0,0,12-12V96A12,12,0,0,0,208,84Zm4,124a4,4,0,0,1-4,4H48a4,4,0,0,1-4-4V96a4,4,0,0,1,4-4H208a4,4,0,0,1,4,4Zm-76-56a8,8,0,1,1-8-8A8,8,0,0,1,136,152Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M208,80H100V52a28,28,0,0,1,56,0,8,8,0,0,0,16,0,44,44,0,0,0-88,0V80H48A16,16,0,0,0,32,96V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V96A16,16,0,0,0,208,80Zm0,128H48V96H208V208Zm-68-56a12,12,0,1,1-12-12A12,12,0,0,1,140,152Z" />
        </>
      )}
    </svg>
  );
}

export function PhLockSimpleOpen(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M208,76H104V52a24,24,0,0,1,47.9-2.7,12,12,0,0,0,23.8-2.6A48,48,0,0,0,80,52V76H48A20.1,20.1,0,0,0,28,96V208a20.1,20.1,0,0,0,20,20H208a20.1,20.1,0,0,0,20-20V96A20.1,20.1,0,0,0,208,76Zm-4,128H52V100H204Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect x="40" y="88" width="176" height="128" rx="8" />
          </g>
          <path d="M208,80H100V52a28,28,0,0,1,56,0,8,8,0,0,0,16,0,44,44,0,0,0-88,0V80H48A16,16,0,0,0,32,96V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V96A16,16,0,0,0,208,80Zm0,128H48V96H208V208Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M224,96V208a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V96A16,16,0,0,1,48,80H84V52a44,44,0,0,1,88,0,8,8,0,0,1-16,0,28,28,0,0,0-56,0V80H208A16,16,0,0,1,224,96Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M208,82H98V52a30,30,0,0,1,60,0,6,6,0,0,0,12,0,42,42,0,0,0-84,0V82H48A14,14,0,0,0,34,96V208a14,14,0,0,0,14,14H208a14,14,0,0,0,14-14V96A14,14,0,0,0,208,82Zm2,126a2,2,0,0,1-2,2H48a2,2,0,0,1-2-2V96a2,2,0,0,1,2-2H208a2,2,0,0,1,2,2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M208,84H96V52a32,32,0,0,1,64,0,4,4,0,0,0,8,0,40,40,0,0,0-80,0V84H48A12,12,0,0,0,36,96V208a12,12,0,0,0,12,12H208a12,12,0,0,0,12-12V96A12,12,0,0,0,208,84Zm4,124a4,4,0,0,1-4,4H48a4,4,0,0,1-4-4V96a4,4,0,0,1,4-4H208a4,4,0,0,1,4,4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M208,80H100V52a28,28,0,0,1,56,0,8,8,0,0,0,16,0,44,44,0,0,0-88,0V80H48A16,16,0,0,0,32,96V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V96A16,16,0,0,0,208,80Zm0,128H48V96H208V208Z" />
        </>
      )}
    </svg>
  );
}

export function PhLockSimple(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M208,76H176V52a48,48,0,0,0-96,0V76H48A20.1,20.1,0,0,0,28,96V208a20.1,20.1,0,0,0,20,20H208a20.1,20.1,0,0,0,20-20V96A20.1,20.1,0,0,0,208,76ZM104,52a24,24,0,0,1,48,0V76H104ZM204,204H52V100H204Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect x="40" y="88" width="176" height="128" rx="8" />
          </g>
          <path d="M208,80H172V52a44,44,0,0,0-88,0V80H48A16,16,0,0,0,32,96V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V96A16,16,0,0,0,208,80ZM100,52a28,28,0,0,1,56,0V80H100ZM208,208H48V96H208V208Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M208,80H172V52a44,44,0,0,0-88,0V80H48A16,16,0,0,0,32,96V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V96A16,16,0,0,0,208,80ZM100,52a28,28,0,0,1,56,0V80H100Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M208,82H170V52a42,42,0,0,0-84,0V82H48A14,14,0,0,0,34,96V208a14,14,0,0,0,14,14H208a14,14,0,0,0,14-14V96A14,14,0,0,0,208,82ZM98,52a30,30,0,0,1,60,0V82H98ZM210,208a2,2,0,0,1-2,2H48a2,2,0,0,1-2-2V96a2,2,0,0,1,2-2H208a2,2,0,0,1,2,2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M208,84H168V52a40,40,0,0,0-80,0V84H48A12,12,0,0,0,36,96V208a12,12,0,0,0,12,12H208a12,12,0,0,0,12-12V96A12,12,0,0,0,208,84ZM96,52a32,32,0,0,1,64,0V84H96ZM212,208a4,4,0,0,1-4,4H48a4,4,0,0,1-4-4V96a4,4,0,0,1,4-4H208a4,4,0,0,1,4,4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M208,80H172V52a44,44,0,0,0-88,0V80H48A16,16,0,0,0,32,96V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V96A16,16,0,0,0,208,80ZM100,52a28,28,0,0,1,56,0V80H100ZM208,208H48V96H208V208Z" />
        </>
      )}
    </svg>
  );
}

export function PhLock(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M208,76H176V52a48,48,0,0,0-96,0V76H48A20.1,20.1,0,0,0,28,96V208a20.1,20.1,0,0,0,20,20H208a20.1,20.1,0,0,0,20-20V96A20.1,20.1,0,0,0,208,76ZM104,52a24,24,0,0,1,48,0V76H104ZM204,204H52V100H204Zm-60-52a16,16,0,1,1-16-16A16,16,0,0,1,144,152Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect x="40" y="88" width="176" height="128" rx="8" />
          </g>
          <circle cx="128" cy="152" r="12" />
          <path d="M208,80H172V52a44,44,0,0,0-88,0V80H48A16,16,0,0,0,32,96V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V96A16,16,0,0,0,208,80ZM100,52a28,28,0,0,1,56,0V80H100ZM208,208H48V96H208V208Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M208,80H172V52a44,44,0,0,0-88,0V80H48A16,16,0,0,0,32,96V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V96A16,16,0,0,0,208,80Zm-80,84a12,12,0,1,1,12-12A12,12,0,0,1,128,164Zm28-84H100V52a28,28,0,0,1,56,0Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M208,82H170V52a42,42,0,0,0-84,0V82H48A14,14,0,0,0,34,96V208a14,14,0,0,0,14,14H208a14,14,0,0,0,14-14V96A14,14,0,0,0,208,82ZM98,52a30,30,0,0,1,60,0V82H98ZM210,208a2,2,0,0,1-2,2H48a2,2,0,0,1-2-2V96a2,2,0,0,1,2-2H208a2,2,0,0,1,2,2Zm-72-56a10,10,0,1,1-10-10A10,10,0,0,1,138,152Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M208,84H168V52a40,40,0,0,0-80,0V84H48A12,12,0,0,0,36,96V208a12,12,0,0,0,12,12H208a12,12,0,0,0,12-12V96A12,12,0,0,0,208,84ZM96,52a32,32,0,0,1,64,0V84H96ZM212,208a4,4,0,0,1-4,4H48a4,4,0,0,1-4-4V96a4,4,0,0,1,4-4H208a4,4,0,0,1,4,4Zm-76-56a8,8,0,1,1-8-8A8,8,0,0,1,136,152Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M208,80H172V52a44,44,0,0,0-88,0V80H48A16,16,0,0,0,32,96V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V96A16,16,0,0,0,208,80ZM100,52a28,28,0,0,1,56,0V80H100ZM208,208H48V96H208V208Zm-68-56a12,12,0,1,1-12-12A12,12,0,0,1,140,152Z" />
        </>
      )}
    </svg>
  );
}

export function PhMagicWand(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M256,152a12,12,0,0,1-12,12H232v12a12,12,0,0,1-24,0V164H196a12,12,0,0,1,0-24h12V128a12,12,0,0,1,24,0v12h12A12,12,0,0,1,256,152ZM60,68h8v8a12,12,0,0,0,24,0V68h8a12,12,0,0,0,0-24H92V36a12,12,0,0,0-24,0v8H60a12,12,0,0,0,0,24ZM180,192h-4v-4a12,12,0,0,0-24,0v4h-4a12,12,0,0,0,0,24h4v4a12,12,0,0,0,24,0v-4h4a12,12,0,0,0,0-24Zm4.5-71.5L78.1,226.8a19.8,19.8,0,0,1-28.2,0L29.2,206.1a19.8,19.8,0,0,1,0-28.2L177.9,29.2a19.9,19.9,0,0,1,28.2,0l20.7,20.7a19.8,19.8,0,0,1,0,28.2l-42.3,42.4ZM161,80l15,15,31-31L192,49Zm-2,32L144,97,49,192l15,15Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M176,112,69.7,218.3a8,8,0,0,1-11.4,0L37.7,197.7a8,8,0,0,1,0-11.4L144,80Z"
            opacity="0.2"
          />
          <path d="M248,152a8,8,0,0,1-8,8H224v16a8,8,0,0,1-16,0V160H192a8,8,0,0,1,0-16h16V128a8,8,0,0,1,16,0v16h16A8,8,0,0,1,248,152ZM64,68H76V80a8,8,0,0,0,16,0V68h12a8,8,0,0,0,0-16H92V40a8,8,0,0,0-16,0V52H64a8,8,0,0,0,0,16ZM184,192h-8v-8a8,8,0,0,0-16,0v8h-8a8,8,0,0,0,0,16h8v8a8,8,0,0,0,16,0v-8h8a8,8,0,0,0,0-16Zm-2.3-74.3L75.3,224a15.9,15.9,0,0,1-22.6,0L32,203.3a15.9,15.9,0,0,1,0-22.6L180.7,32a16.1,16.1,0,0,1,22.6,0L224,52.7a15.9,15.9,0,0,1,0,22.6l-42.3,42.4ZM155.3,80,176,100.7,212.7,64h0L192,43.3Zm9.4,32L144,91.3,43.3,192,64,212.7Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M56,60a8,8,0,0,1,8-8H76V40a8,8,0,0,1,16,0V52h12a8,8,0,0,1,0,16H92V80a8,8,0,0,1-16,0V68H64A8,8,0,0,1,56,60Zm184,84H224V128a8,8,0,0,0-16,0v16H192a8,8,0,0,0,0,16h16v16a8,8,0,0,0,16,0V160h16a8,8,0,0,0,0-16Zm-58.3-26.3L75.3,224a15.9,15.9,0,0,1-22.6,0L32,203.3a15.9,15.9,0,0,1,0-22.6L180.7,32a16.1,16.1,0,0,1,22.6,0L224,52.7a15.9,15.9,0,0,1,0,22.6l-42.3,42.4Zm-5.7-17L212.7,64h0L192,43.3,155.3,80Zm8,91.3h-8v-8a8,8,0,0,0-16,0v8h-8a8,8,0,0,0,0,16h8v8a8,8,0,0,0,16,0v-8h8a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M246,152a6,6,0,0,1-6,6H222v18a6,6,0,0,1-12,0V158H192a6,6,0,0,1,0-12h18V128a6,6,0,0,1,12,0v18h18A6,6,0,0,1,246,152ZM64,66H78V80a6,6,0,0,0,12,0V66h14a6,6,0,0,0,0-12H90V40a6,6,0,0,0-12,0V54H64a6,6,0,0,0,0,12ZM184,194H174V184a6,6,0,0,0-12,0v10H152a6,6,0,0,0,0,12h10v10a6,6,0,0,0,12,0V206h10a6,6,0,0,0,0-12Zm-3.8-77.8L73.9,222.6a14,14,0,0,1-19.8,0L33.4,201.9a14,14,0,0,1,0-19.8L182.1,33.4a14.1,14.1,0,0,1,19.8,0l20.7,20.7a14,14,0,0,1,0,19.8l-42.4,42.3ZM152.5,80,176,103.5l38.1-38.1h0a1.9,1.9,0,0,0,0-2.8L193.4,41.9a1.9,1.9,0,0,0-2.8,0Zm15,32L144,88.5,41.9,190.6a1.9,1.9,0,0,0,0,2.8l20.7,20.7a1.9,1.9,0,0,0,2.8,0Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M244,152a4,4,0,0,1-4,4H220v20a4,4,0,0,1-8,0V156H192a4,4,0,0,1,0-8h20V128a4,4,0,0,1,8,0v20h20A4,4,0,0,1,244,152ZM64,64H80V80a4,4,0,0,0,8,0V64h16a4,4,0,0,0,0-8H88V40a4,4,0,0,0-8,0V56H64a4,4,0,0,0,0,8ZM184,196H172V184a4,4,0,0,0-8,0v12H152a4,4,0,0,0,0,8h12v12a4,4,0,0,0,8,0V204h12a4,4,0,0,0,0-8Zm-5.2-81.2L72.5,221.2a12.1,12.1,0,0,1-17,0L34.8,200.5a12.1,12.1,0,0,1,0-17L183.5,34.8a12.2,12.2,0,0,1,17,0l20.7,20.7a12.1,12.1,0,0,1,0,17l-42.4,42.3ZM149.7,80,176,106.3l39.5-39.5h0a3.9,3.9,0,0,0,0-5.6L194.8,40.5a3.9,3.9,0,0,0-5.6,0Zm20.6,32L144,85.7,40.5,189.2a3.9,3.9,0,0,0,0,5.6l20.7,20.7a3.9,3.9,0,0,0,5.6,0Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M248,152a8,8,0,0,1-8,8H224v16a8,8,0,0,1-16,0V160H192a8,8,0,0,1,0-16h16V128a8,8,0,0,1,16,0v16h16A8,8,0,0,1,248,152ZM64,68H76V80a8,8,0,0,0,16,0V68h12a8,8,0,0,0,0-16H92V40a8,8,0,0,0-16,0V52H64a8,8,0,0,0,0,16ZM184,192h-8v-8a8,8,0,0,0-16,0v8h-8a8,8,0,0,0,0,16h8v8a8,8,0,0,0,16,0v-8h8a8,8,0,0,0,0-16Zm-2.3-74.3L75.3,224a15.9,15.9,0,0,1-22.6,0L32,203.3a15.9,15.9,0,0,1,0-22.6L180.7,32a16.1,16.1,0,0,1,22.6,0L224,52.7a15.9,15.9,0,0,1,0,22.6l-42.3,42.4ZM155.3,80,176,100.7,212.7,64h0L192,43.3Zm9.4,32L144,91.3,43.3,192,64,212.7Z" />
        </>
      )}
    </svg>
  );
}

export function PhMagnetStraight(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M200,36H160a20.1,20.1,0,0,0-20,20v88a12,12,0,0,1-24,0V56A20.1,20.1,0,0,0,96,36H56A20.1,20.1,0,0,0,36,56v88a92,92,0,0,0,92,92h.7c50.3-.4,91.3-42.1,91.3-93V56A20.1,20.1,0,0,0,200,36Zm-4,24V84H164V60ZM92,60V84H60V60Zm36.5,152H128a67.9,67.9,0,0,1-68-68V108H92v36a36,36,0,0,0,72,0V108h32v35C196,180.8,165.7,211.7,128.5,212Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M104,56V96H48V56a8,8,0,0,1,8-8H96A8,8,0,0,1,104,56Zm96-8H160a8,8,0,0,0-8,8V96h56V56A8,8,0,0,0,200,48Z"
            opacity="0.2"
          />
          <path d="M200,40H160a16,16,0,0,0-16,16v88a16,16,0,0,1-32,0V56A16,16,0,0,0,96,40H56A16,16,0,0,0,40,56v88a87.9,87.9,0,0,0,88,88h.7c48.1-.4,87.3-40.3,87.3-89V56A16,16,0,0,0,200,40Zm0,16V88H160V56ZM96,56V88H56V56Zm32.5,160H128a72,72,0,0,1-72-72V104H96v40a32,32,0,0,0,64,0V104h40v39C200,183,167.9,215.7,128.5,216Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M200,40H160a16,16,0,0,0-16,16v88a16,16,0,0,1-32,0V56A16,16,0,0,0,96,40H56A16,16,0,0,0,40,56v88a87.9,87.9,0,0,0,88,88h.7c48.1-.4,87.3-40.3,87.3-89V56A16,16,0,0,0,200,40ZM96,96H56V56H96Zm104,0H160V56h40Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M200,42H160a14,14,0,0,0-14,14v88a18,18,0,0,1-36,0V56A14,14,0,0,0,96,42H56A14,14,0,0,0,42,56v88a86,86,0,0,0,86,86h.7c47-.4,85.3-39.4,85.3-87V56A14,14,0,0,0,200,42ZM160,54h40a2,2,0,0,1,2,2V90H158V56A2,2,0,0,1,160,54ZM56,54H96a2,2,0,0,1,2,2V90H54V56A2,2,0,0,1,56,54Zm72.6,164H128a74.1,74.1,0,0,1-74-74V102H98v42a30,30,0,0,0,60,0V102h44v41C202,184.1,169.1,217.7,128.6,218Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M200,44H160a12,12,0,0,0-12,12v88a20,20,0,0,1-40,0V56A12,12,0,0,0,96,44H56A12,12,0,0,0,44,56v88a84,84,0,0,0,84,84h.6c46-.3,83.4-38.5,83.4-85V56A12,12,0,0,0,200,44Zm-40,8h40a4,4,0,0,1,4,4V92H156V56A4,4,0,0,1,160,52ZM56,52H96a4,4,0,0,1,4,4V92H52V56A4,4,0,0,1,56,52Zm72.6,168A76.1,76.1,0,0,1,52,144V100h48v44a28,28,0,0,0,56,0V100h48v43C204,185.1,170.2,219.7,128.6,220Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M200,40H160a16,16,0,0,0-16,16v88a16,16,0,0,1-32,0V56A16,16,0,0,0,96,40H56A16,16,0,0,0,40,56v88a87.9,87.9,0,0,0,88,88h.7c48.1-.4,87.3-40.3,87.3-89V56A16,16,0,0,0,200,40Zm0,16V88H160V56ZM96,56V88H56V56Zm32.5,160H128a72,72,0,0,1-72-72V104H96v40a32,32,0,0,0,64,0V104h40v39C200,183,167.9,215.7,128.5,216Z" />
        </>
      )}
    </svg>
  );
}

export function PhMagnet(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M209.8,47.4A91.3,91.3,0,0,0,144.6,20h-.3A91.5,91.5,0,0,0,79.2,47L13.1,113.9a20,20,0,0,0,0,28.2l28.7,28.7a20,20,0,0,0,14.3,5.8,19.8,19.8,0,0,0,14.1-6l65.6-67.1a12.1,12.1,0,0,1,16.8-.2,12.3,12.3,0,0,1,3.5,8.4,12.9,12.9,0,0,1-3.7,9.1L85.5,185.7a19.9,19.9,0,0,0-.2,28.5l28.6,28.7a19.9,19.9,0,0,0,14.2,5.8,20.1,20.1,0,0,0,14-5.7l66.4-65.2h.1C244.6,141.8,245.1,83.3,209.8,47.4ZM55.9,150.9l-23-23L54,106.6l22.9,22.9Zm72.2,72.2-23-23,21.5-20.9,22.9,22.9Zm63.6-62.3-25.1,24.5-22.7-22.8L169.2,138v-.2a36.6,36.6,0,0,0,10.8-26.3,35.3,35.3,0,0,0-10.9-25.4,36.2,36.2,0,0,0-50.4.4h-.1L93.6,112.3,70.9,89.5,96.2,63.9A67.6,67.6,0,0,1,144.3,44h.2a67.6,67.6,0,0,1,48.2,20.3C218.8,90.8,218.3,134,191.7,160.8Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M166.6,202.2l-32.9,32.3a8.1,8.1,0,0,1-11.3-.1L93.7,205.7a8.1,8.1,0,0,1,.1-11.4l33-31.9ZM54,89.6,21.6,122.3a8,8,0,0,0,0,11.3l28.7,28.7a8.1,8.1,0,0,0,11.4-.1l32-32.8Z"
            opacity="0.2"
          />
          <path d="M207,50.2A87.7,87.7,0,0,0,144.6,24h-.3A87.3,87.3,0,0,0,82,49.8L48.3,83.9h0L15.9,116.7a16.1,16.1,0,0,0,.1,22.6l28.6,28.6a15.6,15.6,0,0,0,11.3,4.7H56a15.8,15.8,0,0,0,11.4-4.8l32-32.8h0L133,100.7a16.1,16.1,0,0,1,22.4-.2,15.7,15.7,0,0,1,4.7,11.2,17,17,0,0,1-4.9,12l-34.1,33h0L88.3,188.6a15.9,15.9,0,0,0-.2,22.8l28.6,28.7a16.3,16.3,0,0,0,22.6.1l32.9-32.4h0l33.6-32.9C240.2,140.5,240.8,84.6,207,50.2ZM55.9,156.6,27.3,128,54,100.9l28.5,28.5Zm72.2,72.1L99.4,200.1l27.3-26.5,28.5,28.5Zm38.5-37.8-28.4-28.4,28.2-27.4h.1a32.8,32.8,0,0,0,9.6-23.5,31.6,31.6,0,0,0-31.8-31.4,31.9,31.9,0,0,0-22.7,9.3h0L93.7,118,65.2,89.5,93.3,61.1a71.8,71.8,0,0,1,51-21.1h.2a71.7,71.7,0,0,1,51.1,21.5c27.6,28,27.1,73.9-1.1,102.1Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M207,50.2A87.7,87.7,0,0,0,144.6,24h-.3A87.3,87.3,0,0,0,82,49.8L48.3,83.9h0L15.9,116.7a16.1,16.1,0,0,0,.1,22.6l28.6,28.6a15.6,15.6,0,0,0,11.3,4.7H56a15.8,15.8,0,0,0,11.4-4.8l32-32.8h0L133,100.7a16.1,16.1,0,0,1,22.4-.2,15.7,15.7,0,0,1,4.7,11.2,17,17,0,0,1-4.9,12l-34.1,33h0L88.3,188.6a15.9,15.9,0,0,0-.2,22.8l28.6,28.7a16.3,16.3,0,0,0,22.6.1l32.9-32.4h0l33.6-32.9C240.2,140.5,240.8,84.6,207,50.2ZM55.9,156.6,27.3,128,54,100.9l28.5,28.5Zm72.2,72.1L99.4,200.1l27.3-26.5,28.5,28.5Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M205.5,51.7A85.2,85.2,0,0,0,144.6,26h-.3A85.5,85.5,0,0,0,83.4,51.2L17.3,118.1a14.1,14.1,0,0,0,.1,19.8L46,166.5a13.8,13.8,0,0,0,9.9,4.1H56a13.6,13.6,0,0,0,9.9-4.2l65.7-67.2a17.8,17.8,0,0,1,30.5,12.4,19.1,19.1,0,0,1-5.4,13.4l-67,65a13.7,13.7,0,0,0-4.3,10,13.9,13.9,0,0,0,4.1,10l28.7,28.6a13.6,13.6,0,0,0,9.9,4.1,14,14,0,0,0,9.8-4l66.5-65.2C238,139.8,238.6,85.2,205.5,51.7ZM57.4,158a2.1,2.1,0,0,1-1.5.6,2,2,0,0,1-1.4-.6L25.9,129.4a1.9,1.9,0,0,1,0-2.8L54,98.1l31.3,31.3Zm72.1,72.2a2.3,2.3,0,0,1-2.9,0L98,201.5a1.9,1.9,0,0,1,0-2.8l28.7-27.9L158,202.1ZM195.9,165l-29.3,28.7-31.3-31.2,29.8-28.9a30.8,30.8,0,0,0,9-22.1,29.5,29.5,0,0,0-29.8-29.4A30.3,30.3,0,0,0,123,90.8h0l-29.3,30L62.4,89.6,91.9,59.7A73.6,73.6,0,0,1,144.3,38h.2A73.6,73.6,0,0,1,197,60.1C225.4,88.9,224.9,136,195.9,165Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M204.1,53.1A83.4,83.4,0,0,0,144.6,28h-.3A83.5,83.5,0,0,0,84.8,52.6L18.7,119.5a12.1,12.1,0,0,0,.1,17l28.6,28.6a11.9,11.9,0,0,0,8.5,3.5H56a11.8,11.8,0,0,0,8.5-3.6l65.6-67.2a20.1,20.1,0,0,1,28-.2,19.6,19.6,0,0,1,6,14,21.5,21.5,0,0,1-6,14.9L91,191.5a11.8,11.8,0,0,0-3.6,8.5,12.1,12.1,0,0,0,3.5,8.6l28.7,28.6a11.6,11.6,0,0,0,8.5,3.5,12,12,0,0,0,8.4-3.4L169.4,205h0l33.5-32.9C235.8,139.2,236.4,85.8,204.1,53.1ZM58.8,159.4a3.9,3.9,0,0,1-2.8,1.2h-.1a3.9,3.9,0,0,1-2.8-1.2L24.5,130.8a4,4,0,0,1-.1-5.6L54,95.3l34.1,34.1Zm72.1,72.2a4.2,4.2,0,0,1-5.7,0L96.6,202.9a3.8,3.8,0,0,1-1.2-2.8,4,4,0,0,1,1.2-2.9L126.7,168l34.2,34.2Zm35.7-35-34.1-34.2,31.2-30.2a29.3,29.3,0,0,0,8.4-20.7,27.9,27.9,0,0,0-47.6-19.3h-.1L93.7,123.7,59.6,89.6,90.5,58.3A75.6,75.6,0,0,1,144.3,36h.2a75.4,75.4,0,0,1,53.9,22.7c29.2,29.6,28.7,77.9-1.1,107.7Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M207,50.2A87.7,87.7,0,0,0,144.6,24h-.3A87.3,87.3,0,0,0,82,49.8L48.3,83.9h0L15.9,116.7a16.1,16.1,0,0,0,.1,22.6l28.6,28.6a15.6,15.6,0,0,0,11.3,4.7H56a15.8,15.8,0,0,0,11.4-4.8l32-32.8h0L133,100.7a16.1,16.1,0,0,1,22.4-.2,15.7,15.7,0,0,1,4.7,11.2,17,17,0,0,1-4.9,12l-34.1,33h0L88.3,188.6a15.9,15.9,0,0,0-.2,22.8l28.6,28.7a16.3,16.3,0,0,0,22.6.1l32.9-32.4h0l33.6-32.9C240.2,140.5,240.8,84.6,207,50.2ZM55.9,156.6,27.3,128,54,100.9l28.5,28.5Zm72.2,72.1L99.4,200.1l27.3-26.5,28.5,28.5Zm38.5-37.8-28.4-28.4,28.2-27.4h.1a32.8,32.8,0,0,0,9.6-23.5,31.6,31.6,0,0,0-31.8-31.4,31.9,31.9,0,0,0-22.7,9.3h0L93.7,118,65.2,89.5,93.3,61.1a71.8,71.8,0,0,1,51-21.1h.2a71.7,71.7,0,0,1,51.1,21.5c27.6,28,27.1,73.9-1.1,102.1Z" />
        </>
      )}
    </svg>
  );
}

export function PhMagnifyingGlassMinus(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M160,116a12,12,0,0,1-12,12H84a12,12,0,0,1,0-24h64A12,12,0,0,1,160,116Zm72.5,116.5a12.1,12.1,0,0,1-17,0l-40.7-40.7A94.9,94.9,0,0,1,116,212a96,96,0,1,1,96-96,94.9,94.9,0,0,1-20.2,58.8l40.7,40.7A12,12,0,0,1,232.5,232.5ZM116,188a72,72,0,1,0-72-72A72.1,72.1,0,0,0,116,188Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="116" cy="116" r="84" />
          </g>
          <path d="M156,116a8,8,0,0,1-8,8H84a8,8,0,0,1,0-16h64A8,8,0,0,1,156,116Zm73.7,113.7a8.2,8.2,0,0,1-11.4,0l-43.2-43.3a92.2,92.2,0,1,1,11.3-11.3l43.3,43.2A8.1,8.1,0,0,1,229.7,229.7ZM116,192a76,76,0,1,0-76-76A76.1,76.1,0,0,0,116,192Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M229.7,218.3l-43.3-43.2a92.2,92.2,0,1,0-11.3,11.3l43.2,43.3a8.2,8.2,0,0,0,11.4,0A8.1,8.1,0,0,0,229.7,218.3ZM148,124H84a8,8,0,0,1,0-16h64a8,8,0,0,1,0,16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M154,116a6,6,0,0,1-6,6H84a6,6,0,0,1,0-12h64A6,6,0,0,1,154,116Zm74.2,112.2a5.8,5.8,0,0,1-8.4,0l-44.6-44.5a90.2,90.2,0,1,1,8.5-8.5l44.5,44.6A5.8,5.8,0,0,1,228.2,228.2ZM116,194a78,78,0,1,0-78-78A78.1,78.1,0,0,0,116,194Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M152,116a4,4,0,0,1-4,4H84a4,4,0,0,1,0-8h64A4,4,0,0,1,152,116Zm74.8,110.8a3.9,3.9,0,0,1-5.6,0L175.3,181a88,88,0,1,1,5.7-5.7l45.8,45.9A3.9,3.9,0,0,1,226.8,226.8ZM116,196a80,80,0,1,0-80-80A80.1,80.1,0,0,0,116,196Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M156,116a8,8,0,0,1-8,8H84a8,8,0,0,1,0-16h64A8,8,0,0,1,156,116Zm73.7,113.7a8.2,8.2,0,0,1-11.4,0l-43.2-43.3a92.2,92.2,0,1,1,11.3-11.3l43.3,43.2A8.1,8.1,0,0,1,229.7,229.7ZM116,192a76,76,0,1,0-76-76A76.1,76.1,0,0,0,116,192Z" />
        </>
      )}
    </svg>
  );
}

export function PhMagnifyingGlassPlus(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M160,116a12,12,0,0,1-12,12H128v20a12,12,0,0,1-24,0V128H84a12,12,0,0,1,0-24h20V84a12,12,0,0,1,24,0v20h20A12,12,0,0,1,160,116Zm72.5,116.5a12.1,12.1,0,0,1-17,0l-40.7-40.7A94.9,94.9,0,0,1,116,212a96,96,0,1,1,96-96,94.9,94.9,0,0,1-20.2,58.8l40.7,40.7A12,12,0,0,1,232.5,232.5ZM116,188a72,72,0,1,0-72-72A72.1,72.1,0,0,0,116,188Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="116" cy="116" r="84" />
          </g>
          <path d="M156,116a8,8,0,0,1-8,8H124v24a8,8,0,0,1-16,0V124H84a8,8,0,0,1,0-16h24V84a8,8,0,0,1,16,0v24h24A8,8,0,0,1,156,116Zm73.7,113.7a8.2,8.2,0,0,1-11.4,0l-43.2-43.3a92.2,92.2,0,1,1,11.3-11.3l43.3,43.2A8.1,8.1,0,0,1,229.7,229.7ZM116,192a76,76,0,1,0-76-76A76.1,76.1,0,0,0,116,192Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M229.7,218.3l-43.3-43.2a92.2,92.2,0,1,0-11.3,11.3l43.2,43.3a8.2,8.2,0,0,0,11.4,0A8.1,8.1,0,0,0,229.7,218.3ZM148,124H124v24a8,8,0,0,1-16,0V124H84a8,8,0,0,1,0-16h24V84a8,8,0,0,1,16,0v24h24a8,8,0,0,1,0,16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M154,116a6,6,0,0,1-6,6H122v26a6,6,0,0,1-12,0V122H84a6,6,0,0,1,0-12h26V84a6,6,0,0,1,12,0v26h26A6,6,0,0,1,154,116Zm74.2,112.2a5.8,5.8,0,0,1-8.4,0l-44.6-44.5a90.2,90.2,0,1,1,8.5-8.5l44.5,44.6A5.8,5.8,0,0,1,228.2,228.2ZM194,116a78,78,0,1,0-78,78A78.1,78.1,0,0,0,194,116Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M152,116a4,4,0,0,1-4,4H120v28a4,4,0,0,1-8,0V120H84a4,4,0,0,1,0-8h28V84a4,4,0,0,1,8,0v28h28A4,4,0,0,1,152,116Zm74.8,110.8a3.9,3.9,0,0,1-5.6,0L175.3,181a88,88,0,1,1,5.7-5.7l45.8,45.9A3.9,3.9,0,0,1,226.8,226.8ZM116,196a80,80,0,1,0-80-80A80.1,80.1,0,0,0,116,196Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M156,116a8,8,0,0,1-8,8H124v24a8,8,0,0,1-16,0V124H84a8,8,0,0,1,0-16h24V84a8,8,0,0,1,16,0v24h24A8,8,0,0,1,156,116Zm73.7,113.7a8.2,8.2,0,0,1-11.4,0l-43.2-43.3a92.2,92.2,0,1,1,11.3-11.3l43.3,43.2A8.1,8.1,0,0,1,229.7,229.7ZM116,192a76,76,0,1,0-76-76A76.1,76.1,0,0,0,116,192Z" />
        </>
      )}
    </svg>
  );
}

export function PhMagnifyingGlass(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M232.5,215.5l-40.7-40.7A94.9,94.9,0,0,0,212,116a96,96,0,1,0-96,96,94.9,94.9,0,0,0,58.8-20.2l40.7,40.7a12.1,12.1,0,0,0,17,0A12,12,0,0,0,232.5,215.5ZM44,116a72,72,0,1,1,72,72A72.1,72.1,0,0,1,44,116Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="116" cy="116" r="84" />
          </g>
          <path d="M229.7,218.3l-43.3-43.2a92.2,92.2,0,1,0-11.3,11.3l43.2,43.3a8.2,8.2,0,0,0,11.4,0A8.1,8.1,0,0,0,229.7,218.3ZM40,116a76,76,0,1,1,76,76A76.1,76.1,0,0,1,40,116Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M176,116a60,60,0,1,1-60-60A60,60,0,0,1,176,116Zm53.7,113.7a8.2,8.2,0,0,1-11.4,0l-43.2-43.3a92.2,92.2,0,1,1,11.3-11.3l43.3,43.2A8.1,8.1,0,0,1,229.7,229.7ZM116,192a76,76,0,1,0-76-76A76.1,76.1,0,0,0,116,192Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M228.2,219.8l-44.5-44.6a90.2,90.2,0,1,0-8.5,8.5l44.6,44.5a5.9,5.9,0,0,0,8.4-8.4ZM38,116a78,78,0,1,1,78,78A78.1,78.1,0,0,1,38,116Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M226.8,221.2,181,175.3a88,88,0,1,0-5.7,5.7l45.9,45.8a3.9,3.9,0,0,0,5.6,0A3.9,3.9,0,0,0,226.8,221.2ZM36,116a80,80,0,1,1,80,80A80.1,80.1,0,0,1,36,116Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M229.7,218.3l-43.3-43.2a92.2,92.2,0,1,0-11.3,11.3l43.2,43.3a8.2,8.2,0,0,0,11.4,0A8.1,8.1,0,0,0,229.7,218.3ZM40,116a76,76,0,1,1,76,76A76.1,76.1,0,0,1,40,116Z" />
        </>
      )}
    </svg>
  );
}

export function PhMapPinLine(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M200.1,220H160.9c5.1-5,10.7-10.7,16.3-17.1,28.1-32.3,42.9-66.5,42.9-98.9a92,92,0,0,0-184,0c0,50,34.1,91.9,59.2,116H56.1a12,12,0,0,0,0,24h144a12,12,0,1,0,0-24ZM60.1,104a68,68,0,0,1,136,0c0,33.3-19.9,63.4-36.7,82.7a247.8,247.8,0,0,1-31.3,30.2,247.8,247.8,0,0,1-31.3-30.2C80.1,167.4,60.1,137.3,60.1,104Zm68,44a44,44,0,1,0-44-44A44,44,0,0,0,128.1,148Zm0-64a20,20,0,1,1-20,20A20.1,20.1,0,0,1,128.1,84Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M128.1,24a80,80,0,0,0-80,80c0,72,80,128,80,128s80-56,80-128A80,80,0,0,0,128.1,24Zm0,112a32,32,0,1,1,32-32A32,32,0,0,1,128.1,136Z" />
          </g>
          <path d="M200.1,224H150.7a266.5,266.5,0,0,0,23.5-23.8c27.4-31.5,41.9-64.8,41.9-96.2a88,88,0,0,0-176,0c0,31.4,14.5,64.7,42,96.2A253.6,253.6,0,0,0,105.6,224H56.1a8,8,0,0,0,0,16h144a8,8,0,1,0,0-16ZM56.1,104a72,72,0,0,1,144,0c0,57.2-55.5,105-72,118C111.6,209,56.1,161.2,56.1,104Z" />
          <path d="M168.1,104a40,40,0,1,0-40,40A40,40,0,0,0,168.1,104Zm-64,0a24,24,0,1,1,24,24A24.1,24.1,0,0,1,104.1,104Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M200.1,224H150.7a266.5,266.5,0,0,0,23.5-23.8c27.4-31.5,41.9-64.8,41.9-96.2a88,88,0,0,0-176,0c0,31.4,14.5,64.7,42,96.2A253.6,253.6,0,0,0,105.6,224H56.1a8,8,0,0,0,0,16h144a8,8,0,1,0,0-16Zm-72-152a32,32,0,1,1-32,32A32,32,0,0,1,128.1,72Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M200.1,226H145.3a263.3,263.3,0,0,0,27.3-27.1c27.2-31.2,41.5-64,41.5-94.9a86,86,0,0,0-172,0c0,30.9,14.4,63.7,41.5,94.9A263.3,263.3,0,0,0,110.9,226H56.1a6,6,0,0,0,0,12h144a6,6,0,1,0,0-12ZM54.1,104a74,74,0,0,1,148,0c0,59.6-59,108.9-74,120.5C113.1,212.9,54.1,163.6,54.1,104Zm112,0a38,38,0,1,0-38,38A38,38,0,0,0,166.1,104Zm-64,0a26,26,0,1,1,26,26A26.1,26.1,0,0,1,102.1,104Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M200.1,228H139.8a255.1,255.1,0,0,0,31.3-30.4c26.8-30.8,41-63.2,41-93.6a84,84,0,0,0-168,0c0,30.4,14.2,62.8,41,93.6A262.4,262.4,0,0,0,116.4,228H56.1a4,4,0,0,0,0,8h144a4,4,0,1,0,0-8ZM91,192.2C73.3,171.8,52.1,139.9,52.1,104a76,76,0,0,1,152,0c0,35.9-21.1,67.8-38.9,88.2A242.6,242.6,0,0,1,128.1,227,247.5,247.5,0,0,1,91,192.2ZM164.1,104a36,36,0,1,0-36,36A36,36,0,0,0,164.1,104Zm-64,0a28,28,0,1,1,28,28A28.1,28.1,0,0,1,100.1,104Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M200.1,224H150.7a266.5,266.5,0,0,0,23.5-23.8c27.4-31.5,41.9-64.8,41.9-96.2a88,88,0,0,0-176,0c0,31.4,14.5,64.7,42,96.2A253.6,253.6,0,0,0,105.6,224H56.1a8,8,0,0,0,0,16h144a8,8,0,1,0,0-16ZM56.1,104a72,72,0,0,1,144,0c0,57.2-55.5,105-72,118C111.6,209,56.1,161.2,56.1,104Zm112,0a40,40,0,1,0-40,40A40,40,0,0,0,168.1,104Zm-64,0a24,24,0,1,1,24,24A24.1,24.1,0,0,1,104.1,104Z" />
        </>
      )}
    </svg>
  );
}

export function PhMapPin(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128.1,60a44,44,0,1,0,44,44A44.1,44.1,0,0,0,128.1,60Zm0,64a20,20,0,1,1,20-20A20.1,20.1,0,0,1,128.1,124Zm0-112a92.1,92.1,0,0,0-92,92c0,77.4,81.7,135.4,85.1,137.8a11.9,11.9,0,0,0,13.8,0,260,260,0,0,0,42.2-38.9c28.1-32.3,42.9-66.5,42.9-98.9A92.1,92.1,0,0,0,128.1,12Zm31.3,174.7a247.8,247.8,0,0,1-31.3,30.2,247.8,247.8,0,0,1-31.3-30.2C80.1,167.4,60.1,137.3,60.1,104a68,68,0,0,1,136,0C196.1,137.3,176.2,167.4,159.4,186.7Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M128.1,24a80,80,0,0,0-80,80c0,72,80,128,80,128s80-56,80-128A80,80,0,0,0,128.1,24Zm0,112a32,32,0,1,1,32-32A32,32,0,0,1,128.1,136Z" />
          </g>
          <path d="M128.1,64a40,40,0,1,0,40,40A40.1,40.1,0,0,0,128.1,64Zm0,64a24,24,0,1,1,24-24A24.1,24.1,0,0,1,128.1,128Z" />
          <path d="M128.1,16a88.1,88.1,0,0,0-88,88c0,31.4,14.5,64.7,42,96.2a259.4,259.4,0,0,0,41.4,38.4,8.3,8.3,0,0,0,9.2,0,257.6,257.6,0,0,0,41.5-38.4c27.4-31.5,41.9-64.8,41.9-96.2A88.1,88.1,0,0,0,128.1,16Zm0,206c-16.5-13-72-60.8-72-118a72,72,0,0,1,144,0C200.1,161.2,144.6,209,128.1,222Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128.1,16a88.1,88.1,0,0,0-88,88c0,75.3,80,132.2,83.4,134.6a8.3,8.3,0,0,0,9.2,0c3.4-2.4,83.4-59.3,83.4-134.6A88.1,88.1,0,0,0,128.1,16Zm0,56a32,32,0,1,1-32,32A32,32,0,0,1,128.1,72Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128.1,66a38,38,0,1,0,38,38A38,38,0,0,0,128.1,66Zm0,64a26,26,0,1,1,26-26A26.1,26.1,0,0,1,128.1,130Zm0-112a86.1,86.1,0,0,0-86,86c0,30.9,14.4,63.7,41.5,94.9a247.8,247.8,0,0,0,41.1,38,6,6,0,0,0,3.4,1.1,6.6,6.6,0,0,0,3.5-1.1,254.2,254.2,0,0,0,41-38c27.2-31.2,41.5-64,41.5-94.9A86.1,86.1,0,0,0,128.1,18Zm0,206.5c-15-11.6-74-60.9-74-120.5a74,74,0,0,1,148,0C202.1,163.6,143.1,212.9,128.1,224.5Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128.1,68a36,36,0,1,0,36,36A36,36,0,0,0,128.1,68Zm0,64a28,28,0,1,1,28-28A28.1,28.1,0,0,1,128.1,132Zm0-112a84.1,84.1,0,0,0-84,84c0,30.4,14.2,62.8,41,93.6a255.4,255.4,0,0,0,40.7,37.7,4.1,4.1,0,0,0,4.6,0,255.4,255.4,0,0,0,40.7-37.7c26.8-30.8,41-63.2,41-93.6A84.1,84.1,0,0,0,128.1,20Zm37.1,172.2A242.6,242.6,0,0,1,128.1,227,247.5,247.5,0,0,1,91,192.2C73.3,171.8,52.1,139.9,52.1,104a76,76,0,0,1,152,0C204.1,139.9,183,171.8,165.2,192.2Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128.1,64a40,40,0,1,0,40,40A40.1,40.1,0,0,0,128.1,64Zm0,64a24,24,0,1,1,24-24A24.1,24.1,0,0,1,128.1,128Zm0-112a88.1,88.1,0,0,0-88,88c0,31.4,14.5,64.7,42,96.2a259.4,259.4,0,0,0,41.4,38.4,8.3,8.3,0,0,0,9.2,0,257.6,257.6,0,0,0,41.5-38.4c27.4-31.5,41.9-64.8,41.9-96.2A88.1,88.1,0,0,0,128.1,16Zm0,206c-16.5-13-72-60.8-72-118a72,72,0,0,1,144,0C200.1,161.2,144.6,209,128.1,222Z" />
        </>
      )}
    </svg>
  );
}

export function PhMapTrifold(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M231.5,46.5a12.2,12.2,0,0,0-10.3-2.1L161.5,59.3l-60-30h-.1l-1.3-.5h-.2l-1.2-.3H97a13.2,13.2,0,0,0-2.8.2h-.4l-.6.2-64,16A12,12,0,0,0,20.1,56V200a12.1,12.1,0,0,0,4.6,9.5,12.7,12.7,0,0,0,7.4,2.5,9.2,9.2,0,0,0,2.9-.4l59.7-14.9,60,30h.2l1,.4h.2l1.2.4h.2l1.1.2h3.6l.8-.2,64-16a11.9,11.9,0,0,0,9.1-11.6V56A12.1,12.1,0,0,0,231.5,46.5ZM44.1,65.4l40-10V174.6l-40,10Zm104,131.2-40-20V59.4l40,20Zm64-6-40,10V81.4l40-10Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <polygon points="160.1 216 96.1 184 96.1 40 160.1 72 160.1 216" />
          </g>
          <path d="M229,49.7a7.9,7.9,0,0,0-6.8-1.5L161,63.5,99.7,32.8h-.1l-.8-.3h-.2l-.7-.2h-.3l-.7-.2H94.2l-64,16A8,8,0,0,0,24.1,56V200a7.8,7.8,0,0,0,3.1,6.3,7.9,7.9,0,0,0,4.9,1.7l2-.2,61.1-15.3,61.3,30.7h.1l.7.3h.1l.8.3h3.9l64-16a8,8,0,0,0,6-7.8V56A7.8,7.8,0,0,0,229,49.7ZM40.1,62.2l48-12V177.8l-48,12Zm112,140.9-48-24V52.9l48,24Zm64-9.3-48,12V78.2l48-12Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M229,49.7a7.9,7.9,0,0,0-6.8-1.5L161,63.5,99.7,32.8h-.1l-.8-.3h-.2l-.7-.2h-.3l-.7-.2H94.2l-64,16A8,8,0,0,0,24.1,56V200a7.8,7.8,0,0,0,3.1,6.3,7.9,7.9,0,0,0,4.9,1.7l2-.2,61.1-15.3,61.3,30.7h.1l.7.3h.1l.8.3h3.9l64-16a8,8,0,0,0,6-7.8V56A7.8,7.8,0,0,0,229,49.7ZM152.1,203.1l-48-24V52.9l48,24Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M227.8,51.3a5.6,5.6,0,0,0-5.1-1.1L160.8,65.6l-62-31h-.1l-.6-.3h-.7c0-.1-.1-.1-.2-.1H94.7l-64,16A5.9,5.9,0,0,0,26.1,56V200a6.1,6.1,0,0,0,2.3,4.7,5.8,5.8,0,0,0,3.7,1.3l1.5-.2,61.8-15.4,62,31,.7.3h.2l.7.2h2.6l64-16a5.9,5.9,0,0,0,4.5-5.8V56A5.8,5.8,0,0,0,227.8,51.3ZM38.1,60.7l52-13V179.3l-52,13Zm116,145.6-52-26V49.7l52,26Zm64-11-52,13V76.7l52-13Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M226.6,52.8a4.4,4.4,0,0,0-3.5-.7L160.6,67.8,97.9,36.4H95.1l-64,16a4.2,4.2,0,0,0-3,3.9V200a4.2,4.2,0,0,0,1.6,3.2,4.1,4.1,0,0,0,2.4.8h1l62.5-15.7,62.7,31.4h.1l.7.3h2l64-16a4.1,4.1,0,0,0,3-3.9V56A4.4,4.4,0,0,0,226.6,52.8ZM36.1,59.1l56-14V180.9l-56,14Zm120,150.4-56-28V46.5l56,28Zm64-12.6-56,14V75.1l56-14Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M229,49.7a7.9,7.9,0,0,0-6.8-1.5L161,63.5,99.7,32.8h-.1l-.8-.3h-.2l-.7-.2h-.3l-.7-.2H94.2l-64,16A8,8,0,0,0,24.1,56V200a7.8,7.8,0,0,0,3.1,6.3,7.9,7.9,0,0,0,4.9,1.7l2-.2,61.1-15.3,61.3,30.7h.1l.7.3h.1l.8.3h3.9l64-16a8,8,0,0,0,6-7.8V56A7.8,7.8,0,0,0,229,49.7ZM40.1,62.2l48-12V177.8l-48,12Zm112,140.9-48-24V52.9l48,24Zm64-9.3-48,12V78.2l48-12Z" />
        </>
      )}
    </svg>
  );
}

export function PhMarkerCircle(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M204.4,204.4a108,108,0,1,0-152.8,0,111.4,111.4,0,0,0,22.1,17l.8.4a108.5,108.5,0,0,0,107,0l.7-.4A112,112,0,0,0,204.4,204.4ZM108,156V140h40v16ZM44,128a84,84,0,1,1,144,58.8V176a20.1,20.1,0,0,0-16-19.6V136a20,20,0,0,0-14.9-19.3l-9.8-35.5a20.1,20.1,0,0,0-38.6,0l-9.8,35.5A20,20,0,0,0,84,136v20.4A20.1,20.1,0,0,0,68,176v10.8A83.5,83.5,0,0,1,44,128Zm48,75.9V180h72v23.9A84.8,84.8,0,0,1,92,203.9Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M176,211.2a96,96,0,1,0-96,0h0V176a8,8,0,0,1,8-8h8V144a8,8,0,0,1,8-8h4.2l12-57.6c1.7-8.5,13.9-8.5,15.6,0l12,57.6H152a8,8,0,0,1,8,8v24h8a8,8,0,0,1,8,8v35.2Z" />
          </g>
          <path d="M201.5,54.5a103.8,103.8,0,0,0-147,0,103.8,103.8,0,0,0,0,147,103.8,103.8,0,0,0,147,0,103.8,103.8,0,0,0,0-147ZM88,206.4V176h80v30.4A88.8,88.8,0,0,1,88,206.4ZM104,144h48v16H104Zm14-16,10-48h0l10,48Zm72.2,62.2c-2,2-4.1,3.9-6.2,5.7V176a16,16,0,0,0-16-16V144a16,16,0,0,0-13.6-15.8L143.7,76.7a16.1,16.1,0,0,0-31.4,0l-10.7,51.5A16,16,0,0,0,88,144v16a16,16,0,0,0-16,16v19.9c-2.1-1.8-4.2-3.7-6.2-5.7a88,88,0,1,1,124.4,0Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M201.5,54.5a103.8,103.8,0,0,0-147,0,103.8,103.8,0,0,0,0,147,103.8,103.8,0,0,0,147,0,103.8,103.8,0,0,0,0-147ZM168,206.4a88.8,88.8,0,0,1-80,0V176h80ZM104,160V136h48v24Zm86.2,30.2c-2,2-4.1,3.9-6.2,5.7V176a16,16,0,0,0-16-16V136a16,16,0,0,0-13.7-15.8L143.6,73.3a16,16,0,0,0-31.2,0l-10.7,46.9A16,16,0,0,0,88,136v24a16,16,0,0,0-16,16v19.9c-2.1-1.8-4.2-3.7-6.2-5.7a88,88,0,1,1,124.4,0Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M200.1,55.9A102,102,0,1,0,55.9,200.1,102,102,0,1,0,200.1,55.9ZM86,207.6V176a2,2,0,0,1,2-2h80a2,2,0,0,1,2,2v31.6a90,90,0,0,1-84,0ZM104,142h48a2,2,0,0,1,2,2v18H102V144A2,2,0,0,1,104,142Zm11.5-12L126,79.6a2.1,2.1,0,0,1,4,0L140.5,130Zm76.1,61.6A76,76,0,0,1,182,200V176a14,14,0,0,0-14-14h-2V144a14,14,0,0,0-13.3-14l-11-52.9a14,14,0,0,0-27.4,0l-11,52.9A14,14,0,0,0,90,144v18H88a14,14,0,0,0-14,14v24a76,76,0,0,1-9.6-8.4,90,90,0,1,1,127.2,0Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M198.7,57.3A100,100,0,1,0,57.3,198.7,100,100,0,1,0,198.7,57.3ZM84,208.8V176a4,4,0,0,1,4-4h80a4,4,0,0,1,4,4v32.8a92,92,0,0,1-88,0ZM108.1,140H152a4,4,0,0,1,4,4v20H100V144a4,4,0,0,1,4-4Zm5-8,11-52.8a4,4,0,0,1,7.8,0l11,52.8Zm80,61.1A102.9,102.9,0,0,1,180,203.9V176a12,12,0,0,0-12-12h-4V144a12,12,0,0,0-12-12h-.9L139.7,77.6a11.9,11.9,0,0,0-23.4,0L104.9,132H104a12,12,0,0,0-12,12v20H88a12,12,0,0,0-12,12v27.9a102.9,102.9,0,0,1-13.1-10.8,92,92,0,1,1,130.2,0Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M201.5,54.5a103.8,103.8,0,0,0-147,0,103.8,103.8,0,0,0,0,147,103.8,103.8,0,0,0,147,0,103.8,103.8,0,0,0,0-147ZM88,206.4V176h80v30.4A88.8,88.8,0,0,1,88,206.4ZM104,144h48v16H104Zm14-16,10-48h0l10,48Zm72.2,62.2c-2,2-4.1,3.9-6.2,5.7V176a16,16,0,0,0-16-16V144a16,16,0,0,0-13.6-15.8L143.7,76.7a16.1,16.1,0,0,0-31.4,0l-10.7,51.5A16,16,0,0,0,88,144v16a16,16,0,0,0-16,16v19.9c-2.1-1.8-4.2-3.7-6.2-5.7a88,88,0,1,1,124.4,0Z" />
        </>
      )}
    </svg>
  );
}

export function PhMartini(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M243.1,35.4A12,12,0,0,0,232,28H24a12,12,0,0,0-11.1,7.4,12.2,12.2,0,0,0,2.6,13.1L116,149v55H88a12,12,0,0,0,0,24h80a12,12,0,0,0,0-24H140V149L240.5,48.5A12.2,12.2,0,0,0,243.1,35.4ZM203,52,191,64H65L53,52Zm-75,75L89,88h78Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <polygon points="56 72 200 72 128 144 56 72" />
          </g>
          <path d="M237.7,45.7a8.4,8.4,0,0,0,1.7-8.8A8,8,0,0,0,232,32H24a8,8,0,0,0-7.4,4.9,8.4,8.4,0,0,0,1.7,8.8L120,147.3V208H88a8,8,0,0,0,0,16h80a8,8,0,0,0,0-16H136V147.3ZM75.3,80H180.7L128,132.7ZM212.7,48l-16,16H59.3l-16-16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M237.7,45.7a8.4,8.4,0,0,0,1.7-8.8A8,8,0,0,0,232,32H24a8,8,0,0,0-7.4,4.9,8.4,8.4,0,0,0,1.7,8.8L120,147.3V208H88a8,8,0,0,0,0,16h80a8,8,0,0,0,0-16H136V147.3Zm-25,2.3-16,16H59.3l-16-16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M236.2,44.2a5.7,5.7,0,0,0,1.3-6.5A6,6,0,0,0,232,34H24a6,6,0,0,0-5.5,3.7,5.7,5.7,0,0,0,1.3,6.5L122,146.5V210H88a6,6,0,0,0,0,12h80a6,6,0,0,0,0-12H134V146.5ZM70.5,78h115L128,135.5Zm147-32-20,20H58.5l-20-20Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M235.7,38.5A4,4,0,0,0,232,36H24a4,4,0,0,0-3.7,2.5,3.8,3.8,0,0,0,.9,4.3l32,32h0L124,145.7V212H88a4,4,0,0,0,0,8h80a4,4,0,0,0,0-8H132V145.7l70.8-70.9h0l32-32A3.8,3.8,0,0,0,235.7,38.5ZM128,138.3,65.7,76H190.3ZM198.3,68H57.7l-24-24H222.3Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M237.7,45.7a8.4,8.4,0,0,0,1.7-8.8A8,8,0,0,0,232,32H24a8,8,0,0,0-7.4,4.9,8.4,8.4,0,0,0,1.7,8.8L120,147.3V208H88a8,8,0,0,0,0,16h80a8,8,0,0,0,0-16H136V147.3ZM75.3,80H180.7L128,132.7ZM212.7,48l-16,16H59.3l-16-16Z" />
        </>
      )}
    </svg>
  );
}

export function PhMaskHappy(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M137.2,120a35.9,35.9,0,0,1,53.6,0,12,12,0,0,1-.9,16.9,12,12,0,0,1-17-.9,11.9,11.9,0,0,0-17.8,0,12,12,0,0,1-17.9-16Zm-36.3,16a12,12,0,0,0,17.9-16,35.9,35.9,0,0,0-53.6,0,12,12,0,0,0,17.9,16,11.9,11.9,0,0,1,17.8,0Zm47,30a36,36,0,0,1-39.8,0,12,12,0,0,0-13.3,20,60.1,60.1,0,0,0,66.4,0,12,12,0,0,0-13.3-20ZM228,48.3V104c0,36.6-9.9,71.2-27.9,97.3S155.6,244,128,244s-53.2-15.2-72.1-42.7S28,140.6,28,104V48.3a20.2,20.2,0,0,1,8.7-16.5,20,20,0,0,1,18.5-2.1C69,35,96.8,44,128,44s59-9,72.8-14.3a20,20,0,0,1,18.5,2.1A20.2,20.2,0,0,1,228,48.3Zm-24,5.8C187.4,60,159.3,68,128,68S68.6,60,52,54.1V104c0,31.8,8.4,61.5,23.7,83.7S108.6,220,128,220s38-11.5,52.3-32.3S204,135.8,204,104Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M216,48.3V104c0,70.7-39.4,128-88,128S40,174.7,40,104V48.3a7.9,7.9,0,0,1,10.9-7.4C66,46.7,95.1,56,128,56s62-9.3,77.1-15.1A7.9,7.9,0,0,1,216,48.3Z"
            opacity="0.2"
          />
          <path d="M187.8,122.7a8,8,0,0,1-.6,11.3,8.1,8.1,0,0,1-5.3,2,8,8,0,0,1-6-2.7,16,16,0,0,0-23.8,0,8,8,0,0,1-11.3.7,8,8,0,0,1-.6-11.3,31.8,31.8,0,0,1,47.6,0Zm-83.9,10.6a8,8,0,0,0,6,2.7,8.1,8.1,0,0,0,5.3-2,8,8,0,0,0,.6-11.3,31.8,31.8,0,0,0-47.6,0,8,8,0,0,0,.6,11.3,8,8,0,0,0,11.3-.7,16,16,0,0,1,23.8,0Zm46.2,36a39.8,39.8,0,0,1-44.2,0,8,8,0,0,0-11.1,2.3A8.1,8.1,0,0,0,97,182.7a56.3,56.3,0,0,0,62,0,8.1,8.1,0,0,0,2.2-11.1A8,8,0,0,0,150.1,169.3ZM224,48.3V104c0,35.8-9.7,69.5-27.2,95s-42.6,41-68.8,41-50.7-14.5-68.8-41S32,139.8,32,104V48.3A16,16,0,0,1,53.8,33.4C67.8,38.9,96.2,48,128,48s60.2-9.1,74.2-14.6A16,16,0,0,1,224,48.3Zm-16,0h0C192.9,54.2,162.4,64,128,64S63.1,54.2,48,48.3V104c0,32.6,8.7,63.1,24.4,86s34.8,34,55.6,34,40.5-12.1,55.6-34S208,136.6,208,104Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M217,35.1a15.9,15.9,0,0,0-14.8-1.7C188.2,38.9,159.8,48,128,48S67.8,38.9,53.8,33.4A16,16,0,0,0,32,48.3V104c0,35.8,9.7,69.5,27.2,95s42.6,41,68.8,41,50.7-14.5,68.8-41S224,139.8,224,104V48.3A15.9,15.9,0,0,0,217,35.1ZM80.1,133.3a8,8,0,0,1-11.3.7,8,8,0,0,1-.6-11.3,31.8,31.8,0,0,1,47.6,0,8,8,0,0,1-.6,11.3,8.1,8.1,0,0,1-5.3,2,8,8,0,0,1-6-2.7,16,16,0,0,0-23.8,0ZM159,182.7a56.3,56.3,0,0,1-62,0,8.1,8.1,0,0,1-2.2-11.1,8,8,0,0,1,11.1-2.3,39.8,39.8,0,0,0,44.2,0,8,8,0,0,1,11.1,2.3A8.1,8.1,0,0,1,159,182.7ZM187.2,134a8.1,8.1,0,0,1-5.3,2,8,8,0,0,1-6-2.7,16,16,0,0,0-23.8,0,8,8,0,0,1-11.3.7,8,8,0,0,1-.6-11.3,31.8,31.8,0,0,1,47.6,0A8,8,0,0,1,187.2,134Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M186.4,124a6,6,0,0,1-.5,8.5,6.2,6.2,0,0,1-4,1.5,6.1,6.1,0,0,1-4.5-2,18,18,0,0,0-26.8,0,6,6,0,0,1-9-8,30.1,30.1,0,0,1,44.8,0Zm-81,8a6.1,6.1,0,0,0,4.5,2,6.2,6.2,0,0,0,4-1.5,6,6,0,0,0,.5-8.5,30.1,30.1,0,0,0-44.8,0,6,6,0,0,0,9,8,18,18,0,0,1,26.8,0Zm45.8,39a41.9,41.9,0,0,1-46.4,0,6,6,0,0,0-6.7,10,54.2,54.2,0,0,0,59.8,0,6,6,0,0,0-6.7-10ZM222,48.3V104c0,35.4-9.5,68.7-26.8,93.9S153.5,238,128,238s-49.4-14.2-67.2-40.1S34,139.4,34,104V48.3a14,14,0,0,1,19.1-13C67.2,40.8,95.9,50,128,50s60.8-9.2,74.9-14.7a14,14,0,0,1,19.1,13Zm-12,0a1.7,1.7,0,0,0-.9-1.6,1.8,1.8,0,0,0-1.8-.2C192.3,52.3,162.1,62,128,62S63.7,52.3,48.7,46.5l-.7-.2a1.6,1.6,0,0,0-1.1.4,1.7,1.7,0,0,0-.9,1.6V104c0,67.3,36.8,122,82,122s82-54.7,82-122Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M184.9,125.3a4.2,4.2,0,0,1-.3,5.7,4.1,4.1,0,0,1-2.7,1,4.3,4.3,0,0,1-3-1.3,19.9,19.9,0,0,0-29.8,0,4.1,4.1,0,0,1-5.7.3,4.2,4.2,0,0,1-.3-5.7,28.1,28.1,0,0,1,41.8,0Zm-78,5.4a4.3,4.3,0,0,0,3,1.3,4.1,4.1,0,0,0,2.7-1,4.2,4.2,0,0,0,.3-5.7,28.1,28.1,0,0,0-41.8,0,4.2,4.2,0,0,0,.3,5.7,4.1,4.1,0,0,0,5.7-.3,19.9,19.9,0,0,1,29.8,0Zm45.4,42a44.1,44.1,0,0,1-48.6,0,4,4,0,1,0-4.5,6.6,52,52,0,0,0,57.6,0,4,4,0,0,0,1.1-5.5A3.9,3.9,0,0,0,152.3,172.7ZM220,48.3V104c0,35-9.4,67.9-26.5,92.8S152.9,236,128,236s-48.1-13.9-65.5-39.2S36,139,36,104V48.3a12.3,12.3,0,0,1,5.2-9.9,12.2,12.2,0,0,1,11.1-1.3C66.7,42.7,95.5,52,128,52s61.3-9.3,75.7-14.9a12.2,12.2,0,0,1,11.1,1.3A12.3,12.3,0,0,1,220,48.3Zm-8,0a3.9,3.9,0,0,0-5.4-3.7C191.7,50.3,161.8,60,128,60S64.3,50.3,49.4,44.6a3.2,3.2,0,0,0-1.4-.3,4.8,4.8,0,0,0-2.3.7A4,4,0,0,0,44,48.3V104c0,68.4,37.7,124,84,124s84-55.6,84-124Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M187.8,122.7a8,8,0,0,1-.6,11.3,8.1,8.1,0,0,1-5.3,2,8,8,0,0,1-6-2.7,16,16,0,0,0-23.8,0,8,8,0,0,1-11.3.7,8,8,0,0,1-.6-11.3,31.8,31.8,0,0,1,47.6,0Zm-83.9,10.6a8,8,0,0,0,6,2.7,8.1,8.1,0,0,0,5.3-2,8,8,0,0,0,.6-11.3,31.8,31.8,0,0,0-47.6,0,8,8,0,0,0,.6,11.3,8,8,0,0,0,11.3-.7,16,16,0,0,1,23.8,0Zm46.2,36a39.8,39.8,0,0,1-44.2,0,8,8,0,0,0-11.1,2.3A8.1,8.1,0,0,0,97,182.7a56.3,56.3,0,0,0,62,0,8.1,8.1,0,0,0,2.2-11.1A8,8,0,0,0,150.1,169.3ZM224,48.3V104c0,35.8-9.7,69.5-27.2,95s-42.6,41-68.8,41-50.7-14.5-68.8-41S32,139.8,32,104V48.3A16,16,0,0,1,53.8,33.4C67.8,38.9,96.2,48,128,48s60.2-9.1,74.2-14.6A16,16,0,0,1,224,48.3Zm-16,0h0C192.9,54.2,162.4,64,128,64S63.1,54.2,48,48.3V104c0,32.6,8.7,63.1,24.4,86s34.8,34,55.6,34,40.5-12.1,55.6-34S208,136.6,208,104Z" />
        </>
      )}
    </svg>
  );
}

export function PhMaskSad(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M137.2,128a12,12,0,0,1,17.9-16,11.9,11.9,0,0,0,17.8,0,12,12,0,0,1,17.9,16,35.9,35.9,0,0,1-53.6,0Zm-18.4,0a12,12,0,0,0-17.9-16,11.9,11.9,0,0,1-17.8,0,12,12,0,0,0-17.9,16,35.9,35.9,0,0,0,53.6,0Zm42.4,46a60.1,60.1,0,0,0-66.4,0,12,12,0,0,0,13.3,20,36,36,0,0,1,39.8,0,11.4,11.4,0,0,0,6.6,2,12,12,0,0,0,6.7-22ZM228,48.3V104c0,36.6-9.9,71.2-27.9,97.3S155.6,244,128,244s-53.2-15.2-72.1-42.7S28,140.6,28,104V48.3a20.2,20.2,0,0,1,8.7-16.5,20,20,0,0,1,18.5-2.1C69,35,96.8,44,128,44s59-9,72.8-14.3a20,20,0,0,1,18.5,2.1A20.2,20.2,0,0,1,228,48.3Zm-24,5.8C187.4,60,159.3,68,128,68S68.6,60,52,54.1V104c0,31.8,8.4,61.5,23.7,83.7S108.6,220,128,220s38-11.5,52.3-32.3S204,135.8,204,104Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M216,48.3V104c0,70.7-39.4,128-88,128S40,174.7,40,104V48.3a7.9,7.9,0,0,1,10.9-7.4C66,46.7,95.1,56,128,56s62-9.3,77.1-15.1A7.9,7.9,0,0,1,216,48.3Z"
            opacity="0.2"
          />
          <path d="M187.8,125.3a31.8,31.8,0,0,1-47.6,0,8,8,0,0,1,.6-11.3,8,8,0,0,1,11.3.7,16,16,0,0,0,23.8,0,8,8,0,0,1,11.3-.7A8,8,0,0,1,187.8,125.3Zm-72,0a8,8,0,0,0-.6-11.3,8,8,0,0,0-11.3.7,16,16,0,0,1-23.8,0,8,8,0,0,0-11.3-.7,8,8,0,0,0-.6,11.3,31.8,31.8,0,0,0,47.6,0Zm43.2,52a56.3,56.3,0,0,0-62,0,8.1,8.1,0,0,0-2.2,11.1,8,8,0,0,0,11.1,2.3,39.8,39.8,0,0,1,44.2,0,8.1,8.1,0,0,0,11.1-2.3A8.1,8.1,0,0,0,159,177.3Zm65-129V104c0,35.8-9.7,69.5-27.2,95s-42.6,41-68.8,41-50.7-14.5-68.8-41S32,139.8,32,104V48.3A16,16,0,0,1,53.8,33.4C67.8,38.9,96.2,48,128,48s60.2-9.1,74.2-14.6A16,16,0,0,1,224,48.3Zm-16,0h0C192.9,54.2,162.4,64,128,64S63.1,54.2,48,48.3V104c0,32.6,8.7,63.1,24.4,86s34.8,34,55.6,34,40.5-12.1,55.6-34S208,136.6,208,104Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M217,35.1a15.9,15.9,0,0,0-14.8-1.7C188.2,38.9,159.8,48,128,48S67.8,38.9,53.8,33.4A16,16,0,0,0,32,48.3V104c0,35.8,9.7,69.5,27.2,95s42.6,41,68.8,41,50.7-14.5,68.8-41S224,139.8,224,104V48.3A15.9,15.9,0,0,0,217,35.1ZM68.2,125.3a8,8,0,0,1,.6-11.3,8,8,0,0,1,11.3.7,16,16,0,0,0,23.8,0,8,8,0,0,1,11.3-.7,8,8,0,0,1,.6,11.3,31.8,31.8,0,0,1-47.6,0Zm93,63.1a8.1,8.1,0,0,1-11.1,2.3,39.8,39.8,0,0,0-44.2,0,8,8,0,0,1-11.1-2.3A8.1,8.1,0,0,1,97,177.3a56.3,56.3,0,0,1,62,0A8.1,8.1,0,0,1,161.2,188.4Zm26.6-63.1a31.8,31.8,0,0,1-47.6,0,8,8,0,0,1,.6-11.3,8,8,0,0,1,11.3.7,16,16,0,0,0,23.8,0,8,8,0,0,1,11.3-.7A8,8,0,0,1,187.8,125.3Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M185.9,115.5a6,6,0,0,1,.5,8.5,30.1,30.1,0,0,1-44.8,0,6,6,0,0,1,9-8,18,18,0,0,0,26.8,0A6,6,0,0,1,185.9,115.5ZM114.4,124a6,6,0,1,0-9-8,18,18,0,0,1-26.8,0,6,6,0,0,0-9,8,30.1,30.1,0,0,0,44.8,0Zm43.5,55a54.2,54.2,0,0,0-59.8,0,6,6,0,0,0,6.7,10,41.9,41.9,0,0,1,46.4,0,5.8,5.8,0,0,0,3.3,1,5.7,5.7,0,0,0,5-2.7A6,6,0,0,0,157.9,179ZM222,48.3V104c0,35.4-9.5,68.7-26.8,93.9S153.5,238,128,238s-49.4-14.2-67.2-40.1S34,139.4,34,104V48.3a14,14,0,0,1,19.1-13C67.2,40.8,95.9,50,128,50s60.8-9.2,74.9-14.7a14,14,0,0,1,19.1,13Zm-12,0a1.7,1.7,0,0,0-.9-1.6,1.8,1.8,0,0,0-1.8-.2C192.3,52.3,162.1,62,128,62S63.7,52.3,48.7,46.5l-.7-.2a1.6,1.6,0,0,0-1.1.4,1.7,1.7,0,0,0-.9,1.6V104c0,67.3,36.8,122,82,122s82-54.7,82-122Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M184.6,117a4.2,4.2,0,0,1,.3,5.7,28.1,28.1,0,0,1-41.8,0,4.2,4.2,0,0,1,.3-5.7,4.1,4.1,0,0,1,5.7.3,19.9,19.9,0,0,0,29.8,0A4.1,4.1,0,0,1,184.6,117Zm-71.7,5.7a4.2,4.2,0,0,0-.3-5.7,4.1,4.1,0,0,0-5.7.3,19.9,19.9,0,0,1-29.8,0,4.1,4.1,0,0,0-5.7-.3,4.2,4.2,0,0,0-.3,5.7,28.1,28.1,0,0,0,41.8,0Zm43.9,58a52,52,0,0,0-57.6,0,4,4,0,0,0-1.1,5.5,3.9,3.9,0,0,0,5.6,1.1,44.1,44.1,0,0,1,48.6,0,3.8,3.8,0,0,0,2.2.7,4.1,4.1,0,0,0,3.4-1.8A4,4,0,0,0,156.8,180.7ZM220,48.3V104c0,35-9.4,67.9-26.5,92.8S152.9,236,128,236s-48.1-13.9-65.5-39.2S36,139,36,104V48.3a12.3,12.3,0,0,1,5.2-9.9,12.2,12.2,0,0,1,11.1-1.3C66.7,42.7,95.5,52,128,52s61.3-9.3,75.7-14.9a12.2,12.2,0,0,1,11.1,1.3A12.3,12.3,0,0,1,220,48.3Zm-8,0a3.9,3.9,0,0,0-5.4-3.7C191.7,50.3,161.8,60,128,60S64.3,50.3,49.4,44.6a3.2,3.2,0,0,0-1.4-.3,4.8,4.8,0,0,0-2.3.7A4,4,0,0,0,44,48.3V104c0,68.4,37.7,124,84,124s84-55.6,84-124Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M187.8,125.3a31.8,31.8,0,0,1-47.6,0,8,8,0,0,1,.6-11.3,8,8,0,0,1,11.3.7,16,16,0,0,0,23.8,0,8,8,0,0,1,11.3-.7A8,8,0,0,1,187.8,125.3Zm-72,0a8,8,0,0,0-.6-11.3,8,8,0,0,0-11.3.7,16,16,0,0,1-23.8,0,8,8,0,0,0-11.3-.7,8,8,0,0,0-.6,11.3,31.8,31.8,0,0,0,47.6,0Zm43.2,52a56.3,56.3,0,0,0-62,0,8.1,8.1,0,0,0-2.2,11.1,8,8,0,0,0,11.1,2.3,39.8,39.8,0,0,1,44.2,0,8.1,8.1,0,0,0,11.1-2.3A8.1,8.1,0,0,0,159,177.3Zm65-129V104c0,35.8-9.7,69.5-27.2,95s-42.6,41-68.8,41-50.7-14.5-68.8-41S32,139.8,32,104V48.3A16,16,0,0,1,53.8,33.4C67.8,38.9,96.2,48,128,48s60.2-9.1,74.2-14.6A16,16,0,0,1,224,48.3Zm-16,0h0C192.9,54.2,162.4,64,128,64S63.1,54.2,48,48.3V104c0,32.6,8.7,63.1,24.4,86s34.8,34,55.6,34,40.5-12.1,55.6-34S208,136.6,208,104Z" />
        </>
      )}
    </svg>
  );
}

export function PhMathOperations(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M116,184a12,12,0,0,1-12,12H84v20a12,12,0,0,1-24,0V196H40a12,12,0,0,1,0-24H60V152a12,12,0,0,1,24,0v20h20A12,12,0,0,1,116,184ZM104,60H40a12,12,0,0,0,0,24h64a12,12,0,0,0,0-24Zm48,116.1h64a12,12,0,0,0,0-24H152a12,12,0,0,0,0,24Zm64,15.8H152a12,12,0,0,0,0,24h64a12,12,0,0,0,0-24Zm-64.5-87.4a12.1,12.1,0,0,0,17,0L184,89l15.5,15.5a12.1,12.1,0,0,0,17,0,12,12,0,0,0,0-17L201,72l15.5-15.5a12,12,0,0,0-17-17L184,55,168.5,39.5a12,12,0,0,0-17,17L167,72,151.5,87.5A12,12,0,0,0,151.5,104.5Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M112,184a8,8,0,0,1-8,8H80v24a8,8,0,0,1-16,0V192H40a8,8,0,0,1,0-16H64V152a8,8,0,0,1,16,0v24h24A8,8,0,0,1,112,184ZM104,64H40a8,8,0,0,0,0,16h64a8,8,0,0,0,0-16Zm48,112.1h64a8,8,0,0,0,0-16H152a8,8,0,0,0,0,16Zm64,15.8H152a8,8,0,0,0,0,16h64a8,8,0,0,0,0-16Zm-61.7-90.2a8.2,8.2,0,0,0,11.4,0L184,83.3l18.3,18.4a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4L195.3,72l18.4-18.3a8.1,8.1,0,0,0-11.4-11.4L184,60.7,165.7,42.3a8.1,8.1,0,0,0-11.4,11.4L172.7,72,154.3,90.3A8.1,8.1,0,0,0,154.3,101.7Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M112,184a8,8,0,0,1-8,8H80v24a8,8,0,0,1-16,0V192H40a8,8,0,0,1,0-16H64V152a8,8,0,0,1,16,0v24h24A8,8,0,0,1,112,184ZM104,64H40a8,8,0,0,0,0,16h64a8,8,0,0,0,0-16Zm48,112.1h64a8,8,0,0,0,0-16H152a8,8,0,0,0,0,16Zm64,15.8H152a8,8,0,0,0,0,16h64a8,8,0,0,0,0-16Zm-61.7-90.2a8.2,8.2,0,0,0,11.4,0L184,83.3l18.3,18.4a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4L195.3,72l18.4-18.3a8.1,8.1,0,0,0-11.4-11.4L184,60.7,165.7,42.3a8.1,8.1,0,0,0-11.4,11.4L172.7,72,154.3,90.3A8.1,8.1,0,0,0,154.3,101.7Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M110,184a6,6,0,0,1-6,6H78v26a6,6,0,0,1-12,0V190H40a6,6,0,0,1,0-12H66V152a6,6,0,0,1,12,0v26h26A6,6,0,0,1,110,184ZM104,66H40a6,6,0,0,0,0,12h64a6,6,0,0,0,0-12Zm48,108.1h64a6,6,0,0,0,0-12H152a6,6,0,0,0,0,12Zm64,19.8H152a6,6,0,0,0,0,12h64a6,6,0,0,0,0-12Zm-60.2-93.7a5.8,5.8,0,0,0,8.4,0L184,80.5l19.8,19.7a5.9,5.9,0,1,0,8.4-8.4L192.5,72l19.7-19.8a5.9,5.9,0,0,0-8.4-8.4L184,63.5,164.2,43.8a5.9,5.9,0,0,0-8.4,8.4L175.5,72,155.8,91.8A5.8,5.8,0,0,0,155.8,100.2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M108,184a4,4,0,0,1-4,4H76v28a4,4,0,0,1-8,0V188H40a4,4,0,0,1,0-8H68V152a4,4,0,0,1,8,0v28h28A4,4,0,0,1,108,184ZM104,68H40a4,4,0,0,0,0,8h64a4,4,0,0,0,0-8Zm48,104.1h64a4,4,0,0,0,0-8H152a4,4,0,0,0,0,8Zm64,23.8H152a4,4,0,0,0,0,8h64a4,4,0,0,0,0-8ZM157.2,98.8a3.9,3.9,0,0,0,5.6,0L184,77.7l21.2,21.1a3.9,3.9,0,0,0,5.6,0,3.9,3.9,0,0,0,0-5.6L189.7,72l21.1-21.2a4,4,0,1,0-5.6-5.6L184,66.3,162.8,45.2a4,4,0,0,0-5.6,5.6L178.3,72,157.2,93.2A3.9,3.9,0,0,0,157.2,98.8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M112,184a8,8,0,0,1-8,8H80v24a8,8,0,0,1-16,0V192H40a8,8,0,0,1,0-16H64V152a8,8,0,0,1,16,0v24h24A8,8,0,0,1,112,184ZM104,64H40a8,8,0,0,0,0,16h64a8,8,0,0,0,0-16Zm48,112.1h64a8,8,0,0,0,0-16H152a8,8,0,0,0,0,16Zm64,15.8H152a8,8,0,0,0,0,16h64a8,8,0,0,0,0-16Zm-61.7-90.2a8.2,8.2,0,0,0,11.4,0L184,83.3l18.3,18.4a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4L195.3,72l18.4-18.3a8.1,8.1,0,0,0-11.4-11.4L184,60.7,165.7,42.3a8.1,8.1,0,0,0-11.4,11.4L172.7,72,154.3,90.3A8.1,8.1,0,0,0,154.3,101.7Z" />
        </>
      )}
    </svg>
  );
}

export function PhMedal(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M220,96A92,92,0,1,0,68,165.7V240a12,12,0,0,0,5.7,10.2,11.9,11.9,0,0,0,11.7.5L128,229.4l42.6,21.3A11.6,11.6,0,0,0,176,252a11.8,11.8,0,0,0,6.3-1.8A12,12,0,0,0,188,240V165.7A91.9,91.9,0,0,0,220,96ZM60,96a68,68,0,1,1,68,68A68.1,68.1,0,0,1,60,96ZM164,220.6l-30.6-15.3a12.1,12.1,0,0,0-10.8,0L92,220.6V180.7a92.4,92.4,0,0,0,72,0ZM128,148A52,52,0,1,0,76,96,52,52,0,0,0,128,148Zm0-80a28,28,0,1,1-28,28A28.1,28.1,0,0,1,128,68Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="128" cy="96" r="48" />
          </g>
          <path d="M216,96A88,88,0,1,0,72,163.8V240a7.9,7.9,0,0,0,3.8,6.8,8,8,0,0,0,7.8.4L128,224.9l44.4,22.3a9.4,9.4,0,0,0,3.6.8,8,8,0,0,0,8-8V163.8A87.8,87.8,0,0,0,216,96ZM56,96a72,72,0,1,1,72,72A72.1,72.1,0,0,1,56,96ZM168,227.1l-36.4-18.3a8.3,8.3,0,0,0-7.2,0L88,227.1V174.4a88.1,88.1,0,0,0,80,0Z" />
          <path d="M128,152A56,56,0,1,0,72,96,56,56,0,0,0,128,152Zm0-96A40,40,0,1,1,88,96,40.1,40.1,0,0,1,128,56Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M184,96a56,56,0,1,1-56-56A56,56,0,0,1,184,96Zm32,0a87.8,87.8,0,0,1-32,67.8V240a8,8,0,0,1-8,8,9.4,9.4,0,0,1-3.6-.8L128,224.9,83.6,247.2a8,8,0,0,1-7.8-.4A7.9,7.9,0,0,1,72,240V163.8A88,88,0,1,1,216,96Zm-16,0a72,72,0,1,0-72,72A72.1,72.1,0,0,0,200,96Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M214,96A86,86,0,1,0,74,162.9V240a6.1,6.1,0,0,0,2.8,5.1,6.2,6.2,0,0,0,5.9.3L128,222.7l45.3,22.7a6.6,6.6,0,0,0,2.7.6,6.2,6.2,0,0,0,3.2-.9A6.1,6.1,0,0,0,182,240V162.9A85.9,85.9,0,0,0,214,96ZM54,96a74,74,0,1,1,74,74A74.1,74.1,0,0,1,54,96ZM170,230.3l-39.3-19.7a6.4,6.4,0,0,0-5.4,0L86,230.3V171a85.7,85.7,0,0,0,84,0ZM128,150A54,54,0,1,0,74,96,54,54,0,0,0,128,150Zm0-96A42,42,0,1,1,86,96,42,42,0,0,1,128,54Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M212,96A84,84,0,1,0,76,161.9V240a3.9,3.9,0,0,0,1.9,3.4,4.1,4.1,0,0,0,3.9.2L128,220.5l46.2,23.1A4,4,0,0,0,180,240V161.9A83.8,83.8,0,0,0,212,96ZM172,233.5l-42.2-21.1a4.5,4.5,0,0,0-3.6,0L84,233.5v-66a83.7,83.7,0,0,0,88,0ZM128,172a76,76,0,1,1,76-76A76.1,76.1,0,0,1,128,172Zm0-128a52,52,0,1,0,52,52A52,52,0,0,0,128,44Zm0,96a44,44,0,1,1,44-44A44,44,0,0,1,128,140Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,96A88,88,0,1,0,72,163.8V240a7.9,7.9,0,0,0,3.8,6.8,8,8,0,0,0,7.8.4L128,224.9l44.4,22.3a9.4,9.4,0,0,0,3.6.8,8,8,0,0,0,8-8V163.8A87.8,87.8,0,0,0,216,96ZM56,96a72,72,0,1,1,72,72A72.1,72.1,0,0,1,56,96ZM168,227.1l-36.4-18.3a8.3,8.3,0,0,0-7.2,0L88,227.1V174.4a88.1,88.1,0,0,0,80,0ZM128,152A56,56,0,1,0,72,96,56,56,0,0,0,128,152Zm0-96A40,40,0,1,1,88,96,40.1,40.1,0,0,1,128,56Z" />
        </>
      )}
    </svg>
  );
}

export function PhMediumLogo(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M70,56C33.6,56,4,88.3,4,128s29.6,72,66,72,66-32.3,66-72S106.4,56,70,56Zm0,120c-23.2,0-42-21.5-42-48S46.8,80,70,80s42,21.5,42,48S93.2,176,70,176ZM182,60c-23.4,0-34,35.2-34,68s10.6,68,34,68,34-35.2,34-68S205.4,60,182,60Zm0,111.3c-3.7-4.1-10-18.9-10-43.3s6.3-39.2,10-43.3c3.7,4.1,10,18.9,10,43.3S185.7,167.2,182,171.3ZM252,72V184a12,12,0,0,1-24,0V72a12,12,0,0,1,24,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M128,128c0,33.1-25.1,60-56,60s-56-26.9-56-60S41.1,68,72,68,128,94.9,128,128Zm56-56c-13.3,0-24,25.1-24,56s10.7,56,24,56,24-25.1,24-56S197.3,72,184,72Z"
            opacity="0.2"
          />
          <path d="M72,60C36.7,60,8,90.5,8,128s28.7,68,64,68,64-30.5,64-68S107.3,60,72,60Zm0,120c-26.5,0-48-23.3-48-52S45.5,76,72,76s48,23.3,48,52S98.5,180,72,180ZM184,64c-5.7,0-16.4,2.8-24.3,21.3-5,11.5-7.7,26.7-7.7,42.7s2.7,31.2,7.7,42.7c7.9,18.5,18.6,21.3,24.3,21.3s16.4-2.8,24.3-21.3c5-11.5,7.7-26.7,7.7-42.7s-2.7-31.2-7.7-42.7C200.4,66.8,189.7,64,184,64Zm0,112c-5.6,0-16-18.2-16-48s10.4-48,16-48,16,18.2,16,48S189.6,176,184,176ZM248,72V184a8,8,0,0,1-16,0V72a8,8,0,0,1,16,0Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M136,128c0,37.5-28.7,68-64,68S8,165.5,8,128,36.7,60,72,60,136,90.5,136,128ZM240,64a8,8,0,0,0-8,8V184a8,8,0,0,0,16,0V72A8,8,0,0,0,240,64Zm-56,0c-5.7,0-16.4,2.8-24.3,21.3-5,11.5-7.7,26.7-7.7,42.7s2.7,31.2,7.7,42.7c7.9,18.5,18.6,21.3,24.3,21.3s16.4-2.8,24.3-21.3c5-11.5,7.7-26.7,7.7-42.7s-2.7-31.2-7.7-42.7C200.4,66.8,189.7,64,184,64Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M72,62c-34.2,0-62,29.6-62,66s27.8,66,62,66,62-29.6,62-66S106.2,62,72,62Zm0,120c-27.6,0-50-24.2-50-54S44.4,74,72,74s50,24.2,50,54S99.6,182,72,182ZM184,66c-17.1,0-30,26.7-30,62s12.9,62,30,62,30-26.7,30-62S201.1,66,184,66Zm0,112c-7.3,0-18-19.5-18-50s10.7-50,18-50,18,19.5,18,50S191.3,178,184,178ZM246,72V184a6,6,0,0,1-12,0V72a6,6,0,0,1,12,0Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M72,64c-33.1,0-60,28.7-60,64s26.9,64,60,64,60-28.7,60-64S105.1,64,72,64Zm0,120c-28.7,0-52-25.1-52-56S43.3,72,72,72s52,25.1,52,56S100.7,184,72,184ZM184,68c-16,0-28,25.8-28,60s12,60,28,60,28-25.8,28-60S200,68,184,68Zm0,112c-9.5,0-20-21.4-20-52s10.5-52,20-52,20,21.4,20,52S193.5,180,184,180ZM244,72V184a4,4,0,0,1-8,0V72a4,4,0,0,1,8,0Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M72,60C36.7,60,8,90.5,8,128s28.7,68,64,68,64-30.5,64-68S107.3,60,72,60Zm0,120c-26.5,0-48-23.3-48-52S45.5,76,72,76s48,23.3,48,52S98.5,180,72,180ZM184,64c-5.7,0-16.4,2.8-24.3,21.3-5,11.5-7.7,26.7-7.7,42.7s2.7,31.2,7.7,42.7c7.9,18.5,18.6,21.3,24.3,21.3s16.4-2.8,24.3-21.3c5-11.5,7.7-26.7,7.7-42.7s-2.7-31.2-7.7-42.7C200.4,66.8,189.7,64,184,64Zm0,112c-5.6,0-16-18.2-16-48s10.4-48,16-48,16,18.2,16,48S189.6,176,184,176ZM248,72V184a8,8,0,0,1-16,0V72a8,8,0,0,1,16,0Z" />
        </>
      )}
    </svg>
  );
}

export function PhMegaphoneSimple(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M221.6,82.8l-82-23.9h-.1L45.6,31.5A20,20,0,0,0,20,50.7V189.3a19.7,19.7,0,0,0,8,16,19.4,19.4,0,0,0,11.9,4,18.7,18.7,0,0,0,5.7-.8L124,185.7V192a20.1,20.1,0,0,0,20,20h32a20.1,20.1,0,0,0,20-20V164.7l25.6-7.5A20.1,20.1,0,0,0,236,138V102A20.1,20.1,0,0,0,221.6,82.8ZM44,184V56l80,23.3v81.4Zm128,4H148v-9.3l24-7Zm40-53-31.5,9.2h-.1L148,153.7V86.3L212,105Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M136,70.3v99.4L42.2,197A8,8,0,0,1,32,189.3V50.7A8,8,0,0,1,42.2,43Z"
            opacity="0.2"
          />
          <path d="M220.5,86.6,138.4,62.7h-.1L44.5,35.3a15.8,15.8,0,0,0-14.1,2.6A15.7,15.7,0,0,0,24,50.7V189.3a15.7,15.7,0,0,0,6.4,12.8,16.1,16.1,0,0,0,9.6,3.2,17.4,17.4,0,0,0,4.5-.6L128,180.3V192a16,16,0,0,0,16,16h32a16,16,0,0,0,16-16V161.7l28.5-8.3A16.1,16.1,0,0,0,232,138V102A16.1,16.1,0,0,0,220.5,86.6ZM128,163.7,40,189.3V50.7l88,25.6ZM176,192H144V175.7l32-9.4Zm40-54-34.4,10h0L144,159V81l72,21Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M220.5,86.6,138.4,62.7h-.1L44.5,35.3a15.8,15.8,0,0,0-14.1,2.6A15.7,15.7,0,0,0,24,50.7V189.3a15.7,15.7,0,0,0,6.4,12.8,16.1,16.1,0,0,0,9.6,3.2,17.4,17.4,0,0,0,4.5-.6L128,180.3V192a16,16,0,0,0,16,16h32a16,16,0,0,0,16-16V161.7l28.5-8.3A16.1,16.1,0,0,0,232,138V102A16.1,16.1,0,0,0,220.5,86.6ZM176,192H144V175.7l32-9.4Zm40-54-34.4,10h0L144,159V81l72,21Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M219.9,88.6l-82.1-24h0L43.9,37.2a14,14,0,0,0-12.3,2.3A13.6,13.6,0,0,0,26,50.7V189.3a14,14,0,0,0,14,14,13.1,13.1,0,0,0,3.9-.5L130,177.7V192a14,14,0,0,0,14,14h32a14,14,0,0,0,14-14V160.2l29.9-8.8A13.9,13.9,0,0,0,230,138V102A13.9,13.9,0,0,0,219.9,88.6ZM40.6,191.3a2.1,2.1,0,0,1-2.6-2V50.7a1.9,1.9,0,0,1,.8-1.6,1.7,1.7,0,0,1,1.2-.4h.6L130,74.8v90.4ZM178,192a2,2,0,0,1-2,2H144a2,2,0,0,1-2-2V174.2l36-10.5Zm40-54a2,2,0,0,1-1.4,1.9l-34.4,10h0L142,161.7V78.3l74.6,21.8A2,2,0,0,1,218,102Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M219.4,90.5,43.4,39.1A12.1,12.1,0,0,0,28,50.7V189.3a12.1,12.1,0,0,0,4.8,9.6,11.8,11.8,0,0,0,7.2,2.4,13.7,13.7,0,0,0,3.4-.4L132,175v17a12,12,0,0,0,12,12h32a12,12,0,0,0,12-12V158.7l31.4-9.2A12.1,12.1,0,0,0,228,138V102A12.1,12.1,0,0,0,219.4,90.5ZM41.1,193.2a4,4,0,0,1-3.5-.7,3.9,3.9,0,0,1-1.6-3.2V50.7a3.9,3.9,0,0,1,1.6-3.2,3.5,3.5,0,0,1,2.4-.8h1.1L132,73.3v93.4ZM180,192a4,4,0,0,1-4,4H144a4,4,0,0,1-4-4V172.7L180,161Zm40-54a4,4,0,0,1-2.9,3.8L140,164.3V75.7l77.1,22.5A4,4,0,0,1,220,102Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M220.5,86.6,138.4,62.7h-.1L44.5,35.3a15.8,15.8,0,0,0-14.1,2.6A15.7,15.7,0,0,0,24,50.7V189.3a15.7,15.7,0,0,0,6.4,12.8,16.1,16.1,0,0,0,9.6,3.2,17.4,17.4,0,0,0,4.5-.6L128,180.3V192a16,16,0,0,0,16,16h32a16,16,0,0,0,16-16V161.7l28.5-8.3A16.1,16.1,0,0,0,232,138V102A16.1,16.1,0,0,0,220.5,86.6ZM40,189.3V50.7l88,25.6v87.4ZM176,192H144V175.7l32-9.4Zm40-54-34.4,10h0L144,159V81l72,21Z" />
        </>
      )}
    </svg>
  );
}

export function PhMegaphone(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M244,120a52,52,0,0,0-52-52H152c-.5,0-51-.8-99.1-41.1A20,20,0,0,0,20,42.2V197.8a19.7,19.7,0,0,0,11.5,18.1,20.3,20.3,0,0,0,8.5,2,19.7,19.7,0,0,0,12.9-4.8A180.1,180.1,0,0,1,132,174.6v28.5a19.7,19.7,0,0,0,11.1,17.8l18.6,9.4a20.2,20.2,0,0,0,17.3.3,19.9,19.9,0,0,0,11.1-13.4l11.5-46.1A52.1,52.1,0,0,0,244,120ZM44,189.5V50.5a200.9,200.9,0,0,0,88,39.4v60.2A200.9,200.9,0,0,0,44,189.5Zm124,17.1-12-6V172h20.6ZM192,148H156V92h36a28,28,0,0,1,0,56Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M152,160h40a40,40,0,0,0,0-80H152Z" />
          </g>
          <path d="M240,120a48,48,0,0,0-48-48H152c-.5,0-52.4-.7-101.7-42.1a15.9,15.9,0,0,0-17.1-2.2A15.7,15.7,0,0,0,24,42.2V197.8a15.7,15.7,0,0,0,9.2,14.5,16.4,16.4,0,0,0,6.8,1.5,15.9,15.9,0,0,0,10.3-3.7c37.9-31.8,77.2-39.6,93.7-41.5v35.1a15.9,15.9,0,0,0,7.1,13.3l11,7.4a16.8,16.8,0,0,0,14.7,1.6,15.9,15.9,0,0,0,9.7-11.1l11.9-47.3A48.2,48.2,0,0,0,240,120ZM40,197.8V42.2h0C82.7,78,126.4,85.8,144,87.5v65C126.4,154.2,82.7,162,40,197.8ZM171,211l-11-7.3V168h21.8Zm21-59H160V88h32a32,32,0,0,1,0,64Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M240,120a48,48,0,0,0-48-48H152c-.5,0-52.4-.7-101.7-42.1a15.9,15.9,0,0,0-17.1-2.2A15.7,15.7,0,0,0,24,42.2V197.8a15.7,15.7,0,0,0,9.2,14.5,16.4,16.4,0,0,0,6.8,1.5,15.9,15.9,0,0,0,10.3-3.7c37.9-31.8,77.2-39.6,93.7-41.5v35.1a15.9,15.9,0,0,0,7.1,13.3l11,7.4a16.8,16.8,0,0,0,14.7,1.6,15.9,15.9,0,0,0,9.7-11.1l11.9-47.3A48.2,48.2,0,0,0,240,120Zm-69,91-11-7.3V168h21.8Zm21-59H160V88h32a32,32,0,0,1,0,64Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M238,120a46,46,0,0,0-46-46H152c-.5,0-52.8-.5-103-42.5A14,14,0,0,0,26,42.2V197.8a13.9,13.9,0,0,0,8.1,12.7,13.7,13.7,0,0,0,5.9,1.3,13.9,13.9,0,0,0,9-3.3c40.1-33.6,81.8-40.6,97-42.1v37.3a14,14,0,0,0,6.2,11.7l11,7.3A14.8,14.8,0,0,0,171,225a14.3,14.3,0,0,0,5-.9,13.8,13.8,0,0,0,8.6-9.7l12.1-48.6A46.1,46.1,0,0,0,238,120ZM41.3,199.4a2,2,0,0,1-2.2.2,1.9,1.9,0,0,1-1.1-1.8V42.2a1.9,1.9,0,0,1,1.1-1.8l.9-.2a1.8,1.8,0,0,1,1.3.5c43.9,36.8,88.8,43.7,104.7,45v68.6C130.1,155.6,85.2,162.5,41.3,199.4Zm131.6,12.1a2,2,0,0,1-3,1.2l-11-7.3a2,2,0,0,1-.9-1.7V166h26.3ZM192,154H158V86h34a34,34,0,0,1,0,68Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M236,120a44,44,0,0,0-44-44H152c-.5,0-53.6-.5-104.3-43A12,12,0,0,0,28,42.2V197.8a12,12,0,0,0,6.9,10.9,11.7,11.7,0,0,0,5.1,1.1,11.6,11.6,0,0,0,7.7-2.8c42.6-35.7,87-41.8,100.3-42.8v39.5a12.1,12.1,0,0,0,5.3,10l11,7.3a11.7,11.7,0,0,0,6.7,2,10.6,10.6,0,0,0,4.3-.8,11.8,11.8,0,0,0,7.3-8.2l12.6-50.1A44.2,44.2,0,0,0,236,120ZM42.6,200.9a3.9,3.9,0,0,1-4.3.5,3.8,3.8,0,0,1-2.3-3.6V42.2a3.8,3.8,0,0,1,2.3-3.6,3.4,3.4,0,0,1,1.8-.4,4,4,0,0,1,2.5.9C87.9,77.2,134.3,83,148,83.8v72.4C134.3,157,87.9,162.8,42.6,200.9ZM174.9,212a3.9,3.9,0,0,1-2.5,2.8,3.9,3.9,0,0,1-3.6-.4l-11-7.4a3.9,3.9,0,0,1-1.8-3.3V164h30.9ZM192,156H156V84h36a36,36,0,0,1,0,72Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M240,120a48,48,0,0,0-48-48H152c-.5,0-52.4-.7-101.7-42.1a15.9,15.9,0,0,0-17.1-2.2A15.7,15.7,0,0,0,24,42.2V197.8a15.7,15.7,0,0,0,9.2,14.5,16.4,16.4,0,0,0,6.8,1.5,15.9,15.9,0,0,0,10.3-3.7c37.9-31.8,77.2-39.6,93.7-41.5v35.1a15.9,15.9,0,0,0,7.1,13.3l11,7.4a16.8,16.8,0,0,0,14.7,1.6,15.9,15.9,0,0,0,9.7-11.1l11.9-47.3A48.2,48.2,0,0,0,240,120ZM40,197.8V42.2h0C82.7,78,126.4,85.8,144,87.5v65C126.4,154.2,82.7,162,40,197.8ZM171,211l-11-7.3V168h21.8Zm21-59H160V88h32a32,32,0,0,1,0,64Z" />
        </>
      )}
    </svg>
  );
}

export function PhMessengerLogo(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,20A108.1,108.1,0,0,0,32.5,178.5l-8.1,28.4a20,20,0,0,0,24.7,24.7l28.4-8.1A108,108,0,1,0,128,20Zm0,192a85.1,85.1,0,0,1-42.9-11.7,11,11,0,0,0-6.1-1.7,12.4,12.4,0,0,0-3.3.4l-26.2,7.5L57,180.3a11.9,11.9,0,0,0-1.3-9.4A85.1,85.1,0,0,1,44,128a84,84,0,1,1,84,84Zm56.5-91.5-32,32a12.1,12.1,0,0,1-17,0L112,129,88.5,152.5a12,12,0,0,1-17-17l32-32a12,12,0,0,1,17,0L144,127l23.5-23.5a12,12,0,0,1,17,17Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M224,128A96.1,96.1,0,0,1,79,210.6h0L45.8,220a7.9,7.9,0,0,1-9.8-9.8L45.4,177h0A96,96,0,1,1,224,128Z"
            opacity="0.2"
          />
          <path d="M128,24A104,104,0,0,0,36.8,178l-8.5,30A15.9,15.9,0,0,0,48,227.7l30-8.5A104,104,0,1,0,128,24Zm0,192a88.4,88.4,0,0,1-44.9-12.3,8.7,8.7,0,0,0-4.1-1.1,8.3,8.3,0,0,0-2.2.3l-33.2,9.5,9.5-33.2a8.2,8.2,0,0,0-.8-6.3A88,88,0,1,1,128,216Zm53.7-109.7a8.1,8.1,0,0,1,0,11.4l-32,32a8.2,8.2,0,0,1-11.4,0L112,123.3,85.7,149.7a8.1,8.1,0,0,1-11.4-11.4l32-32a8.1,8.1,0,0,1,11.4,0L144,132.7l26.3-26.4A8.1,8.1,0,0,1,181.7,106.3Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,24A104,104,0,0,0,36.8,178l-8.5,30A15.9,15.9,0,0,0,48,227.7l30-8.5A104,104,0,1,0,128,24Zm53.7,93.7-32,32a8.2,8.2,0,0,1-11.4,0L112,123.3,85.7,149.7a8.1,8.1,0,0,1-11.4-11.4l32-32a8.1,8.1,0,0,1,11.4,0L144,132.7l26.3-26.4a8.1,8.1,0,0,1,11.4,11.4Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,26A102,102,0,0,0,39,177.8l-8.8,30.7a13.9,13.9,0,0,0,17.3,17.3L78.2,217A102,102,0,1,0,128,26Zm0,192a90,90,0,0,1-45.9-12.6,6.8,6.8,0,0,0-3.1-.8,4.1,4.1,0,0,0-1.6.2l-33.2,9.5a2,2,0,0,1-2.5-2.5l9.5-33.2a6,6,0,0,0-.6-4.7A90,90,0,1,1,128,218Zm52.2-110.2a5.8,5.8,0,0,1,0,8.4l-32,32a5.8,5.8,0,0,1-8.4,0L112,120.5,84.2,148.2a5.9,5.9,0,0,1-8.4-8.4l32-32a5.8,5.8,0,0,1,8.4,0L144,135.5l27.8-27.7A5.8,5.8,0,0,1,180.2,107.8Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,28A100,100,0,0,0,41.1,177.5l-9,31.6a12,12,0,0,0,14.8,14.8l31.6-9A100,100,0,1,0,128,28Zm0,192a91.3,91.3,0,0,1-47-12.9,4.6,4.6,0,0,0-2-.5H77.9l-33.2,9.5a4,4,0,0,1-4.9-4.9l9.5-33.2a4.1,4.1,0,0,0-.4-3.1A92,92,0,1,1,128,220Zm50.8-110.8a3.9,3.9,0,0,1,0,5.6l-32,32a3.9,3.9,0,0,1-5.6,0L112,117.7,82.8,146.8a4,4,0,0,1-5.6-5.6l32-32a3.9,3.9,0,0,1,5.6,0L144,138.3l29.2-29.1A3.9,3.9,0,0,1,178.8,109.2Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,24A104,104,0,0,0,36.8,178l-8.5,30A15.9,15.9,0,0,0,48,227.7l30-8.5A104,104,0,1,0,128,24Zm0,192a88.4,88.4,0,0,1-44.9-12.3,8.7,8.7,0,0,0-4.1-1.1,8.3,8.3,0,0,0-2.2.3l-33.2,9.5,9.5-33.2a8.2,8.2,0,0,0-.8-6.3A88,88,0,1,1,128,216Zm53.7-109.7a8.1,8.1,0,0,1,0,11.4l-32,32a8.2,8.2,0,0,1-11.4,0L112,123.3,85.7,149.7a8.1,8.1,0,0,1-11.4-11.4l32-32a8.1,8.1,0,0,1,11.4,0L144,132.7l26.3-26.4A8.1,8.1,0,0,1,181.7,106.3Z" />
        </>
      )}
    </svg>
  );
}

export function PhMicrophoneSlash(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M56.9,31.9A12,12,0,0,0,39.1,48.1L76,88.6V120a52,52,0,0,0,52,52,52.6,52.6,0,0,0,20.1-4l11.3,12.4A68.1,68.1,0,0,1,60,120a12,12,0,0,0-24,0,92.1,92.1,0,0,0,80,91.2V232a12,12,0,0,0,24,0V211.2a92,92,0,0,0,35.9-12.7l23.2,25.6a12.1,12.1,0,0,0,17,.8,12.1,12.1,0,0,0,.8-17ZM128,148a28.1,28.1,0,0,1-28-28v-5l29.9,32.9ZM90.7,45.3a12,12,0,0,1-.5-17A52,52,0,0,1,180,64v48.5a12,12,0,0,1-24,0V64a28.1,28.1,0,0,0-28-28,27.8,27.8,0,0,0-20.4,8.8A12,12,0,0,1,90.7,45.3ZM191,145.6a67.5,67.5,0,0,0,5-25.6,12,12,0,0,1,24,0,92.6,92.6,0,0,1-6.7,34.6,12,12,0,0,1-22.3-9Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect x="88" y="24" width="80" height="144" rx="40" />
          </g>
          <path d="M213.9,210.6l-160-176A8,8,0,0,0,42.1,45.4L80,87.1V128a48,48,0,0,0,73.9,40.4l10.9,12A64,64,0,0,1,64.4,135.1a8,8,0,1,0-15.9,1.8A79.9,79.9,0,0,0,120,207.6V232a8,8,0,0,0,16,0V207.6a79.2,79.2,0,0,0,39.6-15.3l26.5,29.1A8,8,0,0,0,208,224a8.2,8.2,0,0,0,5.4-2.1A7.9,7.9,0,0,0,213.9,210.6ZM128,160a32.1,32.1,0,0,1-32-32V104.7l46.9,51.6A31.6,31.6,0,0,1,128,160Z" />
          <path d="M89.7,49.8a8.1,8.1,0,0,0,11.1-2.6A31.7,31.7,0,0,1,128,32a32.1,32.1,0,0,1,32,32v60.4a8,8,0,0,0,16,0V64a48,48,0,0,0-48-48A47.5,47.5,0,0,0,87.2,38.8,8,8,0,0,0,89.7,49.8Z" />
          <path d="M192.2,161.7a9.3,9.3,0,0,0,2.9.5,7.8,7.8,0,0,0,7.4-5.1,78.7,78.7,0,0,0,5-20.2,8,8,0,1,0-15.9-1.8,60.1,60.1,0,0,1-4,16.2A8.1,8.1,0,0,0,192.2,161.7Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M213.4,221.9A8.2,8.2,0,0,1,208,224a8,8,0,0,1-5.9-2.6l-26.5-29.1A79.2,79.2,0,0,1,136,207.6V232a8,8,0,0,1-16,0V207.6a79.9,79.9,0,0,1-71.5-70.7,8,8,0,1,1,15.9-1.8,64,64,0,0,0,100.4,45.3l-10.9-12A48,48,0,0,1,80,128V87.1L42.1,45.4A8,8,0,0,1,53.9,34.6l160,176A7.9,7.9,0,0,1,213.4,221.9Zm-51.3-92.1a7.9,7.9,0,0,0,5.9,2.6,7.4,7.4,0,0,0,2.9-.5,8,8,0,0,0,5.1-7.5V64a48,48,0,0,0-48-48A47.5,47.5,0,0,0,87.2,38.8a8,8,0,0,0,.8,9.6Zm30.1,31.9a9.3,9.3,0,0,0,2.9.5,7.8,7.8,0,0,0,7.4-5.1,78.7,78.7,0,0,0,5-20.2,8,8,0,1,0-15.9-1.8,60.1,60.1,0,0,1-4,16.2A8.1,8.1,0,0,0,192.2,161.7Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M193.3,164.3a5.7,5.7,0,0,1-2.5-.6,6,6,0,0,1-2.9-8,65.3,65.3,0,0,0,5.7-20.4,6,6,0,1,1,11.9,1.4,78,78,0,0,1-6.7,24.1A6,6,0,0,1,193.3,164.3ZM212,220.4a5.7,5.7,0,0,1-4,1.6,5.9,5.9,0,0,1-4.4-2l-27.7-30.4A78.1,78.1,0,0,1,134,205.8V232a6,6,0,0,1-12,0V205.8a77.9,77.9,0,0,1-71.5-69.1,6,6,0,1,1,11.9-1.4,66.1,66.1,0,0,0,105.4,45.4l-13.6-14.9A46,46,0,0,1,82,128V86.3L43.6,44a5.9,5.9,0,0,1,8.8-8L159.3,153.6h0L212.4,212A5.8,5.8,0,0,1,212,220.4Zm-65.9-63.6L94,99.5V128a34,34,0,0,0,52.1,28.8ZM90.8,48.1a6,6,0,0,0,8.3-2A33.6,33.6,0,0,1,128,30a34.1,34.1,0,0,1,34,34v60.4a6,6,0,0,0,12,0V64A46,46,0,0,0,88.9,39.8,6,6,0,0,0,90.8,48.1Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M211,213.3l-53.1-58.4h0L51,37.3a4,4,0,1,0-6,5.4L84,85.5V128a44,44,0,0,0,70.5,35.1l16.2,17.8A68,68,0,0,1,60.4,135.6,4,4,0,0,0,56,132a3.9,3.9,0,0,0-3.5,4.4A75.8,75.8,0,0,0,124,203.9V232a4,4,0,0,0,8,0V203.9a75.7,75.7,0,0,0,44.1-17.1L205,218.7a4.1,4.1,0,0,0,5.7.3A4.2,4.2,0,0,0,211,213.3ZM128,164a36,36,0,0,1-36-36V94.3l57.1,62.9A36.1,36.1,0,0,1,128,164ZM90.6,40.9A43.5,43.5,0,0,1,128,20a44,44,0,0,1,44,44v60.4a4,4,0,0,1-8,0V64A36,36,0,0,0,97.4,45.1a4.1,4.1,0,0,1-5.5,1.3A3.9,3.9,0,0,1,90.6,40.9ZM191.4,152.8a73.3,73.3,0,0,0,4.2-17.2A4,4,0,0,1,200,132a3.9,3.9,0,0,1,3.5,4.4,75.6,75.6,0,0,1-4.7,19.3,3.9,3.9,0,0,1-3.7,2.5l-1.5-.3A3.9,3.9,0,0,1,191.4,152.8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M213.9,210.6l-160-176A8,8,0,0,0,42.1,45.4L80,87.1V128a48,48,0,0,0,73.9,40.4l10.9,12A64,64,0,0,1,64.4,135.1a8,8,0,1,0-15.9,1.8A79.9,79.9,0,0,0,120,207.6V232a8,8,0,0,0,16,0V207.6a79.2,79.2,0,0,0,39.6-15.3l26.5,29.1A8,8,0,0,0,208,224a8.2,8.2,0,0,0,5.4-2.1A7.9,7.9,0,0,0,213.9,210.6ZM128,160a32.1,32.1,0,0,1-32-32V104.7l46.9,51.6A31.6,31.6,0,0,1,128,160ZM87.2,38.8A47.5,47.5,0,0,1,128,16a48,48,0,0,1,48,48v60.4a8,8,0,0,1-16,0V64a32.1,32.1,0,0,0-32-32,31.7,31.7,0,0,0-27.2,15.2,8.1,8.1,0,0,1-11.1,2.6A8,8,0,0,1,87.2,38.8ZM187.6,151.3a60.1,60.1,0,0,0,4-16.2,8,8,0,1,1,15.9,1.8,78.7,78.7,0,0,1-5,20.2,7.8,7.8,0,0,1-7.4,5.1,9.3,9.3,0,0,1-2.9-.5A8.1,8.1,0,0,1,187.6,151.3Z" />
        </>
      )}
    </svg>
  );
}

export function PhMicrophoneStage(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M222.6,140.8A76,76,0,0,0,115.2,33.4l-.9.9-.9.9A75.9,75.9,0,0,0,92,88q0,3.3.3,6.6L18.4,195.4a19.7,19.7,0,0,0,2,25.9l14.3,14.3a19.8,19.8,0,0,0,25.9,2l100.8-73.9q3.3.3,6.6.3a75.9,75.9,0,0,0,52.8-21.4l.9-.9ZM220,88a51.2,51.2,0,0,1-7.8,27.3L140.7,43.8A51.2,51.2,0,0,1,168,36,52,52,0,0,1,220,88ZM116,88a51.2,51.2,0,0,1,7.8-27.3l71.5,71.5A51.2,51.2,0,0,1,168,140,52,52,0,0,1,116,88ZM49.2,216.2l-9.4-9.4,61-83.3a76.9,76.9,0,0,0,31.7,31.7Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M104.8,97.9Zm53.3,53.3ZM104.8,97.9,28.1,202.5a7.9,7.9,0,0,0,.8,10.4l14.2,14.2a7.9,7.9,0,0,0,10.4.8l104.6-76.7A63.9,63.9,0,0,1,104.8,97.9Z"
            opacity="0.2"
          />
          <path d="M219.5,138.2A71.9,71.9,0,0,0,117.8,36.5l-.7.6-.6.7A71.6,71.6,0,0,0,96,88a65.1,65.1,0,0,0,.4,7.7l-74.8,102a16,16,0,0,0,1.6,20.8l14.3,14.3a16,16,0,0,0,20.8,1.6l102-74.8a65.1,65.1,0,0,0,7.7.4,71.6,71.6,0,0,0,50.2-20.5l.7-.6ZM224,88a55.5,55.5,0,0,1-11.2,33.5L134.5,43.2A55.9,55.9,0,0,1,224,88ZM48.8,221.5,34.5,207.2l67.1-91.4a72.2,72.2,0,0,0,38.6,38.6ZM112,88a55.5,55.5,0,0,1,11.2-33.5l78.3,78.3A55.9,55.9,0,0,1,112,88Zm-6.3,62.3a8.1,8.1,0,0,1,0,11.4l-12,12a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4l12-12A8.1,8.1,0,0,1,105.7,150.3Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M115,46.4a4,4,0,0,0-6.1.5A71.9,71.9,0,0,0,96,88a65.1,65.1,0,0,0,.4,7.7l-74.8,102a16,16,0,0,0,1.6,20.8l14.3,14.3a16,16,0,0,0,20.8,1.6l102-74.8a65.1,65.1,0,0,0,7.7.4,71.9,71.9,0,0,0,41.1-12.9,4,4,0,0,0,.5-6.1Zm-1.3,107.3-20,20a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4l20-20a8.1,8.1,0,0,1,11.4,11.4Zm113.4-24.6a4,4,0,0,1-6.1.5L126.4,35a4,4,0,0,1,.5-6.1A72,72,0,0,1,227.1,129.1Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M217.8,137.2a70,70,0,0,0-99-99l-.3.3-.3.3A70,70,0,0,0,98,88a77,77,0,0,0,.5,8.3L23.2,198.9a14,14,0,0,0,1.4,18.2l14.3,14.3a14,14,0,0,0,9.9,4.1,14.2,14.2,0,0,0,8.3-2.7l102.6-75.3a77,77,0,0,0,8.3.5,70,70,0,0,0,49.2-20.2l.3-.3ZM226,88a57.8,57.8,0,0,1-13,36.5L131.5,43A57.9,57.9,0,0,1,226,88ZM50,223.1a2,2,0,0,1-2.6-.2L33.1,208.6a2,2,0,0,1-.2-2.6l69.2-94.4a70.8,70.8,0,0,0,42.3,42.3ZM110,88a57.8,57.8,0,0,1,13-36.5L204.5,133A57.9,57.9,0,0,1,110,88Zm-5.8,63.8a5.8,5.8,0,0,1,0,8.4l-12,12a5.9,5.9,0,0,1-8.4-8.4l12-12A5.8,5.8,0,0,1,104.2,151.8Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M216.3,135.8a68,68,0,0,0-96.1-96.1l-.3.2c-.1.1-.2.2-.2.3A67.7,67.7,0,0,0,100,88a64,64,0,0,0,.6,8.8L24.8,200.1A12,12,0,0,0,26,215.7L40.3,230a12.1,12.1,0,0,0,15.6,1.2l103.3-75.8a64,64,0,0,0,8.8.6,67.7,67.7,0,0,0,47.8-19.7l.3-.2ZM228,88a59.7,59.7,0,0,1-14.9,39.5L128.5,42.9A59.9,59.9,0,0,1,228,88ZM51.2,224.7a4,4,0,0,1-5.2-.4L31.7,210a4,4,0,0,1-.4-5.2l71.5-97.5a68.2,68.2,0,0,0,45.9,45.9ZM108,88a59.7,59.7,0,0,1,14.9-39.5l84.6,84.6A59.9,59.9,0,0,1,108,88Zm-5.2,65.2a3.9,3.9,0,0,1,0,5.6l-12,12a3.9,3.9,0,0,1-5.6,0,3.9,3.9,0,0,1,0-5.6l12-12A3.9,3.9,0,0,1,102.8,153.2Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M219.5,138.2A71.9,71.9,0,0,0,117.8,36.5l-.7.6-.6.7A71.6,71.6,0,0,0,96,88a65.1,65.1,0,0,0,.4,7.7l-74.8,102a16,16,0,0,0,1.6,20.8l14.3,14.3a16,16,0,0,0,20.8,1.6l102-74.8a65.1,65.1,0,0,0,7.7.4,71.6,71.6,0,0,0,50.2-20.5l.7-.6ZM224,88a55.5,55.5,0,0,1-11.2,33.5L134.5,43.2A55.9,55.9,0,0,1,224,88ZM48.8,221.5,34.5,207.2l67.1-91.4a72.2,72.2,0,0,0,38.6,38.6ZM112,88a55.5,55.5,0,0,1,11.2-33.5l78.3,78.3A55.9,55.9,0,0,1,112,88Zm-6.3,62.3a8.1,8.1,0,0,1,0,11.4l-12,12a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4l12-12A8.1,8.1,0,0,1,105.7,150.3Z" />
        </>
      )}
    </svg>
  );
}

export function PhMicrophone(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,172a52,52,0,0,0,52-52V64A52,52,0,0,0,76,64v56A52,52,0,0,0,128,172ZM100,64a28,28,0,0,1,56,0v56a28,28,0,0,1-56,0Zm40,147.2V232a12,12,0,0,1-24,0V211.2A92.1,92.1,0,0,1,36,120a12,12,0,0,1,24,0,68,68,0,0,0,136,0,12,12,0,0,1,24,0A92.1,92.1,0,0,1,140,211.2Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect x="88" y="24" width="80" height="144" rx="40" />
          </g>
          <path d="M128,176a48,48,0,0,0,48-48V64a48,48,0,0,0-96,0v64A48,48,0,0,0,128,176ZM96,64a32,32,0,0,1,64,0v64a32,32,0,0,1-64,0Z" />
          <path d="M200.4,128a8.1,8.1,0,0,0-8.8,7.1,64,64,0,0,1-127.2,0,8,8,0,1,0-15.9,1.8A79.9,79.9,0,0,0,120,207.6V232a8,8,0,0,0,16,0V207.6a79.9,79.9,0,0,0,71.5-70.7A8.1,8.1,0,0,0,200.4,128Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M80,128V64a48,48,0,0,1,96,0v64a48,48,0,0,1-96,0Zm120.4.1a8,8,0,0,0-8.8,7,64,64,0,0,1-127.2,0,8,8,0,0,0-8.8-7,7.9,7.9,0,0,0-7.1,8.8A79.7,79.7,0,0,0,120,207.3V232a8,8,0,0,0,16,0V207.3a79.7,79.7,0,0,0,71.5-70.4A7.9,7.9,0,0,0,200.4,128.1Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,174a46,46,0,0,0,46-46V64a46,46,0,0,0-92,0v64A46,46,0,0,0,128,174ZM94,64a34,34,0,0,1,68,0v64a34,34,0,0,1-68,0Zm111.5,72.7A77.9,77.9,0,0,1,134,205.8V232a6,6,0,0,1-12,0V205.8a77.9,77.9,0,0,1-71.5-69.1,6,6,0,1,1,11.9-1.4,66,66,0,0,0,131.2,0,6,6,0,1,1,11.9,1.4Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,172a44,44,0,0,0,44-44V64a44,44,0,0,0-88,0v64A44,44,0,0,0,128,172ZM92,64a36,36,0,0,1,72,0v64a36,36,0,0,1-72,0Zm111.5,72.4A75.8,75.8,0,0,1,132,203.9V232a4,4,0,0,1-8,0V203.9a75.8,75.8,0,0,1-71.5-67.5A3.9,3.9,0,0,1,56,132a4,4,0,0,1,4.4,3.6,68,68,0,0,0,135.2,0A4,4,0,0,1,200,132,3.9,3.9,0,0,1,203.5,136.4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,176a48,48,0,0,0,48-48V64a48,48,0,0,0-96,0v64A48,48,0,0,0,128,176ZM96,64a32,32,0,0,1,64,0v64a32,32,0,0,1-64,0Zm111.5,72.9A79.9,79.9,0,0,1,136,207.6V232a8,8,0,0,1-16,0V207.6a79.9,79.9,0,0,1-71.5-70.7,8,8,0,1,1,15.9-1.8,64,64,0,0,0,127.2,0,8,8,0,1,1,15.9,1.8Z" />
        </>
      )}
    </svg>
  );
}

export function PhMicrosoftExcelLogo(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M200,20H72A20.1,20.1,0,0,0,52,40V56H40A20.1,20.1,0,0,0,20,76V180a20.1,20.1,0,0,0,20,20H52v16a20.1,20.1,0,0,0,20,20H200a20.1,20.1,0,0,0,20-20V40A20.1,20.1,0,0,0,200,20Zm-28,88h24v40H172Zm24-24H172V76a20.1,20.1,0,0,0-20-20h-4V44h48ZM76,44h48V56H76ZM44,80H148v96H44ZM76,200h48v12H76Zm72,12V200h4a20.1,20.1,0,0,0,20-20v-8h24v40ZM68.4,144.8,81,128,68.4,111.2A12,12,0,1,1,87.6,96.8L96,108l8.4-11.2a12,12,0,0,1,19.2,14.4L111,128l12.6,16.8a12,12,0,0,1-2.4,16.8,12,12,0,0,1-16.8-2.4L96,148l-8.4,11.2a12,12,0,0,1-16.8,2.4A12,12,0,0,1,68.4,144.8Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M152,80v96a8,8,0,0,1-8,8H40a8,8,0,0,1-8-8V80a8,8,0,0,1,8-8H144A8,8,0,0,1,152,80Z"
            opacity="0.2"
          />
          <path d="M200,24H72A16,16,0,0,0,56,40V64H40A16,16,0,0,0,24,80v96a16,16,0,0,0,16,16H56v24a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V40A16,16,0,0,0,200,24Zm-40,80h40v48H160Zm40-16H160V80a16,16,0,0,0-16-16V40h56ZM72,40h56V64H72ZM40,80H144V96h0v80H40ZM72,192h56v24H72Zm72,24V192a16,16,0,0,0,16-16v-8h40v48ZM67.6,147.2,82,128,67.6,108.8a8,8,0,1,1,12.8-9.6L92,114.7l11.6-15.5a8,8,0,0,1,12.8,9.6L102,128l14.4,19.2a8,8,0,0,1-1.6,11.2A7.7,7.7,0,0,1,110,160a8,8,0,0,1-6.4-3.2L92,141.3,80.4,156.8A8,8,0,0,1,74,160a7.7,7.7,0,0,1-4.8-1.6A8,8,0,0,1,67.6,147.2Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M200,24H72A16,16,0,0,0,56,40V64H40A16,16,0,0,0,24,80v96a16,16,0,0,0,16,16H56v24a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V40A16,16,0,0,0,200,24ZM69.2,158.4a8,8,0,0,1-1.6-11.2L82,128,67.6,108.8a8,8,0,1,1,12.8-9.6L92,114.7l11.6-15.5a8,8,0,0,1,12.8,9.6L102,128l14.4,19.2a8,8,0,0,1-1.6,11.2A7.7,7.7,0,0,1,110,160a8,8,0,0,1-6.4-3.2L92,141.3,80.4,156.8A8,8,0,0,1,74,160,7.7,7.7,0,0,1,69.2,158.4ZM128,216H72V192h56Zm0-152H72V40h56Zm72,152H144V192a16,16,0,0,0,16-16v-8h40Zm0-64H160V104h40Zm0-64H160V80a16,16,0,0,0-16-16V40h56Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M200,26H72A14,14,0,0,0,58,40V66H40A14,14,0,0,0,26,80v96a14,14,0,0,0,14,14H58v26a14,14,0,0,0,14,14H200a14,14,0,0,0,14-14V40A14,14,0,0,0,200,26Zm-42,76h44v52H158Zm44-62V90H158V80a14,14,0,0,0-14-14h-2V38h58A2,2,0,0,1,202,40ZM70,40a2,2,0,0,1,2-2h58V66H70ZM38,176V80a2,2,0,0,1,2-2H144a2,2,0,0,1,2,2v96a2,2,0,0,1-2,2H40A2,2,0,0,1,38,176Zm32,40V190h60v28H72A2,2,0,0,1,70,216Zm130,2H142V190h2a14,14,0,0,0,14-14V166h44v50A2,2,0,0,1,200,218ZM69.2,148.4,84.5,128,69.2,107.6a6,6,0,1,1,9.6-7.2L92,118l13.2-17.6a6,6,0,0,1,9.6,7.2L99.5,128l15.3,20.4a6,6,0,0,1-9.6,7.2L92,138,78.8,155.6a6,6,0,1,1-9.6-7.2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M200,28H72A12,12,0,0,0,60,40V68H40A12,12,0,0,0,28,80v96a12,12,0,0,0,12,12H60v28a12,12,0,0,0,12,12H200a12,12,0,0,0,12-12V40A12,12,0,0,0,200,28Zm-44,72h48v56H156Zm48-60V92H156V80a12,12,0,0,0-12-12h-4V36h60A4,4,0,0,1,204,40ZM68,40a4,4,0,0,1,4-4h60V68H68ZM36,176V80a4,4,0,0,1,4-4H144a4,4,0,0,1,4,4v96a4,4,0,0,1-4,4H40A4,4,0,0,1,36,176Zm32,40V188h64v32H72A4,4,0,0,1,68,216Zm132,4H140V188h4a12,12,0,0,0,12-12V164h48v52A4,4,0,0,1,200,220ZM70.8,149.6,87,128,70.8,106.4a4,4,0,0,1,6.4-4.8L92,121.3l14.8-19.7a4,4,0,1,1,6.4,4.8L97,128l16.2,21.6a4,4,0,0,1-.8,5.6,4.3,4.3,0,0,1-2.4.8,4.2,4.2,0,0,1-3.2-1.6L92,134.7,77.2,154.4A4.2,4.2,0,0,1,74,156a4.3,4.3,0,0,1-2.4-.8A4,4,0,0,1,70.8,149.6Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M200,24H72A16,16,0,0,0,56,40V64H40A16,16,0,0,0,24,80v96a16,16,0,0,0,16,16H56v24a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V40A16,16,0,0,0,200,24Zm-40,80h40v48H160Zm40-16H160V80a16,16,0,0,0-16-16V40h56ZM72,40h56V64H72ZM40,80H144V96h0v80H40ZM72,192h56v24H72Zm72,24V192a16,16,0,0,0,16-16v-8h40v48ZM67.6,147.2,82,128,67.6,108.8a8,8,0,1,1,12.8-9.6L92,114.7l11.6-15.5a8,8,0,0,1,12.8,9.6L102,128l14.4,19.2a8,8,0,0,1-1.6,11.2A7.7,7.7,0,0,1,110,160a8,8,0,0,1-6.4-3.2L92,141.3,80.4,156.8A8,8,0,0,1,74,160a7.7,7.7,0,0,1-4.8-1.6A8,8,0,0,1,67.6,147.2Z" />
        </>
      )}
    </svg>
  );
}

export function PhMicrosoftPowerpointLogo(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M98,92H82a12,12,0,0,0-12,12v48a12,12,0,0,0,24,0v-4h4a28,28,0,0,0,0-56Zm0,32H94v-8h4a4,4,0,0,1,0,8ZM136,20A107.6,107.6,0,0,0,55.5,56H40A20.1,20.1,0,0,0,20,76V180a20.1,20.1,0,0,0,20,20H55.5A108,108,0,1,0,136,20Zm0,24a84,84,0,0,1,83.1,72H172V76a20.1,20.1,0,0,0-20-20H92.7A83.5,83.5,0,0,1,136,44ZM44,80H148v96H44Zm92,132a83.5,83.5,0,0,1-43.3-12H152a20.1,20.1,0,0,0,20-20V140h47.1A84,84,0,0,1,136,212Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M152,80v96a8,8,0,0,1-8,8H40a8,8,0,0,1-8-8V80a8,8,0,0,1,8-8H144A8,8,0,0,1,152,80Z"
            opacity="0.2"
          />
          <path d="M94,96H78a8,8,0,0,0-8,8v48a8,8,0,0,0,16,0v-8h8a24,24,0,0,0,0-48Zm0,32H86V112h8a8,8,0,0,1,0,16ZM136,24A104.5,104.5,0,0,0,54,64H40A16,16,0,0,0,24,80v96a16,16,0,0,0,16,16H54A104,104,0,1,0,136,24Zm87.6,96H160V80a16,16,0,0,0-16-16V40.4A88,88,0,0,1,223.6,120ZM128,40.4V64H75.6A88.7,88.7,0,0,1,128,40.4ZM40,80H144v47.9h0V176H40Zm88,112v23.6A88.7,88.7,0,0,1,75.6,192Zm16,23.6V192a16,16,0,0,0,16-16V136h63.6A88,88,0,0,1,144,215.6Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M102,120a8,8,0,0,0-8-8H86v16h8A8,8,0,0,0,102,120Z" />
          <path d="M136,24A104.5,104.5,0,0,0,54,64H40A16,16,0,0,0,24,80v96a16,16,0,0,0,16,16H54A104,104,0,1,0,136,24ZM70,152V104a8,8,0,0,1,8-8H94a24,24,0,0,1,0,48H86v8a8,8,0,0,1-16,0Zm58,63.6A88.7,88.7,0,0,1,75.6,192H128ZM128,64H75.6A88.7,88.7,0,0,1,128,40.4Zm16-23.6A88,88,0,0,1,223.6,120H160V80a16,16,0,0,0-16-16Zm0,175.2V192a16,16,0,0,0,16-16V136h63.6A88,88,0,0,1,144,215.6Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M94,98H78a6,6,0,0,0-6,6v48a6,6,0,0,0,12,0V142H94a22,22,0,0,0,0-44Zm0,32H84V110H94a10,10,0,0,1,0,20ZM136,26A102.4,102.4,0,0,0,55,66H40A14,14,0,0,0,26,80v96a14,14,0,0,0,14,14H55A102,102,0,1,0,136,26Zm89.8,96H158V80a14,14,0,0,0-14-14h-2V38.2A90.1,90.1,0,0,1,225.8,122ZM130,38.2V66H70.8A90.2,90.2,0,0,1,130,38.2ZM38,176V80a2,2,0,0,1,2-2H144a2,2,0,0,1,2,2v96a2,2,0,0,1-2,2H40A2,2,0,0,1,38,176Zm32.8,14H130v27.8A90.2,90.2,0,0,1,70.8,190ZM142,217.8V190h2a14,14,0,0,0,14-14V134h67.8A90.1,90.1,0,0,1,142,217.8Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M94,100H78a4,4,0,0,0-4,4v48a4,4,0,0,0,8,0V140H94a20,20,0,0,0,0-40Zm0,32H82V108H94a12,12,0,0,1,0,24ZM136,28A100.4,100.4,0,0,0,56,68H40A12,12,0,0,0,28,80v96a12,12,0,0,0,12,12H56A100,100,0,1,0,136,28Zm91.9,96H156V80a12,12,0,0,0-12-12h-4V36.1A92,92,0,0,1,227.9,124ZM132,36.1V68H66.3A91.9,91.9,0,0,1,132,36.1ZM36,176V80a4,4,0,0,1,4-4H144a4,4,0,0,1,4,4v96a4,4,0,0,1-4,4H40A4,4,0,0,1,36,176Zm30.3,12H132v31.9A91.9,91.9,0,0,1,66.3,188ZM140,219.9V188h4a12,12,0,0,0,12-12V132h71.9A92,92,0,0,1,140,219.9Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M94,96H78a8,8,0,0,0-8,8v48a8,8,0,0,0,16,0v-8h8a24,24,0,0,0,0-48Zm0,32H86V112h8a8,8,0,0,1,0,16ZM136,24A104.5,104.5,0,0,0,54,64H40A16,16,0,0,0,24,80v96a16,16,0,0,0,16,16H54A104,104,0,1,0,136,24Zm87.6,96H160V80a16,16,0,0,0-16-16V40.4A88,88,0,0,1,223.6,120ZM128,40.4V64H75.6A88.7,88.7,0,0,1,128,40.4ZM40,80H144v47.9h0V176H40Zm88,112v23.6A88.7,88.7,0,0,1,75.6,192Zm16,23.6V192a16,16,0,0,0,16-16V136h63.6A88,88,0,0,1,144,215.6Z" />
        </>
      )}
    </svg>
  );
}

export function PhMicrosoftTeamsLogo(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M126,104a12,12,0,0,1-12,12h-6v36a12,12,0,0,1-24,0V116H78a12,12,0,0,1,0-24h36A12,12,0,0,1,126,104ZM232,88v80a68,68,0,0,1-128,32H40a20.1,20.1,0,0,1-20-20V76A20.1,20.1,0,0,1,40,56h80a44,44,0,0,1,88,0,43.4,43.4,0,0,1-4.8,20H220A12,12,0,0,1,232,88ZM144,56h8a20,20,0,0,1,19.9,18.4A20,20,0,1,0,144,56ZM44,176H148V80H44Zm164-76H172v80a20.1,20.1,0,0,1-20,20H133.9A43.9,43.9,0,0,0,208,168Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M152,80v96a8,8,0,0,1-8,8H40a8,8,0,0,1-8-8V80a8,8,0,0,1,8-8H144A8,8,0,0,1,152,80Z"
            opacity="0.2"
          />
          <path d="M74,112a8,8,0,0,1,0-16h36a8,8,0,0,1,0,16H100v40a8,8,0,0,1-16,0V112ZM232,92.7V152a40,40,0,0,1-36.6,39.8,64,64,0,0,1-118.7.2H40a16,16,0,0,1-16-16V80A16,16,0,0,1,40,64H96.8a42.6,42.6,0,0,1-.8-8,40,40,0,0,1,72.6-23.2A34,34,0,0,1,220,62a33.5,33.5,0,0,1-5.2,18h4.5A12.7,12.7,0,0,1,232,92.7Zm-56.9-45A37.7,37.7,0,0,1,176,56a39.2,39.2,0,0,1-4,17.3A18,18,0,1,0,186,44,17.7,17.7,0,0,0,175.1,47.7ZM113.4,64H144a15.9,15.9,0,0,1,11.9,5.4A24.3,24.3,0,0,0,160,56a24,24,0,0,0-48,0A24.5,24.5,0,0,0,113.4,64ZM40,176H144V88h0V80H40Zm144-8V96H160v80a16,16,0,0,1-16,16H94.4A48.5,48.5,0,0,0,136,216,48,48,0,0,0,184,168Zm32-72H200v72a49.2,49.2,0,0,1-.4,6.7A24,24,0,0,0,216,152Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M219.3,80h-4.5A33.5,33.5,0,0,0,220,62a34,34,0,0,0-51.4-29.2A40,40,0,0,0,96,56a42.6,42.6,0,0,0,.8,8H40A16,16,0,0,0,24,80v96a16,16,0,0,0,16,16H76.7a64,64,0,0,0,118.7-.2A40,40,0,0,0,232,152V92.7A12.7,12.7,0,0,0,219.3,80ZM136,32a24.1,24.1,0,0,1,24,24,24.3,24.3,0,0,1-4.1,13.4A15.9,15.9,0,0,0,144,64H113.4a24.5,24.5,0,0,1-1.4-8A24.1,24.1,0,0,1,136,32ZM84,152V112H74a8,8,0,0,1,0-16h36a8,8,0,0,1,0,16H100v40a8,8,0,0,1-16,0Zm100,16a48,48,0,0,1-48,48,48.5,48.5,0,0,1-41.6-24H144a16,16,0,0,0,16-16V96h24Zm2-88a18,18,0,0,1-14-6.7A39.2,39.2,0,0,0,176,56a37.7,37.7,0,0,0-.9-8.3A17.7,17.7,0,0,1,186,44a18,18,0,0,1,0,36Zm30,72a24,24,0,0,1-16.4,22.7,49.2,49.2,0,0,0,.4-6.7V96h16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M74,110a6,6,0,0,1,0-12h36a6,6,0,0,1,0,12H98v42a6,6,0,0,1-12,0V110ZM230,92.7V152a38,38,0,0,1-36,37.9A62,62,0,0,1,78,190H40a14,14,0,0,1-14-14V80A14,14,0,0,1,40,66H99.3A41.5,41.5,0,0,1,98,56a38,38,0,0,1,70-20.5A32.6,32.6,0,0,1,186,30a32,32,0,0,1,25,52h8.3A10.7,10.7,0,0,1,230,92.7ZM172.9,46.9A39.1,39.1,0,0,1,174,56a37.4,37.4,0,0,1-4.3,17.5A19.8,19.8,0,0,0,186,82a20,20,0,1,0-13.1-35.1ZM112,66h32a14.1,14.1,0,0,1,11.9,6.7A25.8,25.8,0,0,0,162,56a26,26,0,1,0-50,10ZM40,178H144a2,2,0,0,0,2-2V80a2,2,0,0,0-2-2H40a2,2,0,0,0-2,2v96A2,2,0,0,0,40,178Zm146-10V96a2,2,0,0,0-2-2H158v82a14,14,0,0,1-14,14H91.1A50,50,0,0,0,186,168Zm32-74H197.8a13.6,13.6,0,0,1,.2,2v72a59.9,59.9,0,0,1-.7,9.5A26.2,26.2,0,0,0,218,152Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M114,104a4,4,0,0,1-4,4H96v44a4,4,0,0,1-8,0V108H74a4,4,0,0,1,0-8h36A4,4,0,0,1,114,104ZM228,92.7V152a36,36,0,0,1-35.4,36,60,60,0,0,1-113.2,0H40a12,12,0,0,1-12-12V80A12,12,0,0,1,40,68h62.1A34.8,34.8,0,0,1,100,56a36,36,0,0,1,67.4-17.5,30,30,0,0,1,39,45.5h12.9A8.7,8.7,0,0,1,228,92.7ZM158.6,84h7a26,26,0,0,1-3.2-3.5Zm12-37.7A34.5,34.5,0,0,1,172,56a35.6,35.6,0,0,1-4.7,17.6A21.8,21.8,0,0,0,186,84a22,22,0,1,0-15.4-37.7ZM110.7,68H144a12,12,0,0,1,11.4,8.2A28,28,0,1,0,108,56,27.4,27.4,0,0,0,110.7,68ZM40,180H144a4,4,0,0,0,4-4V80a4,4,0,0,0-4-4H40a4,4,0,0,0-4,4v96A4,4,0,0,0,40,180Zm148-12V96a4,4,0,0,0-4-4H156v84a12,12,0,0,1-12,12H88a52,52,0,0,0,100-20Zm32-75.3a.7.7,0,0,0-.7-.7h-24a13.4,13.4,0,0,1,.7,4v72a58.6,58.6,0,0,1-1.2,11.9A28.2,28.2,0,0,0,220,152Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M74,112a8,8,0,0,1,0-16h36a8,8,0,0,1,0,16H100v40a8,8,0,0,1-16,0V112ZM232,92.7V152a40,40,0,0,1-36.6,39.8,64,64,0,0,1-118.7.2H40a16,16,0,0,1-16-16V80A16,16,0,0,1,40,64H96.8a42.6,42.6,0,0,1-.8-8,40,40,0,0,1,72.6-23.2A34,34,0,0,1,220,62a33.5,33.5,0,0,1-5.2,18h4.5A12.7,12.7,0,0,1,232,92.7Zm-56.9-45A37.7,37.7,0,0,1,176,56a39.2,39.2,0,0,1-4,17.3A18,18,0,1,0,186,44,17.7,17.7,0,0,0,175.1,47.7ZM113.4,64H144a15.9,15.9,0,0,1,11.9,5.4A24.3,24.3,0,0,0,160,56a24,24,0,0,0-48,0A24.5,24.5,0,0,0,113.4,64ZM40,176H144V88h0V80H40Zm144-8V96H160v80a16,16,0,0,1-16,16H94.4A48.5,48.5,0,0,0,136,216,48,48,0,0,0,184,168Zm32-72H200v72a49.2,49.2,0,0,1-.4,6.7A24,24,0,0,0,216,152Z" />
        </>
      )}
    </svg>
  );
}

export function PhMicrosoftWordLogo(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M200,20H72A20.1,20.1,0,0,0,52,40V56H40A20.1,20.1,0,0,0,20,76V180a20.1,20.1,0,0,0,20,20H52v16a20.1,20.1,0,0,0,20,20H200a20.1,20.1,0,0,0,20-20V40A20.1,20.1,0,0,0,200,20Zm-28,88h24v40H172ZM76,44H196V84H172V76a20.1,20.1,0,0,0-20-20H76ZM44,80H148v96H44ZM76,212V200h76a20.1,20.1,0,0,0,20-20v-8h24v40Zm-7.6-57.1-12-48a12,12,0,0,1,23.2-5.8l3.6,14.2,1.8-4.2a12.1,12.1,0,0,1,22,0l1.8,4.2,3.6-14.2a12,12,0,0,1,23.2,5.8l-12,48A12,12,0,0,1,113,164h-1a12.2,12.2,0,0,1-11-7.1l-5-11.4-5,11.4a12,12,0,0,1-22.6-2Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M152,80v96a8,8,0,0,1-8,8H40a8,8,0,0,1-8-8V80a8,8,0,0,1,8-8H144A8,8,0,0,1,152,80Z"
            opacity="0.2"
          />
          <path d="M200,24H72A16,16,0,0,0,56,40V64H40A16,16,0,0,0,24,80v96a16,16,0,0,0,16,16H56v24a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V40A16,16,0,0,0,200,24Zm-40,80h40v48H160ZM72,40H200V88H160V80a16,16,0,0,0-16-16H72ZM40,80H144V96h0v80H40ZM72,216V192h72a16,16,0,0,0,16-16v-8h40v48Zm-3.8-62.1-12-48a8,8,0,0,1,15.6-3.8l6.3,25.4,6.6-14.7a8,8,0,0,1,14.6,0l6.6,14.7,6.3-25.4a8,8,0,1,1,15.6,3.8l-12,48a8.1,8.1,0,0,1-7.1,6.1H108a7.9,7.9,0,0,1-7.3-4.8L92,135.7l-8.7,19.5a8,8,0,0,1-8,4.8A8.1,8.1,0,0,1,68.2,153.9Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M200,24H72A16,16,0,0,0,56,40V64H40A16,16,0,0,0,24,80v96a16,16,0,0,0,16,16H56v24a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V40A16,16,0,0,0,200,24ZM68.2,153.9l-12-48a8,8,0,0,1,15.6-3.8l6.3,25.4,6.6-14.7a8,8,0,0,1,14.6,0l6.6,14.7,6.3-25.4a8,8,0,1,1,15.6,3.8l-12,48a8.1,8.1,0,0,1-7.1,6.1H108a7.9,7.9,0,0,1-7.3-4.8L92,135.7l-8.7,19.5a8,8,0,0,1-8,4.8A8.1,8.1,0,0,1,68.2,153.9ZM200,216H72V192h72a16,16,0,0,0,16-16v-8h40Zm0-64H160V104h40Zm0-64H160V80a16,16,0,0,0-16-16H72V40H200Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M200,26H72A14,14,0,0,0,58,40V66H40A14,14,0,0,0,26,80v96a14,14,0,0,0,14,14H58v26a14,14,0,0,0,14,14H200a14,14,0,0,0,14-14V40A14,14,0,0,0,200,26Zm-42,76h44v52H158ZM70,40a2,2,0,0,1,2-2H200a2,2,0,0,1,2,2V90H158V80a14,14,0,0,0-14-14H70ZM38,176V80a2,2,0,0,1,2-2H144a2,2,0,0,1,2,2v96a2,2,0,0,1-2,2H40A2,2,0,0,1,38,176Zm162,42H72a2,2,0,0,1-2-2V190h74a14,14,0,0,0,14-14V166h44v50A2,2,0,0,1,200,218ZM70.2,153.5l-12-48a6,6,0,1,1,11.6-3l7.8,31.1,8.9-20a6,6,0,0,1,11,0l8.9,20,7.8-31.1a6,6,0,1,1,11.6,3l-12,48a6,6,0,0,1-5.3,4.5H108a6,6,0,0,1-5.5-3.6L92,130.8,81.5,154.4a6.1,6.1,0,0,1-6,3.6A6,6,0,0,1,70.2,153.5Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M200,28H72A12,12,0,0,0,60,40V68H40A12,12,0,0,0,28,80v96a12,12,0,0,0,12,12H60v28a12,12,0,0,0,12,12H200a12,12,0,0,0,12-12V40A12,12,0,0,0,200,28Zm-44,72h48v56H156ZM68,40a4,4,0,0,1,4-4H200a4,4,0,0,1,4,4V92H156V80a12,12,0,0,0-12-12H68ZM36,176V80a4,4,0,0,1,4-4H144a4,4,0,0,1,4,4v96a4,4,0,0,1-4,4H40A4,4,0,0,1,36,176Zm164,44H72a4,4,0,0,1-4-4V188h76a12,12,0,0,0,12-12V164h48v52A4,4,0,0,1,200,220ZM72.1,153l-12-48a4,4,0,0,1,7.8-2l9.2,36.8,11.2-25.4a4.1,4.1,0,0,1,7.4,0l11.2,25.4,9.2-36.8a4,4,0,1,1,7.8,2l-12,48a4.2,4.2,0,0,1-3.6,3H108a4,4,0,0,1-3.7-2.4L92,125.8,79.7,153.6a4,4,0,0,1-4,2.4A4.2,4.2,0,0,1,72.1,153Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M200,24H72A16,16,0,0,0,56,40V64H40A16,16,0,0,0,24,80v96a16,16,0,0,0,16,16H56v24a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V40A16,16,0,0,0,200,24Zm-40,80h40v48H160ZM72,40H200V88H160V80a16,16,0,0,0-16-16H72ZM40,80H144V96h0v80H40ZM72,216V192h72a16,16,0,0,0,16-16v-8h40v48Zm-3.8-62.1-12-48a8,8,0,0,1,15.6-3.8l6.3,25.4,6.6-14.7a8,8,0,0,1,14.6,0l6.6,14.7,6.3-25.4a8,8,0,1,1,15.6,3.8l-12,48a8.1,8.1,0,0,1-7.1,6.1H108a7.9,7.9,0,0,1-7.3-4.8L92,135.7l-8.7,19.5a8,8,0,0,1-8,4.8A8.1,8.1,0,0,1,68.2,153.9Z" />
        </>
      )}
    </svg>
  );
}

export function PhMinusCircle(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,20A108,108,0,1,0,236,128,108.1,108.1,0,0,0,128,20Zm0,192a84,84,0,1,1,84-84A84.1,84.1,0,0,1,128,212Zm52-84a12,12,0,0,1-12,12H88a12,12,0,0,1,0-24h80A12,12,0,0,1,180,128Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="128" cy="128" r="96" />
          </g>
          <path d="M128,24A104,104,0,1,0,232,128,104.1,104.1,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Z" />
          <path d="M168,120H88a8,8,0,0,0,0,16h80a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm40,112H88a8,8,0,0,1,0-16h80a8,8,0,0,1,0,16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,26A102,102,0,1,0,230,128,102.2,102.2,0,0,0,128,26Zm0,192a90,90,0,1,1,90-90A90.1,90.1,0,0,1,128,218Zm46-90a6,6,0,0,1-6,6H88a6,6,0,0,1,0-12h80A6,6,0,0,1,174,128Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,28A100,100,0,1,0,228,128,100.2,100.2,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm44-92a4,4,0,0,1-4,4H88a4,4,0,0,1,0-8h80A4,4,0,0,1,172,128Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.1,104.1,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm48-88a8,8,0,0,1-8,8H88a8,8,0,0,1,0-16h80A8,8,0,0,1,176,128Z" />
        </>
      )}
    </svg>
  );
}

export function PhMinus(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216,140H40a12,12,0,0,1,0-24H216a12,12,0,0,1,0,24Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M216,136H40a8,8,0,0,1,0-16H216a8,8,0,0,1,0,16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,136H40a8,8,0,0,1,0-16H216a8,8,0,0,1,0,16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M216,134H40a6,6,0,0,1,0-12H216a6,6,0,0,1,0,12Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M216,132H40a4,4,0,0,1,0-8H216a4,4,0,0,1,0,8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,136H40a8,8,0,0,1,0-16H216a8,8,0,0,1,0,16Z" />
        </>
      )}
    </svg>
  );
}

export function PhMoney(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M232,52H24A20.1,20.1,0,0,0,4,72V184a20.1,20.1,0,0,0,20,20H232a20.1,20.1,0,0,0,20-20V72A20.1,20.1,0,0,0,232,52ZM92.6,180,34.1,128,92.6,76h70.8l58.5,52-58.5,52ZM228,101.3,199.6,76H228ZM56.4,76,28,101.3V76ZM28,154.7,56.4,180H28ZM199.6,180,228,154.7V180ZM128,88a40,40,0,1,0,40,40A40,40,0,0,0,128,88Zm0,56a16,16,0,1,1,16-16A16,16,0,0,1,128,144Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="128" cy="128" r="32" />
            <path d="M232,64H176l64,56V72A8,8,0,0,0,232,64Z" />
            <path d="M232,192a8,8,0,0,0,8-8V136l-64,56Z" />
            <path d="M16,72v48L80,64H24A8,8,0,0,0,16,72Z" />
            <path d="M16,184a8,8,0,0,0,8,8H80L16,136Z" />
          </g>
          <path d="M232,56H24A16,16,0,0,0,8,72V184a16,16,0,0,0,16,16H232a16,16,0,0,0,16-16V72A16,16,0,0,0,232,56ZM83,184,24,132.4v-8.8L83,72h90l59,51.6v8.8L173,184Zm149-81.6L197.3,72H232ZM58.7,72,24,102.4V72ZM24,153.6,58.7,184H24ZM232,184H197.3L232,153.6V184ZM128,88a40,40,0,1,0,40,40A40,40,0,0,0,128,88Zm0,64a24,24,0,1,1,24-24A24.1,24.1,0,0,1,128,152Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M232,56H24A16,16,0,0,0,8,72V184a16,16,0,0,0,16,16H232a16,16,0,0,0,16-16V72A16,16,0,0,0,232,56ZM75,184,24,140.3V115.7L75,72H181l51,43.7v24.6L181,184Zm93-56a40,40,0,1,1-40-40A40,40,0,0,1,168,128Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M232,58H24A14,14,0,0,0,10,72V184a14,14,0,0,0,14,14H232a14,14,0,0,0,14-14V72A14,14,0,0,0,232,58ZM82.3,186,22,133.3V122.7L82.3,70h91.4L234,122.7v10.6L173.7,186ZM234,72v34.8L192,70h40A2,2,0,0,1,234,72ZM24,70H64L22,106.8V72A2,2,0,0,1,24,70ZM22,184V149.2L64,186H24A2,2,0,0,1,22,184Zm210,2H192l42-36.8V184A2,2,0,0,1,232,186ZM128,90a38,38,0,1,0,38,38A38,38,0,0,0,128,90Zm0,64a26,26,0,1,1,26-26A26.1,26.1,0,0,1,128,154Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M232,60H24A12,12,0,0,0,12,72V184a12,12,0,0,0,12,12H232a12,12,0,0,0,12-12V72A12,12,0,0,0,232,60ZM81.5,188,20,134.2V121.8L81.5,68h93L236,121.8v12.4L174.5,188ZM236,72v39.2L186.6,68H232A4,4,0,0,1,236,72ZM24,68H69.4L20,111.2V72A4,4,0,0,1,24,68ZM20,184V144.8L69.4,188H24A4,4,0,0,1,20,184Zm212,4H186.6L236,144.8V184A4,4,0,0,1,232,188ZM128,92a36,36,0,1,0,36,36A36,36,0,0,0,128,92Zm0,64a28,28,0,1,1,28-28A28.1,28.1,0,0,1,128,156Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M232,56H24A16,16,0,0,0,8,72V184a16,16,0,0,0,16,16H232a16,16,0,0,0,16-16V72A16,16,0,0,0,232,56ZM83,184,24,132.4v-8.8L83,72h90l59,51.6v8.8L173,184Zm149-81.6L197.3,72H232ZM58.7,72,24,102.4V72ZM24,153.6,58.7,184H24ZM232,184H197.3L232,153.6V184ZM128,88a40,40,0,1,0,40,40A40,40,0,0,0,128,88Zm0,64a24,24,0,1,1,24-24A24.1,24.1,0,0,1,128,152Z" />
        </>
      )}
    </svg>
  );
}

export function PhMonitorPlay(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M168,120a11.9,11.9,0,0,1-5.1,9.8l-40,28A11.8,11.8,0,0,1,116,160a10.7,10.7,0,0,1-5.5-1.4A11.8,11.8,0,0,1,104,148V92a12,12,0,0,1,18.9-9.8l40,28A11.9,11.9,0,0,1,168,120Zm68-56V176a28.1,28.1,0,0,1-28,28H48a28.1,28.1,0,0,1-28-28V64A28.1,28.1,0,0,1,48,36H208A28.1,28.1,0,0,1,236,64Zm-24,0a4,4,0,0,0-4-4H48a4,4,0,0,0-4,4V176a4,4,0,0,0,4,4H208a4,4,0,0,0,4-4ZM160,216H96a12,12,0,0,0,0,24h64a12,12,0,0,0,0-24Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M208,48H48A16,16,0,0,0,32,64V176a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V64A16,16,0,0,0,208,48ZM112,152V88l48,32Z" />
          </g>
          <path d="M208,40H48A24.1,24.1,0,0,0,24,64V176a24.1,24.1,0,0,0,24,24H208a24.1,24.1,0,0,0,24-24V64A24.1,24.1,0,0,0,208,40Zm8,136a8,8,0,0,1-8,8H48a8,8,0,0,1-8-8V64a8,8,0,0,1,8-8H208a8,8,0,0,1,8,8Zm-48,48a8,8,0,0,1-8,8H96a8,8,0,0,1,0-16h64A8,8,0,0,1,168,224Zm-3.6-110.7-48-32a8.3,8.3,0,0,0-8.2-.4A8,8,0,0,0,104,88v64a8,8,0,0,0,4.2,7.1,8.5,8.5,0,0,0,3.8.9,8.4,8.4,0,0,0,4.4-1.3l48-32a8,8,0,0,0,0-13.4ZM120,137.1V102.9L145.6,120Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M168,224a8,8,0,0,1-8,8H96a8,8,0,0,1,0-16h64A8,8,0,0,1,168,224ZM232,64V176a24.1,24.1,0,0,1-24,24H48a24.1,24.1,0,0,1-24-24V64A24.1,24.1,0,0,1,48,40H208A24.1,24.1,0,0,1,232,64Zm-68,56a7.9,7.9,0,0,0-3.7-6.7l-44-28a7.9,7.9,0,0,0-8.1-.3,8,8,0,0,0-4.2,7v56a8,8,0,0,0,4.2,7,7.7,7.7,0,0,0,8.1-.3l44-28A7.9,7.9,0,0,0,164,120Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M163.3,115l-48-32a6,6,0,0,0-9.3,5v64a6,6,0,0,0,3.2,5.3,5.9,5.9,0,0,0,6.1-.3l48-32a6,6,0,0,0,0-10ZM118,140.8V99.2L149.2,120ZM208,42H48A22.1,22.1,0,0,0,26,64V176a22.1,22.1,0,0,0,22,22H208a22.1,22.1,0,0,0,22-22V64A22.1,22.1,0,0,0,208,42Zm10,134a10,10,0,0,1-10,10H48a10,10,0,0,1-10-10V64A10,10,0,0,1,48,54H208a10,10,0,0,1,10,10Zm-52,48a6,6,0,0,1-6,6H96a6,6,0,0,1,0-12h64A6,6,0,0,1,166,224Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M162.2,116.7l-48-32a4,4,0,0,0-4.1-.2A3.9,3.9,0,0,0,108,88v64a3.9,3.9,0,0,0,2.1,3.5,4.5,4.5,0,0,0,1.9.5,3.6,3.6,0,0,0,2.2-.7l48-32a3.9,3.9,0,0,0,0-6.6ZM116,144.5v-49L152.8,120ZM208,44H48A20.1,20.1,0,0,0,28,64V176a20.1,20.1,0,0,0,20,20H208a20.1,20.1,0,0,0,20-20V64A20.1,20.1,0,0,0,208,44Zm12,132a12,12,0,0,1-12,12H48a12,12,0,0,1-12-12V64A12,12,0,0,1,48,52H208a12,12,0,0,1,12,12Zm-56,48a4,4,0,0,1-4,4H96a4,4,0,0,1,0-8h64A4,4,0,0,1,164,224Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M208,40H48A24.1,24.1,0,0,0,24,64V176a24.1,24.1,0,0,0,24,24H208a24.1,24.1,0,0,0,24-24V64A24.1,24.1,0,0,0,208,40Zm8,136a8,8,0,0,1-8,8H48a8,8,0,0,1-8-8V64a8,8,0,0,1,8-8H208a8,8,0,0,1,8,8Zm-48,48a8,8,0,0,1-8,8H96a8,8,0,0,1,0-16h64A8,8,0,0,1,168,224Zm-3.6-110.7-48-32a8.3,8.3,0,0,0-8.2-.4A8,8,0,0,0,104,88v64a8,8,0,0,0,4.2,7.1,8.5,8.5,0,0,0,3.8.9,8.4,8.4,0,0,0,4.4-1.3l48-32a8,8,0,0,0,0-13.4ZM120,137.1V102.9L145.6,120Z" />
        </>
      )}
    </svg>
  );
}

export function PhMonitor(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M208,36H48A28.1,28.1,0,0,0,20,64V176a28.1,28.1,0,0,0,28,28H208a28.1,28.1,0,0,0,28-28V64A28.1,28.1,0,0,0,208,36Zm4,140a4,4,0,0,1-4,4H48a4,4,0,0,1-4-4V64a4,4,0,0,1,4-4H208a4,4,0,0,1,4,4Zm-40,52a12,12,0,0,1-12,12H96a12,12,0,0,1,0-24h64A12,12,0,0,1,172,228Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect
              x="32"
              y="46.9"
              width="192"
              height="144"
              rx="16"
              transform="translate(256 237.8) rotate(180)"
            />
          </g>
          <path d="M208,38.9H48a24.1,24.1,0,0,0-24,24v112a24,24,0,0,0,24,24H208a24,24,0,0,0,24-24V62.9A24.1,24.1,0,0,0,208,38.9Zm8,136a8,8,0,0,1-8,8H48a8,8,0,0,1-8-8V62.9a8,8,0,0,1,8-8H208a8,8,0,0,1,8,8Z" />
          <path d="M160,214.9H96a8,8,0,1,0,0,16h64a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M232,62.9v112a24,24,0,0,1-24,24H48a24,24,0,0,1-24-24V62.9a24.1,24.1,0,0,1,24-24H208A24.1,24.1,0,0,1,232,62.9Zm-72,152H96a8,8,0,1,0,0,16h64a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M208,40.9H48a22.1,22.1,0,0,0-22,22v112a22,22,0,0,0,22,22H208a22,22,0,0,0,22-22V62.9A22.1,22.1,0,0,0,208,40.9Zm10,134a10,10,0,0,1-10,10H48a10,10,0,0,1-10-10V62.9a10,10,0,0,1,10-10H208a10,10,0,0,1,10,10Zm-52,48a6,6,0,0,1-6,6H96a6,6,0,1,1,0-12h64A6,6,0,0,1,166,222.9Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M208,42.9H48a20.1,20.1,0,0,0-20,20v112a20.1,20.1,0,0,0,20,20H208a20.1,20.1,0,0,0,20-20V62.9A20.1,20.1,0,0,0,208,42.9Zm12,132a12,12,0,0,1-12,12H48a12,12,0,0,1-12-12V62.9a12,12,0,0,1,12-12H208a12,12,0,0,1,12,12Zm-56,48a4,4,0,0,1-4,4H96a4,4,0,1,1,0-8h64A4,4,0,0,1,164,222.9Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M208,38.9H48a24.1,24.1,0,0,0-24,24v112a24,24,0,0,0,24,24H208a24,24,0,0,0,24-24V62.9A24.1,24.1,0,0,0,208,38.9Zm8,136a8,8,0,0,1-8,8H48a8,8,0,0,1-8-8V62.9a8,8,0,0,1,8-8H208a8,8,0,0,1,8,8Zm-48,48a8,8,0,0,1-8,8H96a8,8,0,1,1,0-16h64A8,8,0,0,1,168,222.9Z" />
        </>
      )}
    </svg>
  );
}

export function PhMoonStars(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M252,88a12,12,0,0,1-12,12H228v12a12,12,0,0,1-24,0V100H192a12,12,0,0,1,0-24h12V64a12,12,0,0,1,24,0V76h12A12,12,0,0,1,252,88ZM152,52h4v4a12,12,0,0,0,24,0V52h4a12,12,0,0,0,0-24h-4V24a12,12,0,0,0-24,0v4h-4a12,12,0,0,0,0,24Zm76,96.9a11.5,11.5,0,0,1,.2,7.1A104,104,0,1,1,99.9,27.8a12.6,12.6,0,0,1,7.9.4A12,12,0,0,1,115.3,40a13.5,13.5,0,0,1-.4,2.8,80,80,0,0,0,98.3,98.4,11.3,11.3,0,0,1,3.2-.5A11.6,11.6,0,0,1,228,148.9Zm-30.6,19A104.2,104.2,0,0,1,88.1,58.6,80,80,0,1,0,197.4,167.9Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M216.7,152.6A91.9,91.9,0,0,1,103.4,39.3h0A92,92,0,1,0,216.7,152.6Z" />
          </g>
          <path d="M248,88a8,8,0,0,1-8,8H224v16a8,8,0,0,1-16,0V96H192a8,8,0,0,1,0-16h16V64a8,8,0,0,1,16,0V80h16A8,8,0,0,1,248,88ZM152,48h8v8a8,8,0,0,0,16,0V48h8a8,8,0,0,0,0-16h-8V24a8,8,0,0,0-16,0v8h-8a8,8,0,0,0,0,16Zm72.3,102.1a7.9,7.9,0,0,1,0,4.8A100,100,0,1,1,101.1,31.7a7.8,7.8,0,0,1,5.2.2,8,8,0,0,1,5,7.9,5.7,5.7,0,0,1-.3,1.8A84,84,0,0,0,214.3,145l2.2-.4A8.2,8.2,0,0,1,224.3,150.1Zm-20,13.1A99.9,99.9,0,0,1,92.8,51.7,84,84,0,1,0,204.3,163.2Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M248,88a8,8,0,0,1-8,8H224v16a8,8,0,0,1-16,0V96H192a8,8,0,0,1,0-16h16V64a8,8,0,0,1,16,0V80h16A8,8,0,0,1,248,88ZM152,48h8v8a8,8,0,0,0,16,0V48h8a8,8,0,0,0,0-16h-8V24a8,8,0,0,0-16,0v8h-8a8,8,0,0,0,0,16Zm64.5,96.6-2.2.4A84,84,0,0,1,111,41.6a5.7,5.7,0,0,0,.3-1.8,8,8,0,0,0-5-7.9,7.8,7.8,0,0,0-5.2-.2A100,100,0,1,0,224.3,154.9a7.9,7.9,0,0,0,0-4.8A8.2,8.2,0,0,0,216.5,144.6Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M246,88a6,6,0,0,1-6,6H222v18a6,6,0,0,1-12,0V94H192a6,6,0,0,1,0-12h18V64a6,6,0,0,1,12,0V82h18A6,6,0,0,1,246,88ZM152,46h10V56a6,6,0,0,0,12,0V46h10a6,6,0,0,0,0-12H174V24a6,6,0,0,0-12,0V34H152a6,6,0,0,0,0,12Zm70.4,104.7a6.3,6.3,0,0,1,0,3.6A98,98,0,1,1,101.6,33.6a6.1,6.1,0,0,1,4,.2,5.8,5.8,0,0,1,3.7,5.9,6.8,6.8,0,0,1-.2,1.4A86,86,0,0,0,214.9,146.9l1.6-.3A6.3,6.3,0,0,1,222.4,150.7Zm-14.9,10.1A98.1,98.1,0,0,1,95.2,48.5,86,86,0,1,0,207.5,160.8Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M244,88a4,4,0,0,1-4,4H220v20a4,4,0,0,1-8,0V92H192a4,4,0,0,1,0-8h20V64a4,4,0,0,1,8,0V84h20A4,4,0,0,1,244,88ZM152,44h12V56a4,4,0,0,0,8,0V44h12a4,4,0,0,0,0-8H172V24a4,4,0,0,0-8,0V36H152a4,4,0,0,0,0,8Zm68.5,107.4a4.9,4.9,0,0,1,0,2.4A96,96,0,1,1,102.2,35.5a3.9,3.9,0,0,1,2.6.1,4,4,0,0,1,2.5,4,2.8,2.8,0,0,1-.1.9A88,88,0,0,0,215.4,148.8l1.2-.2A4.2,4.2,0,0,1,220.5,151.4Zm-9.8,6.8A96.1,96.1,0,0,1,97.8,45.3,88,88,0,1,0,210.7,158.2Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M248,88a8,8,0,0,1-8,8H224v16a8,8,0,0,1-16,0V96H192a8,8,0,0,1,0-16h16V64a8,8,0,0,1,16,0V80h16A8,8,0,0,1,248,88ZM152,48h8v8a8,8,0,0,0,16,0V48h8a8,8,0,0,0,0-16h-8V24a8,8,0,0,0-16,0v8h-8a8,8,0,0,0,0,16Zm72.3,102.1a7.9,7.9,0,0,1,0,4.8A100,100,0,1,1,101.1,31.7a7.8,7.8,0,0,1,5.2.2,8,8,0,0,1,5,7.9,5.7,5.7,0,0,1-.3,1.8A84,84,0,0,0,214.3,145l2.2-.4A8.2,8.2,0,0,1,224.3,150.1Zm-20,13.1A99.9,99.9,0,0,1,92.8,51.7,84,84,0,1,0,204.3,163.2Z" />
        </>
      )}
    </svg>
  );
}

export function PhMoon(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M228.1,149.1a12,12,0,0,0-11.6-8.5,11.4,11.4,0,0,0-3.3.6,80,80,0,0,1-98.3-98.4,13.5,13.5,0,0,0,.4-2.8,12,12,0,0,0-7.5-11.8,12.6,12.6,0,0,0-7.9-.4A104,104,0,1,0,228.2,156,12.5,12.5,0,0,0,228.1,149.1ZM128,208A80,80,0,0,1,88.1,58.6,104.2,104.2,0,0,0,197.4,167.9,80.4,80.4,0,0,1,128,208Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M216.7,152.6A91.9,91.9,0,0,1,103.4,39.3h0A92,92,0,1,0,216.7,152.6Z" />
          </g>
          <path d="M224.3,150.3a8.1,8.1,0,0,0-7.8-5.7l-2.2.4A84,84,0,0,1,111,41.6a5.7,5.7,0,0,0,.3-1.8A7.9,7.9,0,0,0,101,31.7,100,100,0,1,0,224.3,154.9,7.2,7.2,0,0,0,224.3,150.3ZM128,212A84,84,0,0,1,92.8,51.7,99.9,99.9,0,0,0,204.3,163.2,84.4,84.4,0,0,1,128,212Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M224.3,154.9A100,100,0,1,1,101,31.7a7.9,7.9,0,0,1,10.3,8.1,5.7,5.7,0,0,1-.3,1.8A84,84,0,0,0,214.3,145l2.2-.4a8.1,8.1,0,0,1,7.8,5.7A7.2,7.2,0,0,1,224.3,154.9Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M222.4,150.9a6.1,6.1,0,0,0-5.7-4.3h-.1l-1.7.3A86,86,0,0,1,109.1,41.1a6.8,6.8,0,0,0,.2-1.4,5.8,5.8,0,0,0-3.7-5.9,6.1,6.1,0,0,0-4-.2A98,98,0,1,0,222.4,154.3,6.5,6.5,0,0,0,222.4,150.9ZM128,214A86,86,0,0,1,95.2,48.5,98.1,98.1,0,0,0,207.5,160.8,86.1,86.1,0,0,1,128,214Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M220.5,151.5a4.1,4.1,0,0,0-3.9-2.9l-1.2.2A88,88,0,0,1,107.2,40.5a2.8,2.8,0,0,0,.1-.9,4,4,0,0,0-2.5-4,3.9,3.9,0,0,0-2.6-.1A96,96,0,1,0,220.5,153.8,4.5,4.5,0,0,0,220.5,151.5ZM128,216A88,88,0,0,1,97.8,45.3,96.1,96.1,0,0,0,210.7,158.2,88.3,88.3,0,0,1,128,216Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M224.3,150.3a8.1,8.1,0,0,0-7.8-5.7l-2.2.4A84,84,0,0,1,111,41.6a5.7,5.7,0,0,0,.3-1.8A7.9,7.9,0,0,0,101,31.7,100,100,0,1,0,224.3,154.9,7.2,7.2,0,0,0,224.3,150.3ZM128,212A84,84,0,0,1,92.8,51.7,99.9,99.9,0,0,0,204.3,163.2,84.4,84.4,0,0,1,128,212Z" />
        </>
      )}
    </svg>
  );
}

export function PhMountains(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M164,84a32,32,0,1,0-32-32A32.1,32.1,0,0,0,164,84Zm0-40a8,8,0,1,1-8,8A8,8,0,0,1,164,44Zm85.6,149.9-52.4-88.4a20,20,0,0,0-34.4,0L146.5,133,109.2,69.6a20,20,0,0,0-34.4,0L1.7,193.9A12,12,0,0,0,12,212H239.3a12,12,0,0,0,10.3-18.1ZM92,87.7,108.7,116H75.3ZM33,188l28.2-48h61.6l13.3,22.7h.1L151,188Zm145.9,0-18.5-31.4L180,123.5,218.2,188Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M144,52a20,20,0,1,1,20,20A20.1,20.1,0,0,1,144,52Zm46.9,51.6a8.1,8.1,0,0,0-13.8,0l-33,55.8L168,200h80ZM81.1,75.7,50.4,128h75.2L94.9,75.7A8.1,8.1,0,0,0,81.1,75.7Z"
            opacity="0.2"
          />
          <path d="M164,80a28,28,0,1,0-28-28A28.1,28.1,0,0,0,164,80Zm0-40a12,12,0,1,1-12,12A12,12,0,0,1,164,40Zm90.9,155.9L197.8,99.5a16.1,16.1,0,0,0-27.6,0l-26.1,44.1L101.8,71.7a16,16,0,0,0-27.6,0L1.1,195.9a7.9,7.9,0,0,0,0,8.1A7.8,7.8,0,0,0,8,208H248a8.1,8.1,0,0,0,7-4A8.3,8.3,0,0,0,254.9,195.9ZM88,79.8,111.7,120H64.3ZM22,192l32.9-56h66.2l16.1,27.4h0L154,192Zm150.6,0-19.2-32.6L184,107.7,234,192Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M140,52a24,24,0,1,1,24,24A24.1,24.1,0,0,1,140,52ZM255,204a8.1,8.1,0,0,1-7,4H8a7.8,7.8,0,0,1-6.9-4,7.9,7.9,0,0,1,0-8.1L74.2,71.7a16,16,0,0,1,27.6,0l42.3,71.9,26.1-44.1a16.1,16.1,0,0,1,27.6,0l57.1,96.4A8.3,8.3,0,0,1,255,204ZM64.3,120h47.4L88,79.8Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M164,78a26,26,0,1,0-26-26A26.1,26.1,0,0,0,164,78Zm0-40a14,14,0,1,1-14,14A14,14,0,0,1,164,38Zm89.2,158.9L196,100.6a13.9,13.9,0,0,0-24,0l-27.9,46.9-44-74.8a14.1,14.1,0,0,0-24.2,0L2.8,197a5.9,5.9,0,0,0,0,6A6.1,6.1,0,0,0,8,206H248a6.1,6.1,0,0,0,5.2-9.1ZM86.3,78.8a1.9,1.9,0,0,1,3.4,0L115.2,122H60.8ZM18.5,194l35.3-60h68.4l16.7,28.4h0L157.5,194Zm152.9,0-20.3-34.6,31.2-52.7a1.9,1.9,0,0,1,3.4,0L237.5,194Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M164,76a24,24,0,1,0-24-24A24.1,24.1,0,0,0,164,76Zm0-40a16,16,0,1,1-16,16A16,16,0,0,1,164,36Zm87.4,162-57.1-96.4a11.9,11.9,0,0,0-20.6,0l-29.6,49.9L98.3,73.7a11.9,11.9,0,0,0-20.6,0L4.6,198a3.8,3.8,0,0,0-.1,4A4.2,4.2,0,0,0,8,204H248a4.2,4.2,0,0,0,3.5-2A3.8,3.8,0,0,0,251.4,198ZM84.6,77.8a3.9,3.9,0,0,1,6.8,0L118.7,124H57.3ZM15,196l37.6-64h70.8L161,196Zm155.3,0-21.6-36.6,31.9-53.7a3.9,3.9,0,0,1,6.8,0L241,196Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M164,80a28,28,0,1,0-28-28A28.1,28.1,0,0,0,164,80Zm0-40a12,12,0,1,1-12,12A12,12,0,0,1,164,40Zm90.9,155.9L197.8,99.5a16.1,16.1,0,0,0-27.6,0l-26.1,44.1L101.8,71.7a16,16,0,0,0-27.6,0L1.1,195.9a7.9,7.9,0,0,0,0,8.1A7.8,7.8,0,0,0,8,208H248a8.1,8.1,0,0,0,7-4A8.3,8.3,0,0,0,254.9,195.9ZM88,79.8,111.7,120H64.3ZM22,192l32.9-56h66.2l16.1,27.4h0L154,192Zm150.6,0-19.2-32.6L184,107.7,234,192Z" />
        </>
      )}
    </svg>
  );
}

export function PhMouseSimple(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M148,20H108A68.1,68.1,0,0,0,40,88v80a68.1,68.1,0,0,0,68,68h40a68.1,68.1,0,0,0,68-68V88A68.1,68.1,0,0,0,148,20Zm44,148a44,44,0,0,1-44,44H108a44,44,0,0,1-44-44V88a44,44,0,0,1,44-44h40a44,44,0,0,1,44,44ZM140,76v40a12,12,0,0,1-24,0V76a12,12,0,0,1,24,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect x="52" y="32" width="152" height="192" rx="56" />
          </g>
          <path d="M148,24H108A64.1,64.1,0,0,0,44,88v80a64.1,64.1,0,0,0,64,64h40a64.1,64.1,0,0,0,64-64V88A64.1,64.1,0,0,0,148,24Zm48,144a48,48,0,0,1-48,48H108a48,48,0,0,1-48-48V88a48,48,0,0,1,48-48h40a48,48,0,0,1,48,48ZM136,72v40a8,8,0,0,1-16,0V72a8,8,0,0,1,16,0Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M148,24H108A64.1,64.1,0,0,0,44,88v80a64.1,64.1,0,0,0,64,64h40a64.1,64.1,0,0,0,64-64V88A64.1,64.1,0,0,0,148,24Zm-12,88a8,8,0,0,1-16,0V64a8,8,0,0,1,16,0Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M148,26H108A62.1,62.1,0,0,0,46,88v80a62.1,62.1,0,0,0,62,62h40a62.1,62.1,0,0,0,62-62V88A62.1,62.1,0,0,0,148,26Zm50,142a50,50,0,0,1-50,50H108a50,50,0,0,1-50-50V88a50,50,0,0,1,50-50h40a50,50,0,0,1,50,50ZM134,72v40a6,6,0,0,1-12,0V72a6,6,0,0,1,12,0Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M148,28H108A60,60,0,0,0,48,88v80a60,60,0,0,0,60,60h40a60,60,0,0,0,60-60V88A60,60,0,0,0,148,28Zm52,140a52,52,0,0,1-52,52H108a52,52,0,0,1-52-52V88a52,52,0,0,1,52-52h40a52,52,0,0,1,52,52ZM132,72v40a4,4,0,0,1-8,0V72a4,4,0,0,1,8,0Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M148,24H108A64.1,64.1,0,0,0,44,88v80a64.1,64.1,0,0,0,64,64h40a64.1,64.1,0,0,0,64-64V88A64.1,64.1,0,0,0,148,24Zm48,144a48,48,0,0,1-48,48H108a48,48,0,0,1-48-48V88a48,48,0,0,1,48-48h40a48,48,0,0,1,48,48ZM136,72v40a8,8,0,0,1-16,0V72a8,8,0,0,1,16,0Z" />
        </>
      )}
    </svg>
  );
}

export function PhMouse(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M148,20H108A68.1,68.1,0,0,0,40,88v80a68.1,68.1,0,0,0,68,68h40a68.1,68.1,0,0,0,68-68V88A68.1,68.1,0,0,0,148,20Zm44,68v12H140V44h8A44,44,0,0,1,192,88ZM108,44h8v56H64V88A44,44,0,0,1,108,44Zm40,168H108a44,44,0,0,1-44-44V124H192v44A44,44,0,0,1,148,212Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M128,32H108A56,56,0,0,0,52,88v24h76Z" />
          </g>
          <path d="M148,24H108A64.1,64.1,0,0,0,44,88v80a64.1,64.1,0,0,0,64,64h40a64.1,64.1,0,0,0,64-64V88A64.1,64.1,0,0,0,148,24Zm48,64v16H136V40h12A48,48,0,0,1,196,88ZM108,40h12v64H60V88A48,48,0,0,1,108,40Zm40,176H108a48,48,0,0,1-48-48V120H196v48A48,48,0,0,1,148,216Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M148,24H108A64.1,64.1,0,0,0,44,88v80a64.1,64.1,0,0,0,64,64h40a64.1,64.1,0,0,0,64-64V88A64.1,64.1,0,0,0,148,24Zm48,64v16H136V40h12A48,48,0,0,1,196,88ZM108,40h12v64H60V88A48,48,0,0,1,108,40Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M148,26H108A62.1,62.1,0,0,0,46,88v80a62.1,62.1,0,0,0,62,62h40a62.1,62.1,0,0,0,62-62V88A62.1,62.1,0,0,0,148,26Zm50,62v18H134V38h14A50,50,0,0,1,198,88ZM108,38h14v68H58V88A50,50,0,0,1,108,38Zm40,180H108a50,50,0,0,1-50-50V118H198v50A50,50,0,0,1,148,218Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M148,28H108A60,60,0,0,0,48,88v80a60,60,0,0,0,60,60h40a60,60,0,0,0,60-60V88A60,60,0,0,0,148,28Zm52,60v20H132V36h16A52,52,0,0,1,200,88ZM108,36h16v72H56V88A52,52,0,0,1,108,36Zm40,184H108a52,52,0,0,1-52-52V116H200v52A52,52,0,0,1,148,220Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M148,24H108A64.1,64.1,0,0,0,44,88v80a64.1,64.1,0,0,0,64,64h40a64.1,64.1,0,0,0,64-64V88A64.1,64.1,0,0,0,148,24Zm48,64v16H136V40h12A48,48,0,0,1,196,88ZM108,40h12v64H60V88A48,48,0,0,1,108,40Zm40,176H108a48,48,0,0,1-48-48V120H196v48A48,48,0,0,1,148,216Z" />
        </>
      )}
    </svg>
  );
}

export function PhMusicNoteSimple(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M211.4,52.5l-80-24A12,12,0,0,0,116,40V140.2A52,52,0,1,0,140,184V56.1l64.6,19.4a12,12,0,0,0,6.8-23ZM88,212a28,28,0,1,1,28-28A28.1,28.1,0,0,1,88,212Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="88" cy="184" r="40" />
          </g>
          <path d="M210.3,56.3l-80-24a8.2,8.2,0,0,0-7.1,1.3A8.1,8.1,0,0,0,120,40V148.3A47.4,47.4,0,0,0,88,136a48,48,0,1,0,48,48V50.8l69.7,20.9a8,8,0,1,0,4.6-15.4ZM88,216a32,32,0,1,1,32-32A32.1,32.1,0,0,1,88,216Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M215.7,66.3a8.2,8.2,0,0,1-10,5.4L136,50.8V184a48,48,0,1,1-48-48,47.4,47.4,0,0,1,32,12.3V40a8.1,8.1,0,0,1,3.2-6.4,8.2,8.2,0,0,1,7.1-1.3l80,24A8.2,8.2,0,0,1,215.7,66.3Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M209.7,58.3l-80-24a5.9,5.9,0,0,0-5.3.9A6,6,0,0,0,122,40V153a46,46,0,1,0,12,31V48.1l72.3,21.6a5.9,5.9,0,0,0,3.4-11.4ZM88,218a34,34,0,1,1,34-34A34.1,34.1,0,0,1,88,218Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M209.1,60.2l-80-24a3.7,3.7,0,0,0-3.5.6A3.9,3.9,0,0,0,124,40V158.7a44,44,0,1,0,8,25.3V45.4l74.9,22.4a4,4,0,0,0,2.2-7.6ZM88,220a36,36,0,1,1,36-36A36,36,0,0,1,88,220Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M210.3,56.3l-80-24a8.2,8.2,0,0,0-7.1,1.3A8.1,8.1,0,0,0,120,40V148.3A47.4,47.4,0,0,0,88,136a48,48,0,1,0,48,48V50.8l69.7,20.9a8,8,0,1,0,4.6-15.4ZM88,216a32,32,0,1,1,32-32A32.1,32.1,0,0,1,88,216Z" />
        </>
      )}
    </svg>
  );
}

export function PhMusicNote(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M211.4,52.5l-80-24A12,12,0,0,0,116,40V140.2A52,52,0,1,0,140,184V104.1l64.6,19.4A12,12,0,0,0,220,112V64A12,12,0,0,0,211.4,52.5ZM88,212a28,28,0,1,1,28-28A28.1,28.1,0,0,1,88,212ZM196,95.9,140,79.1v-23l56,16.8Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="88" cy="184" r="40" />
          </g>
          <path d="M210.3,56.3l-80-24a8.2,8.2,0,0,0-7.1,1.3A8.1,8.1,0,0,0,120,40V148.3A47.4,47.4,0,0,0,88,136a48,48,0,1,0,48,48V98.8l69.7,20.9a8.2,8.2,0,0,0,7.1-1.3A8.1,8.1,0,0,0,216,112V64A8.1,8.1,0,0,0,210.3,56.3ZM88,216a32,32,0,1,1,32-32A32.1,32.1,0,0,1,88,216ZM200,101.2,136,82V50.8L200,70Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,64v48a8.1,8.1,0,0,1-3.2,6.4,8.2,8.2,0,0,1-7.1,1.3L136,98.8V184a48,48,0,1,1-48-48,47.4,47.4,0,0,1,32,12.3V40a8.1,8.1,0,0,1,3.2-6.4,8.2,8.2,0,0,1,7.1-1.3l80,24A8.1,8.1,0,0,1,216,64Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M209.7,58.3l-80-24a5.9,5.9,0,0,0-5.3.9A6,6,0,0,0,122,40V153a46,46,0,1,0,12,31V96.1l72.3,21.6a5.9,5.9,0,0,0,5.3-.9A6,6,0,0,0,214,112V64A5.9,5.9,0,0,0,209.7,58.3ZM88,218a34,34,0,1,1,34-34A34.1,34.1,0,0,1,88,218ZM202,103.9,134,83.5V48.1l68,20.4Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M209.1,60.2l-80-24a3.7,3.7,0,0,0-3.5.6A3.9,3.9,0,0,0,124,40V158.7a44,44,0,1,0,8,25.3V93.4l74.9,22.4a3.7,3.7,0,0,0,3.5-.6A3.9,3.9,0,0,0,212,112V64A4,4,0,0,0,209.1,60.2ZM88,220a36,36,0,1,1,36-36A36,36,0,0,1,88,220ZM204,106.6,132,85V45.4L204,67Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M210.3,56.3l-80-24a8.2,8.2,0,0,0-7.1,1.3A8.1,8.1,0,0,0,120,40V148.3A47.4,47.4,0,0,0,88,136a48,48,0,1,0,48,48V98.8l69.7,20.9a8.2,8.2,0,0,0,7.1-1.3A8.1,8.1,0,0,0,216,112V64A8.1,8.1,0,0,0,210.3,56.3ZM88,216a32,32,0,1,1,32-32A32.1,32.1,0,0,1,88,216ZM200,101.2,136,82V50.8L200,70Z" />
        </>
      )}
    </svg>
  );
}

export function PhMusicNotesPlus(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M236,56a12,12,0,0,1-12,12H212V80a12,12,0,0,1-24,0V68H176a12,12,0,0,1,0-24h12V32a12,12,0,0,1,24,0V44h12A12,12,0,0,1,236,56ZM92,121.4V204a40,40,0,1,1-40-40,38.9,38.9,0,0,1,16,3.4V64a11.9,11.9,0,0,1,9.1-11.6l48.2-12.1a12,12,0,0,1,5.8,23.3L92,73.4V96.6l53.1-13.2a12,12,0,1,1,5.8,23.2ZM68,204a16,16,0,1,0-16,16A16,16,0,0,0,68,204Zm152-76.7V172a40,40,0,1,1-40-40,38.9,38.9,0,0,1,16,3.4v-8.1a12,12,0,0,1,24,0ZM196,172a16,16,0,1,0-16,16A16,16,0,0,0,196,172Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M208,172a28,28,0,1,1-28-28A28,28,0,0,1,208,172ZM52,176a28,28,0,1,0,28,28A28,28,0,0,0,52,176Z"
            opacity="0.2"
          />
          <path d="M232,56a8,8,0,0,1-8,8H208V80a8,8,0,0,1-16,0V64H176a8,8,0,0,1,0-16h16V32a8,8,0,0,1,16,0V48h16A8,8,0,0,1,232,56Zm-16,63.2V172a36,36,0,1,1-36-36,35.3,35.3,0,0,1,20,6.1V119.2a8,8,0,1,1,16,0ZM200,172a20,20,0,1,0-20,20A20.1,20.1,0,0,0,200,172ZM88,118.2V204a36,36,0,1,1-36-36,35.3,35.3,0,0,1,20,6.1V64a8,8,0,0,1,6.1-7.8l56.4-14.1a8,8,0,1,1,3.9,15.5L88,70.2v31.6l69.1-17.3A8,8,0,1,1,161,100ZM72,204a20,20,0,1,0-20,20A20.1,20.1,0,0,0,72,204Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M232,56a8,8,0,0,1-8,8H208V80a8,8,0,0,1-16,0V64H176a8,8,0,0,1,0-16h16V32a8,8,0,0,1,16,0V48h16A8,8,0,0,1,232,56Zm-24,55.2a8,8,0,0,0-8,8v22.9a35.3,35.3,0,0,0-20-6.1,36,36,0,1,0,36,36V119.2A8,8,0,0,0,208,111.2ZM162.4,99.5a7.9,7.9,0,0,0,4.4-5.2,8.3,8.3,0,0,0-2-7.7,9,9,0,0,0-2.6-1.7A32.3,32.3,0,0,1,144,56a37.1,37.1,0,0,1,.3-4.7,7.9,7.9,0,0,0-2-6.9,8.1,8.1,0,0,0-7.8-2.3L78.1,56.2A8,8,0,0,0,72,64V174.1A35.3,35.3,0,0,0,52,168a36,36,0,1,0,36,36V118.2L160.8,100Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M230,56a6,6,0,0,1-6,6H206V80a6,6,0,0,1-12,0V62H176a6,6,0,0,1,0-12h18V32a6,6,0,0,1,12,0V50h18A6,6,0,0,1,230,56Zm-16,63.2V172a34,34,0,1,1-12-25.9V119.2a6,6,0,1,1,12,0ZM202,172a22,22,0,1,0-22,22A22.1,22.1,0,0,0,202,172ZM86,116.7V204a34,34,0,1,1-12-25.9V64a5.9,5.9,0,0,1,4.5-5.8L135,44.1a6,6,0,0,1,7.3,4.3,6.1,6.1,0,0,1-4.4,7.3L86,68.7v35.6l71.6-17.9a6,6,0,0,1,2.9,11.7ZM74,204a22,22,0,1,0-22,22A22.1,22.1,0,0,0,74,204Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M228,56a4,4,0,0,1-4,4H204V80a4,4,0,0,1-8,0V60H176a4,4,0,0,1,0-8h20V32a4,4,0,0,1,8,0V52h20A4,4,0,0,1,228,56Zm-16,63.2V172a32.1,32.1,0,1,1-8-21.1V119.2a4,4,0,1,1,8,0ZM204,172a24,24,0,1,0-24,24A24.1,24.1,0,0,0,204,172ZM84,115.1V204a32.1,32.1,0,1,1-8-21.1V64a4.1,4.1,0,0,1,3-3.9L135.5,46a4,4,0,0,1,4.8,2.9,4,4,0,0,1-2.9,4.9L84,67.1v39.8l74.1-18.5a4,4,0,0,1,1.9,7.7ZM76,204a24,24,0,1,0-24,24A24.1,24.1,0,0,0,76,204Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M232,56a8,8,0,0,1-8,8H208V80a8,8,0,0,1-16,0V64H176a8,8,0,0,1,0-16h16V32a8,8,0,0,1,16,0V48h16A8,8,0,0,1,232,56Zm-16,63.2V172a36,36,0,1,1-36-36,35.3,35.3,0,0,1,20,6.1V119.2a8,8,0,1,1,16,0ZM200,172a20,20,0,1,0-20,20A20.1,20.1,0,0,0,200,172ZM88,118.2V204a36,36,0,1,1-36-36,35.3,35.3,0,0,1,20,6.1V64a8,8,0,0,1,6.1-7.8l56.4-14.1a8,8,0,1,1,3.9,15.5L88,70.2v31.6l69.1-17.3A8,8,0,1,1,161,100ZM72,204a20,20,0,1,0-20,20A20.1,20.1,0,0,0,72,204Z" />
        </>
      )}
    </svg>
  );
}

export function PhMusicNotesSimple(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M215.4,22.5a12.2,12.2,0,0,0-10.3-2.1l-128,32A11.9,11.9,0,0,0,68,64V167.4A38.9,38.9,0,0,0,52,164a40,40,0,1,0,40,40V73.4l104-26v88a38.9,38.9,0,0,0-16-3.4,40,40,0,1,0,40,40V32A12.1,12.1,0,0,0,215.4,22.5ZM52,220a16,16,0,1,1,16-16A16,16,0,0,1,52,220Zm128-32a16,16,0,1,1,16-16A16,16,0,0,1,180,188Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="180" cy="172" r="28" />
          </g>
          <g opacity="0.2">
            <circle cx="52" cy="204" r="28" />
          </g>
          <path d="M212.9,25.7a7.9,7.9,0,0,0-6.8-1.5l-128,32A8,8,0,0,0,72,64V174.1A35.3,35.3,0,0,0,52,168a36,36,0,1,0,36,36V70.2l112-28v99.9a35.3,35.3,0,0,0-20-6.1,36,36,0,1,0,36,36V32A7.8,7.8,0,0,0,212.9,25.7ZM52,224a20,20,0,1,1,20-20A20.1,20.1,0,0,1,52,224Zm128-32a20,20,0,1,1,20-20A20.1,20.1,0,0,1,180,192Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,32V172a36,36,0,1,1-36-36,35.3,35.3,0,0,1,20,6.1V42.2L88,70.2V204a36,36,0,1,1-36-36,35.3,35.3,0,0,1,20,6.1V64a8,8,0,0,1,6.1-7.8l128-32a7.9,7.9,0,0,1,6.8,1.5A7.8,7.8,0,0,1,216,32Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M211.7,27.3a5.8,5.8,0,0,0-5.2-1.1l-128,32A5.9,5.9,0,0,0,74,64V178.1A34,34,0,1,0,86,204V68.7l116-29V146.1A34,34,0,1,0,214,172V32A6.1,6.1,0,0,0,211.7,27.3ZM52,226a22,22,0,1,1,22-22A22.1,22.1,0,0,1,52,226Zm128-32a22,22,0,1,1,22-22A22.1,22.1,0,0,1,180,194Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M210.5,28.8a4.4,4.4,0,0,0-3.5-.7L79,60.1A4.1,4.1,0,0,0,76,64V182.9A32,32,0,1,0,84,204V67.1l120-30V150.9a32,32,0,1,0,8,21.1V32A4.4,4.4,0,0,0,210.5,28.8ZM52,228a24,24,0,1,1,24-24A24.1,24.1,0,0,1,52,228Zm128-32a24,24,0,1,1,24-24A24.1,24.1,0,0,1,180,196Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M212.9,25.7a8,8,0,0,0-6.8-1.5l-128,32A8,8,0,0,0,72,64V174.1A35.3,35.3,0,0,0,52,168a36,36,0,1,0,36,36V70.2l112-28v99.9a35.3,35.3,0,0,0-20-6.1,36,36,0,1,0,36,36V32A7.8,7.8,0,0,0,212.9,25.7ZM52,224a20,20,0,1,1,20-20A20.1,20.1,0,0,1,52,224Zm128-32a20,20,0,1,1,20-20A20.1,20.1,0,0,1,180,192Z" />
        </>
      )}
    </svg>
  );
}

export function PhMusicNotes(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M215.4,22.5a12.2,12.2,0,0,0-10.3-2.1l-128,32A11.9,11.9,0,0,0,68,64V167.4A38.9,38.9,0,0,0,52,164a40,40,0,1,0,40,40V121.4l104-26v40a38.9,38.9,0,0,0-16-3.4,40,40,0,1,0,40,40V32A12.1,12.1,0,0,0,215.4,22.5ZM52,220a16,16,0,1,1,16-16A16,16,0,0,1,52,220ZM92,96.6V73.4l104-26V70.6ZM180,188a16,16,0,1,1,16-16A16,16,0,0,1,180,188Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="180" cy="172" r="28" />
          </g>
          <g opacity="0.2">
            <circle cx="52" cy="204" r="28" />
          </g>
          <path d="M212.9,25.7a7.9,7.9,0,0,0-6.8-1.5l-128,32A8,8,0,0,0,72,64V174.1A35.3,35.3,0,0,0,52,168a36,36,0,1,0,36,36V118.2l112-28v51.9a35.3,35.3,0,0,0-20-6.1,36,36,0,1,0,36,36V32A7.8,7.8,0,0,0,212.9,25.7ZM52,224a20,20,0,1,1,20-20A20.1,20.1,0,0,1,52,224ZM88,101.8V70.2l112-28V73.8ZM180,192a20,20,0,1,1,20-20A20.1,20.1,0,0,1,180,192Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,32V172a36.1,36.1,0,1,1-16-29.9V82.2l-112,28V204a36.1,36.1,0,1,1-16-29.9V64a8,8,0,0,1,6.1-7.8l128-32a8,8,0,0,1,6.8,1.5A7.8,7.8,0,0,1,216,32Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M211.7,27.3a5.8,5.8,0,0,0-5.2-1.1l-128,32A5.9,5.9,0,0,0,74,64V178.1A34,34,0,1,0,86,204V116.7l116-29v58.4A34,34,0,1,0,214,172V32A6.1,6.1,0,0,0,211.7,27.3ZM52,226a22,22,0,1,1,22-22A22.1,22.1,0,0,1,52,226ZM86,104.3V68.7l116-29V75.3ZM180,194a22,22,0,1,1,22-22A22.1,22.1,0,0,1,180,194Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M210.5,28.8a4.4,4.4,0,0,0-3.5-.7L79,60.1A4.1,4.1,0,0,0,76,64V182.9A32,32,0,1,0,84,204V115.1l120-30v65.8a32,32,0,1,0,8,21.1V32A4.4,4.4,0,0,0,210.5,28.8ZM52,228a24,24,0,1,1,24-24A24.1,24.1,0,0,1,52,228Zm128-32a24,24,0,1,1,24-24A24.1,24.1,0,0,1,180,196ZM84,106.9V67.1l120-30V76.9Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M212.9,25.7a8,8,0,0,0-6.8-1.5l-128,32A8,8,0,0,0,72,64V174.1A35.3,35.3,0,0,0,52,168a36,36,0,1,0,36,36V118.2l112-28v51.9a35.3,35.3,0,0,0-20-6.1,36,36,0,1,0,36,36V32A7.8,7.8,0,0,0,212.9,25.7ZM52,224a20,20,0,1,1,20-20A20.1,20.1,0,0,1,52,224ZM88,101.8V70.2l112-28V73.8ZM180,192a20,20,0,1,1,20-20A20.1,20.1,0,0,1,180,192Z" />
        </>
      )}
    </svg>
  );
}

export function PhNavigationArrow(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M103.5,234.3a19.9,19.9,0,0,1-18.8-13.5L26.1,51.5h0a19.7,19.7,0,0,1,4.7-20.7A19.9,19.9,0,0,1,51.5,26L220.8,84.7a19.9,19.9,0,0,1,13.5,19.2,20.1,20.1,0,0,1-14.2,18.8l-74.5,22.9-22.9,74.5a19.9,19.9,0,0,1-18.8,14.1Zm3.8-21.4ZM51.4,51.4l51.9,150,20-64.9a19.8,19.8,0,0,1,13.2-13.2l64.9-20Zm-2.7-7.8Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M37.4,47.6,96,216.9c2.5,7.3,12.9,7.1,15.2-.3L134.8,140a7.5,7.5,0,0,1,5.3-5.2l76.5-23.6c7.4-2.3,7.6-12.7.3-15.2L47.6,37.4A8,8,0,0,0,37.4,47.6Z" />
          </g>
          <path d="M103.5,230.3a15.8,15.8,0,0,1-15.1-10.8L29.8,50.2A16,16,0,0,1,50.2,29.8L219.5,88.4a15.9,15.9,0,0,1,10.8,15.4,15.8,15.8,0,0,1-11.3,15l-76.6,23.6L118.8,219a15.6,15.6,0,0,1-15,11.2ZM45,44.9h0l58.6,169.4h0l23.5-76.6a16.1,16.1,0,0,1,10.6-10.6l76.6-23.5Zm0,0Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M230.3,103.8a15.8,15.8,0,0,1-11.3,15l-76.6,23.6L118.8,219a15.6,15.6,0,0,1-15,11.2h-.3a15.8,15.8,0,0,1-15.1-10.8L29.8,50.2A16,16,0,0,1,50.2,29.8L219.5,88.4A15.9,15.9,0,0,1,230.3,103.8Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M103.5,228.3a13.8,13.8,0,0,1-13.2-9.5L31.7,49.5A14,14,0,0,1,35.1,35a13.7,13.7,0,0,1,14.4-3.3L218.8,90.3a14,14,0,0,1-.4,26.6l-76.6,23.6a2.1,2.1,0,0,0-1.3,1.3l-23.6,76.6a13.6,13.6,0,0,1-13.1,9.8ZM44.9,42.9a2.6,2.6,0,0,0-1.4.6,2,2,0,0,0-.4,2.1h0l58.6,169.3a1.8,1.8,0,0,0,1.8,1.4c.1,0,.1,0,.1-.1a1.8,1.8,0,0,0,1.9-1.4L129,138.3a14.2,14.2,0,0,1,9.3-9.3l76.5-23.5a2.1,2.1,0,0,0,1.5-1.9,1.9,1.9,0,0,0-1.4-1.9L45.6,43.1Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M103.5,226.3a11.8,11.8,0,0,1-11.3-8.1L33.6,48.9A12.1,12.1,0,0,1,48.9,33.6L218.2,92.2a12,12,0,0,1-.4,22.8l-76.6,23.6a4.2,4.2,0,0,0-2.6,2.6L115,217.8a11.7,11.7,0,0,1-11.2,8.4ZM44.9,40.9a3.9,3.9,0,0,0-2.8,1.2,4,4,0,0,0-.9,4.2h0L99.8,215.6a3.8,3.8,0,0,0,3.8,2.6,3.8,3.8,0,0,0,3.8-2.8l23.5-76.5a12.3,12.3,0,0,1,8-8l76.5-23.5a4.1,4.1,0,0,0,2.9-3.8,3.9,3.9,0,0,0-2.7-3.8L46.3,41.2A3.5,3.5,0,0,0,44.9,40.9Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M103.5,230.3a15.8,15.8,0,0,1-15.1-10.8L29.8,50.2A16,16,0,0,1,50.2,29.8L219.5,88.4a15.9,15.9,0,0,1,10.8,15.4,15.8,15.8,0,0,1-11.3,15l-76.6,23.6L118.8,219a15.6,15.6,0,0,1-15,11.2ZM45,44.9h0l58.6,169.4h0l23.5-76.6a16.1,16.1,0,0,1,10.6-10.6l76.6-23.5Zm0,0Z" />
        </>
      )}
    </svg>
  );
}

export function PhNeedle(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M215.1,40.9a43.9,43.9,0,0,0-62.2,0l-24,24a12.1,12.1,0,0,0-3.4,6.4c-7.1,41.7-70.2,112.4-94,136.2h0a12,12,0,0,0,0,17,12.1,12.1,0,0,0,17,0h0c23.7-23.7,94.5-86.9,136.2-94a12.1,12.1,0,0,0,6.4-3.4l24-24a43.9,43.9,0,0,0,0-62.2Zm-17,45.2-21.4,21.5c-14.8,3.3-32.4,11.4-52.5,24.2,12.8-20.1,20.9-37.7,24.2-52.5l21.5-21.4a19.9,19.9,0,1,1,28.2,28.2Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M206.6,94.6l-24,24C128,128,40,216,40,216s88-88,97.4-142.6l24-24a32,32,0,0,1,45.2,45.2Z"
            opacity="0.2"
          />
          <path d="M189.7,66.3a8.1,8.1,0,0,1,0,11.4l-16,16a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4l16-16A8.1,8.1,0,0,1,189.7,66.3ZM224,72a40,40,0,0,1-11.7,28.3l-24,24a8.1,8.1,0,0,1-4.3,2.2c-51.5,8.8-137.5,94.3-138.3,95.2h0a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4h.1c1.6-1.6,86.3-87,95.1-138.3a8.1,8.1,0,0,1,2.2-4.3l24-24A40,40,0,0,1,224,72Zm-16,0a24,24,0,0,0-41-17L144.8,77.3c-4.4,21.1-18.9,46.2-35.5,69.4,23.2-16.6,48.3-31.1,69.4-35.5L201,89A24.2,24.2,0,0,0,208,72Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M212.3,43.7a40.1,40.1,0,0,0-56.6,0l-24,24a8.1,8.1,0,0,0-2.2,4.3C120.7,123.3,36,208.7,34.4,210.3h-.1a8.1,8.1,0,0,0,0,11.4,8.2,8.2,0,0,0,11.4,0h0c.8-.9,86.8-86.4,138.3-95.2a8.1,8.1,0,0,0,4.3-2.2l24-24a40.1,40.1,0,0,0,0-56.6Zm-22.6,34-16,16a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4l16-16a8.1,8.1,0,0,1,11.4,11.4Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M188.2,67.8a5.8,5.8,0,0,1,0,8.4l-16,16a5.9,5.9,0,0,1-8.4-8.4l16-16A5.8,5.8,0,0,1,188.2,67.8ZM222,72a38.1,38.1,0,0,1-11.1,26.9l-24,24a6.4,6.4,0,0,1-3.3,1.6c-52.1,9-138.5,94.9-139.4,95.7a4.6,4.6,0,0,1-1.8,1.3h0a6.9,6.9,0,0,1-2.4.5,6,6,0,0,1-4.2-10.2h0c1.4-1.4,86.7-87.4,95.7-139.3a6.4,6.4,0,0,1,1.6-3.3l24-24A38,38,0,0,1,222,72Zm-12,0a26,26,0,0,0-44.4-18.4L142.9,76.3c-4.1,20.8-18.6,47.6-43.1,79.9,32.3-24.5,59.1-39,79.9-43.1l22.7-22.7A26,26,0,0,0,210,72Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M186.8,69.2a3.9,3.9,0,0,1,0,5.6l-16,16a3.9,3.9,0,0,1-5.6,0,3.9,3.9,0,0,1,0-5.6l16-16A3.9,3.9,0,0,1,186.8,69.2ZM220,72a35.9,35.9,0,0,1-10.5,25.5l-24,24a5.7,5.7,0,0,1-2.2,1.1c-21.7,3.7-52.7,21.4-89.9,51.3C65.1,196.7,43,218.6,42.8,218.8l-.2.2h0a4.3,4.3,0,0,1-2.6.9,3.9,3.9,0,0,1-2.8-1.2,3.9,3.9,0,0,1,0-5.6h0c.2-.2,22.1-22.3,44.9-50.6,29.9-37.2,47.6-68.2,51.3-89.9a5.7,5.7,0,0,1,1.1-2.2l24-24A36,36,0,0,1,220,72Zm-8,0a28,28,0,0,0-47.8-19.8L141.1,75.3c-5.3,28-29.5,63.4-52.5,92.1,28.7-23,64.1-47.2,92.1-52.5l23.1-23.1A27.8,27.8,0,0,0,212,72Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M189.7,66.3a8.1,8.1,0,0,1,0,11.4l-16,16a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4l16-16A8.1,8.1,0,0,1,189.7,66.3ZM224,72a40,40,0,0,1-11.7,28.3l-24,24a8.1,8.1,0,0,1-4.3,2.2c-51.5,8.8-137.5,94.3-138.3,95.2h0a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4h.1c1.6-1.6,86.3-87,95.1-138.3a8.1,8.1,0,0,1,2.2-4.3l24-24A40,40,0,0,1,224,72Zm-16,0a24,24,0,0,0-41-17L144.8,77.3c-4.4,21.1-18.9,46.2-35.5,69.4,23.2-16.6,48.3-31.1,69.4-35.5L201,89A24.2,24.2,0,0,0,208,72Z" />
        </>
      )}
    </svg>
  );
}

export function PhNewspaperClipping(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216,36H40A20.1,20.1,0,0,0,20,56V216a12,12,0,0,0,5.7,10.2,11.9,11.9,0,0,0,11.7.5L64,213.4l26.6,13.3a12.1,12.1,0,0,0,10.8,0L128,213.4l26.6,13.3a11.9,11.9,0,0,0,10.8,0L192,213.4l26.6,13.3a11.9,11.9,0,0,0,11.7-.5A12,12,0,0,0,236,216V56A20.1,20.1,0,0,0,216,36Zm-4,160.6-14.6-7.3a12.1,12.1,0,0,0-10.8,0L160,202.6l-26.6-13.3a11.9,11.9,0,0,0-10.8,0L96,202.6,69.4,189.3a12.1,12.1,0,0,0-10.8,0L44,196.6V60H212ZM136,108a12,12,0,0,1,12-12h36a12,12,0,0,1,0,24H148A12,12,0,0,1,136,108Zm0,40a12,12,0,0,1,12-12h36a12,12,0,0,1,0,24H148A12,12,0,0,1,136,148ZM72,172h40a12,12,0,0,0,12-12V96a12,12,0,0,0-12-12H72A12,12,0,0,0,60,96v64A12,12,0,0,0,72,172Zm12-64h16v40H84Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M216,48H40a8,8,0,0,0-8,8V216l32-16,32,16,32-16,32,16,32-16,32,16V56A8,8,0,0,0,216,48ZM112,160H64V96h48Z" />
          </g>
          <path d="M216,40H40A16,16,0,0,0,24,56V216a7.9,7.9,0,0,0,3.8,6.8,8,8,0,0,0,7.8.4L64,208.9l28.4,14.3a8.3,8.3,0,0,0,7.2,0L128,208.9l28.4,14.3a8.5,8.5,0,0,0,7.2,0L192,208.9l28.4,14.3a8,8,0,0,0,7.8-.4A7.9,7.9,0,0,0,232,216V56A16,16,0,0,0,216,40Zm0,163.1-20.4-10.3a8.3,8.3,0,0,0-7.2,0L160,207.1l-28.4-14.3a8.5,8.5,0,0,0-7.2,0L96,207.1,67.6,192.8a8.3,8.3,0,0,0-7.2,0L40,203.1V56H216ZM136,112a8,8,0,0,1,8-8h48a8,8,0,0,1,0,16H144A8,8,0,0,1,136,112Zm0,32a8,8,0,0,1,8-8h48a8,8,0,0,1,0,16H144A8,8,0,0,1,136,144ZM64,168h48a8,8,0,0,0,8-8V96a8,8,0,0,0-8-8H64a8,8,0,0,0-8,8v64A8,8,0,0,0,64,168Zm8-64h32v48H72Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M72,104h32v48H72ZM232,56V216a7.9,7.9,0,0,1-3.8,6.8,8,8,0,0,1-7.8.4L192,208.9l-28.4,14.3a8.5,8.5,0,0,1-7.2,0L128,208.9,99.6,223.2a8.3,8.3,0,0,1-7.2,0L64,208.9,35.6,223.2a8,8,0,0,1-7.8-.4A7.9,7.9,0,0,1,24,216V56A16,16,0,0,1,40,40H216A16,16,0,0,1,232,56ZM120,96a8,8,0,0,0-8-8H64a8,8,0,0,0-8,8v64a8,8,0,0,0,8,8h48a8,8,0,0,0,8-8Zm80,48a8,8,0,0,0-8-8H144a8,8,0,0,0,0,16h48A8,8,0,0,0,200,144Zm0-32a8,8,0,0,0-8-8H144a8,8,0,0,0,0,16h48A8,8,0,0,0,200,112Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M216,42H40A14,14,0,0,0,26,56V216a6.1,6.1,0,0,0,2.8,5.1,6.2,6.2,0,0,0,5.9.3L64,206.7l29.3,14.7a6.4,6.4,0,0,0,5.4,0L128,206.7l29.3,14.7a6.4,6.4,0,0,0,5.4,0L192,206.7l29.3,14.7a6.6,6.6,0,0,0,2.7.6,6.2,6.2,0,0,0,3.2-.9A6.1,6.1,0,0,0,230,216V56A14,14,0,0,0,216,42Zm2,164.3-23.3-11.7a6.4,6.4,0,0,0-5.4,0L160,209.3l-29.3-14.7a6.4,6.4,0,0,0-5.4,0L96,209.3,66.7,194.6a6.4,6.4,0,0,0-5.4,0L38,206.3V56a2,2,0,0,1,2-2H216a2,2,0,0,1,2,2ZM198,112a6,6,0,0,1-6,6H144a6,6,0,0,1,0-12h48A6,6,0,0,1,198,112Zm0,32a6,6,0,0,1-6,6H144a6,6,0,0,1,0-12h48A6,6,0,0,1,198,144ZM112,90H64a6,6,0,0,0-6,6v64a6,6,0,0,0,6,6h48a6,6,0,0,0,6-6V96A6,6,0,0,0,112,90Zm-6,64H70V102h36Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M216,44H40A12,12,0,0,0,28,56V216a3.9,3.9,0,0,0,1.9,3.4,4.1,4.1,0,0,0,3.9.2L64,204.5l30.2,15.1a4.5,4.5,0,0,0,3.6,0L128,204.5l30.2,15.1a4.5,4.5,0,0,0,3.6,0L192,204.5l30.2,15.1A4,4,0,0,0,228,216V56A12,12,0,0,0,216,44Zm4,165.5-26.2-13.1a4.5,4.5,0,0,0-3.6,0L160,211.5l-30.2-15.1a4.2,4.2,0,0,0-3.6,0L96,211.5,65.8,196.4a4.5,4.5,0,0,0-3.6,0L36,209.5V56a4,4,0,0,1,4-4H216a4,4,0,0,1,4,4ZM196,112a4,4,0,0,1-4,4H144a4,4,0,0,1,0-8h48A4,4,0,0,1,196,112Zm0,32a4,4,0,0,1-4,4H144a4,4,0,0,1,0-8h48A4,4,0,0,1,196,144ZM112,92H64a4,4,0,0,0-4,4v64a4,4,0,0,0,4,4h48a4,4,0,0,0,4-4V96A4,4,0,0,0,112,92Zm-4,64H68V100h40Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,40H40A16,16,0,0,0,24,56V216a7.9,7.9,0,0,0,3.8,6.8,8,8,0,0,0,7.8.4L64,208.9l28.4,14.3a8.3,8.3,0,0,0,7.2,0L128,208.9l28.4,14.3a8.5,8.5,0,0,0,7.2,0L192,208.9l28.4,14.3a8,8,0,0,0,7.8-.4A7.9,7.9,0,0,0,232,216V56A16,16,0,0,0,216,40Zm0,163.1-20.4-10.3a8.3,8.3,0,0,0-7.2,0L160,207.1l-28.4-14.3a8.5,8.5,0,0,0-7.2,0L96,207.1,67.6,192.8a8.3,8.3,0,0,0-7.2,0L40,203.1V56H216ZM136,112a8,8,0,0,1,8-8h48a8,8,0,0,1,0,16H144A8,8,0,0,1,136,112Zm0,32a8,8,0,0,1,8-8h48a8,8,0,0,1,0,16H144A8,8,0,0,1,136,144ZM64,168h48a8,8,0,0,0,8-8V96a8,8,0,0,0-8-8H64a8,8,0,0,0-8,8v64A8,8,0,0,0,64,168Zm8-64h32v48H72Z" />
        </>
      )}
    </svg>
  );
}

export function PhNewspaper(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M92,108a12,12,0,0,1,12-12h72a12,12,0,0,1,0,24H104A12,12,0,0,1,92,108Zm12,52h72a12,12,0,0,0,0-24H104a12,12,0,0,0,0,24ZM236,64V184a28.1,28.1,0,0,1-28,28H36A32.1,32.1,0,0,1,4,180V88a12,12,0,0,1,24,0v92a8,8,0,0,0,16,0V64A20.1,20.1,0,0,1,64,44H216A20.1,20.1,0,0,1,236,64Zm-24,4H68V180a35,35,0,0,1-1,8H208a4,4,0,0,0,4-4Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M32,200a16,16,0,0,0,16-16V64a8,8,0,0,1,8-8H216a8,8,0,0,1,8,8V184a16,16,0,0,1-16,16Z" />
          </g>
          <path d="M88,112a8,8,0,0,1,8-8h80a8,8,0,0,1,0,16H96A8,8,0,0,1,88,112Zm8,40h80a8,8,0,0,0,0-16H96a8,8,0,0,0,0,16ZM232,64V184a24.1,24.1,0,0,1-24,24H32A24,24,0,0,1,8,184.1H8V88a8,8,0,0,1,16,0v96a8,8,0,0,0,16,0V64A16,16,0,0,1,56,48H216A16,16,0,0,1,232,64Zm-16,0H56V184a23.6,23.6,0,0,1-1.4,8H208a8,8,0,0,0,8-8Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,48H56A16,16,0,0,0,40,64V184a8,8,0,0,1-16,0V88A8,8,0,0,0,8,88v96H8A24,24,0,0,0,32,208H208a24.1,24.1,0,0,0,24-24V64A16,16,0,0,0,216,48ZM176,152H96a8,8,0,0,1,0-16h80a8,8,0,0,1,0,16Zm0-32H96a8,8,0,0,1,0-16h80a8,8,0,0,1,0,16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M182,112a6,6,0,0,1-6,6H96a6,6,0,0,1,0-12h80A6,6,0,0,1,182,112Zm-6,26H96a6,6,0,0,0,0,12h80a6,6,0,0,0,0-12Zm54-74V184a22.1,22.1,0,0,1-22,22H32a22,22,0,0,1-22-21.9h0V88a6,6,0,0,1,12,0v96a10,10,0,0,0,20,0V64A14,14,0,0,1,56,50H216A14,14,0,0,1,230,64Zm-12,0a2,2,0,0,0-2-2H56a2,2,0,0,0-2,2V184a22.3,22.3,0,0,1-2.4,10H208a10,10,0,0,0,10-10Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M180,112a4,4,0,0,1-4,4H96a4,4,0,0,1,0-8h80A4,4,0,0,1,180,112Zm-4,28H96a4,4,0,0,0,0,8h80a4,4,0,0,0,0-8Zm52-76V184a20.1,20.1,0,0,1-20,20H32a20,20,0,0,1-20-19.9h0V88a4,4,0,0,1,8,0v96a12,12,0,0,0,24,0V64A12,12,0,0,1,56,52H216A12,12,0,0,1,228,64Zm-8,0a4,4,0,0,0-4-4H56a4,4,0,0,0-4,4V184a19.9,19.9,0,0,1-4,12H208a12,12,0,0,0,12-12Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M88,112a8,8,0,0,1,8-8h80a8,8,0,0,1,0,16H96A8,8,0,0,1,88,112Zm8,40h80a8,8,0,0,0,0-16H96a8,8,0,0,0,0,16ZM232,64V184a24.1,24.1,0,0,1-24,24H32A24,24,0,0,1,8,184.1H8V88a8,8,0,0,1,16,0v96a8,8,0,0,0,16,0V64A16,16,0,0,1,56,48H216A16,16,0,0,1,232,64Zm-16,0H56V184a23.6,23.6,0,0,1-1.4,8H208a8,8,0,0,0,8-8Z" />
        </>
      )}
    </svg>
  );
}

export function PhNoteBlank(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M208,28H48A20.1,20.1,0,0,0,28,48V208a20.1,20.1,0,0,0,20,20H156.7a19.7,19.7,0,0,0,14.1-5.9l51.3-51.3a19.7,19.7,0,0,0,5.9-14.1V48A20.1,20.1,0,0,0,208,28ZM52,52H204v92H156a12,12,0,0,0-12,12v48H52ZM168,191V168h23Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <polygon points="216 160 160 160 160 216 216 160" />
          </g>
          <path d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H156.7a15.9,15.9,0,0,0,11.3-4.7L219.3,168a15.9,15.9,0,0,0,4.7-11.3V48A16,16,0,0,0,208,32ZM48,48H208V152H160a8,8,0,0,0-8,8v48H48ZM196.7,168,168,196.7V168Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H156.7a15.9,15.9,0,0,0,11.3-4.7L219.3,168a15.9,15.9,0,0,0,4.7-11.3V48A16,16,0,0,0,208,32ZM160,204.7V160h44.7Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M208,34H48A14,14,0,0,0,34,48V208a14,14,0,0,0,14,14H156.7a13.1,13.1,0,0,0,5.6-1.2h.2a14.2,14.2,0,0,0,4.1-2.8l51.3-51.3a14.3,14.3,0,0,0,4.1-9.9V48A14,14,0,0,0,208,34ZM46,208V48a2,2,0,0,1,2-2H208a2,2,0,0,1,2,2V154H160a6,6,0,0,0-6,6v50H48A2,2,0,0,1,46,208Zm120-6.5V166h35.5Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M208,36H48A12,12,0,0,0,36,48V208a12,12,0,0,0,12,12H156.7a11.9,11.9,0,0,0,8.5-3.5l51.3-51.3a11.9,11.9,0,0,0,3.5-8.5V48A12,12,0,0,0,208,36ZM164,206.3V164h42.4ZM212,156H160a4,4,0,0,0-4,4v52H48a4,4,0,0,1-4-4V48a4,4,0,0,1,4-4H208a4,4,0,0,1,4,4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M224,156.7V48a16,16,0,0,0-16-16H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H156.7a15.9,15.9,0,0,0,11.3-4.7L219.3,168a16.7,16.7,0,0,0,3.3-4.7c0-.1,0-.1.1-.2A17.4,17.4,0,0,0,224,156.7ZM48,48H208V152H160a8,8,0,0,0-8,8v48H48ZM196.7,168,168,196.7V168Z" />
        </>
      )}
    </svg>
  );
}

export function PhNotePencil(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M232.5,55.5l-32-32a12,12,0,0,0-17,0l-96,96A11.9,11.9,0,0,0,84,128v32a12,12,0,0,0,12,12h32a12.3,12.3,0,0,0,8.5-3.5l96-96A12,12,0,0,0,232.5,55.5ZM192,49l15,15L196,75,181,60Zm-69,99H108V133l56-56,15,15Zm105-19.4V208a20.1,20.1,0,0,1-20,20H48a20.1,20.1,0,0,1-20-20V48A20.1,20.1,0,0,1,48,28h79.4a12,12,0,0,1,0,24H52V204H204V128.6a12,12,0,0,1,24,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <polygon points="128 160 96 160 96 128 168 56 200 88 128 160" />
          </g>
          <path d="M229.6,58.3l-32-32a8,8,0,0,0-11.3,0l-96,96A8.1,8.1,0,0,0,88,128v32a8,8,0,0,0,8,8h32a7.9,7.9,0,0,0,5.6-2.3l96-96A8,8,0,0,0,229.6,58.3ZM124.7,152H104V131.3l64-64L188.7,88ZM200,76.7,179.3,56,192,43.3,212.7,64Z" />
          <path d="M216,112a8,8,0,0,0-8,8v88H48V48h88a8,8,0,0,0,0-16H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V120A8,8,0,0,0,216,112Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M229.6,58.3l-32-32a8,8,0,0,0-11.3,0l-96,96A8.1,8.1,0,0,0,88,128v32a8,8,0,0,0,8,8h32a7.9,7.9,0,0,0,5.6-2.3l96-96A8,8,0,0,0,229.6,58.3ZM200,76.7,179.3,56,192,43.3,212.7,64ZM224,120v88a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V48A16,16,0,0,1,48,32h88a8,8,0,0,1,0,16H48V208H208V120a8,8,0,0,1,16,0Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M228.2,59.8l-32-32a5.9,5.9,0,0,0-8.5,0l-96,96A5.9,5.9,0,0,0,90,128v32a6,6,0,0,0,6,6h32a5.6,5.6,0,0,0,4.2-1.8l96-96A5.8,5.8,0,0,0,228.2,59.8ZM125.5,154H102V130.5l66-66L191.5,88ZM200,79.5,176.5,56,192,40.5,215.5,64ZM222,120v88a14,14,0,0,1-14,14H48a14,14,0,0,1-14-14V48A14,14,0,0,1,48,34h88a6,6,0,0,1,0,12H48a2,2,0,0,0-2,2V208a2,2,0,0,0,2,2H208a2,2,0,0,0,2-2V120a6,6,0,0,1,12,0Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M226.8,61.2l-32-32a3.9,3.9,0,0,0-5.6,0l-96,96A3.6,3.6,0,0,0,92,128v32a4,4,0,0,0,4,4h32a3.6,3.6,0,0,0,2.8-1.2l96-96A3.9,3.9,0,0,0,226.8,61.2ZM126.3,156H100V129.7l68-68L194.3,88ZM200,82.3,173.7,56,192,37.7,218.3,64ZM220,120v88a12,12,0,0,1-12,12H48a12,12,0,0,1-12-12V48A12,12,0,0,1,48,36h88a4,4,0,0,1,0,8H48a4,4,0,0,0-4,4V208a4,4,0,0,0,4,4H208a4,4,0,0,0,4-4V120a4,4,0,0,1,8,0Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M229.7,58.3l-32-32a8.1,8.1,0,0,0-11.4,0l-96,96A8.1,8.1,0,0,0,88,128v32a8,8,0,0,0,8,8h32a8.1,8.1,0,0,0,5.7-2.3l96-96A8.1,8.1,0,0,0,229.7,58.3ZM124.7,152H104V131.3l64-64L188.7,88ZM200,76.7,179.3,56,192,43.3,212.7,64ZM224,120v88a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V48A16,16,0,0,1,48,32h88a8,8,0,0,1,0,16H48V208H208V120a8,8,0,0,1,16,0Z" />
        </>
      )}
    </svg>
  );
}

export function PhNote(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M84,108A12,12,0,0,1,96,96h64a12,12,0,0,1,0,24H96A12,12,0,0,1,84,108Zm32,28H96a12,12,0,0,0,0,24h20a12,12,0,0,0,0-24ZM228,48V156.7a19.7,19.7,0,0,1-5.9,14.1l-51.3,51.3a19.7,19.7,0,0,1-14.1,5.9H48a20.1,20.1,0,0,1-20-20V48A20.1,20.1,0,0,1,48,28H208A20.1,20.1,0,0,1,228,48ZM52,204h92V156a12,12,0,0,1,12-12h48V52H52Zm139-36H168v23Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <polygon points="216 160 160 160 160 216 216 160" />
          </g>
          <path d="M96,104h64a8,8,0,0,0,0-16H96a8,8,0,0,0,0,16Z" />
          <path d="M96,136h64a8,8,0,0,0,0-16H96a8,8,0,0,0,0,16Z" />
          <path d="M128,152H96a8,8,0,0,0,0,16h32a8,8,0,0,0,0-16Z" />
          <path d="M224,156.7V48a16,16,0,0,0-16-16H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H156.7a15.9,15.9,0,0,0,11.3-4.7L219.3,168a16.3,16.3,0,0,0,3.3-4.9h.1A17.4,17.4,0,0,0,224,156.7ZM48,48H208V152H160a8,8,0,0,0-8,8v48H48ZM196.7,168,168,196.7V168Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H156.7a15.9,15.9,0,0,0,11.3-4.7L219.3,168a15.9,15.9,0,0,0,4.7-11.3V48A16,16,0,0,0,208,32ZM96,88h64a8,8,0,0,1,0,16H96a8,8,0,0,1,0-16Zm32,80H96a8,8,0,0,1,0-16h32a8,8,0,0,1,0,16ZM96,136a8,8,0,0,1,0-16h64a8,8,0,0,1,0,16Zm64,68.7V160h44.7Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M90,96a6,6,0,0,1,6-6h64a6,6,0,0,1,0,12H96A6,6,0,0,1,90,96Zm6,38h64a6,6,0,0,0,0-12H96a6,6,0,0,0,0,12Zm32,20H96a6,6,0,0,0,0,12h32a6,6,0,0,0,0-12ZM222,48V156.7a14.3,14.3,0,0,1-4.1,9.9l-51.3,51.3a14.3,14.3,0,0,1-9.9,4.1H48a14,14,0,0,1-14-14V48A14,14,0,0,1,48,34H208A14,14,0,0,1,222,48ZM48,210H154V160a6,6,0,0,1,6-6h50V48a2,2,0,0,0-2-2H48a2,2,0,0,0-2,2V208A2,2,0,0,0,48,210Zm153.5-44H166v35.5Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M92,96a4,4,0,0,1,4-4h64a4,4,0,0,1,0,8H96A4,4,0,0,1,92,96Zm4,36h64a4,4,0,0,0,0-8H96a4,4,0,0,0,0,8Zm32,24H96a4,4,0,0,0,0,8h32a4,4,0,0,0,0-8ZM220,48V156.7a11.9,11.9,0,0,1-3.5,8.5l-51.3,51.3a11.9,11.9,0,0,1-8.5,3.5H48a12,12,0,0,1-12-12V48A12,12,0,0,1,48,36H208A12,12,0,0,1,220,48ZM48,212H156V160a4,4,0,0,1,4-4h52V48a4,4,0,0,0-4-4H48a4,4,0,0,0-4,4V208A4,4,0,0,0,48,212Zm158.4-48H164v42.3Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M88,96a8,8,0,0,1,8-8h64a8,8,0,0,1,0,16H96A8,8,0,0,1,88,96Zm8,40h64a8,8,0,0,0,0-16H96a8,8,0,0,0,0,16Zm32,16H96a8,8,0,0,0,0,16h32a8,8,0,0,0,0-16ZM224,48V156.7a15.9,15.9,0,0,1-4.7,11.3L168,219.3a15.9,15.9,0,0,1-11.3,4.7H48a16,16,0,0,1-16-16V48A16,16,0,0,1,48,32H208A16,16,0,0,1,224,48ZM48,208H152V160a8,8,0,0,1,8-8h48V48H48Zm120-40v28.7L196.7,168Z" />
        </>
      )}
    </svg>
  );
}

export function PhNotebook(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M108,108a12,12,0,0,1,12-12h56a12,12,0,0,1,0,24H120A12,12,0,0,1,108,108Zm68,28H120a12,12,0,0,0,0,24h56a12,12,0,0,0,0-24Zm52-88V208a20.1,20.1,0,0,1-20,20H48a20.1,20.1,0,0,1-20-20V48A20.1,20.1,0,0,1,48,28H208A20.1,20.1,0,0,1,228,48ZM52,204H68V52H52ZM204,52H92V204H204Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M80,216H48a8,8,0,0,1-8-8V48a8,8,0,0,1,8-8H80Z" />
          </g>
          <path d="M176,104H112a8,8,0,0,0,0,16h64a8,8,0,0,0,0-16Z" />
          <path d="M176,136H112a8,8,0,0,0,0,16h64a8,8,0,0,0,0-16Z" />
          <path d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM72,208H48V48H72Zm136,0H88V48H208V208Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM80,208H48V48H80Zm96-56H112a8,8,0,0,1,0-16h64a8,8,0,0,1,0,16Zm0-32H112a8,8,0,0,1,0-16h64a8,8,0,0,1,0,16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M182,112a6,6,0,0,1-6,6H112a6,6,0,0,1,0-12h64A6,6,0,0,1,182,112Zm-6,26H112a6,6,0,0,0,0,12h64a6,6,0,0,0,0-12Zm46-90V208a14,14,0,0,1-14,14H48a14,14,0,0,1-14-14V48A14,14,0,0,1,48,34H208A14,14,0,0,1,222,48ZM48,210H74V46H48a2,2,0,0,0-2,2V208A2,2,0,0,0,48,210ZM210,48a2,2,0,0,0-2-2H86V210H208a2,2,0,0,0,2-2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M180,112a4,4,0,0,1-4,4H112a4,4,0,0,1,0-8h64A4,4,0,0,1,180,112Zm-4,28H112a4,4,0,0,0,0,8h64a4,4,0,0,0,0-8Zm44-92V208a12,12,0,0,1-12,12H48a12,12,0,0,1-12-12V48A12,12,0,0,1,48,36H208A12,12,0,0,1,220,48ZM48,212H76V44H48a4,4,0,0,0-4,4V208A4,4,0,0,0,48,212ZM212,48a4,4,0,0,0-4-4H84V212H208a4,4,0,0,0,4-4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M184,112a8,8,0,0,1-8,8H112a8,8,0,0,1,0-16h64A8,8,0,0,1,184,112Zm-8,24H112a8,8,0,0,0,0,16h64a8,8,0,0,0,0-16Zm48-88V208a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V48A16,16,0,0,1,48,32H208A16,16,0,0,1,224,48ZM48,208H72V48H48Zm160,0V48H88V208Z" />
        </>
      )}
    </svg>
  );
}

export function PhNotepad(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M172,124a12,12,0,0,1-12,12H96a12,12,0,0,1,0-24h64A12,12,0,0,1,172,124Zm-12,28H96a12,12,0,0,0,0,24h64a12,12,0,0,0,0-24ZM220,48V200a36,36,0,0,1-36,36H72a36,36,0,0,1-36-36V48A20.1,20.1,0,0,1,56,28H72V24a12,12,0,0,1,24,0v4h20V24a12,12,0,0,1,24,0v4h20V24a12,12,0,0,1,24,0v4h16A20.1,20.1,0,0,1,220,48Zm-24,4H184v4a12,12,0,0,1-24,0V52H140v4a12,12,0,0,1-24,0V52H96v4a12,12,0,0,1-24,0V52H60V200a12,12,0,0,0,12,12H184a12,12,0,0,0,12-12Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M208,48V200a23.9,23.9,0,0,1-24,24H72a23.9,23.9,0,0,1-24-24V48a8,8,0,0,1,8-8H200A8,8,0,0,1,208,48Z"
            opacity="0.2"
          />
          <path d="M168,128a8,8,0,0,1-8,8H96a8,8,0,0,1,0-16h64A8,8,0,0,1,168,128Zm-8,24H96a8,8,0,0,0,0,16h64a8,8,0,0,0,0-16ZM216,48V200a32.1,32.1,0,0,1-32,32H72a32.1,32.1,0,0,1-32-32V48A16,16,0,0,1,56,32H72V24a8,8,0,0,1,16,0v8h32V24a8,8,0,0,1,16,0v8h32V24a8,8,0,0,1,16,0v8h16A16,16,0,0,1,216,48Zm-16,0H184v8a8,8,0,0,1-16,0V48H136v8a8,8,0,0,1-16,0V48H88v8a8,8,0,0,1-16,0V48H56V200a16,16,0,0,0,16,16H184a16,16,0,0,0,16-16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M200,32H184V24a8,8,0,0,0-16,0v8H136V24a8,8,0,0,0-16,0v8H88V24a8,8,0,0,0-16,0v8H56A16,16,0,0,0,40,48V200a32.1,32.1,0,0,0,32,32H184a32.1,32.1,0,0,0,32-32V48A16,16,0,0,0,200,32ZM160,168H96a8,8,0,0,1,0-16h64a8,8,0,0,1,0,16Zm0-32H96a8,8,0,0,1,0-16h64a8,8,0,0,1,0,16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M166,128a6,6,0,0,1-6,6H96a6,6,0,0,1,0-12h64A6,6,0,0,1,166,128Zm-6,26H96a6,6,0,0,0,0,12h64a6,6,0,0,0,0-12ZM214,48V200a30.1,30.1,0,0,1-30,30H72a30.1,30.1,0,0,1-30-30V48A14,14,0,0,1,56,34H74V24a6,6,0,0,1,12,0V34h36V24a6,6,0,0,1,12,0V34h36V24a6,6,0,0,1,12,0V34h18A14,14,0,0,1,214,48Zm-12,0a2,2,0,0,0-2-2H182V56a6,6,0,0,1-12,0V46H134V56a6,6,0,0,1-12,0V46H86V56a6,6,0,0,1-12,0V46H56a2,2,0,0,0-2,2V200a18.1,18.1,0,0,0,18,18H184a18.1,18.1,0,0,0,18-18Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M164,128a4,4,0,0,1-4,4H96a4,4,0,0,1,0-8h64A4,4,0,0,1,164,128Zm-4,28H96a4,4,0,0,0,0,8h64a4,4,0,0,0,0-8ZM212,48V200a28.1,28.1,0,0,1-28,28H72a28.1,28.1,0,0,1-28-28V48A12,12,0,0,1,56,36H76V24a4,4,0,0,1,8,0V36h40V24a4,4,0,0,1,8,0V36h40V24a4,4,0,0,1,8,0V36h20A12,12,0,0,1,212,48Zm-8,0a4,4,0,0,0-4-4H180V56a4,4,0,0,1-8,0V44H132V56a4,4,0,0,1-8,0V44H84V56a4,4,0,0,1-8,0V44H56a4,4,0,0,0-4,4V200a20.1,20.1,0,0,0,20,20H184a20.1,20.1,0,0,0,20-20Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M168,128a8,8,0,0,1-8,8H96a8,8,0,0,1,0-16h64A8,8,0,0,1,168,128Zm-8,24H96a8,8,0,0,0,0,16h64a8,8,0,0,0,0-16ZM216,48V200a32.1,32.1,0,0,1-32,32H72a32.1,32.1,0,0,1-32-32V48A16,16,0,0,1,56,32H72V24a8,8,0,0,1,16,0v8h32V24a8,8,0,0,1,16,0v8h32V24a8,8,0,0,1,16,0v8h16A16,16,0,0,1,216,48Zm-16,0H184v8a8,8,0,0,1-16,0V48H136v8a8,8,0,0,1-16,0V48H88v8a8,8,0,0,1-16,0V48H56V200a16,16,0,0,0,16,16H184a16,16,0,0,0,16-16Z" />
        </>
      )}
    </svg>
  );
}

export function PhNotification(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M220,128v80a20.1,20.1,0,0,1-20,20H48a20.1,20.1,0,0,1-20-20V56A20.1,20.1,0,0,1,48,36h80a12,12,0,0,1,0,24H52V204H196V128a12,12,0,0,1,24,0Zm16-68a40,40,0,1,1-40-40A40,40,0,0,1,236,60Zm-24,0a16,16,0,1,0-16,16A16,16,0,0,0,212,60Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M224,60a28,28,0,1,1-28-28A28,28,0,0,1,224,60Z"
            opacity="0.2"
          />
          <path d="M216,128v80a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V56A16,16,0,0,1,48,40h80a8,8,0,0,1,0,16H48V208H200V128a8,8,0,0,1,16,0Zm16-68a36,36,0,1,1-36-36A36,36,0,0,1,232,60Zm-16,0a20,20,0,1,0-20,20A20.1,20.1,0,0,0,216,60Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,128v80a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V56A16,16,0,0,1,48,40h80a8,8,0,0,1,0,16H48V208H200V128a8,8,0,0,1,16,0ZM196,24a36,36,0,1,0,36,36A36,36,0,0,0,196,24Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M214,128v80a14,14,0,0,1-14,14H48a14,14,0,0,1-14-14V56A14,14,0,0,1,48,42h80a6,6,0,0,1,0,12H48a2,2,0,0,0-2,2V208a2,2,0,0,0,2,2H200a2,2,0,0,0,2-2V128a6,6,0,0,1,12,0Zm16-68a34,34,0,1,1-34-34A34.1,34.1,0,0,1,230,60Zm-12,0a22,22,0,1,0-22,22A22.1,22.1,0,0,0,218,60Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M212,128v80a12,12,0,0,1-12,12H48a12,12,0,0,1-12-12V56A12,12,0,0,1,48,44h80a4,4,0,0,1,0,8H48a4,4,0,0,0-4,4V208a4,4,0,0,0,4,4H200a4,4,0,0,0,4-4V128a4,4,0,0,1,8,0Zm16-68a32,32,0,1,1-32-32A32.1,32.1,0,0,1,228,60Zm-8,0a24,24,0,1,0-24,24A24.1,24.1,0,0,0,220,60Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,128v80a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V56A16,16,0,0,1,48,40h80a8,8,0,0,1,0,16H48V208H200V128a8,8,0,0,1,16,0Zm16-68a36,36,0,1,1-36-36A36,36,0,0,1,232,60Zm-16,0a20,20,0,1,0-20,20A20.1,20.1,0,0,0,216,60Z" />
        </>
      )}
    </svg>
  );
}

export function PhNumberCircleEight(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,20A108,108,0,1,0,236,128,108.1,108.1,0,0,0,128,20Zm0,192a84,84,0,1,1,84-84A84.1,84.1,0,0,1,128,212Zm26.7-89a33.9,33.9,0,0,0,7.3-21,33.9,33.9,0,0,0-58-24,33.3,33.3,0,0,0-10,24,33.9,33.9,0,0,0,7.3,21h-.2a38,38,0,1,0,53.8,0Zm-33.8-13.9A10.1,10.1,0,1,1,128,112,9.9,9.9,0,0,1,120.9,109.1Zm17,50.8A14,14,0,1,1,142,150,14.1,14.1,0,0,1,137.9,159.9Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="128" cy="128" r="96" />
          </g>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm21.1-92.7h.1a30,30,0,1,0-42.4,0h.1A24.4,24.4,0,0,0,104,126a33.9,33.9,0,1,0,48,48,33.8,33.8,0,0,0,0-48A24.4,24.4,0,0,0,149.1,123.3Zm-31-11.4A14,14,0,1,1,128,116,14.1,14.1,0,0,1,118.1,111.9Zm22.6,50.8A17.9,17.9,0,1,1,146,150,17.9,17.9,0,0,1,140.7,162.7Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm24,150a33.9,33.9,0,1,1-48-48,26.6,26.6,0,0,1,3.1-2.6l-.3-.2a29.9,29.9,0,0,1,0-42.4c11.3-11.3,31.1-11.3,42.4,0a29.9,29.9,0,0,1,0,42.4l-.3.2A26.6,26.6,0,0,1,152,126a33.8,33.8,0,0,1,0,48Zm-33.9-62.1a14,14,0,1,1,19.8,0A14.3,14.3,0,0,1,118.1,111.9Zm22.6,25.4a17.9,17.9,0,0,1,0,25.4,18.4,18.4,0,0,1-25.4,0,18,18,0,1,1,25.4-25.4Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,26A102,102,0,1,0,230,128,102.2,102.2,0,0,0,128,26Zm0,192a90,90,0,1,1,90-90A90.1,90.1,0,0,1,128,218Zm17.9-94.5a15.4,15.4,0,0,0,1.9-1.7,28,28,0,1,0-39.6,0,15.4,15.4,0,0,0,1.9,1.7,25,25,0,0,0-4.7,3.9,32,32,0,1,0,45.2,0A25,25,0,0,0,145.9,123.5Zm-29.2-10.2A15.9,15.9,0,1,1,128,118,15.9,15.9,0,0,1,116.7,113.3Zm25.4,50.8A19.8,19.8,0,1,1,148,150,19.7,19.7,0,0,1,142.1,164.1Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,28A100,100,0,1,0,228,128,100.2,100.2,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm14.4-96.3a41.2,41.2,0,0,0,4-3.3,26,26,0,1,0-36.8,0,41.2,41.2,0,0,0,4,3.3,25.8,25.8,0,0,0-6.8,5.1,30,30,0,1,0,42.4,0A25.8,25.8,0,0,0,142.4,123.7Zm-27.1-9A17.9,17.9,0,1,1,128,120,17.9,17.9,0,0,1,115.3,114.7Zm28.3,50.9A22.2,22.2,0,1,1,150,150,22.2,22.2,0,0,1,143.6,165.6Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm21.1-92.7h.1a30,30,0,1,0-42.4,0h.1A24.4,24.4,0,0,0,104,126a33.9,33.9,0,1,0,48,48,33.8,33.8,0,0,0,0-48A24.4,24.4,0,0,0,149.1,123.3Zm-31-11.4A14,14,0,1,1,128,116,14.1,14.1,0,0,1,118.1,111.9Zm22.6,50.8A17.9,17.9,0,1,1,146,150,17.9,17.9,0,0,1,140.7,162.7Z" />
        </>
      )}
    </svg>
  );
}

export function PhNumberCircleFive(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,20A108,108,0,1,0,236,128,108.1,108.1,0,0,0,128,20Zm0,192a84,84,0,1,1,84-84A84.1,84.1,0,0,1,128,212ZM122,96l-2.6,16.2,4.5-.2a40,40,0,1,1,0,80,40.3,40.3,0,0,1-28.4-11.7,12,12,0,0,1,17-17,16.2,16.2,0,0,0,22.8,0,15.9,15.9,0,0,0,0-22.6,16.2,16.2,0,0,0-22.8,0,12,12,0,0,1-20.3-10.4L100,82.1A12,12,0,0,1,111.8,72H152a12,12,0,0,1,0,24Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="128" cy="128" r="96" />
          </g>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216ZM118.6,92l-4.1,25.2a37.3,37.3,0,0,1,9.4-1.2,36,36,0,1,1-25.5,61.5,8,8,0,1,1,11.2-11.4,20.1,20.1,0,0,0,14.3,5.9,19.9,19.9,0,0,0,14.2-5.9,19.8,19.8,0,0,0,0-28.2,19.9,19.9,0,0,0-14.2-5.9,20.1,20.1,0,0,0-14.3,5.9,8,8,0,0,1-13.5-7l7.8-48.2a8,8,0,0,1,7.9-6.7H152a8,8,0,0,1,0,16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm-4.1,92a36,36,0,1,1-25.5,61.5,8,8,0,1,1,11.2-11.4,20.1,20.1,0,0,0,14.3,5.9,19.9,19.9,0,0,0,14.2-5.9,19.8,19.8,0,0,0,0-28.2,19.9,19.9,0,0,0-14.2-5.9,20.1,20.1,0,0,0-14.3,5.9,8,8,0,0,1-13.5-7l7.8-48.2a8,8,0,0,1,7.9-6.7H152a8,8,0,0,1,0,16H118.6l-4.1,25.2A37.3,37.3,0,0,1,123.9,116Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,26A102,102,0,1,0,230,128,102.2,102.2,0,0,0,128,26Zm0,192a90,90,0,1,1,90-90A90.1,90.1,0,0,1,128,218ZM116.9,90,112,120.1a34.3,34.3,0,0,1,11.9-2.1A34.1,34.1,0,0,1,158,152a34,34,0,0,1-34.1,34,33.6,33.6,0,0,1-24.1-10,5.9,5.9,0,0,1-.1-8.4,6,6,0,0,1,8.5-.1,22.1,22.1,0,0,0,15.7,6.5,21.7,21.7,0,0,0,15.6-6.5,21.7,21.7,0,0,0,0-31,21.7,21.7,0,0,0-15.6-6.5,22.1,22.1,0,0,0-15.7,6.5,5.9,5.9,0,0,1-6.9,1,5.8,5.8,0,0,1-3.2-6.3L105.9,83a6,6,0,0,1,5.9-5H152a6,6,0,0,1,0,12Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,28A100,100,0,1,0,228,128,100.2,100.2,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220ZM115.2,88l-5.7,35.4a32.1,32.1,0,0,1,14.4-3.4A32.1,32.1,0,0,1,156,152a31.4,31.4,0,0,1-9.4,22.6,32.1,32.1,0,0,1-45.4,0,4,4,0,0,1,5.6-5.6,24.3,24.3,0,0,0,34.1,0,24,24,0,0,0-17-41,24.2,24.2,0,0,0-17.1,7,3.8,3.8,0,0,1-4.6.8,3.9,3.9,0,0,1-2.1-4.2l7.8-48.2a4,4,0,0,1,3.9-3.4H152a4,4,0,0,1,0,8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216ZM118.6,92l-4.1,25.2a37.3,37.3,0,0,1,9.4-1.2,36,36,0,1,1-25.5,61.5,8,8,0,1,1,11.2-11.4,20.1,20.1,0,0,0,14.3,5.9,19.9,19.9,0,0,0,14.2-5.9,19.8,19.8,0,0,0,0-28.2,19.9,19.9,0,0,0-14.2-5.9,20.1,20.1,0,0,0-14.3,5.9,8,8,0,0,1-13.5-7l7.8-48.2a8,8,0,0,1,7.9-6.7H152a8,8,0,0,1,0,16Z" />
        </>
      )}
    </svg>
  );
}

export function PhNumberCircleFour(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,20A108,108,0,1,0,236,128,108.1,108.1,0,0,0,128,20Zm0,192a84,84,0,1,1,84-84A84.1,84.1,0,0,1,128,212Zm32-100v64a12,12,0,0,1-24,0V156H100a12,12,0,0,1-11.3-16l24-68a12,12,0,1,1,22.6,8L117,132h19V112a12,12,0,0,1,24,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="128" cy="128" r="96" />
          </g>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm28-104v64a8,8,0,0,1-16,0V152H100a8,8,0,0,1-6.5-3.4,7.9,7.9,0,0,1-1-7.3l24-68a8,8,0,0,1,15,5.4L111.3,136H140V112a8,8,0,0,1,16,0Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm28,152a8,8,0,0,1-16,0V152H100a8,8,0,0,1-6.5-3.4,7.9,7.9,0,0,1-1-7.3l24-68a8,8,0,0,1,15,5.4L111.3,136H140V112a8,8,0,0,1,16,0Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,26A102,102,0,1,0,230,128,102.2,102.2,0,0,0,128,26Zm0,192a90,90,0,1,1,90-90A90.1,90.1,0,0,1,128,218Zm26-106v64a6,6,0,0,1-12,0V150H100a6,6,0,0,1-4.9-2.5,6.3,6.3,0,0,1-.8-5.5l24-68a6,6,0,1,1,11.4,4l-21.2,60H142V112a6,6,0,0,1,12,0Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,28A100,100,0,1,0,228,128,100.2,100.2,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm24-108v64a4,4,0,0,1-8,0V148H100a4,4,0,0,1-3.3-1.7,4.2,4.2,0,0,1-.5-3.6l24-68a4,4,0,1,1,7.6,2.6L105.7,140H144V112a4,4,0,0,1,8,0Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm28-104v64a8,8,0,0,1-16,0V152H100a8,8,0,0,1-6.5-3.4,7.9,7.9,0,0,1-1-7.3l24-68a8,8,0,0,1,15,5.4L111.3,136H140V112a8,8,0,0,1,16,0Z" />
        </>
      )}
    </svg>
  );
}

export function PhNumberCircleNine(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,20A108,108,0,1,0,236,128,108.1,108.1,0,0,0,128,20Zm0,192a84,84,0,1,1,84-84A84.1,84.1,0,0,1,128,212Zm0-140a40,40,0,0,0-5.1,79.7l-13.2,22.1a12.1,12.1,0,0,0,4.1,16.5A11.9,11.9,0,0,0,120,192a12,12,0,0,0,10.3-5.8l32.3-54c.1-.2.1-.4.2-.6A39.6,39.6,0,0,0,168,112,40,40,0,0,0,128,72Zm-16,40a16,16,0,1,1,16,16A16,16,0,0,1,112,112Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="128" cy="128" r="96" />
          </g>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm0-140a36,36,0,0,0,0,72h1.8l-16.7,27.9a8.1,8.1,0,0,0,2.8,11A8.7,8.7,0,0,0,120,188a8.1,8.1,0,0,0,6.9-3.9l32.2-54,.3-.5A36,36,0,0,0,128,76Zm-20,36a20,20,0,1,1,20,20A20.1,20.1,0,0,1,108,112Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M148,112a20,20,0,1,1-20-20A20.1,20.1,0,0,1,148,112Zm84,16A104,104,0,1,1,128,24,104.2,104.2,0,0,1,232,128Zm-68-16a36,36,0,1,0-36,36h1.8l-16.7,27.9a8.1,8.1,0,0,0,2.8,11A8.7,8.7,0,0,0,120,188a8.1,8.1,0,0,0,6.9-3.9l32.2-54,.3-.5A36,36,0,0,0,164,112Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,26A102,102,0,1,0,230,128,102.2,102.2,0,0,0,128,26Zm0,192a90,90,0,1,1,90-90A90.1,90.1,0,0,1,128,218Zm0-140a34,34,0,1,0,5.6,67.5l-18.8,31.4a6.1,6.1,0,0,0,2.1,8.3,6.8,6.8,0,0,0,3.1.8,6.1,6.1,0,0,0,5.2-2.9l32.2-54c.1-.2.2-.3.2-.5A33.4,33.4,0,0,0,162,112,34.1,34.1,0,0,0,128,78Zm-22,34a22,22,0,1,1,22,22A22.1,22.1,0,0,1,106,112Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,28A100,100,0,1,0,228,128,100.2,100.2,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm0-140a32,32,0,1,0,9.8,62.5l-21.2,35.4a4,4,0,0,0,1.3,5.5,4.2,4.2,0,0,0,2.1.6,3.9,3.9,0,0,0,3.4-1.9l32.3-54c0-.1,0-.2.1-.2A31.7,31.7,0,0,0,160,112,32.1,32.1,0,0,0,128,80Zm-24,32a24,24,0,1,1,24,24A24.1,24.1,0,0,1,104,112Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm0-140a36,36,0,0,0,0,72h1.8l-16.7,27.9a8.1,8.1,0,0,0,2.8,11A8.7,8.7,0,0,0,120,188a8.1,8.1,0,0,0,6.9-3.9l32.2-54,.3-.5A36,36,0,0,0,128,76Zm-20,36a20,20,0,1,1,20,20A20.1,20.1,0,0,1,108,112Z" />
        </>
      )}
    </svg>
  );
}

export function PhNumberCircleOne(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,20A108,108,0,1,0,236,128,108.1,108.1,0,0,0,128,20Zm0,192a84,84,0,1,1,84-84A84.1,84.1,0,0,1,128,212ZM144,84v92a12,12,0,0,1-24,0V106.4l-5.3,3.6a12,12,0,0,1-13.4-20l24-16a12.2,12.2,0,0,1,12.4-.6A12,12,0,0,1,144,84Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="128" cy="128" r="96" />
          </g>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216ZM140,84v92a8,8,0,0,1-16,0V98.9l-11.6,7.8a8,8,0,0,1-8.8-13.4l24-16a8.3,8.3,0,0,1,8.2-.4A8,8,0,0,1,140,84Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm12,152a8,8,0,0,1-16,0V98.9l-11.6,7.8a8,8,0,0,1-8.8-13.4l24-16a8.3,8.3,0,0,1,8.2-.4A8,8,0,0,1,140,84Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,26A102,102,0,1,0,230,128,102.2,102.2,0,0,0,128,26Zm0,192a90,90,0,1,1,90-90A90.1,90.1,0,0,1,128,218ZM138,84v92a6,6,0,0,1-12,0V95.2L111.3,105a6,6,0,0,1-6.6-10l24-16a6,6,0,0,1,9.3,5Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,28A100,100,0,1,0,228,128,100.2,100.2,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm8-136v92a4,4,0,0,1-8,0V91.5l-17.8,11.8a4,4,0,0,1-5.5-1.1,3.8,3.8,0,0,1,1.1-5.5l24-16a4,4,0,0,1,4.1-.2A3.9,3.9,0,0,1,136,84Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216ZM140,84v92a8,8,0,0,1-16,0V98.9l-11.6,7.8a8,8,0,0,1-8.8-13.4l24-16a8.3,8.3,0,0,1,8.2-.4A8,8,0,0,1,140,84Z" />
        </>
      )}
    </svg>
  );
}

export function PhNumberCircleSeven(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,20A108,108,0,1,0,236,128,108.1,108.1,0,0,0,128,20Zm0,192a84,84,0,1,1,84-84A84.1,84.1,0,0,1,128,212ZM161.7,77a11.9,11.9,0,0,1,1.7,10.8l-32,96A12.1,12.1,0,0,1,120,192a12.6,12.6,0,0,1-3.8-.6,12,12,0,0,1-7.6-15.2L135.4,96H104a12,12,0,0,1,0-24h48A11.9,11.9,0,0,1,161.7,77Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="128" cy="128" r="96" />
          </g>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216ZM158.5,79.3a8.1,8.1,0,0,1,1.1,7.2l-32,96A7.9,7.9,0,0,1,120,188a7.3,7.3,0,0,1-2.5-.4,8,8,0,0,1-5.1-10.1L140.9,92H104a8,8,0,0,1,0-16h48A7.9,7.9,0,0,1,158.5,79.3Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm31.6,62.5-32,96A7.9,7.9,0,0,1,120,188a7.3,7.3,0,0,1-2.5-.4,8,8,0,0,1-5.1-10.1L140.9,92H104a8,8,0,0,1,0-16h48a7.9,7.9,0,0,1,6.5,3.3A8.1,8.1,0,0,1,159.6,86.5Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,26A102,102,0,1,0,230,128,102.2,102.2,0,0,0,128,26Zm0,192a90,90,0,1,1,90-90A90.1,90.1,0,0,1,128,218ZM156.9,80.5a6.1,6.1,0,0,1,.8,5.4l-32,96A6,6,0,0,1,120,186a6.3,6.3,0,0,1-1.9-.3,6.1,6.1,0,0,1-3.8-7.6L143.7,90H104a6,6,0,0,1,0-12h48A6.1,6.1,0,0,1,156.9,80.5Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,28A100,100,0,1,0,228,128,100.2,100.2,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220ZM155.2,81.7a3.8,3.8,0,0,1,.6,3.6l-32,96A4.1,4.1,0,0,1,120,184l-1.3-.2a4,4,0,0,1-2.5-5.1L146.5,88H104a4,4,0,0,1,0-8h48A3.7,3.7,0,0,1,155.2,81.7Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216ZM158.5,79.3a8.1,8.1,0,0,1,1.1,7.2l-32,96A7.9,7.9,0,0,1,120,188a7.3,7.3,0,0,1-2.5-.4,8,8,0,0,1-5.1-10.1L140.9,92H104a8,8,0,0,1,0-16h48A7.9,7.9,0,0,1,158.5,79.3Z" />
        </>
      )}
    </svg>
  );
}

export function PhNumberCircleSix(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,20A108,108,0,1,0,236,128,108.1,108.1,0,0,0,128,20Zm0,192a84,84,0,1,1,84-84A84.1,84.1,0,0,1,128,212Zm5.1-107.7,13.2-22.1a12,12,0,1,0-20.6-12.4l-32.3,54-.3.6A40.6,40.6,0,0,0,88,144a40,40,0,1,0,45.1-39.7ZM128,160a16,16,0,1,1,16-16A16,16,0,0,1,128,160Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="128" cy="128" r="96" />
          </g>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm0-108h-1.8l16.7-27.9a8,8,0,1,0-13.8-8.2l-32.2,54-.3.6A36,36,0,1,0,128,108Zm0,56a20,20,0,1,1,20-20A20.1,20.1,0,0,1,128,164Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M148,144a20,20,0,1,1-20-20A20.1,20.1,0,0,1,148,144Zm84-16A104,104,0,1,1,128,24,104.2,104.2,0,0,1,232,128Zm-68,16a36,36,0,0,0-36-36h-1.8l16.7-27.9a8,8,0,1,0-13.8-8.2l-32.2,54-.3.6A36,36,0,1,0,164,144Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,26A102,102,0,1,0,230,128,102.2,102.2,0,0,0,128,26Zm0,192a90,90,0,1,1,90-90A90.1,90.1,0,0,1,128,218Zm0-108a34.3,34.3,0,0,0-5.6.5l18.8-31.4a6.1,6.1,0,0,0-2.1-8.3,6.2,6.2,0,0,0-8.3,2.1l-32.2,54-.3.6A34,34,0,1,0,128,110Zm0,56a22,22,0,1,1,22-22A22.1,22.1,0,0,1,128,166Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,28A100,100,0,1,0,228,128,100.2,100.2,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm0-108a31.9,31.9,0,0,0-9.8,1.5l21.2-35.4a4,4,0,1,0-6.8-4.2l-32.3,54a.4.4,0,0,1-.1.3A31.1,31.1,0,0,0,96,144a32,32,0,1,0,32-32Zm0,56a24,24,0,1,1,24-24A24.1,24.1,0,0,1,128,168Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm0-108h-1.8l16.7-27.9a8,8,0,1,0-13.8-8.2l-32.2,54-.3.6A36,36,0,1,0,128,108Zm0,56a20,20,0,1,1,20-20A20.1,20.1,0,0,1,128,164Z" />
        </>
      )}
    </svg>
  );
}

export function PhNumberCircleThree(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,20A108,108,0,1,0,236,128,108.1,108.1,0,0,0,128,20Zm0,192a84,84,0,1,1,84-84A84.1,84.1,0,0,1,128,212Zm24.3-88.3a40,40,0,0,1-56.6,56.6,12,12,0,0,1,17-17A16,16,0,1,0,124,136a12,12,0,0,1-9.8-18.9L129,96H104a12,12,0,0,1,0-24h48a12,12,0,0,1,9.8,18.9l-18.3,26.2A39.7,39.7,0,0,1,152.3,123.7Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="128" cy="128" r="96" />
          </g>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm21.5-89.5a36.1,36.1,0,0,1-51,51,8.1,8.1,0,0,1,11.4-11.4A19.9,19.9,0,1,0,124,132a8.1,8.1,0,0,1-7.1-4.3,8,8,0,0,1,.5-8.3L136.6,92H104a8,8,0,0,1,0-16h48a8.1,8.1,0,0,1,7.1,4.3,8,8,0,0,1-.5,8.3l-21.1,30A37.9,37.9,0,0,1,149.5,126.5Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm21.5,153.5a36.2,36.2,0,0,1-51,0,8.1,8.1,0,0,1,11.4-11.4A19.9,19.9,0,1,0,124,132a8.1,8.1,0,0,1-7.1-4.3,8,8,0,0,1,.5-8.3L136.6,92H104a8,8,0,0,1,0-16h48a8.1,8.1,0,0,1,7.1,4.3,8,8,0,0,1-.5,8.3l-21.1,30a37.9,37.9,0,0,1,12,7.9,36.2,36.2,0,0,1,0,51Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,26A102,102,0,1,0,230,128,102.2,102.2,0,0,0,128,26Zm0,192a90,90,0,1,1,90-90A90.1,90.1,0,0,1,128,218Zm20-90a33.9,33.9,0,1,1-48,48,5.9,5.9,0,0,1,8.4-8.4A22.1,22.1,0,1,0,124,130a5.9,5.9,0,0,1-5.3-3.2,5.8,5.8,0,0,1,.4-6.2L140.5,90H104a6,6,0,0,1,0-12h48a5.9,5.9,0,0,1,5.3,3.2,5.8,5.8,0,0,1-.4,6.2l-22.5,32.2A32.8,32.8,0,0,1,148,128Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,28A100,100,0,1,0,228,128,100.2,100.2,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm18.6-90.6a32,32,0,1,1-45.2,45.2A4,4,0,0,1,107,169a24,24,0,1,0,17-41,3.7,3.7,0,0,1-3.5-2.2,4,4,0,0,1,.2-4.1L144.3,88H104a4,4,0,0,1,0-8h48a3.8,3.8,0,0,1,3.5,2.1,4.2,4.2,0,0,1-.2,4.2l-24.2,34.5A30.7,30.7,0,0,1,146.6,129.4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm21.5-89.5a36.1,36.1,0,0,1-51,51,8.1,8.1,0,0,1,11.4-11.4A19.9,19.9,0,1,0,124,132a8.1,8.1,0,0,1-7.1-4.3,8,8,0,0,1,.5-8.3L136.6,92H104a8,8,0,0,1,0-16h48a8.1,8.1,0,0,1,7.1,4.3,8,8,0,0,1-.5,8.3l-21.1,30A37.9,37.9,0,0,1,149.5,126.5Z" />
        </>
      )}
    </svg>
  );
}

export function PhNumberCircleTwo(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,20A108,108,0,1,0,236,128,108.1,108.1,0,0,0,128,20Zm0,192a84,84,0,1,1,84-84A84.1,84.1,0,0,1,128,212Zm29.5-87.4L128,164h24a12,12,0,0,1,0,24H104a12.6,12.6,0,0,1-3.8-.6,12.1,12.1,0,0,1-5.7-18.8l43.6-58.1A12.3,12.3,0,0,0,140,104a12,12,0,0,0-23.1-4.7A12,12,0,0,1,94.8,90,36,36,0,0,1,164,104a35.1,35.1,0,0,1-6.2,20.1Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="128" cy="128" r="96" />
          </g>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm26.3-93.8L120,168h32a8,8,0,0,1,0,16H104a7.3,7.3,0,0,1-2.5-.4A8,8,0,0,1,96,176a7.5,7.5,0,0,1,1.7-4.9l43.7-58.3A16,16,0,0,0,128,88a15.9,15.9,0,0,0-14.7,9.8,8,8,0,0,1-14.8-6.3,32,32,0,1,1,56,30.4Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm24,144a8,8,0,0,1,0,16H104a7.3,7.3,0,0,1-2.5-.4A8,8,0,0,1,96,176a7.5,7.5,0,0,1,1.7-4.9l43.7-58.3A16,16,0,0,0,128,88a15.9,15.9,0,0,0-14.7,9.8,8,8,0,0,1-14.8-6.3,32,32,0,1,1,56,30.4l-.2.3L120,168Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,26A102,102,0,1,0,230,128,102.2,102.2,0,0,0,128,26Zm0,192a90,90,0,1,1,90-90A90.1,90.1,0,0,1,128,218Zm24.7-97L116,170h36a6,6,0,0,1,0,12H104a6.3,6.3,0,0,1-1.9-.3A6,6,0,0,1,98,176a5.9,5.9,0,0,1,1.3-3.7L143,113.9a17.2,17.2,0,0,0,3-9.9,18,18,0,0,0-34.6-7,6,6,0,1,1-11-4.7A30,30,0,0,1,158,104a30.4,30.4,0,0,1-5.1,16.8Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,28A100,100,0,1,0,228,128,100.2,100.2,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm23.1-100.2L112,172h40a4,4,0,0,1,0,8H104l-1.3-.2A4,4,0,0,1,100,176a3.8,3.8,0,0,1,.9-2.5l43.7-58.4a20,20,0,1,0-35-18.9,4,4,0,0,1-7.4-3.1A28,28,0,0,1,156,104a28.4,28.4,0,0,1-4.8,15.7Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm26.3-93.8L120,168h32a8,8,0,0,1,0,16H104a7.3,7.3,0,0,1-2.5-.4A8,8,0,0,1,96,176a7.5,7.5,0,0,1,1.7-4.9l43.7-58.3A16,16,0,0,0,128,88a15.9,15.9,0,0,0-14.7,9.8,8,8,0,0,1-14.8-6.3,32,32,0,1,1,56,30.4Z" />
        </>
      )}
    </svg>
  );
}

export function PhNumberCircleZero(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,68c-14.4,0-26.7,7.4-34.6,21C87.3,99.3,84,113.2,84,128s3.3,28.7,9.4,39c7.9,13.6,20.2,21,34.6,21s26.7-7.4,34.6-21c6.1-10.3,9.4-24.2,9.4-39s-3.3-28.7-9.4-39C154.7,75.4,142.4,68,128,68Zm0,96c-15.8,0-20-23.5-20-36s4.2-36,20-36,20,23.5,20,36S143.8,164,128,164Zm0-144A108,108,0,1,0,236,128,108.1,108.1,0,0,0,128,20Zm0,192a84,84,0,1,1,84-84A84.1,84.1,0,0,1,128,212Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="128" cy="128" r="96" />
          </g>
          <path d="M128,72c-13,0-24,6.7-31.2,19-5.7,9.7-8.8,22.9-8.8,37s3.1,27.3,8.8,37c7.2,12.3,18.2,19,31.2,19s24-6.7,31.2-19c5.7-9.7,8.8-22.9,8.8-37s-3.1-27.3-8.8-37C152,78.7,141,72,128,72Zm0,96c-8.9,0-24-8.4-24-40s15.1-40,24-40,24,8.4,24,40S136.9,168,128,168Zm0-144A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M152,128c0,14.9-5.1,40-24,40s-24-25.1-24-40,5.1-40,24-40S152,113.1,152,128Zm80,0A104,104,0,1,1,128,24,104.2,104.2,0,0,1,232,128Zm-64,0c0-27.9-12.4-56-40-56s-40,28.1-40,56,12.4,56,40,56S168,155.9,168,128Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,74c-12.4,0-22.6,6.2-29.4,18C93,101.4,90,114.2,90,128s3,26.6,8.6,36c6.8,11.8,17,18,29.4,18s22.6-6.2,29.4-18c5.6-9.4,8.6-22.2,8.6-36s-3-26.6-8.6-36C150.6,80.2,140.4,74,128,74Zm0,96c-18,0-26-21.1-26-42,0-29,13.1-42,26-42,18,0,26,21.1,26,42C154,157,140.9,170,128,170Zm0-144A102,102,0,1,0,230,128,102.2,102.2,0,0,0,128,26Zm0,192a90,90,0,1,1,90-90A90.1,90.1,0,0,1,128,218Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,76c-11.6,0-21.2,5.9-27.7,17-5.4,9.2-8.3,21.6-8.3,35s2.9,25.8,8.3,35c6.5,11.1,16.1,17,27.7,17s21.2-5.9,27.7-17c5.4-9.2,8.3-21.6,8.3-35s-2.9-25.8-8.3-35C149.2,81.9,139.6,76,128,76Zm0,96c-19.3,0-28-22.1-28-44s8.7-44,28-44,28,22.1,28,44S147.3,172,128,172Zm0-144A100,100,0,1,0,228,128,100.2,100.2,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,72c-13,0-24,6.7-31.2,19-5.7,9.7-8.8,22.9-8.8,37s3.1,27.3,8.8,37c7.2,12.3,18.2,19,31.2,19s24-6.7,31.2-19c5.7-9.7,8.8-22.9,8.8-37s-3.1-27.3-8.8-37C152,78.7,141,72,128,72Zm0,96c-8.9,0-24-8.4-24-40s15.1-40,24-40,24,8.4,24,40S136.9,168,128,168Zm0-144A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Z" />
        </>
      )}
    </svg>
  );
}

export function PhNumberEight(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M178.6,127.1a73.8,73.8,0,0,0-11.4-8.8,64,64,0,0,0,4.9-4.2,57.5,57.5,0,0,0,0-84.9,65.1,65.1,0,0,0-88.2,0,57.5,57.5,0,0,0,0,84.9,64,64,0,0,0,4.9,4.2,73.8,73.8,0,0,0-11.4,8.8,65.8,65.8,0,0,0,0,97.1,74.6,74.6,0,0,0,101.2,0,65.8,65.8,0,0,0,0-97.1ZM100.3,96.6a33.6,33.6,0,0,1,0-49.9,41.2,41.2,0,0,1,55.4,0,33.6,33.6,0,0,1,0,49.9,41.2,41.2,0,0,1-55.4,0Zm61.9,110.2a50.9,50.9,0,0,1-68.4,0,41.9,41.9,0,0,1,0-62.2,50.6,50.6,0,0,1,68.4,0,41.9,41.9,0,0,1,0,62.2Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M175.9,130a68.8,68.8,0,0,0-16.4-11.4,59.4,59.4,0,0,0,9.9-7.4,53.6,53.6,0,0,0,0-79.1,61.3,61.3,0,0,0-82.8,0,53.6,53.6,0,0,0,0,79.1,59.4,59.4,0,0,0,9.9,7.4A68.8,68.8,0,0,0,80.1,130a61.9,61.9,0,0,0,0,91.3,70.7,70.7,0,0,0,95.8,0,61.9,61.9,0,0,0,0-91.3ZM97.6,99.5a37.5,37.5,0,0,1,0-55.7,45.1,45.1,0,0,1,60.8,0,37.5,37.5,0,0,1,0,55.7,45.1,45.1,0,0,1-60.8,0ZM165,209.7a55,55,0,0,1-74,0,46,46,0,0,1,0-68,54.7,54.7,0,0,1,74,0,46,46,0,0,1,0,68Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M175.9,130a68.8,68.8,0,0,0-16.4-11.4,59.4,59.4,0,0,0,9.9-7.4,53.6,53.6,0,0,0,0-79.1,61.3,61.3,0,0,0-82.8,0,53.6,53.6,0,0,0,0,79.1,59.4,59.4,0,0,0,9.9,7.4A68.8,68.8,0,0,0,80.1,130a61.9,61.9,0,0,0,0,91.3,70.7,70.7,0,0,0,95.8,0,61.9,61.9,0,0,0,0-91.3ZM97.6,99.5a37.5,37.5,0,0,1,0-55.7,45.1,45.1,0,0,1,60.8,0,37.5,37.5,0,0,1,0,55.7,45.1,45.1,0,0,1-60.8,0ZM165,209.7a55,55,0,0,1-74,0,46,46,0,0,1,0-68,54.7,54.7,0,0,1,74,0,46,46,0,0,1,0,68Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M174.5,131.5a65.8,65.8,0,0,0-19.4-12.7,54.8,54.8,0,0,0,12.9-9.1,51.5,51.5,0,0,0,0-76.1,59.1,59.1,0,0,0-80,0,51.5,51.5,0,0,0,0,76.1,54.8,54.8,0,0,0,12.9,9.1,65.8,65.8,0,0,0-19.4,12.7,59.8,59.8,0,0,0,0,88.4,68.8,68.8,0,0,0,93,0,59.8,59.8,0,0,0,0-88.4ZM96.2,101a39.6,39.6,0,0,1,0-58.7,47.3,47.3,0,0,1,63.6,0,39.6,39.6,0,0,1,0,58.7,47.3,47.3,0,0,1-63.6,0Zm70.1,110.1a56.7,56.7,0,0,1-76.6,0,47.9,47.9,0,0,1,0-70.9,56.7,56.7,0,0,1,76.6,0,47.9,47.9,0,0,1,0,70.9Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M173.2,132.9A66.2,66.2,0,0,0,150,119a53.1,53.1,0,0,0,16.6-10.7,49.6,49.6,0,0,0,0-73.3,57.2,57.2,0,0,0-77.2,0,49.6,49.6,0,0,0,0,73.3A53.1,53.1,0,0,0,106,119a66.2,66.2,0,0,0-23.2,13.9,58,58,0,0,0,0,85.5,66.8,66.8,0,0,0,90.4,0,58,58,0,0,0,0-85.5ZM94.8,102.5a41.7,41.7,0,0,1,0-61.6,49.2,49.2,0,0,1,66.4,0,41.7,41.7,0,0,1,0,61.6,49.5,49.5,0,0,1-66.4,0Zm72.9,110.1a58.9,58.9,0,0,1-79.4,0,50,50,0,0,1,0-73.9,58.9,58.9,0,0,1,79.4,0,50,50,0,0,1,0,73.9Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M175.9,130a68.8,68.8,0,0,0-16.4-11.4,63.8,63.8,0,0,0,9.9-7.4,53.6,53.6,0,0,0,0-79.1,61.3,61.3,0,0,0-82.8,0,53.6,53.6,0,0,0,0,79.1,63.8,63.8,0,0,0,9.9,7.4A68.8,68.8,0,0,0,80.1,130a61.9,61.9,0,0,0,0,91.3,70.7,70.7,0,0,0,95.8,0,61.9,61.9,0,0,0,0-91.3ZM97.6,99.5a37.5,37.5,0,0,1,0-55.7,45.1,45.1,0,0,1,60.8,0,37.5,37.5,0,0,1,0,55.7,45.1,45.1,0,0,1-60.8,0ZM165,209.7a55,55,0,0,1-74,0,46,46,0,0,1,0-68,54.7,54.7,0,0,1,74,0,46,46,0,0,1,0,68Z" />
        </>
      )}
    </svg>
  );
}

export function PhNumberFive(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M168,119.9a67.8,67.8,0,0,1,0,96.2,68.4,68.4,0,0,1-96.5,0,12,12,0,0,1,17-17,44,44,0,1,0,0-62.2,12,12,0,0,1-20.3-10.4L83.8,30.1A12.1,12.1,0,0,1,95.7,20H176a12,12,0,0,1,0,24H105.9l-9.8,60.2A68.5,68.5,0,0,1,168,119.9Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M165.2,122.7a64,64,0,0,1,0,90.6,64.5,64.5,0,0,1-90.8,0,8,8,0,1,1,11.2-11.4,48.4,48.4,0,0,0,68.3,0A48,48,0,0,0,119.8,120a48.3,48.3,0,0,0-34.2,14.1,8,8,0,0,1-13.5-7L87.8,30.7A7.9,7.9,0,0,1,95.7,24H176a8,8,0,0,1,0,16H102.5L91,110.8a63,63,0,0,1,28.8-6.8A64.4,64.4,0,0,1,165.2,122.7Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M165.2,122.7a64,64,0,0,1,0,90.6,64.5,64.5,0,0,1-90.8,0,8,8,0,1,1,11.2-11.4,48.4,48.4,0,0,0,68.3,0A48,48,0,0,0,119.8,120a48.3,48.3,0,0,0-34.2,14.1,8,8,0,0,1-13.5-7L87.8,30.7A7.9,7.9,0,0,1,95.7,24H176a8,8,0,0,1,0,16H102.5L91,110.8a63,63,0,0,1,28.8-6.8A64.4,64.4,0,0,1,165.2,122.7Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M163.8,124.2a62.1,62.1,0,0,1-88,87.7,6,6,0,0,1,8.4-8.6,50.3,50.3,0,0,0,71.1,0,49.7,49.7,0,0,0,0-70.6,50.3,50.3,0,0,0-71.1,0,5.9,5.9,0,0,1-6.9,1,5.8,5.8,0,0,1-3.2-6.3L89.7,31a6,6,0,0,1,6-5H176a6,6,0,0,1,0,12H100.8L88.3,114.5a62.4,62.4,0,0,1,75.5,9.7Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M162.4,125.6a60.1,60.1,0,0,1-85.2,84.8,4,4,0,0,1,5.6-5.6,52.5,52.5,0,0,0,73.9,0,51.9,51.9,0,0,0,0-73.6,52.5,52.5,0,0,0-73.9,0,3.8,3.8,0,0,1-4.6.8,3.9,3.9,0,0,1-2.1-4.2L91.7,31.4a4,4,0,0,1,4-3.4H176a4,4,0,0,1,0,8H99.1L85.7,118.5a60.5,60.5,0,0,1,76.7,7.1Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M165.2,122.7a64,64,0,0,1,0,90.6,64.5,64.5,0,0,1-90.8,0,8,8,0,1,1,11.2-11.4,48.4,48.4,0,0,0,68.3,0A48,48,0,0,0,119.8,120a48.3,48.3,0,0,0-34.2,14.1,8,8,0,0,1-13.5-7L87.8,30.7A7.9,7.9,0,0,1,95.7,24H176a8,8,0,0,1,0,16H102.5L91,110.8a63,63,0,0,1,28.8-6.8A64.4,64.4,0,0,1,165.2,122.7Z" />
        </>
      )}
    </svg>
  );
}

export function PhNumberFour(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M184,96V224a12,12,0,0,1-24,0V172H76a12,12,0,0,1-11.3-16l48-136a12,12,0,1,1,22.6,8L93,148h67V96a12,12,0,0,1,24,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M180,96V224a8,8,0,0,1-16,0V168H76a8,8,0,0,1-6.5-3.4,7.9,7.9,0,0,1-1-7.3l48-136a8,8,0,0,1,15,5.4L87.3,152H164V96a8,8,0,0,1,16,0Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M180,96V224a8,8,0,0,1-16,0V168H76a8,8,0,0,1-6.5-3.4,7.9,7.9,0,0,1-1-7.3l48-136a8,8,0,0,1,15,5.4L87.3,152H164V96a8,8,0,0,1,16,0Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M178,96V224a6,6,0,0,1-12,0V166H76a6,6,0,0,1-4.9-2.5,6.3,6.3,0,0,1-.8-5.5l48-136a6,6,0,0,1,11.4,4L84.5,154H166V96a6,6,0,0,1,12,0Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M176,96V224a4,4,0,0,1-8,0V164H76a4,4,0,0,1-3.3-1.7,4.2,4.2,0,0,1-.5-3.6l48-136a4,4,0,0,1,7.6,2.6L81.7,156H168V96a4,4,0,0,1,8,0Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M180,96V224a8,8,0,0,1-16,0V168H76a8,8,0,0,1-6.5-3.4,7.9,7.9,0,0,1-1-7.3l48-136a8,8,0,0,1,15,5.4L87.3,152H164V96a8,8,0,0,1,16,0Z" />
        </>
      )}
    </svg>
  );
}

export function PhNumberNine(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M186.8,122.2l.3-.6A67.2,67.2,0,0,0,196,88a67.9,67.9,0,1,0-56.9,67.1l-37.6,62.7a11.9,11.9,0,0,0,4.1,16.5,11.3,11.3,0,0,0,6.1,1.7,12,12,0,0,0,10.3-5.8l64.1-107h0ZM128,132a44,44,0,1,1,37.8-21.5l-.3.5A44,44,0,0,1,128,132Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M183.4,120.1l.2-.5A64,64,0,1,0,128,152a65.1,65.1,0,0,0,19.4-3l-42.5,70.9a8,8,0,0,0,2.7,11,8.7,8.7,0,0,0,4.1,1.1,8,8,0,0,0,6.9-3.9l64.1-106.9a.1.1,0,0,1,.1-.1ZM128,136a48,48,0,1,1,41.2-23.4l-.2.4A48.1,48.1,0,0,1,128,136Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M192,88a64,64,0,1,0-64,64,65.1,65.1,0,0,0,19.4-3l-42.5,70.9a8,8,0,0,0,2.7,11,8.7,8.7,0,0,0,4.1,1.1,8,8,0,0,0,6.9-3.9l64.7-108A63.5,63.5,0,0,0,192,88ZM80,88a48,48,0,1,1,48,48A48,48,0,0,1,80,88Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M181.6,119.1l.3-.6A60.7,60.7,0,0,0,190,88a61.9,61.9,0,1,0-38,57.2l-45.4,75.7a5.9,5.9,0,0,0,2.1,8.2,5.5,5.5,0,0,0,3,.9,5.9,5.9,0,0,0,5.2-2.9L181,120.2c0-.1,0-.1.1-.2ZM128,138a50,50,0,1,1,42.9-24.3l-.2.3A50.1,50.1,0,0,1,128,138Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M179.9,118.1a.4.4,0,0,0,.1-.3A59,59,0,0,0,188,88a60,60,0,1,0-30.8,52.4l-48.9,81.5a4,4,0,0,0,1.4,5.5,3.6,3.6,0,0,0,2,.6,4.2,4.2,0,0,0,3.5-1.9l64.1-106.9c0-.1,0-.2.1-.2ZM128,140a52,52,0,1,1,52-52,51.5,51.5,0,0,1-7.4,26.7l-.2.4A52.1,52.1,0,0,1,128,140Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M183.4,120.1l.2-.5A64,64,0,1,0,128,152a65.1,65.1,0,0,0,19.4-3l-42.5,70.9a8,8,0,0,0,2.7,11,8.7,8.7,0,0,0,4.1,1.1,8,8,0,0,0,6.9-3.9l64.1-106.9a.1.1,0,0,1,.1-.1ZM128,136a48,48,0,1,1,41.2-23.4l-.2.4A48.1,48.1,0,0,1,128,136Z" />
        </>
      )}
    </svg>
  );
}

export function PhNumberOne(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M144,32V224a12,12,0,0,1-24,0V54.4L90.7,74A12.1,12.1,0,0,1,74,70.6,11.9,11.9,0,0,1,77.3,54l48-32a12.2,12.2,0,0,1,12.4-.6A12,12,0,0,1,144,32Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M140,32V224a8,8,0,0,1-16,0V46.9L88.4,70.6a8,8,0,0,1-11.1-2.2,8.1,8.1,0,0,1,2.3-11.1l48-32a8.3,8.3,0,0,1,8.2-.4A8,8,0,0,1,140,32Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M140,32V224a8,8,0,0,1-16,0V47L88.4,70.6a8,8,0,0,1-11.1-2.2,8.1,8.1,0,0,1,2.3-11.1l48-32a8.3,8.3,0,0,1,8.2-.4A8,8,0,0,1,140,32Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M138,32V224a6,6,0,0,1-12,0V43.2L87.3,69a6,6,0,1,1-6.6-10l48-32a6,6,0,0,1,9.3,5Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M136,32V224a4,4,0,0,1-8,0V39.5L86.2,67.3a4,4,0,0,1-5.5-1.1,3.8,3.8,0,0,1,1.1-5.5l48-32a4,4,0,0,1,4.1-.2A3.9,3.9,0,0,1,136,32Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M140,32V224a8,8,0,0,1-16,0V46.9L88.4,70.6a8,8,0,0,1-11.1-2.2,8.1,8.1,0,0,1,2.3-11.1l48-32a8.3,8.3,0,0,1,8.2-.4A8,8,0,0,1,140,32Z" />
        </>
      )}
    </svg>
  );
}

export function PhNumberSeven(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M187.4,43.8l-64,192A12.1,12.1,0,0,1,112,244a12.6,12.6,0,0,1-3.8-.6,12,12,0,0,1-7.6-15.2L159.4,52H80a12,12,0,0,1,0-24h96a12.1,12.1,0,0,1,11.4,15.8Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M183.6,42.5l-64,192A7.9,7.9,0,0,1,112,240a7.3,7.3,0,0,1-2.5-.4,8,8,0,0,1-5.1-10.1L164.9,48H80a8,8,0,0,1,0-16h96a7.9,7.9,0,0,1,6.5,3.3A8.1,8.1,0,0,1,183.6,42.5Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M183.6,42.5l-64,192A7.9,7.9,0,0,1,112,240a7.3,7.3,0,0,1-2.5-.4,8,8,0,0,1-5.1-10.1L164.9,48H80a8,8,0,0,1,0-16h96a7.9,7.9,0,0,1,6.5,3.3A8.1,8.1,0,0,1,183.6,42.5Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M181.7,41.9l-64,192A6,6,0,0,1,112,238a6.3,6.3,0,0,1-1.9-.3,6.1,6.1,0,0,1-3.8-7.6L167.7,46H80a6,6,0,0,1,0-12h96a6,6,0,0,1,5.7,7.9Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M179.8,41.3l-64,192A4.1,4.1,0,0,1,112,236l-1.3-.2a4,4,0,0,1-2.5-5.1L170.5,44H80a4,4,0,0,1,0-8h96a3.7,3.7,0,0,1,3.2,1.7A3.8,3.8,0,0,1,179.8,41.3Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M183.6,42.5l-64,192A7.9,7.9,0,0,1,112,240a7.3,7.3,0,0,1-2.5-.4,8,8,0,0,1-5.1-10.1L164.9,48H80a8,8,0,0,1,0-16h96a7.9,7.9,0,0,1,6.5,3.3A8.1,8.1,0,0,1,183.6,42.5Z" />
        </>
      )}
    </svg>
  );
}

export function PhNumberSix(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,100a69.5,69.5,0,0,0-11.2.9l37.5-62.7a12,12,0,1,0-20.6-12.4L69.2,133.9l-.3.5A68,68,0,1,0,128,100Zm0,112a44,44,0,0,1-44-44,43.4,43.4,0,0,1,6.3-22.6v-.3A44,44,0,1,1,128,212Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M128,104a66.3,66.3,0,0,0-19.5,3l42.4-70.9a8,8,0,0,0-13.8-8.2l-64.5,108-.2.5A63,63,0,0,0,64,168a64,64,0,1,0,64-64Zm0,112a48.1,48.1,0,0,1-41.2-72.7l.2-.3a48,48,0,1,1,41,73Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,104a66.3,66.3,0,0,0-19.5,3l42.4-70.9a8,8,0,0,0-13.8-8.2l-64.5,108-.2.5A63,63,0,0,0,64,168a64,64,0,1,0,64-64Zm0,112a48.1,48.1,0,0,1-41.2-72.7l.2-.3a48,48,0,1,1,41,73Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,106a60.7,60.7,0,0,0-24.1,4.9l45.3-75.8a6.1,6.1,0,0,0-10.4-6.2l-64.4,108-.3.5A62,62,0,1,0,128,106Zm0,112a50,50,0,0,1-42.8-75.8V142A50,50,0,1,1,128,218Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,108a59.6,59.6,0,0,0-29.3,7.7l48.7-81.6a4,4,0,0,0-6.8-4.2l-63.9,107h0l-.6,1.1c-.1,0-.1.1-.1.2A59,59,0,0,0,68,168a60,60,0,1,0,60-60Zm0,112a52,52,0,0,1-52-52,51,51,0,0,1,7.5-26.8V141A52,52,0,1,1,128,220Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,104a66.3,66.3,0,0,0-19.5,3l42.4-70.9a8,8,0,0,0-13.8-8.2l-64.5,108-.2.5A63,63,0,0,0,64,168a64,64,0,1,0,64-64Zm0,112a48.1,48.1,0,0,1-41.2-72.7l.2-.3a48,48,0,1,1,41,73Z" />
        </>
      )}
    </svg>
  );
}

export function PhNumberSquareEight(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M90,150a38,38,0,1,0,64.9-26.9h-.2a33.9,33.9,0,0,0,7.3-21,33.9,33.9,0,0,0-58-24,33.3,33.3,0,0,0-10,24,33.9,33.9,0,0,0,7.3,21h-.2A38.1,38.1,0,0,0,90,150Zm47.9,9.9A14,14,0,1,1,142,150,14.1,14.1,0,0,1,137.9,159.9Zm-17-50.8A10.1,10.1,0,1,1,128,112,9.9,9.9,0,0,1,120.9,109.1ZM208,28H48A20.1,20.1,0,0,0,28,48V208a20.1,20.1,0,0,0,20,20H208a20.1,20.1,0,0,0,20-20V48A20.1,20.1,0,0,0,208,28Zm-4,176H52V52H204Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect x="40" y="40" width="176" height="176" rx="8" />
          </g>
          <path d="M149.1,123.3h.1a30,30,0,1,0-42.4,0h.1A24.4,24.4,0,0,0,104,126a33.9,33.9,0,1,0,48,48,33.8,33.8,0,0,0,0-48A24.4,24.4,0,0,0,149.1,123.3Zm-31-11.4A14,14,0,1,1,128,116,14.1,14.1,0,0,1,118.1,111.9Zm22.6,50.8A17.9,17.9,0,1,1,146,150,17.9,17.9,0,0,1,140.7,162.7ZM208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32Zm0,176H48V48H208V208Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M118.1,111.9a14,14,0,1,1,19.8,0A14.3,14.3,0,0,1,118.1,111.9ZM128,132a18,18,0,0,0-12.7,30.7,18.4,18.4,0,0,0,25.4,0A18,18,0,0,0,128,132Zm96-84V208a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V48A16,16,0,0,1,48,32H208A16,16,0,0,1,224,48ZM162,150a33.3,33.3,0,0,0-10-24,26.6,26.6,0,0,0-3.1-2.6l.3-.2a29.9,29.9,0,0,0,0-42.4c-11.3-11.3-31.1-11.3-42.4,0a29.9,29.9,0,0,0,0,42.4l.3.2A26.6,26.6,0,0,0,104,126a33.9,33.9,0,1,0,48,48A33.3,33.3,0,0,0,162,150Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M145.9,123.5a15.4,15.4,0,0,0,1.9-1.7,28,28,0,1,0-39.6,0,15.4,15.4,0,0,0,1.9,1.7,25,25,0,0,0-4.7,3.9,32,32,0,1,0,45.2,0A25,25,0,0,0,145.9,123.5Zm-29.2-10.2A15.9,15.9,0,1,1,128,118,15.9,15.9,0,0,1,116.7,113.3Zm25.4,50.8A19.8,19.8,0,1,1,148,150,19.7,19.7,0,0,1,142.1,164.1ZM208,34H48A14,14,0,0,0,34,48V208a14,14,0,0,0,14,14H208a14,14,0,0,0,14-14V48A14,14,0,0,0,208,34Zm2,174a2,2,0,0,1-2,2H48a2,2,0,0,1-2-2V48a2,2,0,0,1,2-2H208a2,2,0,0,1,2,2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M142.4,123.7a41.2,41.2,0,0,0,4-3.3,26,26,0,1,0-36.8,0,41.2,41.2,0,0,0,4,3.3,25.8,25.8,0,0,0-6.8,5.1,30,30,0,1,0,42.4,0A25.8,25.8,0,0,0,142.4,123.7Zm-27.1-9A17.9,17.9,0,1,1,128,120,17.9,17.9,0,0,1,115.3,114.7Zm28.3,50.9A22.2,22.2,0,1,1,150,150,22.2,22.2,0,0,1,143.6,165.6ZM208,36H48A12,12,0,0,0,36,48V208a12,12,0,0,0,12,12H208a12,12,0,0,0,12-12V48A12,12,0,0,0,208,36Zm4,172a4,4,0,0,1-4,4H48a4,4,0,0,1-4-4V48a4,4,0,0,1,4-4H208a4,4,0,0,1,4,4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M149.1,123.3h.1a30,30,0,1,0-42.4,0h.1A24.4,24.4,0,0,0,104,126a33.9,33.9,0,1,0,48,48,33.8,33.8,0,0,0,0-48A24.4,24.4,0,0,0,149.1,123.3Zm-31-11.4A14,14,0,1,1,128,116,14.1,14.1,0,0,1,118.1,111.9Zm22.6,50.8A17.9,17.9,0,1,1,146,150,17.9,17.9,0,0,1,140.7,162.7ZM208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32Zm0,176H48V48H208V208Z" />
        </>
      )}
    </svg>
  );
}

export function PhNumberSquareFive(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M92.2,126.3,100,78.1A12,12,0,0,1,111.8,68H152a12,12,0,0,1,0,24H122l-2.6,16.2,4.5-.2a40,40,0,1,1,0,80,40.3,40.3,0,0,1-28.4-11.7,12,12,0,0,1,17-17,16.2,16.2,0,0,0,22.8,0,15.9,15.9,0,0,0,0-22.6,16.2,16.2,0,0,0-22.8,0,12,12,0,0,1-20.3-10.4ZM228,48V208a20.1,20.1,0,0,1-20,20H48a20.1,20.1,0,0,1-20-20V48A20.1,20.1,0,0,1,48,28H208A20.1,20.1,0,0,1,228,48Zm-24,4H52V204H204Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect x="40" y="40" width="176" height="176" rx="8" />
          </g>
          <path d="M118.6,88l-4.1,25.2a37.3,37.3,0,0,1,9.4-1.2,36,36,0,1,1-25.5,61.5,8,8,0,1,1,11.2-11.4,20.1,20.1,0,0,0,14.3,5.9,19.9,19.9,0,0,0,14.2-5.9,19.8,19.8,0,0,0,0-28.2,19.9,19.9,0,0,0-14.2-5.9,20.1,20.1,0,0,0-14.3,5.9,8,8,0,0,1-13.5-7l7.8-48.2a8,8,0,0,1,7.9-6.7H152a8,8,0,0,1,0,16ZM224,48V208a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V48A16,16,0,0,1,48,32H208A16,16,0,0,1,224,48ZM208,208V48H48V208Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32Zm-84.1,80a36,36,0,1,1-25.5,61.5,8,8,0,1,1,11.2-11.4,20.1,20.1,0,0,0,14.3,5.9,19.9,19.9,0,0,0,14.2-5.9,19.8,19.8,0,0,0,0-28.2,19.9,19.9,0,0,0-14.2-5.9,20.1,20.1,0,0,0-14.3,5.9,8,8,0,0,1-13.5-7l7.8-48.2a8,8,0,0,1,7.9-6.7H152a8,8,0,0,1,0,16H118.6l-4.1,25.2A37.3,37.3,0,0,1,123.9,112Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M116.9,86,112,116.1a34.3,34.3,0,0,1,11.9-2.1A34.1,34.1,0,0,1,158,148a34,34,0,0,1-34.1,34,33.6,33.6,0,0,1-24.1-10,5.9,5.9,0,0,1-.1-8.4,6,6,0,0,1,8.5-.1,22.1,22.1,0,0,0,15.7,6.5,21.7,21.7,0,0,0,15.6-6.5,21.7,21.7,0,0,0,0-31,21.7,21.7,0,0,0-15.6-6.5,22.1,22.1,0,0,0-15.7,6.5,5.9,5.9,0,0,1-6.9,1,5.8,5.8,0,0,1-3.2-6.3L105.9,79a6,6,0,0,1,5.9-5H152a6,6,0,0,1,0,12ZM222,48V208a14,14,0,0,1-14,14H48a14,14,0,0,1-14-14V48A14,14,0,0,1,48,34H208A14,14,0,0,1,222,48Zm-12,0a2,2,0,0,0-2-2H48a2,2,0,0,0-2,2V208a2,2,0,0,0,2,2H208a2,2,0,0,0,2-2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M115.2,84l-5.7,35.4a32.1,32.1,0,0,1,14.4-3.4A32.1,32.1,0,0,1,156,148a31.4,31.4,0,0,1-9.4,22.6,32.1,32.1,0,0,1-45.4,0,4,4,0,0,1,5.6-5.6,24.3,24.3,0,0,0,34.1,0,24,24,0,0,0-17-41,24.2,24.2,0,0,0-17.1,7,3.8,3.8,0,0,1-4.6.8,3.9,3.9,0,0,1-2.1-4.2l7.8-48.2a4,4,0,0,1,3.9-3.4H152a4,4,0,0,1,0,8ZM220,48V208a12,12,0,0,1-12,12H48a12,12,0,0,1-12-12V48A12,12,0,0,1,48,36H208A12,12,0,0,1,220,48Zm-8,0a4,4,0,0,0-4-4H48a4,4,0,0,0-4,4V208a4,4,0,0,0,4,4H208a4,4,0,0,0,4-4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M118.6,88l-4.1,25.2a37.3,37.3,0,0,1,9.4-1.2,36,36,0,1,1-25.5,61.5,8,8,0,1,1,11.2-11.4,20.1,20.1,0,0,0,14.3,5.9,19.9,19.9,0,0,0,14.2-5.9,19.8,19.8,0,0,0,0-28.2,19.9,19.9,0,0,0-14.2-5.9,20.1,20.1,0,0,0-14.3,5.9,8,8,0,0,1-13.5-7l7.8-48.2a8,8,0,0,1,7.9-6.7H152a8,8,0,0,1,0,16ZM224,48V208a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V48A16,16,0,0,1,48,32H208A16,16,0,0,1,224,48ZM208,208V48H48V208Z" />
        </>
      )}
    </svg>
  );
}

export function PhNumberSquareFour(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M90.2,150.9A12,12,0,0,1,88.7,140l24-68a12,12,0,1,1,22.6,8L117,132h19V112a12,12,0,0,1,24,0v64a12,12,0,0,1-24,0V156H100A12,12,0,0,1,90.2,150.9ZM228,48V208a20.1,20.1,0,0,1-20,20H48a20.1,20.1,0,0,1-20-20V48A20.1,20.1,0,0,1,48,28H208A20.1,20.1,0,0,1,228,48Zm-24,4H52V204H204Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect x="40" y="40" width="176" height="176" rx="8" />
          </g>
          <path d="M156,112v64a8,8,0,0,1-16,0V152H100a8,8,0,0,1-6.5-3.4,7.9,7.9,0,0,1-1-7.3l24-68a8,8,0,0,1,15,5.4L111.3,136H140V112a8,8,0,0,1,16,0Zm68-64V208a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V48A16,16,0,0,1,48,32H208A16,16,0,0,1,224,48ZM208,208V48H48V208Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM156,176a8,8,0,0,1-16,0V152H100a8,8,0,0,1-6.5-3.4,7.9,7.9,0,0,1-1-7.3l24-68a8,8,0,0,1,15,5.4L111.3,136H140V112a8,8,0,0,1,16,0Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M154,112v64a6,6,0,0,1-12,0V150H100a6,6,0,0,1-4.9-2.5,6.3,6.3,0,0,1-.8-5.5l24-68a6,6,0,1,1,11.4,4l-21.2,60H142V112a6,6,0,0,1,12,0Zm68-64V208a14,14,0,0,1-14,14H48a14,14,0,0,1-14-14V48A14,14,0,0,1,48,34H208A14,14,0,0,1,222,48Zm-12,0a2,2,0,0,0-2-2H48a2,2,0,0,0-2,2V208a2,2,0,0,0,2,2H208a2,2,0,0,0,2-2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M152,112v64a4,4,0,0,1-8,0V148H100a4,4,0,0,1-3.3-1.7,4.2,4.2,0,0,1-.5-3.6l24-68a4,4,0,1,1,7.6,2.6L105.7,140H144V112a4,4,0,0,1,8,0Zm68-64V208a12,12,0,0,1-12,12H48a12,12,0,0,1-12-12V48A12,12,0,0,1,48,36H208A12,12,0,0,1,220,48Zm-8,0a4,4,0,0,0-4-4H48a4,4,0,0,0-4,4V208a4,4,0,0,0,4,4H208a4,4,0,0,0,4-4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M156,112v64a8,8,0,0,1-16,0V152H100a8,8,0,0,1-6.5-3.4,7.9,7.9,0,0,1-1-7.3l24-68a8,8,0,0,1,15,5.4L111.3,136H140V112a8,8,0,0,1,16,0Zm68-64V208a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V48A16,16,0,0,1,48,32H208A16,16,0,0,1,224,48ZM208,208V48H48V208Z" />
        </>
      )}
    </svg>
  );
}

export function PhNumberSquareNine(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M208,28H48A20.1,20.1,0,0,0,28,48V208a20.1,20.1,0,0,0,20,20H208a20.1,20.1,0,0,0,20-20V48A20.1,20.1,0,0,0,208,28Zm-4,176H52V52H204Zm-81.1-52.3-13.2,22.1a12.1,12.1,0,0,0,4.1,16.5A11.9,11.9,0,0,0,120,192a12,12,0,0,0,10.3-5.8l32.3-54c.1-.2.1-.4.2-.6A39.6,39.6,0,0,0,168,112a40,40,0,1,0-45.1,39.7ZM128,96a16,16,0,1,1-16,16A16,16,0,0,1,128,96Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect x="40" y="40" width="176" height="176" rx="8" />
          </g>
          <path d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32Zm0,176H48V48H208V208ZM128,76a36,36,0,0,0,0,72h1.8l-16.7,27.9a8.1,8.1,0,0,0,2.8,11A8.7,8.7,0,0,0,120,188a8.1,8.1,0,0,0,6.9-3.9l32.2-54,.3-.5A36,36,0,0,0,128,76Zm-20,36a20,20,0,1,1,20,20A20.1,20.1,0,0,1,108,112Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M148,112a20,20,0,1,1-20-20A20.1,20.1,0,0,1,148,112Zm76-64V208a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V48A16,16,0,0,1,48,32H208A16,16,0,0,1,224,48Zm-60,64a36,36,0,1,0-36,36h1.8l-16.7,27.9a8.1,8.1,0,0,0,2.8,11A8.7,8.7,0,0,0,120,188a8.1,8.1,0,0,0,6.9-3.9l32.2-54,.3-.5A36,36,0,0,0,164,112Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M208,34H48A14,14,0,0,0,34,48V208a14,14,0,0,0,14,14H208a14,14,0,0,0,14-14V48A14,14,0,0,0,208,34Zm2,174a2,2,0,0,1-2,2H48a2,2,0,0,1-2-2V48a2,2,0,0,1,2-2H208a2,2,0,0,1,2,2ZM128,78a34,34,0,1,0,5.6,67.5l-18.8,31.4a6.1,6.1,0,0,0,2.1,8.3,6.8,6.8,0,0,0,3.1.8,6.1,6.1,0,0,0,5.2-2.9l32.2-54c.1-.2.2-.3.2-.5A33.4,33.4,0,0,0,162,112,34.1,34.1,0,0,0,128,78Zm-22,34a22,22,0,1,1,22,22A22.1,22.1,0,0,1,106,112Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M208,36H48A12,12,0,0,0,36,48V208a12,12,0,0,0,12,12H208a12,12,0,0,0,12-12V48A12,12,0,0,0,208,36Zm4,172a4,4,0,0,1-4,4H48a4,4,0,0,1-4-4V48a4,4,0,0,1,4-4H208a4,4,0,0,1,4,4ZM128,80a32,32,0,1,0,9.8,62.5l-21.2,35.4a4,4,0,0,0,1.3,5.5,4.2,4.2,0,0,0,2.1.6,3.9,3.9,0,0,0,3.4-1.9l32.3-54c0-.1,0-.2.1-.2A31.7,31.7,0,0,0,160,112,32.1,32.1,0,0,0,128,80Zm-24,32a24,24,0,1,1,24,24A24.1,24.1,0,0,1,104,112Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32Zm0,176H48V48H208V208ZM128,76a36,36,0,0,0,0,72h1.8l-16.7,27.9a8.1,8.1,0,0,0,2.8,11A8.7,8.7,0,0,0,120,188a8.1,8.1,0,0,0,6.9-3.9l32.2-54,.3-.5A36,36,0,0,0,128,76Zm-20,36a20,20,0,1,1,20,20A20.1,20.1,0,0,1,108,112Z" />
        </>
      )}
    </svg>
  );
}

export function PhNumberSquareOne(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M98,106.6A11.9,11.9,0,0,1,101.3,90l24-16a12.2,12.2,0,0,1,12.4-.6A12,12,0,0,1,144,84v92a12,12,0,0,1-24,0V106.4l-5.3,3.6A12,12,0,0,1,98,106.6ZM228,48V208a20.1,20.1,0,0,1-20,20H48a20.1,20.1,0,0,1-20-20V48A20.1,20.1,0,0,1,48,28H208A20.1,20.1,0,0,1,228,48Zm-24,4H52V204H204Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect x="40" y="40" width="176" height="176" rx="8" />
          </g>
          <path d="M140,84v92a8,8,0,0,1-16,0V98.9l-11.6,7.8a8,8,0,0,1-8.8-13.4l24-16a8.3,8.3,0,0,1,8.2-.4A8,8,0,0,1,140,84Zm84-36V208a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V48A16,16,0,0,1,48,32H208A16,16,0,0,1,224,48ZM208,208V48H48V208Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM140,176a8,8,0,0,1-16,0V99l-11.6,7.6a7.8,7.8,0,0,1-11-2.2,8,8,0,0,1,2.2-11.1l24-16A8,8,0,0,1,140,84Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M138,84v92a6,6,0,0,1-12,0V95.2L111.3,105a6,6,0,0,1-6.6-10l24-16a6,6,0,0,1,9.3,5Zm84-36V208a14,14,0,0,1-14,14H48a14,14,0,0,1-14-14V48A14,14,0,0,1,48,34H208A14,14,0,0,1,222,48Zm-12,0a2,2,0,0,0-2-2H48a2,2,0,0,0-2,2V208a2,2,0,0,0,2,2H208a2,2,0,0,0,2-2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M136,84v92a4,4,0,0,1-8,0V91.5l-17.8,11.8a4,4,0,0,1-5.5-1.1,3.8,3.8,0,0,1,1.1-5.5l24-16a4,4,0,0,1,4.1-.2A3.9,3.9,0,0,1,136,84Zm84-36V208a12,12,0,0,1-12,12H48a12,12,0,0,1-12-12V48A12,12,0,0,1,48,36H208A12,12,0,0,1,220,48Zm-8,0a4,4,0,0,0-4-4H48a4,4,0,0,0-4,4V208a4,4,0,0,0,4,4H208a4,4,0,0,0,4-4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M140,84v92a8,8,0,0,1-16,0V98.9l-11.6,7.8a8,8,0,0,1-8.8-13.4l24-16a8.3,8.3,0,0,1,8.2-.4A8,8,0,0,1,140,84Zm84-36V208a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V48A16,16,0,0,1,48,32H208A16,16,0,0,1,224,48ZM208,208V48H48V208Z" />
        </>
      )}
    </svg>
  );
}

export function PhNumberSquareSeven(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M92,84a12,12,0,0,1,12-12h48a12.1,12.1,0,0,1,11.4,15.8l-32,96A12.1,12.1,0,0,1,120,192a12.6,12.6,0,0,1-3.8-.6,12,12,0,0,1-7.6-15.2L135.4,96H104A12,12,0,0,1,92,84ZM228,48V208a20.1,20.1,0,0,1-20,20H48a20.1,20.1,0,0,1-20-20V48A20.1,20.1,0,0,1,48,28H208A20.1,20.1,0,0,1,228,48Zm-24,4H52V204H204Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect x="40" y="40" width="176" height="176" rx="8" />
          </g>
          <path d="M158.5,79.3a8.1,8.1,0,0,1,1.1,7.2l-32,96A7.9,7.9,0,0,1,120,188a7.3,7.3,0,0,1-2.5-.4,8,8,0,0,1-5.1-10.1L140.9,92H104a8,8,0,0,1,0-16h48A7.9,7.9,0,0,1,158.5,79.3ZM224,48V208a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V48A16,16,0,0,1,48,32H208A16,16,0,0,1,224,48ZM208,208V48H48V208Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM159.6,86.5l-32,96A7.9,7.9,0,0,1,120,188a7.3,7.3,0,0,1-2.5-.4,8,8,0,0,1-5.1-10.1L140.9,92H104a8,8,0,0,1,0-16h48a7.9,7.9,0,0,1,6.5,3.3A8.1,8.1,0,0,1,159.6,86.5Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M156.9,80.5a6.1,6.1,0,0,1,.8,5.4l-32,96A6,6,0,0,1,120,186a6.3,6.3,0,0,1-1.9-.3,6.1,6.1,0,0,1-3.8-7.6L143.7,90H104a6,6,0,0,1,0-12h48A6.1,6.1,0,0,1,156.9,80.5ZM222,48V208a14,14,0,0,1-14,14H48a14,14,0,0,1-14-14V48A14,14,0,0,1,48,34H208A14,14,0,0,1,222,48Zm-12,0a2,2,0,0,0-2-2H48a2,2,0,0,0-2,2V208a2,2,0,0,0,2,2H208a2,2,0,0,0,2-2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M155.2,81.7a3.8,3.8,0,0,1,.6,3.6l-32,96A4.1,4.1,0,0,1,120,184l-1.3-.2a4,4,0,0,1-2.5-5.1L146.5,88H104a4,4,0,0,1,0-8h48A3.7,3.7,0,0,1,155.2,81.7ZM220,48V208a12,12,0,0,1-12,12H48a12,12,0,0,1-12-12V48A12,12,0,0,1,48,36H208A12,12,0,0,1,220,48Zm-8,0a4,4,0,0,0-4-4H48a4,4,0,0,0-4,4V208a4,4,0,0,0,4,4H208a4,4,0,0,0,4-4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M158.5,79.3a8.1,8.1,0,0,1,1.1,7.2l-32,96A7.9,7.9,0,0,1,120,188a7.3,7.3,0,0,1-2.5-.4,8,8,0,0,1-5.1-10.1L140.9,92H104a8,8,0,0,1,0-16h48A7.9,7.9,0,0,1,158.5,79.3ZM224,48V208a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V48A16,16,0,0,1,48,32H208A16,16,0,0,1,224,48ZM208,208V48H48V208Z" />
        </>
      )}
    </svg>
  );
}

export function PhNumberSquareSix(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M208,28H48A20.1,20.1,0,0,0,28,48V208a20.1,20.1,0,0,0,20,20H208a20.1,20.1,0,0,0,20-20V48A20.1,20.1,0,0,0,208,28Zm-4,176H52V52H204Zm-76-16a40,40,0,0,0,5.1-79.7l13.2-22.2a12,12,0,0,0-20.6-12.3L93.5,127.9l-.2.3A39.4,39.4,0,0,0,88,148,40,40,0,0,0,128,188Zm16-40a16,16,0,1,1-16-16A16,16,0,0,1,144,148Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect x="40" y="40" width="176" height="176" rx="8" />
          </g>
          <path d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32Zm0,176H48V48H208V208Zm-80-96h-1.8l16.7-27.9a8,8,0,1,0-13.8-8.2l-32.2,54-.2.3A36,36,0,1,0,128,112Zm0,56a20,20,0,1,1,20-20A20.1,20.1,0,0,1,128,168Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M148,148a20,20,0,1,1-20-20A20.1,20.1,0,0,1,148,148ZM224,48V208a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V48A16,16,0,0,1,48,32H208A16,16,0,0,1,224,48ZM164,148a36,36,0,0,0-36-36h-1.8l16.7-27.9a8,8,0,1,0-13.8-8.2l-32.2,54-.2.3A36,36,0,1,0,164,148Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M208,34H48A14,14,0,0,0,34,48V208a14,14,0,0,0,14,14H208a14,14,0,0,0,14-14V48A14,14,0,0,0,208,34Zm2,174a2,2,0,0,1-2,2H48a2,2,0,0,1-2-2V48a2,2,0,0,1,2-2H208a2,2,0,0,1,2,2Zm-82-94a34.3,34.3,0,0,0-5.6.5l18.8-31.4a6.1,6.1,0,1,0-10.4-6.2l-32.2,54-.2.3A34,34,0,1,0,128,114Zm0,56a22.1,22.1,0,0,1-22-22,21.8,21.8,0,0,1,3.1-11.3h.1A22,22,0,1,1,128,170Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M208,36H48A12,12,0,0,0,36,48V208a12,12,0,0,0,12,12H208a12,12,0,0,0,12-12V48A12,12,0,0,0,208,36Zm4,172a4,4,0,0,1-4,4H48a4,4,0,0,1-4-4V48a4,4,0,0,1,4-4H208a4,4,0,0,1,4,4Zm-84-92a30.8,30.8,0,0,0-9.7,1.5L139.4,82a4,4,0,0,0-6.8-4.1L100.3,132h0A32,32,0,1,0,128,116Zm0,56a24.1,24.1,0,0,1-24-24,24.8,24.8,0,0,1,3.4-12.4h.1A24,24,0,1,1,128,172Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32Zm0,176H48V48H208V208Zm-80-96h-1.8l16.7-27.9a8,8,0,1,0-13.8-8.2l-32.2,54-.2.3A36,36,0,1,0,128,112Zm0,56a20,20,0,1,1,20-20A20.1,20.1,0,0,1,128,168Z" />
        </>
      )}
    </svg>
  );
}

export function PhNumberSquareThree(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M92,84a12,12,0,0,1,12-12h48a12,12,0,0,1,9.8,18.9l-18.3,26.2a40,40,0,1,1-47.8,63.2,12,12,0,0,1,17-17A16,16,0,1,0,124,136a12,12,0,0,1-9.8-18.9L129,96H104A12,12,0,0,1,92,84ZM228,48V208a20.1,20.1,0,0,1-20,20H48a20.1,20.1,0,0,1-20-20V48A20.1,20.1,0,0,1,48,28H208A20.1,20.1,0,0,1,228,48Zm-24,4H52V204H204Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect x="40" y="40" width="176" height="176" rx="8" />
          </g>
          <path d="M149.5,126.5a36.1,36.1,0,0,1-51,51,8.1,8.1,0,0,1,11.4-11.4A19.9,19.9,0,1,0,124,132a8.1,8.1,0,0,1-7.1-4.3,8,8,0,0,1,.5-8.3L136.6,92H104a8,8,0,0,1,0-16h48a8.1,8.1,0,0,1,7.1,4.3,7.8,7.8,0,0,1-.6,8.3l-21,30A37.9,37.9,0,0,1,149.5,126.5ZM224,48V208a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V48A16,16,0,0,1,48,32H208A16,16,0,0,1,224,48ZM208,208V48H48V208Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM149.5,177.5a36.2,36.2,0,0,1-51,0,8.1,8.1,0,0,1,11.4-11.4A19.9,19.9,0,1,0,124,132a8.1,8.1,0,0,1-7.1-4.3,8,8,0,0,1,.5-8.3L136.6,92H104a8,8,0,0,1,0-16h48a8.1,8.1,0,0,1,7.1,4.3,7.8,7.8,0,0,1-.6,8.3l-21,30a37.9,37.9,0,0,1,12,7.9,36.2,36.2,0,0,1,0,51Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M148,128a33.9,33.9,0,1,1-48,48,5.9,5.9,0,0,1,8.4-8.4A22.1,22.1,0,1,0,124,130a5.9,5.9,0,0,1-5.3-3.2,5.8,5.8,0,0,1,.4-6.2L140.5,90H104a6,6,0,0,1,0-12h48a5.9,5.9,0,0,1,5.3,3.2,6,6,0,0,1-.4,6.2l-22.5,32.2A32.8,32.8,0,0,1,148,128Zm74-80V208a14,14,0,0,1-14,14H48a14,14,0,0,1-14-14V48A14,14,0,0,1,48,34H208A14,14,0,0,1,222,48Zm-12,0a2,2,0,0,0-2-2H48a2,2,0,0,0-2,2V208a2,2,0,0,0,2,2H208a2,2,0,0,0,2-2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M146.6,129.4a32,32,0,1,1-45.2,45.2A4,4,0,0,1,107,169a24,24,0,1,0,17-41,3.8,3.8,0,0,1-3.5-2.1,4.2,4.2,0,0,1,.2-4.2L144.3,88H104a4,4,0,0,1,0-8h48a3.7,3.7,0,0,1,3.5,2.2,4,4,0,0,1-.2,4.1l-24.2,34.5A31.4,31.4,0,0,1,146.6,129.4ZM220,48V208a12,12,0,0,1-12,12H48a12,12,0,0,1-12-12V48A12,12,0,0,1,48,36H208A12,12,0,0,1,220,48Zm-8,0a4,4,0,0,0-4-4H48a4,4,0,0,0-4,4V208a4,4,0,0,0,4,4H208a4,4,0,0,0,4-4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M149.5,126.5a36.1,36.1,0,0,1-51,51,8.1,8.1,0,0,1,11.4-11.4A19.9,19.9,0,1,0,124,132a8.1,8.1,0,0,1-7.1-4.3,8,8,0,0,1,.5-8.3L136.6,92H104a8,8,0,0,1,0-16h48a8.1,8.1,0,0,1,7.1,4.3,7.8,7.8,0,0,1-.6,8.3l-21,30A37.9,37.9,0,0,1,149.5,126.5ZM224,48V208a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V48A16,16,0,0,1,48,32H208A16,16,0,0,1,224,48ZM208,208V48H48V208Z" />
        </>
      )}
    </svg>
  );
}

export function PhNumberSquareTwo(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M208,28H48A20.1,20.1,0,0,0,28,48V208a20.1,20.1,0,0,0,20,20H208a20.1,20.1,0,0,0,20-20V48A20.1,20.1,0,0,0,208,28Zm-4,176H52V52H204Zm-65.9-93.5A12.3,12.3,0,0,0,140,104a12,12,0,0,0-23.1-4.7A12,12,0,0,1,94.8,90,36,36,0,0,1,164,104a35.1,35.1,0,0,1-6.2,20.1l-.3.5L128,164h24a12,12,0,0,1,0,24H104a12.6,12.6,0,0,1-3.8-.6,12.1,12.1,0,0,1-5.7-18.8Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect x="40" y="40" width="176" height="176" rx="8" />
          </g>
          <path d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32Zm0,176H48V48H208V208Zm-53.7-85.8L120,168h32a8,8,0,0,1,0,16H104a7.3,7.3,0,0,1-2.5-.4A8,8,0,0,1,96,176a7.5,7.5,0,0,1,1.7-4.9l43.7-58.3A16,16,0,0,0,128,88a15.9,15.9,0,0,0-14.7,9.8,8.1,8.1,0,0,1-10.5,4.2,8,8,0,0,1-4.3-10.5,32,32,0,1,1,56,30.4Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM152,168a8,8,0,0,1,0,16H104a7.3,7.3,0,0,1-2.5-.4A8,8,0,0,1,96,176a7.5,7.5,0,0,1,1.7-4.9l43.7-58.3A16,16,0,0,0,128,88a15.9,15.9,0,0,0-14.7,9.8,8.1,8.1,0,0,1-10.5,4.2,8,8,0,0,1-4.3-10.5,32,32,0,1,1,56,30.4l-.2.3L120,168Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M208,34H48A14,14,0,0,0,34,48V208a14,14,0,0,0,14,14H208a14,14,0,0,0,14-14V48A14,14,0,0,0,208,34Zm2,174a2,2,0,0,1-2,2H48a2,2,0,0,1-2-2V48a2,2,0,0,1,2-2H208a2,2,0,0,1,2,2Zm-57.3-87L116,170h36a6,6,0,0,1,0,12H104a6.3,6.3,0,0,1-1.9-.3A6,6,0,0,1,98,176a5.9,5.9,0,0,1,1.3-3.7L143,113.9a17.2,17.2,0,0,0,3-9.9,18,18,0,0,0-34.6-7,6,6,0,1,1-11-4.7A30,30,0,0,1,158,104a30.4,30.4,0,0,1-5.1,16.8Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M208,36H48A12,12,0,0,0,36,48V208a12,12,0,0,0,12,12H208a12,12,0,0,0,12-12V48A12,12,0,0,0,208,36Zm4,172a4,4,0,0,1-4,4H48a4,4,0,0,1-4-4V48a4,4,0,0,1,4-4H208a4,4,0,0,1,4,4Zm-60.9-88.2L112,172h40a4,4,0,0,1,0,8H104l-1.3-.2A4,4,0,0,1,100,176a3.8,3.8,0,0,1,.9-2.5l43.7-58.4a20,20,0,1,0-35-18.9,4,4,0,0,1-7.4-3.1A28,28,0,0,1,156,104a28.4,28.4,0,0,1-4.8,15.7Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32Zm0,176H48V48H208V208Zm-53.7-85.8L120,168h32a8,8,0,0,1,0,16H104a7.3,7.3,0,0,1-2.5-.4A8,8,0,0,1,96,176a7.5,7.5,0,0,1,1.7-4.9l43.7-58.3A16,16,0,0,0,128,88a15.9,15.9,0,0,0-14.7,9.8,8.1,8.1,0,0,1-10.5,4.2,8,8,0,0,1-4.3-10.5,32,32,0,1,1,56,30.4Z" />
        </>
      )}
    </svg>
  );
}

export function PhNumberSquareZero(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,188c14.4,0,26.7-7.4,34.6-21,6.1-10.3,9.4-24.2,9.4-39s-3.3-28.7-9.4-39c-7.9-13.6-20.2-21-34.6-21s-26.7,7.4-34.6,21C87.3,99.3,84,113.2,84,128s3.3,28.7,9.4,39C101.3,180.6,113.6,188,128,188Zm0-96c15.8,0,20,23.5,20,36s-4.2,36-20,36-20-23.5-20-36S112.2,92,128,92Zm80-64H48A20.1,20.1,0,0,0,28,48V208a20.1,20.1,0,0,0,20,20H208a20.1,20.1,0,0,0,20-20V48A20.1,20.1,0,0,0,208,28Zm-4,176H52V52H204Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect x="40" y="40" width="176" height="176" rx="8" />
          </g>
          <path d="M128,72c-13,0-24,6.7-31.2,19-5.7,9.7-8.8,22.9-8.8,37s3.1,27.3,8.8,37c7.2,12.3,18.2,19,31.2,19s24-6.7,31.2-19c5.7-9.7,8.8-22.9,8.8-37s-3.1-27.3-8.8-37C152,78.7,141,72,128,72Zm0,96c-8.9,0-24-8.4-24-40s15.1-40,24-40,24,8.4,24,40S136.9,168,128,168ZM208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32Zm0,176H48V48H208V208Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M152,128c0,14.9-5.1,40-24,40s-24-25.1-24-40,5.1-40,24-40S152,113.1,152,128Zm72-80V208a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V48A16,16,0,0,1,48,32H208A16,16,0,0,1,224,48Zm-56,80c0-27.9-12.4-56-40-56s-40,28.1-40,56,12.4,56,40,56S168,155.9,168,128Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,74c-12.4,0-22.6,6.2-29.4,18C93,101.4,90,114.2,90,128s3,26.6,8.6,36c6.8,11.8,17,18,29.4,18s22.6-6.2,29.4-18c5.6-9.4,8.6-22.2,8.6-36s-3-26.6-8.6-36C150.6,80.2,140.4,74,128,74Zm0,96c-18,0-26-21.1-26-42,0-29,13.1-42,26-42,18,0,26,21.1,26,42C154,157,140.9,170,128,170ZM208,34H48A14,14,0,0,0,34,48V208a14,14,0,0,0,14,14H208a14,14,0,0,0,14-14V48A14,14,0,0,0,208,34Zm2,174a2,2,0,0,1-2,2H48a2,2,0,0,1-2-2V48a2,2,0,0,1,2-2H208a2,2,0,0,1,2,2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,76c-11.6,0-21.2,5.9-27.7,17-5.4,9.2-8.3,21.6-8.3,35s2.9,25.8,8.3,35c6.5,11.1,16.1,17,27.7,17s21.2-5.9,27.7-17c5.4-9.2,8.3-21.6,8.3-35s-2.9-25.8-8.3-35C149.2,81.9,139.6,76,128,76Zm0,96c-19.3,0-28-22.1-28-44s8.7-44,28-44,28,22.1,28,44S147.3,172,128,172ZM208,36H48A12,12,0,0,0,36,48V208a12,12,0,0,0,12,12H208a12,12,0,0,0,12-12V48A12,12,0,0,0,208,36Zm4,172a4,4,0,0,1-4,4H48a4,4,0,0,1-4-4V48a4,4,0,0,1,4-4H208a4,4,0,0,1,4,4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,72c-13,0-24,6.7-31.2,19-5.7,9.7-8.8,22.9-8.8,37s3.1,27.3,8.8,37c7.2,12.3,18.2,19,31.2,19s24-6.7,31.2-19c5.7-9.7,8.8-22.9,8.8-37s-3.1-27.3-8.8-37C152,78.7,141,72,128,72Zm0,96c-8.9,0-24-8.4-24-40s15.1-40,24-40,24,8.4,24,40S136.9,168,128,168ZM208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32Zm0,176H48V48H208V208Z" />
        </>
      )}
    </svg>
  );
}

export function PhNumberThree(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M188,168A68,68,0,0,1,71.9,216.1a12,12,0,0,1,17-17A44,44,0,1,0,120,124a12,12,0,0,1-9.8-18.9L152.9,44H80a12,12,0,0,1,0-24h96a12,12,0,0,1,9.8,18.9l-45,64.3A68.1,68.1,0,0,1,188,168Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M184,168A64,64,0,0,1,74.8,213.3a8,8,0,0,1,11.3-11.4A47.9,47.9,0,1,0,120,120a8.1,8.1,0,0,1-7.1-4.3,7.8,7.8,0,0,1,.6-8.3L160.6,40H80a8,8,0,0,1,0-16h96a8.1,8.1,0,0,1,7.1,4.3,7.8,7.8,0,0,1-.6,8.3l-48.2,69A64.1,64.1,0,0,1,184,168Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M184,168A64,64,0,0,1,74.8,213.3a8,8,0,0,1,11.3-11.4A47.9,47.9,0,1,0,120,120a8.1,8.1,0,0,1-7.1-4.3,7.8,7.8,0,0,1,.6-8.3L160.6,40H80a8,8,0,0,1,0-16h96a8.1,8.1,0,0,1,7.1,4.3,7.8,7.8,0,0,1-.6,8.3l-48.2,69A64.1,64.1,0,0,1,184,168Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M182,168a62.1,62.1,0,0,1-62,62,61.1,61.1,0,0,1-43.8-18.2,5.8,5.8,0,0,1,0-8.4,5.9,5.9,0,0,1,8.5,0A50,50,0,1,0,120,118a5.9,5.9,0,0,1-5.3-3.2,6,6,0,0,1,.4-6.2L164.5,38H80a6,6,0,0,1,0-12h96a5.9,5.9,0,0,1,5.3,3.2,6,6,0,0,1-.4,6.2l-50,71.6A62.1,62.1,0,0,1,182,168Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M180,168A60,60,0,0,1,77.6,210.4a4,4,0,0,1,5.6-5.6A52,52,0,1,0,120,116a3.8,3.8,0,0,1-3.5-2.1,4.2,4.2,0,0,1,.2-4.2L168.3,36H80a4,4,0,0,1,0-8h96a3.8,3.8,0,0,1,3.5,2.2,4,4,0,0,1-.2,4.1l-51.9,74.2A60,60,0,0,1,180,168Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M184,168A64,64,0,0,1,74.8,213.3a8,8,0,0,1,11.3-11.4A47.9,47.9,0,1,0,120,120a8.1,8.1,0,0,1-7.1-4.3,7.8,7.8,0,0,1,.6-8.3L160.6,40H80a8,8,0,0,1,0-16h96a8.1,8.1,0,0,1,7.1,4.3,7.8,7.8,0,0,1-.6,8.3l-48.2,69A64.1,64.1,0,0,1,184,168Z" />
        </>
      )}
    </svg>
  );
}

export function PhNumberTwo(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M188,224a12,12,0,0,1-12,12H80a12.6,12.6,0,0,1-3.8-.6,12,12,0,0,1-5.6-18.8L158,99.9A36,36,0,1,0,94.8,66a12,12,0,1,1-22.1-9.4,60,60,0,1,1,105,57l-.3.4L104,212h72A12,12,0,0,1,188,224Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M184,224a8,8,0,0,1-8,8H80a7.3,7.3,0,0,1-2.5-.4A8,8,0,0,1,72,224a8,8,0,0,1,1.7-5l87.6-116.8A40,40,0,1,0,91.1,64.4a8,8,0,1,1-14.7-6.2,56,56,0,1,1,98,53.1c0,.1-.1.2-.2.3L96,216h80A8,8,0,0,1,184,224Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M184,224a8,8,0,0,1-8,8H80a7.3,7.3,0,0,1-2.5-.4A8,8,0,0,1,72,224a8,8,0,0,1,1.7-5l87.6-116.8A40,40,0,1,0,91.1,64.4a8,8,0,1,1-14.7-6.2,56,56,0,1,1,98,53.1c0,.1-.1.2-.2.3L96,216h80A8,8,0,0,1,184,224Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M182,224a6,6,0,0,1-6,6H80a6.3,6.3,0,0,1-1.9-.3A6,6,0,0,1,74,224a6.5,6.5,0,0,1,1.3-3.8l87.6-116.8A42,42,0,1,0,89.3,63.7,6,6,0,0,1,78.2,59a54,54,0,1,1,94.6,51.2l-.2.2L92,218h84A6,6,0,0,1,182,224Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M180,224a4,4,0,0,1-4,4H80l-1.3-.2A4,4,0,0,1,76,224a3.7,3.7,0,0,1,.9-2.5l87.6-117A43.4,43.4,0,0,0,172,80,44,44,0,0,0,87.5,62.9,4.1,4.1,0,0,1,82.2,65a4,4,0,0,1-2.1-5.2,52,52,0,1,1,91,49.3H171L88,220h88A4,4,0,0,1,180,224Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M184,224a8,8,0,0,1-8,8H80a7.3,7.3,0,0,1-2.5-.4A8,8,0,0,1,72,224a8,8,0,0,1,1.7-5l87.6-116.8A40,40,0,1,0,91.1,64.4a8,8,0,1,1-14.7-6.2,56,56,0,1,1,98,53.1c0,.1-.1.2-.2.3L96,216h80A8,8,0,0,1,184,224Z" />
        </>
      )}
    </svg>
  );
}

export function PhNumberZero(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M192.8,50.3C177.6,25.2,155.2,12,128,12S78.4,25.2,63.2,50.3C50.8,70.7,44,98.3,44,128s6.8,57.3,19.2,77.7C78.4,230.8,100.8,244,128,244s49.6-13.2,64.8-38.3c12.4-20.4,19.2-48,19.2-77.7S205.2,70.7,192.8,50.3ZM128,220c-41.4,0-60-46.2-60-92s18.6-92,60-92,60,46.2,60,92S169.4,220,128,220Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M189.4,52.4C175,28.6,153.8,16,128,16S81,28.6,66.6,52.4C54.6,72.1,48,99,48,128s6.6,55.8,18.6,75.6C81,227.4,102.2,240,128,240s47-12.6,61.4-36.4C201.4,183.8,208,157,208,128S201.4,72.1,189.4,52.4ZM128,224c-44.2,0-64-48.2-64-96s19.8-96,64-96,64,48.2,64,96S172.2,224,128,224Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M189.4,52.4C175,28.6,153.8,16,128,16S81,28.6,66.6,52.4C54.6,72.1,48,99,48,128s6.6,55.8,18.6,75.6C81,227.4,102.2,240,128,240s47-12.6,61.4-36.4C201.4,183.8,208,157,208,128S201.4,72.1,189.4,52.4ZM128,224c-44.2,0-64-48.2-64-96s19.8-96,64-96,64,48.2,64,96S172.2,224,128,224Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M187.7,53.4C173.7,30.2,153,18,128,18S82.3,30.2,68.3,53.4C56.5,72.9,50,99.4,50,128s6.5,55.1,18.3,74.6C82.3,225.8,103,238,128,238s45.7-12.2,59.7-35.4c11.8-19.5,18.3-46,18.3-74.6S199.5,72.9,187.7,53.4ZM128,226c-45.6,0-66-49.2-66-98s20.4-98,66-98,66,49.2,66,98S173.6,226,128,226Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M186,54.4C172.3,31.9,152.3,20,128,20S83.7,31.9,70,54.4C58.4,73.6,52,99.7,52,128s6.4,54.4,18,73.6c13.7,22.5,33.7,34.4,58,34.4s44.3-11.9,58-34.4c11.6-19.2,18-45.3,18-73.6S197.6,73.6,186,54.4ZM128,228c-47,0-68-50.2-68-100S81,28,128,28s68,50.2,68,100S175,228,128,228Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M189.4,52.4C175,28.6,153.8,16,128,16S81,28.6,66.6,52.4C54.6,72.1,48,99,48,128s6.6,55.8,18.6,75.6C81,227.4,102.2,240,128,240s47-12.6,61.4-36.4C201.4,183.8,208,157,208,128S201.4,72.1,189.4,52.4ZM128,224c-44.2,0-64-48.2-64-96s19.8-96,64-96,64,48.2,64,96S172.2,224,128,224Z" />
        </>
      )}
    </svg>
  );
}

export function PhNut(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M221.8,63.3l-84-47.5a19.6,19.6,0,0,0-19.6,0l-84,47.5A19.9,19.9,0,0,0,24,80.7v94.6a19.9,19.9,0,0,0,10.2,17.4l84,47.5a19.8,19.8,0,0,0,19.6,0l84-47.5A19.9,19.9,0,0,0,232,175.3V80.7A19.9,19.9,0,0,0,221.8,63.3ZM208,173l-80,45.2L48,173V83l80-45.2L208,83ZM80,128a48,48,0,1,0,48-48A48,48,0,0,0,80,128Zm72,0a24,24,0,1,1-24-24A24.1,24.1,0,0,1,152,128Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M215.9,73.7l-84-47.5a7.8,7.8,0,0,0-7.8,0l-84,47.5a8.1,8.1,0,0,0-4.1,7v94.6a8.1,8.1,0,0,0,4.1,7l84,47.5a7.8,7.8,0,0,0,7.8,0l84-47.5a8.1,8.1,0,0,0,4.1-7V80.7A8.1,8.1,0,0,0,215.9,73.7ZM128,164a36,36,0,1,1,36-36A36,36,0,0,1,128,164Z" />
          </g>
          <path d="M219.9,66.7l-84-47.4a15.9,15.9,0,0,0-15.8,0l-84,47.4a16.2,16.2,0,0,0-8.1,14v94.6a16.2,16.2,0,0,0,8.1,14l84,47.4a15.9,15.9,0,0,0,15.8,0l84-47.4a16.2,16.2,0,0,0,8.1-14V80.7A16.2,16.2,0,0,0,219.9,66.7ZM212,175.3l-84,47.5L44,175.3V80.7l84-47.5,84,47.5Z" />
          <path d="M84,128a44,44,0,1,0,44-44A44,44,0,0,0,84,128Zm72,0a28,28,0,1,1-28-28A28.1,28.1,0,0,1,156,128Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M219.9,66.7l-84-47.4a15.9,15.9,0,0,0-15.8,0l-84,47.4a16.2,16.2,0,0,0-8.1,14v94.6a16.2,16.2,0,0,0,8.1,14l84,47.4a15.9,15.9,0,0,0,15.8,0l84-47.4a16.2,16.2,0,0,0,8.1-14V80.7A16.2,16.2,0,0,0,219.9,66.7ZM128,164a36,36,0,1,1,36-36A36,36,0,0,1,128,164Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M218.9,68.5,134.9,21a14.1,14.1,0,0,0-13.8,0l-84,47.5A14,14,0,0,0,30,80.7v94.6a14,14,0,0,0,7.1,12.2l84,47.5a14.1,14.1,0,0,0,13.8,0l84-47.5a14,14,0,0,0,7.1-12.2V80.7A14,14,0,0,0,218.9,68.5ZM214,175.3a2.1,2.1,0,0,1-1,1.8l-84,47.5a2.3,2.3,0,0,1-2,0L43,177.1a2.1,2.1,0,0,1-1-1.8V80.7a2.1,2.1,0,0,1,1-1.8l84-47.5,1-.2,1,.2,84,47.5a2.1,2.1,0,0,1,1,1.8ZM128,86a42,42,0,1,0,42,42A42,42,0,0,0,128,86Zm0,72a30,30,0,1,1,30-30A30.1,30.1,0,0,1,128,158Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M217.9,70.2l-84-47.5a12.4,12.4,0,0,0-11.8,0l-84,47.5A11.9,11.9,0,0,0,32,80.7v94.6a12,12,0,0,0,6.1,10.5l84,47.5a12.4,12.4,0,0,0,11.8,0l84-47.5a12,12,0,0,0,6.1-10.5V80.7A11.9,11.9,0,0,0,217.9,70.2ZM216,175.3a4,4,0,0,1-2,3.5l-84,47.5a4.1,4.1,0,0,1-4,0L42,178.8a4,4,0,0,1-2-3.5V80.7a4,4,0,0,1,2-3.5l84-47.5a4.3,4.3,0,0,1,4,0l84,47.5a4,4,0,0,1,2,3.5ZM128,88a40,40,0,1,0,40,40A40,40,0,0,0,128,88Zm0,72a32,32,0,1,1,32-32A32.1,32.1,0,0,1,128,160Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M219.9,66.7l-84-47.4a15.9,15.9,0,0,0-15.8,0l-84,47.4a16.2,16.2,0,0,0-8.1,14v94.6a16.2,16.2,0,0,0,8.1,14l84,47.4a15.9,15.9,0,0,0,15.8,0l84-47.4a16.2,16.2,0,0,0,8.1-14V80.7A16.2,16.2,0,0,0,219.9,66.7ZM212,175.3l-84,47.5L44,175.3V80.7l84-47.5,84,47.5ZM84,128a44,44,0,1,0,44-44A44,44,0,0,0,84,128Zm72,0a28,28,0,1,1-28-28A28.1,28.1,0,0,1,156,128Z" />
        </>
      )}
    </svg>
  );
}

export function PhNyTimesLogo(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M184.1,148a16,16,0,1,1-16-16A16,16,0,0,1,184.1,148Zm33.2,18.3A91.8,91.8,0,0,1,128,236a90.9,90.9,0,0,1-40.6-9.5l-1.4-.7a92.2,92.2,0,0,1-49.3-70.4h0A85.1,85.1,0,0,1,36,144,91.9,91.9,0,0,1,48.5,97.7,40,40,0,0,1,70.1,24a11.7,11.7,0,0,1,5.2,1.2L188.4,79.8A16,16,0,0,0,186.1,48a12,12,0,0,1,0-24,40,40,0,0,1,0,80,11.7,11.7,0,0,1-5.2-1.2L125.5,76.1a68,68,0,0,0-64.8,58.2l25.5-13.6a.1.1,0,0,0,.1-.1l36.1-19.2a11.8,11.8,0,0,1,11.8.3A12.1,12.1,0,0,1,140,112v98.9a67.7,67.7,0,0,0,54-50.4,12,12,0,1,1,23.3,5.8ZM116,132l-12,6.4v69.2a60.8,60.8,0,0,0,12,3.3ZM54.1,64a16.1,16.1,0,0,0,9.3,14.6,91.3,91.3,0,0,1,28-19L67.7,48.2A16,16,0,0,0,54.1,64ZM80,192.1V151.2l-17.9,9.6A68.6,68.6,0,0,0,80,192.1Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M128,112V224a79.4,79.4,0,0,1-32-6.7h0V129.1Z"
            opacity="0.2"
          />
          <path d="M184,148a12,12,0,1,1-12-12A12,12,0,0,1,184,148Zm29.4,17.3A88.1,88.1,0,0,1,93.3,224.9l-1.1-.5a88.4,88.4,0,0,1-51.5-69.3h0a80.2,80.2,0,0,1-.7-11A87.3,87.3,0,0,1,54.1,96.3,36,36,0,0,1,70.1,28a7.7,7.7,0,0,1,3.4.8L187.8,83.9A20,20,0,0,0,186.1,44a8,8,0,0,1,0-16,36,36,0,0,1,0,72,8.5,8.5,0,0,1-3.5-.8L126.3,72a72.1,72.1,0,0,0-70.2,69.3L92.2,122h0l32-17.1A8,8,0,0,1,136,112V215.5a71.8,71.8,0,0,0,61.9-54.1,8,8,0,0,1,15.5,3.9Zm-93.4-40-16,8.6v78a71.5,71.5,0,0,0,16,3.6ZM64.4,83.2a88.1,88.1,0,0,1,37.1-23.1l-33.1-16a20,20,0,0,0-4,39.1ZM88,203.8V142.4L57.5,158.7A72.1,72.1,0,0,0,88,203.8Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M184,148a12,12,0,1,1-12-12A12,12,0,0,1,184,148Zm29.4,17.3A88.1,88.1,0,0,1,93.3,224.9l-1.1-.5a88.4,88.4,0,0,1-51.5-69.3h0a80.2,80.2,0,0,1-.7-11A87.3,87.3,0,0,1,54.1,96.3,36,36,0,0,1,70.1,28a7.7,7.7,0,0,1,3.4.8L187.8,83.9A20,20,0,0,0,186.1,44a8,8,0,0,1,0-16,36,36,0,0,1,0,72,8.5,8.5,0,0,1-3.5-.8L126.3,72a72.1,72.1,0,0,0-70.2,69.3L92.2,122h0l32-17.1A8,8,0,0,1,136,112V215.5a71.8,71.8,0,0,0,61.9-54.1,8,8,0,0,1,15.5,3.9ZM64.4,83.2a88.1,88.1,0,0,1,37.1-23.1l-33.1-16a20,20,0,0,0-4,39.1ZM88,142.4,57.5,158.7A72.1,72.1,0,0,0,88,203.8Zm32-17.1-16,8.6v78a71.5,71.5,0,0,0,16,3.6Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M172.1,138a10,10,0,1,1-10,10A10,10,0,0,1,172.1,138Zm39.4,26.8A86.1,86.1,0,0,1,94.2,223.1l-1.3-.6a86.2,86.2,0,0,1-50.1-66.9,3.7,3.7,0,0,1-.1-1.1A72.2,72.2,0,0,1,42,144,85.8,85.8,0,0,1,57.1,95.4,33.7,33.7,0,0,1,36.1,64a34,34,0,0,1,34-34,5.8,5.8,0,0,1,2.6.6L187.4,86a22,22,0,0,0-1.3-44,6,6,0,0,1,0-12,34,34,0,0,1,0,68,5.8,5.8,0,0,1-2.6-.6L126.8,70A74.1,74.1,0,0,0,54,144v.7l39.1-20.9h.1l32-17.1a5.9,5.9,0,0,1,5.9.2A5.8,5.8,0,0,1,134,112V217.8a73.9,73.9,0,0,0,65.8-55.9,6,6,0,0,1,11.7,2.9ZM65.1,85.4a86.8,86.8,0,0,1,42.1-24.8L68.8,42A22,22,0,0,0,48.1,64,21.8,21.8,0,0,0,65.1,85.4ZM122,122l-20,10.7v80.6a73.6,73.6,0,0,0,20,4.4ZM90,207.5V139.1L55.3,157.6A74.2,74.2,0,0,0,90,207.5Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M172.1,140a8,8,0,1,1-8,8A8,8,0,0,1,172.1,140Zm37.4,24.3A83.8,83.8,0,0,1,94.8,221.1l-.8-.3a84.2,84.2,0,0,1-49.2-65.7,1.3,1.3,0,0,1-.1-.6A74.1,74.1,0,0,1,44,144,83.3,83.3,0,0,1,60.2,94.5,32,32,0,0,1,70.1,32a3.3,3.3,0,0,1,1.7.4L187,88a24,24,0,0,0-.9-48,4,4,0,0,1,0-8,32,32,0,0,1,0,64,3.9,3.9,0,0,1-1.8-.4L127.2,68A76.2,76.2,0,0,0,52,144c0,1.3,0,2.6.1,3.9l74-39.4a3.8,3.8,0,0,1,4,.1A3.9,3.9,0,0,1,132,112V219.9a75.8,75.8,0,0,0,69.8-57.5,4,4,0,1,1,7.7,1.9ZM65.8,87.6a83.7,83.7,0,0,1,47.5-26.3L69.2,40A24.1,24.1,0,0,0,46.1,64,23.8,23.8,0,0,0,65.8,87.6ZM124,118.7l-24,12.8v83.1a74.2,74.2,0,0,0,24,5.3ZM92,210.9V135.7L53,156.5A76.4,76.4,0,0,0,92,210.9Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M184,148a12,12,0,1,1-12-12A12,12,0,0,1,184,148Zm29.4,17.3A88.1,88.1,0,0,1,93.3,224.9l-1.1-.5a88.4,88.4,0,0,1-51.5-69.3h0a80.2,80.2,0,0,1-.7-11A87.3,87.3,0,0,1,54.1,96.3,36,36,0,0,1,70.1,28a7.7,7.7,0,0,1,3.4.8L187.8,83.9A20,20,0,0,0,186.1,44a8,8,0,0,1,0-16,36,36,0,0,1,0,72,8.5,8.5,0,0,1-3.5-.8L126.3,72a72.1,72.1,0,0,0-70.2,69.3L92.2,122h0l32-17.1A8,8,0,0,1,136,112V215.5a71.8,71.8,0,0,0,61.9-54.1,8,8,0,0,1,15.5,3.9Zm-93.4-40-16,8.6v78a71.5,71.5,0,0,0,16,3.6ZM64.4,83.2a88.1,88.1,0,0,1,37.1-23.1l-33.1-16a20,20,0,0,0-4,39.1ZM88,203.8V142.4L57.5,158.7A72.1,72.1,0,0,0,88,203.8Z" />
        </>
      )}
    </svg>
  );
}

export function PhOctagon(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M164.5,236h-73a19.8,19.8,0,0,1-14.1-5.9L25.9,178.6A19.8,19.8,0,0,1,20,164.5v-73a19.8,19.8,0,0,1,5.9-14.1L77.4,25.9A19.8,19.8,0,0,1,91.5,20h73a19.8,19.8,0,0,1,14.1,5.9l51.5,51.5A19.8,19.8,0,0,1,236,91.5v73a19.8,19.8,0,0,1-5.9,14.1l-51.5,51.5A19.8,19.8,0,0,1,164.5,236ZM93.2,212h69.6L212,162.8V93.2L162.8,44H93.2L44,93.2v69.6ZM164.5,44Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M164.5,32h-73a7.9,7.9,0,0,0-5.6,2.3L34.3,85.9A7.9,7.9,0,0,0,32,91.5v73a7.9,7.9,0,0,0,2.3,5.6l51.6,51.6a7.9,7.9,0,0,0,5.6,2.3h73a7.9,7.9,0,0,0,5.6-2.3l51.6-51.6a7.9,7.9,0,0,0,2.3-5.6v-73a7.9,7.9,0,0,0-2.3-5.6L170.1,34.3A7.9,7.9,0,0,0,164.5,32Z" />
          </g>
          <path d="M164.5,232h-73a16.2,16.2,0,0,1-11.3-4.7L28.7,175.8A16.2,16.2,0,0,1,24,164.5v-73a16.2,16.2,0,0,1,4.7-11.3L80.2,28.7A16.2,16.2,0,0,1,91.5,24h73a16.2,16.2,0,0,1,11.3,4.7l51.5,51.5A16.2,16.2,0,0,1,232,91.5v73a16.2,16.2,0,0,1-4.7,11.3l-51.5,51.5A16.2,16.2,0,0,1,164.5,232ZM91.5,40,40,91.5v73L91.5,216h73L216,164.5v-73L164.5,40Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M232,91.5v73a16.1,16.1,0,0,1-4.7,11.3l-51.5,51.5a16.1,16.1,0,0,1-11.3,4.7h-73a16.1,16.1,0,0,1-11.3-4.7L28.7,175.8A16.1,16.1,0,0,1,24,164.5v-73a16.1,16.1,0,0,1,4.7-11.3L80.2,28.7A16.1,16.1,0,0,1,91.5,24h73a16.1,16.1,0,0,1,11.3,4.7l51.5,51.5A16.1,16.1,0,0,1,232,91.5Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M164.5,230h-73a14.3,14.3,0,0,1-9.9-4.1L30.1,174.4a14.3,14.3,0,0,1-4.1-9.9v-73a14.3,14.3,0,0,1,4.1-9.9L81.6,30.1A14.3,14.3,0,0,1,91.5,26h73a14.3,14.3,0,0,1,9.9,4.1l51.5,51.5a14.3,14.3,0,0,1,4.1,9.9v73a14.3,14.3,0,0,1-4.1,9.9l-51.5,51.5A14.3,14.3,0,0,1,164.5,230ZM91.5,38a2,2,0,0,0-1.4.6L38.6,90.1a2,2,0,0,0-.6,1.4v73a2,2,0,0,0,.6,1.4l51.5,51.5a2,2,0,0,0,1.4.6h73a2,2,0,0,0,1.4-.6l51.5-51.5a2,2,0,0,0,.6-1.4v-73a2,2,0,0,0-.6-1.4L165.9,38.6a2,2,0,0,0-1.4-.6Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M164.5,228h-73a11.5,11.5,0,0,1-8.4-3.5L31.5,172.9a11.5,11.5,0,0,1-3.5-8.4v-73a11.5,11.5,0,0,1,3.5-8.4L83.1,31.5A11.5,11.5,0,0,1,91.5,28h73a11.5,11.5,0,0,1,8.4,3.5l51.6,51.6a11.5,11.5,0,0,1,3.5,8.4v73a11.5,11.5,0,0,1-3.5,8.4l-51.6,51.6A11.5,11.5,0,0,1,164.5,228ZM91.5,36a3.9,3.9,0,0,0-2.8,1.2L37.2,88.7A3.9,3.9,0,0,0,36,91.5v73a3.9,3.9,0,0,0,1.2,2.8l51.5,51.5a3.9,3.9,0,0,0,2.8,1.2h73a3.9,3.9,0,0,0,2.8-1.2l51.5-51.5a3.9,3.9,0,0,0,1.2-2.8v-73a3.9,3.9,0,0,0-1.2-2.8L167.3,37.2a3.9,3.9,0,0,0-2.8-1.2Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M164.5,232h-73a16.2,16.2,0,0,1-11.3-4.7L28.7,175.8A16.2,16.2,0,0,1,24,164.5v-73a16.2,16.2,0,0,1,4.7-11.3L80.2,28.7A16.2,16.2,0,0,1,91.5,24h73a16.2,16.2,0,0,1,11.3,4.7l51.5,51.5A16.2,16.2,0,0,1,232,91.5v73a16.2,16.2,0,0,1-4.7,11.3l-51.5,51.5A16.2,16.2,0,0,1,164.5,232ZM91.5,40,40,91.5v73L91.5,216h73L216,164.5v-73L164.5,40Z" />
        </>
      )}
    </svg>
  );
}

export function PhOption(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M236,184a12,12,0,0,1-12,12H161a19.7,19.7,0,0,1-17.9-11.1L92.6,84H32a12,12,0,0,1,0-24H95.1a19.7,19.7,0,0,1,17.8,11.1L163.4,172H224A12,12,0,0,1,236,184ZM156,84h68a12,12,0,0,0,0-24H156a12,12,0,0,0,0,24Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M232,184a8,8,0,0,1-8,8H161a16.2,16.2,0,0,1-14.4-8.8L95.1,80H32a8,8,0,0,1,0-16H95.1a16,16,0,0,1,14.3,8.8L161,176h63A8,8,0,0,1,232,184ZM152,80h72a8,8,0,0,0,0-16H152a8,8,0,0,0,0,16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M144,72a8,8,0,0,1,8-8h72a8,8,0,0,1,0,16H152A8,8,0,0,1,144,72Zm80,104H161L109.4,72.8A16,16,0,0,0,95.1,64H32a8,8,0,0,0,0,16H95.1l51.5,103.2A16.2,16.2,0,0,0,161,192h63a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M230,184a6,6,0,0,1-6,6H161a14.1,14.1,0,0,1-12.6-7.7L96.9,79.1A2.1,2.1,0,0,0,95.1,78H32a6,6,0,0,1,0-12H95.1a14,14,0,0,1,12.5,7.7l51.6,103.2A1.9,1.9,0,0,0,161,178h63A6,6,0,0,1,230,184ZM152,78h72a6,6,0,0,0,0-12H152a6,6,0,0,0,0,12Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M228,184a4,4,0,0,1-4,4H161a12,12,0,0,1-10.8-6.6L98.6,78.2A3.7,3.7,0,0,0,95.1,76H32a4,4,0,0,1,0-8H95.1a12,12,0,0,1,10.7,6.6l51.6,103.2A3.9,3.9,0,0,0,161,180h63A4,4,0,0,1,228,184ZM152,76h72a4,4,0,0,0,0-8H152a4,4,0,0,0,0,8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M232,184a8,8,0,0,1-8,8H161a16.2,16.2,0,0,1-14.4-8.8L95.1,80H32a8,8,0,0,1,0-16H95.1a16,16,0,0,1,14.3,8.8L161,176h63A8,8,0,0,1,232,184ZM152,80h72a8,8,0,0,0,0-16H152a8,8,0,0,0,0,16Z" />
        </>
      )}
    </svg>
  );
}

export function PhPackage(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M233.5,68.9c0-.1,0-.1-.1-.2h0a20.5,20.5,0,0,0-7.5-7.4l-88-49.5a20.1,20.1,0,0,0-19.6,0l-88,49.5a20,20,0,0,0-7.5,7.5h-.1a.3.3,0,0,1-.1.2,20,20,0,0,0-2.5,9.7v98.6a20.2,20.2,0,0,0,10.2,17.5l88,49.5a20.6,20.6,0,0,0,9,2.5h1.8a21.2,21.2,0,0,0,8.8-2.5l88-49.5a20.2,20.2,0,0,0,10.2-17.5V78.7A19.4,19.4,0,0,0,233.5,68.9ZM128.1,33.8l71.6,40.3L177.2,86.8,104.8,46.9Zm.9,80.4L56.7,73.9,80.2,60.7l72.5,40.1ZM44.1,94.4,117,135l-.7,80.6L44.1,175Zm96.2,121L141,135l24.1-13.7v31.2a12,12,0,0,0,24,0V107.7l23-13.1V175Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M33.2,74.6a8.1,8.1,0,0,0-1.1,4.1v98.6a8.1,8.1,0,0,0,4.1,7l88,49.5a7.8,7.8,0,0,0,3.9,1h0l1-106.8L33.2,74.6Z" />
          </g>
          <path d="M230,70.8h-.1a16.7,16.7,0,0,0-5.9-5.9L136,15.2a16.2,16.2,0,0,0-15.7,0l-88,49.5a16.2,16.2,0,0,0-6,5.9.1.1,0,0,1-.1.1v.2a15,15,0,0,0-2.1,7.8v98.6a16.1,16.1,0,0,0,8.2,14l88,49.5a16.5,16.5,0,0,0,7.2,2h1.4a16.5,16.5,0,0,0,7.1-2l88-49.5a16.2,16.2,0,0,0,8.1-14V78.7A15.6,15.6,0,0,0,230,70.8ZM128.1,29.2,207.9,74,177.2,91.4,96.6,46.9Zm.9,89.6L48.5,74,80.2,56.2l80.7,44.5ZM40.1,87.6,121,132.7l-.8,89.7L40.1,177.3Zm96.1,134.7.8-89.6,32.1-18.3v38.1a8,8,0,0,0,16,0V105.3l31-17.6v89.6Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M230,70.8h-.1a16.7,16.7,0,0,0-5.9-5.9L136,15.2a16.2,16.2,0,0,0-15.7,0l-88,49.5a16.2,16.2,0,0,0-6,5.9.1.1,0,0,1-.1.1v.2a15,15,0,0,0-2.1,7.8v98.6a16.1,16.1,0,0,0,8.2,14l88,49.5a16.5,16.5,0,0,0,7.2,2h1.4a16.5,16.5,0,0,0,7.1-2l88-49.5a16.2,16.2,0,0,0,8.1-14V78.7A15.6,15.6,0,0,0,230,70.8ZM128.1,29.2,207.9,74,177.2,91.4,96.6,46.9Zm.9,89.6L48.5,74,80.2,56.2l80.7,44.5Zm7.2,103.5.8-89.6,32.1-18.3v38.1a8,8,0,0,0,16,0V105.3l31-17.6v89.6Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M228.3,71.8h-.1a13.4,13.4,0,0,0-5.2-5.1L135,17a14.1,14.1,0,0,0-13.8,0l-88,49.5a13.8,13.8,0,0,0-4.9,4.7h0l-.3.4h-.1a14.8,14.8,0,0,0-1.8,6.9v98.6a14,14,0,0,0,7.1,12.2l88,49.5a14.8,14.8,0,0,0,6.1,1.8H129a14.8,14.8,0,0,0,6-1.8l88-49.5a14,14,0,0,0,7.1-12.2V78.7A13.4,13.4,0,0,0,228.3,71.8ZM127.1,27.4l1-.2,1,.2L211.9,74,177.2,93.7,92.4,46.9Zm1.9,93.7L44.4,74,80.1,53.9,165,100.7Zm-89.9,58a2.2,2.2,0,0,1-1-1.8V84.2L123,131.5l-.8,94.3Zm178,0-82.9,46.6.8-94.2L171.1,111v41.5a6,6,0,0,0,12,0V104.2l35-19.9v93A2.2,2.2,0,0,1,217.1,179.1Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M226.5,72.7h0a12.7,12.7,0,0,0-4.5-4.4L134,18.7a12.4,12.4,0,0,0-11.8,0l-88,49.5a12.7,12.7,0,0,0-4.5,4.4h0a12.5,12.5,0,0,0-1.6,6v98.6a12.2,12.2,0,0,0,6.1,10.5l88,49.5a11.5,11.5,0,0,0,5.6,1.5h.6a13.7,13.7,0,0,0,5.6-1.5l88-49.5a11.9,11.9,0,0,0,6.1-10.5V78.7A12.5,12.5,0,0,0,226.5,72.7Zm-100.3-47a4.1,4.1,0,0,1,1.9-.5,4.6,4.6,0,0,1,2,.5L216,74,177.3,96l-89-49Zm2.8,97.7L40.3,74,80.1,51.6l89,49.1ZM38.2,180.8a3.9,3.9,0,0,1-2.1-3.5V80.8L125,130.3l-.8,98.9Zm179.9,0-85.9,48.3.8-98.8,40.1-22.7v44.9a4,4,0,0,0,8,0V103l39-22.1v96.4A4,4,0,0,1,218.1,180.8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M230,70.8h-.1a16.7,16.7,0,0,0-5.9-5.9L136,15.2a16.2,16.2,0,0,0-15.7,0l-88,49.5a16.2,16.2,0,0,0-6,5.9.1.1,0,0,1-.1.1v.2a15,15,0,0,0-2.1,7.8v98.6a16.1,16.1,0,0,0,8.2,14l88,49.5a16.5,16.5,0,0,0,7.2,2h1.4a16.5,16.5,0,0,0,7.1-2l88-49.5a16.2,16.2,0,0,0,8.1-14V78.7A15.6,15.6,0,0,0,230,70.8ZM128.1,29.2,207.9,74,177.2,91.4,96.6,46.9Zm.9,89.6L48.5,74,80.2,56.2l80.7,44.5ZM40.1,87.6,121,132.7l-.8,89.7L40.1,177.3Zm96.1,134.7.8-89.6,32.1-18.3v38.1a8,8,0,0,0,16,0V105.3l31-17.6v89.6Z" />
        </>
      )}
    </svg>
  );
}

export function PhPaintBrushBroad(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216,20H72A44,44,0,0,0,28,64v72a28.1,28.1,0,0,0,28,28H95.1L92,207.1v.9a36,36,0,0,0,72,0v-.9L160.9,164H200a28.1,28.1,0,0,0,28-28V32A12,12,0,0,0,216,20ZM72,44h92V72a12,12,0,0,0,24,0V44h16v56H52V64A20.1,20.1,0,0,1,72,44Zm128,96H156.6a20.2,20.2,0,0,0-20,21.4l3.4,47a12,12,0,0,1-24,0l3.4-47a20.2,20.2,0,0,0-20-21.4H56a4,4,0,0,1-4-4V124H204v12A4,4,0,0,1,200,140Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M40,112H216v24a16,16,0,0,1-16,16H153.2a8,8,0,0,0-7.9,9.1L152,208a24,24,0,0,1-48,0l6.7-46.9a8,8,0,0,0-7.9-9.1H56a16,16,0,0,1-16-16Z" />
          </g>
          <path d="M216,24H72A40,40,0,0,0,32,64v72a24.1,24.1,0,0,0,24,24h46.8l-6.7,46.9A3.7,3.7,0,0,0,96,208a32,32,0,0,0,64,0,3.7,3.7,0,0,0-.1-1.1L153.2,160H200a24.1,24.1,0,0,0,24-24V32A8,8,0,0,0,216,24ZM72,40H176V80a8,8,0,0,0,16,0V40h16v64H48V64A24.1,24.1,0,0,1,72,40ZM200,144H153.2a15.9,15.9,0,0,0-15.8,18.3l6.6,46.2a16,16,0,0,1-32,0l6.6-46.2A15.9,15.9,0,0,0,102.8,144H56a8,8,0,0,1-8-8V120H208v16A8,8,0,0,1,200,144Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,24H72A40,40,0,0,0,32,64v72a24.1,24.1,0,0,0,24,24h46.8l-6.7,46.9A3.7,3.7,0,0,0,96,208a32,32,0,0,0,64,0,3.7,3.7,0,0,0-.1-1.1L153.2,160H200a24.1,24.1,0,0,0,24-24V32A8,8,0,0,0,216,24Zm-8,80H48V64A24.1,24.1,0,0,1,72,40H176V80a8,8,0,0,0,16,0V40h16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M216,26H72A38,38,0,0,0,34,64v72a22.1,22.1,0,0,0,22,22h46.8a1.8,1.8,0,0,1,1.5.7,2,2,0,0,1,.5,1.6l-6.7,46.9a1.9,1.9,0,0,0-.1.8,30,30,0,0,0,60,0,1.9,1.9,0,0,0-.1-.8l-6.7-46.9a2,2,0,0,1,.5-1.6,1.8,1.8,0,0,1,1.5-.7H200a22.1,22.1,0,0,0,22-22V32A6,6,0,0,0,216,26ZM72,38H178V80a6,6,0,0,0,12,0V38h20v68H46V64A26.1,26.1,0,0,1,72,38ZM200,146H153.2a14.4,14.4,0,0,0-10.6,4.8,14.2,14.2,0,0,0-3.2,11.2l6.6,46.4a18,18,0,0,1-36,0l6.6-46.4a14.2,14.2,0,0,0-3.2-11.2,14.4,14.4,0,0,0-10.6-4.8H56a10,10,0,0,1-10-10V118H210v18A10,10,0,0,1,200,146Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M216,28H72A36,36,0,0,0,36,64v72a20.1,20.1,0,0,0,20,20h46.8a3.9,3.9,0,0,1,3,1.4,3.7,3.7,0,0,1,.9,3.2L100,207.4v.6a28,28,0,0,0,56,0v-.6l-6.7-46.8a3.7,3.7,0,0,1,.9-3.2,3.9,3.9,0,0,1,3-1.4H200a20.1,20.1,0,0,0,20-20V32A4,4,0,0,0,216,28ZM72,36H180V80a4,4,0,0,0,8,0V36h24v72H44V64A28.1,28.1,0,0,1,72,36ZM200,148H153.2a11.8,11.8,0,0,0-9,4.1,12.5,12.5,0,0,0-2.9,9.6l6.7,46.6a20,20,0,0,1-40,0l6.7-46.6a12.5,12.5,0,0,0-2.9-9.6,11.8,11.8,0,0,0-9-4.1H56a12,12,0,0,1-12-12V116H212v20A12,12,0,0,1,200,148Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,24H72A40,40,0,0,0,32,64v72a24.1,24.1,0,0,0,24,24h46.8l-6.7,46.9A3.7,3.7,0,0,0,96,208a32,32,0,0,0,64,0,3.7,3.7,0,0,0-.1-1.1L153.2,160H200a24.1,24.1,0,0,0,24-24V32A8,8,0,0,0,216,24ZM72,40H176V80a8,8,0,0,0,16,0V40h16v64H48V64A24.1,24.1,0,0,1,72,40ZM200,144H153.2a15.9,15.9,0,0,0-15.8,18.3l6.6,46.2a16,16,0,0,1-32,0l6.6-46.2A15.9,15.9,0,0,0,102.8,144H56a8,8,0,0,1-8-8V120H208v16A8,8,0,0,1,200,144Z" />
        </>
      )}
    </svg>
  );
}

export function PhPaintBrushHousehold(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M233.5,22.5a36.2,36.2,0,0,0-51,0l-.4.5L130,81l-6.2-6.2a28.1,28.1,0,0,0-39.6,0L7.5,151.5a12,12,0,0,0,0,17l80,80a12.1,12.1,0,0,0,17,0l76.7-76.7a28.1,28.1,0,0,0,0-39.6L175,126l58-52.1.5-.4A36.2,36.2,0,0,0,233.5,22.5ZM96,223l-8.6-8.6,17.3-18.1a12,12,0,1,0-17.4-16.6L70.4,197.4l-11-11,17.3-18.1a12,12,0,1,0-17.4-16.6L42.4,169.4,33,160l39-39,63,63ZM216.7,56.3l-60.9,54.6A20,20,0,0,0,155,140l9.2,9.2a3.9,3.9,0,0,1,0,5.6L152,167,89,104l12.2-12.2a3.9,3.9,0,0,1,5.6,0L116,101a20,20,0,0,0,29.1-.8l54.6-60.9a12,12,0,0,1,17,17Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M163.5,131.5l9.2,9.2a16.1,16.1,0,0,1,0,22.6L152,184,72,104,92.7,83.3a16.1,16.1,0,0,1,22.6,0l9.2,9.2a8,8,0,0,0,11.6-.3L191,31a24,24,0,0,1,34,34l-61.2,54.9A8,8,0,0,0,163.5,131.5Z"
            opacity="0.2"
          />
          <path d="M230.6,25.4a31.9,31.9,0,0,0-45.2,0l-.3.3L130.2,86.9,121,77.7a23.9,23.9,0,0,0-34,0L10.3,154.3a8.1,8.1,0,0,0,0,11.4l80,80a8.2,8.2,0,0,0,11.4,0L178.3,169a23.9,23.9,0,0,0,0-34l-9.2-9.2,61.2-54.9.3-.3A31.9,31.9,0,0,0,230.6,25.4ZM96,228.7,81.9,214.6,107.4,189a8,8,0,0,0-11.3-11.3L70.6,203.3,53.9,186.6,79.4,161a8,8,0,0,0-11.3-11.3L42.6,175.3,27.3,160,72,115.3,140.7,184ZM219.5,59.2l-61,54.7a16.3,16.3,0,0,0-5.4,11.5,16,16,0,0,0,4.7,11.7l9.2,9.2a8,8,0,0,1,0,11.4l-15,15L83.3,104l15-15a8,8,0,0,1,11.4,0l9.2,9.2a16,16,0,0,0,11.7,4.7,16.3,16.3,0,0,0,11.5-5.4h0l54.7-61a16.1,16.1,0,0,1,22.7,22.7Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M230.6,25.4a31.9,31.9,0,0,0-45.2,0l-.3.3L130.2,86.9,121,77.7a23.9,23.9,0,0,0-34,0L10.3,154.3a8.1,8.1,0,0,0,0,11.4l80,80a8.2,8.2,0,0,0,11.4,0L178.3,169a23.9,23.9,0,0,0,0-34l-9.2-9.2,61.2-54.9.3-.3A31.9,31.9,0,0,0,230.6,25.4ZM96,228.7,81.9,214.6,107.4,189a8,8,0,0,0-11.3-11.3L70.6,203.3,53.9,186.6,79.4,161a8,8,0,0,0-11.3-11.3L42.6,175.3,27.3,160,72,115.3,140.7,184Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M229.2,26.8a30,30,0,0,0-42.4,0c-.1.1-.2.1-.2.2L131.7,88.2a2,2,0,0,1-1.5.7,2,2,0,0,1-1.4-.6l-9.2-9.2a22,22,0,0,0-31.2,0L11.8,155.8a5.8,5.8,0,0,0,0,8.4l26.5,26.6h0l28,28h0l25.5,25.4a5.8,5.8,0,0,0,8.4,0l76.7-76.6a22,22,0,0,0,0-31.2l-9.2-9.2a2,2,0,0,1-.6-1.4,2,2,0,0,1,.7-1.5L229,69.4l.2-.2A30,30,0,0,0,229.2,26.8ZM96,231.5,79,214.6l27-27a6,6,0,0,0-8.5-8.5l-26.9,27L51,186.6l27-27a6,6,0,0,0-8.5-8.5l-26.9,27L24.5,160,72,112.5,143.5,184ZM220.8,60.6l-61,54.8a13.9,13.9,0,0,0-.6,20.3l9.2,9.2a10.1,10.1,0,0,1,0,14.2L152,175.5,80.5,104,96.9,87.6a10.1,10.1,0,0,1,14.2,0l9.2,9.2a14.4,14.4,0,0,0,10.3,4.1,13.9,13.9,0,0,0,10-4.7h0l54.8-61a18,18,0,0,1,25.4,25.4Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M227.8,28.2a28.1,28.1,0,0,0-39.6,0c0,.1-.1.1-.1.2L133.2,89.5a4.2,4.2,0,0,1-2.9,1.4,4.1,4.1,0,0,1-3-1.2l-9.2-9.2a19.9,19.9,0,0,0-28.2,0L13.2,157.2a3.9,3.9,0,0,0,0,5.6l80,80a3.9,3.9,0,0,0,5.6,0l76.7-76.7a19.9,19.9,0,0,0,0-28.2l-9.2-9.2a4,4,0,0,1,.2-5.9l61.1-54.9h.2A28.1,28.1,0,0,0,227.8,28.2ZM96,234.3,76.2,214.6l28.4-28.4a4,4,0,0,0-5.7-5.7L70.6,208.9,48.2,186.6l28.4-28.4a4,4,0,0,0-5.7-5.7L42.6,180.9,21.7,160,72,109.7,146.3,184ZM222.2,62.1l-61.1,54.8a12.2,12.2,0,0,0-4,8.6,12.1,12.1,0,0,0,3.6,8.8l9.2,9.2a12.1,12.1,0,0,1,0,17L152,178.3,77.7,104,95.5,86.1a12.1,12.1,0,0,1,17,0l9.2,9.2a11.7,11.7,0,0,0,8.8,3.6,12.2,12.2,0,0,0,8.6-4l54.8-61.1a20,20,0,0,1,28.3,28.3Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M230.6,25.4a31.9,31.9,0,0,0-45.2,0l-.3.3L130.2,86.9,121,77.7a23.9,23.9,0,0,0-34,0L10.3,154.3a8.1,8.1,0,0,0,0,11.4l80,80a8.2,8.2,0,0,0,11.4,0L178.3,169a23.9,23.9,0,0,0,0-34l-9.2-9.2,61.2-54.9.3-.3A31.9,31.9,0,0,0,230.6,25.4ZM96,228.7,81.9,214.6,107.4,189a8,8,0,0,0-11.3-11.3L70.6,203.3,53.9,186.6,79.4,161a8,8,0,0,0-11.3-11.3L42.6,175.3,27.3,160,72,115.3,140.7,184ZM219.5,59.2l-61,54.7a16.3,16.3,0,0,0-5.4,11.5,16,16,0,0,0,4.7,11.7l9.2,9.2a8,8,0,0,1,0,11.4l-15,15L83.3,104l15-15a8,8,0,0,1,11.4,0l9.2,9.2a16,16,0,0,0,11.7,4.7,16.3,16.3,0,0,0,11.5-5.4h0l54.7-61a16.1,16.1,0,0,1,22.7,22.7Z" />
        </>
      )}
    </svg>
  );
}

export function PhPaintBrush(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M225,19c-22.3,0-47.2,12.1-73.8,36-17.4,15.5-32.4,33.1-43.1,47A66.4,66.4,0,0,0,92,100a64.1,64.1,0,0,0-64,64c0,28.6-14.3,41.6-15.5,42.7a11.8,11.8,0,0,0-4,13.2A12,12,0,0,0,20,228H92a64.1,64.1,0,0,0,64-64,66.4,66.4,0,0,0-2-16.1c13.9-10.7,31.5-25.7,47-43.1,23.9-26.6,36-51.5,36-73.8A12,12,0,0,0,225,19ZM92,204H43.3A91.3,91.3,0,0,0,52,164a40,40,0,1,1,40,40Zm38.2-91.3c3-3.8,6.5-8,10.2-12.4a78.2,78.2,0,0,1,15.2,15.2c-4.4,3.9-8.6,7.3-12.3,10.3A62.6,62.6,0,0,0,130.2,112.7Zm43.1-13.5a103,103,0,0,0-16.5-16.5c3.4-3.4,6.8-6.7,10.4-9.9,15.7-14,30.4-23.3,43.4-27.4C204.9,63.9,189.4,83.1,173.3,99.2Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M225,31c0,32.9-32.3,68.1-59.6,92A85.4,85.4,0,0,0,133,90.6C156.9,63.3,192.1,31,225,31Z"
            opacity="0.2"
          />
          <path d="M225,23c-21.3,0-45.3,11.8-71.1,34.9-18.1,16.2-33.6,34.7-44.3,48.7A60.1,60.1,0,0,0,32,164c0,31.2-16.2,45.1-17,45.8a7.7,7.7,0,0,0-2.5,8.8A7.8,7.8,0,0,0,20,224H92a60.1,60.1,0,0,0,57.4-77.6c14-10.7,32.5-26.2,48.7-44.3C221.2,76.3,233,52.3,233,31A8,8,0,0,0,225,23ZM92,208H36.6c5.7-9.5,11.4-24,11.4-44a44,44,0,1,1,44,44Zm32.4-94.4c2.9-3.7,6.3-7.9,10.2-12.5a75.4,75.4,0,0,1,20.3,20.3c-4.6,3.9-8.8,7.3-12.5,10.2A59.4,59.4,0,0,0,124.4,113.6Zm42.6-2.9A93.1,93.1,0,0,0,145.3,89c19.6-21.2,46-44.4,70.8-49.1C211.4,64.7,188.2,91.1,167,110.7Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M225,23c-21.3,0-45.3,11.8-71.1,34.9-18.1,16.2-33.6,34.7-44.3,48.7A60.1,60.1,0,0,0,32,164c0,31.2-16.2,45.1-17,45.8a7.7,7.7,0,0,0-2.5,8.8A7.8,7.8,0,0,0,20,224H92a60.1,60.1,0,0,0,57.4-77.6c14-10.7,32.5-26.2,48.7-44.3C221.2,76.3,233,52.3,233,31A8,8,0,0,0,225,23ZM124.4,113.6c2.9-3.7,6.3-7.9,10.2-12.5a75.4,75.4,0,0,1,20.3,20.3c-4.6,3.9-8.8,7.3-12.5,10.2A59.4,59.4,0,0,0,124.4,113.6Zm42.6-2.9A93.1,93.1,0,0,0,145.3,89c19.6-21.2,46-44.4,70.8-49.1C211.4,64.7,188.2,91.1,167,110.7Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M225,25c-20.8,0-44.3,11.6-69.8,34.4C136.8,75.9,121,94.9,110.3,109A57.9,57.9,0,0,0,34,164c0,32.3-17,46.7-17.8,47.3a6.3,6.3,0,0,0-1.9,6.7,6.1,6.1,0,0,0,5.7,4H92a57.9,57.9,0,0,0,55-76.3c14.1-10.7,33.1-26.5,49.6-44.9C219.4,75.3,231,51.8,231,31A6,6,0,0,0,225,25ZM92,210H33c6.1-9.1,13-24.2,13-46a46,46,0,1,1,46,46Zm29.5-95.9c3.7-4.8,8-10.2,12.7-15.7a76.8,76.8,0,0,1,23.4,23.4c-5.5,4.7-10.9,9-15.7,12.7A58.6,58.6,0,0,0,121.5,114.1Zm66.1-21.3a276.3,276.3,0,0,1-20.9,21,89.3,89.3,0,0,0-24.5-24.5,276.3,276.3,0,0,1,21-20.9c20.5-18.4,39-28.7,55.3-30.9C216.3,53.8,206,72.3,187.6,92.8Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M229,31a4,4,0,0,0-4-4c-34.3,0-70.5,33.1-94.9,60.9l-.3.3c-7.5,8.6-13.9,16.7-18.8,23.1A56.1,56.1,0,0,0,36,164c0,33.4-17.7,48.3-18.5,48.9A4,4,0,0,0,20,220H92a56.1,56.1,0,0,0,52.7-75c6.4-4.9,14.5-11.3,23.1-18.8l.3-.3C195.9,101.5,229,65.3,229,31ZM92,212H29.1c6.4-8.2,14.9-23.9,14.9-48a48,48,0,1,1,48,48Zm26.5-97.3c4.3-5.7,9.5-12.2,15.3-18.9a79.3,79.3,0,0,1,26.4,26.4c-6.7,5.8-13.2,11-18.9,15.3A55.6,55.6,0,0,0,118.5,114.7Zm47.8,2.2a86.2,86.2,0,0,0-27.2-27.2,288.5,288.5,0,0,1,22.8-22.8c21.8-19.6,41.6-30.2,58.9-31.7-1.5,17.3-12.1,37.1-31.7,58.9A288.5,288.5,0,0,1,166.3,116.9Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M225,23c-21.3,0-45.3,11.8-71.1,34.9-18.1,16.2-33.6,34.7-44.3,48.7A60.1,60.1,0,0,0,32,164c0,31.2-16.2,45.1-17,45.8a7.7,7.7,0,0,0-2.5,8.8A7.8,7.8,0,0,0,20,224H92a60.1,60.1,0,0,0,57.4-77.6c14-10.7,32.5-26.2,48.7-44.3C221.2,76.3,233,52.3,233,31A8,8,0,0,0,225,23ZM92,208H36.6c5.7-9.5,11.4-24,11.4-44a44,44,0,1,1,44,44Zm32.4-94.4c2.9-3.7,6.3-7.9,10.2-12.5a75.4,75.4,0,0,1,20.3,20.3c-4.6,3.9-8.8,7.3-12.5,10.2A59.4,59.4,0,0,0,124.4,113.6Zm42.6-2.9A93.1,93.1,0,0,0,145.3,89c19.6-21.2,46-44.4,70.8-49.1C211.4,64.7,188.2,91.1,167,110.7Z" />
        </>
      )}
    </svg>
  );
}

export function PhPaintBucket(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M235,123.5a12.1,12.1,0,0,0-3.5-8.5L124,7.5a12,12,0,0,0-17,0L69.7,44.8,46.3,21.4A12,12,0,0,0,29.4,38.3L52.8,61.7l-42,42a28.1,28.1,0,0,0,0,39.6l84.9,84.8a28,28,0,0,0,39.6,0L231.5,132A12.3,12.3,0,0,0,235,123.5ZM118.3,211.2a4.1,4.1,0,0,1-5.6,0L27.8,126.3a4,4,0,0,1,0-5.7L69.7,78.7l21.8,21.8a36,36,0,0,0,58,41h0a36,36,0,0,0-41-57.9L86.7,61.7,115.5,33,206,123.5ZM124,104a12.2,12.2,0,0,1,8.5,3.5,12,12,0,1,1-17,0A12.2,12.2,0,0,1,124,104Zm108.5,63.5a12.1,12.1,0,0,0-17,0c-2.4,2.4-23.5,24.2-23.5,48.5a32,32,0,0,0,64,0C256,191.7,234.9,169.9,232.5,167.5ZM224,224a8,8,0,0,1-8-8c0-6.9,3.8-14.7,8-20.9,4.1,6.2,8,14,8,20.9A8,8,0,0,1,224,224Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="124" cy="116" r="20" />
          </g>
          <g opacity="0.2">
            <path d="M208,204c0-20,20-40,20-40s20,20,20,40a20,20,0,0,1-40,0Z" />
          </g>
          <path d="M231,123.5a8.2,8.2,0,0,0-2.4-5.7L121.1,10.3a8,8,0,0,0-11.3,0L69.7,50.4,43.5,24.2A8,8,0,0,0,32.2,35.5L58.4,61.7,13.7,106.5a23.9,23.9,0,0,0,0,33.9l84.8,84.9a24.1,24.1,0,0,0,34,0l96.1-96.2A8,8,0,0,0,231,123.5ZM121.1,214a8.1,8.1,0,0,1-11.3,0L25,129.1a8,8,0,0,1,0-11.3L69.7,73.1l29.7,29.6a28,28,0,1,0,11.3-11.3L81.1,61.7l34.4-34.4,96.2,96.2ZM124,104a12.2,12.2,0,0,1,8.5,3.5,12,12,0,1,1-17,0A12.2,12.2,0,0,1,124,104Zm109.7,54.3a8.2,8.2,0,0,0-11.4,0c-.9,1-22.3,22.7-22.3,45.7a28,28,0,0,0,56,0C256,181,234.6,159.3,233.7,158.3ZM228,216a12,12,0,0,1-12-12c0-9.7,6.5-20.6,12-27.8,5.5,7.2,12,18,12,27.8A12,12,0,0,1,228,216Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M115.5,124.5a12,12,0,0,1,0-17,12.1,12.1,0,0,1,17,0,12,12,0,0,1-17,17ZM43.5,24.2A8,8,0,0,0,32.2,35.5L58.4,61.7,69.7,50.4ZM233.7,158.3a8.2,8.2,0,0,0-11.4,0c-.9,1-22.3,22.7-22.3,45.7a28,28,0,0,0,56,0C256,181,234.6,159.3,233.7,158.3ZM231,123.5a8.2,8.2,0,0,0-2.4-5.7L121.1,10.3a8,8,0,0,0-11.3,0L69.7,50.4l41,41a28,28,0,1,1-11.3,11.3l-41-41L13.7,106.5a23.9,23.9,0,0,0,0,33.9l84.8,84.9a24.1,24.1,0,0,0,34,0l96.1-96.2A8,8,0,0,0,231,123.5Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M229,123.5a6.4,6.4,0,0,0-1.8-4.3L119.7,11.8a5.9,5.9,0,0,0-8.5,0L69.7,53.3,42.1,25.6a6,6,0,0,0-8.5,8.5L61.3,61.7,15.1,107.9a22,22,0,0,0,0,31.1l84.8,84.9a22.2,22.2,0,0,0,15.6,6.4,21.8,21.8,0,0,0,15.5-6.4l96.2-96.2A6,6,0,0,0,229,123.5ZM122.6,215.4a10.1,10.1,0,0,1-14.2,0L23.6,130.5a10,10,0,0,1,0-14.1L69.7,70.2l32.2,32.2a26,26,0,0,0,40.5,32h0A26,26,0,0,0,124,90a25.7,25.7,0,0,0-13.6,3.9L78.2,61.7l37.3-37.2,99,99ZM124,102a14.1,14.1,0,0,1,9.9,4.1,13.9,13.9,0,0,1,0,19.8,14,14,0,0,1-19.8,0,13.9,13.9,0,0,1,0-19.8A14.1,14.1,0,0,1,124,102Zm108.2,57.8a5.8,5.8,0,0,0-8.4,0c-.9.8-21.8,22-21.8,44.2a26,26,0,0,0,52,0C254,181.8,233.1,160.6,232.2,159.8ZM228,218a14,14,0,0,1-14-14c0-11.9,8.6-24.3,14-30.9,5.4,6.6,14,19,14,30.9A14,14,0,0,1,228,218Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M227,123.5a4.5,4.5,0,0,0-1.2-2.9L118.3,13.2a3.9,3.9,0,0,0-5.6,0l-43,42.9L40.7,27A4,4,0,0,0,35,32.7l29.1,29L16.5,109.3a20,20,0,0,0,0,28.3l84.8,84.9a20.2,20.2,0,0,0,28.3,0l96.2-96.2A4.2,4.2,0,0,0,227,123.5ZM124,216.8a12.1,12.1,0,0,1-17,0L22.1,132a12.2,12.2,0,0,1,0-17L69.7,67.4l34.8,34.7A24,24,0,0,0,107,133a24.1,24.1,0,0,0,34,0h0a24,24,0,0,0-30.9-36.5L75.4,61.7l40.1-40L217.3,123.5ZM124,100a16,16,0,1,1-11.3,4.7A16,16,0,0,1,124,100Zm106.8,61.2a3.9,3.9,0,0,0-5.6,0c-.9.8-21.2,21.4-21.2,42.8a24,24,0,0,0,48,0C252,182.6,231.7,162,230.8,161.2ZM228,220a16,16,0,0,1-16-16c0-13.7,10.7-27.9,16-34.1,5.3,6.2,16,20.4,16,34.1A16,16,0,0,1,228,220Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M231,123.5a8.2,8.2,0,0,0-2.4-5.7L121.1,10.3a8,8,0,0,0-11.3,0L69.7,50.4,43.5,24.2A8,8,0,0,0,32.2,35.5L58.4,61.7,13.7,106.5a23.9,23.9,0,0,0,0,33.9l84.8,84.9a24.1,24.1,0,0,0,34,0l96.1-96.2A8,8,0,0,0,231,123.5ZM121.1,214a8.1,8.1,0,0,1-11.3,0L25,129.1a8,8,0,0,1,0-11.3L69.7,73.1l29.7,29.6a28,28,0,1,0,11.3-11.3L81.1,61.7l34.4-34.4,96.2,96.2ZM124,104a12.2,12.2,0,0,1,8.5,3.5,12,12,0,1,1-17,0A12.2,12.2,0,0,1,124,104Zm109.7,54.3a8.2,8.2,0,0,0-11.4,0c-.9,1-22.3,22.7-22.3,45.7a28,28,0,0,0,56,0C256,181,234.6,159.3,233.7,158.3ZM228,216a12,12,0,0,1-12-12c0-9.7,6.5-20.6,12-27.8,5.5,7.2,12,18,12,27.8A12,12,0,0,1,228,216Z" />
        </>
      )}
    </svg>
  );
}

export function PhPaintRoller(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M232,84H216V64a20.1,20.1,0,0,0-20-20H52A20.1,20.1,0,0,0,32,64V84H16a12,12,0,0,0,0,24H32v20a20.1,20.1,0,0,0,20,20H196a20.1,20.1,0,0,0,20-20V108h12v42.9l-97.5,27.9A20.1,20.1,0,0,0,116,198v34a12,12,0,0,0,24,0V201.1l97.5-27.9A20.1,20.1,0,0,0,252,154V104A20.1,20.1,0,0,0,232,84Zm-40,40H56V68H192Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M208,64v64a8,8,0,0,1-8,8H48a8,8,0,0,1-8-8V64a8,8,0,0,1,8-8H200A8,8,0,0,1,208,64Z"
            opacity="0.2"
          />
          <path d="M232,88H216V64a16,16,0,0,0-16-16H48A16,16,0,0,0,32,64V88H16a8,8,0,0,0,0,16H32v24a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V104h16v50L131.6,182.6A16.2,16.2,0,0,0,120,198v34a8,8,0,0,0,16,0V198l100.4-28.6A16.2,16.2,0,0,0,248,154V104A16,16,0,0,0,232,88Zm-32,40H48V64H200V95.9h0V128Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M248,104v50a16.2,16.2,0,0,1-11.6,15.4L136,198v34a8,8,0,0,1-16,0V198a16.2,16.2,0,0,1,11.6-15.4L232,154V104H216v24a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V104H16a8,8,0,0,1,0-16H32V64A16,16,0,0,1,48,48H200a16,16,0,0,1,16,16V88h16A16,16,0,0,1,248,104Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M232,90H214V64a14,14,0,0,0-14-14H48A14,14,0,0,0,34,64V90H16a6,6,0,0,0,0,12H34v26a14,14,0,0,0,14,14H200a14,14,0,0,0,14-14V102h18a2,2,0,0,1,2,2v50a2,2,0,0,1-1.5,1.9L132.2,184.6A14,14,0,0,0,122,198v34a6,6,0,0,0,12,0V198a2,2,0,0,1,1.5-1.9l100.3-28.7A14,14,0,0,0,246,154V104A14,14,0,0,0,232,90Zm-30,38a2,2,0,0,1-2,2H48a2,2,0,0,1-2-2V64a2,2,0,0,1,2-2H200a2,2,0,0,1,2,2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M232,92H212V64a12,12,0,0,0-12-12H48A12,12,0,0,0,36,64V92H16a4,4,0,0,0,0,8H36v28a12,12,0,0,0,12,12H200a12,12,0,0,0,12-12V100h20a4,4,0,0,1,4,4v50a4.1,4.1,0,0,1-2.9,3.8L132.7,186.5A12.1,12.1,0,0,0,124,198v34a4,4,0,0,0,8,0V198a4.1,4.1,0,0,1,2.9-3.8l100.4-28.7A12.1,12.1,0,0,0,244,154V104A12,12,0,0,0,232,92Zm-28,36a4,4,0,0,1-4,4H48a4,4,0,0,1-4-4V64a4,4,0,0,1,4-4H200a4,4,0,0,1,4,4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M232,88H216V64a16,16,0,0,0-16-16H48A16,16,0,0,0,32,64V88H16a8,8,0,0,0,0,16H32v24a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V104h16v50L131.6,182.6A16.2,16.2,0,0,0,120,198v34a8,8,0,0,0,16,0V198l100.4-28.6A16.2,16.2,0,0,0,248,154V104A16,16,0,0,0,232,88Zm-32,40H48V64H200V95.9h0V128Z" />
        </>
      )}
    </svg>
  );
}

export function PhPalette(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M203.6,51A108,108,0,1,0,92,229.9a38.2,38.2,0,0,0,12,2,36,36,0,0,0,36-36V192a12,12,0,0,1,12-12h46.2a35.8,35.8,0,0,0,35.1-28,103.9,103.9,0,0,0,2.7-24.9A107.3,107.3,0,0,0,203.6,51Zm6.3,95.7h0a11.8,11.8,0,0,1-11.7,9.3H152a36,36,0,0,0-36,36v3.9a11.9,11.9,0,0,1-5.1,9.8,11.8,11.8,0,0,1-10.9,1.5A84,84,0,0,1,127.1,44h.9a84.3,84.3,0,0,1,84,83.3A79,79,0,0,1,209.9,146.7ZM144,76a16,16,0,1,1-16-16A16,16,0,0,1,144,76ZM96.8,110A16,16,0,1,1,91,88.1,16,16,0,0,1,96.8,110Zm0,36A16,16,0,1,1,75,140.1,16.1,16.1,0,0,1,96.8,146Zm90.1-52A16,16,0,1,1,165,88.1,16,16,0,0,1,186.9,94Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M224,127.2a96.2,96.2,0,0,1-2.4,22.2A24,24,0,0,1,198.2,168H152a23.9,23.9,0,0,0-24,24v3.9a23.9,23.9,0,0,1-32,22.6A96,96,0,0,1,127,32C179.8,31.5,223.6,74.3,224,127.2Z"
            opacity="0.2"
          />
          <path d="M200.8,53.9A103.4,103.4,0,0,0,128,24h-1.1A104,104,0,0,0,93.4,226.1,32,32,0,0,0,136,195.9V192a16,16,0,0,1,16-16h46.2a31.7,31.7,0,0,0,31.2-24.9,101.5,101.5,0,0,0,2.6-24A102.9,102.9,0,0,0,200.8,53.9Zm13,93.7A15.9,15.9,0,0,1,198.2,160H152a32.1,32.1,0,0,0-32,32v3.9A16,16,0,0,1,98.7,211,88.2,88.2,0,0,1,40,128a88.1,88.1,0,0,1,87.1-88h.9a88.3,88.3,0,0,1,88,87.2A86.8,86.8,0,0,1,213.8,147.6ZM140,76a12,12,0,1,1-12-12A12,12,0,0,1,140,76ZM93.4,108A12,12,0,1,1,89,91.6,12.1,12.1,0,0,1,93.4,108Zm0,40A12,12,0,1,1,77,143.6,12.1,12.1,0,0,1,93.4,148Zm90-52A12,12,0,1,1,167,91.6,12,12,0,0,1,183.4,96Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M200.8,53.9A103.4,103.4,0,0,0,128,24h-1.1A104,104,0,0,0,93.4,226.1,32,32,0,0,0,136,195.9V192a16,16,0,0,1,16-16h46.2a31.7,31.7,0,0,0,31.2-24.9,101.5,101.5,0,0,0,2.6-24A102.9,102.9,0,0,0,200.8,53.9ZM89,164.4A12,12,0,1,1,93.4,148,12,12,0,0,1,89,164.4ZM93.4,108A12,12,0,1,1,89,91.6,12.1,12.1,0,0,1,93.4,108ZM128,88a12,12,0,1,1,12-12A12,12,0,0,1,128,88Zm51,24.4A12,12,0,1,1,183.4,96,12.1,12.1,0,0,1,179,112.4Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M199.4,55.3A101.5,101.5,0,0,0,128,26h-1A102,102,0,0,0,94,224.2a30,30,0,0,0,40-28.3V192a18.1,18.1,0,0,1,18-18h46.2a30,30,0,0,0,29.3-23.3,103.4,103.4,0,0,0,2.5-23.6A101.3,101.3,0,0,0,199.4,55.3ZM215.8,148a17.9,17.9,0,0,1-17.6,14H152a30.1,30.1,0,0,0-30,30v3.9a18,18,0,0,1-24,17A90,90,0,0,1,64.1,64.7a88.8,88.8,0,0,1,63-26.7h.9a90.3,90.3,0,0,1,90,89.2A90.8,90.8,0,0,1,215.8,148ZM138,76a10,10,0,1,1-10-10A10,10,0,0,1,138,76ZM91.6,107A10,10,0,1,1,74.3,97a10,10,0,1,1,17.3,10Zm0,42A10,10,0,1,1,78,145.3,10.1,10.1,0,0,1,91.6,149Zm90.1-52A10,10,0,1,1,168,93.3,10,10,0,0,1,181.7,97Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M198,56.7A99.7,99.7,0,0,0,128,28h-1A100,100,0,0,0,94.7,222.3a28.1,28.1,0,0,0,25.5-3.5A27.8,27.8,0,0,0,132,195.9V192a20.1,20.1,0,0,1,20-20h46.2a27.7,27.7,0,0,0,27.3-21.8A99.7,99.7,0,0,0,198,56.7Zm19.7,91.8A19.9,19.9,0,0,1,198.2,164H152a28.1,28.1,0,0,0-28,28v3.9a20.1,20.1,0,0,1-26.6,18.9A92,92,0,0,1,62.6,63.3,91.2,91.2,0,0,1,127.1,36h.9a92.4,92.4,0,0,1,92,91.2A89.7,89.7,0,0,1,217.7,148.5ZM136,76a8,8,0,1,1-8-8A8,8,0,0,1,136,76ZM89.9,106A8,8,0,0,1,76,98a8.1,8.1,0,0,1,11-2.9A8,8,0,0,1,89.9,106Zm0,44A8,8,0,0,1,87,160.9,8.1,8.1,0,0,1,76,158a8,8,0,0,1,13.9-8ZM180,98a8,8,0,1,1-11-2.9A8.1,8.1,0,0,1,180,98Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M200.8,53.9A103.4,103.4,0,0,0,128,24h-1.1A104,104,0,0,0,93.4,226.1,32,32,0,0,0,136,195.9V192a16,16,0,0,1,16-16h46.2a31.7,31.7,0,0,0,31.2-24.9,101.5,101.5,0,0,0,2.6-24A102.9,102.9,0,0,0,200.8,53.9Zm13,93.7A15.9,15.9,0,0,1,198.2,160H152a32.1,32.1,0,0,0-32,32v3.9A16,16,0,0,1,98.7,211,88.2,88.2,0,0,1,40,128a88.1,88.1,0,0,1,87.1-88h.9a88.3,88.3,0,0,1,88,87.2A86.8,86.8,0,0,1,213.8,147.6ZM140,76a12,12,0,1,1-12-12A12,12,0,0,1,140,76ZM93.4,108A12,12,0,1,1,89,91.6,12.1,12.1,0,0,1,93.4,108Zm0,40A12,12,0,1,1,77,143.6,12.1,12.1,0,0,1,93.4,148Zm90-52A12,12,0,1,1,167,91.6,12,12,0,0,1,183.4,96Z" />
        </>
      )}
    </svg>
  );
}

export function PhPaperPlaneRight(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M225.4,110.5,56.5,16A19.9,19.9,0,0,0,27.9,40.1L59.3,128,27.9,215.9a19.8,19.8,0,0,0,5.9,22,20.1,20.1,0,0,0,12.9,4.7,19.5,19.5,0,0,0,9.8-2.6l168.9-94.5a20.1,20.1,0,0,0,0-35ZM54.1,213.9,80.5,140H136a12,12,0,0,0,0-24H80.5L54.1,42.1,207.4,128Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M219.5,121,50.6,26.4a8,8,0,0,0-11.4,9.7L71,125.3a7.2,7.2,0,0,1,0,5.4L39.2,219.9a8,8,0,0,0,11.4,9.7L219.5,135A8,8,0,0,0,219.5,121Z" />
          </g>
          <path d="M223.4,114,54.5,19.4A16.1,16.1,0,0,0,31.6,38.8L63.5,128,31.6,217.2a16.1,16.1,0,0,0,15.1,21.4,16.5,16.5,0,0,0,7.8-2L223.4,142a16.1,16.1,0,0,0,0-28ZM46.7,222.6,77.6,136H136a8,8,0,0,0,0-16H77.6L46.7,33.4,215.6,128Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M223.4,114,54.9,19.7a16.3,16.3,0,0,0-18.2,1.2,15.9,15.9,0,0,0-5.1,17.9l28.1,78.5a4.1,4.1,0,0,0,3.8,2.7h72.2a8.2,8.2,0,0,1,8.3,7.5,8,8,0,0,1-8,8.5H63.5a4.1,4.1,0,0,0-3.8,2.7L31.6,217.2a16.1,16.1,0,0,0,15.1,21.4,16.5,16.5,0,0,0,7.8-2L223.4,142a16.1,16.1,0,0,0,0-28Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M222.5,115.8,53.6,21.2A14,14,0,0,0,33.5,38.1l31.9,89.2a3.3,3.3,0,0,1,0,1.4L33.5,217.9a14,14,0,0,0,13.2,18.7,14.5,14.5,0,0,0,6.9-1.8l168.9-94.6a14,14,0,0,0,0-24.4Zm-5.9,13.9L47.7,224.3a1.9,1.9,0,0,1-2.3-.2,1.8,1.8,0,0,1-.6-2.2L76.2,134H136a6,6,0,0,0,0-12H76.2L44.8,34.1a1.8,1.8,0,0,1,.6-2.2,1.9,1.9,0,0,1,1.3-.5l1,.3,168.9,94.6a1.9,1.9,0,0,1,0,3.4Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M221.5,117.5,52.6,22.9A12.1,12.1,0,0,0,39,24.2a11.9,11.9,0,0,0-3.6,13.2l31.9,89.3a3.9,3.9,0,0,1,0,2.6L35.4,218.6A11.9,11.9,0,0,0,39,231.8a12.2,12.2,0,0,0,7.7,2.8,12.6,12.6,0,0,0,5.9-1.5l168.9-94.6a12.1,12.1,0,0,0,0-21Zm-3.9,14L48.7,226.1a4.1,4.1,0,0,1-5.8-4.9L74.8,132H136a4,4,0,0,0,0-8H74.8L42.9,34.8a3.9,3.9,0,0,1,1.2-4.4,4.2,4.2,0,0,1,2.6-1,3.9,3.9,0,0,1,2,.5l168.9,94.6a4.1,4.1,0,0,1,0,7Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M223.4,114,54.5,19.4A16.1,16.1,0,0,0,31.6,38.8L63.5,128,31.6,217.2a16.1,16.1,0,0,0,15.1,21.4,16.5,16.5,0,0,0,7.8-2L223.4,142a16.1,16.1,0,0,0,0-28ZM46.7,222.6,77.6,136H136a8,8,0,0,0,0-16H77.6L46.7,33.4,215.6,128Z" />
        </>
      )}
    </svg>
  );
}

export function PhPaperPlaneTilt(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M226.6,29.4A20.2,20.2,0,0,0,207,24.3h0L20.7,76.9a20,20,0,0,0-3.2,37.3l84.4,39.9,39.9,84.4a19.7,19.7,0,0,0,18,11.4h1.7a19.9,19.9,0,0,0,17.6-14.5L231.7,49A20.2,20.2,0,0,0,226.6,29.4ZM158.9,218.5l-33.6-70.9,39.3-39.2a12,12,0,0,0-17-17l-39.2,39.3L37.5,97.1,206.6,49.4Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M210.3,35.9,23.9,88.4a8,8,0,0,0-1.2,15l85.6,40.5a7.8,7.8,0,0,1,3.8,3.8l40.5,85.6a8,8,0,0,0,15-1.2L220.1,45.7A7.9,7.9,0,0,0,210.3,35.9Z" />
          </g>
          <path d="M223.7,32.3a15.9,15.9,0,0,0-15.6-4.1L21.8,80.7a16.1,16.1,0,0,0-2.6,29.9l85.7,40.5,40.5,85.7a16,16,0,0,0,14.4,9.1h1.4a15.9,15.9,0,0,0,14.1-11.6L227.8,47.9A15.9,15.9,0,0,0,223.7,32.3ZM159.9,229.9l-39.4-83.1,41.3-41.3a8,8,0,1,0-11.3-11.3l-41.3,41.3L26.1,96.1,212.4,43.6Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M227.7,48.3,175.3,234.2a15.9,15.9,0,0,1-14.1,11.6h-1.4a16,16,0,0,1-14.4-9.1l-35.7-75.4a4.1,4.1,0,0,1,.8-4.6l51.3-51.3a8,8,0,1,0-11.3-11.3L99.2,145.5a4.1,4.1,0,0,1-4.6.8l-75-35.5a16.6,16.6,0,0,1-9.5-15.6A15.9,15.9,0,0,1,21.8,80.7L208.1,28.2a16,16,0,0,1,17.7,6.5A16.7,16.7,0,0,1,227.7,48.3Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M222.3,33.7a13.6,13.6,0,0,0-13.7-3.6L22.3,82.7A13.9,13.9,0,0,0,12.1,95a14.1,14.1,0,0,0,8,13.8l85.6,40.5a2.4,2.4,0,0,1,1,1l40.5,85.6a13.8,13.8,0,0,0,12.6,8H161a13.9,13.9,0,0,0,12.3-10.2L225.9,47.4A13.6,13.6,0,0,0,222.3,33.7Zm-8,10.4L161.8,230.4a1.8,1.8,0,0,1-1.8,1.5,1.8,1.8,0,0,1-1.9-1.1l-40-84.4,42.3-42.3a6,6,0,0,0-8.5-8.5l-42.3,42.3-84.4-40A1.8,1.8,0,0,1,24.1,96a1.8,1.8,0,0,1,1.5-1.8L211.9,41.7h.6a1.6,1.6,0,0,1,1.3.6A1.8,1.8,0,0,1,214.3,44.1Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M220.9,35.1A11.8,11.8,0,0,0,209.2,32L22.8,84.6A12,12,0,0,0,21,107l85.6,40.5a4.4,4.4,0,0,1,1.9,1.9L149,235a11.8,11.8,0,0,0,10.8,6.9h1.1a12.1,12.1,0,0,0,10.5-8.7L224,46.8A11.8,11.8,0,0,0,220.9,35.1Zm-4.6,9.6L163.7,231a3.9,3.9,0,0,1-3.5,2.9,4,4,0,0,1-3.9-2.3L115.7,146h0l43.2-43.3a4,4,0,1,0-5.6-5.6L110,140.3h0L24.4,99.7a4,4,0,0,1-2.3-3.9A3.9,3.9,0,0,1,25,92.3L211.3,39.7h1.2a4.1,4.1,0,0,1,2.8,1.1A4.5,4.5,0,0,1,216.3,44.7Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M223.7,32.3a15.9,15.9,0,0,0-15.6-4.1L21.8,80.7a16.1,16.1,0,0,0-2.6,29.9l85.7,40.5,40.5,85.7a16,16,0,0,0,14.4,9.1h1.4a15.9,15.9,0,0,0,14.1-11.6L227.8,47.9A15.9,15.9,0,0,0,223.7,32.3ZM159.9,229.9l-39.4-83.1,41.3-41.3a8,8,0,1,0-11.3-11.3l-41.3,41.3L26.1,96.1,212.4,43.6Z" />
        </>
      )}
    </svg>
  );
}

export function PhPaperPlane(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M240,199.5,145.5,30.6a20.1,20.1,0,0,0-35,0L16,199.5a19.9,19.9,0,0,0,24.1,28.6L128,196.7l87.9,31.4A19.9,19.9,0,0,0,240,199.5Zm-100-24V120a12,12,0,0,0-24,0v55.5L42.1,201.9,128,48.6l85.9,153.3Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M121,36.5,26.4,205.4a8,8,0,0,0,9.7,11.4L125.3,185a7.2,7.2,0,0,1,5.4,0l89.2,31.8a8,8,0,0,0,9.7-11.4L135,36.5A8,8,0,0,0,121,36.5Z" />
          </g>
          <path d="M236.6,201.5,142,32.6a16.1,16.1,0,0,0-28,0L19.4,201.5a16.1,16.1,0,0,0,19.4,22.9L128,192.5l89.2,31.9a17.1,17.1,0,0,0,5.5.9,15.7,15.7,0,0,0,12.1-5.7A15.9,15.9,0,0,0,236.6,201.5ZM136,178.4V120a8,8,0,0,0-16,0v58.4L33.4,209.3,128,40.4l94.6,168.9Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M236.4,201.5,141.8,32.6a16,16,0,0,0-27.9,0L19.3,201.5a15.7,15.7,0,0,0,1.8,18.1,15.9,15.9,0,0,0,17.6,4.8l78.5-28.1a4.1,4.1,0,0,0,2.7-3.8V120.3a8.2,8.2,0,0,1,7.4-8.3,8,8,0,0,1,8.6,8v72.5a4,4,0,0,0,2.6,3.8l78.6,28.1a17,17,0,0,0,5.4.9,16,16,0,0,0,13.9-23.8Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M234.8,202.4,140.2,33.5a14,14,0,0,0-24.4,0L21.2,202.4a14,14,0,0,0,16.9,20.1l89.2-31.9h1.4l89.2,31.9a16,16,0,0,0,4.7.8,13.7,13.7,0,0,0,10.7-5A13.9,13.9,0,0,0,234.8,202.4Zm-10.7,8.2a1.8,1.8,0,0,1-2.2.6L134,179.8V120a6,6,0,0,0-12,0v59.8L34.1,211.2a1.8,1.8,0,0,1-2.2-.6,1.9,1.9,0,0,1-.2-2.3L126.3,39.4h0a1.9,1.9,0,0,1,3.4,0l94.6,168.9A1.9,1.9,0,0,1,224.1,210.6Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M233.1,203.4,138.5,34.5a12.1,12.1,0,0,0-21,0L22.9,203.4A12.1,12.1,0,0,0,24.2,217a11.9,11.9,0,0,0,13.2,3.6l89.3-31.9a3.9,3.9,0,0,1,2.6,0l89.3,31.9a11.2,11.2,0,0,0,4,.7,12,12,0,0,0,10.5-17.9Zm-7.5,8.5a3.9,3.9,0,0,1-4.4,1.2L132,181.2h0V120a4,4,0,0,0-8,0v61.2h0L34.8,213.1a4.1,4.1,0,0,1-4.9-5.8L124.5,38.4a4.1,4.1,0,0,1,7,0l94.6,168.9A3.9,3.9,0,0,1,225.6,211.9Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M236.6,201.5,142,32.6a16.1,16.1,0,0,0-28,0L19.4,201.5a16.1,16.1,0,0,0,19.4,22.9L128,192.5l89.2,31.9a17.1,17.1,0,0,0,5.5.9,15.7,15.7,0,0,0,12.1-5.7A15.9,15.9,0,0,0,236.6,201.5ZM136,178.4V120a8,8,0,0,0-16,0v58.4L33.4,209.3,128,40.4l94.6,168.9Z" />
        </>
      )}
    </svg>
  );
}

export function PhPaperclipHorizontal(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M248,128a60,60,0,0,1-60,60H48a40,40,0,0,1,0-80H184a12,12,0,0,1,0,24H48a16,16,0,0,0,0,32H188a36,36,0,0,0,0-72H76a12,12,0,0,1,0-24H188A60,60,0,0,1,248,128Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M192,184H46c-21.6,0-39.2-17.9-39.2-40S24.4,104,46,104H190a24,24,0,0,1,0,48H78a8,8,0,0,1,0-16H190a8,8,0,0,0,0-16H46c-12.8,0-23.2,10.8-23.2,24S33.2,168,46,168H192a40,40,0,0,0,0-80H80a8,8,0,0,1,0-16H192a56,56,0,0,1,0,112Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M192,184H46c-21.6,0-39.2-17.9-39.2-40S24.4,104,46,104H190a24,24,0,0,1,0,48H78a8,8,0,0,1,0-16H190a8,8,0,0,0,0-16H46c-12.8,0-23.2,10.8-23.2,24S33.2,168,46,168H192a40,40,0,0,0,0-80H80a8,8,0,0,1,0-16H192a56,56,0,0,1,0,112Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M192,182H46c-20.5,0-37.2-17-37.2-38S25.5,106,46,106H190a22,22,0,0,1,0,44H78a6,6,0,0,1,0-12H190a10,10,0,0,0,0-20H46c-13.9,0-25.2,11.7-25.2,26S32.1,170,46,170H192a42,42,0,0,0,0-84H80a6,6,0,0,1,0-12H192a54,54,0,0,1,0,108Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M192,180H46c-19.4,0-35.2-16.1-35.2-36S26.6,108,46,108H190a20,20,0,0,1,0,40H78a4,4,0,0,1,0-8H190a12,12,0,0,0,0-24H46c-15,0-27.2,12.6-27.2,28S31,172,46,172H192a44,44,0,0,0,0-88H80a4,4,0,0,1,0-8H192a52,52,0,0,1,0,104Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M192,184H46c-21.6,0-39.2-17.9-39.2-40S24.4,104,46,104H190a24,24,0,0,1,0,48H78a8,8,0,0,1,0-16H190a8,8,0,0,0,0-16H46c-12.8,0-23.2,10.8-23.2,24S33.2,168,46,168H192a40,40,0,0,0,0-80H80a8,8,0,0,1,0-16H192a56,56,0,0,1,0,112Z" />
        </>
      )}
    </svg>
  );
}

export function PhPaperclip(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M212.5,136.5l-82.1,81.9a60,60,0,1,1-84.8-84.8l98.1-97.9a40,40,0,0,1,56.6,56.6c-.1,0-.1.1-.2.2l-95.8,92.1a12,12,0,0,1-16.6-17.2l95.7-92.2a16,16,0,0,0-22.7-22.5L62.5,150.6a36,36,0,0,0,51,50.9l82-82a12,12,0,0,1,17,17Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M209.7,122.3a8.1,8.1,0,0,1,0,11.4l-82.1,81.9a56,56,0,0,1-79.2-79.2L147.7,35.8a40,40,0,1,1,56.6,56.5L105,192.9A23.8,23.8,0,0,1,88,200a24,24,0,0,1-17-41l83.3-84.6a8,8,0,1,1,11.4,11.2L82.4,170.3A8,8,0,0,0,80,176a8.1,8.1,0,0,0,2.3,5.7A8.3,8.3,0,0,0,88,184a8.1,8.1,0,0,0,5.7-2.3L192.9,81A23.8,23.8,0,0,0,200,64a24,24,0,0,0-41-17L59.8,147.7a40,40,0,1,0,56.5,56.6l82-82A8.1,8.1,0,0,1,209.7,122.3Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M209.7,122.3a8.1,8.1,0,0,1,0,11.4l-82.1,81.9a56,56,0,0,1-79.2-79.2L147.7,35.8a40,40,0,1,1,56.6,56.5L105,192.9A23.8,23.8,0,0,1,88,200a24,24,0,0,1-17-41l83.3-84.6a8,8,0,1,1,11.4,11.2L82.4,170.3A8,8,0,0,0,80,176a8.1,8.1,0,0,0,2.3,5.7A8.3,8.3,0,0,0,88,184a8.1,8.1,0,0,0,5.7-2.3L192.9,81A23.8,23.8,0,0,0,200,64a24,24,0,0,0-41-17L59.8,147.7a40,40,0,1,0,56.5,56.6l82-82A8.1,8.1,0,0,1,209.7,122.3Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M208.2,123.8a5.8,5.8,0,0,1,0,8.4l-82,82a54,54,0,0,1-76.4-76.4L149.1,37.2a38,38,0,0,1,53.8,53.7L103.6,191.5A21.9,21.9,0,0,1,88,198a22.1,22.1,0,0,1-15.6-37.6l83.3-84.6a6,6,0,0,1,8.6,8.4L81,168.9a9.6,9.6,0,0,0-3,7.1,10,10,0,0,0,17.1,7.1L194.4,82.4a26,26,0,0,0-36.8-36.8L58.3,146.3a42,42,0,0,0,59.4,59.4l82.1-81.9A5.8,5.8,0,0,1,208.2,123.8Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M206.8,125.2a3.9,3.9,0,0,1,0,5.6l-82,82a52,52,0,0,1-73.6-73.6L150.5,38.6a36,36,0,0,1,51,50.9L102.2,190.1A20.1,20.1,0,0,1,88,196a19.9,19.9,0,0,1-14.1-34.1l83.2-84.7a4,4,0,1,1,5.8,5.6L79.5,167.5a12.1,12.1,0,0,0,0,17,12.1,12.1,0,0,0,17,0L195.8,83.8a28,28,0,0,0-39.6-39.6L56.9,144.9a44,44,0,0,0,62.2,62.2l82.1-81.9A3.9,3.9,0,0,1,206.8,125.2Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M209.7,122.3a8.1,8.1,0,0,1,0,11.4l-82.1,81.9a56,56,0,0,1-79.2-79.2L147.7,35.8a40,40,0,1,1,56.6,56.5L105,192.9A23.8,23.8,0,0,1,88,200a24,24,0,0,1-17-41l83.3-84.6a8,8,0,1,1,11.4,11.2L82.4,170.3A8,8,0,0,0,80,176a8.1,8.1,0,0,0,2.3,5.7A8.3,8.3,0,0,0,88,184a8.1,8.1,0,0,0,5.7-2.3L192.9,81A23.8,23.8,0,0,0,200,64a24,24,0,0,0-41-17L59.8,147.7a40,40,0,1,0,56.5,56.6l82-82A8.1,8.1,0,0,1,209.7,122.3Z" />
        </>
      )}
    </svg>
  );
}

export function PhParachute(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M236,120A108.1,108.1,0,0,0,128,12h0A108.1,108.1,0,0,0,20,120a12.1,12.1,0,0,0,4.8,9.6h0L116,198v14h-4a12,12,0,0,0,0,24h32a12,12,0,0,0,0-24h-4V198l91.2-68.4h0A12.1,12.1,0,0,0,236,120Zm-24.9-12H179.6c-1.8-29.3-9.9-50.5-18.9-65.4A84.2,84.2,0,0,1,211.1,108Zm-110.7,0C103.2,68.8,119,48.1,128,39.4c9,8.7,24.8,29.4,27.6,68.6ZM95.3,42.6c-9,14.9-17.1,36.1-18.9,65.4H44.9A84.2,84.2,0,0,1,95.3,42.6ZM68,132h48v36Zm72,0h48l-48,36Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M128,24S88,48,88,120H32A96,96,0,0,1,128,24Zm0,0s40,24,40,96h56A96,96,0,0,0,128,24Z"
            opacity="0.2"
          />
          <path d="M232,120A104.2,104.2,0,0,0,128,16h0A104.2,104.2,0,0,0,24,120a8.2,8.2,0,0,0,3.2,6.4h0L120,196v20h-8a8,8,0,0,0,0,16h32a8,8,0,0,0,0-16h-8V196l92.8-69.6h0A8.2,8.2,0,0,0,232,120Zm-16.4-8H175.8c-1.5-37.9-13.9-62.4-25.1-77A88.1,88.1,0,0,1,215.6,112ZM128,34a79.2,79.2,0,0,1,13.9,16.3c7.6,11.6,16.5,31.6,17.9,61.7H96.2c1.4-30.1,10.3-50.1,17.9-61.7A76.5,76.5,0,0,1,128,34Zm26.4,94L128,175.5,101.6,128Zm-71.1,0,19.5,35.1L56,128Zm89.4,0H200l-46.8,35.1ZM105.3,35C94.1,49.6,81.7,74.1,80.2,112H40.4A88.1,88.1,0,0,1,105.3,35Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M232,120A104.2,104.2,0,0,0,128,16h0A104.2,104.2,0,0,0,24,120a8.2,8.2,0,0,0,3.2,6.4h0L120,196v20h-8a8,8,0,0,0,0,16h32a8,8,0,0,0,0-16h-8V196l92.8-69.6h0A8.2,8.2,0,0,0,232,120Zm-16.4-8H175.8c-1.5-37.9-13.9-62.4-25.1-77A88.1,88.1,0,0,1,215.6,112Zm-61.2,16L128,175.5,101.6,128Zm-71.1,0,19.5,35.1L56,128Zm89.4,0H200l-46.8,35.1ZM105.3,35C94.1,49.6,81.7,74.1,80.2,112H40.4A88.1,88.1,0,0,1,105.3,35Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M230,120a102,102,0,0,0-204,0,5.8,5.8,0,0,0,2.4,4.8h0L122,195v23H112a6,6,0,0,0,0,12h32a6,6,0,0,0,0-12H134V195l93.6-70.2h0A5.8,5.8,0,0,0,230,120Zm-12.2-6H173.9c-1.3-42.9-16.5-68.6-28.4-82.3A90.1,90.1,0,0,1,217.8,114ZM128,31.4a78.5,78.5,0,0,1,15.4,17.5C157.3,70.1,161.2,95,161.9,114H94.1c1.1-31.9,10.5-52.9,18.5-65.1A78.5,78.5,0,0,1,128,31.4ZM157.8,126,128,179.6,98.2,126Zm-73.3,0,24.6,44.3L50,126Zm87,0H206l-59.1,44.3Zm-61-94.3C98.6,45.4,83.4,71.1,82.1,114H38.2A90.1,90.1,0,0,1,110.5,31.7Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M228,120a100,100,0,0,0-200,0,3.9,3.9,0,0,0,1.6,3.2h0L124,194v26H112a4,4,0,0,0,0,8h32a4,4,0,0,0,0-8H132V194l94.4-70.8h0A3.9,3.9,0,0,0,228,120Zm-8.1-4H172c-1-48.6-19.9-75.1-32-87.2A92.1,92.1,0,0,1,219.9,116ZM128,28.9a76.2,76.2,0,0,1,16.9,18.7c8.4,12.7,18.3,34.8,19.1,68.4H92c.8-33.6,10.7-55.7,19.1-68.4A74.3,74.3,0,0,1,128,28.9ZM161.2,124,128,183.8,94.8,124Zm-75.6,0,29.8,53.5L44,124Zm84.8,0H212l-71.4,53.5ZM116,28.8C103.9,40.9,85,67.4,84,116H36.1A92.1,92.1,0,0,1,116,28.8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M232,120A104.2,104.2,0,0,0,128,16h0A104.2,104.2,0,0,0,24,120a8.2,8.2,0,0,0,3.2,6.4h0L120,196v20h-8a8,8,0,0,0,0,16h32a8,8,0,0,0,0-16h-8V196l92.8-69.6h0A8.2,8.2,0,0,0,232,120Zm-16.4-8H175.8c-1.5-37.9-13.9-62.4-25.1-77A88.1,88.1,0,0,1,215.6,112ZM128,34a79.2,79.2,0,0,1,13.9,16.3c7.6,11.6,16.5,31.6,17.9,61.7H96.2c1.4-30.1,10.3-50.1,17.9-61.7A76.5,76.5,0,0,1,128,34Zm26.4,94L128,175.5,101.6,128Zm-71.1,0,19.5,35.1L56,128Zm89.4,0H200l-46.8,35.1ZM105.3,35C94.1,49.6,81.7,74.1,80.2,112H40.4A88.1,88.1,0,0,1,105.3,35Z" />
        </>
      )}
    </svg>
  );
}

export function PhPassword(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M48,56V200a12,12,0,0,1-24,0V56a12,12,0,0,1,24,0Zm82.7,50.7L116,111.5V96a12,12,0,0,0-24,0v15.5l-14.7-4.8a12,12,0,1,0-7.4,22.8l14.7,4.8-9.1,12.5a12.1,12.1,0,0,0,2.6,16.8,12.5,12.5,0,0,0,7.1,2.3,11.8,11.8,0,0,0,9.7-5l9.1-12.5,9.1,12.5a11.8,11.8,0,0,0,9.7,5,12.5,12.5,0,0,0,7.1-2.3,12.1,12.1,0,0,0,2.6-16.8l-9.1-12.5,14.7-4.8a12,12,0,0,0-7.4-22.8Zm111.1,7.7a12,12,0,0,0-15.1-7.7L212,111.5V96a12,12,0,0,0-24,0v15.5l-14.7-4.8a12,12,0,1,0-7.4,22.8l14.7,4.8-9.1,12.5a12.1,12.1,0,0,0,2.6,16.8,12.5,12.5,0,0,0,7.1,2.3,11.8,11.8,0,0,0,9.7-5l9.1-12.5,9.1,12.5a11.8,11.8,0,0,0,9.7,5,12.5,12.5,0,0,0,7.1-2.3,12.1,12.1,0,0,0,2.6-16.8l-9.1-12.5,14.7-4.8A11.9,11.9,0,0,0,241.8,114.4Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M48,56V200a8,8,0,0,1-16,0V56a8,8,0,0,1,16,0Zm84,54.5L112,117V96a8,8,0,0,0-16,0v21l-20-6.5a7.8,7.8,0,0,0-10,5.1,7.9,7.9,0,0,0,5.1,10.1l20,6.5-12.4,17a8,8,0,0,0,1.8,11.2,8.1,8.1,0,0,0,11.2-1.8l12.3-17,12.3,17a8.1,8.1,0,0,0,11.2,1.8,8,8,0,0,0,1.8-11.2l-12.4-17,20-6.5a7.9,7.9,0,0,0,5.1-10.1A7.8,7.8,0,0,0,132,110.5Zm106,5.1a7.8,7.8,0,0,0-10-5.1L208,117V96a8,8,0,0,0-16,0v21l-20-6.5a7.8,7.8,0,0,0-10,5.1,7.9,7.9,0,0,0,5.1,10.1l20,6.5-12.4,17a8,8,0,0,0,1.8,11.2,8.1,8.1,0,0,0,11.2-1.8l12.3-17,12.3,17a8.1,8.1,0,0,0,11.2,1.8,8,8,0,0,0,1.8-11.2l-12.4-17,20-6.5A7.9,7.9,0,0,0,238,115.6Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M48,56V200a8,8,0,0,1-16,0V56a8,8,0,0,1,16,0Zm84,54.5L112,117V96a8,8,0,0,0-16,0v21l-20-6.5a7.8,7.8,0,0,0-10,5.1,7.9,7.9,0,0,0,5.1,10.1l20,6.5-12.4,17a8,8,0,0,0,1.8,11.2,8.1,8.1,0,0,0,11.2-1.8l12.3-17,12.3,17a8.1,8.1,0,0,0,11.2,1.8,8,8,0,0,0,1.8-11.2l-12.4-17,20-6.5a7.9,7.9,0,0,0,5.1-10.1A7.8,7.8,0,0,0,132,110.5Zm106,5.1a7.8,7.8,0,0,0-10-5.1L208,117V96a8,8,0,0,0-16,0v21l-20-6.5a7.8,7.8,0,0,0-10,5.1,7.9,7.9,0,0,0,5.1,10.1l20,6.5-12.4,17a8,8,0,0,0,1.8,11.2,8.1,8.1,0,0,0,11.2-1.8l12.3-17,12.3,17a8.1,8.1,0,0,0,11.2,1.8,8,8,0,0,0,1.8-11.2l-12.4-17,20-6.5A7.9,7.9,0,0,0,238,115.6Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M46,56V200a6,6,0,0,1-12,0V56a6,6,0,0,1,12,0Zm86.6,56.4L110,119.7V96a6,6,0,0,0-12,0v23.7l-22.6-7.3a6,6,0,0,0-3.7,11.4l22.6,7.4-14,19.2a5.9,5.9,0,0,0,1.4,8.3,5.7,5.7,0,0,0,3.5,1.2,5.9,5.9,0,0,0,4.8-2.5l14-19.2,14,19.2a5.9,5.9,0,0,0,4.8,2.5,5.7,5.7,0,0,0,3.5-1.2,5.9,5.9,0,0,0,1.4-8.3l-14-19.2,22.6-7.4a6,6,0,0,0-3.7-11.4Zm103.5,3.9a6,6,0,0,0-7.5-3.9L206,119.7V96a6,6,0,0,0-12,0v23.7l-22.6-7.3a6,6,0,0,0-3.7,11.4l22.6,7.4-14,19.2a5.9,5.9,0,0,0,1.4,8.3,5.7,5.7,0,0,0,3.5,1.2,5.9,5.9,0,0,0,4.8-2.5l14-19.2,14,19.2a5.9,5.9,0,0,0,4.8,2.5,5.7,5.7,0,0,0,3.5-1.2,5.9,5.9,0,0,0,1.4-8.3l-14-19.2,22.6-7.4A5.9,5.9,0,0,0,236.1,116.3Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M44,56V200a4,4,0,0,1-8,0V56a4,4,0,0,1,8,0Zm89.2,58.3L108,122.5V96a4,4,0,0,0-8,0v26.5l-25.2-8.2a4,4,0,0,0-2.5,7.6l25.2,8.2L82,151.5a4.1,4.1,0,0,0,.8,5.6,4.3,4.3,0,0,0,2.4.8,4,4,0,0,0,3.2-1.7L104,134.8l15.6,21.4a4,4,0,0,0,3.2,1.7,4.3,4.3,0,0,0,2.4-.8,4.1,4.1,0,0,0,.8-5.6l-15.5-21.4,25.2-8.2a4,4,0,0,0-2.5-7.6Zm101,2.6a3.9,3.9,0,0,0-5-2.6L204,122.5V96a4,4,0,0,0-8,0v26.5l-25.2-8.2a4,4,0,0,0-2.5,7.6l25.2,8.2L178,151.5a4.1,4.1,0,0,0,.8,5.6,4.3,4.3,0,0,0,2.4.8,4,4,0,0,0,3.2-1.7L200,134.8l15.6,21.4a4,4,0,0,0,3.2,1.7,4.3,4.3,0,0,0,2.4-.8,4.1,4.1,0,0,0,.8-5.6l-15.5-21.4,25.2-8.2A3.9,3.9,0,0,0,234.2,116.9Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M48,56V200a8,8,0,0,1-16,0V56a8,8,0,0,1,16,0Zm84,54.5L112,117V96a8,8,0,0,0-16,0v21l-20-6.5a7.8,7.8,0,0,0-10,5.1,7.9,7.9,0,0,0,5.1,10.1l20,6.5-12.4,17a8,8,0,0,0,1.8,11.2,8.1,8.1,0,0,0,11.2-1.8l12.3-17,12.3,17a8.1,8.1,0,0,0,11.2,1.8,8,8,0,0,0,1.8-11.2l-12.4-17,20-6.5a7.9,7.9,0,0,0,5.1-10.1A7.8,7.8,0,0,0,132,110.5Zm106,5.1a7.8,7.8,0,0,0-10-5.1L208,117V96a8,8,0,0,0-16,0v21l-20-6.5a7.8,7.8,0,0,0-10,5.1,7.9,7.9,0,0,0,5.1,10.1l20,6.5-12.4,17a8,8,0,0,0,1.8,11.2,8.1,8.1,0,0,0,11.2-1.8l12.3-17,12.3,17a8.1,8.1,0,0,0,11.2,1.8,8,8,0,0,0,1.8-11.2l-12.4-17,20-6.5A7.9,7.9,0,0,0,238,115.6Z" />
        </>
      )}
    </svg>
  );
}

export function PhPath(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M200,164a36,36,0,0,0-33.9,24H72a28,28,0,0,1,0-56h96a44,44,0,0,0,0-88H72a12,12,0,0,0,0,24h96a20,20,0,0,1,0,40H72a52,52,0,0,0,0,104h94.1A36,36,0,1,0,200,164Zm0,48a12,12,0,1,1,12-12A12,12,0,0,1,200,212Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="200" cy="200" r="24" />
          </g>
          <path d="M200,168a32.1,32.1,0,0,0-31,24H72a32,32,0,0,1,0-64h96a40,40,0,0,0,0-80H72a8,8,0,0,0,0,16h96a24,24,0,0,1,0,48H72a48,48,0,0,0,0,96h97a32,32,0,1,0,31-40Zm0,48a16,16,0,1,1,16-16A16,16,0,0,1,200,216Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M232,200a32,32,0,0,1-63,8H72a48,48,0,0,1,0-96h96a24,24,0,0,0,0-48H72a8,8,0,0,1,0-16h96a40,40,0,0,1,0,80H72a32,32,0,0,0,0,64h97a32,32,0,0,1,63,8Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M200,170a30,30,0,0,0-29.4,24H72a34,34,0,0,1,0-68h96a38,38,0,0,0,0-76H72a6,6,0,0,0,0,12h96a26,26,0,0,1,0,52H72a46,46,0,0,0,0,92h98.6A30,30,0,1,0,200,170Zm0,48a18,18,0,1,1,18-18A18.1,18.1,0,0,1,200,218Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M200,172a27.9,27.9,0,0,0-27.7,24H72a36,36,0,0,1,0-72h96a36,36,0,0,0,0-72H72a4,4,0,0,0,0,8h96a28,28,0,0,1,0,56H72a44,44,0,0,0,0,88H172.3A28,28,0,1,0,200,172Zm0,48a20,20,0,1,1,20-20A20.1,20.1,0,0,1,200,220Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M200,168a32.1,32.1,0,0,0-31,24H72a32,32,0,0,1,0-64h96a40,40,0,0,0,0-80H72a8,8,0,0,0,0,16h96a24,24,0,0,1,0,48H72a48,48,0,0,0,0,96h97a32,32,0,1,0,31-40Zm0,48a16,16,0,1,1,16-16A16,16,0,0,1,200,216Z" />
        </>
      )}
    </svg>
  );
}

export function PhPauseCircle(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,20A108,108,0,1,0,236,128,108.1,108.1,0,0,0,128,20Zm0,192a84,84,0,1,1,84-84A84.1,84.1,0,0,1,128,212ZM116,96v64a12,12,0,0,1-24,0V96a12,12,0,0,1,24,0Zm48,0v64a12,12,0,0,1-24,0V96a12,12,0,0,1,24,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="128" cy="128" r="96" />
          </g>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Z" />
          <path d="M104,88a8,8,0,0,0-8,8v64a8,8,0,0,0,16,0V96A8,8,0,0,0,104,88Z" />
          <path d="M152,88a8,8,0,0,0-8,8v64a8,8,0,0,0,16,0V96A8,8,0,0,0,152,88Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24ZM112,160a8,8,0,0,1-16,0V96a8,8,0,0,1,16,0Zm48,0a8,8,0,0,1-16,0V96a8,8,0,0,1,16,0Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,26A102,102,0,1,0,230,128,102.2,102.2,0,0,0,128,26Zm0,192a90,90,0,1,1,90-90A90.1,90.1,0,0,1,128,218ZM110,96v64a6,6,0,0,1-12,0V96a6,6,0,0,1,12,0Zm48,0v64a6,6,0,0,1-12,0V96a6,6,0,0,1,12,0Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,28A100,100,0,1,0,228,128,100.2,100.2,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220ZM108,96v64a4,4,0,0,1-8,0V96a4,4,0,0,1,8,0Zm48,0v64a4,4,0,0,1-8,0V96a4,4,0,0,1,8,0Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216ZM112,96v64a8,8,0,0,1-16,0V96a8,8,0,0,1,16,0Zm48,0v64a8,8,0,0,1-16,0V96a8,8,0,0,1,16,0Z" />
        </>
      )}
    </svg>
  );
}

export function PhPause(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M200,28H164a20.1,20.1,0,0,0-20,20V208a20.1,20.1,0,0,0,20,20h36a20.1,20.1,0,0,0,20-20V48A20.1,20.1,0,0,0,200,28Zm-4,176H168V52h28ZM92,28H56A20.1,20.1,0,0,0,36,48V208a20.1,20.1,0,0,0,20,20H92a20.1,20.1,0,0,0,20-20V48A20.1,20.1,0,0,0,92,28ZM88,204H60V52H88Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect x="156" y="40" width="52" height="176" rx="8" />
          </g>
          <g opacity="0.2">
            <rect x="48" y="40" width="52" height="176" rx="8" />
          </g>
          <path d="M200,32H164a16,16,0,0,0-16,16V208a16,16,0,0,0,16,16h36a16,16,0,0,0,16-16V48A16,16,0,0,0,200,32Zm0,176H164V48h36ZM92,32H56A16,16,0,0,0,40,48V208a16,16,0,0,0,16,16H92a16,16,0,0,0,16-16V48A16,16,0,0,0,92,32Zm0,176H56V48H92Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,48V208a16,16,0,0,1-16,16H164a16,16,0,0,1-16-16V48a16,16,0,0,1,16-16h36A16,16,0,0,1,216,48ZM92,32H56A16,16,0,0,0,40,48V208a16,16,0,0,0,16,16H92a16,16,0,0,0,16-16V48A16,16,0,0,0,92,32Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M200,34H164a14,14,0,0,0-14,14V208a14,14,0,0,0,14,14h36a14,14,0,0,0,14-14V48A14,14,0,0,0,200,34Zm2,174a2,2,0,0,1-2,2H164a2,2,0,0,1-2-2V48a2,2,0,0,1,2-2h36a2,2,0,0,1,2,2ZM92,34H56A14,14,0,0,0,42,48V208a14,14,0,0,0,14,14H92a14,14,0,0,0,14-14V48A14,14,0,0,0,92,34Zm2,174a2,2,0,0,1-2,2H56a2,2,0,0,1-2-2V48a2,2,0,0,1,2-2H92a2,2,0,0,1,2,2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M200,36H164a12,12,0,0,0-12,12V208a12,12,0,0,0,12,12h36a12,12,0,0,0,12-12V48A12,12,0,0,0,200,36Zm4,172a4,4,0,0,1-4,4H164a4,4,0,0,1-4-4V48a4,4,0,0,1,4-4h36a4,4,0,0,1,4,4ZM92,36H56A12,12,0,0,0,44,48V208a12,12,0,0,0,12,12H92a12,12,0,0,0,12-12V48A12,12,0,0,0,92,36Zm4,172a4,4,0,0,1-4,4H56a4,4,0,0,1-4-4V48a4,4,0,0,1,4-4H92a4,4,0,0,1,4,4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M200,32H164a16,16,0,0,0-16,16V208a16,16,0,0,0,16,16h36a16,16,0,0,0,16-16V48A16,16,0,0,0,200,32Zm0,176H164V48h36ZM92,32H56A16,16,0,0,0,40,48V208a16,16,0,0,0,16,16H92a16,16,0,0,0,16-16V48A16,16,0,0,0,92,32Zm0,176H56V48H92Z" />
        </>
      )}
    </svg>
  );
}

export function PhPawPrint(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M189,145.3a31,31,0,0,1-14.9-18.7,48,48,0,0,0-92.2,0A31,31,0,0,1,67,145.3,44,44,0,0,0,88,228a45.3,45.3,0,0,0,17.1-3.4,59.6,59.6,0,0,1,45.8,0A45.3,45.3,0,0,0,168,228a44,44,0,0,0,21-82.7ZM168,204a19.1,19.1,0,0,1-7.8-1.6h-.1a83.7,83.7,0,0,0-64.2,0h-.1A19.1,19.1,0,0,1,88,204a20,20,0,0,1-9.5-37.6h.1a55.4,55.4,0,0,0,26.4-33,23.9,23.9,0,0,1,46,0,55.4,55.4,0,0,0,26.4,33h.1A20,20,0,0,1,168,204Zm64-100a24,24,0,1,1-24-24A23.9,23.9,0,0,1,232,104ZM48,128a24,24,0,1,1,24-24A23.9,23.9,0,0,1,48,128ZM72,56A24,24,0,1,1,96,80,23.9,23.9,0,0,1,72,56Zm64,0a24,24,0,1,1,24,24A23.9,23.9,0,0,1,136,56Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M232,108a20,20,0,1,1-20-20A20.1,20.1,0,0,1,232,108ZM64,108a20,20,0,1,0-20,20A20.1,20.1,0,0,0,64,108ZM92,80A20,20,0,1,0,72,60,20.1,20.1,0,0,0,92,80Zm72,0a20,20,0,1,0-20-20A20.1,20.1,0,0,0,164,80Zm19.2,75.9a43.6,43.6,0,0,1-20.6-26h0a36,36,0,0,0-69.2,0h0a43.6,43.6,0,0,1-20.6,26A32,32,0,0,0,88,216a32.4,32.4,0,0,0,12.5-2.5,71.8,71.8,0,0,1,55,0A32.4,32.4,0,0,0,168,216a32,32,0,0,0,15.2-60.1Z"
            opacity="0.2"
          />
          <path d="M212,80a28,28,0,1,0,28,28A28.1,28.1,0,0,0,212,80Zm0,40a12,12,0,1,1,12-12A12,12,0,0,1,212,120ZM72,108a28,28,0,1,0-28,28A28.1,28.1,0,0,0,72,108ZM44,120a12,12,0,1,1,12-12A12,12,0,0,1,44,120ZM92,88A28,28,0,1,0,64,60,28.1,28.1,0,0,0,92,88Zm0-40A12,12,0,1,1,80,60,12,12,0,0,1,92,48Zm72,40a28,28,0,1,0-28-28A28.1,28.1,0,0,0,164,88Zm0-40a12,12,0,1,1-12,12A12,12,0,0,1,164,48Zm23.1,100.8a35.3,35.3,0,0,1-16.9-21.1,43.9,43.9,0,0,0-84.4,0A35.5,35.5,0,0,1,69,148.8,40,40,0,0,0,88,224a40.5,40.5,0,0,0,15.5-3.1,64.2,64.2,0,0,1,48.9-.1A39.6,39.6,0,0,0,168,224a40,40,0,0,0,19.1-75.2ZM168,208a24.1,24.1,0,0,1-9.5-1.9A78.7,78.7,0,0,0,128,200a79.9,79.9,0,0,0-30.6,6.1A23.2,23.2,0,0,1,88,208a24,24,0,0,1-11.3-45.2,51.1,51.1,0,0,0,24.4-30.6,28,28,0,0,1,53.8,0,51.1,51.1,0,0,0,24.4,30.6h.1A24,24,0,0,1,168,208Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M136,60a28,28,0,1,1,28,28A28.1,28.1,0,0,1,136,60ZM72,108a28,28,0,1,0-28,28A28.1,28.1,0,0,0,72,108ZM92,88A28,28,0,1,0,64,60,28.1,28.1,0,0,0,92,88Zm95.1,60.8a35.3,35.3,0,0,1-16.9-21.1,43.9,43.9,0,0,0-84.4,0A35.5,35.5,0,0,1,69,148.8,40,40,0,0,0,88,224a40.5,40.5,0,0,0,15.5-3.1,64.2,64.2,0,0,1,48.9-.1A39.6,39.6,0,0,0,168,224a40,40,0,0,0,19.1-75.2ZM212,80a28,28,0,1,0,28,28A28.1,28.1,0,0,0,212,80Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M212,82a26,26,0,1,0,26,26A26.1,26.1,0,0,0,212,82Zm0,40a14,14,0,1,1,14-14A14,14,0,0,1,212,122ZM70,108a26,26,0,1,0-26,26A26.1,26.1,0,0,0,70,108ZM44,122a14,14,0,1,1,14-14A14,14,0,0,1,44,122ZM92,86A26,26,0,1,0,66,60,26.1,26.1,0,0,0,92,86Zm0-40A14,14,0,1,1,78,60,14,14,0,0,1,92,46Zm72,40a26,26,0,1,0-26-26A26.1,26.1,0,0,0,164,86Zm0-40a14,14,0,1,1-14,14A14,14,0,0,1,164,46Zm22.1,104.6a37.4,37.4,0,0,1-17.8-22.3,42,42,0,0,0-80.6,0,37.4,37.4,0,0,1-17.8,22.3A38,38,0,0,0,102.8,219a65.7,65.7,0,0,1,50.4,0,38,38,0,0,0,32.9-68.4ZM168,210a25.9,25.9,0,0,1-10.2-2.1A79.1,79.1,0,0,0,128,202a80,80,0,0,0-29.9,5.9A25,25,0,0,1,88,210a26,26,0,0,1-12.3-48.9,49.5,49.5,0,0,0,23.5-29.5,30,30,0,0,1,57.6,0,49.5,49.5,0,0,0,23.5,29.5h.1A26,26,0,0,1,168,210Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M212,84a24,24,0,1,0,24,24A24.1,24.1,0,0,0,212,84Zm0,40a16,16,0,1,1,16-16A16,16,0,0,1,212,124ZM68,108a24,24,0,1,0-24,24A24.1,24.1,0,0,0,68,108ZM44,124a16,16,0,1,1,16-16A16,16,0,0,1,44,124ZM92,84A24,24,0,1,0,68,60,24.1,24.1,0,0,0,92,84Zm0-40A16,16,0,1,1,76,60,16,16,0,0,1,92,44Zm72,40a24,24,0,1,0-24-24A24.1,24.1,0,0,0,164,84Zm0-40a16,16,0,1,1-16,16A16,16,0,0,1,164,44Zm21.2,108.3a39,39,0,0,1-18.8-23.5,40,40,0,0,0-76.8,0,39,39,0,0,1-18.7,23.5A36,36,0,0,0,88,220a36.8,36.8,0,0,0,14-2.8,67.9,67.9,0,0,1,52,0,36.8,36.8,0,0,0,14,2.8,36,36,0,0,0,17.2-67.7ZM168,212a27.7,27.7,0,0,1-11-2.2,75.1,75.1,0,0,0-29-5.8,75.9,75.9,0,0,0-29.1,5.8A26.9,26.9,0,0,1,88,212a28,28,0,0,1-13.3-52.6,47.6,47.6,0,0,0,22.6-28.3,32,32,0,0,1,61.4,0,47.6,47.6,0,0,0,22.6,28.3h0A28,28,0,0,1,168,212Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M212,80a28,28,0,1,0,28,28A28.1,28.1,0,0,0,212,80Zm0,40a12,12,0,1,1,12-12A12,12,0,0,1,212,120ZM72,108a28,28,0,1,0-28,28A28.1,28.1,0,0,0,72,108ZM44,120a12,12,0,1,1,12-12A12,12,0,0,1,44,120ZM92,88A28,28,0,1,0,64,60,28.1,28.1,0,0,0,92,88Zm0-40A12,12,0,1,1,80,60,12,12,0,0,1,92,48Zm72,40a28,28,0,1,0-28-28A28.1,28.1,0,0,0,164,88Zm0-40a12,12,0,1,1-12,12A12,12,0,0,1,164,48Zm23.1,100.8a35.3,35.3,0,0,1-16.9-21.1,43.9,43.9,0,0,0-84.4,0A35.5,35.5,0,0,1,69,148.8,40,40,0,0,0,88,224a40.5,40.5,0,0,0,15.5-3.1,64.2,64.2,0,0,1,48.9-.1A39.6,39.6,0,0,0,168,224a40,40,0,0,0,19.1-75.2ZM168,208a24.1,24.1,0,0,1-9.5-1.9A78.7,78.7,0,0,0,128,200a79.9,79.9,0,0,0-30.6,6.1A23.2,23.2,0,0,1,88,208a24,24,0,0,1-11.3-45.2,51.1,51.1,0,0,0,24.4-30.6,28,28,0,0,1,53.8,0,51.1,51.1,0,0,0,24.4,30.6h.1A24,24,0,0,1,168,208Z" />
        </>
      )}
    </svg>
  );
}

export function PhPeace(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M217,189.2a108,108,0,1,0-178,0l.5.7.4.5a107.9,107.9,0,0,0,176.2,0l.4-.5ZM212,128a83.9,83.9,0,0,1-9,37.8l-63-44V44.9A84,84,0,0,1,212,128ZM116,44.9v76.9l-63,44A83.9,83.9,0,0,1,116,44.9ZM66.8,185.5,116,151.1v60A83.8,83.8,0,0,1,66.8,185.5ZM140,211.1v-60l49.2,34.4A83.8,83.8,0,0,1,140,211.1Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="128" cy="128" r="96" />
          </g>
          <path d="M213.6,186.9A102.9,102.9,0,0,0,232,128a104,104,0,0,0-208,0,102.9,102.9,0,0,0,18.4,58.9l.4.8.4.4a103.9,103.9,0,0,0,169.6,0l.4-.4A3,3,0,0,0,213.6,186.9ZM216,128a87.5,87.5,0,0,1-11.6,43.7L136,123.8V40.4A88.1,88.1,0,0,1,216,128ZM120,40.4v83.4L51.6,171.7A88,88,0,0,1,120,40.4ZM60.8,184.8,120,143.4v72.2A87.9,87.9,0,0,1,60.8,184.8ZM136,215.6V143.4l59.2,41.4A87.9,87.9,0,0,1,136,215.6Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M213.6,186.9A102.9,102.9,0,0,0,232,128a104,104,0,0,0-208,0,102.9,102.9,0,0,0,18.4,58.9l.4.8.4.4a103.9,103.9,0,0,0,169.6,0l.4-.4A3,3,0,0,0,213.6,186.9ZM216,128a87.5,87.5,0,0,1-11.6,43.7L136,123.8V40.4A88.1,88.1,0,0,1,216,128ZM120,40.4v83.4L51.6,171.7A88,88,0,0,1,120,40.4ZM60.8,184.8,120,143.4v72.2A87.9,87.9,0,0,1,60.8,184.8ZM136,215.6V143.4l59.2,41.4A87.9,87.9,0,0,1,136,215.6Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M211.9,185.9A102,102,0,1,0,26,128a101.2,101.2,0,0,0,18.1,57.9l.3.6.4.5a102,102,0,0,0,166.4,0l.4-.5ZM218,128a90.2,90.2,0,0,1-13,46.6l-71-49.7V38.2A90.2,90.2,0,0,1,218,128ZM122,38.2v86.7L51,174.6A90,90,0,0,1,122,38.2ZM57.9,184.4,122,139.5v78.3A90.2,90.2,0,0,1,57.9,184.4ZM134,217.8V139.5l64.1,44.9A90.2,90.2,0,0,1,134,217.8Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M210.1,185A100,100,0,1,0,28,128a99.2,99.2,0,0,0,17.9,57l.2.4.2.2a99.9,99.9,0,0,0,163.4,0l.2-.2Zm9.9-57a91.6,91.6,0,0,1-14.4,49.4L132,125.9V36.1A92.1,92.1,0,0,1,220,128ZM124,36.1v89.8L50.4,177.4A92,92,0,0,1,124,36.1ZM55,184l69-48.3v84.2A91.9,91.9,0,0,1,55,184Zm77,35.9V135.7L201,184A91.9,91.9,0,0,1,132,219.9Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M213.6,186.9A102.9,102.9,0,0,0,232,128a104,104,0,0,0-208,0,102.9,102.9,0,0,0,18.4,58.9l.4.8.4.4a103.9,103.9,0,0,0,169.6,0l.4-.4A3,3,0,0,0,213.6,186.9ZM216,128a87.5,87.5,0,0,1-11.6,43.7L136,123.8V40.4A88.1,88.1,0,0,1,216,128ZM120,40.4v83.4L51.6,171.7A88,88,0,0,1,120,40.4ZM60.8,184.8,120,143.4v72.2A87.9,87.9,0,0,1,60.8,184.8ZM136,215.6V143.4l59.2,41.4A87.9,87.9,0,0,1,136,215.6Z" />
        </>
      )}
    </svg>
  );
}

export function PhPenNibStraight(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M224.3,126.5,196,69.2V32a20.1,20.1,0,0,0-20-20H80A20.1,20.1,0,0,0,60,32V69.2L31.7,126.5a12.2,12.2,0,0,0,1.2,12.6l85.6,112.2a12,12,0,0,0,19,0l85.6-112.2A12,12,0,0,0,224.3,126.5ZM172,36V60H84V36ZM128,144a12,12,0,1,1,12-12A12,12,0,0,1,128,144Zm12,64.5V165.9a36,36,0,1,0-24,0v42.6L56.5,130.4,79.5,84h97l23,46.4Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M211.4,127.4,184,72H72L44.6,127.4a8.2,8.2,0,0,0,.7,8.3L128,248l82.7-112.3A8.2,8.2,0,0,0,211.4,127.4ZM128,152a20,20,0,1,1,20-20A20.1,20.1,0,0,1,128,152Z"
            opacity="0.2"
          />
          <path d="M218.6,123.9,192,70.1V32a16,16,0,0,0-16-16H80A16,16,0,0,0,64,32V70.1L37.4,123.9a16.1,16.1,0,0,0,1.5,16.6l82.7,112.2a7.9,7.9,0,0,0,12.8,0l82.7-112.2A16.1,16.1,0,0,0,218.6,123.9ZM176,32V64H80V32ZM128,144a12,12,0,1,1,12-12A12,12,0,0,1,128,144Zm8,79.6V158.8a28,28,0,1,0-16,0v64.8L51.8,131,77,80H179l25.2,51Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,148a16,16,0,1,1,16-16A16,16,0,0,1,128,148Zm89.1-7.5L143.2,240.8a4,4,0,0,1-7.2-2.4V163a32,32,0,1,0-16,0v75.4a4,4,0,0,1-7.2,2.4L38.9,140.5a16.1,16.1,0,0,1-1.5-16.6L64,70.1V32A16,16,0,0,1,80,16h96a16,16,0,0,1,16,16V70.1l26.6,53.8A16.1,16.1,0,0,1,217.1,140.5ZM176,32H80V64h96Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M216.8,124.8,190,70.6V32a14,14,0,0,0-14-14H80A14,14,0,0,0,66,32V70.6L39.2,124.8a14.1,14.1,0,0,0,1.3,14.5l82.7,112.3a6,6,0,0,0,9.6,0l82.7-112.3A14.1,14.1,0,0,0,216.8,124.8ZM80,30h96a2,2,0,0,1,2,2V66H78V32A2,2,0,0,1,80,30Zm48,116a14,14,0,1,1,14-14A14,14,0,0,1,128,146Zm77.8-13.8L134,229.7V157.3a26,26,0,1,0-12,0v72.4L50.2,132.2a2,2,0,0,1-.2-2.1L75.7,78H180.3L206,130.1A2,2,0,0,1,205.8,132.2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M215,125.7,188,71.1V32a12,12,0,0,0-12-12H80A12,12,0,0,0,68,32V71.1L41,125.7a12.1,12.1,0,0,0,1.1,12.4l82.7,112.3a4,4,0,0,0,6.4,0l82.7-112.3A11.8,11.8,0,0,0,215,125.7ZM80,28h96a4,4,0,0,1,4,4V68H76V32A4,4,0,0,1,80,28Zm48,120a16,16,0,1,1,16-16A16,16,0,0,1,128,148Zm79.4-14.7L132,235.8V155.7a24,24,0,1,0-8,0v80.1L48.6,133.3a3.8,3.8,0,0,1-.4-4.1L74.5,76h107l26.3,53.2A3.8,3.8,0,0,1,207.4,133.3Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M218.6,123.9,192,70.1V32a16,16,0,0,0-16-16H80A16,16,0,0,0,64,32V70.1L37.4,123.9a16.1,16.1,0,0,0,1.5,16.6l82.7,112.2a7.9,7.9,0,0,0,12.8,0l82.7-112.2A16.1,16.1,0,0,0,218.6,123.9ZM176,32V64H80V32ZM128,144a12,12,0,1,1,12-12A12,12,0,0,1,128,144Zm8,79.6V158.8a28,28,0,1,0-16,0v64.8L51.8,131,77,80H179l25.2,51Z" />
        </>
      )}
    </svg>
  );
}

export function PhPenNib(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M234.8,89.9,166.1,21.2a19.9,19.9,0,0,0-28.2,0L113.4,45.7,56.1,67.1A20.2,20.2,0,0,0,43.4,82.6L20.2,222A11.9,11.9,0,0,0,32,236l2-.2,139.4-23.2a20.2,20.2,0,0,0,15.5-12.7l21.4-57.2,24.5-24.6A19.9,19.9,0,0,0,234.8,89.9Zm-67.6,99.4L67,206l33.5-33.5a36,36,0,1,0-17-17L50,189,66.7,88.8,117,70l69.1,69ZM104,140a12,12,0,1,1,12,12A12,12,0,0,1,104,140Zm96-21L137,56l15-15,63,63Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M120,56,60.3,78.4a8.1,8.1,0,0,0-5.1,6.2L32,224l139.5-23.2a7.9,7.9,0,0,0,6.1-5.1L200,136Zm-4,104a20,20,0,1,1,20-20A20.1,20.1,0,0,1,116,160Z"
            opacity="0.2"
          />
          <path d="M232,92.7,163.3,24a16.1,16.1,0,0,0-22.6,0L115.6,49.1,57.5,70.9A15.9,15.9,0,0,0,47.4,83.2L24.1,222.7A7.9,7.9,0,0,0,32,232h1.3l139.5-23.2a16.2,16.2,0,0,0,12.3-10.2l21.8-58.1L232,115.3A16.1,16.1,0,0,0,232,92.7ZM170.1,192.9,55.3,212l47.4-47.4A27.1,27.1,0,0,0,116,168a28,28,0,1,0-28-28,27.1,27.1,0,0,0,3.4,13.3L44,200.7,63.1,85.9,118,65.3,190.7,138ZM104,140a12,12,0,1,1,12,12A12,12,0,0,1,104,140Zm96-15.3L131.3,56,152,35.3,220.7,104Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M232,92.7,163.3,24a16.1,16.1,0,0,0-22.6,0L115.6,49.1,57.5,70.9A15.9,15.9,0,0,0,47.4,83.2L26.6,207.8a4,4,0,0,0,6.8,3.5l55-55.1A31.7,31.7,0,0,1,84,140a32,32,0,1,1,32,32,31.7,31.7,0,0,1-16.2-4.4l-55.1,55a4,4,0,0,0,3.5,6.8l124.6-20.7a16.2,16.2,0,0,0,12.3-10.2l21.8-58.1L232,115.3a15.9,15.9,0,0,0,0-22.6Zm-32,32L131.3,56,152,35.3,220.7,104ZM116,156a16,16,0,1,1,16-16A16,16,0,0,1,116,156Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M234.7,104a14.1,14.1,0,0,0-4.1-9.9L161.9,25.4a14.1,14.1,0,0,0-9.9-4.1,14.3,14.3,0,0,0-9.9,4.1L116.7,50.8l-58.5,22a14.1,14.1,0,0,0-8.9,10.8L26.1,223a5.9,5.9,0,0,0,1.7,5.2A5.6,5.6,0,0,0,32,230h1l139.4-23.2a13.7,13.7,0,0,0,10.8-8.9l22-58.5,25.4-25.4A14.1,14.1,0,0,0,234.7,104ZM172,193.6a1.9,1.9,0,0,1-1.5,1.2L49.5,215l52.9-52.9A25.2,25.2,0,0,0,116,166a26,26,0,1,0-26-26,25.2,25.2,0,0,0,3.9,13.6L41,206.5l20.2-121A1.9,1.9,0,0,1,62.4,84l56.1-21L193,137.5ZM102,140a14,14,0,1,1,14,14A14,14,0,0,1,102,140Zm120.1-34.6L200,127.5,128.5,56l22.1-22.1a1.9,1.9,0,0,1,2.8,0l68.7,68.7A1.9,1.9,0,0,1,222.1,105.4Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M232.7,104a12.3,12.3,0,0,0-3.5-8.5L160.5,26.8a12.1,12.1,0,0,0-17,0L117.8,52.6l-58.9,22a12.1,12.1,0,0,0-7.6,9.3L28.1,223.3A4,4,0,0,0,32,228h.7l139.4-23.2a12.1,12.1,0,0,0,9.3-7.6l22-58.9,25.8-25.7A12.3,12.3,0,0,0,232.7,104Zm-58.8,90.3a4,4,0,0,1-3.1,2.5L43.7,218l58.4-58.4A24.4,24.4,0,0,0,116,164a24,24,0,1,0-24-24,24.4,24.4,0,0,0,4.4,13.9L38,212.3,59.2,85.2a4,4,0,0,1,2.5-3.1L119,60.7,195.4,137ZM100,140a16,16,0,1,1,16,16A16,16,0,0,1,100,140Zm123.5-33.2L200,130.3,125.7,56l23.5-23.5a3.9,3.9,0,0,1,5.6,0l68.7,68.7A3.9,3.9,0,0,1,223.5,106.8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M232,92.7,163.3,24a16.1,16.1,0,0,0-22.6,0L115.6,49.1,57.5,70.9A15.9,15.9,0,0,0,47.4,83.2L24.1,222.7A7.9,7.9,0,0,0,32,232h1.3l139.5-23.2a16.2,16.2,0,0,0,12.3-10.2l21.8-58.1L232,115.3A16.1,16.1,0,0,0,232,92.7ZM170.1,192.9,55.3,212l47.4-47.4A27.1,27.1,0,0,0,116,168a28,28,0,1,0-28-28,27.1,27.1,0,0,0,3.4,13.3L44,200.7,63.1,85.9,118,65.3,190.7,138ZM104,140a12,12,0,1,1,12,12A12,12,0,0,1,104,140Zm96-15.3L131.3,56,152,35.3,220.7,104Z" />
        </>
      )}
    </svg>
  );
}

export function PhPen(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M232.7,88a19.7,19.7,0,0,0-5.9-14.1L182.1,29.2a19.9,19.9,0,0,0-28.2,0l-120,120A19.7,19.7,0,0,0,28,163.3V208a20.1,20.1,0,0,0,20,20H92.7a19.7,19.7,0,0,0,14.1-5.9l1.7-1.6h0l77.8-77.8,4.1,13.9-34.9,34.9a12,12,0,0,0,0,17,12.1,12.1,0,0,0,17,0l40-40a11.9,11.9,0,0,0,3-11.9l-9.9-33.2,21.2-21.3A19.7,19.7,0,0,0,232.7,88ZM100,195,61,156l75-75,39,39ZM52,181l23,23H52Zm140-78L153,64l15-15,39,39Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M192,120,136,64l26.3-26.3a8,8,0,0,1,11.4,0l44.6,44.6a8,8,0,0,1,0,11.4Z" />
          </g>
          <path d="M228.7,88A15.9,15.9,0,0,0,224,76.7L179.3,32a16.1,16.1,0,0,0-22.6,0L36.7,152a15.4,15.4,0,0,0-3.6,5.5c-.1.2-.1.4-.2.6a15,15,0,0,0-.9,5.2V208a16,16,0,0,0,16,16H92.7a16,16,0,0,0,5.3-.9l.5-.2a15.4,15.4,0,0,0,5.5-3.6l83.7-83.7,3.4,13.9-36.8,36.8a8.1,8.1,0,0,0,0,11.4,8.2,8.2,0,0,0,11.4,0l40-40a8.5,8.5,0,0,0,2.1-7.6l-6.9-27.6L224,99.3A15.9,15.9,0,0,0,228.7,88ZM48,179.3,76.7,208H48Zm48,25.4L51.3,160,136,75.3,180.7,120Zm96-96L147.3,64,168,43.3,212.7,88Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M228.7,88A15.9,15.9,0,0,0,224,76.7L179.3,32a16.1,16.1,0,0,0-22.6,0L36.7,152a16,16,0,0,0-3.6,5.4l-.3.8a17,17,0,0,0-.8,5.1V208a16,16,0,0,0,16,16H92.7a14.4,14.4,0,0,0,5.1-.9l.8-.2a16,16,0,0,0,5.4-3.6l83.7-83.7,3.4,13.9-36.8,36.8a8.1,8.1,0,0,0,0,11.4,8.2,8.2,0,0,0,11.4,0l40-40a8.5,8.5,0,0,0,2.1-7.6l-6.9-27.6L224,99.3A15.9,15.9,0,0,0,228.7,88ZM192,108.7,147.3,64,168,43.3,212.7,88Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M226.7,88a14.1,14.1,0,0,0-4.1-9.9L177.9,33.4a14.1,14.1,0,0,0-19.8,0l-120,120a13.7,13.7,0,0,0-3.1,4.8h-.1a14.3,14.3,0,0,0-.9,5V208a14,14,0,0,0,14,14H92.7a14.3,14.3,0,0,0,5-.9h.1a13.7,13.7,0,0,0,4.8-3.1l86.1-86.2,4.7,18.5-37.6,37.6a5.9,5.9,0,1,0,8.4,8.4l40-40a5.9,5.9,0,0,0,1.6-5.7l-7.2-28.7,24-23.9A14.1,14.1,0,0,0,226.7,88ZM46,208V174.5L81.5,210H48A2,2,0,0,1,46,208Zm50-.5L48.5,160,136,72.5,183.5,120ZM214.1,89.4,192,111.5,144.5,64l22.1-22.1a1.9,1.9,0,0,1,2.8,0l44.7,44.7a1.9,1.9,0,0,1,0,2.8Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M224.7,88a12.3,12.3,0,0,0-3.5-8.5L176.5,34.8a12.2,12.2,0,0,0-17,0l-120,120a11.7,11.7,0,0,0-2.7,4.2h0a11.6,11.6,0,0,0-.8,4.2V208a12,12,0,0,0,12,12H92.7a11.6,11.6,0,0,0,4.2-.8H97a11.7,11.7,0,0,0,4.2-2.7l88.6-88.7,5.8,23-38.4,38.4a3.9,3.9,0,0,0,0,5.6,3.9,3.9,0,0,0,5.6,0l40-40a4.1,4.1,0,0,0,1.1-3.8l-7.5-29.8,24.8-24.7A12.3,12.3,0,0,0,224.7,88ZM44,208V169.7L86.3,212H48A4,4,0,0,1,44,208Zm52,2.3L45.7,160,136,69.7,186.3,120ZM215.5,90.8,192,114.3,141.7,64l23.5-23.5a3.9,3.9,0,0,1,5.6,0l44.7,44.7a3.9,3.9,0,0,1,0,5.6Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M228.7,88A15.9,15.9,0,0,0,224,76.7L179.3,32a16.1,16.1,0,0,0-22.6,0L36.7,152a16,16,0,0,0-3.6,5.4l-.3.8a17,17,0,0,0-.8,5.1V208a16,16,0,0,0,16,16H92.7a14.4,14.4,0,0,0,5.1-.9l.8-.2a16,16,0,0,0,5.4-3.6l83.7-83.7,3.4,13.9-36.8,36.8a8.1,8.1,0,0,0,0,11.4,8.2,8.2,0,0,0,11.4,0l40-40a8.5,8.5,0,0,0,2.1-7.6l-6.9-27.6L224,99.3A15.9,15.9,0,0,0,228.7,88ZM48,179.3,76.7,208H48Zm48,25.4L51.3,160,136,75.3,180.7,120Zm96-96L147.3,64,168,43.3,212.7,88Z" />
        </>
      )}
    </svg>
  );
}

export function PhPencilCircle(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M182.2,221.4a112,112,0,0,0,22.2-17,108,108,0,1,0-152.8,0,111.4,111.4,0,0,0,22.1,17l.8.4a107.8,107.8,0,0,0,52.2,14.1h2.6a107.8,107.8,0,0,0,52.2-14.1ZM106.1,148h43.8l7.5,16.4a31.9,31.9,0,0,0-5.4-.4,36,36,0,0,0-24,9.2,36,36,0,0,0-24-9.2,31.9,31.9,0,0,0-5.4.4Zm11.1-24L128,100.6,138.8,124ZM92,200a12,12,0,0,1,24,0v11.1a86.1,86.1,0,0,1-24-7.2Zm48,0a12,12,0,0,1,24,0v3.9a86.1,86.1,0,0,1-24,7.2ZM44,128a84,84,0,1,1,144,58.8V176a12.2,12.2,0,0,0-1.1-5l-48-104a12,12,0,0,0-21.8,0l-48,104a12.2,12.2,0,0,0-1.1,5v10.8A83.5,83.5,0,0,1,44,128Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M80,211.2a96,96,0,1,1,96,0h0V176L128,72,80,176v35.2Z" />
          </g>
          <path d="M201.5,54.5a103.8,103.8,0,0,0-147,0,103.8,103.8,0,0,0,0,147,103.8,103.8,0,0,0,147,0,103.8,103.8,0,0,0,0-147ZM88,192a16,16,0,0,1,32,0v23.6a89,89,0,0,1-32-9.2Zm48,0a16,16,0,0,1,32,0v14.4a89,89,0,0,1-32,9.2Zm-28.7-56h41.4l11.6,25.1a31.9,31.9,0,0,0-32.3,9.8,31.9,31.9,0,0,0-32.3-9.8Zm7.4-16L128,91.1,141.3,120Zm75.5,70.2c-2,2-4.1,3.9-6.2,5.7V176a9.8,9.8,0,0,0-.7-3.4l-48-104a8.1,8.1,0,0,0-14.6,0l-48,104A9.8,9.8,0,0,0,72,176v19.9c-2.1-1.8-4.2-3.7-6.2-5.7a88,88,0,1,1,124.4,0Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M201.5,54.5a103.8,103.8,0,0,0-147,0,103.8,103.8,0,0,0,0,147,103.8,103.8,0,0,0,147,0,103.8,103.8,0,0,0,0-147ZM128,170.9a31.9,31.9,0,0,0-32.3-9.8L111,128h34l15.3,33.1a31.9,31.9,0,0,0-32.3,9.8Zm40,35.5a89,89,0,0,1-32,9.2V192a16,16,0,0,1,32,0Zm22.2-16.2c-2,2-4.1,3.9-6.2,5.7V176a9.8,9.8,0,0,0-.7-3.4l-48-104h-.1a4.1,4.1,0,0,0-.5-.9c0-.1,0-.1-.1-.2l-.5-.7H134a4.6,4.6,0,0,0-.7-.7h-.2l-.7-.6h-.2l-.8-.5h-.1l-.9-.3h-.3l-.9-.2h-2.4l-1,.2h-.2l-.8.3h-.2l-.9.4h-.1l-.7.6h-.2l-.7.7h-.1l-.5.7-.2.2a4.1,4.1,0,0,1-.5.9l-22.1,48h0L72.7,172.6A9.8,9.8,0,0,0,72,176v19.9c-2.1-1.8-4.2-3.7-6.2-5.7a88,88,0,1,1,124.4,0Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M200.1,55.9A102,102,0,1,0,55.9,200.1,102,102,0,1,0,200.1,55.9ZM106,134h44l14.1,30.6A28.9,28.9,0,0,0,152,162a30,30,0,0,0-24,12,30,30,0,0,0-24-12,28.9,28.9,0,0,0-12.1,2.6Zm5.5-12L128,86.3,144.5,122ZM134,192a18,18,0,0,1,36,0v15.6a89.9,89.9,0,0,1-36,10.2Zm-48,0a18,18,0,0,1,36,0v25.8a89.9,89.9,0,0,1-36-10.2Zm105.6-.4A76,76,0,0,1,182,200V176a5.4,5.4,0,0,0-.6-2.5l-48-104a5.9,5.9,0,0,0-10.8,0l-48,104A5.4,5.4,0,0,0,74,176v24a76,76,0,0,1-9.6-8.4,90,90,0,1,1,127.2,0Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M198.7,57.3A100,100,0,1,0,57.3,198.7,100,100,0,1,0,198.7,57.3Zm-94,74.7h46.6l17.3,37.4a28.1,28.1,0,0,0-40.6,8.2,28.1,28.1,0,0,0-40.6-8.2Zm3.7-8L128,81.5,147.6,124ZM132,192a20,20,0,0,1,40,0v16.8a90.8,90.8,0,0,1-40,11.1Zm-48,0a20,20,0,0,1,40,0v27.9a90.8,90.8,0,0,1-40-11.1Zm109.1,1.1A102.9,102.9,0,0,1,180,203.9V176a3.3,3.3,0,0,0-.4-1.7l-48-104a4,4,0,0,0-7.2,0l-48,104A3.3,3.3,0,0,0,76,176v27.9a102.9,102.9,0,0,1-13.1-10.8,92,92,0,1,1,130.2,0Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M201.5,54.5a103.8,103.8,0,0,0-147,0,103.8,103.8,0,0,0,0,147,103.8,103.8,0,0,0,147,0,103.8,103.8,0,0,0,0-147ZM88,192a16,16,0,0,1,32,0v23.6a89,89,0,0,1-32-9.2Zm48,0a16,16,0,0,1,32,0v14.4a89,89,0,0,1-32,9.2Zm-28.7-56h41.4l11.6,25.1a31.9,31.9,0,0,0-32.3,9.8,31.9,31.9,0,0,0-32.3-9.8Zm7.4-16L128,91.1,141.3,120Zm75.5,70.2c-2,2-4.1,3.9-6.2,5.7V176a9.8,9.8,0,0,0-.7-3.4l-48-104a8.1,8.1,0,0,0-14.6,0l-48,104A9.8,9.8,0,0,0,72,176v19.9c-2.1-1.8-4.2-3.7-6.2-5.7a88,88,0,1,1,124.4,0Z" />
        </>
      )}
    </svg>
  );
}

export function PhPencilLine(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216,204H125l75.5-75.5h0l26.3-26.4a19.8,19.8,0,0,0,0-28.2L182.1,29.2a19.9,19.9,0,0,0-28.2,0l-120,120A19.8,19.8,0,0,0,28,163.3V208a20.1,20.1,0,0,0,20,20H216a12,12,0,0,0,0-24ZM61,156l75-75,11,11L72,167Zm103-47,11,11-75,75L89,184Zm4-60,39,39-15,15L153,64ZM52,181l11.5,11.5h0L75,204H52Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M218.3,93.7,192,120,136,64l26.3-26.3a8,8,0,0,1,11.4,0l44.6,44.6A8,8,0,0,1,218.3,93.7Z"
            opacity="0.2"
          />
          <path d="M216,208H115.3l82.4-82.3h0L224,99.3a15.9,15.9,0,0,0,0-22.6L179.3,32a16.1,16.1,0,0,0-22.6,0L36.7,152a15.4,15.4,0,0,0-3.6,5.5c-.1.2-.1.4-.2.6a15,15,0,0,0-.9,5.2V208a16,16,0,0,0,16,16H216a8,8,0,0,0,0-16ZM168,43.3,212.7,88,192,108.7,147.3,64Zm-32,32L152.7,92,68,176.7,51.3,160ZM48,208V179.3L76.7,208Zm31.3-20L164,103.3,180.7,120,96,204.7Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M224,76.7,179.3,32a15.9,15.9,0,0,0-22.6,0L36.7,152a15.4,15.4,0,0,0-3.6,5.5l-.2.5a16,16,0,0,0-.9,5.3V208a16,16,0,0,0,16,16H216a8,8,0,0,0,0-16H115.3L224,99.3A16.1,16.1,0,0,0,224,76.7Zm-80-9.4L160.7,84,68,176.7,51.3,160ZM48,208V179.3L76.7,208Zm48-3.3L79.3,188,172,95.3,188.7,112Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M226.7,88a14.1,14.1,0,0,0-4.1-9.9L177.9,33.4a14.1,14.1,0,0,0-19.8,0l-120,120a13.7,13.7,0,0,0-3.1,4.8h-.1a14.3,14.3,0,0,0-.9,5V208a14,14,0,0,0,14,14H216a6,6,0,0,0,0-12H110.5L222.6,97.9A14.1,14.1,0,0,0,226.7,88ZM76.5,188,164,100.5,183.5,120,96,207.5ZM68,179.5,48.5,160,136,72.5,155.5,92ZM46,208V174.5L81.5,210H48A2,2,0,0,1,46,208ZM214.1,89.4,192,111.5,144.5,64l22.1-22.1a1.9,1.9,0,0,1,2.8,0l44.7,44.7a1.9,1.9,0,0,1,0,2.8Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M194.8,122.8h0l26.4-26.3a12.1,12.1,0,0,0,0-17L176.5,34.8a12.2,12.2,0,0,0-17,0l-120,120a11.7,11.7,0,0,0-2.7,4.2h0a11.6,11.6,0,0,0-.8,4.2V208a12,12,0,0,0,12,12H216a4,4,0,0,0,0-8H105.7l89.1-89.2ZM165.2,40.5a3.9,3.9,0,0,1,5.6,0l44.7,44.7a3.9,3.9,0,0,1,0,5.6L192,114.3,141.7,64ZM73.7,188,164,97.7,186.3,120,96,210.3Zm-28-28L136,69.7,158.3,92,68,182.3ZM44,208V169.7l21.2,21.1h0L86.3,212H48A4,4,0,0,1,44,208Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,208H115.3l82.4-82.3h0L224,99.3a15.9,15.9,0,0,0,0-22.6L179.3,32a16.1,16.1,0,0,0-22.6,0L36.7,152a15.4,15.4,0,0,0-3.6,5.5c-.1.2-.1.4-.2.6a15,15,0,0,0-.9,5.2V208a16,16,0,0,0,16,16H216a8,8,0,0,0,0-16ZM79.3,188,164,103.3,180.7,120,96,204.7ZM136,75.3,152.7,92,68,176.7,51.3,160Zm32-32L212.7,88,192,108.7,147.3,64ZM48,179.3,76.7,208H48Z" />
        </>
      )}
    </svg>
  );
}

export function PhPencilSimpleLine(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216,204H125L226.8,102.1a19.8,19.8,0,0,0,0-28.2L182.1,29.2a19.9,19.9,0,0,0-28.2,0l-120,120A19.8,19.8,0,0,0,28,163.3V208a20.1,20.1,0,0,0,20,20H216a12,12,0,0,0,0-24ZM168,49l39,39-15,15L153,64ZM52,204V165l84-84,39,39L91,204Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M218.3,93.7,192,120,136,64l26.3-26.3a8,8,0,0,1,11.4,0l44.6,44.6A8,8,0,0,1,218.3,93.7Z"
            opacity="0.2"
          />
          <path d="M216,208H115.3L224,99.3a15.9,15.9,0,0,0,0-22.6L179.3,32a16.1,16.1,0,0,0-22.6,0L36.7,152A15.9,15.9,0,0,0,32,163.3V208a16,16,0,0,0,16,16H216a8,8,0,0,0,0-16ZM168,43.3,212.7,88,192,108.7,147.3,64ZM48,163.3l88-88L180.7,120l-88,88H48Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,208H115.3l82.4-82.3h0L224,99.3a15.9,15.9,0,0,0,0-22.6L179.3,32a16.1,16.1,0,0,0-22.6,0L36.7,152A15.9,15.9,0,0,0,32,163.3V208a16,16,0,0,0,16,16H216a8,8,0,0,0,0-16ZM48,208V163.3l96-96L188.7,112l-96,96Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M226.7,88a14.1,14.1,0,0,0-4.1-9.9L177.9,33.4a14.1,14.1,0,0,0-19.8,0l-120,120a14.3,14.3,0,0,0-4.1,9.9V208a14,14,0,0,0,14,14H216a6,6,0,0,0,0-12H110.5L222.6,97.9A14.1,14.1,0,0,0,226.7,88ZM46,208V163.3a2,2,0,0,1,.6-1.4L136,72.5,183.5,120l-90,90H48A2,2,0,0,1,46,208ZM214.1,89.4,192,111.5,144.5,64l22.1-22.1a1.9,1.9,0,0,1,2.8,0l44.7,44.7a1.9,1.9,0,0,1,0,2.8Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M221.2,96.5a12.1,12.1,0,0,0,0-17L176.5,34.8a12.2,12.2,0,0,0-17,0l-120,120a11.9,11.9,0,0,0-3.5,8.5V208a12,12,0,0,0,12,12H216a4,4,0,0,0,0-8H105.7Zm-56-56a3.9,3.9,0,0,1,5.6,0l44.7,44.7a3.9,3.9,0,0,1,0,5.6L192,114.3,141.7,64ZM44,208V163.3a3.6,3.6,0,0,1,1.2-2.8L136,69.7,186.3,120l-92,92H48A4,4,0,0,1,44,208Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,208H115.3l82.4-82.3h0L224,99.3a15.9,15.9,0,0,0,0-22.6L179.3,32a16.1,16.1,0,0,0-22.6,0L36.7,152A15.9,15.9,0,0,0,32,163.3V208a16,16,0,0,0,16,16H216a8,8,0,0,0,0-16ZM168,43.3,212.7,88,192,108.7,147.3,64ZM48,163.3l88-88L180.7,120l-88,88H48Z" />
        </>
      )}
    </svg>
  );
}

export function PhPencilSimple(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M226.8,73.9,182.1,29.2a19.8,19.8,0,0,0-28.2,0l-120,120A19.7,19.7,0,0,0,28,163.3V208a20.1,20.1,0,0,0,20,20H92.7a19.7,19.7,0,0,0,14.1-5.9l120-120A19.9,19.9,0,0,0,226.8,73.9ZM91,204H52V165l84-84,39,39ZM192,103,153,64l15-15,39,39Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M218.3,93.7,192,120,136,64l26.3-26.3a8,8,0,0,1,11.4,0l44.6,44.6A8,8,0,0,1,218.3,93.7Z"
            opacity="0.2"
          />
          <path d="M224,76.7,179.3,32a15.9,15.9,0,0,0-22.6,0L36.7,152A15.9,15.9,0,0,0,32,163.3V208a16,16,0,0,0,16,16H92.7a15.9,15.9,0,0,0,11.3-4.7l120-120A16.1,16.1,0,0,0,224,76.7ZM92.7,208H48V163.3l88-88L180.7,120ZM192,108.7,147.3,64,168,43.3,212.7,88Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M224,76.7,179.7,32.3a16.6,16.6,0,0,0-11.3-5A16,16,0,0,0,156.7,32L130.3,58.3h0L36.7,152A15.9,15.9,0,0,0,32,163.3V208a16,16,0,0,0,16,16H92.7a16.1,16.1,0,0,0,11.3-4.7l120-120A16.1,16.1,0,0,0,224,76.7Zm-32,32L147.3,64,168,43.3,212.7,88Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M222.6,78.1,177.9,33.4a14,14,0,0,0-19.8,0l-120,120a14.3,14.3,0,0,0-4.1,9.9V208a14,14,0,0,0,14,14H92.7a14.3,14.3,0,0,0,9.9-4.1l120-120A14.1,14.1,0,0,0,222.6,78.1ZM94.1,209.4a2,2,0,0,1-1.4.6H48a2,2,0,0,1-2-2V163.3a2,2,0,0,1,.6-1.4L136,72.5,183.5,120Zm120-120L192,111.5,144.5,64l22.1-22.1a1.9,1.9,0,0,1,2.8,0l44.7,44.7A1.9,1.9,0,0,1,214.1,89.4Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M221.2,79.5,176.5,34.8a12.1,12.1,0,0,0-17,0l-120,120a11.9,11.9,0,0,0-3.5,8.5V208a12,12,0,0,0,12,12H92.7a11.9,11.9,0,0,0,8.5-3.5l120-120A12,12,0,0,0,221.2,79.5ZM95.5,210.8a3.6,3.6,0,0,1-2.8,1.2H48a4,4,0,0,1-4-4V163.3a3.6,3.6,0,0,1,1.2-2.8L136,69.7,186.3,120Zm120-120L192,114.3,141.7,64l23.5-23.5a3.9,3.9,0,0,1,5.6,0l44.7,44.7A3.9,3.9,0,0,1,215.5,90.8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M224,76.7,179.3,32a15.9,15.9,0,0,0-22.6,0L36.7,152A15.9,15.9,0,0,0,32,163.3V208a16,16,0,0,0,16,16H92.7a15.9,15.9,0,0,0,11.3-4.7l120-120A16.1,16.1,0,0,0,224,76.7ZM92.7,208H48V163.3l88-88L180.7,120ZM192,108.7,147.3,64,168,43.3,212.7,88Z" />
        </>
      )}
    </svg>
  );
}

export function PhPencil(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M226.8,73.9,182.1,29.2a19.8,19.8,0,0,0-28.2,0l-120,120A19.7,19.7,0,0,0,28,163.3V208a20.1,20.1,0,0,0,20,20H92.7a19.7,19.7,0,0,0,14.1-5.9l120-120A19.9,19.9,0,0,0,226.8,73.9ZM89,184l75-75,11,11-75,75ZM72,167,61,156l75-75,11,11ZM52,181l11.5,11.5h0L75,204H52Zm140-78L153,64l15-15,39,39Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M218.3,93.7,192,120,136,64l26.3-26.3a8,8,0,0,1,11.4,0l44.6,44.6A8,8,0,0,1,218.3,93.7Z"
            opacity="0.2"
          />
          <path d="M224,76.7,179.3,32a15.9,15.9,0,0,0-22.6,0L36.7,152A15.9,15.9,0,0,0,32,163.3V208a16,16,0,0,0,16,16H92.7a15.9,15.9,0,0,0,11.3-4.7l120-120A16.1,16.1,0,0,0,224,76.7ZM51.3,160,136,75.3,152.7,92,68,176.7ZM48,179.3,76.7,208H48Zm48,25.4L79.3,188,164,103.3,180.7,120Zm96-96L147.3,64,168,43.3,212.7,88Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M224,76.7,179.3,32a15.9,15.9,0,0,0-22.6,0L130.3,58.3h0L36.7,152A15.9,15.9,0,0,0,32,163.3V208a16,16,0,0,0,16,16H92.7a15.9,15.9,0,0,0,11.3-4.7l120-120A16.1,16.1,0,0,0,224,76.7ZM51.3,160,144,67.3,160.7,84,68,176.7ZM48,179.3,76.7,208H48Zm48,25.4L79.3,188,172,95.3,188.7,112Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M222.6,78.1,177.9,33.4a14,14,0,0,0-19.8,0l-120,120a14.3,14.3,0,0,0-4.1,9.9V208a14,14,0,0,0,14,14H92.7a14.3,14.3,0,0,0,9.9-4.1l120-120A14.1,14.1,0,0,0,222.6,78.1ZM48.5,160,136,72.5,155.5,92,68,179.5ZM46,208V174.5L81.5,210H48A2,2,0,0,1,46,208Zm50-.5L76.5,188,164,100.5,183.5,120ZM214.1,89.4,192,111.5,144.5,64l22.1-22.1a1.9,1.9,0,0,1,2.8,0l44.7,44.7A1.9,1.9,0,0,1,214.1,89.4Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M221.2,79.5,176.5,34.8a12.1,12.1,0,0,0-17,0l-120,120a11.9,11.9,0,0,0-3.5,8.5V208a12,12,0,0,0,12,12H92.7a11.9,11.9,0,0,0,8.5-3.5l120-120A12,12,0,0,0,221.2,79.5ZM45.7,160,136,69.7,158.3,92,68,182.3ZM44,208V169.7l21.2,21.1h0L86.3,212H48A4,4,0,0,1,44,208Zm52,2.3L73.7,188,164,97.7,186.3,120ZM215.5,90.8,192,114.3,141.7,64l23.5-23.5a3.9,3.9,0,0,1,5.6,0l44.7,44.7A3.9,3.9,0,0,1,215.5,90.8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M224,76.7,179.3,32a15.9,15.9,0,0,0-22.6,0L36.7,152A15.9,15.9,0,0,0,32,163.3V208a16,16,0,0,0,16,16H92.7a15.9,15.9,0,0,0,11.3-4.7l120-120A16.1,16.1,0,0,0,224,76.7ZM51.3,160,136,75.3,152.7,92,68,176.7ZM48,179.3,76.7,208H48Zm48,25.4L79.3,188,164,103.3,180.7,120Zm96-96L147.3,64,168,43.3,212.7,88Z" />
        </>
      )}
    </svg>
  );
}

export function PhPercent(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M208.5,64.5l-144,144a12.1,12.1,0,0,1-17,0,12,12,0,0,1,0-17l144-144a12,12,0,0,1,17,17ZM47.7,104.3A40.1,40.1,0,1,1,76,116,40,40,0,0,1,47.7,104.3ZM60,76a16,16,0,1,0,4.7-11.3A15.9,15.9,0,0,0,60,76ZM220,180a40,40,0,1,1-11.7-28.3A40,40,0,0,1,220,180Zm-24,0a15.9,15.9,0,0,0-4.7-11.3h0A15.9,15.9,0,1,0,196,180Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="180" cy="180" r="28" />
          </g>
          <g opacity="0.2">
            <circle cx="76" cy="76" r="28" />
          </g>
          <path d="M205.7,50.3a8.1,8.1,0,0,0-11.4,0l-144,144a8.1,8.1,0,0,0,0,11.4,8.2,8.2,0,0,0,11.4,0l144-144A8.1,8.1,0,0,0,205.7,50.3Z" />
          <path d="M76,112a35.9,35.9,0,1,0-25.5-10.5A36.2,36.2,0,0,0,76,112ZM61.9,61.9a19.8,19.8,0,0,1,28.2,0h0a19.8,19.8,0,0,1,0,28.2,19.9,19.9,0,0,1-28.2,0,19.8,19.8,0,0,1,0-28.2Z" />
          <path d="M205.5,154.5a36.2,36.2,0,0,0-51,0,36,36,0,1,0,51,0Zm-11.4,39.6a19.9,19.9,0,1,1-28.2-28.2,19.8,19.8,0,0,1,28.2,0,19.8,19.8,0,0,1,0,28.2Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M205.7,61.7l-144,144a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4l144-144a8.1,8.1,0,0,1,11.4,11.4ZM50.5,101.5a36.2,36.2,0,0,1,0-51,36.2,36.2,0,0,1,51,0,36.1,36.1,0,0,1-51,51ZM56,76a19.7,19.7,0,0,0,5.9,14.1,19.9,19.9,0,0,0,28.2,0,19.8,19.8,0,0,0,0-28.2h0a19.8,19.8,0,0,0-28.2,0A19.7,19.7,0,0,0,56,76ZM216,180a36,36,0,1,1-61.5-25.5,36.2,36.2,0,0,1,51,0A35.9,35.9,0,0,1,216,180Zm-16,0a19.7,19.7,0,0,0-5.9-14.1,19.8,19.8,0,0,0-28.2,0A19.9,19.9,0,1,0,200,180Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M204.2,60.2l-144,144a5.9,5.9,0,0,1-8.4-8.4l144-144a5.9,5.9,0,0,1,8.4,8.4ZM52,100a33.8,33.8,0,0,1,0-48,33.9,33.9,0,0,1,48,0,34,34,0,0,1-24,58A33.5,33.5,0,0,1,52,100Zm2-24A22,22,0,1,0,91.6,60.4a22.2,22.2,0,0,0-31.2,0A22.2,22.2,0,0,0,54,76ZM214,180a34,34,0,1,1-58-24,33.9,33.9,0,0,1,48,0A33.3,33.3,0,0,1,214,180Zm-12,0a22.2,22.2,0,0,0-6.4-15.6h0a22.2,22.2,0,0,0-31.2,0A22.1,22.1,0,1,0,202,180Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M202.8,58.8l-144,144a3.9,3.9,0,0,1-5.6,0,3.9,3.9,0,0,1,0-5.6l144-144a4,4,0,1,1,5.6,5.6ZM53.4,98.6a31.9,31.9,0,0,1,0-45.2,31.9,31.9,0,0,1,45.2,0A32,32,0,1,1,53.4,98.6ZM52,76A24,24,0,1,0,93,59a24.1,24.1,0,0,0-34,0A24.2,24.2,0,0,0,52,76ZM212,180a32,32,0,1,1-54.6-22.6,31.9,31.9,0,0,1,45.2,0A31.5,31.5,0,0,1,212,180Zm-8,0a24.2,24.2,0,0,0-7-17,24.1,24.1,0,0,0-34,0,24,24,0,1,0,41,17Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M205.7,61.7l-144,144a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4l144-144a8.1,8.1,0,0,1,11.4,11.4ZM50.5,101.5a36.2,36.2,0,0,1,0-51,36.2,36.2,0,0,1,51,0,36.1,36.1,0,0,1-51,51ZM56,76a19.7,19.7,0,0,0,5.9,14.1,19.9,19.9,0,0,0,28.2,0,19.8,19.8,0,0,0,0-28.2h0a19.8,19.8,0,0,0-28.2,0A19.7,19.7,0,0,0,56,76ZM216,180a36,36,0,1,1-61.5-25.5,36.2,36.2,0,0,1,51,0A35.9,35.9,0,0,1,216,180Zm-16,0a19.7,19.7,0,0,0-5.9-14.1,19.8,19.8,0,0,0-28.2,0A19.9,19.9,0,1,0,200,180Z" />
        </>
      )}
    </svg>
  );
}

export function PhPersonSimpleRun(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M152,92a36,36,0,1,0-36-36A36,36,0,0,0,152,92Zm0-48a12,12,0,1,1-12,12A12,12,0,0,1,152,44Zm61.6,108.2h0c-1.6.8-9.1,4.3-21.9,4.3a56.2,56.2,0,0,1-5.7-.2c-15.5-1.2-31.8-7.1-48.6-17.5-2.2,4.7-4.8,10-7.9,15.4,23.7,8.6,58.5,29.6,58.5,77.8a12,12,0,0,1-24,0c0-18.7-7-33.1-21.3-43.9-9.1-7-19.5-11.1-27.4-13.4-1.9,2.3-3.9,4.6-6.1,6.9-19.3,20.3-42.9,30.8-68.7,30.8a79.8,79.8,0,0,1-9.7-.5,12,12,0,1,1,2.4-23.8c22.3,2.2,42-5.6,58.7-23.1a112.2,112.2,0,0,0,8.9-10.7h0a143.6,143.6,0,0,0,16.5-29.8c-15.4-11.2-30.1-15.7-43.9-13.5A37.7,37.7,0,0,0,56,118.5h.1a12.2,12.2,0,0,1-8.1,3.2,12,12,0,0,1-8.1-20.9h0c1-1,11-9.8,27.8-13,27.6-5.3,52,8.2,67.7,20.4l3.4,2.6h.1c40.5,30.2,63.6,20.2,63.8,20.1h-.3a13.2,13.2,0,0,1,5.6-1.3,12,12,0,0,1,5.6,22.6Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M176,56a24,24,0,1,1-24-24A23.9,23.9,0,0,1,176,56Z"
            opacity="0.2"
          />
          <path d="M152,88a32,32,0,1,0-32-32A32.1,32.1,0,0,0,152,88Zm0-48a16,16,0,1,1-16,16A16,16,0,0,1,152,40Zm67.7,100.6h0c-.6.4-7.6,3.9-20.1,3.9s-34.2-4.4-60-22.3a164.5,164.5,0,0,1-16,34.1c9.2,3,20.8,7.9,31.2,15.8C173.9,186.6,184,207.3,184,232a8,8,0,0,1-16,0c0-41.7-34.7-56.7-54.1-61.9C97.3,191,73.3,208.4,40.7,208.4c-3.1,0-6.2-.1-9.5-.4a8,8,0,1,1,1.6-16c26.8,2.7,50-8.1,68.9-32.3l2.4-3.1h.1A149.6,149.6,0,0,0,126,112.1c-38.8-27-63.5-5.6-64.6-4.6a8,8,0,0,1-11.3-.5,7.9,7.9,0,0,1,.5-11.3c1-.9,10.1-9,25.9-12.1,26.1-5,49.4,7.9,64.4,19.6,23.8,18.7,42.1,23.9,53.3,25.1s18.1-1.9,18.2-1.9h-.1a7.8,7.8,0,0,1,3.7-.9,8,8,0,0,1,8,8A7.8,7.8,0,0,1,219.7,140.6Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M120,56a32,32,0,1,1,32,32A32.1,32.1,0,0,1,120,56Zm104,77.6a8,8,0,0,0-8-8,7.8,7.8,0,0,0-3.7.9h.1c-.1,0-6.5,3.1-18.2,1.9s-29.5-6.4-53.3-25.1c-15-11.7-38.3-24.6-64.4-19.6C60.7,86.7,51.6,94.8,50.6,95.7a7.9,7.9,0,0,0-.5,11.3,8,8,0,0,0,11.3.5c1.1-1,25.8-22.4,64.6,4.6a147.9,147.9,0,0,1-21.9,44.4h0l-2.4,3.1c-18.9,24.2-42.1,35-68.9,32.3a8,8,0,1,0-1.6,16c3.3.3,6.4.4,9.5.4,32.6,0,56.6-17.4,73.2-38.3,19.4,5.2,54.1,20.2,54.1,61.9a8,8,0,0,0,16,0c0-24.7-10.1-45.4-29.2-59.9-10.4-7.9-22-12.8-31.2-15.8a164.5,164.5,0,0,0,16-34.1c25.8,17.9,46.2,22.3,60,22.3s19.5-3.5,20.1-3.9h0A7.8,7.8,0,0,0,224,133.6Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M152,86a30,30,0,1,0-30-30A30.1,30.1,0,0,0,152,86Zm0-48a18,18,0,1,1-18,18A18.1,18.1,0,0,1,152,38Zm67.2,100.6-.4.3h0c-.6.3-7.3,3.6-19.2,3.6-13.8,0-34.6-4.5-61-23.5a166.5,166.5,0,0,1-18.1,38.5c9.4,2.8,21.9,7.7,33.1,16.2C172.5,188,182,207.7,182,232a6,6,0,0,1-12,0c0-44-37.2-59.2-56.9-64.1-16.3,20.9-40,38.5-72.4,38.5-3,0-6.1-.1-9.3-.4a6,6,0,1,1,1.2-12c27.6,2.8,51.3-8.3,70.7-33l2.4-3.2c0-.1.1-.1.1-.2a150.1,150.1,0,0,0,22.5-46.3C87.5,82,61.2,105,60,106h0a5.9,5.9,0,0,1-4,1.6,6,6,0,0,1-6-6v-.3h0a3.6,3.6,0,0,1,.2-1.1h0l.3-1h.1a4.1,4.1,0,0,1,.5-.9h.1a2.7,2.7,0,0,1,.7-.8h0a55.7,55.7,0,0,1,25-11.5c13.7-2.7,35.5-1.6,61.6,18.3l.5.4.7.5c46.2,36.2,73.3,23.5,73.6,23.4h.4a6,6,0,0,1,7.6,2.7,6.2,6.2,0,0,1-1.7,7.7h-.4Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M152,84a28,28,0,1,0-28-28A28.1,28.1,0,0,0,152,84Zm0-48a20,20,0,1,1-20,20A20.1,20.1,0,0,1,152,36Zm67,100.2-.3.3-.3.2-.4.3h-.1c-.6.3-7,3.5-18.3,3.5-13.8,0-35-4.7-62.2-24.9a162.6,162.6,0,0,1-20.1,42.9c9.4,2.6,23.1,7.5,35.1,16.7,18.3,13.9,27.6,33,27.6,56.7a4,4,0,0,1-8,0c0-15.9-5.9-53.8-59.7-66.4l-1.2,1.5c-19.2,24.6-43.4,37.3-70.6,37.3-2.9,0-5.9-.1-8.9-.4a4,4,0,0,1,.8-8c28.3,2.8,52.7-8.5,72.4-33.8l2.6-3.3h0c13.2-18.1,20.6-38,23.2-48.3-42.9-31.5-70.7-7.1-71.9-6h-.2l-.4.3-.2.2-.5.2H55l-.7-.2h0l-.6-.4h-.1l-.5-.4H53l-.4-.6h-.1l-.2-.5a.4.4,0,0,1-.1-.3.7.7,0,0,1-.1-.5.4.4,0,0,1-.1-.3v-.3h0v-.4a.8.8,0,0,0,.1-.4c0-.1.1-.3.1-.4s.1-.2.1-.4.2-.2.2-.3l.2-.3.2-.3.3-.3a.1.1,0,0,0,.1-.1c.3-.3,9-8.2,23.9-11.1,24.7-4.7,46.9,7.7,61.3,18.9,47.4,37.1,75.4,23.7,75.7,23.6h.6c0-.1.1-.1.2-.1h1.5l.7.2h0a.9.9,0,0,1,.7.3h0l.6.4h.1l.5.5h0l.4.6h0a1.3,1.3,0,0,1,.3.7h0a6.4,6.4,0,0,1,.2.7h0v.8a.7.7,0,0,1-.1.5v.3a.8.8,0,0,0-.2.4l-.2.3-.2.3Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M152,88a32,32,0,1,0-32-32A32.1,32.1,0,0,0,152,88Zm0-48a16,16,0,1,1-16,16A16,16,0,0,1,152,40Zm67.7,100.6h0c-.6.4-7.6,3.9-20.1,3.9s-34.2-4.4-60-22.3a164.5,164.5,0,0,1-16,34.1c9.2,3,20.8,7.9,31.2,15.8C173.9,186.6,184,207.3,184,232a8,8,0,0,1-16,0c0-41.7-34.7-56.7-54.1-61.9C97.3,191,73.3,208.4,40.7,208.4c-3.1,0-6.2-.1-9.5-.4a8,8,0,1,1,1.6-16c26.8,2.7,50-8.1,68.9-32.3l2.4-3.1h.1A149.6,149.6,0,0,0,126,112.1c-38.8-27-63.5-5.6-64.6-4.6a8,8,0,0,1-11.3-.5,7.9,7.9,0,0,1,.5-11.3c1-.9,10.1-9,25.9-12.1,26.1-5,49.4,7.9,64.4,19.6,23.8,18.7,42.1,23.9,53.3,25.1s18.1-1.9,18.2-1.9h-.1a7.8,7.8,0,0,1,3.7-.9,8,8,0,0,1,8,8A7.8,7.8,0,0,1,219.7,140.6Z" />
        </>
      )}
    </svg>
  );
}

export function PhPersonSimpleWalk(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M152,84a36,36,0,1,0-36-36A36,36,0,0,0,152,84Zm0-48a12,12,0,1,1-12,12A12,12,0,0,1,152,36Zm64,112a12,12,0,0,1-12,12c-37.2,0-55.5-19.7-70.2-35.5l-1.4-1.4-8,18.4L159,166.2a12.2,12.2,0,0,1,5,9.8v56a12,12,0,0,1-24,0V182.2l-25.4-18.1L83,236.8A12.1,12.1,0,0,1,72,244a11.8,11.8,0,0,1-4.8-1A12,12,0,0,1,61,227.2l37.5-86.4h.1l13.7-31.6c-8.1-1-18.2,2-30.3,8.9a156.7,156.7,0,0,0-29.5,22.4,12,12,0,0,1-17-17A187.4,187.4,0,0,1,70,97.3c36.1-20.7,58.1-11.7,70.1-.6,4,3.7,7.7,7.6,11.2,11.5C164.7,122.5,177.2,136,204,136A12,12,0,0,1,216,148Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M176,48a24,24,0,1,1-24-24A23.9,23.9,0,0,1,176,48Z"
            opacity="0.2"
          />
          <path d="M152,80a32,32,0,1,0-32-32A32.1,32.1,0,0,0,152,80Zm0-48a16,16,0,1,1-16,16A16,16,0,0,1,152,32Zm64,112a8,8,0,0,1-8,8c-35.5,0-53.1-19-67.3-34.2l-7.1-7.4-14.1,32.5,37.1,26.6A7.8,7.8,0,0,1,160,176v56a8,8,0,0,1-16,0V180.1l-31.1-22.2L79.3,235.2A7.9,7.9,0,0,1,72,240a7.1,7.1,0,0,1-3.2-.7,7.9,7.9,0,0,1-4.1-10.5l37.5-86.3h0l17.7-40.7c-9.4-2.3-21.4.6-35.7,8.8a157.3,157.3,0,0,0-30.5,23.2,8.1,8.1,0,1,1-11.4-11.4c2.6-2.5,61.9-61,99.1-26.6,3.8,3.5,7.5,7.4,11,11.2,13.9,15,27.1,29.1,55.6,29.1A8,8,0,0,1,216,144Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M120,48a32,32,0,1,1,32,32A32.1,32.1,0,0,1,120,48Zm88,88c-28.5,0-41.7-14.1-55.6-29.1-3.5-3.8-7.2-7.7-11-11.2-37.2-34.4-96.5,24.1-99.1,26.6a8.1,8.1,0,1,0,11.4,11.4,157.3,157.3,0,0,1,30.5-23.2c14.3-8.2,26.3-11.1,35.7-8.8l-17.7,40.7h0L64.7,228.8a7.9,7.9,0,0,0,4.1,10.5,7.1,7.1,0,0,0,3.2.7,7.9,7.9,0,0,0,7.3-4.8l33.6-77.3L144,180.1V232a8,8,0,0,0,16,0V176a7.8,7.8,0,0,0-3.4-6.5l-37.1-26.6,14.1-32.5,7.1,7.4C154.9,133,172.5,152,208,152a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M152,78a30,30,0,1,0-30-30A30.1,30.1,0,0,0,152,78Zm0-48a18,18,0,1,1-18,18A18.1,18.1,0,0,1,152,30Zm62,114a6,6,0,0,1-6,6c-34.6,0-51.2-17.8-65.8-33.6-3.2-3.3-6.2-6.6-9.3-9.5L117,143.6l38.5,27.5A6,6,0,0,1,158,176v56a6,6,0,0,1-12,0V179.1l-33.9-24.2L77.5,234.4A6,6,0,0,1,72,238a6.9,6.9,0,0,1-2.4-.5,6.1,6.1,0,0,1-3.1-7.9L104,143.3h.1l18.6-42.8c-10.3-3.5-23.6-.7-39.5,8.4s-30.8,23.3-31,23.4a5.9,5.9,0,1,1-8.4-8.4c2.4-2.5,60.4-59.7,96.3-26.7,3.7,3.5,7.4,7.4,10.9,11.2,13.6,14.6,27.6,29.7,57,29.7A6,6,0,0,1,214,144Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M152,76a28,28,0,1,0-28-28A28.1,28.1,0,0,0,152,76Zm0-48a20,20,0,1,1-20,20A20.1,20.1,0,0,1,152,28Zm60,116a4,4,0,0,1-4,4c-33.7,0-50-17.5-64.4-32.9-3.5-3.8-6.8-7.4-10.3-10.6l-1.1-.9-17.7,40.7,39.8,28.4A4,4,0,0,1,156,176v56a4,4,0,0,1-8,0V178.1l-36.8-26.3L75.7,233.6A4.1,4.1,0,0,1,72,236a4.5,4.5,0,0,1-1.6-.3,4.2,4.2,0,0,1-2.1-5.3l37.6-86.3h0l19.4-44.7c-29.6-12.7-74,31-74.5,31.5a4,4,0,0,1-5.6-5.6,176.7,176.7,0,0,1,32.8-25c25.2-14.4,46.2-15,60.7-1.6,3.7,3.4,7.2,7.1,10.8,11,13.9,15,28.3,30.4,58.5,30.4A4,4,0,0,1,212,144Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M152,80a32,32,0,1,0-32-32A32.1,32.1,0,0,0,152,80Zm0-48a16,16,0,1,1-16,16A16,16,0,0,1,152,32Zm64,112a8,8,0,0,1-8,8c-35.5,0-53.1-19-67.3-34.2l-7.1-7.4-14.1,32.5,37.1,26.6A7.8,7.8,0,0,1,160,176v56a8,8,0,0,1-16,0V180.1l-31.1-22.2L79.3,235.2A7.9,7.9,0,0,1,72,240a7.1,7.1,0,0,1-3.2-.7,7.9,7.9,0,0,1-4.1-10.5l37.5-86.3h0l17.7-40.7c-9.4-2.3-21.4.6-35.7,8.8a157.3,157.3,0,0,0-30.5,23.2,8.1,8.1,0,1,1-11.4-11.4c2.6-2.5,61.9-61,99.1-26.6,3.8,3.5,7.5,7.4,11,11.2,13.9,15,27.1,29.1,55.6,29.1A8,8,0,0,1,216,144Z" />
        </>
      )}
    </svg>
  );
}

export function PhPersonSimple(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M127.9,84a36,36,0,1,0-36-36A36,36,0,0,0,127.9,84Zm0-48a12,12,0,1,1-12,12A12,12,0,0,1,127.9,36ZM234.3,138.2A12.1,12.1,0,0,1,224,144a11.9,11.9,0,0,1-6.2-1.7c-.3-.2-32.3-18.9-77.8-21.9v27L201,216a12.1,12.1,0,0,1-1,17,12.1,12.1,0,0,1-17-1l-55-61.9L73,232a12,12,0,0,1-18-16l61-68.6v-27c-45.7,3-77.5,21.7-77.8,21.9a12,12,0,0,1-12.4-20.6C27.6,120.7,69.4,96,128,96s100.4,24.7,102.2,25.7A12,12,0,0,1,234.3,138.2Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M151.9,48a24,24,0,1,1-24-24A24,24,0,0,1,151.9,48Z"
            opacity="0.2"
          />
          <path d="M127.9,80a32,32,0,1,0-32-32A32.1,32.1,0,0,0,127.9,80Zm0-48a16,16,0,1,1-16,16A16,16,0,0,1,127.9,32Zm103,100.1A8.1,8.1,0,0,1,224,136a8.7,8.7,0,0,1-4.1-1.1c-.4-.3-35.1-20.6-83.9-22.7V149l62,69.7a8,8,0,0,1-.7,11.3,8.1,8.1,0,0,1-5.3,2,8,8,0,0,1-6-2.7L128,164,70,229.3a8,8,0,0,1-12-10.6L120,149V112.2c-49,2.1-83.5,22.4-83.9,22.7a8,8,0,0,1-8.2-13.8C29.6,120.1,70.4,96,128,96s98.4,24.1,100.1,25.1A8.1,8.1,0,0,1,230.9,132.1Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M95.9,48a32,32,0,1,1,32,32A32,32,0,0,1,95.9,48Zm132.2,73.1C226.4,120.1,185.6,96,128,96S29.6,120.1,27.9,121.1a8,8,0,0,0,8.2,13.8c.4-.3,34.9-20.6,83.9-22.7V149L58,218.7a8,8,0,0,0,12,10.6L128,164l58,65.3a8,8,0,0,0,6,2.7,8.1,8.1,0,0,0,5.3-2,8,8,0,0,0,.7-11.3L136,149V112.2c48.8,2.1,83.5,22.4,83.9,22.7A8.7,8.7,0,0,0,224,136a8,8,0,0,0,4.1-14.9Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M127.9,78a30,30,0,1,0-30-30A30.1,30.1,0,0,0,127.9,78Zm0-48a18,18,0,1,1-18,18A18.1,18.1,0,0,1,127.9,30ZM229.1,131.1a5.9,5.9,0,0,1-8.2,2c-.4-.2-36.5-21.4-86.9-23v39.6L196.5,220a6,6,0,0,1-.5,8.5,6.2,6.2,0,0,1-4,1.5,6.1,6.1,0,0,1-4.5-2L128,161,68.5,228a6,6,0,0,1-9-8L122,149.7V110.1c-50.4,1.6-86.5,22.8-86.9,23a6,6,0,1,1-6.2-10.2C30.6,121.8,71,98,128,98s97.4,23.8,99.1,24.9A6,6,0,0,1,229.1,131.1Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M127.9,76a28,28,0,1,0-28-28A28.1,28.1,0,0,0,127.9,76Zm0-48a20,20,0,1,1-20,20A20.1,20.1,0,0,1,127.9,28Zm99.5,102.1A3.9,3.9,0,0,1,224,132a4.2,4.2,0,0,1-2.1-.6c-.3-.2-37.8-22.3-89.9-23.4v42.5l63,70.8a4.1,4.1,0,0,1-.3,5.7,4.1,4.1,0,0,1-2.7,1,4.3,4.3,0,0,1-3-1.3L128,158,67,226.7a4,4,0,1,1-6-5.4l63-70.8V108c-52.1,1.1-89.6,23.2-89.9,23.4a4,4,0,0,1-5.5-1.3,3.9,3.9,0,0,1,1.3-5.5c1.7-1,41.6-24.6,98.1-24.6s96.4,23.6,98.1,24.6A3.9,3.9,0,0,1,227.4,130.1Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M127.9,80a32,32,0,1,0-32-32A32.1,32.1,0,0,0,127.9,80Zm0-48a16,16,0,1,1-16,16A16,16,0,0,1,127.9,32Zm103,100.1A8.1,8.1,0,0,1,224,136a8.7,8.7,0,0,1-4.1-1.1c-.4-.3-35.1-20.6-83.9-22.7V149l62,69.7a8,8,0,0,1-.7,11.3,8.1,8.1,0,0,1-5.3,2,8,8,0,0,1-6-2.7L128,164,70,229.3a8,8,0,0,1-12-10.6L120,149V112.2c-49,2.1-83.5,22.4-83.9,22.7a8,8,0,0,1-8.2-13.8C29.6,120.1,70.4,96,128,96s98.4,24.1,100.1,25.1A8.1,8.1,0,0,1,230.9,132.1Z" />
        </>
      )}
    </svg>
  );
}

export function PhPerson(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M214.8,136.9,175.6,86.5a5.2,5.2,0,0,0-1-1.1A31.5,31.5,0,0,0,152,76h0a40,40,0,1,0-48,0h0a31.5,31.5,0,0,0-22.6,9.4,5.2,5.2,0,0,0-1,1.1L41.2,136.9a24,24,0,0,0,33.9,33.9l3.8-2.9L63,218.1a23.5,23.5,0,0,0-.4,17.5,24,24,0,0,0,43.9,2.7L128,204.5l21.5,33.8a23.9,23.9,0,0,0,13.2,11.6,23.3,23.3,0,0,0,8.2,1.5A24,24,0,0,0,193,218.1l-15.9-50.2,3.8,2.9a24,24,0,0,0,33.9-33.9ZM128,28a16,16,0,1,1-16,16A16,16,0,0,1,128,28Zm68.1,124.3-34.7-27.1a12,12,0,0,0-18.8,13.1l27.7,87.6.6,1.5a10,10,0,0,0-.8-1.4l-32-50.4a12,12,0,0,0-20.2,0L85.9,226a10,10,0,0,0-.8,1.4l.6-1.5,27.7-87.6a11.9,11.9,0,0,0-4.6-13.4,11.3,11.3,0,0,0-6.8-2.2,12.7,12.7,0,0,0-7.4,2.5L59.9,152.3l-1.2,1,1-1.2,39.1-50.2A8.1,8.1,0,0,1,104,100h48a8.1,8.1,0,0,1,5.2,1.9l39.1,50.2,1,1.2Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M100,44a28,28,0,1,1,28,28A28,28,0,0,1,100,44ZM205.7,144.8,166.1,93.9A19.9,19.9,0,0,0,152,88H104a19.9,19.9,0,0,0-14.1,5.9L50.3,144.8a12,12,0,0,0,16.9,16.9l34.8-27L74.2,222.3A12,12,0,0,0,96,232.5l32-50.4,32,50.4a12,12,0,1,0,21.8-10.2L154,134.7l34.8,27a12,12,0,0,0,16.9-16.9Z"
            opacity="0.2"
          />
          <path d="M164,44a36,36,0,1,0-36,36A36,36,0,0,0,164,44ZM128,64a20,20,0,1,1,20-20A20.1,20.1,0,0,1,128,64Zm83.8,75.5L172.5,88.9l-.7-.7A27.8,27.8,0,0,0,152,80H104a27.8,27.8,0,0,0-19.8,8.2l-.7.7L44.2,139.5a20,20,0,0,0,28.3,28.3l14.1-11L66.8,219.5a19.7,19.7,0,0,0-.5,14.7,20.2,20.2,0,0,0,10.4,11.3,19.8,19.8,0,0,0,15.3.7,20.4,20.4,0,0,0,11-9.8L128,197l25,39.4a20.4,20.4,0,0,0,11,9.8,20.9,20.9,0,0,0,6.9,1.2,19.1,19.1,0,0,0,8.4-1.9,20.2,20.2,0,0,0,10.4-11.3,19.7,19.7,0,0,0-.5-14.7l-19.8-62.7,14.1,11a20,20,0,0,0,28.3-28.3Zm-11.7,16.6a4.2,4.2,0,0,1-5.7,0l-.7-.7-34.8-27a8,8,0,0,0-12.5,8.7l27.7,87.6a10.4,10.4,0,0,0,.4,1,3.5,3.5,0,0,1,.1,3,3.8,3.8,0,0,1-2,2.3,3.7,3.7,0,0,1-3.1.1,3.7,3.7,0,0,1-2.2-2,4.1,4.1,0,0,1-.5-.9l-32-50.4a8.1,8.1,0,0,0-13.6,0l-32,50.4a4.1,4.1,0,0,1-.5.9,3.7,3.7,0,0,1-2.2,2,3.7,3.7,0,0,1-3.1-.1,3.8,3.8,0,0,1-2-2.3,3.5,3.5,0,0,1,.1-3,10.4,10.4,0,0,0,.4-1l27.7-87.6a8,8,0,0,0-3.1-9,7.8,7.8,0,0,0-9.4.3l-34.8,27-.7.7a4,4,0,0,1-5.7-5.7l.7-.7L95.8,99.2A11.9,11.9,0,0,1,104,96h48a11.9,11.9,0,0,1,8.2,3.2l39.2,50.5.7.7a4.1,4.1,0,0,1,1.2,2.9A4.1,4.1,0,0,1,200.1,156.1Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,80a36,36,0,1,1,36-36A36,36,0,0,1,128,80Zm83.8,59.5L172.5,88.9l-.7-.7A27.8,27.8,0,0,0,152,80H104a27.8,27.8,0,0,0-19.8,8.2l-.7.7L44.2,139.5a20,20,0,0,0,28.3,28.3l14.1-11L66.8,219.5a19.7,19.7,0,0,0-.5,14.7,20.2,20.2,0,0,0,10.4,11.3,19.8,19.8,0,0,0,15.3.7,20.4,20.4,0,0,0,11-9.8L128,197l25,39.4a20.4,20.4,0,0,0,11,9.8,20.9,20.9,0,0,0,6.9,1.2,19.1,19.1,0,0,0,8.4-1.9,20.2,20.2,0,0,0,10.4-11.3,19.7,19.7,0,0,0-.5-14.7l-19.8-62.7,14.1,11a20,20,0,0,0,28.3-28.3Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,78A34,34,0,1,0,94,44,34.1,34.1,0,0,0,128,78Zm0-56a22,22,0,1,1-22,22A22.1,22.1,0,0,1,128,22Zm82.3,118.8L170.9,90.2l-.5-.6A26,26,0,0,0,152,82H104a26,26,0,0,0-18.4,7.6l-.5.6L45.7,140.8a18,18,0,0,0,25.5,25.5l19.2-15L68.6,220.2a18,18,0,0,0-.4,13.3,17.7,17.7,0,0,0,9.3,10.2,18.3,18.3,0,0,0,13.8.6,17.7,17.7,0,0,0,9.9-8.9L128,193.3l26.8,42.1a17.7,17.7,0,0,0,9.9,8.9,18.8,18.8,0,0,0,6.2,1.1,18.3,18.3,0,0,0,7.6-1.7,17.7,17.7,0,0,0,9.3-10.2,18,18,0,0,0-.4-13.3l-21.8-68.9,19.2,15a18,18,0,0,0,25.5-25.5Zm-8.8,16.7a6.1,6.1,0,0,1-8.5,0l-.5-.5-34.8-27a6,6,0,0,0-7.1-.2,5.9,5.9,0,0,0-2.3,6.7L176,224.1l.3.7a5.7,5.7,0,0,1,.2,4.6,5.8,5.8,0,0,1-3.1,3.4,5.7,5.7,0,0,1-4.6.2,5.8,5.8,0,0,1-3.4-3.1l-.3-.7-32-50.3a6,6,0,0,0-10.2,0l-32,50.3-.3.7a5.8,5.8,0,0,1-3.4,3.1,5.7,5.7,0,0,1-4.6-.2,5.8,5.8,0,0,1-3.1-3.4,5.7,5.7,0,0,1,.2-4.6l.3-.7,27.7-87.6a5.9,5.9,0,0,0-2.3-6.7,6,6,0,0,0-7.1.2L63.5,157l-.5.5a6.1,6.1,0,0,1-8.5,0,6,6,0,0,1-1.8-4.2,6.2,6.2,0,0,1,1.8-4.3l.5-.5L94.3,97.9A14.2,14.2,0,0,1,104,94h48a14.2,14.2,0,0,1,9.7,3.9L201,148.5l.5.5a6.2,6.2,0,0,1,1.8,4.3A6,6,0,0,1,201.5,157.5Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,76A32,32,0,1,0,96,44,32.1,32.1,0,0,0,128,76Zm0-56a24,24,0,1,1-24,24A24.1,24.1,0,0,1,128,20Zm80.8,122.1L169.3,91.4,169,91a24.2,24.2,0,0,0-17-7H104a24.2,24.2,0,0,0-17,7l-.3.4L47.2,142.1a16.1,16.1,0,0,0-4.5,11.2,16.1,16.1,0,0,0,27.2,11.5l24.4-19L70.5,220.9a16.1,16.1,0,0,0-.4,12,16,16,0,0,0,20.5,9.5,15.9,15.9,0,0,0,8.9-8L128,189.5l28.5,44.9a15.9,15.9,0,0,0,8.9,8,14.9,14.9,0,0,0,5.5,1,16.1,16.1,0,0,0,15-10.5,16.1,16.1,0,0,0-.4-12l-23.8-75.1,24.4,19a16.1,16.1,0,0,0,22.7-22.7Zm-5.9,16.8a8,8,0,0,1-11.3,0l-.4-.3-34.7-27a4.1,4.1,0,0,0-4.8-.2,4.1,4.1,0,0,0-1.5,4.5l27.7,87.6c.1.2.2.3.2.5a8.1,8.1,0,0,1,.3,6.1,7.9,7.9,0,0,1-4.1,4.5,8.3,8.3,0,0,1-6.2.3,7.9,7.9,0,0,1-4.5-4.1c0-.2-.1-.3-.2-.5l-32-50.4a4.1,4.1,0,0,0-6.8,0l-32,50.4c-.1.2-.2.3-.2.5a7.9,7.9,0,0,1-4.5,4.1,8.3,8.3,0,0,1-6.2-.3,7.9,7.9,0,0,1-4.1-4.5,8.1,8.1,0,0,1,.3-6.1c0-.2.1-.3.2-.5l27.7-87.6a4.1,4.1,0,0,0-1.5-4.5,4.1,4.1,0,0,0-4.8.2l-34.7,27-.4.3a8,8,0,1,1-11.3-11.3l.3-.4L92.9,96.5A15.8,15.8,0,0,1,104,92h48a15.8,15.8,0,0,1,11.1,4.5l39.5,50.7.3.4a7.9,7.9,0,0,1,0,11.3Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M164,44a36,36,0,1,0-36,36A36,36,0,0,0,164,44ZM128,64a20,20,0,1,1,20-20A20.1,20.1,0,0,1,128,64Zm83.8,75.5L172.5,88.9l-.7-.7A27.8,27.8,0,0,0,152,80H104a27.8,27.8,0,0,0-19.8,8.2l-.7.7L44.2,139.5a20,20,0,0,0,28.3,28.3l14.1-11L66.8,219.5a19.7,19.7,0,0,0-.5,14.7,20.2,20.2,0,0,0,10.4,11.3,19.8,19.8,0,0,0,15.3.7,20.4,20.4,0,0,0,11-9.8L128,197l25,39.4a20.4,20.4,0,0,0,11,9.8,20.9,20.9,0,0,0,6.9,1.2,19.1,19.1,0,0,0,8.4-1.9,20.2,20.2,0,0,0,10.4-11.3,19.7,19.7,0,0,0-.5-14.7l-19.8-62.7,14.1,11a20,20,0,0,0,28.3-28.3Zm-11.7,16.6a4.2,4.2,0,0,1-5.7,0l-.7-.7-34.8-27a8,8,0,0,0-12.5,8.7l27.7,87.6a10.4,10.4,0,0,0,.4,1,3.5,3.5,0,0,1,.1,3,3.8,3.8,0,0,1-2,2.3,3.7,3.7,0,0,1-3.1.1,3.7,3.7,0,0,1-2.2-2,4.1,4.1,0,0,1-.5-.9l-32-50.4a8.1,8.1,0,0,0-13.6,0l-32,50.4a4.1,4.1,0,0,1-.5.9,3.7,3.7,0,0,1-2.2,2,3.7,3.7,0,0,1-3.1-.1,3.8,3.8,0,0,1-2-2.3,3.5,3.5,0,0,1,.1-3,10.4,10.4,0,0,0,.4-1l27.7-87.6a8,8,0,0,0-3.1-9,7.8,7.8,0,0,0-9.4.3l-34.8,27-.7.7a4,4,0,0,1-5.7-5.7l.7-.7L95.8,99.2A11.9,11.9,0,0,1,104,96h48a11.9,11.9,0,0,1,8.2,3.2l39.2,50.5.7.7a4.1,4.1,0,0,1,1.2,2.9A4.1,4.1,0,0,1,200.1,156.1Z" />
        </>
      )}
    </svg>
  );
}

export function PhPerspective(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M240,116H228V49.6a20,20,0,0,0-23.6-19.7L44.4,59A20,20,0,0,0,28,78.7V116H16a12,12,0,0,0,0,24H28v37.3A20,20,0,0,0,44.4,197l160,29.1h0a21.9,21.9,0,0,0,3.6.3,19.9,19.9,0,0,0,20-20V140h12a12,12,0,0,0,0-24ZM52,82,204,54.4V116H52ZM204,201.6,52,174V140H204Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M216,49.6V206.4a8,8,0,0,1-9.4,7.9l-160-29.1a8,8,0,0,1-6.6-7.9V78.7a8,8,0,0,1,6.6-7.9l160-29.1A8,8,0,0,1,216,49.6Z"
            opacity="0.2"
          />
          <path d="M240,120H224V49.6a16.1,16.1,0,0,0-5.7-12.3,16.6,16.6,0,0,0-13.2-3.5L45.1,62.9A16,16,0,0,0,32,78.7V120H16a8,8,0,0,0,0,16H32v41.3a16,16,0,0,0,13.1,15.8l160,29.1h0l2.9.2a16.5,16.5,0,0,0,10.3-3.7,16.1,16.1,0,0,0,5.7-12.3V136h16a8,8,0,0,0,0-16ZM48,78.7,208,49.6V120H48ZM208,206.4h0L48,177.3V136H208Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M32,120v16H16a8,8,0,0,1,0-16Zm0,57.3a16,16,0,0,0,13.1,15.8l160,29.1h0l2.9.2a16.5,16.5,0,0,0,10.3-3.7,16.1,16.1,0,0,0,5.7-12.3V136H32ZM240,120H224v16h16a8,8,0,0,0,0-16ZM224,49.6a16.1,16.1,0,0,0-5.7-12.3,16.6,16.6,0,0,0-13.2-3.5L45.1,62.9A16,16,0,0,0,32,78.7V120H224Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M240,122H222V49.6a14,14,0,0,0-5-10.8,14.4,14.4,0,0,0-11.5-3L45.5,64.9A14,14,0,0,0,34,78.7V122H16a6,6,0,0,0,0,12H34v43.3a14,14,0,0,0,11.5,13.8l160,29.1h0l2.5.2a13.9,13.9,0,0,0,14-14V134h18a6,6,0,0,0,0-12ZM46,78.7a2,2,0,0,1,1.6-2l160-29.1a2,2,0,0,1,1.7.5,2.1,2.1,0,0,1,.7,1.5V122H46ZM210,206.4a2.1,2.1,0,0,1-.7,1.5,2,2,0,0,1-1.7.5l-160-29.1a2,2,0,0,1-1.6-2V134H210Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M240,124H220V49.6a12,12,0,0,0-4.3-9.2,11.7,11.7,0,0,0-9.8-2.6L45.9,66.9A11.9,11.9,0,0,0,36,78.7V124H16a4,4,0,0,0,0,8H36v45.3a11.9,11.9,0,0,0,9.9,11.8l160,29.1a7.5,7.5,0,0,0,2.1.2,12,12,0,0,0,12-12V132h20a4,4,0,0,0,0-8ZM44,78.7a4.1,4.1,0,0,1,3.3-4l160-29a4.2,4.2,0,0,1,3.3.8,4.3,4.3,0,0,1,1.4,3.1V124H44ZM212,206.4a4.3,4.3,0,0,1-1.4,3.1,4.2,4.2,0,0,1-3.3.8l-160-29a4.1,4.1,0,0,1-3.3-4V132H212Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M240,120H224V49.6a16.1,16.1,0,0,0-5.7-12.3,16.6,16.6,0,0,0-13.2-3.5L45.1,62.9A16,16,0,0,0,32,78.7V120H16a8,8,0,0,0,0,16H32v41.3a16,16,0,0,0,13.1,15.8l160,29.1h0l2.9.2a16.5,16.5,0,0,0,10.3-3.7,16.1,16.1,0,0,0,5.7-12.3V136h16a8,8,0,0,0,0-16ZM48,78.7,208,49.6V120H48ZM208,206.4h0L48,177.3V136H208Z" />
        </>
      )}
    </svg>
  );
}

export function PhPhoneCall(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M147.8,36.9a12,12,0,0,1,14.7-8.5,92.3,92.3,0,0,1,65.1,65.1,12,12,0,0,1-8.5,14.7,11,11,0,0,1-3.1.4,12.1,12.1,0,0,1-11.6-8.9,68,68,0,0,0-48.1-48.1A12,12,0,0,1,147.8,36.9Zm87.7,138.7A60,60,0,0,1,176,228C94.4,228,28,161.6,28,80A60,60,0,0,1,80.4,20.5a19.9,19.9,0,0,1,20.8,12l20.1,46.8a19.9,19.9,0,0,1-1.6,18.9l-15.4,23.4a72.3,72.3,0,0,0,30.3,30.2l23.1-15.4a20.1,20.1,0,0,1,19-1.7l46.8,20.1A19.9,19.9,0,0,1,235.5,175.6Zm-24.3,0-42-18L146,173.1a20.1,20.1,0,0,1-19.7,1.4,96.5,96.5,0,0,1-44.6-44.4A19.8,19.8,0,0,1,83,110.4L98.4,86.8l-18-42A36,36,0,0,0,52,80,124.1,124.1,0,0,0,176,204,36,36,0,0,0,211.2,175.6ZM145.6,89.5a31.7,31.7,0,0,1,20.9,20.9,12.1,12.1,0,0,0,11.5,8.4,11.3,11.3,0,0,0,3.6-.6,11.9,11.9,0,0,0,7.8-15,55.8,55.8,0,0,0-36.6-36.6,12,12,0,0,0-7.2,22.9Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M92.5,124.8a83.6,83.6,0,0,0,39,38.9,8,8,0,0,0,7.9-.6l25-16.7a7.9,7.9,0,0,1,7.6-.7l46.8,20.1a7.9,7.9,0,0,1,4.8,8.3A48,48,0,0,1,176,216,136,136,0,0,1,40,80,48,48,0,0,1,81.9,32.4a7.9,7.9,0,0,1,8.3,4.8l20.1,46.9a8,8,0,0,1-.6,7.5L93,117A8,8,0,0,0,92.5,124.8Z" />
          </g>
          <path d="M222,158.4l-46.9-20a15.6,15.6,0,0,0-15.1,1.3l-25.1,16.7a76.5,76.5,0,0,1-35.2-35h0L116.3,96a15.9,15.9,0,0,0,1.4-15.1L97.6,34a16.3,16.3,0,0,0-16.7-9.6A56.2,56.2,0,0,0,32,80c0,79.4,64.6,144,144,144a56.2,56.2,0,0,0,55.6-48.9A16.3,16.3,0,0,0,222,158.4ZM176,208A128.1,128.1,0,0,1,48,80,40,40,0,0,1,82.9,40.3L103,87.2,86.3,112.6a16,16,0,0,0-1,15.7,92.5,92.5,0,0,0,42.8,42.6,16,16,0,0,0,15.7-1.1l25-16.7,46.9,20A40,40,0,0,1,176,208Z" />
          <path d="M157.4,47.7a72.6,72.6,0,0,1,50.9,50.9,8,8,0,0,0,7.7,6,7.6,7.6,0,0,0,2.1-.3,7.9,7.9,0,0,0,5.6-9.8,88,88,0,0,0-62.2-62.2,8,8,0,1,0-4.1,15.4Z" />
          <path d="M149.1,78.6a40.4,40.4,0,0,1,28.3,28.3,7.9,7.9,0,0,0,7.7,6,6.4,6.4,0,0,0,2-.3,7.9,7.9,0,0,0,5.7-9.8,55.8,55.8,0,0,0-39.6-39.6,8,8,0,1,0-4.1,15.4Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M231.6,175.1A56.2,56.2,0,0,1,176,224C96.6,224,32,159.4,32,80A56.2,56.2,0,0,1,80.9,24.4,16.3,16.3,0,0,1,97.6,34l20.1,46.9A15.9,15.9,0,0,1,116.3,96L99.7,121.4h0a76.5,76.5,0,0,0,35.2,35L160,139.7a15.6,15.6,0,0,1,15.1-1.3l46.9,20A16.3,16.3,0,0,1,231.6,175.1ZM157.4,47.7a72.6,72.6,0,0,1,50.9,50.9,8,8,0,0,0,7.7,6,7.6,7.6,0,0,0,2.1-.3,7.9,7.9,0,0,0,5.6-9.8,88,88,0,0,0-62.2-62.2,8,8,0,1,0-4.1,15.4Zm-8.3,30.9a40.4,40.4,0,0,1,28.3,28.3,7.9,7.9,0,0,0,7.7,6,6.4,6.4,0,0,0,2-.3,7.9,7.9,0,0,0,5.7-9.8,55.8,55.8,0,0,0-39.6-39.6,8,8,0,1,0-4.1,15.4Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M153.6,38.5a6.1,6.1,0,0,1,7.4-4.3A86.1,86.1,0,0,1,221.8,95a6,6,0,0,1-4.3,7.4l-1.5.2a6,6,0,0,1-5.8-4.5,74.3,74.3,0,0,0-52.3-52.3A6.1,6.1,0,0,1,153.6,38.5Zm-4,38.2a42.4,42.4,0,0,1,29.7,29.7,6,6,0,0,0,5.8,4.5l1.5-.2a6.2,6.2,0,0,0,4.3-7.4,54.2,54.2,0,0,0-38.2-38.2,6.2,6.2,0,0,0-7.4,4.3A6,6,0,0,0,149.6,76.7Zm80,98.1A54.1,54.1,0,0,1,176,222C97.7,222,34,158.3,34,80A54.1,54.1,0,0,1,81.2,26.4a14,14,0,0,1,14.5,8.4l20.1,46.9a14.1,14.1,0,0,1-1.1,13.2L98,120.3a2.1,2.1,0,0,0-.1,1.9h0a78.2,78.2,0,0,0,36.2,36.1,1.9,1.9,0,0,0,1.9-.2l25.1-16.7a13.8,13.8,0,0,1,13.2-1.2l46.9,20.1A14,14,0,0,1,229.6,174.8Zm-11.9-1.5a2,2,0,0,0-1.2-2l-46.9-20.1a2.1,2.1,0,0,0-1.9.2l-25,16.7a14.2,14.2,0,0,1-13.8,1,90.8,90.8,0,0,1-41.8-41.7,14,14,0,0,1,.9-13.7l16.6-25.4a1.9,1.9,0,0,0,.2-1.9L84.7,39.5a2,2,0,0,0-1.8-1.2h-.2A42.2,42.2,0,0,0,46,80,130.1,130.1,0,0,0,176,210,42.2,42.2,0,0,0,217.7,173.3Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M155.6,39a4,4,0,0,1,4.9-2.9,84.4,84.4,0,0,1,59.4,59.4,4,4,0,0,1-2.9,4.9l-1,.2a4.1,4.1,0,0,1-3.9-3,76.2,76.2,0,0,0-53.7-53.7A3.9,3.9,0,0,1,155.6,39Zm-5.5,35.8a44,44,0,0,1,31.1,31.1,4.1,4.1,0,0,0,3.9,3l1-.2a3.9,3.9,0,0,0,2.8-4.9,51.6,51.6,0,0,0-36.7-36.7,3.9,3.9,0,0,0-4.9,2.8A4,4,0,0,0,150.1,74.8Zm77.5,99.8A52.1,52.1,0,0,1,176,220C98.8,220,36,157.2,36,80A52.1,52.1,0,0,1,81.4,28.4a12.1,12.1,0,0,1,12.5,7.2L114,82.5a12,12,0,0,1-1,11.3L96.4,119.2a3.9,3.9,0,0,0-.3,3.9h0a79.9,79.9,0,0,0,37.2,37,3.7,3.7,0,0,0,3.8-.3l25.1-16.7a11.9,11.9,0,0,1,11.4-1.1l46.8,20.1A12.1,12.1,0,0,1,227.6,174.6Zm-10.4-5.1-46.8-20.1a4,4,0,0,0-3.8.3l-25,16.7a11.9,11.9,0,0,1-11.8.9,88.6,88.6,0,0,1-40.9-40.7h0a12.1,12.1,0,0,1,.8-11.8l16.6-25.4a3.9,3.9,0,0,0,.3-3.8L86.5,38.8a3.9,3.9,0,0,0-3.6-2.5h-.5A44.1,44.1,0,0,0,44,80,132.1,132.1,0,0,0,176,212a44.1,44.1,0,0,0,43.7-38.4A4,4,0,0,0,217.2,169.5Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M151.7,37.9a7.9,7.9,0,0,1,9.8-5.6,88,88,0,0,1,62.2,62.2,7.9,7.9,0,0,1-5.6,9.8,7.6,7.6,0,0,1-2.1.3,8,8,0,0,1-7.7-6,72.6,72.6,0,0,0-50.9-50.9A7.9,7.9,0,0,1,151.7,37.9Zm-2.6,40.7a40.4,40.4,0,0,1,28.3,28.3,7.9,7.9,0,0,0,7.7,6,6.4,6.4,0,0,0,2-.3,7.9,7.9,0,0,0,5.7-9.8,55.8,55.8,0,0,0-39.6-39.6,8,8,0,1,0-4.1,15.4Zm82.5,96.5A56.2,56.2,0,0,1,176,224C96.6,224,32,159.4,32,80A56.2,56.2,0,0,1,80.9,24.4,16.3,16.3,0,0,1,97.6,34l20.1,46.9A15.9,15.9,0,0,1,116.3,96L99.7,121.4h0a76.5,76.5,0,0,0,35.2,35L160,139.7a15.6,15.6,0,0,1,15.1-1.3l46.9,20A16.3,16.3,0,0,1,231.6,175.1Zm-15.9-2-46.9-20-25,16.7a16,16,0,0,1-15.7,1.1,92.5,92.5,0,0,1-42.8-42.6,16,16,0,0,1,1-15.7L103,87.2,82.9,40.3A40,40,0,0,0,48,80,128.1,128.1,0,0,0,176,208,40,40,0,0,0,215.7,173.1Z" />
        </>
      )}
    </svg>
  );
}

export function PhPhoneDisconnect(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M232.7,87.3a148.3,148.3,0,0,0-209.4,0,60.2,60.2,0,0,0-5,79.2,20,20,0,0,0,15.8,7.7,21.1,21.1,0,0,0,7.4-1.4l47.3-19A19.9,19.9,0,0,0,101,139.2l5.5-27.2a71.5,71.5,0,0,1,42.7-.1l5.8,27.4a19.8,19.8,0,0,0,12.1,14.5l47.4,19a20,20,0,0,0,23.2-6.3A60.2,60.2,0,0,0,232.7,87.3Zm-12.2,62-42.5-17-5.7-27.6a20.3,20.3,0,0,0-13-14.8,97.1,97.1,0,0,0-63,.1,20.1,20.1,0,0,0-12.9,15l-5.5,27.4L35.5,149.3a36.1,36.1,0,0,1,4.8-45,124.3,124.3,0,0,1,175.4,0A36.1,36.1,0,0,1,220.5,149.3ZM228,200a12,12,0,0,1-12,12H40a12,12,0,0,1,0-24H216A12,12,0,0,1,228,200Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M155.4,101.3a83.9,83.9,0,0,0-55.1.1,8,8,0,0,0-5.2,5.9l-5.8,29.5a8.2,8.2,0,0,1-4.9,5.9L37.1,161.6a7.9,7.9,0,0,1-9.3-2.5,48,48,0,0,1,4-63.3,136.1,136.1,0,0,1,192.4,0,48,48,0,0,1,4,63.3,7.9,7.9,0,0,1-9.3,2.5l-47.3-18.9a8.2,8.2,0,0,1-4.9-5.8l-6.2-29.7A7.9,7.9,0,0,0,155.4,101.3Z" />
          </g>
          <path d="M229.8,90.2C173.7,34,82.3,34,26.2,90.2a56.1,56.1,0,0,0-4.7,73.9,16.2,16.2,0,0,0,12.6,6.1,17.1,17.1,0,0,0,5.9-1.1l47.4-19a16,16,0,0,0,9.7-11.7l5.9-29.5a76.3,76.3,0,0,1,49.7-.1h0l6.2,29.7a15.9,15.9,0,0,0,9.7,11.6l47.4,19a16.1,16.1,0,0,0,18.5-5A56.1,56.1,0,0,0,229.8,90.2Zm-7.9,64-47.3-18.9-6.3-29.8A15.8,15.8,0,0,0,158,93.7a92.6,92.6,0,0,0-60.4.1,16.1,16.1,0,0,0-10.3,12l-5.9,29.5L34.1,154.2a40,40,0,0,1,3.4-52.7,128.2,128.2,0,0,1,181,0A40,40,0,0,1,221.9,154.2Z" />
          <path d="M216,192H40a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M234.5,164.1a16.1,16.1,0,0,1-18.5,5l-47.4-19a15.9,15.9,0,0,1-9.7-11.6l-6.2-29.7h0a76.3,76.3,0,0,0-49.7.1l-5.9,29.5a16,16,0,0,1-9.7,11.7L40,169.1a17.1,17.1,0,0,1-5.9,1.1,16.2,16.2,0,0,1-12.6-6.1,56.1,56.1,0,0,1,4.7-73.9C82.3,34,173.7,34,229.8,90.2A56.1,56.1,0,0,1,234.5,164.1ZM216,192H40a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M228.4,91.6a142.1,142.1,0,0,0-200.8,0,54,54,0,0,0-4.5,71.2,13.9,13.9,0,0,0,16.2,4.4l47.3-18.9A13.9,13.9,0,0,0,95.1,138l5.9-29.5a2.3,2.3,0,0,1,1.2-1.5,78.8,78.8,0,0,1,51.2-.1h0a1.9,1.9,0,0,1,1.2,1.5l6.2,29.7a14.3,14.3,0,0,0,8.6,10.2l47.3,18.9a14,14,0,0,0,16.2-4.4A54,54,0,0,0,228.4,91.6Zm-4.9,63.9a2.2,2.2,0,0,1-2.3.6l-47.4-19a1.9,1.9,0,0,1-1.2-1.4l-6.2-29.8a14,14,0,0,0-9.1-10.3,90.4,90.4,0,0,0-59,.1,13.8,13.8,0,0,0-9,10.5l-5.9,29.5a2,2,0,0,1-1.3,1.4l-47.3,19a2.2,2.2,0,0,1-2.3-.6,42,42,0,0,1,3.6-55.4,130.1,130.1,0,0,1,183.8,0A42,42,0,0,1,223.5,155.5ZM222,200a6,6,0,0,1-6,6H40a6,6,0,0,1,0-12H216A6,6,0,0,1,222,200Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M227,93C172.4,38.4,83.6,38.4,29,93a52.1,52.1,0,0,0-4.4,68.6,12,12,0,0,0,14,3.7l47.3-18.9a12.1,12.1,0,0,0,7.3-8.8l5.9-29.5a3.6,3.6,0,0,1,2.5-2.9,80.7,80.7,0,0,1,52.5-.2h0a4.2,4.2,0,0,1,2.5,3l6.2,29.7a11.9,11.9,0,0,0,7.3,8.7l47.3,18.9a12.1,12.1,0,0,0,14-3.7A52.1,52.1,0,0,0,227,93Zm-2,63.7a3.8,3.8,0,0,1-4.6,1.2L173.1,139a4.1,4.1,0,0,1-2.5-2.9l-6.2-29.8a11.7,11.7,0,0,0-7.7-8.8h0a88.7,88.7,0,0,0-57.8.1,12.2,12.2,0,0,0-7.7,8.9l-5.9,29.6a3.7,3.7,0,0,1-2.4,2.9L35.6,157.9a3.8,3.8,0,0,1-4.6-1.2,43.9,43.9,0,0,1,3.7-58,132.1,132.1,0,0,1,186.6,0A43.9,43.9,0,0,1,225,156.7ZM220,200a4,4,0,0,1-4,4H40a4,4,0,0,1,0-8H216A4,4,0,0,1,220,200Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M229.8,90.2C173.7,34,82.3,34,26.2,90.2a56.1,56.1,0,0,0-4.7,73.9,16.2,16.2,0,0,0,12.6,6.1,17.1,17.1,0,0,0,5.9-1.1l47.4-19a16,16,0,0,0,9.7-11.7l5.9-29.5a76.3,76.3,0,0,1,49.7-.1h0l6.2,29.7a15.9,15.9,0,0,0,9.7,11.6l47.4,19a16.1,16.1,0,0,0,18.5-5A56.1,56.1,0,0,0,229.8,90.2Zm-7.9,64-47.3-18.9-6.3-29.8A15.8,15.8,0,0,0,158,93.7a92.6,92.6,0,0,0-60.4.1,16.1,16.1,0,0,0-10.3,12l-5.9,29.5L34.1,154.2a40,40,0,0,1,3.4-52.7,128.2,128.2,0,0,1,181,0A40,40,0,0,1,221.9,154.2ZM224,200a8,8,0,0,1-8,8H40a8,8,0,0,1,0-16H216A8,8,0,0,1,224,200Z" />
        </>
      )}
    </svg>
  );
}

export function PhPhoneIncoming(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M223.5,154.8l-46.8-20.1a20.1,20.1,0,0,0-19,1.7l-23.1,15.4a72.3,72.3,0,0,1-30.3-30.2l15.4-23.4a19.9,19.9,0,0,0,1.6-18.9L101.2,32.5a19.9,19.9,0,0,0-20.8-12A60,60,0,0,0,28,80c0,81.6,66.4,148,148,148a60,60,0,0,0,59.5-52.4A19.9,19.9,0,0,0,223.5,154.8ZM176,204A124.1,124.1,0,0,1,52,80,36,36,0,0,1,80.4,44.8l18,42L83,110.4a19.8,19.8,0,0,0-1.3,19.7,96.5,96.5,0,0,0,44.6,44.4,20.1,20.1,0,0,0,19.7-1.4l23.2-15.5,42,18A36,36,0,0,1,176,204ZM148,96V56a12,12,0,0,1,24,0V67l27.5-27.5a12,12,0,0,1,17,17L189,84h11a12,12,0,0,1,0,24H157.7l-.6-.2h-.6l-.5-.2-.6-.2-.5-.2-.6-.3-.4-.3-.6-.3-.4-.4-.5-.3-.8-.7h-.2l-.7-.8-.3-.5-.4-.4-.3-.6-.3-.4-.3-.6c0-.2-.1-.3-.2-.5s-.1-.4-.2-.6-.1-.3-.2-.5-.1-.4-.1-.6-.1-.4-.2-.6a1.3,1.3,0,0,0-.1-.6v-.5A4.9,4.9,0,0,1,148,96Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M92.5,124.8a83.6,83.6,0,0,0,39,38.9,8,8,0,0,0,7.9-.6l25-16.7a7.9,7.9,0,0,1,7.6-.7l46.8,20.1a7.9,7.9,0,0,1,4.8,8.3A48,48,0,0,1,176,216,136,136,0,0,1,40,80,48,48,0,0,1,81.9,32.4a7.9,7.9,0,0,1,8.3,4.8l20.1,46.9a8,8,0,0,1-.6,7.5L93,117A8,8,0,0,0,92.5,124.8Z" />
          </g>
          <path d="M222,158.4l-46.9-20a15.6,15.6,0,0,0-15.1,1.3l-25.1,16.7a76.5,76.5,0,0,1-35.2-35h0L116.3,96a15.9,15.9,0,0,0,1.4-15.1L97.6,34a16.3,16.3,0,0,0-16.7-9.6A56.2,56.2,0,0,0,32,80c0,79.4,64.6,144,144,144a56.2,56.2,0,0,0,55.6-48.9A16.3,16.3,0,0,0,222,158.4ZM176,208A128.1,128.1,0,0,1,48,80,40,40,0,0,1,82.9,40.3L103,87.2,86.3,112.6a16,16,0,0,0-1,15.7,92.5,92.5,0,0,0,42.8,42.6,16,16,0,0,0,15.7-1.1l25-16.7,46.9,20A40,40,0,0,1,176,208Z" />
          <path d="M152,96.8a.4.4,0,0,1,.1.3.9.9,0,0,0,.1.5v.3a.8.8,0,0,1,.1.4l.2.4c0,.1.1.2.1.4l.2.3c0,.1.1.2.1.4l.2.3.3.3.2.3a.8.8,0,0,0,.2.4c.1.1.3.2.4.4a.3.3,0,0,1,.1.2h.2l.4.4.4.2.3.3.3.2.3.2h.4l.3.2h.4l.4.2H200a8,8,0,0,0,0-16H179.3l34.4-34.3a8.1,8.1,0,0,0-11.4-11.4L168,76.7V56a8,8,0,0,0-16,0V96.8Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M231.6,175.1A56.2,56.2,0,0,1,176,224C96.6,224,32,159.4,32,80A56.2,56.2,0,0,1,80.9,24.4,16.3,16.3,0,0,1,97.6,34l20.1,46.9A15.9,15.9,0,0,1,116.3,96L99.7,121.4h0a76.5,76.5,0,0,0,35.2,35L160,139.7a15.6,15.6,0,0,1,15.1-1.3l46.9,20A16.3,16.3,0,0,1,231.6,175.1Zm-79.5-78a.9.9,0,0,0,.1.5v.3a.8.8,0,0,1,.1.4l.2.4c0,.1.1.2.1.4l.2.3c0,.1.1.2.1.4l.2.3.3.3.2.3a.8.8,0,0,0,.2.4c.1.1.3.2.4.4a.3.3,0,0,1,.1.2h.2l.4.4.4.2.3.3.3.2.3.2h.4l.3.2h.4l.4.2H200a8,8,0,0,0,0-16H179.3l34.4-34.3a8.1,8.1,0,0,0-11.4-11.4L168,76.7V56a8,8,0,0,0-16,0V96.8A.4.4,0,0,1,152.1,97.1Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M221.2,160.3l-46.9-20.1a13.8,13.8,0,0,0-13.2,1.2L136,158.1a1.9,1.9,0,0,1-1.9.2,78.2,78.2,0,0,1-36.2-36.1h0a2.1,2.1,0,0,1,.1-1.9l16.7-25.4a14.1,14.1,0,0,0,1.1-13.2L95.7,34.8a14,14,0,0,0-14.5-8.4A54.1,54.1,0,0,0,34,80c0,78.3,63.7,142,142,142a54.1,54.1,0,0,0,53.6-47.2A14,14,0,0,0,221.2,160.3ZM176,210A130.1,130.1,0,0,1,46,80,42.2,42.2,0,0,1,82.7,38.3h.2a2,2,0,0,1,1.8,1.2l20.1,46.9a1.9,1.9,0,0,1-.2,1.9L88,113.7a14,14,0,0,0-.9,13.7,90.8,90.8,0,0,0,41.8,41.7,14.2,14.2,0,0,0,13.8-1l25-16.7a2.1,2.1,0,0,1,1.9-.2l46.9,20.1a2,2,0,0,1,1.2,2A42.2,42.2,0,0,1,176,210ZM154.1,97.1A4.1,4.1,0,0,1,154,96V56a6,6,0,0,1,12,0V81.5l37.8-37.7a5.9,5.9,0,0,1,8.4,8.4L174.5,90H200a6,6,0,0,1,0,12H158.8l-1-.3h-.1l-1-.5h0a4.6,4.6,0,0,1-1.7-1.7h0a9.3,9.3,0,0,1-.5-1h-.1l-.3-1Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M220.4,162.1,173.6,142a11.9,11.9,0,0,0-11.4,1.1l-25.1,16.7a3.7,3.7,0,0,1-3.8.3,79.9,79.9,0,0,1-37.2-37h0a3.9,3.9,0,0,1,.3-3.9L113,93.8a12,12,0,0,0,1-11.3L93.9,35.6a12.1,12.1,0,0,0-12.5-7.2A52.1,52.1,0,0,0,36,80c0,77.2,62.8,140,140,140a52.1,52.1,0,0,0,51.6-45.4A12.1,12.1,0,0,0,220.4,162.1Zm-.7,11.5A44.1,44.1,0,0,1,176,212,132.1,132.1,0,0,1,44,80,44.1,44.1,0,0,1,82.4,36.3h.5a3.9,3.9,0,0,1,3.6,2.5l20.1,46.8a3.9,3.9,0,0,1-.3,3.8L89.7,114.8a12.1,12.1,0,0,0-.8,11.8h0a88.6,88.6,0,0,0,40.9,40.7,11.9,11.9,0,0,0,11.8-.9l25-16.7a4,4,0,0,1,3.8-.3l46.8,20.1A4,4,0,0,1,219.7,173.6ZM156.1,96.8a2.2,2.2,0,0,1-.1-.8V56a4,4,0,0,1,8,0V86.3l41.2-41.1a4,4,0,1,1,5.6,5.6L169.7,92H200a4,4,0,0,1,0,8H158.5l-.4-.2-.3-.2a2.9,2.9,0,0,1-1.1-1.1l-.2-.3-.2-.4c0-.1-.1-.2-.1-.4A.4.4,0,0,1,156.1,96.8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M222,158.4l-46.9-20a15.6,15.6,0,0,0-15.1,1.3l-25.1,16.7a76.5,76.5,0,0,1-35.2-35h0L116.3,96a15.9,15.9,0,0,0,1.4-15.1L97.6,34a16.3,16.3,0,0,0-16.7-9.6A56.2,56.2,0,0,0,32,80c0,79.4,64.6,144,144,144a56.2,56.2,0,0,0,55.6-48.9A16.3,16.3,0,0,0,222,158.4ZM176,208A128.1,128.1,0,0,1,48,80,40,40,0,0,1,82.9,40.3L103,87.2,86.3,112.6a16,16,0,0,0-1,15.7,92.5,92.5,0,0,0,42.8,42.6,16,16,0,0,0,15.7-1.1l25-16.7,46.9,20A40,40,0,0,1,176,208ZM152,96V56a8,8,0,0,1,16,0V76.7l34.3-34.4a8.1,8.1,0,0,1,11.4,11.4L179.3,88H200a8,8,0,0,1,0,16H157.7l-.4-.2h-.4l-.3-.2h-.4l-.3-.2-.3-.2-.3-.3-.4-.2-.4-.4h-.2a.3.3,0,0,0-.1-.2c-.1-.2-.3-.3-.4-.4a.8.8,0,0,1-.2-.4l-.2-.3-.3-.3-.2-.3c0-.2-.1-.3-.1-.4l-.2-.3c0-.2-.1-.3-.1-.4l-.2-.4a.8.8,0,0,0-.1-.4v-.3a.9.9,0,0,1-.1-.5.4.4,0,0,0-.1-.3Z" />
        </>
      )}
    </svg>
  );
}

export function PhPhoneOutgoing(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M223.5,154.8l-46.8-20.1a20.1,20.1,0,0,0-19,1.7l-23.1,15.4a72.3,72.3,0,0,1-30.3-30.2l15.4-23.4a19.9,19.9,0,0,0,1.6-18.9L101.2,32.5a19.9,19.9,0,0,0-20.8-12A60,60,0,0,0,28,80c0,81.6,66.4,148,148,148a60,60,0,0,0,59.5-52.4A19.9,19.9,0,0,0,223.5,154.8ZM176,204A124.1,124.1,0,0,1,52,80,36,36,0,0,1,80.4,44.8l18,42L83,110.4a19.8,19.8,0,0,0-1.3,19.7,96.5,96.5,0,0,0,44.6,44.4,20.1,20.1,0,0,0,19.7-1.4l23.2-15.5,42,18A36,36,0,0,1,176,204Zm-24.5-99.5a12,12,0,0,1,0-17L179,60H168a12,12,0,0,1,0-24h40a12,12,0,0,1,12,12V88a12,12,0,0,1-24,0V77l-27.5,27.5a12.1,12.1,0,0,1-17,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M223.6,174.1A48,48,0,0,1,176,216,136,136,0,0,1,40,80,48,48,0,0,1,81.9,32.4a7.9,7.9,0,0,1,8.3,4.8l20.1,46.9a8,8,0,0,1-.6,7.5L93,117a8,8,0,0,0-.5,7.8,83.6,83.6,0,0,0,39,38.9,8,8,0,0,0,7.9-.6l25-16.7a7.9,7.9,0,0,1,7.6-.7l46.8,20.1A7.9,7.9,0,0,1,223.6,174.1Z"
            opacity="0.2"
          />
          <path d="M222,158.4l-46.9-20a15.6,15.6,0,0,0-15.1,1.3l-25.1,16.7a76.5,76.5,0,0,1-35.2-35h0L116.3,96a15.9,15.9,0,0,0,1.4-15.1L97.6,34a16.3,16.3,0,0,0-16.7-9.6A56.2,56.2,0,0,0,32,80c0,79.4,64.6,144,144,144a56.2,56.2,0,0,0,55.6-48.9A16.3,16.3,0,0,0,222,158.4ZM176,208A128.1,128.1,0,0,1,48,80,40,40,0,0,1,82.9,40.3L103,87.2,86.3,112.6a16,16,0,0,0-1,15.7,92.5,92.5,0,0,0,42.8,42.6,16,16,0,0,0,15.7-1.1l25-16.7,46.9,20A40,40,0,0,1,176,208ZM154.3,101.7a8.1,8.1,0,0,1,0-11.4L188.7,56H168a8,8,0,0,1,0-16h40a8,8,0,0,1,8,8V88a8,8,0,0,1-16,0V67.3l-34.3,34.4a8.2,8.2,0,0,1-11.4,0Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M231.6,175.1A56.2,56.2,0,0,1,176,224C96.6,224,32,159.4,32,80A56.2,56.2,0,0,1,80.9,24.4,16.3,16.3,0,0,1,97.6,34l20.1,46.9A15.9,15.9,0,0,1,116.3,96L99.7,121.4h0a76.5,76.5,0,0,0,35.2,35L160,139.7a15.6,15.6,0,0,1,15.1-1.3l46.9,20A16.3,16.3,0,0,1,231.6,175.1ZM160,104a8.5,8.5,0,0,0,5.7-2.3L200,67.3V88a8,8,0,0,0,16,0V48a8,8,0,0,0-8-8H168a8,8,0,0,0,0,16h20.7L154.3,90.3a8.1,8.1,0,0,0,0,11.4A8.5,8.5,0,0,0,160,104Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M221.2,160.3l-46.9-20.1a13.8,13.8,0,0,0-13.2,1.2L136,158.1a1.9,1.9,0,0,1-1.9.2,78.2,78.2,0,0,1-36.2-36.1h0a2.1,2.1,0,0,1,.1-1.9l16.7-25.4a14.1,14.1,0,0,0,1.1-13.2L95.7,34.8a14,14,0,0,0-14.5-8.4A54.1,54.1,0,0,0,34,80c0,78.3,63.7,142,142,142a54.1,54.1,0,0,0,53.6-47.2A14,14,0,0,0,221.2,160.3ZM176,210A130.1,130.1,0,0,1,46,80,42.2,42.2,0,0,1,82.7,38.3h.2a2,2,0,0,1,1.8,1.2l20.1,46.9a1.9,1.9,0,0,1-.2,1.9L88,113.7a14,14,0,0,0-.9,13.7,90.8,90.8,0,0,0,41.8,41.7,14.2,14.2,0,0,0,13.8-1l25-16.7a2.1,2.1,0,0,1,1.9-.2l46.9,20.1a2,2,0,0,1,1.2,2A42.2,42.2,0,0,1,176,210ZM155.8,100.2a5.8,5.8,0,0,1,0-8.4L193.5,54H168a6,6,0,0,1,0-12h40a6,6,0,0,1,6,6V88a6,6,0,0,1-12,0V62.5l-37.8,37.7a5.8,5.8,0,0,1-8.4,0Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M220.4,162.1,173.6,142a11.9,11.9,0,0,0-11.4,1.1l-25.1,16.7a3.7,3.7,0,0,1-3.8.3,79.9,79.9,0,0,1-37.2-37h0a3.9,3.9,0,0,1,.3-3.9L113,93.8a12,12,0,0,0,1-11.3L93.9,35.6a12.1,12.1,0,0,0-12.5-7.2A52.1,52.1,0,0,0,36,80c0,77.2,62.8,140,140,140a52.1,52.1,0,0,0,51.6-45.4A12.1,12.1,0,0,0,220.4,162.1Zm-.7,11.5A44.1,44.1,0,0,1,176,212,132.1,132.1,0,0,1,44,80,44.1,44.1,0,0,1,82.4,36.3h.5a3.9,3.9,0,0,1,3.6,2.5l20.1,46.8a3.9,3.9,0,0,1-.3,3.8L89.7,114.8a12.1,12.1,0,0,0-.8,11.8,88.6,88.6,0,0,0,40.9,40.7,11.9,11.9,0,0,0,11.8-.9l25-16.7a4,4,0,0,1,3.8-.3l46.8,20.1A4,4,0,0,1,219.7,173.6ZM157.2,98.8a3.9,3.9,0,0,1,0-5.6L198.3,52H168a4,4,0,0,1,0-8h40a4,4,0,0,1,4,4V88a4,4,0,0,1-8,0V57.7L162.8,98.8a3.9,3.9,0,0,1-5.6,0Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M222,158.4l-46.9-20a15.6,15.6,0,0,0-15.1,1.3l-25.1,16.7a76.5,76.5,0,0,1-35.2-35h0L116.3,96a15.9,15.9,0,0,0,1.4-15.1L97.6,34a16.3,16.3,0,0,0-16.7-9.6A56.2,56.2,0,0,0,32,80c0,79.4,64.6,144,144,144a56.2,56.2,0,0,0,55.6-48.9A16.3,16.3,0,0,0,222,158.4ZM176,208A128.1,128.1,0,0,1,48,80,40,40,0,0,1,82.9,40.3L103,87.2,86.3,112.6a16,16,0,0,0-1,15.7,92.5,92.5,0,0,0,42.8,42.6,16,16,0,0,0,15.7-1.1l25-16.7,46.9,20A40,40,0,0,1,176,208ZM154.3,101.7a8.1,8.1,0,0,1,0-11.4L188.7,56H168a8,8,0,0,1,0-16h40a8,8,0,0,1,8,8V88a8,8,0,0,1-16,0V67.3l-34.3,34.4a8.2,8.2,0,0,1-11.4,0Z" />
        </>
      )}
    </svg>
  );
}

export function PhPhoneSlash(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M123.8,105.6h0l-31-34.1a.1.1,0,0,1-.1-.1L56.9,31.9A12,12,0,0,0,39.1,48.1L63.4,74.8a147.4,147.4,0,0,0-40.1,28.5,60.2,60.2,0,0,0-5,79.2,20,20,0,0,0,15.8,7.7,21.1,21.1,0,0,0,7.4-1.4l47.3-19A19.9,19.9,0,0,0,101,155.2l5.5-27.2a33.1,33.1,0,0,1,4.2-1.2l88.4,97.3a12.1,12.1,0,0,0,17,.8,12.1,12.1,0,0,0,.8-17ZM83.4,121l-5.5,27.4L35.5,165.3a36.1,36.1,0,0,1,4.8-45A123.8,123.8,0,0,1,80.4,93.5l12.7,14A20,20,0,0,0,83.4,121Zm154.3,61.5a22.4,22.4,0,0,1-4.5,4.2,12,12,0,0,1-14.1-19.4,36.1,36.1,0,0,0-3.4-47A124.1,124.1,0,0,0,130.9,84a12,12,0,0,1,.3-24h.3a147.7,147.7,0,0,1,101.2,43.3A60.2,60.2,0,0,1,237.7,182.5Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M155.4,117.3a83.9,83.9,0,0,0-55.1.1,8,8,0,0,0-5.2,5.9l-5.8,29.5a8.2,8.2,0,0,1-4.9,5.9L37.1,177.6a7.9,7.9,0,0,1-9.3-2.5,48,48,0,0,1,4-63.3,136.1,136.1,0,0,1,192.4,0,48,48,0,0,1,4,63.3,7.9,7.9,0,0,1-9.3,2.5l-47.3-18.9a8.2,8.2,0,0,1-4.9-5.8l-6.2-29.7A7.9,7.9,0,0,0,155.4,117.3Z" />
          </g>
          <path d="M120.9,108.3h0L89.8,74h-.1L53.9,34.6A8,8,0,0,0,42.1,45.4L70,76.1a143.9,143.9,0,0,0-43.8,30.1,56.1,56.1,0,0,0-4.7,73.9,16.2,16.2,0,0,0,12.6,6.1,17.1,17.1,0,0,0,5.9-1.1l47.4-19a16,16,0,0,0,9.7-11.7l5.9-29.5a72.1,72.1,0,0,1,9.1-2.5l90,99A8,8,0,0,0,208,224a8.2,8.2,0,0,0,5.4-2.1,7.9,7.9,0,0,0,.5-11.3Zm-23.3,1.5a16.1,16.1,0,0,0-10.3,12l-5.9,29.5L34.1,170.2a40,40,0,0,1,3.4-52.7,126.8,126.8,0,0,1,44-28.8L100,109.1Z" />
          <path d="M229.8,106.2a144.4,144.4,0,0,0-109.7-42,8,8,0,1,0,.9,16,128,128,0,0,1,97.5,37.3,40,40,0,0,1,3.4,52.7l-4-1.6a8,8,0,0,0-6,14.8l4.1,1.7a17.1,17.1,0,0,0,5.9,1.1,16.2,16.2,0,0,0,12.6-6.1A56.1,56.1,0,0,0,229.8,106.2Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M213.9,210.6a7.9,7.9,0,0,1-.5,11.3A8.2,8.2,0,0,1,208,224a8,8,0,0,1-5.9-2.6l-90-99a72.1,72.1,0,0,0-9.1,2.5l-5.9,29.5a16,16,0,0,1-9.7,11.7L40,185.1a17.1,17.1,0,0,1-5.9,1.1,16.2,16.2,0,0,1-12.6-6.1,56.1,56.1,0,0,1,4.7-73.9A143.9,143.9,0,0,1,70,76.1L42.1,45.4A8,8,0,0,1,53.9,34.6L89.7,74h.1l31,34.2h.1Zm15.9-104.4a144.4,144.4,0,0,0-109.7-42,8,8,0,0,0-5.5,13.4L209,181.4a7.2,7.2,0,0,0,2.9,2l4.1,1.7a17.1,17.1,0,0,0,5.9,1.1,16.2,16.2,0,0,0,12.6-6.1A56.1,56.1,0,0,0,229.8,106.2Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M88.3,75.4h-.1L52.4,36a5.9,5.9,0,0,0-8.8,8L73.4,76.9a140.8,140.8,0,0,0-45.8,30.7,54,54,0,0,0-4.5,71.2,13.9,13.9,0,0,0,16.2,4.4l47.3-18.9A13.9,13.9,0,0,0,95.1,154l5.9-29.5a2.3,2.3,0,0,1,1.2-1.5,78.8,78.8,0,0,1,10.6-2.8L203.6,220a5.9,5.9,0,0,0,4.4,2,5.7,5.7,0,0,0,4-1.6,5.8,5.8,0,0,0,.4-8.4Zm10,36.3a13.8,13.8,0,0,0-9,10.5l-5.9,29.5a2,2,0,0,1-1.3,1.4l-47.3,19a2.2,2.2,0,0,1-2.3-.6,42,42,0,0,1,3.6-55.4A128.3,128.3,0,0,1,82,86.4l21.6,23.7Zm134.6,67.1a13.9,13.9,0,0,1-16.2,4.4l-4-1.6a6,6,0,1,1,4.4-11.2l4.1,1.7a2.2,2.2,0,0,0,2.3-.6,42,42,0,0,0-3.6-55.4,130.2,130.2,0,0,0-99-37.9,6,6,0,1,1-.7-12,142.2,142.2,0,0,1,108.2,41.4A54,54,0,0,1,232.9,178.8Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M117.9,111h0L86.8,76.7h0L51,37.3a4,4,0,1,0-6,5.4l31.8,35A138.6,138.6,0,0,0,29,109a52.1,52.1,0,0,0-4.4,68.6,12,12,0,0,0,14,3.7l47.3-18.9a12.1,12.1,0,0,0,7.3-8.8l5.9-29.5a3.6,3.6,0,0,1,2.5-2.9,83.7,83.7,0,0,1,11.9-3.2L205,218.7a4.1,4.1,0,0,0,5.7.3,4.2,4.2,0,0,0,.3-5.7Zm-19,2.6a12.2,12.2,0,0,0-7.7,8.9l-5.9,29.6a3.7,3.7,0,0,1-2.4,2.9L35.6,173.9a3.8,3.8,0,0,1-4.6-1.2,43.9,43.9,0,0,1,3.7-58A129.5,129.5,0,0,1,82.6,84l24.7,27.2A66.2,66.2,0,0,0,98.9,113.6Zm132.5,64a12,12,0,0,1-14,3.7l-4-1.6a3.9,3.9,0,0,1-2.2-5.2,4,4,0,0,1,5.2-2.2l4,1.6a3.8,3.8,0,0,0,4.6-1.2,43.9,43.9,0,0,0-3.7-58A132,132,0,0,0,120.7,76.2a4.1,4.1,0,0,1-4.2-3.8,4,4,0,0,1,3.8-4.2A140.2,140.2,0,0,1,227,109,52.1,52.1,0,0,1,231.4,177.6Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M120.9,108.3h-.1L89.8,74h-.1L53.9,34.6A8,8,0,0,0,42.1,45.4L70,76.1a143.9,143.9,0,0,0-43.8,30.1,56.1,56.1,0,0,0-4.7,73.9,16.2,16.2,0,0,0,12.6,6.1,17.1,17.1,0,0,0,5.9-1.1l47.4-19a16,16,0,0,0,9.7-11.7l5.9-29.5a72.1,72.1,0,0,1,9.1-2.5l90,99A8,8,0,0,0,208,224a8.2,8.2,0,0,0,5.4-2.1,7.9,7.9,0,0,0,.5-11.3Zm-23.3,1.5a16.1,16.1,0,0,0-10.3,12l-5.9,29.5L34.1,170.2a40,40,0,0,1,3.4-52.7,126.8,126.8,0,0,1,44-28.8L100,109.1Zm136.9,70.3a16.2,16.2,0,0,1-12.6,6.1,17.1,17.1,0,0,1-5.9-1.1l-4.1-1.7a8,8,0,0,1,6-14.8l4,1.6a40,40,0,0,0-3.4-52.7A128,128,0,0,0,121,80.2a8,8,0,1,1-.9-16,144.4,144.4,0,0,1,109.7,42A56.1,56.1,0,0,1,234.5,180.1Z" />
        </>
      )}
    </svg>
  );
}

export function PhPhoneX(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M223.5,154.8l-46.8-20.1a20.1,20.1,0,0,0-19,1.7l-23.1,15.4a72.3,72.3,0,0,1-30.3-30.2l15.4-23.4a19.9,19.9,0,0,0,1.6-18.9L101.2,32.5a19.9,19.9,0,0,0-20.8-12A60,60,0,0,0,28,80c0,81.6,66.4,148,148,148a60,60,0,0,0,59.5-52.4A19.9,19.9,0,0,0,223.5,154.8ZM176,204A124.1,124.1,0,0,1,52,80,36,36,0,0,1,80.4,44.8l18,42L83,110.4a19.8,19.8,0,0,0-1.3,19.7,96.5,96.5,0,0,0,44.6,44.4,20.1,20.1,0,0,0,19.7-1.4l23.2-15.5,42,18A36,36,0,0,1,176,204ZM151.5,87.5,167,72,151.5,56.5a12,12,0,0,1,17-17L184,55l15.5-15.5a12,12,0,0,1,17,17L201,72l15.5,15.5a12,12,0,0,1,0,17,12.1,12.1,0,0,1-17,0L184,89l-15.5,15.5a12.1,12.1,0,0,1-17,0A12,12,0,0,1,151.5,87.5Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M92.5,124.8a83.6,83.6,0,0,0,39,38.9,8,8,0,0,0,7.9-.6l25-16.7a7.9,7.9,0,0,1,7.6-.7l46.8,20.1a7.9,7.9,0,0,1,4.8,8.3A48,48,0,0,1,176,216,136,136,0,0,1,40,80,48,48,0,0,1,81.9,32.4a7.9,7.9,0,0,1,8.3,4.8l20.1,46.9a8,8,0,0,1-.6,7.5L93,117A8,8,0,0,0,92.5,124.8Z" />
          </g>
          <path d="M222,158.4l-46.9-20a15.6,15.6,0,0,0-15.1,1.3l-25.1,16.7a76.5,76.5,0,0,1-35.2-35h0L116.3,96a15.9,15.9,0,0,0,1.4-15.1L97.6,34a16.3,16.3,0,0,0-16.7-9.6A56.2,56.2,0,0,0,32,80c0,79.4,64.6,144,144,144a56.2,56.2,0,0,0,55.6-48.9A16.3,16.3,0,0,0,222,158.4ZM176,208A128.1,128.1,0,0,1,48,80,40,40,0,0,1,82.9,40.3L103,87.2,86.3,112.6a16,16,0,0,0-1,15.7,92.5,92.5,0,0,0,42.8,42.6,16,16,0,0,0,15.7-1.1l25-16.7,46.9,20A40,40,0,0,1,176,208Z" />
          <path d="M154.3,101.7a8.2,8.2,0,0,0,11.4,0L184,83.3l18.3,18.4a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4L195.3,72l18.4-18.3a8.1,8.1,0,0,0-11.4-11.4L184,60.7,165.7,42.3a8.1,8.1,0,0,0-11.4,11.4L172.7,72,154.3,90.3A8.1,8.1,0,0,0,154.3,101.7Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M231.6,175.1A56.2,56.2,0,0,1,176,224C96.6,224,32,159.4,32,80A56.2,56.2,0,0,1,80.9,24.4,16.3,16.3,0,0,1,97.6,34l20.1,46.9A15.9,15.9,0,0,1,116.3,96L99.7,121.4h0a76.5,76.5,0,0,0,35.2,35L160,139.7a15.6,15.6,0,0,1,15.1-1.3l46.9,20A16.3,16.3,0,0,1,231.6,175.1Zm-77.3-73.4a8.2,8.2,0,0,0,11.4,0L184,83.3l18.3,18.4a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4L195.3,72l18.4-18.3a8.1,8.1,0,0,0-11.4-11.4L184,60.7,165.7,42.3a8.1,8.1,0,0,0-11.4,11.4L172.7,72,154.3,90.3A8.1,8.1,0,0,0,154.3,101.7Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M221.2,160.3l-46.9-20.1a13.8,13.8,0,0,0-13.2,1.2L136,158.1a1.9,1.9,0,0,1-1.9.2,78.2,78.2,0,0,1-36.2-36.1h0a2.1,2.1,0,0,1,.1-1.9l16.7-25.4a14.1,14.1,0,0,0,1.1-13.2L95.7,34.8a14,14,0,0,0-14.5-8.4A54.1,54.1,0,0,0,34,80c0,78.3,63.7,142,142,142a54.1,54.1,0,0,0,53.6-47.2A14,14,0,0,0,221.2,160.3ZM176,210A130.1,130.1,0,0,1,46,80,42.2,42.2,0,0,1,82.7,38.3h.2a2,2,0,0,1,1.8,1.2l20.1,46.9a1.9,1.9,0,0,1-.2,1.9L88,113.7a14,14,0,0,0-.9,13.7,90.8,90.8,0,0,0,41.8,41.7,14.2,14.2,0,0,0,13.8-1l25-16.7a2.1,2.1,0,0,1,1.9-.2l46.9,20.1a2,2,0,0,1,1.2,2A42.2,42.2,0,0,1,176,210ZM155.8,91.8,175.5,72,155.8,52.2a5.9,5.9,0,0,1,8.4-8.4L184,63.5l19.8-19.7a5.9,5.9,0,0,1,8.4,8.4L192.5,72l19.7,19.8a5.9,5.9,0,1,1-8.4,8.4L184,80.5l-19.8,19.7a5.9,5.9,0,0,1-8.4-8.4Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M220.4,162.1,173.6,142a11.9,11.9,0,0,0-11.4,1.1l-25.1,16.7a3.7,3.7,0,0,1-3.8.3,79.9,79.9,0,0,1-37.2-37h0a3.9,3.9,0,0,1,.3-3.9L113,93.8a12,12,0,0,0,1-11.3L93.9,35.6a12.1,12.1,0,0,0-12.5-7.2A52.1,52.1,0,0,0,36,80c0,77.2,62.8,140,140,140a52.1,52.1,0,0,0,51.6-45.4A12.1,12.1,0,0,0,220.4,162.1Zm-.7,11.5A44.1,44.1,0,0,1,176,212,132.1,132.1,0,0,1,44,80,44.1,44.1,0,0,1,82.4,36.3h.5a3.9,3.9,0,0,1,3.6,2.5l20.1,46.8a3.9,3.9,0,0,1-.3,3.8L89.7,114.8a12.1,12.1,0,0,0-.8,11.8h0a88.6,88.6,0,0,0,40.9,40.7,11.9,11.9,0,0,0,11.8-.9l25-16.7a4.2,4.2,0,0,1,3.8-.3l46.8,20.1A4,4,0,0,1,219.7,173.6ZM157.2,93.2,178.3,72,157.2,50.8a4,4,0,0,1,5.6-5.6L184,66.3l21.2-21.1a4,4,0,1,1,5.6,5.6L189.7,72l21.1,21.2a3.9,3.9,0,0,1,0,5.6,3.9,3.9,0,0,1-5.6,0L184,77.7,162.8,98.8a3.9,3.9,0,0,1-5.6,0A3.9,3.9,0,0,1,157.2,93.2Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M222,158.4l-46.9-20a15.6,15.6,0,0,0-15.1,1.3l-25.1,16.7a76.5,76.5,0,0,1-35.2-35h0L116.3,96a15.9,15.9,0,0,0,1.4-15.1L97.6,34a16.3,16.3,0,0,0-16.7-9.6A56.2,56.2,0,0,0,32,80c0,79.4,64.6,144,144,144a56.2,56.2,0,0,0,55.6-48.9A16.3,16.3,0,0,0,222,158.4ZM176,208A128.1,128.1,0,0,1,48,80,40,40,0,0,1,82.9,40.3L103,87.2,86.3,112.6a16,16,0,0,0-1,15.7,92.5,92.5,0,0,0,42.8,42.6,16,16,0,0,0,15.7-1.1l25-16.7,46.9,20A40,40,0,0,1,176,208ZM154.3,90.3,172.7,72,154.3,53.7a8.1,8.1,0,0,1,11.4-11.4L184,60.7l18.3-18.4a8.1,8.1,0,0,1,11.4,11.4L195.3,72l18.4,18.3a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0L184,83.3l-18.3,18.4a8.2,8.2,0,0,1-11.4,0A8.1,8.1,0,0,1,154.3,90.3Z" />
        </>
      )}
    </svg>
  );
}

export function PhPhone(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M176,228C94.4,228,28,161.6,28,80A60,60,0,0,1,80.4,20.5a19.9,19.9,0,0,1,20.8,12l20.1,46.8a19.9,19.9,0,0,1-1.6,18.9l-15.4,23.4a72.3,72.3,0,0,0,30.3,30.2l23.1-15.4a20.1,20.1,0,0,1,19-1.7l46.8,20.1a19.9,19.9,0,0,1,12,20.8A60,60,0,0,1,176,228ZM80.4,44.8A36,36,0,0,0,52,80,124.1,124.1,0,0,0,176,204a36,36,0,0,0,35.2-28.4l-42-18L146,173.1a20.1,20.1,0,0,1-19.7,1.4,96.5,96.5,0,0,1-44.6-44.4A19.8,19.8,0,0,1,83,110.4L98.4,86.8Zm56.3,108.1h0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M92.5,124.8a83.6,83.6,0,0,0,39,38.9,8,8,0,0,0,7.9-.6l25-16.7a7.9,7.9,0,0,1,7.6-.7l46.8,20.1a7.9,7.9,0,0,1,4.8,8.3A48,48,0,0,1,176,216,136,136,0,0,1,40,80,48,48,0,0,1,81.9,32.4a7.9,7.9,0,0,1,8.3,4.8l20.1,46.9a8,8,0,0,1-.6,7.5L93,117A8,8,0,0,0,92.5,124.8Z" />
          </g>
          <path d="M176,224C96.6,224,32,159.4,32,80A56.2,56.2,0,0,1,80.9,24.4,16.3,16.3,0,0,1,97.6,34l20.1,46.9A15.9,15.9,0,0,1,116.3,96L99.7,121.4h0a76.5,76.5,0,0,0,35.2,35L160,139.7a15.6,15.6,0,0,1,15.1-1.3l46.9,20a16.3,16.3,0,0,1,9.6,16.7A56.2,56.2,0,0,1,176,224ZM82.9,40.3A40,40,0,0,0,48,80,128.1,128.1,0,0,0,176,208a40,40,0,0,0,39.7-34.9l-46.9-20-25,16.7a16,16,0,0,1-15.7,1.1,92.5,92.5,0,0,1-42.8-42.6,16,16,0,0,1,1-15.7L103,87.2ZM135,156.5Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M231.6,175.1A56.2,56.2,0,0,1,176,224C96.6,224,32,159.4,32,80A56.2,56.2,0,0,1,80.9,24.4,16.3,16.3,0,0,1,97.6,34l20.1,46.9A15.9,15.9,0,0,1,116.3,96L99.7,121.4h0a76.5,76.5,0,0,0,35.2,35L160,139.7a15.6,15.6,0,0,1,15.1-1.3l46.9,20A16.3,16.3,0,0,1,231.6,175.1Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M176,222C97.7,222,34,158.3,34,80A54.1,54.1,0,0,1,81.2,26.4a14,14,0,0,1,14.5,8.4l20.1,46.9a14.1,14.1,0,0,1-1.1,13.2L98,120.3a2.1,2.1,0,0,0-.1,1.9h0a78.2,78.2,0,0,0,36.2,36.1,1.9,1.9,0,0,0,1.9-.2l25.1-16.7a13.8,13.8,0,0,1,13.2-1.2l46.9,20.1a14,14,0,0,1,8.4,14.5A54.1,54.1,0,0,1,176,222ZM82.9,38.3h-.2A42.2,42.2,0,0,0,46,80,130.1,130.1,0,0,0,176,210a42.2,42.2,0,0,0,41.7-36.7,2,2,0,0,0-1.2-2l-46.9-20.1a2.1,2.1,0,0,0-1.9.2l-25,16.7a14.2,14.2,0,0,1-13.8,1,90.8,90.8,0,0,1-41.8-41.7,14,14,0,0,1,.9-13.7l16.6-25.4a1.9,1.9,0,0,0,.2-1.9L84.7,39.5A2,2,0,0,0,82.9,38.3Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M176,220C98.8,220,36,157.2,36,80A52.1,52.1,0,0,1,81.4,28.4a12.1,12.1,0,0,1,12.5,7.2L114,82.5a12,12,0,0,1-1,11.3L96.4,119.2a3.9,3.9,0,0,0-.3,3.9h0a79.9,79.9,0,0,0,37.2,37,3.7,3.7,0,0,0,3.8-.3l25.1-16.7a11.9,11.9,0,0,1,11.4-1.1l46.8,20.1a12.1,12.1,0,0,1,7.2,12.5A52.1,52.1,0,0,1,176,220ZM82.9,36.3h-.5A44.1,44.1,0,0,0,44,80,132.1,132.1,0,0,0,176,212a44.1,44.1,0,0,0,43.7-38.4,4,4,0,0,0-2.5-4.1l-46.8-20.1a4,4,0,0,0-3.8.3l-25,16.7a11.9,11.9,0,0,1-11.8.9,88.6,88.6,0,0,1-40.9-40.7h0a12.1,12.1,0,0,1,.8-11.8l16.6-25.4a3.9,3.9,0,0,0,.3-3.8L86.5,38.8A3.9,3.9,0,0,0,82.9,36.3Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M176,224C96.6,224,32,159.4,32,80A56.2,56.2,0,0,1,80.9,24.4,16.3,16.3,0,0,1,97.6,34l20.1,46.9A15.9,15.9,0,0,1,116.3,96L99.7,121.4h0a76.5,76.5,0,0,0,35.2,35L160,139.7a15.6,15.6,0,0,1,15.1-1.3l46.9,20a16.3,16.3,0,0,1,9.6,16.7A56.2,56.2,0,0,1,176,224ZM82.9,40.3A40,40,0,0,0,48,80,128.1,128.1,0,0,0,176,208a40,40,0,0,0,39.7-34.9l-46.9-20-25,16.7a16,16,0,0,1-15.7,1.1,92.5,92.5,0,0,1-42.8-42.6,16,16,0,0,1,1-15.7L103,87.2ZM135,156.5Z" />
        </>
      )}
    </svg>
  );
}

export function PhPhosphorLogo(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M220,98a78.1,78.1,0,0,0-78-78H68A12,12,0,0,0,56,32V164a78.1,78.1,0,0,0,78,78,12,12,0,0,0,12-12V175.9A78.1,78.1,0,0,0,220,98ZM80,82.8,114.6,152H80Zm42,30.4L87.4,44H122ZM81.4,176H122v40.7A53.9,53.9,0,0,1,81.4,176ZM146,151.8V44.2a53.9,53.9,0,0,1,0,107.6Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M68,32l66,132H68Zm74,0h-8V164h8a66,66,0,0,0,0-132Z"
            opacity="0.2"
          />
          <path d="M142,24H68a8,8,0,0,0-8,8V164a74.1,74.1,0,0,0,74,74,8,8,0,0,0,8-8V172a74,74,0,0,0,0-148ZM76,65.9,121.1,156H76Zm50,64.2L80.9,40H126ZM76.6,172H126v49.5A58.1,58.1,0,0,1,76.6,172ZM142,156V40a58,58,0,0,1,0,116Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M142,24H68a8,8,0,0,0-8,8V164a74.1,74.1,0,0,0,74,74,8,8,0,0,0,8-8V172a74,74,0,0,0,0-148ZM126,221.5A58.1,58.1,0,0,1,76.6,172H126Zm0-91.4L80.9,40H126ZM142,156V40a58,58,0,0,1,0,116Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M142,26H68a6,6,0,0,0-6,6V164a72.1,72.1,0,0,0,72,72,6,6,0,0,0,6-6V170h2a72,72,0,0,0,0-144ZM74,57.4,124.3,158H74Zm54,81.2L77.7,38H128ZM74.3,170H128v53.7A60.1,60.1,0,0,1,74.3,170ZM142,158h-2V38h2a60,60,0,0,1,0,120Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M142,28H68a4,4,0,0,0-4,4V164a70.1,70.1,0,0,0,70,70,4,4,0,0,0,4-4V168h4a70,70,0,0,0,0-140ZM72,48.9,127.5,160H72Zm58,98.2L74.5,36H130ZM72.1,168H130v57.9A62.2,62.2,0,0,1,72.1,168Zm69.9-8h-4V36h4a62,62,0,0,1,0,124Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M142,24H68a8,8,0,0,0-8,8V164a74.1,74.1,0,0,0,74,74,8,8,0,0,0,8-8V172a74,74,0,0,0,0-148ZM76,65.9,121.1,156H76Zm50,64.2L80.9,40H126ZM76.6,172H126v49.5A58.1,58.1,0,0,1,76.6,172ZM142,156V40a58,58,0,0,1,0,116Z" />
        </>
      )}
    </svg>
  );
}

export function PhPianoKeys(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M208,28H48A20.1,20.1,0,0,0,28,48V208a20.1,20.1,0,0,0,20,20H208a20.1,20.1,0,0,0,20-20V48A20.1,20.1,0,0,0,208,28ZM116,132H92V52h24Zm12,24h16v48H112V156Zm36-24H140V52h24ZM52,52H68v92a12,12,0,0,0,12,12h8v48H52ZM204,204H168V156h8a12,12,0,0,0,12-12V52h16Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M184,40V144H144V40ZM72,144h40V40H72Z" opacity="0.2" />
          <path d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM80,48h24v88H80Zm32,104a8,8,0,0,0,8-8V48h16v96a8,8,0,0,0,8,8h12v56H100V152Zm40-16V48h24v88ZM48,48H64v96a8,8,0,0,0,8,8H84v56H48ZM208,208H172V152h12a8,8,0,0,0,8-8V48h16V208Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM108,152a8,8,0,0,0,8-8V48h24v96a8,8,0,0,0,8,8h8v56H100V152ZM48,48H68v96a8,8,0,0,0,8,8h8v56H48ZM208,208H172V152h8a8,8,0,0,0,8-8V48h20V208Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M208,34H48A14,14,0,0,0,34,48V208a14,14,0,0,0,14,14H208a14,14,0,0,0,14-14V48A14,14,0,0,0,208,34ZM78,46h28v92H78Zm34,104a6,6,0,0,0,6-6V46h20v98a6,6,0,0,0,6,6h14v60H98V150Zm38-12V46h28v92ZM46,208V48a2,2,0,0,1,2-2H66v98a6,6,0,0,0,6,6H86v60H48A2,2,0,0,1,46,208Zm164,0a2,2,0,0,1-2,2H170V150h14a6,6,0,0,0,6-6V46h18a2,2,0,0,1,2,2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M208,36H48A12,12,0,0,0,36,48V208a12,12,0,0,0,12,12H208a12,12,0,0,0,12-12V48A12,12,0,0,0,208,36ZM76,44h32v96H76Zm36,104a4,4,0,0,0,4-4V44h24V144a4,4,0,0,0,4,4h16v64H96V148Zm36-8V44h32v96ZM44,208V48a4,4,0,0,1,4-4H68V144a4,4,0,0,0,4,4H88v64H48A4,4,0,0,1,44,208Zm168,0a4,4,0,0,1-4,4H168V148h16a4,4,0,0,0,4-4V44h20a4,4,0,0,1,4,4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM80,48h24v88H80Zm32,104a8,8,0,0,0,8-8V48h16v96a8,8,0,0,0,8,8h12v56H100V152Zm40-16V48h24v88ZM48,48H64v96a8,8,0,0,0,8,8H84v56H48ZM208,208H172V152h12a8,8,0,0,0,8-8V48h16V208Z" />
        </>
      )}
    </svg>
  );
}

export function PhPictureInPicture(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216,44H40A20.1,20.1,0,0,0,20,64V192a20.1,20.1,0,0,0,20,20H216a20.1,20.1,0,0,0,20-20V64A20.1,20.1,0,0,0,216,44ZM44,68H212v48H144a20.1,20.1,0,0,0-20,20v52H44ZM148,188V140h64v48Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M216,56H40a8,8,0,0,0-8,8V192a8,8,0,0,0,8,8h96V136a8,8,0,0,1,8-8h80V64A8,8,0,0,0,216,56Z"
            opacity="0.2"
          />
          <path d="M216,48H40A16,16,0,0,0,24,64V192a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V64A16,16,0,0,0,216,48ZM40,64H216v56H144a16,16,0,0,0-16,16v56H40ZM216,192H144V136h72v56Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,48H40A16,16,0,0,0,24,64V192a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V64A16,16,0,0,0,216,48Zm-72,72a16,16,0,0,0-16,16v56H40V64H216v56Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M216,50H40A14,14,0,0,0,26,64V192a14,14,0,0,0,14,14H216a14,14,0,0,0,14-14V64A14,14,0,0,0,216,50ZM38,192V64a2,2,0,0,1,2-2H216a2,2,0,0,1,2,2v58H144a14,14,0,0,0-14,14v58H40A2,2,0,0,1,38,192Zm178,2H142V136a2,2,0,0,1,2-2h74v58A2,2,0,0,1,216,194Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M216,52H40A12,12,0,0,0,28,64V192a12,12,0,0,0,12,12H216a12,12,0,0,0,12-12V64A12,12,0,0,0,216,52ZM36,192V64a4,4,0,0,1,4-4H216a4,4,0,0,1,4,4v60H144a12,12,0,0,0-12,12v60H40A4,4,0,0,1,36,192Zm180,4H140V136a4,4,0,0,1,4-4h76v60A4,4,0,0,1,216,196Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,48H40A16,16,0,0,0,24,64V192a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V64A16,16,0,0,0,216,48ZM40,64H216v56H144a16,16,0,0,0-16,16v56H40ZM216,192H144V136h72v56Z" />
        </>
      )}
    </svg>
  );
}

export function PhPill(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216.5,39.5a57.2,57.2,0,0,0-81,0l-96,96a57.3,57.3,0,1,0,81,81l96-96A57.4,57.4,0,0,0,216.5,39.5Zm-113,160a33.2,33.2,0,0,1-47-47L96,113l47,47Zm96-96L160,143,113,96l39.5-39.5a33.2,33.2,0,0,1,47,47ZM185.2,80.2a11.9,11.9,0,0,1-.5,17l-16.4,15.6a12,12,0,0,1-16.5-17.4l16.4-15.6A12,12,0,0,1,185.2,80.2Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M160,160l-48,48a45.2,45.2,0,0,1-64,0h0a45.2,45.2,0,0,1,0-64L96,96Z"
            opacity="0.2"
          />
          <path d="M213.7,42.3a53.4,53.4,0,0,0-75.4,0l-96,96a53.3,53.3,0,0,0,75.4,75.4l96-96A53.5,53.5,0,0,0,213.7,42.3Zm-107.4,160a36.9,36.9,0,0,1-52.6,0,37.1,37.1,0,0,1,0-52.6L96,107.3,148.7,160Zm96-96L160,148.7,107.3,96l42.4-42.3a36.9,36.9,0,0,1,52.6,0A37.1,37.1,0,0,1,202.3,106.3ZM190.2,82.9a7.9,7.9,0,0,1-.2,11.3l-24.4,23.6a7.9,7.9,0,0,1-11.3-.2,7.9,7.9,0,0,1,.2-11.3l24.4-23.6A8,8,0,0,1,190.2,82.9Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M190.2,82.9a7.9,7.9,0,0,1-.2,11.3l-24.4,23.6a7.9,7.9,0,0,1-11.3-.2,7.9,7.9,0,0,1,.2-11.3l24.4-23.6A8,8,0,0,1,190.2,82.9Zm23.5,34.8-96,96a53.3,53.3,0,0,1-75.4-75.4l96-96a53.3,53.3,0,0,1,75.4,75.4Zm-11.4-64a36.9,36.9,0,0,0-52.6,0L107.3,96,160,148.7l42.3-42.4A37.1,37.1,0,0,0,202.3,53.7Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M212.2,43.8a50.9,50.9,0,0,0-72.4,0l-96,96a51.2,51.2,0,0,0,0,72.4,50.9,50.9,0,0,0,72.4,0l96-96A51.2,51.2,0,0,0,212.2,43.8Zm-104.4,160a39.3,39.3,0,0,1-55.6-55.6L96,104.5,151.5,160Zm96-96L160,151.5,104.5,96l43.7-43.8a39.3,39.3,0,0,1,55.6,55.6Zm-15-23.5a6.1,6.1,0,0,1-.1,8.5l-24.5,23.6a5.8,5.8,0,0,1-4.1,1.7,5.6,5.6,0,0,1-4.3-1.9,5.9,5.9,0,0,1,.1-8.4l24.4-23.6A6,6,0,0,1,188.8,84.3Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M210.8,45.2a49,49,0,0,0-69.6,0l-96,96a49.2,49.2,0,1,0,69.6,69.6l96-96A49.3,49.3,0,0,0,210.8,45.2Zm-101.6,160a41.3,41.3,0,0,1-58.4-58.4L96,101.7,154.3,160Zm96-96L160,154.3,101.7,96l45.1-45.2a41.3,41.3,0,0,1,58.4,58.4ZM187.4,85.7a4.1,4.1,0,0,1-.1,5.7l-24.5,23.5a3.6,3.6,0,0,1-2.7,1.2,3.7,3.7,0,0,1-2.9-1.3,4,4,0,0,1,.1-5.6l24.4-23.6A4.1,4.1,0,0,1,187.4,85.7Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M213.7,42.3a53.4,53.4,0,0,0-75.4,0l-96,96a53.3,53.3,0,0,0,75.4,75.4l48-48h0l48-48A53.5,53.5,0,0,0,213.7,42.3Zm-107.4,160a36.9,36.9,0,0,1-52.6,0,37.1,37.1,0,0,1,0-52.6L96,107.3,148.7,160Zm96-96L160,148.7,107.3,96l42.4-42.3a36.9,36.9,0,0,1,52.6,0A37.1,37.1,0,0,1,202.3,106.3ZM190.2,82.9a7.9,7.9,0,0,1-.2,11.3l-24.4,23.6a7.9,7.9,0,0,1-11.3-.2,7.9,7.9,0,0,1,.2-11.3l24.4-23.6A8.1,8.1,0,0,1,190.2,82.9Z" />
        </>
      )}
    </svg>
  );
}

export function PhPinterestLogo(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M220,112c0,23.6-8.3,45.2-23.4,60.9S163.3,196,144,196c-15.5,0-26.8-4.2-34.9-9.3l-9.4,40A12,12,0,0,1,88,236a12.4,12.4,0,0,1-2.7-.3,12,12,0,0,1-9-14.4l32-136a12,12,0,1,1,23.4,5.4l-16.5,70c3.5,4.2,12,11.3,28.8,11.3,25.6,0,52-22.4,52-60A68,68,0,1,0,71.5,149.9a12,12,0,1,1-19.9,13.3A92,92,0,1,1,220,112Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M216,112c0,22.6-7.9,43.2-22.2,58.1S162.2,192,144,192s-29.8-5.9-37.4-12L95.8,225.8A8.1,8.1,0,0,1,88,232l-1.8-.2a8,8,0,0,1-6-9.6l32-136a8,8,0,1,1,15.6,3.6l-16.9,71.8C114,166,123.3,176,144,176c27.5,0,56-23.9,56-64A72,72,0,1,0,68.2,152.1,8,8,0,0,1,66,163.2,7.9,7.9,0,0,1,54.9,161,88,88,0,1,1,216,112Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M232,131.2c-1.7,54.5-45.8,98.9-100.3,100.7a103.7,103.7,0,0,1-28.6-2.9,4.1,4.1,0,0,1-2.9-4.9l8.9-35.4A50.5,50.5,0,0,0,136,196c37,0,66.7-33.5,63.8-73.4-2.6-35.9-32-64.6-68-66.5A72,72,0,0,0,56,128a73.3,73.3,0,0,0,5.2,27,8,8,0,0,0,14.9-6A55.5,55.5,0,0,1,72,128.9a56,56,0,1,1,112-.9c0,28.7-21.5,52-48,52-10.5,0-17.8-3.7-22.8-8l14.6-58.1a8,8,0,1,0-15.6-3.8L85.4,217.5a4,4,0,0,1-5.7,2.6A104,104,0,1,1,232,131.2Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M214,112c0,22-7.7,42.2-21.7,56.7S161.7,190,144,190c-19.4,0-31.5-7.1-38.6-13.6l-11.6,49A6,6,0,0,1,88,230l-1.4-.2a5.9,5.9,0,0,1-4.4-7.2l32-136a6,6,0,1,1,11.6,2.8L108.7,162c2.9,4.4,12.5,16,35.3,16,28.5,0,58-24.7,58-66A74,74,0,1,0,66.5,153.2a5.9,5.9,0,0,1-1.6,8.3,5.9,5.9,0,0,1-8.3-1.6A85,85,0,0,1,42,112a86,86,0,0,1,172,0Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M212,112c0,21.5-7.5,41.2-21.1,55.3S161.2,188,144,188c-21.4,0-33.5-8.8-39.8-15.6L91.9,224.9A3.9,3.9,0,0,1,88,228h-.9a4,4,0,0,1-3-4.8l32-136a4,4,0,0,1,7.8,1.8l-17.3,73.5c2.4,4,12.3,17.6,37.4,17.6,29.5,0,60-25.4,60-68a76,76,0,0,0-152,0,74.8,74.8,0,0,0,12.9,42.3,4,4,0,1,1-6.7,4.5A84,84,0,1,1,212,112Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path
            d="M216,112c0,22.6-7.9,43.2-22.2,58.1S162.2,192,144,192s-29.8-5.9-37.4-12L95.8,225.8A8.1,8.1,0,0,1,88,232l-1.8-.2a8,8,0,0,1-6-9.6l32-136a8,8,0,1,1,15.6,3.6l-16.9,71.8C114,166,123.3,176,144,176c27.5,0,56-23.9,56-64A72,72,0,1,0,68.2,152.1,8,8,0,0,1,66,163.2,7.9,7.9,0,0,1,54.9,161,88,88,0,1,1,216,112Z"
            opacity="0.9"
          />
        </>
      )}
    </svg>
  );
}

export function PhPinwheel(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M232.5,215.5l-43.9-43.9a64,64,0,0,0,35.6-81,12,12,0,0,0-15.4-7.2l-35.7,13A64,64,0,0,0,90.6,15.8a12,12,0,0,0-7.2,15.4l13,35.7a64,64,0,0,0-80.6,82.5,12.2,12.2,0,0,0,6.2,6.8,12.6,12.6,0,0,0,5.1,1.1,12.2,12.2,0,0,0,4.1-.7l35.7-13a63.9,63.9,0,0,0,82.5,80.6,12,12,0,0,0,7.2-15.4l-13-35.7a60.9,60.9,0,0,0,15.7,3.2l56.2,56.2a12.1,12.1,0,0,0,17,0A12,12,0,0,0,232.5,215.5ZM129.4,39.7a39.8,39.8,0,0,1,19.3,53.1,38.6,38.6,0,0,1-11.9,14.9L110.7,35.9A39.8,39.8,0,0,1,129.4,39.7ZM39.7,110.6A39.8,39.8,0,0,1,62.2,89.9a40.8,40.8,0,0,1,13.7-2.4,40.1,40.1,0,0,1,16.9,3.8,38.6,38.6,0,0,1,14.9,11.9L35.9,129.3A39.5,39.5,0,0,1,39.7,110.6Zm70.9,89.7a39.8,39.8,0,0,1-19.3-53.1,38.6,38.6,0,0,1,11.9-14.9l26.1,71.8A39.8,39.8,0,0,1,110.6,200.3Zm21.7-63.5,71.8-26.1a40.4,40.4,0,0,1-26.3,39.4,43.9,43.9,0,0,1-13.3,2.4h-.1a39.9,39.9,0,0,1-17.2-3.8A38.6,38.6,0,0,1,132.3,136.8Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M124.8,109.7,27.1,145.3a52,52,0,0,1,97.7-35.6Zm88.1-15-97.7,35.6a52,52,0,0,0,97.7-35.6Z"
            opacity="0.2"
          />
          <path d="M229.7,218.3l-48.4-48.4,3.3-1a59.6,59.6,0,0,0,33.9-31.1,60.4,60.4,0,0,0,2-45.9,8.4,8.4,0,0,0-4.2-4.5,7.6,7.6,0,0,0-6.1-.2l-44.4,16.1,1-2A60,60,0,0,0,91.9,19.5a8.4,8.4,0,0,0-4.5,4.2,7.6,7.6,0,0,0-.2,6.1l16.1,44.4-2-1a60,60,0,0,0-81.8,74.9,8.4,8.4,0,0,0,4.2,4.5,7.7,7.7,0,0,0,3.4.7,6.5,6.5,0,0,0,2.7-.5l44.4-16.1-1,2a60,60,0,0,0,54.3,85.4,61.2,61.2,0,0,0,20.6-3.6,8.4,8.4,0,0,0,4.5-4.2,7.6,7.6,0,0,0,.2-6.1l-16.1-44.4,2,1a59.6,59.6,0,0,0,22.4,5.6l57.2,57.3a8.2,8.2,0,0,0,11.4,0A8.1,8.1,0,0,0,229.7,218.3ZM131.1,36a44.2,44.2,0,0,1,3.7,77.9L105.2,32.5A43.8,43.8,0,0,1,131.1,36ZM120,120ZM32.5,134.8A44.2,44.2,0,0,1,75.9,83.5a43.2,43.2,0,0,1,18.6,4.2,43.9,43.9,0,0,1,19.4,17.5ZM108.9,204a44.2,44.2,0,0,1-3.7-77.9l29.6,81.4A43.8,43.8,0,0,1,108.9,204Zm55.6-47.5h-.2a44.4,44.4,0,0,1-18.8-4.2,43.9,43.9,0,0,1-19.4-17.5l81.4-29.6a43.8,43.8,0,0,1-28.4,48.6A45.4,45.4,0,0,1,164.5,156.5Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M229.7,218.3l-48.4-48.4,3.3-1a59.6,59.6,0,0,0,33.9-31.1,60.4,60.4,0,0,0,2-45.9,8.4,8.4,0,0,0-4.2-4.5,7.6,7.6,0,0,0-6.1-.2l-44.4,16.1,1-2A60,60,0,0,0,91.9,19.5a8.4,8.4,0,0,0-4.5,4.2,7.6,7.6,0,0,0-.2,6.1l16.1,44.4-2-1a60,60,0,0,0-81.8,74.9,8.4,8.4,0,0,0,4.2,4.5,7.7,7.7,0,0,0,3.4.7,6.5,6.5,0,0,0,2.7-.5l44.4-16.1-1,2a60,60,0,0,0,54.3,85.4,61.2,61.2,0,0,0,20.6-3.6,8.4,8.4,0,0,0,4.5-4.2,7.6,7.6,0,0,0,.2-6.1l-16.1-44.4,2,1a59.6,59.6,0,0,0,22.4,5.6l57.2,57.3a8.2,8.2,0,0,0,11.4,0A8.1,8.1,0,0,0,229.7,218.3ZM131.1,36a44.2,44.2,0,0,1,3.7,77.9L105.2,32.5A43.8,43.8,0,0,1,131.1,36ZM108.9,204a44.2,44.2,0,0,1-3.7-77.9l29.6,81.4A43.8,43.8,0,0,1,108.9,204Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M228.2,219.8l-50.8-50.9a48.4,48.4,0,0,0,6.5-1.9,58.2,58.2,0,0,0,34.7-74.4,6.5,6.5,0,0,0-3.1-3.4,6.6,6.6,0,0,0-4.6-.2l-49.5,18.1a61.9,61.9,0,0,0,3.6-6.7,58,58,0,0,0-72.4-79,6.5,6.5,0,0,0-3.4,3.1,6.6,6.6,0,0,0-.2,4.6l18.1,49.5a61.9,61.9,0,0,0-6.7-3.6,58,58,0,0,0-79,72.4,6.5,6.5,0,0,0,3.1,3.4,7.5,7.5,0,0,0,2.6.5l2-.3,49.5-18.1a61.9,61.9,0,0,0-3.6,6.7,58.1,58.1,0,0,0,52.5,82.5,59.6,59.6,0,0,0,19.9-3.5,6.5,6.5,0,0,0,3.4-3.1,6.6,6.6,0,0,0,.2-4.6l-18.1-49.5a61.9,61.9,0,0,0,6.7,3.6,55,55,0,0,0,22.4,5.4l57.8,57.8a5.9,5.9,0,0,0,8.4-8.4ZM131.9,34.2a45.9,45.9,0,0,1,1.8,82.5L102.5,31A45.9,45.9,0,0,1,131.9,34.2ZM34.2,108.1a45.8,45.8,0,0,1,26-23.8,44.8,44.8,0,0,1,15.7-2.8,47.3,47.3,0,0,1,19.5,4.3,45.5,45.5,0,0,1,21.3,20.5L31,137.5A45.9,45.9,0,0,1,34.2,108.1Zm73.9,97.7a45.9,45.9,0,0,1-1.8-82.5L137.5,209A45.9,45.9,0,0,1,108.1,205.8Zm9.3-87,3.8-1.4,1.4,3.8-3.8,1.4Zm5.9,14.9L209,102.5a46,46,0,0,1-29.2,53.2,47.5,47.5,0,0,1-15.3,2.8h-.1a47.4,47.4,0,0,1-19.8-4.3A45.5,45.5,0,0,1,123.3,133.7Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M226.8,221.2l-53.4-53.5a56.7,56.7,0,0,0,9.8-2.6,56.1,56.1,0,0,0,33.5-71.8,3.8,3.8,0,0,0-2.1-2.2,3.9,3.9,0,0,0-3-.2L156,111.1a54.3,54.3,0,0,0,7.2-11.5A56,56,0,0,0,93.3,23.3a3.8,3.8,0,0,0-2.2,2.1,3.9,3.9,0,0,0-.2,3L111.1,84a54.3,54.3,0,0,0-11.5-7.2,56,56,0,0,0-76.3,69.9,3.8,3.8,0,0,0,2.1,2.2,3.3,3.3,0,0,0,1.7.4l1.3-.2L84,128.9a54.3,54.3,0,0,0-7.2,11.5,56,56,0,0,0,69.9,76.3,3.8,3.8,0,0,0,2.2-2.1,3.9,3.9,0,0,0,.2-3L128.9,156a54.3,54.3,0,0,0,11.5,7.2,55.7,55.7,0,0,0,22.4,5.3l58.4,58.3a3.9,3.9,0,0,0,5.6,0A3.9,3.9,0,0,0,226.8,221.2Zm-94-188.8a48,48,0,0,1-.2,87.1L99.9,29.6A48,48,0,0,1,132.8,32.4ZM32.4,107.2A47.9,47.9,0,0,1,59.5,82.4a49.3,49.3,0,0,1,16.4-2.9,48,48,0,0,1,43.6,27.9L29.6,140.1A48,48,0,0,1,32.4,107.2Zm74.8,100.4a48,48,0,0,1,.2-87.1l32.7,89.9A48,48,0,0,1,107.2,207.6Zm7.7-90,7.5-2.7,2.7,7.5-7.5,2.7Zm5.6,15,89.9-32.7a47.9,47.9,0,0,1-29.9,57.7,49.1,49.1,0,0,1-16,2.9h-.1a49.7,49.7,0,0,1-20.6-4.5A47.8,47.8,0,0,1,120.5,132.6Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M229.7,218.3l-48.4-48.4,3.3-1a59.6,59.6,0,0,0,33.9-31.1,60.4,60.4,0,0,0,2-45.9,8.4,8.4,0,0,0-4.2-4.5,7.6,7.6,0,0,0-6.1-.2l-44.4,16.1,1-2A60,60,0,0,0,91.9,19.5a8.4,8.4,0,0,0-4.5,4.2,7.6,7.6,0,0,0-.2,6.1l16.1,44.4-2-1a60,60,0,0,0-81.8,74.9,8.4,8.4,0,0,0,4.2,4.5,7.7,7.7,0,0,0,3.4.7,6.5,6.5,0,0,0,2.7-.5l44.4-16.1-1,2a60,60,0,0,0,54.3,85.4,61.2,61.2,0,0,0,20.6-3.6,8.4,8.4,0,0,0,4.5-4.2,7.6,7.6,0,0,0,.2-6.1l-16.1-44.4,2,1a59.6,59.6,0,0,0,22.4,5.6l57.2,57.3a8.2,8.2,0,0,0,11.4,0A8.1,8.1,0,0,0,229.7,218.3ZM131.1,36a44.2,44.2,0,0,1,3.7,77.9L105.2,32.5A43.8,43.8,0,0,1,131.1,36ZM36,108.9A44.4,44.4,0,0,1,75.9,83.5a43.2,43.2,0,0,1,18.6,4.2,43.9,43.9,0,0,1,19.4,17.5L32.5,134.8A43.8,43.8,0,0,1,36,108.9ZM108.9,204a44.2,44.2,0,0,1-3.7-77.9l29.6,81.4A43.8,43.8,0,0,1,108.9,204ZM120,120Zm6.1,14.8,81.4-29.6a43.8,43.8,0,0,1-28.4,48.6,45.4,45.4,0,0,1-14.6,2.7h-.2a44.4,44.4,0,0,1-18.8-4.2A43.9,43.9,0,0,1,126.1,134.8Z" />
        </>
      )}
    </svg>
  );
}

export function PhPizza(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M242.7,82.1a20,20,0,0,0-5.3-27,188,188,0,0,0-218.8,0,20,20,0,0,0-5.3,27h0L78.7,184.9v.2l32.3,50.7a20,20,0,0,0,33.8,0l24.6-38.7h.1ZM152,164a16,16,0,0,1,16-16,17.5,17.5,0,0,1,4,.5l-16.3,25.7A15.6,15.6,0,0,1,152,164ZM128,44a162.6,162.6,0,0,1,92.4,28.5L211.8,86A148,148,0,0,0,44.2,86L35.6,72.5A162.6,162.6,0,0,1,128,44ZM92.5,161.9,76.3,136.4A22.1,22.1,0,0,1,80,136a16,16,0,0,1,16,16A15.7,15.7,0,0,1,92.5,161.9Zm13.2,20.7A40,40,0,0,0,80,112a38.6,38.6,0,0,0-16.8,3.8l-6.1-9.5a123.9,123.9,0,0,1,141.8,0l-13.8,21.6a40,40,0,0,0-42.6,67L128,217.6Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M219.3,96.6l-26.2,41a28,28,0,1,0-28.8,45.3l-29.6,46.5a7.9,7.9,0,0,1-13.4,0L82.1,167.9H84a28,28,0,1,0-26.1-38.1L36.7,96.6a144.1,144.1,0,0,1,182.6,0Z"
            opacity="0.2"
          />
          <path d="M239.3,80a16,16,0,0,0-4.2-21.6,183.9,183.9,0,0,0-214.2,0A16,16,0,0,0,16.7,80h0l97.8,153.7a16,16,0,0,0,27,0l58.4-91.8h.1ZM30.2,71.4a168,168,0,0,1,195.6,0l-8.6,13.5a152.1,152.1,0,0,0-178.4,0Zm36.9,58A19.8,19.8,0,0,1,84,120a20,20,0,0,1,2.5,39.8ZM128,225.1l-32.5-51A36,36,0,0,0,84,104a35.6,35.6,0,0,0-26,11.1L47.4,98.5a135.8,135.8,0,0,1,161.2,0l-17.3,27.1a36,36,0,0,0-38.6,60.8Zm33.3-52.3A19.9,19.9,0,0,1,172,136a20.2,20.2,0,0,1,10.7,3.1Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M235.1,58.4a183.9,183.9,0,0,0-214.2,0A16,16,0,0,0,16.7,80h0l97.8,153.7a16,16,0,0,0,27,0l58.4-91.8h.1L239.3,80A16,16,0,0,0,235.1,58.4Zm-43.8,67.2a36,36,0,0,0-38.6,60.8L128,225.1l-32.5-51A36,36,0,0,0,84,104a35.6,35.6,0,0,0-26,11.1L47.4,98.5a135.8,135.8,0,0,1,161.2,0Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M237.6,78.9A13.9,13.9,0,0,0,233.9,60,181.9,181.9,0,0,0,22.1,60a13.9,13.9,0,0,0-3.7,18.9h0l97.8,153.7a14,14,0,0,0,23.6,0l58.4-91.8h.1ZM29.1,69.7a170.1,170.1,0,0,1,197.8,0,2.1,2.1,0,0,1,.6,2.8l-9.8,15.3a149.9,149.9,0,0,0-179.4,0L28.5,72.5A2.1,2.1,0,0,1,29.1,69.7Zm35.6,59.7a22,22,0,1,1,20.7,32.5Zm65,96.8a2.1,2.1,0,0,1-3.4,0L92.4,172.9a34,34,0,1,0-34.7-54.5L44.7,98a137.9,137.9,0,0,1,166.6,0l-19.4,30.4a34,34,0,1,0-36.5,57.3Zm32.2-50.7A22,22,0,0,1,172,134a21.5,21.5,0,0,1,13.4,4.6Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M232.8,61.6a180.2,180.2,0,0,0-209.6,0,11.9,11.9,0,0,0-3.1,16.2l97.8,153.7a11.9,11.9,0,0,0,20.2,0L235.9,77.8A11.9,11.9,0,0,0,232.8,61.6ZM62.4,129.5A23.8,23.8,0,0,1,84,116a24,24,0,0,1,.4,48Zm69,97.7a4,4,0,0,1-6.8,0L89.2,171.6a32,32,0,1,0-31.6-49.7L42.1,97.5a139.8,139.8,0,0,1,171.8,0l-21.5,33.8a32,32,0,1,0-34.1,53.6Zm31.3-49.1a24,24,0,1,1,25.4-39.9ZM229.2,73.5,218.3,90.7a148.2,148.2,0,0,0-180.6,0L26.8,73.5a4.1,4.1,0,0,1,1.1-5.4,172.1,172.1,0,0,1,200.2,0A4.1,4.1,0,0,1,229.2,73.5Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M239.3,80a16,16,0,0,0-4.2-21.6,183.9,183.9,0,0,0-214.2,0A16,16,0,0,0,16.7,80h0l97.8,153.7a16,16,0,0,0,27,0l58.4-91.8h.1ZM30.2,71.4a168,168,0,0,1,195.6,0l-8.6,13.5a152.1,152.1,0,0,0-178.4,0L30.2,71.4Zm36.9,58A19.8,19.8,0,0,1,84,120a20,20,0,0,1,2.5,39.8ZM128,225.1l-32.5-51A36,36,0,0,0,84,104a35.6,35.6,0,0,0-26,11.1L47.4,98.5a135.8,135.8,0,0,1,161.2,0l-17.3,27.1a36,36,0,0,0-38.6,60.8Zm33.3-52.3A19.9,19.9,0,0,1,172,136a20.2,20.2,0,0,1,10.7,3.1Z" />
        </>
      )}
    </svg>
  );
}

export function PhPlaceholder(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M228,48a20.1,20.1,0,0,0-20-20H48a19.8,19.8,0,0,0-13.2,5l-.9.9-.9.9A19.8,19.8,0,0,0,28,48V208a20.1,20.1,0,0,0,20,20H208a19.8,19.8,0,0,0,13.2-5l.9-.9.9-.9a19.8,19.8,0,0,0,5-13.2ZM204,187,69,52H204ZM52,69,187,204H52Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M216,48V208a8,8,0,0,1-8,8H48a8,8,0,0,1-8-8V48a8,8,0,0,1,8-8H208A8,8,0,0,1,216,48Z"
            opacity="0.2"
          />
          <path d="M224,48a16,16,0,0,0-16-16H48a16.4,16.4,0,0,0-10.7,4.1l-.6.6-.6.6A16.4,16.4,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16.4,16.4,0,0,0,10.7-4.1l.6-.6.6-.6A16.4,16.4,0,0,0,224,208ZM208,196.7,59.3,48H208ZM48,59.3,196.7,208H48Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM187.3,187.3a7.7,7.7,0,0,1-5.6,2.4,8,8,0,0,1-5.7-2.4L68.7,80A8,8,0,1,1,80,68.7L187.3,176A8,8,0,0,1,187.3,187.3Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M222,48a14,14,0,0,0-14-14H48a13.8,13.8,0,0,0-9.8,4,.1.1,0,0,1-.1.1.1.1,0,0,1-.1.1A13.8,13.8,0,0,0,34,48V208a14,14,0,0,0,14,14H208a13.8,13.8,0,0,0,9.8-4h.2a13.8,13.8,0,0,0,4-9.8Zm-12,0V201.5L54.5,46H208A2,2,0,0,1,210,48ZM46,208V54.5L201.5,210H48A2,2,0,0,1,46,208Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M220,48a12,12,0,0,0-12-12H48a12,12,0,0,0-8.4,3.4.1.1,0,0,1-.1.1.1.1,0,0,1-.1.1A12,12,0,0,0,36,48V208a12,12,0,0,0,12,12H208a12,12,0,0,0,8.4-3.4h.2A12,12,0,0,0,220,208Zm-8,0V206.3L49.7,44H208A4,4,0,0,1,212,48ZM44,208V49.7L206.3,212H48A4,4,0,0,1,44,208Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M224,48a16,16,0,0,0-16-16H48a16.4,16.4,0,0,0-10.7,4.1l-.6.6-.6.6A16.4,16.4,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16.4,16.4,0,0,0,10.7-4.1l.6-.6.6-.6A16.4,16.4,0,0,0,224,208ZM208,196.7,59.3,48H208ZM48,59.3,196.7,208H48Z" />
        </>
      )}
    </svg>
  );
}

export function PhPlanet(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M249.2,58c-6.4-11.1-23.3-21.4-67.5-9.5A95.9,95.9,0,0,0,32,128c0,2.3.1,4.6.2,6.8C-.1,167.1.4,186.9,6.8,198s19.3,14.7,33.3,14.7a127,127,0,0,0,26.3-3.2l7.8-2A96,96,0,0,0,224,128c0-2.3-.1-4.6-.2-6.8l5.6-5.8C244.2,99.2,259.7,76.2,249.2,58ZM128,56a72.2,72.2,0,0,1,70.3,56.3C183,126,163.6,139.8,142,152.2S98.8,174.6,79.3,181A72,72,0,0,1,128,56ZM27.5,186c-1-1.8.7-9.6,11.2-22.7a96.5,96.5,0,0,0,14.1,24.3C34.8,190.5,28.2,187.2,27.5,186ZM128,200a71.5,71.5,0,0,1-21.8-3.4A390.9,390.9,0,0,0,154,173a387.6,387.6,0,0,0,44.3-29.6A72.1,72.1,0,0,1,128,200ZM217.3,92.7a96.5,96.5,0,0,0-14.1-24.3c16.6-2.7,24.2-.2,25.3,1.6S228.8,78.5,217.3,92.7Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M216,128a88,88,0,1,1-88-88A88,88,0,0,1,216,128Z"
            opacity="0.2"
          />
          <path d="M245.8,60c-5.5-9.5-20.3-18.4-59.8-8.5A96,96,0,0,0,32,128a117.3,117.3,0,0,0,.7,12C4.4,169.2,4.8,186.5,10.2,196c3.7,6.4,11.9,12.8,29.8,12.8a121.7,121.7,0,0,0,25.5-3.2l4.5-1.1A96,96,0,0,0,224,128a117.3,117.3,0,0,0-.7-12l3.1-3.3C251.7,85.2,250.9,68.9,245.8,60ZM128,48a80.2,80.2,0,0,1,78.1,62.5c-16.2,15.4-37.8,31.2-62.1,45.2s-48.7,24.9-70.1,31.1A79.9,79.9,0,0,1,128,48ZM24.1,188c-1.5-2.6-.7-7.5,2.3-13.4A85.7,85.7,0,0,1,37.1,159a95.2,95.2,0,0,0,18.7,32.2C37.1,194.5,26.5,192.2,24.1,188ZM128,208a79.6,79.6,0,0,1-37.9-9.6c19.6-6.8,40.8-16.7,61.9-28.8a373.3,373.3,0,0,0,55.9-39.2A80,80,0,0,1,128,208ZM200.2,64.8a83.9,83.9,0,0,1,19-1.5c6.6.4,11.2,2.1,12.7,4.7,2.4,4.2-.9,14.5-13,29A95.2,95.2,0,0,0,200.2,64.8Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M223.3,116a117.3,117.3,0,0,1,.7,12A96,96,0,0,1,70,204.5c24.6-6.2,53.5-18.5,82-34.9S205.6,134.2,223.3,116Zm22.5-56c-5.5-9.5-20.3-18.4-59.8-8.5A96,96,0,0,0,32,128a117.3,117.3,0,0,0,.7,12C4.4,169.2,4.8,186.5,10.2,196c3.7,6.4,11.9,12.8,29.8,12.8a121.7,121.7,0,0,0,25.5-3.2l4.5-1.1a105,105,0,0,1-14.2-13.3c-18.7,3.3-29.3,1-31.7-3.2-1.5-2.6-.7-7.5,2.3-13.4A85.7,85.7,0,0,1,37.1,159a95.2,95.2,0,0,0,18.7,32.2l6.2-1.3c24-5.3,53.1-17.5,82-34.2s54-35.8,70.6-53.9a62,62,0,0,0,4.3-4.8,95.2,95.2,0,0,0-18.7-32.2,83.9,83.9,0,0,1,19-1.5c6.6.4,11.2,2.1,12.7,4.7,2.4,4.2-.9,14.5-13,29a107.3,107.3,0,0,1,4.4,19l3.1-3.3C251.7,85.2,250.9,68.9,245.8,60Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M244,61c-7.2-12.6-27.9-15.1-58.5-7.3A93.9,93.9,0,0,0,34,128a89.3,89.3,0,0,0,.9,12.7C12.8,163.2,4.7,182.4,12,195c4.5,7.8,14,11.8,27.9,11.8a118.8,118.8,0,0,0,25.2-3.2l5.4-1.3A93.9,93.9,0,0,0,222,128a89.3,89.3,0,0,0-.9-12.7l3.8-4C244.3,90.3,250.9,72.9,244,61ZM128,46a82.2,82.2,0,0,1,80.3,65.2c-16.4,15.7-38.4,31.9-63.3,46.2s-49.8,25.4-71.6,31.7A81.9,81.9,0,0,1,128,46ZM22.3,189c-4.2-7.3,4.7-21.5,15.7-34a93.5,93.5,0,0,0,21.7,37.5C36.5,197.3,25.2,193.9,22.3,189ZM128,210a81.7,81.7,0,0,1-42.7-12c20.5-6.8,43.2-17.2,65.7-30.2s42.8-27.4,58.9-41.8a12.7,12.7,0,0,1,.1,2A82.1,82.1,0,0,1,128,210ZM196.3,63.5c23.1-4.7,34.5-1.4,37.4,3.5s0,16.3-15.6,34A93.7,93.7,0,0,0,196.3,63.5Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M242.3,62c-6.8-11.8-27.1-14-57.2-6.1A92,92,0,0,0,36,128a84.5,84.5,0,0,0,1,13.4C15.1,163.5,6.9,182.2,13.7,194c4.1,7.2,13,10.8,26.2,10.8a115.3,115.3,0,0,0,24.7-3.1l6.3-1.6A92,92,0,0,0,220,128a84.5,84.5,0,0,0-1-13.4l4.5-4.6C242.2,89.6,248.7,73.1,242.3,62ZM128,44a84,84,0,0,1,82.4,67.9c-16.5,16.1-39,32.6-64.4,47.3S95.1,185,72.9,191.3A83.9,83.9,0,0,1,128,44ZM62.9,193.8c-22.2,5-38,3.6-42.3-3.8s2.6-22.2,18.3-39a92,92,0,0,0,24.7,42.7ZM128,212a83.2,83.2,0,0,1-47.4-14.7c21.5-6.6,45.6-17.4,69.4-31.2s45.2-29.2,61.7-44.5c.2,2.1.3,4.3.3,6.4A84.1,84.1,0,0,1,128,212ZM192.4,62.3c22.4-5.1,38.7-3.8,43,3.7s-2.4,21.8-17.8,38.5c-.2.2-.3.4-.5.5A92.8,92.8,0,0,0,192.4,62.3Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M245.8,60c-5.5-9.5-20.3-18.4-59.8-8.5A96,96,0,0,0,32,128a117.3,117.3,0,0,0,.7,12C4.4,169.2,4.8,186.5,10.2,196c3.7,6.4,11.9,12.8,29.8,12.8a121.7,121.7,0,0,0,25.5-3.2l4.5-1.1A96,96,0,0,0,224,128a117.3,117.3,0,0,0-.7-12l3.1-3.3C251.7,85.2,250.9,68.9,245.8,60ZM128,48a80.2,80.2,0,0,1,78.1,62.5c-16.2,15.4-37.8,31.2-62.1,45.2s-48.7,24.9-70.1,31.1A79.9,79.9,0,0,1,128,48ZM24.1,188c-1.5-2.6-.7-7.5,2.3-13.4A85.7,85.7,0,0,1,37.1,159a95.2,95.2,0,0,0,18.7,32.2C37.1,194.5,26.5,192.2,24.1,188ZM128,208a79.6,79.6,0,0,1-37.9-9.6c19.6-6.8,40.8-16.7,61.9-28.8a373.3,373.3,0,0,0,55.9-39.2A80,80,0,0,1,128,208ZM200.2,64.8a83.9,83.9,0,0,1,19-1.5c6.6.4,11.2,2.1,12.7,4.7,2.4,4.2-.9,14.5-13,29A95.2,95.2,0,0,0,200.2,64.8Z" />
        </>
      )}
    </svg>
  );
}

export function PhPlayCircle(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,20A108,108,0,1,0,236,128,108.1,108.1,0,0,0,128,20Zm0,192a84,84,0,1,1,84-84A84.1,84.1,0,0,1,128,212Zm40-84a11.9,11.9,0,0,1-5.1,9.8l-40,28A11.8,11.8,0,0,1,116,168a10.7,10.7,0,0,1-5.5-1.4A11.8,11.8,0,0,1,104,156V100a12,12,0,0,1,18.9-9.8l40,28A11.9,11.9,0,0,1,168,128Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M128,32a96,96,0,1,0,96,96A96,96,0,0,0,128,32ZM112,160V96l48,32Z" />
          </g>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Z" />
          <path d="M164.4,121.3l-48-32a8.3,8.3,0,0,0-8.2-.4A8,8,0,0,0,104,96v64a8,8,0,0,0,4.2,7.1,8.5,8.5,0,0,0,3.8.9,8.4,8.4,0,0,0,4.4-1.3l48-32a8,8,0,0,0,0-13.4ZM120,145.1V110.9L145.6,128Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm36.4,110.7-48,32A8.7,8.7,0,0,1,112,168a8.5,8.5,0,0,1-3.8-.9A8,8,0,0,1,104,160V96a8,8,0,0,1,4.2-7.1,8.3,8.3,0,0,1,8.2.4l48,32a8,8,0,0,1,0,13.4Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,26A102,102,0,1,0,230,128,102.2,102.2,0,0,0,128,26Zm0,192a90,90,0,1,1,90-90A90.1,90.1,0,0,1,128,218Zm35.3-95-48-32a6,6,0,0,0-9.3,5v64a6,6,0,0,0,3.2,5.3,5.9,5.9,0,0,0,6.1-.3l48-32a6,6,0,0,0,0-10ZM118,148.8V107.2L149.2,128Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,28A100,100,0,1,0,228,128,100.2,100.2,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm34.2-95.3-48-32a4,4,0,0,0-4.1-.2A3.9,3.9,0,0,0,108,96v64a3.9,3.9,0,0,0,2.1,3.5,4.5,4.5,0,0,0,1.9.5,3.6,3.6,0,0,0,2.2-.7l48-32a3.9,3.9,0,0,0,0-6.6ZM116,152.5v-49L152.8,128Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm36.4-94.7-48-32a8.3,8.3,0,0,0-8.2-.4A8,8,0,0,0,104,96v64a8,8,0,0,0,4.2,7.1,8.5,8.5,0,0,0,3.8.9,8.4,8.4,0,0,0,4.4-1.3l48-32a8,8,0,0,0,0-13.4ZM120,145.1V110.9L145.6,128Z" />
        </>
      )}
    </svg>
  );
}

export function PhPlay(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M80,236a20.2,20.2,0,0,1-9.8-2.6A19.9,19.9,0,0,1,60,216V40A20,20,0,0,1,90.4,22.9l144,88h0a20,20,0,0,1,0,34.2l-144,88A20.4,20.4,0,0,1,80,236ZM84,47.1V208.9L216.3,128Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M228.1,121.2,84.2,33.2A8,8,0,0,0,72,40V216a8,8,0,0,0,12.2,6.8l143.9-88A7.9,7.9,0,0,0,228.1,121.2Z" />
          </g>
          <path d="M80,232a15.5,15.5,0,0,1-7.8-2.1A15.8,15.8,0,0,1,64,216V40a15.8,15.8,0,0,1,8.2-13.9,15.5,15.5,0,0,1,16.1.3l144,87.9h0a16,16,0,0,1,0,27.4l-144,87.9A15.4,15.4,0,0,1,80,232ZM80,40V216l144-88Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M240,128a16.1,16.1,0,0,1-7.7,13.7l-144,87.9a15.5,15.5,0,0,1-16.1.3A15.8,15.8,0,0,1,64,216V40a15.8,15.8,0,0,1,8.2-13.9,15.5,15.5,0,0,1,16.1.3l144,87.9A16.1,16.1,0,0,1,240,128Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M80,230a14.1,14.1,0,0,1-14-14V40A14,14,0,0,1,87.3,28.1l144,88h0a13.9,13.9,0,0,1,0,23.8l-144,88A14,14,0,0,1,80,230ZM80,38a1.5,1.5,0,0,0-1,.3A1.8,1.8,0,0,0,78,40V216a1.8,1.8,0,0,0,1,1.7,2,2,0,0,0,2,0l144-88a1.9,1.9,0,0,0,0-3.4L81,38.3A1.9,1.9,0,0,0,80,38Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M80,228a12.6,12.6,0,0,1-5.9-1.5A12,12,0,0,1,68,216V40A11.9,11.9,0,0,1,86.3,29.8l143.9,88h0a11.9,11.9,0,0,1,0,20.4l-143.9,88A12.1,12.1,0,0,1,80,228ZM80,36a4.4,4.4,0,0,0-2,.5A4.1,4.1,0,0,0,76,40V216a4.1,4.1,0,0,0,2,3.5,4.2,4.2,0,0,0,4.1-.1l144-88a4,4,0,0,0,0-6.8l-144-88A3.6,3.6,0,0,0,80,36Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M80,232a15.5,15.5,0,0,1-7.8-2.1A15.8,15.8,0,0,1,64,216V40a15.8,15.8,0,0,1,8.2-13.9,15.5,15.5,0,0,1,16.1.3l144,87.9h0a16,16,0,0,1,0,27.4l-144,87.9A15.4,15.4,0,0,1,80,232ZM80,40V216l144-88Z" />
        </>
      )}
    </svg>
  );
}

export function PhPlaylist(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M28,64A12,12,0,0,1,40,52H216a12,12,0,0,1,0,24H40A12,12,0,0,1,28,64Zm12,76H160a12,12,0,0,0,0-24H40a12,12,0,0,0,0,24Zm72,40H40a12,12,0,0,0,0,24h72a12,12,0,0,0,0-24Zm139.5-52.6a12,12,0,0,1-14.9,8.1L212,128.1V192a36,36,0,1,1-36-36,34.4,34.4,0,0,1,12,2.1V112a12,12,0,0,1,15.4-11.5l40,12A12,12,0,0,1,251.5,127.4ZM188,192a12,12,0,1,0-12,12A12,12,0,0,0,188,192Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M200,192a24,24,0,1,1-24-24A23.9,23.9,0,0,1,200,192Z"
            opacity="0.2"
          />
          <path d="M242.3,116.3l-40-12a8.2,8.2,0,0,0-7.1,1.3A8.1,8.1,0,0,0,192,112v52.3A32,32,0,1,0,208,192V122.8l29.7,8.9a8,8,0,1,0,4.6-15.4ZM176,208a16,16,0,1,1,16-16A16,16,0,0,1,176,208ZM32,64a8,8,0,0,1,8-8H216a8,8,0,0,1,0,16H40A8,8,0,0,1,32,64Zm0,64a8,8,0,0,1,8-8H160a8,8,0,0,1,0,16H40A8,8,0,0,1,32,128Zm88,64a8,8,0,0,1-8,8H40a8,8,0,0,1,0-16h72A8,8,0,0,1,120,192Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M32,128a8,8,0,0,1,8-8H160a8,8,0,0,1,0,16H40A8,8,0,0,1,32,128Zm8-56H216a8,8,0,0,0,0-16H40a8,8,0,0,0,0,16Zm202.3,44.3-40-12a8.2,8.2,0,0,0-7.1,1.3A8.1,8.1,0,0,0,192,112v52.3A32,32,0,1,0,208,192V122.8l29.7,8.9a8,8,0,1,0,4.6-15.4ZM112,184H40a8,8,0,0,0,0,16h72a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M34,64a6,6,0,0,1,6-6H216a6,6,0,0,1,0,12H40A6,6,0,0,1,34,64Zm6,70H160a6,6,0,0,0,0-12H40a6,6,0,0,0,0,12Zm72,52H40a6,6,0,0,0,0,12h72a6,6,0,0,0,0-12Zm133.7-60.3a5.9,5.9,0,0,1-7.4,4L206,120.1V192a30,30,0,1,1-30-30,29.7,29.7,0,0,1,18,6V112a6,6,0,0,1,2.4-4.8,5.9,5.9,0,0,1,5.3-.9l40,12A5.9,5.9,0,0,1,245.7,125.7ZM194,192a18,18,0,1,0-18,18A18.1,18.1,0,0,0,194,192Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M36,64a4,4,0,0,1,4-4H216a4,4,0,0,1,0,8H40A4,4,0,0,1,36,64Zm4,68H160a4,4,0,0,0,0-8H40a4,4,0,0,0,0,8Zm72,56H40a4,4,0,0,0,0,8h72a4,4,0,0,0,0-8Zm131.8-62.9a3.8,3.8,0,0,1-4.9,2.7L204,117.4V192a28,28,0,1,1-8-19.6V112a3.9,3.9,0,0,1,1.6-3.2,3.7,3.7,0,0,1,3.5-.6l40,12A3.8,3.8,0,0,1,243.8,125.1ZM196,192a20,20,0,1,0-20,20A20.1,20.1,0,0,0,196,192Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M32,64a8,8,0,0,1,8-8H216a8,8,0,0,1,0,16H40A8,8,0,0,1,32,64Zm8,72H160a8,8,0,0,0,0-16H40a8,8,0,0,0,0,16Zm72,48H40a8,8,0,0,0,0,16h72a8,8,0,0,0,0-16Zm135.7-57.7a8.2,8.2,0,0,1-10,5.4L208,122.8V192a32,32,0,1,1-16-27.7V112a8.1,8.1,0,0,1,3.2-6.4,8.2,8.2,0,0,1,7.1-1.3l40,12A8.2,8.2,0,0,1,247.7,126.3ZM192,192a16,16,0,1,0-16,16A16,16,0,0,0,192,192Z" />
        </>
      )}
    </svg>
  );
}

export function PhPlug(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M240.5,63.5a12,12,0,0,0-17,0L192,95,161,64l31.5-31.5a12,12,0,0,0-17-17L144,47,120.5,23.5a12,12,0,0,0-17,17L107,44,58.1,92.9a44.1,44.1,0,0,0,0,62.2L71,168,23.5,215.5a12,12,0,0,0,0,17,12.1,12.1,0,0,0,17,0L88,185l12.9,12.9a44.2,44.2,0,0,0,62.2,0L212,149l3.5,3.5a12.1,12.1,0,0,0,17,0,12,12,0,0,0,0-17L209,112l31.5-31.5A12,12,0,0,0,240.5,63.5ZM146.1,180.9a19.9,19.9,0,0,1-28.2,0L75.1,138.1a19.9,19.9,0,0,1,0-28.2L124,61l71,71Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M212,132l-57.4,57.4a31.9,31.9,0,0,1-45.2,0L66.6,146.6a31.9,31.9,0,0,1,0-45.2L124,44Z"
            opacity="0.2"
          />
          <path d="M237.7,66.3a8.1,8.1,0,0,0-11.4,0L192,100.7,155.3,64l34.4-34.3a8.1,8.1,0,0,0-11.4-11.4L144,52.7,117.7,26.3a8.1,8.1,0,0,0-11.4,11.4l6.4,6.3L61,95.7a40.1,40.1,0,0,0,0,56.6L76.7,168,26.3,218.3a8.1,8.1,0,0,0,0,11.4,8.2,8.2,0,0,0,11.4,0L88,179.3,103.7,195a40.1,40.1,0,0,0,56.6,0L212,143.3l6.3,6.4a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4L203.3,112l34.4-34.3A8.1,8.1,0,0,0,237.7,66.3ZM149,183.7a24,24,0,0,1-34,0L72.3,141a24.1,24.1,0,0,1,0-34L124,55.3,200.7,132Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M237.7,77.7,203.3,112l26.4,26.3a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0l-6.3-6.4L160.3,195a40.1,40.1,0,0,1-56.6,0L88,179.3,37.7,229.7a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L76.7,168,61,152.3a40.1,40.1,0,0,1,0-56.6L112.7,44l-6.4-6.3a8.1,8.1,0,0,1,11.4-11.4L144,52.7l34.3-34.4a8.1,8.1,0,0,1,11.4,11.4L155.3,64,192,100.7l34.3-34.4a8.1,8.1,0,0,1,11.4,11.4Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M236.2,67.8a5.8,5.8,0,0,0-8.4,0L192,103.5,152.5,64l35.7-35.8a5.9,5.9,0,0,0-8.4-8.4L144,55.5,116.2,27.8a5.9,5.9,0,0,0-8.4,8.4l7.7,7.8L62.4,97.1a38.1,38.1,0,0,0,0,53.8L79.5,168,27.8,219.8a5.9,5.9,0,1,0,8.4,8.4L88,176.5l17.1,17.1a38.1,38.1,0,0,0,53.8,0L212,140.5l7.8,7.7a5.9,5.9,0,0,0,8.4-8.4L200.5,112l35.7-35.8A5.8,5.8,0,0,0,236.2,67.8ZM150.4,185.1a26,26,0,0,1-36.8,0L70.9,142.4a26,26,0,0,1,0-36.8L124,52.5,203.5,132Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M234.8,69.2a3.9,3.9,0,0,0-5.6,0L192,106.3,149.7,64l37.1-37.2a4,4,0,0,0-5.6-5.6L144,58.3,114.8,29.2a4,4,0,0,0-5.6,5.6l9.1,9.2L63.8,98.5a36.2,36.2,0,0,0,0,51L82.3,168,29.2,221.2a3.9,3.9,0,0,0,0,5.6,3.9,3.9,0,0,0,5.6,0L88,173.7l18.5,18.5a36.2,36.2,0,0,0,51,0L212,137.7l9.2,9.1a3.9,3.9,0,0,0,5.6,0,3.9,3.9,0,0,0,0-5.6L197.7,112l37.1-37.2A3.9,3.9,0,0,0,234.8,69.2Zm-83,117.3a27.9,27.9,0,0,1-39.6,0L69.5,143.8a27.9,27.9,0,0,1,0-39.6L124,49.7,206.3,132Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M237.7,66.3a8.1,8.1,0,0,0-11.4,0L192,100.7,155.3,64l34.4-34.3a8.1,8.1,0,0,0-11.4-11.4L144,52.7,117.7,26.3a8.1,8.1,0,0,0-11.4,11.4l6.4,6.3L61,95.7a40.1,40.1,0,0,0,0,56.6L76.7,168,26.3,218.3a8.1,8.1,0,0,0,0,11.4,8.2,8.2,0,0,0,11.4,0L88,179.3,103.7,195a40.1,40.1,0,0,0,56.6,0L212,143.3l6.3,6.4a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4L203.3,112l34.4-34.3A8.1,8.1,0,0,0,237.7,66.3ZM149,183.7a24,24,0,0,1-34,0L72.3,141a24.1,24.1,0,0,1,0-34L124,55.3,200.7,132Z" />
        </>
      )}
    </svg>
  );
}

export function PhPlugsConnected(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M195.5,82.5,193,80l43.5-43.5a12,12,0,0,0-17-17L176,63l-2.5-2.5a36,36,0,0,0-51,0L100,83.1A12,12,0,0,0,83.1,100L60.5,122.5a36,36,0,0,0,0,51L63,176,19.5,219.5a12,12,0,0,0,0,17,12.1,12.1,0,0,0,17,0L80,193l2.5,2.5a36.2,36.2,0,0,0,51,0L156,172.9a11.7,11.7,0,0,0,8,3.1,12.2,12.2,0,0,0,8.5-3.5,12,12,0,0,0,.4-16.5l22.6-22.5A36,36,0,0,0,195.5,82.5Zm-79,96a12,12,0,0,1-17,0l-22-22a12,12,0,0,1,0-17L100,117l39,39Zm62-62L156,139l-39-39,22.5-22.5a11.8,11.8,0,0,1,17,0l22,22A12,12,0,0,1,178.5,116.5Zm-5.8,107.7a12,12,0,0,1-22.8,7.6l-5.3-16a12,12,0,0,1,22.8-7.6ZM83.3,31.8a12,12,0,1,1,22.8-7.6l5.3,16a12,12,0,0,1-7.6,15.2,12.6,12.6,0,0,1-3.8.6,12.1,12.1,0,0,1-11.4-8.2ZM239.4,165.1a12,12,0,0,1-11.4,8.2,12.6,12.6,0,0,1-3.8-.6l-16-5.3a12,12,0,0,1,7.6-22.8l16,5.3A12,12,0,0,1,239.4,165.1ZM16.6,90.9a12,12,0,0,1,15.2-7.6l16,5.3A12,12,0,0,1,44,112a12.6,12.6,0,0,1-3.8-.6l-16-5.3A12,12,0,0,1,16.6,90.9Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M187,125l-62,62a24,24,0,0,1-34,0L69,165a24,24,0,0,1,0-34l62-62a24,24,0,0,1,34,0l22,22A24,24,0,0,1,187,125Z"
            opacity="0.2"
          />
          <path d="M237.7,18.3a8.1,8.1,0,0,0-11.4,0L176,68.7l-5.4-5.4a31.9,31.9,0,0,0-45.2,0L100,88.7l-2.3-2.4A8.1,8.1,0,0,0,86.3,97.7l2.4,2.3L63.3,125.4a31.9,31.9,0,0,0,0,45.2l5.4,5.4L18.3,226.3a8.1,8.1,0,0,0,0,11.4,8.2,8.2,0,0,0,11.4,0L80,187.3l5.4,5.4a32.1,32.1,0,0,0,45.2,0L156,167.3l2.3,2.4a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4l-2.4-2.3,25.4-25.4a31.9,31.9,0,0,0,0-45.2L187.3,80l50.4-50.3A8.1,8.1,0,0,0,237.7,18.3ZM119.3,181.4a16.1,16.1,0,0,1-22.6,0L74.6,159.3a15.9,15.9,0,0,1,0-22.6L100,111.3,144.7,156Zm62.1-62.1L156,144.7,111.3,100l25.4-25.4a15.9,15.9,0,0,1,22.6,0l22.1,22.1a15.9,15.9,0,0,1,0,22.6Zm50.2,43.2A7.9,7.9,0,0,1,224,168a7.3,7.3,0,0,1-2.5-.4l-24-8a8,8,0,1,1,5-15.2l24,8A8,8,0,0,1,231.6,162.5ZM24.4,93.5a8,8,0,0,1,10.1-5.1l24,8A8,8,0,0,1,56,112a7.3,7.3,0,0,1-2.5-.4l-24-8A8,8,0,0,1,24.4,93.5Zm64-59a8,8,0,0,1,15.2-5l8,24a8,8,0,0,1-5.1,10.1,7.3,7.3,0,0,1-2.5.4,7.9,7.9,0,0,1-7.6-5.5Zm79.2,187a8,8,0,0,1-5.1,10.1,7.3,7.3,0,0,1-2.5.4,7.9,7.9,0,0,1-7.6-5.5l-8-24a8,8,0,1,1,15.2-5Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M237.7,18.3a8.1,8.1,0,0,0-11.4,0L176,68.7l-5.4-5.4a31.9,31.9,0,0,0-45.2,0L100,88.7l-2.3-2.4A8.1,8.1,0,0,0,86.3,97.7l2.4,2.3L63.3,125.4a31.9,31.9,0,0,0,0,45.2l5.4,5.4L18.3,226.3a8.1,8.1,0,0,0,0,11.4,8.2,8.2,0,0,0,11.4,0L80,187.3l5.4,5.4a32.1,32.1,0,0,0,45.2,0L156,167.3l2.3,2.4a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4l-2.4-2.3,25.4-25.4a31.9,31.9,0,0,0,0-45.2L187.3,80l50.4-50.3A8.1,8.1,0,0,0,237.7,18.3Zm-56.3,101L156,144.7,111.3,100l25.4-25.4a15.9,15.9,0,0,1,22.6,0l22.1,22.1a15.9,15.9,0,0,1,0,22.6Zm50.2,43.2A7.9,7.9,0,0,1,224,168a7.3,7.3,0,0,1-2.5-.4l-24-8a8,8,0,1,1,5-15.2l24,8A8,8,0,0,1,231.6,162.5ZM24.4,93.5a8,8,0,0,1,10.1-5.1l24,8A8,8,0,0,1,56,112a7.3,7.3,0,0,1-2.5-.4l-24-8A8,8,0,0,1,24.4,93.5Zm64-59a8,8,0,0,1,15.2-5l8,24a8,8,0,0,1-5.1,10.1,7.3,7.3,0,0,1-2.5.4,7.9,7.9,0,0,1-7.6-5.5Zm79.2,187a8,8,0,0,1-5.1,10.1,7.3,7.3,0,0,1-2.5.4,7.9,7.9,0,0,1-7.6-5.5l-8-24a8,8,0,1,1,15.2-5Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M236.2,19.8a5.8,5.8,0,0,0-8.4,0L176,71.5l-6.8-6.8a29.9,29.9,0,0,0-42.4,0L100,91.5l-3.8-3.7a5.9,5.9,0,0,0-8.4,8.4l3.7,3.8L64.7,126.8a29.9,29.9,0,0,0,0,42.4l6.8,6.8L19.8,227.8a5.9,5.9,0,1,0,8.4,8.4L80,184.5l6.8,6.8a30.2,30.2,0,0,0,42.4,0L156,164.5l3.8,3.7a5.9,5.9,0,0,0,8.4-8.4l-3.7-3.8,26.8-26.8a30,30,0,0,0,0-42.4L184.5,80l51.7-51.8A5.8,5.8,0,0,0,236.2,19.8Zm-115.5,163a17.9,17.9,0,0,1-25.4,0L73.2,160.7a17.9,17.9,0,0,1,0-25.4L100,108.5,147.5,156Zm62.1-62.1L156,147.5,108.5,100l26.8-26.8a17.9,17.9,0,0,1,25.4,0l22.1,22.1a17.9,17.9,0,0,1,0,25.4Zm46.9,41.2A6,6,0,0,1,224,166a6.3,6.3,0,0,1-1.9-.3l-24-8a6,6,0,0,1,3.8-11.4l24,8A6.1,6.1,0,0,1,229.7,161.9ZM26.3,94.1a6.1,6.1,0,0,1,7.6-3.8l24,8a6.1,6.1,0,0,1,3.8,7.6A6,6,0,0,1,56,110a6.3,6.3,0,0,1-1.9-.3l-24-8A6.1,6.1,0,0,1,26.3,94.1Zm64-60.2a6,6,0,0,1,11.4-3.8l8,24a6.1,6.1,0,0,1-3.8,7.6,6.3,6.3,0,0,1-1.9.3,6,6,0,0,1-5.7-4.1Zm75.4,188.2a6.1,6.1,0,0,1-3.8,7.6,6.3,6.3,0,0,1-1.9.3,6,6,0,0,1-5.7-4.1l-8-24a6,6,0,0,1,11.4-3.8Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M234.8,21.2a3.9,3.9,0,0,0-5.6,0L176,74.3l-8.2-8.2a28,28,0,0,0-39.6,0L100,94.3l-5.2-5.1a4,4,0,0,0-5.6,5.6l5.1,5.2L66.1,128.2a28.1,28.1,0,0,0,0,39.6l8.2,8.2L21.2,229.2a3.9,3.9,0,0,0,0,5.6,3.9,3.9,0,0,0,5.6,0L80,181.7l8.2,8.2a28,28,0,0,0,39.6,0L156,161.7l5.2,5.1a3.9,3.9,0,0,0,5.6,0,3.9,3.9,0,0,0,0-5.6l-5.1-5.2,28.2-28.2a28.1,28.1,0,0,0,0-39.6L181.7,80l53.1-53.2A3.9,3.9,0,0,0,234.8,21.2Zm-112.7,163a19.9,19.9,0,0,1-28.2,0L71.8,162.1a19.9,19.9,0,0,1,0-28.2L100,105.7,150.3,156Zm62.1-62.1L156,150.3,105.7,100l28.2-28.2a20,20,0,0,1,28.2,0l22.1,22.1A19.9,19.9,0,0,1,184.2,122.1Zm43.6,39.2A4.1,4.1,0,0,1,224,164l-1.3-.2-24-8a4,4,0,1,1,2.6-7.6l24,8A4,4,0,0,1,227.8,161.3ZM28.2,94.7a4,4,0,0,1,5.1-2.5l24,8a4,4,0,0,1,2.5,5.1A4.1,4.1,0,0,1,56,108l-1.3-.2-24-8A4,4,0,0,1,28.2,94.7Zm64-61.4a4,4,0,0,1,7.6-2.6l8,24a4,4,0,0,1-2.5,5.1L104,60a4.1,4.1,0,0,1-3.8-2.7Zm71.6,189.4a4,4,0,0,1-2.5,5.1l-1.3.2a4.1,4.1,0,0,1-3.8-2.7l-8-24a4,4,0,1,1,7.6-2.6Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M237.7,18.3a8.1,8.1,0,0,0-11.4,0L176,68.7l-5.4-5.4a31.9,31.9,0,0,0-45.2,0L100,88.7l-2.3-2.4A8.1,8.1,0,0,0,86.3,97.7l2.4,2.3L63.3,125.4a31.9,31.9,0,0,0,0,45.2l5.4,5.4L18.3,226.3a8.1,8.1,0,0,0,0,11.4,8.2,8.2,0,0,0,11.4,0L80,187.3l5.4,5.4a32.1,32.1,0,0,0,45.2,0L156,167.3l2.3,2.4a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4l-2.4-2.3,25.4-25.4a31.9,31.9,0,0,0,0-45.2L187.3,80l50.4-50.3A8.1,8.1,0,0,0,237.7,18.3ZM119.3,181.4a16.1,16.1,0,0,1-22.6,0L74.6,159.3a15.9,15.9,0,0,1,0-22.6L100,111.3,144.7,156ZM156,144.7,111.3,100l25.4-25.4a15.9,15.9,0,0,1,22.6,0l22.1,22.1a16.1,16.1,0,0,1,0,22.6Zm75.6,17.8A7.9,7.9,0,0,1,224,168a7.3,7.3,0,0,1-2.5-.4l-24-8a8,8,0,1,1,5-15.2l24,8A8,8,0,0,1,231.6,162.5ZM24.4,93.5a8,8,0,0,1,10.1-5.1l24,8A8,8,0,0,1,56,112a7.3,7.3,0,0,1-2.5-.4l-24-8A8,8,0,0,1,24.4,93.5Zm64-59a8,8,0,0,1,15.2-5l8,24a8,8,0,0,1-5.1,10.1,7.3,7.3,0,0,1-2.5.4,7.9,7.9,0,0,1-7.6-5.5Zm79.2,187a8,8,0,0,1-5.1,10.1,7.3,7.3,0,0,1-2.5.4,7.9,7.9,0,0,1-7.6-5.5l-8-24a8,8,0,1,1,15.2-5Z" />
        </>
      )}
    </svg>
  );
}

export function PhPlugs(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M240.5,15.5a12,12,0,0,0-17,0L200,39l-2.5-2.5a36,36,0,0,0-51,0L124,59.1a12,12,0,0,0-16.5,17.4l72,72a12.1,12.1,0,0,0,17,0,12,12,0,0,0,.4-16.5l22.6-22.5a36,36,0,0,0,0-51L217,56l23.5-23.5A12,12,0,0,0,240.5,15.5Zm-38,77L180,115,141,76l22.5-22.5a11.8,11.8,0,0,1,17,0l22,22a11.8,11.8,0,0,1,0,17Zm-54,47a12,12,0,0,0-17,0L120,151l-15-15,11.5-11.5a12,12,0,0,0-17-17L88,119,76.5,107.5A12,12,0,0,0,59.1,124L36.5,146.5a36,36,0,0,0,0,51L39,200,15.5,223.5a12,12,0,0,0,0,17,12.1,12.1,0,0,0,17,0L56,217l2.5,2.5a36,36,0,0,0,51,0L132,196.9a11.7,11.7,0,0,0,8,3.1,12.2,12.2,0,0,0,8.5-3.5,12,12,0,0,0,0-17L137,168l11.5-11.5A12,12,0,0,0,148.5,139.5Zm-56,63a11.8,11.8,0,0,1-17,0l-22-22a11.8,11.8,0,0,1,0-17L76,141l39,39Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M76,124l56,56-31,31a24,24,0,0,1-34,0L45,189a24,24,0,0,1,0-34ZM211,67,189,45a24,24,0,0,0-34,0L124,76l56,56,31-31A24,24,0,0,0,211,67Z"
            opacity="0.2"
          />
          <path d="M237.7,18.3a8.1,8.1,0,0,0-11.4,0L200,44.7l-5.4-5.4a31.9,31.9,0,0,0-45.2,0L124,64.7l-2.3-2.4a8.1,8.1,0,0,0-11.4,11.4l72,72a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4l-2.4-2.3,25.4-25.4a31.9,31.9,0,0,0,0-45.2L211.3,56l26.4-26.3A8.1,8.1,0,0,0,237.7,18.3Zm-32.3,77L180,120.7,135.3,76l25.4-25.4a15.9,15.9,0,0,1,22.6,0l22.1,22.1a15.9,15.9,0,0,1,0,22.6Zm-67.1,43L120,156.7,99.3,136l18.4-18.3a8.1,8.1,0,0,0-11.4-11.4L88,124.7,73.7,110.3a8.1,8.1,0,0,0-11.4,11.4l2.4,2.3L39.3,149.4a31.9,31.9,0,0,0,0,45.2l5.4,5.4L18.3,226.3a8.1,8.1,0,0,0,0,11.4,8.2,8.2,0,0,0,11.4,0L56,211.3l5.4,5.4a31.9,31.9,0,0,0,45.2,0L132,191.3l2.3,2.4a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4L131.3,168l18.4-18.3a8.1,8.1,0,0,0-11.4-11.4Zm-43,67.1a15.9,15.9,0,0,1-22.6,0L50.6,183.3a15.9,15.9,0,0,1,0-22.6L76,135.3,120.7,180Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M237.7,29.7,211.3,56l5.4,5.4a31.9,31.9,0,0,1,0,45.2L191.3,132l2.4,2.3a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0l-8-8h0l-56-56h0l-8-8a8.1,8.1,0,0,1,11.4-11.4l2.3,2.4,25.4-25.4a31.9,31.9,0,0,1,45.2,0l5.4,5.4,26.3-26.4a8.1,8.1,0,0,1,11.4,11.4ZM138.3,138.3,120,156.7,99.3,136l18.4-18.3a8.1,8.1,0,0,0-11.4-11.4L88,124.7l-6.3-6.4h0l-8-8a8.1,8.1,0,0,0-11.4,11.4l2.4,2.3L39.3,149.4a31.9,31.9,0,0,0,0,45.2l5.4,5.4L18.3,226.3a8.1,8.1,0,0,0,0,11.4,8.2,8.2,0,0,0,11.4,0L56,211.3l5.4,5.4a31.9,31.9,0,0,0,45.2,0L132,191.3l2.3,2.4a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4l-8-8h0l-6.4-6.3,18.4-18.3a8.1,8.1,0,0,0-11.4-11.4Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M236.2,19.8a5.8,5.8,0,0,0-8.4,0L200,47.5l-6.8-6.8a30,30,0,0,0-42.4,0L124,67.5l-3.8-3.7a5.9,5.9,0,0,0-8.4,8.4l72,72a5.9,5.9,0,0,0,8.4-8.4l-3.7-3.8,26.8-26.8a30,30,0,0,0,0-42.4L208.5,56l27.7-27.8A5.8,5.8,0,0,0,236.2,19.8ZM206.8,96.7,180,123.5,132.5,76l26.8-26.8a18,18,0,0,1,25.4,0l22.1,22.1A18,18,0,0,1,206.8,96.7Zm-67,43.1L120,159.5,96.5,136l19.7-19.8a5.9,5.9,0,0,0-8.4-8.4L88,127.5,72.2,111.8a5.9,5.9,0,0,0-8.4,8.4l3.7,3.8L40.7,150.8a30,30,0,0,0,0,42.4l6.8,6.8L19.8,227.8a5.9,5.9,0,1,0,8.4,8.4L56,208.5l6.8,6.8a30.2,30.2,0,0,0,42.4,0L132,188.5l3.8,3.7a5.9,5.9,0,0,0,8.4-8.4L128.5,168l19.7-19.8a5.9,5.9,0,0,0-8.4-8.4Zm-43.1,67a18,18,0,0,1-25.4,0L49.2,184.7a18,18,0,0,1,0-25.4L76,132.5,123.5,180Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M234.8,21.2a3.9,3.9,0,0,0-5.6,0L200,50.3l-8.2-8.2a28,28,0,0,0-39.6,0L124,70.3l-5.2-5.1a4,4,0,0,0-5.6,5.6l64,64h0l8,8a3.9,3.9,0,0,0,5.6,0,3.9,3.9,0,0,0,0-5.6l-5.1-5.2,28.2-28.2a28,28,0,0,0,0-39.6L205.7,56l29.1-29.2A3.9,3.9,0,0,0,234.8,21.2ZM214.1,84a19.7,19.7,0,0,1-5.9,14.1L180,126.3,129.7,76l28.2-28.2a19.8,19.8,0,0,1,28.2,0l22.1,22.1A19.7,19.7,0,0,1,214.1,84Zm-72.9,57.2L120,162.3,93.7,136l21.1-21.2a4,4,0,0,0-5.6-5.6L88,130.3,70.8,113.2a4,4,0,0,0-5.6,5.6l5.1,5.2L42.1,152.2a28,28,0,0,0,0,39.6l8.2,8.2L21.2,229.2a3.9,3.9,0,0,0,0,5.6,3.9,3.9,0,0,0,5.6,0L56,205.7l8.2,8.2a28,28,0,0,0,39.6,0L132,185.7l5.2,5.1a3.9,3.9,0,0,0,5.6,0,3.9,3.9,0,0,0,0-5.6L125.7,168l21.1-21.2a4,4,0,0,0-5.6-5.6Zm-43.1,67a19.8,19.8,0,0,1-28.2,0L47.8,186.1a19.8,19.8,0,0,1,0-28.2L76,129.7,126.3,180Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M237.7,18.3a8.1,8.1,0,0,0-11.4,0L200,44.7l-5.4-5.4a31.9,31.9,0,0,0-45.2,0L124,64.7l-2.3-2.4a8.1,8.1,0,0,0-11.4,11.4l72,72a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4l-2.4-2.3,25.4-25.4a31.9,31.9,0,0,0,0-45.2L211.3,56l26.4-26.3A8.1,8.1,0,0,0,237.7,18.3Zm-32.3,77L180,120.7,135.3,76l25.4-25.4a15.9,15.9,0,0,1,22.6,0l22.1,22.1a15.9,15.9,0,0,1,0,22.6Zm-67.1,43L120,156.7,99.3,136l18.4-18.3a8.1,8.1,0,0,0-11.4-11.4L88,124.7,73.7,110.3a8.1,8.1,0,0,0-11.4,11.4l2.4,2.3L39.3,149.4a31.9,31.9,0,0,0,0,45.2l5.4,5.4L18.3,226.3a8.1,8.1,0,0,0,0,11.4,8.2,8.2,0,0,0,11.4,0L56,211.3l5.4,5.4a32.1,32.1,0,0,0,45.2,0L132,191.3l2.3,2.4a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4L131.3,168l18.4-18.3a8.1,8.1,0,0,0-11.4-11.4Zm-43,67.1a16.1,16.1,0,0,1-22.6,0L50.6,183.3a15.9,15.9,0,0,1,0-22.6L76,135.3,120.7,180Z" />
        </>
      )}
    </svg>
  );
}

export function PhPlusCircle(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,20A108,108,0,1,0,236,128,108.1,108.1,0,0,0,128,20Zm0,192a84,84,0,1,1,84-84A84.1,84.1,0,0,1,128,212Zm52-84a12,12,0,0,1-12,12H140v28a12,12,0,0,1-24,0V140H88a12,12,0,0,1,0-24h28V88a12,12,0,0,1,24,0v28h28A12,12,0,0,1,180,128Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="128" cy="128" r="96" />
          </g>
          <path d="M128,24A104,104,0,1,0,232,128,104,104,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Z" />
          <path d="M168,120H136V88a8,8,0,0,0-16,0v32H88a8,8,0,0,0,0,16h32v32a8,8,0,0,0,16,0V136h32a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.1,104.1,0,0,0,128,24Zm40,112H136v32a8,8,0,0,1-16,0V136H88a8,8,0,0,1,0-16h32V88a8,8,0,0,1,16,0v32h32a8,8,0,0,1,0,16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,26A102,102,0,1,0,230,128,102.1,102.1,0,0,0,128,26Zm0,192a90,90,0,1,1,90-90A90.1,90.1,0,0,1,128,218Zm46-90a6,6,0,0,1-6,6H134v34a6,6,0,0,1-12,0V134H88a6,6,0,0,1,0-12h34V88a6,6,0,0,1,12,0v34h34A6,6,0,0,1,174,128Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,28A100,100,0,1,0,228,128,100.2,100.2,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm44-92a4,4,0,0,1-4,4H132v36a4,4,0,0,1-8,0V132H88a4,4,0,0,1,0-8h36V88a4,4,0,0,1,8,0v36h36A4,4,0,0,1,172,128Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.1,104.1,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm48-88a8,8,0,0,1-8,8H136v32a8,8,0,0,1-16,0V136H88a8,8,0,0,1,0-16h32V88a8,8,0,0,1,16,0v32h32A8,8,0,0,1,176,128Z" />
        </>
      )}
    </svg>
  );
}

export function PhPlusMinus(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M208.5,64.5l-144,144a12.1,12.1,0,0,1-17,0,12,12,0,0,1,0-17l144-144a12,12,0,0,1,17,17ZM60,112a12,12,0,0,0,24,0V84h28a12,12,0,0,0,0-24H84V32a12,12,0,0,0-24,0V60H32a12,12,0,0,0,0,24H60Zm164,60H144a12,12,0,0,0,0,24h80a12,12,0,0,0,0-24Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M205.7,61.7l-144,144a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4l144-144a8.1,8.1,0,0,1,11.4,11.4ZM64,112a8,8,0,0,0,16,0V80h32a8,8,0,0,0,0-16H80V32a8,8,0,0,0-16,0V64H32a8,8,0,0,0,0,16H64Zm160,64H144a8,8,0,0,0,0,16h80a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M205.7,61.7l-144,144a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4l144-144a8.1,8.1,0,0,1,11.4,11.4ZM64,112a8,8,0,0,0,16,0V80h32a8,8,0,0,0,0-16H80V32a8,8,0,0,0-16,0V64H32a8,8,0,0,0,0,16H64Zm160,64H144a8,8,0,0,0,0,16h80a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M204.2,60.2l-144,144a5.9,5.9,0,0,1-8.4-8.4l144-144a5.9,5.9,0,0,1,8.4,8.4ZM66,112a6,6,0,0,0,12,0V78h34a6,6,0,0,0,0-12H78V32a6,6,0,0,0-12,0V66H32a6,6,0,0,0,0,12H66Zm158,66H144a6,6,0,0,0,0,12h80a6,6,0,0,0,0-12Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M202.8,58.8l-144,144a3.9,3.9,0,0,1-5.6,0,3.9,3.9,0,0,1,0-5.6l144-144a4,4,0,1,1,5.6,5.6ZM68,112a4,4,0,0,0,8,0V76h36a4,4,0,0,0,0-8H76V32a4,4,0,0,0-8,0V68H32a4,4,0,0,0,0,8H68Zm156,68H144a4,4,0,0,0,0,8h80a4,4,0,0,0,0-8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M205.7,61.7l-144,144a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4l144-144a8.1,8.1,0,0,1,11.4,11.4ZM64,112a8,8,0,0,0,16,0V80h32a8,8,0,0,0,0-16H80V32a8,8,0,0,0-16,0V64H32a8,8,0,0,0,0,16H64Zm160,64H144a8,8,0,0,0,0,16h80a8,8,0,0,0,0-16Z" />
        </>
      )}
    </svg>
  );
}

export function PhPlus(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M228,128a12,12,0,0,1-12,12H140v76a12,12,0,0,1-24,0V140H40a12,12,0,0,1,0-24h76V40a12,12,0,0,1,24,0v76h76A12,12,0,0,1,228,128Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M224,128a8,8,0,0,1-8,8H136v80a8,8,0,0,1-16,0V136H40a8,8,0,0,1,0-16h80V40a8,8,0,0,1,16,0v80h80A8,8,0,0,1,224,128Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M224,128a8,8,0,0,1-8,8H136v80a8,8,0,0,1-16,0V136H40a8,8,0,0,1,0-16h80V40a8,8,0,0,1,16,0v80h80A8,8,0,0,1,224,128Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M222,128a6,6,0,0,1-6,6H134v82a6,6,0,0,1-12,0V134H40a6,6,0,0,1,0-12h82V40a6,6,0,0,1,12,0v82h82A6,6,0,0,1,222,128Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M220,128a4,4,0,0,1-4,4H132v84a4,4,0,0,1-8,0V132H40a4,4,0,0,1,0-8h84V40a4,4,0,0,1,8,0v84h84A4,4,0,0,1,220,128Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M224,128a8,8,0,0,1-8,8H136v80a8,8,0,0,1-16,0V136H40a8,8,0,0,1,0-16h80V40a8,8,0,0,1,16,0v80h80A8,8,0,0,1,224,128Z" />
        </>
      )}
    </svg>
  );
}

export function PhPokerChip(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M205.2,52.6l-.8-1-1-.8a107.7,107.7,0,0,0-150.8,0l-1,.8-.8,1a107.7,107.7,0,0,0,0,150.8l.8,1,1,.8a107.7,107.7,0,0,0,150.8,0l1-.8.8-1a107.7,107.7,0,0,0,0-150.8Zm5.9,63.4H186.8A60.6,60.6,0,0,0,178,94.9l17.2-17.2A82.7,82.7,0,0,1,211.1,116ZM140,44.9a82.7,82.7,0,0,1,38.3,15.9L161.1,78A60.6,60.6,0,0,0,140,69.2Zm-24,0V69.2A60.6,60.6,0,0,0,94.9,78L77.7,60.8A82.7,82.7,0,0,1,116,44.9ZM60.8,77.7,78,94.9A60.6,60.6,0,0,0,69.2,116H44.9A82.7,82.7,0,0,1,60.8,77.7ZM44.9,140H69.2A60.6,60.6,0,0,0,78,161.1L60.8,178.3A82.7,82.7,0,0,1,44.9,140ZM116,211.1a82.7,82.7,0,0,1-38.3-15.9L94.9,178a60.6,60.6,0,0,0,21.1,8.8ZM92,128a36,36,0,1,1,36,36A36,36,0,0,1,92,128Zm48,83.1V186.8a60.6,60.6,0,0,0,21.1-8.8l17.2,17.2A82.7,82.7,0,0,1,140,211.1Zm55.2-32.8L178,161.1a60.6,60.6,0,0,0,8.8-21.1h24.3A82.7,82.7,0,0,1,195.2,178.3Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M128,32a96,96,0,1,0,96,96A96,96,0,0,0,128,32Zm0,152a56,56,0,1,1,56-56A56,56,0,0,1,128,184Z" />
          </g>
          <path d="M202.2,200.8a103.8,103.8,0,0,0,0-145.6,2.3,2.3,0,0,0-.7-.7,2.3,2.3,0,0,0-.7-.7,103.8,103.8,0,0,0-145.6,0,2.3,2.3,0,0,0-.7.7,2.3,2.3,0,0,0-.7.7,103.8,103.8,0,0,0,0,145.6l.7.7a2.3,2.3,0,0,0,.7.7,103.8,103.8,0,0,0,145.6,0,2.3,2.3,0,0,0,.7-.7A2.3,2.3,0,0,0,202.2,200.8ZM40.4,136H64.5a63.6,63.6,0,0,0,13,31.2L60.4,184.3A87.5,87.5,0,0,1,40.4,136Zm20-64.3L77.5,88.8a63.6,63.6,0,0,0-13,31.2H40.4A87.5,87.5,0,0,1,60.4,71.7ZM215.6,120H191.5a63.6,63.6,0,0,0-13-31.2l17.1-17.1A87.5,87.5,0,0,1,215.6,120ZM128,176a48,48,0,1,1,48-48A48,48,0,0,1,128,176Zm39.2-98.5a63.6,63.6,0,0,0-31.2-13V40.4a87.5,87.5,0,0,1,48.3,20ZM120,64.5a63.6,63.6,0,0,0-31.2,13L71.7,60.4a87.5,87.5,0,0,1,48.3-20Zm-31.2,114a63.6,63.6,0,0,0,31.2,13v24.1a87.5,87.5,0,0,1-48.3-20Zm47.2,13a63.6,63.6,0,0,0,31.2-13l17.1,17.1a87.5,87.5,0,0,1-48.3,20Zm42.5-24.3a63.6,63.6,0,0,0,13-31.2h24.1a87.5,87.5,0,0,1-20,48.3Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M202.2,200.8a103.8,103.8,0,0,0,0-145.6,2.3,2.3,0,0,0-.7-.7,2.3,2.3,0,0,0-.7-.7,103.8,103.8,0,0,0-145.6,0,2.3,2.3,0,0,0-.7.7,2.3,2.3,0,0,0-.7.7,103.8,103.8,0,0,0,0,145.6l.7.7a2.3,2.3,0,0,0,.7.7,103.8,103.8,0,0,0,145.6,0,2.3,2.3,0,0,0,.7-.7A2.3,2.3,0,0,0,202.2,200.8ZM40.4,136H64.5a63.6,63.6,0,0,0,13,31.2L60.4,184.3A87.5,87.5,0,0,1,40.4,136Zm20-64.3L77.5,88.8a63.6,63.6,0,0,0-13,31.2H40.4A87.5,87.5,0,0,1,60.4,71.7ZM215.6,120H191.5a63.6,63.6,0,0,0-13-31.2l17.1-17.1A87.5,87.5,0,0,1,215.6,120ZM167.2,77.5a63.6,63.6,0,0,0-31.2-13V40.4a87.5,87.5,0,0,1,48.3,20ZM120,64.5a63.6,63.6,0,0,0-31.2,13L71.7,60.4a87.5,87.5,0,0,1,48.3-20Zm-31.2,114a63.6,63.6,0,0,0,31.2,13v24.1a87.5,87.5,0,0,1-48.3-20Zm47.2,13a63.6,63.6,0,0,0,31.2-13l17.1,17.1a87.5,87.5,0,0,1-48.3,20Zm42.5-24.3a63.6,63.6,0,0,0,13-31.2h24.1a87.5,87.5,0,0,1-20,48.3Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M200.5,199.7a101.9,101.9,0,0,0,0-143.4c-.2-.2-.2-.3-.4-.4s-.2-.2-.4-.4a101.9,101.9,0,0,0-143.4,0l-.4.4-.4.4a101.9,101.9,0,0,0,0,143.4l.4.4c.1.2.2.2.4.4a101.9,101.9,0,0,0,143.4,0c.2-.2.3-.2.4-.4S200.3,199.9,200.5,199.7ZM38.2,134H66.3a62,62,0,0,0,13.8,33.4L60.3,187.2A89.5,89.5,0,0,1,38.2,134ZM60.3,68.8,80.1,88.6A62,62,0,0,0,66.3,122H38.2A89.5,89.5,0,0,1,60.3,68.8ZM217.8,122H189.7a62,62,0,0,0-13.8-33.4l19.8-19.8A89.5,89.5,0,0,1,217.8,122ZM128,178a50,50,0,1,1,50-50A50,50,0,0,1,128,178Zm39.4-97.9A62,62,0,0,0,134,66.3V38.2a89.5,89.5,0,0,1,53.2,22.1ZM122,66.3A62,62,0,0,0,88.6,80.1L68.8,60.3A89.5,89.5,0,0,1,122,38.2ZM88.6,175.9A62,62,0,0,0,122,189.7v28.1a89.5,89.5,0,0,1-53.2-22.1ZM134,189.7a62,62,0,0,0,33.4-13.8l19.8,19.8A89.5,89.5,0,0,1,134,217.8Zm41.9-22.3A62,62,0,0,0,189.7,134h28.1a89.5,89.5,0,0,1-22.1,53.2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M199,198.3a99.7,99.7,0,0,0,0-140.6l-.3-.4-.4-.3A99.7,99.7,0,0,0,57.7,57l-.4.3-.3.4a99.7,99.7,0,0,0,0,140.6l.3.4.4.3a99.7,99.7,0,0,0,140.6,0l.4-.3ZM36.1,132h32a59.5,59.5,0,0,0,14.8,35.5L60.2,190.1A91.4,91.4,0,0,1,36.1,132ZM60.2,65.9,82.9,88.5A59.5,59.5,0,0,0,68.1,124h-32A91.4,91.4,0,0,1,60.2,65.9ZM219.9,124h-32a59.5,59.5,0,0,0-14.8-35.5l22.7-22.6A91.4,91.4,0,0,1,219.9,124ZM128,180a52,52,0,1,1,52-52A52,52,0,0,1,128,180Zm39.5-97.1A59.5,59.5,0,0,0,132,68.1v-32a91.4,91.4,0,0,1,58.1,24.1ZM124,68.1A59.5,59.5,0,0,0,88.5,82.9L65.9,60.2A91.4,91.4,0,0,1,124,36.1Zm-35.5,105A59.5,59.5,0,0,0,124,187.9v32a91.4,91.4,0,0,1-58.1-24.1ZM132,187.9a59.5,59.5,0,0,0,35.5-14.8l22.6,22.7A91.4,91.4,0,0,1,132,219.9Zm41.1-20.4A59.5,59.5,0,0,0,187.9,132h32a91.4,91.4,0,0,1-24.1,58.1Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M202.2,200.8a103.8,103.8,0,0,0,0-145.6,2.3,2.3,0,0,0-.7-.7,2.3,2.3,0,0,0-.7-.7,103.8,103.8,0,0,0-145.6,0,2.3,2.3,0,0,0-.7.7,2.3,2.3,0,0,0-.7.7,103.8,103.8,0,0,0,0,145.6l.7.7a2.3,2.3,0,0,0,.7.7,103.8,103.8,0,0,0,145.6,0,2.3,2.3,0,0,0,.7-.7A2.3,2.3,0,0,0,202.2,200.8ZM40.4,136H64.5a63.6,63.6,0,0,0,13,31.2L60.4,184.3A87.5,87.5,0,0,1,40.4,136Zm20-64.3L77.5,88.8a63.6,63.6,0,0,0-13,31.2H40.4A87.5,87.5,0,0,1,60.4,71.7ZM215.6,120H191.5a63.6,63.6,0,0,0-13-31.2l17.1-17.1A87.5,87.5,0,0,1,215.6,120ZM128,176a48,48,0,1,1,48-48A48,48,0,0,1,128,176Zm39.2-98.5a63.6,63.6,0,0,0-31.2-13V40.4a87.5,87.5,0,0,1,48.3,20ZM120,64.5a63.6,63.6,0,0,0-31.2,13L71.7,60.4a87.5,87.5,0,0,1,48.3-20Zm-31.2,114a63.6,63.6,0,0,0,31.2,13v24.1a87.5,87.5,0,0,1-48.3-20Zm47.2,13a63.6,63.6,0,0,0,31.2-13l17.1,17.1a87.5,87.5,0,0,1-48.3,20Zm42.5-24.3a63.6,63.6,0,0,0,13-31.2h24.1a87.5,87.5,0,0,1-20,48.3Z" />
        </>
      )}
    </svg>
  );
}

export function PhPoliceCar(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M244,112h-9.4L204.5,65.2A19.9,19.9,0,0,0,187.6,56H68.4a19.9,19.9,0,0,0-16.9,9.2L21.4,112H12a12,12,0,0,0,0,24h4v68a20.1,20.1,0,0,0,20,20H60a20.1,20.1,0,0,0,20-20V184h96v20a20.1,20.1,0,0,0,20,20h24a20.1,20.1,0,0,0,20-20V136h4a12,12,0,0,0,0-24ZM70.6,80H185.4L206,112H50ZM56,200H40V184H56Zm144,0V184h16v16Zm16-40H40V136H216ZM92,28a12,12,0,0,1,12-12h48a12,12,0,0,1,0,24H104A12,12,0,0,1,92,28Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M184,184h40v24a8,8,0,0,1-8,8H192a8,8,0,0,1-8-8ZM32,208a8,8,0,0,0,8,8H64a8,8,0,0,0,8-8V184H32ZM194.3,68a7.9,7.9,0,0,0-6.9-4H68.6a7.9,7.9,0,0,0-6.9,4L32,120H224Z"
            opacity="0.2"
          />
          <path d="M240,112H228.6L201.2,64.1A15.9,15.9,0,0,0,187.4,56H68.6a15.9,15.9,0,0,0-13.8,8.1L27.4,112H16a8,8,0,0,0,0,16h8v80a16,16,0,0,0,16,16H64a16,16,0,0,0,16-16V192h96v16a16,16,0,0,0,16,16h24a16,16,0,0,0,16-16V128h8a8,8,0,0,0,0-16ZM68.6,72H187.4l22.8,40H45.8ZM64,208H40V192H64Zm128,0V192h24v16Zm24-32H40V128H216ZM56,152a8,8,0,0,1,8-8H80a8,8,0,0,1,0,16H64A8,8,0,0,1,56,152Zm112,0a8,8,0,0,1,8-8h16a8,8,0,0,1,0,16H176A8,8,0,0,1,168,152ZM96,32a8,8,0,0,1,8-8h48a8,8,0,0,1,0,16H104A8,8,0,0,1,96,32Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M96,32a8,8,0,0,1,8-8h48a8,8,0,0,1,0,16H104A8,8,0,0,1,96,32Zm152,88a8,8,0,0,1-8,8h-8v80a16,16,0,0,1-16,16H192a16,16,0,0,1-16-16V192H80v16a16,16,0,0,1-16,16H40a16,16,0,0,1-16-16V128H16a8,8,0,0,1,0-16H27.4L54.8,64.1A15.9,15.9,0,0,1,68.6,56H187.4a15.9,15.9,0,0,1,13.8,8.1L228.6,112H240A8,8,0,0,1,248,120ZM88,152a8,8,0,0,0-8-8H64a8,8,0,0,0,0,16H80A8,8,0,0,0,88,152Zm112,0a8,8,0,0,0-8-8H176a8,8,0,0,0,0,16h16A8,8,0,0,0,200,152Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M240,114H227.5l-28-48.9A13.9,13.9,0,0,0,187.4,58H68.6a13.9,13.9,0,0,0-12.1,7.1L28.5,114H16a6,6,0,0,0,0,12H26v82a14,14,0,0,0,14,14H64a14,14,0,0,0,14-14V190H178v18a14,14,0,0,0,14,14h24a14,14,0,0,0,14-14V126h10a6,6,0,0,0,0-12ZM66.9,71a2.1,2.1,0,0,1,1.7-1H187.4a2.1,2.1,0,0,1,1.7,1l24.6,43H42.3ZM66,208a2,2,0,0,1-2,2H40a2,2,0,0,1-2-2V190H66Zm150,2H192a2,2,0,0,1-2-2V190h28v18A2,2,0,0,1,216,210Zm2-32H38V126H218ZM58,152a6,6,0,0,1,6-6H80a6,6,0,0,1,0,12H64A6,6,0,0,1,58,152Zm112,0a6,6,0,0,1,6-6h16a6,6,0,0,1,0,12H176A6,6,0,0,1,170,152ZM98,32a6,6,0,0,1,6-6h48a6,6,0,0,1,0,12H104A6,6,0,0,1,98,32Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M240,116H226.3L197.8,66a12.1,12.1,0,0,0-10.4-6H68.6a12.1,12.1,0,0,0-10.4,6L29.7,116H16a4,4,0,0,0,0,8H28v84a12,12,0,0,0,12,12H64a12,12,0,0,0,12-12V188H180v20a12,12,0,0,0,12,12h24a12,12,0,0,0,12-12V124h12a4,4,0,0,0,0-8ZM65.2,70a4,4,0,0,1,3.4-2H187.4a4,4,0,0,1,3.4,2l26.3,46H38.9ZM68,208a4,4,0,0,1-4,4H40a4,4,0,0,1-4-4V188H68Zm148,4H192a4,4,0,0,1-4-4V188h32v20A4,4,0,0,1,216,212Zm4-32H36V124H220ZM60,152a4,4,0,0,1,4-4H80a4,4,0,0,1,0,8H64A4,4,0,0,1,60,152Zm112,0a4,4,0,0,1,4-4h16a4,4,0,0,1,0,8H176A4,4,0,0,1,172,152ZM100,32a4,4,0,0,1,4-4h48a4,4,0,0,1,0,8H104A4,4,0,0,1,100,32Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M240,112H228.6L201.2,64.1A15.9,15.9,0,0,0,187.4,56H68.6a15.9,15.9,0,0,0-13.8,8.1L27.4,112H16a8,8,0,0,0,0,16h8v80a16,16,0,0,0,16,16H64a16,16,0,0,0,16-16V192h96v16a16,16,0,0,0,16,16h24a16,16,0,0,0,16-16V128h8a8,8,0,0,0,0-16ZM68.6,72H187.4l22.8,40H45.8ZM64,208H40V192H64Zm128,0V192h24v16Zm24-32H40V128H216ZM56,152a8,8,0,0,1,8-8H80a8,8,0,0,1,0,16H64A8,8,0,0,1,56,152Zm112,0a8,8,0,0,1,8-8h16a8,8,0,0,1,0,16H176A8,8,0,0,1,168,152ZM96,32a8,8,0,0,1,8-8h48a8,8,0,0,1,0,16H104A8,8,0,0,1,96,32Z" />
        </>
      )}
    </svg>
  );
}

export function PhPolygon(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M233.5,97.5a36.1,36.1,0,0,0-51-51h0a47.2,47.2,0,0,0-4.1,5l-22.5-6.2A36,36,0,0,0,94.5,22.5h0a36.5,36.5,0,0,0-7.8,39.3L57.2,88.4a36.2,36.2,0,0,0-42.7,6.1h0A36,36,0,0,0,40,156a35.8,35.8,0,0,0,20.3-6.3l65.2,47.9a36.1,36.1,0,1,0,60-15.1l-2.9-2.5L208,108A36.2,36.2,0,0,0,233.5,97.5Zm-93.8,80.8L74.5,130.4a37.2,37.2,0,0,0-1.2-24.2l29.5-26.6a36.1,36.1,0,0,0,42.7-6.1,47.2,47.2,0,0,0,4.1-5l22.5,6.2a36.1,36.1,0,0,0,10.4,22.8l2.9,2.5L160,172A35.8,35.8,0,0,0,139.7,178.3ZM216.5,63.5a12,12,0,1,1-17,0h0A12,12,0,0,1,216.5,63.5Zm-105-24a12,12,0,1,1,0,17,12,12,0,0,1,0-17Zm-80,89a12,12,0,0,1,0-17h0a12,12,0,1,1,0,17Zm137,88a12,12,0,0,1-17-17h0a12,12,0,0,1,17,17Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M137,65a24,24,0,1,1,0-34A24.2,24.2,0,0,1,137,65ZM23,103a24,24,0,0,0,34,34,24,24,0,0,0-34-34Zm120,88a24,24,0,1,0,34,0A24.2,24.2,0,0,0,143,191ZM225,55a24,24,0,1,0,0,34A24.2,24.2,0,0,0,225,55Z"
            opacity="0.2"
          />
          <path d="M230.6,49.4a31.9,31.9,0,0,0-45.2,0h0a30.2,30.2,0,0,0-5.2,6.7L152,48.4a32,32,0,0,0-54.6-23h0a32,32,0,0,0-5.8,37.4L57.7,93.3a32,32,0,0,0-40.3,4.1h0a31.9,31.9,0,0,0,0,45.2A31.6,31.6,0,0,0,40,152a32.1,32.1,0,0,0,20.3-7.2l70,51.3a32,32,0,0,0,7.1,34.5,31.9,31.9,0,0,0,45.2,0,31.9,31.9,0,0,0,0-45.2,43.3,43.3,0,0,0-4.7-4l27.3-77.5H208a31.6,31.6,0,0,0,22.6-9.4A31.9,31.9,0,0,0,230.6,49.4ZM108.7,36.7h0a16,16,0,1,1,0,22.6A15.9,15.9,0,0,1,108.7,36.7Zm-80,94.6a15.9,15.9,0,0,1,0-22.6h0a16,16,0,1,1,0,22.6Zm142.6,88a16,16,0,0,1-22.6-22.6h0a16,16,0,0,1,22.6,22.6Zm-8.5-43.2a32.4,32.4,0,0,0-23.1,7.1l-70-51.3a32.4,32.4,0,0,0-1.3-26.7l33.9-30.5A32.4,32.4,0,0,0,120,80a31.6,31.6,0,0,0,22.6-9.4,30.2,30.2,0,0,0,5.2-6.7L176,71.6a31.6,31.6,0,0,0,9.4,23,43.3,43.3,0,0,0,4.7,4Zm56.5-92.8a16,16,0,0,1-22.6-22.6h0a16,16,0,1,1,22.6,22.6Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M230.6,49.4a31.9,31.9,0,0,0-45.2,0h0a30.2,30.2,0,0,0-5.2,6.7L152,48.4a32,32,0,0,0-54.6-23h0a32,32,0,0,0-5.8,37.4L57.7,93.3a32,32,0,0,0-40.3,4.1h0a31.9,31.9,0,0,0,0,45.2A31.6,31.6,0,0,0,40,152a32.1,32.1,0,0,0,20.3-7.2l70,51.3a32,32,0,0,0,7.1,34.5,31.9,31.9,0,0,0,45.2,0,31.9,31.9,0,0,0,0-45.2,43.3,43.3,0,0,0-4.7-4l27.3-77.5H208a31.6,31.6,0,0,0,22.6-9.4A31.9,31.9,0,0,0,230.6,49.4ZM162.8,176.1a32.4,32.4,0,0,0-23.1,7.1l-70-51.3a32.4,32.4,0,0,0-1.3-26.7l33.9-30.5A32.4,32.4,0,0,0,120,80a31.6,31.6,0,0,0,22.6-9.4,30.2,30.2,0,0,0,5.2-6.7L176,71.6a31.6,31.6,0,0,0,9.4,23,43.3,43.3,0,0,0,4.7,4Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M229.2,50.8a30,30,0,0,0-42.4,0h0a29.8,29.8,0,0,0-5.6,7.7l-31.3-8.6a29.7,29.7,0,0,0-8.7-23.1,30,30,0,0,0-42.4,0h0a30,30,0,0,0-4.7,36.4L57.8,95.9a30,30,0,0,0-39,2.9h0a30,30,0,0,0,0,42.4A29.6,29.6,0,0,0,40,150a30,30,0,0,0,20.2-7.8l72.5,53.3a30.2,30.2,0,0,0,6.1,33.7,29.9,29.9,0,0,0,42.4,0,30,30,0,0,0,0-42.4,27.4,27.4,0,0,0-5.8-4.5l28.5-80.6a27.3,27.3,0,0,0,4.1.3,29.6,29.6,0,0,0,21.2-8.8A30,30,0,0,0,229.2,50.8ZM107.3,35.3h0a18,18,0,1,1,0,25.4A18,18,0,0,1,107.3,35.3Zm-80,97.4a18,18,0,0,1,0-25.4h0a18,18,0,1,1,0,25.4Zm145.4,88a18,18,0,0,1-25.4-25.4h0a18,18,0,0,1,25.4,25.4Zm-8.6-42.4a29.9,29.9,0,0,0-24.3,7.5L67.3,132.5a30.5,30.5,0,0,0-1.4-27.7l36.3-32.7a30,30,0,0,0,44.6-10.6l31.3,8.6a29.7,29.7,0,0,0,8.7,23.1,27.4,27.4,0,0,0,5.8,4.5Zm56.6-93.6a18,18,0,0,1-25.4-25.4h0a18,18,0,0,1,25.4,25.4Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M227.8,52.2a28.1,28.1,0,0,0-39.6,0h0a27.9,27.9,0,0,0-5.9,8.7l-34.5-9.5a28,28,0,0,0-47.6-23.2h0a28.1,28.1,0,0,0-3.5,35.4L57.9,98.5a28,28,0,0,0-37.7,1.7h0a28.1,28.1,0,0,0,0,39.6,28,28,0,0,0,39.6,0l.2-.2,75.3,55.2a28.2,28.2,0,0,0,4.9,33,28,28,0,0,0,39.6,0,28.1,28.1,0,0,0,0-39.6,29,29,0,0,0-6.8-5l29.6-83.7a31.9,31.9,0,0,0,5.4.5,27.8,27.8,0,0,0,19.8-8.2A28.1,28.1,0,0,0,227.8,52.2ZM105.9,33.9h0a19.9,19.9,0,1,1,0,28.2A19.9,19.9,0,0,1,105.9,33.9Zm-80,100.2a19.9,19.9,0,0,1,0-28.2h0a19.9,19.9,0,1,1,0,28.2Zm148.2,88a19.9,19.9,0,0,1-28.2-28.2h0a19.9,19.9,0,0,1,28.2,28.2Zm-8.7-41.6a28.2,28.2,0,0,0-25.2,7.7h0l-.2.2L64.7,133.2a28.3,28.3,0,0,0-1.4-28.8l38.8-34.9a28,28,0,0,0,43.6-10.4l34.5,9.5a28.1,28.1,0,0,0,8,23.2,29,29,0,0,0,6.8,5Zm56.7-94.4a19.9,19.9,0,0,1-28.2-28.2h0a19.9,19.9,0,0,1,28.2,28.2Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M230.6,49.4a31.9,31.9,0,0,0-45.2,0h0a30.2,30.2,0,0,0-5.2,6.7L152,48.4a32,32,0,0,0-54.6-23h0a32,32,0,0,0-5.8,37.4L57.7,93.3a32,32,0,0,0-40.3,4.1h0a31.9,31.9,0,0,0,0,45.2A31.6,31.6,0,0,0,40,152a32.1,32.1,0,0,0,20.3-7.2l70,51.3a32,32,0,0,0,7.1,34.5,31.9,31.9,0,0,0,45.2,0,31.9,31.9,0,0,0,0-45.2,43.3,43.3,0,0,0-4.7-4l27.3-77.5H208a31.6,31.6,0,0,0,22.6-9.4A31.9,31.9,0,0,0,230.6,49.4ZM108.7,36.7h0a16,16,0,1,1,0,22.6A15.9,15.9,0,0,1,108.7,36.7Zm-80,94.6a15.9,15.9,0,0,1,0-22.6h0a16,16,0,1,1,0,22.6Zm142.6,88a16,16,0,0,1-22.6-22.6h0a16,16,0,0,1,22.6,22.6Zm-8.5-43.2a32.4,32.4,0,0,0-23.1,7.1l-70-51.3a32.4,32.4,0,0,0-1.3-26.7l33.9-30.5A32.4,32.4,0,0,0,120,80a31.6,31.6,0,0,0,22.6-9.4,30.2,30.2,0,0,0,5.2-6.7L176,71.6a31.6,31.6,0,0,0,9.4,23,43.3,43.3,0,0,0,4.7,4Zm56.5-92.8a16,16,0,0,1-22.6-22.6h0a16,16,0,1,1,22.6,22.6Z" />
        </>
      )}
    </svg>
  );
}

export function PhPopcorn(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M227.6,77.6A48.2,48.2,0,0,0,180,36a47.5,47.5,0,0,0-9.9,1,47.9,47.9,0,0,0-84.2,0A47.5,47.5,0,0,0,76,36,48.2,48.2,0,0,0,28.4,77.6a20.1,20.1,0,0,0-5,18.4h0L50.9,212.6A19.8,19.8,0,0,0,70.3,228H185.7a19.8,19.8,0,0,0,19.4-15.4L232.6,96A20.1,20.1,0,0,0,227.6,77.6ZM76,60a23,23,0,0,1,11.2,2.8,12,12,0,0,0,17.4-8,24,24,0,0,1,46.8,0,12,12,0,0,0,17.4,8A23,23,0,0,1,180,60a24.2,24.2,0,0,1,21.8,13.9l-33.2,9.4L135.4,70a19.9,19.9,0,0,0-14.8,0L87.4,83.3,54.2,73.9A24.2,24.2,0,0,1,76,60Zm25.1,43.7L128,92.9l26.9,10.8L141.5,204h-27ZM48.4,97.2l28.7,8.2L90.3,204H73.5ZM182.5,204H165.7l13.2-98.6,28.7-8.2Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M88,96l16,120H70.3a8.1,8.1,0,0,1-7.8-6.2L35.1,93.3a8.1,8.1,0,0,1,10-9.6ZM210.9,83.7,168,96,152,216h33.7a8.1,8.1,0,0,0,7.8-6.2L220.9,93.3A8.1,8.1,0,0,0,210.9,83.7Z"
            opacity="0.2"
          />
          <path d="M224.1,79.9l-.3-.4A43.9,43.9,0,0,0,168,41.7a44,44,0,0,0-80,0A43.9,43.9,0,0,0,32.2,79.5l-.3.4a15.7,15.7,0,0,0-4.6,15.2L54.8,211.7A15.9,15.9,0,0,0,70.3,224H185.7a15.9,15.9,0,0,0,15.5-12.3L228.7,95.1A15.7,15.7,0,0,0,224.1,79.9ZM76,56a27.4,27.4,0,0,1,13.1,3.3,8.4,8.4,0,0,0,7.1.2,8.2,8.2,0,0,0,4.5-5.6,28,28,0,0,1,54.6,0,8.2,8.2,0,0,0,4.5,5.6,8.4,8.4,0,0,0,7.1-.2A27.4,27.4,0,0,1,180,56a28,28,0,0,1,27,20.5L168.4,87.6,133.9,73.8a15.1,15.1,0,0,0-11.8,0L87.6,87.6,49,76.5A28,28,0,0,1,76,56Zm35,152L96.8,101.1,128,88.6l31.2,12.5L145,208ZM42.9,91.4h0l37.9,10.9L94.9,208H70.3ZM185.7,208H161.1l14.1-105.7,37.9-10.9h0Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M224.1,79.9l-.3-.4A43.9,43.9,0,0,0,168,41.7a44,44,0,0,0-80,0A43.9,43.9,0,0,0,32.2,79.5l-.3.4a15.7,15.7,0,0,0-4.6,15.2L54.8,211.7A15.9,15.9,0,0,0,70.3,224H185.7a15.9,15.9,0,0,0,15.5-12.3L228.7,95.1A15.7,15.7,0,0,0,224.1,79.9ZM76,56a27.4,27.4,0,0,1,13.1,3.3,8.4,8.4,0,0,0,7.1.2,8.2,8.2,0,0,0,4.5-5.6,28,28,0,0,1,54.6,0,8.2,8.2,0,0,0,4.5,5.6,8.4,8.4,0,0,0,7.1-.2A27.4,27.4,0,0,1,180,56a28,28,0,0,1,27,20.5L168.4,87.6,133.9,73.8a15.1,15.1,0,0,0-11.8,0L87.6,87.6,49,76.5A28,28,0,0,1,76,56ZM42.9,91.4h0l37.9,10.9L94.9,208H70.3ZM185.7,208H161.1l14.1-105.7,37.9-10.9h0Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M222.8,81.3l-1-.8A42,42,0,0,0,180,42a41.3,41.3,0,0,0-13.1,2.1,42,42,0,0,0-77.8,0A41.3,41.3,0,0,0,76,42,42,42,0,0,0,34.2,80.5l-1,.8a13.8,13.8,0,0,0-3.9,13.3h0L56.7,211.2A13.9,13.9,0,0,0,70.3,222H185.7a13.9,13.9,0,0,0,13.6-10.8L226.7,94.6A13.8,13.8,0,0,0,222.8,81.3ZM76,54a29.4,29.4,0,0,1,14,3.5,6,6,0,0,0,5.4.1,5.7,5.7,0,0,0,3.3-4.1,30,30,0,0,1,58.6,0,5.7,5.7,0,0,0,3.3,4.1,6,6,0,0,0,5.4-.1A29.4,29.4,0,0,1,180,54a30,30,0,0,1,29.4,23.9h-.2L168.3,89.7,133.2,75.6a14.4,14.4,0,0,0-10.4,0L87.7,89.7,46.8,78h-.2A30,30,0,0,1,76,54Zm85.4,45.8L146.7,210H109.3L94.6,99.8l32.7-13a1.7,1.7,0,0,1,1.4,0Zm-93,108.7L41,91.9h0a1.8,1.8,0,0,1,.5-1.9l.5-.3.4-.2h1.1l39.1,11.2L97.1,210H70.3A1.9,1.9,0,0,1,68.4,208.5ZM215,91.9,187.6,208.5a1.9,1.9,0,0,1-1.9,1.5H158.9l14.5-109.3,39.1-11.2h1.1l.4.2.5.3A1.8,1.8,0,0,1,215,91.9Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M221.4,82.8l-1.5-1.2A40,40,0,0,0,180,44a40.7,40.7,0,0,0-14.3,2.6,40,40,0,0,0-75.4,0A40.7,40.7,0,0,0,76,44,40,40,0,0,0,36.1,81.6l-1.5,1.2a11.8,11.8,0,0,0-3.4,11.4L58.7,210.7A11.8,11.8,0,0,0,70.3,220H185.7a11.8,11.8,0,0,0,11.6-9.3L224.8,94.2A11.8,11.8,0,0,0,221.4,82.8ZM76,52a32.6,32.6,0,0,1,15,3.7,4.1,4.1,0,0,0,5.8-2.6,31.9,31.9,0,0,1,62.4,0,4.1,4.1,0,0,0,5.8,2.6A32.6,32.6,0,0,1,180,52a32.1,32.1,0,0,1,31.7,27.5l-1.9.4L168.2,91.8,132.5,77.5a11.7,11.7,0,0,0-9,0L87.8,91.8,46.2,79.9l-1.9-.4A32.1,32.1,0,0,1,76,52Zm31.5,160L92.4,98.6l34.1-13.7a3.9,3.9,0,0,1,3,0l34.1,13.7L148.5,212Zm-41.1-3.1L39,92.4a4.2,4.2,0,0,1,1.1-3.9l1.3-.8c.1,0,.2,0,.2-.1a3.9,3.9,0,0,1,2.4,0L84.4,99.1,99.4,212H70.3A4.1,4.1,0,0,1,66.4,208.9ZM217,92.4,189.6,208.9a4.1,4.1,0,0,1-3.9,3.1H156.6l15-112.9L212,87.6a3.9,3.9,0,0,1,2.4,0c0,.1.1.1.2.1l1.3.8A4.2,4.2,0,0,1,217,92.4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M224.1,79.9l-.3-.4A43.9,43.9,0,0,0,168,41.7a44,44,0,0,0-80,0A43.9,43.9,0,0,0,32.2,79.5l-.3.4a15.7,15.7,0,0,0-4.6,15.2h0L54.8,211.7A15.9,15.9,0,0,0,70.3,224H185.7a15.9,15.9,0,0,0,15.5-12.3L228.7,95.1A15.7,15.7,0,0,0,224.1,79.9ZM76,56a27.4,27.4,0,0,1,13.1,3.3,8.4,8.4,0,0,0,7.1.2,8.2,8.2,0,0,0,4.5-5.6,28,28,0,0,1,54.6,0,8.2,8.2,0,0,0,4.5,5.6,8.4,8.4,0,0,0,7.1-.2A27.4,27.4,0,0,1,180,56a28,28,0,0,1,27,20.5L168.4,87.6,133.9,73.8a15.1,15.1,0,0,0-11.8,0L87.6,87.6,49,76.5A28,28,0,0,1,76,56Zm35,152L96.8,101.1,128,88.6l31.2,12.5L145,208ZM42.9,91.4h0l37.9,10.9L94.9,208H70.3ZM185.7,208H161.1l14.1-105.7,37.9-10.9h0Z" />
        </>
      )}
    </svg>
  );
}

export function PhPower(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M116,124V48a12,12,0,0,1,24,0v76a12,12,0,0,1-24,0Zm66.6-79.8a12,12,0,0,0-13.2,20.1,76,76,0,1,1-82.8,0A12,12,0,1,0,73.4,44.2a100,100,0,1,0,109.2,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M120,124V48a8,8,0,0,1,16,0v76a8,8,0,0,1-16,0Zm60.4-76.5a8.1,8.1,0,0,0-11.1,2.4,7.9,7.9,0,0,0,2.3,11,80,80,0,1,1-87.2,0,7.9,7.9,0,0,0,2.3-11,8.1,8.1,0,0,0-11.1-2.4,96,96,0,1,0,104.8,0Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm-8,32a8,8,0,0,1,16,0v60a8,8,0,0,1-16,0Zm8,152A80,80,0,0,1,83.5,61.5a8,8,0,0,1,11.1,2.2,7.9,7.9,0,0,1-2.2,11.1,64.1,64.1,0,1,0,71.1,0,8,8,0,0,1,8.9-13.3A80,80,0,0,1,128,208Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M122,124V48a6,6,0,0,1,12,0v76a6,6,0,0,1-12,0Zm57.3-74.8A6,6,0,0,0,171,51a6.1,6.1,0,0,0,1.7,8.3,82,82,0,1,1-89.4,0,6,6,0,0,0-6.6-10.1,94,94,0,1,0,102.6,0Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M124,124V48a4,4,0,0,1,8,0v76a4,4,0,0,1-8,0Zm54.2-73.1a4,4,0,0,0-4.4,6.7,84,84,0,1,1-91.6,0,4,4,0,1,0-4.4-6.7,92,92,0,1,0,100.4,0Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M120,124V48a8,8,0,0,1,16,0v76a8,8,0,0,1-16,0Zm60.4-76.5a8.1,8.1,0,0,0-11.1,2.4,7.9,7.9,0,0,0,2.3,11,80,80,0,1,1-87.2,0,7.9,7.9,0,0,0,2.3-11,8.1,8.1,0,0,0-11.1-2.4,96,96,0,1,0,104.8,0Z" />
        </>
      )}
    </svg>
  );
}

export function PhPrescription(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M189,188l19.5-19.5a12,12,0,0,0-17-17L172,171l-34-34A56,56,0,0,0,120,28H72A12,12,0,0,0,60,40V192a12,12,0,0,0,24,0V140h23l48,48-19.5,19.5a12,12,0,0,0,0,17,12.1,12.1,0,0,0,17,0L172,205l19.5,19.5a12.1,12.1,0,0,0,17,0,12,12,0,0,0,0-17ZM84,52h36a32,32,0,0,1,0,64H84Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M164,84a44,44,0,0,1-44,44H72V40h48A44,44,0,0,1,164,84Z"
            opacity="0.2"
          />
          <path d="M183.3,188l22.4-22.3a8.1,8.1,0,0,0-11.4-11.4L172,176.7,130.3,135A52,52,0,0,0,120,32H72a8,8,0,0,0-8,8V192a8,8,0,0,0,16,0V136h28.7l52,52-22.4,22.3a8.1,8.1,0,0,0,0,11.4,8.2,8.2,0,0,0,11.4,0L172,199.3l22.3,22.4a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4ZM80,48h40a36,36,0,0,1,0,72H80Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M183.3,188l22.4-22.3a8.1,8.1,0,0,0-11.4-11.4L172,176.7,130.3,135A52,52,0,0,0,120,32H72a8,8,0,0,0-8,8V192a8,8,0,0,0,16,0V136h28.7l52,52-22.4,22.3a8.1,8.1,0,0,0,0,11.4,8.2,8.2,0,0,0,11.4,0L172,199.3l22.3,22.4a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4ZM80,120V48h40a36,36,0,0,1,0,72Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M180.5,188l23.7-23.8a5.9,5.9,0,0,0-8.4-8.4L172,179.5l-45.9-45.9A50,50,0,0,0,120,34H72a6,6,0,0,0-6,6V192a6,6,0,0,0,12,0V134h31.5l54,54-23.7,23.8a5.9,5.9,0,1,0,8.4,8.4L172,196.5l23.8,23.7a5.9,5.9,0,0,0,8.4-8.4ZM78,46h42a38,38,0,0,1,0,76H78Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M177.7,188l25.1-25.2a4,4,0,0,0-5.6-5.6L172,182.3,121.6,132A48,48,0,0,0,120,36H72a4,4,0,0,0-4,4V192a4,4,0,0,0,8,0V132h34.3l56,56-25.1,25.2a3.9,3.9,0,0,0,0,5.6,3.9,3.9,0,0,0,5.6,0L172,193.7l25.2,25.1a3.9,3.9,0,0,0,5.6,0,3.9,3.9,0,0,0,0-5.6ZM76,44h44a40,40,0,0,1,0,80H76Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M183.3,188l22.4-22.3a8.1,8.1,0,0,0-11.4-11.4L172,176.7,130.3,135A52,52,0,0,0,120,32H72a8,8,0,0,0-8,8V192a8,8,0,0,0,16,0V136h28.7l52,52-22.4,22.3a8.1,8.1,0,0,0,0,11.4,8.2,8.2,0,0,0,11.4,0L172,199.3l22.3,22.4a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4ZM80,48h40a36,36,0,0,1,0,72H80Z" />
        </>
      )}
    </svg>
  );
}

export function PhPresentationChart(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216,36H140V24a12,12,0,0,0-24,0V36H40A20.1,20.1,0,0,0,20,56V176a20.1,20.1,0,0,0,20,20H71L54.6,216.5a12.1,12.1,0,0,0,1.9,16.9A12.4,12.4,0,0,0,64,236a12,12,0,0,0,9.4-4.5L101.8,196h52.4l28.4,35.5A12,12,0,0,0,192,236a12.4,12.4,0,0,0,7.5-2.6,12.1,12.1,0,0,0,1.9-16.9L185,196h31a20.1,20.1,0,0,0,20-20V56A20.1,20.1,0,0,0,216,36Zm-4,136H44V60H212ZM104,120v24a12,12,0,0,1-24,0V120a12,12,0,0,1,24,0Zm24-28a12,12,0,0,1,12,12v40a12,12,0,0,1-24,0V104A12,12,0,0,1,128,92Zm24,52V88a12,12,0,0,1,24,0v56a12,12,0,0,1-24,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M224,56V176a8,8,0,0,1-8,8H40a8,8,0,0,1-8-8V56a8,8,0,0,1,8-8H216A8,8,0,0,1,224,56Z"
            opacity="0.2"
          />
          <path d="M216,40H136V24a8,8,0,0,0-16,0V40H40A16,16,0,0,0,24,56V176a16,16,0,0,0,16,16H79.4L57.8,219A8,8,0,0,0,64,232a7.8,7.8,0,0,0,6.2-3l29.6-37h56.4l29.6,37a7.8,7.8,0,0,0,6.2,3,8,8,0,0,0,6.2-13l-21.6-27H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40Zm0,136H40V56H216V176ZM104,120v24a8,8,0,0,1-16,0V120a8,8,0,0,1,16,0Zm32-16v40a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Zm32-16v56a8,8,0,0,1-16,0V88a8,8,0,0,1,16,0Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,40H136V24a8,8,0,0,0-16,0V40H40A16,16,0,0,0,24,56V176a16,16,0,0,0,16,16H79.4L57.8,219A8,8,0,0,0,64,232a7.8,7.8,0,0,0,6.2-3l29.6-37h56.4l29.6,37a7.8,7.8,0,0,0,6.2,3,8,8,0,0,0,6.2-13l-21.6-27H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40ZM104,144a8,8,0,0,1-16,0V120a8,8,0,0,1,16,0Zm32,0a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Zm32,0a8,8,0,0,1-16,0V88a8,8,0,0,1,16,0Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M216,42H134V24a6,6,0,0,0-12,0V42H40A14,14,0,0,0,26,56V176a14,14,0,0,0,14,14H83.5L59.3,220.3a5.9,5.9,0,0,0,1,8.4A5.8,5.8,0,0,0,64,230a6,6,0,0,0,4.7-2.3L98.9,190h58.2l30.2,37.7A6,6,0,0,0,192,230a5.8,5.8,0,0,0,3.7-1.3,5.9,5.9,0,0,0,1-8.4L172.5,190H216a14,14,0,0,0,14-14V56A14,14,0,0,0,216,42Zm2,134a2,2,0,0,1-2,2H40a2,2,0,0,1-2-2V56a2,2,0,0,1,2-2H216a2,2,0,0,1,2,2ZM102,120v24a6,6,0,0,1-12,0V120a6,6,0,0,1,12,0Zm32-16v40a6,6,0,0,1-12,0V104a6,6,0,0,1,12,0Zm32-16v56a6,6,0,0,1-12,0V88a6,6,0,0,1,12,0Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M216,44H132V24a4,4,0,0,0-8,0V44H40A12,12,0,0,0,28,56V176a12,12,0,0,0,12,12H87.7L60.9,221.5a4,4,0,0,0,.6,5.6,3.8,3.8,0,0,0,2.5.9,3.9,3.9,0,0,0,3.1-1.5L97.9,188h60.2l30.8,38.5A3.9,3.9,0,0,0,192,228a3.8,3.8,0,0,0,2.5-.9,4,4,0,0,0,.6-5.6L168.3,188H216a12,12,0,0,0,12-12V56A12,12,0,0,0,216,44Zm4,132a4,4,0,0,1-4,4H40a4,4,0,0,1-4-4V56a4,4,0,0,1,4-4H216a4,4,0,0,1,4,4ZM100,120v24a4,4,0,0,1-8,0V120a4,4,0,0,1,8,0Zm32-16v40a4,4,0,0,1-8,0V104a4,4,0,0,1,8,0Zm32-16v56a4,4,0,0,1-8,0V88a4,4,0,0,1,8,0Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,40H136V24a8,8,0,0,0-16,0V40H40A16,16,0,0,0,24,56V176a16,16,0,0,0,16,16H79.4L57.8,219A8,8,0,0,0,64,232a7.8,7.8,0,0,0,6.2-3l29.6-37h56.4l29.6,37a7.8,7.8,0,0,0,6.2,3,8,8,0,0,0,6.2-13l-21.6-27H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40Zm0,136H40V56H216V176ZM104,120v24a8,8,0,0,1-16,0V120a8,8,0,0,1,16,0Zm32-16v40a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Zm32-16v56a8,8,0,0,1-16,0V88a8,8,0,0,1,16,0Z" />
        </>
      )}
    </svg>
  );
}

export function PhPresentation(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216,36H140V24a12,12,0,0,0-24,0V36H40A20.1,20.1,0,0,0,20,56V176a20.1,20.1,0,0,0,20,20H71L54.6,216.5a12.1,12.1,0,0,0,1.9,16.9A12.4,12.4,0,0,0,64,236a12,12,0,0,0,9.4-4.5L101.8,196h52.4l28.4,35.5A12,12,0,0,0,192,236a12.4,12.4,0,0,0,7.5-2.6,12.1,12.1,0,0,0,1.9-16.9L185,196h31a20.1,20.1,0,0,0,20-20V56A20.1,20.1,0,0,0,216,36Zm-4,136H44V60H212Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M224,56V176a8,8,0,0,1-8,8H40a8,8,0,0,1-8-8V56a8,8,0,0,1,8-8H216A8,8,0,0,1,224,56Z"
            opacity="0.2"
          />
          <path d="M216,40H136V24a8,8,0,0,0-16,0V40H40A16,16,0,0,0,24,56V176a16,16,0,0,0,16,16H79.4L57.8,219A8,8,0,0,0,64,232a7.8,7.8,0,0,0,6.2-3l29.6-37h56.4l29.6,37a7.8,7.8,0,0,0,6.2,3,8,8,0,0,0,6.2-13l-21.6-27H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40Zm0,136H40V56H216V176Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M232,56V176a16,16,0,0,1-16,16H176.6l21.6,27a8,8,0,0,1-6.2,13,7.8,7.8,0,0,1-6.2-3l-29.6-37H99.8L70.2,229a7.8,7.8,0,0,1-6.2,3,8,8,0,0,1-6.2-13l21.6-27H40a16,16,0,0,1-16-16V56A16,16,0,0,1,40,40h80V24a8,8,0,0,1,16,0V40h80A16,16,0,0,1,232,56Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M216,42H134V24a6,6,0,0,0-12,0V42H40A14,14,0,0,0,26,56V176a14,14,0,0,0,14,14H83.5L59.3,220.3a5.9,5.9,0,0,0,1,8.4A5.8,5.8,0,0,0,64,230a6,6,0,0,0,4.7-2.3L98.9,190h58.2l30.2,37.7A6,6,0,0,0,192,230a5.8,5.8,0,0,0,3.7-1.3,5.9,5.9,0,0,0,1-8.4L172.5,190H216a14,14,0,0,0,14-14V56A14,14,0,0,0,216,42Zm2,134a2,2,0,0,1-2,2H40a2,2,0,0,1-2-2V56a2,2,0,0,1,2-2H216a2,2,0,0,1,2,2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M216,44H132V24a4,4,0,0,0-8,0V44H40A12,12,0,0,0,28,56V176a12,12,0,0,0,12,12H87.7L60.9,221.5a4,4,0,0,0,.6,5.6,3.8,3.8,0,0,0,2.5.9,3.9,3.9,0,0,0,3.1-1.5L97.9,188h60.2l30.8,38.5A3.9,3.9,0,0,0,192,228a3.8,3.8,0,0,0,2.5-.9,4,4,0,0,0,.6-5.6L168.3,188H216a12,12,0,0,0,12-12V56A12,12,0,0,0,216,44Zm4,132a4,4,0,0,1-4,4H40a4,4,0,0,1-4-4V56a4,4,0,0,1,4-4H216a4,4,0,0,1,4,4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,40H136V24a8,8,0,0,0-16,0V40H40A16,16,0,0,0,24,56V176a16,16,0,0,0,16,16H79.4L57.8,219A8,8,0,0,0,64,232a7.8,7.8,0,0,0,6.2-3l29.6-37h56.4l29.6,37a7.8,7.8,0,0,0,6.2,3,8,8,0,0,0,6.2-13l-21.6-27H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40Zm0,136H40V56H216V176Z" />
        </>
      )}
    </svg>
  );
}

export function PhPrinter(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M210.7,68H204V40a12,12,0,0,0-12-12H64A12,12,0,0,0,52,40V68H45.3C29.2,68,16,80.6,16,96v80a12,12,0,0,0,12,12H52v32a12,12,0,0,0,12,12H192a12,12,0,0,0,12-12V188h24a12,12,0,0,0,12-12V96C240,80.6,226.8,68,210.7,68ZM76,52H180V68H76ZM180,208H76V168H180Zm36-44H204v-8a12,12,0,0,0-12-12H64a12,12,0,0,0-12,12v8H40V96c0-2.2,2.4-4,5.3-4H210.7c2.9,0,5.3,1.8,5.3,4Zm-12-48a16,16,0,1,1-16-16A16,16,0,0,1,204,116Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M210.7,80H45.3C35.8,80,28,87.2,28,96v80H64V152H192v24h36V96C228,87.2,220.2,80,210.7,80Z" />
          </g>
          <circle cx="188" cy="116" r="12" />
          <path d="M210.7,72H200V40a8,8,0,0,0-8-8H64a8,8,0,0,0-8,8V72H45.3C31.4,72,20,82.8,20,96v80a8,8,0,0,0,8,8H56v36a8,8,0,0,0,8,8H192a8,8,0,0,0,8-8V184h28a8,8,0,0,0,8-8V96C236,82.8,224.6,72,210.7,72ZM72,48H184V72H72ZM184,212H72V160H184Zm36-44H200V152a8,8,0,0,0-8-8H64a8,8,0,0,0-8,8v16H36V96c0-4.4,4.2-8,9.3-8H210.7c5.1,0,9.3,3.6,9.3,8Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M210.7,72H200V40a8,8,0,0,0-8-8H64a8,8,0,0,0-8,8V72H45.3C31.4,72,20,82.8,20,96v80a8,8,0,0,0,8,8H56v36a8,8,0,0,0,8,8H192a8,8,0,0,0,8-8V184h28a8,8,0,0,0,8-8V96C236,82.8,224.6,72,210.7,72ZM72,48H184V72H72ZM184,212H72V160H184Zm4-84a12,12,0,1,1,12-12A12,12,0,0,1,188,128Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M210.7,74H198V40a6,6,0,0,0-6-6H64a6,6,0,0,0-6,6V74H45.3C32.5,74,22,83.9,22,96v80a6,6,0,0,0,6,6H58v38a6,6,0,0,0,6,6H192a6,6,0,0,0,6-6V182h30a6,6,0,0,0,6-6V96C234,83.9,223.5,74,210.7,74ZM70,46H186V74H70ZM186,214H70V158H186Zm36-44H198V152a6,6,0,0,0-6-6H64a6,6,0,0,0-6,6v18H34V96c0-5.5,5.1-10,11.3-10H210.7c6.2,0,11.3,4.5,11.3,10Zm-24-54a10,10,0,1,1-10-10A10,10,0,0,1,198,116Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M210.7,76H196V40a4,4,0,0,0-4-4H64a4,4,0,0,0-4,4V76H45.3C33.6,76,24,85,24,96v80a4,4,0,0,0,4,4H60v40a4,4,0,0,0,4,4H192a4,4,0,0,0,4-4V180h32a4,4,0,0,0,4-4V96C232,85,222.4,76,210.7,76ZM68,44H188V76H68ZM188,216H68V156H188Zm36-44H196V152a4,4,0,0,0-4-4H64a4,4,0,0,0-4,4v20H32V96c0-6.6,6-12,13.3-12H210.7C218,84,224,89.4,224,96Zm-28-56a8,8,0,1,1-8-8A8,8,0,0,1,196,116Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M210.7,72H200V40a8,8,0,0,0-8-8H64a8,8,0,0,0-8,8V72H45.3C31.4,72,20,82.8,20,96v80a8,8,0,0,0,8,8H56v36a8,8,0,0,0,8,8H192a8,8,0,0,0,8-8V184h28a8,8,0,0,0,8-8V96C236,82.8,224.6,72,210.7,72ZM72,48H184V72H72ZM184,212H72V160H184Zm36-44H200V152a8,8,0,0,0-8-8H64a8,8,0,0,0-8,8v16H36V96c0-4.4,4.2-8,9.3-8H210.7c5.1,0,9.3,3.6,9.3,8Zm-20-52a12,12,0,1,1-12-12A12,12,0,0,1,200,116Z" />
        </>
      )}
    </svg>
  );
}

export function PhProhibitInset(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,20A108,108,0,1,0,236,128,108.1,108.1,0,0,0,128,20Zm0,192a84,84,0,1,1,84-84A84.1,84.1,0,0,1,128,212Zm36.8-64.2a12,12,0,0,1,0,17,12.2,12.2,0,0,1-8.5,3.5,12,12,0,0,1-8.5-3.5L91.2,108.2a12,12,0,0,1,17-17Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="128" cy="128" r="96" />
          </g>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Z" />
          <path d="M105.4,94.1a8,8,0,0,0-11.3,11.3l56.5,56.5a8,8,0,0,0,5.7,2.4,7.7,7.7,0,0,0,5.6-2.4,7.9,7.9,0,0,0,0-11.3Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm22.6,137.9L94.1,105.4a8,8,0,0,1,11.3-11.3l56.5,56.5a8,8,0,0,1-11.3,11.3Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,26A102,102,0,1,0,230,128,102.2,102.2,0,0,0,128,26Zm0,192a90,90,0,1,1,90-90A90.1,90.1,0,0,1,128,218Zm32.5-66a5.9,5.9,0,0,1,0,8.5,5.8,5.8,0,0,1-4.2,1.8,6,6,0,0,1-4.3-1.8L95.5,104a6,6,0,0,1,8.5-8.5Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,28A100,100,0,1,0,228,128,100.2,100.2,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm31.1-66.5a3.9,3.9,0,0,1,0,5.6,3.9,3.9,0,0,1-2.8,1.2,3.8,3.8,0,0,1-2.8-1.2L96.9,102.5a4,4,0,0,1,5.6-5.6Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm33.9-65.4a7.9,7.9,0,0,1,0,11.3,7.7,7.7,0,0,1-5.6,2.4,8,8,0,0,1-5.7-2.4L94.1,105.4a8,8,0,0,1,11.3-11.3Z" />
        </>
      )}
    </svg>
  );
}

export function PhProhibit(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M205.2,203.4A107.9,107.9,0,0,0,52.6,50.8a3.9,3.9,0,0,0-1,.8l-.8,1A107.9,107.9,0,0,0,203.4,205.2l1-.8ZM212,128a83.4,83.4,0,0,1-16.8,50.3L77.7,60.7A84,84,0,0,1,212,128ZM44,128A83.1,83.1,0,0,1,60.8,77.7L178.3,195.2A83.1,83.1,0,0,1,128,212,84.1,84.1,0,0,1,44,128Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="128" cy="128" r="96" />
          </g>
          <path d="M202.2,200.8a103.9,103.9,0,0,0-147-147,2.3,2.3,0,0,0-.7.7,2.3,2.3,0,0,0-.7.7,103.9,103.9,0,0,0,147,147,2.3,2.3,0,0,0,.7-.7A2.3,2.3,0,0,0,202.2,200.8ZM216,128a87.9,87.9,0,0,1-20.4,56.3L71.7,60.4A88,88,0,0,1,216,128ZM40,128A87.9,87.9,0,0,1,60.4,71.7L184.3,195.6A88,88,0,0,1,40,128Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M200,128a71.3,71.3,0,0,1-15.8,44.9L83.1,71.8A71.3,71.3,0,0,1,128,56,72.1,72.1,0,0,1,200,128ZM56,128a72.1,72.1,0,0,0,72,72,71.3,71.3,0,0,0,44.9-15.8L71.8,83.1A71.3,71.3,0,0,0,56,128Zm180,0A108,108,0,1,1,128,20,108.1,108.1,0,0,1,236,128Zm-20,0a88,88,0,1,0-88,88A88.1,88.1,0,0,0,216,128Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M200.5,199.7A102,102,0,0,0,56.3,55.5l-.4.4-.4.4A102,102,0,0,0,199.7,200.5l.4-.4C200.3,200,200.3,199.8,200.5,199.7ZM218,128a89.3,89.3,0,0,1-22.3,59.2L68.8,60.3A89.9,89.9,0,0,1,218,128ZM38,128A89.3,89.3,0,0,1,60.3,68.8L187.2,195.7A89.9,89.9,0,0,1,38,128Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M199,198.3A100,100,0,0,0,57.7,56.9c-.1.2-.3.3-.4.4l-.3.4A99.9,99.9,0,0,0,198.3,199l.4-.3ZM220,128a91.8,91.8,0,0,1-24.2,62.1L65.9,60.2A91.9,91.9,0,0,1,220,128ZM36,128A91.6,91.6,0,0,1,60.2,65.9L190.1,195.8A91.9,91.9,0,0,1,36,128Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M202.2,200.8a103.9,103.9,0,0,0-147-147,2.3,2.3,0,0,0-.7.7,2.3,2.3,0,0,0-.7.7,103.9,103.9,0,0,0,147,147,2.3,2.3,0,0,0,.7-.7A2.3,2.3,0,0,0,202.2,200.8ZM216,128a87.9,87.9,0,0,1-20.4,56.3L71.7,60.4A88,88,0,0,1,216,128ZM40,128A87.9,87.9,0,0,1,60.4,71.7L184.3,195.6A88,88,0,0,1,40,128Z" />
        </>
      )}
    </svg>
  );
}

export function PhProjectorScreenChart(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M104,128v8a12,12,0,0,1-24,0v-8a12,12,0,0,1,24,0Zm24-16a12,12,0,0,0-12,12v12a12,12,0,0,0,24,0V124A12,12,0,0,0,128,112Zm36-4a12,12,0,0,0-12,12v16a12,12,0,0,0,24,0V120A12,12,0,0,0,164,108Zm56-16.4V164h4a12,12,0,0,1,0,24H140v23.2a24,24,0,1,1-24,0V188H32a12,12,0,0,1,0-24h4V91.6A20.1,20.1,0,0,1,20,72V48A20.1,20.1,0,0,1,40,28H216a20.1,20.1,0,0,1,20,20V72A20.1,20.1,0,0,1,220,91.6ZM44,68H212V52H44Zm152,96V92H60v72Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M208,72V184H48V72Z" opacity="0.2" />
          <path d="M88,144V128a8,8,0,0,1,16,0v16a8,8,0,0,1-16,0Zm40,8a8,8,0,0,0,8-8V120a8,8,0,0,0-16,0v24A8,8,0,0,0,128,152Zm32,0a8,8,0,0,0,8-8V112a8,8,0,0,0-16,0v32A8,8,0,0,0,160,152Zm56-72v96h8a8,8,0,0,1,0,16H136v17.4a24,24,0,1,1-16,0V192H32a8,8,0,0,1,0-16h8V80A16,16,0,0,1,24,64V48A16,16,0,0,1,40,32H216a16,16,0,0,1,16,16V64A16,16,0,0,1,216,80ZM136,232a8,8,0,1,0-8,8A8,8,0,0,0,136,232ZM40,64H216V48H40ZM200,80H56v96H200Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M232,64V48a16,16,0,0,0-16-16H40A16,16,0,0,0,24,48V64A16,16,0,0,0,40,80v96H32a8,8,0,0,0,0,16h88v17.4a24,24,0,1,0,16,0V192h88a8,8,0,0,0,0-16h-8V80A16,16,0,0,0,232,64ZM104,144a8,8,0,0,1-16,0V128a8,8,0,0,1,16,0Zm24,96a8,8,0,1,1,8-8A8,8,0,0,1,128,240Zm8-96a8,8,0,0,1-16,0V120a8,8,0,0,1,16,0Zm32,0a8,8,0,0,1-16,0V112a8,8,0,0,1,16,0ZM40,64V48H216V64H40Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M90,144V128a6,6,0,0,1,12,0v16a6,6,0,0,1-12,0Zm38,6a6,6,0,0,0,6-6V120a6,6,0,0,0-12,0v24A6,6,0,0,0,128,150Zm32,0a6,6,0,0,0,6-6V112a6,6,0,0,0-12,0v32A6,6,0,0,0,160,150Zm54-72V178h10a6,6,0,0,1,0,12H134v20.8a22,22,0,1,1-12,0V190H32a6,6,0,0,1,0-12H42V78H40A14,14,0,0,1,26,64V48A14,14,0,0,1,40,34H216a14,14,0,0,1,14,14V64a14,14,0,0,1-14,14ZM128,222a10,10,0,1,0,10,10A10,10,0,0,0,128,222ZM40,66H216a2,2,0,0,0,2-2V48a2,2,0,0,0-2-2H40a2,2,0,0,0-2,2V64A2,2,0,0,0,40,66ZM202,78H54V178H202Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M92,144V128a4,4,0,0,1,8,0v16a4,4,0,0,1-8,0Zm36,4a4,4,0,0,0,4-4V120a4,4,0,0,0-8,0v24A4,4,0,0,0,128,148Zm32,0a4,4,0,0,0,4-4V112a4,4,0,0,0-8,0v32A4,4,0,0,0,160,148Zm52-72V180h12a4,4,0,0,1,0,8H132v24.4a20,20,0,1,1-8,0V188H32a4,4,0,0,1,0-8H44V76H40A12,12,0,0,1,28,64V48A12,12,0,0,1,40,36H216a12,12,0,0,1,12,12V64a12,12,0,0,1-12,12ZM128,220a12,12,0,1,0,12,12A12,12,0,0,0,128,220ZM204,76H52V180H204Zm12-8a4,4,0,0,0,4-4V48a4,4,0,0,0-4-4H40a4,4,0,0,0-4,4V64a4,4,0,0,0,4,4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M88,144V128a8,8,0,0,1,16,0v16a8,8,0,0,1-16,0Zm40,8a8,8,0,0,0,8-8V120a8,8,0,0,0-16,0v24A8,8,0,0,0,128,152Zm32,0a8,8,0,0,0,8-8V112a8,8,0,0,0-16,0v32A8,8,0,0,0,160,152Zm56-72v96h8a8,8,0,0,1,0,16H136v17.4a24,24,0,1,1-16,0V192H32a8,8,0,0,1,0-16h8V80A16,16,0,0,1,24,64V48A16,16,0,0,1,40,32H216a16,16,0,0,1,16,16V64A16,16,0,0,1,216,80ZM136,232a8,8,0,1,0-8,8A8,8,0,0,0,136,232ZM40,64H216V48H40ZM200,80H56v96H200Z" />
        </>
      )}
    </svg>
  );
}

export function PhProjectorScreen(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M236,72V48a20.1,20.1,0,0,0-20-20H40A20.1,20.1,0,0,0,20,48V72A20.1,20.1,0,0,0,36,91.6V164H32a12,12,0,0,0,0,24h84v23.2a24,24,0,1,0,24,0V188h84a12,12,0,0,0,0-24h-4V91.6A20.1,20.1,0,0,0,236,72ZM44,52H212V68H44ZM60,164V92H196v72Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M208,72V184H48V72Z" opacity="0.2" />
          <path d="M232,64V48a16,16,0,0,0-16-16H40A16,16,0,0,0,24,48V64A16,16,0,0,0,40,80v96H32a8,8,0,0,0,0,16h88v17.4a24,24,0,1,0,16,0V192h88a8,8,0,0,0,0-16h-8V80A16,16,0,0,0,232,64ZM128,240a8,8,0,1,1,8-8A8,8,0,0,1,128,240ZM40,48H216V64H40ZM200,176H56V80H200Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M232,64V48a16,16,0,0,0-16-16H40A16,16,0,0,0,24,48V64A16,16,0,0,0,40,80v96H32a8,8,0,0,0,0,16h88v17.4a24,24,0,1,0,16,0V192h88a8,8,0,0,0,0-16h-8V80A16,16,0,0,0,232,64ZM128,240a8,8,0,1,1,8-8A8,8,0,0,1,128,240ZM40,64V48H216V64H40Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M216,78a14,14,0,0,0,14-14V48a14,14,0,0,0-14-14H40A14,14,0,0,0,26,48V64A14,14,0,0,0,40,78h2V178H32a6,6,0,0,0,0,12h90v20.8a22,22,0,1,0,12,0V190h90a6,6,0,0,0,0-12H214V78ZM138,232a10,10,0,1,1-10-10A10,10,0,0,1,138,232ZM38,64V48a2,2,0,0,1,2-2H216a2,2,0,0,1,2,2V64a2,2,0,0,1-2,2H40A2,2,0,0,1,38,64ZM202,178H54V78H202Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M216,76a12,12,0,0,0,12-12V48a12,12,0,0,0-12-12H40A12,12,0,0,0,28,48V64A12,12,0,0,0,40,76h4V180H32a4,4,0,0,0,0,8h92v24.4a20,20,0,1,0,8,0V188h92a4,4,0,0,0,0-8H212V76ZM140,232a12,12,0,1,1-12-12A12,12,0,0,1,140,232ZM36,64V48a4,4,0,0,1,4-4H216a4,4,0,0,1,4,4V64a4,4,0,0,1-4,4H40A4,4,0,0,1,36,64ZM204,180H52V76H204Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M232,64V48a16,16,0,0,0-16-16H40A16,16,0,0,0,24,48V64A16,16,0,0,0,40,80v96H32a8,8,0,0,0,0,16h88v17.4a24,24,0,1,0,16,0V192h88a8,8,0,0,0,0-16h-8V80A16,16,0,0,0,232,64ZM128,240a8,8,0,1,1,8-8A8,8,0,0,1,128,240ZM40,48H216V64H40ZM200,176H56V80H200Z" />
        </>
      )}
    </svg>
  );
}

export function PhPushPinSimpleSlash(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216.9,207.9l-160-176A12,12,0,0,0,39.1,48.1L62,73.2,45.9,164H40a12,12,0,0,0,0,24h76v52a12,12,0,0,0,24,0V188h26.3l32.8,36.1a12.1,12.1,0,0,0,17,.8A12.1,12.1,0,0,0,216.9,207.9ZM70.3,164,82.4,95.6,144.5,164ZM90.1,40a12,12,0,0,1,12-12H192a12,12,0,0,1,0,24h-1.7l15.3,86.8a11.9,11.9,0,0,1-9.7,13.9,7.5,7.5,0,0,1-2.1.2A12,12,0,0,1,182,143L165.9,52H102.1A12,12,0,0,1,90.1,40Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M200,176H56L80,40h96Z" opacity="0.2" />
          <path d="M83.2,40a8,8,0,0,1,8-8H192a8,8,0,0,1,0,16h-6.5l18.8,106.3a8,8,0,0,1-6.5,9.3h-1.4a8,8,0,0,1-7.9-6.6L169.3,48H91.2A8,8,0,0,1,83.2,40ZM213.4,221.9A8.2,8.2,0,0,1,208,224a8,8,0,0,1-5.9-2.6l-34-37.4H136v56a8,8,0,0,1-16,0V184H40a8,8,0,0,1,0-16h9.3L66.2,72,42.1,45.4A8,8,0,0,1,53.9,34.6l160,176A7.9,7.9,0,0,1,213.4,221.9ZM153.6,168,79.8,86.9,65.5,168Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M85.3,45.4a7.9,7.9,0,0,1-2-5.1,1.1,1.1,0,0,1,0-.8A8.3,8.3,0,0,1,85,35a9.4,9.4,0,0,1,1-1,8,8,0,0,1,5.2-2H192a8,8,0,0,1,0,16h-6.5l18.8,106.3a8.1,8.1,0,0,1-4.4,8.6,8.5,8.5,0,0,1-3.5.8,7.9,7.9,0,0,1-5.9-2.6ZM213.9,210.6l-36.3-40h0l-48.5-53.2L53.9,34.6A8,8,0,0,0,42.1,45.4L66.2,72,49.3,168H40a8,8,0,0,0,0,16h80v56a8,8,0,0,0,16,0V184h32.1l34,37.4A8,8,0,0,0,208,224a8.2,8.2,0,0,0,5.4-2.1A7.9,7.9,0,0,0,213.9,210.6Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M85.2,40a6,6,0,0,1,6-6H192a6,6,0,0,1,0,12h-8.8l19.1,108.6a6,6,0,0,1-4.8,7h-1.1a6.1,6.1,0,0,1-5.9-5L171,46H91.2A6,6,0,0,1,85.2,40ZM212,220.4a5.7,5.7,0,0,1-4,1.6,5.9,5.9,0,0,1-4.4-2L169,182H134v58a6,6,0,0,1-12,0V182H40a6,6,0,0,1,0-12H51L68.4,71.3,43.6,44a5.9,5.9,0,0,1,8.8-8l160,176A5.8,5.8,0,0,1,212,220.4ZM158.1,170,78.6,82.6,63.2,170Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M87.2,40a4,4,0,0,1,4-4H192a4,4,0,0,1,0,8H180.8l19.6,111a4,4,0,0,1-3.3,4.6h-.7a4,4,0,0,1-3.9-3.3L172.6,44H91.2A4,4,0,0,1,87.2,40ZM210.7,219a4.1,4.1,0,0,1-5.7-.3L169.9,180H132v60a4,4,0,0,1-8,0V180H40a4,4,0,0,1,0-8H52.6L70.5,70.7,45,42.7a4,4,0,1,1,6-5.4l160,176A4.2,4.2,0,0,1,210.7,219Zm-48.1-47L77.3,78.2,60.8,172Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M83.2,40a8,8,0,0,1,8-8H192a8,8,0,0,1,0,16h-6.5l18.8,106.3a8,8,0,0,1-6.5,9.3h-1.4a8,8,0,0,1-7.9-6.6L169.3,48H91.2A8,8,0,0,1,83.2,40ZM213.4,221.9A8.2,8.2,0,0,1,208,224a8,8,0,0,1-5.9-2.6l-34-37.4H136v56a8,8,0,0,1-16,0V184H40a8,8,0,0,1,0-16h9.3L66.2,72,42.1,45.4A8,8,0,0,1,53.9,34.6l160,176A7.9,7.9,0,0,1,213.4,221.9ZM153.6,168,79.8,86.9,65.5,168Z" />
        </>
      )}
    </svg>
  );
}

export function PhPushPinSimple(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216,164h-5.9L190.3,52H192a12,12,0,0,0,0-24H64a12,12,0,0,0,0,24h1.7L45.9,164H40a12,12,0,0,0,0,24h76v52a12,12,0,0,0,24,0V188h76a12,12,0,0,0,0-24ZM90.1,52h75.8l19.8,112H70.3Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M200,176H56L80,40h96Z" opacity="0.2" />
          <path d="M216,168h-9.3L185.5,48H192a8,8,0,0,0,0-16H64a8,8,0,0,0,0,16h6.5L49.3,168H40a8,8,0,0,0,0,16h80v56a8,8,0,0,0,16,0V184h80a8,8,0,0,0,0-16ZM86.7,48h82.6l21.2,120H65.5Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M224,176a8,8,0,0,1-8,8H136v56a8,8,0,0,1-16,0V184H40a8,8,0,0,1,0-16h9.3L70.5,48H64a8,8,0,0,1,0-16H192a8,8,0,0,1,0,16h-6.5l21.2,120H216A8,8,0,0,1,224,176Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M216,170H205L183.2,46H192a6,6,0,0,0,0-12H64a6,6,0,0,0,0,12h8.8L51,170H40a6,6,0,0,0,0,12h82v58a6,6,0,0,0,12,0V182h82a6,6,0,0,0,0-12ZM85,46h86l21.8,124H63.2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M216,172H203.4L180.8,44H192a4,4,0,0,0,0-8H64a4,4,0,0,0,0,8H75.2L52.6,172H40a4,4,0,0,0,0,8h84v60a4,4,0,0,0,8,0V180h84a4,4,0,0,0,0-8ZM83.4,44h89.2l22.6,128H60.8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,168h-9.3L185.5,48H192a8,8,0,0,0,0-16H64a8,8,0,0,0,0,16h6.5L49.3,168H40a8,8,0,0,0,0,16h80v56a8,8,0,0,0,16,0V184h80a8,8,0,0,0,0-16ZM86.7,48h82.6l21.2,120H65.5Z" />
        </>
      )}
    </svg>
  );
}

export function PhPushPinSlash(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M56.9,31.9A12,12,0,0,0,39.1,48.1L64.8,76.4c-11.2,1.2-24.3,5.5-37.7,16.3a19.8,19.8,0,0,0-1.6,29.7L71,168,39.5,199.5a12,12,0,0,0,0,17,12.1,12.1,0,0,0,17,0L88,185l45.4,45.3a19.7,19.7,0,0,0,14.1,5.9h1.4a19.8,19.8,0,0,0,14.6-7.9,91.7,91.7,0,0,0,14.3-27.6l21.3,23.5a12.1,12.1,0,0,0,17,.8,12.1,12.1,0,0,0,.8-17ZM147,210,45.7,108.7c21.1-14.6,39.5-6.6,41-5.9a12.8,12.8,0,0,0,3.1,1.1l66,72.5C158.6,189.1,152.5,201.8,147,210ZM240.7,96a19.7,19.7,0,0,1-5.9,14.1l-35.7,35.8a12.1,12.1,0,0,1-17,0,12,12,0,0,1,0-17L215,96,160,41,130.5,70.5a12,12,0,0,1-17-17l32.4-32.3a19.9,19.9,0,0,1,28.2,0l60.7,60.7A19.7,19.7,0,0,1,240.7,96Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M226.3,101.7,164,164c11.5,22.9-1.7,45.9-10.1,57a8,8,0,0,1-12.1.8L34,114a8,8,0,0,1,.6-11.9C64.3,78.1,92,92,92,92l62.3-62.3a8,8,0,0,1,11.4,0l60.6,60.6A8,8,0,0,1,226.3,101.7Z"
            opacity="0.2"
          />
          <path d="M53.9,34.6A8,8,0,0,0,42.1,45.4L73.5,80c-12-.3-27.7,2.8-43.9,15.8a15.9,15.9,0,0,0-1.3,23.8L76.7,168,42.3,202.3a8.1,8.1,0,0,0,0,11.4,8.2,8.2,0,0,0,11.4,0L88,179.3l48.2,48.2a16.1,16.1,0,0,0,11.3,4.7h1.1a16.3,16.3,0,0,0,11.7-6.4c5.5-7.3,12.9-19.3,15.4-33.5l26.4,29.1A8,8,0,0,0,208,224a8.2,8.2,0,0,0,5.4-2.1,7.9,7.9,0,0,0,.5-11.3Zm93.6,181.6L39.6,108.3c25.1-20.2,47.9-9.6,48.8-9.1a10,10,0,0,0,3.3.8l67.7,74.5C163.7,191.2,153.9,207.7,147.5,216.2ZM236.7,96a15.9,15.9,0,0,1-4.7,11.3l-41.4,41.4a8,8,0,0,1-5.7,2.4,7.7,7.7,0,0,1-5.6-2.4,8,8,0,0,1,0-11.3L220.7,96,160,35.3l-38,38A8,8,0,1,1,110.7,62l38-38a16.1,16.1,0,0,1,22.6,0L232,84.7A15.9,15.9,0,0,1,236.7,96Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M213.9,210.6a7.9,7.9,0,0,1-.5,11.3A8.2,8.2,0,0,1,208,224a8,8,0,0,1-5.9-2.6l-26.4-29.1c-2.5,14.2-9.9,26.2-15.4,33.5a16.3,16.3,0,0,1-11.7,6.4h-1.1a16.1,16.1,0,0,1-11.3-4.7L88,179.3,53.7,213.7a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L76.7,168,28.3,119.6a15.9,15.9,0,0,1,1.3-23.8C45.8,82.8,61.5,79.7,73.5,80L42.1,45.4A8,8,0,0,1,53.9,34.6ZM232,84.7,171.3,24a16.1,16.1,0,0,0-22.6,0l-38,38a8,8,0,0,0-.3,11L179,148.4a8.1,8.1,0,0,0,5.7,2.7h.2a8,8,0,0,0,5.7-2.4L232,107.3a15.9,15.9,0,0,0,0-22.6Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M52.4,36a5.9,5.9,0,0,0-8.8,8L78.3,82.3c-12-1.3-29.4.5-47.5,15.1a14,14,0,0,0-1.1,20.8L79.5,168,43.8,203.8a5.9,5.9,0,1,0,8.4,8.4L88,176.5l49.6,49.6a14.1,14.1,0,0,0,9.9,4.1h1a13.9,13.9,0,0,0,10.2-5.6c5.9-7.8,14.1-21.2,15.6-36.8L203.6,220a5.9,5.9,0,0,0,4.4,2,5.7,5.7,0,0,0,4-1.6,5.8,5.8,0,0,0,.4-8.4Zm96.7,181.4a2,2,0,0,1-1.4.8,2,2,0,0,1-1.6-.6L38.2,109.7a1.8,1.8,0,0,1-.6-1.5,2.2,2.2,0,0,1,.8-1.5c26.1-21,49.9-9.8,50.9-9.3a6.5,6.5,0,0,0,3.3.6l68.6,75.5C166,191.2,155.8,208.5,149.1,217.4ZM234.7,96a14.1,14.1,0,0,1-4.1,9.9l-41.4,41.4a5.9,5.9,0,0,1-8.5,0,6.1,6.1,0,0,1,0-8.5l41.4-41.4a1.9,1.9,0,0,0,0-2.8L161.4,33.9a1.9,1.9,0,0,0-2.8,0l-38,38a6,6,0,1,1-8.5-8.5l38-38a14.1,14.1,0,0,1,19.8,0l60.7,60.7A14.1,14.1,0,0,1,234.7,96Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M51,37.3a4,4,0,1,0-6,5.4L83.6,85.1c-11.4-2.3-31-2.7-51.5,13.8a12.3,12.3,0,0,0-4.5,8.8,11.9,11.9,0,0,0,3.5,9.1L82.3,168,45.2,205.2a3.9,3.9,0,0,0,0,5.6,3.9,3.9,0,0,0,5.6,0L88,173.7l51,51a12.3,12.3,0,0,0,8.5,3.5h.9a12.2,12.2,0,0,0,8.7-4.8c6.3-8.4,15.4-23.5,15.4-40.5L205,218.7a4.1,4.1,0,0,0,5.7.3,4.2,4.2,0,0,0,.3-5.7Zm99.7,181.3a4.1,4.1,0,0,1-2.9,1.6,3.6,3.6,0,0,1-3.1-1.2L36.8,111.1a4.2,4.2,0,0,1-1.2-3,4,4,0,0,1,1.5-2.9c27.2-22,52.1-10.1,53.1-9.6a4.2,4.2,0,0,0,3.1.2L163,172.5C168.4,191.2,157.6,209.4,150.7,218.6ZM232.7,96a12.3,12.3,0,0,1-3.5,8.5l-41.4,41.4a4,4,0,0,1-2.9,1.2,4.1,4.1,0,0,1-2.8-6.9l41.4-41.4a3.9,3.9,0,0,0,0-5.6L162.8,32.5a3.9,3.9,0,0,0-5.6,0l-38,38a4,4,0,1,1-5.7-5.7l38-38a12.2,12.2,0,0,1,17,0l60.7,60.7A12.3,12.3,0,0,1,232.7,96Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M53.9,34.6A8,8,0,0,0,42.1,45.4L73.5,80c-12-.3-27.7,2.8-43.9,15.8a15.9,15.9,0,0,0-1.3,23.8L76.7,168,42.3,202.3a8.1,8.1,0,0,0,0,11.4,8.2,8.2,0,0,0,11.4,0L88,179.3l48.2,48.2a16.1,16.1,0,0,0,11.3,4.7h1.1a16.3,16.3,0,0,0,11.7-6.4c5.5-7.3,12.9-19.3,15.4-33.5l26.4,29.1A8,8,0,0,0,208,224a8.2,8.2,0,0,0,5.4-2.1,7.9,7.9,0,0,0,.5-11.3Zm93.6,181.6L39.6,108.3c25.1-20.2,47.9-9.6,48.8-9.1a10,10,0,0,0,3.3.8l67.7,74.5C163.7,191.2,153.9,207.7,147.5,216.2ZM236.7,96a15.9,15.9,0,0,1-4.7,11.3l-41.4,41.4a8,8,0,0,1-5.7,2.4,7.7,7.7,0,0,1-5.6-2.4,8,8,0,0,1,0-11.3L220.7,96,160,35.3l-38,38A8,8,0,1,1,110.7,62l38-38a16.1,16.1,0,0,1,22.6,0L232,84.7A15.9,15.9,0,0,1,236.7,96Z" />
        </>
      )}
    </svg>
  );
}

export function PhPushPin(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M240.7,96a19.7,19.7,0,0,0-5.9-14.1L174.1,21.2a19.9,19.9,0,0,0-28.2,0h0l-57.1,57c-12.4-3.3-36.7-5.7-61.7,14.5a19.8,19.8,0,0,0-1.6,29.7L71,168,39.5,199.5a12,12,0,0,0,0,17,12.1,12.1,0,0,0,17,0L88,185l45.4,45.3a19.7,19.7,0,0,0,14.1,5.9h1.4a19.8,19.8,0,0,0,14.6-7.9,88,88,0,0,0,14.4-27.9c3.4-11.7,3.5-22.9.2-33.4l56.7-56.8A19.7,19.7,0,0,0,240.7,96Zm-85.2,59.5a12.2,12.2,0,0,0-2.2,13.9c3.4,6.8,6.9,20.9-6.3,40.6L96.5,159.5h0L45.7,108.7c21.1-14.6,39.5-6.6,41-5.9a11.7,11.7,0,0,0,13.8-2.3L160,41l55,55Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M154.3,29.7,92,92S64.3,78.1,34.6,102.1A8,8,0,0,0,34,114L141.8,221.8a8,8,0,0,0,12.1-.8c8.4-11.1,21.6-34.1,10.1-57l62.3-62.3a8,8,0,0,0,0-11.4L165.7,29.7A8,8,0,0,0,154.3,29.7Z" />
          </g>
          <path d="M236.7,96A15.9,15.9,0,0,0,232,84.7L171.3,24a16.1,16.1,0,0,0-22.6,0h0L90,82.7c-10.7-3.3-35-7.4-60.4,13.1a15.9,15.9,0,0,0-1.3,23.8L76.7,168,42.3,202.3a8.1,8.1,0,0,0,0,11.4,8.2,8.2,0,0,0,11.4,0L88,179.3l48.2,48.2a16.1,16.1,0,0,0,11.3,4.7h1.1a16.3,16.3,0,0,0,11.7-6.4c19.6-26.1,17.7-47.3,13.2-60L232,107.3A15.9,15.9,0,0,0,236.7,96Zm-78.4,62.3a8.2,8.2,0,0,0-1.5,9.3c9.5,18.9-1.8,38.6-9.3,48.6L39.6,108.3C51.7,98.5,63.3,96,72.1,96S88,98.9,88.4,99.2a8.2,8.2,0,0,0,9.3-1.5L160,35.3,220.7,96Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M232,107.3l-58.5,58.5c4.5,12.7,6.4,33.9-13.2,60a16.3,16.3,0,0,1-11.7,6.4h-1.1a16.1,16.1,0,0,1-11.3-4.7L88,179.3,53.7,213.7a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L76.7,168,28.3,119.6a15.9,15.9,0,0,1,1.3-23.8C55,75.3,79.3,79.4,90,82.7L148.7,24h0a16.1,16.1,0,0,1,22.6,0L232,84.7a15.9,15.9,0,0,1,0,22.6Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M234.7,96a14.1,14.1,0,0,0-4.1-9.9L169.9,25.4a14.1,14.1,0,0,0-19.8,0h0L90.5,85C81,81.7,56.6,76.6,30.8,97.4a14,14,0,0,0-1.1,20.8L79.5,168,43.8,203.8a5.9,5.9,0,1,0,8.4,8.4L88,176.5l49.6,49.6a14.1,14.1,0,0,0,9.9,4.1h1a13.9,13.9,0,0,0,10.2-5.6c19.7-26.2,17.1-47.2,12.4-59.3l59.5-59.4A14.1,14.1,0,0,0,234.7,96Zm-12.6,1.4-62.3,62.4a5.9,5.9,0,0,0-1.2,6.9c10,19.9-1.7,40.3-9.5,50.7a2,2,0,0,1-1.4.8,2,2,0,0,1-1.6-.6L38.2,109.7a1.8,1.8,0,0,1-.6-1.5,2.2,2.2,0,0,1,.8-1.5C50.9,96.6,62.9,94,72.1,94s16.7,3.1,17.2,3.4a5.9,5.9,0,0,0,6.9-1.2l62.4-62.3a1.9,1.9,0,0,1,2.8,0l60.7,60.7a1.9,1.9,0,0,1,0,2.8Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M232.7,96a12.3,12.3,0,0,0-3.5-8.5L168.5,26.8a12.2,12.2,0,0,0-17,0h0L91.1,87.3c-8.1-3.1-32.8-9.5-59,11.6a12.3,12.3,0,0,0-4.5,8.8,11.9,11.9,0,0,0,3.5,9.1L82.3,168,45.2,205.2a3.9,3.9,0,0,0,0,5.6,3.9,3.9,0,0,0,5.6,0L88,173.7l51,51a12.3,12.3,0,0,0,8.5,3.5h.9a12.2,12.2,0,0,0,8.7-4.8c19.8-26.3,16.5-47.2,11.7-58.5l60.4-60.4A12.3,12.3,0,0,0,232.7,96Zm-9.2,2.8-62.3,62.4a4.1,4.1,0,0,0-.8,4.6c10.4,20.8-1.6,42-9.7,52.8a4.1,4.1,0,0,1-2.9,1.6,3.6,3.6,0,0,1-3.1-1.2L36.8,111.1a4.2,4.2,0,0,1-1.2-3,4,4,0,0,1,1.5-2.9C50.1,94.7,62.5,91.9,72,91.9s17.7,3.4,18.2,3.7a4.1,4.1,0,0,0,4.6-.8l62.4-62.3h0a3.9,3.9,0,0,1,5.6,0l60.7,60.7a3.9,3.9,0,0,1,0,5.6Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M236.7,96A15.9,15.9,0,0,0,232,84.7L171.3,24a16.1,16.1,0,0,0-22.6,0h0L90,82.7c-10.7-3.3-35-7.4-60.4,13.1a15.9,15.9,0,0,0-1.3,23.8L76.7,168,42.3,202.3a8.1,8.1,0,0,0,0,11.4,8.2,8.2,0,0,0,11.4,0L88,179.3l48.2,48.2a16.1,16.1,0,0,0,11.3,4.7h1.1a16.3,16.3,0,0,0,11.7-6.4c19.6-26.1,17.7-47.3,13.2-60L232,107.3A15.9,15.9,0,0,0,236.7,96Zm-78.4,62.3a8.2,8.2,0,0,0-1.5,9.3c9.5,18.9-1.8,38.6-9.3,48.6L39.6,108.3C51.7,98.5,63.3,96,72.1,96S88,98.9,88.4,99.2a8.2,8.2,0,0,0,9.3-1.5L160,35.3,220.7,96Z" />
        </>
      )}
    </svg>
  );
}

export function PhPuzzlePiece(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M222.4,155.2a11.8,11.8,0,0,0-11.5-.7A16.5,16.5,0,0,1,204,156a15.8,15.8,0,0,1-11.7-5.1,16,16,0,0,1,18.6-25.4A12,12,0,0,0,228,114.7V72a20.1,20.1,0,0,0-20-20H176a40.4,40.4,0,0,0-12.6-29.2A40.1,40.1,0,0,0,96.1,49.1,28.1,28.1,0,0,0,96,52H64A20.1,20.1,0,0,0,44,72v28a40.4,40.4,0,0,0-29.2,12.6,40.1,40.1,0,0,0,26.3,67.3H44v28a20.1,20.1,0,0,0,20,20H208a20.1,20.1,0,0,0,20-20V165.3A11.9,11.9,0,0,0,222.4,155.2ZM204,204H68V165.3a12,12,0,0,0-17.1-10.8,16,16,0,0,1-18.6-25.4A15.8,15.8,0,0,1,44,124a16.5,16.5,0,0,1,6.9,1.5A12,12,0,0,0,68,114.7V76h42.7a12,12,0,0,0,10.8-17.1A16.1,16.1,0,0,1,134.9,36,16.1,16.1,0,0,1,152,52a16.5,16.5,0,0,1-1.5,6.9A12,12,0,0,0,161.3,76H204v24h-2.9a40.1,40.1,0,0,0-26.3,67.3A40.4,40.4,0,0,0,204,180h0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M64,216a8,8,0,0,1-8-8V165.3a27.6,27.6,0,0,1-14.1,2.6A28,28,0,1,1,56,114.7V72a8,8,0,0,1,8-8h46.7a27.6,27.6,0,0,1-2.6-14.1A28,28,0,1,1,161.3,64H208a8,8,0,0,1,8,8v42.7a27.6,27.6,0,0,0-14.1-2.6A28,28,0,1,0,216,165.3V208a8,8,0,0,1-8,8Z" />
          </g>
          <path d="M220.3,158.5a8.1,8.1,0,0,0-7.7-.4,20.2,20.2,0,0,1-23.2-4.4,20,20,0,0,1,13.1-33.6,19.6,19.6,0,0,1,10.1,1.8,8.1,8.1,0,0,0,7.7-.4,8.2,8.2,0,0,0,3.7-6.8V72a16,16,0,0,0-16-16H171.8c.1-1.3.2-2.7.2-4a36.1,36.1,0,0,0-38.3-35.9,36,36,0,0,0-33.6,33.3,36.4,36.4,0,0,0,.1,6.6H64A16,16,0,0,0,48,72v32.2l-4-.2a35.6,35.6,0,0,0-26.2,11.4,35.3,35.3,0,0,0-9.7,26.9,36,36,0,0,0,33.3,33.6,36.4,36.4,0,0,0,6.6-.1V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V165.3A8.2,8.2,0,0,0,220.3,158.5ZM208,208H64V165.3a8.2,8.2,0,0,0-3.7-6.8,8.1,8.1,0,0,0-7.7-.4,19.6,19.6,0,0,1-10.1,1.8,20,20,0,0,1-13.1-33.6,20.2,20.2,0,0,1,23.2-4.4,8.1,8.1,0,0,0,7.7-.4,8.2,8.2,0,0,0,3.7-6.8V72h46.7a8.2,8.2,0,0,0,6.8-3.7,8.1,8.1,0,0,0,.4-7.7,19.6,19.6,0,0,1-1.8-10.1,20,20,0,0,1,33.6-13.1,20.2,20.2,0,0,1,4.4,23.2,8.1,8.1,0,0,0,.4,7.7,8.2,8.2,0,0,0,6.8,3.7H208v32.2a36.4,36.4,0,0,0-6.6-.1,36,36,0,0,0-33.3,33.6A36.1,36.1,0,0,0,204,176l4-.2Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M224,170.3V208a16,16,0,0,1-16,16H64a16,16,0,0,1-16-16V175.8l-4,.2a36,36,0,0,1,0-72l4,.2V72A16,16,0,0,1,64,56h36.2c-.1-1.3-.2-2.7-.2-4a36,36,0,0,1,72,0c0,1.3-.1,2.7-.2,4H208a16,16,0,0,1,16,16v37.7a7.9,7.9,0,0,1-3.6,6.6,7.7,7.7,0,0,1-7.5.7,24.3,24.3,0,0,0-8.9-1.7c-13.2,0-24,11.1-24,24.7s10.8,24.7,24,24.7a24.3,24.3,0,0,0,8.9-1.7,7.7,7.7,0,0,1,7.5.7A7.9,7.9,0,0,1,224,170.3Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M219.2,160.2a6,6,0,0,0-5.8-.3A21.5,21.5,0,0,1,204,162a22,22,0,0,1-16-6.9,22.4,22.4,0,0,1-6-16.5,22.1,22.1,0,0,1,31.4-18.5,6,6,0,0,0,5.8-.3,6.1,6.1,0,0,0,2.8-5.1V72a14,14,0,0,0-14-14H169.5a40.5,40.5,0,0,0,.5-6,34.3,34.3,0,0,0-10.7-24.8,34,34,0,0,0-57.2,22.3,34.4,34.4,0,0,0,.4,8.5H64A14,14,0,0,0,50,72v34.5a40.5,40.5,0,0,0-6-.5,34.3,34.3,0,0,0-24.8,10.7,34,34,0,0,0,22.3,57.2,34.4,34.4,0,0,0,8.5-.4V208a14,14,0,0,0,14,14H208a14,14,0,0,0,14-14V165.3A6.1,6.1,0,0,0,219.2,160.2ZM210,208a2,2,0,0,1-2,2H64a2,2,0,0,1-2-2V165.3a6.1,6.1,0,0,0-2.8-5.1,6.2,6.2,0,0,0-3.2-.9,5.8,5.8,0,0,0-2.6.6A22.1,22.1,0,0,1,22,141.4a22.4,22.4,0,0,1,6-16.5A22,22,0,0,1,44,118a21.5,21.5,0,0,1,9.4,2.1,6,6,0,0,0,5.8-.3,6.1,6.1,0,0,0,2.8-5.1V72a2,2,0,0,1,2-2h46.7a6.1,6.1,0,0,0,5.1-2.8,6,6,0,0,0,.3-5.8A22.1,22.1,0,0,1,134.6,30a22.4,22.4,0,0,1,16.5,6A22,22,0,0,1,158,52a21.5,21.5,0,0,1-2.1,9.4,6,6,0,0,0,.3,5.8,6.1,6.1,0,0,0,5.1,2.8H208a2,2,0,0,1,2,2v34.5a34.4,34.4,0,0,0-8.5-.4A34,34,0,0,0,204,174a40.5,40.5,0,0,0,6-.5Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M218.1,161.9a3.9,3.9,0,0,0-3.8-.2A24.2,24.2,0,0,1,204,164a24,24,0,1,1,10.3-45.7,3.9,3.9,0,0,0,3.8-.2,4,4,0,0,0,1.9-3.4V72a12,12,0,0,0-12-12H167a30.8,30.8,0,0,0,1-8,32.1,32.1,0,0,0-10.1-23.3,32,32,0,0,0-53.8,21A32.2,32.2,0,0,0,105,60H64A12,12,0,0,0,52,72v37a30.8,30.8,0,0,0-8-1,32.1,32.1,0,0,0-23.3,10.1,32,32,0,0,0,21,53.8A32.2,32.2,0,0,0,52,171v37a12,12,0,0,0,12,12H208a12,12,0,0,0,12-12V165.3A4,4,0,0,0,218.1,161.9ZM212,208a4,4,0,0,1-4,4H64a4,4,0,0,1-4-4V165.3a4,4,0,0,0-1.9-3.4,3.9,3.9,0,0,0-2.1-.6,3.3,3.3,0,0,0-1.7.4A24,24,0,1,1,44,116a24.2,24.2,0,0,1,10.3,2.3,3.9,3.9,0,0,0,3.8-.2,4,4,0,0,0,1.9-3.4V72a4,4,0,0,1,4-4h46.7a4,4,0,0,0,3.4-1.9,3.9,3.9,0,0,0,.2-3.8A24,24,0,1,1,160,52a24.2,24.2,0,0,1-2.3,10.3,3.9,3.9,0,0,0,.2,3.8,4,4,0,0,0,3.4,1.9H208a4,4,0,0,1,4,4v37a32.1,32.1,0,0,0-39.9,28.9,31.6,31.6,0,0,0,8.6,24A32.1,32.1,0,0,0,204,172a30.8,30.8,0,0,0,8-1Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M220.3,158.5a8.1,8.1,0,0,0-7.7-.4,20.2,20.2,0,0,1-23.2-4.4,20,20,0,0,1,13.1-33.6,19.6,19.6,0,0,1,10.1,1.8,8.1,8.1,0,0,0,7.7-.4,8.2,8.2,0,0,0,3.7-6.8V72a16,16,0,0,0-16-16H171.8c.1-1.3.2-2.7.2-4a35.6,35.6,0,0,0-11.4-26.2,35.3,35.3,0,0,0-26.9-9.7,36,36,0,0,0-33.6,33.3,36.4,36.4,0,0,0,.1,6.6H64A16,16,0,0,0,48,72v32.2l-4-.2a35.6,35.6,0,0,0-26.2,11.4,35.3,35.3,0,0,0-9.7,26.9,36,36,0,0,0,33.3,33.6,36.4,36.4,0,0,0,6.6-.1V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V165.3A8.2,8.2,0,0,0,220.3,158.5ZM208,208H64V165.3a8.2,8.2,0,0,0-3.7-6.8,8.1,8.1,0,0,0-7.7-.4,19.6,19.6,0,0,1-10.1,1.8,20,20,0,0,1-13.1-33.6,20.2,20.2,0,0,1,23.2-4.4,8.1,8.1,0,0,0,7.7-.4,8.2,8.2,0,0,0,3.7-6.8V72h46.7a8.2,8.2,0,0,0,6.8-3.7,8.1,8.1,0,0,0,.4-7.7,19.6,19.6,0,0,1-1.8-10.1,20,20,0,0,1,33.6-13.1,20.2,20.2,0,0,1,4.4,23.2,8.1,8.1,0,0,0,.4,7.7,8.2,8.2,0,0,0,6.8,3.7H208v32.2a36.4,36.4,0,0,0-6.6-.1,36,36,0,0,0-33.3,33.6A36.1,36.1,0,0,0,204,176l4-.2Z" />
        </>
      )}
    </svg>
  );
}

export function PhQrCode(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M100,36H56A20.1,20.1,0,0,0,36,56v44a20.1,20.1,0,0,0,20,20h44a20.1,20.1,0,0,0,20-20V56A20.1,20.1,0,0,0,100,36ZM96,96H60V60H96Z" />
          <path d="M100,136H56a20.1,20.1,0,0,0-20,20v44a20.1,20.1,0,0,0,20,20h44a20.1,20.1,0,0,0,20-20V156A20.1,20.1,0,0,0,100,136Zm-4,60H60V160H96Z" />
          <path d="M200,36H156a20.1,20.1,0,0,0-20,20v44a20.1,20.1,0,0,0,20,20h44a20.1,20.1,0,0,0,20-20V56A20.1,20.1,0,0,0,200,36Zm-4,60H160V60h36Z" />
          <path d="M148,184a12,12,0,0,0,12-12V148a12,12,0,0,0-24,0v24A12,12,0,0,0,148,184Z" />
          <path d="M208,152H196v-4a12,12,0,0,0-24,0v48H148a12,12,0,0,0,0,24h36a12,12,0,0,0,12-12V176h12a12,12,0,0,0,0-24Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect x="48" y="48" width="64" height="64" rx="8" />
          </g>
          <g opacity="0.2">
            <rect x="48" y="144" width="64" height="64" rx="8" />
          </g>
          <g opacity="0.2">
            <rect x="144" y="48" width="64" height="64" rx="8" />
          </g>
          <path d="M104,40H56A16,16,0,0,0,40,56v48a16,16,0,0,0,16,16h48a16,16,0,0,0,16-16V56A16,16,0,0,0,104,40Zm0,64H56V56h48v48Z" />
          <path d="M104,136H56a16,16,0,0,0-16,16v48a16,16,0,0,0,16,16h48a16,16,0,0,0,16-16V152A16,16,0,0,0,104,136Zm0,64H56V152h48v48Z" />
          <path d="M200,40H152a16,16,0,0,0-16,16v48a16,16,0,0,0,16,16h48a16,16,0,0,0,16-16V56A16,16,0,0,0,200,40Zm0,64H152V56h48v48Z" />
          <path d="M144,184a8,8,0,0,0,8-8V144a8,8,0,0,0-16,0v32A8,8,0,0,0,144,184Z" />
          <path d="M208,152H184v-8a8,8,0,0,0-16,0v56H144a8,8,0,0,0,0,16h32a8,8,0,0,0,8-8V168h24a8,8,0,0,0,0-16Z" />
          <path d="M208,184a8,8,0,0,0-8,8v16a8,8,0,0,0,16,0V192A8,8,0,0,0,208,184Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M120,56v48a16,16,0,0,1-16,16H56a16,16,0,0,1-16-16V56A16,16,0,0,1,56,40h48A16,16,0,0,1,120,56Zm-16,80H56a16,16,0,0,0-16,16v48a16,16,0,0,0,16,16h48a16,16,0,0,0,16-16V152A16,16,0,0,0,104,136Zm96-96H152a16,16,0,0,0-16,16v48a16,16,0,0,0,16,16h48a16,16,0,0,0,16-16V56A16,16,0,0,0,200,40ZM144,184a8,8,0,0,0,8-8V144a8,8,0,0,0-16,0v32A8,8,0,0,0,144,184Zm64-32H184v-8a8,8,0,0,0-16,0v56H144a8,8,0,0,0,0,16h32a8,8,0,0,0,8-8V168h24a8,8,0,0,0,0-16Zm0,32a8,8,0,0,0-8,8v16a8,8,0,0,0,16,0V192A8,8,0,0,0,208,184Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M104,42H56A14,14,0,0,0,42,56v48a14,14,0,0,0,14,14h48a14,14,0,0,0,14-14V56A14,14,0,0,0,104,42Zm2,62a2,2,0,0,1-2,2H56a2,2,0,0,1-2-2V56a2,2,0,0,1,2-2h48a2,2,0,0,1,2,2Zm-2,34H56a14,14,0,0,0-14,14v48a14,14,0,0,0,14,14h48a14,14,0,0,0,14-14V152A14,14,0,0,0,104,138Zm2,62a2,2,0,0,1-2,2H56a2,2,0,0,1-2-2V152a2,2,0,0,1,2-2h48a2,2,0,0,1,2,2ZM200,42H152a14,14,0,0,0-14,14v48a14,14,0,0,0,14,14h48a14,14,0,0,0,14-14V56A14,14,0,0,0,200,42Zm2,62a2,2,0,0,1-2,2H152a2,2,0,0,1-2-2V56a2,2,0,0,1,2-2h48a2,2,0,0,1,2,2Zm-64,72V144a6,6,0,0,1,12,0v32a6,6,0,0,1-12,0Zm76-16a6,6,0,0,1-6,6H182v42a6,6,0,0,1-6,6H144a6,6,0,0,1,0-12h26V144a6,6,0,0,1,12,0v10h26A6,6,0,0,1,214,160Zm0,32v16a6,6,0,0,1-12,0V192a6,6,0,0,1,12,0Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M104,44H56A12,12,0,0,0,44,56v48a12,12,0,0,0,12,12h48a12,12,0,0,0,12-12V56A12,12,0,0,0,104,44Zm4,60a4,4,0,0,1-4,4H56a4,4,0,0,1-4-4V56a4,4,0,0,1,4-4h48a4,4,0,0,1,4,4Zm-4,36H56a12,12,0,0,0-12,12v48a12,12,0,0,0,12,12h48a12,12,0,0,0,12-12V152A12,12,0,0,0,104,140Zm4,60a4,4,0,0,1-4,4H56a4,4,0,0,1-4-4V152a4,4,0,0,1,4-4h48a4,4,0,0,1,4,4ZM200,44H152a12,12,0,0,0-12,12v48a12,12,0,0,0,12,12h48a12,12,0,0,0,12-12V56A12,12,0,0,0,200,44Zm4,60a4,4,0,0,1-4,4H152a4,4,0,0,1-4-4V56a4,4,0,0,1,4-4h48a4,4,0,0,1,4,4Zm-64,72V144a4,4,0,0,1,8,0v32a4,4,0,0,1-8,0Zm72-16a4,4,0,0,1-4,4H180v44a4,4,0,0,1-4,4H144a4,4,0,0,1,0-8h28V144a4,4,0,0,1,8,0v12h28A4,4,0,0,1,212,160Zm0,32v16a4,4,0,0,1-8,0V192a4,4,0,0,1,8,0Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M104,40H56A16,16,0,0,0,40,56v48a16,16,0,0,0,16,16h48a16,16,0,0,0,16-16V56A16,16,0,0,0,104,40Zm0,64H56V56h48v48Zm0,32H56a16,16,0,0,0-16,16v48a16,16,0,0,0,16,16h48a16,16,0,0,0,16-16V152A16,16,0,0,0,104,136Zm0,64H56V152h48v48ZM200,40H152a16,16,0,0,0-16,16v48a16,16,0,0,0,16,16h48a16,16,0,0,0,16-16V56A16,16,0,0,0,200,40Zm0,64H152V56h48v48Zm-64,72V144a8,8,0,0,1,16,0v32a8,8,0,0,1-16,0Zm80-16a8,8,0,0,1-8,8H184v40a8,8,0,0,1-8,8H144a8,8,0,0,1,0-16h24V144a8,8,0,0,1,16,0v8h24A8,8,0,0,1,216,160Zm0,32v16a8,8,0,0,1-16,0V192a8,8,0,0,1,16,0Z" />
        </>
      )}
    </svg>
  );
}

export function PhQuestion(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,20A108,108,0,1,0,236,128,108.1,108.1,0,0,0,128,20Zm0,192a84,84,0,1,1,84-84A84.1,84.1,0,0,1,128,212Zm16-36a16,16,0,1,1-16-16A16,16,0,0,1,144,176Zm24-68a40,40,0,0,1-40,40,12,12,0,0,1,0-24,16,16,0,1,0-16-16,12,12,0,0,1-24,0,40,40,0,0,1,80,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="128" cy="128" r="96" />
          </g>
          <path d="M128,24A104,104,0,1,0,232,128,104.1,104.1,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm12-36a12,12,0,1,1-12-12A12,12,0,0,1,140,180Zm24-72a36,36,0,0,1-28,35.1v.9a8,8,0,0,1-16,0v-8a8,8,0,0,1,8-8,20,20,0,1,0-20-20,8,8,0,0,1-16,0,36,36,0,0,1,72,0Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.1,104.1,0,0,0,128,24Zm0,168a12,12,0,1,1,12-12A12,12,0,0,1,128,192Zm8-48.9v.9a8,8,0,0,1-16,0v-8a8,8,0,0,1,8-8,20,20,0,1,0-20-20,8,8,0,0,1-16,0,36,36,0,1,1,44,35.1Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,26A102,102,0,1,0,230,128,102.2,102.2,0,0,0,128,26Zm0,192a90,90,0,1,1,90-90A90.1,90.1,0,0,1,128,218Zm10-38a10,10,0,1,1-10-10A10,10,0,0,1,138,180Zm24-72a34.1,34.1,0,0,1-28,33.5V144a6,6,0,0,1-12,0v-8a6,6,0,0,1,6-6,22,22,0,1,0-22-22,6,6,0,0,1-12,0,34,34,0,0,1,68,0Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,28A100,100,0,1,0,228,128,100.2,100.2,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm8-40a8,8,0,1,1-8-8A8,8,0,0,1,136,180Zm24-72a32.1,32.1,0,0,1-28,31.8V144a4,4,0,0,1-8,0v-8a4,4,0,0,1,4-4,24,24,0,1,0-24-24,4,4,0,0,1-8,0,32,32,0,0,1,64,0Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.1,104.1,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm12-36a12,12,0,1,1-12-12A12,12,0,0,1,140,180Zm24-72a36,36,0,0,1-28,35.1v.9a8,8,0,0,1-16,0v-8a8,8,0,0,1,8-8,20,20,0,1,0-20-20,8,8,0,0,1-16,0,36,36,0,0,1,72,0Z" />
        </>
      )}
    </svg>
  );
}

export function PhQueue(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M148,192a12,12,0,0,1-12,12H40a12,12,0,0,1,0-24h96A12,12,0,0,1,148,192Zm104-32a12.1,12.1,0,0,1-5.6,10.2l-64,40A12.4,12.4,0,0,1,176,212a12,12,0,0,1-12-12V120a12,12,0,0,1,18.4-10.2l64,40A12.1,12.1,0,0,1,252,160Zm-34.6,0L188,141.7v36.6ZM40,76H216a12,12,0,0,0,0-24H40a12,12,0,0,0,0,24Zm96,40H40a12,12,0,0,0,0,24h96a12,12,0,0,0,0-24Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M240,160l-64,40V120Z" opacity="0.2" />
          <path d="M32,64a8,8,0,0,1,8-8H216a8,8,0,0,1,0,16H40A8,8,0,0,1,32,64Zm104,56H40a8,8,0,0,0,0,16h96a8,8,0,0,0,0-16Zm0,64H40a8,8,0,0,0,0,16h96a8,8,0,0,0,0-16Zm112-24a7.9,7.9,0,0,1-3.8,6.8l-64,40a8,8,0,0,1-8.1.2,8.1,8.1,0,0,1-4.1-7V120a8,8,0,0,1,12.2-6.8l64,40A7.9,7.9,0,0,1,248,160Zm-23.1,0L184,134.4v51.2Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M144,192a8,8,0,0,1-8,8H40a8,8,0,0,1,0-16h96A8,8,0,0,1,144,192ZM40,72H216a8,8,0,0,0,0-16H40a8,8,0,0,0,0,16Zm96,48H40a8,8,0,0,0,0,16h96a8,8,0,0,0,0-16Zm108.2,33.2-64-40A8,8,0,0,0,168,120v80a8.1,8.1,0,0,0,4.1,7,8,8,0,0,0,8.1-.2l64-40a8,8,0,0,0,0-13.6Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M34,64a6,6,0,0,1,6-6H216a6,6,0,0,1,0,12H40A6,6,0,0,1,34,64Zm102,58H40a6,6,0,0,0,0,12h96a6,6,0,0,0,0-12Zm0,64H40a6,6,0,0,0,0,12h96a6,6,0,0,0,0-12Zm110-26a6.1,6.1,0,0,1-2.8,5.1l-64,40a6.2,6.2,0,0,1-3.2.9,5.1,5.1,0,0,1-2.9-.8A5.9,5.9,0,0,1,170,200V120a5.9,5.9,0,0,1,3.1-5.2,6.1,6.1,0,0,1,6.1.1l64,40A6.1,6.1,0,0,1,246,160Zm-17.3,0L182,130.8v58.4Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M36,64a4,4,0,0,1,4-4H216a4,4,0,0,1,0,8H40A4,4,0,0,1,36,64Zm100,60H40a4,4,0,0,0,0,8h96a4,4,0,0,0,0-8Zm0,64H40a4,4,0,0,0,0,8h96a4,4,0,0,0,0-8Zm108-28a3.9,3.9,0,0,1-1.9,3.4l-64,40a3.9,3.9,0,0,1-2.1.6,4.1,4.1,0,0,1-1.9-.5A3.9,3.9,0,0,1,172,200V120a3.9,3.9,0,0,1,2.1-3.5,3.8,3.8,0,0,1,4,.1l64,40A3.9,3.9,0,0,1,244,160Zm-11.5,0L180,127.2v65.6Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M32,64a8,8,0,0,1,8-8H216a8,8,0,0,1,0,16H40A8,8,0,0,1,32,64Zm104,56H40a8,8,0,0,0,0,16h96a8,8,0,0,0,0-16Zm0,64H40a8,8,0,0,0,0,16h96a8,8,0,0,0,0-16Zm112-24a7.9,7.9,0,0,1-3.8,6.8l-64,40a8,8,0,0,1-8.1.2,8.1,8.1,0,0,1-4.1-7V120a8,8,0,0,1,12.2-6.8l64,40A7.9,7.9,0,0,1,248,160Zm-23.1,0L184,134.4v51.2Z" />
        </>
      )}
    </svg>
  );
}

export function PhQuotes(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M100,52H40A20.1,20.1,0,0,0,20,72v64a20.1,20.1,0,0,0,20,20H96v4a28.1,28.1,0,0,1-28,28,12,12,0,0,0,0,24,52,52,0,0,0,52-52V72A20.1,20.1,0,0,0,100,52Zm-4,80H44V76H96ZM216,52H156a20.1,20.1,0,0,0-20,20v64a20.1,20.1,0,0,0,20,20h56v4a28.1,28.1,0,0,1-28,28,12,12,0,0,0,0,24,52,52,0,0,0,52-52V72A20.1,20.1,0,0,0,216,52Zm-4,80H160V76h52Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M108,72v72H40a8,8,0,0,1-8-8V72a8,8,0,0,1,8-8h60A8,8,0,0,1,108,72Zm108-8H156a8,8,0,0,0-8,8v64a8,8,0,0,0,8,8h68V72A8,8,0,0,0,216,64Z"
            opacity="0.2"
          />
          <path d="M100,56H40A16,16,0,0,0,24,72v64a16,16,0,0,0,16,16h60v8a32.1,32.1,0,0,1-32,32,8,8,0,0,0,0,16,48,48,0,0,0,48-48V72A16,16,0,0,0,100,56Zm0,80H40V72h60ZM216,56H156a16,16,0,0,0-16,16v64a16,16,0,0,0,16,16h60v8a32.1,32.1,0,0,1-32,32,8,8,0,0,0,0,16,48,48,0,0,0,48-48V72A16,16,0,0,0,216,56Zm0,80H156V72h60Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M116,72v88a48,48,0,0,1-48,48,8,8,0,0,1,0-16,32.1,32.1,0,0,0,32-32v-8H40a16,16,0,0,1-16-16V72A16,16,0,0,1,40,56h60A16,16,0,0,1,116,72ZM216,56H156a16,16,0,0,0-16,16v64a16,16,0,0,0,16,16h60v8a32.1,32.1,0,0,1-32,32,8,8,0,0,0,0,16,48,48,0,0,0,48-48V72A16,16,0,0,0,216,56Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M100,58H40A14,14,0,0,0,26,72v64a14,14,0,0,0,14,14h62v10a34.1,34.1,0,0,1-34,34,6,6,0,0,0,0,12,46,46,0,0,0,46-46V72A14,14,0,0,0,100,58Zm2,80H40a2,2,0,0,1-2-2V72a2,2,0,0,1,2-2h60a2,2,0,0,1,2,2ZM216,58H156a14,14,0,0,0-14,14v64a14,14,0,0,0,14,14h62v10a34.1,34.1,0,0,1-34,34,6,6,0,0,0,0,12,46,46,0,0,0,46-46V72A14,14,0,0,0,216,58Zm2,80H156a2,2,0,0,1-2-2V72a2,2,0,0,1,2-2h60a2,2,0,0,1,2,2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M100,60H40A12,12,0,0,0,28,72v64a12,12,0,0,0,12,12h64v12a36,36,0,0,1-36,36,4,4,0,0,0,0,8,44,44,0,0,0,44-44V72A12,12,0,0,0,100,60Zm4,80H40a4,4,0,0,1-4-4V72a4,4,0,0,1,4-4h60a4,4,0,0,1,4,4ZM216,60H156a12,12,0,0,0-12,12v64a12,12,0,0,0,12,12h64v12a36,36,0,0,1-36,36,4,4,0,0,0,0,8,44,44,0,0,0,44-44V72A12,12,0,0,0,216,60Zm4,80H156a4,4,0,0,1-4-4V72a4,4,0,0,1,4-4h60a4,4,0,0,1,4,4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M100,56H40A16,16,0,0,0,24,72v64a16,16,0,0,0,16,16h60v8a32.1,32.1,0,0,1-32,32,8,8,0,0,0,0,16,48,48,0,0,0,48-48V72A16,16,0,0,0,100,56Zm0,80H40V72h60ZM216,56H156a16,16,0,0,0-16,16v64a16,16,0,0,0,16,16h60v8a32.1,32.1,0,0,1-32,32,8,8,0,0,0,0,16,48,48,0,0,0,48-48V72A16,16,0,0,0,216,56Zm0,80H156V72h60Z" />
        </>
      )}
    </svg>
  );
}

export function PhRadical(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M72,212a11.9,11.9,0,0,1-11.2-7.8l-48-128a12,12,0,1,1,22.4-8.4l36.8,98,36.8-98A11.9,11.9,0,0,1,120,60H232a12,12,0,0,1,12,12V96a12,12,0,0,1-24,0V84H128.3L83.2,204.2A11.9,11.9,0,0,1,72,212Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M72,208a8.1,8.1,0,0,1-7.5-5.2l-48-128a8,8,0,1,1,15-5.6L72,177.2l40.5-108A8.1,8.1,0,0,1,120,64H232a8,8,0,0,1,8,8V96a8,8,0,0,1-16,0V80H125.5l-46,122.8A8.1,8.1,0,0,1,72,208Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M72,208a8.1,8.1,0,0,1-7.5-5.2l-48-128a8,8,0,1,1,15-5.6L72,177.2l40.5-108A8.1,8.1,0,0,1,120,64H232a8,8,0,0,1,8,8V96a8,8,0,0,1-16,0V80H125.5l-46,122.8A8.1,8.1,0,0,1,72,208Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M72,206a6.1,6.1,0,0,1-5.6-3.9l-48-128a6,6,0,1,1,11.2-4.2L72,182.9l42.4-113A6.1,6.1,0,0,1,120,66H232a6,6,0,0,1,6,6V96a6,6,0,0,1-12,0V78H124.2L77.6,202.1A6.1,6.1,0,0,1,72,206Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M72,204a3.8,3.8,0,0,1-3.7-2.6l-48-128a4,4,0,0,1,7.4-2.8L72,188.6l44.3-118A3.8,3.8,0,0,1,120,68H232a4,4,0,0,1,4,4V96a4,4,0,0,1-8,0V76H122.8L75.7,201.4A3.8,3.8,0,0,1,72,204Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M72,208a8.1,8.1,0,0,1-7.5-5.2l-48-128a8,8,0,1,1,15-5.6L72,177.2l40.5-108A8.1,8.1,0,0,1,120,64H232a8,8,0,0,1,8,8V96a8,8,0,0,1-16,0V80H125.5l-46,122.8A8.1,8.1,0,0,1,72,208Z" />
        </>
      )}
    </svg>
  );
}

export function PhRadioButton(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,20A108,108,0,1,0,236,128,108.1,108.1,0,0,0,128,20Zm0,192a84,84,0,1,1,84-84A84.1,84.1,0,0,1,128,212Zm0-148a64,64,0,1,0,64,64A64.1,64.1,0,0,0,128,64Zm0,104a40,40,0,1,1,40-40A40,40,0,0,1,128,168Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M184,128a56,56,0,1,1-56-56A56,56,0,0,1,184,128Z"
            opacity="0.2"
          />
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm0-152a64,64,0,1,0,64,64A64.1,64.1,0,0,0,128,64Zm0,112a48,48,0,1,1,48-48A48,48,0,0,1,128,176Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M192,128a64,64,0,1,1-64-64A64.1,64.1,0,0,1,192,128Zm40,0A104,104,0,1,1,128,24,104.2,104.2,0,0,1,232,128Zm-16,0a88,88,0,1,0-88,88A88.1,88.1,0,0,0,216,128Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,26A102,102,0,1,0,230,128,102.2,102.2,0,0,0,128,26Zm0,192a90,90,0,1,1,90-90A90.1,90.1,0,0,1,128,218Zm0-152a62,62,0,1,0,62,62A62.1,62.1,0,0,0,128,66Zm0,112a50,50,0,1,1,50-50A50,50,0,0,1,128,178Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,28A100,100,0,1,0,228,128,100.2,100.2,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm0-152a60,60,0,1,0,60,60A60,60,0,0,0,128,68Zm0,112a52,52,0,1,1,52-52A52,52,0,0,1,128,180Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm0-152a64,64,0,1,0,64,64A64.1,64.1,0,0,0,128,64Zm0,112a48,48,0,1,1,48-48A48,48,0,0,1,128,176Z" />
        </>
      )}
    </svg>
  );
}

export function PhRadio(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216,68H113.8l81.6-24.5a12,12,0,1,0-6.8-23l-160,48h-.1l-1,.4h-.1l-.8.4h-.3l-.3.2a11.8,11.8,0,0,0-3.2,2.7h-.1l-.4.5-.3.4c0,.1-.1.2-.1.3a10,10,0,0,0-1.5,3.5c-.1.1-.1.3-.2.5v.2c0,.3-.1.6-.1,1h0A3.7,3.7,0,0,0,20,80h0V200a20.1,20.1,0,0,0,20,20H216a20.1,20.1,0,0,0,20-20V88A20.1,20.1,0,0,0,216,68Zm-4,128H44V92H212Zm-52-16a36,36,0,1,0-36-36A36,36,0,0,0,160,180Zm0-48a12,12,0,1,1-12,12A12,12,0,0,1,160,132ZM60,124a12,12,0,0,1,12-12H96a12,12,0,0,1,0,24H72A12,12,0,0,1,60,124Zm0,40a12,12,0,0,1,12-12H96a12,12,0,0,1,0,24H72A12,12,0,0,1,60,164Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M216,80H32V200a8,8,0,0,0,8,8H216a8,8,0,0,0,8-8V88A8,8,0,0,0,216,80Zm-56,96a32,32,0,1,1,32-32A32,32,0,0,1,160,176Z"
            opacity="0.2"
          />
          <path d="M216,72H86.5L194.3,39.7a8,8,0,1,0-4.6-15.4l-160,48h0l-.7.3h-.1l-.5.3H28a6.8,6.8,0,0,0-2.1,1.7.1.1,0,0,1-.1.1c-.1.1-.1.2-.2.4l-.2.2a.3.3,0,0,0-.1.2,6.7,6.7,0,0,0-1,2.3c-.1.1-.1.2-.1.4h-.1a2,2,0,0,1-.1.7h0V80h0V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V88A16,16,0,0,0,216,72Zm0,128H40V88H216V200Zm-56-96a40,40,0,1,0,40,40A40,40,0,0,0,160,104Zm0,64a24,24,0,1,1,24-24A24.1,24.1,0,0,1,160,168Zm-56-56a8,8,0,0,1-8,8H64a8,8,0,0,1,0-16H96A8,8,0,0,1,104,112Zm0,32a8,8,0,0,1-8,8H64a8,8,0,0,1,0-16H96A8,8,0,0,1,104,144Zm0,32a8,8,0,0,1-8,8H64a8,8,0,0,1,0-16H96A8,8,0,0,1,104,176Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,72H86.5L194.3,39.7a8,8,0,1,0-4.6-15.4l-160,48h0l-.7.3h-.1l-.5.3H28a6.8,6.8,0,0,0-2.1,1.7.1.1,0,0,1-.1.1c-.1.1-.1.2-.2.4l-.2.2a.3.3,0,0,0-.1.2,6.7,6.7,0,0,0-1,2.3c-.1.1-.1.2-.1.4h-.1a2,2,0,0,1-.1.7h0V80h0V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V88A16,16,0,0,0,216,72ZM96,184H56a8,8,0,0,1,0-16H96a8,8,0,0,1,0,16Zm0-32H56a8,8,0,0,1,0-16H96a8,8,0,0,1,0,16Zm0-32H56a8,8,0,0,1,0-16H96a8,8,0,0,1,0,16Zm72,52a28,28,0,1,1,28-28A28.1,28.1,0,0,1,168,172Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M216,74H72.9L193.7,37.7a5.9,5.9,0,0,0-3.4-11.4l-160,48h-.6l-.4.2H29a5.4,5.4,0,0,0-1.6,1.4h0l-.2.3-.2.2h-.1a8.7,8.7,0,0,0-.7,1.7v.3h0a4.3,4.3,0,0,0-.1.5h0V80h0V200a14,14,0,0,0,14,14H216a14,14,0,0,0,14-14V88A14,14,0,0,0,216,74Zm2,126a2,2,0,0,1-2,2H40a2,2,0,0,1-2-2V86H216a2,2,0,0,1,2,2Zm-58-94a38,38,0,1,0,38,38A38,38,0,0,0,160,106Zm0,64a26,26,0,1,1,26-26A26.1,26.1,0,0,1,160,170Zm-58-58a6,6,0,0,1-6,6H64a6,6,0,0,1,0-12H96A6,6,0,0,1,102,112Zm0,32a6,6,0,0,1-6,6H64a6,6,0,0,1,0-12H96A6,6,0,0,1,102,144Zm0,32a6,6,0,0,1-6,6H64a6,6,0,0,1,0-12H96A6,6,0,0,1,102,176Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M216,76H59.3L193.1,35.8a4,4,0,0,0-2.2-7.6l-160,48h-.2l-.4.2-.4.2-.3.2-.3.3-.3.2c0,.1-.1.2-.2.3l-.2.3c-.1.1-.1.3-.2.4s-.1.2-.1.3-.1.3-.2.4v.4a.6.6,0,0,0-.1.4V200a12,12,0,0,0,12,12H216a12,12,0,0,0,12-12V88A12,12,0,0,0,216,76Zm4,124a4,4,0,0,1-4,4H40a4,4,0,0,1-4-4V84H216a4,4,0,0,1,4,4Zm-60-92a36,36,0,1,0,36,36A36,36,0,0,0,160,108Zm0,64a28,28,0,1,1,28-28A28.1,28.1,0,0,1,160,172Zm-60-60a4,4,0,0,1-4,4H64a4,4,0,0,1,0-8H96A4,4,0,0,1,100,112Zm0,32a4,4,0,0,1-4,4H64a4,4,0,0,1,0-8H96A4,4,0,0,1,100,144Zm0,32a4,4,0,0,1-4,4H64a4,4,0,0,1,0-8H96A4,4,0,0,1,100,176Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,72H86.5L194.3,39.7a8,8,0,1,0-4.6-15.4l-160,48h0l-.7.3h-.1l-.5.3H28a6.8,6.8,0,0,0-2.1,1.7.1.1,0,0,1-.1.1c-.1.1-.1.2-.2.4l-.2.2a.3.3,0,0,0-.1.2,6.7,6.7,0,0,0-1,2.3c-.1.1-.1.2-.1.4h-.1a2,2,0,0,1-.1.7h0V80h0V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V88A16,16,0,0,0,216,72Zm0,128H40V88H216V200Zm-56-96a40,40,0,1,0,40,40A40,40,0,0,0,160,104Zm0,64a24,24,0,1,1,24-24A24.1,24.1,0,0,1,160,168Zm-56-56a8,8,0,0,1-8,8H64a8,8,0,0,1,0-16H96A8,8,0,0,1,104,112Zm0,32a8,8,0,0,1-8,8H64a8,8,0,0,1,0-16H96A8,8,0,0,1,104,144Zm0,32a8,8,0,0,1-8,8H64a8,8,0,0,1,0-16H96A8,8,0,0,1,104,176Z" />
        </>
      )}
    </svg>
  );
}

export function PhRainbowCloud(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M204,108a52.2,52.2,0,0,0-47.2,30.2H156c-19.9,0-36,16.6-36,36.9S136.1,212,156,212h48a52,52,0,0,0,0-104Zm0,80H156c-6.6,0-12-5.8-12-12.9s5.4-12.9,12-12.9a10.7,10.7,0,0,1,4.3.9,12.2,12.2,0,0,0,10.2-.6,11.8,11.8,0,0,0,6.1-8.1A28,28,0,1,1,204,188Zm-94.8-51.2a12.1,12.1,0,0,1-2.1,16.9A7.8,7.8,0,0,0,104,160v16a12,12,0,0,1-24,0V160a31.7,31.7,0,0,1,12.4-25.3A12,12,0,0,1,109.2,136.8Zm27.5-18A48.8,48.8,0,0,0,112,112a48,48,0,0,0-48,48v16a12,12,0,0,1-24,0V160A72,72,0,0,1,149,98.2a12,12,0,0,1-12.3,20.6ZM24,160v16a12,12,0,0,1-24,0V160A112,112,0,0,1,185.5,75.5a12,12,0,0,1-15.8,18.1A88,88,0,0,0,24,160Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M152,200c-13.3,0-24-11.1-24-24.9s10.7-24.9,24-24.9a21.6,21.6,0,0,1,8.8,1.8h0A40,40,0,1,1,200,200Z" />
          </g>
          <path d="M200,112a48.4,48.4,0,0,0-44.7,30.4l-3.3-.2c-17.6,0-32,14.8-32,32.9S134.4,208,152,208h48a48,48,0,0,0,0-96Zm0,80H152c-8.8,0-16-7.6-16-16.9s7.2-16.9,16-16.9a14.7,14.7,0,0,1,5.8,1.2,8.1,8.1,0,0,0,6.8-.4,7.6,7.6,0,0,0,4-5.4A32,32,0,1,1,200,192ZM24,160v16a8,8,0,0,1-16,0V160A104,104,0,0,1,184.5,85.4a8,8,0,1,1-11.2,11.5A88,88,0,0,0,24,160Zm32,0v16a8,8,0,0,1-16,0V160a72.1,72.1,0,0,1,72-72,70.9,70.9,0,0,1,42.6,14,8,8,0,1,1-9.5,12.8A56,56,0,0,0,56,160Zm61.6-23.4a26,26,0,0,0-5.6-.6,24.1,24.1,0,0,0-24,24v16a8,8,0,0,1-16,0V160a40,40,0,0,1,40-40,42.2,42.2,0,0,1,9.3,1.1,8,8,0,0,1-3.7,15.5Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M24,160v16a8,8,0,0,1-16,0V160A104,104,0,0,1,184.5,85.4a8,8,0,1,1-11.2,11.5A88,88,0,0,0,24,160Zm88-56a56,56,0,0,1,33.1,10.8,8,8,0,1,0,9.5-12.8A70.9,70.9,0,0,0,112,88a72.1,72.1,0,0,0-72,72v16a8,8,0,0,0,16,0V160A56,56,0,0,1,112,104Zm15.2,26.7a7.9,7.9,0,0,0-5.9-9.6A42.2,42.2,0,0,0,112,120a40,40,0,0,0-40,40v16a8,8,0,0,0,16,0V160a24.1,24.1,0,0,1,24-24,26,26,0,0,1,5.6.6A7.9,7.9,0,0,0,127.2,130.7ZM200,112a48.4,48.4,0,0,0-44.7,30.4l-3.3-.2c-17.6,0-32,14.8-32,32.9S134.4,208,152,208h48a48,48,0,0,0,0-96Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M200,114a46.3,46.3,0,0,0-43.4,30.6,23.2,23.2,0,0,0-4.6-.4c-16.5,0-30,13.9-30,30.9S135.5,206,152,206h48a46,46,0,0,0,0-92Zm0,80H152c-9.9,0-18-8.5-18-18.9s8.1-18.9,18-18.9a17.5,17.5,0,0,1,6.5,1.3,5.7,5.7,0,0,0,5.1-.3,6,6,0,0,0,3.1-4A34,34,0,1,1,200,194ZM22,160v16a6,6,0,0,1-12,0V160A102,102,0,0,1,183.1,86.9a6,6,0,0,1,.1,8.5,6.1,6.1,0,0,1-8.5.1A88.9,88.9,0,0,0,112,70,90.1,90.1,0,0,0,22,160Zm90-58a58,58,0,0,0-58,58v16a6,6,0,0,1-12,0V160a70.1,70.1,0,0,1,70-70,69.4,69.4,0,0,1,41.4,13.6,5.9,5.9,0,0,1,1.3,8.4,6,6,0,0,1-8.4,1.2A57.7,57.7,0,0,0,112,102Zm6,32.7a24,24,0,0,0-6-.7,26.1,26.1,0,0,0-26,26v16a6,6,0,0,1-12,0V160a38,38,0,0,1,38-38,37.1,37.1,0,0,1,8.8,1,6.2,6.2,0,0,1,4.5,7.3A6,6,0,0,1,118,134.7Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M200,116a44.2,44.2,0,0,0-42,30.9,24.6,24.6,0,0,0-6-.7c-15.4,0-28,13-28,28.9S136.6,204,152,204h48a44,44,0,0,0,0-88Zm0,80H152c-11,0-20-9.4-20-20.9s9-20.9,20-20.9a18.3,18.3,0,0,1,7.3,1.5,4.5,4.5,0,0,0,3.4-.2,4.2,4.2,0,0,0,2-2.7A36,36,0,1,1,200,196ZM20,160v16a4,4,0,0,1-8,0V160A100,100,0,0,1,181.7,88.3a4,4,0,1,1-5.6,5.7A91.2,91.2,0,0,0,112,68,92.1,92.1,0,0,0,20,160Zm92-60a60,60,0,0,0-60,60v16a4,4,0,0,1-8,0V160a68,68,0,0,1,108.2-54.8,4,4,0,0,1,.9,5.6,4.1,4.1,0,0,1-5.6.8A59.4,59.4,0,0,0,112,100Zm11.3,29.8a4.1,4.1,0,0,1-4.8,3A27.9,27.9,0,0,0,84,160v16a4,4,0,0,1-8,0V160a36,36,0,0,1,36-36,33.1,33.1,0,0,1,8.3,1A3.9,3.9,0,0,1,123.3,129.8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M200,112a48.4,48.4,0,0,0-44.7,30.4l-3.3-.2c-17.6,0-32,14.8-32,32.9S134.4,208,152,208h48a48,48,0,0,0,0-96Zm0,80H152c-8.8,0-16-7.6-16-16.9s7.2-16.9,16-16.9a14.7,14.7,0,0,1,5.8,1.2,8.1,8.1,0,0,0,6.8-.4,7.6,7.6,0,0,0,4-5.4A32,32,0,1,1,200,192ZM24,160v16a8,8,0,0,1-16,0V160A104,104,0,0,1,184.5,85.4a8,8,0,1,1-11.2,11.5A88,88,0,0,0,24,160Zm32,0v16a8,8,0,0,1-16,0V160a72.1,72.1,0,0,1,72-72,70.9,70.9,0,0,1,42.6,14,8,8,0,1,1-9.5,12.8A56,56,0,0,0,56,160Zm61.6-23.4a26,26,0,0,0-5.6-.6,24.1,24.1,0,0,0-24,24v16a8,8,0,0,1-16,0V160a40,40,0,0,1,40-40,42.2,42.2,0,0,1,9.3,1.1,8,8,0,0,1-3.7,15.5Z" />
        </>
      )}
    </svg>
  );
}

export function PhRainbow(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M256,172v8a12,12,0,0,1-24,0v-8a104,104,0,0,0-208,0v8a12,12,0,0,1-24,0v-8a128,128,0,0,1,256,0ZM128,140a36,36,0,0,0-36,36v4a12,12,0,0,0,24,0v-4a12,12,0,0,1,24,0v4a12,12,0,0,0,24,0v-4A36,36,0,0,0,128,140Zm0-48a84.1,84.1,0,0,0-84,84v4a12,12,0,0,0,24,0v-4a60,60,0,0,1,120,0v4a12,12,0,0,0,24,0v-4A84.1,84.1,0,0,0,128,92Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M184,168v16a8,8,0,0,1-16,0V168a40,40,0,0,0-80,0v16a8,8,0,0,1-16,0V168a56,56,0,0,1,112,0ZM128,80a88.1,88.1,0,0,0-88,88v16a8,8,0,0,0,16,0V168a72,72,0,0,1,144,0v16a8,8,0,0,0,16,0V168A88.1,88.1,0,0,0,128,80Zm0-32A120.1,120.1,0,0,0,8,168v16a8,8,0,0,0,16,0V168a104,104,0,0,1,208,0v16a8,8,0,0,0,16,0V168A120.1,120.1,0,0,0,128,48Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M184,168v16a8,8,0,0,1-16,0V168a40,40,0,0,0-80,0v16a8,8,0,0,1-16,0V168a56,56,0,0,1,112,0ZM128,48A120.1,120.1,0,0,0,8,168v16a8,8,0,0,0,16,0V168a104,104,0,0,1,208,0v16a8,8,0,0,0,16,0V168A120.1,120.1,0,0,0,128,48Zm0,32a88.1,88.1,0,0,0-88,88v16a8,8,0,0,0,16,0V168a72,72,0,0,1,144,0v16a8,8,0,0,0,16,0V168A88.1,88.1,0,0,0,128,80Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M182,168v16a6,6,0,0,1-12,0V168a42,42,0,0,0-84,0v16a6,6,0,0,1-12,0V168a54,54,0,0,1,108,0ZM128,82a86.1,86.1,0,0,0-86,86v16a6,6,0,0,0,12,0V168a74,74,0,0,1,148,0v16a6,6,0,0,0,12,0V168A86.1,86.1,0,0,0,128,82Zm0-32A118.1,118.1,0,0,0,10,168v16a6,6,0,0,0,12,0V168a106,106,0,0,1,212,0v16a6,6,0,0,0,12,0V168A118.1,118.1,0,0,0,128,50Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M180,168v16a4,4,0,0,1-8,0V168a44,44,0,0,0-88,0v16a4,4,0,0,1-8,0V168a52,52,0,0,1,104,0ZM128,84a84.1,84.1,0,0,0-84,84v16a4,4,0,0,0,8,0V168a76,76,0,0,1,152,0v16a4,4,0,0,0,8,0V168A84.1,84.1,0,0,0,128,84Zm0-32A116.1,116.1,0,0,0,12,168v16a4,4,0,0,0,8,0V168a108,108,0,0,1,216,0v16a4,4,0,0,0,8,0V168A116.1,116.1,0,0,0,128,52Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M184,168v16a8,8,0,0,1-16,0V168a40,40,0,0,0-80,0v16a8,8,0,0,1-16,0V168a56,56,0,0,1,112,0ZM128,80a88.1,88.1,0,0,0-88,88v16a8,8,0,0,0,16,0V168a72,72,0,0,1,144,0v16a8,8,0,0,0,16,0V168A88.1,88.1,0,0,0,128,80Zm0-32A120.1,120.1,0,0,0,8,168v16a8,8,0,0,0,16,0V168a104,104,0,0,1,208,0v16a8,8,0,0,0,16,0V168A120.1,120.1,0,0,0,128,48Z" />
        </>
      )}
    </svg>
  );
}

export function PhReceipt(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M64,100A12,12,0,0,1,76,88H180a12,12,0,0,1,0,24H76A12,12,0,0,1,64,100Zm12,52H180a12,12,0,0,0,0-24H76a12,12,0,0,0,0,24ZM236,56V208a12,12,0,0,1-5.7,10.2,11.9,11.9,0,0,1-11.7.5L192,205.4l-26.6,13.3a11.9,11.9,0,0,1-10.8,0L128,205.4l-26.6,13.3a12.1,12.1,0,0,1-10.8,0L64,205.4,37.4,218.7a11.9,11.9,0,0,1-11.7-.5A12,12,0,0,1,20,208V56A20.1,20.1,0,0,1,40,36H216A20.1,20.1,0,0,1,236,56Zm-24,4H44V188.6l14.6-7.3a12.1,12.1,0,0,1,10.8,0L96,194.6l26.6-13.3a11.9,11.9,0,0,1,10.8,0L160,194.6l26.6-13.3a12.1,12.1,0,0,1,10.8,0l14.6,7.3Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M32,208V56a8,8,0,0,1,8-8H216a8,8,0,0,1,8,8V208l-32-16-32,16-32-16L96,208,64,192Z" />
          </g>
          <path d="M76,112H180a8,8,0,0,0,0-16H76a8,8,0,0,0,0,16Z" />
          <path d="M76,144H180a8,8,0,0,0,0-16H76a8,8,0,0,0,0,16Z" />
          <path d="M216,40H40A16,16,0,0,0,24,56V208a7.9,7.9,0,0,0,3.8,6.8,8,8,0,0,0,7.8.4L64,200.9l28.4,14.3a8.3,8.3,0,0,0,7.2,0L128,200.9l28.4,14.3a8.5,8.5,0,0,0,7.2,0L192,200.9l28.4,14.3a8,8,0,0,0,7.8-.4A7.9,7.9,0,0,0,232,208V56A16,16,0,0,0,216,40Zm0,155.1-20.4-10.3a8.3,8.3,0,0,0-7.2,0L160,199.1l-28.4-14.3a8.5,8.5,0,0,0-7.2,0L96,199.1,67.6,184.8a8.3,8.3,0,0,0-7.2,0L40,195.1V56H216Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,40H40A16,16,0,0,0,24,56V208a7.9,7.9,0,0,0,3.8,6.8,8,8,0,0,0,7.8.4L64,200.9l28.4,14.3a8.3,8.3,0,0,0,7.2,0L128,200.9l28.4,14.3a8.5,8.5,0,0,0,7.2,0L192,200.9l28.4,14.3a8,8,0,0,0,7.8-.4A7.9,7.9,0,0,0,232,208V56A16,16,0,0,0,216,40ZM180,144H76a8,8,0,0,1,0-16H180a8,8,0,0,1,0,16Zm0-32H76a8,8,0,0,1,0-16H180a8,8,0,0,1,0,16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M186,104a6,6,0,0,1-6,6H76a6,6,0,0,1,0-12H180A6,6,0,0,1,186,104Zm-6,26H76a6,6,0,0,0,0,12H180a6,6,0,0,0,0-12Zm50-74V208a6.1,6.1,0,0,1-2.8,5.1,6.2,6.2,0,0,1-3.2.9,6.6,6.6,0,0,1-2.7-.6L192,198.7l-29.3,14.7a6.4,6.4,0,0,1-5.4,0L128,198.7,98.7,213.4a6.4,6.4,0,0,1-5.4,0L64,198.7,34.7,213.4a6.2,6.2,0,0,1-5.9-.3A6.1,6.1,0,0,1,26,208V56A14,14,0,0,1,40,42H216A14,14,0,0,1,230,56Zm-12,0a2,2,0,0,0-2-2H40a2,2,0,0,0-2,2V198.3l23.3-11.7a6.4,6.4,0,0,1,5.4,0L96,201.3l29.3-14.7a6.4,6.4,0,0,1,5.4,0L160,201.3l29.3-14.7a6.4,6.4,0,0,1,5.4,0L218,198.3Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M184,104a4,4,0,0,1-4,4H76a4,4,0,0,1,0-8H180A4,4,0,0,1,184,104Zm-4,28H76a4,4,0,0,0,0,8H180a4,4,0,0,0,0-8Zm48-76V208a4,4,0,0,1-5.8,3.6L192,196.5l-30.2,15.1a4.5,4.5,0,0,1-3.6,0L128,196.5,97.8,211.6a4.5,4.5,0,0,1-3.6,0L64,196.5,33.8,211.6a4.1,4.1,0,0,1-3.9-.2A3.9,3.9,0,0,1,28,208V56A12,12,0,0,1,40,44H216A12,12,0,0,1,228,56Zm-8,0a4,4,0,0,0-4-4H40a4,4,0,0,0-4,4V201.5l26.2-13.1a4.5,4.5,0,0,1,3.6,0L96,203.5l30.2-15.1a4.2,4.2,0,0,1,3.6,0L160,203.5l30.2-15.1a4.5,4.5,0,0,1,3.6,0L220,201.5Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M68,104a8,8,0,0,1,8-8H180a8,8,0,0,1,0,16H76A8,8,0,0,1,68,104Zm8,40H180a8,8,0,0,0,0-16H76a8,8,0,0,0,0,16ZM232,56V208a7.9,7.9,0,0,1-3.8,6.8,8,8,0,0,1-7.8.4L192,200.9l-28.4,14.3a8.5,8.5,0,0,1-7.2,0L128,200.9,99.6,215.2a8.3,8.3,0,0,1-7.2,0L64,200.9,35.6,215.2a8,8,0,0,1-7.8-.4A7.9,7.9,0,0,1,24,208V56A16,16,0,0,1,40,40H216A16,16,0,0,1,232,56Zm-16,0H40V195.1l20.4-10.3a8.3,8.3,0,0,1,7.2,0L96,199.1l28.4-14.3a8.5,8.5,0,0,1,7.2,0L160,199.1l28.4-14.3a8.3,8.3,0,0,1,7.2,0L216,195.1Z" />
        </>
      )}
    </svg>
  );
}

export function PhRecord(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,20A108,108,0,1,0,236,128,108.1,108.1,0,0,0,128,20Zm0,192a84,84,0,1,1,84-84A84.1,84.1,0,0,1,128,212Zm0-152a68,68,0,1,0,68,68A68.1,68.1,0,0,0,128,60Zm0,112a44,44,0,1,1,44-44A44,44,0,0,1,128,172Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="128" cy="128" r="64" />
          </g>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Z" />
          <path d="M128,56a72,72,0,1,0,72,72A72.1,72.1,0,0,0,128,56Zm0,128a56,56,0,1,1,56-56A56,56,0,0,1,128,184Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm72-88a72,72,0,1,1-72-72A72.1,72.1,0,0,1,200,128Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,26A102,102,0,1,0,230,128,102.2,102.2,0,0,0,128,26Zm0,192a90,90,0,1,1,90-90A90.1,90.1,0,0,1,128,218Zm0-160a70,70,0,1,0,70,70A70.1,70.1,0,0,0,128,58Zm0,128a58,58,0,1,1,58-58A58,58,0,0,1,128,186Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,28A100,100,0,1,0,228,128,100.2,100.2,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm0-160a68,68,0,1,0,68,68A68.1,68.1,0,0,0,128,60Zm0,128a60,60,0,1,1,60-60A60,60,0,0,1,128,188Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm0-160a72,72,0,1,0,72,72A72.1,72.1,0,0,0,128,56Zm0,128a56,56,0,1,1,56-56A56,56,0,0,1,128,184Z" />
        </>
      )}
    </svg>
  );
}

export function PhRectangle(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216,220H40a20.1,20.1,0,0,1-20-20V56A20.1,20.1,0,0,1,40,36H216a20.1,20.1,0,0,1,20,20V200A20.1,20.1,0,0,1,216,220ZM44,196H212V60H44Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect x="32" y="48" width="192" height="160" rx="8" />
          </g>
          <path d="M216,216H40a16,16,0,0,1-16-16V56A16,16,0,0,1,40,40H216a16,16,0,0,1,16,16V200A16,16,0,0,1,216,216ZM40,56V200H216V56Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M232,56V200a16,16,0,0,1-16,16H40a16,16,0,0,1-16-16V56A16,16,0,0,1,40,40H216A16,16,0,0,1,232,56Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M216,214H40a14,14,0,0,1-14-14V56A14,14,0,0,1,40,42H216a14,14,0,0,1,14,14V200A14,14,0,0,1,216,214ZM40,54a2,2,0,0,0-2,2V200a2,2,0,0,0,2,2H216a2,2,0,0,0,2-2V56a2,2,0,0,0-2-2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M216,212H40a12,12,0,0,1-12-12V56A12,12,0,0,1,40,44H216a12,12,0,0,1,12,12V200A12,12,0,0,1,216,212ZM40,52a4,4,0,0,0-4,4V200a4,4,0,0,0,4,4H216a4,4,0,0,0,4-4V56a4,4,0,0,0-4-4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,216H40a16,16,0,0,1-16-16V56A16,16,0,0,1,40,40H216a16,16,0,0,1,16,16V200A16,16,0,0,1,216,216ZM40,56V200H216V56Z" />
        </>
      )}
    </svg>
  );
}

export function PhRecycle(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M240.2,206A27.5,27.5,0,0,1,216,220H157l3.5,3.5a12,12,0,0,1,0,17,12.1,12.1,0,0,1-17,0l-24-24a12.1,12.1,0,0,1,0-17l24-24a12,12,0,0,1,17,17L157,196h59a3.8,3.8,0,0,0,3.4-2,3.8,3.8,0,0,0,0-4l-19.9-34.5a12,12,0,1,1,20.8-12L240.2,178A27.5,27.5,0,0,1,240.2,206ZM128,36a4,4,0,0,1,3.5,2l29.4,50.9-4.7-1.3a12,12,0,1,0-6.2,23.2l32.7,8.8a11.5,11.5,0,0,0,3.1.4,12.1,12.1,0,0,0,11.6-8.9l8.8-32.8A12,12,0,0,0,183,72.1l-1.3,4.8L152.2,26a27.9,27.9,0,0,0-48.4,0l-20,34.4a12,12,0,0,0,20.8,12.1L124.5,38A4,4,0,0,1,128,36ZM80,196H40a3.8,3.8,0,0,1-3.4-2,3.8,3.8,0,0,1,0-4l29.5-51,1.3,4.9a12,12,0,0,0,11.5,8.9,17.5,17.5,0,0,0,3.2-.4,11.9,11.9,0,0,0,8.4-14.7l-8.7-32.8a12.5,12.5,0,0,0-5.6-7.3,12.1,12.1,0,0,0-9.1-1.2l-32.8,8.8a12,12,0,0,0,6.2,23.2l4.7-1.3L15.8,178A28,28,0,0,0,40,220H80a12,12,0,0,0,0-24Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M236.8,204A23.9,23.9,0,0,1,216,216H147.3l10.4,10.3a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0l-24-24.1A7.9,7.9,0,0,1,120,208a8.1,8.1,0,0,1,2.3-5.7l24-24a8.1,8.1,0,0,1,11.4,11.4L147.3,200H216a7.9,7.9,0,0,0,6.9-4,7.8,7.8,0,0,0,0-8l-24-41.4a8,8,0,0,1,13.9-8L236.7,180A23.7,23.7,0,0,1,236.8,204ZM88,200H40a7.9,7.9,0,0,1-6.9-4,7.8,7.8,0,0,1,0-8l34.3-59.3,3.8,14.1a8.1,8.1,0,0,0,7.8,6l2-.3a8,8,0,0,0,5.7-9.8l-8.8-32.8a8.1,8.1,0,0,0-3.7-4.8,7.7,7.7,0,0,0-6.1-.8L35.3,109a8,8,0,0,0,4.2,15.5l14-3.8L19.3,180A23.9,23.9,0,0,0,40,216H88a8,8,0,0,0,0-16ZM128,32a7.8,7.8,0,0,1,6.9,4l34.3,59.2-14.1-3.7a8,8,0,1,0-4.1,15.4l32.8,8.8,2,.3a8.1,8.1,0,0,0,7.8-5.9l8.8-32.8a8,8,0,0,0-15.5-4.2l-3.8,14.2L148.8,28a24,24,0,0,0-41.6,0L83.3,69.4a8,8,0,1,0,13.8,8l24-41.4A7.8,7.8,0,0,1,128,32Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M236.8,204A23.9,23.9,0,0,1,216,216H160v16a8,8,0,0,1-4.9,7.4,8.5,8.5,0,0,1-3.1.6,8.3,8.3,0,0,1-5.7-2.3l-24-24.1A7.9,7.9,0,0,1,120,208a8.1,8.1,0,0,1,2.3-5.7l24-24a8.4,8.4,0,0,1,8.8-1.7A8,8,0,0,1,160,184v16h56a7.9,7.9,0,0,0,6.9-4,7.8,7.8,0,0,0,0-8l-24-41.4a8,8,0,0,1,13.9-8L236.7,180A23.7,23.7,0,0,1,236.8,204ZM88,200H40a7.9,7.9,0,0,1-6.9-4,7.8,7.8,0,0,1,0-8l28-48.3,13.9,8a8,8,0,0,0,4,1.1,7.8,7.8,0,0,0,4.8-1.7,8,8,0,0,0,2.9-8.4l-8.8-32.8a8.1,8.1,0,0,0-3.7-4.8,7.7,7.7,0,0,0-6.1-.8L35.3,109a8,8,0,0,0-5.8,6.7,7.9,7.9,0,0,0,3.9,8l13.8,8L19.3,180A23.9,23.9,0,0,0,40,216H88a8,8,0,0,0,0-16ZM128,32a7.8,7.8,0,0,1,6.9,4l28,48.3-13.8,8a7.9,7.9,0,0,0,1.9,14.6l32.8,8.8,2,.3a8.1,8.1,0,0,0,7.8-5.9l8.8-32.8a8.1,8.1,0,0,0-2.9-8.5,8,8,0,0,0-8.9-.5l-13.9,8L148.8,28a24,24,0,0,0-41.6,0L83.3,69.4a8,8,0,1,0,13.8,8l24-41.4A7.8,7.8,0,0,1,128,32Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M235,203a21.6,21.6,0,0,1-19,11H142.5l13.7,13.8a5.9,5.9,0,1,1-8.4,8.4l-24-24A5.6,5.6,0,0,1,122,208a6,6,0,0,1,1.8-4.3l24-23.9a5.9,5.9,0,0,1,8.4,8.4L142.5,202H216a10,10,0,0,0,8.6-15l-23.9-41.4a6,6,0,1,1,10.4-6L235,181A21.7,21.7,0,0,1,235,203ZM88,202H40a10,10,0,0,1-8.6-15l36.7-63.5,5.1,18.8a6,6,0,0,0,5.8,4.5l1.5-.2a6.2,6.2,0,0,0,4.3-7.4L76,106.4a6.7,6.7,0,0,0-2.8-3.6,6.2,6.2,0,0,0-4.6-.6L35.8,111a5.9,5.9,0,0,0-4.2,7.3,6.1,6.1,0,0,0,7.3,4.3l18.8-5.1L21,181a22,22,0,0,0,19,33H88a6,6,0,0,0,0-12ZM136.7,35l36.7,63.4-18.8-5a5.9,5.9,0,0,0-7.3,4.2,6,6,0,0,0,4.2,7.4l32.8,8.8,1.5.2a6,6,0,0,0,5.8-4.5l8.8-32.8a5.9,5.9,0,0,0-4.2-7.3,6,6,0,0,0-7.4,4.2l-5,18.9L147,29a21.9,21.9,0,0,0-38,0L85,70.4a6,6,0,0,0,2.2,8.2,6.1,6.1,0,0,0,8.2-2.2L119.3,35a10.1,10.1,0,0,1,17.4,0Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M233.3,202A19.9,19.9,0,0,1,216,212H137.7l17.1,17.2a3.9,3.9,0,0,1,0,5.6,3.9,3.9,0,0,1-5.6,0l-24-24a3.9,3.9,0,0,1,0-5.6l24-24a4,4,0,0,1,5.6,5.6L137.7,204H216a12.1,12.1,0,0,0,10.4-6,12.2,12.2,0,0,0,0-12l-24-41.4a4.1,4.1,0,0,1,1.5-5.5,4,4,0,0,1,5.4,1.5l24,41.4A19.9,19.9,0,0,1,233.3,202ZM88,204H40a12.1,12.1,0,0,1-10.4-6,12.2,12.2,0,0,1,0-12l39.2-67.7,6.3,23.5a4.1,4.1,0,0,0,3.9,3l1-.2a3.9,3.9,0,0,0,2.8-4.9L74,106.9a3.6,3.6,0,0,0-1.8-2.4,4.2,4.2,0,0,0-3.1-.4l-32.7,8.8a4.1,4.1,0,0,0-2.9,4.9,4,4,0,0,0,4.9,2.8l23.5-6.3L22.7,182A20,20,0,0,0,40,212H88a4,4,0,0,0,0-8ZM138.4,34l39.1,67.6-23.4-6.3a4.1,4.1,0,0,0-4.9,2.9,4,4,0,0,0,2.8,4.9l32.8,8.7,1,.2a4.1,4.1,0,0,0,3.9-3l8.8-32.8a4,4,0,1,0-7.7-2l-6.3,23.4L145.3,30a20,20,0,0,0-34.6,0l-24,41.4a4,4,0,0,0,1.5,5.4,4,4,0,0,0,5.5-1.4L117.6,34a12,12,0,0,1,20.8,0Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M236.8,204A23.9,23.9,0,0,1,216,216H147.3l10.4,10.3a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0l-24-24.1A7.9,7.9,0,0,1,120,208a8.1,8.1,0,0,1,2.3-5.7l24-24a8.1,8.1,0,0,1,11.4,11.4L147.3,200H216a7.9,7.9,0,0,0,6.9-4,7.8,7.8,0,0,0,0-8l-24-41.4a8,8,0,0,1,13.9-8L236.7,180A23.7,23.7,0,0,1,236.8,204ZM88,200H40a7.9,7.9,0,0,1-6.9-4,7.8,7.8,0,0,1,0-8l34.3-59.3,3.8,14.1a8.1,8.1,0,0,0,7.8,6l2-.3a8,8,0,0,0,5.7-9.8l-8.8-32.8a8.1,8.1,0,0,0-3.7-4.8,7.7,7.7,0,0,0-6.1-.8L35.3,109a8,8,0,0,0,4.2,15.5l14-3.8L19.3,180A23.9,23.9,0,0,0,40,216H88a8,8,0,0,0,0-16ZM128,32a7.8,7.8,0,0,1,6.9,4l34.3,59.2-14.1-3.7a8,8,0,1,0-4.1,15.4l32.8,8.8,2,.3a8.1,8.1,0,0,0,7.8-5.9l8.8-32.8a8,8,0,0,0-15.5-4.2l-3.8,14.2L148.8,28a24,24,0,0,0-41.6,0L83.3,69.4a8,8,0,1,0,13.8,8l24-41.4A7.8,7.8,0,0,1,128,32Z" />
        </>
      )}
    </svg>
  );
}

export function PhRedditLogo(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M88,148a16,16,0,1,1,16-16A16,16,0,0,1,88,148Zm80-32a16,16,0,1,0,16,16A16,16,0,0,0,168,116Zm-12.5,45.3a60,60,0,0,1-55,0,12,12,0,1,0-11,21.4,84.3,84.3,0,0,0,77,0,12,12,0,0,0-11-21.4Zm86.6-32.5A60.3,60.3,0,0,1,244,144c0,23.3-12.7,44.9-35.6,60.8S158.2,228,128,228s-58.7-8.2-80.4-23.2S12,167.3,12,144a60.3,60.3,0,0,1,1.9-15.2A36,36,0,0,1,40,68a35.5,35.5,0,0,1,21.4,7.1C78,66.7,97.5,61.6,117.8,60.3L124.2,22a11.9,11.9,0,0,1,13.6-9.9L169.3,17A23.8,23.8,0,0,1,188,8a24,24,0,1,1-22.4,32.7l-19.7-3-3.8,22.9c18.9,1.7,36.9,6.6,52.5,14.5A35.5,35.5,0,0,1,216,68a36,36,0,0,1,26.1,60.8ZM228,104a12,12,0,0,0-21.7-7.1,11.9,11.9,0,0,1-15.9,3.2h-.3C173.1,89.7,151,84,128,84h0c-23,0-45.1,5.7-62.1,16h-.3a11.9,11.9,0,0,1-15.9-3.2A12,12,0,0,0,28,104a11.7,11.7,0,0,0,5.9,10.3,12,12,0,0,1,4.9,15.1A40.7,40.7,0,0,0,36,144c0,15.2,9,29.8,25.3,41s41.3,19,66.7,19,49.1-6.7,66.7-19S220,159.2,220,144a39.7,39.7,0,0,0-2.9-14.7,12.1,12.1,0,0,1,5-15A11.7,11.7,0,0,0,228,104Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M240,104a24,24,0,0,0-43.4-14.1h0C178.3,78.7,154.3,72,128,72S77.7,78.7,59.4,89.9h0a24,24,0,1,0-31.6,34.8h0A50.7,50.7,0,0,0,24,144c0,39.8,46.6,72,104,72s104-32.2,104-72a50.7,50.7,0,0,0-3.8-19.3h0A24,24,0,0,0,240,104Z" />
          </g>
          <path d="M88,148a16,16,0,1,1,16-16A16,16,0,0,1,88,148Zm80-32a16,16,0,1,0,16,16A16,16,0,0,0,168,116Zm-10.6,52.9a64.2,64.2,0,0,1-58.8,0,8,8,0,0,0-7.3,14.2,80.1,80.1,0,0,0,73.4,0,8,8,0,0,0-7.3-14.2Zm80.3-41.3A60.1,60.1,0,0,1,240,144c0,21.9-12,42.4-33.9,57.5S157.4,224,128,224s-57.1-8-78.1-22.5S16,165.9,16,144a61.4,61.4,0,0,1,2.3-16.5A32,32,0,0,1,60.9,79.8c17.5-9.3,38.5-14.7,60.3-15.6l6.9-41.5a8,8,0,0,1,9.1-6.6l29.7,4.6A23.8,23.8,0,0,1,188,8a24,24,0,1,1-23.6,28.5l-21.8-3.4-5.2,31.2c20.9,1.2,40.9,6.6,57.7,15.5A32,32,0,0,1,248,104,32.4,32.4,0,0,1,237.7,127.6ZM180,32a8,8,0,1,0,8-8A8,8,0,0,0,180,32Zm52,72a16,16,0,0,0-28.9-9.4,8,8,0,0,1-10.7,2.1h-.2C174.5,85.9,151.8,80,128,80h0c-23.7,0-46.5,5.9-64.2,16.6h-.2a8,8,0,0,1-10.7-2.1,16,16,0,1,0-21,23.2,7.9,7.9,0,0,1,3.2,10A45.7,45.7,0,0,0,32,144c0,16.5,9.6,32.3,27,44.3S101.8,208,128,208s50.7-7,69-19.7,27-27.8,27-44.3a43.7,43.7,0,0,0-3.2-16.2,8.1,8.1,0,0,1,3.3-10A16.1,16.1,0,0,0,232,104Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M248,104a32,32,0,0,0-52.9-24.2c-16.8-8.9-36.8-14.3-57.7-15.5l5.2-31.2,21.8,3.4a24.2,24.2,0,1,0,2.5-15.8l-29.7-4.6a8,8,0,0,0-9.1,6.6l-6.9,41.5c-21.8.9-42.8,6.3-60.3,15.6a32,32,0,0,0-42.6,47.7A61.4,61.4,0,0,0,16,144c0,21.9,12,42.4,33.9,57.5S98.6,224,128,224s57.1-8,78.1-22.5S240,165.9,240,144a60.1,60.1,0,0,0-2.3-16.4A32.4,32.4,0,0,0,248,104ZM72,132a16,16,0,1,1,16,16A16,16,0,0,1,72,132Zm92.7,51.1a80.1,80.1,0,0,1-73.4,0,8,8,0,0,1,7.3-14.2,64.2,64.2,0,0,0,58.8,0,8,8,0,0,1,7.3,14.2ZM168,148a16,16,0,1,1,16-16A16,16,0,0,1,168,148Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M88,146a14,14,0,1,1,14-14A14,14,0,0,1,88,146Zm80-28a14,14,0,1,0,14,14A14,14,0,0,0,168,118Zm-9.7,52.7a66.1,66.1,0,0,1-60.6,0,6,6,0,0,0-8.1,2.5,6.1,6.1,0,0,0,2.6,8.1,78,78,0,0,0,71.6,0,6.1,6.1,0,0,0,2.6-8.1A6,6,0,0,0,158.3,170.7Zm77.1-43.8A58.6,58.6,0,0,1,238,144c0,21.3-11.7,41.1-33,55.8S157,222,128,222s-56.3-7.9-77-22.2S18,165.3,18,144a58.6,58.6,0,0,1,2.6-17.1,30,30,0,1,1,40-44.7c17.9-9.7,39.7-15.4,62.3-16.1L130.1,23a6,6,0,0,1,6.8-4.9L168,22.9a22,22,0,1,1-1.8,11.8l-25.3-3.9-5.8,35.4c21.9,1,42.9,6.6,60.3,16a30,30,0,1,1,40,44.7ZM178,32a10,10,0,1,0,10-10A10,10,0,0,0,178,32Zm56,72a18.1,18.1,0,0,0-18-18,18.3,18.3,0,0,0-14.6,7.4,6,6,0,0,1-7.9,1.6h-.2C175.3,84,152.1,78,128,78h0c-24.1,0-47.3,6-65.3,16.9l-.3.2a6,6,0,0,1-7.8-1.7A18.3,18.3,0,0,0,40,86a18,18,0,0,0-9.1,33.5,6.1,6.1,0,0,1,2.4,7.6A45.4,45.4,0,0,0,30,144c0,36.4,44,66,98,66s98-29.6,98-66a45.5,45.5,0,0,0-3.3-17,5.9,5.9,0,0,1,2.5-7.5A18.2,18.2,0,0,0,234,104Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M88,144a12,12,0,1,1,12-12A12,12,0,0,1,88,144Zm80-24a12,12,0,1,0,12,12A12,12,0,0,0,168,120Zm-8.8,52.4a68,68,0,0,1-62.4,0,4,4,0,0,0-5.4,1.8,4.1,4.1,0,0,0,1.7,5.4,76.7,76.7,0,0,0,69.8,0,4.1,4.1,0,0,0,1.7-5.4A4,4,0,0,0,159.2,172.4ZM233,126.2a54.5,54.5,0,0,1,3,17.8c0,20.6-11.4,39.8-32.2,54.2S156.6,220,128,220s-55.5-7.7-75.8-21.8S20,164.6,20,144a54.5,54.5,0,0,1,3-17.8A28,28,0,0,1,40,76a28.2,28.2,0,0,1,20.3,8.7c18.2-10.3,40.8-16.1,64.3-16.6l7.5-44.8a4,4,0,0,1,4.5-3.3l32.7,5.1A19.8,19.8,0,1,1,168,33l-28.7-4.4-6.6,39.5c23,.7,45.1,6.5,63,16.6A28,28,0,0,1,244,104,27.9,27.9,0,0,1,233,126.2ZM176,32a12,12,0,1,0,12-12A12,12,0,0,0,176,32Zm50.2,89.2a20,20,0,1,0-26.4-29,4,4,0,0,1-5.2,1.2l-.2-.2C176.1,82.1,152.5,76,128,76h0c-24.5,0-48.1,6.1-66.4,17.2l-.2.2a4,4,0,0,1-5.2-1.2A20.3,20.3,0,0,0,40,84a20,20,0,0,0-10.2,37.2,4.1,4.1,0,0,1,1.7,5.1A47.3,47.3,0,0,0,28,144c0,37.5,44.9,68,100,68s100-30.5,100-68a47.3,47.3,0,0,0-3.5-17.7A4.1,4.1,0,0,1,226.2,121.2Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M88,148a16,16,0,1,1,16-16A16,16,0,0,1,88,148Zm80-32a16,16,0,1,0,16,16A16,16,0,0,0,168,116Zm-10.6,52.9a64.2,64.2,0,0,1-58.8,0,8,8,0,0,0-7.3,14.2,80.1,80.1,0,0,0,73.4,0,8,8,0,0,0-7.3-14.2Zm80.3-41.4A61.4,61.4,0,0,1,240,144c0,21.9-12,42.4-33.9,57.5S157.4,224,128,224s-57.1-8-78.1-22.5S16,165.9,16,144a61.4,61.4,0,0,1,2.3-16.5A32,32,0,0,1,60.9,79.8c17.5-9.3,38.5-14.7,60.3-15.6l6.9-41.5a8,8,0,0,1,9.1-6.6l29.7,4.6A23.8,23.8,0,0,1,188,8a24,24,0,1,1-23.6,28.5l-21.8-3.4-5.2,31.2c20.9,1.2,40.9,6.6,57.7,15.5a32,32,0,0,1,42.6,47.7ZM180,32a8,8,0,1,0,8-8A8,8,0,0,0,180,32Zm52,72a16,16,0,0,0-28.9-9.4,8.1,8.1,0,0,1-10.4,2.3l-.5-.3C174.6,85.9,151.8,80,128,80h0c-23.7,0-46.5,5.9-64.2,16.6l-.4.2a8,8,0,0,1-10.5-2.2,16,16,0,1,0-21,23.2,7.9,7.9,0,0,1,3.2,10A45.7,45.7,0,0,0,32,144c0,16.5,9.6,32.3,27,44.3S101.8,208,128,208s50.7-7,69-19.7,27-27.8,27-44.3a43.7,43.7,0,0,0-3.2-16.2,8.1,8.1,0,0,1,3.3-10A16.1,16.1,0,0,0,232,104Z" />
        </>
      )}
    </svg>
  );
}

export function PhRepeatOnce(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M20,128A76.1,76.1,0,0,1,96,52h99l-3.5-3.5a12,12,0,0,1,17-17l24,24a12,12,0,0,1,0,17l-24,24a12.1,12.1,0,0,1-17,0,12,12,0,0,1,0-17L195,76H96a52,52,0,0,0-52,52,12,12,0,0,1-24,0Zm204-12a12,12,0,0,0-12,12,52,52,0,0,1-52,52H61l3.5-3.5a12,12,0,0,0-17-17l-24,24a12,12,0,0,0,0,17l24,24a12.1,12.1,0,0,0,17,0,12,12,0,0,0,0-17L61,204h99a76.1,76.1,0,0,0,76-76A12,12,0,0,0,224,116Zm-92,48a12,12,0,0,0,12-12V104a12,12,0,0,0-5.7-10.2,11.9,11.9,0,0,0-11.7-.5l-16,8a12,12,0,0,0,9.4,22V152A12,12,0,0,0,132,164Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M24,128A72.1,72.1,0,0,1,96,56H204.7L194.3,45.7a8.1,8.1,0,0,1,11.4-11.4l24,24a8.1,8.1,0,0,1,0,11.4l-24,24a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L204.7,72H96a56,56,0,0,0-56,56,8,8,0,0,1-16,0Zm200-8a8,8,0,0,0-8,8,56,56,0,0,1-56,56H51.3l10.4-10.3a8.1,8.1,0,0,0-11.4-11.4l-24,24a8.1,8.1,0,0,0,0,11.4l24,24a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4L51.3,200H160a72.1,72.1,0,0,0,72-72A8,8,0,0,0,224,120Zm-92,40a8,8,0,0,0,8-8V104a7.9,7.9,0,0,0-3.8-6.8,8,8,0,0,0-7.8-.4l-16,8a8.2,8.2,0,0,0-3.6,10.8,8.1,8.1,0,0,0,10.8,3.5l4.4-2.2V152A8,8,0,0,0,132,160Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M232,128a72.1,72.1,0,0,1-72,72H64v16a8,8,0,0,1-4.9,7.4,8.5,8.5,0,0,1-3.1.6,8.3,8.3,0,0,1-5.7-2.3l-24-24a8.1,8.1,0,0,1,0-11.4l24-24a8.4,8.4,0,0,1,8.8-1.7A8,8,0,0,1,64,168v16h96a56,56,0,0,0,56-56,8,8,0,0,1,16,0ZM32,136a8,8,0,0,0,8-8A56,56,0,0,1,96,72h96V88a8,8,0,0,0,4.9,7.4,8.5,8.5,0,0,0,3.1.6,8.3,8.3,0,0,0,5.7-2.3l24-24a8.1,8.1,0,0,0,0-11.4l-24-24a8.4,8.4,0,0,0-8.8-1.7A8,8,0,0,0,192,40V56H96a72.1,72.1,0,0,0-72,72A8,8,0,0,0,32,136Zm100,24a8,8,0,0,0,8-8V104a7.9,7.9,0,0,0-3.8-6.8,8,8,0,0,0-7.8-.4l-16,8a8.2,8.2,0,0,0-3.6,10.8,8.1,8.1,0,0,0,10.8,3.5l4.4-2.2V152A8,8,0,0,0,132,160Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M26,128A70.1,70.1,0,0,1,96,58H209.5L195.8,44.2a5.9,5.9,0,0,1,8.4-8.4l24,24a5.8,5.8,0,0,1,0,8.4l-24,24a5.9,5.9,0,0,1-8.4-8.4L209.5,70H96a58,58,0,0,0-58,58,6,6,0,0,1-12,0Zm198-6a6,6,0,0,0-6,6,58,58,0,0,1-58,58H46.5l13.7-13.8a5.9,5.9,0,0,0-8.4-8.4l-24,24a5.8,5.8,0,0,0,0,8.4l24,24a5.9,5.9,0,0,0,8.4-8.4L46.5,198H160a70.1,70.1,0,0,0,70-70A6,6,0,0,0,224,122Zm-92,36a6,6,0,0,0,6-6V104a6.1,6.1,0,0,0-2.8-5.1,6.2,6.2,0,0,0-5.9-.3l-16,8a6,6,0,0,0,5.4,10.8l7.3-3.7V152A6,6,0,0,0,132,158Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M28,128A68.1,68.1,0,0,1,96,60H214.3L197.2,42.8a4,4,0,0,1,5.6-5.6l24,24a3.9,3.9,0,0,1,0,5.6l-24,24a3.9,3.9,0,0,1-5.6,0,3.9,3.9,0,0,1,0-5.6L214.3,68H96a60,60,0,0,0-60,60,4,4,0,0,1-8,0Zm196-4a4,4,0,0,0-4,4,60,60,0,0,1-60,60H41.7l17.1-17.2a4,4,0,0,0-5.6-5.6l-24,24a3.9,3.9,0,0,0,0,5.6l24,24a3.9,3.9,0,0,0,5.6,0,3.9,3.9,0,0,0,0-5.6L41.7,196H160a68.1,68.1,0,0,0,68-68A4,4,0,0,0,224,124Zm-92,32a4,4,0,0,0,4-4V104a3.9,3.9,0,0,0-1.9-3.4,4.1,4.1,0,0,0-3.9-.2l-16,8a4,4,0,0,0,3.6,7.2l10.2-5.1V152A4,4,0,0,0,132,156Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M24,128A72.1,72.1,0,0,1,96,56H204.7L194.3,45.7a8.1,8.1,0,0,1,11.4-11.4l24,24a8.1,8.1,0,0,1,0,11.4l-24,24a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L204.7,72H96a56,56,0,0,0-56,56,8,8,0,0,1-16,0Zm200-8a8,8,0,0,0-8,8,56,56,0,0,1-56,56H51.3l10.4-10.3a8.1,8.1,0,0,0-11.4-11.4l-24,24a8.1,8.1,0,0,0,0,11.4l24,24a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4L51.3,200H160a72.1,72.1,0,0,0,72-72A8,8,0,0,0,224,120Zm-92,40a8,8,0,0,0,8-8V104a7.9,7.9,0,0,0-3.8-6.8,8,8,0,0,0-7.8-.4l-16,8a8.2,8.2,0,0,0-3.6,10.8,8.1,8.1,0,0,0,10.8,3.5l4.4-2.2V152A8,8,0,0,0,132,160Z" />
        </>
      )}
    </svg>
  );
}

export function PhRepeat(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M20,128A76.1,76.1,0,0,1,96,52h99l-3.5-3.5a12,12,0,0,1,17-17l24,24a12,12,0,0,1,0,17l-24,24a12.1,12.1,0,0,1-17,0,12,12,0,0,1,0-17L195,76H96a52,52,0,0,0-52,52,12,12,0,0,1-24,0Zm204-12a12,12,0,0,0-12,12,52,52,0,0,1-52,52H61l3.5-3.5a12,12,0,0,0-17-17l-24,24a12,12,0,0,0,0,17l24,24a12.1,12.1,0,0,0,17,0,12,12,0,0,0,0-17L61,204h99a76.1,76.1,0,0,0,76-76A12,12,0,0,0,224,116Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M24,128A72.1,72.1,0,0,1,96,56H204.7L194.3,45.7a8.1,8.1,0,0,1,11.4-11.4l24,24a8.1,8.1,0,0,1,0,11.4l-24,24a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L204.7,72H96a56,56,0,0,0-56,56,8,8,0,0,1-16,0Zm200-8a8,8,0,0,0-8,8,56,56,0,0,1-56,56H51.3l10.4-10.3a8.1,8.1,0,0,0-11.4-11.4l-24,24a8.1,8.1,0,0,0,0,11.4l24,24a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4L51.3,200H160a72.1,72.1,0,0,0,72-72A8,8,0,0,0,224,120Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M24,128A72.1,72.1,0,0,1,96,56h96V40a8,8,0,0,1,4.9-7.4,8.4,8.4,0,0,1,8.8,1.7l24,24a8.1,8.1,0,0,1,0,11.4l-24,24A8.3,8.3,0,0,1,200,96a8.5,8.5,0,0,1-3.1-.6A8,8,0,0,1,192,88V72H96a56,56,0,0,0-56,56,8,8,0,0,1-16,0Zm200-8a8,8,0,0,0-8,8,56,56,0,0,1-56,56H64V168a8,8,0,0,0-4.9-7.4,8.4,8.4,0,0,0-8.8,1.7l-24,24a8.1,8.1,0,0,0,0,11.4l24,24A8.3,8.3,0,0,0,56,224a8.5,8.5,0,0,0,3.1-.6A8,8,0,0,0,64,216V200h96a72.1,72.1,0,0,0,72-72A8,8,0,0,0,224,120Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M26,128A70.1,70.1,0,0,1,96,58H209.5L195.8,44.2a5.9,5.9,0,0,1,8.4-8.4l24,24a5.8,5.8,0,0,1,0,8.4l-24,24a5.9,5.9,0,0,1-8.4-8.4L209.5,70H96a58,58,0,0,0-58,58,6,6,0,0,1-12,0Zm198-6a6,6,0,0,0-6,6,58,58,0,0,1-58,58H46.5l13.7-13.8a5.9,5.9,0,0,0-8.4-8.4l-24,24a5.8,5.8,0,0,0,0,8.4l24,24a5.9,5.9,0,0,0,8.4-8.4L46.5,198H160a70.1,70.1,0,0,0,70-70A6,6,0,0,0,224,122Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M28,128A68.1,68.1,0,0,1,96,60H214.3L197.2,42.8a4,4,0,0,1,5.6-5.6l24,24a3.9,3.9,0,0,1,0,5.6l-24,24a3.9,3.9,0,0,1-5.6,0,3.9,3.9,0,0,1,0-5.6L214.3,68H96a60,60,0,0,0-60,60,4,4,0,0,1-8,0Zm196-4a4,4,0,0,0-4,4,60,60,0,0,1-60,60H41.7l17.1-17.2a4,4,0,0,0-5.6-5.6l-24,24a3.9,3.9,0,0,0,0,5.6l24,24a3.9,3.9,0,0,0,5.6,0,3.9,3.9,0,0,0,0-5.6L41.7,196H160a68.1,68.1,0,0,0,68-68A4,4,0,0,0,224,124Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M24,128A72.1,72.1,0,0,1,96,56H204.7L194.3,45.7a8.1,8.1,0,0,1,11.4-11.4l24,24a8.1,8.1,0,0,1,0,11.4l-24,24a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L204.7,72H96a56,56,0,0,0-56,56,8,8,0,0,1-16,0Zm200-8a8,8,0,0,0-8,8,56,56,0,0,1-56,56H51.3l10.4-10.3a8.1,8.1,0,0,0-11.4-11.4l-24,24a8.1,8.1,0,0,0,0,11.4l24,24a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4L51.3,200H160a72.1,72.1,0,0,0,72-72A8,8,0,0,0,224,120Z" />
        </>
      )}
    </svg>
  );
}

export function PhRewindCircle(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,20A108,108,0,1,0,236,128,108.1,108.1,0,0,0,128,20Zm0,192a84,84,0,1,1,84-84A84.1,84.1,0,0,1,128,212Zm52-112v56a12,12,0,0,1-6.7,10.8A12.4,12.4,0,0,1,168,168a12.7,12.7,0,0,1-7.4-2.5l-36-28-.6-.6V156a12,12,0,0,1-6.7,10.8A12.4,12.4,0,0,1,112,168a12.7,12.7,0,0,1-7.4-2.5l-36-28a12.1,12.1,0,0,1,0-19l36-28a12.2,12.2,0,0,1,12.7-1.3A12,12,0,0,1,124,100v19.1l.6-.6,36-28a12.2,12.2,0,0,1,12.7-1.3A12,12,0,0,1,180,100Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M128,32a96,96,0,1,0,96,96A96,96,0,0,0,128,32ZM112,160,64,128l48-32Zm64,0-48-32,48-32Z" />
          </g>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Z" />
          <path d="M179.8,88.9a8.3,8.3,0,0,0-8.2.4l-48,32a8,8,0,0,0,0,13.4l48,32A8.4,8.4,0,0,0,176,168a8.5,8.5,0,0,0,3.8-.9A8,8,0,0,0,184,160V96A8,8,0,0,0,179.8,88.9ZM168,145.1,142.4,128,168,110.9Z" />
          <path d="M115.8,88.9a8.3,8.3,0,0,0-8.2.4l-48,32a8,8,0,0,0,0,13.4l48,32A8.4,8.4,0,0,0,112,168a8.5,8.5,0,0,0,3.8-.9A8,8,0,0,0,120,160V96A8,8,0,0,0,115.8,88.9ZM104,145.1,78.4,128,104,110.9Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M24,128A104,104,0,1,0,128,24,104.2,104.2,0,0,0,24,128Zm44,0a8.2,8.2,0,0,1,3.4-6.6l40-28a8,8,0,0,1,8.3-.5A8.1,8.1,0,0,1,124,100v28a8.2,8.2,0,0,1,3.4-6.6l40-28a8,8,0,0,1,8.3-.5A8.1,8.1,0,0,1,180,100v56a8,8,0,0,1-8,8,8.6,8.6,0,0,1-4.6-1.4l-40-28A8.2,8.2,0,0,1,124,128v28a8,8,0,0,1-8,8,8.6,8.6,0,0,1-4.6-1.4l-40-28A8.2,8.2,0,0,1,68,128Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,26A102,102,0,1,0,230,128,102.2,102.2,0,0,0,128,26Zm0,192a90,90,0,1,1,90-90A90.1,90.1,0,0,1,128,218ZM174.8,90.7a6.1,6.1,0,0,0-6.1.3L122,122.1V96a6,6,0,0,0-9.3-5l-48,32a6,6,0,0,0,0,10l48,32a5.9,5.9,0,0,0,6.1.3A6,6,0,0,0,122,160V133.9L168.7,165a5.9,5.9,0,0,0,6.1.3A6,6,0,0,0,178,160V96A6,6,0,0,0,174.8,90.7ZM110,148.8,78.8,128,110,107.2Zm56,0L134.8,128,166,107.2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,28A100,100,0,1,0,228,128,100.2,100.2,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220ZM173.9,92.5a4,4,0,0,0-4.1.2l-48,32a3.9,3.9,0,0,0,0,6.6l48,32a3.6,3.6,0,0,0,2.2.7,4.5,4.5,0,0,0,1.9-.5A3.9,3.9,0,0,0,176,160V96A3.9,3.9,0,0,0,173.9,92.5Zm-5.9,60L131.2,128,168,103.5Zm-50.1-60a4,4,0,0,0-4.1.2l-48,32a3.9,3.9,0,0,0,0,6.6l48,32a3.6,3.6,0,0,0,2.2.7,4.5,4.5,0,0,0,1.9-.5A3.9,3.9,0,0,0,120,160V96A3.9,3.9,0,0,0,117.9,92.5Zm-5.9,60L75.2,128,112,103.5Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216ZM179.8,88.9a8.3,8.3,0,0,0-8.2.4l-48,32a8,8,0,0,0,0,13.4l48,32A8.4,8.4,0,0,0,176,168a8.5,8.5,0,0,0,3.8-.9A8,8,0,0,0,184,160V96A8,8,0,0,0,179.8,88.9ZM168,145.1,142.4,128,168,110.9ZM115.8,88.9a8.3,8.3,0,0,0-8.2.4l-48,32a8,8,0,0,0,0,13.4l48,32A8.4,8.4,0,0,0,112,168a8.5,8.5,0,0,0,3.8-.9A8,8,0,0,0,120,160V96A8,8,0,0,0,115.8,88.9ZM104,145.1,78.4,128,104,110.9Z" />
        </>
      )}
    </svg>
  );
}

export function PhRewind(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M229.6,53.1a20.1,20.1,0,0,0-20.4.7L132,103.4V70.7a20,20,0,0,0-30.8-16.9L12,111.2a19.9,19.9,0,0,0,0,33.6l89.2,57.4a19.9,19.9,0,0,0,10.8,3.1,20.4,20.4,0,0,0,9.6-2.4A20,20,0,0,0,132,185.3V152.6l77.2,49.6a19.9,19.9,0,0,0,10.8,3.1,20.4,20.4,0,0,0,9.6-2.4A20,20,0,0,0,240,185.3V70.7A20,20,0,0,0,229.6,53.1ZM108,178,30.2,128,108,78Zm108,0-77.8-50L216,78Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M116,70.7V185.3a8,8,0,0,1-12.3,6.8L14.5,134.7a7.9,7.9,0,0,1,0-13.4l89.2-57.4A8,8,0,0,1,116,70.7Zm99.7-6.8-89.2,57.4a7.9,7.9,0,0,0,0,13.4l89.2,57.4a8,8,0,0,0,12.3-6.8V70.7A8,8,0,0,0,215.7,63.9Z"
            opacity="0.2"
          />
          <path d="M227.7,56.6a16.1,16.1,0,0,0-16.4.6L124,113.3V70.7A16,16,0,0,0,99.3,57.2L10.1,114.5a16.1,16.1,0,0,0,0,27l89.2,57.3A16,16,0,0,0,124,185.3V142.7l87.3,56.1A16,16,0,0,0,236,185.3V70.7A16.1,16.1,0,0,0,227.7,56.6ZM108,185.3,18.8,128h0L108,70.7Zm112,0L130.8,128h0L220,70.7Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M236,70.7V185.3a16.1,16.1,0,0,1-8.3,14.1,16.5,16.5,0,0,1-16.4-.6L124,142.7v42.6a16.1,16.1,0,0,1-8.3,14.1,16.5,16.5,0,0,1-16.4-.6L10.1,141.5a16.1,16.1,0,0,1,0-27L99.3,57.2A16,16,0,0,1,124,70.7v42.6l87.3-56.1A16,16,0,0,1,236,70.7Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M226.7,58.4a13.9,13.9,0,0,0-14.3.5l-89.2,57.3-1.2.9V70.7a14,14,0,0,0-21.6-11.8L11.2,116.2a14.1,14.1,0,0,0,0,23.6l89.2,57.3a14,14,0,0,0,7.6,2.2,13.4,13.4,0,0,0,6.7-1.7,13.9,13.9,0,0,0,7.3-12.3V138.9l1.2.9,89.2,57.3a14,14,0,0,0,7.6,2.2,13.4,13.4,0,0,0,6.7-1.7,13.9,13.9,0,0,0,7.3-12.3V70.7A13.9,13.9,0,0,0,226.7,58.4ZM110,185.3a2.1,2.1,0,0,1-1,1.8,2,2,0,0,1-2.1-.1L17.7,129.7a2.1,2.1,0,0,1,0-3.4L106.9,69a1.9,1.9,0,0,1,1.1-.4,2.9,2.9,0,0,1,1,.3,2.1,2.1,0,0,1,1,1.8Zm112,0a2.1,2.1,0,0,1-1,1.8,2,2,0,0,1-2.1-.1l-89.2-57.3a2.1,2.1,0,0,1,0-3.4L218.9,69a1.9,1.9,0,0,1,1.1-.4,2.9,2.9,0,0,1,1,.3,2.1,2.1,0,0,1,1,1.8Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M225.8,60.1a11.8,11.8,0,0,0-12.3.5l-89.2,57.3a11.3,11.3,0,0,0-4.3,4.9V70.7a11.9,11.9,0,0,0-18.5-10.1L12.3,117.9a12,12,0,0,0,0,20.2l89.2,57.3a11.8,11.8,0,0,0,6.5,2,12.2,12.2,0,0,0,5.8-1.5,12,12,0,0,0,6.2-10.6V133.2a11.3,11.3,0,0,0,4.3,4.9l89.2,57.3a11.8,11.8,0,0,0,6.5,2,12.2,12.2,0,0,0,5.8-1.5,12,12,0,0,0,6.2-10.6V70.7A12,12,0,0,0,225.8,60.1ZM112,185.3a4.2,4.2,0,0,1-2.1,3.6,4,4,0,0,1-4.1-.2L16.6,131.4a4.1,4.1,0,0,1,0-6.8l89.2-57.3a4,4,0,0,1,2.2-.7,4,4,0,0,1,1.9.5,4.2,4.2,0,0,1,2.1,3.6Zm112,0a4.2,4.2,0,0,1-2.1,3.6,4,4,0,0,1-4.1-.2l-89.2-57.3a4.1,4.1,0,0,1,0-6.8l89.2-57.3a4,4,0,0,1,2.2-.7,4,4,0,0,1,1.9.5,4.2,4.2,0,0,1,2.1,3.6Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M227.7,56.6a16.1,16.1,0,0,0-16.4.6L124,113.3V70.7A16,16,0,0,0,99.3,57.2L10.1,114.5a16.1,16.1,0,0,0,0,27l89.2,57.3A16,16,0,0,0,124,185.3V142.7l87.3,56.1A16,16,0,0,0,236,185.3V70.7A16.1,16.1,0,0,0,227.7,56.6ZM108,185.3,18.8,128h0L108,70.7Zm112,0L130.8,128h0L220,70.7Z" />
        </>
      )}
    </svg>
  );
}

export function PhRobot(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M200,44H140V16a12,12,0,0,0-24,0V44H56A36,36,0,0,0,20,80V192a36,36,0,0,0,36,36H200a36,36,0,0,0,36-36V80A36,36,0,0,0,200,44Zm12,148a12,12,0,0,1-12,12H56a12,12,0,0,1-12-12V80A12,12,0,0,1,56,68H200a12,12,0,0,1,12,12Zm-40-60H84a28.1,28.1,0,0,0-28,28v4a28.1,28.1,0,0,0,28,28h88a28.1,28.1,0,0,0,28-28v-4A28.1,28.1,0,0,0,172,132Zm-36,24v12H120V156Zm-56,8v-4a4,4,0,0,1,4-4H96v12H84A4,4,0,0,1,80,164Zm96,0a4,4,0,0,1-4,4H160V156h12a4,4,0,0,1,4,4ZM68,104a16,16,0,1,1,16,16A16,16,0,0,1,68,104Zm88,0a16,16,0,1,1,16,16A16,16,0,0,1,156,104Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M200,56H56A23.9,23.9,0,0,0,32,80V192a23.9,23.9,0,0,0,24,24H200a23.9,23.9,0,0,0,24-24V80A23.9,23.9,0,0,0,200,56ZM164,184H92a20,20,0,0,1,0-40h72a20,20,0,0,1,0,40Z"
            opacity="0.2"
          />
          <path d="M200,48H136V16a8,8,0,0,0-16,0V48H56A32.1,32.1,0,0,0,24,80V192a32.1,32.1,0,0,0,32,32H200a32.1,32.1,0,0,0,32-32V80A32.1,32.1,0,0,0,200,48Zm16,144a16,16,0,0,1-16,16H56a16,16,0,0,1-16-16V80A16,16,0,0,1,56,64H200a16,16,0,0,1,16,16Zm-52-56H92a28,28,0,0,0,0,56h72a28,28,0,0,0,0-56Zm-24,16v24H116V152ZM80,164a12,12,0,0,1,12-12h8v24H92A12,12,0,0,1,80,164Zm84,12h-8V152h8a12,12,0,0,1,0,24ZM72,108a12,12,0,1,1,12,12A12,12,0,0,1,72,108Zm88,0a12,12,0,1,1,12,12A12,12,0,0,1,160,108Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M200,48H136V16a8,8,0,0,0-16,0V48H56A32.1,32.1,0,0,0,24,80V192a32.1,32.1,0,0,0,32,32H200a32.1,32.1,0,0,0,32-32V80A32.1,32.1,0,0,0,200,48ZM72,108a12,12,0,1,1,12,12A12,12,0,0,1,72,108Zm28,76H92a16,16,0,0,1,0-32h8Zm40,0H116V152h24Zm24,0h-8V152h8a16,16,0,0,1,0,32Zm8-64a12,12,0,1,1,12-12A12,12,0,0,1,172,120Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M200,50H134V16a6,6,0,0,0-12,0V50H56A30.1,30.1,0,0,0,26,80V192a30.1,30.1,0,0,0,30,30H200a30.1,30.1,0,0,0,30-30V80A30.1,30.1,0,0,0,200,50Zm18,142a18.1,18.1,0,0,1-18,18H56a18.1,18.1,0,0,1-18-18V80A18.1,18.1,0,0,1,56,62H200a18.1,18.1,0,0,1,18,18Zm-54-54H92a26,26,0,0,0,0,52h72a26,26,0,0,0,0-52Zm-22,12v28H114V150ZM78,164a14,14,0,0,1,14-14h10v28H92A14,14,0,0,1,78,164Zm86,14H154V150h10a14,14,0,0,1,0,28ZM74,108a10,10,0,1,1,10,10A10,10,0,0,1,74,108Zm88,0a10,10,0,1,1,10,10A10,10,0,0,1,162,108Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M200,52H132V16a4,4,0,0,0-8,0V52H56A28.1,28.1,0,0,0,28,80V192a28.1,28.1,0,0,0,28,28H200a28.1,28.1,0,0,0,28-28V80A28.1,28.1,0,0,0,200,52Zm20,140a20.1,20.1,0,0,1-20,20H56a20.1,20.1,0,0,1-20-20V80A20.1,20.1,0,0,1,56,60H200a20.1,20.1,0,0,1,20,20Zm-56-52H92a24,24,0,0,0,0,48h72a24,24,0,0,0,0-48Zm-20,8v32H112V148ZM76,164a16,16,0,0,1,16-16h12v32H92A16,16,0,0,1,76,164Zm88,16H152V148h12a16,16,0,0,1,0,32ZM76,108a8,8,0,1,1,8,8A8,8,0,0,1,76,108Zm88,0a8,8,0,1,1,8,8A8,8,0,0,1,164,108Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M200,48H136V16a8,8,0,0,0-16,0V48H56A32.1,32.1,0,0,0,24,80V192a32.1,32.1,0,0,0,32,32H200a32.1,32.1,0,0,0,32-32V80A32.1,32.1,0,0,0,200,48Zm16,144a16,16,0,0,1-16,16H56a16,16,0,0,1-16-16V80A16,16,0,0,1,56,64H200a16,16,0,0,1,16,16Zm-52-56H92a28,28,0,0,0,0,56h72a28,28,0,0,0,0-56Zm-24,16v24H116V152ZM80,164a12,12,0,0,1,12-12h8v24H92A12,12,0,0,1,80,164Zm84,12h-8V152h8a12,12,0,0,1,0,24ZM72,108a12,12,0,1,1,12,12A12,12,0,0,1,72,108Zm88,0a12,12,0,1,1,12,12A12,12,0,0,1,160,108Z" />
        </>
      )}
    </svg>
  );
}

export function PhRocketLaunch(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M231.6,41.2a19.9,19.9,0,0,0-16.8-16.8c-11.9-1.7-42.8-2.6-72.7,27.2l-7.8,7.8H74.7a19.8,19.8,0,0,0-14.1,5.9L28.3,97.6a20,20,0,0,0,10.2,33.8l38.3,7.7,40.1,40.1,7.7,38.3a20,20,0,0,0,13.8,15.2,19.5,19.5,0,0,0,5.8.9,20.1,20.1,0,0,0,14.2-5.9l32.3-32.3a19.8,19.8,0,0,0,5.9-14.1V121.7l7.8-7.8C234.2,84,233.3,53.1,231.6,41.2ZM76.4,83.4h33.9L78.8,115l-28.3-5.7Zm82.7-14.8c19.8-19.8,39.3-21.5,49.1-20.8.7,9.8-1,29.3-20.8,49.1L128,156.3,99.7,128Zm13.5,111-25.9,25.9L141,177.2l31.6-31.5ZM101,198.4c-18.4,31.7-61.7,32.1-63.5,32.1a12,12,0,0,1-12-12c0-1.8.4-45.1,32.1-63.5a12,12,0,0,1,12,20.8c-11,6.4-16,19-18.2,28.9,9.8-2.3,22.4-7.3,28.8-18.3a12,12,0,1,1,20.8,12Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M94.1,184.6c-11.4,33.9-56.6,33.9-56.6,33.9s0-45.2,33.9-56.6Z" />
          </g>
          <g opacity="0.2">
            <path d="M184.6,116.7v64.6a8,8,0,0,1-2.4,5.6l-32.3,32.4a8,8,0,0,1-13.5-4.1L128,173.3Z" />
          </g>
          <g opacity="0.2">
            <path d="M139.3,71.4H74.7a8,8,0,0,0-5.6,2.4L36.7,106.1a8,8,0,0,0,4.1,13.5L82.7,128Z" />
          </g>
          <path d="M96.6,177a7.9,7.9,0,0,0-10.1,5c-6.6,19.7-27.9,25.8-40.2,27.7,1.9-12.3,8-33.6,27.7-40.2a8,8,0,1,0-5.1-15.1c-16.4,5.4-28.4,18.4-34.8,37.5a91.8,91.8,0,0,0-4.6,26.6,8,8,0,0,0,8,8,91.8,91.8,0,0,0,26.6-4.6c19.1-6.4,32.1-18.4,37.5-34.8A7.9,7.9,0,0,0,96.6,177Z" />
          <path d="M227.6,41.8a15.7,15.7,0,0,0-13.4-13.4c-11.3-1.7-40.6-2.5-69.2,26.1l-9,8.9H74.7a16.2,16.2,0,0,0-11.3,4.7L31.1,100.5a15.9,15.9,0,0,0-4,15.9,16,16,0,0,0,12.2,11.1l39.5,7.9,41.8,41.8,7.9,39.5a16,16,0,0,0,11.1,12.2,14.7,14.7,0,0,0,4.6.7,15.6,15.6,0,0,0,11.3-4.7l32.4-32.3a16.2,16.2,0,0,0,4.7-11.3V120l8.9-9C230.1,82.4,229.3,53.1,227.6,41.8ZM74.7,79.4H120L80.1,119.3l-37.7-7.5Zm81.6-13.6c7.8-7.8,28.8-25.6,55.5-21.6,4,26.7-13.8,47.7-21.6,55.5L128,161.9,94.1,128Zm20.3,115.5-32.4,32.3-7.5-37.7L176.6,136Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M101.6,187.1c-5.4,16.4-18.4,28.4-37.5,34.8a91.8,91.8,0,0,1-26.6,4.6,8,8,0,0,1-8-8,91.8,91.8,0,0,1,4.6-26.6c6.4-19.1,18.4-32.1,34.8-37.5A8,8,0,1,1,74,169.5c-19.7,6.6-25.8,27.9-27.7,40.2,12.3-1.9,33.6-8,40.2-27.7a8,8,0,0,1,15.1,5.1ZM201.5,111l-8.9,9v61.3a16.2,16.2,0,0,1-4.7,11.3l-32.4,32.3a15.6,15.6,0,0,1-11.3,4.7,14.7,14.7,0,0,1-4.6-.7,16,16,0,0,1-11.1-12.2l-7.9-39.5L78.8,135.4l-39.5-7.9a16,16,0,0,1-12.2-11.1,15.9,15.9,0,0,1,4-15.9L63.4,68.1a16.2,16.2,0,0,1,11.3-4.7H136l9-8.9c28.6-28.6,57.9-27.8,69.2-26.1a15.7,15.7,0,0,1,13.4,13.4C229.3,53.1,230.1,82.4,201.5,111Zm-159.1.8,37.7,7.5L120,79.4H74.7ZM176.6,136l-39.9,39.9,7.5,37.7,32.4-32.3Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M99.8,186.5c-5.3,15.7-17.9,27.3-36.4,33.5a89.2,89.2,0,0,1-25.9,4.5,6,6,0,0,1-6-6A89.2,89.2,0,0,1,36,192.6c6.2-18.5,17.8-31.1,33.5-36.4a6,6,0,0,1,7.6,3.8,5.9,5.9,0,0,1-3.8,7.6C51.1,175.1,45.4,200,44,212c11.9-1.5,37-7.1,44.4-29.3a5.9,5.9,0,0,1,7.6-3.8A6,6,0,0,1,99.8,186.5Zm100.3-76.9-9.5,9.6v62.1a14.3,14.3,0,0,1-4.1,9.9l-32.4,32.3a13.7,13.7,0,0,1-9.9,4.1,14,14,0,0,1-13.7-11.3l-8-40.1L79.8,133.5l-40.1-8A13.9,13.9,0,0,1,29,115.8a13.8,13.8,0,0,1,3.5-13.9L64.8,69.5a14.3,14.3,0,0,1,9.9-4.1h62.1l9.6-9.5c27.9-28,56.5-27.2,67.5-25.5a13.8,13.8,0,0,1,11.7,11.7C227.3,53.1,228.1,81.7,200.1,109.6ZM42,113.7l38.8,7.8,44-44.1H74.7a2,2,0,0,0-1.4.6L41,110.4a2,2,0,0,0-.5,2A1.8,1.8,0,0,0,42,113.7Zm136.6,17.5-44.1,44,7.8,38.8a1.8,1.8,0,0,0,1.3,1.5,2,2,0,0,0,2-.5L178,182.7a2,2,0,0,0,.6-1.4Zm13-30.1c8.1-8,26.3-29.6,22.2-57.2a2.1,2.1,0,0,0-1.7-1.7c-27.6-4.1-49.2,14.1-57.2,22.2L143.6,75.7h0L91.2,128,128,164.8l52.3-52.4h0l11.3-11.3Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M97.9,185.8c-5.1,15.2-17.2,26.3-35.1,32.3a87.1,87.1,0,0,1-25.3,4.4,4,4,0,0,1-4-4,87.1,87.1,0,0,1,4.4-25.3c6-17.9,17.1-30,32.3-35.1a4,4,0,0,1,5,2.6,3.9,3.9,0,0,1-2.5,5c-24.9,8.3-29.9,37.5-31,48.6,11.1-1.1,40.3-6.1,48.6-31a3.9,3.9,0,0,1,5-2.5A4,4,0,0,1,97.9,185.8Zm100.8-77.6-10.1,10.1v63a11.9,11.9,0,0,1-3.5,8.4l-32.4,32.4a11.9,11.9,0,0,1-8.5,3.5,14.8,14.8,0,0,1-3.5-.5,11.9,11.9,0,0,1-8.2-9.1l-8.2-40.8L80.8,131.7l-40.7-8.2a11.9,11.9,0,0,1-9.2-8.2,12,12,0,0,1,3-12L66.3,70.9a11.9,11.9,0,0,1,8.4-3.5h63l10.1-10.1c27.3-27.3,55.1-26.6,65.8-25a12.1,12.1,0,0,1,10.1,10.1C225.3,53.1,226,80.9,198.7,108.2ZM41.6,115.7l39.8,8,48.3-48.3h-55a3.9,3.9,0,0,0-2.8,1.2L39.6,108.9a4.1,4.1,0,0,0,2,6.8Zm139,10.6-48.3,48.3,8,39.8a4.1,4.1,0,0,0,6.8,2l32.3-32.3a3.9,3.9,0,0,0,1.2-2.8Zm12.5-23.8c24.7-24.7,24.1-49.4,22.6-58.9a3.8,3.8,0,0,0-3.3-3.3c-9.5-1.5-34.2-2.1-58.9,22.6L142.1,74.3h0L88.4,128,128,167.6l53.7-53.7h0Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M101.6,187.1c-5.4,16.4-18.4,28.4-37.5,34.8a91.8,91.8,0,0,1-26.6,4.6,8,8,0,0,1-8-8,91.8,91.8,0,0,1,4.6-26.6c6.4-19.1,18.4-32.1,34.8-37.5A8,8,0,1,1,74,169.5c-19.7,6.6-25.8,27.9-27.7,40.2,12.3-1.9,33.6-8,40.2-27.7a8,8,0,0,1,15.1,5.1ZM201.5,111l-8.9,9v61.3a16.2,16.2,0,0,1-4.7,11.3l-32.4,32.3a15.6,15.6,0,0,1-11.3,4.7,14.7,14.7,0,0,1-4.6-.7,16,16,0,0,1-11.1-12.2l-7.9-39.5L78.8,135.4l-39.5-7.9a16,16,0,0,1-12.2-11.1,15.9,15.9,0,0,1,4-15.9L63.4,68.1a16.2,16.2,0,0,1,11.3-4.7H136l9-8.9c28.6-28.6,57.9-27.8,69.2-26.1a15.7,15.7,0,0,1,13.4,13.4C229.3,53.1,230.1,82.4,201.5,111Zm-159.1.8,37.7,7.5L120,79.4H74.7ZM94.1,128,128,161.9l62.2-62.2c7.8-7.8,25.6-28.8,21.6-55.5-26.7-4-47.7,13.8-55.5,21.6Zm82.5,8-39.9,39.9,7.5,37.7,32.4-32.3Z" />
        </>
      )}
    </svg>
  );
}

export function PhRocket(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M156,228a12,12,0,0,1-12,12H112a12,12,0,0,1,0-24h32A12,12,0,0,1,156,228Zm70.9-69.6-12.4,55.7a19.7,19.7,0,0,1-12.9,14.5,19.2,19.2,0,0,1-6.7,1.1,20.3,20.3,0,0,1-12.4-4.3L155.8,204H100.2L73.5,225.4a20.3,20.3,0,0,1-12.4,4.3,19.2,19.2,0,0,1-6.7-1.1,19.7,19.7,0,0,1-12.9-14.5L29.1,158.4a20.3,20.3,0,0,1,4.2-17.1l28.8-34.5a126.9,126.9,0,0,1,8.2-35.6c12.1-31.1,33.5-51.5,45.2-60.8a20.1,20.1,0,0,1,25.1.1c11.5,9.3,32.6,29.7,44.5,60.8a128,128,0,0,1,8.2,34.7l29.4,35.3A20.3,20.3,0,0,1,226.9,158.4ZM92.7,79.8c-12,31-8.5,64.7,10.4,100.2h49.8c18.4-35.5,21.8-69.1,9.8-100.1-9.3-24.3-25.9-41-34.8-48.7C119,38.8,102.2,55.6,92.7,79.8ZM80.6,188.9A172.7,172.7,0,0,1,64,142L53,155.2l10.5,47.4ZM203,155.2l-11.4-13.7c-2.7,15.5-8.2,31.3-16.3,47.4l17.2,13.7ZM128,112a16,16,0,1,0-16-16A16,16,0,0,0,128,112Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M73.9,111.4h0L42.5,149a7.6,7.6,0,0,0-1.6,6.8l12.3,55.7A8,8,0,0,0,66,216l30-24C78.4,161.4,72.7,134.5,73.9,111.4Z" />
          </g>
          <g opacity="0.2">
            <path d="M181.5,110.7h0l31.4,37.7a7.6,7.6,0,0,1,1.6,6.8l-12.3,55.6a8,8,0,0,1-12.8,4.6l-30-24C177,160.7,182.7,133.8,181.5,110.7Z" />
          </g>
          <path d="M144,216H112a8,8,0,0,0,0,16h32a8,8,0,0,0,0-16Z" />
          <circle cx="128" cy="96" r="12" />
          <path d="M219.6,143.9l-30.2-36.3a125.4,125.4,0,0,0-8-34.9c-11.6-30.2-32.1-50-43.4-59.1a15.9,15.9,0,0,0-20-.1c-11.4,9.1-32.2,28.9-43.9,59.1A121.5,121.5,0,0,0,66,108.3L36.4,143.9A16.1,16.1,0,0,0,33,157.6l12.4,55.6a15.9,15.9,0,0,0,10.3,11.6,17,17,0,0,0,5.4.9,16,16,0,0,0,9.9-3.5L98.8,200h58.4L185,222.2a16,16,0,0,0,9.9,3.5,17,17,0,0,0,5.4-.9,15.9,15.9,0,0,0,10.3-11.6L223,157.6A16.1,16.1,0,0,0,219.6,143.9ZM128,26c70.3,57.1,41.4,131,27.2,158H100.7C52.7,96.3,109.2,41,128,26ZM61,209.7,48.7,154.1l17.9-21.5q3.5,28.1,19.1,57.4Zm134,0-24.8-19.8c10.4-19.7,16.6-39,18.8-57.8l18.3,22Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M152,224a8,8,0,0,1-8,8H112a8,8,0,0,1,0-16h32A8,8,0,0,1,152,224Zm71-66.4-12.4,55.6a15.9,15.9,0,0,1-10.3,11.6,17,17,0,0,1-5.4.9,16,16,0,0,1-9.9-3.5L157.2,200H98.8L71,222.2a16,16,0,0,1-9.9,3.5,17,17,0,0,1-5.4-.9,15.9,15.9,0,0,1-10.3-11.6L33,157.6a16.1,16.1,0,0,1,3.4-13.7L66,108.3a121.5,121.5,0,0,1,8.1-35.7c11.7-30.2,32.5-50,43.9-59.1a15.9,15.9,0,0,1,20,.1c11.3,9.1,31.8,28.9,43.4,59.1a125.4,125.4,0,0,1,8,34.9l30.2,36.3A16.1,16.1,0,0,1,223,157.6ZM85.7,190q-15.6-29.4-19.1-57.4L48.7,154.1,61,209.7ZM140,96a12,12,0,1,0-12,12A12,12,0,0,0,140,96Zm67.3,58.1-18.3-22c-2.2,18.8-8.4,38.1-18.8,57.8L195,209.7Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M150,224a6,6,0,0,1-6,6H112a6,6,0,0,1,0-12h32A6,6,0,0,1,150,224Zm71-66.9-12.4,55.7a13.7,13.7,0,0,1-9,10.1,12.6,12.6,0,0,1-4.7.8,14.1,14.1,0,0,1-8.7-3L157.9,198H98.1L69.8,220.7a14.1,14.1,0,0,1-8.7,3,12.6,12.6,0,0,1-4.7-.8,13.7,13.7,0,0,1-9-10.1L35,157.1a14.1,14.1,0,0,1,2.9-12l30-36a121.3,121.3,0,0,1,8-35.8C87.5,43.5,108,24.1,119.2,15.1h0a14.1,14.1,0,0,1,17.6,0c11,9,31.3,28.5,42.7,58.3a119.2,119.2,0,0,1,7.9,35l30.7,36.7A14.1,14.1,0,0,1,221,157.1ZM99.5,186h57c48.7-89.7-8.4-146.2-27.3-161.5a2.1,2.1,0,0,0-2.5-.1h0C107.6,39.8,49.7,96.3,99.5,186Zm-11.2,4.5C76.6,169,69.8,148,68.1,127.6l-21,25.2a2.1,2.1,0,0,0-.4,1.7l12.4,55.7a1.8,1.8,0,0,0,1.3,1.4,1.8,1.8,0,0,0,1.9-.3Zm120.6-37.7-21.5-25.7c-1.5,20.5-8.1,41.7-19.7,63.4l26,20.8a1.8,1.8,0,0,0,1.9.3,1.8,1.8,0,0,0,1.3-1.4l12.4-55.7A2.1,2.1,0,0,0,208.9,152.8ZM128,106a10,10,0,1,0-10-10A10,10,0,0,0,128,106Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M148,224a4,4,0,0,1-4,4H112a4,4,0,0,1,0-8h32A4,4,0,0,1,148,224Zm71.1-67.3-12.4,55.6a12,12,0,0,1-7.7,8.8,14.4,14.4,0,0,1-4,.6,12,12,0,0,1-7.5-2.6L158.6,196H97.4L68.5,219.1a12,12,0,0,1-7.5,2.6,14.4,14.4,0,0,1-4-.6,12,12,0,0,1-7.7-8.8L36.9,156.7a12.4,12.4,0,0,1,2.5-10.3l30.5-36.5a117.4,117.4,0,0,1,7.9-35.8c11.8-30.5,33.8-50.3,42.7-57.5a12,12,0,0,1,15,.1c10.9,8.8,30.9,28.1,42.2,57.4a119,119,0,0,1,7.8,35l31.1,37.3A12.4,12.4,0,0,1,219.1,156.7ZM98.3,188h59.4c50.4-91.6-8-149.5-27.2-165.1a3.9,3.9,0,0,0-5,0h0C106,38.5,46.8,96.3,98.3,188Zm-7.5,3c-13-23.5-20-46.4-21-68.5l-24.2,29a4.1,4.1,0,0,0-.8,3.5l12.3,55.6a4,4,0,0,0,2.6,2.9,4,4,0,0,0,3.8-.6Zm119.6-39.5-24.7-29.6c-.8,22.3-7.7,45.4-20.6,69.1l27.4,21.9a4,4,0,0,0,3.8.6,4,4,0,0,0,2.6-2.9L211.2,155A4.1,4.1,0,0,0,210.4,151.5ZM128,104a8,8,0,1,0-8-8A8,8,0,0,0,128,104Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M152,224a8,8,0,0,1-8,8H112a8,8,0,0,1,0-16h32A8,8,0,0,1,152,224ZM128,108a12,12,0,1,0-12-12A12,12,0,0,0,128,108Zm95,49.6-12.4,55.6a15.9,15.9,0,0,1-10.3,11.6,17,17,0,0,1-5.4.9,16,16,0,0,1-9.9-3.5L157.2,200H98.8L71,222.2a16,16,0,0,1-9.9,3.5,17,17,0,0,1-5.4-.9,15.9,15.9,0,0,1-10.3-11.6L33,157.6a16.1,16.1,0,0,1,3.4-13.7L66,108.3a121.5,121.5,0,0,1,8.1-35.7c11.7-30.2,32.5-50,43.9-59.1a15.9,15.9,0,0,1,20,.1c11.3,9.1,31.8,28.9,43.4,59.1a125.4,125.4,0,0,1,8,34.9l30.2,36.3A16.1,16.1,0,0,1,223,157.6ZM100.7,184h54.5C169.4,157,198.3,83.1,128,26,109.2,41,52.7,96.3,100.7,184Zm-15,6q-15.6-29.4-19.1-57.4L48.7,154.1,61,209.7Zm121.6-35.9-18.3-22c-2.2,18.8-8.4,38.1-18.8,57.8L195,209.7Z" />
        </>
      )}
    </svg>
  );
}

export function PhRows(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M208,132H48a20.1,20.1,0,0,0-20,20v40a20.1,20.1,0,0,0,20,20H208a20.1,20.1,0,0,0,20-20V152A20.1,20.1,0,0,0,208,132Zm-4,56H52V156H204Zm4-144H48A20.1,20.1,0,0,0,28,64v40a20.1,20.1,0,0,0,20,20H208a20.1,20.1,0,0,0,20-20V64A20.1,20.1,0,0,0,208,44Zm-4,56H52V68H204Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect x="40" y="144" width="176" height="56" rx="8" />
          </g>
          <g opacity="0.2">
            <rect x="40" y="56" width="176" height="56" rx="8" />
          </g>
          <path d="M208,136H48a16,16,0,0,0-16,16v40a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V152A16,16,0,0,0,208,136Zm0,56H48V152H208v40Zm0-144H48A16,16,0,0,0,32,64v40a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V64A16,16,0,0,0,208,48Zm0,56H48V64H208v40Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M224,152v40a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V152a16,16,0,0,1,16-16H208A16,16,0,0,1,224,152ZM208,48H48A16,16,0,0,0,32,64v40a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V64A16,16,0,0,0,208,48Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M208,138H48a14,14,0,0,0-14,14v40a14,14,0,0,0,14,14H208a14,14,0,0,0,14-14V152A14,14,0,0,0,208,138Zm2,54a2,2,0,0,1-2,2H48a2,2,0,0,1-2-2V152a2,2,0,0,1,2-2H208a2,2,0,0,1,2,2ZM208,50H48A14,14,0,0,0,34,64v40a14,14,0,0,0,14,14H208a14,14,0,0,0,14-14V64A14,14,0,0,0,208,50Zm2,54a2,2,0,0,1-2,2H48a2,2,0,0,1-2-2V64a2,2,0,0,1,2-2H208a2,2,0,0,1,2,2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M208,140H48a12,12,0,0,0-12,12v40a12,12,0,0,0,12,12H208a12,12,0,0,0,12-12V152A12,12,0,0,0,208,140Zm4,52a4,4,0,0,1-4,4H48a4,4,0,0,1-4-4V152a4,4,0,0,1,4-4H208a4,4,0,0,1,4,4ZM208,52H48A12,12,0,0,0,36,64v40a12,12,0,0,0,12,12H208a12,12,0,0,0,12-12V64A12,12,0,0,0,208,52Zm4,52a4,4,0,0,1-4,4H48a4,4,0,0,1-4-4V64a4,4,0,0,1,4-4H208a4,4,0,0,1,4,4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M208,136H48a16,16,0,0,0-16,16v40a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V152A16,16,0,0,0,208,136Zm0,56H48V152H208v40Zm0-144H48A16,16,0,0,0,32,64v40a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V64A16,16,0,0,0,208,48Zm0,56H48V64H208v40Z" />
        </>
      )}
    </svg>
  );
}

export function PhRssSimple(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M220,200a12,12,0,0,1-24,0c0-77.2-62.8-140-140-140a12,12,0,0,1,0-24C146.4,36,220,109.6,220,200ZM56,108a12,12,0,0,0,0,24,68.1,68.1,0,0,1,68,68,12,12,0,0,0,24,0A92.1,92.1,0,0,0,56,108Zm0,76a16,16,0,1,0,16,16A16,16,0,0,0,56,184Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M216,200a8,8,0,0,1-16,0c0-79.4-64.6-144-144-144a8,8,0,0,1,0-16C144.2,40,216,111.8,216,200ZM56,112a8,8,0,0,0,0,16,72.1,72.1,0,0,1,72,72,8,8,0,0,0,16,0A88.1,88.1,0,0,0,56,112Zm0,76a12,12,0,1,0,12,12A12,12,0,0,0,56,188Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M68,200a12,12,0,1,1-12-12A12,12,0,0,1,68,200ZM56,40a8,8,0,0,0,0,16c79.4,0,144,64.6,144,144a8,8,0,0,0,16,0C216,111.8,144.2,40,56,40Zm0,72a8,8,0,0,0,0,16,72.1,72.1,0,0,1,72,72,8,8,0,0,0,16,0A88.1,88.1,0,0,0,56,112Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M214,200a6,6,0,0,1-12,0c0-80.5-65.5-146-146-146a6,6,0,0,1,0-12C143.1,42,214,112.9,214,200ZM56,114a6,6,0,0,0,0,12,74.1,74.1,0,0,1,74,74,6,6,0,0,0,12,0A86.1,86.1,0,0,0,56,114Zm0,76a10,10,0,1,0,10,10A10,10,0,0,0,56,190Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M212,200a4,4,0,0,1-8,0c0-81.6-66.4-148-148-148a4,4,0,0,1,0-8C142,44,212,114,212,200ZM56,116a4,4,0,0,0,0,8,76.1,76.1,0,0,1,76,76,4,4,0,0,0,8,0A84.1,84.1,0,0,0,56,116Zm0,76a8,8,0,1,0,8,8A8,8,0,0,0,56,192Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,200a8,8,0,0,1-16,0c0-79.4-64.6-144-144-144a8,8,0,0,1,0-16C144.2,40,216,111.8,216,200ZM56,112a8,8,0,0,0,0,16,72.1,72.1,0,0,1,72,72,8,8,0,0,0,16,0A88.1,88.1,0,0,0,56,112Zm0,76a12,12,0,1,0,12,12A12,12,0,0,0,56,188Z" />
        </>
      )}
    </svg>
  );
}

export function PhRss(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M101.7,154.3A75.2,75.2,0,0,1,124,208a12,12,0,0,1-24,0,51.9,51.9,0,0,0-52-52,12,12,0,0,1,0-24A75.2,75.2,0,0,1,101.7,154.3ZM48,84a12,12,0,0,0,0,24A100,100,0,0,1,148,208a12,12,0,0,0,24,0A123.9,123.9,0,0,0,48,84Zm121.6,2.4A170.9,170.9,0,0,0,48,36a12,12,0,0,0,0,24A147.8,147.8,0,0,1,196,208a12,12,0,0,0,24,0A170.7,170.7,0,0,0,169.6,86.4ZM52,188a16,16,0,1,0,16,16A16,16,0,0,0,52,188Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M98.9,157.1A71.6,71.6,0,0,1,120,208a8,8,0,0,1-16,0,56,56,0,0,0-56-56,8,8,0,0,1,0-16A71.6,71.6,0,0,1,98.9,157.1ZM48,88a8,8,0,0,0,0,16,102.9,102.9,0,0,1,73.5,30.5A102.9,102.9,0,0,1,152,208a8,8,0,0,0,16,0A119.8,119.8,0,0,0,48,88Zm118.8,1.2A166.9,166.9,0,0,0,48,40a8,8,0,0,0,0,16,151.1,151.1,0,0,1,107.5,44.5A151.1,151.1,0,0,1,200,208a8,8,0,0,0,16,0A166.9,166.9,0,0,0,166.8,89.2ZM52,192a12,12,0,1,0,12,12A12,12,0,0,0,52,192Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M64,204a12,12,0,1,1-12-12A12,12,0,0,1,64,204ZM166.8,89.2A166.9,166.9,0,0,0,48,40a8,8,0,0,0,0,16,151.1,151.1,0,0,1,107.5,44.5A151.1,151.1,0,0,1,200,208a8,8,0,0,0,16,0A166.9,166.9,0,0,0,166.8,89.2ZM48,136a8,8,0,0,0,0,16,56,56,0,0,1,56,56,8,8,0,0,0,16,0,72,72,0,0,0-72-72Zm0-48a8,8,0,0,0,0,16,102.9,102.9,0,0,1,73.5,30.5A102.9,102.9,0,0,1,152,208a8,8,0,0,0,16,0A119.8,119.8,0,0,0,48,88Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M97.5,158.5A69.6,69.6,0,0,1,118,208a6,6,0,0,1-12,0,58,58,0,0,0-58-58,6,6,0,0,1,0-12A69.6,69.6,0,0,1,97.5,158.5ZM48,90a6,6,0,0,0,0,12A105.8,105.8,0,0,1,154,208a6,6,0,0,0,12,0,117,117,0,0,0-34.6-83.4A117,117,0,0,0,48,90Zm117.4.6A165.3,165.3,0,0,0,48,42a6,6,0,0,0,0,12A153,153,0,0,1,156.9,99.1,153,153,0,0,1,202,208a6,6,0,0,0,12,0A165.3,165.3,0,0,0,165.4,90.6ZM52,194a10,10,0,1,0,10,10A10,10,0,0,0,52,194Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M96.1,159.9A67.8,67.8,0,0,1,116,208a4,4,0,0,1-8,0,60.1,60.1,0,0,0-60-60,4,4,0,0,1,0-8A67.8,67.8,0,0,1,96.1,159.9ZM48,92a4,4,0,0,0,0,8A107.9,107.9,0,0,1,156,208a4,4,0,0,0,8,0,115.2,115.2,0,0,0-34-82A115.2,115.2,0,0,0,48,92Zm116,0A163.3,163.3,0,0,0,48,44a4,4,0,0,0,0,8A154.8,154.8,0,0,1,158.3,97.7,154.7,154.7,0,0,1,204,208a4,4,0,0,0,8,0A163.3,163.3,0,0,0,164,92ZM52,196a8,8,0,1,0,8,8A8,8,0,0,0,52,196Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M98.9,157.1A71.6,71.6,0,0,1,120,208a8,8,0,0,1-16,0,56,56,0,0,0-56-56,8,8,0,0,1,0-16A71.6,71.6,0,0,1,98.9,157.1ZM48,88a8,8,0,0,0,0,16,102.9,102.9,0,0,1,73.5,30.5A102.9,102.9,0,0,1,152,208a8,8,0,0,0,16,0A119.8,119.8,0,0,0,48,88Zm118.8,1.2A166.9,166.9,0,0,0,48,40a8,8,0,0,0,0,16,151.1,151.1,0,0,1,107.5,44.5A151.1,151.1,0,0,1,200,208a8,8,0,0,0,16,0A166.9,166.9,0,0,0,166.8,89.2ZM52,192a12,12,0,1,0,12,12A12,12,0,0,0,52,192Z" />
        </>
      )}
    </svg>
  );
}

export function PhRug(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M200,12a12,12,0,0,0-12,12V36H164V24a12,12,0,0,0-24,0V36H116V24a12,12,0,0,0-24,0V36H68V24a12,12,0,0,0-24,0V232a12,12,0,0,0,24,0V220H92v12a12,12,0,0,0,24,0V220h24v12a12,12,0,0,0,24,0V220h24v12a12,12,0,0,0,24,0V24A12,12,0,0,0,200,12ZM68,60H188V196H68Zm60,120a12.1,12.1,0,0,0,10.3-5.8l24-40a12,12,0,0,0,0-12.4l-24-40a12,12,0,0,0-20.6,0l-24,40a12,12,0,0,0,0,12.4l24,40A12.1,12.1,0,0,0,128,180Zm0-68.7L138,128l-10,16.7L118,128Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M56,48V208H200V48Zm72,120-24-40,24-40,24,40Z"
            opacity="0.2"
          />
          <path d="M200,16a8,8,0,0,0-8,8V40H160V24a8,8,0,0,0-16,0V40H112V24a8,8,0,0,0-16,0V40H64V24a8,8,0,0,0-16,0V232a8,8,0,0,0,16,0V216H96v16a8,8,0,0,0,16,0V216h32v16a8,8,0,0,0,16,0V216h32v16a8,8,0,0,0,16,0V24A8,8,0,0,0,200,16ZM64,56H192V200H64Zm64,120a8.2,8.2,0,0,0,6.9-3.9l24-40a8,8,0,0,0,0-8.2l-24-40a8.1,8.1,0,0,0-13.8,0l-24,40a8,8,0,0,0,0,8.2l24,40A8.2,8.2,0,0,0,128,176Zm0-72.5L142.7,128,128,152.5,113.3,128Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M97.1,132.1a8,8,0,0,1,0-8.2l24-40a8.1,8.1,0,0,1,13.8,0l24,40a8,8,0,0,1,0,8.2l-24,40a8.1,8.1,0,0,1-13.8,0ZM208,24V232a8,8,0,0,1-16,0V216H160v16a8,8,0,0,1-16,0V216H112v16a8,8,0,0,1-16,0V216H64v16a8,8,0,0,1-16,0V24a8,8,0,0,1,16,0V40H96V24a8,8,0,0,1,16,0V40h32V24a8,8,0,0,1,16,0V40h32V24a8,8,0,0,1,16,0ZM192,56H64V200H192Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M200,18a6,6,0,0,0-6,6V42H158V24a6,6,0,0,0-12,0V42H110V24a6,6,0,0,0-12,0V42H62V24a6,6,0,0,0-12,0V232a6,6,0,0,0,12,0V214H98v18a6,6,0,0,0,12,0V214h36v18a6,6,0,0,0,12,0V214h36v18a6,6,0,0,0,12,0V24A6,6,0,0,0,200,18ZM62,54H194V202H62Zm66,120a5.8,5.8,0,0,0,5.1-2.9l24-40a5.8,5.8,0,0,0,0-6.2l-24-40a5.9,5.9,0,0,0-10.2,0l-24,40a5.8,5.8,0,0,0,0,6.2l24,40A5.8,5.8,0,0,0,128,174Zm0-74.3L145,128l-17,28.3L111,128Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M200,20a4,4,0,0,0-4,4V44H156V24a4,4,0,0,0-8,0V44H108V24a4,4,0,0,0-8,0V44H60V24a4,4,0,0,0-8,0V232a4,4,0,0,0,8,0V212h40v20a4,4,0,0,0,8,0V212h40v20a4,4,0,0,0,8,0V212h40v20a4,4,0,0,0,8,0V24A4,4,0,0,0,200,20ZM60,52H196V204H60Zm68,120a3.9,3.9,0,0,0,3.4-1.9l24-40a4,4,0,0,0,0-4.2l-24-40a4,4,0,0,0-6.8,0l-24,40a4,4,0,0,0,0,4.2l24,40A3.9,3.9,0,0,0,128,172Zm0-76.2L147.3,128,128,160.2,108.7,128Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M200,16a8,8,0,0,0-8,8V40H160V24a8,8,0,0,0-16,0V40H112V24a8,8,0,0,0-16,0V40H64V24a8,8,0,0,0-16,0V232a8,8,0,0,0,16,0V216H96v16a8,8,0,0,0,16,0V216h32v16a8,8,0,0,0,16,0V216h32v16a8,8,0,0,0,16,0V24A8,8,0,0,0,200,16ZM64,56H192V200H64Zm64,120a8.2,8.2,0,0,0,6.9-3.9l24-40a8,8,0,0,0,0-8.2l-24-40a8.1,8.1,0,0,0-13.8,0l-24,40a8,8,0,0,0,0,8.2l24,40A8.2,8.2,0,0,0,128,176Zm0-72.5L142.7,128,128,152.5,113.3,128Z" />
        </>
      )}
    </svg>
  );
}

export function PhRuler(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M234.8,73.9,182.1,21.2a19.9,19.9,0,0,0-28.2,0L51.5,123.5h0L21.2,153.9a19.8,19.8,0,0,0,0,28.2l52.7,52.7a19.8,19.8,0,0,0,28.2,0L234.8,102.1a19.8,19.8,0,0,0,0-28.2ZM88,215,41,168l19-19,23.5,23.5a12.1,12.1,0,0,0,17,0,12,12,0,0,0,0-17L77,132l19-19,23.5,23.5a12.1,12.1,0,0,0,17,0,12,12,0,0,0,0-17L113,96l19-19,23.5,23.5a12.1,12.1,0,0,0,17,0,12,12,0,0,0,0-17L149,60l19-19,47,47Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M226.3,93.7,93.7,226.3a8,8,0,0,1-11.4,0L29.7,173.7a8,8,0,0,1,0-11.4L162.3,29.7a8,8,0,0,1,11.4,0l52.6,52.6A8,8,0,0,1,226.3,93.7Z"
            opacity="0.2"
          />
          <path d="M232,76.7,179.3,24a16.1,16.1,0,0,0-22.6,0L90.3,90.3h0l-36,36h0L24,156.7a15.9,15.9,0,0,0,0,22.6L76.7,232a15.9,15.9,0,0,0,22.6,0L232,99.3a15.9,15.9,0,0,0,0-22.6ZM220.7,88h0L88,220.7,35.3,168,60,143.3l26.3,26.4a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4L71.3,132,96,107.3l26.3,26.4a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4L107.3,96,132,71.3l26.3,26.4a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4L143.3,60,168,35.3,220.7,88Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M236.7,88.4a16.6,16.6,0,0,1-5,11.3L99.3,232a15.9,15.9,0,0,1-22.6,0L24,179.3a15.9,15.9,0,0,1,0-22.6l21.9-21.9a4,4,0,0,1,5.6,0l34.8,34.9A8.5,8.5,0,0,0,92,172a8,8,0,0,0,6.1-2.8,8.3,8.3,0,0,0-.6-11.1L62.8,123.5a4,4,0,0,1,0-5.6L81.9,98.8a4,4,0,0,1,5.6,0l34.8,34.9A8.5,8.5,0,0,0,128,136a8,8,0,0,0,6.1-2.8,8.3,8.3,0,0,0-.6-11.1L98.8,87.5a4,4,0,0,1,0-5.6l19.1-19.1a4,4,0,0,1,5.6,0l34.8,34.9A8.5,8.5,0,0,0,164,100a8,8,0,0,0,6.1-2.8,8.3,8.3,0,0,0-.6-11.1L134.8,51.5a4,4,0,0,1,0-5.6L156.7,24a16.1,16.1,0,0,1,22.6,0L232,76.7A16,16,0,0,1,236.7,88.4Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M230.6,78.1,177.9,25.4a14.1,14.1,0,0,0-19.8,0L25.4,158.1a14,14,0,0,0,0,19.8l52.7,52.7a14,14,0,0,0,19.8,0L230.6,97.9a14,14,0,0,0,0-19.8Zm-8.5,11.3h0L89.4,222.1a1.9,1.9,0,0,1-2.8,0L33.9,169.4a1.9,1.9,0,0,1,0-2.8L60,140.5l27.8,27.7a5.9,5.9,0,0,0,8.4-8.4L68.5,132,96,104.5l27.8,27.7a5.9,5.9,0,1,0,8.4-8.4L104.5,96,132,68.5l27.8,27.7a5.9,5.9,0,1,0,8.4-8.4L140.5,60l26.1-26.1a1.9,1.9,0,0,1,2.8,0l52.7,52.7a1.9,1.9,0,0,1,0,2.8Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M229.2,79.5,176.5,26.8a12.2,12.2,0,0,0-17,0L26.8,159.5a12.1,12.1,0,0,0,0,17l52.7,52.7a12.1,12.1,0,0,0,17,0L229.2,96.5a12.1,12.1,0,0,0,0-17Zm-5.7,11.3h0L90.8,223.5a3.9,3.9,0,0,1-5.6,0L32.5,170.8a3.9,3.9,0,0,1,0-5.6L60,137.7l29.2,29.1a3.9,3.9,0,0,0,5.6,0,3.9,3.9,0,0,0,0-5.6L65.7,132,96,101.7l29.2,29.1a3.9,3.9,0,0,0,5.6,0,3.9,3.9,0,0,0,0-5.6L101.7,96,132,65.7l29.2,29.1a3.9,3.9,0,0,0,5.6,0,3.9,3.9,0,0,0,0-5.6L137.7,60l27.5-27.5a3.9,3.9,0,0,1,5.6,0l52.7,52.7a3.9,3.9,0,0,1,0,5.6Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M232,76.7,179.3,24a16.1,16.1,0,0,0-22.6,0L90.3,90.3h0l-36,36h0L24,156.7a15.9,15.9,0,0,0,0,22.6L76.7,232a15.9,15.9,0,0,0,22.6,0L232,99.3a15.9,15.9,0,0,0,0-22.6ZM220.7,88h0L88,220.7,35.3,168,60,143.3l26.3,26.4a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4L71.3,132,96,107.3l26.3,26.4a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4L107.3,96,132,71.3l26.3,26.4a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4L143.3,60,168,35.3,220.7,88Z" />
        </>
      )}
    </svg>
  );
}

export function PhScales(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M243.1,131.5l-32-80a.3.3,0,0,0-.1-.2c0-.1-.1-.3-.1-.4l-.3-.6a.5.5,0,0,1-.2-.4l-.6-.8c0-.1-.1-.2-.2-.3l-.4-.5-.3-.3-.4-.5-.3-.3-.8-.6-.2-.2-.6-.4-.4-.3-.5-.3-.4-.2-.6-.2-.7-.3-.5-.2h-5.1l-.9.2h-.2L140,57V40a12,12,0,0,0-24,0V62.4L53.4,76.3h0l-1.1.3H52l-.7.3h-.4l-.7.4-.3.2-.7.4-.4.3-.5.4L48,79l-.6.6c0,.1-.1.1-.1.2a2.7,2.7,0,0,0-.7.8l-.2.2-.4.6-.2.3a4.3,4.3,0,0,0-.4.7v.2a3.6,3.6,0,0,0-.4.9h-.1L12.9,163.5A12,12,0,0,0,12,168c0,12.1,6.2,22.4,17.5,29a53.7,53.7,0,0,0,53,0c11.3-6.6,17.5-16.9,17.5-29a12,12,0,0,0-.9-4.5L72.4,96.7,116,87V204H104a12,12,0,0,0,0,24h48a12,12,0,0,0,0-24H140V81.6l40.4-9-23.5,58.9a12,12,0,0,0-.9,4.5c0,12.1,6.2,22.4,17.5,29a53.7,53.7,0,0,0,53,0c11.3-6.6,17.5-16.9,17.5-29A12,12,0,0,0,243.1,131.5ZM56,180c-3.7,0-18-1.9-19.8-10.2L56,120.3l19.8,49.5C74,178.1,59.7,180,56,180Zm144-32c-3.7,0-18-1.9-19.8-10.2L200,88.3l19.8,49.5C218,146.1,203.7,148,200,148Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M56,88l32,80c0,17.7-20,24-32,24s-32-6.3-32-24ZM200,56l-32,80c0,17.7,20,24,32,24s32-6.3,32-24Z"
            opacity="0.2"
          />
          <path d="M239.4,133l-32-80h0l-.5-.9h0l-.6-.8c-.1-.1-.1-.1-.1-.2l-.8-.8a.1.1,0,0,1-.1-.1,1.8,1.8,0,0,0-.7-.5l-.2-.2-.9-.5h-.2l-.8-.3h-.2l-1-.2h-3L136,62V40a8,8,0,0,0-16,0V65.6L54.3,80.2h-.7l-1,.4h-.2l-.8.4a.1.1,0,0,1-.1.1l-.9.7a.1.1,0,0,1-.1.1l-.6.7h-.1a2.4,2.4,0,0,0-.6.9l-.2.2-.4.9h-.1L16.6,165a8,8,0,0,0-.6,3c0,23.3,24.5,32,40,32s40-8.7,40-32a8,8,0,0,0-.6-3L66.9,93.8,120,82V208H104a8,8,0,0,0,0,16h48a8,8,0,0,0,0-16H136V78.4l50.9-11.3L160.6,133a8,8,0,0,0-.6,3c0,23.3,24.5,32,40,32s40-8.7,40-32A8,8,0,0,0,239.4,133ZM56,184c-7.5,0-22.8-3.6-23.9-14.6L56,109.5l23.9,59.9C78.8,180.4,63.5,184,56,184Zm144-32c-7.5,0-22.8-3.6-23.9-14.6L200,77.5l23.9,59.9C222.8,148.4,207.5,152,200,152Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M239.4,133l-32-80h0l-.5-.9h0l-.6-.8c-.1-.1-.1-.1-.1-.2l-.8-.8a.1.1,0,0,1-.1-.1l-.7-.5-.2-.2-.9-.5h-.2l-.8-.3h-.2l-1-.2h-3L136,62V40a8,8,0,0,0-16,0V65.6L54.3,80.2h-.7l-1,.4h-.2l-.8.4a.1.1,0,0,1-.1.1l-.9.7a.1.1,0,0,1-.1.1l-.6.7h-.1a2.4,2.4,0,0,0-.6.9l-.2.2-.4.9h-.1L16.6,165a8,8,0,0,0-.6,3c0,23.3,24.5,32,40,32s40-8.7,40-32a8,8,0,0,0-.6-3L66.9,93.8,120,82V208H104a8,8,0,0,0,0,16h48a8,8,0,0,0,0-16H136V78.4l50.9-11.3L160.6,133a8,8,0,0,0-.6,3c0,23.3,24.5,32,40,32s40-8.7,40-32A8,8,0,0,0,239.4,133Zm-160,35H32.6L56,109.5Zm97.2-32L200,77.5,223.4,136Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M237.6,133.8l-32-80h-.1l-.3-.7a.1.1,0,0,0-.1-.1,2.1,2.1,0,0,0-.4-.6h-.1l-.5-.6a.1.1,0,0,1-.1-.1l-.5-.4h-.2l-.6-.4c-.1,0-.2,0-.2-.1l-.6-.2h-.2l-.7-.2h-2.3L134,64.5V40a6,6,0,0,0-12,0V67.2L54.7,82.1h-.5l-.8.3h-.1l-.6.4h-.1l-.6.4h-.1l-.5.6h-.1l-.4.6h-.1a4.3,4.3,0,0,1-.4.7h0l-32,80A5.4,5.4,0,0,0,18,168c0,21.9,23.3,30,38,30s38-8.1,38-30a5.4,5.4,0,0,0-.4-2.2L64.2,92.3,122,79.5V210H104a6,6,0,0,0,0,12h48a6,6,0,0,0,0-12H134V76.8l56.2-12.5-27.8,69.5a5.4,5.4,0,0,0-.4,2.2c0,21.9,23.3,30,38,30s38-8.1,38-30A5.4,5.4,0,0,0,237.6,133.8ZM56,186a36.6,36.6,0,0,1-17.5-4.6c-5.3-3.1-8.1-7.1-8.5-12.3l26-64.9,26,64.9C81.1,182.9,62.6,186,56,186Zm144-32a36.6,36.6,0,0,1-17.5-4.6c-5.3-3.1-8.1-7.1-8.5-12.3l26-64.9,26,64.9C225.1,150.9,206.6,154,200,154Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M235.7,134.5l-32-80h0c-.1,0-.1-.1-.1-.2l-.3-.4c0-.1-.1-.1-.1-.2s-.2-.3-.4-.4a.1.1,0,0,0-.1-.1,1,1,0,0,0-.5-.4H202l-.4-.3h-2.5L132,67V40a4,4,0,0,0-8,0V68.8L55.1,84.1H55l-.5.2h-.2l-.6.3h-.1l-.4.4H53l-.3.4c0,.1-.1.1-.1.2l-.3.6h0l-32,80A4,4,0,0,0,20,168c0,20.4,22.1,28,36,28s36-7.6,36-28a4,4,0,0,0-.3-1.5L61.5,90.9,124,77V212H104a4,4,0,0,0,0,8h48a4,4,0,0,0,0-8H132V75.2l61.5-13.7-29.2,73a4,4,0,0,0-.3,1.5c0,20.4,22.1,28,36,28s36-7.6,36-28A4,4,0,0,0,235.7,134.5ZM56,188c-7.2,0-27.4-3.6-28-19.3L56,98.8l28,69.9C83.4,184.4,63.2,188,56,188Zm144-32c-7.2,0-27.4-3.6-28-19.3l28-69.9,28,69.9C227.4,152.4,207.2,156,200,156Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M239.4,133l-32-80h0l-.5-.9h0l-.6-.8c-.1-.1-.1-.1-.1-.2l-.8-.8h-.1l-.7-.5-.2-.2-.9-.5h-.1l-.9-.3h-.2l-1-.2h-3L136,62V40a8,8,0,0,0-16,0V65.6L54.3,80.2h-.7l-1,.4h-.2l-.8.4a.1.1,0,0,1-.1.1l-.9.7a.1.1,0,0,1-.1.1l-.6.7h-.1a2.4,2.4,0,0,0-.6.9l-.2.2-.4.9h-.1L16.6,165a8,8,0,0,0-.6,3c0,23.3,24.5,32,40,32s40-8.7,40-32a8,8,0,0,0-.6-3L66.9,93.8,120,82V208H104a8,8,0,0,0,0,16h48a8,8,0,0,0,0-16H136V78.4l50.9-11.3L160.6,133a8,8,0,0,0-.6,3c0,23.3,24.5,32,40,32s40-8.7,40-32A8,8,0,0,0,239.4,133ZM56,184c-7.5,0-22.8-3.6-23.9-14.6L56,109.5l23.9,59.9C78.8,180.4,63.5,184,56,184Zm144-32c-7.5,0-22.8-3.6-23.9-14.6L200,77.5l23.9,59.9C222.8,148.4,207.5,152,200,152Z" />
        </>
      )}
    </svg>
  );
}

export function PhScan(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M228,40V80a12,12,0,0,1-24,0V52H176a12,12,0,0,1,0-24h40A12,12,0,0,1,228,40ZM80,204H52V176a12,12,0,0,0-24,0v40a12,12,0,0,0,12,12H80a12,12,0,0,0,0-24Zm136-40a12,12,0,0,0-12,12v28H176a12,12,0,0,0,0,24h40a12,12,0,0,0,12-12V176A12,12,0,0,0,216,164ZM40,92A12,12,0,0,0,52,80V52H80a12,12,0,0,0,0-24H40A12,12,0,0,0,28,40V80A12,12,0,0,0,40,92Zm124,92H92a20.1,20.1,0,0,1-20-20V92A20.1,20.1,0,0,1,92,72h72a20.1,20.1,0,0,1,20,20v72A20.1,20.1,0,0,1,164,184Zm-4-88H96v64h64Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M176,88v80a8,8,0,0,1-8,8H88a8,8,0,0,1-8-8V88a8,8,0,0,1,8-8h80A8,8,0,0,1,176,88Z"
            opacity="0.2"
          />
          <path d="M224,40V80a8,8,0,0,1-16,0V48H176a8,8,0,0,1,0-16h40A8,8,0,0,1,224,40ZM80,208H48V176a8,8,0,0,0-16,0v40a8,8,0,0,0,8,8H80a8,8,0,0,0,0-16Zm136-40a8,8,0,0,0-8,8v32H176a8,8,0,0,0,0,16h40a8,8,0,0,0,8-8V176A8,8,0,0,0,216,168ZM40,88a8,8,0,0,0,8-8V48H80a8,8,0,0,0,0-16H40a8,8,0,0,0-8,8V80A8,8,0,0,0,40,88Zm128,96H88a16,16,0,0,1-16-16V88A16,16,0,0,1,88,72h80a16,16,0,0,1,16,16v80A16,16,0,0,1,168,184ZM88,168h80V88H88Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M32,80V40a8,8,0,0,1,8-8H80a8,8,0,0,1,0,16H48V80a8,8,0,0,1-16,0ZM216,32H176a8,8,0,0,0,0,16h32V80a8,8,0,0,0,16,0V40A8,8,0,0,0,216,32ZM80,208H48V176a8,8,0,0,0-16,0v40a8,8,0,0,0,8,8H80a8,8,0,0,0,0-16Zm104-40V88a16,16,0,0,0-16-16H88A16,16,0,0,0,72,88v80a16,16,0,0,0,16,16h80A16,16,0,0,0,184,168Zm32,0a8,8,0,0,0-8,8v32H176a8,8,0,0,0,0,16h40a8,8,0,0,0,8-8V176A8,8,0,0,0,216,168Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M222,40V80a6,6,0,0,1-12,0V46H176a6,6,0,0,1,0-12h40A6,6,0,0,1,222,40ZM80,210H46V176a6,6,0,0,0-12,0v40a6,6,0,0,0,6,6H80a6,6,0,0,0,0-12Zm136-40a6,6,0,0,0-6,6v34H176a6,6,0,0,0,0,12h40a6,6,0,0,0,6-6V176A6,6,0,0,0,216,170ZM40,86a6,6,0,0,0,6-6V46H80a6,6,0,0,0,0-12H40a6,6,0,0,0-6,6V80A6,6,0,0,0,40,86Zm128,96H88a14,14,0,0,1-14-14V88A14,14,0,0,1,88,74h80a14,14,0,0,1,14,14v80A14,14,0,0,1,168,182Zm2-94a2,2,0,0,0-2-2H88a2,2,0,0,0-2,2v80a2,2,0,0,0,2,2h80a2,2,0,0,0,2-2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M220,40V80a4,4,0,0,1-8,0V44H176a4,4,0,0,1,0-8h40A4,4,0,0,1,220,40ZM80,212H44V176a4,4,0,0,0-8,0v40a4,4,0,0,0,4,4H80a4,4,0,0,0,0-8Zm136-40a4,4,0,0,0-4,4v36H176a4,4,0,0,0,0,8h40a4,4,0,0,0,4-4V176A4,4,0,0,0,216,172ZM40,84a4,4,0,0,0,4-4V44H80a4,4,0,0,0,0-8H40a4,4,0,0,0-4,4V80A4,4,0,0,0,40,84Zm128,96H88a12,12,0,0,1-12-12V88A12,12,0,0,1,88,76h80a12,12,0,0,1,12,12v80A12,12,0,0,1,168,180Zm4-92a4,4,0,0,0-4-4H88a4,4,0,0,0-4,4v80a4,4,0,0,0,4,4h80a4,4,0,0,0,4-4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M224,40V80a8,8,0,0,1-16,0V48H176a8,8,0,0,1,0-16h40A8,8,0,0,1,224,40ZM80,208H48V176a8,8,0,0,0-16,0v40a8,8,0,0,0,8,8H80a8,8,0,0,0,0-16Zm136-40a8,8,0,0,0-8,8v32H176a8,8,0,0,0,0,16h40a8,8,0,0,0,8-8V176A8,8,0,0,0,216,168ZM40,88a8,8,0,0,0,8-8V48H80a8,8,0,0,0,0-16H40a8,8,0,0,0-8,8V80A8,8,0,0,0,40,88Zm128,96H88a16,16,0,0,1-16-16V88A16,16,0,0,1,88,72h80a16,16,0,0,1,16,16v80A16,16,0,0,1,168,184ZM88,168h80V88H88Z" />
        </>
      )}
    </svg>
  );
}

export function PhScissors(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M238.8,183.8,98.3,87.7A41.9,41.9,0,0,0,100,76a40.1,40.1,0,1,0-15.3,31.4l30,20.6-30,20.6A40,40,0,1,0,100,180a41.9,41.9,0,0,0-1.7-11.7L136,142.5l89.2,61.1a12.3,12.3,0,0,0,6.8,2.1,11.9,11.9,0,0,0,9.9-5.2A12.1,12.1,0,0,0,238.8,183.8ZM71.3,191.3h0A15.9,15.9,0,1,1,76,180,15.9,15.9,0,0,1,71.3,191.3ZM48.7,87.3a16,16,0,1,1,22.6,0h0A15.9,15.9,0,0,1,48.7,87.3Zm112.8,23.2a12,12,0,0,1,3.1-16.6l60.6-41.5a12,12,0,0,1,13.6,19.8l-60.6,41.5a12,12,0,0,1-16.7-3.2Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="60" cy="76" r="28" />
          </g>
          <g opacity="0.2">
            <circle cx="60" cy="180" r="28" />
          </g>
          <path d="M164.3,116.6a7.8,7.8,0,0,0,4.6-1.4l67.6-46.3a7.9,7.9,0,0,0,2.1-11.1,8,8,0,0,0-11.1-2.1L159.8,102a8,8,0,0,0,4.5,14.6Z" />
          <path d="M236.5,187.1,93.5,89.2A36.4,36.4,0,0,0,96,76,35.9,35.9,0,0,0,85.5,50.5a36.2,36.2,0,0,0-51,0,36,36,0,0,0,49.9,51.9L121.8,128,84.4,153.6A36,36,0,1,0,96,180a36.4,36.4,0,0,0-2.5-13.2L136,137.7l91.5,62.6a8.2,8.2,0,0,0,4.5,1.4,8,8,0,0,0,4.5-14.6Zm-162.4,7a19.9,19.9,0,0,1-28.2,0,19.8,19.8,0,0,1,0-28.2,19.8,19.8,0,0,1,28.2,0,19.8,19.8,0,0,1,0,28.2ZM45.9,90.1a19.8,19.8,0,0,1,0-28.2,19.8,19.8,0,0,1,28.2,0,19.8,19.8,0,0,1,0,28.2A19.9,19.9,0,0,1,45.9,90.1Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M157.7,113.1a8,8,0,0,1,2.1-11.1l67.7-46.3a8,8,0,0,1,11.1,2.1,7.9,7.9,0,0,1-2.1,11.1l-67.6,46.3a7.8,7.8,0,0,1-4.6,1.4A8,8,0,0,1,157.7,113.1Zm80.9,85.1a7.9,7.9,0,0,1-6.6,3.5,8.2,8.2,0,0,1-4.5-1.4L136,137.7,93.5,166.8A36.4,36.4,0,0,1,96,180a36.1,36.1,0,1,1-11.6-26.4L121.8,128,84.4,102.4A35.5,35.5,0,0,1,60,112,36,36,0,1,1,93.5,89.2l143,97.9A7.9,7.9,0,0,1,238.6,198.2ZM80,180a19.7,19.7,0,0,0-5.9-14.1,19.8,19.8,0,0,0-28.2,0,19.8,19.8,0,0,0,0,28.2,19.9,19.9,0,0,0,28.2,0A19.7,19.7,0,0,0,80,180ZM74.1,90.1a19.8,19.8,0,0,0,0-28.2,19.8,19.8,0,0,0-28.2,0,19.8,19.8,0,0,0,0,28.2A19.9,19.9,0,0,0,74.1,90.1Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M159.4,112a5.9,5.9,0,0,1,1.5-8.3l67.7-46.3a6.1,6.1,0,0,1,8.4,1.5,6.2,6.2,0,0,1-1.6,8.4l-67.7,46.3a5.9,5.9,0,0,1-3.4,1A5.8,5.8,0,0,1,159.4,112ZM237,197.1a6.1,6.1,0,0,1-5,2.6,6,6,0,0,1-3.4-1.1L136,135.3,91,166.1A33.6,33.6,0,0,1,94,180a34,34,0,1,1-34-34,33.8,33.8,0,0,1,24.2,10.2L125.4,128,84.2,99.8A33.8,33.8,0,0,1,60,110,34,34,0,1,1,94,76a33.6,33.6,0,0,1-3,13.9l144.4,98.8A6.2,6.2,0,0,1,237,197.1ZM82,76A22,22,0,1,0,60,98,22.1,22.1,0,0,0,82,76Zm0,104a22,22,0,1,0-22,22A22.1,22.1,0,0,0,82,180Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M161,110.9a4.1,4.1,0,0,1,1.1-5.6L229.7,59a4,4,0,0,1,4.6,6.6l-67.7,46.3a3.9,3.9,0,0,1-2.3.7A4.2,4.2,0,0,1,161,110.9Zm74.3,85a4,4,0,0,1-5.6,1.1L136,132.8,88.5,165.4A32.1,32.1,0,0,1,60,212a32,32,0,0,1-22.6-54.6,31.9,31.9,0,0,1,45.2,0,8.6,8.6,0,0,1,1.3,1.4l45-30.8-45-30.8a8.6,8.6,0,0,1-1.3,1.4,31.9,31.9,0,0,1-45.2,0,31.9,31.9,0,0,1,0-45.2,31.9,31.9,0,0,1,45.2,0,32,32,0,0,1,5.9,37.2l49.7,34h0l96.1,65.8A4,4,0,0,1,235.3,195.9ZM77,93a29.7,29.7,0,0,0,2.8-3.4h0A23.7,23.7,0,0,0,84,76a24.2,24.2,0,0,0-7-17,24.1,24.1,0,0,0-34,0A24,24,0,0,0,77,93Zm7,87a24.6,24.6,0,0,0-4.1-13.5h-.1A29.7,29.7,0,0,0,77,163a24.1,24.1,0,0,0-34,0,24,24,0,0,0,34,34h0A24.2,24.2,0,0,0,84,180Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M157.7,113.1a8,8,0,0,1,2.1-11.1l67.7-46.3a8,8,0,0,1,11.1,2.1,7.9,7.9,0,0,1-2.1,11.1l-67.6,46.3a7.8,7.8,0,0,1-4.6,1.4A8,8,0,0,1,157.7,113.1Zm80.9,85.1a7.9,7.9,0,0,1-6.6,3.5,8.2,8.2,0,0,1-4.5-1.4L136,137.7,93.5,166.8A36.4,36.4,0,0,1,96,180a36.1,36.1,0,1,1-11.6-26.4L121.8,128,84.4,102.4A35.5,35.5,0,0,1,60,112,36,36,0,1,1,93.5,89.2l143,97.9A7.9,7.9,0,0,1,238.6,198.2ZM80,180a19.7,19.7,0,0,0-5.9-14.1,19.8,19.8,0,0,0-28.2,0,19.8,19.8,0,0,0,0,28.2,19.9,19.9,0,0,0,28.2,0A19.7,19.7,0,0,0,80,180ZM74.1,90.1a19.8,19.8,0,0,0,0-28.2,19.8,19.8,0,0,0-28.2,0,19.8,19.8,0,0,0,0,28.2A19.9,19.9,0,0,0,74.1,90.1Z" />
        </>
      )}
    </svg>
  );
}

export function PhScreencast(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M232,60V196a20.1,20.1,0,0,1-20,20H172a12,12,0,0,1,0-24h36V64H48v4a12,12,0,0,1-24,0V60A20.1,20.1,0,0,1,44,40H212A20.1,20.1,0,0,1,232,60ZM36,176a12,12,0,0,0,0,24,4,4,0,0,1,4,4,12,12,0,0,0,24,0A28.1,28.1,0,0,0,36,176Zm0-40a12,12,0,0,0,0,24,44,44,0,0,1,44,44,12,12,0,0,0,24,0A68.1,68.1,0,0,0,36,136Zm0-40a12,12,0,0,0,0,24,84.1,84.1,0,0,1,84,84,12,12,0,0,0,24,0A108.1,108.1,0,0,0,36,96Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M216,48H40a8,8,0,0,0-8,8V192a16,16,0,0,1,16,16H216a8,8,0,0,0,8-8V56A8,8,0,0,0,216,48Z" />
          </g>
          <path d="M216,40H40A16,16,0,0,0,24,56V96a8,8,0,0,0,16,0V56H216V200H144a8,8,0,0,0,0,16h72a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40Z" />
          <path d="M32,184a8,8,0,0,0,0,16,8,8,0,0,1,8,8,8,8,0,0,0,16,0A24.1,24.1,0,0,0,32,184Z" />
          <path d="M32,152a8,8,0,0,0,0,16,40,40,0,0,1,40,40,8,8,0,0,0,16,0A56,56,0,0,0,32,152Z" />
          <path d="M32,120a8,8,0,0,0,0,16,72.1,72.1,0,0,1,72,72,8,8,0,0,0,16,0A88.1,88.1,0,0,0,32,120Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M56,208a8,8,0,0,1-16,0,8,8,0,0,0-8-8,8,8,0,0,1,0-16A24.1,24.1,0,0,1,56,208ZM32,152a8,8,0,0,0,0,16,40,40,0,0,1,40,40,8,8,0,0,0,16,0A56,56,0,0,0,32,152Zm0-32a8,8,0,0,0,0,16,72.1,72.1,0,0,1,72,72,8,8,0,0,0,16,0A88.1,88.1,0,0,0,32,120ZM216,40H40A16,16,0,0,0,24,56V96a8,8,0,0,0,8,8A104.2,104.2,0,0,1,136,208a8,8,0,0,0,8,8h72a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M230,56V200a14,14,0,0,1-14,14H144a6,6,0,0,1,0-12h72a2,2,0,0,0,2-2V56a2,2,0,0,0-2-2H40a2,2,0,0,0-2,2V96a6,6,0,0,1-12,0V56A14,14,0,0,1,40,42H216A14,14,0,0,1,230,56ZM32,186a6,6,0,0,0,0,12,10,10,0,0,1,10,10,6,6,0,0,0,12,0A22.1,22.1,0,0,0,32,186Zm0-32a6,6,0,0,0,0,12,42,42,0,0,1,42,42,6,6,0,0,0,12,0A54,54,0,0,0,32,154Zm0-32a6,6,0,0,0,0,12,74.1,74.1,0,0,1,74,74,6,6,0,0,0,12,0A86.1,86.1,0,0,0,32,122Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M228,56V200a12,12,0,0,1-12,12H144a4,4,0,0,1,0-8h72a4,4,0,0,0,4-4V56a4,4,0,0,0-4-4H40a4,4,0,0,0-4,4V96a4,4,0,0,1-8,0V56A12,12,0,0,1,40,44H216A12,12,0,0,1,228,56ZM32,188a4,4,0,0,0,0,8,12,12,0,0,1,12,12,4,4,0,0,0,8,0A20.1,20.1,0,0,0,32,188Zm0-32a4,4,0,0,0,0,8,44,44,0,0,1,44,44,4,4,0,0,0,8,0A52,52,0,0,0,32,156Zm0-32a4,4,0,0,0,0,8,76.1,76.1,0,0,1,76,76,4,4,0,0,0,8,0A84.1,84.1,0,0,0,32,124Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M232,56V200a16,16,0,0,1-16,16H144a8,8,0,0,1,0-16h72V56H40V96a8,8,0,0,1-16,0V56A16,16,0,0,1,40,40H216A16,16,0,0,1,232,56ZM32,184a8,8,0,0,0,0,16,8,8,0,0,1,8,8,8,8,0,0,0,16,0A24.1,24.1,0,0,0,32,184Zm0-32a8,8,0,0,0,0,16,40,40,0,0,1,40,40,8,8,0,0,0,16,0A56,56,0,0,0,32,152Zm0-32a8,8,0,0,0,0,16,72.1,72.1,0,0,1,72,72,8,8,0,0,0,16,0A88.1,88.1,0,0,0,32,120Z" />
        </>
      )}
    </svg>
  );
}

export function PhScribbleLoop(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M249.2,152.3c-1.2-1.5-22.7-26.7-59.2-41.6-2.5-17.7-9.5-33.8-20.2-46.4C153.9,45.8,131.2,36,104,36,50.4,36,15.7,83.8,14.2,85.8a12,12,0,0,0,19.6,14C34,99.4,62.7,60,104,60c20,0,36.4,6.9,47.5,19.9a64,64,0,0,1,12.3,22.9A137.2,137.2,0,0,0,136,100c-27,0-49.8,7.1-65.8,20.6-14.5,12.2-22.8,29.2-22.8,46.6,0,26.3,19.4,52.8,56.6,52.8,26.5,0,49-10.5,65.2-30.3,11.6-14.2,19-32.8,21.2-52.3,25.3,12.7,40.2,30,40.4,30.3a12,12,0,1,0,18.4-15.4ZM104,196c-22.5,0-32.6-14.4-32.6-28.8,0-20.8,20.2-43.2,64.6-43.2a113.3,113.3,0,0,1,31,4.3C165.9,162.4,144.3,196,104,196Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M246.1,154.9c-1.2-1.5-22.9-27.1-59.8-41.4-2.1-17.8-8.9-34-19.6-46.6C151.7,49.3,130,40,104,40,52.5,40,18.9,86.2,17.5,88.1a8,8,0,0,0,13,9.4C30.8,97,60.8,56,104,56c21.2,0,38.6,7.4,50.5,21.3a68,68,0,0,1,14.7,30.8A134.2,134.2,0,0,0,136,104c-26.1,0-47.9,6.8-63.3,19.7C59.2,135.1,51.4,151,51.4,167.2a47.4,47.4,0,0,0,13.9,34.1c9.6,9.6,23,14.7,38.7,14.7,25.2,0,46.7-10,62.1-28.8,12.2-15,19.6-35.1,20.8-56a146.3,146.3,0,0,1,47,33.9,8,8,0,1,0,12.2-10.2ZM104,200c-25.3,0-36.6-16.4-36.6-32.8,0-22.7,21.5-47.2,68.6-47.2a117.3,117.3,0,0,1,35,5.3v.3C171,162.6,148,200,104,200Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M246.1,154.9c-1.2-1.5-22.9-27.1-59.8-41.4-2.1-17.8-8.9-34-19.6-46.6C151.7,49.3,130,40,104,40,52.5,40,18.9,86.2,17.5,88.1a8,8,0,0,0,13,9.4C30.8,97,60.8,56,104,56c21.2,0,38.6,7.4,50.5,21.3a68,68,0,0,1,14.7,30.8A134.2,134.2,0,0,0,136,104c-26.1,0-47.9,6.8-63.3,19.7C59.2,135.1,51.4,151,51.4,167.2a47.4,47.4,0,0,0,13.9,34.1c9.6,9.6,23,14.7,38.7,14.7,25.2,0,46.7-10,62.1-28.8,12.2-15,19.6-35.1,20.8-56a146.3,146.3,0,0,1,47,33.9,8,8,0,1,0,12.2-10.2ZM104,200c-25.3,0-36.6-16.4-36.6-32.8,0-22.7,21.5-47.2,68.6-47.2a117.3,117.3,0,0,1,35,5.3v.3C171,162.6,148,200,104,200Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M244.6,156.2c-1.2-1.5-23-27.2-60.1-41.2-1.9-17.9-8.6-34.3-19.3-46.8C150.5,51.1,129.4,42,104,42,53.5,42,20.5,87.4,19.1,89.3a6,6,0,0,0,9.8,7C29.2,95.9,59.7,54,104,54c21.8,0,39.8,7.6,52.1,22,8,9.4,13.4,21.5,15.7,34.8A134.7,134.7,0,0,0,136,106c-25.6,0-47,6.6-62,19.2-13.1,11-20.6,26.4-20.6,42a45.5,45.5,0,0,0,13.3,32.7C76,209.1,88.9,214,104,214c24.6,0,45.5-9.7,60.5-28.1,12.5-15.4,19.9-36.2,20.5-57.7a149.2,149.2,0,0,1,50.4,35.6,6,6,0,0,0,9.2-7.6ZM104,202c-18.6,0-38.6-10.9-38.6-34.8S87.5,118,136,118a122.6,122.6,0,0,1,37,5.8v1.8C173,175.8,138.3,202,104,202Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M243.1,157.4a155.1,155.1,0,0,0-60.5-41c-1.7-18-8.2-34.4-18.9-46.9C149.4,52.8,128.8,44,104,44,54.5,44,22.1,88.6,20.7,90.5a4,4,0,0,0,6.6,4.6C27.6,94.7,58.7,52,104,52c22.4,0,40.9,7.9,53.6,22.7,8.9,10.4,14.6,24,16.6,38.9A134.4,134.4,0,0,0,136,108c-25.1,0-46.1,6.5-60.7,18.8-12.6,10.6-19.9,25.3-19.9,40.4a43.8,43.8,0,0,0,12.7,31.3C77,207.3,89.4,212,104,212c24,0,44.4-9.5,59-27.4,12.7-15.6,20-37.1,20-59v-.3c33.7,13.4,53.7,36.9,53.9,37.3a4,4,0,0,0,6.2-5.2ZM104,204c-27.9,0-40.6-19.1-40.6-36.8,0-24.6,22.7-51.2,72.6-51.2a125.5,125.5,0,0,1,39,6.3v3.3C175,177.1,139.3,204,104,204Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M246.1,154.9c-1.2-1.5-22.9-27.1-59.8-41.4-2.1-17.8-8.9-34-19.6-46.6C151.7,49.3,130,40,104,40,52.5,40,18.9,86.2,17.5,88.1a8,8,0,0,0,13,9.4C30.8,97,60.8,56,104,56c21.2,0,38.6,7.4,50.5,21.3a68,68,0,0,1,14.7,30.8A134.2,134.2,0,0,0,136,104c-26.1,0-47.9,6.8-63.3,19.7C59.2,135.1,51.4,151,51.4,167.2a47.4,47.4,0,0,0,13.9,34.1c9.6,9.6,23,14.7,38.7,14.7,25.2,0,46.7-10,62.1-28.8,12.2-15,19.6-35.1,20.8-56a146.3,146.3,0,0,1,47,33.9,8,8,0,1,0,12.2-10.2ZM104,200c-25.3,0-36.6-16.4-36.6-32.8,0-22.7,21.5-47.2,68.6-47.2a117.3,117.3,0,0,1,35,5.3v.3C171,162.6,148,200,104,200Z" />
        </>
      )}
    </svg>
  );
}

export function PhScroll(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M92,100a12,12,0,0,1,12-12h64a12,12,0,0,1,0,24H104A12,12,0,0,1,92,100Zm12,52h64a12,12,0,0,0,0-24H104a12,12,0,0,0,0,24Zm132,40a36,36,0,0,1-36,36H88a36,36,0,0,1-36-36V64a12,12,0,0,0-24,0,11.8,11.8,0,0,0,3.1,8A12,12,0,0,1,13.2,88,36,36,0,0,1,40,28H176a36,36,0,0,1,36,36V164h5.9a12,12,0,0,1,8.9,4A36,36,0,0,1,236,192ZM94.9,171.1a12.2,12.2,0,0,1,11-7.1H188V64a12,12,0,0,0-12-12H73.9A34.4,34.4,0,0,1,76,64V192a12,12,0,0,0,24,0,11.8,11.8,0,0,0-3.1-8A12.2,12.2,0,0,1,94.9,171.1ZM212,192a10.9,10.9,0,0,0-.7-4H123.8c.1,1.3.2,2.7.2,4a34.4,34.4,0,0,1-2.1,12H200A12,12,0,0,0,212,192Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M224,192a23.9,23.9,0,0,1-24,24H88a24,24,0,0,0,17.9-40h112A23.9,23.9,0,0,1,224,192Z"
            opacity="0.2"
          />
          <path d="M96,104a8,8,0,0,1,8-8h64a8,8,0,0,1,0,16H104A8,8,0,0,1,96,104Zm72,40a8,8,0,0,0,0-16H104a8,8,0,0,0,0,16Zm64,48a32.1,32.1,0,0,1-32,32H88a32.1,32.1,0,0,1-32-32V64A16,16,0,1,0,28.1,74.7,8,8,0,0,1,27.4,86a7.9,7.9,0,0,1-11.2-.7A31.5,31.5,0,0,1,8,64,32.1,32.1,0,0,1,40,32H176a32.1,32.1,0,0,1,32,32V168h9.9a7.8,7.8,0,0,1,5.9,2.7A31.5,31.5,0,0,1,232,192ZM98.6,172.7a8.1,8.1,0,0,1,7.3-4.7H192V64a16,16,0,0,0-16-16H67.7A32.2,32.2,0,0,1,72,64V192a16,16,0,1,0,27.9-10.7A8,8,0,0,1,98.6,172.7ZM216,192a15.9,15.9,0,0,0-2.1-8H119a30.8,30.8,0,0,1,1,8,32.2,32.2,0,0,1-4.3,16H200A16,16,0,0,0,216,192Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M223.8,170.7a7.8,7.8,0,0,0-5.9-2.7H208V64a32.1,32.1,0,0,0-32-32H40A32.1,32.1,0,0,0,8,64a31.5,31.5,0,0,0,8.2,21.3,7.9,7.9,0,0,0,11.2.7,8,8,0,0,0,.7-11.3A16,16,0,1,1,56,64V192a32.1,32.1,0,0,0,32,32H200a32.1,32.1,0,0,0,32-32A31.5,31.5,0,0,0,223.8,170.7ZM104,96h64a8,8,0,0,1,0,16H104a8,8,0,0,1,0-16Zm-8,40a8,8,0,0,1,8-8h64a8,8,0,0,1,0,16H104A8,8,0,0,1,96,136Zm104,72H115.7a32.2,32.2,0,0,0,4.3-16,30.8,30.8,0,0,0-1-8h94.9A16.1,16.1,0,0,1,200,208Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M98,104a6,6,0,0,1,6-6h64a6,6,0,0,1,0,12H104A6,6,0,0,1,98,104Zm70,38a6,6,0,0,0,0-12H104a6,6,0,0,0,0,12Zm62,50a30.1,30.1,0,0,1-30,30H88a30.1,30.1,0,0,1-30-30V64a18,18,0,0,0-36,0,17.8,17.8,0,0,0,4.6,12,6,6,0,1,1-9,8A30,30,0,0,1,40,34H176a30.1,30.1,0,0,1,30,30V170h11.9a6.1,6.1,0,0,1,4.5,2A30,30,0,0,1,230,192Zm-124,0a17.8,17.8,0,0,0-4.6-12,5.8,5.8,0,0,1-1-6.4,6,6,0,0,1,5.5-3.6H194V64a18.1,18.1,0,0,0-18-18H64a29.7,29.7,0,0,1,6,18V192a18,18,0,0,0,36,0Zm112,0a18.4,18.4,0,0,0-3-10H116.3a30.9,30.9,0,0,1,1.7,10,29.7,29.7,0,0,1-6,18h88A18.1,18.1,0,0,0,218,192Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M100,104a4,4,0,0,1,4-4h64a4,4,0,0,1,0,8H104A4,4,0,0,1,100,104Zm68,36a4,4,0,0,0,0-8H104a4,4,0,0,0,0,8Zm60,52a28.1,28.1,0,0,1-28,28H88a28.1,28.1,0,0,1-28-28V64a20,20,0,0,0-40,0,19.7,19.7,0,0,0,5.1,13.3,4,4,0,1,1-6,5.4A28,28,0,0,1,40,36H176a28.1,28.1,0,0,1,28,28V172h13.9a4.3,4.3,0,0,1,3,1.3A28.1,28.1,0,0,1,228,192Zm-120,0a19.7,19.7,0,0,0-5.1-13.3,4.2,4.2,0,0,1-.7-4.3,4,4,0,0,1,3.7-2.4H196V64a20.1,20.1,0,0,0-20-20H59.6A27.9,27.9,0,0,1,68,64V192a20,20,0,0,0,40,0Zm112,0a19.7,19.7,0,0,0-4-12H113.3a27.4,27.4,0,0,1,2.7,12,27.9,27.9,0,0,1-8.4,20H200A20.1,20.1,0,0,0,220,192Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M96,104a8,8,0,0,1,8-8h64a8,8,0,0,1,0,16H104A8,8,0,0,1,96,104Zm72,40a8,8,0,0,0,0-16H104a8,8,0,0,0,0,16Zm64,48a32.1,32.1,0,0,1-32,32H88a32.1,32.1,0,0,1-32-32V64A16,16,0,1,0,28.1,74.7,8,8,0,0,1,27.4,86a7.9,7.9,0,0,1-11.2-.7A31.5,31.5,0,0,1,8,64,32.1,32.1,0,0,1,40,32H176a32.1,32.1,0,0,1,32,32V168h9.9a7.8,7.8,0,0,1,5.9,2.7A31.5,31.5,0,0,1,232,192ZM98.6,172.7a8.1,8.1,0,0,1,7.3-4.7H192V64a16,16,0,0,0-16-16H67.7A32.2,32.2,0,0,1,72,64V192a16,16,0,1,0,27.9-10.7A8,8,0,0,1,98.6,172.7ZM216,192a15.9,15.9,0,0,0-2.1-8H119a30.8,30.8,0,0,1,1,8,32.2,32.2,0,0,1-4.3,16H200A16,16,0,0,0,216,192Z" />
        </>
      )}
    </svg>
  );
}

export function PhSelectionAll(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M100,40a12,12,0,0,1,12-12h32a12,12,0,0,1,0,24H112A12,12,0,0,1,100,40Zm44,164H112a12,12,0,0,0,0,24h32a12,12,0,0,0,0-24ZM208,28H184a12,12,0,0,0,0,24h20V72a12,12,0,0,0,24,0V48A20.1,20.1,0,0,0,208,28Zm8,72a12,12,0,0,0-12,12v32a12,12,0,0,0,24,0V112A12,12,0,0,0,216,100Zm0,72a12,12,0,0,0-12,12v20H184a12,12,0,0,0,0,24h24a20.1,20.1,0,0,0,20-20V184A12,12,0,0,0,216,172ZM40,156a12,12,0,0,0,12-12V112a12,12,0,0,0-24,0v32A12,12,0,0,0,40,156Zm32,48H52V184a12,12,0,0,0-24,0v24a20.1,20.1,0,0,0,20,20H72a12,12,0,0,0,0-24ZM40,84A12,12,0,0,0,52,72V52H72a12,12,0,0,0,0-24H48A20.1,20.1,0,0,0,28,48V72A12,12,0,0,0,40,84ZM80,68h96a12,12,0,0,1,12,12v96a12,12,0,0,1-12,12H80a12,12,0,0,1-12-12V80A12,12,0,0,1,80,68Zm12,96h72V92H92Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M176,80v96H80V80Z" opacity="0.2" />
          <path d="M104,40a8,8,0,0,1,8-8h32a8,8,0,0,1,0,16H112A8,8,0,0,1,104,40Zm40,168H112a8,8,0,0,0,0,16h32a8,8,0,0,0,0-16ZM208,32H184a8,8,0,0,0,0,16h24V72a8,8,0,0,0,16,0V48A16,16,0,0,0,208,32Zm8,72a8,8,0,0,0-8,8v32a8,8,0,0,0,16,0V112A8,8,0,0,0,216,104Zm0,72a8,8,0,0,0-8,8v24H184a8,8,0,0,0,0,16h24a16,16,0,0,0,16-16V184A8,8,0,0,0,216,176ZM40,152a8,8,0,0,0,8-8V112a8,8,0,0,0-16,0v32A8,8,0,0,0,40,152Zm32,56H48V184a8,8,0,0,0-16,0v24a16,16,0,0,0,16,16H72a8,8,0,0,0,0-16ZM40,80a8,8,0,0,0,8-8V48H72a8,8,0,0,0,0-16H48A16,16,0,0,0,32,48V72A8,8,0,0,0,40,80ZM176,184H80a8,8,0,0,1-8-8V80a8,8,0,0,1,8-8h96a8,8,0,0,1,8,8v96A8,8,0,0,1,176,184Zm-8-96H88v80h80Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M32,72V48A16,16,0,0,1,48,32H72a8,8,0,0,1,0,16H48V72a8,8,0,0,1-16,0Zm8,80a8,8,0,0,0,8-8V112a8,8,0,0,0-16,0v32A8,8,0,0,0,40,152Zm32,56H48V184a8,8,0,0,0-16,0v24a16,16,0,0,0,16,16H72a8,8,0,0,0,0-16ZM112,48h32a8,8,0,0,0,0-16H112a8,8,0,0,0,0,16Zm96-16H184a8,8,0,0,0,0,16h24V72a8,8,0,0,0,16,0V48A16,16,0,0,0,208,32Zm8,144a8,8,0,0,0-8,8v24H184a8,8,0,0,0,0,16h24a16,16,0,0,0,16-16V184A8,8,0,0,0,216,176Zm0-72a8,8,0,0,0-8,8v32a8,8,0,0,0,16,0V112A8,8,0,0,0,216,104Zm-32,72V80a8,8,0,0,0-8-8H80a8,8,0,0,0-8,8v96a8,8,0,0,0,8,8h96A8,8,0,0,0,184,176Zm-40,32H112a8,8,0,0,0,0,16h32a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M106,40a6,6,0,0,1,6-6h32a6,6,0,0,1,0,12H112A6,6,0,0,1,106,40Zm38,170H112a6,6,0,0,0,0,12h32a6,6,0,0,0,0-12ZM208,34H184a6,6,0,0,0,0,12h24a2,2,0,0,1,2,2V72a6,6,0,0,0,12,0V48A14,14,0,0,0,208,34Zm8,72a6,6,0,0,0-6,6v32a6,6,0,0,0,12,0V112A6,6,0,0,0,216,106Zm0,72a6,6,0,0,0-6,6v24a2,2,0,0,1-2,2H184a6,6,0,0,0,0,12h24a14,14,0,0,0,14-14V184A6,6,0,0,0,216,178ZM40,150a6,6,0,0,0,6-6V112a6,6,0,0,0-12,0v32A6,6,0,0,0,40,150Zm32,60H48a2,2,0,0,1-2-2V184a6,6,0,0,0-12,0v24a14,14,0,0,0,14,14H72a6,6,0,0,0,0-12ZM40,78a6,6,0,0,0,6-6V48a2,2,0,0,1,2-2H72a6,6,0,0,0,0-12H48A14,14,0,0,0,34,48V72A6,6,0,0,0,40,78ZM176,182H80a6,6,0,0,1-6-6V80a6,6,0,0,1,6-6h96a6,6,0,0,1,6,6v96A6,6,0,0,1,176,182Zm-6-96H86v84h84Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M108,40a4,4,0,0,1,4-4h32a4,4,0,0,1,0,8H112A4,4,0,0,1,108,40Zm36,172H112a4,4,0,0,0,0,8h32a4,4,0,0,0,0-8ZM208,36H184a4,4,0,0,0,0,8h24a4,4,0,0,1,4,4V72a4,4,0,0,0,8,0V48A12,12,0,0,0,208,36Zm8,72a4,4,0,0,0-4,4v32a4,4,0,0,0,8,0V112A4,4,0,0,0,216,108Zm0,72a4,4,0,0,0-4,4v24a4,4,0,0,1-4,4H184a4,4,0,0,0,0,8h24a12,12,0,0,0,12-12V184A4,4,0,0,0,216,180ZM40,148a4,4,0,0,0,4-4V112a4,4,0,0,0-8,0v32A4,4,0,0,0,40,148Zm32,64H48a4,4,0,0,1-4-4V184a4,4,0,0,0-8,0v24a12,12,0,0,0,12,12H72a4,4,0,0,0,0-8ZM40,76a4,4,0,0,0,4-4V48a4,4,0,0,1,4-4H72a4,4,0,0,0,0-8H48A12,12,0,0,0,36,48V72A4,4,0,0,0,40,76ZM180,176a4,4,0,0,1-4,4H80a4,4,0,0,1-4-4V80a4,4,0,0,1,4-4h96a4,4,0,0,1,4,4Zm-8-92H84v88h88Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M104,40a8,8,0,0,1,8-8h32a8,8,0,0,1,0,16H112A8,8,0,0,1,104,40Zm40,168H112a8,8,0,0,0,0,16h32a8,8,0,0,0,0-16ZM208,32H184a8,8,0,0,0,0,16h24V72a8,8,0,0,0,16,0V48A16,16,0,0,0,208,32Zm8,72a8,8,0,0,0-8,8v32a8,8,0,0,0,16,0V112A8,8,0,0,0,216,104Zm0,72a8,8,0,0,0-8,8v24H184a8,8,0,0,0,0,16h24a16,16,0,0,0,16-16V184A8,8,0,0,0,216,176ZM40,152a8,8,0,0,0,8-8V112a8,8,0,0,0-16,0v32A8,8,0,0,0,40,152Zm32,56H48V184a8,8,0,0,0-16,0v24a16,16,0,0,0,16,16H72a8,8,0,0,0,0-16ZM40,80a8,8,0,0,0,8-8V48H72a8,8,0,0,0,0-16H48A16,16,0,0,0,32,48V72A8,8,0,0,0,40,80ZM176,184H80a8,8,0,0,1-8-8V80a8,8,0,0,1,8-8h96a8,8,0,0,1,8,8v96A8,8,0,0,1,176,184Zm-8-96H88v80h80Z" />
        </>
      )}
    </svg>
  );
}

export function PhSelectionBackground(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M156.2,80H47.7A19.8,19.8,0,0,0,28,99.7V208.2A19.8,19.8,0,0,0,47.7,228H156.2A19.8,19.8,0,0,0,176,208.2V99.7A19.8,19.8,0,0,0,156.2,80ZM152,204H52V104H152ZM132,40a12,12,0,0,1,12-12h16a12,12,0,0,1,0,24H144A12,12,0,0,1,132,40Zm96,8v8a12,12,0,0,1-24,0V52h-4a12,12,0,0,1,0-24h8A20.1,20.1,0,0,1,228,48Zm0,48v16a12,12,0,0,1-24,0V96a12,12,0,0,1,24,0Zm0,56v8a20.1,20.1,0,0,1-20,20h-8a12,12,0,0,1,0-24h4v-4a12,12,0,0,1,24,0ZM76,56V48A20.1,20.1,0,0,1,96,28h8a12,12,0,0,1,0,24h-4v4a12,12,0,0,1-24,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M216,48V160a8,8,0,0,1-8,8H168V96a8,8,0,0,0-8-8H88V48a8,8,0,0,1,8-8H208A8,8,0,0,1,216,48Z"
            opacity="0.2"
          />
          <path d="M160,80H48A16,16,0,0,0,32,96V208a16,16,0,0,0,16,16H160a16,16,0,0,0,16-16V96A16,16,0,0,0,160,80Zm0,128H48V96H160ZM136,40a8,8,0,0,1,8-8h16a8,8,0,0,1,0,16H144A8,8,0,0,1,136,40Zm88,8v8a8,8,0,0,1-16,0V48h-8a8,8,0,0,1,0-16h8A16,16,0,0,1,224,48Zm0,48v16a8,8,0,0,1-16,0V96a8,8,0,0,1,16,0Zm0,56v8a16,16,0,0,1-16,16h-8a8,8,0,0,1,0-16h8v-8a8,8,0,0,1,16,0ZM80,56V48A16,16,0,0,1,96,32h8a8,8,0,0,1,0,16H96v8a8,8,0,0,1-16,0Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M80,56V48A16,16,0,0,1,96,32h8a8,8,0,0,1,0,16H96v8a8,8,0,0,1-16,0Zm64-8h16a8,8,0,0,0,0-16H144a8,8,0,0,0,0,16Zm72,40a8,8,0,0,0-8,8v16a8,8,0,0,0,16,0V96A8,8,0,0,0,216,88Zm-8-56h-8a8,8,0,0,0,0,16h8v8a8,8,0,0,0,16,0V48A16,16,0,0,0,208,32ZM176,96V208a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V96A16,16,0,0,1,48,80H160A16,16,0,0,1,176,96Zm-16,0H48V208H160Zm56,48a8,8,0,0,0-8,8v8h-8a8,8,0,0,0,0,16h8a16,16,0,0,0,16-16v-8A8,8,0,0,0,216,144Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M160,82H48A14,14,0,0,0,34,96V208a14,14,0,0,0,14,14H160a14,14,0,0,0,14-14V96A14,14,0,0,0,160,82Zm2,126a2,2,0,0,1-2,2H48a2,2,0,0,1-2-2V96a2,2,0,0,1,2-2H160a2,2,0,0,1,2,2ZM138,40a6,6,0,0,1,6-6h16a6,6,0,0,1,0,12H144A6,6,0,0,1,138,40Zm84,8v8a6,6,0,0,1-12,0V48a2,2,0,0,0-2-2h-8a6,6,0,0,1,0-12h8A14,14,0,0,1,222,48Zm0,48v16a6,6,0,0,1-12,0V96a6,6,0,0,1,12,0Zm0,56v8a14,14,0,0,1-14,14h-8a6,6,0,0,1,0-12h8a2,2,0,0,0,2-2v-8a6,6,0,0,1,12,0ZM82,56V48A14,14,0,0,1,96,34h8a6,6,0,0,1,0,12H96a2,2,0,0,0-2,2v8a6,6,0,0,1-12,0Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M160,84H48A12,12,0,0,0,36,96V208a12,12,0,0,0,12,12H160a12,12,0,0,0,12-12V96A12,12,0,0,0,160,84Zm4,124a4,4,0,0,1-4,4H48a4,4,0,0,1-4-4V96a4,4,0,0,1,4-4H160a4,4,0,0,1,4,4ZM140,40a4,4,0,0,1,4-4h16a4,4,0,0,1,0,8H144A4,4,0,0,1,140,40Zm80,8v8a4,4,0,0,1-8,0V48a4,4,0,0,0-4-4h-8a4,4,0,0,1,0-8h8A12,12,0,0,1,220,48Zm0,48v16a4,4,0,0,1-8,0V96a4,4,0,0,1,8,0Zm0,56v8a12,12,0,0,1-12,12h-8a4,4,0,0,1,0-8h8a4,4,0,0,0,4-4v-8a4,4,0,0,1,8,0ZM84,56V48A12,12,0,0,1,96,36h8a4,4,0,0,1,0,8H96a4,4,0,0,0-4,4v8a4,4,0,0,1-8,0Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M160,80H48A16,16,0,0,0,32,96V208a16,16,0,0,0,16,16H160a16,16,0,0,0,16-16V96A16,16,0,0,0,160,80Zm0,128H48V96H160ZM136,40a8,8,0,0,1,8-8h16a8,8,0,0,1,0,16H144A8,8,0,0,1,136,40Zm88,8v8a8,8,0,0,1-16,0V48h-8a8,8,0,0,1,0-16h8A16,16,0,0,1,224,48Zm0,48v16a8,8,0,0,1-16,0V96a8,8,0,0,1,16,0Zm0,56v8a16,16,0,0,1-16,16h-8a8,8,0,0,1,0-16h8v-8a8,8,0,0,1,16,0ZM80,56V48A16,16,0,0,1,96,32h8a8,8,0,0,1,0,16H96v8a8,8,0,0,1-16,0Z" />
        </>
      )}
    </svg>
  );
}

export function PhSelectionForeground(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M68,216a12,12,0,0,1-12,12H48a20.1,20.1,0,0,1-20-20v-8a12,12,0,0,1,24,0v4h4A12,12,0,0,1,68,216Zm44-12H96a12,12,0,0,0,0,24h16a12,12,0,0,0,0-24ZM40,172a12,12,0,0,0,12-12V144a12,12,0,0,0-24,0v16A12,12,0,0,0,40,172Zm128,16a12,12,0,0,0-12,12v4h-4a12,12,0,0,0,0,24h8a20.1,20.1,0,0,0,20-20v-8A12,12,0,0,0,168,188Zm0-72a12,12,0,0,0,12-12V96a20.1,20.1,0,0,0-20-20h-8a12,12,0,0,0,0,24h4v4A12,12,0,0,0,168,116ZM56,76H48A20.1,20.1,0,0,0,28,96v8a12,12,0,0,0,24,0v-4h4a12,12,0,0,0,0-24ZM208.2,28H99.8A19.8,19.8,0,0,0,80,47.7V88a12,12,0,0,0,12,12h20a12,12,0,0,0,0-24h-8V52H204V152H180v-8a12,12,0,0,0-24,0v20a12,12,0,0,0,12,12h40.2A19.8,19.8,0,0,0,228,156.2V47.7A19.8,19.8,0,0,0,208.2,28Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M168,96V208a8,8,0,0,1-8,8H48a8,8,0,0,1-8-8V96a8,8,0,0,1,8-8H160A8,8,0,0,1,168,96Z"
            opacity="0.2"
          />
          <path d="M64,216a8,8,0,0,1-8,8H48a16,16,0,0,1-16-16v-8a8,8,0,0,1,16,0v8h8A8,8,0,0,1,64,216Zm48-8H96a8,8,0,0,0,0,16h16a8,8,0,0,0,0-16ZM40,168a8,8,0,0,0,8-8V144a8,8,0,0,0-16,0v16A8,8,0,0,0,40,168Zm128,24a8,8,0,0,0-8,8v8h-8a8,8,0,0,0,0,16h8a16,16,0,0,0,16-16v-8A8,8,0,0,0,168,192Zm0-80a8,8,0,0,0,8-8V96a16,16,0,0,0-16-16h-8a8,8,0,0,0,0,16h8v8A8,8,0,0,0,168,112ZM56,80H48A16,16,0,0,0,32,96v8a8,8,0,0,0,16,0V96h8a8,8,0,0,0,0-16ZM208,32H96A16,16,0,0,0,80,48V88a8,8,0,0,0,8,8h24a8,8,0,0,0,0-16H96V48H208V160H176V144a8,8,0,0,0-16,0v24a8,8,0,0,0,8,8h40a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M64,88a8,8,0,0,1-8,8H48v8a8,8,0,0,1-16,0V96A16,16,0,0,1,48,80h8A8,8,0,0,1,64,88Zm48,120H96a8,8,0,0,0,0,16h16a8,8,0,0,0,0-16ZM40,168a8,8,0,0,0,8-8V144a8,8,0,0,0-16,0v16A8,8,0,0,0,40,168Zm16,40H48v-8a8,8,0,0,0-16,0v8a16,16,0,0,0,16,16h8a8,8,0,0,0,0-16ZM208,32H96A16,16,0,0,0,80,48V88a8,8,0,0,0,8,8h24a8,8,0,0,0,0-16H96V48H208V160H176V144a8,8,0,0,0-16,0v24a8,8,0,0,0,8,8h40a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM168,192a8,8,0,0,0-8,8v8h-8a8,8,0,0,0,0,16h8a16,16,0,0,0,16-16v-8A8,8,0,0,0,168,192Zm0-80a8,8,0,0,0,8-8V96a16,16,0,0,0-16-16h-8a8,8,0,0,0,0,16h8v8A8,8,0,0,0,168,112Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M62,216a6,6,0,0,1-6,6H48a14,14,0,0,1-14-14v-8a6,6,0,0,1,12,0v8a2,2,0,0,0,2,2h8A6,6,0,0,1,62,216Zm50-6H96a6,6,0,0,0,0,12h16a6,6,0,0,0,0-12ZM40,166a6,6,0,0,0,6-6V144a6,6,0,0,0-12,0v16A6,6,0,0,0,40,166Zm128,28a6,6,0,0,0-6,6v8a2,2,0,0,1-2,2h-8a6,6,0,0,0,0,12h8a14,14,0,0,0,14-14v-8A6,6,0,0,0,168,194Zm0-84a6,6,0,0,0,6-6V96a14,14,0,0,0-14-14h-8a6,6,0,0,0,0,12h8a2,2,0,0,1,2,2v8A6,6,0,0,0,168,110ZM56,82H48A14,14,0,0,0,34,96v8a6,6,0,0,0,12,0V96a2,2,0,0,1,2-2h8a6,6,0,0,0,0-12ZM208,34H96A14,14,0,0,0,82,48V88a6,6,0,0,0,6,6h24a6,6,0,0,0,0-12H94V48a2,2,0,0,1,2-2H208a2,2,0,0,1,2,2V160a2,2,0,0,1-2,2H174V144a6,6,0,0,0-12,0v24a6,6,0,0,0,6,6h40a14,14,0,0,0,14-14V48A14,14,0,0,0,208,34Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M60,216a4,4,0,0,1-4,4H48a12,12,0,0,1-12-12v-8a4,4,0,0,1,8,0v8a4,4,0,0,0,4,4h8A4,4,0,0,1,60,216Zm52-4H96a4,4,0,0,0,0,8h16a4,4,0,0,0,0-8ZM40,164a4,4,0,0,0,4-4V144a4,4,0,0,0-8,0v16A4,4,0,0,0,40,164Zm128,32a4,4,0,0,0-4,4v8a4,4,0,0,1-4,4h-8a4,4,0,0,0,0,8h8a12,12,0,0,0,12-12v-8A4,4,0,0,0,168,196Zm0-88a4,4,0,0,0,4-4V96a12,12,0,0,0-12-12h-8a4,4,0,0,0,0,8h8a4,4,0,0,1,4,4v8A4,4,0,0,0,168,108ZM56,84H48A12,12,0,0,0,36,96v8a4,4,0,0,0,8,0V96a4,4,0,0,1,4-4h8a4,4,0,0,0,0-8ZM208,36H96A12,12,0,0,0,84,48V88a4,4,0,0,0,4,4h24a4,4,0,0,0,0-8H92V48a4,4,0,0,1,4-4H208a4,4,0,0,1,4,4V160a4,4,0,0,1-4,4H172V144a4,4,0,0,0-8,0v24a4,4,0,0,0,4,4h40a12,12,0,0,0,12-12V48A12,12,0,0,0,208,36Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M64,216a8,8,0,0,1-8,8H48a16,16,0,0,1-16-16v-8a8,8,0,0,1,16,0v8h8A8,8,0,0,1,64,216Zm48-8H96a8,8,0,0,0,0,16h16a8,8,0,0,0,0-16ZM40,168a8,8,0,0,0,8-8V144a8,8,0,0,0-16,0v16A8,8,0,0,0,40,168Zm128,24a8,8,0,0,0-8,8v8h-8a8,8,0,0,0,0,16h8a16,16,0,0,0,16-16v-8A8,8,0,0,0,168,192Zm0-80a8,8,0,0,0,8-8V96a16,16,0,0,0-16-16h-8a8,8,0,0,0,0,16h8v8A8,8,0,0,0,168,112ZM56,80H48A16,16,0,0,0,32,96v8a8,8,0,0,0,16,0V96h8a8,8,0,0,0,0-16ZM208,32H96A16,16,0,0,0,80,48V88a8,8,0,0,0,8,8h24a8,8,0,0,0,0-16H96V48H208V160H176V144a8,8,0,0,0-16,0v24a8,8,0,0,0,8,8h40a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32Z" />
        </>
      )}
    </svg>
  );
}

export function PhSelectionInverse(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M156,216a12,12,0,0,1-12,12H112a12,12,0,0,1,0-24h32A12,12,0,0,1,156,216ZM40,156a12,12,0,0,0,12-12V112a12,12,0,0,0-24,0v32A12,12,0,0,0,40,156Zm32,48H52V184a12,12,0,0,0-24,0v24a20.1,20.1,0,0,0,20,20H72a12,12,0,0,0,0-24Zm156,4a19.8,19.8,0,0,1-5,13.2l-.9.9-.9.9a19.8,19.8,0,0,1-13.2,5H184a12,12,0,0,1,0-24h3L52,69v3a12,12,0,0,1-24,0V48a19.8,19.8,0,0,1,5-13.2l.9-.9.9-.9A19.8,19.8,0,0,1,48,28H208a20.1,20.1,0,0,1,20,20ZM204,52H69L204,187Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M216,48V208a8.4,8.4,0,0,1-2.3,5.7h0L42.3,42.3h0A8.4,8.4,0,0,1,48,40H208A8,8,0,0,1,216,48Z"
            opacity="0.2"
          />
          <path d="M152,216a8,8,0,0,1-8,8H112a8,8,0,0,1,0-16h32A8,8,0,0,1,152,216ZM40,152a8,8,0,0,0,8-8V112a8,8,0,0,0-16,0v32A8,8,0,0,0,40,152Zm32,56H48V184a8,8,0,0,0-16,0v24a16,16,0,0,0,16,16H72a8,8,0,0,0,0-16Zm152,0a16.4,16.4,0,0,1-4.1,10.7l-.6.6-.6.6A16.4,16.4,0,0,1,208,224H184a8,8,0,0,1,0-16h12.7L48,59.3V72a8,8,0,0,1-16,0V48a16.4,16.4,0,0,1,4.1-10.7l.6-.6.6-.6A16.4,16.4,0,0,1,48,32H208a16,16,0,0,1,16,16ZM208,48H59.3L208,196.7Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M80,216a8,8,0,0,1-8,8H48a16,16,0,0,1-16-16V184a8,8,0,0,1,16,0v24H72A8,8,0,0,1,80,216ZM40,152a8,8,0,0,0,8-8V112a8,8,0,0,0-16,0v32A8,8,0,0,0,40,152ZM208,32H48a16.4,16.4,0,0,0-10.7,4.1l-.6.6-.6.6A16.4,16.4,0,0,0,32,48V72a8,8,0,0,0,16,0V59.3L196.7,208H184a8,8,0,0,0,0,16h24a16.4,16.4,0,0,0,10.7-4.1l.6-.6.6-.6A16.4,16.4,0,0,0,224,208V48A16,16,0,0,0,208,32ZM144,208H112a8,8,0,0,0,0,16h32a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M150,216a6,6,0,0,1-6,6H112a6,6,0,0,1,0-12h32A6,6,0,0,1,150,216ZM40,150a6,6,0,0,0,6-6V112a6,6,0,0,0-12,0v32A6,6,0,0,0,40,150Zm32,60H48a2,2,0,0,1-2-2V184a6,6,0,0,0-12,0v24a14,14,0,0,0,14,14H72a6,6,0,0,0,0-12Zm150-2a13.8,13.8,0,0,1-4,9.8h-.2a13.8,13.8,0,0,1-9.8,4H184a6,6,0,0,1,0-12h17.5L46,54.5V72a6,6,0,0,1-12,0V48a13.8,13.8,0,0,1,4-9.8.1.1,0,0,0,.1-.1.1.1,0,0,0,.1-.1A13.8,13.8,0,0,1,48,34H208a14,14,0,0,1,14,14ZM208,46H54.5L210,201.5V48A2,2,0,0,0,208,46Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M148,216a4,4,0,0,1-4,4H112a4,4,0,0,1,0-8h32A4,4,0,0,1,148,216ZM40,148a4,4,0,0,0,4-4V112a4,4,0,0,0-8,0v32A4,4,0,0,0,40,148Zm32,64H48a4,4,0,0,1-4-4V184a4,4,0,0,0-8,0v24a12,12,0,0,0,12,12H72a4,4,0,0,0,0-8Zm148-4a12,12,0,0,1-3.4,8.4h-.2A12,12,0,0,1,208,220H184a4,4,0,0,1,0-8h22.3L44,49.7V72a4,4,0,0,1-8,0V48a12,12,0,0,1,3.4-8.4.1.1,0,0,0,.1-.1.1.1,0,0,0,.1-.1A12,12,0,0,1,48,36H208a12,12,0,0,1,12,12ZM208,44H49.7L212,206.3V48A4,4,0,0,0,208,44Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M152,216a8,8,0,0,1-8,8H112a8,8,0,0,1,0-16h32A8,8,0,0,1,152,216ZM40,152a8,8,0,0,0,8-8V112a8,8,0,0,0-16,0v32A8,8,0,0,0,40,152Zm32,56H48V184a8,8,0,0,0-16,0v24a16,16,0,0,0,16,16H72a8,8,0,0,0,0-16Zm152,0a16.4,16.4,0,0,1-4.1,10.7l-.6.6-.6.6A16.4,16.4,0,0,1,208,224H184a8,8,0,0,1,0-16h12.7L48,59.3V72a8,8,0,0,1-16,0V48a16.4,16.4,0,0,1,4.1-10.7l.6-.6.6-.6A16.4,16.4,0,0,1,48,32H208a16,16,0,0,1,16,16ZM208,48H59.3L208,196.7Z" />
        </>
      )}
    </svg>
  );
}

export function PhSelectionPlus(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M152,48a12,12,0,0,1-12,12H116a12,12,0,0,1,0-24h24A12,12,0,0,1,152,48ZM140,196H116a12,12,0,0,0,0,24h24a12,12,0,0,0,0-24ZM180,60h16V76a12,12,0,0,0,24,0V56a20.1,20.1,0,0,0-20-20H180a12,12,0,0,0,0,24Zm28,44a12,12,0,0,0-12,12v24a12,12,0,0,0,24,0V116A12,12,0,0,0,208,104ZM48,152a12,12,0,0,0,12-12V116a12,12,0,0,0-24,0v24A12,12,0,0,0,48,152Zm28,44H60V180a12,12,0,0,0-24,0v20a20.1,20.1,0,0,0,20,20H76a12,12,0,0,0,0-24ZM76,36H56A20.1,20.1,0,0,0,36,56V76a12,12,0,0,0,24,0V60H76a12,12,0,0,0,0-24ZM236,196H220V180a12,12,0,0,0-24,0v16H180a12,12,0,0,0,0,24h16v16a12,12,0,0,0,24,0V220h16a12,12,0,0,0,0-24Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M148,48a8,8,0,0,1-8,8H116a8,8,0,0,1,0-16h24A8,8,0,0,1,148,48Zm-8,152H116a8,8,0,0,0,0,16h24a8,8,0,0,0,0-16ZM180,56h20V76a8,8,0,0,0,16,0V56a16,16,0,0,0-16-16H180a8,8,0,0,0,0,16Zm28,52a8,8,0,0,0-8,8v24a8,8,0,0,0,16,0V116A8,8,0,0,0,208,108ZM48,148a8,8,0,0,0,8-8V116a8,8,0,0,0-16,0v24A8,8,0,0,0,48,148Zm28,52H56V180a8,8,0,0,0-16,0v20a16,16,0,0,0,16,16H76a8,8,0,0,0,0-16ZM76,40H56A16,16,0,0,0,40,56V76a8,8,0,0,0,16,0V56H76a8,8,0,0,0,0-16ZM236,200H216V180a8,8,0,0,0-16,0v20H180a8,8,0,0,0,0,16h20v20a8,8,0,0,0,16,0V216h20a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M148,48a8,8,0,0,1-8,8H116a8,8,0,0,1,0-16h24A8,8,0,0,1,148,48Zm-8,152H116a8,8,0,0,0,0,16h24a8,8,0,0,0,0-16ZM180,56h20V76a8,8,0,0,0,16,0V56a16,16,0,0,0-16-16H180a8,8,0,0,0,0,16Zm28,52a8,8,0,0,0-8,8v24a8,8,0,0,0,16,0V116A8,8,0,0,0,208,108ZM48,148a8,8,0,0,0,8-8V116a8,8,0,0,0-16,0v24A8,8,0,0,0,48,148Zm28,52H56V180a8,8,0,0,0-16,0v20a16,16,0,0,0,16,16H76a8,8,0,0,0,0-16ZM76,40H56A16,16,0,0,0,40,56V76a8,8,0,0,0,16,0V56H76a8,8,0,0,0,0-16ZM236,200H216V180a8,8,0,0,0-16,0v20H180a8,8,0,0,0,0,16h20v20a8,8,0,0,0,16,0V216h20a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M146,48a6,6,0,0,1-6,6H116a6,6,0,0,1,0-12h24A6,6,0,0,1,146,48Zm-6,154H116a6,6,0,0,0,0,12h24a6,6,0,0,0,0-12ZM180,54h20a2,2,0,0,1,2,2V76a6,6,0,0,0,12,0V56a14,14,0,0,0-14-14H180a6,6,0,0,0,0,12Zm28,56a6,6,0,0,0-6,6v24a6,6,0,0,0,12,0V116A6,6,0,0,0,208,110ZM48,146a6,6,0,0,0,6-6V116a6,6,0,0,0-12,0v24A6,6,0,0,0,48,146Zm28,56H56a2,2,0,0,1-2-2V180a6,6,0,0,0-12,0v20a14,14,0,0,0,14,14H76a6,6,0,0,0,0-12ZM76,42H56A14,14,0,0,0,42,56V76a6,6,0,0,0,12,0V56a2,2,0,0,1,2-2H76a6,6,0,0,0,0-12ZM236,202H214V180a6,6,0,0,0-12,0v22H180a6,6,0,0,0,0,12h22v22a6,6,0,0,0,12,0V214h22a6,6,0,0,0,0-12Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M144,48a4,4,0,0,1-4,4H116a4,4,0,0,1,0-8h24A4,4,0,0,1,144,48Zm-4,156H116a4,4,0,0,0,0,8h24a4,4,0,0,0,0-8ZM180,52h20a4,4,0,0,1,4,4V76a4,4,0,0,0,8,0V56a12,12,0,0,0-12-12H180a4,4,0,0,0,0,8Zm28,60a4,4,0,0,0-4,4v24a4,4,0,0,0,8,0V116A4,4,0,0,0,208,112ZM48,144a4,4,0,0,0,4-4V116a4,4,0,0,0-8,0v24A4,4,0,0,0,48,144Zm28,60H56a4,4,0,0,1-4-4V180a4,4,0,0,0-8,0v20a12,12,0,0,0,12,12H76a4,4,0,0,0,0-8ZM76,44H56A12,12,0,0,0,44,56V76a4,4,0,0,0,8,0V56a4,4,0,0,1,4-4H76a4,4,0,0,0,0-8ZM236,204H212V180a4,4,0,0,0-8,0v24H180a4,4,0,0,0,0,8h24v24a4,4,0,0,0,8,0V212h24a4,4,0,0,0,0-8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M148,48a8,8,0,0,1-8,8H116a8,8,0,0,1,0-16h24A8,8,0,0,1,148,48Zm-8,152H116a8,8,0,0,0,0,16h24a8,8,0,0,0,0-16ZM180,56h20V76a8,8,0,0,0,16,0V56a16,16,0,0,0-16-16H180a8,8,0,0,0,0,16Zm28,52a8,8,0,0,0-8,8v24a8,8,0,0,0,16,0V116A8,8,0,0,0,208,108ZM48,148a8,8,0,0,0,8-8V116a8,8,0,0,0-16,0v24A8,8,0,0,0,48,148Zm28,52H56V180a8,8,0,0,0-16,0v20a16,16,0,0,0,16,16H76a8,8,0,0,0,0-16ZM76,40H56A16,16,0,0,0,40,56V76a8,8,0,0,0,16,0V56H76a8,8,0,0,0,0-16ZM236,200H216V180a8,8,0,0,0-16,0v20H180a8,8,0,0,0,0,16h20v20a8,8,0,0,0,16,0V216h20a8,8,0,0,0,0-16Z" />
        </>
      )}
    </svg>
  );
}

export function PhSelectionSlash(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M100,40a12,12,0,0,1,12-12h32a12,12,0,0,1,0,24H112A12,12,0,0,1,100,40Zm44,164H112a12,12,0,0,0,0,24h32a12,12,0,0,0,0-24ZM208,28H184a12,12,0,0,0,0,24h20V72a12,12,0,0,0,24,0V48A20.1,20.1,0,0,0,208,28Zm8,72a12,12,0,0,0-12,12v32a12,12,0,0,0,24,0V112A12,12,0,0,0,216,100ZM40,156a12,12,0,0,0,12-12V112a12,12,0,0,0-24,0v32A12,12,0,0,0,40,156Zm32,48H52V184a12,12,0,0,0-24,0v24a20.1,20.1,0,0,0,20,20H72a12,12,0,0,0,0-24ZM56.9,31.9A12,12,0,0,0,39.1,48.1l160,176a12.1,12.1,0,0,0,17,.8,12.1,12.1,0,0,0,.8-17Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M104,40a8,8,0,0,1,8-8h32a8,8,0,0,1,0,16H112A8,8,0,0,1,104,40Zm40,168H112a8,8,0,0,0,0,16h32a8,8,0,0,0,0-16ZM208,32H184a8,8,0,0,0,0,16h24V72a8,8,0,0,0,16,0V48A16,16,0,0,0,208,32Zm8,72a8,8,0,0,0-8,8v32a8,8,0,0,0,16,0V112A8,8,0,0,0,216,104ZM40,152a8,8,0,0,0,8-8V112a8,8,0,0,0-16,0v32A8,8,0,0,0,40,152Zm32,56H48V184a8,8,0,0,0-16,0v24a16,16,0,0,0,16,16H72a8,8,0,0,0,0-16ZM53.9,34.6A8,8,0,0,0,42.1,45.4l160,176A8,8,0,0,0,208,224a8.2,8.2,0,0,0,5.4-2.1,7.9,7.9,0,0,0,.5-11.3Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M32,144V112a8,8,0,0,1,16,0v32a8,8,0,0,1-16,0Zm80-96h32a8,8,0,0,0,0-16H112a8,8,0,0,0,0,16ZM72,208H48V184a8,8,0,0,0-16,0v24a16,16,0,0,0,16,16H72a8,8,0,0,0,0-16ZM216,104a8,8,0,0,0-8,8v32a8,8,0,0,0,16,0V112A8,8,0,0,0,216,104Zm-8-72H184a8,8,0,0,0,0,16h24V72a8,8,0,0,0,16,0V48A16,16,0,0,0,208,32ZM53.9,34.6A8,8,0,0,0,42.1,45.4l160,176A8,8,0,0,0,208,224a8.2,8.2,0,0,0,5.4-2.1,7.9,7.9,0,0,0,.5-11.3ZM144,208H112a8,8,0,0,0,0,16h32a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M106,40a6,6,0,0,1,6-6h32a6,6,0,0,1,0,12H112A6,6,0,0,1,106,40Zm38,170H112a6,6,0,0,0,0,12h32a6,6,0,0,0,0-12ZM208,34H184a6,6,0,0,0,0,12h24a2,2,0,0,1,2,2V72a6,6,0,0,0,12,0V48A14,14,0,0,0,208,34Zm8,72a6,6,0,0,0-6,6v32a6,6,0,0,0,12,0V112A6,6,0,0,0,216,106ZM40,150a6,6,0,0,0,6-6V112a6,6,0,0,0-12,0v32A6,6,0,0,0,40,150Zm32,60H48a2,2,0,0,1-2-2V184a6,6,0,0,0-12,0v24a14,14,0,0,0,14,14H72a6,6,0,0,0,0-12ZM52.4,36a5.9,5.9,0,0,0-8.8,8l160,176a5.9,5.9,0,0,0,4.4,2,5.7,5.7,0,0,0,4-1.6,5.8,5.8,0,0,0,.4-8.4Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M108,40a4,4,0,0,1,4-4h32a4,4,0,0,1,0,8H112A4,4,0,0,1,108,40Zm36,172H112a4,4,0,0,0,0,8h32a4,4,0,0,0,0-8ZM208,36H184a4,4,0,0,0,0,8h24a4,4,0,0,1,4,4V72a4,4,0,0,0,8,0V48A12,12,0,0,0,208,36Zm8,72a4,4,0,0,0-4,4v32a4,4,0,0,0,8,0V112A4,4,0,0,0,216,108ZM40,148a4,4,0,0,0,4-4V112a4,4,0,0,0-8,0v32A4,4,0,0,0,40,148Zm32,64H48a4,4,0,0,1-4-4V184a4,4,0,0,0-8,0v24a12,12,0,0,0,12,12H72a4,4,0,0,0,0-8ZM51,37.3a4,4,0,1,0-6,5.4l160,176a4.1,4.1,0,0,0,5.7.3,4.2,4.2,0,0,0,.3-5.7Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M104,40a8,8,0,0,1,8-8h32a8,8,0,0,1,0,16H112A8,8,0,0,1,104,40Zm40,168H112a8,8,0,0,0,0,16h32a8,8,0,0,0,0-16ZM208,32H184a8,8,0,0,0,0,16h24V72a8,8,0,0,0,16,0V48A16,16,0,0,0,208,32Zm8,72a8,8,0,0,0-8,8v32a8,8,0,0,0,16,0V112A8,8,0,0,0,216,104ZM40,152a8,8,0,0,0,8-8V112a8,8,0,0,0-16,0v32A8,8,0,0,0,40,152Zm32,56H48V184a8,8,0,0,0-16,0v24a16,16,0,0,0,16,16H72a8,8,0,0,0,0-16ZM53.9,34.6A8,8,0,0,0,42.1,45.4l160,176A8,8,0,0,0,208,224a8.2,8.2,0,0,0,5.4-2.1,7.9,7.9,0,0,0,.5-11.3Z" />
        </>
      )}
    </svg>
  );
}

export function PhSelection(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M156,40a12,12,0,0,1-12,12H112a12,12,0,0,1,0-24h32A12,12,0,0,1,156,40ZM144,204H112a12,12,0,0,0,0,24h32a12,12,0,0,0,0-24ZM208,28H184a12,12,0,0,0,0,24h20V72a12,12,0,0,0,24,0V48A20.1,20.1,0,0,0,208,28Zm8,72a12,12,0,0,0-12,12v32a12,12,0,0,0,24,0V112A12,12,0,0,0,216,100Zm0,72a12,12,0,0,0-12,12v20H184a12,12,0,0,0,0,24h24a20.1,20.1,0,0,0,20-20V184A12,12,0,0,0,216,172ZM40,156a12,12,0,0,0,12-12V112a12,12,0,0,0-24,0v32A12,12,0,0,0,40,156Zm32,48H52V184a12,12,0,0,0-24,0v24a20.1,20.1,0,0,0,20,20H72a12,12,0,0,0,0-24ZM72,28H48A20.1,20.1,0,0,0,28,48V72a12,12,0,0,0,24,0V52H72a12,12,0,0,0,0-24Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M152,40a8,8,0,0,1-8,8H112a8,8,0,0,1,0-16h32A8,8,0,0,1,152,40Zm-8,168H112a8,8,0,0,0,0,16h32a8,8,0,0,0,0-16ZM208,32H184a8,8,0,0,0,0,16h24V72a8,8,0,0,0,16,0V48A16,16,0,0,0,208,32Zm8,72a8,8,0,0,0-8,8v32a8,8,0,0,0,16,0V112A8,8,0,0,0,216,104Zm0,72a8,8,0,0,0-8,8v24H184a8,8,0,0,0,0,16h24a16,16,0,0,0,16-16V184A8,8,0,0,0,216,176ZM40,152a8,8,0,0,0,8-8V112a8,8,0,0,0-16,0v32A8,8,0,0,0,40,152Zm32,56H48V184a8,8,0,0,0-16,0v24a16,16,0,0,0,16,16H72a8,8,0,0,0,0-16ZM72,32H48A16,16,0,0,0,32,48V72a8,8,0,0,0,16,0V48H72a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M32,144V112a8,8,0,0,1,16,0v32a8,8,0,0,1-16,0ZM72,32H48A16,16,0,0,0,32,48V72a8,8,0,0,0,16,0V48H72a8,8,0,0,0,0-16Zm0,176H48V184a8,8,0,0,0-16,0v24a16,16,0,0,0,16,16H72a8,8,0,0,0,0-16Zm72,0H112a8,8,0,0,0,0,16h32a8,8,0,0,0,0-16Zm72-104a8,8,0,0,0-8,8v32a8,8,0,0,0,16,0V112A8,8,0,0,0,216,104Zm-8-72H184a8,8,0,0,0,0,16h24V72a8,8,0,0,0,16,0V48A16,16,0,0,0,208,32Zm-64,0H112a8,8,0,0,0,0,16h32a8,8,0,0,0,0-16Zm72,144a8,8,0,0,0-8,8v24H184a8,8,0,0,0,0,16h24a16,16,0,0,0,16-16V184A8,8,0,0,0,216,176Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M150,40a6,6,0,0,1-6,6H112a6,6,0,0,1,0-12h32A6,6,0,0,1,150,40Zm-6,170H112a6,6,0,0,0,0,12h32a6,6,0,0,0,0-12ZM208,34H184a6,6,0,0,0,0,12h24a2,2,0,0,1,2,2V72a6,6,0,0,0,12,0V48A14,14,0,0,0,208,34Zm8,72a6,6,0,0,0-6,6v32a6,6,0,0,0,12,0V112A6,6,0,0,0,216,106Zm0,72a6,6,0,0,0-6,6v24a2,2,0,0,1-2,2H184a6,6,0,0,0,0,12h24a14,14,0,0,0,14-14V184A6,6,0,0,0,216,178ZM40,150a6,6,0,0,0,6-6V112a6,6,0,0,0-12,0v32A6,6,0,0,0,40,150Zm32,60H48a2,2,0,0,1-2-2V184a6,6,0,0,0-12,0v24a14,14,0,0,0,14,14H72a6,6,0,0,0,0-12ZM72,34H48A14,14,0,0,0,34,48V72a6,6,0,0,0,12,0V48a2,2,0,0,1,2-2H72a6,6,0,0,0,0-12Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M148,40a4,4,0,0,1-4,4H112a4,4,0,0,1,0-8h32A4,4,0,0,1,148,40Zm-4,172H112a4,4,0,0,0,0,8h32a4,4,0,0,0,0-8ZM208,36H184a4,4,0,0,0,0,8h24a4,4,0,0,1,4,4V72a4,4,0,0,0,8,0V48A12,12,0,0,0,208,36Zm8,72a4,4,0,0,0-4,4v32a4,4,0,0,0,8,0V112A4,4,0,0,0,216,108Zm0,72a4,4,0,0,0-4,4v24a4,4,0,0,1-4,4H184a4,4,0,0,0,0,8h24a12,12,0,0,0,12-12V184A4,4,0,0,0,216,180ZM40,148a4,4,0,0,0,4-4V112a4,4,0,0,0-8,0v32A4,4,0,0,0,40,148Zm32,64H48a4,4,0,0,1-4-4V184a4,4,0,0,0-8,0v24a12,12,0,0,0,12,12H72a4,4,0,0,0,0-8ZM72,36H48A12,12,0,0,0,36,48V72a4,4,0,0,0,8,0V48a4,4,0,0,1,4-4H72a4,4,0,0,0,0-8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M152,40a8,8,0,0,1-8,8H112a8,8,0,0,1,0-16h32A8,8,0,0,1,152,40Zm-8,168H112a8,8,0,0,0,0,16h32a8,8,0,0,0,0-16ZM208,32H184a8,8,0,0,0,0,16h24V72a8,8,0,0,0,16,0V48A16,16,0,0,0,208,32Zm8,72a8,8,0,0,0-8,8v32a8,8,0,0,0,16,0V112A8,8,0,0,0,216,104Zm0,72a8,8,0,0,0-8,8v24H184a8,8,0,0,0,0,16h24a16,16,0,0,0,16-16V184A8,8,0,0,0,216,176ZM40,152a8,8,0,0,0,8-8V112a8,8,0,0,0-16,0v32A8,8,0,0,0,40,152Zm32,56H48V184a8,8,0,0,0-16,0v24a16,16,0,0,0,16,16H72a8,8,0,0,0,0-16ZM72,32H48A16,16,0,0,0,32,48V72a8,8,0,0,0,16,0V48H72a8,8,0,0,0,0-16Z" />
        </>
      )}
    </svg>
  );
}

export function PhShareNetwork(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M176,156a44.3,44.3,0,0,0-29.1,11l-40.8-26.2a44.1,44.1,0,0,0,0-25.6L146.9,89A44,44,0,1,0,132,56a45.6,45.6,0,0,0,1.9,12.8L93.1,95a44,44,0,1,0,0,66l40.8,26.2A45.6,45.6,0,0,0,132,200a44,44,0,1,0,44-44Zm0-120a20,20,0,1,1-20,20A20.1,20.1,0,0,1,176,36ZM64,148a20,20,0,1,1,20-20A20.1,20.1,0,0,1,64,148Zm112,72a20,20,0,1,1,20-20A20.1,20.1,0,0,1,176,220Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="176" cy="200" r="32" />
          </g>
          <g opacity="0.2">
            <circle cx="176" cy="56" r="32" />
          </g>
          <path d="M176,160a39.7,39.7,0,0,0-28.6,12.1l-46.1-29.6a40.3,40.3,0,0,0,0-29l46.1-29.6A40,40,0,1,0,136,56a41,41,0,0,0,2.7,14.5L92.6,100.1a40,40,0,1,0,0,55.8l46.1,29.6A41,41,0,0,0,136,200a40,40,0,1,0,40-40Zm0-128a24,24,0,1,1-24,24A24.1,24.1,0,0,1,176,32ZM64,152a24,24,0,1,1,24-24A24.1,24.1,0,0,1,64,152Zm112,72a24,24,0,1,1,24-24A24.1,24.1,0,0,1,176,224Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,200a40,40,0,0,1-80,0,41,41,0,0,1,2.7-14.5L92.6,155.9a40,40,0,1,1,0-55.8l46.1-29.6A41,41,0,0,1,136,56a40.1,40.1,0,1,1,11.4,27.9l-46.1,29.6a40.3,40.3,0,0,1,0,29l46.1,29.6A40,40,0,0,1,216,200Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M176,162a37.9,37.9,0,0,0-28.3,12.7L98.8,143.2a37.7,37.7,0,0,0,0-30.4l48.9-31.5A38,38,0,1,0,138,56a36.6,36.6,0,0,0,3.2,15.2L92.3,102.7a38,38,0,1,0,0,50.6l48.9,31.5A36.6,36.6,0,0,0,138,200a38,38,0,1,0,38-38Zm0-132a26,26,0,1,1-26,26A26.1,26.1,0,0,1,176,30ZM64,154a26,26,0,1,1,26-26A26.1,26.1,0,0,1,64,154Zm112,72a26,26,0,1,1,26-26A26.1,26.1,0,0,1,176,226Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M176,164a35.9,35.9,0,0,0-27.9,13.3L96.2,144a35.6,35.6,0,0,0,0-32l51.9-33.3a36.4,36.4,0,1,0-4.3-6.7L91.9,105.3a36,36,0,1,0,0,45.4L143.8,184A36,36,0,1,0,176,164Zm0-136a28,28,0,1,1-28,28A28.1,28.1,0,0,1,176,28ZM64,156a28,28,0,1,1,28-28A28.1,28.1,0,0,1,64,156Zm112,72a28,28,0,1,1,28-28A28.1,28.1,0,0,1,176,228Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M176,160a39.7,39.7,0,0,0-28.6,12.1l-46.1-29.6a40.3,40.3,0,0,0,0-29l46.1-29.6A40,40,0,1,0,136,56a41,41,0,0,0,2.7,14.5L92.6,100.1a40,40,0,1,0,0,55.8l46.1,29.6A41,41,0,0,0,136,200a40,40,0,1,0,40-40Zm0-128a24,24,0,1,1-24,24A24.1,24.1,0,0,1,176,32ZM64,152a24,24,0,1,1,24-24A24.1,24.1,0,0,1,64,152Zm112,72a24,24,0,1,1,24-24A24.1,24.1,0,0,1,176,224Z" />
        </>
      )}
    </svg>
  );
}

export function PhShare(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M204,216a12,12,0,0,1-12,12H40a20.1,20.1,0,0,1-20-20V88a12,12,0,0,1,24,0V204H192A12,12,0,0,1,204,216Zm29.3-104.4.3-.5.4-.4.3-.6.3-.4.3-.6c0-.2.1-.3.2-.5s.1-.4.2-.6.1-.3.2-.5.1-.4.1-.6.1-.4.2-.6a1.3,1.3,0,0,1,.1-.6v-.5a9.6,9.6,0,0,0,0-2.4v-.5a1.3,1.3,0,0,1-.1-.6c-.1-.2-.1-.4-.2-.6s-.1-.4-.1-.6-.1-.4-.2-.5-.1-.4-.2-.6-.2-.3-.2-.5l-.3-.6-.3-.4-.3-.6-.4-.4-.3-.5-.7-.8h-.1l-48-48a12,12,0,0,0-17,17L195,92H168A108,108,0,0,0,63.4,173,12,12,0,0,0,72,187.6a10.3,10.3,0,0,0,3,.4,11.9,11.9,0,0,0,11.6-9A84,84,0,0,1,168,116h27l-27.5,27.5a12,12,0,0,0,0,17,12.1,12.1,0,0,0,17,0l48-48h.1Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M200,216a8,8,0,0,1-8,8H40a16,16,0,0,1-16-16V88a8,8,0,0,1,16,0V208H192A8,8,0,0,1,200,216Zm30.2-106.9a.8.8,0,0,0,.2-.4l.2-.3.3-.3.2-.3c0-.2.1-.3.1-.4l.2-.3c0-.2.1-.3.1-.4l.2-.4a.8.8,0,0,1,.1-.4v-.3a.9.9,0,0,0,.1-.5.4.4,0,0,1,.1-.3v-1.6a.4.4,0,0,1-.1-.3.9.9,0,0,0-.1-.5v-.3a.8.8,0,0,1-.1-.4l-.2-.4c0-.1-.1-.2-.1-.4l-.2-.3c0-.1-.1-.2-.1-.4l-.2-.3-.3-.3-.2-.3a.8.8,0,0,0-.2-.4c-.1-.1-.3-.2-.4-.4a.3.3,0,0,1-.1-.2l-48-48a8.1,8.1,0,0,0-11.4,11.4L204.7,96H168A103.9,103.9,0,0,0,67.3,174a8,8,0,0,0,5.7,9.7l2,.3a8.1,8.1,0,0,0,7.8-6A87.9,87.9,0,0,1,168,112h36.7l-34.4,34.3a8.1,8.1,0,0,0,0,11.4,8.2,8.2,0,0,0,11.4,0l48-48a.3.3,0,0,1,.1-.2C229.9,109.3,230.1,109.2,230.2,109.1Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M229.7,109.7l-48,48A8.3,8.3,0,0,1,176,160a8.5,8.5,0,0,1-3.1-.6A8,8,0,0,1,168,152V112a87.9,87.9,0,0,0-85.2,66,8.1,8.1,0,0,1-7.8,6l-2-.3a8,8,0,0,1-5.7-9.7A103.9,103.9,0,0,1,168,96V56a8,8,0,0,1,4.9-7.4,8.4,8.4,0,0,1,8.8,1.7l48,48A8.1,8.1,0,0,1,229.7,109.7ZM192,208H40V88a8,8,0,0,0-16,0V208a16,16,0,0,0,16,16H192a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M198,216a6,6,0,0,1-6,6H40a14,14,0,0,1-14-14V88a6,6,0,0,1,12,0V208a2,2,0,0,0,2,2H192A6,6,0,0,1,198,216Zm31-108.7h0a9.3,9.3,0,0,0,.5-1h.1l.3-1h0a6.1,6.1,0,0,0,0-2.2h0l-.3-1h-.1a9.3,9.3,0,0,0-.5-1h0a2.4,2.4,0,0,0-.8-.9l-48-48a5.9,5.9,0,0,0-8.4,8.4L209.5,98H168a102,102,0,0,0-98.8,76.5,6,6,0,0,0,4.3,7.3l1.5.2a6,6,0,0,0,5.8-4.5A90.1,90.1,0,0,1,168,110h41.5l-37.7,37.8a5.9,5.9,0,1,0,8.4,8.4l48-48A2.4,2.4,0,0,0,229,107.3Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M196,216a4,4,0,0,1-4,4H40a12,12,0,0,1-12-12V88a4,4,0,0,1,8,0V208a4,4,0,0,0,4,4H192A4,4,0,0,1,196,216Zm31.3-109.8.2-.3.2-.4c0-.1.1-.2.1-.4a.4.4,0,0,0,.1-.3,4.3,4.3,0,0,0,0-1.6.4.4,0,0,0-.1-.3c0-.2-.1-.3-.1-.4l-.2-.4-.2-.3-.5-.6-48-48a4,4,0,0,0-5.6,5.6L214.3,100H168a99.7,99.7,0,0,0-96.8,75,4,4,0,0,0,2.8,4.9h1a4.1,4.1,0,0,0,3.9-3A92,92,0,0,1,168,108h46.3l-41.1,41.2a3.9,3.9,0,0,0,0,5.6,3.9,3.9,0,0,0,5.6,0l48-48Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M200,216a8,8,0,0,1-8,8H40a16,16,0,0,1-16-16V88a8,8,0,0,1,16,0V208H192A8,8,0,0,1,200,216Zm30.2-106.9a.8.8,0,0,0,.2-.4l.2-.3.3-.3.2-.3c0-.2.1-.3.1-.4l.2-.3c0-.2.1-.3.1-.4l.2-.4a.8.8,0,0,1,.1-.4v-.3a.9.9,0,0,0,.1-.5.4.4,0,0,1,.1-.3v-1.6a.4.4,0,0,1-.1-.3.9.9,0,0,0-.1-.5v-.3a.8.8,0,0,1-.1-.4l-.2-.4c0-.1-.1-.2-.1-.4l-.2-.3c0-.1-.1-.2-.1-.4l-.2-.3-.3-.3-.2-.3a.8.8,0,0,0-.2-.4c-.1-.1-.3-.2-.4-.4a.3.3,0,0,1-.1-.2l-48-48a8.1,8.1,0,0,0-11.4,11.4L204.7,96H168A103.9,103.9,0,0,0,67.3,174a8,8,0,0,0,5.7,9.7l2,.3a8.1,8.1,0,0,0,7.8-6A87.9,87.9,0,0,1,168,112h36.7l-34.4,34.3a8.1,8.1,0,0,0,0,11.4,8.2,8.2,0,0,0,11.4,0l48-48a.3.3,0,0,1,.1-.2C229.9,109.3,230.1,109.2,230.2,109.1Z" />
        </>
      )}
    </svg>
  );
}

export function PhShieldCheck(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M208,36H48A20.1,20.1,0,0,0,28,56v58.7c0,92.2,78.1,122.7,93.8,127.9a19.7,19.7,0,0,0,12.4,0c15.7-5.2,93.8-35.7,93.8-127.9V56A20.1,20.1,0,0,0,208,36Zm-4,78.7c0,73.4-60.5,99.3-76,104.7-15.5-5.4-76-31.3-76-104.7V60H204Zm-128.3,26a12,12,0,1,1,16.6-17.4l21,20.1,50.4-48.1a12,12,0,1,1,16.6,17.4l-58.7,56a12,12,0,0,1-8.3,3.3,12.2,12.2,0,0,1-8.3-3.3Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M40,114.7V56a8,8,0,0,1,8-8H208a8,8,0,0,1,8,8v58.7c0,84-71.3,111.8-85.5,116.5a7.2,7.2,0,0,1-5,0C111.3,226.5,40,198.7,40,114.7Z" />
          </g>
          <path d="M208,40H48A16,16,0,0,0,32,56v58.7c0,89.4,75.8,119.1,91,124.1a16,16,0,0,0,10,0c15.2-5,91-34.7,91-124.1V56A16,16,0,0,0,208,40Zm0,74.7c0,78.2-66.4,104.4-80,108.9-13.5-4.5-80-30.6-80-108.9V56H208Z" />
          <path d="M107.8,165.8a7.9,7.9,0,0,0,5.5,2.2,8.1,8.1,0,0,0,5.6-2.2l58.6-56a8,8,0,0,0-11-11.6l-53.2,50.7L89.5,126.2a8,8,0,1,0-11,11.6Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M208,40H48A16,16,0,0,0,32,56v58.7c0,89.4,75.8,119.1,91,124.1a16,16,0,0,0,10,0c15.2-5,91-34.7,91-124.1V56A16,16,0,0,0,208,40Zm-30.5,69.8-58.6,56a8.1,8.1,0,0,1-5.6,2.2,7.9,7.9,0,0,1-5.5-2.2l-29.3-28a8,8,0,1,1,11-11.6l23.8,22.7,53.2-50.7a8,8,0,0,1,11,11.6Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M222,56a14,14,0,0,0-14-14H48A14,14,0,0,0,34,56v58.7c0,88,74.7,117.3,89.7,122.2a13.6,13.6,0,0,0,8.6,0c15-4.9,89.7-34.2,89.7-122.2ZM128.6,225.5a1.3,1.3,0,0,1-1.2,0C113.8,221,46,194.5,46,114.7V56a2,2,0,0,1,2-2H208a2,2,0,0,1,2,2v58.7C210,194.5,142.2,221,128.6,225.5ZM176.3,99.9a5.9,5.9,0,0,1-.2,8.4l-58.6,56a6.3,6.3,0,0,1-4.2,1.7,5.8,5.8,0,0,1-4.1-1.7l-29.3-28a5.9,5.9,0,0,1,8.2-8.6l25.2,24,54.6-52A5.9,5.9,0,0,1,176.3,99.9Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M208,44H48A12,12,0,0,0,36,56v58.7c0,86.7,73.5,115.4,88.3,120.3a11.7,11.7,0,0,0,7.4,0c14.8-4.9,88.3-33.6,88.3-120.3V56A12,12,0,0,0,208,44Zm4,70.7c0,81.2-69,108.2-82.8,112.7a3.3,3.3,0,0,1-2.4,0C113,222.9,44,195.9,44,114.7V56a4,4,0,0,1,4-4H208a4,4,0,0,1,4,4Zm-37.1-13.5a4.1,4.1,0,0,1-.1,5.7l-58.7,56a4.3,4.3,0,0,1-2.8,1.1,3.9,3.9,0,0,1-2.7-1.1l-29.4-28a4,4,0,1,1,5.6-5.8l26.5,25.4,55.9-53.4A4.1,4.1,0,0,1,174.9,101.2Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M208,40H48A16,16,0,0,0,32,56v58.7c0,89.4,75.8,119.1,91,124.1a16,16,0,0,0,10,0c15.2-5,91-34.7,91-124.1V56A16,16,0,0,0,208,40Zm0,74.7c0,78.2-66.4,104.4-80,108.9-13.5-4.5-80-30.6-80-108.9V56H208ZM78.5,137.8a8,8,0,1,1,11-11.6l23.8,22.7,53.2-50.7a8,8,0,0,1,11,11.6l-58.6,56a8.1,8.1,0,0,1-5.6,2.2,7.9,7.9,0,0,1-5.5-2.2Z" />
        </>
      )}
    </svg>
  );
}

export function PhShieldCheckered(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M228,114.7V56a20.1,20.1,0,0,0-20-20H48A20.1,20.1,0,0,0,28,56v58.7a136.8,136.8,0,0,0,.7,14.5h0c7.7,80.8,78.3,108.4,93.1,113.3a21.3,21.3,0,0,0,5.5,1h1.4a20.5,20.5,0,0,0,5.5-1c14.8-4.9,85.4-32.5,93.1-113.3v-.2A133.6,133.6,0,0,0,228,114.7Zm-24,0V116H140V60h64ZM52,60h64v56H52V60Zm2.6,80H116v74.4C96.4,204.9,64,183.2,54.6,140ZM140,214.4V140h61.4C192,183.2,159.6,204.9,140,214.4Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M128,48v80H40V56a8,8,0,0,1,8-8Zm87.4,80H128V231.6h0a7.7,7.7,0,0,0,2.5-.4c13.4-4.4,77.8-29.5,84.9-103.2Z"
            opacity="0.2"
          />
          <path d="M224,114.7V56a16,16,0,0,0-16-16H48A16,16,0,0,0,32,56v58.7a127.4,127.4,0,0,0,.7,14v.2c7.5,78.4,76,105.2,90.3,109.9a16,16,0,0,0,10,0c14.3-4.7,82.8-31.5,90.3-109.9v-.2A127.4,127.4,0,0,0,224,114.7Zm-16,0c0,1.8-.1,3.5-.1,5.3H136V56h72ZM48,56h72v64H48.1c-.1-1.8-.1-3.5-.1-5.3Zm1.8,80H120v84.6C100.3,212.2,58.8,188.7,49.8,136ZM136,220.6V136h70.2C197.2,188.7,155.7,212.2,136,220.6Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M208,40H48A16,16,0,0,0,32,56v58.7c0,89.4,75.8,119.1,91,124.1a16,16,0,0,0,10,0c15.2-5,91-34.7,91-124.1V56A16,16,0,0,0,208,40Zm0,74.7a114.9,114.9,0,0,1-.7,13.3H128v95.6h0C115.3,219.4,55.7,196,48.7,128H128V56h80Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M222,114.7V56a14,14,0,0,0-14-14H48A14,14,0,0,0,34,56v58.7q0,7,.6,13.8v.2c7.4,77.2,74.8,103.5,88.9,108.2a13.5,13.5,0,0,0,4.4.7,12.9,12.9,0,0,0,4.3-.7c14.2-4.7,81.6-31,89-108.2v-.3A121.9,121.9,0,0,0,222,114.7ZM210,56v58.7c0,2.5-.1,4.9-.2,7.3H134V54h74A2,2,0,0,1,210,56ZM46,56a2,2,0,0,1,2-2h74v68H46.2c-.1-2.4-.2-4.8-.2-7.3Zm1.4,78H122v89.6C103.2,216.1,56.2,191.9,47.4,134ZM134,223.5V134h74.6C199.8,191.9,152.8,216.1,134,223.5Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M208,44H48A12,12,0,0,0,36,56v58.7c0,86.7,73.5,115.4,88.3,120.3a11.5,11.5,0,0,0,3.7.6h0a11.5,11.5,0,0,0,3.7-.6c14.8-4.9,88.3-33.6,88.3-120.3V56A12,12,0,0,0,208,44Zm4,12v58.7q0,4.8-.3,9.3H132V52h76A4,4,0,0,1,212,56ZM44,56a4,4,0,0,1,4-4h76v72H44.3q-.3-4.5-.3-9.3Zm1.1,76H124v94.5C107,220.2,53.5,195.7,45.1,132ZM132,226.5V132h78.9C202.5,195.7,149,220.2,132,226.5Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M224,114.7V56a16,16,0,0,0-16-16H48A16,16,0,0,0,32,56v58.7a127.4,127.4,0,0,0,.7,14v.2c7.5,78.4,76,105.2,90.3,109.9a16,16,0,0,0,10,0c14.3-4.7,82.8-31.5,90.3-109.9v-.2A127.4,127.4,0,0,0,224,114.7Zm-16,0c0,1.8-.1,3.5-.1,5.3H136V56h72ZM48,56h72v64H48.1c-.1-1.8-.1-3.5-.1-5.3Zm1.8,80H120v84.6C100.3,212.2,58.8,188.7,49.8,136ZM136,220.6V136h70.2C197.2,188.7,155.7,212.2,136,220.6Z" />
        </>
      )}
    </svg>
  );
}

export function PhShieldChevron(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M208,36H48A20.1,20.1,0,0,0,28,56v58.7c0,92.2,78.1,122.7,93.8,127.9a19.7,19.7,0,0,0,12.4,0c15.7-5.2,93.8-35.7,93.8-127.9V56A20.1,20.1,0,0,0,208,36ZM52,60H204v54.7a111.6,111.6,0,0,1-6.7,39.2l-62.4-43.7a12,12,0,0,0-13.8,0L58.7,153.9A111.6,111.6,0,0,1,52,114.7Zm76,159.4c-10-3.5-39.1-15.7-58.3-44L128,134.6l58.3,40.8C167.1,203.7,138,215.9,128,219.4Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M202.4,172.1c-21.4,40.2-61.6,55.7-71.9,59.1a7.2,7.2,0,0,1-5,0c-10.3-3.4-50.5-18.9-71.9-59.1h0L128,120l74.4,52.1Z" />
          </g>
          <path d="M208,40H48A16,16,0,0,0,32,56v58.7c0,89.4,75.8,119.1,91,124.1a16,16,0,0,0,10,0c15.2-5,91-34.7,91-124.1V56A16,16,0,0,0,208,40ZM48,56H208v58.7a115.1,115.1,0,0,1-8.8,45.4l-66.6-46.7a8.1,8.1,0,0,0-9.2,0L56.8,160.1A115.1,115.1,0,0,1,48,114.7Zm80,167.6c-9.2-3.1-43.2-16.2-63.8-49.2L128,129.8l63.8,44.6C171.2,207.3,137.3,220.5,128,223.6Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M208,40H48A16,16,0,0,0,32,56v58.7c0,89.4,75.8,119.1,91,124.1a16,16,0,0,0,10,0c15.2-5,91-34.7,91-124.1V56A16,16,0,0,0,208,40ZM48,56H208v58.7a115.1,115.1,0,0,1-8.8,45.4l-66.6-46.7a8.1,8.1,0,0,0-9.2,0L56.8,160.1A115.1,115.1,0,0,1,48,114.7Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M222,56a14,14,0,0,0-14-14H48A14,14,0,0,0,34,56v58.7c0,88,74.7,117.3,89.7,122.2a13.6,13.6,0,0,0,8.6,0c15-4.9,89.7-34.2,89.7-122.2ZM128.6,225.5a1.3,1.3,0,0,1-1.2,0c-9.4-3.1-45-16.8-65.9-51.7L128,127.3l66.5,46.5C173.6,208.7,138,222.4,128.6,225.5Zm71.5-62.4-68.7-48a5.7,5.7,0,0,0-6.8,0l-68.7,48c-6-13.4-9.9-29.4-9.9-48.4V56a2,2,0,0,1,2-2H208a2,2,0,0,1,2,2v58.7C210,133.7,206.1,149.7,200.1,163.1Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M208,44H48A12,12,0,0,0,36,56v58.7c0,86.7,73.5,115.4,88.3,120.3a11.7,11.7,0,0,0,7.4,0c14.8-4.9,88.3-33.6,88.3-120.3V56A12,12,0,0,0,208,44ZM129.2,227.4a3.3,3.3,0,0,1-2.4,0c-9.7-3.2-46.8-17.5-67.9-54.1L128,124.9l69.1,48.4C176,209.9,138.9,224.2,129.2,227.4ZM212,114.7c0,20.4-4.4,37.4-11.1,51.4l-70.6-49.4a4.3,4.3,0,0,0-4.6,0L55.1,166.1c-6.7-14-11.1-31-11.1-51.4V56a4,4,0,0,1,4-4H208a4,4,0,0,1,4,4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M208,40H48A16,16,0,0,0,32,56v58.7c0,89.4,75.8,119.1,91,124.1a16,16,0,0,0,10,0c15.2-5,91-34.7,91-124.1V56A16,16,0,0,0,208,40ZM48,56H208v58.7a115.1,115.1,0,0,1-8.8,45.4l-66.6-46.7a8.1,8.1,0,0,0-9.2,0L56.8,160.1A115.1,115.1,0,0,1,48,114.7Zm80,167.6c-9.2-3.1-43.2-16.2-63.8-49.2L128,129.8l63.8,44.6C171.2,207.3,137.3,220.5,128,223.6Z" />
        </>
      )}
    </svg>
  );
}

export function PhShieldPlus(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M208,36H48A20.1,20.1,0,0,0,28,56v58.7c0,92.2,78.1,122.7,93.8,127.9a19.7,19.7,0,0,0,12.4,0c15.7-5.2,93.8-35.7,93.8-127.9V56A20.1,20.1,0,0,0,208,36Zm-4,78.7c0,73.4-60.5,99.3-76,104.7-15.5-5.4-76-31.3-76-104.7V60H204ZM84,128a12,12,0,0,1,12-12h20V96a12,12,0,0,1,24,0v20h20a12,12,0,0,1,0,24H140v20a12,12,0,0,1-24,0V140H96A12,12,0,0,1,84,128Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M216,56v58.7c0,84-71.3,111.8-85.5,116.5a7.2,7.2,0,0,1-5,0C111.3,226.5,40,198.7,40,114.7V56a8,8,0,0,1,8-8H208A8,8,0,0,1,216,56Z"
            opacity="0.2"
          />
          <path d="M208,40H48A16,16,0,0,0,32,56v58.7c0,89.4,75.8,119.1,91,124.1a16,16,0,0,0,10,0c15.2-5,91-34.7,91-124.1V56A16,16,0,0,0,208,40Zm0,74.7c0,78.2-66.4,104.4-80,108.9-13.5-4.5-80-30.6-80-108.9V56H208ZM88,128a8,8,0,0,1,8-8h24V96a8,8,0,0,1,16,0v24h24a8,8,0,0,1,0,16H136v24a8,8,0,0,1-16,0V136H96A8,8,0,0,1,88,128Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M208,40H48A16,16,0,0,0,32,56v58.7c0,89.4,75.8,119.1,91,124.1a16,16,0,0,0,10,0c15.2-5,91-34.7,91-124.1V56A16,16,0,0,0,208,40Zm-48,96H136v24a8,8,0,0,1-16,0V136H96a8,8,0,0,1,0-16h24V96a8,8,0,0,1,16,0v24h24a8,8,0,0,1,0,16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M208,42H48A14,14,0,0,0,34,56v58.7c0,88,74.7,117.3,89.6,122.2a13.5,13.5,0,0,0,4.4.7,12.9,12.9,0,0,0,4.3-.7c15-4.9,89.7-34.2,89.7-122.2V56A14,14,0,0,0,208,42Zm2,72.7c0,79.8-67.8,106.3-81.4,110.8a1.3,1.3,0,0,1-1.2,0C113.8,221,46,194.5,46,114.7V56a2,2,0,0,1,2-2H208a2,2,0,0,1,2,2ZM166,128a6,6,0,0,1-6,6H134v26a6,6,0,0,1-12,0V134H96a6,6,0,0,1,0-12h26V96a6,6,0,0,1,12,0v26h26A6,6,0,0,1,166,128Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M208,44H48A12,12,0,0,0,36,56v58.7c0,86.7,73.5,115.4,88.3,120.3a11.7,11.7,0,0,0,7.4,0c14.8-4.9,88.3-33.6,88.3-120.3V56A12,12,0,0,0,208,44Zm4,70.7c0,81.2-69,108.2-82.8,112.7a3.3,3.3,0,0,1-2.4,0C113,222.9,44,195.9,44,114.7V56a4,4,0,0,1,4-4H208a4,4,0,0,1,4,4ZM164,128a4,4,0,0,1-4,4H132v28a4,4,0,0,1-8,0V132H96a4,4,0,0,1,0-8h28V96a4,4,0,0,1,8,0v28h28A4,4,0,0,1,164,128Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M208,40H48A16,16,0,0,0,32,56v58.7c0,89.4,75.8,119.1,91,124.1a16,16,0,0,0,10,0c15.2-5,91-34.7,91-124.1V56A16,16,0,0,0,208,40Zm0,74.7c0,78.2-66.4,104.4-80,108.9-13.5-4.5-80-30.6-80-108.9V56H208ZM88,128a8,8,0,0,1,8-8h24V96a8,8,0,0,1,16,0v24h24a8,8,0,0,1,0,16H136v24a8,8,0,0,1-16,0V136H96A8,8,0,0,1,88,128Z" />
        </>
      )}
    </svg>
  );
}

export function PhShieldSlash(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M231.4,223.9l-35-38.5h-.1L42.4,15.9A12,12,0,1,0,24.6,32.1l9.1,10A19.9,19.9,0,0,0,28,56v58.7c0,92.2,78.1,122.7,93.8,127.9a19.7,19.7,0,0,0,12.4,0,151,151,0,0,0,53-31.6l26.4,29.1a12.1,12.1,0,0,0,17,.8A12.1,12.1,0,0,0,231.4,223.9ZM128,219.4c-15.5-5.4-76-31.3-76-104.7V62.2L171.1,193.3A127.6,127.6,0,0,1,128,219.4ZM97.4,48a12,12,0,0,1,12-12H208a20.1,20.1,0,0,1,20,20v58.7a138.2,138.2,0,0,1-7.8,46.9,12,12,0,0,1-11.3,7.9,11.4,11.4,0,0,1-4.1-.7,12,12,0,0,1-7.2-15.4,115.1,115.1,0,0,0,6.4-38.7V60H109.4A12,12,0,0,1,97.4,48Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M216,56v58.7c0,84-71.3,111.8-85.5,116.5a7.2,7.2,0,0,1-5,0C111.3,226.5,40,198.7,40,114.7V56a8,8,0,0,1,8-8H208A8,8,0,0,1,216,56Z"
            opacity="0.2"
          />
          <path d="M228.4,226.6l-35-38.5h0L39.4,18.6a8,8,0,0,0-11.3-.5,7.9,7.9,0,0,0-.5,11.3L39.5,42.5A15.9,15.9,0,0,0,32,56v58.7c0,89.4,75.8,119.1,91,124.1a14.3,14.3,0,0,0,10,0,146.4,146.4,0,0,0,54.4-33.5l29.2,32.1a8,8,0,0,0,5.9,2.6,8.2,8.2,0,0,0,5.4-2.1A7.9,7.9,0,0,0,228.4,226.6Zm-100.4-3c-13.5-4.5-80-30.6-80-108.9V56h3.8L176.6,193.4A129.4,129.4,0,0,1,128,223.6ZM90.5,48a8,8,0,0,1,8-8H208a16,16,0,0,1,16,16v58.7c0,19.7-3.8,38-11.2,54.3a8,8,0,0,1-10.6,4,8,8,0,0,1-4-10.6c6.5-14.3,9.8-30.3,9.8-47.7V56H98.5A8,8,0,0,1,90.5,48Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M92.6,53.4a8,8,0,0,1-1.4-8.6A8.1,8.1,0,0,1,98.5,40H208a16,16,0,0,1,16,16v58.7c0,19.7-3.8,38-11.2,54.3a7.9,7.9,0,0,1-6,4.6h-1.3a7.9,7.9,0,0,1-5.9-2.6ZM228.4,226.6l-34.9-38.4h0L61.2,42.6h0l-21.8-24a8,8,0,0,0-11.3-.5,7.9,7.9,0,0,0-.5,11.3L39.5,42.5A15.9,15.9,0,0,0,32,56v58.7c0,89.4,75.8,119.1,91,124.1a14.3,14.3,0,0,0,10,0,146.4,146.4,0,0,0,54.4-33.5l29.2,32.1a8,8,0,0,0,5.9,2.6,8.2,8.2,0,0,0,5.4-2.1A7.9,7.9,0,0,0,228.4,226.6Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M226.9,228,192,189.5h-.1L37.9,20a5.9,5.9,0,1,0-8.8,8L42.7,43A14.1,14.1,0,0,0,34,56v58.7c0,88,74.7,117.3,89.7,122.2a13.6,13.6,0,0,0,8.6,0,144,144,0,0,0,55.2-34.5L218.1,236a5.9,5.9,0,0,0,4.4,2,5.7,5.7,0,0,0,4-1.6A5.8,5.8,0,0,0,226.9,228Zm-98.3-2.5a1.3,1.3,0,0,1-1.2,0C113.8,221,46,194.5,46,114.7V56a2,2,0,0,1,2-2h4.7L179.4,193.5A130.8,130.8,0,0,1,128.6,225.5ZM92.5,48a6,6,0,0,1,6-6H208a14,14,0,0,1,14,14v58.7c0,19.4-3.7,37.4-11,53.5a6,6,0,0,1-5.5,3.5,5.2,5.2,0,0,1-2.4-.5,5.9,5.9,0,0,1-3-7.9c6.6-14.6,9.9-30.9,9.9-48.6V56a2,2,0,0,0-2-2H98.5A6,6,0,0,1,92.5,48Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M225.5,229.3l-189-208a4,4,0,1,0-6,5.4L46.4,44.1A12,12,0,0,0,36,56v58.7c0,86.7,73.5,115.4,88.3,120.3a11.7,11.7,0,0,0,7.4,0c7.8-2.6,34.1-12.5,55.8-35.5l32,35.2a4.1,4.1,0,0,0,5.7.3A4.2,4.2,0,0,0,225.5,229.3Zm-96.3-1.9a3.3,3.3,0,0,1-2.4,0C113,222.9,44,195.9,44,114.7V56a4,4,0,0,1,4-4h5.5L182.1,193.5C161.6,215.5,136.6,225,129.2,227.4ZM94.5,48a4,4,0,0,1,4-4H208a12,12,0,0,1,12,12v58.7c0,19.1-3.6,36.8-10.8,52.7a4,4,0,0,1-3.7,2.3,4.5,4.5,0,0,1-1.6-.3,4,4,0,0,1-2-5.3c6.7-14.8,10.1-31.4,10.1-49.4V56a4,4,0,0,0-4-4H98.5A4,4,0,0,1,94.5,48Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M228.4,226.6l-35-38.5h0L39.4,18.6a8,8,0,0,0-11.3-.5,7.9,7.9,0,0,0-.5,11.3L39.5,42.5A15.9,15.9,0,0,0,32,56v58.7c0,89.4,75.8,119.1,91,124.1a14.3,14.3,0,0,0,10,0,146.4,146.4,0,0,0,54.4-33.5l29.2,32.1a8,8,0,0,0,5.9,2.6,8.2,8.2,0,0,0,5.4-2.1A7.9,7.9,0,0,0,228.4,226.6Zm-100.4-3c-13.5-4.5-80-30.6-80-108.9V56h3.8L176.6,193.4A129.4,129.4,0,0,1,128,223.6ZM90.5,48a8,8,0,0,1,8-8H208a16,16,0,0,1,16,16v58.7c0,19.7-3.8,38-11.2,54.3a8,8,0,0,1-10.6,4,8,8,0,0,1-4-10.6c6.5-14.3,9.8-30.3,9.8-47.7V56H98.5A8,8,0,0,1,90.5,48Z" />
        </>
      )}
    </svg>
  );
}

export function PhShieldStar(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M208,36H48A20.1,20.1,0,0,0,28,56v58.7c0,92.2,78.1,122.7,93.8,127.9a19.7,19.7,0,0,0,12.4,0c15.7-5.2,93.8-35.7,93.8-127.9V56A20.1,20.1,0,0,0,208,36Zm-4,78.7c0,73.4-60.5,99.3-76,104.7-15.5-5.4-76-31.3-76-104.7V60H204ZM78.5,115.9a12.1,12.1,0,0,1,15.2-7.7l22.3,7.3V92a12,12,0,0,1,24,0v23.5l22.3-7.3a12,12,0,1,1,7.5,22.9l-22.4,7.2,13.8,19a12.1,12.1,0,0,1-2.6,16.8,12.5,12.5,0,0,1-7.1,2.3,12.2,12.2,0,0,1-9.7-5l-13.8-19-13.8,19a12.2,12.2,0,0,1-9.7,5,12.5,12.5,0,0,1-7.1-2.3,12.1,12.1,0,0,1-2.6-16.8l13.8-19-22.4-7.2A12.1,12.1,0,0,1,78.5,115.9Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M216,56v58.7c0,84-71.3,111.8-85.5,116.5a7.2,7.2,0,0,1-5,0C111.3,226.5,40,198.7,40,114.7V56a8,8,0,0,1,8-8H208A8,8,0,0,1,216,56Z"
            opacity="0.2"
          />
          <path d="M208,40H48A16,16,0,0,0,32,56v58.7c0,89.4,75.8,119.1,91,124.1a14.3,14.3,0,0,0,10,0c15.2-5,91-34.7,91-124.1V56A16,16,0,0,0,208,40Zm0,74.7c0,78.2-66.4,104.4-80,108.9-13.5-4.5-80-30.6-80-108.9V56H208ZM82.3,121.2A8.1,8.1,0,0,1,92.4,116l27.6,9V96a8,8,0,0,1,16,0v29l27.6-9a8.1,8.1,0,0,1,10.1,5.2,8,8,0,0,1-5.2,10l-27.6,9L158,163.7a7.9,7.9,0,0,1-1.8,11.1,7.6,7.6,0,0,1-4.7,1.6,8.2,8.2,0,0,1-6.5-3.3l-17-23.5-17,23.5a8.2,8.2,0,0,1-6.5,3.3,7.6,7.6,0,0,1-4.7-1.6A7.9,7.9,0,0,1,98,163.7l17.1-23.5-27.6-9A8,8,0,0,1,82.3,121.2Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M208,40H48A16,16,0,0,0,32,56v58.7c0,89.4,75.8,119.1,91,124.1a14.3,14.3,0,0,0,10,0c15.2-5,91-34.7,91-124.1V56A16,16,0,0,0,208,40Zm-39.5,91.2-27.6,9L158,163.7a7.9,7.9,0,0,1-1.8,11.1,7.6,7.6,0,0,1-4.7,1.6,8.2,8.2,0,0,1-6.5-3.3l-17-23.5-17,23.5a8.2,8.2,0,0,1-6.5,3.3,7.6,7.6,0,0,1-4.7-1.6A7.9,7.9,0,0,1,98,163.7l17.1-23.5-27.6-9a8,8,0,0,1-5.2-10A8.1,8.1,0,0,1,92.4,116l27.6,9V96a8,8,0,0,1,16,0v29l27.6-9a8.1,8.1,0,0,1,10.1,5.2A8,8,0,0,1,168.5,131.2Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M208,42H48A14,14,0,0,0,34,56v58.7c0,88,74.7,117.3,89.7,122.2a13.6,13.6,0,0,0,8.6,0c15-4.9,89.7-34.2,89.7-122.2V56A14,14,0,0,0,208,42Zm2,72.7c0,79.8-67.8,106.3-81.4,110.8a1.3,1.3,0,0,1-1.2,0C113.8,221,46,194.5,46,114.7V56a2,2,0,0,1,2-2H208a2,2,0,0,1,2,2Zm-38.3,7.1a5.9,5.9,0,0,1-3.8,7.5l-30.2,9.9,18.7,25.6a6.1,6.1,0,0,1-1.4,8.4,5.7,5.7,0,0,1-3.5,1.2,5.9,5.9,0,0,1-4.8-2.5L128,146.2l-18.7,25.7a5.9,5.9,0,0,1-4.8,2.5,5.7,5.7,0,0,1-3.5-1.2,6.1,6.1,0,0,1-1.4-8.4l18.7-25.6-30.2-9.9a6,6,0,0,1,3.7-11.4l30.2,9.8V96a6,6,0,0,1,12,0v31.7l30.2-9.8A6,6,0,0,1,171.7,121.8Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M208,44H48A12,12,0,0,0,36,56v58.7c0,86.7,73.5,115.4,88.3,120.3a11.7,11.7,0,0,0,7.4,0c14.8-4.9,88.3-33.6,88.3-120.3V56A12,12,0,0,0,208,44Zm4,70.7c0,81.2-69,108.2-82.8,112.7a3.3,3.3,0,0,1-2.4,0C113,222.9,44,195.9,44,114.7V56a4,4,0,0,1,4-4H208a4,4,0,0,1,4,4Zm-42.2,7.7a3.9,3.9,0,0,1-2.5,5l-32.8,10.7L154.7,166a4,4,0,0,1-.8,5.6,4.3,4.3,0,0,1-2.4.8,4,4,0,0,1-3.2-1.7L128,142.8l-20.3,27.9a4,4,0,0,1-3.2,1.7,4.3,4.3,0,0,1-2.4-.8,4,4,0,0,1-.8-5.6l20.2-27.9L88.7,127.4a3.9,3.9,0,0,1-2.5-5,4,4,0,0,1,5-2.6L124,130.5V96a4,4,0,0,1,8,0v34.5l32.8-10.7A4,4,0,0,1,169.8,122.4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M208,40H48A16,16,0,0,0,32,56v58.7c0,89.4,75.8,119.1,91,124.1a14.3,14.3,0,0,0,10,0c15.2-5,91-34.7,91-124.1V56A16,16,0,0,0,208,40Zm0,74.7c0,78.2-66.4,104.4-80,108.9-13.5-4.5-80-30.6-80-108.9V56H208ZM82.3,121.2A8.1,8.1,0,0,1,92.4,116l27.6,9V96a8,8,0,0,1,16,0v29l27.6-9a8.1,8.1,0,0,1,10.1,5.2,8,8,0,0,1-5.2,10l-27.6,9L158,163.7a7.9,7.9,0,0,1-1.8,11.1,7.6,7.6,0,0,1-4.7,1.6,8.2,8.2,0,0,1-6.5-3.3l-17-23.5-17,23.5a8.2,8.2,0,0,1-6.5,3.3,7.6,7.6,0,0,1-4.7-1.6A7.9,7.9,0,0,1,98,163.7l17.1-23.5-27.6-9A8,8,0,0,1,82.3,121.2Z" />
        </>
      )}
    </svg>
  );
}

export function PhShieldWarning(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M208,36H48A20.1,20.1,0,0,0,28,56v58.7c0,92.2,78.1,122.7,93.8,127.9a19.7,19.7,0,0,0,12.4,0c15.7-5.2,93.8-35.7,93.8-127.9V56A20.1,20.1,0,0,0,208,36Zm-4,78.7c0,73.4-60.5,99.3-76,104.7-15.5-5.4-76-31.3-76-104.7V60H204ZM116,132V92a12,12,0,0,1,24,0v40a12,12,0,0,1-24,0Zm.7,51.3A15.9,15.9,0,0,1,112,172a16,16,0,0,1,32,0h0a16,16,0,0,1-16,16A15.9,15.9,0,0,1,116.7,183.3Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M40,114.7V56a8,8,0,0,1,8-8H208a8,8,0,0,1,8,8v58.7c0,84-71.3,111.8-85.5,116.5a7.2,7.2,0,0,1-5,0C111.3,226.5,40,198.7,40,114.7Z" />
          </g>
          <path d="M208,40H48A16,16,0,0,0,32,56v58.7c0,89.4,75.8,119.1,91,124.1a14.3,14.3,0,0,0,10,0c15.2-5,91-34.7,91-124.1V56A16,16,0,0,0,208,40Zm0,74.7c0,78.2-66.4,104.4-80,108.9-13.5-4.5-80-30.6-80-108.9V56H208Z" />
          <path d="M128,144a8,8,0,0,0,8-8V96a8,8,0,0,0-16,0v40A8,8,0,0,0,128,144Z" />
          <circle cx="128" cy="172" r="12" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M208,40H48A16,16,0,0,0,32,56v58.7c0,89.4,75.8,119.1,91,124.1a14.3,14.3,0,0,0,10,0c15.2-5,91-34.7,91-124.1V56A16,16,0,0,0,208,40ZM120,96a8,8,0,0,1,16,0v40a8,8,0,0,1-16,0Zm8,88a12,12,0,1,1,12-12A12,12,0,0,1,128,184Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M208,42H48A14,14,0,0,0,34,56v58.7c0,88,74.7,117.3,89.7,122.2a13.6,13.6,0,0,0,8.6,0c15-4.9,89.7-34.2,89.7-122.2V56A14,14,0,0,0,208,42Zm2,72.7c0,79.8-67.8,106.3-81.4,110.8a1.3,1.3,0,0,1-1.2,0C113.8,221,46,194.5,46,114.7V56a2,2,0,0,1,2-2H208a2,2,0,0,1,2,2ZM122,136V96a6,6,0,0,1,12,0v40a6,6,0,0,1-12,0Zm16,36h0a10,10,0,0,1-10,10,9.9,9.9,0,0,1-10-10,10,10,0,0,1,20,0Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M208,44H48A12,12,0,0,0,36,56v58.7c0,86.7,73.5,115.4,88.3,120.3a11.7,11.7,0,0,0,7.4,0c14.8-4.9,88.3-33.6,88.3-120.3V56A12,12,0,0,0,208,44Zm4,70.7c0,81.2-69,108.2-82.8,112.7a3.3,3.3,0,0,1-2.4,0C113,222.9,44,195.9,44,114.7V56a4,4,0,0,1,4-4H208a4,4,0,0,1,4,4ZM124,136V96a4,4,0,0,1,8,0v40a4,4,0,0,1-8,0Zm12,36h0a8,8,0,0,1-8,8,7.9,7.9,0,0,1-8-8,8,8,0,0,1,16,0Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M208,40H48A16,16,0,0,0,32,56v58.7c0,89.4,75.8,119.1,91,124.1a14.3,14.3,0,0,0,10,0c15.2-5,91-34.7,91-124.1V56A16,16,0,0,0,208,40Zm0,74.7c0,78.2-66.4,104.4-80,108.9-13.5-4.5-80-30.6-80-108.9V56H208ZM120,136V96a8,8,0,0,1,16,0v40a8,8,0,0,1-16,0Zm-.5,44.5A11.9,11.9,0,0,1,116,172a12,12,0,0,1,24,0h0a12,12,0,0,1-12,12A11.9,11.9,0,0,1,119.5,180.5Z" />
        </>
      )}
    </svg>
  );
}

export function PhShield(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,243.6a18.7,18.7,0,0,1-6.2-1C106.1,237.4,28,206.9,28,114.7V56A20.1,20.1,0,0,1,48,36H208a20.1,20.1,0,0,1,20,20v58.7c0,92.2-78.1,122.7-93.8,127.9A18.7,18.7,0,0,1,128,243.6Zm1.3-23.8ZM52,60v54.7c0,73.4,60.5,99.3,76,104.7,15.5-5.4,76-31.3,76-104.7V60Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M40,114.7V56a8,8,0,0,1,8-8H208a8,8,0,0,1,8,8v58.7c0,84-71.3,111.8-85.5,116.5a7.2,7.2,0,0,1-5,0C111.3,226.5,40,198.7,40,114.7Z" />
          </g>
          <path d="M208,40H48A16,16,0,0,0,32,56v58.7c0,89.4,75.8,119.1,91,124.1a16,16,0,0,0,10,0c15.2-5,91-34.7,91-124.1V56A16,16,0,0,0,208,40Zm0,74.7c0,78.2-66.4,104.4-80,108.9-13.5-4.5-80-30.6-80-108.9V56H208Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M224,56v58.7c0,89.4-75.8,119.1-91,124.1a16,16,0,0,1-10,0c-15.2-5-91-34.7-91-124.1V56A16,16,0,0,1,48,40H208A16,16,0,0,1,224,56Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,237.6a12.5,12.5,0,0,1-4.3-.7C108.7,232,34,202.7,34,114.7V56A14,14,0,0,1,48,42H208a14,14,0,0,1,14,14v58.7c0,88-74.7,117.3-89.7,122.2A12.5,12.5,0,0,1,128,237.6ZM48,54a2,2,0,0,0-2,2v58.7c0,79.8,67.8,106.3,81.4,110.8a1.3,1.3,0,0,0,1.2,0c13.6-4.5,81.4-31,81.4-110.8V56a2,2,0,0,0-2-2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,235.6a11.9,11.9,0,0,1-3.7-.6C109.5,230.1,36,201.4,36,114.7V56A12,12,0,0,1,48,44H208a12,12,0,0,1,12,12v58.7c0,86.7-73.5,115.4-88.3,120.3A11.9,11.9,0,0,1,128,235.6ZM48,52a4,4,0,0,0-4,4v58.7c0,81.2,69,108.2,82.8,112.7a3.3,3.3,0,0,0,2.4,0C143,222.9,212,195.9,212,114.7V56a4,4,0,0,0-4-4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,239.6a14.5,14.5,0,0,1-5-.8c-15.2-5-91-34.7-91-124.1V56A16,16,0,0,1,48,40H208a16,16,0,0,1,16,16v58.7c0,89.4-75.8,119.1-91,124.1A14.5,14.5,0,0,1,128,239.6ZM48,56v58.7c0,78.3,66.5,104.4,80,108.9,13.6-4.5,80-30.7,80-108.9V56Z" />
        </>
      )}
    </svg>
  );
}

export function PhShoppingBagOpen(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216,36H40A20.1,20.1,0,0,0,20,56V200a20.1,20.1,0,0,0,20,20H216a20.1,20.1,0,0,0,20-20V56A20.1,20.1,0,0,0,216,36Zm-4,24V76H44V60ZM44,196V100H212v96Zm136-72a52,52,0,0,1-104,0,12,12,0,0,1,24,0,28,28,0,0,0,56,0,12,12,0,0,1,24,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M32,80H224V56a8,8,0,0,0-8-8H40a8,8,0,0,0-8,8Z" />
          </g>
          <path d="M216,40H40A16,16,0,0,0,24,56V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40Zm0,16V72H40V56Zm0,144H40V88H216V200Z" />
          <path d="M168,104a8,8,0,0,0-8,8,32,32,0,0,1-64,0,8,8,0,0,0-16,0,48,48,0,0,0,96,0A8,8,0,0,0,168,104Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,40H40A16,16,0,0,0,24,56V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40ZM128,160a48,48,0,0,1-48-48,8,8,0,0,1,16,0,32,32,0,0,0,64,0,8,8,0,0,1,16,0A48,48,0,0,1,128,160ZM40,72V56H216V72Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M216,42H40A14,14,0,0,0,26,56V200a14,14,0,0,0,14,14H216a14,14,0,0,0,14-14V56A14,14,0,0,0,216,42ZM40,54H216a2,2,0,0,1,2,2V74H38V56A2,2,0,0,1,40,54ZM216,202H40a2,2,0,0,1-2-2V86H218V200A2,2,0,0,1,216,202Zm-42-90a46,46,0,0,1-92,0,6,6,0,0,1,12,0,34,34,0,0,0,68,0,6,6,0,0,1,12,0Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M216,44H40A12,12,0,0,0,28,56V200a12,12,0,0,0,12,12H216a12,12,0,0,0,12-12V56A12,12,0,0,0,216,44ZM40,52H216a4,4,0,0,1,4,4V76H36V56A4,4,0,0,1,40,52ZM216,204H40a4,4,0,0,1-4-4V84H220V200A4,4,0,0,1,216,204Zm-44-92a44,44,0,0,1-88,0,4,4,0,0,1,8,0,36,36,0,0,0,72,0,4,4,0,0,1,8,0Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,40H40A16,16,0,0,0,24,56V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40Zm0,16V72H40V56Zm0,144H40V88H216V200Zm-40-88a48,48,0,0,1-96,0,8,8,0,0,1,16,0,32,32,0,0,0,64,0,8,8,0,0,1,16,0Z" />
        </>
      )}
    </svg>
  );
}

export function PhShoppingBag(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216,36H40A20.1,20.1,0,0,0,20,56V200a20.1,20.1,0,0,0,20,20H216a20.1,20.1,0,0,0,20-20V56A20.1,20.1,0,0,0,216,36Zm-4,160H44V60H212ZM76,88a12,12,0,0,1,24,0,28,28,0,0,0,56,0,12,12,0,0,1,24,0A52,52,0,0,1,76,88Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect x="32" y="48" width="192" height="160" rx="8" />
          </g>
          <path d="M216,40H40A16,16,0,0,0,24,56V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40Zm0,160H40V56H216V200Z" />
          <path d="M168,80a8,8,0,0,0-8,8,32,32,0,0,1-64,0,8,8,0,0,0-16,0,48,48,0,0,0,96,0A8,8,0,0,0,168,80Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,40H40A16,16,0,0,0,24,56V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40Zm-88,96A48,48,0,0,1,80,88a8,8,0,0,1,16,0,32,32,0,0,0,64,0,8,8,0,0,1,16,0A48,48,0,0,1,128,136Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M216,42H40A14,14,0,0,0,26,56V200a14,14,0,0,0,14,14H216a14,14,0,0,0,14-14V56A14,14,0,0,0,216,42Zm2,158a2,2,0,0,1-2,2H40a2,2,0,0,1-2-2V56a2,2,0,0,1,2-2H216a2,2,0,0,1,2,2ZM174,88a46,46,0,0,1-92,0,6,6,0,0,1,12,0,34,34,0,0,0,68,0,6,6,0,0,1,12,0Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M216,44H40A12,12,0,0,0,28,56V200a12,12,0,0,0,12,12H216a12,12,0,0,0,12-12V56A12,12,0,0,0,216,44Zm4,156a4,4,0,0,1-4,4H40a4,4,0,0,1-4-4V56a4,4,0,0,1,4-4H216a4,4,0,0,1,4,4ZM172,88a44,44,0,0,1-88,0,4,4,0,0,1,8,0,36,36,0,0,0,72,0,4,4,0,0,1,8,0Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,40H40A16,16,0,0,0,24,56V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40Zm0,160H40V56H216V200ZM176,88a48,48,0,0,1-96,0,8,8,0,0,1,16,0,32,32,0,0,0,64,0,8,8,0,0,1,16,0Z" />
        </>
      )}
    </svg>
  );
}

export function PhShoppingCartSimple(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M100,216a20,20,0,1,1-20-20A20.1,20.1,0,0,1,100,216Zm84-20a20,20,0,1,0,20,20A20.1,20.1,0,0,0,184,196ZM233.3,75.3l-24.2,84.4A28.1,28.1,0,0,1,182.2,180H81.8a28.1,28.1,0,0,1-26.9-20.3L30.8,75.5c0-.1-.1-.3-.1-.4L21.8,44H12a12,12,0,0,1,0-24H24.8A20.3,20.3,0,0,1,44.1,34.5L51.3,60H221.7a12.1,12.1,0,0,1,11.6,15.3ZM205.8,84H58.2l19.7,69.1a4.1,4.1,0,0,0,3.9,2.9H182.2a4.1,4.1,0,0,0,3.9-2.9Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M221.7,72l-26.4,92.4A15.9,15.9,0,0,1,179.9,176H84.1a15.9,15.9,0,0,1-15.4-11.6L42.3,72Z"
            opacity="0.2"
          />
          <path d="M96,216a16,16,0,1,1-16-16A16,16,0,0,1,96,216Zm88-16a16,16,0,1,0,16,16A16,16,0,0,0,184,200ZM229.4,74.2,203,166.6A24.1,24.1,0,0,1,179.9,184H84.1A24.1,24.1,0,0,1,61,166.6L34.6,74.3v-.2L24.8,40H8A8,8,0,0,1,8,24H24.8A16.1,16.1,0,0,1,40.2,35.6L48.3,64H221.7a8,8,0,0,1,7.7,10.2ZM211.1,80H52.9l23.5,82.2a8,8,0,0,0,7.7,5.8h95.8a8,8,0,0,0,7.7-5.8Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M200,216a16,16,0,1,1-16-16A16,16,0,0,1,200,216ZM80,200a16,16,0,1,0,16,16A16,16,0,0,0,80,200ZM228.1,67.2a8.1,8.1,0,0,0-6.4-3.2H48.3L40.2,35.6A16.1,16.1,0,0,0,24.8,24H8A8,8,0,0,0,8,40H24.8l9.8,34.1v.2L61,166.6A24.1,24.1,0,0,0,84.1,184h95.8A24.1,24.1,0,0,0,203,166.6l26.4-92.4A8,8,0,0,0,228.1,67.2Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M94,216a14,14,0,1,1-14-14A14,14,0,0,1,94,216Zm90-14a14,14,0,1,0,14,14A14,14,0,0,0,184,202ZM227.5,73.6,201.1,166a22.2,22.2,0,0,1-21.2,16H84.1a22.2,22.2,0,0,1-21.2-16L36.5,73.8v-.3l-9.8-34A1.9,1.9,0,0,0,24.8,38H8A6,6,0,0,1,8,26H24.8A14.1,14.1,0,0,1,38.3,36.2L46.8,66H221.7a6,6,0,0,1,4.8,2.4A6,6,0,0,1,227.5,73.6ZM213.8,78H50.2l24.3,84.7a10,10,0,0,0,9.6,7.3h95.8a10,10,0,0,0,9.6-7.3Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M92,216a12,12,0,1,1-12-12A12,12,0,0,1,92,216Zm92-12a12,12,0,1,0,12,12A12,12,0,0,0,184,204ZM225.6,73.1l-26.4,92.4A20.3,20.3,0,0,1,179.9,180H84.1a20.3,20.3,0,0,1-19.3-14.5L38.5,73.2V73L28.7,38.9A4.1,4.1,0,0,0,24.8,36H8a4,4,0,0,1,0-8H24.8a12.1,12.1,0,0,1,11.6,8.7L45.3,68H221.7a3.9,3.9,0,0,1,3.2,1.6A4,4,0,0,1,225.6,73.1ZM216.4,76H47.6l24.9,87.3A12.1,12.1,0,0,0,84.1,172h95.8a12.1,12.1,0,0,0,11.6-8.7Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M96,216a16,16,0,1,1-16-16A16,16,0,0,1,96,216Zm88-16a16,16,0,1,0,16,16A16,16,0,0,0,184,200ZM229.4,74.2,203,166.6A24.1,24.1,0,0,1,179.9,184H84.1A24.1,24.1,0,0,1,61,166.6L34.6,74.3v-.2L24.8,40H8A8,8,0,0,1,8,24H24.8A16.1,16.1,0,0,1,40.2,35.6L48.3,64H221.7a8,8,0,0,1,7.7,10.2ZM211.1,80H52.9l23.5,82.2a8,8,0,0,0,7.7,5.8h95.8a8,8,0,0,0,7.7-5.8Z" />
        </>
      )}
    </svg>
  );
}

export function PhShoppingCart(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M215.6,133l12.2-66.9A12,12,0,0,0,216,52H58L53.7,28.4A19.9,19.9,0,0,0,34.1,12H16a12,12,0,0,0,0,24H30.7L57.2,181.6A32,32,0,1,0,112,204a35,35,0,0,0-1-8h42a35,35,0,0,0-1,8,32,32,0,1,0,32-32H79.8l-2.9-16H188.1A28,28,0,0,0,215.6,133ZM88,204a8,8,0,1,1-8-8A8,8,0,0,1,88,204Zm96,8a8,8,0,1,1,8-8A8,8,0,0,1,184,212ZM62.4,76H201.6L192,128.7a4,4,0,0,1-3.9,3.3H72.6Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M216,64l-12.2,66.9A15.9,15.9,0,0,1,188.1,144H62.5L48,64Z"
            opacity="0.2"
          />
          <path d="M211.7,132.3l12.2-66.9a7.7,7.7,0,0,0-1.8-6.5A7.9,7.9,0,0,0,216,56H54.7L49.8,29.1A16,16,0,0,0,34.1,16H16a8,8,0,0,0,0,16H34.1L61.5,183a28,28,0,1,0,43.8,9h53.4A28,28,0,1,0,184,176H76.5l-4.4-24h116A24,24,0,0,0,211.7,132.3ZM92,204a12,12,0,1,1-12-12A12,12,0,0,1,92,204Zm104,0a12,12,0,1,1-12-12A12,12,0,0,1,196,204ZM57.6,72H206.4L196,129.4a8,8,0,0,1-7.9,6.6H69.2Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M223.9,65.4l-12.2,66.9A24,24,0,0,1,188.1,152H72.1l4.4,24H184a24,24,0,1,1-24,24,23.6,23.6,0,0,1,1.4-8H102.6a23.6,23.6,0,0,1,1.4,8,24,24,0,1,1-42.2-15.6L34.1,32H16a8,8,0,0,1,0-16H34.1A16,16,0,0,1,49.8,29.1L54.7,56H216a7.9,7.9,0,0,1,6.1,2.9A7.7,7.7,0,0,1,223.9,65.4Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M209.7,131.9l12.2-66.8a6.1,6.1,0,0,0-1.3-4.9A5.8,5.8,0,0,0,216,58H53L47.8,29.5A13.9,13.9,0,0,0,34.1,18H16a6,6,0,0,0,0,12H34.1A2,2,0,0,1,36,31.6L63.7,183.8A26,26,0,1,0,106,204a25.6,25.6,0,0,0-4.1-14h60.2a25.6,25.6,0,0,0-4.1,14,26,26,0,1,0,26-26H74.8l-5.1-28H188.1A22,22,0,0,0,209.7,131.9ZM94,204a14,14,0,1,1-14-14A14,14,0,0,1,94,204Zm104,0a14,14,0,1,1-14-14A14,14,0,0,1,198,204ZM55.2,70H208.8l-10.9,59.8a10,10,0,0,1-9.8,8.2H67.6Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M207.8,131.6l12.1-66.9a4.2,4.2,0,0,0-.8-3.3A4.1,4.1,0,0,0,216,60H51.3L45.9,29.9A12,12,0,0,0,34.1,20H16a4,4,0,0,0,0,8H34.1A4,4,0,0,1,38,31.3L65.9,184.6a24,24,0,1,0,32,3.4h68.2a24,24,0,1,0,17.9-8H73.2l-5.9-32H188.1A20,20,0,0,0,207.8,131.6ZM96,204a16,16,0,1,1-16-16A16,16,0,0,1,96,204Zm104,0a16,16,0,1,1-16-16A16,16,0,0,1,200,204ZM52.8,68H211.2l-11.3,62.1a11.9,11.9,0,0,1-11.8,9.9H65.9Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M211.7,132.3l12.2-66.9a7.7,7.7,0,0,0-1.8-6.5A7.9,7.9,0,0,0,216,56H54.7L49.8,29.1A16,16,0,0,0,34.1,16H16a8,8,0,0,0,0,16H34.1L61.5,183a28,28,0,1,0,43.8,9h53.4A28,28,0,1,0,184,176H76.5l-4.4-24h116A24,24,0,0,0,211.7,132.3ZM92,204a12,12,0,1,1-12-12A12,12,0,0,1,92,204Zm104,0a12,12,0,1,1-12-12A12,12,0,0,1,196,204ZM57.6,72H206.4L196,129.4a8,8,0,0,1-7.9,6.6H69.2Z" />
        </>
      )}
    </svg>
  );
}

export function PhShower(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M76,228a16,16,0,1,1-16-16A16,16,0,0,1,76,228Zm16-48a16,16,0,1,0,16,16A16,16,0,0,0,92,180Zm-64,0a16,16,0,1,0,16,16A16,16,0,0,0,28,180Zm32,0a16,16,0,1,0-16-16A16,16,0,0,0,60,180ZM256,40a12,12,0,0,1-12,12H221L195.4,77.5,181.8,193a20,20,0,0,1-13.3,16.6,21.2,21.2,0,0,1-6.6,1.1,20.2,20.2,0,0,1-14.1-5.9L51.2,108.2A20,20,0,0,1,63,74.2L178.5,60.6l26.7-26.7A19.7,19.7,0,0,1,219.3,28H244A12,12,0,0,1,256,40ZM170.3,85.7,74,97l85,85Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M184,72,162.6,200.7A8,8,0,0,1,149,205L51,107a8,8,0,0,1,4.3-13.6Z"
            opacity="0.2"
          />
          <path d="M72,228a12,12,0,1,1-12-12A12,12,0,0,1,72,228Zm16-40a12,12,0,1,0,12,12A12,12,0,0,0,88,188Zm-60-4a12,12,0,1,0,12,12A12,12,0,0,0,28,184Zm40-16a12,12,0,1,0-12,12A12,12,0,0,0,68,168ZM256,40a8,8,0,0,1-8,8H219.3L191.5,75.8,170.4,202a15.7,15.7,0,0,1-10.9,12.6,16.2,16.2,0,0,1-4.9.8,16,16,0,0,1-11.2-4.7L45.3,112.6a16,16,0,0,1-3.9-16.1A15.7,15.7,0,0,1,54,85.6L180.2,64.5,208,36.7A15.9,15.9,0,0,1,219.3,32H248A8,8,0,0,1,256,40ZM174.3,81.7,56.6,101.3l98.1,98.1Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M56,180a12,12,0,1,1,12-12A12,12,0,0,1,56,180Zm4,36a12,12,0,1,0,12,12A12,12,0,0,0,60,216ZM28,184a12,12,0,1,0,12,12A12,12,0,0,0,28,184Zm60,4a12,12,0,1,0,12,12A12,12,0,0,0,88,188ZM248,32H219.3A15.9,15.9,0,0,0,208,36.7L180.2,64.5,54,85.6A15.7,15.7,0,0,0,41.4,96.5a16,16,0,0,0,3.9,16.1l98.1,98.1a16,16,0,0,0,11.2,4.7,16.2,16.2,0,0,0,4.9-.8A15.7,15.7,0,0,0,170.4,202L191.5,75.8h0L219.3,48H248a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M70,228a10,10,0,1,1-10-10A10,10,0,0,1,70,228Zm18-38a10,10,0,1,0,10,10A10,10,0,0,0,88,190Zm-60-4a10,10,0,1,0,10,10A10,10,0,0,0,28,186Zm38-18a10,10,0,1,0-10,10A10,10,0,0,0,66,168ZM254,40a6,6,0,0,1-6,6H219.3a2,2,0,0,0-1.4.6L189.6,74.9,168.5,201.7a14,14,0,0,1-9.6,11,15.5,15.5,0,0,1-4.3.7,13.7,13.7,0,0,1-9.8-4.2l-98.1-98a13.9,13.9,0,0,1-3.4-14.1,14,14,0,0,1,11-9.6L181.1,66.4l28.3-28.3a14.3,14.3,0,0,1,9.9-4.1H248A6,6,0,0,1,254,40ZM176.7,79.3,56.3,99.4a2,2,0,0,0-1.6,1.3,2.3,2.3,0,0,0,.5,2.1l98.1,98a2,2,0,0,0,2,.5,2,2,0,0,0,1.3-1.6Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M68,228a8,8,0,1,1-8-8A8,8,0,0,1,68,228Zm20-36a8,8,0,1,0,8,8A8,8,0,0,0,88,192Zm-60-4a8,8,0,1,0,8,8A8,8,0,0,0,28,188Zm36-20a8,8,0,1,0-8,8A8,8,0,0,0,64,168ZM252,40a4,4,0,0,1-4,4H219.3a3.6,3.6,0,0,0-2.8,1.2L187.7,73.9,166.5,201.3a11.9,11.9,0,0,1-8.2,9.5,11.5,11.5,0,0,1-3.7.6,11.9,11.9,0,0,1-8.4-3.6l-98-98a11.9,11.9,0,0,1,6.5-20.3L182.1,68.3l28.7-28.8a11.9,11.9,0,0,1,8.5-3.5H248A4,4,0,0,1,252,40ZM179.1,76.9,56,97.4a4,4,0,0,0-3.2,2.7,4.1,4.1,0,0,0,1,4.1l98,98a4.1,4.1,0,0,0,4.1,1,4,4,0,0,0,2.7-3.2Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M72,228a12,12,0,1,1-12-12A12,12,0,0,1,72,228Zm16-40a12,12,0,1,0,12,12A12,12,0,0,0,88,188Zm-60-4a12,12,0,1,0,12,12A12,12,0,0,0,28,184Zm40-16a12,12,0,1,0-12,12A12,12,0,0,0,68,168ZM256,40a8,8,0,0,1-8,8H219.3L191.5,75.8,170.4,202a15.7,15.7,0,0,1-10.9,12.6,16.2,16.2,0,0,1-4.9.8,16,16,0,0,1-11.2-4.7L45.3,112.6a16,16,0,0,1-3.9-16.1A15.7,15.7,0,0,1,54,85.6L180.2,64.5,208,36.7A15.9,15.9,0,0,1,219.3,32H248A8,8,0,0,1,256,40ZM174.3,81.7,56.6,101.3l98.1,98.1Z" />
        </>
      )}
    </svg>
  );
}

export function PhShuffleAngular(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M240.5,175.5a12,12,0,0,1,0,17l-24,24a12.1,12.1,0,0,1-17,0,12,12,0,0,1,0-17L203,196H172.1a20.1,20.1,0,0,1-16.3-8.4L81.8,84H32a12,12,0,0,1,0-24H83.9a20.1,20.1,0,0,1,16.3,8.4l74,103.6H203l-3.5-3.5a12,12,0,0,1,17-17ZM110.4,152.6a12,12,0,0,0-16.7,2.8L81.8,172H32a12,12,0,0,0,0,24H83.9a20.1,20.1,0,0,0,16.3-8.4l13-18.2A12.1,12.1,0,0,0,110.4,152.6Zm35.2-49.2a12,12,0,0,0,16.7-2.8L174.2,84H203l-3.5,3.5a12,12,0,0,0,0,17,12.1,12.1,0,0,0,17,0l24-24a12,12,0,0,0,0-17l-24-24a12,12,0,0,0-17,17L203,60H172.1a20.1,20.1,0,0,0-16.3,8.4l-13,18.2A12.1,12.1,0,0,0,145.6,103.4Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M237.7,178.3a8.1,8.1,0,0,1,0,11.4l-24,24a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L212.7,192H172.1a16.1,16.1,0,0,1-13-6.7L83.9,80H32a8,8,0,0,1,0-16H83.9a16.1,16.1,0,0,1,13,6.7L172.1,176h40.6l-10.4-10.3a8.1,8.1,0,0,1,11.4-11.4ZM143,107a8,8,0,0,0,11.2-1.9L172.1,80h40.6L202.3,90.3a8.1,8.1,0,0,0,0,11.4,8.2,8.2,0,0,0,11.4,0l24-24a8.1,8.1,0,0,0,0-11.4l-24-24a8.1,8.1,0,0,0-11.4,11.4L212.7,64H172.1a16.1,16.1,0,0,0-13,6.7L141.2,95.8A8,8,0,0,0,143,107Zm-30,42a8,8,0,0,0-11.2,1.9L83.9,176H32a8,8,0,0,0,0,16H83.9a16.1,16.1,0,0,0,13-6.7l17.9-25.1A8,8,0,0,0,113,149Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M114.8,160.2,96.9,185.3a16.1,16.1,0,0,1-13,6.7H32a8,8,0,0,1,0-16H83.9l17.9-25.1a8,8,0,1,1,13,9.3ZM143,107a8,8,0,0,0,11.2-1.9L172.1,80H200V96a8,8,0,0,0,4.9,7.4,8.5,8.5,0,0,0,3.1.6,8.3,8.3,0,0,0,5.7-2.3l24-24a8.1,8.1,0,0,0,0-11.4l-24-24a8.4,8.4,0,0,0-8.8-1.7A8,8,0,0,0,200,48V64H172.1a16.1,16.1,0,0,0-13,6.7L141.2,95.8A8,8,0,0,0,143,107Zm70.7,47.3a8.4,8.4,0,0,0-8.8-1.7A8,8,0,0,0,200,160v16H172.1L96.9,70.7a16.1,16.1,0,0,0-13-6.7H32a8,8,0,0,0,0,16H83.9l75.2,105.3a16.1,16.1,0,0,0,13,6.7H200v16a8,8,0,0,0,4.9,7.4,8.5,8.5,0,0,0,3.1.6,8.3,8.3,0,0,0,5.7-2.3l24-24a8.1,8.1,0,0,0,0-11.4Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M236.2,179.8a5.8,5.8,0,0,1,0,8.4l-24,24a5.9,5.9,0,0,1-8.4-8.4L217.5,190H172.1a14,14,0,0,1-11.4-5.9L85.5,78.8a2.1,2.1,0,0,0-1.6-.8H32a6,6,0,0,1,0-12H83.9a14,14,0,0,1,11.4,5.9l75.2,105.3a2.1,2.1,0,0,0,1.6.8h45.4l-13.7-13.8a5.9,5.9,0,0,1,8.4-8.4Zm-92-74.4a6.3,6.3,0,0,0,3.5,1.1,5.9,5.9,0,0,0,4.8-2.5l18-25.2a2.1,2.1,0,0,1,1.6-.8h45.4L203.8,91.8a5.9,5.9,0,1,0,8.4,8.4l24-24a5.8,5.8,0,0,0,0-8.4l-24-24a5.9,5.9,0,0,0-8.4,8.4L217.5,66H172.1a14,14,0,0,0-11.4,5.9L142.8,97A6.1,6.1,0,0,0,144.2,105.4Zm-32.4,45.2a5.9,5.9,0,0,0-8.3,1.4l-18,25.2a2.1,2.1,0,0,1-1.6.8H32a6,6,0,0,0,0,12H83.9a14,14,0,0,0,11.4-5.9L113.2,159A6.1,6.1,0,0,0,111.8,150.6Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M234.8,181.2a3.9,3.9,0,0,1,0,5.6l-24,24a3.9,3.9,0,0,1-5.6,0,3.9,3.9,0,0,1,0-5.6L222.3,188H172.1a11.9,11.9,0,0,1-9.7-5L87.1,77.7A3.7,3.7,0,0,0,83.9,76H32a4,4,0,0,1,0-8H83.9a12,12,0,0,1,9.7,5l75.3,105.3a3.7,3.7,0,0,0,3.2,1.7h50.2l-17.1-17.2a4,4,0,0,1,5.6-5.6Zm-89.5-77.5a4.3,4.3,0,0,0,2.4.8,4,4,0,0,0,3.2-1.7l18-25.1a3.7,3.7,0,0,1,3.2-1.7h50.2L205.2,93.2a3.9,3.9,0,0,0,0,5.6,3.9,3.9,0,0,0,5.6,0l24-24a3.9,3.9,0,0,0,0-5.6l-24-24a4,4,0,0,0-5.6,5.6L222.3,68H172.1a11.9,11.9,0,0,0-9.7,5l-18,25.1A4,4,0,0,0,145.3,103.7Zm-34.6,48.6a4,4,0,0,0-5.6.9l-18,25.1a3.7,3.7,0,0,1-3.2,1.7H32a4,4,0,0,0,0,8H83.9a12,12,0,0,0,9.7-5l18-25.1A4,4,0,0,0,110.7,152.3Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M237.7,178.3a8.1,8.1,0,0,1,0,11.4l-24,24a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L212.7,192H172.1a16.1,16.1,0,0,1-13-6.7L83.9,80H32a8,8,0,0,1,0-16H83.9a16.1,16.1,0,0,1,13,6.7L172.1,176h40.6l-10.4-10.3a8.1,8.1,0,0,1,11.4-11.4ZM143,107a8,8,0,0,0,11.2-1.9L172.1,80h40.6L202.3,90.3a8.1,8.1,0,0,0,0,11.4,8.2,8.2,0,0,0,11.4,0l24-24a8.1,8.1,0,0,0,0-11.4l-24-24a8.1,8.1,0,0,0-11.4,11.4L212.7,64H172.1a16.1,16.1,0,0,0-13,6.7L141.2,95.8A8,8,0,0,0,143,107Zm-30,42a8,8,0,0,0-11.2,1.9L83.9,176H32a8,8,0,0,0,0,16H83.9a16.1,16.1,0,0,0,13-6.7l17.9-25.1A8,8,0,0,0,113,149Z" />
        </>
      )}
    </svg>
  );
}

export function PhShuffleSimple(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M217.3,215.6l-.8.8h-.1l-.8.8-.4.3-.5.4-.6.3-.4.3-.6.2-.5.3-.6.2-.5.2h-.6l-.6.2H168a12,12,0,0,1,0-24h11L39.5,56.5a12,12,0,0,1,17-17L196,179V168a12,12,0,0,1,24,0v40a4.9,4.9,0,0,1-.1,1.2v.5a1.3,1.3,0,0,0-.1.6c-.1.2-.1.4-.2.6s-.1.4-.1.6-.1.4-.2.5-.1.4-.2.6-.2.3-.2.5l-.3.6-.3.4-.3.6-.4.5Zm-61-103.9a12.2,12.2,0,0,0,8.5-3.5L196,77V88a12,12,0,0,0,24,0V48a4.9,4.9,0,0,0-.1-1.2v-.5a1.3,1.3,0,0,1-.1-.6c-.1-.2-.1-.4-.2-.6s-.1-.4-.1-.6-.1-.4-.2-.5-.1-.4-.2-.6-.2-.3-.2-.5l-.3-.6-.3-.4-.3-.6-.4-.5-.3-.4a6,6,0,0,0-.8-.8h-.1l-.8-.8-.4-.3-.5-.4-.6-.3-.4-.3-.6-.3-.5-.2-.6-.2-.5-.2h-.6l-.6-.2H168a12,12,0,0,0,0,24h11L147.8,91.2a12,12,0,0,0,8.5,20.5ZM91.2,147.8,39.5,199.5a12,12,0,0,0,0,17,12.1,12.1,0,0,0,17,0l51.7-51.7a12,12,0,0,0-17-17Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M214.2,213.1l-.5.5h-.1l-.5.5-.3.2-.4.3-.3.2-.3.2h-.4l-.3.2h-.4l-.4.2H168a8,8,0,0,1,0-16h20.7L42.3,53.7A8.1,8.1,0,0,1,53.7,42.3L200,188.7V168a8,8,0,0,1,16,0v40.8a.4.4,0,0,0-.1.3.9.9,0,0,1-.1.5v.3a.8.8,0,0,0-.1.4l-.2.4c0,.1-.1.2-.1.4l-.2.3c0,.1-.1.2-.1.4l-.2.3-.2.3-.3.4Zm-63.6-99.7a8,8,0,0,0,5.7-2.4L200,67.3V88a8,8,0,0,0,16,0V47.2a.4.4,0,0,1-.1-.3.9.9,0,0,0-.1-.5v-.3a.8.8,0,0,1-.1-.4c-.1-.1-.1-.3-.2-.4s-.1-.2-.1-.4l-.2-.3c0-.1-.1-.2-.1-.4s-.2-.2-.2-.3-.2-.2-.2-.3l-.3-.4-.2-.3-.5-.5h-.1c-.2-.2-.4-.3-.5-.5l-.4-.2-.3-.2-.3-.3-.3-.2h-.4l-.3-.2h-.4l-.4-.2H168a8,8,0,0,0,0,16h20.7L145,99.7a7.9,7.9,0,0,0,0,11.3A7.7,7.7,0,0,0,150.6,113.4ZM99.7,145,42.3,202.3a8.1,8.1,0,0,0,0,11.4,8.2,8.2,0,0,0,11.4,0L111,156.3A8,8,0,0,0,99.7,145Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,48V88a8,8,0,0,1-4.9,7.4,8.5,8.5,0,0,1-3.1.6,8.3,8.3,0,0,1-5.7-2.3L188,79.3,156.3,111a8,8,0,0,1-5.7,2.4A7.7,7.7,0,0,1,145,111a7.9,7.9,0,0,1,0-11.3L176.7,68,162.3,53.7a8.4,8.4,0,0,1-1.7-8.8A8,8,0,0,1,168,40h40A8,8,0,0,1,216,48Zm-4.9,112.6a8.4,8.4,0,0,0-8.8,1.7L188,176.7,53.7,42.3A8.1,8.1,0,0,0,42.3,53.7L176.7,188l-14.4,14.3a8.4,8.4,0,0,0-1.7,8.8A8,8,0,0,0,168,216h40a8,8,0,0,0,8-8V168A8,8,0,0,0,211.1,160.6ZM99.7,145,42.3,202.3a8.1,8.1,0,0,0,0,11.4,8.2,8.2,0,0,0,11.4,0L111,156.3A8,8,0,0,0,99.7,145Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M214,48V88a6,6,0,0,1-12,0V62.5l-47.1,47.1a5.9,5.9,0,0,1-8.5,0,5.9,5.9,0,0,1,0-8.5L193.5,54H168a6,6,0,0,1,0-12h41.2l1,.3a.1.1,0,0,1,.1.1l1,.5h0a4.6,4.6,0,0,1,1.7,1.7h0a9.3,9.3,0,0,1,.5,1h.1l.3,1h0A4.1,4.1,0,0,1,214,48Zm-6,114a6,6,0,0,0-6,6v25.5L52.2,43.8a5.9,5.9,0,0,0-8.4,8.4L193.5,202H168a6,6,0,0,0,0,12h41.2l1-.3h.1l1-.5h0a4.6,4.6,0,0,0,1.7-1.7h0l.5-1a.1.1,0,0,0,.1-.1l.3-1h0a4.1,4.1,0,0,0,.1-1.1V168A6,6,0,0,0,208,162ZM101.1,146.4,43.8,203.8a5.9,5.9,0,1,0,8.4,8.4l57.4-57.3a6,6,0,0,0-8.5-8.5Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M212,168v40a2.2,2.2,0,0,1-.1.8.4.4,0,0,1-.1.3c0,.2-.1.3-.1.4s-.1.3-.2.4l-.2.3a2.9,2.9,0,0,1-1.1,1.1l-.3.2-.4.2H168a4,4,0,0,1,0-8h30.3L45.2,50.8a4,4,0,0,1,5.6-5.6L204,198.3V168a4,4,0,0,1,8,0Zm-.1-120.8a.4.4,0,0,0-.1-.3c0-.2-.1-.3-.1-.4l-.2-.4-.2-.3a2.9,2.9,0,0,0-1.1-1.1l-.3-.2-.4-.2H168a4,4,0,0,0,0,8h30.3l-50.5,50.5a4,4,0,1,0,5.7,5.7L204,57.7V88a4,4,0,0,0,8,0V48A2.2,2.2,0,0,0,211.9,47.2ZM102.5,147.8,45.2,205.2a3.9,3.9,0,0,0,0,5.6,3.9,3.9,0,0,0,5.6,0l57.4-57.3a4,4,0,0,0-5.7-5.7Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M214.2,213.1l-.5.5h-.1l-.5.5-.3.2-.4.3-.3.2-.3.2h-.4l-.3.2h-.4l-.4.2H168a8,8,0,0,1,0-16h20.7L42.3,53.7A8.1,8.1,0,0,1,53.7,42.3L200,188.7V168a8,8,0,0,1,16,0v40.8a.4.4,0,0,0-.1.3.9.9,0,0,1-.1.5v.3a.8.8,0,0,0-.1.4l-.2.4c0,.1-.1.2-.1.4l-.2.3c0,.1-.1.2-.1.4l-.2.3-.2.3-.3.4Zm-63.6-99.7a8,8,0,0,0,5.7-2.4L200,67.3V88a8,8,0,0,0,16,0V47.2a.4.4,0,0,1-.1-.3.9.9,0,0,0-.1-.5v-.3a.8.8,0,0,1-.1-.4c-.1-.1-.1-.3-.2-.4s-.1-.2-.1-.4l-.2-.3c0-.1-.1-.2-.1-.4s-.2-.2-.2-.3-.2-.2-.2-.3l-.3-.4-.2-.3-.5-.5h-.1c-.2-.2-.4-.3-.5-.5l-.3-.2-.4-.3-.3-.2-.3-.2h-.4l-.3-.2h-.4l-.4-.2H168a8,8,0,0,0,0,16h20.7L145,99.7a7.9,7.9,0,0,0,0,11.3A7.7,7.7,0,0,0,150.6,113.4ZM99.7,145,42.3,202.3a8.1,8.1,0,0,0,0,11.4,8.2,8.2,0,0,0,11.4,0L111,156.3A8,8,0,0,0,99.7,145Z" />
        </>
      )}
    </svg>
  );
}

export function PhShuffle(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M240.5,175.5a12,12,0,0,1,0,17l-24,24a12.1,12.1,0,0,1-17,0,12,12,0,0,1,0-17L203,196h-2.1a76.1,76.1,0,0,1-61.8-31.8L97.4,105.8A52.1,52.1,0,0,0,55.1,84H32a12,12,0,0,1,0-24H55.1a76.1,76.1,0,0,1,61.8,31.8l41.7,58.4A52.1,52.1,0,0,0,200.9,172H203l-3.5-3.5a12,12,0,0,1,17-17Zm-95.6-72.6a11.8,11.8,0,0,0,7.9,3,12,12,0,0,0,9-4.1A51.9,51.9,0,0,1,200.9,84H203l-3.5,3.5a12,12,0,0,0,0,17,12.1,12.1,0,0,0,17,0l24-24a12,12,0,0,0,0-17l-24-24a12,12,0,0,0-17,17L203,60h-2.1a76.2,76.2,0,0,0-57.2,26A12,12,0,0,0,144.9,102.9Zm-33.8,50.2a12,12,0,0,0-16.9,1.1A51.9,51.9,0,0,1,55.1,172H32a12,12,0,0,0,0,24H55.1a76.2,76.2,0,0,0,57.2-26A12,12,0,0,0,111.1,153.1Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M237.7,178.3a8.1,8.1,0,0,1,0,11.4l-24,24a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L212.7,192H200.9a72.2,72.2,0,0,1-58.6-30.2l-41.7-58.3A56.1,56.1,0,0,0,55.1,80H32a8,8,0,0,1,0-16H55.1a72.2,72.2,0,0,1,58.6,30.2l41.7,58.3A56.1,56.1,0,0,0,200.9,176h11.8l-10.4-10.3a8.1,8.1,0,0,1,11.4-11.4ZM143,107a8,8,0,0,0,11.2-1.9l1.2-1.6A56.1,56.1,0,0,1,200.9,80h11.8L202.3,90.3a8.1,8.1,0,0,0,0,11.4,8.2,8.2,0,0,0,11.4,0l24-24a8.1,8.1,0,0,0,0-11.4l-24-24a8.1,8.1,0,0,0-11.4,11.4L212.7,64H200.9a72.2,72.2,0,0,0-58.6,30.2l-1.1,1.6A8,8,0,0,0,143,107Zm-30,42a8,8,0,0,0-11.2,1.9l-1.2,1.6A56.1,56.1,0,0,1,55.1,176H32a8,8,0,0,0,0,16H55.1a72.2,72.2,0,0,0,58.6-30.2l1.1-1.6A8,8,0,0,0,113,149Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M114.8,160.2l-1.1,1.6A72.2,72.2,0,0,1,55.1,192H32a8,8,0,0,1,0-16H55.1a56.1,56.1,0,0,0,45.5-23.5l1.2-1.6a8,8,0,1,1,13,9.3ZM143,107a8,8,0,0,0,11.2-1.9l1.2-1.6A55.9,55.9,0,0,1,200,80V96a8,8,0,0,0,4.9,7.4,8.5,8.5,0,0,0,3.1.6,8.3,8.3,0,0,0,5.7-2.3l24-24a8.1,8.1,0,0,0,0-11.4l-24-24a8.4,8.4,0,0,0-8.8-1.7A8,8,0,0,0,200,48V64a72.2,72.2,0,0,0-57.7,30.2l-1.1,1.6A8,8,0,0,0,143,107Zm70.7,47.3a8.4,8.4,0,0,0-8.8-1.7A8,8,0,0,0,200,160v16a55.9,55.9,0,0,1-44.6-23.5L113.7,94.2A72.2,72.2,0,0,0,55.1,64H32a8,8,0,0,0,0,16H55.1a56.1,56.1,0,0,1,45.5,23.5l41.7,58.3A72.2,72.2,0,0,0,200,192v16a8,8,0,0,0,4.9,7.4,8.5,8.5,0,0,0,3.1.6,8.3,8.3,0,0,0,5.7-2.3l24-24a8.1,8.1,0,0,0,0-11.4Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M236.2,179.8a5.8,5.8,0,0,1,0,8.4l-24,24a5.9,5.9,0,0,1-8.4-8.4L217.5,190H200.9A70.2,70.2,0,0,1,144,160.7l-41.7-58.4A58.2,58.2,0,0,0,55.1,78H32a6,6,0,0,1,0-12H55.1A70.2,70.2,0,0,1,112,95.3l41.7,58.4A58.2,58.2,0,0,0,200.9,178h16.6l-13.7-13.8a5.9,5.9,0,0,1,8.4-8.4Zm-92-74.4a6.3,6.3,0,0,0,3.5,1.1,5.9,5.9,0,0,0,4.8-2.5l1.2-1.7A58.2,58.2,0,0,1,200.9,78h16.6L203.8,91.8a5.9,5.9,0,1,0,8.4,8.4l24-24a5.8,5.8,0,0,0,0-8.4l-24-24a5.9,5.9,0,0,0-8.4,8.4L217.5,66H200.9A70.2,70.2,0,0,0,144,95.3L142.8,97A6.1,6.1,0,0,0,144.2,105.4Zm-32.4,45.2a5.9,5.9,0,0,0-8.3,1.4l-1.2,1.7A58.2,58.2,0,0,1,55.1,178H32a6,6,0,0,0,0,12H55.1A70.2,70.2,0,0,0,112,160.7l1.2-1.7A6.1,6.1,0,0,0,111.8,150.6Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M234.8,181.2a3.9,3.9,0,0,1,0,5.6l-24,24a3.9,3.9,0,0,1-5.6,0,3.9,3.9,0,0,1,0-5.6L222.3,188H200.9a67.9,67.9,0,0,1-55.3-28.5l-41.7-58.4A60.1,60.1,0,0,0,55.1,76H32a4,4,0,0,1,0-8H55.1a67.9,67.9,0,0,1,55.3,28.5l41.7,58.4A60.1,60.1,0,0,0,200.9,180h21.4l-17.1-17.2a4,4,0,0,1,5.6-5.6Zm-89.5-77.5a4.3,4.3,0,0,0,2.4.8,4,4,0,0,0,3.2-1.7l1.2-1.7A60.1,60.1,0,0,1,200.9,76h21.4L205.2,93.2a3.9,3.9,0,0,0,0,5.6,3.9,3.9,0,0,0,5.6,0l24-24a3.9,3.9,0,0,0,0-5.6l-24-24a4,4,0,0,0-5.6,5.6L222.3,68H200.9a67.9,67.9,0,0,0-55.3,28.5l-1.2,1.6A4,4,0,0,0,145.3,103.7Zm-34.6,48.6a4,4,0,0,0-5.6.9l-1.2,1.7A60.1,60.1,0,0,1,55.1,180H32a4,4,0,0,0,0,8H55.1a67.9,67.9,0,0,0,55.3-28.5l1.2-1.6A4,4,0,0,0,110.7,152.3Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M237.7,178.3a8.1,8.1,0,0,1,0,11.4l-24,24a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L212.7,192H200.9a72.2,72.2,0,0,1-58.6-30.2l-41.7-58.3A56.1,56.1,0,0,0,55.1,80H32a8,8,0,0,1,0-16H55.1a72.2,72.2,0,0,1,58.6,30.2l41.7,58.3A56.1,56.1,0,0,0,200.9,176h11.8l-10.4-10.3a8.1,8.1,0,0,1,11.4-11.4ZM143,107a8,8,0,0,0,11.2-1.9l1.2-1.6A56.1,56.1,0,0,1,200.9,80h11.8L202.3,90.3a8.1,8.1,0,0,0,0,11.4,8.2,8.2,0,0,0,11.4,0l24-24a8.1,8.1,0,0,0,0-11.4l-24-24a8.1,8.1,0,0,0-11.4,11.4L212.7,64H200.9a72.2,72.2,0,0,0-58.6,30.2l-1.1,1.6A8,8,0,0,0,143,107Zm-30,42a8,8,0,0,0-11.2,1.9l-1.2,1.6A56.1,56.1,0,0,1,55.1,176H32a8,8,0,0,0,0,16H55.1a72.2,72.2,0,0,0,58.6-30.2l1.1-1.6A8,8,0,0,0,113,149Z" />
        </>
      )}
    </svg>
  );
}

export function PhSidebarSimple(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216,36H40A20.1,20.1,0,0,0,20,56V200a20.1,20.1,0,0,0,20,20H216a20.1,20.1,0,0,0,20-20V56A20.1,20.1,0,0,0,216,36ZM44,60H76V196H44ZM212,196H100V60H212Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M88,48V208H40a8,8,0,0,1-8-8V56a8,8,0,0,1,8-8Z"
            opacity="0.2"
          />
          <path d="M216,40H40A16,16,0,0,0,24,56V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40ZM40,56H80V200H40ZM216,200H96V56H216V200Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,40H40A16,16,0,0,0,24,56V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40Zm0,160H88V56H216V200Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M216,42H40A14,14,0,0,0,26,56V200a14,14,0,0,0,14,14H216a14,14,0,0,0,14-14V56A14,14,0,0,0,216,42ZM38,200V56a2,2,0,0,1,2-2H82V202H40A2,2,0,0,1,38,200Zm180,0a2,2,0,0,1-2,2H94V54H216a2,2,0,0,1,2,2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M216,44H40A12,12,0,0,0,28,56V200a12,12,0,0,0,12,12H216a12,12,0,0,0,12-12V56A12,12,0,0,0,216,44ZM36,200V56a4,4,0,0,1,4-4H84V204H40A4,4,0,0,1,36,200Zm184,0a4,4,0,0,1-4,4H92V52H216a4,4,0,0,1,4,4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,40H40A16,16,0,0,0,24,56V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40ZM40,56H80V200H40ZM216,200H96V56H216V200Z" />
        </>
      )}
    </svg>
  );
}

export function PhSidebar(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216,36H40A20.1,20.1,0,0,0,20,56V200a20.1,20.1,0,0,0,20,20H216a20.1,20.1,0,0,0,20-20V56A20.1,20.1,0,0,0,216,36ZM44,100H76v16H44ZM76,60V76H44V60ZM44,140H76v56H44Zm168,56H100V60H212Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M88,48V208H40a8,8,0,0,1-8-8V56a8,8,0,0,1,8-8Z"
            opacity="0.2"
          />
          <path d="M216,40H40A16,16,0,0,0,24,56V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40ZM40,152H56a8,8,0,0,0,0-16H40V120H56a8,8,0,0,0,0-16H40V88H56a8,8,0,0,0,0-16H40V56H80V200H40Zm176,48H96V56H216V200Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,40H40A16,16,0,0,0,24,56V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40ZM64,152H48a8,8,0,0,1,0-16H64a8,8,0,0,1,0,16Zm0-32H48a8,8,0,0,1,0-16H64a8,8,0,0,1,0,16Zm0-32H48a8,8,0,0,1,0-16H64a8,8,0,0,1,0,16ZM216,200H88V56H216V200Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M216,42H40A14,14,0,0,0,26,56V200a14,14,0,0,0,14,14H216a14,14,0,0,0,14-14V56A14,14,0,0,0,216,42ZM38,200V150H56a6,6,0,0,0,0-12H38V118H56a6,6,0,0,0,0-12H38V86H56a6,6,0,0,0,0-12H38V56a2,2,0,0,1,2-2H82V202H40A2,2,0,0,1,38,200Zm180,0a2,2,0,0,1-2,2H94V54H216a2,2,0,0,1,2,2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M216,44H40A12,12,0,0,0,28,56V200a12,12,0,0,0,12,12H216a12,12,0,0,0,12-12V56A12,12,0,0,0,216,44ZM36,200V148H56a4,4,0,0,0,0-8H36V116H56a4,4,0,0,0,0-8H36V84H56a4,4,0,0,0,0-8H36V56a4,4,0,0,1,4-4H84V204H40A4,4,0,0,1,36,200Zm184,0a4,4,0,0,1-4,4H92V52H216a4,4,0,0,1,4,4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,40H40A16,16,0,0,0,24,56V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40ZM40,152H56a8,8,0,0,0,0-16H40V120H56a8,8,0,0,0,0-16H40V88H56a8,8,0,0,0,0-16H40V56H80V200H40Zm176,48H96V56H216V200Z" />
        </>
      )}
    </svg>
  );
}

export function PhSignIn(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M144.5,136.5l-42,42A12,12,0,0,1,94,182a12.2,12.2,0,0,1-8.5-3.5,12,12,0,0,1,0-17L107,140H24a12,12,0,0,1,0-24h83L85.5,94.5a12,12,0,0,1,17-17l42,42A12,12,0,0,1,144.5,136.5ZM192,28H136a12,12,0,0,0,0,24h52V204H136a12,12,0,0,0,0,24h56a20.1,20.1,0,0,0,20-20V48A20.1,20.1,0,0,0,192,28Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M141.7,133.7l-42,42A8.3,8.3,0,0,1,94,178a8,8,0,0,1-5.6-13.7L116.7,136H24a8,8,0,0,1,0-16h92.7L88.4,91.7A8,8,0,0,1,99.7,80.3l42,42A8.1,8.1,0,0,1,141.7,133.7ZM192,32H136a8,8,0,0,0,0,16h56V208H136a8,8,0,0,0,0,16h56a16,16,0,0,0,16-16V48A16,16,0,0,0,192,32Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M141.7,133.7l-42,42A8.3,8.3,0,0,1,94,178a7.7,7.7,0,0,1-3-.6,8,8,0,0,1-5-7.4V136H24a8,8,0,0,1,0-16H86V86a8,8,0,0,1,5-7.4,8.2,8.2,0,0,1,8.7,1.7l42,42A8.1,8.1,0,0,1,141.7,133.7ZM192,32H136a8,8,0,0,0,0,16h56V208H136a8,8,0,0,0,0,16h56a16,16,0,0,0,16-16V48A16,16,0,0,0,192,32Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M140.2,132.2l-41.9,42A6.1,6.1,0,0,1,94,176a6,6,0,0,1-4.2-10.2L121.5,134H24a6,6,0,0,1,0-12h97.5L89.8,90.2a5.8,5.8,0,0,1,0-8.4,5.9,5.9,0,0,1,8.5,0l41.9,42,.3.3.2.2a3.5,3.5,0,0,1,.5.8h.1a6.1,6.1,0,0,1,0,5.6h-.1a3.5,3.5,0,0,1-.5.8l-.2.2ZM192,34H136a6,6,0,0,0,0,12h56a2,2,0,0,1,2,2V208a2,2,0,0,1-2,2H136a6,6,0,0,0,0,12h56a14,14,0,0,0,14-14V48A14,14,0,0,0,192,34Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M138.8,130.8l-42,42A3.8,3.8,0,0,1,94,174a3.9,3.9,0,0,1-2.8-1.2,3.9,3.9,0,0,1,0-5.6L126.3,132H24a4,4,0,0,1,0-8H126.3L91.2,88.8a4,4,0,0,1,5.6-5.6l42,42A3.9,3.9,0,0,1,138.8,130.8ZM192,36H136a4,4,0,0,0,0,8h56a4,4,0,0,1,4,4V208a4,4,0,0,1-4,4H136a4,4,0,0,0,0,8h56a12,12,0,0,0,12-12V48A12,12,0,0,0,192,36Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M141.7,133.7l-42,42A8.3,8.3,0,0,1,94,178a8,8,0,0,1-5.6-13.7L116.7,136H24a8,8,0,0,1,0-16h92.7L88.4,91.7A8,8,0,0,1,99.7,80.3l42,42A8.1,8.1,0,0,1,141.7,133.7ZM192,32H136a8,8,0,0,0,0,16h56V208H136a8,8,0,0,0,0,16h56a16,16,0,0,0,16-16V48A16,16,0,0,0,192,32Z" />
        </>
      )}
    </svg>
  );
}

export function PhSignOut(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M224.5,136.5l-42,42A12,12,0,0,1,174,182a12.2,12.2,0,0,1-8.5-3.5,12,12,0,0,1,0-17L187,140H104a12,12,0,0,1,0-24h83L165.5,94.5a12,12,0,0,1,17-17l42,42A12,12,0,0,1,224.5,136.5ZM104,204H52V52h52a12,12,0,0,0,0-24H48A20.1,20.1,0,0,0,28,48V208a20.1,20.1,0,0,0,20,20h56a12,12,0,0,0,0-24Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M221.7,133.7l-42,42A8.3,8.3,0,0,1,174,178a8,8,0,0,1-5.6-13.7L196.7,136H104a8,8,0,0,1,0-16h92.7L168.4,91.7a8,8,0,0,1,11.3-11.4l42,42A8.1,8.1,0,0,1,221.7,133.7ZM104,208H48V48h56a8,8,0,0,0,0-16H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16h56a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M221.7,133.7l-42,42A8.3,8.3,0,0,1,174,178a7.7,7.7,0,0,1-3-.6,8,8,0,0,1-5-7.4V136H104a8,8,0,0,1,0-16h62V86a8,8,0,0,1,5-7.4,8.2,8.2,0,0,1,8.7,1.7l42,42A8.1,8.1,0,0,1,221.7,133.7ZM104,208H48V48h56a8,8,0,0,0,0-16H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16h56a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M220.5,131.9l-.3.3-41.9,42A6.1,6.1,0,0,1,174,176a6,6,0,0,1-4.2-10.2L201.5,134H104a6,6,0,0,1,0-12h97.5L169.8,90.2a5.8,5.8,0,0,1,0-8.4,5.9,5.9,0,0,1,8.5,0l41.9,42,.3.3.2.2a3.5,3.5,0,0,1,.5.8h.1a6.1,6.1,0,0,1,0,5.6h-.1a3.5,3.5,0,0,1-.5.8ZM104,210H48a2,2,0,0,1-2-2V48a2,2,0,0,1,2-2h56a6,6,0,0,0,0-12H48A14,14,0,0,0,34,48V208a14,14,0,0,0,14,14h56a6,6,0,0,0,0-12Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M218.8,130.8l-42,42A3.8,3.8,0,0,1,174,174a3.9,3.9,0,0,1-2.8-1.2,3.9,3.9,0,0,1,0-5.6L206.3,132H104a4,4,0,0,1,0-8H206.3L171.2,88.8a4,4,0,0,1,5.6-5.6l42,42A3.9,3.9,0,0,1,218.8,130.8ZM104,212H48a4,4,0,0,1-4-4V48a4,4,0,0,1,4-4h56a4,4,0,0,0,0-8H48A12,12,0,0,0,36,48V208a12,12,0,0,0,12,12h56a4,4,0,0,0,0-8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M221.7,133.7l-42,42A8.3,8.3,0,0,1,174,178a8,8,0,0,1-5.6-13.7L196.7,136H104a8,8,0,0,1,0-16h92.7L168.4,91.7a8,8,0,0,1,11.3-11.4l42,42A8.1,8.1,0,0,1,221.7,133.7ZM104,208H48V48h56a8,8,0,0,0,0-16H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16h56a8,8,0,0,0,0-16Z" />
        </>
      )}
    </svg>
  );
}

export function PhSignpost(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M248.9,104l-36-40a11.8,11.8,0,0,0-8.9-4H140V32a12,12,0,0,0-24,0V60H40A20.1,20.1,0,0,0,20,80v64a20.1,20.1,0,0,0,20,20h76v60a12,12,0,0,0,24,0V164h64a11.8,11.8,0,0,0,8.9-4l36-40A11.9,11.9,0,0,0,248.9,104Zm-50.2,36H44V84H198.7l25.2,28Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M240,112l-36,40H40a8,8,0,0,1-8-8V80a8,8,0,0,1,8-8H204Z"
            opacity="0.2"
          />
          <path d="M245.9,106.6l-36-40A8.1,8.1,0,0,0,204,64H136V32a8,8,0,0,0-16,0V64H40A16,16,0,0,0,24,80v64a16,16,0,0,0,16,16h80v64a8,8,0,0,0,16,0V160h68a8.1,8.1,0,0,0,5.9-2.6l36-40A8,8,0,0,0,245.9,106.6ZM200.4,144H40V80H200.4l28.8,32Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M245.9,117.4l-36,40A8.1,8.1,0,0,1,204,160H136v64a8,8,0,0,1-16,0V160H40a16,16,0,0,1-16-16V80A16,16,0,0,1,40,64h80V32a8,8,0,0,1,16,0V64h68a8.1,8.1,0,0,1,5.9,2.6l36,40A8,8,0,0,1,245.9,117.4Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M244.5,108l-36-40a6.1,6.1,0,0,0-4.5-2H134V32a6,6,0,0,0-12,0V66H40A14,14,0,0,0,26,80v64a14,14,0,0,0,14,14h82v66a6,6,0,0,0,12,0V158h70a6.1,6.1,0,0,0,4.5-2l36-40A6.1,6.1,0,0,0,244.5,108Zm-43.2,38H40a2,2,0,0,1-2-2V80a2,2,0,0,1,2-2H201.3l30.6,34Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M243,109.3l-36-40a4.3,4.3,0,0,0-3-1.3H132V32a4,4,0,0,0-8,0V68H40A12,12,0,0,0,28,80v64a12,12,0,0,0,12,12h84v68a4,4,0,0,0,8,0V156h72a4.3,4.3,0,0,0,3-1.3l36-40A4.2,4.2,0,0,0,243,109.3ZM202.2,148H40a4,4,0,0,1-4-4V80a4,4,0,0,1,4-4H202.2l32.4,36Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M245.9,106.6l-36-40A8.1,8.1,0,0,0,204,64H136V32a8,8,0,0,0-16,0V64H40A16,16,0,0,0,24,80v64a16,16,0,0,0,16,16h80v64a8,8,0,0,0,16,0V160h68a8.1,8.1,0,0,0,5.9-2.6l36-40A8,8,0,0,0,245.9,106.6ZM200.4,144H40V80H200.4l28.8,32Z" />
        </>
      )}
    </svg>
  );
}

export function PhSimCard(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216.5,79.5l-56-56A12.3,12.3,0,0,0,152,20H56A20.1,20.1,0,0,0,36,40V216a20.1,20.1,0,0,0,20,20H200a20.1,20.1,0,0,0,20-20V88A12.3,12.3,0,0,0,216.5,79.5ZM196,212H60V44h87l49,49ZM76,124v64a12,12,0,0,0,12,12h80a12,12,0,0,0,12-12V124a12,12,0,0,0-12-12H88A12,12,0,0,0,76,124Zm24,12h56v40H140V164a12,12,0,0,0-24,0v12H100Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M176,120v72H80V120Z" opacity="0.2" />
          <path d="M213.7,82.3l-56-56A8.1,8.1,0,0,0,152,24H56A16,16,0,0,0,40,40V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V88A8.1,8.1,0,0,0,213.7,82.3ZM200,216H56V40h92.7L200,91.3V216ZM176,112H80a8,8,0,0,0-8,8v72a8,8,0,0,0,8,8h96a8,8,0,0,0,8-8V120A8,8,0,0,0,176,112Zm-8,72H152V152a8,8,0,0,0-16,0v32H120V152a8,8,0,0,0-16,0v32H88V128h80Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M213.7,82.3l-56-56A8.1,8.1,0,0,0,152,24H56A16,16,0,0,0,40,40V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V88A8.1,8.1,0,0,0,213.7,82.3ZM200,216H56V40h92.7L200,91.3V216ZM176,112H80a8,8,0,0,0-8,8v72a8,8,0,0,0,8,8h96a8,8,0,0,0,8-8V120A8,8,0,0,0,176,112Zm-56,64a8,8,0,0,1-16,0V136a8,8,0,0,1,16,0Zm32,0a8,8,0,0,1-16,0V136a8,8,0,0,1,16,0Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M212.2,83.8l-56-56A5.6,5.6,0,0,0,152,26H56A14,14,0,0,0,42,40V216a14,14,0,0,0,14,14H200a14,14,0,0,0,14-14V88A5.6,5.6,0,0,0,212.2,83.8ZM202,216a2,2,0,0,1-2,2H56a2,2,0,0,1-2-2V40a2,2,0,0,1,2-2h93.5L202,90.5ZM74,120v72a6,6,0,0,0,6,6h96a6,6,0,0,0,6-6V120a6,6,0,0,0-6-6H80A6,6,0,0,0,74,120Zm12,6h84v60H150V152a6,6,0,0,0-12,0v34H118V152a6,6,0,0,0-12,0v34H86Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M210.8,85.2l-56-56A3.6,3.6,0,0,0,152,28H56A12,12,0,0,0,44,40V216a12,12,0,0,0,12,12H200a12,12,0,0,0,12-12V88A3.6,3.6,0,0,0,210.8,85.2ZM204,216a4,4,0,0,1-4,4H56a4,4,0,0,1-4-4V40a4,4,0,0,1,4-4h94.3L204,89.7ZM76,120v72a4,4,0,0,0,4,4h96a4,4,0,0,0,4-4V120a4,4,0,0,0-4-4H80A4,4,0,0,0,76,120Zm8,4h88v64H148V152a4,4,0,0,0-8,0v36H116V152a4,4,0,0,0-8,0v36H84Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M213.7,82.3l-56-56A8.1,8.1,0,0,0,152,24H56A16,16,0,0,0,40,40V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V88A8.1,8.1,0,0,0,213.7,82.3ZM200,216H56V40h92.7L200,91.3V216ZM176,112H80a8,8,0,0,0-8,8v72a8,8,0,0,0,8,8h96a8,8,0,0,0,8-8V120A8,8,0,0,0,176,112Zm-8,72H152V152a8,8,0,0,0-16,0v32H120V152a8,8,0,0,0-16,0v32H88V128h80Z" />
        </>
      )}
    </svg>
  );
}

export function PhSketchLogo(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M249,96.1l-56-64a11.8,11.8,0,0,0-9-4.1H72a11.8,11.8,0,0,0-9,4.1L7,96.1a12,12,0,0,0,.2,16.1l112,120a12.1,12.1,0,0,0,17.6,0l112-120A12,12,0,0,0,249,96.1ZM213.6,92H183L152.3,52h26.3ZM70.9,116l21.2,52L43.6,116Zm88.3,0L128,192.3,96.8,116Zm-56-24L128,59.7,152.8,92Zm81.9,24h27.3l-48.5,52ZM77.4,52h26.3L73,92H42.4Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M240,104,128,224,78.9,104,128,40h56Z" opacity="0.2" />
          <path d="M246,98.7l-56-64a8,8,0,0,0-6-2.7H72a8,8,0,0,0-6,2.7l-56,64a8.1,8.1,0,0,0,.2,10.8l112,120a8,8,0,0,0,11.6,0l112-120A8.1,8.1,0,0,0,246,98.7ZM222.4,96H181L144.2,48h36.2ZM73.5,112l30.6,74.6L34.4,112Zm91.7,0L128,202.9,90.8,112ZM95.1,96,128,53.1,160.9,96Zm87.4,16h39.1l-69.7,74.6ZM75.6,48h36.2L75,96H33.6Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M246,98.7l-56-64a8,8,0,0,0-6-2.7H72a8,8,0,0,0-6,2.7l-56,64a8.1,8.1,0,0,0,.2,10.8l112,120a8,8,0,0,0,11.6,0l112-120A8.1,8.1,0,0,0,246,98.7ZM222.4,96H181L144.2,48h36.2ZM73.5,112l30.6,74.6L34.4,112Zm109,0h39.1l-69.7,74.6ZM75.6,48h36.2L75,96H33.6Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M244.5,100l-56-64a5.9,5.9,0,0,0-4.5-2H72a5.9,5.9,0,0,0-4.5,2l-56,64a6.1,6.1,0,0,0,.1,8.1l112,120a6,6,0,0,0,8.8,0l112-120A6.1,6.1,0,0,0,244.5,100ZM74.7,46h41.1L75.9,98H29.2ZM128,49.9,164.9,98H91.1ZM29.8,110H74.9l35.2,86ZM128,208.2,87.8,110h80.4ZM145.9,196l35.2-86h45.1Zm34.2-98L140.2,46h41.1l45.5,52Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M243,101.4l-56-64a3.7,3.7,0,0,0-3-1.4H72a3.7,3.7,0,0,0-3,1.4l-56,64a3.9,3.9,0,0,0,.1,5.3l112,120a3.9,3.9,0,0,0,5.8,0l112-120A3.9,3.9,0,0,0,243,101.4ZM231.2,100H179.1l-43-56h46.1Zm-155,8L116,205.3,25.2,108Zm94.9,0L128,213.4,84.9,108ZM87,100l41-53.4L169,100Zm92.8,8h51L140,205.3ZM73.8,44h46.1l-43,56H24.8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M246,98.7l-56-64a8,8,0,0,0-6-2.7H72a8,8,0,0,0-6,2.7l-56,64a8.1,8.1,0,0,0,.2,10.8l112,120a8,8,0,0,0,11.6,0l112-120A8.1,8.1,0,0,0,246,98.7ZM222.4,96H181L144.2,48h36.2ZM73.5,112l30.6,74.6L34.4,112Zm91.7,0L128,202.9,90.8,112ZM95.1,96,128,53.1,160.9,96Zm87.4,16h39.1l-69.7,74.6ZM75.6,48h36.2L75,96H33.6Z" />
        </>
      )}
    </svg>
  );
}

export function PhSkipBackCircle(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M168,100v56a12,12,0,0,1-6.7,10.8A12.4,12.4,0,0,1,156,168a12.7,12.7,0,0,1-7.4-2.5l-36-28-.6-.6V156a12,12,0,0,1-24,0V100a12,12,0,0,1,24,0v19.1l.6-.6,36-28a12.2,12.2,0,0,1,12.7-1.3A12,12,0,0,1,168,100Zm68,28A108,108,0,1,1,128,20,108.1,108.1,0,0,1,236,128Zm-24,0a84,84,0,1,0-84,84A84.1,84.1,0,0,0,212,128Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M128,32a96,96,0,1,0,96,96A96,96,0,0,0,128,32Zm28,128-48-32,48-32Z" />
          </g>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Z" />
          <path d="M159.8,88.9a8.3,8.3,0,0,0-8.2.4L108,118.4V96a8,8,0,0,0-16,0v64a8,8,0,0,0,16,0V137.6l43.6,29.1A8.4,8.4,0,0,0,156,168a8.5,8.5,0,0,0,3.8-.9A8,8,0,0,0,164,160V96A8,8,0,0,0,159.8,88.9ZM148,145.1,122.4,128,148,110.9Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm36,136a8,8,0,0,1-4.2,7.1,8.5,8.5,0,0,1-3.8.9,8.7,8.7,0,0,1-4.4-1.3L108,137.6V160a8,8,0,0,1-16,0V96a8,8,0,0,1,16,0v22.4l43.6-29.1a8.3,8.3,0,0,1,8.2-.4A8,8,0,0,1,164,96Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,26A102,102,0,1,0,230,128,102.2,102.2,0,0,0,128,26Zm0,192a90,90,0,1,1,90-90A90.1,90.1,0,0,1,128,218ZM158.8,90.7a6.1,6.1,0,0,0-6.1.3L106,122.1V96a6,6,0,0,0-12,0v64a6,6,0,0,0,12,0V133.9L152.7,165a5.9,5.9,0,0,0,6.1.3A6,6,0,0,0,162,160V96A6,6,0,0,0,158.8,90.7ZM150,148.8,118.8,128,150,107.2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,28A100,100,0,1,0,228,128,100.2,100.2,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220ZM157.9,92.5a4,4,0,0,0-4.1.2l-48,32a3.9,3.9,0,0,0,0,6.6l48,32a3.6,3.6,0,0,0,2.2.7,4.5,4.5,0,0,0,1.9-.5A3.9,3.9,0,0,0,160,160V96A3.9,3.9,0,0,0,157.9,92.5Zm-5.9,60L115.2,128,152,103.5ZM104,96v64a4,4,0,0,1-8,0V96a4,4,0,0,1,8,0Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216ZM159.8,88.9a8.3,8.3,0,0,0-8.2.4L108,118.4V96a8,8,0,0,0-16,0v64a8,8,0,0,0,16,0V137.6l43.6,29.1A8.4,8.4,0,0,0,156,168a8.5,8.5,0,0,0,3.8-.9A8,8,0,0,0,164,160V96A8,8,0,0,0,159.8,88.9ZM148,145.1,122.4,128,148,110.9Z" />
        </>
      )}
    </svg>
  );
}

export function PhSkipBack(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M201.8,36.8a20,20,0,0,0-20.2.4L68,106.6V40a12,12,0,0,0-24,0V216a12,12,0,0,0,24,0V149.4l113.6,69.4a19.5,19.5,0,0,0,10.4,2.9,19.8,19.8,0,0,0,20-20V54.3A20.1,20.1,0,0,0,201.8,36.8ZM188,194.6,79,128,188,61.4Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M67.2,121.2,187.8,47.4A8,8,0,0,1,200,54.3V201.7a8,8,0,0,1-12.2,6.9L67.2,134.8A8,8,0,0,1,67.2,121.2Z" />
          </g>
          <path d="M199.8,40.3a16,16,0,0,0-16.1.3L64,113.7V40a8,8,0,0,0-16,0V216a8,8,0,0,0,16,0V142.3l119.7,73.1a16.2,16.2,0,0,0,8.3,2.3,15.4,15.4,0,0,0,7.8-2,15.9,15.9,0,0,0,8.2-14V54.3A15.9,15.9,0,0,0,199.8,40.3ZM192,201.7,71.3,128h0L192,54.3Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M56,32a8,8,0,0,1,8,8v73.7L183.7,40.6A16,16,0,0,1,208,54.3V201.7a15.9,15.9,0,0,1-8.2,14,15.4,15.4,0,0,1-7.8,2,16.2,16.2,0,0,1-8.3-2.3L64,142.3V216a8,8,0,0,1-16,0V40A8,8,0,0,1,56,32Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M198.8,42.1a13.5,13.5,0,0,0-14.1.2L64,116.1a9.5,9.5,0,0,0-2,1.5V40a6,6,0,0,0-12,0V216a6,6,0,0,0,12,0V138.4a9.5,9.5,0,0,0,2,1.5l120.7,73.8a14,14,0,0,0,7.3,2.1,13,13,0,0,0,6.8-1.9,13.7,13.7,0,0,0,7.2-12.2V54.3A13.7,13.7,0,0,0,198.8,42.1ZM194,201.7a1.9,1.9,0,0,1-1,1.8,1.8,1.8,0,0,1-2-.1L70.3,129.7a1.9,1.9,0,0,1,0-3.4L191,52.6a1.3,1.3,0,0,1,1-.3l1,.2a1.9,1.9,0,0,1,1,1.8Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M197.9,43.8a11.9,11.9,0,0,0-12.2.2L65.1,117.8a11.7,11.7,0,0,0-5.1,6.3V40a4,4,0,0,0-8,0V216a4,4,0,0,0,8,0V131.9a11.7,11.7,0,0,0,5.1,6.3L185.7,212a12.1,12.1,0,0,0,6.3,1.8,12.7,12.7,0,0,0,5.9-1.6,12,12,0,0,0,6.1-10.5V54.3A12,12,0,0,0,197.9,43.8ZM196,201.7a4,4,0,0,1-6.1,3.4L69.3,131.4a3.9,3.9,0,0,1,0-6.8L189.9,50.9a3.7,3.7,0,0,1,2.1-.7,4.3,4.3,0,0,1,2,.6,3.9,3.9,0,0,1,2,3.5Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M199.8,40.3a16,16,0,0,0-16.1.3L64,113.7V40a8,8,0,0,0-16,0V216a8,8,0,0,0,16,0V142.3l119.7,73.1a16.2,16.2,0,0,0,8.3,2.3,15.4,15.4,0,0,0,7.8-2,15.9,15.9,0,0,0,8.2-14V54.3A15.9,15.9,0,0,0,199.8,40.3ZM192,201.7,71.3,128h0L192,54.3Z" />
        </>
      )}
    </svg>
  );
}

export function PhSkipForwardCircle(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,20A108,108,0,1,0,236,128,108.1,108.1,0,0,0,128,20Zm0,192a84,84,0,1,1,84-84A84.1,84.1,0,0,1,128,212Zm40-112v56a12,12,0,0,1-24,0V136.9l-.6.6-36,28A12.7,12.7,0,0,1,100,168a12.4,12.4,0,0,1-5.3-1.2A12,12,0,0,1,88,156V100a12,12,0,0,1,6.7-10.8,12.2,12.2,0,0,1,12.7,1.3l36,28,.6.6V100a12,12,0,0,1,24,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M128,32a96,96,0,1,0,96,96A96,96,0,0,0,128,32ZM100,160V96l48,32Z" />
          </g>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Z" />
          <path d="M156,88a8,8,0,0,0-8,8v22.4L104.4,89.3a8.3,8.3,0,0,0-8.2-.4A8,8,0,0,0,92,96v64a8,8,0,0,0,4.2,7.1,8.5,8.5,0,0,0,3.8.9,8.4,8.4,0,0,0,4.4-1.3L148,137.6V160a8,8,0,0,0,16,0V96A8,8,0,0,0,156,88Zm-48,57.1V110.9L133.6,128Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm36,136a8,8,0,0,1-16,0V137.6l-43.6,29.1A8.7,8.7,0,0,1,100,168a8.5,8.5,0,0,1-3.8-.9A8,8,0,0,1,92,160V96a8,8,0,0,1,4.2-7.1,8.3,8.3,0,0,1,8.2.4L148,118.4V96a8,8,0,0,1,16,0Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,26A102,102,0,1,0,230,128,102.2,102.2,0,0,0,128,26Zm0,192a90,90,0,1,1,90-90A90.1,90.1,0,0,1,128,218ZM156,90a6,6,0,0,0-6,6v26.1L103.3,91A6,6,0,0,0,94,96v64a6,6,0,0,0,3.2,5.3,5.9,5.9,0,0,0,6.1-.3L150,133.9V160a6,6,0,0,0,12,0V96A6,6,0,0,0,156,90Zm-50,58.8V107.2L137.2,128Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,28A100,100,0,1,0,228,128,100.2,100.2,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220ZM160,96v64a4,4,0,0,1-8,0V96a4,4,0,0,1,8,0Zm-9.8,28.7-48-32a4,4,0,0,0-4.1-.2A3.9,3.9,0,0,0,96,96v64a3.9,3.9,0,0,0,2.1,3.5,4.5,4.5,0,0,0,1.9.5,3.6,3.6,0,0,0,2.2-.7l48-32a3.9,3.9,0,0,0,0-6.6ZM104,152.5v-49L140.8,128Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216ZM156,88a8,8,0,0,0-8,8v22.4L104.4,89.3a8.3,8.3,0,0,0-8.2-.4A8,8,0,0,0,92,96v64a8,8,0,0,0,4.2,7.1,8.5,8.5,0,0,0,3.8.9,8.4,8.4,0,0,0,4.4-1.3L148,137.6V160a8,8,0,0,0,16,0V96A8,8,0,0,0,156,88Zm-48,57.1V110.9L133.6,128Z" />
        </>
      )}
    </svg>
  );
}

export function PhSkipForward(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M200,28a12,12,0,0,0-12,12v66.6L74.4,37.2A20,20,0,0,0,44,54.3V201.7a19.8,19.8,0,0,0,20,20,19.5,19.5,0,0,0,10.4-2.9L188,149.4V216a12,12,0,0,0,24,0V40A12,12,0,0,0,200,28ZM68,194.6V61.4L177,128Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M188.8,121.2,68.2,47.4A8,8,0,0,0,56,54.3V201.7a8,8,0,0,0,12.2,6.9l120.6-73.8A8,8,0,0,0,188.8,121.2Z" />
          </g>
          <path d="M200,32a8,8,0,0,0-8,8v73.7L72.3,40.6A16,16,0,0,0,48,54.3V201.7a15.9,15.9,0,0,0,8.2,14,15.4,15.4,0,0,0,7.8,2,16.2,16.2,0,0,0,8.3-2.3L192,142.3V216a8,8,0,0,0,16,0V40A8,8,0,0,0,200,32ZM64,201.7V54.3L184.7,128Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M208,40V216a8,8,0,0,1-16,0V142.3L72.3,215.4a16.2,16.2,0,0,1-8.3,2.3,15.4,15.4,0,0,1-7.8-2,15.9,15.9,0,0,1-8.2-14V54.3A16,16,0,0,1,72.3,40.6L192,113.7V40a8,8,0,0,1,16,0Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M200,34a6,6,0,0,0-6,6v77.6a9.5,9.5,0,0,0-2-1.5L71.3,42.3a13.5,13.5,0,0,0-14.1-.2A13.7,13.7,0,0,0,50,54.3V201.7a13.7,13.7,0,0,0,7.2,12.2,13,13,0,0,0,6.8,1.9,14,14,0,0,0,7.3-2.1L192,139.9a9.5,9.5,0,0,0,2-1.5V216a6,6,0,0,0,12,0V40A6,6,0,0,0,200,34Zm-14.3,95.7L65,203.4a1.8,1.8,0,0,1-2,.1,1.9,1.9,0,0,1-1-1.8V54.3a1.9,1.9,0,0,1,1-1.8l1-.2a1.3,1.3,0,0,1,1,.3l120.7,73.7a1.9,1.9,0,0,1,0,3.4Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M200,36a4,4,0,0,0-4,4v84.1a11.7,11.7,0,0,0-5.1-6.3L70.3,44a11.9,11.9,0,0,0-12.2-.2A12,12,0,0,0,52,54.3V201.7a12,12,0,0,0,6.1,10.5,12.7,12.7,0,0,0,5.9,1.6,12.1,12.1,0,0,0,6.3-1.8l120.6-73.8a11.7,11.7,0,0,0,5.1-6.3V216a4,4,0,0,0,8,0V40A4,4,0,0,0,200,36Zm-13.3,95.4L66.1,205.1a4,4,0,0,1-6.1-3.4V54.3a3.9,3.9,0,0,1,2-3.5,4.3,4.3,0,0,1,2-.6,3.7,3.7,0,0,1,2.1.7l120.6,73.7a3.9,3.9,0,0,1,0,6.8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M200,32a8,8,0,0,0-8,8v73.7L72.3,40.6A16,16,0,0,0,48,54.3V201.7a15.9,15.9,0,0,0,8.2,14,15.4,15.4,0,0,0,7.8,2,16.2,16.2,0,0,0,8.3-2.3L192,142.3V216a8,8,0,0,0,16,0V40A8,8,0,0,0,200,32ZM64,201.7V54.3L184.7,128Z" />
        </>
      )}
    </svg>
  );
}

export function PhSkull(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M116,132a24,24,0,1,1-24-24A24.1,24.1,0,0,1,116,132Zm48-24a24,24,0,1,0,24,24A24.1,24.1,0,0,0,164,108Zm68,8a103.8,103.8,0,0,1-32,75v25a20.1,20.1,0,0,1-20,20H76a20.1,20.1,0,0,1-20-20V191a104.1,104.1,0,0,1-32-74.8C23.9,59.5,69.9,12.8,126.5,12A104,104,0,0,1,232,116Zm-24,0a79.9,79.9,0,0,0-80-80h-1.1c-43.6.6-79,36.6-78.9,80.2a79.7,79.7,0,0,0,27.8,60.4,11.9,11.9,0,0,1,4.2,9.1V212H96V192a12,12,0,0,1,24,0v20h16V192a12,12,0,0,1,24,0v20h16V185.7a11.5,11.5,0,0,1,4.2-9A80.1,80.1,0,0,0,208,116Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M128,24A92,92,0,0,0,80,194.5V216a8,8,0,0,0,8,8h80a8,8,0,0,0,8-8V194.5A92,92,0,0,0,128,24ZM92,152a20,20,0,1,1,20-20A20.1,20.1,0,0,1,92,152Zm72,0a20,20,0,1,1,20-20A20.1,20.1,0,0,1,164,152Z"
            opacity="0.2"
          />
          <path d="M92,104a28,28,0,1,0,28,28A28.1,28.1,0,0,0,92,104Zm0,40a12,12,0,1,1,12-12A12,12,0,0,1,92,144Zm72-40a28,28,0,1,0,28,28A28.1,28.1,0,0,0,164,104Zm0,40a12,12,0,1,1,12-12A12,12,0,0,1,164,144ZM128,16A100,100,0,0,0,72,198.9V216a16,16,0,0,0,16,16h80a16,16,0,0,0,16-16V198.9A100,100,0,0,0,128,16Zm43.8,171.7a7.8,7.8,0,0,0-3.8,6.8V216H152V192a8,8,0,0,0-16,0v24H120V192a8,8,0,0,0-16,0v24H88V194.5a7.8,7.8,0,0,0-3.8-6.8,84,84,0,1,1,87.6,0Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M124.1,16.1c-51.6,1.9-93.7,43.6-96,95.3A99.4,99.4,0,0,0,72,198.9V216a16,16,0,0,0,16,16h8a4,4,0,0,0,4-4V204.3a8.2,8.2,0,0,1,7.5-8.3,8,8,0,0,1,8.5,8v24a4,4,0,0,0,4,4h16a4,4,0,0,0,4-4V204.3a8.2,8.2,0,0,1,7.5-8.3,8,8,0,0,1,8.5,8v24a4,4,0,0,0,4,4h8a16,16,0,0,0,16-16V198.9A100,100,0,0,0,124.1,16.1ZM92,152a20,20,0,1,1,20-20A20.1,20.1,0,0,1,92,152Zm72,0a20,20,0,1,1,20-20A20.1,20.1,0,0,1,164,152Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M92,106a26,26,0,1,0,26,26A26.1,26.1,0,0,0,92,106Zm0,40a14,14,0,1,1,14-14A14,14,0,0,1,92,146Zm72-40a26,26,0,1,0,26,26A26.1,26.1,0,0,0,164,106Zm0,40a14,14,0,1,1,14-14A14,14,0,0,1,164,146ZM128,18A98,98,0,0,0,74,197.8V216a14,14,0,0,0,14,14h80a14,14,0,0,0,14-14V197.8A98,98,0,0,0,128,18Zm44.9,171.4a6,6,0,0,0-2.9,5.1V216a2,2,0,0,1-2,2H150V192a6,6,0,0,0-12,0v26H118V192a6,6,0,0,0-12,0v26H88a2,2,0,0,1-2-2V194.5a6,6,0,0,0-2.9-5.1,86,86,0,1,1,89.8,0Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M92,108a24,24,0,1,0,24,24A24.1,24.1,0,0,0,92,108Zm0,40a16,16,0,1,1,16-16A16,16,0,0,1,92,148Zm72-40a24,24,0,1,0,24,24A24.1,24.1,0,0,0,164,108Zm0,40a16,16,0,1,1,16-16A16,16,0,0,1,164,148ZM128,20A96,96,0,0,0,76,196.7V216a12,12,0,0,0,12,12h80a12,12,0,0,0,12-12V196.7A96,96,0,0,0,128,20Zm45.9,171.1a3.9,3.9,0,0,0-1.9,3.4V216a4,4,0,0,1-4,4H148V192a4,4,0,0,0-8,0v28H116V192a4,4,0,0,0-8,0v28H88a4,4,0,0,1-4-4V194.5a3.9,3.9,0,0,0-1.9-3.4,88,88,0,1,1,91.8,0Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M92,104a28,28,0,1,0,28,28A28.1,28.1,0,0,0,92,104Zm0,40a12,12,0,1,1,12-12A12,12,0,0,1,92,144Zm72-40a28,28,0,1,0,28,28A28.1,28.1,0,0,0,164,104Zm0,40a12,12,0,1,1,12-12A12,12,0,0,1,164,144ZM128,16A100,100,0,0,0,72,198.9V216a16,16,0,0,0,16,16h80a16,16,0,0,0,16-16V198.9A100,100,0,0,0,128,16Zm43.8,171.7a7.8,7.8,0,0,0-3.8,6.8V216H152V192a8,8,0,0,0-16,0v24H120V192a8,8,0,0,0-16,0v24H88V194.5a7.8,7.8,0,0,0-3.8-6.8,84,84,0,1,1,87.6,0Z" />
        </>
      )}
    </svg>
  );
}

export function PhSlackLogo(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M236,104a36,36,0,0,0-36-36,34.4,34.4,0,0,0-12,2.1V56a36,36,0,0,0-60-26.8A36,36,0,0,0,68,56a34.4,34.4,0,0,0,2.1,12H56a36,36,0,0,0-26.8,60A36,36,0,0,0,56,188a34.4,34.4,0,0,0,12-2.1V200a36,36,0,0,0,60,26.8A36,36,0,0,0,188,200a34.4,34.4,0,0,0-2.1-12H200a36,36,0,0,0,26.8-60A36,36,0,0,0,236,104ZM200,92a12,12,0,0,1,0,24H188V104A12,12,0,0,1,200,92ZM152,44a12,12,0,0,1,12,12v48a12,12,0,0,1-12,12H140V56A12,12,0,0,1,152,44ZM92,56a12,12,0,0,1,24,0V68H104A12,12,0,0,1,92,56ZM56,92h48a12,12,0,0,1,12,12v12H56a12,12,0,0,1,0-24Zm0,72a12,12,0,0,1,0-24H68v12A12,12,0,0,1,56,164Zm48,48a12,12,0,0,1-12-12V152a12,12,0,0,1,12-12h12v60A12,12,0,0,1,104,212Zm60-12a12,12,0,0,1-24,0V188h12A12,12,0,0,1,164,200Zm36-36H152a12,12,0,0,1-12-12V140h60a12,12,0,0,1,0,24Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path
              d="M176,104h24a0,0,0,0,1,0,0v72a24,24,0,0,1-24,24h0a24,24,0,0,1-24-24V128a24,24,0,0,1,24-24Z"
              transform="translate(24 328) rotate(-90)"
            />
          </g>
          <g opacity="0.2">
            <path d="M128,176h24a23.9,23.9,0,0,1,24,24h0a23.9,23.9,0,0,1-24,24h0a23.9,23.9,0,0,1-24-24Z" />
          </g>
          <g opacity="0.2">
            <path
              d="M80,56h24a0,0,0,0,1,0,0v72a24,24,0,0,1-24,24h0a24,24,0,0,1-24-24V80A24,24,0,0,1,80,56Z"
              transform="translate(184 24) rotate(90)"
            />
          </g>
          <g opacity="0.2">
            <path d="M128,80H104A23.9,23.9,0,0,1,80,56h0a23.9,23.9,0,0,1,24-24h0a23.9,23.9,0,0,1,24,24Z" />
          </g>
          <path d="M221.1,128A32,32,0,0,0,200,72a32.2,32.2,0,0,0-16,4.3V56a32,32,0,0,0-56-21.1A32,32,0,0,0,72,56a32.2,32.2,0,0,0,4.3,16H56a32,32,0,0,0-21.1,56A32,32,0,0,0,56,184a32.2,32.2,0,0,0,16-4.3V200a32,32,0,0,0,56,21.1A32,32,0,0,0,184,200a32.2,32.2,0,0,0-4.3-16H200a32,32,0,0,0,21.1-56ZM200,88a16,16,0,0,1,0,32H184V104A16,16,0,0,1,200,88ZM152,40a16,16,0,0,1,16,16v48a16,16,0,0,1-16,16H136V56A16,16,0,0,1,152,40ZM88,56a16,16,0,0,1,32,0V72H104A16,16,0,0,1,88,56ZM40,104A16,16,0,0,1,56,88h48a16,16,0,0,1,16,16v16H56A16,16,0,0,1,40,104Zm16,64a16,16,0,0,1,0-32H72v16A16,16,0,0,1,56,168Zm48,48a16,16,0,0,1-16-16V152a16,16,0,0,1,16-16h16v64A16,16,0,0,1,104,216Zm64-16a16,16,0,0,1-32,0V184h16A16,16,0,0,1,168,200Zm32-32H152a16,16,0,0,1-16-16V136h64a16,16,0,0,1,0,32Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M221.1,128A32,32,0,0,0,200,72a32.2,32.2,0,0,0-16,4.3V56a32,32,0,0,0-56-21.1A32,32,0,0,0,72,56a32.2,32.2,0,0,0,4.3,16H56a32,32,0,0,0-21.1,56A32,32,0,0,0,56,184a32.2,32.2,0,0,0,16-4.3V200a32,32,0,0,0,56,21.1A32,32,0,0,0,184,200a32.2,32.2,0,0,0-4.3-16H200a32,32,0,0,0,21.1-56ZM88,56a16,16,0,0,1,32,0V72H104A16,16,0,0,1,88,56ZM40,104A16,16,0,0,1,56,88h48a16,16,0,0,1,16,16v16H56A16,16,0,0,1,40,104Zm128,96a16,16,0,0,1-32,0V184h16A16,16,0,0,1,168,200Zm32-32H152a16,16,0,0,1-16-16V136h64a16,16,0,0,1,0,32Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M218,128a30,30,0,1,0-36-48V56a30,30,0,0,0-54-18A30,30,0,0,0,80,74H56a30,30,0,0,0-18,54,30,30,0,0,0,36,48v24a30,30,0,0,0,54,18,30,30,0,0,0,48-36h24a30,30,0,0,0,18-54ZM200,86a18,18,0,0,1,0,36H182V104A18.1,18.1,0,0,1,200,86ZM152,38a18.1,18.1,0,0,1,18,18v48a18.1,18.1,0,0,1-18,18H134V56A18.1,18.1,0,0,1,152,38ZM86,56a18,18,0,0,1,36,0V74H104A18.1,18.1,0,0,1,86,56ZM38,104A18.1,18.1,0,0,1,56,86h48a18.1,18.1,0,0,1,18,18v18H56A18.1,18.1,0,0,1,38,104Zm18,66a18,18,0,0,1,0-36H74v18A18.1,18.1,0,0,1,56,170Zm48,48a18.1,18.1,0,0,1-18-18V152a18.1,18.1,0,0,1,18-18h18v66A18.1,18.1,0,0,1,104,218Zm66-18a18,18,0,0,1-36,0V182h18A18.1,18.1,0,0,1,170,200Zm30-30H152a18.1,18.1,0,0,1-18-18V134h66a18,18,0,0,1,0,36Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M214.4,128A28,28,0,1,0,180,84.4V56a28,28,0,0,0-52-14.4A28,28,0,1,0,84.4,76H56a28,28,0,0,0-14.4,52A28,28,0,1,0,76,171.6V200a28,28,0,0,0,52,14.4A28,28,0,1,0,171.6,180H200a28,28,0,0,0,14.4-52ZM200,84a20,20,0,0,1,0,40H180V104A20.1,20.1,0,0,1,200,84Zm-28.4,40,.4-.4v.4ZM152,36a20.1,20.1,0,0,1,20,20v48a20.1,20.1,0,0,1-20,20H132V56A20.1,20.1,0,0,1,152,36ZM132,171.6l.4.4H132Zm-8-87.2-.4-.4h.4ZM84,56a20,20,0,0,1,40,0V76H104A20.1,20.1,0,0,1,84,56ZM36,104A20.1,20.1,0,0,1,56,84h48a20.1,20.1,0,0,1,20,20v20H56A20.1,20.1,0,0,1,36,104Zm48.4,28-.4.4V132ZM56,172a20,20,0,0,1,0-40H76v20A20.1,20.1,0,0,1,56,172Zm48,48a20.1,20.1,0,0,1-20-20V152a20.1,20.1,0,0,1,20-20h20v68A20.1,20.1,0,0,1,104,220Zm68-20a20,20,0,0,1-40,0V180h20A20.1,20.1,0,0,1,172,200Zm28-28H152a20.1,20.1,0,0,1-20-20V132h68a20,20,0,0,1,0,40Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M221.1,128A32,32,0,0,0,200,72a32.2,32.2,0,0,0-16,4.3V56a32,32,0,0,0-56-21.1A32,32,0,0,0,72,56a32.2,32.2,0,0,0,4.3,16H56a32,32,0,0,0-21.1,56A32,32,0,0,0,56,184a32.2,32.2,0,0,0,16-4.3V200a32,32,0,0,0,56,21.1A32,32,0,0,0,184,200a32.2,32.2,0,0,0-4.3-16H200a32,32,0,0,0,21.1-56ZM200,88a16,16,0,0,1,0,32H184V104A16,16,0,0,1,200,88ZM152,40a16,16,0,0,1,16,16v48a16,16,0,0,1-16,16H136V56A16,16,0,0,1,152,40ZM88,56a16,16,0,0,1,32,0V72H104A16,16,0,0,1,88,56ZM40,104A16,16,0,0,1,56,88h48a16,16,0,0,1,16,16v16H56A16,16,0,0,1,40,104Zm16,64a16,16,0,0,1,0-32H72v16A16,16,0,0,1,56,168Zm48,48a16,16,0,0,1-16-16V152a16,16,0,0,1,16-16h16v64A16,16,0,0,1,104,216Zm64-16a16,16,0,0,1-32,0V184h16A16,16,0,0,1,168,200Zm32-32H152a16,16,0,0,1-16-16V136h64a16,16,0,0,1,0,32Z" />
        </>
      )}
    </svg>
  );
}

export function PhSlidersHorizontal(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M228,172a12,12,0,0,1-12,12H197.7a32.1,32.1,0,0,1-59.4,0H40a12,12,0,0,1,0-24h98.3a32.1,32.1,0,0,1,59.4,0H216A12,12,0,0,1,228,172ZM40,96H74.3a32.1,32.1,0,0,0,59.4,0H216a12,12,0,0,0,0-24H133.7a32.1,32.1,0,0,0-59.4,0H40a12,12,0,0,0,0,24Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="104" cy="84" r="20" />
          </g>
          <path d="M216,164H194.8a28,28,0,0,0-53.6,0H40a8,8,0,0,0,0,16H141.2a28,28,0,0,0,53.6,0H216a8,8,0,0,0,0-16Zm-48,20a12,12,0,1,1,12-12A12,12,0,0,1,168,184Z" />
          <path d="M40,92H77.2a28,28,0,0,0,53.6,0H216a8,8,0,0,0,0-16H130.8a28,28,0,0,0-53.6,0H40a8,8,0,0,0,0,16Zm64-20A12,12,0,1,1,92,84,12,12,0,0,1,104,72Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M224,172a8,8,0,0,1-8,8H194.8a28,28,0,0,1-53.6,0H40a8,8,0,0,1,0-16H141.2a28,28,0,0,1,53.6,0H216A8,8,0,0,1,224,172ZM40,92H77.2a28,28,0,0,0,53.6,0H216a8,8,0,0,0,0-16H130.8a28,28,0,0,0-53.6,0H40a8,8,0,0,0,0,16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M216,166H193.3a26,26,0,0,0-50.6,0H40a6,6,0,0,0,0,12H142.7a26,26,0,0,0,50.6,0H216a6,6,0,0,0,0-12Zm-48,20a14,14,0,1,1,14-14A14,14,0,0,1,168,186ZM40,90H78.7a26,26,0,0,0,50.6,0H216a6,6,0,0,0,0-12H129.3a26,26,0,0,0-50.6,0H40a6,6,0,0,0,0,12Zm64-20A14,14,0,1,1,90,84,14,14,0,0,1,104,70Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M84,88H40a4,4,0,0,1,0-8H84a4,4,0,0,1,0,8Zm136,84a4,4,0,0,1-4,4H191.7a24,24,0,0,1-47.4,0H40a4,4,0,0,1,0-8H144.3a24,24,0,0,1,47.4,0H216A4,4,0,0,1,220,172Zm-36,0a16,16,0,1,0-16,16A16,16,0,0,0,184,172ZM80,84a24,24,0,0,1,47.7-4H216a4,4,0,0,1,0,8H127.7A24,24,0,0,1,80,84Zm8,0a16,16,0,1,0,16-16A16,16,0,0,0,88,84Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,164H194.8a28,28,0,0,0-53.6,0H40a8,8,0,0,0,0,16H141.2a28,28,0,0,0,53.6,0H216a8,8,0,0,0,0-16Zm-48,20a12,12,0,1,1,12-12A12,12,0,0,1,168,184ZM40,92H77.2a28,28,0,0,0,53.6,0H216a8,8,0,0,0,0-16H130.8a28,28,0,0,0-53.6,0H40a8,8,0,0,0,0,16Zm64-20A12,12,0,1,1,92,84,12,12,0,0,1,104,72Z" />
        </>
      )}
    </svg>
  );
}

export function PhSliders(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M140,58.4V40a12,12,0,0,0-24,0V58.4a32,32,0,0,0,0,59.3V216a12,12,0,0,0,24,0V117.7a32,32,0,0,0,0-59.3Z" />
          <path d="M232,168a31.9,31.9,0,0,0-20-29.6V40a12,12,0,0,0-24,0v98.4a32,32,0,0,0,0,59.3V216a12,12,0,0,0,24,0V197.7A32.2,32.2,0,0,0,232,168Z" />
          <path d="M68,106.4V40a12,12,0,0,0-24,0v66.4a32,32,0,0,0,0,59.3V216a12,12,0,0,0,24,0V165.7a32,32,0,0,0,0-59.3Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="56" cy="136" r="20" />
          </g>
          <path d="M136,61.2V40a8,8,0,0,0-16,0V61.2a28,28,0,0,0,0,53.6V216a8,8,0,0,0,16,0V114.8a28,28,0,0,0,0-53.6ZM128,100a12,12,0,1,1,12-12A12,12,0,0,1,128,100Z" />
          <path d="M228,168a28,28,0,0,0-20-26.8V40a8,8,0,0,0-16,0V141.2a28,28,0,0,0,0,53.6V216a8,8,0,0,0,16,0V194.8A28,28,0,0,0,228,168Zm-28,12a12,12,0,1,1,12-12A12,12,0,0,1,200,180Z" />
          <path d="M64,109.2V40a8,8,0,0,0-16,0v69.2a28,28,0,0,0,0,53.6V216a8,8,0,0,0,16,0V162.8a28,28,0,0,0,0-53.6ZM56,148a12,12,0,1,1,12-12A12,12,0,0,1,56,148Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M152,88a24,24,0,0,1-16,22.6V216a8,8,0,0,1-16,0V110.6a24,24,0,0,1,0-45.2V40a8,8,0,0,1,16,0V65.4A24,24,0,0,1,152,88Zm72,80a24,24,0,0,0-16-22.6V40a8,8,0,0,0-16,0V145.4a24,24,0,0,0,0,45.2V216a8,8,0,0,0,16,0V190.6A24,24,0,0,0,224,168ZM64,113.4V40a8,8,0,0,0-16,0v73.4a24,24,0,0,0,0,45.2V216a8,8,0,0,0,16,0V158.6a24,24,0,0,0,0-45.2Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M134,62.7V40a6,6,0,0,0-12,0V62.7a26,26,0,0,0,0,50.6V216a6,6,0,0,0,12,0V113.3a26,26,0,0,0,0-50.6ZM128,102a14,14,0,1,1,14-14A14,14,0,0,1,128,102Zm98,66a26,26,0,0,0-20-25.3V40a6,6,0,0,0-12,0V142.7a26,26,0,0,0,0,50.6V216a6,6,0,0,0,12,0V193.3A26,26,0,0,0,226,168Zm-26,14a14,14,0,1,1,14-14A14,14,0,0,1,200,182ZM62,110.7V40a6,6,0,0,0-12,0v70.7a26,26,0,0,0,0,50.6V216a6,6,0,0,0,6,6h0a6,6,0,0,0,6-6V161.3a26,26,0,0,0,0-50.6ZM56,150a14,14,0,1,1,14-14A14,14,0,0,1,56,150Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M132,64.3V40a4,4,0,0,0-8,0V64.3a24,24,0,0,0,0,47.4V216a4,4,0,0,0,8,0V111.7a24,24,0,0,0,0-47.4ZM128,104a16,16,0,1,1,16-16A16,16,0,0,1,128,104Zm96,64a24.2,24.2,0,0,0-20-23.7V40a4,4,0,0,0-8,0V144.3a24,24,0,0,0,0,47.4V216a4,4,0,0,0,8,0V191.7A24.1,24.1,0,0,0,224,168Zm-24,16a16,16,0,1,1,16-16A16,16,0,0,1,200,184ZM60,112.3V40a4,4,0,0,0-8,0v72.3a24,24,0,0,0,0,47.4V216a4,4,0,0,0,8,0V159.7a24,24,0,0,0,0-47.4ZM56,152a16,16,0,1,1,16-16A16,16,0,0,1,56,152Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M136,61.2V40a8,8,0,0,0-16,0V61.2a28,28,0,0,0,0,53.6V216a8,8,0,0,0,16,0V114.8a28,28,0,0,0,0-53.6ZM128,100a12,12,0,1,1,12-12A12,12,0,0,1,128,100Zm100,68a27.9,27.9,0,0,0-20-26.8V40a8,8,0,0,0-8-8h0a8,8,0,0,0-8,8V141.2a28,28,0,0,0,0,53.6V216a8,8,0,0,0,16,0V194.8A27.9,27.9,0,0,0,228,168Zm-28,12a12,12,0,1,1,12-12A12,12,0,0,1,200,180ZM64,109.2V40a8,8,0,0,0-16,0v69.2a28,28,0,0,0,0,53.6V216a8,8,0,0,0,8,8h0a8,8,0,0,0,8-8V162.8a28,28,0,0,0,0-53.6ZM56,148a12,12,0,1,1,12-12A12,12,0,0,1,56,148Z" />
        </>
      )}
    </svg>
  );
}

export function PhSmileyBlank(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M108,108A16,16,0,1,1,92,92,16,16,0,0,1,108,108Zm56-16a16,16,0,1,0,16,16A16,16,0,0,0,164,92Zm72,36A108,108,0,1,1,128,20,108.1,108.1,0,0,1,236,128Zm-24,0a84,84,0,1,0-84,84A84.1,84.1,0,0,0,212,128Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="128" cy="128" r="96" />
          </g>
          <circle cx="92" cy="108" r="12" />
          <circle cx="164" cy="108" r="12" />
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24ZM92,120a12,12,0,1,1,12-12A12,12,0,0,1,92,120Zm72,0a12,12,0,1,1,12-12A12,12,0,0,1,164,120Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,26A102,102,0,1,0,230,128,102.2,102.2,0,0,0,128,26Zm0,192a90,90,0,1,1,90-90A90.1,90.1,0,0,1,128,218ZM102,108A10,10,0,1,1,92,98,10,10,0,0,1,102,108Zm72,0a10,10,0,1,1-10-10A10,10,0,0,1,174,108Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,28A100,100,0,1,0,228,128,100.2,100.2,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220ZM100,108a8,8,0,1,1-8-8A8,8,0,0,1,100,108Zm72,0a8,8,0,1,1-8-8A8,8,0,0,1,172,108Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M104,108A12,12,0,1,1,92,96,12,12,0,0,1,104,108Zm60-12a12,12,0,1,0,12,12A12,12,0,0,0,164,96Zm68,32A104,104,0,1,1,128,24,104.2,104.2,0,0,1,232,128Zm-16,0a88,88,0,1,0-88,88A88.1,88.1,0,0,0,216,128Z" />
        </>
      )}
    </svg>
  );
}

export function PhSmileyMeh(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M76,108a16,16,0,1,1,16,16A16,16,0,0,1,76,108Zm88-16a16,16,0,1,0,16,16A16,16,0,0,0,164,92Zm72,36A108,108,0,1,1,128,20,108.1,108.1,0,0,1,236,128Zm-24,0a84,84,0,1,0-84,84A84.1,84.1,0,0,0,212,128Zm-44,20H88a12,12,0,0,0,0,24h80a12,12,0,0,0,0-24Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="128" cy="128" r="96" />
          </g>
          <circle cx="92" cy="108" r="12" />
          <circle cx="164" cy="108" r="12" />
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Z" />
          <path d="M168,152H88a8,8,0,0,0,0,16h80a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24ZM92,96a12,12,0,1,1-12,12A12,12,0,0,1,92,96Zm76,72H88a8,8,0,0,1,0-16h80a8,8,0,0,1,0,16Zm-4-48a12,12,0,1,1,12-12A12,12,0,0,1,164,120Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,26A102,102,0,1,0,230,128,102.2,102.2,0,0,0,128,26Zm0,192a90,90,0,1,1,90-90A90.1,90.1,0,0,1,128,218Zm46-58a6,6,0,0,1-6,6H88a6,6,0,0,1,0-12h80A6,6,0,0,1,174,160ZM82,108a10,10,0,1,1,10,10A10,10,0,0,1,82,108Zm92,0a10,10,0,1,1-10-10A10,10,0,0,1,174,108Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,28A100,100,0,1,0,228,128,100.2,100.2,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm44-60a4,4,0,0,1-4,4H88a4,4,0,0,1,0-8h80A4,4,0,0,1,172,160ZM84,108a8,8,0,1,1,8,8A8,8,0,0,1,84,108Zm88,0a8,8,0,1,1-8-8A8,8,0,0,1,172,108Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M80,108a12,12,0,1,1,12,12A12,12,0,0,1,80,108Zm84-12a12,12,0,1,0,12,12A12,12,0,0,0,164,96Zm68,32A104,104,0,1,1,128,24,104.2,104.2,0,0,1,232,128Zm-16,0a88,88,0,1,0-88,88A88.1,88.1,0,0,0,216,128Zm-48,24H88a8,8,0,0,0,0,16h80a8,8,0,0,0,0-16Z" />
        </>
      )}
    </svg>
  );
}

export function PhSmileyNervous(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,20A108,108,0,1,0,236,128,108.1,108.1,0,0,0,128,20Zm0,192a84,84,0,1,1,84-84A84.1,84.1,0,0,1,128,212ZM76,108a16,16,0,1,1,16,16A16,16,0,0,1,76,108Zm72,0a16,16,0,1,1,16,16A16,16,0,0,1,148,108Zm31.6,49.3a12,12,0,0,1-18.8,15l-.8-1.1-6.6,8.3a12.1,12.1,0,0,1-18.8,0l-6.6-8.3-6.6,8.3a12.1,12.1,0,0,1-18.8,0L96,171.2l-.8,1.1a12,12,0,0,1-18.8-15l10.2-12.8a12.1,12.1,0,0,1,18.8,0l6.6,8.3,6.6-8.3a12.1,12.1,0,0,1,18.8,0l6.6,8.3,6.6-8.3a12.1,12.1,0,0,1,18.8,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="128" cy="128" r="96" />
          </g>
          <path d="M80,108a12,12,0,1,1,12,12A12,12,0,0,1,80,108Zm84,12a12,12,0,1,0-12-12A12,12,0,0,0,164,120Zm68,8A104,104,0,1,1,128,24,104.2,104.2,0,0,1,232,128Zm-16,0a88,88,0,1,0-88,88A88.1,88.1,0,0,0,216,128Zm-49.8,19a7.9,7.9,0,0,0-12.4,0L144,159.2,134.2,147a7.9,7.9,0,0,0-12.4,0L112,159.2,102.2,147a7.9,7.9,0,0,0-12.4,0l-16,20a8,8,0,0,0,12.4,10L96,164.8l9.8,12.2a7.9,7.9,0,0,0,12.4,0l9.8-12.2,9.8,12.2a7.9,7.9,0,0,0,12.4,0l9.8-12.2,9.8,12.2a7.8,7.8,0,0,0,6.2,3,8,8,0,0,0,6.2-13Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm36,72a12,12,0,1,1-12,12A12,12,0,0,1,164,96ZM92,96a12,12,0,1,1-12,12A12,12,0,0,1,92,96Zm89,82.2a7.9,7.9,0,0,1-5,1.8,7.8,7.8,0,0,1-6.2-3L160,164.8,150.2,177a7.9,7.9,0,0,1-12.4,0L128,164.8,118.2,177a7.9,7.9,0,0,1-12.4,0L96,164.8,86.2,177a8,8,0,0,1-12.4-10l16-20a7.9,7.9,0,0,1,12.4,0l9.8,12.2,9.8-12.2a7.9,7.9,0,0,1,12.4,0l9.8,12.2,9.8-12.2a7.9,7.9,0,0,1,12.4,0l16,20A7.9,7.9,0,0,1,181,178.2Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,26A102,102,0,1,0,230,128,102.2,102.2,0,0,0,128,26Zm0,192a90,90,0,1,1,90-90A90.1,90.1,0,0,1,128,218Zm52.7-49.7a5.9,5.9,0,0,1-1,8.4A5.8,5.8,0,0,1,176,178a6,6,0,0,1-4.7-2.3L160,161.6l-11.3,14.1a6,6,0,0,1-9.4,0L128,161.6l-11.3,14.1a6,6,0,0,1-9.4,0L96,161.6,84.7,175.7a6,6,0,1,1-9.4-7.4l16-20a6,6,0,0,1,9.4,0L112,162.4l11.3-14.1a6,6,0,0,1,9.4,0L144,162.4l11.3-14.1a6,6,0,0,1,9.4,0ZM82,108a10,10,0,1,1,10,10A10,10,0,0,1,82,108Zm72,0a10,10,0,1,1,10,10A10,10,0,0,1,154,108Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,28A100,100,0,1,0,228,128,100.2,100.2,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm51.1-50.5a4,4,0,0,1-.6,5.6,3.8,3.8,0,0,1-2.5.9,3.9,3.9,0,0,1-3.1-1.5L160,158.4l-12.9,16.1a4,4,0,0,1-6.2,0L128,158.4l-12.9,16.1a4,4,0,0,1-6.2,0L96,158.4,83.1,174.5a4,4,0,0,1-6.2-5l16-20a4,4,0,0,1,6.2,0L112,165.6l12.9-16.1a4,4,0,0,1,6.2,0L144,165.6l12.9-16.1a4,4,0,0,1,6.2,0ZM84,108a8,8,0,1,1,8,8A8,8,0,0,1,84,108Zm72,0a8,8,0,1,1,8,8A8,8,0,0,1,156,108Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M80,108a12,12,0,1,1,12,12A12,12,0,0,1,80,108Zm84,12a12,12,0,1,0-12-12A12,12,0,0,0,164,120Zm68,8A104,104,0,1,1,128,24,104.2,104.2,0,0,1,232,128Zm-16,0a88,88,0,1,0-88,88A88.1,88.1,0,0,0,216,128Zm-49.8,19a7.9,7.9,0,0,0-12.4,0L144,159.2,134.2,147a7.9,7.9,0,0,0-12.4,0L112,159.2,102.2,147a7.9,7.9,0,0,0-12.4,0l-16,20a8,8,0,0,0,12.4,10L96,164.8l9.8,12.2a7.9,7.9,0,0,0,12.4,0l9.8-12.2,9.8,12.2a7.9,7.9,0,0,0,12.4,0l9.8-12.2,9.8,12.2a7.8,7.8,0,0,0,6.2,3,8,8,0,0,0,6.2-13Z" />
        </>
      )}
    </svg>
  );
}

export function PhSmileySad(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,20A108,108,0,1,0,236,128,108.1,108.1,0,0,0,128,20Zm0,192a84,84,0,1,1,84-84A84.1,84.1,0,0,1,128,212ZM76,108a16,16,0,1,1,16,16A16,16,0,0,1,76,108Zm104,0a16,16,0,1,1-16-16A16,16,0,0,1,180,108Zm-5.4,54.2A12,12,0,1,1,156,177.3a36.1,36.1,0,0,0-56,0,12,12,0,1,1-18.6-15.1,60,60,0,0,1,93.2,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M224,128a96,96,0,1,1-96-96A96,96,0,0,1,224,128Z"
            opacity="0.2"
          />
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216ZM80,108a12,12,0,1,1,12,12A12,12,0,0,1,80,108Zm72,0a12,12,0,1,1,12,12A12,12,0,0,1,152,108Zm24.5,64a8,8,0,0,1-2.9,10.9,7.6,7.6,0,0,1-4,1.1,7.7,7.7,0,0,1-6.9-4,40.1,40.1,0,0,0-69.4,0,8,8,0,1,1-13.8-8,56,56,0,0,1,97,0Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm36,72a12,12,0,1,1-12,12A12,12,0,0,1,164,96ZM92,96a12,12,0,1,1-12,12A12,12,0,0,1,92,96Zm81.6,86.9a7.6,7.6,0,0,1-4,1.1,7.7,7.7,0,0,1-6.9-4,40.1,40.1,0,0,0-69.4,0,8,8,0,1,1-13.8-8,56,56,0,0,1,97,0A8,8,0,0,1,173.6,182.9Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,26A102,102,0,1,0,230,128,102.2,102.2,0,0,0,128,26Zm0,192a90,90,0,1,1,90-90A90.1,90.1,0,0,1,128,218ZM82,108a10,10,0,1,1,10,10A10,10,0,0,1,82,108Zm72,0a10,10,0,1,1,10,10A10,10,0,0,1,154,108Zm20.8,65a6,6,0,0,1-2.2,8.2,6.7,6.7,0,0,1-3,.8,6,6,0,0,1-5.2-3,42,42,0,0,0-72.8,0,5.9,5.9,0,0,1-8.2,2.2,6,6,0,0,1-2.2-8.2,54.1,54.1,0,0,1,93.6,0Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,28A100,100,0,1,0,228,128,100.2,100.2,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220ZM84,108a8,8,0,1,1,8,8A8,8,0,0,1,84,108Zm72,0a8,8,0,1,1,8,8A8,8,0,0,1,156,108Zm17,66a4,4,0,0,1-1.4,5.5,4.6,4.6,0,0,1-2,.5,4.1,4.1,0,0,1-3.5-2,44,44,0,0,0-76.2,0,4,4,0,1,1-6.9-4,51.9,51.9,0,0,1,90,0Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216ZM80,108a12,12,0,1,1,12,12A12,12,0,0,1,80,108Zm72,0a12,12,0,1,1,12,12A12,12,0,0,1,152,108Zm24.5,64a8,8,0,0,1-2.9,10.9,7.6,7.6,0,0,1-4,1.1,7.7,7.7,0,0,1-6.9-4,40.1,40.1,0,0,0-69.4,0,8,8,0,1,1-13.8-8,56,56,0,0,1,97,0Z" />
        </>
      )}
    </svg>
  );
}

export function PhSmileySticker(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,20a108,108,0,1,0,31.7,211.3,12.2,12.2,0,0,0,5-3l63.6-63.6a12.2,12.2,0,0,0,3-5A108.2,108.2,0,0,0,128,20Zm81.1,129.9-59.2,59.2a84,84,0,1,1,59.2-59.2ZM76,108a16,16,0,1,1,16,16A16,16,0,0,1,76,108Zm72,0a16,16,0,1,1,16,16A16,16,0,0,1,148,108Zm32,50a60.1,60.1,0,0,1-104,0,12,12,0,0,1,20.8-12,36,36,0,0,0,62.4,0A12,12,0,1,1,180,158Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M224,128a97.2,97.2,0,0,1-4.2,28.2l-63.6,63.6A97.2,97.2,0,0,1,128,224a96,96,0,1,1,96-96Z"
            opacity="0.2"
          />
          <path d="M128,24a104,104,0,0,0,0,208,102.2,102.2,0,0,0,30.6-4.6,6.7,6.7,0,0,0,3.3-2l63.5-63.5a7.2,7.2,0,0,0,2-3.3A102.2,102.2,0,0,0,232,128,104.2,104.2,0,0,0,128,24Zm84.7,128L152,212.7A87.9,87.9,0,1,1,212.7,152ZM80,108a12,12,0,1,1,12,12A12,12,0,0,1,80,108Zm72,0a12,12,0,1,1,12,12A12,12,0,0,1,152,108Zm24.5,48a56,56,0,0,1-97,0,8,8,0,1,1,13.8-8,40.1,40.1,0,0,0,69.4,0,8,8,0,0,1,13.8,8Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,24a104,104,0,0,0,0,208,102.2,102.2,0,0,0,30.6-4.6,6.7,6.7,0,0,0,3.3-2l63.5-63.5a7.2,7.2,0,0,0,2-3.3A102.2,102.2,0,0,0,232,128,104.2,104.2,0,0,0,128,24Zm36,72a12,12,0,1,1-12,12A12,12,0,0,1,164,96ZM92,96a12,12,0,1,1-12,12A12,12,0,0,1,92,96Zm84.5,60a56,56,0,0,1-97,0,8,8,0,1,1,13.8-8,40.1,40.1,0,0,0,69.4,0,8,8,0,0,1,13.8,8Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,26a102,102,0,1,0,30,199.5,6.4,6.4,0,0,0,2.5-1.5L224,160.5a6.4,6.4,0,0,0,1.5-2.5A102,102,0,0,0,128,26Zm86.5,127L153,214.5a91.5,91.5,0,0,1-25,3.5,90,90,0,1,1,90-90A91.5,91.5,0,0,1,214.5,153ZM82,108a10,10,0,1,1,10,10A10,10,0,0,1,82,108Zm72,0a10,10,0,1,1,10,10A10,10,0,0,1,154,108Zm20.8,47a54.1,54.1,0,0,1-93.6,0,6,6,0,0,1,2.2-8.2,5.9,5.9,0,0,1,8.2,2.2,42,42,0,0,0,72.8,0,5.9,5.9,0,0,1,8.2-2.2A6,6,0,0,1,174.8,155Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,28a100,100,0,1,0,29.4,195.6,3.4,3.4,0,0,0,1.6-1L222.6,159a3.4,3.4,0,0,0,1-1.6A100.1,100.1,0,0,0,128,28Zm88.2,126.1-62.1,62.1a92.1,92.1,0,1,1,62.1-62.1ZM84,108a8,8,0,1,1,8,8A8,8,0,0,1,84,108Zm72,0a8,8,0,1,1,8,8A8,8,0,0,1,156,108Zm17,46a51.9,51.9,0,0,1-90,0,4,4,0,1,1,6.9-4,44,44,0,0,0,76.2,0,4,4,0,1,1,6.9,4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,24a104,104,0,0,0,0,208,102.2,102.2,0,0,0,30.6-4.6,6.7,6.7,0,0,0,3.3-2l63.5-63.5a7.2,7.2,0,0,0,2-3.3A102.2,102.2,0,0,0,232,128,104.2,104.2,0,0,0,128,24Zm84.7,128L152,212.7A87.9,87.9,0,1,1,212.7,152ZM80,108a12,12,0,1,1,12,12A12,12,0,0,1,80,108Zm72,0a12,12,0,1,1,12,12A12,12,0,0,1,152,108Zm24.5,48a56,56,0,0,1-97,0,8,8,0,1,1,13.8-8,40.1,40.1,0,0,0,69.4,0,8,8,0,0,1,13.8,8Z" />
        </>
      )}
    </svg>
  );
}

export function PhSmileyWink(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,20A108,108,0,1,0,236,128,108.1,108.1,0,0,0,128,20Zm0,192a84,84,0,1,1,84-84A84.1,84.1,0,0,1,128,212ZM76,108a16,16,0,1,1,16,16A16,16,0,0,1,76,108Zm112,0a12,12,0,0,1-12,12H152a12,12,0,0,1,0-24h24A12,12,0,0,1,188,108Zm-8,50a60.1,60.1,0,0,1-104,0,12,12,0,0,1,20.8-12,36,36,0,0,0,62.4,0A12,12,0,1,1,180,158Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M224,128a96,96,0,1,1-96-96A96,96,0,0,1,224,128Z"
            opacity="0.2"
          />
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216ZM80,108a12,12,0,1,1,12,12A12,12,0,0,1,80,108Zm104,0a8,8,0,0,1-8,8H152a8,8,0,0,1,0-16h24A8,8,0,0,1,184,108Zm-7.5,48a56,56,0,0,1-97,0,8,8,0,1,1,13.8-8,40.1,40.1,0,0,0,69.4,0,8,8,0,0,1,13.8,8Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24ZM92,96a12,12,0,1,1-12,12A12,12,0,0,1,92,96Zm84.5,60a56,56,0,0,1-97,0,8,8,0,1,1,13.8-8,40.1,40.1,0,0,0,69.4,0,8,8,0,0,1,13.8,8Zm3.5-40H148a8,8,0,0,1,0-16h32a8,8,0,0,1,0,16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,26A102,102,0,1,0,230,128,102.2,102.2,0,0,0,128,26Zm0,192a90,90,0,1,1,90-90A90.1,90.1,0,0,1,128,218ZM82,108a10,10,0,1,1,10,10A10,10,0,0,1,82,108Zm100,0a6,6,0,0,1-6,6H152a6,6,0,0,1,0-12h24A6,6,0,0,1,182,108Zm-7.2,47a54.1,54.1,0,0,1-93.6,0,6,6,0,0,1,2.2-8.2,5.9,5.9,0,0,1,8.2,2.2,42,42,0,0,0,72.8,0,5.9,5.9,0,0,1,8.2-2.2A6,6,0,0,1,174.8,155Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,28A100,100,0,1,0,228,128,100.2,100.2,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220ZM84,108a8,8,0,1,1,8,8A8,8,0,0,1,84,108Zm96,0a4,4,0,0,1-4,4H152a4,4,0,0,1,0-8h24A4,4,0,0,1,180,108Zm-7,46a51.9,51.9,0,0,1-90,0,4,4,0,1,1,6.9-4,44,44,0,0,0,76.2,0,4,4,0,1,1,6.9,4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216ZM80,108a12,12,0,1,1,12,12A12,12,0,0,1,80,108Zm104,0a8,8,0,0,1-8,8H152a8,8,0,0,1,0-16h24A8,8,0,0,1,184,108Zm-7.5,48a56,56,0,0,1-97,0,8,8,0,1,1,13.8-8,40.1,40.1,0,0,0,69.4,0,8,8,0,0,1,13.8,8Z" />
        </>
      )}
    </svg>
  );
}

export function PhSmileyXEyes(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,20A108,108,0,1,0,236,128,108.1,108.1,0,0,0,128,20Zm0,192a84,84,0,1,1,84-84A84.1,84.1,0,0,1,128,212Zm60.5-92.5a12,12,0,0,1,0,17,12.1,12.1,0,0,1-17,0L164,129l-7.5,7.5a12.1,12.1,0,0,1-17,0,12,12,0,0,1,0-17L147,112l-7.5-7.5a12,12,0,0,1,17-17L164,95l7.5-7.5a12,12,0,0,1,17,17L181,112Zm-72,17a12.1,12.1,0,0,1-17,0L92,129l-7.5,7.5a12.1,12.1,0,0,1-17,0,12,12,0,0,1,0-17L75,112l-7.5-7.5a12,12,0,0,1,17-17L92,95l7.5-7.5a12,12,0,0,1,17,17L109,112l7.5,7.5A12,12,0,0,1,116.5,136.5ZM144,180a16,16,0,1,1-16-16A16,16,0,0,1,144,180Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="128" cy="128" r="96" />
          </g>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Z" />
          <circle cx="128" cy="180" r="12" />
          <path d="M179.3,112l10.4-10.3a8.1,8.1,0,0,0-11.4-11.4L168,100.7,157.7,90.3a8.1,8.1,0,0,0-11.4,11.4L156.7,112l-10.4,10.3a8.1,8.1,0,0,0,0,11.4,8.2,8.2,0,0,0,11.4,0L168,123.3l10.3,10.4a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4Z" />
          <path d="M109.7,90.3a8.1,8.1,0,0,0-11.4,0L88,100.7,77.7,90.3a8.1,8.1,0,0,0-11.4,11.4L76.7,112,66.3,122.3a8.1,8.1,0,0,0,0,11.4,8.2,8.2,0,0,0,11.4,0L88,123.3l10.3,10.4a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4L99.3,112l10.4-10.3A8.1,8.1,0,0,0,109.7,90.3Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm-18.3,98.3a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0L88,123.3,77.7,133.7a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L76.7,112,66.3,101.7A8.1,8.1,0,0,1,77.7,90.3L88,100.7,98.3,90.3a8.1,8.1,0,0,1,11.4,11.4L99.3,112ZM128,192a12,12,0,1,1,12-12A12,12,0,0,1,128,192Zm61.7-69.7a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0L168,123.3l-10.3,10.4a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L156.7,112l-10.4-10.3a8.1,8.1,0,0,1,11.4-11.4L168,100.7l10.3-10.4a8.1,8.1,0,0,1,11.4,11.4L179.3,112Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,26A102,102,0,1,0,230,128,102.2,102.2,0,0,0,128,26Zm0,192a90,90,0,1,1,90-90A90.1,90.1,0,0,1,128,218Zm60.2-117.8L176.5,112l11.7,11.8a5.9,5.9,0,1,1-8.4,8.4L168,120.5l-11.8,11.7a5.9,5.9,0,1,1-8.4-8.4L159.5,112l-11.7-11.8a5.9,5.9,0,0,1,8.4-8.4L168,103.5l11.8-11.7a5.9,5.9,0,0,1,8.4,8.4Zm-80,0L96.5,112l11.7,11.8a5.9,5.9,0,1,1-8.4,8.4L88,120.5,76.2,132.2a5.9,5.9,0,1,1-8.4-8.4L79.5,112,67.8,100.2a5.9,5.9,0,0,1,8.4-8.4L88,103.5,99.8,91.8a5.9,5.9,0,0,1,8.4,8.4ZM138,180a10,10,0,1,1-10-10A10,10,0,0,1,138,180Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,28A100,100,0,1,0,228,128,100.2,100.2,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220ZM186.8,98.8,173.7,112l13.1,13.2a3.9,3.9,0,0,1,0,5.6,3.9,3.9,0,0,1-5.6,0L168,117.7l-13.2,13.1a3.9,3.9,0,0,1-5.6,0,3.9,3.9,0,0,1,0-5.6L162.3,112,149.2,98.8a4,4,0,0,1,5.6-5.6L168,106.3l13.2-13.1a4,4,0,1,1,5.6,5.6Zm-80,0L93.7,112l13.1,13.2a3.9,3.9,0,0,1,0,5.6,3.9,3.9,0,0,1-5.6,0L88,117.7,74.8,130.8a3.9,3.9,0,0,1-5.6,0,3.9,3.9,0,0,1,0-5.6L82.3,112,69.2,98.8a4,4,0,0,1,5.6-5.6L88,106.3l13.2-13.1a4,4,0,0,1,5.6,5.6ZM136,180a8,8,0,1,1-8-8A8,8,0,0,1,136,180Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm12-36a12,12,0,1,1-12-12A12,12,0,0,1,140,180Zm49.7-57.7a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0L168,123.3l-10.3,10.4a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L156.7,112l-10.4-10.3a8.1,8.1,0,0,1,11.4-11.4L168,100.7l10.3-10.4a8.1,8.1,0,0,1,11.4,11.4L179.3,112Zm-80-20.6L99.3,112l10.4,10.3a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0L88,123.3,77.7,133.7a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L76.7,112,66.3,101.7A8.1,8.1,0,0,1,77.7,90.3L88,100.7,98.3,90.3a8.1,8.1,0,0,1,11.4,11.4Z" />
        </>
      )}
    </svg>
  );
}

export function PhSmiley(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,20A108,108,0,1,0,236,128,108.1,108.1,0,0,0,128,20Zm0,192a84,84,0,1,1,84-84A84.1,84.1,0,0,1,128,212ZM76,108a16,16,0,1,1,16,16A16,16,0,0,1,76,108Zm72,0a16,16,0,1,1,16,16A16,16,0,0,1,148,108Zm32,50a60.1,60.1,0,0,1-104,0,12,12,0,0,1,20.8-12,36,36,0,0,0,62.4,0A12,12,0,1,1,180,158Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M224,128a96,96,0,1,1-96-96A96,96,0,0,1,224,128Z"
            opacity="0.2"
          />
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216ZM80,108a12,12,0,1,1,12,12A12,12,0,0,1,80,108Zm72,0a12,12,0,1,1,12,12A12,12,0,0,1,152,108Zm24.5,48a56,56,0,0,1-97,0,8,8,0,1,1,13.8-8,40.1,40.1,0,0,0,69.4,0,8,8,0,0,1,13.8,8Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm36,72a12,12,0,1,1-12,12A12,12,0,0,1,164,96ZM92,96a12,12,0,1,1-12,12A12,12,0,0,1,92,96Zm84.5,60a56,56,0,0,1-97,0,8,8,0,1,1,13.8-8,40.1,40.1,0,0,0,69.4,0,8,8,0,0,1,13.8,8Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,26A102,102,0,1,0,230,128,102.2,102.2,0,0,0,128,26Zm0,192a90,90,0,1,1,90-90A90.1,90.1,0,0,1,128,218ZM82,108a10,10,0,1,1,10,10A10,10,0,0,1,82,108Zm72,0a10,10,0,1,1,10,10A10,10,0,0,1,154,108Zm20.8,47a54.1,54.1,0,0,1-93.6,0,6,6,0,0,1,2.2-8.2,5.9,5.9,0,0,1,8.2,2.2,42,42,0,0,0,72.8,0,5.9,5.9,0,0,1,8.2-2.2A6,6,0,0,1,174.8,155Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,28A100,100,0,1,0,228,128,100.2,100.2,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220ZM84,108a8,8,0,1,1,8,8A8,8,0,0,1,84,108Zm72,0a8,8,0,1,1,8,8A8,8,0,0,1,156,108Zm17,46a51.9,51.9,0,0,1-90,0,4,4,0,1,1,6.9-4,44,44,0,0,0,76.2,0,4,4,0,1,1,6.9,4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216ZM80,108a12,12,0,1,1,12,12A12,12,0,0,1,80,108Zm72,0a12,12,0,1,1,12,12A12,12,0,0,1,152,108Zm24.5,48a56,56,0,0,1-97,0,8,8,0,1,1,13.8-8,40.1,40.1,0,0,0,69.4,0,8,8,0,0,1,13.8,8Z" />
        </>
      )}
    </svg>
  );
}

export function PhSnapchatLogo(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M249.4,186.8a12.2,12.2,0,0,0-6.8-9c-1.4-.6-25.5-12.7-38.5-48.1l16.4-6.6a12,12,0,0,0-9-22.2L198,106.3A163.6,163.6,0,0,1,196,80,68,68,0,0,0,60,80a163.6,163.6,0,0,1-2,26.3l-13.5-5.4a12,12,0,1,0-9,22.2l16.4,6.6C39,165,14.9,177.1,13.4,177.8a12,12,0,0,0-6.7,9,11.7,11.7,0,0,0,3.6,10.6c8.2,7.7,19.3,8.5,29,9.1,5.1.4,10.9.8,13.6,2.3s5.9,6,8.9,10c5.5,7.6,12.4,17,23.5,19.8a25,25,0,0,0,6.6.9c8.2,0,15.8-3.6,22.8-6.8,4.9-2.3,10-4.7,13.3-4.7s8.4,2.4,13.3,4.7c8.8,4.1,18.7,8.7,29.4,5.9s18-12.2,23.5-19.8c3-4,6.3-8.6,8.9-10s8.5-1.9,13.6-2.3c9.7-.6,20.8-1.4,29-9.1A12,12,0,0,0,249.4,186.8Zm-57.8.9c-7.3,4-12.3,10.9-16.8,17-3.2,4.4-7.3,10-10,10.7s-8.7-2.3-13.3-4.5c-7-3.2-14.9-6.9-23.5-6.9s-16.5,3.7-23.5,6.9c-4.6,2.2-10.9,5.1-13.3,4.5s-6.8-6.3-10-10.7c-4.5-6.1-9.5-13-16.8-17-5.7-3.1-12.2-4.2-18.4-4.7,17.2-17,38-48.8,38-103a44,44,0,0,1,88,0c0,54.2,20.8,86,38,103C203.8,183.5,197.3,184.6,191.6,187.7Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M237.5,188.7c-9.3,8.7-29,3.4-40.2,9.5s-17,25.6-29.5,28.8S141,216,128,216s-27.7,14.1-39.8,11-18.5-22.7-29.5-28.8-30.9-.8-40.2-9.5c0,0,53.5-24.8,53.5-108.7a56,56,0,0,1,112,0C184,163.9,237.5,188.7,237.5,188.7Z"
            opacity="0.2"
          />
          <path d="M245.4,187.4a8.1,8.1,0,0,0-4.5-6c-.2,0-12.4-6-24.5-21.3a105.5,105.5,0,0,1-17.3-32.7l19.9-8a8,8,0,0,0-6-14.8l-18,7.2A160.5,160.5,0,0,1,192,80,64,64,0,0,0,64,80a160.5,160.5,0,0,1-3,31.8l-18-7.2a8,8,0,1,0-6,14.8l19.9,8a105.5,105.5,0,0,1-17.3,32.7c-12,15.3-24.2,21.2-24.5,21.3A8,8,0,0,0,13,194.5c7.2,6.7,17.5,7.4,26.6,8,5.7.4,11.7.8,15.2,2.7s6.8,6.7,10.2,11.3c5.4,7.4,11.5,15.8,21.2,18.3a27.7,27.7,0,0,0,5.6.7c7.5,0,14.7-3.4,21.2-6.4s10.8-5.1,15-5.1,9.7,2.6,15,5.1c8.2,3.8,17.4,8.1,26.8,5.7s15.8-10.9,21.2-18.3c3.4-4.6,6.8-9.4,10.2-11.3s9.5-2.3,15.2-2.7c9.1-.6,19.4-1.3,26.6-8A8.1,8.1,0,0,0,245.4,187.4Zm-30-.8c-7.5.5-15.3,1-21.9,4.6s-11,9.8-15.4,15.8-8.2,11.2-12.3,12.3-10-1.9-16-4.7S135.7,208,128,208s-14.9,3.3-21.8,6.6-12.2,5.6-16,4.7-8.3-6.8-12.3-12.3S69,194.8,62.5,191.2s-14.4-4.1-21.9-4.6l-4.2-.3a106.9,106.9,0,0,0,15.1-15.5c8-9.9,16.9-24.7,22.6-45.1h0a8.4,8.4,0,0,0,.5-1.7A171,171,0,0,0,80,80a48,48,0,0,1,96,0,171,171,0,0,0,5.4,43.9,8.4,8.4,0,0,0,.5,1.7h0c5.7,20.5,14.6,35.3,22.6,45.2a106.9,106.9,0,0,0,15.1,15.5Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M243,194.5c-7.2,6.7-17.5,7.4-26.6,8-5.7.4-11.7.8-15.2,2.7s-6.8,6.7-10.2,11.3c-5.4,7.4-11.5,15.8-21.2,18.3s-18.6-1.9-26.8-5.7c-5.3-2.5-10.8-5.1-15-5.1s-9.7,2.6-15,5.1-13.7,6.4-21.2,6.4a27.7,27.7,0,0,1-5.6-.7c-9.7-2.5-15.8-10.9-21.2-18.3-3.4-4.6-6.8-9.4-10.2-11.3s-9.5-2.3-15.2-2.7c-9.1-.6-19.4-1.3-26.6-8a8,8,0,0,1,2.1-13.1c.3-.1,12.5-6,24.5-21.3a105.5,105.5,0,0,0,17.3-32.7l-19.9-8a8,8,0,1,1,6-14.8l18,7.2A160.5,160.5,0,0,0,64,80a64,64,0,0,1,128,0,160.5,160.5,0,0,0,3,31.8l18-7.2a8,8,0,0,1,6,14.8l-19.9,8a105.5,105.5,0,0,0,17.3,32.7c12.1,15.3,24.3,21.3,24.5,21.3a8,8,0,0,1,2.1,13.1Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M243.4,187.7a6.2,6.2,0,0,0-3.4-4.5c-.1,0-12.7-6-25-21.7a109.6,109.6,0,0,1-18.3-35.3l21.5-8.6a6,6,0,1,0-4.4-11.2l-20.2,8.1A158.8,158.8,0,0,1,190,80,62,62,0,0,0,66,80a158.8,158.8,0,0,1-3.6,34.5l-20.2-8.1a6,6,0,0,0-4.4,11.2l21.5,8.6A109.6,109.6,0,0,1,41,161.5c-12.3,15.6-24.8,21.7-25,21.7a6.2,6.2,0,0,0-3.4,4.5,6.3,6.3,0,0,0,1.8,5.4c6.7,6.2,16.1,6.8,25.3,7.4,6,.4,12.2.9,16.1,3s7.3,7,10.8,11.8c5.2,7.1,11.1,15.2,20.1,17.5a19.1,19.1,0,0,0,5.2.7c6.9,0,13.6-3.2,20.2-6.2S123.4,222,128,222s10.3,2.7,15.9,5.3c8.2,3.8,16.7,7.7,25.4,5.5s14.9-10.4,20.1-17.5c3.5-4.8,7.1-9.7,10.8-11.8s10.1-2.6,16.1-3c9.2-.6,18.6-1.2,25.3-7.4A6.3,6.3,0,0,0,243.4,187.7Zm-27.9.9c-7.3.5-14.8,1-21.1,4.4s-10.5,9.4-14.7,15.2-8.6,11.8-13.4,13-10.8-1.7-17.4-4.8S135.3,210,128,210s-14.2,3.2-20.9,6.4-12.9,6-17.4,4.8-9.2-7.2-13.4-13S67.6,196.3,61.6,193s-13.8-3.9-21.1-4.4c-3.1-.2-6.3-.5-9.2-.9a99,99,0,0,0,18.6-18.1c7.9-9.8,16.7-24.4,22.3-44.6h0a7.4,7.4,0,0,0,.4-1.4A168.3,168.3,0,0,0,78,80a50,50,0,0,1,100,0,167.7,167.7,0,0,0,5.4,43.5,7.4,7.4,0,0,0,.4,1.4h0c5.6,20.2,14.4,34.8,22.3,44.6a99,99,0,0,0,18.6,18.1C221.8,188.1,218.6,188.4,215.5,188.6Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M241.4,188.1a3.7,3.7,0,0,0-2.2-3c-.2-.1-13-6.2-25.6-22.1a111.3,111.3,0,0,1-19.4-38l23.3-9.3a4,4,0,0,0-3-7.4l-22.4,8.9A163.3,163.3,0,0,1,188,80,60,60,0,0,0,68,80a163.3,163.3,0,0,1-4.1,37.2l-22.4-8.9a4,4,0,1,0-3,7.4L61.8,125a111.3,111.3,0,0,1-19.4,38C29.8,178.8,17,185,16.8,185.1a3.7,3.7,0,0,0-2.2,2.9,3.9,3.9,0,0,0,1.2,3.6c6.1,5.8,15.2,6.4,24,6.9,6.2.5,12.7.9,16.9,3.2s7.8,7.2,11.5,12.4c5.3,7.2,10.7,14.7,19,16.8a18.9,18.9,0,0,0,4.7.6c6.5,0,13-3.1,19.4-6.1S123,220,128,220s11,2.8,16.7,5.4c7.9,3.7,16.1,7.5,24.1,5.5s13.7-9.6,19-16.8c3.7-5.2,7.3-10.1,11.5-12.4s10.7-2.7,16.9-3.2c8.8-.5,17.9-1.1,24-6.9A3.8,3.8,0,0,0,241.4,188.1Zm-25.8,2.5c-7,.4-14.4.9-20.2,4.1s-10,9-14.1,14.7-9,12.3-14.5,13.7-11.8-1.7-18.7-4.9S134.8,212,128,212s-13.6,3.1-20.1,6.2-13.5,6.3-18.7,4.9-9.9-7.4-14.5-13.7-8.4-11.5-14.1-14.7-13.2-3.7-20.2-4.1c-5.1-.4-10-.7-13.9-2a94.3,94.3,0,0,0,21.9-20.3,122.1,122.1,0,0,0,21.9-44h.1a4.4,4.4,0,0,0,.2-1A165.9,165.9,0,0,0,76,80a52,52,0,0,1,104,0,165.3,165.3,0,0,0,5.4,43.2,4.4,4.4,0,0,0,.2,1h.1a122.1,122.1,0,0,0,21.9,44,94.3,94.3,0,0,0,21.9,20.3C225.6,189.9,220.7,190.2,215.6,190.6Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M245.4,187.4a8.1,8.1,0,0,0-4.5-6c-.2,0-12.4-6-24.5-21.3a105.5,105.5,0,0,1-17.3-32.7l19.9-8a8,8,0,0,0-6-14.8l-18,7.2A160.5,160.5,0,0,1,192,80,64,64,0,0,0,64,80a160.5,160.5,0,0,1-3,31.8l-18-7.2a8,8,0,1,0-6,14.8l19.9,8a105.5,105.5,0,0,1-17.3,32.7c-12,15.2-24.1,21.2-24.5,21.3A8,8,0,0,0,13,194.5c7.2,6.7,17.5,7.4,26.6,8,5.7.4,11.7.8,15.2,2.7s6.8,6.7,10.2,11.3c5.4,7.4,11.5,15.8,21.2,18.3a27.7,27.7,0,0,0,5.6.7c7.5,0,14.7-3.4,21.2-6.4s10.8-5.1,15-5.1,9.7,2.6,15,5.1c8.2,3.8,17.4,8.1,26.8,5.7s15.8-10.9,21.2-18.3c3.4-4.6,6.8-9.4,10.2-11.3s9.5-2.3,15.2-2.7c9.1-.6,19.4-1.3,26.6-8A8.1,8.1,0,0,0,245.4,187.4Zm-30-.8c-7.5.5-15.3,1-21.9,4.6s-11,9.8-15.4,15.8-8.2,11.2-12.3,12.3-10-1.9-16-4.7S135.7,208,128,208s-14.9,3.3-21.8,6.6-12.2,5.6-16,4.7-8.3-6.8-12.3-12.3S69,194.8,62.5,191.2s-14.4-4.1-21.9-4.6l-4.2-.3a106.9,106.9,0,0,0,15.1-15.5c8-9.9,16.9-24.7,22.6-45.1h0a8.4,8.4,0,0,0,.5-1.7A171,171,0,0,0,80,80a48,48,0,0,1,96,0,171,171,0,0,0,5.4,43.9,8.4,8.4,0,0,0,.5,1.7h0c5.7,20.5,14.6,35.3,22.6,45.2a106.9,106.9,0,0,0,15.1,15.5Z" />
        </>
      )}
    </svg>
  );
}

export function PhSnowflake(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M227.8,148.1a12,12,0,0,1-8.5,14.7l-21.2,5.7,5.7,21.2a12,12,0,0,1-8.5,14.7,11,11,0,0,1-3.1.4,12,12,0,0,1-11.6-8.9l-7.5-28L140,148.8V187l20.5,20.5a12,12,0,0,1,0,17,12.1,12.1,0,0,1-17,0L128,209l-15.5,15.5a12,12,0,0,1-17-17L116,187V148.8L82.9,167.9l-7.5,28a12,12,0,0,1-11.6,8.9,11,11,0,0,1-3.1-.4,12,12,0,0,1-8.5-14.7l5.7-21.2-21.2-5.7a12,12,0,1,1,6.2-23.2l28,7.5L104,128,70.9,108.9l-28,7.5a11,11,0,0,1-3.1.4,12,12,0,0,1-3.1-23.6l21.2-5.7L52.2,66.3a12,12,0,0,1,23.2-6.2l7.5,28L116,107.2V69L95.5,48.5a12,12,0,0,1,17-17L128,47l15.5-15.5a12,12,0,0,1,17,17L140,69v38.2l33.1-19.1,7.5-28a12,12,0,1,1,23.2,6.2l-5.7,21.2,21.2,5.7a12,12,0,0,1-3.1,23.6,11,11,0,0,1-3.1-.4l-28-7.5L152,128l33.1,19.1,28-7.5A12,12,0,0,1,227.8,148.1Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M223.9,149.1a7.9,7.9,0,0,1-5.6,9.8l-25.1,6.8,6.7,25a7.9,7.9,0,0,1-5.6,9.8l-2.1.3a8,8,0,0,1-7.7-5.9l-7.9-29.6L136,141.9v46.8l21.7,21.6a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0L128,203.3l-18.3,18.4a8.1,8.1,0,0,1-11.4-11.4L120,188.7V141.9L79.4,165.3l-7.9,29.6a8,8,0,0,1-7.7,5.9l-2.1-.3a7.9,7.9,0,0,1-5.6-9.8l6.7-25-25.1-6.8a8,8,0,1,1,4.2-15.4l29.5,7.9L112,128,71.4,104.6l-29.5,7.9a7.6,7.6,0,0,1-2.1.3,8,8,0,0,1-2.1-15.7l25.1-6.8-6.7-25a8,8,0,0,1,15.4-4.2l7.9,29.6L120,114.1V67.3L98.3,45.7a8.1,8.1,0,0,1,11.4-11.4L128,52.7l18.3-18.4a8.1,8.1,0,0,1,11.4,11.4L136,67.3v46.8l40.6-23.4,7.9-29.6a8,8,0,1,1,15.4,4.2l-6.7,25,25.1,6.8a8,8,0,0,1-2.1,15.7l-2.1-.3-29.5-7.9L144,128l40.6,23.4,29.5-7.9A7.9,7.9,0,0,1,223.9,149.1Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M223.9,149.1a7.9,7.9,0,0,1-5.6,9.8l-25.1,6.8,6.7,25a7.9,7.9,0,0,1-5.6,9.8l-2.1.3a8,8,0,0,1-7.7-5.9l-7.9-29.6L136,141.9v46.8l21.7,21.6a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0L128,203.3l-18.3,18.4a8.1,8.1,0,0,1-11.4-11.4L120,188.7V141.9L79.4,165.3l-7.9,29.6a8,8,0,0,1-7.7,5.9l-2.1-.3a7.9,7.9,0,0,1-5.6-9.8l6.7-25-25.1-6.8a8,8,0,1,1,4.2-15.4l29.5,7.9L112,128,71.4,104.6l-29.5,7.9a7.6,7.6,0,0,1-2.1.3,8,8,0,0,1-2.1-15.7l25.1-6.8-6.7-25a8,8,0,0,1,15.4-4.2l7.9,29.6L120,114.1V67.3L98.3,45.7a8.1,8.1,0,0,1,11.4-11.4L128,52.7l18.3-18.4a8.1,8.1,0,0,1,11.4,11.4L136,67.3v46.8l40.6-23.4,7.9-29.6a8,8,0,1,1,15.4,4.2l-6.7,25,25.1,6.8a8,8,0,0,1-2.1,15.7l-2.1-.3-29.5-7.9L144,128l40.6,23.4,29.5-7.9A7.9,7.9,0,0,1,223.9,149.1Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M222,149.7a5.9,5.9,0,0,1-4.2,7.3l-27,7.2,7.2,27a6,6,0,0,1-4.2,7.4l-1.6.2a6.1,6.1,0,0,1-5.8-4.5L178.3,164,134,138.4v51.1l22.2,22.3a5.9,5.9,0,1,1-8.4,8.4L128,200.5l-19.8,19.7a5.9,5.9,0,0,1-8.4-8.4L122,189.5V138.4L77.7,164l-8.1,30.3a6.1,6.1,0,0,1-5.8,4.5l-1.6-.2a6,6,0,0,1-4.2-7.4l7.2-27-27-7.2a5.9,5.9,0,0,1-4.2-7.3,6.1,6.1,0,0,1,7.3-4.3l30.4,8.2L116,128,71.7,102.4l-30.4,8.2-1.5.2a6,6,0,0,1-5.8-4.5A5.9,5.9,0,0,1,38.2,99l27-7.2L58,64.8a6,6,0,0,1,4.2-7.4,6.2,6.2,0,0,1,7.4,4.3L77.7,92,122,117.6V66.5L99.8,44.2a5.9,5.9,0,0,1,8.4-8.4L128,55.5l19.8-19.7a5.9,5.9,0,0,1,8.4,8.4L134,66.5v51.1L178.3,92l8.1-30.3a6.2,6.2,0,0,1,7.4-4.3,6,6,0,0,1,4.2,7.4l-7.2,27,27,7.2a5.9,5.9,0,0,1,4.2,7.3,6,6,0,0,1-5.8,4.5l-1.5-.2-30.4-8.2L140,128l44.3,25.6,30.4-8.2A6.1,6.1,0,0,1,222,149.7Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M220.1,150.2a4.1,4.1,0,0,1-2.9,4.9l-28.9,7.7,7.8,28.9a4,4,0,0,1-2.9,4.9l-1,.2a4.1,4.1,0,0,1-3.9-3L180,162.6l-48-27.7v55.4l22.8,22.9a3.9,3.9,0,0,1,0,5.6,3.9,3.9,0,0,1-5.6,0L128,197.7l-21.2,21.1a4,4,0,0,1-5.6-5.6L124,190.3V134.9L76,162.6l-8.3,31.2a4.1,4.1,0,0,1-3.9,3l-1-.2a4,4,0,0,1-2.9-4.9l7.8-28.9-28.9-7.7a4.1,4.1,0,0,1-2.9-4.9,3.9,3.9,0,0,1,4.9-2.8L72,155.7,120,128,72,100.3l-31.2,8.3-1,.2a4,4,0,0,1-1-7.9l28.9-7.7L59.9,64.3a4,4,0,0,1,2.9-4.9,3.9,3.9,0,0,1,4.9,2.8L76,93.4l48,27.7V65.7L101.2,42.8a4,4,0,0,1,5.6-5.6L128,58.3l21.2-21.1a4,4,0,1,1,5.6,5.6L132,65.7v55.4l48-27.7,8.3-31.2a3.9,3.9,0,0,1,4.9-2.8,4,4,0,0,1,2.9,4.9l-7.8,28.9,28.9,7.7a4,4,0,0,1-1,7.9l-1-.2L184,100.3,136,128l48,27.7,31.2-8.3A3.9,3.9,0,0,1,220.1,150.2Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M223.9,149.1a7.9,7.9,0,0,1-5.6,9.8l-25.1,6.8,6.7,25a7.9,7.9,0,0,1-5.6,9.8l-2.1.3a8,8,0,0,1-7.7-5.9l-7.9-29.6L136,141.9v46.8l21.7,21.6a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0L128,203.3l-18.3,18.4a8.1,8.1,0,0,1-11.4-11.4L120,188.7V141.9L79.4,165.3l-7.9,29.6a8,8,0,0,1-7.7,5.9l-2.1-.3a7.9,7.9,0,0,1-5.6-9.8l6.7-25-25.1-6.8a8,8,0,1,1,4.2-15.4l29.5,7.9L112,128,71.4,104.6l-29.5,7.9a7.6,7.6,0,0,1-2.1.3,8,8,0,0,1-2.1-15.7l25.1-6.8-6.7-25a8,8,0,0,1,15.4-4.2l7.9,29.6L120,114.1V67.3L98.3,45.7a8.1,8.1,0,0,1,11.4-11.4L128,52.7l18.3-18.4a8.1,8.1,0,0,1,11.4,11.4L136,67.3v46.8l40.6-23.4,7.9-29.6a8,8,0,1,1,15.4,4.2l-6.7,25,25.1,6.8a8,8,0,0,1-2.1,15.7l-2.1-.3-29.5-7.9L144,128l40.6,23.4,29.5-7.9A7.9,7.9,0,0,1,223.9,149.1Z" />
        </>
      )}
    </svg>
  );
}

export function PhSoccerBall(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M236,128A108,108,0,1,0,63.8,214.8l.7.6.9.5a107.5,107.5,0,0,0,125.2,0l.9-.5.7-.6A107.8,107.8,0,0,0,236,128Zm-49.5,60.2L173,169.5l15.8-48.7,22-7.1a84.1,84.1,0,0,1-24.3,74.5ZM105,159.7,90.8,115.9l37.2-27,37.2,27L151,159.7Zm98.3-68.8L181.4,98,140,67.9v-23A84.2,84.2,0,0,1,203.3,90.9ZM116,44.9v23L74.6,98,52.7,90.9A84.2,84.2,0,0,1,116,44.9ZM44,128a84.5,84.5,0,0,1,1.2-14.3l22,7.1L83,169.5,69.5,188.2A83.8,83.8,0,0,1,44,128Zm44.9,74.3,13.5-18.6h51.2l13.5,18.6a83.7,83.7,0,0,1-78.2,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M103.3,162,88.1,115,128,86l39.9,29-15.2,47ZM128,64l35-25.4h0a96.1,96.1,0,0,0-70,0h0ZM53.8,67.1h0A95.5,95.5,0,0,0,36.7,98.3a98.4,98.4,0,0,0-4.6,35.4h0l35-25.5ZM47.1,179.8h0a97,97,0,0,0,24.5,25.9,95.9,95.9,0,0,0,32.2,15.2h0L90.4,179.8Zm118.5,0-13.4,41.1h0a95.9,95.9,0,0,0,32.2-15.2,97,97,0,0,0,24.5-25.9H165.6Zm53.7-81.5a95.5,95.5,0,0,0-17.1-31.2h0l-13.3,41.1,35,25.5h0A98.4,98.4,0,0,0,219.3,98.3Z"
            opacity="0.2"
          />
          <path d="M231.8,134.8a4.8,4.8,0,0,0,0-1.2c.1-1.9.2-3.7.2-5.6a103.2,103.2,0,0,0-23-65.1,5.5,5.5,0,0,0-1.4-1.7,103.9,103.9,0,0,0-41.1-29.8l-1.1-.4a103.4,103.4,0,0,0-74.8,0l-1.1.4A103.9,103.9,0,0,0,48.4,61.2,5.5,5.5,0,0,0,47,62.9,103.2,103.2,0,0,0,24,128c0,1.9.1,3.7.2,5.6a4.8,4.8,0,0,0,0,1.2,104.2,104.2,0,0,0,15.7,48.4,9.9,9.9,0,0,0,1.1,1.7,104.3,104.3,0,0,0,60.3,43.6h.3a104.2,104.2,0,0,0,52.8,0h.3A104.3,104.3,0,0,0,215,184.9a9.9,9.9,0,0,0,1.1-1.7A104.2,104.2,0,0,0,231.8,134.8ZM40.5,137.5l28-20.4,13.2,4.3,12.7,39.2-8.1,11.2H51.7A86.2,86.2,0,0,1,40.5,137.5ZM51.2,85.1l6.5,20.1L40.6,117.6A87.6,87.6,0,0,1,51.2,85.1ZM109.1,154,97.5,118.1,128,95.9l30.5,22.2L146.9,154Zm60.6,17.8-8.1-11.2,12.7-39.2,13.2-4.3,28,20.4a86.2,86.2,0,0,1-11.2,34.3Zm45.7-54.2-17.1-12.4,6.5-20.1A87.6,87.6,0,0,1,215.4,117.6ZM193.2,69l-10.7,32.9-13.2,4.3L136,81.9V68.1l28-20.4A87,87,0,0,1,193.2,69ZM145.1,41.7,128,54.1,110.9,41.7a86.9,86.9,0,0,1,34.2,0ZM92,47.7l28,20.4V81.9L86.7,106.2l-13.2-4.3L62.8,69A87,87,0,0,1,92,47.7ZM63.5,187.8H84.6l6.5,20.1A89.2,89.2,0,0,1,63.5,187.8ZM110,214.1,99.3,181.2l8.1-11.2h41.2l8.1,11.2L146,214.1a86.2,86.2,0,0,1-36,0Zm54.9-6.2,6.5-20.1h21.1A89.2,89.2,0,0,1,164.9,207.9Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M231.8,134.8a4.8,4.8,0,0,0,0-1.2c.1-1.9.2-3.7.2-5.6a103.2,103.2,0,0,0-23-65.1,5.5,5.5,0,0,0-1.4-1.7,103.9,103.9,0,0,0-41.1-29.8l-1.1-.4a103.4,103.4,0,0,0-74.8,0l-1.1.4A103.9,103.9,0,0,0,48.4,61.2,5.5,5.5,0,0,0,47,62.9,103.2,103.2,0,0,0,24,128c0,1.9.1,3.7.2,5.6a4.8,4.8,0,0,0,0,1.2,104.2,104.2,0,0,0,15.7,48.4,9.9,9.9,0,0,0,1.1,1.7,104.3,104.3,0,0,0,60.3,43.6h.3a104.2,104.2,0,0,0,52.8,0h.3A104.3,104.3,0,0,0,215,184.9a9.9,9.9,0,0,0,1.1-1.7A104.2,104.2,0,0,0,231.8,134.8ZM68.5,117.1l13.2,4.3,12.7,39.2-8.1,11.2H51.7a86.2,86.2,0,0,1-11.2-34.3Zm119,0,28,20.4a86.2,86.2,0,0,1-11.2,34.3H169.7l-8.1-11.2,12.7-39.2ZM193.2,69l-10.7,32.9-13.2,4.3L136,81.9V68.1l28-20.4A87,87,0,0,1,193.2,69ZM92,47.7l28,20.4V81.9L86.7,106.2l-13.2-4.3L62.8,69A87,87,0,0,1,92,47.7Zm18,166.4L99.3,181.2l8.1-11.2h41.2l8.1,11.2L146,214.1a86.2,86.2,0,0,1-36,0Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M229.8,134.6a4.1,4.1,0,0,0,0-1.1c.1-1.8.2-3.6.2-5.5a101.8,101.8,0,0,0-22.6-64,5.3,5.3,0,0,0-1.2-1.4,102.4,102.4,0,0,0-40.5-29.4l-1-.4a102.4,102.4,0,0,0-73.4,0l-1,.4A102.4,102.4,0,0,0,49.8,62.6,5.3,5.3,0,0,0,48.6,64,101.8,101.8,0,0,0,26,128c0,1.9.1,3.7.2,5.5a4.1,4.1,0,0,0,0,1.1,101.7,101.7,0,0,0,15.5,47.7,5.5,5.5,0,0,0,.9,1.4,102.1,102.1,0,0,0,59.3,42.9h.4a101.7,101.7,0,0,0,51.4,0h.4a102.1,102.1,0,0,0,59.3-42.9,5.5,5.5,0,0,0,.9-1.4A101.7,101.7,0,0,0,229.8,134.6Zm-61.1,39.2-9.4-12.9,13.4-41.1,15.1-4.9,29.8,21.6a89.7,89.7,0,0,1-12.1,37.3Zm-81.4,0H50.5a89.7,89.7,0,0,1-12.1-37.3l29.8-21.6,15.1,4.9,13.4,41.1ZM51.7,80.2l8.4,25.7L38.2,121.8A90.2,90.2,0,0,1,51.7,80.2Zm56,75.8L95.1,117.3,128,93.4l32.9,23.9L148.3,156Zm96.5-75.8a89.2,89.2,0,0,1,13.6,41.6l-21.9-15.9Zm-8.8-11.7-11.3,35L169,108.4,134,82.9V67.1l29.8-21.7A89.8,89.8,0,0,1,195.4,68.5ZM149.9,40.7,128,56.6,106.1,40.7a90.2,90.2,0,0,1,43.8,0ZM92.2,45.4,122,67.1V82.9L87,108.4l-15.1-4.9-11.3-35A89.8,89.8,0,0,1,92.2,45.4ZM59,185.8H86l8.4,25.7A91.7,91.7,0,0,1,59,185.8Zm49.4,30.1L97,180.8l9.4-12.8h43.2l9.4,12.8-11.4,35.1a92.5,92.5,0,0,1-39.2,0Zm53.2-4.4,8.4-25.7h27A91.7,91.7,0,0,1,161.6,211.5Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M227.8,134.2v-.6c.1-1.9.2-3.7.2-5.6a99.8,99.8,0,0,0-22.3-62.9,3.1,3.1,0,0,0-.9-1.1,100.2,100.2,0,0,0-40.1-29l-.5-.2a99.8,99.8,0,0,0-72.4,0l-.5.2A100.2,100.2,0,0,0,51.2,64a3.1,3.1,0,0,0-.9,1.1A99.8,99.8,0,0,0,28,128c0,1.9.1,3.7.2,5.6v.6a99.8,99.8,0,0,0,15.2,47.1,4.1,4.1,0,0,0,.7,1.1,100.6,100.6,0,0,0,58.4,42.3h.5a99.3,99.3,0,0,0,50,0h.5a100.6,100.6,0,0,0,58.4-42.3,4.1,4.1,0,0,0,.7-1.1A99.8,99.8,0,0,0,227.8,134.2Zm-60.1,41.6-10.6-14.5,14-43.1,17.1-5.5,31.5,22.9a91,91,0,0,1-13.1,40.2Zm-79.4,0H49.4a91,91,0,0,1-13.1-40.2l31.5-22.9,17.1,5.5,14,43.1ZM52.4,75.7l10,31L36,125.9A90.8,90.8,0,0,1,52.4,75.7ZM106.2,158,92.8,116.5,128,90.9l35.2,25.6L149.8,158ZM220,125.9l-26.4-19.2,10-31A90.8,90.8,0,0,1,220,125.9ZM197.7,68l-12,37-17.1,5.6L132,84V66l31.5-22.9A92.5,92.5,0,0,1,197.7,68ZM154.4,39.9,128,59.1,101.6,39.9a91.3,91.3,0,0,1,52.8,0ZM92.5,43.1,124,66V84L87.4,110.6,70.3,105l-12-37A92.5,92.5,0,0,1,92.5,43.1ZM54.9,183.8H87.5l10.1,31A92.4,92.4,0,0,1,54.9,183.8Zm52,33.8L94.8,180.5,105.4,166h45.2l10.6,14.5-12.1,37.1a94,94,0,0,1-42.2,0Zm51.5-2.8,10.1-31h32.6A92.4,92.4,0,0,1,158.4,214.8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M231.8,134.8a4.8,4.8,0,0,0,0-1.2c.1-1.9.2-3.7.2-5.6a103.2,103.2,0,0,0-23-65.1,5.5,5.5,0,0,0-1.4-1.7,103.9,103.9,0,0,0-41.1-29.8l-1.1-.4a103.4,103.4,0,0,0-74.8,0l-1.1.4A103.9,103.9,0,0,0,48.4,61.2,5.5,5.5,0,0,0,47,62.9,103.2,103.2,0,0,0,24,128c0,1.9.1,3.7.2,5.6a4.8,4.8,0,0,0,0,1.2,104.2,104.2,0,0,0,15.7,48.4,9.9,9.9,0,0,0,1.1,1.7,104.3,104.3,0,0,0,60.3,43.6h.3a104.2,104.2,0,0,0,52.8,0h.3A104.3,104.3,0,0,0,215,184.9a9.9,9.9,0,0,0,1.1-1.7A104.2,104.2,0,0,0,231.8,134.8ZM40.5,137.5l28-20.4,13.2,4.3,12.7,39.2-8.1,11.2H51.7A86.2,86.2,0,0,1,40.5,137.5ZM51.2,85.1l6.5,20.1L40.6,117.6A87.6,87.6,0,0,1,51.2,85.1ZM109.1,154,97.5,118.1,128,95.9l30.5,22.2L146.9,154Zm60.6,17.8-8.1-11.2,12.7-39.2,13.2-4.3,28,20.4a86.2,86.2,0,0,1-11.2,34.3Zm45.7-54.2-17.1-12.4,6.5-20.1A87.6,87.6,0,0,1,215.4,117.6ZM193.2,69l-10.7,32.9-13.2,4.3L136,81.9V68.1l28-20.4A87,87,0,0,1,193.2,69ZM145.1,41.7,128,54.1,110.9,41.7a86.9,86.9,0,0,1,34.2,0ZM92,47.7l28,20.4V81.9L86.7,106.2l-13.2-4.3L62.8,69A87,87,0,0,1,92,47.7ZM63.5,187.8H84.6l6.5,20.1A89.2,89.2,0,0,1,63.5,187.8ZM110,214.1,99.3,181.2l8.1-11.2h41.2l8.1,11.2L146,214.1a86.2,86.2,0,0,1-36,0Zm54.9-6.2,6.5-20.1h21.1A89.2,89.2,0,0,1,164.9,207.9Z" />
        </>
      )}
    </svg>
  );
}

export function PhSortAscending(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M232.5,176.5l-40,40h-.1l-.8.7-.4.3-.5.4-.6.3-.4.3-.6.2-.5.3-.5.2-.6.2h-.6l-.6.2h-4.6l-.6-.2h-.6l-.6-.2-.5-.2-.5-.3-.6-.2-.4-.3-.6-.3-.5-.4-.4-.3-.8-.8h-.1l-40-40a12,12,0,0,1,17-17L172,179V112a12,12,0,0,1,24,0v67l19.5-19.5a12,12,0,0,1,17,17ZM120,116H48a12,12,0,0,0,0,24h72a12,12,0,0,0,0-24ZM48,76H184a12,12,0,0,0,0-24H48a12,12,0,0,0,0,24Zm56,104H48a12,12,0,0,0,0,24h56a12,12,0,0,0,0-24Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M229.7,173.7l-40,40h-.1c-.1.2-.3.3-.5.5l-.3.2-.4.3-.3.2-.3.2h-.4l-.3.2h-.4l-.4.2h-4.6l-.4-.2h-.4l-.3-.2h-.4l-.3-.2-.3-.2-.4-.3-.3-.2-.5-.5h-.1l-40-40a8.1,8.1,0,0,1,11.4-11.4L176,188.7V112a8,8,0,0,1,16,0v76.7l26.3-26.4a8.1,8.1,0,0,1,11.4,11.4ZM120,120H48a8,8,0,0,0,0,16h72a8,8,0,0,0,0-16ZM48,72H184a8,8,0,0,0,0-16H48a8,8,0,0,0,0,16Zm56,112H48a8,8,0,0,0,0,16h56a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M229.7,173.7l-40,40a8.2,8.2,0,0,1-11.4,0l-40-40a8.4,8.4,0,0,1-1.7-8.8A8,8,0,0,1,144,160h32V112a8,8,0,0,1,16,0v48h32a8.1,8.1,0,0,1,7.4,4.9A8.4,8.4,0,0,1,229.7,173.7ZM120,120H48a8,8,0,0,0,0,16h72a8,8,0,0,0,0-16ZM48,72H184a8,8,0,0,0,0-16H48a8,8,0,0,0,0,16Zm56,112H48a8,8,0,0,0,0,16h56a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M228.2,172.3l-40,39.9h0a2.4,2.4,0,0,1-.9.8h0l-1,.5h-.1l-1,.3h-2.4l-1-.3h-.1l-1-.5h0a2.4,2.4,0,0,1-.9-.8l-40-40a5.9,5.9,0,0,1,8.4-8.4L178,193.5V112a6,6,0,0,1,12,0v81.5l29.8-29.7a5.8,5.8,0,0,1,8.4,0A5.9,5.9,0,0,1,228.2,172.3ZM120,122H48a6,6,0,0,0,0,12h72a6,6,0,0,0,0-12ZM48,70H184a6,6,0,0,0,0-12H48a6,6,0,0,0,0,12Zm56,116H48a6,6,0,0,0,0,12h56a6,6,0,0,0,0-12Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M226.8,170.8l-40,40h0l-.6.4-.3.2-.4.2h-3l-.4-.2-.3-.2-.6-.5-40-40a4,4,0,0,1,5.6-5.6L180,198.3V112a4,4,0,0,1,8,0v86.3l33.2-33.1a4,4,0,0,1,5.6,5.6ZM120,124H48a4,4,0,0,0,0,8h72a4,4,0,0,0,0-8ZM48,68H184a4,4,0,0,0,0-8H48a4,4,0,0,0,0,8Zm56,120H48a4,4,0,0,0,0,8h56a4,4,0,0,0,0-8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M229.7,173.7l-40,40h-.1c-.1.2-.3.3-.5.5l-.3.2-.4.3-.3.2-.3.2h-.4l-.3.2h-.4l-.4.2h-4.6l-.4-.2h-.4l-.3-.2h-.4l-.3-.2-.3-.2-.4-.3-.3-.2-.5-.5h-.1l-40-40a8.1,8.1,0,0,1,11.4-11.4L176,188.7V112a8,8,0,0,1,16,0v76.7l26.3-26.4a8.1,8.1,0,0,1,11.4,11.4ZM120,120H48a8,8,0,0,0,0,16h72a8,8,0,0,0,0-16ZM48,72H184a8,8,0,0,0,0-16H48a8,8,0,0,0,0,16Zm56,112H48a8,8,0,0,0,0,16h56a8,8,0,0,0,0-16Z" />
        </>
      )}
    </svg>
  );
}

export function PhSortDescending(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M232.5,96.5a12,12,0,0,1-17,0L196,77v67a12,12,0,0,1-24,0V77L152.5,96.5a12.1,12.1,0,0,1-17,0,12,12,0,0,1,0-17l40-40h.1l.8-.8.4-.3.5-.4.6-.3.4-.3.6-.2.5-.3.5-.2.6-.2h.6l.6-.2h4.6l.6.2h.6l.5.2.6.2.5.2.6.3.4.3.6.3.4.4.5.3.8.7a.1.1,0,0,0,.1.1l40,40A12,12,0,0,1,232.5,96.5ZM48,140h72a12,12,0,0,0,0-24H48a12,12,0,0,0,0,24Zm0-64h56a12,12,0,0,0,0-24H48a12,12,0,0,0,0,24ZM184,180H48a12,12,0,0,0,0,24H184a12,12,0,0,0,0-24Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M229.7,93.6a8.1,8.1,0,0,1-11.4.1L192,67.3V144a8,8,0,0,1-16,0V67.3L149.7,93.7a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4l40-40h.1c.2-.2.4-.3.5-.5l.3-.2.4-.3.3-.2.3-.2h.4l.3-.2h.4l.4-.2h4.6l.4.2h.4l.3.2h.4l.3.2.3.2.4.3.3.2c.2.2.4.3.5.5h.1l40,40A8,8,0,0,1,229.7,93.6ZM48,136h72a8,8,0,0,0,0-16H48a8,8,0,0,0,0,16Zm0-64h56a8,8,0,0,0,0-16H48a8,8,0,0,0,0,16ZM184,184H48a8,8,0,0,0,0,16H184a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M231.4,91.1A8.1,8.1,0,0,1,224,96H192v48a8,8,0,0,1-16,0V96H144a8,8,0,0,1-7.4-4.9,8.4,8.4,0,0,1,1.7-8.8l40-40a8.1,8.1,0,0,1,11.4,0l40,40A8.4,8.4,0,0,1,231.4,91.1ZM48,136h72a8,8,0,0,0,0-16H48a8,8,0,0,0,0,16Zm0-64h56a8,8,0,0,0,0-16H48a8,8,0,0,0,0,16ZM184,184H48a8,8,0,0,0,0,16H184a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M228.2,92.2a5.8,5.8,0,0,1-8.4,0L190,62.5V144a6,6,0,0,1-12,0V62.5L148.2,92.2a5.9,5.9,0,0,1-8.4-8.4l40-40a2.4,2.4,0,0,1,.9-.8h0l1-.5a.1.1,0,0,1,.1-.1l1-.3h2.4l1,.3a.1.1,0,0,1,.1.1l1,.5h0a2.4,2.4,0,0,1,.9.8h0l40,40A5.8,5.8,0,0,1,228.2,92.2ZM48,134h72a6,6,0,0,0,0-12H48a6,6,0,0,0,0,12Zm0-64h56a6,6,0,0,0,0-12H48a6,6,0,0,0,0,12ZM184,186H48a6,6,0,0,0,0,12H184a6,6,0,0,0,0-12Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M226.8,90.8a3.9,3.9,0,0,1-5.6,0L188,57.7V144a4,4,0,0,1-8,0V57.7L146.8,90.8a3.9,3.9,0,0,1-5.6,0,3.9,3.9,0,0,1,0-5.6l40-40,.6-.5.3-.2.4-.2h3l.4.2.3.2.6.4h0l40,40A3.9,3.9,0,0,1,226.8,90.8ZM48,132h72a4,4,0,0,0,0-8H48a4,4,0,0,0,0,8Zm0-64h56a4,4,0,0,0,0-8H48a4,4,0,0,0,0,8ZM184,188H48a4,4,0,0,0,0,8H184a4,4,0,0,0,0-8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M229.7,93.6a8.1,8.1,0,0,1-11.4.1L192,67.3V144a8,8,0,0,1-16,0V67.3L149.7,93.7a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4l40-40h.1c.2-.2.4-.3.5-.5l.3-.2.4-.3.3-.2.3-.2h.4l.3-.2h.4l.4-.2h4.6l.4.2h.4l.3.2h.4l.3.2.3.2.4.3.3.2c.2.2.4.3.5.5h.1l40,40A8,8,0,0,1,229.7,93.6ZM48,136h72a8,8,0,0,0,0-16H48a8,8,0,0,0,0,16Zm0-64h56a8,8,0,0,0,0-16H48a8,8,0,0,0,0,16ZM184,184H48a8,8,0,0,0,0,16H184a8,8,0,0,0,0-16Z" />
        </>
      )}
    </svg>
  );
}

export function PhSpade(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M186,53.6a450.1,450.1,0,0,0-51.5-39.7,12.2,12.2,0,0,0-13,0A450.1,450.1,0,0,0,70,53.6C34.2,85.8,16,114.9,16,140a64.1,64.1,0,0,0,64,64,57,57,0,0,0,9.2-.7l-5,26.5A12,12,0,0,0,96,244h64a12,12,0,0,0,11.8-14.2l-5-26.5a57,57,0,0,0,9.2.7,64.1,64.1,0,0,0,64-64C240,114.9,221.8,85.8,186,53.6ZM176,180a39.4,39.4,0,0,1-19-4.8A12.1,12.1,0,0,0,139.5,188l6,32h-35l6-32A12.1,12.1,0,0,0,99,175.2,39.4,39.4,0,0,1,80,180a40,40,0,0,1-40-40c0-17.5,16.3-41.7,45.8-68.4A430.5,430.5,0,0,1,128,38.5a424.4,424.4,0,0,1,42,32.9c29.7,26.7,46,51.1,46,68.6A40,40,0,0,1,176,180Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M128,24S228,88,228,140a52,52,0,0,1-85.4,39.9L160,232H96l17.4-52.1A52,52,0,0,1,28,140C28,88,128,24,128,24Z"
            opacity="0.2"
          />
          <path d="M160,240H96a7.9,7.9,0,0,1-6.5-3.3,8.1,8.1,0,0,1-1.1-7.2l10.9-32.7A60,60,0,0,1,20,140c0-23.9,17.7-52,52.6-83.4a420.5,420.5,0,0,1,51.1-39.3,7.9,7.9,0,0,1,8.6,0,420.5,420.5,0,0,1,51.1,39.3C218.3,88,236,116.1,236,140a60,60,0,0,1-79.3,56.8l10.9,32.7a8.1,8.1,0,0,1-1.1,7.2A7.9,7.9,0,0,1,160,240Zm-52.9-16h41.8L135,182.4a8.1,8.1,0,0,1,3.1-9.1,8,8,0,0,1,9.7.4A43.6,43.6,0,0,0,176,184a44,44,0,0,0,44-44c0-37.1-62.8-86.8-92-106.4C98.8,53.2,36,102.9,36,140a44,44,0,0,0,44,44,43.6,43.6,0,0,0,28.2-10.3,8.1,8.1,0,0,1,12.8,8.7Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M232,140a56,56,0,0,1-56,56,55.2,55.2,0,0,1-25.9-6.4L162,225.5a7.8,7.8,0,0,1-1.1,7.2,7.9,7.9,0,0,1-6.4,3.3h-53a7.9,7.9,0,0,1-6.4-3.3,7.8,7.8,0,0,1-1.1-7.2l11.9-35.9A55.2,55.2,0,0,1,80,196a56,56,0,0,1-56-56C24,86.4,121.7,23.3,125.8,20.6a4.3,4.3,0,0,1,4.4,0C134.3,23.3,232,86.4,232,140Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M160,238H96a6,6,0,0,1-5.7-7.9l12.2-36.7A57.9,57.9,0,0,1,22,140C22,85.3,120.6,21.6,124.8,18.9a6.1,6.1,0,0,1,6.4,0C135.4,21.6,234,85.3,234,140a57.9,57.9,0,0,1-80.5,53.4l12.2,36.7A6,6,0,0,1,160,238Zm-55.7-12h47.4l-14.8-44.2a6,6,0,0,1,9.6-6.5A46,46,0,0,0,222,140c0-19.5-16.6-44.7-47.9-72.9A431.8,431.8,0,0,0,128,31.2,431.8,431.8,0,0,0,81.9,67.1C50.6,95.3,34,120.5,34,140a46,46,0,0,0,75.5,35.3,6,6,0,0,1,9.6,6.5Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M160,236H96a3.7,3.7,0,0,1-3.2-1.7,3.8,3.8,0,0,1-.6-3.6l13.7-41.1A55.2,55.2,0,0,1,80,196a56,56,0,0,1-56-56C24,86.4,121.7,23.3,125.8,20.6a4.3,4.3,0,0,1,4.4,0C134.3,23.3,232,86.4,232,140a56,56,0,0,1-56,56,55.2,55.2,0,0,1-25.9-6.4l13.7,41.1a3.8,3.8,0,0,1-.6,3.6A3.7,3.7,0,0,1,160,236Zm-58.5-8h53l-15.7-46.9a4,4,0,0,1,1.6-4.5,4.1,4.1,0,0,1,4.8.2A48,48,0,0,0,224,140c0-45.1-81.6-101.6-96-111.2C113.6,38.4,32,94.9,32,140a48,48,0,0,0,78.8,36.8,4.1,4.1,0,0,1,4.8-.2,4,4,0,0,1,1.6,4.5Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M160,240H96a7.9,7.9,0,0,1-6.5-3.3,8.1,8.1,0,0,1-1.1-7.2l10.9-32.7A60,60,0,0,1,20,140c0-23.9,17.7-52,52.6-83.4a420.5,420.5,0,0,1,51.1-39.3,7.9,7.9,0,0,1,8.6,0,420.5,420.5,0,0,1,51.1,39.3C218.3,88,236,116.1,236,140a60,60,0,0,1-79.3,56.8l10.9,32.7a8.1,8.1,0,0,1-1.1,7.2A7.9,7.9,0,0,1,160,240Zm-52.9-16h41.8L135,182.4a8.1,8.1,0,0,1,3.1-9.1,8,8,0,0,1,9.7.4A43.6,43.6,0,0,0,176,184a44,44,0,0,0,44-44c0-37.1-62.8-86.8-92-106.4C98.8,53.2,36,102.9,36,140a44,44,0,0,0,44,44,43.6,43.6,0,0,0,28.2-10.3,8.1,8.1,0,0,1,12.8,8.7Z" />
        </>
      )}
    </svg>
  );
}

export function PhSparkle(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M199.8,125.2l-50.5-18.5L130.8,56.2a20,20,0,0,0-37.6,0L74.7,106.7,24.2,125.2a20,20,0,0,0,0,37.6l50.5,18.5,18.5,50.5a20,20,0,0,0,37.6,0l18.5-50.5,50.5-18.5a20,20,0,0,0,0-37.6Zm-60.5,34.3a19.7,19.7,0,0,0-11.8,11.8h0l-15.5,42-15.5-42a19.7,19.7,0,0,0-11.8-11.8L42.7,144l42-15.5a19.7,19.7,0,0,0,11.8-11.8l15.5-42,15.5,42a19.7,19.7,0,0,0,11.8,11.8l42,15.5ZM140,40a12,12,0,0,1,12-12h12V16a12,12,0,0,1,24,0V28h12a12,12,0,0,1,0,24H188V64a12,12,0,0,1-24,0V52H152A12,12,0,0,1,140,40ZM252,88a12,12,0,0,1-12,12h-4v4a12,12,0,0,1-24,0v-4h-4a12,12,0,0,1,0-24h4V72a12,12,0,0,1,24,0v4h4A12,12,0,0,1,252,88Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M195.6,151.5l-52.1,19.2a8.1,8.1,0,0,0-4.8,4.8l-19.2,52.1a8,8,0,0,1-15,0L85.3,175.5a8.1,8.1,0,0,0-4.8-4.8L28.4,151.5a8,8,0,0,1,0-15l52.1-19.2a8.1,8.1,0,0,0,4.8-4.8l19.2-52.1a8,8,0,0,1,15,0l19.2,52.1a8.1,8.1,0,0,0,4.8,4.8l52.1,19.2A8,8,0,0,1,195.6,151.5Z"
            opacity="0.2"
          />
          <path d="M198.4,129l-52.2-19.2L127,57.6a16,16,0,0,0-30,0L77.8,109.8,25.6,129a16,16,0,0,0,0,30l52.2,19.2L97,230.4a16,16,0,0,0,30,0l19.2-52.2L198.4,159a16,16,0,0,0,0-30Zm-57.7,34.2a15.9,15.9,0,0,0-9.5,9.5L112,224.9,92.8,172.7a15.9,15.9,0,0,0-9.5-9.5L31.1,144l52.2-19.2a15.9,15.9,0,0,0,9.5-9.5L112,63.1l19.2,52.2a15.9,15.9,0,0,0,9.5,9.5L192.9,144ZM144,40a8,8,0,0,1,8-8h16V16a8,8,0,0,1,16,0V32h16a8,8,0,0,1,0,16H184V64a8,8,0,0,1-16,0V48H152A8,8,0,0,1,144,40ZM248,88a8,8,0,0,1-8,8h-8v8a8,8,0,0,1-16,0V96h-8a8,8,0,0,1,0-16h8V72a8,8,0,0,1,16,0v8h8A8,8,0,0,1,248,88Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M208.9,144a15.8,15.8,0,0,1-10.5,15l-52.2,19.2L127,230.4a16,16,0,0,1-30,0L77.8,178.2,25.6,159a16,16,0,0,1,0-30l52.2-19.2L97,57.6a16,16,0,0,1,30,0l19.2,52.2L198.4,129A15.8,15.8,0,0,1,208.9,144ZM152,48h16V64a8,8,0,0,0,16,0V48h16a8,8,0,0,0,0-16H184V16a8,8,0,0,0-16,0V32H152a8,8,0,0,0,0,16Zm88,32h-8V72a8,8,0,0,0-16,0v8h-8a8,8,0,0,0,0,16h8v8a8,8,0,0,0,16,0V96h8a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M197.7,130.9l-52.2-19.3a2,2,0,0,1-1.1-1.1L125.1,58.3a13.9,13.9,0,0,0-26.2,0L79.6,110.5a2,2,0,0,1-1.1,1.1L26.3,130.9a13.9,13.9,0,0,0,0,26.2l52.2,19.3a2,2,0,0,1,1.1,1.1l19.3,52.2a13.9,13.9,0,0,0,26.2,0l19.3-52.2h0a2,2,0,0,1,1.1-1.1l52.2-19.3a13.9,13.9,0,0,0,0-26.2Zm-4.1,15-52.2,19.2a13.7,13.7,0,0,0-8.3,8.3h0l-19.2,52.2a2,2,0,0,1-3.8,0L90.9,173.4a13.7,13.7,0,0,0-8.3-8.3L30.4,145.9a2,2,0,0,1,0-3.8l52.2-19.2a13.7,13.7,0,0,0,8.3-8.3l19.2-52.2a2,2,0,0,1,3.8,0l19.2,52.2a13.7,13.7,0,0,0,8.3,8.3l52.2,19.2a2,2,0,0,1,0,3.8ZM146,40a6,6,0,0,1,6-6h18V16a6,6,0,0,1,12,0V34h18a6,6,0,0,1,0,12H182V64a6,6,0,0,1-12,0V46H152A6,6,0,0,1,146,40ZM246,88a6,6,0,0,1-6,6H230v10a6,6,0,0,1-12,0V94H208a6,6,0,0,1,0-12h10V72a6,6,0,0,1,12,0V82h10A6,6,0,0,1,246,88Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M197,132.7l-52.2-19.2a3.7,3.7,0,0,1-2.3-2.3L123.3,59a12,12,0,0,0-22.6,0L81.5,111.2a3.7,3.7,0,0,1-2.3,2.3L27,132.7a12,12,0,0,0,0,22.6l52.2,19.2a3.7,3.7,0,0,1,2.3,2.3L100.7,229a12,12,0,0,0,22.6,0l19.2-52.2h0a3.7,3.7,0,0,1,2.3-2.3L197,155.3a12,12,0,0,0,0-22.6Zm-2.8,15.1L142.1,167a11.9,11.9,0,0,0-7.1,7.1l-19.2,52.1a4,4,0,0,1-7.6,0L89,174.1a11.9,11.9,0,0,0-7.1-7.1L29.8,147.8a4,4,0,0,1,0-7.6L81.9,121a11.9,11.9,0,0,0,7.1-7.1l19.2-52.1a4,4,0,0,1,7.6,0L135,113.9a11.9,11.9,0,0,0,7.1,7.1l52.1,19.2a4,4,0,0,1,0,7.6ZM148,40a4,4,0,0,1,4-4h20V16a4,4,0,0,1,8,0V36h20a4,4,0,0,1,0,8H180V64a4,4,0,0,1-8,0V44H152A4,4,0,0,1,148,40Zm96,48a4,4,0,0,1-4,4H228v12a4,4,0,0,1-8,0V92H208a4,4,0,0,1,0-8h12V72a4,4,0,0,1,8,0V84h12A4,4,0,0,1,244,88Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M198.4,129l-52.2-19.2L127,57.6a16,16,0,0,0-30,0L77.8,109.8,25.6,129a16,16,0,0,0,0,30l52.2,19.2L97,230.4a16,16,0,0,0,30,0l19.2-52.2L198.4,159a16,16,0,0,0,0-30Zm-57.7,34.2a15.9,15.9,0,0,0-9.5,9.5L112,224.9,92.8,172.7a15.9,15.9,0,0,0-9.5-9.5L31.1,144l52.2-19.2a15.9,15.9,0,0,0,9.5-9.5L112,63.1l19.2,52.2a15.9,15.9,0,0,0,9.5,9.5L192.9,144ZM144,40a8,8,0,0,1,8-8h16V16a8,8,0,0,1,16,0V32h16a8,8,0,0,1,0,16H184V64a8,8,0,0,1-16,0V48H152A8,8,0,0,1,144,40ZM248,88a8,8,0,0,1-8,8h-8v8a8,8,0,0,1-16,0V96h-8a8,8,0,0,1,0-16h8V72a8,8,0,0,1,16,0v8h8A8,8,0,0,1,248,88Z" />
        </>
      )}
    </svg>
  );
}

export function PhSpeakerHigh(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M252,128a83.4,83.4,0,0,1-24.6,59.4,12.1,12.1,0,0,1-17,0,12,12,0,0,1,0-17,59.9,59.9,0,0,0,0-84.8,12,12,0,0,1,17-17A83.4,83.4,0,0,1,252,128ZM164,32V224a12,12,0,0,1-6.7,10.8A12.4,12.4,0,0,1,152,236a12.7,12.7,0,0,1-7.4-2.5L75.9,180H32a20.1,20.1,0,0,1-20-20V96A20.1,20.1,0,0,1,32,76H75.9l68.7-53.5a12.2,12.2,0,0,1,12.7-1.3A12,12,0,0,1,164,32ZM36,156H68V100H36ZM140,56.5,92,93.9v68.2l48,37.4Zm59.1,40.4a12,12,0,0,0-17,17,19.8,19.8,0,0,1,0,28.2,12,12,0,0,0,0,17,11.8,11.8,0,0,0,8.5,3.5,11.6,11.6,0,0,0,8.5-3.5,43.9,43.9,0,0,0,0-62.2Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M80,168H32a8,8,0,0,1-8-8V96a8,8,0,0,1,8-8H80Z" />
          </g>
          <path d="M224.6,71.4a8,8,0,0,0-11.3,0,7.9,7.9,0,0,0,0,11.3,64.2,64.2,0,0,1,0,90.6,7.9,7.9,0,0,0,0,11.3,8,8,0,0,0,5.6,2.3,8.3,8.3,0,0,0,5.7-2.3,80.2,80.2,0,0,0,0-113.2Z" />
          <path d="M155.5,24.8a8,8,0,0,0-8.4.9L77.3,80H32A16,16,0,0,0,16,96v64a16,16,0,0,0,16,16H77.3l69.8,54.3A8.1,8.1,0,0,0,152,232a8.5,8.5,0,0,0,3.5-.8A8,8,0,0,0,160,224V32A8,8,0,0,0,155.5,24.8ZM32,96H72v64H32ZM144,207.6,88,164.1V91.9l56-43.5Z" />
          <path d="M185,99.7a7.9,7.9,0,0,0,0,11.3,24.1,24.1,0,0,1,0,34,7.9,7.9,0,0,0,0,11.3,8,8,0,0,0,5.6,2.3,8.3,8.3,0,0,0,5.7-2.3,40.1,40.1,0,0,0,0-56.6A8,8,0,0,0,185,99.7Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M248,128a79.5,79.5,0,0,1-23.4,56.6,8.3,8.3,0,0,1-5.7,2.3,8,8,0,0,1-5.6-2.3,7.9,7.9,0,0,1,0-11.3,64.2,64.2,0,0,0,0-90.6,7.9,7.9,0,0,1,0-11.3,8,8,0,0,1,11.3,0A79.5,79.5,0,0,1,248,128ZM160,32V224a8,8,0,0,1-4.5,7.2,8.5,8.5,0,0,1-3.5.8,8.1,8.1,0,0,1-4.9-1.7L77.3,176H32a16,16,0,0,1-16-16V96A16,16,0,0,1,32,80H77.3l69.8-54.3A8,8,0,0,1,160,32ZM32,160H72V96H32ZM185,99.7a7.9,7.9,0,0,0,0,11.3,24.1,24.1,0,0,1,0,34,7.9,7.9,0,0,0,0,11.3,8,8,0,0,0,5.6,2.3,8.3,8.3,0,0,0,5.7-2.3,40.1,40.1,0,0,0,0-56.6A8,8,0,0,0,185,99.7Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M246,128a77.5,77.5,0,0,1-22.9,55.2,6,6,0,0,1-8.4,0,5.9,5.9,0,0,1,0-8.5,66.1,66.1,0,0,0,0-93.4,6,6,0,1,1,8.4-8.5A77.5,77.5,0,0,1,246,128ZM158,32V224a6,6,0,0,1-3.4,5.4,5.8,5.8,0,0,1-2.6.6,5.8,5.8,0,0,1-3.7-1.3L77.9,174H32a14,14,0,0,1-14-14V96A14,14,0,0,1,32,82H77.9l70.4-54.7A6,6,0,0,1,158,32ZM32,162H74V94H32a2,2,0,0,0-2,2v64A2,2,0,0,0,32,162ZM146,44.3,86,90.9v74.2l60,46.6Zm40.4,56.8a5.9,5.9,0,0,0,0,8.5,26.1,26.1,0,0,1,0,36.8,5.9,5.9,0,0,0,0,8.5,6.1,6.1,0,0,0,4.2,1.7,6.5,6.5,0,0,0,4.3-1.7,38.1,38.1,0,0,0,0-53.8A6.1,6.1,0,0,0,186.4,101.1Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M244,128a75.2,75.2,0,0,1-22.3,53.7,3.9,3.9,0,0,1-2.8,1.2,3.8,3.8,0,0,1-2.8-1.2,3.9,3.9,0,0,1,0-5.6,68.1,68.1,0,0,0,0-96.2,4,4,0,1,1,5.6-5.6A75.2,75.2,0,0,1,244,128ZM156,32V224a4.2,4.2,0,0,1-2.2,3.6,3.9,3.9,0,0,1-1.8.4,4.6,4.6,0,0,1-2.5-.8L78.6,172H32a12,12,0,0,1-12-12V96A12,12,0,0,1,32,84H78.6l70.9-55.2A4,4,0,0,1,156,32ZM32,164H76V92H32a4,4,0,0,0-4,4v64A4,4,0,0,0,32,164ZM148,40.2,84,90v76l64,49.8Zm39.8,62.3a4,4,0,0,0,0,5.7,28,28,0,0,1,0,39.6,4,4,0,0,0,0,5.7,4.3,4.3,0,0,0,2.8,1.1,4.1,4.1,0,0,0,2.8-1.1,36,36,0,0,0,0-51A4,4,0,0,0,187.8,102.5Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M248,128a79.6,79.6,0,0,1-23.5,56.6,8,8,0,0,1-5.6,2.3,8.3,8.3,0,0,1-5.7-2.3,8,8,0,0,1,0-11.3,64,64,0,0,0,0-90.6,8,8,0,0,1,11.3-11.3A79.6,79.6,0,0,1,248,128ZM160,32V224a8,8,0,0,1-4.5,7.2,8.5,8.5,0,0,1-3.5.8,8.1,8.1,0,0,1-4.9-1.7L77.3,176H32a16,16,0,0,1-16-16V96A16,16,0,0,1,32,80H77.3l69.8-54.3A8,8,0,0,1,160,32ZM32,160H72V96H32ZM144,48.4,88,91.9v72.2l56,43.5Zm40.9,51.3a8,8,0,0,0,0,11.3,23.9,23.9,0,0,1,0,34,8,8,0,0,0,0,11.3,8.3,8.3,0,0,0,5.7,2.3,8.5,8.5,0,0,0,5.7-2.3,40.1,40.1,0,0,0,0-56.6A8.1,8.1,0,0,0,184.9,99.7Z" />
        </>
      )}
    </svg>
  );
}

export function PhSpeakerLow(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M157.3,21.2a12.2,12.2,0,0,0-12.7,1.3L75.9,76H32A20.1,20.1,0,0,0,12,96v64a20.1,20.1,0,0,0,20,20H75.9l68.7,53.5A12.7,12.7,0,0,0,152,236a12.4,12.4,0,0,0,5.3-1.2A12,12,0,0,0,164,224V32A12,12,0,0,0,157.3,21.2ZM36,100H68v56H36Zm104,99.5L92,162.1V93.9l48-37.4ZM212,128a43.6,43.6,0,0,1-12.9,31.1,11.6,11.6,0,0,1-8.5,3.5,11.8,11.8,0,0,1-8.5-3.5,12,12,0,0,1,0-17,19.8,19.8,0,0,0,0-28.2,12,12,0,0,1,17-17A43.6,43.6,0,0,1,212,128Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M80,168H32a8,8,0,0,1-8-8V96a8,8,0,0,1,8-8H80Z" />
          </g>
          <path d="M155.5,24.8a8,8,0,0,0-8.4.9L77.3,80H32A16,16,0,0,0,16,96v64a16,16,0,0,0,16,16H77.3l69.8,54.3A8.1,8.1,0,0,0,152,232a8.5,8.5,0,0,0,3.5-.8A8,8,0,0,0,160,224V32A8,8,0,0,0,155.5,24.8ZM32,96H72v64H32ZM144,207.6,88,164.1V91.9l56-43.5Z" />
          <path d="M196.3,99.7a8,8,0,0,0-11.3,0,7.9,7.9,0,0,0,0,11.3,24.1,24.1,0,0,1,0,34,7.9,7.9,0,0,0,0,11.3,8,8,0,0,0,5.6,2.3,8.3,8.3,0,0,0,5.7-2.3,40.1,40.1,0,0,0,0-56.6Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M155.5,24.8a8,8,0,0,0-8.4.9L77.3,80H32A16,16,0,0,0,16,96v64a16,16,0,0,0,16,16H77.3l69.8,54.3A8.1,8.1,0,0,0,152,232a8.5,8.5,0,0,0,3.5-.8A8,8,0,0,0,160,224V32A8,8,0,0,0,155.5,24.8ZM32,96H72v64H32Zm176,32a40,40,0,0,1-11.7,28.3,8.3,8.3,0,0,1-5.7,2.3,8,8,0,0,1-5.6-2.3,7.9,7.9,0,0,1,0-11.3,24.1,24.1,0,0,0,0-34,7.9,7.9,0,0,1,0-11.3,8,8,0,0,1,11.3,0A40,40,0,0,1,208,128Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M154.6,26.6a6,6,0,0,0-6.3.7L77.9,82H32A14,14,0,0,0,18,96v64a14,14,0,0,0,14,14H77.9l70.4,54.7A5.8,5.8,0,0,0,152,230a5.8,5.8,0,0,0,2.6-.6A6,6,0,0,0,158,224V32A6,6,0,0,0,154.6,26.6ZM30,160V96a2,2,0,0,1,2-2H74v68H32A2,2,0,0,1,30,160Zm116,51.7L86,165.1V90.9l60-46.6ZM206,128a38.1,38.1,0,0,1-11.1,26.9,6.5,6.5,0,0,1-4.3,1.7,6.1,6.1,0,0,1-4.2-1.7,5.9,5.9,0,0,1,0-8.5,26.1,26.1,0,0,0,0-36.8,5.9,5.9,0,0,1,0-8.5,6.1,6.1,0,0,1,8.5,0A38.1,38.1,0,0,1,206,128Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M153.8,28.4a4.2,4.2,0,0,0-4.3.4L78.6,84H32A12,12,0,0,0,20,96v64a12,12,0,0,0,12,12H78.6l70.9,55.2a4.6,4.6,0,0,0,2.5.8,3.9,3.9,0,0,0,1.8-.4A4.2,4.2,0,0,0,156,224V32A4.2,4.2,0,0,0,153.8,28.4ZM28,160V96a4,4,0,0,1,4-4H76v72H32A4,4,0,0,1,28,160Zm120,55.8L84,166V90l64-49.8ZM204,128a35.9,35.9,0,0,1-10.6,25.5,4.1,4.1,0,0,1-2.8,1.1,4.3,4.3,0,0,1-2.8-1.1,4,4,0,0,1,0-5.7,28,28,0,0,0,0-39.6,4,4,0,0,1,0-5.7,4,4,0,0,1,5.6,0A35.9,35.9,0,0,1,204,128Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M155.5,24.8a8,8,0,0,0-8.4.9L77.3,80H32A16,16,0,0,0,16,96v64a16,16,0,0,0,16,16H77.3l69.8,54.3A8.1,8.1,0,0,0,152,232a8.5,8.5,0,0,0,3.5-.8A8,8,0,0,0,160,224V32A8,8,0,0,0,155.5,24.8ZM32,96H72v64H32ZM144,207.6,88,164.1V91.9l56-43.5ZM208,128a40,40,0,0,1-11.7,28.3,8.5,8.5,0,0,1-5.7,2.3,8.3,8.3,0,0,1-5.7-2.3,8,8,0,0,1,0-11.3,23.9,23.9,0,0,0,0-34,8,8,0,0,1,0-11.3,8.1,8.1,0,0,1,11.4,0A40,40,0,0,1,208,128Z" />
        </>
      )}
    </svg>
  );
}

export function PhSpeakerNone(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M157.3,21.2a12.2,12.2,0,0,0-12.7,1.3L75.9,76H32A20.1,20.1,0,0,0,12,96v64a20.1,20.1,0,0,0,20,20H75.9l68.7,53.5A12.7,12.7,0,0,0,152,236a12.4,12.4,0,0,0,5.3-1.2A12,12,0,0,0,164,224V32A12,12,0,0,0,157.3,21.2ZM36,100H68v56H36Zm104,99.5L92,162.1V93.9l48-37.4Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M80,168H32a8,8,0,0,1-8-8V96a8,8,0,0,1,8-8H80Z" />
          </g>
          <path d="M155.5,24.8a8,8,0,0,0-8.4.9L77.3,80H32A16,16,0,0,0,16,96v64a16,16,0,0,0,16,16H77.3l69.8,54.3A8.1,8.1,0,0,0,152,232a8.5,8.5,0,0,0,3.5-.8A8,8,0,0,0,160,224V32A8,8,0,0,0,155.5,24.8ZM32,96H72v64H32ZM144,207.6,88,164.1V91.9l56-43.5Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M155.5,24.8a8,8,0,0,0-8.4.9L77.3,80H32A16,16,0,0,0,16,96v64a16,16,0,0,0,16,16H77.3l69.8,54.3A8.1,8.1,0,0,0,152,232a8.5,8.5,0,0,0,3.5-.8A8,8,0,0,0,160,224V32A8,8,0,0,0,155.5,24.8ZM32,96H72v64H32Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M154.6,26.6a6,6,0,0,0-6.3.7L77.9,82H32A14,14,0,0,0,18,96v64a14,14,0,0,0,14,14H77.9l70.4,54.7A5.8,5.8,0,0,0,152,230a5.8,5.8,0,0,0,2.6-.6A6,6,0,0,0,158,224V32A6,6,0,0,0,154.6,26.6ZM30,160V96a2,2,0,0,1,2-2H74v68H32A2,2,0,0,1,30,160Zm116,51.7L86,165.1V90.9l60-46.6Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M153.8,28.4a4.2,4.2,0,0,0-4.3.4L78.6,84H32A12,12,0,0,0,20,96v64a12,12,0,0,0,12,12H78.6l70.9,55.2a4.6,4.6,0,0,0,2.5.8,3.9,3.9,0,0,0,1.8-.4A4.2,4.2,0,0,0,156,224V32A4.2,4.2,0,0,0,153.8,28.4ZM28,160V96a4,4,0,0,1,4-4H76v72H32A4,4,0,0,1,28,160Zm120,55.8L84,166V90l64-49.8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M155.5,24.8a8,8,0,0,0-8.4.9L77.3,80H32A16,16,0,0,0,16,96v64a16,16,0,0,0,16,16H77.3l69.8,54.3A8.1,8.1,0,0,0,152,232a8.5,8.5,0,0,0,3.5-.8A8,8,0,0,0,160,224V32A8,8,0,0,0,155.5,24.8ZM32,96H72v64H32ZM144,207.6,88,164.1V91.9l56-43.5Z" />
        </>
      )}
    </svg>
  );
}

export function PhSpeakerSimpleHigh(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M157.3,21.2a12.2,12.2,0,0,0-12.7,1.3L75.9,76H32A20.1,20.1,0,0,0,12,96v64a20.1,20.1,0,0,0,20,20H75.9l68.7,53.5A12.7,12.7,0,0,0,152,236a12.4,12.4,0,0,0,5.3-1.2A12,12,0,0,0,164,224V32A12,12,0,0,0,157.3,21.2ZM140,199.5l-52.6-41A12.2,12.2,0,0,0,80,156H36V100H80a12.2,12.2,0,0,0,7.4-2.5l52.6-41ZM204,104v48a12,12,0,0,1-24,0V104a12,12,0,0,1,24,0Zm36-16v80a12,12,0,0,1-24,0V88a12,12,0,0,1,24,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M80,168H32a8,8,0,0,1-8-8V96a8,8,0,0,1,8-8H80l72-56V224Z" />
          </g>
          <path d="M155.5,24.8a8,8,0,0,0-8.4.9L77.3,80H32A16,16,0,0,0,16,96v64a16,16,0,0,0,16,16H77.3l69.8,54.3A8.1,8.1,0,0,0,152,232a8.5,8.5,0,0,0,3.5-.8A8,8,0,0,0,160,224V32A8,8,0,0,0,155.5,24.8ZM144,207.6,84.9,161.7A7.9,7.9,0,0,0,80,160H32V96H80a7.9,7.9,0,0,0,4.9-1.7L144,48.4Z" />
          <path d="M192,96a8,8,0,0,0-8,8v48a8,8,0,0,0,16,0V104A8,8,0,0,0,192,96Z" />
          <path d="M224,80a8,8,0,0,0-8,8v80a8,8,0,0,0,16,0V88A8,8,0,0,0,224,80Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M160,32V224a8,8,0,0,1-4.5,7.2,8.5,8.5,0,0,1-3.5.8,7.9,7.9,0,0,1-4.9-1.7L77.2,176H32a16,16,0,0,1-16-16V96A16,16,0,0,1,32,80H77.2l69.9-54.3A8,8,0,0,1,160,32Zm32,64a8,8,0,0,0-8,8v48a8,8,0,0,0,16,0V104A8,8,0,0,0,192,96Zm32-16a8,8,0,0,0-8,8v80a8,8,0,0,0,16,0V88A8,8,0,0,0,224,80Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M154.6,26.6a6,6,0,0,0-6.3.7L77.9,82H32A14,14,0,0,0,18,96v64a14,14,0,0,0,14,14H77.9l70.4,54.7A5.8,5.8,0,0,0,152,230a5.8,5.8,0,0,0,2.6-.6A6,6,0,0,0,158,224V32A6,6,0,0,0,154.6,26.6ZM146,211.7,83.7,163.3A5.8,5.8,0,0,0,80,162H32a2,2,0,0,1-2-2V96a2,2,0,0,1,2-2H80a5.8,5.8,0,0,0,3.7-1.3L146,44.3ZM198,104v48a6,6,0,0,1-12,0V104a6,6,0,0,1,12,0Zm32-16v80a6,6,0,0,1-12,0V88a6,6,0,0,1,12,0Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M153.8,28.4a4.2,4.2,0,0,0-4.3.4L78.6,84H32A12,12,0,0,0,20,96v64a12,12,0,0,0,12,12H78.6l70.9,55.2a4.6,4.6,0,0,0,2.5.8,3.9,3.9,0,0,0,1.8-.4A4.2,4.2,0,0,0,156,224V32A4.2,4.2,0,0,0,153.8,28.4ZM148,215.8l-65.5-51A4.4,4.4,0,0,0,80,164H32a4,4,0,0,1-4-4V96a4,4,0,0,1,4-4H80a4.4,4.4,0,0,0,2.5-.8l65.5-51ZM196,104v48a4,4,0,0,1-8,0V104a4,4,0,0,1,8,0Zm32-16v80a4,4,0,0,1-8,0V88a4,4,0,0,1,8,0Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M155.5,24.8a8,8,0,0,0-8.4.9L77.3,80H32A16,16,0,0,0,16,96v64a16,16,0,0,0,16,16H77.3l69.8,54.3A8.1,8.1,0,0,0,152,232a8.5,8.5,0,0,0,3.5-.8A8,8,0,0,0,160,224V32A8,8,0,0,0,155.5,24.8ZM144,207.6,84.9,161.7A7.9,7.9,0,0,0,80,160H32V96H80a7.9,7.9,0,0,0,4.9-1.7L144,48.4ZM200,104v48a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Zm32-16v80a8,8,0,0,1-16,0V88a8,8,0,0,1,16,0Z" />
        </>
      )}
    </svg>
  );
}

export function PhSpeakerSimpleLow(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M157.3,21.2a12.2,12.2,0,0,0-12.7,1.3L75.9,76H32A20.1,20.1,0,0,0,12,96v64a20.1,20.1,0,0,0,20,20H75.9l68.7,53.5A12.7,12.7,0,0,0,152,236a12.4,12.4,0,0,0,5.3-1.2A12,12,0,0,0,164,224V32A12,12,0,0,0,157.3,21.2ZM140,199.5l-52.6-41A12.2,12.2,0,0,0,80,156H36V100H80a12.2,12.2,0,0,0,7.4-2.5l52.6-41ZM204,104v48a12,12,0,0,1-24,0V104a12,12,0,0,1,24,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M80,168H32a8,8,0,0,1-8-8V96a8,8,0,0,1,8-8H80l72-56V224Z" />
          </g>
          <path d="M155.5,24.8a8,8,0,0,0-8.4.9L77.3,80H32A16,16,0,0,0,16,96v64a16,16,0,0,0,16,16H77.3l69.8,54.3A8.1,8.1,0,0,0,152,232a8.5,8.5,0,0,0,3.5-.8A8,8,0,0,0,160,224V32A8,8,0,0,0,155.5,24.8ZM144,207.6,84.9,161.7A7.9,7.9,0,0,0,80,160H32V96H80a7.9,7.9,0,0,0,4.9-1.7L144,48.4Z" />
          <path d="M192,96a8,8,0,0,0-8,8v48a8,8,0,0,0,16,0V104A8,8,0,0,0,192,96Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M160,32V224a8,8,0,0,1-4.5,7.2,8.5,8.5,0,0,1-3.5.8,7.9,7.9,0,0,1-4.9-1.7L77.2,176H32a16,16,0,0,1-16-16V96A16,16,0,0,1,32,80H77.2l69.9-54.3A8,8,0,0,1,160,32Zm32,64a8,8,0,0,0-8,8v48a8,8,0,0,0,16,0V104A8,8,0,0,0,192,96Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M154.6,26.6a6,6,0,0,0-6.3.7L77.9,82H32A14,14,0,0,0,18,96v64a14,14,0,0,0,14,14H77.9l70.4,54.7A5.8,5.8,0,0,0,152,230a5.8,5.8,0,0,0,2.6-.6A6,6,0,0,0,158,224V32A6,6,0,0,0,154.6,26.6ZM146,211.7,83.7,163.3A5.8,5.8,0,0,0,80,162H32a2,2,0,0,1-2-2V96a2,2,0,0,1,2-2H80a5.8,5.8,0,0,0,3.7-1.3L146,44.3ZM198,104v48a6,6,0,0,1-12,0V104a6,6,0,0,1,12,0Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M153.8,28.4a4.2,4.2,0,0,0-4.3.4L78.6,84H32A12,12,0,0,0,20,96v64a12,12,0,0,0,12,12H78.6l70.9,55.2a4.6,4.6,0,0,0,2.5.8,3.9,3.9,0,0,0,1.8-.4A4.2,4.2,0,0,0,156,224V32A4.2,4.2,0,0,0,153.8,28.4ZM148,215.8l-65.5-51A4.4,4.4,0,0,0,80,164H32a4,4,0,0,1-4-4V96a4,4,0,0,1,4-4H80a4.4,4.4,0,0,0,2.5-.8l65.5-51ZM196,104v48a4,4,0,0,1-8,0V104a4,4,0,0,1,8,0Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M155.5,24.8a8,8,0,0,0-8.4.9L77.3,80H32A16,16,0,0,0,16,96v64a16,16,0,0,0,16,16H77.3l69.8,54.3A8.1,8.1,0,0,0,152,232a8.5,8.5,0,0,0,3.5-.8A8,8,0,0,0,160,224V32A8,8,0,0,0,155.5,24.8ZM144,207.6,84.9,161.7A7.9,7.9,0,0,0,80,160H32V96H80a7.9,7.9,0,0,0,4.9-1.7L144,48.4ZM200,104v48a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Z" />
        </>
      )}
    </svg>
  );
}

export function PhSpeakerSimpleNone(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M152,236a12.7,12.7,0,0,1-7.4-2.5L75.9,180H32a20.1,20.1,0,0,1-20-20V96A20.1,20.1,0,0,1,32,76H75.9l68.7-53.5a12.2,12.2,0,0,1,12.7-1.3A12,12,0,0,1,164,32V224a12,12,0,0,1-6.7,10.8A12.4,12.4,0,0,1,152,236ZM36,156H80a12.2,12.2,0,0,1,7.4,2.5l52.6,41V56.5l-52.6,41A12.2,12.2,0,0,1,80,100H36Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M80,168H32a8,8,0,0,1-8-8V96a8,8,0,0,1,8-8H80l72-56V224Z" />
          </g>
          <path d="M152,232a8.1,8.1,0,0,1-4.9-1.7L77.3,176H32a16,16,0,0,1-16-16V96A16,16,0,0,1,32,80H77.3l69.8-54.3A8,8,0,0,1,160,32V224a8,8,0,0,1-4.5,7.2A8.5,8.5,0,0,1,152,232ZM32,96v64H80a7.9,7.9,0,0,1,4.9,1.7L144,207.6V48.4L84.9,94.3A7.9,7.9,0,0,1,80,96Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M160,32V224a8,8,0,0,1-4.5,7.2,8.5,8.5,0,0,1-3.5.8,7.9,7.9,0,0,1-4.9-1.7L77.2,176H32a16,16,0,0,1-16-16V96A16,16,0,0,1,32,80H77.2l69.9-54.3A8,8,0,0,1,160,32Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M152,230a5.8,5.8,0,0,1-3.7-1.3L77.9,174H32a14,14,0,0,1-14-14V96A14,14,0,0,1,32,82H77.9l70.4-54.7A6,6,0,0,1,158,32V224a6,6,0,0,1-3.4,5.4A5.8,5.8,0,0,1,152,230ZM32,94a2,2,0,0,0-2,2v64a2,2,0,0,0,2,2H80a5.8,5.8,0,0,1,3.7,1.3L146,211.7V44.3L83.7,92.7A5.8,5.8,0,0,1,80,94Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M152,228a4.6,4.6,0,0,1-2.5-.8L78.6,172H32a12,12,0,0,1-12-12V96A12,12,0,0,1,32,84H78.6l70.9-55.2A4,4,0,0,1,156,32V224a4.2,4.2,0,0,1-2.2,3.6A3.9,3.9,0,0,1,152,228ZM32,92a4,4,0,0,0-4,4v64a4,4,0,0,0,4,4H80a4.4,4.4,0,0,1,2.5.8l65.5,51V40.2l-65.5,51A4.4,4.4,0,0,1,80,92Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M152,232a8.1,8.1,0,0,1-4.9-1.7L77.3,176H32a16,16,0,0,1-16-16V96A16,16,0,0,1,32,80H77.3l69.8-54.3A8,8,0,0,1,160,32V224a8,8,0,0,1-4.5,7.2A8.5,8.5,0,0,1,152,232ZM32,96v64H80a7.9,7.9,0,0,1,4.9,1.7L144,207.6V48.4L84.9,94.3A7.9,7.9,0,0,1,80,96Z" />
        </>
      )}
    </svg>
  );
}

export function PhSpeakerSimpleSlash(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M240,88v80a12,12,0,0,1-24,0V88a12,12,0,0,1,24,0ZM216.9,207.9a12.1,12.1,0,0,1-.8,17,12.1,12.1,0,0,1-17-.8L164,185.4V224a12,12,0,0,1-6.7,10.8A12.4,12.4,0,0,1,152,236a12.7,12.7,0,0,1-7.4-2.5L75.9,180H32a20.1,20.1,0,0,1-20-20V96A20.1,20.1,0,0,1,32,76H64.5L39.1,48.1A12,12,0,0,1,56.9,31.9ZM140,159,86.3,100H36v56H80a12.2,12.2,0,0,1,7.4,2.5l52.6,41ZM125.9,67.5l14.1-11V94.9a12,12,0,0,0,24,0V32a12,12,0,0,0-6.7-10.8,12.2,12.2,0,0,0-12.7,1.3L111.1,48.6a12,12,0,1,0,14.8,18.9ZM192,150.9a12,12,0,0,0,12-12V104a12,12,0,0,0-24,0v34.9A12,12,0,0,0,192,150.9Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M80,168H32a8,8,0,0,1-8-8V96a8,8,0,0,1,8-8H80l72-56V224Z" />
          </g>
          <path d="M192,160a8,8,0,0,0,8-8V104a8,8,0,0,0-16,0v48A8,8,0,0,0,192,160Z" />
          <path d="M224,80a8,8,0,0,0-8,8v80a8,8,0,0,0,16,0V88A8,8,0,0,0,224,80Z" />
          <path d="M53.9,34.6A8,8,0,0,0,42.1,45.4L73.6,80H32A16,16,0,0,0,16,96v64a16,16,0,0,0,16,16H77.3l69.8,54.3A8.1,8.1,0,0,0,152,232a8.5,8.5,0,0,0,3.5-.8A8,8,0,0,0,160,224V175.1l42.1,46.3A8,8,0,0,0,208,224a8.2,8.2,0,0,0,5.4-2.1,7.9,7.9,0,0,0,.5-11.3Zm90.1,173L84.9,161.7A7.9,7.9,0,0,0,80,160H32V96H88.1L144,157.5Z" />
          <path d="M117.1,69.3,144,48.4v58.4a8,8,0,1,0,16,0V32a8,8,0,0,0-12.9-6.3l-39.9,31a8,8,0,1,0,9.9,12.6Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M184,152V104a8,8,0,0,1,16,0v48a8,8,0,0,1-16,0Zm40-72a8,8,0,0,0-8,8v80a8,8,0,0,0,16,0V88A8,8,0,0,0,224,80ZM53.9,34.6A8,8,0,0,0,42.1,45.4L73.6,80H32A16,16,0,0,0,16,96v64a16,16,0,0,0,16,16H77.3l69.8,54.3A8.1,8.1,0,0,0,152,232a8.5,8.5,0,0,0,3.5-.8A8,8,0,0,0,160,224V175.1l42.1,46.3A8,8,0,0,0,208,224a8.2,8.2,0,0,0,5.4-2.1,7.9,7.9,0,0,0,.5-11.3Zm92.2,77.6a7.9,7.9,0,0,0,5.9,2.6,7.4,7.4,0,0,0,2.9-.5,8,8,0,0,0,5.1-7.5V32a8,8,0,0,0-12.9-6.3l-39.9,31a8.1,8.1,0,0,0-1,11.7Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M186,152V104a6,6,0,0,1,12,0v48a6,6,0,0,1-12,0Zm38-70a6,6,0,0,0-6,6v80a6,6,0,0,0,12,0V88A6,6,0,0,0,224,82ZM212.4,212a5.8,5.8,0,0,1-.4,8.4,5.7,5.7,0,0,1-4,1.6,5.9,5.9,0,0,1-4.4-2L158,169.9V224a6,6,0,0,1-3.4,5.4,5.8,5.8,0,0,1-2.6.6,5.8,5.8,0,0,1-3.7-1.3L77.9,174H32a14,14,0,0,1-14-14V96A14,14,0,0,1,32,82H78.1L43.6,44a5.9,5.9,0,0,1,8.8-8ZM146,156.7,89,94H32a2,2,0,0,0-2,2v64a2,2,0,0,0,2,2H80a5.8,5.8,0,0,1,3.7,1.3L146,211.7Zm-30.2-89L146,44.3v62.5a6,6,0,1,0,12,0V32a6,6,0,0,0-9.7-4.7l-39.8,31a6,6,0,0,0-1.1,8.4A5.9,5.9,0,0,0,115.8,67.7Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M188,152V104a4,4,0,0,1,8,0v48a4,4,0,0,1-8,0Zm36-68a4,4,0,0,0-4,4v80a4,4,0,0,0,8,0V88A4,4,0,0,0,224,84ZM211,213.3a4.2,4.2,0,0,1-.3,5.7,4.1,4.1,0,0,1-5.7-.3l-49-54V224a4.2,4.2,0,0,1-2.2,3.6,3.9,3.9,0,0,1-1.8.4,4.6,4.6,0,0,1-2.5-.8L78.6,172H32a12,12,0,0,1-12-12V96A12,12,0,0,1,32,84H82.6L45,42.7a4,4,0,1,1,6-5.4Zm-63-57.4L89.9,92H32a4,4,0,0,0-4,4v64a4,4,0,0,0,4,4H80a4.4,4.4,0,0,1,2.5.8l65.5,51ZM114.6,66.1,148,40.2v66.6a4,4,0,1,0,8,0V32a4,4,0,0,0-6.5-3.2l-39.8,31a4,4,0,0,0-.7,5.6A3.9,3.9,0,0,0,114.6,66.1Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M184,152V104a8,8,0,0,1,16,0v48a8,8,0,0,1-16,0Zm40-72a8,8,0,0,0-8,8v80a8,8,0,0,0,16,0V88A8,8,0,0,0,224,80ZM213.9,210.6a7.9,7.9,0,0,1-.5,11.3A8.2,8.2,0,0,1,208,224a8,8,0,0,1-5.9-2.6L160,175.1V224a8,8,0,0,1-4.5,7.2,8.5,8.5,0,0,1-3.5.8,8.1,8.1,0,0,1-4.9-1.7L77.3,176H32a16,16,0,0,1-16-16V96A16,16,0,0,1,32,80H73.6L42.1,45.4A8,8,0,0,1,53.9,34.6ZM144,157.5,88.1,96H32v64H80a7.9,7.9,0,0,1,4.9,1.7L144,207.6ZM117.1,69.3,144,48.4v58.4a8,8,0,1,0,16,0V32a8,8,0,0,0-12.9-6.3l-39.9,31a8,8,0,1,0,9.9,12.6Z" />
        </>
      )}
    </svg>
  );
}

export function PhSpeakerSimpleX(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M157.3,21.2a12.2,12.2,0,0,0-12.7,1.3L75.9,76H32A20.1,20.1,0,0,0,12,96v64a20.1,20.1,0,0,0,20,20H75.9l68.7,53.5A12.7,12.7,0,0,0,152,236a12.4,12.4,0,0,0,5.3-1.2A12,12,0,0,0,164,224V32A12,12,0,0,0,157.3,21.2ZM140,199.5l-52.6-41A12.2,12.2,0,0,0,80,156H36V100H80a12.2,12.2,0,0,0,7.4-2.5l52.6-41Zm108.5-56a12,12,0,0,1,0,17,12.1,12.1,0,0,1-17,0L216,145l-15.5,15.5a12.1,12.1,0,0,1-17,0,12,12,0,0,1,0-17L199,128l-15.5-15.5a12,12,0,0,1,17-17L216,111l15.5-15.5a12,12,0,0,1,17,17L233,128Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M80,168H32a8,8,0,0,1-8-8V96a8,8,0,0,1,8-8H80l72-56V224Z" />
          </g>
          <path d="M155.5,24.8a8,8,0,0,0-8.4.9L77.3,80H32A16,16,0,0,0,16,96v64a16,16,0,0,0,16,16H77.3l69.8,54.3A8.1,8.1,0,0,0,152,232a8.5,8.5,0,0,0,3.5-.8A8,8,0,0,0,160,224V32A8,8,0,0,0,155.5,24.8ZM144,207.6,84.9,161.7A7.9,7.9,0,0,0,80,160H32V96H80a7.9,7.9,0,0,0,4.9-1.7L144,48.4Z" />
          <path d="M227.3,128l18.4-18.3a8.1,8.1,0,0,0-11.4-11.4L216,116.7,197.7,98.3a8.1,8.1,0,0,0-11.4,11.4L204.7,128l-18.4,18.3a8.1,8.1,0,0,0,0,11.4,8.2,8.2,0,0,0,11.4,0L216,139.3l18.3,18.4a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M160,32V224a8,8,0,0,1-4.5,7.2,8.5,8.5,0,0,1-3.5.8,7.9,7.9,0,0,1-4.9-1.7L77.2,176H32a16,16,0,0,1-16-16V96A16,16,0,0,1,32,80H77.2l69.9-54.3A8,8,0,0,1,160,32Zm67.3,96,18.4-18.3a8.1,8.1,0,0,0-11.4-11.4L216,116.7,197.7,98.3a8.1,8.1,0,0,0-11.4,11.4L204.7,128l-18.4,18.3a8.1,8.1,0,0,0,0,11.4A8.3,8.3,0,0,0,192,160a8.5,8.5,0,0,0,5.7-2.3L216,139.3l18.3,18.4A8.3,8.3,0,0,0,240,160a8.5,8.5,0,0,0,5.7-2.3,8.1,8.1,0,0,0,0-11.4Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M154.6,26.6a6,6,0,0,0-6.3.7L77.9,82H32A14,14,0,0,0,18,96v64a14,14,0,0,0,14,14H77.9l70.4,54.7A5.8,5.8,0,0,0,152,230a5.8,5.8,0,0,0,2.6-.6A6,6,0,0,0,158,224V32A6,6,0,0,0,154.6,26.6ZM146,211.7,83.7,163.3A5.8,5.8,0,0,0,80,162H32a2,2,0,0,1-2-2V96a2,2,0,0,1,2-2H80a5.8,5.8,0,0,0,3.7-1.3L146,44.3Zm98.2-63.9a5.9,5.9,0,1,1-8.4,8.4L216,136.5l-19.8,19.7a5.9,5.9,0,0,1-8.4-8.4L207.5,128l-19.7-19.8a5.9,5.9,0,0,1,8.4-8.4L216,119.5l19.8-19.7a5.9,5.9,0,0,1,8.4,8.4L224.5,128Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M153.8,28.4a4.2,4.2,0,0,0-4.3.4L78.6,84H32A12,12,0,0,0,20,96v64a12,12,0,0,0,12,12H78.6l70.9,55.2a4.6,4.6,0,0,0,2.5.8,3.9,3.9,0,0,0,1.8-.4A4.2,4.2,0,0,0,156,224V32A4.2,4.2,0,0,0,153.8,28.4ZM148,215.8l-65.5-51A4.4,4.4,0,0,0,80,164H32a4,4,0,0,1-4-4V96a4,4,0,0,1,4-4H80a4.4,4.4,0,0,0,2.5-.8l65.5-51Zm94.8-66.6a3.9,3.9,0,0,1,0,5.6,3.9,3.9,0,0,1-5.6,0L216,133.7l-21.2,21.1a3.9,3.9,0,0,1-5.6,0,3.9,3.9,0,0,1,0-5.6L210.3,128l-21.1-21.2a4,4,0,0,1,5.6-5.6L216,122.3l21.2-21.1a4,4,0,1,1,5.6,5.6L221.7,128Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M155.5,24.8a8,8,0,0,0-8.4.9L77.3,80H32A16,16,0,0,0,16,96v64a16,16,0,0,0,16,16H77.3l69.8,54.3A8.1,8.1,0,0,0,152,232a8.5,8.5,0,0,0,3.5-.8A8,8,0,0,0,160,224V32A8,8,0,0,0,155.5,24.8ZM144,207.6,84.9,161.7A7.9,7.9,0,0,0,80,160H32V96H80a7.9,7.9,0,0,0,4.9-1.7L144,48.4Zm101.7-61.3a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0L216,139.3l-18.3,18.4a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L204.7,128l-18.4-18.3a8.1,8.1,0,0,1,11.4-11.4L216,116.7l18.3-18.4a8.1,8.1,0,0,1,11.4,11.4L227.3,128Z" />
        </>
      )}
    </svg>
  );
}

export function PhSpeakerSlash(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M56.9,31.9A12,12,0,0,0,39.1,48.1L64.5,76H32A20.1,20.1,0,0,0,12,96v64a20.1,20.1,0,0,0,20,20H75.9l68.7,53.5A12,12,0,0,0,152,236a12.4,12.4,0,0,0,5.3-1.2A12,12,0,0,0,164,224V185.4l35.1,38.7a12.1,12.1,0,0,0,17,.8,12.1,12.1,0,0,0,.8-17ZM36,100H68v56H36Zm104,99.5L92,162.1V106.2L140,159ZM109,65.4a12,12,0,0,1,2.1-16.8l33.5-26.1a12.2,12.2,0,0,1,12.7-1.3A12,12,0,0,1,164,32V94.9a12,12,0,0,1-24,0V56.5l-14.1,11A12,12,0,0,1,109,65.4Zm73.1,48.5a12,12,0,0,1,17-17A43.8,43.8,0,0,1,212,128a45.1,45.1,0,0,1-5.3,21,12.1,12.1,0,0,1-10.6,6.3,11.8,11.8,0,0,1-5.7-1.5,12,12,0,0,1-4.8-16.3A19.4,19.4,0,0,0,188,128,19.7,19.7,0,0,0,182.1,113.9ZM252,128a84,84,0,0,1-19.1,53.3,12,12,0,1,1-18.6-15.2,59.9,59.9,0,0,0-3.9-80.5,12,12,0,0,1,17-17A83.9,83.9,0,0,1,252,128Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M80,168H32a8,8,0,0,1-8-8V96a8,8,0,0,1,8-8H80Z" />
          </g>
          <path d="M224.5,71.4a8,8,0,0,0-11.3,0,8.1,8.1,0,0,0,0,11.4,63.9,63.9,0,0,1,0,90.5,8,8,0,0,0,0,11.3,8.3,8.3,0,0,0,5.7,2.3,8,8,0,0,0,5.6-2.3,79.9,79.9,0,0,0,0-113.2Z" />
          <path d="M192,128a23.8,23.8,0,0,1-7.1,17,8,8,0,0,0,5.7,13.6,7.8,7.8,0,0,0,5.7-2.3,40.1,40.1,0,0,0,0-56.6,8.1,8.1,0,0,0-11.4,0,8,8,0,0,0,0,11.3A23.8,23.8,0,0,1,192,128Z" />
          <path d="M53.9,34.6A8,8,0,0,0,42.1,45.4L73.6,80H32A16,16,0,0,0,16,96v64a16,16,0,0,0,16,16H77.3l69.8,54.3A8.1,8.1,0,0,0,152,232a8.5,8.5,0,0,0,3.5-.8A7.8,7.8,0,0,0,160,224V175.1l42.1,46.3A8,8,0,0,0,208,224a8.2,8.2,0,0,0,5.4-2.1,7.9,7.9,0,0,0,.5-11.3ZM32,96H72v64H32ZM144,207.7,88,164.1V95.9l56,61.6Z" />
          <path d="M117.1,69.3,144,48.4v58.4a8,8,0,1,0,16,0V32a8,8,0,0,0-12.9-6.3l-39.9,31a8,8,0,1,0,9.9,12.6Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M53.9,34.6A8,8,0,0,0,42.1,45.4L73.6,80H32A16,16,0,0,0,16,96v64a16,16,0,0,0,16,16H77.3l69.8,54.3h0A7.9,7.9,0,0,0,152,232a8.5,8.5,0,0,0,3.5-.8A8.1,8.1,0,0,0,160,224V175.1l42.1,46.3A8,8,0,0,0,208,224a8.2,8.2,0,0,0,5.4-2.1,7.9,7.9,0,0,0,.5-11.3ZM72,160H32V96H72Zm34.2-91.6a8.1,8.1,0,0,1-2-6.1,8.2,8.2,0,0,1,3-5.6l39.9-31A8,8,0,0,1,160,32v74.8a8,8,0,0,1-5.1,7.5,7.4,7.4,0,0,1-2.9.5,7.9,7.9,0,0,1-5.9-2.6ZM248,128a79.2,79.2,0,0,1-23.5,56.6,8,8,0,0,1-5.6,2.3,8.3,8.3,0,0,1-5.7-2.3,8,8,0,0,1,0-11.3,63.9,63.9,0,0,0,0-90.5,8.1,8.1,0,0,1,0-11.4,8,8,0,0,1,11.3,0A79.2,79.2,0,0,1,248,128Zm-63.1-17a8,8,0,0,1,0-11.3,8.1,8.1,0,0,1,11.4,0,40.1,40.1,0,0,1,0,56.6,7.8,7.8,0,0,1-5.7,2.3,8,8,0,0,1-5.7-13.6,23.9,23.9,0,0,0,0-34Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M246,128a77.5,77.5,0,0,1-22.9,55.2,6.1,6.1,0,0,1-4.2,1.7,6.5,6.5,0,0,1-4.3-1.7,6.1,6.1,0,0,1,0-8.5,65.9,65.9,0,0,0,0-93.4,6,6,0,1,1,8.5-8.4A77.1,77.1,0,0,1,246,128Zm-59.6,26.9a6,6,0,0,0,8.4,0,37.9,37.9,0,0,0,0-53.8,6,6,0,0,0-8.4,8.5,26.1,26.1,0,0,1,0,36.8A5.9,5.9,0,0,0,186.4,154.9Zm26,57.1a5.8,5.8,0,0,1-.4,8.4,5.7,5.7,0,0,1-4,1.6,5.9,5.9,0,0,1-4.4-2L158,169.9V224a6,6,0,0,1-3.4,5.4,5.8,5.8,0,0,1-2.6.6,5.8,5.8,0,0,1-3.7-1.3L77.9,174H32a14,14,0,0,1-14-14V96A14,14,0,0,1,32,82H78L43.6,44a5.9,5.9,0,0,1,8.8-8ZM32,162H74V94H32a2,2,0,0,0-2,2v64A2,2,0,0,0,32,162Zm114-5.3L86.1,90.8H86v74.2l60,46.6Zm-30.2-89L146,44.3v62.5a6,6,0,1,0,12,0V32a6,6,0,0,0-9.7-4.7l-39.8,31a6,6,0,0,0-1.1,8.4A5.9,5.9,0,0,0,115.8,67.7Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M244,128a75.2,75.2,0,0,1-22.3,53.7,3.9,3.9,0,0,1-2.8,1.2,3.8,3.8,0,0,1-2.8-1.2,3.9,3.9,0,0,1,0-5.6,68.1,68.1,0,0,0,0-96.2,4,4,0,1,1,5.6-5.6A75.2,75.2,0,0,1,244,128Zm-56.2,25.5a4.3,4.3,0,0,0,2.8,1.1,4.1,4.1,0,0,0,2.8-1.1,35.9,35.9,0,0,0,0-50.9,4,4,0,0,0-5.6,5.6,28,28,0,0,1,0,39.6A4,4,0,0,0,187.8,153.5ZM211,213.3a4.2,4.2,0,0,1-.3,5.7,4.1,4.1,0,0,1-5.7-.3l-49-54V224a4.2,4.2,0,0,1-2.2,3.6,3.9,3.9,0,0,1-1.8.4,4.6,4.6,0,0,1-2.5-.8L78.6,172H32a12,12,0,0,1-12-12V96A12,12,0,0,1,32,84H78.6L81,82.2,45,42.7a4,4,0,1,1,6-5.4ZM32,164H76V92H32a4,4,0,0,0-4,4v64A4,4,0,0,0,32,164Zm116-8.1L86.4,88.1,84,90v76l64,49.8ZM114.6,66.2l33.4-26v66.6a4,4,0,1,0,8,0V32a4.2,4.2,0,0,0-2.2-3.6,4,4,0,0,0-4.3.5L109.7,59.8a4,4,0,0,0,4.9,6.4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M248,128a79.2,79.2,0,0,1-23.5,56.6,8,8,0,0,1-5.6,2.3,8.3,8.3,0,0,1-5.7-2.3,8,8,0,0,1,0-11.3,63.9,63.9,0,0,0,0-90.5,8.1,8.1,0,0,1,0-11.4,8,8,0,0,1,11.3,0A79.2,79.2,0,0,1,248,128Zm-56,0a23.8,23.8,0,0,1-7.1,17,8,8,0,0,0,5.7,13.6,7.8,7.8,0,0,0,5.7-2.3,40.1,40.1,0,0,0,0-56.6,8.1,8.1,0,0,0-11.4,0,8,8,0,0,0,0,11.3A23.8,23.8,0,0,1,192,128Zm21.9,82.6a7.9,7.9,0,0,1-.5,11.3A8.2,8.2,0,0,1,208,224a8,8,0,0,1-5.9-2.6L160,175.1V224a7.8,7.8,0,0,1-4.5,7.2,8.5,8.5,0,0,1-3.5.8,8.1,8.1,0,0,1-4.9-1.7L77.3,176H32a16,16,0,0,1-16-16V96A16,16,0,0,1,32,80H73.6L42.1,45.4A8,8,0,0,1,53.9,34.6ZM32,160H72V96H32Zm112-2.5L88,95.9v68.2l56,43.6ZM117.1,69.3,144,48.4v58.4a8,8,0,1,0,16,0V32a8,8,0,0,0-12.9-6.3l-39.9,31a8,8,0,1,0,9.9,12.6Z" />
        </>
      )}
    </svg>
  );
}

export function PhSpeakerX(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M157.3,21.2a12.2,12.2,0,0,0-12.7,1.3L75.9,76H32A20.1,20.1,0,0,0,12,96v64a20.1,20.1,0,0,0,20,20H75.9l68.7,53.5A12.7,12.7,0,0,0,152,236a12.4,12.4,0,0,0,5.3-1.2A12,12,0,0,0,164,224V32A12,12,0,0,0,157.3,21.2ZM36,100H68v56H36Zm104,99.5L92,162.1V93.9l48-37.4Zm108.5-56a12,12,0,0,1,0,17,12.1,12.1,0,0,1-17,0L216,145l-15.5,15.5a12.1,12.1,0,0,1-17,0,12,12,0,0,1,0-17L199,128l-15.5-15.5a12,12,0,0,1,17-17L216,111l15.5-15.5a12,12,0,0,1,17,17L233,128Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M80,168H32a8,8,0,0,1-8-8V96a8,8,0,0,1,8-8H80Z" />
          </g>
          <path d="M155.5,24.8a8,8,0,0,0-8.4.9L77.3,80H32A16,16,0,0,0,16,96v64a16,16,0,0,0,16,16H77.3l69.8,54.3A8.1,8.1,0,0,0,152,232a8.5,8.5,0,0,0,3.5-.8A8,8,0,0,0,160,224V32A8,8,0,0,0,155.5,24.8ZM32,96H72v64H32ZM144,207.6,88,164.1V91.9l56-43.5Z" />
          <path d="M227.3,128l18.4-18.3a8.1,8.1,0,0,0-11.4-11.4L216,116.7,197.7,98.3a8.1,8.1,0,0,0-11.4,11.4L204.7,128l-18.4,18.3a8.1,8.1,0,0,0,0,11.4,8.2,8.2,0,0,0,11.4,0L216,139.3l18.3,18.4a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M155.5,24.8a8,8,0,0,0-8.4.9L77.3,80H32A16,16,0,0,0,16,96v64a16,16,0,0,0,16,16H77.3l69.8,54.3A8.1,8.1,0,0,0,152,232a8.5,8.5,0,0,0,3.5-.8A8,8,0,0,0,160,224V32A8,8,0,0,0,155.5,24.8ZM32,96H72v64H32Zm213.7,50.3a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0L216,139.3l-18.3,18.4a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L204.7,128l-18.4-18.3a8.1,8.1,0,0,1,11.4-11.4L216,116.7l18.3-18.4a8.1,8.1,0,0,1,11.4,11.4L227.3,128Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M154.6,26.6a6,6,0,0,0-6.3.7L77.9,82H32A14,14,0,0,0,18,96v64a14,14,0,0,0,14,14H77.9l70.4,54.7A5.8,5.8,0,0,0,152,230a5.8,5.8,0,0,0,2.6-.6A6,6,0,0,0,158,224V32A6,6,0,0,0,154.6,26.6ZM30,160V96a2,2,0,0,1,2-2H74v68H32A2,2,0,0,1,30,160Zm116,51.7L86,165.1V90.9l60-46.6Zm98.2-63.9a5.9,5.9,0,1,1-8.4,8.4L216,136.5l-19.8,19.7a5.9,5.9,0,0,1-8.4-8.4L207.5,128l-19.7-19.8a5.9,5.9,0,0,1,8.4-8.4L216,119.5l19.8-19.7a5.9,5.9,0,0,1,8.4,8.4L224.5,128Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M153.8,28.4a4.2,4.2,0,0,0-4.3.4L78.6,84H32A12,12,0,0,0,20,96v64a12,12,0,0,0,12,12H78.6l70.9,55.2a4.6,4.6,0,0,0,2.5.8,3.9,3.9,0,0,0,1.8-.4A4.2,4.2,0,0,0,156,224V32A4.2,4.2,0,0,0,153.8,28.4ZM28,160V96a4,4,0,0,1,4-4H76v72H32A4,4,0,0,1,28,160Zm120,55.8L84,166V90l64-49.8Zm94.8-66.6a3.9,3.9,0,0,1,0,5.6,3.9,3.9,0,0,1-5.6,0L216,133.7l-21.2,21.1a3.9,3.9,0,0,1-5.6,0,3.9,3.9,0,0,1,0-5.6L210.3,128l-21.1-21.2a4,4,0,0,1,5.6-5.6L216,122.3l21.2-21.1a4,4,0,1,1,5.6,5.6L221.7,128Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M155.5,24.8a8,8,0,0,0-8.4.9L77.3,80H32A16,16,0,0,0,16,96v64a16,16,0,0,0,16,16H77.3l69.8,54.3A8.1,8.1,0,0,0,152,232a8.5,8.5,0,0,0,3.5-.8A8,8,0,0,0,160,224V32A8,8,0,0,0,155.5,24.8ZM32,96H72v64H32ZM144,207.6,88,164.1V91.9l56-43.5Zm101.7-61.3a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0L216,139.3l-18.3,18.4a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L204.7,128l-18.4-18.3a8.1,8.1,0,0,1,11.4-11.4L216,116.7l18.3-18.4a8.1,8.1,0,0,1,11.4,11.4L227.3,128Z" />
        </>
      )}
    </svg>
  );
}

export function PhSpinnerGap(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M140,32V64a12,12,0,0,1-24,0V32a12,12,0,0,1,24,0Zm84,84H192a12,12,0,0,0,0,24h32a12,12,0,0,0,0-24Zm-42.3,48.8a12,12,0,0,0-16.9,16.9l22.6,22.7a12,12,0,0,0,8.5,3.5,12.2,12.2,0,0,0,8.5-3.5,12,12,0,0,0,0-17ZM128,180a12,12,0,0,0-12,12v32a12,12,0,0,0,24,0V192A12,12,0,0,0,128,180ZM74.3,164.8,51.6,187.4a12,12,0,0,0,0,17,12.2,12.2,0,0,0,8.5,3.5,12,12,0,0,0,8.5-3.5l22.6-22.7a12,12,0,0,0-16.9-16.9ZM76,128a12,12,0,0,0-12-12H32a12,12,0,0,0,0,24H64A12,12,0,0,0,76,128ZM68.6,51.6a12,12,0,1,0-17,17L74.3,91.2a12,12,0,0,0,16.9,0,11.9,11.9,0,0,0,0-16.9Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M136,32V64a8,8,0,0,1-16,0V32a8,8,0,0,1,16,0Zm88,88H192a8,8,0,0,0,0,16h32a8,8,0,0,0,0-16Zm-45.1,47.6a8,8,0,0,0-11.3,11.3l22.6,22.6a8,8,0,0,0,5.7,2.4,7.7,7.7,0,0,0,5.6-2.4,7.9,7.9,0,0,0,0-11.3ZM128,184a8,8,0,0,0-8,8v32a8,8,0,0,0,16,0V192A8,8,0,0,0,128,184ZM77.1,167.6,54.5,190.2a7.9,7.9,0,0,0,0,11.3,7.7,7.7,0,0,0,5.6,2.4,8,8,0,0,0,5.7-2.4l22.6-22.6a8,8,0,0,0-11.3-11.3ZM72,128a8,8,0,0,0-8-8H32a8,8,0,0,0,0,16H64A8,8,0,0,0,72,128ZM65.8,54.5A8,8,0,0,0,54.5,65.8L77.1,88.4a8.1,8.1,0,0,0,11.3,0,8,8,0,0,0,0-11.3Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M88.4,77.1a8,8,0,0,1,0,11.3,8.1,8.1,0,0,1-11.3,0L54.5,65.8A8,8,0,0,1,65.8,54.5ZM77.1,167.6,54.5,190.2a7.9,7.9,0,0,0,0,11.3,7.7,7.7,0,0,0,5.6,2.4,8,8,0,0,0,5.7-2.4l22.6-22.6a8,8,0,0,0-11.3-11.3ZM72,128a8,8,0,0,0-8-8H32a8,8,0,0,0,0,16H64A8,8,0,0,0,72,128ZM128,24a8,8,0,0,0-8,8V64a8,8,0,0,0,16,0V32A8,8,0,0,0,128,24Zm96,96H192a8,8,0,0,0,0,16h32a8,8,0,0,0,0-16Zm-45.1,47.6a8,8,0,0,0-11.3,11.3l22.6,22.6a8,8,0,0,0,5.7,2.4,7.7,7.7,0,0,0,5.6-2.4,7.9,7.9,0,0,0,0-11.3ZM128,184a8,8,0,0,0-8,8v32a8,8,0,0,0,16,0V192A8,8,0,0,0,128,184Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M134,32V64a6,6,0,0,1-12,0V32a6,6,0,0,1,12,0Zm90,90H192a6,6,0,0,0,0,12h32a6,6,0,0,0,0-12Zm-46.5,47a6,6,0,0,0-8.5,8.5l22.6,22.6a6,6,0,0,0,4.3,1.8,5.8,5.8,0,0,0,4.2-1.8,5.9,5.9,0,0,0,0-8.5ZM128,186a6,6,0,0,0-6,6v32a6,6,0,0,0,12,0V192A6,6,0,0,0,128,186ZM78.5,169,55.9,191.6a5.9,5.9,0,0,0,0,8.5,5.8,5.8,0,0,0,4.2,1.8,6,6,0,0,0,4.3-1.8L87,177.5a6,6,0,0,0-8.5-8.5ZM70,128a6,6,0,0,0-6-6H32a6,6,0,0,0,0,12H64A6,6,0,0,0,70,128ZM64.4,55.9a6,6,0,0,0-8.5,8.5L78.5,87a5.8,5.8,0,0,0,4.2,1.7A5.9,5.9,0,0,0,87,87a6.1,6.1,0,0,0,0-8.5Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M132,32V64a4,4,0,0,1-8,0V32a4,4,0,0,1,8,0Zm92,92H192a4,4,0,0,0,0,8h32a4,4,0,0,0,0-8Zm-47.9,46.4a4,4,0,0,0-5.7,5.7l22.7,22.6a3.8,3.8,0,0,0,2.8,1.2,3.9,3.9,0,0,0,2.8-1.2,3.9,3.9,0,0,0,0-5.6ZM128,188a4,4,0,0,0-4,4v32a4,4,0,0,0,8,0V192A4,4,0,0,0,128,188ZM79.9,170.4,57.3,193.1a3.9,3.9,0,0,0,0,5.6,3.9,3.9,0,0,0,2.8,1.2,3.8,3.8,0,0,0,2.8-1.2l22.7-22.6a4,4,0,0,0-5.7-5.7ZM68,128a4,4,0,0,0-4-4H32a4,4,0,0,0,0,8H64A4,4,0,0,0,68,128ZM62.9,57.3a4,4,0,1,0-5.6,5.6L79.9,85.6a3.8,3.8,0,0,0,2.8,1.1,3.9,3.9,0,0,0,2.9-1.1,4.2,4.2,0,0,0,0-5.7Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M136,32V64a8,8,0,0,1-16,0V32a8,8,0,0,1,16,0Zm88,88H192a8,8,0,0,0,0,16h32a8,8,0,0,0,0-16Zm-45.1,47.6a8,8,0,0,0-11.3,11.3l22.6,22.6a8,8,0,0,0,5.7,2.4,7.7,7.7,0,0,0,5.6-2.4,7.9,7.9,0,0,0,0-11.3ZM128,184a8,8,0,0,0-8,8v32a8,8,0,0,0,16,0V192A8,8,0,0,0,128,184ZM77.1,167.6,54.5,190.2a7.9,7.9,0,0,0,0,11.3,7.7,7.7,0,0,0,5.6,2.4,8,8,0,0,0,5.7-2.4l22.6-22.6a8,8,0,0,0-11.3-11.3ZM72,128a8,8,0,0,0-8-8H32a8,8,0,0,0,0,16H64A8,8,0,0,0,72,128ZM65.8,54.5A8,8,0,0,0,54.5,65.8L77.1,88.4a8.1,8.1,0,0,0,11.3,0,8,8,0,0,0,0-11.3Z" />
        </>
      )}
    </svg>
  );
}

export function PhSpinner(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M140,32V64a12,12,0,0,1-24,0V32a12,12,0,0,1,24,0Zm33.3,62.7a11.6,11.6,0,0,0,8.4-3.5l22.7-22.6a12,12,0,1,0-17-17L164.8,74.3a11.9,11.9,0,0,0,0,16.9A11.6,11.6,0,0,0,173.3,94.7ZM224,116H192a12,12,0,0,0,0,24h32a12,12,0,0,0,0-24Zm-42.3,48.8a12,12,0,0,0-16.9,16.9l22.6,22.7a12,12,0,0,0,8.5,3.5,12.2,12.2,0,0,0,8.5-3.5,12,12,0,0,0,0-17ZM128,180a12,12,0,0,0-12,12v32a12,12,0,0,0,24,0V192A12,12,0,0,0,128,180ZM74.3,164.8,51.6,187.4a12,12,0,0,0,0,17,12.2,12.2,0,0,0,8.5,3.5,12,12,0,0,0,8.5-3.5l22.6-22.7a12,12,0,0,0-16.9-16.9ZM76,128a12,12,0,0,0-12-12H32a12,12,0,0,0,0,24H64A12,12,0,0,0,76,128ZM68.6,51.6a12,12,0,1,0-17,17L74.3,91.2a12,12,0,0,0,16.9,0,11.9,11.9,0,0,0,0-16.9Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M136,32V64a8,8,0,0,1-16,0V32a8,8,0,0,1,16,0Zm37.3,58.7a7.8,7.8,0,0,0,5.6-2.3l22.6-22.6a8,8,0,1,0-11.3-11.3L167.6,77.1a8,8,0,0,0,0,11.3A7.8,7.8,0,0,0,173.3,90.7ZM224,120H192a8,8,0,0,0,0,16h32a8,8,0,0,0,0-16Zm-45.1,47.6a8,8,0,0,0-11.3,11.3l22.6,22.6a8,8,0,0,0,5.7,2.4,7.7,7.7,0,0,0,5.6-2.4,7.9,7.9,0,0,0,0-11.3ZM128,184a8,8,0,0,0-8,8v32a8,8,0,0,0,16,0V192A8,8,0,0,0,128,184ZM77.1,167.6,54.5,190.2a7.9,7.9,0,0,0,0,11.3,7.7,7.7,0,0,0,5.6,2.4,8,8,0,0,0,5.7-2.4l22.6-22.6a8,8,0,0,0-11.3-11.3ZM72,128a8,8,0,0,0-8-8H32a8,8,0,0,0,0,16H64A8,8,0,0,0,72,128ZM65.8,54.5A8,8,0,0,0,54.5,65.8L77.1,88.4a8.1,8.1,0,0,0,11.3,0,8,8,0,0,0,0-11.3Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M64,136H32a8,8,0,0,1,0-16H64a8,8,0,0,1,0,16ZM173.3,90.7a7.8,7.8,0,0,0,5.6-2.3l22.6-22.6a8,8,0,1,0-11.3-11.3L167.6,77.1a8,8,0,0,0,0,11.3A7.8,7.8,0,0,0,173.3,90.7ZM65.8,54.5A8,8,0,0,0,54.5,65.8L77.1,88.4a8.1,8.1,0,0,0,11.3,0,8,8,0,0,0,0-11.3ZM77.1,167.6,54.5,190.2a7.9,7.9,0,0,0,0,11.3,7.7,7.7,0,0,0,5.6,2.4,8,8,0,0,0,5.7-2.4l22.6-22.6a8,8,0,0,0-11.3-11.3ZM224,120H192a8,8,0,0,0,0,16h32a8,8,0,0,0,0-16Zm-45.1,47.6a8,8,0,0,0-11.3,11.3l22.6,22.6a8,8,0,0,0,5.7,2.4,7.7,7.7,0,0,0,5.6-2.4,7.9,7.9,0,0,0,0-11.3ZM128,184a8,8,0,0,0-8,8v32a8,8,0,0,0,16,0V192A8,8,0,0,0,128,184Zm0-160a8,8,0,0,0-8,8V64a8,8,0,0,0,16,0V32A8,8,0,0,0,128,24Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M134,32V64a6,6,0,0,1-12,0V32a6,6,0,0,1,12,0Zm39.3,56.7a5.8,5.8,0,0,0,4.2-1.7l22.6-22.6a6,6,0,0,0-8.5-8.5L169,78.5a6.1,6.1,0,0,0,0,8.5A5.9,5.9,0,0,0,173.3,88.7ZM224,122H192a6,6,0,0,0,0,12h32a6,6,0,0,0,0-12Zm-46.5,47a6,6,0,0,0-8.5,8.5l22.6,22.6a6,6,0,0,0,4.3,1.8,5.8,5.8,0,0,0,4.2-1.8,5.9,5.9,0,0,0,0-8.5ZM128,186a6,6,0,0,0-6,6v32a6,6,0,0,0,12,0V192A6,6,0,0,0,128,186ZM78.5,169,55.9,191.6a5.9,5.9,0,0,0,0,8.5,5.8,5.8,0,0,0,4.2,1.8,6,6,0,0,0,4.3-1.8L87,177.5a6,6,0,0,0-8.5-8.5ZM70,128a6,6,0,0,0-6-6H32a6,6,0,0,0,0,12H64A6,6,0,0,0,70,128ZM64.4,55.9a6,6,0,0,0-8.5,8.5L78.5,87a5.8,5.8,0,0,0,4.2,1.7A5.9,5.9,0,0,0,87,87a6.1,6.1,0,0,0,0-8.5Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M132,32V64a4,4,0,0,1-8,0V32a4,4,0,0,1,8,0Zm41.3,54.7a3.8,3.8,0,0,0,2.8-1.1l22.6-22.7a4,4,0,0,0-5.6-5.6L170.4,79.9a4.2,4.2,0,0,0,0,5.7A3.9,3.9,0,0,0,173.3,86.7ZM224,124H192a4,4,0,0,0,0,8h32a4,4,0,0,0,0-8Zm-47.9,46.4a4,4,0,0,0-5.7,5.7l22.7,22.6a3.8,3.8,0,0,0,2.8,1.2,3.9,3.9,0,0,0,2.8-1.2,3.9,3.9,0,0,0,0-5.6ZM128,188a4,4,0,0,0-4,4v32a4,4,0,0,0,8,0V192A4,4,0,0,0,128,188ZM79.9,170.4,57.3,193.1a3.9,3.9,0,0,0,0,5.6,3.9,3.9,0,0,0,2.8,1.2,3.8,3.8,0,0,0,2.8-1.2l22.7-22.6a4,4,0,0,0-5.7-5.7ZM68,128a4,4,0,0,0-4-4H32a4,4,0,0,0,0,8H64A4,4,0,0,0,68,128ZM62.9,57.3a4,4,0,1,0-5.6,5.6L79.9,85.6a3.8,3.8,0,0,0,2.8,1.1,3.9,3.9,0,0,0,2.9-1.1,4.2,4.2,0,0,0,0-5.7Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M136,32V64a8,8,0,0,1-16,0V32a8,8,0,0,1,16,0Zm37.3,58.7a7.8,7.8,0,0,0,5.6-2.3l22.6-22.6a8,8,0,1,0-11.3-11.3L167.6,77.1a8,8,0,0,0,0,11.3A7.8,7.8,0,0,0,173.3,90.7ZM224,120H192a8,8,0,0,0,0,16h32a8,8,0,0,0,0-16Zm-45.1,47.6a8,8,0,0,0-11.3,11.3l22.6,22.6a8,8,0,0,0,5.7,2.4,7.7,7.7,0,0,0,5.6-2.4,7.9,7.9,0,0,0,0-11.3ZM128,184a8,8,0,0,0-8,8v32a8,8,0,0,0,16,0V192A8,8,0,0,0,128,184ZM77.1,167.6,54.5,190.2a7.9,7.9,0,0,0,0,11.3,7.7,7.7,0,0,0,5.6,2.4,8,8,0,0,0,5.7-2.4l22.6-22.6a8,8,0,0,0-11.3-11.3ZM72,128a8,8,0,0,0-8-8H32a8,8,0,0,0,0,16H64A8,8,0,0,0,72,128ZM65.8,54.5A8,8,0,0,0,54.5,65.8L77.1,88.4a8.1,8.1,0,0,0,11.3,0,8,8,0,0,0,0-11.3Z" />
        </>
      )}
    </svg>
  );
}

export function PhSpiral(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M250,138a12,12,0,0,1-24,0,94.1,94.1,0,0,0-94-94,84.1,84.1,0,0,0-84,84,74.1,74.1,0,0,0,74,74,64.1,64.1,0,0,0,64-64,54,54,0,0,0-54-54,44,44,0,0,0-44,44,34.1,34.1,0,0,0,34,34,24.1,24.1,0,0,0,24-24,14,14,0,0,0-14-14,3.6,3.6,0,0,0-2.8,1.2A12,12,0,0,1,124,148a20.1,20.1,0,0,1-20-20,28.1,28.1,0,0,1,28-28,38,38,0,0,1,38,38,48,48,0,0,1-48,48,58,58,0,0,1-58-58,68.1,68.1,0,0,1,68-68,78.1,78.1,0,0,1,78,78,88.1,88.1,0,0,1-88,88,98.2,98.2,0,0,1-98-98A108.1,108.1,0,0,1,132,20,118.1,118.1,0,0,1,250,138Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M248,144a8,8,0,0,1-16,0,96.2,96.2,0,0,0-96-96,88.1,88.1,0,0,0-88,88,80.1,80.1,0,0,0,80,80,72.1,72.1,0,0,0,72-72,64.1,64.1,0,0,0-64-64,56,56,0,0,0-56,56,48,48,0,0,0,48,48,40,40,0,0,0,40-40,32.1,32.1,0,0,0-32-32,24.1,24.1,0,0,0-24,24,16,16,0,0,0,16,16,8,8,0,0,0,8-8,8,8,0,0,1,0-16,16,16,0,0,1,16,16,24.1,24.1,0,0,1-24,24,32.1,32.1,0,0,1-32-32,40,40,0,0,1,40-40,48,48,0,0,1,48,48,56,56,0,0,1-56,56,64.1,64.1,0,0,1-64-64,72.1,72.1,0,0,1,72-72,80.1,80.1,0,0,1,80,80,88.1,88.1,0,0,1-88,88,96.2,96.2,0,0,1-96-96A104.2,104.2,0,0,1,136,32,112.1,112.1,0,0,1,248,144Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M248,144a8,8,0,0,1-16,0,96.2,96.2,0,0,0-96-96,88.1,88.1,0,0,0-88,88,80.1,80.1,0,0,0,80,80,72.1,72.1,0,0,0,72-72,64.1,64.1,0,0,0-64-64,56,56,0,0,0-56,56,48,48,0,0,0,48,48,40,40,0,0,0,40-40,32.1,32.1,0,0,0-32-32,24.1,24.1,0,0,0-24,24,16,16,0,0,0,16,16,8,8,0,0,0,8-8,8,8,0,0,1,0-16,16,16,0,0,1,16,16,24.1,24.1,0,0,1-24,24,32.1,32.1,0,0,1-32-32,40,40,0,0,1,40-40,48,48,0,0,1,48,48,56,56,0,0,1-56,56,64.1,64.1,0,0,1-64-64,72.1,72.1,0,0,1,72-72,80.1,80.1,0,0,1,80,80,88.1,88.1,0,0,1-88,88,96.2,96.2,0,0,1-96-96A104.2,104.2,0,0,1,136,32,112.1,112.1,0,0,1,248,144Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M246,144a6,6,0,0,1-12,0,98.2,98.2,0,0,0-98-98,90.1,90.1,0,0,0-90,90,82.1,82.1,0,0,0,82,82,74.1,74.1,0,0,0,74-74,66.1,66.1,0,0,0-66-66,58,58,0,0,0-58,58,50,50,0,0,0,50,50,42,42,0,0,0,42-42,34.1,34.1,0,0,0-34-34,26.1,26.1,0,0,0-26,26,18.1,18.1,0,0,0,18,18,10,10,0,0,0,10-10,2,2,0,0,0-2-2,6,6,0,0,1,0-12,14,14,0,0,1,14,14,22.1,22.1,0,0,1-22,22,30.1,30.1,0,0,1-30-30,38,38,0,0,1,38-38,46,46,0,0,1,46,46,54,54,0,0,1-54,54,62.1,62.1,0,0,1-62-62,70.1,70.1,0,0,1,70-70,78.1,78.1,0,0,1,78,78,86.1,86.1,0,0,1-86,86,94.1,94.1,0,0,1-94-94A102.2,102.2,0,0,1,136,34,110.1,110.1,0,0,1,246,144Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M244,144a4,4,0,0,1-8,0A100.2,100.2,0,0,0,136,44a92.1,92.1,0,0,0-92,92,84.1,84.1,0,0,0,84,84,76.1,76.1,0,0,0,76-76,68.1,68.1,0,0,0-68-68,60,60,0,0,0-60,60,52,52,0,0,0,52,52,44,44,0,0,0,44-44,36,36,0,0,0-36-36,28.1,28.1,0,0,0-28,28,20.1,20.1,0,0,0,20,20,12,12,0,0,0,12-12,4,4,0,0,0-4-4,4,4,0,0,1,0-8,12,12,0,0,1,12,12,20.1,20.1,0,0,1-20,20,28.1,28.1,0,0,1-28-28,36,36,0,0,1,36-36,44,44,0,0,1,44,44,52,52,0,0,1-52,52,60,60,0,0,1-60-60,68.1,68.1,0,0,1,68-68,76.1,76.1,0,0,1,76,76,84.1,84.1,0,0,1-84,84,92.1,92.1,0,0,1-92-92A100.2,100.2,0,0,1,136,36,108.1,108.1,0,0,1,244,144Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M248,144a8,8,0,0,1-16,0,96.2,96.2,0,0,0-96-96,88.1,88.1,0,0,0-88,88,80.1,80.1,0,0,0,80,80,72.1,72.1,0,0,0,72-72,64.1,64.1,0,0,0-64-64,56,56,0,0,0-56,56,48,48,0,0,0,48,48,40,40,0,0,0,40-40,32.1,32.1,0,0,0-32-32,24.1,24.1,0,0,0-24,24,16,16,0,0,0,16,16,8,8,0,0,0,8-8,8,8,0,0,1,0-16,16,16,0,0,1,16,16,24.1,24.1,0,0,1-24,24,32.1,32.1,0,0,1-32-32,40,40,0,0,1,40-40,48,48,0,0,1,48,48,56,56,0,0,1-56,56,64.1,64.1,0,0,1-64-64,72.1,72.1,0,0,1,72-72,80.1,80.1,0,0,1,80,80,88.1,88.1,0,0,1-88,88,96.2,96.2,0,0,1-96-96A104.2,104.2,0,0,1,136,32,112.1,112.1,0,0,1,248,144Z" />
        </>
      )}
    </svg>
  );
}

export function PhSpotifyLogo(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128.1,20a108,108,0,1,0,108,108A108.1,108.1,0,0,0,128.1,20Zm0,192a84,84,0,1,1,84-84A84.1,84.1,0,0,1,128.1,212Zm61.8-90.2a12.1,12.1,0,0,1-16.2,5.2,100.4,100.4,0,0,0-91.4,0,10.7,10.7,0,0,1-5.5,1.4,12,12,0,0,1-5.5-22.7,124.2,124.2,0,0,1,113.4,0A11.9,11.9,0,0,1,189.9,121.8Zm-20.4,39a11.9,11.9,0,0,1-16.1,5.2,55.7,55.7,0,0,0-25.3-6,54.4,54.4,0,0,0-25.4,6.1,12,12,0,0,1-5.5,1.3,12.1,12.1,0,0,1-10.7-6.6,12,12,0,0,1,5.3-16.1,80.1,80.1,0,0,1,72.4-.1A12.1,12.1,0,0,1,169.5,160.8Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M224.1,128a96,96,0,1,1-96-96A96,96,0,0,1,224.1,128Z"
            opacity="0.2"
          />
          <path d="M128.1,24a104,104,0,1,0,104,104A104.1,104.1,0,0,0,128.1,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128.1,216Zm58.2-104a7.9,7.9,0,0,1-10.8,3.4,104.3,104.3,0,0,0-95,.1,8.1,8.1,0,0,1-3.7.9,8,8,0,0,1-3.6-15.1,119.9,119.9,0,0,1,109.6-.1A8,8,0,0,1,186.3,112Zm-14.8,28.3a8,8,0,0,1-10.8,3.5,72,72,0,0,0-65.4.1,8,8,0,0,1-3.6.9A8,8,0,0,1,88,129.6a88.1,88.1,0,0,1,80,0A8,8,0,0,1,171.5,140.3Zm-14.8,28.4a8.1,8.1,0,0,1-10.8,3.5,39.8,39.8,0,0,0-35.8.1,9.4,9.4,0,0,1-3.6.8,8,8,0,0,1-3.6-15.1,55,55,0,0,1,25.2-6,55.7,55.7,0,0,1,25,5.9A8,8,0,0,1,156.7,168.7Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128.1,24a104,104,0,1,0,104,104A104.1,104.1,0,0,0,128.1,24Zm28.6,144.7a8.1,8.1,0,0,1-10.8,3.5,39.8,39.8,0,0,0-35.8.1,9.4,9.4,0,0,1-3.6.8,8,8,0,0,1-3.6-15.1,55,55,0,0,1,25.2-6,55.7,55.7,0,0,1,25,5.9A8,8,0,0,1,156.7,168.7Zm14.8-28.4a8,8,0,0,1-10.8,3.5,72,72,0,0,0-65.4.1,8,8,0,0,1-3.6.9A8,8,0,0,1,88,129.6a88.1,88.1,0,0,1,80,0A8,8,0,0,1,171.5,140.3ZM186.3,112a7.9,7.9,0,0,1-10.8,3.4,104.3,104.3,0,0,0-95,.1,8.1,8.1,0,0,1-3.7.9,8,8,0,0,1-3.6-15.1,119.9,119.9,0,0,1,109.6-.1A8,8,0,0,1,186.3,112Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128.1,26a102,102,0,1,0,102,102A102.1,102.1,0,0,0,128.1,26Zm0,192a90,90,0,1,1,90-90A90.1,90.1,0,0,1,128.1,218Zm56.4-106.9a6.1,6.1,0,0,1-8.1,2.6,106,106,0,0,0-96.8,0,5.5,5.5,0,0,1-2.8.7A6,6,0,0,1,74.1,103a118.5,118.5,0,0,1,54-13,116.7,116.7,0,0,1,53.8,13A6,6,0,0,1,184.5,111.1Zm-14.8,28.3a6,6,0,0,1-8.1,2.7,73.7,73.7,0,0,0-67.2,0,6.8,6.8,0,0,1-2.7.7,6,6,0,0,1-2.8-11.4,86,86,0,0,1,78.2,0A6,6,0,0,1,169.7,139.4Zm-14.8,28.4a6.1,6.1,0,0,1-8.1,2.6,41.8,41.8,0,0,0-18.7-4.4,41.1,41.1,0,0,0-18.9,4.5,6.6,6.6,0,0,1-2.7.6,6.1,6.1,0,0,1-5.4-3.3,5.9,5.9,0,0,1,2.7-8,52.4,52.4,0,0,1,24.3-5.8,53.1,53.1,0,0,1,24.1,5.7A6,6,0,0,1,154.9,167.8Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128.1,28a100,100,0,1,0,100,100A100.1,100.1,0,0,0,128.1,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128.1,220Zm54.6-109.8a4,4,0,0,1-5.3,1.7,108.3,108.3,0,0,0-98.7,0,4.1,4.1,0,0,1-1.9.5,4,4,0,0,1-1.8-7.6,116.1,116.1,0,0,1,106,0A3.9,3.9,0,0,1,182.7,110.2Zm-14.8,28.3a4,4,0,0,1-5.4,1.8,74.6,74.6,0,0,0-34.4-8.3,75.4,75.4,0,0,0-34.6,8.3,4.1,4.1,0,0,1-1.8.5,4,4,0,0,1-3.6-2.2,4.1,4.1,0,0,1,1.7-5.4,83.9,83.9,0,0,1,76.4,0A4,4,0,0,1,167.9,138.5Zm-14.8,28.4a4.1,4.1,0,0,1-5.4,1.7,44.1,44.1,0,0,0-39.4.1,3.9,3.9,0,0,1-1.8.4,4,4,0,0,1-1.8-7.6,52.6,52.6,0,0,1,23.4-5.5,50.8,50.8,0,0,1,23.2,5.5A4,4,0,0,1,153.1,166.9Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128.1,24a104,104,0,1,0,104,104A104.1,104.1,0,0,0,128.1,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128.1,216Zm58.2-104a7.9,7.9,0,0,1-10.8,3.4,104.3,104.3,0,0,0-95,.1,8.1,8.1,0,0,1-3.7.9,8,8,0,0,1-3.6-15.1,119.9,119.9,0,0,1,109.6-.1A8,8,0,0,1,186.3,112Zm-14.8,28.3a8,8,0,0,1-10.8,3.5,72,72,0,0,0-65.4.1,8,8,0,0,1-3.6.9A8,8,0,0,1,88,129.6a88.1,88.1,0,0,1,80,0A8,8,0,0,1,171.5,140.3Zm-14.8,28.4a8.1,8.1,0,0,1-10.8,3.5,39.8,39.8,0,0,0-35.8.1,9.4,9.4,0,0,1-3.6.8,8,8,0,0,1-3.6-15.1,55,55,0,0,1,25.2-6,55.7,55.7,0,0,1,25,5.9A8,8,0,0,1,156.7,168.7Z" />
        </>
      )}
    </svg>
  );
}

export function PhSquareHalfBottom(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M204,32H52A20.1,20.1,0,0,0,32,52V204a20.1,20.1,0,0,0,20,20H204a20.1,20.1,0,0,0,20-20V52A20.1,20.1,0,0,0,204,32Zm-4,24v60H56V56Zm-84,84v60H96V140Zm24,0h20v60H140Zm-84,0H72v60H56Zm128,60V140h16v60Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M212,128v76a8,8,0,0,1-8,8H52a8,8,0,0,1-8-8V128Z"
            opacity="0.2"
          />
          <path d="M204,36H52A16,16,0,0,0,36,52V204a16,16,0,0,0,16,16H204a16,16,0,0,0,16-16V52A16,16,0,0,0,204,36Zm0,16v68H52V52Zm0,152H52V136H204v68Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M204,36H52A16,16,0,0,0,36,52V204a16,16,0,0,0,16,16H204a16,16,0,0,0,16-16V52A16,16,0,0,0,204,36Zm0,16v76H52V52Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M204,38H52A14,14,0,0,0,38,52V204a14,14,0,0,0,14,14H204a14,14,0,0,0,14-14V52A14,14,0,0,0,204,38ZM52,50H204a2,2,0,0,1,2,2v70H50V52A2,2,0,0,1,52,50Zm54,84v72H86V134Zm12,0h20v72H118Zm32,0h20v72H150ZM50,204V134H74v72H52A2,2,0,0,1,50,204Zm154,2H182V134h24v70A2,2,0,0,1,204,206Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M204,40H52A12,12,0,0,0,40,52V204a12,12,0,0,0,12,12H204a12,12,0,0,0,12-12V52A12,12,0,0,0,204,40ZM52,48H204a4,4,0,0,1,4,4v72H48V52A4,4,0,0,1,52,48Zm56,84v76H84V132Zm8,0h24v76H116Zm32,0h24v76H148ZM48,204V132H76v76H52A4,4,0,0,1,48,204Zm156,4H180V132h28v72A4,4,0,0,1,204,208Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M204,36H52A16,16,0,0,0,36,52V204a16,16,0,0,0,16,16H204a16,16,0,0,0,16-16V52A16,16,0,0,0,204,36Zm0,16v68H52V52ZM104,136v68H88V136Zm16,0h16v68H120Zm32,0h16v68H152ZM52,136H72v68H52Zm152,68H184V136h20v68Z" />
        </>
      )}
    </svg>
  );
}

export function PhSquareHalf(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M204,32H52A20.1,20.1,0,0,0,32,52V204a20.1,20.1,0,0,0,20,20H204a20.1,20.1,0,0,0,20-20V52A20.1,20.1,0,0,0,204,32ZM140,96h60v20H140Zm0,44h60v20H140Zm60-68H140V56h60ZM56,56h60V200H56Zm84,144V184h60v16Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M212,52V204a8,8,0,0,1-8,8H128V44h76A8,8,0,0,1,212,52Z"
            opacity="0.2"
          />
          <path d="M204,36H52A16,16,0,0,0,36,52V204a16,16,0,0,0,16,16H204a16,16,0,0,0,16-16V52A16,16,0,0,0,204,36ZM52,52h68V204H52ZM204,204H136V52h68V204Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M204,36H52A16,16,0,0,0,36,52V204a16,16,0,0,0,16,16H204a16,16,0,0,0,16-16V52A16,16,0,0,0,204,36ZM52,52h76V204H52Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M204,38H52A14,14,0,0,0,38,52V204a14,14,0,0,0,14,14H204a14,14,0,0,0,14-14V52A14,14,0,0,0,204,38Zm-70,80h72v20H134Zm0-12V86h72v20Zm0,44h72v20H134Zm72-98V74H134V50h70A2,2,0,0,1,206,52ZM50,204V52a2,2,0,0,1,2-2h70V206H52A2,2,0,0,1,50,204Zm154,2H134V182h72v22A2,2,0,0,1,204,206Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M204,40H52A12,12,0,0,0,40,52V204a12,12,0,0,0,12,12H204a12,12,0,0,0,12-12V52A12,12,0,0,0,204,40Zm-72,76h76v24H132Zm0-8V84h76v24Zm0,40h76v24H132Zm76-96V76H132V48h72A4,4,0,0,1,208,52ZM48,204V52a4,4,0,0,1,4-4h72V208H52A4,4,0,0,1,48,204Zm156,4H132V180h76v24A4,4,0,0,1,204,208Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M204,36H52A16,16,0,0,0,36,52V204a16,16,0,0,0,16,16H204a16,16,0,0,0,16-16V52A16,16,0,0,0,204,36Zm-68,84h68v16H136Zm0-16V88h68v16Zm0,48h68v16H136Zm68-80H136V52h68ZM52,52h68V204H52ZM204,204H136V184h68v20Z" />
        </>
      )}
    </svg>
  );
}

export function PhSquareLogo(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M208,28H48A20.1,20.1,0,0,0,28,48V208a20.1,20.1,0,0,0,20,20H208a20.1,20.1,0,0,0,20-20V48A20.1,20.1,0,0,0,208,28Zm-4,176H52V52H204ZM96,172h64a12,12,0,0,0,12-12V96a12,12,0,0,0-12-12H96A12,12,0,0,0,84,96v64A12,12,0,0,0,96,172Zm12-64h40v40H108Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M160,96v64H96V96Z" opacity="0.2" />
          <path d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32Zm0,176H48V48H208V208ZM160,88H96a8,8,0,0,0-8,8v64a8,8,0,0,0,8,8h64a8,8,0,0,0,8-8V96A8,8,0,0,0,160,88Zm-8,64H104V104h48Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M168,96v64a8,8,0,0,1-8,8H96a8,8,0,0,1-8-8V96a8,8,0,0,1,8-8h64A8,8,0,0,1,168,96Zm56-48V208a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V48A16,16,0,0,1,48,32H208A16,16,0,0,1,224,48ZM208,208V48H48V208Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M208,34H48A14,14,0,0,0,34,48V208a14,14,0,0,0,14,14H208a14,14,0,0,0,14-14V48A14,14,0,0,0,208,34Zm2,174a2,2,0,0,1-2,2H48a2,2,0,0,1-2-2V48a2,2,0,0,1,2-2H208a2,2,0,0,1,2,2ZM160,90H96a6,6,0,0,0-6,6v64a6,6,0,0,0,6,6h64a6,6,0,0,0,6-6V96A6,6,0,0,0,160,90Zm-6,64H102V102h52Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M208,36H48A12,12,0,0,0,36,48V208a12,12,0,0,0,12,12H208a12,12,0,0,0,12-12V48A12,12,0,0,0,208,36Zm4,172a4,4,0,0,1-4,4H48a4,4,0,0,1-4-4V48a4,4,0,0,1,4-4H208a4,4,0,0,1,4,4ZM160,92H96a4,4,0,0,0-4,4v64a4,4,0,0,0,4,4h64a4,4,0,0,0,4-4V96A4,4,0,0,0,160,92Zm-4,64H100V100h56Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32Zm0,176H48V48H208V208ZM160,88H96a8,8,0,0,0-8,8v64a8,8,0,0,0,8,8h64a8,8,0,0,0,8-8V96A8,8,0,0,0,160,88Zm-8,64H104V104h48Z" />
        </>
      )}
    </svg>
  );
}

export function PhSquare(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M208,228H48a20.1,20.1,0,0,1-20-20V48A20.1,20.1,0,0,1,48,28H208a20.1,20.1,0,0,1,20,20V208A20.1,20.1,0,0,1,208,228ZM52,204H204V52H52Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect x="40" y="40" width="176" height="176" rx="8" />
          </g>
          <path d="M208,224H48a16,16,0,0,1-16-16V48A16,16,0,0,1,48,32H208a16,16,0,0,1,16,16V208A16,16,0,0,1,208,224ZM48,48V208H208V48Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M224,48V208a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V48A16,16,0,0,1,48,32H208A16,16,0,0,1,224,48Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M208,222H48a14,14,0,0,1-14-14V48A14,14,0,0,1,48,34H208a14,14,0,0,1,14,14V208A14,14,0,0,1,208,222ZM48,46a2,2,0,0,0-2,2V208a2,2,0,0,0,2,2H208a2,2,0,0,0,2-2V48a2,2,0,0,0-2-2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M208,220H48a12,12,0,0,1-12-12V48A12,12,0,0,1,48,36H208a12,12,0,0,1,12,12V208A12,12,0,0,1,208,220ZM48,44a4,4,0,0,0-4,4V208a4,4,0,0,0,4,4H208a4,4,0,0,0,4-4V48a4,4,0,0,0-4-4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M208,224H48a16,16,0,0,1-16-16V48A16,16,0,0,1,48,32H208a16,16,0,0,1,16,16V208A16,16,0,0,1,208,224ZM48,48V208H208V48Z" />
        </>
      )}
    </svg>
  );
}

export function PhSquaresFour(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M108,36H48A12,12,0,0,0,36,48v60a12,12,0,0,0,12,12h60a12,12,0,0,0,12-12V48A12,12,0,0,0,108,36ZM96,96H60V60H96Z" />
          <path d="M208,36H148a12,12,0,0,0-12,12v60a12,12,0,0,0,12,12h60a12,12,0,0,0,12-12V48A12,12,0,0,0,208,36ZM196,96H160V60h36Z" />
          <path d="M108,136H48a12,12,0,0,0-12,12v60a12,12,0,0,0,12,12h60a12,12,0,0,0,12-12V148A12,12,0,0,0,108,136ZM96,196H60V160H96Z" />
          <path d="M208,136H148a12,12,0,0,0-12,12v60a12,12,0,0,0,12,12h60a12,12,0,0,0,12-12V148A12,12,0,0,0,208,136Zm-12,60H160V160h36Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect x="48" y="48" width="64" height="64" />
          </g>
          <g opacity="0.2">
            <rect x="144" y="48" width="64" height="64" />
          </g>
          <g opacity="0.2">
            <rect x="48" y="144" width="64" height="64" />
          </g>
          <g opacity="0.2">
            <rect x="144" y="144" width="64" height="64" />
          </g>
          <path d="M208,136H144a8,8,0,0,0-8,8v64a8,8,0,0,0,8,8h64a8,8,0,0,0,8-8V144A8,8,0,0,0,208,136Zm-8,64H152V152h48Z" />
          <path d="M112,40H48a8,8,0,0,0-8,8v64a8,8,0,0,0,8,8h64a8,8,0,0,0,8-8V48A8,8,0,0,0,112,40Zm-8,64H56V56h48Z" />
          <path d="M208,40H144a8,8,0,0,0-8,8v64a8,8,0,0,0,8,8h64a8,8,0,0,0,8-8V48A8,8,0,0,0,208,40Zm-8,64H152V56h48Z" />
          <path d="M112,136H48a8,8,0,0,0-8,8v64a8,8,0,0,0,8,8h64a8,8,0,0,0,8-8V144A8,8,0,0,0,112,136Zm-8,64H56V152h48Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M120,48v64a8,8,0,0,1-8,8H48a8,8,0,0,1-8-8V48a8,8,0,0,1,8-8h64A8,8,0,0,1,120,48Zm88-8H144a8,8,0,0,0-8,8v64a8,8,0,0,0,8,8h64a8,8,0,0,0,8-8V48A8,8,0,0,0,208,40Zm-96,96H48a8,8,0,0,0-8,8v64a8,8,0,0,0,8,8h64a8,8,0,0,0,8-8V144A8,8,0,0,0,112,136Zm96,0H144a8,8,0,0,0-8,8v64a8,8,0,0,0,8,8h64a8,8,0,0,0,8-8V144A8,8,0,0,0,208,136Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M112,42H48a6,6,0,0,0-6,6v64a6,6,0,0,0,6,6h64a6,6,0,0,0,6-6V48A6,6,0,0,0,112,42Zm-6,64H54V54h52ZM208,42H144a6,6,0,0,0-6,6v64a6,6,0,0,0,6,6h64a6,6,0,0,0,6-6V48A6,6,0,0,0,208,42Zm-6,64H150V54h52Zm-90,32H48a6,6,0,0,0-6,6v64a6,6,0,0,0,6,6h64a6,6,0,0,0,6-6V144A6,6,0,0,0,112,138Zm-6,64H54V150h52Zm102-64H144a6,6,0,0,0-6,6v64a6,6,0,0,0,6,6h64a6,6,0,0,0,6-6V144A6,6,0,0,0,208,138Zm-6,64H150V150h52Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M112,44H48a4,4,0,0,0-4,4v64a4,4,0,0,0,4,4h64a4,4,0,0,0,4-4V48A4,4,0,0,0,112,44Zm-4,64H52V52h56ZM208,44H144a4,4,0,0,0-4,4v64a4,4,0,0,0,4,4h64a4,4,0,0,0,4-4V48A4,4,0,0,0,208,44Zm-4,64H148V52h56Zm-92,32H48a4,4,0,0,0-4,4v64a4,4,0,0,0,4,4h64a4,4,0,0,0,4-4V144A4,4,0,0,0,112,140Zm-4,64H52V148h56Zm100-64H144a4,4,0,0,0-4,4v64a4,4,0,0,0,4,4h64a4,4,0,0,0,4-4V144A4,4,0,0,0,208,140Zm-4,64H148V148h56Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M112,40H48a8,8,0,0,0-8,8v64a8,8,0,0,0,8,8h64a8,8,0,0,0,8-8V48A8,8,0,0,0,112,40Zm-8,64H56V56h48ZM208,40H144a8,8,0,0,0-8,8v64a8,8,0,0,0,8,8h64a8,8,0,0,0,8-8V48A8,8,0,0,0,208,40Zm-8,64H152V56h48Zm-88,32H48a8,8,0,0,0-8,8v64a8,8,0,0,0,8,8h64a8,8,0,0,0,8-8V144A8,8,0,0,0,112,136Zm-8,64H56V152h48Zm104-64H144a8,8,0,0,0-8,8v64a8,8,0,0,0,8,8h64a8,8,0,0,0,8-8V144A8,8,0,0,0,208,136Zm-8,64H152V152h48Z" />
        </>
      )}
    </svg>
  );
}

export function PhStackOverflowLogo(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M220,152v64a12,12,0,0,1-12,12H48a12,12,0,0,1-12-12V152a12,12,0,0,1,24,0v52H196V152a12,12,0,0,1,24,0ZM88,184h80a12,12,0,0,0,0-24H88a12,12,0,0,0,0,24Zm8.2-70.5,75.2,27.4a11.4,11.4,0,0,0,4.1.7,12,12,0,0,0,4.1-23.3L104.4,91a12,12,0,1,0-8.2,22.5ZM128,50.1l61.3,51.4a11.8,11.8,0,0,0,7.7,2.8,12.2,12.2,0,0,0,9.2-4.3,11.9,11.9,0,0,0-1.5-16.9L143.4,31.7A12,12,0,0,0,128,50.1Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M216,152v64a8,8,0,0,1-8,8H48a8,8,0,0,1-8-8V152a8,8,0,0,1,16,0v56H200V152a8,8,0,0,1,16,0ZM88,184h80a8,8,0,0,0,0-16H88a8,8,0,0,0,0,16Zm4.9-53.1,77.3,20.7a6.4,6.4,0,0,0,2,.3,8,8,0,0,0,2.1-15.7L97,115.5a8,8,0,1,0-4.1,15.4Zm18.4-50,69.3,40a7.3,7.3,0,0,0,4,1.1,7.7,7.7,0,0,0,6.9-4,7.9,7.9,0,0,0-2.9-10.9l-69.3-40a8,8,0,0,0-8,13.8ZM198.7,94a8,8,0,0,0,5.6,2.3A8.3,8.3,0,0,0,210,94a8,8,0,0,0,0-11.3L153.4,26.1a8,8,0,0,0-11.3,11.3Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M108.4,70a8,8,0,0,1,10.9-2.9l69.3,40a7.9,7.9,0,0,1,2.9,10.9,7.7,7.7,0,0,1-6.9,4,7.3,7.3,0,0,1-4-1.1l-69.3-40A8,8,0,0,1,108.4,70Zm90.3,24a8,8,0,0,0,5.6,2.3A8.3,8.3,0,0,0,210,94a8,8,0,0,0,0-11.3L153.4,26.1a8,8,0,0,0-11.3,11.3ZM92.9,130.9l77.3,20.7a6.4,6.4,0,0,0,2,.3,8,8,0,0,0,2.1-15.7L97,115.5a8,8,0,1,0-4.1,15.4ZM208,144a8,8,0,0,0-8,8v56H56V152a8,8,0,0,0-16,0v64a8,8,0,0,0,8,8H208a8,8,0,0,0,8-8V152A8,8,0,0,0,208,144ZM88,184h80a8,8,0,0,0,0-16H88a8,8,0,0,0,0,16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M214,152v64a6,6,0,0,1-6,6H48a6,6,0,0,1-6-6V152a6,6,0,0,1,12,0v58H202V152a6,6,0,0,1,12,0ZM88,182h80a6,6,0,0,0,0-12H88a6,6,0,0,0,0,12Zm5.4-53,77.3,20.7,1.5.2a6,6,0,0,0,1.6-11.8L96.5,117.4a5.9,5.9,0,0,0-7.3,4.2A6,6,0,0,0,93.4,129Zm18.9-49.8,69.3,40a6.7,6.7,0,0,0,3,.8,6,6,0,0,0,3-11.2l-69.3-40a6,6,0,0,0-8.2,2.2A6.1,6.1,0,0,0,112.3,79.2Zm87.8,13.4a6.1,6.1,0,0,0,4.2,1.7,6.3,6.3,0,0,0,4.3-1.7,6.1,6.1,0,0,0,0-8.5L152,27.5a6,6,0,0,0-8.5,8.5Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M212,152v64a4,4,0,0,1-4,4H48a4,4,0,0,1-4-4V152a4,4,0,0,1,8,0v60H204V152a4,4,0,0,1,8,0ZM88,180h80a4,4,0,0,0,0-8H88a4,4,0,0,0,0,8Zm5.9-52.9,77.3,20.7h1a4,4,0,0,0,1.1-7.9L96,119.3a4.1,4.1,0,0,0-4.9,2.9A4,4,0,0,0,93.9,127.1Zm19.4-49.6,69.3,40a4.6,4.6,0,0,0,2,.5,4,4,0,0,0,2-7.5l-69.3-40a4,4,0,0,0-5.4,1.5A4,4,0,0,0,113.3,77.5Zm88.2,13.6a3.9,3.9,0,0,0,2.8,1.2,3.8,3.8,0,0,0,2.8-1.2,3.9,3.9,0,0,0,0-5.6L150.6,28.9a4,4,0,0,0-5.7,5.7Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,152v64a8,8,0,0,1-8,8H48a8,8,0,0,1-8-8V152a8,8,0,0,1,16,0v56H200V152a8,8,0,0,1,16,0ZM88,184h80a8,8,0,0,0,0-16H88a8,8,0,0,0,0,16Zm4.9-53.1,77.3,20.7a6.4,6.4,0,0,0,2,.3,8,8,0,0,0,2.1-15.7L97,115.5a8,8,0,1,0-4.1,15.4Zm18.4-50,69.3,40a7.3,7.3,0,0,0,4,1.1,7.7,7.7,0,0,0,6.9-4,7.9,7.9,0,0,0-2.9-10.9l-69.3-40a8,8,0,0,0-8,13.8ZM198.7,94a8,8,0,0,0,5.6,2.3A8.3,8.3,0,0,0,210,94a8,8,0,0,0,0-11.3L153.4,26.1a8,8,0,0,0-11.3,11.3Z" />
        </>
      )}
    </svg>
  );
}

export function PhStackSimple(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M10,110.4l112,64a12.1,12.1,0,0,0,12,0l112-64a12,12,0,0,0,0-20.8l-112-64a12.2,12.2,0,0,0-12,0L10,89.6a12,12,0,0,0,0,20.8ZM128,49.8,215.8,100,128,150.2,40.2,100ZM250.4,142a12,12,0,0,1-4.4,16.4l-112,64a12,12,0,0,1-12,0l-112-64a12,12,0,1,1,12-20.8l106,60.6,106-60.6A12,12,0,0,1,250.4,142Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <polygon points="16 104 128 168 240 104 128 40 16 104" />
          </g>
          <path d="M12,110.9l112,64a7.8,7.8,0,0,0,8,0l112-64a8,8,0,0,0,0-13.8l-112-64a7.7,7.7,0,0,0-8,0L12,97.1a8,8,0,0,0,0,13.8ZM128,49.2,223.9,104,128,158.8,32.1,104Z" />
          <path d="M236,137.1,128,198.8,20,137.1a8,8,0,0,0-8,13.8l112,64a7.8,7.8,0,0,0,8,0l112-64a8,8,0,1,0-8-13.8Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M8,104a7.8,7.8,0,0,1,4-6.9l112-64a7.7,7.7,0,0,1,8,0l112,64a8,8,0,0,1,0,13.8l-112,64a7.8,7.8,0,0,1-8,0l-112-64A7.8,7.8,0,0,1,8,104Zm228,33.1L128,198.8,20,137.1a8,8,0,0,0-8,13.8l112,64a7.8,7.8,0,0,0,8,0l112-64a8,8,0,1,0-8-13.8Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M13,109.2l112,64a6,6,0,0,0,6,0l112-64a6,6,0,0,0,0-10.4l-112-64a5.9,5.9,0,0,0-6,0L13,98.8a6,6,0,0,0,0,10.4ZM128,46.9,227.9,104,128,161.1,28.1,104ZM245.2,141a5.9,5.9,0,0,1-2.2,8.2l-112,64a6,6,0,0,1-6,0l-112-64a6,6,0,1,1,6-10.4l109,62.3,109-62.3A5.9,5.9,0,0,1,245.2,141Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M14,107.5l112,64a4.3,4.3,0,0,0,4,0l112-64a4.1,4.1,0,0,0,0-7l-112-64a4.1,4.1,0,0,0-4,0l-112,64a4.1,4.1,0,0,0,0,7ZM128,44.6,231.9,104,128,163.4,24.1,104ZM243.5,142a4.1,4.1,0,0,1-1.5,5.5l-112,64a4.3,4.3,0,0,1-4,0l-112-64a4,4,0,1,1,4-7l110,62.9,110-62.9A4.1,4.1,0,0,1,243.5,142Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M12,110.9l112,64a7.8,7.8,0,0,0,8,0l112-64a8,8,0,0,0,0-13.8l-112-64a7.7,7.7,0,0,0-8,0L12,97.1a8,8,0,0,0,0,13.8ZM128,49.2,223.9,104,128,158.8,32.1,104ZM246.9,140a7.9,7.9,0,0,1-2.9,10.9l-112,64a7.8,7.8,0,0,1-8,0l-112-64a8,8,0,0,1,8-13.8l108,61.7,108-61.7A7.9,7.9,0,0,1,246.9,140Z" />
        </>
      )}
    </svg>
  );
}

export function PhStack(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M234.4,170a12,12,0,0,1-4.4,16.4l-96,56a12,12,0,0,1-12,0l-96-56a12,12,0,1,1,12-20.8l90,52.5,90-52.5A12,12,0,0,1,234.4,170ZM218,117.6l-90,52.5L38,117.6a12,12,0,1,0-12,20.8l96,56a12.1,12.1,0,0,0,12,0l96-56a12,12,0,0,0-12-20.8ZM20,80a12.1,12.1,0,0,1,6-10.4l96-56a12.2,12.2,0,0,1,12,0l96,56a12,12,0,0,1,0,20.8l-96,56a12,12,0,0,1-12,0l-96-56A12.1,12.1,0,0,1,20,80Zm35.8,0L128,122.1,200.2,80,128,37.9Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <polygon points="32 80 128 136 224 80 128 24 32 80" />
          </g>
          <path d="M220,169.1l-92,53.6L36,169.1a8,8,0,0,0-8,13.8l96,56a7.8,7.8,0,0,0,8,0l96-56a8,8,0,1,0-8-13.8Z" />
          <path d="M220,121.1l-92,53.6L36,121.1a8,8,0,0,0-8,13.8l96,56a7.8,7.8,0,0,0,8,0l96-56a8,8,0,1,0-8-13.8Z" />
          <path d="M28,86.9l96,56a7.8,7.8,0,0,0,8,0l96-56a8,8,0,0,0,0-13.8l-96-56a7.7,7.7,0,0,0-8,0l-96,56a8,8,0,0,0,0,13.8ZM128,33.3,208.1,80,128,126.7,47.9,80Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M230.9,172a8,8,0,0,1-2.9,10.9l-96,56a7.8,7.8,0,0,1-8,0l-96-56a8,8,0,0,1,8-13.8l92,53.6,92-53.6A8,8,0,0,1,230.9,172ZM220,121.1l-92,53.6L36,121.1a8,8,0,0,0-8,13.8l96,56a7.8,7.8,0,0,0,8,0l96-56a8,8,0,1,0-8-13.8ZM28,86.9l96,56a7.8,7.8,0,0,0,8,0l96-56a8,8,0,0,0,0-13.8l-96-56a7.7,7.7,0,0,0-8,0l-96,56a8,8,0,0,0,0,13.8Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M229.2,173a6,6,0,0,1-2.2,8.2l-96,56a6,6,0,0,1-6,0l-96-56a6,6,0,1,1,6-10.4l93,54.3,93-54.3A6,6,0,0,1,229.2,173ZM221,122.8l-93,54.3L35,122.8a6,6,0,1,0-6,10.4l96,56a6,6,0,0,0,6,0l96-56a6,6,0,0,0-6-10.4ZM26,80a6,6,0,0,1,3-5.2l96-56a5.9,5.9,0,0,1,6,0l96,56a6,6,0,0,1,0,10.4l-96,56a6,6,0,0,1-6,0l-96-56A6,6,0,0,1,26,80Zm17.9,0L128,129.1,212.1,80,128,30.9Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M227.5,174a4.1,4.1,0,0,1-1.5,5.5l-96,56a4.3,4.3,0,0,1-4,0l-96-56a4,4,0,1,1,4-7l94,54.9,94-54.9A4.1,4.1,0,0,1,227.5,174ZM222,124.5l-94,54.9L34,124.5a4,4,0,1,0-4,7l96,56a4.3,4.3,0,0,0,4,0l96-56a4,4,0,1,0-4-7ZM28,80a4.3,4.3,0,0,1,2-3.5l96-56a4.1,4.1,0,0,1,4,0l96,56a4.1,4.1,0,0,1,0,7l-96,56a4.3,4.3,0,0,1-4,0l-96-56A4.3,4.3,0,0,1,28,80Zm11.9,0L128,131.4,216.1,80,128,28.6Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M230.9,172a8,8,0,0,1-2.9,10.9l-96,56a7.8,7.8,0,0,1-8,0l-96-56a8,8,0,0,1,8-13.8l92,53.6,92-53.6A8,8,0,0,1,230.9,172ZM220,121.1l-92,53.6L36,121.1a8,8,0,0,0-8,13.8l96,56a7.8,7.8,0,0,0,8,0l96-56a8,8,0,1,0-8-13.8ZM24,80a7.9,7.9,0,0,1,4-6.9l96-56a7.7,7.7,0,0,1,8,0l96,56a8,8,0,0,1,0,13.8l-96,56a7.8,7.8,0,0,1-8,0l-96-56A7.9,7.9,0,0,1,24,80Zm23.9,0L128,126.7,208.1,80,128,33.3Z" />
        </>
      )}
    </svg>
  );
}

export function PhStamp(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M228,224a12,12,0,0,1-12,12H40a12,12,0,0,1,0-24H216A12,12,0,0,1,228,224Zm0-80v32a20.1,20.1,0,0,1-20,20H48a20.1,20.1,0,0,1-20-20V144a20.1,20.1,0,0,1,20-20H97.2L82.5,55.5A36,36,0,0,1,117.7,12h20.6a36,36,0,0,1,35.2,43.5L158.8,124H208A20.1,20.1,0,0,1,228,144ZM121.7,124h12.6L150,50.5a11.7,11.7,0,0,0-2.4-10,11.5,11.5,0,0,0-9.3-4.5H117.7a11.5,11.5,0,0,0-9.3,4.5,11.7,11.7,0,0,0-2.4,10ZM204,148H52v24H204Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M161.8,53,144,136H112L94.2,53a24,24,0,0,1,23.5-29h20.6A24,24,0,0,1,161.8,53Z"
            opacity="0.2"
          />
          <path d="M224,224a8,8,0,0,1-8,8H40a8,8,0,0,1,0-16H216A8,8,0,0,1,224,224Zm0-80v40a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V144a16,16,0,0,1,16-16h54.1L86.4,54.7A32,32,0,0,1,117.7,16h20.6a32,32,0,0,1,31.3,38.7L153.9,128H208A16,16,0,0,1,224,144ZM118.5,128h19L154,51.4A16,16,0,0,0,138.3,32H117.7A16,16,0,0,0,102,51.4ZM208,184V144H48v40Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M224,224a8,8,0,0,1-8,8H40a8,8,0,0,1,0-16H216A8,8,0,0,1,224,224Zm0-80v40a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V144a16,16,0,0,1,16-16h54.1L86.4,54.7A32,32,0,0,1,117.7,16h20.6a32,32,0,0,1,31.3,38.7L153.9,128H208A16,16,0,0,1,224,144Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M222,224a6,6,0,0,1-6,6H40a6,6,0,0,1,0-12H216A6,6,0,0,1,222,224Zm0-80v40a14,14,0,0,1-14,14H48a14,14,0,0,1-14-14V144a14,14,0,0,1,14-14h56.6L88.4,54.3a29.4,29.4,0,0,1,6-25.2A30,30,0,0,1,117.7,18h20.6a30,30,0,0,1,23.3,11.1,29.4,29.4,0,0,1,6,25.2L151.4,130H208A14,14,0,0,1,222,144ZM116.9,130h22.2l16.8-78.2A18,18,0,0,0,138.3,30H117.7a18,18,0,0,0-17.6,21.8ZM210,144a2,2,0,0,0-2-2H48a2,2,0,0,0-2,2v40a2,2,0,0,0,2,2H208a2,2,0,0,0,2-2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M220,224a4,4,0,0,1-4,4H40a4,4,0,0,1,0-8H216A4,4,0,0,1,220,224Zm0-80v40a12,12,0,0,1-12,12H48a12,12,0,0,1-12-12V144a12,12,0,0,1,12-12h59.1L90.3,53.9A28,28,0,0,1,117.7,20h20.6a28,28,0,0,1,27.4,33.9L148.9,132H208A12,12,0,0,1,220,144ZM115.2,132h25.6l17.1-79.8a20.3,20.3,0,0,0-4-16.8A20,20,0,0,0,138.3,28H117.7a20,20,0,0,0-15.6,7.4,20.3,20.3,0,0,0-4,16.8ZM212,144a4,4,0,0,0-4-4H48a4,4,0,0,0-4,4v40a4,4,0,0,0,4,4H208a4,4,0,0,0,4-4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M224,224a8,8,0,0,1-8,8H40a8,8,0,0,1,0-16H216A8,8,0,0,1,224,224Zm0-80v40a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V144a16,16,0,0,1,16-16h54.1L86.4,54.7A32,32,0,0,1,117.7,16h20.6a32,32,0,0,1,31.3,38.7L153.9,128H208A16,16,0,0,1,224,144ZM118.5,128h19L154,51.4A16,16,0,0,0,138.3,32H117.7A16,16,0,0,0,102,51.4ZM208,184V144H48v40Z" />
        </>
      )}
    </svg>
  );
}

export function PhStarFour(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M231.4,109.2l-62-22.6-22.6-62a20,20,0,0,0-37.6,0l-22.6,62-62,22.6a20,20,0,0,0,0,37.6l62,22.6,22.6,62a20,20,0,0,0,37.6,0l22.6-62,62-22.6a20,20,0,0,0,0-37.6Zm-72,38.2a20.3,20.3,0,0,0-12,12h0L128,212.9l-19.4-53.5a20.3,20.3,0,0,0-12-12L43.1,128l53.5-19.4a20.3,20.3,0,0,0,12-12L128,43.1l19.4,53.5a20.3,20.3,0,0,0,12,12L212.9,128Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M227.3,135.5l-63.8,23.2a8.1,8.1,0,0,0-4.8,4.8l-23.2,63.8a8,8,0,0,1-15,0L97.3,163.5a8.1,8.1,0,0,0-4.8-4.8L28.7,135.5a8,8,0,0,1,0-15L92.5,97.3a8.1,8.1,0,0,0,4.8-4.8l23.2-63.8a8,8,0,0,1,15,0l23.2,63.8a8.1,8.1,0,0,0,4.8,4.8l63.8,23.2A8,8,0,0,1,227.3,135.5Z"
            opacity="0.2"
          />
          <path d="M230.1,113,166.2,89.8,143,25.9a16,16,0,0,0-30,0L89.8,89.8,25.9,113a16,16,0,0,0,0,30l63.9,23.2L113,230.1a16,16,0,0,0,30,0l23.2-63.9L230.1,143a16,16,0,0,0,0-30Zm-69.3,38.2a15.7,15.7,0,0,0-9.6,9.6h0L128,224.6l-23.2-63.8a15.7,15.7,0,0,0-9.6-9.6L31.4,128l63.8-23.2a15.7,15.7,0,0,0,9.6-9.6L128,31.4l23.2,63.8a15.7,15.7,0,0,0,9.6,9.6L224.6,128Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M240.6,128a15.8,15.8,0,0,1-10.5,15l-63.9,23.2L143,230.1a16,16,0,0,1-30,0L89.8,166.2,25.9,143a16,16,0,0,1,0-30L89.8,89.8,113,25.9a16,16,0,0,1,30,0l23.2,63.9L230.1,113A15.8,15.8,0,0,1,240.6,128Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M229.4,114.8,165.6,91.6a1.8,1.8,0,0,1-1.2-1.2L141.2,26.6a14.1,14.1,0,0,0-26.4,0L91.6,90.4a1.8,1.8,0,0,1-1.2,1.2L26.6,114.8a14.1,14.1,0,0,0,0,26.4l63.8,23.2a1.8,1.8,0,0,1,1.2,1.2l23.2,63.8a14.1,14.1,0,0,0,26.4,0l23.2-63.8h0a1.8,1.8,0,0,1,1.2-1.2l63.8-23.2a14.1,14.1,0,0,0,0-26.4Zm-4.1,15.1-63.8,23.2a14,14,0,0,0-8.4,8.4h0l-23.2,63.8a2,2,0,0,1-3.8,0l-23.2-63.8a14,14,0,0,0-8.4-8.4L30.7,129.9a2,2,0,0,1,0-3.8l63.8-23.2a14,14,0,0,0,8.4-8.4l23.2-63.8a2,2,0,0,1,3.8,0l23.2,63.8a14,14,0,0,0,8.4,8.4l63.8,23.2a2,2,0,0,1,0,3.8Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M228.7,116.7,164.9,93.5a4.1,4.1,0,0,1-2.4-2.4L139.3,27.3a12,12,0,0,0-22.6,0L93.5,91.1a4.1,4.1,0,0,1-2.4,2.4L27.3,116.7a12,12,0,0,0,0,22.6l63.8,23.2a4.1,4.1,0,0,1,2.4,2.4l23.2,63.8a12,12,0,0,0,22.6,0l23.2-63.8h0a4.1,4.1,0,0,1,2.4-2.4l63.8-23.2a12,12,0,0,0,0-22.6ZM226,131.8,162.1,155a11.9,11.9,0,0,0-7.1,7.1L131.8,226a4.1,4.1,0,0,1-7.6,0L101,162.1a11.9,11.9,0,0,0-7.1-7.1L30,131.8a4.1,4.1,0,0,1,0-7.6L93.9,101a11.9,11.9,0,0,0,7.1-7.1L124.2,30a4.1,4.1,0,0,1,7.6,0L155,93.9a11.9,11.9,0,0,0,7.1,7.1L226,124.2a4.1,4.1,0,0,1,0,7.6Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M230.1,113,166.2,89.8,143,25.9a16,16,0,0,0-30,0L89.8,89.8,25.9,113a16,16,0,0,0,0,30l63.9,23.2L113,230.1a16,16,0,0,0,30,0l23.2-63.9L230.1,143a16,16,0,0,0,0-30Zm-69.3,38.2a15.7,15.7,0,0,0-9.6,9.6h0L128,224.6l-23.2-63.8a15.7,15.7,0,0,0-9.6-9.6L31.4,128l63.8-23.2a15.7,15.7,0,0,0,9.6-9.6L128,31.4l23.2,63.8a15.7,15.7,0,0,0,9.6,9.6L224.6,128Z" />
        </>
      )}
    </svg>
  );
}

export function PhStarHalf(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M236.6,118.6l-8.8,7.2a11.5,11.5,0,0,1-7.6,2.8,12.2,12.2,0,0,1-9.3-4.3,12,12,0,0,1,1.6-16.9l2.3-2-3-.2a12,12,0,1,1,1.5-23.9l11.6.7A20.5,20.5,0,0,1,243,96.2,20.9,20.9,0,0,1,236.6,118.6Zm-32.4,80.7a12,12,0,1,0-23.2,5.9l.6,2.5-2.2-1.4a12,12,0,0,0-12.8,20.3l9.8,6.2a20.6,20.6,0,0,0,10.9,3.2,19.9,19.9,0,0,0,11.8-3.9,20.6,20.6,0,0,0,7.9-21.9ZM176,137.7a20.9,20.9,0,0,0-6.8,21.1l1.2,4.7a12,12,0,0,0,11.6,9,15.3,15.3,0,0,0,3-.3,12,12,0,0,0,8.6-14.6l-.7-2.7,1.8-1.5a12,12,0,1,0-15.3-18.5ZM140,72.5V181.3l3.1,2a12,12,0,0,1,3.7,16.5,12.1,12.1,0,0,1-16.6,3.8l-2.2-1.5L83.1,230.6a21.7,21.7,0,0,1-24.5-.8A22.4,22.4,0,0,1,50,206.1l13.1-51.2L19.4,118.6A20.9,20.9,0,0,1,13,96.2,20.5,20.5,0,0,1,31.1,82l56.8-3.6,21.2-53.5a20.3,20.3,0,0,1,37.8,0l21.2,53.5h2.3a12,12,0,1,1-1.6,24l-4.5-.3a20.5,20.5,0,0,1-17.6-12.9ZM116,181.3V72.5l-6.7,16.8a20.5,20.5,0,0,1-17.6,12.9l-50.5,3.2L80,137.7a20.9,20.9,0,0,1,6.8,21.1L74.4,207.7Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M128,24V189.4a8.7,8.7,0,0,0-4.4,1.3L76.7,220.4c-7.7,4.9-17.3-2.4-15-11.4l13.5-53.2a8.7,8.7,0,0,0-2.9-8.8L27.1,109.3c-5.9-4.9-2.9-14.8,4.8-15.3l59-3.8a8.3,8.3,0,0,0,7.3-5.4l22-55.4A8.3,8.3,0,0,1,128,24Zm0,0Z"
            opacity="0.2"
          />
          <path d="M239.2,97.4A16.4,16.4,0,0,0,224.6,86l-59-3.8a.5.5,0,0,1-.4-.3l-22-55.5a16.3,16.3,0,0,0-30.4,0l-22,55.5a.5.5,0,0,1-.4.3L31.4,86A16.4,16.4,0,0,0,16.8,97.4,16.8,16.8,0,0,0,22,115.5l45.2,37.6a.8.8,0,0,1,.2.8L53.9,207a18.6,18.6,0,0,0,7,19.6,18,18,0,0,0,20.1.6l46.9-29.7h.2l50.5,31.9a16.1,16.1,0,0,0,8.7,2.6,16.8,16.8,0,0,0,9.5-3.1,16.6,16.6,0,0,0,6.3-17.7l-14.5-57.3a.8.8,0,0,1,.2-.8L234,115.5A16.8,16.8,0,0,0,239.2,97.4ZM72.4,213.7a1.6,1.6,0,0,1-2.1-.1,2.5,2.5,0,0,1-.9-2.6l13.5-53.2a16.8,16.8,0,0,0-5.4-17L32.2,103.2c-.1-.1-.3-.3-.1-.8s.2-.4.3-.4l59.1-3.8a16.4,16.4,0,0,0,14.1-10.4L120,51.6v132l-.7.4ZM223.8,103.2l-45.3,37.6a16.8,16.8,0,0,0-5.4,17l14.5,57.3c.1.4.1.5,0,.6a.7.7,0,0,1-.3.3h-.2L136.7,184l-.7-.4V51.6l14.4,36.2a16.4,16.4,0,0,0,14.1,10.4l59.1,3.8c.1,0,.2,0,.3.4S223.9,103.1,223.8,103.2Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M239.2,97.4A16.4,16.4,0,0,0,224.6,86l-59-3.8a.5.5,0,0,1-.4-.3l-22-55.5a16.3,16.3,0,0,0-30.4,0l-22,55.5a.5.5,0,0,1-.4.3L31.4,86A16.4,16.4,0,0,0,16.8,97.4,16.8,16.8,0,0,0,22,115.5l45.2,37.6a.8.8,0,0,1,.2.8L53.9,207a18.6,18.6,0,0,0,7,19.6,18,18,0,0,0,20.1.6l46.9-29.7h.2l50.5,31.9a16.1,16.1,0,0,0,8.7,2.6,16.8,16.8,0,0,0,9.5-3.1,16.6,16.6,0,0,0,6.3-17.7l-14.5-57.3a.8.8,0,0,1,.2-.8L234,115.5A16.8,16.8,0,0,0,239.2,97.4Zm-15.4,5.8-45.3,37.6a16.8,16.8,0,0,0-5.4,17l14.5,57.3c.1.4.1.5,0,.6a.7.7,0,0,1-.3.3h-.2L136.7,184a16,16,0,0,0-8.7-2.5V32c.1,0,.2,0,.3.3l22.1,55.5a16.4,16.4,0,0,0,14.1,10.4l59.1,3.8c.1,0,.2,0,.3.4S223.9,103.1,223.8,103.2Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M232.7,113.9l-8.7,7.3a6,6,0,0,1-8.5-.8,6,6,0,0,1,.8-8.4l8.7-7.3a2.5,2.5,0,0,0,.8-2.9,2.3,2.3,0,0,0-2.1-1.8l-11.5-.7a6.1,6.1,0,0,1-5.6-6.4,6,6,0,0,1,6.4-5.6l11.5.7a14.5,14.5,0,0,1,12.8,10A14.7,14.7,0,0,1,232.7,113.9ZM175,157.3l3.3,13.1a6,6,0,0,0,5.8,4.5l1.5-.2a5.9,5.9,0,0,0,4.3-7.3l-3.3-13a2.8,2.8,0,0,1,.9-2.8l10-8.4a5.9,5.9,0,0,0,.8-8.4,6,6,0,0,0-8.5-.8l-10,8.4A14.7,14.7,0,0,0,175,157.3ZM177.8,97l-13.1-.8a14.8,14.8,0,0,1-12.5-9.1L134,41.1V184.8a5.1,5.1,0,0,1,1.6.9l11.5,7.3a5.9,5.9,0,0,1,1.9,8.2,6,6,0,0,1-8.3,1.9l-11.5-7.3a2.1,2.1,0,0,0-2.4,0L79.9,225.5a15.6,15.6,0,0,1-17.8-.6,16.2,16.2,0,0,1-6.2-17.4l13.5-53.1a2.8,2.8,0,0,0-.9-2.8L23.3,113.9A14.7,14.7,0,0,1,18.7,98,14.5,14.5,0,0,1,31.5,88l59.1-3.8a2.4,2.4,0,0,0,2-1.6l22-55.4a14.4,14.4,0,0,1,26.8,0l22,55.4a2.4,2.4,0,0,0,2,1.6l13.2.9a5.9,5.9,0,0,1,5.6,6.3A6,6,0,0,1,177.8,97Zm-57.4,88.7a5.1,5.1,0,0,1,1.6-.9V41.1l-18.2,46a14.8,14.8,0,0,1-12.5,9.1l-59,3.8a2.3,2.3,0,0,0-2.1,1.8,2.5,2.5,0,0,0,.8,2.9l45.2,37.7A14.7,14.7,0,0,1,81,157.3L67.5,210.5a4.4,4.4,0,0,0,1.6,4.7,3.9,3.9,0,0,0,4.4.2Zm78,15.1a6,6,0,1,0-11.6,2.9l2.8,10.9a2.7,2.7,0,0,1-1,2.9,2.1,2.1,0,0,1-2.5.1l-9.9-6.2a6,6,0,0,0-6.4,10.1l9.8,6.3a15.2,15.2,0,0,0,7.7,2.2,14.2,14.2,0,0,0,8.3-2.7,14.7,14.7,0,0,0,5.6-15.6Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M231.4,112.4l-8.7,7.3a3.8,3.8,0,0,1-2.5.9,3.9,3.9,0,0,1-3.1-1.5,4,4,0,0,1,.5-5.6l8.7-7.2a5,5,0,0,0,1.5-5.2,4.5,4.5,0,0,0-3.9-3.1l-11.6-.7a4,4,0,0,1-3.7-4.3,3.9,3.9,0,0,1,4.2-3.7l11.6.7a12.5,12.5,0,0,1,11,8.7A12.7,12.7,0,0,1,231.4,112.4Zm-54.5,44.4,3.3,13.1a4.1,4.1,0,0,0,3.9,3h1a4.1,4.1,0,0,0,2.9-4.9l-3.3-13a4.8,4.8,0,0,1,1.5-4.9l10-8.3a4,4,0,0,0,.6-5.6,4.1,4.1,0,0,0-5.7-.6l-10,8.4A12.9,12.9,0,0,0,176.9,156.8Zm1-61.8-13.1-.8a12.5,12.5,0,0,1-10.7-7.9l-22-55.5c-.1,0-.1,0-.1-.1V186.1a10.5,10.5,0,0,1,2.6,1.3l11.5,7.3a4,4,0,1,1-4.3,6.7l-11.5-7.3a4.8,4.8,0,0,0-2.3-.7h0a4.8,4.8,0,0,0-2.3.7L78.9,223.8a13.9,13.9,0,0,1-7.5,2.2,13.6,13.6,0,0,1-8.1-2.7A14.4,14.4,0,0,1,57.8,208l13.5-53.1a4.8,4.8,0,0,0-1.5-4.9L24.6,112.4a12.7,12.7,0,0,1-4-13.7,12.5,12.5,0,0,1,11-8.7l59.1-3.8a4.6,4.6,0,0,0,3.8-2.8l22-55.5a12.3,12.3,0,0,1,23,0l22,55.5a4.6,4.6,0,0,0,3.8,2.8l13.1.9a4,4,0,0,1,3.8,4.2A4,4,0,0,1,177.9,95Zm-56.5,92.4a10.5,10.5,0,0,1,2.6-1.3V30.7c0,.1,0,.1-.1.1l-22,55.5a12.5,12.5,0,0,1-10.7,7.9L32.1,98a4.5,4.5,0,0,0-3.9,3.1,5,5,0,0,0,1.5,5.2l45.2,37.6a12.9,12.9,0,0,1,4.2,12.9L65.6,210a6.1,6.1,0,0,0,2.4,6.8,5.6,5.6,0,0,0,6.6.2Zm75.1,13.9a4,4,0,0,0-4.9-2.9,4,4,0,0,0-2.9,4.8l2.8,11a4.7,4.7,0,0,1-1.7,5,4.2,4.2,0,0,1-4.8.1l-9.8-6.2a4.1,4.1,0,0,0-5.6,1.2,4,4,0,0,0,1.3,5.5l9.8,6.3a12.3,12.3,0,0,0,6.6,1.9,11.7,11.7,0,0,0,7.1-2.4,12.5,12.5,0,0,0,4.9-13.4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M234,115.5l-8.7,7.2a7.7,7.7,0,0,1-5.1,1.9,8,8,0,0,1-5.2-14.2l8.8-7.2c.1-.1.3-.3.1-.8s-.2-.4-.3-.4l-11.5-.7a8,8,0,0,1,1-16l11.5.7a16.4,16.4,0,0,1,14.6,11.4A16.8,16.8,0,0,1,234,115.5Zm-60.9,42.3,3.3,13.1a7.9,7.9,0,0,0,7.7,6,6.9,6.9,0,0,0,2-.3,8,8,0,0,0,5.8-9.7l-3.3-13a.8.8,0,0,1,.2-.8l10-8.3a8,8,0,0,0,1-11.3,7.8,7.8,0,0,0-11.2-1l-10.1,8.3A16.8,16.8,0,0,0,173.1,157.8ZM177.7,99l-13.2-.8a16.4,16.4,0,0,1-14.1-10.4L136,51.6v132l.7.4,11.5,7.3a8,8,0,1,1-8.6,13.5l-11.5-7.3h-.2L81,227.2a17.6,17.6,0,0,1-9.6,2.8,18.2,18.2,0,0,1-17.5-23l13.5-53.1a.8.8,0,0,0-.2-.8L22,115.5a16.8,16.8,0,0,1-5.2-18.1A16.4,16.4,0,0,1,31.4,86l59-3.8a.5.5,0,0,0,.4-.3l22-55.5a16.3,16.3,0,0,1,30.4,0l22,55.5a.5.5,0,0,0,.4.3l13.1.9a8,8,0,1,1-1,15.9Zm-58.4,85,.7-.4V51.6L105.6,87.8A16.4,16.4,0,0,1,91.5,98.2L32.4,102c-.1,0-.2,0-.3.4s0,.7.1.8l45.3,37.6a16.8,16.8,0,0,1,5.4,17L69.4,211a2.5,2.5,0,0,0,.9,2.6,1.6,1.6,0,0,0,2.1.1Zm81.1,16.3a8.1,8.1,0,0,0-9.8-5.8,8,8,0,0,0-5.7,9.7l2.7,10.9c.1.4.1.5,0,.6a.7.7,0,0,1-.3.3h-.2l-9.8-6.2a8,8,0,0,0-8.6,13.5l9.9,6.2a16.1,16.1,0,0,0,8.7,2.6,16.8,16.8,0,0,0,9.5-3.1,16.6,16.6,0,0,0,6.3-17.7Z" />
        </>
      )}
    </svg>
  );
}

export function PhStar(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M187.3,236a20.6,20.6,0,0,1-10.9-3.2L128,202.1,83.1,230.6a21.7,21.7,0,0,1-24.5-.8A22.4,22.4,0,0,1,50,206.1l13.1-51.2L19.4,118.6A20.9,20.9,0,0,1,13,96.2,20.5,20.5,0,0,1,31.1,82l56.8-3.6,21.2-53.5a20.3,20.3,0,0,1,37.8,0l21.2,53.5L224.9,82A20.5,20.5,0,0,1,243,96.2a20.9,20.9,0,0,1-6.4,22.4l-43.7,36.3L207,210.2a20.6,20.6,0,0,1-7.9,21.9A19.9,19.9,0,0,1,187.3,236Zm2-23.5Zm-61.3-35a19.9,19.9,0,0,1,10.8,3.1h0l42.8,27.1-12.4-48.9a20.9,20.9,0,0,1,6.8-21.1l38.8-32.3-50.5-3.2a20.5,20.5,0,0,1-17.6-12.9L128,42.3l-18.7,47a20.5,20.5,0,0,1-17.6,12.9l-50.5,3.2L80,137.7a20.9,20.9,0,0,1,6.8,21.1L74.4,207.7l42.8-27.1A19.9,19.9,0,0,1,128,177.5Zm-2,23.4Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M132.4,190.7l50.4,32c6.5,4.1,14.5-2,12.6-9.5l-14.6-57.4a8.7,8.7,0,0,1,2.9-8.8l45.2-37.7c5.9-4.9,2.9-14.8-4.8-15.3l-59-3.8a8.3,8.3,0,0,1-7.3-5.4l-22-55.4a8.3,8.3,0,0,0-15.6,0l-22,55.4a8.3,8.3,0,0,1-7.3,5.4L31.9,94c-7.7.5-10.7,10.4-4.8,15.3L72.3,147a8.7,8.7,0,0,1,2.9,8.8L61.7,209c-2.3,9,7.3,16.3,15,11.4l46.9-29.7A8.2,8.2,0,0,1,132.4,190.7Z" />
          </g>
          <path d="M187.3,232a16.1,16.1,0,0,1-8.7-2.6l-50.5-31.9h-.2L81,227.2a18,18,0,0,1-20.1-.6,18.6,18.6,0,0,1-7-19.6l13.5-53.1a.8.8,0,0,0-.2-.8L22,115.5a16.8,16.8,0,0,1-5.2-18.1A16.4,16.4,0,0,1,31.4,86l59-3.8a.5.5,0,0,0,.4-.3l22-55.5a16.3,16.3,0,0,1,30.4,0l22,55.5a.5.5,0,0,0,.4.3l59,3.8a16.4,16.4,0,0,1,14.6,11.4,16.8,16.8,0,0,1-5.2,18.1l-45.2,37.6a.8.8,0,0,0-.2.8l14.5,57.3a16.6,16.6,0,0,1-6.3,17.7A16.8,16.8,0,0,1,187.3,232ZM128,181.5a16,16,0,0,1,8.7,2.5h0l50.4,31.9h.2a.7.7,0,0,0,.3-.3c.1-.1.1-.2,0-.6l-14.5-57.3a16.8,16.8,0,0,1,5.4-17l45.3-37.6c.1-.1.3-.3.1-.8s-.2-.4-.3-.4l-59.1-3.8a16.4,16.4,0,0,1-14.1-10.4L128.3,32.3c-.1-.3-.2-.3-.3-.3s-.2,0-.3.3L105.6,87.8A16.4,16.4,0,0,1,91.5,98.2L32.4,102c-.1,0-.2,0-.3.4s0,.7.1.8l45.3,37.6a16.8,16.8,0,0,1,5.4,17L69.4,211a2.5,2.5,0,0,0,.9,2.6,1.6,1.6,0,0,0,2.1.1L119.3,184A16,16,0,0,1,128,181.5Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M234,115.5l-45.2,37.6,14.3,58.1A16.5,16.5,0,0,1,187.3,232a16.1,16.1,0,0,1-8.7-2.6l-50.5-31.9h-.2L81,227.2a18,18,0,0,1-20.1-.6,18.5,18.5,0,0,1-7-19.6l13.5-53.1L22,115.5a16.8,16.8,0,0,1-5.2-18.1A16.5,16.5,0,0,1,31.4,86l59-3.8,22.4-55.8A16.4,16.4,0,0,1,128,16h0a16.4,16.4,0,0,1,15.2,10.4l22,55.5L224.6,86a16.4,16.4,0,0,1,14.6,11.4A16.8,16.8,0,0,1,234,115.5Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M187.3,230a15.2,15.2,0,0,1-7.7-2.2l-50.4-32a2.1,2.1,0,0,0-2.4,0L79.9,225.5a15.5,15.5,0,0,1-17.8-.6,16.2,16.2,0,0,1-6.2-17.4l13.5-53.1a2.8,2.8,0,0,0-.9-2.8L23.3,113.9A14.7,14.7,0,0,1,18.7,98,14.5,14.5,0,0,1,31.5,88l59.1-3.8a2.4,2.4,0,0,0,2-1.6l22-55.4a14.4,14.4,0,0,1,26.8,0l22,55.4a2.4,2.4,0,0,0,2,1.6L224.5,88a14.5,14.5,0,0,1,12.8,10,14.7,14.7,0,0,1-4.6,15.9l-45.2,37.7a2.8,2.8,0,0,0-.9,2.8l14.6,57.3a14.7,14.7,0,0,1-5.6,15.6A14.2,14.2,0,0,1,187.3,230ZM128,183.5a13.7,13.7,0,0,1,7.6,2.2h0l50.5,31.9a2.1,2.1,0,0,0,2.5-.1,2.7,2.7,0,0,0,1-2.9L175,157.3a14.7,14.7,0,0,1,4.8-14.9L225,104.7a2.5,2.5,0,0,0,.8-2.9,2.3,2.3,0,0,0-2.1-1.8l-59-3.8a14.8,14.8,0,0,1-12.5-9.1l-22-55.5a2.3,2.3,0,0,0-4.4,0l-22,55.5a14.8,14.8,0,0,1-12.5,9.1l-59,3.8a2.3,2.3,0,0,0-2.1,1.8,2.5,2.5,0,0,0,.8,2.9l45.2,37.7A14.7,14.7,0,0,1,81,157.3L67.5,210.5a4.4,4.4,0,0,0,1.6,4.7,3.9,3.9,0,0,0,4.4.2l46.9-29.7A13.7,13.7,0,0,1,128,183.5Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M187.3,228a12.3,12.3,0,0,1-6.6-1.9l-50.4-32a4.3,4.3,0,0,0-4.6,0L78.9,223.8a13.7,13.7,0,0,1-15.6-.5A14.4,14.4,0,0,1,57.8,208l13.5-53.1a4.8,4.8,0,0,0-1.5-4.9L24.6,112.4a12.7,12.7,0,0,1-4-13.7,12.5,12.5,0,0,1,11-8.7l59.1-3.8a4.6,4.6,0,0,0,3.8-2.8l22-55.5a12.3,12.3,0,0,1,23,0l22,55.5a4.6,4.6,0,0,0,3.8,2.8L224.4,90a12.5,12.5,0,0,1,11,8.7,12.7,12.7,0,0,1-4,13.7L186.2,150a4.8,4.8,0,0,0-1.5,4.9l14.6,57.3a12.5,12.5,0,0,1-4.9,13.4A11.7,11.7,0,0,1,187.3,228ZM128,185.4a12.4,12.4,0,0,1,6.6,2h0L185,219.3a4,4,0,0,0,4.7-.1,4.6,4.6,0,0,0,1.8-5l-14.6-57.4a12.9,12.9,0,0,1,4.2-12.9l45.2-37.6a4.7,4.7,0,0,0,1.4-5.2,4.2,4.2,0,0,0-3.8-3.1l-59.1-3.8a12.5,12.5,0,0,1-10.7-7.9l-22-55.5a4.4,4.4,0,0,0-8.2,0l-22,55.5a12.5,12.5,0,0,1-10.7,7.9L32.1,98a4.5,4.5,0,0,0-3.9,3.1,5,5,0,0,0,1.5,5.2l45.2,37.6a12.9,12.9,0,0,1,4.2,12.9L65.6,210a6.1,6.1,0,0,0,2.4,6.8,5.6,5.6,0,0,0,6.6.2l46.8-29.6A12.4,12.4,0,0,1,128,185.4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M187.3,232a16.1,16.1,0,0,1-8.7-2.6l-50.5-31.9h-.2L81,227.2a18,18,0,0,1-20.1-.6,18.6,18.6,0,0,1-7-19.6l13.5-53.1a.8.8,0,0,0-.2-.8L22,115.5a16.8,16.8,0,0,1-5.2-18.1A16.4,16.4,0,0,1,31.4,86l59-3.8a.5.5,0,0,0,.4-.3l22-55.5a16.3,16.3,0,0,1,30.4,0l22,55.5a.5.5,0,0,0,.4.3l59,3.8a16.4,16.4,0,0,1,14.6,11.4,16.8,16.8,0,0,1-5.2,18.1l-45.2,37.6a.8.8,0,0,0-.2.8l14.5,57.3a16.6,16.6,0,0,1-6.3,17.7A16.8,16.8,0,0,1,187.3,232ZM128,181.5a16,16,0,0,1,8.7,2.5h0l50.4,31.9h.2a.7.7,0,0,0,.3-.3c.1-.1.1-.2,0-.6l-14.5-57.3a16.8,16.8,0,0,1,5.4-17l45.3-37.6c.1-.1.3-.3.1-.8s-.2-.4-.3-.4l-59.1-3.8a16.4,16.4,0,0,1-14.1-10.4L128.3,32.3c-.1-.3-.2-.3-.3-.3s-.2,0-.3.3L105.6,87.8A16.4,16.4,0,0,1,91.5,98.2L32.4,102c-.1,0-.2,0-.3.4s0,.7.1.8l45.3,37.6a16.8,16.8,0,0,1,5.4,17L69.4,211a2.5,2.5,0,0,0,.9,2.6,1.6,1.6,0,0,0,2.1.1L119.3,184A16,16,0,0,1,128,181.5Z" />
        </>
      )}
    </svg>
  );
}

export function PhSticker(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M227.7,138.7a4.3,4.3,0,0,1,.1-.5c0-.2.1-.5.1-.7v-.4a4.1,4.1,0,0,0,.1-1.1V88a60,60,0,0,0-60-60H88A60,60,0,0,0,28,88v80a60,60,0,0,0,60,60h50.6l.6-.2h.6c27.6-9.2,78.4-60,87.6-87.6h0a.7.7,0,0,0,.1-.5A1.7,1.7,0,0,0,227.7,138.7ZM52,168V88A36,36,0,0,1,88,52h80a36,36,0,0,1,36,36v36H184a60,60,0,0,0-60,60v20H88A36,36,0,0,1,52,168Zm96,27.6V184a36,36,0,0,1,36-36h11.6C184,164.1,164.1,184,148,195.6Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M136,216V184a48,48,0,0,1,48-48h32C208,160,160,208,136,216Z" />
          </g>
          <path d="M223.9,137.2a4.9,4.9,0,0,0,.1-1.2V88a56,56,0,0,0-56-56H88A56,56,0,0,0,32,88v80a56,56,0,0,0,56,56h49.4l1.1-.3h0c26.3-8.8,76.3-58.8,85.1-85.1h0l.3-1.1ZM48,168V88A40,40,0,0,1,88,48h80a40,40,0,0,1,40,40v40H184a56,56,0,0,0-56,56v24H88A40,40,0,0,1,48,168Zm96,35.1V184a40,40,0,0,1,40-40h19.1C191,163.5,163.5,191,144,203.1Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M168,32H88A56,56,0,0,0,32,88v80a56,56,0,0,0,56,56h48a6.9,6.9,0,0,0,2.5-.4c26.3-8.8,76.3-58.8,85.1-85.1a6.9,6.9,0,0,0,.4-2.5V88A56,56,0,0,0,168,32ZM136,207.4V176a40,40,0,0,1,40-40h31.4C198.2,157.6,157.6,198.2,136,207.4Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M221.9,136.9a2.8,2.8,0,0,0,.1-.9V88a54,54,0,0,0-54-54H88A54,54,0,0,0,34,88v80a54,54,0,0,0,54,54h49l.9-.2h0c25.8-8.6,75.2-58,83.8-83.8h0a2.9,2.9,0,0,0,.2-.9ZM46,168V88A42,42,0,0,1,88,46h80a42,42,0,0,1,42,42v42H184a54,54,0,0,0-54,54v26H88A42,42,0,0,1,46,168Zm96,38.7V184a42,42,0,0,1,42-42h22.7C194.8,163.1,163.1,194.8,142,206.7Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M220,136V88a52,52,0,0,0-52-52H88A52,52,0,0,0,36,88v80a52,52,0,0,0,52,52h49.3c25.4-8.5,74-57.1,82.5-82.5a.4.4,0,0,1,.1-.3v-.3A1.7,1.7,0,0,0,220,136ZM44,168V88A44,44,0,0,1,88,44h80a44,44,0,0,1,44,44v44H184a52,52,0,0,0-52,52v28H88A44,44,0,0,1,44,168Zm96,42V184a44,44,0,0,1,44-44h26C198.9,162.2,162.2,198.9,140,210Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M223.9,137.2a4.9,4.9,0,0,0,.1-1.2V88a56,56,0,0,0-56-56H88A56,56,0,0,0,32,88v80a56,56,0,0,0,56,56h49.4l1.1-.3h0c26.3-8.8,76.3-58.8,85.1-85.1h0l.3-1.1ZM48,168V88A40,40,0,0,1,88,48h80a40,40,0,0,1,40,40v40H184a56,56,0,0,0-56,56v24H88A40,40,0,0,1,48,168Zm96,35.1V184a40,40,0,0,1,40-40h19.1C191,163.5,163.5,191,144,203.1Z" />
        </>
      )}
    </svg>
  );
}

export function PhStopCircle(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,20A108,108,0,1,0,236,128,108.1,108.1,0,0,0,128,20Zm0,192a84,84,0,1,1,84-84A84.1,84.1,0,0,1,128,212Zm32-104v40a12,12,0,0,1-12,12H108a12,12,0,0,1-12-12V108a12,12,0,0,1,12-12h40A12,12,0,0,1,160,108Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M128,32a96,96,0,1,0,96,96A96,96,0,0,0,128,32Zm24,120H104V104h48Z" />
          </g>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Z" />
          <path d="M152,96H104a8,8,0,0,0-8,8v48a8,8,0,0,0,8,8h48a8,8,0,0,0,8-8V104A8,8,0,0,0,152,96Zm-8,48H112V112h32Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm32,128a8,8,0,0,1-8,8H104a8,8,0,0,1-8-8V104a8,8,0,0,1,8-8h48a8,8,0,0,1,8,8Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,26A102,102,0,1,0,230,128,102.2,102.2,0,0,0,128,26Zm0,192a90,90,0,1,1,90-90A90.1,90.1,0,0,1,128,218ZM152,98H104a6,6,0,0,0-6,6v48a6,6,0,0,0,6,6h48a6,6,0,0,0,6-6V104A6,6,0,0,0,152,98Zm-6,48H110V110h36Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,28A100,100,0,1,0,228,128,100.2,100.2,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm24-120H104a4,4,0,0,0-4,4v48a4,4,0,0,0,4,4h48a4,4,0,0,0,4-4V104A4,4,0,0,0,152,100Zm-4,48H108V108h40Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216ZM152,96H104a8,8,0,0,0-8,8v48a8,8,0,0,0,8,8h48a8,8,0,0,0,8-8V104A8,8,0,0,0,152,96Zm-8,48H112V112h32Z" />
        </>
      )}
    </svg>
  );
}

export function PhStop(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M197.1,216H58.9A18.9,18.9,0,0,1,40,197.1V58.9A18.9,18.9,0,0,1,58.9,40H197.1A18.9,18.9,0,0,1,216,58.9V197.1A18.9,18.9,0,0,1,197.1,216ZM64,192H192V64H64Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect x="52" y="52" width="152" height="152" rx="6.9" />
          </g>
          <path d="M197.1,212H58.9A14.9,14.9,0,0,1,44,197.1V58.9A14.9,14.9,0,0,1,58.9,44H197.1A14.9,14.9,0,0,1,212,58.9V197.1A14.9,14.9,0,0,1,197.1,212ZM60,196H196V60H60Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M212,58.9V197.1A14.9,14.9,0,0,1,197.1,212H58.9A14.9,14.9,0,0,1,44,197.1V58.9A14.9,14.9,0,0,1,58.9,44H197.1A14.9,14.9,0,0,1,212,58.9Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M197.1,210H58.9A12.9,12.9,0,0,1,46,197.1V58.9A12.9,12.9,0,0,1,58.9,46H197.1A12.9,12.9,0,0,1,210,58.9V197.1A12.9,12.9,0,0,1,197.1,210ZM58.9,58a.9.9,0,0,0-.9.9V197.1a.9.9,0,0,0,.9.9H197.1a.9.9,0,0,0,.9-.9V58.9a.9.9,0,0,0-.9-.9Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M197.1,208H58.9A10.9,10.9,0,0,1,48,197.1V58.9A10.9,10.9,0,0,1,58.9,48H197.1A10.9,10.9,0,0,1,208,58.9V197.1A10.9,10.9,0,0,1,197.1,208ZM58.9,56A2.9,2.9,0,0,0,56,58.9V197.1a2.9,2.9,0,0,0,2.9,2.9H197.1a2.9,2.9,0,0,0,2.9-2.9V58.9a2.9,2.9,0,0,0-2.9-2.9Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M197.1,212H58.9A14.9,14.9,0,0,1,44,197.1V58.9A14.9,14.9,0,0,1,58.9,44H197.1A14.9,14.9,0,0,1,212,58.9V197.1A14.9,14.9,0,0,1,197.1,212ZM60,196H196V60H60Z" />
        </>
      )}
    </svg>
  );
}

export function PhStorefront(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M236,95.7v-.5a2.8,2.8,0,0,1-.1-.9v-.4c-.1-.3-.1-.7-.2-1a.3.3,0,0,0-.1-.2v-.3h0L221.2,42.5A20.1,20.1,0,0,0,202,28H54A20.1,20.1,0,0,0,34.8,42.5L20.5,92.4h0v.3a.3.3,0,0,0-.1.2c-.1.3-.1.7-.2,1v.4a2.8,2.8,0,0,1-.1.9V112a43.9,43.9,0,0,0,16,33.9V208a20.1,20.1,0,0,0,20,20H200a20.1,20.1,0,0,0,20-20V145.9A43.9,43.9,0,0,0,236,112V95.7ZM212,112a20,20,0,0,1-9,16.7,11.8,11.8,0,0,0-1.9,1.1A19.7,19.7,0,0,1,192,132a20.1,20.1,0,0,1-20-20v-4h40ZM54.9,129.8a11.8,11.8,0,0,0-1.9-1.1A20,20,0,0,1,44,112v-4H84v4a20,20,0,0,1-29.1,17.8ZM108,108h40v4a20,20,0,0,1-40,0ZM57.1,52H198.9l9.2,32H47.9ZM196,204H60V155.8l4,.2a43.8,43.8,0,0,0,32-13.8,44,44,0,0,0,64,0A43.8,43.8,0,0,0,192,156l4-.2Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M96,96v16a32,32,0,0,1-64,0V96" />
          </g>
          <g opacity="0.2">
            <path d="M224,96v16a32,32,0,0,1-64,0V96" />
          </g>
          <path d="M232,95.8v-.4a4.3,4.3,0,0,1-.1-.5v-.3c-.1-.2-.1-.4-.2-.6v-.2L217.4,43.6A16.2,16.2,0,0,0,202,32H54A16.2,16.2,0,0,0,38.6,43.6L24.3,93.8V94c-.1.2-.1.4-.2.6v.3a4.3,4.3,0,0,1-.1.5V112a40,40,0,0,0,16,32v64a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V144a40,40,0,0,0,16-32V95.8ZM54,48H202l11.4,40H42.6Zm50,56h48v8a24,24,0,0,1-48,0Zm-16,0v8a24,24,0,0,1-35.1,21.3,8.7,8.7,0,0,0-1.8-1.1A23.9,23.9,0,0,1,40,112v-8ZM200,208H56V151.2a42.6,42.6,0,0,0,8,.8,40,40,0,0,0,32-16,40,40,0,0,0,64,0,40,40,0,0,0,32,16,42.6,42.6,0,0,0,8-.8Zm4.9-75.8a8.7,8.7,0,0,0-1.8,1.1A24,24,0,0,1,168,112v-8h48v8A23.9,23.9,0,0,1,204.9,132.2Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M232,95.8v-.4a4.3,4.3,0,0,1-.1-.5v-.3c-.1-.2-.1-.4-.2-.6v-.2L217.4,43.6A16.2,16.2,0,0,0,202,32H54A16.2,16.2,0,0,0,38.6,43.6L24.3,93.8V94c-.1.2-.1.4-.2.6v.3a4.3,4.3,0,0,1-.1.5V112a40,40,0,0,0,16,32v64a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V144a40,40,0,0,0,16-32V95.8ZM40,104H88v8a24,24,0,0,1-35.1,21.3,8.7,8.7,0,0,0-1.8-1.1A23.9,23.9,0,0,1,40,112Zm112,8a24,24,0,0,1-48,0v-8h48Zm52.9,20.2a8.7,8.7,0,0,0-1.8,1.1A24,24,0,0,1,168,112v-8h48v8A23.9,23.9,0,0,1,204.9,132.2Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M230,95.8v-.2c0-.2,0-.3-.1-.4v-.3c0-.1-.1-.3-.1-.4h0L215.4,44.2A14,14,0,0,0,202,34H54A14,14,0,0,0,40.6,44.2L26.2,94.4h0c0,.1-.1.3-.1.4v.3c-.1.1-.1.2-.1.4V112a37.9,37.9,0,0,0,16,31v65a14,14,0,0,0,14,14H200a14,14,0,0,0,14-14V143a37.9,37.9,0,0,0,16-31V95.8ZM52.1,47.5A2,2,0,0,1,54,46H202a2,2,0,0,1,1.9,1.5L216,90H40ZM102,102h52v10a26,26,0,0,1-52,0Zm-64,0H90v10a26.1,26.1,0,0,1-26,26,26.5,26.5,0,0,1-12.2-3,5.4,5.4,0,0,0-1.6-1A26,26,0,0,1,38,112ZM202,208a2,2,0,0,1-2,2H56a2,2,0,0,1-2-2V148.7A40.9,40.9,0,0,0,64,150a38.1,38.1,0,0,0,32-17.5,38,38,0,0,0,64,0A38.1,38.1,0,0,0,192,150a40.9,40.9,0,0,0,10-1.3Zm3.8-74a5.4,5.4,0,0,0-1.6,1,26.5,26.5,0,0,1-12.2,3,26.1,26.1,0,0,1-26-26V102h52v10A26,26,0,0,1,205.8,134Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M228,95.9v-.3a1.3,1.3,0,0,1-.1-.6h-.1L213.5,44.7A12.1,12.1,0,0,0,202,36H54a12.1,12.1,0,0,0-11.5,8.7L28.2,94.9h-.1a1.3,1.3,0,0,1-.1.6v.3h0v16a35.9,35.9,0,0,0,16,29.9V208a12,12,0,0,0,12,12H200a12,12,0,0,0,12-12V141.9A35.9,35.9,0,0,0,228,112V96ZM50.2,46.9A4.1,4.1,0,0,1,54,44H202a4.1,4.1,0,0,1,3.8,2.9L218.7,92H37.3ZM100,100h56v12a28,28,0,0,1-56,0ZM36,112V100H92v12a28.1,28.1,0,0,1-28,28,27.8,27.8,0,0,1-13.4-3.4,5.7,5.7,0,0,0-1.3-.8A27.8,27.8,0,0,1,36,112Zm168,96a4,4,0,0,1-4,4H56a4,4,0,0,1-4-4V145.9A34.4,34.4,0,0,0,64,148a36,36,0,0,0,32-19.5,36,36,0,0,0,64,0A36,36,0,0,0,192,148a34.4,34.4,0,0,0,12-2.1Zm2.7-72.2a5.7,5.7,0,0,0-1.3.8A27.8,27.8,0,0,1,192,140a28.1,28.1,0,0,1-28-28V100h56v12A27.8,27.8,0,0,1,206.7,135.8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M232,95.8v-.4a4.3,4.3,0,0,1-.1-.5v-.3c-.1-.2-.1-.4-.2-.6v-.2L217.4,43.6A16.2,16.2,0,0,0,202,32H54A16.2,16.2,0,0,0,38.6,43.6L24.3,93.8V94c-.1.2-.1.4-.2.6v.3a4.3,4.3,0,0,1-.1.5V112a40,40,0,0,0,16,32v64a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V144a40,40,0,0,0,16-32V95.8ZM54,48H202l11.4,40H42.6Zm50,56h48v8a24,24,0,0,1-48,0Zm-16,0v8a24,24,0,0,1-35.1,21.3,8.7,8.7,0,0,0-1.8-1.1A23.9,23.9,0,0,1,40,112v-8ZM200,208H56V151.2a42.6,42.6,0,0,0,8,.8,40,40,0,0,0,32-16,40,40,0,0,0,64,0,40,40,0,0,0,32,16,42.6,42.6,0,0,0,8-.8Zm4.9-75.8a8.7,8.7,0,0,0-1.8,1.1A24,24,0,0,1,168,112v-8h48v8A23.9,23.9,0,0,1,204.9,132.2Z" />
        </>
      )}
    </svg>
  );
}

export function PhStrategy(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M72,144a40,40,0,1,0,40,40A40,40,0,0,0,72,144Zm0,56a16,16,0,1,1,16-16A16,16,0,0,1,72,200ZM40,128a12.2,12.2,0,0,1-8.5-3.5,12,12,0,0,1,0-17L43,96,31.5,84.5a12,12,0,0,1,17-17L60,79,71.5,67.5a12,12,0,0,1,17,17L77,96l11.5,11.5a12,12,0,0,1,0,17,12.1,12.1,0,0,1-17,0L60,113,48.5,124.5A12.2,12.2,0,0,1,40,128Zm184.5,75.5a12,12,0,0,1,0,17,12.1,12.1,0,0,1-17,0L196,209l-11.5,11.5a12.1,12.1,0,0,1-17,0,12,12,0,0,1,0-17L179,192l-11.5-11.5a12,12,0,0,1,17-17L196,175l11.5-11.5a12,12,0,0,1,17,17L213,192Zm-43.4-92.6c-5.2,22.9-23.3,43.5-45.1,51.2a11.2,11.2,0,0,1-4,.7,12,12,0,0,1-4-23.3c14.1-5,26.3-18.9,29.7-33.9,2.4-10.5.4-20.9-5.7-30.6v9a12,12,0,0,1-24,0V44a12,12,0,0,1,12-12h40a12,12,0,0,1,0,24H168C180.8,72.6,185.4,91.8,181.1,110.9Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M96,188a28,28,0,1,1-28-28A28,28,0,0,1,96,188Z"
            opacity="0.2"
          />
          <path d="M68,152a36,36,0,1,0,36,36A36,36,0,0,0,68,152Zm0,56a20,20,0,1,1,20-20A20.1,20.1,0,0,1,68,208ZM34.3,106.3,48.7,92,34.3,77.7A8.1,8.1,0,0,1,45.7,66.3L60,80.7,74.3,66.3A8.1,8.1,0,0,1,85.7,77.7L71.3,92l14.4,14.3a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0L60,103.3,45.7,117.7a8.2,8.2,0,0,1-11.4,0A8.1,8.1,0,0,1,34.3,106.3Zm187.4,96a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0L196,199.3l-14.3,14.4a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L184.7,188l-14.4-14.3a8.1,8.1,0,0,1,11.4-11.4L196,176.7l14.3-14.4a8.1,8.1,0,0,1,11.4,11.4L207.3,188Zm-45.2-89.5c-6.2,22.4-25.3,41.7-46.6,47l-1.9.2a8,8,0,0,1-7.8-6.1,8.1,8.1,0,0,1,5.9-9.7c15.6-3.9,30.3-18.9,35-35.6,3.1-11.5,3.2-29-14.8-46.9L144,59.3V80a8,8,0,0,1-16,0V40a8,8,0,0,1,8-8h40a8,8,0,0,1,0,16H155.3l2.4,2.3C175.9,68.6,182.6,90.8,176.5,112.8Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M104,188a36,36,0,1,1-36-36A36,36,0,0,1,104,188ZM34.3,117.7a8.2,8.2,0,0,0,11.4,0L60,103.3l14.3,14.4a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4L71.3,92,85.7,77.7A8.1,8.1,0,0,0,74.3,66.3L60,80.7,45.7,66.3A8.1,8.1,0,0,0,34.3,77.7L48.7,92,34.3,106.3A8.1,8.1,0,0,0,34.3,117.7Zm173,70.3,14.4-14.3a8.1,8.1,0,0,0-11.4-11.4L196,176.7l-14.3-14.4a8.1,8.1,0,0,0-11.4,11.4L184.7,188l-14.4,14.3a8.1,8.1,0,0,0,0,11.4,8.2,8.2,0,0,0,11.4,0L196,199.3l14.3,14.4a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4ZM157.7,50.3,155.3,48H176a8,8,0,0,0,0-16H136a8,8,0,0,0-8,8V80a8,8,0,0,0,16,0V59.3l2.3,2.4c18,17.9,17.9,35.4,14.8,46.9-4.7,16.7-19.4,31.7-35,35.6a8.1,8.1,0,0,0-5.9,9.7A8,8,0,0,0,128,160l1.9-.2c21.3-5.3,40.4-24.6,46.6-47S175.9,68.6,157.7,50.3Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M68,154a34,34,0,1,0,34,34A34.1,34.1,0,0,0,68,154Zm0,56a22,22,0,1,1,22-22A22.1,22.1,0,0,1,68,210ZM35.8,107.8,51.5,92,35.8,76.2a5.9,5.9,0,0,1,8.4-8.4L60,83.5,75.8,67.8a5.9,5.9,0,0,1,8.4,8.4L68.5,92l15.7,15.8a5.9,5.9,0,1,1-8.4,8.4L60,100.5,44.2,116.2a5.9,5.9,0,0,1-8.4-8.4Zm184.4,96a5.9,5.9,0,1,1-8.4,8.4L196,196.5l-15.8,15.7a5.9,5.9,0,0,1-8.4-8.4L187.5,188l-15.7-15.8a5.9,5.9,0,0,1,8.4-8.4L196,179.5l15.8-15.7a5.9,5.9,0,0,1,8.4,8.4L204.5,188Zm-45.7-91.5c-6,21.7-24.5,40.4-45,45.5l-1.5.2a6,6,0,0,1-1.5-11.8c16.3-4.1,31.7-19.7,36.5-37.1,3.3-12,3.4-30.2-15.2-48.9L142,54.5V80a6,6,0,0,1-12,0V40a6,6,0,0,1,6-6h40a6,6,0,0,1,0,12H150.5l5.7,5.8C174,69.5,180.5,91,174.5,112.3Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M68,156a32,32,0,1,0,32,32A32.1,32.1,0,0,0,68,156Zm0,56a24,24,0,1,1,24-24A24.1,24.1,0,0,1,68,212ZM37.2,109.2,54.3,92,37.2,74.8a4,4,0,0,1,5.6-5.6L60,86.3,77.2,69.2a4,4,0,0,1,5.6,5.6L65.7,92l17.1,17.2a3.9,3.9,0,0,1,0,5.6,3.9,3.9,0,0,1-5.6,0L60,97.7,42.8,114.8a3.9,3.9,0,0,1-5.6,0A3.9,3.9,0,0,1,37.2,109.2Zm181.6,96a3.9,3.9,0,0,1,0,5.6,3.9,3.9,0,0,1-5.6,0L196,193.7l-17.2,17.1a3.9,3.9,0,0,1-5.6,0,3.9,3.9,0,0,1,0-5.6L190.3,188l-17.1-17.2a4,4,0,0,1,5.6-5.6L196,182.3l17.2-17.1a4,4,0,0,1,5.6,5.6L201.7,188Zm-46.2-93.4c-5.8,21-23.7,39.1-43.6,44.1h-1a4.1,4.1,0,0,1-3.9-3,4.1,4.1,0,0,1,2.9-4.9c17-4.2,32.9-20.4,37.9-38.5,3.5-12.5,3.6-31.5-15.7-50.8L140,49.7V80a4,4,0,0,1-8,0V40a4,4,0,0,1,4-4h40a4,4,0,0,1,0,8H145.7l9.1,9.2C172,70.4,178.3,91.2,172.6,111.8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M68,152a36,36,0,1,0,36,36A36,36,0,0,0,68,152Zm0,56a20,20,0,1,1,20-20A20.1,20.1,0,0,1,68,208ZM34.3,106.3,48.7,92,34.3,77.7A8.1,8.1,0,0,1,45.7,66.3L60,80.7,74.3,66.3A8.1,8.1,0,0,1,85.7,77.7L71.3,92l14.4,14.3a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0L60,103.3,45.7,117.7a8.2,8.2,0,0,1-11.4,0A8.1,8.1,0,0,1,34.3,106.3Zm187.4,96a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0L196,199.3l-14.3,14.4a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L184.7,188l-14.4-14.3a8.1,8.1,0,0,1,11.4-11.4L196,176.7l14.3-14.4a8.1,8.1,0,0,1,11.4,11.4L207.3,188Zm-45.2-89.5c-6.2,22.4-25.3,41.7-46.6,47l-1.9.2a8,8,0,0,1-7.8-6.1,8.1,8.1,0,0,1,5.9-9.7c15.6-3.9,30.3-18.9,35-35.6,3.1-11.5,3.2-29-14.8-46.9L144,59.3V80a8,8,0,0,1-16,0V40a8,8,0,0,1,8-8h40a8,8,0,0,1,0,16H155.3l2.4,2.3C175.9,68.6,182.6,90.8,176.5,112.8Z" />
        </>
      )}
    </svg>
  );
}

export function PhStripeLogo(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M152.1,152c0-6.7-11.6-10.2-27.8-14.3-9-2.3-18.2-4.7-25.7-8.6-13.8-7.3-16.7-17.7-16.7-25.1,0-20.5,19.8-36,46.1-36,18.6,0,34.7,7.9,42,20.7a12.2,12.2,0,0,1-4.5,16.4,12,12,0,0,1-16.4-4.5c-2.4-4.3-9.9-8.6-21.1-8.6s-22.1,4.8-22.1,12c0,4.2,15.2,8.1,24.3,10.4s19.8,5.1,28.1,9.7c14.7,8.1,17.8,19.7,17.8,27.9,0,20.2-21.1,36-48.1,36s-48.1-15.8-48.1-36a12,12,0,0,1,24,0c0,2.2,2,4.8,5.2,7,4.8,3.2,11.7,5,18.9,5s14.1-1.8,18.9-5C150.1,156.8,152.1,154.2,152.1,152ZM228,48V208a20.1,20.1,0,0,1-20,20H48a20.1,20.1,0,0,1-20-20V48A20.1,20.1,0,0,1,48,28H208A20.1,20.1,0,0,1,228,48Zm-24,4H52V204H204Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M216,48V208a8,8,0,0,1-8,8H48a8,8,0,0,1-8-8V48a8,8,0,0,1,8-8H208A8,8,0,0,1,216,48Z"
            opacity="0.2"
          />
          <path d="M172.1,152c0,17.9-19.4,32-44.1,32s-44.1-14.1-44.1-32a8,8,0,0,1,16,0c0,7.6,11.6,16,28.1,16,13.3,0,28.1-6.6,28.1-16s-12.2-13.4-30.8-18.2c-8.7-2.2-17.7-4.5-24.8-8.2-9.7-5.2-14.6-12.4-14.6-21.6,0-18.2,18.1-32,42.1-32,17.2,0,31.9,7.2,38.5,18.7a8,8,0,0,1-3,10.9,7.9,7.9,0,0,1-10.9-3C148.9,92.2,139.3,88,128,88c-14.6,0-26.1,7-26.1,16,0,6.8,10.8,10.1,27.3,14.3C148.3,123.2,172.1,129.3,172.1,152ZM224,48V208a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V48A16,16,0,0,1,48,32H208A16,16,0,0,1,224,48ZM208,208V48H48V208Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM128,184c-24.7,0-44.1-14.1-44.1-32a8,8,0,0,1,16,0c0,7.6,11.6,16,28.1,16,13.3,0,28.1-6.6,28.1-16s-12.2-13.4-30.8-18.2c-8.7-2.2-17.7-4.5-24.8-8.2-9.7-5.2-14.6-12.4-14.6-21.6,0-18.2,18.1-32,42.1-32,17.2,0,31.9,7.2,38.5,18.7a8,8,0,0,1-3,10.9,7.9,7.9,0,0,1-10.9-3C148.9,92.2,139.3,88,128,88c-14.6,0-26.1,7-26.1,16,0,6.8,10.8,10.1,27.3,14.3,19.1,4.9,42.9,11,42.9,33.7C172.1,169.9,152.7,184,128,184Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M170.1,152c0,16.8-18.5,30-42.1,30s-42.1-13.2-42.1-30a6,6,0,0,1,12,0c0,9.8,13.8,18,30.1,18s30.1-8.2,30.1-18-12.8-15.1-32.3-20.1c-17.8-4.6-37.9-9.7-37.9-27.9S105.2,74,128,74c16.5,0,30.5,6.8,36.8,17.7a6,6,0,0,1-10.5,5.9C150.3,90.6,140,86,128,86c-15.7,0-28.1,7.9-28.1,18,0,8.3,11.4,11.8,28.8,16.2S170.1,130.8,170.1,152ZM222,48V208a14,14,0,0,1-14,14H48a14,14,0,0,1-14-14V48A14,14,0,0,1,48,34H208A14,14,0,0,1,222,48Zm-12,0a2,2,0,0,0-2-2H48a2,2,0,0,0-2,2V208a2,2,0,0,0,2,2H208a2,2,0,0,0,2-2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M168.1,152c0,15.7-17.6,28-40.1,28s-40.1-12.3-40.1-28a4,4,0,0,1,8,0c0,10.8,14.7,20,32.1,20s32.1-9.2,32.1-20c0-12.5-13.4-16.9-33.8-22.1-17.9-4.5-36.4-9.3-36.4-25.9S106.3,76,128,76c15.7,0,29.2,6.4,35,16.7a4,4,0,0,1-1.5,5.4,3.9,3.9,0,0,1-5.4-1.5C151.6,88.8,140.9,84,128,84c-17.1,0-30.1,8.6-30.1,20,0,9.7,12,13.5,30.3,18.2S168.1,132.4,168.1,152ZM220,48V208a12,12,0,0,1-12,12H48a12,12,0,0,1-12-12V48A12,12,0,0,1,48,36H208A12,12,0,0,1,220,48Zm-8,0a4,4,0,0,0-4-4H48a4,4,0,0,0-4,4V208a4,4,0,0,0,4,4H208a4,4,0,0,0,4-4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M172.1,152c0,17.9-19.4,32-44.1,32s-44.1-14.1-44.1-32a8,8,0,0,1,16,0c0,7.6,11.6,16,28.1,16,13.3,0,28.1-6.6,28.1-16s-12.2-13.4-30.8-18.2c-8.7-2.2-17.7-4.5-24.8-8.2-9.7-5.2-14.6-12.4-14.6-21.6,0-18.2,18.1-32,42.1-32,17.2,0,31.9,7.2,38.5,18.7a8,8,0,0,1-3,10.9,7.9,7.9,0,0,1-10.9-3C148.9,92.2,139.3,88,128,88c-14.6,0-26.1,7-26.1,16,0,6.8,10.8,10.1,27.3,14.3C148.3,123.2,172.1,129.3,172.1,152ZM224,48V208a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V48A16,16,0,0,1,48,32H208A16,16,0,0,1,224,48ZM208,208V48H48V208Z" />
        </>
      )}
    </svg>
  );
}

export function PhStudent(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M227.8,52.6l-96-32a12.3,12.3,0,0,0-7.6,0L28.4,52.5h-.2l-.5.2-.6.3-.7.3-.4.2-.6.4-.3.2-.6.4a.2.2,0,0,1-.2.2l-.7.6a.1.1,0,0,1-.1.1l-.7.8-.2.3a.9.9,0,0,0-.4.6l-.3.3-.3.5c-.1.2-.2.3-.2.4l-.3.6-.2.4-.3.9a.8.8,0,0,0-.1.4,1.9,1.9,0,0,0-.2.7c0,.1-.1.3-.1.4s-.1.5-.1.7v.4A4.1,4.1,0,0,0,20,64v80a12,12,0,0,0,24,0V80.6l23.7,8a67.9,67.9,0,0,0,18.1,84.7,99.9,99.9,0,0,0-41.6,36.1,12,12,0,1,0,20.1,13.2,75.9,75.9,0,0,1,127.4,0,12.1,12.1,0,0,0,10.1,5.4,12,12,0,0,0,6.5-1.9,12.1,12.1,0,0,0,3.5-16.7,99.9,99.9,0,0,0-41.6-36.1,67.9,67.9,0,0,0,18.1-84.7l39.5-13.2a12,12,0,0,0,0-22.8Zm-99.8-8L186.1,64l-20.8,6.9h0L128,83.4,90.7,70.9h0L69.9,64ZM172,120A44,44,0,1,1,90.9,96.3l33.3,11.1a12.3,12.3,0,0,0,7.6,0l33.3-11.1A43.7,43.7,0,0,1,172,120Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M224,64,128,96,32,64l96-32Z" opacity="0.2" />
          <path d="M226.5,56.4l-96-32a8.5,8.5,0,0,0-5,0l-95.9,32h-.2l-1,.5h-.1l-1,.6c0,.1-.1.1-.2.2l-.8.7h0l-.7.8c0,.1-.1.1-.1.2l-.6.9c0,.1,0,.1-.1.2l-.4.9h0l-.3,1.1v.3A3.7,3.7,0,0,0,24,64v80a8,8,0,0,0,16,0V75.1L73.6,86.3A63.2,63.2,0,0,0,64,120a64,64,0,0,0,30,54.2,96.1,96.1,0,0,0-46.5,37.4,8.1,8.1,0,0,0,2.4,11.1,7.9,7.9,0,0,0,11-2.3,80,80,0,0,1,134.2,0,8,8,0,0,0,6.7,3.6,7.5,7.5,0,0,0,4.3-1.3,8.1,8.1,0,0,0,2.4-11.1A96.1,96.1,0,0,0,162,174.2,64,64,0,0,0,192,120a63.2,63.2,0,0,0-9.6-33.7l44.1-14.7a8,8,0,0,0,0-15.2ZM176,120a48,48,0,0,1-96,0,48.6,48.6,0,0,1,9.3-28.5l36.2,12.1a8,8,0,0,0,5,0l36.2-12.1A48.6,48.6,0,0,1,176,120Zm-9.3-45.3h-.1L128,87.6,89.4,74.7h-.1L57.3,64,128,40.4,198.7,64Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M226.5,56.4l-96-32a8.5,8.5,0,0,0-5,0l-95.9,32h-.2l-1,.5h-.1l-1,.6c0,.1-.1.1-.2.2l-.8.7h0l-.7.8c0,.1-.1.1-.1.2l-.6.9c0,.1,0,.1-.1.2l-.4.9h0l-.3,1.1v.3A3.7,3.7,0,0,0,24,64v80a8,8,0,0,0,16,0V75.1L73.6,86.3A63.2,63.2,0,0,0,64,120a64,64,0,0,0,30,54.2,96.1,96.1,0,0,0-46.5,37.4,8.1,8.1,0,0,0,2.4,11.1,7.9,7.9,0,0,0,11-2.3,80,80,0,0,1,134.2,0,8,8,0,0,0,6.7,3.6,7.5,7.5,0,0,0,4.3-1.3,8.1,8.1,0,0,0,2.4-11.1A96.1,96.1,0,0,0,162,174.2,64,64,0,0,0,192,120a63.2,63.2,0,0,0-9.6-33.7l44.1-14.7a8,8,0,0,0,0-15.2ZM128,168a48,48,0,0,1-48-48,48.6,48.6,0,0,1,9.3-28.5l36.2,12.1a8,8,0,0,0,5,0l36.2-12.1A48.6,48.6,0,0,1,176,120,48,48,0,0,1,128,168Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M225.9,58.3l-96-32a6.2,6.2,0,0,0-3.8,0l-95.9,32H30l-.7.4h-.1l-.7.4a.1.1,0,0,1-.1.1l-.7.6h0l-.5.6a.1.1,0,0,1-.1.1l-.4.7c0,.1-.1.1-.1.2l-.3.7h0a1.9,1.9,0,0,0-.2.8h0a2.8,2.8,0,0,0-.1.9v80a6,6,0,0,0,12,0V72.3L76.7,85.2A61.4,61.4,0,0,0,66,120a62,62,0,0,0,32.7,54.6,94.2,94.2,0,0,0-49.5,38.1,6,6,0,0,0,10.1,6.6,81.9,81.9,0,0,1,137.4,0,6.3,6.3,0,0,0,5.1,2.7,5.5,5.5,0,0,0,3.2-1,6,6,0,0,0,1.8-8.3,94.2,94.2,0,0,0-49.5-38.1A62,62,0,0,0,190,120a61.4,61.4,0,0,0-10.7-34.8l46.6-15.5a6,6,0,0,0,0-11.4ZM178,120A50,50,0,1,1,88.6,89.2l37.5,12.5a6.2,6.2,0,0,0,3.8,0l37.5-12.5A50.4,50.4,0,0,1,178,120ZM167.3,76.6h0L128,89.7,88.7,76.6h0L51,64l77-25.7L205,64Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M225.3,60.2l-96-32a3.9,3.9,0,0,0-2.6,0l-96,32h-.4l-.4.2h-.2l-.5.4h0c-.2.1-.3.3-.5.5s-.1.1-.1.2l-.2.4a.4.4,0,0,0-.1.3c-.1.1-.1.3-.2.4v.3a1.7,1.7,0,0,0-.1.7v80a4,4,0,0,0,8,0V69.6L79.9,84.2a59.9,59.9,0,0,0,24.4,90.9,91.7,91.7,0,0,0-53.4,38.7,4,4,0,0,0,6.7,4.4,84,84,0,0,1,140.8,0,4.1,4.1,0,0,0,3.4,1.8,3.8,3.8,0,0,0,2.2-.7,4,4,0,0,0,1.1-5.5,91.7,91.7,0,0,0-53.4-38.7,59.9,59.9,0,0,0,24.4-90.9l49.2-16.4a4,4,0,0,0,0-7.6ZM180,120A52,52,0,1,1,87.9,86.9l38.8,12.9,1.3.2,1.3-.2,38.8-12.9A51.7,51.7,0,0,1,180,120ZM168,78.4h0L128,91.8,44.6,64,128,36.2,211.4,64Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M226.5,56.4l-96-32a8.5,8.5,0,0,0-5,0l-95.9,32h-.2l-1,.5h-.1l-1,.6c0,.1-.1.1-.2.2l-.8.7h0l-.7.8c0,.1-.1.1-.1.2l-.6.9c0,.1,0,.1-.1.2l-.4.9h0l-.3,1.1v.3A3.7,3.7,0,0,0,24,64v80a8,8,0,0,0,16,0V75.1L73.6,86.3A63.2,63.2,0,0,0,64,120a64,64,0,0,0,30,54.2,96.1,96.1,0,0,0-46.5,37.4,8.1,8.1,0,0,0,2.4,11.1,7.9,7.9,0,0,0,11-2.3,80,80,0,0,1,134.2,0,8,8,0,0,0,6.7,3.6,7.5,7.5,0,0,0,4.3-1.3,8.1,8.1,0,0,0,2.4-11.1A96.1,96.1,0,0,0,162,174.2,64,64,0,0,0,192,120a63.2,63.2,0,0,0-9.6-33.7l44.1-14.7a8,8,0,0,0,0-15.2ZM176,120a48,48,0,0,1-96,0,48.6,48.6,0,0,1,9.3-28.5l36.2,12.1a8,8,0,0,0,5,0l36.2-12.1A48.6,48.6,0,0,1,176,120Zm-9.3-45.3h-.1L128,87.6,89.4,74.7h-.1L57.3,64,128,40.4,198.7,64Z" />
        </>
      )}
    </svg>
  );
}

export function PhSuitcaseSimple(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216,56H180V48a28.1,28.1,0,0,0-28-28H104A28.1,28.1,0,0,0,76,48v8H40A20.1,20.1,0,0,0,20,76V204a20.1,20.1,0,0,0,20,20H216a20.1,20.1,0,0,0,20-20V76A20.1,20.1,0,0,0,216,56ZM100,48a4,4,0,0,1,4-4h48a4,4,0,0,1,4,4v8H100ZM212,80v68H44V80ZM44,200V172H212v28Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M224,160v48a8,8,0,0,1-8,8H40a8,8,0,0,1-8-8V160" />
          </g>
          <path d="M216,64H176V56a24.1,24.1,0,0,0-24-24H104A24.1,24.1,0,0,0,80,56v8H40A16,16,0,0,0,24,80V208a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V80A16,16,0,0,0,216,64ZM96,56a8,8,0,0,1,8-8h48a8,8,0,0,1,8,8v8H96ZM216,80v72H40V80Zm0,128H40V168H216v40Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,64H176V56a24.1,24.1,0,0,0-24-24H104A24.1,24.1,0,0,0,80,56v8H40A16,16,0,0,0,24,80V208a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V80A16,16,0,0,0,216,64ZM96,56a8,8,0,0,1,8-8h48a8,8,0,0,1,8,8v8H96ZM216,80v72H40V80Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M216,66H174V56a22.1,22.1,0,0,0-22-22H104A22.1,22.1,0,0,0,82,56V66H40A14,14,0,0,0,26,80V208a14,14,0,0,0,14,14H216a14,14,0,0,0,14-14V80A14,14,0,0,0,216,66ZM94,56a10,10,0,0,1,10-10h48a10,10,0,0,1,10,10V66H94ZM40,78H216a2,2,0,0,1,2,2v74H38V80A2,2,0,0,1,40,78ZM216,210H40a2,2,0,0,1-2-2V166H218v42A2,2,0,0,1,216,210Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M216,68H172V56a20.1,20.1,0,0,0-20-20H104A20.1,20.1,0,0,0,84,56V68H40A12,12,0,0,0,28,80v79.9h0V208a12,12,0,0,0,12,12H216a12,12,0,0,0,12-12V80A12,12,0,0,0,216,68ZM92,56a12,12,0,0,1,12-12h48a12,12,0,0,1,12,12V68H92ZM40,76H216a4,4,0,0,1,4,4v76H36V80A4,4,0,0,1,40,76ZM216,212H40a4,4,0,0,1-4-4V164H220v44A4,4,0,0,1,216,212Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,64H176V56a24.1,24.1,0,0,0-24-24H104A24.1,24.1,0,0,0,80,56v8H40A16,16,0,0,0,24,80V208a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V80A16,16,0,0,0,216,64ZM96,56a8,8,0,0,1,8-8h48a8,8,0,0,1,8,8v8H96ZM216,80v72H40V80Zm0,128H40V168H216v40Z" />
        </>
      )}
    </svg>
  );
}

export function PhSuitcase(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216,56H180V48a28.1,28.1,0,0,0-28-28H104A28.1,28.1,0,0,0,76,48v8H40A20.1,20.1,0,0,0,20,76V204a20.1,20.1,0,0,0,20,20H216a20.1,20.1,0,0,0,20-20V76A20.1,20.1,0,0,0,216,56ZM100,48a4,4,0,0,1,4-4h48a4,4,0,0,1,4,4v8H100Zm56,32V200H100V80ZM44,80H76V200H44ZM212,200H180V80h32Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M88,72V216H40a8,8,0,0,1-8-8V80a8,8,0,0,1,8-8Z" />
          </g>
          <g opacity="0.2">
            <path d="M168,72V216h48a8,8,0,0,0,8-8V80a8,8,0,0,0-8-8Z" />
          </g>
          <path d="M216,64H176V56a24.1,24.1,0,0,0-24-24H104A24.1,24.1,0,0,0,80,56v8H40A16,16,0,0,0,24,80V208a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V80A16,16,0,0,0,216,64ZM96,56a8,8,0,0,1,8-8h48a8,8,0,0,1,8,8v8H96Zm64,24V208H96V80ZM40,80H80V208H40ZM216,208H176V80h40V208Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,64H176V56a24.1,24.1,0,0,0-24-24H104A24.1,24.1,0,0,0,80,56v8H40A16,16,0,0,0,24,80V208a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V80A16,16,0,0,0,216,64ZM96,80h64V208H96Zm0-24a8,8,0,0,1,8-8h48a8,8,0,0,1,8,8v8H96Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M216,66H174V56a22.1,22.1,0,0,0-22-22H104A22.1,22.1,0,0,0,82,56V66H40A14,14,0,0,0,26,80V208a14,14,0,0,0,14,14H216a14,14,0,0,0,14-14V80A14,14,0,0,0,216,66ZM94,56a10,10,0,0,1,10-10h48a10,10,0,0,1,10,10V66H94Zm68,22V210H94V78ZM38,208V80a2,2,0,0,1,2-2H82V210H40A2,2,0,0,1,38,208Zm180,0a2,2,0,0,1-2,2H174V78h42a2,2,0,0,1,2,2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M216,68H172V56a20.1,20.1,0,0,0-20-20H104A20.1,20.1,0,0,0,84,56V68H40A12,12,0,0,0,28,80V208a12,12,0,0,0,12,12H216a12,12,0,0,0,12-12V80A12,12,0,0,0,216,68ZM92,56a12,12,0,0,1,12-12h48a12,12,0,0,1,12,12V68H92Zm72,20V212H92V76ZM36,208V80a4,4,0,0,1,4-4H84V212H40A4,4,0,0,1,36,208Zm184,0a4,4,0,0,1-4,4H172V76h44a4,4,0,0,1,4,4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,64H176V56a24.1,24.1,0,0,0-24-24H104A24.1,24.1,0,0,0,80,56v8H40A16,16,0,0,0,24,80V208a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V80A16,16,0,0,0,216,64ZM96,56a8,8,0,0,1,8-8h48a8,8,0,0,1,8,8v8H96Zm64,24V208H96V80ZM40,80H80V208H40ZM216,208H176V80h40V208Z" />
        </>
      )}
    </svg>
  );
}

export function PhSunDim(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,56a72,72,0,1,0,72,72A72.1,72.1,0,0,0,128,56Zm0,120a48,48,0,1,1,48-48A48,48,0,0,1,128,176ZM116,28V20a12,12,0,0,1,24,0v8a12,12,0,0,1-24,0Zm74.2,37.8a12,12,0,0,1,0-17l5.7-5.7a12,12,0,0,1,17,17l-5.7,5.7a12,12,0,0,1-8.5,3.5A12.2,12.2,0,0,1,190.2,65.8ZM248,128a12,12,0,0,1-12,12h-8a12,12,0,0,1,0-24h8A12,12,0,0,1,248,128Zm-35.1,67.9a12.2,12.2,0,0,1,0,17,12.4,12.4,0,0,1-8.5,3.5,12,12,0,0,1-8.5-3.5l-5.7-5.7a12,12,0,0,1,17-17ZM140,228v8a12,12,0,0,1-24,0v-8a12,12,0,0,1,24,0ZM65.8,190.2a12,12,0,0,1,0,17l-5.7,5.7a12,12,0,0,1-8.5,3.5,12.4,12.4,0,0,1-8.5-3.5,12.2,12.2,0,0,1,0-17l5.7-5.7A12,12,0,0,1,65.8,190.2ZM40,128a12,12,0,0,1-12,12H20a12,12,0,0,1,0-24h8A12,12,0,0,1,40,128Zm3.1-67.9a12,12,0,0,1,17-17l5.7,5.7a12,12,0,0,1,0,17,12.2,12.2,0,0,1-8.5,3.5,12,12,0,0,1-8.5-3.5Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="128" cy="128" r="60" />
          </g>
          <path d="M128,60a68,68,0,1,0,68,68A68.1,68.1,0,0,0,128,60Zm0,120a52,52,0,1,1,52-52A52,52,0,0,1,128,180ZM120,36V28a8,8,0,0,1,16,0v8a8,8,0,0,1-16,0ZM51.6,62.9A8,8,0,0,1,62.9,51.6l5.7,5.7a8,8,0,0,1,0,11.3,8.1,8.1,0,0,1-11.3,0ZM44,128a8,8,0,0,1-8,8H28a8,8,0,0,1,0-16h8A8,8,0,0,1,44,128Zm24.6,59.4a8,8,0,0,1,0,11.3l-5.7,5.7a8,8,0,0,1-5.6,2.3,8.3,8.3,0,0,1-5.7-2.3,8,8,0,0,1,0-11.3l5.7-5.7A8,8,0,0,1,68.6,187.4ZM136,220v8a8,8,0,0,1-16,0v-8a8,8,0,0,1,16,0Zm68.4-26.9a8,8,0,0,1,0,11.3,8.3,8.3,0,0,1-5.7,2.3,8,8,0,0,1-5.6-2.3l-5.7-5.7a8,8,0,0,1,11.3-11.3ZM236,128a8,8,0,0,1-8,8h-8a8,8,0,0,1,0-16h8A8,8,0,0,1,236,128ZM187.4,68.6a8,8,0,0,1,0-11.3l5.7-5.7a8,8,0,1,1,11.3,11.3l-5.7,5.7a8.1,8.1,0,0,1-11.3,0Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M196,128a68,68,0,1,1-68-68A68.1,68.1,0,0,1,196,128ZM128,44a8,8,0,0,0,8-8V28a8,8,0,0,0-16,0v8A8,8,0,0,0,128,44ZM57.3,68.6a8.1,8.1,0,0,0,11.3,0,8,8,0,0,0,0-11.3l-5.7-5.7A8,8,0,0,0,51.6,62.9ZM36,120H28a8,8,0,0,0,0,16h8a8,8,0,0,0,0-16Zm21.3,67.4-5.7,5.7a8,8,0,0,0,0,11.3,8.3,8.3,0,0,0,5.7,2.3,8,8,0,0,0,5.6-2.3l5.7-5.7a8,8,0,0,0-11.3-11.3ZM128,212a8,8,0,0,0-8,8v8a8,8,0,0,0,16,0v-8A8,8,0,0,0,128,212Zm70.7-24.6a8,8,0,0,0-11.3,11.3l5.7,5.7a8,8,0,0,0,5.6,2.3,8.3,8.3,0,0,0,5.7-2.3,8,8,0,0,0,0-11.3ZM228,120h-8a8,8,0,0,0,0,16h8a8,8,0,0,0,0-16ZM193.1,70.9a7.8,7.8,0,0,0,5.6-2.3l5.7-5.7a8,8,0,1,0-11.3-11.3l-5.7,5.7a8,8,0,0,0,0,11.3A7.8,7.8,0,0,0,193.1,70.9Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,62a66,66,0,1,0,66,66A66.1,66.1,0,0,0,128,62Zm0,120a54,54,0,1,1,54-54A54,54,0,0,1,128,182ZM122,36V28a6,6,0,0,1,12,0v8a6,6,0,0,1-12,0ZM53,61.5A6,6,0,0,1,61.5,53l5.7,5.7a6.1,6.1,0,0,1,0,8.5,5.9,5.9,0,0,1-4.3,1.7,5.8,5.8,0,0,1-4.2-1.7ZM42,128a6,6,0,0,1-6,6H28a6,6,0,0,1,0-12h8A6,6,0,0,1,42,128Zm25.2,60.8a6.1,6.1,0,0,1,0,8.5L61.5,203a6.1,6.1,0,0,1-4.2,1.7A6.5,6.5,0,0,1,53,203a6.1,6.1,0,0,1,0-8.5l5.7-5.7A6.1,6.1,0,0,1,67.2,188.8ZM134,220v8a6,6,0,0,1-12,0v-8a6,6,0,0,1,12,0Zm69-25.5a6.1,6.1,0,0,1,0,8.5,6.5,6.5,0,0,1-4.3,1.7,6.1,6.1,0,0,1-4.2-1.7l-5.7-5.7a6,6,0,0,1,8.5-8.5ZM234,128a6,6,0,0,1-6,6h-8a6,6,0,0,1,0-12h8A6,6,0,0,1,234,128ZM203,53a6.1,6.1,0,0,1,0,8.5l-5.7,5.7a5.8,5.8,0,0,1-4.2,1.7,5.9,5.9,0,0,1-4.3-1.7,6.1,6.1,0,0,1,0-8.5l5.7-5.7A6.1,6.1,0,0,1,203,53Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,64a64,64,0,1,0,64,64A64.1,64.1,0,0,0,128,64Zm0,120a56,56,0,1,1,56-56A56,56,0,0,1,128,184ZM124,36V28a4,4,0,0,1,8,0v8a4,4,0,0,1-8,0ZM54.5,60.1a4,4,0,1,1,5.6-5.6l5.7,5.6a4.2,4.2,0,0,1,0,5.7,3.9,3.9,0,0,1-2.9,1.1,3.8,3.8,0,0,1-2.8-1.1ZM40,128a4,4,0,0,1-4,4H28a4,4,0,0,1,0-8h8A4,4,0,0,1,40,128Zm25.8,62.2a4.2,4.2,0,0,1,0,5.7l-5.7,5.6a3.9,3.9,0,0,1-2.8,1.2,3.8,3.8,0,0,1-2.8-1.2,3.9,3.9,0,0,1,0-5.6l5.6-5.7A4.2,4.2,0,0,1,65.8,190.2ZM132,220v8a4,4,0,0,1-8,0v-8a4,4,0,0,1,8,0Zm69.5-24.1a3.9,3.9,0,0,1,0,5.6,3.8,3.8,0,0,1-2.8,1.2,3.9,3.9,0,0,1-2.8-1.2l-5.7-5.6a4,4,0,0,1,5.7-5.7ZM232,128a4,4,0,0,1-4,4h-8a4,4,0,0,1,0-8h8A4,4,0,0,1,232,128ZM190.2,65.8a4.2,4.2,0,0,1,0-5.7l5.7-5.6a4,4,0,0,1,5.6,5.6l-5.6,5.7a3.8,3.8,0,0,1-2.8,1.1A3.9,3.9,0,0,1,190.2,65.8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,60a68,68,0,1,0,68,68A68.1,68.1,0,0,0,128,60Zm0,120a52,52,0,1,1,52-52A52,52,0,0,1,128,180ZM120,36V28a8,8,0,0,1,16,0v8a8,8,0,0,1-16,0ZM51.6,62.9A8,8,0,0,1,62.9,51.6l5.7,5.7a8,8,0,0,1,0,11.3,8.1,8.1,0,0,1-11.3,0ZM44,128a8,8,0,0,1-8,8H28a8,8,0,0,1,0-16h8A8,8,0,0,1,44,128Zm24.6,59.4a8,8,0,0,1,0,11.3l-5.7,5.7a8,8,0,0,1-5.6,2.3,8.3,8.3,0,0,1-5.7-2.3,8,8,0,0,1,0-11.3l5.7-5.7A8,8,0,0,1,68.6,187.4ZM136,220v8a8,8,0,0,1-16,0v-8a8,8,0,0,1,16,0Zm68.4-26.9a8,8,0,0,1,0,11.3,8.3,8.3,0,0,1-5.7,2.3,8,8,0,0,1-5.6-2.3l-5.7-5.7a8,8,0,0,1,11.3-11.3ZM236,128a8,8,0,0,1-8,8h-8a8,8,0,0,1,0-16h8A8,8,0,0,1,236,128ZM187.4,68.6a8,8,0,0,1,0-11.3l5.7-5.7a8,8,0,1,1,11.3,11.3l-5.7,5.7a8.1,8.1,0,0,1-11.3,0Z" />
        </>
      )}
    </svg>
  );
}

export function PhSunHorizon(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M74.1,45.1a12,12,0,1,1,22.1-9.2l7.7,18.5a12.1,12.1,0,0,1-6.5,15.7,12.5,12.5,0,0,1-4.6.9,12,12,0,0,1-11.1-7.4ZM19.9,112.2l18.5,7.7a12.5,12.5,0,0,0,4.6.9,12,12,0,0,0,4.6-23.1L29.1,90.1a12,12,0,1,0-9.2,22.1ZM213,120.8a12.5,12.5,0,0,0,4.6-.9l18.5-7.7a12,12,0,1,0-9.2-22.1l-18.5,7.6a12,12,0,0,0,4.6,23.1ZM158.6,70.1a12.5,12.5,0,0,0,4.6.9,12,12,0,0,0,11.1-7.4l7.6-18.5a12,12,0,1,0-22.1-9.2l-7.7,18.5A12.1,12.1,0,0,0,158.6,70.1ZM252,160a12,12,0,0,1-12,12H16a12,12,0,0,1,0-24H64a64,64,0,0,1,128,0h48A12,12,0,0,1,252,160ZM88,148h80a40,40,0,0,0-80,0Zm120,40H48a12,12,0,0,0,0,24H208a12,12,0,0,0,0-24Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M70.2,160a60,60,0,1,1,115.6,0Z" />
          </g>
          <path d="M77.7,43.6a8.1,8.1,0,0,1,4.4-10.5,7.9,7.9,0,0,1,10.4,4.4l7.7,18.4a8.1,8.1,0,0,1-4.3,10.5,8.5,8.5,0,0,1-3.1.6,8.2,8.2,0,0,1-7.4-4.9ZM21.5,108.5l18.4,7.7a8.5,8.5,0,0,0,3.1.6,8,8,0,0,0,3.1-15.4L27.6,93.7a8.1,8.1,0,0,0-10.5,4.4A7.9,7.9,0,0,0,21.5,108.5ZM213,116.8a8.5,8.5,0,0,0,3.1-.6l18.4-7.7a7.9,7.9,0,0,0,4.4-10.4,8.1,8.1,0,0,0-10.5-4.4l-18.5,7.7a8,8,0,0,0,3.1,15.4ZM160.1,66.4a8.5,8.5,0,0,0,3.1.6,8.2,8.2,0,0,0,7.4-4.9l7.7-18.5a8.1,8.1,0,0,0-4.4-10.5,7.9,7.9,0,0,0-10.4,4.4l-7.7,18.4A8.1,8.1,0,0,0,160.1,66.4ZM248,160a8,8,0,0,1-8,8H16a8,8,0,0,1,0-16H60.5a70.1,70.1,0,0,1-.5-8,68,68,0,0,1,136,0,70.1,70.1,0,0,1-.5,8H240A8,8,0,0,1,248,160ZM76.6,152H179.4a52.9,52.9,0,0,0,.6-8,52,52,0,0,0-104,0A52.9,52.9,0,0,0,76.6,152ZM208,192H48a8,8,0,0,0,0,16H208a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M77.7,43.6a8.1,8.1,0,0,1,4.4-10.5,7.9,7.9,0,0,1,10.4,4.4l7.7,18.4a8.1,8.1,0,0,1-4.3,10.5,8.5,8.5,0,0,1-3.1.6,8.2,8.2,0,0,1-7.4-4.9ZM213,116.8a8.5,8.5,0,0,0,3.1-.6l18.4-7.7a7.9,7.9,0,0,0,4.4-10.4,8.1,8.1,0,0,0-10.5-4.4l-18.5,7.7a8,8,0,0,0,3.1,15.4ZM160.1,66.4a8.5,8.5,0,0,0,3.1.6,8.2,8.2,0,0,0,7.4-4.9l7.7-18.5a8.1,8.1,0,0,0-4.4-10.5,7.9,7.9,0,0,0-10.4,4.4l-7.7,18.4A8.1,8.1,0,0,0,160.1,66.4ZM21.5,108.5l18.4,7.7a8.5,8.5,0,0,0,3.1.6,8,8,0,0,0,3.1-15.4L27.6,93.7a8.1,8.1,0,0,0-10.5,4.4A7.9,7.9,0,0,0,21.5,108.5ZM240,152H195.5a70.1,70.1,0,0,0,.5-8,68,68,0,0,0-136,0,70.1,70.1,0,0,0,.5,8H16a8,8,0,0,0,0,16H240a8,8,0,0,0,0-16Zm-32,40H48a8,8,0,0,0,0,16H208a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M79.6,42.8A6,6,0,0,1,82.8,35a6.1,6.1,0,0,1,7.9,3.2l7.6,18.5a5.8,5.8,0,0,1-3.2,7.8,4.8,4.8,0,0,1-2.3.5,6.1,6.1,0,0,1-5.6-3.7ZM22.2,106.7l18.5,7.6a6.4,6.4,0,0,0,2.3.5,6,6,0,0,0,2.3-11.5L26.8,95.6A6,6,0,0,0,19,98.8,6.1,6.1,0,0,0,22.2,106.7ZM213,114.8a6.4,6.4,0,0,0,2.3-.5l18.5-7.6a6.1,6.1,0,0,0,3.2-7.9,6,6,0,0,0-7.8-3.2l-18.5,7.7a6,6,0,0,0,2.3,11.5ZM160.9,64.5a4.8,4.8,0,0,0,2.3.5,6.1,6.1,0,0,0,5.6-3.7l7.6-18.5a6,6,0,0,0-3.2-7.8,6.1,6.1,0,0,0-7.9,3.2l-7.6,18.5A5.8,5.8,0,0,0,160.9,64.5ZM246,160a6,6,0,0,1-6,6H16a6,6,0,0,1,0-12H62.8a66,66,0,1,1,130.4,0H240A6,6,0,0,1,246,160ZM74.9,154H181.1a55.5,55.5,0,0,0,.9-10,54,54,0,0,0-108,0A55.5,55.5,0,0,0,74.9,154ZM208,194H48a6,6,0,0,0,0,12H208a6,6,0,0,0,0-12Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M81.4,42.1a4.2,4.2,0,0,1,2.2-5.3A4.1,4.1,0,0,1,88.8,39l7.7,18.5A4,4,0,0,1,92.8,63a3.9,3.9,0,0,1-3.7-2.5ZM23,104.8l18.5,7.7a4,4,0,0,0,1.5.3,3.9,3.9,0,0,0,3.7-2.5,4,4,0,0,0-2.2-5.2L26.1,97.4a4.2,4.2,0,0,0-5.3,2.2A4,4,0,0,0,23,104.8Zm190,8a4,4,0,0,0,1.5-.3l18.5-7.7a4,4,0,0,0,2.2-5.2,4.2,4.2,0,0,0-5.3-2.2l-18.4,7.7a4,4,0,0,0-2.2,5.2A3.9,3.9,0,0,0,213,112.8ZM161.7,62.7a4,4,0,0,0,1.5.3,3.9,3.9,0,0,0,3.7-2.5l7.7-18.4a4.2,4.2,0,0,0-2.2-5.3,4.1,4.1,0,0,0-5.2,2.2l-7.7,18.5A4,4,0,0,0,161.7,62.7ZM244,160a4,4,0,0,1-4,4H16a4,4,0,0,1,0-8H65.1A68.7,68.7,0,0,1,64,144a64,64,0,0,1,128,0,68.7,68.7,0,0,1-1.1,12H240A4,4,0,0,1,244,160ZM73.3,156H182.7a53.2,53.2,0,0,0,1.3-12,56,56,0,0,0-112,0A53.2,53.2,0,0,0,73.3,156ZM208,196H48a4,4,0,0,0,0,8H208a4,4,0,0,0,0-8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M77.7,43.6a8.1,8.1,0,0,1,4.4-10.5,7.9,7.9,0,0,1,10.4,4.4l7.7,18.4a8.1,8.1,0,0,1-4.3,10.5,8.5,8.5,0,0,1-3.1.6,8.2,8.2,0,0,1-7.4-4.9ZM21.5,108.5l18.4,7.7a8.5,8.5,0,0,0,3.1.6,8,8,0,0,0,3.1-15.4L27.6,93.7a8.1,8.1,0,0,0-10.5,4.4A7.9,7.9,0,0,0,21.5,108.5ZM213,116.8a8.5,8.5,0,0,0,3.1-.6l18.4-7.7a7.9,7.9,0,0,0,4.4-10.4,8.1,8.1,0,0,0-10.5-4.4l-18.5,7.7a8,8,0,0,0,3.1,15.4ZM160.1,66.4a8.5,8.5,0,0,0,3.1.6,8.2,8.2,0,0,0,7.4-4.9l7.7-18.5a8.1,8.1,0,0,0-4.4-10.5,7.9,7.9,0,0,0-10.4,4.4l-7.7,18.4A8.1,8.1,0,0,0,160.1,66.4ZM248,160a8,8,0,0,1-8,8H16a8,8,0,0,1,0-16H60.5a70.1,70.1,0,0,1-.5-8,68,68,0,0,1,136,0,70.1,70.1,0,0,1-.5,8H240A8,8,0,0,1,248,160ZM76.6,152H179.4a52.9,52.9,0,0,0,.6-8,52,52,0,0,0-104,0A52.9,52.9,0,0,0,76.6,152ZM208,192H48a8,8,0,0,0,0,16H208a8,8,0,0,0,0-16Z" />
        </>
      )}
    </svg>
  );
}

export function PhSun(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,56a72,72,0,1,0,72,72A72.1,72.1,0,0,0,128,56Zm0,120a48,48,0,1,1,48-48A48,48,0,0,1,128,176ZM116,28V12a12,12,0,0,1,24,0V28a12,12,0,0,1-24,0Zm74.2,37.8a12,12,0,0,1,0-17l11.3-11.3a12,12,0,0,1,17,17L207.2,65.8a12,12,0,0,1-8.5,3.5A12.2,12.2,0,0,1,190.2,65.8ZM256,128a12,12,0,0,1-12,12H228a12,12,0,0,1,0-24h16A12,12,0,0,1,256,128Zm-37.5,73.5a12,12,0,0,1,0,17A11.6,11.6,0,0,1,210,222a12,12,0,0,1-8.5-3.5l-11.3-11.3a12,12,0,0,1,17-17ZM140,228v16a12,12,0,0,1-24,0V228a12,12,0,0,1,24,0ZM65.8,190.2a12,12,0,0,1,0,17L54.5,218.5A12,12,0,0,1,46,222a11.6,11.6,0,0,1-8.5-3.5,12,12,0,0,1,0-17l11.3-11.3A12,12,0,0,1,65.8,190.2ZM28,140H12a12,12,0,0,1,0-24H28a12,12,0,0,1,0,24Zm9.5-85.5a12,12,0,0,1,17-17L65.8,48.8a12,12,0,0,1,0,17,12.2,12.2,0,0,1-8.5,3.5,12,12,0,0,1-8.5-3.5Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="128" cy="128" r="60" />
          </g>
          <path d="M128,60a68,68,0,1,0,68,68A68.1,68.1,0,0,0,128,60Zm0,120a52,52,0,1,1,52-52A52,52,0,0,1,128,180ZM120,36V16a8,8,0,0,1,16,0V36a8,8,0,0,1-16,0ZM43.1,54.5A8.1,8.1,0,1,1,54.5,43.1L68.6,57.3a8,8,0,0,1,0,11.3,8.1,8.1,0,0,1-11.3,0ZM36,136H16a8,8,0,0,1,0-16H36a8,8,0,0,1,0,16Zm32.6,51.4a8,8,0,0,1,0,11.3L54.5,212.9a8.3,8.3,0,0,1-5.7,2.3,8.5,8.5,0,0,1-5.7-2.3,8.1,8.1,0,0,1,0-11.4l14.2-14.1A8,8,0,0,1,68.6,187.4ZM136,220v20a8,8,0,0,1-16,0V220a8,8,0,0,1,16,0Zm76.9-18.5a8.1,8.1,0,0,1,0,11.4,8.5,8.5,0,0,1-5.7,2.3,8.3,8.3,0,0,1-5.7-2.3l-14.1-14.2a8,8,0,0,1,11.3-11.3ZM248,128a8,8,0,0,1-8,8H220a8,8,0,0,1,0-16h20A8,8,0,0,1,248,128ZM187.4,68.6a8,8,0,0,1,0-11.3l14.1-14.2a8.1,8.1,0,0,1,11.4,11.4L198.7,68.6a8.1,8.1,0,0,1-11.3,0Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M196,128a68,68,0,1,1-68-68A68.1,68.1,0,0,1,196,128ZM128,44a8,8,0,0,0,8-8V16a8,8,0,0,0-16,0V36A8,8,0,0,0,128,44ZM57.3,68.6a8.1,8.1,0,0,0,11.3,0,8,8,0,0,0,0-11.3L54.5,43.1A8.1,8.1,0,1,0,43.1,54.5ZM44,128a8,8,0,0,0-8-8H16a8,8,0,0,0,0,16H36A8,8,0,0,0,44,128Zm13.3,59.4L43.1,201.5a8.1,8.1,0,0,0,0,11.4,8.5,8.5,0,0,0,5.7,2.3,8.3,8.3,0,0,0,5.7-2.3l14.1-14.2a8,8,0,0,0-11.3-11.3ZM128,212a8,8,0,0,0-8,8v20a8,8,0,0,0,16,0V220A8,8,0,0,0,128,212Zm70.7-24.6a8,8,0,0,0-11.3,11.3l14.1,14.2a8.3,8.3,0,0,0,5.7,2.3,8.5,8.5,0,0,0,5.7-2.3,8.1,8.1,0,0,0,0-11.4ZM240,120H220a8,8,0,0,0,0,16h20a8,8,0,0,0,0-16ZM193.1,70.9a7.8,7.8,0,0,0,5.6-2.3l14.2-14.1a8.1,8.1,0,0,0-11.4-11.4L187.4,57.3a8,8,0,0,0,0,11.3A7.8,7.8,0,0,0,193.1,70.9Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,62a66,66,0,1,0,66,66A66.1,66.1,0,0,0,128,62Zm0,120a54,54,0,1,1,54-54A54,54,0,0,1,128,182ZM122,36V16a6,6,0,0,1,12,0V36a6,6,0,0,1-12,0ZM44.6,53A5.9,5.9,0,1,1,53,44.6L67.2,58.7a6.1,6.1,0,0,1,0,8.5,5.9,5.9,0,0,1-4.3,1.7,5.8,5.8,0,0,1-4.2-1.7ZM42,128a6,6,0,0,1-6,6H16a6,6,0,0,1,0-12H36A6,6,0,0,1,42,128Zm25.2,60.8a6.1,6.1,0,0,1,0,8.5L53,211.4a5.9,5.9,0,0,1-8.4-8.4l14.1-14.2A6.1,6.1,0,0,1,67.2,188.8ZM134,220v20a6,6,0,0,1-12,0V220a6,6,0,0,1,12,0Zm77.4-17a5.9,5.9,0,1,1-8.4,8.4l-14.2-14.1a6,6,0,0,1,8.5-8.5ZM246,128a6,6,0,0,1-6,6H220a6,6,0,0,1,0-12h20A6,6,0,0,1,246,128ZM211.4,44.6a5.8,5.8,0,0,1,0,8.4L197.3,67.2a5.8,5.8,0,0,1-4.2,1.7,5.9,5.9,0,0,1-4.3-1.7,6.1,6.1,0,0,1,0-8.5L203,44.6A5.8,5.8,0,0,1,211.4,44.6Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,64a64,64,0,1,0,64,64A64.1,64.1,0,0,0,128,64Zm0,120a56,56,0,1,1,56-56A56,56,0,0,1,128,184ZM124,36V16a4,4,0,0,1,8,0V36a4,4,0,0,1-8,0ZM46,51.6A4,4,0,1,1,51.6,46L65.8,60.1a4.2,4.2,0,0,1,0,5.7,3.9,3.9,0,0,1-2.9,1.1,3.8,3.8,0,0,1-2.8-1.1ZM40,128a4,4,0,0,1-4,4H16a4,4,0,0,1,0-8H36A4,4,0,0,1,40,128Zm25.8,62.2a4.2,4.2,0,0,1,0,5.7L51.6,210a3.8,3.8,0,0,1-2.8,1.2A3.9,3.9,0,0,1,46,210a3.9,3.9,0,0,1,0-5.6l14.1-14.2A4.2,4.2,0,0,1,65.8,190.2ZM132,220v20a4,4,0,0,1-8,0V220a4,4,0,0,1,8,0Zm78-15.6a3.9,3.9,0,0,1,0,5.6,3.9,3.9,0,0,1-2.8,1.2,3.8,3.8,0,0,1-2.8-1.2l-14.2-14.1a4,4,0,0,1,5.7-5.7ZM244,128a4,4,0,0,1-4,4H220a4,4,0,0,1,0-8h20A4,4,0,0,1,244,128ZM210,46a3.9,3.9,0,0,1,0,5.6L195.9,65.8a3.8,3.8,0,0,1-2.8,1.1,3.9,3.9,0,0,1-2.9-1.1,4.2,4.2,0,0,1,0-5.7L204.4,46A3.9,3.9,0,0,1,210,46Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,60a68,68,0,1,0,68,68A68.1,68.1,0,0,0,128,60Zm0,120a52,52,0,1,1,52-52A52,52,0,0,1,128,180ZM120,36V16a8,8,0,0,1,16,0V36a8,8,0,0,1-16,0ZM43.1,54.5A8.1,8.1,0,1,1,54.5,43.1L68.6,57.3a8,8,0,0,1,0,11.3,8.1,8.1,0,0,1-11.3,0ZM36,136H16a8,8,0,0,1,0-16H36a8,8,0,0,1,0,16Zm32.6,51.4a8,8,0,0,1,0,11.3L54.5,212.9a8.3,8.3,0,0,1-5.7,2.3,8.5,8.5,0,0,1-5.7-2.3,8.1,8.1,0,0,1,0-11.4l14.2-14.1A8,8,0,0,1,68.6,187.4ZM136,220v20a8,8,0,0,1-16,0V220a8,8,0,0,1,16,0Zm76.9-18.5a8.1,8.1,0,0,1,0,11.4,8.5,8.5,0,0,1-5.7,2.3,8.3,8.3,0,0,1-5.7-2.3l-14.1-14.2a8,8,0,0,1,11.3-11.3ZM248,128a8,8,0,0,1-8,8H220a8,8,0,0,1,0-16h20A8,8,0,0,1,248,128ZM187.4,68.6a8,8,0,0,1,0-11.3l14.1-14.2a8.1,8.1,0,0,1,11.4,11.4L198.7,68.6a8.1,8.1,0,0,1-11.3,0Z" />
        </>
      )}
    </svg>
  );
}

export function PhSunglasses(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M200,36a12,12,0,0,0,0,24,12,12,0,0,1,12,12v56H44V72A12,12,0,0,1,56,60a12,12,0,0,0,0-24A36,36,0,0,0,20,72v92a48,48,0,0,0,96,0V152h24v12a48,48,0,0,0,96,0V72A36,36,0,0,0,200,36Zm11.2,134.2L193,152h19v12A25.7,25.7,0,0,1,211.2,170.2ZM44,164v-7l30.2,30.2a25.7,25.7,0,0,1-6.2.8A24.1,24.1,0,0,1,44,164Zm48,0a25.7,25.7,0,0,1-.8,6.2L73,152H92Zm72,0v-7l30.2,30.2a25.7,25.7,0,0,1-6.2.8A24.1,24.1,0,0,1,164,164Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M32,140h72v24a36,36,0,0,1-72,0Zm120,0v24a36,36,0,0,0,72,0V140Z"
            opacity="0.2"
          />
          <path d="M200,40a8,8,0,0,0,0,16,16,16,0,0,1,16,16v60H40V72A16,16,0,0,1,56,56a8,8,0,0,0,0-16A32.1,32.1,0,0,0,24,72v92a44,44,0,0,0,77,29.1h0a6,6,0,0,0,.8-.8A43.9,43.9,0,0,0,112,164V148h32v16a44,44,0,0,0,77,29.1h0a2.7,2.7,0,0,0,.7-.8A43.8,43.8,0,0,0,232,164V72A32.1,32.1,0,0,0,200,40Zm16,124a27.1,27.1,0,0,1-2.1,10.6L187.3,148H216ZM40,164V148h4.7l39.1,39.1A27.6,27.6,0,0,1,68,192,28.1,28.1,0,0,1,40,164Zm56,0a27.1,27.1,0,0,1-2.1,10.6L67.3,148H96Zm64,0V148h4.7l39.1,39.1A27.6,27.6,0,0,1,188,192,28.1,28.1,0,0,1,160,164Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M200,40a8,8,0,0,0,0,16,16,16,0,0,1,16,16v60H40V72A16,16,0,0,1,56,56a8,8,0,0,0,0-16A32.1,32.1,0,0,0,24,72v92a44,44,0,0,0,88,0V148h32v16a44,44,0,0,0,88,0V72A32.1,32.1,0,0,0,200,40ZM85,177a7.7,7.7,0,0,1-5.6,2.4,8,8,0,0,1-5.7-2.4L58.3,161.7a8.1,8.1,0,0,1,11.4-11.4L85,165.7A7.9,7.9,0,0,1,85,177Zm120,0a7.7,7.7,0,0,1-5.6,2.4,8,8,0,0,1-5.7-2.4l-15.4-15.3a8.1,8.1,0,0,1,11.4-11.4L205,165.7A7.9,7.9,0,0,1,205,177Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M200,42a6,6,0,0,0,0,12,18.1,18.1,0,0,1,18,18v62H38V72A18.1,18.1,0,0,1,56,54a6,6,0,0,0,0-12A30.1,30.1,0,0,0,26,72v92a42,42,0,0,0,84,0V146h36v18a42,42,0,0,0,84,0V72A30.1,30.1,0,0,0,200,42Zm14.5,136-32-32H218v18A29.9,29.9,0,0,1,214.5,178ZM38,164V146h7.5l41.3,41.3A29.6,29.6,0,0,1,68,194,30.1,30.1,0,0,1,38,164Zm60,0a29.9,29.9,0,0,1-3.5,14l-32-32H98Zm60,0V146h7.5l41.3,41.3A29.6,29.6,0,0,1,188,194,30.1,30.1,0,0,1,158,164Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M200,44a4,4,0,0,0,0,8,20.1,20.1,0,0,1,20,20v64H36V72A20.1,20.1,0,0,1,56,52a4,4,0,0,0,0-8A28.1,28.1,0,0,0,28,72v92a40,40,0,0,0,80,0V144h40v20a40,40,0,0,0,80,0V72A28.1,28.1,0,0,0,200,44ZM36,164V144H46.3l43.5,43.4A32,32,0,0,1,36,164Zm64,0a31.9,31.9,0,0,1-5.1,17.3L57.7,144H100Zm56,0V144h10.3l43.5,43.4A32,32,0,0,1,156,164Zm58.9,17.3L177.7,144H220v20A31.9,31.9,0,0,1,214.9,181.3Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M200,40a8,8,0,0,0,0,16,16,16,0,0,1,16,16v60H40V72A16,16,0,0,1,56,56a8,8,0,0,0,0-16A32.1,32.1,0,0,0,24,72v92a44,44,0,0,0,88,0V148h32v16a44,44,0,0,0,88,0V72A32.1,32.1,0,0,0,200,40Zm13.9,134.6L187.3,148H216v16A27.1,27.1,0,0,1,213.9,174.6ZM40,164V148h4.7l39.1,39.1A27.6,27.6,0,0,1,68,192,28.1,28.1,0,0,1,40,164Zm56,0a27.1,27.1,0,0,1-2.1,10.6L67.3,148H96Zm64,0V148h4.7l39.1,39.1A27.6,27.6,0,0,1,188,192,28.1,28.1,0,0,1,160,164Z" />
        </>
      )}
    </svg>
  );
}

export function PhSwap(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M228,48V152a20.1,20.1,0,0,1-20,20H109l3.5,3.5a12,12,0,0,1,0,17,12.1,12.1,0,0,1-17,0l-24-24a12,12,0,0,1,0-17l24-24a12,12,0,0,1,17,17L109,148h95V52H100v4a12,12,0,0,1-24,0V48A20.1,20.1,0,0,1,96,28H208A20.1,20.1,0,0,1,228,48ZM168,188a12,12,0,0,0-12,12v4H52V108h95l-3.5,3.5a12,12,0,0,0,0,17,12.1,12.1,0,0,0,17,0l24-24a12,12,0,0,0,0-17l-24-24a12,12,0,0,0-17,17L147,84H48a20.1,20.1,0,0,0-20,20V208a20.1,20.1,0,0,0,20,20H160a20.1,20.1,0,0,0,20-20v-8A12,12,0,0,0,168,188Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M216,48V152a8,8,0,0,1-8,8H168v48a8,8,0,0,1-8,8H48a8,8,0,0,1-8-8V104a8,8,0,0,1,8-8H88V48a8,8,0,0,1,8-8H208A8,8,0,0,1,216,48Z"
            opacity="0.2"
          />
          <path d="M224,48V152a16,16,0,0,1-16,16H99.3l10.4,10.3a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0l-24-24a8.1,8.1,0,0,1,0-11.4l24-24a8.1,8.1,0,0,1,11.4,11.4L99.3,152H208V48H96v8a8,8,0,0,1-16,0V48A16,16,0,0,1,96,32H208A16,16,0,0,1,224,48ZM168,192a8,8,0,0,0-8,8v8H48V104H156.7l-10.4,10.3a8.1,8.1,0,0,0,0,11.4,8.2,8.2,0,0,0,11.4,0l24-24a8.1,8.1,0,0,0,0-11.4l-24-24a8.1,8.1,0,0,0-11.4,11.4L156.7,88H48a16,16,0,0,0-16,16V208a16,16,0,0,0,16,16H160a16,16,0,0,0,16-16v-8A8,8,0,0,0,168,192Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M224,48V152a16,16,0,0,1-16,16H112v16a8,8,0,0,1-4.9,7.4,8.5,8.5,0,0,1-3.1.6,8.3,8.3,0,0,1-5.7-2.3l-24-24a8.1,8.1,0,0,1,0-11.4l24-24a8.4,8.4,0,0,1,8.8-1.7A8,8,0,0,1,112,136v16h96V48H96v8a8,8,0,0,1-16,0V48A16,16,0,0,1,96,32H208A16,16,0,0,1,224,48ZM168,192a8,8,0,0,0-8,8v8H48V104h96v16a8.1,8.1,0,0,0,4.9,7.4,8.5,8.5,0,0,0,3.1.6,8.3,8.3,0,0,0,5.7-2.3l24-24a8.1,8.1,0,0,0,0-11.4l-24-24a8.2,8.2,0,0,0-8.8-1.7A8,8,0,0,0,144,72V88H48a16,16,0,0,0-16,16V208a16,16,0,0,0,16,16H160a16,16,0,0,0,16-16v-8A8,8,0,0,0,168,192Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M222,48V152a14,14,0,0,1-14,14H94.5l13.7,13.8a5.9,5.9,0,1,1-8.4,8.4l-24-24a5.8,5.8,0,0,1,0-8.4l24-24a5.9,5.9,0,0,1,8.4,8.4L94.5,154H208a2,2,0,0,0,2-2V48a2,2,0,0,0-2-2H96a2,2,0,0,0-2,2v8a6,6,0,0,1-12,0V48A14,14,0,0,1,96,34H208A14,14,0,0,1,222,48ZM168,194a6,6,0,0,0-6,6v8a2,2,0,0,1-2,2H48a2,2,0,0,1-2-2V104a2,2,0,0,1,2-2H161.5l-13.7,13.8a5.9,5.9,0,1,0,8.4,8.4l24-24a5.8,5.8,0,0,0,0-8.4l-24-24a5.9,5.9,0,0,0-8.4,8.4L161.5,90H48a14,14,0,0,0-14,14V208a14,14,0,0,0,14,14H160a14,14,0,0,0,14-14v-8A6,6,0,0,0,168,194Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M220,48V152a12,12,0,0,1-12,12H89.6l17.2,17.2a3.9,3.9,0,0,1,0,5.6,3.9,3.9,0,0,1-5.6,0l-24-24a3.9,3.9,0,0,1,0-5.6l24-24a4,4,0,0,1,5.6,5.6L89.7,156H208a4,4,0,0,0,4-4V48a4,4,0,0,0-4-4H96a4,4,0,0,0-4,4v8a4,4,0,0,1-8,0V48A12,12,0,0,1,96,36H208A12,12,0,0,1,220,48ZM168,196a4,4,0,0,0-4,4v8a4,4,0,0,1-4,4H48a4,4,0,0,1-4-4V104a4,4,0,0,1,4-4H166.3l-17.1,17.2a3.9,3.9,0,0,0,0,5.6,3.9,3.9,0,0,0,5.6,0l24-24a3.9,3.9,0,0,0,0-5.6l-24-24a4,4,0,0,0-5.6,5.6L166.4,92H48a12,12,0,0,0-12,12V208a12,12,0,0,0,12,12H160a12,12,0,0,0,12-12v-8A4,4,0,0,0,168,196Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M224,48V152a16,16,0,0,1-16,16H99.3l10.4,10.3a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0l-24-24a8.1,8.1,0,0,1,0-11.4l24-24a8.1,8.1,0,0,1,11.4,11.4L99.3,152H208V48H96v8a8,8,0,0,1-16,0V48A16,16,0,0,1,96,32H208A16,16,0,0,1,224,48ZM168,192a8,8,0,0,0-8,8v8H48V104H156.7l-10.4,10.3a8.1,8.1,0,0,0,0,11.4,8.2,8.2,0,0,0,11.4,0l24-24a8.1,8.1,0,0,0,0-11.4l-24-24a8.1,8.1,0,0,0-11.4,11.4L156.7,88H48a16,16,0,0,0-16,16V208a16,16,0,0,0,16,16H160a16,16,0,0,0,16-16v-8A8,8,0,0,0,168,192Z" />
        </>
      )}
    </svg>
  );
}

export function PhSwatches(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M231.9,151.6,212.8,99a20.1,20.1,0,0,0-25.7-12l-53.4,19.5,9.9-56a20.4,20.4,0,0,0-3.3-15,19.9,19.9,0,0,0-12.9-8.2L72.2,17.6A20,20,0,0,0,49,33.8L24.9,171a49.3,49.3,0,0,0,8.4,37,47.2,47.2,0,0,0,31.3,19.4,44.9,44.9,0,0,0,7.3.6H212a20.1,20.1,0,0,0,20-20V165A19.3,19.3,0,0,0,231.9,151.6Zm-40.3-40.7L208,156l-88.7,32.3h0l9.6-54.5ZM52.8,194a24.9,24.9,0,0,1-4.3-18.9L72,41.9l47.3,8.3-23.7,134a24,24,0,0,1-27.3,19.5A23.6,23.6,0,0,1,52.8,194Zm93.6,10L208,181.6V204ZM69.9,191.8a11.9,11.9,0,0,1-9.7-13.9l2.1-11.8a12,12,0,0,1,23.6,4.2l-2.1,11.8A12,12,0,0,1,72,192Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M36.7,173,60.9,35.9a7.9,7.9,0,0,1,9.2-6.5l55.2,9.7a8.1,8.1,0,0,1,6.5,9.3L107.5,186.3a36.1,36.1,0,0,1-41.1,29.3C46.5,212.4,33.2,192.9,36.7,173Z" />
          </g>
          <path d="M72,192a12,12,0,1,1,12-12A12,12,0,0,1,72,192Zm156-27.7V208a16,16,0,0,1-16,16H71.9a51.6,51.6,0,0,1-6.7-.5,43.4,43.4,0,0,1-28.7-17.9,45,45,0,0,1-7.7-33.9L53,34.5a16.1,16.1,0,0,1,18.5-13l55.2,9.7A16.2,16.2,0,0,1,137,37.8a16,16,0,0,1,2.6,12l-11,62.8,59.9-21.8a16,16,0,0,1,20.5,9.5L228.2,153A15.7,15.7,0,0,1,228,164.3ZM125.4,130.8l-10.1,56.8a41,41,0,0,1-1.8,7.1l99.6-36.3L194,105.8ZM67.7,207.7a28.3,28.3,0,0,0,31.9-22.8L123.9,47,68.7,37.3,44.5,174.4a29,29,0,0,0,5.1,21.9A27.5,27.5,0,0,0,67.7,207.7ZM212,208V175.9L123.7,208Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M228.2,153,209,100.3a16,16,0,0,0-20.5-9.5l-59.9,21.8,11-62.8a16,16,0,0,0-2.6-12,16.2,16.2,0,0,0-10.3-6.6L71.5,21.5A16.1,16.1,0,0,0,53,34.5L28.8,171.7a45,45,0,0,0,7.7,33.9,43.4,43.4,0,0,0,28.7,17.9,51.6,51.6,0,0,0,6.7.5H212a16,16,0,0,0,16-16V164.3A15.7,15.7,0,0,0,228.2,153ZM72,192a12,12,0,1,1,12-12A12,12,0,0,1,72,192Zm140,16H123.7L212,175.9Zm-98.5-13.3a41,41,0,0,0,1.8-7.1l10.1-56.8,68.6-25,19.1,52.6Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M72,190a10,10,0,1,1,10-10A10,10,0,0,1,72,190Zm154-26.1V208a14,14,0,0,1-14,14H71.9a47.2,47.2,0,0,1-6.4-.5,41.3,41.3,0,0,1-27.3-17A43.3,43.3,0,0,1,30.8,172L54.9,34.8A14.1,14.1,0,0,1,71.2,23.5l55.1,9.7a14.2,14.2,0,0,1,9.1,5.8,14.1,14.1,0,0,1,2.3,10.4L126,115.7l63.2-23a13.9,13.9,0,0,1,17.9,8.3l19.2,52.6A14.6,14.6,0,0,1,226,163.9ZM123.6,129.3l-10.2,58a42.7,42.7,0,0,1-3.5,10.8l103.9-37.8a2,2,0,0,0,1.2-2.6l-19.1-52.6a2.1,2.1,0,0,0-2.6-1.2ZM67.4,209.6a30,30,0,0,0,34.1-24.4L125.9,47.3a2.1,2.1,0,0,0-.4-1.5,2.2,2.2,0,0,0-1.3-.8L69.1,35.3h-.4a1.8,1.8,0,0,0-1.9,1.6L42.6,174.1a31,31,0,0,0,5.3,23.4A29.2,29.2,0,0,0,67.4,209.6ZM214,208V173L112.4,210H212A2,2,0,0,0,214,208Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M72,188a8,8,0,1,1,8-8A8,8,0,0,1,72,188Zm152-24.5V208a12,12,0,0,1-12,12H71.9a43,43,0,0,1-6.1-.5,39.1,39.1,0,0,1-26-16.2,41,41,0,0,1-7.1-30.9L56.9,35.2a12,12,0,0,1,5-7.8,12.2,12.2,0,0,1,8.9-2L126,35.2a11.7,11.7,0,0,1,7.7,4.9,11.5,11.5,0,0,1,2,9l-12.3,69.6,66.5-24.2a12.1,12.1,0,0,1,15.4,7.2l19.1,52.6A12.2,12.2,0,0,1,224,163.5ZM121.8,127.8l-10.4,59.1a40.4,40.4,0,0,1-6,15l109.1-39.7a4,4,0,0,0,2.4-5.1l-19.2-52.7a4.1,4.1,0,0,0-5.1-2.4ZM67.1,211.6a32.1,32.1,0,0,0,36.4-26L127.8,47.7a3.9,3.9,0,0,0-.6-3,4.4,4.4,0,0,0-2.6-1.7L69.4,33.3h-.7a3.9,3.9,0,0,0-3.9,3.3L40.6,173.7a33.6,33.6,0,0,0,5.7,25A31.3,31.3,0,0,0,67.1,211.6ZM216,208V170.2L101,212H212A4,4,0,0,0,216,208Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M72,192a12,12,0,1,1,12-12A12,12,0,0,1,72,192Zm156-27.7V208a16,16,0,0,1-16,16H71.9a51.6,51.6,0,0,1-6.7-.5,43.4,43.4,0,0,1-28.7-17.9,45,45,0,0,1-7.7-33.9L53,34.5a16.1,16.1,0,0,1,18.5-13l55.2,9.7A16.2,16.2,0,0,1,137,37.8a16,16,0,0,1,2.6,12l-11,62.8,59.9-21.8a16,16,0,0,1,20.5,9.5L228.2,153A15.7,15.7,0,0,1,228,164.3ZM125.4,130.8l-10.1,56.8a41,41,0,0,1-1.8,7.1l99.6-36.3L194,105.8ZM67.7,207.7a28.3,28.3,0,0,0,31.9-22.8L123.9,47,68.7,37.3,44.5,174.4a29,29,0,0,0,5.1,21.9A27.5,27.5,0,0,0,67.7,207.7ZM212,208V175.9L123.7,208Z" />
        </>
      )}
    </svg>
  );
}

export function PhSword(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M224.5,31.5A12.3,12.3,0,0,0,216,28h0l-63.8.2a12,12,0,0,0-8.9,3.9L71.8,110.8l-1.7-1.6a19.9,19.9,0,0,0-28.2,0L25.2,125.9a19.8,19.8,0,0,0,0,28.2l14,14.1L20.1,187.3a20,20,0,0,0,0,28.3l20.3,20.3a20.2,20.2,0,0,0,28.3,0l19.1-19.1,14.1,14a19.9,19.9,0,0,0,28.2,0l16.7-16.7a19.8,19.8,0,0,0,0-28.2l-1.6-1.7,78.7-71.5a12,12,0,0,0,3.9-8.9L228,40A12.3,12.3,0,0,0,224.5,31.5ZM116,211l-14.1-14a19.8,19.8,0,0,0-28.2,0L54.5,216.1,39.9,201.5,59,182.3a19.9,19.9,0,0,0,0-28.2L45,140l11-11,35.5,35.5h0L127,200ZM203.8,98.5l-75.6,68.7L117,156l51.5-51.5a12,12,0,0,0-17-17L100,139,88.8,127.8l68.7-75.6L204,52Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M82.1,197.5,52.2,227.4a8,8,0,0,1-11.3,0L28.6,215.1a8,8,0,0,1,0-11.3l29.9-29.9a8,8,0,0,0,0-11.4L37.7,141.7a8,8,0,0,1,0-11.4l12.6-12.6a8,8,0,0,1,11.4,0l76.6,76.6a8,8,0,0,1,0,11.4l-12.6,12.6a8,8,0,0,1-11.4,0L93.5,197.5A8,8,0,0,0,82.1,197.5Z" />
          </g>
          <path d="M221.7,34.3A8.1,8.1,0,0,0,216,32h0l-63.8.2a8.1,8.1,0,0,0-6.2,2.9L75.6,120.3,67.3,112a16.1,16.1,0,0,0-22.6,0L32,124.7a15.9,15.9,0,0,0,0,22.6l20.9,20.9-30,29.9a16.2,16.2,0,0,0,0,22.7l12.3,12.3a16.3,16.3,0,0,0,22.7,0l29.9-30L108.7,224a16.1,16.1,0,0,0,22.6,0L144,211.3a15.9,15.9,0,0,0,0-22.6l-8.3-8.3L220.9,110a8.1,8.1,0,0,0,2.9-6.2L224,40A8.1,8.1,0,0,0,221.7,34.3ZM120,212.7,99.1,191.8a15.9,15.9,0,0,0-22.6,0l-30,29.9L34.3,209.5l29.9-30a16.1,16.1,0,0,0,0-22.6L43.3,136,56,123.3l14.5,14.5h0l23.8,23.9h0L132.7,200ZM207.8,100l-83.5,69-13-13,54.4-54.3a8.1,8.1,0,0,0-11.4-11.4L100,144.7l-13-13,69-83.5,52-.2Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M221.7,34.3A8.1,8.1,0,0,0,216,32h0l-63.8.2a8.1,8.1,0,0,0-6.2,2.9L75.6,120.3,67.3,112a16.1,16.1,0,0,0-22.6,0L32,124.7a15.9,15.9,0,0,0,0,22.6l20.9,20.9-30,29.9a16.2,16.2,0,0,0,0,22.7l12.3,12.3a16.3,16.3,0,0,0,22.7,0l29.9-30L108.7,224a16.1,16.1,0,0,0,22.6,0L144,211.3a15.9,15.9,0,0,0,0-22.6l-8.3-8.3L220.9,110a8.1,8.1,0,0,0,2.9-6.2L224,40A8.1,8.1,0,0,0,221.7,34.3ZM207.8,100l-83.5,69-13-13,54.4-54.3a8.1,8.1,0,0,0-11.4-11.4L100,144.7l-13-13,69-83.5,52-.2Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M220.2,35.8A5.6,5.6,0,0,0,216,34h0l-63.8.2a5.8,5.8,0,0,0-4.6,2.2L75.8,123.3l-9.9-9.9a14.1,14.1,0,0,0-19.8,0L33.4,126.1a14,14,0,0,0,0,19.8l20.9,20.9a1.9,1.9,0,0,1,0,2.8l-29.9,30a13.9,13.9,0,0,0,0,19.8l12.2,12.2a13.8,13.8,0,0,0,9.9,4.1,13.5,13.5,0,0,0,9.9-4.1l30-29.9h0a1.9,1.9,0,0,1,2.8,0l20.9,20.9a14.1,14.1,0,0,0,19.8,0l12.7-12.7a14,14,0,0,0,0-19.8l-9.9-9.9,86.9-71.8a5.8,5.8,0,0,0,2.2-4.6L222,40A5.6,5.6,0,0,0,220.2,35.8ZM134.1,198.6a1.9,1.9,0,0,1,0,2.8l-12.7,12.7a1.9,1.9,0,0,1-2.8,0L97.7,193.2a14,14,0,0,0-19.8,0L48,223.2a2.1,2.1,0,0,1-1.5.5,1.8,1.8,0,0,1-1.4-.5L32.8,210.9a2.3,2.3,0,0,1,0-2.9l30-29.9a14.1,14.1,0,0,0,0-19.8L41.9,137.4a1.9,1.9,0,0,1,0-2.8l12.7-12.7a1.9,1.9,0,0,1,2.8,0l14.5,14.5h0ZM209.8,101l-85.6,70.7L108.5,156l55.7-55.8a5.9,5.9,0,0,0-8.4-8.4L100,147.5,84.3,131.8,155,46.2l55-.2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M218.8,37.2A3.6,3.6,0,0,0,216,36h0l-63.8.2a3.9,3.9,0,0,0-3.1,1.5L75.9,126.3,64.5,114.8a12.2,12.2,0,0,0-17,0L34.8,127.5a12.1,12.1,0,0,0,0,17l20.9,20.9a3.9,3.9,0,0,1,0,5.6L25.8,201a11.9,11.9,0,0,0,0,16.9l12.3,12.3a12,12,0,0,0,16.9,0l30-29.9a3.9,3.9,0,0,1,5.6,0l20.9,20.9a12.2,12.2,0,0,0,17,0l12.7-12.7a12.1,12.1,0,0,0,0-17l-11.5-11.4,88.6-73.2a3.9,3.9,0,0,0,1.5-3.1L220,40A3.6,3.6,0,0,0,218.8,37.2ZM136.7,200a3.6,3.6,0,0,1-1.2,2.8l-12.7,12.7a3.9,3.9,0,0,1-5.6,0L96.3,194.6a12.1,12.1,0,0,0-17,0l-29.9,30a4.4,4.4,0,0,1-2.9,1.1,4.3,4.3,0,0,1-2.8-1.1L31.4,212.3a4.2,4.2,0,0,1,0-5.7l30-29.9a12,12,0,0,0,0-17L40.5,138.8a3.9,3.9,0,0,1,0-5.6l12.7-12.7a3.9,3.9,0,0,1,5.6,0l76.7,76.7A3.6,3.6,0,0,1,136.7,200Zm75.1-98.1-87.7,72.5L105.7,156l57.1-57.2a4,4,0,1,0-5.6-5.6L100,150.3,81.6,131.9l72.5-87.7L212,44Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M221.7,34.3A8.1,8.1,0,0,0,216,32h0l-63.8.2a8.1,8.1,0,0,0-6.2,2.9L75.6,120.3,67.3,112a16.1,16.1,0,0,0-22.6,0L32,124.7a15.9,15.9,0,0,0,0,22.6l20.9,20.9-30,29.9a16.2,16.2,0,0,0,0,22.7l12.3,12.3a16.3,16.3,0,0,0,22.7,0l29.9-30L108.7,224a16.1,16.1,0,0,0,22.6,0L144,211.3a15.9,15.9,0,0,0,0-22.6l-8.3-8.3L220.9,110a8.1,8.1,0,0,0,2.9-6.2L224,40A8.1,8.1,0,0,0,221.7,34.3ZM120,212.7,99.1,191.8a15.9,15.9,0,0,0-22.6,0l-30,29.9L34.3,209.5l29.9-30a16.1,16.1,0,0,0,0-22.6L43.3,136,56,123.3l14.5,14.5h0l23.8,23.9h0L132.7,200ZM207.8,100l-83.5,69-13-13,54.4-54.3a8.1,8.1,0,0,0-11.4-11.4L100,144.7l-13-13,69-83.5,52-.2Z" />
        </>
      )}
    </svg>
  );
}

export function PhSyringe(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M240.5,63.5l-48-48a12,12,0,0,0-17,17L191,48,168,71,136.5,39.5a12,12,0,0,0-17,17L123,60,41.9,141.2A19.7,19.7,0,0,0,36,155.3V203L15.5,223.5a12,12,0,0,0,0,17,12.1,12.1,0,0,0,17,0L53,220h47.7a19.7,19.7,0,0,0,14.1-5.9L196,133l3.5,3.5a12.1,12.1,0,0,0,17,0,12,12,0,0,0,0-17L185,88l23-23,15.5,15.5a12.1,12.1,0,0,0,17,0A12,12,0,0,0,240.5,63.5ZM99,196H60V157l14-14,17.5,17.5a12.1,12.1,0,0,0,17,0,12,12,0,0,0,0-17L91,126l11-11,17.5,17.5a12.1,12.1,0,0,0,17,0,12,12,0,0,0,0-17L119,98l21-21,39,39Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M196,116l-89.7,89.7a7.9,7.9,0,0,1-5.6,2.3H48V155.3a7.9,7.9,0,0,1,2.3-5.6L140,60Z"
            opacity="0.2"
          />
          <path d="M237.7,66.3l-48-48a8.1,8.1,0,0,0-11.4,11.4L196.7,48,168,76.7,133.7,42.3a8.1,8.1,0,0,0-11.4,11.4l6.4,6.3-84,84A15.9,15.9,0,0,0,40,155.3v49.4L18.3,226.3a8.1,8.1,0,0,0,0,11.4,8.2,8.2,0,0,0,11.4,0L51.3,216h49.4a15.9,15.9,0,0,0,11.3-4.7l84-84,6.3,6.4a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4L179.3,88,208,59.3l18.3,18.4a8.2,8.2,0,0,0,11.4,0A8.1,8.1,0,0,0,237.7,66.3ZM100.7,200H56V155.3l18-18,20.3,20.4a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4L85.3,126,102,109.3l20.3,20.4a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4L113.3,98,140,71.3,184.7,116Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M237.7,77.7a8.2,8.2,0,0,1-11.4,0L208,59.3,179.3,88l34.4,34.3a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0l-6.3-6.4-84,84a16.1,16.1,0,0,1-11.3,4.7H51.3L29.7,237.7a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L40,204.7V155.3A16.1,16.1,0,0,1,44.7,144l11.2-11.2a4,4,0,0,1,5.6,0l28.8,28.9a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4L72.8,121.5a4,4,0,0,1,0-5.6L91.9,96.8a4,4,0,0,1,5.6,0l28.8,28.9a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4L108.8,85.5a4,4,0,0,1,0-5.6L128.7,60l-6.1-6.1a8.3,8.3,0,0,1-.4-11.4,8.1,8.1,0,0,1,11.5-.2L168,76.7,196.7,48,178.6,29.9a8.3,8.3,0,0,1-.4-11.4,8.1,8.1,0,0,1,11.5-.2l48,48A8.1,8.1,0,0,1,237.7,77.7Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M236.2,67.8l-48-48a5.9,5.9,0,0,0-8.4,8.4L199.5,48,168,79.5,132.2,43.8a5.9,5.9,0,0,0-8.4,8.4l7.7,7.8L46.1,145.4a14.3,14.3,0,0,0-4.1,9.9v50.2L19.8,227.8a5.9,5.9,0,1,0,8.4,8.4L50.5,214h50.2a14.3,14.3,0,0,0,9.9-4.1L196,124.5l7.8,7.7a5.9,5.9,0,1,0,8.4-8.4L176.5,88,208,56.5l19.8,19.7a5.9,5.9,0,1,0,8.4-8.4ZM102.1,201.4a2,2,0,0,1-1.4.6H54V155.3a2,2,0,0,1,.6-1.4L74,134.5l21.8,21.7a5.9,5.9,0,0,0,8.4-8.4L82.5,126,102,106.5l21.8,21.7a5.9,5.9,0,1,0,8.4-8.4L110.5,98,140,68.5,187.5,116Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M234.8,69.2l-48-48a4,4,0,0,0-5.6,5.6L202.3,48,168,82.3,130.8,45.2a4,4,0,1,0-5.6,5.6l9.1,9.2L47.5,146.8a11.9,11.9,0,0,0-3.5,8.5v51L21.2,229.2a3.9,3.9,0,0,0,0,5.6,3.9,3.9,0,0,0,5.6,0L49.7,212h51a11.9,11.9,0,0,0,8.5-3.5L196,121.7l9.2,9.1a3.9,3.9,0,0,0,5.6,0,3.9,3.9,0,0,0,0-5.6L173.7,88,208,53.7l21.2,21.1a3.9,3.9,0,0,0,5.6,0A3.9,3.9,0,0,0,234.8,69.2ZM103.5,202.8a3.6,3.6,0,0,1-2.8,1.2H52V155.3a3.6,3.6,0,0,1,1.2-2.8L74,131.7l23.2,23.1a3.9,3.9,0,0,0,5.6,0,3.9,3.9,0,0,0,0-5.6L79.7,126,102,103.7l23.2,23.1a3.9,3.9,0,0,0,5.6,0,3.9,3.9,0,0,0,0-5.6L107.7,98,140,65.7l25.2,25.1h0L190.3,116Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M237.7,66.3l-48-48a8.1,8.1,0,0,0-11.4,11.4L196.7,48,168,76.7,133.7,42.3a8.1,8.1,0,0,0-11.4,11.4l6.4,6.3-84,84A15.9,15.9,0,0,0,40,155.3v49.4L18.3,226.3a8.1,8.1,0,0,0,0,11.4,8.2,8.2,0,0,0,11.4,0L51.3,216h49.4a15.9,15.9,0,0,0,11.3-4.7l84-84,6.3,6.4a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4L179.3,88,208,59.3l18.3,18.4a8.2,8.2,0,0,0,11.4,0A8.1,8.1,0,0,0,237.7,66.3ZM100.7,200H56V155.3l18-18,20.3,20.4a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4L85.3,126,102,109.3l20.3,20.4a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4L113.3,98,140,71.3,184.7,116Z" />
        </>
      )}
    </svg>
  );
}

export function PhTShirt(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M244.5,58.2,198.3,29.8h-.2l-.7-.4-.4-.2-.7-.3-.4-.2h-.6l-.6-.2H160a12,12,0,0,0-12,12,20,20,0,0,1-40,0A12,12,0,0,0,96,28H61.3l-.6.2h-.5l-.6.2-.6.3-.4.2-.7.4h-.2L11.5,58.2A19.8,19.8,0,0,0,3.6,82.9l15.3,36.8A19.8,19.8,0,0,0,37.3,132H52v76a20.1,20.1,0,0,0,20,20H184a20.1,20.1,0,0,0,20-20V132h14.7a19.8,19.8,0,0,0,18.4-12.3l15.3-36.8A19.8,19.8,0,0,0,244.5,58.2ZM40,108,27,76.8,52,61.5V108Zm140,96H76V52h9.7a44,44,0,0,0,84.6,0H180Zm36-96H204V61.5l25,15.3Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M244.6,78.8l-18.4,36.8a7.9,7.9,0,0,1-7.1,4.4H192V40l49.4,28.2A8.1,8.1,0,0,1,244.6,78.8Zm-233.2,0,18.4,36.8a7.9,7.9,0,0,0,7.1,4.4H64V40L14.6,68.2A8.1,8.1,0,0,0,11.4,78.8Z"
            opacity="0.2"
          />
          <path d="M245.4,61.3,196,33.1h-.1l-.5-.2h-.2l-.5-.2H160a8,8,0,0,0-8,8,24,24,0,0,1-48,0,8,8,0,0,0-8-8H61.3l-.5.2h-.2l-.5.2H60L10.6,61.3a15.9,15.9,0,0,0-6.4,21l18.4,36.9A16,16,0,0,0,36.9,128H56v80a16,16,0,0,0,16,16H184a16,16,0,0,0,16-16V128h19.1a16,16,0,0,0,14.3-8.8l18.4-36.9A15.9,15.9,0,0,0,245.4,61.3ZM36.9,112,18.5,75.2,56,53.8V112ZM184,208H72V48H88.8a40,40,0,0,0,78.4,0H184Zm35.1-96H200V53.8l37.5,21.4Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M245.4,61.3,196,33.1h0a8,8,0,0,0-4-1.1H160a8,8,0,0,0-8,8,24,24,0,0,1-48,0,8,8,0,0,0-8-8H64a8,8,0,0,0-4,1.1h0L10.6,61.3a15.9,15.9,0,0,0-6.4,21l18.4,36.9A16,16,0,0,0,36.9,128H56v80a16,16,0,0,0,16,16H184a16,16,0,0,0,16-16V128h19.1a16,16,0,0,0,14.3-8.8l18.4-36.9A15.9,15.9,0,0,0,245.4,61.3ZM36.9,112,18.5,75.2,56,53.8V112Zm182.2,0H200V53.8l37.5,21.4Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M244.4,63,195,34.8h-.1l-.3-.2H194c0-.1-.1-.1-.2-.1H160a6,6,0,0,0-6,6,26,26,0,0,1-52,0,6,6,0,0,0-6-6H62.2c-.1,0-.2,0-.2.1h-.6l-.3.2H61L11.6,63A14.1,14.1,0,0,0,6,81.5l18.4,36.8A14,14,0,0,0,36.9,126H58v82a14,14,0,0,0,14,14H184a14,14,0,0,0,14-14V126h21.1a14,14,0,0,0,12.5-7.7L250,81.5A14.1,14.1,0,0,0,244.4,63ZM36.9,114a1.8,1.8,0,0,1-1.7-1.1L16.8,76.1a1.9,1.9,0,0,1,.7-2.6L58,50.3V114ZM186,208a2,2,0,0,1-2,2H72a2,2,0,0,1-2-2V46H90.5a38,38,0,0,0,75,0H186ZM239.2,76.1l-18.4,36.8a1.8,1.8,0,0,1-1.7,1.1H198V50.3l40.5,23.2A1.9,1.9,0,0,1,239.2,76.1Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M243.4,64.8,194,36.5h-.6l-.4-.2H160a4,4,0,0,0-4,4,28,28,0,0,1-56,0,4,4,0,0,0-4-4H63l-.4.2H62L12.6,64.8A12.1,12.1,0,0,0,7.8,80.6l18.4,36.8A12,12,0,0,0,36.9,124H60v84a12,12,0,0,0,12,12H184a12,12,0,0,0,12-12V124h23.1a12,12,0,0,0,10.7-6.6l18.4-36.8A12.1,12.1,0,0,0,243.4,64.8ZM36.9,116a3.7,3.7,0,0,1-3.5-2.2L15,77a4,4,0,0,1,1.6-5.3L60,46.9V116ZM188,208a4,4,0,0,1-4,4H72a4,4,0,0,1-4-4V44H92.2a36,36,0,0,0,71.6,0H188ZM241,77l-18.4,36.8a3.7,3.7,0,0,1-3.5,2.2H196V46.9l43.4,24.8A4,4,0,0,1,241,77Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M245.4,61.3,196,33.1h-.1l-.5-.2h-.2l-.5-.2H160a8,8,0,0,0-8,8,24,24,0,0,1-48,0,8,8,0,0,0-8-8H61.3l-.5.2h-.2l-.5.2H60L10.6,61.3a15.9,15.9,0,0,0-6.4,21l18.4,36.9A16,16,0,0,0,36.9,128H56v80a16,16,0,0,0,16,16H184a16,16,0,0,0,16-16V128h19.1a16,16,0,0,0,14.3-8.8l18.4-36.9A15.9,15.9,0,0,0,245.4,61.3ZM36.9,112,18.5,75.2,56,53.8V112ZM184,208H72V48H88.8a40,40,0,0,0,78.4,0H184Zm35.1-96H200V53.8l37.5,21.4Z" />
        </>
      )}
    </svg>
  );
}

export function PhTable(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M224,44H32A12,12,0,0,0,20,56V192a20.1,20.1,0,0,0,20,20H216a20,20,0,0,0,20-20V56A12,12,0,0,0,224,44ZM44,116H76v24H44Zm56,0H212v24H100ZM212,68V92H44V68ZM44,164H76v24H44Zm56,24V164H212v24Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect x="32" y="104" width="56" height="96" />
          </g>
          <path d="M224,48H32a8,8,0,0,0-8,8V192a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A8,8,0,0,0,224,48ZM40,112H80v32H40Zm56,0H216v32H96ZM216,64V96H40V64ZM40,160H80v32H40Zm176,32H96V160H216v32Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M224,48H32a8,8,0,0,0-8,8V192a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A8,8,0,0,0,224,48ZM40,112H80v32H40Zm56,0H216v32H96ZM40,160H80v32H40Zm176,32H96V160H216v32Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M224,50H32a6,6,0,0,0-6,6V192a14,14,0,0,0,14,14H216a14,14,0,0,0,14-14V56A6,6,0,0,0,224,50ZM38,110H82v36H38Zm56,0H218v36H94ZM218,62V98H38V62ZM38,192V158H82v36H40A2,2,0,0,1,38,192Zm178,2H94V158H218v34A2,2,0,0,1,216,194Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M224,52H32a4,4,0,0,0-4,4V192a12,12,0,0,0,12,12H216a12,12,0,0,0,12-12V56A4,4,0,0,0,224,52ZM36,108H84v40H36Zm56,0H220v40H92ZM220,60v40H36V60ZM36,192V156H84v40H40A4,4,0,0,1,36,192Zm180,4H92V156H220v36A4,4,0,0,1,216,196Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M224,48H32a8,8,0,0,0-8,8V192a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A8,8,0,0,0,224,48ZM40,112H80v32H40Zm56,0H216v32H96ZM216,64V96H40V64ZM40,160H80v32H40Zm176,32H96V160H216v32Z" />
        </>
      )}
    </svg>
  );
}

export function PhTabs(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M255.5,164.6h-.1L233.2,90.3A19.9,19.9,0,0,0,214,76H204a12,12,0,0,0,0,24h7.1l16.8,56h-31L177.2,90.3A19.9,19.9,0,0,0,158,76H148a12,12,0,0,0,0,24h7.1l16.8,56h-31L121.2,90.3A19.9,19.9,0,0,0,102,76H42A19.9,19.9,0,0,0,22.8,90.3L.6,164.4H.5A12,12,0,0,0,12,180H244a12,12,0,0,0,11.5-15.4ZM44.9,100H99.1l16.8,56H28.1Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M152,168H8L30.3,93.7A8,8,0,0,1,38,88h84a8,8,0,0,1,7.7,5.7Z"
            opacity="0.2"
          />
          <path d="M255.7,165.7h-.1L233.4,91.4A16,16,0,0,0,218,80H208a8,8,0,0,0,0,16h10l19.2,64H206L185.4,91.4A16,16,0,0,0,170,80H160a8,8,0,0,0,0,16h10l19.2,64H158L137.4,91.4A16,16,0,0,0,122,80H38A16,16,0,0,0,22.6,91.4L.4,165.6H.3A8.5,8.5,0,0,0,0,168a8,8,0,0,0,8,8H248a8,8,0,0,0,8-8A8.5,8.5,0,0,0,255.7,165.7ZM38,96h84l19.2,64H18.8Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M256,168a8,8,0,0,1-8,8H8a8,8,0,0,1-8-8,8.5,8.5,0,0,1,.3-2.3H.4L22.6,91.4A16,16,0,0,1,38,80h84a16,16,0,0,1,15.4,11.4L158,160h31.2L170,96H160a8,8,0,0,1,0-16h10a16,16,0,0,1,15.4,11.4L206,160h31.2L218,96H208a8,8,0,0,1,0-16h10a16,16,0,0,1,15.4,11.4l22.2,74.2h.1A8.5,8.5,0,0,1,256,168Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M253.7,166.3h0L231.5,92A14,14,0,0,0,218,82H208a6,6,0,0,0,0,12h10a2.2,2.2,0,0,1,2,1.4L239.9,162H204.5l-21-70A14,14,0,0,0,170,82H160a6,6,0,0,0,0,12h10a2.2,2.2,0,0,1,2,1.4L191.9,162H156.5l-21-70A14,14,0,0,0,122,82H38A14,14,0,0,0,24.5,92L2.3,166.2h0A4.7,4.7,0,0,0,2,168a6,6,0,0,0,6,6H248a6,6,0,0,0,6-6A4.7,4.7,0,0,0,253.7,166.3ZM36,95.4A2.2,2.2,0,0,1,38,94h84a2.2,2.2,0,0,1,2,1.4L143.9,162H16.1Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M251.8,166.9h0L229.5,92.6A11.8,11.8,0,0,0,218,84H208a4,4,0,0,0,0,8h10a4,4,0,0,1,3.9,2.9L242.6,164H203L181.5,92.6A11.8,11.8,0,0,0,170,84H160a4,4,0,0,0,0,8h10a4,4,0,0,1,3.9,2.9L194.6,164H155L133.5,92.6A11.8,11.8,0,0,0,122,84H38a11.8,11.8,0,0,0-11.5,8.6L4.2,166.9h0A3.6,3.6,0,0,0,4,168a4,4,0,0,0,4,4H248a4,4,0,0,0,4-4A3.6,3.6,0,0,0,251.8,166.9ZM34.1,94.8A4,4,0,0,1,38,92h84a4,4,0,0,1,3.9,2.9L146.6,164H13.4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M255.7,165.7h-.1L233.4,91.4A16,16,0,0,0,218,80H208a8,8,0,0,0,0,16h10l19.2,64H206L185.4,91.4A16,16,0,0,0,170,80H160a8,8,0,0,0,0,16h10l19.2,64H158L137.4,91.4A16,16,0,0,0,122,80H38A16,16,0,0,0,22.6,91.4L.4,165.6H.3A8.5,8.5,0,0,0,0,168a8,8,0,0,0,8,8H248a8,8,0,0,0,8-8A8.5,8.5,0,0,0,255.7,165.7ZM38,96h84l19.2,64H18.8Z" />
        </>
      )}
    </svg>
  );
}

export function PhTagChevron(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M239,116.9l-42.6-64A20.1,20.1,0,0,0,179.7,44H24A12,12,0,0,0,14,62.6L57.6,128,14,193.3a12.2,12.2,0,0,0-.6,12.4A12,12,0,0,0,24,212H179.7a20.1,20.1,0,0,0,16.7-8.9h0l42.6-64A19.9,19.9,0,0,0,239,116.9ZM177.6,188H46.4L79,139.1a19.9,19.9,0,0,0,0-22.2L46.4,68H177.6l40,60Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M229,132.4l-42.6,64a7.9,7.9,0,0,1-6.7,3.6H24l45-67.6a7.8,7.8,0,0,0,0-8.8L24,56H179.7a7.9,7.9,0,0,1,6.7,3.6l42.6,64A7.8,7.8,0,0,1,229,132.4Z"
            opacity="0.2"
          />
          <path d="M235.7,119.1,193,55.1A15.9,15.9,0,0,0,179.7,48H24a7.8,7.8,0,0,0-7,4.2,8,8,0,0,0,.3,8.2L62.4,128,17.3,195.6a8,8,0,0,0-.3,8.2,7.8,7.8,0,0,0,7,4.2H179.7a15.9,15.9,0,0,0,13.3-7.1h0l42.7-64A16,16,0,0,0,235.7,119.1Zm-56,72.9H39l36.7-55.1a16,16,0,0,0,0-17.8L39,64H179.7l42.7,64Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M235.7,136.9l-42.7,64a15.9,15.9,0,0,1-13.3,7.1H24a7.8,7.8,0,0,1-7-4.2,8,8,0,0,1,.3-8.2L62.4,128,17.3,60.4a8,8,0,0,1-.3-8.2A7.8,7.8,0,0,1,24,48H179.7A15.9,15.9,0,0,1,193,55.1l42.7,64A16,16,0,0,1,235.7,136.9Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M234,120.2l-42.6-64A14,14,0,0,0,179.7,50H24a6,6,0,0,0-5,9.3l45.1,67.6a2.2,2.2,0,0,1,0,2.2L19,196.7a6,6,0,0,0,5,9.3H179.7a14,14,0,0,0,11.7-6.2l42.6-64A13.9,13.9,0,0,0,234,120.2Zm-9.9,8.9-42.7,64a2,2,0,0,1-1.7.9H35.2L74,135.8a13.9,13.9,0,0,0,0-15.6L35.2,62H179.7a2,2,0,0,1,1.7.9l42.7,64A2.2,2.2,0,0,1,224.1,129.1Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M232.4,121.3l-42.7-64a12.1,12.1,0,0,0-10-5.3H24a3.9,3.9,0,0,0-3.5,2.1,4,4,0,0,0,.2,4.1l45,67.6a3.9,3.9,0,0,1,0,4.4l-45,67.6a4,4,0,0,0-.2,4.1A3.9,3.9,0,0,0,24,204H179.7a11.9,11.9,0,0,0,10-5.4l42.7-64A11.9,11.9,0,0,0,232.4,121.3Zm-6.7,8.9-42.6,64a4.2,4.2,0,0,1-3.4,1.8H31.5l40.9-61.3a12.1,12.1,0,0,0,0-13.4L31.5,60H179.7a4.2,4.2,0,0,1,3.4,1.8l42.6,64A3.9,3.9,0,0,1,225.7,130.2Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M235.7,119.1,193,55.1A15.9,15.9,0,0,0,179.7,48H24a7.8,7.8,0,0,0-7,4.2,8,8,0,0,0,.3,8.2L62.4,128,17.3,195.6a8,8,0,0,0-.3,8.2,7.8,7.8,0,0,0,7,4.2H179.7a15.9,15.9,0,0,0,13.3-7.1h0l42.7-64A16,16,0,0,0,235.7,119.1Zm-56,72.9H39l36.7-55.1a16,16,0,0,0,0-17.8L39,64H179.7l42.7,64Z" />
        </>
      )}
    </svg>
  );
}

export function PhTagSimple(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M187.7,212H40a20.1,20.1,0,0,1-20-20V64A20.1,20.1,0,0,1,40,44H187.7a20.1,20.1,0,0,1,16.7,8.9L250,121.3a11.9,11.9,0,0,1,0,13.3l-45.6,68.5A20.1,20.1,0,0,1,187.7,212ZM44,188H185.6l40-60-40-60H44Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M194.4,196.4,240,128,194.4,59.6a7.9,7.9,0,0,0-6.7-3.6H40a8,8,0,0,0-8,8V192a8,8,0,0,0,8,8H187.7A7.9,7.9,0,0,0,194.4,196.4Z" />
          </g>
          <path d="M187.7,208H40a16,16,0,0,1-16-16V64A16,16,0,0,1,40,48H187.7A15.9,15.9,0,0,1,201,55.1l45.7,68.5a7.8,7.8,0,0,1,0,8.8L201,200.9h0A15.9,15.9,0,0,1,187.7,208Zm0-16h0l42.7-64L187.7,64H40V192Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M246.7,132.4,201,200.9h0a15.9,15.9,0,0,1-13.3,7.1H40a16,16,0,0,1-16-16V64A16,16,0,0,1,40,48H187.7A15.9,15.9,0,0,1,201,55.1l45.7,68.5A7.8,7.8,0,0,1,246.7,132.4Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M187.7,206H40a14,14,0,0,1-14-14V64A14,14,0,0,1,40,50H187.7a14,14,0,0,1,11.7,6.2L245,124.7a6.1,6.1,0,0,1,0,6.6l-45.6,68.5A14,14,0,0,1,187.7,206ZM40,62a2,2,0,0,0-2,2V192a2,2,0,0,0,2,2H187.7a2,2,0,0,0,1.7-.9h0L232.8,128,189.4,62.9a2,2,0,0,0-1.7-.9Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M187.7,204H40a12,12,0,0,1-12-12V64A12,12,0,0,1,40,52H187.7a12.1,12.1,0,0,1,10,5.3l45.6,68.5a3.9,3.9,0,0,1,0,4.4l-45.6,68.4A11.9,11.9,0,0,1,187.7,204ZM40,60a4,4,0,0,0-4,4V192a4,4,0,0,0,4,4H187.7a4.2,4.2,0,0,0,3.4-1.8h0L235.2,128,191.1,61.8a4.2,4.2,0,0,0-3.4-1.8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M187.7,208H40a16,16,0,0,1-16-16V64A16,16,0,0,1,40,48H187.7A15.9,15.9,0,0,1,201,55.1l45.7,68.5a7.8,7.8,0,0,1,0,8.8L201,200.9h0A15.9,15.9,0,0,1,187.7,208Zm0-16h0l42.7-64L187.7,64H40V192Z" />
        </>
      )}
    </svg>
  );
}

export function PhTag(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M242.8,124,138.4,19.6a19.9,19.9,0,0,0-18.1-5.5L39.7,30.2a12.2,12.2,0,0,0-9.5,9.5L14.1,120.3a19.9,19.9,0,0,0,5.5,18.1L124,242.8a19.9,19.9,0,0,0,28.3,0l90.5-90.5a19.9,19.9,0,0,0,0-28.3ZM138.2,223,38.1,122.9,52.2,52.2l70.7-14.1L223,138.2ZM100,84A16,16,0,1,1,84,68,16,16,0,0,1,100,84Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M122.7,25.9,42,42,25.9,122.7a8,8,0,0,0,2.2,7.2L132.5,234.3a7.9,7.9,0,0,0,11.3,0l90.5-90.5a7.9,7.9,0,0,0,0-11.3L129.9,28.1A8,8,0,0,0,122.7,25.9Z" />
          </g>
          <path d="M240,126.9,135.6,22.4A16.2,16.2,0,0,0,121.1,18L40.4,34.2a7.9,7.9,0,0,0-6.2,6.2L18,121.1a15.9,15.9,0,0,0,4.4,14.4L126.9,240a15.9,15.9,0,0,0,22.6,0L240,149.5a15.9,15.9,0,0,0,0-22.6ZM138.2,228.7,33.7,124.2,48.8,48.8l75.4-15.1h0L228.7,138.2ZM96,84A12,12,0,1,1,84,72,12,12,0,0,1,96,84Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M240,126.9,135.6,22.4A16.2,16.2,0,0,0,121.1,18L40.4,34.2a7.9,7.9,0,0,0-6.2,6.2L18,121.1a15.9,15.9,0,0,0,4.4,14.4L126.9,240a15.9,15.9,0,0,0,22.6,0L240,149.5a15.9,15.9,0,0,0,0-22.6ZM84,96A12,12,0,1,1,96,84,12,12,0,0,1,84,96Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M238.6,128.3,134.1,23.8A14,14,0,0,0,121.5,20L40.8,36.1a6.3,6.3,0,0,0-4.7,4.7L20,121.5a14,14,0,0,0,3.8,12.6L128.3,238.6a13.9,13.9,0,0,0,9.9,4.1,14.3,14.3,0,0,0,9.9-4.1l90.5-90.5a14.3,14.3,0,0,0,4.1-9.9A13.9,13.9,0,0,0,238.6,128.3Zm-8.5,11.3-90.5,90.5a1.9,1.9,0,0,1-2.8,0L32.3,125.6a1.7,1.7,0,0,1-.5-1.8L47.1,47.1l76.7-15.3a1.9,1.9,0,0,1,1.9.5L230.1,136.8a1.9,1.9,0,0,1,0,2.8ZM94,84A10,10,0,1,1,84,74,10,10,0,0,1,94,84Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M237.2,129.7,132.7,25.2A11.9,11.9,0,0,0,121.9,22L41.2,38.1a3.8,3.8,0,0,0-3.1,3.1L22,121.9a11.9,11.9,0,0,0,3.2,10.8L129.7,237.2a12.1,12.1,0,0,0,17,0l90.5-90.5a12.1,12.1,0,0,0,0-17ZM231.5,141,141,231.5a3.9,3.9,0,0,1-5.6,0L30.9,127.1a4.2,4.2,0,0,1-1.1-3.7l15.6-78,78.1-15.6a3.8,3.8,0,0,1,3.6,1.1L231.5,135.3a4,4,0,0,1,1.2,2.9A3.9,3.9,0,0,1,231.5,141ZM92,84a8,8,0,1,1-8-8A8,8,0,0,1,92,84Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M240,126.9,135.6,22.4A16.2,16.2,0,0,0,121.1,18L40.4,34.2a7.9,7.9,0,0,0-6.2,6.2L18,121.1a15.9,15.9,0,0,0,4.4,14.4L126.9,240a15.9,15.9,0,0,0,22.6,0L240,149.5a15.9,15.9,0,0,0,0-22.6ZM138.2,228.7,33.7,124.2,48.8,48.8l75.4-15.1h0L228.7,138.2ZM96,84A12,12,0,1,1,84,72,12,12,0,0,1,96,84Z" />
        </>
      )}
    </svg>
  );
}

export function PhTarget(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M229.3,90.4A107.9,107.9,0,0,1,128,235.9,108,108,0,1,1,195.4,43.6l20.1-20.1a12,12,0,0,1,17,17l-62.1,62h0l-33.9,34a12.1,12.1,0,0,1-17,0,12,12,0,0,1,0-17l24-24a36,36,0,1,0,10,58,36.4,36.4,0,0,0,9.8-18.4,12,12,0,0,1,23.5,4.8A60,60,0,0,1,85.6,170.4,60,60,0,0,1,128,68a60.1,60.1,0,0,1,33.1,9.9l17.2-17.2a83.9,83.9,0,1,0,28.5,38,12,12,0,0,1,7-15.4A12.1,12.1,0,0,1,229.3,90.4Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="128" cy="128" r="48" />
          </g>
          <path d="M211.2,79.4a8,8,0,0,0-3.8,10.7,88,88,0,1,1-23.1-29.7L161.5,83.2a55.9,55.9,0,1,0,22.4,41.6,7.9,7.9,0,0,0-8.4-7.5,8,8,0,0,0-7.6,8.4,39.8,39.8,0,1,1-17.8-31.1l-27.8,27.7a8.1,8.1,0,0,0,0,11.4,8.2,8.2,0,0,0,11.4,0l33.9-34h0l62.1-62a8.1,8.1,0,0,0-11.4-11.4L195.7,49A104,104,0,0,0,54.5,54.5a103.8,103.8,0,0,0,0,147,103.8,103.8,0,0,0,147,0A104,104,0,0,0,221.9,83.2,8,8,0,0,0,211.2,79.4Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M221.9,83.2a104,104,0,0,1-20.4,118.3,103.8,103.8,0,0,1-147,0,103.8,103.8,0,0,1,0-147A104,104,0,0,1,195.7,49l22.6-22.7a8.1,8.1,0,0,1,11.4,11.4l-62.1,62h0l-37.7,37.7-24,24a40,40,0,0,0,62-35.7,8,8,0,0,1,7.6-8.4,7.9,7.9,0,0,1,8.4,7.5,56,56,0,0,1-95.5,42.8h0a56,56,0,0,1,73.1-84.4l22.8-22.8a87.9,87.9,0,1,0,23.1,29.7,8,8,0,0,1,14.5-6.9Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M220.1,84A102,102,0,0,1,55.9,200.1a101.9,101.9,0,0,1,0-144.2c38.3-38.4,99.8-39.7,139.9-4.1l24-24a5.9,5.9,0,1,1,8.4,8.4l-62,62.1h0l-34,33.9a5.9,5.9,0,0,1-8.4-8.4l29.3-29.4a42,42,0,1,0,16.8,31.2,6,6,0,0,1,5.7-6.3,5.9,5.9,0,0,1,6.3,5.6,54,54,0,1,1-20.2-39.1l25.5-25.5a89.8,89.8,0,1,0,22,28.9A6,6,0,0,1,220.1,84Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M218.3,84.9a100.3,100.3,0,1,1-22.5-30.4l25.4-25.3a4,4,0,0,1,5.6,5.6l-62,62.1h0l-34,33.9a3.9,3.9,0,0,1-5.6,0,3.9,3.9,0,0,1,0-5.6l31-31a44.1,44.1,0,1,0,15.7,31.3,4,4,0,0,1,3.8-4.2,3.9,3.9,0,0,1,4.2,3.7A52,52,0,1,1,128,76a51.5,51.5,0,0,1,33.8,12.5l28.4-28.3A92.1,92.1,0,1,0,211,88.3a4,4,0,0,1,7.3-3.4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M221.9,83.2a104,104,0,0,1-20.4,118.3,103.8,103.8,0,0,1-147,0,103.8,103.8,0,0,1,0-147A104,104,0,0,1,195.7,49l22.6-22.7a8.1,8.1,0,0,1,11.4,11.4l-62.1,62h0l-33.9,34a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4l27.8-27.7a40.2,40.2,0,1,0,17.8,31.1,8,8,0,0,1,7.6-8.4,7.9,7.9,0,0,1,8.4,7.5,56,56,0,1,1-22.4-41.6l22.8-22.8a87.9,87.9,0,1,0,23.1,29.7,8,8,0,0,1,14.5-6.9Z" />
        </>
      )}
    </svg>
  );
}

export function PhTaxi(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M244,112h-9.4L204.5,65.2A19.9,19.9,0,0,0,187.6,56H168.1L157.2,28.6A19.9,19.9,0,0,0,138.6,16H117.4A19.9,19.9,0,0,0,98.8,28.6L87.9,56H68.4a19.9,19.9,0,0,0-16.9,9.2L21.4,112H12a12,12,0,0,0,0,24h4v68a20.1,20.1,0,0,0,20,20H60a20.1,20.1,0,0,0,20-20V184h96v20a20.1,20.1,0,0,0,20,20h24a20.1,20.1,0,0,0,20-20V136h4a12,12,0,0,0,0-24ZM120.1,40h15.8l6.4,16H113.7ZM70.6,80H185.4L206,112H50ZM56,200H40V184H56Zm144,0V184h16v16Zm16-40H40V136H216Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M184,184h40v24a8,8,0,0,1-8,8H192a8,8,0,0,1-8-8ZM32,208a8,8,0,0,0,8,8H64a8,8,0,0,0,8-8V184H32ZM194.3,68a7.9,7.9,0,0,0-6.9-4H68.6a7.9,7.9,0,0,0-6.9,4L32,120H224Z"
            opacity="0.2"
          />
          <path d="M240,112H228.6L201.2,64.1A15.9,15.9,0,0,0,187.4,56h-22l-12-29.9A15.7,15.7,0,0,0,138.6,16H117.4a15.7,15.7,0,0,0-14.8,10.1L90.6,56h-22a15.9,15.9,0,0,0-13.8,8.1L27.4,112H16a8,8,0,0,0,0,16h8v80a16,16,0,0,0,16,16H64a16,16,0,0,0,16-16V192h96v16a16,16,0,0,0,16,16h24a16,16,0,0,0,16-16V128h8a8,8,0,0,0,0-16ZM117.4,32h21.2l9.6,24H107.8ZM68.6,72H187.4l22.8,40H45.8ZM64,208H40V192H64Zm128,0V192h24v16Zm24-32H40V128H216ZM56,152a8,8,0,0,1,8-8H80a8,8,0,0,1,0,16H64A8,8,0,0,1,56,152Zm112,0a8,8,0,0,1,8-8h16a8,8,0,0,1,0,16H176A8,8,0,0,1,168,152Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M240,112H228.6L201.2,64.1A15.9,15.9,0,0,0,187.4,56h-22l-12-29.9A15.7,15.7,0,0,0,138.6,16H117.4a15.7,15.7,0,0,0-14.8,10.1L90.6,56h-22a15.9,15.9,0,0,0-13.8,8.1L27.4,112H16a8,8,0,0,0,0,16h8v80a16,16,0,0,0,16,16H64a16,16,0,0,0,16-16V192h96v16a16,16,0,0,0,16,16h24a16,16,0,0,0,16-16V128h8a8,8,0,0,0,0-16ZM117.4,32h21.2l9.6,24H107.8ZM80,160H64a8,8,0,0,1,0-16H80a8,8,0,0,1,0,16Zm112,0H176a8,8,0,0,1,0-16h16a8,8,0,0,1,0,16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M240,114H227.5l-28-48.9A13.9,13.9,0,0,0,187.4,58H164.1L151.6,26.8a14.1,14.1,0,0,0-13-8.8H117.4a14.1,14.1,0,0,0-13,8.8L91.9,58H68.6a13.9,13.9,0,0,0-12.1,7.1L28.5,114H16a6,6,0,0,0,0,12H26v82a14,14,0,0,0,14,14H64a14,14,0,0,0,14-14V190H178v18a14,14,0,0,0,14,14h24a14,14,0,0,0,14-14V126h10a6,6,0,0,0,0-12ZM115.6,31.3a1.9,1.9,0,0,1,1.8-1.3h21.2a1.9,1.9,0,0,1,1.8,1.3L151.1,58H104.9ZM66.9,71a2.1,2.1,0,0,1,1.7-1H187.4a2.1,2.1,0,0,1,1.7,1l24.6,43H42.3ZM66,208a2,2,0,0,1-2,2H40a2,2,0,0,1-2-2V190H66Zm150,2H192a2,2,0,0,1-2-2V190h28v18A2,2,0,0,1,216,210Zm2-32H38V126H218ZM58,152a6,6,0,0,1,6-6H80a6,6,0,0,1,0,12H64A6,6,0,0,1,58,152Zm112,0a6,6,0,0,1,6-6h16a6,6,0,0,1,0,12H176A6,6,0,0,1,170,152Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M240,116H226.3L197.8,66a12.1,12.1,0,0,0-10.4-6H162.7l-13-32.5A12,12,0,0,0,138.6,20H117.4a12,12,0,0,0-11.1,7.5L93.3,60H68.6a12.1,12.1,0,0,0-10.4,6L29.7,116H16a4,4,0,0,0,0,8H28v84a12,12,0,0,0,12,12H64a12,12,0,0,0,12-12V188H180v20a12,12,0,0,0,12,12h24a12,12,0,0,0,12-12V124h12a4,4,0,0,0,0-8ZM113.7,30.5a4,4,0,0,1,3.7-2.5h21.2a4,4,0,0,1,3.7,2.5L154.1,60H101.9ZM65.2,70a4,4,0,0,1,3.4-2H187.4a4,4,0,0,1,3.4,2l26.3,46H38.9ZM68,208a4,4,0,0,1-4,4H40a4,4,0,0,1-4-4V188H68Zm148,4H192a4,4,0,0,1-4-4V188h32v20A4,4,0,0,1,216,212Zm4-32H36V124H220ZM60,152a4,4,0,0,1,4-4H80a4,4,0,0,1,0,8H64A4,4,0,0,1,60,152Zm112,0a4,4,0,0,1,4-4h16a4,4,0,0,1,0,8H176A4,4,0,0,1,172,152Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M240,112H228.6L201.2,64.1A15.9,15.9,0,0,0,187.4,56h-22l-12-29.9A15.7,15.7,0,0,0,138.6,16H117.4a15.7,15.7,0,0,0-14.8,10.1L90.6,56h-22a15.9,15.9,0,0,0-13.8,8.1L27.4,112H16a8,8,0,0,0,0,16h8v80a16,16,0,0,0,16,16H64a16,16,0,0,0,16-16V192h96v16a16,16,0,0,0,16,16h24a16,16,0,0,0,16-16V128h8a8,8,0,0,0,0-16ZM117.4,32h21.2l9.6,24H107.8ZM68.6,72H187.4l22.8,40H45.8ZM64,208H40V192H64Zm128,0V192h24v16Zm24-32H40V128H216ZM56,152a8,8,0,0,1,8-8H80a8,8,0,0,1,0,16H64A8,8,0,0,1,56,152Zm112,0a8,8,0,0,1,8-8h16a8,8,0,0,1,0,16H176A8,8,0,0,1,168,152Z" />
        </>
      )}
    </svg>
  );
}

export function PhTelegramLogo(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M233.9,28.7a20.2,20.2,0,0,0-20.4-3.5L28.9,97.8a19.7,19.7,0,0,0-12.6,20.3,20,20,0,0,0,16,17.9L76,144.7V200a19.9,19.9,0,0,0,12.3,18.5A20.7,20.7,0,0,0,96,220a19.6,19.6,0,0,0,14.1-5.9l23.3-23.3L170,223a19.7,19.7,0,0,0,13.1,5,18.7,18.7,0,0,0,6.2-1,19.9,19.9,0,0,0,13.4-14.5L240.3,48.3A20,20,0,0,0,233.9,28.7Zm-86,48.1L85.2,122.1l-34.8-7ZM100,190.3V161.4l15.4,13.5Zm80.8,10.3-73.6-64.8,106-76.5Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M88,134.9,224.1,36.6h0a7.8,7.8,0,0,0-6.2-.2L33.3,108.9c-7.4,2.9-6.4,13.7,1.4,15.3Z" />
          </g>
          <g opacity="0.2">
            <path d="M132.9,174.4l-31.2,31.2A8,8,0,0,1,88,200V134.9Z" />
          </g>
          <path d="M231.3,31.7A16.1,16.1,0,0,0,215,29L30.4,101.5a15.8,15.8,0,0,0-10.1,16.3,16,16,0,0,0,12.8,14.3L80,141.4V200a16,16,0,0,0,9.9,14.8A16.6,16.6,0,0,0,96,216a15.8,15.8,0,0,0,11.3-4.7l26-25.9L172.6,220a16,16,0,0,0,10.5,4,14.2,14.2,0,0,0,5-.8,15.9,15.9,0,0,0,10.7-11.6L236.4,47.4A16,16,0,0,0,231.3,31.7ZM86.1,126.3l-49.8-9.9L175.9,61.5ZM96,200V152.6l25.2,22.2Zm87.2,8-82.4-72.5L219.5,49.8Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M231.3,31.7A16.1,16.1,0,0,0,215,29L30.4,101.5a15.8,15.8,0,0,0-10.1,16.3,16,16,0,0,0,12.8,14.3L80,141.4V200a16,16,0,0,0,9.9,14.8A16.6,16.6,0,0,0,96,216a15.8,15.8,0,0,0,11.3-4.7l26-25.9L172.6,220a16,16,0,0,0,10.5,4,14.2,14.2,0,0,0,5-.8,15.9,15.9,0,0,0,10.7-11.6L236.4,47.4A16,16,0,0,0,231.3,31.7ZM183.2,208l-82.4-72.5L219.5,49.8Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M230,33.3a14.1,14.1,0,0,0-14.3-2.5L31.1,103.3a14,14,0,0,0,2.4,26.8L82,139.8V200a14,14,0,0,0,8.6,12.9A14.9,14.9,0,0,0,96,214a13.8,13.8,0,0,0,9.9-4.1l27.3-27.3L174,218.5a13.7,13.7,0,0,0,9.2,3.5,12.9,12.9,0,0,0,4.3-.7,13.8,13.8,0,0,0,9.3-10.2L234.5,47A14,14,0,0,0,230,33.3ZM34.3,116.5a1.8,1.8,0,0,1,1.2-2L190,53.8,86.6,128.5,35.9,118.3A1.8,1.8,0,0,1,34.3,116.5Zm63.1,84.9a1.9,1.9,0,0,1-2.2.4A2,2,0,0,1,94,200V148.1l30.1,26.6Zm87.8,7a2,2,0,0,1-3.3,1.1L97.6,135.3l125-90.2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M228.6,34.8a12,12,0,0,0-12.2-2.1L31.9,105.2a12,12,0,0,0,2,22.9L84,138.2V200a11.8,11.8,0,0,0,7.4,11.1,12.7,12.7,0,0,0,4.7.9,11.8,11.8,0,0,0,8.4-3.5l28.6-28.6L175.3,217a11.6,11.6,0,0,0,7.9,3,11.9,11.9,0,0,0,3.7-.6,12.1,12.1,0,0,0,8-8.7L232.5,46.5A11.8,11.8,0,0,0,228.6,34.8ZM32.3,116.7a3.8,3.8,0,0,1,2.5-4.1L204,46.2,87.1,130.6,35.5,120.3A3.9,3.9,0,0,1,32.3,116.7Zm66.5,86.1a3.9,3.9,0,0,1-4.3.9A4,4,0,0,1,92,200V143.7l35.1,30.9Zm125.9-158L187.1,208.9a3.9,3.9,0,0,1-2.7,2.9,3.7,3.7,0,0,1-3.8-.8L94.4,135.2,224.1,41.5A4.1,4.1,0,0,1,224.7,44.8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M231.3,31.7A16.1,16.1,0,0,0,215,29L30.4,101.5a15.8,15.8,0,0,0-10.1,16.3,16,16,0,0,0,12.8,14.3L80,141.4V200a16,16,0,0,0,9.9,14.8A16.6,16.6,0,0,0,96,216a15.8,15.8,0,0,0,11.3-4.7l26-25.9L172.6,220a16,16,0,0,0,10.5,4,14.2,14.2,0,0,0,5-.8,15.9,15.9,0,0,0,10.7-11.6L236.4,47.4A16,16,0,0,0,231.3,31.7ZM86.1,126.3l-49.8-9.9L175.9,61.5ZM96,200V152.6l25.2,22.2Zm87.2,8-82.4-72.5L219.5,49.8Z" />
        </>
      )}
    </svg>
  );
}

export function PhTelevisionSimple(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216,60H157l27.5-27.5a12,12,0,0,0-17-17L128,55,88.5,15.5a12,12,0,0,0-17,17L99,60H40A20.1,20.1,0,0,0,20,80V200a20.1,20.1,0,0,0,20,20H216a20.1,20.1,0,0,0,20-20V80A20.1,20.1,0,0,0,216,60Zm-4,136H44V84H212Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M224,80V200a8,8,0,0,1-8,8H40a8,8,0,0,1-8-8V80a8,8,0,0,1,8-8H216A8,8,0,0,1,224,80Z"
            opacity="0.2"
          />
          <path d="M216,64H147.3l34.4-34.3a8.1,8.1,0,0,0-11.4-11.4L128,60.7,85.7,18.3A8.1,8.1,0,0,0,74.3,29.7L108.7,64H40A16,16,0,0,0,24,80V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V80A16,16,0,0,0,216,64Zm0,136H40V80H216V200Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M232,80V200a16,16,0,0,1-16,16H40a16,16,0,0,1-16-16V80A16,16,0,0,1,40,64h68.7L74.3,29.7A8.1,8.1,0,0,1,85.7,18.3L128,60.7l42.3-42.4a8.1,8.1,0,0,1,11.4,11.4L147.3,64H216A16,16,0,0,1,232,80Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M216,66H142.5l37.7-37.8a5.9,5.9,0,0,0-8.4-8.4L128,63.5,84.2,19.8a5.9,5.9,0,0,0-8.4,8.4L113.5,66H40A14,14,0,0,0,26,80V200a14,14,0,0,0,14,14H216a14,14,0,0,0,14-14V80A14,14,0,0,0,216,66Zm2,134a2,2,0,0,1-2,2H40a2,2,0,0,1-2-2V80a2,2,0,0,1,2-2H216a2,2,0,0,1,2,2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M216,68H137.7l41.1-41.2a4,4,0,0,0-5.6-5.6L128,66.3,82.8,21.2a4,4,0,0,0-5.6,5.6L118.3,68H40A12,12,0,0,0,28,80V200a12,12,0,0,0,12,12H216a12,12,0,0,0,12-12V80A12,12,0,0,0,216,68Zm4,132a4,4,0,0,1-4,4H40a4,4,0,0,1-4-4V80a4,4,0,0,1,4-4H216a4,4,0,0,1,4,4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,64H147.3l34.4-34.3a8.1,8.1,0,0,0-11.4-11.4L128,60.7,85.7,18.3A8.1,8.1,0,0,0,74.3,29.7L108.7,64H40A16,16,0,0,0,24,80V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V80A16,16,0,0,0,216,64Zm0,136H40V80H216V200Z" />
        </>
      )}
    </svg>
  );
}

export function PhTelevision(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216,60H157l27.5-27.5a12,12,0,0,0-17-17L128,55,88.5,15.5a12,12,0,0,0-17,17L99,60H40A20.1,20.1,0,0,0,20,80V200a20.1,20.1,0,0,0,20,20H216a20.1,20.1,0,0,0,20-20V80A20.1,20.1,0,0,0,216,60ZM44,84h84V196H44ZM212,196H152V84h60Zm-44-80a16,16,0,1,1,16,16A16,16,0,0,1,168,116Zm32,48a16,16,0,1,1-16-16A16,16,0,0,1,200,164Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M152,72V208H40a8,8,0,0,1-8-8V80a8,8,0,0,1,8-8Z"
            opacity="0.2"
          />
          <path d="M216,64H147.3l34.4-34.3a8.1,8.1,0,0,0-11.4-11.4L128,60.7,85.7,18.3A8.1,8.1,0,0,0,74.3,29.7L108.7,64H40A16,16,0,0,0,24,80V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V80A16,16,0,0,0,216,64ZM40,80H144V200H40ZM216,200H160V80h56V200Zm-16-84a12,12,0,1,1-12-12A12,12,0,0,1,200,116Zm0,48a12,12,0,1,1-12-12A12,12,0,0,1,200,164Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,64H147.3l34.4-34.3a8.1,8.1,0,0,0-11.4-11.4L128,60.7,85.7,18.3A8.1,8.1,0,0,0,74.3,29.7L108.7,64H40A16,16,0,0,0,24,80V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V80A16,16,0,0,0,216,64Zm0,136H160V80h56V200Zm-16-36a12,12,0,1,1-12-12A12,12,0,0,1,200,164Zm0-48a12,12,0,1,1-12-12A12,12,0,0,1,200,116Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M216,66H142.5l37.7-37.8a5.9,5.9,0,0,0-8.4-8.4L128,63.5,84.2,19.8a5.9,5.9,0,0,0-8.4,8.4L113.5,66H40A14,14,0,0,0,26,80V200a14,14,0,0,0,14,14H216a14,14,0,0,0,14-14V80A14,14,0,0,0,216,66ZM38,200V80a2,2,0,0,1,2-2H146V202H40A2,2,0,0,1,38,200Zm180,0a2,2,0,0,1-2,2H158V78h58a2,2,0,0,1,2,2Zm-20-84a10,10,0,1,1-10-10A10,10,0,0,1,198,116Zm0,48a10,10,0,1,1-10-10A10,10,0,0,1,198,164Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M216,68H137.7l41.1-41.2a4,4,0,0,0-5.6-5.6L128,66.3,82.8,21.2a4,4,0,0,0-5.6,5.6L118.3,68H40A12,12,0,0,0,28,80V200a12,12,0,0,0,12,12H216a12,12,0,0,0,12-12V80A12,12,0,0,0,216,68ZM36,200V80a4,4,0,0,1,4-4H148V204H40A4,4,0,0,1,36,200Zm184,0a4,4,0,0,1-4,4H156V76h60a4,4,0,0,1,4,4Zm-24-84a8,8,0,1,1-8-8A8,8,0,0,1,196,116Zm0,48a8,8,0,1,1-8-8A8,8,0,0,1,196,164Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,64H147.3l34.4-34.3a8.1,8.1,0,0,0-11.4-11.4L128,60.7,85.7,18.3A8.1,8.1,0,0,0,74.3,29.7L108.7,64H40A16,16,0,0,0,24,80V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V80A16,16,0,0,0,216,64ZM40,80H144V200H40ZM216,200H160V80h56V200Zm-16-84a12,12,0,1,1-12-12A12,12,0,0,1,200,116Zm0,48a12,12,0,1,1-12-12A12,12,0,0,1,200,164Z" />
        </>
      )}
    </svg>
  );
}

export function PhTennisBall(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M235.9,129.4a5.3,5.3,0,0,0,.1-1.3,4.9,4.9,0,0,0-.1-1.2A107.6,107.6,0,0,0,129.1,20.1h-2.5A107.6,107.6,0,0,0,20.1,126.6a5.3,5.3,0,0,0-.1,1.3,3.7,3.7,0,0,0,.1,1.1A107.6,107.6,0,0,0,127,235.9h2.4A107.6,107.6,0,0,0,235.9,129.4ZM68.6,68.6a83.8,83.8,0,0,1,46.2-23.5,84.3,84.3,0,0,1-69.7,69.7A83.8,83.8,0,0,1,68.6,68.6ZM44.8,139.1a108.2,108.2,0,0,0,94.3-94.3,83.7,83.7,0,0,1,72.1,72.1,108.2,108.2,0,0,0-94.3,94.3,83.7,83.7,0,0,1-72.1-72.1Zm96.4,71.8a84.3,84.3,0,0,1,69.7-69.7,83.7,83.7,0,0,1-69.7,69.7Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M60.1,60.1A95.5,95.5,0,0,1,127.8,32h0A95.9,95.9,0,0,1,32,127.8,95.5,95.5,0,0,1,60.1,60.1ZM32,128v-.2h0Zm96.2,96.2V224h0Zm28.1-67.9A95.5,95.5,0,0,0,128.2,224,95.9,95.9,0,0,0,224,128.2h0A95.5,95.5,0,0,0,156.3,156.3Z"
            opacity="0.2"
          />
          <path d="M231.9,129.1a2.8,2.8,0,0,0,.1-.9,3.1,3.1,0,0,0-.1-1A104,104,0,0,0,128,24h-.2A104,104,0,0,0,24.1,126.9a2.8,2.8,0,0,0-.1.9,3.1,3.1,0,0,0,.1,1A104,104,0,0,0,128,232h.2A104,104,0,0,0,231.9,129.1ZM65.8,65.8a87.4,87.4,0,0,1,53.6-25.3,87.8,87.8,0,0,1-78.9,78.9A87.1,87.1,0,0,1,65.8,65.8ZM40.4,135.5a104,104,0,0,0,95.1-95.1,87.8,87.8,0,0,1,80.1,80.1,104,104,0,0,0-95.1,95.1,87.8,87.8,0,0,1-80.1-80.1Zm96.2,80a87.8,87.8,0,0,1,78.9-78.9,87.8,87.8,0,0,1-78.9,78.9Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M224,136h3.3a4,4,0,0,1,3.9,4.5,104.2,104.2,0,0,1-94.8,91.1A88,88,0,0,1,224,136Zm-73.5,14.5A102.9,102.9,0,0,1,224,120h3.1a4,4,0,0,0,4.1-4.4,104.1,104.1,0,0,0-90.9-90.9,4,4,0,0,0-4.4,4.1,103.7,103.7,0,0,1-30.4,76.7A102.9,102.9,0,0,1,32,136H28.9a4,4,0,0,0-4.1,4.4,104.1,104.1,0,0,0,90.9,90.9,4,4,0,0,0,4.4-4.1A103.7,103.7,0,0,1,150.5,150.5ZM94.2,94.2a87.6,87.6,0,0,0,25.7-65.7,4,4,0,0,0-4.5-3.7,104,104,0,0,0-90.6,90.6,4,4,0,0,0,3.8,4.5A87.8,87.8,0,0,0,94.2,94.2Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M229.9,129a2.5,2.5,0,0,0,.1-.8,1.9,1.9,0,0,0-.1-.8A101.9,101.9,0,0,0,128,26h-.2A101.9,101.9,0,0,0,26.1,127a2.5,2.5,0,0,0-.1.8,1.9,1.9,0,0,0,.1.8A101.9,101.9,0,0,0,128,230h.2A101.9,101.9,0,0,0,229.9,129ZM64.4,64.4a89.1,89.1,0,0,1,57.2-26.1,89.9,89.9,0,0,1-83.3,83.3A89.1,89.1,0,0,1,64.4,64.4ZM38.2,133.6a101.7,101.7,0,0,0,95.4-95.4,90.1,90.1,0,0,1,84.2,84.2,101.7,101.7,0,0,0-95.4,95.4,90.1,90.1,0,0,1-84.2-84.2Zm96.2,84.1a89.9,89.9,0,0,1,83.3-83.3,89.9,89.9,0,0,1-83.3,83.3Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M227.9,128.7a.9.9,0,0,0,.1-.5.7.7,0,0,0-.1-.5A99.9,99.9,0,0,0,128,28h-.2a99.9,99.9,0,0,0-99.7,99.3,1.1,1.1,0,0,0-.1.5.7.7,0,0,0,.1.5A99.9,99.9,0,0,0,128,228h.2a99.9,99.9,0,0,0,99.7-99.3ZM62.9,62.9a91.7,91.7,0,0,1,60.8-26.8,91.8,91.8,0,0,1-87.6,87.6A92.4,92.4,0,0,1,62.9,62.9ZM36.1,131.7a98.7,98.7,0,0,0,66.4-29.2,98.7,98.7,0,0,0,29.2-66.4,91.7,91.7,0,0,1,88.2,88.2,100,100,0,0,0-95.6,95.6,91.7,91.7,0,0,1-88.2-88.2Zm96.2,88.2a91.8,91.8,0,0,1,87.6-87.6,91.8,91.8,0,0,1-87.6,87.6Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M231.9,129.1a2.8,2.8,0,0,0,.1-.9,3.1,3.1,0,0,0-.1-1A104,104,0,0,0,128,24h-.2A104,104,0,0,0,24.1,126.9a2.8,2.8,0,0,0-.1.9,3.1,3.1,0,0,0,.1,1A104,104,0,0,0,128,232h.2A104,104,0,0,0,231.9,129.1ZM65.8,65.8a87.4,87.4,0,0,1,53.6-25.3,87.8,87.8,0,0,1-78.9,78.9A87.1,87.1,0,0,1,65.8,65.8ZM40.4,135.5a104,104,0,0,0,95.1-95.1,87.8,87.8,0,0,1,80.1,80.1,104,104,0,0,0-95.1,95.1,87.8,87.8,0,0,1-80.1-80.1Zm96.2,80a87.8,87.8,0,0,1,78.9-78.9,87.8,87.8,0,0,1-78.9,78.9Z" />
        </>
      )}
    </svg>
  );
}

export function PhTerminalWindow(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M72.5,150.6,100.8,128,72.5,105.4a12,12,0,1,1,15-18.8l40,32a12.1,12.1,0,0,1,0,18.8l-40,32A12.4,12.4,0,0,1,80,172a12,12,0,0,1-7.5-21.4ZM144,172h32a12,12,0,0,0,0-24H144a12,12,0,0,0,0,24ZM236,56.5v143A20.5,20.5,0,0,1,215.5,220H40.5A20.5,20.5,0,0,1,20,199.5V56.5A20.5,20.5,0,0,1,40.5,36h175A20.5,20.5,0,0,1,236,56.5ZM212,60H44V196H212Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M224,56.5v143a8.5,8.5,0,0,1-8.5,8.5H40.5a8.5,8.5,0,0,1-8.5-8.5V56.5A8.5,8.5,0,0,1,40.5,48h175A8.5,8.5,0,0,1,224,56.5Z"
            opacity="0.2"
          />
          <path d="M128,128a7.9,7.9,0,0,1-3,6.2l-40,32a7.9,7.9,0,0,1-5,1.8,7.8,7.8,0,0,1-6.2-3A7.9,7.9,0,0,1,75,153.8L107.2,128,75,102.2A8,8,0,1,1,85,89.8l40,32A7.9,7.9,0,0,1,128,128Zm48,24H136a8,8,0,0,0,0,16h40a8,8,0,0,0,0-16Zm56.5-95.5v143a17,17,0,0,1-17,17H40.5a17,17,0,0,1-17-17V56.5a17,17,0,0,1,17-17h175A17,17,0,0,1,232.5,56.5Zm-17,143V56.5H40.5v143Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M215.5,39.5H40.5a17,17,0,0,0-17,17v143a17,17,0,0,0,17,17h175a17,17,0,0,0,17-17V56.5A17,17,0,0,0,215.5,39.5ZM121,134.2l-40,32a7.9,7.9,0,0,1-5,1.8,7.8,7.8,0,0,1-6.2-3A7.9,7.9,0,0,1,71,153.8L103.2,128,71,102.2A8,8,0,1,1,81,89.8l40,32a7.9,7.9,0,0,1,0,12.4ZM180,168H140a8,8,0,0,1,0-16h40a8,8,0,0,1,0,16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M126,128a6,6,0,0,1-2.3,4.7l-40,32A5.8,5.8,0,0,1,80,166a6,6,0,0,1-3.7-10.7L110.4,128,76.3,100.7a6,6,0,0,1,7.4-9.4l40,32A6,6,0,0,1,126,128Zm50,26H136a6,6,0,0,0,0,12h40a6,6,0,0,0,0-12Zm54-97.5v143A14.5,14.5,0,0,1,215.5,214H40.5A14.5,14.5,0,0,1,26,199.5V56.5A14.5,14.5,0,0,1,40.5,42h175A14.5,14.5,0,0,1,230,56.5Zm-12,0a2.5,2.5,0,0,0-2.5-2.5H40.5A2.5,2.5,0,0,0,38,56.5v143a2.5,2.5,0,0,0,2.5,2.5h175a2.5,2.5,0,0,0,2.5-2.5Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M122.5,124.9a4,4,0,0,1,0,6.2l-40,32a3.8,3.8,0,0,1-2.5.9,3.9,3.9,0,0,1-3.1-1.5,4,4,0,0,1,.6-5.6L113.6,128,77.5,99.1a4,4,0,1,1,5-6.2ZM176,156H136a4,4,0,0,0,0,8h40a4,4,0,0,0,0-8Zm52-99.5v143A12.5,12.5,0,0,1,215.5,212H40.5A12.5,12.5,0,0,1,28,199.5V56.5A12.5,12.5,0,0,1,40.5,44h175A12.5,12.5,0,0,1,228,56.5Zm-8,0a4.5,4.5,0,0,0-4.5-4.5H40.5A4.5,4.5,0,0,0,36,56.5v143a4.5,4.5,0,0,0,4.5,4.5h175a4.5,4.5,0,0,0,4.5-4.5Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,128a7.9,7.9,0,0,1-3,6.2l-40,32a7.9,7.9,0,0,1-5,1.8,7.8,7.8,0,0,1-6.2-3A7.9,7.9,0,0,1,75,153.8L107.2,128,75,102.2A8,8,0,1,1,85,89.8l40,32A7.9,7.9,0,0,1,128,128Zm48,24H136a8,8,0,0,0,0,16h40a8,8,0,0,0,0-16Zm56.5-95.5v143a17,17,0,0,1-17,17H40.5a17,17,0,0,1-17-17V56.5a17,17,0,0,1,17-17h175A17,17,0,0,1,232.5,56.5Zm-17,143V56.5H40.5v143Z" />
        </>
      )}
    </svg>
  );
}

export function PhTerminal(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M120,137,48,201a12.1,12.1,0,0,1-17-1,12.1,12.1,0,0,1,1-17l61.9-55L32,73A12,12,0,1,1,48,55l72,64a12.1,12.1,0,0,1,0,18Zm96,43H120a12,12,0,0,0,0,24h96a12,12,0,0,0,0-24Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M117.3,134l-72,64a8.1,8.1,0,0,1-5.3,2,8,8,0,0,1-5.3-14L100,128,34.7,70A8,8,0,1,1,45.3,58l72,64a8,8,0,0,1,0,12ZM216,184H120a8,8,0,0,0,0,16h96a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M117.3,134l-72,64a8.1,8.1,0,0,1-5.3,2,8,8,0,0,1-5.3-14L100,128,34.7,70A8,8,0,1,1,45.3,58l72,64a8,8,0,0,1,0,12ZM216,184H120a8,8,0,0,0,0,16h96a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M116,132.5l-72,64a6.1,6.1,0,0,1-8.5-.5,6,6,0,0,1,.5-8.5L103,128,36,68.5a6,6,0,1,1,8-9l72,64a6.1,6.1,0,0,1,0,9ZM216,186H120a6,6,0,0,0,0,12h96a6,6,0,0,0,0-12Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M116,128a4.3,4.3,0,0,1-1.3,3l-72,64a3.9,3.9,0,0,1-2.7,1,4.3,4.3,0,0,1-3-1.3,4.1,4.1,0,0,1,.3-5.7L106,128,37.3,67a4,4,0,1,1,5.4-6l72,64A4.3,4.3,0,0,1,116,128Zm100,60H120a4,4,0,0,0,0,8h96a4,4,0,0,0,0-8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M117.3,134l-72,64a8.1,8.1,0,0,1-5.3,2,8,8,0,0,1-5.3-14L100,128,34.7,70A8,8,0,1,1,45.3,58l72,64a8,8,0,0,1,0,12ZM216,184H120a8,8,0,0,0,0,16h96a8,8,0,0,0,0-16Z" />
        </>
      )}
    </svg>
  );
}

export function PhTestTube(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M240.5,83.5l-60-60a12,12,0,0,0-17,0l-130,130a48.8,48.8,0,1,0,69,69l112-112,21.3-7.1a12.2,12.2,0,0,0,7.9-8.6A12.4,12.4,0,0,0,240.5,83.5Zm-155,122a24.7,24.7,0,0,1-35-35l25.3-25.3c2.6-1.7,16.6-9.6,38.8,1.5a81.9,81.9,0,0,0,22.2,7.5ZM204.2,88.6a12,12,0,0,0-4.7,2.9l-35.3,35.3c-2.6,1.7-16.6,9.6-38.8-1.5a81.9,81.9,0,0,0-22.2-7.5L172,49l37.8,37.8Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M172,136,94,214a36.7,36.7,0,0,1-52,0h0a36.7,36.7,0,0,1,0-52l26-26s20-16,52,0S172,136,172,136Z"
            opacity="0.2"
          />
          <path d="M237.7,86.3l-60-60a8.1,8.1,0,0,0-11.4,0L62.4,130.3h0l-26.1,26a44.8,44.8,0,0,0,63.4,63.4l77.9-78h0L212.3,107l22.2-7.4a8.2,8.2,0,0,0,5.3-5.8A8.3,8.3,0,0,0,237.7,86.3ZM88.3,208.3a28.7,28.7,0,0,1-40.6-40.6l25.6-25.6c2.1-1.6,17.8-11.6,43.1,1.1,11,5.5,20.8,7.6,29.2,7.9ZM205.5,92.4a9.4,9.4,0,0,0-3.2,1.9l-35.6,35.6c-2.1,1.6-17.9,11.6-43.1-1.1-11-5.5-20.8-7.6-29.2-7.9L172,43.3l45.2,45.2Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M237.7,86.3l-60-60a8.1,8.1,0,0,0-11.4,0L62.4,130.3h0l-26.1,26a44.8,44.8,0,0,0,63.4,63.4l77.9-78h0L212.3,107l22.2-7.4a8.2,8.2,0,0,0,5.3-5.8A8.3,8.3,0,0,0,237.7,86.3Zm-32.2,6.1a9.4,9.4,0,0,0-3.2,1.9l-35.6,35.6c-2.1,1.6-17.9,11.6-43.1-1.1-11-5.5-20.8-7.6-29.2-7.9L172,43.3l45.2,45.2Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M236.2,87.8l-60-60a5.8,5.8,0,0,0-8.4,0L63.8,131.7h0l-26,26.1a42.7,42.7,0,1,0,60.4,60.4l78-77.9h0l35-35.1,22.7-7.5a6,6,0,0,0,2.3-9.9ZM89.8,209.8a30.8,30.8,0,0,1-43.6-43.6l25.7-25.7c2.2-1.5,18.9-12.4,45.4.9,11.6,5.8,21.9,7.7,30.4,7.7h2.8ZM206.1,94.3a5.1,5.1,0,0,0-2.3,1.5l-35.7,35.7c-2.2,1.5-18.9,12.4-45.4-.9-12.9-6.4-24.1-8.1-33.1-7.7L172,40.5l48.9,48.9Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M234.8,89.2l-60-60a3.9,3.9,0,0,0-5.6,0l-130,130a40.7,40.7,0,1,0,57.6,57.6L210.2,103.5l23.1-7.7a3.8,3.8,0,0,0,2.6-2.9A4,4,0,0,0,234.8,89.2ZM91.2,211.2a33,33,0,0,1-46.4,0,32.8,32.8,0,0,1,0-46.4L70.6,139c2-1.4,19.5-13.5,47.6.6,11.3,5.6,21.3,7.6,29.6,7.6a48.6,48.6,0,0,0,8-.7Zm115.5-115a3.1,3.1,0,0,0-1.5,1L169.4,133c-2,1.4-19.6,13.5-47.6-.6-15.3-7.6-28.1-8.4-37.6-6.9L172,37.7l52.6,52.6Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M237.7,86.3l-60-60a8.1,8.1,0,0,0-11.4,0L62.4,130.3h0l-26.1,26a44.8,44.8,0,0,0,63.4,63.4l77.9-78h0L212.3,107l22.2-7.4a8.2,8.2,0,0,0,5.3-5.8A8.3,8.3,0,0,0,237.7,86.3ZM88.3,208.3a28.5,28.5,0,0,1-40.6,0,28.5,28.5,0,0,1,0-40.6l25.6-25.6c2.1-1.6,17.9-11.6,43.1,1.1,11,5.5,20.8,7.6,29.2,7.9ZM205.5,92.4a9.4,9.4,0,0,0-3.2,1.9l-35.6,35.6c-2.1,1.6-17.8,11.6-43.1-1.1-11-5.5-20.8-7.6-29.2-7.9L172,43.3l45.2,45.2Z" />
        </>
      )}
    </svg>
  );
}

export function PhTextAa(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M86.9,50.9a12.1,12.1,0,0,0-21.8,0l-64,136a12,12,0,0,0,21.8,10.2L38.4,164h75.2l15.5,33.1A12,12,0,0,0,140,204a13,13,0,0,0,5.1-1.1,12.1,12.1,0,0,0,5.8-16ZM49.7,140,76,84.2,102.3,140ZM204,90.9a47,47,0,0,0-26.9,8.3,12,12,0,0,0,13.8,19.7,22.6,22.6,0,0,1,13.1-4c11,0,20,7.2,20,16v1.4a48.8,48.8,0,0,0-20-4.3c-24.3,0-44,17.5-44,39s19.7,39,44,39a48.4,48.4,0,0,0,23.2-5.9A11.9,11.9,0,0,0,236,204a12,12,0,0,0,12-12V130.9C248,108.8,228.3,90.9,204,90.9Zm0,91.1c-10.8,0-20-6.9-20-15s9.2-15,20-15,20,6.9,20,15S214.8,182,204,182Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M137.9,148.2h0L87.1,52.3a8,8,0,0,0-14.2,0L22.1,148.2h0L.9,188.3a8.1,8.1,0,0,0,3.4,10.8,8.1,8.1,0,0,0,10.8-3.4L34,160h92l18.9,35.7A7.9,7.9,0,0,0,152,200a8,8,0,0,0,7.1-11.7ZM42.5,144,80,73.1,117.5,144ZM208,94.9a42,42,0,0,0-29.6,11.8,8,8,0,1,0,11.2,11.4,26,26,0,0,1,18.4-7.2c13.2,0,24,9,24,20v7.2a43,43,0,0,0-24-7.2c-22.1,0-40,16.1-40,36s17.9,36,40,36a42.9,42.9,0,0,0,24.7-7.7A7.9,7.9,0,0,0,240,200a8,8,0,0,0,8-8V130.9C248,111,230.1,94.9,208,94.9Zm0,92c-13.2,0-24-9-24-20s10.8-20,24-20,24,9,24,20S221.2,186.9,208,186.9Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M208,94.9a42,42,0,0,0-29.6,11.8,8,8,0,1,0,11.2,11.4,26,26,0,0,1,18.4-7.2c13.2,0,24,9,24,20v7.2a43,43,0,0,0-24-7.2c-22.1,0-40,16.1-40,36s17.9,36,40,36a42.9,42.9,0,0,0,24.7-7.7A7.9,7.9,0,0,0,240,200a8,8,0,0,0,8-8V130.9C248,111,230.1,94.9,208,94.9Zm0,92c-13.2,0-24-9-24-20s10.8-20,24-20,24,9,24,20S221.2,186.9,208,186.9Zm-70.1-38.7h0L87.1,52.3a8,8,0,0,0-14.2,0L22.1,148.2h0L.9,188.3a8.1,8.1,0,0,0,3.4,10.8,8.1,8.1,0,0,0,10.8-3.4L34,160h92l18.9,35.7A7.9,7.9,0,0,0,152,200a8,8,0,0,0,7.1-11.7ZM42.5,144,80,73.1,117.5,144Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M136.1,149.1h0L85.3,53.2a6,6,0,0,0-10.6,0L23.9,149.1h0L2.7,189.2a6,6,0,1,0,10.6,5.6L32.8,158h94.4l19.5,36.8A6.1,6.1,0,0,0,152,198a5.5,5.5,0,0,0,2.8-.7,6,6,0,0,0,2.5-8.1Zm-97-3.1L80,68.8,120.9,146ZM208,96.9a40.2,40.2,0,0,0-28.2,11.2,6,6,0,0,0-.1,8.5,5.9,5.9,0,0,0,8.5,0,28.3,28.3,0,0,1,19.8-7.7c14.3,0,26,9.9,26,22v11.2a40.3,40.3,0,0,0-26-9.2c-21,0-38,15.3-38,34s17,34,38,34a40.3,40.3,0,0,0,26-9.2v.3a6,6,0,0,0,12,0V130.9C246,112.2,229,96.9,208,96.9Zm0,92c-14.3,0-26-9.9-26-22s11.7-22,26-22,26,9.9,26,22S222.3,188.9,208,188.9Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M83.5,54.1a4,4,0,0,0-7,0l-72,136a4,4,0,0,0,7,3.8L31.6,156h96.8l20.1,37.9A4,4,0,0,0,152,196a3.4,3.4,0,0,0,1.9-.5,3.9,3.9,0,0,0,1.6-5.4ZM35.8,148,80,64.5,124.2,148ZM208,98.9a38.4,38.4,0,0,0-26.8,10.6,4,4,0,0,0,0,5.7,3.9,3.9,0,0,0,5.6,0c5.3-5.3,13.1-8.3,21.2-8.3,15.4,0,28,10.8,28,24v15.9a38,38,0,0,0-28-11.9c-19.9,0-36,14.4-36,32s16.1,32,36,32A38,38,0,0,0,236,187v5a4,4,0,0,0,8,0V130.9C244,113.3,227.9,98.9,208,98.9Zm0,92c-15.4,0-28-10.8-28-24s12.6-24,28-24,28,10.8,28,24S223.4,190.9,208,190.9Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M137.9,148.2h0L87.1,52.3a8,8,0,0,0-14.2,0L22.1,148.2h0L.9,188.3a8.1,8.1,0,0,0,3.4,10.8,8.1,8.1,0,0,0,10.8-3.4L34,160h92l18.9,35.7A7.9,7.9,0,0,0,152,200a8,8,0,0,0,7.1-11.7ZM42.5,144,80,73.1,117.5,144ZM208,94.9a42,42,0,0,0-29.6,11.8,8,8,0,1,0,11.2,11.4,26,26,0,0,1,18.4-7.2c13.2,0,24,9,24,20v7.2a43,43,0,0,0-24-7.2c-22.1,0-40,16.1-40,36s17.9,36,40,36a42.9,42.9,0,0,0,24.7-7.7A7.9,7.9,0,0,0,240,200a8,8,0,0,0,8-8V130.9C248,111,230.1,94.9,208,94.9Zm0,92c-13.2,0-24-9-24-20s10.8-20,24-20,24,9,24,20S221.2,186.9,208,186.9Z" />
        </>
      )}
    </svg>
  );
}

export function PhTextAlignCenter(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M28,68A12,12,0,0,1,40,56H216a12,12,0,0,1,0,24H40A12,12,0,0,1,28,68ZM64,96a12,12,0,0,0,0,24H192a12,12,0,0,0,0-24Zm152,40H40a12,12,0,0,0,0,24H216a12,12,0,0,0,0-24Zm-24,40H64a12,12,0,0,0,0,24H192a12,12,0,0,0,0-24Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M32,68a8,8,0,0,1,8-8H216a8,8,0,0,1,0,16H40A8,8,0,0,1,32,68Zm32,32a8,8,0,0,0,0,16H192a8,8,0,0,0,0-16Zm152,40H40a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Zm-24,40H64a8,8,0,0,0,0,16H192a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M32,68a8,8,0,0,1,8-8H216a8,8,0,0,1,0,16H40A8,8,0,0,1,32,68Zm32,32a8,8,0,0,0,0,16H192a8,8,0,0,0,0-16Zm152,40H40a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Zm-24,40H64a8,8,0,0,0,0,16H192a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M34,68a6,6,0,0,1,6-6H216a6,6,0,0,1,0,12H40A6,6,0,0,1,34,68Zm30,34a6,6,0,0,0,0,12H192a6,6,0,0,0,0-12Zm152,40H40a6,6,0,0,0,0,12H216a6,6,0,0,0,0-12Zm-24,40H64a6,6,0,0,0,0,12H192a6,6,0,0,0,0-12Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M36,68a4,4,0,0,1,4-4H216a4,4,0,0,1,0,8H40A4,4,0,0,1,36,68Zm28,36a4,4,0,0,0,0,8H192a4,4,0,0,0,0-8Zm152,40H40a4,4,0,0,0,0,8H216a4,4,0,0,0,0-8Zm-24,40H64a4,4,0,0,0,0,8H192a4,4,0,0,0,0-8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M32,68a8,8,0,0,1,8-8H216a8,8,0,0,1,0,16H40A8,8,0,0,1,32,68Zm32,32a8,8,0,0,0,0,16H192a8,8,0,0,0,0-16Zm152,40H40a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Zm-24,40H64a8,8,0,0,0,0,16H192a8,8,0,0,0,0-16Z" />
        </>
      )}
    </svg>
  );
}

export function PhTextAlignJustify(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M28,68A12,12,0,0,1,40,56H216a12,12,0,0,1,0,24H40A12,12,0,0,1,28,68ZM216,96H40a12,12,0,0,0,0,24H216a12,12,0,0,0,0-24Zm0,40H40a12,12,0,0,0,0,24H216a12,12,0,0,0,0-24Zm0,40H40a12,12,0,0,0,0,24H216a12,12,0,0,0,0-24Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M32,68a8,8,0,0,1,8-8H216a8,8,0,0,1,0,16H40A8,8,0,0,1,32,68Zm184,32H40a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Zm0,40H40a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Zm0,40H40a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M32,68a8,8,0,0,1,8-8H216a8,8,0,0,1,0,16H40A8,8,0,0,1,32,68Zm184,32H40a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Zm0,40H40a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Zm0,40H40a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M34,68a6,6,0,0,1,6-6H216a6,6,0,0,1,0,12H40A6,6,0,0,1,34,68Zm182,34H40a6,6,0,0,0,0,12H216a6,6,0,0,0,0-12Zm0,40H40a6,6,0,0,0,0,12H216a6,6,0,0,0,0-12Zm0,40H40a6,6,0,0,0,0,12H216a6,6,0,0,0,0-12Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M36,68a4,4,0,0,1,4-4H216a4,4,0,0,1,0,8H40A4,4,0,0,1,36,68Zm180,36H40a4,4,0,0,0,0,8H216a4,4,0,0,0,0-8Zm0,40H40a4,4,0,0,0,0,8H216a4,4,0,0,0,0-8Zm0,40H40a4,4,0,0,0,0,8H216a4,4,0,0,0,0-8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M32,68a8,8,0,0,1,8-8H216a8,8,0,0,1,0,16H40A8,8,0,0,1,32,68Zm184,32H40a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Zm0,40H40a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Zm0,40H40a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Z" />
        </>
      )}
    </svg>
  );
}

export function PhTextAlignLeft(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M28,68A12,12,0,0,1,40,56H216a12,12,0,0,1,0,24H40A12,12,0,0,1,28,68Zm12,52H168a12,12,0,0,0,0-24H40a12,12,0,0,0,0,24Zm176,16H40a12,12,0,0,0,0,24H216a12,12,0,0,0,0-24Zm-48,40H40a12,12,0,0,0,0,24H168a12,12,0,0,0,0-24Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M32,68a8,8,0,0,1,8-8H216a8,8,0,0,1,0,16H40A8,8,0,0,1,32,68Zm8,48H168a8,8,0,0,0,0-16H40a8,8,0,0,0,0,16Zm176,24H40a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Zm-48,40H40a8,8,0,0,0,0,16H168a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M32,68a8,8,0,0,1,8-8H216a8,8,0,0,1,0,16H40A8,8,0,0,1,32,68Zm8,48H168a8,8,0,0,0,0-16H40a8,8,0,0,0,0,16Zm176,24H40a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Zm-48,40H40a8,8,0,0,0,0,16H168a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M34,68a6,6,0,0,1,6-6H216a6,6,0,0,1,0,12H40A6,6,0,0,1,34,68Zm6,46H168a6,6,0,0,0,0-12H40a6,6,0,0,0,0,12Zm176,28H40a6,6,0,0,0,0,12H216a6,6,0,0,0,0-12Zm-48,40H40a6,6,0,0,0,0,12H168a6,6,0,0,0,0-12Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M36,68a4,4,0,0,1,4-4H216a4,4,0,0,1,0,8H40A4,4,0,0,1,36,68Zm4,44H168a4,4,0,0,0,0-8H40a4,4,0,0,0,0,8Zm176,32H40a4,4,0,0,0,0,8H216a4,4,0,0,0,0-8Zm-48,40H40a4,4,0,0,0,0,8H168a4,4,0,0,0,0-8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M32,68a8,8,0,0,1,8-8H216a8,8,0,0,1,0,16H40A8,8,0,0,1,32,68Zm8,48H168a8,8,0,0,0,0-16H40a8,8,0,0,0,0,16Zm176,24H40a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Zm-48,40H40a8,8,0,0,0,0,16H168a8,8,0,0,0,0-16Z" />
        </>
      )}
    </svg>
  );
}

export function PhTextAlignRight(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M28,68A12,12,0,0,1,40,56H216a12,12,0,0,1,0,24H40A12,12,0,0,1,28,68ZM216,96H88a12,12,0,0,0,0,24H216a12,12,0,0,0,0-24Zm0,40H40a12,12,0,0,0,0,24H216a12,12,0,0,0,0-24Zm0,40H88a12,12,0,0,0,0,24H216a12,12,0,0,0,0-24Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M32,68a8,8,0,0,1,8-8H216a8,8,0,0,1,0,16H40A8,8,0,0,1,32,68Zm184,32H88a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Zm0,40H40a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Zm0,40H88a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M32,68a8,8,0,0,1,8-8H216a8,8,0,0,1,0,16H40A8,8,0,0,1,32,68Zm184,32H88a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Zm0,40H40a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Zm0,40H88a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M34,68a6,6,0,0,1,6-6H216a6,6,0,0,1,0,12H40A6,6,0,0,1,34,68Zm182,34H88a6,6,0,0,0,0,12H216a6,6,0,0,0,0-12Zm0,40H40a6,6,0,0,0,0,12H216a6,6,0,0,0,0-12Zm0,40H88a6,6,0,0,0,0,12H216a6,6,0,0,0,0-12Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M36,68a4,4,0,0,1,4-4H216a4,4,0,0,1,0,8H40A4,4,0,0,1,36,68Zm180,36H88a4,4,0,0,0,0,8H216a4,4,0,0,0,0-8Zm0,40H40a4,4,0,0,0,0,8H216a4,4,0,0,0,0-8Zm0,40H88a4,4,0,0,0,0,8H216a4,4,0,0,0,0-8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M32,68a8,8,0,0,1,8-8H216a8,8,0,0,1,0,16H40A8,8,0,0,1,32,68Zm184,32H88a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Zm0,40H40a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Zm0,40H88a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Z" />
        </>
      )}
    </svg>
  );
}

export function PhTextBolder(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M177.1,114.5A48,48,0,0,0,140,36H64A12,12,0,0,0,52,48V200a12,12,0,0,0,12,12h88a52,52,0,0,0,25.1-97.5ZM76,60h64a24,24,0,0,1,0,48H76Zm76,128H76V132h76a28,28,0,0,1,0,56Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M170.5,115.7A44,44,0,0,0,140,40H64a7.9,7.9,0,0,0-8,8V200a8,8,0,0,0,8,8h88a48,48,0,0,0,18.5-92.3ZM72,56h68a28,28,0,0,1,0,56H72Zm80,136H72V128h80a32,32,0,0,1,0,64Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M170.5,115.7A44,44,0,0,0,140,40H64a7.9,7.9,0,0,0-8,8V200a8,8,0,0,0,8,8h88a48,48,0,0,0,18.5-92.3ZM72,56h68a28,28,0,0,1,0,56H72Zm80,136H72V128h80a32,32,0,0,1,0,64Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M166.7,116.4A42,42,0,0,0,140,42H64a6.2,6.2,0,0,0-6,6V200a6,6,0,0,0,6,6h88a46,46,0,0,0,14.7-89.6ZM70,54h70a30,30,0,0,1,0,60H70Zm82,140H70V126h82a34,34,0,0,1,0,68Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M162.3,117.2A40,40,0,0,0,140,44H64a4.1,4.1,0,0,0-4,4V200a4,4,0,0,0,4,4h88a44,44,0,0,0,10.3-86.8ZM68,52h72a32,32,0,0,1,0,64H68Zm84,144H68V124h84a36,36,0,0,1,0,72Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M170.5,115.7A44,44,0,0,0,140,40H64a7.9,7.9,0,0,0-8,8V200a8,8,0,0,0,8,8h88a48,48,0,0,0,18.5-92.3ZM72,56h68a28,28,0,0,1,0,56H72Zm80,136H72V128h80a32,32,0,0,1,0,64Z" />
        </>
      )}
    </svg>
  );
}

export function PhTextHFive(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M156,56V176a12,12,0,0,1-24,0V128H52v48a12,12,0,0,1-24,0V56a12,12,0,0,1,24,0v48h80V56a12,12,0,0,1,24,0Zm55.9,80-4.5.2L210,120h30a12,12,0,0,0,0-24H199.8A12,12,0,0,0,188,106.1l-7.8,48.2a12,12,0,0,0,20.3,10.4,16.2,16.2,0,0,1,22.8,0,15.9,15.9,0,0,1,0,22.6,16.2,16.2,0,0,1-22.8,0,12,12,0,0,0-17,17A40.3,40.3,0,0,0,211.9,216a40,40,0,1,0,0-80Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M152,56V176a8,8,0,0,1-16,0V124H48v52a8,8,0,0,1-16,0V56a8,8,0,0,1,16,0v52h88V56a8,8,0,0,1,16,0Zm59.9,84a37.3,37.3,0,0,0-9.4,1.2l4.1-25.2H240a8,8,0,0,0,0-16H199.8a8,8,0,0,0-7.9,6.7l-7.8,48.2a8,8,0,0,0,13.5,7,20.1,20.1,0,0,1,14.3-5.9,19.9,19.9,0,0,1,14.2,5.9,19.8,19.8,0,0,1,0,28.2,19.9,19.9,0,0,1-14.2,5.9,20.1,20.1,0,0,1-14.3-5.9,8,8,0,1,0-11.2,11.4A36.1,36.1,0,1,0,211.9,140Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M237.4,150.5a36.1,36.1,0,0,1-51,51,8,8,0,1,1,11.2-11.4,20.1,20.1,0,0,0,14.3,5.9,19.9,19.9,0,0,0,14.2-5.9,19.8,19.8,0,0,0,0-28.2,19.9,19.9,0,0,0-14.2-5.9,20.1,20.1,0,0,0-14.3,5.9,8,8,0,0,1-13.5-7l7.8-48.2a8,8,0,0,1,7.9-6.7H240a8,8,0,0,1,0,16H206.6l-4.1,25.2a37.3,37.3,0,0,1,9.4-1.2A35.9,35.9,0,0,1,237.4,150.5ZM144,48a8,8,0,0,0-8,8v52H48V56a8,8,0,0,0-16,0V176a8,8,0,0,0,16,0V124h88v52a8,8,0,0,0,16,0V56A8,8,0,0,0,144,48Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M150,56V176a6,6,0,0,1-12,0V122H46v54a6,6,0,0,1-12,0V56a6,6,0,0,1,12,0v54h92V56a6,6,0,0,1,12,0Zm61.9,86a34.3,34.3,0,0,0-11.9,2.1l4.9-30.1H240a6,6,0,0,0,0-12H199.8a6,6,0,0,0-5.9,5l-7.8,48.2a5.8,5.8,0,0,0,3.2,6.3,5.9,5.9,0,0,0,6.9-1,22.1,22.1,0,0,1,15.7-6.5,21.7,21.7,0,0,1,15.6,6.5,21.7,21.7,0,0,1,0,31,21.7,21.7,0,0,1-15.6,6.5,22.1,22.1,0,0,1-15.7-6.5,6,6,0,0,0-8.5.1,5.9,5.9,0,0,0,.1,8.4,34.2,34.2,0,0,0,48.2.1A34.1,34.1,0,0,0,246,176a34.1,34.1,0,0,0-34.1-34Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M148,56V176a4,4,0,0,1-8,0V120H44v56a4,4,0,0,1-8,0V56a4,4,0,0,1,8,0v56h96V56a4,4,0,0,1,8,0Zm63.9,88a32.1,32.1,0,0,0-14.4,3.4l5.7-35.4H240a4,4,0,0,0,0-8H199.8a4,4,0,0,0-3.9,3.4l-7.8,48.2a3.9,3.9,0,0,0,2.1,4.2,3.8,3.8,0,0,0,4.6-.8,24.3,24.3,0,0,1,34.1,0,24,24,0,0,1-17,41,24.2,24.2,0,0,1-17.1-7,4,4,0,0,0-5.6,5.6A32,32,0,1,0,211.9,144Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M152,56V176a8,8,0,0,1-16,0V124H48v52a8,8,0,0,1-16,0V56a8,8,0,0,1,16,0v52h88V56a8,8,0,0,1,16,0Zm59.9,84a37.3,37.3,0,0,0-9.4,1.2l4.1-25.2H240a8,8,0,0,0,0-16H199.8a8,8,0,0,0-7.9,6.7l-7.8,48.2a8,8,0,0,0,13.5,7,20.1,20.1,0,0,1,14.3-5.9,19.9,19.9,0,0,1,14.2,5.9,19.8,19.8,0,0,1,0,28.2,19.9,19.9,0,0,1-14.2,5.9,20.1,20.1,0,0,1-14.3-5.9,8,8,0,1,0-11.2,11.4A36.1,36.1,0,1,0,211.9,140Z" />
        </>
      )}
    </svg>
  );
}

export function PhTextHFour(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M156,56V176a12,12,0,0,1-24,0V128H52v48a12,12,0,0,1-24,0V56a12,12,0,0,1,24,0v48h80V56a12,12,0,0,1,24,0Zm80,68a12,12,0,0,0-12,12v20H205l18.3-52a12,12,0,0,0-22.6-8l-24,68A12,12,0,0,0,188,180h36v20a12,12,0,0,0,24,0V136A12,12,0,0,0,236,124Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M152,56V176a8,8,0,0,1-16,0V124H48v52a8,8,0,0,1-16,0V56a8,8,0,0,1,16,0v52h88V56a8,8,0,0,1,16,0Zm84,72a8,8,0,0,0-8,8v24H199.3l20.2-57.3a8,8,0,0,0-15-5.4l-24,68a7.9,7.9,0,0,0,1,7.3A8,8,0,0,0,188,176h40v24a8,8,0,0,0,16,0V136A8,8,0,0,0,236,128Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M152,56V176a8,8,0,0,1-16,0V124H48v52a8,8,0,0,1-16,0V56a8,8,0,0,1,16,0v52h88V56a8,8,0,0,1,16,0Zm84,72a8,8,0,0,0-8,8v24H199.3l20.2-57.3a8,8,0,0,0-15-5.4l-24,68a7.9,7.9,0,0,0,1,7.3A8,8,0,0,0,188,176h40v24a8,8,0,0,0,16,0V136A8,8,0,0,0,236,128Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M150,56V176a6,6,0,0,1-12,0V122H46v54a6,6,0,0,1-12,0V56a6,6,0,0,1,12,0v54h92V56a6,6,0,0,1,12,0Zm86,74a6,6,0,0,0-6,6v26H196.5l21.2-60a6,6,0,1,0-11.4-4l-24,68a6.3,6.3,0,0,0,.8,5.5A6,6,0,0,0,188,174h42v26a6,6,0,0,0,12,0V136A6,6,0,0,0,236,130Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M148,56V176a4,4,0,0,1-8,0V120H44v56a4,4,0,0,1-8,0V56a4,4,0,0,1,8,0v56h96V56a4,4,0,0,1,8,0Zm88,76a4,4,0,0,0-4,4v28H193.7l22.1-62.7a4,4,0,1,0-7.6-2.6l-24,68a4.2,4.2,0,0,0,.5,3.6A4,4,0,0,0,188,172h44v28a4,4,0,0,0,8,0V136A4,4,0,0,0,236,132Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M152,56V176a8,8,0,0,1-16,0V124H48v52a8,8,0,0,1-16,0V56a8,8,0,0,1,16,0v52h88V56a8,8,0,0,1,16,0Zm84,72a8,8,0,0,0-8,8v24H199.3l20.2-57.3a8,8,0,0,0-15-5.4l-24,68a7.9,7.9,0,0,0,1,7.3A8,8,0,0,0,188,176h40v24a8,8,0,0,0,16,0V136A8,8,0,0,0,236,128Z" />
        </>
      )}
    </svg>
  );
}

export function PhTextHOne(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M156,56V176a12,12,0,0,1-24,0V128H52v48a12,12,0,0,1-24,0V56a12,12,0,0,1,24,0v48h80V56a12,12,0,0,1,24,0Zm69.7,41.4a12.2,12.2,0,0,0-12.4.6l-24,16a12,12,0,0,0,13.4,20l5.3-3.6V200a12,12,0,0,0,24,0V108A12,12,0,0,0,225.7,97.4Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M152,56V176a8,8,0,0,1-16,0V124H48v52a8,8,0,0,1-16,0V56a8,8,0,0,1,16,0v52h88V56a8,8,0,0,1,16,0Zm71.8,44.9a8.3,8.3,0,0,0-8.2.4l-24,16a8,8,0,0,0,8.8,13.4l11.6-7.8V200a8,8,0,0,0,16,0V108A8,8,0,0,0,223.8,100.9Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M228,108v92a8,8,0,0,1-16,0V122.9l-11.6,7.8a8,8,0,0,1-8.8-13.4l24-16a8.3,8.3,0,0,1,8.2-.4A8,8,0,0,1,228,108ZM144,48a8,8,0,0,0-8,8v52H48V56a8,8,0,0,0-16,0V176a8,8,0,0,0,16,0V124h88v52a8,8,0,0,0,16,0V56A8,8,0,0,0,144,48Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M150,56V176a6,6,0,0,1-12,0V122H46v54a6,6,0,0,1-12,0V56a6,6,0,0,1,12,0v54h92V56a6,6,0,0,1,12,0Zm72.8,46.7a6.1,6.1,0,0,0-6.1.3l-24,16a6,6,0,1,0,6.6,10l14.7-9.8V200a6,6,0,0,0,12,0V108A6,6,0,0,0,222.8,102.7Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M148,56V176a4,4,0,0,1-8,0V120H44v56a4,4,0,0,1-8,0V56a4,4,0,0,1,8,0v56h96V56a4,4,0,0,1,8,0Zm73.9,48.5a4,4,0,0,0-4.1.2l-24,16a3.8,3.8,0,0,0-1.1,5.5,4,4,0,0,0,5.5,1.1L216,115.5V200a4,4,0,0,0,8,0V108A3.9,3.9,0,0,0,221.9,104.5Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M152,56V176a8,8,0,0,1-16,0V124H48v52a8,8,0,0,1-16,0V56a8,8,0,0,1,16,0v52h88V56a8,8,0,0,1,16,0Zm71.8,44.9a8.3,8.3,0,0,0-8.2.4l-24,16a8,8,0,0,0,8.8,13.4l11.6-7.8V200a8,8,0,0,0,16,0V108A8,8,0,0,0,223.8,100.9Z" />
        </>
      )}
    </svg>
  );
}

export function PhTextHSix(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M156,56V176a12,12,0,0,1-24,0V128H52v48a12,12,0,0,1-24,0V56a12,12,0,0,1,24,0v48h80V56a12,12,0,0,1,24,0ZM256,168a40,40,0,0,1-80,0,40.6,40.6,0,0,1,5.1-19.6l.3-.6,32.3-54a12,12,0,1,1,20.6,12.4l-13.2,22.1A40.1,40.1,0,0,1,256,168Zm-24,0a16,16,0,1,0-16,16A16,16,0,0,0,232,168Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M152,56V176a8,8,0,0,1-16,0V124H48v52a8,8,0,0,1-16,0V56a8,8,0,0,1,16,0v52h88V56a8,8,0,0,1,16,0ZM252,168a36,36,0,1,1-67.4-17.5l.3-.6,32.2-54a8,8,0,0,1,13.8,8.2L214.2,132H216A36,36,0,0,1,252,168Zm-16,0a20,20,0,1,0-20,20A20.1,20.1,0,0,0,236,168Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M152,56V176a8,8,0,0,1-16,0V124H48v52a8,8,0,0,1-16,0V56a8,8,0,0,1,16,0v52h88V56a8,8,0,0,1,16,0ZM252,168a36,36,0,1,1-67.4-17.5l.3-.6,32.2-54a8,8,0,0,1,13.8,8.2L214.2,132H216A36,36,0,0,1,252,168Zm-16,0a20,20,0,1,0-20,20A20.1,20.1,0,0,0,236,168Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M150,56V176a6,6,0,0,1-12,0V122H46v54a6,6,0,0,1-12,0V56a6,6,0,0,1,12,0v54h92V56a6,6,0,0,1,12,0ZM250,168a34,34,0,1,1-63.7-16.5l.3-.6,32.2-54a6.1,6.1,0,0,1,10.4,6.2l-18.8,31.4A33.8,33.8,0,0,1,250,168Zm-12,0a22,22,0,1,0-22,22A22.1,22.1,0,0,0,238,168Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M148,56V176a4,4,0,0,1-8,0V120H44v56a4,4,0,0,1-8,0V56a4,4,0,0,1,8,0v56h96V56a4,4,0,0,1,8,0ZM248,168a32,32,0,0,1-64,0,31.1,31.1,0,0,1,4.2-15.8.4.4,0,0,0,.1-.3l32.3-54a4,4,0,1,1,6.8,4.2l-21.2,35.4A32.1,32.1,0,0,1,248,168Zm-8,0a24,24,0,1,0-24,24A24.1,24.1,0,0,0,240,168Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M152,56V176a8,8,0,0,1-16,0V124H48v52a8,8,0,0,1-16,0V56a8,8,0,0,1,16,0v52h88V56a8,8,0,0,1,16,0ZM252,168a36,36,0,1,1-67.4-17.5l.3-.6,32.2-54a8,8,0,0,1,13.8,8.2L214.2,132H216A36,36,0,0,1,252,168Zm-16,0a20,20,0,1,0-20,20A20.1,20.1,0,0,0,236,168Z" />
        </>
      )}
    </svg>
  );
}

export function PhTextHThree(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M156,56V176a12,12,0,0,1-24,0V128H52v48a12,12,0,0,1-24,0V56a12,12,0,0,1,24,0v48h80V56a12,12,0,0,1,24,0Zm84.3,91.7a39.7,39.7,0,0,0-8.8-6.6l18.3-26.2A12,12,0,0,0,240,96H192a12,12,0,0,0,0,24h25l-14.8,21.1A12,12,0,0,0,212,160a16,16,0,1,1-11.3,27.3,12,12,0,0,0-17,17,40,40,0,0,0,56.6-56.6Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M152,56V176a8,8,0,0,1-16,0V124H48v52a8,8,0,0,1-16,0V56a8,8,0,0,1,16,0v52h88V56a8,8,0,0,1,16,0Zm85.5,94.5a37.9,37.9,0,0,0-12-7.9l21.1-30a8,8,0,0,0,.5-8.3A8.1,8.1,0,0,0,240,100H192a8,8,0,0,0,0,16h32.6l-19.2,27.4a8,8,0,0,0-.5,8.3A8.1,8.1,0,0,0,212,156a20,20,0,1,1-14.1,34.1,8.1,8.1,0,0,0-11.4,11.4,36.1,36.1,0,0,0,51-51Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M152,56V176a8,8,0,0,1-16,0V124H48v52a8,8,0,0,1-16,0V56a8,8,0,0,1,16,0v52h88V56a8,8,0,0,1,16,0Zm85.5,94.5a37.9,37.9,0,0,0-12-7.9l21.1-30a8,8,0,0,0,.5-8.3A8.1,8.1,0,0,0,240,100H192a8,8,0,0,0,0,16h32.6l-19.2,27.4a8,8,0,0,0-.5,8.3A8.1,8.1,0,0,0,212,156a20,20,0,1,1-14.1,34.1,8.1,8.1,0,0,0-11.4,11.4,36.1,36.1,0,0,0,51-51Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M150,56V176a6,6,0,0,1-12,0V122H46v54a6,6,0,0,1-12,0V56a6,6,0,0,1,12,0v54h92V56a6,6,0,0,1,12,0Zm86,96a32.8,32.8,0,0,0-13.6-8.4l22.5-32.2a5.8,5.8,0,0,0,.4-6.2A5.9,5.9,0,0,0,240,102H192a6,6,0,0,0,0,12h36.5l-21.4,30.6a5.8,5.8,0,0,0-.4,6.2A5.9,5.9,0,0,0,212,154a22,22,0,1,1-15.6,37.6A5.9,5.9,0,0,0,188,200a33.9,33.9,0,1,0,48-48Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M148,56V176a4,4,0,0,1-8,0V120H44v56a4,4,0,0,1-8,0V56a4,4,0,0,1,8,0v56h96V56a4,4,0,0,1,8,0Zm86.6,97.4a30.7,30.7,0,0,0-15.5-8.6l24.2-34.5a4.2,4.2,0,0,0,.2-4.2A3.8,3.8,0,0,0,240,104H192a4,4,0,0,0,0,8h40.3l-23.6,33.7a4,4,0,0,0-.2,4.1A3.7,3.7,0,0,0,212,152a24,24,0,1,1-17,41,4,4,0,0,0-5.6,5.6,32,32,0,1,0,45.2-45.2Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M152,56V176a8,8,0,0,1-16,0V124H48v52a8,8,0,0,1-16,0V56a8,8,0,0,1,16,0v52h88V56a8,8,0,0,1,16,0Zm85.5,94.5a37.9,37.9,0,0,0-12-7.9l21.1-30a8,8,0,0,0,.5-8.3A8.1,8.1,0,0,0,240,100H192a8,8,0,0,0,0,16h32.6l-19.2,27.4a8,8,0,0,0-.5,8.3A8.1,8.1,0,0,0,212,156a20,20,0,1,1-14.1,34.1,8.1,8.1,0,0,0-11.4,11.4,36.1,36.1,0,0,0,51-51Z" />
        </>
      )}
    </svg>
  );
}

export function PhTextHTwo(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M156,56V176a12,12,0,0,1-24,0V128H52v48a12,12,0,0,1-24,0V56a12,12,0,0,1,24,0v48h80V56a12,12,0,0,1,24,0Zm84,132H216l29.5-39.4.3-.5A35.1,35.1,0,0,0,252,128a36,36,0,0,0-69.2-14,12,12,0,1,0,22.1,9.3A12,12,0,0,1,228,128a12.3,12.3,0,0,1-1.9,6.5l-43.6,58.1a12.1,12.1,0,0,0,5.7,18.8,12.6,12.6,0,0,0,3.8.6h48a12,12,0,0,0,0-24Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M152,56V176a8,8,0,0,1-16,0V124H48v52a8,8,0,0,1-16,0V56a8,8,0,0,1,16,0v52h88V56a8,8,0,0,1,16,0Zm88,136H208l34.3-45.8.2-.3A31.8,31.8,0,0,0,248,128a32,32,0,0,0-61.5-12.5,8,8,0,0,0,14.8,6.3A15.9,15.9,0,0,1,216,112a16,16,0,0,1,13.4,24.8l-43.7,58.3A7.5,7.5,0,0,0,184,200a8,8,0,0,0,5.5,7.6,7.3,7.3,0,0,0,2.5.4h48a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M152,56V176a8,8,0,0,1-16,0V124H48v52a8,8,0,0,1-16,0V56a8,8,0,0,1,16,0v52h88V56a8,8,0,0,1,16,0Zm88,136H208l34.3-45.8.2-.3A31.8,31.8,0,0,0,248,128a32,32,0,0,0-61.5-12.5,8,8,0,0,0,14.8,6.3A15.9,15.9,0,0,1,216,112a16,16,0,0,1,13.4,24.8l-43.7,58.3A7.5,7.5,0,0,0,184,200a8,8,0,0,0,5.5,7.6,7.3,7.3,0,0,0,2.5.4h48a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M150,56V176a6,6,0,0,1-12,0V122H46v54a6,6,0,0,1-12,0V56a6,6,0,0,1,12,0v54h92V56a6,6,0,0,1,12,0Zm90,138H204l36.7-49,.2-.2A30.4,30.4,0,0,0,246,128a30,30,0,0,0-57.6-11.7,6,6,0,1,0,11,4.7,18,18,0,0,1,34.6,7,17.2,17.2,0,0,1-3,9.9l-43.7,58.4A5.9,5.9,0,0,0,186,200a6,6,0,0,0,4.1,5.7,6.3,6.3,0,0,0,1.9.3h48a6,6,0,0,0,0-12Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M148,56V176a4,4,0,0,1-8,0V120H44v56a4,4,0,0,1-8,0V56a4,4,0,0,1,8,0v56h96V56a4,4,0,0,1,8,0Zm92,140H200l39.1-52.2h.1A28.4,28.4,0,0,0,244,128a28,28,0,0,0-53.8-10.9,3.9,3.9,0,0,0,2.1,5.2,4,4,0,0,0,5.3-2.1,20,20,0,1,1,35,18.9l-43.7,58.4a3.8,3.8,0,0,0-.9,2.5,4,4,0,0,0,2.7,3.8l1.3.2h48a4,4,0,0,0,0-8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M152,56V176a8,8,0,0,1-16,0V124H48v52a8,8,0,0,1-16,0V56a8,8,0,0,1,16,0v52h88V56a8,8,0,0,1,16,0Zm88,136H208l34.3-45.8.2-.3A31.8,31.8,0,0,0,248,128a32,32,0,0,0-61.5-12.5,8,8,0,0,0,14.8,6.3A15.9,15.9,0,0,1,216,112a16,16,0,0,1,13.4,24.8l-43.7,58.3A7.5,7.5,0,0,0,184,200a8,8,0,0,0,5.5,7.6,7.3,7.3,0,0,0,2.5.4h48a8,8,0,0,0,0-16Z" />
        </>
      )}
    </svg>
  );
}

export function PhTextH(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M212,56V200a12,12,0,0,1-24,0V140H68v60a12,12,0,0,1-24,0V56a12,12,0,0,1,24,0v60H188V56a12,12,0,0,1,24,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M208,56V200a8,8,0,0,1-16,0V136H64v64a8,8,0,0,1-16,0V56a8,8,0,0,1,16,0v64H192V56a8,8,0,0,1,16,0Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M208,56V200a8,8,0,0,1-16,0V136H64v64a8,8,0,0,1-16,0V56a8,8,0,0,1,16,0v64H192V56a8,8,0,0,1,16,0Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M206,56V200a6,6,0,0,1-12,0V134H62v66a6,6,0,0,1-12,0V56a6,6,0,0,1,12,0v66H194V56a6,6,0,0,1,12,0Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M204,56V200a4,4,0,0,1-8,0V132H60v68a4,4,0,0,1-8,0V56a4,4,0,0,1,8,0v68H196V56a4,4,0,0,1,8,0Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M208,56V200a8,8,0,0,1-16,0V136H64v64a8,8,0,0,1-16,0V56a8,8,0,0,1,16,0v64H192V56a8,8,0,0,1,16,0Z" />
        </>
      )}
    </svg>
  );
}

export function PhTextIndent(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M228,128a12,12,0,0,1-12,12H120a12,12,0,0,1,0-24h96A12,12,0,0,1,228,128ZM120,76h96a12,12,0,0,0,0-24H120a12,12,0,0,0,0,24Zm96,104H40a12,12,0,0,0,0,24H216a12,12,0,0,0,0-24ZM31.5,144.5a12.1,12.1,0,0,0,17,0l40-40a12,12,0,0,0,0-17l-40-40a12,12,0,0,0-17,17L63,96,31.5,127.5A12,12,0,0,0,31.5,144.5Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M224,128a8,8,0,0,1-8,8H112a8,8,0,0,1,0-16H216A8,8,0,0,1,224,128ZM112,72H216a8,8,0,0,0,0-16H112a8,8,0,0,0,0,16ZM216,184H40a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16ZM34.3,141.7a8.2,8.2,0,0,0,11.4,0l40-40a8.1,8.1,0,0,0,0-11.4l-40-40A8.1,8.1,0,0,0,34.3,61.7L68.7,96,34.3,130.3A8.1,8.1,0,0,0,34.3,141.7Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M224,128a8,8,0,0,1-8,8H120a8,8,0,0,1,0-16h96A8,8,0,0,1,224,128ZM120,72h96a8,8,0,0,0,0-16H120a8,8,0,0,0,0,16Zm96,112H40a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16ZM36.9,143.4a8.5,8.5,0,0,0,3.1.6,8.3,8.3,0,0,0,5.7-2.3l40-40a8.1,8.1,0,0,0,0-11.4l-40-40a8.4,8.4,0,0,0-8.8-1.7A8,8,0,0,0,32,56v80A8,8,0,0,0,36.9,143.4Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M222,128a6,6,0,0,1-6,6H112a6,6,0,0,1,0-12H216A6,6,0,0,1,222,128ZM112,70H216a6,6,0,0,0,0-12H112a6,6,0,0,0,0,12ZM216,186H40a6,6,0,0,0,0,12H216a6,6,0,0,0,0-12ZM35.8,140.2a5.8,5.8,0,0,0,8.4,0l40-40a5.8,5.8,0,0,0,0-8.4l-40-40a5.9,5.9,0,0,0-8.4,8.4L71.5,96,35.8,131.8A5.8,5.8,0,0,0,35.8,140.2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M220,128a4,4,0,0,1-4,4H112a4,4,0,0,1,0-8H216A4,4,0,0,1,220,128ZM112,68H216a4,4,0,0,0,0-8H112a4,4,0,0,0,0,8ZM216,188H40a4,4,0,0,0,0,8H216a4,4,0,0,0,0-8ZM37.2,138.8a3.9,3.9,0,0,0,5.6,0l40-40a3.9,3.9,0,0,0,0-5.6l-40-40a4,4,0,0,0-5.6,5.6L74.3,96,37.2,133.2A3.9,3.9,0,0,0,37.2,138.8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M224,128a8,8,0,0,1-8,8H112a8,8,0,0,1,0-16H216A8,8,0,0,1,224,128ZM112,72H216a8,8,0,0,0,0-16H112a8,8,0,0,0,0,16ZM216,184H40a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16ZM34.3,141.7a8.2,8.2,0,0,0,11.4,0l40-40a8.1,8.1,0,0,0,0-11.4l-40-40A8.1,8.1,0,0,0,34.3,61.7L68.7,96,34.3,130.3A8.1,8.1,0,0,0,34.3,141.7Z" />
        </>
      )}
    </svg>
  );
}

export function PhTextItalic(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M204,56a12,12,0,0,1-12,12H160.6l-40,120H144a12,12,0,0,1,0,24H64a12,12,0,0,1,0-24H95.3l40-120H112a12,12,0,0,1,0-24h80A12,12,0,0,1,204,56Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M200,56a8,8,0,0,1-8,8H157.8L115.1,192H144a8,8,0,0,1,0,16H64a8,8,0,0,1,0-16H98.2L140.9,64H112a8,8,0,0,1,0-16h80A8,8,0,0,1,200,56Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M200,56a8,8,0,0,1-8,8H157.8L115.1,192H144a8,8,0,0,1,0,16H64a8,8,0,0,1,0-16H98.2L140.9,64H112a8,8,0,0,1,0-16h80A8,8,0,0,1,200,56Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M198,56a6,6,0,0,1-6,6H156.3l-44,132H144a6,6,0,0,1,0,12H64a6,6,0,0,1,0-12H99.7l44-132H112a6,6,0,0,1,0-12h80A6,6,0,0,1,198,56Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M196,56a4,4,0,0,1-4,4H154.9L109.5,196H144a4,4,0,0,1,0,8H64a4,4,0,0,1,0-8h37.1L146.4,60H112a4,4,0,0,1,0-8h80A4,4,0,0,1,196,56Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M200,56a8,8,0,0,1-8,8H157.8L115.1,192H144a8,8,0,0,1,0,16H64a8,8,0,0,1,0-16H98.2L140.9,64H112a8,8,0,0,1,0-16h80A8,8,0,0,1,200,56Z" />
        </>
      )}
    </svg>
  );
}

export function PhTextOutdent(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M228,128a12,12,0,0,1-12,12H120a12,12,0,0,1,0-24h96A12,12,0,0,1,228,128ZM120,76h96a12,12,0,0,0,0-24H120a12,12,0,0,0,0,24Zm96,104H40a12,12,0,0,0,0,24H216a12,12,0,0,0,0-24ZM72,148a12.2,12.2,0,0,0,8.5-3.5,12,12,0,0,0,0-17L49,96,80.5,64.5a12,12,0,0,0-17-17l-40,40a12,12,0,0,0,0,17l40,40A12.2,12.2,0,0,0,72,148Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M224,128a8,8,0,0,1-8,8H112a8,8,0,0,1,0-16H216A8,8,0,0,1,224,128ZM112,72H216a8,8,0,0,0,0-16H112a8,8,0,0,0,0,16ZM216,184H40a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16ZM72,144a8.5,8.5,0,0,0,5.7-2.3,8.1,8.1,0,0,0,0-11.4L43.3,96,77.7,61.7A8.1,8.1,0,0,0,66.3,50.3l-40,40a8.1,8.1,0,0,0,0,11.4l40,40A8.5,8.5,0,0,0,72,144Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M112,64a8,8,0,0,1,8-8h96a8,8,0,0,1,0,16H120A8,8,0,0,1,112,64ZM72,144a8.5,8.5,0,0,0,3.1-.6A8,8,0,0,0,80,136V56a8,8,0,0,0-4.9-7.4,8.4,8.4,0,0,0-8.8,1.7l-40,40a8.1,8.1,0,0,0,0,11.4l40,40A8.3,8.3,0,0,0,72,144Zm144,40H40a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Zm0-64H120a8,8,0,0,0,0,16h96a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M222,128a6,6,0,0,1-6,6H112a6,6,0,0,1,0-12H216A6,6,0,0,1,222,128ZM112,70H216a6,6,0,0,0,0-12H112a6,6,0,0,0,0,12ZM216,186H40a6,6,0,0,0,0,12H216a6,6,0,0,0,0-12ZM72,142a6,6,0,0,0,4.2-10.2L40.5,96,76.2,60.2a5.9,5.9,0,0,0-8.4-8.4l-40,40a5.8,5.8,0,0,0,0,8.4l40,40A5.8,5.8,0,0,0,72,142Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M220,128a4,4,0,0,1-4,4H112a4,4,0,0,1,0-8H216A4,4,0,0,1,220,128ZM112,68H216a4,4,0,0,0,0-8H112a4,4,0,0,0,0,8ZM216,188H40a4,4,0,0,0,0,8H216a4,4,0,0,0,0-8ZM72,140a3.9,3.9,0,0,0,2.8-1.2,3.9,3.9,0,0,0,0-5.6L37.7,96,74.8,58.8a4,4,0,0,0-5.6-5.6l-40,40a3.9,3.9,0,0,0,0,5.6l40,40A3.9,3.9,0,0,0,72,140Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M224,128a8,8,0,0,1-8,8H112a8,8,0,0,1,0-16H216A8,8,0,0,1,224,128ZM112,72H216a8,8,0,0,0,0-16H112a8,8,0,0,0,0,16ZM216,184H40a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16ZM72,144a8.5,8.5,0,0,0,5.7-2.3,8.1,8.1,0,0,0,0-11.4L43.3,96,77.7,61.7A8.1,8.1,0,0,0,66.3,50.3l-40,40a8.1,8.1,0,0,0,0,11.4l40,40A8.5,8.5,0,0,0,72,144Z" />
        </>
      )}
    </svg>
  );
}

export function PhTextStrikethrough(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M228,128a12,12,0,0,1-12,12H185.9A41.8,41.8,0,0,1,196,168c0,14.5-7.8,28.3-21.4,38s-29.2,14-46.6,14-34-5-46.6-14S60,182.5,60,168a12,12,0,0,1,24,0c0,15.2,20.1,28,44,28s44-12.8,44-28c0-12.8-9.3-20.2-35.3-28H40a12,12,0,0,1,0-24H216A12,12,0,0,1,228,128ZM75.1,100a12,12,0,0,0,12-12c0-16,17.6-28,40.9-28,18,0,33.2,7.4,38.7,18.8a12,12,0,0,0,21.6-10.5C178.6,48.4,155.5,36,128,36,91,36,63.1,58.4,63.1,88A12,12,0,0,0,75.1,100Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M224,128a8,8,0,0,1-8,8H175.9c9.2,7.1,16.1,17.2,16.1,32s-7,25.7-19.8,34.8S144.6,216,128,216s-32.3-4.7-44.2-13.2S64,181.3,64,168a8,8,0,0,1,16,0c0,17.3,22,32,48,32s48-14.7,48-32c0-14.9-10.5-23.6-38.8-32H40a8,8,0,0,1,0-16H216A8,8,0,0,1,224,128ZM76.3,104a6.9,6.9,0,0,0,2.5-.4,8,8,0,0,0,5.1-10.1,19.2,19.2,0,0,1-.8-5.5c0-18.2,19.3-32,44.9-32,19.5,0,36.1,8.3,42.3,21A8.1,8.1,0,0,0,181,80.7,7.9,7.9,0,0,0,184.7,70c-9-18.5-30.7-30-56.7-30C93.3,40,67.1,60.6,67.1,88a36,36,0,0,0,1.6,10.5A8,8,0,0,0,76.3,104Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M224,128a8,8,0,0,1-8,8H175.9c9.2,7.1,16.1,17.2,16.1,32s-7,25.7-19.8,34.8S144.6,216,128,216s-32.3-4.7-44.2-13.2S64,181.3,64,168a8,8,0,0,1,16,0c0,17.3,22,32,48,32s48-14.7,48-32c0-14.9-10.5-23.6-38.8-32H40a8,8,0,0,1,0-16H216A8,8,0,0,1,224,128ZM76.3,104a6.9,6.9,0,0,0,2.5-.4,8,8,0,0,0,5.1-10.1,19.2,19.2,0,0,1-.8-5.5c0-18.2,19.3-32,44.9-32,19.5,0,36.1,8.3,42.3,21A8.1,8.1,0,0,0,181,80.7,7.9,7.9,0,0,0,184.7,70c-9-18.5-30.7-30-56.7-30C93.3,40,67.1,60.6,67.1,88a36,36,0,0,0,1.6,10.5A8,8,0,0,0,76.3,104Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M222,128a6,6,0,0,1-6,6H169.4c11.3,6.9,20.6,17.4,20.6,34,0,25.4-27.8,46-62,46s-62-20.6-62-46a6,6,0,0,1,12,0c0,18.7,22.4,34,50,34s50-15.3,50-34-15.5-26.6-40.5-34H40a6,6,0,0,1,0-12H216A6,6,0,0,1,222,128ZM76.3,102a5.8,5.8,0,0,0,1.9-.3A5.9,5.9,0,0,0,82,94.1a19.5,19.5,0,0,1-.9-6.1c0-19.4,20.2-34,46.9-34,20.3,0,37.6,8.7,44.1,22.1a6.1,6.1,0,0,0,8.1,2.8,6,6,0,0,0,2.7-8C174.2,53.1,153.2,42,128,42,94.4,42,69.1,61.8,69.1,88a32.1,32.1,0,0,0,1.5,9.9A6.1,6.1,0,0,0,76.3,102Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M220,128a4,4,0,0,1-4,4H161.2c15.4,7.2,26.8,17.8,26.8,36,0,24.3-26.9,44-60,44s-60-19.7-60-44a4,4,0,0,1,8,0c0,19.9,23.3,36,52,36s52-16.1,52-36-16.1-28.3-42.2-36H40a4,4,0,0,1,0-8H216A4,4,0,0,1,220,128ZM76.3,100l1.3-.2a3.9,3.9,0,0,0,2.5-5,24.8,24.8,0,0,1-1-6.8c0-20.5,21-36,48.9-36,21.3,0,38.9,8.9,45.9,23.3a4,4,0,0,0,5.4,1.8,3.9,3.9,0,0,0,1.8-5.3C172.8,54.6,152.4,44,128,44,95.6,44,71.1,62.9,71.1,88a30.1,30.1,0,0,0,1.4,9.2A4.2,4.2,0,0,0,76.3,100Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M224,128a8,8,0,0,1-8,8H175.9c9.2,7.1,16.1,17.2,16.1,32s-7,25.7-19.8,34.8S144.6,216,128,216s-32.3-4.7-44.2-13.2S64,181.3,64,168a8,8,0,0,1,16,0c0,17.3,22,32,48,32s48-14.7,48-32c0-14.9-10.5-23.6-38.8-32H40a8,8,0,0,1,0-16H216A8,8,0,0,1,224,128ZM76.3,104a6.9,6.9,0,0,0,2.5-.4,8,8,0,0,0,5.1-10.1,19.2,19.2,0,0,1-.8-5.5c0-18.2,19.3-32,44.9-32,19.5,0,36.1,8.3,42.3,21A8.1,8.1,0,0,0,181,80.7,7.9,7.9,0,0,0,184.7,70c-9-18.5-30.7-30-56.7-30C93.3,40,67.1,60.6,67.1,88a36,36,0,0,0,1.6,10.5A8,8,0,0,0,76.3,104Z" />
        </>
      )}
    </svg>
  );
}

export function PhTextT(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M220,56V88a12,12,0,0,1-24,0V68H140V188h20a12,12,0,0,1,0,24H96a12,12,0,0,1,0-24h20V68H60V88a12,12,0,0,1-24,0V56A12,12,0,0,1,48,44H208A12,12,0,0,1,220,56Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M216,56V88a8,8,0,0,1-16,0V64H136V192h24a8,8,0,0,1,0,16H96a8,8,0,0,1,0-16h24V64H56V88a8,8,0,0,1-16,0V56a8,8,0,0,1,8-8H208A8,8,0,0,1,216,56Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,56V88a8,8,0,0,1-16,0V64H136V192h24a8,8,0,0,1,0,16H96a8,8,0,0,1,0-16h24V64H56V88a8,8,0,0,1-16,0V56a8,8,0,0,1,8-8H208A8,8,0,0,1,216,56Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M214,56V88a6,6,0,0,1-12,0V62H134V194h26a6,6,0,0,1,0,12H96a6,6,0,0,1,0-12h26V62H54V88a6,6,0,0,1-12,0V56a6,6,0,0,1,6-6H208A6,6,0,0,1,214,56Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M212,56V88a4,4,0,0,1-8,0V60H132V196h28a4,4,0,0,1,0,8H96a4,4,0,0,1,0-8h28V60H52V88a4,4,0,0,1-8,0V56a4,4,0,0,1,4-4H208A4,4,0,0,1,212,56Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,56V88a8,8,0,0,1-16,0V64H136V192h24a8,8,0,0,1,0,16H96a8,8,0,0,1,0-16h24V64H56V88a8,8,0,0,1-16,0V56a8,8,0,0,1,8-8H208A8,8,0,0,1,216,56Z" />
        </>
      )}
    </svg>
  );
}

export function PhTextUnderline(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M228,216a12,12,0,0,1-12,12H40a12,12,0,0,1,0-24H216A12,12,0,0,1,228,216ZM128,192a76.1,76.1,0,0,0,76-76V56a12,12,0,0,0-24,0v60a52,52,0,0,1-104,0V56a12,12,0,0,0-24,0v60A76.1,76.1,0,0,0,128,192Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M224,216a8,8,0,0,1-8,8H40a8,8,0,0,1,0-16H216A8,8,0,0,1,224,216Zm-96-24a72.1,72.1,0,0,0,72-72V56a8,8,0,0,0-16,0v64a56,56,0,0,1-112,0V56a8,8,0,0,0-16,0v64A72.1,72.1,0,0,0,128,192Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M56,120V56a8,8,0,0,1,16,0v64a56,56,0,0,0,112,0V56a8,8,0,0,1,16,0v64a72,72,0,0,1-144,0Zm160,88H40a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M222,216a6,6,0,0,1-6,6H40a6,6,0,0,1,0-12H216A6,6,0,0,1,222,216Zm-94-26a70.1,70.1,0,0,0,70-70V56a6,6,0,0,0-12,0v64a58,58,0,0,1-116,0V56a6,6,0,0,0-12,0v64A70.1,70.1,0,0,0,128,190Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M220,216a4,4,0,0,1-4,4H40a4,4,0,0,1,0-8H216A4,4,0,0,1,220,216Zm-92-28a68.1,68.1,0,0,0,68-68V56a4,4,0,0,0-8,0v64a60,60,0,0,1-120,0V56a4,4,0,0,0-8,0v64A68.1,68.1,0,0,0,128,188Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M224,216a8,8,0,0,1-8,8H40a8,8,0,0,1,0-16H216A8,8,0,0,1,224,216Zm-96-24a72.1,72.1,0,0,0,72-72V56a8,8,0,0,0-16,0v64a56,56,0,0,1-112,0V56a8,8,0,0,0-16,0v64A72.1,72.1,0,0,0,128,192Z" />
        </>
      )}
    </svg>
  );
}

export function PhTextbox(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M112,36a12,12,0,0,0-12,12V60H24A20.1,20.1,0,0,0,4,80v96a20.1,20.1,0,0,0,20,20h76v12a12,12,0,0,0,24,0V48A12,12,0,0,0,112,36ZM28,172V84h72v88ZM252,80v96a20.1,20.1,0,0,1-20,20H152a12,12,0,0,1,0-24h76V84H152a12,12,0,0,1,0-24h80A20.1,20.1,0,0,1,252,80ZM90,112a12,12,0,0,1-12,12H76v24a12,12,0,0,1-24,0V124H50a12,12,0,0,1,0-24H78A12,12,0,0,1,90,112Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M240,80v96a8,8,0,0,1-8,8H24a8,8,0,0,1-8-8V80a8,8,0,0,1,8-8H232A8,8,0,0,1,240,80Z"
            opacity="0.2"
          />
          <path d="M112,40a8,8,0,0,0-8,8V64H24A16,16,0,0,0,8,80v96a16,16,0,0,0,16,16h80v16a8,8,0,0,0,16,0V48A8,8,0,0,0,112,40ZM24,176V80h80v96ZM248,80v96a16,16,0,0,1-16,16H144a8,8,0,0,1,0-16h88V80H144a8,8,0,0,1,0-16h88A16,16,0,0,1,248,80ZM86,112a8,8,0,0,1-8,8H72v28a8,8,0,0,1-16,0V120H50a8,8,0,0,1,0-16H78A8,8,0,0,1,86,112Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M248,80v96a16,16,0,0,1-16,16H144a8,8,0,0,1-8-8V72a8,8,0,0,1,8-8h88A16,16,0,0,1,248,80ZM120,48V208a8,8,0,0,1-16,0V192H24A16,16,0,0,1,8,176V80A16,16,0,0,1,24,64h80V48a8,8,0,0,1,16,0ZM86,112a8,8,0,0,0-8-8H50a8,8,0,0,0,0,16h6v28a8,8,0,0,0,16,0V120h6A8,8,0,0,0,86,112Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M112,42a6,6,0,0,0-6,6V66H24A14,14,0,0,0,10,80v96a14,14,0,0,0,14,14h82v18a6,6,0,0,0,12,0V48A6,6,0,0,0,112,42ZM24,178a2,2,0,0,1-2-2V80a2,2,0,0,1,2-2h82V178ZM246,80v96a14,14,0,0,1-14,14H144a6,6,0,0,1,0-12h88a2,2,0,0,0,2-2V80a2,2,0,0,0-2-2H144a6,6,0,0,1,0-12h88A14,14,0,0,1,246,80ZM84,112a6,6,0,0,1-6,6H70v30a6,6,0,0,1-12,0V118H50a6,6,0,0,1,0-12H78A6,6,0,0,1,84,112Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M112,44a4,4,0,0,0-4,4V68H24A12,12,0,0,0,12,80v96a12,12,0,0,0,12,12h84v20a4,4,0,0,0,8,0V48A4,4,0,0,0,112,44ZM24,180a4,4,0,0,1-4-4V80a4,4,0,0,1,4-4h84V180ZM244,80v96a12,12,0,0,1-12,12H144a4,4,0,0,1,0-8h88a4,4,0,0,0,4-4V80a4,4,0,0,0-4-4H144a4,4,0,0,1,0-8h88A12,12,0,0,1,244,80ZM82,112a4,4,0,0,1-4,4H68v32a4,4,0,0,1-8,0V116H50a4,4,0,0,1,0-8H78A4,4,0,0,1,82,112Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M112,40a8,8,0,0,0-8,8V64H24A16,16,0,0,0,8,80v96a16,16,0,0,0,16,16h80v16a8,8,0,0,0,16,0V48A8,8,0,0,0,112,40ZM24,176V80h80v96ZM248,80v96a16,16,0,0,1-16,16H144a8,8,0,0,1,0-16h88V80H144a8,8,0,0,1,0-16h88A16,16,0,0,1,248,80ZM86,112a8,8,0,0,1-8,8H72v28a8,8,0,0,1-16,0V120H50a8,8,0,0,1,0-16H78A8,8,0,0,1,86,112Z" />
        </>
      )}
    </svg>
  );
}

export function PhThermometerCold(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M132,188a24,24,0,1,1-36-20.8V120a12,12,0,0,1,24,0v47.2A24,24,0,0,1,132,188ZM253.8,66.4a12,12,0,0,0-15.1-7.7L224,63.5V48a12,12,0,0,0-24,0V63.5l-14.7-4.8a12,12,0,1,0-7.4,22.8l14.7,4.8-9.1,12.5a12.1,12.1,0,0,0,2.6,16.8,12.5,12.5,0,0,0,7.1,2.3,11.8,11.8,0,0,0,9.7-5l9.1-12.5,9.1,12.5a11.8,11.8,0,0,0,9.7,5,12.5,12.5,0,0,0,7.1-2.3,12.1,12.1,0,0,0,2.6-16.8l-9.1-12.5,14.7-4.8A11.9,11.9,0,0,0,253.8,66.4ZM160,150.7a64,64,0,1,1-104,0V56a52,52,0,0,1,104,0ZM148,188a40.6,40.6,0,0,0-9.2-25.6,11.7,11.7,0,0,1-2.8-7.6V56a28,28,0,0,0-56,0v98.8a12.1,12.1,0,0,1-2.9,7.8A40,40,0,1,0,148,188Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M244.9,77.7l-20,6.5,12.4,17a8,8,0,0,1-1.8,11.2,8.1,8.1,0,0,1-11.2-1.8L212,93.6l-12.3,17a8.1,8.1,0,0,1-11.2,1.8,8,8,0,0,1-1.8-11.2l12.4-17-20-6.5A7.9,7.9,0,0,1,174,67.6a7.8,7.8,0,0,1,10-5.1L204,69V48a8,8,0,0,1,16,0V69l20-6.5a7.8,7.8,0,0,1,10,5.1A7.9,7.9,0,0,1,244.9,77.7ZM148,188a28,28,0,1,1-36-26.8V120a8,8,0,0,1,16,0v41.2A27.9,27.9,0,0,1,148,188Zm-16,0a12,12,0,1,0-12,12A12,12,0,0,0,132,188Zm48,0a60,60,0,0,1-120,0,59.4,59.4,0,0,1,20-44.7V48a40,40,0,0,1,80,0v95.3A59.4,59.4,0,0,1,180,188Zm-16,0a43.8,43.8,0,0,0-16.7-34.5A8.2,8.2,0,0,1,144,147V48a24,24,0,0,0-48,0v99a8.1,8.1,0,0,1-3.4,6.6A43.5,43.5,0,0,0,76,188a44,44,0,0,0,88,0Z" />
          <g opacity="0.2">
            <path d="M152,147h0V48a32,32,0,0,0-64,0v99h0a52,52,0,1,0,64,0Zm-32,61a20,20,0,1,1,20-20A20.1,20.1,0,0,1,120,208Z" />
          </g>
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M244.9,77.7l-20,6.5,12.4,17a8,8,0,0,1-1.8,11.2,8.1,8.1,0,0,1-11.2-1.8L212,93.6l-12.3,17a8.1,8.1,0,0,1-11.2,1.8,8,8,0,0,1-1.8-11.2l12.4-17-20-6.5A7.9,7.9,0,0,1,174,67.6a7.8,7.8,0,0,1,10-5.1L204,69V48a8,8,0,0,1,16,0V69l20-6.5a7.8,7.8,0,0,1,10,5.1A7.9,7.9,0,0,1,244.9,77.7ZM180,188a60,60,0,0,1-120,0,59.4,59.4,0,0,1,20-44.7V48a40,40,0,0,1,80,0v95.3A59.4,59.4,0,0,1,180,188ZM96,48v56h48V48a24,24,0,0,0-48,0Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M126,162.7V120a6,6,0,0,0-12,0v42.7a26,26,0,1,0,12,0ZM120,202a14,14,0,1,1,14-14A14,14,0,0,1,120,202ZM244.3,75.8l-22.6,7.4,14,19.2a5.9,5.9,0,0,1-1.4,8.3,5.7,5.7,0,0,1-3.5,1.2,5.9,5.9,0,0,1-4.8-2.5L212,90.2l-14,19.2a5.9,5.9,0,0,1-4.8,2.5,5.7,5.7,0,0,1-3.5-1.2,5.9,5.9,0,0,1-1.4-8.3l14-19.2-22.6-7.4a6,6,0,0,1,3.7-11.4L206,71.7V48a6,6,0,0,1,12,0V71.7l22.6-7.3a6,6,0,0,1,3.7,11.4ZM158,144.2V48a38,38,0,0,0-76,0v96.2a58,58,0,1,0,76,0ZM120,234a46,46,0,0,1-28.6-82,6.1,6.1,0,0,0,2.6-5V48a26,26,0,0,1,52,0v99a6.1,6.1,0,0,0,2.5,4.9A46,46,0,0,1,120,234Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M124,164.3V120a4,4,0,0,0-8,0v44.3a24,24,0,1,0,8,0ZM120,204a16,16,0,1,1,16-16A16,16,0,0,1,120,204ZM243.7,73.9l-25.2,8.2L234,103.5a4.1,4.1,0,0,1-.8,5.6,4.3,4.3,0,0,1-2.4.8,4,4,0,0,1-3.2-1.7L212,86.8l-15.6,21.4a4,4,0,0,1-3.2,1.7,4.3,4.3,0,0,1-2.4-.8,4.1,4.1,0,0,1-.8-5.6l15.5-21.4-25.2-8.2a4,4,0,0,1,2.5-7.6L208,74.5V48a4,4,0,0,1,8,0V74.5l25.2-8.2a4,4,0,0,1,2.5,7.6ZM156,145.1V48a36,36,0,0,0-72,0v97.1a56,56,0,1,0,72,0ZM120,236a48,48,0,0,1-48-48,47.4,47.4,0,0,1,18.2-37.6A4.2,4.2,0,0,0,92,147V48a28,28,0,0,1,56,0v99a4,4,0,0,0,1.7,3.3A48,48,0,0,1,120,236Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,161.2V120a8,8,0,0,0-16,0v41.2a28,28,0,1,0,16,0ZM120,200a12,12,0,1,1,12-12A12,12,0,0,1,120,200ZM244.9,77.7l-20,6.5,12.4,17a8,8,0,0,1-1.8,11.2,8.1,8.1,0,0,1-11.2-1.8L212,93.6l-12.3,17a8.1,8.1,0,0,1-11.2,1.8,8,8,0,0,1-1.8-11.2l12.4-17-20-6.5A7.9,7.9,0,0,1,174,67.6a7.8,7.8,0,0,1,10-5.1L204,69V48a8,8,0,0,1,16,0V69l20-6.5a7.8,7.8,0,0,1,10,5.1A7.9,7.9,0,0,1,244.9,77.7ZM160,143.3V48a40,40,0,0,0-80,0v95.3A59.4,59.4,0,0,0,60,188a60,60,0,0,0,120,0A59.4,59.4,0,0,0,160,143.3ZM120,232a44,44,0,0,1-44-44,43.5,43.5,0,0,1,16.6-34.4A8.1,8.1,0,0,0,96,147V48a24,24,0,0,1,48,0v99a8.2,8.2,0,0,0,3.3,6.5A44,44,0,0,1,120,232Z" />
        </>
      )}
    </svg>
  );
}

export function PhThermometerHot(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M132,188a24,24,0,1,1-36-20.8V56a12,12,0,0,1,24,0V167.2A24,24,0,0,1,132,188Zm60.2-99.5a7.9,7.9,0,0,1,11.3,0,32.2,32.2,0,0,0,45.3,0,12,12,0,0,0-17-17,7.9,7.9,0,0,1-11.3,0,32,32,0,0,0-45.3,0,12,12,0,0,0,17,17Zm39.6,23a7.9,7.9,0,0,1-11.3,0,32,32,0,0,0-45.3,0,12,12,0,0,0,17,17,7.9,7.9,0,0,1,11.3,0,32.2,32.2,0,0,0,45.3,0,12,12,0,0,0-17-17ZM160,150.7a64,64,0,1,1-104,0V56a52,52,0,0,1,104,0ZM148,188a40.6,40.6,0,0,0-9.2-25.6,11.7,11.7,0,0,1-2.8-7.6V56a28,28,0,0,0-56,0v98.8a12.1,12.1,0,0,1-2.9,7.8A40,40,0,1,0,148,188Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M128,161.2V48a8,8,0,0,0-16,0V161.2a28,28,0,1,0,16,0ZM120,200a12,12,0,1,1,12-12A12,12,0,0,1,120,200ZM178.1,85.7a8,8,0,0,1,0-11.4,28,28,0,0,1,19.8-8.2,28.4,28.4,0,0,1,19.8,8.2,11.9,11.9,0,0,0,16.9,0,8,8,0,0,1,11.3,11.4,28,28,0,0,1-19.8,8.2,28.4,28.4,0,0,1-19.8-8.2,11.9,11.9,0,0,0-16.9,0A8,8,0,0,1,178.1,85.7Zm67.8,28.6a8,8,0,0,1,0,11.4,28,28,0,0,1-19.8,8.2,28.4,28.4,0,0,1-19.8-8.2,11.9,11.9,0,0,0-16.9,0,8,8,0,0,1-11.3-11.4,28,28,0,0,1,19.8-8.2,28.4,28.4,0,0,1,19.8,8.2,11.9,11.9,0,0,0,16.9,0A8,8,0,0,1,245.9,114.3Zm-85.9,29V48a40,40,0,0,0-80,0v95.3A59.4,59.4,0,0,0,60,188a60,60,0,0,0,120,0A59.4,59.4,0,0,0,160,143.3ZM120,232a44,44,0,0,1-44-44,43.5,43.5,0,0,1,16.6-34.4A8.1,8.1,0,0,0,96,147V48a24,24,0,0,1,48,0v99a8.2,8.2,0,0,0,3.3,6.5A44,44,0,0,1,120,232Z" />
          <g opacity="0.2">
            <path d="M152,147h0V48a32,32,0,0,0-64,0v99h0a52,52,0,1,0,64,0Zm-32,61a20,20,0,1,1,20-20A20.1,20.1,0,0,1,120,208Z" />
          </g>
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M160,143.3V48a40,40,0,0,0-80,0v95.3A59.4,59.4,0,0,0,60,188a60,60,0,0,0,120,0A59.4,59.4,0,0,0,160,143.3ZM120,24a24.1,24.1,0,0,1,24,24v8H96V48A24.1,24.1,0,0,1,120,24Zm58.1,61.7a8,8,0,0,1,0-11.4,28,28,0,0,1,19.8-8.2,28.4,28.4,0,0,1,19.8,8.2,11.9,11.9,0,0,0,16.9,0,8,8,0,0,1,11.3,11.4,28,28,0,0,1-19.8,8.2,28.4,28.4,0,0,1-19.8-8.2,11.9,11.9,0,0,0-16.9,0A8,8,0,0,1,178.1,85.7Zm67.8,28.6a8,8,0,0,1,0,11.4,28,28,0,0,1-19.8,8.2,28.4,28.4,0,0,1-19.8-8.2,11.9,11.9,0,0,0-16.9,0,8,8,0,0,1-11.3-11.4,28,28,0,0,1,19.8-8.2,28.4,28.4,0,0,1,19.8,8.2,11.9,11.9,0,0,0,16.9,0A8,8,0,0,1,245.9,114.3Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M126,162.7V48a6,6,0,0,0-12,0V162.7a26,26,0,1,0,12,0ZM120,202a14,14,0,1,1,14-14A14,14,0,0,1,120,202ZM179.5,84.2a5.8,5.8,0,0,1,0-8.4,25.8,25.8,0,0,1,36.7,0,14.1,14.1,0,0,0,19.8,0,5.9,5.9,0,0,1,8.5,0,5.8,5.8,0,0,1,0,8.4,26,26,0,0,1-36.7,0,14.1,14.1,0,0,0-19.8,0A5.9,5.9,0,0,1,179.5,84.2Zm65,31.6a5.8,5.8,0,0,1,0,8.4,26,26,0,0,1-36.7,0,14.1,14.1,0,0,0-19.8,0,5.9,5.9,0,0,1-8.5,0,5.8,5.8,0,0,1,0-8.4,25.8,25.8,0,0,1,36.7,0,14.1,14.1,0,0,0,19.8,0A5.9,5.9,0,0,1,244.5,115.8ZM158,144.2V48a38,38,0,0,0-76,0v96.2a58,58,0,1,0,76,0ZM120,234a46,46,0,0,1-28.6-82,6.1,6.1,0,0,0,2.6-5V48a26,26,0,0,1,52,0v99a6.1,6.1,0,0,0,2.5,4.9A46,46,0,0,1,120,234Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M124,164.3V48a4,4,0,0,0-8,0V164.3a24,24,0,1,0,8,0ZM120,204a16,16,0,1,1,16-16A16,16,0,0,1,120,204ZM180.9,82.8a3.9,3.9,0,0,1,0-5.6,23.9,23.9,0,0,1,33.9,0,16.2,16.2,0,0,0,22.7,0,4,4,0,1,1,5.6,5.6,24,24,0,0,1-33.9,0,16.2,16.2,0,0,0-22.7,0A3.9,3.9,0,0,1,180.9,82.8Zm62.2,34.4a3.9,3.9,0,0,1,0,5.6,24,24,0,0,1-33.9,0,16.2,16.2,0,0,0-22.7,0,4,4,0,1,1-5.6-5.6,23.9,23.9,0,0,1,33.9,0,16.2,16.2,0,0,0,22.7,0A3.9,3.9,0,0,1,243.1,117.2ZM156,145.1V48a36,36,0,0,0-72,0v97.1a56,56,0,1,0,72,0ZM120,236a48,48,0,0,1-48-48,47.4,47.4,0,0,1,18.2-37.6A4.2,4.2,0,0,0,92,147V48a28,28,0,0,1,56,0v99a4,4,0,0,0,1.7,3.3A48,48,0,0,1,120,236Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,161.2V48a8,8,0,0,0-16,0V161.2a28,28,0,1,0,16,0ZM120,200a12,12,0,1,1,12-12A12,12,0,0,1,120,200ZM178.1,85.7a8,8,0,0,1,0-11.4,28,28,0,0,1,19.8-8.2,28.4,28.4,0,0,1,19.8,8.2,11.9,11.9,0,0,0,16.9,0,8,8,0,0,1,11.3,11.4,28,28,0,0,1-19.8,8.2,28.4,28.4,0,0,1-19.8-8.2,11.9,11.9,0,0,0-16.9,0A8,8,0,0,1,178.1,85.7Zm67.8,28.6a8,8,0,0,1,0,11.4,28,28,0,0,1-19.8,8.2,28.4,28.4,0,0,1-19.8-8.2,11.9,11.9,0,0,0-16.9,0,8,8,0,0,1-11.3-11.4,28,28,0,0,1,19.8-8.2,28.4,28.4,0,0,1,19.8,8.2,11.9,11.9,0,0,0,16.9,0A8,8,0,0,1,245.9,114.3Zm-85.9,29V48a40,40,0,0,0-80,0v95.3A59.4,59.4,0,0,0,60,188a60,60,0,0,0,120,0A59.4,59.4,0,0,0,160,143.3ZM120,232a44,44,0,0,1-44-44,43.5,43.5,0,0,1,16.6-34.4A8.1,8.1,0,0,0,96,147V48a24,24,0,0,1,48,0v99a8.2,8.2,0,0,0,3.3,6.5A44,44,0,0,1,120,232Z" />
        </>
      )}
    </svg>
  );
}

export function PhThermometerSimple(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M152,188a24,24,0,1,1-36-20.8V92a12,12,0,0,1,24,0v75.2A24,24,0,0,1,152,188Zm40,0A64,64,0,1,1,76,150.7V56a52,52,0,0,1,104,0v94.7A63.8,63.8,0,0,1,192,188Zm-24,0a40.6,40.6,0,0,0-9.2-25.6,11.7,11.7,0,0,1-2.8-7.6V56a28,28,0,0,0-56,0v98.8a12.1,12.1,0,0,1-2.9,7.8A40,40,0,1,0,168,188Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M160,147h0V48a32,32,0,0,0-64,0v99h0a52,52,0,1,0,64,0Zm-32,61a20,20,0,1,1,20-20A20.1,20.1,0,0,1,128,208Z" />
          </g>
          <path d="M168,143.3V48a40,40,0,0,0-80,0v95.3A59.4,59.4,0,0,0,68,188a60,60,0,0,0,120,0A59.4,59.4,0,0,0,168,143.3ZM128,232a44,44,0,0,1-44-44,43.5,43.5,0,0,1,16.6-34.4A8.1,8.1,0,0,0,104,147V48a24,24,0,0,1,48,0v99a8.2,8.2,0,0,0,3.3,6.5A44,44,0,0,1,128,232Zm8-70.8V88a8,8,0,0,0-16,0v73.2a28,28,0,1,0,16,0ZM128,200a12,12,0,1,1,12-12A12,12,0,0,1,128,200Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M168,143.3V48a40,40,0,0,0-80,0v95.3A59.4,59.4,0,0,0,68,188a60,60,0,0,0,120,0A59.4,59.4,0,0,0,168,143.3ZM128,24a24.1,24.1,0,0,1,24,24V80H104V48A24.1,24.1,0,0,1,128,24Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M166,144.2V48a38,38,0,0,0-76,0v96.2a58,58,0,1,0,76,0ZM128,234a46,46,0,0,1-28.6-82,6.1,6.1,0,0,0,2.6-5V48a26,26,0,0,1,52,0v99a6.1,6.1,0,0,0,2.5,4.9A46,46,0,0,1,128,234Zm6-71.3V88a6,6,0,0,0-12,0v74.7a26,26,0,1,0,12,0ZM128,202a14,14,0,1,1,14-14A14,14,0,0,1,128,202Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M164,145.1V48a36,36,0,0,0-72,0v97.1a56,56,0,1,0,72,0ZM128,236a48,48,0,0,1-48-48,47.4,47.4,0,0,1,18.2-37.6A4.2,4.2,0,0,0,100,147V48a28,28,0,0,1,56,0v99a4,4,0,0,0,1.7,3.3A48,48,0,0,1,128,236Zm4-71.7V88a4,4,0,0,0-8,0v76.3a24,24,0,1,0,8,0ZM128,204a16,16,0,1,1,16-16A16,16,0,0,1,128,204Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M168,143.3V48a40,40,0,0,0-80,0v95.3A59.4,59.4,0,0,0,68,188a60,60,0,0,0,120,0A59.4,59.4,0,0,0,168,143.3ZM128,232a44,44,0,0,1-44-44,43.5,43.5,0,0,1,16.6-34.4A8.1,8.1,0,0,0,104,147V48a24,24,0,0,1,48,0v99a8.2,8.2,0,0,0,3.3,6.5A44,44,0,0,1,128,232Zm8-70.8V88a8,8,0,0,0-16,0v73.2a28,28,0,1,0,16,0ZM128,200a12,12,0,1,1,12-12A12,12,0,0,1,128,200Z" />
        </>
      )}
    </svg>
  );
}

export function PhThermometer(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216,48a32,32,0,1,0,32,32A32.1,32.1,0,0,0,216,48Zm0,40a8,8,0,1,1,8-8A8,8,0,0,1,216,88ZM132,188a24,24,0,1,1-36-20.8V92a12,12,0,0,1,24,0v75.2A24,24,0,0,1,132,188ZM160,56A52,52,0,0,0,56,56v94.7a64,64,0,1,0,104,0ZM108,228a40,40,0,0,1-30.9-65.4,12.1,12.1,0,0,0,2.9-7.8V56a28,28,0,0,1,56,0v98.8a11.7,11.7,0,0,0,2.8,7.6A40.6,40.6,0,0,1,148,188,40,40,0,0,1,108,228Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M152,147h0V48a32,32,0,0,0-64,0v99h0a52,52,0,1,0,64,0Zm-32,61a20,20,0,1,1,20-20A20.1,20.1,0,0,1,120,208Z" />
          </g>
          <path d="M212,52a28,28,0,1,0,28,28A28.1,28.1,0,0,0,212,52Zm0,40a12,12,0,1,1,12-12A12,12,0,0,1,212,92Zm-52,51.3V48a40,40,0,0,0-80,0v95.3A59.4,59.4,0,0,0,60,188a60,60,0,0,0,120,0A59.4,59.4,0,0,0,160,143.3ZM120,232a44,44,0,0,1-44-44,43.5,43.5,0,0,1,16.6-34.4A8.1,8.1,0,0,0,96,147V48a24,24,0,0,1,48,0v99a8.2,8.2,0,0,0,3.3,6.5A44,44,0,0,1,120,232Zm8-70.8V88a8,8,0,0,0-16,0v73.2a28,28,0,1,0,16,0ZM120,200a12,12,0,1,1,12-12A12,12,0,0,1,120,200Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M212,52a28,28,0,1,0,28,28A28.1,28.1,0,0,0,212,52Zm0,40a12,12,0,1,1,12-12A12,12,0,0,1,212,92Zm-52,51.3V48a40,40,0,0,0-80,0v95.3A59.4,59.4,0,0,0,60,188a60,60,0,0,0,120,0A59.4,59.4,0,0,0,160,143.3ZM120,24a24.1,24.1,0,0,1,24,24V80H96V48A24.1,24.1,0,0,1,120,24Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M212,54a26,26,0,1,0,26,26A26.1,26.1,0,0,0,212,54Zm0,40a14,14,0,1,1,14-14A14,14,0,0,1,212,94Zm-54,50.2V48a38,38,0,0,0-76,0v96.2a58,58,0,1,0,76,0ZM120,234a46,46,0,0,1-28.6-82,6.1,6.1,0,0,0,2.6-5V48a26,26,0,0,1,52,0v99a6.1,6.1,0,0,0,2.5,4.9A46,46,0,0,1,120,234Zm6-71.3V88a6,6,0,0,0-12,0v74.7a26,26,0,1,0,12,0ZM120,202a14,14,0,1,1,14-14A14,14,0,0,1,120,202Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M212,56a24,24,0,1,0,24,24A24.1,24.1,0,0,0,212,56Zm0,40a16,16,0,1,1,16-16A16,16,0,0,1,212,96Zm-56,49.1V48a36,36,0,0,0-72,0v97.1a56,56,0,1,0,72,0ZM120,236a48,48,0,0,1-48-48,47.4,47.4,0,0,1,18.2-37.6A4.2,4.2,0,0,0,92,147V48a28,28,0,0,1,56,0v99a4,4,0,0,0,1.7,3.3A48,48,0,0,1,120,236Zm4-71.7V88a4,4,0,0,0-8,0v76.3a24,24,0,1,0,8,0ZM120,204a16,16,0,1,1,16-16A16,16,0,0,1,120,204Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M212,52a28,28,0,1,0,28,28A28.1,28.1,0,0,0,212,52Zm0,40a12,12,0,1,1,12-12A12,12,0,0,1,212,92Zm-52,51.3V48a40,40,0,0,0-80,0v95.3A59.4,59.4,0,0,0,60,188a60,60,0,0,0,120,0A59.4,59.4,0,0,0,160,143.3ZM120,232a44,44,0,0,1-44-44,43.5,43.5,0,0,1,16.6-34.4A8.1,8.1,0,0,0,96,147V48a24,24,0,0,1,48,0v99a8.2,8.2,0,0,0,3.3,6.5A44,44,0,0,1,120,232Zm8-70.8V88a8,8,0,0,0-16,0v73.2a28,28,0,1,0,16,0ZM120,200a12,12,0,1,1,12-12A12,12,0,0,1,120,200Z" />
        </>
      )}
    </svg>
  );
}

export function PhThumbsDown(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M241.7,156.5l-12-96A28.1,28.1,0,0,0,201.9,36H32A20.1,20.1,0,0,0,12,56v88a20.1,20.1,0,0,0,20,20H72.6l36.7,73.4A11.9,11.9,0,0,0,120,244a44,44,0,0,0,44-44V188h49.9a28.1,28.1,0,0,0,27.8-31.5ZM68,140H36V60H68Zm148.9,22.6a3.9,3.9,0,0,1-3,1.4H152a12,12,0,0,0-12,12v24a20.1,20.1,0,0,1-13.2,18.8L92,149.2V60H201.9a3.9,3.9,0,0,1,3.9,3.5l12,96A3.9,3.9,0,0,1,216.9,162.6Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M32,48H80a0,0,0,0,1,0,0V152a0,0,0,0,1,0,0H32a8,8,0,0,1-8-8V56a8,8,0,0,1,8-8Z" />
          </g>
          <path d="M237.7,157l-12-96a24.1,24.1,0,0,0-23.8-21H32A16,16,0,0,0,16,56v88a16,16,0,0,0,16,16H75l37.8,75.6A8.2,8.2,0,0,0,120,240a40.1,40.1,0,0,0,40-40V184h53.9a23.9,23.9,0,0,0,23.8-27ZM72,144H32V56H72Zm147.9,21.3a8.2,8.2,0,0,1-6,2.7H152a8,8,0,0,0-8,8v24a24,24,0,0,1-19.3,23.5L88,150.1V56H201.9a8,8,0,0,1,7.9,7l12,96A8.1,8.1,0,0,1,219.9,165.3Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M237.7,157l-12-96a24.1,24.1,0,0,0-23.8-21H32A16,16,0,0,0,16,56v88a16,16,0,0,0,16,16H75l37.8,75.6A8.2,8.2,0,0,0,120,240a40.1,40.1,0,0,0,40-40V184h53.9a23.9,23.9,0,0,0,23.8-27ZM72,144H32V56H72Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M235.7,157.3l-12-96A22.1,22.1,0,0,0,201.9,42H32A14,14,0,0,0,18,56v88a14,14,0,0,0,14,14H76.3l38.3,76.7A6,6,0,0,0,120,238a38,38,0,0,0,38-38V182h55.9a22.1,22.1,0,0,0,16.5-7.4A22.4,22.4,0,0,0,235.7,157.3ZM74,146H32a2,2,0,0,1-2-2V56a2,2,0,0,1,2-2H74Zm147.4,20.6a10,10,0,0,1-7.5,3.4H152a6,6,0,0,0-6,6v24a26.2,26.2,0,0,1-22.4,25.8L86,150.6V54H201.9a10,10,0,0,1,9.9,8.8l12,96A9.8,9.8,0,0,1,221.4,166.6Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M233.7,157.5l-12-96A20,20,0,0,0,201.9,44H32A12,12,0,0,0,20,56v88a12,12,0,0,0,12,12H77.5l38.9,77.8A4.2,4.2,0,0,0,120,236a36,36,0,0,0,36-36V180h57.9a20,20,0,0,0,19.8-22.5ZM76,148H32a4,4,0,0,1-4-4V56a4,4,0,0,1,4-4H76Zm146.9,19.9a12.1,12.1,0,0,1-9,4.1H152a4,4,0,0,0-4,4v24a28.1,28.1,0,0,1-25.6,27.9L84,151.1V52H201.9a12.1,12.1,0,0,1,11.9,10.5l12,96A12.2,12.2,0,0,1,222.9,167.9Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M237.7,157l-12-96a24.1,24.1,0,0,0-23.8-21H32A16,16,0,0,0,16,56v88a16,16,0,0,0,16,16H75l37.8,75.6A8.2,8.2,0,0,0,120,240a40.1,40.1,0,0,0,40-40V184h53.9a23.9,23.9,0,0,0,23.8-27ZM72,144H32V56H72Zm147.9,21.3a8.2,8.2,0,0,1-6,2.7H152a8,8,0,0,0-8,8v24a24,24,0,0,1-19.3,23.5L88,150.1V56H201.9a8,8,0,0,1,7.9,7l12,96A8.1,8.1,0,0,1,219.9,165.3Z" />
        </>
      )}
    </svg>
  );
}

export function PhThumbsUp(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M234.9,77.5a28.3,28.3,0,0,0-21-9.5H164V56a44,44,0,0,0-44-44,11.9,11.9,0,0,0-10.7,6.6L72.6,92H32a20.1,20.1,0,0,0-20,20v88a20.1,20.1,0,0,0,20,20H201.9a28.1,28.1,0,0,0,27.8-24.5l12-96A28.4,28.4,0,0,0,234.9,77.5ZM36,116H68v80H36ZM217.8,96.5l-12,96a3.9,3.9,0,0,1-3.9,3.5H92V106.8l34.8-69.6A20.1,20.1,0,0,1,140,56V80a12,12,0,0,0,12,12h61.9a3.9,3.9,0,0,1,3,1.4A3.9,3.9,0,0,1,217.8,96.5Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M32,104H80a0,0,0,0,1,0,0V208a0,0,0,0,1,0,0H32a8,8,0,0,1-8-8V112A8,8,0,0,1,32,104Z" />
          </g>
          <path d="M231.9,80.1a24.4,24.4,0,0,0-18-8.1H160V56a40.1,40.1,0,0,0-40-40,8.2,8.2,0,0,0-7.2,4.4L75,96H32a16,16,0,0,0-16,16v88a16,16,0,0,0,16,16H201.9a24.1,24.1,0,0,0,23.8-21l12-96A24.5,24.5,0,0,0,231.9,80.1ZM32,112H72v88H32ZM221.8,97l-12,96a8,8,0,0,1-7.9,7H88V105.9l36.7-73.4A24,24,0,0,1,144,56V80a8,8,0,0,0,8,8h61.9a8.2,8.2,0,0,1,6,2.7A8.1,8.1,0,0,1,221.8,97Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M231.9,80.1a24.4,24.4,0,0,0-18-8.1H160V56a40.1,40.1,0,0,0-40-40,8.2,8.2,0,0,0-7.2,4.4L75,96H32a16,16,0,0,0-16,16v88a16,16,0,0,0,16,16H201.9a24.1,24.1,0,0,0,23.8-21l12-96A24.5,24.5,0,0,0,231.9,80.1ZM32,112H72v88H32Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M230.4,81.4A22.1,22.1,0,0,0,213.9,74H158V56a38,38,0,0,0-38-38,6,6,0,0,0-5.4,3.3L76.3,98H32a14,14,0,0,0-14,14v88a14,14,0,0,0,14,14H201.9a22.1,22.1,0,0,0,21.8-19.3l12-96A22.4,22.4,0,0,0,230.4,81.4ZM30,200V112a2,2,0,0,1,2-2H74v92H32A2,2,0,0,1,30,200ZM223.8,97.2l-12,96a10,10,0,0,1-9.9,8.8H86V105.4l37.6-75.2A26.2,26.2,0,0,1,146,56V80a6,6,0,0,0,6,6h61.9a10,10,0,0,1,7.5,3.4A9.8,9.8,0,0,1,223.8,97.2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M228.9,82.8a20.1,20.1,0,0,0-15-6.8H156V56a36,36,0,0,0-36-36,4.2,4.2,0,0,0-3.6,2.2L77.5,100H32a12,12,0,0,0-12,12v88a12,12,0,0,0,12,12H201.9a20,20,0,0,0,19.8-17.5l12-96A19.9,19.9,0,0,0,228.9,82.8ZM28,200V112a4,4,0,0,1,4-4H76v96H32A4,4,0,0,1,28,200ZM225.8,97.5l-12,96A12.1,12.1,0,0,1,201.9,204H84V104.9l38.4-76.8A28.1,28.1,0,0,1,148,56V80a4,4,0,0,0,4,4h61.9a12.1,12.1,0,0,1,9,4.1A12.2,12.2,0,0,1,225.8,97.5Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M231.9,80.1a24.3,24.3,0,0,0-18-8.1H160V56a40.1,40.1,0,0,0-40-40,8.2,8.2,0,0,0-7.2,4.4L75,96H32a16,16,0,0,0-16,16v88a16,16,0,0,0,16,16H201.9a24.1,24.1,0,0,0,23.8-21l12-96A24.5,24.5,0,0,0,231.9,80.1ZM32,112H72v88H32ZM221.8,97l-12,96a8,8,0,0,1-7.9,7H88V105.9l36.7-73.4A24,24,0,0,1,144,56V80a8,8,0,0,0,8,8h61.9a8.2,8.2,0,0,1,6,2.7A8.1,8.1,0,0,1,221.8,97Z" />
        </>
      )}
    </svg>
  );
}

export function PhTicket(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M228,108.4a20,20,0,0,0,16-19.6V64a20.1,20.1,0,0,0-20-20H32A20.1,20.1,0,0,0,12,64V88.8a20,20,0,0,0,16,19.6,20,20,0,0,1,0,39.2,20,20,0,0,0-16,19.6V192a20.1,20.1,0,0,0,20,20H224a20.1,20.1,0,0,0,20-20V167.2a20,20,0,0,0-16-19.6,20,20,0,0,1,0-39.2ZM36,170.3a44,44,0,0,0,0-84.6V68H84V188H36Zm184,0V188H108V68H220V85.7a44,44,0,0,0,0,84.6Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M96,56H224a8,8,0,0,1,8,8V88.8a7.9,7.9,0,0,1-6.4,7.8,32.1,32.1,0,0,0,0,62.8,7.9,7.9,0,0,1,6.4,7.8V192a8,8,0,0,1-8,8H96Z" />
          </g>
          <path d="M227.2,104.5A16.1,16.1,0,0,0,240,88.8V64a16,16,0,0,0-16-16H32A16,16,0,0,0,16,64V88.8a16.1,16.1,0,0,0,12.8,15.7,24,24,0,0,1,0,47A16.1,16.1,0,0,0,16,167.2V192a16,16,0,0,0,16,16H224a16,16,0,0,0,16-16V167.2a16.1,16.1,0,0,0-12.8-15.7,24,24,0,0,1,0-47ZM32,167.2a40,40,0,0,0,0-78.4V64H88V192H32Zm192,0V192H104V64H224V88.8a40,40,0,0,0,0,78.4Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M227.2,104.5A16.1,16.1,0,0,0,240,88.8V64a16,16,0,0,0-16-16H32A16,16,0,0,0,16,64V88.8a16.1,16.1,0,0,0,12.8,15.7,24,24,0,0,1,0,47A16.1,16.1,0,0,0,16,167.2V192a16,16,0,0,0,16,16H224a16,16,0,0,0,16-16V167.2a16.1,16.1,0,0,0-12.8-15.7,24,24,0,0,1,0-47ZM32,167.2a40,40,0,0,0,0-78.4V64H88V192H32Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M226.8,102.5A14,14,0,0,0,238,88.8V64a14,14,0,0,0-14-14H32A14,14,0,0,0,18,64V88.8a14,14,0,0,0,11.2,13.7,26,26,0,0,1,0,51A14,14,0,0,0,18,167.2V192a14,14,0,0,0,14,14H224a14,14,0,0,0,14-14V167.2a14,14,0,0,0-11.2-13.7,26,26,0,0,1,0-51ZM30,192V167.2a2,2,0,0,1,1.6-2,38,38,0,0,0,0-74.4,2,2,0,0,1-1.6-2V64a2,2,0,0,1,2-2H90V194H32A2,2,0,0,1,30,192Zm194.4-26.8a2,2,0,0,1,1.6,2V192a2,2,0,0,1-2,2H102V62H224a2,2,0,0,1,2,2V88.8a2,2,0,0,1-1.6,2,38,38,0,0,0,0,74.4Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M226.4,100.6A12.1,12.1,0,0,0,236,88.8V64a12,12,0,0,0-12-12H32A12,12,0,0,0,20,64V88.8a12.1,12.1,0,0,0,9.6,11.8,28,28,0,0,1,0,54.8A12.1,12.1,0,0,0,20,167.2V192a12,12,0,0,0,12,12H224a12,12,0,0,0,12-12V167.2a12.1,12.1,0,0,0-9.6-11.8,28,28,0,0,1,0-54.8ZM28,192V167.2a4,4,0,0,1,3.2-3.9,36,36,0,0,0,0-70.6A4,4,0,0,1,28,88.8V64a4,4,0,0,1,4-4H92V196H32A4,4,0,0,1,28,192Zm168-64a36.1,36.1,0,0,0,28.8,35.3,4,4,0,0,1,3.2,3.9V192a4,4,0,0,1-4,4H100V60H224a4,4,0,0,1,4,4V88.8a4,4,0,0,1-3.2,3.9A36.1,36.1,0,0,0,196,128Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M227.2,104.5A16.1,16.1,0,0,0,240,88.8V64a16,16,0,0,0-16-16H32A16,16,0,0,0,16,64V88.8a16.1,16.1,0,0,0,12.8,15.7,24,24,0,0,1,0,47A16.1,16.1,0,0,0,16,167.2V192a16,16,0,0,0,16,16H224a16,16,0,0,0,16-16V167.2a16.1,16.1,0,0,0-12.8-15.7,24,24,0,0,1,0-47ZM32,167.2a40,40,0,0,0,0-78.4V64H88V192H32Zm192,0V192H104V64H224V88.8a40,40,0,0,0,0,78.4Z" />
        </>
      )}
    </svg>
  );
}

export function PhTiktokLogo(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M224,72a44,44,0,0,1-44-44,12,12,0,0,0-12-12H128a12,12,0,0,0-12,12V156a16,16,0,0,1-32,0,16.3,16.3,0,0,1,9.1-14.5,12,12,0,0,0,6.9-10.8V89.1a11.9,11.9,0,0,0-4.3-9.2,12,12,0,0,0-9.8-2.7A80,80,0,1,0,180,156V126.7a107.9,107.9,0,0,0,44,9.3,12,12,0,0,0,12-12V84A12,12,0,0,0,224,72Zm-12,39.2a83.2,83.2,0,0,1-37-15,12.2,12.2,0,0,0-12.5-.9A12.1,12.1,0,0,0,156,106v50a56,56,0,1,1-80-50.6V124a40,40,0,1,0,64,32V40h17.1A67.9,67.9,0,0,0,212,94.9Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M224,124a95.9,95.9,0,0,1-56-18v50A68,68,0,1,1,88,89.1v41.6A28,28,0,1,0,128,156V28h40a56,56,0,0,0,56,56Z"
            opacity="0.2"
          />
          <path d="M224,76a48,48,0,0,1-48-48,8,8,0,0,0-8-8H128a8,8,0,0,0-8,8V156a20,20,0,1,1-28.6-18.1,7.9,7.9,0,0,0,4.6-7.2V89.1a7.8,7.8,0,0,0-2.9-6.2,8,8,0,0,0-6.5-1.7A76,76,0,1,0,176,156V120.3A103.2,103.2,0,0,0,224,132a8,8,0,0,0,8-8V84A8,8,0,0,0,224,76Zm-8,39.6a87.4,87.4,0,0,1-43.3-16.1A8.1,8.1,0,0,0,160,106v50A60,60,0,1,1,80,99.4v26.7A36,36,0,1,0,136,156V36h24.5A64.1,64.1,0,0,0,216,91.5Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M232,84v40a8,8,0,0,1-8,8,103.2,103.2,0,0,1-48-11.7V156A76,76,0,1,1,86.6,81.2a8,8,0,0,1,6.5,1.7A7.8,7.8,0,0,1,96,89.1v41.6a7.9,7.9,0,0,1-4.6,7.2A20,20,0,1,0,120,156V28a8,8,0,0,1,8-8h40a8,8,0,0,1,8,8,48,48,0,0,0,48,48A8,8,0,0,1,232,84Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M224,78a50,50,0,0,1-50-50,6,6,0,0,0-6-6H128a6,6,0,0,0-6,6V156a22,22,0,1,1-31.4-19.9,6,6,0,0,0,3.4-5.4V89.1a6.1,6.1,0,0,0-7.1-6A74,74,0,1,0,174,156V116.9A101.1,101.1,0,0,0,224,130a6,6,0,0,0,6-6V84A6,6,0,0,0,224,78Zm-6,39.8a89,89,0,0,1-46.5-16.7,6,6,0,0,0-6.2-.5A6.2,6.2,0,0,0,162,106v50A62,62,0,1,1,82,96.6v30.6A34,34,0,1,0,134,156V34h28.3A62,62,0,0,0,218,89.7Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M224,80a52,52,0,0,1-52-52,4,4,0,0,0-4-4H128a4,4,0,0,0-4,4V156a24,24,0,1,1-34.3-21.7,4.1,4.1,0,0,0,2.3-3.6V89.1a4,4,0,0,0-4.7-4A72,72,0,1,0,172,156V113.4A98.9,98.9,0,0,0,224,128a4,4,0,0,0,4-4V84A4,4,0,0,0,224,80Zm-4,39.9a90.3,90.3,0,0,1-49.7-17.2,4,4,0,0,0-4.1-.3A4,4,0,0,0,164,106v50A64,64,0,1,1,84,94v34.3A32,32,0,1,0,132,156V32h32.1A60.2,60.2,0,0,0,220,87.9Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M224,76a48,48,0,0,1-48-48,8,8,0,0,0-8-8H128a8,8,0,0,0-8,8V156a20,20,0,1,1-28.6-18.1,7.9,7.9,0,0,0,4.6-7.2V89.1a7.8,7.8,0,0,0-2.9-6.2,8,8,0,0,0-6.5-1.7A76,76,0,1,0,176,156V120.3A103.2,103.2,0,0,0,224,132a8,8,0,0,0,8-8V84A8,8,0,0,0,224,76Zm-8,39.6a87.4,87.4,0,0,1-43.3-16.1A8.1,8.1,0,0,0,160,106v50A60,60,0,1,1,80,99.4v26.7A36,36,0,1,0,136,156V36h24.5A64.1,64.1,0,0,0,216,91.5Z" />
        </>
      )}
    </svg>
  );
}

export function PhTimer(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,40A100,100,0,1,0,228,140,100.2,100.2,0,0,0,128,40Zm0,176a76,76,0,1,1,76-76A76.1,76.1,0,0,1,128,216ZM170.4,97.6a11.9,11.9,0,0,1,0,16.9l-33.9,34a12.1,12.1,0,0,1-17,0,12,12,0,0,1,0-17l34-33.9A11.9,11.9,0,0,1,170.4,97.6ZM92,12A12,12,0,0,1,104,0h48a12,12,0,0,1,0,24H104A12,12,0,0,1,92,12Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="128" cy="128" r="88" />
          </g>
          <path d="M128,32a96,96,0,1,0,96,96A96.2,96.2,0,0,0,128,32Zm0,176a80,80,0,1,1,80-80A80.1,80.1,0,0,1,128,208Z" />
          <path d="M161.9,82.7l-39.6,39.6a8.1,8.1,0,0,0,0,11.4,8.2,8.2,0,0,0,11.4,0l39.6-39.6a8.1,8.1,0,1,0-11.4-11.4Z" />
          <path d="M104,16h48a8,8,0,0,0,0-16H104a8,8,0,0,0,0,16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M96,8a8,8,0,0,1,8-8h48a8,8,0,0,1,0,16H104A8,8,0,0,1,96,8ZM224,128a96,96,0,1,1-96-96A96.2,96.2,0,0,1,224,128ZM173.3,82.7a8.1,8.1,0,0,0-11.4,0l-39.6,39.6a8.1,8.1,0,0,0,0,11.4,8.2,8.2,0,0,0,11.4,0l39.6-39.6A8.1,8.1,0,0,0,173.3,82.7Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,34a94,94,0,1,0,94,94A94.1,94.1,0,0,0,128,34Zm0,176a82,82,0,1,1,82-82A82.1,82.1,0,0,1,128,210ZM171.8,84.2a5.8,5.8,0,0,1,0,8.4l-39.6,39.6a5.9,5.9,0,0,1-8.4-8.4l39.6-39.6A5.8,5.8,0,0,1,171.8,84.2ZM98,8a6,6,0,0,1,6-6h48a6,6,0,0,1,0,12H104A6,6,0,0,1,98,8Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,36a92,92,0,1,0,92,92A92.1,92.1,0,0,0,128,36Zm0,176a84,84,0,1,1,84-84A84.1,84.1,0,0,1,128,212ZM170.4,85.6a3.9,3.9,0,0,1,0,5.6l-39.6,39.6a3.9,3.9,0,0,1-5.6,0,3.9,3.9,0,0,1,0-5.6l39.6-39.6A3.9,3.9,0,0,1,170.4,85.6ZM100,8a4,4,0,0,1,4-4h48a4,4,0,0,1,0,8H104A4,4,0,0,1,100,8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,32a96,96,0,1,0,96,96A96.2,96.2,0,0,0,128,32Zm0,176a80,80,0,1,1,80-80A80.1,80.1,0,0,1,128,208ZM173.3,82.7a8.1,8.1,0,0,1,0,11.4l-39.6,39.6a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4l39.6-39.6A8.1,8.1,0,0,1,173.3,82.7ZM96,8a8,8,0,0,1,8-8h48a8,8,0,0,1,0,16H104A8,8,0,0,1,96,8Z" />
        </>
      )}
    </svg>
  );
}

export function PhToggleLeft(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M176,52H80a76,76,0,0,0,0,152h96a76,76,0,0,0,0-152Zm0,128H80A52,52,0,0,1,80,76h96a52,52,0,0,1,0,104ZM80,92a36,36,0,1,0,36,36A36,36,0,0,0,80,92Zm0,48a12,12,0,1,1,12-12A12,12,0,0,1,80,140Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M112,128A32,32,0,1,1,80,96,32,32,0,0,1,112,128Z"
            opacity="0.2"
          />
          <path d="M176,56H80a72,72,0,0,0,0,144h96a72,72,0,0,0,0-144Zm0,128H80A56,56,0,0,1,80,72h96a56,56,0,0,1,0,112ZM80,88a40,40,0,1,0,40,40A40,40,0,0,0,80,88Zm0,64a24,24,0,1,1,24-24A24.1,24.1,0,0,1,80,152Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M176,56H80a72,72,0,0,0,0,144h96a72,72,0,0,0,0-144ZM80,168a40,40,0,1,1,40-40A40,40,0,0,1,80,168Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M176,58H80a70,70,0,0,0,0,140h96a70,70,0,0,0,0-140Zm0,128H80A58,58,0,0,1,80,70h96a58,58,0,0,1,0,116ZM80,90a38,38,0,1,0,38,38A38,38,0,0,0,80,90Zm0,64a26,26,0,1,1,26-26A26.1,26.1,0,0,1,80,154Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M176,60H80a68,68,0,0,0,0,136h96a68,68,0,0,0,0-136Zm0,128H80A60,60,0,0,1,80,68h96a60,60,0,0,1,0,120ZM80,92a36,36,0,1,0,36,36A36,36,0,0,0,80,92Zm0,64a28,28,0,1,1,28-28A28.1,28.1,0,0,1,80,156Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M176,56H80a72,72,0,0,0,0,144h96a72,72,0,0,0,0-144Zm0,128H80A56,56,0,0,1,80,72h96a56,56,0,0,1,0,112ZM80,88a40,40,0,1,0,40,40A40,40,0,0,0,80,88Zm0,64a24,24,0,1,1,24-24A24.1,24.1,0,0,1,80,152Z" />
        </>
      )}
    </svg>
  );
}

export function PhToggleRight(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M176,52H80a76,76,0,0,0,0,152h96a76,76,0,0,0,0-152Zm0,128H80A52,52,0,0,1,80,76h96a52,52,0,0,1,0,104Zm0-88a36,36,0,1,0,36,36A36,36,0,0,0,176,92Zm0,48a12,12,0,1,1,12-12A12,12,0,0,1,176,140Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M208,128a32,32,0,1,1-32-32A32,32,0,0,1,208,128Z"
            opacity="0.2"
          />
          <path d="M176,56H80a72,72,0,0,0,0,144h96a72,72,0,0,0,0-144Zm0,128H80A56,56,0,0,1,80,72h96a56,56,0,0,1,0,112Zm0-96a40,40,0,1,0,40,40A40,40,0,0,0,176,88Zm0,64a24,24,0,1,1,24-24A24.1,24.1,0,0,1,176,152Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M176,56H80a72,72,0,0,0,0,144h96a72,72,0,0,0,0-144Zm0,112a40,40,0,1,1,40-40A40,40,0,0,1,176,168Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M176,58H80a70,70,0,0,0,0,140h96a70,70,0,0,0,0-140Zm0,128H80A58,58,0,0,1,80,70h96a58,58,0,0,1,0,116Zm0-96a38,38,0,1,0,38,38A38,38,0,0,0,176,90Zm0,64a26,26,0,1,1,26-26A26.1,26.1,0,0,1,176,154Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M176,60H80a68,68,0,0,0,0,136h96a68,68,0,0,0,0-136Zm0,128H80A60,60,0,0,1,80,68h96a60,60,0,0,1,0,120Zm0-96a36,36,0,1,0,36,36A36,36,0,0,0,176,92Zm0,64a28,28,0,1,1,28-28A28.1,28.1,0,0,1,176,156Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M176,56H80a72,72,0,0,0,0,144h96a72,72,0,0,0,0-144Zm0,128H80A56,56,0,0,1,80,72h96a56,56,0,0,1,0,112Zm0-96a40,40,0,1,0,40,40A40,40,0,0,0,176,88Zm0,64a24,24,0,1,1,24-24A24.1,24.1,0,0,1,176,152Z" />
        </>
      )}
    </svg>
  );
}

export function PhToiletPaper(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M80,120a16,16,0,1,1-16-16A16,16,0,0,1,80,120Zm164,0v88a20.1,20.1,0,0,1-20,20H112a20.1,20.1,0,0,1-20-20v-9.6C84,207.1,74.5,212,64,212c-29.6,0-52-39.6-52-92S34.4,28,64,28H192C221.6,28,244,67.6,244,120ZM92,120c0-19.3-3.8-37.9-10.4-51.2C76.3,58.3,69.7,52,64,52S51.7,58.3,46.4,68.8C39.8,82.1,36,100.7,36,120s3.8,37.9,10.4,51.2C51.7,181.7,58.3,188,64,188s12.3-6.3,17.6-16.8C88.2,157.9,92,139.3,92,120Zm128,84V132h-8a12,12,0,0,1,0-24h7.5c-1.2-14.9-4.7-28.7-9.9-39.2S197.7,52,192,52H99.7c8.7,14.1,14.4,33.6,15.9,56H124a12,12,0,0,1,0,24h-8v72Zm-48-96h-8a12,12,0,0,0,0,24h8a12,12,0,0,0,0-24Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M104,120c0,44.2-17.9,80-40,80s-40-35.8-40-80S41.9,40,64,40,104,75.8,104,120Z"
            opacity="0.2"
          />
          <path d="M76,120a12,12,0,1,1-12-12A12,12,0,0,1,76,120Zm164,0v88a16,16,0,0,1-16,16H112a16,16,0,0,1-16-16V186.3C87.4,200.4,76.2,208,64,208c-13.9,0-26.5-9.9-35.4-27.9C20.5,163.9,16,142.6,16,120s4.5-43.9,12.6-60.1C37.5,41.9,50.1,32,64,32H192c13.9,0,26.5,9.9,35.4,27.9C235.5,76.1,240,97.4,240,120ZM96,120c0-42.4-16.9-72-32-72S32,77.6,32,120s16.9,72,32,72S96,162.4,96,120Zm128,88V128H208a8,8,0,0,1,0-16h15.8c-2-38.1-17.6-64-31.8-64H92.1a65,65,0,0,1,7.3,11.9c7.2,14.2,11.5,32.5,12.4,52.1H128a8,8,0,0,1,0,16H112v80Zm-48-96H160a8,8,0,0,0,0,16h16a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M184,120a8,8,0,0,1-8,8H160a8,8,0,0,1,0-16h16A8,8,0,0,1,184,120Zm56,0v88a16,16,0,0,1-16,16H112a16,16,0,0,1-16-16V186.3C87.4,200.4,76.2,208,64,208c-13.9,0-26.5-9.9-35.4-27.9C20.5,163.9,16,142.6,16,120s4.5-43.9,12.6-60.1C37.5,41.9,50.1,32,64,32H192c13.9,0,26.5,9.9,35.4,27.9C235.5,76.1,240,97.4,240,120ZM76,120a12,12,0,1,0-12,12A12,12,0,0,0,76,120Zm148,8H208a8,8,0,0,1,0-16h15.8c-2-38.1-17.6-64-31.8-64H92.1a65,65,0,0,1,7.3,11.9c7.2,14.2,11.5,32.5,12.4,52.1H128a8,8,0,0,1,0,16H112v80H224Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M74,120a10,10,0,1,1-10-10A10,10,0,0,1,74,120Zm164,0v88a14,14,0,0,1-14,14H112a14,14,0,0,1-14-14V178.5c-8.4,17-20.4,27.5-34,27.5-25.8,0-46-37.8-46-86S38.2,34,64,34H192C217.8,34,238,71.8,238,120ZM98,120c0-44.3-17.6-74-34-74S30,75.7,30,120s17.6,74,34,74S98,164.3,98,120Zm128,88V126H208a6,6,0,0,1,0-12h17.9c-1.5-40.9-18.2-68-33.9-68H87.8c12.5,13.9,21,38.7,22.1,68H128a6,6,0,0,1,0,12H110v82a2,2,0,0,0,2,2H224A2,2,0,0,0,226,208Zm-50-94H160a6,6,0,0,0,0,12h16a6,6,0,0,0,0-12Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M72,120a8,8,0,1,1-8-8A8,8,0,0,1,72,120Zm164,0v88a12,12,0,0,1-12,12H112a12,12,0,0,1-12-12V168.9C92.1,190.3,79.1,204,64,204c-24.7,0-44-36.9-44-84S39.3,36,64,36H192C216.7,36,236,72.9,236,120Zm-136,0c0-41.2-16.5-76-36-76S28,78.8,28,120s16.5,76,36,76S100,161.2,100,120Zm128,88V124H208a4,4,0,0,1,0-8h19.9c-1-39.4-17-72-35.9-72H83c14.3,12.8,24.2,39.8,24.9,72H128a4,4,0,0,1,0,8H108v84a4,4,0,0,0,4,4H224A4,4,0,0,0,228,208Zm-52-92H160a4,4,0,0,0,0,8h16a4,4,0,0,0,0-8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M76,120a12,12,0,1,1-12-12A12,12,0,0,1,76,120Zm164,0v88a16,16,0,0,1-16,16H112a16,16,0,0,1-16-16V186.3C87.4,200.4,76.2,208,64,208c-13.9,0-26.5-9.9-35.4-27.9C20.5,163.9,16,142.6,16,120s4.5-43.9,12.6-60.1C37.5,41.9,50.1,32,64,32H192c13.9,0,26.5,9.9,35.4,27.9C235.5,76.1,240,97.4,240,120ZM96,120c0-42.4-16.9-72-32-72S32,77.6,32,120s16.9,72,32,72S96,162.4,96,120Zm128,88V128H208a8,8,0,0,1,0-16h15.8c-2-38.1-17.6-64-31.8-64H92.1a65,65,0,0,1,7.3,11.9c7.2,14.2,11.5,32.5,12.4,52.1H128a8,8,0,0,1,0,16H112v80Zm-48-96H160a8,8,0,0,0,0,16h16a8,8,0,0,0,0-16Z" />
        </>
      )}
    </svg>
  );
}

export function PhToilet(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,68a12,12,0,0,1-12,12H100a12,12,0,0,1,0-24h16A12,12,0,0,1,128,68Zm47,128.2,3.6,25A20,20,0,0,1,158.8,244H97.2a20,20,0,0,1-19.8-22.8l3.6-25A99.9,99.9,0,0,1,28,108,12,12,0,0,1,40,96H52V40A20.1,20.1,0,0,1,72,20H184a20.1,20.1,0,0,1,20,20V96h12a12,12,0,0,1,12,12A99.9,99.9,0,0,1,175,196.2ZM76,96H180V44H76Zm76,109.1a100.8,100.8,0,0,1-48,0L101.8,220h52.4ZM203.1,120H52.9a76.1,76.1,0,0,0,150.2,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M192,40v72H64V40a8,8,0,0,1,8-8H184A8,8,0,0,1,192,40Z"
            opacity="0.2"
          />
          <path d="M120,64a8,8,0,0,1-8,8H96a8,8,0,0,1,0-16h16A8,8,0,0,1,120,64Zm51.2,133.7,3.4,24A15.9,15.9,0,0,1,158.8,240H97.2a15.9,15.9,0,0,1-15.8-18.3l3.4-24A96,96,0,0,1,32,112a8,8,0,0,1,8-8H56V40A16,16,0,0,1,72,24H184a16,16,0,0,1,16,16v64h16a8,8,0,0,1,8,8A96,96,0,0,1,171.2,197.7ZM72,104H184V40H72Zm135.6,16H48.4a80,80,0,0,0,159.2,0Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M224,112a8,8,0,0,0-8-8H40a8,8,0,0,0-8,8,96,96,0,0,0,52.8,85.7l-3.4,24A15.9,15.9,0,0,0,97.2,240h61.6a15.9,15.9,0,0,0,15.8-18.3l-3.4-24A96,96,0,0,0,224,112ZM97.2,224l2.9-20.1a97,97,0,0,0,55.8,0l2.9,20.1ZM60,88H196a4,4,0,0,0,4-4V40a16,16,0,0,0-16-16H72A16,16,0,0,0,56,40V84A4,4,0,0,0,60,88ZM88,48h16a8,8,0,0,1,0,16H88a8,8,0,0,1,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M118,64a6,6,0,0,1-6,6H96a6,6,0,0,1,0-12h16A6,6,0,0,1,118,64Zm51,132.6,3.6,25.4a14.2,14.2,0,0,1-3.2,11.2,14.4,14.4,0,0,1-10.6,4.8H97.2a14.4,14.4,0,0,1-10.6-4.8A14.2,14.2,0,0,1,83.4,222L87,196.6A94.3,94.3,0,0,1,34,112a6,6,0,0,1,6-6H58V40A14,14,0,0,1,72,26H184a14,14,0,0,1,14,14v66h18a6,6,0,0,1,6,6A94.3,94.3,0,0,1,169,196.6ZM70,106H186V40a2,2,0,0,0-2-2H72a2,2,0,0,0-2,2Zm87.5,95.2a93.1,93.1,0,0,1-59,0l-3.3,22.5a2,2,0,0,0,.5,1.6,1.8,1.8,0,0,0,1.5.7h61.6a1.8,1.8,0,0,0,1.5-.7,2,2,0,0,0,.5-1.6ZM209.8,118H46.2a82,82,0,0,0,163.6,0Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M116,64a4,4,0,0,1-4,4H96a4,4,0,0,1,0-8h16A4,4,0,0,1,116,64Zm50.8,131.4,3.9,26.9a12.5,12.5,0,0,1-2.9,9.6,11.8,11.8,0,0,1-9,4.1H97.2a11.8,11.8,0,0,1-9-4.1,12.5,12.5,0,0,1-2.9-9.6l3.9-26.9A92.1,92.1,0,0,1,36,112a4,4,0,0,1,4-4H60V40A12,12,0,0,1,72,28H184a12,12,0,0,1,12,12v68h20a4,4,0,0,1,4,4A92.1,92.1,0,0,1,166.8,195.4ZM68,108H188V40a4,4,0,0,0-4-4H72a4,4,0,0,0-4,4Zm91.2,90.6a92.8,92.8,0,0,1-62.4,0l-3.5,24.8a3.7,3.7,0,0,0,.9,3.2,3.9,3.9,0,0,0,3,1.4h61.6a3.9,3.9,0,0,0,3-1.4,3.7,3.7,0,0,0,.9-3.2ZM211.9,116H44.1a84,84,0,0,0,167.8,0Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M120,64a8,8,0,0,1-8,8H96a8,8,0,0,1,0-16h16A8,8,0,0,1,120,64Zm51.2,133.7,3.4,24A15.9,15.9,0,0,1,158.8,240H97.2a15.9,15.9,0,0,1-15.8-18.3l3.4-24A96,96,0,0,1,32,112a8,8,0,0,1,8-8H56V40A16,16,0,0,1,72,24H184a16,16,0,0,1,16,16v64h16a8,8,0,0,1,8,8A96,96,0,0,1,171.2,197.7ZM72,104H184V40H72Zm83.9,99.9a97,97,0,0,1-55.8,0L97.2,224h61.6ZM207.6,120H48.4a80,80,0,0,0,159.2,0Z" />
        </>
      )}
    </svg>
  );
}

export function PhToteSimple(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M238,66.7A20,20,0,0,0,223.1,60H178.6A52,52,0,0,0,77.4,60H32.9A20,20,0,0,0,18,66.7a19.6,19.6,0,0,0-4.9,15.5l14.2,128A19.9,19.9,0,0,0,47.2,228H208.8a19.9,19.9,0,0,0,19.9-17.8l14.2-128A19.6,19.6,0,0,0,238,66.7ZM128,44a28,28,0,0,1,25.3,16H102.7A28,28,0,0,1,128,44Zm77.3,160H50.7L37.4,84H218.6Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M208.8,216H47.2a8.1,8.1,0,0,1-8-7.1L25,80.9A8,8,0,0,1,32.9,72H223.1a8,8,0,0,1,7.9,8.9l-14.2,128A8.1,8.1,0,0,1,208.8,216Z" />
          </g>
          <path d="M235,69.3A15.9,15.9,0,0,0,223.1,64H175.3a48,48,0,0,0-94.6,0H32.9A15.9,15.9,0,0,0,21,69.3a16.2,16.2,0,0,0-4,12.5l14.3,128A15.9,15.9,0,0,0,47.2,224H208.8a15.9,15.9,0,0,0,15.9-14.2L239,81.8A16.2,16.2,0,0,0,235,69.3ZM128,40a32.1,32.1,0,0,1,31,24H97A32.1,32.1,0,0,1,128,40Zm80.8,168H47.2L32.9,80H223.1Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M235,69.3A15.9,15.9,0,0,0,223.1,64H175.3a48,48,0,0,0-94.6,0H32.9A15.9,15.9,0,0,0,21,69.3a16.2,16.2,0,0,0-4,12.5l14.3,128A15.9,15.9,0,0,0,47.2,224H208.8a15.9,15.9,0,0,0,15.9-14.2L239,81.8A16.2,16.2,0,0,0,235,69.3ZM128,40a32.1,32.1,0,0,1,31,24H97A32.1,32.1,0,0,1,128,40Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M233.5,70.7A14.1,14.1,0,0,0,223.1,66H173.6a46,46,0,0,0-91.2,0H32.9A14.1,14.1,0,0,0,19,81.5l14.2,128a14.1,14.1,0,0,0,14,12.5H208.8a14.1,14.1,0,0,0,14-12.5L237,81.5A14.2,14.2,0,0,0,233.5,70.7ZM128,38a34.1,34.1,0,0,1,33.5,28h-67A34.1,34.1,0,0,1,128,38Zm82.8,170.2a2,2,0,0,1-2,1.8H47.2a2,2,0,0,1-2-1.8L31,80.2a1.8,1.8,0,0,1,.4-1.5,2.1,2.1,0,0,1,1.5-.7H223.1a2.1,2.1,0,0,1,1.5.7,1.8,1.8,0,0,1,.4,1.5Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M232,72a12.1,12.1,0,0,0-8.9-4H171.8a44,44,0,0,0-87.6,0H32.9A12.1,12.1,0,0,0,24,72a11.7,11.7,0,0,0-3,9.3l14.2,128a12,12,0,0,0,12,10.7H208.8a12,12,0,0,0,12-10.7L235,81.3A11.7,11.7,0,0,0,232,72ZM128,36a36.1,36.1,0,0,1,35.8,32H92.2A36.1,36.1,0,0,1,128,36Zm99,44.4-14.2,128a3.9,3.9,0,0,1-4,3.6H47.2a3.9,3.9,0,0,1-4-3.6L29,80.4a3.7,3.7,0,0,1,1-3.1A3.9,3.9,0,0,1,32.9,76H223.1a3.9,3.9,0,0,1,2.9,1.3A3.7,3.7,0,0,1,227,80.4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M235,69.3A15.9,15.9,0,0,0,223.1,64H175.3a48,48,0,0,0-94.6,0H32.9A15.9,15.9,0,0,0,21,69.3a16.2,16.2,0,0,0-4,12.5l14.3,128A15.9,15.9,0,0,0,47.2,224H208.8a15.9,15.9,0,0,0,15.9-14.2L239,81.8A16.2,16.2,0,0,0,235,69.3ZM128,40a32.1,32.1,0,0,1,31,24H97A32.1,32.1,0,0,1,128,40Zm80.8,168H47.2L32.9,80H223.1Z" />
        </>
      )}
    </svg>
  );
}

export function PhTote(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M238,66.7A20,20,0,0,0,223.1,60H178.6A52,52,0,0,0,77.4,60H32.9A20,20,0,0,0,18,66.7a19.6,19.6,0,0,0-4.9,15.5l14.2,128A19.9,19.9,0,0,0,47.2,228H208.8a19.9,19.9,0,0,0,19.9-17.8l14.2-128A19.6,19.6,0,0,0,238,66.7ZM128,44a28,28,0,0,1,25.3,16H102.7A28,28,0,0,1,128,44Zm77.3,160H50.7L37.4,84H76v20a12,12,0,0,0,24,0V84h56v20a12,12,0,0,0,24,0V84h38.6Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M208.8,216H47.2a8.1,8.1,0,0,1-8-7.1L25,80.9A8,8,0,0,1,32.9,72H223.1a8,8,0,0,1,7.9,8.9l-14.2,128A8.1,8.1,0,0,1,208.8,216Z" />
          </g>
          <path d="M235,69.3A15.9,15.9,0,0,0,223.1,64H175.3a48,48,0,0,0-94.6,0H32.9A15.9,15.9,0,0,0,21,69.3a16.2,16.2,0,0,0-4,12.5l14.3,128A15.9,15.9,0,0,0,47.2,224H208.8a15.9,15.9,0,0,0,15.9-14.2L239,81.8A16.2,16.2,0,0,0,235,69.3ZM128,40a32.1,32.1,0,0,1,31,24H97A32.1,32.1,0,0,1,128,40Zm80.8,168H47.2L32.9,80H80v24a8,8,0,0,0,16,0V80h64v24a8,8,0,0,0,16,0V80h47.1Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M235,69.3A15.9,15.9,0,0,0,223.1,64H175.3a48,48,0,0,0-94.6,0H32.9A15.9,15.9,0,0,0,21,69.3a16.2,16.2,0,0,0-4,12.5l14.3,128A15.9,15.9,0,0,0,47.2,224H208.8a15.9,15.9,0,0,0,15.9-14.2L239,81.8A16.2,16.2,0,0,0,235,69.3ZM96,104a8,8,0,0,1-16,0V88a8,8,0,0,1,16,0Zm1-40a32,32,0,0,1,62,0Zm79,40a8,8,0,0,1-16,0V88a8,8,0,0,1,16,0Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M233.5,70.7A14.1,14.1,0,0,0,223.1,66H173.6a46,46,0,0,0-91.2,0H32.9A14.1,14.1,0,0,0,19,81.5l14.2,128a14.1,14.1,0,0,0,14,12.5H208.8a14.1,14.1,0,0,0,14-12.5L237,81.5A14.2,14.2,0,0,0,233.5,70.7ZM128,38a34.1,34.1,0,0,1,33.5,28h-67A34.1,34.1,0,0,1,128,38Zm82.8,170.2a2,2,0,0,1-2,1.8H47.2a2,2,0,0,1-2-1.8L31,80.2a1.8,1.8,0,0,1,.4-1.5,2.1,2.1,0,0,1,1.5-.7H82v26a6,6,0,0,0,12,0V78h68v26a6,6,0,0,0,12,0V78h49.1a2.1,2.1,0,0,1,1.5.7,1.8,1.8,0,0,1,.4,1.5Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M232,72a12.1,12.1,0,0,0-8.9-4H171.8a44,44,0,0,0-87.6,0H32.9A12.1,12.1,0,0,0,24,72a11.7,11.7,0,0,0-3,9.3l14.2,128a12,12,0,0,0,12,10.7H208.8a12,12,0,0,0,12-10.7L235,81.3A11.7,11.7,0,0,0,232,72ZM128,36a36.1,36.1,0,0,1,35.8,32H92.2A36.1,36.1,0,0,1,128,36Zm99,44.4-14.2,128a3.9,3.9,0,0,1-4,3.6H47.2a3.9,3.9,0,0,1-4-3.6L29,80.4a3.7,3.7,0,0,1,1-3.1A3.9,3.9,0,0,1,32.9,76H84v28a4,4,0,0,0,8,0V76h72v28a4,4,0,0,0,8,0V76h51.1a3.9,3.9,0,0,1,2.9,1.3A3.7,3.7,0,0,1,227,80.4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M235,69.3A15.9,15.9,0,0,0,223.1,64H175.3a48,48,0,0,0-94.6,0H32.9A15.9,15.9,0,0,0,21,69.3a16.2,16.2,0,0,0-4,12.5l14.3,128A15.9,15.9,0,0,0,47.2,224H208.8a15.9,15.9,0,0,0,15.9-14.2L239,81.8A16.2,16.2,0,0,0,235,69.3ZM128,40a32.1,32.1,0,0,1,31,24H97A32.1,32.1,0,0,1,128,40Zm80.8,168H47.2L32.9,80H80v24a8,8,0,0,0,16,0V80h64v24a8,8,0,0,0,16,0V80h47.1Z" />
        </>
      )}
    </svg>
  );
}

export function PhTrademarkRegistered(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,20A108,108,0,1,0,236,128,108.1,108.1,0,0,0,128,20Zm0,192a84,84,0,1,1,84-84A84.1,84.1,0,0,1,128,212Zm44-100a36,36,0,0,0-36-36H104A12,12,0,0,0,92,88v80a12,12,0,0,0,24,0V148h15.4l18.8,26.9A11.9,11.9,0,0,0,160,180a11.8,11.8,0,0,0,6.9-2.2,12,12,0,0,0,2.9-16.7l-13.5-19.4A35.9,35.9,0,0,0,172,112Zm-56-12h20a12,12,0,0,1,0,24H116Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M224,128a96,96,0,1,1-96-96A96,96,0,0,1,224,128Z"
            opacity="0.2"
          />
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm40-104a32.1,32.1,0,0,0-32-32H104a8,8,0,0,0-8,8v80a8,8,0,0,0,16,0V144h21.4l20,28.6A8.2,8.2,0,0,0,160,176a8.6,8.6,0,0,0,4.6-1.4,8.1,8.1,0,0,0,2-11.2l-16.1-22.9A32,32,0,0,0,168,112Zm-32,16H112V96h24a16,16,0,0,1,0,32Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,56a72,72,0,1,0,72,72A72.1,72.1,0,0,0,128,56Zm28.5,110.6A8.2,8.2,0,0,1,152,168a8,8,0,0,1-6.6-3.5L131.3,144H116v16a8,8,0,0,1-16,0V96a8,8,0,0,1,8-8h24a28,28,0,0,1,15.5,51.3l11.1,16.2A7.9,7.9,0,0,1,156.5,166.6ZM144,116a12,12,0,0,1-12,12H116V104h16A12,12,0,0,1,144,116ZM128,20A108,108,0,1,0,236,128,108.1,108.1,0,0,0,128,20Zm0,196a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,26A102,102,0,1,0,230,128,102.2,102.2,0,0,0,128,26Zm0,192a90,90,0,1,1,90-90A90.1,90.1,0,0,1,128,218Zm38-106a30.1,30.1,0,0,0-30-30H104a6,6,0,0,0-6,6v80a6,6,0,0,0,12,0V142h24.5l20.6,29.4a5.9,5.9,0,0,0,8.3,1.5,5.9,5.9,0,0,0,1.5-8.3l-17.4-24.9A29.9,29.9,0,0,0,166,112Zm-30,18H110V94h26a18,18,0,0,1,0,36Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,28A100,100,0,1,0,228,128,100.2,100.2,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm36-108a28.1,28.1,0,0,0-28-28H104a4,4,0,0,0-4,4v80a4,4,0,0,0,8,0V140h27.5l21.2,30.3A4,4,0,0,0,160,172a3.9,3.9,0,0,0,2.3-.7,4.1,4.1,0,0,0,1-5.6l-18.9-27A28.1,28.1,0,0,0,164,112Zm-28,20H108V92h28a20,20,0,0,1,0,40Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm40-104a32.1,32.1,0,0,0-32-32H104a8,8,0,0,0-8,8v80a8,8,0,0,0,16,0V144h21.4l20,28.6A8.2,8.2,0,0,0,160,176a8.6,8.6,0,0,0,4.6-1.4,8.1,8.1,0,0,0,2-11.2l-16.1-22.9A32,32,0,0,0,168,112Zm-32,16H112V96h24a16,16,0,0,1,0,32Z" />
        </>
      )}
    </svg>
  );
}

export function PhTrafficCone(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M232,204H216.5L157.2,33.4A20.1,20.1,0,0,0,138.3,20H117.7A20.1,20.1,0,0,0,98.8,33.4L39.5,204H24a12,12,0,0,0,0,24H232a12,12,0,0,0,0-24ZM98.3,108h59.4l13.9,40H84.4Zm22.2-64h15l13.9,40H106.6ZM76,172H180l11.1,32H64.9Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M188.5,160H67.5L89.7,96h76.6Z" opacity="0.2" />
          <path d="M232,208H213.7L153.4,34.7A16,16,0,0,0,138.3,24H117.7a16,16,0,0,0-15.1,10.7L42.3,208H24a8,8,0,0,0,0,16H232a8,8,0,0,0,0-16ZM95.4,104h65.2l16.7,48H78.7Zm22.3-64h20.6L155,88H101ZM73.2,168H182.8l13.9,40H59.3Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M232,208H213.7L153.4,34.7A16,16,0,0,0,138.3,24H117.7a16,16,0,0,0-15.1,10.7L42.3,208H24a8,8,0,0,0,0,16H232a8,8,0,0,0,0-16ZM95.4,104h65.2l16.7,48H78.7Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M232,210H212.3L151.5,35.4A13.9,13.9,0,0,0,138.3,26H117.7a13.9,13.9,0,0,0-13.2,9.4L43.7,210H24a6,6,0,0,0,0,12H232a6,6,0,0,0,0-12ZM94,102h68l18.1,52H75.9Zm21.8-62.7a2,2,0,0,1,1.9-1.3h20.6a2,2,0,0,1,1.9,1.3L157.8,90H98.2ZM71.7,166H184.3l15.3,44H56.4Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M232,212H210.8L149.6,36.1A11.8,11.8,0,0,0,138.3,28H117.7a11.8,11.8,0,0,0-11.3,8.1L45.2,212H24a4,4,0,0,0,0,8H232a4,4,0,0,0,0-8ZM92.6,100h70.8l19.5,56H73.1Zm21.3-61.3a4.1,4.1,0,0,1,3.8-2.7h20.6a4.1,4.1,0,0,1,3.8,2.7L160.6,92H95.4ZM70.3,164H185.7l16.7,48H53.6Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M232,208H213.7L153.4,34.7A16,16,0,0,0,138.3,24H117.7a16,16,0,0,0-15.1,10.7L42.3,208H24a8,8,0,0,0,0,16H232a8,8,0,0,0,0-16ZM95.4,104h65.2l16.7,48H78.7Zm22.3-64h20.6L155,88H101ZM73.2,168H182.8l13.9,40H59.3Z" />
        </>
      )}
    </svg>
  );
}

export function PhTrafficSign(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M135.5,104.5a12,12,0,0,1,17-17l24,24h0a6,6,0,0,1,.8.8l.3.4.4.5.3.6.3.4a1.4,1.4,0,0,1,.2.6l.3.5c.1.2.1.4.2.6s.1.3.2.5.1.4.1.6.1.4.2.6a1.3,1.3,0,0,0,.1.6v.5a9.6,9.6,0,0,1,0,2.4v.5a1.3,1.3,0,0,0-.1.6c-.1.2-.1.4-.2.6s-.1.4-.1.6-.1.4-.2.5-.1.4-.2.6l-.3.5a1.4,1.4,0,0,1-.2.6l-.3.4-.3.6-.4.5-.3.4-.8.8h0l-24,24a12.1,12.1,0,0,1-17,0,12,12,0,0,1,0-17L139,132H112a12,12,0,0,0-12,12v4a12,12,0,0,1-24,0v-4a36,36,0,0,1,36-36h27Zm111.3,37.6L142.1,246.8a19.8,19.8,0,0,1-28.2,0L9.2,142.1a19.9,19.9,0,0,1,0-28.2L113.9,9.2a19.8,19.8,0,0,1,28.2,0L246.8,113.9A19.9,19.9,0,0,1,246.8,142.1ZM227,128,128,29,29,128l99,99Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M238.3,133.7,133.7,238.3a8,8,0,0,1-11.4,0L17.7,133.7a8,8,0,0,1,0-11.4L122.3,17.7a8,8,0,0,1,11.4,0L238.3,122.3A8,8,0,0,1,238.3,133.7Z"
            opacity="0.2"
          />
          <path d="M138.3,101.7a8.1,8.1,0,0,1,11.4-11.4l24,24h0l.5.5.2.3.3.4c0,.1.1.2.2.3l.2.3v.4l.2.3c0,.2.1.3.1.4l.2.4v.3a.8.8,0,0,1,.1.4.9.9,0,0,1,.1.5.4.4,0,0,0,.1.3v.8h0v.8a.4.4,0,0,0-.1.3.9.9,0,0,1-.1.5.8.8,0,0,1-.1.4v.3l-.2.4c0,.1-.1.2-.1.4l-.2.3v.4l-.2.3c-.1.1-.2.2-.2.3l-.3.4-.2.3-.5.5h0l-24,24a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L148.7,128H112a16,16,0,0,0-16,16v8a8,8,0,0,1-16,0v-8a32.1,32.1,0,0,1,32-32h36.7ZM244,139.3,139.3,244a15.9,15.9,0,0,1-22.6,0L12,139.3a16.1,16.1,0,0,1,0-22.6L116.7,12a15.9,15.9,0,0,1,22.6,0L244,116.7A16.1,16.1,0,0,1,244,139.3ZM232.7,128,128,23.3,23.3,128,128,232.7h0Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M244,116.7,139.3,12a15.9,15.9,0,0,0-22.6,0L12,116.7a16.1,16.1,0,0,0,0,22.6L116.7,244a15.9,15.9,0,0,0,22.6,0L244,139.3A16.1,16.1,0,0,0,244,116.7ZM176,120v.8a.4.4,0,0,0-.1.3.9.9,0,0,1-.1.5.8.8,0,0,1-.1.4v.3l-.2.4c0,.1-.1.2-.1.4l-.2.3v.4l-.2.3c-.1.1-.2.2-.2.3l-.3.4-.2.3-.5.5h0l-24,24a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L148.7,128H112a16,16,0,0,0-16,16v8a8,8,0,0,1-16,0v-8a32.1,32.1,0,0,1,32-32h36.7l-10.4-10.3a8.1,8.1,0,0,1,11.4-11.4l24,24h0l.5.5.2.3.3.4c0,.1.1.2.2.3l.2.3v.4l.2.3c0,.2.1.3.1.4l.2.4v.3a.8.8,0,0,1,.1.4.9.9,0,0,1,.1.5.4.4,0,0,0,.1.3v.8Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M242.6,118.1,137.9,13.4a14,14,0,0,0-19.8,0L13.4,118.1a14.1,14.1,0,0,0,0,19.8L118.1,242.6a14,14,0,0,0,19.8,0L242.6,137.9A14.1,14.1,0,0,0,242.6,118.1Zm-8.5,11.3L129.4,234.1a1.9,1.9,0,0,1-2.8,0h0L21.9,129.4a1.9,1.9,0,0,1,0-2.8L126.6,21.9a1.9,1.9,0,0,1,2.8,0L234.1,126.6A1.9,1.9,0,0,1,234.1,129.4ZM173,123.3h0a2.4,2.4,0,0,1-.8.9l-24,24a5.9,5.9,0,0,1-8.4-8.4L153.5,126H112a18.1,18.1,0,0,0-18,18v8a6,6,0,0,1-12,0v-8a30.1,30.1,0,0,1,30-30h41.5l-13.7-13.8a5.9,5.9,0,0,1,8.4-8.4l24,24a2.4,2.4,0,0,1,.8.9h0a9.3,9.3,0,0,1,.5,1h.1l.3,1h0a6.1,6.1,0,0,1,0,2.2h0l-.3,1h-.1A9.3,9.3,0,0,1,173,123.3Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M241.2,119.5,136.5,14.8a12.1,12.1,0,0,0-17,0L14.8,119.5a12.2,12.2,0,0,0,0,17L119.5,241.2a12.1,12.1,0,0,0,17,0L241.2,136.5A12.2,12.2,0,0,0,241.2,119.5Zm-5.7,11.3L130.8,235.5a4,4,0,0,1-5.6,0h0L20.5,130.8a3.9,3.9,0,0,1,0-5.6L125.2,20.5a3.9,3.9,0,0,1,5.6,0L235.5,125.2A3.9,3.9,0,0,1,235.5,130.8Zm-64-8.9-.2.3-.5.6-24,24a3.9,3.9,0,0,1-5.6,0,3.9,3.9,0,0,1,0-5.6L158.3,124H112a20.1,20.1,0,0,0-20,20v8a4,4,0,0,1-8,0v-8a28.1,28.1,0,0,1,28-28h46.3L141.2,98.8a4,4,0,0,1,5.6-5.6l24,24,.5.6.2.3.2.4c0,.1.1.2.1.4a.4.4,0,0,1,.1.3,4.3,4.3,0,0,1,0,1.6.4.4,0,0,1-.1.3c0,.2-.1.3-.1.4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M138.3,101.7a8.1,8.1,0,0,1,11.4-11.4l24,24h0l.5.5.2.3.3.4c0,.1.1.2.2.3l.2.3v.4l.2.3c0,.2.1.3.1.4l.2.4v.3a.8.8,0,0,1,.1.4.9.9,0,0,1,.1.5.4.4,0,0,0,.1.3v1.6a.4.4,0,0,0-.1.3.9.9,0,0,1-.1.5.8.8,0,0,1-.1.4v.3l-.2.4c0,.1-.1.2-.1.4l-.2.3v.4l-.2.3c-.1.1-.2.2-.2.3l-.3.4-.2.3-.5.5h0l-24,24a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L148.7,128H112a16,16,0,0,0-16,16v8a8,8,0,0,1-16,0v-8a32.1,32.1,0,0,1,32-32h36.7ZM244,139.3,139.3,244a15.9,15.9,0,0,1-22.6,0L12,139.3a16.1,16.1,0,0,1,0-22.6L116.7,12a15.9,15.9,0,0,1,22.6,0L244,116.7A16.1,16.1,0,0,1,244,139.3ZM232.7,128,128,23.3,23.3,128,128,232.7h0Z" />
        </>
      )}
    </svg>
  );
}

export function PhTrafficSignal(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216,140H204V84h12a12,12,0,0,0,0-24H204V40a20.1,20.1,0,0,0-20-20H72A20.1,20.1,0,0,0,52,40V60H40a12,12,0,0,0,0,24H52v56H40a12,12,0,0,0,0,24H52v52a20.1,20.1,0,0,0,20,20H184a20.1,20.1,0,0,0,20-20V164h12a12,12,0,0,0,0-24Zm-36,72H76V44H180Zm-52-92A32,32,0,1,0,96,88,32.1,32.1,0,0,0,128,120Zm0-40a8,8,0,1,1-8,8A8,8,0,0,1,128,80Zm0,120a32,32,0,1,0-32-32A32.1,32.1,0,0,0,128,200Zm0-40a8,8,0,1,1-8,8A8,8,0,0,1,128,160Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M184,32H72a8,8,0,0,0-8,8V216a8,8,0,0,0,8,8H184a8,8,0,0,0,8-8V40A8,8,0,0,0,184,32ZM128,192a24,24,0,1,1,24-24A23.9,23.9,0,0,1,128,192Zm0-80a24,24,0,1,1,24-24A23.9,23.9,0,0,1,128,112Z"
            opacity="0.2"
          />
          <path d="M128,56a32,32,0,1,0,32,32A32.1,32.1,0,0,0,128,56Zm0,48a16,16,0,1,1,16-16A16,16,0,0,1,128,104Zm0,32a32,32,0,1,0,32,32A32.1,32.1,0,0,0,128,136Zm0,48a16,16,0,1,1,16-16A16,16,0,0,1,128,184Zm88-40H200V80h16a8,8,0,0,0,0-16H200V40a16,16,0,0,0-16-16H72A16,16,0,0,0,56,40V64H40a8,8,0,0,0,0,16H56v64H40a8,8,0,0,0,0,16H56v56a16,16,0,0,0,16,16H184a16,16,0,0,0,16-16V160h16a8,8,0,0,0,0-16Zm-32,72H72V40H184V216Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,144H200V80h16a8,8,0,0,0,0-16H200V40a16,16,0,0,0-16-16H72A16,16,0,0,0,56,40V64H40a8,8,0,0,0,0,16H56v64H40a8,8,0,0,0,0,16H56v56a16,16,0,0,0,16,16H184a16,16,0,0,0,16-16V160h16a8,8,0,0,0,0-16Zm-88-28a28,28,0,1,1,28-28A28.1,28.1,0,0,1,128,116Zm0,24a28,28,0,1,1-28,28A28.1,28.1,0,0,1,128,140Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,58a30,30,0,1,0,30,30A30.1,30.1,0,0,0,128,58Zm0,48a18,18,0,1,1,18-18A18.1,18.1,0,0,1,128,106Zm0,32a30,30,0,1,0,30,30A30.1,30.1,0,0,0,128,138Zm0,48a18,18,0,1,1,18-18A18.1,18.1,0,0,1,128,186Zm88-40H198V78h18a6,6,0,0,0,0-12H198V40a14,14,0,0,0-14-14H72A14,14,0,0,0,58,40V66H40a6,6,0,0,0,0,12H58v68H40a6,6,0,0,0,0,12H58v58a14,14,0,0,0,14,14H184a14,14,0,0,0,14-14V158h18a6,6,0,0,0,0-12Zm-30,70a2,2,0,0,1-2,2H72a2,2,0,0,1-2-2V40a2,2,0,0,1,2-2H184a2,2,0,0,1,2,2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,60a28,28,0,1,0,28,28A28.1,28.1,0,0,0,128,60Zm0,48a20,20,0,1,1,20-20A20.1,20.1,0,0,1,128,108Zm0,32a28,28,0,1,0,28,28A28.1,28.1,0,0,0,128,140Zm0,48a20,20,0,1,1,20-20A20.1,20.1,0,0,1,128,188Zm88-40H196V76h20a4,4,0,0,0,0-8H196V40a12,12,0,0,0-12-12H72A12,12,0,0,0,60,40V68H40a4,4,0,0,0,0,8H60v72H40a4,4,0,0,0,0,8H60v60a12,12,0,0,0,12,12H184a12,12,0,0,0,12-12V156h20a4,4,0,0,0,0-8Zm-28,68a4,4,0,0,1-4,4H72a4,4,0,0,1-4-4V40a4,4,0,0,1,4-4H184a4,4,0,0,1,4,4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,56a32,32,0,1,0,32,32A32.1,32.1,0,0,0,128,56Zm0,48a16,16,0,1,1,16-16A16,16,0,0,1,128,104Zm0,32a32,32,0,1,0,32,32A32.1,32.1,0,0,0,128,136Zm0,48a16,16,0,1,1,16-16A16,16,0,0,1,128,184Zm88-40H200V80h16a8,8,0,0,0,0-16H200V40a16,16,0,0,0-16-16H72A16,16,0,0,0,56,40V64H40a8,8,0,0,0,0,16H56v64H40a8,8,0,0,0,0,16H56v56a16,16,0,0,0,16,16H184a16,16,0,0,0,16-16V160h16a8,8,0,0,0,0-16Zm-32,72H72V40H184V216Z" />
        </>
      )}
    </svg>
  );
}

export function PhTrainRegional(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M227.9,121.6a7.6,7.6,0,0,0,.1-1.5v-.3a4.9,4.9,0,0,0-.1-1.2v-.4a.4.4,0,0,1-.1-.3,1.9,1.9,0,0,0-.2-.8v-.3L205.2,34.7A20.1,20.1,0,0,0,185.9,20H70.1A20.1,20.1,0,0,0,50.8,34.7L28.4,116.8v.3a1.9,1.9,0,0,0-.2.8.4.4,0,0,1-.1.3v.4a4.9,4.9,0,0,0-.1,1.2v.3a7.6,7.6,0,0,0,.1,1.5h0l.3,1.3v.2l22.4,82.1A20.1,20.1,0,0,0,70.1,220H72l-9.6,12.8a12,12,0,0,0,2.4,16.8,12,12,0,0,0,16.8-2.4L102,220h52l20.4,27.2a12,12,0,0,0,16.8,2.4,12,12,0,0,0,2.4-16.8L184,220h1.9a20.1,20.1,0,0,0,19.3-14.7l22.4-82.1V123l.3-1.3ZM73.2,44H182.8L201,110.5l-73,13.3L55,110.5ZM56.6,135.2,116,146v50H73.2ZM182.8,196H140V146l59.4-10.8ZM84,88A12,12,0,0,1,96,76h64a12,12,0,0,1,0,24H96A12,12,0,0,1,84,88Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M40,120l88,16,88-16-22.4,82.1a7.9,7.9,0,0,1-7.7,5.9H70.1a7.9,7.9,0,0,1-7.7-5.9Z" />
          </g>
          <path d="M223.9,121a2.8,2.8,0,0,0,.1-.9v-.2a2.2,2.2,0,0,0-.1-.8v-.5c-.1-.2-.1-.4-.2-.6h0l-22.4-82A15.9,15.9,0,0,0,185.9,24H70.1A15.9,15.9,0,0,0,54.7,35.8l-22.4,82h0c-.1.2-.1.4-.2.6v.5a2.2,2.2,0,0,0-.1.8v.2a2.8,2.8,0,0,0,.1.9h0a2.9,2.9,0,0,0,.2.9h0l22.4,82.1A15.9,15.9,0,0,0,70.1,216H80L65.6,235.2a8,8,0,0,0,1.6,11.2A7.7,7.7,0,0,0,72,248a8,8,0,0,0,6.4-3.2L100,216h56l21.6,28.8A8,8,0,0,0,184,248a7.7,7.7,0,0,0,4.8-1.6,8,8,0,0,0,1.6-11.2L176,216h9.9a15.9,15.9,0,0,0,15.4-11.8l22.4-82.1h0a2.9,2.9,0,0,0,.2-.9ZM70.1,40H185.9L206,113.7l-78,14.2L50,113.7Zm-19,90.1L120,142.7V200H70.1ZM185.9,200H136V142.7l68.9-12.6Z" />
          <path d="M96,96h64a8,8,0,0,0,0-16H96a8,8,0,0,0,0,16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M168,88a8,8,0,0,1-8,8H96a8,8,0,0,1,0-16h64A8,8,0,0,1,168,88Zm56,32.1a2.8,2.8,0,0,1-.1.9h0a2.9,2.9,0,0,1-.2.9h0l-22.4,82.1A15.9,15.9,0,0,1,185.9,216H176l14.4,19.2a8,8,0,0,1-1.6,11.2A7.7,7.7,0,0,1,184,248a8,8,0,0,1-6.4-3.2L156,216H100L78.4,244.8A8,8,0,0,1,72,248a7.7,7.7,0,0,1-4.8-1.6,8,8,0,0,1-1.6-11.2L80,216H70.1a15.9,15.9,0,0,1-15.4-11.8L32.3,122.1h0a2.9,2.9,0,0,1-.2-.9h0a2.8,2.8,0,0,1-.1-.9v-.2a2.2,2.2,0,0,1,.1-.8v-.5c.1-.2.1-.4.2-.6h0l22.4-82A15.9,15.9,0,0,1,70.1,24H185.9a15.9,15.9,0,0,1,15.4,11.8l22.4,82h0c.1.2.1.4.2.6v.5a2.2,2.2,0,0,1,.1.8ZM136,152a8,8,0,0,0-16,0v40a8,8,0,0,0,16,0Zm70-38.3L185.9,40H70.1L50,113.7l78,14.2Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M221.9,120.8a2.2,2.2,0,0,0,.1-.8h0v-.6c-.1-.1-.1-.1-.1-.2v-.2c0-.1-.1-.2-.1-.4h0l-22.4-82A14,14,0,0,0,185.9,26H70.1A14,14,0,0,0,56.6,36.3l-22.4,82h0c0,.2-.1.3-.1.4v.2c0,.1,0,.1-.1.2v.6h0a2.2,2.2,0,0,0,.1.8h0a1.5,1.5,0,0,0,.1.7h0l22.4,82.1A14,14,0,0,0,70.1,214H84L67.2,236.4a6,6,0,1,0,9.6,7.2L99,214h58l22.2,29.6a6,6,0,0,0,9.6-7.2L172,214h13.9a14,14,0,0,0,13.5-10.3l22.4-82.1h0a1.5,1.5,0,0,0,.1-.7ZM68.2,39.5A1.9,1.9,0,0,1,70.1,38H185.9a1.9,1.9,0,0,1,1.9,1.5l20.7,75.8L128,129.9,47.5,115.3Zm0,161L48.3,127.6,122,141v61H70.1A1.9,1.9,0,0,1,68.2,200.5Zm119.6,0a1.9,1.9,0,0,1-1.9,1.5H134V141l73.7-13.4ZM90,88a6,6,0,0,1,6-6h64a6,6,0,0,1,0,12H96A6,6,0,0,1,90,88Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M220,120.2h0a1.5,1.5,0,0,0-.1-.7h0v-.3L197.5,36.8A12.1,12.1,0,0,0,185.9,28H70.1a12.1,12.1,0,0,0-11.6,8.8L36.1,118.9v.3h0a1.5,1.5,0,0,0-.1.7h0a1.3,1.3,0,0,0,.1.6h0v.2l22.4,82.1A12.1,12.1,0,0,0,70.1,212H88L68.8,237.6a4,4,0,0,0,.8,5.6,4.3,4.3,0,0,0,2.4.8,4.2,4.2,0,0,0,3.2-1.6L98,212h60l22.8,30.4A4.2,4.2,0,0,0,184,244a4.3,4.3,0,0,0,2.4-.8,4,4,0,0,0,.8-5.6L168,212h17.9a12.1,12.1,0,0,0,11.6-8.8l22.4-82.1v-.2h0A1.3,1.3,0,0,0,220,120.2ZM66.3,38.9A3.9,3.9,0,0,1,70.1,36H185.9a3.9,3.9,0,0,1,3.8,2.9L211,116.8l-83,15.1L45,116.8Zm0,162.2-20.8-76L124,139.3V204H70.1A3.9,3.9,0,0,1,66.3,201.1Zm123.4,0a3.9,3.9,0,0,1-3.8,2.9H132V139.3l78.5-14.2ZM92,88a4,4,0,0,1,4-4h64a4,4,0,0,1,0,8H96A4,4,0,0,1,92,88Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M223.9,121a2.8,2.8,0,0,0,.1-.9v-.2a2.2,2.2,0,0,0-.1-.8v-.5c-.1-.2-.1-.4-.2-.6h0l-22.4-82A15.9,15.9,0,0,0,185.9,24H70.1A15.9,15.9,0,0,0,54.7,35.8l-22.4,82h0c-.1.2-.1.4-.2.6v.5a2.2,2.2,0,0,0-.1.8v.2a2.8,2.8,0,0,0,.1.9h0a2.9,2.9,0,0,0,.2.9h0l22.4,82.1A15.9,15.9,0,0,0,70.1,216H80L65.6,235.2a8,8,0,0,0,1.6,11.2A7.7,7.7,0,0,0,72,248a8,8,0,0,0,6.4-3.2L100,216h56l21.6,28.8A8,8,0,0,0,184,248a7.7,7.7,0,0,0,4.8-1.6,8,8,0,0,0,1.6-11.2L176,216h9.9a15.9,15.9,0,0,0,15.4-11.8l22.4-82.1h0a2.9,2.9,0,0,0,.2-.9ZM70.1,40H185.9L206,113.7l-78,14.2L50,113.7Zm-19,90.1L120,142.7V200H70.1ZM185.9,200H136V142.7l68.9-12.6ZM88,88a8,8,0,0,1,8-8h64a8,8,0,0,1,0,16H96A8,8,0,0,1,88,88Z" />
        </>
      )}
    </svg>
  );
}

export function PhTrainSimple(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M188,20H68A36,36,0,0,0,32,56V184a36,36,0,0,0,36,36h4l-9.6,12.8a12,12,0,0,0,2.4,16.8,12,12,0,0,0,16.8-2.4L102,220h52l20.4,27.2a12,12,0,0,0,16.8,2.4,12,12,0,0,0,2.4-16.8L184,220h4a36,36,0,0,0,36-36V56A36,36,0,0,0,188,20ZM68,44H188a12,12,0,0,1,12,12v60H56V56A12,12,0,0,1,68,44ZM188,196H68a12,12,0,0,1-12-12V140H200v44A12,12,0,0,1,188,196Zm-84-28a16,16,0,1,1-16-16A16,16,0,0,1,104,168Zm80,0a16,16,0,1,1-16-16A16,16,0,0,1,184,168Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M212,56a23.9,23.9,0,0,0-24-24H68A23.9,23.9,0,0,0,44,56v72H212Z" />
          </g>
          <path d="M188,24H68A32.1,32.1,0,0,0,36,56V184a32.1,32.1,0,0,0,32,32H80L65.6,235.2a8,8,0,0,0,1.6,11.2A7.7,7.7,0,0,0,72,248a8,8,0,0,0,6.4-3.2L100,216h56l21.6,28.8A8,8,0,0,0,184,248a7.7,7.7,0,0,0,4.8-1.6,8,8,0,0,0,1.6-11.2L176,216h12a32.1,32.1,0,0,0,32-32V56A32.1,32.1,0,0,0,188,24ZM68,40H188a16,16,0,0,1,16,16v64H52V56A16,16,0,0,1,68,40ZM188,200H68a16,16,0,0,1-16-16V136H204v48A16,16,0,0,1,188,200Z" />
          <circle cx="84" cy="172" r="12" />
          <circle cx="172" cy="172" r="12" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M188,24H68A32.1,32.1,0,0,0,36,56V184a32.1,32.1,0,0,0,32,32H80L65.6,235.2a8,8,0,0,0,1.6,11.2A7.7,7.7,0,0,0,72,248a8,8,0,0,0,6.4-3.2L100,216h56l21.6,28.8A8,8,0,0,0,184,248a7.7,7.7,0,0,0,4.8-1.6,8,8,0,0,0,1.6-11.2L176,216h12a32.1,32.1,0,0,0,32-32V56A32.1,32.1,0,0,0,188,24Zm0,176H68a16,16,0,0,1-16-16V136H204v48A16,16,0,0,1,188,200ZM96,172a12,12,0,1,1-12-12A12,12,0,0,1,96,172Zm88,0a12,12,0,1,1-12-12A12,12,0,0,1,184,172Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M188,26H68A30.1,30.1,0,0,0,38,56V184a30.1,30.1,0,0,0,30,30H84L67.2,236.4a6,6,0,1,0,9.6,7.2L99,214h58l22.2,29.6a6,6,0,0,0,9.6-7.2L172,214h16a30.1,30.1,0,0,0,30-30V56A30.1,30.1,0,0,0,188,26ZM68,38H188a18.1,18.1,0,0,1,18,18v66H50V56A18.1,18.1,0,0,1,68,38ZM188,202H68a18.1,18.1,0,0,1-18-18V134H206v50A18.1,18.1,0,0,1,188,202ZM94,172a10,10,0,1,1-10-10A10,10,0,0,1,94,172Zm88,0a10,10,0,1,1-10-10A10,10,0,0,1,182,172Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M188,28H68A28.1,28.1,0,0,0,40,56V184a28.1,28.1,0,0,0,28,28H88L68.8,237.6a4,4,0,0,0,.8,5.6,4.3,4.3,0,0,0,2.4.8,4.2,4.2,0,0,0,3.2-1.6L98,212h60l22.8,30.4A4.2,4.2,0,0,0,184,244a4.3,4.3,0,0,0,2.4-.8,4,4,0,0,0,.8-5.6L168,212h20a28.1,28.1,0,0,0,28-28V56A28.1,28.1,0,0,0,188,28ZM68,36H188a20.1,20.1,0,0,1,20,20v68H48V56A20.1,20.1,0,0,1,68,36ZM188,204H68a20.1,20.1,0,0,1-20-20V132H208v52A20.1,20.1,0,0,1,188,204ZM92,172a8,8,0,1,1-8-8A8,8,0,0,1,92,172Zm88,0a8,8,0,1,1-8-8A8,8,0,0,1,180,172Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M188,24H68A32.1,32.1,0,0,0,36,56V184a32.1,32.1,0,0,0,32,32H80L65.6,235.2a8,8,0,0,0,1.6,11.2A7.7,7.7,0,0,0,72,248a8,8,0,0,0,6.4-3.2L100,216h56l21.6,28.8A8,8,0,0,0,184,248a7.7,7.7,0,0,0,4.8-1.6,8,8,0,0,0,1.6-11.2L176,216h12a32.1,32.1,0,0,0,32-32V56A32.1,32.1,0,0,0,188,24ZM68,40H188a16,16,0,0,1,16,16v64H52V56A16,16,0,0,1,68,40ZM188,200H68a16,16,0,0,1-16-16V136H204v48A16,16,0,0,1,188,200ZM96,172a12,12,0,1,1-12-12A12,12,0,0,1,96,172Zm88,0a12,12,0,1,1-12-12A12,12,0,0,1,184,172Z" />
        </>
      )}
    </svg>
  );
}

export function PhTrain(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M188,20H68A36,36,0,0,0,32,56V184a36,36,0,0,0,36,36h4l-9.6,12.8a12,12,0,0,0,2.4,16.8,12,12,0,0,0,16.8-2.4L102,220h52l20.4,27.2a12,12,0,0,0,16.8,2.4,12,12,0,0,0,2.4-16.8L184,220h4a36,36,0,0,0,36-36V56A36,36,0,0,0,188,20ZM56,116V84h60v32Zm84-32h60v32H140ZM68,44H188a12,12,0,0,1,12,12v4H56V56A12,12,0,0,1,68,44ZM188,196H68a12,12,0,0,1-12-12V140H200v44A12,12,0,0,1,188,196Zm-84-28a16,16,0,1,1-16-16A16,16,0,0,1,104,168Zm80,0a16,16,0,1,1-16-16A16,16,0,0,1,184,168Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect x="44" y="72" width="168" height="56" />
          </g>
          <path d="M188,24H68A32.1,32.1,0,0,0,36,56V184a32.1,32.1,0,0,0,32,32H80L65.6,235.2a8,8,0,0,0,1.6,11.2A7.7,7.7,0,0,0,72,248a8,8,0,0,0,6.4-3.2L100,216h56l21.6,28.8A8,8,0,0,0,184,248a7.7,7.7,0,0,0,4.8-1.6,8,8,0,0,0,1.6-11.2L176,216h12a32.1,32.1,0,0,0,32-32V56A32.1,32.1,0,0,0,188,24ZM52,120V80h68v40Zm84-40h68v40H136ZM68,40H188a16,16,0,0,1,16,16v8H52V56A16,16,0,0,1,68,40ZM188,200H68a16,16,0,0,1-16-16V136H204v48A16,16,0,0,1,188,200Z" />
          <circle cx="84" cy="172" r="12" />
          <circle cx="172" cy="172" r="12" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M188,24H68A32.1,32.1,0,0,0,36,56V184a32.1,32.1,0,0,0,32,32H80L65.6,235.2a8,8,0,0,0,1.6,11.2A7.7,7.7,0,0,0,72,248a8,8,0,0,0,6.4-3.2L100,216h56l21.6,28.8A8,8,0,0,0,184,248a7.7,7.7,0,0,0,4.8-1.6,8,8,0,0,0,1.6-11.2L176,216h12a32.1,32.1,0,0,0,32-32V56A32.1,32.1,0,0,0,188,24ZM84,184a12,12,0,1,1,12-12A12,12,0,0,1,84,184Zm36-64H52V80h68Zm52,64a12,12,0,1,1,12-12A12,12,0,0,1,172,184Zm32-64H136V80h68Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M188,26H68A30.1,30.1,0,0,0,38,56V184a30.1,30.1,0,0,0,30,30H84L67.2,236.4a6,6,0,1,0,9.6,7.2L99,214h58l22.2,29.6a6,6,0,0,0,9.6-7.2L172,214h16a30.1,30.1,0,0,0,30-30V56A30.1,30.1,0,0,0,188,26ZM50,122V78h72v44Zm84-44h72v44H134ZM68,38H188a18.1,18.1,0,0,1,18,18V66H50V56A18.1,18.1,0,0,1,68,38ZM188,202H68a18.1,18.1,0,0,1-18-18V134H206v50A18.1,18.1,0,0,1,188,202ZM94,172a10,10,0,1,1-10-10A10,10,0,0,1,94,172Zm88,0a10,10,0,1,1-10-10A10,10,0,0,1,182,172Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M188,28H68A28.1,28.1,0,0,0,40,56V184a28.1,28.1,0,0,0,28,28H88L68.8,237.6a4,4,0,0,0,.8,5.6,4.3,4.3,0,0,0,2.4.8,4.2,4.2,0,0,0,3.2-1.6L98,212h60l22.8,30.4A4.2,4.2,0,0,0,184,244a4.3,4.3,0,0,0,2.4-.8,4,4,0,0,0,.8-5.6L168,212h20a28.1,28.1,0,0,0,28-28V56A28.1,28.1,0,0,0,188,28ZM48,124V76h76v48Zm84-48h76v48H132ZM68,36H188a20.1,20.1,0,0,1,20,20V68H48V56A20.1,20.1,0,0,1,68,36ZM188,204H68a20.1,20.1,0,0,1-20-20V132H208v52A20.1,20.1,0,0,1,188,204ZM92,172a8,8,0,1,1-8-8A8,8,0,0,1,92,172Zm88,0a8,8,0,1,1-8-8A8,8,0,0,1,180,172Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M188,24H68A32.1,32.1,0,0,0,36,56V184a32.1,32.1,0,0,0,32,32H80L65.6,235.2a8,8,0,0,0,1.6,11.2A7.7,7.7,0,0,0,72,248a8,8,0,0,0,6.4-3.2L100,216h56l21.6,28.8A8,8,0,0,0,184,248a7.7,7.7,0,0,0,4.8-1.6,8,8,0,0,0,1.6-11.2L176,216h12a32.1,32.1,0,0,0,32-32V56A32.1,32.1,0,0,0,188,24ZM52,120V80h68v40Zm84-40h68v40H136ZM68,40H188a16,16,0,0,1,16,16v8H52V56A16,16,0,0,1,68,40ZM188,200H68a16,16,0,0,1-16-16V136H204v48A16,16,0,0,1,188,200ZM96,172a12,12,0,1,1-12-12A12,12,0,0,1,96,172Zm88,0a12,12,0,1,1-12-12A12,12,0,0,1,184,172Z" />
        </>
      )}
    </svg>
  );
}

export function PhTranslate(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M242.7,210.6l-56-112a12,12,0,0,0-21.4,0l-20.5,41A84.2,84.2,0,0,1,106,126.2,107.3,107.3,0,0,0,131.3,68H152a12,12,0,0,0,0-24H100V32a12,12,0,0,0-24,0V44H24a12,12,0,0,0,0,24h83.1A83.3,83.3,0,0,1,88,110.3,83.8,83.8,0,0,1,75.6,91a12,12,0,1,0-21.8,10A103.4,103.4,0,0,0,70,126.2,83.3,83.3,0,0,1,24,140a12,12,0,0,0,0,24,107,107,0,0,0,64-21.1,108.3,108.3,0,0,0,45.4,19.4l-24.1,48.3a12,12,0,1,0,21.4,10.8L143.4,196h65.2l12.7,25.4a12,12,0,0,0,21.4-10.8ZM155.4,172,176,130.8,196.6,172Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M239.1,212.4l-56-112A7.9,7.9,0,0,0,176,96a8,8,0,0,0-7.2,4.4l-21.7,43.4A87.4,87.4,0,0,1,100,126.9,104.1,104.1,0,0,0,127.7,64H152a8,8,0,0,0,0-16H96V32a8,8,0,0,0-16,0V48H24a8,8,0,0,0,0,16h87.6A87.5,87.5,0,0,1,88,116.3a87.1,87.1,0,0,1-19-31,8,8,0,0,0-10.2-4.8,7.9,7.9,0,0,0-4.9,10.2A103.8,103.8,0,0,0,76,126.9,87.1,87.1,0,0,1,24,144a8,8,0,0,0,0,16,103.8,103.8,0,0,0,64-22.1,103.6,103.6,0,0,0,51.4,21.3l-26.6,53.2a8,8,0,1,0,14.3,7.2L140.9,192H211l13.8,27.6A8,8,0,0,0,232,224a9.4,9.4,0,0,0,3.6-.8A8.1,8.1,0,0,0,239.1,212.4ZM148.9,176,176,121.9,203,176Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M239.1,212.4l-56-112A7.9,7.9,0,0,0,176,96a8.2,8.2,0,0,0-7.2,4.4l-21.7,43.4A87.4,87.4,0,0,1,100,126.9,104.1,104.1,0,0,0,127.7,64H152a8,8,0,0,0,0-16H96V32a8,8,0,0,0-16,0V48H24a8,8,0,0,0,0,16h87.6A87.5,87.5,0,0,1,88,116.3a87.1,87.1,0,0,1-19-31,8,8,0,0,0-10.2-4.8,7.9,7.9,0,0,0-4.9,10.2A103.8,103.8,0,0,0,76,126.9,87.1,87.1,0,0,1,24,144a8,8,0,0,0,0,16,103.8,103.8,0,0,0,64-22.1,103.6,103.6,0,0,0,51.4,21.3l-26.6,53.2a8.2,8.2,0,0,0,3.6,10.8,8,8,0,0,0,10.7-3.6L140.9,192H211l13.8,27.6A8.1,8.1,0,0,0,232,224a9.4,9.4,0,0,0,3.6-.8A8.1,8.1,0,0,0,239.1,212.4ZM148.9,176,176,121.9,203,176Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M237.4,213.3l-56-112A6.2,6.2,0,0,0,176,98a6,6,0,0,0-5.4,3.3l-22.3,44.6A90,90,0,0,1,97,127.2,102.1,102.1,0,0,0,125.8,62H152a6,6,0,0,0,0-12H94V32a6,6,0,0,0-12,0V50H24a6,6,0,0,0,0,12h89.8A90.1,90.1,0,0,1,88,119.2,90.2,90.2,0,0,1,67.1,86a6,6,0,0,0-7.6-3.7A6.1,6.1,0,0,0,55.8,90,102.3,102.3,0,0,0,79,127.2,89.4,89.4,0,0,1,24,146a6,6,0,0,0,0,12,101.8,101.8,0,0,0,64-22.6,102.9,102.9,0,0,0,54.5,22.1l-27.9,55.8a6,6,0,0,0,2.7,8.1,6.2,6.2,0,0,0,8.1-2.7L139.7,190h72.6l14.3,28.7A6.1,6.1,0,0,0,232,222a6.6,6.6,0,0,0,2.7-.6A6.2,6.2,0,0,0,237.4,213.3ZM145.7,178,176,117.4,206.3,178Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M235.6,214.2l-56-112a4,4,0,0,0-7.2,0l-22.8,45.7A91.3,91.3,0,0,1,94,127.3,99.3,99.3,0,0,0,123.9,60H152a4,4,0,0,0,0-8H92V32a4,4,0,0,0-8,0V52H24a4,4,0,0,0,0,8h91.9A91.2,91.2,0,0,1,88,122,91.6,91.6,0,0,1,65.2,86.7a4,4,0,0,0-5.1-2.5,4.1,4.1,0,0,0-2.4,5.1A100,100,0,0,0,82,127.4,91.4,91.4,0,0,1,24,148a4,4,0,0,0,0,8,99.6,99.6,0,0,0,64-23.2,99.6,99.6,0,0,0,57.7,22.9l-29.3,58.5a4,4,0,0,0,7.2,3.6L138.5,188h75l14.9,29.8A4,4,0,0,0,232,220a3.9,3.9,0,0,0,1.8-.4A4,4,0,0,0,235.6,214.2ZM142.5,180,176,112.9,209.5,180Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M239.2,212.4l-56-112A8.2,8.2,0,0,0,176,96a7.9,7.9,0,0,0-7.1,4.4l-21.7,43.4A87.4,87.4,0,0,1,100,126.9,103.5,103.5,0,0,0,127.7,64H152a8,8,0,0,0,0-16H96V32a8,8,0,0,0-16,0V48H24a8,8,0,0,0,0,16h87.6A87,87,0,0,1,88,116.3a87.1,87.1,0,0,1-19-31,8,8,0,0,0-15.1,5.4A103.8,103.8,0,0,0,76,126.9,87.1,87.1,0,0,1,24,144a8,8,0,0,0,0,16,103.6,103.6,0,0,0,64-22.1,103.6,103.6,0,0,0,51.5,21.3l-26.6,53.2a8.1,8.1,0,0,0,3.5,10.8,8.2,8.2,0,0,0,10.8-3.6L141,192h70.1l13.8,27.6A7.9,7.9,0,0,0,232,224a9.4,9.4,0,0,0,3.6-.8A8.2,8.2,0,0,0,239.2,212.4ZM149,176l27-54.1L203.1,176Z" />
        </>
      )}
    </svg>
  );
}

export function PhTrashSimple(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216,48H40a12,12,0,0,0,0,24h4V208a20.1,20.1,0,0,0,20,20H192a20.1,20.1,0,0,0,20-20V72h4a12,12,0,0,0,0-24ZM188,204H68V72H188ZM76,20A12,12,0,0,1,88,8h80a12,12,0,0,1,0,24H88A12,12,0,0,1,76,20Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M200,56V208a8,8,0,0,1-8,8H64a8,8,0,0,1-8-8V56Z"
            opacity="0.2"
          />
          <path d="M216,48H40a8,8,0,0,0,0,16h8V208a16,16,0,0,0,16,16H192a16,16,0,0,0,16-16V64h8a8,8,0,0,0,0-16ZM192,208H64V64H192ZM80,24a8,8,0,0,1,8-8h80a8,8,0,0,1,0,16H88A8,8,0,0,1,80,24Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M88,32h80a8,8,0,0,0,0-16H88a8,8,0,0,0,0,16Z" />
          <path d="M216,48H40a8,8,0,0,0,0,16h8V208a16,16,0,0,0,16,16H192a16,16,0,0,0,16-16V64h8a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M216,50H40a6,6,0,0,0,0,12H50V208a14,14,0,0,0,14,14H192a14,14,0,0,0,14-14V62h10a6,6,0,0,0,0-12ZM194,208a2,2,0,0,1-2,2H64a2,2,0,0,1-2-2V62H194ZM82,24a6,6,0,0,1,6-6h80a6,6,0,0,1,0,12H88A6,6,0,0,1,82,24Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M216,52H40a4,4,0,0,0,0,8H52V208a12,12,0,0,0,12,12H192a12,12,0,0,0,12-12V60h12a4,4,0,0,0,0-8ZM196,208a4,4,0,0,1-4,4H64a4,4,0,0,1-4-4V60H196ZM84,24a4,4,0,0,1,4-4h80a4,4,0,0,1,0,8H88A4,4,0,0,1,84,24Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,48H40a8,8,0,0,0,0,16h8V208a16,16,0,0,0,16,16H192a16,16,0,0,0,16-16V64h8a8,8,0,0,0,0-16ZM192,208H64V64H192ZM80,24a8,8,0,0,1,8-8h80a8,8,0,0,1,0,16H88A8,8,0,0,1,80,24Z" />
        </>
      )}
    </svg>
  );
}

export function PhTrash(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216,48H180V36A28.1,28.1,0,0,0,152,8H104A28.1,28.1,0,0,0,76,36V48H40a12,12,0,0,0,0,24h4V208a20.1,20.1,0,0,0,20,20H192a20.1,20.1,0,0,0,20-20V72h4a12,12,0,0,0,0-24ZM100,36a4,4,0,0,1,4-4h48a4,4,0,0,1,4,4V48H100Zm88,168H68V72H188ZM116,104v64a12,12,0,0,1-24,0V104a12,12,0,0,1,24,0Zm48,0v64a12,12,0,0,1-24,0V104a12,12,0,0,1,24,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M200,56V208a8,8,0,0,1-8,8H64a8,8,0,0,1-8-8V56Z" />
          </g>
          <path d="M216,48H176V40a24.1,24.1,0,0,0-24-24H104A24.1,24.1,0,0,0,80,40v8H40a8,8,0,0,0,0,16h8V208a16,16,0,0,0,16,16H192a16,16,0,0,0,16-16V64h8a8,8,0,0,0,0-16ZM96,40a8,8,0,0,1,8-8h48a8,8,0,0,1,8,8v8H96Zm96,168H64V64H192Z" />
          <path d="M104,96a8,8,0,0,0-8,8v64a8,8,0,0,0,16,0V104A8,8,0,0,0,104,96Z" />
          <path d="M152,96a8,8,0,0,0-8,8v64a8,8,0,0,0,16,0V104A8,8,0,0,0,152,96Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,48H176V40a24.1,24.1,0,0,0-24-24H104A24.1,24.1,0,0,0,80,40v8H40a8,8,0,0,0,0,16h8V208a16,16,0,0,0,16,16H192a16,16,0,0,0,16-16V64h8a8,8,0,0,0,0-16ZM112,168a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Zm48,0a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Zm0-120H96V40a8,8,0,0,1,8-8h48a8,8,0,0,1,8,8Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M216,50H174V40a22.1,22.1,0,0,0-22-22H104A22.1,22.1,0,0,0,82,40V50H40a6,6,0,0,0,0,12H50V208a14,14,0,0,0,14,14H192a14,14,0,0,0,14-14V62h10a6,6,0,0,0,0-12ZM94,40a10,10,0,0,1,10-10h48a10,10,0,0,1,10,10V50H94ZM194,208a2,2,0,0,1-2,2H64a2,2,0,0,1-2-2V62H194ZM110,104v64a6,6,0,0,1-12,0V104a6,6,0,0,1,12,0Zm48,0v64a6,6,0,0,1-12,0V104a6,6,0,0,1,12,0Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M216,52H172V40a20.1,20.1,0,0,0-20-20H104A20.1,20.1,0,0,0,84,40V52H40a4,4,0,0,0,0,8H52V208a12,12,0,0,0,12,12H192a12,12,0,0,0,12-12V60h12a4,4,0,0,0,0-8ZM92,40a12,12,0,0,1,12-12h48a12,12,0,0,1,12,12V52H92ZM196,208a4,4,0,0,1-4,4H64a4,4,0,0,1-4-4V60H196ZM108,104v64a4,4,0,0,1-8,0V104a4,4,0,0,1,8,0Zm48,0v64a4,4,0,0,1-8,0V104a4,4,0,0,1,8,0Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,48H176V40a24.1,24.1,0,0,0-24-24H104A24.1,24.1,0,0,0,80,40v8H40a8,8,0,0,0,0,16h8V208a16,16,0,0,0,16,16H192a16,16,0,0,0,16-16V64h8a8,8,0,0,0,0-16ZM96,40a8,8,0,0,1,8-8h48a8,8,0,0,1,8,8v8H96Zm96,168H64V64H192ZM112,104v64a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Zm48,0v64a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Z" />
        </>
      )}
    </svg>
  );
}

export function PhTray(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M208,28H48A20.1,20.1,0,0,0,28,48V155.9h0V208a20.1,20.1,0,0,0,20,20H208a20.1,20.1,0,0,0,20-20V48A20.1,20.1,0,0,0,208,28Zm-4,24v92H179.3a19.7,19.7,0,0,0-14.1,5.9L147,168H109L90.8,149.9A19.8,19.8,0,0,0,76.7,144H52V52ZM52,204V168H75l18.2,18.1a19.7,19.7,0,0,0,14.1,5.9h41.4a19.8,19.8,0,0,0,14.1-5.9L181,168h23v36Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M76.7,160a7.9,7.9,0,0,1,5.6,2.3l19.4,19.4a7.9,7.9,0,0,0,5.6,2.3h41.4a7.9,7.9,0,0,0,5.6-2.3l19.4-19.4a7.9,7.9,0,0,1,5.6-2.3H216V48a8,8,0,0,0-8-8H48a8,8,0,0,0-8,8V160Z" />
          </g>
          <path d="M208,32H48A16,16,0,0,0,32,48V159.9h0V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32Zm0,16V152H179.3a15.9,15.9,0,0,0-11.3,4.7L148.7,176H107.3L88,156.7A15.9,15.9,0,0,0,76.7,152H48V48Zm0,160H48V168H76.7L96,187.3a15.9,15.9,0,0,0,11.3,4.7h41.4a15.9,15.9,0,0,0,11.3-4.7L179.3,168H208v40Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M208,32H48A16,16,0,0,0,32,48V159.9h0V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32Zm0,176H48V168H76.7L96,187.3a15.9,15.9,0,0,0,11.3,4.7h41.4a15.9,15.9,0,0,0,11.3-4.7L179.3,168H208v40Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M208,34H48A14,14,0,0,0,34,48V159.9h0V208a14,14,0,0,0,14,14H208a14,14,0,0,0,14-14V48A14,14,0,0,0,208,34ZM48,46H208a2,2,0,0,1,2,2V154H179.3a14.3,14.3,0,0,0-9.9,4.1l-19.3,19.3a2,2,0,0,1-1.4.6H107.3a2,2,0,0,1-1.4-.6L86.6,158.1a14.3,14.3,0,0,0-9.9-4.1H46V48A2,2,0,0,1,48,46ZM208,210H48a2,2,0,0,1-2-2V166H76.7a2,2,0,0,1,1.4.6l19.3,19.3a14.3,14.3,0,0,0,9.9,4.1h41.4a14.3,14.3,0,0,0,9.9-4.1l19.3-19.3a2,2,0,0,1,1.4-.6H210v42A2,2,0,0,1,208,210Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M208,36H48A12,12,0,0,0,36,48V159.9h0V208a12,12,0,0,0,12,12H208a12,12,0,0,0,12-12V48A12,12,0,0,0,208,36ZM48,44H208a4,4,0,0,1,4,4V156H179.3a11.9,11.9,0,0,0-8.5,3.5l-19.3,19.3a3.8,3.8,0,0,1-2.8,1.2H107.3a3.6,3.6,0,0,1-2.8-1.2L85.2,159.5a11.9,11.9,0,0,0-8.5-3.5H44V48A4,4,0,0,1,48,44ZM208,212H48a4,4,0,0,1-4-4V164H76.7a3.8,3.8,0,0,1,2.8,1.2l19.3,19.3a11.9,11.9,0,0,0,8.5,3.5h41.4a11.9,11.9,0,0,0,8.5-3.5l19.3-19.3a3.6,3.6,0,0,1,2.8-1.2H212v44A4,4,0,0,1,208,212Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M208,32H48A16,16,0,0,0,32,48V159.9h0V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32Zm0,16V152H179.3a15.9,15.9,0,0,0-11.3,4.7L148.7,176H107.3L88,156.7A15.9,15.9,0,0,0,76.7,152H48V48Zm0,160H48V168H76.7L96,187.3a15.9,15.9,0,0,0,11.3,4.7h41.4a15.9,15.9,0,0,0,11.3-4.7L179.3,168H208v40Z" />
        </>
      )}
    </svg>
  );
}

export function PhTreeEvergreen(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M233.5,184.6l-41-52.6H208a12.1,12.1,0,0,0,10.8-6.7,12,12,0,0,0-1.3-12.6l-80-104a12,12,0,0,0-19,0l-80,104a12,12,0,0,0-1.3,12.6A12.1,12.1,0,0,0,48,132H63.5l-41,52.6a12.2,12.2,0,0,0-1.3,12.7A12,12,0,0,0,32,204h84v36a12,12,0,0,0,24,0V204h84a12,12,0,0,0,10.8-6.7A12.2,12.2,0,0,0,233.5,184.6ZM56.5,180l41-52.6a12.2,12.2,0,0,0,1.3-12.7A12,12,0,0,0,88,108H72.4L128,35.7,183.6,108H168a12,12,0,0,0-10.8,6.7,12.2,12.2,0,0,0,1.3,12.7l41,52.6Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M32,192l56-72H48L128,16l80,104H168l56,72Z" opacity="0.2" />
          <path d="M230.3,187.1,184.4,128H208a8,8,0,0,0,6.3-12.9l-80-104a8,8,0,0,0-12.6,0l-80,104A8,8,0,0,0,48,128H71.6L25.7,187.1A8,8,0,0,0,32,200h88v40a8,8,0,0,0,16,0V200h88a8,8,0,0,0,6.3-12.9ZM48.4,184l45.9-59.1A8,8,0,0,0,88,112H64.2L128,29.1,191.8,112H168a8,8,0,0,0-6.3,12.9L207.6,184Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M231.2,195.5A8,8,0,0,1,224,200H136v40a8,8,0,0,1-16,0V200H32a8,8,0,0,1-6.3-12.9L71.6,128H48a8,8,0,0,1-6.3-12.9l80-104a8,8,0,0,1,12.6,0l80,104A8,8,0,0,1,208,128H184.4l45.9,59.1A8,8,0,0,1,231.2,195.5Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M228.7,188.3,180.3,126H208a6,6,0,0,0,5.4-3.3,6.4,6.4,0,0,0-.6-6.4l-80-104a6.2,6.2,0,0,0-9.6,0l-80,104a6.4,6.4,0,0,0-.6,6.4A6,6,0,0,0,48,126H75.7L27.3,188.3A6,6,0,0,0,32,198h90v42a6,6,0,0,0,12,0V198h90a6,6,0,0,0,4.7-9.7ZM44.3,186l48.4-62.3A6,6,0,0,0,88,114H60.2L128,25.8,195.8,114H168a6,6,0,0,0-4.7,9.7L211.7,186Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M227.2,189.5l-51-65.5H208a4.2,4.2,0,0,0,3.6-2.2,4,4,0,0,0-.4-4.2l-80-104a4,4,0,0,0-6.4,0l-80,104a4,4,0,0,0-.4,4.2A4.2,4.2,0,0,0,48,124H79.8l-51,65.5A4,4,0,0,0,32,196h92v44a4,4,0,0,0,8,0V196h92a4,4,0,0,0,3.2-6.5ZM40.2,188l51-65.5A4,4,0,0,0,88,116H56.1L128,22.6,199.9,116H168a4,4,0,0,0-3.2,6.5l51,65.5Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M230.3,187.1,184.4,128H208a8,8,0,0,0,6.3-12.9l-80-104a8,8,0,0,0-12.6,0l-80,104A8,8,0,0,0,48,128H71.6L25.7,187.1A8,8,0,0,0,32,200h88v40a8,8,0,0,0,16,0V200h88a8,8,0,0,0,6.3-12.9ZM48.4,184l45.9-59.1A8,8,0,0,0,88,112H64.2L128,29.1,191.8,112H168a8,8,0,0,0-6.3,12.9L207.6,184Z" />
        </>
      )}
    </svg>
  );
}

export function PhTreeStructure(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M168,116h48a20.1,20.1,0,0,0,20-20V48a20.1,20.1,0,0,0-20-20H168a20.1,20.1,0,0,0-20,20V60h-4a36,36,0,0,0-36,36v20H92v-8A20.1,20.1,0,0,0,72,88H32a20.1,20.1,0,0,0-20,20v40a20.1,20.1,0,0,0,20,20H72a20.1,20.1,0,0,0,20-20v-8h16v20a36,36,0,0,0,36,36h4v12a20.1,20.1,0,0,0,20,20h48a20.1,20.1,0,0,0,20-20V160a20.1,20.1,0,0,0-20-20H168a20.1,20.1,0,0,0-20,20v12h-4a12,12,0,0,1-12-12V96a12,12,0,0,1,12-12h4V96A20.1,20.1,0,0,0,168,116ZM68,144H36V112H68Zm104,20h40v40H172Zm0-112h40V92H172Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M80,108v40a8,8,0,0,1-8,8H32a8,8,0,0,1-8-8V108a8,8,0,0,1,8-8H72A8,8,0,0,1,80,108ZM216,40H168a8,8,0,0,0-8,8V96a8,8,0,0,0,8,8h48a8,8,0,0,0,8-8V48A8,8,0,0,0,216,40Zm0,112H168a8,8,0,0,0-8,8v48a8,8,0,0,0,8,8h48a8,8,0,0,0,8-8V160A8,8,0,0,0,216,152Z"
            opacity="0.2"
          />
          <path d="M168,112h48a16,16,0,0,0,16-16V48a16,16,0,0,0-16-16H168a16,16,0,0,0-16,16V64h-8a32.1,32.1,0,0,0-32,32v24H88V108A16,16,0,0,0,72,92H32a16,16,0,0,0-16,16v40a16,16,0,0,0,16,16H72a16,16,0,0,0,16-16V136h24v24a32.1,32.1,0,0,0,32,32h8v16a16,16,0,0,0,16,16h48a16,16,0,0,0,16-16V160a16,16,0,0,0-16-16H168a16,16,0,0,0-16,16v16h-8a16,16,0,0,1-16-16V96a16,16,0,0,1,16-16h8V96A16,16,0,0,0,168,112ZM72,148H32V108H72v19.9h0V148Zm96,12h48v48H168Zm0-112h48V96H168Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M156,92V80H144a16,16,0,0,0-16,16v64a16,16,0,0,0,16,16h12V164a16,16,0,0,1,16-16h40a16,16,0,0,1,16,16v40a16,16,0,0,1-16,16H172a16,16,0,0,1-16-16V192H144a32.1,32.1,0,0,1-32-32V136H84v8a16,16,0,0,1-16,16H36a16,16,0,0,1-16-16V112A16,16,0,0,1,36,96H68a16,16,0,0,1,16,16v8h28V96a32.1,32.1,0,0,1,32-32h12V52a16,16,0,0,1,16-16h40a16,16,0,0,1,16,16V92a16,16,0,0,1-16,16H172A16,16,0,0,1,156,92Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M168,110h48a14,14,0,0,0,14-14V48a14,14,0,0,0-14-14H168a14,14,0,0,0-14,14V66H144a30.1,30.1,0,0,0-30,30v26H86V108A14,14,0,0,0,72,94H32a14,14,0,0,0-14,14v40a14,14,0,0,0,14,14H72a14,14,0,0,0,14-14V134h28v26a30.1,30.1,0,0,0,30,30h10v18a14,14,0,0,0,14,14h48a14,14,0,0,0,14-14V160a14,14,0,0,0-14-14H168a14,14,0,0,0-14,14v18H144a18.1,18.1,0,0,1-18-18V96a18.1,18.1,0,0,1,18-18h10V96A14,14,0,0,0,168,110ZM74,148a2,2,0,0,1-2,2H32a2,2,0,0,1-2-2V108a2,2,0,0,1,2-2H72a2,2,0,0,1,2,2Zm92,12a2,2,0,0,1,2-2h48a2,2,0,0,1,2,2v48a2,2,0,0,1-2,2H168a2,2,0,0,1-2-2Zm0-112a2,2,0,0,1,2-2h48a2,2,0,0,1,2,2V96a2,2,0,0,1-2,2H168a2,2,0,0,1-2-2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M168,108h48a12,12,0,0,0,12-12V48a12,12,0,0,0-12-12H168a12,12,0,0,0-12,12V68H144a28.1,28.1,0,0,0-28,28v28H84V108A12,12,0,0,0,72,96H32a12,12,0,0,0-12,12v40a12,12,0,0,0,12,12H72a12,12,0,0,0,12-12V132h32v28a28.1,28.1,0,0,0,28,28h12v20a12,12,0,0,0,12,12h48a12,12,0,0,0,12-12V160a12,12,0,0,0-12-12H168a12,12,0,0,0-12,12v20H144a20.1,20.1,0,0,1-20-20V96a20.1,20.1,0,0,1,20-20h12V96A12,12,0,0,0,168,108ZM76,148a4,4,0,0,1-4,4H32a4,4,0,0,1-4-4V108a4,4,0,0,1,4-4H72a4,4,0,0,1,4,4Zm88,12a4,4,0,0,1,4-4h48a4,4,0,0,1,4,4v48a4,4,0,0,1-4,4H168a4,4,0,0,1-4-4Zm0-112a4,4,0,0,1,4-4h48a4,4,0,0,1,4,4V96a4,4,0,0,1-4,4H168a4,4,0,0,1-4-4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M168,112h48a16,16,0,0,0,16-16V48a16,16,0,0,0-16-16H168a16,16,0,0,0-16,16V64h-8a32.1,32.1,0,0,0-32,32v24H88V108A16,16,0,0,0,72,92H32a16,16,0,0,0-16,16v40a16,16,0,0,0,16,16H72a16,16,0,0,0,16-16V136h24v24a32.1,32.1,0,0,0,32,32h8v16a16,16,0,0,0,16,16h48a16,16,0,0,0,16-16V160a16,16,0,0,0-16-16H168a16,16,0,0,0-16,16v16h-8a16,16,0,0,1-16-16V96a16,16,0,0,1,16-16h8V96A16,16,0,0,0,168,112ZM72,148H32V108H72v19.9h0V148Zm96,12h48v48H168Zm0-112h48V96H168Z" />
        </>
      )}
    </svg>
  );
}

export function PhTree(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M201.2,59.6a80.1,80.1,0,0,0-146.4,0A76.4,76.4,0,0,0,12,127.8c-.1,41,33.1,75.1,74,76.2a77.8,77.8,0,0,0,30-5.3V232a12,12,0,0,0,24,0V198.7a75.7,75.7,0,0,0,28,5.3h2c40.9-1.1,74.1-35.2,74-76.2A76.4,76.4,0,0,0,201.2,59.6ZM169.4,180a51.7,51.7,0,0,1-29.4-8.2V135.4l41.4-20.7a12,12,0,0,0-10.8-21.4L140,108.6V88a12,12,0,0,0-24,0v44.6L85.4,117.3a12,12,0,0,0-10.8,21.4L116,159.4v12.4a52,52,0,1,1-49.7-91,19.8,19.8,0,0,0,10-10.5,56.1,56.1,0,0,1,103.4,0,19.8,19.8,0,0,0,10,10.5A52,52,0,0,1,169.4,180Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M232,127.8a63.9,63.9,0,0,1-99.5,53.4,8.2,8.2,0,0,0-9,0A64,64,0,1,1,61.3,69.9a8.6,8.6,0,0,0,4-4.2,68,68,0,0,1,125.4,0,8.6,8.6,0,0,0,4,4.2A63.9,63.9,0,0,1,232,127.8Z"
            opacity="0.2"
          />
          <path d="M198.1,62.6a76,76,0,0,0-140.2,0A72.3,72.3,0,0,0,16,127.8C15.9,166.6,47.4,199,86.1,200a71.6,71.6,0,0,0,33.9-7.5V232a8,8,0,0,0,16,0V192.5a72,72,0,0,0,32,7.5h1.9c38.7-1,70.2-33.4,70.1-72.2A72.3,72.3,0,0,0,198.1,62.6ZM169.5,184a56.2,56.2,0,0,1-32.6-9.4l-.9-.6V132.9l43.6-21.7a8,8,0,0,0-7.2-14.4L136,115.1V88a8,8,0,0,0-16,0v51.1L83.6,120.8a8,8,0,1,0-7.2,14.4L120,156.9V174l-.9.6A56.2,56.2,0,0,1,86.5,184c-30.1-.8-54.6-26-54.5-56.2A56.3,56.3,0,0,1,64.6,77.1a16.4,16.4,0,0,0,8.1-8.3,60,60,0,0,1,110.6,0,16.4,16.4,0,0,0,8.1,8.3A56.3,56.3,0,0,1,224,127.8C224.1,158,199.6,183.2,169.5,184Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,187.9a91.6,91.6,0,0,0,8,4.6V232a8,8,0,0,1-16,0V192.5a91.6,91.6,0,0,0,8-4.6ZM198.1,62.6a76,76,0,0,0-140.2,0A72.3,72.3,0,0,0,16,127.8C15.9,166.6,47.4,199,86.1,200a71.9,71.9,0,0,0,33.9-7.5V156.9L76.4,135.2a8,8,0,1,1,7.2-14.4L120,139.1V88a8,8,0,0,1,16,0v27.1l36.4-18.3a8,8,0,0,1,7.2,14.4L136,132.9v59.6a72,72,0,0,0,32,7.5h1.9c38.7-1,70.2-33.4,70.1-72.2A72.3,72.3,0,0,0,198.1,62.6Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M197.3,64.4a1.9,1.9,0,0,1-1-1,74.1,74.1,0,0,0-136.6,0,1.9,1.9,0,0,1-1,1A70.5,70.5,0,0,0,18,127.8C17.9,165.5,48.5,197,86.2,198a69.7,69.7,0,0,0,35.8-8.8V232a6,6,0,0,0,12,0V189.2a69.5,69.5,0,0,0,34,8.8h1.8c37.7-1,68.3-32.5,68.2-70.2A70.5,70.5,0,0,0,197.3,64.4ZM169.5,186a57.6,57.6,0,0,1-33.7-9.8l-1.8-1V131.7l44.7-22.3a6,6,0,0,0-5.4-10.8L134,118.3V88a6,6,0,0,0-12,0v54.3L82.7,122.6a6,6,0,1,0-5.4,10.8L122,155.7v19.5l-1.8,1A57.6,57.6,0,0,1,86.5,186c-31.2-.8-56.6-26.9-56.5-58.2A58.1,58.1,0,0,1,63.8,75.3a14.3,14.3,0,0,0,7-7.3,62.1,62.1,0,0,1,114.4,0,14.3,14.3,0,0,0,7,7.3A58.1,58.1,0,0,1,226,127.8C226.1,159.1,200.7,185.2,169.5,186Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M196.4,66.2a3.8,3.8,0,0,1-2-2.1,72,72,0,0,0-132.8,0,3.8,3.8,0,0,1-2,2.1A68.3,68.3,0,0,0,20,127.8c-.1,36.7,29.6,67.3,66.2,68.2A67.7,67.7,0,0,0,124,185.7V232a4,4,0,0,0,8,0V185.7A68.2,68.2,0,0,0,168,196h1.8c36.6-.9,66.3-31.5,66.2-68.2A68.3,68.3,0,0,0,196.4,66.2ZM169.6,188a59.9,59.9,0,0,1-34.9-10.1h0a11.1,11.1,0,0,0-2.7-1.3V130.5l45.8-22.9a4,4,0,1,0-3.6-7.2L132,121.5V88a4,4,0,0,0-8,0v57.5L81.8,124.4a4,4,0,1,0-3.6,7.2L124,154.5v22.1a11.1,11.1,0,0,0-2.7,1.3A59.9,59.9,0,0,1,86.4,188c-32.3-.8-58.5-27.8-58.4-60.2A60.3,60.3,0,0,1,62.9,73.5,12.1,12.1,0,0,0,69,67.2a64,64,0,0,1,118,0,12.1,12.1,0,0,0,6.1,6.3A60.3,60.3,0,0,1,228,127.8C228.1,160.2,201.9,187.2,169.6,188Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M198.1,62.6a76,76,0,0,0-140.2,0A72.3,72.3,0,0,0,16,127.8C15.9,166.6,47.4,199,86.1,200a71.9,71.9,0,0,0,33.9-7.5V232a8,8,0,0,0,16,0V192.5a72,72,0,0,0,32,7.5h1.9c38.7-1,70.2-33.4,70.1-72.2A72.3,72.3,0,0,0,198.1,62.6ZM169.5,184a56.2,56.2,0,0,1-32.6-9.4l-.9-.6V132.9l43.6-21.7a8,8,0,0,0-7.2-14.4L136,115.1V88a8,8,0,0,0-16,0v51.1L83.6,120.8a8,8,0,1,0-7.2,14.4L120,156.9V174l-.9.6A56.2,56.2,0,0,1,86.5,184c-30.1-.8-54.6-26-54.5-56.2A56.3,56.3,0,0,1,64.6,77.1a16.4,16.4,0,0,0,8.1-8.3,60,60,0,0,1,110.6,0,16.4,16.4,0,0,0,8.1,8.3A56.3,56.3,0,0,1,224,127.8C224.1,158,199.6,183.2,169.5,184Z" />
        </>
      )}
    </svg>
  );
}

export function PhTrendDown(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M244,136v64a12,12,0,0,1-12,12H168a12,12,0,0,1,0-24h35l-67-67-31.5,31.5a12,12,0,0,1-17,0l-72-72a12,12,0,0,1,17-17L96,127l31.5-31.5a12,12,0,0,1,17,0L220,171V136a12,12,0,0,1,24,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M240,136v64a8,8,0,0,1-8,8H168a8,8,0,0,1,0-16h44.7L136,115.3l-34.3,34.4a8.1,8.1,0,0,1-11.4,0l-72-72A8.1,8.1,0,0,1,29.7,66.3L96,132.7l34.3-34.4a8.1,8.1,0,0,1,11.4,0L224,180.7V136a8,8,0,0,1,16,0Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M240,136v64a8,8,0,0,1-8,8H168a8.1,8.1,0,0,1-7.4-4.9,8.4,8.4,0,0,1,1.7-8.8L188.7,168,136,115.3l-34.3,34.4a8.1,8.1,0,0,1-11.4,0l-72-72A8.1,8.1,0,0,1,29.7,66.3L96,132.7l34.3-34.4a8.1,8.1,0,0,1,11.4,0L200,156.7l26.3-26.4a8.4,8.4,0,0,1,8.8-1.7A8,8,0,0,1,240,136Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M238,136v64a6,6,0,0,1-6,6H168a6,6,0,0,1,0-12h49.5L136,112.5l-35.8,35.7a5.8,5.8,0,0,1-8.4,0l-72-72a5.9,5.9,0,1,1,8.4-8.4L96,135.5l35.8-35.7a5.8,5.8,0,0,1,8.4,0L226,185.5V136a6,6,0,0,1,12,0Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M236,136v64a4,4,0,0,1-4,4H168a4,4,0,0,1,0-8h54.3L136,109.7,98.8,146.8a3.9,3.9,0,0,1-5.6,0l-72-72a4,4,0,0,1,5.6-5.6L96,138.3l37.2-37.1a3.9,3.9,0,0,1,5.6,0L228,190.3V136a4,4,0,0,1,8,0Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M240,136v64a8,8,0,0,1-8,8H168a8,8,0,0,1,0-16h44.7L136,115.3l-34.3,34.4a8.1,8.1,0,0,1-11.4,0l-72-72A8.1,8.1,0,0,1,29.7,66.3L96,132.7l34.3-34.4a8.1,8.1,0,0,1,11.4,0L224,180.7V136a8,8,0,0,1,16,0Z" />
        </>
      )}
    </svg>
  );
}

export function PhTrendUp(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M244,56v64a12,12,0,0,1-24,0V85l-75.5,75.5a12,12,0,0,1-17,0L96,129,32.5,192.5a12.1,12.1,0,0,1-17,0,12,12,0,0,1,0-17l72-72a12,12,0,0,1,17,0L136,135l67-67H168a12,12,0,0,1,0-24h64A12,12,0,0,1,244,56Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M240,56v64a8,8,0,0,1-16,0V75.3l-82.3,82.4a8.1,8.1,0,0,1-11.4,0L96,123.3,29.7,189.7A8.3,8.3,0,0,1,24,192a8.5,8.5,0,0,1-5.7-2.3,8.1,8.1,0,0,1,0-11.4l72-72a8.1,8.1,0,0,1,11.4,0L136,140.7,212.7,64H168a8,8,0,0,1,0-16h64A8,8,0,0,1,240,56Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M240,56v64a8,8,0,0,1-4.9,7.4,8.5,8.5,0,0,1-3.1.6,8.3,8.3,0,0,1-5.7-2.3L200,99.3l-58.3,58.4a8.1,8.1,0,0,1-11.4,0L96,123.3,29.7,189.7A8.3,8.3,0,0,1,24,192a8.5,8.5,0,0,1-5.7-2.3,8.1,8.1,0,0,1,0-11.4l72-72a8.1,8.1,0,0,1,11.4,0L136,140.7,188.7,88,162.3,61.7a8.4,8.4,0,0,1-1.7-8.8A8.1,8.1,0,0,1,168,48h64A8,8,0,0,1,240,56Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M238,56v64a6,6,0,0,1-12,0V70.5l-85.8,85.7a5.8,5.8,0,0,1-8.4,0L96,120.5,28.2,188.2a5.9,5.9,0,0,1-8.4-8.4l72-72a5.8,5.8,0,0,1,8.4,0L136,143.5,217.5,62H168a6,6,0,0,1,0-12h64A6,6,0,0,1,238,56Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M236,56v64a4,4,0,0,1-8,0V65.7l-89.2,89.1a3.9,3.9,0,0,1-5.6,0L96,117.7,26.8,186.8A3.8,3.8,0,0,1,24,188a3.9,3.9,0,0,1-2.8-1.2,3.9,3.9,0,0,1,0-5.6l72-72a3.9,3.9,0,0,1,5.6,0L136,146.3,222.3,60H168a4,4,0,0,1,0-8h64A4,4,0,0,1,236,56Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M240,56v64a8,8,0,0,1-16,0V75.3l-82.3,82.4a8.1,8.1,0,0,1-11.4,0L96,123.3,29.7,189.7a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4l72-72a8.1,8.1,0,0,1,11.4,0L136,140.7,212.7,64H168a8,8,0,0,1,0-16h64A8,8,0,0,1,240,56Z" />
        </>
      )}
    </svg>
  );
}

export function PhTriangle(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216,228H40a28,28,0,0,1-24.2-42l88-152h0a27.9,27.9,0,0,1,48.4,0l88,152A28,28,0,0,1,216,228ZM124.5,46,36.6,198a3.8,3.8,0,0,0,0,4,3.8,3.8,0,0,0,3.4,2H216a3.8,3.8,0,0,0,3.4-2,3.8,3.8,0,0,0,0-4L131.5,46a4.1,4.1,0,0,0-7,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M114.2,40l-88,152A16,16,0,0,0,40,216H216a16,16,0,0,0,13.8-24l-88-152A15.9,15.9,0,0,0,114.2,40Z" />
          </g>
          <path d="M216,224H40a23.9,23.9,0,0,1-20.7-36L107.2,36h0a24,24,0,0,1,41.6,0l87.9,152A23.9,23.9,0,0,1,216,224ZM121.1,44l-88,152a7.8,7.8,0,0,0,0,8,7.9,7.9,0,0,0,6.9,4H216a7.9,7.9,0,0,0,6.9-4,7.8,7.8,0,0,0,0-8l-88-152a8,8,0,0,0-13.8,0Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M236.8,212A23.9,23.9,0,0,1,216,224H40a23.9,23.9,0,0,1-20.7-36L107.2,36h0a24,24,0,0,1,41.6,0l87.9,152A23.7,23.7,0,0,1,236.8,212Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M109,37a21.9,21.9,0,0,1,38,0l88,152a22,22,0,0,1-19,33H40a22,22,0,0,1-19-33L109,37m10.3,6L31.4,195A10,10,0,0,0,40,210H216a10,10,0,0,0,8.6-15L136.7,43a10.1,10.1,0,0,0-17.4,0Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M110.7,38a20,20,0,0,1,34.6,0l88,152A20,20,0,0,1,216,220H40a20,20,0,0,1-17.3-30l88-152M128,36a11.9,11.9,0,0,0-10.4,6l-88,152a12.2,12.2,0,0,0,0,12A12.1,12.1,0,0,0,40,212H216a12.1,12.1,0,0,0,10.4-6,12.2,12.2,0,0,0,0-12l-88-152A11.9,11.9,0,0,0,128,36Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,224H40a23.9,23.9,0,0,1-20.7-36L107.2,36h0a24,24,0,0,1,41.6,0l87.9,152A23.9,23.9,0,0,1,216,224ZM121.1,44l-88,152a7.8,7.8,0,0,0,0,8,7.9,7.9,0,0,0,6.9,4H216a7.9,7.9,0,0,0,6.9-4,7.8,7.8,0,0,0,0-8l-88-152a8,8,0,0,0-13.8,0Z" />
        </>
      )}
    </svg>
  );
}

export function PhTrophy(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M232,60H212V56a20.1,20.1,0,0,0-20-20H64A20.1,20.1,0,0,0,44,56v4H23.9a20,20,0,0,0-20,20V96a44,44,0,0,0,44,44H49c10.5,28.9,36.1,50.6,67,55.1V212H96a12,12,0,0,0,0,24h64a12,12,0,0,0,0-24H140V195.1A83.9,83.9,0,0,0,207.2,140h.8a44,44,0,0,0,44-44V80A20.1,20.1,0,0,0,232,60ZM27.9,96V84H44v27.1c0,1.5,0,3,.1,4.5A20,20,0,0,1,27.9,96Zm99.6,76C94.7,171.8,68,144.4,68,111.1V60H188v52a59.9,59.9,0,0,1-60.5,60ZM228,96a20,20,0,0,1-16.1,19.6c.1-1.2.1-2.4.1-3.6V84h16Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M200,56v56a72,72,0,0,1-72.5,72C87.8,183.7,56,150.8,56,111.1V56a8,8,0,0,1,8-8H192A8,8,0,0,1,200,56Z"
            opacity="0.2"
          />
          <path d="M232,64H208V56a16,16,0,0,0-16-16H64A16,16,0,0,0,48,56v8H23.9a16,16,0,0,0-16,16V96a40,40,0,0,0,40,40h4c9.7,29.9,36.2,52.3,68.1,55.6V216H96a8,8,0,0,0,0,16h64a8,8,0,0,0,0-16H136V191.6A79.7,79.7,0,0,0,204.3,136H208a40,40,0,0,0,40-40V80A16,16,0,0,0,232,64ZM47.9,120a24,24,0,0,1-24-24V80H48v31.1a86.9,86.9,0,0,0,.5,8.9Zm125.2,37.4A63.6,63.6,0,0,1,128,176h-.5c-35-.3-63.5-29.4-63.5-64.9V56H192v56A63.4,63.4,0,0,1,173.1,157.4ZM232,96a24.1,24.1,0,0,1-24,24h-.4a70.3,70.3,0,0,0,.4-8V80h24Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M232,64H208V56a16,16,0,0,0-16-16H64A16,16,0,0,0,48,56v8H23.9a16,16,0,0,0-16,16V96a40,40,0,0,0,40,40h4c9.7,29.9,36.2,52.3,68.1,55.6V216H96a8,8,0,0,0,0,16h64a8,8,0,0,0,0-16H136V191.6A79.7,79.7,0,0,0,204.3,136H208a40,40,0,0,0,40-40V80A16,16,0,0,0,232,64ZM47.9,120a24,24,0,0,1-24-24V80H48v31.1a86.9,86.9,0,0,0,.5,8.9ZM232,96a24.1,24.1,0,0,1-24,24h-.4a70.3,70.3,0,0,0,.4-8V80h24Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M232,66H206V56a14,14,0,0,0-14-14H64A14,14,0,0,0,50,56V66H23.9a14,14,0,0,0-14,14V96a38,38,0,0,0,38,38h5.5c9.2,30.5,36.2,53.2,68.6,55.7V218H96a6,6,0,0,0,0,12h64a6,6,0,0,0,0-12H134V189.8a77,77,0,0,0,48.9-22.4,78.7,78.7,0,0,0,20-33.4H208a38,38,0,0,0,38-38V80A14,14,0,0,0,232,66ZM47.9,122a26,26,0,0,1-26-26V80a2,2,0,0,1,2-2H50v33.1a78.8,78.8,0,0,0,.8,10.9Zm126.6,36.8A65.6,65.6,0,0,1,128,178h-.5C91.4,177.7,62,147.7,62,111.1V56a2,2,0,0,1,2-2H192a2,2,0,0,1,2,2v56A65.2,65.2,0,0,1,174.5,158.8ZM234,96a26.1,26.1,0,0,1-26,26h-2.7a66.3,66.3,0,0,0,.7-10V78h26a2,2,0,0,1,2,2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M232,68H204V56a12,12,0,0,0-12-12H64A12,12,0,0,0,52,56V68H23.9a12,12,0,0,0-12,12V96a36,36,0,0,0,36,36h7c8.7,31,36.2,54.1,69.1,55.9V220H96a4,4,0,0,0,0,8h64a4,4,0,0,0,0-8H132V187.9a75.5,75.5,0,0,0,49.5-22A74.1,74.1,0,0,0,201.3,132H208a36,36,0,0,0,36-36V80A12,12,0,0,0,232,68ZM47.9,124a28,28,0,0,1-28-28V80a4,4,0,0,1,4-4H52v35.1A78.3,78.3,0,0,0,53.1,124Zm128,36.3A68.1,68.1,0,0,1,127.5,180C90.3,179.7,60,148.8,60,111.1V56a4,4,0,0,1,4-4H192a4,4,0,0,1,4,4v56A67.4,67.4,0,0,1,175.9,160.3ZM236,96a28.1,28.1,0,0,1-28,28h-4.9a78.8,78.8,0,0,0,.9-12V76h28a4,4,0,0,1,4,4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M232,64H208V56a16,16,0,0,0-16-16H64A16,16,0,0,0,48,56v8H23.9a16,16,0,0,0-16,16V96a40,40,0,0,0,40,40h4c9.7,29.9,36.2,52.3,68.1,55.6V216H96a8,8,0,0,0,0,16h64a8,8,0,0,0,0-16H136V191.6A79.7,79.7,0,0,0,204.3,136H208a40,40,0,0,0,40-40V80A16,16,0,0,0,232,64ZM47.9,120a24,24,0,0,1-24-24V80H48v31.1a86.9,86.9,0,0,0,.5,8.9Zm125.2,37.4A63.6,63.6,0,0,1,128,176h-.5c-35-.3-63.5-29.4-63.5-64.9V56H192v56A63.4,63.4,0,0,1,173.1,157.4ZM232,96a24.1,24.1,0,0,1-24,24h-.4a70.3,70.3,0,0,0,.4-8V80h24Z" />
        </>
      )}
    </svg>
  );
}

export function PhTruck(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M252.1,119.9v-.4a3.8,3.8,0,0,0-.1-1v-.4a2.9,2.9,0,0,1-.2-.9.8.8,0,0,1-.1-.4c-.1-.4-.3-.7-.4-1.1v-.2h-.1L237.3,80.6A19.9,19.9,0,0,0,218.7,68H188.1V64a12,12,0,0,0-12-12H24.1a20.1,20.1,0,0,0-20,20V184a20.1,20.1,0,0,0,20,20H42.2a35.9,35.9,0,0,0,67.8,0h36.2a35.9,35.9,0,0,0,67.8,0h18.1a20.1,20.1,0,0,0,20-20V120ZM216,92l6.4,16H188.1V92ZM164.1,76v56H28.1V76Zm-136,80h136v3.8A36,36,0,0,0,146.2,180H110a35.9,35.9,0,0,0-67.8,0H28.1Zm48,48a12,12,0,1,1,12-12A12,12,0,0,1,76.1,204Zm104,0a12,12,0,1,1,12-12A12,12,0,0,1,180.1,204Zm48-24H214a36,36,0,0,0-25.9-23.1V132h40Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M176.1,144H16.1v40a8,8,0,0,0,8,8h20a24,24,0,0,1,48,0h72a24,24,0,0,1,12-20.8Z" />
          </g>
          <g opacity="0.2">
            <path d="M212.1,192a24,24,0,0,0-36-20.8V120h64v64a8,8,0,0,1-8,8h-20" />
          </g>
          <path d="M248.1,119.9v-.2a1.7,1.7,0,0,0-.1-.7v-.3c0-.2-.1-.4-.1-.6v-.2c-.1-.3-.1-.5-.2-.8h-.1L233.6,82.1A15.9,15.9,0,0,0,218.7,72H184.1V64a8,8,0,0,0-8-8H24.1a16,16,0,0,0-16,16V184a16,16,0,0,0,16,16h13a32,32,0,0,0,62,0h58a32,32,0,0,0,62,0h13a16,16,0,0,0,16-16V120ZM218.7,88l9.6,24H184.1V88ZM168.1,72v64H24.1V72Zm-144,80h144v15a32.6,32.6,0,0,0-11,17h-58a32,32,0,0,0-62,0h-13Zm44,56a16,16,0,1,1,16-16A16,16,0,0,1,68.1,208Zm120,0a16,16,0,1,1,16-16A16,16,0,0,1,188.1,208Zm44-24h-13a32.1,32.1,0,0,0-31-24,26.9,26.9,0,0,0-4,.3V128h48Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M248.1,119.9v-.2a1.7,1.7,0,0,0-.1-.7v-.3c0-.2-.1-.4-.1-.6v-.2c-.1-.3-.1-.5-.2-.8h-.1L233.6,82.1A15.9,15.9,0,0,0,218.7,72H184.1V64a8,8,0,0,0-8-8H24.1a16,16,0,0,0-16,16V184a16,16,0,0,0,16,16h13a32,32,0,0,0,62,0h58a32,32,0,0,0,62,0h13a16,16,0,0,0,16-16V120ZM184.1,88h34.6l9.6,24H184.1ZM24.1,72h144v64H24.1Zm44,136a16,16,0,1,1,16-16A16,16,0,0,1,68.1,208Zm120,0a16,16,0,1,1,16-16A16,16,0,0,1,188.1,208Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M246.1,120v-.9a.7.7,0,0,0-.1-.5v-.2c-.1-.2-.1-.4-.2-.5h-.1L231.7,82.8a13.9,13.9,0,0,0-13-8.8H182.1V64a6,6,0,0,0-6-6H24.1a14,14,0,0,0-14,14V184a14,14,0,0,0,14,14H38.7a30,30,0,0,0,58.8,0h61.2a30,30,0,0,0,58.8,0h14.6a14,14,0,0,0,14-14V120ZM218.7,86a2.2,2.2,0,0,1,1.9,1.3L231.2,114H182.1V86ZM24.1,70h146v68H22.1V72A2,2,0,0,1,24.1,70Zm-2,114V150h148v18a30.3,30.3,0,0,0-11.4,18H97.5a30,30,0,0,0-58.8,0H24.1A2,2,0,0,1,22.1,184Zm46,26a18,18,0,1,1,18-18A18.1,18.1,0,0,1,68.1,210Zm120,0a18,18,0,1,1,18-18A18.1,18.1,0,0,1,188.1,210Zm46-26a2,2,0,0,1-2,2H217.5a30,30,0,0,0-29.4-24,30.5,30.5,0,0,0-6,.6V126h52Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M244.1,120a1.5,1.5,0,0,0-.1-.7v-.2a1.4,1.4,0,0,0-.2-.6h0l-14-34.9A12,12,0,0,0,218.7,76H180.1V64a4,4,0,0,0-4-4H24.1a12,12,0,0,0-12,12V184a12,12,0,0,0,12,12H40.4a28,28,0,0,0,55.4,0h64.6a28,28,0,0,0,55.4,0h16.3a12,12,0,0,0,12-12V120ZM218.7,84a4,4,0,0,1,3.7,2.5L234.2,116H180.1V84ZM24.1,68h148v72H20.1V72A4,4,0,0,1,24.1,68Zm-4,116V148h152v21a28.2,28.2,0,0,0-11.7,19H95.8a28,28,0,0,0-55.4,0H24.1A4,4,0,0,1,20.1,184Zm48,28a20,20,0,1,1,20-20A20.1,20.1,0,0,1,68.1,212Zm120,0a20,20,0,1,1,20-20A20.1,20.1,0,0,1,188.1,212Zm48-28a4,4,0,0,1-4,4H215.8a27.9,27.9,0,0,0-27.7-24,26.3,26.3,0,0,0-8,1.2V124h56Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M248.1,119.9v-.2a1.7,1.7,0,0,0-.1-.7v-.3c0-.2-.1-.4-.1-.6v-.2c-.1-.3-.1-.5-.2-.8h-.1L233.6,82.1A15.9,15.9,0,0,0,218.7,72H184.1V64a8,8,0,0,0-8-8H24.1a16,16,0,0,0-16,16V184a16,16,0,0,0,16,16h13a32,32,0,0,0,62,0h58a32,32,0,0,0,62,0h13a16,16,0,0,0,16-16V120ZM218.7,88l9.6,24H184.1V88ZM168.1,72v64H24.1V72Zm-144,80h144v15a32.6,32.6,0,0,0-11,17h-58a32,32,0,0,0-62,0h-13Zm44,56a16,16,0,1,1,16-16A16,16,0,0,1,68.1,208Zm120,0a16,16,0,1,1,16-16A16,16,0,0,1,188.1,208Zm44-24h-13a32.1,32.1,0,0,0-31-24,26.9,26.9,0,0,0-4,.3V128h48Z" />
        </>
      )}
    </svg>
  );
}

export function PhTwitchLogo(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M72,252a13,13,0,0,1-5.1-1.1A12,12,0,0,1,60,240V212H48a20.1,20.1,0,0,1-20-20V48A20.1,20.1,0,0,1,48,28H208a20.1,20.1,0,0,1,20,20V156.3a19.8,19.8,0,0,1-7.2,15.3l-42.9,35.8a19.9,19.9,0,0,1-12.8,4.6H124.3L79.7,249.2A11.6,11.6,0,0,1,72,252ZM52,188H72a12,12,0,0,1,12,12v14.4l26.1-21.8a19.9,19.9,0,0,1,12.8-4.6h40.8L204,154.4V52H52Z" />
          <path d="M168,148a12,12,0,0,1-12-12V88a12,12,0,0,1,24,0v48A12,12,0,0,1,168,148Z" />
          <path d="M120,148a12,12,0,0,1-12-12V88a12,12,0,0,1,24,0v48A12,12,0,0,1,120,148Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M165.1,200H122.9a7.8,7.8,0,0,0-5.1,1.9L72,240V200H48a8,8,0,0,1-8-8V48a8,8,0,0,1,8-8H208a8,8,0,0,1,8,8V156.3a8.1,8.1,0,0,1-2.9,6.1l-42.9,35.7A7.8,7.8,0,0,1,165.1,200Z" />
          </g>
          <path d="M208,32H48A16,16,0,0,0,32,48V192a16,16,0,0,0,16,16H64v32a7.9,7.9,0,0,0,4.6,7.2,6.8,6.8,0,0,0,3.4.8,7.5,7.5,0,0,0,5.1-1.9L122.9,208h42.2a15.8,15.8,0,0,0,10.2-3.7l42.9-35.8a15.7,15.7,0,0,0,5.8-12.2V48A16,16,0,0,0,208,32Zm0,124.3L165.1,192H122.9a15.8,15.8,0,0,0-10.2,3.7L80,222.9V200a8,8,0,0,0-8-8H48V48H208ZM160,136V88a8,8,0,0,1,16,0v48a8,8,0,0,1-16,0Zm-48,0V88a8,8,0,0,1,16,0v48a8,8,0,0,1-16,0Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M208,32H48A16,16,0,0,0,32,48V192a16,16,0,0,0,16,16H64v32a7.9,7.9,0,0,0,4.6,7.2,6.8,6.8,0,0,0,3.4.8,7.5,7.5,0,0,0,5.1-1.9L122.9,208h42.2a15.8,15.8,0,0,0,10.2-3.7l42.9-35.8a15.7,15.7,0,0,0,5.8-12.2V48A16,16,0,0,0,208,32ZM128,136a8,8,0,0,1-16,0V88a8,8,0,0,1,16,0Zm48,0a8,8,0,0,1-16,0V88a8,8,0,0,1,16,0Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M72,246a5.4,5.4,0,0,1-2.5-.6A5.9,5.9,0,0,1,66,240V206H48a14,14,0,0,1-14-14V48A14,14,0,0,1,48,34H208a14,14,0,0,1,14,14V156.3a14,14,0,0,1-5,10.7l-42.9,35.8a14.5,14.5,0,0,1-9,3.2H122.9a2.1,2.1,0,0,0-1.3.5L75.8,244.6A5.9,5.9,0,0,1,72,246ZM48,46a2,2,0,0,0-2,2V192a2,2,0,0,0,2,2H72a6,6,0,0,1,6,6v27.2l35.9-30a14.5,14.5,0,0,1,9-3.2h42.2a2.1,2.1,0,0,0,1.3-.5l42.9-35.7a2.3,2.3,0,0,0,.7-1.5V48a2,2,0,0,0-2-2Z" />
          <path d="M168,142a6,6,0,0,1-6-6V88a6,6,0,0,1,12,0v48A6,6,0,0,1,168,142Z" />
          <path d="M120,142a6,6,0,0,1-6-6V88a6,6,0,0,1,12,0v48A6,6,0,0,1,120,142Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M72,244a3.3,3.3,0,0,1-1.7-.4A3.9,3.9,0,0,1,68,240V204H48a12,12,0,0,1-12-12V48A12,12,0,0,1,48,36H208a12,12,0,0,1,12,12V156.3a12.2,12.2,0,0,1-4.3,9.2l-42.9,35.7a12.2,12.2,0,0,1-7.7,2.8H122.9a4.3,4.3,0,0,0-2.6.9L74.6,243.1A4.3,4.3,0,0,1,72,244ZM48,44a4,4,0,0,0-4,4V192a4,4,0,0,0,4,4H72a4,4,0,0,1,4,4v31.5l39.2-32.7a12.2,12.2,0,0,1,7.7-2.8h42.2a4.3,4.3,0,0,0,2.6-.9l42.9-35.8a3.9,3.9,0,0,0,1.4-3V48a4,4,0,0,0-4-4Z" />
          <path d="M168,140a4,4,0,0,1-4-4V88a4,4,0,0,1,8,0v48A4,4,0,0,1,168,140Z" />
          <path d="M120,140a4,4,0,0,1-4-4V88a4,4,0,0,1,8,0v48A4,4,0,0,1,120,140Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M72,248a6.8,6.8,0,0,1-3.4-.8A7.9,7.9,0,0,1,64,240V208H48a16,16,0,0,1-16-16V48A16,16,0,0,1,48,32H208a16,16,0,0,1,16,16V156.3a15.7,15.7,0,0,1-5.8,12.2l-42.9,35.8a15.8,15.8,0,0,1-10.2,3.7H122.9L77.1,246.1A7.5,7.5,0,0,1,72,248ZM48,48V192H72a8,8,0,0,1,8,8v22.9l32.7-27.2a15.8,15.8,0,0,1,10.2-3.7h42.2L208,156.3V48Z" />
          <path d="M168,144a8,8,0,0,1-8-8V88a8,8,0,0,1,16,0v48A8,8,0,0,1,168,144Z" />
          <path d="M120,144a8,8,0,0,1-8-8V88a8,8,0,0,1,16,0v48A8,8,0,0,1,120,144Z" />
        </>
      )}
    </svg>
  );
}

export function PhTwitterLogo(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M251.1,67.4A12,12,0,0,0,240,60H211.8A52,52,0,0,0,116,88v.8c-37.3-11.5-67.2-41-67.5-41.3a12,12,0,0,0-20.3,6.4c-9,49.6,6,83,20.1,102.2a112.3,112.3,0,0,0,22,22.5C55.9,193,36,200.7,35.8,200.8a11.7,11.7,0,0,0-7.2,7.5A12.3,12.3,0,0,0,30,218.7c1.9,2.9,13.4,17.3,50,17.3,72.1,0,132.5-55.1,139.3-126.4l29.2-29.1A12.2,12.2,0,0,0,251.1,67.4ZM199.3,95.8a11.3,11.3,0,0,0-3.5,7.7C191.9,164.3,141,212,80,212a89.4,89.4,0,0,1-12.5-.8c10.4-6.7,22.2-16.1,30.5-28.5a12.1,12.1,0,0,0,1.6-9.8,11.8,11.8,0,0,0-6.2-7.6c-2-1.1-45.6-24.2-43.9-85.4,17.2,13.1,45,30.7,76.5,35.9A12,12,0,0,0,140,104V88h0a28.3,28.3,0,0,1,28.4-28,28,28,0,0,1,25.3,16.8,11.9,11.9,0,0,0,11,7.2H211Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M240,72l-32.3,32.3A127.9,127.9,0,0,1,80,224c-32,0-40-12-40-12s32-12,48-36c0,0-64-32-48-120,0,0,40,40,88,48V88c0-22,18.5-40.3,40.5-40a40,40,0,0,1,36.2,24Z"
            opacity="0.2"
          />
          <path d="M247.4,68.9A8,8,0,0,0,240,64H209.6a48.2,48.2,0,0,0-41-24A48.3,48.3,0,0,0,120,88v6.1C79.3,83.5,46,50.7,45.7,50.3a8,8,0,0,0-8.1-1.9,8.1,8.1,0,0,0-5.5,6.2c-8.7,48.2,5.8,80.5,19.5,99.1a108.6,108.6,0,0,0,24.7,24.4C61,195.4,37.4,204.4,37.2,204.5a8,8,0,0,0-3.9,11.9c.8,1.2,3.8,5.1,11.1,8.8,9.1,4.5,21.1,6.8,35.6,6.8,70.5,0,129.5-54.3,135.5-124.2l30.2-30.1A8.4,8.4,0,0,0,247.4,68.9ZM202.1,98.6a7.8,7.8,0,0,0-2.3,5.2C195.7,166.7,143.1,216,80,216c-10.6,0-18-1.4-23.2-3.1,11.5-6.2,27.5-17,37.9-32.5a8,8,0,0,0,1-6.4,8.1,8.1,0,0,0-4.1-5.1c-.1-.1-14.9-7.8-27.6-25.3C49.6,123.8,43.5,99.7,45.9,71.9c15.8,13,46,34.2,80.8,40a8.1,8.1,0,0,0,6.5-1.8A8.2,8.2,0,0,0,136,104V88h0a32,32,0,0,1,61.3-12.8,8.1,8.1,0,0,0,7.4,4.8h16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M245.7,77.7l-30.2,30.1C209.5,177.7,150.5,232,80,232c-14.5,0-26.5-2.3-35.6-6.8-7.3-3.7-10.3-7.6-11.1-8.8a8,8,0,0,1,3.9-11.9c.2-.1,23.8-9.1,39.1-26.4a108.6,108.6,0,0,1-24.7-24.4c-13.7-18.6-28.2-50.9-19.5-99.1a8.1,8.1,0,0,1,5.5-6.2,8,8,0,0,1,8.1,1.9c.3.4,33.6,33.2,74.3,43.8V88a48.3,48.3,0,0,1,48.6-48,48.2,48.2,0,0,1,41,24H240a8,8,0,0,1,7.4,4.9A8.4,8.4,0,0,1,245.7,77.7Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M245.5,69.7A6,6,0,0,0,240,66H208.4a46,46,0,0,0-39.8-24A46.4,46.4,0,0,0,122,88v8.7C79.6,86.6,44.6,52.1,44.2,51.8a6,6,0,0,0-10.1,3.1c-8.7,47.6,5.6,79.4,19.1,97.7a105.6,105.6,0,0,0,26,25.1c-15.5,18.8-41,28.6-41.3,28.7a5.9,5.9,0,0,0-3.6,3.8,5.8,5.8,0,0,0,.7,5.1c1,1.5,10.8,14.7,45,14.7,69.7,0,128-53.9,133.6-123.1l30.6-30.7A5.7,5.7,0,0,0,245.5,69.7Zm-42,30.3a5.8,5.8,0,0,0-1.7,3.9C197.7,167.9,144.2,218,80,218c-13.9,0-22.8-2.4-28.1-4.8,11.3-5.7,29.8-16.9,41.1-33.9a6.2,6.2,0,0,0,.8-4.8,6.1,6.1,0,0,0-3.1-3.9c-.2,0-15.2-7.8-28.2-25.6C47,123.8,40.9,97.9,44.3,68c14.6,12.4,46.1,35.8,82.7,41.9a5.9,5.9,0,0,0,7-5.9V88h0a34.3,34.3,0,0,1,34.4-34,34.2,34.2,0,0,1,30.8,20.4,5.9,5.9,0,0,0,5.5,3.6h20.8Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M243.7,70.5A4,4,0,0,0,240,68H207.2a44,44,0,0,0-38.6-24A44.4,44.4,0,0,0,124,88V99.2c-44.1-9.4-80.8-45.7-81.2-46a3.9,3.9,0,0,0-6.7,2.1c-8.6,46.8,5.4,78.1,18.7,96.1a101.8,101.8,0,0,0,27.4,25.8c-15.7,20.4-43.3,30.9-43.6,31.1a4,4,0,0,0-2.4,2.5,3.8,3.8,0,0,0,.5,3.4c.2.4,2.8,4,9.5,7.4,8.5,4.2,19.9,6.4,33.8,6.4,68.9,0,126.5-53.5,131.6-122l31.2-31.2A3.8,3.8,0,0,0,243.7,70.5Zm-38.8,30.9a4.2,4.2,0,0,0-1.2,2.6C199.6,169.1,145.2,220,80,220c-17.8,0-27.6-3.9-32.5-6.9,10.4-4.8,31.5-16.3,43.8-34.9a3.7,3.7,0,0,0,.6-3.2,4.2,4.2,0,0,0-2.1-2.6c-.2-.1-15.3-7.8-28.6-25.8C44.6,124,38.4,96.3,42.8,64c12.8,11.3,45.8,37.5,84.5,43.9a4.2,4.2,0,0,0,3.3-.8A4.1,4.1,0,0,0,132,104V88h0a36.4,36.4,0,0,1,36.5-36A36.1,36.1,0,0,1,201,73.6a3.9,3.9,0,0,0,3.7,2.4h25.6Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M247.4,68.9A8,8,0,0,0,240,64H209.6a47.8,47.8,0,0,0-75.2-10.1A47.7,47.7,0,0,0,120,88v6.1C79.3,83.5,46,50.7,45.7,50.3a8,8,0,0,0-8.1-1.9,8.1,8.1,0,0,0-5.5,6.2c-8.7,48.2,5.8,80.5,19.5,99.1a108.6,108.6,0,0,0,24.7,24.4C61,195.4,37.4,204.4,37.2,204.5a8,8,0,0,0-3.9,11.9c.8,1.2,3.8,5.1,11.1,8.8,9.1,4.5,21.1,6.8,35.6,6.8,70.5,0,129.5-54.3,135.5-124.2l30.2-30.1A8.4,8.4,0,0,0,247.4,68.9ZM202.1,98.6a7.8,7.8,0,0,0-2.3,5.2C195.7,166.7,143.1,216,80,216c-10.6,0-18-1.4-23.2-3.1,11.5-6.2,27.5-17,37.9-32.5a8,8,0,0,0,1-6.4,8.1,8.1,0,0,0-4.1-5.1c-.1-.1-14.9-7.8-27.6-25.3C49.6,123.8,43.5,99.7,45.9,71.9c15.8,13,46,34.2,80.8,40a8.1,8.1,0,0,0,6.5-1.8A8.2,8.2,0,0,0,136,104V88h0a32,32,0,0,1,61.3-12.8,8.1,8.1,0,0,0,7.4,4.8h16Z" />
        </>
      )}
    </svg>
  );
}

export function PhUmbrellaSimple(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M243.6,126.3a116,116,0,0,0-231.2,0,19.7,19.7,0,0,0,5.2,15.2A20.2,20.2,0,0,0,32.3,148H116v52a36,36,0,0,0,72,0,12,12,0,0,0-24,0,12,12,0,0,1-24,0V148h83.7a20.2,20.2,0,0,0,14.7-6.5A19.7,19.7,0,0,0,243.6,126.3ZM36.8,124a92,92,0,0,1,182.4,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M223.7,136H32.3a7.9,7.9,0,0,1-7.9-8.7,104,104,0,0,1,207.2,0A7.9,7.9,0,0,1,223.7,136Z"
            opacity="0.2"
          />
          <path d="M239.6,126.6A111.9,111.9,0,0,0,52,53.8a110.9,110.9,0,0,0-35.6,72.8,15.7,15.7,0,0,0,4.2,12.2A15.9,15.9,0,0,0,32.3,144H120v56a32,32,0,0,0,64,0,8,8,0,0,0-16,0,16,16,0,0,1-32,0V144h87.7a15.9,15.9,0,0,0,11.7-5.2A15.7,15.7,0,0,0,239.6,126.6ZM32.3,128h0a96,96,0,0,1,191.4,0Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M235.4,138.8a15.9,15.9,0,0,1-11.7,5.2H136v56a16,16,0,0,0,32,0,8,8,0,0,1,16,0,32,32,0,0,1-64,0V144H32.3a15.9,15.9,0,0,1-11.7-5.2,15.7,15.7,0,0,1-4.2-12.2A111.9,111.9,0,0,1,204,53.8a110.9,110.9,0,0,1,35.6,72.8A15.7,15.7,0,0,1,235.4,138.8Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M237.6,126.8a110,110,0,0,0-219.2,0A14.4,14.4,0,0,0,22,137.5,14,14,0,0,0,32.3,142H122v58a30,30,0,0,0,60,0,6,6,0,0,0-12,0,18,18,0,0,1-36,0V142h89.7a14,14,0,0,0,10.3-4.5A14.4,14.4,0,0,0,237.6,126.8Zm-12.5,2.6a1.7,1.7,0,0,1-1.4.6H32.3a1.7,1.7,0,0,1-1.4-.6,2.2,2.2,0,0,1-.6-1.6,98,98,0,0,1,195.4,0A2.2,2.2,0,0,1,225.1,129.4Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M235.6,127a108,108,0,0,0-215.2,0,11.7,11.7,0,0,0,3.1,9.1,12,12,0,0,0,8.8,3.9H124v60a28,28,0,0,0,56,0,4,4,0,0,0-8,0,20,20,0,0,1-40,0V140h91.7a12,12,0,0,0,8.8-3.9A11.7,11.7,0,0,0,235.6,127Zm-9,3.7a4.1,4.1,0,0,1-2.9,1.3H32.3a4.1,4.1,0,0,1-2.9-1.3,4.2,4.2,0,0,1-1.1-3.1,100.1,100.1,0,0,1,199.4,0A4.2,4.2,0,0,1,226.6,130.7Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M239.6,126.6A111.9,111.9,0,0,0,52,53.8a110.9,110.9,0,0,0-35.6,72.8,15.7,15.7,0,0,0,4.2,12.2A15.9,15.9,0,0,0,32.3,144H120v56a32,32,0,0,0,64,0,8,8,0,0,0-16,0,16,16,0,0,1-32,0V144h87.7a15.9,15.9,0,0,0,11.7-5.2A15.7,15.7,0,0,0,239.6,126.6ZM32.3,128h0a96,96,0,0,1,191.4,0Z" />
        </>
      )}
    </svg>
  );
}

export function PhUmbrella(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M243.6,126.3a116,116,0,0,0-231.2,0,19.7,19.7,0,0,0,5.2,15.2A20.2,20.2,0,0,0,32.3,148H116v52a36,36,0,0,0,72,0,12,12,0,0,0-24,0,12,12,0,0,1-24,0V148h83.7a20.2,20.2,0,0,0,14.7-6.5A19.7,19.7,0,0,0,243.6,126.3ZM100.4,124c2.7-39.3,18.1-63.5,27.6-74.9,9.5,11.4,24.9,35.6,27.6,74.9ZM65.5,68.4A92.7,92.7,0,0,1,99,48.7C88.8,65.3,78.4,90.1,76.4,124H36.8A91.7,91.7,0,0,1,65.5,68.4ZM179.6,124c-2-33.9-12.4-58.7-22.6-75.3a92.7,92.7,0,0,1,33.5,19.7A91.7,91.7,0,0,1,219.2,124Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M223.7,136H168c0-72-40-104-40-104a103.9,103.9,0,0,1,103.6,95.3A7.9,7.9,0,0,1,223.7,136ZM24.4,127.3a7.9,7.9,0,0,0,7.9,8.7H88c0-72,40-104,40-104A103.9,103.9,0,0,0,24.4,127.3Z"
            opacity="0.2"
          />
          <path d="M239.6,126.6A112.1,112.1,0,0,0,128,24h0A112.1,112.1,0,0,0,16.4,126.6a15.7,15.7,0,0,0,4.2,12.2A15.9,15.9,0,0,0,32.3,144H120v56a32,32,0,0,0,64,0,8,8,0,0,0-16,0,16,16,0,0,1-32,0V144h87.7a15.9,15.9,0,0,0,11.7-5.2A15.7,15.7,0,0,0,239.6,126.6ZM96.2,128c1.4-30.8,10.5-52.8,18.3-66.2A97.7,97.7,0,0,1,128,43.2a97.7,97.7,0,0,1,13.5,18.6c13.3,22.9,17.5,47.5,18.3,66.2Zm-63.9,0a95.9,95.9,0,0,1,75.9-85.9c-11.7,16-26.4,44-28,85.9Zm143.5,0c-1.6-41.9-16.3-69.9-28-85.9A95.9,95.9,0,0,1,223.7,128Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M239.6,126.6A112.1,112.1,0,0,0,128,24h0A112.1,112.1,0,0,0,16.4,126.6a15.7,15.7,0,0,0,4.2,12.2A15.9,15.9,0,0,0,32.3,144H120v56a32,32,0,0,0,64,0,8,8,0,0,0-16,0,16,16,0,0,1-32,0V144h87.7a15.9,15.9,0,0,0,11.7-5.2A15.7,15.7,0,0,0,239.6,126.6ZM80.2,128H32.3a95.9,95.9,0,0,1,75.9-85.9C96.5,58.1,81.8,86.1,80.2,128Zm95.6,0c-1.6-41.9-16.3-69.9-28-85.9A95.9,95.9,0,0,1,223.7,128Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M237.6,126.8a110,110,0,0,0-219.2,0A14.4,14.4,0,0,0,22,137.5,14,14,0,0,0,32.3,142H122v58a30,30,0,0,0,60,0,6,6,0,0,0-12,0,18,18,0,0,1-36,0V142h89.7a14,14,0,0,0,10.3-4.5A14.4,14.4,0,0,0,237.6,126.8ZM94.1,130c1.7-51.2,24.7-80.2,33.9-89.7,9.2,9.6,32.2,38.5,33.9,89.7Zm-63.2-.6a2.2,2.2,0,0,1-.6-1.6,98,98,0,0,1,82.6-88.6c-12,14.9-29.4,44.4-30.8,90.8H32.3A1.7,1.7,0,0,1,30.9,129.4Zm194.2,0a1.7,1.7,0,0,1-1.4.6H173.9c-1.4-46.4-18.8-75.9-30.8-90.8a98,98,0,0,1,82.6,88.6A2.2,2.2,0,0,1,225.1,129.4Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M235.6,127A108,108,0,0,0,128,28h0A108,108,0,0,0,20.4,127a11.7,11.7,0,0,0,3.1,9.1,12,12,0,0,0,8.8,3.9H124v60a28,28,0,0,0,56,0,4,4,0,0,0-8,0,20,20,0,0,1-40,0V140h91.7a12,12,0,0,0,8.8-3.9A11.7,11.7,0,0,0,235.6,127ZM92.1,132c1.2-56,27.6-86.4,35.9-94.6,8.3,8.2,34.7,38.6,35.9,94.6Zm-62.7-1.3a4.2,4.2,0,0,1-1.1-3.1,100.2,100.2,0,0,1,89.4-91.1C106,49.8,85.1,80.5,84,132H32.3A4.1,4.1,0,0,1,29.4,130.7Zm197.2,0a4.1,4.1,0,0,1-2.9,1.3H172c-1.1-51.5-22-82.2-33.7-95.5a100.2,100.2,0,0,1,89.4,91.1A4.2,4.2,0,0,1,226.6,130.7Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M239.6,126.6A112.1,112.1,0,0,0,128,24h0A112.1,112.1,0,0,0,16.4,126.6a15.7,15.7,0,0,0,4.2,12.2A15.9,15.9,0,0,0,32.3,144H120v56a32,32,0,0,0,64,0,8,8,0,0,0-16,0,16,16,0,0,1-32,0V144h87.7a15.9,15.9,0,0,0,11.7-5.2A15.7,15.7,0,0,0,239.6,126.6ZM96.2,128c1.4-30.8,10.5-52.8,18.3-66.2A97.7,97.7,0,0,1,128,43.2a97.7,97.7,0,0,1,13.5,18.6c13.3,22.9,17.5,47.5,18.3,66.2Zm-63.9,0a95.9,95.9,0,0,1,75.9-85.9c-11.7,16-26.4,44-28,85.9Zm143.5,0c-1.6-41.9-16.3-69.9-28-85.9A95.9,95.9,0,0,1,223.7,128Z" />
        </>
      )}
    </svg>
  );
}

export function PhUploadSimple(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M77.5,90.5a12,12,0,0,1,0-17l42-42a12,12,0,0,1,17,0l42,42a12,12,0,0,1,0,17,12.1,12.1,0,0,1-17,0L140,69v83a12,12,0,0,1-24,0V69L94.5,90.5A12,12,0,0,1,77.5,90.5ZM216,140a12,12,0,0,0-12,12v52H52V152a12,12,0,0,0-24,0v56a20.1,20.1,0,0,0,20,20H208a20.1,20.1,0,0,0,20-20V152A12,12,0,0,0,216,140Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M80.3,87.6a8,8,0,0,1,0-11.3l42-42a8.1,8.1,0,0,1,11.4,0l42,42a8,8,0,0,1,0,11.3,8,8,0,0,1-11.4,0L136,59.3V152a8,8,0,0,1-16,0V59.3L91.7,87.6A8,8,0,0,1,80.3,87.6ZM216,144a8,8,0,0,0-8,8v56H48V152a8,8,0,0,0-16,0v56a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V152A8,8,0,0,0,216,144Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M78.6,85a8.2,8.2,0,0,1,1.7-8.7l42-42a8.1,8.1,0,0,1,11.4,0l42,42a8.2,8.2,0,0,1,1.7,8.7,8,8,0,0,1-7.4,5H136v62a8,8,0,0,1-16,0V90H86A8,8,0,0,1,78.6,85ZM216,144a8,8,0,0,0-8,8v56H48V152a8,8,0,0,0-16,0v56a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V152A8,8,0,0,0,216,144Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M81.8,86.2a5.9,5.9,0,0,1,0-8.5l42-41.9a5.8,5.8,0,0,1,8.4,0l42,41.9a5.9,5.9,0,0,1,0,8.5,5.8,5.8,0,0,1-8.4,0L134,54.5V152a6,6,0,0,1-12,0V54.5L90.2,86.2A5.8,5.8,0,0,1,81.8,86.2ZM216,146a6,6,0,0,0-6,6v56a2,2,0,0,1-2,2H48a2,2,0,0,1-2-2V152a6,6,0,0,0-12,0v56a14,14,0,0,0,14,14H208a14,14,0,0,0,14-14V152A6,6,0,0,0,216,146Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M83.2,84.8a3.9,3.9,0,0,1,0-5.6l42-42a3.9,3.9,0,0,1,5.6,0l42,42a3.9,3.9,0,0,1,0,5.6,3.9,3.9,0,0,1-5.6,0L132,49.7V152a4,4,0,0,1-8,0V49.7L88.8,84.8A3.9,3.9,0,0,1,83.2,84.8ZM216,148a4,4,0,0,0-4,4v56a4,4,0,0,1-4,4H48a4,4,0,0,1-4-4V152a4,4,0,0,0-8,0v56a12,12,0,0,0,12,12H208a12,12,0,0,0,12-12V152A4,4,0,0,0,216,148Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M80.3,87.6a8,8,0,0,1,0-11.3l42-42a8.1,8.1,0,0,1,11.4,0l42,42a8,8,0,0,1,0,11.3,8,8,0,0,1-11.4,0L136,59.3V152a8,8,0,0,1-16,0V59.3L91.7,87.6A8,8,0,0,1,80.3,87.6ZM216,144a8,8,0,0,0-8,8v56H48V152a8,8,0,0,0-16,0v56a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V152A8,8,0,0,0,216,144Z" />
        </>
      )}
    </svg>
  );
}

export function PhUpload(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M244,136v64a20.1,20.1,0,0,1-20,20H32a20.1,20.1,0,0,1-20-20V136a20.1,20.1,0,0,1,20-20H84a12,12,0,0,1,0,24H36v56H220V140H172a12,12,0,0,1,0-24h52A20.1,20.1,0,0,1,244,136ZM88.5,80.5,116,53v75a12,12,0,0,0,24,0V53l27.5,27.5a12.1,12.1,0,0,0,17,0,12,12,0,0,0,0-17l-48-48a12,12,0,0,0-17,0l-48,48a12,12,0,0,0,17,17ZM204,168a16,16,0,1,0-16,16A16,16,0,0,0,204,168Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M232,136v64a8,8,0,0,1-8,8H32a8,8,0,0,1-8-8V136a8,8,0,0,1,8-8H224A8,8,0,0,1,232,136Z"
            opacity="0.2"
          />
          <path d="M240,136v64a16,16,0,0,1-16,16H32a16,16,0,0,1-16-16V136a16,16,0,0,1,16-16H80a8,8,0,0,1,0,16H32v64H224V136H176a8,8,0,0,1,0-16h48A16,16,0,0,1,240,136ZM85.7,77.7,120,43.3V128a8,8,0,0,0,16,0V43.3l34.3,34.4a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4l-48-48a8.1,8.1,0,0,0-11.4,0l-48,48A8.1,8.1,0,0,0,85.7,77.7ZM200,168a12,12,0,1,0-12,12A12,12,0,0,0,200,168Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M74.3,77.7a8.1,8.1,0,0,1,0-11.4l48-48a8.1,8.1,0,0,1,11.4,0l48,48a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0L136,43.3V128a8,8,0,0,1-16,0V43.3L85.7,77.7A8.1,8.1,0,0,1,74.3,77.7ZM240,136v64a16,16,0,0,1-16,16H32a16,16,0,0,1-16-16V136a16,16,0,0,1,16-16h68a4,4,0,0,1,4,4v3.5c0,13.4,11,24.8,24.5,24.5A24,24,0,0,0,152,128v-4a4,4,0,0,1,4-4h68A16,16,0,0,1,240,136Zm-40,32a12,12,0,1,0-12,12A12,12,0,0,0,200,168Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M238,136v64a14,14,0,0,1-14,14H32a14,14,0,0,1-14-14V136a14,14,0,0,1,14-14H80a6,6,0,0,1,0,12H32a2,2,0,0,0-2,2v64a2,2,0,0,0,2,2H224a2,2,0,0,0,2-2V136a2,2,0,0,0-2-2H176a6,6,0,0,1,0-12h48A14,14,0,0,1,238,136ZM84.2,76.2,122,38.5V128a6,6,0,0,0,12,0V38.5l37.8,37.7a5.9,5.9,0,1,0,8.4-8.4l-48-48a5.8,5.8,0,0,0-8.4,0l-48,48a5.9,5.9,0,0,0,8.4,8.4ZM198,168a10,10,0,1,0-10,10A10,10,0,0,0,198,168Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M236,136v64a12,12,0,0,1-12,12H32a12,12,0,0,1-12-12V136a12,12,0,0,1,12-12H80a4,4,0,0,1,0,8H32a4,4,0,0,0-4,4v64a4,4,0,0,0,4,4H224a4,4,0,0,0,4-4V136a4,4,0,0,0-4-4H176a4,4,0,0,1,0-8h48A12,12,0,0,1,236,136ZM82.8,74.8,124,33.7V128a4,4,0,0,0,8,0V33.7l41.2,41.1a3.9,3.9,0,0,0,5.6,0,3.9,3.9,0,0,0,0-5.6l-48-48a3.9,3.9,0,0,0-5.6,0l-48,48a4,4,0,0,0,5.6,5.6ZM196,168a8,8,0,1,0-8,8A8,8,0,0,0,196,168Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M240,136v64a16,16,0,0,1-16,16H32a16,16,0,0,1-16-16V136a16,16,0,0,1,16-16H80a8,8,0,0,1,0,16H32v64H224V136H176a8,8,0,0,1,0-16h48A16,16,0,0,1,240,136ZM85.7,77.7,120,43.3V128a8,8,0,0,0,16,0V43.3l34.3,34.4a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4l-48-48a8.1,8.1,0,0,0-11.4,0l-48,48A8.1,8.1,0,0,0,85.7,77.7ZM200,168a12,12,0,1,0-12,12A12,12,0,0,0,200,168Z" />
        </>
      )}
    </svg>
  );
}

export function PhUserCircleGear(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M169.6,46.1a12,12,0,0,1,15.1-7.7l3.3,1.1V36a12,12,0,0,1,24,0v3.5l3.3-1.1a12,12,0,0,1,7.4,22.8l-3.3,1.1,2.1,2.8a12,12,0,0,1-2.7,16.8A12,12,0,0,1,202,79.2l-2-2.8-2,2.8a12,12,0,0,1-19.5-14.1l2.1-2.8-3.3-1.1A11.9,11.9,0,0,1,169.6,46.1ZM201,207.5a11.4,11.4,0,0,1-1.6,1.5,107.9,107.9,0,0,1-142.8,0,11.4,11.4,0,0,1-1.6-1.5A107.9,107.9,0,0,1,128,20c3.3,0,6.7.2,10,.5a12,12,0,0,1,10.9,13,12.1,12.1,0,0,1-13.1,10.9A67.9,67.9,0,0,0,128,44,83.9,83.9,0,0,0,62.1,179.9,84,84,0,0,1,91,156.5a52,52,0,1,1,74,0,84,84,0,0,1,28.9,23.4A83.4,83.4,0,0,0,212,128a84.3,84.3,0,0,0-.6-10.1,12,12,0,0,1,23.8-2.8A110.3,110.3,0,0,1,236,128,107.7,107.7,0,0,1,201,207.5ZM128,148a28,28,0,1,0-28-28A28.1,28.1,0,0,0,128,148Zm0,64a83.5,83.5,0,0,0,48.4-15.4,59.9,59.9,0,0,0-96.8,0A83.5,83.5,0,0,0,128,212Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M205.3,71.1a15.5,15.5,0,0,1-5.3.9,16,16,0,0,1-16-16,15.5,15.5,0,0,1,.9-5.3A96,96,0,0,0,63.8,199.4h0A72,72,0,0,1,128,160a40,40,0,1,1,40-40,40,40,0,0,1-40,40,72,72,0,0,1,64.2,39.4A96,96,0,0,0,205.3,71.1Z" />
          </g>
          <path d="M228.2,63.1l-4.6-2.7A21.2,21.2,0,0,0,224,56a21.2,21.2,0,0,0-.4-4.4l4.6-2.7a7.9,7.9,0,0,0,3-10.9,8.1,8.1,0,0,0-11-2.9l-4.6,2.7a24,24,0,0,0-7.6-4.4V28a8,8,0,0,0-16,0v5.4a24,24,0,0,0-7.6,4.4l-4.6-2.7a8.1,8.1,0,0,0-11,2.9,7.9,7.9,0,0,0,3,10.9l4.6,2.7A21.2,21.2,0,0,0,176,56a21.2,21.2,0,0,0,.4,4.4l-4.6,2.7a8,8,0,0,0,4,14.9,7.6,7.6,0,0,0,4-1.1l4.6-2.7a24,24,0,0,0,7.6,4.4V84a8,8,0,0,0,16,0V78.6a24,24,0,0,0,7.6-4.4l4.6,2.7a7.6,7.6,0,0,0,4,1.1,8,8,0,0,0,4-14.9ZM192,56a8,8,0,1,1,8,8A8,8,0,0,1,192,56Zm30.4,52.6a8,8,0,0,0-7,8.8A94.2,94.2,0,0,1,216,128a87.6,87.6,0,0,1-22.2,58.4,79.2,79.2,0,0,0-36.1-28.7,48,48,0,1,0-59.4,0,79.2,79.2,0,0,0-36.1,28.7A88,88,0,0,1,128,40a75,75,0,0,1,8.2.4,8,8,0,1,0,1.4-16c-3.1-.3-6.4-.4-9.6-.4A104,104,0,0,0,57.8,204.7l1.3,1.2a104,104,0,0,0,137.8,0l1.3-1.2A103.7,103.7,0,0,0,232,128a101.9,101.9,0,0,0-.7-12.4A8,8,0,0,0,222.4,108.6ZM96,120a32,32,0,1,1,32,32A32.1,32.1,0,0,1,96,120ZM74.1,197.5a64,64,0,0,1,107.8,0,87.8,87.8,0,0,1-107.8,0Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,76a44,44,0,1,1-44,44A44,44,0,0,1,128,76Zm103.2-2a8,8,0,0,1-7,4,7.6,7.6,0,0,1-4-1.1l-4.6-2.7a24,24,0,0,1-7.6,4.4V84a8,8,0,0,1-16,0V78.6a24,24,0,0,1-7.6-4.4l-4.6,2.7a7.6,7.6,0,0,1-4,1.1,8,8,0,0,1-4-14.9l4.6-2.7A21.2,21.2,0,0,1,176,56a21.2,21.2,0,0,1,.4-4.4l-4.6-2.7a7.9,7.9,0,0,1-3-10.9,8.1,8.1,0,0,1,11-2.9l4.6,2.7a24,24,0,0,1,7.6-4.4V28a8,8,0,0,1,16,0v5.4a24,24,0,0,1,7.6,4.4l4.6-2.7a8.1,8.1,0,0,1,11,2.9,7.9,7.9,0,0,1-3,10.9l-4.6,2.7A21.2,21.2,0,0,1,224,56a21.2,21.2,0,0,1-.4,4.4l4.6,2.7A7.9,7.9,0,0,1,231.2,74ZM200,64a8,8,0,1,0-8-8A8,8,0,0,0,200,64Zm22.4,44.6a8,8,0,0,0-7,8.8A94.2,94.2,0,0,1,216,128a87.6,87.6,0,0,1-22.2,58.4,81.3,81.3,0,0,0-24.5-23,59.7,59.7,0,0,1-82.6,0,81.3,81.3,0,0,0-24.5,23A88,88,0,0,1,128,40a75,75,0,0,1,8.2.4,8,8,0,1,0,1.4-16c-3.1-.3-6.4-.4-9.6-.4A104,104,0,0,0,57.8,204.7l1.3,1.2a104,104,0,0,0,137.8,0l1.3-1.2A103.7,103.7,0,0,0,232,128a101.9,101.9,0,0,0-.7-12.4A8,8,0,0,0,222.4,108.6Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M227.2,64.8l-5.9-3.4a21.2,21.2,0,0,0,0-10.8l5.9-3.4a6,6,0,0,0-6-10.4l-5.9,3.4a21.8,21.8,0,0,0-9.3-5.4V28a6,6,0,0,0-12,0v6.8a21.8,21.8,0,0,0-9.3,5.4l-5.9-3.4a6,6,0,1,0-6,10.4l5.9,3.4a21.2,21.2,0,0,0,0,10.8l-5.9,3.4a6,6,0,0,0,3,11.2,6.7,6.7,0,0,0,3-.8l5.9-3.4a21.8,21.8,0,0,0,9.3,5.4V84a6,6,0,0,0,12,0V77.2a21.8,21.8,0,0,0,9.3-5.4l5.9,3.4a6.7,6.7,0,0,0,3,.8,6,6,0,0,0,3-11.2ZM200,66a10,10,0,1,1,10-10A10,10,0,0,1,200,66Zm22.6,44.5a6,6,0,0,0-5.2,6.7A97.7,97.7,0,0,1,218,128a89.5,89.5,0,0,1-24.5,61.6,77.7,77.7,0,0,0-40-31.3,46,46,0,1,0-51,0,77.7,77.7,0,0,0-40,31.3A89.9,89.9,0,0,1,128,38a78.7,78.7,0,0,1,8.4.4,6,6,0,0,0,6.5-5.4,6.1,6.1,0,0,0-5.4-6.6c-3.2-.3-6.3-.4-9.5-.4A102,102,0,0,0,59.2,203.3l1.2,1a101.8,101.8,0,0,0,135.2,0l1.2-1A101.8,101.8,0,0,0,230,128a98.7,98.7,0,0,0-.7-12.2A6.1,6.1,0,0,0,222.6,110.5ZM94,120a34,34,0,1,1,34,34A34.1,34.1,0,0,1,94,120ZM71.4,198a66.1,66.1,0,0,1,113.2,0,90.1,90.1,0,0,1-113.2,0Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M226.2,66.5,219,62.3a20.3,20.3,0,0,0,0-12.6l7.2-4.2a4,4,0,0,0,1.5-5.5,4,4,0,0,0-5.5-1.5l-7.3,4.3A19.9,19.9,0,0,0,204,36.4V28a4,4,0,0,0-8,0v8.4a19.9,19.9,0,0,0-10.9,6.4l-7.3-4.3a4,4,0,0,0-5.5,1.5,4,4,0,0,0,1.5,5.5l7.2,4.2a20.3,20.3,0,0,0,0,12.6l-7.2,4.2a4,4,0,0,0,2,7.5,5.3,5.3,0,0,0,2-.5l7.3-4.3A19.9,19.9,0,0,0,196,75.6V84a4,4,0,0,0,8,0V75.6a19.9,19.9,0,0,0,10.9-6.4l7.3,4.3a5.3,5.3,0,0,0,2,.5,4,4,0,0,0,2-7.5ZM200,68a12,12,0,1,1,12-12A12,12,0,0,1,200,68Zm22.8,44.5a4.2,4.2,0,0,0-3.5,4.5,80.2,80.2,0,0,1,.7,11,92.1,92.1,0,0,1-26.8,64.9,75.8,75.8,0,0,0-44.5-34.1,44,44,0,1,0-41.4,0,75.8,75.8,0,0,0-44.5,34.1A92,92,0,0,1,128,36a80.6,80.6,0,0,1,8.5.4,4,4,0,0,0,.8-8c-3.1-.3-6.2-.4-9.3-.4A100,100,0,0,0,60.7,201.9a3.6,3.6,0,0,0,.9.8,99.8,99.8,0,0,0,132.8,0,3.6,3.6,0,0,0,.9-.8A99.6,99.6,0,0,0,228,128a96.6,96.6,0,0,0-.7-12A4.1,4.1,0,0,0,222.8,112.5ZM92,120a36,36,0,1,1,36,36A36,36,0,0,1,92,120ZM68.9,198.4a68,68,0,0,1,118.2,0,91.7,91.7,0,0,1-118.2,0Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M228.2,63.1l-4.6-2.7A21.2,21.2,0,0,0,224,56a21.2,21.2,0,0,0-.4-4.4l4.6-2.7a7.9,7.9,0,0,0,3-10.9,8.1,8.1,0,0,0-11-2.9l-4.6,2.7a24,24,0,0,0-7.6-4.4V28a8,8,0,0,0-16,0v5.4a24,24,0,0,0-7.6,4.4l-4.6-2.7a8.1,8.1,0,0,0-11,2.9,7.9,7.9,0,0,0,3,10.9l4.6,2.7A21.2,21.2,0,0,0,176,56a21.2,21.2,0,0,0,.4,4.4l-4.6,2.7a8,8,0,0,0,4,14.9,7.6,7.6,0,0,0,4-1.1l4.6-2.7a24,24,0,0,0,7.6,4.4V84a8,8,0,0,0,16,0V78.6a24,24,0,0,0,7.6-4.4l4.6,2.7a7.6,7.6,0,0,0,4,1.1,8,8,0,0,0,4-14.9ZM192,56a8,8,0,1,1,8,8A8,8,0,0,1,192,56Zm30.4,52.6a8,8,0,0,0-7,8.8A94.2,94.2,0,0,1,216,128a87.6,87.6,0,0,1-22.2,58.4,79.2,79.2,0,0,0-36.1-28.7,48,48,0,1,0-59.4,0,79.2,79.2,0,0,0-36.1,28.7A88,88,0,0,1,128,40a75,75,0,0,1,8.2.4,8,8,0,1,0,1.4-16c-3.1-.3-6.4-.4-9.6-.4A104,104,0,0,0,57.8,204.7l1.3,1.2a104,104,0,0,0,137.8,0l1.3-1.2A103.7,103.7,0,0,0,232,128a101.9,101.9,0,0,0-.7-12.4A8,8,0,0,0,222.4,108.6ZM96,120a32,32,0,1,1,32,32A32.1,32.1,0,0,1,96,120ZM74.1,197.5a64,64,0,0,1,107.8,0,87.8,87.8,0,0,1-107.8,0Z" />
        </>
      )}
    </svg>
  );
}

export function PhUserCircleMinus(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M164,56a12,12,0,0,1,12-12h48a12,12,0,0,1,0,24H176A12,12,0,0,1,164,56Zm65.9,36a109.8,109.8,0,0,1,6.1,36,107.7,107.7,0,0,1-35,79.5,11.4,11.4,0,0,1-1.6,1.5,107.9,107.9,0,0,1-142.8,0,11.4,11.4,0,0,1-1.6-1.5A108.1,108.1,0,0,1,145,21.3a12.1,12.1,0,0,1,10,13.8A11.9,11.9,0,0,1,141.3,45,85.1,85.1,0,0,0,128,44,83.9,83.9,0,0,0,62.1,179.9,84,84,0,0,1,91,156.5a52,52,0,1,1,74,0,84,84,0,0,1,28.9,23.4A83.7,83.7,0,0,0,207.2,100a12,12,0,0,1,22.7-8ZM128,148a28,28,0,1,0-28-28A28.1,28.1,0,0,0,128,148Zm0,64a83.5,83.5,0,0,0,48.4-15.4,59.9,59.9,0,0,0-96.8,0A83.5,83.5,0,0,0,128,212Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M128,32A96,96,0,0,0,63.8,199.4h0A72,72,0,0,1,128,160a40,40,0,1,1,40-40,40,40,0,0,1-40,40,72,72,0,0,1,64.2,39.4A96,96,0,0,0,128,32Z" />
          </g>
          <path d="M168,56a8,8,0,0,1,8-8h48a8,8,0,0,1,0,16H176A8,8,0,0,1,168,56Zm58.1,37.3a104.2,104.2,0,0,1-27.9,111.4l-1.3,1.2a104,104,0,0,1-137.8,0l-1.3-1.2A104,104,0,0,1,128,24a99.8,99.8,0,0,1,16.4,1.3,8,8,0,0,1-2.5,15.8A92,92,0,0,0,128,40,88,88,0,0,0,62.2,186.4a79.2,79.2,0,0,1,36.1-28.7,48,48,0,1,1,59.4,0,79.2,79.2,0,0,1,36.1,28.7A88,88,0,0,0,211,98.7a7.9,7.9,0,0,1,4.9-10.2A8,8,0,0,1,226.1,93.3ZM128,152a32,32,0,1,0-32-32A32.1,32.1,0,0,0,128,152Zm0,64a87.4,87.4,0,0,0,53.9-18.5,64,64,0,0,0-107.8,0A87.4,87.4,0,0,0,128,216Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M226.1,93.3a104.2,104.2,0,0,1-27.9,111.4l-1.3,1.2a104,104,0,0,1-137.8,0l-1.3-1.2A104,104,0,0,1,128,24a99.8,99.8,0,0,1,16.4,1.3,8,8,0,0,1-2.5,15.8A92,92,0,0,0,128,40,88,88,0,0,0,62.2,186.4a81.3,81.3,0,0,1,24.5-23,59.7,59.7,0,0,0,82.6,0,81.3,81.3,0,0,1,24.5,23A88,88,0,0,0,211,98.7a7.9,7.9,0,0,1,4.9-10.2A8,8,0,0,1,226.1,93.3ZM176,64h48a8,8,0,0,0,0-16H176a8,8,0,0,0,0,16ZM84,120a44,44,0,1,0,44-44A44,44,0,0,0,84,120Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M170,56a6,6,0,0,1,6-6h48a6,6,0,0,1,0,12H176A6,6,0,0,1,170,56Zm54.2,38a102.9,102.9,0,0,1,5.8,34,101.8,101.8,0,0,1-33.2,75.3l-1.2,1a101.8,101.8,0,0,1-135.2,0l-1.2-1A102,102,0,0,1,128,26a96.3,96.3,0,0,1,16.1,1.3,6,6,0,0,1,5,6.8,6.1,6.1,0,0,1-6.9,5A96,96,0,0,0,128,38,89.9,89.9,0,0,0,62.5,189.6a77.7,77.7,0,0,1,40-31.3,46,46,0,1,1,51,0,77.7,77.7,0,0,1,40,31.3A90,90,0,0,0,212.9,98a6,6,0,1,1,11.3-4ZM128,154a34,34,0,1,0-34-34A34.1,34.1,0,0,0,128,154Zm0,64a89.9,89.9,0,0,0,56.6-20,66.1,66.1,0,0,0-113.2,0A89.9,89.9,0,0,0,128,218Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M172,56a4,4,0,0,1,4-4h48a4,4,0,0,1,0,8H176A4,4,0,0,1,172,56Zm50.3,38.7a99.9,99.9,0,0,1-27,107.2l-.8.8a99.9,99.9,0,0,1-132.9,0,3.6,3.6,0,0,1-.9-.8A100,100,0,0,1,128,28a104.1,104.1,0,0,1,15.8,1.2,4.1,4.1,0,0,1,3.3,4.6,4,4,0,0,1-4.6,3.3A100,100,0,0,0,128,36,92,92,0,0,0,62.8,192.9a75.8,75.8,0,0,1,44.5-34.1,44,44,0,1,1,41.4,0,75.8,75.8,0,0,1,44.5,34.1A92.1,92.1,0,0,0,220,128a94.3,94.3,0,0,0-5.2-30.7,3.9,3.9,0,0,1,2.4-5.1A4,4,0,0,1,222.3,94.7ZM128,156a36,36,0,1,0-36-36A36,36,0,0,0,128,156Zm0,64a91.3,91.3,0,0,0,59.1-21.6,68,68,0,0,0-118.2,0A91.3,91.3,0,0,0,128,220Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M168,56a8,8,0,0,1,8-8h48a8,8,0,0,1,0,16H176A8,8,0,0,1,168,56Zm58.1,37.3a104.2,104.2,0,0,1-27.9,111.4l-1.3,1.2a104,104,0,0,1-137.8,0l-1.3-1.2A104,104,0,0,1,128,24a99.8,99.8,0,0,1,16.4,1.3,8,8,0,0,1-2.5,15.8A92,92,0,0,0,128,40,88,88,0,0,0,62.2,186.4a79.2,79.2,0,0,1,36.1-28.7,48,48,0,1,1,59.4,0,79.2,79.2,0,0,1,36.1,28.7A88,88,0,0,0,211,98.7a7.9,7.9,0,0,1,4.9-10.2A8,8,0,0,1,226.1,93.3ZM128,152a32,32,0,1,0-32-32A32.1,32.1,0,0,0,128,152Zm0,64a87.4,87.4,0,0,0,53.9-18.5,64,64,0,0,0-107.8,0A87.4,87.4,0,0,0,128,216Z" />
        </>
      )}
    </svg>
  );
}

export function PhUserCirclePlus(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M164,56a12,12,0,0,1,12-12h12V32a12,12,0,0,1,24,0V44h12a12,12,0,0,1,0,24H212V80a12,12,0,0,1-24,0V68H176A12,12,0,0,1,164,56Zm70.7,55A108.4,108.4,0,0,1,201,207.5a11.4,11.4,0,0,1-1.6,1.5,107.9,107.9,0,0,1-142.8,0,11.4,11.4,0,0,1-1.6-1.5A108.1,108.1,0,0,1,145,21.3a12.1,12.1,0,0,1,10,13.8A11.9,11.9,0,0,1,141.3,45,85.1,85.1,0,0,0,128,44,83.9,83.9,0,0,0,62.1,179.9,84,84,0,0,1,91,156.5a52,52,0,1,1,74,0,84,84,0,0,1,28.9,23.4A83.4,83.4,0,0,0,212,128a85.1,85.1,0,0,0-1-13.3,11.9,11.9,0,0,1,9.9-13.7A12.1,12.1,0,0,1,234.7,111ZM128,148a28,28,0,1,0-28-28A28.1,28.1,0,0,0,128,148Zm0,64a83.5,83.5,0,0,0,48.4-15.4,59.9,59.9,0,0,0-96.8,0A83.5,83.5,0,0,0,128,212Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M128,32A96,96,0,0,0,63.8,199.4h0A72,72,0,0,1,128,160a40,40,0,1,1,40-40,40,40,0,0,1-40,40,72,72,0,0,1,64.2,39.4A96,96,0,0,0,128,32Z" />
          </g>
          <path d="M168,56a8,8,0,0,1,8-8h16V32a8,8,0,0,1,16,0V48h16a8,8,0,0,1,0,16H208V80a8,8,0,0,1-16,0V64H176A8,8,0,0,1,168,56Zm62.7,55.6A99.8,99.8,0,0,1,232,128a103.7,103.7,0,0,1-33.8,76.7l-1.3,1.2a104,104,0,0,1-137.8,0l-1.3-1.2A104,104,0,0,1,128,24a99.8,99.8,0,0,1,16.4,1.3,8,8,0,0,1-2.5,15.8A92,92,0,0,0,128,40,88,88,0,0,0,62.2,186.4a79.2,79.2,0,0,1,36.1-28.7,48,48,0,1,1,59.4,0,79.2,79.2,0,0,1,36.1,28.7A87.6,87.6,0,0,0,216,128a92,92,0,0,0-1.1-13.9,8,8,0,0,1,15.8-2.5ZM128,152a32,32,0,1,0-32-32A32.1,32.1,0,0,0,128,152Zm0,64a87.4,87.4,0,0,0,53.9-18.5,64,64,0,0,0-107.8,0A87.4,87.4,0,0,0,128,216Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,76a44,44,0,1,1-44,44A44,44,0,0,1,128,76Zm48-12h16V80a8,8,0,0,0,16,0V64h16a8,8,0,0,0,0-16H208V32a8,8,0,0,0-16,0V48H176a8,8,0,0,0,0,16Zm45.6,41a7.9,7.9,0,0,0-6.7,9.1A92,92,0,0,1,216,128a87.6,87.6,0,0,1-22.2,58.4,81.3,81.3,0,0,0-24.5-23,59.7,59.7,0,0,1-82.6,0,81.3,81.3,0,0,0-24.5,23A88,88,0,0,1,128,40a92,92,0,0,1,13.9,1.1,8,8,0,0,0,2.5-15.8A99.8,99.8,0,0,0,128,24,104,104,0,0,0,57.8,204.7l1.3,1.2a104,104,0,0,0,137.8,0l1.3-1.2A103.7,103.7,0,0,0,232,128a99.8,99.8,0,0,0-1.3-16.4A7.9,7.9,0,0,0,221.6,105Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M170,56a6,6,0,0,1,6-6h18V32a6,6,0,0,1,12,0V50h18a6,6,0,0,1,0,12H206V80a6,6,0,0,1-12,0V62H176A6,6,0,0,1,170,56Zm58.7,55.9A96.3,96.3,0,0,1,230,128a101.8,101.8,0,0,1-33.2,75.3l-1.2,1a101.8,101.8,0,0,1-135.2,0l-1.2-1A102,102,0,0,1,128,26a96.3,96.3,0,0,1,16.1,1.3,6,6,0,1,1-1.9,11.8A96,96,0,0,0,128,38,89.9,89.9,0,0,0,62.5,189.6a77.7,77.7,0,0,1,40-31.3,46,46,0,1,1,51,0,77.7,77.7,0,0,1,40,31.3A89.5,89.5,0,0,0,218,128a96,96,0,0,0-1.1-14.2,6.1,6.1,0,0,1,5-6.9A6,6,0,0,1,228.7,111.9ZM128,154a34,34,0,1,0-34-34A34.1,34.1,0,0,0,128,154Zm0,64a89.9,89.9,0,0,0,56.6-20,66.1,66.1,0,0,0-113.2,0A89.9,89.9,0,0,0,128,218Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M172,56a4,4,0,0,1,4-4h20V32a4,4,0,0,1,8,0V52h20a4,4,0,0,1,0,8H204V80a4,4,0,0,1-8,0V60H176A4,4,0,0,1,172,56Zm54.8,56.2A104.1,104.1,0,0,1,228,128a99.6,99.6,0,0,1-32.7,73.9l-.8.8a99.9,99.9,0,0,1-132.9,0,3.6,3.6,0,0,1-.9-.8A100,100,0,0,1,128,28a104.1,104.1,0,0,1,15.8,1.2,4,4,0,0,1,3.3,4.6,4,4,0,0,1-4.6,3.3A100,100,0,0,0,128,36,92,92,0,0,0,62.8,192.9a75.8,75.8,0,0,1,44.5-34.1,44,44,0,1,1,41.4,0,75.8,75.8,0,0,1,44.5,34.1A92.1,92.1,0,0,0,220,128a99,99,0,0,0-1.1-14.5,4,4,0,0,1,3.3-4.6A4,4,0,0,1,226.8,112.2ZM128,156a36,36,0,1,0-36-36A36,36,0,0,0,128,156Zm0,64a91.3,91.3,0,0,0,59.1-21.6,68,68,0,0,0-118.2,0A91.3,91.3,0,0,0,128,220Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M168,56a8,8,0,0,1,8-8h16V32a8,8,0,0,1,16,0V48h16a8,8,0,0,1,0,16H208V80a8,8,0,0,1-16,0V64H176A8,8,0,0,1,168,56Zm62.7,55.6A99.8,99.8,0,0,1,232,128a103.7,103.7,0,0,1-33.8,76.7l-1.3,1.2a104,104,0,0,1-137.8,0l-1.3-1.2A104,104,0,0,1,128,24a99.8,99.8,0,0,1,16.4,1.3,8,8,0,0,1-2.5,15.8A92,92,0,0,0,128,40,88,88,0,0,0,62.2,186.4a79.2,79.2,0,0,1,36.1-28.7,48,48,0,1,1,59.4,0,79.2,79.2,0,0,1,36.1,28.7A87.6,87.6,0,0,0,216,128a92,92,0,0,0-1.1-13.9,8,8,0,0,1,15.8-2.5ZM128,152a32,32,0,1,0-32-32A32.1,32.1,0,0,0,128,152Zm0,64a87.4,87.4,0,0,0,53.9-18.5,64,64,0,0,0-107.8,0A87.4,87.4,0,0,0,128,216Z" />
        </>
      )}
    </svg>
  );
}

export function PhUserCircle(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M236,128A108,108,0,1,0,55,207.5a11.4,11.4,0,0,0,1.6,1.5,107.9,107.9,0,0,0,142.8,0,11.4,11.4,0,0,0,1.6-1.5A107.7,107.7,0,0,0,236,128ZM44,128a84,84,0,1,1,149.9,51.9A84,84,0,0,0,165,156.5a52,52,0,1,0-74,0,84,84,0,0,0-28.9,23.4A83.4,83.4,0,0,1,44,128Zm56-8a28,28,0,1,1,28,28A28.1,28.1,0,0,1,100,120ZM79.6,196.6a59.9,59.9,0,0,1,96.8,0,83.8,83.8,0,0,1-96.8,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M128,32A96,96,0,0,0,63.8,199.4h0A72,72,0,0,1,128,160a40,40,0,1,1,40-40,40,40,0,0,1-40,40,72,72,0,0,1,64.2,39.4A96,96,0,0,0,128,32Z" />
          </g>
          <path d="M232,128A104,104,0,1,0,57.8,204.7l1.3,1.2a104,104,0,0,0,137.8,0l1.3-1.2A103.7,103.7,0,0,0,232,128ZM40,128a88,88,0,1,1,153.8,58.4,79.2,79.2,0,0,0-36.1-28.7,48,48,0,1,0-59.4,0,79.2,79.2,0,0,0-36.1,28.7A87.6,87.6,0,0,1,40,128Zm56-8a32,32,0,1,1,32,32A32.1,32.1,0,0,1,96,120ZM74.1,197.5a64,64,0,0,1,107.8,0,87.8,87.8,0,0,1-107.8,0Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M172,120a44,44,0,1,1-44-44A44,44,0,0,1,172,120Zm60,8A104,104,0,1,1,128,24,104.2,104.2,0,0,1,232,128Zm-16,0A88,88,0,1,0,62.2,186.4a81.3,81.3,0,0,1,24.5-23,59.7,59.7,0,0,0,82.6,0,81.3,81.3,0,0,1,24.5,23A87.6,87.6,0,0,0,216,128Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M230,128A102,102,0,1,0,59.2,203.3l1.2,1a101.8,101.8,0,0,0,135.2,0l1.2-1A101.8,101.8,0,0,0,230,128ZM38,128a90,90,0,1,1,155.5,61.6,77.7,77.7,0,0,0-40-31.3,46,46,0,1,0-51,0,77.7,77.7,0,0,0-40,31.3A89.5,89.5,0,0,1,38,128Zm56-8a34,34,0,1,1,34,34A34.1,34.1,0,0,1,94,120ZM71.4,198a66.1,66.1,0,0,1,113.2,0,90.1,90.1,0,0,1-113.2,0Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M228,128A100,100,0,1,0,60.7,201.9a3.6,3.6,0,0,0,.9.8,99.9,99.9,0,0,0,132.9,0l.8-.8A99.6,99.6,0,0,0,228,128ZM36,128a92,92,0,1,1,157.2,64.9,75.8,75.8,0,0,0-44.5-34.1,44,44,0,1,0-41.4,0,75.8,75.8,0,0,0-44.5,34.1A92.1,92.1,0,0,1,36,128Zm92,28a36,36,0,1,1,36-36A36,36,0,0,1,128,156ZM68.9,198.4a68,68,0,0,1,118.2,0,91.7,91.7,0,0,1-118.2,0Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M232,128A104,104,0,1,0,57.8,204.7l1.3,1.2a104,104,0,0,0,137.8,0l1.3-1.2A103.7,103.7,0,0,0,232,128ZM40,128a88,88,0,1,1,153.8,58.4,79.2,79.2,0,0,0-36.1-28.7,48,48,0,1,0-59.4,0,79.2,79.2,0,0,0-36.1,28.7A87.6,87.6,0,0,1,40,128Zm56-8a32,32,0,1,1,32,32A32.1,32.1,0,0,1,96,120ZM74.1,197.5a64,64,0,0,1,107.8,0,87.8,87.8,0,0,1-107.8,0Z" />
        </>
      )}
    </svg>
  );
}

export function PhUserFocus(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M228,48V76a12,12,0,0,1-24,0V52H180a12,12,0,0,1,0-24h28A20.1,20.1,0,0,1,228,48ZM216,168a12,12,0,0,0-12,12v24H180a12,12,0,0,0,0,24h28a20.1,20.1,0,0,0,20-20V180A12,12,0,0,0,216,168ZM76,204H52V180a12,12,0,0,0-24,0v28a20.1,20.1,0,0,0,20,20H76a12,12,0,0,0,0-24ZM40,88A12,12,0,0,0,52,76V52H76a12,12,0,0,0,0-24H48A20.1,20.1,0,0,0,28,48V76A12,12,0,0,0,40,88Zm126.4,87.2a48,48,0,0,0-76.8,0,12,12,0,0,1-16.8,2.4,12,12,0,0,1-2.4-16.8,72.1,72.1,0,0,1,23.9-20.5,44,44,0,1,1,67.4,0,72.1,72.1,0,0,1,23.9,20.5,12,12,0,0,1-19.2,14.4ZM108,112a20,20,0,1,0,20-20A20.1,20.1,0,0,0,108,112Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M160,112a32,32,0,1,1-32-32A32,32,0,0,1,160,112Z"
            opacity="0.2"
          />
          <path d="M224,48V76a8,8,0,0,1-16,0V48H180a8,8,0,0,1,0-16h28A16,16,0,0,1,224,48Zm-8,124a8,8,0,0,0-8,8v28H180a8,8,0,0,0,0,16h28a16,16,0,0,0,16-16V180A8,8,0,0,0,216,172ZM76,208H48V180a8,8,0,0,0-16,0v28a16,16,0,0,0,16,16H76a8,8,0,0,0,0-16ZM40,84a8,8,0,0,0,8-8V48H76a8,8,0,0,0,0-16H48A16,16,0,0,0,32,48V76A8,8,0,0,0,40,84Zm134,95.7a52,52,0,0,0-92,0,8,8,0,1,1-14.2-7.4,69.1,69.1,0,0,1,33.3-30.8A39.5,39.5,0,0,1,88,112a40,40,0,0,1,80,0,39.5,39.5,0,0,1-13.1,29.5,69.1,69.1,0,0,1,33.3,30.8,8.1,8.1,0,0,1-3.4,10.8,8.4,8.4,0,0,1-3.7.9A7.8,7.8,0,0,1,174,179.7ZM128,136a24,24,0,1,0-24-24A24.1,24.1,0,0,0,128,136Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M88,112a40,40,0,0,1,80,0,39.5,39.5,0,0,1-13.1,29.5,69.1,69.1,0,0,1,33.3,30.8,7.8,7.8,0,0,1-.3,7.8,7.9,7.9,0,0,1-6.8,3.9H74.9a7.9,7.9,0,0,1-6.8-3.9,7.8,7.8,0,0,1-.3-7.8,69.1,69.1,0,0,1,33.3-30.8A39.5,39.5,0,0,1,88,112ZM40,84a8,8,0,0,0,8-8V48H76a8,8,0,0,0,0-16H48A16,16,0,0,0,32,48V76A8,8,0,0,0,40,84Zm176,88a8,8,0,0,0-8,8v28H180a8,8,0,0,0,0,16h28a16,16,0,0,0,16-16V180A8,8,0,0,0,216,172ZM208,32H180a8,8,0,0,0,0,16h28V76a8,8,0,0,0,16,0V48A16,16,0,0,0,208,32ZM76,208H48V180a8,8,0,0,0-16,0v28a16,16,0,0,0,16,16H76a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M222,48V76a6,6,0,0,1-12,0V48a2,2,0,0,0-2-2H180a6,6,0,0,1,0-12h28A14,14,0,0,1,222,48Zm-6,126a6,6,0,0,0-6,6v28a2,2,0,0,1-2,2H180a6,6,0,0,0,0,12h28a14,14,0,0,0,14-14V180A6,6,0,0,0,216,174ZM76,210H48a2,2,0,0,1-2-2V180a6,6,0,0,0-12,0v28a14,14,0,0,0,14,14H76a6,6,0,0,0,0-12ZM40,82a6,6,0,0,0,6-6V48a2,2,0,0,1,2-2H76a6,6,0,0,0,0-12H48A14,14,0,0,0,34,48V76A6,6,0,0,0,40,82Zm135.8,96.8a54.1,54.1,0,0,0-95.6,0,6,6,0,0,1-8.1,2.5,6.1,6.1,0,0,1-2.5-8.1,65.7,65.7,0,0,1,35.3-31,38,38,0,1,1,46.2,0,65.7,65.7,0,0,1,35.3,31,6.1,6.1,0,0,1-2.5,8.1,5.8,5.8,0,0,1-2.8.7A6,6,0,0,1,175.8,178.8ZM128,138a26,26,0,1,0-26-26A26.1,26.1,0,0,0,128,138Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M220,48V76a4,4,0,0,1-8,0V48a4,4,0,0,0-4-4H180a4,4,0,0,1,0-8h28A12,12,0,0,1,220,48Zm-4,128a4,4,0,0,0-4,4v28a4,4,0,0,1-4,4H180a4,4,0,0,0,0,8h28a12,12,0,0,0,12-12V180A4,4,0,0,0,216,176ZM76,212H48a4,4,0,0,1-4-4V180a4,4,0,0,0-8,0v28a12,12,0,0,0,12,12H76a4,4,0,0,0,0-8ZM40,80a4,4,0,0,0,4-4V48a4,4,0,0,1,4-4H76a4,4,0,0,0,0-8H48A12,12,0,0,0,36,48V76A4,4,0,0,0,40,80Zm137.5,97.9a55.9,55.9,0,0,0-99,0,4,4,0,0,1-7.1-3.8,64.2,64.2,0,0,1,37.9-31.3,36,36,0,1,1,37.4,0,64.2,64.2,0,0,1,37.9,31.3,4,4,0,0,1-1.7,5.4,2.9,2.9,0,0,1-1.8.5A4.2,4.2,0,0,1,177.5,177.9ZM128,140a28,28,0,1,0-28-28A28.1,28.1,0,0,0,128,140Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M224,48V76a8,8,0,0,1-16,0V48H180a8,8,0,0,1,0-16h28A16,16,0,0,1,224,48Zm-8,124a8,8,0,0,0-8,8v28H180a8,8,0,0,0,0,16h28a16,16,0,0,0,16-16V180A8,8,0,0,0,216,172ZM76,208H48V180a8,8,0,0,0-16,0v28a16,16,0,0,0,16,16H76a8,8,0,0,0,0-16ZM40,84a8,8,0,0,0,8-8V48H76a8,8,0,0,0,0-16H48A16,16,0,0,0,32,48V76A8,8,0,0,0,40,84Zm134,95.7a52,52,0,0,0-92,0,8,8,0,1,1-14.2-7.4,69.1,69.1,0,0,1,33.3-30.8A39.5,39.5,0,0,1,88,112a40,40,0,0,1,80,0,39.5,39.5,0,0,1-13.1,29.5,69.1,69.1,0,0,1,33.3,30.8,8.1,8.1,0,0,1-3.4,10.8,8.4,8.4,0,0,1-3.7.9A7.8,7.8,0,0,1,174,179.7ZM128,136a24,24,0,1,0-24-24A24.1,24.1,0,0,0,128,136Z" />
        </>
      )}
    </svg>
  );
}

export function PhUserGear(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M246.7,141.2l-3.3,1.1,2.1,2.8a12,12,0,0,1-2.7,16.8,12,12,0,0,1-16.8-2.7l-2-2.8-2,2.8a12,12,0,1,1-19.5-14.1l2.1-2.8-3.3-1.1a12,12,0,1,1,7.4-22.8l3.3,1.1V116a12,12,0,0,1,24,0v3.5l3.3-1.1a12,12,0,0,1,7.4,22.8ZM203,192.3a12,12,0,0,1-1.5,16.9,11.8,11.8,0,0,1-7.7,2.8,12.2,12.2,0,0,1-9.2-4.3,100,100,0,0,0-153.2,0A12,12,0,1,1,13,192.3a124.4,124.4,0,0,1,50.3-36,72,72,0,1,1,89.4,0A124.4,124.4,0,0,1,203,192.3ZM108,148a48,48,0,1,0-48-48A48,48,0,0,0,108,148Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M168,100a60,60,0,1,1-60-60A60,60,0,0,1,168,100Z"
            opacity="0.2"
          />
          <path d="M144.1,157.6a68,68,0,1,0-72.2,0,118.4,118.4,0,0,0-55.8,37.3,8,8,0,0,0,12.2,10.2,104.2,104.2,0,0,1,159.4,0,7.8,7.8,0,0,0,11.2,1,7.9,7.9,0,0,0,1-11.2A118.4,118.4,0,0,0,144.1,157.6ZM56,100a52,52,0,1,1,52,52A52,52,0,0,1,56,100Zm192.2,43.1-4.6-2.7a24.4,24.4,0,0,0,0-8.8l4.6-2.7a7.9,7.9,0,0,0,3-10.9,8.1,8.1,0,0,0-11-2.9l-4.6,2.7a24,24,0,0,0-7.6-4.4V108a8,8,0,0,0-16,0v5.4a24,24,0,0,0-7.6,4.4l-4.6-2.7a8.1,8.1,0,0,0-11,2.9,7.9,7.9,0,0,0,3,10.9l4.6,2.7a24.4,24.4,0,0,0,0,8.8l-4.6,2.7a8,8,0,0,0,4,14.9,7.6,7.6,0,0,0,4-1.1l4.6-2.7a24,24,0,0,0,7.6,4.4V164a8,8,0,0,0,16,0v-5.4a24,24,0,0,0,7.6-4.4l4.6,2.7a7.6,7.6,0,0,0,4,1.1,8,8,0,0,0,4-14.9ZM212,136a8,8,0,1,1,8,8A8,8,0,0,1,212,136Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M199.9,194.9a7.8,7.8,0,0,1,1.1,8.5,7.9,7.9,0,0,1-7.2,4.6H22.2a7.9,7.9,0,0,1-7.2-4.6,7.8,7.8,0,0,1,1.1-8.5,118.4,118.4,0,0,1,55.8-37.3,68,68,0,1,1,72.2,0A118.4,118.4,0,0,1,199.9,194.9ZM251.2,154a8,8,0,0,1-7,4,7.6,7.6,0,0,1-4-1.1l-4.6-2.7a24,24,0,0,1-7.6,4.4V164a8,8,0,0,1-16,0v-5.4a24,24,0,0,1-7.6-4.4l-4.6,2.7a7.6,7.6,0,0,1-4,1.1,8,8,0,0,1-4-14.9l4.6-2.7a24.4,24.4,0,0,1,0-8.8l-4.6-2.7a7.9,7.9,0,0,1-3-10.9,8.1,8.1,0,0,1,11-2.9l4.6,2.7a24,24,0,0,1,7.6-4.4V108a8,8,0,0,1,16,0v5.4a24,24,0,0,1,7.6,4.4l4.6-2.7a8.1,8.1,0,0,1,11,2.9,7.9,7.9,0,0,1-3,10.9l-4.6,2.7a24.4,24.4,0,0,1,0,8.8l4.6,2.7A7.9,7.9,0,0,1,251.2,154ZM220,144a8,8,0,1,0-8-8A8,8,0,0,0,220,144Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M139.1,158.2a66,66,0,1,0-62.2,0,117.9,117.9,0,0,0-59.3,37.9,6,6,0,0,0,9.2,7.8,105.9,105.9,0,0,1,162.4,0,6,6,0,0,0,4.6,2.1,6.4,6.4,0,0,0,3.9-1.4,6.1,6.1,0,0,0,.7-8.5A117.9,117.9,0,0,0,139.1,158.2ZM54,100a54,54,0,1,1,54,54A54,54,0,0,1,54,100Zm193.2,44.8-5.9-3.4a21.2,21.2,0,0,0,0-10.8l5.9-3.4a6,6,0,0,0-6-10.4l-5.9,3.4a21.8,21.8,0,0,0-9.3-5.4V108a6,6,0,0,0-12,0v6.8a21.8,21.8,0,0,0-9.3,5.4l-5.9-3.4a6,6,0,1,0-6,10.4l5.9,3.4a21.2,21.2,0,0,0,0,10.8l-5.9,3.4a6,6,0,0,0,3,11.2,6.7,6.7,0,0,0,3-.8l5.9-3.4a21.8,21.8,0,0,0,9.3,5.4V164a6,6,0,0,0,12,0v-6.8a21.8,21.8,0,0,0,9.3-5.4l5.9,3.4a6.7,6.7,0,0,0,3,.8,6,6,0,0,0,3-11.2ZM220,146a10,10,0,1,1,10-10A10,10,0,0,1,220,146Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M133.3,158.8a64,64,0,1,0-50.6,0,116.3,116.3,0,0,0-63.6,38.6,4,4,0,0,0,6.2,5.2,107.9,107.9,0,0,1,165.4,0,4.3,4.3,0,0,0,3.1,1.4,4.3,4.3,0,0,0,2.6-.9,4.1,4.1,0,0,0,.5-5.7A116.3,116.3,0,0,0,133.3,158.8ZM52,100a56,56,0,1,1,56,56A56,56,0,0,1,52,100Zm194.2,46.5-7.2-4.2a20.3,20.3,0,0,0,0-12.6l7.2-4.2a4,4,0,0,0,1.5-5.5,4,4,0,0,0-5.5-1.5l-7.3,4.3a19.9,19.9,0,0,0-10.9-6.4V108a4,4,0,0,0-8,0v8.4a19.9,19.9,0,0,0-10.9,6.4l-7.3-4.3a4,4,0,0,0-5.5,1.5,4,4,0,0,0,1.5,5.5l7.2,4.2a20.3,20.3,0,0,0,0,12.6l-7.2,4.2a4,4,0,0,0,2,7.5,5.3,5.3,0,0,0,2-.5l7.3-4.3a19.9,19.9,0,0,0,10.9,6.4V164a4,4,0,0,0,8,0v-8.4a19.9,19.9,0,0,0,10.9-6.4l7.3,4.3a5.3,5.3,0,0,0,2,.5,4,4,0,0,0,2-7.5ZM220,148a12,12,0,1,1,12-12A12,12,0,0,1,220,148Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M144.1,157.6a68,68,0,1,0-72.2,0,118.4,118.4,0,0,0-55.8,37.3,8,8,0,0,0,12.2,10.2,104.2,104.2,0,0,1,159.4,0,7.8,7.8,0,0,0,11.2,1,7.9,7.9,0,0,0,1-11.2A118.4,118.4,0,0,0,144.1,157.6ZM56,100a52,52,0,1,1,52,52A52,52,0,0,1,56,100Zm192.2,43.1-4.6-2.7a24.4,24.4,0,0,0,0-8.8l4.6-2.7a7.9,7.9,0,0,0,3-10.9,8.1,8.1,0,0,0-11-2.9l-4.6,2.7a24,24,0,0,0-7.6-4.4V108a8,8,0,0,0-16,0v5.4a24,24,0,0,0-7.6,4.4l-4.6-2.7a8.1,8.1,0,0,0-11,2.9,7.9,7.9,0,0,0,3,10.9l4.6,2.7a24.4,24.4,0,0,0,0,8.8l-4.6,2.7a8,8,0,0,0,4,14.9,7.6,7.6,0,0,0,4-1.1l4.6-2.7a24,24,0,0,0,7.6,4.4V164a8,8,0,0,0,16,0v-5.4a24,24,0,0,0,7.6-4.4l4.6,2.7a7.6,7.6,0,0,0,4,1.1,8,8,0,0,0,4-14.9ZM212,136a8,8,0,1,1,8,8A8,8,0,0,1,212,136Z" />
        </>
      )}
    </svg>
  );
}

export function PhUserList(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M140,80a12,12,0,0,1,12-12h92a12,12,0,0,1,0,24H152A12,12,0,0,1,140,80Zm104,36H152a12,12,0,0,0,0,24h92a12,12,0,0,0,0-24Zm0,48H176a12,12,0,0,0,0,24h68a12,12,0,0,0,0-24Zm-90.4,25a11.9,11.9,0,0,1-8.6,14.6,10.3,10.3,0,0,1-3,.4,12,12,0,0,1-11.6-9,52.1,52.1,0,0,0-100.8,0,12,12,0,0,1-23.2-6,75.7,75.7,0,0,1,37.3-47.8,52,52,0,1,1,72.6,0A75.7,75.7,0,0,1,153.6,189ZM80,132a28,28,0,1,0-28-28A28.1,28.1,0,0,0,80,132Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M80,144a40,40,0,1,1,40-40A40,40,0,0,1,80,144Z"
            opacity="0.2"
          />
          <path d="M144,80a8,8,0,0,1,8-8h96a8,8,0,0,1,0,16H152A8,8,0,0,1,144,80Zm104,40H152a8,8,0,0,0,0,16h96a8,8,0,0,0,0-16Zm0,48H176a8,8,0,0,0,0,16h72a8,8,0,0,0,0-16Zm-98.3,22a7.9,7.9,0,0,1-5.7,9.7l-2,.3a8.1,8.1,0,0,1-7.8-6,56,56,0,0,0-108.4,0,8,8,0,0,1-9.8,5.7,7.9,7.9,0,0,1-5.7-9.7,72,72,0,0,1,40.6-47.9,48,48,0,1,1,58.2,0A72,72,0,0,1,149.7,190ZM80,136a32,32,0,1,0-32-32A32.1,32.1,0,0,0,80,136Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M149.7,190a8.1,8.1,0,0,1-1.4,6.9A7.8,7.8,0,0,1,142,200H18a7.8,7.8,0,0,1-6.3-3.1,8.1,8.1,0,0,1-1.4-6.9,72,72,0,0,1,40.6-47.9,48,48,0,1,1,58.2,0A72,72,0,0,1,149.7,190ZM152,88h96a8,8,0,0,0,0-16H152a8,8,0,0,0,0,16Zm96,80H176a8,8,0,0,0,0,16h72a8,8,0,0,0,0-16Zm0-48H152a8,8,0,0,0,0,16h96a8,8,0,0,0,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M146,80a6,6,0,0,1,6-6h96a6,6,0,0,1,0,12H152A6,6,0,0,1,146,80Zm102,42H152a6,6,0,0,0,0,12h96a6,6,0,0,0,0-12Zm0,48H176a6,6,0,0,0,0,12h72a6,6,0,0,0,0-12ZM147.8,190.5a6,6,0,0,1-4.3,7.3l-1.5.2a6,6,0,0,1-5.8-4.5,58.1,58.1,0,0,0-112.4,0,6,6,0,0,1-11.6-3A69.9,69.9,0,0,1,55,142.6a46,46,0,1,1,50,0A69.9,69.9,0,0,1,147.8,190.5ZM80,138a34,34,0,1,0-34-34A34.1,34.1,0,0,0,80,138Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M148,80a4,4,0,0,1,4-4h96a4,4,0,0,1,0,8H152A4,4,0,0,1,148,80Zm100,44H152a4,4,0,0,0,0,8h96a4,4,0,0,0,0-8Zm0,48H176a4,4,0,0,0,0,8h72a4,4,0,0,0,0-8ZM145.9,191a4.2,4.2,0,0,1-2.9,4.9h-1a4.1,4.1,0,0,1-3.9-3,60,60,0,0,0-116.2,0,4.1,4.1,0,0,1-4.9,2.9,4.2,4.2,0,0,1-2.9-4.9,67.7,67.7,0,0,1,45.7-47.9,44,44,0,1,1,40.4,0A67.7,67.7,0,0,1,145.9,191ZM80,140a36,36,0,1,0-36-36A36,36,0,0,0,80,140Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M144,80a8,8,0,0,1,8-8h96a8,8,0,0,1,0,16H152A8,8,0,0,1,144,80Zm104,40H152a8,8,0,0,0,0,16h96a8,8,0,0,0,0-16Zm0,48H176a8,8,0,0,0,0,16h72a8,8,0,0,0,0-16Zm-98.3,22a7.9,7.9,0,0,1-5.7,9.7l-2,.3a8.1,8.1,0,0,1-7.8-6,56,56,0,0,0-108.4,0,8,8,0,0,1-9.8,5.7,7.9,7.9,0,0,1-5.7-9.7,72,72,0,0,1,40.6-47.9,48,48,0,1,1,58.2,0A72,72,0,0,1,149.7,190ZM80,136a32,32,0,1,0-32-32A32.1,32.1,0,0,0,80,136Z" />
        </>
      )}
    </svg>
  );
}

export function PhUserMinus(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M256,136a12,12,0,0,1-12,12H204a12,12,0,0,1,0-24h40A12,12,0,0,1,256,136Zm-53,56.3a12,12,0,0,1-1.5,16.9,11.8,11.8,0,0,1-7.7,2.8,12.2,12.2,0,0,1-9.2-4.3,100,100,0,0,0-153.2,0A12,12,0,1,1,13,192.3a124.4,124.4,0,0,1,50.3-36,72,72,0,1,1,89.4,0A124.4,124.4,0,0,1,203,192.3ZM108,148a48,48,0,1,0-48-48A48,48,0,0,0,108,148Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M168,100a60,60,0,1,1-60-60A60,60,0,0,1,168,100Z"
            opacity="0.2"
          />
          <path d="M256,136a8,8,0,0,1-8,8H200a8,8,0,0,1,0-16h48A8,8,0,0,1,256,136Zm-56.1,58.9a7.9,7.9,0,0,1-1,11.2,7.8,7.8,0,0,1-11.2-1,104.2,104.2,0,0,0-159.4,0,8,8,0,0,1-12.2-10.2,118.4,118.4,0,0,1,55.8-37.3,68,68,0,1,1,72.2,0A118.4,118.4,0,0,1,199.9,194.9ZM108,152a52,52,0,1,0-52-52A52,52,0,0,0,108,152Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M256,136a8,8,0,0,1-8,8H200a8,8,0,0,1,0-16h48A8,8,0,0,1,256,136ZM144.1,157.6a68,68,0,1,0-72.2,0,118.4,118.4,0,0,0-55.8,37.3,7.8,7.8,0,0,0-1.1,8.5,7.9,7.9,0,0,0,7.2,4.6H193.8a7.9,7.9,0,0,0,7.2-4.6,7.8,7.8,0,0,0-1.1-8.5A118.4,118.4,0,0,0,144.1,157.6Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M254,136a6,6,0,0,1-6,6H200a6,6,0,0,1,0-12h48A6,6,0,0,1,254,136Zm-55.6,60.1a6.1,6.1,0,0,1-.7,8.5,6.4,6.4,0,0,1-3.9,1.4,6,6,0,0,1-4.6-2.1,105.9,105.9,0,0,0-162.4,0,6,6,0,0,1-9.2-7.8,117.9,117.9,0,0,1,59.3-37.9,66,66,0,1,1,62.2,0A117.9,117.9,0,0,1,198.4,196.1ZM108,154a54,54,0,1,0-54-54A54,54,0,0,0,108,154Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M252,136a4,4,0,0,1-4,4H200a4,4,0,0,1,0-8h48A4,4,0,0,1,252,136Zm-55.1,61.4a4.1,4.1,0,0,1-.5,5.7,4.3,4.3,0,0,1-2.6.9,4.3,4.3,0,0,1-3.1-1.4,107.9,107.9,0,0,0-165.4,0,4,4,0,0,1-6.2-5.2,116.3,116.3,0,0,1,63.6-38.6,64,64,0,1,1,50.6,0A116.3,116.3,0,0,1,196.9,197.4ZM108,156a56,56,0,1,0-56-56A56,56,0,0,0,108,156Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M256,136a8,8,0,0,1-8,8H200a8,8,0,0,1,0-16h48A8,8,0,0,1,256,136Zm-56.1,58.9a7.9,7.9,0,0,1-1,11.2,7.8,7.8,0,0,1-11.2-1,104.2,104.2,0,0,0-159.4,0,8,8,0,0,1-12.2-10.2,118.4,118.4,0,0,1,55.8-37.3,68,68,0,1,1,72.2,0A118.4,118.4,0,0,1,199.9,194.9ZM108,152a52,52,0,1,0-52-52A52,52,0,0,0,108,152Z" />
        </>
      )}
    </svg>
  );
}

export function PhUserPlus(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M256,136a12,12,0,0,1-12,12h-8v8a12,12,0,0,1-24,0v-8h-8a12,12,0,0,1,0-24h8v-8a12,12,0,0,1,24,0v8h8A12,12,0,0,1,256,136Zm-53,56.3a12,12,0,0,1-1.5,16.9,11.8,11.8,0,0,1-7.7,2.8,12.2,12.2,0,0,1-9.2-4.3,100,100,0,0,0-153.2,0A12,12,0,1,1,13,192.3a124.4,124.4,0,0,1,50.3-36,72,72,0,1,1,89.4,0A124.4,124.4,0,0,1,203,192.3ZM108,148a48,48,0,1,0-48-48A48,48,0,0,0,108,148Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M168,100a60,60,0,1,1-60-60A60,60,0,0,1,168,100Z"
            opacity="0.2"
          />
          <path d="M256,136a8,8,0,0,1-8,8H232v16a8,8,0,0,1-16,0V144H200a8,8,0,0,1,0-16h16V112a8,8,0,0,1,16,0v16h16A8,8,0,0,1,256,136Zm-56.1,58.9a7.9,7.9,0,0,1-1,11.2,7.8,7.8,0,0,1-11.2-1,104.2,104.2,0,0,0-159.4,0,8,8,0,0,1-12.2-10.2,118.4,118.4,0,0,1,55.8-37.3,68,68,0,1,1,72.2,0A118.4,118.4,0,0,1,199.9,194.9ZM108,152a52,52,0,1,0-52-52A52,52,0,0,0,108,152Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M256,136a8,8,0,0,1-8,8H232v16a8,8,0,0,1-16,0V144H200a8,8,0,0,1,0-16h16V112a8,8,0,0,1,16,0v16h16A8,8,0,0,1,256,136ZM144.1,157.6a68,68,0,1,0-72.2,0,118.4,118.4,0,0,0-55.8,37.3,7.8,7.8,0,0,0-1.1,8.5,7.9,7.9,0,0,0,7.2,4.6H193.8a7.9,7.9,0,0,0,7.2-4.6,7.8,7.8,0,0,0-1.1-8.5A118.4,118.4,0,0,0,144.1,157.6Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M254,136a6,6,0,0,1-6,6H230v18a6,6,0,0,1-12,0V142H200a6,6,0,0,1,0-12h18V112a6,6,0,0,1,12,0v18h18A6,6,0,0,1,254,136Zm-55.6,60.1a6.1,6.1,0,0,1-.7,8.5,6.4,6.4,0,0,1-3.9,1.4,6,6,0,0,1-4.6-2.1,105.9,105.9,0,0,0-162.4,0,6,6,0,0,1-9.2-7.8,117.9,117.9,0,0,1,59.3-37.9,66,66,0,1,1,62.2,0A117.9,117.9,0,0,1,198.4,196.1ZM108,154a54,54,0,1,0-54-54A54,54,0,0,0,108,154Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M252,136a4,4,0,0,1-4,4H228v20a4,4,0,0,1-8,0V140H200a4,4,0,0,1,0-8h20V112a4,4,0,0,1,8,0v20h20A4,4,0,0,1,252,136Zm-55.1,61.4a4.1,4.1,0,0,1-.5,5.7,4.3,4.3,0,0,1-2.6.9,4.3,4.3,0,0,1-3.1-1.4,107.9,107.9,0,0,0-165.4,0,4,4,0,0,1-6.2-5.2,116.3,116.3,0,0,1,63.6-38.6,64,64,0,1,1,50.6,0A116.3,116.3,0,0,1,196.9,197.4ZM108,156a56,56,0,1,0-56-56A56,56,0,0,0,108,156Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M256,136a8,8,0,0,1-8,8H232v16a8,8,0,0,1-16,0V144H200a8,8,0,0,1,0-16h16V112a8,8,0,0,1,16,0v16h16A8,8,0,0,1,256,136Zm-56.1,58.9a7.9,7.9,0,0,1-1,11.2,7.8,7.8,0,0,1-11.2-1,104.2,104.2,0,0,0-159.4,0,8,8,0,0,1-12.2-10.2,118.4,118.4,0,0,1,55.8-37.3,68,68,0,1,1,72.2,0A118.4,118.4,0,0,1,199.9,194.9ZM108,152a52,52,0,1,0-52-52A52,52,0,0,0,108,152Z" />
        </>
      )}
    </svg>
  );
}

export function PhUserRectangle(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216,36H40A20.1,20.1,0,0,0,20,56V200a20.1,20.1,0,0,0,20,20H216a20.1,20.1,0,0,0,20-20V56A20.1,20.1,0,0,0,216,36ZM100,128a28,28,0,1,1,28,28A28.1,28.1,0,0,1,100,128Zm28,52a60.1,60.1,0,0,1,40.9,16H87.1A60.1,60.1,0,0,1,128,180Zm84,16H199.6A83.9,83.9,0,0,0,165,164.5a52,52,0,1,0-74,0A83.9,83.9,0,0,0,56.4,196H44V60H212Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M216,48H40a8,8,0,0,0-8,8V200a8,8,0,0,0,8,8H60.1A72,72,0,0,1,128,160a40,40,0,1,1,40-40,40,40,0,0,1-40,40,72,72,0,0,1,67.9,48H216a8,8,0,0,0,8-8V56A8,8,0,0,0,216,48Z" />
          </g>
          <path d="M216,40H40A16,16,0,0,0,24,56V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40ZM96,120a32,32,0,1,1,32,32A32.1,32.1,0,0,1,96,120ZM72.6,200a64,64,0,0,1,110.8,0ZM216,200H201.3a80.1,80.1,0,0,0-43.7-42.3,48,48,0,1,0-59.2,0A80.1,80.1,0,0,0,54.7,200H40V56H216V200Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M172,120a44,44,0,1,1-44-44A44,44,0,0,1,172,120Zm60-64V200a16,16,0,0,1-16,16H40a16,16,0,0,1-16-16V56A16,16,0,0,1,40,40H216A16,16,0,0,1,232,56ZM216,200V56H40V200H54.7a80.2,80.2,0,0,1,32-36.5,59.9,59.9,0,0,0,82.6,0,80.2,80.2,0,0,1,32,36.5Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M216,42H40A14,14,0,0,0,26,56V200a14,14,0,0,0,14,14H216a14,14,0,0,0,14-14V56A14,14,0,0,0,216,42ZM94,120a34,34,0,1,1,34,34A34.1,34.1,0,0,1,94,120ZM69.2,202a66,66,0,0,1,117.6,0ZM218,200a2,2,0,0,1-2,2H200a78.3,78.3,0,0,0-46.5-43.7,46,46,0,1,0-51,0A78.3,78.3,0,0,0,56,202H40a2,2,0,0,1-2-2V56a2,2,0,0,1,2-2H216a2,2,0,0,1,2,2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M216,44H40A12,12,0,0,0,28,56V200a12,12,0,0,0,12,12H216a12,12,0,0,0,12-12V56A12,12,0,0,0,216,44ZM66,204a68,68,0,0,1,124,0Zm154-4a4,4,0,0,1-4,4H198.7a76.1,76.1,0,0,0-50.1-45.1,44,44,0,1,0-41.2,0A76.1,76.1,0,0,0,57.3,204H40a4,4,0,0,1-4-4V56a4,4,0,0,1,4-4H216a4,4,0,0,1,4,4Zm-92-44a36,36,0,1,1,36-36A36,36,0,0,1,128,156Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,40H40A16,16,0,0,0,24,56V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40ZM96,120a32,32,0,1,1,32,32A32.1,32.1,0,0,1,96,120ZM72.6,200a64,64,0,0,1,110.8,0ZM216,200H201.3a80.1,80.1,0,0,0-43.7-42.3,48,48,0,1,0-59.2,0A80.1,80.1,0,0,0,54.7,200H40V56H216V200Z" />
        </>
      )}
    </svg>
  );
}

export function PhUserSquare(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M208,28H48A20.1,20.1,0,0,0,28,48V208a20.1,20.1,0,0,0,20,20H208a20.1,20.1,0,0,0,20-20V48A20.1,20.1,0,0,0,208,28Zm-4,24V196.2a82.4,82.4,0,0,0-39.1-39.6,52,52,0,1,0-73.8,0A82.4,82.4,0,0,0,52,196.2V52ZM100,120a28,28,0,1,1,28,28A28.1,28.1,0,0,1,100,120Zm28,52a59.4,59.4,0,0,1,37.7,13.3A61.7,61.7,0,0,1,181.1,204H74.9a61.7,61.7,0,0,1,15.4-18.7A59.4,59.4,0,0,1,128,172Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M208,40H48a8,8,0,0,0-8,8V208a8,8,0,0,0,8,8H208a8,8,0,0,0,8-8V48A8,8,0,0,0,208,40ZM57.8,216A72,72,0,0,1,128,160a40,40,0,1,1,40-40,40,40,0,0,1-40,40,72,72,0,0,1,70.2,56Z" />
          </g>
          <path d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM96,120a32,32,0,1,1,32,32A32.1,32.1,0,0,1,96,120ZM68.7,208a63.9,63.9,0,0,1,19.1-25.8,64,64,0,0,1,80.4,0A63.9,63.9,0,0,1,187.3,208ZM208,208h-3.7a79.9,79.9,0,0,0-46.7-50.3,48,48,0,1,0-59.2,0A79.9,79.9,0,0,0,51.7,208H48V48H208V208Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M172,120a44,44,0,1,1-44-44A44,44,0,0,1,172,120Zm52-72V208a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V48A16,16,0,0,1,48,32H208A16,16,0,0,1,224,48ZM208,208V48H48V208h3.7a80.7,80.7,0,0,1,26-38.2,76.8,76.8,0,0,1,9-6.3,59.9,59.9,0,0,0,82.6,0,76.8,76.8,0,0,1,9,6.3,80.7,80.7,0,0,1,26,38.2Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M208,34H48A14,14,0,0,0,34,48V208a14,14,0,0,0,14,14H208a14,14,0,0,0,14-14V48A14,14,0,0,0,208,34ZM94,120a34,34,0,1,1,34,34A34.1,34.1,0,0,1,94,120ZM65.8,210a65.9,65.9,0,0,1,124.4,0ZM210,208a2,2,0,0,1-2,2h-5.2A78.5,78.5,0,0,0,177,171.3a77.5,77.5,0,0,0-23.5-13,46,46,0,1,0-51,0,77.5,77.5,0,0,0-23.5,13A78.5,78.5,0,0,0,53.2,210H48a2,2,0,0,1-2-2V48a2,2,0,0,1,2-2H208a2,2,0,0,1,2,2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M208,36H48A12,12,0,0,0,36,48V208a12,12,0,0,0,12,12H208a12,12,0,0,0,12-12V48A12,12,0,0,0,208,36ZM63,212a68,68,0,0,1,130,0Zm149-4a4,4,0,0,1-4,4h-6.7a76,76,0,0,0-52.7-53.1,44,44,0,1,0-41.2,0A76,76,0,0,0,54.7,212H48a4,4,0,0,1-4-4V48a4,4,0,0,1,4-4H208a4,4,0,0,1,4,4Zm-84-52a36,36,0,1,1,36-36A36,36,0,0,1,128,156Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM96,120a32,32,0,1,1,32,32A32.1,32.1,0,0,1,96,120ZM68.7,208a63.9,63.9,0,0,1,19.1-25.8,64,64,0,0,1,80.4,0A63.9,63.9,0,0,1,187.3,208ZM208,208h-3.7a79.9,79.9,0,0,0-46.7-50.3,48,48,0,1,0-59.2,0A79.9,79.9,0,0,0,51.7,208H48V48H208V208Z" />
        </>
      )}
    </svg>
  );
}

export function PhUserSwitch(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M252.5,140.5l-20,20a12.1,12.1,0,0,1-17,0l-20-20a12,12,0,0,1,16.3-17.6A84,84,0,0,0,61.5,76.7,12,12,0,0,1,42.5,62a108,108,0,0,1,193.4,61.2,12,12,0,0,1,16.6,17.3Zm-39,53.5A108,108,0,0,1,20.1,132.8,12,12,0,0,1,3.5,115.5l20-20a12,12,0,0,1,17,0l20,20a12,12,0,0,1,0,17A12.2,12.2,0,0,1,52,136a12,12,0,0,1-7.8-2.9,82.2,82.2,0,0,0,17.9,46.8A84,84,0,0,1,91,156.5a52,52,0,1,1,74,0A84.4,84.4,0,0,1,194,180l.5-.7a12,12,0,0,1,19,14.7ZM128,148a28,28,0,1,0-28-28A28.1,28.1,0,0,0,128,148Zm0,64a83,83,0,0,0,48.5-15.4,60,60,0,0,0-96.9,0A83.5,83.5,0,0,0,128,212Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M224,128a96,96,0,0,1-31.8,71.4A72,72,0,0,0,128,160a40,40,0,1,0-40-40,40,40,0,0,0,40,40,72,72,0,0,0-64.2,39.4h0A96,96,0,1,1,224,128Z"
            opacity="0.2"
          />
          <path d="M253.7,133.7l-24,24a8.2,8.2,0,0,1-11.4,0l-24-24a8.1,8.1,0,0,1,11.4-11.4L216,132.7V128A88,88,0,0,0,54.1,80.3,8,8,0,0,1,43,82.6a7.9,7.9,0,0,1-2.4-11A104,104,0,0,1,232,128v4.7l10.3-10.4a8.1,8.1,0,0,1,11.4,11.4Zm-38.3,50.7A104,104,0,0,1,24,128v-4.7L13.7,133.7a8.1,8.1,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4l24-24a8.1,8.1,0,0,1,11.4,0l24,24a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0L40,123.3V128a87.6,87.6,0,0,0,22.2,58.4,79.2,79.2,0,0,1,36.1-28.7,48,48,0,1,1,59.4,0,79.4,79.4,0,0,1,36.1,28.8,86.9,86.9,0,0,0,8.1-10.8,8,8,0,0,1,11.1-2.3A7.9,7.9,0,0,1,215.4,184.4ZM128,152a32,32,0,1,0-32-32A32.1,32.1,0,0,0,128,152Zm0,64a88.1,88.1,0,0,0,54-18.4,64,64,0,0,0-107.9-.1A87.4,87.4,0,0,0,128,216Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M253.7,133.7l-24,24a8.2,8.2,0,0,1-11.4,0l-24-24a8.4,8.4,0,0,1-1.7-8.8A8,8,0,0,1,200,120h15.6A87.9,87.9,0,0,0,54.1,80.3,8,8,0,0,1,43,82.6a7.9,7.9,0,0,1-2.4-11A104,104,0,0,1,231.7,120H248a8,8,0,0,1,7.4,4.9A8.4,8.4,0,0,1,253.7,133.7ZM213,173.4a8,8,0,0,0-11.1,2.3,86.9,86.9,0,0,1-8.1,10.8h0a81.3,81.3,0,0,0-24.5-23,59.7,59.7,0,0,1-82.6,0,81.3,81.3,0,0,0-24.5,23h0A87.6,87.6,0,0,1,40.4,136H56a8,8,0,0,0,7.4-4.9,8.4,8.4,0,0,0-1.7-8.8l-24-24a8.1,8.1,0,0,0-11.4,0l-24,24a8.4,8.4,0,0,0-1.7,8.8A8,8,0,0,0,8,136H24.3a103.7,103.7,0,0,0,34.5,69.6h0l.4.3a103.9,103.9,0,0,0,156.2-21.5A7.9,7.9,0,0,0,213,173.4ZM128,164a44,44,0,1,0-44-44A44,44,0,0,0,128,164Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M252.2,132.2l-24,24a5.8,5.8,0,0,1-8.4,0l-24-24a5.9,5.9,0,0,1,8.4-8.4L218,137.5V128A90,90,0,0,0,52.4,79.2a6,6,0,0,1-8.3,1.7,5.9,5.9,0,0,1-1.8-8.2A102,102,0,0,1,230,128v9.5l13.8-13.7a5.9,5.9,0,0,1,8.4,8.4Zm-38.5,51.1A102,102,0,0,1,26,128v-9.5L12.2,132.2a5.8,5.8,0,0,1-8.4,0,5.8,5.8,0,0,1,0-8.4l24-24a5.8,5.8,0,0,1,8.4,0l24,24a5.9,5.9,0,1,1-8.4,8.4L38,118.5V128a89.5,89.5,0,0,0,24.5,61.6,77.7,77.7,0,0,1,40-31.3,46,46,0,1,1,51,0,77,77,0,0,1,40,31.4,85.9,85.9,0,0,0,10.1-12.9,6,6,0,0,1,8.3-1.7A5.9,5.9,0,0,1,213.7,183.3ZM128,154a34,34,0,1,0-34-34A34.1,34.1,0,0,0,128,154Zm0,64a89.6,89.6,0,0,0,56.6-20,66.1,66.1,0,0,0-113.2,0A89.9,89.9,0,0,0,128,218Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M250.8,130.8l-24,24a3.9,3.9,0,0,1-5.6,0l-24-24a4,4,0,1,1,5.6-5.6L220,142.3V128A92,92,0,0,0,50.7,78.1,4,4,0,1,1,44,73.7,100,100,0,0,1,228,128v14.3l17.2-17.1a4,4,0,1,1,5.6,5.6ZM212,182.3A100,100,0,0,1,28,128V113.7L10.8,130.8a3.9,3.9,0,0,1-5.6,0,3.9,3.9,0,0,1,0-5.6l24-24a3.9,3.9,0,0,1,5.6,0l24,24a3.9,3.9,0,0,1,0,5.6,3.9,3.9,0,0,1-5.6,0L36,113.7V128a92.1,92.1,0,0,0,26.8,64.9,75.8,75.8,0,0,1,44.5-34.1,44,44,0,1,1,41.4,0,76.2,76.2,0,0,1,44.5,34.1,93.4,93.4,0,0,0,12.1-15,4,4,0,1,1,6.7,4.4ZM128,156a36,36,0,1,0-36-36A36,36,0,0,0,128,156Zm0,64a92.2,92.2,0,0,0,59.2-21.5,68,68,0,0,0-118.3-.1A91.3,91.3,0,0,0,128,220Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M253.7,133.7l-24,24a8.2,8.2,0,0,1-11.4,0l-24-24a8.1,8.1,0,0,1,11.4-11.4L216,132.7V128A88,88,0,0,0,54.1,80.3,8,8,0,0,1,43,82.6a7.9,7.9,0,0,1-2.4-11A104,104,0,0,1,232,128v4.7l10.3-10.4a8.1,8.1,0,0,1,11.4,11.4Zm-38.3,50.7A104,104,0,0,1,24,128v-4.7L13.7,133.7a8.1,8.1,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4l24-24a8.1,8.1,0,0,1,11.4,0l24,24a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0L40,123.3V128a87.6,87.6,0,0,0,22.2,58.4,79.2,79.2,0,0,1,36.1-28.7,48,48,0,1,1,59.4,0,79.4,79.4,0,0,1,36.1,28.8,86.9,86.9,0,0,0,8.1-10.8,8,8,0,0,1,11.1-2.3A7.9,7.9,0,0,1,215.4,184.4ZM128,152a32,32,0,1,0-32-32A32.1,32.1,0,0,0,128,152Zm0,64a88.1,88.1,0,0,0,54-18.4,64,64,0,0,0-107.9-.1A87.4,87.4,0,0,0,128,216Z" />
        </>
      )}
    </svg>
  );
}

export function PhUser(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M235.4,210a124.2,124.2,0,0,0-61.8-53.2,76,76,0,1,0-91.2,0A124.2,124.2,0,0,0,20.6,210a12,12,0,0,0,20.8,12,100,100,0,0,1,173.2,0,12.1,12.1,0,0,0,10.4,6,11.7,11.7,0,0,0,6-1.6A12,12,0,0,0,235.4,210ZM76,96a52,52,0,1,1,52,52A52,52,0,0,1,76,96Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="128" cy="96" r="64" />
          </g>
          <path d="M231.9,212a120.7,120.7,0,0,0-67.1-54.2,72,72,0,1,0-73.6,0A120.7,120.7,0,0,0,24.1,212a8,8,0,1,0,13.8,8,104.1,104.1,0,0,1,180.2,0,8,8,0,1,0,13.8-8ZM72,96a56,56,0,1,1,56,56A56,56,0,0,1,72,96Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M231.9,220a7.8,7.8,0,0,1-6.9,4H31a7.8,7.8,0,0,1-6.9-4,7.7,7.7,0,0,1,0-8,120.7,120.7,0,0,1,67.1-54.2,72,72,0,1,1,73.6,0A120.7,120.7,0,0,1,231.9,212,7.7,7.7,0,0,1,231.9,220Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M230.2,213a118.3,118.3,0,0,0-70.5-54.6,70,70,0,1,0-63.4,0A118.3,118.3,0,0,0,25.8,213a5.9,5.9,0,0,0,2.2,8.2,6,6,0,0,0,8.2-2.2,106,106,0,0,1,183.6,0,6,6,0,0,0,5.2,3,6.4,6.4,0,0,0,3-.8A5.9,5.9,0,0,0,230.2,213ZM70,96a58,58,0,1,1,58,58A58,58,0,0,1,70,96Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M228.5,214a116.5,116.5,0,0,0-74.8-55.1,68,68,0,1,0-51.4,0A116.5,116.5,0,0,0,27.5,214a4,4,0,0,0,7,4,107.9,107.9,0,0,1,187,0,4.1,4.1,0,0,0,3.5,2,4.6,4.6,0,0,0,2-.5A4.1,4.1,0,0,0,228.5,214ZM68,96a60,60,0,1,1,60,60A60,60,0,0,1,68,96Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M231.9,212a120.7,120.7,0,0,0-67.1-54.2,72,72,0,1,0-73.6,0A120.7,120.7,0,0,0,24.1,212a8,8,0,1,0,13.8,8,104.1,104.1,0,0,1,180.2,0,8,8,0,1,0,13.8-8ZM72,96a56,56,0,1,1,56,56A56,56,0,0,1,72,96Z" />
        </>
      )}
    </svg>
  );
}

export function PhUsersFour(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M24.8,125.6A12,12,0,0,0,32,128a12.2,12.2,0,0,0,9.6-4.8,48,48,0,0,1,76.8,0h.1l.7.8c0,.1.1.1.2.2l.6.6.4.4.4.3a.1.1,0,0,1,.1.1l.3.2.6.4.3.2.8.3c0,.1.1.1.2.1l.9.4h.1a7.9,7.9,0,0,0,2.2.6h3.2l.9-.2h.4a11.7,11.7,0,0,0,2.4-.9l.4-.2.7-.4.5-.3.4-.3.2-.2.4-.3.7-.6.3-.3.7-.9h.1a48,48,0,0,1,76.8,0,12,12,0,0,0,19.2-14.4,72.4,72.4,0,0,0-25.3-21.2,40,40,0,1,0-64.6,0A71.5,71.5,0,0,0,128,98.3a71.5,71.5,0,0,0-15.7-10.7,40,40,0,1,0-64.6,0,72.4,72.4,0,0,0-25.3,21.2A12,12,0,0,0,24.8,125.6ZM160,64a16,16,0,1,1,16,16A16,16,0,0,1,160,64ZM64,64A16,16,0,1,1,80,80,16,16,0,0,1,64,64ZM208.3,195.6a40,40,0,1,0-64.6,0A71.5,71.5,0,0,0,128,206.3a71.5,71.5,0,0,0-15.7-10.7,40,40,0,1,0-64.6,0,72.4,72.4,0,0,0-25.3,21.2A12,12,0,0,0,32,236a12.2,12.2,0,0,0,9.6-4.8,48,48,0,0,1,76.8,0h.1l.7.8a.2.2,0,0,0,.2.2l.6.6.4.4.4.3h.1l.3.2.6.4.3.2.8.3.2.2.9.3h.1a7.9,7.9,0,0,0,2.2.6h3.2l.9-.2h.4l2.4-.9.4-.2.7-.4.5-.3.4-.3.2-.2.4-.3.7-.6.3-.3.7-.9h.1a48,48,0,0,1,76.8,0,12,12,0,0,0,19.2-14.4A72.4,72.4,0,0,0,208.3,195.6ZM64,172a16,16,0,1,1,16,16A16,16,0,0,1,64,172Zm96,0a16,16,0,1,1,16,16A16,16,0,0,1,160,172Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M112,168a32,32,0,1,1-32-32A32,32,0,0,1,112,168ZM80,32a32,32,0,1,0,32,32A32,32,0,0,0,80,32Zm96,104a32,32,0,1,0,32,32A32,32,0,0,0,176,136Zm0-40a32,32,0,1,0-32-32A32,32,0,0,0,176,96Z"
            opacity="0.2"
          />
          <path d="M27.2,126.4A7.7,7.7,0,0,0,32,128a8,8,0,0,0,6.4-3.2,52,52,0,0,1,83.1-.1l.6.7h.1c.1.2.3.3.5.5h.2l.3.3h.1a4.7,4.7,0,0,0,.9.6l.3.2.9.4h.3l.8.2h3.3l1.7-.6h.2l.5-.3.3-.2.3-.2a.1.1,0,0,0,.1-.1l.3-.2.5-.5h.1l.6-.6h0a52,52,0,0,1,83.2,0,8.1,8.1,0,0,0,11.2,1.6,8,8,0,0,0,1.6-11.2A67.8,67.8,0,0,0,203,93.5a40,40,0,1,0-54,0,68.1,68.1,0,0,0-21,14.3,68.1,68.1,0,0,0-21-14.3,40,40,0,1,0-54,0,67.8,67.8,0,0,0-27.4,21.7A8,8,0,0,0,27.2,126.4ZM152,64a24,24,0,1,1,24,24A24.1,24.1,0,0,1,152,64ZM56,64A24,24,0,1,1,80,88,24.1,24.1,0,0,1,56,64ZM203,197.5a40,40,0,1,0-54,0,68.1,68.1,0,0,0-21,14.3,68.1,68.1,0,0,0-21-14.3,40,40,0,1,0-54,0,67.8,67.8,0,0,0-27.4,21.7,8,8,0,0,0,1.6,11.2A7.7,7.7,0,0,0,32,232a8,8,0,0,0,6.4-3.2,52,52,0,0,1,83.1-.1l.6.7h.1l.5.5h.2l.3.3h.1l.9.6.3.2.9.4h.3l.8.2h3.4l.7-.3h.1l.8-.3h.2l.5-.3.3-.2.3-.2h.1l.3-.2.5-.5h.1l.6-.6h0a52,52,0,0,1,83.2,0,8.1,8.1,0,0,0,11.2,1.6,8,8,0,0,0,1.6-11.2A67.8,67.8,0,0,0,203,197.5ZM56,168a24,24,0,1,1,24,24A24.1,24.1,0,0,1,56,168Zm96,0a24,24,0,1,1,24,24A24.1,24.1,0,0,1,152,168Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M25.6,115.2A67.8,67.8,0,0,1,53,93.5a40,40,0,1,1,54,0,68.1,68.1,0,0,1,21,14.3,68.1,68.1,0,0,1,21-14.3,40,40,0,1,1,54,0,67.8,67.8,0,0,1,27.4,21.7,8,8,0,0,1-1.6,11.2,8.1,8.1,0,0,1-11.2-1.6,52,52,0,0,0-83.2,0h0l-.6.6h-.1l-.5.5-.3.2a.1.1,0,0,1-.1.1l-.3.2-.3.2-.5.3h-.2l-1.7.6h-3.3l-.8-.2h-.3l-.9-.4-.3-.2a4.7,4.7,0,0,1-.9-.6h-.1l-.3-.3h-.2c-.2-.2-.4-.3-.5-.5h-.1l-.6-.7a52,52,0,0,0-83.1.1A8,8,0,0,1,32,128a7.7,7.7,0,0,1-4.8-1.6A8,8,0,0,1,25.6,115.2ZM203,197.5a40,40,0,1,0-54,0,68.1,68.1,0,0,0-21,14.3,68.1,68.1,0,0,0-21-14.3,40,40,0,1,0-54,0,67.8,67.8,0,0,0-27.4,21.7A8,8,0,0,0,32,232H224a8,8,0,0,0,6.4-12.8A67.8,67.8,0,0,0,203,197.5Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M28.4,124.8a6,6,0,0,0,8.4-1.2,54,54,0,0,1,86.3-.1l.5.6h0l.4.4h.1l.3.2h0l.7.5h.3l.7.3h.2l.6.2H130l.6-.3h.2l.4-.2.2-.2h.3c.1,0,.1-.1.2-.2l.3-.3h.2l.4-.5h0a54,54,0,0,1,86.4,0,6,6,0,0,0,8.4,1.2,6,6,0,0,0,1.2-8.4,66.4,66.4,0,0,0-29.7-22.3A37.6,37.6,0,0,0,214,64a38,38,0,0,0-76,0,37.6,37.6,0,0,0,14.9,30.1A64.9,64.9,0,0,0,128,110.7a64.9,64.9,0,0,0-24.9-16.6A37.6,37.6,0,0,0,118,64a38,38,0,0,0-76,0A37.6,37.6,0,0,0,56.9,94.1a66.4,66.4,0,0,0-29.7,22.3A6,6,0,0,0,28.4,124.8ZM150,64a26,26,0,1,1,26,26A26.1,26.1,0,0,1,150,64ZM54,64A26,26,0,1,1,80,90,26.1,26.1,0,0,1,54,64ZM199.1,198.1A37.6,37.6,0,0,0,214,168a38,38,0,0,0-76,0,37.6,37.6,0,0,0,14.9,30.1A64.9,64.9,0,0,0,128,214.7a64.9,64.9,0,0,0-24.9-16.6A37.6,37.6,0,0,0,118,168a38,38,0,0,0-76,0,37.6,37.6,0,0,0,14.9,30.1,66.4,66.4,0,0,0-29.7,22.3,6,6,0,1,0,9.6,7.2,54,54,0,0,1,86.3-.1l.5.6h0l.4.4h.1l.3.2h0l.7.5h.3l.7.3h.2l.6.2H130l.6-.3h.2l.4-.2.2-.2h.3c.1,0,.1-.1.2-.2l.3-.3h.2l.4-.5h0a54,54,0,0,1,86.4,0,6,6,0,0,0,8.4,1.2,6,6,0,0,0,1.2-8.4A66.4,66.4,0,0,0,199.1,198.1ZM54,168a26,26,0,1,1,26,26A26.1,26.1,0,0,1,54,168Zm96,0a26,26,0,1,1,26,26A26.1,26.1,0,0,1,150,168Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M29.6,123.2a4.3,4.3,0,0,0,2.4.8,4.2,4.2,0,0,0,3.2-1.6,56,56,0,0,1,89.6,0h.1l.3.3.3.3a.1.1,0,0,0,.1.1l.3.2h.1l.6.3h.1l.7.2h1.5l.8-.3h.2l.5-.3h.1l.2-.2.4-.4h.1a56,56,0,0,1,89.6,0,4,4,0,1,0,6.4-4.8,63,63,0,0,0-32.5-22.8,36,36,0,1,0-37.4,0A63.1,63.1,0,0,0,128,113.7,63.1,63.1,0,0,0,98.7,94.8a36,36,0,1,0-37.4,0,63,63,0,0,0-32.5,22.8A4,4,0,0,0,29.6,123.2ZM148,64a28,28,0,1,1,28,28A28.1,28.1,0,0,1,148,64ZM52,64A28,28,0,1,1,80,92,28.1,28.1,0,0,1,52,64ZM194.7,198.8a36,36,0,1,0-37.4,0A63.1,63.1,0,0,0,128,217.7a63.1,63.1,0,0,0-29.3-18.9,36,36,0,1,0-37.4,0,63,63,0,0,0-32.5,22.8,4,4,0,0,0,.8,5.6,4.3,4.3,0,0,0,2.4.8,4.2,4.2,0,0,0,3.2-1.6,56,56,0,0,1,89.6,0h.1l.3.3.3.3h.1l.3.2h.1l.7.3h0l.7.2h1.5l.8-.3h.2l.5-.3h.1l.2-.2.4-.4h.1a56,56,0,0,1,89.6,0,4,4,0,0,0,6.4-4.8A63,63,0,0,0,194.7,198.8ZM52,168a28,28,0,1,1,28,28A28.1,28.1,0,0,1,52,168Zm96,0a28,28,0,1,1,28,28A28.1,28.1,0,0,1,148,168Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M27.2,126.4A7.7,7.7,0,0,0,32,128a8,8,0,0,0,6.4-3.2,52,52,0,0,1,83.1-.1l.6.7h.1l.5.5h.2l.3.3h.1a4.7,4.7,0,0,0,.9.6l.3.2.9.4h4.5l.7-.2h.1l.8-.3h.2l.5-.3.3-.2.3-.2a.1.1,0,0,0,.1-.1l.3-.3a.8.8,0,0,0,.4-.4h.2l.5-.6h.1a52,52,0,0,1,83.2,0,8.1,8.1,0,0,0,11.2,1.6,8,8,0,0,0,1.6-11.2A67.8,67.8,0,0,0,203,93.5a40,40,0,1,0-54,0,68.1,68.1,0,0,0-21,14.3,68.1,68.1,0,0,0-21-14.3,40,40,0,1,0-54,0,67.8,67.8,0,0,0-27.4,21.7A8,8,0,0,0,27.2,126.4ZM152,64a24,24,0,1,1,24,24A24.1,24.1,0,0,1,152,64ZM56,64A24,24,0,1,1,80,88,24.1,24.1,0,0,1,56,64ZM203,197.5a40,40,0,1,0-54,0,68.1,68.1,0,0,0-21,14.3,68.1,68.1,0,0,0-21-14.3,40,40,0,1,0-54,0,67.8,67.8,0,0,0-27.4,21.7,8,8,0,0,0,1.6,11.2A7.7,7.7,0,0,0,32,232a8,8,0,0,0,6.4-3.2,52,52,0,0,1,83.1-.1l.6.7h.1l.5.5h.2l.3.3h.1l.9.6.3.2.9.4h4.5l.7-.2h.1l.8-.3h.2l.5-.3.3-.2.3-.2h.1l.3-.3a.8.8,0,0,0,.4-.4h.2l.5-.6h.1a52,52,0,0,1,83.2,0,8.1,8.1,0,0,0,11.2,1.6,8,8,0,0,0,1.6-11.2A67.8,67.8,0,0,0,203,197.5ZM56,168a24,24,0,1,1,24,24A24.1,24.1,0,0,1,56,168Zm96,0a24,24,0,1,1,24,24A24.1,24.1,0,0,1,152,168Z" />
        </>
      )}
    </svg>
  );
}

export function PhUsersThree(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M164.3,177.2a52,52,0,1,0-72.6,0,75.5,75.5,0,0,0-32.1,33.5,12,12,0,0,0,21.6,10.6,52,52,0,0,1,93.6,0,12,12,0,0,0,10.8,6.7,12.3,12.3,0,0,0,5.2-1.2,12,12,0,0,0,5.6-16.1A75.5,75.5,0,0,0,164.3,177.2ZM100,140a28,28,0,1,1,28,28A28.1,28.1,0,0,1,100,140Zm151.2,9.6a12,12,0,0,1-16.8-2.4,47.4,47.4,0,0,0-31.6-18.7,12,12,0,0,1-9.9-9.1,7.8,7.8,0,0,1-.5-1.4,11.9,11.9,0,0,1,8.6-14.6,20,20,0,1,0-23.1-28,12,12,0,1,1-21.6-10.3,44,44,0,1,1,73.4,47.2,71.1,71.1,0,0,1,23.9,20.5A12,12,0,0,1,251.2,149.6Zm-198-21.1a47.4,47.4,0,0,0-31.6,18.7A12.2,12.2,0,0,1,12,152a12.2,12.2,0,0,1-7.2-2.4,12,12,0,0,1-2.4-16.8,71.1,71.1,0,0,1,23.9-20.5A44,44,0,1,1,99.7,65.1,12,12,0,1,1,78.1,75.4a20,20,0,1,0-23.1,28A11.9,11.9,0,0,1,63.6,118a7.8,7.8,0,0,1-.5,1.4A12,12,0,0,1,53.2,128.5Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M168,140a40,40,0,1,1-40-40A40,40,0,0,1,168,140ZM60,52A32,32,0,1,0,92,84,32,32,0,0,0,60,52Zm136,0a32,32,0,1,0,32,32A32,32,0,0,0,196,52Z"
            opacity="0.2"
          />
          <path d="M248.8,146.4A7.7,7.7,0,0,1,244,148a8,8,0,0,1-6.4-3.2A51.6,51.6,0,0,0,196,124a8,8,0,0,1,0-16,24,24,0,1,0-23.6-28.5,8,8,0,1,1-15.7-3,40,40,0,1,1,66.3,37,67.8,67.8,0,0,1,27.4,21.7A8,8,0,0,1,248.8,146.4Zm-56,66.1a8.1,8.1,0,0,1-3.7,10.7,9.3,9.3,0,0,1-3.5.8,8.1,8.1,0,0,1-7.2-4.5,56.1,56.1,0,0,0-100.8,0,8,8,0,0,1-10.7,3.7,8.1,8.1,0,0,1-3.7-10.7,72.1,72.1,0,0,1,35.6-34.4,48,48,0,1,1,58.4,0A72.1,72.1,0,0,1,192.8,212.5ZM128,172a32,32,0,1,0-32-32A32.1,32.1,0,0,0,128,172ZM68,116a8,8,0,0,0-8-8A24,24,0,1,1,83.6,79.5a8,8,0,1,0,15.7-3,40,40,0,1,0-66.3,37A67.8,67.8,0,0,0,5.6,135.2a8,8,0,0,0,1.6,11.2A7.7,7.7,0,0,0,12,148a8,8,0,0,0,6.4-3.2A51.6,51.6,0,0,1,60,124,8,8,0,0,0,68,116Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M64,140a7.9,7.9,0,0,1-8,8H12a8.2,8.2,0,0,1-7.2-4.4,8.2,8.2,0,0,1,.8-8.4A67.8,67.8,0,0,1,33,113.5a40,40,0,1,1,66.3-37,8.1,8.1,0,0,1-3.8,8.4,64.3,64.3,0,0,0-27.8,33.8A61.6,61.6,0,0,0,64,140Zm186.4-4.8A67.8,67.8,0,0,0,223,113.5a40,40,0,1,0-66.3-37,8.1,8.1,0,0,0,3.8,8.4,64,64,0,0,1,27.8,33.8A61.6,61.6,0,0,1,192,140a7.9,7.9,0,0,0,8,8h44a8,8,0,0,0,6.4-12.8Zm-93.2,42.9a48,48,0,1,0-58.4,0,72.1,72.1,0,0,0-35.6,34.4,7.8,7.8,0,0,0,.5,7.7,7.8,7.8,0,0,0,6.7,3.8H185.6a7.8,7.8,0,0,0,6.7-3.8,7.8,7.8,0,0,0,.5-7.7A72.1,72.1,0,0,0,157.2,178.1Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M247.6,144.8a6,6,0,0,1-8.4-1.2A53.7,53.7,0,0,0,196,122a6,6,0,0,1,0-12,26,26,0,1,0-25.5-30.9,6.1,6.1,0,0,1-7.1,4.8,5.9,5.9,0,0,1-4.7-7A38,38,0,0,1,234,84a37.6,37.6,0,0,1-14.9,30.1,66.4,66.4,0,0,1,29.7,22.3A6,6,0,0,1,247.6,144.8ZM191,213.4a6.1,6.1,0,0,1-2.8,8,6.2,6.2,0,0,1-2.6.6,5.8,5.8,0,0,1-5.4-3.4,58.1,58.1,0,0,0-104.4,0,6,6,0,0,1-8,2.8,6.1,6.1,0,0,1-2.8-8,69.9,69.9,0,0,1,38-34.8,46,46,0,1,1,50,0A69.9,69.9,0,0,1,191,213.4ZM128,174a34,34,0,1,0-34-34A34.1,34.1,0,0,0,128,174ZM60,110A26,26,0,1,1,85.5,79.1a6.1,6.1,0,0,0,7.1,4.8,5.9,5.9,0,0,0,4.7-7A38,38,0,0,0,22,84a37.6,37.6,0,0,0,14.9,30.1A66.4,66.4,0,0,0,7.2,136.4a6,6,0,1,0,9.6,7.2A53.7,53.7,0,0,1,60,122a6,6,0,0,0,0-12Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M246.4,143.2a4.3,4.3,0,0,1-2.4.8,4.2,4.2,0,0,1-3.2-1.6A55.7,55.7,0,0,0,196,120a4,4,0,0,1,0-8,28,28,0,1,0-27.5-33.3,4,4,0,1,1-7.9-1.5,36,36,0,1,1,54.1,37.6,63,63,0,0,1,32.5,22.8A4,4,0,0,1,246.4,143.2Zm-57.2,71a4.1,4.1,0,0,1-1.9,5.4,3.3,3.3,0,0,1-1.7.4,4,4,0,0,1-3.6-2.2,60,60,0,0,0-108,0,3.9,3.9,0,0,1-5.3,1.8,4.1,4.1,0,0,1-1.9-5.4A68.3,68.3,0,0,1,107.7,179a44,44,0,1,1,40.6,0A68.3,68.3,0,0,1,189.2,214.2ZM128,176a36,36,0,1,0-36-36A36,36,0,0,0,128,176ZM60,112A28,28,0,1,1,87.5,78.7a4,4,0,1,0,7.9-1.5,36,36,0,1,0-54.1,37.6A63,63,0,0,0,8.8,137.6a4,4,0,0,0,.8,5.6,4.3,4.3,0,0,0,2.4.8,4.2,4.2,0,0,0,3.2-1.6A55.7,55.7,0,0,1,60,120a4,4,0,0,0,0-8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M248.8,146.4A7.7,7.7,0,0,1,244,148a8,8,0,0,1-6.4-3.2A51.6,51.6,0,0,0,196,124a8,8,0,0,1,0-16,24,24,0,1,0-23.6-28.5,8,8,0,1,1-15.7-3,40,40,0,1,1,66.3,37,67.8,67.8,0,0,1,27.4,21.7A8,8,0,0,1,248.8,146.4Zm-56,66.1a8.1,8.1,0,0,1-3.7,10.7,9.3,9.3,0,0,1-3.5.8,8.1,8.1,0,0,1-7.2-4.5,56.1,56.1,0,0,0-100.8,0,8,8,0,0,1-10.7,3.7,8.1,8.1,0,0,1-3.7-10.7,72.1,72.1,0,0,1,35.6-34.4,48,48,0,1,1,58.4,0A72.1,72.1,0,0,1,192.8,212.5ZM128,172a32,32,0,1,0-32-32A32.1,32.1,0,0,0,128,172ZM68,116a8,8,0,0,0-8-8A24,24,0,1,1,83.6,79.5a8,8,0,1,0,15.7-3,40,40,0,1,0-66.3,37A67.8,67.8,0,0,0,5.6,135.2a8,8,0,0,0,1.6,11.2A7.7,7.7,0,0,0,12,148a8,8,0,0,0,6.4-3.2A51.6,51.6,0,0,1,60,124,8,8,0,0,0,68,116Z" />
        </>
      )}
    </svg>
  );
}

export function PhUsers(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M129.2,156.9a64,64,0,1,0-82.4,0A100.1,100.1,0,0,0,6.2,190.5a12,12,0,0,0,2.9,16.7,11.8,11.8,0,0,0,6.9,2.2,11.9,11.9,0,0,0,9.8-5.1,76,76,0,0,1,124.4,0,12,12,0,1,0,19.6-13.8A100.1,100.1,0,0,0,129.2,156.9ZM48,108a40,40,0,1,1,40,40A40,40,0,0,1,48,108Zm200.4,99.2a11.8,11.8,0,0,1-6.9,2.2,12.1,12.1,0,0,1-9.8-5.1A76.2,76.2,0,0,0,169.5,172a12,12,0,0,1,0-24,40,40,0,0,0,0-80,39.1,39.1,0,0,0-10.8,1.5,12,12,0,1,1-6.5-23.1A62.8,62.8,0,0,1,169.5,44a63.9,63.9,0,0,1,41.2,112.9,100.1,100.1,0,0,1,40.6,33.6A11.9,11.9,0,0,1,248.4,207.2Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="88" cy="108" r="52" />
          </g>
          <path d="M121.2,157.9a60,60,0,1,0-66.4,0A95.5,95.5,0,0,0,9.5,192.8a8,8,0,1,0,13,9.2,80.1,80.1,0,0,1,131,0,8,8,0,1,0,13-9.2A95.5,95.5,0,0,0,121.2,157.9ZM44,108a44,44,0,1,1,44,44A44,44,0,0,1,44,108Z" />
          <path d="M248.1,192.8a96.3,96.3,0,0,0-45.4-34.9A59.9,59.9,0,0,0,169.5,48a64,64,0,0,0-16.3,2.2,8.1,8.1,0,0,0-5.5,9.9,8,8,0,0,0,9.9,5.5A47.4,47.4,0,0,1,169.5,64a44,44,0,0,1,0,88,8,8,0,0,0,0,16A80.2,80.2,0,0,1,235,202a7.9,7.9,0,0,0,11.1,1.9A8,8,0,0,0,248.1,192.8Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M166.5,192.8a7.8,7.8,0,0,1,.6,8.3,8.1,8.1,0,0,1-7.1,4.3H16a8.1,8.1,0,0,1-7.1-4.3,7.8,7.8,0,0,1,.6-8.3,95.5,95.5,0,0,1,45.3-34.9,60,60,0,1,1,66.4,0A95.5,95.5,0,0,1,166.5,192.8Zm81.6,0a96.3,96.3,0,0,0-45.4-34.9A59.9,59.9,0,0,0,169.5,48a64,64,0,0,0-16.3,2.2,8.2,8.2,0,0,0-5.4,5.2,8,8,0,0,0,1.2,7.3,75.8,75.8,0,0,1,3.8,84.9,8.1,8.1,0,0,0,2.1,10.6q4.5,3.5,8.7,7.2l.5.5a112.6,112.6,0,0,1,25.5,34.9,7.9,7.9,0,0,0,7.2,4.6h44.7a8.1,8.1,0,0,0,7.1-4.3A8,8,0,0,0,248.1,192.8Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M116.5,158.5a58,58,0,1,0-57,0,93.6,93.6,0,0,0-48.4,35.4,6,6,0,0,0,1.4,8.4,6.6,6.6,0,0,0,3.5,1.1,6.1,6.1,0,0,0,4.9-2.5,82,82,0,0,1,134.2-.1,6,6,0,0,0,8.4,1.5,6.1,6.1,0,0,0,1.4-8.4A94.4,94.4,0,0,0,116.5,158.5ZM42,108a46,46,0,1,1,46,46A46,46,0,0,1,42,108Zm203,94.3a6.6,6.6,0,0,1-3.5,1.1,5.9,5.9,0,0,1-4.9-2.6A82,82,0,0,0,169.5,166a6,6,0,0,1,0-12,46,46,0,0,0,0-92A48.3,48.3,0,0,0,157,63.7a6,6,0,1,1-3.2-11.5A55.6,55.6,0,0,1,169.5,50a58,58,0,0,1,28.6,108.5,93.8,93.8,0,0,1,48.3,35.4A6.1,6.1,0,0,1,245,202.3Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M111.1,159a56,56,0,1,0-46.2,0,92.2,92.2,0,0,0-52.2,36.1,4.1,4.1,0,0,0,1,5.6,3.9,3.9,0,0,0,2.3.7,4.2,4.2,0,0,0,3.3-1.7,84,84,0,0,1,137.4,0,4,4,0,1,0,6.6-4.6A92.2,92.2,0,0,0,111.1,159ZM40,108a48,48,0,1,1,48,48A48,48,0,0,1,40,108Zm203.8,92.7a4,4,0,0,1-5.5-1A84.3,84.3,0,0,0,169.5,164a4,4,0,0,1,0-8,48,48,0,0,0,0-96,47.4,47.4,0,0,0-13,1.8,4,4,0,0,1-4.9-2.8,4,4,0,0,1,2.7-4.9A56,56,0,0,1,192.7,159a92.3,92.3,0,0,1,52.1,36.1A4,4,0,0,1,243.8,200.7Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M121.2,157.9a60,60,0,1,0-66.4,0A95.5,95.5,0,0,0,9.5,192.8a8,8,0,1,0,13,9.2,80.1,80.1,0,0,1,131,0,8,8,0,1,0,13-9.2A95.5,95.5,0,0,0,121.2,157.9ZM44,108a44,44,0,1,1,44,44A44,44,0,0,1,44,108Zm202.1,95.9A7.9,7.9,0,0,1,235,202a80.2,80.2,0,0,0-65.5-34,8,8,0,0,1,0-16,44,44,0,0,0,0-88,47.4,47.4,0,0,0-11.9,1.6,8,8,0,0,1-9.9-5.5,8.1,8.1,0,0,1,5.5-9.9A64,64,0,0,1,169.5,48a59.9,59.9,0,0,1,33.2,109.9,96.3,96.3,0,0,1,45.4,34.9A8,8,0,0,1,246.1,203.9Z" />
        </>
      )}
    </svg>
  );
}

export function PhVault(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216,36H40A20.1,20.1,0,0,0,20,56V192a20.1,20.1,0,0,0,20,20H52v12a12,12,0,0,0,24,0V212H180v12a12,12,0,0,0,24,0V212h12a20.1,20.1,0,0,0,20-20V56A20.1,20.1,0,0,0,216,36ZM44,188V60H212v56H190.3a44,44,0,1,0,0,24H212v48Zm124-60a20,20,0,1,1-20-20A20.1,20.1,0,0,1,168,128Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M216,48H40a8,8,0,0,0-8,8V192a8,8,0,0,0,8,8H216a8,8,0,0,0,8-8V56A8,8,0,0,0,216,48ZM152,168a40,40,0,1,1,40-40A40,40,0,0,1,152,168Z"
            opacity="0.2"
          />
          <path d="M216,40H40A16,16,0,0,0,24,56V192a16,16,0,0,0,16,16H56v16a8,8,0,0,0,16,0V208H184v16a8,8,0,0,0,16,0V208h16a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40Zm0,152H40V56H216v64H199.3a48,48,0,1,0,0,16H216v56Zm-50.2-72a15.8,15.8,0,0,0-13.8-8,16,16,0,0,0,0,32,15.8,15.8,0,0,0,13.8-8H183a32,32,0,1,1,0-16Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,40H40A16,16,0,0,0,24,56V192a16,16,0,0,0,16,16H56v16a8,8,0,0,0,16,0V208H184v16a8,8,0,0,0,16,0V208h16a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40Zm-8,96H179.1a36,36,0,1,1,0-16H208a8,8,0,0,1,0,16Zm-44-8a20,20,0,1,1-20-20A20.1,20.1,0,0,1,164,128Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M216,42H40A14,14,0,0,0,26,56V192a14,14,0,0,0,14,14H58v18a6,6,0,0,0,12,0V206H186v18a6,6,0,0,0,12,0V206h18a14,14,0,0,0,14-14V56A14,14,0,0,0,216,42Zm0,152H40a2,2,0,0,1-2-2V56a2,2,0,0,1,2-2H216a2,2,0,0,1,2,2v66H197.6a46,46,0,1,0,0,12H218v58A2,2,0,0,1,216,194Zm-51.4-72a14,14,0,1,0,0,12h20.9a34,34,0,1,1,0-12Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M216,44H40A12,12,0,0,0,28,56V192a12,12,0,0,0,12,12H60v20a4,4,0,0,0,8,0V204H188v20a4,4,0,0,0,8,0V204h20a12,12,0,0,0,12-12V56A12,12,0,0,0,216,44Zm0,152H40a4,4,0,0,1-4-4V56a4,4,0,0,1,4-4H216a4,4,0,0,1,4,4v68H195.8a44,44,0,1,0,0,8H220v60A4,4,0,0,1,216,196Zm-52.7-72a11.9,11.9,0,0,0-11.3-8,12,12,0,0,0,0,24,11.9,11.9,0,0,0,11.3-8h24.5a36,36,0,1,1,0-8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,40H40A16,16,0,0,0,24,56V192a16,16,0,0,0,16,16H56v16a8,8,0,0,0,16,0V208H184v16a8,8,0,0,0,16,0V208h16a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40Zm0,152H40V56H216v64H199.3a48,48,0,1,0,0,16H216v56Zm-50.2-72a15.8,15.8,0,0,0-13.8-8,16,16,0,0,0,0,32,15.8,15.8,0,0,0,13.8-8H183a32,32,0,1,1,0-16Z" />
        </>
      )}
    </svg>
  );
}

export function PhVibrate(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M164,28H92A28.1,28.1,0,0,0,64,56V200a28.1,28.1,0,0,0,28,28h72a28.1,28.1,0,0,0,28-28V56A28.1,28.1,0,0,0,164,28Zm4,172a4,4,0,0,1-4,4H92a4,4,0,0,1-4-4V56a4,4,0,0,1,4-4h72a4,4,0,0,1,4,4Zm64-100v56a12,12,0,0,1-24,0V100a12,12,0,0,1,24,0ZM48,100v56a12,12,0,0,1-24,0V100a12,12,0,0,1,24,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect
              x="40"
              y="80"
              width="176"
              height="96"
              rx="16"
              transform="translate(256 0) rotate(90)"
            />
          </g>
          <path d="M160,32H96A24.1,24.1,0,0,0,72,56V200a24.1,24.1,0,0,0,24,24h64a24.1,24.1,0,0,0,24-24V56A24.1,24.1,0,0,0,160,32Zm8,168a8,8,0,0,1-8,8H96a8,8,0,0,1-8-8V56a8,8,0,0,1,8-8h64a8,8,0,0,1,8,8Z" />
          <path d="M208,80a8,8,0,0,0-8,8v80a8,8,0,0,0,16,0V88A8,8,0,0,0,208,80Z" />
          <path d="M240,96a8,8,0,0,0-8,8v48a8,8,0,0,0,16,0V104A8,8,0,0,0,240,96Z" />
          <path d="M48,80a8,8,0,0,0-8,8v80a8,8,0,0,0,16,0V88A8,8,0,0,0,48,80Z" />
          <path d="M16,96a8,8,0,0,0-8,8v48a8,8,0,0,0,16,0V104A8,8,0,0,0,16,96Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M184,56V200a24.1,24.1,0,0,1-24,24H96a24.1,24.1,0,0,1-24-24V56A24.1,24.1,0,0,1,96,32h64A24.1,24.1,0,0,1,184,56Zm24,24a8,8,0,0,0-8,8v80a8,8,0,0,0,16,0V88A8,8,0,0,0,208,80Zm32,16a8,8,0,0,0-8,8v48a8,8,0,0,0,16,0V104A8,8,0,0,0,240,96ZM48,80a8,8,0,0,0-8,8v80a8,8,0,0,0,16,0V88A8,8,0,0,0,48,80ZM16,96a8,8,0,0,0-8,8v48a8,8,0,0,0,16,0V104A8,8,0,0,0,16,96Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M160,34H96A22.1,22.1,0,0,0,74,56V200a22.1,22.1,0,0,0,22,22h64a22.1,22.1,0,0,0,22-22V56A22.1,22.1,0,0,0,160,34Zm10,166a10,10,0,0,1-10,10H96a10,10,0,0,1-10-10V56A10,10,0,0,1,96,46h64a10,10,0,0,1,10,10ZM214,88v80a6,6,0,0,1-12,0V88a6,6,0,0,1,12,0Zm32,16v48a6,6,0,0,1-12,0V104a6,6,0,0,1,12,0ZM54,88v80a6,6,0,0,1-12,0V88a6,6,0,0,1,12,0ZM22,104v48a6,6,0,0,1-12,0V104a6,6,0,0,1,12,0Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M160,36H96A20.1,20.1,0,0,0,76,56V200a20.1,20.1,0,0,0,20,20h64a20.1,20.1,0,0,0,20-20V56A20.1,20.1,0,0,0,160,36Zm12,164a12,12,0,0,1-12,12H96a12,12,0,0,1-12-12V56A12,12,0,0,1,96,44h64a12,12,0,0,1,12,12ZM212,88v80a4,4,0,0,1-8,0V88a4,4,0,0,1,8,0Zm32,16v48a4,4,0,0,1-8,0V104a4,4,0,0,1,8,0ZM52,88v80a4,4,0,0,1-8,0V88a4,4,0,0,1,8,0ZM20,104v48a4,4,0,0,1-8,0V104a4,4,0,0,1,8,0Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M160,32H96A24.1,24.1,0,0,0,72,56V200a24.1,24.1,0,0,0,24,24h64a24.1,24.1,0,0,0,24-24V56A24.1,24.1,0,0,0,160,32Zm8,168a8,8,0,0,1-8,8H96a8,8,0,0,1-8-8V56a8,8,0,0,1,8-8h64a8,8,0,0,1,8,8ZM216,88v80a8,8,0,0,1-16,0V88a8,8,0,0,1,16,0Zm32,16v48a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0ZM56,88v80a8,8,0,0,1-16,0V88a8,8,0,0,1,16,0ZM24,104v48a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Z" />
        </>
      )}
    </svg>
  );
}

export function PhVideoCameraSlash(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M246,69.6a12.2,12.2,0,0,0-12,0L196,91.3V68a20.1,20.1,0,0,0-20-20H121.7a12,12,0,1,0,0,24H172v85L43.8,15.9A12,12,0,1,0,26,32.1L40.5,48H24A20.1,20.1,0,0,0,4,68V188a20.1,20.1,0,0,0,20,20H176a20.6,20.6,0,0,0,8.3-1.8l30.8,33.9a12.1,12.1,0,0,0,17,.8,12.1,12.1,0,0,0,.8-17L196,183.4V164.7l38,21.7a11.7,11.7,0,0,0,6,1.6,11.5,11.5,0,0,0,6-1.6,12.1,12.1,0,0,0,6-10.4V80A12.1,12.1,0,0,0,246,69.6ZM28,184V72H62.3L164.1,184Zm200-28.7L196,137V119l32-18.3Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M184,68V188a8,8,0,0,1-8,8H24a8,8,0,0,1-8-8V68a8,8,0,0,1,8-8H176A8,8,0,0,1,184,68Z"
            opacity="0.2"
          />
          <path d="M244,73.1a7.7,7.7,0,0,0-8,0L192,98.2V68a16,16,0,0,0-16-16H110.9a8,8,0,0,0,0,16H176v76a7.8,7.8,0,0,0,4,6.9l56,32a8.3,8.3,0,0,0,4,1.1,8,8,0,0,0,4-1.1,7.8,7.8,0,0,0,4-6.9V80A7.8,7.8,0,0,0,244,73.1Zm-12,89.1-40-22.8V116.6l40-22.8ZM40.8,18.6a7.9,7.9,0,0,0-11.3-.5A8,8,0,0,0,29,29.4L49.6,52H24A16,16,0,0,0,8,68V188a16,16,0,0,0,16,16H176a15.9,15.9,0,0,0,9.1-2.9l33,36.3A8,8,0,0,0,224,240a8.2,8.2,0,0,0,5.4-2.1,7.9,7.9,0,0,0,.5-11.3ZM24,188V68H64.1L173.2,188Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M105,65.4a8,8,0,0,1-.5-10.3,8.5,8.5,0,0,1,6.6-3.1H160a16,16,0,0,1,16,16v54.5a8.2,8.2,0,0,1-4.1,7.3,8,8,0,0,1-9.8-1.6ZM40.8,18.6a7.9,7.9,0,0,0-11.3-.5A8,8,0,0,0,29,29.4L49.6,52H24A16,16,0,0,0,8,68V188a16,16,0,0,0,16,16H160a15.9,15.9,0,0,0,15.7-13.2l42.4,46.6A8,8,0,0,0,224,240a8.2,8.2,0,0,0,5.4-2.1,7.9,7.9,0,0,0,.5-11.3ZM245.5,74.2a7.9,7.9,0,0,0-9.5-1.1L196,95.9a8,8,0,0,0-4,7v50.2a8,8,0,0,0,4,7l40,22.8a8.3,8.3,0,0,0,4,1.1,7.9,7.9,0,0,0,5.8-2.5,8,8,0,0,0,2.2-5.7V80.2A8,8,0,0,0,245.5,74.2Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M243,74.8a5.9,5.9,0,0,0-6,0l-47,26.9V68a14,14,0,0,0-14-14H110.9a6,6,0,0,0,0,12H176a2,2,0,0,1,2,2v76a6.1,6.1,0,0,0,3,5.2l56,32a6,6,0,0,0,6,0,6,6,0,0,0,3-5.2V80A6,6,0,0,0,243,74.8Zm-9,90.9-44-25.2v-25l44-25.2ZM39.3,20a5.9,5.9,0,1,0-8.8,8L54.1,54H24A14,14,0,0,0,10,68V188a14,14,0,0,0,14,14H176a13.8,13.8,0,0,0,9.3-3.6L219.6,236a5.9,5.9,0,0,0,4.4,2,5.7,5.7,0,0,0,4-1.6,5.8,5.8,0,0,0,.4-8.4ZM176,190H24a2,2,0,0,1-2-2V68a2,2,0,0,1,2-2H65L177.3,189.5A2.1,2.1,0,0,1,176,190Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M242,76.5a4.1,4.1,0,0,0-4,0l-50,28.6V68a12,12,0,0,0-12-12H110.9a4,4,0,0,0,0,8H176a4,4,0,0,1,4,4v76a4.2,4.2,0,0,0,2,3.5l56,32a4.3,4.3,0,0,0,4,0,4.3,4.3,0,0,0,2-3.5V80A4.3,4.3,0,0,0,242,76.5Zm-6,92.6-48-27.4V114.3l48-27.4ZM37.9,21.3a4,4,0,0,0-6,5.4L58.6,56H24A12,12,0,0,0,12,68V188a12,12,0,0,0,12,12H176a12.2,12.2,0,0,0,9.4-4.5L221,234.7a4.1,4.1,0,0,0,5.7.3,4.2,4.2,0,0,0,.3-5.7ZM176,192H24a4,4,0,0,1-4-4V68a4,4,0,0,1,4-4H65.9L179.8,189.3A4,4,0,0,1,176,192Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M244,73.1a7.7,7.7,0,0,0-8,0L192,98.2V68a16,16,0,0,0-16-16H110.9a8,8,0,0,0,0,16H176v76a7.8,7.8,0,0,0,4,6.9l56,32a8.3,8.3,0,0,0,4,1.1,8,8,0,0,0,4-1.1,7.8,7.8,0,0,0,4-6.9V80A7.8,7.8,0,0,0,244,73.1Zm-12,89.1-40-22.8V116.6l40-22.8ZM40.8,18.6a7.9,7.9,0,0,0-11.3-.5A8,8,0,0,0,29,29.4L49.6,52H24A16,16,0,0,0,8,68V188a16,16,0,0,0,16,16H176a15.9,15.9,0,0,0,9.1-2.9l33,36.3A8,8,0,0,0,224,240a8.2,8.2,0,0,0,5.4-2.1,7.9,7.9,0,0,0,.5-11.3ZM24,188V68H64.1L173.2,188Z" />
        </>
      )}
    </svg>
  );
}

export function PhVideoCamera(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M246,69.6a12.2,12.2,0,0,0-12,0L196,91.3A44.1,44.1,0,0,0,152,48H24A20.1,20.1,0,0,0,4,68v96a44,44,0,0,0,44,44H176a20.1,20.1,0,0,0,20-20V164.7l38,21.7a11.7,11.7,0,0,0,6,1.6,11.5,11.5,0,0,0,6-1.6,12.1,12.1,0,0,0,6-10.4V80A12.1,12.1,0,0,0,246,69.6ZM172,184H48a20.1,20.1,0,0,1-20-20V72H152a20.1,20.1,0,0,1,20,20Zm56-28.7L196,137V119l32-18.3Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M24,60H152a32,32,0,0,1,32,32v96a8,8,0,0,1-8,8H48a32,32,0,0,1-32-32V68A8,8,0,0,1,24,60Z" />
          </g>
          <path d="M244,73.1a7.7,7.7,0,0,0-8,0L192,98.2V92a40,40,0,0,0-40-40H24A16,16,0,0,0,8,68v96a40,40,0,0,0,40,40H176a16,16,0,0,0,16-16V157.8l44,25.1a8.3,8.3,0,0,0,4,1.1,8,8,0,0,0,4-1.1,7.8,7.8,0,0,0,4-6.9V80A7.8,7.8,0,0,0,244,73.1ZM176,188H48a24.1,24.1,0,0,1-24-24V68H152a24.1,24.1,0,0,1,24,24v20.1h0V188Zm56-25.8-40-22.8V116.6l40-22.8Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M176,92v96a16,16,0,0,1-16,16H48A40,40,0,0,1,8,164V68A16,16,0,0,1,24,52H136A40,40,0,0,1,176,92Zm68-18.9a7.7,7.7,0,0,0-8,0L196,95.9a8,8,0,0,0-4,7v50.2a8,8,0,0,0,4,7l40,22.8a8.3,8.3,0,0,0,4,1.1,8,8,0,0,0,4-1.1,7.8,7.8,0,0,0,4-6.9V80A7.8,7.8,0,0,0,244,73.1Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M243,74.8a5.9,5.9,0,0,0-6,0l-47,26.9V92a38,38,0,0,0-38-38H24A14,14,0,0,0,10,68v96a38,38,0,0,0,38,38H176a14,14,0,0,0,14-14V154.3l47,26.9a6,6,0,0,0,6,0,6,6,0,0,0,3-5.2V80A6,6,0,0,0,243,74.8ZM178,188a2,2,0,0,1-2,2H48a26.1,26.1,0,0,1-26-26V68a2,2,0,0,1,2-2H152a26.1,26.1,0,0,1,26,26Zm56-22.3-44-25.2v-25l44-25.2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M242,76.5a4.1,4.1,0,0,0-4,0l-50,28.6V92a36,36,0,0,0-36-36H24A12,12,0,0,0,12,68v96a36,36,0,0,0,36,36H176a12,12,0,0,0,12-12V150.9l50,28.6a4.3,4.3,0,0,0,4,0,4.3,4.3,0,0,0,2-3.5V80A4.3,4.3,0,0,0,242,76.5ZM180,188a4,4,0,0,1-4,4H48a28.1,28.1,0,0,1-28-28V68a4,4,0,0,1,4-4H152a28.1,28.1,0,0,1,28,28Zm56-18.9-48-27.4V114.3l48-27.4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M244,73.1a7.7,7.7,0,0,0-8,0L192,98.2V92a40,40,0,0,0-40-40H24A16,16,0,0,0,8,68v96a40,40,0,0,0,40,40H176a16,16,0,0,0,16-16V157.8l44,25.1a8.3,8.3,0,0,0,4,1.1,8,8,0,0,0,4-1.1,7.8,7.8,0,0,0,4-6.9V80A7.8,7.8,0,0,0,244,73.1ZM176,188H48a24.1,24.1,0,0,1-24-24V68H152a24.1,24.1,0,0,1,24,24v20.1h0V188Zm56-25.8-40-22.8V116.6l40-22.8Z" />
        </>
      )}
    </svg>
  );
}

export function PhVignette(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216,36H40A20.1,20.1,0,0,0,20,56V200a20.1,20.1,0,0,0,20,20H216a20.1,20.1,0,0,0,20-20V56A20.1,20.1,0,0,0,216,36Zm-4,160H44V60H212ZM81.4,166c12.6,9,29.2,14,46.6,14s34-5,46.6-14S196,142.5,196,128s-7.8-28.3-21.4-38S145.4,76,128,76,94,81,81.4,90,60,113.5,60,128,67.8,156.3,81.4,166ZM128,100c23.9,0,44,12.8,44,28s-20.1,28-44,28-44-12.8-44-28S104.1,100,128,100Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M216,48H40a8,8,0,0,0-8,8V200a8,8,0,0,0,8,8H216a8,8,0,0,0,8-8V56A8,8,0,0,0,216,48ZM128,176c-35.3,0-64-21.5-64-48s28.7-48,64-48,64,21.5,64,48S163.3,176,128,176Z"
            opacity="0.2"
          />
          <path d="M216,40H40A16,16,0,0,0,24,56V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40Zm0,160H40V56H216V200ZM178.1,87.7C164.6,77.6,146.8,72,128,72S91.4,77.6,77.9,87.7,56,112.6,56,128s7.8,29.7,21.9,40.3S109.2,184,128,184s36.6-5.6,50.1-15.7S200,143.4,200,128,192.2,98.3,178.1,87.7ZM128,168c-30.9,0-56-17.9-56-40s25.1-40,56-40,56,17.9,56,40S158.9,168,128,168Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,40H40A16,16,0,0,0,24,56V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40Zm-16,88c0,30.9-32.2,56-72,56s-72-25.1-72-56,32.2-56,72-56S200,97.1,200,128Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M216,42H40A14,14,0,0,0,26,56V200a14,14,0,0,0,14,14H216a14,14,0,0,0,14-14V56A14,14,0,0,0,216,42Zm2,158a2,2,0,0,1-2,2H40a2,2,0,0,1-2-2V56a2,2,0,0,1,2-2H216a2,2,0,0,1,2,2ZM128,74c-38.6,0-70,24.2-70,54s31.4,54,70,54,70-24.2,70-54S166.6,74,128,74Zm0,96c-32,0-58-18.8-58-42s26-42,58-42,58,18.8,58,42S160,170,128,170Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M216,44H40A12,12,0,0,0,28,56V200a12,12,0,0,0,12,12H216a12,12,0,0,0,12-12V56A12,12,0,0,0,216,44Zm4,156a4,4,0,0,1-4,4H40a4,4,0,0,1-4-4V56a4,4,0,0,1,4-4H216a4,4,0,0,1,4,4ZM128,76c-37.5,0-68,23.3-68,52s30.5,52,68,52,68-23.3,68-52S165.5,76,128,76Zm0,96c-33.1,0-60-19.7-60-44s26.9-44,60-44,60,19.7,60,44S161.1,172,128,172Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,40H40A16,16,0,0,0,24,56V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40Zm0,160H40V56H216V200ZM178.1,87.7C164.6,77.6,146.8,72,128,72S91.4,77.6,77.9,87.7,56,112.6,56,128s7.8,29.7,21.9,40.3S109.2,184,128,184s36.6-5.6,50.1-15.7S200,143.4,200,128,192.2,98.3,178.1,87.7ZM128,168c-30.9,0-56-17.9-56-40s25.1-40,56-40,56,17.9,56,40S158.9,168,128,168Z" />
        </>
      )}
    </svg>
  );
}

export function PhVoicemail(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M196,68a60,60,0,0,0-48,96H108a60,60,0,1,0-48,24H196a60,60,0,0,0,0-120ZM24,128a36,36,0,1,1,36,36A36,36,0,0,1,24,128Zm172,36a36,36,0,1,1,36-36A36,36,0,0,1,196,164Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="56" cy="128" r="48" />
          </g>
          <g opacity="0.2">
            <circle cx="200" cy="128" r="48" />
          </g>
          <path d="M200,72a55.9,55.9,0,0,0-39.1,96H95.1A55.9,55.9,0,1,0,56,184H200a56,56,0,0,0,0-112ZM16,128a40,40,0,1,1,40,40A40,40,0,0,1,16,128Zm184,40a40,40,0,1,1,40-40A40,40,0,0,1,200,168Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M200,72a55.9,55.9,0,0,0-39.1,96H95.1A55.9,55.9,0,1,0,56,184H200a56,56,0,0,0,0-112ZM16,128a40,40,0,1,1,40,40A40,40,0,0,1,16,128Zm184,40a40,40,0,1,1,40-40A40,40,0,0,1,200,168Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M200,74a54,54,0,0,0-33.9,96H89.9A54,54,0,1,0,56,182H200a54,54,0,0,0,0-108ZM14,128a42,42,0,1,1,42,42A42,42,0,0,1,14,128Zm186,42a42,42,0,1,1,42-42A42,42,0,0,1,200,170Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M200,76a52,52,0,0,0-27.7,96H83.7A52,52,0,1,0,56,180H200a52,52,0,0,0,0-104ZM12,128a44,44,0,1,1,44,44A44,44,0,0,1,12,128Zm188,44a44,44,0,1,1,44-44A44,44,0,0,1,200,172Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M200,72a55.9,55.9,0,0,0-39.1,96H95.1A55.9,55.9,0,1,0,56,184H200a56,56,0,0,0,0-112ZM16,128a40,40,0,1,1,40,40A40,40,0,0,1,16,128Zm184,40a40,40,0,1,1,40-40A40,40,0,0,1,200,168Z" />
        </>
      )}
    </svg>
  );
}

export function PhVolleyball(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M227.7,169.3a107.3,107.3,0,0,0,8.3-40.7v-1.1a108,108,0,0,0-162-93C22.4,64.2,4.7,130.4,34.5,182a101,101,0,0,0,6.7,10.3l.6.8A106.9,106.9,0,0,0,100,232.3a109.2,109.2,0,0,0,28.2,3.7,108,108,0,0,0,98.5-64.3A8.2,8.2,0,0,0,227.7,169.3ZM114.7,45a84.4,84.4,0,0,1,46.6,71H134.9L96.8,50A86.6,86.6,0,0,1,114.7,45Zm20.2,95h76.2a85.1,85.1,0,0,1-4.7,18,83.9,83.9,0,0,1-84.6,4.8Zm65.8-54a82,82,0,0,1,10.4,30H185.3a108.3,108.3,0,0,0-14.4-49.7,109.5,109.5,0,0,0-11.4-16.2A83.4,83.4,0,0,1,200.7,86ZM76,62,89,84.4a107.8,107.8,0,0,0-35.9,37.3,115.6,115.6,0,0,0-8.3,17.9A84.5,84.5,0,0,1,76,62ZM62.8,181a82.7,82.7,0,0,1,11.1-47.3A84,84,0,0,1,101,105.2L114.1,128,76,194A85.4,85.4,0,0,1,62.8,181Zm43.5,28.1a75.3,75.3,0,0,1-9.5-3.1l12.9-22.4a108.4,108.4,0,0,0,70,10.6,78.1,78.1,0,0,1-9.7,6.5A83,83,0,0,1,106.3,209.1Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M216.2,165.8A96.1,96.1,0,0,1,80,211.2h0a96.3,96.3,0,0,1-28.9-25.5h0A96,96,0,0,1,105.5,89L80,44.9a96,96,0,0,1,36.6-12.2h0A96.1,96.1,0,0,1,173.1,128H128l-22.5,39a95.9,95.9,0,0,0,110.7-1.2Zm.1,0h0Z"
            opacity="0.2"
          />
          <path d="M224,167.9a105.7,105.7,0,0,0,8-39.3v-1A104,104,0,1,0,37.9,180c2,3.4,4.2,6.7,6.5,9.9l.5.7a103.9,103.9,0,0,0,178.2-20.7A7.2,7.2,0,0,0,224,167.9ZM91.1,48.1a89.1,89.1,0,0,1,24.3-7.2,88.1,88.1,0,0,1,50,79.1H132.6ZM132.6,136h83a89.3,89.3,0,0,1-5.9,24.6,88.2,88.2,0,0,1-93.5,3.8Zm71.6-52a87.2,87.2,0,0,1,11.4,36H181.4a105,105,0,0,0-35.7-78.2A88,88,0,0,1,204.2,84ZM77.3,56.1,94.4,85.8a104.4,104.4,0,0,0-49.9,70.1A88.2,88.2,0,0,1,77.3,56.1ZM58.9,182.5a88,88,0,0,1,43.5-82.9L118.8,128,77.3,199.9A84.2,84.2,0,0,1,58.9,182.5ZM172,204.2a87.9,87.9,0,0,1-80.9,3.6l17.1-29.6a104.4,104.4,0,0,0,85.6,8.2A88.4,88.4,0,0,1,172,204.2Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M232,127.6A104,104,0,1,0,37.9,180c2,3.4,4.2,6.7,6.5,9.9l.5.7a103.9,103.9,0,0,0,178.2-20.7,7.2,7.2,0,0,0,.9-2,105.7,105.7,0,0,0,8-39.3v-1ZM44.5,155.9A88.2,88.2,0,0,1,77.3,56.1L94.4,85.8a104.4,104.4,0,0,0-49.9,70.1Zm32.8,44a84.2,84.2,0,0,1-18.4-17.4,88,88,0,0,1,43.5-82.9L118.8,128Zm132.4-39.3a88.2,88.2,0,0,1-93.5,3.8L132.6,136h83A89.3,89.3,0,0,1,209.7,160.6ZM181.4,120a105,105,0,0,0-35.7-78.2A87.9,87.9,0,0,1,215.6,120Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M222.1,167.2a102,102,0,0,0,7.9-38.6v-1A102.1,102.1,0,0,0,216.3,77,102,102,0,1,0,39.7,179a97.3,97.3,0,0,0,6.6,10.1l.2.2a101.9,101.9,0,0,0,132.5,27A101.3,101.3,0,0,0,221.4,169,13.4,13.4,0,0,0,222.1,167.2ZM88.3,47.2a88.4,88.4,0,0,1,27.4-8.3,89.3,89.3,0,0,1,39.6,36.4,88.3,88.3,0,0,1,12,46.7H131.5ZM131.5,134h86.3a92.3,92.3,0,0,1-6.5,27.9,90,90,0,0,1-97.8,3.2Zm74.4-51a88.9,88.9,0,0,1,11.9,39H179.3a100.7,100.7,0,0,0-13.6-52.7,102.2,102.2,0,0,0-27.1-30.6A89.6,89.6,0,0,1,205.9,83ZM77.9,53.2,97.1,86.5a102.4,102.4,0,0,0-51.8,77.1A90.3,90.3,0,0,1,77.9,53.2ZM57,183.3a89,89,0,0,1,11.7-52.6,90,90,0,0,1,34.4-33.8l18,31.1L77.9,202.8A91.9,91.9,0,0,1,57,183.3Zm116,22.6a89.7,89.7,0,0,1-84.7,2.8l19.2-33.2a102.2,102.2,0,0,0,92.6,6.3A88.9,88.9,0,0,1,173,205.9Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M220.2,166.7a102.9,102.9,0,0,0,7.8-38.4v-.5A100,100,0,1,0,41.4,178a99,99,0,0,0,6.3,9.6l.5.7a99.4,99.4,0,0,0,53.9,36.3,101.1,101.1,0,0,0,26.1,3.4,99.9,99.9,0,0,0,91.4-60A5.2,5.2,0,0,0,220.2,166.7ZM85.5,46.4a89.9,89.9,0,0,1,30.4-9.6A92.1,92.1,0,0,1,169.3,124h-39ZM130.3,132h89.6a93.1,93.1,0,0,1-7,31.2,92,92,0,0,1-102.1,2.6Zm77.4-50a92.4,92.4,0,0,1,12.2,42H177.3a99.9,99.9,0,0,0-46.1-88,96.8,96.8,0,0,1,20.6,3.1A91.7,91.7,0,0,1,207.7,82ZM78.6,50.4,99.9,87.3a100,100,0,0,0-53.2,83.8A92.2,92.2,0,0,1,78.6,50.4ZM55,184a92.3,92.3,0,0,1,48.9-89.8L123.4,128,78.6,205.6A89.9,89.9,0,0,1,55,184Zm119,23.7a91.8,91.8,0,0,1-88.5,1.9l21.3-36.9A99.5,99.5,0,0,0,160,188a98.2,98.2,0,0,0,45.9-11.2A90.9,90.9,0,0,1,174,207.7Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M224,167.9a105.7,105.7,0,0,0,8-39.3v-1A104,104,0,1,0,37.9,180c2,3.4,4.2,6.7,6.5,9.9l.5.7a103.9,103.9,0,0,0,178.2-20.7A7.2,7.2,0,0,0,224,167.9ZM91.1,48.1a89.1,89.1,0,0,1,24.3-7.2,88.1,88.1,0,0,1,50,79.1H132.6ZM132.6,136h83a89.3,89.3,0,0,1-5.9,24.6,88.2,88.2,0,0,1-93.5,3.8Zm71.6-52a87.2,87.2,0,0,1,11.4,36H181.4a105,105,0,0,0-35.7-78.2A88,88,0,0,1,204.2,84ZM77.3,56.1,94.4,85.8a104.4,104.4,0,0,0-49.9,70.1A88.2,88.2,0,0,1,77.3,56.1ZM58.9,182.5a88,88,0,0,1,43.5-82.9L118.8,128,77.3,199.9A84.2,84.2,0,0,1,58.9,182.5ZM172,204.2a87.9,87.9,0,0,1-80.9,3.6l17.1-29.6a104.4,104.4,0,0,0,85.6,8.2A88.4,88.4,0,0,1,172,204.2Z" />
        </>
      )}
    </svg>
  );
}

export function PhWall(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M224,44H32A12,12,0,0,0,20,56V200a12,12,0,0,0,12,12H224a12,12,0,0,0,12-12V56A12,12,0,0,0,224,44ZM92,140V116h72v24Zm-48,0V116H68v24Zm144-24h24v24H188Zm24-24H140V68h72ZM116,68V92H44V68ZM44,164h72v24H44Zm96,24V164h72v24Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M128,104H32V56h96Zm48,0v48H128v48h96V104Z" opacity="0.2" />
          <path d="M224,48H32a8,8,0,0,0-8,8V200a8,8,0,0,0,8,8H224a8,8,0,0,0,8-8V56A8,8,0,0,0,224,48ZM88,144V112h80v32Zm-48,0V112H72v32Zm144-32h32v32H184Zm32-16H136V64h80ZM120,64V96H40V64ZM40,160h80v32H40Zm96,32V160h80v32Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M136,52a4,4,0,0,1,4-4h84a8,8,0,0,1,8,8V88a4,4,0,0,1-4,4H136Zm48,100h44a4,4,0,0,0,4-4V112a4,4,0,0,0-4-4H184Zm-96,0h80V108H88ZM72,108H28a4,4,0,0,0-4,4v36a4,4,0,0,0,4,4H72Zm64,60v36a4,4,0,0,0,4,4h84a8,8,0,0,0,8-8V172a4,4,0,0,0-4-4ZM120,92V52a4,4,0,0,0-4-4H32a8,8,0,0,0-8,8V88a4,4,0,0,0,4,4Zm0,76H28a4,4,0,0,0-4,4v28a8,8,0,0,0,8,8h84a4,4,0,0,0,4-4Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M224,50H32a6,6,0,0,0-6,6V200a6,6,0,0,0,6,6H224a6,6,0,0,0,6-6V56A6,6,0,0,0,224,50ZM86,146V110h84v36Zm-48,0V110H74v36Zm144-36h36v36H182Zm36-12H134V62h84ZM122,62V98H38V62ZM38,158h84v36H38Zm96,36V158h84v36Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M224,52H32a4,4,0,0,0-4,4V200a4,4,0,0,0,4,4H224a4,4,0,0,0,4-4V56A4,4,0,0,0,224,52ZM84,148V108h88v40Zm-48,0V108H76v40Zm144-40h40v40H180Zm40-8H132V60h88ZM124,60v40H36V60ZM36,156h88v40H36Zm96,40V156h88v40Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M224,48H32a8,8,0,0,0-8,8V200a8,8,0,0,0,8,8H224a8,8,0,0,0,8-8V56A8,8,0,0,0,224,48ZM88,144V112h80v32Zm-48,0V112H72v32Zm144-32h32v32H184Zm32-16H136V64h80ZM120,64V96H40V64ZM40,160h80v32H40Zm96,32V160h80v32Z" />
        </>
      )}
    </svg>
  );
}

export function PhWallet(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M164,144a16,16,0,1,1,16,16A16,16,0,0,1,164,144Zm72-48V200a20.1,20.1,0,0,1-20,20H56a28.1,28.1,0,0,1-28-28V68.2A32.1,32.1,0,0,1,60,36H192a12,12,0,0,1,0,24H60a8.4,8.4,0,0,0-5.8,2.4A8.2,8.2,0,0,0,52,68h0v.2A8.4,8.4,0,0,0,60.5,76H216A20.1,20.1,0,0,1,236,96Zm-24,4H60.5A33.5,33.5,0,0,1,52,98.9V192a4,4,0,0,0,4,4H212Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M224,88V200a8,8,0,0,1-8,8H56a16,16,0,0,1-16-16V64A16,16,0,0,0,56,80H216A8,8,0,0,1,224,88Z"
            opacity="0.2"
          />
          <path d="M216,72H56a8,8,0,0,1,0-16H192a8,8,0,0,0,0-16H56A24.1,24.1,0,0,0,32,64V192a24.1,24.1,0,0,0,24,24H216a16,16,0,0,0,16-16V88A16,16,0,0,0,216,72Zm0,128H56a8,8,0,0,1-8-8V86.6A23.6,23.6,0,0,0,56,88H216Zm-48-56a12,12,0,1,1,12,12A12,12,0,0,1,168,144Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M216,72H56a8,8,0,0,1,0-16H192a8,8,0,0,0,0-16H56A24.1,24.1,0,0,0,32,64V192a24.1,24.1,0,0,0,24,24H216a16,16,0,0,0,16-16V88A16,16,0,0,0,216,72Zm-36,84a12,12,0,1,1,12-12A12,12,0,0,1,180,156Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M216,74H56a10,10,0,0,1,0-20H192a6,6,0,0,0,0-12H56A22.1,22.1,0,0,0,34,64V192a22.1,22.1,0,0,0,22,22H216a14,14,0,0,0,14-14V88A14,14,0,0,0,216,74Zm2,126a2,2,0,0,1-2,2H56a10,10,0,0,1-10-10V83.6A22.3,22.3,0,0,0,56,86H216a2,2,0,0,1,2,2Zm-28-56a10,10,0,1,1-10-10A10,10,0,0,1,190,144Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M216,76H56a12,12,0,0,1,0-24H192a4,4,0,0,0,0-8H56A20.1,20.1,0,0,0,36,64V192a20.1,20.1,0,0,0,20,20H216a12,12,0,0,0,12-12V88A12,12,0,0,0,216,76Zm4,124a4,4,0,0,1-4,4H56a12,12,0,0,1-12-12V80a19.9,19.9,0,0,0,12,4H216a4,4,0,0,1,4,4Zm-32-56a8,8,0,1,1-8-8A8,8,0,0,1,188,144Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,72H56a8,8,0,0,1,0-16H192a8,8,0,0,0,0-16H56A24.1,24.1,0,0,0,32,64V192a24.1,24.1,0,0,0,24,24H216a16,16,0,0,0,16-16V88A16,16,0,0,0,216,72Zm0,128H56a8,8,0,0,1-8-8V86.6A23.6,23.6,0,0,0,56,88H216Zm-48-56a12,12,0,1,1,12,12A12,12,0,0,1,168,144Z" />
        </>
      )}
    </svg>
  );
}

export function PhWarningCircle(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,20A108,108,0,1,0,236,128,108.1,108.1,0,0,0,128,20Zm0,192a84,84,0,1,1,84-84A84.1,84.1,0,0,1,128,212Zm-12-80V80a12,12,0,0,1,24,0v52a12,12,0,0,1-24,0Zm28,40a16,16,0,1,1-16-16A16,16,0,0,1,144,172Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="128" cy="128" r="96" />
          </g>
          <path d="M128,24A104,104,0,1,0,232,128,104.1,104.1,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm-8-80V80a8,8,0,0,1,16,0v56a8,8,0,0,1-16,0Zm20,36a12,12,0,1,1-12-12A12,12,0,0,1,140,172Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.1,104.1,0,0,0,128,24Zm-8,56a8,8,0,0,1,16,0v56a8,8,0,0,1-16,0Zm8,104a12,12,0,1,1,12-12A12,12,0,0,1,128,184Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,26A102,102,0,1,0,230,128,102.2,102.2,0,0,0,128,26Zm0,192a90,90,0,1,1,90-90A90.1,90.1,0,0,1,128,218Zm-6-82V80a6,6,0,0,1,12,0v56a6,6,0,0,1-12,0Zm16,36a10,10,0,1,1-10-10A10,10,0,0,1,138,172Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,28A100,100,0,1,0,228,128,100.2,100.2,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm-4-84V80a4,4,0,0,1,8,0v56a4,4,0,0,1-8,0Zm12,36a8,8,0,1,1-8-8A8,8,0,0,1,136,172Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.1,104.1,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm-8-80V80a8,8,0,0,1,16,0v56a8,8,0,0,1-16,0Zm20,36a12,12,0,1,1-12-12A12,12,0,0,1,140,172Z" />
        </>
      )}
    </svg>
  );
}

export function PhWarningOctagon(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M116,132V80a12,12,0,0,1,24,0v52a12,12,0,0,1-24,0ZM236,91.5v72.9a19.9,19.9,0,0,1-5.9,14.2l-51.5,51.5a19.8,19.8,0,0,1-14.1,5.9h-73a19.8,19.8,0,0,1-14.1-5.9L25.9,178.6A19.9,19.9,0,0,1,20,164.4V91.5a19.8,19.8,0,0,1,5.9-14.1L77.4,25.9A19.8,19.8,0,0,1,91.5,20h73a19.8,19.8,0,0,1,14.1,5.9l51.5,51.5A19.8,19.8,0,0,1,236,91.5Zm-24,1.7L162.8,44H93.2L44,93.2v69.6L93.2,212h69.6L212,162.8ZM128,156a16,16,0,1,0,16,16A16,16,0,0,0,128,156Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M164.5,32h-73a7.9,7.9,0,0,0-5.6,2.3L34.3,85.9A7.9,7.9,0,0,0,32,91.5v72.9a7.9,7.9,0,0,0,2.3,5.7l51.6,51.6a7.9,7.9,0,0,0,5.6,2.3h73a7.9,7.9,0,0,0,5.6-2.3l51.6-51.6a7.9,7.9,0,0,0,2.3-5.7V91.5a7.9,7.9,0,0,0-2.3-5.6L170.1,34.3A7.9,7.9,0,0,0,164.5,32Z" />
          </g>
          <path d="M120,136V80a8,8,0,0,1,16,0v56a8,8,0,0,1-16,0Zm8,24a12,12,0,1,0,12,12A12,12,0,0,0,128,160ZM232,91.5v72.9a16.3,16.3,0,0,1-4.7,11.4l-51.5,51.5a16.2,16.2,0,0,1-11.3,4.7h-73a16.2,16.2,0,0,1-11.3-4.7L28.7,175.8A16.3,16.3,0,0,1,24,164.4V91.5a16.2,16.2,0,0,1,4.7-11.3L80.2,28.7A16.2,16.2,0,0,1,91.5,24h73a16.2,16.2,0,0,1,11.3,4.7l51.5,51.5A16.2,16.2,0,0,1,232,91.5Zm-16,0L164.5,40h-73L40,91.5v72.9L91.5,216h73L216,164.4Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M227.3,80.2,175.8,28.7A16.1,16.1,0,0,0,164.5,24h-73a16.1,16.1,0,0,0-11.3,4.7L28.7,80.2A16.1,16.1,0,0,0,24,91.5v72.9a16.1,16.1,0,0,0,4.7,11.4l51.5,51.5A16.1,16.1,0,0,0,91.5,232h73a16.1,16.1,0,0,0,11.3-4.7l51.5-51.5a16.1,16.1,0,0,0,4.7-11.4V91.5A16.1,16.1,0,0,0,227.3,80.2ZM120,80a8,8,0,0,1,16,0v56a8,8,0,0,1-16,0Zm8,104a12,12,0,1,1,12-12A12,12,0,0,1,128,184Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M122,136V80a6,6,0,0,1,12,0v56a6,6,0,0,1-12,0ZM230,91.5v72.9a13.9,13.9,0,0,1-4.1,9.9l-51.6,51.6a13.9,13.9,0,0,1-9.8,4.1h-73a14.3,14.3,0,0,1-9.9-4.1L30.1,174.3a13.9,13.9,0,0,1-4.1-9.9V91.5a14.3,14.3,0,0,1,4.1-9.9L81.7,30.1A13.9,13.9,0,0,1,91.5,26h73a14.3,14.3,0,0,1,9.9,4.1l51.5,51.5A14.3,14.3,0,0,1,230,91.5Zm-12,0a2,2,0,0,0-.6-1.4L165.9,38.6a2,2,0,0,0-1.4-.6h-73a2,2,0,0,0-1.4.6L38.6,90.1a2,2,0,0,0-.6,1.4v72.9a2,2,0,0,0,.6,1.5l51.5,51.5a2,2,0,0,0,1.4.6h73a2,2,0,0,0,1.4-.6l51.5-51.5a2,2,0,0,0,.6-1.5ZM128,162a10,10,0,1,0,10,10A10,10,0,0,0,128,162Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M124,136V80a4,4,0,0,1,8,0v56a4,4,0,0,1-8,0ZM228,91.5v72.9a11.5,11.5,0,0,1-3.5,8.5l-51.6,51.6a11.9,11.9,0,0,1-8.4,3.5h-73a11.9,11.9,0,0,1-8.4-3.5L31.5,172.9a11.5,11.5,0,0,1-3.5-8.5V91.5a11.5,11.5,0,0,1,3.5-8.4L83.1,31.5A11.5,11.5,0,0,1,91.5,28h73a11.5,11.5,0,0,1,8.4,3.5l51.6,51.6A11.5,11.5,0,0,1,228,91.5Zm-8,0a3.9,3.9,0,0,0-1.2-2.8L167.3,37.2a3.9,3.9,0,0,0-2.8-1.2h-73a3.9,3.9,0,0,0-2.8,1.2L37.2,88.7A3.9,3.9,0,0,0,36,91.5v72.9a4,4,0,0,0,1.2,2.9l51.5,51.5a3.9,3.9,0,0,0,2.8,1.2h73a3.9,3.9,0,0,0,2.8-1.2l51.5-51.5a4,4,0,0,0,1.2-2.9ZM128,164a8,8,0,1,0,8,8A8,8,0,0,0,128,164Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M120,136V80a8,8,0,0,1,16,0v56a8,8,0,0,1-16,0ZM232,91.5v72.9a16.3,16.3,0,0,1-4.7,11.4l-51.5,51.5a16.2,16.2,0,0,1-11.3,4.7h-73a16.2,16.2,0,0,1-11.3-4.7L28.7,175.8A16.3,16.3,0,0,1,24,164.4V91.5a16.2,16.2,0,0,1,4.7-11.3L80.2,28.7A16.2,16.2,0,0,1,91.5,24h73a16.2,16.2,0,0,1,11.3,4.7l51.5,51.5A16.2,16.2,0,0,1,232,91.5Zm-16,0L164.5,40h-73L40,91.5v72.9L91.5,216h73L216,164.4ZM128,160a12,12,0,1,0,12,12A12,12,0,0,0,128,160Z" />
        </>
      )}
    </svg>
  );
}

export function PhWarning(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M116,136V104a12,12,0,0,1,24,0v32a12,12,0,0,1-24,0Zm124.2,78A27.5,27.5,0,0,1,216,228H40a28,28,0,0,1-24.2-42l88-152h0a27.9,27.9,0,0,1,48.4,0l88,152A27.5,27.5,0,0,1,240.2,214Zm-20.8-16L131.5,46a4.1,4.1,0,0,0-7,0h0L36.6,198a3.8,3.8,0,0,0,0,4,3.8,3.8,0,0,0,3.4,2H216a3.8,3.8,0,0,0,3.4-2A3.8,3.8,0,0,0,219.4,198ZM128,160a16,16,0,1,0,16,16A16,16,0,0,0,128,160Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M114.2,40l-88,152A16,16,0,0,0,40,216H216a16,16,0,0,0,13.8-24l-88-152A15.9,15.9,0,0,0,114.2,40Z" />
          </g>
          <path d="M120,144V112a8,8,0,0,1,16,0v32a8,8,0,0,1-16,0Zm116.8,68A23.9,23.9,0,0,1,216,224H40a23.9,23.9,0,0,1-20.7-36L107.2,36a24,24,0,0,1,41.6,0l87.9,152A23.7,23.7,0,0,1,236.8,212Zm-13.9-16-88-152a8,8,0,0,0-13.8,0h0l-88,152a7.8,7.8,0,0,0,0,8,7.9,7.9,0,0,0,6.9,4H216a7.9,7.9,0,0,0,6.9-4A7.8,7.8,0,0,0,222.9,196ZM128,168a12,12,0,1,0,12,12A12,12,0,0,0,128,168Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M236.7,188,148.8,36a24,24,0,0,0-41.6,0h0L19.3,188A23.9,23.9,0,0,0,40,224H216a23.9,23.9,0,0,0,20.7-36ZM120,104a8,8,0,0,1,16,0v40a8,8,0,0,1-16,0Zm8,88a12,12,0,1,1,12-12A12,12,0,0,1,128,192Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M122,144V104a6,6,0,0,1,12,0v40a6,6,0,0,1-12,0Zm113,67a21.6,21.6,0,0,1-19,11H40a22,22,0,0,1-19-33L109,37a21.9,21.9,0,0,1,38,0l88,152A21.7,21.7,0,0,1,235,211Zm-10.4-16L136.7,43a10.1,10.1,0,0,0-17.4,0h0L31.4,195A10,10,0,0,0,40,210H216a10,10,0,0,0,8.6-15ZM128,170a10,10,0,1,0,10,10A10,10,0,0,0,128,170Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M124,144V104a4,4,0,0,1,8,0v40a4,4,0,0,1-8,0Zm109.3,66A19.7,19.7,0,0,1,216,220H40a20,20,0,0,1-17.3-30l88-152a20,20,0,0,1,34.6,0l88,152A19.9,19.9,0,0,1,233.3,210Zm-6.9-16-88-152a12,12,0,0,0-20.8,0l-88,152a12.2,12.2,0,0,0,0,12A12.1,12.1,0,0,0,40,212H216a12.1,12.1,0,0,0,10.4-6A12.2,12.2,0,0,0,226.4,194ZM128,172a8,8,0,1,0,8,8A8,8,0,0,0,128,172Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M120,144V104a8,8,0,0,1,16,0v40a8,8,0,0,1-16,0Zm116.8,68A23.9,23.9,0,0,1,216,224H40a23.9,23.9,0,0,1-20.7-36L107.2,36a24,24,0,0,1,41.6,0l87.9,152A23.7,23.7,0,0,1,236.8,212Zm-13.9-16-88-152a8,8,0,0,0-13.8,0h0l-88,152a7.8,7.8,0,0,0,0,8,7.9,7.9,0,0,0,6.9,4H216a7.9,7.9,0,0,0,6.9-4A7.8,7.8,0,0,0,222.9,196ZM128,168a12,12,0,1,0,12,12A12,12,0,0,0,128,168Z" />
        </>
      )}
    </svg>
  );
}

export function PhWatch(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M212,128a83.9,83.9,0,0,0-32.9-66.6L172.9,21A19.8,19.8,0,0,0,153.1,4H102.9A19.8,19.8,0,0,0,83.1,21L76.9,61.4a83.9,83.9,0,0,0,0,133.2L83.1,235a19.8,19.8,0,0,0,19.8,17h50.2a19.8,19.8,0,0,0,19.8-17l6.2-40.4A83.9,83.9,0,0,0,212,128ZM106.3,28h43.4l3,19.7a84.3,84.3,0,0,0-49.4,0ZM68,128a60,60,0,1,1,60,60A60,60,0,0,1,68,128Zm81.7,100H106.3l-3-19.7a84.3,84.3,0,0,0,49.4,0ZM116,128V96a12,12,0,0,1,24,0v20h20a12,12,0,0,1,0,24H128A12,12,0,0,1,116,128Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="128" cy="128" r="72" />
          </g>
          <path d="M208,128a80.1,80.1,0,0,0-32.7-64.5l-6.2-34.4A16,16,0,0,0,153.3,16H102.7A16,16,0,0,0,86.9,29.1L80.7,63.5a80,80,0,0,0,0,129l6.2,34.4A16,16,0,0,0,102.7,240h50.6a16,16,0,0,0,15.8-13.1l6.2-34.4A80.1,80.1,0,0,0,208,128ZM102.7,32h50.6l3.9,21.6a78.9,78.9,0,0,0-58.4,0ZM64,128a64,64,0,1,1,64,64A64.1,64.1,0,0,1,64,128Zm89.3,96H102.7l-3.9-21.6a78.9,78.9,0,0,0,58.4,0Z" />
          <path d="M128,136h40a8,8,0,0,0,0-16H136V88a8,8,0,0,0-16,0v40A8,8,0,0,0,128,136Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M175.3,63.5l-6.2-34.4A16,16,0,0,0,153.3,16H102.7A16,16,0,0,0,86.9,29.1L80.7,63.5a80,80,0,0,0,0,129l6.2,34.4A16,16,0,0,0,102.7,240h50.6a16,16,0,0,0,15.8-13.1l6.2-34.4a80,80,0,0,0,0-129ZM102.7,32h50.6l3.9,21.6a78.9,78.9,0,0,0-58.4,0Zm50.6,192H102.7l-3.9-21.6a78.9,78.9,0,0,0,58.4,0ZM168,136H128a8,8,0,0,1-8-8V88a8,8,0,0,1,16,0v32h32a8,8,0,0,1,0,16Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M206,128a77.9,77.9,0,0,0-32.5-63.3l-6.4-35.2A14,14,0,0,0,153.3,18H102.7A14,14,0,0,0,88.9,29.5L82.5,64.7a77.9,77.9,0,0,0,0,126.6l6.4,35.2A14,14,0,0,0,102.7,238h50.6a14,14,0,0,0,13.8-11.5l6.4-35.2A77.9,77.9,0,0,0,206,128ZM100.7,31.6a2,2,0,0,1,2-1.6h50.6a2,2,0,0,1,2,1.6l4.6,25.2a78.2,78.2,0,0,0-63.8,0Zm54.6,192.8a2,2,0,0,1-2,1.6H102.7a2,2,0,0,1-2-1.6l-4.6-25.2a78.2,78.2,0,0,0,63.8,0ZM128,194a66,66,0,1,1,66-66A66.1,66.1,0,0,1,128,194Zm46-66a6,6,0,0,1-6,6H128a6,6,0,0,1-6-6V88a6,6,0,0,1,12,0v34h34A6,6,0,0,1,174,128Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M204,128a76.1,76.1,0,0,0-32.3-62.2l-6.6-35.9A11.9,11.9,0,0,0,153.3,20H102.7a11.9,11.9,0,0,0-11.8,9.9L84.3,65.8a76,76,0,0,0,0,124.4l6.6,35.9a11.9,11.9,0,0,0,11.8,9.9h50.6a11.9,11.9,0,0,0,11.8-9.9l6.6-35.9A76.1,76.1,0,0,0,204,128ZM98.7,31.3a4.1,4.1,0,0,1,4-3.3h50.6a4.1,4.1,0,0,1,4,3.3l5.2,29a75.9,75.9,0,0,0-69,0Zm58.6,193.4a4.1,4.1,0,0,1-4,3.3H102.7a4.1,4.1,0,0,1-4-3.3l-5.2-29a75.9,75.9,0,0,0,69,0ZM128,196a68,68,0,1,1,68-68A68.1,68.1,0,0,1,128,196Zm44-68a4,4,0,0,1-4,4H128a4,4,0,0,1-4-4V88a4,4,0,0,1,8,0v36h36A4,4,0,0,1,172,128Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M208,128a80.1,80.1,0,0,0-32.7-64.5l-6.2-34.4A16,16,0,0,0,153.3,16H102.7A16,16,0,0,0,86.9,29.1L80.7,63.5a80,80,0,0,0,0,129l6.2,34.4A16,16,0,0,0,102.7,240h50.6a16,16,0,0,0,15.8-13.1l6.2-34.4A80.1,80.1,0,0,0,208,128ZM102.7,32h50.6l3.9,21.6a78.9,78.9,0,0,0-58.4,0ZM64,128a64,64,0,1,1,64,64A64.1,64.1,0,0,1,64,128Zm89.3,96H102.7l-3.9-21.6a78.9,78.9,0,0,0,58.4,0ZM120,128V88a8,8,0,0,1,16,0v32h32a8,8,0,0,1,0,16H128A8,8,0,0,1,120,128Z" />
        </>
      )}
    </svg>
  );
}

export function PhWaveSawtooth(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M238.3,138.2l-104,64A11.8,11.8,0,0,1,128,204a12.6,12.6,0,0,1-5.9-1.5A12.2,12.2,0,0,1,116,192V85.5L30.3,138.2a12,12,0,0,1-12.6-20.4l104-64A11.9,11.9,0,0,1,140,64V170.5l85.7-52.7a12,12,0,0,1,12.6,20.4Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M128,192V128H232ZM24,128H128V64Z" opacity="0.2" />
          <path d="M236.2,134.8l-104,64a8,8,0,0,1-8.1.2,8.1,8.1,0,0,1-4.1-7V78.3L28.2,134.8a8,8,0,0,1-8.4-13.6l104-64A8,8,0,0,1,136,64V177.7l91.8-56.5a8,8,0,0,1,8.4,13.6Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M236.2,134.8l-104,64a8,8,0,0,1-8.1.2,8.1,8.1,0,0,1-4.1-7V78.3L28.2,134.8a8,8,0,0,1-8.4-13.6l104-64A8,8,0,0,1,136,64V177.7l91.8-56.5a8,8,0,0,1,8.4,13.6Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M235.1,133.1l-104,64a5.6,5.6,0,0,1-3.1.9,6,6,0,0,1-2.9-.8A5.9,5.9,0,0,1,122,192V74.7L27.1,133.1a6,6,0,1,1-6.2-10.2l104-64A6,6,0,0,1,134,64V181.3l94.9-58.4a6,6,0,1,1,6.2,10.2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M234.1,131.4l-104,64a3.9,3.9,0,0,1-2.1.6,4.9,4.9,0,0,1-2-.5,4.2,4.2,0,0,1-2-3.5V71.2L26.1,131.4a4,4,0,0,1-4.2-6.8l104-64a4,4,0,0,1,4.1-.1,4.2,4.2,0,0,1,2,3.5V184.8l97.9-60.2a4,4,0,0,1,4.2,6.8Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M236.2,134.8l-104,64a8,8,0,0,1-8.1.2,8.1,8.1,0,0,1-4.1-7V78.3L28.2,134.8a8,8,0,0,1-8.4-13.6l104-64A8,8,0,0,1,136,64V177.7l91.8-56.5a8,8,0,0,1,8.4,13.6Z" />
        </>
      )}
    </svg>
  );
}

export function PhWaveSine(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M242.9,133.1c-23,49.5-43,71.6-64.9,71.6-27.6,0-43.8-34.8-60.9-71.6-7.2-15.5-14.6-31.5-22.2-42.9C88.5,80.6,82.4,75.3,78,75.3s-18.3,4.2-43.1,57.8a12,12,0,0,1-21.8-10.2c23-49.5,43-71.6,64.9-71.6,27.6,0,43.8,34.8,60.9,71.6,7.2,15.5,14.6,31.5,22.2,42.9,6.4,9.6,12.5,14.9,16.9,14.9s18.3-4.2,43.1-57.8a12,12,0,1,1,21.8,10.2Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M128,128H24C76,16,102,72,128,128Zm0,0c26,56,52,112,104,0Z"
            opacity="0.2"
          />
          <path d="M239.3,131.4c-22,47.2-41.4,69.3-61.3,69.3-25.1,0-40.7-33.7-57.3-69.3-13-28.2-27.8-60.1-42.7-60.1s-36.3,37.6-46.7,60.1a8.1,8.1,0,1,1-14.6-6.8C38.7,77.4,58.1,55.3,78,55.3c25.1,0,40.7,33.7,57.3,69.3,13,28.2,27.8,60.1,42.7,60.1,16.4,0,36.3-37.6,46.7-60.1a8.1,8.1,0,0,1,14.6,6.8Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M178,200.7c-25.1,0-40.7-33.7-57.3-69.3-13-28.2-27.8-60.1-42.7-60.1s-36.3,37.6-46.7,60.1a8.1,8.1,0,1,1-14.6-6.8C38.7,77.4,58.1,55.3,78,55.3c25.1,0,40.7,33.7,57.3,69.3,13,28.2,27.8,60.1,42.7,60.1,16.4,0,36.3-37.6,46.7-60.1a8.1,8.1,0,0,1,14.6,6.8C217.3,178.6,197.9,200.7,178,200.7Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M237.4,130.5C215.9,177,197,198.7,178,198.7c-23.8,0-39.2-33.1-55.4-68.2C109.2,101.8,94.1,69.3,78,69.3c-9.2,0-25.1,10.7-48.6,61.2a6,6,0,1,1-10.8-5C40.1,79,59,57.3,78,57.3c23.8,0,39.2,33.1,55.4,68.2,13.4,28.7,28.5,61.2,44.6,61.2,9.2,0,25.1-10.7,48.6-61.2a6,6,0,1,1,10.8,5Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M235.6,129.7c-21.2,45.7-39.5,67-57.6,67-22.5,0-37.6-32.6-53.6-67C110.1,99,95.4,67.3,78,67.3c-14.2,0-31.2,21-50.4,62.4a4,4,0,0,1-7.2-3.4c21.2-45.7,39.5-67,57.6-67,22.5,0,37.6,32.6,53.6,67,14.3,30.7,29,62.4,46.4,62.4,14.2,0,31.2-21,50.4-62.4a4,4,0,1,1,7.2,3.4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M239.3,131.4c-22,47.2-41.4,69.3-61.3,69.3-25.1,0-40.7-33.7-57.3-69.3-13-28.2-27.8-60.1-42.7-60.1s-36.3,37.6-46.7,60.1a8.1,8.1,0,1,1-14.6-6.8C38.7,77.4,58.1,55.3,78,55.3c25.1,0,40.7,33.7,57.3,69.3,13,28.2,27.8,60.1,42.7,60.1,16.4,0,36.3-37.6,46.7-60.1a8.1,8.1,0,0,1,14.6,6.8Z" />
        </>
      )}
    </svg>
  );
}

export function PhWaveSquare(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M244,128v56a12,12,0,0,1-12,12H128a12,12,0,0,1-12-12V84H36v44a12,12,0,0,1-24,0V72A12,12,0,0,1,24,60H128a12,12,0,0,1,12,12V172h80V128a12,12,0,0,1,24,0Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M128,72v56H24V72Zm0,56v56H232V128Z" opacity="0.2" />
          <path d="M240,128v56a8,8,0,0,1-8,8H128a8,8,0,0,1-8-8V80H32v48a8,8,0,0,1-16,0V72a8,8,0,0,1,8-8H128a8,8,0,0,1,8,8V176h88V128a8,8,0,0,1,16,0Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M240,128v56a8,8,0,0,1-8,8H128a8,8,0,0,1-8-8V80H32v48a8,8,0,0,1-16,0V72a8,8,0,0,1,8-8H128a8,8,0,0,1,8,8V176h88V128a8,8,0,0,1,16,0Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M238,128v56a6,6,0,0,1-6,6H128a6,6,0,0,1-6-6V78H30v50a6,6,0,0,1-12,0V72a6,6,0,0,1,6-6H128a6,6,0,0,1,6,6V178h92V128a6,6,0,0,1,12,0Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M236,128v56a4,4,0,0,1-4,4H128a4,4,0,0,1-4-4V76H28v52a4,4,0,0,1-8,0V72a4,4,0,0,1,4-4H128a4,4,0,0,1,4,4V180h96V128a4,4,0,0,1,8,0Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M240,128v56a8,8,0,0,1-8,8H128a8,8,0,0,1-8-8V80H32v48a8,8,0,0,1-16,0V72a8,8,0,0,1,8-8H128a8,8,0,0,1,8,8V176h88V128a8,8,0,0,1,16,0Z" />
        </>
      )}
    </svg>
  );
}

export function PhWaveTriangle(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M241.7,135l-52,72a11.9,11.9,0,0,1-19.4,0L76,76.5,33.7,135a12,12,0,1,1-19.4-14l52-72a11.9,11.9,0,0,1,19.4,0L180,179.5,222.3,121a12,12,0,0,1,19.4,14Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M76,56l52,72H24Zm156,72H128l52,72Z" opacity="0.2" />
          <path d="M238.5,132.7l-52,72a8.1,8.1,0,0,1-13,0L76,69.7l-45.5,63a8,8,0,1,1-13-9.4l52-72a8.1,8.1,0,0,1,13,0l97.5,135,45.5-63a8,8,0,0,1,13,9.4Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M238.5,132.7l-52,72a8.1,8.1,0,0,1-13,0L76,69.7l-45.5,63a8,8,0,1,1-13-9.4l52-72a8.1,8.1,0,0,1,13,0l97.5,135,45.5-63a8,8,0,0,1,13,9.4Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M236.9,131.5l-52,72a6.1,6.1,0,0,1-9.8,0L76,66.2,28.9,131.5a6,6,0,1,1-9.8-7l52-72a6.1,6.1,0,0,1,9.8,0L180,189.8l47.1-65.3a6,6,0,0,1,9.8,7Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M235.2,130.3l-52,72a3.9,3.9,0,0,1-6.4,0L76,62.8,27.2,130.3a3.9,3.9,0,1,1-6.4-4.6l52-72a3.9,3.9,0,0,1,6.4,0L180,193.2l48.8-67.5a3.9,3.9,0,0,1,6.4,4.6Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M238.5,132.7l-52,72a8.1,8.1,0,0,1-13,0L76,69.7l-45.5,63a8,8,0,1,1-13-9.4l52-72a8.1,8.1,0,0,1,13,0l97.5,135,45.5-63a8,8,0,0,1,13,9.4Z" />
        </>
      )}
    </svg>
  );
}

export function PhWaves(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M30.6,79.5a12.1,12.1,0,0,1,1.9-16.9c1.2-1,12.6-9.7,30.6-14.2s43.1-5.3,71.6,13.6c40.2,26.8,73.5.9,73.8.6a12,12,0,1,1,15,18.8c-1.2,1-12.6,9.7-30.6,14.2a84.1,84.1,0,0,1-20.7,2.6c-14.7,0-32.3-3.8-50.9-16.2-40.2-26.8-73.5-.9-73.8-.6A12.1,12.1,0,0,1,30.6,79.5Zm177.9,39.1c-.3.3-33.6,26.2-73.8-.6C106.2,99.1,80,100.1,63.1,104.4s-29.4,13.2-30.6,14.2a12,12,0,1,0,15,18.8c.3-.3,33.6-26.2,73.8.6,18.6,12.4,36.2,16.2,50.9,16.2a84.1,84.1,0,0,0,20.7-2.6c18-4.5,29.4-13.2,30.6-14.2a12,12,0,0,0-15-18.8Zm0,56c-.3.3-33.6,26.2-73.8-.6-28.5-18.9-54.7-17.9-71.6-13.6s-29.4,13.2-30.6,14.2a12,12,0,0,0,15,18.8c.3-.3,33.6-26.2,73.8.6,18.6,12.4,36.2,16.2,50.9,16.2a84.1,84.1,0,0,0,20.7-2.6c18-4.5,29.4-13.2,30.6-14.2a12,12,0,0,0-15-18.8Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M33.7,77A8,8,0,0,1,35,65.8,81.8,81.8,0,0,1,64.1,52.2c16.1-4,41.1-5,68.3,13.1,42.4,28.3,77.2,1.6,78.6.4A8.2,8.2,0,0,1,222.3,67,8,8,0,0,1,221,78.2a81.8,81.8,0,0,1-29.1,13.6,81,81,0,0,1-19.7,2.4c-14,0-30.8-3.7-48.6-15.5C81.2,50.4,46.4,77.1,45,78.3A8.2,8.2,0,0,1,33.7,77ZM211,121.7c-1.4,1.2-36.2,27.9-78.6-.4-27.2-18.1-52.2-17.1-68.3-13.1A81.8,81.8,0,0,0,35,121.8,8,8,0,0,0,33.7,133,8.2,8.2,0,0,0,45,134.3c1.4-1.2,36.2-27.9,78.6.4,17.8,11.8,34.6,15.5,48.6,15.5a81,81,0,0,0,19.7-2.4A81.8,81.8,0,0,0,221,134.2a8,8,0,0,0,1.3-11.2A8.2,8.2,0,0,0,211,121.7Zm0,56c-1.4,1.2-36.2,27.9-78.6-.4-27.2-18.1-52.2-17.1-68.3-13.1A81.8,81.8,0,0,0,35,177.8,8,8,0,0,0,33.7,189,8.2,8.2,0,0,0,45,190.3c1.4-1.2,36.2-27.9,78.6.4,17.8,11.8,34.6,15.5,48.6,15.5a82.6,82.6,0,0,0,48.8-16,8,8,0,0,0,1.3-11.2A8.2,8.2,0,0,0,211,177.7Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M33.7,77A8,8,0,0,1,35,65.8,81.8,81.8,0,0,1,64.1,52.2c16.1-4,41.1-5,68.3,13.1,42.4,28.3,77.2,1.6,78.6.4A8.2,8.2,0,0,1,222.3,67,8,8,0,0,1,221,78.2a81.8,81.8,0,0,1-29.1,13.6,81,81,0,0,1-19.7,2.4c-14,0-30.8-3.7-48.6-15.5C81.2,50.4,46.4,77.1,45,78.3A8.2,8.2,0,0,1,33.7,77ZM211,121.7c-1.4,1.2-36.2,27.9-78.6-.4-27.2-18.1-52.2-17.1-68.3-13.1A81.8,81.8,0,0,0,35,121.8,8,8,0,0,0,33.7,133,8.2,8.2,0,0,0,45,134.3c1.4-1.2,36.2-27.9,78.6.4,17.8,11.8,34.6,15.5,48.6,15.5a81,81,0,0,0,19.7-2.4A81.8,81.8,0,0,0,221,134.2a8,8,0,0,0,1.3-11.2A8.2,8.2,0,0,0,211,121.7Zm0,56c-1.4,1.2-36.2,27.9-78.6-.4-27.2-18.1-52.2-17.1-68.3-13.1A81.8,81.8,0,0,0,35,177.8,8,8,0,0,0,33.7,189,8.2,8.2,0,0,0,45,190.3c1.4-1.2,36.2-27.9,78.6.4,17.8,11.8,34.6,15.5,48.6,15.5a82.6,82.6,0,0,0,48.8-16,8,8,0,0,0,1.3-11.2A8.2,8.2,0,0,0,211,177.7Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M35.3,75.7a6,6,0,0,1,1-8.4A79,79,0,0,1,64.5,54.2c15.8-4,40.2-4.9,66.8,12.8,43.6,29.1,79.5,1.5,81,.3a6,6,0,0,1,7.4,9.4,79,79,0,0,1-28.2,13.1,78.2,78.2,0,0,1-19.3,2.4c-13.7,0-30.1-3.6-47.5-15.2-43.6-29.1-79.5-1.5-81-.3A6,6,0,0,1,35.3,75.7Zm177,47.6c-1.5,1.2-37.4,28.8-81-.3-26.6-17.7-51-16.8-66.8-12.8a79,79,0,0,0-28.2,13.1,6,6,0,0,0,7.4,9.4c1.5-1.2,37.4-28.8,81,.3,17.4,11.6,33.8,15.2,47.5,15.2a78.2,78.2,0,0,0,19.3-2.4,79,79,0,0,0,28.2-13.1,6,6,0,0,0-7.4-9.4Zm0,56c-1.5,1.2-37.4,28.8-81-.3-26.6-17.7-51-16.8-66.8-12.8a79,79,0,0,0-28.2,13.1,6,6,0,0,0,7.4,9.4c1.5-1.2,37.4-28.8,81,.3,17.4,11.6,33.8,15.2,47.5,15.2a81.6,81.6,0,0,0,47.5-15.5,6,6,0,0,0-7.4-9.4Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M36.9,74.5a4,4,0,0,1,.6-5.6c.4-.4,10.9-8.6,27.5-12.8s39.2-4.8,65.2,12.6c18.8,12.5,38.5,16.4,58.5,11.5a72.4,72.4,0,0,0,24.8-11.3,4,4,0,0,1,5,6.2c-.4.4-10.9,8.6-27.5,12.8a79,79,0,0,1-18.7,2.3c-13.4,0-29.5-3.5-46.5-14.9C107,62.8,87.3,58.9,67.3,63.8A72.4,72.4,0,0,0,42.5,75.1,4,4,0,0,1,36.9,74.5Zm176.6,50.4a72.4,72.4,0,0,1-24.8,11.3c-20,4.9-39.7,1-58.5-11.5-26-17.4-49.8-16.4-65.2-12.6s-27.1,12.4-27.5,12.8a4,4,0,1,0,5,6.2,72.4,72.4,0,0,1,24.8-11.3c20-4.9,39.7-1,58.5,11.5,17,11.4,33.1,14.9,46.5,14.9a79,79,0,0,0,18.7-2.3c16.6-4.2,27.1-12.4,27.5-12.8a4,4,0,0,0-5-6.2Zm0,56a72.4,72.4,0,0,1-24.8,11.3c-20,4.9-39.7,1-58.5-11.5-26-17.4-49.8-16.4-65.2-12.6s-27.1,12.4-27.5,12.8a4,4,0,0,0,5,6.2,72.4,72.4,0,0,1,24.8-11.3c20-4.9,39.7-1,58.5,11.5,17,11.4,33.1,14.9,46.5,14.9a79,79,0,0,0,18.7-2.3c16.6-4.2,27.1-12.4,27.5-12.8a4,4,0,0,0-5-6.2Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M33.7,77A8,8,0,0,1,35,65.8,81.8,81.8,0,0,1,64.1,52.2c16.1-4,41.1-5,68.3,13.1,42.4,28.3,77.2,1.6,78.6.4A8.2,8.2,0,0,1,222.3,67,8,8,0,0,1,221,78.2a81.8,81.8,0,0,1-29.1,13.6,81,81,0,0,1-19.7,2.4c-14,0-30.8-3.7-48.6-15.5C81.2,50.4,46.4,77.1,45,78.3A8.2,8.2,0,0,1,33.7,77ZM211,121.7c-1.4,1.2-36.2,27.9-78.6-.4-27.2-18.1-52.2-17.1-68.3-13.1A81.8,81.8,0,0,0,35,121.8,8,8,0,0,0,33.7,133,8.2,8.2,0,0,0,45,134.3c1.4-1.2,36.2-27.9,78.6.4,17.8,11.8,34.6,15.5,48.6,15.5a81,81,0,0,0,19.7-2.4A81.8,81.8,0,0,0,221,134.2a8,8,0,0,0,1.3-11.2A8.2,8.2,0,0,0,211,121.7Zm0,56c-1.4,1.2-36.2,27.9-78.6-.4-27.2-18.1-52.2-17.1-68.3-13.1A81.8,81.8,0,0,0,35,177.8,8,8,0,0,0,33.7,189,8.2,8.2,0,0,0,45,190.3c1.4-1.2,36.2-27.9,78.6.4,17.8,11.8,34.6,15.5,48.6,15.5a82.6,82.6,0,0,0,48.8-16,8,8,0,0,0,1.3-11.2A8.2,8.2,0,0,0,211,177.7Z" />
        </>
      )}
    </svg>
  );
}

export function PhWebcam(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M168,104a40,40,0,1,0-40,40A40,40,0,0,0,168,104Zm-56,0a16,16,0,1,1,16,16A16,16,0,0,1,112,104Zm112,92H140v-8.9a84,84,0,1,0-24,0V196H32a12,12,0,0,0,0,24H224a12,12,0,0,0,0-24ZM68,104a60,60,0,1,1,60,60A60,60,0,0,1,68,104Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M128,32a72,72,0,1,0,72,72A72,72,0,0,0,128,32Zm0,104a32,32,0,1,1,32-32A32,32,0,0,1,128,136Z"
            opacity="0.2"
          />
          <path d="M168,104a40,40,0,1,0-40,40A40,40,0,0,0,168,104Zm-64,0a24,24,0,1,1,24,24A24.1,24.1,0,0,1,104,104Zm120,96H136V183.6a80,80,0,1,0-16,0V200H32a8,8,0,0,0,0,16H224a8,8,0,0,0,0-16ZM64,104a64,64,0,1,1,64,64A64.1,64.1,0,0,1,64,104Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M160,104a32,32,0,1,1-32-32A32.1,32.1,0,0,1,160,104Zm72,104a8,8,0,0,1-8,8H32a8,8,0,0,1,0-16h88V183.6a80,80,0,1,1,16,0V200h88A8,8,0,0,1,232,208ZM128,152a48,48,0,1,0-48-48A48,48,0,0,0,128,152Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M166,104a38,38,0,1,0-38,38A38,38,0,0,0,166,104Zm-64,0a26,26,0,1,1,26,26A26.1,26.1,0,0,1,102,104Zm122,98H134V181.7a78,78,0,1,0-12,0V202H32a6,6,0,0,0,0,12H224a6,6,0,0,0,0-12ZM62,104a66,66,0,1,1,66,66A66.1,66.1,0,0,1,62,104Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M164,104a36,36,0,1,0-36,36A36,36,0,0,0,164,104Zm-64,0a28,28,0,1,1,28,28A28.1,28.1,0,0,1,100,104ZM224,204H132V179.9a76,76,0,1,0-8,0V204H32a4,4,0,0,0,0,8H224a4,4,0,0,0,0-8ZM60,104a68,68,0,1,1,68,68A68.1,68.1,0,0,1,60,104Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M168,104a40,40,0,1,0-40,40A40,40,0,0,0,168,104Zm-64,0a24,24,0,1,1,24,24A24.1,24.1,0,0,1,104,104Zm120,96H136V183.6a80,80,0,1,0-16,0V200H32a8,8,0,0,0,0,16H224a8,8,0,0,0,0-16ZM64,104a64,64,0,1,1,64,64A64.1,64.1,0,0,1,64,104Z" />
        </>
      )}
    </svg>
  );
}

export function PhWhatsappLogo(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,20A108.1,108.1,0,0,0,32.5,178.5l-8.1,28.4a20,20,0,0,0,24.7,24.7l28.4-8.1A108,108,0,1,0,128,20Zm0,192a85.1,85.1,0,0,1-42.9-11.7,11,11,0,0,0-6.1-1.7,12.4,12.4,0,0,0-3.3.4l-26.2,7.5L57,180.3a11.9,11.9,0,0,0-1.3-9.4A85.1,85.1,0,0,1,44,128a84,84,0,1,1,84,84Zm50.3-80.6L165.9,124a19.9,19.9,0,0,0-19.2-.8l-5,2.5a24.3,24.3,0,0,1-11.4-11.4l2.5-5a19.9,19.9,0,0,0-.8-19.2l-7.4-12.4A20,20,0,0,0,107.5,68H104a35.9,35.9,0,0,0-36,35.5A84,84,0,0,0,152,188h.5A35.9,35.9,0,0,0,188,152v-3.5A20,20,0,0,0,178.3,131.4ZM164,152a11.8,11.8,0,0,1-11.6,12H152a60.1,60.1,0,0,1-60-60.4A11.8,11.8,0,0,1,104,92h1.2l5.1,8.5-3.8,7.6a11.9,11.9,0,0,0-.8,8.5,47.8,47.8,0,0,0,33.7,33.7,11.9,11.9,0,0,0,8.5-.8l7.6-3.8,8.5,5.1Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M128,32A96.1,96.1,0,0,0,45.4,177h0L36,210.2a7.9,7.9,0,0,0,9.8,9.8L79,210.6h0A96,96,0,1,0,128,32Zm24.1,152A79.9,79.9,0,0,1,72,103.9,28,28,0,0,1,100,76h0a6.8,6.8,0,0,1,6,3.5l11.7,20.4a8.1,8.1,0,0,1-.1,8.1l-9.4,15.7h0a48,48,0,0,0,24.1,24.1h0l15.7-9.4a8.1,8.1,0,0,1,8.1-.1L176.5,150a6.8,6.8,0,0,1,3.5,6A28.1,28.1,0,0,1,152.1,184Z" />
          </g>
          <path d="M128,24A104,104,0,0,0,36.8,178l-8.5,30A15.9,15.9,0,0,0,48,227.7l30-8.5A104,104,0,1,0,128,24Zm0,192a88.4,88.4,0,0,1-44.9-12.3,8.7,8.7,0,0,0-4.1-1.1,8.3,8.3,0,0,0-2.2.3l-33.2,9.5,9.5-33.2a8.2,8.2,0,0,0-.8-6.3A88,88,0,1,1,128,216Zm52.5-72.9L160,131.4a15.8,15.8,0,0,0-16.1.2L132,138.7A41.4,41.4,0,0,1,117.3,124l7.1-11.9a15.8,15.8,0,0,0,.2-16.1L112.9,75.5A14.9,14.9,0,0,0,100,68a36,36,0,0,0-36,35.9A88.1,88.1,0,0,0,152,192h.1A36,36,0,0,0,188,156,14.9,14.9,0,0,0,180.5,143.1ZM152.1,176H152a72,72,0,0,1-72-72.1A19.9,19.9,0,0,1,99.4,84l11.3,19.9-9.4,15.6a8.1,8.1,0,0,0-.4,7.4,55.4,55.4,0,0,0,28.2,28.2,8.1,8.1,0,0,0,7.4-.4l15.6-9.4L172,156.6A19.9,19.9,0,0,1,152.1,176Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,24A104,104,0,0,0,36.8,178l-8.5,30A15.9,15.9,0,0,0,48,227.7l30-8.5A104,104,0,1,0,128,24Zm24.1,168H152a88.1,88.1,0,0,1-88-88.1A36,36,0,0,1,100,68a14.9,14.9,0,0,1,12.9,7.5L124.6,96a15.8,15.8,0,0,1-.2,16.1L117.3,124A41.4,41.4,0,0,0,132,138.7l11.9-7.1a15.8,15.8,0,0,1,16.1-.2l20.5,11.7A14.9,14.9,0,0,1,188,156,36,36,0,0,1,152.1,192Z" />
          <path d="M136.5,154.7a8.1,8.1,0,0,1-7.4.4,55.4,55.4,0,0,1-28.2-28.2,8.1,8.1,0,0,1,.4-7.4l9.4-15.6L99.4,84A19.9,19.9,0,0,0,80,103.9,72,72,0,0,0,152,176h.1A19.9,19.9,0,0,0,172,156.6l-19.9-11.3Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,26A102,102,0,0,0,39,177.8l-8.8,30.7a13.9,13.9,0,0,0,17.3,17.3L78.2,217A102,102,0,1,0,128,26Zm0,192a90,90,0,0,1-45.9-12.6,6.8,6.8,0,0,0-3.1-.8,4.4,4.4,0,0,0-1.6.2l-33.2,9.5a2,2,0,0,1-2.5-2.5l9.5-33.2a6,6,0,0,0-.6-4.7A90,90,0,1,1,128,218Zm51.5-73.2L159,133.1a14,14,0,0,0-14.1.2L132.1,141A41.4,41.4,0,0,1,115,123.9l7.7-12.8a14,14,0,0,0,.2-14.1L111.2,76.5A13,13,0,0,0,100,70a34,34,0,0,0-34,33.9A86,86,0,0,0,152,190h.1A34,34,0,0,0,186,156,13,13,0,0,0,179.5,144.8ZM152.1,178H152a74.1,74.1,0,0,1-74-74.1A22,22,0,0,1,100,82a1.2,1.2,0,0,1,.8.4l11.7,20.5a2,2,0,0,1-.1,2l-9.3,15.7a5.7,5.7,0,0,0-.4,5.5,54.6,54.6,0,0,0,27.2,27.2,5.7,5.7,0,0,0,5.5-.4l15.7-9.3a2,2,0,0,1,2-.1l20.5,11.7a1.2,1.2,0,0,1,.4.8A22,22,0,0,1,152.1,178Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,28A100,100,0,0,0,41.1,177.5l-9,31.6a12,12,0,0,0,14.8,14.8l31.6-9A100,100,0,1,0,128,28Zm0,192a91.3,91.3,0,0,1-47-12.9,4.6,4.6,0,0,0-2-.5H77.9l-33.2,9.5a4,4,0,0,1-4.9-4.9l9.5-33.2a4.1,4.1,0,0,0-.4-3.1A92,92,0,1,1,128,220Zm50.5-73.5-20.4-11.6a12.1,12.1,0,0,0-12.2.1l-13.8,8.3a45.4,45.4,0,0,1-19.4-19.4l8.3-13.8a12.1,12.1,0,0,0,.1-12.2L109.5,77.5A11,11,0,0,0,100,72a32,32,0,0,0-32,31.9A84,84,0,0,0,152,188h.1A32,32,0,0,0,184,156,11,11,0,0,0,178.5,146.5ZM152.1,180H152a76.1,76.1,0,0,1-76-76.1A24,24,0,0,1,100,80a2.9,2.9,0,0,1,2.5,1.5l11.7,20.4a4.3,4.3,0,0,1,0,4.1l-9.4,15.6a3.8,3.8,0,0,0-.2,3.7,51.9,51.9,0,0,0,26.1,26.1,3.8,3.8,0,0,0,3.7-.2l15.6-9.4a4.3,4.3,0,0,1,4.1,0l20.4,11.7A2.9,2.9,0,0,1,176,156,24,24,0,0,1,152.1,180Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,24A104,104,0,0,0,36.8,178l-8.5,30A15.9,15.9,0,0,0,48,227.7l30-8.5A104,104,0,1,0,128,24Zm0,192a88.4,88.4,0,0,1-44.9-12.3,8.7,8.7,0,0,0-4.1-1.1,8.3,8.3,0,0,0-2.2.3l-33.2,9.5,9.5-33.2a8.2,8.2,0,0,0-.8-6.3A88,88,0,1,1,128,216Zm52.5-72.9L160,131.4a15.8,15.8,0,0,0-16.1.2L132,138.7A41.4,41.4,0,0,1,117.3,124l7.1-11.9a15.8,15.8,0,0,0,.2-16.1L112.9,75.5A14.9,14.9,0,0,0,100,68a36,36,0,0,0-36,35.9A88.1,88.1,0,0,0,152,192h.1A36,36,0,0,0,188,156,14.9,14.9,0,0,0,180.5,143.1ZM152.1,176A72,72,0,0,1,80,103.9,19.9,19.9,0,0,1,99.4,84l11.3,19.9-9.4,15.6a8.1,8.1,0,0,0-.4,7.4,55.4,55.4,0,0,0,28.2,28.2,8.1,8.1,0,0,0,7.4-.4l15.6-9.4L172,156.6A19.9,19.9,0,0,1,152.1,176Z" />
        </>
      )}
    </svg>
  );
}

export function PhWheelchair(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M251.4,188.2a12,12,0,0,0-15.2-7.6l-14.1,4.7-27.4-54.7A12.1,12.1,0,0,0,184,124H108V108h52a12,12,0,0,0,0-24H108V69.7a32,32,0,1,0-24,0v17a76,76,0,1,0,90.4,101.9,12,12,0,1,0-22.2-9A52,52,0,1,1,84,112v24a12,12,0,0,0,12,12h80.6l28.7,57.4A12,12,0,0,0,216,212a12.6,12.6,0,0,0,3.8-.6l24-8A12,12,0,0,0,251.4,188.2ZM96,32a8,8,0,1,1-8,8A8,8,0,0,1,96,32Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="96" cy="40" r="20" />
          </g>
          <path d="M247.6,185.5a8,8,0,0,0-10.1-5.1l-17.4,5.8-28.9-57.8A8.2,8.2,0,0,0,184,124H104V104h56a8,8,0,0,0,0-16H104V66.8a28,28,0,1,0-16,0v23a72,72,0,1,0,87.9,74.7,8,8,0,0,0-16-1A56,56,0,1,1,88,106.3V132a8,8,0,0,0,8,8h83.1l29.7,59.6A8,8,0,0,0,216,204a7.3,7.3,0,0,0,2.5-.4l24-8A8,8,0,0,0,247.6,185.5ZM84,40A12,12,0,1,1,96,52,12,12,0,0,1,84,40Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M242.5,195.6l-24,8a7.3,7.3,0,0,1-2.5.4,8,8,0,0,1-7.2-4.4L179.1,140H96a8,8,0,0,1-8-8V106.3a56,56,0,1,0,71.9,57.2,8,8,0,0,1,16,1A72,72,0,1,1,88,89.8V62.6a24,24,0,1,1,16,0V88h56a8,8,0,0,1,0,16H104v20h80a8.2,8.2,0,0,1,7.2,4.4l28.9,57.8,17.4-5.8a8,8,0,0,1,5,15.2Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M245.7,186.1a6.1,6.1,0,0,0-7.6-3.8L219,188.7l-29.6-59.4A6,6,0,0,0,184,126H102V102.1h58a6,6,0,0,0,0-12H102V65.3a26,26,0,1,0-12,0V91.4a70,70,0,1,0,83.9,73,6,6,0,0,0-5.7-6.4,5.9,5.9,0,0,0-6.3,5.6A58,58,0,1,1,90,103.7V132a6,6,0,0,0,6,6h84.3l30.3,60.7A6.1,6.1,0,0,0,216,202a6.3,6.3,0,0,0,1.9-.3l24-8A6.1,6.1,0,0,0,245.7,186.1ZM82,40A14,14,0,1,1,96,54,14,14,0,0,1,82,40Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M243.8,186.7a4,4,0,0,0-5.1-2.5L218,191.1l-30.4-60.9A4.2,4.2,0,0,0,184,128H100V100.1h60a4,4,0,0,0,0-8H100V63.7a24,24,0,1,0-8,0V93.1a68,68,0,1,0,79.9,71.1,4,4,0,0,0-3.8-4.2,4,4,0,0,0-4.2,3.8A60,60,0,1,1,92,101.2V132a4,4,0,0,0,4,4h85.5l30.9,61.8A4,4,0,0,0,216,200l1.3-.2,24-8A4,4,0,0,0,243.8,186.7ZM80,40A16,16,0,1,1,96,56,16,16,0,0,1,80,40Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M247.6,185.5a8,8,0,0,0-10.1-5.1l-17.4,5.8-28.9-57.8A8.2,8.2,0,0,0,184,124H104V104h56a8,8,0,0,0,0-16H104V66.8a28,28,0,1,0-16,0v23a72,72,0,1,0,87.9,74.7,8,8,0,0,0-16-1A56,56,0,1,1,88,106.3V132a8,8,0,0,0,8,8h83.1l29.7,59.6A8,8,0,0,0,216,204a7.3,7.3,0,0,0,2.5-.4l24-8A8,8,0,0,0,247.6,185.5ZM84,40A12,12,0,1,1,96,52,12,12,0,0,1,84,40Z" />
        </>
      )}
    </svg>
  );
}

export function PhWifiHigh(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M171.4,157.4a12,12,0,0,1,.3,17,12.2,12.2,0,0,1-8.6,3.7,12.5,12.5,0,0,1-8.4-3.4,38.7,38.7,0,0,0-53.4,0,12,12,0,1,1-16.7-17.3,62.8,62.8,0,0,1,86.8,0ZM128,92a109.6,109.6,0,0,0-77.4,31.6,11.9,11.9,0,0,0-.2,16.9,12,12,0,0,0,17,.2,86.7,86.7,0,0,1,121.2,0,11.9,11.9,0,0,0,8.4,3.4,12.1,12.1,0,0,0,8.6-3.6,11.9,11.9,0,0,0-.2-16.9A109.6,109.6,0,0,0,128,92Zm111.4-2.4a158.9,158.9,0,0,0-222.8,0,12,12,0,0,0,16.9,17.1,134.7,134.7,0,0,1,189,0,12,12,0,0,0,8.4,3.5,12,12,0,0,0,8.5-20.6ZM128,184a16,16,0,1,0,16,16A16,16,0,0,0,128,184Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M20.2,75.9C83.7,28,172.3,28,235.8,75.9A8,8,0,0,1,237,87.5L134,208.9a7.8,7.8,0,0,1-12.1-.1L19,87.6A8.1,8.1,0,0,1,20.2,75.9Z" />
          </g>
          <path d="M128,219.7a16.1,16.1,0,0,1-12.2-5.7L12.9,92.8A16.6,16.6,0,0,1,9.2,80.5a15.6,15.6,0,0,1,6.2-10.9c66.3-50.1,158.9-50.1,225.2,0a15.3,15.3,0,0,1,6.2,10.9,16,16,0,0,1-3.7,12.2l-103,121.4A16,16,0,0,1,128,219.7ZM25.1,82.3h0L128,203.6,230.9,82.4h0C170.4,36.6,85.7,36.6,25.1,82.3Zm-4.9-6.4L25,82.3h0Zm0,0Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M243.1,92.7l-103,121.4a15.9,15.9,0,0,1-24.2,0L12.9,92.8A16.6,16.6,0,0,1,9.2,80.5a15.6,15.6,0,0,1,6.2-10.9c66.3-50.1,158.9-50.1,225.3,0a15.6,15.6,0,0,1,6.2,10.9A16,16,0,0,1,243.1,92.7Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M167.2,161.7a6,6,0,0,1,.2,8.5,5.9,5.9,0,0,1-4.3,1.9,6.3,6.3,0,0,1-4.2-1.7,44.7,44.7,0,0,0-61.8,0,6,6,0,1,1-8.3-8.7,56.8,56.8,0,0,1,78.4,0ZM128,98a103.7,103.7,0,0,0-73.2,29.8,6,6,0,0,0,8.4,8.6,92.7,92.7,0,0,1,129.6,0,6.3,6.3,0,0,0,4.2,1.7,6,6,0,0,0,4.2-10.3A103.7,103.7,0,0,0,128,98Zm107.1-4.1a152.6,152.6,0,0,0-214.2,0,6,6,0,0,0-.1,8.5,5.9,5.9,0,0,0,8.5,0,140.8,140.8,0,0,1,197.4,0,5.9,5.9,0,0,0,8.5,0A6,6,0,0,0,235.1,93.9ZM128,190a10,10,0,1,0,10,10A10,10,0,0,0,128,190Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M165.9,163.2a4,4,0,0,1,0,5.6,3.5,3.5,0,0,1-2.8,1.3,3.9,3.9,0,0,1-2.8-1.2,46.9,46.9,0,0,0-64.6,0,3.9,3.9,0,0,1-5.6-.1,4,4,0,0,1,0-5.6,54.9,54.9,0,0,1,75.8,0ZM128,100a101.7,101.7,0,0,0-71.8,29.3,4,4,0,0,0-.1,5.6,4.1,4.1,0,0,0,5.7.1,94.7,94.7,0,0,1,132.4,0,4.3,4.3,0,0,0,2.8,1.1,4.2,4.2,0,0,0,2.9-1.2,4,4,0,0,0-.1-5.6A101.7,101.7,0,0,0,128,100Zm105.7-4.7a150.7,150.7,0,0,0-211.4,0,4.1,4.1,0,0,0-.1,5.7,4,4,0,0,0,5.7,0,142.7,142.7,0,0,1,200.2,0,3.9,3.9,0,0,0,2.8,1.2,4,4,0,0,0,2.9-1.2A4.1,4.1,0,0,0,233.7,95.3ZM128,192a8,8,0,1,0,8,8A8,8,0,0,0,128,192Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M168.6,160.3a7.9,7.9,0,0,1,.2,11.3,8.1,8.1,0,0,1-5.7,2.5,8.2,8.2,0,0,1-5.6-2.3,42.8,42.8,0,0,0-59,0,8,8,0,1,1-11.1-11.5,58.7,58.7,0,0,1,81.2,0ZM128,96a105.6,105.6,0,0,0-74.6,30.4,8,8,0,1,0,11.2,11.4,90.8,90.8,0,0,1,126.8,0,7.8,7.8,0,0,0,5.6,2.3,8,8,0,0,0,5.6-13.7A105.6,105.6,0,0,0,128,96Zm108.6-3.5a154.8,154.8,0,0,0-217.2,0,8,8,0,0,0,11.3,11.4,138.6,138.6,0,0,1,194.6,0,8.2,8.2,0,0,0,5.6,2.3,8.1,8.1,0,0,0,5.7-13.7ZM128,188a12,12,0,1,0,12,12A12,12,0,0,0,128,188Z" />
        </>
      )}
    </svg>
  );
}

export function PhWifiLow(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M171.7,174.4a12.2,12.2,0,0,1-8.6,3.7,12.5,12.5,0,0,1-8.4-3.4,38.7,38.7,0,0,0-53.4,0,12,12,0,1,1-16.7-17.3,62.8,62.8,0,0,1,86.8,0A12,12,0,0,1,171.7,174.4ZM128,184a16,16,0,1,0,16,16A16,16,0,0,0,128,184Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M71.1,148.9a87.9,87.9,0,0,1,113.8,0l-50.9,60a7.9,7.9,0,0,1-12.1-.1Z" />
          </g>
          <path d="M246.8,80.5a15.7,15.7,0,0,0-6.2-11c-66.3-50-158.9-50-225.2,0a16.1,16.1,0,0,0-6.2,11,16.6,16.6,0,0,0,3.7,12.3L115.8,214a15.9,15.9,0,0,0,24.3.1l103-121.4A16,16,0,0,0,246.8,80.5ZM25,82.3l-4.8-6.4L25,82.3ZM128,203.6,82.6,150.1a80.1,80.1,0,0,1,90.8,0ZM230.9,82.4l-47.1,55.5a95.9,95.9,0,0,0-111.6,0L25.1,82.4h0c60.6-45.7,145.3-45.7,205.8,0Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M246.8,80.5a15.7,15.7,0,0,0-6.2-11c-66.3-50-158.9-50-225.2,0h0a16.1,16.1,0,0,0-6.2,11,16.6,16.6,0,0,0,3.7,12.3L115.8,214a18.5,18.5,0,0,0,4,3.4,15.6,15.6,0,0,0,8.2,2.3h0a15.7,15.7,0,0,0,10.9-4.4,6.8,6.8,0,0,0,1.2-1.2l103-121.4A16,16,0,0,0,246.8,80.5Zm-15.9,1.9-47.1,55.5a95.9,95.9,0,0,0-111.6,0L25.1,82.4h0c60.6-45.7,145.3-45.7,205.8,0Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M167.4,170.2a5.9,5.9,0,0,1-4.3,1.9,6.3,6.3,0,0,1-4.2-1.7,44.7,44.7,0,0,0-61.8,0,6,6,0,0,1-8.5-.2,5.9,5.9,0,0,1,.2-8.4,56.5,56.5,0,0,1,78.4,0A5.9,5.9,0,0,1,167.4,170.2ZM128,190a10,10,0,1,0,10,10A10,10,0,0,0,128,190Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M165.9,168.8a3.5,3.5,0,0,1-2.8,1.3,3.9,3.9,0,0,1-2.8-1.2,46.9,46.9,0,0,0-64.6,0,3.9,3.9,0,0,1-5.6-.1,4,4,0,0,1,0-5.6,54.9,54.9,0,0,1,75.8,0A4,4,0,0,1,165.9,168.8ZM128,192a8,8,0,1,0,8,8A8,8,0,0,0,128,192Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M168.8,171.6a8.1,8.1,0,0,1-5.7,2.5,8.2,8.2,0,0,1-5.6-2.3,42.8,42.8,0,0,0-59,0,8,8,0,1,1-11.1-11.5,58.7,58.7,0,0,1,81.2,0A7.9,7.9,0,0,1,168.8,171.6ZM128,188a12,12,0,1,0,12,12A12,12,0,0,0,128,188Z" />
        </>
      )}
    </svg>
  );
}

export function PhWifiMedium(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M171.4,157.4a12,12,0,0,1,.3,17,12.2,12.2,0,0,1-8.6,3.7,12.5,12.5,0,0,1-8.4-3.4,38.7,38.7,0,0,0-53.4,0,12,12,0,1,1-16.7-17.3,62.8,62.8,0,0,1,86.8,0Zm34-33.8a110.6,110.6,0,0,0-154.8,0,11.9,11.9,0,0,0-.2,16.9,12,12,0,0,0,17,.2,86.7,86.7,0,0,1,121.2,0,11.9,11.9,0,0,0,8.4,3.4,12.1,12.1,0,0,0,8.6-3.6A11.9,11.9,0,0,0,205.4,123.6ZM128,184a16,16,0,1,0,16,16A16,16,0,0,0,128,184Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M134,208.9a7.9,7.9,0,0,1-12.1-.1L50.4,124.5a119.9,119.9,0,0,1,155.2,0Z" />
          </g>
          <path d="M246.8,80.5a15.7,15.7,0,0,0-6.2-11c-66.3-50-158.9-50-225.2,0h0a16.1,16.1,0,0,0-6.2,11,16.6,16.6,0,0,0,3.7,12.3L115.8,214a16.1,16.1,0,0,0,12.2,5.7h0a16,16,0,0,0,12.1-5.6l103-121.4A16,16,0,0,0,246.8,80.5ZM25,82.3l-4.8-6.4L25,82.3ZM128,203.6,61.8,125.7a111.8,111.8,0,0,1,132.4,0ZM230.9,82.4l-26.3,31a128.1,128.1,0,0,0-153.1,0l-26.4-31h0c60.6-45.7,145.3-45.7,205.8,0Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M240.6,69.6C174.3,19.5,81.7,19.5,15.4,69.5a16.1,16.1,0,0,0-6.2,11A16.3,16.3,0,0,0,13,92.8L115.8,214a16.1,16.1,0,0,0,12.2,5.7h0a16,16,0,0,0,12.1-5.6h0l103-121.4a16,16,0,0,0,3.7-12.2A15.6,15.6,0,0,0,240.6,69.6ZM25,82.3Zm179.5,31.2a131,131,0,0,0-17-10.7l-.8-.4A125.8,125.8,0,0,0,169,94.8l-1.6-.4A115.2,115.2,0,0,0,149,89.8l-1.6-.2a118.4,118.4,0,0,0-38.8,0l-1.6.2a115.2,115.2,0,0,0-18.4,4.6l-1.5.4a127,127,0,0,0-17.8,7.6l-.8.4a123.5,123.5,0,0,0-16.9,10.7h0L25.1,82.3c60.6-45.7,145.3-45.7,205.8.1Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M167.2,161.8a6,6,0,0,1-4.1,10.3,6.3,6.3,0,0,1-4.2-1.7,44.7,44.7,0,0,0-61.8,0,6,6,0,0,1-8.5-.2,5.9,5.9,0,0,1,.2-8.4,56.5,56.5,0,0,1,78.4,0Zm34-34a104.8,104.8,0,0,0-146.4,0,6,6,0,0,0,8.4,8.6,92.7,92.7,0,0,1,129.6,0,6.3,6.3,0,0,0,4.2,1.7,6,6,0,0,0,4.2-10.3ZM128,190a10,10,0,1,0,10,10A10,10,0,0,0,128,190Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M165.9,163.2a4,4,0,0,1,0,5.6,3.5,3.5,0,0,1-2.8,1.3,3.9,3.9,0,0,1-2.8-1.2,46.9,46.9,0,0,0-64.6,0,3.9,3.9,0,0,1-5.6-.1,4,4,0,0,1,0-5.6,54.9,54.9,0,0,1,75.8,0Zm33.9-33.9a102.6,102.6,0,0,0-143.6,0,4,4,0,0,0-.1,5.6,4.1,4.1,0,0,0,5.7.1,94.7,94.7,0,0,1,132.4,0,4.3,4.3,0,0,0,2.8,1.1,4.2,4.2,0,0,0,2.9-1.2A4,4,0,0,0,199.8,129.3ZM128,192a8,8,0,1,0,8,8A8,8,0,0,0,128,192Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M168.6,160.3a7.9,7.9,0,0,1,.2,11.3,8.1,8.1,0,0,1-5.7,2.5,8.2,8.2,0,0,1-5.6-2.3,42.8,42.8,0,0,0-59,0,8,8,0,1,1-11.1-11.5,58.7,58.7,0,0,1,81.2,0Zm34-33.9a106.7,106.7,0,0,0-149.2,0,8,8,0,1,0,11.2,11.4,90.8,90.8,0,0,1,126.8,0,7.8,7.8,0,0,0,5.6,2.3,8,8,0,0,0,5.6-13.7ZM128,188a12,12,0,1,0,12,12A12,12,0,0,0,128,188Z" />
        </>
      )}
    </svg>
  );
}

export function PhWifiNone(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M144,200a16,16,0,1,1-16-16A16,16,0,0,1,144,200Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M128,219.7a16,16,0,0,1-12.1-5.6L12.9,92.7A16,16,0,0,1,9.2,80.5a16.3,16.3,0,0,1,6.2-11c66.3-50,158.9-50,225.2,0a16.1,16.1,0,0,1,2.5,23.2l-103,121.4A16,16,0,0,1,128,219.7ZM25.1,82.3h0L128,203.6,230.9,82.4v-.2C170.4,36.6,85.7,36.6,25.1,82.3Zm-4.9-6.4Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,219.7a16,16,0,0,1-12.1-5.6L12.9,92.7a16.1,16.1,0,0,1,2.5-23.2c66.3-50,158.9-50,225.2,0a16.3,16.3,0,0,1,6.2,11,16,16,0,0,1-3.7,12.2l-103,121.4A16,16,0,0,1,128,219.7ZM25.1,82.3h0L128,203.6,230.9,82.4v-.2C170.4,36.6,85.7,36.6,25.1,82.3Zm-.1,0Zm-4.8-6.4Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M138,200a10,10,0,1,1-10-10A10,10,0,0,1,138,200Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M136,200a8,8,0,1,1-8-8A8,8,0,0,1,136,200Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M140,200a12,12,0,1,1-12-12A12,12,0,0,1,140,200Z" />
        </>
      )}
    </svg>
  );
}

export function PhWifiSlash(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M216.9,207.9a12.1,12.1,0,0,1-.8,17,12.1,12.1,0,0,1-17-.8l-46.9-51.6a38.7,38.7,0,0,0-50.9,2.2,12.5,12.5,0,0,1-8.4,3.4,12.2,12.2,0,0,1-8.6-3.7,12,12,0,0,1,.3-17,62.3,62.3,0,0,1,38.3-17.2l-19-20.8a85.5,85.5,0,0,0-36.5,21.3,11.9,11.9,0,0,1-8.4,3.4,12.1,12.1,0,0,1-8.6-3.6,11.9,11.9,0,0,1,.2-16.9,107.5,107.5,0,0,1,35.8-23.5l-17-18.7a134.2,134.2,0,0,0-35.9,25.3,12,12,0,0,1-8.4,3.5,12,12,0,0,1-8.5-20.6A158.8,158.8,0,0,1,52.7,63L39.1,48.1A12,12,0,0,1,56.9,31.9ZM128,184a16,16,0,1,0,16,16A16,16,0,0,0,128,184ZM239.4,89.6A157.5,157.5,0,0,0,128,44c-4,0-8,.2-11.9.4a12,12,0,0,0,1.8,24c3.3-.3,6.7-.4,10.1-.4a133.7,133.7,0,0,1,94.5,38.7,12,12,0,0,0,8.4,3.5,12,12,0,0,0,8.5-20.6Zm-50.8,51.1a11.9,11.9,0,0,0,8.4,3.4,12.1,12.1,0,0,0,8.6-3.6,11.9,11.9,0,0,0-.2-16.9,109.1,109.1,0,0,0-32.7-22.3,12,12,0,1,0-9.7,22A86,86,0,0,1,188.6,140.7Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M20.2,75.9C83.7,28,172.3,28,235.8,75.9A8,8,0,0,1,237,87.5L134,208.9a7.8,7.8,0,0,1-12.1-.1L19,87.6A8.1,8.1,0,0,1,20.2,75.9Z" />
          </g>
          <path d="M172.4,165h0L53.9,34.6a8,8,0,0,0-11.3-.5,7.9,7.9,0,0,0-.5,11.3l4.6,5.1A185.8,185.8,0,0,0,15.4,69.6,15.6,15.6,0,0,0,9.2,80.5a16.6,16.6,0,0,0,3.7,12.3L115.8,214a15.9,15.9,0,0,0,24.3.1l26.7-31.5,35.3,38.8A8,8,0,0,0,208,224a8.2,8.2,0,0,0,5.4-2.1,8,8,0,0,0,.5-11.3ZM128,203.6,25.1,82.4h0A167.3,167.3,0,0,1,58,62.9l98,107.8ZM243.1,92.7l-49.5,58.4a8.1,8.1,0,0,1-6.1,2.8,8,8,0,0,1-5.2-1.9,8.1,8.1,0,0,1-.9-11.3l49.5-58.3h0A171.4,171.4,0,0,0,95.7,51.1a8,8,0,0,1-3-15.8A187.6,187.6,0,0,1,240.6,69.6a15.3,15.3,0,0,1,6.2,10.9A16,16,0,0,1,243.1,92.7Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M214,210.6a8,8,0,0,1-.6,11.3A7.8,7.8,0,0,1,208,224a8.2,8.2,0,0,1-5.9-2.6l-35.2-38.8-26.8,31.5a15.9,15.9,0,0,1-24.3-.1L12.9,92.8A16.3,16.3,0,0,1,9.1,80.5a15.6,15.6,0,0,1,6.2-10.9A182.8,182.8,0,0,1,46.7,50.5L42,45.4A8,8,0,0,1,53.9,34.6ZM246.9,80.5a15.6,15.6,0,0,0-6.2-10.9A188,188,0,0,0,92.6,35.3a8.3,8.3,0,0,0-6.1,5.4,8.2,8.2,0,0,0,1.7,7.9l93.4,102.7a8.3,8.3,0,0,0,5.9,2.6h.2a8.3,8.3,0,0,0,5.9-2.8l49.6-58.4A16.4,16.4,0,0,0,246.9,80.5Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M212.4,212a5.8,5.8,0,0,1-.4,8.4,5.7,5.7,0,0,1-4,1.6,6,6,0,0,1-4.4-2l-47.2-51.9A44.8,44.8,0,0,0,128,158a44,44,0,0,0-30.9,12.4,6.3,6.3,0,0,1-4.2,1.7,6,6,0,0,1-4.1-10.3A55.7,55.7,0,0,1,128,146a65.4,65.4,0,0,1,8.9.7l-31-34.1a92.5,92.5,0,0,0-42.7,23.8,6.3,6.3,0,0,1-4.2,1.7,6,6,0,0,1-4.2-10.3,104.8,104.8,0,0,1,42.1-25.1l-26-28.6a138.8,138.8,0,0,0-41.6,28.3,5.9,5.9,0,0,1-8.5,0,6,6,0,0,1,.1-8.5A148.1,148.1,0,0,1,62.4,64.8L43.6,44a5.9,5.9,0,0,1,8.8-8ZM235.1,93.9A151.2,151.2,0,0,0,128,50a156.1,156.1,0,0,0-21.7,1.5A6,6,0,1,0,108,63.4,148.3,148.3,0,0,1,128,62a139.8,139.8,0,0,1,98.7,40.4,5.9,5.9,0,0,0,8.5,0A6,6,0,0,0,235.1,93.9Zm-42.3,42.5a6.3,6.3,0,0,0,4.2,1.7,6,6,0,0,0,4.2-10.3,104.4,104.4,0,0,0-47.6-26.7,6,6,0,1,0-2.9,11.7A91.9,91.9,0,0,1,192.8,136.4ZM128,190a10,10,0,1,0,10,10A10,10,0,0,0,128,190Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M211,213.3a4.1,4.1,0,0,1-.3,5.7,4.1,4.1,0,0,1-5.7-.3l-47.3-52a46.7,46.7,0,0,0-62,2.2,3.9,3.9,0,0,1-2.8,1.2,3.5,3.5,0,0,1-2.8-1.3,4,4,0,0,1,0-5.6A54.7,54.7,0,0,1,142.6,150l-36-39.6A94,94,0,0,0,61.8,135a4.3,4.3,0,0,1-2.8,1.1,4,4,0,0,1-2.9-1.2,4,4,0,0,1,.1-5.6,101.6,101.6,0,0,1,44.3-25.6l-29.1-32A139.6,139.6,0,0,0,27.9,101a3.9,3.9,0,0,1-2.8,1.2,4,4,0,0,1-2.9-1.2,4.1,4.1,0,0,1,.1-5.7A147.6,147.6,0,0,1,65.7,65.4L45,42.7a4,4,0,1,1,6-5.4Zm22.7-118A149.4,149.4,0,0,0,128,52a151.8,151.8,0,0,0-21.4,1.5,4,4,0,0,0,1.1,7.9A152.7,152.7,0,0,1,128,60a141.6,141.6,0,0,1,100.1,41,3.9,3.9,0,0,0,2.8,1.2,4,4,0,0,0,2.9-1.2A4.1,4.1,0,0,0,233.7,95.3ZM194.2,135a4.3,4.3,0,0,0,2.8,1.1,4.2,4.2,0,0,0,2.9-1.2,4,4,0,0,0-.1-5.6,101.1,101.1,0,0,0-46.7-26.2,4,4,0,1,0-1.9,7.7A93.7,93.7,0,0,1,194.2,135ZM128,192a8,8,0,1,0,8,8A8,8,0,0,0,128,192Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M213.9,210.6A8,8,0,0,1,208,224a8,8,0,0,1-5.9-2.6L155,169.6a42.8,42.8,0,0,0-56.5,2.2,8.2,8.2,0,0,1-5.6,2.3,8,8,0,0,1-5.5-13.8A58,58,0,0,1,128,144h3.9l-26.6-29.2a89.3,89.3,0,0,0-40.7,22.9,8,8,0,0,1-11.3-.1,7.9,7.9,0,0,1,.1-11.3,104.7,104.7,0,0,1,39.9-24.6L70.4,76.5a135.5,135.5,0,0,0-39.7,27.4,8.2,8.2,0,0,1-5.6,2.3,7.8,7.8,0,0,1-5.7-2.4,8,8,0,0,1,0-11.3A155.9,155.9,0,0,1,59.1,64.1l-17-18.7a8,8,0,0,1,.5-11.3,7.9,7.9,0,0,1,11.3.5ZM236.6,92.5A153.4,153.4,0,0,0,128,48a160.5,160.5,0,0,0-22,1.5,8,8,0,1,0,2.3,15.9A143.9,143.9,0,0,1,128,64a137.5,137.5,0,0,1,97.3,39.9,8.2,8.2,0,0,0,5.6,2.3,8.1,8.1,0,0,0,5.7-13.7Zm-45.2,45.3a7.8,7.8,0,0,0,5.6,2.3,8,8,0,0,0,5.6-13.7,106,106,0,0,0-48.5-27.2,8,8,0,1,0-3.9,15.5A90.4,90.4,0,0,1,191.4,137.8ZM128,188a12,12,0,1,0,12,12A12,12,0,0,0,128,188Z" />
        </>
      )}
    </svg>
  );
}

export function PhWifiX(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M171.4,157.4a12,12,0,0,1,.3,17,12,12,0,0,1-17,.3,38.7,38.7,0,0,0-53.4,0,12,12,0,1,1-16.7-17.3,62.8,62.8,0,0,1,86.8,0ZM217,80l15.5-15.5a12,12,0,0,0-17-17L200,63,184.5,47.5a12,12,0,0,0-17,17L183,80,167.5,95.5a12,12,0,0,0,0,17,12.1,12.1,0,0,0,17,0L200,97l15.5,15.5a12.1,12.1,0,0,0,17,0,12,12,0,0,0,0-17ZM128,184a16,16,0,1,0,16,16A16,16,0,0,0,128,184Zm7.4-115.8a12,12,0,1,0,1.2-24L128,44A157.5,157.5,0,0,0,16.6,89.6a12,12,0,1,0,16.9,17.1A133.7,133.7,0,0,1,128,68Zm-.4,48.1a12.1,12.1,0,0,0,13-11,12,12,0,0,0-11-12.9c-3-.3-6-.4-9-.4a109.6,109.6,0,0,0-77.4,31.6,11.9,11.9,0,0,0-.2,16.9,12.1,12.1,0,0,0,8.6,3.6,11.9,11.9,0,0,0,8.4-3.4A86,86,0,0,1,128,116C130.3,116,132.7,116.1,135,116.3Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M20.2,75.9C83.7,28,172.3,28,235.8,75.9A8,8,0,0,1,237,87.5L134,208.9a7.8,7.8,0,0,1-12.1-.1L19,87.6A8.1,8.1,0,0,1,20.2,75.9Z" />
          </g>
          <path d="M229.7,98.3a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0L200,91.3l-18.3,18.4a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L188.7,80,170.3,61.7a8.1,8.1,0,0,1,11.4-11.4L200,68.7l18.3-18.4a8.1,8.1,0,0,1,11.4,11.4L211.3,80Zm-21.8,23.5a8.1,8.1,0,0,0-11.3.9L128,203.6,25.1,82.4h0A170.9,170.9,0,0,1,146.8,49a8,8,0,0,0,1.7-15.9A186.1,186.1,0,0,0,15.4,69.6,15.6,15.6,0,0,0,9.2,80.5a16.6,16.6,0,0,0,3.7,12.3L115.8,214a15.9,15.9,0,0,0,24.3.1l68.7-81A8,8,0,0,0,207.9,121.8Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M229.4,98.1a8.2,8.2,0,0,1,.6,11.1,7.8,7.8,0,0,1-6,2.8,8.3,8.3,0,0,1-5.7-2.3L200,91.3l-18.3,18.4A8.5,8.5,0,0,1,176,112a8,8,0,0,1-6.1-2.8,8.3,8.3,0,0,1,.6-11.1L188.7,80,170.6,61.9a8.3,8.3,0,0,1-.4-11.4,8.1,8.1,0,0,1,11.5-.2L200,68.7l18.3-18.4a8.1,8.1,0,0,1,11.5.2,8.3,8.3,0,0,1-.4,11.4L211.3,80Zm-26.6,18.7a3.9,3.9,0,0,0-5.6,0l-3.9,3.8a24,24,0,0,1-34.3.4,24.2,24.2,0,0,1,0-34l7.1-7-6.7-6.6c-9-9-9.9-23.6-1.5-33.2a11.4,11.4,0,0,1,1.1-1.1,2.9,2.9,0,0,0-1.5-4.8A187.4,187.4,0,0,0,15.4,69.6,15.6,15.6,0,0,0,9.2,80.5a16.6,16.6,0,0,0,3.7,12.3l103,121.3a15.9,15.9,0,0,0,24.2,0l71.5-84.2a4,4,0,0,0-.9-5.9,26.9,26.9,0,0,1-3.7-3Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M167.2,161.8a6,6,0,0,1-4.1,10.3,6.3,6.3,0,0,1-4.2-1.7,44.7,44.7,0,0,0-61.8,0,6,6,0,0,1-8.4-8.6,56.7,56.7,0,0,1,78.5,0ZM208.5,80l19.7-19.8a5.9,5.9,0,0,0-8.4-8.4L200,71.5,180.2,51.8a5.9,5.9,0,0,0-8.4,8.4L191.5,80,171.8,99.8a5.9,5.9,0,1,0,8.4,8.4L200,88.5l19.8,19.7a5.9,5.9,0,1,0,8.4-8.4ZM143.4,62.8a6,6,0,0,0,6.6-5.3,6.1,6.1,0,0,0-5.3-6.6A154.3,154.3,0,0,0,128,50,151.6,151.6,0,0,0,20.8,93.9a6.1,6.1,0,0,0,0,8.5,5.9,5.9,0,0,0,8.5,0A139.6,139.6,0,0,1,128,62C133.1,62,138.3,62.3,143.4,62.8Zm-.3,48.4A6,6,0,0,0,145,99.4,107.3,107.3,0,0,0,128,98a103.7,103.7,0,0,0-73.2,29.8A6,6,0,0,0,59,138.1a6.3,6.3,0,0,0,4.2-1.7A91.9,91.9,0,0,1,128,110,96.1,96.1,0,0,1,143.1,111.2ZM128,190a10,10,0,1,0,10,10A10,10,0,0,0,128,190Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M165.8,163.2a3.9,3.9,0,0,1,.1,5.6,3.5,3.5,0,0,1-2.8,1.3,3.9,3.9,0,0,1-2.8-1.2,46.9,46.9,0,0,0-64.6,0,4,4,0,0,1-5.7-.1,4,4,0,0,1,.1-5.6,54.7,54.7,0,0,1,75.7,0ZM205.7,80l21.1-21.2a4,4,0,1,0-5.6-5.6L200,74.3,178.8,53.2a4,4,0,0,0-5.6,5.6L194.3,80l-21.1,21.2a3.9,3.9,0,0,0,0,5.6,3.9,3.9,0,0,0,5.6,0L200,85.7l21.2,21.1a3.9,3.9,0,0,0,5.6,0,3.9,3.9,0,0,0,0-5.6ZM143.6,60.8a3.9,3.9,0,0,0,4.4-3.5,4,4,0,0,0-3.6-4.4A150.6,150.6,0,0,0,22.3,95.3a4.1,4.1,0,0,0-.1,5.7,4,4,0,0,0,2.9,1.2,3.9,3.9,0,0,0,2.8-1.2A141.6,141.6,0,0,1,128,60C133.2,60,138.4,60.3,143.6,60.8Zm-.2,48.4a4,4,0,0,0,4.6-3.3,4.2,4.2,0,0,0-3.3-4.6A116.3,116.3,0,0,0,128,100a101.7,101.7,0,0,0-71.8,29.3,4,4,0,0,0-.1,5.6,4,4,0,0,0,2.9,1.2,4.3,4.3,0,0,0,2.8-1.1A93.8,93.8,0,0,1,128,108,98.9,98.9,0,0,1,143.4,109.2ZM128,192a8,8,0,1,0,8,8A8,8,0,0,0,128,192Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M168.6,160.3a7.9,7.9,0,0,1,.2,11.3,8.1,8.1,0,0,1-5.7,2.5,8.2,8.2,0,0,1-5.6-2.3,42.8,42.8,0,0,0-59,0,8,8,0,1,1-11.1-11.5,58.7,58.7,0,0,1,81.2,0ZM211.3,80l18.4-18.3a8.1,8.1,0,0,0-11.4-11.4L200,68.7,181.7,50.3a8.1,8.1,0,0,0-11.4,11.4L188.7,80,170.3,98.3a8.1,8.1,0,0,0,0,11.4,8.2,8.2,0,0,0,11.4,0L200,91.3l18.3,18.4a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4ZM143.1,64.8a8,8,0,1,0,1.8-15.9A159.5,159.5,0,0,0,128,48,153.4,153.4,0,0,0,19.4,92.5a8,8,0,0,0,0,11.3,7.8,7.8,0,0,0,5.7,2.4,8.2,8.2,0,0,0,5.6-2.3A137.5,137.5,0,0,1,128,64,149.6,149.6,0,0,1,143.1,64.8Zm-.4,48.4a8,8,0,1,0,2.6-15.8A111.1,111.1,0,0,0,128,96a105.6,105.6,0,0,0-74.6,30.4,7.9,7.9,0,0,0-.1,11.3,8,8,0,0,0,11.3.1,90.8,90.8,0,0,1,78.1-24.6ZM128,188a12,12,0,1,0,12,12A12,12,0,0,0,128,188Z" />
        </>
      )}
    </svg>
  );
}

export function PhWind(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M24,104a12,12,0,0,1,0-24h96a12,12,0,0,0,0-24,11.6,11.6,0,0,0-8.9,4,12,12,0,0,1-17,.9A11.9,11.9,0,0,1,93.2,44,36,36,0,1,1,120,104ZM208,72a36.1,36.1,0,0,0-26.8,12,11.9,11.9,0,0,0,.9,16.9,12,12,0,0,0,17-.9,11.6,11.6,0,0,1,8.9-4,12,12,0,0,1,0,24H32a12,12,0,0,0,0,24H208a36,36,0,0,0,0-72Zm-56,88H40a12,12,0,0,0,0,24H152a12,12,0,0,1,0,24,11.6,11.6,0,0,1-8.9-4,12,12,0,0,0-17-.9,11.9,11.9,0,0,0-.9,16.9A36,36,0,1,0,152,160Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M184,184a32,32,0,0,1-62,11.1,8,8,0,1,1,15-5.5A16,16,0,1,0,152,168H40a8,8,0,0,1,0-16H152A32.1,32.1,0,0,1,184,184Zm-64-80A32,32,0,1,0,90,60.9a8,8,0,1,0,15,5.5A16,16,0,1,1,120,88H24a8,8,0,0,0,0,16Zm88-32a32.1,32.1,0,0,0-30,20.9,8,8,0,1,0,15,5.5A16,16,0,1,1,208,120H32a8,8,0,0,0,0,16H208a32,32,0,0,0,0-64Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M24,104a8,8,0,0,1,0-16h96a16,16,0,1,0-15-21.6,8,8,0,1,1-15-5.5A32,32,0,1,1,120,104Zm128,48H40a8,8,0,0,0,0,16H152a16,16,0,1,1-15,21.6,8,8,0,1,0-15,5.5A32,32,0,1,0,152,152Zm56-80a32.1,32.1,0,0,0-30,20.9,8,8,0,1,0,15,5.5A16,16,0,1,1,208,120H32a8,8,0,0,0,0,16H208a32,32,0,0,0,0-64Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M182,184a30,30,0,0,1-58.1,10.4,6,6,0,0,1,11.2-4.2A18,18,0,1,0,152,166H40a6,6,0,0,1,0-12H152A30.1,30.1,0,0,1,182,184ZM150,72A30,30,0,0,0,91.9,61.6a6,6,0,0,0,11.2,4.2A18,18,0,1,1,120,90H24a6,6,0,0,0,0,12h96A30.1,30.1,0,0,0,150,72Zm58,2a30.1,30.1,0,0,0-28.1,19.6,6,6,0,0,0,11.2,4.2A18,18,0,1,1,208,122H32a6,6,0,0,0,0,12H208a30,30,0,0,0,0-60Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M180,184a28,28,0,0,1-54.3,9.7,4,4,0,0,1,2.4-5.1,3.9,3.9,0,0,1,5.1,2.3A20,20,0,1,0,152,164H40a4,4,0,0,1,0-8H152A28.1,28.1,0,0,1,180,184ZM148,72a28,28,0,0,0-54.3-9.7,4,4,0,0,0,2.4,5.1,3.9,3.9,0,0,0,5.1-2.3A20,20,0,1,1,120,92H24a4,4,0,0,0,0,8h96A28.1,28.1,0,0,0,148,72Zm60,4a28.1,28.1,0,0,0-26.3,18.3,4,4,0,0,0,2.4,5.1,3.9,3.9,0,0,0,5.1-2.3A20,20,0,1,1,208,124H32a4,4,0,0,0,0,8H208a28,28,0,0,0,0-56Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M184,184a32,32,0,0,1-62,11.1,8,8,0,1,1,15-5.5A16,16,0,1,0,152,168H40a8,8,0,0,1,0-16H152A32.1,32.1,0,0,1,184,184Zm-64-80A32,32,0,1,0,90,60.9a8,8,0,1,0,15,5.5A16,16,0,1,1,120,88H24a8,8,0,0,0,0,16Zm88-32a32.1,32.1,0,0,0-30,20.9,8,8,0,1,0,15,5.5A16,16,0,1,1,208,120H32a8,8,0,0,0,0,16H208a32,32,0,0,0,0-64Z" />
        </>
      )}
    </svg>
  );
}

export function PhWindowsLogo(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M107.7,51.9a11.6,11.6,0,0,0-9.8-2.6l-60,10.9A11.9,11.9,0,0,0,28,72v36a12,12,0,0,0,12,12h60a12,12,0,0,0,12-12V61.1A12,12,0,0,0,107.7,51.9ZM88,96H52V82l36-6.5ZM223.7,30.8a12,12,0,0,0-9.8-2.6L137.9,42A11.9,11.9,0,0,0,128,53.8V108a12,12,0,0,0,12,12h76a12,12,0,0,0,12-12V40A12,12,0,0,0,223.7,30.8ZM204,96H152V63.8l52-9.4ZM100,136H40a12,12,0,0,0-12,12v36a11.9,11.9,0,0,0,9.9,11.8l60,10.9,2.1.2a12,12,0,0,0,12-12V148A12,12,0,0,0,100,136ZM88,180.5,52,174V160H88ZM216,136H140a12,12,0,0,0-12,12v54.2a11.9,11.9,0,0,0,9.9,11.8l76,13.8,2.1.2a12,12,0,0,0,12-12V148A12,12,0,0,0,216,136Zm-12,65.6-52-9.4V160h52Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M136,144h80v72l-80-14.5ZM40,184l64,11.6V144H40ZM136,54.5V112h80V40ZM40,112h64V60.4L40,72Z"
            opacity="0.2"
          />
          <path d="M216,136H136a8,8,0,0,0-8,8v57.5a8,8,0,0,0,6.6,7.8l80,14.6H216a7.4,7.4,0,0,0,5.1-1.9A7.9,7.9,0,0,0,224,216V144A8,8,0,0,0,216,136Zm-8,70.4-64-11.6V152h64ZM104,136H40a8,8,0,0,0-8,8v40a8,8,0,0,0,6.6,7.9l64,11.6H104a7.8,7.8,0,0,0,5.1-1.8,8.1,8.1,0,0,0,2.9-6.2V144A8,8,0,0,0,104,136Zm-8,50.1-48-8.8V152H96ZM221.1,33.9a7.7,7.7,0,0,0-6.5-1.8l-80,14.6a8,8,0,0,0-6.6,7.8V112a8,8,0,0,0,8,8h80a8,8,0,0,0,8-8V40A7.9,7.9,0,0,0,221.1,33.9ZM208,104H144V61.2l64-11.6ZM109.1,54.2a8,8,0,0,0-6.5-1.7l-64,11.6A8,8,0,0,0,32,72v40a8,8,0,0,0,8,8h64a8,8,0,0,0,8-8V60.4A8.1,8.1,0,0,0,109.1,54.2ZM96,104H48V78.7l48-8.8Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M112,144v51.6a8.1,8.1,0,0,1-2.9,6.2,7.8,7.8,0,0,1-5.1,1.8h-1.4l-64-11.6A8,8,0,0,1,32,184V144a8,8,0,0,1,8-8h64A8,8,0,0,1,112,144Zm-2.9-89.8a8,8,0,0,0-6.5-1.7l-64,11.6A8,8,0,0,0,32,72v40a8,8,0,0,0,8,8h64a8,8,0,0,0,8-8V60.4A8.1,8.1,0,0,0,109.1,54.2Zm112-20.3a7.7,7.7,0,0,0-6.5-1.8l-80,14.6a8,8,0,0,0-6.6,7.8V112a8,8,0,0,0,8,8h80a8,8,0,0,0,8-8V40A7.9,7.9,0,0,0,221.1,33.9ZM216,136H136a8,8,0,0,0-8,8v57.5a8,8,0,0,0,6.6,7.8l80,14.6H216a7.4,7.4,0,0,0,5.1-1.9A7.9,7.9,0,0,0,224,216V144A8,8,0,0,0,216,136Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M216,138H136a6,6,0,0,0-6,6v57.5a6.1,6.1,0,0,0,4.9,5.9l80,14.5H216a5.7,5.7,0,0,0,3.8-1.4A5.8,5.8,0,0,0,222,216V144A6,6,0,0,0,216,138Zm-6,70.8-68-12.4V150h68ZM104,138H40a6,6,0,0,0-6,6v40a6,6,0,0,0,4.9,5.9l64,11.6H104a5.3,5.3,0,0,0,3.8-1.4,5.8,5.8,0,0,0,2.2-4.6V144A6,6,0,0,0,104,138Zm-6,50.4L46,179V150H98Zm121.8-153a6.1,6.1,0,0,0-4.9-1.3l-80,14.5a6.1,6.1,0,0,0-4.9,5.9V112a6,6,0,0,0,6,6h80a6,6,0,0,0,6-6V40A5.8,5.8,0,0,0,219.8,35.4ZM210,106H142V59.6l68-12.4ZM107.8,55.8a5.4,5.4,0,0,0-4.9-1.3l-64,11.6A6,6,0,0,0,34,72v40a6,6,0,0,0,6,6h64a6,6,0,0,0,6-6V60.4A5.8,5.8,0,0,0,107.8,55.8ZM98,106H46V77l52-9.4Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M216,140H136a4,4,0,0,0-4,4v57.5a4,4,0,0,0,3.3,3.9l80,14.5h.7a4.3,4.3,0,0,0,2.6-.9A4.1,4.1,0,0,0,220,216V144A4,4,0,0,0,216,140Zm-4,71.2-72-13.1V148h72ZM104,140H40a4,4,0,0,0-4,4v40a4,4,0,0,0,3.3,3.9l64,11.7h.7a4.3,4.3,0,0,0,2.6-.9,4.1,4.1,0,0,0,1.4-3.1V144A4,4,0,0,0,104,140Zm-4,50.8L44,180.7V148h56ZM218.6,36.9a4.2,4.2,0,0,0-3.3-.8l-80,14.5a4,4,0,0,0-3.3,3.9V112a4,4,0,0,0,4,4h80a4,4,0,0,0,4-4V40A4.1,4.1,0,0,0,218.6,36.9ZM212,108H140V57.9l72-13.1ZM103.3,56.4l-64,11.7A4,4,0,0,0,36,72v40a4,4,0,0,0,4,4h64a4,4,0,0,0,4-4V60.4a4.1,4.1,0,0,0-1.4-3.1A3.9,3.9,0,0,0,103.3,56.4ZM100,108H44V75.3l56-10.1Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M216,136H136a8,8,0,0,0-8,8v57.5a8,8,0,0,0,6.6,7.8l80,14.6H216a7.4,7.4,0,0,0,5.1-1.9A7.9,7.9,0,0,0,224,216V144A8,8,0,0,0,216,136Zm-8,70.4-64-11.6V152h64ZM104,136H40a8,8,0,0,0-8,8v40a8,8,0,0,0,6.6,7.9l64,11.6H104a7.8,7.8,0,0,0,5.1-1.8,8.1,8.1,0,0,0,2.9-6.2V144A8,8,0,0,0,104,136Zm-8,50.1-48-8.8V152H96ZM221.1,33.9a7.7,7.7,0,0,0-6.5-1.8l-80,14.6a8,8,0,0,0-6.6,7.8V112a8,8,0,0,0,8,8h80a8,8,0,0,0,8-8V40A7.9,7.9,0,0,0,221.1,33.9ZM208,104H144V61.2l64-11.6ZM109.1,54.2a8,8,0,0,0-6.5-1.7l-64,11.6A8,8,0,0,0,32,72v40a8,8,0,0,0,8,8h64a8,8,0,0,0,8-8V60.4A8.1,8.1,0,0,0,109.1,54.2ZM96,104H48V78.7l48-8.8Z" />
        </>
      )}
    </svg>
  );
}

export function PhWine(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M213.6,108.9,187.5,20.6A12,12,0,0,0,176,12H80a12,12,0,0,0-11.5,8.6L42.4,108.9a36.5,36.5,0,0,0,1.7,25A92.4,92.4,0,0,0,116,187.2V220H88a12,12,0,0,0,0,24h80a12,12,0,0,0,0-24H140V187.2a92.4,92.4,0,0,0,71.9-53.3A36.5,36.5,0,0,0,213.6,108.9ZM89,36h78l18.9,64.1c-10.6,3.5-28.4,5.2-52.5-6.8s-43.2-13.4-58-11.2Zm39,128a68.1,68.1,0,0,1-62-40,11.4,11.4,0,0,1-.5-8.3l2-6.8c10.6-4.3,29.2-7.1,55.1,5.8,17.9,9,33.5,12,46.5,12a78.9,78.9,0,0,0,20.9-2.8h0A68.1,68.1,0,0,1,128,164Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M200.4,106.7Zm1.7,5.6-1.7-5.6c-9.3,5.5-34.8,16.1-72.4-2.7-35.3-17.6-59.9-9.4-70.5-3.7l-3.6,12A24.1,24.1,0,0,0,55.1,129a80,80,0,0,0,145.8,0A24.1,24.1,0,0,0,202.1,112.3Z"
            opacity="0.2"
          />
          <path d="M209.7,110l-26-88.3A8,8,0,0,0,176,16H80a8,8,0,0,0-7.7,5.7L46.3,110a31.5,31.5,0,0,0,1.5,22.2A88.1,88.1,0,0,0,120,183.6V224H88a8,8,0,0,0,0,16h80a8,8,0,0,0,0-16H136V183.6a88.1,88.1,0,0,0,72.2-51.4A31.5,31.5,0,0,0,209.7,110ZM86,32h84l20.8,70.5c-10.7,4.5-31,8.4-59.2-5.7-26.2-13-47.2-13-61.9-9.5Zm107.6,93.7a72,72,0,0,1-131.2,0,16,16,0,0,1-.8-11.2l2.6-8.6c11-4.9,31.4-9.1,60.2,5.3,17.3,8.6,32.3,11.5,44.7,11.5a74.7,74.7,0,0,0,25.9-4.6A15.4,15.4,0,0,1,193.6,125.7Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M209.7,110l-26-88.3A8,8,0,0,0,176,16H80a8,8,0,0,0-7.7,5.7L46.3,110a31.5,31.5,0,0,0,1.5,22.2A88.1,88.1,0,0,0,120,183.6V224H88a8,8,0,0,0,0,16h80a8,8,0,0,0,0-16H136V183.6a88.1,88.1,0,0,0,72.2-51.4A31.5,31.5,0,0,0,209.7,110ZM131.6,96.8c-26.2-13-47.2-13-61.9-9.5L86,32h84l20.8,70.5C180.1,107,159.8,110.9,131.6,96.8Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M207.8,110.6l-26-88.3A6.1,6.1,0,0,0,176,18H80a6.1,6.1,0,0,0-5.8,4.3l-26,88.3a29.8,29.8,0,0,0,1.4,20.8A86.3,86.3,0,0,0,122,181.8V226H88a6,6,0,0,0,0,12h80a6,6,0,0,0,0-12H134V181.8a86.3,86.3,0,0,0,72.4-50.4A29.8,29.8,0,0,0,207.8,110.6ZM84.5,30h87l21.8,73.6c-10.7,4.9-32.3,10.2-62.6-5-27.8-13.8-49.5-12.7-64-8.5Zm111,96.5a74.1,74.1,0,0,1-135,0,18.5,18.5,0,0,1-.8-12.5l2.8-9.5c11.2-5.3,32.6-10.2,62.8,4.9,16.9,8.4,31.7,11.3,43.8,11.3a71.3,71.3,0,0,0,27.5-5.4A18.7,18.7,0,0,1,195.5,126.5Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M205.9,111.1,179.8,22.9A4,4,0,0,0,176,20H80a4,4,0,0,0-3.8,2.9L50.1,111.1a28.4,28.4,0,0,0,1.3,19.5A84.4,84.4,0,0,0,124,179.9V228H88a4,4,0,0,0,0,8h80a4,4,0,0,0,0-8H132V179.9a84.4,84.4,0,0,0,72.6-49.3A28.4,28.4,0,0,0,205.9,111.1ZM83,28h90l22.7,76.7c-10.4,5.2-33.3,12-65.9-4.3-29.6-14.8-52.2-12.2-66-7.2Zm114.3,99.3a76.1,76.1,0,0,1-138.6,0,20.1,20.1,0,0,1-.9-13.9l3-10.3c11.3-5.6,33.7-11.4,65.4,4.5,16.6,8.3,31,11.1,42.9,11.1a67,67,0,0,0,28.8-6.2l.3.9A20.1,20.1,0,0,1,197.3,127.3Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M209.7,110l-26-88.3A8,8,0,0,0,176,16H80a8,8,0,0,0-7.7,5.7L46.3,110a31.5,31.5,0,0,0,1.5,22.2A88.1,88.1,0,0,0,120,183.6V224H88a8,8,0,0,0,0,16h80a8,8,0,0,0,0-16H136V183.6a88.1,88.1,0,0,0,72.2-51.4A31.5,31.5,0,0,0,209.7,110ZM86,32h84l20.8,70.5c-10.7,4.5-31,8.4-59.2-5.7-26.2-13-47.2-13-61.9-9.5Zm107.6,93.7a72,72,0,0,1-131.2,0,16,16,0,0,1-.8-11.2l2.6-8.6c11-4.9,31.4-9.1,60.2,5.3,17.3,8.6,32.3,11.5,44.7,11.5a74.7,74.7,0,0,0,25.9-4.6A15.4,15.4,0,0,1,193.6,125.7Z" />
        </>
      )}
    </svg>
  );
}

export function PhWrench(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M230,66.3a12.4,12.4,0,0,0-6.9-6.6,12.1,12.1,0,0,0-9.5.6,14,14,0,0,0-3.2,2.2L173,99.9l-14.1-2.8L156.1,83l37.4-37.4a14,14,0,0,0,2.2-3.2,12.1,12.1,0,0,0,.6-9.5,12.4,12.4,0,0,0-6.6-6.9A76,76,0,0,0,89.3,123.8L31.2,173.9l-.7.6a36.1,36.1,0,0,0,51,51l.6-.7,50.1-58.1A76,76,0,0,0,230,66.3Zm-33.2,66.5a52.5,52.5,0,0,1-61.7,8.9,11.9,11.9,0,0,0-15.1,2.5L64.2,208.7a12,12,0,0,1-16.9-16.9L111.8,136a12.1,12.1,0,0,0,2.7-14.9A51.9,51.9,0,0,1,161.1,44L134.5,70.5a12.1,12.1,0,0,0-3.2,10.9l5.6,28.3a12.2,12.2,0,0,0,9.4,9.4l28.3,5.6a12.1,12.1,0,0,0,10.9-3.2L212,94.9A51.8,51.8,0,0,1,196.8,132.8Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M185,37.1,143,79l5.7,28.3L177,113l41.9-42h0a63.9,63.9,0,0,1-89.8,81h0L73,217a24,24,0,0,1-34-34l65-56.1h0a63.9,63.9,0,0,1,81-89.8Z" />
          </g>
          <path d="M226.3,67.9a8.3,8.3,0,0,0-4.6-4.4,7.7,7.7,0,0,0-6.3.4,5.1,5.1,0,0,0-2.1,1.5l-39,38.9-18.8-3.8-3.8-18.8,38.9-39a5.1,5.1,0,0,0,1.5-2.1,7.7,7.7,0,0,0,.4-6.3,8.3,8.3,0,0,0-4.4-4.6,72,72,0,0,0-94,95.2L33.8,177c-.1.1-.3.2-.4.4a31.9,31.9,0,0,0,0,45.2,31.9,31.9,0,0,0,45.2,0c.2-.1.3-.3.4-.4l52.1-60.3a72,72,0,0,0,95.2-94Zm-26.7,67.7a56.2,56.2,0,0,1-66.5,9.5,8.1,8.1,0,0,0-10.1,1.7L67.1,211.5a16,16,0,0,1-22.6-22.6L109.2,133a8,8,0,0,0,1.8-9.9,56.1,56.1,0,0,1,58.9-82.3L137.4,73.4a8,8,0,0,0-2.2,7.2l5.6,28.3a8.4,8.4,0,0,0,6.3,6.3l28.3,5.6a8,8,0,0,0,7.2-2.2l32.6-32.5A55.9,55.9,0,0,1,199.6,135.6Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M210.9,146.9a72.2,72.2,0,0,1-79.8,15L79,222.2c-.1.1-.2.3-.4.4a31.9,31.9,0,0,1-45.2,0,31.9,31.9,0,0,1,0-45.2c.1-.2.3-.3.4-.4l60.3-52.1a72,72,0,0,1,94-95.2,8.3,8.3,0,0,1,4.4,4.6,7.7,7.7,0,0,1-.4,6.3,5.1,5.1,0,0,1-1.5,2.1l-38.9,39,3.8,18.8,18.8,3.8,39-38.9a5.1,5.1,0,0,1,2.1-1.5,7.7,7.7,0,0,1,6.3-.4,8.3,8.3,0,0,1,4.6,4.4A71.6,71.6,0,0,1,210.9,146.9Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M224.5,68.7a6,6,0,0,0-3.5-3.3,6.2,6.2,0,0,0-4.8.2,5.7,5.7,0,0,0-1.5,1.2L175,106.5l-21.2-4.3L149.5,81l39.7-39.7a4.8,4.8,0,0,0,1.2-1.6,5.9,5.9,0,0,0,.2-4.7,6,6,0,0,0-3.3-3.5,70,70,0,0,0-90.8,94l-61.4,53-.3.3a30,30,0,0,0,0,42.4,29.9,29.9,0,0,0,42.4,0l.3-.3,53-61.4a70,70,0,0,0,94-90.8ZM201,137a58.3,58.3,0,0,1-68.9,9.9,6,6,0,0,0-7.6,1.2L68.6,212.9a18,18,0,0,1-25.5-25.5l64.8-55.9a6.1,6.1,0,0,0,1.3-7.5,58,58,0,0,1,64.7-84.3L138.8,74.8a6.3,6.3,0,0,0-1.7,5.4l5.7,28.3a6,6,0,0,0,4.7,4.7l28.3,5.7a6.3,6.3,0,0,0,5.4-1.7l35.1-35.1A57.6,57.6,0,0,1,201,137Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M222.6,69.4a3.5,3.5,0,0,0-2.3-2.1,4,4,0,0,0-3.2.1l-1,.8-40.4,40.4-23.6-4.7-4.7-23.6,40.4-40.4.8-1a4,4,0,0,0,.1-3.2,3.5,3.5,0,0,0-2.1-2.3,67.7,67.7,0,0,0-74.7,14.5A68.4,68.4,0,0,0,99,126L36.4,180l-.2.2a28.1,28.1,0,0,0,0,39.6,28,28,0,0,0,39.6,0l.2-.2,54-62.5a68.6,68.6,0,0,0,78.1-13A67.7,67.7,0,0,0,222.6,69.4Zm-20.2,69a60.1,60.1,0,0,1-71.3,10.2,4,4,0,0,0-5.1.8L70,214.2A19.9,19.9,0,0,1,41.8,186l64.8-56a4.1,4.1,0,0,0,.9-5,60.2,60.2,0,0,1,10.1-71.4,59.5,59.5,0,0,1,60.1-14.9L140.2,76.2a4,4,0,0,0-1.1,3.6l5.7,28.3a3.8,3.8,0,0,0,3.1,3.1l28.3,5.7a4,4,0,0,0,3.6-1.1l37.5-37.5A59.5,59.5,0,0,1,202.4,138.4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M226.3,67.9a8.3,8.3,0,0,0-4.6-4.4,7.7,7.7,0,0,0-6.3.4,5.1,5.1,0,0,0-2.1,1.5l-39,38.9-18.8-3.8-3.8-18.8,38.9-39a5.1,5.1,0,0,0,1.5-2.1,7.7,7.7,0,0,0,.4-6.3,8.3,8.3,0,0,0-4.4-4.6,72,72,0,0,0-94,95.2L33.8,177c-.1.1-.3.2-.4.4a31.9,31.9,0,0,0,0,45.2,31.9,31.9,0,0,0,45.2,0c.2-.1.3-.3.4-.4l52.1-60.3a72,72,0,0,0,95.2-94Zm-26.7,67.7a56.2,56.2,0,0,1-66.5,9.5,8.1,8.1,0,0,0-10.1,1.7L67.1,211.5a16,16,0,0,1-22.6-22.6L109.2,133a8,8,0,0,0,1.8-9.9,56.1,56.1,0,0,1,58.9-82.3L137.4,73.4a8,8,0,0,0-2.2,7.2l5.6,28.3a8.4,8.4,0,0,0,6.3,6.3l28.3,5.6a8,8,0,0,0,7.2-2.2l32.6-32.5A55.9,55.9,0,0,1,199.6,135.6Z" />
        </>
      )}
    </svg>
  );
}

export function PhXCircle(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,20A108,108,0,1,0,236,128,108.1,108.1,0,0,0,128,20Zm0,192a84,84,0,1,1,84-84A84.1,84.1,0,0,1,128,212Zm40.5-107.5L145,128l23.5,23.5a12,12,0,0,1,0,17,12.1,12.1,0,0,1-17,0L128,145l-23.5,23.5a12.1,12.1,0,0,1-17,0,12,12,0,0,1,0-17L111,128,87.5,104.5a12,12,0,0,1,17-17L128,111l23.5-23.5a12,12,0,0,1,17,17Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <circle cx="128" cy="128" r="96" />
          </g>
          <path d="M128,24A104,104,0,1,0,232,128,104.1,104.1,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Z" />
          <path d="M165.7,90.3a8.1,8.1,0,0,0-11.4,0L128,116.7,101.7,90.3a8.1,8.1,0,0,0-11.4,11.4L116.7,128,90.3,154.3a8.1,8.1,0,0,0,0,11.4,8.2,8.2,0,0,0,11.4,0L128,139.3l26.3,26.4a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4L139.3,128l26.4-26.3A8.1,8.1,0,0,0,165.7,90.3Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm37.7,130.3a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0L128,139.3l-26.3,26.4a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L116.7,128,90.3,101.7a8.1,8.1,0,0,1,11.4-11.4L128,116.7l26.3-26.4a8.1,8.1,0,0,1,11.4,11.4L139.3,128Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,26A102,102,0,1,0,230,128,102.2,102.2,0,0,0,128,26Zm0,192a90,90,0,1,1,90-90A90.1,90.1,0,0,1,128,218Zm36.2-117.8L136.5,128l27.7,27.8a5.9,5.9,0,1,1-8.4,8.4L128,136.5l-27.8,27.7a5.9,5.9,0,0,1-8.4-8.4L119.5,128,91.8,100.2a5.9,5.9,0,0,1,8.4-8.4L128,119.5l27.8-27.7a5.9,5.9,0,0,1,8.4,8.4Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,28A100,100,0,1,0,228,128,100.2,100.2,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220ZM162.8,98.8,133.7,128l29.1,29.2a3.9,3.9,0,0,1,0,5.6,3.9,3.9,0,0,1-5.6,0L128,133.7,98.8,162.8a3.9,3.9,0,0,1-5.6,0,3.9,3.9,0,0,1,0-5.6L122.3,128,93.2,98.8a4,4,0,0,1,5.6-5.6L128,122.3l29.2-29.1a4,4,0,1,1,5.6,5.6Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.1,104.1,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm37.7-61.7a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0L128,139.3l-26.3,26.4a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L116.7,128,90.3,101.7a8.1,8.1,0,0,1,11.4-11.4L128,116.7l26.3-26.4a8.1,8.1,0,0,1,11.4,11.4L139.3,128Z" />
        </>
      )}
    </svg>
  );
}

export function PhXSquare(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M87.5,151.5,111,128,87.5,104.5a12,12,0,0,1,17-17L128,111l23.5-23.5a12,12,0,0,1,17,17L145,128l23.5,23.5a12,12,0,0,1,0,17,12.1,12.1,0,0,1-17,0L128,145l-23.5,23.5a12.1,12.1,0,0,1-17,0A12,12,0,0,1,87.5,151.5ZM228,48V208a20.1,20.1,0,0,1-20,20H48a20.1,20.1,0,0,1-20-20V48A20.1,20.1,0,0,1,48,28H208A20.1,20.1,0,0,1,228,48Zm-24,4H52V204H204Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <rect x="40" y="40" width="176" height="176" rx="8" />
          </g>
          <path d="M165.7,90.3a8.1,8.1,0,0,0-11.4,0L128,116.7,101.7,90.3a8.1,8.1,0,0,0-11.4,11.4L116.7,128,90.3,154.3a8.1,8.1,0,0,0,0,11.4,8.2,8.2,0,0,0,11.4,0L128,139.3l26.3,26.4a8.2,8.2,0,0,0,11.4,0,8.1,8.1,0,0,0,0-11.4L139.3,128l26.4-26.3A8.1,8.1,0,0,0,165.7,90.3Z" />
          <path d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32Zm0,176H48V48H208V208Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM165.7,154.3a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0L128,139.3l-26.3,26.4a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L116.7,128,90.3,101.7a8.1,8.1,0,0,1,11.4-11.4L128,116.7l26.3-26.4a8.1,8.1,0,0,1,11.4,11.4L139.3,128Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M164.2,100.2,136.5,128l27.7,27.8a5.9,5.9,0,1,1-8.4,8.4L128,136.5l-27.8,27.7a5.9,5.9,0,0,1-8.4-8.4L119.5,128,91.8,100.2a5.9,5.9,0,0,1,8.4-8.4L128,119.5l27.8-27.7a5.9,5.9,0,0,1,8.4,8.4ZM222,48V208a14,14,0,0,1-14,14H48a14,14,0,0,1-14-14V48A14,14,0,0,1,48,34H208A14,14,0,0,1,222,48Zm-12,0a2,2,0,0,0-2-2H48a2,2,0,0,0-2,2V208a2,2,0,0,0,2,2H208a2,2,0,0,0,2-2Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M162.8,98.8,133.7,128l29.1,29.2a3.9,3.9,0,0,1,0,5.6,3.9,3.9,0,0,1-5.6,0L128,133.7,98.8,162.8a3.9,3.9,0,0,1-5.6,0,3.9,3.9,0,0,1,0-5.6L122.3,128,93.2,98.8a4,4,0,0,1,5.6-5.6L128,122.3l29.2-29.1a4,4,0,1,1,5.6,5.6ZM220,48V208a12,12,0,0,1-12,12H48a12,12,0,0,1-12-12V48A12,12,0,0,1,48,36H208A12,12,0,0,1,220,48Zm-8,0a4,4,0,0,0-4-4H48a4,4,0,0,0-4,4V208a4,4,0,0,0,4,4H208a4,4,0,0,0,4-4Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M165.7,154.3a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0L128,139.3l-26.3,26.4a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L116.7,128,90.3,101.7a8.1,8.1,0,0,1,11.4-11.4L128,116.7l26.3-26.4a8.1,8.1,0,0,1,11.4,11.4L139.3,128ZM224,48V208a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V48A16,16,0,0,1,48,32H208A16,16,0,0,1,224,48ZM208,208V48H48V208Z" />
        </>
      )}
    </svg>
  );
}

export function PhX(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M208.5,191.5a12,12,0,0,1,0,17,12.1,12.1,0,0,1-17,0L128,145,64.5,208.5a12.1,12.1,0,0,1-17,0,12,12,0,0,1,0-17L111,128,47.5,64.5a12,12,0,0,1,17-17L128,111l63.5-63.5a12,12,0,0,1,17,17L145,128Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path d="M205.7,194.3a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0L128,139.3,61.7,205.7a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L116.7,128,50.3,61.7A8.1,8.1,0,0,1,61.7,50.3L128,116.7l66.3-66.4a8.1,8.1,0,0,1,11.4,11.4L139.3,128Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M205.7,194.3a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0L128,139.3,61.7,205.7a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L116.7,128,50.3,61.7A8.1,8.1,0,0,1,61.7,50.3L128,116.7l66.3-66.4a8.1,8.1,0,0,1,11.4,11.4L139.3,128Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M204.2,195.8a5.9,5.9,0,1,1-8.4,8.4L128,136.5,60.2,204.2a5.9,5.9,0,0,1-8.4-8.4L119.5,128,51.8,60.2a5.9,5.9,0,0,1,8.4-8.4L128,119.5l67.8-67.7a5.9,5.9,0,0,1,8.4,8.4L136.5,128Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M202.8,197.2a3.9,3.9,0,0,1,0,5.6,3.9,3.9,0,0,1-5.6,0L128,133.7,58.8,202.8a3.9,3.9,0,0,1-5.6,0,3.9,3.9,0,0,1,0-5.6L122.3,128,53.2,58.8a4,4,0,0,1,5.6-5.6L128,122.3l69.2-69.1a4,4,0,1,1,5.6,5.6L133.7,128Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M205.7,194.3a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0L128,139.3,61.7,205.7a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4L116.7,128,50.3,61.7A8.1,8.1,0,0,1,61.7,50.3L128,116.7l66.3-66.4a8.1,8.1,0,0,1,11.4,11.4L139.3,128Z" />
        </>
      )}
    </svg>
  );
}

export function PhYinYang(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M128,20A108,108,0,1,0,236,128,108.1,108.1,0,0,0,128,20ZM44,128a84.1,84.1,0,0,1,84-84,36,36,0,0,1,0,72,60,60,0,0,0-60,60,57.9,57.9,0,0,0,1.2,11.9A83.8,83.8,0,0,1,44,128Zm84,84a36,36,0,0,1,0-72,60,60,0,0,0,60-60,57.9,57.9,0,0,0-1.2-11.9A83.9,83.9,0,0,1,128,212Zm16-36a16,16,0,1,1-16-16A16,16,0,0,1,144,176ZM112,80a16,16,0,1,1,16,16A16,16,0,0,1,112,80Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <path
            d="M224,128a96,96,0,0,1-96,96,48,48,0,0,1,0-96,48,48,0,0,0,0-96A96,96,0,0,1,224,128Z"
            opacity="0.2"
          />
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24ZM40,128a88.1,88.1,0,0,1,88-88,40,40,0,0,1,0,80,56,56,0,0,0-56,56,55.2,55.2,0,0,0,5.4,24A88.1,88.1,0,0,1,40,128Zm88,88a40,40,0,0,1,0-80,56,56,0,0,0,56-56,55.2,55.2,0,0,0-5.4-24A88,88,0,0,1,128,216Zm12-40a12,12,0,1,1-12-12A12,12,0,0,1,140,176ZM116,80a12,12,0,1,1,12,12A12,12,0,0,1,116,80Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M140,80a12,12,0,1,1-12-12A12,12,0,0,1,140,80Zm92,48A104,104,0,1,1,128,24,104.2,104.2,0,0,1,232,128Zm-92,48a12,12,0,1,0-12,12A12,12,0,0,0,140,176Zm32-92a44,44,0,0,0-44-44A88,88,0,0,0,81.1,202.4,51.1,51.1,0,0,1,76,180a52,52,0,0,1,52-52A44,44,0,0,0,172,84Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M128,26A102,102,0,1,0,230,128,102.2,102.2,0,0,0,128,26ZM38,128a90.1,90.1,0,0,1,90-90,42,42,0,0,1,0,84,54,54,0,0,0-44.9,84A90.2,90.2,0,0,1,38,128Zm90,90a42,42,0,0,1,0-84,54,54,0,0,0,44.9-84A90,90,0,0,1,128,218Zm12-42a12,12,0,1,1-12-12A12,12,0,0,1,140,176ZM118,80a10,10,0,1,1,10,10A10,10,0,0,1,118,80Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M128,28A100,100,0,1,0,228,128,100.2,100.2,0,0,0,128,28ZM36,128a92.1,92.1,0,0,1,92-92,44,44,0,0,1,0,88,51.9,51.9,0,0,0-37.4,88A91.9,91.9,0,0,1,36,128Zm92,92a44,44,0,0,1,0-88,51.9,51.9,0,0,0,37.4-88A92,92,0,0,1,128,220Zm8-44a8,8,0,1,1-8-8A8,8,0,0,1,136,176ZM120,80a8,8,0,1,1,8,8A8,8,0,0,1,120,80Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24ZM40,128a88.1,88.1,0,0,1,88-88,40,40,0,0,1,0,80,56,56,0,0,0-56,56,55.2,55.2,0,0,0,5.4,24A88.1,88.1,0,0,1,40,128Zm88,88a40,40,0,0,1,0-80,56,56,0,0,0,56-56,55.2,55.2,0,0,0-5.4-24A88,88,0,0,1,128,216Zm12-40a12,12,0,1,1-12-12A12,12,0,0,1,140,176ZM116,80a12,12,0,1,1,12,12A12,12,0,0,1,116,80Z" />
        </>
      )}
    </svg>
  );
}

export function PhYoutubeLogo(props: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      {props.children}
      <rect width="256" height="256" fill="none" />
      {(props.weight || "regular") === "bold" && (
        <>
          <path d="M170.5,117.9l-56-36A12.1,12.1,0,0,0,96,92v72a11.9,11.9,0,0,0,6.3,10.5A11.3,11.3,0,0,0,108,176a11.7,11.7,0,0,0,6.5-1.9l56-36a12,12,0,0,0,0-20.2ZM120,142V114l21.8,14ZM238.2,68.8a28,28,0,0,0-16.9-19.1c-34.9-13.4-90-13.3-93.3-13.3s-58.4-.1-93.3,13.3A28,28,0,0,0,17.8,68.8C15.2,78.9,12,97.5,12,128s3.2,49.1,5.8,59.2a28,28,0,0,0,16.9,19.1c33.5,12.8,85.7,13.3,92.7,13.3h1.2c7,0,59.2-.5,92.7-13.3a28,28,0,0,0,16.9-19.1c2.6-10.1,5.8-28.7,5.8-59.2S240.8,78.9,238.2,68.8ZM215,181.2a4.4,4.4,0,0,1-2.3,2.7c-29.9,11.5-80.4,11.7-84.4,11.7h-.4c-.5,0-53.6.2-84.6-11.7a4.4,4.4,0,0,1-2.3-2.7c-1.9-7.2-5-23.7-5-53.2s3.1-46,5-53.2a4.4,4.4,0,0,1,2.3-2.7c31-11.9,84.1-11.7,84.6-11.7h.2c.5,0,53.6-.2,84.6,11.7a4.4,4.4,0,0,1,2.3,2.7c1.9,7.2,5,23.7,5,53.2S216.9,174,215,181.2Z" />
        </>
      )}
      {(props.weight || "regular") === "duotone" && (
        <>
          <g opacity="0.2">
            <path d="M226.6,71.8A16.1,16.1,0,0,0,217,60.9c-33.5-12.8-89-12.5-89-12.5s-55.5-.3-89,12.5a16.1,16.1,0,0,0-9.6,10.9C27.1,80.8,24,98.2,24,128s3.1,47.2,5.4,56.2A16.1,16.1,0,0,0,39,195.1c33.5,12.8,89,12.5,89,12.5s55.5.3,89-12.5a16.1,16.1,0,0,0,9.6-10.9c2.3-9,5.4-26.4,5.4-56.2S228.9,80.8,226.6,71.8ZM112,160V96l48,32Z" />
          </g>
          <path d="M164.4,121.3l-48-32a8.3,8.3,0,0,0-8.2-.4A8,8,0,0,0,104,96v64a8,8,0,0,0,4.2,7.1,8.5,8.5,0,0,0,3.8.9,8.4,8.4,0,0,0,4.4-1.3l48-32a8,8,0,0,0,0-13.4ZM120,145.1V110.9L145.6,128ZM234.3,69.8a23.5,23.5,0,0,0-14.5-16.3C185.6,40.3,131,40.4,128,40.4s-57.6-.1-91.8,13.1A23.5,23.5,0,0,0,21.7,69.8C19.1,79.7,16,97.9,16,128s3.1,48.3,5.7,58.2a23.5,23.5,0,0,0,14.5,16.3c32.8,12.7,84.2,13.1,91.1,13.1h1.4c6.9,0,58.3-.4,91.1-13.1a23.5,23.5,0,0,0,14.5-16.3c2.6-9.9,5.7-28.1,5.7-58.2S236.9,79.7,234.3,69.8ZM218.8,182.2a7.8,7.8,0,0,1-4.7,5.4c-31.7,12.2-85.5,12-86.1,12s-54.4.2-86.1-12a7.8,7.8,0,0,1-4.7-5.4C34.8,173.1,32,156.4,32,128s2.8-45.1,5.2-54.2a7.8,7.8,0,0,1,4.7-5.4c31.7-12.2,85.5-12,86.1-12s54.4-.2,86.1,12a7.8,7.8,0,0,1,4.7,5.4c2.4,9.1,5.2,25.8,5.2,54.2S221.2,173.1,218.8,182.2Z" />
        </>
      )}
      {(props.weight || "regular") === "fill" && (
        <>
          <path d="M234.3,69.8a23.5,23.5,0,0,0-14.5-16.3C185.6,40.3,131,40.4,128,40.4s-57.6-.1-91.8,13.1A23.5,23.5,0,0,0,21.7,69.8C19.1,79.7,16,97.9,16,128s3.1,48.3,5.7,58.2a23.5,23.5,0,0,0,14.5,16.3c32.8,12.7,84.2,13.1,91.1,13.1h1.4c6.9,0,58.3-.4,91.1-13.1a23.5,23.5,0,0,0,14.5-16.3c2.6-9.9,5.7-28.1,5.7-58.2S236.9,79.7,234.3,69.8Zm-72.1,61.5-48,32a3.6,3.6,0,0,1-2.2.7,4.5,4.5,0,0,1-1.9-.5A3.9,3.9,0,0,1,108,160V96a3.9,3.9,0,0,1,2.1-3.5,4,4,0,0,1,4.1.2l48,32a3.9,3.9,0,0,1,0,6.6Z" />
        </>
      )}
      {(props.weight || "regular") === "light" && (
        <>
          <path d="M163.3,123l-48-32a6,6,0,0,0-9.3,5v64a6,6,0,0,0,3.2,5.3,5.9,5.9,0,0,0,6.1-.3l48-32a6,6,0,0,0,0-10ZM118,148.8V107.2L149.2,128ZM232.4,70.3a22,22,0,0,0-13.3-15C185,42.2,130.3,42.4,128,42.4s-57-.2-91.1,12.9a22,22,0,0,0-13.3,15C21,80.1,18,98.1,18,128s3,47.9,5.6,57.7a22,22,0,0,0,13.3,15c34.1,13.1,88.8,12.9,91.1,12.9h.7c6.9,0,57.9-.4,90.4-12.9a22,22,0,0,0,13.3-15c2.6-9.8,5.6-27.8,5.6-57.7S235,80.1,232.4,70.3ZM220.8,182.7a10,10,0,0,1-6,6.8c-32,12.3-86.2,12.1-86.8,12.1s-54.8.2-86.8-12.1a10,10,0,0,1-6-6.8C32.8,173.5,30,156.6,30,128s2.8-45.5,5.2-54.7a10,10,0,0,1,6-6.8c32-12.3,86.2-12.1,86.8-12.1s54.8-.2,86.8,12.1a10,10,0,0,1,6,6.8c2.4,9.2,5.2,26.1,5.2,54.7S223.2,173.5,220.8,182.7Z" />
        </>
      )}
      {(props.weight || "regular") === "thin" && (
        <>
          <path d="M162.2,124.7l-48-32a4,4,0,0,0-4.1-.2A3.9,3.9,0,0,0,108,96v64a3.9,3.9,0,0,0,2.1,3.5,4.5,4.5,0,0,0,1.9.5,3.6,3.6,0,0,0,2.2-.7l48-32a3.9,3.9,0,0,0,0-6.6ZM116,152.5v-49L152.8,128ZM230.5,70.8a20.4,20.4,0,0,0-12.1-13.6c-33.8-13-88.1-12.8-90.4-12.8s-56.6-.2-90.4,12.8A20.4,20.4,0,0,0,25.5,70.8C23,80.5,20,98.3,20,128s3,47.5,5.5,57.2a20.4,20.4,0,0,0,12.1,13.6c33.8,13,88.1,12.8,90.4,12.8h.7c6.9,0,57.6-.4,89.7-12.8a20.4,20.4,0,0,0,12.1-13.6c2.5-9.7,5.5-27.5,5.5-57.2S233,80.5,230.5,70.8Zm-7.8,112.4a12,12,0,0,1-7.2,8.1c-32.4,12.5-86.9,12.3-87.5,12.3s-55.1.2-87.5-12.3a12,12,0,0,1-7.2-8.1C30.9,173.9,28,156.8,28,128s2.9-45.9,5.3-55.2a12,12,0,0,1,7.2-8.1C72.9,52.2,127.4,52.4,128,52.4s55.1-.2,87.5,12.3a12,12,0,0,1,7.2,8.1c2.4,9.3,5.3,26.4,5.3,55.2S225.1,173.9,222.7,183.2Z" />
        </>
      )}
      {(props.weight || "regular") === "regular" && (
        <>
          <path d="M164.4,121.3l-48-32a8.3,8.3,0,0,0-8.2-.4A8,8,0,0,0,104,96v64a8,8,0,0,0,4.2,7.1,8.5,8.5,0,0,0,3.8.9,8.4,8.4,0,0,0,4.4-1.3l48-32a8,8,0,0,0,0-13.4ZM120,145.1V110.9L145.6,128ZM234.3,69.8a23.5,23.5,0,0,0-14.5-16.3C185.6,40.3,131,40.4,128,40.4s-57.6-.1-91.8,13.1A23.5,23.5,0,0,0,21.7,69.8C19.1,79.7,16,97.9,16,128s3.1,48.3,5.7,58.2a23.5,23.5,0,0,0,14.5,16.3c32.8,12.7,84.2,13.1,91.1,13.1h1.4c6.9,0,58.3-.4,91.1-13.1a23.5,23.5,0,0,0,14.5-16.3c2.6-9.9,5.7-28.1,5.7-58.2S236.9,79.7,234.3,69.8ZM218.8,182.2a7.8,7.8,0,0,1-4.7,5.4c-31.7,12.2-85.5,12-86.1,12h-.1c-.5,0-54.3.2-86-12a7.8,7.8,0,0,1-4.7-5.4C34.8,173.1,32,156.4,32,128s2.8-45.1,5.2-54.2a7.8,7.8,0,0,1,4.7-5.4c30.5-11.7,81.7-12,85.9-12h.2c.6,0,54.4-.2,86.1,12a7.8,7.8,0,0,1,4.7,5.4c2.4,9.1,5.2,25.8,5.2,54.2S221.2,173.1,218.8,182.2Z" />
        </>
      )}
    </svg>
  );
}
