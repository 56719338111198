import type { EventsSchemaInput } from '@donkeyjs/module-events';
import {
  shoppingSchemaOrderable,
  type ShoppingSchemaInput,
} from '@donkeyjs/module-shopping';
import {
  createSchemaExtension,
  type SchemaFromInput,
  type SchemaInputWithExtensions,
} from '@donkeyjs/proxy';

export const ticketingSchemaExtension = createSchemaExtension<
  SchemaInputWithExtensions<EventsSchemaInput, ShoppingSchemaInput>
>()({
  nodes: {
    EventOption: {
      name: ['string', { i18n: true }],
      event: [
        'Event',
        { reverse: 'options', embeddedList: true, cascadeDelete: true },
      ],
      sortIndex: 'float?',
      ...shoppingSchemaOrderable,
    },
  },

  extensions: {
    OrderItem: {
      add: {
        eventOption: [
          'EventOption?',
          { reverse: 'orderItems', embeddedList: true },
        ],
      },
    },
  },

  resolvers: {
    Event: {
      ticketingEvents: {
        return: 'many',
        where: { starts: ['starts', ['gt', 'lt']] },
        sort: [
          { name: 'DATE_ASC', order: ['starts:asc'] },
          { name: 'DATE_DESC', order: ['starts:desc'] },
        ],
      },
    },

    Order: {
      eventOrders: {
        return: 'many',
        where: {
          event: ['items.eventOption.event', ['eq']],
          stage: ['stage', ['eq']],
        },
        sort: [
          {
            name: 'LAST_NAME',
            order: [
              'billingLastName:asc',
              'billingMiddleName:asc',
              'billingFirstName:asc',
            ],
          },
        ],
      },
    },
  },
} as const);

export type TicketingSchemaInput = SchemaInputWithExtensions<
  SchemaInputWithExtensions<EventsSchemaInput, ShoppingSchemaInput>,
  typeof ticketingSchemaExtension
>;

export type TicketingSchema = SchemaFromInput<TicketingSchemaInput>;
