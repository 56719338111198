import type { NodeFieldsFromSchema } from '@donkeyjs/proxy';

export const orderSum = (order: {
  items?: Pick<
    NodeFieldsFromSchema<DataSchema, 'OrderItem'>,
    'quantity' | 'price'
  >[];
}) =>
  Math.round(
    (order.items || []).reduce(
      (sum, item) => sum + item.quantity * item.price,
      0,
    ) * 100,
  ) / 100;

// export const orderSumWithShipping = ({
//   countries,
//   order,
// }: {
//   countries: { id: string; name: string; region: string }[];
//   order: DataNode<DataSchema, 'Order'>;
// }) => orderSum(order) + orderShippingCosts({ countries, order });
