import { baseSchema, blocksSort } from '@donkeyjs/core';
import { schemaNlNL } from '@donkeyjs/i18n-nl-nl';
import { eventsSchemaExtension } from '@donkeyjs/module-events';
import { mailingsSchemaExtension } from '@donkeyjs/module-mailings';
import {
  productsSchemaExtension,
  shoppingSchemaExtension,
} from '@donkeyjs/module-shopping';
import { ticketingSchemaExtension } from '@donkeyjs/module-ticketing';
import { createSchema, extendSchema } from '@donkeyjs/proxy';

const { result } = extendSchema(baseSchema)
  .with({ i18n: { nl: schemaNlNL } })
  .with(eventsSchemaExtension)
  .with(shoppingSchemaExtension)
  .with(productsSchemaExtension)
  .with(ticketingSchemaExtension)
  .with(mailingsSchemaExtension)
  .with({
    nodes: {
      Koek: {
        publishedAt: 'date',
        image: ['FileRef?', { reverse: 'koek' }],
      },

      TagEvents: {
        tag: ['Tag', { reverse: 'events' }],
        event: ['Event', { reverse: 'tags' }],
      },
    },

    extensions: {
      Block: {
        add: {
          onEventDescription: [
            'Event?',
            { reverse: 'description', sort: blocksSort },
          ],
          onEventMoreInfo: [
            'Event?',
            { reverse: 'moreInfo', sort: blocksSort },
          ],
          onProductInformation: [
            'Product?',
            { reverse: 'information', sort: blocksSort },
          ],
          onKoekText: ['Koek?', { reverse: 'text', sort: blocksSort }],
        },
      },

      Event: {
        add: {
          image: ['FileRef?', { reverse: 'events' }],
        },
      },

      Newsletter: {
        add: {
          photo: ['FileRef?', { reverse: 'onNewsletterPhoto' }],
        },
      },

      Product: {
        add: {
          image: ['FileRef?', { reverse: 'products' }],
        },
      },
    },

    resolvers: {
      Event: {
        events: {
          return: 'many',
          where: {
            ends: ['ends', ['gte', 'lt']],
            tags: ['tags.tag', ['eq']],
          },
          sort: [
            { name: 'DATE_ASC', order: ['starts:asc'] },
            { name: 'DATE_DESC', order: ['starts:desc'] },
          ],
        },
      },

      Koek: {
        koek: {
          return: 'many',
          sort: [{ name: 'DATE_DESC', order: ['publishedAt:desc'] }],
        },
      },

      Product: {
        sortedProducts: {
          return: 'many',
          sort: [{ name: 'SORTED', order: ['sortIndex:auto'] }],
        },
      },
    },

    i18n: {
      nl: () => import('./i18n/nl').then((m) => m.Schema),
    },
  } as const);

export const schema = createSchema(result, {
  cultures: ['nl'],
  defaultCulture: 'nl',
});

export type AppSchema = typeof schema;
