import { session, type I18nContext } from '@donkeyjs/client';
import { formatCurrencySign } from '../../helpers/formatCurrencySign';

export const formatMoney = (
  i18n: I18nContext,
  amount: number,
  currency?: string,
) => {
  const sign = formatCurrencySign(
    currency || session.app.data[0]?.defaultCurrency || '',
  );
  return [sign, i18n.formatNumber(amount, '.2f')].filter(Boolean).join(' ');
};
