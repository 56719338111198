import {
  Heading,
  IncreaseHeadingLevel,
  createView,
  getTheme,
  session,
} from '@donkeyjs/client';
import { bind } from '@donkeyjs/proxy';
import styles from './ViewEvent.module.css';
import { CartOptions } from './components/CartOptions';
import { ViewBox } from './components/ViewBox';

export const ViewEvent = createView<DataSchema, 'Event'>(
  {
    name: () => 'Event View',
    enableAsBlock: true,
  },

  function ViewEvent(props) {
    const theme = getTheme();

    return (
      <ViewBox
        item={props.node}
        class={bind(() => props.class)}
        onmount={props.onmount}
      >
        <div class={styles.section}>
          <a
            class={[styles.button, theme.class.button, 'default']}
            href={bind(() =>
              session.router.getPath(
                props.selected ? { route: 'current' } : { node: props.node },
              ),
            )}
          >
            {() => (props.selected ? 'Sluiten' : 'Info & Kaarten')}
          </a>
          <props.node.$.starts />
          <Heading styleAs="title" class={styles.title}>
            <props.node.$.name />
          </Heading>
        </div>
        <IncreaseHeadingLevel>
          <div class={styles.section}>
            <div class={styles.tags}>
              {props.node.tags.$((tag) => (
                <span>{tag.tag.name}</span>
              ))}
            </div>
            <props.node.$.description />
          </div>
          {() =>
            props.selected && (
              <>
                <CartOptions
                  kind="eventOption"
                  node={props.node}
                  class={styles.section}
                />
                <div class={styles.section}>
                  <props.node.$.moreInfo />
                </div>
              </>
            )
          }
        </IncreaseHeadingLevel>
      </ViewBox>
    );
  },
);
