import { Loader } from '@donkeyjs/client';
import { bind } from '@donkeyjs/jsx-runtime';
import { meta, store } from '@donkeyjs/proxy';
import { useCart, type OrderableItem, type OrderableKey } from '../useCart';
import { Quantity } from './Quantity';

interface AddToCartQuantityProps<Key extends OrderableKey> {
  kind: Key;
  item: OrderableItem<Key>;
  class?: JSX.ClassNames;
  style?: 'default' | 'icon-only';
}

export function AddToCartQuantity<Key extends OrderableKey>(
  props: AddToCartQuantityProps<Key>,
) {
  const cart = useCart();

  meta(props.item).request({
    currency: true,
    name: true,
    price: true,
    weight: true,
  });

  const state = store({
    get count() {
      return cart.countItems(props.kind, props.item);
    },
  });

  return () => (
    <Loader loading={bind(() => meta(props.item).isLoading)} type="none">
      <Quantity
        number={bind(() => state.count)}
        increment={() => cart.add(props.kind, props.item)}
        decrement={() => cart.remove(props.kind, props.item, 1)}
        class={bind(() => props.class)}
      />
    </Loader>
  );
}
