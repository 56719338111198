import type { BlockPreset, BlocksType } from '@donkeyjs/client';
import { PhAddressBook } from '@donkeyjs/phosphor-icons';
import { SubscribeFormBlock } from './mailing-list-subscribe/SubscribeFormBlock';

export const blocks: BlocksType = {
  'mailing-list-subscribe': { component: SubscribeFormBlock },
};

export const blockPresets: BlockPreset[] = [
  {
    name: () => 'Subscribe Form',
    group: () => 'Widgets',
    icon: () => <PhAddressBook weight="duotone" />,
    hue: 327,
    values: {
      type: 'mailing-list-subscribe',
    },
  },
];
