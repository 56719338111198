import { Blocks, session, useMenu } from '@donkeyjs/client';
import { bind, updateComponent } from '@donkeyjs/jsx-runtime';
import { map } from '@donkeyjs/proxy';
import styles from './Main.module.css';
import { Sidebar } from './components/Sidebar';

export function Main() {
  const menu = useMenu();

  return (
    <div class={styles.main}>
      <div class={styles.body}>
        <ul class={styles.menu}>
          {map(
            () => menu.level,
            (route) => (
              <li class={bind(() => ({ [styles.active]: route.isActive }))}>
                <a href={session.router.getPath({ route: route.route })}>
                  <span>{route.route.name}</span>
                  {route.route.node && (
                    <route.route.node.$.image changeOnClick={false} />
                  )}
                </a>
              </li>
            ),
          )}
        </ul>
        <div class={styles.content}>
          <Blocks segment="main" />
        </div>
      </div>
      <Sidebar />
    </div>
  );
}

// css`
//   :global(.app-root) {
//     display: flex;
//     flex-direction: column;
//   }

if (import.meta.hot) {
  import.meta.hot.accept((hot) => {
    updateComponent(Main, hot?.Main);
  });
}
