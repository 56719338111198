import { Blocks, SlideOut, getTheme } from '@donkeyjs/client';
import { bind, store } from '@donkeyjs/proxy';
import styles from './Sidebar.module.css';

export function Subscribe() {
  const theme = getTheme();

  const state = store({
    open: false,
  });

  return (
    <>
      <button
        type="button"
        class={[theme.class.button, 'active', styles.shadow]}
        onclick={() => {
          state.open = !state.open;
        }}
      >
        Ontvang onze nieuwsbrief
      </button>
      <SlideOut open={bind(state, 'open')}>
        <div class={styles.subscribe}>
          <Blocks on="app" segment="mailing-list-subscribe" />
        </div>
      </SlideOut>
    </>
  );
}
